var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "printBagNumber",
      staticClass: "fontSizeStyle",
      attrs: { id: "printBagNumber" },
    },
    _vm._l(_vm.paramData, function (item, index) {
      return _c("div", { key: index }, [
        _c(
          "div",
          {
            ref: "printBagNumberContent",
            refInFor: true,
            staticClass: "printBagNumberContent",
            staticStyle: {
              "page-break-before": "always",
              "break-inside": "avoid",
            },
          },
          [
            _c("div", { staticClass: "headerBag" }, [
              _c(
                "div",
                { staticStyle: { "font-size": "25px", "font-weight": "bold" } },
                [_vm._v(_vm._s(item.providerName || "--"))]
              ),
              _c("div", [
                _c("div", [_vm._v(_vm._s(item.time || "--"))]),
                _c("div", [_vm._v(_vm._s(item.timeSs || "--"))]),
              ]),
            ]),
            _c("div", { staticClass: "bagContent" }, [
              _c(
                "div",
                {
                  staticStyle: {
                    "text-align": "center",
                    "font-size": "22px",
                    "padding-top": "10px",
                  },
                },
                [_vm._v("Informações da Saca")]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "text-align": "center",
                    "font-size": "38px",
                    margin: "20px 0",
                    "font-weight": "bold",
                  },
                },
                [_vm._v(" " + _vm._s(item.threeCode || "--") + " ")]
              ),
              _c("div", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "margin-right": "10px",
                      "font-size": "18px",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("GlobalSetObj.bagNumber")) + ":")]
                ),
                _c("span", { staticStyle: { "font-size": "22px" } }, [
                  _vm._v(_vm._s(item.bagNumber || "--")),
                ]),
              ]),
              _c("div", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "margin-right": "10px",
                      "font-size": "18px",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("GlobalSetObj.DeliveryOutlets")) + ":")]
                ),
                _c("span", { staticStyle: { "font-size": "22px" } }, [
                  _vm._v(_vm._s(item.deliverSiteName || "--")),
                ]),
              ]),
              _c("div", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "margin-right": "10px",
                      "font-size": "18px",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.netAdress")) + ":")]
                ),
                _c("span", { staticStyle: { "font-size": "22px" } }, [
                  _vm._v(_vm._s(item.siteAddress || "--")),
                ]),
              ]),
              _c("div", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "margin-right": "10px",
                      "font-size": "18px",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.fenboCode")) + ":")]
                ),
                _c("span", { staticStyle: { "font-size": "22px" } }, [
                  _vm._v(_vm._s(item.departmentCode || "--")),
                ]),
              ]),
              _c("div", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "margin-right": "10px",
                      "font-size": "18px",
                    },
                  },
                  [_vm._v(_vm._s("Basecode") + ":")]
                ),
                _c("span", { staticStyle: { "font-size": "22px" } }, [
                  _vm._v(_vm._s(item.baseCodeName || "--")),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "logoAnjun" }, [
              _c("img", {
                staticStyle: { width: "150px" },
                attrs: { src: require("@/assets/anjunTxt.jpg"), alt: "tu" },
              }),
            ]),
          ]
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }