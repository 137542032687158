<template>
  <el-drawer
    :title="$t('receivablePayable.手动开票')"
    :visible.sync="isDrawer"
    direction="ltr"
    size="50%"
  >
    <el-form
      ref="ruleForm" :model="ruleForm" :rules="rules"
      label-width="100px" class="demo-ruleForm"
    >
      <!-- 业务类型 -->
      <el-form-item :label="$t('collectionCenter.businessT')" prop="businessType">
        <el-select
          v-model="ruleForm.businessType" :placeholder="$t('GlobalSetObj.pleaseChoose')" style="width: 100%;"
          @change="onBusinessType"
        >
          <el-option
            v-for="item in BUSINESS_TYPE"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />

        </el-select>
      </el-form-item>

      <!-- 揽收类别 业务类型为揽收时必填 -->
      <el-form-item :label="$t('basicData.PickupCategory')" prop="collectionCategory">
        <el-select
          v-model="ruleForm.collectionCategory" :placeholder="$t('GlobalSetObj.pleaseChoose')" :disabled="ruleForm.businessType !==BUSINESS_TYPE['1'].value"
          style="width: 100%;"
        >
          <el-option
            v-for="item in COLLECTION_CATEGORY_TYPE"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />

        </el-select>
      </el-form-item>

      <el-form-item :label="$t('collectionCenter.Number')" prop="businessNumber">
        <InputTextareaCmp
          ref="inputTextareaRef" :max-rows="10" :val="ruleForm.businessNumber"
          @input="(val, str) => ruleForm.businessNumber = str"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">{{ $t('GlobalSetObj.determine') }}</el-button>
        <el-button @click="() => isDrawer = false">{{ $t('GlobalSetObj.cancel') }}</el-button>
      </el-form-item>
    </el-form>
  </el-drawer>
</template>

<script>
import { COLLECTION_CATEGORY_TYPE } from '@/views/finance/enums';
import { BUSINESS_TYPE } from './../utils';
import InputTextareaCmp from '@/views/finance/cmp/Query/InputTextareaCmp.vue';
import { apiLogisticsInvoiceGenerateCte } from '@/api/finance/basicData';
import { goFileUploadManagement } from '@/utils/goExportList.js';

const QUERY = {
  businessType: '', // 业务类型 1:揽收 2：派送 3：转运
  collectionCategory: '', // 揽收类别  1:普通揽收 2:备货揽收，业务类型为揽收时必填
  businessNumber: ''
};

export default {
  name: '',
  components: {
    InputTextareaCmp
  },
  data() {
    const collectionCategoryCheck = (rule, value, callback) => {
      const { businessType } = this.ruleForm;

      // 非揽收
      if (businessType !== BUSINESS_TYPE['1'].value) {
        return callback();
      }

      if (!value) {
        return callback(new Error(this.$t('GlobalSetObj.pleaseChoose')));
      }
      return callback();
    };

    return {
      isDrawer: false,

      ruleForm: {
        ...QUERY
      },
      rules: {
        businessNumber: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'blur' }
        ],
        collectionCategory: [
          { required: true, validator: collectionCategoryCheck, trigger: 'blur' }
        ],
        businessType: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ]
      }

    };
  },

  created() {
    this.COLLECTION_CATEGORY_TYPE = COLLECTION_CATEGORY_TYPE;
    this.BUSINESS_TYPE = BUSINESS_TYPE; // 业务类型
  },

  methods: {
    onOpen() {
      this.isDrawer = true;
      this.listVal = '';
      this.ruleForm = {
        ...QUERY
      };
    },

    getQuery() {
      const { businessType, collectionCategory } = this.ruleForm;
      const arr = this.$refs.inputTextareaRef.getValue();

      const obj = {
        businessType,
        collectionCategory: collectionCategory || undefined,
        businessNumberList: arr
      };

      return obj;
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          // alert('submit!');
          return false;
        }

        const obj = this.getQuery();
        this.asyncApiGenerateCte(obj);
      });
    },

    // 业务类型切换
    onBusinessType() {
      this.ruleForm.collectionCategory = '';
    },

    asyncApiGenerateCte(obj) {
      apiLogisticsInvoiceGenerateCte(obj).then(res => {
        this.isDrawer = false;
        // this.$emit('success');
        this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
        setTimeout(() => {
          goFileUploadManagement(this);
        }, 500);
      }).catch(err => {
        console.error(err);
      });
    }
  }

};
</script>

<style lang="scss" scoped>
.demo-ruleForm {
  padding: 0 10px;
}
</style>
