<template>
  <div class="nodeTable">
    <el-table
      :data="dataList"
      max-height="300"
      border
      style="width: 100%"
    >
      <!--序号-->
      <el-table-column :label="$t('operationCenter.ind')" width="60px" type="index" />
      <!-- 操作时间 -->
      <el-table-column :label="$t('GlobalSetObj.operationTime')" prop="createTime" width="180" />
      <!-- 操作节点 -->
      <el-table-column :label="$t('userManage.node')" prop="operationNode" width="180">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.operationNode] && status[scope.row.operationNode].type || ''">
            {{ status[scope.row.operationNode] && status[scope.row.operationNode].name || '' }}
          </el-tag>
        </template>
      </el-table-column>
      <!-- 操作人 -->
      <el-table-column :label="$t('GlobalSetObj.operator')" prop="createUserName" width="180"/>
      <!-- 操作详情 -->
      <el-table-column :label="$t('GlobalSetObj.details')" prop="remarks" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span v-if="!scope.row.extra">{{ scope.row.remarks }}</span>
          <el-link v-else type="primary" @click="onOpen(scope.row)">{{ scope.row.remarks }}</el-link>
        </template>
      </el-table-column>
    </el-table>

    <!-- 查看移除单号 -->
    <el-dialog 
      :title="$t('basicData.订单信息')" 
      :visible.sync="dialogTableVisible"  
      width="50%"  
      append-to-body
      :close-on-click-modal="false"
      :style="{height: '350px', overflowY: 'auto'}"
    >
      <logTable :dataList="dialogData" />
    </el-dialog>
  </div>
</template>

<script>
import { STATUS } from '../../content.js';
import logTable from './logTable.vue';

export default {
  props: {
    dataList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  components: {
    logTable
  },

  data() {
    return {
      status: STATUS,
      dialogTableVisible: false,
      dialogData: []
    };
  },

  methods: {
    onOpen(row) {
      this.dialogData = JSON.parse(row.extra);
      this.dialogTableVisible = true;
    }
  },
};
</script>

<style lang="scss" scoped>

</style>