var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container job" },
    [
      _c("el-card", [
        _c("div", [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
                "margin-bottom": "5px",
              },
            },
            [
              _c(
                "span",
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value: "but:user-center:job-management:add",
                          expression: "'but:user-center:job-management:add'",
                          arg: "remove",
                        },
                      ],
                      attrs: {
                        type: "primary",
                        icon: "el-icon-plus",
                        size: "mini",
                      },
                      on: { click: _vm.addOpen },
                    },
                    [_vm._v(_vm._s(_vm.$t("jobManagement.add")))]
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
        _c(
          "div",
          [
            _c(
              "el-table",
              { attrs: { data: _vm.tableData, border: "" } },
              [
                _c("el-table-column", {
                  attrs: { type: "index", width: "50" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "name", label: _vm.$t("jobManagement.name") },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "positionName",
                    label: _vm.$t("jobManagement.SubordinateDepartments"),
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("jobManagement.operation"),
                    width: "80",
                    fixed: "right",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permit",
                                  rawName: "v-permit:remove",
                                  value: "but:user-center:job-management:edit",
                                  expression:
                                    "'but:user-center:job-management:edit'",
                                  arg: "remove",
                                },
                              ],
                              attrs: { type: "info", size: "mini" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.addOpen(scope.row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("jobManagement.edit")))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pagination" },
          [
            _c("el-pagination", {
              staticStyle: { margin: "10px 0" },
              attrs: {
                layout: "total, sizes, prev, pager, next, jumper",
                "current-page": _vm.queryForm.pageNumber,
                "page-sizes": [10, 20, 50, 100],
                "page-size": _vm.queryForm.pageSize,
                total: _vm.total,
                background: "",
              },
              on: {
                "update:currentPage": function ($event) {
                  return _vm.$set(_vm.queryForm, "pageNumber", $event)
                },
                "update:current-page": function ($event) {
                  return _vm.$set(_vm.queryForm, "pageNumber", $event)
                },
                "update:pageSize": function ($event) {
                  return _vm.$set(_vm.queryForm, "pageSize", $event)
                },
                "update:page-size": function ($event) {
                  return _vm.$set(_vm.queryForm, "pageSize", $event)
                },
                "current-change": _vm.getData,
                "size-change": _vm.getData,
              },
            }),
          ],
          1
        ),
      ]),
      _vm.addDialogObj.dialogVisible
        ? _c(
            "div",
            [
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: _vm.addDialogObj.title,
                    visible: _vm.addDialogObj.dialogVisible,
                    width: "30%",
                    "close-on-click-modal": true,
                  },
                  on: {
                    "update:visible": function ($event) {
                      return _vm.$set(_vm.addDialogObj, "dialogVisible", $event)
                    },
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "demo-ruleForm",
                      attrs: {
                        model: _vm.addDialogObj.ruleForm,
                        rules: _vm.addDialogObj.rules,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("jobManagement.department"),
                            prop: "orgIds",
                          },
                        },
                        [
                          _c("el-cascader", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              options: _vm.organizationTree,
                              "show-all-levels": false,
                              props: {
                                checkStrictly: true,
                                value: "id",
                                label: "name",
                              },
                              clearable: "",
                              placeholder: _vm.$t("jobManagement.choose"),
                            },
                            model: {
                              value: _vm.addDialogObj.ruleForm.orgIds,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.addDialogObj.ruleForm,
                                  "orgIds",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "addDialogObj.ruleForm.orgIds",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("jobManagement.JobTitle"),
                            prop: "name",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("jobManagement.input"),
                            },
                            model: {
                              value: _vm.addDialogObj.ruleForm.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.addDialogObj.ruleForm,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "addDialogObj.ruleForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.addDialogObj.dialogVisible = false
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("jobManagement.cancel")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("jobManagement.confirm")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }