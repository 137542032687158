var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      staticClass: "demo-ruleForm",
      attrs: {
        model: _vm.formData,
        "label-width": "0",
        size: "small",
        rules: _vm.rules,
      },
    },
    [
      _c(
        "el-table",
        { attrs: { data: _vm.formData.arr, border: "" } },
        [
          _c(
            "el-table-column",
            {
              attrs: { prop: "startWeight", width: "200" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "arr." + scope.$index + ".startWeight",
                            rules: {
                              required: true,
                              trigger: "blur",
                              validator: function (rule, value, callback) {
                                return _vm.startCheck(
                                  rule,
                                  value,
                                  callback,
                                  scope.row
                                )
                              },
                            },
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              precision: 2,
                              min: 0,
                              max: 999999999,
                              controls: false,
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: scope.row.startWeight,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "startWeight", $$v)
                              },
                              expression: "scope.row.startWeight",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _c("div", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("basicData.KilometersStartKM"))),
                  ]),
                  _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "el-table-column",
            {
              attrs: { prop: "endWeight", width: "200" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "arr." + scope.$index + ".endWeight",
                            rules: {
                              required: true,
                              trigger: "blur",
                              validator: function (rule, value, callback) {
                                return _vm.startCheck(
                                  rule,
                                  value,
                                  callback,
                                  scope.row
                                )
                              },
                            },
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              precision: 2,
                              min: 0,
                              max: 999999999,
                              controls: false,
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: scope.row.endWeight,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "endWeight", $$v)
                              },
                              expression: "scope.row.endWeight",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _c("div", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("basicData.MileageEndKM"))),
                  ]),
                  _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "el-table-column",
            {
              attrs: { prop: "price", "min-width": "200" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "arr." + scope.$index + ".price",
                            rules: _vm.rules.price,
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              precision: 3,
                              min: 0,
                              max: 999999999,
                              controls: false,
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: scope.row.price,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "price", $$v)
                              },
                              expression: "scope.row.price",
                            },
                          }),
                          _vm._v(
                            " " + _vm._s(_vm.$t("orderCenterCont.days")) + " "
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _c("div", [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("collectionCenter.hut")) +
                        _vm._s(_vm.$t("collectionCenter.turnTransport")) +
                        _vm._s(_vm.$t("basicData.BasePrice")) +
                        "(PT014)"
                    ),
                  ]),
                  _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "el-table-column",
            {
              attrs: { prop: "overshipmentPrice", "min-width": "200" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "arr." + scope.$index + ".overshipmentPrice",
                            rules: _vm.rules.overshipmentPrice,
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              precision: 3,
                              min: 0,
                              max: 999999999,
                              controls: false,
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: scope.row.overshipmentPrice,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "overshipmentPrice", $$v)
                              },
                              expression: "scope.row.overshipmentPrice",
                            },
                          }),
                          _vm._v(" /" + _vm._s(_vm.$t("basicData.个")) + " "),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _c("div", [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("collectionCenter.hut")) +
                        _vm._s(_vm.$t("collectionCenter.turnTransport")) +
                        _vm._s(_vm.$t("basicData.商家提成")) +
                        "(PT015)"
                    ),
                  ]),
                  _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "el-table-column",
            {
              attrs: { prop: "price", "min-width": "200" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "arr." + scope.$index + ".standardPrice",
                            rules: _vm.rules.standardPrice,
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              precision: 3,
                              min: 0,
                              max: 999999999,
                              controls: false,
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: scope.row.standardPrice,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "standardPrice", $$v)
                              },
                              expression: "scope.row.standardPrice",
                            },
                          }),
                          _vm._v(
                            " /" +
                              _vm._s(_vm.$t("collectionCenter.ticket")) +
                              " "
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _c("div", [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("collectionCenter.hut")) +
                        _vm._s(_vm.$t("collectionCenter.turnTransport")) +
                        _vm._s(_vm.$t("basicData.包裹价格")) +
                        "(PT016)"
                    ),
                  ]),
                  _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "el-table-column",
            {
              attrs: { "min-width": "200" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-form-item",
                        [
                          _c("el-input-number", {
                            attrs: {
                              precision: 0,
                              min: 0,
                              max: 999999999,
                              controls: false,
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: scope.row.overWeightExtraPrice,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "overWeightExtraPrice", $$v)
                              },
                              expression: "scope.row.overWeightExtraPrice",
                            },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("collectionCenter.ticket")) +
                              " "
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _c("div", [
                  _c("span", [_vm._v(_vm._s(_vm.$t("basicData.最低货量")))]),
                ]),
              ]),
            ],
            2
          ),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("basicData.operate"),
              fixed: "right",
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDel(scope.$index)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("basicData.Delete")))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }