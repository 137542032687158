var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      staticStyle: { width: "100%" },
      attrs: {
        filterable: "",
        clearable: "",
        remote: "",
        placeholder:
          _vm.$t("customerManagements.pleaseChoose") +
          _vm.$t("collectionCenter.dispatch"),
        loading: _vm.loading,
        multiple: "",
        "multiple-limit": 10,
        "remote-method": _vm.getDeliveryPage,
        "value-key": "dispatcherId",
      },
      on: { change: _vm.onChaneg },
      model: {
        value: _vm.dispatcherList,
        callback: function ($$v) {
          _vm.dispatcherList = $$v
        },
        expression: "dispatcherList",
      },
    },
    _vm._l(_vm.list, function (item) {
      return _c("el-option", {
        key: item.value.dispatcherId + "" + item.value.source,
        attrs: { label: item.label, value: item.value },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }