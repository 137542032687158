<template>
  <el-form
    ref="ruleForm" :model="ruleForm" :rules="rules"
    label-width="100px" class="demo-ruleForm"
  >
    <el-form-item label="" prop="passed">
      <el-radio-group v-model="ruleForm.passed">
        <!-- 通过 -->
        <el-radio :label="1">{{ $t('ClaimsManagement.Pass') }}</el-radio>
        <!-- 驳回 -->
        <el-radio :label="2">{{ $t('ClaimsManagement.TurnDown') }}</el-radio>
      </el-radio-group>
    </el-form-item>
    <!-- 备注 -->
    <el-form-item :label="$t('GlobalSetObj.remark')" prop="rejectionReasons">
      <el-input
        v-model="ruleForm.rejectionReasons"
        maxlength="200"
        type="textarea"
        show-word-limit
        :placeholder="$t('GlobalSetObj.pleaseInputContent')"
      />
    </el-form-item>
    <el-form-item>
      <!-- 提交 -->
      <el-button type="primary" @click="onSubmit">{{ $t('customerManagements.submit') }}</el-button>
      <!-- 取消 -->
      <el-button @click="() => onClose(false)">{{ $t('GlobalSetObj.cancel') }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { apiClaimformRejectAndPass } from '@/api/claimsManagement/list';

export default {
  name: '',

  props: {
    ids: {
      type: Number,
      default: 0
    }
  },

  data() {
    const passedCheck = (rule, value, callback) => {
      const { passed } = this.ruleForm;
      if (passed === 1) {
        return callback();
      }

      if (!value.trim().length) {
        return callback(new Error(this.$t('GlobalSetObj.pleaseInput'))); // 请输入驳回原因
      }
      return callback();
    };

    return {
      ruleForm: {
        rejectionReasons: '',
        passed: 1
      },
      rules: {
        passed: [
          {
            required: true,
            message: this.$t('GlobalSetObj.pleaseChoose'), // 请选择是否通过
            trigger: 'change'
          }
        ],
        rejectionReasons: [
          { validator: passedCheck, trigger: 'blur' }
        ]

      }
    };
  },

  created() {

  },

  methods: {
    onClose(isBool) {
      this.$emit('close', isBool);
    },

    // 提交
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) {
          return false;
        }
        this.asyncApiClaimformRejectAndPass();
      });
    },

    asyncApiClaimformRejectAndPass() {
      const obj = {
        ...this.ruleForm,
        id: this.ids
      };
      apiClaimformRejectAndPass(obj).then(res => {
        this.$message.success('审核完成');
        setTimeout(() => {
          this.onClose(true);
        }, 500);
      }).catch(err => {
        console.error(err);
      });
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
