var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("FormGroup", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.step === 0,
            expression: "step === 0",
          },
        ],
        attrs: {
          "is-edit": true,
          "handle-next": _vm.handleNextStep,
          "handle-previous": _vm.handlePreviousStep,
          "is-last-step": _vm.isLastStep,
          "is-first-step": _vm.isFirstStep,
          "cod-group": _vm.formData.codGroup,
        },
        on: { codGroupChanged: _vm.handleChangeCodGroup },
      }),
      _vm._l(_vm.formData.listEditions, function (edition, index) {
        return _c("FormEdition", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step === index + 1,
              expression: "step === (index + 1)",
            },
          ],
          key: index,
          attrs: {
            "number-edition": index + 1,
            "form-data": edition,
            "handle-next": _vm.handleNextStep,
            "handle-previous": _vm.handlePreviousStep,
            "is-last-step": _vm.isLastStep,
            "is-first-step": _vm.isFirstStep,
          },
        })
      }),
      _c("o-loading", {
        attrs: { "full-page": true, active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }