export const uploadFile = {
  uploadFiles: {
    zh: '上传文件',
    en: 'Upload files',
    pu: 'Upload de arquivos'
  },
  uploadSheet: {
    zh: '上传表格',
    en: 'Upload spreadsheet',
    pu: 'Upload planilha'
  },
  lotNumber: {
    zh: '批号',
    en: 'Lot number',
    pu: 'Número do lote'
  },
  lotNumberPlaceholder: {
    zh: '按批号搜索',
    en: 'Search by lot number',
    pu: 'Pesquise pelo número do lote'
  },
  date: {
    zh: '日期',
    en: 'Date',
    pu: 'Data'
  },
  filesQuantity: {
    zh: '文件数量',
    en: 'Files quantity',
    pu: 'Qtde. Arquivos'
  },
  download: {
    zh: '下载',
    en: 'Download',
    pu: 'Download'
  },
  uploadFileTitle: {
    zh: '上传文件',
    en: 'Upload file',
    pu: 'Upload do arquivo'
  },
  uploadFileParagraph: {
    zh: '填写下面的字段以完成所有编辑文件的上传',
    en: 'Fill in the fields below to complete the upload of the editing files',
    pu: 'Preencha os campos logo abaixo para realizar o todo o upload do arquivo das edições'
  },

  // formUploadFile
  importDate: {
    zh: '导入日期',
    en: 'Import date',
    pu: 'Data da importação'
  },
  editionCode: {
    zh: '版本代码',
    en: 'Edition code',
    pu: 'Cód. Edição'
  },
  dragFile: {
    zh: '拖拽文件到此处或点击上传',
    en: 'Drag file here or click to upload',
    pu: 'Arraste o arquivo aqui ou clique para fazer upload'
  },
  submit: {
    zh: '提交',
    en: 'Submit',
    pu: 'Enviar'
  },
  template: {
    zh: '模板',
    en: '',
    pu: 'Modelo'
  }
};
