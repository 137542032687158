<script >
import { default as FormCreateTransferRoute } from './form/formCreateTransferRoute';
import { default as WrapperHandleError } from '@/components/wrapperHandleError';
import { default as SectionHeader } from '@/components/sectionHeader';
import { default as SkeletonTable } from '@/components/skeletonTable';
import { default as Actions } from './actions.vue';
import { useGetAllTransferRoute } from '@/api/transfer/transferRoute/query';
import { ref, computed, watch, provide } from '@vue/composition-api';
import { FiltersEnum } from '../enum/filtersEnum';
import { useModal } from '@/composable/useModal';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    isOpen: isOpenModalCreate,
    handleVisibility: handleVisibilityModalCreate
  } = useModal();
  const queryParams = ref({
    status: FiltersEnum.ACTIVE,
    currentPage: 1,
    perPage: 10
  });
  const filters = ref({
    status: 0
  });
  const {
    data,
    isError,
    isLoading,
    error,
    refetch
  } = useGetAllTransferRoute(queryParams);
  provide('refetchData', refetch);
  const dataTable = computed(() => {
    return data.value?.data?.result ?? data.value;
  });
  const totalTransferRoute = computed(() => {
    return data.value?.data?.total_routes ?? data.value;
  });
  watch(filters.value, newFilters => {
    if (queryParams.value.currentPage > 1) {
      queryParams.value = {
        ...newFilters,
        currentPage: 1,
        perPage: 10
      };
    } else {
      queryParams.value = {
        ...queryParams.value,
        ...newFilters
      };
    }
  });
  return {
    FiltersEnum,
    isOpenModalCreate,
    handleVisibilityModalCreate,
    queryParams,
    filters,
    isError,
    isLoading,
    error,
    dataTable,
    totalTransferRoute
  };
};
__sfc_main.components = Object.assign({
  WrapperHandleError,
  SectionHeader,
  SkeletonTable,
  Actions,
  FormCreateTransferRoute
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <WrapperHandleError :error="error" :is-error="isError">

    <SectionHeader>

      <template #header>
        <h1 class="header-1">Rotas de transferência</h1>
        <o-button variant="primary" size="md" @click="handleVisibilityModalCreate">Criar rota</o-button>
      </template>

      <template #filter>
        <o-field label="Status da rota" label-for="status" class="flex-1">
          <o-select id="status" v-model="filters.status" placeholder="Selecione o status">
            <option :value="FiltersEnum.ACTIVE">Ativa</option>
            <option :value="FiltersEnum.IN_PROGRESS">Em andamento</option>
            <option :value="FiltersEnum.FINISHED">Finalizados</option>
          </o-select>
        </o-field>
      </template>
    </SectionHeader>

    <SkeletonTable :is-loading="isLoading">
      <o-table
        :data="dataTable"
        class="space-m-y-32"
        paginated
        backend-pagination
        :total="totalTransferRoute"
        :per-page="queryParams.perPage"
        :current-page.sync="queryParams.currentPage"
        :pagination-simple="false"
      >
        <o-table-column
          v-slot="props"
          field="route_identification"
          label="Rota ID"
        >
          {{ props.row.route_identification }}
        </o-table-column>

        <o-table-column labe="" width="144">

          <template slot-scope="{row}">
            <Actions :filters.sync="filters" :row="row" />
          </template>

        </o-table-column>

      </o-table>
    </SkeletonTable>

    <o-modal :active="isOpenModalCreate" width="560" @close="handleVisibilityModalCreate">
      <div>
        <h2 class="header-2">Criando rota</h2>
        <p class="paragraph">Preencha os campos abaixo para criar uma rota de transferência em nosso sistema</p>
      </div>
      <FormCreateTransferRoute :close-modal="handleVisibilityModalCreate" />
    </o-modal>
  </Wrapperhandleerror>

</template>

<style>
  .flex-1{
    flex: 1;
  }
</style>
