<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item :label="$t('newOrder.orderCreateTime')">
        <el-date-picker
          v-model="timeValue"
          :clearable="true"
          :format="$i18n.locale=='zh'?zhDate:ptDate"
          :placeholder="$t('newOrder.SelectDatetime')"
          range-separator="~"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item label="">
        <!--搜索-->
        <el-button icon="el-icon-search" type="primary" @click="getSearchBtn">{{ $t('newOrder.search') }}</el-button>
        <!--重置-->
        <el-button icon="el-icon-refresh-left" type="info" @click="resetForm">{{ $t('newOrder.reset') }}</el-button>
      </el-form-item>
    </el-form>

    <el-row style="margin-bottom: 20px">
      <!--导出-->
      <el-button icon="el-icon-upload2" plain @click="exportToExcel">{{ $t('newOrder.export') }}</el-button>
      <el-radio-group
        v-model="queryForm.params.customerType" size="large" style="margin-left: 20px"
        @change="getSearchBtn"
      >
        <el-radio-button :label="1">{{ $t('newOrder.Formal') }}</el-radio-button>
        <el-radio-button :label="2">{{ $t('newOrder.test1') }}</el-radio-button>
      </el-radio-group>
    </el-row>
    <!--  表格数据  -->
    <div style="overflow: auto">
      <table style="width: 100%;text-align: center;font-size: 14px">
        <thead>
          <tr>
            <th colspan="2" style="min-width: 130px">{{ $t('GlobalSetObj.scannerRate') }}</th>
            <th style="min-width: 90px">{{ $t('orderCenterCont.total') }}</th>
            <th style="min-width: 120px">{{ $t('newOrder.ToBeCollected') }}</th>
            <th style="min-width: 100px">{{ $t('newOrder.Collected') }}</th>
            <th style="min-width: 130px">{{ $t('newOrder.CollectTheRevenuePool') }}</th>
            <th style="min-width: 160px">{{ $t('newOrder.CollectTakeOutWarehouse') }}</th>
            <th style="min-width: 130px">{{ $t('newOrder.TransshipmentIntoStorage') }}</th>
            <th style="min-width: 160px">{{ $t('newOrder.TransferOutWarehouse') }}</th>
            <th style="min-width: 160px">{{ $t('newOrder.DeliveryOutletsIntoStorage') }}</th>
            <th style="min-width: 90px">{{ $t('newOrder.Delivering') }}</th>
            <th style="min-width: 90px">{{ $t('newOrder.Signed') }}</th>
            <th style="min-width: 140px">{{ $t('newOrder.ProblemPiece') }}</th>
            <th style="min-width: 140px">{{ $t('GlobalSetObj.deliveryFailure') }}</th>
            <th style="min-width: 130px">{{ $t('GlobalSetObj.Withdrawn') }}</th>
            <th style="min-width: 130px">{{ $t('GlobalSetObj.TheReturnComplete') }}</th>
          </tr>
        </thead>
        <tbody ref="contentbOdy" />
      </table>
    </div>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 50, 200, 400]"
      :total="total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { trackStatisticalExport, trackStatisticalsiteScanlist } from '@/api/orderApi';
import * as ExcelJS from 'exceljs';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

export default {
  name: 'DotSacnRate',
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      zhDate: 'yyyy-MM-dd',
      ptDate: 'dd-MM-yyyy',
      timeValue: [],
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          customerType: 1 // 1：正式2：测试
        }
      },
      total: 0,
      tableData: [],
      showTableData: [],
      exportData: [],
      columns: []
    };
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getTimer();
  },
  mounted() {
    this.getSearch();
  },
  methods: {
    // 按照搜索条件
    getSearchBtn() {
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    // 导出
    async exportToExcel() {
      const data = {};
      if (this.timeValue) {
        data.beginDate = this.timeValue[0];
        data.endDate = this.timeValue[1];
      }
      // 请求导出数据
      const result = await trackStatisticalExport(data);
      this.exportData = result.data;
      this.exportData.forEach(item => {
        item.siteName = item.siteName ? item.siteName : this.$t('GlobalSetObj.sum');
        if (item.siteName === this.$t('GlobalSetObj.sum') && item.total === 0) {
          item.totalRange = '- -';
          item.waitCollectedRange = '- -';
          item.collectededRange = '- -';
          item.collectInStoragedRange = '- -';
          item.collectOutStorageRange = '- -';
          item.transferInStorageRange = '- -';
          item.transferOutStorageRange = '- -';
          item.deliverySiteInStorageRange = '- -';
          item.deliveringRange = '- -';
          item.signedRange = '- -';
          item.problemRange = '- -';
          item.deliveryFailedRange = '- -';
          item.returningRange = '- -';
          item.returnCompletedRange = '- -';
        } else {
          item.totalRange = 100 + '%';
          item.waitCollectedRange = item.waitCollected ? (item.waitCollected / item.total * 100).toFixed(2) + '%' : '- -';
          item.collectededRange = item.collected ? (item.collected / item.total * 100).toFixed(2) + '%' : '- -';
          item.collectInStoragedRange = item.collectInStorage ? (item.collectInStorage / item.total * 100).toFixed(2) + '%' : '- -';
          item.collectOutStorageRange = item.collectOutStorage ? (item.collectOutStorage / item.total * 100).toFixed(2) + '%' : '- -';
          item.transferInStorageRange = item.transferInStorage ? (item.transferInStorage / item.total * 100).toFixed(2) + '%' : '- -';
          item.transferOutStorageRange = item.transferOutStorage ? (item.transferOutStorage / item.total * 100).toFixed(2) + '%' : '- -';
          item.deliverySiteInStorageRange = item.deliverySiteInStorage ? (item.deliverySiteInStorage / item.total * 100).toFixed(2) + '%' : '- -';
          item.deliveringRange = item.delivering ? (item.delivering / item.total * 100).toFixed(2) + '%' : '- -';
          item.signedRange = item.signed ? (item.signed / item.total * 100).toFixed(2) + '%' : '- -';
          item.problemRange = item.problem ? (item.problem / item.total * 100).toFixed(2) + '%' : '- -';
          item.deliveryFailedRange = item.deliveryFailed ? (item.deliveryFailed / item.total * 100).toFixed(2) + '%' : '- -';
          item.returningRange = item.returning ? (item.returning / item.total * 100).toFixed(2) + '%' : '- -';
          item.returnCompletedRange = item.returnCompleted ? (item.returnCompleted / item.total * 100).toFixed(2) + '%' : '- -';
        }
      });
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('checkTheStock');
      worksheet.properties.defaultRowHeight = 30;
      this.columns =
        [
          {
            width: 80,
            dataIndex: 'siteName',
            key: 'siteName',
            title: this.$t('GlobalSetObj.scannerRate')
          },
          {
            dataIndex: '1',
            key: '1',
            title: this.$t('orderCenterCont.total'),
            children: [
              {
                width: 50,
                dataIndex: 'total',
                key: 'total',
                title: 'qt'
              },
              {
                width: 50,
                dataIndex: 'totalRange',
                key: 'totalRange',
                title: '%'
              }
            ]
          },
          {
            dataIndex: '2',
            key: '2',
            title: this.$t('newOrder.stayLanShou'),
            children: [
              {
                width: 50,
                dataIndex: 'waitCollected',
                key: 'waitCollected',
                title: 'qt'
              },
              {
                width: 50,
                dataIndex: 'waitCollectedRange',
                key: 'waitCollectedRange',
                title: '%'
              }
            ]
          },
          {
            dataIndex: '3',
            key: '3',
            title: this.$t('newOrder.Collected'),
            children: [
              {
                width: 50,
                dataIndex: 'collected',
                key: 'collected',
                title: 'qt'
              },
              {
                width: 50,
                dataIndex: 'collectededRange',
                key: 'collectededRange',
                title: '%'
              }
            ]
          },
          {
            dataIndex: '4',
            key: '4',
            title: this.$t('newOrder.CollectTheRevenuePool'),
            children: [
              {
                width: 50,
                dataIndex: 'collectInStorage',
                key: 'collectInStorage',
                title: 'qt'
              },
              {
                width: 50,
                dataIndex: 'collectInStoragedRange',
                key: 'collectInStoragedRange',
                title: '%'
              }
            ]
          },
          {
            dataIndex: '5',
            key: '5',
            title: this.$t('newOrder.CollectTakeOutWarehouse'),
            children: [
              {
                width: 50,
                dataIndex: 'collectOutStorage',
                key: 'collectOutStorage',
                title: 'qt'
              },
              {
                width: 50,
                dataIndex: 'collectOutStorageRange',
                key: 'collectOutStorageRange',
                title: '%'
              }
            ]
          },
          {
            dataIndex: '6',
            key: '6',
            title: this.$t('newOrder.TransshipmentIntoStorage'),
            children: [
              {
                width: 50,
                dataIndex: 'transferInStorage',
                key: 'transferInStorage',
                title: 'qt'
              },
              {
                width: 50,
                dataIndex: 'transferInStorageRange',
                key: 'transferInStorageRange',
                title: '%'
              }
            ]
          },
          {
            dataIndex: '7',
            key: '7',
            title: this.$t('newOrder.TransferOutWarehouse'),
            children: [
              {
                width: 50,
                dataIndex: 'transferOutStorage',
                key: 'transferOutStorage',
                title: 'qt'
              },
              {
                width: 50,
                dataIndex: 'transferOutStorageRange',
                key: 'transferOutStorageRange',
                title: '%'
              }
            ]
          },
          {
            dataIndex: '8',
            key: '8',
            title: this.$t('newOrder.DeliveryOutletsIntoStorage'),
            children: [
              {
                width: 50,
                dataIndex: 'deliverySiteInStorage',
                key: 'deliverySiteInStorage',
                title: 'qt'
              },
              {
                width: 50,
                dataIndex: 'deliverySiteInStorageRange',
                key: 'deliverySiteInStorageRange',
                title: '%'
              }
            ]
          },
          {
            dataIndex: '9',
            key: '9',
            title: this.$t('newOrder.Delivering'),
            children: [
              {
                width: 50,
                dataIndex: 'delivering',
                key: 'delivering',
                title: 'qt'
              },
              {
                width: 50,
                dataIndex: 'deliveringRange',
                key: 'deliveringRange',
                title: '%'
              }
            ]
          },
          {
            dataIndex: '10',
            key: '10',
            title: this.$t('newOrder.Signed'),
            children: [
              {
                width: 50,
                dataIndex: 'signed',
                key: 'signed',
                title: 'qt'
              },
              {
                width: 50,
                dataIndex: 'signedRange',
                key: 'signedRange',
                title: '%'
              }
            ]
          },
          {
            dataIndex: '11',
            key: '11',
            title: this.$t('newOrder.ProblemPiece'),
            children: [
              {
                width: 50,
                dataIndex: 'problem',
                key: 'problem',
                title: 'qt'
              },
              {
                width: 50,
                dataIndex: 'problemRange',
                key: 'problemRange',
                title: '%'
              }
            ]
          },
          {
            dataIndex: '12',
            key: '12',
            title: this.$t('GlobalSetObj.deliveryFailure'),
            children: [
              {
                width: 50,
                dataIndex: 'deliveryFailed',
                key: 'deliveryFailed',
                title: 'qt'
              },
              {
                width: 50,
                dataIndex: 'deliveryFailedRange',
                key: 'deliveryFailedRange',
                title: '%'
              }
            ]
          },
          {
            dataIndex: '13',
            key: '13',
            title: this.$t('GlobalSetObj.Withdrawn'),
            children: [
              {
                width: 50,
                dataIndex: 'returning',
                key: 'returning',
                title: 'qt'
              },
              {
                width: 50,
                dataIndex: 'returningRange',
                key: 'returningRange',
                title: '%'
              }
            ]
          },
          {
            dataIndex: '14',
            key: '14',
            title: this.$t('GlobalSetObj.TheReturnComplete'),
            children: [
              {
                width: 50,
                dataIndex: 'returnCompleted',
                key: 'returnCompleted',
                title: 'qt'
              },
              {
                width: 50,
                dataIndex: 'returnCompletedRange',
                key: 'returnCompletedRange',
                title: '%'
              }
            ]
          }
        ];
      const headers = this.generateHeaders(this.columns);
      console.log(headers, 'headersheaders');
      // 第一行表头
      const names1 = [];
      // 第二行表头
      const names2 = [];
      // 用于匹配数据的 keys
      const headerKeys = [];
      headers.forEach(item => {
        if (item.children) {
          // 有 children 说明是多级表头，header name 需要两行
          item.children.forEach(child => {
            names1.push(item.header);
            names2.push(child.header);
            headerKeys.push(child.key);
          });
        } else {
          const columnNumber = this.getColumnNumber(item.width);
          for (let i = 0; i < columnNumber; i++) {
            names1.push(item.header);
            names2.push(item.header);
            headerKeys.push(item.key);
          }
        }
      });
      this.handleHeader(worksheet, headers, names1, names2);
      // 添加数据
      this.addData2Table(worksheet, headerKeys, headers);
      // 给每列设置固定宽度
      worksheet.columns = worksheet.columns.map(col => ({ ...col, width: 15 }));
      // 将工作表转换为二进制数据流
      workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const saveName = this.$t('GlobalSetObj.dotScanRate') + '.xlsx';
        saveAs(blob, saveName);
        // const url = URL.createObjectURL(blob);
        // const link = document.createElement('a');
        // link.href = url;
        // link.download = this.$t('GlobalSetObj.dotScanRate') + '.xlsx';
        // link.click();
        this.$message.success(this.$t('collectionCenter.ExportSuccessful1'));
      });
    },
    addData2Table(worksheet, headerKeys, headers) {
      this.exportData.forEach((item) => {
        const rowData = headerKeys?.map(key => item[key]);
        const row = worksheet.addRow(rowData);
        this.mergeRowCell(headers, row, worksheet);
        row.height = 30;
        // 设置行样式, wrapText: 自动换行
        row.alignment = { vertical: 'middle', wrapText: true, shrinkToFit: false };
        row.font = { size: 12, name: '微软雅黑' };
      });
    },
    generateHeaders(columns) {
      return columns.map(col => {
        const obj = {
          // 显示的 name
          header: col.title,
          // 用于数据匹配的 key
          key: col.dataIndex,
          // 列宽
          width: col.width / 5 || 20
        };
        if (col.children) {
          obj.children = col.children?.map((item) => ({
            key: item.dataIndex,
            header: item.title,
            width: item.width,
            parentKey: col.dataIndex
          }));
        }
        return obj;
      });
    },
    getColumnNumber(width) {
      // 需要的列数，四舍五入
      return Math.round(width / 20);
    },
    handleHeader(
      worksheet,
      headers,
      names1,
      names2
    ) {
      // 判断是否有 children, 有的话是两行表头
      const isMultiHeader = headers?.some(item => item.children);
      if (isMultiHeader) {
        // 加表头数据
        const rowHeader1 = worksheet.addRow(names1);
        const rowHeader2 = worksheet.addRow(names2);
        // 添加表头样式
        this.addHeaderStyle(rowHeader1, { color: '339966' });
        this.addHeaderStyle(rowHeader2, { color: '339966' });
        this.mergeColumnCell(headers, rowHeader1, rowHeader2, names1, names2, worksheet);
        return;
      }
      // 加表头数据
      const rowHeader = worksheet.addRow(names1);
      // 表头根据内容宽度合并单元格
      this.mergeRowCell(headers, rowHeader, worksheet);
      // 添加表头样式
      this.addHeaderStyle(rowHeader, { color: '339966' });
    },
    addHeaderStyle(row, attr) {
      const { color, fontSize, horizontal, bold } = attr || {};
      // eslint-disable-next-line no-param-reassign
      row.height = 20;
      row.eachCell((cell, colNumber) => {
        // eslint-disable-next-line no-param-reassign
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color }
        };
        // eslint-disable-next-line no-param-reassign
        cell.font = {
          bold: bold ?? true,
          size: fontSize ?? 12,
          name: '微软雅黑'
        };
        // eslint-disable-next-line no-param-reassign
        cell.alignment = { vertical: 'middle', wrapText: true, horizontal: horizontal ?? 'left' };
      });
    },
    // 合并行和列，用于处理表头合并
    mergeColumnCell(
      headers,
      rowHeader1,
      rowHeader2,
      nameRow1,
      nameRow2,
      worksheet
    ) {
      // 当前 index 的指针
      let pointer = -1;
      nameRow1.forEach((name, index) => {
        // 当 index 小于指针时，说明这一列已经被合并过了，不能再合并
        if (index <= pointer) return;
        // 是否应该列合并
        const shouldVerticalMerge = name === nameRow2[index];
        // 是否应该行合并
        const shouldHorizontalMerge = index !== nameRow1.lastIndexOf(name);
        pointer = nameRow1.lastIndexOf(name);
        if (shouldVerticalMerge && shouldHorizontalMerge) {
          // 两个方向都合并
          worksheet.mergeCells(
            Number(rowHeader1.number),
            index + 1,
            Number(rowHeader2.number),
            nameRow1.lastIndexOf(name) + 1
          );
        } else if (shouldVerticalMerge && !shouldHorizontalMerge) {
          // 只在垂直方向上同一列的两行合并
          worksheet.mergeCells(Number(rowHeader1.number), index + 1, Number(rowHeader2.number), index + 1);
        } else if (!shouldVerticalMerge && shouldHorizontalMerge) {
          // 只有水平方向同一行的多列合并
          worksheet.mergeCells(
            Number(rowHeader1.number),
            index + 1,
            Number(rowHeader1.number),
            nameRow1.lastIndexOf(name) + 1
          );
          // eslint-disable-next-line no-param-reassign
          const cell = rowHeader1.getCell(index + 1);
          cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        }
      });
    },
    // 行合并单元格
    mergeRowCell(headers, row, worksheet) {
      // 当前列的索引
      let colIndex = 1;
      headers.forEach(header => {
        const { width, children } = header;
        if (children) {
          children.forEach(child => {
            colIndex += 1;
          });
        } else {
          // 需要的列数，四舍五入
          const colNum = this.getColumnNumber(width);
          // 如果 colNum > 1 说明需要合并
          if (colNum > 1) {
            worksheet.mergeCells(Number(row.number), colIndex, Number(row.number), colIndex + colNum - 1);
          }
          colIndex += colNum;
        }
      });
    },
    // 查询
    getSearch() {
      // 获取表格体
      const body = this.$refs.contentbOdy;
      const data = {
        pageSize: this.queryForm.pageSize,
        pageNumber: this.queryForm.pageNumber,
        params: {
          beginDate: '',
          endDate: '',
          customerType: this.queryForm.params.customerType
        }
      };
      if (this.timeValue) {
        data.params.beginDate = this.timeValue[0];
        data.params.endDate = this.timeValue[1];
      }
      trackStatisticalsiteScanlist(data).then(res => {
        if (res.status === 'OK') {
          this.total = res.data.total;
          this.tableData = res.data.records;
          let tablebody = '';
          // 转换数据格式
          // const transformedData = this.tableData.map(item => [item]);
          // console.log(this.tableData, 'resresres', transformedData);
          this.tableData.forEach(item => {
            item.siteName = item.siteName ? item.siteName : this.$t('GlobalSetObj.sum');
            if (item.total === 0) {
              item.totalRange = '- -';
              item.waitCollectedRange = '- -';
              item.collectededRange = '- -';
              item.collectInStoragedRange = '- -';
              item.collectOutStorageRange = '- -';
              item.transferInStorageRange = '- -';
              item.transferOutStorageRange = '- -';
              item.deliverySiteInStorageRange = '- -';
              item.deliveringRange = '- -';
              item.signedRange = '- -';
              item.problemRange = '- -';
              item.deliveryFailedRange = '- -';
              item.returningRange = '- -';
              item.returnCompletedRange = '- -';
            } else {
              item.totalRange = 100 + '%';
              item.waitCollectedRange = item.waitCollected ? (item.waitCollected / item.total * 100).toFixed(2) + '%' : '- -';
              item.collectededRange = item.collected ? (item.collected / item.total * 100).toFixed(2) + '%' : '- -';
              item.collectInStoragedRange = item.collectInStorage ? (item.collectInStorage / item.total * 100).toFixed(2) + '%' : '- -';
              item.collectOutStorageRange = item.collectOutStorage ? (item.collectOutStorage / item.total * 100).toFixed(2) + '%' : '- -';
              item.transferInStorageRange = item.transferInStorage ? (item.transferInStorage / item.total * 100).toFixed(2) + '%' : '- -';
              item.transferOutStorageRange = item.transferOutStorage ? (item.transferOutStorage / item.total * 100).toFixed(2) + '%' : '- -';
              item.deliverySiteInStorageRange = item.deliverySiteInStorage ? (item.deliverySiteInStorage / item.total * 100).toFixed(2) + '%' : '- -';
              item.deliveringRange = item.delivering ? (item.delivering / item.total * 100).toFixed(2) + '%' : '- -';
              item.signedRange = item.signed ? (item.signed / item.total * 100).toFixed(2) + '%' : '- -';
              item.problemRange = item.problem ? (item.problem / item.total * 100).toFixed(2) + '%' : '- -';
              item.deliveryFailedRange = item.deliveryFailed ? (item.deliveryFailed / item.total * 100).toFixed(2) + '%' : '- -';
              item.returningRange = item.returning ? (item.returning / item.total * 100).toFixed(2) + '%' : '- -';
              item.returnCompletedRange = item.returnCompleted ? (item.returnCompleted / item.total * 100).toFixed(2) + '%' : '- -';
            }
            tablebody += `<tr style="${item.siteName === this.$t('GlobalSetObj.sum') ? 'background-color: #efefef;border: 1px solid #ebeef5;padding: 5px;' : 'border: 1px solid #ebeef5;padding: 5px;'}">
            <td rowspan="2">${item.siteName || '--'}</td>
            <td style="border: 1px solid #ebeef5;height: 40px;width: 50px">qt</td>
            <td style="border: 1px solid #ebeef5;height: 40px;">${item.total}</td>
            <td style="border: 1px solid #ebeef5;height: 40px;">${item.waitCollected}</td>
            <td style="border: 1px solid #ebeef5;height: 40px;">${item.collected}</td>
            <td style="border: 1px solid #ebeef5;height: 40px;">${item.collectInStorage}</td>
            <td style="border: 1px solid #ebeef5;height: 40px;">${item.collectOutStorage}</td>
            <td style="border: 1px solid #ebeef5;height: 40px;">${item.transferInStorage}</td>
            <td style="border: 1px solid #ebeef5;height: 40px;">${item.transferOutStorage}</td>
            <td style="border: 1px solid #ebeef5;height: 40px;">${item.deliverySiteInStorage}</td>
            <td style="border: 1px solid #ebeef5;height: 40px;">${item.delivering}</td>
            <td style="border: 1px solid #ebeef5;height: 40px;">${item.signed}</td>
            <td style="border: 1px solid #ebeef5;height: 40px;">${item.problem}</td>
            <td style="border: 1px solid #ebeef5;height: 40px;">${item.deliveryFailed}</td>
            <td style="border: 1px solid #ebeef5;height: 40px;">${item.returning}</td>
             <td style="border: 1px solid #ebeef5;height: 40px;">${item.returnCompleted}</td>
          </tr>
          <tr style="${item.siteName === this.$t('GlobalSetObj.sum') ? 'background-color: #efefef;border: 1px solid #ebeef5;padding: 5px;' : 'border: 1px solid #ebeef5;padding: 5px;'}">
            <td style="border: 1px solid #ebeef5;height: 40px;width: 50px">%</td>
            <td style="border: 1px solid #ebeef5;height: 40px;">${item.totalRange}</td>
            <td style="border: 1px solid #ebeef5;height: 40px;">${item.waitCollectedRange}</td>
            <td style="border: 1px solid #ebeef5;height: 40px;">${item.collectededRange}</td>
            <td style="border: 1px solid #ebeef5;height: 40px;">${item.collectInStoragedRange}</td>
            <td style="border: 1px solid #ebeef5;height: 40px;">${item.collectOutStorageRange}</td>
            <td style="border: 1px solid #ebeef5;height: 40px;">${item.transferInStorageRange}</td>
            <td style="border: 1px solid #ebeef5;height: 40px;">${item.transferOutStorageRange}</td>
            <td style="border: 1px solid #ebeef5;height: 40px;">${item.deliverySiteInStorageRange}</td>
            <td style="border: 1px solid #ebeef5;height: 40px;">${item.deliveringRange}</td>
            <td style="border: 1px solid #ebeef5;height: 40px;">${item.signedRange}</td>
            <td style="border: 1px solid #ebeef5;height: 40px;">${item.problemRange}</td>
            <td style="border: 1px solid #ebeef5;height: 40px;">${item.deliveryFailedRange}</td>
            <td style="border: 1px solid #ebeef5;height: 40px;">${item.returningRange}</td>
            <td style="border: 1px solid #ebeef5;height: 40px;">${item.returnCompletedRange}</td>
          </tr>`;
          });
          this.$nextTick(() => {
            body.innerHTML = tablebody;
          });
          console.log(this.tableData, 'resresres');
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    },
    // 重置
    resetForm() {
      this.queryForm.pageNumber = 1;
      this.queryForm.params.customerType = 1; // 1：正式2：测试
      this.getTimer();
      this.getSearch();
    },
    // 获取最近一星期时间
    getTimer() {
      const day = new Date();
      const brazilTime = day.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
      const today = dayjs(brazilTime).format('YYYY-MM-DD');
      const oneWeekAgo = dayjs(brazilTime).subtract(1, 'days').format('YYYY-MM-DD');
      this.timeValue = [oneWeekAgo, today];
    }
  }
};
</script>

<style scoped>
.container {
  padding: 10px;
}

table {
  border-collapse: collapse;
  border: 1px solid #ebeef5;
}

th, td {
  border: 1px solid #ebeef5;
  padding: 5px;
}

th {
  height: 50px;
  background-color: #cecece;
  color: #3d3637;
  min-width: 180px;
}

td {
  height: 40px;
}
</style>
