<template>
  <div class="add">
    <el-form
      ref="ruleForm" :model="ruleForm" :rules="rules"
      label-width="120px" class="demo-ruleForm"
      size="small"
    >
      <el-form-item :label="$t('routes.班次编号')" prop="scheduleNumber">
        <el-input
          v-model="ruleForm.scheduleNumber" type="textarea" :autosize="{ minRows: 2, maxRows: 4}"
          maxlength="50"
          show-word-limit
          :placeholder="$t('GlobalSetObj.pleaseInput')"
        />
      </el-form-item>

      <!-- 揽收时间 $t('newOrder.lanshouT')-->
      <el-form-item label="" prop="collectTimeList">
        <AddTimeCmp ref="addTimeRef" />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">{{ $t('customerManagements.Save') }}</el-button>
        <el-button @click="onClose(false)">{{ $t('GlobalSetObj.cancel') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import AddTimeCmp from './AddTimeCmp.vue';
import { apiDepartureScheduleModify } from '@/api/routerPlan.js';
import { COLLECT_TIME_ROW, SCHEDULE_ROW } from '../utils';
import { cloneDeep } from 'lodash-es';

/** @typedef {import('../types').TCollectTime} TCollectTime */

export default {
  name: '',

  components: {
    AddTimeCmp
  },

  props: {
    index: {
      type: Number,
      default: 0
    },

    // 当前行的数据
    row: {
      type: Object,
      default: () => {
        return cloneDeep(SCHEDULE_ROW);
      }
    }
  },

  data() {
    return {
      ruleForm: {
        scheduleNumber: '',
        collectTimeList: []
      },
      rules: {
        scheduleNumber: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'blur' }
        ],

        collectTimeList: [{
          required: true,
          trigger: 'change',
          validator: (rule, value, callback) => {
            // console.log(Array.isArray(value), '====pppp');
            //
            // if (!Array.isArray(value) || value.length === 0) {
            //   // 请选择
            //   return callback(new Error(this.$t('GlobalSetObj.pleaseChoose')));
            // }
            const isBool = this.$refs.addTimeRef.submitForm();

            if (!isBool) {
              return callback(new Error(''));
            }
            callback();
          }
        }
        ]
      }
    };
  },

  watch: {
    index: {
      handler(val) {
        const { id } = this.row;
        let arr = [COLLECT_TIME_ROW];

        // 编辑
        if (id) {
          const { scheduleNumber, collectTimeList } = this.row;
          console.log(collectTimeList, '===collectTimeListssss');
          this.ruleForm.scheduleNumber = scheduleNumber;
          arr = collectTimeList.map(item => {
            const { collectTime, collectLoadFinishTime, hub1ArrivalTime } = item;
            return {
              startCollect: collectTime[0],
              endCollect: collectTime[1],
              collectLoadFinishTime,
              hub1ArrivalTime
            };
          });
        } else {
          // 新增
          this.ruleForm.scheduleNumber = '';
        }
        this.ruleForm.collectTimeList = arr;
        setTimeout(() => {
          this.$refs.addTimeRef.setData(arr);
        }, 100);
      },
      immediate: true
    }

    // 'row.id': {
    //   handler(val) {
    //     let arr = [COLLECT_TIME_ROW];

    //     // 编辑
    //     if (val) {
    //       const { scheduleNumber, collectTimeList } = this.row;
    //       this.ruleForm.scheduleNumber = scheduleNumber;
    //       arr = collectTimeList.map(item => {
    //         const { collectTime, collectLoadFinishTime, hub1ArrivalTime } = item;
    //         return {
    //           collectTime,
    //           collectLoadFinishTime,
    //           hub1ArrivalTime
    //         };
    //       });
    //     } else {
    //       // 新增
    //       this.ruleForm.scheduleNumber = '';
    //     }
    //     this.ruleForm.collectTimeList = arr;
    //     setTimeout(() => {
    //       this.$refs.addTimeRef.setData(arr);
    //     }, 100);
    //   },

    //   immediate: true
    // }
  },

  created() {

  },

  methods: {
    getData() {
      const { id } = this.row;
      const { scheduleNumber, collectTimeList } = this.ruleForm;

      const obj = {
        id: id || undefined,
        scheduleNumber,
        collectTimeList
      };

      return obj;
    },

    submitForm(formName) {
      this.ruleForm.collectTimeList = this.$refs.addTimeRef.getData();
      console.log(this.ruleForm.collectTimeList);

      // 校验
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return;
        }
        if (this.ruleForm.collectTimeList !== null) {
          // 新增 / 编辑
          this.asyncApiDepartureScheduleModify();
        }
      });
    },

    // 新增编辑
    asyncApiDepartureScheduleModify() {
      const obj = this.getData();
      apiDepartureScheduleModify(obj).then(res => {
        this.$message.success(this.$t('GlobalSetObj.SaveSuccessfully'));

        setTimeout(() => {
          this.onClose(true);
        }, 1000);
      }).catch(err => {
        console.error(err);
      });
    },

    onClose(isBool) {
      this.$emit('close', isBool);
    }
  }

};
</script>

<style scoped>
.add {
  height: 70vh;
  overflow-y: scroll;
  /* border: 1px solid #000; */
}
</style>
