<template>
  <div style="margin: -15px;">
    <div style="height: calc(100vh - 100px); overflow-y: auto;padding: 15px;">
      <el-form ref="formData" :model="formData" label-width="200px">
        <!--结算类型-->
        <el-form-item
          :label="$t('GlobalSetObj.settlementType') + '：'"
          prop="paymentType"
          :rules="[
            { required: true, message: $t('GlobalSetObj.pleaseChoose'), trigger: ['blur', 'change'] }
          ]"
        >
          <el-select
            v-model="formData.paymentType"
            :placeholder="$t('GlobalSetObj.pleaseChoose')"
            class="form-bottom"
            filterable
            clearable
            style="width: 400px;"
          >
            <el-option key="0" label="RPA" value="RPA" />
            <el-option key="1" label="CNPJ" value="CNPJ" />
          </el-select>
        </el-form-item>
        <el-card>
          <div style="margin-bottom: 10px">
            <!--PIX转账-->
            <span>{{ 'PIX' + $t('basicData.transferable') }}</span>
          </div>
          <!--PIX账号类型-->
          <el-form-item
            :label="'PIX' + $t('receivablePayable.accountType') + '：'"
            prop="dictCodeType"
            :rules="[
              { required: true, message: $t('GlobalSetObj.pleaseChoose'), trigger: ['blur', 'change'] }
            ]"
          >
            <el-select
              v-model="formData.dictCodeType"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              class="form-bottom"
              filterable
              clearable
              style="width: 400px;"
            >
              <el-option key="0" label="CPF" value="CPF" />
              <el-option key="1" label="CNPJ" value="CNPJ" />
              <el-option key="2" label="MOBILE PHONE" value="MOBILE PHONE" />
              <el-option key="3" label="EMAIL" value="EMAIL" />
              <el-option key="4" label="EVP" value="EVP" />
            </el-select>
          </el-form-item>
          <!--PIX账号-->
          <el-form-item
            :label="'PIX' + $t('customerManagements.account') + '：'"
            prop="dictCode"
            :rules="[
              { required: true, message: $t('GlobalSetObj.pleaseInput'), trigger: ['blur', 'change'] }
            ]"
          >
            <el-input
              v-model="formData.dictCode"
              clearable
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              style="width: 400px;"
            />
          </el-form-item>
        </el-card>
        <el-card style="margin-top: 10px;">
          <div style="margin-bottom: 10px">
            <!--银行转账-->
            <span>{{ $t('basicData.bankTransfer') }}</span>
          </div>
          <!--收款名称-->
          <el-form-item
            :label="$t('basicData.ReceivingName') + '：'"
            prop="payeeName"
            :rules="[
              { required: true, message: $t('GlobalSetObj.pleaseInput'), trigger: ['blur', 'change'] }
            ]"
          >
            <el-input
              v-model="formData.payeeName"
              clearable
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              style="width: 400px;"
            />
          </el-form-item>
          <!--证件类型-->
          <el-form-item
            :label="$t('orderCenterCont.CertificateType') + '：'"
            prop="documentType"
            :rules="[
              { required: true, message: $t('GlobalSetObj.pleaseChoose'), trigger: ['blur', 'change'] }
            ]"
          >
            <el-select
              v-model="formData.documentType"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              class="form-bottom"
              filterable
              clearable
              style="width: 400px;"
            >
              <el-option key="0" label="CPF" value="CPF" />
              <el-option key="1" label="CNPJ" value="CNPJ" />
            </el-select>
          </el-form-item>
          <!--证件号码-->
          <el-form-item
            :label="$t('basicData.CertificateNumber') + '：'"
            prop="documentNumber"
            :rules="[
              { required: true, validator: validateNumber, trigger: ['blur', 'change'] }
            ]"
          >
            <el-input
              v-model="formData.documentNumber"
              clearable
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              style="width: 400px;"
            />
          </el-form-item>
          <!--银行编号-->
          <el-form-item
            :label="$t('basicData.BankNumber') + '：'"
            prop="bankCode"
            :rules="[
              { required: true, message: $t('GlobalSetObj.pleaseChoose'), trigger: ['blur', 'change'] }
            ]"
          >
            <el-select
              v-model="formData.bankCode"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              class="form-bottom"
              filterable
              clearable
              style="width: 400px;"
            >
              <el-option
                v-for="item in bankList"
                :key="item.id"
                :label="item.code + '（' + item.name + '）-' + item.ispd"
                :value="item.code"
              />
            </el-select>
          </el-form-item>
          <!--分行号码-->
          <el-form-item
            :label="$t('basicData.BranchNumber') + '：'"
            prop="branch"
            :rules="[
              { required: true, validator: validateNumber, trigger: ['blur', 'change'] }
            ]"
          >
            <el-input
              v-model="formData.branch"
              clearable
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              style="width: 400px;"
            />
          </el-form-item>
          <!--收款账户-->
          <el-form-item
            :label="$t('basicData.ReceivingAccount') + '：'"
            prop="bankNumber"
            :rules="[
              { required: true, validator: validateNumber, trigger: ['blur', 'change'] }
            ]"
          >
            <el-input
              v-model="formData.bankNumber"
              clearable
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              style="width: 400px;"
            />
          </el-form-item>
        </el-card>
      </el-form>
    </div>
    <div style="padding: 10px;text-align: right;border-top: 1px solid #ccc;">
      <el-button @click="onClose()">{{ $t('GlobalSetObj.cancel') }}</el-button>
      <el-button
        v-permit:remove="'but:finance:basicData:payable:dispatchAccountsPayable:edit'"
        type="primary"
        @click="submitForm">
        {{ $t('basicData.Save') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { apiDeliveryPayableAccountEdit } from '@/api/newFinance';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'PaymentData',
  props: {
    bankList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    detailData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    // 0-9的数字
    const validateNumber = (rule, value, callback) => {
      const regex = /^[0-9]*$/;
      if (rule.required) {
        if (value === '' || value === null || value === undefined) {
          callback(new Error(this.$t('GlobalSetObj.pleaseInput')));
        } else {
          if (!regex.test(value)) {
            callback(new Error(this.$t('collectionCenter.inputNum')));
          } else {
            callback();
          }
        }
      } else if (value !== '' && value !== null && value !== undefined) {
        if (!regex.test(value)) {
          callback(new Error(this.$t('collectionCenter.inputNum')));
        } else {
          callback();
        }
      }
    };
    return {
      formData: {
        id: this.detailData.deliveryPayableAccountCondition.id,
        paymentType: null, // 结算类型
        dictCodeType: '', // PIX账号类型
        dictCode: '', // PIX账号
        payeeName: '', // 收款名称
        documentType: null, // 证件类型
        documentNumber: '', // 证件号码
        bankCode: '', // 银行编号
        branch: '', // 分行号码
        bankNumber: '' // 收款账户
      },
      validateNumber: validateNumber
    };
  },
  created() {
    const obj = cloneDeep(this.detailData);
    this.formData = {
      id: obj.id,
      paymentType: obj.settlementType ? obj.settlementType : null, // 结算类型
      dictCodeType: obj.dictCodeType ? obj.dictCodeType : null, // PIX账号类型
      dictCode: obj.dictCode ? obj.dictCode : null, // PIX账号
      payeeName: obj.payeeName ? obj.payeeName : '', // 收款名称
      documentType: obj.documentType ? obj.documentType : null, // 证件类型
      documentNumber: obj.documentNumber ? obj.documentNumber : '', // 证件号码
      bankCode: obj.bankCode ? obj.bankCode : '', // 银行编号
      branch: obj.branch ? obj.branch : '', // 分行号码
      bankNumber: obj.bankNumber ? obj.bankNumber : '' // 收款账户
    };
  },
  methods: {
    // 关闭
    onClose() {
      this.bus.$emit('onClose', false);
    },
    // 保存
    submitForm() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const params = {
            id: this.formData.id,
            paymentType: this.formData.paymentType,
            dictCodeType: this.formData.dictCodeType,
            dictCode: this.formData.dictCode,
            payeeName: this.formData.payeeName,
            documentType: this.formData.documentType,
            documentNumber: this.formData.documentNumber,
            bankCode: this.formData.bankCode,
            branch: this.formData.branch,
            bankNumber: this.formData.bankNumber
          };
          apiDeliveryPayableAccountEdit(params).then(res => {
            if (res.code === 200) {
              this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
              this.bus.$emit('onClose', true);
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped>

</style>
