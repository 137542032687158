var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container po" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.permissionOpt.title,
            visible: _vm.permissionOpt.visible,
            width: "30%",
            "close-on-click-modal": true,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.permissionOpt, "visible", $event)
            },
            open: _vm.openPermissionOpt,
            close: _vm.closePermissionOpt,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "permissionRef",
              attrs: {
                model: _vm.permissonForm,
                rules: _vm.permissonRules,
                "label-width": "100px",
                "label-position": "left",
              },
            },
            [
              _vm.permissionOpt.type !== "add"
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("roleManage.permissionsID"),
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.permissonForm.id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.permissonForm, "id", $$v)
                                  },
                                  expression: "permissonForm.id",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.permissonForm.parentId !== 0,
                      expression: "permissonForm.parentId!==0",
                    },
                  ],
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("roleManage.parentPermissionID"),
                            prop: "parentId",
                          },
                        },
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false",
                              },
                            ],
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.permissonForm.parentId,
                              callback: function ($$v) {
                                _vm.$set(_vm.permissonForm, "parentId", $$v)
                              },
                              expression: "permissonForm.parentId",
                            },
                          }),
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.permissionOpt.parentName,
                              callback: function ($$v) {
                                _vm.$set(_vm.permissionOpt, "parentName", $$v)
                              },
                              expression: "permissionOpt.parentName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("roleManage.permissionsName"),
                            prop: "name",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { autocomplete: "off" },
                            model: {
                              value: _vm.permissonForm.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.permissonForm,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "permissonForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("roleManage.permissionsTag"),
                            prop: "code",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { autocomplete: "off" },
                            model: {
                              value: _vm.permissonForm.code,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.permissonForm,
                                  "code",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "permissonForm.code",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("roleManage.sort"),
                            prop: "sort",
                            rules: [
                              {
                                required: true,
                                message: _vm.$t("roleManage.sortNotEmpty"),
                              },
                              {
                                type: "number",
                                message: _vm.$t("roleManage.sortIsNumber"),
                              },
                            ],
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "sort",
                              placeholder: _vm.$t(
                                "roleManage.littleValueFront"
                              ),
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.permissonForm.sort,
                              callback: function ($$v) {
                                _vm.$set(_vm.permissonForm, "sort", _vm._n($$v))
                              },
                              expression: "permissonForm.sort",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("roleManage.note") } },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea", autocomplete: "off" },
                            model: {
                              value: _vm.permissonForm.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.permissonForm, "remark", $$v)
                              },
                              expression: "permissonForm.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { size: "large" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.onSubmit },
                            },
                            [_vm._v(_vm._s(_vm.$t("roleManage.confirm")))]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  _vm.permissionOpt.visible = false
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("roleManage.cancel")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }