<template>
  <div>

    <!-- 搜索栏 -->
    <el-card style="margin-bottom: 20px;">
      <HeadCmp @change="onChange" @query="onQuery" @reset="onReset" />
    </el-card>

    <el-card>
      <!-- 申请理赔 -->
      <el-button
        type="primary"
        size="small"
        style="margin-bottom: 20px;"
        @click="() =>onAddClaims(0)"
      >{{ $t('ClaimsManagement.ApplyForClaim') }}</el-button>
      <!-- 导出 -->
      <el-button
        type="info"
        size="small"
        @click="onExport"
      >{{ $t('CenterForSorting.export') }}</el-button>

      <el-table
        :data="tableData"
        border
        style="width: 100%;min-height: 300px;"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <!--序号-->
        <el-table-column :label="$t('operationCenter.ind')" width="60px" align="center">
          <template slot-scope="scope">
            <span>{{ (query.pageNumber - 1) * query.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>

        <!-- 标题 -->
        <el-table-column :label="$t('customerManagements.title')" prop="title" width="180" />
        <!-- 理赔单号 -->
        <el-table-column :label="$t('ClaimsManagement.ClaimNumber')" prop="claimsNumber" width="140">
          <template slot-scope="scope">
            <el-link type="primary" @click="onOpen(scope.row)">{{ scope.row.claimsNumber }}</el-link>
          </template>
        </el-table-column>
        <!-- 理赔状态 -->
        <el-table-column :label="$t('ClaimsManagement.ClaimStatus')" prop="claimsStatus" width="150">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.claimsStatus === CLAIMS_STATUS_SELECT_OBJ['0'].value">{{ CLAIMS_STATUS_SELECT_OBJ['0'].name }}</el-tag>
            <el-tag v-else-if="scope.row.claimsStatus === CLAIMS_STATUS_SELECT_OBJ['1'].value">{{ CLAIMS_STATUS_SELECT_OBJ['1'].name }}</el-tag>
            <el-tag v-else-if="scope.row.claimsStatus === CLAIMS_STATUS_SELECT_OBJ['2'].value" type="danger">{{ CLAIMS_STATUS_SELECT_OBJ['2'].name }}</el-tag>
            <el-tag v-else-if="scope.row.claimsStatus === CLAIMS_STATUS_SELECT_OBJ['3'].value">{{ CLAIMS_STATUS_SELECT_OBJ['3'].name }}</el-tag>
            <el-tag v-else-if="scope.row.claimsStatus === CLAIMS_STATUS_SELECT_OBJ['4'].value" type="success">{{ CLAIMS_STATUS_SELECT_OBJ['4'].name }}</el-tag>
            <el-tag v-else-if="scope.row.claimsStatus === CLAIMS_STATUS_SELECT_OBJ['5'].value" type="info">{{ CLAIMS_STATUS_SELECT_OBJ['5'].name }}</el-tag>
            <span v-else />
          </template>
        </el-table-column>
        <!-- 理赔节点 -->
        <el-table-column :label="$t('ClaimsManagement.ClaimsNode')" prop="claimsNodeStr" />
        <!-- 客户 -->
        <el-table-column :label="$t('GlobalSetObj.client')" prop="customerName" />
        <!-- 商户 -->
        <el-table-column :label="$t('placeOrder.CommercialOwner')" prop="merchantsName" />
        <!-- 申请时间 -->
        <el-table-column :label="$t('ClaimsManagement.ApplicationTime')" prop="applicationTime" width="170" />
        <!-- 结束时间 -->
        <el-table-column :label="$t('collectionCenter.EndTime')" prop="finishTime" width="170" />
        <!-- 申请理赔金额 -->
        <el-table-column :label="$t('ClaimsManagement.ApplyForClaimAmount')" prop="claimAmount" width="120" />
        <!-- 实际理赔金额 -->
        <el-table-column :label="$t('ClaimsManagement.ActualClaimAmount')" prop="actualClaimAmount" width="120" />
        <!-- 创建人 -->
        <el-table-column :label="$t('GlobalSetObj.CreatePeople')" prop="createUserStr" />
        <!-- 审核人 -->
        <el-table-column :label="$t('Resource.Reviewer')" prop="reviewerStr" />
        <!-- 审核时间 -->
        <el-table-column :label="$t('receivablePayable.ReviewTime')" prop="auditingTime" width="170" />
        <!-- 付款人 -->
        <el-table-column :label="$t('ClaimsManagement.Drawee')" prop="payerStr" />
        <!-- 付款时间 -->
        <el-table-column :label="$t('receivablePayable.PaymentTime')" prop="paymentTime" width="170" />
      </el-table>

      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="query.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="query.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getCurrentTableData"
          @size-change="getSizeTableData"
        />
      </div>
    </el-card>

    <!-- 申请理赔 -->
    <el-drawer
      :title="$t('ClaimsManagement.ClaimApplication')"
      direction="ltr"
      :visible.sync="addRow.isBool"
      size="1400px"
      z-index="1000"
    >
      <AddCmp v-if="addRow.isBool" :ids="addRow.ids" @close="onAddClose" />
    </el-drawer>

    <!-- 详情操作 -->
    <el-drawer
      :title="editRow.title"
      direction="ltr"
      :visible.sync="editRow.isBool"
      size="1300px"
      z-index="1000"
    >
      <EditCmp v-if="editRow.isBool" :ids="editRow.ids" @close="onEditClose" />
    </el-drawer>
  </div>
</template>

<script>
import HeadCmp from './cmp/HeadCmp.vue';
import { apiClaimformPage, apiClaimformFormExport } from '@/api/claimsManagement/list';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { CLAIMS_STATUS_OBJ, CLAIMS_STATUS_SELECT_OBJ, CLAIMSNODE_TYPE_OBJ } from '@/views/claimsManagement/claimList/utils.js';
import { goExportList } from '@/utils/goExportList.js';

const editTitle = '理赔详情-';

export default {
  name: '',

  components: {
    HeadCmp,
    AddCmp: () => import('./cmp/Add/AddCmp.vue'),
    EditCmp: () => import('./cmp/Edit/EditCmp.vue')
  },

  data() {
    return {
      query: {
        pageNumber: 1,
        pageSize: 10,
        params: {
        //   title: '', // 标题
        //   claimsNumber: '', // 理赔单号
        //   customerId: '', // 客户
        //   claimsStatus: '', // 理赔状态
        //   createUser: '', // 创建人
        //   startedTime: '', // 时间
        //   endedTime: ''// 理赔节点
        }
      },
      total: 0,

      multipleSelection: [], // 选中的数据

      tableData: [],

      addRow: {
        ids: 0,
        isBool: false
      },

      // 详情操作
      editRow: {
        ids: 0,
        isBool: false,
        title: ''
      }
      // isAddClaims: true,
      // isEditClaims: false
    };
  },

  created() {
    this.CLAIMS_STATUS_OBJ = CLAIMS_STATUS_OBJ;
    this.CLAIMS_STATUS_SELECT_OBJ = CLAIMS_STATUS_SELECT_OBJ;
    // this.CLAIMSNODE_TYPE_OBJ = CLAIMSNODE_TYPE_OBJ; // 理赔节点
    this.userObj = getUserIdAndNameObj();
    setTimeout(() => {
      this.getTableData();
    }, 100);
  },

  methods: {

    onChange(row) {
      Object.assign(this.query.params, row);
    },

    // 页码变化翻页
    getCurrentTableData(val) {
      this.query.pageNumber = val;
      this.getTableData();
    },
    // 每页显示数量变化
    getSizeTableData(val) {
      this.query.pageSize = val;
      this.query.pageNumber = 1;
      this.getTableData();
    },

    // 获取列表数据
    getTableData() {
      apiClaimformPage(this.query).then(res => {
        const { total, records } = res.data;
        this.total = total;
        this.tableData = (records || []).map(item => {
          const { id, title, claimsNumber, customerName, claimAmount, actualClaimAmount, reviewer, claimsStatus, claimsNode, applicationTime, finishTime, customerId, createUser, auditingTime, payer, paymentTime, merchantsName } = item;

          const createUserStr = this.userObj[createUser] || '';
          const reviewerStr = this.userObj[reviewer] || '';
          const payerStr = this.userObj[payer] || '';
          const claimsNodeStr = CLAIMSNODE_TYPE_OBJ[claimsNode]?.name || '';

          const obj = {
            id,
            title, // 标题
            claimsNumber, // 理赔单号
            claimsStatus, // 理赔状态
            claimsNode, // 理赔节点
            claimsNodeStr, // 理赔节点
            customerId, // 客户
            customerName, // 客户
            applicationTime, // 申请时间
            finishTime, // 结束时间
            claimAmount, // 理赔申请金额
            actualClaimAmount, // 实际支付金额
            merchantsName, // 商户
            createUser, // 创建人
            createUserStr, // 创建人
            reviewer, // 审核人
            reviewerStr, // 审核人
            auditingTime, // 审核时间
            payer, // 付款人
            payerStr, // 付款人
            paymentTime// 付款时间
          };

          return Object.freeze(obj);
        });
      }).catch(err => {
        console.error(err);
      });
    },

    // 新增添加
    onAddClaims(ids) {
      this.addRow.ids = ids;
      this.addRow.isBool = true;
      // this.isAddClaims = true;
    },

    // 编辑
    onEditClaims(row) {
      const { id, title, claimsNumber } = row;
      this.editRow.ids = id;
      this.editRow.title = editTitle + title + '-' + claimsNumber;
      this.editRow.isBool = true;
      // this.isAddClaims = true;
    },

    onOpen(row) {
      const { id, claimsStatus } = row;

      // 当前处于 待提交 状态
      if (claimsStatus === CLAIMS_STATUS_SELECT_OBJ['0'].value) {
        this.onAddClaims(id);
        return;
      }

      // 当前处于 已驳回 状态
      if (claimsStatus === CLAIMS_STATUS_SELECT_OBJ['2'].value) {
        this.onAddClaims(id);
        return;
      }

      // 待审核 待付款 已完成 已关闭
      this.onEditClaims(row);
    },

    // 新增 编辑 关闭
    onAddClose(isBool) {
      // this.isAddClaims = false;
      this.addRow.isBool = false;
      if (isBool) {
        this.getTableData();
      }
    },

    // 编辑关闭
    onEditClose(isBool) {
      this.editRow.isBool = false;
      if (isBool) {
        this.getTableData();
      }
    },

    // 搜索
    onQuery() {
      this.query.pageNumber = 1;
      this.query.pageSize = 10;

      this.getTableData();
    },

    // 重置
    onReset() {
      this.query.pageNumber = 1;
      this.query.pageSize = 10;
      this.getTableData();
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 导出
    onExport() {
      let query = {};
      const arr = this.multipleSelection.map(item => {
        return item.id;
      });

      // 判断是否是多选
      if (arr.length === 0) {
        // 导出条件为当前搜索条件的数据
        query = {
          ...this.query.params
        };
      } else {
        query = {
          ids: arr
        };
      }

      apiClaimformFormExport(query).then(res => {
        goExportList(this);
      }).catch(err => {
        console.error(err);
      });
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
