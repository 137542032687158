var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detialInfo" },
    [
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.activeNames,
            callback: function ($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames",
          },
        },
        [
          _c(
            "el-collapse-item",
            {
              attrs: {
                title: _vm.$t("collectionCenter.waybillSRMessage"),
                name: "1",
              },
            },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "el-descriptions",
                    {
                      attrs: { colon: false, column: 3, direction: "vertical" },
                    },
                    [
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "div",
                              { staticStyle: { "font-weight": "normal" } },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      padding: "5px",
                                      background: "#00a121",
                                      color: "#fff",
                                      "border-radius": "5px",
                                      "margin-right": "8px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("collectionCenter.ji"))
                                    ),
                                  ]
                                ),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("collectionCenter.addresser"))
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.senderName || "--")
                            ),
                          ]),
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("newOrder.senderPhone") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.senderMobile || "--")
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("newOrder.SenderPhone") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.senderPhone || "--")
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("newOrder.SenderCompany") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.senderCompany || "--")
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("newOrder.SenderCPF") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.senderTax || "--")
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("newOrder.SenderEmail") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.senderMail || "--")
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$t("orderCenterCont.SenderStreet"),
                          },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.senderStreet || "--")
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("newOrder.SenderNumber") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.tableInfomation.senderHouseNumber || "--"
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("newOrder.SenderDistrict") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.senderArea || "--")
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("newOrder.SenderCity") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.senderCity || "--")
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("newOrder.SenderState") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.senderState || "--")
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "GlobalSetObj.NationalRegistrationNumber"
                            ),
                          },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.senderIeNumber || "--")
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.sendderComplement"),
                          },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.tableInfomation.senderComplement || "--"
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("newOrder.SenderZipCode") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.senderZipcode || "--")
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("newOrder.SendingAddress") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.senderAddress || "--")
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: { "margin-top": "10px" },
                },
                [
                  _c(
                    "el-descriptions",
                    {
                      attrs: { colon: false, column: 3, direction: "vertical" },
                    },
                    [
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "div",
                              { staticStyle: { "font-weight": "normal" } },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      padding: "5px",
                                      background: "#00a121",
                                      color: "#fff",
                                      "border-radius": "5px",
                                      "margin-right": "8px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("collectionCenter.shou"))
                                    ),
                                  ]
                                ),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("collectionCenter.recipientS")
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.receiveName || "--")
                            ),
                          ]),
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("newOrder.Recipientphone") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.receiveMobile || "--")
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$t("newOrder.Recipientphonenumber"),
                          },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.receivePhone || "--")
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: { label: _vm.$t("newOrder.RecipientCompany") },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.receiveCompany || "--")
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("newOrder.RecipientCPF") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.receiveTax || "--")
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("newOrder.RecipientEmail") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.receiveMail || "--")
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("orderCenterCont.Street") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.senderStreet || "--")
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("newOrder.doorNumber") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.tableInfomation.receiveHouseNumber || "--"
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$t("newOrder.RecipientDistrict"),
                          },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.receiveArea || "--")
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("newOrder.RecipientCity") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.receiveCity || "--")
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("newOrder.RecipientState") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.receiveState || "--")
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "GlobalSetObj.NationalRegistrationNumber"
                            ),
                          },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.tableInfomation.receiveIeNumber || "--"
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.recipientComplement"),
                          },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.tableInfomation.receiveComplement || "--"
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: { label: _vm.$t("newOrder.RecipientZipCode") },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.receiveZipcode || "--")
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: { label: _vm.$t("newOrder.ReceivingAddress") },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.receiveAddress || "--")
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-collapse-item",
            { attrs: { title: _vm.$t("navbar.willNumberInfo"), name: "2" } },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "el-descriptions",
                    {
                      attrs: { colon: false, column: 3, direction: "vertical" },
                    },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("navbar.anjunOrderNumber") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.orderNumber || "--")
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("navbar.totalNumber") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.waybillCount || "--")
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("newOrder.createTime") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.createTime || "--")
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("newOrder.CustomerName") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.customerName || "--")
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("newOrder.orderNumber") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.tableInfomation.customerNumber || "--")
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("newOrder.TypeService") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.expressTypeName[
                                  _vm.tableInfomation.expressType
                                ] || "--"
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("newOrder.CollectionType") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.collectTypeName[
                                  _vm.tableInfomation.collectType
                                ] || "--"
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("placeOrder.goodType") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.cargoTypeName[
                                  _vm.tableInfomation.cargoType
                                ] || "--"
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$t("navbar.totalCreateOrderWeight"),
                          },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.tableInfomation.totalCreateOrderWeight ||
                                  "--"
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$t("navbar.totalParcelDeclaredVolume"),
                          },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.tableInfomation.totalParcelDeclaredVolume ||
                                  "--"
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("navbar.transportWeight") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.tableInfomation
                                  .totalParcelDeclaredWeight1 || "--"
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$t("navbar.transportVolume") } },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.tableInfomation
                                  .totalParcelDeclaredWeight1 || "--"
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-collapse-item",
            { attrs: { title: _vm.$t("navbar.childrenTableData"), name: "3" } },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.childrenTableData,
                        height: _vm.isHeight,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("newOrder.SerialNumber"),
                          align: "center",
                          type: "index",
                          width: "70",
                        },
                      }),
                      _vm._l(_vm.tableHeaderList1, function (item) {
                        return [
                          _c("el-table-column", {
                            key: item.id,
                            attrs: {
                              align: item.center,
                              label: item.label,
                              "min-width": item.cloWidth,
                              prop: item.prop,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row[item.prop]
                                              ? "" + scope.row[item.prop]
                                              : "--"
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("orderCenterCont.FaceSheet"),
                          align: "center",
                          fixed: "right",
                          width: "120",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", { staticClass: "QuestionMark" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: scope.row.labelUrl,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("GlobalSetObj.see")))]
                                  ),
                                ]),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.downloadSheep(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("GlobalSetObj.download")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-collapse-item",
            {
              attrs: {
                title: _vm.$t("collectionCenter.cnsignmentInformation"),
                name: "4",
              },
            },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.childrenTableData1,
                        height: _vm.isHeight1,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("newOrder.SerialNumber"),
                          align: "center",
                          type: "index",
                          width: "70",
                        },
                      }),
                      _vm._l(_vm.tableHeaderList2, function (item) {
                        return [
                          _c("el-table-column", {
                            key: item.id,
                            attrs: {
                              align: item.center,
                              label: item.label,
                              "min-width": item.cloWidth,
                              prop: item.prop,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      item.prop === "declared"
                                        ? _c("div", [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "orderCenterCont.DeclareUnitPrice"
                                                  )
                                                ) +
                                                  ": " +
                                                  _vm._s(
                                                    scope.row.declaredValue ||
                                                      "- -"
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "orderCenterCont.DeclaredWeight"
                                                  )
                                                ) +
                                                  ": " +
                                                  _vm._s(
                                                    scope.row.declaredWeight ||
                                                      "- -"
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ])
                                        : item.prop === "objSize"
                                        ? _c("div", [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("navbar.objLength")
                                                ) +
                                                  ":" +
                                                  _vm._s(
                                                    scope.row.objLength || "- -"
                                                  )
                                              ),
                                            ]),
                                          ])
                                        : _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                scope.row[item.prop]
                                                  ? "" + scope.row[item.prop]
                                                  : "--"
                                              )
                                            ),
                                          ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-collapse-item",
            {
              attrs: {
                title: _vm.$t("orderCenterCont.InvoiceInformation"),
                name: "5",
              },
            },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.childrenTableData2,
                        height: _vm.isHeight2,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("newOrder.SerialNumber"),
                          align: "center",
                          type: "index",
                          width: "70",
                        },
                      }),
                      _vm._l(_vm.tableHeaderList3, function (item) {
                        return [
                          _c("el-table-column", {
                            key: item.id,
                            attrs: {
                              align: item.center,
                              label: item.label,
                              "min-width": item.cloWidth,
                              prop: item.prop,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row[item.prop]
                                              ? "" + scope.row[item.prop]
                                              : "--"
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }