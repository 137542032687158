var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        _vm._g(
          _vm._b(
            {
              staticStyle: { width: "100%" },
              attrs: {
                value: _vm.defaultValue,
                clearable: "",
                filterable: "",
                "filter-method": _vm.filterMethod,
              },
              on: { "visible-change": _vm.visibleChange },
            },
            "el-select",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _c("virtual-list", {
            ref: "virtualList",
            staticClass: "virtualselect-list",
            attrs: {
              "data-key": _vm.selectData.value,
              "data-sources": _vm.selectArr,
              "data-component": _vm.itemComponent,
              keeps: 20,
              "extra-props": {
                label: _vm.selectData.label,
                value: _vm.selectData.value,
                isRight: _vm.selectData.isRight,
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }