var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "ruleForm",
      attrs: {
        model: _vm.form,
        "label-position": "right",
        rules: _vm.rules,
        "label-width": "170px",
      },
    },
    [
      _c(
        "el-form-item",
        {
          staticClass: "numberClassSelect",
          attrs: { label: _vm.$t("GlobalSetObj.client"), prop: "customerId" },
        },
        [
          _c("CustomerSelectCmp", {
            model: {
              value: _vm.form.customerId,
              callback: function ($$v) {
                _vm.$set(_vm.form, "customerId", $$v)
              },
              expression: "form.customerId",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticClass: "numberClassSelect",
          attrs: { label: _vm.$t("ClaimsManagement.ClaimsNode") },
        },
        [
          _c(
            "el-select",
            {
              attrs: {
                placeholder: _vm.$t("operationCenter.PleaseSelect"),
                clearable: "",
              },
              model: {
                value: _vm.form.claimsNode,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "claimsNode", $$v)
                },
                expression: "form.claimsNode",
              },
            },
            _vm._l(_vm.CLAIMSNODE_TYPE_OBJ, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.name, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticClass: "numberClassSelect",
          attrs: {
            label:
              _vm.$t("ClaimsManagement.DeclarationValueThreshold") + "(<=)",
          },
        },
        [
          _c("el-input", {
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("GlobalSetObj.pleaseInputContent") },
            model: {
              value: _vm.form.declaredValue,
              callback: function ($$v) {
                _vm.$set(_vm.form, "declaredValue", $$v)
              },
              expression: "form.declaredValue",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticClass: "numberClassSelect",
          attrs: { label: _vm.$t("ClaimsManagement.DefaultAuditor") },
        },
        [
          _c("UserSelectCmp", {
            attrs: {
              "user-name": _vm.row.defaultReviewerName,
              "is-organization-name": true,
            },
            model: {
              value: _vm.form.defaultReviewer,
              callback: function ($$v) {
                _vm.$set(_vm.form, "defaultReviewer", $$v)
              },
              expression: "form.defaultReviewer",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("ruleForm")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("GlobalSetObj.determine")))]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.onClose(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }