<script >
import { ref, computed, defineProps, watch, defineEmits } from '@vue/composition-api';
import { useVuelidate } from '@vuelidate/core';
import { FormActions, FormActionsNext, FormActionsPrevious } from '@/components/wizard-form/formStepActions';
import { default as SkeletonTable } from '@/components/skeletonTable';
import { useGetAllSellersRegistered } from '@/api/transport/seller/query';
const __sfc_main = {};
__sfc_main.props = {
  sellers: {
    type: Array
  },
  handleNext: {
    type: Function
  },
  isLastStep: {
    type: Boolean
  },
  isFirstStep: {
    type: Boolean
  },
  handlePrevious: {
    type: Function
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const rules = {
    listSellers: {
      requiredList(value) {
        return !!value.length;
      }
    }
  };

  /* logic for updated list sellers */
  const emit = __ctx.emit;
  const listSellers = computed({
    get() {
      return props.sellers;
    },
    set(newSellers) {
      emit('updateSeller', newSellers);
    }
  });
  function handleCheck(newCheckedRows, lastRow) {
    const checkedSellers = listSellers.value;
    const newSellerCode = lastRow.seller_code;
    listSellers.value = checkedSellers.includes(newSellerCode) ? checkedSellers.filter(sellerCode => sellerCode !== newSellerCode) : [...checkedSellers, newSellerCode];
  }
  const v$ = useVuelidate(rules, {
    listSellers: listSellers
  });

  /* logic selected sellers */
  function handleCustomChecked(selected, row) {
    return selected === row.seller_code;
  }

  /* get all sellers */
  const queryParams = ref({
    currentPage: 1,
    perPage: 5,
    code: null,
    name: null
  });
  const filters = ref({
    code: '',
    name: ''
  });
  const {
    data,
    isLoading,
    isFetched
  } = useGetAllSellersRegistered(queryParams);
  const tableData = computed(() => {
    return data.value?.data?.result ?? data.value;
  });
  const tableTotal = computed(() => {
    return data.value?.data?.activeSeller ?? 1;
  });
  const isLoadingTable = computed(() => {
    return isLoading.value || !isFetched.value;
  });
  watch(filters.value, newFilters => {
    if (queryParams.value.currentPage > 1) {
      queryParams.value = {
        ...newFilters,
        currentPage: 1,
        perPage: 10
      };
    } else {
      queryParams.value = {
        ...queryParams.value,
        ...newFilters
      };
    }
  });

  /* submit data */
  async function handleSubmit() {
    const isValid = await v$.value.$validate();
    if (isValid) props.handleNext();
  }
  return {
    props,
    listSellers,
    handleCheck,
    v$,
    handleCustomChecked,
    queryParams,
    filters,
    isLoading,
    tableData,
    tableTotal,
    isLoadingTable,
    handleSubmit
  };
};
__sfc_main.components = Object.assign({
  SkeletonTable,
  FormActions,
  FormActionsPrevious,
  FormActionsNext
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div class="wrapper-collect-day">
    <form class="wrapper-filters">
      <o-field
        :label="$t('routes.sellerCode')"
        label-for="sellerCode"
        class="flex-1"
      >
        <o-input id="sellerCode" v-model="filters.code" />
      </o-field>

      <o-field
        :label="$t('routes.sellerName')"
        label-for="sellerName"
        class="flex-1"
      >
        <o-input id="sellerName" v-model="filters.name" />
      </o-field>
    </form>

    <SkeletonTable :is-loading="isLoadingTable" :qtd-rows="5">
      <o-table
        v-if="!isLoading"
        :data="tableData"
        :custom-is-checked="handleCustomChecked"
        :row-key="'id'"
        bordered
        backend-pagination
        paginated
        :total="tableTotal"
        :per-page="queryParams.perPage"
        :current-page.sync="queryParams.currentPage"
        :pagination-simple="false"
        checkable
        :checked-rows="listSellers"
        @check="handleCheck"
      >
        <o-table-column
          v-slot="props"
          field="seller"
          :label="$t('routes.sellerLabel')"
        >
          {{ `${props.row.seller_code} - ${props.row.seller_name}` }}
        </o-table-column>

        <o-table-column
          v-slot="props"
          field="seller_zipcode"
          :label="$t('routes.cepLabel')"
        >
          {{ props.row.seller_zipcode }}
        </o-table-column>

        <template #empty>
          {{ $t('routes.noResults') }}
        </template>

        <template slot="footer">
          <p>{{ `${$t('routes.sellerQuantity')}: ${listSellers.length}` }}</p>
        </template>
      </o-table>

      <p v-if="!!v$.listSellers.$errors.length" class="o-field-message"> {{ $t('routes.sellerAlert') }} </p>
    </SkeletonTable>

    <FormActions v-if="!!handleNext">
      <FormActionsPrevious :is-first-step="isFirstStep" />
      <FormActionsNext :is-last-step="isLastStep" @handleSubmit="handleSubmit" />
    </FormActions>
  </div>
</template>

<style scoped>
.wrapper-collect-day {
  display: grid;
  gap: 1.5rem/* 24px */;
}

.wrapper-filters{
  display: flex;
  gap: 0.5rem; /* 16px */
}

.flex-1 {
  flex: 1;
}

.errorMessage {

}
</style>
