var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "el-form",
        { attrs: { inline: true } },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("newOrder.pickUpDate") } },
            [
              _c("el-date-picker", {
                attrs: {
                  format: _vm.$i18n.locale == "zh" ? _vm.zhDate : _vm.ptDate,
                  placeholder: _vm.$t("newOrder.SelectDatetime"),
                  "range-separator": "~",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.timeValue,
                  callback: function ($$v) {
                    _vm.timeValue = $$v
                  },
                  expression: "timeValue",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-search", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.getSearchBtn()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("newOrder.search")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh-left", type: "info" },
                  on: { click: _vm.resetForm },
                },
                [_vm._v(_vm._s(_vm.$t("newOrder.reset")))]
              ),
              _c("el-button", { on: { click: _vm.exportTable } }, [
                _vm._v(_vm._s(_vm.$t("newOrder.export"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.tabClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("customerManagements.all"),
                name: "",
                lazy: "",
              },
            },
            [_c("statisticsTable", { attrs: { "table-data": _vm.tableData } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "SHEIN", lazy: "", name: "shein" } },
            [
              _c("statisticsTable", {
                attrs: { "table-data": _vm.tableData, name: "shein" },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Kwai", lazy: "", name: "kwai" } },
            [
              _c("statisticsTable", {
                attrs: { "table-data": _vm.tableData, name: "kwai" },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("newOrder.crossBorder"),
                lazy: "",
                name: "szanjun",
              },
            },
            [
              _c("statisticsTable", {
                attrs: { "table-data": _vm.tableData, name: "szanjun" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { margin: "20px 0", "text-align": "right" },
        attrs: {
          "current-page": _vm.queryForm.pageNumber,
          "page-size": _vm.queryForm.pageSize,
          "page-sizes": [10, 50, 200, 400],
          total: _vm.total,
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "update:currentPage": function ($event) {
            return _vm.$set(_vm.queryForm, "pageNumber", $event)
          },
          "update:current-page": function ($event) {
            return _vm.$set(_vm.queryForm, "pageNumber", $event)
          },
          "update:pageSize": function ($event) {
            return _vm.$set(_vm.queryForm, "pageSize", $event)
          },
          "update:page-size": function ($event) {
            return _vm.$set(_vm.queryForm, "pageSize", $event)
          },
          "size-change": function ($event) {
            return _vm.getSearchBtn()
          },
          "current-change": function ($event) {
            return _vm.getSearchBtn()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }