import { requestReverse } from '@/lib/axios';
import { removeNullParams } from '@/utils/removeNullParams';

export const uploadService = {
  async getAll(params, signal) {
    return requestReverse({
      url: `panini/getLote/${params.take}/${params.skip}`,
      params: removeNullParams({ numLote: params.numLote }),
      signal
    });
  },
  async create(numLote, codGroup, body) {
    return requestReverse({
      'Content-Type': 'multipart/form-data',
      url: `panini/uploadExcel?numLote=${numLote}&codGroup=${codGroup}`,
      method: 'POST',
      data: body
    });
  },
  async downloadFile(params) {
    return requestReverse({
      responseType: 'json',
      url: 'edition/createRomaneio',
      params: removeNullParams(params),
      method: 'GET'
    });
  }
};
