import { ref } from '@vue/composition-api';

export function useModal() {
  const isOpen = ref(false);

  function handleVisibility() {
    isOpen.value = !isOpen.value;
  }

  return {
    isOpen,
    handleVisibility
  };
}
