<script >
import { ref, defineProps } from '@vue/composition-api';
import { default as FormVehicle } from '../../../order/components/form/steps/formVehicle';
import { default as FormDriver } from '../../../order/components/form/steps/formDriver';
import { default as FormTrailer } from '../../../order/components/form/steps/formTrailer';
import { useWizardForm } from '@/composable/useWizardForm';
import { equipmentService } from '@/api/transport/equipment';
import getMessageError from '@/utils/getMessageError';
import { addingLoading, closeLoading } from '@/utils/handleControlLoading';
import { toast } from '@/utils/programaticToast';
import { getToastContentComponent } from '@/utils/getToastContentComponent';
const __sfc_main = {};
__sfc_main.props = {
  routeId: {
    type: Number
  },
  closeModal: {
    type: Function
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const isLoading = ref(false);
  const equipmentId = ref(null);
  const formSteps = ref({
    formStepDriver: {
      document: null,
      username: null,
      name: null,
      helpers: []
    },
    formStepVehicle: {
      plate: null
    },
    formStepTrailer: {
      trailerPlate: ''
    }
  });
  async function getEquipment() {
    try {
      const res = await equipmentService.getByRoute(props.routeId);
      formSteps.value.formStepDriver.document = res.data.driver_document;
      formSteps.value.formStepDriver.helpers = res.data.helpersPDA.map(helper => helper.username);
      formSteps.value.formStepVehicle.plate = res.data.vehicle_license_plate;
      equipmentId.value = res.data.id;
    } catch (error) {
      console.log(error);
    } finally {
      isLoading.value = true;
    }
  }
  getEquipment();
  async function handleSubmit(formStepsData) {
    try {
      const toastText = {
        title: '',
        describe: ''
      };
      addingLoading();
      const dataEquipment = {
        ...formStepsData.value.formStepDriver,
        ...formStepsData.value.formStepVehicle,
        ...formStepsData.value.formStepTrailer,
        routeId: props.routeId
      };
      if (equipmentId.value) {
        await equipmentService.edit(dataEquipment, equipmentId.value);
        toastText.title = 'Equipamento editado com sucesso';
        toastText.describe = 'Equipamento foi editado com sucesso';
      } else {
        await equipmentService.create(dataEquipment);
        toastText.title = 'Equipamento criado com sucesso';
        toastText.describe = 'Equipamento foi criado com sucesso';
      }
      props.closeModal();
      toast.open({
        variant: 'success',
        position: 'bottom',
        component: getToastContentComponent({
          title: toastText.title,
          describe: toastText.describe,
          typeIcon: 'success'
        }),
        closable: true,
        duration: 4000
      });
    } catch (error) {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Ocorreu um erro',
          describe: getMessageError(error),
          typeIcon: 'error'
        }),
        closable: true,
        duration: 4000
      });
    } finally {
      closeLoading();
    }
  }
  const STEPS_TOTAL = 3;
  const {
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  } = useWizardForm(formSteps, handleSubmit, STEPS_TOTAL);
  return {
    isLoading,
    formSteps,
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  };
};
__sfc_main.components = Object.assign({
  FormDriver,
  FormVehicle,
  FormTrailer
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div v-if="isLoading">
    <FormDriver
      v-show="step === 0"
      :form-data.sync="formSteps.formStepDriver"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
    />

    <FormVehicle
      v-show="step === 1"
      :form-data.sync="formSteps.formStepVehicle"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
    />

    <FormTrailer
      v-show="step === 2"
      :form-data.sync="formSteps.formStepTrailer"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
    />
  </div>

</template>
