import { requestScripting } from '@/lib/axios';
import clearString from '@/utils/clearString';

export const baseService = {
  async create(data) {
    const body = this.getBody(data);
    return requestScripting({
      method: 'POST',
      data: body,
      url: `shipping-base`
    });
  },
  async edit(data, id, isFormatedBody = true) {
    const body = isFormatedBody ? this.getBody(data) : data;

    return requestScripting({
      method: 'PUT',
      data: body,
      url: `shipping-base/${id}`
    });
  },
  async delete(id) {
    return requestScripting({
      method: 'DELETE',
      url: `shipping-base/${id}`
    });
  },
  async getAll(skip, take, params) {
    const queryString = new URLSearchParams();

    for (const key in params) {
      if (params[key]) {
        queryString.append(key, params[key]);
      }
    }

    return requestScripting({
      method: 'GET',
      url: `shipping-base?skip=${skip}&take=${take}&${queryString.toString()}`
    });
  },
  getOpeningHours(initialHour, finalHour) {
    if (initialHour && finalHour) {
      const initialHourEN = initialHour.toLocaleString('en-US', { hour: 'numeric', hour12: true });
      const finalHourEN = finalHour.toLocaleString('en-US', { hour: 'numeric', hour12: true });
      return `${initialHourEN} to ${finalHourEN}`;
    }
  },
  getBody(data) {
    return {
      'shipping_company_id': data.companyId,
      'shipping_company_base': data.base,
      'rm_shipping_company_base_IATA': data.iata,
      'shipping_company_base_document': clearString(data.document),
      'shipping_company_base_state_registration': clearString(data.stateRegistration),
      'shipping_company_base_address': data.street,
      'shipping_company_base_address_number': data.number,
      'shipping_company_base_address_complement': data.complement,
      'shipping_company_base_neighborhood': data.neighborhood,
      'shipping_company_base_municipality': data.municipality,
      'shipping_company_base_state': data.state,
      'shipping_company_base_country': 'BR',
      'shipping_company_base_zipcode': clearString(data.zipcode),
      'shipping_company_base_commercial_phone_number': clearString(data.comercialPhone),
      'shipping_company_base_contact_name': data.contactName,
      'shipping_company_base_cellphone': clearString(data.phone),
      'shipping_company_base_email': data.email,
      'shipping_company_base_receiving_capacity': data.receivingCapacity,
      'shipping_company_base_shipping_capacity': data.shippingCapacity,
      'shipping_company_base_opening_hours': this.getOpeningHours(data.initialHour, data.finalHour),
      'shipping_company_base_is_active': 1
    };
  }
};
