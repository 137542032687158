import i18n from '@/lang';

export const CODE_TYPE = {
  1: {
		value: 1, // 付款批次号
		label: i18n.t('receivablePayable.PaymentBatchNumber')
	},
	2: {
		value: 2, // 条形码
		label: i18n.t('receivablePayable.BarCode')
	},
}

// 批次状态
export const BATCH_STATUS = {
	2: {
		value: 2, // 全部成功
		type: 'success',
		label: i18n.t('receivablePayable.AllSuccessful')
	},
	3: {
		value: 3, // 全部失败
		type: 'error',
		label: i18n.t('receivablePayable.AllFail')
	},
	4: {
		value: 4, // 部分成功
		type: '',
		label: i18n.t('receivablePayable.PartiallySuccessful')
	},
	0: {
		value: 0, // 待付款
		type: 'info',
		label: i18n.t('ClaimsManagement.Due')
	},
};

// 付款状态
export const STATUS_TYPE = {
  2: {
		value: 2, // 全部成功
		type: 'success',
		label: i18n.t('receivablePayable.AllSuccessful')
	},
	3: {
		value: 3, // 全部失败
		type: 'danger',
		label: i18n.t('receivablePayable.AllFail')
	},
	4: {
		value: 4, // 部分成功
		type: 'warning',
		label: i18n.t('receivablePayable.PartiallySuccessful')
	},
	// 5: {
	// 	value: 5, // 付款失败
	// 	type: 'danger',
	// 	label: i18n.t('receivablePayable.付款失败')
	// },
	1: {
		value: 1, // 付款中
		type: '',
		label: i18n.t('receivablePayable.付款中')
	},
	0: {
		value: 0, // 待付款
		type: 'info',
		label: i18n.t('ClaimsManagement.Due')
	},
}