
<template>
  <div>
    <ul>
      <li v-for="(item,index) in list" :key="index">
        <el-link
          icon="el-icon-folder" target="_blank" :href="item.url"
          type="primary"
        >{{ item.name }}</el-link>
      </li>

      <!-- 暂无内容 -->
      <li v-if="list.length === 0" class="no_data">{{ $t('WorkCentreTable.NoContent') }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: [Array, Object],
      default: function() {
        return [];
      }
    }
  }
};
</script>

<style scoped>
.no_data {
  list-style: none;
  text-align: center;
  color: #9b9999;
}
</style>
