<template>
  <div>
    <el-card class="box-card">
      <div class="right">
        <el-button
          type="primary" plain
          size="small"
          @click="closeWindow">
          {{ $t('collectionCenter.close') }}
        </el-button>
      </div>
      <el-tabs v-model="activeName">
        <el-tab-pane :label="$t('collectionCenter.SecondarySummary')" name="hz">
          <div>
            <el-table
              :data="tableData1"
              border
            >
              <el-table-column
                :label="$t('operationCenter.ind')"
                width="60px"
                align="center"
                fixed="left"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="collectCount"
                :label="$t('collectionCenter.TotalCollectionPackages')"
                align="center"
              ><!-- 揽收包裹总数 -->
                <template slot-scope="scope">
                  <div style="cursor: pointer" @click="toMX(scope.row, 1)">
                    {{ scope.row.collectCount }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="siteLoadCount"
                :label="$t('collectionCenter.TotalPackagesLoadedBranch')"
                align="center"
              ><!-- 网点装车包裹总数 -->
                <template slot-scope="scope">
                  <div style="cursor: pointer" @click="toMX(scope.row, 2)">
                    {{ scope.row.siteLoadCount }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="hubUnloadCount"
                :label="$t('collectionCenter.TotalUnloadedParcelsTransferCenter')"
                align="center"
              ><!-- 转运中心卸车包裹总数 -->
                <template slot-scope="scope">
                  <div style="cursor: pointer" @click="toMX(scope.row, 3)">
                    {{ scope.row.hubUnloadCount }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="hubLoadCount"
                :label="$t('collectionCenter.TotalPackagesLoadedTransferCenter')"
                align="center"
              ><!-- 转运中心装车包裹总数 -->
                <template slot-scope="scope">
                  <div style="cursor: pointer" @click="toMX(scope.row, 4)">
                    {{ scope.row.hubLoadCount }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="glpUnloadCount"
                :label="$t('collectionCenter.TotalUnloadedParcelsDestinationWarehouse')"
                align="center"
              ><!-- 揽收目的仓卸车包裹总数 -->
                <template slot-scope="scope">
                  <div style="cursor: pointer" @click="toMX(scope.row, 5)">
                    {{ scope.row.glpUnloadCount }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="signCount"
                :label="$t('collectionCenter.TotalSignedParcelsDestinationWarehouse')"
                align="center"
              ><!-- 目的仓签收包裹总数 -->
                <template slot-scope="scope">
                  <div style="cursor: pointer" @click="toMX(scope.row, 6)">
                    {{ scope.row.signCount }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="closeCount"
                :label="$t('collectionCenter.TotalClosedPackages')"
                align="center"
              ><!-- 闭环包裹总数 -->
                <template slot-scope="scope">
                  <div style="cursor: pointer" @click="toMX(scope.row, 7)">
                    {{ scope.row.closeCount }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane v-if="activeName === 'mx'" :label="$t('collectionCenter.SecondarySummaryDetail')" name="mx">
          <el-table
            :data="tableDataMX"
            border
            :max-height="mxHeight"
          >
            <el-table-column
              :label="$t('operationCenter.ind')"
              width="60px"
              align="center"
              fixed="left"
            >
              <template slot-scope="scope">
                <span>{{ (formMX.pageNumber - 1) * formMX.pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="scanNumber"
              :label="$t('collectionCenter.Barcode')"
              min-width="200"
              align="center"
            /><!-- 条形码 -->
            <el-table-column
              prop="packageNumber"
              :label="$t('collectionCenter.parcelNum')"
              min-width="200"
              align="center"
            /><!-- 包裹号 -->
            <el-table-column
              prop="collectTime"
              :label="$t('collectionCenter.lanshouT')"
              min-width="180"
              align="center"
            /><!-- 揽收时间 -->
            <el-table-column
              prop="collectUserName"
              :label="$t('collectionCenter.LanShouPeople')"
              min-width="180"
              align="center"
            /><!-- 揽收人 -->
            <el-table-column
              prop="collectSiteName"
              :label="$t('collectionCenter.LanShouSite')"
              min-width="180"
              align="center"
            /><!-- 揽收网点 -->
            <el-table-column
              prop="siteLoadVehicleNumber"
              :label="$t('collectionCenter.BranchLoadingLicensePlate')"
              min-width="180"
              align="center"
            /><!-- 网点装车车牌 -->
            <el-table-column
              prop="siteLoadTime"
              :label="$t('collectionCenter.networkLoadingTime')"
              min-width="180"
              align="center"
            /><!-- 网点装车时间 -->
            <el-table-column
              prop="siteLoadUserName"
              :label="$t('collectionCenter.networkLoader')"
              min-width="180"
              align="center"
            /><!-- 网点装车人 -->
            <el-table-column
              prop="siteLoadSiteName"
              :label="$t('customerManagements.loadSiteIdList')"
              min-width="180"
              align="center"
            /><!-- 装车网点 -->
            <el-table-column
              prop="hubUnloadTime"
              :label="$t('collectionCenter.unloadingTransferCenterTime')"
              min-width="180"
              align="center"
            /><!-- 转运中心卸车时间 -->
            <el-table-column
              prop="hubUnloadUserName"
              :label="$t('collectionCenter.unloadingTransferCenterP')"
              min-width="180"
              align="center"
            /><!-- 转运中心卸车人 -->
            <el-table-column
              prop="hubLoadSiteName"
              :label="$t('collectionCenter.LoadingTransferCenter')"
              min-width="180"
              align="center"
            /><!-- 装车转运中心 -->
            <el-table-column
              prop="hubLoadTime"
              :label="$t('collectionCenter.transferCenterLoadingTime')"
              min-width="180"
              align="center"
            /><!-- 转运中心装车时间 -->
            <el-table-column
              prop="hubLoadUserName"
              :label="$t('collectionCenter.transferCenterLoader')"
              min-width="180"
              align="center"
            /><!-- 转运中心装车人 -->
          </el-table>
          <div class="right">
            <el-pagination
              layout="total, sizes, prev, pager, next, jumper"
              :current-page.sync="formMX.pageNumber"
              :page-sizes="[10, 20, 50, 100]"
              :page-size.sync="formMX.pageSize"
              :total="totalMX"
              background
              style="margin: 10px 0"
              @current-change="getDatePageMX"
              @size-change="getPageSizeMX"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import {
  getPunctualSummaryData,
  getPunctualOrderDataPage
} from '@/api/lanshou';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'Punctuality',
  props: {
    timeForm: {
      type: Object,
      default: () => {
        return {
          startTime: '',
          endTime: '',
          mdStayType: '',
          timeType: '',
          updateTime: ''
        };
      }
    }
  },
  data() {
    return {
      activeName: 'hz',
      tableData1: [],
      formMX: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: '',
          endTime: '',
          collectPunctualType: '',
          punctualOrderType: '',
          latestBatchTime: ''
        }
      },
      totalMX: 0,
      tableDataMX: [],
      mxHeight: 600
    };
  },
  created() {
    console.log(this.timeForm, '===timeForm');
    this.mxHeight = document.body.clientHeight - 270;
    this.getData();
  },
  methods: {
    getData() {
      const data = cloneDeep(this.timeForm);
      const param = {
        startTime: data.startTime,
        endTime: data.endTime,
        collectPunctualType: data.mdStayType
      };
      getPunctualSummaryData(param).then(res => {
        if (res.status === 'OK') {
          this.tableData1 = [res.data];
        }
      });
    },
    toMX(row, type) {
      const data = cloneDeep(this.timeForm);
      this.formMX = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: data.startTime,
          endTime: data.endTime,
          collectPunctualType: data.mdStayType,
          punctualOrderType: type,
          latestBatchTime: data.timeType === 1 ? data.updateTime : ''
        }
      };
      this.getTableDatePageMX();
      this.activeName = 'mx';
    },
    getTableDatePageMX() {
      getPunctualOrderDataPage(this.formMX).then(res => {
        this.tableDataMX = [];
        if (res.status === 'OK') {
          this.totalMX = res.data.total;
          this.tableDataMX = res.data.records;
        }
      });
    },
    getPageSizeMX(val) {
      this.formMX.pageNumber = 1;
      this.formMX.pageSize = val;
      this.getTableDatePageMX();
    },
    getDatePageMX(val) {
      this.formMX.pageNumber = val;
      this.getTableDatePageMX();
    },
    // 关闭
    closeWindow() {
      this.$emit('closeWindow');
    }
  }
};
</script>

<style lang="scss" scoped>
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
