<template>
  <div class="container">
    <el-form label-position="top" size="small">
      <el-row :gutter="20">
        <el-col :span="7">
          <el-form-item :label="$t('Resource.licensePlateNumber')">
            <el-input v-model="queryForm.params.vehicleNumber" />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item :label="$t('Resource.vehicleBrand')">
            <el-input v-model="queryForm.params.vehicleBrand" />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item :label="$t('Resource.vehicleIde')">
            <el-input v-model="queryForm.params.vehicleFrameNumber" />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item :label="$t('Resource.vehicleCondition')">
            <el-select v-model="queryForm.params.vehicleStatus">
              <el-option :label="$t('userManage.normal')" :value="1" />
              <el-option :label="$t('newOperationCenter.abnormal')" :value="0" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row
      type="flex"
      justify="start"
    >
      <el-col :span="8">
        <div>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="getTableData"
          >{{ $t('operationCenter.Procurar') }}</el-button>
          <el-button
            icon="el-icon-refresh"
            size="mini"
          >{{ $t('operationCenter.reset') }}</el-button>
        </div>
      </el-col>
    </el-row>
    <el-divider />
    <el-button type="primary" size="small" @click="showAddVehicleDialog = true">{{ $t('Resource.addNewVehicle') }}</el-button>
    <!-- Main Table -->
    <el-table
      :data="tableData" style="width: 100%;margin-top: 20px" border
      @selection-change="selectionChange"
    >
      <el-table-column
        :reserve-selection="false" align="center" type="selection"
        width="45"
      />
      <el-table-column :label="$t('collectionCenter.ind')" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.$index+1 }}
        </template>
      </el-table-column>
      <el-table-column prop="vehicleNumber" :label="$t('Resource.licensePlateNumber')">
        <template slot-scope="scope">
          <span style="color: #409eff;cursor: pointer" @click="getVehicleInfo(scope.row)">{{ scope.row.vehicleNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="licensingTime" :label="$t('Resource.registrationDate')" />
      <el-table-column prop="vehicleBrand" :label="$t('Resource.vehicleBrand')" />
      <el-table-column prop="vehicleType" :label="$t('Resource.vehicleModel')" />
      <el-table-column prop="vehicleFrameNumber" :label="$t('Resource.vehicleIde')" />
      <el-table-column prop="store" :label="$t('Resource.sshop')" />
      <el-table-column prop="vehicleStatus" :label="$t('Resource.vehicleCondition')">
        <template slot-scope="scope">
          {{ scope.row.vehicleStatus?$t('userManage.normal'):$t('newOperationCenter.abnormal') }}
        </template>
      </el-table-column>
      <el-table-column prop="userDepartment" :label="$t('Resource.departmentInUse')">
        <template slot-scope="scope">
          {{ orgObjList['orgCode-'+scope.row.userDepartment] }}
        </template>
      </el-table-column>
      <el-table-column prop="currentDriver" :label="$t('Resource.currentDriver')">
        <template slot-scope="scope">
          {{ userIdAndNameObj[scope.row.currentDriver] }}
        </template>
      </el-table-column>
      <el-table-column prop="serviceTime" :label="$t('Resource.latestMaintenance')" />
      <el-table-column prop="repairTime" :label="$t('Resource.latestRepair')" />
      <el-table-column prop="insuranceExpiryTime" :label="$t('Resource.InsuranceExpirationDate')" />
      <el-table-column prop="updateTime" :label="$t('Resource.latestInventoryCheck')" />
      <el-table-column :label="$t('GlobalSetObj.operate')" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="handleCheck(scope.row)">{{ $t('Resource.records') }}</el-button>
          <el-divider direction="vertical" />
          <el-button type="text" size="small" @click="handleLog(scope.row)">{{ $t('Resource.register') }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <!-- 分页工具 -->
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getTableData"
        @size-change="getTableData"
      />
    </div>
    <!-- 车辆登记 -->
    <el-dialog
      :title="$t('Resource.addNewVehicle')" :visible.sync="showAddVehicleDialog" width="500px"
      @close="closeNewVehicle"
    >
      <el-form :model="newVehicle" label-position="top" size="small">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item :label="$t('Resource.licensePlateNumber')">
              <el-input v-model="newVehicle.vehicleNumber" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('Resource.registrationDate')">
              <el-date-picker
                v-model="newVehicle.licensingTime" type="datetime" :format="$i18n.locale=='zh'?zhDate:ptDate"
                value-format="yyyy-MM-dd HH:mm:ss"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('Resource.vehicleBrand')">
              <el-input v-model="newVehicle.vehicleBrand" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('Resource.vehicleModel')">
              <el-input v-model="newVehicle.vehicleType" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('Resource.vehicleIde')">
              <el-input v-model="newVehicle.vehicleFrameNumber" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('Resource.sshop')">
              <el-input v-model="newVehicle.store" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddVehicleDialog = false">{{ $t('GlobalSetObj.cancel') }}</el-button>
        <el-button type="primary" @click="addVehicle">{{ $t('GlobalSetObj.confirm') }}</el-button>
      </span>
    </el-dialog>

    <!-- 新增记录维修/保养记录 -->
    <el-dialog :title="$t('Resource.addNewRecord')" :visible.sync="innerVisible" width="800px">
      <el-form :model="maintenance" label-position="top" size="small">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item :label="$t('Resource.licensePlateNumber')">
              <el-input v-model="maintenance.vehicleNumber" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('GlobalSetObj.Type')">
              <el-select v-model="maintenance.type">
                <el-option :label="$t('Resource.maintenance')" :value="0" />
                <el-option :label="$t('Resource.repair')" :value="1" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('Resource.shop')">
              <el-input v-model="maintenance.serviceShop" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('Resource.content')">
              <el-input v-model="maintenance.serviceContent" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('Resource.handler')">
              <el-select
                v-model="maintenance.createUser"
                filterable
                clearable
                :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
                style="width: 100%;"
              >
                <el-option
                  v-for="(item, ind) in customerList"
                  :key="ind"
                  :label="item.realName + (item.username ? '（' + item.username + '）' : '')"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('Resource.expenses')">
              <el-input v-model="maintenance.fee" type="number" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="innerVisible = false">{{ $t('GlobalSetObj.cancel') }}</el-button>
        <el-button type="primary" @click="addRecord">{{ $t('Resource.addNewRecord') }}</el-button>
      </span>
    </el-dialog>
    <!-- 车辆盘点 -->
    <el-dialog :title="$t('Resource.vehicleInventory')" :visible.sync="showVehicleInventoryDialog" width="700px">
      <el-form :model="vehicleInventory" label-position="top" size="small">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item :label="$t('Resource.licensePlateNumber')">
              <el-input v-model="vehicleInventory.vehicleNumber" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('Resource.currentMileage')">
              <el-input v-model="vehicleInventory.mileage" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('Resource.currentDriver')">
              <el-select
                v-model="vehicleInventory.currentDriver"
                filterable
                clearable
                :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
                style="width: 100%;"
              >
                <el-option
                  v-for="(item, ind) in customerList"
                  :key="ind"
                  :label="item.realName + (item.username ? '（' + item.username + '）' : '')"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('Resource.vehicleCondition')">
              <el-select v-model="vehicleInventory.vehicleStatus">
                <el-option :label="$t('userManage.normal')" :value="1" />
                <el-option :label="$t('newOperationCenter.abnormal')" :value="0" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('Resource.insuranceExpiration')">
              <el-date-picker
                v-model="vehicleInventory.insuranceExpiryTime" type="datetime" :format="$i18n.locale=='zh'?zhDate:ptDate"
                value-format="yyyy-MM-dd HH:mm:ss"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('Resource.repair')">
              <el-date-picker
                v-model="vehicleInventory.repairTime" type="datetime" :format="$i18n.locale=='zh'?zhDate:ptDate"
                value-format="yyyy-MM-dd HH:mm:ss"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('Resource.maintenance')">
              <el-date-picker
                v-model="vehicleInventory.serviceTime" type="datetime" :format="$i18n.locale=='zh'?zhDate:ptDate"
                value-format="yyyy-MM-dd HH:mm:ss"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('Resource.locationOfUse')">
              <el-input v-model="vehicleInventory.usePlace" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('Resource.inventoryClerk')">
              <el-select
                v-model="vehicleInventory.createUser"
                filterable
                clearable
                :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
                style="width: 100%;"
              >
                <el-option
                  v-for="(item, ind) in customerList"
                  :key="ind"
                  :label="item.realName + (item.username ? '（' + item.username + '）' : '')"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('Resource.frontOfTheCar')">
              <el-upload
                :accept="filtTypeArr.join(',')"
                :multiple="true"
                :file-list="fileList1"
                :show-file-list="true"
                :on-remove="(params)=>handleRemove(params,'fileList1')"
                :before-upload="(params)=>beforeUpload(params,'fileList1')"
                :http-request="(params)=>enclosureUpload(params,'fileList1')"
                action
                list-type="picture-card"
                size="small"
              >
                <i class="el-icon-plus" />
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('Resource.carInterior')">
              <el-upload
                :accept="filtTypeArr.join(',')"
                :multiple="true"
                :file-list="fileList2"
                :show-file-list="true"
                :on-remove="(params)=>handleRemove(params,'fileList2')"
                :before-upload="(params)=>beforeUpload(params,'fileList2')"
                :http-request="(params)=>enclosureUpload(params,'fileList2')"
                action
                list-type="picture-card"
                size="small"
              >
                <i class="el-icon-plus" />
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('Resource.trunk')">
              <el-upload
                :accept="filtTypeArr.join(',')"
                :multiple="true"
                :file-list="fileList3"
                :show-file-list="true"
                :on-remove="(params)=>handleRemove(params,'fileList3')"
                :before-upload="(params)=>beforeUpload(params,'fileList3')"
                :http-request="(params)=>enclosureUpload(params,'fileList3')"
                action
                list-type="picture-card"
                size="small"
              >
                <i class="el-icon-plus" />
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('Resource.exterior')">
              <el-upload
                :accept="filtTypeArr.join(',')"
                :multiple="true"
                :file-list="fileList4"
                :show-file-list="true"
                :on-remove="(params)=>handleRemove(params,'fileList4')"
                :before-upload="(params)=>beforeUpload(params,'fileList4')"
                :http-request="(params)=>enclosureUpload(params,'fileList4')"
                action
                list-type="picture-card"
                size="small"
              >
                <i class="el-icon-plus" />
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showVehicleInventoryDialog = false">{{ $t('GlobalSetObj.cancel') }}</el-button>
        <el-button type="primary" @click="addVehicleInventory">{{ $t('Resource.saveInformation') }}</el-button>
      </span>
    </el-dialog>
    <el-drawer
      :title="$t('Resource.vehicleDetails')"
      :visible.sync="drawer"
      size="900px"
      direction="ltr"
    >
      <el-tabs type="border-card">
        <el-tab-pane :label="$t('Resource.vehicleDetails')">
          <el-card>
            <el-form label-position="top" size="small">
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item :label="$t('Resource.licensePlateNumber')">
                    <span>{{ vehicleInfo.vehicleNumber }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="$t('Resource.registrationDate')">
                    <span>{{ vehicleInfo.licensingTime }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="$t('Resource.vehicleBrand')">
                    <span>{{ vehicleInfo.vehicleBrand }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="$t('Resource.vehicleModel')">
                    <span>{{ vehicleInfo.vehicleType }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="$t('Resource.vehicleIde')">
                    <span>{{ vehicleInfo.vehicleFrameNumber }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="$t('Resource.sshop')">
                    <span>{{ vehicleInfo.store }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="$t('Resource.vehicleCondition')">
                    <span>{{ vehicleInfo.vehicleStatus?$t('userManage.normal'):$t('newOperationCenter.abnormal') }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="$t('Resource.departmentInUse')">
                    <span>{{ orgObjList['orgCode-'+vehicleInfo.userDepartment] }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="$t('Resource.currentDriver')">
                    <span>{{ userIdAndNameObj[vehicleInfo.currentDriver] }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="$t('Resource.latestMaintenance')">
                    <span>{{ vehicleInfo.serviceTime }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="$t('Resource.latestRepair')">
                    <span>{{ vehicleInfo.repairTime }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="$t('Resource.InsuranceExpirationDate')">
                    <span>{{ vehicleInfo.insuranceExpiryTime }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="$t('Resource.latestInventoryCheck')">
                    <span>{{ vehicleInfo.updateTime }}</span>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-card>
        </el-tab-pane>
        <el-tab-pane :label="$t('Resource.maintenanceRecords')">
          <el-card class="box-card">
            <div style="height: 80vh;overflow-y: auto">
              <el-table
                :data="maintenanceList"
                style="width: 100%"
              >
                <el-table-column
                  prop="vehicleNumber"
                  :label="$t('Resource.licensePlateNumber')"
                />
                <el-table-column
                  prop="type"
                  :label="$t('GlobalSetObj.Type')"
                >
                  <template slot-scope="scope">
                    {{ scope.row.type?$t('Resource.repair'):$t('Resource.maintenance') }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="serviceShop"
                  :label="$t('Resource.shop')"
                />
                <el-table-column
                  prop="serviceContent"
                  :label="$t('Resource.content')"
                />
                <el-table-column
                  prop="createUser"
                  :label="$t('Resource.handler')"
                >
                  <template slot-scope="scope">
                    {{ userIdAndNameObj[scope.row.createUser] }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="fee"
                  :label="$t('Resource.expenses')"
                />
              </el-table>
              <div class="pagination">
                <!-- 分页工具 -->
                <el-pagination
                  layout="total, sizes, prev, pager, next, jumper"
                  :current-page.sync="maintenanceQueryForm.pageNumber"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size.sync="maintenanceQueryForm.pageSize"
                  :total="maintenanceTotal"
                  background
                  style="margin: 10px 0"
                  @current-change="getMaintenanceList"
                  @size-change="getMaintenanceList"
                />
              </div>
            </div>
          </el-card>
        </el-tab-pane>
        <el-tab-pane :label="$t('Resource.inventoryRecords')">
          <el-card class="box-card">
            <div style="height: 80vh;overflow-y: auto">
              <el-table
                :data="inventoryRecordsList"
                style="width: 100%"
              >
                <el-table-column
                  prop="vehicleNumber"
                  :label="$t('Resource.licensePlateNumber')"
                />
                <el-table-column
                  prop="mileage"
                  :label="$t('Resource.currentMileage')"
                />
                <el-table-column
                  prop="currentDriver"
                  :label="$t('Resource.currentDriver')"
                >
                  <template slot-scope="scope">
                    {{ userIdAndNameObj[scope.row.currentDriver] }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="vehicleStatus"
                  :label="$t('Resource.vehicleCondition')"
                >
                  <template slot-scope="scope">
                    {{ scope.row.vehicleStatus?$t('userManage.normal'):$t('newOperationCenter.abnormal') }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="insuranceExpiryTime"
                  :label="$t('Resource.InsuranceExpirationDate')"
                />
                <el-table-column
                  prop="createTime"
                  :label="$t('Resource.CreationTime')"
                />
                <el-table-column
                  prop="createUser"
                  :label="$t('Resource.inventoryClerk')"
                >
                  <template slot-scope="scope">
                    {{ userIdAndNameObj[scope.row.createUser] }}
                  </template>
                </el-table-column>
              </el-table>
              <div class="pagination">
                <!-- 分页工具 -->
                <el-pagination
                  layout="total, sizes, prev, pager, next, jumper"
                  :current-page.sync="maintenanceQueryForm.pageNumber"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size.sync="maintenanceQueryForm.pageSize"
                  :total="maintenanceTotal"
                  background
                  style="margin: 10px 0"
                  @current-change="getInventoryRecordsList"
                  @size-change="getInventoryRecordsList"
                />
              </div>
            </div>
          </el-card>
        </el-tab-pane>
      </el-tabs>
    </el-drawer>
  </div>
</template>

<script>
import { getOrganizationTree } from '@/api/organization';
import { getUserPage } from '@/api/user';
import { imgTypeArr } from '@/enums/evaluateEnums';
import { uploadOos } from '@/utils/ali_oos_util';
import {
  getVehiclePage,
  vehicleAddApi,
  vehicleUpdate,
  vehicleServiceAdd, vehicleServicePage, vehicleMaintenancePage
} from '@/api/vehicleManagement';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { cloneDeep } from 'lodash-es';

export default {
  data() {
    return {
      total: 0,
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          vehicleNumber: '',
          vehicleBrand: '',
          vehicleFrameNumber: '',
          store: '',
          vehicleStatus: 1,
          createUser: '',
          userDepartmentList: []
        }
      },
      tableData: [],
      selectedList: [],
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      showAddVehicleDialog: false,
      showAddRecordDialog: false,
      showVehicleInventoryDialog: false,
      innerVisible: false, // 判断记录内层弹窗
      drawer: false,
      // 新增车辆字段
      newVehicle: {
        vehicleNumber: '',
        licensingTime: '',
        vehicleBrand: '',
        vehicleType: '',
        vehicleFrameNumber: '',
        store: ''
      },
      // 维修保养字段
      maintenance: {
        vehicleNumber: '',
        type: '',
        serviceShop: '',
        serviceContent: '',
        fee: '',
        createTime: '',
        createUser: ''
      },
      maintenanceQueryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          vehicleNumber: ''
        }
      },
      maintenanceTotal: 0,
      maintenanceList: [], // 维修保养记录
      // 盘点车辆字段
      vehicleInventory: {
        vehicleNumber: '',
        mileage: '',
        currentDriver: '',
        vehicleStatus: '',
        serviceTime: '',
        repairTime: '',
        usePlace: '',
        createUser: '',
        id: '',
        insuranceExpiryTime: ''
      },
      orgObjList: {}, // 组织列表
      customerList: [], // 处理人列表
      filtTypeArr: imgTypeArr, // 上传图片格式
      fileList1: [], // 车头图片列表
      fileList2: [], // 车内图片列表
      fileList3: [], // 车厢图片列表
      fileList4: [], // 外观图片列表
      userIdAndNameObj: {},
      inventoryRecordsList: [], // 盘点记录
      inventoryRecordsForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          vehicleNumber: ''
        }
      },
      inventoryRecordsTotal: 0,
      vehicleInfo: {} // 车辆详情
    };
  },
  async created() {
    // 获取组织列表
    await this.getOrg();
    // 获取用户列表
    this.getUserManageOption();
    // 获取表格数据
    this.getUserPermissions();
    this.getTableData();
    try {
      // 所有发起人/用户
      this.userIdAndNameObj = getUserIdAndNameObj();
    } catch (error) {
      console.err(error);
    }
  },
  methods: {
    /**
     * @description 获取用户可查看的部门权限，如果没有可查看的部门，只能查看自己创建的数据
     */
    getUserPermissions() {
      // 可查看的部门权限列表
      const filteredData = this.$store.getters.button_permissions.filter(item => item.startsWith('orgCode-'));
      console.log(filteredData, 'filteredData');
      filteredData.forEach((item) => {
        console.log(item, this.orgObjList[item], this.orgObjList);
        if (this.orgObjList[item]) {
          this.queryForm.params.userDepartmentList.push(this.orgObjList[item]);
        }
      });
      // 判断是否有权限
      if (this.queryForm.params.userDepartmentList && !this.queryForm.params.userDepartmentList.length) {
        this.queryForm.params.createUser = this.$store.getters.userId;
      }
    },
    /**
     * @description 表格选中数据
     */
    selectionChange(val) {
      this.selectedList = val;
    },
    /**
     * @description 获取列表数据
     */
    getTableData() {
      getVehiclePage(this.queryForm)
        .then((res) => {
          if (res.status === 'OK') {
            this.tableData = res.data.records;
            this.total = res.data.total;
          }
        });
    },
    /**
     * @description 新增车辆
     */
    addVehicle() {
      vehicleAddApi({
        ...this.newVehicle,
        userDepartment: this.$store.getters.orgCode,
        licensingTime: this.newVehicle.licensingTime ? this.newVehicle.licensingTime : null })
        .then((res) => {
          if (res.status === 'OK') {
            this.$message.success('添加成功');
            this.showAddVehicleDialog = false;
            this.getTableData();
          }
        });
    },
    /**
     * @description 新增车辆保养/维修记录
     */
    addRecord() {
      vehicleServiceAdd(this.maintenance)
        .then((res) => {
          if (res.status === 'OK') {
            this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded'));
            this.innerVisible = false;
            this.getTableData();
          }
        });
    },
    /**
     * @description 盘点
     * @param row
     */
    handleCheck(row) {
      const { vehicleNumber, mileage, currentDriver, vehicleStatus, serviceTime, repairTime, usePlace, createUser, vehicleFront, vehicleInterior, vehicleCabin, vehicleExterior, id, insuranceExpiryTime } = row;
      this.vehicleInventory = {
        vehicleNumber, mileage, currentDriver: currentDriver ? Number(currentDriver) : currentDriver, vehicleStatus, serviceTime, repairTime, usePlace, createUser,
        id, insuranceExpiryTime
      };
      if (vehicleFront) {
        this.fileList1 = vehicleFront.split(',').map((item) => {
          return { url: item };
        });
      } else {
        this.fileList1 = [];
      }
      if (vehicleInterior) {
        this.fileList2 = vehicleInterior.split(',').map((item) => {
          return { url: item };
        });
      } else {
        this.fileList2 = [];
      }
      if (vehicleCabin) {
        this.fileList3 = vehicleCabin.split(',').map((item) => {
          return { url: item };
        });
      } else {
        this.fileList3 = [];
      }
      if (vehicleExterior) {
        this.fileList4 = vehicleExterior.split(',').map((item) => {
          return { url: item };
        });
      } else {
        this.fileList4 = [];
      }
      this.showVehicleInventoryDialog = true;
    },
    /**
     * 查看维修保养记录
     * @param row
     */
    handleLog(row) {
      this.maintenance.vehicleNumber = row.vehicleNumber;
      this.innerVisible = true;
    },
    /**
     * @description 获取维修保养记录
     */
    getMaintenanceList() {
      this.maintenanceQueryForm.params.vehicleNumber = this.vehicleInfo.vehicleNumber;
      vehicleServicePage(this.maintenanceQueryForm)
        .then((res) => {
          if (res.status === 'OK') {
            this.maintenanceList = res.data.records;
            this.maintenanceTotal = res.data.total;
          }
        });
    },
    /**
     * @description 获取盘点记录
     */
    getInventoryRecordsList() {
      this.inventoryRecordsForm.params.vehicleNumber = this.vehicleInfo.vehicleNumber;
      vehicleMaintenancePage(this.inventoryRecordsForm)
        .then((res) => {
          if (res.status === 'OK') {
            this.inventoryRecordsList = res.data.records;
            this.inventoryRecordsTotal = res.data.total;
          }
        });
    },
    /**
     * @description 获取组织结构列表
     */
    getOrg() {
      return new Promise((resolve, reject) => {
        getOrganizationTree()
          .then((res) => {
            if (res.status === 'OK') {
              const orgObjList = {};
              res.data.forEach((arr1) => {
                if (arr1.children && arr1.children.length) {
                  arr1.children.forEach((arr2) => {
                    orgObjList['orgCode-' + arr2.orgCode] = arr2.orgCode;
                    if (arr2.children && arr2.children.length) {
                      arr2.children.forEach((arr3) => {
                        orgObjList['orgCode-' + arr3.orgCode] = arr3.orgCode;
                      });
                    }
                  });
                }
              });
              resolve();
              this.orgObjList = orgObjList;
              console.log(this.orgObjList, 'this.orgObjList');
            }
          })
          .catch(() => {
            reject();
          });
      });
    },
    /**
     * @description 获取用户列表（处理人）
     */
    getUserManageOption() {
      const param = {
        pageSize: 9999999,
        pageNumber: 1,
        params: {
          accountState: '1'
        }
      };
      getUserPage(param).then((response) => {
        if (response.status === 'OK') {
          const { records } = response.data;
          this.customerList = records;
        }
      });
    },
    handleRemove(file, key) {
      const uid = file.uid;
      this[key] = this[key].filter(item => {
        return item.uid !== uid;
      });
    },
    beforeUpload(file) {
      const sizes = 10; // 上传文件大小 单位M
      const { type, size } = file;
      const isLt10M = (size / 1024 / 1024) < sizes;
      const isBool = imgTypeArr.includes(type);
      if (!isBool) {
        this.$message.error(`上传格式不符合要求!`);
      }
      if (!isLt10M) {
        this.$message.error(`上传图片大小不能超过 ${sizes}MB!`);
      }
      return isBool && isLt10M;
    },

    // 上传oos
    async enclosureUpload(option, key) {
      const file = option.file;
      const res = await uploadOos(file, 'workOrder');
      if (res.code === 200) {
        const obj = {
          name: file.name,
          url: res.url
        };
        this[key] = [...this[key], obj];
      } else {
        this.$message.error(res.msg);
      }
    },
    /**
     * @description 车辆盘点
     */
    addVehicleInventory() {
      const data = cloneDeep(this.vehicleInventory);
      if (this.fileList1 && this.fileList1.length) {
        const arr = this.fileList1.map((item) => {
          return item.url;
        });
        data.vehicleFront = arr.toString();
      }
      if (this.fileList2 && this.fileList2.length) {
        const arr = this.fileList2.map((item) => {
          return item.url;
        });
        data.vehicleInterior = arr.toString();
      }
      if (this.fileList3 && this.fileList3.length) {
        const arr = this.fileList3.map((item) => {
          return item.url;
        });
        data.vehicleCabin = arr.toString();
      }
      if (this.fileList4 && this.fileList4.length) {
        const arr = this.fileList4.map((item) => {
          return item.url;
        });
        data.vehicleExterior = arr.toString();
      }
      vehicleUpdate(data)
        .then((res) => {
          if (res.status === 'OK') {
            this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded'));
            this.showVehicleInventoryDialog = false;
            this.getTableData();
          }
        });
    },
    /**
     * @description 获取车辆详情
     */
    getVehicleInfo(row) {
      this.vehicleInfo = row;
      this.drawer = true;
      this.getMaintenanceList();
      this.getInventoryRecordsList();
    },
    closeNewVehicle() {
      this.newVehicle = {
        vehicleNumber: '',
        licensingTime: '',
        vehicleBrand: '',
        vehicleType: '',
        vehicleFrameNumber: '',
        store: ''
      };
    }
  }
};
</script>

<style>
.container{
  padding: 10px;
}
.dialog-footer {
  text-align: right;
}
</style>
