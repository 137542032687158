var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("spareNumberPoo.trackingNumber"),
              "min-width": "150",
              prop: "trackingNumber",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("spareNumberPoo.SortingTime"),
              "min-width": "160",
              prop: "creatTime",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("spareNumberPoo.DropGrille"),
              "min-width": "120",
              prop: "gridNumber",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("spareNumberPoo.CarNumber"),
              "min-width": "150",
              prop: "container",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("spareNumberPoo.SortingCenter"),
              "min-width": "180",
              prop: "warehouseCode",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("spareNumberPoo.deviceID"),
              "min-width": "180",
              prop: "idSorter",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("spareNumberPoo.packageWeight"),
              "min-width": "210",
              prop: "weight",
            },
          }),
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { margin: "10px 0", float: "right" },
        attrs: {
          "current-page": _vm.queryForm.pageNumber,
          "page-size": _vm.queryForm.pageSize,
          "page-sizes": [10, 20, 50, 100],
          total: _vm.queryForm.total,
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }