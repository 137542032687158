import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';

import { sellerService } from '.';
import { getSkipPage } from '@/utils/getSkipPage';
import getMessageError from '@/utils/getMessageError';
import { toast } from '@/utils/programaticToast';
import { getToastContentComponent } from '@/utils/getToastContentComponent';

export function useGetAllSellersRegistered(params) {
  return useQuery({
    queryKey: ['sellersRegistered', params],
    queryFn: async({ signal }) => {
      try {
        const { currentPage, perPage, name, code } = params.value;
        const skip = getSkipPage(currentPage, perPage);

        const res = await sellerService.getAllRegistered({
          skip,
          take: perPage,
          name,
          code
        }, signal);

        return res;
      } catch (error) {
        if (error.response && error.response.status === 400) {
          return [];
        }

        throw error;
      }
    }
  });
}

export function useGetAllSellersNotRegistered(params) {
  return useQuery({
    queryKey: ['sellersNotRegistered', params],
    queryFn: async({ signal }) => {
      try {
        const { currentPage, perPage, filters, name, code } = params.value;
        const skip = getSkipPage(currentPage, perPage);

        const res = await sellerService.getAllNotRegistered({
          skip,
          take: perPage,
          name,
          code
        }, signal);

        return res;
      } catch (error) {
        if (error.response && error.response.status === 400) {
          return [];
        }

        throw error;
      }
    }
  });
}

export function useCreateSeller() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (newSeller) => {
      return sellerService.create(newSeller);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['sellersNotRegistered'] });

      toast.open({
        variant: 'success',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Criado com sucesso', describe: 'Seller foi criada com sucesso em nosso sistema', typeIcon: 'success' }),
        closable: true,
        duration: 4000
      });
    },
    onError: (error) => {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Ocorreu um erro', describe: getMessageError(error), typeIcon: 'error' }),
        closable: true,
        duration: 4000
      });
    }
  });
}

export function useEditSeller() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ newSeller, id }) => {
      return sellerService.edit(newSeller, id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['sellersRegistered'] });

      toast.open({
        variant: 'success',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Editado com sucesso', describe: 'Seller foi editado com sucesso em nosso sistema', typeIcon: 'success' }),
        closable: true,
        duration: 4000
      });
    },
    onError: (error) => {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Ocorreu um erro', describe: getMessageError(error), typeIcon: 'error' }),
        closable: true,
        duration: 4000
      });
    }
  });
}
