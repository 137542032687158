
// 消息类型
export const MSG_TYPE = {
  'sms': {
    code: 'sms',
    text: '短信'
  },
  'email': {
    code: 'email',
    text: '邮箱'
  }
};

// 发送节点,
export const NODE_TYPE = {
  'need_pay_taxes': {
    code: 'need_pay_taxes',
    text: '待缴税'
  },
  'receive': {
    code: 'receive',
    text: '待收货'
  },
  'arrearage': {
    code: 'arrearage',
    text: '欠费'
  }
};

// 发送状态
export const STATE_TYPE = {
  '0': {
    code: '0',
    text: '发送中'
  },
  '1': {
    code: '1',
    text: '发送成功'
  },
  '2': {
    code: '2',
    text: '发送失败'
  }
};
