var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "wrapper-form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        },
      },
    },
    [
      _c(
        "o-field",
        {
          staticClass: "col-span-2",
          attrs: {
            label: _vm.$t("trailer.document"),
            "label-for": "document",
            message: _vm.v$.document.$errors.length
              ? _vm.v$.document.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            directives: [
              {
                name: "mask",
                rawName: "v-mask",
                value: "###########",
                expression: "'###########'",
              },
            ],
            attrs: {
              id: "document",
              placeholder: _vm.$t("trailer.documentPlaceholder"),
            },
            model: {
              value: _vm.formData.document,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "document", $$v)
              },
              expression: "formData.document",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("trailer.plate"),
            "label-for": "licensePlate",
            message: _vm.v$.licensePlate.$errors.length
              ? _vm.v$.licensePlate.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            directives: [
              {
                name: "mask",
                rawName: "v-mask",
                value: "AAA-XXXX",
                expression: "'AAA-XXXX'",
              },
            ],
            attrs: {
              id: "licensePlate",
              placeholder: _vm.$t("trailer.createPlatePlaceholder"),
            },
            model: {
              value: _vm.formData.licensePlate,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "licensePlate", $$v)
              },
              expression: "formData.licensePlate",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        { attrs: { label: _vm.$t("trailer.brand"), "label-for": "brand" } },
        [
          _c("o-input", {
            attrs: {
              id: "brand",
              placeholder: _vm.$t("trailer.createBrandPlaceholder"),
            },
            model: {
              value: _vm.formData.brand,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "brand", $$v)
              },
              expression: "formData.brand",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          staticClass: "col-span-2",
          attrs: {
            label: _vm.$t("trailer.createShed"),
            "label-for": "provider",
            message: _vm.v$.provider.$errors.length
              ? _vm.v$.provider.$errors[0].$message
              : null,
          },
        },
        [
          _c(
            "o-autocomplete",
            {
              attrs: {
                id: "provider",
                placeholder: _vm.$t("trailer.createShedPlaceholder"),
                clearable: "",
                expanded: "",
                data: _vm.listProviders,
              },
              on: { input: _vm.filteredProvider },
              model: {
                value: _vm.formData.provider,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "provider", $$v)
                },
                expression: "formData.provider",
              },
            },
            [
              _c("template", { slot: "empty" }, [
                _vm._v(_vm._s(_vm.$t("trailer.noResults"))),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: "RNTRC",
            "label-for": "rntrc",
            message: _vm.v$.rntrc.$errors.length
              ? _vm.v$.rntrc.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "rntrc",
              placeholder: _vm.$t("trailer.rntrcPlaceholder"),
            },
            model: {
              value: _vm.formData.rntrc,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "rntrc", $$v)
              },
              expression: "formData.rntrc",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("trailer.state"),
            "label-for": "state",
            message: _vm.v$.state.$errors.length
              ? _vm.v$.state.$errors[0].$message
              : null,
          },
        },
        [
          _c(
            "o-select",
            {
              attrs: {
                id: "state",
                placeholder: _vm.$t("trailer.statePlaceholder"),
              },
              model: {
                value: _vm.formData.state,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "state", $$v)
                },
                expression: "formData.state",
              },
            },
            _vm._l(_vm.listUF, function (UF) {
              return _c("option", { key: UF, domProps: { value: UF } }, [
                _vm._v(" " + _vm._s(UF) + " "),
              ])
            }),
            0
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "o-checkbox",
            {
              model: {
                value: _vm.formData.outsourced,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "outsourced", $$v)
                },
                expression: "formData.outsourced",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("trailer.outsourcedDriver")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "FormActions",
        [
          _c("FormActionsPrevious", {
            attrs: { "is-first-step": _vm.isFirstStep },
            on: { handlePrevious: _vm.handlePrevious },
          }),
          _c("FormActionsNext", { attrs: { "is-last-step": _vm.isLastStep } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }