var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "regional",
          attrs: {
            model: _vm.updateForm,
            "label-width": "120px",
            "label-position": _vm.$i18n.locale !== "zh" ? "top" : "right",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("orderCenterCont.ChineseName") + "：",
                          prop: "nameCn",
                          rules: _vm.isCh,
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { maxlength: "100", "show-word-limit": "" },
                          model: {
                            value: _vm.updateForm.nameCn,
                            callback: function ($$v) {
                              _vm.$set(_vm.updateForm, "nameCn", $$v)
                            },
                            expression: "updateForm.nameCn",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("orderCenterCont.EnglishName") + "：",
                          prop: "nameEn",
                          rules: _vm.isEn,
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { maxlength: "100", "show-word-limit": "" },
                          model: {
                            value: _vm.updateForm.nameEn,
                            callback: function ($$v) {
                              _vm.$set(_vm.updateForm, "nameEn", $$v)
                            },
                            expression: "updateForm.nameEn",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { padding: "30px 20px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.regionalSaving(1)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("operationCenter.Cancel")))]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.regionalSaving(2)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("operationCenter.determine")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }