var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderBox" },
    [
      _c(
        "div",
        { staticClass: "searchBox" },
        [
          _c(
            "el-input",
            {
              attrs: {
                placeholder: _vm.$t("newOrder.enterWaybill"),
                clearable: "",
              },
              model: {
                value: _vm.search,
                callback: function ($$v) {
                  _vm.search = $$v
                },
                expression: "search",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { slot: "append" },
                  on: { click: _vm.searchClick },
                  slot: "append",
                },
                [_vm._v(_vm._s(_vm.$t("newOrder.search")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticStyle: { height: "50px" } }),
      _c("div", { staticClass: "orderBtn" }, [
        _c(
          "div",
          { staticClass: "orderBtn-l" },
          [
            _vm.multipleSelection === 0
              ? _c("span", { staticClass: "orderBtn-l-cb" }, [
                  _vm._v(_vm._s(_vm.$t("newOrder.notChoose"))),
                ])
              : _c("span", { staticClass: "orderBtn-l-cb" }, [
                  _vm._v(
                    _vm._s(_vm.$t("newOrder.selected")) +
                      " " +
                      _vm._s(_vm.multipleSelection) +
                      " " +
                      _vm._s(_vm.$t("newOrder.strip"))
                  ),
                ]),
            _c(
              "el-button",
              {
                staticClass: "exportBtn",
                attrs: { icon: "el-icon-upload2" },
                on: { click: _vm.downloadLogistics },
              },
              [_vm._v(_vm._s(_vm.$t("newOrder.export")) + " ")]
            ),
            _c(
              "el-button",
              {
                staticClass: "cancelBtn",
                attrs: { type: "info" },
                on: { click: _vm.cancel },
              },
              [_vm._v(_vm._s(_vm.$t("newOrder.cancel")))]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "orderBtn-r" },
          [
            _c(
              "el-button",
              {
                staticClass: "manualOrder",
                attrs: { icon: "el-icon-edit-outline" },
                on: { click: _vm.handsOrder },
              },
              [_vm._v(_vm._s(_vm.$t("newOrder.manualOrder")) + " ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          ref: "multipleTable",
          staticClass: "tableExpandIcon",
          attrs: {
            data: _vm.tableData,
            load: _vm.getOrderListChild,
            "tree-props": { children: "children", hasChildren: "hasChildren" },
            lazy: "",
            "row-key": "id",
          },
          on: {
            "selection-change": _vm.handleSelectionChange,
            "row-click": _vm.rowClick,
          },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("newOrder.waybillNumber"),
              fixed: "left",
              prop: "orderNumber",
              width: "250",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("newOrder.receiverName"),
              prop: "receiveName",
              width: "150",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("newOrder.quantity"),
              prop: "quantity",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("newOrder.receiveCity"),
              prop: "receiveCity",
              "show-overflow-tooltip": "",
              width: "230",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("newOrder.customerNumber"),
              prop: "customerNumber",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("newOrder.channel"),
              prop: "providerChannelName",
              width: "150",
            },
          }),
          _c("el-table-column", {
            staticClass: "orderStatusStyle",
            attrs: {
              label: _vm.$t("newOrder.waybillStatus"),
              prop: "orderStatus",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _c("div", {}, [
                    scope.row.orderStatus == "toBeCollected"
                      ? _c("span", { staticStyle: { color: "#23d5b2" } })
                      : scope.row.orderStatus == "inTransit"
                      ? _c("span", { staticStyle: { color: "#23d5b2" } })
                      : scope.row.orderStatus == "signForReceipt"
                      ? _c("span", { staticStyle: { color: "#23d5b2" } })
                      : _c("span", { staticStyle: { color: "#23d5b2" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.waybillStatus[scope.row.orderStatus].label
                            )
                          ),
                        ]),
                  ])
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("newOrder.createTime"), prop: "createTime" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.isTormat
                      ? _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                scope.row.createTime
                                  ? "" +
                                      _vm
                                        .dayjs(scope.row.createTime)
                                        .format("DD-MM-YYYY")
                                  : "--"
                              ) +
                              " "
                          ),
                        ])
                      : _c("div", [
                          _vm._v(
                            _vm._s(
                              scope.row.createTime
                                ? "" + scope.row.createTime
                                : "--"
                            )
                          ),
                        ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination" },
        [
          _c("el-pagination", {
            staticStyle: { margin: "10px 0" },
            attrs: {
              "current-page": _vm.queryForm.pageNumber,
              "page-size": _vm.queryForm.pageSize,
              "page-sizes": [100],
              total: _vm.total,
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "current-change": _vm.getPlatformTableData,
              "size-change": _vm.getPlatformTableData,
            },
          }),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          staticClass: "drawerHeader",
          attrs: {
            direction: _vm.msgDirection,
            title: _vm.$t("orderDetails.waybillMsg"),
            visible: _vm.orderMsg,
            "wrapper-closable": true,
            size: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.orderMsg = $event
            },
          },
        },
        [_c("deTails", { attrs: { details: _vm.detailsListP } })],
        1
      ),
      _c(
        "el-drawer",
        {
          staticClass: "drawerHeader",
          attrs: {
            direction: _vm.msgDirection,
            title: _vm.$t("orderImport.orderImport"),
            visible: _vm.orderImportOpen,
            "wrapper-closable": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.orderImportOpen = $event
            },
          },
        },
        [_c("orderImport")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }