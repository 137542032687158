<script >
import { defineProps, computed } from '@vue/composition-api';
import { useVuelidate } from '@vuelidate/core';
import { FormActions, FormActionsNext, FormActionsPrevious } from '@/components/wizard-form/formStepActions';
import { required, minLength, maxLength, email } from '@/utils/validationErrors';
export async function checkFields() {
  return await v$.value.$validate();
}
const __sfc_main = {};
__sfc_main.props = {
  handleNext: {
    type: Function
  },
  isLastStep: {
    type: Boolean
  },
  isFirstStep: {
    type: Boolean
  },
  handlePrevious: {
    type: Function,
    default: () => {}
  },
  formData: {
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const sellerNameAndCode = computed(() => {
    return `${props.formData.sellerCode} / ${props.formData.sellerName}`;
  });
  const customerNameAndCode = computed(() => {
    return `${props.formData.customerCode} / ${props.formData.customerName}`;
  });
  const rules = {
    phone: {
      required,
      minLength: minLength(18),
      maxLength: maxLength(19)
    },
    email: {
      required,
      email
    },
    nameContact: {
      required
    },
    document: {
      required,
      maxLength: maxLength(18),
      minLength: minLength(14)
    }
  };
  const v$ = useVuelidate(rules, props.formData);
  async function handleSubmit() {
    const isValid = await v$.value.$validate();
    if (isValid) props.handleNext();
  }
  return {
    sellerNameAndCode,
    customerNameAndCode,
    v$,
    handleSubmit
  };
};
__sfc_main.components = Object.assign({
  FormActions,
  FormActionsPrevious,
  FormActionsNext
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <form class="wrapper-form" @submit.prevent="handleSubmit">
    <o-field
      :label="$t('seller.seller')"
      label-for="seller"
    >
      <o-input id="seller" v-model="sellerNameAndCode" disabled />
    </o-field>

    <o-field
      :label="$t('seller.client')"
      label-for="customer"
    >
      <o-input id="customer" v-model="customerNameAndCode" disabled />
    </o-field>

    <o-field
      v-mask="['+## (##) ####-####', '+## (##) #####-####']"
      :label="$t('seller.phone')"
      label-for="phone"
      :message="v$.phone.$errors.length ? v$.phone.$errors[0].$message : null"
    >
      <o-input id="phone" v-model="formData.phone" :placeholder="$t('seller.phonePlaceholder')" />
    </o-field>

    <o-field
      :label="$t('seller.email')"
      label-for="email"
      :message="v$.email.$errors.length ? v$.email.$errors[0].$message : null"
    >
      <o-input id="email" v-model="formData.email" :placeholder="$t('seller.emailPlaceholder')" />
    </o-field>

    <o-field
      v-mask="['###.###.###-##', '##.###.###/####-##']"
      :label="$t('seller.document')"
      label-for="document"
      :message="v$.document.$errors.length ? v$.document.$errors[0].$message : null"
    >
      <o-input id="document" v-model="formData.document" :placeholder="$t('seller.documentPlaceholder')" />
    </o-field>

    <o-field
      :label="$t('seller.nameContact')"
      label-for="nameContact"
      :message="v$.nameContact.$errors.length ? v$.nameContact.$errors[0].$message : null"
    >
      <o-input id="nameContact" v-model="formData.nameContact" :placeholder="$t('seller.nameContactPlaceholder')" />
    </o-field>

    <FormActions>
      <FormActionsPrevious :is-first-step="isFirstStep" @handlePrevious="handlePrevious" />
      <FormActionsNext :is-last-step="isLastStep" />
    </FormActions>
  </form>
</template>

<style scoped>
.wrapper-form {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem/* 16px */;
  padding-top: 0.5rem/* 8px */;
  padding-bottom: 0.5rem/* 8px */;
}
</style>
