<template>
  <div class="preStyle">
    <el-form
      ref="formData"
      label-width="120px"
      label-position="left"
      label-suffix=":"
      style="padding: 20px; height: calc(100vh - 68px); overflow-y: auto"
    >
      <el-row>
        <!-- 业务单号 -->
        <el-col :span="12">
          <div class="grid-content bg-purple-light">
            <el-form-item
              prop="referenceNumber"
              :label="$t('receivablePayable.TicketNumber') "
            >
              <span>{{ row.logisticsNumber }}</span>
            </el-form-item>
          </div>
        </el-col>
        <!-- 订单号 -->
        <el-col :span="12">
          <div class="grid-content bg-purple-light">
            <el-form-item
              prop="type"
              :label="$t('orderCenterCont.TheOrderNumber') "
            >
              <span>{{ row.orderNumber }}</span>
            </el-form-item>
          </div>
        </el-col>

        <el-col :span="12">
          <div class="grid-content bg-purple-light">
            <el-form-item
              prop="createTime"
              :label="$t('orderCenterCont.CreateTime') "
            >
              <span>{{ row.createTime }}</span>
            </el-form-item>
          </div>
        </el-col>

        <!-- 客户名称 -->
        <el-col :span="12">
          <div class="grid-content bg-purple-light">
            <el-form-item
              prop="createUser"
              :label="$t('orderCenterCont.CustomerName') "
            >
              <span>{{ row.customerName }}</span>
            </el-form-item>
          </div>
        </el-col>

        <!-- 创建人 -->
        <!-- <el-col :span="12">
          <div class="grid-content bg-purple-light">
            <el-form-item
              prop="createUser"
              :label="$t('operationCenter.creator') "
            >
              <span>{{ props.row.createUser === 0 ? '系统' : userIdAndNameObj[props.row.createUser] }}</span>
            </el-form-item>
          </div>
        </el-col> -->

        <el-row>

          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <el-form-item
                prop="type"
                :label="$t('orderCenterCont.WhetherPushWasSuccessful') "
              >
                <!-- 推送成功 -->
                <el-tag v-if="row.state === 1" type="success">{{
                  $t('operationCenter.succeed')
                }}</el-tag>
                <!-- 失败 -->
                <el-tag
                  v-else
                  type="danger"
                >{{ $t('operationCenter.fail') }}</el-tag>
              <!-- <span>{{
                props.row.state !== 1
                  ? $t('operationCenter.fail')
                  : $t('operationCenter.succeed')
              }}</span> -->
              </el-form-item>
            </div>
          </el-col>
          <!-- 日志描述 -->
          <el-col :span="24">
            <div class="grid-content bg-purple-light">
              <el-form-item
                prop="description"
                :label="$t('orderCenterCont.LogDescription') "
              >
                <span>{{ row.responseMessage }}</span>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </el-row>

      <!-- 请求数据 -->
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-light">
            <el-form-item :label="$t('orderCenterCont.RequestMessages') ">
              <div class="messageCon">
                <div style="text-align: right; line-height: 25px; padding: 5px">
                  <i
                    class="el-icon-copy-document"
                    style="font-size: 20px; cursor: pointer"
                    :title="$t('orderCenterCont.copy')"
                    @click="copyData(row.requestMessage)"
                  />
                </div>
                <pre>{{ row.requestMessage | setStyle }}</pre>
              </div>
            </el-form-item>
          </div>
        </el-col>

        <!-- 响应数据 -->
        <!-- <el-col :span="24">
          <div class="grid-content bg-purple-light">
            <el-form-item :label="$t('orderCenterCont.ResponseData') ">
              <div class="messageCon">
                <div style="text-align: right; line-height: 25px; padding: 5px">
                  <i
                    class="el-icon-copy-document"
                    style="font-size: 20px; cursor: pointer"
                    :title="$t('orderCenterCont.copy')"
                    @click="copyData(props.row.resultData)"
                  />
                </div>
                <pre>{{ props.row.resultData | setStyle }}</pre>
              </div>
            </el-form-item>
          </div>
        </el-col> -->
      </el-row>
    </el-form>
  </div>
</template>

<script>
// import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { formData } from './../utils';

export default {
  name: '',

  filters: {
    setStyle(val) {
      if (val === '') return '';

      return JSON.stringify(
        JSON.parse(val),
        null,
        2
      );
    }
  },

  props: {
    row: {
      type: Object,
      default: () => {
        return {
          ...formData
        };
      }
    }
  },

  data() {
    return {
    };
  },

  created() {
    // this.userIdAndNameObj = getUserIdAndNameObj();
  },

  methods: {
    // 复制
    copyData(data) {
      navigator.clipboard.writeText(data).then(() => {
        this.$message.success(this.$i18n.t('orderCenterCont.ReplicationSucceeded')); // '复制成功'
      }).catch((err) => { console.error(err); });

      // const input = document.getElementById('message2');
      // input.value = data; // 修改文本框的内容
      // input.select(); // 选中文本
      // document.execCommand('copy'); // 执行浏览器复制命令
    }
  }

};
</script>

<style lang="scss" scoped>
.preStyle {
  height: calc(100vh - 70px);
  overflow: hidden;
}
.preStyle .messageCon {
  background: #eee;
}
.preStyle pre {
  max-height: calc(100vh - 280px);
  overflow-y: auto;
  white-space: break-spaces;
  margin: 0;
  padding: 0 10px;
  line-height: 26px;
}
</style>
