var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Box" },
    [
      _c(
        "el-form",
        {
          staticClass: "selectClass",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { width: "auto" }, attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("collectionCenter.createTime") } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          clearable: false,
                          type: "datetimerange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "default-time": ["00:00:00", "23:59:59"],
                          "range-separator": _vm.$t("collectionCenter.to"),
                          "start-placeholder": _vm.$t(
                            "collectionCenter.StartTime"
                          ),
                          "end-placeholder": _vm.$t("collectionCenter.EndTime"),
                        },
                        on: { change: _vm.dateChange },
                        model: {
                          value: _vm.timeValue,
                          callback: function ($$v) {
                            _vm.timeValue = $$v
                          },
                          expression: "timeValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { width: "auto" }, attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("collectionCenter.module") + "：",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t(
                              "collectionCenter.pleaseChoose"
                            ),
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.queryForm.params.moduleCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm.params, "moduleCode", $$v)
                            },
                            expression: "queryForm.params.moduleCode",
                          },
                        },
                        _vm._l(_vm.moduleList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { width: "auto" }, attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("collectionCenter.titleName") + "：",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("collectionCenter.pleaseInput"),
                          clearable: "",
                        },
                        model: {
                          value: _vm.queryForm.params.titleName,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm.params, "titleName", $$v)
                          },
                          expression: "queryForm.params.titleName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:system-manage:operation-guide:find",
                      expression: "'btn:system-manage:operation-guide:find'",
                      arg: "remove",
                    },
                  ],
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-search",
                  },
                  on: { click: _vm.searchClick },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.search")))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:system-manage:operation-guide:reset",
                      expression: "'btn:system-manage:operation-guide:reset'",
                      arg: "remove",
                    },
                  ],
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetClick },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:system-manage:operation-guide:add",
                      expression: "'btn:system-manage:operation-guide:add'",
                      arg: "remove",
                    },
                  ],
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-plus",
                  },
                  on: { click: _vm.addClick },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.add")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-table",
        {
          ref: "tableRef",
          attrs: { "max-height": 600, border: "", data: _vm.tableDate },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.ind"),
              type: "index",
              width: "60",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          (_vm.queryForm.pageNumber - 1) *
                            _vm.queryForm.pageSize +
                            scope.$index +
                            1
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "titleName",
              label: _vm.$t("collectionCenter.titleName"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "moduleCode",
              label: _vm.$t("collectionCenter.module"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.moduleCode == 1
                            ? _vm.$t("collectionCenter.collect")
                            : scope.row.moduleCode == 2
                            ? _vm.$t("collectionCenter.transfer")
                            : scope.row.moduleCode == 3
                            ? _vm.$t("collectionCenter.dispatch")
                            : scope.row.moduleCode == 4
                            ? _vm.$t("collectionCenter.finance")
                            : scope.row.moduleCode == 5
                            ? _vm.$t("collectionCenter.trackingPackage")
                            : scope.row.moduleCode == 6
                            ? _vm.$t("collectionCenter.BasicInformation")
                            : scope.row.moduleCode == 7
                            ? _vm.$t("collectionCenter.serviceQuality")
                            : scope.row.moduleCode == 0
                            ? _vm.$t("collectionCenter.other")
                            : ""
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "versionInfo",
              label: _vm.$t("editingRegistration.editions"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "createRealName",
              label: _vm.$t("collectionCenter.creator"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "createTime",
              label: _vm.$t("collectionCenter.CreationTime"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "updateRealName",
              label: _vm.$t("collectionCenter.ModifiedBy"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "updateTime",
              label: _vm.$t("collectionCenter.ModificationTime"),
            },
          }),
          _vm.isAllowDownload
            ? _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  align: "center",
                  label: _vm.$t("collectionCenter.enclosureFJ"),
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return _vm._l(
                          scope.row.attachment,
                          function (item, index) {
                            return _c("div", { key: index }, [
                              _vm._v(
                                " " +
                                  _vm._s(item.file_name) +
                                  "." +
                                  _vm._s(item.file_type) +
                                  " "
                              ),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "#83d4f8",
                                    cursor: "pointer",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.uploadUrl(item.file_url)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("GlobalSetObj.Download"))
                                  ),
                                ]
                              ),
                            ])
                          }
                        )
                      },
                    },
                  ],
                  null,
                  false,
                  2900994838
                ),
              })
            : _vm._e(),
          _vm.isEdit
            ? _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  align: "center",
                  label: _vm.$t("collectionCenter.operation"),
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#83d4f8",
                                cursor: "pointer",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.editRow(scope.row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("collectionCenter.modify")))]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1550794311
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-pagination", {
            staticStyle: { margin: "10px 0" },
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              "current-page": _vm.queryForm.pageNumber,
              "page-sizes": [10, 20, 50, 100, 500, 1000],
              "page-size": _vm.queryForm.pageSize,
              total: _vm.total,
              background: "",
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "current-change": _vm.getPage,
              "size-change": _vm.getPageSize,
            },
          }),
        ],
        1
      ),
      _vm.drawerIsOpen
        ? _c(
            "div",
            [
              _c(
                "el-drawer",
                {
                  attrs: {
                    direction: _vm.direction,
                    title: _vm.drawerTitle,
                    visible: _vm.drawerIsOpen,
                    size: "50%",
                    "before-close": _vm.handleClose,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.drawerIsOpen = $event
                    },
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-form",
                        {
                          ref: "drawerFormRef",
                          staticClass: "selectClass drawer",
                          staticStyle: { "margin-bottom": "20px" },
                          attrs: { model: _vm.submitForm, rules: _vm.rules },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 20 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "titleName",
                                        label:
                                          _vm.$t("collectionCenter.titleName") +
                                          "：",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "300",
                                          "show-word-limit": "",
                                          type: "textarea",
                                          placeholder: _vm.$t(
                                            "collectionCenter.pleaseInput"
                                          ),
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.submitForm.titleName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.submitForm,
                                              "titleName",
                                              $$v
                                            )
                                          },
                                          expression: "submitForm.titleName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 10 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "moduleCode",
                                        label:
                                          _vm.$t("collectionCenter.module") +
                                          "：",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "collectionCenter.pleaseChoose"
                                            ),
                                            clearable: "",
                                            filterable: "",
                                          },
                                          model: {
                                            value: _vm.submitForm.moduleCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.submitForm,
                                                "moduleCode",
                                                $$v
                                              )
                                            },
                                            expression: "submitForm.moduleCode",
                                          },
                                        },
                                        _vm._l(
                                          _vm.moduleList,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 10 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label:
                                          _vm.$t(
                                            "editingRegistration.editions"
                                          ) + "：",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "collectionCenter.pleaseInput"
                                          ),
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.submitForm.versionInfo,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.submitForm,
                                              "versionInfo",
                                              $$v
                                            )
                                          },
                                          expression: "submitForm.versionInfo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "fileClass",
                                  staticStyle: { width: "auto" },
                                  attrs: { span: 24 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label:
                                          _vm.$t(
                                            "collectionCenter.enclosureFJ"
                                          ) + "：",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-upload",
                                        {
                                          staticClass: "uploadFileClass",
                                          attrs: {
                                            action: "",
                                            limit: 5,
                                            "show-file-list": true,
                                            "file-list": _vm.fileList,
                                            "before-upload": _vm.beforeUpload,
                                            "http-request": _vm.uploadClick,
                                            "on-remove": _vm.handleRemove,
                                          },
                                        },
                                        [
                                          _c("el-button", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "spareNumberPoo.SelectTheFile"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("br"),
                                          _c("span", [
                                            _vm._v(
                                              "*" +
                                                _vm._s(
                                                  _vm.$t(
                                                    "collectionCenter.max5Files"
                                                  )
                                                )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm.isSave && _vm.fileList.length <= 0
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: { color: "#F56C6C" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "collectionCenter.notNull"
                                                  )
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("el-button", { on: { click: _vm.handleClose } }, [
                            _vm._v(_vm._s(_vm.$t("collectionCenter.cancel"))),
                          ]),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "success" },
                              on: { click: _vm.saveClick },
                            },
                            [_vm._v(_vm._s(_vm.$t("collectionCenter.Save")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }