import { cloneDeep } from 'lodash-es';
import { apiPayableSiteExpenseGetPage } from '@/api/finance/receivables';
import { apiProviderPage } from '@/api/finance/basicData';
import { apiCommonSite } from '@/api/logisticsOrders';
import { getUserPage } from '@/api/user';
import { feePage } from '@/api/finance/partition';
import store from '@/store';

/**
 * 获取列表数据
 * @param {{}} query 搜索字段
 * @returns {Promise<any[]>} 返回结果
   */
export const asyncGetList = (query) => {
  const queryForm = cloneDeep(query);

  queryForm.params.ids = [];

  return apiPayableSiteExpenseGetPage(queryForm).then(res => {
    return res;
  }).catch(err => {
    console.error(err);
  });
};

/**
 * 获取主体、网点、操作人、费用类型的下拉数据
 * @returns {Promise<any[]>} 返回结果
 */
export const initGetData = (() => {
  // const FEES_PAYABLE = 'FEES_PAYABLE';
  const providerList = 'providerList'; // 应付主体选项
  const outletsList = 'outletsList'; // 获取站点
  const operatorList = 'operatorList'; // 操作人
  // const feeTypeOption = 'feeTypeOption'; // 费用类型

  // const row = {
  //   [providerList]: [],
  //   [outletsList]: [],
  //   [operatorList]: [],
  //   [feeTypeOption]: []
  // };

  // 从缓存中提前数据
  const getStorageData = (key) => {
    // const data = JSON.parse(sessionStorage.getItem(FEES_PAYABLE)) || {};

    // if (data && data[key] && data[key].length > 0) {
    //   return data[key];
    // }
    // return undefined;

    if (store.state.feesPayable[key] && store.state.feesPayable[key].length > 0) {
      return store.state.feesPayable[key];
    }
    return undefined;
  };

  /**
   * 获取主体数据
   * @returns
   */
  const Providerparam = {
    pageSize: 9999999,
    pageNumber: 1,
    params: {
      state: 1
    }
  };
  const getProviderPage = () => {
    const arr = getStorageData(providerList);

    if (arr) {
      return Promise.resolve(arr);
    }

    return apiProviderPage(Providerparam, false).then(res => {
      if (res.status === 'OK') {
        const { records } = res.data;
        // row[providerList] = records;

        store.commit('feesPayable/SET_PROVIDER_LIST', records);
        return records;
      }
      throw new Error('获取主体数据失败');     
    }).catch(err => {
      console.error(err);
      return [];
    });
  };

  /**
   * 获取网点数据
   * @returns
   */
  const Siteparam = {
    pageNumber: 1,
    pageSize: 9999999,
    params: {
      siteStatus: undefined,
      name: ''
    }
  };
  const getSitePageList = () => {
    const arr = getStorageData(outletsList);

    if (arr) {
      return Promise.resolve(arr);
    }
    return apiCommonSite(Siteparam, false).then(res => {
      if (res.status === 'OK') {
        const { records } = res.data;
        const list = records.map((item) => {
          return {
            id: item.id,
            name: item.name
          };
        });
        // row[outletsList] = list;

        store.commit('feesPayable/SET_OUTLETS_LIST', list);
        return records;
      }
      throw new Error('获取网点数据失败');     
    }).catch(err => {
      console.error(err);
      return [];
    });
  };

  /**
   * 获取操作人
   */
  const OperatorqueryForm = {
    pageSize: 9999999,
    pageNumber: 1,
    params: {
      realName: '',
      accountState: 1
    }
  };
  const getOperatorList = () => {
    const arr = getStorageData(operatorList);

    if (arr) {
      return Promise.resolve(arr);
    }
    return getUserPage(OperatorqueryForm, false).then((response) => {
      if (response.status === 'OK') {
        const { records } = response.data;
        // row[operatorList] = records;

        store.commit('feesPayable/SET_OPERATOR_LIST', records);
        return records;
      }
      throw new Error('获取操作人数据失败');     
    }).catch(err => {
      console.error(err);
      return [];
    });
  };

  /**
   * 获取费用类型
   * @param {*} type 业务类型 1 安俊 2 第三方 3 AJD 4 DSP
   * @returns
   */
  const feePageparam = {
    orderDirection: 'DESC',
    pageNumber: 1,
    pageSize: 10000,
    params: {
      state: 1,
      attribute: 2, // 1 应收，2 应付
      businessType: ''
    }
  };
  const getExpenseType = (type) => {
    console.log(type, 'getExpenseType');
    feePageparam.params.businessType = type;

    return feePage(feePageparam, false).then(res => {
      // @ts-ignore
      if (res.status === 'OK') {
        const { records } = res.data;
        // row[feeTypeOption] = records;

        store.commit('feesPayable/SET_FEE_TYPE_OPTION', records);
        return records;
      }
      throw new Error('获取费用类型数据失败');     
    }).catch(err => {
      console.error(err);
      return [];
    });
  };
  // 初始化数据
  const initGetData = (obj) => {
    const arr = [getProviderPage(), getSitePageList(), getOperatorList(), getExpenseType(obj.payableType)];
    return Promise.all(arr).then(res => {
      console.log(res, 'initGetData-缓存数据')
      // sessionStorage.setItem(FEES_PAYABLE, JSON.stringify(row));
      return res;
    });
  };

  return initGetData;
})();
