<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>{{ $t('basicData.订单信息') }}</span>
      <el-button
        style="float: right;"
        type="primary"
        size="small"
        @click="isAdd = true">{{ $t('basicData.AddOrder') }}</el-button>
    </div>

    <el-table
      :data="tableData"
      style="width: 100%"
      border
      height="300"
    >
      <!--业务单号-->
      <el-table-column prop="waybillNumber" :label="$t('receivablePayable.TicketNumber')"/>
      <!-- 订单号 -->
      <el-table-column v-if="[3,4].includes(queryForm.params.payableType)" prop="orderNumber" :label="$t('CenterForSorting.orderNumber')"/>
      <!-- 揽收单号 -->
      <el-table-column v-if="[1,2].includes(queryForm.params.payableType)" prop="orderNumber" :label="$t('receivablePayable.CollectionTrackingNumber')"/>
      <!-- 客户单号 -->
      <el-table-column v-if="[3,4,5].includes(queryForm.params.payableType)" prop="customerNumber" :label="$t('collectionCenter.customerNum')"/>
      <!-- 业务主题 -->
      <el-table-column prop="providerName" :label="$t('GlobalSetObj.businessEntity')"/>
      <!--网点-->
      <el-table-column prop="siteName" :label="$t('GlobalSetObj.Outlets')"/>
      <!--客户-->
      <el-table-column prop="customerAlias" :label="$t('GlobalSetObj.client')"/>
      <!--计费重量-->
      <el-table-column prop="chargeWeight" :label="$t('collectionCenter.chargedWeight')"/>
    </el-table>

    <el-dialog
      :title="$t('orderCenterCont.AddOrder')"
      :visible.sync="isAdd"
      :close-on-click-modal="false"
      :append-to-body="true"
      width="500px"
    >
      <OrderDialog
        :business-type="getBusinessType"
        :payable-type="payableType"
        :is-business-type-select="isBusinessTypeSelect"
        @submit="onAdd"
        @close="isAdd = false"
      />
    </el-dialog>

  </el-card>
</template>

<script>
import { cloneDeep } from 'lodash-es';
import OrderDialog from './orderDialog.vue';

export default {
  name: '',

  components: {
    OrderDialog
  },

  props: {
    // 列表选中的
    selectData: {
      type: Array,
      default: () => {
        return [];
      }
    },

    // 外部搜索条件
    queryForm: {
      type: Object,
      default: () => {
        return {};
      }
    },

    // 是否打开了添加弹窗
    isOpen: {
      type: Boolean,
      default: false
    },
    payableType: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      tableData: [],
      isAdd: false
    };
  },

  computed: {
    // 设置是否默认
    getBusinessType() {
      const { businessType } = this.queryForm.params;
      return businessType;
    },

    // 是否禁用 业务类型选择框
    isBusinessTypeSelect() {
      return true;
    }
  },

  watch: {
    'selectData': {
      handler(val) {
        if (!this.isOpen) return;

        if (val.length === 0) return;

        const arr = this.setSelectDataType(val);
        this.onAdd(arr);
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    // 添加成功后
    onAdd(arr, siteId) {
      this.isAdd = false;
      this.$emit('getSite', siteId);
      // 重复校验
      const addArr = this.findCommonElements(arr);
      if (addArr.length === 0) return;

      // 添加新的数据
      const newArr = addArr.map(item => {
        item.orderNumber = item.scanOrderNumber || item.orderNumber;
        return item;
      });
      this.tableData.push(...newArr);
    },

    // 去重处理
    findCommonElements(arr) {
      const addArr = this.tableData.map((item) => {
        return item.waybillNumber;
      });

      // 去重
      return arr.filter(item => {
        return !addArr.includes(item.waybillNumber);
      });
    },

    // 选中的数据处理
    setSelectDataType(arr) {
      return arr.map(item => {
        const { id, businessType, waybillNumber, orderNumber, customerNumber, providerName, siteName, customerAlias, chargeWeight } = item;
        const obj = {
          id,
          businessType, // 业务类型
          waybillNumber, // 业务订单
          orderNumber, // 订单号
          customerNumber, // 客户单号
          providerName, // 业务主体
          siteName, // 网点
          customerAlias, // 客户
          chargeWeight// 计费重量
        };
        return obj;
      });
    },
    // 为父级调用服务的
    getWaybillNumberList() {
      return cloneDeep(this.tableData);
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
