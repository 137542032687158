<script >
import { defineProps } from '@vue/composition-api';
import { useQueryClient } from '@tanstack/vue-query';
import { useModal } from '@/composable/useModal';
import { default as FormEditBase } from './form/formEditBase';
import { default as DropdownTable } from '@/components/dropdownTable';
import { default as Alert } from '@/components/alert';
import getMessageError from '@/utils/getMessageError';
import { addingLoading, closeLoading } from '@/utils/handleControlLoading';
import { baseService } from '@/api/transfer/base';
import { toast } from '@/utils/programaticToast';
import { getToastContentComponent } from '@/utils/getToastContentComponent';
const __sfc_main = {};
__sfc_main.props = {
  row: {
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;

  /* edit base */
  const {
    isOpen: isOpenModalEdit,
    handleVisibility: handleVisibilityModalEdit
  } = useModal();
  const {
    isOpen: isOpenModalDelete,
    handleVisibility: handleVisibilityModalDelete
  } = useModal();
  const queryClient = useQueryClient();
  async function handleDelete() {
    try {
      addingLoading();
      await baseService.delete(props.row.id);
      toast.open({
        variant: 'success',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Excluído com sucesso',
          describe: 'Base foi excluída com sucesso',
          typeIcon: 'success'
        }),
        closable: true,
        duration: 4000
      });
      queryClient.invalidateQueries({
        queryKey: ['bases']
      });
      handleVisibilityModalDelete();
    } catch (error) {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Ocorreu um erro',
          describe: getMessageError(error),
          typeIcon: 'error'
        }),
        closable: true,
        duration: 4000
      });
    } finally {
      closeLoading();
    }
  }
  return {
    isOpenModalEdit,
    handleVisibilityModalEdit,
    isOpenModalDelete,
    handleVisibilityModalDelete,
    handleDelete
  };
};
__sfc_main.components = Object.assign({
  DropdownTable,
  FormEditBase,
  Alert
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <DropdownTable aria-role="list">
      <o-dropdown-item aria-role="listitem" @click="handleVisibilityModalEdit">
        {{ $t('base.edit') }}
      </o-dropdown-item>

      <o-dropdown-item aria-role="listitem" @click="handleVisibilityModalDelete">
        {{ $t('base.delete') }}
      </o-dropdown-item>
    </DropdownTable>

    <o-modal :active="isOpenModalEdit" width="560" @close="handleVisibilityModalEdit">
      <div>
        <h2 class="header-2">{{ $t('base.editBaseTitle') }}</h2>
        <p class="paragraph">{{ $t('base.editBaseParagraph') }}</p>
      </div>

      <FormEditBase :base-data="row" :close-modal="handleVisibilityModalEdit" />
    </o-modal>

    <Alert
      :active="isOpenModalDelete"
      @handleClose="handleVisibilityModalDelete"
    >
      <template #title> {{ $t('base.confirmDeletionTitle') }} </template>
      <template #message>
        {{ $t('base.confirmDeletionParagraph') }} {{ row.shipping_company_base }}
      </template>

      <template #buttons>
        <o-button variant="outline" size="md" @click="handleVisibilityModalDelete"> {{ $t('base.cancel') }} </o-button>
        <o-button variant="primary" size="md" @click="handleDelete"> {{ $t('base.confirmDelete') }} </o-button>
      </template>
    </Alert>
  </div>
</template>
