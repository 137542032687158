var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vtag-form__container" }, [
    _c("h1", { staticClass: "header-3" }, [_vm._v("V-Tags no veículo")]),
    _c(
      "ul",
      { staticClass: "vtags-items" },
      [
        _vm._l(_vm.form.vtag_maintained, function (item, index) {
          return _c("li", { key: index, staticClass: "header-3 vtag__item" }, [
            _vm._v(_vm._s(item)),
          ])
        }),
        _vm.form.vtag_maintained && _vm.form.vtag_maintained.length === 0
          ? _c("li", [_vm._v("Nenhuma v-tag no veículo")])
          : _vm._e(),
      ],
      2
    ),
    _c(
      "form",
      {
        staticClass: "wrapper-form",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.handleSubmit($event)
          },
        },
      },
      [
        _c(
          "o-field",
          { attrs: { label: "V-tag de entrada", "label-for": "v-tag-in" } },
          [
            _c("o-inputitems", {
              ref: "inputVtagIn",
              attrs: {
                id: "v-tag-in",
                placeholder: "Selecione a v-tag que foi carregada",
                data: _vm.filteredVtags,
                "allow-new": false,
                "open-on-focus": false,
                autocomplete: true,
                "allow-autocomplete": true,
              },
              on: { typing: _vm.getFilteredVtags },
              scopedSlots: _vm._u([
                {
                  key: "selected",
                  fn: function (ref) {
                    var items = ref.items
                    return _vm._l(items, function (item, index) {
                      return _c(
                        "span",
                        {
                          key: index,
                          staticClass: "item_selected",
                          attrs: { rounded: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$refs.inputVtagIn.removeItem(
                                index,
                                $event
                              )
                            },
                          },
                        },
                        [
                          _vm._v(" " + _vm._s(item) + " "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-left": "5px",
                                cursor: "pointer",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.inputVtagIn.removeItem(
                                    index,
                                    $event
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "lucide lucide-x",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "16",
                                    height: "16",
                                    viewBox: "0 0 24 24",
                                    fill: "none",
                                    stroke: "currentColor",
                                    "stroke-width": "2",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                  },
                                },
                                [
                                  _c("path", { attrs: { d: "M18 6 6 18" } }),
                                  _c("path", { attrs: { d: "m6 6 12 12" } }),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    })
                  },
                },
              ]),
              model: {
                value: _vm.form.vtag_in,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "vtag_in", $$v)
                },
                expression: "form.vtag_in",
              },
            }),
          ],
          1
        ),
        _c(
          "o-field",
          { attrs: { label: "V-tag de saída", "label-for": "v-tag-out" } },
          [
            _c("o-inputitems", {
              ref: "inputVtagOut",
              attrs: {
                id: "v-tag-out",
                placeholder: "Selecione a v-tag que foi descarregada",
                data: _vm.form.vtag_maintained,
                "allow-new": false,
                "open-on-focus": false,
                autocomplete: true,
                "allow-autocomplete": true,
              },
              on: {
                add: function (item) {
                  return _vm.handleAddVtag(item, _vm.props.form.vtag_maintained)
                },
                remove: function (item) {
                  return _vm.handleRemoveVTag(
                    item,
                    _vm.props.form.vtag_maintained
                  )
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "selected",
                  fn: function (ref) {
                    var items = ref.items
                    return _vm._l(items, function (item, index) {
                      return _c(
                        "span",
                        {
                          key: index,
                          staticClass: "item_selected",
                          attrs: { rounded: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$refs.inputVtagOut.removeItem(
                                index,
                                $event
                              )
                            },
                          },
                        },
                        [
                          _vm._v(" " + _vm._s(item) + " "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-left": "5px",
                                cursor: "pointer",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.inputVtagOut.removeItem(
                                    index,
                                    $event
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "lucide lucide-x",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "16",
                                    height: "16",
                                    viewBox: "0 0 24 24",
                                    fill: "none",
                                    stroke: "currentColor",
                                    "stroke-width": "2",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                  },
                                },
                                [
                                  _c("path", { attrs: { d: "M18 6 6 18" } }),
                                  _c("path", { attrs: { d: "m6 6 12 12" } }),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    })
                  },
                },
              ]),
              model: {
                value: _vm.form.vtag_out,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "vtag_out", $$v)
                },
                expression: "form.vtag_out",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }