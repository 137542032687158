<template>
  <el-dropdown trigger="click" class="international" @command="handleSetLanguage">
    <div>
      <!-- <svg-icon class-name="international-icon" icon-class="language" /> -->
      <svg
        t="1642340950488"
        class="icon"
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
        p-id="1903"
        width="26"
        height="26"
      >
        <path
          d="M864 64a96 96 0 0 1 96 96v704a96 96 0 0 1-96 96H160a96 96 0 0 1-96-96V160a96 96 0 0 1 96-96h704z m0 64H160a32 32 0 0 0-32 32v704a32 32 0 0 0 32 32h704a32 32 0 0 0 32-32V160a32 32 0 0 0-32-32z m-322.4 256c0-31.456 40.64-44.032 58.4-18.08l133.6 195.168V384a32 32 0 0 1 64 0v280.48c0 31.456-40.64 44.032-58.4 18.08l-133.6-195.168v177.088a32 32 0 1 1-64 0z"
          fill="#409eff"
          p-id="1904"
          data-spm-anchor-id="a313x.7781069.0.i15"
          class="selected"
        />
        <path
          d="M448 352a32 32 0 0 1 0 64H288v80h160a32 32 0 0 1 31.776 28.256L480 528a32 32 0 0 1-32 32H288v72.48h160a32 32 0 1 1 0 64H256a32 32 0 0 1-32-32V384a32 32 0 0 1 32-32z"
          fill="#FAAC08"
          p-id="1905"
        />
      </svg>
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        v-for="(val, key) in languageObj" :key="val.code" :disabled="language === key"
        :command="key"
      >
        {{ val.txt }}
      </el-dropdown-item>
      <!-- <el-dropdown-item :disabled="language==='zh'" command="zh">
        中文
      </el-dropdown-item>
      <el-dropdown-item :disabled="language==='en'" command="en">
        English
      </el-dropdown-item>
      <el-dropdown-item :disabled="language==='pu'" command="pu">
        Português
      </el-dropdown-item> -->
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { LANGUAGE_OBJ } from '@/enums/operationCenterEnums';
import { setPageTitle } from '@/utils/get-page-title';

export default {
  computed: {
    language() {
      return this.$store.getters.language;
    }
  },

  created() {
    this.languageObj = LANGUAGE_OBJ;
  },

  methods: {
    handleSetLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.dispatch('language/setLanguage', lang);
      setTimeout(() => {
        // this.$router.push(this.$route.path);
        document.title = setPageTitle(this.$route.meta.title); // 修改页面title
        console.log('document.title', document.title);
      }, 100);

      this.$message({
        message: 'Switch Language Success',
        type: 'success'
      });
      location.reload();
    }
  }
};
</script>
