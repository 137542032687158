var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticStyle: { "margin-bottom": "10px" } },
      [
        _c(
          "el-card",
          {
            staticClass: "box-card",
            staticStyle: { "margin-bottom": "10px", position: "relative" },
          },
          [
            _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
              _vm._v("1." + _vm._s(_vm.$t("GlobalSetObj.scannerTip"))),
            ]),
            _c("div", [
              _vm._v("2." + _vm._s(_vm.$t("GlobalSetObj.beforeScannerTip"))),
            ]),
          ]
        ),
        _c("el-card", { staticClass: "box-card" }, [
          _c(
            "div",
            {
              staticClass: "titleStyle",
              staticStyle: { "margin-bottom": "10px" },
            },
            [_vm._v(_vm._s(_vm.$t("GlobalSetObj.ScanningEntry")))]
          ),
          _c(
            "div",
            [
              _c("span", { staticStyle: { "margin-right": "10px" } }, [
                _vm._v(_vm._s(_vm.$t("GlobalSetObj.bagNumber"))),
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "inline-block",
                    width: "50%",
                    "margin-right": "20px",
                  },
                },
                [
                  _c("el-input", {
                    ref: "inputRef",
                    attrs: {
                      placeholder: _vm.$t("GlobalSetObj.scannerOrinput"),
                      clearable: "",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.inboundOperations(1)
                      },
                    },
                    model: {
                      value: _vm.bagNo,
                      callback: function ($$v) {
                        _vm.bagNo = $$v
                      },
                      expression: "bagNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.inboundOperations(2)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.ConfirmReceipt")) + " ")]
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 24 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple" },
                [
                  _c(
                    "el-card",
                    { staticClass: "box-card" },
                    [
                      _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                        _c("span", { staticClass: "titleStyle" }, [
                          _vm._v(_vm._s(_vm.$t("GlobalSetObj.ScanRecord"))),
                        ]),
                        _c("span", { staticStyle: { float: "right" } }, [
                          _vm._v(
                            _vm._s(_vm.$t("GlobalSetObj.ReceivedGoods")) + ": "
                          ),
                          _c("span", [_vm._v(_vm._s(_vm.total))]),
                        ]),
                      ]),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.tableDataRight,
                            "header-cell-style": {
                              background: "#f2f2f2",
                              color: "black",
                            },
                            height: _vm.height,
                            border: "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.bagNumber"),
                              "min-width": "200",
                              prop: "instoreBigBagNo",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.package"),
                              "min-width": "200",
                              prop: "packageCount",
                            },
                          }),
                          _c(
                            "el-table-column",
                            {
                              attrs: {
                                "min-width": "200",
                                prop: "weighingWeight",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "5px" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("GlobalSetObj.pesoTotal")
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-tooltip",
                                      { attrs: { placement: "top" } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "content" },
                                            slot: "content",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("GlobalSetObj.coleta")
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "QuestionMark",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            2
                          ),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.operator"),
                              "min-width": "200",
                              prop: "createUserStr",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("el-pagination", {
                        staticStyle: {
                          "margin-top": "10px",
                          float: "right",
                          "margin-bottom": "20px",
                        },
                        attrs: {
                          "current-page": _vm.page.pageNumber,
                          "page-size": _vm.page.pageSize,
                          "page-sizes": [10, 20, 50, 100],
                          "pager-count": 5,
                          total: _vm.page.total,
                          background: "",
                          layout: "total, sizes, prev, pager, next, jumper",
                        },
                        on: {
                          "size-change": _vm.SizeChange,
                          "current-change": _vm.CurrentChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
      },
      [
        _c("audio", {
          ref: "errRef",
          attrs: { src: _vm.errorMp3, controls: "controls" },
        }),
        _c("audio", {
          ref: "okRef",
          attrs: { src: _vm.okMp3, controls: "controls" },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }