var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WrapperHandleError",
    { attrs: { error: _vm.error, "is-error": _vm.isError } },
    [
      _c("SectionHeader", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("h1", { staticClass: "header-1" }, [
                  _vm._v(" " + _vm._s(_vm.$t("order.generatedOrders")) + " "),
                ]),
                _c(
                  "div",
                  { staticClass: "wrapperButtons" },
                  [
                    _c(
                      "o-button",
                      {
                        attrs: { variant: "outline", size: "md" },
                        on: { click: _vm.handleDownload },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("order.downloadMaster")) + " "
                        ),
                      ]
                    ),
                    _c(
                      "o-button",
                      {
                        attrs: { variant: "primary", size: "md" },
                        on: { click: _vm.handleVisibilityModalCreate },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("order.createOrder")) + " ")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "filter",
            fn: function () {
              return [
                _c(
                  "o-field",
                  {
                    staticClass: "flex-1",
                    attrs: {
                      label: _vm.$t("order.orderDate"),
                      "label-for": "date",
                    },
                  },
                  [
                    _c("o-input", {
                      attrs: {
                        id: "date",
                        type: "date",
                        placeholder: _vm.$t("order.orderDatePlaceholder"),
                      },
                      model: {
                        value: _vm.currentDate,
                        callback: function ($$v) {
                          _vm.currentDate = $$v
                        },
                        expression: "currentDate",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "SkeletonTable",
        { attrs: { "is-loading": _vm.isLoadingTable } },
        [
          !_vm.isLoading
            ? _c(
                "o-table",
                {
                  staticClass: "space-m-y-32",
                  attrs: {
                    data: _vm.tableData,
                    "row-key": "routeId",
                    bordered: "",
                    paginated: "",
                    "backend-pagination": "",
                    total: _vm.tableTotal,
                    "per-page": _vm.queryParams.perPage,
                    "current-page": _vm.queryParams.currentPage,
                    "pagination-simple": false,
                  },
                  on: {
                    "update:currentPage": function ($event) {
                      return _vm.$set(_vm.queryParams, "currentPage", $event)
                    },
                    "update:current-page": function ($event) {
                      return _vm.$set(_vm.queryParams, "currentPage", $event)
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "empty",
                        fn: function () {
                          return [
                            _vm._v(
                              " " + _vm._s(_vm.$t("order.noResults")) + " "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1555475023
                  ),
                },
                [
                  _c("o-table-column", {
                    attrs: {
                      field: "order_vehicle_initial_kilometers",
                      label: _vm.$t("order.initialKilometers"),
                      width: "160",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    props.row
                                      .order_vehicle_initial_kilometers || 0
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3372662499
                    ),
                  }),
                  _c("o-table-column", {
                    attrs: {
                      field: "order_vehicle_arrival_kilometers",
                      label: _vm.$t("order.arrivalKilometers"),
                      width: "160",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    props.row
                                      .order_vehicle_arrival_kilometers || 0
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2673018766
                    ),
                  }),
                  _c("o-table-column", {
                    attrs: {
                      field: "order_vehicle_total_kilometers",
                      label: _vm.$t("order.totalKilometers"),
                      width: "160",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    props.row.order_vehicle_total_kilometers ||
                                      0
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1081405599
                    ),
                  }),
                  _c("o-table-column", {
                    attrs: {
                      field: "driver_name",
                      label: _vm.$t("order.driver"),
                      width: "160",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _vm._v(" " + _vm._s(props.row.driver_name) + " "),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1914472539
                    ),
                  }),
                  _c("o-table-column", {
                    attrs: {
                      field: "vehicle_license_plate",
                      label: _vm.$t("order.licensePlate"),
                      width: "160",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(props.row.vehicle_license_plate) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      379332850
                    ),
                  }),
                  _c("o-table-column", {
                    attrs: {
                      field: "totalPackages",
                      label: _vm.$t("order.totalPackages"),
                      width: "160",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _vm._v(
                                " " + _vm._s(props.row.totalPackages) + " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3248189654
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "o-modal",
        {
          attrs: { active: _vm.isOpenModalCreate, width: "720" },
          on: { close: _vm.handleVisibilityModalCreate },
        },
        [
          _c("div", [
            _c("h2", { staticClass: "header-2" }, [
              _vm._v(_vm._s(_vm.$t("order.createOrderTitle"))),
            ]),
            _c("p", { staticClass: "paragraph" }, [
              _vm._v(_vm._s(_vm.$t("order.createOrderParagraph"))),
            ]),
          ]),
          _c("FormCreateOrder", {
            attrs: { "close-modal": _vm.handleVisibilityModalCreate },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }