var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "165px",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("WorkCentreTable.ticketDescribe"),
                prop: "followUpDescribe",
              },
            },
            [
              _vm.isTinymce
                ? _c("tinymce", {
                    attrs: { height: 200 },
                    model: {
                      value: _vm.ruleForm.followUpDescribe,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "followUpDescribe", $$v)
                      },
                      expression: "ruleForm.followUpDescribe",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("WorkCentreTable.FollowUpStatus"),
                prop: "followUpStatus",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.followUpStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "followUpStatus", $$v)
                    },
                    expression: "ruleForm.followUpStatus",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "0" } }, [
                    _vm._v(_vm._s(_vm.$t("WorkCentreTable.NormalFollowUp"))),
                  ]),
                  _c("el-radio-button", { attrs: { label: "1" } }, [
                    _vm._v(_vm._s(_vm.$t("WorkCentreTable.FinishTask"))),
                  ]),
                  _c("el-radio-button", { attrs: { label: "2" } }, [
                    _vm._v(_vm._s(_vm.$t("WorkCentreTable.VoidTask"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("WorkCentreTable.FollowUpPerson") } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    clearable: "",
                    filterable: "",
                    remote: "",
                    "remote-method": _vm.asyncGetUserPage,
                    loading: _vm.userObj.loading,
                    placeholder: _vm.$t("operationCenter.PleaseSelect"),
                  },
                  model: {
                    value: _vm.ruleForm.follower,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "follower", $$v)
                    },
                    expression: "ruleForm.follower",
                  },
                },
                _vm._l(_vm.userObj.arr, function (item) {
                  return _c(
                    "el-option",
                    {
                      key: item.id,
                      attrs: {
                        label:
                          item.name +
                          (item.englishName
                            ? "(" + item.englishName + ")"
                            : ""),
                        value: JSON.stringify({
                          id: item.id,
                          name: item.name,
                          englishName: item.englishName || "",
                        }),
                      },
                    },
                    [
                      _c("span", { staticStyle: { float: "left" } }, [
                        _vm._v(
                          _vm._s(item.name) +
                            _vm._s(
                              item.englishName
                                ? "(" + item.englishName + ")"
                                : ""
                            )
                        ),
                      ]),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            float: "right",
                            color: "#8492a6",
                            "font-size": "13px",
                          },
                        },
                        [_vm._v(_vm._s(item.positionName))]
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c("el-form-item", [
            _c("div", { staticClass: "foot" }, [
              _c("div"),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("WorkCentreTable.add")))]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function () {
                          return _vm.updataData()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("WorkCentreTable.cancel")))]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }