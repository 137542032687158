var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "wrap" }, [
      _c("div", { staticClass: "child" }, [
        _c("div", { staticClass: "main" }, [
          _c("div", { staticClass: "item-container" }, [
            _c(
              "div",
              {
                class: [
                  "item",
                  _vm.data.children.length ? "item-line-bottom" : "",
                  "item-top-node",
                ],
              },
              [
                _c("div", [
                  _c("p", { staticClass: "name" }, [
                    _vm._v(_vm._s(_vm.data.name)),
                  ]),
                  _c("p", { staticClass: "number" }, [
                    _vm._v("人数："),
                    _c("span", { staticClass: "number-crude" }, [
                      _vm._v(_vm._s(_vm.data.number)),
                    ]),
                  ]),
                ]),
              ]
            ),
            _c("div", { staticClass: "inline" }, [
              _c("div", { staticClass: "child" }, [
                _c(
                  "div",
                  { staticClass: "main" },
                  _vm._l(_vm.data.children, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        class: [
                          "item-container",
                          index == 0 && _vm.data.children.length != 1
                            ? "item-line-l"
                            : "",
                          index == _vm.data.children.length - 1 &&
                          _vm.data.children.length != 1
                            ? "item-line-r"
                            : "",
                          index != _vm.data.children.length - 1 || index != 0
                            ? "item-line-m"
                            : "",
                        ],
                      },
                      [
                        _c(
                          "div",
                          {
                            class: [
                              "item",
                              "item-line-top",
                              item.children && item.children.length
                                ? "item-line-bottom"
                                : "",
                            ],
                          },
                          [
                            _c("div", [
                              _c("p", { staticClass: "name" }, [
                                _vm._v(_vm._s(item.name)),
                              ]),
                              _c("p", { staticClass: "number" }, [
                                _vm._v("人数："),
                                _c("span", { staticClass: "number-crude" }, [
                                  _vm._v(_vm._s(item.number)),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "inline" }),
                        item.children && item.children.length
                          ? _c("div", { staticClass: "child" }, [
                              _c(
                                "div",
                                { staticClass: "main" },
                                _vm._l(item.children, function (items, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      class: [
                                        "item-container",
                                        index == 0 && item.children.length != 1
                                          ? "item-line-l"
                                          : "",
                                        index == item.children.length - 1 &&
                                        item.children.length != 1
                                          ? "item-line-r"
                                          : "",
                                        index != item.children.length - 1 ||
                                        index != 0
                                          ? "item-line-m"
                                          : "",
                                      ],
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "item item-line-top" },
                                        [
                                          _c("div", [
                                            _c("p", { staticClass: "name" }, [
                                              _vm._v(_vm._s(items.name)),
                                            ]),
                                            _c("p", { staticClass: "number" }, [
                                              _vm._v("人数："),
                                              _c(
                                                "span",
                                                { staticClass: "number-crude" },
                                                [_vm._v(_vm._s(items.number))]
                                              ),
                                            ]),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ])
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }