<!--
 * @Author: 肖强
 * @Date: 2022-06-15 14:36:24
 * @LastEditTime: 2022-07-04 11:39:22
 * @LastEditors: 肖强
 * @Description: 修改省市区展示组件
 * @FilePath: \admin-oa-web\src\views\orderCenter\region\cmp\editDetails.vue
-->
<template>
  <div>
    <div><span class="font20">{{ $t('orderCenterCont.edit') }}{{ row.level==0?$t('orderCenterCont.state'):'' }}{{ row.level==1?$t('orderCenterCont.city'):'' }}{{ row.level==2?$t('orderCenterCont.Area'):'' }}-{{ row.level==0?superior.province:'' }}{{ row.level==1?superior.city:'' }}{{ row.level==2?row.nameCn:'' }}</span> <span
      class="font-blue"
      @click="goBack"
    >{{ $t('orderCenterCont.back') }} {{ row.level==0?$t('orderCenterCont.state'):'' }}{{ row.level==1 || row.level==2?$t('orderCenterCont.city'):'' }} {{ $t('orderCenterCont.list') }}</span></div>
    <!-- 列表 -->
    <el-divider />
    <el-tabs
      ref="tabs"
      v-model="tabs"
      type="border-card"
    >
      <!-- 基本信息 -->
      <el-tab-pane
        :label="$t('orderCenterCont.basicInformation')"
        name="from"
      >
        <el-form
          status-icon
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item
            v-if="superior.country&&row.level===0"
            :label="$t('orderCenterCont.SubordinateState')"
            required
          >
            <!-- 所属州： -->
            {{ superior.province }}
          </el-form-item>
          <el-form-item
            v-if="row.level==1 || row.level==2"
            :label="$t('orderCenterCont.BelongsToTheCity')"
            required
          >
            {{ superior.city }}
          </el-form-item>
          <!--          <el-form-item-->
          <!--            v-if="row.level==3"-->
          <!--            label="所属城市："-->
          <!--            required-->
          <!--          >-->
          <!--            {{ superior.city }}-->
          <!--          </el-form-item>-->
        </el-form>
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          status-icon
          :rules="rules"
          label-width="100px"
          class="demo-ruleForm"
        >
          <!-- <div v-if="ruleForm.level === 0">
            所属大区
            <el-form-item
              prop="regionalId"
              :label="$t('orderCenterCont.BelongsToTheRegion') + '：'"
              :rules="[
                { required: false, message: $t('orderCenterCont.CannotBeEmpty'), trigger: ['blur','change'] }
              ]"
            >
              <el-select
                v-model="ruleForm.regionalNameCn"
                filterable
                :placeholder="$t('operationCenter.PleaseSelect')"
                clearable
                @change="changeRegional(ruleForm.regionalId)"
              >
                <el-option
                  v-for="item in regionList"
                  :key="item.id"
                  :label="item.nameCn + (item.nameEn ? '(' + item.nameEn + ')' : '')"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </div> -->
          <div v-if="ruleForm.level === 0 || ruleForm.level === 1">
            <el-row>
              <el-col :span="12">
                <!-- 起始邮编: -->
                <el-form-item :label="$t('orderCenterCont.startZipCode')" prop="startZipCode" :rules="isNumberRules">
                  <el-input v-model.trim="ruleForm.startZipCode" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <!-- 结束邮编: -->
                <el-form-item :label="$t('orderCenterCont.endZipCode')" prop="endZipCode" :rules="isNumberRules">
                  <el-input v-model.trim="ruleForm.endZipCode" />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <el-form-item
            :label="$t('orderCenterCont.nameCn')"
            prop="nameCn"
            :rules="isChinese"
          >
            <el-input v-model="ruleForm.nameCn" />
          </el-form-item>
          <el-form-item
            :label="$t('orderCenterCont.nameEn')"
            prop="nameEn"
            :rules="isEnglish"
          >
            <el-input v-model="ruleForm.nameEn" />
          </el-form-item>
          <!-- 扩展字段:  -->
          <el-form-item
            v-if="ruleForm.level==1"
            :label="$t('orderCenterCont.expandText')"
          >
            <!-- 多个字段用'，'隔开 -->
            <el-input
              v-model="ruleForm.extra"
              :placeholder="$t('orderCenterCont.MultipleFields')"
              type="textarea"
            />
          </el-form-item>
          <el-form-item
            v-if="ruleForm.level==1"
            :label="$t('orderCenterCont.zipCode')"
            :hide-required-asterisk="true"
          >
            <!-- 填写邮编。填写示例如：10~20,21,22~30 -->
            <el-input
              v-model="ruleForm.zipCode"
              :placeholder="$t('orderCenterCont.writeZipCode')"
              type="textarea"
            />
          </el-form-item>
          <!-- 二字代码 -->
          <el-form-item
            :label="$t('orderCenterCont.TwoWordCode') + '：'"
            prop="twoCode"
            :rules="[
              { required: false, validator: englishLetter, length: 2, trigger: ['blur','change'] }
            ]"
          >
            <el-input
              v-model="ruleForm.twoCode"
              maxlength="2"
              show-word-limit
            />
          </el-form-item>
          <div v-if="ruleForm.level === 1">
            <!-- 邮编分区 -->
            <el-row>
              <el-col :span="24">
                <el-form-item :label="$t('orderCenterCont.partition')"><!-- 分区： -->
                  <!-- <el-select
                    v-model="ruleForm.zipcodeAreaName"
                    style="display: block;"
                    filterable
                    clearable
                    remote
                    reserve-keyword
                    :placeholder="$t('collectionCenter.pleaseInput')"
                    :remote-method="remoteMethod"
                    @change="changeValue"
                  > -->
                  <el-select
                    v-model="ruleForm.zipcodeAreaName"
                    style="display: block;"
                    filterable
                    clearable
                    :placeholder="$t('collectionCenter.pleaseInput')"
                  >
                    <el-option
                      v-for="(item, index) in ZipCodeAreaList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <el-form-item>
            <el-button
              type="primary"
              @click="editLgArea"
            >{{ $t('orderCenterCont.confirm') }}</el-button>
            <el-button @click="goBack">{{ $t('orderCenterCont.cancel') }}</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane
        :label="name"
        name="table"
      >
        <tableCmp
          ref="tableCmp"
          :level="ruleForm.level<3?ruleForm.level+1:ruleForm.level"
          :parent="parent"
          :superior="superior"
          @editLgArea="editLgArea"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { editLgAreaList } from '@/api/orderApi';
import { regionalList } from '@/api/regionalManagement/regional';
import tableCmp from './tableCmp';
import { englishLetter, isChinese, isEn, isNumber } from '@/utils/validate';
import { isEmpty } from '@/utils/strUtil';
import { zipcodeAreaPage } from '@/api/automaticSorting';
export default {
  components: {
    tableCmp
  },
  props: {
    // 当前选择的对象
    row: {
      type: Object,
      default: () => {}
    },
    // 国家省份城市名称对象
    superior: {
      type: Object,
      default: () => {}
    },
    // 当前选择的零一二级对象
    dadData: {
      type: Array,
      default: () => {}
    },
    // 上级id
    parent: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      zipcodeAreaId: '',
      ZipCodeAreaList: [],
      // 表单数据
      ruleForm: {
        deleted: '',
        level: 0,
        nameCn: '', // 中文名
        nameEn: '', // 英文名
        parentId: '', // 父id
        twoCode: '', // 二字码
        startZipCode: '', // 起始邮编
        endZipCode: '', // 起始邮编
        zipcodeAreaName: '',
        extra: '', // 拓展字段
        extraold: '',
        id: '',
        regionalId: '', // 大区id
        regionalNameCn: '' // 大区名称
      },
      isNumberRules: [
        { required: true, message: this.$t('orderCenterCont.CannotBeEmpty'), trigger: ['blur', 'change'] },
        {
          validator: async(rule, value, callback) => {
            if (!isNumber(value)) {
              // 请输入数字
              return callback(new Error(this.$t('orderCenterCont.inputNum'))); // '请输入数字'
            } else if (value.length !== 8) {
              // 请输入8位数字
              return callback(new Error(this.$t('orderCenterCont.input8Sum'))); // '请输入8位数字'
            }
          }
        }
      ],
      englishLetter: englishLetter,
      isChinese: [
        { message: this.$t('orderCenterCont.CannotBeEmpty'), trigger: ['blur', 'change'] },
        {
          validator: async(rule, value, callback) => {
            if (isEmpty(value)) {
              return true;
            } else {
              if (!isChinese(value)) {
                // 请输入中文
                return callback(new Error(this.$t('orderCenterCont.inputChinese')));
              }
              if (isEn(value)) {
                // 不能有英文字符
                return callback(new Error(this.$t('orderCenterCont.notEnglish')));
              }
            }
          }
        }
      ],
      isEnglish: [
        { required: true, message: this.$t('orderCenterCont.CannotBeEmpty'), trigger: ['blur', 'change'] },
        {
          validator: async(rule, value, callback) => {
            if (!isEn(value)) {
              // 请输入英文
              return callback(new Error(this.$t('orderCenterCont.inputEnglish')));
            }
            if (isChinese(value)) {
              // 不能有中文字符
              return callback(new Error(this.$t('orderCenterCont.notChinese')));
            }
          }
        }
      ],
      // 校验字段
      rules: {
        nameCn: {
          required: true,
          // 请输入中文名
          message: this.$t('orderCenterCont.inputCnNamw'),
          trigger: 'blur'
        },
        nameEn: {
          required: true,
          // 请输入英文名
          message: this.$t('orderCenterCont.inputEnName'),
          trigger: 'blur'
        },
        zipCode: {
          required: true,
          // 请输入邮编
          message: this.$t('orderCenterCont.inputEmail'),
          trigger: 'blur'
        },
        regionalId: {
          required: false,
          // 请选择大区
          message: this.$t('orderCenterCont.chooseRegion'),
          trigger: 'blur'
        }
      },
      tabs: 'from', // 切换table变量
      name: '', // 标签名
      regionList: [] // 大区选项
    };
  },
  watch: {
    // 监听数据变化更改文本内容，修改地区时不显示地区列表，v-if and v-show不可用，使用ref控制显示隐藏
    row: {
      handler(newVal, oldVal) {
        this.zipcodeAreaId = newVal.zipcodeAreaId;
        if (Object.keys(newVal).length > 0) {
          // 切换tab
          this.tabs = 'from';
          this.ruleForm = newVal;
          this.ruleForm.extraold = newVal.extra;
          if (newVal.level === 0) {
            // this.name = '城市列表';
            this.name = this.$t('orderCenterCont.cityList');
            return;
          } else if (newVal.level === 1) {
            this.getRegionalList();
            // this.name = '地区列表';
            this.name = this.$t('orderCenterCont.AreaList');
            return;
          } else if (newVal.level === 2) {
            // this.name = '地区列表';
            this.name = this.$t('orderCenterCont.AreaList');
            if (this.$refs.tabs.$children[0]) {
              this.$refs.tabs.$children[0].$refs.tabs[1].style.display = 'none';
            }
            return;
          }
          if (this.$refs.tabs.$children[0]) {
            this.$refs.tabs.$children[0].$refs.tabs[1].style.display = 'inline-block';
          }
        }
      },
      immediate: true
    },
    'ruleForm.twoCode'() {
      if (this.ruleForm.twoCode) {
        this.ruleForm.twoCode = this.ruleForm.twoCode.toUpperCase();
      }
    }
  },
  created() {
    const zipcodeAreaCodeForm = {
      pageNumber: 1,
      pageSize: 20,
      params: {
        zipCodeName: '',
        cityName: '',
        twoCode: '',
        status: 1
      }
    };
    zipcodeAreaPage(zipcodeAreaCodeForm).then((res) => {
      if (res.status === 'OK') {
        const ZipCodeAreaList = [];
        res.data.forEach(list => {
          ZipCodeAreaList.push({
            id: list.id,
            name: list.name
          });
        });
        this.zipcodeAreaId = '';
        this.ZipCodeAreaList = ZipCodeAreaList;
      }
    }).catch({});
  },
  methods: {
    // remoteMethod(value) {
    //   if (value !== '') {
    //     const zipcodeAreaCodeForm = {
    //       pageNumber: 1,
    //       pageSize: 20,
    //       params: {
    //         zipCodeName: value,
    //         cityName: '',
    //         twoCode: '',
    //         status: 1
    //       }
    //     };
    //     zipcodeAreaPage(zipcodeAreaCodeForm).then((res) => {
    //       if (res.status === 'OK') {
    //         const ZipCodeAreaList = [];
    //         res.data.records.forEach(list => {
    //           ZipCodeAreaList.push({
    //             id: list.id,
    //             name: list.name
    //           });
    //         });
    //         this.zipcodeAreaId = '';
    //         this.ZipCodeAreaList = ZipCodeAreaList;
    //         if (this.ZipCodeAreaList.length <= 0) {
    //           this.ruleForm.zipcodeAreaName = '';
    //         }
    //       }
    //     }).catch({});
    //   }
    // },
    // changeValue(value) { // 邮编分区 赋值
    //   if (value) {
    //     this.ruleForm.zipcodeAreaName = value;
    //   } else {
    //     this.ruleForm.zipcodeAreaName = '';
    //     this.ZipCodeAreaList = [];
    //   }
    // },
    // 大区选项变化
    changeRegional(id) {
      for (let i = 0; i < this.regionList.length; i++) {
        if (this.regionList[i].id === id) {
          this.ruleForm.regionalNameCn = this.regionList[i].nameCn;
        }
      }
    },
    // 获取大区选择 regionalList
    getRegionalList() {
      const param = {
        areaId: this.row.parentNode.regionalId
      };
      regionalList(param)
        .then((res) => {
          if (res.status === 'OK') {
            this.regionList = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * @description: 返回列表
     */
    goBack() {
      // 返回上一级列表，修改选中到列表,增加nextTick让切换更丝滑
      this.$nextTick(() => {
        this.tabs = 'table';
      });
      // 如果是返回地区列表，显示地区列表tab
      if (this.ruleForm.level === 3 && this.$refs.tabs.$children[0]) {
        this.$refs.tabs.$children[0].$refs.tabs[1].style.display = 'inline-block';
      }
      // 通知父组件切换组件
      if (this.row.level === 0) {
        this.$emit('getLevel');
      }
      // 获取到返回的上一级修改数据
      this.dadData.forEach((item) => {
        if (item.level === this.row.level - 1) {
          // 解构获得表单数据，不修改级别
          const { nameCn, nameEn, parentId, twoCode, zipCode, extra } = item;
          this.ruleForm.nameCn = nameCn;
          this.ruleForm.nameEn = nameEn;
          this.ruleForm.parentId = parentId;
          this.ruleForm.twoCode = twoCode;
          this.ruleForm.zipCode = zipCode;
          this.ruleForm.extra = extra;
          this.parent = this.superior[this.row.level - 1];
          return;
        }
      });
      // 修改级数，重新获取列表
      if (this.ruleForm.level !== 0) {
        this.ruleForm.level -= 1;
      }
      // 修改tab的文本
      if (this.row.level === 0) {
        // this.name = '城市列表';
        this.name = this.$t('orderCenterCont.cityList');
      } else if (this.row.level === 1) {
        // this.name = '地区列表';
        this.name = this.$t('orderCenterCont.AreaList');
      } else if (this.row.level === 2) {
        // this.name = '地区列表';
        this.name = this.$t('orderCenterCont.AreaList');
      }
    },
    /**
     * @description: 修改
     */
    editLgArea(item, type) {
      // console.log(item, 'item');
      let data;
      let ruleForm;
      if (type) {
        data = item;
      } else {
        ruleForm = {
          deleted: this.ruleForm.deleted,
          level: this.ruleForm.level,
          nameCn: this.ruleForm.nameCn, // 中文名
          nameEn: this.ruleForm.nameEn, // 英文名
          parentId: this.ruleForm.parentId, // 父id
          twoCode: this.ruleForm.twoCode, // 二字码
          startZipCode: this.ruleForm.startZipCode, // 起始邮编
          endZipCode: this.ruleForm.endZipCode, // 起始邮编
          zipcodeAreaId: this.zipcodeAreaId || this.ruleForm.zipcodeAreaName,
          extra: this.ruleForm.extra, // 拓展字段
          extraold: this.ruleForm.extraold,
          id: this.ruleForm.id,
          regionalId: this.ruleForm.regionalId, // 大区id
          regionalNameCn: this.ruleForm.regionalNameCn // 大区名称
        };
        data = ruleForm;
        delete data.parentNode;
      }
      // console.log(data);
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          editLgAreaList({ ...data })
            .then((res) => {
              if (res.status === 'OK') {
                // '修改成功'
                this.$message({ message: this.$t('orderCenterCont.ModificationSuccessful'), type: 'success' });
                // this.$emit('getLevel');
                // 修改邮编的时候不回退页面
                if (type) {
                  return;
                }
                this.goBack();
              } else {
                this.$message({
                  message: res.msg,
                  type: 'warning'
                });
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.font20 {
  font-size: 18px;
}
.font-blue {
  color: #409eff;
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}
.is_required > .el-form-item__label:before {
  content: '*';
  color: #f56c6c;
  margin-right: 4px;
}
</style>
