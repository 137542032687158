const home = {
  anJun: {
    zh: '安骏跨境物流系统',
    en: '',
    pu: 'Sistema Logístico Cross Border de Anjun'
  },
  welcomeAnJun: {
    zh: '欢迎使用安骏跨境物流系统',
    en: '',
    pu: 'Bem-vindo ao Sistema Logístico Cross Border de Anjun'
  },
  welcomeanjunKD: {
    zh: '欢迎使用安骏快递系统',
    en: '',
    pu: 'Bem-vindo ao Sistema Anjun Express'
  },
  brazilOrder: {
    zh: '巴西本地快递下单',
    en: '',
    pu: 'Fazer pedido por Express local do Brasil '
  },
  test: {
    zh: '请输入旧密码',
    en: '',
    pu: 'Favor digitar a senha antiga'
  },
  coverCountry: {
    zh: '覆盖巴西全国地区',
    en: '',
    pu: 'Cobrir todo o Brasil'
  },
  placeOrder: {
    zh: '立即下单',
    en: '',
    pu: 'Fazer pedido agora'
  },
  finalState: {
    zh: '最终状态',
    en: '',
    pu: 'Estado final'
  },
  failNode: {
    zh: '推送失败节点',
    en: '',
    pu: 'Nó de envio falhado'
  }
};
export {
  home
};
