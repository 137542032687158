var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "wrapper-form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        },
      },
    },
    [
      _c(
        "o-field",
        {
          attrs: {
            label: "Base inicial",
            "label-for": "base_start",
            message: _vm.v$.base_start_iata.$errors.length
              ? _vm.v$.base_start_iata.$errors[0].$message
              : null,
          },
        },
        [
          _c(
            "o-autocomplete",
            {
              staticClass: "col-2",
              attrs: {
                id: "base_start",
                placeholder: "Digite a base inicial",
                clearable: "",
                expanded: "",
                field: "shipping_company_base",
                data: _vm.bases,
              },
              on: {
                input: _vm.handleGetBases,
                select: function (res) {
                  return _vm.changeInfoForm(res, "base_start")
                },
              },
              model: {
                value: _vm.formData.base_start_iata,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "base_start_iata", $$v)
                },
                expression: "formData.base_start_iata",
              },
            },
            [_c("template", { slot: "empty" }, [_vm._v("Sem resultados")])],
            2
          ),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: "Pontos de parada",
            "label-for": "base_stops",
            message: _vm.v$.base_stops_iata.$errors.length
              ? _vm.v$.base_stops_iata.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-inputitems", {
            ref: "inputColor",
            attrs: {
              id: "base_stops",
              placeholder: "Selecione os pontos de parada",
              data: _vm.bases,
              "allow-new": false,
              "open-on-focus": false,
              autocomplete: true,
              "allow-autocomplete": true,
              field: "shipping_company_base",
              "create-item": _vm.handlePushNewItem,
            },
            on: {
              typing: _vm.handleGetBases,
              remove: function (remove) {
                return _vm.removeItem(remove, _vm.bases)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "selected",
                fn: function (ref) {
                  var items = ref.items
                  return _vm._l(items, function (item, index) {
                    return _c(
                      "span",
                      {
                        key: index,
                        staticClass: "item_selected",
                        attrs: { rounded: "" },
                        on: {
                          click: function ($event) {
                            return _vm.$refs.inputColor.removeItem(
                              index,
                              $event
                            )
                          },
                        },
                      },
                      [
                        _vm._v(" " + _vm._s(item) + " "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "5px",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.inputColor.removeItem(
                                  index,
                                  $event
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "lucide lucide-x",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "16",
                                  height: "16",
                                  viewBox: "0 0 24 24",
                                  fill: "none",
                                  stroke: "currentColor",
                                  "stroke-width": "2",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                },
                              },
                              [
                                _c("path", { attrs: { d: "M18 6 6 18" } }),
                                _c("path", { attrs: { d: "m6 6 12 12" } }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  })
                },
              },
            ]),
            model: {
              value: _vm.formData.base_stops_iata,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "base_stops_iata", $$v)
              },
              expression: "formData.base_stops_iata",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: "Base final",
            "label-for": "base_finished",
            message: _vm.v$.base_finished_iata.$errors.length
              ? _vm.v$.base_finished_iata.$errors[0].$message
              : null,
          },
        },
        [
          _c(
            "o-autocomplete",
            {
              staticClass: "col-2",
              attrs: {
                id: "base_finished",
                placeholder: "Digite a base final",
                clearable: "",
                expanded: "",
                field: "shipping_company_base",
                data: _vm.bases,
              },
              on: {
                input: _vm.handleGetBases,
                select: function (res) {
                  return _vm.changeInfoForm(res, "base_finished")
                },
              },
              model: {
                value: _vm.formData.base_finished_iata,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "base_finished_iata", $$v)
                },
                expression: "formData.base_finished_iata",
              },
            },
            [_c("template", { slot: "empty" }, [_vm._v("Sem resultados")])],
            2
          ),
        ],
        1
      ),
      _c(
        "o-button",
        {
          staticClass: "button-submit",
          attrs: { variant: "primary", size: "md", "native-type": "submit" },
        },
        [_vm._v("Enviar")]
      ),
      _c("o-loading", {
        attrs: { "full-page": true, active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }