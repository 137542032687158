<template>
  <div>
    <div style="margin-bottom: 20px;">
      <!--选择客户-->
      <span style="color:#f00;margin-right: 3px;">*</span>{{ $t('orderCenterCont.SelectCustomers') }}：
      <el-select v-model="customId" :placeholder="$t('GlobalSetObj.pleaseChoose')">
        <el-option
          v-for="item in customerList"
          :key="item.id"
          :label="item.username + '（' + item.customerAlias + '）'"
          :value="item.id"
        />
      </el-select>
    </div>
    <div style="display: flex;margin-bottom: 20px;">
      <el-upload
        ref="upload"
        class="upload-demo"
        action
        :multiple="false"
        :limit="1"
        :on-error="onError"
        :on-change="handleChange"
        :before-upload="fileFormatLimit"
        :auto-upload="false"
        :http-request="uploadBulkUpdateFiles"
      >
        <!--:http-request="uploadBulkUpdateFiles"-->
        <el-button size="small" type="primary" icon="el-icon-upload2">{{ $t('orderCenterCont.OrderInformationFileUpload') }}</el-button>
      </el-upload>
      <div style="flex: 1;text-align: center;">
        <el-button size="small" type="primary" @click="downloadTemplate()">{{ $t('orderCenterCont.DownloadTemplate') }}</el-button>
      </div>
    </div>
    <div style="text-align: center;margin-top: 40px;">
      <el-button size="small" type="primary" @click="onsubmitFile()">{{ $t('orderCenterCont.confirm') }}</el-button>
      <el-button size="small" @click="clearImportOrder()">{{ $t('orderCenterCont.cancel') }}</el-button>
    </div>
  </div>
</template>

<script>
import { apiImportCreateOrder } from '@/api/logisticsOrders';
import { saveAs } from 'file-saver';

export default {
  name: 'ImportOrders',
  props: {
    customerList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      customId: '',
      customerVO: {
        customerId: '',
        username: '',
        passWord: ''
      },
      fileList: []
    };
  },
  watch: {
    customId(val) {
      this.customerList.map(item => {
        if (item.id === val) {
          this.customerVO.customerId = item.id;
          this.customerVO.username = item.username;
          this.customerVO.passWord = item.authorizationCode;
        }
      });
    }
  },
  methods: {
    // 取消导入订单
    clearImportOrder() {
      this.$emit('importOrder', false);
    },
    // 文件格式限制
    fileFormatLimit(file) {
      const { name } = file;
      const index = name.lastIndexOf('.');
      const ext = name.substr(index + 1);
      const typeFile = ['xlsx', 'XLSX', 'xls', 'XLS', 'xlsm', 'XLSM'];
      if (typeFile.includes(ext)) {
        return true;
      } else {
        this.$message.warning(this.$t('orderCenterCont.OnlyExcelFilesCanBeUploaded')); // '只能上传Excel文件'
        return false;
      }
    },
    // 上传文件变化
    handleChange(param) {
      this.fileList = [param];
    },
    /**
     * @description: 批量导入订单
     * @param {*} param
     * @return {*}
     */
    onsubmitFile() {
      if (!this.customId) {
        // 请选择客户
        this.$message.warning(this.$t('placeOrder.pleaseChooseClient'));
      } else if (this.fileList.length === 0) {
        // 请添加导入订单文件
        this.$message.warning(this.$t('orderCenterCont.PleaseAddImportOrderFile'));
      } else if (!this.customId && this.fileList.length === 0) {
        // 客户和导入订单文件不能为空
        this.$message.warning(this.$t('orderCenterCont.TheCustomerAndImportOrderFilesCannotBeEmpty'));
      } else {
        this.$refs.upload.submit();
      }
    },
    uploadBulkUpdateFiles(param) {
      const data = new FormData();
      data.append('file', param.file);
      data.append('customerId', this.customerVO.customerId);
      data.append('username', this.customerVO.username);
      data.append('passWord', this.customerVO.passWord);
      apiImportCreateOrder(data).then((res) => {
        if (res.status === 'OK') {
          this.$message.success(res.data);
          this.bus.$emit('upDateMsg');
          this.$emit('importOrder', true);
        }
      });
    },
    onError() {
      this.$refs.upload.clearFiles();
    },
    // 下载模板
    downloadTemplate() {
      // let element = document.createElement('a');
      const url = '/static/Anjun订单导入模板.xlsx';
      // element.setAttribute('href', url);
      // element.setAttribute('download', 'Anjun订单导入模板.xlsx');
      // element.style.display = 'none';
      // document.body.appendChild(element);
      // element.click();
      // document.body.removeChild(element);
      // element = null;
      saveAs(url, 'Anjun订单导入模板.xlsx');
    }
  }
};
</script>

<style scoped>

</style>
