<template>
  <div class="app-container">
    <div style="margin-bottom: 10px;">
      <el-card class="box-card" style="margin-bottom: 10px;position: relative">
        <div style="margin-bottom: 10px;">1.{{ $t('GlobalSetObj.scannerTip') }}</div>
        <div>2.{{ $t('GlobalSetObj.beforeScannerTip') }}</div>
      </el-card>
      <el-card class="box-card">
        <div class="titleStyle" style="margin-bottom: 10px;">{{ $t('GlobalSetObj.ScanningEntry') }}</div>
        <div>
          <span style="margin-right: 10px">{{ $t('GlobalSetObj.bagNumber') }}</span>
          <div style="display:inline-block;width: 50%;margin-right: 20px;">
            <el-input
              ref="inputRef"
              v-model="bagNo"
              :placeholder="$t('GlobalSetObj.scannerOrinput')"
              clearable
              @keyup.enter.native="inboundOperations(1)"
            />
          </div>
          <el-button type="primary" @click="inboundOperations(2)">{{
            $t('GlobalSetObj.ConfirmReceipt')
          }}
          </el-button>
        </div>
        <!--        <div style="color:red;height: 30px;line-height: 30px;margin-left: 40px">-->
        <!--          <div v-if="showErr">{{ errMessage }}</div>-->
        <!--        </div>-->
      </el-card>
    </div>
    <div>
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div style="margin-bottom: 10px;">
                <span class="titleStyle">{{ $t('GlobalSetObj.ScanRecord') }}</span>
                <span style="float:right;">{{ $t('GlobalSetObj.ReceivedGoods') }}: <span>{{ total }}</span></span>
              </div>
              <el-table
                :data="tableDataRight"
                :header-cell-style="{background:'#f2f2f2',color:'black'}"
                :height="height"
                border
                style="width: 100%"
              >
                <el-table-column
                  :label="$t('GlobalSetObj.bagNumber')"
                  min-width="200"
                  prop="instoreBigBagNo"
                />
                <el-table-column
                  :label="$t('GlobalSetObj.package')"
                  min-width="200"
                  prop="packageCount"
                />
                <el-table-column
                  min-width="200"
                  prop="weighingWeight"
                >
                  <template slot="header">
                    <div style="display: flex;align-items: center">
                      <span style="margin-right: 5px">{{ $t('GlobalSetObj.pesoTotal') }}</span>
                      <el-tooltip placement="top">
                        <div slot="content">{{ $t('GlobalSetObj.coleta') }}</div>
                        <svg-icon icon-class="QuestionMark" />
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('GlobalSetObj.operator')"
                  min-width="200"
                  prop="createUserStr"
                />
              </el-table>
              <!--  分页  -->
              <el-pagination
                :current-page="page.pageNumber"
                :page-size="page.pageSize"
                :page-sizes="[10, 20, 50, 100]"
                :pager-count="5"
                :total="page.total"
                background
                layout="total, sizes, prev, pager, next, jumper"
                style="margin-top: 10px; float: right;margin-bottom: 20px"
                @size-change="SizeChange"
                @current-change="CurrentChange"
              />
            </el-card>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 隐藏提示音 -->
    <div v-show="false">
      <audio ref="errRef" :src="errorMp3" controls="controls" />
      <audio ref="okRef" :src="okMp3" controls="controls" />
    </div>
  </div>
</template>

<script>
import { apiStorageRecordPage, apiStorageRecordQuery, apiStorageRecordSave } from '@/api/storeAdmin';
import { getUserName } from '@/utils/asyncTools';
import { getUserIdAndNameObj } from '@/enums/storageEnum';

const okMp3 = 'https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/static/music/su.wav';
const errorMp3 = 'https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/static/music/print_error.wav';
export default {
  name: 'WarehouseEnter',
  data() {
    return {
      bagNo: '',
      tableDataLeft: [],
      tableDataRight: [],
      height: 320,
      showErr: false,
      errMessage: '',
      userIdAndNameObj: null,
      okMp3,
      errorMp3,
      page: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
        params: {
          deleted: 0
        }
      },
      total: 0,
      isFirst: true
    };
  },
  watch: {
    bagNo: {
      handler: function(val) {
        this.showErr = false;
        this.errMessage = '';
      }
    }
  },
  created() {
    this.height = window.document.body.offsetHeight - 470;
    this.userIdAndNameObj = getUserIdAndNameObj();
    this.getInboundRecords();
  },
  methods: {
    // 查询入库记录
    getInboundRecords() {
      apiStorageRecordPage(this.page).then(res => {
        if (res.status === 'OK') {
          this.isFirst = false;
          this.total = res.data.total;
          this.page.total = res.data.total;
          this.tableDataRight = res.data.records.map(item => {
            const createUserStr = getUserName(item.createUser, this.userIdAndNameObj);
            return {
              ...item,
              createUserStr
            };
          });
        }
      });
    },
    // 点击分页
    SizeChange(val) {
      this.page.pageNumber = 1;
      this.page.pageSize = val;
      this.getInboundRecords();
    },
    CurrentChange(val) {
      this.page.pageNumber = val;
      this.getInboundRecords();
    },
    // 取消入库
    cancelShipping() {
      this.tableDataLeft = [];
    },
    // 确定入库
    saveStorage(data) {
      const paramSave = {
        instoreBigBagId: data.id,
        instoreBigBagNo: data.instoreBigBagNo,
        packageCount: data.package_count,
        weighingWeight: data.weighingWeight
      };
      apiStorageRecordSave(paramSave, false).then(resp => {
        if (resp.status === 'OK') {
          this.bagNo = '';
          this.$refs.okRef.play();
          this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
          this.page.pageNumber = 1;
          this.getInboundRecords();
        }
      }).catch(() => {
        this.bagNo = '';
        this.$refs.errRef.play();
        this.showErr = false;
        this.$nextTick(_ => {
          this.$refs.inputRef.$refs.input.select();
        });
      });
    },
    // 入库操作
    inboundOperations(type) {
      if (this.bagNo) {
        const param = {
          instoreBigBagNo: this.bagNo
        };
        // 查询袋号信息
        apiStorageRecordQuery(param, false).then(res => {
          if (res.status === 'OK') {
            this.$refs.okRef.play();
            this.showErr = false;
            this.saveStorage(res.data);
          } else {
            this.$refs.errRef.play();
            this.errMessage = res.msg;
            this.showErr = true;
            this.$nextTick(_ => {
              this.$refs.inputRef.$refs.input.select();
            });
          }
        }).catch((err) => {
          this.$refs.errRef.play();
          this.errMessage = err.msg;
          this.showErr = true;
          this.$nextTick(_ => {
            this.$refs.inputRef.$refs.input.select();
          });
        });
      }
    },
    // 滚动翻页查询入库记录
    scrollLoad() {
      if (this.isFirst) {
        this.getInboundRecords();
      } else {
        if (this.total > this.tableDataRight.length) {
          this.page.pageNumber = this.page.pageNumber + 1;
          this.getInboundRecords();
        }
      }
    }
  }
};
</script>

<style scoped>
.titleStyle {
  font-size: 18px;
  font-weight: bold;
}

</style>
