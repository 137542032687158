var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.disabled
        ? _c("ProductReadOnlyCmp", {
            attrs: { height: _vm.tableHeight, arr: _vm.formData.arr },
          })
        : _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: { model: _vm.formData, "label-width": "0", size: "mini" },
            },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.formData.arr,
                    height: _vm.tableHeight,
                    border: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("newOrder.SerialNumber"),
                      type: "index",
                      width: "50",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("GlobalSetObj.Name") + "*",
                      prop: "start",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "arr." + scope.$index + ".name",
                                  rules: _vm.rules.name,
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    autosize: { minRows: 2, maxRows: 4 },
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.pleaseInput"
                                    ),
                                    type: "textarea",
                                  },
                                  model: {
                                    value: scope.row.name,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "name", $$v)
                                    },
                                    expression: "scope.row.name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("newOrder.Quantity") + "*",
                      width: "180",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "arr." + scope.$index + ".quantity",
                                  rules: _vm.rules.name,
                                },
                              },
                              [
                                _c("el-input-number", {
                                  attrs: {
                                    controls: false,
                                    max: 999999999,
                                    min: 0,
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.pleaseInput"
                                    ),
                                    precision: 0,
                                  },
                                  model: {
                                    value: scope.row.quantity,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "quantity", $$v)
                                    },
                                    expression: "scope.row.quantity",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("newOrder.Size") },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "table-size" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("operationCenter.length"),
                                      "label-width": "80px",
                                    },
                                  },
                                  [
                                    _c("el-input-number", {
                                      staticStyle: { width: "120px" },
                                      attrs: {
                                        controls: false,
                                        max: 999999,
                                        min: 0,
                                        placeholder: _vm.$t(
                                          "GlobalSetObj.pleaseInput"
                                        ),
                                        precision: 2,
                                      },
                                      model: {
                                        value: scope.row.length,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "length", $$v)
                                        },
                                        expression: "scope.row.length",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("operationCenter.width"),
                                      "label-width": "80px",
                                    },
                                  },
                                  [
                                    _c("el-input-number", {
                                      staticStyle: { width: "120px" },
                                      attrs: {
                                        controls: false,
                                        max: 999999,
                                        min: 0,
                                        placeholder: _vm.$t(
                                          "GlobalSetObj.pleaseInput"
                                        ),
                                        precision: 2,
                                      },
                                      model: {
                                        value: scope.row.width,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "width", $$v)
                                        },
                                        expression: "scope.row.width",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("operationCenter.height"),
                                      "label-width": "80px",
                                    },
                                  },
                                  [
                                    _c("el-input-number", {
                                      staticStyle: { width: "120px" },
                                      attrs: {
                                        controls: false,
                                        max: 999999,
                                        min: 0,
                                        placeholder: _vm.$t(
                                          "GlobalSetObj.pleaseInput"
                                        ),
                                        precision: 2,
                                      },
                                      model: {
                                        value: scope.row.height,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "height", $$v)
                                        },
                                        expression: "scope.row.height",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("newOrder.Declaration") + "*" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "table-size" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "orderCenterCont.DeclareUnitPrice"
                                      ),
                                      prop:
                                        "arr." +
                                        scope.$index +
                                        ".declaredValue",
                                      rules: _vm.rules.name,
                                      "label-width": "120px",
                                    },
                                  },
                                  [
                                    _c("el-input-number", {
                                      staticStyle: { width: "120px" },
                                      attrs: {
                                        controls: false,
                                        max: 999999999,
                                        min: 0,
                                        placeholder: _vm.$t(
                                          "GlobalSetObj.pleaseInput"
                                        ),
                                        precision: 2,
                                      },
                                      model: {
                                        value: scope.row.declaredValue,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "declaredValue",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.declaredValue",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "orderCenterCont.DeclaredWeight"
                                      ),
                                      prop:
                                        "arr." +
                                        scope.$index +
                                        ".declaredWeight",
                                      rules: _vm.rules.name,
                                      "label-width": "120px",
                                    },
                                  },
                                  [
                                    _c("el-input-number", {
                                      staticStyle: { width: "120px" },
                                      attrs: {
                                        controls: false,
                                        max: 999999999,
                                        min: 0,
                                        placeholder: _vm.$t(
                                          "GlobalSetObj.pleaseInput"
                                        ),
                                        precision: 2,
                                      },
                                      model: {
                                        value: scope.row.declaredWeight,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "declaredWeight",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.declaredWeight",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }