<template>
  <div class="app-container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <!--目录-->
      <el-tab-pane :label="$t('roleManage.catalog')" name="catalog">
        <div style="margin-bottom: 10px;display: flex;justify-content: space-between;">
          <div>
            <!--添加同级-->
            <el-button size="small" type="primary" @click="addEditNode(1)">{{
              $t('organizationManage.addSameLevel')
            }}
            </el-button>
            <!--添加下级-->
            <el-button
              plain size="small" type="primary"
              @click="addEditNode(2)"
            >{{
              $t('organizationManage.addNextLevel')
            }}
            </el-button>
            <!--编辑菜单-->
            <el-button
              plain size="small" type="primary"
              @click="addEditNode(3)"
            >{{
              $t('roleManage.EditTheMenu')
            }}
            </el-button>
            <!--编辑内容-->
            <el-button
              plain size="small" type="primary"
              @click="editContent()"
            >{{
              $t('roleManage.EditTheContent')
            }}
            </el-button>
            <!--删除-->
            <el-button
              plain size="small" type="danger"
              @click="deleteNode()"
            >{{ $t('roleManage.delete') }}</el-button>
          </div>
          <div>
            <!--新增标题-->
            <el-button size="small" type="primary" @click="clicNewkHeaderLine">{{
              $t('roleManage.newTitle')
            }}
            </el-button>
          </div>
        </div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-card class="box-card" shadow="never" style=" height: calc(100vh - 240px); overflow-y: auto">
              <div slot="header" class="clearfix">
                <span class="biaoti">{{ $t('roleManage.OperatorsManual') }}</span>
              </div>
              <div v-for="(item, ind) in permissionList" :key="ind" style="margin-bottom: 10px;">
                <el-tree
                  :data="item"
                  :expand-on-click-node="false"
                  :props="defaultProps"
                  default-expand-all
                  @node-click="nodeClickData"
                >
                  <span slot-scope="{ node,data }" class="custom-tree-node">
                    <span :class="data.id === selectNode.id ? 'selected-node' : ''" class="titlts">
                      <i v-if="data.level === 0" class="iconfont icon-folder-open" style="font-size: 21px" />
                      <i
                        v-else-if="data.level === 1 || ( (data.level - 3 * (parseInt(data.level / 3))) === 1)"
                        class="iconfont icon-yuanquan"
                      />
                      <i
                        v-else-if="data.level === 2 || ( (data.level - 3 * (parseInt(data.level / 3))) === 2)"
                        class="iconfont icon-tx-sanjiaoxing"
                        style="font-size: 19px;font-weight: bold;"
                      />
                      <i
                        v-else-if="data.level === 3 || ( (data.level - 3 * (parseInt(data.level / 3))) === 0)"
                        class="iconfont icon-zhengfangxing"
                        style="font-size: 19px;font-weight: bold;"
                      />
                      {{ data.name }}
                    </span>
                  </span>
                </el-tree>
              </div>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card class="box-card" shadow="never" style=" height: calc(100vh - 240px); overflow-y: auto">
              <div slot="header" class="clearfix">
                <span class="biaoti">{{ $t('roleManage.oftenProblem') }}</span>
              </div>
              <div>
                <el-table
                  :data="headLineList"
                  style="width: 100%"
                >
                  <el-table-column
                    :label="$t('roleManage.newTitleName')"
                    prop="name"
                  />
                  <el-table-column
                    :label="$t('newOrder.operation')"
                    align="center"
                    fixed="right"
                    width="100"
                  >
                    <template slot-scope="scope">
                      <div style="text-align: center;">
                        <el-button size="mini" type="text" @click="showHeadLine(scope.row)"><i
                          class="el-icon-edit-outline"
                          style="font-size: 18px;margin-right: 10px;"
                        /></el-button>
                        <el-popconfirm
                          :title="$t('GlobalSetObj.AreYouSureDelete')"
                          @confirm="delLableName(scope.row)"
                        >
                          <el-button slot="reference" size="mini" type="text"><i
                            class="el-icon-delete"
                            style="font-size: 18px;color: red;"
                          /></el-button>
                        </el-popconfirm>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-tab-pane>
      <!--编辑内容-->
      <el-tab-pane
        :disabled="(selectNode.id === null || selectNode.id === '' || selectNode.id === undefined) || selectNode.level === 0"
        :label="$t('roleManage.EditTheContent')"
        name="content"
      >
        <div style="width: 100%;">
          <tinymce
            :key="contentKey" v-model="paramForm.content" :height="360"
            width="90%"
          />
        </div>
        <div style="text-align: center;margin-top: 40px;">
          <el-button size="small" type="primary" @click="saveData(1)">{{ $t('CenterForSorting.Save') }}</el-button>
          <el-button size="small" @click="saveData(2)">{{ $t('CenterForSorting.cancel') }}</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!--添加编辑手册-->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="addEditTitle"
      :visible.sync="showAddEdit"
      width="500px"
    >
      <div>
        <el-form
          ref="paramForm" :model="paramForm" label-position="top"
          size="small"
        >
          <!--菜单名称-->
          <el-form-item
            :label="$t('roleManage.MenuName')"
            :rules="{ required: true, message: $t('orderCenterCont.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            prop="name"
          >
            <el-input v-model="paramForm.name" maxlength="100" show-word-limit />
          </el-form-item>
          <!--顺序-->
          <el-form-item
            :label="$t('roleManage.Introduction')"
            :rules="{ required: false, message: $t('orderCenterCont.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            prop="sort"
          >
            <el-input v-model="paramForm.sort" />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeAddEdit()">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button type="primary" @click="saveAddEdit()">{{ $t('GlobalSetObj.Salve') }}</el-button>
      </div>
    </el-dialog>
    <!--  常见问题  -->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('roleManage.oftenProblem')"
      :visible.sync="headlineDialog"
      top="5vh"
      width="800px"
      @close="closeHeadline"
    >
      <el-form
        :key="headlineDialog" ref="headlineDialog" :model="headlineForm"
        label-position="top" size="small"
      >
        <!--标题名称-->
        <el-form-item
          :label="$t('roleManage.newTitleName')"
          :rules="{ required: true, message: $t('orderCenterCont.CannotBeEmpty'), trigger: ['blur', 'change'] }"
          prop="name"
        >
          <el-input
            v-model="headlineForm.name"
            :placeholder="$t('GlobalSetObj.pleaseInput')"
            maxlength="50"
            show-word-limit
          />
        </el-form-item>
        <!--描述内容-->
        <el-form-item
          :label="$t('roleManage.DescribeContent')"
          :rules="{ required: true, message: $t('orderCenterCont.CannotBeEmpty'), trigger: ['blur', 'change'] }"
          prop="content"
        >
          <tinymce :key="contentKey" v-model="headlineForm.content" :height="360" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="headlineDialog = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button size="small" type="primary" @click="saveAddHeadleLine">{{ $t('GlobalSetObj.Salve') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Tinymce from '@/components/Tinymce/index';
import {
  apiPmOperationDocAdd,
  apiPmOperationDocDeleted,
  apiPmOperationDocGetById,
  apiPmOperationDocGetTree,
  apiPmOperationDocUpdate,
  deleteQuestion,
  questionAdd,
  questionlist,
  questionUpdate
} from '@/api/role';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'OperatorsManual',
  components: {
    Tinymce
  },
  data() {
    return {
      activeName: 'catalog',
      notePublic: '',
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      permissionList: [],
      selectNode: {
        id: '',
        level: '',
        name: '',
        sort: '',
        content: '',
        parentId: '',
        children: []
      },
      paramForm: {
        id: '',
        level: '',
        name: '',
        sort: '',
        content: '',
        parentId: '',
        children: []
      },
      contentKey: new Date().getTime(),
      showAddEdit: false,
      addEditTitle: '',
      addEditType: 1,
      headlineDialog: false,
      headlineForm: {
        name: '',
        content: ''
      },
      headLineList: [],
      headLineId: null
    };
  },
  created() {
    this.getOperatorManual();
    this.getHeadleLine();
  },
  methods: {
    async delLableName(row) {
      const res = await deleteQuestion(row.id);
      if (res.status === 'OK') {
        this.$message.success(this.$t('GlobalSetObj.DeleteSuccessful'));
        this.getHeadleLine();
      }
    },
    // 保存添加、编辑菜单
    saveAddEdit() {
      this.$refs.paramForm.validate((valid) => {
        if (valid) {
          if (this.addEditType === 1 || this.addEditType === 2) {
            if (this.selectNode.id === null || this.selectNode.id === '' || this.selectNode.id === undefined) {
              this.paramForm.parentId = 0;
            } else {
              if (this.addEditType === 1) {
                this.paramForm.parentId = this.selectNode.parentId;
              } else if (this.addEditType === 2) {
                this.paramForm.parentId = this.selectNode.id;
              }
            }
            apiPmOperationDocAdd(this.paramForm).then(res => {
              if (res.status === 'OK') {
                this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
                this.getOperatorManual();
                this.closeAddEdit();
              }
            });
          } else {
            apiPmOperationDocUpdate(this.paramForm).then(res => {
              if (res.status === 'OK') {
                this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
                this.getOperatorManual();
                this.closeAddEdit();
              }
            });
          }
        }
      });
    },
    // 获取操作手册菜单
    getOperatorManual() {
      apiPmOperationDocGetTree({}).then(res => {
        if (res.status === 'OK') {
          this.permissionList = [];
          res.data.map(item => {
            const arr = [item];
            this.permissionList.push(arr);
          });
        }
      });
    },
    // 标签页点击切换
    handleClick(value) {
      if (value.name === 'content') {
        apiPmOperationDocGetById(this.selectNode.id).then(res => {
          if (res.status === 'OK') {
            this.paramForm = res.data;
          }
        });
        this.activeName = 'content';
        this.contentKey = new Date().getTime();
      }
    },
    // 节点点击
    nodeClickData(data, node) {
      this.selectNode = cloneDeep(data);
    },
    // 关闭弹窗
    closeAddEdit() {
      this.paramForm = {
        id: '',
        level: '',
        name: '',
        sort: '',
        content: '',
        parentId: '',
        children: []
      };
      this.$refs.paramForm.resetFields();
      this.showAddEdit = false;
    },
    closeHeadline() {
      this.headlineForm = {
        name: '',
        content: ''
      };
      this.$refs.headlineDialog.resetFields();
      this.headLineId = null;
      this.headlineDialog = false;
    },
    clicNewkHeaderLine() {
      this.headLineId = null;
      this.headlineForm.name = '';
      this.headlineForm.content = '';
      this.$nextTick(() => {
        this.headlineForm.name = '';
        this.headlineForm.content = '';
      });
      this.contentKey = new Date().getTime();
      this.headlineDialog = true;
    },
    // 新建标题名称
    saveAddHeadleLine() {
      this.$refs.headlineDialog.validate((valid) => {
        if (valid) {
          console.log(this.headlineForm, 'headlineDialog');
          if (this.headLineId) {
            const data = {
              id: this.headLineId,
              name: this.headlineForm.name,
              content: this.headlineForm.content
            };
            questionUpdate(data).then(res => {
              if (res.status === 'OK') {
                this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
                this.headlineDialog = false;
                this.getHeadleLine();
              }
            });
          } else {
            questionAdd(this.headlineForm).then(res => {
              if (res.status === 'OK') {
                this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
                this.headlineDialog = false;
                this.getHeadleLine();
              }
            });
          }
        }
      });
    },
    // 查询标题名称
    getHeadleLine() {
      const data = {
        pageNumber: 1,
        pageSize: 100,
        params: {}
      };
      questionlist(data).then(res => {
        if (res.status === 'OK') {
          console.log(res, 'LKJLK');
          this.headLineList = res.data.records;
        }
      });
    },
    showHeadLine(item) {
      console.log(item, 'item');
      this.headlineForm.name = '';
      this.headlineForm.content = '';
      if (item) {
        this.headLineId = item.id;
        this.$nextTick(() => {
          this.headlineForm.name = item.name;
          this.headlineForm.content = item.content;
        });
        this.headlineDialog = true;
        this.contentKey = new Date().getTime();
      }
    },
    // 添加、编辑同级、下级
    addEditNode(type) {
      // type 1: 同级；2：下级；3：编辑
      this.addEditType = type;
      this.paramForm = {
        id: '',
        level: '',
        name: '',
        sort: '',
        content: '',
        parentId: '',
        children: []
      };
      if (type === 1) {
        this.addEditTitle = this.$t('roleManage.AddSiblingMenus'); // '添加同级菜单';
      } else if (type === 2) {
        if (this.selectNode.id === null || this.selectNode.id === '' || this.selectNode.id === undefined) {
          this.$message.warning(this.$t('roleManage.PleaseSelectTheMenuFirst')); // 请先选择菜单
          return;
        }
        this.addEditTitle = this.$t('roleManage.AddSubmenus'); // '添加下级菜单';
      } else {
        if (this.selectNode.id === null || this.selectNode.id === '' || this.selectNode.id === undefined) {
          this.$message.warning(this.$t('roleManage.PleaseSelectTheMenuFirst')); // 请先选择菜单
          return;
        }
        this.addEditTitle = this.$t('roleManage.EditTheMenu'); // '编辑菜单';
        this.paramForm = cloneDeep(this.selectNode);
      }
      this.showAddEdit = true;
    },
    // 编辑内容
    editContent() {
      if (this.selectNode.id === null || this.selectNode.id === '' || this.selectNode.id === undefined) {
        this.$message.warning(this.$t('roleManage.PleaseSelectTheMenuFirst')); // 请先选择菜单
        return;
      }
      if (this.selectNode.level === 0) {
        this.$message.warning(this.$t('roleManage.TheFirstLevelMenuIsNotEditable')); // 一级菜单不可编辑内容
        return;
      }
      apiPmOperationDocGetById(this.selectNode.id).then(res => {
        if (res.status === 'OK') {
          this.paramForm = res.data;
        }
      });
      this.activeName = 'content';
      this.contentKey = new Date().getTime();
    },
    // 删除
    deleteNode() {
      if (this.selectNode.id === null || this.selectNode.id === '' || this.selectNode.id === undefined) {
        this.$message.warning(this.$t('roleManage.PleaseSelectTheMenuFirst')); // 请先选择菜单
        return;
      }
      this.$confirm(this.$t('GlobalSetObj.AreYouSureDelete'), this.$t('GlobalSetObj.tips'), {
        confirmButtonText: this.$t('GlobalSetObj.determine'), // '确定',
        cancelButtonText: this.$t('GlobalSetObj.cancel'), // '取消',
        type: 'warning'
      }).then(() => {
        apiPmOperationDocDeleted(this.selectNode).then(res => {
          if (res.status === 'OK') {
            this.selectNode = {
              id: '',
              level: '',
              name: '',
              sort: '',
              content: '',
              parentId: '',
              children: []
            };
            this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
            this.getOperatorManual();
          }
        });
      });
    },
    // 保存、取消
    saveData(type) {
      // type 1: 保存 2：取消
      if (type === 1) {
        apiPmOperationDocUpdate(this.paramForm).then(res => {
          if (res.status === 'OK') {
            this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
            this.getOperatorManual();
          }
        });
      }
      this.activeName = 'catalog';
      this.contentKey = new Date().getTime();
    }
  }
};
</script>

<style scoped>
.custom-tree-node {
  font-size: 16px;
}

.custom-tree-node-btn {
  margin-left: 10px;
}

.selected-node {
  color: #1684FC;
}

.biaoti {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

>>> .el-dialog__body {
  padding: 0 20px;
}
</style>
