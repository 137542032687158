var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          key: _vm.tableKey,
          attrs: { inline: "", "label-position": "top", size: "small" },
        },
        [
          _vm.searchProp.includes("status")
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.waybillStatus") } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "330px" },
                      attrs: {
                        placeholder: _vm.$t("GlobalSetObj.all"),
                        clearable: "",
                        "collapse-tags": "",
                        filterable: "",
                        multiple: "",
                        size: "small",
                      },
                      on: { change: _vm.selectStatus },
                      model: {
                        value: _vm.queryForm.params.waybillStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm.params, "waybillStatus", $$v)
                        },
                        expression: "queryForm.params.waybillStatus",
                      },
                    },
                    _vm._l(_vm.waybillStatusList, function (item, ind) {
                      return _c("el-option", {
                        key: ind,
                        attrs: { label: item.label, value: item.code },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.searchProp.includes("customerName")
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("collectionCenter.CustomerName") } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "250px" },
                      attrs: {
                        placeholder: _vm.$t(
                          "spareNumberPoo.Only10AreDisplayed"
                        ),
                        "remote-method": _vm.getCustomerPage,
                        clearable: "",
                        filterable: "",
                        multiple: "",
                        remote: "",
                        "reserve-keyword": "",
                      },
                      on: {
                        focus: function ($event) {
                          return _vm.getCustomerPage()
                        },
                      },
                      model: {
                        value: _vm.queryForm.params.customerIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm.params, "customerIds", $$v)
                        },
                        expression: "queryForm.params.customerIds",
                      },
                    },
                    _vm._l(_vm.customerList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.customerAlias + "(" + item.username + ")",
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isArrayInclude(_vm.searchProp, [
            "preDeliverStationName",
            "deliverStationName",
            "warehouseStationName",
          ])
            ? _c(
                "el-form-item",
                { attrs: { prop: "siteDataId" } },
                [
                  _c("template", { slot: "label" }, [
                    _c(
                      "div",
                      { staticClass: "selectLabel" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            },
                            model: {
                              value: _vm.queryForm.params.providerType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm.params,
                                  "providerType",
                                  $$v
                                )
                              },
                              expression: "queryForm.params.providerType",
                            },
                          },
                          [
                            _vm.searchProp.includes("preDeliverStationName")
                              ? _c("el-option", {
                                  key: "1",
                                  attrs: {
                                    label: _vm.$t("GlobalSetObj.preEntrega"),
                                    value: 1,
                                  },
                                })
                              : _vm._e(),
                            _vm.searchProp.includes("deliverStationName")
                              ? _c("el-option", {
                                  key: "2",
                                  attrs: {
                                    label: _vm.$t("GlobalSetObj.entrega"),
                                    value: 2,
                                  },
                                })
                              : _vm._e(),
                            _vm.searchProp.includes("warehouseStationName")
                              ? _c("el-option", {
                                  key: "3",
                                  attrs: {
                                    label: _vm.$t("GlobalSetObj.infeatSite"),
                                    value: 3,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "320px" },
                      attrs: {
                        placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                        "remote-method": _vm.getSupperSite,
                        clearable: "",
                        filterable: "",
                        multiple: "",
                        remote: "",
                        "reserve-keyword": "",
                        size: "small",
                      },
                      on: {
                        change: _vm.hanleSiteId,
                        focus: function ($event) {
                          return _vm.getSupperSite("")
                        },
                      },
                      model: {
                        value: _vm.queryForm.params.siteDataId,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm.params, "siteDataId", $$v)
                        },
                        expression: "queryForm.params.siteDataId",
                      },
                    },
                    _vm._l(_vm.siteData, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("orderCenterCont.TimeoutOrNot") } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.queryForm.params.isTimeOut,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryForm.params, "isTimeOut", $$v)
                    },
                    expression: "queryForm.params.isTimeOut",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: _vm.$t("GlobalSetObj.all"), value: -1 },
                  }),
                  _c("el-option", {
                    attrs: { label: _vm.$t("GlobalSetObj.yes"), value: 1 },
                  }),
                  _c("el-option", {
                    attrs: { label: _vm.$t("GlobalSetObj.No"), value: 0 },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isArrayInclude(_vm.searchProp, ["receiveState", "warehouseState"])
            ? _c(
                "el-form-item",
                { attrs: { prop: "providerType" } },
                [
                  _c("template", { slot: "label" }, [
                    _c(
                      "div",
                      { staticClass: "selectLabel" },
                      [
                        _c(
                          "el-select",
                          {
                            style: _vm.IsWidth,
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            },
                            model: {
                              value: _vm.queryForm.params.stateType,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryForm.params, "stateType", $$v)
                              },
                              expression: "queryForm.params.stateType",
                            },
                          },
                          [
                            _vm.searchProp.includes("receiveState")
                              ? _c("el-option", {
                                  key: "1",
                                  attrs: {
                                    label: _vm.$t("newOrder.RecipientState"),
                                    value: 0,
                                  },
                                })
                              : _vm._e(),
                            _vm.searchProp.includes("warehouseState")
                              ? _c("el-option", {
                                  key: "2",
                                  attrs: {
                                    label: _vm.$t(
                                      "GlobalSetObj.storageOutletsState"
                                    ),
                                    value: 1,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "250px" },
                      attrs: {
                        placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                        clearable: "",
                        filterable: "",
                        multiple: "",
                      },
                      model: {
                        value: _vm.queryForm.params.stateList,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm.params, "stateList", $$v)
                        },
                        expression: "queryForm.params.stateList",
                      },
                    },
                    _vm._l(_vm.StateList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.label, value: item.label },
                      })
                    }),
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
          _vm.searchProp.includes("lostType")
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.LostType") } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "250px" },
                      attrs: {
                        placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                      },
                      model: {
                        value: _vm.queryForm.params.lostType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm.params, "lostType", $$v)
                        },
                        expression: "queryForm.params.lostType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label: _vm.$t("GlobalSetObj.all"),
                          value: null,
                        },
                      }),
                      _vm._l(_vm.LOST_TYPE, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isArrayInclude(_vm.searchProp, [
            "createTime",
            "customerSendTime",
            "collectScanTime",
            "receiptTime",
            "sendTime",
            "deliverUploadScanTime",
            "dispatchReceiptTime",
            "lastDeliveryTime",
            "deliveredTime",
            "startDeliveryTime",
            "customerAgingEndTime",
          ])
            ? _c(
                "el-form-item",
                { attrs: { prop: "searchTimeType" } },
                [
                  _c("template", { slot: "label" }, [
                    _c(
                      "div",
                      {
                        staticClass: "selectLabel",
                        staticStyle: { width: "450px" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            },
                            model: {
                              value: _vm.queryForm.params.timeType,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryForm.params, "timeType", $$v)
                              },
                              expression: "queryForm.params.timeType",
                            },
                          },
                          [
                            _vm.searchProp.includes("createTime")
                              ? _c("el-option", {
                                  key: "1",
                                  attrs: {
                                    label: _vm.$t("newOrder.orderCreateTime"),
                                    value: 1,
                                  },
                                })
                              : _vm._e(),
                            _vm.searchProp.includes("collectScanTime")
                              ? _c("el-option", {
                                  key: "2",
                                  attrs: {
                                    label: _vm.$t("newOrder.lanshouT"),
                                    value: 2,
                                  },
                                })
                              : _vm._e(),
                            _vm.searchProp.includes("receiptTime")
                              ? _c("el-option", {
                                  key: "3",
                                  attrs: {
                                    label: _vm.$t(
                                      "GlobalSetObj.oneTransitInboundTime"
                                    ),
                                    value: 3,
                                  },
                                })
                              : _vm._e(),
                            _vm.searchProp.includes("sendTime")
                              ? _c("el-option", {
                                  key: "4",
                                  attrs: {
                                    label: _vm.$t(
                                      "GlobalSetObj.oneshipmentTime"
                                    ),
                                    value: 4,
                                  },
                                })
                              : _vm._e(),
                            _vm.searchProp.includes("transferInStorageTime")
                              ? _c("el-option", {
                                  key: "13",
                                  attrs: {
                                    label: _vm.$t(
                                      "GlobalSetObj.twoTransitInboundTime"
                                    ),
                                    value: 13,
                                  },
                                })
                              : _vm._e(),
                            _vm.searchProp.includes("transferOutStorageTime")
                              ? _c("el-option", {
                                  key: "14",
                                  attrs: {
                                    label: _vm.$t(
                                      "GlobalSetObj.twoshipmentTime"
                                    ),
                                    value: 14,
                                  },
                                })
                              : _vm._e(),
                            _vm.searchProp.includes("dispatchReceiptTime")
                              ? _c("el-option", {
                                  key: "5",
                                  attrs: {
                                    label: _vm.$t(
                                      "newOrder.StorageTimeOutlets"
                                    ),
                                    value: 5,
                                  },
                                })
                              : _vm._e(),
                            _vm.searchProp.includes("startDeliveryTime")
                              ? _c("el-option", {
                                  key: "8",
                                  attrs: {
                                    label: _vm.$t(
                                      "orderCenterCont.FirstDeliveryTime"
                                    ),
                                    value: 8,
                                  },
                                })
                              : _vm._e(),
                            _vm.searchProp.includes("lastDeliveryTime")
                              ? _c("el-option", {
                                  key: "9",
                                  attrs: {
                                    label: _vm.$t(
                                      "orderCenterCont.deliveryTime"
                                    ),
                                    value: 9,
                                  },
                                })
                              : _vm._e(),
                            _vm.searchProp.includes("deliveredTime")
                              ? _c("el-option", {
                                  key: "6",
                                  attrs: {
                                    label: _vm.$t(
                                      "orderCenterCont.SigningTime"
                                    ),
                                    value: 6,
                                  },
                                })
                              : _vm._e(),
                            _vm.searchProp.includes("customerSendTime")
                              ? _c("el-option", {
                                  key: "10",
                                  attrs: {
                                    label: _vm.$t(
                                      "orderCenterCont.customerDeliveryTime"
                                    ),
                                    value: 10,
                                  },
                                })
                              : _vm._e(),
                            _vm.searchProp.includes("customerAgingEndTime")
                              ? _c("el-option", {
                                  key: "12",
                                  attrs: {
                                    label: _vm.$t("orderCenterCont.dueTime01"),
                                    value: 12,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-date-picker", {
                    staticStyle: { width: "450px" },
                    attrs: {
                      clearable: true,
                      "default-time": ["00:00:00", "23:59:59"],
                      format:
                        _vm.$i18n.locale == "zh" ? _vm.zhDate : _vm.ptDate,
                      "picker-options": _vm.pickerOptions,
                      placeholder: _vm.$t("newOrder.SelectDatetime"),
                      "range-separator": "~",
                      type: "datetimerange",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                    },
                    on: {
                      change: _vm.handleTime,
                      input: function ($event) {
                        return _vm.changeInput($event)
                      },
                    },
                    model: {
                      value: _vm.timeValue,
                      callback: function ($$v) {
                        _vm.timeValue = $$v
                      },
                      expression: "timeValue",
                    },
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.searchProp.includes("waybillNumber")
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("newOrder.waybillNumber"),
                    prop: "inputValue",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "260px" },
                    attrs: {
                      placeholder: _vm.$t("newOrder.PreciseSearch1"),
                      rows: 1,
                      type: "textarea",
                    },
                    model: {
                      value: _vm.queryForm.params.inputValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm.params, "inputValue", $$v)
                      },
                      expression: "queryForm.params.inputValue",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticStyle: { "margin-top": "10px" } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-search",
                        size: "small",
                        type: "primary",
                      },
                      on: { click: _vm.enterSearch },
                    },
                    [_vm._v(_vm._s(_vm.$t("newOrder.search")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-refresh-left",
                        size: "small",
                        type: "info",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.resetForm()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("newOrder.reset")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { icon: "el-icon-upload2", size: "small" },
                      on: { click: _vm.allExportOrder },
                    },
                    [_vm._v(_vm._s(_vm.$t("newOrder.export")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.clickBtchExport },
                    },
                    [_vm._v(_vm._s(_vm.$t("placeOrder.BatchExport")) + " ")]
                  ),
                  _c(
                    "el-radio-group",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { size: "small" },
                      on: { change: _vm.enterSearch },
                      model: {
                        value: _vm.queryForm.params.customerType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm.params, "customerType", $$v)
                        },
                        expression: "queryForm.params.customerType",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 1 } }, [
                        _vm._v(_vm._s(_vm.$t("newOrder.Formal"))),
                      ]),
                      _c("el-radio-button", { attrs: { label: 2 } }, [
                        _vm._v(_vm._s(_vm.$t("newOrder.test1"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        content: _vm.$t(
                          "orderCenterCont.customizingDisplayColumns"
                        ),
                        effect: "dark",
                        placement: "top",
                      },
                    },
                    [
                      _c("el-button", {
                        staticStyle: {
                          "font-size": "25px",
                          color: "#333",
                          padding: "3px",
                        },
                        attrs: { icon: "el-icon-setting", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.customColumn()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          key: _vm.tableKey,
          ref: "timelinessWaybillTable",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, border: "" },
        },
        _vm._l(_vm.currentColumns, function (item, ind) {
          return _c(
            "el-table-column",
            {
              key: ind,
              attrs: {
                label: item.label,
                "min-width": item.width,
                prop: item.prop,
                "show-overflow-tooltip": !item.tips,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        item.prop === "waybillNumber"
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#409eff",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.toOrderDetail(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(scope.row.waybillNumber) + " "
                                ),
                              ]
                            )
                          : _c("div", [
                              _vm._v(" " + _vm._s(scope.row[item.prop]) + " "),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            },
            [
              item.tips
                ? _c("template", { slot: "header" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _c("span", { staticStyle: { "margin-right": "2px" } }, [
                          _vm._v(_vm._s(item.label)),
                        ]),
                        _c(
                          "el-tooltip",
                          { attrs: { placement: "top" } },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [_vm._v(_vm._s(item.tips))]
                            ),
                            _c("svg-icon", {
                              attrs: { "icon-class": "QuestionMark" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            2
          )
        }),
        1
      ),
      _c("el-pagination", {
        staticStyle: { margin: "10px 0", float: "right" },
        attrs: {
          "current-page": _vm.queryForm.number,
          "page-size": _vm.queryForm.pageSize,
          "page-sizes": [10, 20, 50, 100],
          total: _vm.queryForm.total,
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c(
        "el-drawer",
        {
          attrs: {
            "append-to-body": true,
            visible: _vm.waybillDescription,
            "custom-class": "drawerClass",
            direction: "ltr",
            size: "90%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.waybillDescription = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { height: "calc(100vh - 67px)", overflow: "auto" } },
            [
              _c("detailPage", {
                key: _vm.detailKey,
                attrs: {
                  "query-track-param": _vm.queryTrackParam,
                  "query-type": "1",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "show-close": true,
            title: _vm.$t("placeOrder.BatchExport"),
            visible: _vm.dialogVisibleExport,
            top: "10vh",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibleExport = $event
            },
            close: _vm.clearForm,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "exportRuleForm",
              staticClass: "demo-exportRuleForm",
              attrs: { model: _vm.exportRuleForm, "label-position": "top" },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    rules: {
                      required: true,
                      message: _vm.$t("placeOrder.pleaseInput"),
                      trigger: ["blur", "change"],
                    },
                    prop: "NumberType",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "80%" },
                      model: {
                        value: _vm.exportRuleForm.NumberType,
                        callback: function ($$v) {
                          _vm.$set(_vm.exportRuleForm, "NumberType", $$v)
                        },
                        expression: "exportRuleForm.NumberType",
                      },
                    },
                    [
                      _c("el-option", {
                        key: "1",
                        attrs: {
                          label: _vm.$t("orderCenterCont.anjunWaybillNumber"),
                          value: "1",
                        },
                      }),
                      _c("el-option", {
                        key: "5",
                        attrs: {
                          label: _vm.$t("GlobalSetObj.ScanNumber"),
                          value: "5",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    rules: {
                      required: true,
                      message: _vm.$t("placeOrder.pleaseInput"),
                      trigger: ["blur"],
                    },
                    prop: "inputValeu",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t("orderCenterCont.Multiple"),
                      clearable: "",
                      rows: "6",
                      type: "textarea",
                    },
                    model: {
                      value: _vm.exportRuleForm.inputValeu,
                      callback: function ($$v) {
                        _vm.$set(_vm.exportRuleForm, "inputValeu", $$v)
                      },
                      expression: "exportRuleForm.inputValeu",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "right", margin: "15px 0" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisibleExport = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.comfireBtchExport },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.CheckItOutNow")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            "append-to-body": true,
            title: _vm.$t("orderCenterCont.SelectColumn"),
            visible: _vm.showColumn,
            "custom-class": "drawerClass",
            direction: "ltr",
            size: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showColumn = $event
            },
            close: function ($event) {
              return _vm.closeDrawer()
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "columnTree",
              staticStyle: {
                padding: "20px",
                height: "calc(100vh - 140px)",
                overflow: "auto",
              },
            },
            [
              _c("el-tree", {
                key: _vm.dragging,
                ref: "timelinessWaybillTree",
                attrs: {
                  "allow-drag": _vm.allowDrag,
                  "allow-drop": _vm.allowDrop,
                  data: _vm.columnList,
                  "default-checked-keys": _vm.checkedColumns,
                  props: _vm.props,
                  "check-on-click-node": "",
                  "default-expand-all": "",
                  draggable: "",
                  "node-key": "prop",
                  "show-checkbox": "",
                },
                on: {
                  check: _vm.handleCheckChange,
                  "node-drop": _vm.handleDrop,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var node = ref.node
                      var data = ref.data
                      return [
                        data.tips
                          ? _c(
                              "span",
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "margin-right": "2px" } },
                                  [_vm._v(_vm._s(data.label))]
                                ),
                                _c(
                                  "el-tooltip",
                                  { attrs: { placement: "top" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [_vm._v(_vm._s(data.tips))]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "QuestionMark" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _c("span", [_vm._v(_vm._s(data.label))]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                padding: "10px 20px",
                "text-align": "right",
                "border-top": "1px solid #ccc",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.determineColumns()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("operationCenter.determine")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }