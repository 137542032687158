var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.partitionId
      ? _c(
          "div",
          [
            _c(
              "el-radio-group",
              {
                attrs: { size: "small" },
                model: {
                  value: _vm.partitionAffiliateId,
                  callback: function ($$v) {
                    _vm.partitionAffiliateId = $$v
                  },
                  expression: "partitionAffiliateId",
                },
              },
              _vm._l(_vm.subregionList, function (item) {
                return _c(
                  "el-radio-button",
                  { key: item.id, attrs: { label: item.id } },
                  [_vm._v(" " + _vm._s(item.partitionIdentifier) + " ")]
                )
              }),
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "priceTableForm", staticStyle: { "margin-top": "10px" } },
      [
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "permit",
                    rawName: "v-permit:remove",
                    value: "btn:payable:quoteManagement:addEdit",
                    expression: "'btn:payable:quoteManagement:addEdit'",
                    arg: "remove",
                  },
                ],
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.handleAdd },
              },
              [_vm._v(_vm._s(_vm.$t("basicData.AddARow")))]
            ),
          ],
          1
        ),
        !_vm.partitionId
          ? _c("TableCmp", {
              ref: "defaultTableRef",
              attrs: { "table-data": _vm.defaultPartitionObj.default.arr },
              on: { change: _vm.changeDefaultPartition },
            })
          : _vm._e(),
        _vm.partitionId && _vm.subregionList.length > 0
          ? _c("TableCmp", {
              ref: "tableRef",
              attrs: { "table-data": _vm.getPartitionArr },
              on: { change: _vm.changePartition },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }