import request from '@/utils/request';

// 揽收每日应收统计 分页
export const apiStatisticsPage = (data) => {
  return request({
    method: 'POST',
    url: '/fa/statistics/page',
    data
  });
};

// 揽收每日应收统计 刷新数据
export const apiStatisticsAgainStatistics = (time) => {
  return request({
    method: 'GET',
    url: `fa/statistics/againStatistics?time=${time}`
  });
};

// 揽收每日应收统计 导出文件
export const apiStatisticsExport = (data) => {
  return request({
    method: 'POST',
    url: '/fa/statistics/export',
    data
  });
};
