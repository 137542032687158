var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "statistics" },
    [
      _c("i", { staticClass: "el-icon-info icon" }),
      _vm._l(_vm.arr, function (item) {
        return [
          _c("p", { key: item.name }, [
            _vm._v(_vm._s(item.name) + ": "),
            _c("span", [_vm._v(_vm._s(item.value))]),
            _vm._v(" " + _vm._s(item.suffix)),
          ]),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }