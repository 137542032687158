import request from '@/utils/request';

// const baseURL1 = process.env.VUE_APP_BASE_API1;
const baseURL5 = process.env.VUE_APP_BASE_API4;
// 路线分页查询
export const deliveryPathPage = (data) => {
  return request.post('/deliveryPath/page', data);
};
// 新建路线
export const deliveryPathCreate = (data) => {
  return request.post('/deliveryPath/create', data);
};
// 查询未关联的网点
export const getSiteInfo = (data) => {
  return request.post('/deliveryPath/getSiteInfo', data);
};
// 网点查询
export const getSitePath = (data) => {
  return request.post('/deliveryPath/getSitePath', data);
};
// 修改路线
export const deliveryPathUpdate = (data) => {
  return request.post('/deliveryPath/update', data);
};
// 获取运单详情
export const getOrderInfo = (id) => {
  return request.get(`/orderWaybill/${id}`);
};
// 手动取号
export const providerTakeNo = (data) => {
  return request({
    method: 'POST',
    url: '/takeNo',
    data,
    baseURL: process.env.VUE_APP_BASE_API3
  });
};
// 获取路线详情
export const getDeliveryPathInfo = (data) => {
  return request.post('/deliveryPath/getDeliveryPathInfo', data);
};
// 关联路线
export const relevancePath = (data) => {
  return request.post('/deliveryPath/relevancePath', data);
};
// 解绑关联路线
export const updatePath = (data) => {
  return request.post('/deliveryPath/updatePath', data);
};
// 解绑关联路线
export const transitBagNumberPage = (data) => {
  return request.post('/transitBagNumber/page', data);
};
// 切换运输公司
export const transportCompanyInfo = (data) => {
  return request.get(`/providerChannel/getProvider?condition=${data}`);
};
// 查询mdfe分页
export const pageMdfe = (data) => {
  return request.post('/mdfeCost/page', data);
};
// 查询mdfe分页 ==> cql
export const pageMdfeCql = (data) => {
  return request.post('/invoice/pageMdfe', data);
};
// 手动发送Mdfe付费接口
export const getCteInfo = (data) => {
  return request.post('/invoice/getCteInfo', data);
};
// 手动发送Mdfe付费接口
export const buildCostMdfe = (data) => {
  return request({
    method: 'POST',
    url: '/wms/invoice/buildCostMdfe',
    data,
    baseURL: baseURL5
  });
};
// 关闭付费Mdfe
export const clotureMdfe = (data) => {
  return request({
    method: 'POST',
    url: '/wms/invoice/clotureMdfe',
    data,
    baseURL: baseURL5
  });
};
// 二次确定关闭付费Mdfe
export const twoClotureCostMdfe = (data) => {
  return request({
    method: 'POST',
    url: '/wms/invoice/invoice/twoClotureCostMdfe',
    data,
    baseURL: baseURL5
  });
};
// 查询线上司机信息
export const logisticsDrivers = (data) => {
  return request({
    method: 'POST',
    url: '/wms/invoice/invoice/drivers',
    data,
    baseURL: baseURL5
  });
};
// 查询车签号
export const getTagNumber = (data) => {
  return request.post('/invoice/getTagNumber', data);
};
// 查询干线管理的车签号
export const getVehicleTagList = (data) => {
  return request.post('/invoice/get/vehicle/tag/list', data);
};
// 获取卸货点清单车牌号
export const getVehicleNumber = (data) => {
  return request.post('/routeGuide/getVehicleNumber', data);
};
// 获取卸货点清单车签号
export const getTagNumbers = (data) => {
  return request.post('/routeGuide/getTagNumbers', data);
};
// 获取卸货点清单
export const getRouteGuideList = (data) => {
  return request.post('/routeGuide/getRouteGuideList', data);
};
// 分页查询卸货点信息
export const unloadingpage = (data) => {
  return request.post('/unloading/page', data);
};
// 创建卸货点信息
export const createUnloading = (data) => {
  return request.post('/unloading/createUnloading', data);
};
// 卸货点查询关联网点信息
export const unloadinggetSiteInfo = (data) => {
  return request.post('/unloading/getSiteInfo', data);
};
// 编辑展示关联网点的查询
export const unloadinggetUnloadingInfo = (data) => {
  return request.post('/unloading/getUnloadingInfo', data);
};
// 创建卸货点信息
export const updateUnloading = (data) => {
  return request.post('/unloading/updateUnloading', data);
};
// 查询派送网点信息
export const getDeliverSiteInfo = (data) => {
  return request.post('/unloading/getDeliverSiteInfo', data);
};
// 查询派送网点信息
export const getUnloadingPoint = (data) => {
  return request.post('/routeGuide/getUnloadingPoint', data);
};
// 卸货点状态更新
export const updateUnloadingStatus = (data) => {
  return request.post('/unloading/updateUnloadingStatus', data);
};
// 获取车牌号
export const gettransitBagNumber = (data) => {
  return request.post('/transitBagNumber/vehicleNumber', data);
};
// 获取车签号  get请求
export const findVehicleTagNumber = (id) => {
  return request.get(`/transitBagNumber/findVehicleTagNumber/${id}`);
};
// 获取卸货点清单
export const saveVehicleTagNumber = (data) => {
  return request.post('/transitBagNumber/saveVehicleTagNumber', data);
};
// 修改路线网点/卸货点途径州
export const updatePoint = (data) => {
  return request.post('/routeGuide/updatePoint', data);
};
// 更新线路网点顺序
export const updateSort = (data) => {
  return request.post('/routeGuide/updateSort', data);
};
// 设置mdfe自动关闭时间
export const setAutoCloseMinTime = (time) => {
  return request.get(`/invoice/setAutoCloseMinTime?hour=${time}`);
};
// 根据网点ID查询途径州
export const getRouteState = (id) => {
  return request.get(`/routeGuide/getRouteState/${id}`);
};
// 根据网点ID查询途径州
export const getAutoCloseMinTime = (id) => {
  return request.get(`/invoice/getAutoCloseMinTime`);
};
// 获取车牌号 SM
// export const gettransitBagNumber = (data) => {
//   return request.post('/transitBagNumber/vehicleNumber', data);
// };
// 获取车签号 对应司机  SM
export const findVehicleTagAndDrivers = (id) => {
  return request.get(`/transitBagNumber/findVehicleTagAndDrivers/${id}`);
};
// 获取所有的司机  get请求 SM
export const findDriversAll = (id) => {
  return request.get(`/transitBagNumber/findDriversAll`);
};
// 获取所有的司机  get请求 SM
export const saveVehicleTagAndDrivers = (data) => {
  return request.post('/transitBagNumber/saveVehicleTagAndDrivers', data);
};
// 车签号 ==> post请求
export const collectTagNumberDatailPage = (data) => {
  return request.post('/collectTagNumberDatail/trunk/line/page', data);
};
/**
 * @description: 获取车签号详情
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const transitBagNumberPackNumListInfo = (data) => {
  return request.post(`/transitBagNumber/pack/num/list/info/${data}`);
};
// 获取运输公司
export const transportCompanyFun = () => {
  return request.get('/provider/getTransportCompanyList');
};
// 时效管理 ==> 列表分页
export const timelinessPage = (data) => {
  return request.post('/timeliness/transport/list', data);
};
// 时效管理 ==> 获取 所有启用的网点
export const getEnableSiteList = () => {
  return request.get('/timeliness/transport/getEnableSiteList');
};
// 时效管理 ==> 新增或更新
export const timelinesstransportAdd = (data) => {
  return request.post('/timeliness/transport/modify', data);
};
// 时效管理 ==> 状态修改
export const timelinessmodifyStatus = (data) => {
  return request.post('/timeliness/transport/modifyStatus', data);
};
// 时效管理 ==> 导出
export const timelinessTransportexport = (data) => {
  return request.post('/timeliness/transport/export', data);
};
// 中转时长 ==> 列表分页
export const timelinesstransferPage = (data) => {
  return request.post('/timeliness/transfer/list', data);
};
// 中转时长 ==> 导出
export const timelinesstransferPageexport = (data) => {
  return request.post('/timeliness/transfer/export', data);
};
// 中转时长 ==> 新增
export const timelinesstransferPagemodify = (data) => {
  return request.post('/timeliness/transfer/modify', data);
};
// 中转时长 ==> 状态修改
export const timelinesstransferPagemodifyStatus = (data) => {
  return request.post('/timeliness/transfer/modifyStatus', data);
};
// 时效管理 ==> 导入
export const timelinesstransferPagemoimportData = (data) => {
  return request.post('/timeliness/transport/importData', data);
};

// 班次管理 列表
export const apiDepartureScheduleList = (data) => {
  return request.post('/departure/schedule/list', data);
};

// 班次管理 新增/编辑
export const apiDepartureScheduleModify = (data) => {
  return request.post('/departure/schedule/modify', data);
};

// 班次管理 班次编号下拉框
export const apiDepartureScheduleGetScheduleNumber = (data) => {
  return request.get('/departure/schedule/getScheduleNumber', data);
};

// 干支线管理 获取所有启用的网点和转运中心
export const apiDepartureScheduleGetSiteAndTransfer = (data) => {
  return request.get('/branchLine/direct/getSiteAndTransfer', data);
};

// 干支线管理 发车时间
export const apiDepartureScheduleGetDepartureTime = (data) => {
  return request.get('/branchLine/direct/getDepartureTime', data);
};

// 干支线管理 列表
export const apiBranchLineDirectList = (data) => {
  return request.post('/branchLine/direct/list', data);
};

// 干支线管理 启用停用
export const apiBranchLineDirectModifyStatus = (data) => {
  return request.post('/branchLine/direct/modifyStatus', data);
};

// 干支线管理 新增或编辑
export const apiBranchLineDirectModify = (data) => {
  return request.post('/branchLine/direct/modify', data);
};
