var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.expressWaybillType === 0,
              expression: "expressWaybillType === 0",
            },
          ],
          staticClass: "print",
        },
        [
          _c(
            "div",
            { staticClass: "print_left" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    "label-width": "80px",
                    "label-position":
                      _vm.$i18n.locale != "zh" ? "top" : "right",
                    model: _vm.ordinaryObj.formObj,
                    disabled: _vm.isDisabled,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("operationCenter.length") + "(cm)",
                      },
                    },
                    [
                      _c("el-input-number", {
                        attrs: {
                          min: 0,
                          max: 5000,
                          placeholder: _vm.$t(
                            "operationCenter.PleaseEnterTheContent"
                          ),
                        },
                        model: {
                          value: _vm.ordinaryObj.formObj.length,
                          callback: function ($$v) {
                            _vm.$set(_vm.ordinaryObj.formObj, "length", $$v)
                          },
                          expression: "ordinaryObj.formObj.length",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("operationCenter.width") + "(cm)",
                      },
                    },
                    [
                      _c("el-input-number", {
                        attrs: {
                          min: 0,
                          max: 5000,
                          placeholder: _vm.$t(
                            "operationCenter.PleaseEnterTheContent"
                          ),
                        },
                        model: {
                          value: _vm.ordinaryObj.formObj.width,
                          callback: function ($$v) {
                            _vm.$set(_vm.ordinaryObj.formObj, "width", $$v)
                          },
                          expression: "ordinaryObj.formObj.width",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("operationCenter.height") + "(cm)",
                      },
                    },
                    [
                      _c("el-input-number", {
                        attrs: {
                          min: 0,
                          max: 5000,
                          placeholder: _vm.$t(
                            "operationCenter.PleaseEnterTheContent"
                          ),
                        },
                        model: {
                          value: _vm.ordinaryObj.formObj.height,
                          callback: function ($$v) {
                            _vm.$set(_vm.ordinaryObj.formObj, "height", $$v)
                          },
                          expression: "ordinaryObj.formObj.height",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "print_right" }, [
            _c("div", { staticClass: "prin_pdf" }, [
              _c(
                "ul",
                {
                  ref: "bagLabelsRef",
                  staticStyle: {
                    border: "2px solid #000",
                    width: "577pt",
                    padding: "0",
                    height: "384pt",
                  },
                  attrs: { id: "bagLabels" },
                },
                [
                  _c(
                    "li",
                    {
                      staticStyle: {
                        "list-style": "none",
                        "border-bottom": "2px solid #000",
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            height: "40pt",
                            "line-height": "40pt",
                            width: "230px",
                            "border-right": "2px solid #000",
                            "text-align": "right",
                            margin: "0",
                            "font-weight": "bold",
                            "font-size": "20px",
                            "font-family": "Arial",
                            color: "#000",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("operationCenter.bagNumber")) + ":"
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticStyle: {
                            height: "40pt",
                            "line-height": "40pt",
                            flex: "1",
                            "text-align": "center",
                            margin: "0",
                            "font-weight": "bold",
                            "font-size": "20px",
                            "font-family": "Arial",
                            color: "#000",
                          },
                        },
                        [_vm._v(_vm._s(_vm.ordinaryPropsObj.bagNo))]
                      ),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticStyle: {
                        "list-style": "none",
                        "border-bottom": "2px solid #000",
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            height: "42pt",
                            "line-height": "42pt",
                            width: "230px",
                            "border-right": "2px solid #000",
                            "text-align": "right",
                            margin: "0",
                            "font-weight": "bold",
                            "font-size": "20px",
                            "font-family": "Arial",
                            color: "#000",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("operationCenter.numberPieces")) +
                              ":"
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticStyle: {
                            height: "42pt",
                            "line-height": "42pt",
                            flex: "1",
                            "text-align": "center",
                            margin: "0",
                            "font-weight": "bold",
                            "font-size": "20px",
                            "font-family": "Arial",
                            color: "#000",
                          },
                        },
                        [_vm._v(_vm._s(_vm.ordinaryPropsObj.quantity))]
                      ),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticStyle: {
                        "list-style": "none",
                        "border-bottom": "2px solid #000",
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            height: "48pt",
                            "line-height": "48pt",
                            width: "230px",
                            "border-right": "2px solid #000",
                            "text-align": "right",
                            margin: "0",
                            "font-weight": "bold",
                            "font-size": "20px",
                            "font-family": "Arial",
                            color: "#000",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("operationCenter.Weight")) + ":"
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticStyle: {
                            height: "48pt",
                            "line-height": "48pt",
                            flex: "1",
                            "text-align": "center",
                            margin: "0",
                            "font-weight": "bold",
                            "font-size": "20px",
                            "font-family": "Arial",
                            color: "#000",
                          },
                        },
                        [_vm._v(_vm._s(_vm.ordinaryPropsObj.kg))]
                      ),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticStyle: {
                        "list-style": "none",
                        "border-bottom": "2px solid #000",
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            height: "51pt",
                            "line-height": "51pt",
                            width: "230px",
                            "border-right": "2px solid #000",
                            "text-align": "right",
                            margin: "0",
                            "font-weight": "bold",
                            "font-size": "20px",
                            "font-family": "Arial",
                            color: "#000",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("operationCenter.country")) +
                              ":"
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticStyle: {
                            height: "51pt",
                            "line-height": "51pt",
                            flex: "1",
                            "text-align": "center",
                            margin: "0",
                            "font-weight": "bold",
                            "font-size": "20px",
                            "font-family": "Arial",
                            color: "#000",
                          },
                        },
                        [_vm._v(_vm._s(_vm.ordinaryPropsObj.country))]
                      ),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticStyle: {
                        "list-style": "none",
                        "border-bottom": "2px solid #000",
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            height: "40pt",
                            "line-height": "40pt",
                            width: "230px",
                            "border-right": "2px solid #000",
                            "text-align": "right",
                            margin: "0",
                            "font-weight": "bold",
                            "font-size": "20px",
                            "font-family": "Arial",
                            color: "#000",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("operationCenter.nextHome")) +
                              ":"
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticStyle: {
                            height: "40pt",
                            "line-height": "40pt",
                            flex: "1",
                            "text-align": "center",
                            margin: "0",
                            "font-weight": "bold",
                            "font-size": "20px",
                            "font-family": "Arial",
                            color: "#000",
                          },
                        },
                        [_vm._v(_vm._s(_vm.ordinaryPropsObj.downstreamName))]
                      ),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticStyle: {
                        "list-style": "none",
                        "border-bottom": "2px solid #000",
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            height: "40pt",
                            "line-height": "40pt",
                            width: "230px",
                            "border-right": "2px solid #000",
                            "text-align": "right",
                            margin: "0",
                            "font-weight": "bold",
                            "font-size": "20px",
                            "font-family": "Arial",
                            color: "#000",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("operationCenter.volume")) + ":"
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticStyle: {
                            height: "40pt",
                            "line-height": "40pt",
                            flex: "1",
                            "text-align": "center",
                            margin: "0",
                            "font-weight": "bold",
                            "font-size": "20px",
                            "font-family": "Arial",
                            color: "#000",
                          },
                        },
                        [_vm._v(_vm._s(_vm.getSize))]
                      ),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticStyle: {
                        "list-style": "none",
                        "border-bottom": "2px solid #000",
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            height: "30pt",
                            "line-height": "30pt",
                            width: "230px",
                            "border-right": "2px solid #000",
                            "text-align": "right",
                            margin: "0",
                            "font-weight": "bold",
                            "font-size": "20px",
                            "font-family": "Arial",
                            color: "#000",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("operationCenter.logotype")) +
                              ":"
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticStyle: {
                            height: "30pt",
                            "line-height": "30pt",
                            flex: "1",
                            "text-align": "center",
                            margin: "0",
                            "font-weight": "bold",
                            "font-size": "20px",
                            "font-family": "Arial",
                            color: "#000",
                          },
                        },
                        [_vm._v(_vm._s(_vm.ordinaryPropsObj.Sender))]
                      ),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "code",
                      staticStyle: {
                        "list-style": "none",
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "center",
                        height: "70pt",
                      },
                    },
                    [
                      _c("svg", {
                        staticStyle: { height: "63pt" },
                        attrs: { id: "baglabcode" },
                      }),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.expressWaybillType === 1,
              expression: "expressWaybillType === 1",
            },
          ],
          staticClass: "other",
        },
        [
          _c(
            "ul",
            { ref: "other", staticClass: "other_list", attrs: { id: "other" } },
            [
              _c("li", [
                _c("div", { staticClass: "left" }, [
                  _c("p", { staticClass: "left_title" }, [
                    _vm._v(_vm._s(_vm.otherPropsObj.origemInfo)),
                  ]),
                ]),
                _c("div", { staticClass: "right" }, [
                  _c("div", { staticClass: "imgs" }, [
                    _c("img", { attrs: { src: _vm.correlosImg, alt: "logo" } }),
                  ]),
                  _c("div", { staticClass: "identification imgs" }, [
                    _vm.otherPropsObj.serviceType ===
                    _vm.serviceTypeObj["PACKET EXPRESS"].code
                      ? _c("img", { attrs: { src: _vm.kdImg, alt: "logo" } })
                      : _vm.otherPropsObj.serviceType ===
                        _vm.serviceTypeObj["PACKET STANDARD"].code
                      ? _c("img", { attrs: { src: _vm.bxImg, alt: "logo" } })
                      : _c("img", { attrs: { src: _vm.miniImg, alt: "logo" } }),
                  ]),
                  _c("div", { staticClass: "txt" }, [
                    _vm._v(_vm._s(_vm.otherPropsObj.serviceType)),
                  ]),
                ]),
              ]),
              _c("li", [
                _c("div", { staticClass: "left" }, [
                  _vm._m(0),
                  _c("p", { staticClass: "cont" }, [
                    _vm._v(_vm._s(_vm.otherPropsObj.bigBagId)),
                  ]),
                ]),
                _vm._m(1),
              ]),
              _c("li", [
                _c("div", { staticClass: "left" }, [
                  _vm._m(2),
                  _c("p", { staticClass: "cont" }, [
                    _vm._v(_vm._s(_vm.otherPropsObj.serialNumbe)),
                  ]),
                ]),
                _vm._m(3),
              ]),
              _c("li", [
                _c("div", { staticClass: "left" }, [
                  _vm._m(4),
                  _c("p", { staticClass: "cont" }, [
                    _vm._v(_vm._s(_vm.otherPropsObj.date)),
                  ]),
                ]),
                _c("div", { staticClass: "right" }, [
                  _c("div", [
                    _c("p", [
                      _vm._v("Aeroporto de Origem (Airport of departure)"),
                    ]),
                    _c("p", [_vm._v(_vm._s(_vm.otherPropsObj.startingStr))]),
                  ]),
                  _c("div", [
                    _c("p", [
                      _vm._v("Aeroporto de Destino(Airport of Offloading)"),
                    ]),
                    _c("p", [_vm._v(_vm._s(_vm.otherPropsObj.endStr))]),
                  ]),
                ]),
              ]),
              _c("li", [
                _c("div", { staticClass: "left" }, [
                  _c("div", { staticClass: "child" }, [
                    _vm._m(5),
                    _c("p", { staticClass: "cont" }, [
                      _vm._v(_vm._s(_vm.otherPropsObj.quantity)),
                    ]),
                  ]),
                  _c("div", { staticClass: "child" }, [
                    _vm._m(6),
                    _c("p", { staticClass: "cont" }, [
                      _vm._v(_vm._s(_vm.otherPropsObj.weight)),
                    ]),
                  ]),
                  _vm._m(7),
                ]),
                _c("div", { staticClass: "right" }, [
                  _c("svg", { attrs: { id: "barcode" } }),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c("el-divider"),
      _c(
        "div",
        { staticClass: "buts" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.expressWaybillType === 0,
                  expression: "expressWaybillType === 0",
                },
              ],
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handlerConfirm(_vm.setVolume)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("operationCenter.BagSealingPrinting")))]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.expressWaybillType === 1,
                  expression: "expressWaybillType === 1",
                },
              ],
              attrs: { type: "primary" },
              on: { click: _vm.setVolumeOther },
            },
            [_vm._v(_vm._s(_vm.$t("operationCenter.BagSealingPrinting")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "mr10",
              attrs: { type: "info" },
              on: { click: _vm.handlePrint },
            },
            [_vm._v(_vm._s(_vm.$t("operationCenter.print")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "mr10",
              on: {
                click: function ($event) {
                  return _vm.handleClose(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("operationCenter.Cancel")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "left_title" }, [
      _c("span", [_vm._v("Nº Do Despacho")]),
      _c("span", [_vm._v("(Dispatch No.)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right" }, [
      _c("p", [
        _c("span", [_vm._v("Empresa Brasileira de Correios e Telégrafos")]),
        _c("span", [_vm._v("Centro Internacional de Curitiba - SE / PR")]),
        _c("span", [
          _vm._v("Rua Salgado Filho, 476, Jardim Amélia - Pinhais / PR"),
        ]),
        _c("span", [_vm._v("CEP: 83.330-972")]),
        _c("span", [_vm._v("CNPJ: 34.028.316/0031-29")]),
      ]),
      _c("p", [_vm._v("CEI13")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "left_title" }, [
      _c("span", [_vm._v("Nº Serial da Mala")]),
      _c("span", [_vm._v("(Receptacle Serial Number)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right" }, [
      _c("p", [_vm._v("Nº VÔO (Flight Number)")]),
      _c("p"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "left_title" }, [
      _c("span", [_vm._v("Data do despacho (Date)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", [_vm._v("Quantidade de Itens(Quantity)")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", [_vm._v("Peso Kg (Weight kg)")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "child" }, [
      _c("p", [_vm._v("Correios Packet Standard")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }