var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tabClass" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              key: 0,
              attrs: {
                label: _vm.$t("GlobalSetObj.bagNumber"),
                name: "TrunkStatus",
              },
            },
            [
              _vm.isShow
                ? _c("TrunkStatus", { on: { setTagNumber: _vm.setTagNumber } })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              key: 1,
              attrs: {
                label: _vm.$t("GlobalSetObj.NIV"),
                name: "CarSignature",
              },
            },
            [
              _c("CarSignature", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isShow1,
                    expression: "isShow1",
                  },
                ],
                ref: "CarSignature",
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }