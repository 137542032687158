<template>
  <div class="app-container">
    <el-form label-position="top">
      <!--提交人-->
      <el-form-item>
        <template slot="label">
          <div class="selectLabel">
            <el-select
              v-model="searchType"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              @change="changeSearchType"
            >
              <el-option key="1" :label="$t('GlobalSetObj.NIV')" value="1" />
              <el-option key="2" :label="$t('GlobalSetObj.licensePlateNumber')" value="2" />
            </el-select>
          </div>
        </template>
        <el-input
          v-if="searchType == 1"
          v-model="queryForm.params.tagNumber"
          :placeholder="$t('orderDetails.pleaseInput')"
          clearable
          style="width: 250px"
          @keyup.enter.native.prevent="getSearchEnter"
        />
        <el-input
          v-else-if="searchType ==2"
          v-model="queryForm.params.vehicleNumber"
          :placeholder="$t('orderDetails.pleaseInput')"
          clearable
          style="width: 250px"
          @keyup.enter.native.prevent="getSearchEnter"
        />
      </el-form-item>
      <el-form-item>
        <!--搜索-->
        <el-button type="primary" @click="getSearchEnter">{{ $t('newOrder.search') }}</el-button>
        <!--重置-->
        <el-button type="info" @click="resetForm">{{ $t('newOrder.reset') }}</el-button>
      </el-form-item>
    </el-form>
    <!--  表格数据  -->
    <AnjunTableList
      ref="anjunTable"
      :format-time="isTormat"
      :has-operation="true"
      :header-list="tableHeaderList"
      :is-tip="true"
      :table-data="tableData"
      :on-print-name="$t('GlobalSetObj.BagNumberList')"
      @on-PrintList="onPrintList"
    />
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <tagNumberPdf v-if="shouPdf" ref="tagNumberPdf" :param-shipping-data="paramShippingData" />
  </div>
</template>

<script>
import AnjunTableList from '@/components/anjun-tableList';
import tagNumberPdf from './tagNumberPdf';
import { getVehicleTagList, transitBagNumberPackNumListInfo } from '@/api/routerPlan';

export default {
  name: 'PackageReturned',
  components: {
    AnjunTableList,
    tagNumberPdf
  },
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      queryForm: {
        timeValue: [],
        pageSize: 10,
        pageNumber: 1,
        total: 0,
        params: {
          tagNumber: '',
          vehicleNumber: ''
        }
      },
      // 搜索字段名
      searchType: '1', // 1：车签号 2：车牌号
      tableHeaderList: [
        { id: 1, label: this.$i18n.t('GlobalSetObj.NIV'), prop: 'tagNumber', cloWidth: '210' },
        { id: 2, label: this.$i18n.t('GlobalSetObj.licensePlateNumber'), prop: 'vehicleNumber', cloWidth: '210' },
        { id: 3, label: this.$i18n.t('GlobalSetObj.DeliveryRoutes'), prop: 'siteName', cloWidth: '210' },
        { id: 4, label: this.$i18n.t('GlobalSetObj.closingTime'), prop: 'loadedTime', cloWidth: '210' },
        { id: 5, label: this.$i18n.t('GlobalSetObj.personInChargeOfClosing'), prop: 'loadedUserName', cloWidth: '210' }
      ],
      tableData: [],
      isDisable: false, // 判断是否可以处理
      paramShippingData: {}, // pdf参数
      shouPdf: false
    };
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getSearch();
  },
  methods: {
    getSearchEnter() {
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    // 重置
    resetForm() {
      this.queryForm.params = {
        tagNumber: '',
        vehicleNumber: ''
      };
      this.getSearchEnter();
    },
    // 查询
    getSearch() {
      const data = {};
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.pageNumber;
      data.params = this.queryForm.params;
      data.params.shippingStatus = 1;
      getVehicleTagList(data).then(res => {
        if (res.status === 'OK') {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records.map((item) => {
            return { ...item, PrintList: true };
          });
          console.log(this.tableData, 'this.tableDatathis.tableData');
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    },
    // 设置查询车签号
    setGetData(val) {
      this.queryForm.params.tagNumber = val;
      this.searchType = '1';
      this.getSearch();
    },
    /**
       * @description: 打印清单
       */
    onPrintList(row) {
      console.log('打印清单', row);
      transitBagNumberPackNumListInfo(row.tagNumber)
        .then((res) => {
          if (res.status === 'OK') {
            this.paramShippingData = res.data;
            this.shouPdf = true;
            // 浏览器打印
            this.$nextTick(() => {
              this.$refs.tagNumberPdf.handlePrint();
              this.$nextTick(() => {
                this.shouPdf = false;
              });
            });
          }
        });
    },
    /**
       * @description: 切换搜索类型
       */
    changeSearchType() {
      if (this.searchType == 1) {
        this.queryForm.params.vehicleNumber = '';
      } else if (this.searchType == 2) {
        this.queryForm.params.tagNumber = '';
      }
    }
  }
};
</script>

<style scoped>
.app-container >>> .el-dialog__body {
  padding: 10px 20px;
}

.app-container >>> .el-form--label-top .el-form-item__label {
  width: 100% !important;
}

.app-container >>> .selectLabel .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

.app-container >>> .selectLabel .el-input__icon {
  line-height: 28px !important;
}
</style>
