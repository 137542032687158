<script >
import { defineProps, computed, defineEmits } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  sellers: {
    type: Array
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const listSellers = computed({
    get() {
      return props.sellers;
    },
    set(newSellers) {
      emit('updateSeller', newSellers);
    }
  });
  function removeSeller(sellerCode) {
    const newListSeller = listSellers.value.filter(itemSellerCode => {
      return itemSellerCode !== sellerCode;
    });
    listSellers.value = newListSeller;
  }
  return {
    listSellers,
    removeSeller
  };
};
export default __sfc_main;
</script>

<template>
  <ul class="list-sellers">
    <li
      v-for="(sellerCode, index) in listSellers"
      :key="index"
      class="item-seller"
    >
      <p class="seller-name">
        {{ `${sellerCode}` }}
      </p>
      <o-button variant="danger" size="sm" @click="removeSeller(sellerCode)">
        <o-icon icon="trash" />
      </o-button>
    </li>
  </ul>
</template>

<style scoped>
.list-sellers {
  max-height: 24rem/* 384px */;
  overflow-y: auto;
  display: grid;
  gap: 1rem/* 16px */;
}

.item-seller {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
}

.seller-name {
  font-weight: 700;
}

</style>
