<template>
  <div class="app-container">
    <el-row :gutter="10">
      <el-col :span="12">
        <div>
          <!--新增角色-->
          <el-button
            v-permit:remove="'btn:supplierManagement:VendorRole:add'"
            size="small"
            type="primary"
            :disabled="operationType === 2"
            @click="addVendorRole(1)"
          >{{ $t('roleManage.addRole') }}</el-button>
          <!--编辑-->
          <el-button
            v-permit:remove="'btn:supplierManagement:VendorRole:edit'"
            size="small"
            type="primary"
            :disabled="operationType !== null"
            @click="editVendorRole(2)"
          >{{ $t('GlobalSetObj.edit') }}</el-button>
          <!--启用-->
          <el-button
            v-permit:remove="'btn:supplierManagement:VendorRole:enable'"
            size="small"
            type="primary"
            :disabled="isQt"
            @click="updateStatusType(1)"
          >{{ $t('GlobalSetObj.enable') }}</el-button>
          <!--停用-->
          <el-button
            v-permit:remove="'btn:supplierManagement:VendorRole:stop'"
            size="small"
            type="primary"
            :disabled="isQt"
            @click="updateStatusType(0)"
          >{{ $t('GlobalSetObj.stop') }}</el-button>
          <!--保存-->
          <el-button
            :disabled="isSave"
            size="small"
            type="primary"
            @click="saveVendorRole()"
          >{{ $t('basicData.Save') }}</el-button>
          <el-button
            :disabled="isSave"
            size="small"
            type="danger"
            @click="clearVendorRole()"
          >{{ $t('basicData.Cancel') }}</el-button>
        </div>
        <div style="margin-top: 10px;" class="vendorRoleList">
          <el-form
            ref="vendorRoleData" size="mini" :model="vendorRoleData"
            label-width="0" style="height: 100%;"
          >
            <el-table
              ref="vendorRoleList"
              :data="vendorRoleData.addVendorRoleList"
              :height="tableHeight"
              border
              :header-cell-class-name="disabledCheckAll"
              @select="handleSelectionChange"
              @row-click="selectionRow"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
              />
              <el-table-column :label="$t('operationCenter.ind')" width="60px" align="center">
                <template slot-scope="scope">
                  <span>{{ total - ((queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index) }}</span>
                </template>
              </el-table-column>
              <!--中文角色名称-->
              <el-table-column
                prop="roleName"
                :label="$t('GlobalSetObj.ChineseRoleName')"
                align="center"
              >
                <template slot-scope="scope">
                  <div class="formStyle">
                    <div v-if="scope.row.isEdit === false">{{ scope.row.roleName }}</div>
                    <el-form-item
                      v-else
                      :prop="'addVendorRoleList.' + scope.$index + '.roleName'"
                      :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
                    >
                      <div @click.stop>
                        <el-input v-model="scope.row.roleName" @change="scope.row.isChange = true" />
                      </div>
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <!--葡语角色名称-->
              <el-table-column
                prop="portugal"
                :label="$t('GlobalSetObj.PortugueseCharacterNames')"
                align="center"
              >
                <template slot-scope="scope">
                  <div class="formStyle">
                    <div v-if="scope.row.isEdit === false">{{ scope.row.portugal }}</div>
                    <el-form-item
                      v-else
                      :prop="'addVendorRoleList.' + scope.$index + '.portugal'"
                      :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
                    >
                      <div @click.stop>
                        <el-input v-model="scope.row.portugal" @change="scope.row.isChange = true" />
                      </div>
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <!--角色状态-->
              <el-table-column
                prop="state"
                :label="$t('GlobalSetObj.RoleStatus')"
                align="center"
              >
                <template slot-scope="scope">
                  <div class="formStyle">
                    <div v-if="scope.row.isEdit === false">{{ scope.row.state === 0 ? $t('GlobalSetObj.stop') : $t('GlobalSetObj.enable') }}</div>
                    <el-form-item
                      v-else
                      :prop="'addVendorRoleList.' + scope.$index + '.state'"
                      :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
                    >
                      <el-select
                        v-model="scope.row.state"
                        filterable
                        :placeholder="$t('operationCenter.PleaseSelect')"
                        style="width: 100%;"
                        @change="scope.row.isChange = true"
                      >
                        <el-option :key="0" :label="$t('GlobalSetObj.stop')" :value="0" />
                        <el-option :key="1" :label="$t('GlobalSetObj.enable')" :value="1" />
                      </el-select>
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <!--最新修改时间-->
              <el-table-column
                prop="updateTime"
                :label="$t('GlobalSetObj.LastModified')"
                align="center"
              />
              <!--最新修改人-->
              <el-table-column
                prop="updateUserName"
                :label="$t('GlobalSetObj.LatestModifiedBy')"
                align="center"
              />
            </el-table>
          </el-form>
        </div>
        <!-- 分页工具 -->
        <div class="pagination">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryForm.pageNumber"
            :page-sizes="[10, 20, 50, 100]"
            :page-size.sync="queryForm.pageSize"
            :total="total"
            background
            style="margin: 10px 0"
            @current-change="currentTableData"
            @size-change="sizeChangeTableData"
          />
        </div>
      </el-col>
      <el-col :span="12">
        <el-tabs type="border-card">
          <el-tab-pane label="PC">
            <div style="height: calc(100vh - 180px);overflow: auto;">
              <el-tree
                ref="pcTree"
                :key="treeKey"
                show-checkbox
                node-key="id"
                :check-strictly="true"
                check-on-click-node
                default-expand-all
                :data="permissionPcList"
                :expand-on-click-node="false"
                :props="defaultRole"
                :default-checked-keys="defaultRolePcCheck"
                @check="handleCheckPcChange"
              >
                <span slot-scope="{ node,data }" class="custom-tree-node mb">
                  <span class="titlts">
                    <i v-if="data.parentId === 0" class="iconfont icon-folder-open" />
                    <i v-else class="iconfont icon-wenjian" />
                    {{ data.name }}
                  </span>
                </span>
              </el-tree>
            </div>
          </el-tab-pane>
          <el-tab-pane label="PDA">
            <div style="height: calc(100vh - 180px);overflow: auto;">
              <el-tree
                ref="pdaTree"
                :key="treeKey"
                show-checkbox
                node-key="id"
                :check-strictly="true"
                check-on-click-node
                default-expand-all
                :data="permissionPadList"
                :expand-on-click-node="false"
                :props="defaultRole"
                :default-checked-keys="defaultRolePadCheck"
                @check="handleCheckPadChange"
              >
                <span slot-scope="{ node,data }" class="custom-tree-node mb">
                  <span class="titlts">
                    <i v-if="data.parentId === 0" class="iconfont icon-folder-open" />
                    <i v-else class="iconfont icon-wenjian" />
                    {{ data.name }}
                  </span>
                </span>
              </el-tree>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  getPmRolePage,
  getPermissionStructure,
  addPmRole,
  editPmRole
} from '@/api/role';

export default {
  name: 'VendorRole',
  data() {
    return {
      isSave: true,
      vendorRoleData: {
        addVendorRoleList: []
      },
      selectData: null,
      selectDataList: [],
      tableHeight: 300,
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {}
      },
      total: 0,
      operationType: null,
      permissionPcList: [],
      defaultRole: {
        children: 'children',
        label: 'name'
      },
      permissionPadList: [],
      defaultRolePcCheck: [],
      defaultRolePadCheck: [],
      pcRoleAll: [],
      padRoleAll: [],
      treeKey: new Date().getTime(),
      isQt: true,
      addTableList: []
    };
  },
  created() {
    const pageHeight = document.documentElement.clientHeight; // 可用窗口的高度
    this.tableHeight = Number(pageHeight - 200); // 计算完之后剩余table可用的高度
    this.getTableData();
    this.getRoleTree();
  },
  mounted() {
    this.bus.$on('updateRoleTree', (data) => {
      this.getRoleTree();
    });
  },
  methods: {
    // 禁用全选
    disabledCheckAll({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return 'selectAllBtnDis';
      }
    },
    // 启用停用
    updateStatusType(type) {
      const addObj = [];
      this.selectDataList.map(item => {
        const obj = {
          id: item.id,
          roleName: item.roleName,
          portugal: item.portugal,
          permissionIds: item.permissionIds, // item.id === this.selectData.id ? arrList : item.permissionIds,
          state: type
        };
        addObj.push(obj);
      });
      const param = {
        roles: addObj
      };
      editPmRole(param).then(res => {
        if (res.status === 'OK') {
          this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
          this.clearVendorRole();
        }
      });
    },
    // 取消
    clearVendorRole() {
      this.getTableData();
      this.$refs.vendorRoleList.clearSelection();
      this.selectDataList = [];
      this.selectData = null;
      this.addTableList = [];
      this.defaultRolePcCheck = [];
      this.defaultRolePadCheck = [];
      this.isSave = true;
      this.operationType = null;
      this.isQt = true;
      this.treeKey = new Date().getTime();
    },
    // 列表复选框勾选、取消
    handleSelectionChange(arr, row) {
      this.$refs.vendorRoleList.clearSelection();
      this.selectDataList = [];
      if (!this.selectData || row.id !== this.selectData.id) {
        this.selectDataList = [row];
        this.selectData = row;
        const pc = this.pcRoleAll.filter(item => row.permissionIds.indexOf(item) > -1);
        const pad = this.padRoleAll.filter(item => row.permissionIds.indexOf(item) > -1);
        this.defaultRolePcCheck = pc;
        this.defaultRolePadCheck = pad;
        this.$refs.vendorRoleList.toggleRowSelection(row);
      } else {
        this.selectData = null;
        this.defaultRolePcCheck = [];
        this.defaultRolePadCheck = [];
      }
      this.treeKey = new Date().getTime();
      const a = [];
      this.selectDataList.map(item => {
        if (a.indexOf(item.state) === -1) {
          a.push(item.state);
        }
      });
      if (this.selectDataList.length === 0 || this.operationType !== null || a.length > 1) {
        this.isQt = true;
      } else {
        this.isQt = false;
      }
    },
    // 点击行选中
    selectionRow(row) {
      this.$refs.vendorRoleList.clearSelection();
      this.selectDataList = [];
      if (!this.selectData || row.id !== this.selectData.id) {
        this.selectDataList = [row];
        this.selectData = row;
        const pc = this.pcRoleAll.filter(item => row.permissionIds.indexOf(item) > -1);
        const pad = this.padRoleAll.filter(item => row.permissionIds.indexOf(item) > -1);
        this.defaultRolePcCheck = pc;
        this.defaultRolePadCheck = pad;
        this.$refs.vendorRoleList.toggleRowSelection(row);
      } else {
        this.selectData = null;
        this.defaultRolePcCheck = [];
        this.defaultRolePadCheck = [];
      }
      this.treeKey = new Date().getTime();
      const a = [];
      this.selectDataList.map(item => {
        if (a.indexOf(item.state) === -1) {
          a.push(item.state);
        }
      });
      if (this.selectDataList.length === 0 || this.operationType !== null || a.length > 1) {
        this.isQt = true;
      } else {
        this.isQt = false;
      }
    },
    // 获取列表
    getTableData() {
      getPmRolePage(this.queryForm).then((response) => {
        if (response.status === 'OK') {
          const { records, total } = response.data;
          this.total = total;
          if (this.operationType === 2) {
            records.map(item => {
              item.isEdit = true;
            });
          } else {
            records.map(item => {
              item.isEdit = false;
            });
          }
          this.vendorRoleData.addVendorRoleList = [...this.addTableList, ...records];
        }
      });
    },
    // 点击页码翻页
    currentTableData(val) {
      this.queryForm.pageNumber = val;
      this.getTableData();
      this.selectData = null;
      this.defaultRolePcCheck = [];
      this.defaultRolePadCheck = [];
      this.selectDataList = [];
      this.treeKey = new Date().getTime();
    },
    // 每页显示数量切换
    sizeChangeTableData(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.getTableData();
      this.selectData = null;
      this.defaultRolePcCheck = [];
      this.defaultRolePadCheck = [];
      this.selectDataList = [];
      this.treeKey = new Date().getTime();
    },
    // 递归获取权限代码
    recursionTreeCode(data, code) {
      // 使用map遍历,生成新的数组
      data.map(item => {
        if (code.includes(item.id) === false) {
          code.push(item.id);
        }
        if (item.children && item.children.length > 0) {
          this.recursionTreeCode(item.children, code);
        }
      });
      return code;
    },
    // 获取权限树
    getRoleTree() {
      getPermissionStructure().then(res => {
        if (res.status === 'OK') {
          this.permissionPcList = res.data.permissionStructurePc || [];
          this.permissionPadList = res.data.permissionStructurePda || [];
          this.pcRoleAll = this.recursionTreeCode(this.permissionPcList, []); // res.data.lastChildrenIdsPc || [];
          this.padRoleAll = this.recursionTreeCode(this.permissionPadList, []); // res.data.lastChildrenIdsPda || [];
          this.treeKey = new Date().getTime();
        }
      });
    },
    // 添加角色
    addVendorRole(type) {
      this.$refs.vendorRoleList.clearSelection();
      this.selectDataList = [];
      this.selectData = null;
      this.defaultRolePcCheck = [];
      this.defaultRolePadCheck = [];
      this.isSave = false;
      this.operationType = type;
      this.isQt = true;
      this.treeKey = new Date().getTime();
      const addObj = {
        id: 'add' + new Date().getTime(),
        roleName: '',
        portugal: '',
        state: 1,
        permissionIds: [],
        isEdit: true,
        isAdd: true
      };
      this.addTableList.unshift(addObj);
      this.vendorRoleData.addVendorRoleList.unshift(addObj);
      this.total += 1;
      this.$refs.vendorRoleList.toggleRowSelection(addObj);
    },
    // 编辑
    editVendorRole(type) {
      // this.$refs.vendorRoleList.clearSelection();
      // this.selectDataList = [];
      // this.selectData = null;
      // 没有选中数据时，权限默认全不选中
      if (Object.keys(this.selectData).length === 0) {
        this.defaultRolePcCheck = [];
        this.defaultRolePadCheck = [];
      }
      this.isSave = false;
      this.operationType = type;
      this.isQt = true;
      this.treeKey = new Date().getTime();
      this.vendorRoleData.addVendorRoleList.map(item => {
        item.isEdit = true;
        item.isChange = false;
      });
    },
    // 父级节点取消选中子级全部取消选中
    setChildNode(node) {
      if (node.checked === false) {
        if (node.childNodes && node.childNodes.length) {
          node.childNodes.forEach(item => {
            item.checked = false;
            this.setChildNode(item);
          });
        }
      }
    },
    // 子级节点选中所有父级全部选中
    setParentNode(node) {
      if (node.parent) {
        for (const key in node) {
          if (key === 'parent') {
            node[key].checked = true;
            this.setParentNode(node[key]);
          }
        }
      }
    },
    // 递归设置子节点和父节点
    setNode(node) {
      if (node.checked) {
        // 如果当前是选中checkbox,则递归设置父节点和父父节点++选中
        this.setParentNode(node);
      } else {
        // 如果当前是取消选中checkbox,则递归设置子节点全部取消选中
        this.setChildNode(node);
      }
    },
    // pc权限勾选
    handleCheckPcChange(node, data) {
      const clickNode = this.$refs.pcTree.getNode(node.id);
      console.log(clickNode, '===clickNode');
      this.setNode(clickNode);
      this.defaultRolePcCheck = this.$refs.pcTree.getCheckedKeys();
      if (this.selectData) {
        const dataTree = this.$refs.pdaTree.getCheckedKeys();
        const checkData = dataTree.concat(this.defaultRolePcCheck);
        const allCheck = [...checkData];
        const arrNew = new Set(allCheck);
        const arrList = Array.from(arrNew);
        this.vendorRoleData.addVendorRoleList.map((item, ind) => {
          if (this.selectData.id === item.id) {
            if (this.operationType !== null) {
              item.permissionIds = arrList;
              if (this.operationType === 2) {
                item.isChange = true;
              }
            }
          }
        });
      }
    },
    // pad权限勾选
    handleCheckPadChange(node, data) {
      const clickNode = this.$refs.pdaTree.getNode(node.id);
      this.setNode(clickNode);
      this.defaultRolePadCheck = this.$refs.pdaTree.getCheckedKeys();
      if (this.selectData) {
        const dataTree = this.$refs.pcTree.getCheckedKeys();
        const checkData = dataTree.concat(this.defaultRolePadCheck);
        const allCheck = [...checkData];
        const arrNew = new Set(allCheck);
        const arrList = Array.from(arrNew);
        this.vendorRoleData.addVendorRoleList.map((item, ind) => {
          if (this.selectData.id === item.id) {
            if (this.operationType !== null) {
              item.permissionIds = arrList;
              if (this.operationType === 2) {
                item.isChange = true;
              }
            }
          }
        });
      }
    },
    // 保存
    saveVendorRole() {
      this.$refs['vendorRoleData'].validate((valid) => {
        if (valid) {
          if (this.operationType === 1) {
            const addObj = [];
            this.vendorRoleData.addVendorRoleList.map(item => {
              if (item.isAdd) {
                const obj = {
                  roleName: item.roleName,
                  portugal: item.portugal,
                  permissionIds: item.permissionIds, // item.id === this.selectData.id ? arrList : [],
                  state: item.state
                };
                addObj.push(obj);
              }
            });
            const param = {
              roles: addObj
            };
            addPmRole(param).then(res => {
              if (res.status === 'OK') {
                this.queryForm.pageNumber = 1;
                this.$message.success(this.$t('GlobalSetObj.SaveSuccessfully'));
                this.clearVendorRole();
              }
            });
          } else {
            const addObj = [];
            this.vendorRoleData.addVendorRoleList.map(item => {
              if (item.isChange) {
                const obj = {
                  id: item.id,
                  roleName: item.roleName,
                  portugal: item.portugal,
                  permissionIds: item.permissionIds, // item.id === this.selectData.id ? arrList : item.permissionIds,
                  state: item.state
                };
                addObj.push(obj);
              }
            });
            const param = {
              roles: addObj
            };
            editPmRole(param).then(res => {
              if (res.status === 'OK') {
                this.queryForm.pageNumber = 1;
                this.$message.success(this.$t('GlobalSetObj.SaveSuccessfully'));
                this.clearVendorRole();
              }
            });
          }
        } else {
          this.$message.warning(this.$t('GlobalSetObj.required'));
          return false;
        }
      });
    }
  }
};
</script>

<style scoped>
.app-container >>> .vendorRoleList .el-form-item {
  margin-bottom: 0
}

::v-deep .selectAllBtnDis .cell {
  visibility: hidden;
}
</style>
