<template>
  <div class="warp" style="padding:0 20px;margin-top: 20px;">
    <el-descriptions :column="4" class="margin-top" direction="vertical">
      <el-descriptions-item :label="$t('collectionCenter.loadingPicture')">
        <div
          v-if="loadPictureList.length > 0" class="fle-left"
          @click="seeImage(loadPictureList,$t('collectionCenter.loadingPicture'))"
        >
          <span>{{ $t('GlobalSetObj.see') }}</span>
          <svg-icon icon-class="iconEye" style="color: #409EFF;" />
        </div>
        <div v-else class="fle-left">
          <span>{{ "--" }}</span>
        </div>
      </el-descriptions-item>
      <!--      <el-descriptions-item :label="$t('collectionCenter.unloadingPicture')">-->
      <!--        <div class="fle-left" @click="seeImage(1,$t('collectionCenter.unloadingPicture'))">-->
      <!--          <span>{{ $t('GlobalSetObj.see') }}</span>-->
      <!--          <svg-icon icon-class="iconEye" style="color: #409EFF;" />-->
      <!--        </div>-->
      <!--      </el-descriptions-item>-->
      <el-descriptions-item :label="$t('navbar.loadSign')">
        <div v-if="loadSignList.length > 0" class="fle-left" @click="seeImage(loadSignList,$t('navbar.loadSign'))">
          <span>{{ $t('GlobalSetObj.see') }}</span>
          <svg-icon icon-class="iconEye" style="color: #409EFF;" />
        </div>
        <div v-else class="fle-left">
          <span>{{ "--" }}</span>
        </div>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('navbar.unLoadSign')">
        <div
          v-if="unLoadSignList.length > 0" class="fle-left"
          @click="seeImage(unLoadSignList,$t('navbar.unLoadSign'))"
        >
          <span>{{ $t('GlobalSetObj.see') }}</span>
          <svg-icon icon-class="iconEye" style="color: #409EFF;" />
        </div>
        <div v-else class="fle-left">
          <span>{{ "--" }}</span>
        </div>
      </el-descriptions-item>
      <!-- 封车图片 -->
      <el-descriptions-item :label="$t('collectionCenter.sealVehiclePicture')">
        <div
          v-if="sealVehiclePictureList.length > 0" class="fle-left"
          @click="seeImage(sealVehiclePictureList,$t('collectionCenter.sealVehiclePicture'))"
        >
          <span>{{ $t('GlobalSetObj.see') }}</span>
          <svg-icon icon-class="iconEye" style="color: #409EFF;" />
        </div>
        <div v-else class="fle-left">
          <span>{{ "--" }}</span>
        </div>
      </el-descriptions-item>
      <!-- 解封车图片 -->
      <el-descriptions-item :label="$t('collectionCenter.unsealVehiclePicture')">
        <div
          v-if="unsealVehiclePictureList.length > 0" class="fle-left"
          @click="seeImage(unsealVehiclePictureList,$t('collectionCenter.unsealVehiclePicture'))"
        >
          <span>{{ $t('GlobalSetObj.see') }}</span>
          <svg-icon icon-class="iconEye" style="color: #409EFF;" />
        </div>
        <div v-else class="fle-left">
          <span>{{ "--" }}</span>
        </div>
      </el-descriptions-item>
    </el-descriptions>
    <!-- 图片弹框   -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="true"
      :title="titleImage"
      :visible.sync="dialogVisible"
      top="5vh"
      width="550px"
    >
      <div>
        <div v-if="imageList.length>0" style="width: 500px;">
          <div style="text-align: center; color: #409EFF;font-size: 18px; ">
            <span>{{ currentIndex + 1 }}</span>
            <span>/</span>
            <span>{{ imageList.length }}</span>
          </div>
          <el-carousel
            ref="carousel" height="750px" trigger="click"
            @change="handleCurrentChange"
          >
            <el-carousel-item v-for="item in imageList" :key="item">
              <div style="margin-left: 10px;">
                <el-image :src="item" fit="fill" style="width: 100%" />
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div v-else style="width: 500px;text-align: center;height: 750px;">
          <el-image style="width: 100%">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline" style="font-size: 30px;" />
            </div>
          </el-image>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { loadUnloadSumqueryPictureList } from '@/api/orderApi';

export default {
  name: 'PhotoSee',
  props: {
    dataobj: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      titleImage: this.$t('collectionCenter.loadingPicture'),
      imageList: [],
      currentIndex: 0,
      loadPictureList: [], // 装车图片
      loadSignList: [], // 转成前面
      unLoadPictureList: [], // 卸车图片
      unLoadSignList: [], // 卸车签名
      sealVehiclePictureList: [], // 封车图片
      unsealVehiclePictureList: [] // 解封车图片
    };
  },
  mounted() {
    console.log(this.dataobj);
    this.getseeImage();
  },
  methods: {
    handleCurrentChange(val) {
      this.currentIndex = val;
      console.log(val); // 当前的index值
    },
    getseeImage() {
      console.log(23);
      const imgForm = {
        tagNumber: this.dataobj.vehicleTagNumber,
        nextSiteId: this.dataobj.nextSiteId
      };
      loadUnloadSumqueryPictureList(imgForm).then(res => {
        if (res.status === 'OK') {
          this.loadPictureList = res.data.loadPictureList;
          this.unLoadPictureList = res.data.unLoadPictureList;
          this.loadSignList = res.data.loadSignList;
          this.unLoadSignList = res.data.unLoadSignList;
          this.sealVehiclePictureList = res.data.sealVehiclePictureList;
          this.unsealVehiclePictureList = res.data.unsealVehiclePictureList;
        }
      });
    },
    seeImage(type, title) {
      console.log(type);
      this.imageList = type;
      this.titleImage = title;
      this.dialogVisible = true;
    }
  }
};
</script>

<style scoped>
.fle-left {
  display: flex;
  align-items: center;
  justify-content: left;
  color: #409EFF;
  cursor: pointer;
}

>>> .el-dialog__body {
  padding-top: 15px;
}
</style>
