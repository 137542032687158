<template>
  <el-dialog
    :title="$t('basicData.numberOrInvoiceNumber')" :visible.sync="dialogVisible" label-position="top"
    @close="onClose"
  >
    <div>
      <el-form
        ref="ruleForm" :model="ruleForm" :rules="rules"
        label-width="100px"
      >
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="">
              <el-select v-model="ruleForm.orderType">
                <el-option :label="$t('basicData.InvoiceNumber')" :value="1" />
                <el-option :label="$t('receivablePayable.TicketNumber')" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- 业务类型 -->
            <el-form-item :label="$t('collectionCenter.businessT')">
              <el-select v-model="ruleForm.businessType" clearable :placeholder="$t('GlobalSetObj.pleaseChoose')">
                <!--揽收-->
                <el-option :label="$t('collectionCenter.collect')" value="COLLECT" />
                <!--派送-->
                <el-option :label="$t('collectionCenter.dispatch')" value="DELIVERY" />
                <el-option label="SFS" value="SFS" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label=" " prop="orderNumber">
          <el-input
            v-model="ruleForm.orderNumber" :placeholder="$t('orderCenterCont.MultipleLineBreaks')+','+$t('orderCenterCont.canOnlyExport10000')" type="textarea"
            :rows="12"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onClose">{{ $t('basicData.Cancel') }}</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">{{ $t('basicData.determine') }}</el-button>
      </span>
    </div>
  </el-dialog>
</template>

<script>
import {
  logisticsInvoiceExport
} from '@/api/finance/basicData';
import { goExportList } from '@/utils/goExportList.js';

export default {
  name: '',
  data() {
    return {
      dialogVisible: false, // 按单号导出弹窗
      ruleForm: {
        orderType: 1, // 订单类型 1:发票号码 2:业务单号
        businessType: 'COLLECT',
        orderNumber: '' // 订单号
      },

      rules: {
        orderType: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ],
        // businessType: [
        //   { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        // ],
        orderNumber: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'change' }
        ]
      }
    };
  },

  created() {

  },

  methods: {
    onOpen() {
      this.dialogVisible = true;
      this.ruleForm.orderType = 1;
      this.ruleForm.businessType = '';
      this.ruleForm.orderNumber = '';
    },

    onClose() {
      this.dialogVisible = false;
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          // alert('submit!');
          return false;
        }
        this.exportOrderType();
      });
    },

    setQuery() {
      const { orderNumber, orderType, businessType } = this.ruleForm;
      const params = {
        invoiceNumberList: [],
        businessNumberList: [],
        exportType: 2,
        businessType
      };

      // 字符串去除前后空格，分割成数组，过滤掉空行
      const cleanedArray = orderNumber
        .split('\n') // 将文本按换行符分割成数组
        .map(line => line.trim()) // 去除每行前后的空格
        .filter(line => line !== ''); // 过滤掉空行
      if (cleanedArray && cleanedArray.length > 10000) {
        this.$message.warning(this.$t('orderCenterCont.canOnlyExport10000'));
        return;
      }
      if (orderType === 1) {
        params.invoiceNumberList = cleanedArray;
        delete params.businessNumberList;
      } else {
        params.businessNumberList = cleanedArray;
        delete params.invoiceNumberList;
      }

      return params;
    },

    /**
     * @description 按业务单号或发票号导出
     */
    exportOrderType() {
      const params = this.setQuery();

      logisticsInvoiceExport(params).then(res => {
        if (res.status === 'OK') {
          this.dialogVisible = false;

          // 需要传递this
          goExportList(this);
        }
      }).catch(err => {
        console.error(err);
      });
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
