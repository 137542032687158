<template>
  <div class="app-container">
    <div style="margin-bottom: 10px;">
      <el-card class="box-card">
        <el-form ref="formQuery" label-position="top" size="small">
          <el-row :gutter="10">
            <el-col :span="6">
              <!--账号ID-->
              <el-form-item :label="$t('userManage.usernameId')" prop="accountCode">
                <el-input v-model="queryForm.params.accountCode" :placeholder="$t('GlobalSetObj.pleaseInput')" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <!--账号名称-->
              <el-form-item :label="$t('customerManagements.AccountName')" prop="acountName">
                <el-input v-model="queryForm.params.acountName" :placeholder="$t('GlobalSetObj.pleaseInput')" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <!--真实姓名-->
              <el-form-item :label="$t('customerManagements.realName')" prop="realName">
                <el-input v-model="queryForm.params.realName" :placeholder="$t('GlobalSetObj.pleaseInput')" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <!--服务商-->
              <el-form-item :label="$t('userManage.provider')" prop="orgName">
                <el-select
                  v-model="queryForm.params.orgName"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  filterable
                  style="width: 100%;"
                  @change="changeProvider"
                >
                  <el-option
                    v-for="item in providerList"
                    :key="item.orgId"
                    :label="item.orgName"
                    :value="item.orgName"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <!--网点名称-->
              <el-form-item :label="$t('navbar.siteName')" prop="orgName">
                <el-select
                  v-model="queryForm.params.siteId"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  filterable
                  style="width: 100%;"
                >
                  <el-option
                    v-for="item in siteList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <!--账号角色-->
              <el-form-item :label="$t('GlobalSetObj.AccountRole')" prop="roleName">
                <el-select
                  v-model="queryForm.params.roleName"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  filterable
                  style="width: 100%;"
                >
                  <el-option
                    v-for="(item, ind) in vendorRoleList1"
                    :key="ind"
                    :label="item.roleName + '(' + item.portugal + ')'"
                    :value="item.roleName"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <!--状态-->
              <el-form-item :label="$t('GlobalSetObj.status')" prop="accountState">
                <el-select
                  v-model="queryForm.params.accountState"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  filterable
                  style="width: 100%;"
                >
                  <el-option key="0" :label="$t('GlobalSetObj.all')" :value="null" />
                  <el-option key="1" :label="$t('GlobalSetObj.enable')" :value="1" />
                  <el-option key="2" :label="$t('GlobalSetObj.stop')" :value="0" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-button
                icon="el-icon-search" size="small" type="primary"
                @click="searchTable()"
              >
                {{ $t('GlobalSetObj.search') }}
              </el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </div>
    <div>
      <el-card>
        <div style="margin-bottom: 10px;">
          <!--启用-->
          <el-button
            v-permit:remove="'btn:supplierManagement:vendorAccountNumber:enable'"
            :disabled="isEnable"
            size="small"
            type="primary"
            @click="updateUserName(1)"
          >{{ $t('GlobalSetObj.enable') }}
          </el-button>
          <!--停用-->
          <el-button
            v-permit:remove="'btn:supplierManagement:vendorAccountNumber:stop'"
            :disabled="isEnable"
            size="small"
            type="primary"
            @click="updateUserName(0)"
          >{{ $t('GlobalSetObj.stop') }}
          </el-button>
          <!--添加-->
          <el-button
            v-permit:remove="'btn:supplierManagement:vendorAccountNumber:add'"
            size="small"
            style="float: right;"
            type="primary"
            @click="addUserName(1)"
          >{{ $t('GlobalSetObj.add') }}
          </el-button>
        </div>
        <el-table
          :data="tableData"
          border
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            align="center"
            type="selection"
            width="55"
          />
          <el-table-column :label="$t('operationCenter.ind')" align="center" width="60px">
            <template slot-scope="scope">
              <span>{{ total - ((queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index) }}</span>
            </template>
          </el-table-column>
          <!--账号ID-->
          <el-table-column
            :label="$t('userManage.usernameId')"
            :show-overflow-tooltip="true"
            align="left"
            min-width="120"
            prop="code"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.code }}</div>
            </template>
          </el-table-column>
          <!--账号名称-->
          <el-table-column
            :label="$t('customerManagements.AccountName')"
            :show-overflow-tooltip="true"
            align="center"
            min-width="160"
            prop="username"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.username }}</div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('customerManagements.realName')" min-width="160" prop="realName" />
          <el-table-column :label="$t('navbar.promisseType')" min-width="160" prop="userTypeName" />
          <el-table-column :label="$t('GlobalSetObj.Estado')" min-width="160" prop="stateName" />
          <!--服务商-->
          <el-table-column
            :label="$t('userManage.provider')"
            :show-overflow-tooltip="true"
            align="left"
            min-width="160"
            prop="orgName"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.orgName }}</div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('navbar.siteName')" min-width="160" prop="siteName" />
          <!--账号角色-->
          <el-table-column
            :label="$t('GlobalSetObj.AccountRole')"
            :show-overflow-tooltip="false"
            align="center"
            min-width="140"
            prop="roleName"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.roleName }}</div>
            </template>
          </el-table-column>
          <!--状态-->
          <el-table-column
            :label="$t('GlobalSetObj.status')"
            :show-overflow-tooltip="false"
            align="center"
            min-width="120"
            prop="userState"
          >
            <template slot-scope="scope">
              <!--启用-->
              <el-tag v-if="scope.row.accountState === 1" type="success">
                {{ $t('GlobalSetObj.enable') }}
              </el-tag>
              <!--禁用-->
              <el-tag v-if="scope.row.accountState === 0" type="danger">{{ $t('GlobalSetObj.stop') }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column :label="$t('GlobalSetObj.settlementType')" min-width="160" prop="settlementType" />
          <el-table-column label="CPF" min-width="120" prop="cpf" />
          <!--创建人-->
          <el-table-column
            :label="$t('GlobalSetObj.CreatePeople')"
            :show-overflow-tooltip="false"
            align="center"
            min-width="160"
            prop="createUserName"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.createUserName }}</span>
            </template>
          </el-table-column>
          <!--创建时间-->
          <el-table-column
            :label="$t('GlobalSetObj.CreationTime')"
            :show-overflow-tooltip="false"
            align="center"
            min-width="160"
            prop="createTime"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.createTime }}</span>
            </template>
          </el-table-column>
          <!--操作-->
          <el-table-column
            :label="$t('GlobalSetObj.operate')"
            align="center"
            fixed="right"
            width="120"
          >
            <template slot-scope="scope">
              <div>
                <!--重置密码-->
                <el-tooltip
                  :content="$t('userManage.resetPassword')" class="item" effect="dark"
                  placement="top"
                >
                  <el-popconfirm
                    :title="$t('GlobalSetObj.AreYouSureResetPassword')"
                    @confirm="resetPassword(scope.row)"
                  >
                    <el-button
                      slot="reference"
                      size="mini"
                      type="text"
                    ><i class="iconfont icon-ResetPassword hoverClass" style="font-size: 25px;color: #666;" />
                    </el-button>
                  </el-popconfirm>
                </el-tooltip>
                <!--    编辑        -->
                <el-tooltip
                  :content="$t('GlobalSetObj.edit')" class="item" effect="dark"
                  placement="top"
                >
                  <el-button
                    v-permit:remove="'btn:supplierManagement:vendorAccountNumber:add'"
                    size="mini"
                    style="margin-left: 10px;color: #666"
                    type="text"
                    @click="addUserName(2, scope.row)"
                  ><i class="el-icon-edit-outline hoverClass" style="font-size: 25px;" />
                  </el-button>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页工具 -->
        <div class="pagination">
          <el-pagination
            :current-page.sync="queryForm.pageNumber"
            :page-size.sync="queryForm.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            style="margin: 10px 0"
            @current-change="getCurrentTableData"
            @size-change="getSizeChangeTableData"
          />
        </div>
      </el-card>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="userForm.title"
      :visible.sync="userForm.visible"
      width="700px"
      @close="closeUserNameForm"
    >
      <el-form
        :key="userForm.visible"
        ref="serFormRef"
        :model="userForm"
        :rules="userRules"
        label-position="top"
      >
        <el-row :gutter="20">
          <!-- 账号名称 -->
          <el-col :span="12">
            <el-form-item :label="$t('customerManagements.AccountName')" prop="username">
              <el-input
                v-model.trim="userForm.username"
                :disabled="editType === 2"
                :placeholder="$t('GlobalSetObj.pleaseInput')"
                autocomplete="off"
                maxlength="50"
                show-word-limit
              />
            </el-form-item>
          </el-col>
          <!-- 账号名称 -->
          <el-col :span="12">
            <el-form-item :label="$t('customerManagements.realName')" prop="realName">
              <el-input
                v-model.trim="userForm.realName"
                :placeholder="$t('GlobalSetObj.pleaseInput')"
                autocomplete="off"
                maxlength="50"
                show-word-limit
              />
            </el-form-item>
          </el-col>
          <!-- 权限类型 -->
          <el-col :span="24">
            <el-form-item :label="$t('navbar.promisseType')" prop="userType">
              <el-radio-group v-model="userForm.userType" @change="handleUserType">
                <el-radio :label="1">{{ $t('navbar.anjunAccount') }}</el-radio>
                <el-radio :label="4">{{ $t('navbar.stateAccount') }}</el-radio>
                <el-radio :label="2">{{ $t('navbar.providerAccount') }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <!-- 服务商 -->
          <el-col :span="12">
            <el-form-item :label="$t('userManage.provider')" prop="orgId">
              <el-select
                v-model="userForm.orgId"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                filterable
                style="width: 100%;"
                @change="handleProviderName"
              >
                <el-option
                  v-for="item in providerList"
                  :key="item.orgId"
                  :label="item.orgName"
                  :value="item.orgId"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <!-- 操作网点 -->
          <el-col :span="12">
            <el-form-item :label="$t('navbar.opretaSite')" prop="siteId">
              <el-select
                v-model="userForm.siteId"
                :placeholder="$t('operationCenter.PleaseSelect')"
                clearable
                filterable
                style="width: 100%;"
                @change="handleSiteName"
              >
                <el-option
                  v-for="item in siteList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <!-- 账号角色 -->
          <el-col :span="12">
            <el-form-item
              :label="$t('GlobalSetObj.AccountRole')"
              prop="roleId"
            >
              <el-select
                v-model="userForm.roleId"
                :placeholder="$t('operationCenter.PleaseSelect')"
                clearable
                filterable
                style="width: 100%;"
              >
                <el-option
                  v-for="(item, ind) in vendorRoleList"
                  :key="ind"
                  :label="item.roleName"
                  :value="item.roleId"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <!--  结算类型      -->
          <el-col v-if="TypeOfSettlement" :span="12">
            <el-form-item :label="$t('GlobalSetObj.settlementType')" prop="settlementType">
              <el-select
                v-model="userForm.settlementType"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                style="width: 100%"
              >
                <el-option :key="1" label="RPA" value="RPA" />
                <el-option :key="2" label="CNPJ" value="CNPJ" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="CPF" prop="cpf">
              <el-input v-model.trim="userForm.cpf" :placeholder="$t('GlobalSetObj.pleaseInput')" clearable />
            </el-form-item>
          </el-col>
          <!-- 所属州 -->
          <el-col v-if="isShowState" :span="12">
            <el-form-item :label="$t('operationTips.belongState')" prop="state">
              <el-select
                v-model="userForm.state"
                :placeholder="$t('operationCenter.PleaseSelect')"
                clearable
                filterable
                multiple
                style="width: 100%;"
              >
                <el-option
                  v-for="item in StateList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.label"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <!-- 账号密码 -->
          <el-col v-if="editType === 1" :span="12">
            <el-form-item :label="$t('navbar.accountPosword')" prop="password">
              <el-input
                v-model.trim="userForm.password"
                :placeholder="$t('GlobalSetObj.pleaseInput')"
                autocomplete="off"
                clearable
              />
            </el-form-item>
          </el-col>
          <!-- 确认密码 -->
          <el-col v-if="editType === 1" :span="12">
            <el-form-item :label="$t('customerManagements.checkPwd')" prop="confirmPassword">
              <el-input
                v-model.trim="userForm.confirmPassword"
                :placeholder="$t('GlobalSetObj.pleaseInput')"
                autocomplete="off"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="onSubmit">{{ $t('roleManage.confirm') }}</el-button><!-- 确认 -->
        <el-button size="small" @click="userForm.visible = false">{{ $t('roleManage.cancel') }}</el-button><!-- 取消 -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  apiPmUserEdit,
  apiResetPwd,
  getPmRolePage,
  getSiteInfo,
  pmUserAddApi,
  pmUserPageApi,
  updateStateApi
} from '@/api/role';
import { getUserName } from '@/utils/asyncTools';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { getLgAreaListData } from '@/api/orderApi';
import { checkCPF, checkPassword } from '@/utils/validate';
import md5 from 'js-md5';

export default {
  name: 'VendorAccountNumber',
  data() {
    return {
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          acountName: '',
          accountState: null,
          accountCode: '',
          roleName: '',
          orgName: '',
          siteId: null,
          realName: ''
        }
      },
      providerList: [],
      tableData: [],
      total: 0,
      userForm: {
        title: this.$t('GlobalSetObj.add'),
        visible: false,
        username: '',
        orgId: '',
        orgName: '',
        roleId: '',
        roleName: '',
        realName: '',
        state: [],
        userType: 1,
        siteId: '',
        siteName: '',
        password: '',
        confirmPassword: '',
        settlementType: '',
        cpf: ''
      },
      userRules: {
        username: {
          required: true, message: this.$t('customerManagements.accountNameNotEmpty'), trigger: ['blur', 'change']
        },
        realName: {
          required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: ['blur', 'change']
        },
        orgId: {
          required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: ['blur', 'change']
        },
        roleId: {
          required: true, message: this.$t('GlobalSetObj.TheAccountRoleCannotEmpty'), trigger: ['blur', 'change']
        },
        siteId: {
          required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: ['blur', 'change']
        },
        password: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: ['blur', 'change'] },
          { validator: checkPassword, trigger: ['blur'] }
        ],
        settlementType: [{ required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: ['blur', 'change'] }],
        cpf: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: ['blur', 'change'] },
          {
            validator: checkCPF,
            trigger: ['blur', 'change']
          }
        ],
        confirmPassword: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: ['blur', 'change'] },
          { validator: checkPassword, trigger: ['blur'] }
        ],
        state: {
          required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: ['blur', 'change']
        }
      },
      vendorRoleList: [],
      userIdAndNameObj: null,
      selectedList: [],
      isEnable: true,
      editType: 1,
      StateList: [],
      isShowState: false,
      siteList: [],
      siteListAll: [],
      vendorRoleList1: [],
      TypeOfSettlement: false
    };
  },
  watch: {
    'userForm.roleId'(val) {
      this.vendorRoleList.map(item => {
        if (val === item.id) {
          this.userForm.roleName = item.roleName;
        }
      });
    }
  },
  created() {
    this.getAddress();
    this.userIdAndNameObj = getUserIdAndNameObj();
    this.getTableDataList();
    this.getPmRoleList();
    this.handleUserType(null);
  },
  methods: {
    // 州
    getAddress() {
      const params = {
        level: 0,
        parentId: ''
      };
      getLgAreaListData(params, false).then((res) => {
        if (res.status === 'OK') {
          this.StateList = res.data.map(item => {
            return {
              id: item.id,
              label: `${item.nameEn}`
            };
          });
        }
      });
    },
    // 重置密码
    resetPassword(row) {
      const param = {
        id: row.id
      };
      apiResetPwd(param).then(res => {
        if (res.status === 'OK') {
          this.$message.success(this.$t('GlobalSetObj.ResetPasswordSuccessfully'));
          this.searchTable();
        }
      });
    },
    // 启用、禁用
    updateUserName(type) {
      const ids = [];
      this.selectedList.map(item => {
        ids.push(item.id);
      });
      const param = {
        ids: ids,
        state: type
      };
      updateStateApi(param).then(res => {
        if (res.status === 'OK') {
          this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
          this.searchTable();
        }
      });
    },
    // 列表勾选
    handleSelectionChange(arr) {
      this.selectedList = arr;
      const a = [];
      this.selectedList.map(item => {
        if (a.indexOf(item.accountState) === -1) {
          a.push(item.accountState);
        }
      });
      this.isEnable = this.selectedList.length === 0 || a.length > 1;
    },
    // 获取供应商角色
    getPmRoleList() {
      const param = {
        pageSize: 1000000,
        pageNumber: 1,
        params: {}
      };
      getPmRolePage(param).then((response) => {
        if (response.status === 'OK') {
          const { records } = response.data;
          this.vendorRoleList1 = records;
        }
      });
    },
    // 关闭弹窗清除
    closeUserNameForm() {
      this.$refs.serFormRef.resetFields();
      this.userForm = {
        title: this.$t('GlobalSetObj.add'),
        visible: false,
        username: '',
        orgId: '',
        orgName: '',
        roleId: '',
        roleName: '',
        realName: '',
        state: [],
        userType: 1,
        siteId: '',
        siteName: '',
        password: '',
        confirmPassword: '',
        settlementType: '',
        cpf: ''
      };
      this.handleUserType(null);
    },
    handleUserType(val) {
      this.TypeOfSettlement = false;
      this.isShowState = val === 4;
      this.userForm.state = [];
      this.userForm.orgId = '';
      this.userForm.siteId = '';
      this.userForm.roleId = '';
      this.userForm.settlementType = '';
      const data = {
        'userType': val, // 1为安骏账号,2为供应商账号,4州级账号
        'orgId': null
      };
      getSiteInfo(data).then(res => {
        console.log(res, 'resresres');
        if (res.status === 'OK') {
          this.providerList = res.data.orgList;
          this.siteList = res.data.siteList;
          this.siteListAll = res.data.siteList;
          this.vendorRoleList = res.data.roleList;
          console.log(this.vendorRoleList, 'this.vendorRoleList');
        }
      });
    },
    // 添加\编辑账户
    async addUserName(type, row) {
      console.log(row, 'LKLKLKLKJLK');
      this.editType = type;
      if (type === 1) {
        this.TypeOfSettlement = false;
        this.userForm = {
          title: this.$t('GlobalSetObj.add'),
          visible: true,
          username: '',
          orgId: '',
          orgName: '',
          roleId: '',
          roleName: '',
          realName: '',
          state: [],
          userType: 1,
          siteId: '',
          siteName: '',
          password: '',
          confirmPassword: '',
          settlementType: '',
          cpf: ''
        };
        this.isShowState = false;
        console.log(this.userForm, ' this.userForm');
        const data = {
          'userType': 1, // 1为安骏账号,2为供应商账号,4州级账号
          'orgId': null
        };
        getSiteInfo(data).then(res => {
          console.log(res, 'resresres');
          if (res.status === 'OK') {
            this.providerList = res.data.orgList;
            this.siteList = res.data.siteList;
            this.vendorRoleList = res.data.roleList;
          }
        });
      } else {
        if (Array.isArray(row.state)) {
          console.log(1);
        } else if (typeof row.state === 'string') {
          row.state = row.state.split(','); // 将字符串分割成数组
        } else {
          row.state = [];
        }
        // const find = this.vendorRoleList.find(item => item.id === row.accountRole);
        // if (!find) {
        //   row.accountRole = null;
        // }
        if (row.settlementType) {
          this.TypeOfSettlement = true;
        } else {
          this.TypeOfSettlement = false;
        }
        const data01 = {
          'userType': row.userType, // 1为安骏账号,2为供应商账号,4州级账号
          'orgId': null
        };
        const data02 = {
          'userType': row.userType, // 1为安骏账号,2为供应商账号,4州级账号
          'orgId': row.orgId
        };
        const res01 = await getSiteInfo(data01);
        if (res01.status === 'OK') {
          this.providerList = res01.data.orgList;
          this.siteList = res01.data.siteList;
          this.vendorRoleList = res01.data.roleList;
          getSiteInfo(data02).then(res => {
            console.log(res, 'resresres');
            if (res.status === 'OK') {
              // this.providerList = res.data.orgList;
              this.siteList = res.data.siteList;
              this.vendorRoleList = res.data.roleList;
              this.userForm = {
                title: this.$t('GlobalSetObj.edit'),
                visible: true,
                username: row.username,
                orgId: row.orgId,
                orgName: row.orgName,
                roleId: row.accountRole,
                roleName: row.roleName,
                realName: row.realName,
                state: row.state,
                siteName: row.siteName,
                ...row
              };
              this.isShowState = row.userType === 4;
            }
          });
        }
      }
    },
    // 查询
    searchTable() {
      this.queryForm.pageNumber = 1;
      this.getTableDataList();
    },
    // 获取供应商账号分页列表
    getTableDataList() {
      pmUserPageApi(this.queryForm).then(res => {
        if (res.status === 'OK') {
          this.isEnable = true;
          this.selectedList = [];
          res.data.records.map(item => {
            item.createUserName = getUserName(item.createUser, this.userIdAndNameObj);
          });
          this.tableData = res.data.records;
          this.tableData.map(item => {
            item.stateName = item.state || '--';
            if (item.userType === 1) {
              item.userTypeName = this.$t('navbar.anjunAccount');
            } else if (item.userType === 4) {
              item.userTypeName = this.$t('navbar.stateAccount');
            } else {
              item.userTypeName = this.$t('navbar.providerAccount');
            }
          });
          this.total = res.data.total;
        }
      });
    },
    // 页码切换翻页
    getCurrentTableData(val) {
      this.queryForm.pageNumber = val;
      this.getTableDataList();
    },
    // 每页显示数量切换
    getSizeChangeTableData(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.getTableDataList();
    },
    // 切换选择供应商
    handleProviderName(val) {
      this.userForm.state = [];
      this.userForm.siteId = '';
      this.userForm.roleId = '';
      if (val) {
        console.log(val, ';val');
        const obj = this.providerList.find(item => item.orgId === val);
        if (obj.orgId === 193470482) {
          this.TypeOfSettlement = true;
          this.userForm.settlementType = '';
        } else {
          this.TypeOfSettlement = false;
          this.userForm.settlementType = '';
        }
        this.userForm.orgName = obj.orgName;
        const data = {
          'userType': this.userForm.userType, // 1为安骏账号,2为供应商账号,4州级账号
          'orgId': val
        };
        getSiteInfo(data).then(res => {
          console.log(res, 'resresres');
          if (res.status === 'OK') {
            // this.providerList = res.data.orgList;
            this.siteList = res.data.siteList;
            this.vendorRoleList = res.data.roleList;
          }
        });
      } else {
        this.TypeOfSettlement = false;
      }
    },
    changeProvider(val) {
      this.queryForm.params.siteId = '';
      if (val) {
        const find = this.providerList.find(item => item.orgName === val);
        if (find.orgName === 'anjun SRM system') {
          this.siteList = this.siteListAll;
        } else {
          const data = {
            'userType': null, // 1为安骏账号,2为供应商账号,4州级账号
            'orgId': find.orgId
          };
          getSiteInfo(data).then(res => {
            console.log(res, 'resresres');
            if (res.status === 'OK') {
              // this.providerList = res.data.orgList;
              this.siteList = res.data.siteList;
              this.vendorRoleList = res.data.roleList;
            }
          });
        }
        console.log(find, 'findfindfind');
      } else {
        this.handleUserType(null);
      }
    },
    handleSiteName(val) {
      this.userForm.roleId = '';
      if (val) {
        const find = this.siteList.find(item => item.id === val);
        this.userForm.siteName = find.name;
        console.log(find, 'findfindfind');
      }
    },
    // 保存提交
    onSubmit() {
      this.$refs.serFormRef.validate((valid) => {
        if (!valid) {
          return;
        }
        if (this.editType === 1) {
          const param = {
            username: this.userForm.username,
            orgId: this.userForm.orgId,
            orgName: this.userForm.orgName,
            roleId: this.userForm.roleId,
            roleName: this.userForm.roleName,
            realName: this.userForm.realName,
            state: this.userForm.state.join(','),
            userType: this.userForm.userType,
            siteId: this.userForm.siteId,
            password: md5(md5(this.userForm.password)),
            confirmPassword: md5(md5(this.userForm.confirmPassword)),
            siteName: this.userForm.siteName,
            settlementType: this.userForm.settlementType,
            cpf: this.userForm.cpf
          };
          pmUserAddApi(param).then(res => {
            if (res.status === 'OK') {
              this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
              this.userForm.visible = false;
              this.searchTable();
            }
          });
        } else {
          const param = {
            state: this.userForm.state.join(','),
            username: this.userForm.username,
            realName: this.userForm.realName,
            roleName: this.userForm.roleName,
            accountRole: this.userForm.roleId,
            orgName: this.userForm.orgName,
            orgId: this.userForm.orgId,
            id: this.userForm.id,
            accountState: this.userForm.accountState,
            phone: this.userForm.phone,
            siteId: this.userForm.siteId,
            userState: this.userForm.userState,
            userType: this.userForm.userType,
            siteName: this.userForm.siteName,
            settlementType: this.userForm.settlementType,
            cpf: this.userForm.cpf
          };
          apiPmUserEdit(param).then(res => {
            if (res.status === 'OK') {
              this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
              this.userForm.visible = false;
              this.searchTable();
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.hoverClass:hover {
  color: #4BA4FF !important;
}

.app-container >>> .el-dialog__body {
  padding: 0 20px;
}
</style>
