var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WrapperHandleError",
    { attrs: { error: _vm.error, "is-error": _vm.isError } },
    [
      _c("SectionHeader", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("h1", { staticClass: "header-1" }, [
                  _vm._v(_vm._s(_vm.$t("company.company"))),
                ]),
                _c(
                  "o-button",
                  {
                    attrs: { variant: "primary", size: "md" },
                    on: { click: _vm.handleVisibilityModalCreate },
                  },
                  [_vm._v(_vm._s(_vm.$t("company.createCompany")))]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "filter",
            fn: function () {
              return [
                _c(
                  "o-field",
                  {
                    staticClass: "flex-1",
                    attrs: {
                      label: _vm.$t("company.name"),
                      "label-for": "name",
                    },
                  },
                  [
                    _c("o-input", {
                      attrs: {
                        id: "name",
                        placeholder: _vm.$t("company.namePlaceholder"),
                      },
                      model: {
                        value: _vm.filters.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "name", $$v)
                        },
                        expression: "filters.name",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "o-field",
                  {
                    staticClass: "flex-1",
                    attrs: {
                      label: _vm.$t("company.document"),
                      "label-for": "document",
                    },
                  },
                  [
                    _c("o-input", {
                      attrs: {
                        id: "document",
                        placeholder: _vm.$t("company.documentPlaceholder"),
                      },
                      model: {
                        value: _vm.filters.document,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "document", $$v)
                        },
                        expression: "filters.document",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "SkeletonTable",
        { attrs: { "is-loading": _vm.isLoadingTable } },
        [
          _c(
            "o-table",
            {
              staticClass: "space-m-y-32",
              attrs: {
                data: _vm.tableData,
                "row-key": "id",
                bordered: "",
                paginated: "",
                "backend-pagination": "",
                total: _vm.tableTotal,
                "per-page": _vm.queryParams.perPage,
                "current-page": _vm.queryParams.currentPage,
                "pagination-simple": false,
              },
              on: {
                "update:currentPage": function ($event) {
                  return _vm.$set(_vm.queryParams, "currentPage", $event)
                },
                "update:current-page": function ($event) {
                  return _vm.$set(_vm.queryParams, "currentPage", $event)
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "empty",
                  fn: function () {
                    return [
                      _vm._v(" " + _vm._s(_vm.$t("company.noResults")) + " "),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("o-table-column", {
                attrs: {
                  field: "shipping_company_name",
                  label: _vm.$t("company.base"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(
                          " " + _vm._s(props.row.shipping_company_name) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "shipping_company_documentation",
                  label: _vm.$t("company.document"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(props.row.shipping_company_documentation) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "shipping_company_address",
                  label: _vm.$t("company.address"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(" " + _vm._s(_vm.getAddress(props.row)) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: { label: "", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("Actions", { attrs: { row: row } })]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "o-modal",
        {
          attrs: { active: _vm.isOpenModalCreate, width: "560" },
          on: { close: _vm.handleVisibilityModalCreate },
        },
        [
          _c("div", [
            _c("h2", { staticClass: "header-2" }, [
              _vm._v(_vm._s(_vm.$t("company.createCompanyTitle"))),
            ]),
            _c("p", { staticClass: "paragraph" }, [
              _vm._v(_vm._s(_vm.$t("company.createCompanyParagraph"))),
            ]),
          ]),
          _c("FormCreateCompany", {
            attrs: { "close-modal": _vm.handleVisibilityModalCreate },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }