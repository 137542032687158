<script >
import { ref, defineProps } from '@vue/composition-api';
import { default as FormCompany } from './steps/formCompany';
import { default as FormAddress } from '@/components/steps/formAddress';
import { useWizardForm } from '@/composable/useWizardForm';
import { useEditCompany } from '@/api/transfer/company/query';
const __sfc_main = {};
__sfc_main.props = {
  closeModal: {
    type: Function
  },
  companyData: {
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const STEPS_TOTAL = 2;
  const formData = ref({
    formStep01: {
      name: props.companyData.shipping_company_name,
      tradeName: props.companyData.shipping_company_trade_name,
      documentation: props.companyData.shipping_company_documentation,
      stateRegistration: props.companyData.shipping_company_state_registration,
      comercialPhone: props.companyData.shipping_company_commercial_phone_number,
      contact: props.companyData.shipping_company_contact_name,
      cellphone: props.companyData.shipping_company_cellphone,
      email: props.companyData.shipping_company_mail,
      contractType: !!props.companyData.shipping_company_contract_type
    },
    formStep02: {
      zipcode: props.companyData.shipping_company_zipcode,
      street: props.companyData.shipping_company_address,
      number: props.companyData.shipping_company_address_number,
      neighborhood: props.companyData.shipping_company_address_neighborhood,
      municipality: props.companyData.shipping_company_address_municipality,
      state: props.companyData.shipping_company_state,
      complement: props.companyData.shipping_company_address_complement
    }
  });
  const {
    mutate,
    isLoading
  } = useEditCompany();
  function handleSubmit() {
    const newBase = {
      ...formData.value.formStep01,
      ...formData.value.formStep02
    };
    mutate({
      newBase,
      id: props.companyData.id
    }, {
      onSuccess() {
        props.closeModal();
      }
    });
  }
  const {
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  } = useWizardForm(formData, handleSubmit, STEPS_TOTAL);
  return {
    formData,
    isLoading,
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  };
};
__sfc_main.components = Object.assign({
  FormCompany,
  FormAddress
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <FormCompany
      v-show="step === 0"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      :form-data.sync="formData.formStep01"
    />

    <FormAddress
      v-show="step === 1"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      :form-data="formData.formStep02"
      :is-edit="true"
    />

    <o-loading :full-page="true" :active.sync="isLoading" />
  </div>
</template>
