<template>
  <div>
    <el-button
      v-permit:remove="permission"
      type="danger" size="small"
      style="margin-right: 10px;" @click="removeFeetype">{{ $t('receivablePayable.Delete') }}</el-button>

    <el-dialog
      :title="$t('receivablePayable.DeleteTheFee')"
      :visible.sync="isBool"
      width="500px"
      :loading="loading"
    >
      <div>
        <!--注意：会删除所选费用类型的所有数据-->
        <p style="color: red;margin-bottom: 20px;">{{
          $t('receivablePayable.NoteAllDataSelectedChargeDeleted')
        }}
        </p>

        <el-form
          ref="ruleForm"
          :model="ruleForm" :rules="rules"
          label-position="top" size="small"
          class="demo-ruleForm">
          <el-form-item :label="$t('basicData.ExpenseType')" prop="feeTypeCode" style="width: 100%;">
            <FeeCodeCmp
              :val="ruleForm.feeTypeCode"
              :business-type="payableType"
              @change="onChange"
            />
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="isBool = false">{{ $t('GlobalSetObj.cancel') }}</el-button>
        <el-button size="small" type="primary" @click="submitForm">{{ $t('GlobalSetObj.determine') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import FeeCodeCmp from './FeeCodeCmp.vue';

export default {
  name: '',

  components: {
    FeeCodeCmp
  },

  props: {
    // 费用类型
    payableType: {
      type: [String, Number],
      required: true
    },
    // 提交函数
    asyncSumbit: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      ruleForm: {
        feeTypeCode: ''
      },
      isBool: false,
      loading: false,
      rules: {
        feeTypeCode: [
          { required: true, message: this.$t('receivablePayable.TheChargeTypeCannotBeEmpty'), trigger: ['blur', 'change'] }
        ]
      },
      feeTypeOption: [],
      permission: '' // 权限
    };
  },

  activated() {
    this.getPermission();
  },
  created() {
    this.getPermission();
  },

  methods: {
    onChange(val) {
      this.ruleForm.feeTypeCode = val;
    },
    // 删除
    removeFeetype() {
      this.isBool = true;

      this.$nextTick(() => {
        this.$refs.ruleForm?.resetFields();
        this.$refs.ruleForm?.clearValidate();
      });
    },
    // 提交
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$confirm(this.$i18n.t('receivablePayable.ConfirmDeletionAllDataForSelectedExpenseType'), this.$i18n.t('basicData.tips'), { // '确认删除所选费用类型的所有数据？' 提示
            confirmButtonText: this.$i18n.t('basicData.determine'), // '确定',
            cancelButtonText: this.$i18n.t('basicData.Cancel'), // '取消',
            type: 'warning'
          }).then(() => {
            this.loading = true;
            return this.asyncSumbit(this.ruleForm.feeTypeCode);
          }).then(res => {
            this.isBool = false;
          }).catch(err => {
            console.error(err);
          }).finally(() => {
            this.loading = false;
          });
        }
      });
    },

    getPermission() {
      switch (this.payableType) {
        case 1: // 安俊
          this.permission = 'btn:finance:payableManagement:hut:del';
          break;
        case 2: // 第三方
          this.permission = 'btn:finance:payableManagement:threeCollect:del';
          break;
        case 3: // AJD
          this.permission = 'btn:finance:payableManagement:ajd:del';
          break;
        case 4: // DSP
          this.permission = 'btn:finance:payableManagement:dsp:del';
          break;
        default: // 运输干支线
          this.permission = 'btn:finance:payableManagement:transportation:del';
          break;
      }
    }
  }
};
</script>
