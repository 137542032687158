var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DropdownTable",
        { attrs: { "aria-role": "list" } },
        [
          _c(
            "o-dropdown-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !!_vm.status,
                  expression: "!!status",
                },
              ],
              attrs: { "aria-role": "listitem" },
              on: { click: _vm.handleVisibilityModalEdit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("vehicle.edit")) + " ")]
          ),
          _c(
            "o-dropdown-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !!_vm.status,
                  expression: "!!status",
                },
              ],
              attrs: { "aria-role": "listitem" },
              on: { click: _vm.handleVisibilityModalDelete },
            },
            [_vm._v(" " + _vm._s(_vm.$t("vehicle.delete")) + " ")]
          ),
          _c(
            "o-dropdown-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !!_vm.status,
                  expression: "!!status",
                },
              ],
              attrs: { "aria-role": "listitem" },
              on: { click: _vm.handlePrintQRcode },
            },
            [_vm._v(" " + _vm._s(_vm.$t("vehicle.qrCode")) + " ")]
          ),
          _c(
            "o-dropdown-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !!!_vm.status,
                  expression: "!(!!status)",
                },
              ],
              attrs: { "aria-role": "listitem" },
              on: { click: _vm.handleVisibilityModalRestore },
            },
            [_vm._v(" " + _vm._s(_vm.$t("vehicle.restore")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "o-modal",
        {
          attrs: { active: _vm.isOpenModalEdit, width: "560" },
          on: { close: _vm.handleVisibilityModalEdit },
        },
        [
          _c("div", [
            _c("h2", { staticClass: "header-2" }, [
              _vm._v(_vm._s(_vm.$t("vehicle.editTitle"))),
            ]),
            _c("p", { staticClass: "paragraph" }, [
              _vm._v(_vm._s(_vm.$t("vehicle.editParagraph"))),
            ]),
          ]),
          _c("FormEditVehicle", {
            attrs: {
              status: _vm.status,
              vehicle: _vm.row,
              "close-modal": _vm.handleVisibilityModalEdit,
            },
          }),
        ],
        1
      ),
      _c("Alert", {
        attrs: { active: _vm.isOpenModalDelete },
        on: { handleClose: _vm.handleVisibilityModalDelete },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.$t("vehicle.deleteTitle")) + " ")]
            },
            proxy: true,
          },
          {
            key: "message",
            fn: function () {
              return [
                _vm._v(" " + _vm._s(_vm.$t("vehicle.deleteParagraph")) + " "),
              ]
            },
            proxy: true,
          },
          {
            key: "buttons",
            fn: function () {
              return [
                _c(
                  "o-button",
                  {
                    attrs: { variant: "outline", size: "md" },
                    on: { click: _vm.handleVisibilityModalDelete },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("vehicle.buttonCancel")) + " ")]
                ),
                _c(
                  "o-button",
                  {
                    attrs: { variant: "primary", size: "md" },
                    on: { click: _vm.handleDelete },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("vehicle.buttonDelete")) + " ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Alert", {
        attrs: { active: _vm.isOpenModalRestore },
        on: { handleClose: _vm.handleVisibilityModalRestore },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _vm._v(" " + _vm._s(_vm.$t("vehicle.restoreTitle")) + " "),
              ]
            },
            proxy: true,
          },
          {
            key: "message",
            fn: function () {
              return [
                _vm._v(" " + _vm._s(_vm.$t("vehicle.retoreParagraph")) + " "),
              ]
            },
            proxy: true,
          },
          {
            key: "buttons",
            fn: function () {
              return [
                _c(
                  "o-button",
                  {
                    attrs: { variant: "outline", size: "md" },
                    on: { click: _vm.handleVisibilityModalRestore },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("vehicle.buttonCancel")) + " ")]
                ),
                _c(
                  "o-button",
                  {
                    attrs: { variant: "primary", size: "md" },
                    on: { click: _vm.handleRestore },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("vehicle.buttonRestore")) + " ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("o-loading", {
        attrs: { "full-page": true, active: _vm.isLoadingRestore },
        on: {
          "update:active": function ($event) {
            _vm.isLoadingRestore = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }