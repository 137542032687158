var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticStyle: { "font-size": "20px" } }, [
      _c(
        "div",
        [
          _c("el-image", {
            staticStyle: { height: "50px" },
            attrs: { src: _vm.logo1 },
          }),
        ],
        1
      ),
      _c("div", [
        _c("p", { staticStyle: { margin: "15px 0 5px 0" } }, [
          _vm._v(
            "Rota：" +
              _vm._s(
                _vm.paramData[0].routeNumber
                  ? _vm.paramData[0].routeNumber
                  : "--"
              )
          ),
        ]),
        _c("p", { staticStyle: { margin: "15px 0 5px 0" } }, [
          _vm._v(
            "Data de impressão：" +
              _vm._s(
                _vm.paramData[0].createTime ? _vm.paramData[0].createTime : "--"
              )
          ),
        ]),
      ]),
      _c("div", [
        _vm._m(0),
        _c("div", { staticStyle: { "margin-top": "20px" } }, [
          _c(
            "table",
            {
              staticStyle: {
                color: "#000",
                width: "100%",
                "border-collapse": "collapse",
              },
              attrs: { border: "1" },
            },
            [
              _vm._m(1),
              _vm._l(_vm.paramData, function (item, index) {
                return _c("tr", { key: index }, [
                  _c("td", {
                    staticStyle: {
                      padding: "10px",
                      width: "80px",
                      height: "60px",
                    },
                  }),
                  _c(
                    "td",
                    {
                      staticStyle: {
                        padding: "10px",
                        width: "100px",
                        height: "60px",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(item.bagNumber ? item.bagNumber : "--") +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "td",
                    { staticStyle: { padding: "10px", height: "60px" } },
                    [
                      item.unloadingPoint
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                item.unloadingPoint.address
                                  ? item.unloadingPoint.address
                                  : "--"
                              )
                            ),
                          ])
                        : _c("span", [
                            _vm._v(
                              _vm._s(
                                item.site.address ? item.site.address : "--"
                              )
                            ),
                          ]),
                    ]
                  ),
                  _c(
                    "td",
                    {
                      staticStyle: {
                        padding: "10px",
                        width: "160px",
                        height: "60px",
                      },
                    },
                    [_vm._v(" " + _vm._s(item.zipCode) + " ")]
                  ),
                  _c(
                    "td",
                    {
                      staticStyle: {
                        padding: "10px",
                        width: "200px",
                        height: "60px",
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(item.city ? item.city : "--"))])]
                  ),
                  _c(
                    "td",
                    {
                      staticStyle: {
                        padding: "10px",
                        width: "100px",
                        height: "60px",
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(item.state ? item.state : "--")),
                      ]),
                    ]
                  ),
                ])
              }),
            ],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "table",
      {
        staticStyle: { width: "100%", "border-collapse": "collapse" },
        attrs: { border: "1" },
      },
      [
        _c("tr", [
          _c(
            "td",
            {
              staticStyle: { color: "#000", padding: "10px", height: "60px" },
              attrs: { colspan: "2" },
            },
            [_vm._v(" Origem：ANJUN EXPRESS LOGISTICA E TRANSPORTES LTDA ")]
          ),
        ]),
        _c("tr", [
          _c(
            "td",
            {
              staticStyle: { color: "#000", padding: "10px", height: "60px" },
              attrs: { colspan: "2" },
            },
            [
              _vm._v(
                " Endereço completo：Avenida Raimundo Pereira de Magalhães, 16800,Estr. da Boiada,17481-ANJUN-EXPRESS "
              ),
            ]
          ),
        ]),
        _c("tr", [
          _c(
            "td",
            { staticStyle: { color: "#000", padding: "10px", height: "60px" } },
            [_vm._v(" Bairro：Perus ")]
          ),
          _c(
            "td",
            { staticStyle: { color: "#000", padding: "10px", height: "60px" } },
            [_vm._v(" Cidade：São Paulo ")]
          ),
        ]),
        _c("tr", [
          _c(
            "td",
            { staticStyle: { color: "#000", padding: "10px", height: "60px" } },
            [_vm._v(" UF：SP ")]
          ),
          _c(
            "td",
            { staticStyle: { color: "#000", padding: "10px", height: "60px" } },
            [_vm._v(" CEP：05220-000 ")]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", {
        staticStyle: {
          padding: "10px",
          width: "80px",
          height: "60px",
          "text-align": "left",
        },
      }),
      _c(
        "th",
        {
          staticStyle: {
            padding: "10px",
            width: "200px",
            height: "60px",
            "text-align": "left",
          },
        },
        [_vm._v("quantidade de sacas")]
      ),
      _c(
        "th",
        {
          staticStyle: {
            padding: "10px",
            "text-align": "left",
            height: "60px",
          },
        },
        [_vm._v("Destinatário")]
      ),
      _c(
        "th",
        {
          staticStyle: {
            padding: "10px",
            width: "160px",
            height: "60px",
            "text-align": "left",
          },
        },
        [_vm._v("CEP")]
      ),
      _c(
        "th",
        {
          staticStyle: {
            padding: "10px",
            width: "200px",
            height: "60px",
            "text-align": "left",
          },
        },
        [_vm._v("Cidade")]
      ),
      _c(
        "th",
        {
          staticStyle: {
            padding: "10px",
            width: "100px",
            height: "60px",
            "text-align": "left",
          },
        },
        [_vm._v("UF")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }