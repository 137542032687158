// 相当于计算属性，来返回最新的值
const getters = {
  sidebar: state => state.app.sidebar,
  language: state => state.app.language,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  getmessageShowObj: state => state.user.getmessageShowObj,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  userId: state => state.user.id,
  userName: state => state.user.username,
  siteId: state => state.user.siteId,
  dotName: state => state.user.dotName,
  realName: state => state.user.realName,
  roles: state => state.user.roles,
  passwordLastModified: state => state.user.passwordLastModified,
  button_permissions: state => state.user.buttonPermissions,
  orgCode: state => state.user.orgCode,
  permission_routes: state => state.permission.routes,
  ElectronicBrand: state => state.settings.ElectronicBrand,
  isLink1: state => state.settings.isLink1,
  isLink2: state => state.settings.isLink2
};
export default getters;
