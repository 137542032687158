<template>
  <div class="warpp">
    <!-- <div class="searchContainer">
      <el-form
        ref="queryFormRef"
        class="queryFormClass"
        :model="queryForm.params"
        label-width="120px"
        :label-position="$i18n.locale !== 'zh' ? 'top' : 'right'"
        size="small"
      >
        <el-row :gutter="10">
          <el-col :span="7">
            操作人国家
            <el-form-item :label="$t('operationCenter.OperatorCountry')">
              <el-radio-group v-model="queryForm.params.country" size="small">
                <el-radio-button label="">{{ $t('operationCenter.all') }}</el-radio-button>
                <el-radio-button label="CN">{{ $t('operationCenter.China') }}</el-radio-button>
                <el-radio-button label="BR">{{ $t('operationCenter.Brazil') }}</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          状态
          <el-col :span="7">
            <el-form-item :label="$t('operationCenter.state')">
              <el-select
                v-model="queryForm.params.bigBagStatus"
                placeholder="请选择"
                style="width: 100%;"
              >
                <el-option :label="$t('operationCenter.all')" value="" />
                <el-option
                  v-for="(value, key) in radioOb"
                  :key="key"
                  :label="value.name"
                  :value="value.code"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            创建人
            <el-form-item :label="$t('operationCenter.creator')">
              <el-select
                v-model="queryForm.params.createUser"
                filterable
                clearable
                style="width: 100%;"
                :placeholder="$t('operationCenter.PleaseSelect')"
              >
                <el-option
                  v-for="(value, key) in userIdAndNameObj"
                  :key="key"
                  :label="value"
                  :value="key"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="7">
            操作人
            <el-form-item :label="$t('operationCenter.TheIngestorOperator')">
              <el-select
                v-model="queryForm.params.packageUserId"
                filterable
                clearable
                style="width: 100%;"
                :placeholder="$t('operationCenter.PleaseSelect')"
              >
                <el-option
                  v-for="(value, key) in userIdAndNameObj"
                  :key="key"
                  :label="value"
                  :value="key"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="7">
            下家
            <el-form-item :label="$t('operationCenter.nextHome')">
              <el-select
                v-model="queryForm.params.downstreamCode"
                filterable
                clearable
                :placeholder="$t('operationCenter.PleaseSelect')"
                style="width: 100%;"
              >
                <el-option
                  v-for="(item, index) in downstreamOption"
                  :key="index"
                  :label="item.downstreamName"
                  :value="item.downstreamCode"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            创建日期
            <el-form-item :label="$t('operationCenter.CreationTime')">
              <el-date-picker
                v-model="pickerArr"
                type="datetimerange"
                :range-separator="$t('operationCenter.to')"
                :start-placeholder="$t('operationCenter.startDate')"
                :end-placeholder="$t('operationCenter.endDate')"
                value-format="yyyy-MM-dd"
                style="width: 100%;"
                @change="getChangeDate()"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="7">
            操作日期
            <el-form-item :label="$t('operationCenter.operationTime')">
              <el-date-picker
                v-model="fitOverTimeArr"
                type="datetimerange"
                :range-separator="$t('operationCenter.to')"
                :start-placeholder="$t('operationCenter.startDate')"
                :end-placeholder="$t('operationCenter.endDate')"
                style="width: 100%;"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
          </el-col>

          <el-col :span="7">
            大包号
            <el-form-item :label="$t('operationCenter.largePackageNumber')">
              <el-input
                ref="totalListNoRef"
                v-model="queryForm.params.bigBagNos"
                clearable
                type="textarea"
                :placeholder="$t('operationCenter.Multiple100')"
                @keyup.enter.native="getData"
              />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            服务商袋号
            <el-form-item :label="$t('operationCenter.ServiceProviderBagNumber')">
              <el-input
                ref="totalListNoRef"
                v-model="queryForm.params.apiBigBagNos"
                clearable
                type="textarea"
                :placeholder="$t('operationCenter.Multiple100')"
                @keyup.enter.native="getData"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="7">
            <div>
              <el-button type="primary" size="mini" @click="queryFormReset(1)">{{
                $t('operationCenter.Procurar')
              }}</el-button>
              <el-button icon="el-icon-refresh" size="mini" @click="queryFormReset(2)">{{
                $t('operationCenter.reset')
              }}</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <el-divider />
      <div style="margin-bottom: 20px">
        <el-button
          v-permit:remove="'but:operation-center:bag-label:import'"
          size="mini"
          type="info"
          icon="el-icon-download"
          @click="handleExportPackageNumber"
        >{{ $t('operationCenter.exports') }} {{ $t('operationCenter.largePackageNumber') }}</el-button>
        <el-button
          v-permit:remove="'but:operation-center:bag-label:print'"
          size="mini"
          icon="el-icon-download"
          :disabled="selectionArr.length !== 1"
          @click="asyncGetTotalListLabel"
        >{{ $t('operationCenter.TemporaryPrinting') }}</el-button>
      </div>
    </div> -->
    <el-table
      :data="tableData"
      :max-height="600"
      border
    >
      <el-table-column type="selection" width="40" />
      <el-table-column :label="$t('newOperationCenter.ind')" align="center">
        <template slot-scope="scope">
          <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <!-- 大包号 -->
      <el-table-column prop="bagNumber" :label="$t('newOperationCenter.bigBagNum')" />
      <!-- 包裹数 -->
      <el-table-column prop="packageCount" :label="$t('newOperationCenter.NumberPackages')" />
      <!-- 格口 -->
      <el-table-column prop="grid" :label="$t('newOperationCenter.Lattice')" />
      <!-- 重量 -->
      <el-table-column prop="weight" :label="$t('newOperationCenter.Weight')" />
      <!-- 大包状态 -->
      <el-table-column prop="bagStatus" :label="$t('newOperationCenter.LargePackCondition')">
        <template slot-scope="scope">
          <div>
            <!-- scope.row.bagStatus == 0 ? '已封袋' :
              scope.row.bagStatus == 1 ? '已签收' : '' -->
            {{
              scope.row.bagStatus == 0 ? $t('newOperationCenter.SealedBag') :
              scope.row.bagStatus == 1 ? $t('newOperationCenter.haveBeenSigned') : ''
            }}
          </div>
        </template>
      </el-table-column>
      <!-- 预报状态 -->
      <el-table-column prop="plpLink" :label="$t('newOperationCenter.ForecastState')">
        <template slot-scope="scope">
          <div>
            <!-- '预报成功' : '异常' -->
            {{ scope.row.plpLink ? $t('newOperationCenter.SuccessfulForecast') : $t('newOperationCenter.abnormal') }}
          </div>
        </template>
      </el-table-column>
      <!-- 创建时间 -->
      <el-table-column prop="createTime" :label="$t('newOperationCenter.CreationTime')" />
      <!-- 备注 -->
      <el-table-column prop="remark" :label="$t('newOperationCenter.Note')" />
      <el-table-column
        :label="$t('collectionCenter.operation')"
        width="150"
        fixed="right"
      >
        <template slot-scope="scope">
          <div>
            <!-- 面单url -->
            <el-button
              v-show="scope.row.labelUrl"
              style="width: 100%;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;"
              @click="downClick(scope.row)"
            >
              打印面单
            </el-button>
            <!-- 小包列表 (大包号跳转到分拣订单) -->
            <el-button
              v-show="scope.row.bagNumber"
              style="width: 100%;overflow: hidden;white-space: normal;word-break:break-all;"
              @click="bigBagClick(scope.row)"
            >
              小包列表
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- OA系统——start -->
    <el-table
      :data="tableData"
      style="width: 100%;display:none;"
      border
      :row-style="{ height: 0 + 'px' }"
      :cell-style="{ padding: 0 + 'px' }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="40" />
      <el-table-column :label="$t('operationCenter.ind')" align="center">
        <template slot-scope="scope">
          <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="bigBagNo" :label="$t('operationCenter.bagNumber')" />
      <el-table-column
        prop="apiBigBagNo"
        label="服务商袋号"
      /><!-- 服务商袋号 -->
      <el-table-column prop="downstreamName" :label="$t('operationCenter.nextHome')" />
      <el-table-column
        prop="smallBagCount"
        width="70"
        :label="$t('operationCenter.numberOfPackets')"
      />
      <el-table-column prop="weight" :label="$t('operationCenter.Weight') + '(KG)'" />
      <el-table-column prop="smallBagState" :label="$t('operationCenter.state')" />

      <el-table-column prop="createUserName" :label="$t('operationCenter.creator')" />
      <el-table-column prop="createTime" :label="$t('operationCenter.CreationTime')" />
      <el-table-column prop="packageUserName" :label="$t('operationCenter.operator')" />
      <el-table-column prop="updateTime" :label="$t('operationCenter.operationTime')" />

      <el-table-column :label="$t('operationCenter.operate')" width="210">
        <template slot-scope="scope">
          <el-button style="margin: 5px" size="mini" @click="handleDetails(scope.row)">{{
            $t('operationCenter.viewUnblock')
          }}</el-button>
          <el-popover placement="bottom-start" trigger="click">
            <div>
              <!-- 未打包 -->
              <template v-if="scope.row.bigBagStatus === radioOb.unpacked.code">
                <el-popconfirm
                  :title="$t('operationCenter.AreYouSureItIsVoid')"
                  @confirm="asyncCancelBag(scope.row.id)"
                >
                  <el-button slot="reference" class="ml5" type="danger" size="mini">{{ $t('operationCenter.void') }}</el-button>
                </el-popconfirm>
              </template>

              <!-- 已打包 -->
              <template v-if="scope.row.bigBagStatus === radioOb.packaged.code">
                <!-- <el-button class="ml5" type="warning" size="mini" @click="handleDetails(scope.row)">解封</el-button> -->
                <el-button
                  v-if="scope.row.apiBigBagNo.length === 0"
                  style="margin: 5px"
                  type="success"
                  size="mini"
                  @click="handleBagLabel(scope.row)"
                >
                  {{ $t('operationCenter.bagSealingPrinting') }}
                </el-button>
                <el-popconfirm
                  :title="$t('operationCenter.AreYouSureItIsVoid')"
                  @confirm="asyncCancelBag(scope.row.id)"
                >
                  <el-button slot="reference" class="ml5" type="danger" size="mini">{{ $t('operationCenter.void') }}</el-button>
                </el-popconfirm>
              </template>

              <!-- 已封袋 -->
              <template v-if="scope.row.bigBagStatus === radioOb.printed.code">
                <el-button
                  style="margin: 5px"
                  type="info"
                  size="mini"
                  @click="asyncRetryGetBigBagExpressWaybill(scope.row)"
                >
                  {{ $t('operationCenter.anew') }} {{ $t('operationCenter.print') }}
                </el-button>
                <!-- <el-button class="ml5" type="warning" size="mini" @click="handleDetails(scope.row)">解封</el-button> -->
              </template>

              <!-- 已配载 -->
              <template v-if="scope.row.bigBagStatus === radioOb.fited.code">
                <el-button
                  style="margin: 5px"
                  type="info"
                  size="mini"
                  @click="asyncRetryGetBigBagExpressWaybill(scope.row)"
                >
                  {{ $t('operationCenter.anew') }} {{ $t('operationCenter.print') }}
                </el-button>
              </template>
            </div>
            <el-button slot="reference" size="mini">{{ $t('operationCenter.MoreActions') }}</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!-- OA系统——end -->

    <!-- 分页工具 -->
    <div class="pagination">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100, 500]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getData"
        @size-change="getData"
      />
    </div>

    <!-- 只有巴西才需要选择 机场 弹窗 -->
    <el-dialog
      :title="$t('operationCenter.SelectTheAirport')"
      :close-on-click-modal="false"
      :visible.sync="airportObj.visible"
      width="30%"
    >
      <el-form
        ref="ruleForm"
        :model="airportObj.ruleForm"
        :rules="airportObj.rules"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('operationCenter.DepartureAirport')" prop="startingStr">
          <el-select
            v-model="airportObj.ruleForm.startingStr"
            :placeholder="$t('operationCenter.PleaseSelect')"
          >
            <template v-for="item of startingArr">
              <el-option :key="item.key" :label="item.val" :value="item.key" />
            </template>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('operationCenter.DestinationAirport')" prop="endStr">
          <el-select
            v-model="airportObj.ruleForm.endStr"
            :placeholder="$t('operationCenter.PleaseSelect')"
          >
            <template v-for="item of endArr">
              <el-option :key="item.key" :label="item.val" :value="item.key" />
            </template>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="airportObj.visible = false">
          {{ $t('operationCenter.Cancel') }}
        </el-button>
        <el-button type="primary" @click="submitAirportForm('ruleForm')">
          {{ $t('operationCenter.determine') }}
        </el-button>
      </div>
    </el-dialog>

    <!-- 已解封大包 -->
    <el-drawer
      :title="$t('operationCenter.LargeBags') + ' ' + $t('operationCenter.detail')"
      :visible.sync="unsealObj.visible"
      direction="ltr"
      size="50%"
    >
      <details-list-cmp
        :big-bag-no="unsealObj.bigBagNo"
        :big-bag-status="unsealObj.bigBagStatus"
        :downstream-code="unsealObj.downstreamCode"
        :ids="unsealObj.ids"
        :downstream-name="unsealObj.downstreamName"
        :random-val="unsealObj.randomVal"
        @handleUpdata="getData"
      />
    </el-drawer>

    <!-- 袋标 弹窗 -->
    <el-dialog
      :title="$t('operationCenter.print')"
      :close-on-click-modal="false"
      :visible.sync="printObj.visible"
      width="50%"
      @close="getData"
    >
      <bag-label-cmp
        :index="printObj.index"
        :bag-no="printObj.bagNo"
        :express-waybill-type="printObj.expressWaybillType"
        :ordinary-props-obj="printObj.ordinaryObj"
        :other-props-obj="printObj.otherObj"
        @handleClose="handleClose"
      />
    </el-dialog>
  </div>
</template>

<script>
import {
  apiPostPperationBigBagInfoPage,
  apiCancelBagRelation,
  apiGetBigBagExpressWaybill,
  apiRetryGetBigBagExpressWaybill,
  apiCancelBag,
  apiGetTotalListLabel
} from '@/api/operationCenter';
// import { RADIOOB } from '@/enums/operationCenterEnums';
// import { getRandomInt } from '@/utils/math_util';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { apiDownstreamList } from '@/api/downstream';
import LAY_EXCEL from 'lay-excel';
import { random } from 'lodash-es';
import { cloneDeep } from 'lodash-es';

// 起飞机场
const startingArr = [
  { key: 'HKG', val: '香港机场' },
  { key: 'SZX', val: '深圳机场' },
  { key: 'CAN', val: '广州机场' },
  { key: 'CKG', val: '重庆机场' },
  { key: 'WUH', val: '武汉机场' },
  { key: 'PVG', val: '上海机场' },
  { key: 'MFM', val: '澳门机场' }
];
// 目的机场
const endArr = [
  { key: 'GRU', val: '圣保罗机场' }
];

export default {
  components: {
    'bag-label-cmp': () => import('./BagLabelCmp.vue'),
    'details-list-cmp': () => import('./DetailsListCmp.vue')
  },

  props: {
    tabName: {
      type: String,
      default: ''
    },
    cardName: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          createStartTime: '', // 当前时间
          createEndTime: '',
          bigBagStatus: 'packaged',
          country: '', // 操作人国家
          bigBagNos: '', // 大包号
          downstreamCode: '', // 下家代码
          packageUserId: '', // 装配人ID
          createUser: '', // 创建人
          apiBigBagNos: '' // 服务商袋号
        }
      },

      total: 0,

      tableData: [],

      // 打印
      printObj: {
        visible: false,

        expressWaybillType: 1, // 袋标类型 0-->普通  1--> 巴西袋标
        bagNo: '',
        index: 1,

        // 普通袋标
        ordinaryObj: {
          id: '',
          bagNo: '', // 袋号
          quantity: '', // 件数
          kg: '', // 重量
          country: '待定', // 国家
          channel: '', // 渠道/ 线路
          downstreamName: '', // 下家
          size: '', // 尺寸
          Sender: 'Anjun',
          height: 0,
          width: 0,
          length: 0,
          expressWaybillOssLink: '', // oss 地址
          downstreamCode: '' // 下家
        },

        // 巴西袋标
        otherObj: {
          id: '',
          startingStr: '', // 起飞机场
          endStr: '', // 目的机场
          bagNo: '', // 袋号
          origemInfo: '', //  安骏标识
          bigBagId: '', // 袋号id
          date: '', // 日期
          quantity: '',
          weight: '', // 重量
          serviceType: '', // 类型
          serialNumbe: '',
          expressWaybillOssLink: '' // oss 地址
        }
      },

      // 机场选择
      airportObj: {
        visible: false,
        ruleForm: {
          startingStr: '',
          endStr: ''
        },
        rules: {
          startingStr: [
            {
              required: true,
              message: this.$i18n.t('operationCenter.PleaseSelect'),
              trigger: 'change'
            }
          ],
          endStr: [
            {
              required: true,
              message: this.$i18n.t('operationCenter.PleaseSelect'),
              trigger: 'change'
            }
          ]
        }
      },

      // 解封打包
      unsealObj: {
        visible: false,
        bigBagNo: '', // 大包号
        bigBagStatus: '', // 大包状态
        downstreamCode: '', // 下家code
        downstreamName: '', // 下家名称
        ids: '',
        randomVal: 0 // 随机数保证每次更新
      },

      selectionArr: [], // 已经选择的大包
      pickerArr: [], // 创建人时间
      fitOverTimeArr: [], // 操作人完成时间
      radioOb: {
        packaged: {
          name: this.$i18n.t('operationCenter.Packaged'), // '已打包',
          code: 'packaged'
        },
        printed: {
          name: this.$i18n.t('operationCenter.isSealed'), // '已封袋',
          code: 'printed'
        },
        fited: {
          name: this.$i18n.t('operationCenter.Stowed'), // '已配载',
          code: 'fited'
        },
        unpacked: {
          name: this.$i18n.t('operationCenter.NotPackaged'), // '未打包',
          code: 'unpacked'
        },
        have_air_bill_no: {
          name: this.$i18n.t('operationCenter.BillLading'), // '已提单',
          code: 'have_air_bill_no'
        }
      },
      downstreamOption: []
    };
  },

  watch: {
    tabName: {
      handler(val) {
        if (val !== this.cardName) {
          return;
        }
        // 默认 搜索 已打包
        this.queryForm.params.bigBagStatus = this.radioOb.packaged.code;
        this.getData();

        this.$nextTick(() => {
          this.$refs.totalListNoRef.focus();
        });
      },
      immediate: true
    },
    '$i18n.locale'() {
      this.radioOb = {
        packaged: {
          name: this.$i18n.t('operationCenter.Packaged'), // '已打包',
          code: 'packaged'
        },
        printed: {
          name: this.$i18n.t('operationCenter.isSealed'), // '已封袋',
          code: 'printed'
        },
        fited: {
          name: this.$i18n.t('operationCenter.Stowed'), // '已配载',
          code: 'fited'
        },
        unpacked: {
          name: this.$i18n.t('operationCenter.NotPackaged'), // '未打包',
          code: 'unpacked'
        },
        have_air_bill_no: {
          name: this.$i18n.t('operationCenter.BillLading'), // '已提单',
          code: 'have_air_bill_no'
        }
      };
    }
  },

  created() {
    try {
      // 所有发起人/用户
      this.userIdAndNameObj = getUserIdAndNameObj();
    } catch (error) {
      console.err(error);
    }

    this.queryForm.params.bigBagStatus = this.radioOb.packaged.code;

    this.startingArr = startingArr;
    this.endArr = endArr;
    this.getData();
    // this.getDownstreamOption();
  },

  methods: {
    // 获取下家选项
    getDownstreamOption() {
      apiDownstreamList({}, false).then(res => {
        if (res.code !== 200) { return; }
        this.downstreamOption = res.data;
      }).catch(err => {
        console.error(err);
      });
    },
    // 选择时间
    getChangeDate() {
      if (this.pickerArr && this.pickerArr.length > 0) {
        const startDate = new Date(this.pickerArr[0]).getTime();
        const endDate = new Date(this.pickerArr[1]).getTime();
        if (endDate - startDate <= 0) {
          this.$message.warning(this.$i18n.t('operationCenter.startTimeLessEndTime'));
          this.pickerArr = [];
        }
      }
    },

    // 请求数据整理
    setRequestData() {
      const obj = cloneDeep(this.queryForm);
      const bigBagNos = obj.params.bigBagNos.trim();
      const apiBigBagNos = obj.params.apiBigBagNos.trim();

      // 创建时间处理
      if (Array.isArray(this.pickerArr) && (this.pickerArr.length === 2)) {
        obj.params.createStartTime = this.pickerArr[0] + ' 00:00:00';
        obj.params.createEndTime = this.pickerArr[1] + ' 23:59:59';
      }

      // 操作时间处理
      if (Array.isArray(this.fitOverTimeArr) && (this.fitOverTimeArr.length === 2)) {
        obj.params.operateStartTime = this.fitOverTimeArr[0] + ' 00:00:00';
        obj.params.operateEndTime = this.fitOverTimeArr[1] + ' 23:59:59';
      }

      // 大包号处理(批量)
      if (bigBagNos.length === 0) {
        obj.params.bigBagNos = [];
      } else {
        obj.params.bigBagNos = bigBagNos.split('\n').map(item => {
          return item.trim();
        });
      }
      // 服务商袋号处理
      if (apiBigBagNos.length === 0) {
        obj.params.apiBigBagNos = [];
      } else {
        obj.params.apiBigBagNos = apiBigBagNos.split('\n').map(item => {
          return item.trim();
        });
      }

      return obj;
    },

    // 获取数据
    getData() {
      const obj = this.setRequestData();

      if (obj.params.bigBagNos.length >= 101) {
        this.$message({
          message: this.$i18n.t('operationCenter.searches100') + '(' + this.$i18n.t('operationCenter.current') + `${obj.params.bigBagNos.length})`,
          type: 'warning'
        });
        return;
      }
      if (obj.params.apiBigBagNos.length >= 101) {
        this.$message({
          message: this.$i18n.t('operationCenter.searches100ServiceNumbers') + '(' + this.$i18n.t('operationCenter.current') + `${obj.params.apiBigBagNos.length})`,
          type: 'warning'
        });
        return;
      }

      const sorterBagPage = {
        pageNumber: this.queryForm.pageNumber,
        pageSize: this.queryForm.pageSize,
        params: {
          orderNumber: ''
        }
      };
      apiPostPperationBigBagInfoPage(sorterBagPage).then(res => {
        // this.queryForm.params.bigBagNo = '';
        if (res.status !== 'OK') { return; }
        this.total = res.data.total;
        this.tableData = res.data.records.map(item => {
          const smallBagState = this.radioOb[item.bigBagStatus]?.name ?? '';
          const createUserName = this.userIdAndNameObj[item.createUser] ? this.userIdAndNameObj[item.createUser] : ''; // 创建人

          return {
            ...item,
            id: item.id,
            bigBagNo: item.bigBagNo,
            apiBigBagNo: item.apiBigBagNo || '',
            updateTime: item.updateTime,
            packageUserName: item.packageUserName,
            channelName: item.customerChannelName,
            smallBagTotalCount: item.smallBagTotalCount,
            smallBagTotalWeight: item.smallBagTotalWeight,
            expressWaybillOssLink: item.expressWaybillOssLink || '',
            smallBagState,
            createUserName
          };
        });
      }).catch(err => {
        console.error(err);
      });
    },
    downClick(down) {
      window.open(down.labelUrl);
    },
    bigBagClick(row) {
      this.$router.push({
        name: 'sorterOrder',
        query: {
          bagNumber: row.bagNumber
        }
      });
    },

    // 重置
    queryFormReset(type) {
      if (type === 1) {
        this.queryForm.pageNumber = 1;
        this.getData();
      } else {
        this.pickerArr = [];
        this.fitOverTimeArr = [];
        const obj = this.queryForm.params;
        for (const key in obj) {
          this.queryForm.params[key] = '';
        }
        this.queryForm.params.bigBagStatus = 'packaged';
      }
    },

    // 解封
    handleUnseal(ids) {
      apiCancelBagRelation(ids)
        .then((res) => {
          if (res.code !== 200) {
            return;
          }
          this.$message({
            message:
              this.$i18n.t('operationCenter.Unblock') + this.$i18n.t('operationCenter.succeed'),
            type: 'success'
          });
          this.getData();
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // 机场提交
    submitAirportForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        }

        this.airportObj.visible = false;

        const { startingStr, endStr } = this.airportObj.ruleForm;
        this.printObj.otherObj.startingStr = startingStr;
        this.printObj.otherObj.endStr = endStr;

        this.printObj.visible = true;
      });
    },

    // 打印袋标
    handleBagLabel(obj) {
      console.log(obj);
      // 从后台获取袋标--普通/巴西
      // this.asyncGetBigBagExpressWaybill(obj);
      this.routineData(obj);
    },

    // 获取袋标
    asyncGetBigBagExpressWaybill(obj) {
      apiGetBigBagExpressWaybill(obj.id)
        .then((res) => {
          if (res.code !== 200) {
            return;
          }

          this.unifiedData(obj, res);
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // 重试
    asyncRetryGetBigBagExpressWaybill(obj) {
      const { expressWaybillOssLink } = obj;
      // 已经存在PDF
      if (expressWaybillOssLink) {
        window.open(expressWaybillOssLink);
        return;
      }

      apiRetryGetBigBagExpressWaybill(obj.id)
        .then((res) => {
          if (res.code !== 200) {
            return;
          }

          this.unifiedData(obj, res);
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // 统一处理
    unifiedData(obj, res) {
      const { data, expressWaybillType, msg, success } = res.data;
      if (!success) {
        this.$message.error(msg || this.$i18n.t('operationCenter.PrintError'));
        return;
      }
      // 后台返回数据有问题
      if (expressWaybillType !== 0 && expressWaybillType !== 1) {
        this.$message.error(this.$i18n.t('operationCenter.ReturnDataError'));
        return;
      }

      // 普通订单
      if (expressWaybillType === 0) {
        this.routineData(obj, data.apiBigBagNo);
        return;
      }

      // 巴西订单
      this.otherData(obj, data);

      // 刷新页面
      this.getData();
    },

    // 普通订单数据处理
    routineData(obj, bagNo) {
      const {
        channelName,
        smallBagCount,
        weight,
        id,
        height,
        width,
        length,
        downstreamCode,
        expressWaybillOssLink,
        bigBagNo,
        downstreamName
      } = obj;

      this.printObj.visible = true;
      this.printObj.expressWaybillType = 0;
      // this.printObj.bagNo = bagNo;
      this.printObj.bagNo = bigBagNo;
      this.printObj.index = this.getIndexData();
      this.printObj.ordinaryObj.id = id;
      this.printObj.ordinaryObj.height = height;
      this.printObj.ordinaryObj.downstreamCode = downstreamCode;
      this.printObj.ordinaryObj.width = width;
      this.printObj.ordinaryObj.length = length;
      this.printObj.ordinaryObj.expressWaybillOssLink = expressWaybillOssLink || '';

      this.printObj.ordinaryObj.bagNo = bigBagNo; // 最终袋标
      this.printObj.ordinaryObj.channel = channelName;
      this.printObj.ordinaryObj.downstreamName = downstreamName;
      this.printObj.ordinaryObj.quantity = smallBagCount;
      this.printObj.ordinaryObj.kg = weight;
    },

    // 生成随机数
    getIndexData() {
      const index = this.printObj.index + 1;
      if (index > 10) {
        return 1;
      } else {
        return index;
      }
    },

    // 巴西（API）订单数据处理
    otherData(obj, data) {
      const { id, expressWaybillOssLink } = obj;
      const {
        origemInfo,
        bigBagId,
        date,
        quantity,
        weight,
        serviceType,
        serialNumbe,
        apiBigBagNo
      } = data;

      this.printObj.expressWaybillType = 1;

      this.printObj.bagNo = apiBigBagNo;
      this.printObj.index = this.getIndexData();

      this.printObj.otherObj.id = id;
      this.printObj.otherObj.expressWaybillOssLink = expressWaybillOssLink || '';
      this.printObj.otherObj.bagNo = apiBigBagNo;
      this.printObj.otherObj.origemInfo = origemInfo;
      this.printObj.otherObj.bigBagId = bigBagId;
      this.printObj.otherObj.date = date;
      this.printObj.otherObj.quantity = quantity;
      this.printObj.otherObj.weight = weight;
      this.printObj.otherObj.serviceType = serviceType;
      this.printObj.otherObj.serialNumbe = serialNumbe;

      this.airportObj.visible = true;
    },

    // 关闭弹窗
    handleClose(isBool) {
      console.log(isBool);
      this.printObj.visible = false;
      if (isBool) {
        this.getData();
      }
    },

    // 作废
    asyncCancelBag(ids) {
      apiCancelBag(ids).then(res => {
        const { msg, code } = res;
        if (code !== 200) {
          this.$message.error(msg || this.$i18n.t('operationCenter.operationFailed'));
          return;
        }
        this.$message({
          message: this.$i18n.t('operationCenter.ProcessingSucceeded'),
          type: 'success'
        });
        this.getData();
      }).catch(err => {
        console.error(err);
      });
    },

    // 查看 CN35
    getLink(url) {
      window.open(url);
    },

    handleSelectionChange(selection) {
      this.selectionArr = selection;
    },

    // 导出大包号
    handleExportPackageNumber() {
      // 有选中导出选中的数据，没选中导出当前列表所有数据
      const selectionArr = this.selectionArr.length ? this.selectionArr : this.tableData;
      let list = selectionArr.map((item, index) => {
        return {
          ...item,
          index: index + 1
        };
      });
      list.unshift(
        { index: '序号', bigBagNo: '袋号', downstreamName: '下家',
          smallBagCount: '小包数', weight: '重量(KG)', smallBagState: '状态',
          createUserName: '创建人', createTime: '创建时间', packageUserName: '操作人',
          updateTime: '操作时间'
        }
      );
      list = LAY_EXCEL.filterExportData(list, [
        'index',
        'bigBagNo',
        'downstreamName',
        'smallBagCount',
        'weight',
        'smallBagState',
        'createUserName',
        'createTime',
        'packageUserName',
        'updateTime'
      ]);
      LAY_EXCEL.exportExcel(
        {
          sheet1: list
        },
        '大包列表导出.xlsx',
        'xlsx'
      );
    },

    // 大包标签临时打印
    asyncGetTotalListLabel() {
      const selectionArr = this.selectionArr;
      if (selectionArr.length !== 1) {
        console.error('请只能选择1个大包');
        return;
      }
      const obj = {
        bigBagId: selectionArr[0].id,
        height: 0,
        length: 0,
        width: 0
      };
      apiGetTotalListLabel(obj).then(res => {
        const { code, data } = res;
        if (code !== 200) { return; }
        window.open(data);
      });
    },

    // // 查看解封打包
    // handleUnseal() {
    //   this.unsealObj.visible = true;
    //   const { index } = this.unsealObj;
    //   if (index >= 10) {
    //     this.unsealObj.index = 1;
    //   } else {
    //     this.unsealObj.index = index + 1;
    //   }
    // },

    // 查看详情
    handleDetails(obj) {
      const { bigBagNo, bigBagStatus, downstreamCode, id, downstreamName } = obj;
      console.log(obj);
      this.unsealObj.visible = true;
      this.unsealObj.randomVal = random(1, 100);
      this.unsealObj.bigBagNo = bigBagNo;
      this.unsealObj.bigBagStatus = bigBagStatus;
      this.unsealObj.downstreamCode = downstreamCode;
      this.unsealObj.ids = id;
      this.unsealObj.downstreamName = downstreamName;
    }
  }
};
</script>

<style scoped>
.ml5 {
  margin: 5px;
}
</style>
