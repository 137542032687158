<template>
  <div style="display: flex;justify-content: center;align-items: center;height: calc(100vh - 85px);">
    <el-card class="box-card">
      <h1>{{ $t("operatingPlatform.bagNumberReplacement") }}</h1>
      <el-form ref="ruleForm" label-width="200px" label-position="top" style="width: 600px" :model="form" :rules="rules">
        <el-form-item :label="$t('operatingPlatform.currentBagNumber')" prop="oldBagNumber">
          <el-input v-model="form.oldBagNumber" :placeholder="$t('operatingPlatform.enterTheCurrentBagNumber')" />
        </el-form-item>
        <el-form-item :label="$t('operatingPlatform.newBagNumber')" prop="newBagNumber">
          <el-input v-model="form.newBagNumber" :placeholder="$t('operatingPlatform.enterTheNewBagNumber')" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="width: 100%" @click="submitForm"> {{ $t('operatingPlatform.swap') }} </el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import { collectPcReplaceCollectOrderBag } from '@/api/operatingPlatform';

export default {
  data() {
    return {
      form: {
        newBagNumber: '',
        oldBagNumber: ''
      },
      rules: {
        newBagNumber: [
          { required: true, message: this.$t('operatingPlatform.BagNumberLimit'), trigger: 'blur' },
          { min: 6, max: 8, message: this.$t('operatingPlatform.BagNumberLimit'), trigger: 'blur' }
        ],
        oldBagNumber: [
          { required: true, message: this.$t('operatingPlatform.BagNumberLimit'), trigger: 'blur' },
          { min: 6, max: 8, message: this.$t('operatingPlatform.BagNumberLimit'), trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    /**
     * @description 提交
     */
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          collectPcReplaceCollectOrderBag(this.form)
            .then((res) => {
              if (res.status === 'OK') {
                this.$message({
                  message: this.$t('GlobalSetObj.TheOperationSucceeded'),
                  type: 'success'
                });
                this.$refs.ruleForm.resetFields();
              }
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
</script>
<style scoped>

</style>
