// 连接类型
const WS_TYPE = {
  'user_register': {
    code: '/user/register',
    txt: '首次注册'
  },
  'heartbeat_detection': {
    code: '/heartbeat/detection',
    txt: '心跳'
  },
  'scan_machine': {
    code: '/wms/scan/machine',
    txt: '机器入库'
  },
  'scan_manual': {
    code: '/wms/scan/manual',
    txt: '手工入库'
  },
  'scan_manual_rest_weight': {
    code: '/wms/scan/manualRestWeight',
    txt: '复重'
  },
  'scan_over_weight_handle': {
    code: '/wms/scan/overWeightHandle',
    txt: '超重'
  },
  'over_weight_no_handle': {
    code: 'over_weight_no_handle',
    txt: '超重未处理'
  },
  'scan_over_customer_channel': {
    code: '/wms/scan/overCustomerChannelOrderWeightHandle',
    txt: '超出客户渠道预报重量'
  }
};

const RES_TYPE = {
  'match_order_failure': {
    txt: '未匹配到订单', // 未匹配到订单
    code: 'match_order_failure',
    tips: {
      pu: 'A ordem não foi correspondida.',
      zh: '未匹配到订单'
    }
  },
  'check_weight_failure': {
    txt: '超出预报重量',
    code: 'check_weight_failure',
    tips: {
      pu: 'Peso de previsão superado',
      zh: '超出预报重量$千克'
    }
  },
  'no_customer_channel': {
    txt: '未找到客户渠道',
    code: 'no_customer_channel',
    tips: {
      pu: 'Canal do cliente não encontrado',
      zh: '未找到客户渠道'
    }
  },
  'over_weight_no_handle': {
    txt: '存在一条超重未处理订单',
    code: 'over_weight_no_handle',
    tips: {
      pu: 'Há uma ordem não processada com excesso de peso',
      zh: '存在一条超重未处理订单'
    }
  },
  'exist': {
    txt: '该包裹已经入库，请不要重复扫描',
    code: 'exist',
    tips: {
      pu: 'O pacote já está armazenado, por favor, não repita a varredura',
      zh: '该包裹已经入库，请不要重复扫描'
    }
  },
  'json_parse_error': {
    txt: '无法识别该单号',
    code: 'json_parse_error',
    tips: {
      pu: 'O número do bilhete não é reconhecido',
      zh: '无法识别该单号'
    }
  },
  'order_weight_empty': {
    txt: '订单预报重量为空',
    code: 'order_weight_empty',
    tips: {
      pu: 'O peso da previsão da ordem está vazio',
      zh: '订单预报重量为空'
    }
  },
  'work_console_is_taken': {
    txt: '操作台已经被绑定',
    code: 'work_console_is_taken',
    tips: {
      pu: '',
      zh: ''
    }
  },
  'bind_your_work_console_request': {
    txt: '接收解绑操作台',
    code: 'bind_your_work_console_request',
    tips: {
      pu: '',
      zh: ''
    }
  },
  'request_unbind_work_console': {
    txt: '请求对方解绑操作台',
    code: 'request_unbind_work_console',
    tips: {
      pu: '',
      zh: ''
    }
  },
  'accept_your_bind_work_console_request': {
    txt: '已同意你的绑定请求',
    code: 'accept_your_bind_work_console_request',
    tips: {
      pu: '',
      zh: ''
    }
  },
  'refuse_your_bind_work_console_request': {
    txt: '已拒绝你的绑定请求',
    code: 'refuse_your_bind_work_console_request',
    tips: {
      pu: '',
      zh: ''
    }
  },
  'match_small_bag_failure': {
    txt: '未匹配到小包',
    code: 'match_small_bag_failure',
    tips: {
      pu: 'Não corresponde ao pacote',
      zh: '未匹配到小包'
    }
  },
  'no_customer_channel_config': {
    txt: '未找到当前客户的客户渠道配置',
    code: 'no_customer_channel_config',
    tips: {
      pu: 'A configuração do canal do cliente para o cliente atual não foi encontrada',
      zh: '未找到当前客户的客户渠道配置'
    }
  },
  'problem_order': {
    txt: '问题件',
    code: 'problem_order',
    tips: {
      pt: 'O item foi interceptado. Causa: Peça de problema',
      zh: '该物品已被拦截。原因：问题件'
    }
  },
  'RESET_WEIGHT_SUCCESS': {
    txt: '复重成功',
    code: 'RESET_WEIGHT_SUCCESS',
    tips: {
      pu: 'Repetir o sucesso',
      zh: '复重成功'
    }
  },
  'SYSTEM_ERROR': {
    txt: '系统错误',
    code: 'SYSTEM_ERROR',
    tips: {
      pu: 'Erro do sistema',
      zh: '系统错误'
    }
  },
  'INVALID_REQUEST': {
    txt: '无效请求',
    code: 'invalid_request',
    tips: {
      pu: 'Solicitação inválida',
      zh: '无效请求'
    }
  },
  'over_customer_channel_in_storage_order_weight_max': {
    txt: '超过客户渠道入库最大预报重量',
    code: 'over_customer_channel_in_storage_order_weight_max',
    tips: {
      pu: 'Excede o peso máximo de previsão para entrada do canal do cliente',
      zh: '超过客户渠道入库最大预报重量'
    }
  },
  'over_customer_channel_in_storage_order_weight_min': {
    txt: '小于客户渠道入库最小预报重量',
    code: 'over_customer_channel_in_storage_order_weight_min',
    tips: {
      pu: 'Menor que o peso mínimo previsto para entrada do canal do cliente',
      zh: '小于客户渠道入库最小预报重量'
    }
  },
  'nonsupport_use_order_weight': {
    txt: '客户渠道不支持使用预报重量',
    code: 'nonsupport_use_order_weight',
    tips: {
      pu: 'O uso de pesos de previsão não é suportado para canais de clientes',
      zh: '客户渠道不支持使用预报重量'
    }
  },
  'over_customer_channel_in_storage_order_weight_max_no_handle': {
    txt: '超过客户渠道入库最大预报重量，未处理',
    code: 'over_customer_channel_in_storage_order_weight_max_no_handle',
    tips: {
      pu: 'Excedeu o peso máximo de previsão da entrada do canal do cliente e não foi tratada',
      zh: '超过客户渠道入库最大预报重量，未处理'
    }
  },
  'over_customer_channel_in_storage_order_weight_min_no_handle': {
    txt: '小于客户渠道入库最小预报重量，未处理',
    code: 'over_customer_channel_in_storage_order_weight_min_no_handle',
    tips: {
      pu: 'Excedeu o peso máximo de previsão da entrada do canal do cliente e não foi tratada',
      zh: '小于客户渠道入库最小预报重量，未处理'
    }
  },
  'unlock_no_weight': {
    txt: '超出客户渠道预报重量处理，未传重量！',
    code: 'unlock_no_weight',
    tips: {
      pu: 'Exceda o tratamento de peso de previsão do canal do cliente, peso não traduzido!',
      zh: '超出客户渠道预报重量处理，未传重量！'
    }
  },
  'weight_out_of_range': {
    txt: '重量超出限制',
    code: 'weight_out_of_range',
    tips: {
      pu: 'Peso excede o limite',
      zh: '重量超出限制'
    }
  },
  'othen': {
    txt: '其他',
    tips: {
      '自动处理超重': {
        pu: 'Manuseio automático de excessos de peso',
        zh: '自动处理超重'
      },
      '请选择处理': {
        pu: 'Selecione Processo',
        zh: '请选择处理'
      }
    }
  }
};

/**
 * 下载数据映射
 */
const DOWNLOAD_OBJ = {
  'bigBagNo': '大包号',
  'smallBagTrackingNo': '转单号',
  'weight': '重量(kg)',
  'errorMsg': '错误提示'
};

/**
 * 装载操作 类型
 */
const DOWNSTREAM_OBJ = {
  'single_pkg': {
    code: 'single_pkg',
    text: '单个打包'
  },
  'batch_pkg': {
    code: 'batch_pkg',
    text: '批量打包'
  },
  'select_downstream': {
    code: 'select_downstream',
    text: '选择下家'
  },
  'need_select_downstream': {
    code: 'need_select_downstream',
    text: '需要选择下家'
  },
  'get_downstream': {
    code: 'get_downstream',
    text: '获取下家'
  }
};
const TOTALLISTINFOOBJ = {
  // 'done': '已装配',
  // 'initial': '未装配'
  'done': {
    code: 'done',
    text: '已装配'
  },
  'initial': {
    code: 'initial',
    text: '未装配'
  },
  'over_fit': {
    code: 'over_fit',
    text: '装配完结'
  }
};

/**
 * 导出数据映射
 */
const EXPORTTEMPLATE_OBJ = {
  '物流单号': 'trackingNo',
  '订单号': 'orderNo',
  '重量(kg)': 'weight'
};

const RADIOOB = {
  'packaged': {
    name: '已打包',
    code: 'packaged'
  },
  'printed': {
    name: '已封袋',
    code: 'printed'
  },
  'fited': {
    name: '已配载',
    code: 'fited'
  },
  'unpacked': {
    name: '未打包',
    code: 'unpacked'
  },
  'have_air_bill_no': {
    name: '已提单',
    code: 'have_air_bill_no'
  }
};

const DOWNSTREAM = {
  'single_pkg': {
    code: 'single_pkg',
    text: '单个打包'
  },
  'batch_pkg': {
    code: 'batch_pkg',
    text: '批量打包'
  },
  'select_downstream': {
    code: 'select_downstream',
    text: '选择下家'
  },
  'need_select_downstream': {
    code: 'need_select_downstream',
    text: '需要选择下家'
  },
  'get_downstream': {
    code: 'get_downstream',
    text: '获取下家'
  }
};

/**
 * 导出数据映射
 */
const EXPORTTEMPLATEOBJ = {
  '物流单号': 'trackingNo',
  '订单号': 'orderNo',
  '重量(kg)': 'weight'
};

/**
 * 总单类型
 */
const TOTALLISTTYPE = {
  normal: {
    code: 'normal',
    txt: '普货'
  },
  battery: {
    code: 'battery',
    txt: '纯电'
  }
};

/**
 * 快递类型
 */
const TRACKING_NO_TYPE = {
  'inner': {
    code: 'inner',
    txt: '内单号'
  },
  'reference': {
    code: 'reference',
    txt: '参考号'
  },
  'transfer': {
    code: 'transfer',
    txt: '转单号'
  }
};

/**
 * 语言映射
 */
const LANGUAGE_OBJ = {
  zh: {
    code: 'zh-CN',
    txt: '中文',
    name: 'zh'
  },
  // en: {
  //   code: 'en-US',
  //   txt: 'English'
  // },
  pu: {
    // 葡萄牙-巴西
    code: 'pt-BR',
    txt: 'Português',
    name: 'pu'
  }

};

export {
  WS_TYPE,
  RES_TYPE,
  DOWNLOAD_OBJ,
  DOWNSTREAM_OBJ,
  EXPORTTEMPLATE_OBJ,
  RADIOOB,
  DOWNSTREAM,
  EXPORTTEMPLATEOBJ,
  TOTALLISTINFOOBJ,
  TOTALLISTTYPE,
  TRACKING_NO_TYPE,
  LANGUAGE_OBJ
};

