
// 字典
const dictionary = {
  ModificationSuccessful: {
    zh: '修改成功',
    en: 'Modification successful',
    pu: 'Modificação bem sucedida'
  },
  pleaseChoose: {
    zh: '请选择',
    en: 'Please select',
    pu: 'Favor selecionar'
  },
  pleaseInput: {
    zh: '请输入',
    en: 'Please enter the content',
    pu: 'por favor digite'
  },
  inputNum: {
    zh: '请输入数字',
    en: '',
    pu: 'Favor digitar o número'
  },
  notNull: {
    zh: '不能为空',
    en: 'Cannot be empty',
    pu: 'Não pode estar vazio'
  },
  dictionaryList: {
    zh: '字典列表',
    en: '',
    pu: 'lista do dicionário'
  },
  dictionaryCode: {
    zh: '字典代码：',
    en: '',
    pu: 'código de dicionário'
  },
  dictionaryValue: {
    zh: '字典值：',
    en: '',
    pu: 'valor do dicionário'
  },
  search: {
    zh: '查询',
    en: '',
    pu: 'consultar'
  },
  reset: {
    zh: '重置',
    en: 'reset',
    pu: 'Redefinir'
  },
  dictionaryType: {
    zh: '字典类型',
    en: '',
    pu: 'tipo do dicionário'
  },
  dictionaryT: {
    zh: '字典类型：',
    en: '',
    pu: 'tipo do dicionário: '
  },
  dictionaryC: {
    zh: '字典代码',
    en: '',
    pu: 'código do dicionário'
  },
  dictionaryName: {
    zh: '字典名称',
    en: '',
    pu: 'nome do dicionário'
  },
  dictionaryN: {
    zh: '字典名称：',
    en: '',
    pu: 'nome do dicionário: '
  },
  dictionaryV: {
    zh: '字典值',
    en: '',
    pu: 'valor do dicionário'
  },
  CreationTime: {
    zh: '创建时间',
    en: 'Creation time',
    pu: 'tempo de criação'
  },
  CreationT: {
    zh: '创建时间：',
    en: 'Creation time: ',
    pu: 'tempo de criação: '
  },
  ModificationTime: {
    zh: '修改时间',
    en: 'Modification time',
    pu: 'Tempo de modificação'
  },
  ModificationT: {
    zh: '修改时间：',
    en: 'Modification time: ',
    pu: 'Tempo de modificação: '
  },
  DictionaryMultilingualList: {
    zh: '字典多语言列表',
    en: '',
    pu: 'Lista multilíngue de dicionário'
  },
  DictionaryEnumerationValue: {
    zh: '字典枚举值：',
    en: '',
    pu: 'Valor de enumeração do dicionário:'
  },
  language: {
    zh: '语言：',
    en: '',
    pu: 'idioma'
  },
  add: {
    zh: '新增',
    en: 'add',
    pu: 'adicionar'
  },
  modify: {
    zh: '修改',
    en: 'modify',
    pu: 'modificar'
  },
  newAddSuc: {
    zh: '新增成功',
    en: '',
    pu: 'adicionar com sucesso'
  },
  DictionaryEnumerationV: {
    zh: '字典枚举值',
    en: '',
    pu: 'Valor de enumeração do dicionário:'
  },
  yuyan: {
    zh: '语言',
    en: '',
    pu: 'idioma'
  },
  describe: {
    zh: '描述',
    en: '',
    pu: 'descrição'
  },
  description: {
    zh: '描述：',
    en: '',
    pu: 'descrição: '
  },
  operation: {
    zh: '操作',
    en: '',
    pu: 'Operar'
  },
  edit: {
    zh: '编辑',
    en: '',
    pu: 'editar'
  },
  submit: {
    zh: '提交',
    en: '',
    pu: 'submeter'
  },
  detail: {
    zh: '详情',
    en: 'detail',
    pu: 'detalhe'
  },
  pleaseInputNumber: {
    zh: '请输入数字类型',
    en: '',
    pu: 'Por favor, digite o tipo de número'
  }

};
export {
  dictionary
};
