<template>
  <div class="Box">
    <div style="margin-bottom: 20px;">
      <el-form class="findClass">
        <el-row>
          <el-col :span="6" style="width: auto;">
            <!-- 所属柜子编号： -->
            <el-form-item :label="$t('collectionCenter.belongToCabinet')">
              <el-select
                v-model="queryForm.params.lockerId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                @change="getLockerId(queryForm.params.lockerId)" @focus="getLocker"
              >
                <el-option
                  v-for="(item, index) in cabinetList"
                  :key="index"
                  :label="item.number"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <!-- 格子编号： -->
            <el-form-item :label="$t('collectionCenter.gridNum')">
              <el-select
                v-model="queryForm.params.latticeNumberList" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                multiple filterable @focus="isLatticeNumber(queryForm.params.lockerId)"
              >
                <el-option
                  v-for="(item, index) in latticeList"
                  :key="index"
                  :label="item.latticeNumber"
                  :value="item.latticeNumber"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <!-- 业务类型： -->
            <el-form-item :label="$t('collectionCenter.businessT') + '：'">
              <el-select v-model="queryForm.params.businessType" :placeholder="$t('collectionCenter.pleaseChoose')" clearable>
                <el-option :label="$t('collectionCenter.collectParcel')" :value="1" /><!-- 取件 -->
                <el-option :label="$t('collectionCenter.delivery')" :value="2" /><!-- 派件 -->
                <el-option :label="$t('collectionCenter.mixed')" :value="3" /><!-- 混合 -->
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <!-- 格子规格： -->
            <el-form-item :label="$t('collectionCenter.latticeS')">
              <el-select v-model="queryForm.params.latticeNorm" :placeholder="$t('collectionCenter.pleaseChoose')" clearable>
                <el-option :label="$t('collectionCenter.oversized')" :value="4" /><!-- 超大 -->
                <el-option :label="$t('collectionCenter.big')" :value="3" /><!-- 大 -->
                <el-option :label="$t('collectionCenter.middle')" :value="2" /><!-- 中 -->
                <el-option :label="$t('collectionCenter.small')" :value="1" /><!-- 小 -->
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="24" style="margin-left: 20px">
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
          <el-button
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="addClick"
          >{{ $t('collectionCenter.add') }}</el-button><!-- 新增 -->
        </el-col>
      </el-row>
    </div>
    <div>
      <el-table
        ref="tableRef"
        border
        :max-height="600"
        :data="tableData"
      >
        <el-table-column
          prop="latticeNumber"
          align="center"
          :label="$t('collectionCenter.gridNumber')"
        /><!-- 格子编号 -->
        <el-table-column
          prop="businessType"
          align="center"
          :label="$t('collectionCenter.businessT')"
        ><!-- 业务类型 -->
          <template slot-scope="scope">
            <div>
              <!-- 1：取件，2：派件，3：混合 -->
              {{
                scope.row.businessType == 1 ? $t('collectionCenter.collectParcel') :
                scope.row.businessType == 2 ? $t('collectionCenter.delivery') :
                scope.row.businessType == 3 ? $t('collectionCenter.mixed') : ''
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="lockerNumber"
          align="center"
          :label="$t('collectionCenter.belongToCabinetNum')"
        /><!-- 所属柜子编号 -->
        <el-table-column
          prop="latticeNorm"
          align="center"
          :label="$t('collectionCenter.latticeSize')"
        ><!-- 格子规格 -->
          <template slot-scope="scope">
            <div>
              <!-- 1:小,2:中,3:大,4:超大 -->
              {{
                scope.row.latticeNorm == 1 ? $t('collectionCenter.small') :
                scope.row.latticeNorm == 2 ? $t('collectionCenter.middle') :
                scope.row.latticeNorm == 3 ? $t('collectionCenter.big') :
                scope.row.latticeNorm == 4 ? $t('collectionCenter.oversized') : ''
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="remark"
          align="center"
          :label="$t('collectionCenter.Note')"
        /><!-- 备注 -->
        <el-table-column
          align="center"
          prop="createUserStr"
          :label="$t('collectionCenter.creator')"
        /><!-- 创建人 -->
        <el-table-column
          align="center"
          prop="createTime"
          :label="$t('collectionCenter.CreationTime')"
        /><!-- 创建时间 -->
        <el-table-column
          align="center"
          prop="updateUserStr"
          :label="$t('collectionCenter.ModifiedBy')"
        /><!-- 修改人 -->
        <el-table-column
          align="center"
          prop="updateTime"
          :label="$t('collectionCenter.ModificationTime')"
        /><!-- 修改时间 -->
        <el-table-column
          :label="$t('operatingPlatform.operation')"
          fixed="right"
          align="center"
        ><!-- 操作 -->
          <template slot-scope="scope">
            <el-button
              style="margin-right: 5px;"
              size="mini"
              @click="editClick(scope.row)"
            >{{ $t('collectionCenter.modify') }}</el-button><!-- 修改 -->
            <el-popconfirm
              :title="$t('collectionCenter.deleteSure')"
              @confirm="delClick(scope.row)"
            ><!-- 确定删除吗？ -->
              <el-button slot="reference" type="danger" size="mini">{{ $t('collectionCenter.Delete') }}</el-button><!-- 删除 -->
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getPage"
        @size-change="searchClick"
      />
    </div>

    <div v-if="drawerIsOpen">
      <el-drawer
        :title="drawerTitle"
        :visible.sync="drawerIsOpen"
        :direction="direction"
        size="40%"
        @close="closeDrawer"
      >
        <div class="drawerClass">
          <el-form
            ref="drawerFormRef" :model="drawerForm" :rules="rules"
            label-position="top"
          >
            <el-row type="flex" justify="space-between">
              <el-col :span="11">
                <!-- 所属柜子编号： -->
                <el-form-item prop="lockerId" :label="$t('collectionCenter.belongToCabinet')">
                  <el-select
                    v-model="drawerForm.lockerId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                    filterable @change="getLockerId(drawerForm.lockerId)" @focus="getLocker"
                  >
                    <el-option
                      v-for="(item, index) in cabinetList"
                      :key="index"
                      :label="item.number"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <!-- 格子编号： -->
                <el-form-item prop="latticeNumberList" :label="$t('collectionCenter.gridNum')">
                  <div>
                    <el-input v-model="drawerForm.latticeNumberList" :placeholder="$t('collectionCenter.pleaseNumber')" clearable />
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" justify="space-between">
              <el-col :span="11">
                <!-- 业务类型： -->
                <el-form-item prop="businessType" :label="$t('collectionCenter.businessT') + '：'">
                  <el-select
                    v-model="drawerForm.businessType" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                    filterable
                  >
                    <el-option :label="$t('collectionCenter.collectParcel')" :value="1" /><!-- 取件 -->
                    <el-option :label="$t('collectionCenter.delivery')" :value="2" /><!-- 派件 -->
                    <el-option :label="$t('collectionCenter.mixed')" :value="3" /><!-- 混合 -->
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <!-- 格子规格： -->
                <el-form-item prop="latticeNorm" :label="$t('collectionCenter.latticeS')">
                  <el-select
                    v-model="drawerForm.latticeNorm" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                    filterable
                  >
                    <el-option :label="$t('collectionCenter.oversized')" :value="4" /><!-- 超大 -->
                    <el-option :label="$t('collectionCenter.big')" :value="3" /><!-- 大 -->
                    <el-option :label="$t('collectionCenter.middle')" :value="2" /><!-- 中 -->
                    <el-option :label="$t('collectionCenter.small')" :value="1" /><!-- 小 -->
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <!-- 备注： -->
                <el-form-item :label="$t('collectionCenter.note')">
                  <div class="textarea">
                    <el-input
                      v-model="drawerForm.remark"
                      type="textarea"
                      autosize
                      maxlength="250"
                      show-word-limit
                      placeholder=""
                    />
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div class="submitClickBtn">
            <el-button type="success" @click="save">{{ $t('collectionCenter.Save') }}</el-button><!-- 保存 -->
            <el-button @click="cancel">{{ $t('collectionCenter.cancel') }}</el-button><!-- 取消 -->
          </div>
        </div>
      </el-drawer>
    </div>

  </div>
</template>

<script>
import {
  queryDoor,
  queryLocker,
  addDoor,
  editDoor,
  delDoor,
  doorList
} from '@/api/smartCabinet';
import { getUserName } from '@/utils/asyncTools';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
export default {
  data() {
    return {
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          latticeNumberList: [], // 格子编号
          businessType: '', // 业务类型
          lockerId: '', // 柜子id
          latticeNorm: '' // 格子规格:1:小,2:中,3:大,4:超大
        }
      },
      latticeList: [], // 格子编号list
      cabinetList: [], // 所属柜子编号list
      tableData: [{}],
      total: 0,
      userIdAndNameObj: null,
      drawerIsOpen: false,
      drawerTitle: '',
      direction: 'ltr',
      drawerForm: {},
      rules: {
        latticeNumberList: [
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              const reg = /^(([0-9](,)?)*)+$/;
              if (!reg.test(value)) {
                callback(new Error(this.$t('collectionCenter.pleaseNumber'))); // '请填写数字，多个值用逗号分隔'
              } else {
                callback();
              }
            }
          }
        ],
        businessType: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        lockerId: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        latticeNorm: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }]
      }
    };
  },
  created() {
    this.getLocker();
    this.userIdAndNameObj = getUserIdAndNameObj();
    this.searchClick();
    this.openDrawer();
  },
  methods: {
    // 打开窗口调用并赋值
    openDrawer() {
      let locker = '';
      if (this.drawerTitle === this.$t('collectionCenter.modify')) {
        locker = this.drawerForm.lockerId;
      }
      const gez = {
        lockerId: locker
      };
      doorList(gez).then(res => {
        if (res.status === 'OK') {
          this.latticeList = [];
          this.latticeList = res.data.map(item => {
            return {
              latticeNumber: item.latticeNumber
            };
          });
        }
      }).catch({});
    },
    // 所属柜子编号list
    getLocker() {
      const lq = {
        lockerNumber: ''
      };
      queryLocker(lq).then(res => {
        this.cabinetList = res.data.map(item => {
          return {
            id: item.id,
            number: item.lockerNumber
          };
        });
      }).catch({});
    },
    isLatticeNumber(lockerId) {
      // 先选柜子再选格子
      if (!lockerId) {
        this.$message({
          message: this.$t('collectionCenter.selectCabinet'), // 请选择柜子
          type: 'warning'
        });
      }
    },
    getLockerId(lockerId) {
      // 选柜子时调用当前柜子的格子
      this.getDoorList(lockerId);
    },
    // 格子编号list
    getDoorList(lockerId) {
      if (this.drawerTitle === this.$t('collectionCenter.modify')) {
        this.drawerForm.latticeNumberList = '';
      }
      this.queryForm.params.latticeNumberList = [];
      const gez = {
        lockerId
      };
      doorList(gez).then(res => {
        if (res.status === 'OK') {
          this.latticeList = [];
          this.latticeList = res.data.map(item => {
            return {
              latticeNumber: item.latticeNumber
            };
          });
        }
      }).catch({});
    },
    searchClick() {
      this.queryForm.pageNumber = 1;
      this.getPage();
    },
    resetClick() {
      this.queryForm.params = {
        latticeNumberList: [], // 格子编号
        businessType: '', // 业务类型
        lockerId: '', // 柜子id
        latticeNorm: '' // 格子规格:1:小,2:中,3:大,4:超大
      };
    },
    addClick() {
      this.drawerIsOpen = true;
      this.drawerTitle = this.$t('collectionCenter.add'); // '新增'
    },
    getPage() {
      queryDoor(this.queryForm).then(res => {
        if (res.status === 'OK') {
          this.total = res.data.total;
          this.tableData = res.data.records.map(item => {
            const updateUserStr = getUserName(item.updateUser, this.userIdAndNameObj);
            const createUserStr = getUserName(item.createUser, this.userIdAndNameObj);
            return {
              ...item,
              updateUserStr,
              createUserStr
            };
          });
        } else {
          this.total = 0;
          this.tableData = [];
        }
      }).catch({});
    },
    editClick(row) {
      this.drawerForm = {
        id: row.id,
        latticeNumberOld: row.latticeNumber, // 格子编号
        lockerId: row.lockerId,
        latticeNorm: row.latticeNorm,
        businessType: row.businessType,
        remark: row.remark,
        latticeNumberList: row.latticeNumber
      };
      this.drawerIsOpen = true;
      this.drawerTitle = this.$t('collectionCenter.modify'); // '修改'
      this.openDrawer();
    },
    delClick(row) {
      const dataForm = {
        latticeNumberOld: row.latticeNumber,
        lockerNumber: row.lockerNumber,
        latticeNorm: row.latticeNorm,
        businessType: row.businessType,
        remark: row.remark
      };
      delDoor(row.id, dataForm).then(res => {
        if (res.status === 'OK') {
          this.searchClick();
          this.$message({
            message: this.$t('operationCenter.DeleteSucceeded'), // '删除成功'
            type: 'success'
          });
        }
      }).catch({});
    },

    // 新增 、 修改
    save() {
      this.$refs['drawerFormRef'].validate(valid => {
        if (!valid) {
          return;
        } else {
          const drawerForm = {
            id: this.drawerForm.id,
            latticeNumberOld: this.drawerForm.latticeNumberOld,
            lockerId: this.drawerForm.lockerId,
            latticeNorm: this.drawerForm.latticeNorm,
            businessType: this.drawerForm.businessType,
            remark: this.drawerForm.remark,
            latticeNumberList: this.drawerForm.latticeNumberList.toString().split(',')
          };
          if (this.drawerTitle === this.$t('collectionCenter.add')) {
            addDoor(drawerForm).then(res => {
              if (res.status === 'OK') {
                this.cancel();
                this.searchClick();
                this.$message({
                  message: this.$t('dictionary.newAddSuc'), // '新增成功'
                  type: 'success'
                });
              }
            }).catch({});
          }
          if (this.drawerTitle === this.$t('collectionCenter.modify')) {
            editDoor(drawerForm).then(res => {
              if (res.status === 'OK') {
                this.cancel();
                this.searchClick();
                this.$message({
                  message: this.$t('dictionary.ModificationSuccessful'), // '修改成功'
                  type: 'success'
                });
              }
            }).catch({});
          }
        }
      });
    },
    closeDrawer() {
      this.cancel();
    },
    cancel() {
      this.drawerForm = {
        latticeNumberList: '',
        lockerId: '',
        latticeNorm: '',
        businessType: '',
        remark: ''
      };
      this.$refs['drawerFormRef'].clearValidate();
      this.drawerIsOpen = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.drawerClass ::v-deep .el-select{
  width: 100%;
}
.submitClickBtn{
  text-align: end;
  margin-top: 30px;
}
.textarea ::v-deep textarea.el-textarea__inner {
  min-height: 40px !important;
  max-height: 200px !important;
}
.drawerClass{
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 50px;
}
.drawerClass ::v-deep .el-textarea .el-input__count{
  background: transparent;
  bottom: -9px;
  right: 18px;
}
.findClass ::v-deep .el-form-item{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.findClass ::v-deep .el-form-item__label{
  padding: 0;
  line-height: 16px;
  white-space: nowrap;
  margin-left: 20px;
}
.findClass ::v-deep .el-form-item__content{
  margin-left: 0 !important;
  width: 100%;
}

.Box{
  padding: 20px;
  box-sizing: border-box;
}
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
