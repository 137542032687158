<template>
  <div class="app-container">
    <div class="tip01">{{ $t('placeOrder.automaticPrinting1') }}
      <el-link
        href="https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/software/安骏桌面插件/安骏桌面插件.exe"
        style="font-size: 16px"
        type="warning"
      >{{ $t('placeOrder.automaticPrinting2') }}
      </el-link>
      {{ $t('placeOrder.automaticPrinting3') }}
    </div>
    <el-radio-group v-model="radio2" size="medium" @change="changeRadio">
      <el-radio-button label="1">{{ $t('GlobalSetObj.autoPrint') }}</el-radio-button>
      <el-radio-button label="2">{{ $t('GlobalSetObj.mualPrint') }}</el-radio-button>
    </el-radio-group>
    <el-form
      :inline="false" label-position="top" size="small"
      style="margin-top: 10px;" @submit.native.prevent
    >
      <el-row :gutter="20">
        <el-col v-if="radio2 === '1'" :span="6">
          <!--  扫描袋号   -->
          <el-form-item :label="$t('collectionCenter.scanningBag')" prop="inputValue">
            <el-input
              ref="inputRef"
              v-model.trim="printObj.BagNo"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              clearable
              style="width:100%"
              @keyup.enter.native="scannerBagNo"
            />
          </el-form-item>
        </el-col>
        <div v-else>
          <el-col :span="6">
            <!--  选择网点   -->
            <el-form-item :label="$t('collectionCenter.SelectNet')">
              <el-select
                v-model="printObj.siteName"
                clearable
                filterable
                style="width:100%"
                @change="handleSiteId"
              >
                <el-option
                  v-for="item in siteData"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <!--  打印分数   -->
            <el-form-item :label="$t('collectionCenter.PrintCopies')" prop="inputValue">
              <el-input
                v-model.trim="printObj.printNum"
                :placeholder="$t('GlobalSetObj.pleaseInput')"
                style="width:100%"
                @input="handlePrintNum"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item style="margin-top: 28px">
              <!-- 打印 -->
              <el-button size="small" type="primary" @click="clickPrint()">{{
                $t('GlobalSetObj.Print')
              }}
              </el-button>
            </el-form-item>
          </el-col>
        </div>
      </el-row>
    </el-form>
    <div class="titleClass">{{ $t('collectionCenter.printRecord') }}</div>
    <!--  表格数据  -->
    <el-table
      :cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      :header-cell-style="{background:'#f2f2f2',color:'black','text-align': 'center'}"
      border
      style="width: 100%"
    >
      <el-table-column
        :label="$t('collectionCenter.printTime')"
        min-width="180"
        prop="createTime"
      />
      <el-table-column
        :label="$t('GlobalSetObj.Outlets')"
        min-width="180"
        prop="siteName"
      />
      <el-table-column
        :label="$t('collectionCenter.printRecord')"
        min-width="150"
        prop="printCount"
      />
      <el-table-column
        :label="$t('GlobalSetObj.operator')"
        min-width="150"
        prop="operatorUser"
      />
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.number"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!--打印大包号-->
    <div class="printStyle">
      <printBagNumberHtml
        v-if="showPrintBagNumberHtml"
        :param-data="printBagObj"
        @hiddenBagNumberHtml="hiddenBagNumberHtml"
      />
    </div>
    <!-- 隐藏提示音 -->
    <div v-show="false">
      <audio ref="errRef" :src="errorMp3" controls="controls" />
      <audio ref="okRef" :src="okMp3" controls="controls" />
    </div>
  </div>
</template>

<script>
import printBagNumberHtml from './printBagNumber';
import { getPrintBagNumber, printBagNumberApi } from '@/api/storeAdmin';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { getUserName } from '@/utils/asyncTools';
import { getAllsite } from '@/api/logisticsOrders';
import { automaticPrintingBagNumber } from '@/api/automaticSorting';
import { apiStartUp, autoPrint } from '@/api/serialPort';
import dayjs from 'dayjs';

const okMp3 = 'https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/static/music/su.wav';
const errorMp3 = 'https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/static/music/print_error.wav';
export default {
  name: 'PackagePrinting',
  components: {
    printBagNumberHtml
  },
  data() {
    return {
      // 是否格式化成巴西时间
      showPrintBagNumberHtml: false,
      printBagObj: [],
      isTormat: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      timeValue: [],
      queryForm: {
        pageSize: 10,
        number: 1,
        total: 0
      },
      printObj: {
        printNum: null,
        siteName: null,
        BagNo: null
      },
      siteData: [], // 网点
      tableData: [],
      userIdAndNameObj: null,
      selectSiteId: null,
      selectSiteName: null,
      selectProviderId: null,
      selectProviderName: null,
      siteObj: {},
      radio2: '1',
      okMp3,
      errorMp3
    };
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.userIdAndNameObj = getUserIdAndNameObj();
    this.getAllSite01();
    this.getSearch();
  },
  methods: {
    changeRadio() {
      this.printObj = {
        printNum: null,
        siteName: null,
        BagNo: null
      };
    },
    scannerBagNo() {
      if (this.printObj.BagNo) {
        const _this = this;
        const data = {
          bagNumber: this.printObj.BagNo
        };
        automaticPrintingBagNumber(data).then(res => {
          if (res.status === 'OK') {
            apiStartUp().then((res1) => {
              if (res1.code === 200) {
                const loading = this.$loading({
                  fullscreen: true,
                  lock: true,
                  spinner: 'el-icon-loading',
                  text: 'loading...',
                  background: 'rgba(250, 250, 250, 0.6)'
                });
                autoPrint(res.data).then(async(resPrint) => {
                  loading.close();
                  if (resPrint.code === 200) {
                    await this.$refs.okRef.play();
                    this.$message.success(this.$t('collectionCenter.printSuc'));
                    _this.printObj.BagNo = '';
                    _this.$nextTick(_ => {
                      _this.$refs.inputRef.$refs.input.focus();
                    });
                    _this.getSearch();
                  }
                }).catch(async(e) => {
                  loading.close();
                  await this.$refs.errRef.play();
                  this.$message.error(this.$t('GlobalSetObj.PrintFailure'));
                  // this.ruleForm.SingleNumber = '';
                  _this.$nextTick(_ => {
                    _this.$refs.inputRef.$refs.input.select();
                  });
                }).finally(() => {
                  loading.close();
                });
              }
            }).catch(() => {
              this.$refs.errRef.play();
              this.$message.error(this.$t('GlobalSetObj.setDefaultPrinter'));
              _this.$nextTick(_ => {
                _this.$refs.inputRef.$refs.input.select();
              });
            });
          }
        }).catch(() => {
          this.$nextTick(_ => {
            this.$refs.inputRef.$refs.input.select();
          });
        });
      } else {
        this.getSearch();
        // return false;
      }
    },
    handlePrintNum(val) {
      const pattern = /^[1-9]\d*$/;
      if (!pattern.test(val)) {
        this.printObj.printNum = '';
      }
    },
    // 清空打印袋号数据
    hiddenBagNumberHtml() {
      this.showPrintBagNumberHtml = false;
      this.resetForm();
      this.enterSearch();
    },
    enterSearch() {
      this.queryForm.number = 1;
      this.getSearch();
    },
    // 点击打印
    clickPrint() {
      if (!this.printObj.siteName && !this.printObj.gridNum) {
        this.$message.error(this.$t('collectionCenter.pleaseCheckSite'));
        return;
      }
      if (!this.printObj.printNum) {
        this.$message.error(this.$t('collectionCenter.inputcopies'));
        return;
      }
      if (this.printObj.printNum > 20) {
        this.$message.error(this.$t('collectionCenter.more20'));
        return;
      }
      const data = {
        siteName: this.selectSiteName,
        siteId: this.selectSiteId,
        printNum: this.printObj.printNum,
        providerId: this.selectProviderId,
        providerName: this.selectProviderName
      };
      printBagNumberApi(data).then(res => {
        if (res.status === 'OK') {
          this.printBagObj = [];
          for (let i = 0; i < this.printObj.printNum; i++) {
            this.printBagObj.push(
              {
                id: i,
                threeCode: this.siteObj.name,
                departmentCode: this.siteObj.departmentCode,
                providerName: this.siteObj.providerName,
                deliverSiteName: this.siteObj.code,
                siteAddress: this.siteObj.address,
                baseCodeName: this.siteObj.baseCodeName,
                time: dayjs().format('DD-MM-YYYY'),
                timeSs: dayjs().format('HH:mm:ss')
              }
            );
          }
          this.showPrintBagNumberHtml = true;
          console.log(res.data, '打印');
        }
      });
    },
    handleSiteId(val) {
      console.log(val, 'LKLK');
      if (val) {
        const obj = this.siteData.find(item => item.id === val);
        this.siteObj = obj;
        if (
          this.siteObj.baseCode && // 确保 baseCode 存在
          this.siteObj.baseCode.length === 3 && // 确保 baseCode 的长度是 3
          /^[a-zA-Z]{3}$/.test(this.siteObj.baseCode) // 使用正则表达式测试 baseCode 是否仅包含3个字母
        ) {
          this.siteObj.baseCodeName = this.siteObj.baseCode;
        }
        this.selectSiteId = obj.id;
        this.selectSiteName = obj.name;
        this.selectProviderId = obj.providerId;
        this.selectProviderName = obj.providerName;
        console.log(obj, 'SLKDLF');
      } else {
        this.selectSiteId = null;
        this.selectSiteName = null;
        this.selectProviderId = null;
        this.selectProviderName = null;
        this.siteObj = {};
      }
    },
    // 重置
    resetForm() {
      this.printBagObj = [];
      this.printObj = {
        printNum: null,
        siteName: null
      };
      this.selectSiteId = null;
      this.selectSiteName = null;
      this.selectProviderId = null;
      this.selectProviderName = null;
      this.siteObj = {};
    },
    // 查询
    getSearch() {
      const data = {
        params: {}
      };
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.number;
      getPrintBagNumber(data).then(res => {
        if (res.status === 'OK') {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
          this.tableData.forEach((item) => {
            item.operatorUser = getUserName(item.createUser, this.userIdAndNameObj);
          });
          console.log(this.tableData, 'this.tableDatathis.tableData');
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.number = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.number = val;
      this.getSearch();
    },
    // 获取对应的供应商下的网点
    // 获取所有网点
    getAllSite01() {
      this.siteData = [];
      getAllsite().then(res => {
        if (res.status === 'OK') {
          console.log('获取所有网点', res.data);
          this.siteData = res.data;
        }
      });
    }
  }
};
</script>

<style scoped>
.titleClass {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.printStyle {
  display: none;
}

.app-container >>> .el-alert {
  background-color: #ecf5ff;
  color: #409eff;
}

.tip01 {
  background-color: #ecf5ff;
  color: #409eff;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 15px;
}
</style>
