var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      staticClass: "demo-ruleForm",
      attrs: { model: _vm.ruleForm, rules: _vm.rules, "label-position": "top" },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("routes.选择班次"), prop: "scheduleNumber" } },
        [
          _c("ScheduleNumberSelect", {
            attrs: { list: _vm.scheduleNumberList },
            model: {
              value: _vm.ruleForm.scheduleNumber,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "scheduleNumber", $$v)
              },
              expression: "ruleForm.scheduleNumber",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("routes.发车时间"), prop: "departureTime" } },
        [
          _c("el-time-picker", {
            attrs: {
              placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
              format: "HH:mm",
              "value-format": "HH:mm",
            },
            model: {
              value: _vm.ruleForm.departureTime,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "departureTime", $$v)
              },
              expression: "ruleForm.departureTime",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("routes.下一站流向"),
            prop: "nextSiteIdList",
            rules: _vm.rules.scheduleNumber,
          },
        },
        [
          _c("NextSiteIdListSelect", {
            attrs: { list: _vm.nextSiteIdList },
            model: {
              value: _vm.ruleForm.nextSiteIdList,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "nextSiteIdList", $$v)
              },
              expression: "ruleForm.nextSiteIdList",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("ruleForm")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("customerManagements.Save")))]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.onClose(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }