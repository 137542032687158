var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "wrapper-form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        },
      },
    },
    [
      _c(
        "o-field",
        { attrs: { label: _vm.$t("seller.seller"), "label-for": "seller" } },
        [
          _c("o-input", {
            attrs: { id: "seller", disabled: "" },
            model: {
              value: _vm.sellerNameAndCode,
              callback: function ($$v) {
                _vm.sellerNameAndCode = $$v
              },
              expression: "sellerNameAndCode",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        { attrs: { label: _vm.$t("seller.client"), "label-for": "customer" } },
        [
          _c("o-input", {
            attrs: { id: "customer", disabled: "" },
            model: {
              value: _vm.customerNameAndCode,
              callback: function ($$v) {
                _vm.customerNameAndCode = $$v
              },
              expression: "customerNameAndCode",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          directives: [
            {
              name: "mask",
              rawName: "v-mask",
              value: ["+## (##) ####-####", "+## (##) #####-####"],
              expression: "['+## (##) ####-####', '+## (##) #####-####']",
            },
          ],
          attrs: {
            label: _vm.$t("seller.phone"),
            "label-for": "phone",
            message: _vm.v$.phone.$errors.length
              ? _vm.v$.phone.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "phone",
              placeholder: _vm.$t("seller.phonePlaceholder"),
            },
            model: {
              value: _vm.formData.phone,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "phone", $$v)
              },
              expression: "formData.phone",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("seller.email"),
            "label-for": "email",
            message: _vm.v$.email.$errors.length
              ? _vm.v$.email.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "email",
              placeholder: _vm.$t("seller.emailPlaceholder"),
            },
            model: {
              value: _vm.formData.email,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "email", $$v)
              },
              expression: "formData.email",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          directives: [
            {
              name: "mask",
              rawName: "v-mask",
              value: ["###.###.###-##", "##.###.###/####-##"],
              expression: "['###.###.###-##', '##.###.###/####-##']",
            },
          ],
          attrs: {
            label: _vm.$t("seller.document"),
            "label-for": "document",
            message: _vm.v$.document.$errors.length
              ? _vm.v$.document.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "document",
              placeholder: _vm.$t("seller.documentPlaceholder"),
            },
            model: {
              value: _vm.formData.document,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "document", $$v)
              },
              expression: "formData.document",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("seller.nameContact"),
            "label-for": "nameContact",
            message: _vm.v$.nameContact.$errors.length
              ? _vm.v$.nameContact.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "nameContact",
              placeholder: _vm.$t("seller.nameContactPlaceholder"),
            },
            model: {
              value: _vm.formData.nameContact,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "nameContact", $$v)
              },
              expression: "formData.nameContact",
            },
          }),
        ],
        1
      ),
      _c(
        "FormActions",
        [
          _c("FormActionsPrevious", {
            attrs: { "is-first-step": _vm.isFirstStep },
            on: { handlePrevious: _vm.handlePrevious },
          }),
          _c("FormActionsNext", { attrs: { "is-last-step": _vm.isLastStep } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }