var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: "btn:supplierManagement:VendorPermissions:add",
                  expression: "'btn:supplierManagement:VendorPermissions:add'",
                  arg: "remove",
                },
              ],
              attrs: {
                size: "small",
                type: "primary",
                disabled: _vm.selectData === null,
              },
              on: {
                click: function ($event) {
                  return _vm.addPermissions(1)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("organizationManage.addSameLevel")))]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: "btn:supplierManagement:VendorPermissions:add",
                  expression: "'btn:supplierManagement:VendorPermissions:add'",
                  arg: "remove",
                },
              ],
              attrs: {
                size: "small",
                type: "primary",
                disabled: _vm.selectData === null,
              },
              on: {
                click: function ($event) {
                  return _vm.addPermissions(2)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("organizationManage.addNextLevel")))]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: "btn:supplierManagement:VendorPermissions:edit",
                  expression: "'btn:supplierManagement:VendorPermissions:edit'",
                  arg: "remove",
                },
              ],
              attrs: {
                size: "small",
                type: "primary",
                disabled: _vm.selectData === null,
              },
              on: {
                click: function ($event) {
                  return _vm.editPermissions()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("GlobalSetObj.edit")))]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: "btn:supplierManagement:VendorPermissions:delete",
                  expression:
                    "'btn:supplierManagement:VendorPermissions:delete'",
                  arg: "remove",
                },
              ],
              attrs: {
                size: "small",
                type: "danger",
                disabled: _vm.selectData === null,
              },
              on: {
                click: function ($event) {
                  return _vm.deletePermissions()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Delete")))]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "treeNode" },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "border-card" },
              on: { "tab-click": _vm.handleClickPermissions },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "PC", name: "pc" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      height: "calc(100vh - 220px)",
                      overflow: "auto",
                    },
                  },
                  [
                    _c("el-tree", {
                      key: _vm.treeKey,
                      attrs: {
                        "node-key": "id",
                        "default-expand-all": "",
                        "highlight-current": "",
                        data: _vm.permissionPcList,
                        "expand-on-click-node": false,
                        props: _vm.defaultRole,
                      },
                      on: { "current-change": _vm.getPcClickNode },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var node = ref.node
                            var data = ref.data
                            return _c(
                              "span",
                              { staticClass: "custom-tree-node mb" },
                              [
                                _c("span", { staticClass: "titlts" }, [
                                  data.parentId === 0
                                    ? _c("i", {
                                        staticClass:
                                          "iconfont icon-folder-open",
                                      })
                                    : _c("i", {
                                        staticClass: "iconfont icon-wenjian",
                                      }),
                                  _vm._v(" " + _vm._s(data.name) + " "),
                                ]),
                              ]
                            )
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]),
              _c("el-tab-pane", { attrs: { label: "PDA", name: "pda" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      height: "calc(100vh - 220px)",
                      overflow: "auto",
                    },
                  },
                  [
                    _c("el-tree", {
                      key: _vm.treeKey,
                      attrs: {
                        "node-key": "id",
                        "default-expand-all": "",
                        "highlight-current": "",
                        data: _vm.permissionPadList,
                        "expand-on-click-node": false,
                        props: _vm.defaultRole,
                      },
                      on: { "current-change": _vm.getPadClickNode },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var node = ref.node
                            var data = ref.data
                            return _c(
                              "span",
                              { staticClass: "custom-tree-node mb" },
                              [
                                _c("span", { staticClass: "titlts" }, [
                                  data.parentId === 0
                                    ? _c("i", {
                                        staticClass:
                                          "iconfont icon-folder-open",
                                      })
                                    : _c("i", {
                                        staticClass: "iconfont icon-wenjian",
                                      }),
                                  _vm._v(" " + _vm._s(data.name) + " "),
                                ]),
                              ]
                            )
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.permissonForm.title,
            visible: _vm.permissonForm.visible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.permissonForm, "visible", $event)
            },
            close: _vm.closePermissonForm,
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  key: _vm.permissonForm.visible,
                  ref: "permissionRef",
                  attrs: {
                    model: _vm.permissonForm,
                    rules: _vm.permissonRules,
                    "label-position": "top",
                  },
                },
                [
                  _vm.permissonForm.type !== "add"
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("roleManage.permissionsID"),
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.permissonForm.id,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.permissonForm, "id", $$v)
                                      },
                                      expression: "permissonForm.id",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.permissonForm.parentId !== 0,
                          expression: "permissonForm.parentId!==0",
                        },
                      ],
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("roleManage.parentPermissionID"),
                                prop: "parentId",
                              },
                            },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: false,
                                    expression: "false",
                                  },
                                ],
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.permissonForm.parentId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.permissonForm, "parentId", $$v)
                                  },
                                  expression: "permissonForm.parentId",
                                },
                              }),
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.permissonForm.parentName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.permissonForm,
                                      "parentName",
                                      $$v
                                    )
                                  },
                                  expression: "permissonForm.parentName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("roleManage.permissionsName"),
                                prop: "name",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { autocomplete: "off" },
                                model: {
                                  value: _vm.permissonForm.name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.permissonForm,
                                      "name",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "permissonForm.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("roleManage.permissionsTag"),
                                prop: "code",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { autocomplete: "off" },
                                model: {
                                  value: _vm.permissonForm.code,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.permissonForm,
                                      "code",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "permissonForm.code",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("roleManage.sort"),
                                prop: "sort",
                                rules: [
                                  {
                                    required: true,
                                    message: _vm.$t("roleManage.sortNotEmpty"),
                                  },
                                  {
                                    type: "number",
                                    message: _vm.$t("roleManage.sortIsNumber"),
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "sort",
                                  placeholder: _vm.$t(
                                    "roleManage.littleValueFront"
                                  ),
                                  autocomplete: "off",
                                },
                                model: {
                                  value: _vm.permissonForm.sort,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.permissonForm,
                                      "sort",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "permissonForm.sort",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v(_vm._s(_vm.$t("roleManage.confirm")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.permissonForm.visible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("roleManage.cancel")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }