var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("roleManage.catalog"), name: "catalog" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-bottom": "10px",
                    display: "flex",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.addEditNode(1)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("organizationManage.addSameLevel")) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { plain: "", size: "small", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.addEditNode(2)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("organizationManage.addNextLevel")) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { plain: "", size: "small", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.addEditNode(3)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("roleManage.EditTheMenu")) + " ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { plain: "", size: "small", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.editContent()
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("roleManage.EditTheContent")) + " "
                          ),
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { plain: "", size: "small", type: "danger" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteNode()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("roleManage.delete")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.clicNewkHeaderLine },
                        },
                        [_vm._v(_vm._s(_vm.$t("roleManage.newTitle")) + " ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-card",
                        {
                          staticClass: "box-card",
                          staticStyle: {
                            height: "calc(100vh - 240px)",
                            "overflow-y": "auto",
                          },
                          attrs: { shadow: "never" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "clearfix",
                              attrs: { slot: "header" },
                              slot: "header",
                            },
                            [
                              _c("span", { staticClass: "biaoti" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("roleManage.OperatorsManual"))
                                ),
                              ]),
                            ]
                          ),
                          _vm._l(_vm.permissionList, function (item, ind) {
                            return _c(
                              "div",
                              {
                                key: ind,
                                staticStyle: { "margin-bottom": "10px" },
                              },
                              [
                                _c("el-tree", {
                                  attrs: {
                                    data: item,
                                    "expand-on-click-node": false,
                                    props: _vm.defaultProps,
                                    "default-expand-all": "",
                                  },
                                  on: { "node-click": _vm.nodeClickData },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var node = ref.node
                                          var data = ref.data
                                          return _c(
                                            "span",
                                            { staticClass: "custom-tree-node" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "titlts",
                                                  class:
                                                    data.id ===
                                                    _vm.selectNode.id
                                                      ? "selected-node"
                                                      : "",
                                                },
                                                [
                                                  data.level === 0
                                                    ? _c("i", {
                                                        staticClass:
                                                          "iconfont icon-folder-open",
                                                        staticStyle: {
                                                          "font-size": "21px",
                                                        },
                                                      })
                                                    : data.level === 1 ||
                                                      data.level -
                                                        3 *
                                                          parseInt(
                                                            data.level / 3
                                                          ) ===
                                                        1
                                                    ? _c("i", {
                                                        staticClass:
                                                          "iconfont icon-yuanquan",
                                                      })
                                                    : data.level === 2 ||
                                                      data.level -
                                                        3 *
                                                          parseInt(
                                                            data.level / 3
                                                          ) ===
                                                        2
                                                    ? _c("i", {
                                                        staticClass:
                                                          "iconfont icon-tx-sanjiaoxing",
                                                        staticStyle: {
                                                          "font-size": "19px",
                                                          "font-weight": "bold",
                                                        },
                                                      })
                                                    : data.level === 3 ||
                                                      data.level -
                                                        3 *
                                                          parseInt(
                                                            data.level / 3
                                                          ) ===
                                                        0
                                                    ? _c("i", {
                                                        staticClass:
                                                          "iconfont icon-zhengfangxing",
                                                        staticStyle: {
                                                          "font-size": "19px",
                                                          "font-weight": "bold",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(data.name) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-card",
                        {
                          staticClass: "box-card",
                          staticStyle: {
                            height: "calc(100vh - 240px)",
                            "overflow-y": "auto",
                          },
                          attrs: { shadow: "never" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "clearfix",
                              attrs: { slot: "header" },
                              slot: "header",
                            },
                            [
                              _c("span", { staticClass: "biaoti" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("roleManage.oftenProblem"))
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "el-table",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { data: _vm.headLineList },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      label: _vm.$t("roleManage.newTitleName"),
                                      prop: "name",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: _vm.$t("newOrder.operation"),
                                      align: "center",
                                      fixed: "right",
                                      width: "100",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "text-align": "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      size: "mini",
                                                      type: "text",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.showHeadLine(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-edit-outline",
                                                      staticStyle: {
                                                        "font-size": "18px",
                                                        "margin-right": "10px",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "el-popconfirm",
                                                  {
                                                    attrs: {
                                                      title: _vm.$t(
                                                        "GlobalSetObj.AreYouSureDelete"
                                                      ),
                                                    },
                                                    on: {
                                                      confirm: function (
                                                        $event
                                                      ) {
                                                        return _vm.delLableName(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          slot: "reference",
                                                          size: "mini",
                                                          type: "text",
                                                        },
                                                        slot: "reference",
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-delete",
                                                          staticStyle: {
                                                            "font-size": "18px",
                                                            color: "red",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                disabled:
                  _vm.selectNode.id === null ||
                  _vm.selectNode.id === "" ||
                  _vm.selectNode.id === undefined ||
                  _vm.selectNode.level === 0,
                label: _vm.$t("roleManage.EditTheContent"),
                name: "content",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { width: "100%" } },
                [
                  _c("tinymce", {
                    key: _vm.contentKey,
                    attrs: { height: 360, width: "90%" },
                    model: {
                      value: _vm.paramForm.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.paramForm, "content", $$v)
                      },
                      expression: "paramForm.content",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "margin-top": "40px" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.saveData(1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("CenterForSorting.Save")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.saveData(2)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("CenterForSorting.cancel")))]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.addEditTitle,
            visible: _vm.showAddEdit,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAddEdit = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "paramForm",
                  attrs: {
                    model: _vm.paramForm,
                    "label-position": "top",
                    size: "small",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("roleManage.MenuName"),
                        rules: {
                          required: true,
                          message: _vm.$t("orderCenterCont.CannotBeEmpty"),
                          trigger: ["blur", "change"],
                        },
                        prop: "name",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "100", "show-word-limit": "" },
                        model: {
                          value: _vm.paramForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.paramForm, "name", $$v)
                          },
                          expression: "paramForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("roleManage.Introduction"),
                        rules: {
                          required: false,
                          message: _vm.$t("orderCenterCont.CannotBeEmpty"),
                          trigger: ["blur", "change"],
                        },
                        prop: "sort",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.paramForm.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.paramForm, "sort", $$v)
                          },
                          expression: "paramForm.sort",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.closeAddEdit()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.saveAddEdit()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Salve")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("roleManage.oftenProblem"),
            visible: _vm.headlineDialog,
            top: "5vh",
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.headlineDialog = $event
            },
            close: _vm.closeHeadline,
          },
        },
        [
          _c(
            "el-form",
            {
              key: _vm.headlineDialog,
              ref: "headlineDialog",
              attrs: {
                model: _vm.headlineForm,
                "label-position": "top",
                size: "small",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("roleManage.newTitleName"),
                    rules: {
                      required: true,
                      message: _vm.$t("orderCenterCont.CannotBeEmpty"),
                      trigger: ["blur", "change"],
                    },
                    prop: "name",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                      maxlength: "50",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.headlineForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.headlineForm, "name", $$v)
                      },
                      expression: "headlineForm.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("roleManage.DescribeContent"),
                    rules: {
                      required: true,
                      message: _vm.$t("orderCenterCont.CannotBeEmpty"),
                      trigger: ["blur", "change"],
                    },
                    prop: "content",
                  },
                },
                [
                  _c("tinymce", {
                    key: _vm.contentKey,
                    attrs: { height: 360 },
                    model: {
                      value: _vm.headlineForm.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.headlineForm, "content", $$v)
                      },
                      expression: "headlineForm.content",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.headlineDialog = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.saveAddHeadleLine },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Salve")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }