<template>
  <div class="detailContainer">
    <el-timeline>
      <el-timeline-item
        v-for="(item, index) in tableData" :key="index" :timestamp="item.createTime"
        placement="top"
      >
        <el-card>
          <el-form :model="item" class="operationData">
            <!--操作ip-->
            <el-form-item
              prop="ip"
              :label="$t('basicData.OperationalIP') + '：'"
            >
              <div>{{ item.ip }}</div>
            </el-form-item>
            <!--操作人姓名-->
            <el-form-item
              prop="operatorName"
              :label="$t('basicData.OperatorName') + '：'"
            >
              <div>{{ item.operatorName }}</div>
            </el-form-item>
            <!--操作时间-->
            <el-form-item
              prop="createTime"
              :label="$t('basicData.OperationTime') + '：'"
            >
              <div>{{ item.createTime }}</div>
            </el-form-item>
            <!--操作内容-->
            <el-form-item
              prop="operationContent"
              :label="$t('basicData.ActionContent') + '：'"
            >
              <div v-for="(list, ind) in item.operationContent.split('\n')" :key="ind">
                {{ list }}
                <!--下载报价-->
                <span
                  v-if="item.extra && ind === (item.operationContent.split('\n').length - 1)"
                  style="color: #409eff;cursor: pointer;"
                  @click="downloadQuote(item.extra)"
                >{{ $t('basicData.DownloadTheQuote') }}</span>
              </div>
            </el-form-item>
          </el-form>
        </el-card>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { apiPayableOfferPriceOperateLog } from '@/api/finance/basicData';
import { feePage } from '@/api/finance/partition';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';

export default {
  name: 'OperationLog',
  props: {
    editData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      tableData: [],
      createUser: [],
      expenseTypeList: []
    };
  },
  created() {
    this.createUser = getUserIdAndNameObj();
    this.getLogTable();
    this.getExpenseTypeList();
  },
  methods: {
    // 获取费用类型
    getExpenseTypeList() {
      const param = {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 100000,
        params: {
          state: 1,
          attribute: 2
        }
      };
      feePage(param, false).then(res => {
        if (res.status === 'OK') {
          this.expenseTypeList = res.data.records;
        }
      });
    },
    // 下载报价
    downloadQuote(data) {
      const that = this;
      // 揽收-上门交件
      const column_one = [
        {
          header: that.$t('basicData.KilometersStartKM'), // 距离开始(KM)
          key: 'startWeight',
          width: 20
        },
        {
          header: that.$t('basicData.MileageEndKM'), // 距离结束(KM)
          key: 'endWeight',
          width: 20
        },
        {
          header: `${that.$t('collectionCenter.hut')+that.$t('collectionCenter.turnTransport')+that.$t('basicData.BasePrice')}(PT014)`, // 安俊小屋基础价格PT014
          key: 'price',
          width: 20
        },
        {
          header: `${that.$t('collectionCenter.hut')+that.$t('collectionCenter.turnTransport')+that.$t('basicData.商家提成')}(PT014)`, // 安俊小屋转运商家提成PT015
          key: 'overshipmentPrice',
          width: 20
        },
        {
          header: `${that.$t('collectionCenter.hut')+that.$t('collectionCenter.turnTransport')+that.$t('basicData.包裹价格')}(PT016)`, // 安俊小屋转运包裹价格PT016
          key: 'standardPrice',
          width: 20
        },
        {
          header: that.$t('basicData.最低货量'), // 最低货量
          key: 'overWeightExtraPrice',
          width: 20
        },
      ];
      // 揽收-上门揽收
      const column_two = [
        {
          header: that.$t('collectionCenter.distance'), // 距离
          key: 'distanceModel',
          width: 20
        },
        {
          header: `${that.$t('basicData.BasePrice') }(PT003)`, // 基础价格
          key: 'standardPrice',
          width: 20
        },
        {
          header: `${that.$t('basicData.揽收商家提成') }(PT004)`, // 揽收商家提成PT004
          key: 'overshipmentPrice',
          width: 20
        },
        {
          header: `${that.$t('basicData.揽收包裹价格') }(PT013)`, // 揽收包裹价格PT013
          key: 'price',
          width: 20
        },
        {
          header: that.$t('basicData.最低货量'), // 最低存货
          key: 'standardCargo',
          width: 20
        },
      ];
      // 安俊小屋
      const column_three = [
        {
          header: that.$t('basicData.ExpenseType'), // 费用类型
          key: 'feeTypeCode',
          width: 20
        },
        {
          header: `${that.$t('basicData.QuantityStartTicketNotIncluded')}（票）包含`, // 货量开始
          key: 'startOfGoodsQuantity',
          width: 20
        },
        {
          header: `${that.$t('basicData.EndOfVolumeTicketIncluded')}（票）包含`, // 货量结束
          key: 'endOfGoodsQuantity',
          width: 20
        },
        {
          header: `${that.$t('basicData.PickUpPrice') }}}(PT013)`, // 揽收价格
          key: 'price',
          width: 20
        },
      ];
      // AJD
      const column_four = [
        {
          header: that.$t('basicData.KilometersStartKM'), // 公里数开始KM(不包含)
          key: 'startDistance',
          width: 20
        },
        {
          header: that.$t('basicData.MileageEndKM'), // 公里数结束KM(包含)
          key: 'endDistance',
          width: 20
        },
        {
          header: that.$t('basicData.DriverType'), // 司机类型
          key: 'vehicleType',
          width: 20
        },
        {
          header: that.$t('basicData.DailyTaskCapacityFromBase'), // 起底日任务量
          key: 'standardCargo',
          width: 20
        },
        {
          header: that.$t('basicData.BasicDeliveryFee')+'(PT005)', // 基础派送费
          key: '',
          width: 40
        },
        {
          header: that.$t('basicData.ExceedingTargetIncentiveBonus')+'(PT012)', // 超目标激励奖
          key: 'overshipmentPrice',
          width: 40
        },
        {
          header: that.$t('basicData.AttendanceIncentiveBonus')+'(PT012)', // 出勤激励奖金
          key: 'incentiveBonus',
          width: 40
        },
        {
          header: that.$t('basicData.SingleVolumeBonusOverTarget')+'(PT007)', // 超目标单量奖金
          key: 'price',
          width: 40
        },
        {
          header: that.$t('basicData.FuelSubsidy')+'(PT009)', // 燃油补贴
          key: 'subsidy',
          width: 40
        },
        {
          header: `${that.$t('basicData.Remission')}INSS11%(PT010)`, // 减免
          key: '',
          width: 40
        },
        {
          header: `${that.$t('basicData.Remission')}Sest Senat 2.5%(PT011)`,
          key: '',
          width: 40
        },
      ];
      // DSP
      const column_five = [
        {
          header: that.$t('basicData.ExpenseType'), // 费用类型
          key: 'feeTypeCode',
          width: 20
        },
        {
          header: that.$t('basicData.WeightStartKG'), // 重量开始KG（不包含）
          key: 'startWeight',
          width: 20
        },
        {
          header: that.$t('basicData.WeightEndKG'), // 重量结束KG（不包含）
          key: 'endWeight',
          width: 20
        },
        {
          header: `0-31KM${that.$t('basicData.Price')}`, // 0-30KM价格
          key: 'innerDistancePrice',
          width: 20
        },
        {
          header: `31-101KM${that.$t('basicData.Price')}`, // 31-101KM价格
          key: 'outerDistancePrice',
          width: 20
        },
        {
          header: `>101KM${that.$t('basicData.Price')}`, // 大于101KM价格
          key: 'greaterThanDistancePrice',
          width: 20
        },
        {
          header: `0-31KM${that.$t('basicData.ExcessWeightFareIncrease')}`, // 0-30KM超重价格
          key: 'overWeightExtraPrice',
          width: 20
        },
        {
          header: `31-101KM${that.$t('basicData.ExcessWeightFareIncrease')}`, // 31-101KM超重价格
          key: 'overKilometerExtraPrice',
          width: 20
        },
        {
          header: `>101KM${that.$t('basicData.ExcessWeightFareIncrease')}`, // >101KM超重价格
          key: 'greaterThanKilometerExtraPrice',
          width: 20
        },
      ];
      const dataArr = JSON.parse(data);
      console.log(dataArr, '数据');
      async function ddd() {
        const wb = new ExcelJS.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1'); 
        const Sheet1_data = [];

        if (data.businessType === 2 && data.collectType === 1) {
          Sheet1.columns = column_one;
        } else if (data.businessType === 2 && data.collectType === 2) {
          Sheet1.columns = column_two;
        } else if (data.businessType === 1) {
          Sheet1.columns = column_three;
        } else if (data.businessType === 3) {
          Sheet1.columns = column_four;
        } else if (data.businessType === 4) {
          Sheet1.columns = column_five;
        }
        
        // Sheet1.addRows(Sheet1_data);
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        const saveName = that.$t('basicData.QuoteDetails') + '.xlsx'; // 报价详情
        saveAs(blob, saveName);
        return;
      }
      ddd();
    },
    // 获取操作日志
    getLogTable() {
      const dateData = (property, bol) => {
        return function(a, b) {
          const value1 = a[property];
          const value2 = b[property];
          if (bol) {
            // 升序
            return Date.parse(value1) - Date.parse(value2);
          } else {
            // 降序
            return Date.parse(value2) - Date.parse(value1);
          }
        };
      };
      apiPayableOfferPriceOperateLog(this.editData.id).then(res => {
        if (res.status === 'OK') {
          res.data.map(item => {
            item.createTime = item.createTime.replace(/T|Z/g, ' ');
          });
          res.data.sort(dateData('createTime', false));
          this.tableData = res.data;
        }
      });
    }
  }
};
</script>

<style scoped>
.detailContainer {
  height: calc(100vh - 40px);
  overflow-y: auto;
  padding: 20px;
}
.el-timeline{
  padding: 0;
}
.el-form-item{
  margin-bottom: 0;
}
.detailContainer >>> .operationData .el-form-item__label,
.detailContainer >>> .operationData .el-form-item__content{
  line-height: 26px!important;
}
pre {
  white-space: pre-wrap;      /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /*Opera 7 */
  word-wrap:break-word;       /* Internet Explorer 5.5+ */
  margin: 0;
  font-family: inherit;
  max-width: 600px;
}
</style>
