import i18n from '@/lang';

// 后面需要使用 src/views/finance/enums.js 里面的WAYBILL_STATUS
// 现在因为财务代码重构未结束，才暂时在此处重新定义一份
// 注意需要补充财务代码中 code 12 的字段
// 物流订单
export const WAYBILL_STATUS = Object.freeze([
  { label: i18n.t('newOrder.all'), code: 0 }, // 待分配
  { label: i18n.t('newOrder.ToBeAssigned'), code: 1 }, // 待分配
  { label: i18n.t('newOrder.ToBeCollected'), code: 2 }, // 待揽收
  { label: i18n.t('newOrder.Collected'), code: 3 }, // 已揽收
  { label: i18n.t('newOrder.CollectTheRevenuePool'), code: 4 }, // 揽收入库
  { label: i18n.t('newOrder.CollectTakeOutWarehouse'), code: 5 }, // 揽收出库
  { label: i18n.t('GlobalSetObj.moduan'), code: 19 }, // 末端已揽收
  { label: i18n.t('newOrder.TransshipmentIntoStorage'), code: 6 }, // 转运入库
  { label: i18n.t('newOrder.TransferOutWarehouse'), code: 7 }, // 转运出库
  { label: i18n.t('newOrder.DeliveryOutletsIntoStorage'), code: 8 }, // 派送网点入库
  { label: i18n.t('newOrder.Delivering'), code: 9 }, // 派送中
  { label: i18n.t('newOrder.Signed'), code: 10 }, // 已签收
  { label: i18n.t('newOrder.ProblemPiece'), code: 11 }, // 问题件
  { label: i18n.t('collectionCenter.unclaimed'), code: 12 }, // 待取件
  { label: i18n.t('GlobalSetObj.Canceled'), code: 13 }, // 已取消
  { label: i18n.t('GlobalSetObj.deliveryFailure'), code: 14 }, // 投递失败
  { label: i18n.t('GlobalSetObj.DotBack'), code: 17 }, // 网点退回
  { label: i18n.t('GlobalSetObj.ReturnToStorage'), code: 18 }, // 退仓入库
  { label: i18n.t('GlobalSetObj.Withdrawn'), code: 15 }, // 退件中
  { label: i18n.t('GlobalSetObj.TheReturnComplete'), code: 16 }, // 退件完成
  { label: i18n.t('GlobalSetObj.mutilSign'), code: 20 }, // 多次签收
  { label: i18n.t('GlobalSetObj.processSettlement'), code: 21 }, // 理赔中
  { label: i18n.t('GlobalSetObj.ClaimsCompleted'), code: 22 } // 理赔完成
]);
