<script >
import { ref, defineProps } from '@vue/composition-api';
import { useQueryClient } from '@tanstack/vue-query';
import { default as FormTrailerData } from './steps/formTrailerData';
import { default as FormAdditionalData } from './steps/formAdditionalData';
import { default as FormMeasurementData } from './steps/formMeasurementData';
import { useWizardForm } from '@/composable/useWizardForm';
import { useCreateTrailer } from '@/api/transport/trailer/query';
const __sfc_main = {};
__sfc_main.props = {
  closeModal: {
    type: Function
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const STEPS_TOTAL = 3;
  const formData = ref({
    formStep01: {
      document: null,
      licensePlate: null,
      rntrc: null,
      brand: null,
      state: null,
      provider: null,
      outsourced: 0
    },
    formStep02: {
      capacityKg: null,
      capacityM3: null,
      bodyType: null,
      weight: null
    },
    formStep03: {
      documentOwner: null,
      stateRegistration: null,
      trackerCompany: null,
      trackerCompanyCode: null
    }
  });
  const queryClient = useQueryClient();
  const {
    mutate,
    isLoading
  } = useCreateTrailer();
  function handleSubmit() {
    const newTrailer = {
      ...formData.value.formStep01,
      ...formData.value.formStep02,
      ...formData.value.formStep03
    };
    mutate(newTrailer, {
      onSuccess() {
        props.closeModal();
      }
    });
  }
  const {
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  } = useWizardForm(formData, handleSubmit, STEPS_TOTAL);
  return {
    formData,
    isLoading,
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  };
};
__sfc_main.components = Object.assign({
  FormTrailerData,
  FormMeasurementData,
  FormAdditionalData
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <FormTrailerData
      v-show="step === 0"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      :form-data.sync="formData.formStep01"
    />

    <FormMeasurementData
      v-show="step === 1"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      :form-data.sync="formData.formStep02"
    />

    <FormAdditionalData
      v-show="step === 2"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      :form-data.sync="formData.formStep03"
    />

    <o-loading :full-page="true" :active.sync="isLoading" />
  </div>
</template>
