<template>
  <el-input
    v-model="value" type="textarea" :autosize="{ minRows, maxRows}"
    :placeholder="placeholder"
    @input="onInput"
  />
</template>

<script>
import { debounce } from 'lodash-es';

export default {
  name: '',

  props: {
    // 值
    val: {
      type: [String, Array],
      default: ''
    },

    minRows: {
      type: Number,
      default: 2
    },

    maxRows: {
      type: Number,
      default: 4
    },

    // 最大输入长度
    maxLength: {
      type: Number,
      default: 10000
    }
  },

  data() {
    return {
      value: ''
    };
  },

  watch: {
    'val': {
      handler(val) {
        if (Array.isArray(val)) {
          this.value = val.join('\n');
          return;
        }

        this.value = val;
      },
      immediate: true
    }
  },

  created() {
    this.onInput = debounce(this.getInput, 100);

    const str1 = this.$t('orderCenterCont.MultipleLineBreaks'); // 多个请换行输入
    const str2 = this.$t('GlobalSetObj.queried200pieces').replace('200', this.maxLength); // 最多输入
    this.placeholder = `${str1}(${str2})`;
  },

  methods: {
    getInput() {
      const arr = this.getValue();
      this.$emit('input', arr, this.value);
    },

    // 父元素可以直接掉用，获取最新的数值
    getValue() {
      let arr = [];

      if (this.value.length) {
        arr = this.value.split(/[(\r\n)\r\n]+/).map(item => item.trim()).filter(item => Boolean(item));
        const set = new Set([...arr]);
        arr = [...set].slice(0, this.maxLength); // 去重
      }
      return arr;
    },

    // 清空输入框
    clear() {
      this.value = '';
    }
  }

};
</script>

<style scoped>
</style>

