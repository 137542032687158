var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "outOfWarehouseBox" }, [
    _c(
      "div",
      { staticClass: "outOfWarehouseClass" },
      [
        _c("div", [
          _c(
            "div",
            { staticClass: "scanClass" },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("operatingPlatform.outOfWarehouseScan"))),
              ]),
              _c("el-input", {
                staticClass: "iconBtn",
                attrs: {
                  placeholder: _vm.$t("operatingPlatform.PleaseScan"),
                  "prefix-icon": "el-icon-full-screen",
                  clearable: "",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.addTable($event)
                  },
                },
                model: {
                  value: _vm.outOfWarehouseScan,
                  callback: function ($$v) {
                    _vm.outOfWarehouseScan =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "outOfWarehouseScan",
                },
              }),
            ],
            1
          ),
        ]),
        _c(
          "el-button",
          { staticClass: "uploadBtn onlyClickOnce", on: { click: _vm.upload } },
          [_vm._v(_vm._s(_vm.$t("operatingPlatform.Upload")))]
        ),
        _c(
          "div",
          { staticClass: "tableClass" },
          [
            _c(
              "el-table",
              {
                attrs: {
                  "max-height": 600,
                  data: _vm.scanTableData,
                  border: "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "scanWaybillNumber",
                    align: "center",
                    label: _vm.$t("operatingPlatform.waybillNumber"),
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "scanTime",
                    align: "center",
                    label: _vm.$t("operatingPlatform.scanTime"),
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("operatingPlatform.operation"),
                    fixed: "right",
                    width: "100",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-popconfirm",
                            {
                              attrs: {
                                title: _vm.$t("operatingPlatform.deleteSure"),
                              },
                              on: {
                                confirm: function ($event) {
                                  return _vm.scanDelClick(
                                    scope.row,
                                    scope.$index
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "reference",
                                    type: "danger",
                                    size: "mini",
                                  },
                                  slot: "reference",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("operatingPlatform.Delete"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
      },
      [
        _c("audio", {
          ref: "errRef",
          attrs: { src: _vm.errorMp3, controls: "controls" },
        }),
        _c("audio", {
          ref: "okRef",
          attrs: { src: _vm.okMp3, controls: "controls" },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }