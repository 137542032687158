<template>
  <div class="app-container">
    <el-card shadow="never">
      <el-form :inline="false" label-position="top" size="small">
        <el-row :gutter="20">
          <el-col :span="8">
            <!--创建时间-->
            <el-form-item :label="$t('GlobalSetObj.senderTime')">
              <el-date-picker
                v-model="timeValue"
                :clearable="true"
                :default-time="['00:00:00', '23:59:59']"
                :format="$i18n.locale=='zh'?zhDate:ptDate"
                :placeholder="$t('newOrder.SelectDatetime')"
                range-separator="~"
                style="width:100%"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
              />
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <!--搜索-->
            <el-button
              icon="el-icon-search" size="small" type="primary"
              @click="enterSearch"
            >{{
              $t('newOrder.search')
            }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh-left" size="small" type="info"
              @click="resetForm()"
            >{{
              $t('newOrder.reset')
            }}
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <div style="margin-top: 20px">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-card shadow="never">
            <div>
              <span>分拣机有效分拣票数</span>
            </div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card shadow="never">
            <div>
              <span>分拣机有效分拣票数</span>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { shipmentPage } from '@/api/orderApi';
import dayjs from 'dayjs';

export default {
  name: 'OutGoRecord',
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      timeValue: [],
      queryForm: {
        pageSize: 10,
        number: 1,
        total: 0
      }
    };
  },
  computed: {
    IsWidth() {
      return {
        width: this.isTormat ? '220px' : '120px'
      };
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getTimer();
    this.getSearch();
  },
  methods: {
    enterSearch() {
      this.queryForm.number = 1;
      this.getSearch();
    },
    // 重置
    resetForm() {
      this.queryForm.number = 1;
      this.getTimer();
      this.getSearch();
    },
    // 查询
    getSearch() {
      const data = {
        params: {}
      };
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.number;
      if (this.timeValue && this.timeValue.length > 0) {
        if (this.queryForm.params.selectTimeType === 1) {
          data.params.shipmentTimeStart = this.timeValue[0];
          data.params.shipmentTimeEnd = this.timeValue[1];
        } else {
          data.params.arriveTimeStart = this.timeValue[0];
          data.params.arriveTimeEnd = this.timeValue[1];
        }
      }
      shipmentPage(data).then(res => {
        if (res.status === 'OK') {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
          console.log(this.tableData, 'tableData');
        }
      });
    },
    // 获取最近一星期时间
    getTimer() {
      const day = new Date();
      const brazilTime = day.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
      const today = dayjs(brazilTime).format('YYYY-MM-DD');
      const oneWeekAgo = dayjs(brazilTime).subtract(1, 'week').format('YYYY-MM-DD');
      this.timeValue = [oneWeekAgo + ' 00:00:00', today + ' 23:59:59'];
    }
  }
};
</script>

<style scoped>
</style>
