<template>
  <div class="pathToPushBox">
    <div style="margin-bottom: 20px;" class="selectClass">
      <el-form>
        <el-row type="flex" justify="start" style="flex-wrap: wrap;" class="listQueryTime">
          <el-col :span="7" style="width: auto;">
            <el-row>
              <el-col class="col2">
                <el-select v-model="selectTime" :placeholder="$t('collectionCenter.pleaseChoose')" class="selectTimeClass autoWidth" @change="changeTime">
                  <template slot="prefix">
                    {{ (timeList.find(item => item.value === selectTime) || {}).time }}
                  </template>
                  <el-option
                    v-for="(item, index) in timeList"
                    :key="index"
                    :label="item.time"
                    :value="item.value"
                  />
                </el-select>
              </el-col>
              <el-col style="width: auto;">
                <!-- 扫描时间 selectTime == 't1' queryForm.params.startScanTime queryForm.params.endScanTime -->
                <!-- 创建时间 selectTime == 't2' queryForm.params.startCreateTime queryForm.params.endCreateTime -->
                <el-form-item class="delMl">
                  <el-date-picker
                    v-model="timeValue"
                    :clearable="false"
                    type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :default-time="['00:00:00', '23:59:59']"
                    :range-separator="$t('collectionCenter.to')"
                    :start-placeholder="$t('collectionCenter.StartTime')"
                    :end-placeholder="$t('collectionCenter.EndTime')"
                    @change="dateChange"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8" style="width: auto;">
            <el-row>
              <el-col class="col2">
                <el-select v-model="selectCondition" class="selectConditionClass selectTimeClass autoWidth" @change="selectConditionClick">
                  <template slot="prefix">
                    {{ (chooseCondition.find(item => item.value === selectCondition) || {}).chooseName }}
                  </template>
                  <el-option
                    v-for="(item, index) in chooseCondition"
                    :key="index"
                    :label="item.chooseName"
                    :value="item.value"
                  />
                </el-select>
              </el-col>
              <el-col v-if="selectCondition == 'c1'" style="width: auto;" class="colWill">
                <!-- 订单号： -->
                <el-form-item class="selectDelMl textarea">
                  <el-input v-model="queryForm.params.orderNumber" type="textarea" autosize :placeholder="$t('collectionCenter.BatchQuery')" />
                </el-form-item>
              </el-col>
              <el-col v-if="selectCondition == 'c2'" style="width: auto;" class="colWill">
                <!-- 包裹号： -->
                <el-form-item class="selectDelMl textarea">
                  <el-input v-model="queryForm.params.packageNumber" type="textarea" autosize :placeholder="$t('collectionCenter.BatchQuery')" />
                </el-form-item>
              </el-col>
              <el-col v-if="selectCondition == 'c3'" style="width: auto;" class="colWill">
                <el-form-item class="selectDelMl textarea">
                  <el-input
                    v-model="queryForm.params.scanNumber"
                    type="textarea"
                    autosize
                    :placeholder="$t('collectionCenter.BatchQuery')"
                    @keydown.enter.native="BlockNewlineScan"
                  /><!-- 支持批量查询(回车换行分割) -->
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8" style="width: auto;">
            <!-- 客户名称： -->
            <el-form-item :label="$t('collectionCenter.CustomerN')" class="selectClass">
              <el-select v-model="queryForm.params.customerId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable filterable @focus="getNewestCustomer">
                <el-option
                  v-for="(item, index) in customerIdList"
                  :key="index"
                  :label="item.username"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="width: auto;">
            <!-- 推送结果： -->
            <el-form-item :label="$t('collectionCenter.pushresults')" class="selectClass">
              <el-select v-model="queryForm.params.status" :placeholder="$t('collectionCenter.pleaseChoose')" filterable clearable>
                <el-option
                  v-for="(item, index) in PushResultList"
                  :key="index"
                  :label="item.msg"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="width: auto;">
            <!-- 消息类型： -->
            <el-form-item :label="$t('collectionCenter.msgType')" class="selectClass">
              <el-select v-model="queryForm.params.pushType" :placeholder="$t('collectionCenter.pleaseChoose')" clearable filterable>
                <el-option
                  v-for="(item, index) in pushTypeList"
                  :key="index"
                  :label="item.msg"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="width: auto;">
            <!-- 订单状态： -->
            <el-form-item :label="$t('collectionCenter.orderStatus')">
              <el-select
                v-model="queryForm.params.collectStatusList"
                class="selectClass"
                multiple
                :placeholder="$t('collectionCenter.pleaseChoose')"
                clearable
                filterable
              >
                <el-option
                  v-for="(item, index) in orderTypeList"
                  :key="index"
                  :label="item.collectStatusName"
                  :value="item.collectStatus"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex" justify="space-between">
        <el-col :span="12">
          <el-button
            v-permit:remove="'btn:collection-center:order:path-push:find'"
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
          <el-button
            v-permit:remove="'btn:collection-center:order:path-push:reset'"
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
          <!--<el-button
            v-permit:remove="'btn:collection-center:order:path-push:BatchRepush'"
            icon="el-icon-s-promotion"
            size="mini"
            @click="BatchWeighingPush"
            :class="BatchRepushIdList.length <=0 && isClick ? 'onlyClickOnce10' : ''"
          >{{ $t('collectionCenter.BatchRepush') }}</el-button> 批量重推 -->
        </el-col>
      </el-row>
      <el-divider />
    </div>
    <div>
      <el-table
        ref="tableList"
        :max-height="600"
        border
        :data="tableDate"
        @select="batchSelect"
        @select-all="batchSelectAll"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <!-- 序号 -->
        <el-table-column :label="$t('collectionCenter.ind')" type="index" width="60" align="center">
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="customerName"
          :label="$t('collectionCenter.CustomerName')"
        /><!-- 客户名称 -->
        <el-table-column
          align="center"
          prop="orderNumber"
          :label="$t('collectionCenter.lanshouOrderNum')"
          width="200"
        /><!-- 揽收订单号 -->
        <el-table-column
          align="center"
          prop="scanNumber"
          :label="$t('collectionCenter.Barcode')"
        /><!-- 条形码 -->
        <el-table-column
          align="center"
          prop="packageNumber"
          :label="$t('collectionCenter.parcelNum')"
          width="220"
        /><!-- 包裹号 -->
        <el-table-column
          align="center"
          prop="bagNumber"
          :label="$t('collectionCenter.BagNumber')"
        /><!-- 袋号 -->
        <el-table-column
          align="center"
          prop="collectStatusName"
          :label="$t('collectionCenter.OrderStatus')"
        /><!-- 订单状态 -->
        <el-table-column
          align="center"
          prop="pushType"
          :label="$t('collectionCenter.pushMsgType')"
        ><!-- 消息类型 -->
          <template slot-scope="scope">
            <div>
              {{
                scope.row.pushType == 1 ? $t('collectionCenter.parcelTrack') :
                scope.row.pushType == 2 ? $t('collectionCenter.batchTrack') :
                scope.row.pushType == 3 ? $t('collectionCenter.truncatedBatchTrack') :
                scope.row.pushType == 4 ? $t('collectionCenter.lastLoadingTrack') : ''
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="createTime"
          :label="$t('collectionCenter.CreationTime')"
          width="150"
        /><!-- 创建时间 -->
        <el-table-column
          align="center"
          prop="scanTimeStr"
          :label="$t('collectionCenter.scanTime')"
          width="150"
        /><!-- 扫描时间 -->
        <el-table-column
          align="center"
          prop="pushTime"
          :label="$t('collectionCenter.pushTime')"
        /><!-- 推送时间 -->
        <el-table-column
          prop="pushBody"
          :label="$t('collectionCenter.PushContent')"
          :show-overflow-tooltip="false"
        ><!-- 推送内容 -->
          <template slot-scope="scope">
            <el-tooltip placement="top">
              <pre slot="content" style="max-height: 200px;overflow: auto;width:500px;">{{ scope.row.pushBody }}</pre>
              <div class="paramsStyle">{{ scope.row.pushBody }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="status"
          :label="$t('collectionCenter.PushResults')"
        ><!-- 推送结果 -->
          <template slot-scope="scope">
            <div>
              {{
                scope.row.status == 1 ? $t('collectionCenter.pushSucess') :
                scope.row.status == 2 ? $t('collectionCenter.Falha') :
                scope.row.status == 3 ? $t('collectionCenter.pushException') : ''
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="failedReason"
          :label="$t('collectionCenter.ReasonForFailure')"
          show-overflow-tooltip
        /><!-- 失败原因 -->
      </el-table>
    </div>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 30, 50, 100, 500, 1000]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getPathPushPage"
        @size-change="getPageSize"
      />
    </div>
  </div>
</template>

<script>
import {
  queryAllCustomer,
  mqMsgLogPage,
  trackRepush
} from '@/api/lanshou';
import dayjs from 'dayjs';

export default {
  name: 'PathToPush',
  data() {
    return {
      isClick: false,
      BatchRepushIdList: [],
      timeValue: [],
      orderTypeList: [
        { collectStatus: 0, collectStatusName: this.$t('collectionCenter.notLanShou') }, // '未揽收'
        { collectStatus: 1, collectStatusName: this.$t('collectionCenter.hasLanShou') }, // '已揽收'
        { collectStatus: 2, collectStatusName: this.$t('collectionCenter.cancel') }, // 取消
        { collectStatus: 3, collectStatusName: this.$t('collectionCenter.LanShouing') }, // 揽收中
        { collectStatus: 4, collectStatusName: this.$t('collectionCenter.scheduled') }, // 已调度
        { collectStatus: 5, collectStatusName: this.$t('collectionCenter.AbnormalLanShou') }, // 揽收异常
        { collectStatus: 6, collectStatusName: this.$t('collectionCenter.haveBeenSigned') }, // 已签收
        { collectStatus: 7, collectStatusName: this.$t('collectionCenter.ReceivingAbnormalPush') }, // 揽收异常推送
        { collectStatus: 8, collectStatusName: this.$t('collectionCenter.SealedBag') }, // 已封袋
        { collectStatus: 9, collectStatusName: this.$t('collectionCenter.truckLoading') }, // 装车
        { collectStatus: 10, collectStatusName: this.$t('collectionCenter.unloading') }, // 卸车
        { collectStatus: 11, collectStatusName: this.$t('collectionCenter.Bagged') }, // 已装袋
        { collectStatus: 12, collectStatusName: this.$t('collectionCenter.closedLoop') }, // 已闭环
        { collectStatus: 13, collectStatusName: this.$t('collectionCenter.unsolicited') }, // 待揽件
        { collectStatus: 14, collectStatusName: this.$t('collectionCenter.collectWarehousing') } // 网点揽收入库
      ],
      pushTypeList: [
        { code: 1, msg: this.$t('collectionCenter.collectPackageTrack') }, // 揽收包裹轨迹
        { code: 2, msg: this.$t('collectionCenter.batchTrack') }, // 批量轨迹
        { code: 3, msg: this.$t('collectionCenter.truncatedBatchTrack') }, // 截单批量轨迹
        { code: 4, msg: this.$t('collectionCenter.lastLoadingTrack') } // 最后一次装车轨迹
      ],
      // isRepushList: true, // 是否都是消费失败的数据
      selectTime: 't2', // 选择扫描时间 / 创建时间
      timeList: [
        { value: 't1', time: this.$t('collectionCenter.scanTime') }, // '扫描时间'
        { value: 't2', time: this.$t('collectionCenter.CreationTime') } // '创建时间'
      ],
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startScanTime: '', // 扫描——开始时间
          endScanTime: '', // 扫描——结束时间
          startCreateTime: '', // 创建——开始时间
          endCreateTime: '', // 创建——结束时间
          orderNumber: '',
          packageNumber: '',
          scanNumber: '',
          customerId: '', // 客户名称
          status: '', // 推送结果
          pushType: 1, // 消息类型
          collectStatusList: [0, 1, 6]
        }
      },
      selectCondition: 'c1', // 单号查询——选择条件
      chooseCondition: [
        { value: 'c1', chooseName: this.$t('collectionCenter.lanshouOrderNum') }, // '揽收订单号'
        { value: 'c3', chooseName: this.$t('collectionCenter.Barcode') }, // '条形码'
        { value: 'c2', chooseName: this.$t('collectionCenter.parcelNum') } // '包裹号'
      ],
      customerIdList: [], // 客户名称列表
      PushResultList: [
        { code: 1, msg: this.$t('collectionCenter.pushSucess') }, // '推送成功'
        { code: 2, msg: this.$t('collectionCenter.Falha') }, // '推送失败'
        { code: 3, msg: this.$t('collectionCenter.pushException') } // '推送异常'
      ],
      tableDate: [], // 表单
      total: 0,
      userId: '',
      notEnter: true // 是否允许换行
    };
  },
  watch: {
    timeValue() {
      if (this.timeValue && this.timeValue.length <= 0) {
        this.queryForm.params.startScanTime = '';
        this.queryForm.params.endScanTime = '';
        this.queryForm.params.startCreateTime = '';
        this.queryForm.params.endCreateTime = '';
      }
    },
    'queryForm.params.scanNumber'(val) {
      const bgh1 = val.split('\n');
      let resetScanNumber = [];
      if (bgh1.length >= 1000) {
        resetScanNumber = bgh1.slice(0, 1000).join('\n');
        this.queryForm.params.scanNumber = resetScanNumber;
        this.notEnter = false;
      } else {
        this.notEnter = true;
      }
      if (bgh1.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    }
  },
  created() {
    this.userId = this.$store.state.user.id;
    this.getNewestCustomer();
    this.default();
    this.searchClick();
    this.isClick = false;
  },
  methods: {
    // 获取最新的客户list
    getNewestCustomer() {
      queryAllCustomer().then(res => {
        if (res.status === 'OK') {
          res.data.forEach((list) => {
            this.customerIdList.push({
              id: list.id,
              username: list.username
            });
          });
        }
      });
    },
    BlockNewlineScan() { // 文本域最大输入行数为1000
      const e = window.event || arguments[0];
      const packageNumberLen = this.queryForm.params.scanNumber.split('\n');
      if (!this.notEnter) {
        if (packageNumberLen.length >= 1000) {
          this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        return;
      }
      this.queryForm.params.startScanTime = '';
      this.queryForm.params.endScanTime = '';
      this.queryForm.params.startCreateTime = '';
      this.queryForm.params.endCreateTime = '';
      if (this.selectTime === 't1') {
        this.queryForm.params.startScanTime = val[0];
        this.queryForm.params.endScanTime = val[1];
      }
      if (this.selectTime === 't2') {
        this.queryForm.params.startCreateTime = val[0];
        this.queryForm.params.endCreateTime = val[1];
      }
      if (val[1]) {
        const endTime = dayjs(val[0]).subtract(-1, 'year').format('YYYY-MM-DD 23:59:59');
        if (val[1] > endTime) {
          this.$message({
            // 最多可查询到一年的轨迹数据
            message: this.$t('collectionCenter.UpToOneYear'),
            type: 'warning'
          });
          this.changeTime();
        }
      }
    },
    changeTime() { // 选择扫描时间 / 创建时间
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      if (this.selectTime === 't1') {
        this.queryForm.params.startScanTime = today + ' 00:00:00';
        this.queryForm.params.endScanTime = today + ' 23:59:59';
        this.queryForm.params.startCreateTime = '';
        this.queryForm.params.endCreateTime = '';
      }
      if (this.selectTime === 't2') {
        this.queryForm.params.startCreateTime = today + ' 00:00:00';
        this.queryForm.params.endCreateTime = today + ' 23:59:59';
        this.queryForm.params.startScanTime = '';
        this.queryForm.params.endScanTime = '';
      }
    },
    default() { // 默认查询条件
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.selectTime = 't2';
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      this.queryForm.params = {
        startScanTime: '', // 扫描——开始时间
        endScanTime: '', // 扫描——结束时间
        startCreateTime: today + ' 00:00:00', // 创建——开始时间
        endCreateTime: today + ' 23:59:59', // 创建——结束时间
        orderNumber: '',
        packageNumber: '',
        scanNumber: '',
        customerId: '', // 客户名称
        status: '', // 推送结果
        pushType: 1,
        collectStatusList: [0, 1, 6]
      };
    },
    selectConditionClick() { // 单号：条件查询——选项发生变化时触发
      this.queryForm.params.scanNumber = '';
      this.queryForm.params.orderNumber = '';
      this.queryForm.params.packageNumber = '';
    },
    getPathPushPage() { // 表单
      this.BatchRepushIdList = [];
      const queryForm = JSON.parse(localStorage.getItem('ptpQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      mqMsgLogPage(queryForm).then(res => {
        if (res.status === 'OK') {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        }
      }).catch({});
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      const query = JSON.parse(localStorage.getItem('ptpQueryForm')) || this.queryForm;
      const queryForm = {
        pageNumber: 1,
        pageSize: this.queryForm.pageSize,
        params: query.params
      };
      mqMsgLogPage(queryForm).then(res => {
        if (res.status === 'OK') {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        }
      }).catch({});
    },

    checkQuery() {
      /**
       *  单号去除空值
       */
      let orderNumber = this.queryForm.params.orderNumber.split('\n');
      let packageNumber = this.queryForm.params.packageNumber.split('\n');
      if (this.selectCondition === 'c1') {
        orderNumber = orderNumber.filter(s => {
          return s && s.trim();
        });
        this.queryForm.params.orderNumber = orderNumber.join('\n');
      }
      if (this.selectCondition === 'c2') {
        packageNumber = packageNumber.filter(s => {
          return s && s.trim();
        });
        this.queryForm.params.packageNumber = packageNumber.join('\n');
      }
    },
    searchClick() { // 表单
      this.checkQuery();
      if (this.selectTime === 't1') {
        if ((!this.queryForm.params.endScanTime || !this.queryForm.params.startScanTime) &&
          (!this.queryForm.params.orderNumber && !this.queryForm.params.packageNumber)) {
          this.$message({
            message: this.$t('collectionCenter.PleaseSelectTime'),
            type: 'warning'
          });
          return;
        }
      }
      if (this.selectTime === 't2') {
        if ((!this.queryForm.params.endCreateTime || !this.queryForm.params.startCreateTime) &&
          (!this.queryForm.params.orderNumber && !this.queryForm.params.packageNumber)) {
          this.$message({
            message: this.$t('collectionCenter.PleaseSelectTime'),
            type: 'warning'
          });
          return;
        }
      }
      if (this.queryForm.params.orderNumber || this.queryForm.params.packageNumber) {
        this.timeValue = [];
        this.queryForm.params.startCreateTime = '';
        this.queryForm.params.endCreateTime = '';
        this.queryForm.params.startScanTime = '';
        this.queryForm.params.endScanTime = '';
      }
      this.queryForm.pageNumber = 1;
      localStorage.setItem('ptpQueryForm', JSON.stringify(this.queryForm));
      this.getPathPushPage();
    },
    resetClick() {
      this.default();
    },
    BatchWeighingPush() { // 批量重推
      this.isClick = true;
      if (this.BatchRepushIdList.length <= 0) {
        this.$message.warning(this.$t('collectionCenter.checkMsg')); // 请勾选数据
        return;
      } else {
        // if (this.isRepushList) {
        const msgIdList = {
          userId: this.userId,
          msgIdList: this.BatchRepushIdList
        };
        trackRepush(msgIdList).then(res => {
          if (res.status === 'OK' || res.data.status === 'OK') {
            this.isClick = false;
            setTimeout(() => {
              this.getPathPushPage();
            }, 300);
          }
        }).catch(() => {
          this.isClick = false;
        });
        // } else {
        //   this.BatchRepushIdList = [];
        //   this.$refs.tableList.clearSelection();
        //   this.isRepushList = true;
        //   // '所选数据不符合要求，已消费成功数据不支持重新推送，请重新勾选'
        //   this.$message.warning(this.$t('collectionCenter.PleaseReselectIt'));
        // }
      }
    },
    batchSelect(selection, row) {
      const BatchRepushIdList = [];
      selection.forEach(list => {
        BatchRepushIdList.push(list.msgId);
        // if (list.status === 3) {
        //   this.isRepushList = false;
        // }
      });
      this.BatchRepushIdList = BatchRepushIdList;
    },
    batchSelectAll(selection) {
      const BatchRepushIdListAll = [];
      selection.forEach(list => {
        BatchRepushIdListAll.push(list.msgId);
        // if (list.status === 3) {
        //   this.isRepushList = false;
        // }
      });
      this.BatchRepushIdList = BatchRepushIdListAll;
    }
  }
};
</script>

<style lang="scss" scoped>

  // 防止按钮重复点击
  @keyframes throttle{
    from{
      filter: opacity(40%);
      pointer-events: none;
    }
    to{
      pointer-events: all;
    }
  }
  .onlyClickOnce10{
    animation: throttle 10s step-end forwards;
  }
  .onlyClickOnce10:active{
    filter: opacity(40%);
    animation: none;
  }

.autoWidth ::v-deep .el-input__prefix{ // 下拉框自适应占位
  position: relative;
  left: 0;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  padding: 0 25px;
  padding-left: 0;
  margin-left: 20px;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  visibility: hidden;
  white-space: nowrap;
}
.autoWidth ::v-deep input {
  position: absolute;
}
.selectClass ::v-deep .el-form-item{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.selectClass ::v-deep .el-form-item__label{
  padding: 0;
  line-height: 16px;
  white-space: nowrap;
  margin-left: 20px;
}
.selectClass ::v-deep .el-form-item__content{
  margin-left: 0 !important;
}
  .selectClass ::v-deep .el-date-editor .el-range-separator{
    width: 28px;
  }
  .paramsStyle{
    width: 100%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .pathToPushBox{
    padding: 20px;
    box-sizing: border-box;
  }
  .pathToPushBox .listQueryTime .delMl ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }

  .col2 {
    width: auto !important;
    // margin-left: 20px;
  }
  .colWill{
    //width: calc(100% - 200px);
    width: auto;
  }
  .pathToPushBox .colWill ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
  .pathToPushBox .selectConditionClass ::v-deep.el-input--suffix .el-input__inner{
    text-align: right;
    border: none !important;
    font-size: 14px;
    // color: #606266;
    font-weight: bold;
    &::placeholder{
      color: #606266 !important;
    }
    &::-webkit-input-placeholder{
      color: #606266 !important;
    }
  }
  .selectClass ::v-deep .el-select{
    width: 100%;
  }
  .pathToPushBox .selectTimeClass ::v-deep.el-input--suffix .el-input__inner{
    border: none !important;
    font-size: 14px;
    color: #606266;
    font-weight: bold;
    text-align: right;
    padding: 0 25px;
    padding-left: 0;
  }
  ::v-deep .el-tooltip__popper{ max-width:50%; }
  .selectDelMl.textarea ::v-deep .el-textarea__inner{
    max-height: 150px;
  }
</style>
