<template>
  <div class="app-container">
    <el-card class="box-card searchInputCon">
      <el-form size="small" :model="queryForm" label-position="top">
        <el-row :gutter="20">
          <!--退件时间-->
          <el-col :span="8">
            <el-form-item :label="$t('GlobalSetObj.ReturnTime')">
              <el-date-picker
                v-model="creationTime"
                style="width: 100%"
                type="datetimerange"
                :format="$i18n.locale=='zh'?zhDate:ptDate"
                :range-separator="$t('operationCenter.to')"
                :start-placeholder="$t('operationCenter.startDate')"
                :end-placeholder="$t('operationCenter.endDate')"
                :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd HH:mm:ss"
              />
            </el-form-item>
          </el-col>
          <!--运单状态-->
          <el-col :span="8">
            <el-form-item :label="$t('newOrder.waybillStatus')">
              <el-select
                v-model="queryForm.params.status"
                filterable
                :placeholder="$t('operationCenter.PleaseSelect')"
                clearable
                style="width: 100%;"
              >
                <el-option :key="0" :label="$t('GlobalSetObj.all')" :value="null" />
                <el-option :key="1" :label="$t('GlobalSetObj.Withdrawn')" :value="15" />
                <el-option :key="2" :label="$t('GlobalSetObj.TheReturnComplete')" :value="16" />
              </el-select>
            </el-form-item>
          </el-col>
          <!--操作人-->
          <el-col :span="8">
            <el-form-item :label="$t('GlobalSetObj.operator')">
              <el-select
                v-model="queryForm.params.operatorId"
                :placeholder="$t('GlobalSetObj.Only10AreDisplayed')"
                :remote-method="getOperatorList"
                clearable
                filterable
                remote
                reserve-keyword
                style="width: 100%;"
              >
                <el-option :key="0" :label="$t('GlobalSetObj.all')" :value="null" />
                <el-option
                  v-for="(item, ind) in operatorList" :key="ind + 1" :label="item.realName + '（' + item.username + '）'"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <!--运单号-->
          <el-col :span="8">
            <el-form-item :label="$t('GlobalSetObj.waybillNumber')">
              <el-input
                v-model="queryForm.params.waybillNumbers" type="textarea" :rows="3"
                :placeholder="$t('orderCenterCont.MultipleLineBreaks')" style="width: 100%"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <div style="margin-top: 10px;">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="searchResources(1)"
          >{{ $t('operationCenter.Procurar') }}</el-button>
          <el-button size="small" icon="el-icon-refresh-left" @click="searchResources(2)">
            {{ $t('operationCenter.reset') }}
          </el-button>
        </div>
      </el-form>
    </el-card>
    <el-card class="box-card">
      <div style="margin-bottom: 10px;">
        <el-button
          type="primary"
          size="small"
          @click="exportData()"
        >{{ $t('operationCenter.exports') }}</el-button>
      </div>
      <el-table
        ref="reverseOrders"
        border
        :data="tableData"
      >
        <!--序号-->
        <el-table-column :label="$t('operationCenter.ind')" align="center" width="60px">
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!--退件时间-->
        <el-table-column :label="$t('GlobalSetObj.ReturnTime')" align="center" min-width="160px">
          <template slot-scope="scope">
            <div>{{ scope.row.registerTime }}</div>
          </template>
        </el-table-column>
        <!--运单号-->
        <el-table-column
          :label="$t('GlobalSetObj.waybillNumber')" prop="waybillNumber" align="center"
          min-width="160px"
        />
        <!--运单状态-->
        <el-table-column :label="$t('newOrder.waybillStatus')" align="center" min-width="160px">
          <template slot-scope="scope">
            <div>{{ scope.row.status }}</div>
          </template>
        </el-table-column>
        <!--退件签收时间-->
        <el-table-column
          :label="$t('collectionCenter.returnReceiptTime')" prop="signTime" align="center"
          min-width="160px"
        />
        <!--退件操作人-->
        <el-table-column
          :label="$t('GlobalSetObj.operator')" prop="registerUserName" align="center"
          min-width="160px"
        />
        <!--操作-->
        <el-table-column
          :label="$t('GlobalSetObj.operate')" align="center" fixed="right"
          width="160px"
        >
          <template slot-scope="scope">
            <div>
              <el-button size="mini" type="primary" @click="searchTrack(scope.row)">
                {{ $t('GlobalSetObj.TrackSearch') }}
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!--  分页  -->
      <el-pagination
        :current-page="queryForm.pageNumber"
        :page-size="queryForm.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 10px 0;float: right"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <!--轨迹-->
    <el-drawer
      :append-to-body="true"
      :title="$t('orderCenterCont.TrajectoryQuery')"
      :visible.sync="showQueryTrackVisible"
      custom-class="drawerClass"
      direction="ltr"
      size="80%"
    >
      <div style="height: calc(100vh - 67px); overflow: auto">
        <trackQuery :key="queryTrackKey" :query-track-param="queryTrackParam" query-type="1" />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getUserPage } from '@/api/user';
import { apiOrderWaybillExport, apiOrderWaybillPage } from '@/api/logisticsOrders';
import trackQuery from '@/views/orderCenter/logisticsOrders/components/trackQuery/trackQuery';
import { cloneDeep } from 'lodash-es';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList.js';

export default {
  name: 'ReturnWaybill',
  components: {
    trackQuery
  },
  data() {
    return {
      queryForm: {
        pageSize: 50,
        pageNumber: 1,
        params: {
          beginDateTime: '',
          endDateTime: '',
          waybillNumbers: '',
          status: null,
          operatorId: null
        }
      },
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      creationTime: [],
      operatorList: [],
      tableData: [],
      total: 0,
      showQueryTrackVisible: false,
      queryTrackKey: new Date().getTime(),
      queryTrackParam: {}
    };
  },
  created() {
    this.getCurrentDate();
    this.getOperatorList();
    this.getTableList();
  },
  methods: {
    // 查询轨迹
    searchTrack(row) {
      this.queryTrackParam = row;
      this.showQueryTrackVisible = true;
      this.queryTrackKey = new Date().getTime();
    },
    // 获取当前日期
    getCurrentDate() {
      const day = new Date();
      const brazilTime = day.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
      const today = dayjs(brazilTime).format('YYYY-MM-DD');
      const oneWeekAgo = dayjs(brazilTime).subtract(1, 'week').format('YYYY-MM-DD');
      this.creationTime = [oneWeekAgo + ' 00:00:00', today + ' 23:59:59'];
    },
    // 获取操作人
    getOperatorList(name) {
      const queryForm = {
        pageSize: 10,
        pageNumber: 1,
        params: {
          realName: name,
          accountState: 1
        }
      };
      getUserPage(queryForm).then((response) => {
        if (response.status === 'OK') {
          const { records } = response.data;
          this.operatorList = records;
        }
      });
    },
    // 搜索、重置
    searchResources(type) {
      if (type === 1) {
        this.queryForm.pageNumber = 1;
        this.getTableList();
      } else {
        this.getCurrentDate();
        this.queryForm = {
          pageSize: 50,
          pageNumber: 1,
          params: {
            beginDateTime: '',
            endDateTime: '',
            waybillNumbers: '',
            status: null,
            operatorId: null
          }
        };
      }
    },
    // 获取退件运单列表
    getTableList() {
      const queryParam = cloneDeep(this.queryForm);
      const param = {
        pageSize: queryParam.pageSize,
        pageNumber: queryParam.pageNumber,
        params: {
          beginDateTime: '',
          endDateTime: '',
          waybillNumbers: [],
          status: queryParam.params.status,
          operatorId: queryParam.params.operatorId
        }
      };
      if (this.creationTime && this.creationTime.length > 1) {
        param.params.beginDateTime = this.creationTime[0];
        param.params.endDateTime = this.creationTime[1];
      }
      if (queryParam.params.waybillNumbers) {
        const num = queryParam.params.waybillNumbers.split(/([\n\r])/);
        num.map(item => {
          const reg = /\s/g;
          const a = item.replace(reg, '');
          const b = a.trim();
          if (b) {
            param.params.waybillNumbers.push(b);
          }
        });
      }
      apiOrderWaybillPage(param).then(res => {
        const { records, total } = res.data;
        this.total = total;
        this.tableData = records;
      });
    },
    // 每页显示数量变化
    handleSizeChange(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.getTableList();
    },
    // 翻页变化
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getTableList();
    },
    // 导出
    exportData() {
      const queryParam = cloneDeep(this.queryForm.params);
      const param = {
        beginDateTime: '',
        endDateTime: '',
        waybillNumbers: [],
        status: queryParam.status,
        operatorId: queryParam.operatorId
      };
      if (this.creationTime && this.creationTime.length > 1) {
        param.beginDateTime = this.creationTime[0];
        param.endDateTime = this.creationTime[1];
      }
      if (queryParam.waybillNumbers) {
        const num = queryParam.waybillNumbers.split(/([\n\r])/);
        num.map(item => {
          const reg = /\s/g;
          const a = item.replace(reg, '');
          const b = a.trim();
          if (b) {
            param.waybillNumbers.push(b);
          }
        });
      }
      console.log(param, '====param');
      apiOrderWaybillExport(param).then(res => {
        goExportList(this);
      });
    }
  }
};
</script>

<style scoped>
.app-container >>> .drawerClass header {
  border-bottom: 1px solid #ccc !important;
  margin-bottom: 0 !important;
  padding: 20px 20px 20px !important;
}
.searchInputCon {
  margin-bottom: 10px;
}
.searchInputCon .el-form-item--mini.el-form-item,
.searchInputCon .el-form-item--small.el-form-item {
  margin-bottom: 5px;
}
</style>
