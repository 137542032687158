<template>
  <div class="app-container box">
    <el-form
      ref="queryForm"
      class="queryFormClass"
      size="small"
      :model="detailData"
      :rules="rules"
      label-width="auto"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <!--中文名称-->
          <el-form-item :label="$t('GlobalSetObj.ChineseName') + '：'" prop="chineseName">
            <el-input v-model="detailData.chineseName" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!--英文名称-->
          <el-form-item :label="$t('userManage.englishName') + '：'" prop="englishName">
            <el-input v-model="detailData.englishName" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!--货币代码-->
          <el-form-item :label="$t('GlobalSetObj.CurrencyCode') + '：'" prop="code">
            <el-input v-model="detailData.code" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!--默认汇率-->
          <el-form-item :label="$t('GlobalSetObj.DefaultExchangeRate') + '：'" prop="exchangeRate">
            <el-input v-model="detailData.exchangeRate" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!--状态-->
          <el-form-item :label="$t('GlobalSetObj.status') + '：'" prop="status">
            <el-radio-group v-model="detailData.status">
              <el-radio-button :label="0">{{ $t('GlobalSetObj.disable') }}</el-radio-button>
              <el-radio-button :label="1">{{ $t('GlobalSetObj.enable') }}</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <!--备注-->
          <el-form-item :label="$t('GlobalSetObj.remark') + '：'" prop="remark">
            <el-input
              v-model="detailData.remark" type="textarea" :rows="4"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div v-permit:remove="'btn:currencyManagement:edit'" style="text-align: center;">
      <el-button @click="saveFormData(2)">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
      <el-button type="primary" @click="saveFormData(1)">{{ $t('GlobalSetObj.Salve') }}</el-button>
    </div>
  </div>
</template>

<script>
import {
  addCurrencyType,
  editCurrencyType
} from '@/api/finance/partition';
export default {
  name: 'CheckDetail',
  props: {
    detailData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    operationType: {
      type: Number,
      default: 1
    }
  },
  data() {
    // 只能输入英文字母
    const englishLetter = (rule, value, callback) => {
      const reg = /[a-zA-Z]/;
      const { required } = rule;
      if (!required) {
        // 非必填
        return callback();
      }

      if (!value || (value.length === 0)) {
        // 不能为空
        return callback(new Error(this.$t('basicData.CannotBeEmpty')));
      }

      if (!reg.test(value)) {
        // '请输入英文字母'
        return callback(new Error(this.$t('basicData.PleaseEnterEnglishLetters')));
      }

      if (rule.length && (value.length !== rule.length)) {
        // '字符长度只能为*位'
        return callback(new Error(this.$t('GlobalSetObj.CharactersCanOnlyBe') + rule.length + this.$t('GlobalSetObj.digitsLong')));
      }
      return callback();
    };
    // 价格校验
    const priceType = (rule, value, callback) => {
      const reg = /(^[1-9]\d*(\.\d{1,5})?$)|(^0(\.\d{1,5})?$)/;
      const { required } = rule;
      if (!required) {
        // 非必填
        return callback();
      }
      if (!value || (value.length === 0)) {
        return callback(new Error(this.$t('basicData.CannotBeEmpty')));
      }
      if (!reg.test(value)) {
        return callback(new Error(this.$t('basicData.IncorrectFormatting')));
      }
      const valNum = Number(value);

      if (rule.max && (valNum >= rule.max)) {
        return callback(new Error(this.$t('GlobalSetObj.MustBeLessThan') + rule.max));
      }
      return callback();
    };
    return {
      rules: {
        chineseName: [
          { required: true, message: this.$t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
        ],
        code: [
          { required: true, validator: englishLetter, trigger: ['blur', 'change'] }
        ],
        englishName: [
          { required: true, validator: englishLetter, trigger: ['blur', 'change'] }
        ],
        exchangeRate: [
          { required: true, validator: priceType, trigger: ['blur', 'change'] }
        ],
        remark: [
          { required: false, message: this.$t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
        ]
      }
    };
  },
  methods: {
    saveFormData(type) {
      if (type === 1) {
        this.$refs['queryForm'].validate((valid) => {
          if (valid) {
            if (this.operationType === 1) {
              //  新增
              addCurrencyType(this.detailData).then(res => {
                if (res.status === 'OK') {
                  this.$message.success(this.$t('GlobalSetObj.SaveSuccessfully')); // '保存成功'
                  this.$emit('saveChange', type);
                }
              }).catch(err => {
                console.error(err);
              });
            } else {
              editCurrencyType(this.detailData).then(res => {
                if (res.status === 'OK') {
                  this.$message.success(this.$t('GlobalSetObj.SaveSuccessfully')); // '保存成功'
                  this.$emit('saveChange', type);
                }
              }).catch(err => {
                console.error(err);
              });
            }
          } else {
            return false;
          }
        });
      } else {
        this.$emit('saveChange', type);
      }
    }
  }
};
</script>

<style scoped>

</style>
