var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "background" },
    [
      _vm.tableData.length > 0
        ? _c(
            "div",
            { staticStyle: { "margin-bottom": "10px", color: "#f00" } },
            [_vm._v(_vm._s(_vm.tableData[0].msg))]
          )
        : _vm._e(),
      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
        _c("div", { staticClass: "colorCon" }, [
          _c("span", {
            staticClass: "colorClass colorClass1",
            staticStyle: { background: "#64B1FF" },
          }),
          _c("span", { staticClass: "colorTitle" }, [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "receivablePayable.ThePriceBecomesLowerAfterRecalculation"
                )
              ) +
                "（" +
                _vm._s(_vm.tableData[0].resultTypeCountMap[3] || 0) +
                "）"
            ),
          ]),
        ]),
        _c("div", { staticClass: "colorCon" }, [
          _c("span", {
            staticClass: "colorClass colorClass2",
            staticStyle: { background: "#FF3E3E" },
          }),
          _c("span", { staticClass: "colorTitle" }, [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "receivablePayable.ThePriceBecomesHigherAfterRecalculation"
                )
              ) +
                "（" +
                _vm._s(_vm.tableData[0].resultTypeCountMap[2] || 0) +
                "）"
            ),
          ]),
        ]),
        _c("div", { staticClass: "colorCon" }, [
          _c("span", {
            staticClass: "colorClass colorClass3",
            staticStyle: { background: "#999999" },
          }),
          _c("span", { staticClass: "colorTitle" }, [
            _vm._v(
              _vm._s(
                _vm.$t("receivablePayable.ThePriceAfterRecalculationIs0")
              ) +
                "（" +
                _vm._s(_vm.tableData[0].resultTypeCountMap[1] || 0) +
                "）"
            ),
          ]),
        ]),
        _c("div", { staticClass: "colorCon" }, [
          _c("span", {
            staticClass: "colorClass colorClass4",
            staticStyle: { background: "#FFFFFF", border: "1px solid #999999" },
          }),
          _c("span", { staticClass: "colorTitle" }, [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "receivablePayable.ThePriceRemainsUnchangedAfterRecalculation"
                )
              ) +
                "（" +
                _vm._s(_vm.tableData[0].resultTypeCountMap[0] || 0) +
                "）"
            ),
          ]),
        ]),
      ]),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            height: "400",
            "cell-class-name": _vm.myClass,
            border: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("operationCenter.ind"),
              width: "60px",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          (_vm.queryForm.pageNumber - 1) *
                            _vm.queryForm.pageSize +
                            scope.$index +
                            1
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._l(_vm.currentColumns, function (item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                prop: item.prop,
                label: item.label,
                align: item.fixed,
                width: item.width,
                "show-overflow-tooltip": true,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row[item.prop]))])]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "pagination" },
        [
          _c("el-pagination", {
            staticStyle: { margin: "10px 0" },
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              "current-page": _vm.queryForm.pageNumber,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.queryForm.pageSize,
              total: _vm.total,
              background: "",
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "current-change": _vm.getTablePageData,
              "size-change": _vm.getTablePageData,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "text-align": "right" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.confirmReplacement(true)
                },
              },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("receivablePayable.ConfirmTheReplacementPrice"))
              ),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.confirmReplacement(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("receivablePayable.CancelTheRecalculation")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }