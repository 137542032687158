import request from '@/utils/request';
import qs from 'qs';

// 客户收款单分页
export const apiCustomerReceiptPage = data => {
  return request.post('/fa/customerReceipt/page', data);
};

// 收款详情
export const apiCustomerReceiptDetailsPage = data => {
  return request.post('/fa/customerReceiptDetails/page', data);
};

// 获取客户账户列表
export const apiGetCustomerPage = data => {
  return request.post('/customer/page', data);
};
// 修改账期
export const apiUpdatePaymentCycle = data => {
  return request.post('/customer/updatePaymentCycle', qs.stringify(data));
};
// 获取网点账户列表
export const apiGetSiteAccountPage = data => {
  return request.post('/siteAccount/page', data);
};
// 设置网点账期
export const apiSiteAccountSetCycle = data => {
  return request.post('/siteAccount/setCycle', data);
};
// 导出网点账户信息
export const apiSiteAccountExportSiteAccountInfo = data => {
  return request.post('/siteAccount/exportSiteAccountInfo', data);
};
// 导出网点账户流水
export const apiSiteAccountExportExportOfBillFlow = data => {
  return request.post('/fa/SiteReceipt/exportOfBillFlow', data);
};
