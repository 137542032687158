<template>
  <div style="padding:0 20px;height: calc(100vh - 80px); overflow: auto;">
    <el-form
      :inline="true" :model="searchForm.params" class="search-form"
      label-position="top" size="small"
    >
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item :label="$t('GlobalSetObj.waybillNumber')" style="width: 100%">
            <el-input
              v-model="searchForm.params.waybillNumber"
              :placeholder="$t('newOrder.PreciseSearch2')"
              :rows="1"
              clearable
              style="width:100%"
              type="textarea"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('operationTips.lostType')" style="width: 100%">
            <el-select
              v-model="searchForm.params.lostType"
              :placeholder="$t('GlobalSetObj.all')"
              clearable
              style="width: 100%;"
            >
              <el-option
                v-for="item in lostTypeList"
                :key="item.id"
                :label="item.dictDescribe"
                :value="item.dictName"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div style="margin-bottom: 20px">
      <el-button size="small" type="primary" @click="entreaGetSearch">{{ $t('newOrder.search') }}</el-button>
      <el-button size="small" @click="resetForm">{{ $t('newOrder.reset') }}</el-button>
      <el-button size="small" @click="exportToExcel">{{ $t('newOrder.export') }}</el-button>
    </div>
    <el-table :data="tableData" border style="width: 100%;">
      <el-table-column
        :label="$t('GlobalSetObj.serial')" align="center" prop="index"
        type="index" width="60px"
      />
      <el-table-column
        :label="$t('GlobalSetObj.waybillNumber')" align="center" min-width="160px"
        prop="waybillNumber"
      />
      <el-table-column
        :label="$t('GlobalSetObj.infeatSite')"
        align="center"
        min-width="150px"
        prop="stationName"
      />
      <el-table-column
        :label="$t('operationTips.lostType')" align="center" min-width="130px"
        prop="lostTypeName"
      />
      <el-table-column
        :label="$t('orderCenterCont.TheLatestTraceTime')"
        align="center"
        min-width="160px"
        prop="lastTrackTime"
      />
      <el-table-column
        :label="$t('orderCenterCont.zuixinguijineir')"
        align="center"
        min-width="140px"
        prop="lastTrackContent"
      />
    </el-table>
    <div style="text-align: right">
      <el-pagination
        :current-page.sync="searchForm.pageNumber"
        :page-size.sync="searchForm.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 15px 0;"
        @current-change="getSearch"
        @size-change="getSearch"
      />
    </div>
  </div>
</template>

<script>
import { getLostdictCodeType, lostMonitorDetail, lostMonitorDetailExport } from '@/api/finance/basicData';
import dayjs from 'dayjs';

export default {
  props: {
    queryDateTime: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    dataobj: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      searchForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          waybillNumber: '',
          lostType: null
        }
      },
      tableData: [],
      total: 0,
      lostTypeList: []
    };
  },
  created() {
    this.getSearch();
    this.getLostType();
  },
  methods: {
    // 获取丢件类型
    getLostType() {
      getLostdictCodeType().then((res) => {
        if (res.status === 'OK') {
          this.lostTypeList = res.data;
        }
      });
    },
    getSearch() {
      const data = {
        pageNumber: this.searchForm.pageNumber,
        pageSize: this.searchForm.pageSize,
        params: {
          waybillNumberList: [],
          dataType: this.dataobj.dataType,
          beginDate: this.queryDateTime[0],
          endDate: this.queryDateTime[1],
          detailType: this.dataobj.detailType,
          siteIdList: this.dataobj.siteIdList,
          lostType: this.searchForm.params.lostType
        }
      };
      if (this.searchForm.params.waybillNumber) {
        data.params.waybillNumberList = this.searchForm.params.waybillNumber.split('\n');
      }
      console.log(this.searchForm, 'this.searchForm');
      lostMonitorDetail(data).then((res) => {
        console.log(res);
        if (res.status === 'OK') {
          this.total = res.data.total;
          this.tableData = res.data.records;
          this.tableData.forEach((item) => {
            item.lostTypeName = item.lostTypeName ? item.lostTypeName : '--';
          });
        }
      });
    },
    exportToExcel() {
      const data = {
        waybillNumberList: [],
        dataType: this.dataobj.dataType,
        beginDate: this.queryDateTime[0],
        endDate: this.queryDateTime[1],
        detailType: this.dataobj.detailType,
        lostType: this.searchForm.params.lostType,
        siteIdList: this.dataobj.siteIdList
      };
      if (this.searchForm.params.waybillNumber) {
        data.waybillNumberList = this.searchForm.params.waybillNumber.split('\n');
      }
      lostMonitorDetailExport(data).then(res => {
        if (res.status === 'OK') {
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          })
            .then((action) => {
              if (action === 'confirm') {
                this.$emit('call-parent-method', false);
                this.$router.push({ path: '/resource/exportList' });
              }
            })
            .catch(() => {
            });
        }
      });
    },
    resetForm() {
      this.searchForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          waybillNumber: '',
          lostType: null
        }
      };
      this.getSearch();
    },
    entreaGetSearch() {
      this.searchForm.pageNumber = 1;
      this.getSearch();
    },
    resetForm1() {
      this.searchForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          waybillNumber: '',
          lostType: null
        }
      };
    },
    // 日期选择器失去焦点
    refreshDate() {
      this.choiceDate0 = '';
    },
    // 获取最近30天时间
    getAWeek() {
      return {
        endDate: dayjs().format('YYYY-MM-DD') + ' 23:59:59',
        startDate: dayjs().subtract(30, 'day').format('YYYY-MM-DD') + ' 00:00:00'
      };
    }
  }
};
</script>

<style scoped>
.search-form {
  margin-bottom: 20px;
}

>>> .el-form-item {
  margin-bottom: 0 !important;
}
</style>
