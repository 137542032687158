<template> <!-- 商品信息 -->
  <div>
    <!-- 查看 -->
    <ProductReadOnlyCmp v-if="disabled" :height="tableHeight" :arr="formData.arr" />

    <!-- 编辑 -->
    <el-form
      v-else
      ref="ruleForm"
      :model="formData"
      class="demo-ruleForm"
      label-width="0"
      size="mini"
    >
      <el-table
        :data="formData.arr"
        :height="tableHeight"
        border
      >

        <el-table-column :label="$t('newOrder.SerialNumber')" type="index" width="50" />
        <!-- 名称 -->
        <el-table-column
          :label="$t('GlobalSetObj.Name') + '*'"
          prop="start"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="`arr.${scope.$index}.name`"
              :rules="rules.name"
            >
              <el-input
                v-model="scope.row.name" :autosize="{ minRows: 2, maxRows: 4}"
                :placeholder="$t('GlobalSetObj.pleaseInput')" type="textarea"
              />
            </el-form-item>
          </template>
        </el-table-column>

        <!-- 数量 -->
        <el-table-column
          :label="$t('newOrder.Quantity') + '*'"
          width="180"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="`arr.${scope.$index}.quantity`"
              :rules="rules.name"
            >
              <el-input-number
                v-model="scope.row.quantity" :controls="false" :max="999999999"
                :min="0" :placeholder="$t('GlobalSetObj.pleaseInput')" :precision="0"
              />
            </el-form-item>
          </template>

        </el-table-column>

        <!-- 尺寸 -->
        <el-table-column
          :label="$t('newOrder.Size')"
        >
          <template slot-scope="scope">
            <div class="table-size">
              <!-- 长度 -->
              <el-form-item
                :label="$t('operationCenter.length')"
                label-width="80px"
              >
                <el-input-number
                  v-model="scope.row.length" :controls="false" :max="999999"
                  :min="0" :placeholder="$t('GlobalSetObj.pleaseInput')"
                  :precision="2" style="width: 120px"
                />
              </el-form-item>

              <!-- 宽度 -->
              <el-form-item
                :label="$t('operationCenter.width')"
                label-width="80px"
              >
                <el-input-number
                  v-model="scope.row.width" :controls="false" :max="999999"
                  :min="0" :placeholder="$t('GlobalSetObj.pleaseInput')"
                  :precision="2" style="width: 120px"
                />
              </el-form-item>

              <!-- 高度 -->
              <el-form-item
                :label="$t('operationCenter.height')"
                label-width="80px"
              >
                <el-input-number
                  v-model="scope.row.height" :controls="false" :max="999999"
                  :min="0" :placeholder="$t('GlobalSetObj.pleaseInput')"
                  :precision="2" style="width: 120px"
                />
              </el-form-item>
            </div>
          </template>

        </el-table-column>

        <!-- 申报 -->
        <el-table-column
          :label="$t('newOrder.Declaration') + '*'"
        >
          <template slot-scope="scope">

            <!-- 申报单价 -->
            <div class="table-size">
              <el-form-item
                :label="$t('orderCenterCont.DeclareUnitPrice')"
                :prop="`arr.${scope.$index}.declaredValue`"
                :rules="rules.name"
                label-width="120px"
              >
                <el-input-number
                  v-model="scope.row.declaredValue" :controls="false" :max="999999999"
                  :min="0" :placeholder="$t('GlobalSetObj.pleaseInput')" :precision="2"
                  style="width: 120px"
                />
              </el-form-item>

              <!-- 申报重量 -->
              <el-form-item
                :label="$t('orderCenterCont.DeclaredWeight')"
                :prop="`arr.${scope.$index}.declaredWeight`"
                :rules="rules.name"
                label-width="120px"
              >
                <el-input-number
                  v-model="scope.row.declaredWeight" :controls="false" :max="999999999"
                  :min="0" :placeholder="$t('GlobalSetObj.pleaseInput')" :precision="2"
                  style="width: 120px"
                />
              </el-form-item>
            </div>
          </template>
        </el-table-column>
      </el-table>
    <!-- <p @click="submit">测试</p> -->
    </el-form>
  </div>
</template>

<script>
import ProductReadOnlyCmp from './ProductReadOnlyCmp.vue';

export default {
  name: '',

  components: {
    ProductReadOnlyCmp
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    // 高度
    height: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      formData: {
        arr: []
      },

      rules: {
        name: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'blur' }
        ]

      }
    };
  },

  computed: {
    tableHeight() {
      return this.height - 70;
    }
  },

  created() {

  },

  methods: {

    getData() {
      return this.formData.arr;
    },

    setData(arr) {
      this.formData.arr = arr.map(item => {
        return { ...item };
        // const { name, quantity, width, height, length, declaredValue, declaredWeight } = item;

        // return {
        //   goodName: name, // 商品名称
        //   quantity, // 数量
        //   length, // 长度
        //   width, // 宽度
        //   height, // 高度
        //   declaredValue, // 申报单价
        //   declaredWeight // 申报重量
        // };
      });
    },

    submit() {
      let isBool = false;
      this.$refs['ruleForm'].validate((valid) => {
        console.log(valid);

        if (!valid) {
          // alert('submit!');

        } else {
          isBool = true;
        }
      });
      return isBool;
    }
  }

};
</script>

<style scoped>
.table-size {
  display: flex;
  flex-direction: column;
  /* border: 1px solid #000; */
}
</style>
