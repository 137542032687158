// 用户ID和名称匹配
export const USERID_AND_NAME = 'userIdAndName';
// 组织机构ID和名称匹配
export const ORGID_AND_NAME = 'orgIdAndName';
// 组织结构树形
export const ORGANIZATION_TREE = 'organizationTree';
// 职位列表
export const POSITION_LIST = 'positionList';

/**
 * 用户列表
 */
export const getUserIdAndNameObj = (function() {
  let obj = '';
  /**
   * isUpdata { boolean } true 默认从缓存中拿数据
   */
  return function(isUpdata = true) {
    try {
      if (isUpdata || (obj === '')) {
        obj = JSON.parse(localStorage.getItem(USERID_AND_NAME)) || {};
      }
      return obj;
    } catch (error) {
      console.error(error);
    }
  };
})();

/**
 * 组织机构
 */
export const getOrgIdAndNameObj = (function() {
  let obj = '';
  /**
   * isUpdata { boolean } 默认从缓存中拿数据
   */
  return function(isUpdata = true) {
    try {
      if (isUpdata || (obj === '')) {
        obj = JSON.parse(localStorage.getItem(ORGID_AND_NAME)) || {};
      }
      return obj;
    } catch (error) {
      console.error(error);
    }
  };
})();

/**
 * 组织机构 树状结构
 */
export const getOrganizationTreeObj = (function() {
  let arr = '';
  /**
   * isUpdata { boolean } 默认从缓存中拿数据
   */
  return function(isUpdata = true) {
    try {
      if (isUpdata || (arr === '')) {
        arr = JSON.parse(localStorage.getItem(ORGANIZATION_TREE)) || [];
      }
      return arr;
    } catch (error) {
      console.error(error);
    }
  };
})();

/**
 * 职位列表
 */
export const getPositionListObj = (function() {
  let obj = '';
  /**
   * isUpdata { boolean } 默认从缓存中拿数据
   */
  return function(isUpdata = true) {
    try {
      if (isUpdata || (obj === '')) {
        obj = JSON.parse(localStorage.getItem(POSITION_LIST)) || {};
      }
      return obj;
    } catch (error) {
      console.error(error);
    }
  };
})();
