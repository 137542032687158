<template> <!-- 揽收人选择 -->
  <el-select
    v-model="dispatcherList"
    filterable
    clearable
    remote
    :placeholder="$t('customerManagements.pleaseChoose') + $t('GlobalSetObj.Collect')"
    :loading="loading"
    multiple
    :multiple-limit="10"
    :remote-method="getDeliveryPage"
    style="width: 100%;"
    @change="onChaneg"
  >
    <el-option
      v-for="item in list"
      :key="item.value + '' + item.label"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
import { apiQueryCollectManNew } from '@/api/lanshou';

// const QUERY_DATA = {
//   pageNumber: 1,
//   pageSize: 20,
//   params: {
//     deliveryManName: ''
//   }
// };

export default {
  name: '',

  // components: {
  //   ElSelectV2
  // },

  props: {
    val: {
      type: [Object, String, Number],
      default: ''
    }

  },

  data() {
    return {
      loading: false,
      list: [], // 所有揽收人
      dispatcherList: ''
    };
  },

  watch: {
    'val': {
      immediate: true,
      deep: true,
      handler(val) {
        // // this.dispatcherList = val;
        if (val === '') {
          this.dispatcherList = '';
          this.getDeliveryPage(' ');
          return;
        }

        if (val && val.dispatcherId) {
          this.dispatcherList = val;
          this.getDeliveryPage(val.dispatcherName);
          return;
        }

      // if (Array.isArray(val) && val.length > 0) {
      //   this.dispatcherList = val;
      // }
      }

    }
  },

  created() {

  },

  methods: {
    // 获取揽收
    getDeliveryPage(name = '') {
      this.loading = true;

      apiQueryCollectManNew(name).then(res => {
        this.list = (res.data || []).map(item => {
          const { driverId, driverName, username } = item;

          const obj = {
            value: driverId,
            label: `${driverName}(${username})`
          };
          return Object.freeze(obj);
        });
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },

    onChaneg(val) {
      this.$emit('change', val);
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
