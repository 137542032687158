<script >
import { defineProps, ref } from '@vue/composition-api';
import { default as DropdownTable } from '@/components/dropdownTable';
import { default as FormCreateSeller } from './form/formCreateSeller';
import { useModal } from '@/composable/useModal';
import { sellerService } from '@/api/transport/seller';
import { addingLoading, closeLoading } from '@/utils/handleControlLoading';
import { toast } from '@/utils/programaticToast';
import { getToastContentComponent } from '@/utils/getToastContentComponent';
const __sfc_main = {};
__sfc_main.props = {
  row: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    isOpen: isOpenModalCreate,
    handleVisibility: handleVisibleModalCreate
  } = useModal();
  const sellerData = ref({});
  async function handleOpenModalCreate() {
    if (parseInt(props.row.seller_code) === 0) {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Ocorreu um erro',
          describe: 'Não é possível cadastrar com seller code 0',
          typeIcon: 'error'
        }),
        closable: true,
        duration: 4000
      });
      return;
    }
    try {
      addingLoading();
      const detailSeller = await sellerService.consultAddress(props.row.seller_code);
      sellerData.value = {
        ...props.row,
        seller_address: null,
        seller_address_neighborhood: null,
        seller_address_municipality: null,
        seller_state: null,
        nameContact: detailSeller.data.sender_name,
        document: detailSeller.data.sender_tax,
        phone: detailSeller.data.sender_real_phone,
        email: detailSeller.data.sender_mail,
        complement: detailSeller.data.sender_address
      };
      handleVisibleModalCreate();
    } catch (error) {
      sellerData.value = {
        ...props.row,
        nameContact: null,
        document: null,
        phone: null,
        email: null,
        complement: null
      };
      handleVisibleModalCreate();
    } finally {
      closeLoading();
    }
  }
  return {
    isOpenModalCreate,
    handleVisibleModalCreate,
    sellerData,
    handleOpenModalCreate
  };
};
__sfc_main.components = Object.assign({
  DropdownTable,
  FormCreateSeller
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <DropdownTable>
      <o-dropdown-item aria-role="listitem" @click="handleOpenModalCreate">
        {{ $t('seller.create') }}
      </o-dropdown-item>
    </DropdownTable>

    <o-modal :active="isOpenModalCreate" width="560" @close="handleVisibleModalCreate">
      <div>
        <h2 class="header-2">{{ $t('seller.createTitle') }}</h2>
        <p class="paragraph">{{ $t('seller.createParagraph') }}</p>
      </div>

      <FormCreateSeller :seller="sellerData" :close-modal="handleVisibleModalCreate" />
    </o-modal>
  </div>
</template>
