var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "MonitoringReportBox" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          model: {
            value: _vm.findActive,
            callback: function ($$v) {
              _vm.findActive = $$v
            },
            expression: "findActive",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("collectionCenter.byTimeRange"),
                name: "timeFind",
              },
            },
            [_c("timeRange")],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("collectionCenter.byOdd"),
                name: "oddFind",
              },
            },
            [_c("odd")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }