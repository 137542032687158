<template>
  <div class="content">
    <div style="height: calc(100% - 55px); overflow-y: scroll;">
      <!-- 基础信息 -->
      <el-card>
        <div slot="header" class="clearfix">
          <span>{{ $t('newOrder.BasicInformation') }}</span>
        </div>
        <BasicForm ref='basicForm' :status="claimsStatus" @change="updateForm" @removeChange="remove"/>
      </el-card>

      <!-- 关联订单 -->
      <el-card>
        <div slot="header" class="clearfix">
          <span>{{ $t('ClaimsManagement.LinkedOrder') }}</span>
        </div>
        <BasicTable ref="basicTable" :formData="formList" :status="claimsStatus" @change="clickUpdate"/>
      </el-card>
    </div>

    <!-- 操作区 -->
    <div class="footer">
      <el-row :gutter="20">
        <template v-for="(item, index) in btnList">
          <el-button size="small" :key="index" :type="item.type" @click="btnClick(item.btnType)">{{ item.name }}</el-button>
        </template>
      </el-row>
    </div>
  </div>
</template>

<script>
import i18n from '@/lang';
import BasicForm from './basicForm.vue';
import BasicTable from './basicTable.vue';
import { commonMinXin } from '../../commonMinXin.js'
  
export default {
  data() {
    return {
      formList: {}, // 基础信息
      claimsStatus: 0, // 索赔状态
      auditStatus: 2, // 1 提交 2 保存

      btnList: [
        {
          type: '',
          btnType: 'cancel',
          name: i18n.t('GlobalSetObj.cancel'),
        },
        {
          type: 'success',
          btnType: 'save',
          name: i18n.t('customerManagements.Save'),
        },
        {
          type: 'primary',
          btnType: 'submit',
          name: i18n.t('customerManagements.submit'),
        }
      ],
    }
  },

  components: {
    BasicForm,
    BasicTable,
  },

  mixins: [commonMinXin],

  methods: {
    // 按钮点击事件
    btnClick(type) {
      switch (type) {
        case 'cancel':
          this.$emit('close', false);
          break;
        case 'save':
          this.save();
          break;
        case 'submit':
          this.submit();
          break;
      }   
    },
  }
}
</script>

<style scoped lang="scss">
.content {
  height: calc(100vh - 75px);
  overflow-y: auto;
}

.el-card {
  margin-bottom: 10px;
}

.clearfix {
  padding-left: 10px;
}

.footer {
  padding: 10px 20px;
  display: flex;
  flex-direction: row-reverse;
  position: sticky;
  bottom: 0;
  border-top: 1px solid #ebeef5;
  background-color: #fff;
  z-index: 999;
}
</style>