<template>
  <el-table
    ref="elTable"
    border
    :data="tableData"
  >
    <el-table-column
      :label="name!=='szanjun'?$t('newOrder.pickUpDate'): $t('newOrder.TransitInboundTime')"
      align="center"
      width="200px"
    >
      <template slot="header">
        <template v-if="name!=='szanjun'">
          <p>{{ $t('newOrder.pickUpDate','zh') }}</p>
          <p>{{ $t('newOrder.pickUpDate','pu') }}</p>
        </template>
        <template v-else>
          <p>{{ $t('newOrder.TransitInboundTime','zh') }}</p>
          <p>{{ $t('newOrder.TransitInboundTime','pu') }}</p>
        </template>
      </template>
      <template slot-scope="scope">
        <span>{{ scope.row.date }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="name!=='szanjun'?$t('newOrder.pickUpDay'): $t('newOrder.transitWarehouseWeek')"
      width="170px"
    >
      <template slot="header">
        <template v-if="name!=='szanjun'">
          <p>{{ $t('newOrder.pickUpDay','zh') }}</p>
          <p>{{ $t('newOrder.pickUpDay','pu') }}</p>
        </template>
        <template v-else>
          <p>{{ $t('newOrder.transitWarehouseWeek','zh') }}</p>
          <p>{{ $t('newOrder.transitWarehouseWeek','pu') }}</p>
        </template>
      </template>
      <template slot-scope="scope">
        <span>{{ weekObj[scope.row.week] }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('newOrder.orderVolume')"
      width="130px"
    >
      <template slot="header">
        <p>{{ $t('newOrder.orderVolume','zh') }}</p>
        <p>{{ $t('newOrder.orderVolume','pu') }}</p>
      </template>
      <template slot-scope="scope">
        <span>{{ scope.row.createTotal }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('newOrder.pickUpVolume')"
      width="140px"
    >
      <template slot="header">
        <p>{{ $t('newOrder.pickUpVolume','zh') }}</p>
        <p>{{ $t('newOrder.pickUpVolume','pu') }}</p>
      </template>
      <template slot-scope="scope">
        <span>{{ scope.row.collectTotal }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('newOrder.D0deliveryVolume')"
      width="200px"
    >
      <template slot="header">
        <p>{{ $t('newOrder.D0deliveryVolume','zh') }}</p>
        <p>{{ $t('newOrder.D0deliveryVolume','pu') }}</p>
      </template>
      <template slot-scope="scope">
        <span>{{ scope.row.zeroDayPer==='-'?'-':scope.row.zeroDay }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('newOrder.D0DeliveryRate')"
      width="210px"
    >
      <template slot="header">
        <p>{{ $t('newOrder.D0DeliveryRate','zh') }}</p>
        <p>{{ $t('newOrder.D0DeliveryRate','pu') }}</p>
      </template>
      <template slot-scope="scope">
        <span>{{ scope.row.zeroDayPer }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('newOrder.D1deliveryVolume')"
      width="200px"
    >
      <template slot="header">
        <p>{{ $t('newOrder.D1deliveryVolume','zh') }}</p>
        <p>{{ $t('newOrder.D1deliveryVolume','pu') }}</p>
      </template>
      <template slot-scope="scope">
        <span>{{ scope.row.firstDayPer==='-'?'-':scope.row.firstDay }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('newOrder.D1DeliveryRate')"
      width="210px"
    >
      <template slot="header">
        <p>{{ $t('newOrder.D1DeliveryRate','zh') }}</p>
        <p>{{ $t('newOrder.D1DeliveryRate','pu') }}</p>
      </template>
      <template slot-scope="scope">
        <span>{{ scope.row.firstDayPer }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('newOrder.D2deliveryVolume')"
      width="200px"
    >
      <template slot="header">
        <p>{{ $t('newOrder.D2deliveryVolume','zh') }}</p>
        <p>{{ $t('newOrder.D2deliveryVolume','pu') }}</p>
      </template>
      <template slot-scope="scope">
        <span>{{ scope.row.secondDayPer==='-'?'-':scope.row.secondDay }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('newOrder.D2DeliveryRate')"
      width="210px"
    >
      <template slot="header">
        <p>{{ $t('newOrder.D2DeliveryRate','zh') }}</p>
        <p>{{ $t('newOrder.D2DeliveryRate','pu') }}</p>
      </template>
      <template slot-scope="scope">
        <span>{{ scope.row.secondDayPer }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('newOrder.D3deliveryVolume')"
      width="200px"
    >
      <template slot="header">
        <p>{{ $t('newOrder.D3deliveryVolume','zh') }}</p>
        <p>{{ $t('newOrder.D3deliveryVolume','pu') }}</p>
      </template>
      <template slot-scope="scope">
        <span>{{ scope.row.thirdDayPer==='-'?'-':scope.row.thirdDay }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('newOrder.D3DeliveryRate')"
      width="210px"
    >
      <template slot="header">
        <p>{{ $t('newOrder.D3DeliveryRate','zh') }}</p>
        <p>{{ $t('newOrder.D3DeliveryRate','pu') }}</p>
      </template>
      <template slot-scope="scope">
        <span>{{ scope.row.thirdDayPer }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('newOrder.D4deliveryVolume')"
      width="200px"
    >
      <template slot="header">
        <p>{{ $t('newOrder.D4deliveryVolume','zh') }}</p>
        <p>{{ $t('newOrder.D4deliveryVolume','pu') }}</p>
      </template>
      <template slot-scope="scope">
        <span>{{ scope.row.fourthDayPer==='-'?'-':scope.row.fourthDay }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('newOrder.D4DeliveryRate')"
      width="210px"
    >
      <template slot="header">
        <p>{{ $t('newOrder.D4DeliveryRate','zh') }}</p>
        <p>{{ $t('newOrder.D4DeliveryRate','pu') }}</p>
      </template>
      <template slot-scope="scope">
        <span>{{ scope.row.fourthDayPer }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('newOrder.D5deliveryVolume')"
      width="200px"
    >
      <template slot="header">
        <p>{{ $t('newOrder.D5deliveryVolume','zh') }}</p>
        <p>{{ $t('newOrder.D5deliveryVolume','pu') }}</p>
      </template>
      <template slot-scope="scope">
        <span>{{ scope.row.fifthDayPer==='-'?'-':scope.row.fifthDay }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('newOrder.D5DeliveryRate')"
      width="210px"
    >
      <template slot="header">
        <p>{{ $t('newOrder.D5DeliveryRate','zh') }}</p>
        <p>{{ $t('newOrder.D5DeliveryRate','pu') }}</p>
      </template>
      <template slot-scope="scope">
        <span>{{ scope.row.fifthDayPer }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('newOrder.D6deliveryVolume')"
      width="200px"
    >
      <template slot="header">
        <p>{{ $t('newOrder.D6deliveryVolume','zh') }}</p>
        <p>{{ $t('newOrder.D6deliveryVolume','pu') }}</p>
      </template>
      <template slot-scope="scope">
        <span>{{ scope.row.sixthDayPer==='-'?'-':scope.row.sixthDay }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('newOrder.D6DeliveryRate')"
      width="210px"
    >
      <template slot="header">
        <p>{{ $t('newOrder.D6DeliveryRate','zh') }}</p>
        <p>{{ $t('newOrder.D6DeliveryRate','pu') }}</p>
      </template>
      <template slot-scope="scope">
        <span>{{ scope.row.sixthDayPer }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('newOrder.D7deliveryVolume')"
      width="200px"
    >
      <template slot="header">
        <p>{{ $t('newOrder.D7deliveryVolume','zh') }}</p>
        <p>{{ $t('newOrder.D7deliveryVolume','pu') }}</p>
      </template>
      <template slot-scope="scope">
        <span>{{ scope.row.seventhDayPer==='-'?'-':scope.row.seventhDay }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('newOrder.D7DeliveryRate')"
      width="210px"
    >
      <template slot="header">
        <p>{{ $t('newOrder.D7DeliveryRate','zh') }}</p>
        <p>{{ $t('newOrder.D7DeliveryRate','pu') }}</p>
      </template>
      <template slot-scope="scope">
        <span>{{ scope.row.seventhDayPer }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('newOrder.D8deliveryVolume')"
      width="200px"
    >
      <template slot="header">
        <p>{{ $t('newOrder.D8deliveryVolume','zh') }}</p>
        <p>{{ $t('newOrder.D8deliveryVolume','pu') }}</p>
      </template>
      <template slot-scope="scope">
        <span>{{ scope.row.eighthDayPer==='-'?'-':scope.row.eighthDay }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('newOrder.D8DeliveryRate')"
      width="210px"
    >
      <template slot="header">
        <p>{{ $t('newOrder.D8DeliveryRate','zh') }}</p>
        <p>{{ $t('newOrder.D8DeliveryRate','pu') }}</p>
      </template>
      <template slot-scope="scope">
        <span>{{ scope.row.eighthDayPer }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('newOrder.deliverySuccessRate')"
      width="160px"
    >
      <template slot="header">
        <p>{{ $t('newOrder.deliverySuccessRate','zh') }}</p>
        <p>{{ $t('newOrder.deliverySuccessRate','pu') }}</p>
      </template>
      <template slot-scope="scope">
        <span>{{ scope.row.completedPer }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('newOrder.undeliveredCount')"
      width="180px"
    >
      <template slot="header">
        <p>{{ $t('newOrder.undeliveredCount','zh') }}</p>
        <p>{{ $t('newOrder.undeliveredCount','pu') }}</p>
      </template>
      <template slot-scope="scope">
        <span>{{ scope.row.unCompleted }}</span>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
export default {
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      }
    },
    // 当前选择的标签
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      weekObj: {
        'MONDAY': this.$t('newOrder.monday', 'zh') + `（${this.$t('newOrder.monday', 'pu')}）`,
        'TUESDAY': this.$t('newOrder.tuesday', 'zh') + `（${this.$t('newOrder.tuesday', 'pu')}）`,
        'WEDNESDAY': this.$t('newOrder.wednesday', 'zh') + `（${this.$t('newOrder.wednesday', 'pu')}）`,
        'THURSDAY': this.$t('newOrder.thursday', 'zh') + `（${this.$t('newOrder.thursday', 'pu')}）`,
        'FRIDAY': this.$t('newOrder.friday', 'zh') + `（${this.$t('newOrder.friday', 'pu')}）`,
        'SATURDAY': this.$t('newOrder.saturday', 'zh') + `（${this.$t('newOrder.saturday', 'pu')}）`,
        'SUNDAY': this.$t('newOrder.sunday', 'zh') + `（${this.$t('newOrder.sunday', 'pu')}）`
      }
    };
  }
};
</script>
<style scoped lang="scss">

</style>
