<script >
import { ref, defineProps } from '@vue/composition-api';
import { default as AvailableSellers } from './step/availableSellers';
import { useCreateRouter } from '@/api/transport/router/query';
const __sfc_main = {};
__sfc_main.props = {
  closeModal: {
    type: Function
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const sellers = ref([]);
  const {
    mutate,
    isLoading
  } = useCreateRouter();
  async function handleSubmit(formData) {
    mutate(sellers.value, {
      onSuccess: () => {
        props.closeModal();
      }
    });
  }
  function onUpdateSellers(newSellers) {
    sellers.value = newSellers;
  }
  return {
    sellers,
    isLoading,
    handleSubmit,
    onUpdateSellers
  };
};
__sfc_main.components = Object.assign({
  AvailableSellers
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <AvailableSellers
      :sellers="sellers"
      :handle-next="handleSubmit"
      :is-last-step="true"
      :is-first-step="true"
      @updateSeller="onUpdateSellers"
    />

    <o-loading :full-page="true" :active.sync="isLoading" />
  </div>
</template>
