<template>
  <!--          折线图-->
  <div id="lineChart" style="width: 100%; height: 380px;" />
</template>
<script>
import * as echarts from 'echarts';
import { nextTick } from '@vue/composition-api';

export default {
  name: 'LineChart',
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    dateData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chart: null
    };
  },
  watch: {
    chartData: {
      handler(newVal, oldVal) {
        nextTick(() => {
          this.initLineChart(this.dateData, newVal);
        });
      },
      deep: true
    }
  },
  mounted() {
    this.chart = echarts.init(document.getElementById('lineChart'));
    window.onresize = () => {
      this.chart && this.chart.resize();
    };
    // 折线图初始化
    // this.initLineChart();
  },
  methods: {
    /**
     * 折线图
     */
    initLineChart(date, data) {
      // 生成随机颜色
      function getRandomColor() {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        return `rgb(${r}, ${g}, ${b})`;
      }
      // 将十六进制颜色转换为 RGB 字符串
      function hexToRgb(hex) {
        // 确保移除 # 号并处理缩写形式（如 #fff）
        hex = hex.replace(/^#/, '');
        if (hex.length === 3) {
          hex = hex.split('').map(char => char + char).join('');
        }

        // 验证输入
        if (!/^[0-9A-Fa-f]{6}$/.test(hex)) {
          throw new Error('Invalid hex color format');
        }

        // 解析颜色值
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        // 返回 RGB 字符串，因为 generateGradient 函数期望的是字符串形式
        return `${r}, ${g}, ${b}`;
      }

      // 更新后的渐变生成函数
      function generateGradient(color) {
        let rgbString;
        if (color.startsWith('#')) {
          rgbString = hexToRgb(color);
        } else if (color.startsWith('rgb')) {
          // 如果已经是 RGB 格式，直接提取数字
          rgbString = color.replace(/[^\d,]/g, '');
        } else {
          throw new Error('Invalid color format');
        }

        return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: `rgba(${rgbString}, 0.3)` },
          { offset: 0.5, color: `rgba(${rgbString}, 0.1)` },
          { offset: 1, color: `rgba(${rgbString}, 0)` }
        ]);
      }

      // 颜色列表
      const colors = ['rgba(91, 197, 169, 0.6)', 'rgba(247, 147, 30, 0.6)', 'rgba(53, 127, 241, 0.6)'];
      const option = {
        title: {
          text: this.$t('newOrder.CustomerOrderTrendLast7Days'),
          textStyle: {
            fontFamily: 'PingFang SC',
            fontWeight: 'bold',
            fontSize: 16,
            color: '#1C1C1C'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#ffffff'
            }
          }
        },
        legend: {
          orient: 'horizontal', // 图例垂直排列
          right: '0%', // 图例距离右侧 0%
          textStyle: {
            color: '#333333' // 图例文字颜色
          },
          data: []
        },
        toolbox: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            splitLine: { // 配置 x 轴的分隔线
              show: false,
              lineStyle: {
                type: 'dashed', // 设置为虚线
                color: '#EDEDED', // 虚线颜色
                width: 1, // 虚线宽度
                opacity: 1 // 虚线透明度
              }
            },
            // 去除x轴底部的线
            axisLine: {
              show: false
            },
            // 去除x轴底部的刻度
            axisTick: {
              show: false
            },
            data: date,
            // x轴文字颜色加粗
            axisLabel: {
              formatter: '{value}', // 显示w
              color: '#1A1A1A',
              fontWeight: 400,
              // 设置字体和字号
              fontFamily: 'Arial',
              fontSize: 14
            }
          }
        ],
        yAxis: [
          {
            // 设置网格线为虚线
            splitLine: { // 网格线
              lineStyle: {
                type: 'dashed', // 设置网格线类型 dotted：虚线   solid:实线
                color: '#EDEDED'
              },
              show: true // 隐藏或显示
            },
            type: 'value',
            // x轴文字颜色加粗
            axisLabel: {
              color: 'rgba(132, 132, 132, 1)',
              fontWeight: '400',
              // 设置字体和字号
              fontFamily: 'Arial',
              fontSize: 12
            }
          }
        ],
        series: [
        ]
      };
      // 客户名称列表
      const names = [];
      data.forEach((item, index) => {
        const dynamicColor = getRandomColor(); // 获取动态颜色
        // console.log(generateGradient(dynamicColor),'dynamicColor')
        names.push(item.customerName);
        option.series.push({
          name: item.customerName,
          type: 'line',
          // 设置成圆的线
          smooth: false,
          itemStyle: {
            borderWidth: 1,
            borderColor: dynamicColor,
            color: dynamicColor
          },
          areaStyle: {
            lineStyle: {
              opacity: 0.5
            },
            color: generateGradient(dynamicColor)
          },
          emphasis: {
            focus: 'series'
          },
          symbol: 'circle', // 设置数据点的形状为圆形
          symbolSize: 8, // 设置数据点的大小
          showSymbol: true, // 始终显示数据点
          label: {
            show: true, // 显示数据标签
            position: 'top', // 标签位置
            fontSize: 14, // 标签字体大小
            fontFamily: 'PingFang SC', // 标签字体
            formatter: '{c}', // 标签格式，{c}表示数值
            color: 'inherit'
          },
          data: item.total
        });
      });
      console.log(option, 'option');
      option.legend.data = names;
      this.chart.setOption(option, {
        notMerge: true
      });
    }
  }
};
</script>

<style scoped>

</style>
