<template>
  <div class="app-container">
    <div style="position:relative;min-height: 40px;">
      <el-button
        v-if="queryBoxShow"
        class="putAway"
        size="small"
        @click="queryBoxShow = !queryBoxShow"
      >{{ $t('Resource.CollapseQuery') }}
      </el-button>
      <el-button
        v-else
        class="putAway"
        size="small"
        @click="queryBoxShow = !queryBoxShow"
      >{{ $t('Resource.ExpandQuery') }}
      </el-button>
      <div
        v-show="queryBoxShow"
        class="searchContainer"
      >
        <el-form
          ref="queryForm"
          :model="queryForm.params"
          class="queryFormClass"
          size="small"
          @submit.native.prevent
        >
          <el-row
            :gutter="20" align="center" justify="left"
            style="flex-wrap: wrap;" type="flex"
          >
            <el-col :span="8" style="width: auto;">
              <!-- 名称 -->
              <el-form-item :label="$t('Resource.fileName') + '：'">
                <el-input
                  v-model="queryForm.params.fileName"
                  :placeholder="$t('Resource.pleaseInput')"
                  clearable
                  style="width:240px;"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8" style="width: auto;">
              <el-button
                icon="el-icon-search"
                size="medium"
                type="primary"
                @click="getData"
              >{{ $t('operationCenter.Procurar') }}
              </el-button>
              <el-button
                icon="el-icon-refresh"
                size="medium"
                @click="queryFormReset"
              >{{ $t('operationCenter.reset') }}
              </el-button>
            </el-col>
          </el-row>
        </el-form>
        <el-divider />
      </div>
    </div>
    <el-table
      :data="tableData"
      border
    >
      <el-table-column
        :label="$t('Resource.taskId')"
        align="center"
        prop="id"
        width="120"
      />
      <!-- 文档导出状态 -->
      <el-table-column
        :label="$t('Resource.taskType')" align="center" prop="type"
        width="100"
      >
        <template #default="scope">
          <el-tag v-if="scope.row.type === 1" type="success">{{ $t("Resource.import") }}</el-tag>
          <el-tag v-else-if="scope.row.type === 2" type="warning">{{ $t("Resource.export") }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('Resource.taskStatus')" align="center" prop="status"
        width="130"
      >
        <template slot-scope="scope">
          <!-- 状态：0-初始,1-进行中,2-完成,3-失败-->
          <el-tag v-if="scope.row.status === 0" type="info">{{ $t("Resource.init") }}</el-tag>
          <el-tag v-else-if="scope.row.status === 1" type="warning">{{ $t("Resource.action") }}</el-tag>
          <el-tag v-else-if="scope.row.status === 2" type="success">{{ $t("Resource.complete") }}</el-tag>
          <el-tag v-else-if="scope.row.status === 3" type="danger">{{ $t("Resource.fail") }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('Resource.schedule')" align="center" prop="schedule"
        width="180"
      >
        <template slot-scope="scope">
          <el-progress
            :percentage="scope.row.schedule"
            :status="scope.row.schedule == 100 ? 'success' : scope.row.status === 3 ? 'exception' : ''"
          />
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('Resource.total')" align="center" prop="estimateCount"
        width="190"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.status == 2">
            {{ scope.row.successCount }}
          </div>
          <div v-else>
            {{ scope.row.estimateCount }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('Resource.fileName')" align="center" prop="fileName"
        width="220"
      />
      <el-table-column
        :label="$t('Resource.failedMessage')"
        align="center"
        prop="failedMessage"
        show-overflow-tooltip
        width="200"
      />
      <el-table-column
        :label="$t('Resource.operator')" align="center" prop="createUsername"
        width="150"
      />
      <el-table-column
        :label="$t('Resource.startTime')" align="center" prop="startTime"
        width="200"
      />
      <el-table-column
        :label="$t('Resource.endTime')" align="center" prop="endTime"
        width="200"
      />
      <el-table-column
        :label="$t('operationCenter.operate')" align="center" fixed="right"
        prop="operation" width="120"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.fileUrl">
            <el-button size="mini" type="info" @click="handleDownload(scope.row.fileUrl)">{{
              $t("Resource.download")
            }}
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <!-- 分页工具 -->
      <el-pagination
        :current-page.sync="queryForm.pageNumber"
        :page-size.sync="queryForm.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 10px 0"
        @current-change="getData"
        @size-change="getData"
      />
    </div>
  </div>
</template>

<script>
import { apiFileTaskPage } from '@/api/resources';
import { setHttpTurnHttps } from '@/utils/tools';

export default {
  data() {
    return {
      interval: null,
      total: 0,
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          fileName: ''
        }
      },
      tableData: [],
      queryBoxShow: true,
      userObj: {
        ids: 0,
        name: ''
      }
    };
  },

  created() {
    const { id, realName } = this.$store.state.user;
    this.userObj.ids = id;
    this.userObj.name = realName;

    this.getData();
  },
  mounted() {
    this.interval = setInterval(() => {
      this.getData();
    }, 2000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    getData() {
      apiFileTaskPage(this.queryForm)
        .then((res) => {
          if (res.status === 'OK') {
            const { records, total } = res.data;
            this.total = total;
            this.tableData = records.map((item, index) => {
              if (item.fileUrl) {
                item.newFileUrl = item.fileUrl;
              }
              // 进度条
              const schedule = item.estimateCount ? (item.successCount / item.estimateCount) * 100 : 0;
              if (schedule > 100) {
                item.schedule = 100;
              } else {
                item.schedule = Number(schedule.toFixed(1));
              }
              if (item.fileUrl != null) {
                item.schedule = 100;
                item.status = 2;
              }
              if (item.successCount === 0 && item.totalCount === 0 && item.status === 2) {
                item.schedule = 100;
              }
              if (item.isFailed) {
                item.status = 3;
                item.failedMessage = this.$t('Resource.timeout');
              }
              return {
                ...item
              };
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    /**
     * 重置
     */
    queryFormReset() {
      this.queryForm.params.fileName = '';
      this.getData();
    },

    /**
     * 文件下载
     */
    handleDownload(url) {
      if (url === 'ok') {
        this.$message({
          // '导入成功无错误数据'
          message: this.$t('Resource.importSuccessNotErr'),
          type: 'warning'
        });
        return;
      }
      if (url.length) {
        const a = document.createElement('a');
        a.href = setHttpTurnHttps(url);
        a.click();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.queryFormClass ::v-deep .el-form-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.queryFormClass ::v-deep .el-form-item__label {
  padding: 0;
  white-space: nowrap;
  margin-left: 20px;
}

.queryFormClass ::v-deep .el-form-item__content {
  margin-left: 0 !important;
}

.queryFormClass ::v-deep .el-input__inner {
  height: 40px;
}

.mr10 {
  margin-right: 10px;
}

.putAway {
  position: absolute;
  right: 0;
  top: 0;
  color: #1989fa;
  cursor: pointer;
  z-index: 100;
}
</style>
