var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        { attrs: { inline: false, "label-position": "top", size: "small" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "selectType" } },
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "div",
                          { staticClass: "selectLabel" },
                          [
                            _c(
                              "el-select",
                              {
                                style: _vm.IsWidth1,
                                model: {
                                  value: _vm.queryForm.params.selectType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "selectType",
                                      $$v
                                    )
                                  },
                                  expression: "queryForm.params.selectType",
                                },
                              },
                              [
                                _c("el-option", {
                                  key: "1",
                                  attrs: {
                                    label: _vm.$t("newOrder.waybillNumber"),
                                    value: 1,
                                  },
                                }),
                                _c("el-option", {
                                  key: "2",
                                  attrs: {
                                    label: _vm.$t("GlobalSetObj.ScanNumber"),
                                    value: 2,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: _vm.$t("newOrder.PreciseSearch1"),
                          rows: 1,
                          type: "textarea",
                        },
                        model: {
                          value: _vm.queryForm.params.inputValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm.params, "inputValue", $$v)
                          },
                          expression: "queryForm.params.inputValue",
                        },
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("orderCenterCont.CustomerName") },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t(
                              "GlobalSetObj.OnlyAreDisplayed"
                            ),
                            "remote-method": _vm.getCustomerPage,
                            clearable: "",
                            filterable: "",
                            multiple: "",
                            remote: "",
                            "reserve-keyword": "",
                          },
                          on: { focus: _vm.getCustomerPage1 },
                          model: {
                            value: _vm.queryForm.params.customerIdList,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "customerIdList",
                                $$v
                              )
                            },
                            expression: "queryForm.params.customerIdList",
                          },
                        },
                        _vm._l(_vm.customerList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.username, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.preEntrega") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            "remote-method": _vm.remoteGetSiteList,
                            clearable: "",
                            filterable: "",
                            multiple: "",
                            remote: "",
                            "reserve-keyword": "",
                          },
                          on: {
                            focus: function ($event) {
                              return _vm.remoteGetSiteList("")
                            },
                          },
                          model: {
                            value: _vm.queryForm.params.preDeliverIdList,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "preDeliverIdList",
                                $$v
                              )
                            },
                            expression: "queryForm.params.preDeliverIdList",
                          },
                        },
                        _vm._l(_vm.preDeliverySiteList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 8 } }, [
                _c(
                  "div",
                  { staticStyle: { "margin-top": "26px" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          icon: "el-icon-search",
                          size: "small",
                          type: "primary",
                        },
                        on: { click: _vm.enterSearch },
                      },
                      [_vm._v(_vm._s(_vm.$t("newOrder.search")) + " ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: { icon: "el-icon-upload2", size: "small" },
                        on: { click: _vm.allExportOrder },
                      },
                      [_vm._v(_vm._s(_vm.$t("newOrder.export")) + " ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          icon: "el-icon-refresh-left",
                          size: "small",
                          type: "info",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.resetForm()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("newOrder.reset")) + " ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            "header-cell-style": { background: "#f2f2f2", color: "black" },
            border: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("GlobalSetObj.serial"),
              type: "index",
              width: "80",
            },
          }),
          _vm._l(_vm.tableHeaderList, function (item) {
            return _c("el-table-column", {
              key: item.id,
              attrs: {
                align: item.center,
                label: item.label,
                "min-width": item.cloWidth,
                prop: item.prop,
                "show-overflow-tooltip": false,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              scope.row[item.prop]
                                ? "" + scope.row[item.prop]
                                : "--"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
      _c("el-pagination", {
        staticStyle: { margin: "10px 0", float: "right" },
        attrs: {
          "current-page": _vm.queryForm.pageNumber,
          "page-size": _vm.queryForm.pageSize,
          "page-sizes": [10, 20, 50, 100],
          total: _vm.queryForm.total,
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }