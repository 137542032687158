var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "warp" }, [
    _c("div", { staticClass: "bgcs", style: _vm.lockBgcImgObj }),
    _c("div", { staticClass: "box" }, [
      _c(
        "div",
        { staticClass: "imgs" },
        [
          _c("el-image", {
            staticStyle: { width: "150px", height: "150px" },
            attrs: { src: _vm.avatar, fit: "cover" },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pls" },
        [
          _c("el-input", {
            attrs: {
              autocomplete: "off",
              "show-password": "",
              placeholder: "请输入",
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handlleInp($event)
              },
            },
            model: {
              value: _vm.pwsVal,
              callback: function ($$v) {
                _vm.pwsVal = $$v
              },
              expression: "pwsVal",
            },
          }),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlleInp } },
            [_vm._v("解锁")]
          ),
        ],
        1
      ),
      _c("p", [_vm._v("锁屏密码为登录密码")]),
      _c(
        "p",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isTips,
              expression: "isTips",
            },
          ],
          staticClass: "tips",
        },
        [_vm._v("密码错误，请重新输入")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }