<template>
  <div class="app-container">
    <el-form
      ref="regional"
      :model="updateForm"
      label-width="120px"
      :label-position="$i18n.locale!=='zh'?'top':'right'"
    >
      <el-row :gutter="10">
        <!--
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-form-item
              :label="$t('orderCenterCont.CountryOrigin') + '：'"
              prop="areaTwoCode"
              :rules="{ required: true, message: $t('orderCenterCont.CannotBeEmpty'), trigger: ['blur','change'] }"
            >
              <el-select
                v-model="updateForm.areaTwoCode"
                filterable
                :placeholder="$t('operationCenter.PleaseSelect')"
                clearable
                style="width: 100%;"
                @change="selectCountry(updateForm.areaTwoCode)"
              >
                <el-option
                  v-for="(item, index) in countryList"
                  :key="index"
                  :label="item.key + '(' + item.label + ')'"
                  :value="item.key"
                />
              </el-select>
            </el-form-item>
          </div>
        </el-col>
         -->
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-form-item
              :label="$t('orderCenterCont.ChineseName') + '：'"
              prop="nameCn"
              :rules="isCh"
            >
              <el-input
                v-model="updateForm.nameCn"
                maxlength="100"
                show-word-limit
              />
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-form-item
              :label="$t('orderCenterCont.EnglishName') + '：'"
              prop="nameEn"
              :rules="isEn"
            >
              <!-- :rules="{ required: true, validator: englishLetter, length: 3, trigger: ['blur','change'] }" -->
              <el-input
                v-model="updateForm.nameEn"
                maxlength="100"
                show-word-limit
              />
            </el-form-item>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
      style="padding: 30px 20px;text-align: right;"
    >
      <el-button @click="regionalSaving(1)">{{ $t('operationCenter.Cancel') }}</el-button>
      <el-button
        type="primary"
        @click="regionalSaving(2)"
      >{{ $t('operationCenter.determine') }}</el-button>
    </div>
  </div>
</template>

<script>
import { regionalAdd, regionalChange } from '@/api/regionalManagement/regional';
import { isChinese, isEn } from '@/utils/validate';
export default {
  name: 'AddDetails',
  props: {
    updateForm: {
      type: Object,
      default: () => {
        return {
          // areaId: '',
          // areaNameCn: '',
          // areaTwoCode: '',
          id: '',
          nameCn: '',
          nameEn: ''
        };
      }
    },
    countryList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    operationType: {
      type: Number,
      default: 1
    }
  },
  data() {
    const englishLetter = (rule, value, callback) => {
      const reg = /[^\a-zA-Z]/g;
      const { required } = rule;
      if (!required) {
        // 非必填
        return callback();
      }

      if (!value || value.length === 0) {
        return callback(new Error(this.$i18n.t('orderCenterCont.CannotBeEmpty'))); // '不能为空'
      }
      if (reg.test(value)) {
        return callback(new Error(this.$i18n.t('orderCenterCont.OnlyEnglishLetters'))); // '请输入英文字母'
      }

      if (rule.length && value.length < rule.length) {
        return callback(
          new Error(this.$i18n.t('orderCenterCont.wordCountLengthMust') + rule.length)
        );
      }
      return callback();
    };
    return {
      englishLetter: englishLetter,
      isCh: [
        { required: true, message: this.$t('orderCenterCont.CannotBeEmpty'), trigger: ['blur', 'change'] },
        {
          validator: async(rule, value, callback) => {
            if (!value) {
              return callback(new Error(this.$t('Resource.notNull'))); // '不能为空'
            }
            if (value.length > 100) {
              return callback(new Error(this.$t('Resource.noMore100Code'))); // '输入不能超出100个字符'
            }
            if (!isChinese(value)) {
              return callback(new Error(this.$t('Resource.inputChinese'))); // '请输入中文'
            }
            if (isEn(value)) {
              return callback(new Error(this.$t('Resource.notEnglish'))); // '不能有英文字符'
            }
          }
        }
      ],
      isEn: [
        { required: true, message: this.$t('orderCenterCont.CannotBeEmpty'), trigger: ['blur', 'change'] },
        {
          validator: async(rule, value, callback) => {
            if (!value) {
              return callback(new Error(this.$t('Resource.notNull'))); // '不能为空'
            }
            if (!isEn(value)) {
              return callback(new Error(this.$t('Resource.inputEnglish'))); // '请输入英文'
            }
            if (isChinese(value)) {
              return callback(new Error(this.$t('Resource.notChinese'))); // '不能有中文字符'
            }
          }
        }
      ]
    };
  },
  methods: {
    // // 国家选项变化
    // selectCountry(code) {
    //   for (let i = 0; i < this.countryList.length; i++) {
    //     if (this.countryList[i].key === code) {
    //       this.updateForm.areaNameCn = this.countryList[i].label;
    //       this.updateForm.areaTwoCode = this.countryList[i].key;
    //       this.updateForm.areaId = this.countryList[i].id;
    //     }
    //   }
    // },
    // 保存
    regionalSaving(type) {
      if (type === 1) {
        this.$emit('changeData', false);
      } else {
        this.$refs.regional.validate((valid) => {
          if (valid) {
            if (this.operationType === 1) {
              regionalAdd(this.updateForm)
                .then((res) => {
                  if (res.status === 'OK') {
                    this.$message.success(this.$i18n.t('operationCenter.TheOperationSucceeded')); // '添加成功'
                    this.$emit('changeData', true);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              regionalChange(this.updateForm, this.updateForm.id)
                .then((res) => {
                  if (res.status === 'OK') {
                    this.$message.success(this.$i18n.t('operationCenter.TheOperationSucceeded')); // '修改成功'
                    this.$emit('changeData', true);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }
        });
      }
    }
  }
};
</script>

<style scoped>
</style>
