import { render, staticRenderFns } from "./detailsCmp.vue?vue&type=template&id=32fa44f7&scoped=true"
import script from "./detailsCmp.vue?vue&type=script&lang=js"
export * from "./detailsCmp.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32fa44f7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/work/jenkins/workspace/pre预发布-anjun-express-web-management/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('32fa44f7')) {
      api.createRecord('32fa44f7', component.options)
    } else {
      api.reload('32fa44f7', component.options)
    }
    module.hot.accept("./detailsCmp.vue?vue&type=template&id=32fa44f7&scoped=true", function () {
      api.rerender('32fa44f7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/finance/basicData/cope/dispatchReferenceTable/cmp/detailsCmp.vue"
export default component.exports