<template>
  <div style="margin-top: 200px;font-size: 16px">
    <!--  头部  -->
    <div style="text-align: center;">
      <div style="font-weight: 700">Manifesto de Carga</div>
      <div style="display: flex;justify-content: center">
        <img id="SHIPPING">
      </div>
    </div>
    <!--  介绍  -->
    <div style="line-height: 30px;margin-top: 15px;margin-bottom: 35px">
      <table style="width: 100%;text-align: left;font-size: 16px;">
        <tr>
          <td>Local de partida:{{ paramShippingData.origin }}</td>
          <td>CNPJ:{{ paramShippingData.beginCnpj }}</td>
        </tr>
        <tr>
          <td>Endereço:{{ paramShippingData.beginAddress }}</td>
        </tr>
        <tr>
          <td>Empresa de transporte:{{ paramShippingData.agent }}</td>
          <td>CNPJ:{{ paramShippingData.agentCnpj }}</td>
        </tr>
        <tr>
          <td>Número da placa:{{ paramShippingData.trailer }}</td>
          <td>Número da placa do cavalo:{{ paramShippingData.saleTrailer }}</td>
        </tr>
        <tr>
          <td>Nome do Motorista:{{ paramShippingData.driverName }}</td>
          <td>CPF do Motorista:{{ paramShippingData.driverCpf }}</td>
        </tr>
        <tr>
          <td>Rota:{{ paramShippingData.routeNumber }}</td>
        </tr>
        <tr>
          <td>Empresa de gerenciamento de risco:{{ paramShippingData.riskManager }}</td>
          <td>Solicitação de monitoramento:{{ paramShippingData.monitoringRequest }}</td>
        </tr>
      </table>
      <hr>
    </div>
    <!--  项目  -->
    <div
      v-for="(item,index) in paramShippingData.destinations"
      :key="index"
      style="line-height: 25px;"
    >
      <div style="margin-bottom: 30px">
        <table style="width: 100%;text-align: left;font-size: 16px;">
          <tr>
            <td>Nome do destino:{{ item.destinationName }}</td>
          </tr>
          <tr>
            <td>Endereço:{{ item.endAddress }}</td>
          </tr>
        </table>
        <div>
          <table style="width: 100%;text-align: left">
            <tr>
              <th style="width: 33%">Número do Manifesto de Transferência</th>
              <th style="width: 33%">Quantidade de sacas</th>
              <th style="width: 33%">Quantidade de pacotes</th>
              <!--              <th>Valor Total</th>-->
            </tr>
            <tr>
              <td style="width: 33%">{{ item.transferNumber }}</td>
              <td style="width: 33%">{{ item.quantityBag }}</td>
              <td style="width: 33%">{{ item.quantityDeliveries }}</td>
              <!--              <td>{{ item.totalValue }}</td>-->
            </tr>
          </table>
        </div>
        <div>
          <table style="width: 100%;text-align: left">
            <tr>
              <th style="width: 33%">Número de MDFE</th>
              <th style="width: 33%">Chave do MDFE</th>
              <th style="width: 33%">Tempo de compilação</th>
            </tr>
            <tr>
              <td style="width: 33%">{{ item.mdfePro || '--' }}</td>
              <td style="width: 33%">{{ item.mdfeKey }}</td>
              <td style="width: 33%">{{ item.mdfeCreateTime || '--' }}</td>
            </tr>
          </table>
          <div style="margin-left: 16%">
            <img :id="`barcode${index}`">
          </div>
        </div>
        <hr>
      </div>
    </div>
  </div>
</template>
<script>
import JsBarcode from 'jsbarcode';

const logoSrc1 = require('@/assets/ajlogo1.svg');
export default {
  name: 'ShippingMinfistoPdf',
  props: {
    paramShippingData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      logo1: logoSrc1
    };
  },
  mounted() {
    JsBarcode('#SHIPPING', this.paramShippingData.serialNumber, {
      height: 60
    });
    if (this.paramShippingData.destinations?.length > 0) {
      this.paramShippingData.destinations.forEach((item, index) => {
        const elm = '#barcode' + index;
        if (item.mdfeKey) {
          JsBarcode(elm, item.mdfeKey, {
            height: 60,
            displayValue: false
          });
        } else {
          item.mdfeKey = '--';
        }
      });
    }
  },
  methods: {
    // 随机生成6位数据
    generateRandomNumber() {
      // 生成一个0到999999之间的随机数
      const randomNumber = Math.floor(Math.random() * 1000000);
      // 将随机数转换为字符串
      let randomString = randomNumber.toString();
      // 如果随机数不足6位，在前面补0
      while (randomString.length < 6) {
        randomString = '0' + randomString;
      }
      return randomString;
    }
  }
};
</script>
