var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "10px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "name" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 10, maxRows: 30 },
                  placeholder: _vm.$t("trajectory.multipleOrders"),
                },
                model: {
                  value: _vm.ruleForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "name", $$v)
                  },
                  expression: "ruleForm.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.determine")))]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function () {
                      return _vm.onClose(undefined)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }