import i18n from '@/lang/index.js';
import dayjs from 'dayjs';

const firstDay = dayjs().startOf('month').format('YYYY-MM-DD') + ' 00:00:00';
const lastDay = dayjs().endOf('month').format('YYYY-MM-DD') + ' 23:59:59';

// 理赔状态 下拉列表
export const CLAIMS_STATUS_SELECT_OBJ = {
  0: {
    value: 0,
    name: i18n.t('ClaimsManagement.ToBeSubmitted') // 待提交
  },
  1: {
    value: 1,
    name: i18n.t('receivablePayable.ToBeReviewed') // 待审核
  },
  2: {
    value: 2,
    name: i18n.t('ClaimsManagement.Rejected') // 已驳回
  },
  3: {
    value: 3,
    name: i18n.t('ClaimsManagement.Due') // 待付款
  },
  // 已完成
  4: {
    value: 4,
    name: i18n.t('collectionCenter.completed') // 已完成
  },
  // 已关闭
  5: {
    value: 5,
    name: i18n.t('GlobalSetObj.Closed') // 已关闭
  }
};

// 理赔状态
export const CLAIMS_STATUS_OBJ = {
  // 待提交
  0: CLAIMS_STATUS_SELECT_OBJ[0],
  1: {
    value: 1,
    name: i18n.t('ClaimsManagement.Examine') // 审核
  },

  2: CLAIMS_STATUS_SELECT_OBJ[2], // 已驳回
  3: {
    value: 3,
    name: i18n.t('ClaimsManagement.Payment') // 付款
  },

  4: CLAIMS_STATUS_SELECT_OBJ[4], // 已完成

  5: CLAIMS_STATUS_SELECT_OBJ[5], // 已关闭
  // 编辑
  6: {
    value: 6,
    name: i18n.t('customerManagements.edit') // 编辑
  }
};

// 理赔节点
export const CLAIMSNODE_TYPE_OBJ = {
  // 揽收
  2: {
    value: 2,
    name: i18n.t('collectionCenter.lanshou')
  },
  // 派送
  3: {
    value: 3,
    name: i18n.t('collectionCenter.dispatch')
  },
  // 转运
  1: {
    value: 1,
    name: i18n.t('collectionCenter.turnTransport')
  }
};

// 揽收类型
export const COLLECT_TYPE_OBJ = {
  // 快递订单
  1: {
    value: 1,
    name: i18n.t('basicData.CourierSystemBusiness')
  },

  // 普通揽收
  2: {
    value: 2,
    name: i18n.t('basicData.OrdinaryPickUp')
  },

  // 备货揽收
  3: {
    value: 3,
    name: i18n.t('basicData.StockingAndPickup')
  }
};

// 搜索
export const QUERY_OBJ = {
  title: '', // 标题
  claimsNumber: '', // 理赔单号
  customerId: '', // 客户
  claimsStatus: '', // 理赔状态
  createUser: '', // 创建人
  claimsNode: '', // 理赔节点
  // startedTime: '', // 时间
  // endedTime: [firstDay, lastDay], // 结束时间
  val1: '', // 订单号、客户单号
  val2: [firstDay, lastDay] // 申请时间、结束时间
};

// 备货揽收
export const STOCKING_COLLECT_TYPE_OBJ = {
  0: {
    value: 0,
    name: i18n.t('collectionCenter.notLanShou') // 未揽收
  },
  1: {
    value: 1,
    name: i18n.t('collectionCenter.hasLanShou') // 已揽收
  },
  2: {
    value: 2,
    name: i18n.t('collectionCenter.dispatchedGoods') // 已派件
  },
  3: {
    value: 3,
    name: i18n.t('GlobalSetObj.Canceled') // 已取消
  },
  4: {
    value: 4,
    name: i18n.t('collectionCenter.truckLoading') // 装车
  },
  5: {
    value: 5,
    name: i18n.t('collectionCenter.unloading') // 卸车
  },
  6: {
    value: 6,
    name: i18n.t('RouterObj.collectWarehousing') // 网点揽收入库
  }
};

// 普通揽收
export const NORMAL_COLLECT_TYPE_OBJ = {
  0: {
    value: 0,
    name: i18n.t('collectionCenter.notLanShou') // 未揽收
  },
  1: {
    value: 1,
    name: i18n.t('collectionCenter.hasLanShou') // 已揽收
  },
  2: {
    value: 2,
    name: i18n.t('GlobalSetObj.cancel') // 取消
  },
  3: {
    value: 3,
    name: i18n.t('collectionCenter.LanShouing') // 揽收中
  },
  4: {
    value: 4,
    name: i18n.t('collectionCenter.scheduled') // 已调度
  },
  5: {
    value: 5,
    name: i18n.t('collectionCenter.AbnormalLanShou') // 揽收异常
  },
  6: {
    value: 6,
    name: i18n.t('collectionCenter.haveBeenSigned') // 已签收
  },
  7: {
    value: 7,
    name: i18n.t('collectionCenter.ReceivingAbnormalPush') // 揽收异常推送
  },
  8: {
    value: 8,
    name: i18n.t('collectionCenter.SealedBag') // 已封袋
  },
  9: {
    value: 9,
    name: i18n.t('collectionCenter.truckLoading') // 装车
  },
  10: {
    value: 10,
    name: i18n.t('collectionCenter.unloading') // 卸车
  },
  11: {
    value: 11,
    name: i18n.t('collectionCenter.Bagged') // 已装袋
  },
  12: {
    value: 12,
    name: i18n.t('collectionCenter.closedLoop') // 已闭环
  },
  13: {
    value: 13,
    name: i18n.t('collectionCenter.unsolicited') // 待揽件
  },
  14: {
    value: 14,
    name: i18n.t('RouterObj.collectWarehousing') // 网点揽收入库
  }
};

// 快递订单
export const ORDER_TYPE_OBJ = {
  1: {
    value: 1,
    name: i18n.t('basicData.CourierSystemBusiness') // 快递订单
  },
  2: {
    value: 2,
    name: i18n.t('newOrder.stayLanShou') // 待揽收
  },
  3: {
    value: 3,
    name: i18n.t('collectionCenter.hasLanShou') // 已揽收
  },
  4: {
    value: 4,
    name: i18n.t('newOrder.CollectTheRevenuePool') // 揽收入库
  },
  5: {
    value: 5,
    name: i18n.t('newOrder.CollectTakeOutWarehouse') // 揽收出库
  },
  6: {
    value: 6,
    name: i18n.t('newOrder.TransshipmentIntoStorage') // 转运入库
  },
  7: {
    value: 7,
    name: i18n.t('newOrder.TransferOutWarehouse') // 转运出库
  },
  8: {
    value: 8,
    name: i18n.t('newOrder.DeliveryOutletsIntoStorage') // 派送网点入库
  },
  9: {
    value: 9,
    name: i18n.t('newOrder.Delivering') // 派送中
  },
  10: {
    value: 10,
    name: i18n.t('newOrder.Signed') // 已签收
  },
  11: {
    value: 11,
    name: i18n.t('newOrder.ProblemPiece') // 问题件
  },
  12: {
    value: 12,
    name: i18n.t('collectionCenter.unclaimed') // 待取件
  },
  13: {
    value: 13,
    name: i18n.t('collectionCenter.cancelled') // 已取消
  },
  14: {
    value: 14,
    name: i18n.t('collectionCenter.FailureDeliver') // 投递失败
  },
  15: {
    value: 15,
    name: i18n.t('GlobalSetObj.Withdrawn') // 退件中
  },

  16: {
    value: 16,
    name: i18n.t('ClaimsManagement.ReturnCompleted') // 退件完成
  },
  17: {
    value: 17,
    name: i18n.t('GlobalSetObj.DotBack') // 网点退回
  },
  18: {
    value: 18,
    name: i18n.t('GlobalSetObj.ReturnToStorage') // 退仓入库
  },
  19: {
    value: 19,
    name: i18n.t('GlobalSetObj.moduan') // 末端已揽收
  },
  20: {
    value: 20,
    name: i18n.t('GlobalSetObj.mutilSign') // 多次签收
  }
};

// 揽收类型1为上门交件,2为上门揽收
export const COLLECT_STATUS_OBJ = {
  1: {
    value: 1,
    name: i18n.t('collectionCenter.dropOff') // 上门交件
  },
  2: {
    value: 2,
    name: i18n.t('collectionCenter.toCollect') // 上门揽收
  }
};
