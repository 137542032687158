var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "warpp" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "queryFormClass",
          attrs: {
            model: _vm.queryForm,
            rules: _vm.rules,
            "label-width": "110px",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("CenterForSorting.LatticePortNum"),
                prop: "gridNum",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.isDisable,
                  placeholder: _vm.$t("CenterForSorting.pleaseInput"),
                  maxlength: "9",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.queryForm.gridNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryForm, "gridNum", _vm._n($$v))
                  },
                  expression: "queryForm.gridNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("GlobalSetObj.deviceName"),
                prop: "deviceId",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "40%" },
                  attrs: {
                    placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                    "collapse-tags": "",
                    filterable: "",
                  },
                  model: {
                    value: _vm.queryForm.deviceId,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryForm, "deviceId", $$v)
                    },
                    expression: "queryForm.deviceId",
                  },
                },
                _vm._l(_vm.deviceList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.deviceName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("CenterForSorting.note") } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("CenterForSorting.pleaseInput"),
                  rows: 5,
                  type: "textarea",
                },
                model: {
                  value: _vm.queryForm.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryForm, "remark", $$v)
                  },
                  expression: "queryForm.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _vm.ids === 0
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleAddSubmit("ruleForm")
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("CenterForSorting.ImmediatelyCreate")) +
                          " "
                      ),
                    ]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleUpdateSubmit("ruleForm")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("CenterForSorting.modify")) + " ")]
                  ),
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v(_vm._s(_vm.$t("CenterForSorting.cancel"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }