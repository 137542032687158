var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { padding: "0 10px" } }, [
    _c(
      "div",
      { staticClass: "addTable" },
      [
        _c(
          "el-card",
          { staticClass: "box-card" },
          [
            _c(
              "el-form",
              {
                ref: "basicInformation",
                attrs: {
                  model: _vm.addBatchFee.basicInformation,
                  "label-width": "auto",
                  size: "small",
                  "label-position": "top",
                },
              },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label:
                                _vm.$t("receivablePayable.TicketNumber") + "：",
                              prop: "waybillNumber",
                              rules: [
                                {
                                  required: true,
                                  message: _vm.$t("basicData.CannotBeEmpty"),
                                  trigger: ["blur", "change"],
                                },
                                {
                                  max: 20,
                                  message: _vm.$t(
                                    "basicData.LengthCantExceed20"
                                  ),
                                  trigger: ["blur", "change"],
                                },
                              ],
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { display: "flex" } },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: { disabled: !!_vm.queryFeeParam.id },
                                    model: {
                                      value:
                                        _vm.addBatchFee.basicInformation
                                          .waybillNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.addBatchFee.basicInformation,
                                          "waybillNumber",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "addBatchFee.basicInformation.waybillNumber",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "prepend" }, [
                                      _vm._v("QR"),
                                    ]),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label:
                                _vm.$t("receivablePayable.customer") + "：",
                              prop: "customerId",
                              rules: {
                                required: true,
                                message: _vm.$t("basicData.CannotBeEmpty"),
                                trigger: ["blur", "change"],
                              },
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  filterable: "",
                                  clearable: "",
                                  placeholder: _vm.$t(
                                    "spareNumberPoo.pleaseChoose"
                                  ),
                                  disabled: !!_vm.queryFeeParam.id,
                                },
                                on: { change: _vm.changeCustomer },
                                model: {
                                  value:
                                    _vm.addBatchFee.basicInformation.customerId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addBatchFee.basicInformation,
                                      "customerId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "addBatchFee.basicInformation.customerId",
                                },
                              },
                              _vm._l(_vm.customerList, function (item, ind) {
                                return _c("el-option", {
                                  key: ind,
                                  attrs: {
                                    label:
                                      item.customerAlias +
                                      "(" +
                                      item.username +
                                      ")",
                                    value: item.id,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label:
                                _vm.$t("basicData.PayablePrincipal") + "：",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { disabled: true },
                              model: {
                                value:
                                  _vm.addBatchFee.basicInformation.providerName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.addBatchFee.basicInformation,
                                    "providerName",
                                    $$v
                                  )
                                },
                                expression:
                                  "addBatchFee.basicInformation.providerName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("userManage.site") + "：",
                              prop: "siteId",
                              rules: {
                                required: true,
                                message: _vm.$t("basicData.CannotBeEmpty"),
                                trigger: ["blur", "change"],
                              },
                            },
                          },
                          [
                            _c("QuerySiteV2Cmp", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                "provider-id": "",
                                disabled: !!_vm.queryFeeParam.id,
                                val: _vm.addBatchFee.basicInformation.siteId,
                              },
                              on: {
                                change: function (val) {
                                  return (_vm.addBatchFee.basicInformation.siteId =
                                    val)
                                },
                                row: _vm.changeOutlets,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label:
                                _vm.$t("receivablePayable.BillableWeight") +
                                "：",
                              prop: "chargeWeight",
                              rules: {
                                required: true,
                                suffix: 3,
                                negative: "",
                                validator: _vm.priceType,
                                trigger: ["blur", "change"],
                              },
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { disabled: !!_vm.queryFeeParam.id },
                              model: {
                                value:
                                  _vm.addBatchFee.basicInformation.chargeWeight,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.addBatchFee.basicInformation,
                                    "chargeWeight",
                                    $$v
                                  )
                                },
                                expression:
                                  "addBatchFee.basicInformation.chargeWeight",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        [1, 2].includes(_vm.payableType)
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label:
                                    _vm.$t("newOrder.CollectionType") + "：",
                                  prop: "collectType",
                                  rules: {
                                    required: true,
                                    message: _vm.$t("basicData.CannotBeEmpty"),
                                    trigger: ["blur", "change"],
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "basicData.PleaseSelect"
                                      ),
                                      disabled: !!_vm.queryFeeParam.id,
                                    },
                                    model: {
                                      value:
                                        _vm.addBatchFee.basicInformation
                                          .collectType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.addBatchFee.basicInformation,
                                          "collectType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "addBatchFee.basicInformation.collectType",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      key: 1,
                                      attrs: {
                                        label: _vm.$t(
                                          "collectionCenter.dropOff"
                                        ),
                                        value: 1,
                                      },
                                    }),
                                    _c("el-option", {
                                      key: 2,
                                      attrs: {
                                        label: _vm.$t(
                                          "collectionCenter.doorPickUp"
                                        ),
                                        value: 2,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("receivablePayable.remark") + "：",
                              prop: "remark",
                              rules: {
                                max: 200,
                                message: _vm.$t(
                                  "basicData.LengthCantExceed200"
                                ),
                                trigger: ["blur", "change"],
                              },
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                rows: 4,
                                type: "textarea",
                                disabled: !!_vm.queryFeeParam.id,
                              },
                              model: {
                                value: _vm.addBatchFee.basicInformation.remark,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.addBatchFee.basicInformation,
                                    "remark",
                                    $$v
                                  )
                                },
                                expression:
                                  "addBatchFee.basicInformation.remark",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-card",
          [
            _c(
              "div",
              {
                staticClass: "clearfix",
                attrs: { slot: "header" },
                slot: "header",
              },
              [
                _c("span", { staticClass: "headerStyle" }, [
                  _vm._v(_vm._s(_vm.$t("receivablePayable.FeeBreakdown"))),
                ]),
              ]
            ),
            _c(
              "el-form",
              {
                ref: "addBatchFee",
                attrs: {
                  model: _vm.addBatchFee,
                  "label-width": "0",
                  size: "small",
                },
              },
              [
                _c(
                  "el-table",
                  {
                    staticClass: "CostDetails",
                    attrs: {
                      data: _vm.addBatchFee.expenseSubList,
                      height: "300",
                      border: "",
                    },
                  },
                  [
                    _c(
                      "el-table-column",
                      {
                        attrs: { width: "55", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      title: _vm.$t(
                                        "basicData.AreYouSureDelete"
                                      ),
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.deleteCost(scope)
                                      },
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "el-icon-delete deleteStyle",
                                      attrs: { slot: "reference" },
                                      slot: "reference",
                                    }),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c("template", { slot: "header" }, [
                          _c("span", {
                            staticClass: "el-icon-plus addStyle",
                            on: { click: _vm.addCost },
                          }),
                        ]),
                      ],
                      2
                    ),
                    _c(
                      "el-table-column",
                      {
                        attrs: {
                          prop: "feeName",
                          label: _vm.$t("basicData.ExpenseType"),
                          width: "180",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "formStyle" },
                                  [
                                    !scope.row.isEdit
                                      ? _c("div", [
                                          _vm._v(_vm._s(scope.row.feeName)),
                                        ])
                                      : _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "expenseSubList." +
                                                scope.$index +
                                                ".feeCode",
                                              rules: {
                                                required: true,
                                                message: _vm.$t(
                                                  "basicData.CannotBeEmpty"
                                                ),
                                                trigger: ["blur", "change"],
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  filterable: "",
                                                  clearable: "",
                                                  placeholder: _vm.$t(
                                                    "operationCenter.PleaseSelect"
                                                  ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.feeTypeChange(
                                                      scope
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.feeCode,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "feeCode",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.feeCode",
                                                },
                                              },
                                              _vm._l(
                                                _vm.feeTypeList,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.id,
                                                    attrs: {
                                                      label: item.displayName,
                                                      value: item.code,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c("template", { slot: "header" }, [
                          _c("div", [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#f00",
                                  "margin-right": "5px",
                                },
                              },
                              [_vm._v("*")]
                            ),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("basicData.ExpenseType"))),
                            ]),
                          ]),
                        ]),
                      ],
                      2
                    ),
                    _c(
                      "el-table-column",
                      {
                        attrs: {
                          prop: "fee",
                          label: _vm.$t("receivablePayable.AmountDue"),
                          width: "160",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "formStyle" },
                                  [
                                    !scope.row.isEdit
                                      ? _c("div", [
                                          _vm._v(_vm._s(scope.row.fee)),
                                        ])
                                      : _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "expenseSubList." +
                                                scope.$index +
                                                ".fee",
                                              rules: {
                                                required: true,
                                                suffix: 2,
                                                negative: "-?",
                                                validator: _vm.priceType,
                                                trigger: ["blur", "change"],
                                              },
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              model: {
                                                value: scope.row.fee,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "fee",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.fee",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c("template", { slot: "header" }, [
                          _c("div", [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#f00",
                                  "margin-right": "5px",
                                },
                              },
                              [_vm._v("*")]
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("receivablePayable.AmountDue"))
                              ),
                            ]),
                          ]),
                        ]),
                      ],
                      2
                    ),
                    _c(
                      "el-table-column",
                      {
                        attrs: {
                          prop: "operateTime",
                          label: _vm.$t("basicData.BusinessTime"),
                          width: "220",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "formStyle" },
                                  [
                                    !scope.row.isEdit
                                      ? _c("div", [
                                          _vm._v(_vm._s(scope.row.operateTime)),
                                        ])
                                      : _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "expenseSubList." +
                                                scope.$index +
                                                ".operateTime",
                                              rules: {
                                                required: true,
                                                message: _vm.$t(
                                                  "basicData.CannotBeEmpty"
                                                ),
                                                trigger: ["blur", "change"],
                                              },
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                type: "datetime",
                                                "value-format":
                                                  "yyyy-MM-dd HH:mm:ss",
                                                placeholder: _vm.$t(
                                                  "basicData.SelectADatetime"
                                                ),
                                              },
                                              model: {
                                                value: scope.row.operateTime,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "operateTime",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.operateTime",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c("template", { slot: "header" }, [
                          _c("div", [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#f00",
                                  "margin-right": "5px",
                                },
                              },
                              [_vm._v("*")]
                            ),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("basicData.BusinessTime"))),
                            ]),
                          ]),
                        ]),
                      ],
                      2
                    ),
                    _c(
                      "el-table-column",
                      {
                        attrs: {
                          label: _vm.$t("basicData.Operator"),
                          prop: "feeName",
                          width: "180",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "formStyle" },
                                  [
                                    !scope.row.isEdit
                                      ? _c("div", [
                                          _vm._v(
                                            _vm._s(scope.row.operatorName)
                                          ),
                                        ])
                                      : _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "expenseSubList." +
                                                scope.$index +
                                                ".operator",
                                              rules: {
                                                required: true,
                                                message: _vm.$t(
                                                  "basicData.CannotBeEmpty"
                                                ),
                                                trigger: ["blur", "change"],
                                              },
                                            },
                                          },
                                          [
                                            _c("QueryUserV2Cmp", {
                                              attrs: {
                                                val: scope.row.operator,
                                              },
                                              on: {
                                                change: function (val) {
                                                  return (scope.row.operator =
                                                    val)
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c("template", { slot: "header" }, [
                          _c("div", [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#f00",
                                  "margin-right": "5px",
                                },
                              },
                              [_vm._v("*")]
                            ),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("basicData.Operator"))),
                            ]),
                          ]),
                        ]),
                      ],
                      2
                    ),
                    _c("el-table-column", {
                      attrs: {
                        prop: "remark",
                        label: _vm.$t("receivablePayable.remark"),
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              !scope.row.isEdit
                                ? _c("div", [_vm._v(_vm._s(scope.row.remark))])
                                : _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "expenseSubList." +
                                          scope.$index +
                                          ".remark",
                                        rules: {
                                          max: 200,
                                          message: _vm.$t(
                                            "basicData.LengthCantExceed200"
                                          ),
                                          trigger: ["blur", "change"],
                                        },
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "GlobalSetObj.pleaseInputContent"
                                          ),
                                        },
                                        model: {
                                          value: scope.row.remark,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "remark", $$v)
                                          },
                                          expression: "scope.row.remark",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "footerBtn" },
      [
        _c(
          "el-button",
          {
            directives: [
              {
                name: "permit",
                rawName: "v-permit:remove",
                value: _vm.permission,
                expression: "permission",
                arg: "remove",
              },
            ],
            attrs: { type: "primary", size: "small" },
            on: {
              click: function ($event) {
                return _vm.saveData(2)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("basicData.Save")))]
        ),
        _c(
          "el-button",
          {
            attrs: { size: "small" },
            on: {
              click: function ($event) {
                return _vm.saveData(1)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("orderCenterCont.ShutDown")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }