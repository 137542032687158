var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "permit",
            rawName: "v-permit:remove",
            value: "but:finance:basicData:partition:edit",
            expression: "'but:finance:basicData:partition:edit'",
            arg: "remove",
          },
        ],
        staticStyle: { "margin-bottom": "10px" },
      },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary", size: "small" },
            on: {
              click: function ($event) {
                return _vm.downloadTemplate(1)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("basicData.DownloadTheTemplate")))]
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "permit",
            rawName: "v-permit:remove",
            value: "but:finance:basicData:partition:edit",
            expression: "'but:finance:basicData:partition:edit'",
            arg: "remove",
          },
        ],
        staticStyle: { "margin-bottom": "10px" },
      },
      [
        _c("upload-excel", {
          attrs: {
            "on-success": _vm.importLogistics,
            "but-name": _vm.fileName,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary", size: "small" },
            on: { click: _vm.downloadLogistics },
          },
          [_vm._v(_vm._s(_vm.$t("basicData.DownloadThePartition")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }