<template> <!-- 揽收人选择 -->
  <el-select
    v-model="dispatcherList"
    filterable
    clearable
    remote
    :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
    :loading="loading"
    multiple
    :multiple-limit="10"
    :remote-method="getDeliveryPage"
    style="width: 100%;"
    @change="onChaneg"
  >
    <el-option
      v-for="item in list"
      :key="item.value + '' + item.label"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
import { apiQueryCollectManNew } from '@/api/lanshou';

export default {
  name: '',

  props: {
    value: {
      type: Array,
      required: true
    }

  },

  data() {
    return {
      loading: false,
      list: [], // 所有揽收人
      dispatcherList: ''
    };
  },

  watch: {
    'value': {
      handler(val) {
        this.dispatcherList = val;
      }

    }
  },
  created() {
    this.getDeliveryPage();
  },

  methods: {
    // 获取揽收
    getDeliveryPage(name = '') {
      this.loading = true;
      apiQueryCollectManNew(name).then(res => {
        this.list = (res.data || []).map(item => {
          const { driverId, driverName, username } = item;

          const obj = {
            value: driverId,
            label: `${driverName}(${username})`
          };
          return Object.freeze(obj);
        });
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },

    onChaneg(val) {
      this.$emit('change', val);
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
