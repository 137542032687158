var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("customerManagements.newPwd"),
                prop: "newPassword",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("customerManagements.inputNewPwd"),
                  minlength: "8",
                  maxlength: "12",
                  "show-password": "",
                },
                model: {
                  value: _vm.ruleForm.newPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "newPassword", $$v)
                  },
                  expression: "ruleForm.newPassword",
                },
              }),
              _c("span", { staticClass: "tips" }, [
                _vm._v(_vm._s(_vm.$t("customerManagements.inputHint"))),
              ]),
              _c("br"),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("customerManagements.checkPwd"),
                prop: "newPasswordConfirm",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("customerManagements.inputNewCodeAgain"),
                  minlength: "8",
                  maxlength: "12",
                  "show-password": "",
                },
                model: {
                  value: _vm.ruleForm.newPasswordConfirm,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "newPasswordConfirm", $$v)
                  },
                  expression: "ruleForm.newPasswordConfirm",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleCancel($event)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("customerManagements.cancel")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "mr5",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleDetermine("ruleForm")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("customerManagements.confirm")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }