<script >
import { defineProps, ref } from '@vue/composition-api';
import { useVuelidate } from '@vuelidate/core';
import { FormActions, FormActionsNext, FormActionsPrevious } from '@/components/wizard-form/formStepActions';
import { required, minLength, maxLength, numeric } from '@/utils/validationErrors';
import { providerService } from '@/api/transport/provider';
const __sfc_main = {};
__sfc_main.props = {
  handleNext: {
    type: Function
  },
  isLastStep: {
    type: Boolean
  },
  isFirstStep: {
    type: Boolean
  },
  handlePrevious: {
    type: Function
  },
  formData: {
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const rules = {
    document: {
      required,
      maxLength: maxLength(11),
      minLength: minLength(9)
    },
    licensePlate: {
      required,
      minLength: minLength(8)
    },
    rntrc: {
      numeric,
      maxLength: maxLength(8),
      minLength: minLength(7)
    },
    state: {
      required
    },
    provider: {
      required
    }
  };
  const v$ = useVuelidate(rules, props.formData);
  async function handleSubmit() {
    const isValid = await v$.value.$validate();
    if (isValid) props.handleNext();
  }
  const listProviders = ref([]);
  async function filteredProvider() {
    try {
      const res = await providerService.get(0, 10, props.formData.provider);
      listProviders.value = res.data.result.map(provider => {
        return provider.provider_name;
      });
    } catch (error) {
      listProviders.value = [];
    }
  }
  const listUF = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];
  return {
    v$,
    handleSubmit,
    listProviders,
    filteredProvider,
    listUF
  };
};
__sfc_main.components = Object.assign({
  FormActions,
  FormActionsPrevious,
  FormActionsNext
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <form class="wrapper-form" @submit.prevent="handleSubmit">
    <o-field
      :label="$t('trailer.document')"
      label-for="document"
      :message="v$.document.$errors.length ? v$.document.$errors[0].$message : null"
      class="col-span-2"
    >
      <o-input
        id="document"
        v-model="formData.document"
        v-mask="'###########'"
        :placeholder="$t('trailer.documentPlaceholder')"
      />
    </o-field>

    <o-field
      :label="$t('trailer.plate')"
      label-for="licensePlate"
      :message="v$.licensePlate.$errors.length ? v$.licensePlate.$errors[0].$message : null"
    >
      <o-input
        id="licensePlate"
        v-model="formData.licensePlate"
        v-mask="'AAA-XXXX'"
        :placeholder="$t('trailer.createPlatePlaceholder')"
      />
    </o-field>

    <o-field
      :label="$t('trailer.brand')"
      label-for="brand"
    >
      <o-input
        id="brand"
        v-model="formData.brand"
        :placeholder="$t('trailer.createBrandPlaceholder')"
      />
    </o-field>

    <o-field
      :label="$t('trailer.createShed')"
      label-for="provider"
      class="col-span-2"
      :message="v$.provider.$errors.length ? v$.provider.$errors[0].$message : null"
    >
      <o-autocomplete
        id="provider"
        v-model="formData.provider"
        :placeholder="$t('trailer.createShedPlaceholder')"
        clearable
        expanded
        :data="listProviders"
        @input="filteredProvider"
      >
        <template slot="empty">{{ $t('trailer.noResults') }}</template>
      </o-autocomplete>
    </o-field>

    <o-field
      label="RNTRC"
      label-for="rntrc"
      :message="v$.rntrc.$errors.length ? v$.rntrc.$errors[0].$message : null"
    >
      <o-input
        id="rntrc"
        v-model="formData.rntrc"
        :placeholder="$t('trailer.rntrcPlaceholder')"
      />
    </o-field>

    <o-field
      :label="$t('trailer.state')"
      label-for="state"
      :message="v$.state.$errors.length ? v$.state.$errors[0].$message : null"
    >
      <o-select id="state" v-model="formData.state" :placeholder="$t('trailer.statePlaceholder')">
        <option v-for="UF in listUF" :key="UF" :value="UF"> {{ UF }} </option>
      </o-select>
    </o-field>

    <div>
      <o-checkbox v-model="formData.outsourced">
        {{ $t('trailer.outsourcedDriver') }}
      </o-checkbox>
    </div>

    <FormActions>
      <FormActionsPrevious
        :is-first-step="isFirstStep"
        @handlePrevious="handlePrevious"
      />

      <FormActionsNext
        :is-last-step="isLastStep"
      />
    </FormActions>
  </form>
</template>

<style scoped>
  .wrapper-form {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem/* 16px */;
    padding-top: 0.5rem/* 8px */;
    padding-bottom: 0.5rem/* 8px */;
  }

  .col-span-2 {
    grid-column: span 2 / span 2;
  }
</style>
