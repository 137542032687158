import { requestScripting } from '@/lib/axios';
import { removeNullParams } from '@/utils/removeNullParams';

export const routerService = {
  async create(sellers) {
    const body = this.getBody(sellers);

    return requestScripting({
      method: 'POST',
      data: body,
      url: `route`
    });
  },
  async edit(sellers, id) {
    const body = this.getBody(sellers);

    return requestScripting({
      method: 'PATCH',
      data: body,
      url: `route/${id}`
    });
  },
  async getAll(params, signal) {
    return requestScripting({
      method: 'GET',
      url: `route`,
      params: removeNullParams(params),
      signal
    });
  },
  async get(id) {
    return requestScripting({
      method: 'GET',
      url: `route/${id}`
    });
  },
  async getAllCollectDay(params, date) {
    return requestScripting({
      method: 'GET',
      url: `collect/${date}`,
      params: removeNullParams(params)
    });
  },
  async getAllFrequentRoutes(params) {
    return requestScripting({
      method: 'GET',
      url: `route/frequentRoutes`,
      params: removeNullParams(params)
    });
  },
  async getAllRoutePreview(params) {
    return requestScripting({
      method: 'GET',
      url: `route/routePreview`,
      params: removeNullParams(params)
    });
  },
  async delete(id) {
    return requestScripting({
      method: 'DELETE',
      url: `route/${id}`
    });
  },
  getBody(sellers) {
    return {
      'seller_code': sellers
    };
  }
};

