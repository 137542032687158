import defaultSettings from '@/settings';

const { showSettings, fixedHeader, sidebarLogo, tagsView } = defaultSettings;

const state = {
  showSettings: showSettings,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
  tagsView: tagsView,
  ElectronicBrand: localStorage.getItem('ElectronicBrand') || 'PRIX-3FIT',
  isLink1: false, // 用来判断是否需要请求连接 入库
  isLink2: false// 用来判断是否需要请求连接 校正
};

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    // eslint-disable-next-line no-prototype-builtins
    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  },
  CHANGE_ElectronicBrand(state, payload) {
    state.ElectronicBrand = payload.item;
    // 不为快手设备时存储
    if (state.ElectronicBrand !== 'KUAI_SHOW') {
      localStorage.setItem('ElectronicBrand', state.ElectronicBrand);
    }
  },
  CHANGE_isLink1(state, payload) {
    state.isLink1 = payload.item;
  },
  CHANGE_isLink2(state, payload) {
    state.isLink2 = payload.item;
  }
};

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};

