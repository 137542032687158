import { requestScripting } from '@/lib/axios';
import { removeNullParams } from '@/utils/removeNullParams';
import clearString from '@/utils/clearString';

export const bankService = {
  async create(data) {
    const body = this.getBody(data);

    return requestScripting({
      method: 'POST',
      data: body,
      url: `shipping-bank`
    });
  },
  async edit(data, id, isFormatedBody = true) {
    const body = isFormatedBody ? this.getBody(data) : data;

    return requestScripting({
      method: 'PUT',
      data: body,
      url: `shipping-bank/${id}`
    });
  },
  async delete(id) {
    return requestScripting({
      method: 'DELETE',
      url: `shipping-bank/${id}`
    });
  },
  async getAll(signal, skip, take) {
    return requestScripting({
      method: 'GET',
      url: `shipping-bank`,
      params: removeNullParams({ skip, take }),
      signal
    });
  },
  getBody(data) {
    return {
      'shipping_company_id': data.companyId,
      'shipping_company_name': data.companyName,
      'shipping_company_bank': data.bank,
      'shipping_company_bank_agency': data.agency,
      'shipping_company_bank_account': data.account,
      'shipping_company_bank_beneficiary': data.beneficiary,
      'shipping_company_bank_financial_contact': data.financialContact,
      'shipping_company_bank_financial_mail': data.financialEmail,
      'shipping_company_bank_financial_phone': clearString(data.financialPhone),
      'shipping_company_bank_is_active': 1
    };
  }
};
