var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        _c("span", { staticClass: "font20" }, [
          _vm._v(
            _vm._s(_vm.$t("orderCenterCont.edit")) +
              _vm._s(
                _vm.row.level == 0 ? _vm.$t("orderCenterCont.state") : ""
              ) +
              _vm._s(_vm.row.level == 1 ? _vm.$t("orderCenterCont.city") : "") +
              _vm._s(_vm.row.level == 2 ? _vm.$t("orderCenterCont.Area") : "") +
              "-" +
              _vm._s(_vm.row.level == 0 ? _vm.superior.province : "") +
              _vm._s(_vm.row.level == 1 ? _vm.superior.city : "") +
              _vm._s(_vm.row.level == 2 ? _vm.row.nameCn : "")
          ),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "font-blue", on: { click: _vm.goBack } }, [
          _vm._v(
            _vm._s(_vm.$t("orderCenterCont.back")) +
              " " +
              _vm._s(
                _vm.row.level == 0 ? _vm.$t("orderCenterCont.state") : ""
              ) +
              _vm._s(
                _vm.row.level == 1 || _vm.row.level == 2
                  ? _vm.$t("orderCenterCont.city")
                  : ""
              ) +
              " " +
              _vm._s(_vm.$t("orderCenterCont.list"))
          ),
        ]),
      ]),
      _c("el-divider"),
      _c(
        "el-tabs",
        {
          ref: "tabs",
          attrs: { type: "border-card" },
          model: {
            value: _vm.tabs,
            callback: function ($$v) {
              _vm.tabs = $$v
            },
            expression: "tabs",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("orderCenterCont.basicInformation"),
                name: "from",
              },
            },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-ruleForm",
                  attrs: { "status-icon": "", "label-width": "100px" },
                },
                [
                  _vm.superior.country && _vm.row.level === 0
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("orderCenterCont.SubordinateState"),
                            required: "",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.superior.province) + " ")]
                      )
                    : _vm._e(),
                  _vm.row.level == 1 || _vm.row.level == 2
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("orderCenterCont.BelongsToTheCity"),
                            required: "",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.superior.city) + " ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    "status-icon": "",
                    rules: _vm.rules,
                    "label-width": "100px",
                  },
                },
                [
                  _vm.ruleForm.level === 0 || _vm.ruleForm.level === 1
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "orderCenterCont.startZipCode"
                                        ),
                                        prop: "startZipCode",
                                        rules: _vm.isNumberRules,
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.ruleForm.startZipCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "startZipCode",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "ruleForm.startZipCode",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "orderCenterCont.endZipCode"
                                        ),
                                        prop: "endZipCode",
                                        rules: _vm.isNumberRules,
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.ruleForm.endZipCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "endZipCode",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "ruleForm.endZipCode",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("orderCenterCont.nameCn"),
                        prop: "nameCn",
                        rules: _vm.isChinese,
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.nameCn,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "nameCn", $$v)
                          },
                          expression: "ruleForm.nameCn",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("orderCenterCont.nameEn"),
                        prop: "nameEn",
                        rules: _vm.isEnglish,
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.nameEn,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "nameEn", $$v)
                          },
                          expression: "ruleForm.nameEn",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.ruleForm.level == 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("orderCenterCont.expandText"),
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "orderCenterCont.MultipleFields"
                              ),
                              type: "textarea",
                            },
                            model: {
                              value: _vm.ruleForm.extra,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "extra", $$v)
                              },
                              expression: "ruleForm.extra",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.ruleForm.level == 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("orderCenterCont.zipCode"),
                            "hide-required-asterisk": true,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "orderCenterCont.writeZipCode"
                              ),
                              type: "textarea",
                            },
                            model: {
                              value: _vm.ruleForm.zipCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "zipCode", $$v)
                              },
                              expression: "ruleForm.zipCode",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("orderCenterCont.TwoWordCode") + "：",
                        prop: "twoCode",
                        rules: [
                          {
                            required: false,
                            validator: _vm.englishLetter,
                            length: 2,
                            trigger: ["blur", "change"],
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "2", "show-word-limit": "" },
                        model: {
                          value: _vm.ruleForm.twoCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "twoCode", $$v)
                          },
                          expression: "ruleForm.twoCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.ruleForm.level === 1
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "orderCenterCont.partition"
                                        ),
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { display: "block" },
                                          attrs: {
                                            filterable: "",
                                            clearable: "",
                                            placeholder: _vm.$t(
                                              "collectionCenter.pleaseInput"
                                            ),
                                          },
                                          model: {
                                            value: _vm.ruleForm.zipcodeAreaName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "zipcodeAreaName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "ruleForm.zipcodeAreaName",
                                          },
                                        },
                                        _vm._l(
                                          _vm.ZipCodeAreaList,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.name,
                                                value: item.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.editLgArea },
                        },
                        [_vm._v(_vm._s(_vm.$t("orderCenterCont.confirm")))]
                      ),
                      _c("el-button", { on: { click: _vm.goBack } }, [
                        _vm._v(_vm._s(_vm.$t("orderCenterCont.cancel"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.name, name: "table" } },
            [
              _c("tableCmp", {
                ref: "tableCmp",
                attrs: {
                  level:
                    _vm.ruleForm.level < 3
                      ? _vm.ruleForm.level + 1
                      : _vm.ruleForm.level,
                  parent: _vm.parent,
                  superior: _vm.superior,
                },
                on: { editLgArea: _vm.editLgArea },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }