<template>
  <div class="app-container">
    <el-card style="margin-bottom: 10px;">
      <el-form label-position="top">
        <el-row :gutter="20">
          <el-col :span="6" style="height: 70px;">
            <!--运单号-->
            <el-form-item :label="$t('GlobalSetObj.waybillNumber')">
              <el-input v-model="queryForm.params.waybillNumbers" type="text" />
            </el-form-item>
          </el-col>
          <el-col :span="6" style="height: 70px;">
            <!--服务商-->
            <el-form-item :label="$t('GlobalSetObj.fuwushang')">
              <el-select
                v-model="queryForm.params.logisticsName"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                filterable
                style="width: 100%;"
              >
                <el-option
                  v-for="(item, ind) in serviceList"
                  :key="ind"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="height: 70px;">
            <!--任务创建时间-->
            <el-form-item :label="$t('GlobalSetObj.taskCreationTime')">
              <template slot="label">
                <div class="selectLabelStyle">
                  <el-select
                    v-model="queryForm.params.searchDateType"
                    :placeholder="$t('GlobalSetObj.pleaseChoose')"
                    :style="{width: $i18n.locale=='zh'?'160px':'280px'}"
                  >
                    <el-option
                      key="1"
                      :label="$t('newOrder.CancellationTime')"
                      value="1"
                    />
                    <el-option key="2" :label="$t('GlobalSetObj.TheOperationSucceededTime')" value="2" />
                  </el-select>
                </div>
              </template>
              <el-date-picker
                v-model="createStartDate"
                :default-time="['00:00:00', '23:59:59']"
                :format="$i18n.locale=='zh'?zhDate:ptDate"
                :placeholder="$t('newOrder.SelectDatetime')"
                clearable
                style="width: 100%;"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div style="margin-top: 10px;">
        <el-button
          icon="el-icon-search"
          size="small"
          type="primary"
          @click="searchResources(1)"
        >{{ $t('operationCenter.Procurar') }}
        </el-button>
        <el-button icon="el-icon-refresh-left" size="small" @click="searchResources(2)">
          {{ $t('operationCenter.reset') }}
        </el-button>
      </div>
    </el-card>

    <!--    <el-row>-->
    <!--      &lt;!&ndash;手动重试&ndash;&gt;-->
    <!--      <el-button style="margin-bottom: 10px" type="primary" size="small" icon="el-icon-refresh-left" @click="manualPush">-->
    <!--        {{ $t('GlobalSetObj.RetryManually') }}-->
    <!--      </el-button>-->
    <!--    </el-row>-->
    <!--  表格数据  -->
    <el-table :data="tableData" border>
      <!--      <el-table-column-->
      <!--        type="selection"-->
      <!--        align="center"-->
      <!--        width="60">-->
      <!--      </el-table-column>-->
      <!--      <el-table-column :label="$t('operationCenter.ind')" width="60px" align="center">-->
      <!--        <template slot-scope="scope">-->
      <!--          <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column
        v-for="(item, index) in tableHeaderList"
        :key="index"
        :align="item.fixed"
        :label="item.label"
        :min-width="item.cloWidth"
        :prop="item.prop"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <div v-if="item.prop === 'processingState'">
            <!--取消成功-->
            <span v-if="scope.row[item.prop] === 0">{{ $t('orderCenterCont.CancellationSuccessful') }}</span>
            <!--取消失败-->
            <span v-if="scope.row[item.prop] === 1">{{ $t('orderCenterCont.CancellationFailed') }}</span>
          </div>
          <div v-else-if="item.prop === 'failState'">
            <!--安骏-->
            <span v-if="scope.row[item.prop] === 10">{{ $t('GlobalSetObj.anjun') }}</span>
            <!--服务商-->
            <span v-if="scope.row[item.prop] === 11">{{ $t('GlobalSetObj.fuwushang') }}</span>
          </div>
          <div v-else>{{ scope.row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!--取消订单重试-->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('GlobalSetObj.CancelOrderRetry')"
      :visible.sync="showCancelOrder"
      width="600px"
    >
      <div style="padding: 20px;">
        <div>
          <div style="width: 100%;background:#DADADA;border-radius: 20px;height: 10px;">
            <div
              :style="{ width: widthProgress + '%' }"
              style="background:#42B983;height: 100%;border-radius: 20px;"
            />
          </div>
          <div style="text-align: right;color: #13ADF0;font-size: 13px;margin-top: 10px;">
            <span v-if="widthProgress < 100">{{ $t('newOrder.processing') }}</span>
            <span v-else>{{ $t('newOrder.ProcessingComplete') }}</span>
            <!--            {{ (widthProgress < 100) ? $t('newOrder.processing') : $t('newOrder.ProcessingComplete') }}-->
          </div>
        </div>
        <div v-if="widthProgress === 100">
          <div style="margin-bottom: 20px;">
            <span>
              {{ $t('GlobalSetObj.all') }}：
              <span style="color:#13ADF0;margin-right: 30px;">{{ cancelAll.length }}</span>
              {{ $t('operationCenter.succeed') }}：
              <span style="color:#42B983;margin-right: 30px;">{{ problemPieceReturn.successNumber }}</span>
              {{ $t('operationCenter.fail') }}：<span style="color:red;">{{ problemPieceReturn.failureNumber }}</span>
            </span>
          </div>
          <div>
            <!--失败原因-->
            <div style="margin-bottom: 10px;">{{ $t('GlobalSetObj.ReasonForFailure') }}：</div>
            <div class="errTable">
              <el-table :data="errTableData" :height="tableHeight" border>
                <el-table-column
                  :label="$t('GlobalSetObj.waybillNumber')"
                  :show-overflow-tooltip="true"
                  align="center"
                  prop="waybillNumber"
                />
                <el-table-column
                  :label="$t('GlobalSetObj.ReasonForFailure')"
                  :show-overflow-tooltip="true"
                  align="left"
                  prop="failReason"
                />
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="showCancelOrder = false">{{ $t('GlobalSetObj.close') }}</el-button>
      </div>
    </el-dialog>
    <!--确定重新取消订单-->
    <el-dialog
      :title="$t('GlobalSetObj.CancelOrderRetry')"
      :visible.sync="showCancelOrderTips"
      width="500px"
    >
      <div>
        <div style="font-size: 15px;margin-bottom: 20px;">{{ $t('GlobalSetObj.cancelOrderSure') }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showCancelOrderTips = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button type="primary" @click="determineCancelOrder">{{ $t('GlobalSetObj.confirm') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiAbolishPage, apiGetProviderNames } from '@/api/logisticsOrders';
import { cloneDeep } from 'lodash-es';
import dayjs from 'dayjs';

export default {
  name: 'CancelOrder',
  data() {
    return {
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          createStartTime: this.getTimer().today + ' 00:00:00', // 订单取消开始时间
          abolishEndTime: this.getTimer().today + ' 23:59:59', // 订单取消结束时间
          operationStartTime: '', // 操作成功开始时间
          operationEndTime: '', // 操作成功结束时间
          searchDateType: '1',
          waybillNumbers: '',
          logisticsName: ''
        }
      },
      total: 0,
      serviceList: [],
      createStartDate: [this.getTimer().today + ' 00:00:00', this.getTimer().today + ' 23:59:59'],
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      tableHeaderList: [
        // 运单号
        { id: 1, label: this.$t('newOrder.waybillNumber'), prop: 'waybillNumber', cloWidth: '180', fixed: 'left' },
        // 服务商单号
        {
          id: 2,
          label: this.$t('GlobalSetObj.serviceLogistics'),
          prop: 'logisticsNumber',
          cloWidth: '200',
          fixed: 'left'
        },
        // 服务商
        { id: 3, label: this.$t('GlobalSetObj.fuwushang'), prop: 'logisticsName', cloWidth: '200', fixed: 'left' },
        // 订单创建时间
        {
          id: 4,
          label: this.$t('collectionCenter.orderCreateT'),
          prop: 'waybillCreateTime',
          cloWidth: '190',
          fixed: 'center'
        },
        // 取消时间
        { id: 5, label: this.$t('newOrder.CancellationTime'), prop: 'abolishTime', cloWidth: '190', fixed: 'center' },
        // 处理状态
        {
          id: 6,
          label: this.$t('GlobalSetObj.ProcessingStatus'),
          prop: 'processingState',
          cloWidth: '160',
          fixed: 'center'
        },
        // 失败环节
        { id: 7, label: this.$t('GlobalSetObj.FailureLink'), prop: 'failState', cloWidth: '160', fixed: 'center' },
        // 失败原因
        { id: 8, label: this.$t('GlobalSetObj.ReasonForFailure'), prop: 'failReason', cloWidth: '190', fixed: 'left' },
        // 操作成功时间
        {
          id: 9,
          label: this.$t('GlobalSetObj.TheOperationSucceededTime'),
          prop: 'operationTime',
          cloWidth: '190',
          fixed: 'center'
        },
        // 操作人
        { id: 10, label: this.$t('GlobalSetObj.operator'), prop: 'operationUser', cloWidth: '120', fixed: 'center' }
      ],
      tableData: [],
      widthProgress: 0,
      cancelAll: [],
      showCancelOrder: false,
      problemPieceReturn: {
        successNumber: 0,
        failureNumber: 0
      },
      errTableData: [],
      tableHeight: 200,
      showCancelOrderTips: false
    };
  },
  created() {
    this.getServiceList();
    this.gettableDataList();
  },
  methods: {
    // 获取服务商
    getServiceList() {
      apiGetProviderNames().then(res => {
        if (res.status === 'OK') {
          this.serviceList = res.data;
        }
      });
    },
    // 手动重试提示
    manualPush() {
      this.showCancelOrderTips = true;
    },
    determineCancelOrder() {
      this.showCancelOrderTips = false;
      this.showCancelOrder = true;
      this.widthProgress = 0;
      if (this.errTableData.length > 5) {
        this.tableHeight = 300;
      }
      const time = setInterval(() => {
        if (this.widthProgress < 100) {
          this.widthProgress++;
        }
        if (this.widthProgress >= 100) {
          clearInterval(time);
        }
      }, 50);
    },
    // 列表数据勾选
    selectTable(data) {
      console.log(data, '列表数据勾选');
      this.selectionList = data;
    },
    // 获取当前巴西时间
    getTimer() {
      const day = new Date();
      const brazilTime = day.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
      const today = dayjs(brazilTime).format('YYYY-MM-DD');
      return {
        today
      };
    },
    // 搜索
    searchResources(type) {
      if (type === 1) {
        this.queryForm.pageNumber = 1;
        this.gettableDataList();
      } else {
        this.createStartDate = [this.getTimer().today + ' 00:00:00', this.getTimer().today + ' 23:59:59'];
        this.queryForm = {
          pageNumber: 1,
          pageSize: 10,
          params: {
            createStartTime: this.getTimer().today + ' 00:00:00', // 订单取消开始时间
            abolishEndTime: this.getTimer().today + ' 23:59:59', // 订单取消结束时间
            operationStartTime: '', // 操作成功开始时间
            operationEndTime: '', // 操作成功结束时间
            searchDateType: '1',
            waybillNumbers: '',
            logisticsName: ''
          }
        };
      }
    },
    // 获取取消订单列表数据
    gettableDataList() {
      const param = cloneDeep(this.queryForm);
      const paramData = {
        pageSize: param.pageSize,
        pageNumber: param.pageNumber,
        params: {
          abolishStartTime: '',
          abolishEndTime: '',
          operationStartTime: '',
          operationEndTime: '',
          waybillNumbers: [],
          logisticsName: param.params.logisticsName
        }
      };
      if (this.createStartDate && this.createStartDate.length > 0) {
        if (param.params.searchDateType === '1') {
          paramData.params.abolishStartTime = this.createStartDate[0];
          paramData.params.abolishEndTime = this.createStartDate[1];
        } else {
          paramData.params.operationStartTime = this.createStartDate[0];
          paramData.params.operationEndTime = this.createStartDate[1];
        }
      }
      if (param.params.waybillNumbers) {
        paramData.params.waybillNumbers = [param.params.waybillNumbers];
      }
      apiAbolishPage(paramData).then(res => {
        if (res.status === 'OK') {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.gettableDataList();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.gettableDataList();
    }
  }
};
</script>

<style scoped>
.app-container >>> .selectLabelStyle .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
  padding: 0;
}

.app-container >>> .selectLabelStyle .el-input__icon {
  line-height: 28px !important;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type='number'] {
  -moz-appearance: textfield !important;
}
</style>
