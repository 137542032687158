var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "headeContainer" },
        [
          _c(
            "div",
            { staticClass: "searchWrap" },
            [
              _c(
                "el-form",
                {
                  ref: "queryForm",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "label-position":
                      _vm.$i18n.locale != "zh" ? "top" : "right",
                    model: _vm.queryForm,
                    inline: "",
                    "label-width": "150px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("ChannelManage.ServiceProviderName"),
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "380px" },
                          attrs: {
                            placeholder: _vm.$t(
                              "spareNumberPoo.Only10AreDisplayed"
                            ),
                            "remote-method": _vm.remoteMethod,
                            clearable: "",
                            filterable: "",
                            remote: "",
                          },
                          model: {
                            value: _vm.queryForm.params.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm.params, "name", $$v)
                            },
                            expression: "queryForm.params.name",
                          },
                        },
                        _vm._l(_vm.systemNameList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t(
                          "ChannelManage.ServiceProviderChannelName"
                        ),
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "380px" },
                        attrs: {
                          placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                          clearable: "",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.getSearch($event)
                          },
                        },
                        model: {
                          value: _vm.queryForm.params.providerChannelName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.queryForm.params,
                              "providerChannelName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.params.providerChannelName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("ChannelManage.channelCode") } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                          clearable: "",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.getSearch($event)
                          },
                        },
                        model: {
                          value: _vm.queryForm.params.providerChannelCode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.queryForm.params,
                              "providerChannelCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.params.providerChannelCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "btnList" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-search",
                    size: "small",
                    type: "primary",
                  },
                  on: { click: _vm.getSearch },
                },
                [_vm._v(_vm._s(_vm.$t("newOrder.search")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-refresh-left",
                    plain: "",
                    size: "small",
                  },
                  on: { click: _vm.resetForm },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.reset")) + " ")]
              ),
            ],
            1
          ),
          _c("el-divider"),
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticStyle: { "margin-bottom": "15px" },
          attrs: { icon: "el-icon-plus", size: "small", type: "primary" },
          on: { click: _vm.addDrawer },
        },
        [_vm._v(" " + _vm._s(_vm.$t("GlobalSetObj.add")) + " ")]
      ),
      _c(
        "el-card",
        [
          _c("AnjunTableList", {
            ref: "anjunTable",
            attrs: {
              "format-time": _vm.isTormat,
              "has-operation": true,
              "header-list": _vm.tableHeaderList,
              "is-index": true,
              "is-tip": true,
              "op-width": 180,
              "table-data": _vm.tableData,
            },
            on: {
              "on-look": _vm.toLook,
              "to-SystemName": _vm.toChannelName,
              "on-stop": _vm.UpdateState,
            },
          }),
          _c("el-pagination", {
            staticStyle: { margin: "10px 0", float: "right" },
            attrs: {
              "current-page": _vm.queryForm.number,
              "page-size": _vm.queryForm.pageSize,
              "page-sizes": [10, 20, 50, 100],
              total: _vm.queryForm.total,
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          ref: "drawer",
          attrs: {
            "append-to-body": true,
            title: _vm.$t("ChannelManage.NewProviderChannels"),
            visible: _vm.drawer,
            "custom-class": "demo-drawer",
            direction: "ltr",
            size: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "demo-drawer__content",
              staticStyle: { "padding-right": "30px", "padding-left": "30px" },
            },
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    "label-position":
                      _vm.$i18n.locale != "zh" ? "top" : "right",
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "150px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t(
                          "ChannelManage.ServiceProviderChannelName"
                        ),
                        prop: "providerChannelName",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.providerChannelName,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "providerChannelName", $$v)
                          },
                          expression: "ruleForm.providerChannelName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("ChannelManage.channelCode"),
                        prop: "providerChannelCode",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.providerChannelCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "providerChannelCode", $$v)
                          },
                          expression: "ruleForm.providerChannelCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "takeNumber" } },
                    [
                      _c(
                        "template",
                        { slot: "label" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "QuestionMark",
                              attrs: { placement: "top" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("ChannelManage.takeNumberTip")
                                    )
                                  ),
                                ]
                              ),
                              _c("svg-icon", {
                                attrs: { "icon-class": "QuestionMark" },
                              }),
                            ],
                            1
                          ),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("ChannelManage.TakeNumberOrNot"))
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "mini" },
                          model: {
                            value: _vm.ruleForm.takeNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "takeNumber", $$v)
                            },
                            expression: "ruleForm.takeNumber",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "1" } }, [
                            _vm._v(_vm._s(_vm.$t("GlobalSetObj.yes"))),
                          ]),
                          _c("el-radio-button", { attrs: { label: "0" } }, [
                            _vm._v(_vm._s(_vm.$t("GlobalSetObj.No"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "whetherOrder" } },
                    [
                      _c(
                        "template",
                        { slot: "label" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "QuestionMark",
                              attrs: { placement: "top" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("ChannelManage.whetherOrderTip")
                                    )
                                  ),
                                ]
                              ),
                              _c("svg-icon", {
                                attrs: { "icon-class": "QuestionMark" },
                              }),
                            ],
                            1
                          ),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("ChannelManage.changeOrderOrNot"))
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "mini" },
                          model: {
                            value: _vm.ruleForm.whetherOrder,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "whetherOrder", $$v)
                            },
                            expression: "ruleForm.whetherOrder",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "1" } }, [
                            _vm._v(_vm._s(_vm.$t("GlobalSetObj.yes"))),
                          ]),
                          _c("el-radio-button", { attrs: { label: "0" } }, [
                            _vm._v(_vm._s(_vm.$t("GlobalSetObj.No"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "packageMaxWeight" } },
                    [
                      _c(
                        "template",
                        { slot: "label" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "QuestionMark",
                              attrs: { placement: "top" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("ChannelManage.ChannelWeight")
                                    )
                                  ),
                                ]
                              ),
                              _c("svg-icon", {
                                attrs: { "icon-class": "QuestionMark" },
                              }),
                            ],
                            1
                          ),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("ChannelManage.maximumParcelWeight")
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("placeOrder.input500"),
                          type: "number",
                        },
                        model: {
                          value: _vm.ruleForm.packageMaxWeight,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "packageMaxWeight", $$v)
                          },
                          expression: "ruleForm.packageMaxWeight",
                        },
                      }),
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t(
                          "ChannelManage.AssociatedServiceProvider"
                        ),
                        prop: "systemName",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t(
                              "spareNumberPoo.Only10AreDisplayed"
                            ),
                            "remote-method": _vm.remoteMethod,
                            clearable: "",
                            filterable: "",
                            remote: "",
                          },
                          on: { change: _vm.selectSystemName },
                          model: {
                            value: _vm.ruleForm.systemName,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "systemName", $$v)
                            },
                            expression: "ruleForm.systemName",
                          },
                        },
                        _vm._l(_vm.systemNameList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.variableList.length > 0
                ? _c(
                    "div",
                    { staticClass: "createdForm" },
                    [
                      _c("form-create", {
                        key: _vm.newKey,
                        attrs: { option: _vm.option, rule: _vm.variableList },
                        model: {
                          value: _vm.fApi,
                          callback: function ($$v) {
                            _vm.fApi = $$v
                          },
                          expression: "fApi",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "demo-drawer__footer" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "20px" },
                  on: { click: _vm.cancelForm },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveProviderChannel },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Salve")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-drawer",
        {
          ref: "drawer",
          attrs: {
            "append-to-body": true,
            title: _vm.drawerInfoName,
            visible: _vm.drawerInfo,
            "custom-class": "demo-drawer",
            direction: "ltr",
            size: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawerInfo = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            { attrs: { type: "border-card" } },
            [
              _c(
                "el-tab-pane",
                {
                  staticClass: "elTabs",
                  attrs: {
                    label: _vm.$t("ChannelManage.ServiceChannelDetails"),
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "systemInfo" },
                    [
                      _c(
                        "el-form",
                        {
                          attrs: {
                            "label-position":
                              _vm.$i18n.locale != "zh" ? "top" : "right",
                            "label-width": "150px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "ChannelManage.ServiceProviderChannelName"
                                ),
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.infoForm.providerChannelName)
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("ChannelManage.channelCode"),
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.infoForm.providerChannelCode)
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "template",
                                { slot: "label" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "QuestionMark",
                                      attrs: { placement: "top" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "content" },
                                          slot: "content",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "ChannelManage.takeNumberTip"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("svg-icon", {
                                        attrs: { "icon-class": "QuestionMark" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("ChannelManage.TakeNumberOrNot")
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { size: "small" },
                                  model: {
                                    value: _vm.updateRuleForm.takeNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.updateRuleForm,
                                        "takeNumber",
                                        $$v
                                      )
                                    },
                                    expression: "updateRuleForm.takeNumber",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    {
                                      attrs: {
                                        disabled: _vm.infoForm.takeNumber == 0,
                                        label: "1",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("GlobalSetObj.yes")) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    {
                                      attrs: {
                                        disabled: _vm.infoForm.takeNumber == 1,
                                        label: "0",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("GlobalSetObj.No")) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "template",
                                { slot: "label" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "QuestionMark",
                                      attrs: { placement: "top" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "content" },
                                          slot: "content",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "ChannelManage.whetherOrderTip"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("svg-icon", {
                                        attrs: { "icon-class": "QuestionMark" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("ChannelManage.changeOrderOrNot")
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { size: "small" },
                                  model: {
                                    value: _vm.infoForm.whetherOrder,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.infoForm,
                                        "whetherOrder",
                                        $$v
                                      )
                                    },
                                    expression: "infoForm.whetherOrder",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.infoForm.whetherOrder == 0,
                                        label: "1",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("GlobalSetObj.yes")) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.infoForm.whetherOrder == 1,
                                        label: "0",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("GlobalSetObj.No")) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "template",
                                { slot: "label" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "QuestionMark",
                                      attrs: { placement: "top" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "content" },
                                          slot: "content",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "ChannelManage.ChannelWeight"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("svg-icon", {
                                        attrs: { "icon-class": "QuestionMark" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "ChannelManage.maximumParcelWeight"
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c("div", [
                                _vm._v(_vm._s(_vm.infoForm.packageMaxWeight)),
                              ]),
                            ],
                            2
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "ChannelManage.AssociatedServiceProvider"
                                ),
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(_vm._s(_vm.infoForm.systemName)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "createdForm" },
                        [
                          _c("form-create", {
                            key: _vm.newKey,
                            attrs: {
                              option: _vm.option,
                              rule: _vm.infovariableList,
                            },
                            model: {
                              value: _vm.fApi,
                              callback: function ($$v) {
                                _vm.fApi = $$v
                              },
                              expression: "fApi",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-tab-pane",
                {
                  staticClass: "elTabs1",
                  attrs: { label: _vm.$t("ChannelManage.fixServiceChannel") },
                },
                [
                  _c(
                    "div",
                    { staticClass: "updateInfo" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "editRuleForm",
                          staticClass: "demo-ruleForm",
                          attrs: {
                            "label-position":
                              _vm.$i18n.locale != "zh" ? "top" : "right",
                            model: _vm.updateRuleForm,
                            rules: _vm.updateRules,
                            "label-width": "180px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "ChannelManage.ServiceProviderChannelName"
                                ),
                                prop: "providerChannelName",
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.updateRuleForm.providerChannelName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.updateRuleForm,
                                      "providerChannelName",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "updateRuleForm.providerChannelName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("ChannelManage.channelCode"),
                                prop: "providerChannelCode",
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.updateRuleForm.providerChannelCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.updateRuleForm,
                                      "providerChannelCode",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "updateRuleForm.providerChannelCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "takeNumber" } },
                            [
                              _c(
                                "template",
                                { slot: "label" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "QuestionMark",
                                      attrs: { placement: "top" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "content" },
                                          slot: "content",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "ChannelManage.takeNumberTip"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("svg-icon", {
                                        attrs: { "icon-class": "QuestionMark" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("ChannelManage.TakeNumberOrNot")
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.updateRuleForm.takeNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.updateRuleForm,
                                        "takeNumber",
                                        $$v
                                      )
                                    },
                                    expression: "updateRuleForm.takeNumber",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "1" } },
                                    [_vm._v(_vm._s(_vm.$t("GlobalSetObj.yes")))]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "0" } },
                                    [_vm._v(_vm._s(_vm.$t("GlobalSetObj.No")))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "whetherOrder" } },
                            [
                              _c(
                                "template",
                                { slot: "label" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "QuestionMark",
                                      attrs: { placement: "top" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "content" },
                                          slot: "content",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "ChannelManage.whetherOrderTip"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("svg-icon", {
                                        attrs: { "icon-class": "QuestionMark" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("ChannelManage.changeOrderOrNot")
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.updateRuleForm.whetherOrder,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.updateRuleForm,
                                        "whetherOrder",
                                        $$v
                                      )
                                    },
                                    expression: "updateRuleForm.whetherOrder",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "1" } },
                                    [_vm._v(_vm._s(_vm.$t("GlobalSetObj.yes")))]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "0" } },
                                    [_vm._v(_vm._s(_vm.$t("GlobalSetObj.No")))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "packageMaxWeight" } },
                            [
                              _c(
                                "template",
                                { slot: "label" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "QuestionMark",
                                      attrs: { placement: "top" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "content" },
                                          slot: "content",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "ChannelManage.ChannelWeight"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("svg-icon", {
                                        attrs: { "icon-class": "QuestionMark" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "ChannelManage.maximumParcelWeight"
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t("placeOrder.input500"),
                                  type: "number",
                                },
                                model: {
                                  value: _vm.updateRuleForm.packageMaxWeight,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.updateRuleForm,
                                      "packageMaxWeight",
                                      $$v
                                    )
                                  },
                                  expression: "updateRuleForm.packageMaxWeight",
                                },
                              }),
                            ],
                            2
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "ChannelManage.AssociatedServiceProvider"
                                ),
                                prop: "systemName",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "operationCenter.Only10AreDisplayed"
                                    ),
                                    "remote-method": _vm.remoteMethod,
                                    clearable: "",
                                    filterable: "",
                                    remote: "",
                                  },
                                  on: { change: _vm.updateSelectSystemName },
                                  model: {
                                    value: _vm.updateRuleForm.systemName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.updateRuleForm,
                                        "systemName",
                                        $$v
                                      )
                                    },
                                    expression: "updateRuleForm.systemName",
                                  },
                                },
                                _vm._l(_vm.systemNameList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.updateVariableList.length !== 0
                        ? _c(
                            "div",
                            { staticClass: "createdForm" },
                            [
                              _c("form-create", {
                                key: _vm.newKey,
                                attrs: {
                                  option: _vm.option,
                                  rule: _vm.updateVariableList,
                                },
                                model: {
                                  value: _vm.editApi,
                                  callback: function ($$v) {
                                    _vm.editApi = $$v
                                  },
                                  expression: "editApi",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "demo_footer" },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-right": "20px" },
                          on: { click: _vm.cancelForm },
                        },
                        [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.updateSystem },
                        },
                        [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Salve")))]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }