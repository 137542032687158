<template>
  <div class="sorterOrderBox">
    <div style="margin-bottom: 20px;">
      <el-form>
        <el-row>
          <el-col :span="8">
            <!-- 订单号： -->
            <el-form-item :label="$t('CenterForSorting.orderNumber')" label-width="115px">
              <el-input v-model.trim="queryForm.params.orderNumber" clearable :placeholder="$t('CenterForSorting.pleaseInput')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <!-- 大包号： -->
            <el-form-item :label="$t('CenterForSorting.bigBagNumber')" label-width="115px">
              <el-input v-model.trim="queryForm.params.bagNumber" clearable :placeholder="$t('CenterForSorting.pleaseInput')" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="6">
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="searchClick"
          >{{ $t('CenterForSorting.search') }}</el-button>
          <!-- 查询 -->
          <el-button
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('CenterForSorting.reset') }}</el-button>
          <!-- 重置 -->
        </el-col>
      </el-row>
      <el-divider />
    </div>
    <div>
      <el-table
        :data="tableDate"
        :max-height="600"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <!-- 序号 -->
        <el-table-column
          :label="$t('CenterForSorting.ind')" type="index" width="60"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="orderNumber"
          :label="$t('CenterForSorting.orderNum')"
        /><!-- 订单号 -->
        <!-- <el-table-column
          prop="xxx"
          label="渠道号"
        />xxx -->
        <el-table-column
          prop="referenceNumber"
          :label="$t('CenterForSorting.ReferenceNumber')"
        /><!-- 参考号 -->
        <el-table-column
          prop="bagNumber"
          :label="$t('CenterForSorting.bigBagNum')"
        /><!-- 大包号 -->
        <el-table-column
          prop="createTime"
          :label="$t('CenterForSorting.CreationTime')"
        /><!-- 创建时间 -->
        <el-table-column
          prop="orderStatus"
          :label="$t('CenterForSorting.orderStatus')"
        ><!-- 出入库状态 -->
          <template slot-scope="scope">
            <div>
              <!-- scope.row.orderStatus == 0 ? '未入库' :
                scope.row.orderStatus == 1 ? '已入库' :
                scope.row.orderStatus == 2 ? '已出库' : '' -->
              {{
                scope.row.orderStatus == 0 ? $t('CenterForSorting.NotInStorage') :
                scope.row.orderStatus == 1 ? $t('CenterForSorting.InStorage') :
                scope.row.orderStatus == 2 ? $t('CenterForSorting.OutLibrary') : ''
              }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100, 500]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getTablePage"
        @size-change="searchClick"
      />
    </div>
  </div>
</template>

<script>
import { sorterOrderPage } from '@/api/automaticSorting';
export default {
  data() {
    return {
      total: 0,
      tableDate: [],
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          orderNumber: '',
          bagNumber: ''
        }
      }
    };
  },
  created() {
    if (this.$route && this.$route.query && this.$route.query.bagNumber) {
      this.queryForm.params.bagNumber = this.$route.query.bagNumber;
    } else {
      this.queryForm.params.bagNumber = '';
    }
    console.log('get', this.$route.query.bagNumber);
    this.getTablePage();
  },
  beforeDestroy() {
    this.$nextTick(() => {
      this.$router.push({ query: {}});
    });
  },
  methods: {
    getTablePage() {
      sorterOrderPage(this.queryForm).then(res => {
        if (res.status === 'OK') {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        }
      }).catch({});
    },
    searchClick() {
      this.queryForm.pageNumber = 1;
      this.getTablePage();
    },
    resetClick() {
      this.queryForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          orderNumber: '',
          bagNumber: ''
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
  .sorterOrderBox{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
</style>
