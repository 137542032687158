/**
 * 包裹
 */
import request from '@/utils/request';
import qs from 'qs';

const baseURL = process.env.VUE_APP_BASE_WMS_API;

// 查询记录
export const apiFindPage = data => {
  return request.post('/wms/operationPackageInfo/page', data);
};

// 查询操作记录
export const apiGetPackageLog = ids => {
  return request.get(`/wms/packageOperationLog/getByPackageId/${ids}`);
};

// 打包操作--添加包裹
export const apiAddPackage = data => {
  return request({
    method: 'POST',
    url: '/package',
    data,
    baseURL
  });
};

// 封袋
export const apiOverPackage = bigbagno => {
  return request({
    method: 'PUT',
    url: `/package/overPackage/${bigbagno}`,
    baseURL
  });
};

// 移除小包
export const apiDelPackage = ids => {
  return request({
    method: 'DELETE',
    url: `/package/removeSmallBag/${ids}`,
    baseURL
  });
};

// 查询待配载
export const apiPostPperationBigBagInfoList = data => {
  return request.post('/wms/operationBigBagInfo/list', data);
};

// 大包列表
export const apiPostPperationBigBagInfoPage = data => {
  // return request.post('/wms/operationBigBagInfo/page', data);
  return request.post('/sorterBag/page', data);
};

// 根据大包号获取大包下的所有小包
export const apiGetSmallBagByBigBagNo = bigBagNo => {
  return request.get(`/wms/operationPackageInfo/getByBigBagNo/${bigBagNo}`);
};

// 配载
export const apiOperationTotalListInfo = data => {
  return request.post('/wms/operationTotalListInfo/fit', data);
};

// 配载完结
export const apiOperationTotalListInfoFitOver = ids => {
  return request({
    method: 'GET',
    url: `/totalList/overFit/${ids}`,
    timeout: 40 * 1000, // 增加请求延迟时间
    baseURL
  });
};

// 总单详情（总单下的大包）
export const apiGetDetail = totalListNo => {
  return request({
    method: 'GET',
    url: `/wms/operationBigBagInfo/listByTotalListNo/${totalListNo}`
  });
};

// 获取大包下的小包
export const apiGetByBigBagNo = bigBagNo => {
  return request({
    method: 'GET',
    url: `/wms/operationPackageInfo/getByBigBagNo/${bigBagNo}`
  });
};

// 配载保存
export const apiSaveFit = data => {
  return request.put(`/wms/operationTotalListInfo/saveFit`, data);
};

// 配载作废
export const apiCancel = data => {
  return request.delete(`/wms/operationTotalListInfo/cancel/${data}`);
};

// 移除大包
export const apiRemoveBigBag = ids => {
  return request.delete(`/wms/operationTotalListInfo/removeBigBag/${ids}`);
};

// 总单--移除小包
export const apiRemoveSmallBag = ids => {
  return request.delete(`/wms/operationTotalListInfo/removeSmallBag/${ids}`);
};

// 获取服务商渠道
export const apiProviderChannelList = data => {
  // return request.post('/logistics/channel/list', data);
  return request.get('providerChannel/list', data);
};

// 新渠道接口
export const apiCustomerChannelList = data => {
  // return request.post('/logistics/channel/list', data);
  return request.post('logistics/customerChannel/list', data);
};

// 模板 上传小包
export const apiUploadSmallBag = data => {
  return request.post('/uploadSmallBag/uploadSmallBag', data);
};

// 总单分页——查询分拣机总单分页查询
export const apiOperationTotalListInfoPage = data => {
  // return request.post('wms/operationTotalListInfo/page', data);
  return request.post('/operationTotalListInfo/page', data);
};

// 获取最后袋标
export const apiGetBigBagExpressWaybill = ids => {
  return request({
    method: 'GET',
    url: `wms/operationBigBagInfo/getBigBagExpressWaybill/${ids}`,
    timeout: 40 * 1000 // 巴西接口反应慢，增加请求延迟时间
  });
};

// 袋标重试
export const apiRetryGetBigBagExpressWaybill = ids => {
  return request({
    method: 'GET',
    url: `wms/operationBigBagInfo/retryGetBigBagExpressWaybill/${ids}`,
    timeout: 40 * 1000 // 巴西接口反应慢，增加请求延迟时间
  });
};

// 打包后解封
export const apiCancelBagRelation = ids => {
  return request({
    method: 'DELETE',
    url: `/package/cancelBagRelation/${ids}`,
    baseURL
  });
};

// 将 oss 地址传至 后端
export const apiUpdateAfterPrint = data => {
  return request.put(`/wms/operationBigBagInfo/updateAfterPrint`, data);
};

// 订单作废
export const apiCancelBag = ids => {
  return request({
    method: 'DELETE',
    url: `/package/cancelBag/${ids}`,
    baseURL
  });
};

// 三免清单
export const apiGetDetailByTotalListNo = totalListNo => {
  return request.get(`/wms/operationTotalListInfo/getDetailByTotalListNo/${totalListNo}`);
};

// 生成面单
export const apiCreateExpressWaybill = data => {
  return request({
    method: 'POST',
    url: '/package/createExpressWaybill',
    data,
    baseURL
  });
};

// 总单类型 修改
export const apiTotalListInfoEdit = data => {
  return request({
    method: 'PUT',
    url: '/wms/operationTotalListInfo/edit',
    data
  });
};

// 获取当前操作人未完结配载的总单
export const apiGetNoOverFit = ids => {
  return request({
    method: 'GET',
    url: `/wms/operationTotalListInfo/getNoOverFit/${ids}`
  });
};

export const apiPperationPackageInfoDelete = ids => {
  return request({
    method: 'DELETE',
    url: `/wms/operationPackageInfo/delete/${ids}`
  });
};
// 分拣机小包
export const pageSorterSmallBag = data => {
  return request.post('/wms/sorterSmallBag/page', data);
};
// 分拣打包
export const pageSorterBigBag = data => {
  return request.post('/wms/sorterBigBag/page', data);
};
// 称重查询导出
export const exportWeightInfo = data => {
  return request.post('/wms/operationPackageInfo/export/weightInfo', data);
};

// 获取大包标签
export const apiGetTotalListLabel = data => {
  return request.get(`/wms/operationBigBagInfo/getTotalListLabel?${qs.stringify(data)}`);
};

// 导出装袋清单
export const ApiOperationBigBagInfoExport = data => {
  return request.post('/wms/operationBigBagInfo/export', data);
};
// 新增材积重
export const orderVolumeWeightAdd = data => {
  return request.post('/orderVolumeWeight/add', data);
};
// 材积重右侧列表数据
export const orderVolumeWeightPage = data => {
  return request.post('/orderVolumeWeight/page', data);
};
// 材积重说明
export const orderVolumeWeightEdit = data => {
  return request.post('/orderVolumeWeight/edit?id=' + data.id + '&remark=' + data.remark);
};
// 材积重查询订单
export const orderVolumeWeightGetOrder = data => {
  return request.post('/orderVolumeWeight/getOrder', data);
};

/**
 * @description: 装袋列表详情数据查询
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const baggingGetTransitWaybillBag = data => {
  return request.post('/bagging/getTransitWaybillBag', data);
};

/**
 * @description: 装袋列表详情数据导出
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const baggingExport = data => {
  return request.post('/bagging/export', data);
};

/**
 * @description: 导出运单详情
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const bigBagExportWaybillDetails = data => {
  return request({
    method: 'POST',
    url: '/wms/bigBag/exportWaybillDetails',
    data
  });
};

/**
 * @description: 导出装袋数据
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const bigBagExporTwayBillBag = data => {
  return request({
    method: 'POST',
    url: '/wms/bigBag/exporTwayBillBag',
    data
  });
};
