<template>
  <div>
    <div style="margin-bottom: 20px;">
      <el-form>
        <el-row>
          <el-col :span="12">
            <el-row>
              <el-col class="col2">
                <el-select
                  v-model="selectCondition" class="selectConditionClass autoWidth" :placeholder="$t('collectionCenter.conditionQuery')"
                  @change="selectConditionClick"
                >
                  <template slot="prefix">
                    {{ (chooseCondition.find(item => item.value === selectCondition) || {}).chooseName || $t('collectionCenter.conditionQuery') }}
                  </template>
                  <el-option
                    v-for="(item, index) in chooseCondition"
                    :key="index"
                    :label="item.chooseName"
                    :value="item.value"
                  />
                </el-select>
              </el-col>
              <el-col v-if="selectCondition == 'c1'" class="colWill">
                <!-- 订单号： -->
                <el-form-item>
                  <el-input
                    v-model="odd.orderNumber" type="textarea" autosize
                    :placeholder="$t('collectionCenter.BatchQuery')"
                  />
                </el-form-item>
              </el-col>
              <el-col v-if="selectCondition == 'c3'" class="colWill">
                <!-- 包裹号： -->
                <el-form-item>
                  <el-input
                    v-model="odd.packageNumber" type="textarea" autosize
                    :placeholder="$t('collectionCenter.BatchQuery')"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="12">
          <el-button
            v-permit:remove="'btn:collection-center:report:MonitoringReport:odd-find'"
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="oddSearch"
          >{{ $t('collectionCenter.search') }}</el-button>
          <!-- 查询 -->
          <el-button
            v-permit:remove="'btn:collection-center:report:MonitoringReport:odd-reset'"
            icon="el-icon-refresh"
            size="mini"
            @click="resetClickOdd"
          >{{ $t('collectionCenter.reset') }}</el-button>
          <!-- 重置 -->
          <el-button
            v-if="activeNameDH === 'dh' && oddExportDH"
            icon="el-icon-upload2"
            size="mini"
            :class="loadUpList.length <=0 && isClick ? 'onlyClickOnce10' : ''"
            @click="exportClickAll"
          >{{ $t('collectionCenter.export') }}</el-button>
          <el-button
            v-if="activeNameDH === 'dh' && oddAllExportDH"
            icon="el-icon-upload"
            size="mini"
            :class="isClickAll3 ? 'onlyClickOnce10' : ''"
            @click="downloadAll"
          >{{ $t('collectionCenter.allExport') }}</el-button><!-- 全部导出 -->
          <el-button
            v-if="activeNameDH === 'dmx' && oddExportMX"
            icon="el-icon-upload2"
            size="mini"
            :class="loadUpList.length <=0 && isClick ? 'onlyClickOnce10' : ''"
            @click="downloadLogistics"
          >{{ $t('collectionCenter.export') }}</el-button>
          <!-- 导出 -->
          <el-button
            v-if="activeNameDH === 'dmx' && oddAllExportMX"
            icon="el-icon-upload"
            size="mini"
            :class="isClickAll4 ? 'onlyClickOnce10' : ''"
            @click="downloadAllMX"
          >{{ $t('collectionCenter.allExport') }}</el-button><!-- 全部导出 -->
        </el-col>
      </el-row>
    </div>
    <el-tabs v-model="activeNameDH" type="border-card" @tab-click="handleOddClick">
      <!-- 单号 -->
      <el-tab-pane :label="$t('collectionCenter.Number')" name="dh">
        <div style="padding: 10px;box-sizing: border-box;">
          <div>
            <el-table
              ref="oddDH"
              :max-height="600"
              border
              show-summary
              :summary-method="getSummaries"
              :data="oddTableDate"
              @select="batchSelectDH"
              @select-all="batchSelectAllDH"
            >
              <el-table-column
                type="selection"
                width="55"
              />
              <el-table-column
                min-width="120"
                align="center"
                prop="customerId"
                :label="$t('collectionCenter.clientID')"
              /><!-- 客户编号 -->
              <el-table-column
                min-width="130"
                align="center"
                prop="customerName"
                :label="$t('collectionCenter.CustomerName')"
              /><!-- 客户名称 -->
              <el-table-column
                min-width="100"
                align="center"
                prop="merchantCode"
                :label="$t('collectionCenter.MerchantNum')"
              /><!-- 商户号 -->
              <el-table-column
                min-width="150"
                align="center"
                prop="merchantName"
                :label="$t('collectionCenter.MerchantName')"
              /><!-- 商户名称 -->
              <el-table-column
                min-width="150"
                align="center"
                prop="collectSource"
                :label="$t('collectionCenter.lanshouTypee')"
              ><!-- 揽收方式 -->
                <template slot-scope="scope">
                  <span>
                    <!-- 1:drop-off(上门交件)，2:pick-up(上门揽件) -->
                    {{
                      scope.row.collectSource == 1 ? $t('collectionCenter.dropOff') :
                      scope.row.collectSource == 2 ? $t('collectionCenter.doorPickUp') : ''
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                min-width="150"
                align="center"
                prop="collectWay"
                :label="$t('collectionCenter.collectWay')"
              ><!-- 实际揽收方式 -->
                <template slot-scope="scope">
                  <span>
                    <!--  0:APP 1:PC  -->
                    {{
                      scope.row.collectWay == 0 ? 'APP' :
                      scope.row.collectWay == 1 ? 'PC' : ''
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                min-width="150"
                align="center"
                prop="createTime"
                :label="$t('collectionCenter.orderCreateT')"
              /><!-- 订单创建时间 -->
              <el-table-column
                min-width="200"
                align="center"
                prop="orderCount"
                :label="$t('collectionCenter.TotalNumberOfOrdersReceived')"
              ><!-- 总揽收订单数 -->
                <template slot-scope="scope">
                  <el-button v-if="hzTomx" size="mini" @click="cellClickOdd(scope.row)">{{ scope.row.orderCount }}</el-button>
                  <span v-else>{{ scope.row.orderCount }}</span>
                </template>
              </el-table-column>
              <el-table-column
                min-width="150"
                prop="collectedCount"
                align="center"
                :label="$t('collectionCenter.ActualNumberOfOrdersReceived')"
              ><!-- 实际已揽收订单数 -->
                <template slot-scope="scope">
                  <el-button v-if="hzTomx" size="mini" @click="cellClickOdd(scope.row, 'collectTimeBool1')">{{ scope.row.collectedCount }}</el-button>
                  <span v-else>{{ scope.row.collectedCount }}</span>
                </template>
              </el-table-column>
              <el-table-column
                min-width="150"
                prop="uncollectedCount"
                align="center"
                :label="$t('collectionCenter.NumberOfUnreceivedOrders')"
              ><!-- 未揽收订单数 -->
                <template slot-scope="scope">
                  <el-button v-if="hzTomx" size="mini" @click="cellClickOdd(scope.row, 'collectTimeBool2')">{{ scope.row.uncollectedCount }}</el-button>
                  <span v-else>{{ scope.row.uncollectedCount }}</span>
                </template>
              </el-table-column>
              <el-table-column
                min-width="200"
                prop="collectErrorCount"
                align="center"
                :label="$t('collectionCenter.AbnormalNumberOfOrdersReceived')"
              ><!-- 揽收异常订单数 -->
                <template slot-scope="scope">
                  <el-button v-if="hzTomx" size="mini" @click="cellClickOdd(scope.row, [5, 7])">{{ scope.row.collectErrorCount }}</el-button>
                  <span v-else>{{ scope.row.collectErrorCount }}</span>
                </template>
              </el-table-column>
              <el-table-column
                min-width="150"
                prop="signedInCount"
                align="center"
                :label="$t('collectionCenter.orderNumberHasBeenSigned')"
              ><!-- 已签收订单数 -->
                <template slot-scope="scope">
                  <el-button v-if="hzTomx" size="mini" @click="cellClickOdd(scope.row, 'signerTimeBool')">{{ scope.row.signedInCount }}</el-button>
                  <span v-else>{{ scope.row.signedInCount }}</span>
                </template>
              </el-table-column>
              <el-table-column
                min-width="150"
                prop="canceledCount"
                align="center"
                :label="$t('collectionCenter.orderNumberHasBeenCancelled')"
              ><!-- 已取消订单数 -->
                <template slot-scope="scope">
                  <el-button v-if="hzTomx" size="mini" @click="cellClickOdd(scope.row, [2])">{{ scope.row.canceledCount }}</el-button>
                  <span v-else>{{ scope.row.canceledCount }}</span>
                </template>
              </el-table-column>
              <el-table-column
                min-width="150"
                prop="bagSealedCount"
                align="center"
                :label="$t('collectionCenter.SealedBagOrderNumber')"
              ><!-- 已封袋订单数 -->
                <template slot-scope="scope">
                  <el-button v-if="hzTomx" size="mini" @click="cellClickOdd(scope.row, 'bagSealingTimeBool')">{{ scope.row.bagSealedCount }}</el-button>
                  <span v-else>{{ scope.row.bagSealedCount }}</span>
                </template>
              </el-table-column>
              <el-table-column
                min-width="150"
                align="center"
                :label="$t('collectionCenter.RangeEarningRate')"
              ><!-- 揽收率 -->
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.collectSuccessRate }}%
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                min-width="150"
                align="center"
                :label="$t('collectionCenter.RateOfSigning')"
              ><!-- 签收率 -->
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.signInRate }}%
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-tab-pane>
      <!-- 明细 -->
      <el-tab-pane v-if="isShowMXOdd" :label="$t('collectionCenter.particulars')" name="dmx">
        <div>
          <el-table
            ref="oddDMX"
            :max-height="600"
            border
            :data="OddMXTableDate"
            @select="batchSelectOddMX"
            @select-all="batchSelectAllOddMX"
          >
            <el-table-column
              type="selection"
              width="55"
            />
            <el-table-column
              prop="merchantCode"
              :label="$t('collectionCenter.MerchantNum')"
            /><!-- 商户号 -->
            <el-table-column
              prop="merchantName"
              :label="$t('collectionCenter.MerchantName')"
            /><!-- 商户名称 -->
            <el-table-column
              prop="orderNumber"
              :label="$t('collectionCenter.lanshouOrderNum')"
            /><!-- 揽收订单号 -->
            <el-table-column
              prop="collectListNumber"
              :label="$t('collectionCenter.receivingListNum')"
            /><!-- 揽收清单号 -->
            <el-table-column
              prop="packageNumber"
              :label="$t('collectionCenter.parcelNum')"
            /><!-- 包裹号 -->
            <el-table-column
              prop="scanNumber"
              :label="$t('collectionCenter.Barcode')"
            /><!-- 条形码 -->
            <el-table-column
              prop="bagNumber"
              :label="$t('collectionCenter.BagNumber')"
            /><!-- 袋号 -->
            <el-table-column
              prop="siteName"
              :label="$t('collectionCenter.collectionDot')"
            /><!-- 揽收网点 -->
            <el-table-column
              prop="driverName"
              :label="$t('collectionCenter.LanShouPeople')"
            /><!-- 揽收人 -->
            <el-table-column
              prop="scanTime"
              :label="$t('collectionCenter.lanshouT')"
            /><!-- 揽收时间 -->
          </el-table>
        </div>
        <div class="right">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryFormOddMX.pageNumber"
            :page-sizes="[10, 20, 30, 50, 100, 500, 1000]"
            :page-size.sync="queryFormOddMX.pageSize"
            :total="OddMXTotal"
            background
            style="margin: 10px 0"
            @current-change="getOddPage"
            @size-change="oddSearch"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  collectOrderCountQueryDetailPage,
  findListOrderNumberPage,
  findListOrderDetailExportAll,
  findGatherExportAll
} from '@/api/lanshou';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { goExportList } from '@/utils/goExportList.js';

export default {
  data() {
    return {
      isClickAll3: false,
      isClickAll4: false,
      isClick: false,
      hzTomx: this.$store.getters.button_permissions.includes('btn:collection-center:report:MonitoringReport:odd-hzTomx'),
      oddExportDH: this.$store.getters.button_permissions.includes('btn:collection-center:report:MonitoringReport:odd-export-dh'),
      oddAllExportDH: this.$store.getters.button_permissions.includes('btn:collection-center:report:MonitoringReport:odd-allExport-dh'),
      oddExportMX: this.$store.getters.button_permissions.includes('btn:collection-center:report:MonitoringReport:odd-export-mx'),
      oddAllExportMX: this.$store.getters.button_permissions.includes('btn:collection-center:report:MonitoringReport:odd-allExport-mx'),
      // 按单号----------------------------------------------------------
      loadUpList: [], // 导出数据
      isShowMXOdd: false, // 控制明细表显示
      activeNameDH: 'dh', // dh 单号   dmx 明细
      selectCondition: 'c1', // 单号查询——选择条件
      chooseCondition: [
        { value: 'c1', chooseName: this.$t('collectionCenter.lanshouOrderNum') }, // '订单号'
        { value: 'c3', chooseName: this.$t('collectionCenter.parcelNum') } // '包裹号'
      ],
      odd: { // 单号
        orderNumber: '', // 订单号
        listNumber: '', // 清单号
        packageNumber: '' // 包裹号
      },
      oddTableDate: [], // 单号表单
      tableOrderIdList: [], // 单号——导出——选中的数据id
      queryFormOddMX: { // 单号——明细查询条件
        pageNumber: 1,
        pageSize: 10,
        params: {
          beginTime: '', // 开始时间
          endTime: '', // 结束时间
          siteId: '', // 站点id
          driverId: '', // 司机id
          customerId: '', // 客户id
          merchantCode: '', // 商户号
          merchantName: '', // 商户名称
          orderNumber: '',
          listNumber: '',
          packageNumber: '',
          collectstatusList: [],
          sourceType: '',
          customerCollectType: ''
        }
      },
      OddMXTableDate: [], // 单号——明细表单
      OddMXTotal: 0, // 单号——明细表单total
      oddTableIdList: [] // 按单号查询——明细——导出——选中的数据id
    };
  },
  watch: {
    selectCondition() {
      if (!this.selectCondition) {
        this.selectConditionClick(); // 清空值
      }
    }
  },
  created() {
    this.isClick = false;
    this.isClickAll3 = false;
    this.isClickAll4 = false;
  },
  methods: {
    // --------------------------按单号查询--------------------------------
    handleOddClick() {
      if (this.activeNameDH === 'dh') {
        this.isShowMXOdd = false;
        this.queryFormOddMX.pageNumber = 1;
      }
    },
    selectConditionClick() { // 单号：条件查询——选项发生变化时触发
      this.odd.orderNumber = '';
      this.odd.listNumber = '';
      this.odd.packageNumber = '';
    },
    oddSearch() { // 按单号查询
      if (this.activeNameDH === 'dh') {
        this.oddPage();
      }
      if (this.activeNameDH === 'dmx') {
        this.queryFormOddMX.pageNumber = 1;
        this.getOddPage();
      }
    },
    oddPage() { // 单号——表单
      if (this.activeNameDH === 'dh') {
        if (this.odd.orderNumber || this.odd.listNumber || this.odd.packageNumber) {
          localStorage.setItem('MROddHQueryForm', JSON.stringify(this.odd));
          findListOrderNumberPage(this.odd).then(res => {
            if (res.status === 'OK') {
              this.oddTableDate = res.data;
            }
          }).catch({});
        } else {
          this.oddTableDate = [];
          this.$message.warning(this.$t('collectionCenter.pleaseCheckAndInput')); // 请选择条件查询并输入内容
        }
      }
      if (this.activeNameDH === 'dmx') {
        this.queryFormOddMX.pageNumber = 1;
        this.getOddPage();
      }
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('collectionCenter.summary');
          return;
        }
        if (column.label === this.$t('collectionCenter.TotalNumberOfOrdersReceived') ||
            column.label === this.$t('collectionCenter.ActualNumberOfOrdersReceived') ||
            column.label === this.$t('collectionCenter.NumberOfUnreceivedOrders') ||
            column.label === this.$t('collectionCenter.AbnormalNumberOfOrdersReceived') ||
            column.label === this.$t('collectionCenter.orderNumberHasBeenSigned') ||
            column.label === this.$t('collectionCenter.orderNumberHasBeenCancelled') ||
            column.label === this.$t('collectionCenter.SealedBagOrderNumber')) {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += '';
          } else {
            sums[index] = '';
          }
        }
      });
      return sums;
    },
    resetClickOdd() { // 单号——重置
      this.odd = {
        orderNumber: '', // 订单号
        listNumber: '', // 清单号
        packageNumber: '' // 包裹号
      };
    },
    SelectDH(selection) {
      this.loadUpList = [];
      this.loadUpList = selection;
      const tDHIdList = [];
      selection.forEach(list => {
        tDHIdList.push(list.customerId);
      });
      this.tableOrderIdList = tDHIdList;
    },
    batchSelectDH(selection, row) { // 单号——多选
      this.SelectDH(selection);
    },
    batchSelectAllDH(selection) { // 单号——全选
      this.SelectDH(selection);
    },
    // 下载揽收监控报表替换模板
    exportClickAll() { // 汇总、单号导出
      this.isClick = true;
      if (this.loadUpList.length <= 0) {
        // '请勾选要导出的数据'
        this.$message.warning(this.$t('collectionCenter.PleaseTickTheDataExport'));
        return;
      }
      const that = this;
      async function ddd() {
        const wb = new ExcelJS.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        Sheet1.getRow(1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '009944' }};
        Sheet1.getRow(1).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
        Sheet1.getRow(1).height = 30;
        Sheet1.getRow(1).font = { bold: true, color: { argb: '000000' }, sz: 30 };
        Sheet1.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
        Sheet1.views = [
          { state: 'frozen', xSplit: undefined, ySplit: 1 }
        ];
        let Sheet1_data = [];
        Sheet1.columns = [
          {
            header: that.$t('collectionCenter.clientID'), // '客户编号'
            key: 'customerId',
            width: 15
          },
          {
            header: that.$t('collectionCenter.MerchantNum'), // '商户号'
            key: 'merchantCode',
            width: 15
          },
          {
            header: that.$t('collectionCenter.lanshouTypee'), // '揽收方式'
            key: 'collectSource',
            width: 15
          },
          {
            header: that.$t('collectionCenter.collectWay'), // '实际揽收方式'
            key: 'collectWay',
            width: 15
          },
          {
            header: that.$t('collectionCenter.orderCreateT'), // '订单创建时间'
            key: 'createTime',
            width: 15
          },
          {
            header: that.$t('collectionCenter.TotalNumberOfOrdersReceived'), // '总揽收订单数'
            key: 'orderCount',
            width: 15
          },
          {
            header: that.$t('collectionCenter.ActualNumberOfOrdersReceived'), // '实际已揽收订单数'
            key: 'collectedCount',
            width: 15
          },
          {
            header: that.$t('collectionCenter.NumberOfUnreceivedOrders'), // '未揽收订单数'
            key: 'uncollectedCount',
            width: 15
          },
          {
            header: that.$t('collectionCenter.AbnormalNumberOfOrdersReceived'), // '揽收异常订单数'
            key: 'collectErrorCount',
            width: 15
          },
          {
            header: that.$t('collectionCenter.orderNumberHasBeenSigned'), // '已签收订单数'
            key: 'signedInCount',
            width: 15
          },
          {
            header: that.$t('collectionCenter.orderNumberHasBeenCancelled'), // '已取消订单数'
            key: 'canceledCount',
            width: 15
          },
          {
            header: that.$t('collectionCenter.SealedBagOrderNumber'), // '已封袋订单数'
            key: 'bagSealedCount',
            width: 15
          },
          {
            header: that.$t('collectionCenter.RangeEarningRate'), // '揽收率'
            key: 'collectSuccessRate',
            width: 15
          },
          {
            header: that.$t('collectionCenter.RateOfSigning'), // '签收率'
            key: 'signInRate',
            width: 15
          }
        ];
        Sheet1_data = [];
        that.loadUpList.map((item) => {
          Sheet1_data.push({
            customerId: item.customerId,
            merchantCode: item.merchantCode,
            collectSource: item.collectSource === 1 ? that.$t('collectionCenter.dropOff') : item.collectSource === 2 ? that.$t('collectionCenter.doorPickUp') : '',
            collectWay: item.collectWay === 0 ? 'APP' : item.collectWay === 1 ? 'PC' : '',
            createTime: item.createTime,
            orderCount: item.orderCount,
            collectedCount: item.collectedCount,
            uncollectedCount: item.uncollectedCount,
            collectErrorCount: item.collectErrorCount,
            signedInCount: item.signedInCount,
            canceledCount: item.canceledCount,
            bagSealedCount: item.bagSealedCount,
            collectSuccessRate: item.collectSuccessRate + '%',
            signInRate: item.signInRate + '%'
          });
        });
        Sheet1.addRows(Sheet1_data);
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        const saveName = that.$t('collectionCenter.collectXlsx') + '.xlsx'; // '订单揽收监控报表汇总.xlsx'
        saveAs(blob, saveName);
        return;
      }
      ddd();
      this.loadUpList = [];
      this.$refs.oddDH.clearSelection();
    },
    downloadLogistics() { // 明细导出
      this.isClick = true;
      if (this.loadUpList.length <= 0) {
        // '请勾选要导出的数据'
        this.$message.warning(this.$t('collectionCenter.PleaseTickTheDataExport'));
        return;
      }
      const that = this;
      async function ddd() {
        const wb = new ExcelJS.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        Sheet1.getRow(1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '009944' }};
        Sheet1.getRow(1).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
        Sheet1.getRow(1).height = 30;
        Sheet1.getRow(1).font = { bold: true, color: { argb: '000000' }, sz: 30 };
        Sheet1.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
        Sheet1.views = [
          { state: 'frozen', xSplit: undefined, ySplit: 1 }
        ];
        let Sheet2_data = [];
        Sheet1.columns = [
          {
            header: that.$t('collectionCenter.MerchantNum'), // '商户号'
            key: 'merchantCode',
            width: 15
          },
          {
            header: that.$t('collectionCenter.lanshouOrderNum'), // '揽收订单号'
            key: 'orderNumber',
            width: 30
          },
          {
            header: that.$t('collectionCenter.receivingListNum'), // '揽收清单号'
            key: 'collectListNumber',
            width: 30
          },
          {
            header: that.$t('collectionCenter.parcelNum'), // '包裹号'
            key: 'packageNumber',
            width: 20
          },
          {
            header: that.$t('collectionCenter.Barcode'), // '条形码'
            key: 'scanNumber',
            width: 20
          },
          {
            header: that.$t('collectionCenter.BagNumber'), // '袋号'
            key: 'bagNumber',
            width: 15
          },
          {
            header: that.$t('collectionCenter.collectionDot'), // '揽收网点'
            key: 'siteName',
            width: 15
          },
          {
            header: that.$t('collectionCenter.LanShouPeople'), // '揽收人'
            key: 'driverName',
            width: 15
          },
          {
            header: that.$t('collectionCenter.lanshouT'), // '揽收时间'
            key: 'scanTime',
            width: 20
          }
        ];
        Sheet2_data = [];
        that.loadUpList.map((item) => {
          Sheet2_data.push({
            merchantCode: item.merchantCode,
            orderNumber: item.orderNumber,
            collectListNumber: item.collectListNumber,
            packageNumber: item.packageNumber,
            scanNumber: item.scanNumber,
            bagNumber: item.bagNumber,
            siteName: item.siteName,
            driverName: item.driverName,
            scanTime: item.scanTime
          });
        });
        Sheet1.addRows(Sheet2_data);
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });

        const saveName = that.$t('collectionCenter.detailXlsx') + '.xlsx'; // '订单揽收监控报表明细.xlsx'

        saveAs(blob, saveName);
        // if (typeof url === 'object' && url instanceof Blob) {
        //   url = URL.createObjectURL(url);
        // }
        // const aLink = document.createElement('a');
        // aLink.href = url;
        // aLink.download = saveName || '';
        // let event;
        // if (window.MouseEvent) event = new MouseEvent('click');
        // else {
        //   event = document.createEvent('MouseEvents');
        //   event.initMouseEvent(
        //     'click',
        //     true,
        //     false,
        //     window,
        //     0,
        //     0,
        //     0,
        //     0,
        //     0,
        //     false,
        //     false,
        //     false,
        //     false,
        //     0,
        //     null
        //   );
        // }
        // aLink.dispatchEvent(event);
        return;
      }
      ddd();
      this.loadUpList = [];
      this.$refs.oddDMX.clearSelection();
    },
    cellClickOdd(row, ind) { // 单号切换到 ——> 明细
      this.loadUpList = [];
      this.isShowMXOdd = true;
      this.activeNameDH = 'dmx';
      this.queryFormOddMX.params.customerId = row.customerId;
      if (ind === 'collectTimeBool1') {
        this.queryFormOddMX.params.collectTimeBool = 'false';
        this.queryFormOddMX.params.signerTimeBool = '';
        this.queryFormOddMX.params.bagSealingTimeBool = '';
        this.queryFormOddMX.params.collectstatusList = [];
      } else if (ind === 'collectTimeBool2') {
        this.queryFormOddMX.params.collectTimeBool = 'true';
        this.queryFormOddMX.params.signerTimeBool = '';
        this.queryFormOddMX.params.bagSealingTimeBool = '';
        this.queryFormOddMX.params.collectstatusList = [];
      } else if (ind === 'signerTimeBool') {
        this.queryFormOddMX.params.signerTimeBool = 'false';
        this.queryFormOddMX.params.collectTimeBool = '';
        this.queryFormOddMX.params.bagSealingTimeBool = '';
        this.queryFormOddMX.params.collectstatusList = [];
      } else if (ind === 'bagSealingTimeBool') {
        this.queryFormOddMX.params.bagSealingTimeBool = 'false';
        this.queryFormOddMX.params.collectTimeBool = '';
        this.queryFormOddMX.params.signerTimeBool = '';
        this.queryFormOddMX.params.collectstatusList = [];
      } else if (ind) {
        this.queryFormOddMX.params.collectstatusList = ind;
        this.queryFormOddMX.params.collectTimeBool = '';
        this.queryFormOddMX.params.signerTimeBool = '';
        this.queryFormOddMX.params.bagSealingTimeBool = '';
      } else {
        this.queryFormOddMX.params.collectTimeBool = '';
        this.queryFormOddMX.params.signerTimeBool = '';
        this.queryFormOddMX.params.bagSealingTimeBool = '';
      }
      this.getOddPage(row);
    },
    // 单号——明细表单
    getOddPage(row) {
      this.loadUpList = [];
      this.tableOrderIdList = [];
      this.oddTableIdList = [];
      let merchantCode = '';
      let merchantName = '';
      let timeS = '';
      let timeE = '';
      let orderNumber = '';
      let listNumber = '';
      let packageNumber = '';
      let sourceType = '';
      let customerCollectType = '';
      if (row) {
        sourceType = row.collectWay;
        customerCollectType = row.collectSource;
        this.queryFormOddMX.params.sourceType = row.collectWay;
        this.queryFormOddMX.params.customerCollectType = row.collectSource;
        merchantCode = row.merchantCode;
        merchantName = row.merchantName;
        if (row.createTime) {
          timeS = row.createTime + ' 00:00:00';
          timeE = row.createTime + ' 23:59:59';
          this.queryFormOddMX.params.beginTime = timeS;
          this.queryFormOddMX.params.endTime = timeE;
        }
        const oddHQueryForm = JSON.parse(localStorage.getItem('MROddHQueryForm'));
        orderNumber = oddHQueryForm.orderNumber;
        listNumber = oddHQueryForm.listNumber;
        packageNumber = oddHQueryForm.packageNumber;
      }
      this.queryFormOddMX = {
        pageNumber: this.queryFormOddMX.pageNumber,
        pageSize: this.queryFormOddMX.pageSize,
        params: {
          beginTime: this.queryFormOddMX.params.beginTime, // 开始时间
          endTime: this.queryFormOddMX.params.endTime, // 结束时间
          siteId: '', // 站点id
          driverId: '', // 司机id
          customerId: this.queryFormOddMX.params.customerId, // 客户id
          merchantCode, // 商户号
          merchantName, // 商户名称
          orderNumber: row ? orderNumber : this.odd.orderNumber, // 订单号
          listNumber: row ? listNumber : this.odd.listNumber, // 清单号
          packageNumber: row ? packageNumber : this.odd.packageNumber, // 包裹号
          collectstatusList: this.queryFormOddMX.params.collectstatusList, // 状态
          collectTimeBool: this.queryFormOddMX.params.collectTimeBool,
          signerTimeBool: this.queryFormOddMX.params.signerTimeBool,
          bagSealingTimeBool: this.queryFormOddMX.params.bagSealingTimeBool,
          sourceType: sourceType || this.queryFormOddMX.params.sourceType,
          customerCollectType: customerCollectType || this.queryFormOddMX.params.customerCollectType
        }
      };
      const qp = this.queryFormOddMX.params;
      if (qp.collectstatusList <= 0 || !qp.collectstatusList) {
        delete this.queryFormOddMX.params.collectstatusList;
      }
      if (!qp.collectTimeBool) {
        delete this.queryFormOddMX.params.collectTimeBool;
      }
      if (!qp.signerTimeBool) {
        delete this.queryFormOddMX.params.signerTimeBool;
      }
      if (!qp.bagSealingTimeBool) {
        delete this.queryFormOddMX.params.bagSealingTimeBool;
      }
      localStorage.setItem('MROddMXQueryForm', JSON.stringify(this.queryFormOddMX.params));
      collectOrderCountQueryDetailPage(this.queryFormOddMX).then(res => {
        if (res.status === 'OK') {
          this.OddMXTableDate = res.data.records;
          this.OddMXTotal = res.data.total;
        }
      }).catch({});
    },
    SelectOddMX(selection) {
      this.loadUpList = [];
      this.loadUpList = selection;
      const tIdList = [];
      selection.forEach(list => {
        tIdList.push(list.id);
      });
      this.oddTableIdList = tIdList;
    },
    batchSelectOddMX(selection, row) { // 明细——多选
      this.SelectOddMX(selection);
    },
    batchSelectAllOddMX(selection) { // 明细——全选
      this.SelectOddMX(selection);
    },
    downloadAll() { // 汇总——全部导出
      this.isClickAll3 = true;
      const dataForm = {
        orderNumber: this.odd.orderNumber,
        listNumber: this.odd.listNumber,
        packageNumber: this.odd.packageNumber
      };
      const queryForm = JSON.parse(localStorage.getItem('MROddHQueryForm')) || dataForm;
      findGatherExportAll(queryForm).then(res => {
        if (res.status === 'OK') {
          goExportList(this);
        }
      }).catch({});
    },
    downloadAllMX() { // 明细——全部导出
      this.isClickAll4 = true;
      const dataForm = {
        beginTime: this.queryFormOddMX.params.beginTime,
        endTime: this.queryFormOddMX.params.endTime,
        siteId: '',
        driverId: '',
        customerId: this.queryFormOddMX.params.customerId,
        merchantCodes: [],
        merchantNames: [],
        orderNumber: this.odd.orderNumber,
        listNumber: this.odd.listNumber,
        packageNumber: this.odd.packageNumber,
        collectstatusList: this.queryFormOddMX.params.collectstatusList,
        collectTimeBool: this.queryFormOddMX.params.collectTimeBool,
        signerTimeBool: this.queryFormOddMX.params.signerTimeBool,
        bagSealingTimeBool: this.queryFormOddMX.params.bagSealingTimeBool,
        sourceType: this.queryFormOddMX.params.sourceType,
        customerCollectType: this.queryFormOddMX.params.customerCollectType
      };
      const qp = this.queryFormOddMX.params;
      if (qp.collectstatusList <= 0 || !qp.collectstatusList) {
        delete this.queryFormOddMX.params.collectstatusList;
        delete dataForm.collectstatusList;
      }
      if (!qp.collectTimeBool) {
        delete this.queryFormOddMX.params.collectTimeBool;
        delete dataForm.collectTimeBool;
      }
      if (!qp.signerTimeBool) {
        delete this.queryFormOddMX.params.signerTimeBool;
        delete dataForm.signerTimeBool;
      }
      if (!qp.bagSealingTimeBool) {
        delete this.queryFormOddMX.params.bagSealingTimeBool;
        delete dataForm.bagSealingTimeBool;
      }
      const queryForm = JSON.parse(localStorage.getItem('MROddMXQueryForm')) || dataForm;
      findListOrderDetailExportAll(queryForm).then(res => {
        goExportList(this);
      }).catch({});
    }
  }
};
</script>

<style lang="scss" scoped>

  // 防止按钮重复点击
  @keyframes throttle{
    from{
      filter: opacity(40%);
      pointer-events: none;
    }
    to{
      pointer-events: all;
    }
  }
  .onlyClickOnce2{
    animation: throttle 2s step-end forwards;
  }
  .onlyClickOnce2:active{
    filter: opacity(40%);
    animation: none;
  }
  .onlyClickOnce10{
    animation: throttle 10s step-end forwards;
  }
  .onlyClickOnce10:active{
    filter: opacity(40%);
    animation: none;
  }

  .autoWidth ::v-deep .el-input__prefix{ // 下拉框自适应占位
    position: relative;
    left: 0;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    padding: 0 25px;
    padding-left: 0;
    margin-left: 20px;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    visibility: hidden;
    white-space: nowrap;
  }
  .autoWidth ::v-deep input {
    position: absolute;
  }
  .colWill ::v-deep textarea.el-textarea__inner {
    max-height: 150px;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .col2 {
    width: auto !important;
    // margin-left: 20px;
  }
  .colWill{
    width: calc(100% - 210px);
  }
  .selectConditionClass ::v-deep.el-input--suffix .el-input__inner{
    text-align: right;
    border: none !important;
    font-size: 14px;
    // color: #606266;
    font-weight: bold;
    padding-right: 25px;
    padding-left: 0;
    &::placeholder{
      color: #606266 !important;
    }
    &::-webkit-input-placeholder{
      color: #606266 !important;
    }
  }
  .colWill ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
</style>
