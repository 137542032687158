var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    [
      _vm.isSubmit
        ? _c(
            "div",
            { staticClass: "tips" },
            [
              _vm._m(0),
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "350px",
                    size: "medium",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "上传发票/fazer upload da nota fiscal",
                        prop: "attachmentUrl",
                      },
                    },
                    [
                      _c("UploadCmp", {
                        attrs: {
                          drag: true,
                          multiple: true,
                          "auto-upload": true,
                          limit: 5,
                          "upload-text": _vm.uploadText,
                        },
                        on: { change: _vm.onFileChange },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: !_vm.isSubmitBut,
                            type: "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v("确定/Determinar")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "el-result",
            {
              attrs: {
                icon: "success",
                title: "成功/success",
                "sub-title": "",
              },
            },
            [
              _c(
                "template",
                { slot: "extra" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "medium" },
                      on: { click: _vm.closePage },
                    },
                    [_vm._v("关闭/fechar")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("p", [_vm._v("Aviso: ")]),
        _c("div", [
          _vm._v(
            " Para garantir a segurança de seus dados, esse link permite apenas um único upload de uma NOTA FISCAL. Portanto, preencha corretamente o conteúdo da NOTA FISCAL ao emiti-la para garantir que a fatura carregada esteja correta. Muito obrigado!"
          ),
        ]),
      ]),
      _c("li", [
        _c("p", [_vm._v("注意：")]),
        _c("div", [
          _vm._v(
            " 为了您的数据安全此链接只允许单次上传发票。因此，请在开具发票时正确填写发票内容，以确保上传的发票正确无误。非常感谢！ "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }