<template>
  <div class="container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :key="0" :label="$t('GlobalSetObj.BagNumbeDetail')" name="first">
        <div style="height: calc(100vh - 145px);overflow: hidden auto;padding: 0 10px;">
          <el-form label-position="top">
            <el-descriptions :colon="false" :column="4" direction="vertical">
              <!--总单号-->
              <el-descriptions-item :label="$t('GlobalSetObj.totalSingNumber')">
                <div>{{ totalSingleObject.instoreBigBagNo || '--' }}</div>
              </el-descriptions-item>
              <!--服务商-->
              <el-descriptions-item :label="$t('GlobalSetObj.fuwushang')">
                <div>{{ totalSingleObject.systemName || '--' }}</div>
              </el-descriptions-item>
              <!--派送网点-->
              <el-descriptions-item :label="$t('GlobalSetObj.DeliveryPoint')">
                <div>{{ totalSingleObject.deliveryStationName || '--' }}</div>
              </el-descriptions-item>
              <!--总重量（KG）-->
              <el-descriptions-item :label="$t('placeOrder.totalWeight')">
                <div>{{ sumTotalWeight || '--' }}</div>
              </el-descriptions-item>
            </el-descriptions>
          </el-form>
          <div style="margin: 20px 0;">
            <el-form label-width="auto" @submit.native.prevent>
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item :label="$t('GlobalSetObj.bagNumber')">
                    <el-input
                      v-model="queryForm1.params.transitBagNumber"
                      :placeholder="$t('GlobalSetObj.pleaseInput')"
                      clearable
                      size="small"
                      @keyup.enter.native="getSearchData(1)"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div>
              <el-button
                type="primary"
                size="small"
                icon="el-icon-search"
                @click="getSearchData(1)"
              >{{ $t('operationCenter.Procurar') }}</el-button>
              <div v-if="assemblyStatus === 1" style="display: inline-block;">
                <el-input
                  v-model="scanTransitBagNumber"
                  size="small"
                  style="display: inline-block;width: 260px;margin: 0 10px 0 20px"
                  :placeholder="$t('collectionCenter.scanBag')"
                  @keyup.enter.native="addTableData()"
                />
                <el-button
                  size="small"
                  @click="addTableData()"
                >{{ $t('operationCenter.add') }}</el-button>
                <el-button
                  size="small"
                  type="warning"
                  @click="loadingStorage"
                >{{ $t('operationCenter.stowageSave') }}</el-button>
              </div>
              <el-button
                size="small"
                style="float:right;"
                @click="exportTransitBagList"
              >{{ $t('operationCenter.exports') }}</el-button>
            </div>
          </div>
          <div style="margin-top: 10px;">
            <AnjunTableList
              ref="PackageList1"
              :has-operation="true"
              :header-list="TableHeaderList1"
              :is-select-btn="true"
              :is-tip="true"
              :op-width="200"
              :table-data="TableData1"
              @selectTable="selectTable"
              @on-delStr="onDel"
            />
            <!--  分页  -->
            <el-pagination
              :current-page="queryForm1.pageNumber"
              :page-size="queryForm1.pageSize"
              :page-sizes="[10, 20, 50, 100]"
              :total="queryForm1.total"
              background
              layout="total, sizes, prev, pager, next, jumper"
              style="margin: 10px 0;text-align: right"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </el-tab-pane>
      <!--  运单明细    -->
      <el-tab-pane :key="1" :label="$t('GlobalSetObj.DetailOfwaybill')" name="second">
        <div style="height: calc(100vh - 145px);overflow: hidden auto;padding: 0 10px;">
          <el-form label-position="top">
            <el-row :gutter="20">
              <el-col :span="9">
                <el-form-item prop="searchType">
                  <template slot="label">
                    <div class="selectLabel">
                      <el-select
                        v-model="queryForm1.params.searchType"
                        :placeholder="$t('GlobalSetObj.pleaseChoose')"
                      >
                        <!--运单号-->
                        <el-option key="1" :label="$t('newOrder.waybillNumber')" :value="1" />
                        <!--客户单号-->
                        <el-option key="2" :label="$t('placeOrder.customerNumber')" :value="2" />
                        <!--服务商单号-->
                        <el-option key="3" :label="$t('GlobalSetObj.serviceLogistics')" :value="3" />
                      </el-select>
                    </div>
                  </template>
                  <el-input
                    v-model="queryForm1.params.inputValue"
                    :placeholder="$t('operationCenter.Multiple200')"
                    clearable
                    type="textarea"
                    :rows="2"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="9" style="height: 80px">
                <!--  袋号   -->
                <el-form-item :label="$t('GlobalSetObj.bagNumber')">
                  <el-input
                    v-model="queryForm1.params.transitBagNumber1"
                    :placeholder="$t('GlobalSetObj.pleaseInput')"
                    clearable
                    @keyup.enter.native="getSearch1(1)"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div style="margin-bottom: 25px;">
            <el-button type="primary" size="small" @click="getSearch1(1)">
              {{ $t('GlobalSetObj.search') }}
            </el-button>
          </div>
          <div>
            <AnjunTableList
              ref="PackageList"
              :has-operation="false"
              :header-list="TableHeaderList12"
              :is-select-btn="false"
              :is-tip="true"
              :table-data="TableData2"
              @selectTable="selectTable"
            />
            <!--  分页  -->
            <el-pagination
              :current-page="queryForm1.pageNumber1"
              :page-size="queryForm1.pageSize1"
              :page-sizes="[10, 20, 50, 100]"
              :total="queryForm1.total1"
              background
              layout="total, sizes, prev, pager, next, jumper"
              style="margin: 10px 0;text-align: right"
              @size-change="handleSizeChange1"
              @current-change="handleCurrentChange1"
            />
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!--  分配申报数量设置  -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="manualDeclarationDialog"
      width="25%"
    >
      <div style="margin-bottom: 25px;font-size: 16px;">{{ $t('GlobalSetObj.ImmediateDeclaration') }}</div>
      <p style="color: #a4a7ab;">{{ $t('GlobalSetObj.AutomaticFiltration') }}</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="manualDeclarationDialog = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button type="primary" @click="confirmDeclaration">{{ $t('GlobalSetObj.confirm') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AnjunTableList from '@/components/anjun-tableList';
import {
  apiAddBag, apiComplete,
  apiRemoveBag,
  bigBagwaybillNumber,
  // deleteTransitBagNumber,
  manualDeclare,
  selectBigBagNumber
  // transitBagNumberPage
} from '@/api/storeAdmin.js';
// import { getUserName } from '@/utils/asyncTools';

export default {
  name: 'SummaryDetails',
  components: {
    AnjunTableList
  },
  props: {
    detailId: {
      type: [Number, String],
      default: ''
    },
    totalSingleObject: {
      type: Object,
      required: true,
      default: () => {
      }
    }
  },
  data() {
    return {
      manualDeclarationDialog: false,
      TableHeaderList1: [
        // 袋号
        { id: 1, label: this.$t('GlobalSetObj.bagNumber'), prop: 'transitBagNumber', clickClass: false },
        // 包裹数
        { id: 2, label: this.$t('GlobalSetObj.package'), prop: 'packageCount', clickClass: false },
        // 重量
        { id: 3, label: this.$t('GlobalSetObj.weight'), prop: 'totalWeight', clickClass: false },
        // 封袋人
        { id: 4, label: this.$t('GlobalSetObj.BagSealer'), prop: 'sealerUser', clickClass: false },
        // 封袋时间
        { id: 5, label: this.$t('GlobalSetObj.BagSealingTime'), prop: 'createTime', clickClass: false }
      ],
      TableHeaderList12: [
        // 袋号
        { id: 1, label: this.$t('GlobalSetObj.bagNumber'), prop: 'transitBagNumber', cloWidth: '180', clickClass: false },
        // 运单号
        { id: 2, label: this.$t('GlobalSetObj.waybillNumber'), prop: 'waybillNumber', cloWidth: '180', clickClass: false },
        // 客户单号
        { id: 3, label: this.$t('placeOrder.customerNumber'), prop: 'customerNumber', cloWidth: '180', clickClass: false },
        // 服务商单号
        { id: 6, label: this.$t('GlobalSetObj.serviceLogistics'), prop: 'logisticsNumber', cloWidth: '180', clickClass: false },
        // 入库单号
        { id: 4, label: this.$t('operationCenter.TheInboundOrderNumber'), prop: 'inboundReceiptNumber', cloWidth: '180' },
        // 重量
        { id: 2, label: this.$t('placeOrder.weight'), prop: 'weight', cloWidth: '160' }
      ],
      TableData1: [],
      TableData2: [],
      queryForm1: {
        pageNumber: 1,
        pageSize: 50,
        total: 0,
        pageNumber1: 1,
        pageSize1: 50,
        total1: 0,
        params: {
          transitBagNumber: '',
          transitBagNumber1: '',
          waybillNumber: '',
          declareStatus: '',
          inputValue: '',
          searchType: 1
        }
      },
      activeName: 'first',
      isShow: true,
      isShow1: false,
      selectionList: [],
      scanTransitBagNumber: '',
      sumTotalWeight: '--',
      assemblyStatus: null
    };
  },
  computed: {
    DeclareStatus() {
      return {
        1: { label: this.$t('GlobalSetObj.undeclared'), code: 1 }, // 未申报
        2: { label: this.$t('GlobalSetObj.SuccessfulDeclaration'), code: 2 }, // 申报成功
        3: { label: this.$t('GlobalSetObj.PartialDeclaration'), code: 3 }, // 部分申报
        4: { label: this.$t('GlobalSetObj.FailureTodeclare'), code: 4 }, // 申报失败
        5: { label: this.$t('GlobalSetObj.NoDeclaration'), code: 5 } // 无需申报
      };
    }
  },
  created() {
    this.assemblyStatus = this.totalSingleObject.assemblyStatus1;
    console.log(this.assemblyStatus, '====this.assemblyStatus');
    this.$nextTick(() => {
      this.getSearchData();
      this.getSearch1();
    });
  },
  methods: {
    // 袋号明细导出
    exportTransitBagList() {
      this.$emit('exportClose', this.selectionList, { instoreBigBagNo: this.detailId, transitBagNumber: this.queryForm1.params.transitBagNumber });
    },
    // 袋号明细配载保存
    loadingStorage() {
      this.$confirm(this.$t('operationCenter.withStowage'), this.$t('GlobalSetObj.tips'), {
        confirmButtonText: this.$t('GlobalSetObj.determine'), // '确定',
        cancelButtonText: this.$t('orderCenterCont.cancel'), // '取消',
        type: 'warning'
      }).then(() => {
        apiComplete(this.totalSingleObject.instoreBigBagNo).then(res => {
          if (res.status === 'OK') {
            this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
            this.assemblyStatus = 1;
            this.$nextTick(() => {
              this.getSearchData();
            });
            this.$emit('updateTable');
          }
        });
      });
    },
    // 袋号明细添加
    addTableData() {
      const param = {
        bagNumber: this.scanTransitBagNumber,
        bagManifestNo: this.totalSingleObject.instoreBigBagNo
      };
      apiAddBag(param).then(res => {
        if (res.status === 'OK') {
          this.scanTransitBagNumber = '';
          this.getSearchData();
          this.$emit('updateTable');
          // const obj = {
          //   bagManifestNo: res.data.bagManifestNo,
          //   totalWeight: res.data.bagManifestNo,
          //   transitBagNumber: res.data.bigBagNo,
          //   packageCount: res.data.packageCount,
          //   createTime: res.data.packageTime,
          //   packageUserId: res.data.packageUserId,
          //   sealerUser: getUserName(res.data.packageUserId, this.userIdAndNameObj),
          //   preDeliverStationName: res.data.preDeliverStationName,
          //   providerName: res.data.providerName
          // };
          // this.TableData1.unshift(obj);
        }
      });
    },
    // 获取运单明细
    getSearch1(type) {
      if (type === 1) {
        this.queryForm1.pageNumber1 = 1;
      }
      const data = {
        pageSize: this.queryForm1.pageSize1,
        pageNumber: this.queryForm1.pageNumber1,
        params: {
          instoreBigBagNo: this.totalSingleObject.instoreBigBagNo,
          transitBagNumber: this.queryForm1.params.transitBagNumber1,
          waybillNumberList: [],
          logisticsNumberList: [],
          customerNumberList: []
        }
      };
      const inputValList = [];
      if (this.queryForm1.params.inputValue) {
        const orderList = this.queryForm1.params.inputValue.split('\n');
        orderList.map(item => {
          const number = item.replace(/\s/g, '').trim();
          if (number) {
            inputValList.push(number);
          }
        });
      }
      if (this.queryForm1.params.searchType === 1) {
        data.params.waybillNumberList = inputValList;
      } else if (this.queryForm1.params.searchType === 2) {
        data.params.customerNumberList = inputValList;
      } else {
        data.params.logisticsNumberList = inputValList;
      }
      bigBagwaybillNumber(data).then(res => {
        console.log(res);
        this.queryForm1.total1 = res.data.total;
        this.TableData2 = res.data.records;
        this.TableData2.map(item => {
          item.PrepostNumber = item.prepostNumber ? `${item.prepostNumber}` : '- -';
          if (item.declareStatus) {
            item.DeclareStatus = this.DeclareStatus[item.declareStatus].label;
          } else {
            item.DeclareStatus = '- -';
          }
        });
      });
    },
    // 获取袋号明细
    getSearchData(type) {
      if (type === 1) {
        this.queryForm1.pageNumber = 1;
      }
      const data = {
        pageSize: this.queryForm1.pageSize,
        pageNumber: this.queryForm1.pageNumber,
        params: {
          transitBagNumber: this.queryForm1.params.transitBagNumber,
          instoreBigBagNo: this.totalSingleObject.instoreBigBagNo
        }
      };
      // data.params.instoreBigBagNo = this.detailId;
      // if (flag === 1) {
      //   data.params.transitBagNumber = this.queryForm1.params.transitBagNumber;
      //   this.queryForm1.pageNumber = 1;
      //   data.pageNumber = this.queryForm1.pageNumber;
      //   transitBagNumberPage(data).then(res => {
      //     this.queryForm1.total = res.data.total;
      //     this.TableData1 = res.data.records;
      //     this.TableData1.map(item => {
      //       item['delStr'] = true;
      //       if (item.declareStatus) {
      //         item.DeclareStatus = this.DeclareStatus[item.declareStatus].label;
      //       } else {
      //         item.DeclareStatus = '- -';
      //       }
      //     });
      //   });
      // } else {
      selectBigBagNumber(data).then(res => {
        if (res.status === 'OK') {
          this.queryForm1.total = res.data.transitWaybillBagBOPage.total;
          this.TableData1 = res.data.transitWaybillBagBOPage.records;
          this.sumTotalWeight = res.data.totalWeight || '--';
          if (res.data.transitWaybillBagBOPage.records && res.data.transitWaybillBagBOPage.records.length > 0) {
            this.TableData1.map(item => {
              if (this.assemblyStatus !== 2) {
                item['delStr'] = true;
              }
              if (item.declareStatus) {
                item.DeclareStatus = this.DeclareStatus[item.declareStatus].label;
              } else {
                item.DeclareStatus = '- -';
              }
            });
          }
        }
      });
      // }
    },
    // 删除
    onDel(row) {
      const data = {
        bagManifestNo: this.totalSingleObject.instoreBigBagNo,
        bagNumber: row.transitBagNumber
      };
      apiRemoveBag(data).then(res => {
        if (res.status === 'OK') {
          this.$message.success(this.$t('GlobalSetObj.DeleteSuccessful'));
          this.getSearchData();
        }
      });
      // const data = {
      //   declareStatus: row.declareStatus,
      //   packageCount: row.packageCount,
      //   transitBagNumber: row.transitBagNumber
      // };
      // deleteTransitBagNumber(data).then(res => {
      //   if (res.status === 'OK') {
      //     this.$message.success(this.$t('GlobalSetObj.DeleteSuccessful'));
      //     this.getSearchData();
      //   }
      // });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm1.pageSize = val;
      this.queryForm1.pageNumber = 1;
      this.getSearchData();
    },
    handleCurrentChange(val) {
      this.queryForm1.pageNumber = val;
      this.getSearchData();
    },
    // 点击分页
    handleSizeChange1(val) {
      this.queryForm1.pageSize1 = val;
      this.queryForm1.pageNumber1 = 1;
      this.getSearch1();
    },
    handleCurrentChange1(val) {
      this.queryForm1.pageNumber1 = val;
      this.getSearch1();
    },
    handleChange(val) {
      this.queryForm1.params.declareStatus = val;
      this.queryForm1.pageNumber1 = 1;
      this.getSearch1();
    },
    // 列表数据勾选
    selectTable(data) {
      this.selectionList = data;
    },
    clickManualDeclaration() {
      if (!this.selectionList.length) {
        this.$message.error(this.$t('GlobalSetObj.CheckForm'));
      } else {
        this.manualDeclarationDialog = true;
      }
    },
    // 确认手动申报
    confirmDeclaration() {
      console.log(this.selectionList, 'this.selectionListthis.selectionList');
      const data = {
        params: this.totalSingleObject,
        manualDeclareList: this.selectionList
      };
      const arr = this.selectionList.filter(item => {
        return !(item.declareStatus === 2 || item.declareStatus === 5);
      });
      if (arr.length === 0) {
        this.$message.error(this.$t('GlobalSetObj.declaradossucesso'));
        return;
      }
      data.manualDeclareList = arr;
      manualDeclare(data).then(res => {
        if (res.status === 'OK') {
          this.$message.success(this.$t('GlobalSetObj.SuccessfulDeclaration'));
          this.manualDeclarationDialog = false;
          this.getSearch1();
          this.$refs.PackageList.toggleSelection();
        }
      }).catch(e => {
        this.$refs.PackageList.toggleSelection();
        this.manualDeclarationDialog = false;
      });
    },
    handleClick(tab, event) {
      if (this.activeName === 'first') {
        this.getSearchData();
      } else {
        this.getSearch1();
      }
      // if (tab.index == '0') {
      //   this.activeName = 'first';
      //   this.getSearchData();
      // }
      // if (tab.index == '1') {
      //   this.activeName = 'second';
      //   this.getSearch1();
      // }
    }
  }
};
</script>

<style scoped>
.container {
  margin-top: 20px;
  padding: 0 10px;
}

.container >>> .el-dialog__body {
  padding-bottom: 10px;
}
</style>
