var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "messageCon" }, [
    _c(
      "div",
      {
        staticStyle: {
          "text-align": "right",
          "line-height": "25px",
          padding: "5px",
        },
      },
      [
        _c("i", {
          staticClass: "el-icon-copy-document",
          staticStyle: { "font-size": "20px", cursor: "pointer" },
          attrs: { title: _vm.$t("orderCenterCont.copy") },
          on: {
            click: function ($event) {
              return _vm.copyData(_vm.jsonStrin)
            },
          },
        }),
      ]
    ),
    _c("pre", [_vm._v(_vm._s(_vm._f("setStyle")(_vm.jsonStrin)))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }