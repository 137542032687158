var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.organizationOpt.title,
            visible: _vm.organizationOpt.visible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.organizationOpt, "visible", $event)
            },
            open: _vm.openOrgOpt,
            close: _vm.closeOrgOpt,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "orgRef",
              attrs: {
                model: _vm.orgForm,
                rules: _vm.rules,
                "label-width": "100px",
                "label-position": "left",
              },
            },
            [
              _vm.organizationOpt.type !== "add"
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "organizationManage.OrganizationID"
                                ),
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.orgForm.id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.orgForm, "id", $$v)
                                  },
                                  expression: "orgForm.id",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.orgForm.parentId !== 0,
                      expression: "orgForm.parentId!==0",
                    },
                  ],
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "organizationManage.ParentOrganization"
                            ),
                            prop: "parentId",
                          },
                        },
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false",
                              },
                            ],
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.orgForm.parentId,
                              callback: function ($$v) {
                                _vm.$set(_vm.orgForm, "parentId", $$v)
                              },
                              expression: "orgForm.parentId",
                            },
                          }),
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.organizationOpt.parentName,
                              callback: function ($$v) {
                                _vm.$set(_vm.organizationOpt, "parentName", $$v)
                              },
                              expression: "organizationOpt.parentName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "organizationManage.OrganizationName"
                            ),
                            prop: "name",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { autocomplete: "off" },
                            model: {
                              value: _vm.orgForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.orgForm, "name", $$v)
                              },
                              expression: "orgForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("organizationManage.sort"),
                            rules: [
                              {
                                required: true,
                                message: _vm.$t("roleManage.sortNotEmpty"),
                              },
                              {
                                type: "number",
                                message: _vm.$t("roleManage.sortIsNumber"),
                              },
                            ],
                            prop: "sort",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "sort",
                              autocomplete: "off",
                              placeholder: _vm.$t(
                                "roleManage.littleValueFront"
                              ),
                            },
                            model: {
                              value: _vm.orgForm.sort,
                              callback: function ($$v) {
                                _vm.$set(_vm.orgForm, "sort", _vm._n($$v))
                              },
                              expression: "orgForm.sort",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("organizationManage.note") } },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea", autocomplete: "off" },
                            model: {
                              value: _vm.orgForm.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.orgForm, "remark", $$v)
                              },
                              expression: "orgForm.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { size: "large" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.onSubmit },
                            },
                            [_vm._v(_vm._s(_vm.$t("roleManage.confirm")))]
                          ),
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.organizationOpt.visible = false
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("roleManage.cancel")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }