<template>
  <div>
    <div v-if="noRouteAssociated" class="headNoRoute">{{ $t('GlobalSetObj.thereAre') }}
      <span style="color: #f00">{{ noRouteAssociated }}</span>
      {{ $t('GlobalSetObj.deliveryOutlets') }}
    </div>
    <div class="tabClass">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :key="0" :label="$t('GlobalSetObj.route')" name="route">
          <route-data v-if="isShow" @siteTotal="getSitePageList" />
        </el-tab-pane>
        <el-tab-pane :key="1" :label="$t('GlobalSetObj.Outlets')" name="outlets">
          <outlets-data v-if="isShow1" @siteTotal="getSitePageList" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { getSiteInfo } from '@/api/routerPlan';

export default {
  name: 'RoutePlan',
  components: {
    'routeData': () => import('./com/route'),
    'outletsData': () => import('./com/outlets')
  },
  data() {
    return {
      activeName: 'route',
      noRouteAssociated: 0,
      isShow: true,
      isShow1: false
    };
  },
  created() {
    this.getSitePageList();
  },
  methods: {
    // 未关联站点数量
    siteTotal(data) {
      this.noRouteAssociated = data;
    },
    handleClick(tab, event) {
      if (tab.name === 'route') {
        this.isShow1 = false;
        this.isShow = true;
      }
      if (tab.name === 'outlets') {
        this.isShow = false;
        this.isShow1 = true;
      }
    },
    // 获取未关联路线站点
    getSitePageList() {
      const param = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          name: '',
          zipCode: ''
        }
      };
      getSiteInfo(param).then(res => {
        if (res.status === 'OK') {
          this.noRouteAssociated = res.data.total;
        }
      });
    }
  }
};
</script>

<style scoped>
.headNoRoute {
  background: #F4E6DE;
  height: 35px;
  line-height: 35px;
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 5px;
  font-size: 14px;
}

.tabClass {
  padding: 0 10px 10px 10px;
}
</style>
