import { render, staticRenderFns } from "./senderAddress.vue?vue&type=template&id=27ad40da&scoped=true"
import script from "./senderAddress.vue?vue&type=script&lang=js"
export * from "./senderAddress.vue?vue&type=script&lang=js"
import style0 from "./senderAddress.vue?vue&type=style&index=0&id=27ad40da&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27ad40da",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/work/jenkins/workspace/pre预发布-anjun-express-web-management/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('27ad40da')) {
      api.createRecord('27ad40da', component.options)
    } else {
      api.reload('27ad40da', component.options)
    }
    module.hot.accept("./senderAddress.vue?vue&type=template&id=27ad40da&scoped=true", function () {
      api.rerender('27ad40da', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/customerManagements/CustomerList/cmp/senderAddress.vue"
export default component.exports