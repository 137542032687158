var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detialInfo" },
    [
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.activeNames,
            callback: function ($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames",
          },
        },
        [
          _c(
            "el-collapse-item",
            {
              attrs: {
                title: _vm.$t("newOrder.BasicInformationWaybill"),
                name: "1",
              },
            },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c("basicInfo", {
                    attrs: { "table-infomation": _vm.tableInfomation },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-collapse-item",
            {
              attrs: { title: _vm.$t("GlobalSetObj.qinashouInfo"), name: "2" },
            },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c("signeInfo", {
                    attrs: { "table-infomation": _vm.tableInfomation },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-collapse-item",
            {
              attrs: {
                title: _vm.$t("newOrder.InvoiceInformation"),
                name: "4",
              },
            },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c("child-order-invoice-info", {
                    attrs: {
                      "query-track-param": _vm.queryTrackParam,
                      "waybill-invoice-v-o-list": _vm.waybillInvoiceVOList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-collapse-item",
            {
              attrs: {
                title: _vm.$t("orderCenterCont.RecipientInformation"),
                name: "5",
              },
            },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c("RecipientsInfo", {
                    attrs: {
                      "encryption-receiver-info": _vm.encryptionReceiverInfo,
                      "receiver-info": _vm.tableInfomation.receiverInfo,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-collapse-item",
            {
              attrs: {
                title: _vm.$t("orderCenterCont.ProductInformation"),
                name: "6",
              },
            },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c("GoodsInfo", {
                    attrs: { "goods-table-data": _vm.goodsTableData },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-collapse-item",
            {
              attrs: {
                title: _vm.$t("orderCenterCont.SenderInformation"),
                name: "7",
              },
            },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c("senderInfo", {
                    attrs: {
                      "encryption-sender-info": _vm.encryptionSenderInfo,
                      "sender-info": _vm.tableInfomation.senderInfo,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }