var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ElSelectV2", {
    attrs: {
      loading: _vm.loading,
      clearable: "",
      options: _vm.list,
      filterable: "",
      placeholder: _vm.$t("operationCenter.PleaseSelect"),
    },
    on: { change: _vm.onChange },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v
      },
      expression: "value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }