<template>
  <div class="container">
    <div class="searchBox">
      <el-form
        ref="searchForm"
        v-model="queryForm"
        :inline="false"
        label-position="top"
      >
        <el-row :gutter="50">
          <el-col :span="6">
            <!--母单状态-->
            <el-form-item :label="$t('navbar.cargoOrderStatus')">
              <el-select
                v-model="queryForm.params.cargoOrderStatus"
                :placeholder="$t('GlobalSetObj.all')"
                clearable
                filterable
                multiple
                size="small"
                style="width: 100%;"
              >
                <el-option
                  v-for="(item) in waybillStatusList"
                  :key="item.code"
                  :label="item.label"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--客户-->
            <el-form-item :label="$t('newOrder.CustomerName')">
              <el-select
                v-model="queryForm.params.customerId"
                :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
                :remote-method="getCustomerPage"
                clearable
                filterable
                remote
                reserve-keyword
                size="small"
                style="width: 100%;"
                @focus="getCustomerPage('')"
              >
                <el-option
                  v-for="(item) in customerList"
                  :key="item.id"
                  :label="item.customerAlias + '(' + item.username + ')'"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--发件人所属州-->
            <el-form-item :label="$t('navbar.senderState')">
              <el-select
                v-model="queryForm.params.senderState"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                filterable
                size="small"
                style="width: 100%;"
              >
                <el-option
                  v-for="item in StateList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.label"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--收件人所属州-->
            <el-form-item :label="$t('navbar.receiveState')">
              <el-select
                v-model="queryForm.params.receiveState"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                filterable
                size="small"
                style="width: 100%;"
              >
                <el-option
                  v-for="item in StateList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.label"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--揽收网点-->
            <el-form-item :label="$t('collectionCenter.LanShouSite')">
              <el-select
                v-model="queryForm.params.collectStationId"
                :placeholder="$t('GlobalSetObj.OnlyAreDisplayed')"
                :remote-method="remoteGetSiteList"
                clearable
                filterable
                remote
                reserve-keyword
                size="small"
                style="width: 100%;"
                @focus="remoteGetSiteList('')"
              >
                <el-option
                  v-for="item in siteList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--预派送网点-->
            <el-form-item :label="$t('GlobalSetObj.preEntrega')">
              <el-select
                v-model="queryForm.params.preDeliverStationId"
                :placeholder="$t('GlobalSetObj.OnlyAreDisplayed')"
                :remote-method="remoteGetSiteList"
                clearable
                filterable
                remote
                reserve-keyword
                size="small"
                style="width: 100%;"
                @focus="remoteGetSiteList('')"
              >
                <el-option
                  v-for="item in siteList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--实际派送网点-->
            <el-form-item :label="$t('GlobalSetObj.entrega')">
              <el-select
                v-model="queryForm.params.deliverStationId"
                :placeholder="$t('GlobalSetObj.OnlyAreDisplayed')"
                :remote-method="remoteGetSiteList"
                clearable
                filterable
                remote
                reserve-keyword
                size="small"
                style="width: 100%;"
                @focus="remoteGetSiteList('')"
              >
                <el-option
                  v-for="item in siteList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--创建时间-->
            <el-form-item prop="searchTimeType">
              <template slot="label">
                <el-select
                  v-model="queryForm.params.searchTimeType"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  :style="IsWidthTime"
                  class="selectClass"
                  size="small"
                >
                  <!--创建时间-->
                  <el-option key="1" :label="$t('GlobalSetObj.TimeOfOrder')" value="1" />
                </el-select>
              </template>
              <el-date-picker
                v-model="timeValue"
                :default-time="['00:00:00', '23:59:59']"
                :end-placeholder="$t('operationCenter.endDate')"
                :format="$i18n.locale==='zh'?zhDate:ptDate"
                :start-placeholder="$t('operationCenter.startDate')"
                range-separator="~"
                size="small"
                style="width: 100%"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <template slot="label">
                <el-select
                  v-model="queryForm.params.searchNumberType"
                  :placeholder="$t('orderDetails.pleaseChoose')"
                  :style="IsWidth"
                  class="selectClass"
                  size="small"
                >
                  <!--安骏订单号-->
                  <el-option key="1" :label="$t('navbar.anjunOrderNumber')" value="1" />
                </el-select>
              </template>
              <div style="width: 100%;margin-top: 5px">
                <el-input
                  v-model="queryForm.params.searchNumberStr"
                  :placeholder="$t('newOrder.PreciseSearch2')"
                  :rows="2"
                  size="small"
                  type="textarea"
                />
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <div style="margin-top: 35px">
              <!--搜索-->
              <el-button size="small" type="primary" @click="enterGetSearch">{{ $t('newOrder.search') }}</el-button>
              <!--重置-->
              <el-button
                size="small" style="margin-left: 20px" type="info"
                @click="resetData"
              >{{ $t('newOrder.reset') }}
              </el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div style="margin-bottom: 10px;display: flex;justify-content: flex-end;">
      <!--勾选导出-->
      <!--      <el-button size="small" type="success" @click="allExportOrder">{{ "勾选导出" }}</el-button>-->
      <!--全部导出-->
      <el-button size="small" type="success" @click="allExportOrder">{{ $t('collectionCenter.allExport') }}</el-button>
    </div>
    <el-table
      :data="tableData"
      :header-cell-style="{background:'#f2f2f2',color:'black'}"
      border
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="55"
      />
      <el-table-column
        :label="$t('GlobalSetObj.serial')" align="center" type="index"
        width="80"
      />
      <el-table-column
        v-for="item in tableHeaderList"
        :key="item.id"
        :align="item.center"
        :label="item.label"
        :min-width="item.cloWidth"
        :prop="item.prop"
        :show-overflow-tooltip="false"
      >
        <template slot-scope="scope">
          <div v-if="item.prop === 'orderNumber'">
            <div class="textOrder" @click="toDetail(scope.row)">{{ scope.row.orderNumber }}</div>
          </div>
          <div v-else>{{ scope.row[item.prop] ? `${scope.row[item.prop]}` : '--' }}</div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('newOrder.operation')"
        align="center"
        fixed="right"
        width="100"
      >
        <template slot-scope="scope">
          <div style="display: flex;justify-content: center;align-items: center;">
            <el-tooltip
              :content="$t('GlobalSetObj.details')" class="item" effect="dark"
              placement="top"
            >
              <i class="iconfont icon-chakan textOperator" @click="toDetail(scope.row)" />
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="queryForm.pageNumber"
      :page-size.sync="queryForm.pageSize"
      :page-sizes="[10, 50, 100, 200, 400]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 20px 0; text-align: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!--大件订单详情-->
    <el-drawer
      :append-to-body="true"
      :visible.sync="detailDialog"
      custom-class="drawerClass"
      direction="ltr"
      size="80%"
      @close="handledetailDialog"
    >
      <template #title>
        <div>
          <span style="margin-right: 20px">{{ $t('collectionCenter.largeOrderDetails') }}</span>
          <span style="margin-right: 10px;"> {{ `${detailDialogObj.orderNumber}` }}</span>
          <span>({{ $t('navbar.gong') }} {{ detailDialogObj.totalNumber }} {{ $t('navbar.jian') }})</span>
        </div>
      </template>
      <!-- 大件详情   -->
      <Detail :key="keyChange" ref="Detail" :grandson="DetailId" />
    </el-drawer>
  </div>
</template>

<script>
import { apiCustomerPage } from '@/api/customer';
import { siteFuzzyQuery } from '@/api/lanshou';
import { headerList, waybillStatusList } from './tableHeadListData/index';
import dayjs from 'dayjs';
import { getLgAreaListData } from '@/api/orderApi';
import { cargoExportAll, getServiceList } from '@/api/bulkExpressApi';
import { waybillStatusObj } from '@/utils/enumData';

import Detail from './component/detail';

export default {
  name: 'LargeOrderManagement',
  components: {
    Detail
  },
  data() {
    return {
      StateList: [],
      // 是否格式化成巴西时间
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      isTormat: false,
      timeValue: [],
      tableHeaderList: headerList,
      tableData: [],
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          cargoOrderStatus: [],
          senderState: null,
          receiveState: null,
          collectStationId: null,
          preDeliverStationId: null,
          deliverStationId: null,
          searchTimeType: '1',
          searchNumberType: '1',
          customerId: '',
          searchNumberStr: ''
        }
      },
      // 勾选表格数据
      selectionList: [],
      customerList: [],
      waybillStatusList: waybillStatusList,
      DetailId: 0,
      detailDialog: false,
      siteList: [],
      detailDialogObj: {},
      keyChange: ''
    };
  },
  computed: {
    IsWidth() {
      return {
        width: this.isTormat ? '250px' : '130px'
      };
    },
    IsWidthTime() {
      return {
        width: this.isTormat ? '200px' : '150px'
      };
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getTimer();
    this.getSearch();
  },
  mounted() {
    // 获取客户列表
    // this.getCustomerPage();
    this.getState();
  },
  methods: {
    // 勾选
    handleSelectionChange(data) {
      this.selectionList = data;
    },
    // 搜索
    getSearch() {
      const data = {
        pageNumber: this.queryForm.pageNumber,
        pageSize: this.queryForm.pageSize,
        params: {
          cargoOrderStatus: this.queryForm.params.cargoOrderStatus || [],
          senderState: this.queryForm.params.senderState || null,
          customerId: this.queryForm.params.customerId || null,
          receiveState: this.queryForm.params.receiveState || null,
          collectStationId: this.queryForm.params.collectStationId || null,
          preDeliverStationId: this.queryForm.params.preDeliverStationId || null,
          deliverStationId: this.queryForm.params.deliverStationId || null,
          createBeginTime: '',
          createEndTime: ''
        }
      };
      if (this.queryForm.params.searchNumberStr) {
        if (this.queryForm.params.searchNumberType === '1') {
          data.params.orderNumberList = this.queryForm.params.searchNumberStr.split('\n');
        } else {
          data.params.providerNumberList = this.queryForm.params.searchNumberStr.split('\n');
        }
      }
      if (this.timeValue && this.timeValue.length > 0) {
        data.params.createBeginTime = this.timeValue[0];
        data.params.createEndTime = this.timeValue[1];
      } else {
        if (!this.queryForm.params.searchNumberStr) {
          this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
          return;
        }
      }
      getServiceList(data).then(res => {
        if (res.status === 'OK') {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
          this.tableData.forEach(item => {
            try {
              item['waybillStatusName'] = waybillStatusObj[item.waybillStatus].label;
            } catch (e) {
              item['waybillStatusName'] = '--';
            }
          });
        }
      });
    },
    handledetailDialog() {
      this.detailDialog = false;
    },
    toDetail(row) {
      console.log(row.id, 'toDetail');
      this.DetailId = row.id;
      this.keyChange = new Date().getTime();
      this.detailDialogObj = {
        totalNumber: row.totalNumber,
        orderNumber: row.orderNumber
      };
      this.detailDialog = true;
    },
    // 获取客户选项
    getCustomerPage(name = '') {
      const param = {
        pageSize: 10,
        pageNumber: 1,
        params: {
          quickSearch: name
        }
      };
      apiCustomerPage(param).then((res) => {
        if (res.status === 'OK') {
          this.customerList = res.data.records;
        }
      });
    },
    // 全部导出
    allExportOrder() {
      const data = {
        cargoOrderStatus: this.queryForm.params.cargoOrderStatus || [],
        senderState: this.queryForm.params.senderState || null,
        customerId: this.queryForm.params.customerId || null,
        receiveState: this.queryForm.params.receiveState || null,
        collectStationId: this.queryForm.params.collectStationId || null,
        preDeliverStationId: this.queryForm.params.preDeliverStationId || null,
        deliverStationId: this.queryForm.params.deliverStationId || null,
        createBeginTime: '',
        createEndTime: ''
      };
      if (this.queryForm.params.searchNumberStr) {
        if (this.queryForm.params.searchNumberType === '1') {
          data.orderNumberList = this.queryForm.params.searchNumberStr.split('\n');
        } else {
          data.providerNumberList = this.queryForm.params.searchNumberStr.split('\n');
        }
      }
      if (this.timeValue && this.timeValue.length > 0) {
        data.createBeginTime = this.timeValue[0];
        data.createEndTime = this.timeValue[1];
      } else {
        if (!this.queryForm.params.searchNumberStr) {
          this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
          return;
        }
      }
      cargoExportAll(data).then((res) => {
        if (res.status === 'OK') {
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          })
            .then((action) => {
              if (action === 'confirm') {
                if (res.data.taskId) {
                  this.$router.push({ path: '/resource/exportTaskList' });
                } else {
                  this.$router.push({ path: '/resource/exportList' });
                }
              }
            })
            .catch(() => {
            });
        }
      });
    },
    // 分页
    handleSizeChange(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    },
    // 州
    getState() { // level 0 州    1 城市   2 地区
      const params = {
        level: 0,
        parentId: ''
      };
      getLgAreaListData(params, false).then((res) => {
        if (res.status === 'OK') {
          this.StateList = res.data.map(item => {
            return {
              id: item.id,
              label: `${item.nameEn}`
            };
          });
        }
      });
    },
    // 获取最近一星期时间
    getTimer() {
      // const day = new Date();
      // const brazilTime = day.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
      const today = dayjs().format('YYYY-MM-DD');
      const oneWeekAgo = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
      this.timeValue = [oneWeekAgo + ' 00:00:00', oneWeekAgo + ' 23:59:59'];
    },
    remoteGetSiteList(val) {
      const value = val || '';
      siteFuzzyQuery(value).then(res => {
        if (res.status === 'OK') {
          this.siteList = res.data;
        }
      });
    },
    resetData() {
      // 使用默认值重置 queryForm
      const defaultParams = {
        cargoOrderStatus: [],
        senderState: null,
        receiveState: null,
        collectStationId: null,
        preDeliverStationId: null,
        deliverStationId: null,
        searchTimeType: '1',
        searchNumberType: '1',
        customerId: '',
        searchNumberStr: ''
      };
      this.getTimer();
      this.queryForm = {
        pageSize: 10,
        pageNumber: 1,
        params: {
          ...defaultParams
        }
      };
      this.getSearch();
    },
    enterGetSearch() {
      this.queryForm.pageNumber = 1;
      this.getSearch();
    }
  }
};
</script>

<style scoped>
.container {
  padding: 10px;
}

.searchBox >>> .selectClass .el-input__inner {
  font-weight: 700 !important;
}

.textOperator {
  font-size: 20px;
  color: #409EFF;
  cursor: pointer;
}

.textOrder {
  color: #409EFF;
  cursor: pointer;
}
</style>
