var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-form",
            {
              ref: "queryForm",
              staticClass: "queryFormClass",
              attrs: {
                model: _vm.queryForm,
                "label-position": "top",
                size: "small",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.TheNameOfTheDevice"),
                            prop: "deviceName",
                          },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.pleaseChoose"
                                    ),
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.queryForm.params.selectionType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm.params,
                                        "selectionType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "queryForm.params.selectionType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: "1",
                                    attrs: {
                                      label: _vm.$t(
                                        "GlobalSetObj.TheNameOfTheDevice"
                                      ),
                                      value: 1,
                                    },
                                  }),
                                  _c("el-option", {
                                    key: "2",
                                    attrs: {
                                      label: _vm.$t(
                                        "GlobalSetObj.DeviceNumber"
                                      ),
                                      value: 2,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("orderDetails.pleaseInput"),
                              clearable: "",
                            },
                            model: {
                              value: _vm.queryForm.params.selectionCont,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm.params,
                                  "selectionCont",
                                  $$v
                                )
                              },
                              expression: "queryForm.params.selectionCont",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "IP", prop: "deviceIp" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("orderDetails.pleaseInput"),
                              clearable: "",
                            },
                            model: {
                              value: _vm.queryForm.params.deviceIp,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryForm.params, "deviceIp", $$v)
                              },
                              expression: "queryForm.params.deviceIp",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "GlobalSetObj.TheTransitWarehouseWhereLocated"
                            ),
                            prop: "transitSiteId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                              },
                              model: {
                                value: _vm.queryForm.params.transitSiteId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "transitSiteId",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.transitSiteId",
                              },
                            },
                            [
                              _c("el-option", {
                                key: "01",
                                attrs: {
                                  label: _vm.$t("collectionCenter.tudo"),
                                  value: null,
                                },
                              }),
                              _vm._l(_vm.transitOption, function (item, ind) {
                                return _c("el-option", {
                                  key: ind,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.DeviceStatus"),
                            prop: "deviceStatus",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                              },
                              model: {
                                value: _vm.queryForm.params.deviceStatus,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "deviceStatus",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.deviceStatus",
                              },
                            },
                            [
                              _c("el-option", {
                                key: "0",
                                attrs: {
                                  label: _vm.$t("collectionCenter.tudo"),
                                  value: 0,
                                },
                              }),
                              _c("el-option", {
                                key: "1",
                                attrs: {
                                  label: _vm.$t("GlobalSetObj.enable"),
                                  value: 1,
                                },
                              }),
                              _c("el-option", {
                                key: "2",
                                attrs: {
                                  label: _vm.$t("GlobalSetObj.stop"),
                                  value: 2,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { justify: "start", type: "flex" } },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          icon: "el-icon-search",
                          size: "mini",
                          type: "primary",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getDataSearch(1)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("basicData.Search")) + " ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { icon: "el-icon-refresh", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.getDataSearch(2)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("basicData.reset")) + " ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:automatic-sorting:deviceManagement:addEdit",
                      expression:
                        "'btn:automatic-sorting:deviceManagement:addEdit'",
                      arg: "remove",
                    },
                  ],
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.addEquipment(1)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.AddNewDevice")) + " ")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.tableData, border: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("basicData.serialNumber"),
                  align: "center",
                  type: "index",
                  width: "60",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.queryForm.pageNumber - 1) *
                                _vm.queryForm.pageSize +
                                scope.$index +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._l(_vm.columns, function (item, ind) {
                return _c("el-table-column", {
                  key: ind,
                  attrs: {
                    align: item.align,
                    label: item.label,
                    "min-width": item.width,
                    prop: item.prop,
                    "show-overflow-tooltip": true,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            item.prop === "deviceName"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "top-end",
                                          "popper-class": "copy",
                                          trigger: "hover",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.copy(
                                                  scope.row.deviceName
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("orderDetails.copy")
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "#409eff",
                                              cursor: "pointer",
                                            },
                                            attrs: { slot: "reference" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addEquipment(
                                                  2,
                                                  scope.row
                                                )
                                              },
                                            },
                                            slot: "reference",
                                          },
                                          [_vm._v(_vm._s(scope.row.deviceName))]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : item.prop === "deviceStatus"
                              ? _c(
                                  "div",
                                  [
                                    scope.row.deviceStatus === 1
                                      ? _c(
                                          "el-tag",
                                          { attrs: { type: "success" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("GlobalSetObj.enable")
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    scope.row.deviceStatus === 2
                                      ? _c(
                                          "el-tag",
                                          { attrs: { type: "danger" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("GlobalSetObj.stop")
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _c("div", [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row[item.prop])),
                                  ]),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("basicData.operate"),
                  align: "center",
                  fixed: "right",
                  width: "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  content: _vm.$t("GlobalSetObj.edit"),
                                  effect: "dark",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permit",
                                        rawName: "v-permit:remove",
                                        value:
                                          "btn:automatic-sorting:deviceManagement:addEdit",
                                        expression:
                                          "'btn:automatic-sorting:deviceManagement:addEdit'",
                                        arg: "remove",
                                      },
                                    ],
                                    staticStyle: { color: "#666" },
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addEquipment(2, scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-edit-outline",
                                      staticStyle: { "font-size": "25px" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                staticStyle: { margin: "10px 0" },
                attrs: {
                  "current-page": _vm.queryForm.pageNumber,
                  "page-size": _vm.queryForm.pageSize,
                  "page-sizes": [10, 20, 50, 100],
                  total: _vm.total,
                  background: "",
                  layout: "total, sizes, prev, pager, next, jumper",
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageNumber", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageNumber", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageSize", $event)
                  },
                  "current-change": _vm.getCurrentData,
                  "size-change": _vm.getSizeData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.addEditTitle,
            visible: _vm.showAddEdit,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAddEdit = $event
            },
            close: function ($event) {
              return _vm.closeAddEdit()
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "paramForm",
                  attrs: {
                    model: _vm.paramForm,
                    "label-position": "top",
                    size: "small",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.TheNameOfTheDevice"),
                        rules: {
                          required: true,
                          message: _vm.$t("orderCenterCont.CannotBeEmpty"),
                          trigger: ["blur", "change"],
                        },
                        prop: "deviceName",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "100", "show-word-limit": "" },
                        model: {
                          value: _vm.paramForm.deviceName,
                          callback: function ($$v) {
                            _vm.$set(_vm.paramForm, "deviceName", $$v)
                          },
                          expression: "paramForm.deviceName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.DeviceNumber"),
                        rules: {
                          required: true,
                          message: _vm.$t("orderCenterCont.CannotBeEmpty"),
                          trigger: ["blur", "change"],
                        },
                        prop: "deviceNumber",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: _vm.isDisable,
                          maxlength: "100",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.paramForm.deviceNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.paramForm, "deviceNumber", $$v)
                          },
                          expression: "paramForm.deviceNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        rules: {
                          required: true,
                          message: _vm.$t("orderCenterCont.CannotBeEmpty"),
                          trigger: ["blur", "change"],
                        },
                        label: "IP",
                        prop: "deviceIp",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "100", "show-word-limit": "" },
                        model: {
                          value: _vm.paramForm.deviceIp,
                          callback: function ($$v) {
                            _vm.$set(_vm.paramForm, "deviceIp", $$v)
                          },
                          expression: "paramForm.deviceIp",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t(
                          "GlobalSetObj.TheTransitWarehouseWhereLocated"
                        ),
                        rules: {
                          required: true,
                          message: _vm.$t("orderCenterCont.CannotBeEmpty"),
                          trigger: ["blur", "change"],
                        },
                        prop: "transitSiteId",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.changeTransitSite(
                                _vm.paramForm.transitSiteId
                              )
                            },
                          },
                          model: {
                            value: _vm.paramForm.transitSiteId,
                            callback: function ($$v) {
                              _vm.$set(_vm.paramForm, "transitSiteId", $$v)
                            },
                            expression: "paramForm.transitSiteId",
                          },
                        },
                        _vm._l(_vm.transitOption, function (item, ind) {
                          return _c("el-option", {
                            key: ind,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.addEditType === 2
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.RuleName"),
                            prop: "ruleId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.paramForm.ruleId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.paramForm, "ruleId", $$v)
                                },
                                expression: "paramForm.ruleId",
                              },
                            },
                            _vm._l(_vm.ruleNameList, function (item, ind) {
                              return _c("el-option", {
                                key: ind,
                                attrs: { label: item.ruleName, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.DeviceStatus"),
                        prop: "deviceStatus",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "small" },
                          model: {
                            value: _vm.paramForm.deviceStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.paramForm, "deviceStatus", $$v)
                            },
                            expression: "paramForm.deviceStatus",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: 1 } }, [
                            _vm._v(_vm._s(_vm.$t("GlobalSetObj.enable"))),
                          ]),
                          _c("el-radio-button", { attrs: { label: 2 } }, [
                            _vm._v(_vm._s(_vm.$t("GlobalSetObj.stop"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: "btn:automatic-sorting:deviceManagement:addEdit",
                  expression:
                    "'btn:automatic-sorting:deviceManagement:addEdit'",
                  arg: "remove",
                },
              ],
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.closeAddEdit()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.saveAddEdit()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Salve")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }