<script >
import { ref, defineProps, watch } from '@vue/composition-api';
import { useQueryClient } from '@tanstack/vue-query';
import { useVuelidate } from '@vuelidate/core';
import dayjs from 'dayjs';
import { required } from '@/utils/validationErrors';
import getMessageError from '@/utils/getMessageError';
import { uploadFileService } from '@/api/reverse/uploadFile';
import { toast } from '@/utils/programaticToast';
import { getToastContentComponent } from '@/utils/getToastContentComponent';
import { addingLoading, closeLoading } from '@/utils/handleControlLoading';
export async function checkFields() {
  return await v$.value.$validate();
}
const __sfc_main = {};
__sfc_main.props = {
  closeModal: {
    type: Function
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const queryClient = useQueryClient();
  const props = __props;
  function generateLote() {
    const currentDate = new Date();
    return `${dayjs(currentDate).format('DDMMYYYYHHmmss')}L`;
  }
  const formData = ref({
    codEdition: null,
    numLote: generateLote(),
    dateImport: dayjs(new Date()).format('DD/MM/YYYY'),
    dropFile: null
  });
  const rules = {
    codEdition: {
      required
    }
  };
  const v$ = useVuelidate(rules, formData);
  watch(formData.value, newForm => {
    console.log(newForm);
  });
  async function handleSubmit() {
    const isValid = await v$.value.$validate();
    if (isValid) {
      try {
        addingLoading();
        const form = new FormData();
        form.append('file', formData.value.dropFile);
        await uploadFileService.create(formData.value.numLote, formData.value.codEdition, form);
        props.closeModal();
        queryClient.invalidateQueries({
          queryKey: ['uploads']
        });
        toast.open({
          variant: 'success',
          position: 'bottom',
          component: getToastContentComponent({
            title: 'Criado com sucesso',
            describe: 'Upload foi realizado com sucesso',
            typeIcon: 'success'
          }),
          closable: true,
          duration: 4000
        });
      } catch (error) {
        toast.open({
          variant: 'error',
          position: 'bottom',
          component: getToastContentComponent({
            title: 'Ocorreu um erro',
            describe: getMessageError(error),
            typeIcon: 'error'
          }),
          closable: true,
          duration: 4000
        });
      } finally {
        closeLoading();
      }
    }
  }
  function removeFile() {
    formData.value.dropFile = null;
  }
  return {
    formData,
    v$,
    handleSubmit,
    removeFile
  };
};
export default __sfc_main;
</script>

<template>
  <form class="wrapper-form" @submit.prevent="handleSubmit">
    <o-field :label="$t('uploadFile.lotNumber')" label-for="numLote">
      <o-input id="numLote" v-model="formData.numLote" disabled />
    </o-field>

    <o-field :label="$t('uploadFile.importDate')" label-for="dateImport">
      <o-input id="dateImport" v-model="formData.dateImport" disabled />
    </o-field>

    <o-field
      :label="$t('uploadFile.editionCode')"
      label-for="codEdition"
      class="col-span-2"
      :message="v$.codEdition.$errors.length ? v$.codEdition.$errors[0].$message : null"
    >
      <o-input id="codEdition" v-model="formData.codEdition" />
    </o-field>

    <o-field class="col-span-2" :label="$t('uploadFile.uploadSheet')">
      <o-upload v-model="formData.dropFile" :multiple="false" drag-drop>
        <section class="wrapper-upload">
          <p class="wrapper-icon">
            <o-icon icon="upload" size="is-large" />
          </p>
          <p class="max-w-xs text-center">{{ $t('uploadFile.dragFile') }}</p>
        </section>
      </o-upload>
    </o-field>

    <div v-if="formData.dropFile" class="wrapper-file col-span-2">
      <p>{{ formData.dropFile.name }}</p>
      <o-button variant="danger" size="sm" @click="removeFile">
        <o-icon icon="trash" />
      </o-button>
    </div>

    <div class="wapper-buttons col-span-2">
      <o-button variant="primary" size="md" native-type="submit">
        {{ $t('uploadFile.submit') }}
      </o-button>
    </div>
  </form>
</template>

<style scoped>
.wrapper-form {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem /* 16px */;
}

.wrapper-file {
  display: flex;
  justify-content: space-between;
  text-overflow: ellipsis;
  align-items: center;
  margin: 1rem 0; /* 16px 0*/
}

.wrapper-upload {
  display: grid;
  justify-content: center;
  gap: 0.5rem; /* 8px */
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.text-center {
  text-align: center;
}

.wrapper-icon {
  display: flex;
  justify-content: center;
  text-align: center;
  gap: 1rem;
}

.max-w-xs {
  max-width: 20rem; /* 320px */
}

.wapper-buttons {
  display: flex;
  justify-content: flex-end;
  padding-top: 0.5rem /* 8px */;
}
</style>
