var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "SignForTheQueryBox" }, [
    _c(
      "div",
      { staticStyle: { "margin-bottom": "20px" } },
      [
        _c(
          "el-form",
          [
            _c(
              "el-row",
              { staticClass: "listQueryTime" },
              [
                _c(
                  "el-col",
                  { staticStyle: { width: "auto" }, attrs: { span: 10 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("collectionCenter.signTime") } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            clearable: false,
                            type: "daterange",
                            "value-format": "yyyy-MM-dd",
                            "default-time": ["00:00:00", "23:59:59"],
                            "range-separator": _vm.$t("collectionCenter.to"),
                            "start-placeholder": _vm.$t(
                              "collectionCenter.StartTime"
                            ),
                            "end-placeholder": _vm.$t(
                              "collectionCenter.EndTime"
                            ),
                          },
                          on: { change: _vm.dateChange },
                          model: {
                            value: _vm.timeValue,
                            callback: function ($$v) {
                              _vm.timeValue = $$v
                            },
                            expression: "timeValue",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticStyle: { width: "auto" }, attrs: { span: 9 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("collectionCenter.bigBagNumber"),
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                clearable: "",
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseInput"
                                ),
                              },
                              model: {
                                value: _vm.queryForm.params.bagNumber,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "bagNumber",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "queryForm.params.bagNumber",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c(
              "el-col",
              { attrs: { span: 8 } },
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value: "btn:collection-center:report:signForQuery:find",
                        expression:
                          "'btn:collection-center:report:signForQuery:find'",
                        arg: "remove",
                      },
                    ],
                    attrs: {
                      type: "primary",
                      size: "mini",
                      icon: "el-icon-search",
                    },
                    on: { click: _vm.searchClick },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.search")))]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value:
                          "btn:collection-center:report:signForQuery:reset",
                        expression:
                          "'btn:collection-center:report:signForQuery:reset'",
                        arg: "remove",
                      },
                    ],
                    attrs: { icon: "el-icon-refresh", size: "mini" },
                    on: { click: _vm.resetClick },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")))]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("el-divider"),
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "el-table",
          { attrs: { border: "", data: _vm.tableDate, "max-height": 600 } },
          [
            _c("el-table-column", {
              attrs: {
                prop: "bagNumber",
                label: _vm.$t("collectionCenter.bigBagNumber"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "expectedNumber",
                label: _vm.$t("collectionCenter.ExpectedNumberOfDeliveries"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "realityNumber",
                label: _vm.$t("collectionCenter.ActualNumberOfDispatches"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "driverName",
                label: _vm.$t("collectionCenter.SendPerson"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "signerImg",
                label: _vm.$t("collectionCenter.signImage"),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "top",
                            title: _vm.$t("collectionCenter.signImage"),
                            width: "300",
                            trigger: "click",
                            content: "",
                          },
                        },
                        [
                          scope.row.signerImg
                            ? _c("img", {
                                staticStyle: { width: "26px", height: "36px" },
                                attrs: {
                                  slot: "reference",
                                  src: scope.row.signerImg,
                                  alt: "",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                                slot: "reference",
                              })
                            : _c(
                                "div",
                                {
                                  attrs: { slot: "reference" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                  slot: "reference",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("collectionCenter.Null"))
                                  ),
                                ]
                              ),
                          scope.row.signerImg
                            ? _c("img", {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: { src: scope.row.signerImg, alt: "" },
                              })
                            : _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("collectionCenter.NoContentYet")
                                  )
                                ),
                              ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "signerTime",
                label: _vm.$t("collectionCenter.signTime"),
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "right" },
      [
        _c("el-pagination", {
          staticStyle: { margin: "10px 0" },
          attrs: {
            layout: "total, sizes, prev, pager, next, jumper",
            "current-page": _vm.queryForm.pageNumber,
            "page-sizes": [10, 20, 50, 100],
            "page-size": _vm.queryForm.pageSize,
            total: _vm.total,
            background: "",
          },
          on: {
            "update:currentPage": function ($event) {
              return _vm.$set(_vm.queryForm, "pageNumber", $event)
            },
            "update:current-page": function ($event) {
              return _vm.$set(_vm.queryForm, "pageNumber", $event)
            },
            "update:pageSize": function ($event) {
              return _vm.$set(_vm.queryForm, "pageSize", $event)
            },
            "update:page-size": function ($event) {
              return _vm.$set(_vm.queryForm, "pageSize", $event)
            },
            "current-change": _vm.getDatePage,
            "size-change": _vm.getPageSize,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }