<script >
import { ref } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const trigger = ref(null);
  const dropdown = ref(null);
  function calculatePositionMenu() {
    const [trigger, _, listMenu] = dropdown.value.$el.childNodes;
    const {
      x,
      y,
      height,
      width
    } = trigger.getBoundingClientRect();
    const top = y + height;
    const left = x;
    listMenu.style.position = 'fixed';
    listMenu.style.top = `${top}px`;
    listMenu.style.left = `${left - width * 2}px`;
  }
  function handleActive(isActive) {
    if (isActive) {
      setTimeout(() => {
        document.querySelector('.app-main').style.overflow = 'hidden';
        window.addEventListener('resize', calculatePositionMenu);
        calculatePositionMenu();
      });
    } else {
      document.querySelector('.app-main').style.overflow = 'auto';
      window.removeEventListener('resize', calculatePositionMenu);
    }
  }
  return {
    trigger,
    dropdown,
    handleActive
  };
};
export default __sfc_main;
</script>

<template>
  <o-dropdown ref="dropdown" aria-role="list" class="o-container" @active-change="handleActive">
    <o-button slot="trigger" ref="trigger" variant="ghost" size="sm">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-more-horizontal"><circle cx="12" cy="12" r="1" /><circle cx="19" cy="12" r="1" /><circle cx="5" cy="12" r="1" /></svg>
    </o-button>

    <slot />

  </o-dropdown>
</template>
