var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-box" },
    [
      _c(
        "el-form",
        {
          staticClass: "selectClass",
          attrs: { model: _vm.queryForm, "label-position": "top" },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { staticStyle: { height: "70px" }, attrs: { span: 24 } },
                [
                  _c("el-form-item", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "flex-start",
                          "align-items": "center",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "selectLabel" },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "GlobalSetObj.pleaseChoose"
                                  ),
                                },
                                model: {
                                  value: _vm.searchTimeType,
                                  callback: function ($$v) {
                                    _vm.searchTimeType = $$v
                                  },
                                  expression: "searchTimeType",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: {
                                    label: _vm.$t("trajectory.ArrivalTime"),
                                    value: 1,
                                  },
                                }),
                                _c("el-option", {
                                  attrs: {
                                    label: _vm.$t(
                                      "collectionCenter.departureTime"
                                    ),
                                    value: 2,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("el-date-picker", {
                          attrs: {
                            placeholder: _vm.$t("newOrder.SelectDatetime"),
                            type: "datetimerange",
                            "range-separator": _vm.$t("collectionCenter.to"),
                            "start-placeholder": _vm.$t(
                              "collectionCenter.StartTime"
                            ),
                            "end-placeholder": _vm.$t(
                              "collectionCenter.EndTime"
                            ),
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            "default-time": ["00:00:00", "23:59:59"],
                            clearable: false,
                          },
                          model: {
                            value: _vm.queryDate,
                            callback: function ($$v) {
                              _vm.queryDate = $$v
                            },
                            expression: "queryDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { height: "70px" }, attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("collectionCenter.licensePlateNumber"),
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "searchVehicleNumbers" },
                        [
                          _c("el-input", {
                            staticStyle: { width: "70%" },
                            attrs: {
                              placeholder: _vm.$t("orderDetails.pleaseInput"),
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.searchClick($event)
                              },
                            },
                            model: {
                              value: _vm.queryForm.params.vehicleNumbers,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm.params,
                                  "vehicleNumbers",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "queryForm.params.vehicleNumbers",
                            },
                          }),
                          _c(
                            "div",
                            { staticStyle: { width: "40px" } },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "chaxunBtn",
                                  on: { click: _vm.searchClick },
                                },
                                [
                                  _c("i", {
                                    staticClass: "iconfont icon-chaxun",
                                    staticStyle: { "font-size": "24px" },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { width: "38px" } },
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "bottom-end",
                                    trigger: "manual",
                                  },
                                  model: {
                                    value: _vm.showNumList,
                                    callback: function ($$v) {
                                      _vm.showNumList = $$v
                                    },
                                    expression: "showNumList",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "collectionCenter.maxQuery1000"
                                          ),
                                          rows: 10,
                                          type: "textarea",
                                        },
                                        model: {
                                          value:
                                            _vm.queryForm.params.vehicleNumbers,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryForm.params,
                                              "vehicleNumbers",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "queryForm.params.vehicleNumbers",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { "margin-top": "10px" },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { size: "small" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.queryForm.params.vehicleNumbers =
                                                    ""
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("newOrder.empty"))
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticStyle: { float: "right" } },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { size: "small" },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.showNumList = false
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "orderExport.close"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    plain: "",
                                                    size: "small",
                                                    type: "primary",
                                                  },
                                                  on: {
                                                    click: _vm.searchClick,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("newOrder.search")
                                                    ) + " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "btnIcon",
                                      attrs: { slot: "reference" },
                                      on: {
                                        click: function ($event) {
                                          _vm.showNumList = true
                                        },
                                      },
                                      slot: "reference",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "iconfont icon-liebiao1",
                                        staticStyle: { "font-size": "24px" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { height: "70px" }, attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("collectionCenter.vehicleLocation"),
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            placeholder: _vm.$t("collectionCenter.tudo"),
                            clearable: "",
                          },
                          model: {
                            value: _vm.queryForm.params.siteId,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm.params, "siteId", $$v)
                            },
                            expression: "queryForm.params.siteId",
                          },
                        },
                        _vm._l(_vm.siteIdList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { height: "70px" }, attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("collectionCenter.operationMode"),
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t("collectionCenter.tudo"),
                            clearable: "",
                          },
                          model: {
                            value: _vm.queryForm.params.operationMode,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "operationMode",
                                $$v
                              )
                            },
                            expression: "queryForm.params.operationMode",
                          },
                        },
                        _vm._l(_vm.operationModeObj, function (item, key) {
                          return _c("el-option", {
                            key: key,
                            attrs: { label: item, value: key },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { width: "auto" }, attrs: { span: 8 } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { staticClass: "col2" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "autoWidth",
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                              },
                              on: { change: _vm.changeSite },
                              model: {
                                value: _vm.selectSite,
                                callback: function ($$v) {
                                  _vm.selectSite = $$v
                                },
                                expression: "selectSite",
                              },
                            },
                            [
                              _c("template", { slot: "prefix" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      (
                                        _vm.stList.find(function (item) {
                                          return item.value === _vm.selectSite
                                        }) || {}
                                      ).label
                                    ) +
                                    " "
                                ),
                              ]),
                              _vm._l(_vm.stList, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm.selectSite == "s1"
                        ? _c(
                            "el-col",
                            { staticStyle: { width: "auto" } },
                            [
                              _c(
                                "el-form-item",
                                { staticClass: "siteClass" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "collectionCenter.pleaseChoose"
                                        ),
                                        clearable: "",
                                        filterable: "",
                                      },
                                      on: { focus: _vm.getNewestSite },
                                      model: {
                                        value: _vm.queryForm.params.reachSite,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "reachSite",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.reachSite",
                                      },
                                    },
                                    _vm._l(
                                      _vm.dotIdList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.name,
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.selectSite == "s2"
                        ? _c(
                            "el-col",
                            { staticStyle: { width: "auto" } },
                            [
                              _c(
                                "el-form-item",
                                { staticClass: "siteClass" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "collectionCenter.pleaseChoose"
                                        ),
                                        clearable: "",
                                        filterable: "",
                                      },
                                      on: { focus: _vm.getNewestSite },
                                      model: {
                                        value: _vm.queryForm.params.leaveSite,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "leaveSite",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.leaveSite",
                                      },
                                    },
                                    _vm._l(
                                      _vm.dotIdList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.name,
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { width: "auto" }, attrs: { span: 8 } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { staticClass: "col2" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "autoWidth",
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                              },
                              on: { change: _vm.changePeople },
                              model: {
                                value: _vm.selectPeople,
                                callback: function ($$v) {
                                  _vm.selectPeople = $$v
                                },
                                expression: "selectPeople",
                              },
                            },
                            [
                              _c("template", { slot: "prefix" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      (
                                        _vm.selectPeopleList.find(function (
                                          item
                                        ) {
                                          return item.value === _vm.selectPeople
                                        }) || {}
                                      ).label
                                    ) +
                                    " "
                                ),
                              ]),
                              _vm._l(
                                _vm.selectPeopleList,
                                function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm.selectPeople == "n1"
                        ? _c(
                            "el-col",
                            { staticStyle: { width: "auto" } },
                            [
                              _c(
                                "el-form-item",
                                { staticClass: "siteClass" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        loading: _vm.loading,
                                        placeholder: _vm.$t(
                                          "collectionCenter.pleaseInput"
                                        ),
                                        "remote-method": _vm.driverNameMethod,
                                        clearable: "",
                                        filterable: "",
                                        remote: "",
                                        "reserve-keyword": "",
                                      },
                                      model: {
                                        value:
                                          _vm.queryForm.params.reachScanUserId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "reachScanUserId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.reachScanUserId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.driverNameList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.name,
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.selectPeople == "n2"
                        ? _c(
                            "el-col",
                            { staticStyle: { width: "auto" } },
                            [
                              _c(
                                "el-form-item",
                                { staticClass: "siteClass" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        loading: _vm.loading,
                                        placeholder: _vm.$t(
                                          "collectionCenter.pleaseInput"
                                        ),
                                        "remote-method": _vm.driverNameMethod,
                                        clearable: "",
                                        filterable: "",
                                        remote: "",
                                        "reserve-keyword": "",
                                      },
                                      model: {
                                        value:
                                          _vm.queryForm.params.leaveScanUserId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "leaveScanUserId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.leaveScanUserId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.driverNameList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.name,
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticStyle: { "margin-top": "10px" }, attrs: { span: 24 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:collection-center:report:signAgeing:odd-find",
                      expression:
                        "'btn:collection-center:report:signAgeing:odd-find'",
                      arg: "remove",
                    },
                  ],
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-search",
                  },
                  on: { click: _vm.searchClick },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.search")))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value:
                        "btn:collection-center:report:signAgeing:odd-reset",
                      expression:
                        "'btn:collection-center:report:signAgeing:odd-reset'",
                      arg: "remove",
                    },
                  ],
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetClick },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")))]
              ),
              _c(
                "el-button",
                {
                  class: _vm.isClickAll ? "onlyClickOnce10" : "",
                  attrs: { icon: "el-icon-upload2", size: "mini" },
                  on: { click: _vm.download },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              ref: "tableRef",
              staticStyle: { "margin-top": "20px" },
              attrs: { border: "", data: _vm.tableDate },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.ind"),
                  type: "index",
                  width: "60",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.queryForm.pageNumber - 1) *
                                _vm.queryForm.pageSize +
                                scope.$index +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "vehicleNumber",
                  label: _vm.$t("collectionCenter.licensePlateNumber"),
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "vehicleType",
                  label: _vm.$t("collectionCenter.carType"),
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.vehicleTypeObj[scope.row.vehicleType])
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "operationMode",
                  label: _vm.$t("collectionCenter.operationMode"),
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.operationModeObj[scope.row.operationMode]
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "siteName",
                  label: _vm.$t("collectionCenter.vehicleLocation"),
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reachScanTime",
                  label: _vm.$t("trajectory.ArrivalTime"),
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reachAddress",
                  label: _vm.$t("collectionCenter.arrivalLocation"),
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reachScanUser",
                  label: _vm.$t("collectionCenter.arrivalScanningPersonnel"),
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reachSiteName",
                  label: _vm.$t("collectionCenter.arrivalPoint"),
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "leaveScanTime",
                  label: _vm.$t("collectionCenter.departureTime"),
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "leaveScanUser",
                  label: _vm.$t("collectionCenter.departureScanningPersonnel"),
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "leaveSiteName",
                  label: _vm.$t("collectionCenter.exitNode"),
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "leaveAddress",
                  label: _vm.$t("collectionCenter.leaveLocation"),
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "collectTime",
                  label: _vm.$t("collectionCenter.operation"),
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.getPictureDetails(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("collectionCenter.imageDetails"))
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-pagination", {
            staticStyle: { margin: "10px 0" },
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              "current-page": _vm.queryForm.pageNumber,
              "page-sizes": [10, 20, 50, 100, 500, 1000],
              "page-size": _vm.queryForm.pageSize,
              total: _vm.total,
              background: "",
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "current-change": _vm.searchClick,
              "size-change": _vm.searchClick,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("collectionCenter.imageDetails"),
            visible: _vm.dialogVisible,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: function ($event) {
              _vm.imageDetailsType = 1
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "min-height": "300px",
                "max-height": "70vh",
                "overflow-y": "auto",
              },
            },
            [
              _c(
                "el-tabs",
                {
                  attrs: { type: "card" },
                  model: {
                    value: _vm.imageDetailsType,
                    callback: function ($$v) {
                      _vm.imageDetailsType = $$v
                    },
                    expression: "imageDetailsType",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        label: _vm.$t("collectionCenter.arrival"),
                        name: 1,
                      },
                    },
                    [
                      _vm.pictureDetails.reachImgUrls
                        ? [
                            _c(
                              "el-row",
                              { attrs: { gutter: 20 } },
                              _vm._l(
                                _vm.pictureDetails.reachImgUrls,
                                function (item, index) {
                                  return _c(
                                    "el-col",
                                    { key: index, attrs: { span: 12 } },
                                    [
                                      _c("el-image", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                        },
                                        attrs: {
                                          "preview-src-list":
                                            _vm.pictureDetails.reachImgUrls,
                                          src: item,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            ),
                          ]
                        : _c("el-empty", {
                            attrs: {
                              description: _vm.$t(
                                "collectionCenter.noImageAvailable"
                              ),
                            },
                          }),
                    ],
                    2
                  ),
                  _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        label: _vm.$t("collectionCenter.departure"),
                        name: 2,
                      },
                    },
                    [
                      _vm.pictureDetails.leaveImgUrls
                        ? [
                            _c(
                              "el-row",
                              { attrs: { gutter: 20 } },
                              _vm._l(
                                _vm.pictureDetails.leaveImgUrls,
                                function (item, index) {
                                  return _c(
                                    "el-col",
                                    { key: index, attrs: { span: 12 } },
                                    [
                                      _c("el-image", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                        },
                                        attrs: {
                                          "preview-src-list":
                                            _vm.pictureDetails.leaveImgUrls,
                                          src: item,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            ),
                          ]
                        : _c("el-empty", {
                            attrs: {
                              description: _vm.$t(
                                "collectionCenter.noImageAvailable"
                              ),
                            },
                          }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }