import request from '@/utils/request';
import { getToken } from '@/utils/auth';

/**
 * 柜子信息——分页
 */
export function cabinetPage(data) {
  return request({
    url: '/locker/getLockerInfoPage',
    method: 'post',
    data,
    headers: { 'token': getToken() }
  });
}

/**
 * 柜子信息——分页详情
 */
export function queryLockerSumDoor(data) {
  return request({
    url: '/locker/queryLockerSumDoor',
    method: 'post',
    data,
    headers: { 'token': getToken() }
  });
}

/**
 * 格子——分页
 */
export function queryDoor(data) {
  return request({
    url: '/door/page',
    method: 'post',
    data,
    headers: { 'token': getToken() }
  });
}

/**
 * 格子——所属柜子编号
 */
export function queryLocker(data) {
  return request({
    url: '/locker/getLockerNumbers',
    method: 'post',
    data,
    headers: { 'token': getToken() },
    isLoading: false // 是否显示加载中参数
  });
}

/**
 * 格子——格子编号list
 */
export function doorList(data) {
  return request({
    url: '/door/findList',
    data,
    method: 'post',
    headers: { 'token': getToken() }
  });
}

/**
 * 格子——新增
 */
export function addDoor(data) {
  return request({
    url: '/door/addBatch',
    method: 'post',
    data,
    headers: { 'token': getToken() }
  });
}

/**
 * 格子——修改
 */
export function editDoor(data) {
  return request({
    url: '/door/editBatch',
    method: 'post',
    data,
    headers: { 'token': getToken() }
  });
}

/**
 * 格子——删除
 */
export function delDoor(id, data) {
  return request({
    url: '/door/' + id,
    method: 'DELETE',
    data,
    headers: { 'token': getToken() }
  });
}

/**
 * 柜子维护——分页
 */
export function getLockerPage(data) {
  return request({
    url: '/locker/getLockerPage',
    method: 'post',
    data,
    headers: { 'token': getToken() }
  });
}

/**
 * 柜子维护——新增——生成柜子编号
 */
export function generateNumber(data) {
  return request({
    url: '/locker/addLockerNumber',
    method: 'post',
    data,
    headers: { 'token': getToken() }
  });
}

/**
 * 柜子维护——新增——删除生成的柜子编号
 */
export function delGenerateNumber(id) {
  return request({
    url: '/locker/deleteByIdNotLog/' + id,
    method: 'DELETE',
    headers: { 'token': getToken() }
  });
}

/**
 * 柜子维护——新增
 */
export function addLocker(data) {
  return request({
    url: '/locker/add',
    method: 'post',
    data,
    headers: { 'token': getToken() }
  });
}

/**
 * 柜子维护——修改
 */
export function editLocker(id, data) {
  return request({
    url: '/locker/' + id,
    method: 'PUT',
    data,
    headers: { 'token': getToken() }
  });
}

/**
 * 柜子维护——删除
 */
export function delLocker(id) {
  return request({
    url: '/locker/' + id,
    method: 'DELETE',
    headers: { 'token': getToken() }
  });
}
