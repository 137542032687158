<script >
import { defineProps } from '@vue/composition-api';
import { useVuelidate } from '@vuelidate/core';
import { FormActions, FormActionsNext, FormActionsPrevious } from '@/components/wizard-form/formStepActions';
import { required, numeric } from '@/utils/validationErrors';
import i18n from '@/lang';
const __sfc_main = {};
__sfc_main.props = {
  isLastStep: {
    type: Boolean,
    required: true
  },
  isFirstStep: {
    type: Boolean,
    required: true
  },
  handleNext: {
    type: Function,
    required: true
  },
  handlePrevious: {
    type: Function
  },
  formData: {
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const rules = {
    capacityKg: {
      numeric,
      required
    },
    capacityM3: {
      numeric,
      required
    },
    wheelsetType: {
      required
    },
    bodyType: {
      required
    },
    weight: {
      numeric,
      required
    }
  };
  const v$ = useVuelidate(rules, props.formData);
  async function handleSubmit() {
    const isValid = await v$.value.$validate();
    if (isValid) props.handleNext();
  }
  const optionsBodyType = [{
    value: 'NOT_APPLICABLE',
    label: i18n.t('vehicle.notApplicable')
  }, {
    value: 'CLOSED_CHEST',
    label: i18n.t('vehicle.closedChest')
  }, {
    value: 'OPEN',
    label: i18n.t('vehicle.open')
  }, {
    value: 'BULK',
    label: i18n.t('vehicle.bulk')
  }, {
    value: 'CONTAINER_DOOR',
    label: i18n.t('vehicle.containerDoor')
  }, {
    value: 'SIDER',
    label: i18n.t('vehicle.sider')
  }];
  const optionsWheelsetType = [{
    value: 'TRUCK',
    label: i18n.t('vehicle.truck')
  }, {
    value: 'STUMP',
    label: i18n.t('vehicle.stump')
  }, {
    value: 'MECHANICAL_HORSE',
    label: i18n.t('vehicle.mechanicalHorse')
  }, {
    value: 'VAN',
    label: i18n.t('vehicle.van')
  }, {
    value: 'UTILITY',
    label: i18n.t('vehicle.utility')
  }, {
    value: 'OTHERS',
    label: i18n.t('vehicle.others')
  }];
  return {
    v$,
    handleSubmit,
    optionsBodyType,
    optionsWheelsetType
  };
};
__sfc_main.components = Object.assign({
  FormActions,
  FormActionsPrevious,
  FormActionsNext
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>

  <form class="wrapper-form" @submit.prevent="handleSubmit">

    <o-field
      :label="$t('vehicle.kgLabel')"
      label-for="capacityKg"
      :message="v$.capacityKg.$errors.length ? v$.capacityKg.$errors[0].$message : null"
    >
      <o-input
        id="capacityKg"
        v-model="formData.capacityKg"
        :placeholder="$t('vehicle.kgPlaceholder')"
      />
    </o-field>

    <o-field
      :label="$t('vehicle.m3Label')"
      label-for="capacityM3"
      :message="v$.capacityM3.$errors.length ? v$.capacityM3.$errors[0].$message : null"
    >
      <o-input
        id="capacityM3"
        v-model="formData.capacityM3"
        :placeholder="$t('vehicle.m3Placeholder')"
      />
    </o-field>

    <o-field
      :label="$t('vehicle.wheelTypeLabel')"
      label-for="wheelsetType"
      :message="v$.wheelsetType.$errors.length ? v$.wheelsetType.$errors[0].$message : null"
    >
      <o-select id="wheelsetType" v-model="formData.wheelsetType" :placeholder="$t('vehicle.wheelTypePlaceholder')">
        <option v-for="wheelsetType in optionsWheelsetType" :key="wheelsetType.value" :value="wheelsetType.value"> {{ wheelsetType.label }} </option>
      </o-select>
    </o-field>

    <o-field
      :label="$t('vehicle.bodyTypeLabel')"
      label-for="bodyType"
      :message="v$.bodyType.$errors.length ? v$.bodyType.$errors[0].$message : null"
    >
      <o-select id="bodyType" v-model="formData.bodyType" :placeholder="$t('vehicle.bodyTypePlaceholder')">
        <option v-for="bodyType in optionsBodyType" :key="bodyType.value" :value="bodyType.value"> {{ bodyType.label }} </option>
      </o-select>
    </o-field>

    <o-field
      :label="$t('vehicle.weightLabel')"
      label-for="weight"
      class="col-span-2"
      :message="v$.weight.$errors.length ? v$.weight.$errors[0].$message : null"
    >
      <o-input
        id="weight"
        v-model="formData.weight"
        :placeholder="$t('vehicle.weightPlaceholder')"
      />
    </o-field>

    <FormActions>
      <FormActionsPrevious :is-first-step="isFirstStep" @handlePrevious="handlePrevious" />
      <FormActionsNext :is-last-step="isLastStep" />
    </FormActions>
  </form>

</template>

<style scoped>
  .wrapper-form {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem/* 16px */;
    padding-top: 0.5rem/* 8px */;
    padding-bottom: 0.5rem/* 8px */;
  }

  .col-span-2 {
    grid-column: span 2 / span 2;
  }

  .wrapper-buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 0.5rem/* 8px */;
  }
</style>
