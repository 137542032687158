<template>
  <div class="orderBox">
    <div class="searchBox">
      <!-- <el-input v-model="search" placeholder="收件人电话/邮编/单号/运单号"> -->
      <el-input v-model="search" :placeholder="$t('newOrder.enterWaybill')" clearable>
        <el-button slot="append" @click="searchClick">{{ $t('newOrder.search') }}</el-button>
      </el-input>
    </div>

    <div style="height:50px;" />
    <div class="orderBtn">
      <div class="orderBtn-l">
        <span v-if="multipleSelection===0" class="orderBtn-l-cb">{{ $t('newOrder.notChoose') }}</span>
        <span v-else class="orderBtn-l-cb">{{ $t('newOrder.selected') }} {{ multipleSelection }} {{
          $t('newOrder.strip')
        }}</span>
        <!-- 导出 -->
        <el-button class="exportBtn" icon="el-icon-upload2" @click="downloadLogistics">{{
          $t('newOrder.export')
        }}
        </el-button>
        <!-- 取消 -->
        <el-button class="cancelBtn" type="info" @click="cancel">{{ $t('newOrder.cancel') }}</el-button>
      </div>
      <div class="orderBtn-r">
        <!-- 批量导入 -->
        <!--        <el-button class="bulkImport" icon="el-icon-download" @click="bulkImport">{{-->
        <!--          $t('newOrder.bulkImport')-->
        <!--        }}-->
        <!--        </el-button>-->
        <!-- 手动下单 -->
        <el-button class="manualOrder" icon="el-icon-edit-outline" @click="handsOrder">{{
          $t('newOrder.manualOrder')
        }}
        </el-button>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      :load="getOrderListChild"
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      class="tableExpandIcon"
      lazy
      row-key="id"
      @selection-change="handleSelectionChange"
      @row-click="rowClick"
    >
      <el-table-column
        type="selection"
        width="55"
      />
      <!-- 运单号 -->
      <el-table-column
        :label="$t('newOrder.waybillNumber')"
        fixed="left"
        prop="orderNumber"
        width="250"
      />
      <!-- 收件人名称 -->
      <el-table-column
        :label="$t('newOrder.receiverName')"
        prop="receiveName"
        width="150"
      />
      <!-- 件数 -->
      <el-table-column
        :label="$t('newOrder.quantity')"
        prop="quantity"
        width="100"
      />
      <!-- 收件人城市 -->
      <el-table-column
        :label="$t('newOrder.receiveCity')"
        prop="receiveCity"
        show-overflow-tooltip
        width="230"
      />
      <!-- 客户单号 -->
      <el-table-column
        :label="$t('newOrder.customerNumber')"
        prop="customerNumber"
      />
      <!-- 渠道 -->
      <el-table-column
        :label="$t('newOrder.channel')"
        prop="providerChannelName"
        width="150"
      />
      <!-- 运单状态 -->
      <el-table-column
        :label="$t('newOrder.waybillStatus')"
        class="orderStatusStyle"
        prop="orderStatus"
        width="150"
      >
        <!--        &lt;!&ndash; '待揽收':'异常' &ndash;&gt;-->
        <!--        <div slot-scope="scope">-->
        <!--          <span v-if="scope.row.orderStatus=='toBeCollected'" style="color:#23d5b2;">{{-->
        <!--            $t('newOrder.stayLanShou')-->
        <!--          }}</span>-->
        <!--          <span v-else-if="scope.row.orderStatus=='inTransit'" style="color:#23d5b2;">{{-->
        <!--            $t('newOrder.InTransit')-->
        <!--          }}</span>&lt;!&ndash; 运输中 &ndash;&gt;-->
        <!--          <span-->
        <!--            v-else-if="scope.row.orderStatus=='signForReceipt'"-->
        <!--            style="color:#23d5b2;"-->
        <!--          >{{ $t('newOrder.haveBeenSigned') }}</span>&lt;!&ndash; 已签收 &ndash;&gt;-->
        <!-- '待揽收':'异常' -->
        <div slot-scope="scope">
          <span v-if="scope.row.orderStatus=='toBeCollected'" style="color:#23d5b2;" />
          <span v-else-if="scope.row.orderStatus=='inTransit'" style="color:#23d5b2;" /><!-- 运输中 -->
          <span
            v-else-if="scope.row.orderStatus=='signForReceipt'"
            style="color:#23d5b2;"
          /><!-- 已签收 -->
          <span v-else style="color:#23d5b2;">{{ waybillStatus[scope.row.orderStatus].label }}</span><!-- 异常 -->
        </div>
      </el-table-column>
      <!-- 创建时间 -->
      <el-table-column
        :label="$t('newOrder.createTime')"
        prop="createTime"
      >
        <template slot-scope="scope">
          <div v-if="isTormat">
            {{ scope.row.createTime ? `${dayjs(scope.row.createTime).format('DD-MM-YYYY')}` : '--' }}
          </div>
          <div v-else>{{ scope.row.createTime ? `${scope.row.createTime}` : '--' }}</div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页工具 -->
    <div class="pagination">
      <el-pagination
        :current-page.sync="queryForm.pageNumber"
        :page-size.sync="queryForm.pageSize"
        :page-sizes="[100]"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 10px 0"
        @current-change="getPlatformTableData"
        @size-change="getPlatformTableData"
      />
    </div>
    <!-- 订单详情 -->
    <el-drawer
      :direction="msgDirection"
      :title="$t('orderDetails.waybillMsg')"
      :visible.sync="orderMsg"
      :wrapper-closable="true"
      class="drawerHeader"
      size="60%"
    >
      <deTails
        :details="detailsListP"
      />
    </el-drawer>

    <!-- 批量导入 -->
    <el-drawer
      :direction="msgDirection"
      :title="$t('orderImport.orderImport')"
      :visible.sync="orderImportOpen"
      :wrapper-closable="true"
      class="drawerHeader"
    >
      <orderImport />
    </el-drawer>

  </div>
</template>

<script>
import deTails from '@/views/orderCenter/orderList/details/details';
import orderImport from '@/views/orderCenter/orderList/import';
import { getOrderDetail, getOrderList, getOrderWaybillByOrderId, orderExport } from '@/api/orderApi';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList.js';

// const ExcelJS = require('exceljs');
export default {
  name: 'OrderList',
  components: {
    deTails,
    orderImport
  },
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      today: '',
      loadUpList: [], // 导出数据
      parentId: '', // 当前点击行的父id
      tableDataChild: [], // 子运单表
      detailsListP: {}, // 订单详情
      orderMsg: false,
      orderImportOpen: false,
      msgDirection: 'rtl',
      options1: [],
      options: [
        { value: 1, label: 222 },
        { value: 2, label: 333 },
        { value: 3, label: 2434 }
      ],
      isOpenBulkTable: false, // 订单批量查询是否打开
      search: '',
      activeName: 'f1',
      multipleSelection: 0,
      checked: false, // 单独使用可以表示两种状态之间的切换 ——>  未选  已选
      tableData: [], // 订单列表
      timeRange: [],
      queryForm: {
        pageSize: 100,
        pageNumber: 1,
        params: {
          beginTime: '2022-07-25 00:00:00',
          endTime: '2022-08-30 00:00:00',
          receivePhone: '', // 收件人电话
          receiveZipcode: '', // 收件人电话
          platformCode: '', // 平台代码
          orderNumber: '', // 订单号
          logisticsNumber: '' // 物流单号
        }
      },
      total: 1
    };
  },
  created() {
    this.dayjs = dayjs;

    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    const timedate = new Date();
    const year = timedate.getFullYear();
    let mounth = timedate.getMonth() + 1;
    let day = timedate.getDate();
    // let hours = timedate.getHours();
    // let minutes = timedate.getMinutes();
    // let seconds = timedate.getSeconds();
    if (mounth > 0 && mounth < 9) {
      mounth = '0' + mounth;
    }
    if (day > 0 && day < 9) {
      day = '0' + day;
    }
    // if (hours > 0 && hours < 9) {
    //   hours = '0' + hours;
    // }
    // if (minutes > 0 && minutes < 9) {
    //   minutes = '0' + minutes;
    // }
    // if (seconds > 0 && seconds < 9) {
    //   seconds = '0' + seconds;
    // }
    // this.today = year.toString() + '-' + mounth.toString() + '-' + day.toString() + ' ' + hours.toString() + ':' + minutes.toString() + ':' + seconds.toString();
    this.today = year.toString() + '-' + mounth.toString() + '-' + day.toString() + ' ' + '23:59:59';
    this.queryForm.params.endTime = this.today;

    this.getPlatformTableData();
  },
  methods: {
    searchClick() { // 搜索运单号
      this.queryForm.pageNumber = 1;
      this.queryForm.params.orderNumber = this.search;
      this.getPlatformTableData();
    },
    // 切换tab分页
    handleClick(event) {
      this.getPlatformTableData();
    },

    rowClick(row, column, event) {
      console.log(row.id, 'row.idrow.idrow.id');
      const idStr = row.id.toString();
      const idList = idStr.split('-');
      console.log(idList, 'idList');
      if (idList.length === 1) {
        console.log(idList[0], 'idList[0]');
        this.getOrderDetail(idList[0]);
      } else {
        this.getOrderDetail(idList[1]);
      }
      this.orderMsg = true;
    },
    getOrderDetail(id) {
      console.log(id, 'ididididid');
      getOrderDetail(id).then(res => {
        if (res.status === 'OK') {
          this.detailsListP = res.data;
        }
      }).catch(err => {
        console.log(err);
      });
    },

    dialogVisible(open) {
      if (open === 1) {
        this.isOpenBulkTable = true;
      } else {
        this.isOpenBulkTable = false;
      }
    },
    // 运单列表分页
    getPlatformTableData() {
      getOrderList(this.queryForm).then(res => {
        if (res.status === 'OK') {
          this.total = res.data.total;
          this.tableData = res.data.records;
        }
      }).catch(err => {
        console.log(err);
      });
    },
    getOrderListChild(tree, treeNode, resolve) {
      this.tableDataChild = [];
      this.parentId = tree.id; // 点开详情时做分割处理
      setTimeout(() => {
        getOrderWaybillByOrderId(tree.id).then(res => {
          if (res.status === 'OK') {
            console.log(res.data, 'this.tableDataChild');
            res.data.forEach((arr, index) => {
              this.tableDataChild.push({
                // console.log('ree.id.toString()')
                id: arr.id.toString() + '-' + tree.id.toString(),
                // id: tree.id.toString(),
                orderNumber: arr.waybillNumber,
                receiveName: tree.receiveName,
                quantity: 1,
                receiveCity: tree.receiveCity,
                providerChannelName: tree.providerChannelName,
                orderStatus: arr.waybillStatus,
                createTime: tree.createTime,
                customerNumber: tree.customerNumber
              });
            });
            console.log(this.tableDataChild, 'this.resolve');
            resolve(this.tableDataChild);
          }
        }).catch(err => {
          console.log(err);
        });
      }, 1000);
    },

    handsOrder() {
      this.$router.push({ path: '/orderCenter/placeOrder' }); // 跳到下单页 需添加三级路由配置
    },
    bulkImport() {
      this.orderImportOpen = true; // 导入由路由改为了右侧抽屉
    },
    cancel() {
      this.loadUpList = [];
      this.multipleSelection = 0;
      this.checked = false;
      this.$nextTick(() => {
        this.$refs['multipleTable'].clearSelection();
      });
    },

    handleSelectionChange(val) {
      this.loadUpList = val;
      this.multipleSelection = val.length;
      if (this.multipleSelection > 0) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
    downloadLogistics() { // 导出数据传入id并跳转
      if (!this.loadUpList.length) {
        this.$message({
          message: this.$t('newOrder.chooseWaybillPlease'),
          type: 'warning'
        });
        return;
      }
      const idd = this.loadUpList.map(item => {
        return item.id;
      });
      const { id, realName } = this.$store.state.user;
      const obj = {
        operator: realName,
        operatorId: id,
        orderIdList: idd
      };
      this.orderExport(obj);
    },
    orderExport(id) {
      orderExport(id).then(res => {
        if (res.status === 'OK') {
          goExportList(this);
        }
      }).catch(err => {
        console.log(err);
      });
    }
    // // 下载物流单号替换模板
    // downloadLogistics() {
    //   const that = this;
    //   async function ddd() {
    //     const wb = new ExcelJS.Workbook();
    //     const Sheet1 = wb.addWorksheet('Sheet1');
    //     const Sheet1_data = [];
    //     Sheet1.columns = [
    //       {
    //         header: '订单号',
    //         key: 'orderNumber',
    //         width: 40
    //       },
    //       {
    //         header: '收件人名称',
    //         key: 'receiveName',
    //         width: 40
    //       },
    //       {
    //         header: '件数',
    //         key: 'quantity',
    //         width: 40
    //       },
    //       {
    //         header: '收件人城市',
    //         key: 'receiveCity',
    //         width: 40
    //       },
    //       {
    //         header: '渠道',
    //         key: 'providerChannelName',
    //         width: 40
    //       },
    //       {
    //         header: '创建时间',
    //         key: 'createTime',
    //         width: 40
    //       }
    //     ];
    //     that.loadUpList.map((item) => {
    //       const quantityS = item.quantity.toString();
    //       Sheet1_data.push({
    //         orderNumber: item.orderNumber,
    //         receiveName: item.receiveName,
    //         quantity: quantityS,
    //         receiveCity: item.receiveCity,
    //         providerChannelName: item.providerChannelName,
    //         createTime: item.createTime
    //       });
    //     });
    //     Sheet1.addRows(Sheet1_data);
    //     const buffer = await wb.xlsx.writeBuffer();
    //     const blob = new Blob([buffer], {
    //       type: 'application/octet-stream'
    //     });
    //     let url = blob;
    //     const saveName = '订单.xlsx';
    //     if (typeof url === 'object' && url instanceof Blob) {
    //       url = URL.createObjectURL(url); // 创建blob地址
    //     }
    //     const aLink = document.createElement('a');
    //     aLink.href = url;
    //     aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    //     let event;
    //     if (window.MouseEvent) event = new MouseEvent('click');
    //     else {
    //       event = document.createEvent('MouseEvents');
    //       event.initMouseEvent(
    //         'click',
    //         true,
    //         false,
    //         window,
    //         0,
    //         0,
    //         0,
    //         0,
    //         0,
    //         false,
    //         false,
    //         false,
    //         false,
    //         0,
    //         null
    //       );
    //     }
    //     aLink.dispatchEvent(event);
    //     return;
    //   }
    //   ddd();
    // }

  }
};
</script>

<style lang="scss" scoped>
.orderBox .tableExpandIcon ::v-deep .el-table .is-leaf.el-table__cell .cell {
  background: none !important;
}

.orderBox .tableExpandIcon ::v-deep td.el-table_1_column_7.el-table__cell {
  color: #1ed4b0;
}

.orderBox .tableExpandIcon ::v-deep tbody .el-table_1_column_7 .cell {
  background: #e0f6f2;
  border-radius: 3px;
  display: inline-block;
}

.orderBox .tableExpandIcon ::v-deep span.el-table__indent {
  padding-left: 11px !important;
}

.orderBox .tableExpandIcon ::v-deep .el-table__placeholder {
  display: inline-block;
  width: 0 !important;
}

.orderBox ::v-deep tr.el-table__row.el-table__row--level-1 {
  background: #fafafa;
}

.orderBox ::v-deep tbody .el-table tr {
  background-color: #f3f7fd;
}

// .tableExpandIcon ::v-deep .el-table__expand-icon--expanded.el-table__expand-icon {
//   border: 1px solid #dcdfe6;
//   border-radius: 5px 20px 20px 5px;
// }
.orderBox .drawerHeader ::v-deep .el-drawer__header {
  font-size: 23px;
  font-weight: bold;
  color: #000;
  margin-bottom: 0 !important;
}

.orderBox ::v-deep .el-drawer__open .el-drawer {
  //width: 40% !important;
  overflow-y: scroll;
}

.query {
  display: inline-block;
  border: none;
  width: 56px;
  height: 30px;
  border-radius: 2px;
  background-color: #d9d9d9;
  color: #000;
  font-size: 14px;
  position: absolute;
  right: 13px;
  bottom: 25px;
}

.orderBox ::v-deep .el-select.s1, ::v-deep .el-select.s2 {
  width: 130px;
}

.orderBox ::v-deep .el-select.s3 {
  width: 100px;
}

.orderBox ::v-deep .el-select.s4 {
  width: 215px;
}

.orderBox ::v-deep .el-select.s2 {
  width: 130px;
}

.orderBox ::v-deep .el-button--primary {
  background-color: #000;
  border: none;
}

.orderBox ::v-deep .el-select {
  margin: 0 15px 15px 0;

  input::-webkit-input-placeholder {
    color: #000 !important;
  }
}

// .orderBox ::v-deep .el-input--suffix .el-input__inner{
//   border: none;
//   color: #000;
//   background-color: #F4F6F9FF;
// }
.orderBox ::v-deep .el-form-item {
  margin-bottom: 0px !important;
}

.orderBox ::v-deep .el-textarea__inner {
  min-height: 150px !important;
  max-height: 150px !important;
  background-color: #f5f7fa !important;
}

.orderBox ::v-deep .el-input__count {
  background-color: #f5f7fa !important;
  height: 25px;
}

.searchBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  float: right;
  width: 40%;
  overflow: hidden;

  .bulkImport.search {
    margin-left: 10px;
  }
}

.manualRrder {
  border: none;
  background: #000;
  color: #fff;
}

.bulkImport {
  border: 1px solid #E5E8EDFF;
  background: #fff;
  color: #000;
}

.orderBtn-l-cb {
  margin-right: 20px;
}

.orderBox {
  padding: 20px;
}

.exportBtn {
  outline: none;
  background-color: #12D2AC;
  color: #fff;
  height: 40px;
  font-size: 14px;
  // width: 80px;
  padding: 12px 16px;
}

.cancelBtn {
  height: 38px;
  background-color: #d8d8d8;
  color: #000;
  border: none;
  outline: none;
  font-size: 14px;
}

.orderBtn {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.orderBox .elTabs ::v-deep .el-tabs__header {
  display: inline-block;
  width: 52%;
}

.orderBox ::v-deep .el-tabs__item:hover {
  color: #12D2AC !important;
}

.orderBox ::v-deep .el-tabs__item.is-active {
  color: #12D2AC !important;
}

.orderBox ::v-deep .el-tabs__active-bar {
  background-color: #12D2AC !important;
}

.orderBox ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #000 !important;
}

.orderBox ::v-deep .block .el-input__inner {
  width: 380px;
  background-color: #f4f6f9;
}

.orderBox ::v-deep .el-range-editor .el-range-input {
  background-color: #f4f6f9;
}
</style>
