var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "space-p-20 o-container" },
    [
      _c("SectionHeader", {
        staticClass: "m-b-32",
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("h1", { staticClass: "header-1" }, [
                  _vm._v(_vm._s(_vm.$t("dashboard.orderDashboard"))),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "container-charts" },
        [
          _c("ChartBarPackageHistory", { staticClass: "span-2" }),
          _c("ChartPieDataPackages"),
          _c("ChartPieDataStores"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }