var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WrapperHandleError",
    { attrs: { error: _vm.error, "is-error": _vm.isError } },
    [
      _c("SectionHeader", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("h1", { staticClass: "header-1" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("editingRegistration.editions")) + " "
                  ),
                ]),
                _c(
                  "o-button",
                  {
                    attrs: { variant: "primary", size: "md" },
                    on: { click: _vm.handleVisibilityModalCreate },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("editingRegistration.createEdition")) +
                        " "
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "filter",
            fn: function () {
              return [
                _c(
                  "o-field",
                  {
                    staticClass: "flex-1",
                    attrs: {
                      label: _vm.$t("editingRegistration.groupCode"),
                      "label-for": "codGroupSearch",
                    },
                  },
                  [
                    _c("o-input", {
                      attrs: {
                        id: "codGroupSearch",
                        placeholder: _vm.$t(
                          "editingRegistration.groupCodePlaceholder"
                        ),
                      },
                      model: {
                        value: _vm.filters.codGroup,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "codGroup", $$v)
                        },
                        expression: "filters.codGroup",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "SkeletonTable",
        { attrs: { "is-loading": _vm.isLoadingTable } },
        [
          _c(
            "o-table",
            {
              staticClass: "space-m-y-32",
              attrs: {
                data: _vm.tableData,
                "row-key": "id",
                bordered: "",
                paginated: "",
                "backend-pagination": "",
                total: _vm.tableTotal,
                "per-page": _vm.queryParams.perPage,
                "current-page": _vm.queryParams.currentPage,
                "pagination-simple": false,
              },
              on: {
                "update:currentPage": function ($event) {
                  return _vm.$set(_vm.queryParams, "currentPage", $event)
                },
                "update:current-page": function ($event) {
                  return _vm.$set(_vm.queryParams, "currentPage", $event)
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "empty",
                  fn: function () {
                    return [_vm._v(" Sem resultados ")]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("o-table-column", {
                attrs: {
                  field: "codGroup",
                  label: _vm.$t("editingRegistration.groupCode"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [_vm._v(" " + _vm._s(props.row.codGroup) + " ")]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "firstCod",
                  label: _vm.$t("editingRegistration.firstBatchCode"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [_vm._v(" " + _vm._s(props.row.firstCod) + " ")]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "firstDescription",
                  label: _vm.$t("editingRegistration.firstBatchName"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(" " + _vm._s(props.row.firstDescription) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "firstVoulume",
                  label: _vm.$t("editingRegistration.firstBatchVolume"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(" " + _vm._s(props.row.firstVoulume) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "secondCod",
                  label: _vm.$t("editingRegistration.secondBatchCode"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [_vm._v(" " + _vm._s(props.row.secondCod) + " ")]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "secondDescription",
                  label: _vm.$t("editingRegistration.secondBatchName"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(" " + _vm._s(props.row.secondDescription) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "secondVoulume",
                  label: _vm.$t("editingRegistration.secondBatchVolume"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(" " + _vm._s(props.row.secondVoulume) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "thirdCod",
                  label: _vm.$t("editingRegistration.thirdBatchCode"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [_vm._v(" " + _vm._s(props.row.thirdCod) + " ")]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "thirdDescription",
                  label: _vm.$t("editingRegistration.thirdBatchName"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(" " + _vm._s(props.row.thirdDescription) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "thirdVoulume",
                  label: _vm.$t("editingRegistration.thirdBatchVolume"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(" " + _vm._s(props.row.thirdVoulume) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "fourthCod",
                  label: _vm.$t("editingRegistration.fourthBatchCode"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [_vm._v(" " + _vm._s(props.row.fourthCod) + " ")]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "fourthDescription",
                  label: _vm.$t("editingRegistration.fourthBatchName"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(" " + _vm._s(props.row.fourthDescription) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "fourthVoulume",
                  label: _vm.$t("editingRegistration.fourthBatchVolume"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(" " + _vm._s(props.row.fourthVoulume) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: { label: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("Actions", { attrs: { row: row } })]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "o-modal",
        {
          attrs: { active: _vm.isOpenModalCreate, width: "560" },
          on: { close: _vm.handleVisibilityModalCreate },
        },
        [
          _c("div", [
            _c("h2", { staticClass: "header-2" }, [
              _vm._v(
                _vm._s(_vm.$t("editingRegistration.creatingEditionTitle"))
              ),
            ]),
            _c("p", { staticClass: "paragraph" }, [
              _vm._v(
                _vm._s(_vm.$t("editingRegistration.creatingEditionParagraph"))
              ),
            ]),
          ]),
          _c("FormCreateEdition", {
            attrs: { "close-modal": _vm.handleVisibilityModalCreate },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }