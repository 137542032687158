<template>
  <div class="statistics">
    <i class="el-icon-info icon" />
    <template v-for="item of arr">
      <p :key="item.name">{{ item.name }}: <span>{{ item.value }}</span> {{ item.suffix }}</p>
    </template>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    arr: {
      type: Array,
      default: () => []
      // 格式
      // {
      //   name: 'xxx',
      //   value: 100,
      //   suffix: ''
      // }
    }
  }

};
</script>

<style lang="scss" scoped>
.statistics {
  margin-left: 10px;
  display: flex;
  align-items: center;
  // background-color: #67c23a;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: rgba(230, 247, 255, 1);
  border: 1px solid #91d5ff;

  // p:nth-child(2) {
  //   margin-left: 10px;
  // }

  p{
    margin: 0;
    font-size: 13px;
    color: #666;
    margin-right: 5px;

    span {
      color: #D9001B;
      font-weight: bold;
      font-size: 14px;
    }
  }
}

.icon {
  // background-color: rgb(16, 142, 233);
  color: rgb(16, 142, 233);
  border-radius: 50%;
  margin-right: 10px;
}
</style>
