var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "o-tabs",
        {
          attrs: { multiline: false },
          model: {
            value: _vm.activeTabsDriver,
            callback: function ($$v) {
              _vm.activeTabsDriver = $$v
            },
            expression: "activeTabsDriver",
          },
        },
        [
          _c(
            "o-tab-item",
            { attrs: { value: 0, label: _vm.$t("routes.searchDriverTitle") } },
            [
              _c(
                "form",
                {
                  staticClass: "wrapper-form-search-driver",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.handleSubmit($event)
                    },
                  },
                },
                [
                  _c(
                    "o-field",
                    {
                      staticClass: "col-span-2",
                      attrs: {
                        message: _vm.validateSearchForm.name.$errors.length
                          ? _vm.validateSearchForm.name.$errors[0].$message
                          : null,
                        label: _vm.$t("routes.nameLabel"),
                        "label-for": "name",
                      },
                    },
                    [
                      _c(
                        "o-autocomplete",
                        {
                          attrs: {
                            id: "name",
                            placeholder: _vm.$t("routes.namePlaceholder"),
                            clearable: "",
                            expanded: "",
                            field: "driver_name",
                            data: _vm.listNames,
                          },
                          on: {
                            input: _vm.filteredNames,
                            select: _vm.selectedNames,
                          },
                          model: {
                            value: _vm.formData.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "name", $$v)
                            },
                            expression: "formData.name",
                          },
                        },
                        [
                          _c("template", { slot: "empty" }, [
                            _vm._v("Sem resultados"),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "o-field",
                    {
                      attrs: {
                        label: _vm.$t("routes.documentLabel"),
                        message: _vm.validateSearchForm.document.$errors.length
                          ? _vm.validateSearchForm.document.$errors[0].$message
                          : null,
                      },
                    },
                    [
                      _c("o-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.formData.document,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "document", $$v)
                          },
                          expression: "formData.document",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "o-field",
                    {
                      attrs: {
                        label: _vm.$t("routes.usernameLabel"),
                        message: _vm.validateSearchForm.username.$errors.length
                          ? _vm.validateSearchForm.username.$errors[0].$message
                          : null,
                      },
                    },
                    [
                      _c("o-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.formData.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "username", $$v)
                          },
                          expression: "formData.username",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "o-field",
                    {
                      staticClass: "col-span-2",
                      attrs: { label: _vm.$t("routes.helpersLabel") },
                    },
                    [
                      _c("o-inputitems", {
                        ref: "inputHelpers",
                        attrs: {
                          placeholder: _vm.$t("routes.helpersPlaceholder"),
                          data: _vm.listHelpers,
                          "allow-new": false,
                          "open-on-focus": false,
                          autocomplete: true,
                          "allow-autocomplete": true,
                        },
                        on: { typing: _vm.filteredDataUsernameHelpers },
                        scopedSlots: _vm._u([
                          {
                            key: "selected",
                            fn: function (ref) {
                              var items = ref.items
                              return _vm._l(items, function (item, index) {
                                return _c(
                                  "span",
                                  {
                                    key: index,
                                    staticClass: "item_selected",
                                    attrs: { rounded: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$refs.inputHelpers.removeItem(
                                          index,
                                          $event
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" " + _vm._s(item) + " "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "margin-left": "5px",
                                          cursor: "pointer",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$refs.inputHelpers.removeItem(
                                              index,
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "lucide lucide-x",
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              width: "16",
                                              height: "16",
                                              viewBox: "0 0 24 24",
                                              fill: "none",
                                              stroke: "currentColor",
                                              "stroke-width": "2",
                                              "stroke-linecap": "round",
                                              "stroke-linejoin": "round",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: { d: "M18 6 6 18" },
                                            }),
                                            _c("path", {
                                              attrs: { d: "m6 6 12 12" },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              })
                            },
                          },
                        ]),
                        model: {
                          value: _vm.formData.helpers,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "helpers", $$v)
                          },
                          expression: "formData.helpers",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "FormActions",
                    [
                      _c("FormActionsPrevious", {
                        attrs: { "is-first-step": _vm.isFirstStep },
                        on: { handlePrevious: _vm.handlePrevious },
                      }),
                      _c("FormActionsNext", {
                        attrs: { "is-last-step": _vm.isLastStep },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "o-tab-item",
            { attrs: { value: 1, label: _vm.$t("routes.createDriverTitle") } },
            [
              _c(
                "o-collapse",
                {
                  staticClass: "content-separator",
                  attrs: { open: _vm.isOpenCollapse == 0 },
                  on: {
                    open: function ($event) {
                      _vm.isOpenCollapse = 0
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "trigger",
                      fn: function (props) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "wrapper-tab-item",
                              attrs: {
                                role: "button",
                                "aria-controls":
                                  "contentIdForA11y5-formDataUser",
                                "aria-expanded": _vm.isOpenCollapse,
                              },
                            },
                            [
                              _c("p", { staticClass: "step-name" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("routes.dataDriver")) +
                                    " "
                                ),
                              ]),
                              _c(
                                "div",
                                [
                                  _c("o-icon", {
                                    attrs: {
                                      icon: props.open
                                        ? "caret-up"
                                        : "caret-down",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "div",
                    { staticClass: "wrapper-form-step" },
                    [
                      _c("FormPersonalData", {
                        attrs: {
                          "handle-next": _vm.nextCollapseItem,
                          "handle-previous": _vm.previousCollapseItem,
                          "is-last-step": false,
                          "is-first-step": true,
                          "form-data": _vm.formDataDriver.formStep01,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "o-collapse",
                {
                  staticClass: "content-separator",
                  attrs: { open: _vm.isOpenCollapse == 1 },
                  on: {
                    open: function ($event) {
                      _vm.isOpenCollapse = 1
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "trigger",
                      fn: function (props) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "wrapper-tab-item",
                              attrs: {
                                role: "button",
                                "aria-controls":
                                  "contentIdForA11y5-formDataAddress",
                                "aria-expanded": _vm.isOpenCollapse,
                              },
                            },
                            [
                              _c("p", { staticClass: "step-name" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("routes.dataAddress")) +
                                    " "
                                ),
                              ]),
                              _c(
                                "div",
                                [
                                  _c("o-icon", {
                                    attrs: {
                                      icon: props.open
                                        ? "caret-up"
                                        : "caret-down",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "div",
                    { staticClass: "wrapper-form-step" },
                    [
                      _c("FormAddress", {
                        attrs: {
                          "handle-next": _vm.nextCollapseItem,
                          "handle-previous": _vm.previousCollapseItem,
                          "is-last-step": true,
                          "is-first-step": false,
                          "form-data": _vm.formDataDriver.formStep02,
                        },
                        on: {
                          "update:formData": function ($event) {
                            return _vm.$set(
                              _vm.formDataDriver,
                              "formStep02",
                              $event
                            )
                          },
                          "update:form-data": function ($event) {
                            return _vm.$set(
                              _vm.formDataDriver,
                              "formStep02",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }