var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("FormVtag", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.step === 0,
            expression: "step === 0",
          },
        ],
        attrs: {
          "handle-next": _vm.handleNextStep,
          "handle-previous": _vm.handlePreviousStep,
          "is-last-step": _vm.isLastStep,
          "is-first-step": _vm.isFirstStep,
          "form-data": _vm.formData.formStepVtag,
        },
      }),
      _c("FormEletronicIdentification", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.step === 1,
            expression: "step === 1",
          },
        ],
        attrs: {
          "handle-next": _vm.handleNextStep,
          "handle-previous": _vm.handlePreviousStep,
          "is-last-step": _vm.isLastStep,
          "is-first-step": _vm.isFirstStep,
          "form-data": _vm.formData.formStepEletronicIdentification,
        },
      }),
      _c("FormSenderInformation", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.step === 2,
            expression: "step === 2",
          },
        ],
        attrs: {
          "handle-next": _vm.handleNextStep,
          "handle-previous": _vm.handlePreviousStep,
          "is-last-step": _vm.isLastStep,
          "is-first-step": _vm.isFirstStep,
          "form-data": _vm.formData.formStepSenderInformation,
        },
      }),
      _c("FormInsuranceInformation", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.step === 3,
            expression: "step===3",
          },
        ],
        attrs: {
          "handle-next": _vm.handleNextStep,
          "handle-previous": _vm.handlePreviousStep,
          "is-last-step": _vm.isLastStep,
          "is-first-step": _vm.isFirstStep,
          "form-data": _vm.formData.formStepInsuranceInformation,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }