var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isLoading
      ? _c(
          "div",
          { staticClass: "space-m-y-32" },
          _vm._l(_vm.qtdRows, function (i) {
            return _c("o-skeleton", {
              key: i,
              attrs: { animated: "", height: "50px" },
            })
          }),
          1
        )
      : _c("div", [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }