<template>
  <div class="app-main">
    <el-card style="margin-bottom: 10px;">
      <el-form :model="queryForm" size="small">
        <el-row :gutter="10">
          <el-col :span="5">
            <el-form-item>
              <el-select
                v-model="queryForm.params.searchType"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                class="searchType"
                style="width: 100%;"
              >
                <!--袋号-->
                <el-option key="1" :label="$t('GlobalSetObj.bagNumber')" value="transitBagNumbers" />
                <!--总单号-->
                <el-option key="2" :label="$t('GlobalSetObj.totalSingNumber')" value="instoreBigBagNos" />
              </el-select>
              <el-input
                v-model="queryForm.params.transitBagNumbers"
                :placeholder="$t('operatingPlatform.please200')"
                rows="5"
                style="width: 100%"
                type="textarea"
              />
            </el-form-item>
          </el-col>
          <!--        服务商-->
          <el-col :span="6">
            <el-form-item :label="$t('GlobalSetObj.fuwushang')+'：'">
              <el-select
                v-model="queryForm.params.systemCode"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                filterable
                style="width: 100%"
              >
                <el-option
                  v-for="item in systemSeviceList"
                  :key="item.id"
                  :label="`${item.name}${item.code ? `(${item.code})` : ''}`"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <!--        派送网点-->
          <el-col :span="6">
            <el-form-item :label="$t('GlobalSetObj.DeliveryPoint')+'：'">
              <el-select
                v-model="queryForm.params.preDeliverStationId"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                filterable
                style="width: 100%"
              >
                <el-option
                  v-for="item in siteIdList"
                  :key="item.id"
                  :label="`${item.name}${item.code ? `(${item.code})` : ''}`"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <!--        状态-->
          <el-col :span="6">
            <el-form-item :label="$t('GlobalSetObj.status')+'：'">
              <el-select
                v-model="queryForm.params.bigBagStatus"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                style="width: 100%"
              >
                <!--              已封袋-->
                <el-option
                  :label="$t('newOperationCenter.SealedBag')"
                  value="1"
                />
                <!--              已装配-->
                <el-option
                  :label="$t('operatingPlatform.assemblyCompleted')"
                  value="2"
                />
                <!--              装配完结-->
                <el-option
                  :label="$t('operatingPlatform.carregamento')"
                  value="3"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <!--        封袋人-->
          <el-col :span="5">
            <el-form-item :label="$t('GlobalSetObj.BagSealer')+'：'">
              <el-select
                v-model="queryForm.params.packageUserId"
                :disabled="!$store.getters.button_permissions.includes('btn:automatic-sorting:bagworkList:search-all')"
                :placeholder="$t('operationCenter.Only10AreDisplayed')"
                :remote-method="getUserList"
                clearable
                filterable
                remote
                style="width: 100%"
              >
                <el-option
                  v-for="item in userList"
                  :key="item.id"
                  :label="item.createUserName+'('+item.realName+')'"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <!--        封袋时间-->
          <el-col :span="8">
            <el-form-item :label="$t('GlobalSetObj.BagSealingTime')+'：'">
              <el-date-picker
                v-model="queryDate"
                :default-time="['00:00:00', '23:59:59']"
                :end-placeholder="$t('operationCenter.endDate')"
                :picker-options="pickerOptions"
                :range-separator="$t('operationCenter.to')"
                :start-placeholder="$t('operationCenter.startDate')"
                style="width: 100%"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
              />
            </el-form-item>
          </el-col>
          <!--  州 -->
          <el-col :span="5">
            <el-form-item :label="$t('GlobalSetObj.Estado')+'：'">
              <el-select
                v-model="queryForm.params.siteState"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                filterable
                style="width: 100%"
              >
                <el-option
                  v-for="item in StateList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.label"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row :gutter="20" style="margin-top: 10px;">
        <!--      查询-->
        <el-button
          size="small" style="margin-left: 10px" type="primary"
          @click="getData(1)"
        >{{
          $t('GlobalSetObj.search')
        }}
        </el-button>
        <!--      导出装袋数据-->
        <el-button icon="el-icon-download" size="small" @click="exportBigBagExporTwayBillBag">
          {{ $t('operatingPlatform.exportBagData') }}
        </el-button>
        <!--      导出运单详情-->
        <el-button icon="el-icon-download" size="small" @click="exportBigBagExportWaybillDetails">
          {{ $t('operatingPlatform.exportWaybillDetails') }}
        </el-button>
      </el-row>
    </el-card>
    <el-card>
      <el-table
        :data="tableData"
        border
        style="width: 100%;margin-top: 10px"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <!--      袋号-->
        <el-table-column
          :label="$t('GlobalSetObj.bagNumber')"
          prop="transitBagNumber"
        >
          <template slot-scope="scope">
            <span
              style="color: #409eff;cursor: pointer"
              @click="getBagDetail(scope.row.transitBagNumber)"
            >{{ scope.row.transitBagNumber }}</span>
          </template>
        </el-table-column>
        <!--      总单号-->
        <el-table-column
          :label="$t('GlobalSetObj.totalSingNumber')"
          prop="instoreBigBagNo"
        />
        <!--      服务商-->
        <el-table-column
          :label="$t('GlobalSetObj.fuwushang')"
          prop="systemProviderName"
        />
        <!-- 州  -->
        <el-table-column
          :label="$t('GlobalSetObj.Estado')"
          prop="siteState"
        />
        <!--      派送网点-->
        <el-table-column
          :label="$t('GlobalSetObj.DeliveryPoint')"
          prop="siteName"
        />
        <!--      派送路线-->
        <el-table-column
          :label="$t('GlobalSetObj.DeliveryRoutes')"
          prop="pathNumber"
        />
        <!--      小包数-->
        <el-table-column
          :label="$t('trajectory.numberSmallPackets')"
          prop="packageCount"
        />
        <!--      总重量（KG）-->
        <el-table-column
          :label="$t('GlobalSetObj.totalWeightKG')"
          prop="totalWeight"
        />
        <!--      状态-->
        <el-table-column
          :label="$t('GlobalSetObj.status')"
          prop="address"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.bigBagStatus === 1">{{ $t('newOperationCenter.SealedBag') }}</span>
            <span v-if="scope.row.bigBagStatus === 2">{{ $t('operatingPlatform.assemblyCompleted') }}</span>
            <span v-if="scope.row.bigBagStatus === 3">{{ $t('operatingPlatform.carregamento') }}</span>
          </template>
        </el-table-column>
        <!--      封袋人-->
        <el-table-column
          :label="$t('GlobalSetObj.BagSealer')"
          prop="packageUser"
        />
        <!--      封袋时间-->
        <el-table-column
          :label="$t('GlobalSetObj.BagSealingTime')"
          prop="packageTime"
        />
        <!--      操作-->
        <el-table-column
          :label="$t('GlobalSetObj.operate')"
          prop="address"
        >
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="getBagDetail(scope.row.transitBagNumber)">
              {{ $t('operatingPlatform.viewUpdate') }}
            </el-button>
            <!--打印袋号-->
            <el-button
              size="small"
              type="text"
              @click="printBagNo(scope.row)"
            >{{ $t('GlobalSetObj.Print') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--  分页  -->
      <el-pagination
        :current-page.sync="queryForm.number"
        :page-size.sync="queryForm.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 10px 0;text-align: right"
        @size-change="getData"
        @current-change="getData"
      />
    </el-card>
    <!--    装袋详情-->
    <el-drawer
      :title="$t('GlobalSetObj.operate')"
      :visible.sync="drawer"
      :wrapper-closable="false"
      direction="ltr"
      size="70%"
    >
      <el-form label-position="right" label-width="120px">
        <el-row :gutter="10">
          <el-col :span="6">
            <!--            袋号-->
            <el-form-item :label="$t('GlobalSetObj.bagNumber')+'：'">
              <span>{{ BagNumberDetail.bagNumber }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--            服务商-->
            <el-form-item :label="$t('GlobalSetObj.fuwushang')+'：'">
              <span>{{ BagNumberDetail.systemProviderName }}</span>
            </el-form-item>
          </el-col>
          <!--          派送网点-->
          <el-col :span="6">
            <el-form-item :label="$t('GlobalSetObj.DeliveryPoint')+'：'">
              <span>{{ BagNumberDetail.siteName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--            总重量-->
            <el-form-item :label="$t('collectionCenter.totalWeight')+''">
              <span>{{ BagNumberDetail.totalWeight }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <!--            运单号-->
            <el-form-item :label="$t('GlobalSetObj.waybillNumber')+'：'">
              <el-input v-model="bagNumberDetailSearch.waybillNumber" size="small" style="width: 80%" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <!--            客户单号-->
            <el-form-item :label="$t('collectionCenter.customerNumber')+'：'">
              <el-input v-model="bagNumberDetailSearch.customerNumber" size="small" style="width: 80%" />
            </el-form-item>
          </el-col>
          <!--          服务商单号-->
          <el-col :span="8">
            <el-form-item :label="$t('GlobalSetObj.serviceLogistics')+'：'">
              <el-input v-model="bagNumberDetailSearch.logisticsNumber" size="small" style="width: 80%" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row :gutter="20">
        <el-col :span="16">
          <!--          查询-->
          <el-button style="margin-left: 30px" type="primary" @click="getListDetail">{{
            $t('GlobalSetObj.search')
          }}
          </el-button>
          <el-input
            v-if="BagNumberDetail.bigBagStatus===1"
            v-model="waybillNumberValue"
            :placeholder="$t('operatingPlatform.scanOrEnterWaybillToAdd')"
            style="width: 300px;margin-left: 20px;margin-right: 20px"
            @keyup.enter.native="getWaybillNumberList"
          />
          <!--          封袋-->
          <el-button
            v-if="BagNumberDetail.bigBagStatus===1&&$store.getters.button_permissions.includes('btn:automatic-sorting:bagworkList:seal-the-bag')"
            type="warning"
            @click="sealBag"
          >{{ $t('GlobalSetObj.envelope') }}
          </el-button>
        </el-col>
        <el-col :span="8">
          <div style="position: relative;overflow: hidden">
            <!--            导出-->
            <el-button icon="el-icon-download" style="float: right;margin-right: 30px" @click="exportDetailList">
              {{ $t('collectionCenter.export') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
      <div style="margin: 10px 20px;overflow:hidden;">
        <el-table
          :data="searchTransitBagNumberList"
          border
          style="height: 70vh;overflow-y: auto"
        >
          <!--          运单号-->
          <el-table-column
            :label="$t('GlobalSetObj.waybillNumber')"
            prop="waybill_number"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.waybill_number ? scope.row.waybill_number : '- -' }}</span>
            </template>
          </el-table-column>
          <!--          客户单号-->
          <el-table-column
            :label="$t('collectionCenter.customerNum')"
            prop="customerNumber"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.customerNumber ? scope.row.customerNumber : '- -' }}</span>
            </template>
          </el-table-column>
          <!--          服务商单号-->
          <el-table-column
            :label="$t('GlobalSetObj.serviceLogistics')"
            prop="logistics_number"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.logistics_number ? scope.row.logistics_number : '- -' }}</span>
            </template>
          </el-table-column>
          <!--          入库单号-->
          <el-table-column
            :label="$t('GlobalSetObj.TheInboundOrderNumber')"
            prop="inboundReceiptNumber"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.inboundReceiptNumber ? scope.row.inboundReceiptNumber : '- -' }}</span>
            </template>
          </el-table-column>
          <!--          重量（KG）-->
          <el-table-column
            :label="$t('operatingPlatform.weightKG')"
            prop="weight"
          />
          <!--          操作-->
          <el-table-column
            :label="$t('GlobalSetObj.operate')"
            align="center"
            prop="address"
          >
            <template slot-scope="scope">
              <!--              移出-->
              <el-link v-if="BagNumberDetail.bigBagStatus===1" type="danger" @click="detailAddAndDel('del',scope.row)">
                {{ $t('GlobalSetObj.MoveOut') }}
              </el-link>
            </template>
          </el-table-column>
        </el-table>
        <!--  分页  -->
        <!--        <el-pagination-->
        <!--          :current-page="bagNumberDetailSearch.number"-->
        <!--          :page-size="bagNumberDetailSearch.pageSize"-->
        <!--          :page-sizes="[10, 20, 50, 100]"-->
        <!--          :total="searchTransitBagNumberList.length"-->
        <!--          background-->
        <!--          layout="total, sizes, prev, pager, next, jumper"-->
        <!--          style="margin: 10px 0;text-align: right"-->
        <!--        />-->
      </div>
    </el-drawer>
    <!--打印大包号-->
    <div class="printStyle" style="display: none">
      <printBagNumberHtml
        v-if="showPrintBagNumberHtml"
        :param-data="printBagObj"
        @hiddenBagNumberHtml="hiddenBagNumberHtml"
      />
    </div>
  </div>
</template>
<script>
import { getSystemServiceList } from '@/api/channelManage';
import { searchSiteIdList } from '@/api/lanshou';
import { getUserPage } from '@/api/user';
import { baggingExport, bigBagExporTwayBillBag, bigBagExportWaybillDetails } from '@/api/operationCenter';
import { apiQueryTransitBag, apiSaveTransitBag, apiUpdateTransitBag, baggingPage } from '@/api/storeAdmin';
import dayjs from 'dayjs';
import printBagNumberHtml from '@/views/automaticSortings/ScanBagging/printBagNumber';
import { operatingPlatform } from '@/lang/type/operatingPlatform';
import { getLgAreaListData } from '@/api/orderApi';
import { goExportList } from '@/utils/goExportList.js';

export default {
  components: { printBagNumberHtml },
  data() {
    return {
      StateList: [], // 州列表
      queryForm: {
        pageSize: 10,
        number: 1,
        params: {
          searchType: 'transitBagNumbers',
          systemCode: '', // 服务商
          bigBagStatus: '', // 大包状态
          preDeliverStationId: '', // 派送网点
          packageUserId: '', // 封袋人
          transitBagNumbers: '',
          siteState: ''
        }
      },
      total: 0,
      queryDate: [], // 封袋时间
      tableData: [], // 表格数据
      selectData: [], // 选中数据
      drawer: false, // 装袋详情弹窗
      systemSeviceList: [], // 服务商列表
      siteIdList: [], // 网点列表
      userList: [], // 用户列表
      transitBagNumberList: [], // 袋号列表
      searchTransitBagNumberList: [], // 查询袋号列表
      BagNumberDetail: {}, // 袋号详情
      bagNumberDetailSearch: {
        waybillNumber: '', // 运单号
        customerNumber: '', // 客户单号
        logisticsNumber: '', // 服务商单号
        pageSize: 10,
        number: 1
      }, // 袋号详情搜索
      waybillNumberValue: '', // 运单号搜索
      printBagObj: {}, // 打印袋号参数
      showPrintBagNumberHtml: false,
      // el-date-picker type="datetimerange"开始时间和结束时间最大选择一个月
      pickerOptions: {
        // 设置不能选择的日期
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate0 = minDate.getTime();
          if (maxDate) {
            this.choiceDate0 = '';
          }
        },
        disabledDate: (time) => {
          const choiceDateTime = new Date(this.choiceDate0).getTime();
          const minTime = new Date(choiceDateTime).setMonth(
            new Date(choiceDateTime).getMonth() - 1
          );
          const maxTime = new Date(choiceDateTime).setMonth(
            new Date(choiceDateTime).getMonth() + 1
          );
          const min = minTime;
          const newDate =
            new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000;
          const max = newDate < maxTime ? newDate : maxTime;
          // 如果已经选中一个日期 则 返回 该日期前后一个月时间可选
          if (this.choiceDate0) {
            return time.getTime() < min || time.getTime() > max;
          }
          console.log(time.getTime(), 'time.getTime()time.getTime()');
          // 若一个日期也没选中 则 返回 当前日期以前日期可选
          return time.getTime() > newDate;
        }
      }
    };
  },
  computed: {
    operatingPlatform() {
      return operatingPlatform;
    }
  },
  async created() {
    this.getAddress();
    await this.getUserList();
    {
      // 获取当前用户数据，添加在列表中
      const param = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          accountState: '1',
          usernameLike: this.$store.getters.name
        }
      };
      getUserPage(param)
        .then((res) => {
          if (res.status === 'OK') {
            this.userList = [...this.userList, ...res.data.records];
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    this.queryForm.params.packageUserId = this.$store.getters.userId;
    // moment获取最近一周的开始时间和结束时间
    const start = dayjs().subtract(7, 'day').format('YYYY-MM-DD 00:00:00');
    const end = dayjs().format('YYYY-MM-DD 23:59:59');
    this.queryDate = [start, end];
    this.getData();
    this.getServiceProviders();
    this.getBranchList();
  },
  methods: {
    /**
     * @description 获取查询参数
     */
    getQueryParame(pageNumber) {
      if (pageNumber) {
        this.queryForm.number = pageNumber;
      }
      const param = {
        pageNumber: this.queryForm.number,
        pageSize: this.queryForm.pageSize,
        params: {
          systemCode: this.queryForm.params.systemCode,
          bigBagStatus: this.queryForm.params.bigBagStatus,
          preDeliverStationId: this.queryForm.params.preDeliverStationId,
          packageUserId: this.queryForm.params.packageUserId,
          siteState: this.queryForm.params.siteState
        }
      };
      if (this.queryDate && this.queryDate.length) {
        param.params.packageBeginTime = this.queryDate[0];
        param.params.packageEndTime = this.queryDate[1];
      }
      if (this.queryForm.params.transitBagNumbers) {
        const order = this.queryForm.params.transitBagNumbers.split(/[(\r\n)\r\n]+/);
        const orderNoList = [];
        order.map((item) => {
          if (item) {
            orderNoList.push(item.trim());
          }
        });
        if (this.queryForm.params.searchType === 'transitBagNumbers') {
          param.params.transitBagNumbers = orderNoList;
          param.params.instoreBigBagNos = [];
        } else {
          param.params.transitBagNumbers = [];
          param.params.instoreBigBagNos = orderNoList;
        }
      } else {
        param.params.transitBagNumbers = [];
        param.params.instoreBigBagNos = [];
      }
      return param;
    },
    /**
     * @description 获取表格数据
     */
    getData(pageNumber) {
      const param = this.getQueryParame(pageNumber);
      if (param.params.transitBagNumbers.length > 200 || param.params.instoreBigBagNos.length > 200) {
        // 提示最大输入两百个袋号
        this.$message({
          message: this.$t('GlobalSetObj.queried200pieces'),
          type: 'warning'
        });
        return;
      }
      baggingPage(param)
        .then((res) => {
          if (res.status === 'OK') {
            this.tableData = res.data.page.records;
            console.log(this.total, 'this.total');
            this.total = res.data.page.total;
          }
        });
    },
    /**
     * @description 导出装袋数据
     */
    exportBigBagExporTwayBillBag() {
      const param = this.getQueryParame();
      if (this.selectData.length) {
        param.params = {};
        param.params.idList = this.selectData.map((item) => {
          return item.id;
        });
      }
      bigBagExporTwayBillBag(param.params)
        .then((res) => {
          if (res.status === 'OK') {
            goExportList(this);
          }
        });
    },
    /**
     * @description 导出运单详情
     */
    exportBigBagExportWaybillDetails() {
      const param = this.getQueryParame();
      if (this.selectData.length) {
        param.params = {};
        param.params.idList = this.selectData.map((item) => {
          return item.id;
        });
      }
      bigBagExportWaybillDetails(param.params)
        .then((res) => {
          if (res.status === 'OK') {
            goExportList(this);
          }
        });
    },
    /**
     * @description 获取系统服务商列表
     */
    getServiceProviders() {
      getSystemServiceList().then(res => {
        if (res.status === 'OK') {
          this.systemSeviceList = res.data;
        }
      });
    },
    /**
     * @description 获取网点列表
     */
    getBranchList() {
      searchSiteIdList().then(res => {
        if (res.status === 'OK') {
          res.data.forEach(data => {
            this.siteIdList.push({ id: data.id, name: data.name, code: data.code });
          });
        }
      });
    },
    /**
     * @description 获取用户列表
     * @param name
     */
    getUserList(name) {
      console.log('被调用了', name);
      // 获取操作员
      const param = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          accountState: '1',
          usernameLike: ''
        }
      };
      if (name) {
        param.params.usernameLike = name;
      }
      getUserPage(param)
        .then((res) => {
          if (res.status === 'OK') {
            this.userList = res.data.records;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * @description 获取袋详情
     */
    getBagDetail(transitBagNumber) {
      this.transitBagNumberList = [];
      this.searchTransitBagNumberList = [];
      this.BagNumberDetail = {};
      this.waybillNumberValue = '';
      this.bagNumberDetailSearch = {
        waybillNumber: '', // 运单号
        customerNumber: '', // 客户单号
        logisticsNumber: '', // 服务商单号
        pageSize: 10,
        number: 1
      };
      this.drawer = true;
      apiUpdateTransitBag({ transitBagNumber, notLimitation: true })
        .then((res) => {
          if (res.status === 'OK') {
            console.log(res.data);
            this.transitBagNumberList = res.data.list;
            this.searchTransitBagNumberList = res.data.list;
            if (res.data.list && res.data.list.length) {
              // 总重量
              let totalWeight = 0;
              res.data.list.forEach((item) => {
                totalWeight += item.weight;
              });
              this.BagNumberDetail = {
                ...res.data.list[0],
                bagNumber: res.data.bagNumber,
                totalWeight: totalWeight.toFixed(3).replace(/\.?0+$/, ''),
                bigBagStatus: res.data.bigBagStatus,
                systemProviderName: res.data.systemProviderName
              };
              console.log(this.BagNumberDetail, 'this.BagNumberDetail');
            }
          }
        });
    },
    /**
     * @description 搜索详情列表的数据
     */
    getListDetail() {
      console.log(this.bagNumberDetailSearch.customerNumber === '', this.bagNumberDetailSearch.logisticsNumber === '', this.bagNumberDetailSearch.waybillNumber === '');
      if (this.bagNumberDetailSearch.customerNumber === '' && this.bagNumberDetailSearch.logisticsNumber === '' && this.bagNumberDetailSearch.waybillNumber === '') {
        this.searchTransitBagNumberList = this.transitBagNumberList;
        return;
      }
      console.log(this.bagNumberDetailSearch, 'this.bagNumberDetailSearch');
      this.searchTransitBagNumberList = this.transitBagNumberList.filter((item) => {
        if (item.waybill_number === this.bagNumberDetailSearch.waybillNumber || item.customerNumber === this.bagNumberDetailSearch.customerNumber || item.logistics_number === this.bagNumberDetailSearch.logisticsNumber) {
          return item;
        }
      });
    },
    /**
     * @description 导出装袋数据
     */
    exportDetailList() {
      const waybillNumbers = [];
      this.searchTransitBagNumberList.forEach(item => {
        waybillNumbers.push(item.waybill_number);
        // return item.waybill_number;
      });
      baggingExport({ waybillNumbers })
        .then((res) => {
          if (res.status === 'OK') {
            goExportList(this);
          }
        });
    },
    /**
     * @description 袋号详情添加和删除
     * @param { string } type add:添加 del:删除
     * @param { object } row 袋号详情
     */
    detailAddAndDel(type, row) {
      console.log(type, row);
      if (type === 'del') {
        this.searchTransitBagNumberList = this.searchTransitBagNumberList.filter((item) => {
          return item.waybill_number !== row.waybill_number;
        });
        this.transitBagNumberList = this.transitBagNumberList.filter((item) => {
          return item.waybill_number !== row.waybill_number;
        });
      } else if (type === 'add') {
        if (this.transitBagNumberList.length) {
          if (row.systemCode !== this.transitBagNumberList[0].systemCode) {
            this.$message({
              message: this.$t('operatingPlatform.serviceProviderInconsistency'),
              type: 'warning'
            });
            return;
          }
          if (row.siteId !== this.transitBagNumberList[0].siteId) {
            this.$message({
              message: this.$t('operatingPlatform.deliveryPointInconsistency'),
              type: 'warning'
            });
            return;
          }
        }
        // 查询transitBagNumberList中是否存在该运单号，存在则不添加
        const isExist = this.transitBagNumberList.some((item) => {
          return item.waybill_number === row.waybill_number;
        });
        if (isExist) {
          return;
        }
        this.searchTransitBagNumberList = [row, ...this.searchTransitBagNumberList];
        this.transitBagNumberList = [row, ...this.transitBagNumberList];
      }
      // 总重量
      let totalWeight = 0;
      this.searchTransitBagNumberList.forEach((item) => {
        totalWeight += item.weight;
      });
      // 保留三位小数，去除0
      totalWeight = totalWeight.toFixed(3).replace(/\.?0+$/, '');
      this.BagNumberDetail.totalWeight = totalWeight;
    },
    /**
     * @description 表格选中数据
     * @param arr
     */
    handleSelectionChange(arr) {
      this.selectData = arr;
    },
    /**
     * @description 封袋
     */
    sealBag() {
      if (!this.transitBagNumberList.length) {
        this.$message({
          message: this.$t('operatingPlatform.emptyBagCannotBeSubmitted'),
          type: 'warning'
        });
        return;
      }
      const dtoList = this.transitBagNumberList.map(item => {
        return {
          logisticsNumber: item.logistics_number,
          orderWaybill: item.waybill_number,
          weight: item.weight,
          quantity: item.package_count,
          id: item.id,
          customerName: item.customer_name
        };
      });
      const { totalWeight, preDeliverStationId, bagNumber, siteState } = this.BagNumberDetail;
      const serverChannelName = this.BagNumberDetail.server_channel_name;
      const serverChannelId = this.BagNumberDetail.server_channel_id;
      const serverChannelCode = this.BagNumberDetail.provider_channel_code;
      const pathNumber = this.BagNumberDetail.pathNumber;
      const providerId = this.BagNumberDetail.providerId;
      const providerName = this.BagNumberDetail.providerName;
      const systemCode = this.BagNumberDetail.systemCode;
      const param = {
        serverChannelName,
        serverChannelId,
        serverChannelCode,
        pathNumber,
        providerId,
        providerName,
        systemCode,
        totalWeight,
        preDeliverStationId,
        transitBagNumber: bagNumber,
        dtoList,
        siteState
      };
      // return;
      apiSaveTransitBag(param).then((res) => {
        console.log(res);
        if (res.status === 'OK') {
          this.$message({
            message: this.$t('operatingPlatform.bagSealedSuccessfully'),
            type: 'success'
          });
          this.getData();
        }
      });
    },
    /**
     * @description 获取运单号列表
     */
    getWaybillNumberList() {
      const param = {
        orderWaybill: this.waybillNumberValue
      };
      this.waybillNumberValue = '';
      apiQueryTransitBag(param).then(res => {
        if (res.status === 'OK') {
          console.log(res);
          if (res.data) {
            console.log(res.data, 'res.data');
            this.detailAddAndDel('add', res.data);
          } else {
            this.$message({
              message: this.$t('operatingPlatform.waybillNumberNotFound'),
              type: 'warning'
            });
          }
          if (res.msg) {
            this.$message.info(res.msg);
          }
        }
      });
    },
    // 打印袋号
    printBagNo(row) {
      console.log(row, '打印袋号');
      if (
        row.baseCode && // 确保 baseCode 存在
        row.baseCode.length === 4 && // 确保 baseCode 的长度是 3
        /^[a-zA-Z]{4}$/.test(row.baseCode) // 使用正则表达式测试 baseCode 是否仅包含3个字母
      ) {
        row.baseCodeName = row.baseCode;
      }
      this.printBagObj = {
        threeCode: row.siteName,
        departmentCode: row.siteDepartmentCode,
        providerName: row.providerName,
        deliverSiteName: row.siteCode,
        siteAddress: row.siteAddress,
        baseCodeName: row.baseCodeName,
        time: dayjs().format('DD-MM-YYYY'),
        timeSs: dayjs().format('HH:mm:ss')
      };
      this.showPrintBagNumberHtml = true;
      // const param = {
      //   orderWaybill: '',
      //   bagNumber: row.transitBagNumber,
      //   serverChannelName: row.serverChannelName
      // };
      // printBagNumber(param).then(res => {
      //   if (res.status === 'OK') {
      //     this.printBagObj = {
      //       threeCode: row.siteName,
      //       departmentCode: row.siteDepartmentCode,
      //       providerName: row.providerName,
      //       deliverSiteName: row.siteCode,
      //       siteAddress: row.siteAddress,
      //       time: dayjs().format('DD-MM-YYYY'),
      //       timeSs: dayjs().format('HH:mm:ss')
      //     };
      //     this.showPrintBagNumberHtml = true;
      //   }
      // });
    },
    // 清空打印袋号数据
    hiddenBagNumberHtml() {
      this.showPrintBagNumberHtml = false;
      this.printBagObj = {};
    },
    // 州
    getAddress() { // level 0 州    1 城市   2 地区
      const params = {
        level: 0,
        parentId: ''
      };
      getLgAreaListData(params, false).then((res) => {
        if (res.status === 'OK') {
          this.StateList = res.data.map(item => {
            return {
              id: item.id,
              label: `${item.nameEn}`
            };
          });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.app-main {
  padding: 10px;
}

.app-main .el-form-item {
  margin-bottom: 0;
}
</style>
