<template>
  <div class="app-container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="CT-e" name="CTE">
        <cte-invoice v-if="activeName === 'CTE'" />
      </el-tab-pane>
      <!--      <el-tab-pane label="NFS-e" name="NFSE">-->
      <!--        <nfse-invoice v-if="activeName === 'NFSE'" />-->
      <!--      </el-tab-pane>-->
      <el-tab-pane :label="$t('GlobalSetObj.all')" name="all">
        <all-invoice v-if="activeName === 'all'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import cteInvoice from '@/views/orderCenter/logisticsInvoice/cmp/cteInvoice';
// import nfseInvoice from '@/views/orderCenter/logisticsInvoice/cmp/nfseInvoice';
import allInvoice from '@/views/orderCenter/logisticsInvoice/cmp/allInvoice';

export default {
  name: 'LogisticsInvoice',
  components: {
    cteInvoice,
    // nfseInvoice,
    allInvoice
  },
  data() {
    return {
      activeName: 'CTE'
    };
  },
  created() {

  },
  methods: {
    // 标签切换
    handleClick(data) {
    }
  }
};
</script>

<style scoped>

</style>
