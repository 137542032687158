// 角色管理
const roleManage = {
  // 角色管理
  roleName: {
    zh: '角色名称',
    en: '',
    pu: 'Nome do papel'
  },
  CollapseQuery: {
    zh: '收起查询',
    en: 'Collapse the Query button',
    pu: 'Recolher o botão Consulta'
  },
  ExpandQuery: {
    zh: '展开查询',
    en: 'Expand the Query button',
    pu: 'Expandir o botão Consulta'
  },
  search: {
    zh: '查询',
    en: '',
    pu: 'consultar'
  },
  reset: {
    zh: '重置',
    en: 'reset',
    pu: 'Redefinir'
  },
  pleaseInput: {
    zh: '请输入',
    en: 'Please enter the content',
    pu: 'por favor digite'
  },
  add: {
    zh: '新增',
    en: 'add',
    pu: 'adicionar'
  },
  ind: {
    zh: '序号',
    en: 'index',
    pu: 'Número de série.'
  },
  createTime: {
    zh: '创建日期',
    en: '',
    pu: 'Data de criação'
  },
  operation: {
    zh: '操作',
    en: 'operation',
    pu: 'Operar'
  },
  edit: {
    zh: '编辑',
    en: 'Edit',
    pu: 'editar'
  },
  usePermissions: {
    zh: '应用权限',
    en: '',
    pu: 'permissão de aplicação'
  },
  usePermissionsTip: {
    zh: '应用权限: ',
    en: '',
    pu: 'permissão de aplicação: '
  },
  delete: {
    zh: '删除',
    en: '',
    pu: 'excluir'
  },
  AreYouSureDelete: {
    zh: '确定删除吗？',
    en: 'Are you sure to delete it?',
    pu: 'Tem certeza de apagar?'
  },
  userPermissionsType: {
    zh: '用户权限类型',
    en: '',
    pu: 'tipo de permissão do usuário'
  },
  ordinary: {
    zh: '普通',
    en: '',
    pu: 'normal'
  },
  personality: {
    zh: '个性',
    en: '',
    pu: 'personalizado'
  },
  unauthorized: {
    zh: '未授权',
    en: '',
    pu: 'não autorizado'
  },
  use: {
    zh: '应用',
    en: '',
    pu: 'aplicar'
  },
  addRole: {
    zh: '新增角色',
    en: '',
    pu: 'adicionar papel'
  },
  changeRole: {
    zh: '修改角色',
    en: '',
    pu: 'modificar o papel'
  },
  TheOperationSucceeded: {
    zh: '操作成功!',
    en: 'The operation succeeded',
    pu: 'A operação foi bem sucedida.'
  },
  note: {
    zh: '备注',
    en: 'remark',
    pu: 'observações'
  },
  Note: {
    zh: '备注：',
    en: 'remark：',
    pu: 'observações：'
  },
  selectPermissions: {
    zh: '选择权限',
    en: '',
    pu: 'selecionar a permissão'
  },
  confirm: {
    zh: '确认',
    en: '',
    pu: 'confirmar'
  },
  cancel: {
    zh: '取消',
    en: 'cancel',
    pu: 'Cancelar'
  },
  inputRoleName: {
    zh: '请填写角色名称',
    en: '',
    pu: 'Favor preencher o nome do papel'
  },
  inputRoleNameCH: {
    zh: '请填写角色名称(中文)',
    en: '',
    pu: ''
  },
  inputRoleNamePU: {
    zh: '请填写角色名称(葡语)',
    en: '',
    pu: ''
  },
  repeatReset: {
    zh: '角色名称重复,请重新填写',
    en: '',
    pu: 'nome do papel repetido, por favor preencha novamente'
  },
  long2to30: {
    zh: '长度在 2 到 30 个字符',
    en: '',
    pu: 'Comprimento entre 2 e 30 caracteres'
  },
  mustChooseOne: {
    zh: '必须选择至少一项权限!',
    en: '',
    pu: 'Pelo menos uma permissão deve ser selecionada!'
  },

  // 添加下级、添加同级、编辑
  addPermissions: {
    zh: '添加权限',
    en: '',
    pu: 'adicionar permissão'
  },
  parentPermissionID: {
    zh: '父权限ID',
    en: '',
    pu: 'ID de permissão de pai'
  },
  permissionsName: {
    zh: '权限名称',
    en: '',
    pu: 'nome de permissão'
  },
  permissionsNamePu: {
    zh: '权限名称（葡语）',
    en: '',
    pu: 'nome da autoridade (em português)'
  },
  permissionsNameEn: {
    zh: '权限名称（英语）',
    en: '',
    pu: 'nome da autoridade (inglês)'
  },
  permissionsTag: {
    zh: '权限标识',
    en: '',
    pu: 'marca de permissão'
  },
  sort: {
    zh: '排序',
    en: '',
    pu: 'ordenar'
  },
  sortNotEmpty: {
    zh: '排序不能为空',
    en: '',
    pu: 'A ordenação não pode ser vazia'
  },
  sortIsNumber: {
    zh: '排序必须为数字值',
    en: '',
    pu: 'A ordenação deve ser valores numéricos'
  },
  littleValueFront: {
    zh: '值越小越靠前',
    en: '',
    pu: 'Quanto menor o valor, mais avançada é a posição'
  },
  editPermissions: {
    zh: '编辑权限',
    en: '',
    pu: 'editar permissão'
  },
  delPermissions: {
    zh: '删除权限',
    en: '',
    pu: 'eliminar permissões'
  },
  permissionsID: {
    zh: '权限ID',
    en: '',
    pu: 'Id de permissão'
  },
  roleNameCH: {
    zh: '角色名称(中文)：',
    en: '',
    pu: 'Nome do personagem (chinês)：'
  },
  roleNamePU: {
    zh: '角色名称(葡语)：',
    en: '',
    pu: 'Nome do personagem (português)：'
  },
  roleNameCHT: {
    zh: '角色名称(中文)',
    en: '',
    pu: 'Nome do personagem (chinês)'
  },
  roleNamePUT: {
    zh: '角色名称(葡语)',
    en: '',
    pu: 'Nome do personagem (português)'
  },
  roleNameCH1: {
    zh: '角色名称(中文)',
    en: '',
    pu: 'Nome do personagem (chinês)'
  },
  roleNamePU1: {
    zh: '角色名称(葡语)',
    en: '',
    pu: 'Nome do personagem (português)'
  },
  roleType: {
    zh: '角色类别：',
    en: '',
    pu: 'Categoria de personagem.'
  },
  default: {
    zh: '默认',
    en: '',
    pu: 'Predefinição'
  },
  onlySite: {
    zh: '仅网点系统',
    en: '',
    pu: 'Apenas sistema de Ponto'
  },
  updateUserBelongs: {
    zh: '修改人所属网点',
    en: '',
    pu: 'Ponto de modificador'
  },
  ModifiedBy: {
    zh: '修改人',
    en: 'Modified by',
    pu: 'Modificado por'
  },
  creator: {
    zh: '创建人',
    en: 'creator',
    pu: 'Criado por'
  },
  createSite: {
    zh: '创建人所属网点',
    en: '',
    pu: 'rede de criadores'
  },
  catalog: {
    zh: '目录',
    en: 'catalog',
    pu: 'índice'
  },
  EditTheContent: {
    zh: '编辑内容',
    en: 'Edit the content',
    pu: 'Editar'
  },
  PleaseSelectTheMenuFirst: {
    zh: '请先选择菜单',
    en: 'Please select the menu first',
    pu: 'selecina o menu primeiro'
  },
  TheFirstLevelMenuIsNotEditable: {
    zh: '一级菜单不可编辑内容',
    en: 'The first-level menu is not editable',
    pu: 'Não pode editar no 1º nível de menu'
  },
  AddSiblingMenus: {
    zh: '添加同级菜单',
    en: 'Add sibling menus',
    pu: 'Adicionar Menu do Mesmo Nível'
  },
  AddSubmenus: {
    zh: '添加下级菜单',
    en: 'Add submenus',
    pu: 'Adicionar Submenu'
  },
  EditTheMenu: {
    zh: '编辑菜单',
    en: 'Edit the menu',
    pu: 'Editar Menu'
  },
  MenuName: {
    zh: '菜单名称',
    en: 'Menu name',
    pu: 'Nomde de Menu'
  },
  Introduction: {
    zh: '顺序',
    en: 'Introduction',
    pu: 'Ordem'
  },
  OperatorsManual: {
    zh: '操作手册',
    en: "Operator's manual",
    pu: 'Manual do operador'
  },
  oftenProblem: {
    zh: '常见问题',
    en: '',
    pu: 'Problemas comuns'
  },
  newTitle: {
    zh: '新增标题',
    en: '',
    pu: 'Novo título'
  },
  editTitle: {
    zh: '编辑内容',
    en: '',
    pu: 'Conteúdo editorial'
  },
  newTitleName: {
    zh: '标题名称',
    en: '',
    pu: 'Nome do título'
  },
  DescribeContent: {
    zh: '描述内容',
    en: '',
    pu: 'Descrição'
  },
  requestMessageList: {
    zh: '需求留言列表',
    en: '',
    pu: 'Solicitar lista de mensagens'
  }
};
export { roleManage };
