var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c(
            "el-form",
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { staticClass: "col2" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "selectConditionClass autoWidth",
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "collectionCenter.conditionQuery"
                                    ),
                                  },
                                  on: { change: _vm.selectConditionClick },
                                  model: {
                                    value: _vm.selectCondition,
                                    callback: function ($$v) {
                                      _vm.selectCondition = $$v
                                    },
                                    expression: "selectCondition",
                                  },
                                },
                                [
                                  _c("template", { slot: "prefix" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          (
                                            _vm.chooseCondition.find(function (
                                              item
                                            ) {
                                              return (
                                                item.value ===
                                                _vm.selectCondition
                                              )
                                            }) || {}
                                          ).chooseName ||
                                            _vm.$t(
                                              "collectionCenter.conditionQuery"
                                            )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _vm._l(
                                    _vm.chooseCondition,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.chooseName,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm.selectCondition == "c1"
                            ? _c(
                                "el-col",
                                { staticClass: "colWill" },
                                [
                                  _c(
                                    "el-form-item",
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          autosize: "",
                                          placeholder: _vm.$t(
                                            "collectionCenter.BatchQuery"
                                          ),
                                        },
                                        model: {
                                          value: _vm.odd.orderNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.odd,
                                              "orderNumber",
                                              $$v
                                            )
                                          },
                                          expression: "odd.orderNumber",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.selectCondition == "c3"
                            ? _c(
                                "el-col",
                                { staticClass: "colWill" },
                                [
                                  _c(
                                    "el-form-item",
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          autosize: "",
                                          placeholder: _vm.$t(
                                            "collectionCenter.BatchQuery"
                                          ),
                                        },
                                        model: {
                                          value: _vm.odd.packageNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.odd,
                                              "packageNumber",
                                              $$v
                                            )
                                          },
                                          expression: "odd.packageNumber",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:report:MonitoringReport:odd-find",
                          expression:
                            "'btn:collection-center:report:MonitoringReport:odd-find'",
                          arg: "remove",
                        },
                      ],
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-search",
                      },
                      on: { click: _vm.oddSearch },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.search")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:report:MonitoringReport:odd-reset",
                          expression:
                            "'btn:collection-center:report:MonitoringReport:odd-reset'",
                          arg: "remove",
                        },
                      ],
                      attrs: { icon: "el-icon-refresh", size: "mini" },
                      on: { click: _vm.resetClickOdd },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")))]
                  ),
                  _vm.activeNameDH === "dh" && _vm.oddExportDH
                    ? _c(
                        "el-button",
                        {
                          class:
                            _vm.loadUpList.length <= 0 && _vm.isClick
                              ? "onlyClickOnce10"
                              : "",
                          attrs: { icon: "el-icon-upload2", size: "mini" },
                          on: { click: _vm.exportClickAll },
                        },
                        [_vm._v(_vm._s(_vm.$t("collectionCenter.export")))]
                      )
                    : _vm._e(),
                  _vm.activeNameDH === "dh" && _vm.oddAllExportDH
                    ? _c(
                        "el-button",
                        {
                          class: _vm.isClickAll3 ? "onlyClickOnce10" : "",
                          attrs: { icon: "el-icon-upload", size: "mini" },
                          on: { click: _vm.downloadAll },
                        },
                        [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")))]
                      )
                    : _vm._e(),
                  _vm.activeNameDH === "dmx" && _vm.oddExportMX
                    ? _c(
                        "el-button",
                        {
                          class:
                            _vm.loadUpList.length <= 0 && _vm.isClick
                              ? "onlyClickOnce10"
                              : "",
                          attrs: { icon: "el-icon-upload2", size: "mini" },
                          on: { click: _vm.downloadLogistics },
                        },
                        [_vm._v(_vm._s(_vm.$t("collectionCenter.export")))]
                      )
                    : _vm._e(),
                  _vm.activeNameDH === "dmx" && _vm.oddAllExportMX
                    ? _c(
                        "el-button",
                        {
                          class: _vm.isClickAll4 ? "onlyClickOnce10" : "",
                          attrs: { icon: "el-icon-upload", size: "mini" },
                          on: { click: _vm.downloadAllMX },
                        },
                        [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleOddClick },
          model: {
            value: _vm.activeNameDH,
            callback: function ($$v) {
              _vm.activeNameDH = $$v
            },
            expression: "activeNameDH",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("collectionCenter.Number"), name: "dh" } },
            [
              _c(
                "div",
                {
                  staticStyle: { padding: "10px", "box-sizing": "border-box" },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          ref: "oddDH",
                          attrs: {
                            "max-height": 600,
                            border: "",
                            "show-summary": "",
                            "summary-method": _vm.getSummaries,
                            data: _vm.oddTableDate,
                          },
                          on: {
                            select: _vm.batchSelectDH,
                            "select-all": _vm.batchSelectAllDH,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { type: "selection", width: "55" },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "120",
                              align: "center",
                              prop: "customerId",
                              label: _vm.$t("collectionCenter.clientID"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "130",
                              align: "center",
                              prop: "customerName",
                              label: _vm.$t("collectionCenter.CustomerName"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "100",
                              align: "center",
                              prop: "merchantCode",
                              label: _vm.$t("collectionCenter.MerchantNum"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "150",
                              align: "center",
                              prop: "merchantName",
                              label: _vm.$t("collectionCenter.MerchantName"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "150",
                              align: "center",
                              prop: "collectSource",
                              label: _vm.$t("collectionCenter.lanshouTypee"),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.collectSource == 1
                                              ? _vm.$t(
                                                  "collectionCenter.dropOff"
                                                )
                                              : scope.row.collectSource == 2
                                              ? _vm.$t(
                                                  "collectionCenter.doorPickUp"
                                                )
                                              : ""
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "150",
                              align: "center",
                              prop: "collectWay",
                              label: _vm.$t("collectionCenter.collectWay"),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.collectWay == 0
                                              ? "APP"
                                              : scope.row.collectWay == 1
                                              ? "PC"
                                              : ""
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "150",
                              align: "center",
                              prop: "createTime",
                              label: _vm.$t("collectionCenter.orderCreateT"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "200",
                              align: "center",
                              prop: "orderCount",
                              label: _vm.$t(
                                "collectionCenter.TotalNumberOfOrdersReceived"
                              ),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.hzTomx
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { size: "mini" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.cellClickOdd(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(scope.row.orderCount))]
                                        )
                                      : _c("span", [
                                          _vm._v(_vm._s(scope.row.orderCount)),
                                        ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "150",
                              prop: "collectedCount",
                              align: "center",
                              label: _vm.$t(
                                "collectionCenter.ActualNumberOfOrdersReceived"
                              ),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.hzTomx
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { size: "mini" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.cellClickOdd(
                                                  scope.row,
                                                  "collectTimeBool1"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.collectedCount)
                                            ),
                                          ]
                                        )
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(scope.row.collectedCount)
                                          ),
                                        ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "150",
                              prop: "uncollectedCount",
                              align: "center",
                              label: _vm.$t(
                                "collectionCenter.NumberOfUnreceivedOrders"
                              ),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.hzTomx
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { size: "mini" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.cellClickOdd(
                                                  scope.row,
                                                  "collectTimeBool2"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.uncollectedCount)
                                            ),
                                          ]
                                        )
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(scope.row.uncollectedCount)
                                          ),
                                        ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "200",
                              prop: "collectErrorCount",
                              align: "center",
                              label: _vm.$t(
                                "collectionCenter.AbnormalNumberOfOrdersReceived"
                              ),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.hzTomx
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { size: "mini" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.cellClickOdd(
                                                  scope.row,
                                                  [5, 7]
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.collectErrorCount
                                              )
                                            ),
                                          ]
                                        )
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(scope.row.collectErrorCount)
                                          ),
                                        ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "150",
                              prop: "signedInCount",
                              align: "center",
                              label: _vm.$t(
                                "collectionCenter.orderNumberHasBeenSigned"
                              ),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.hzTomx
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { size: "mini" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.cellClickOdd(
                                                  scope.row,
                                                  "signerTimeBool"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.signedInCount)
                                            ),
                                          ]
                                        )
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(scope.row.signedInCount)
                                          ),
                                        ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "150",
                              prop: "canceledCount",
                              align: "center",
                              label: _vm.$t(
                                "collectionCenter.orderNumberHasBeenCancelled"
                              ),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.hzTomx
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { size: "mini" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.cellClickOdd(
                                                  scope.row,
                                                  [2]
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.canceledCount)
                                            ),
                                          ]
                                        )
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(scope.row.canceledCount)
                                          ),
                                        ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "150",
                              prop: "bagSealedCount",
                              align: "center",
                              label: _vm.$t(
                                "collectionCenter.SealedBagOrderNumber"
                              ),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.hzTomx
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { size: "mini" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.cellClickOdd(
                                                  scope.row,
                                                  "bagSealingTimeBool"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.bagSealedCount)
                                            ),
                                          ]
                                        )
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(scope.row.bagSealedCount)
                                          ),
                                        ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "150",
                              align: "center",
                              label: _vm.$t(
                                "collectionCenter.RangeEarningRate"
                              ),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(scope.row.collectSuccessRate) +
                                          "% "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "150",
                              align: "center",
                              label: _vm.$t("collectionCenter.RateOfSigning"),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(scope.row.signInRate) +
                                          "% "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _vm.isShowMXOdd
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("collectionCenter.particulars"),
                    name: "dmx",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          ref: "oddDMX",
                          attrs: {
                            "max-height": 600,
                            border: "",
                            data: _vm.OddMXTableDate,
                          },
                          on: {
                            select: _vm.batchSelectOddMX,
                            "select-all": _vm.batchSelectAllOddMX,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { type: "selection", width: "55" },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "merchantCode",
                              label: _vm.$t("collectionCenter.MerchantNum"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "merchantName",
                              label: _vm.$t("collectionCenter.MerchantName"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "orderNumber",
                              label: _vm.$t("collectionCenter.lanshouOrderNum"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "collectListNumber",
                              label: _vm.$t(
                                "collectionCenter.receivingListNum"
                              ),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "packageNumber",
                              label: _vm.$t("collectionCenter.parcelNum"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "scanNumber",
                              label: _vm.$t("collectionCenter.Barcode"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "bagNumber",
                              label: _vm.$t("collectionCenter.BagNumber"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "siteName",
                              label: _vm.$t("collectionCenter.collectionDot"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "driverName",
                              label: _vm.$t("collectionCenter.LanShouPeople"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "scanTime",
                              label: _vm.$t("collectionCenter.lanshouT"),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-pagination", {
                        staticStyle: { margin: "10px 0" },
                        attrs: {
                          layout: "total, sizes, prev, pager, next, jumper",
                          "current-page": _vm.queryFormOddMX.pageNumber,
                          "page-sizes": [10, 20, 30, 50, 100, 500, 1000],
                          "page-size": _vm.queryFormOddMX.pageSize,
                          total: _vm.OddMXTotal,
                          background: "",
                        },
                        on: {
                          "update:currentPage": function ($event) {
                            return _vm.$set(
                              _vm.queryFormOddMX,
                              "pageNumber",
                              $event
                            )
                          },
                          "update:current-page": function ($event) {
                            return _vm.$set(
                              _vm.queryFormOddMX,
                              "pageNumber",
                              $event
                            )
                          },
                          "update:pageSize": function ($event) {
                            return _vm.$set(
                              _vm.queryFormOddMX,
                              "pageSize",
                              $event
                            )
                          },
                          "update:page-size": function ($event) {
                            return _vm.$set(
                              _vm.queryFormOddMX,
                              "pageSize",
                              $event
                            )
                          },
                          "current-change": _vm.getOddPage,
                          "size-change": _vm.oddSearch,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }