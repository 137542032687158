import { requestReverse } from '@/lib/axios';
import { removeNullParams } from '@/utils/removeNullParams';

export const editionService = {
  async getAll(params, signal) {
    return requestReverse({
      url: `edition/findEdition/${params.take}/${params.skip}`,
      params: {
        ...removeNullParams({ codGroup: params.codGroup })
      },
      signal
    });
  },
  async create(data) {
    const body = this.getBody(data);

    return requestReverse({
      url: `edition/createEdition`,
      method: 'POST',
      data: body
    });
  },
  async delete(id) {
    return requestReverse({
      url: `edition/deleteEdition`,
      method: 'DELETE',
      params: { codGroup: id }
    });
  },
  async update(id, data) {
    const body = this.getBody(data);

    return requestReverse({
      url: `edition/updateEdition`,
      method: 'PUT',
      params: { codGroup: id },
      data: body
    });
  },
  getBody(data) {
    const [firstEdition, secondEdition, thirdEdition, fourthEdition] = data.listEditions;

    return {
      codGroup: data.codGroup,
      firstCod: firstEdition.codEdition,
      firstDescription: firstEdition.description,
      firstVoulume: firstEdition.volume,
      secondCod: secondEdition.codEdition,
      secondDescription: secondEdition.description,
      secondVoulume: secondEdition.volume,
      thirdCod: thirdEdition.codEdition,
      thirdDescription: thirdEdition.description,
      thirdVoulume: thirdEdition.volume,
      fourthCod: fourthEdition.codEdition,
      fourthDescription: fourthEdition.description,
      fourthVoulume: fourthEdition.volume
    };
  }
};
