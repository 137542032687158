<template>
  <el-tabs type="border-card">
    <el-tab-pane v-if="detailsObj.id === 0" :label="$t('WorkCentreTable.add')">
      <add-cmp
        :index="index"
        :user-id-and-name-obj="userIdAndNameObj"
        :ticket-option="ticketOption"
        @close="close"
      />
    </el-tab-pane>
    <!-- <el-tab-pane v-else label="详情">
      <old-details-cmp
        :index="index"
        :user-id-and-name-obj="userIdAndNameObj"
        :details-obj="detailsObj"
      />
    </el-tab-pane> -->
    <el-tab-pane v-else :label="$t('WorkCentreTable.details')">
      <details-cmp
        :index="index"
        :user-id-and-name-obj="userIdAndNameObj"
        :details-obj="detailsObj"
        @closeDetailsCmp="closeDetailsCmp"
      />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
export default {
  components: {
    'add-cmp': () => import('./AddCmp'),
    // 'old-details-cmp': () => import('./DetailsCmp'),
    'details-cmp': () => import('./DetailsCmp.vue')
  },
  props: {
    // 所有发起人
    userIdAndNameObj: {
      type: Object,
      default: () => {}
    },

    // 详情
    detailsObj: {
      type: Object,
      default() {
        return {
          id: 0,
          ticketName: '', // 名称
          appendix: [], // 附件
          ticketDescribe: '', // 内容
          createUserName: '', // 发起人
          executeUserName: '', // 执行人
          planFinishTime: '', // 预计完成时间
          ticketType: '', // 类型
          ticketStatus: '', // 状态
          sort: 4 // 优先级
        };
      }
    },

    // 所有 微工单 类型
    ticketOption: {
      type: Array,
      default() {
        return [];
      }
    },

    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {

    };
  },

  methods: {
    // 新增关闭
    close(isBool) {
      this.$emit('close', isBool);
    },

    // 详情关闭
    closeDetailsCmp(obj) {
      this.$emit('closeDetailsCmp', obj);
    }
  }
};
</script>

<style>

</style>
