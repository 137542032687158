var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.roleOperationPop.title,
            visible: _vm.roleOperationPop.visible,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.roleOperationPop, "visible", $event)
            },
            close: _vm.closeRoleOperation,
            open: _vm.openRoleOperation,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "roleOperationRef",
              attrs: {
                model: _vm.roleForm,
                rules: _vm.roleOperationRules,
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { spam: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("roleManage.roleNameCH"),
                            prop: "roleName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: _vm.isEdit,
                              placeholder: _vm.$t("roleManage.pleaseInput"),
                            },
                            model: {
                              value: _vm.roleForm.roleName,
                              callback: function ($$v) {
                                _vm.$set(_vm.roleForm, "roleName", $$v)
                              },
                              expression: "roleForm.roleName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { spam: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("roleManage.roleNamePU"),
                            prop: "portugal",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: _vm.isEdit,
                              placeholder: _vm.$t("roleManage.pleaseInput"),
                            },
                            model: {
                              value: _vm.roleForm.portugal,
                              callback: function ($$v) {
                                _vm.$set(_vm.roleForm, "portugal", $$v)
                              },
                              expression: "roleForm.portugal",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { spam: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("roleManage.Note"),
                            prop: "remark",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("roleManage.pleaseInput"),
                              type: "textarea",
                            },
                            model: {
                              value: _vm.roleForm.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.roleForm, "remark", $$v)
                              },
                              expression: "roleForm.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { spam: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("roleManage.roleType") } },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.roleType,
                                callback: function ($$v) {
                                  _vm.roleType = $$v
                                },
                                expression: "roleType",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("roleManage.default")))]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 2 },
                              model: {
                                value: _vm.roleType,
                                callback: function ($$v) {
                                  _vm.roleType = $$v
                                },
                                expression: "roleType",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("roleManage.onlySite")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { spam: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("roleManage.selectPermissions"),
                          },
                        },
                        [
                          _vm.permissionTreeShow
                            ? _c("permission-tree", {
                                ref: "permissionTreeRef",
                                attrs: {
                                  "permission-tree-pop": _vm.permissionTreePop,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { spam: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.characterPermission"),
                          },
                        },
                        [
                          _vm.permissionTreeShow
                            ? _c("StrPermissionTree", {
                                ref: "permissionTreeRefStr",
                                attrs: {
                                  "permission-tree-pop": _vm.permissionTreePop,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-search",
                    size: "small",
                    type: "primary",
                  },
                  on: { click: _vm.submit },
                },
                [_vm._v(_vm._s(_vm.$t("roleManage.confirm")))]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-refresh",
                    size: "small",
                    type: "warning",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeRoleOperationDialog")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("roleManage.cancel")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }