var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "warehousingBox" }, [
    _c("div", { staticClass: "warehousingClass" }, [
      _c(
        "div",
        { staticClass: "changeClass" },
        [
          _c(
            "div",
            { staticClass: "scanClass scanRecords" },
            [
              _c("span", { staticStyle: { display: "block" } }, [
                _vm._v(_vm._s(_vm.$t("operatingPlatform.warehousingScan"))),
              ]),
              _c(
                "el-input",
                {
                  staticClass: "iconBtn",
                  attrs: {
                    placeholder: _vm.$t("operatingPlatform.PleaseScan"),
                    "prefix-icon": "el-icon-full-screen",
                    clearable: "",
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.addTable($event)
                    },
                  },
                  model: {
                    value: _vm.warehousingScan,
                    callback: function ($$v) {
                      _vm.warehousingScan =
                        typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "warehousingScan",
                  },
                },
                [
                  _c("template", { slot: "append" }, [
                    _vm._v(_vm._s(_vm.$t("collectionCenter.scanned"))),
                    _c("span", [_vm._v(_vm._s(_vm.scanTableData.length))]),
                    _vm._v(_vm._s(_vm.$t("collectionCenter.ticket"))),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "scanClass" },
            [
              _c("span", { staticStyle: { display: "block" } }, [
                _vm._v(_vm._s(_vm.$t("operatingPlatform.weighingMethod"))),
              ]),
              _c(
                "el-radio-group",
                {
                  on: { input: _vm.DeviceSetup, change: _vm.radioChange },
                  model: {
                    value: _vm.weighingMode,
                    callback: function ($$v) {
                      _vm.weighingMode = $$v
                    },
                    expression: "weighingMode",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("operatingPlatform.Other"))),
                  ]),
                  _c("el-radio-button", { attrs: { label: 2 } }, [
                    _vm._v(_vm._s(_vm.$t("operatingPlatform.SerialPort"))),
                  ]),
                  _c("el-radio-button", { attrs: { label: 3 } }, [
                    _vm._v(_vm._s(_vm.$t("operatingPlatform.racerDevices"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.weighingMode === 2,
                  expression: "weighingMode===2",
                },
              ],
              staticClass: "scanClass",
            },
            [
              _c("span", { staticStyle: { display: "block" } }, [
                _vm._v(
                  _vm._s(_vm.$t("operatingPlatform.weighingEquipmentBrand"))
                ),
              ]),
              _c(
                "el-radio-group",
                {
                  on: { input: _vm.setupElectronicScales },
                  model: {
                    value: _vm.equipmentBrand,
                    callback: function ($$v) {
                      _vm.equipmentBrand = $$v
                    },
                    expression: "equipmentBrand",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "TSC-L" } }, [
                    _vm._v("TSC-L"),
                  ]),
                  _c("el-radio-button", { attrs: { label: "PRIX-3FIT" } }, [
                    _vm._v("PRIX-3FIT"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "scanClass" },
            [
              _c("span", { staticStyle: { display: "block" } }, [
                _vm._v(_vm._s(_vm.$t("operatingPlatform.inputWeight"))),
              ]),
              _c("el-input", {
                ref: "inputRef",
                staticClass: "warehousingKGClass",
                attrs: {
                  placeholder: _vm.$t("operatingPlatform.pleaseInput"),
                  clearable: "",
                  disabled: _vm.weighingMode === 2,
                },
                on: { blur: _vm.checkNumber },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.addTable($event)
                  },
                },
                model: {
                  value: _vm.warehousingKG,
                  callback: function ($$v) {
                    _vm.warehousingKG = $$v
                  },
                  expression: "warehousingKG",
                },
              }),
              _vm._v(" kg "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isSuc,
                      expression: "!isSuc",
                    },
                  ],
                  staticClass: "warehousingKGTitle",
                },
                [_vm._v(_vm._s(_vm.$t("operatingPlatform.inputNumberHint")))]
              ),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isSuc,
                    expression: "isSuc",
                  },
                ],
                staticClass: "warehousingKGTitle",
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "scanClass" },
            [
              _c("span", { staticStyle: { display: "block" } }, [
                _vm._v(_vm._s(_vm.$t("operatingPlatform.Comprimento"))),
              ]),
              _c("el-input", {
                staticClass: "volume",
                attrs: {
                  placeholder: _vm.$t("operatingPlatform.long"),
                  clearable: "",
                },
                on: { blur: _vm.checkVolume },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.addTable($event)
                  },
                },
                model: {
                  value: _vm.long,
                  callback: function ($$v) {
                    _vm.long = $$v
                  },
                  expression: "long",
                },
              }),
              _vm._v("x "),
              _c("el-input", {
                staticClass: "volume",
                attrs: {
                  placeholder: _vm.$t("operatingPlatform.width"),
                  clearable: "",
                },
                on: { blur: _vm.checkVolume },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.addTable($event)
                  },
                },
                model: {
                  value: _vm.width,
                  callback: function ($$v) {
                    _vm.width = $$v
                  },
                  expression: "width",
                },
              }),
              _vm._v("x "),
              _c("el-input", {
                staticClass: "volume",
                attrs: {
                  placeholder: _vm.$t("operatingPlatform.height"),
                  clearable: "",
                },
                on: { blur: _vm.checkVolume },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.addTable($event)
                  },
                },
                model: {
                  value: _vm.height,
                  callback: function ($$v) {
                    _vm.height = $$v
                  },
                  expression: "height",
                },
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLong || !_vm.isWidth || !_vm.isHeight,
                      expression: "!isLong || !isWidth || !isHeight",
                    },
                  ],
                  staticClass: "warehousingKGTitle",
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("collectionCenter.enterNumber3significantDecimals")
                    )
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "onlyClickOnce",
              attrs: { icon: "el-icon-upload2" },
              on: { click: _vm.bagSealing },
            },
            [_vm._v(_vm._s(_vm.$t("collectionCenter.SealingBags")))]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tableClass" },
        [
          _c(
            "el-table",
            {
              attrs: { "max-height": 600, data: _vm.scanTableData, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "scanWaybillNumber",
                  align: "center",
                  label: _vm.$t("operatingPlatform.waybillNumber"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "length",
                  align: "center",
                  label: _vm.$t("operatingPlatform.long"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "width",
                  align: "center",
                  label: _vm.$t("operatingPlatform.width"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "height",
                  align: "center",
                  label: _vm.$t("operatingPlatform.height"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "weight",
                  align: "center",
                  label: _vm.$t("operatingPlatform.scanWeight"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "volumeWeight",
                  align: "center",
                  label: _vm.$t("operatingPlatform.volumeWeight"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "scanTime",
                  align: "center",
                  label: _vm.$t("operatingPlatform.scanTime"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "operator",
                  align: "center",
                  label: _vm.$t("operatingPlatform.operator"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: _vm.$t("collectionCenter.operation"),
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticStyle: { cursor: "pointer" } },
                          [
                            _c(
                              "el-popconfirm",
                              {
                                attrs: {
                                  title: _vm.$t("collectionCenter.deleteSure"),
                                },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.delRow(scope.row, scope.$index)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#da0014",
                                      "font-size": "15px",
                                    },
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("collectionCenter.Delete"))
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      [
        _c(
          "el-dialog",
          {
            attrs: { visible: _vm.dialogVisible, width: "50%" },
            on: {
              "update:visible": function ($event) {
                _vm.dialogVisible = $event
              },
              close: _vm.handleClose,
            },
          },
          [
            _c(
              "div",
              { staticClass: "bagBox" },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("collectionCenter.bagsNumber")) + " "
                ),
                _c("el-input", {
                  attrs: {
                    placeholder: _vm.$t("operatingPlatform.inputNumbers"),
                    minlength: "6",
                    maxlength: "8",
                    "show-word-limit": "",
                    clearable: "",
                  },
                  model: {
                    value: _vm.bagNumber,
                    callback: function ($$v) {
                      _vm.bagNumber = typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "bagNumber",
                  },
                }),
                _c(
                  "el-checkbox",
                  {
                    staticStyle: { "margin-left": "10px" },
                    on: { change: _vm.choose },
                    model: {
                      value: _vm.checked,
                      callback: function ($$v) {
                        _vm.checked = $$v
                      },
                      expression: "checked",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("receivablePayable.SystemGenerated")))]
                ),
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c("el-button", { on: { click: _vm.handleClose } }, [
                  _vm._v(_vm._s(_vm.$t("basicData.Cancel"))),
                ]),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.submitBagSealing },
                  },
                  [_vm._v(_vm._s(_vm.$t("basicData.determine")))]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
      },
      [
        _c("audio", {
          ref: "errRef",
          attrs: { src: _vm.errorMp3, controls: "controls" },
        }),
        _c("audio", {
          ref: "okRef",
          attrs: { src: _vm.okMp3, controls: "controls" },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }