var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "boxs" },
    [
      _c(
        "el-timeline",
        { attrs: { reverse: true } },
        [
          _vm._l(_vm.list, function (item) {
            return [
              _c(
                "el-timeline-item",
                {
                  key: item.id,
                  attrs: { timestamp: item.newTime, placement: "top" },
                },
                [
                  item.followUpStatus === 0
                    ? _c(
                        "el-button",
                        {
                          staticClass: "mb",
                          attrs: { type: "primary", size: "small", round: "" },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("WorkCentreTable.NormalFollowUp"))
                          ),
                        ]
                      )
                    : item.followUpStatus === 1
                    ? _c(
                        "el-button",
                        {
                          staticClass: "mb",
                          attrs: { type: "success", size: "small", round: "" },
                        },
                        [_vm._v(_vm._s(_vm.$t("WorkCentreTable.FinishTask")))]
                      )
                    : _c(
                        "el-button",
                        {
                          staticClass: "mb",
                          attrs: { type: "danger", size: "small", round: "" },
                        },
                        [_vm._v(_vm._s(_vm.$t("WorkCentreTable.VoidTask")))]
                      ),
                  _c("el-card", [
                    _c("div", { staticClass: "comment-class" }, [
                      _c("span", {
                        staticClass: "comment-content-class",
                        domProps: { innerHTML: _vm._s(item.content) },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.getImgSrc($event)
                          },
                        },
                      }),
                      item.followerStr.length !== 0
                        ? _c("p", { staticClass: "follower" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("WorkCentreTable.executeUserName")
                              ) +
                                "： " +
                                _vm._s(item.followerStr)
                            ),
                          ])
                        : _vm._e(),
                      _c("p", { staticClass: "row" }, [
                        _vm._v(
                          _vm._s(item.createUser) +
                            " " +
                            _vm._s(_vm.$t("WorkCentreTable.SubmittedOn")) +
                            " " +
                            _vm._s(item.newTime)
                        ),
                      ]),
                    ]),
                  ]),
                  item.contentTranslate.length !== 0
                    ? _c(
                        "el-card",
                        [
                          _c(
                            "el-divider",
                            { attrs: { "content-position": "left" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("WorkCentreTable.ContentTranslation")
                                )
                              ),
                            ]
                          ),
                          _c("div", {
                            staticClass: "content",
                            domProps: {
                              innerHTML: _vm._s(item.contentTranslate),
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
      _vm.list.length === 0
        ? _c("div", { staticClass: "no_date" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("WorkCentreTable.NoContent")))]),
          ])
        : _vm._e(),
      _c("el-image-viewer", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showViewer,
            expression: "showViewer",
          },
        ],
        attrs: {
          "on-close": _vm.closeViewer,
          "url-list": _vm.imgArr,
          "z-index": 9999,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }