
/**
 * 谷歌翻译
 */

import translate from 'translate';
// import translate from 'translate-google';

// const MAX_LEN = 150; // 字符串最大长度

// const getParseText = (html) => {
//   const reg = new RegExp('<.+?>(.*?)</.+?>', 'g');
//   let flag = false;
//   const msg = html.replace(reg, (all, tag, content) => {
//     if (tag === 'strong') {
//       return content;
//     } else {
//       const res = content.replace(new RegExp('<.+?>', 'g'), '');
//       flag = true;
//       return res;
//     }
//   });

//   if (flag) {
//     return msg;
//   } else {
//     const res = html.replace(new RegExp('<.+?>', 'g'), '');
//     return res;
//   }
// };

// 使用正则提取html中的内容
// function extractText(html) {
//   const reg = new RegExp('<.+?>(.*?)</.+?>', 'g');
//   const msg = html.replace(reg, (all, content) => {
//     return getParseText(content);
//   });

//   const res = msg.replace(new RegExp('<.+?>', 'g'), '');
//   console.log(res);

//   const arr = res.split('\n').map(item => {
//     return item.trim();
//   }).filter(item => item.length !== 0);

//   console.log(arr);
// }

// 单行翻译
function translateSingleText(text, to, from) {
  return translate(text, { to, from }).then(res => {
    console.log(res);
    return res;
  }).catch(err => {
    console.error(err);
  });
}

export {
  translateSingleText
};
