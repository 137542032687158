var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Card",
    { staticClass: "container", attrs: { width: "100%" } },
    [
      _c("div", { staticClass: "container-header" }, [
        _c("h2", { staticClass: "header-2 m-t-20" }, [
          _vm._v(_vm._s(_vm.$t("dashboard.storeVisits"))),
        ]),
        _c("div", { staticClass: "legends" }, [
          _c("p", { staticClass: "label-1" }, [
            _vm._v(
              _vm._s(_vm.$t("dashboard.visitedStores")) +
                ": " +
                _vm._s(_vm.dataVisitedStore[0])
            ),
          ]),
          _c("p", { staticClass: "label-2" }, [
            _vm._v(
              _vm._s(_vm.$t("dashboard.unvisitedStores")) +
                ": " +
                _vm._s(_vm.dataVisitedStore[1])
            ),
          ]),
        ]),
      ]),
      _c("apexchart", {
        attrs: {
          type: "donut",
          width: "400",
          options: _vm.chartOptions,
          series: _vm.dataVisitedStore,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }