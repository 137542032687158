<template>
  <!-- 客户选择器 -->
  <ElSelectV2
    v-model="value"
    :multiple="multiple"
    :clearable="clearable"
    :multiple-limit="multipleLimit"
    :loading="loading"
    :options="list"
    filterable
    :placeholder="$t('operationCenter.PleaseSelect')"
    @change="onChange"
  />
</template>

<script>
import ElSelectV2 from 'el-select-v2';
import { apiCustomerPage } from '@/api/customer.js';

const QueryCustomerV2Cmp = 'QueryCustomerV2Cmp';

const PARAM = {
  pageNumber: 1,
  pageSize: 9999999,
  params: {
    quickSearch: ''
  }
};

export default {
  name: '',

  components: {
    ElSelectV2
  },

  props: {

    // 是否多选
    multiple: {
      type: Boolean,
      default: false
    },

    // 是否可以清空选项
    clearable: {
      type: Boolean,
      default: true
    },

    // 多选时用户最多可以选择的项目数，为 0 则不限制
    multipleLimit: {
      type: Number,
      default: 0
    },

    // id 用于回显
    val: {
      type: [Array, String, Number],
      default: () => []
    }
  },

  data() {
    return {
      loading: false,
      list: [],
      value: [],
      isOne: true
    };
  },

  watch: {
    val: {
      handler(val) {
        // this.value = [...this.val];
        if (Array.isArray(val)) {
          this.value = [...val];
          return;
        }
        this.value = val;

        // 在编辑操作，第一次初始化的时候主动触发一次事件，便于在编辑操作的时候回调
        // console.log('val', val, this.isOne);
      },
      immediate: true
    }
    // val(val) {
    //   // this.value = [...this.val];
    //   if (Array.isArray(val)) {
    //     this.value = [...val];
    //     return;
    //   }
    //   this.value = val;
    // }
  },

  created() {
    const list = window.sessionStorage.getItem(QueryCustomerV2Cmp);
    if (list) {
      // this.onChange();
      this.list = JSON.parse(list).map(item => {
        return Object.freeze(item);
      });

      this.trigger();
      return;
    }
    setTimeout(() => {
      this.getVendorList();
    }, 100);
  },

  methods: {
    // 获取客户分页列表
    getVendorList() {
      this.loading = true;
      this.list = [];
      apiCustomerPage(PARAM, false).then(res => {
        if (res.status === 'OK') {
          const arr = (res.data.records || []).map(item => {
            const { id, customerAlias, username, billingMethod } = item;
            const obj = {
              value: id,
              label: `${customerAlias}(${username})`,
              billingMethod, // 报价方式
              customerAlias // 客户别名
            };

            return Object.freeze(obj);
          });

          window.sessionStorage.setItem(QueryCustomerV2Cmp, JSON.stringify(arr));

          this.list = arr;

          this.trigger();
          // this.onChange();
        }
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },

    onChange(val) {
      this.$emit('change', val);

      const row = this.list.find(item => item.value === val);
      if (row) {
        this.$emit('row', row);
      }
    },

    // 主动触发一次事件
    trigger() {
      if (this.val && this.isOne) {
        // console.log('val', val);

        this.isOne = false;
        this.onChange(this.val);
      }
    }
  }

};
</script>

<style scoped>
</style>
