var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("newOrder.order"), name: "1" } },
            [
              _c(
                "el-card",
                { staticStyle: { "margin-bottom": "20px" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("newOrder.BasicInformation"))),
                      ]),
                    ]
                  ),
                  _c("BasicsCmp", { ref: "claimBasicsRef" }),
                ],
                1
              ),
              _c(
                "el-card",
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("ClaimsManagement.LinkedOrder"))),
                      ]),
                    ]
                  ),
                  _c("OrderCmp", {
                    ref: "claimOrderRef",
                    attrs: { ids: _vm.ids },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("ClaimsManagement.ClaimSettlementProcess"),
                name: "2",
              },
            },
            [_c("HistoryCmp", { ref: "claimHistoryRef" })],
            1
          ),
        ],
        1
      ),
      _vm.activeName === "1"
        ? _c("div", { staticClass: "footer" }, [
            _c("div"),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function () {
                        return _vm.onClose(false)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel")))]
                ),
                _vm.isCloseBut
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "danger" },
                        on: { click: _vm.onAddClose },
                      },
                      [_vm._v(_vm._s(_vm.$t("GlobalSetObj.close")))]
                    )
                  : _vm._e(),
                _vm.isSubmitBut
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.paymentRow.isBool = true
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("ClaimsManagement.SubmitPaymentRequest")
                          )
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.isExamineBut
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.examineRow.isBool = true
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("customerManagements.Approval")))]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("GlobalSetObj.tips"),
            visible: _vm.paymentRow.isBool,
            width: "30%",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.paymentRow, "isBool", $event)
            },
          },
        },
        [
          _c("PaymentCmp", {
            attrs: { ids: _vm.ids },
            on: { close: _vm.onPaymentClose },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("GlobalSetObj.tips"),
            visible: _vm.examineRow.isBool,
            width: "30%",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.examineRow, "isBool", $event)
            },
          },
        },
        [
          _c("ExamineCmp", {
            attrs: { ids: _vm.ids },
            on: { close: _vm.onExamineClose },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }