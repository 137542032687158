export const dashboard = {
  // index
  orderDashboard: {
    zh: '订单仪表板',
    en: 'Order Dashboard',
    pu: 'Dashboard de pedidos'
  },

  // chartBarPackageHistory
  collectionHistory: {
    zh: '收集历史',
    en: 'Collection History',
    pu: 'Históricos de coleta'
  },
  collectedPackages: {
    zh: '已收集包裹',
    en: 'Collected Packages',
    pu: 'Pacotes coletados'
  },
  uncollectedPackages: {
    zh: '未收集包裹',
    en: 'Uncollected Packages',
    pu: 'Pacotes não coletados'
  },
  jan: {
    zh: '一月',
    en: 'Jan',
    pu: 'Jan'
  },
  feb: {
    zh: '二月',
    en: 'Feb',
    pu: 'Fev'
  },
  mar: {
    zh: '三月',
    en: 'Mar',
    pu: 'Mar'
  },
  apr: {
    zh: '四月',
    en: 'Apr',
    pu: 'Abr'
  },
  may: {
    zh: '五月',
    en: 'May',
    pu: 'Mai'
  },
  jun: {
    zh: '六月',
    en: 'Jun',
    pu: 'Jun'
  },
  jul: {
    zh: '七月',
    en: 'Jul',
    pu: 'Jul'
  },
  aug: {
    zh: '八月',
    en: 'Aug',
    pu: 'Ago'
  },
  sep: {
    zh: '九月',
    en: 'Sep',
    pu: 'Set'
  },
  oct: {
    zh: '十月',
    en: 'Oct',
    pu: 'Out'
  },
  nov: {
    zh: '十一月',
    en: 'Nov',
    pu: 'Nov'
  },
  dec: {
    zh: '十二月',
    en: 'Dec',
    pu: 'Dez'
  },

  // chartPieDataPackages
  packageCollection: {
    zh: '包裹收集',
    en: 'Package Collection',
    pu: 'Coleta de pacotes'
  },

  // chartPieDataStores
  storeVisits: {
    zh: '店铺访问',
    en: 'Store Visits',
    pu: 'Visitas em lojas'
  },
  visitedStores: {
    zh: '访问过的店铺',
    en: 'Visited Stores',
    pu: 'Lojas visitadas'
  },
  unvisitedStores: {
    zh: '未访问的店铺',
    en: 'Unvisited Stores',
    pu: 'Lojas não visitadas'
  }

};
