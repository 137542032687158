var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "wrapper-form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        },
      },
    },
    [
      _c(
        "fieldset",
        { staticClass: "wapper-fieldset col-span-2" },
        [
          _c("legend", { staticClass: "legend" }, [
            _vm._v(
              _vm._s(_vm.numberEdition) +
                "° " +
                _vm._s(_vm.$t("editingRegistration.edition"))
            ),
          ]),
          _c(
            "o-field",
            {
              attrs: {
                label: _vm.$t("editingRegistration.editionCode"),
                "label-for": "codEdition",
                message: _vm.v$.codEdition.$errors.length
                  ? _vm.v$.codEdition.$errors[0].$message
                  : null,
              },
            },
            [
              _c("o-input", {
                attrs: { id: "codEdition" },
                model: {
                  value: _vm.formData.codEdition,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "codEdition", $$v)
                  },
                  expression: "formData.codEdition",
                },
              }),
            ],
            1
          ),
          _c(
            "o-field",
            {
              attrs: {
                label: _vm.$t("editingRegistration.volume"),
                "label-for": "volume",
                message: _vm.v$.volume.$errors.length
                  ? _vm.v$.volume.$errors[0].$message
                  : null,
              },
            },
            [
              _c("o-input", {
                attrs: { id: "volume" },
                model: {
                  value: _vm.formData.volume,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "volume", $$v)
                  },
                  expression: "formData.volume",
                },
              }),
            ],
            1
          ),
          _c(
            "o-field",
            {
              staticClass: "col-span-2",
              attrs: {
                label: _vm.$t("editingRegistration.description"),
                "label-for": "description",
                message: _vm.v$.description.$errors.length
                  ? _vm.v$.description.$errors[0].$message
                  : null,
              },
            },
            [
              _c("o-input", {
                attrs: { id: "description" },
                model: {
                  value: _vm.formData.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "description", $$v)
                  },
                  expression: "formData.description",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "FormActions",
        [
          _c("FormActionsPrevious", {
            attrs: { "is-first-step": _vm.isFirstStep },
            on: { handlePrevious: _vm.handlePrevious },
          }),
          _c("FormActionsNext", { attrs: { "is-last-step": _vm.isLastStep } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }