<template>
  <div :class="classObj" class="app-wrapper">
    <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
    <sidebar class="sidebar-container" />
    <div class="main-container">
      <!--是否固定  -->
      <div :class="{'fixed-header':fixedHeader}">
        <!--面包屑-->
        <navbar />
        <!-- 快捷标签页面-->
        <tags-view v-if="needTagsView" />
      </div>
      <app-main />
    </div>
    <!--  系统信息弹框  -->
    <transition name="el-zoom-in-bottom">
      <SysInfo
        v-if="getmessageShow.messageInfo"
        :key="getmessageShow.messageInfo"
        :message-obj="getmessageShow"
        :message-show="getmessageShow.messageInfo"
      />
    </transition>
  </div>
</template>

<script>
import { AppMain, Navbar, Sidebar } from './components';
import ResizeMixin from './mixin/ResizeHandler';
import TagsView from './components/TagsView';
import SysInfo from '@/components/sysInfo/index.vue';

export default {
  name: 'Layout',
  components: {
    Navbar,
    Sidebar,
    AppMain,
    TagsView,
    SysInfo
  },
  mixins: [ResizeMixin],
  data() {
    return {
      // getmessageShow: false
    };
  },
  computed: {
    needTagsView() {
      return this.$store.state.settings.tagsView;
    },
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    device() {
      return this.$store.state.app.device;
    },
    fixedHeader() {
      return this.$store.state.settings.fixedHeader;
    },
    getmessageShow() {
      return this.$store.state.user.getmessageShowObj;
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile' // 开启这在页面缩小到一定程度后就隐藏菜单栏
      };
    }
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/mixin.scss';
@import '~@/styles/variables.scss';

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.mobile .fixed-header {
  width: 100%;
}
</style>
