<template>
  <el-dialog
    :title="`${$i18n.t('ClaimsManagement.Payment')}-Bradesco`"
    :visible.sync="visible"
    :before-close="handleClose"
    width="500px"
  >
    <el-form size="small" label-position="top" :disabled="true">
      <!-- 付款批次号 -->
      <el-form-item :label="$i18n.t('receivablePayable.PaymentBatchNumber')">
        <el-input v-model="payForm.paymentBatchNumber"/>
      </el-form-item>

      <!-- 付款总金额 -->
      <el-form-item :label="$i18n.t('basicData.付款总金额')">
        <el-input v-model="payForm.feeTotal"/>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="onClose">{{ $i18n.t('GlobalSetObj.cancel') }}</el-button>
      <!-- 确定 -->
      <el-button size="small" type="primary" @click="paySumbit">{{ $i18n.t('GlobalSetObj.determine') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    'visible': {
      type: Boolean,
      default() {
        return false;
      }
    },

    'row': {
      type: Object,
      default() {
        return {};
      }
    }
  },

  watch: {
    visible(val) {
      if (val) {
        this.payForm = { ...this.row };
      }
    }
  },

  data() {
    return {
      isLoading: false,
      payForm: {
        paymentBatchNumber: '',
        feeTotal: ''
      }
    }
  },

  methods: {
    handleClose() {
      this.onClose();
    },
    onClose() {
      this.$emit('close');
    },

    paySumbit() {
      this.$emit('submint');
    }
  }
}
</script>

<style scoped lang="scss">
</style>