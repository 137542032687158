/**
 * 评分状态
 */
const evaluateTextArr = ['1分', '2分', '3分', '4分', '5分', '6分', '7分', '8分', '9分', '10分'];

/**
 * 图片
 * .jpeg jpg png gif webp
 */
const imgTypeArr = ['image/jpeg', 'image/png', 'image/gif', 'image/webp']; // 图片

/**
 * 压缩包
 * rar zip 7z
 */
const compressedTypeArr = ['application/x-rar-compressed', 'application/zip', 'application/x-7z-compressed'];

const pdfTypeArr = ['application/pdf'];
/**
 * 文本
 * pdf txt .doc .docx .ppt .pptx .xls .xlsx
 */
const fileTypeArr = [...pdfTypeArr, 'text/plain', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

/**
 * 附件上传类型
 */
const enclosureTypeArr = [...imgTypeArr, ...compressedTypeArr, ...fileTypeArr];

export {
  imgTypeArr,
  fileTypeArr,
  pdfTypeArr,
  evaluateTextArr,
  enclosureTypeArr
};
