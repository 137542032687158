<script >
import { defineProps, ref } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  queryParams: {
    type: Object,
    required: true
  },
  handleSubmitFilter: {
    type: Function,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const keyMDFE = ref('');
  function handleClearFilter() {
    keyMDFE.value = '';
    props.queryParams.keyMDFE = null;
  }
  function handleSubmit() {
    props.handleSubmitFilter(keyMDFE.value);
  }
  return {
    keyMDFE,
    handleClearFilter,
    handleSubmit
  };
};
export default __sfc_main;
</script>

<template>
  <form class="form-filters" @submit.prevent="handleSubmit">
    <o-field
      label="Chave MDFE"
      label-for="keyMDFE"
    >
      <o-input
        id="keyMDFE"
        v-model="keyMDFE"
        placeholder="Digite uma chave existente do MDFE"
      />
    </o-field>

    <!-- <o-field
      label="Vtag"
      label-for="vtag"
    >
      <o-input
        id="vtag"
        v-model="queryParams.vtag"
        placeholder="Digite uma vtag existente"
      />
    </o-field> -->

    <div class="button-actions">
      <o-button variant="outline" size="md" @click="handleClearFilter"> Limpar </o-button>
      <o-button variant="primary" size="md" native-type="submit"> Enviar </o-button>
    </div>
  </form>
</template>

<style scoped>
.form-filters {
  display: grid;
  gap: 1rem;
}

.button-actions {
  display: flex;
  justify-content: space-between;
}
</style>
