<template>
  <div class="app-container">
    <div style="margin-bottom: 20px">
      <el-form
        ref="formSearch"
        :model="queryForm"
        label-position="top"
        size="small"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item prop="timeType">
              <template slot="label" style="width:450px">
                <div class="selectLabel">
                  <el-select
                    v-model="queryForm.params.timeType"
                    :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  >
                    <el-option key="1" :label="$t('newOrder.StorageTimeOutlets')" :value="1" />
                    <el-option key="2" :label="$t('newOrder.DepositTime')" :value="2" />
                  </el-select>
                </div>
              </template>
              <!--网点入库时间-->
              <el-date-picker
                v-model="queryDateTime"
                :end-placeholder="$t('operationCenter.endDate')"
                :range-separator="$t('operationCenter.to')"
                :start-placeholder="$t('operationCenter.startDate')"
                style="width: 100%"
                type="daterange"
                value-format="yyyy-MM-dd"
                @blur="refreshDate"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--网点名称-->
            <el-form-item :label="$t('basicData.siteName')">
              <el-select
                v-model="queryForm.params.siteIdList"
                :placeholder="$t('GlobalSetObj.all')"
                clearable
                collapse-tags
                filterable
                multiple
                style="width: 100%;"
              >
                <el-option
                  v-for="item in siteList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-button
        icon="el-icon-search"
        size="small"
        type="primary"
        @click="searchResources(1)"
      >{{ $t('operationCenter.Procurar') }}
      </el-button>
      <el-button icon="el-icon-refresh-left" size="small" @click="searchResources(2)">
        {{ $t('operationCenter.reset') }}
      </el-button>
      <el-button
        size="small"
        style="margin-left: 10px"
        type="primary"
        @click="exportDateDimension"
      >{{ $t('operationCenter.exports') }}
      </el-button>
    </div>
    <el-tabs v-model="activeNameTab" @tab-click="handleClickTab">
      <!--网点遗失-->
      <el-tab-pane key="网点遗失" :label="$t('operationTips.siteLost')" name="summary">
        <el-table :data="tableData" border>
          <el-table-column
            :label="$t('GlobalSetObj.serial')"
            align="center"
            type="index"
            width="80"
          />
          <el-table-column
            v-for="(item, ind) in columnList"
            :key="ind"
            :align="item.align"
            :min-width="item.width"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot="header">
              <template>
                <!--                <div v-if="item.prop === 'presumablyLost'">-->
                <!--                  <span style="margin-right: 2px">{{ item.label }}</span>-->
                <!--                  <el-tooltip placement="top">-->
                <!--                    <div slot="content">{{ $t('orderCenterCont.TimeoutDay4') }}</div>-->
                <!--                    <svg-icon icon-class="QuestionMark" />-->
                <!--                  </el-tooltip>-->
                <!--                </div>-->
                <!--                <div v-else-if="item.prop === 'lost'">-->
                <!--                  <span style="margin-right: 2px">{{ item.label }}</span>-->
                <!--                  <el-tooltip placement="top">-->
                <!--                    <div slot="content">{{ $t('orderCenterCont.TimeoutDay4') }}</div>-->
                <!--                    <svg-icon icon-class="QuestionMark" />-->
                <!--                  </el-tooltip>-->
                <!--                </div>-->
                <div> {{ item.label }}</div>
              </template>
            </template>
            <template slot-scope="scope">
              <div
                :class="strARR.includes(item.prop) ? 'table-active' :''"
                @click="seeDetial(scope.row,item.prop,true)"
              >
                {{ scope.row[item.prop] || 0 }}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            :current-page.sync="queryForm.pageNumber"
            :page-size.sync="queryForm.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            style="margin: 10px 0"
            @current-change="getCurrentTableData"
            @size-change="getSizeTableData"
          />
        </div>
      </el-tab-pane>
      <!--分拨遗失-->
      <el-tab-pane key="分拨遗失" :label="$t('operationTips.fenboLost')" name="dateDimension">
        <el-table :data="tableData" border>
          <el-table-column
            :label="$t('GlobalSetObj.serial')"
            align="center"
            type="index"
            width="80"
          />
          <el-table-column
            v-for="(item, ind) in columnList1"
            :key="ind"
            :align="item.align"
            :min-width="item.width"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot="header">
              <template>
                <!--                <div v-if="item.prop === 'presumablyLost'">-->
                <!--                  <span style="margin-right: 2px">{{ item.label }}</span>-->
                <!--                  <el-tooltip placement="top">-->
                <!--                    <div slot="content">{{ $t('orderCenterCont.TimeoutDay4') }}</div>-->
                <!--                    <svg-icon icon-class="QuestionMark" />-->
                <!--                  </el-tooltip>-->
                <!--                </div>-->
                <!--                <div v-else-if="item.prop === 'lost'">-->
                <!--                  <span style="margin-right: 2px">{{ item.label }}</span>-->
                <!--                  <el-tooltip placement="top">-->
                <!--                    <div slot="content">{{ $t('orderCenterCont.TimeoutDay4') }}</div>-->
                <!--                    <svg-icon icon-class="QuestionMark" />-->
                <!--                  </el-tooltip>-->
                <!--                </div>-->
                <div> {{ item.label }}</div>
              </template>
            </template>
            <template slot-scope="scope">
              <div
                :class="strARR.includes(item.prop) ? 'table-active' :''"
                @click="seeDetial(scope.row,item.prop,false)"
              >
                {{ scope.row[item.prop] || 0 }}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            :current-page.sync="queryForm.pageNumber"
            :page-size.sync="queryForm.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            style="margin: 10px 0"
            @current-change="getCurrentTableData"
            @size-change="getSizeTableData"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 包裹详情-->
    <el-drawer
      :title="$t('orderCenterCont.packageMsg')"
      :visible.sync="dialogVisible"
      center
      direction="ltr"
      size="1000px"
      @close="closeDrawer"
    >
      <packageDetail
        :key="nowTime"
        ref="votesDetails"
        :dataobj="dataObj"
        :query-date-time="queryDateTime"
      />
    </el-drawer>
  </div>
</template>

<script>
import { lostMonitor, lostMonitorExport } from '@/api/finance/basicData';
import { selectInfoByStateTwo } from '@/api/orderApi';
import dayjs from 'dayjs';

export default {
  name: 'LossRateMonitoring',
  components: {
    'packageDetail': () => import('./component/packageDetail')
  },
  data() {
    return {
      dialogVisible: false, // 详情弹窗
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          siteIdList: [],
          beginDate: '',
          endDate: '',
          dataType: 1,
          timeType: 1
        }
      },
      tableData: [],
      total: 0,
      activeNameTab: 'summary',
      columnList: [
        // 网点入库时间
        { label: this.$t('newOrder.StorageTimeOutlets'), prop: 'showDate', width: 180 },
        // 网点名称
        { label: this.$t('basicData.siteName'), prop: 'siteName', width: 180 },
        // 网点入库总票数
        { label: this.$t('basicData.zongpianshu'), prop: 'total', width: 180 },
        // 已签收票数
        { label: this.$t('operationTips.singedNumber'), prop: 'received', width: 180 },
        // 疑似丢失
        { label: this.$t('operationTips.yisiLost'), prop: 'presumablyLost', width: 180 },
        // 丢失
        { label: this.$t('operationTips.Lost'), prop: 'lost', width: 180 }
      ],
      columnList1: [
        // 到件时间
        { label: this.$t('newOrder.DepositTime'), prop: 'showDate', width: 180 },
        // 分拨名称
        { label: this.$t('operationTips.fenboNome'), prop: 'siteName', width: 180 },
        // 到件量
        { label: this.$t('operationTips.arrivedNumber'), prop: 'total', width: 180 },
        // 疑似丢失
        { label: this.$t('operationTips.yisiLost'), prop: 'presumablyLost', width: 180 },
        // 丢失
        { label: this.$t('operationTips.Lost'), prop: 'lost', width: 180 }
      ],
      queryDateTime: [this.getAWeek().startDate, this.getAWeek().endDate],
      siteList: [],
      dataObj: {},
      strARR: ['total', 'received', 'presumablyLost', 'lost'],
      nowTime: new Date().getTime()
    };
  },
  created() {
    this.getOperationState();
    this.getTableData(1);
  },
  methods: {
    // 获取加盟商时效统计列表
    getTableData(val) {
      if (this.queryDateTime && this.queryDateTime.length > 0) {
        this.queryForm.params.beginDate = this.queryDateTime[0];
        this.queryForm.params.endDate = this.queryDateTime[1];
      } else {
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
        return;
      }
      let loading = null;
      if (val === 1) {
        loading = this.$loading({
          fullscreen: true,
          lock: true,
          spinner: 'el-icon-loading',
          text: 'loading...',
          background: 'rgba(250, 250, 250, 0.6)'
        });
      }
      // 网点遗失
      if (this.activeNameTab === 'dateDimension') {
        this.queryForm.params.timeType = 2;
        lostMonitor(this.queryForm).then(res => {
          if (res.status === 'OK') {
            this.total = res.data.total;
            this.tableData = res.data.records;
            this.tableData.forEach(item => {
              item.dataDate = item.dataDate || '--';
            });
            if (val === 1) {
              loading.close();
            }
          }
        }).catch(() => {
          if (val === 1) {
            loading.close();
          }
        });
      } else {
        this.queryForm.params.timeType = 1;
        // 分拨遗失
        lostMonitor(this.queryForm).then(res => {
          if (res.status === 'OK') {
            this.tableData = res.data.records;
            this.total = res.data.total;
            this.tableData.forEach(item => {
              item.dataDate = item.dataDate || '--';
            });
            if (val === 1) {
              loading.close();
            }
          }
        }).catch(() => {
          if (val === 1) {
            loading.close();
          }
        });
      }
    },
    getOperationState() {
      const data = {};
      selectInfoByStateTwo(data).then((res) => {
        if (res.status === 'OK') {
          this.siteList = res.data.siteList;
        }
      });
    },
    // 日期维度导出
    exportDateDimension() {
      const data = {
        beginDate: '',
        endDate: '',
        dataType: this.queryForm.params.dataType,
        siteIdList: this.queryForm.params.siteIdList
      };
      if (this.queryDateTime && this.queryDateTime.length > 0) {
        data.beginDate = this.queryDateTime[0];
        data.endDate = this.queryDateTime[1];
      }
      // 网点遗失
      if (this.activeNameTab === 'summary') {
        this.queryForm.params.timeType = 1;
        lostMonitorExport(data).then(res => {
          if (res.status === 'OK') {
            const h = this.$createElement;
            this.$msgbox({
              // 提示
              title: this.$t('collectionCenter.tips'),
              message: h('p', null, [
                // 导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载,
                h('span', this.$t('collectionCenter.ExportSuccessful')),
                // 请勿重复导出!
                h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
              ]),
              showCancelButton: true,
              // 立即前往
              confirmButtonText: this.$t('collectionCenter.Immediately'),
              // 关闭
              cancelButtonText: this.$t('collectionCenter.close')
            }).then(action => {
              if (action === 'confirm') {
                this.$router.push({ path: '/resource/exportList' });
              }
            });
          }
        });
      } else {
        this.queryForm.params.timeType = 2;
        lostMonitorExport(this.queryForm.params).then(res => {
          if (res.status === 'OK') {
            const h = this.$createElement;
            this.$msgbox({
              // 提示
              title: this.$t('collectionCenter.tips'),
              message: h('p', null, [
                // 导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载,
                h('span', this.$t('collectionCenter.ExportSuccessful')),
                // 请勿重复导出!
                h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
              ]),
              showCancelButton: true,
              // 立即前往
              confirmButtonText: this.$t('collectionCenter.Immediately'),
              // 关闭
              cancelButtonText: this.$t('collectionCenter.close')
            }).then(action => {
              if (action === 'confirm') {
                this.$router.push({ path: '/resource/exportList' });
              }
            });
          }
        });
      }
    },
    // 页面切换
    handleClickTab() {
      this.queryForm.pageNumber = 1;
      if (this.activeNameTab === 'summary') {
        this.queryForm.params.timeType = 1;
        this.queryForm.params.dataType = 1;
      } else {
        this.queryForm.params.timeType = 2;
        this.queryForm.params.dataType = 2;
      }
      this.getTableData();
    },
    // 搜索、重置
    searchResources(type) {
      if (type === 1) {
        this.queryForm.pageNumber = 1;
        this.getTableData();
      } else {
        this.queryForm = {
          pageSize: 10,
          pageNumber: 1,
          params: {
            siteIdList: [],
            beginDate: '',
            endDate: '',
            dataType: 1,
            timeType: 1
          }
        };
        this.activeNameTab = 'summary';
        this.queryDateTime = [this.getAWeek().startDate, this.getAWeek().endDate];
        this.getTableData();
      }
    },
    // 日期选择器失去焦点
    refreshDate() {
      this.choiceDate0 = '';
    },
    // 获取最近30天时间
    getAWeek() {
      return {
        endDate: dayjs().format('YYYY-MM-DD'),
        startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD')
      };
    },
    // 页码变化翻页
    getCurrentTableData(val) {
      this.queryForm.pageNumber = val;
      this.getTableData();
    },
    // 每页显示数量变化
    getSizeTableData(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.getTableData();
    },
    closeDrawer() {
      this.dialogVisible = false;
      this.$refs.votesDetails.resetForm1();
    },
    seeDetial(row, porp, flage) {
      console.log(row, 'LKJLK', porp, 'porp');
      if (this.strARR.includes(porp)) {
        this.dataObj = {
          detailType: porp,
          dataType: this.queryForm.params.dataType,
          siteIdList: [row.siteId]
        };
        if (flage) {
          // 网点遗失
          this.dataObj.stationId = row.deliverStationId;
          this.dataObj.searchDate = null;
        } else {
          // 分拨遗失
          this.dataObj.stationId = row.deliverStationId;
          this.dataObj.searchDate = row.date;
        }
        this.nowTime = new Date().getTime();
        this.dialogVisible = true;
      }
      console.log(row, porp);
    }
  }
};
</script>

<style scoped>
>>> .el-drawer__header {
  margin-bottom: 10px;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 20px;
  color: black;
}

.table-active {
  color: #409EFF;
  cursor: pointer;
}

.app-container >>> .selectLabel .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

.app-container >>> .selectLabel .el-input__icon {
  line-height: 28px !important;
}
</style>
