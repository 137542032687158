import { requestScripting } from '@/lib/axios';
import dayjs from 'dayjs';

import clearString from '@/utils/clearString';
import { removeNullParams } from '@/utils/removeNullParams';

export const driverService = {
  async create(data) {
    const body = this.getBody(data);

    return requestScripting({
      url: 'drivers',
      data: body,
      method: 'POST'
    });
  },
  async edit(data, id, isFormatedBody = true) {
    const body = isFormatedBody ? this.getBody(data) : data;

    return requestScripting({
      method: 'PATCH',
      data: body,
      url: `drivers/${id}`
    });
  },
  async getAllByRealName(realName) {
    return requestScripting({
      method: 'GET',
      url: `consulting/users`,
      params: removeNullParams({
        realName: realName
      })
    });
  },
  async getAll(params, signal) {
    return requestScripting({
      method: 'GET',
      url: `drivers`,
      params: removeNullParams({
        take: params?.take ?? 10,
        active: params?.active ?? 1,
        ...params
      }),
      signal
    });
  },
  async get(id) {
    return requestScripting({
      method: 'GET',
      url: `drivers/${id}`
    });
  },
  async delete(id, data) {
    return requestScripting({
      method: 'DELETE',
      url: `drivers/${id}`,
      data
    });
  },
  getBody(data) {
    return {
      'driver_name': data.name,
      'driver_document': clearString(data.document),
      'driver_license_validate': dayjs(data.licenseValidate).format('YYYY/MM/DD'),
      'driver_license': data.driverLicense,
      'driver_license_category': data.licenseType,
      'driver_address': `${data.street}, ${data.number}, ${data.neighborhood}, ${data.municipality} - ${data.state}`,
      'driver_address_number': data.number,
      'driver_address_complement': data.complement,
      'driver_address_neighborhood': data.neighborhood,
      'driver_address_municipality': data.municipality,
      'driver_state': data.state,
      'driver_country': 'BR',
      'driver_zipcode': clearString(data.zipcode),
      'driver_phone_number': `${clearString(data.phone)}`,
      'driver_license_score': parseInt(data.scoreLicense),
      'driver_score': parseInt(data.scoreDriver),
      'driver_outsourced': data.outsourced ? 1 : 0,
      'driver_is_active': 1,
      'provider_name': data.providerName,
      'driver_username': data.username
    };
  }
};

