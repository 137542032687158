var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "printBagNumber",
      staticClass: "fontSizeStyle",
      attrs: { id: "printBagNumber" },
    },
    _vm._l(_vm.paramData, function (item, index) {
      return _c("div", { key: index }, [
        _c(
          "div",
          {
            ref: "printBagNumberContent",
            refInFor: true,
            staticClass: "printBagNumberContent",
            staticStyle: {
              "page-break-before": "always",
              "break-inside": "avoid",
            },
          },
          [
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _c("img", {
                  staticStyle: { width: "100px" },
                  attrs: { src: require("@/assets/ajlogo1.svg"), alt: "tu" },
                }),
                _c(
                  "div",
                  {
                    staticStyle: { "font-size": "20px", "margin-left": "20px" },
                  },
                  [_vm._v("RELATÓRIO DE EXPEDIÇÃO-DESTINO ✖ IATAS")]
                ),
              ]
            ),
            _c(
              "table",
              {
                staticStyle: {
                  width: "100%",
                  "text-align": "left",
                  "margin-top": "15px",
                  "border-collapse": "collapse",
                },
              },
              [
                _c("tr", [
                  _c("td", [
                    _vm._v("Data: " + _vm._s(item.uploadTime || "--")),
                  ]),
                  _c("td", [
                    _vm._v("Vtag: " + _vm._s(item.vehicleTagNumber || "--")),
                  ]),
                ]),
                _vm._m(0, true),
                _c("tr", [
                  _c("td", [
                    _vm._v("Nome: " + _vm._s(item.vehicleDriver || "--")),
                  ]),
                  _c("td", { staticStyle: { "background-color": "#add88d" } }, [
                    _vm._v("Outras informações"),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("RG/CPF: " + _vm._s(item.itin || "--"))]),
                  _c("td", { attrs: { rowspan: "4" } }, [
                    _vm._v(_vm._s(item.remark || "--")),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [
                    _vm._v("Veiculo: " + _vm._s(item.vehicleTypeName || "--")),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      "Inicio Carregamento/Horário: " +
                        _vm._s(item.startLoadingTime || "--")
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      "Fim Carregamento/Horário: " +
                        _vm._s(item.endLoadingTime || "--")
                    ),
                  ]),
                ]),
                _vm._m(1, true),
                _c("tr", [
                  _c("td", [
                    _c("div", [_vm._v("Placa:")]),
                    _c("div", { staticStyle: { "text-align": "center" } }, [
                      _vm._v(_vm._s(item.vehicleNumber || "--")),
                    ]),
                    _vm._m(2, true),
                  ]),
                  _c("td", [
                    _c("div", [_vm._v("Vtag:")]),
                    _c("div", { staticStyle: { "text-align": "center" } }, [
                      _vm._v(_vm._s(item.vehicleTagNumber || "--")),
                    ]),
                    _vm._m(3, true),
                  ]),
                ]),
              ]
            ),
            _c("div", { staticStyle: { margin: "10px 0" } }, [
              _vm._v("CONTAGEM"),
            ]),
            _c(
              "table",
              {
                staticStyle: {
                  width: "100%",
                  "border-collapse": "collapse",
                  "text-align": "center",
                },
              },
              [
                _vm._m(4, true),
                _vm._l(item.siteAddressesSort01, function (item1, index1) {
                  return _c(
                    "tbody",
                    { key: index1, staticStyle: { "text-align": "center" } },
                    [
                      _c("tr", [
                        _c("td", { attrs: { rowspan: "2" } }, [
                          _vm._v(_vm._s(item1.sort || "--")),
                        ]),
                        _c("td", { staticStyle: { width: "140px" } }, [
                          _vm._v(_vm._s(item1.name || "--")),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(item1.bagPackageCount || "--")),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(item1.cargoPackageCount || "--")),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(item1.totalPackageCount || "--")),
                        ]),
                        _c("td"),
                        _c("td"),
                        _c("td"),
                      ]),
                      _c("tr", { staticStyle: { "text-align": "left" } }, [
                        _c("td", { attrs: { colspan: "5" } }, [
                          _vm._v(
                            "Endereço: " + _vm._s(item1.originAddress || "--")
                          ),
                        ]),
                      ]),
                    ]
                  )
                }),
              ],
              2
            ),
            _vm._m(5, true),
          ]
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticStyle: { "background-color": "#ffc000" } }, [
      _c("td", { attrs: { colspan: "2" } }, [
        _vm._v("DADOS MOTORISTA/ VEÍCULO:"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c(
        "td",
        {
          staticStyle: { "background-color": "#ffc000" },
          attrs: { colspan: "2" },
        },
        [_vm._v("Destino das lATAS (Expedicao/ Local)")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { display: "flex", "justify-content": "center" } },
      [_c("div", { attrs: { id: "qrCanvas" } })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { display: "flex", "justify-content": "center" } },
      [_c("div", { attrs: { id: "barcode" } })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticStyle: { "background-color": "#add88d" } }, [
        _c("th", [_vm._v("No.")]),
        _c("th", { staticStyle: { width: "140px" } }, [_vm._v("IATA")]),
        _c("th", [_vm._v("Número de sacolas")]),
        _c("th", [_vm._v("Quantidade de pacote grande")]),
        _c("th", [_vm._v("Quantidade de pacote")]),
        _c("th", [_vm._v("Tempo de descarga")]),
        _c("th", [_vm._v("Número de descargas")]),
        _c("th", [_vm._v("Confirmação da assinatura")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "space-between",
          "margin-top": "20px",
        },
      },
      [
        _c("div", { staticStyle: { "border-bottom": "1px solid #000000" } }, [
          _c("div", { staticStyle: { "margin-bottom": "30px" } }, [
            _vm._v("Nome Motorista"),
          ]),
        ]),
        _c("div", { staticStyle: { "border-bottom": "1px solid #000000" } }, [
          _c("div", { staticStyle: { "margin-bottom": "30px" } }, [
            _vm._v("Nome Anjun (Expedição Perus)"),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }