var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "box-card" },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("basicData.订单信息")))]),
          _c(
            "el-button",
            {
              staticStyle: { float: "right" },
              attrs: { type: "primary", size: "small" },
              on: {
                click: function ($event) {
                  _vm.isAdd = true
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("basicData.AddOrder")))]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, border: "", height: "300" },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "waybillNumber",
              label: _vm.$t("receivablePayable.TicketNumber"),
            },
          }),
          [3, 4].includes(_vm.queryForm.params.payableType)
            ? _c("el-table-column", {
                attrs: {
                  prop: "orderNumber",
                  label: _vm.$t("CenterForSorting.orderNumber"),
                },
              })
            : _vm._e(),
          [1, 2].includes(_vm.queryForm.params.payableType)
            ? _c("el-table-column", {
                attrs: {
                  prop: "orderNumber",
                  label: _vm.$t("receivablePayable.CollectionTrackingNumber"),
                },
              })
            : _vm._e(),
          [3, 4, 5].includes(_vm.queryForm.params.payableType)
            ? _c("el-table-column", {
                attrs: {
                  prop: "customerNumber",
                  label: _vm.$t("collectionCenter.customerNum"),
                },
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              prop: "providerName",
              label: _vm.$t("GlobalSetObj.businessEntity"),
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "siteName", label: _vm.$t("GlobalSetObj.Outlets") },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "customerAlias",
              label: _vm.$t("GlobalSetObj.client"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "chargeWeight",
              label: _vm.$t("collectionCenter.chargedWeight"),
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("orderCenterCont.AddOrder"),
            visible: _vm.isAdd,
            "close-on-click-modal": false,
            "append-to-body": true,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isAdd = $event
            },
          },
        },
        [
          _c("OrderDialog", {
            attrs: {
              "business-type": _vm.getBusinessType,
              "payable-type": _vm.payableType,
              "is-business-type-select": _vm.isBusinessTypeSelect,
            },
            on: {
              submit: _vm.onAdd,
              close: function ($event) {
                _vm.isAdd = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }