<script >
import { ref, defineProps } from '@vue/composition-api';
import { default as BaseData } from './steps/baseData';
import { default as ContactData } from './steps/contactData';
import { default as FormAddress } from '@/components/steps/formAddress';
import { useWizardForm } from '@/composable/useWizardForm';
import { useCreateBase } from '@/api/transfer/base/query';
const __sfc_main = {};
__sfc_main.props = {
  closeModal: {
    type: Function
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const STEPS_TOTAL = 3;
  const formData = ref({
    formStep01: {
      companyName: null,
      companyId: null,
      base: null,
      iata: null,
      document: null,
      stateRegistration: null,
      receivingCapacity: null,
      shippingCapacity: null,
      initialHour: null,
      finalHour: null
    },
    formStep02: {
      comercialPhone: null,
      contactName: null,
      phone: null,
      email: null
    },
    formStep03: {
      zipcode: null,
      street: null,
      number: null,
      neighborhood: null,
      municipality: null,
      state: null,
      complement: null
    }
  });
  const {
    mutate,
    isLoading
  } = useCreateBase();
  function handleSubmit() {
    const newBase = {
      ...formData.value.formStep01,
      ...formData.value.formStep02,
      ...formData.value.formStep03
    };
    mutate(newBase, {
      onSuccess() {
        props.closeModal();
      }
    });
  }
  const {
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  } = useWizardForm(formData, handleSubmit, STEPS_TOTAL);
  return {
    formData,
    isLoading,
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  };
};
__sfc_main.components = Object.assign({
  BaseData,
  ContactData,
  FormAddress
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <BaseData
      v-show="step === 0"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      :form-data.sync="formData.formStep01"
    />

    <ContactData
      v-show="step === 1"
      :form-data.sync="formData.formStep02"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
    />

    <FormAddress
      v-show="step === 2"
      :form-data.sync="formData.formStep03"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
    />

    <o-loading :full-page="true" :active.sync="isLoading" />
  </div>
</template>

