import request from '@/utils/request';

// 理赔审核规则

// 分页
export const apiOperationBigBagInfoPage = (data, isLoading = true) => {
  // return request.post('/fa/claimsReviewConfiguration/page', data);
  return request({
    method: 'POST',
    url: '/fa/claimsReviewConfiguration/page',
    data,
    isLoading // 是否显示加载中参数
  });
};

// 添加
export const apiClaimsReviewConfigurationAdd = data => {
  return request({
    method: 'POST',
    url: '/fa/claimsReviewConfiguration/add',
    data // 是否显示加载中参数
  });
};

// 修改
export const apiClaimsReviewConfigurationEdit = data => {
  return request({
    method: 'PUT',
    url: '/fa/claimsReviewConfiguration/edit',
    data // 是否显示加载中参数
  });
};

// 删除
export const apiClaimsReviewConfigurationDelete = ids => {
  return request.delete(`/fa/claimsReviewConfiguration/delete/${ids}`);
};
