import { requestScripting } from '@/lib/axios';

export const providerService = {
  async get(skip, take, providerName) {
    return requestScripting({
      method: 'GET',
      url: `providers?provider=${providerName}&skip=${skip}&take=${take}`
    });
  }
};

