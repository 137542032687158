<script >
import { ref, watch, computed } from '@vue/composition-api';
import { default as SkeletonTable } from '@/components/skeletonTable';
import { default as WrapperHandleError } from '@/components/wrapperHandleError';
import { default as SectionHeader } from '@/components/sectionHeader';
import { default as Actions } from './actions';
import { default as FormCreateDriver } from './form/formCreateDriver';
import { useModal } from '@/composable/useModal';
import { useGetAll } from '@/api/transport/driver/query';

/* show all drivers */
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const filters = ref({
    status: 1,
    cpf: null,
    name: null
  });
  const queryParams = ref({
    status: 1,
    cpf: null,
    name: null,
    currentPage: 1,
    perPage: 10
  });
  const {
    data,
    isError,
    isLoading,
    isFetched,
    error
  } = useGetAll(queryParams);
  const tableData = computed(() => {
    return data.value?.data?.result ?? data.value;
  });
  const tableTotal = computed(() => {
    return data.value?.data?.activeDrivers ?? 1;
  });
  const isLoadingTable = computed(() => {
    return isLoading.value || !isFetched.value;
  });
  watch(filters.value, newFilters => {
    if (queryParams.value.currentPage > 1) {
      queryParams.value = {
        ...newFilters,
        currentPage: 1,
        perPage: 10
      };
    } else {
      queryParams.value = {
        ...queryParams.value,
        ...newFilters
      };
    }
  });

  /* create driver */
  const {
    isOpen: isOpenModalCreate,
    handleVisibility: handleVisibilityModalCreate
  } = useModal();
  return {
    filters,
    queryParams,
    isError,
    error,
    tableData,
    tableTotal,
    isLoadingTable,
    isOpenModalCreate,
    handleVisibilityModalCreate
  };
};
__sfc_main.components = Object.assign({
  WrapperHandleError,
  SectionHeader,
  SkeletonTable,
  Actions,
  FormCreateDriver
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <WrapperHandleError :error="error" :is-error="isError">
    <SectionHeader>
      <template #header>
        <h1 class="header-1">{{ $t('driver.driverTitle') }}</h1>
        <o-button variant="primary" size="md" @click="handleVisibilityModalCreate"> {{ $t('driver.createDriver') }} </o-button>
      </template>

      <template #filter>
        <o-field :label="$t('driver.cpfLabel')" label-for="cpf" class="flex-1">
          <o-input id="cpf" v-model="filters.cpf" :placeholder="$t('driver.cpfPlaceholder')" />
        </o-field>

        <o-field :label="$t('driver.nameLabel')" label-for="name" class="flex-1">
          <o-input id="name" v-model="filters.name" :placeholder="$t('driver.namePlaceholder')" />
        </o-field>

        <o-field :label="$t('driver.statusDriver')" label-for="status" class="flex-1">
          <o-select id="status" v-model="filters.status" placeholder="Selecione o status">
            <option :value="1">{{ $t('driver.activeDrivers') }}</option>
            <option :value="0">{{ $t('driver.disabledDrivers') }}</option>
          </o-select>
        </o-field>
      </template>
    </SectionHeader>

    <SkeletonTable :is-loading="isLoadingTable">
      <o-table
        :data="tableData"
        :row-key="'id'"
        bordered
        class="space-m-y-32"
        paginated
        backend-pagination
        :total="tableTotal"
        :per-page="queryParams.perPage"
        :current-page.sync="queryParams.currentPage"
        :pagination-simple="false"
      >
        <o-table-column v-slot="props" field="driver_document" :label="$t('driver.document')" width="260">
          {{ props.row.driver_document }}
        </o-table-column>
        <o-table-column v-slot="props" field="driver_name" :label="$t('driver.nameLabel')">
          {{ props.row.driver_name }}
        </o-table-column>
        <o-table-column v-slot="props" field="licenseType" :label="$t('driver.cnhType')" width="140">
          {{ props.row.driver_license_category }}
        </o-table-column>
        <o-table-column v-slot="props" field="driver_score" :label="$t('driver.driverPoints')" width="180">
          {{ props.row.driver_score }}
        </o-table-column>
        <o-table-column v-slot="props" field="driver_outsourced" :label="$t('driver.outsourcing')" width="150">
          {{ props.row.driver_outsourced ? $t('driver.yes') : $t('driver.no') }}
        </o-table-column>

        <o-table-column label="" width="80">
          <template slot-scope="{row}">
            <Actions :status="!!filters.status" :row="row" />
          </template>
        </o-table-column>

        <template #empty>
          Sem resultados
        </template>
      </o-table>
    </SkeletonTable>

    <o-modal :active="isOpenModalCreate" width="560" @close="handleVisibilityModalCreate">
      <div>
        <h2 class="header-2">{{ $t('driver.modalTitle') }}</h2>
        <p class="paragraph">{{ $t('driver.modalParagraph') }}</p>
      </div>
      <FormCreateDriver :close-modal="handleVisibilityModalCreate" />
    </o-modal>
  </WrapperHandleError>

</template>

<style>
  .flex-1{
    flex: 1;
  }
</style>
