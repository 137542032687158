<template>
  <div>
    <el-card class="box-card" style="margin-bottom: 10px;">
      <el-form :model="queryForm" label-position="top" size="small">
        <el-row :gutter="10">
          <el-col :span="6">
            <!--操作节点-->
            <el-form-item :label="$t('Resource.OperationNode')">
              <el-select
                v-model="queryForm.params.operationNode"
                :placeholder="$t('GlobalSetObj.all')"
                clearable
                filterable
                style="width: 100%;"
              >
                <!--订单创建-->
                <el-option
                  v-for="(item, index) in operationNodeList"
                  :key="index"
                  :label="item.dictName"
                  :value="item.dictValue"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--问题件类型-->
            <el-form-item :label="$t('orderCenterCont.problemPieceType')">
              <el-select
                v-model="queryForm.params.problemTypeIdList"
                :placeholder="$t('GlobalSetObj.all')"
                clearable
                collapse-tags
                filterable
                multiple
                style="width: 100%;"
              >
                <el-option
                  v-for="(item, index) in causeProblemList"
                  :key="index"
                  :label="item.dictDescribe"
                  :value="item.problemId"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--州-->
            <el-form-item :label="$t('GlobalSetObj.Estado')">
              <el-select
                v-model="queryForm.params.state"
                :placeholder="$t('GlobalSetObj.all')"
                clearable
                filterable
                style="width: 100%;"
              >
                <el-option
                  v-for="item in stateList"
                  :key="item.id"
                  :label="item.nameEn"
                  :value="item.nameEn"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--网点-->
            <el-form-item :label="$t('GlobalSetObj.Outlets')">
              <el-select
                v-model="queryForm.params.siteId"
                :placeholder="$t('GlobalSetObj.all')"
                clearable
                filterable
                style="width: 100%"
              >
                <el-option
                  v-for="(item,index) in siteList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--状态-->
            <el-form-item :label="$t('GlobalSetObj.status')">
              <el-select
                v-model="queryForm.params.configStatus"
                :placeholder="$t('GlobalSetObj.all')"
                clearable
                filterable
                style="width: 100%"
              >
                <el-option key="all" :label="$t('GlobalSetObj.all')" :value="null" />
                <!--停用-->
                <el-option key="1" :label="$t('collectionCenter.Disable')" :value="0" />
                <!--启用-->
                <el-option key="2" :label="$t('collectionCenter.enable')" :value="1" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div style="margin-top: 27px;">
                <el-button icon="el-icon-search" type="primary" @click="searchData()">{{
                  $t('GlobalSetObj.search')
                }}
                </el-button>
                <el-button icon="el-icon-refresh-left" @click="resetForm()">{{ $t('GlobalSetObj.reset') }}</el-button>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card class="box-card">
      <div v-permit:remove="'but:system-manage:SMSConfiguration:add'" style="margin-bottom: 10px">
        <el-button
          icon="el-icon-plus"
          size="small"
          type="primary"
          @click="addData()"
        >{{ $t('GlobalSetObj.add') }}
        </el-button>
      </div>
      <el-table ref="smsConfigTable" :data="tableData" border>
        <!--序号-->
        <el-table-column
          :label="$t('basicData.serialNumber')" align="center" type="index"
          width="60"
        >
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!--创建时间-->
        <el-table-column
          v-for="(item, index) in columnData"
          :key="index"
          :align="item.align"
          :label="item.label"
          :min-width="item.width"
          :prop="item.prop"
        >
          <template slot-scope="scope">
            <div v-if="item.prop === 'configStatus'">
              <el-tag
                v-if="scope.row[item.prop] === 1"
                size="mini"
              >
                {{ $t('GlobalSetObj.enable') }}
              </el-tag>
              <el-tag
                v-else-if="scope.row[item.prop] === 0"
                size="mini"
                type="danger"
              >
                {{ $t('GlobalSetObj.stop') }}
              </el-tag>
            </div>
            <div v-else-if="item.prop === 'signType'">
              <span v-if="scope.row[item.prop] === 0">{{ $t('GlobalSetObj.Myself') }}</span>
              <span v-if="scope.row[item.prop] === 1">{{ $t('GlobalSetObj.NotMyself') }}</span>
              <span v-if="scope.row[item.prop] === 2">{{ $t('GlobalSetObj.all') }}</span>
            </div>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
        <!--操作-->
        <el-table-column
          :label="$t('orderCenterCont.operation')"
          fixed="right"
          width="100"
        >
          <template slot-scope="scope">
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <el-tooltip
                :content="$t('GlobalSetObj.edit')" class="item" effect="dark"
                placement="top"
              >
                <i class="el-icon-edit textOperator edit" @click="editData(scope.row)" />
              </el-tooltip>
              <el-tooltip
                v-show="scope.row.configStatus === 0"
                v-permit:remove="'but:system-manage:SMSConfiguration:edit'"
                :content="$t('GlobalSetObj.enable')" class="item" effect="dark"
                placement="top"
              >
                <i class="el-icon-circle-check textOperator1" @click="disableData(scope.row, 1)" />
              </el-tooltip>
              <el-tooltip
                v-show="scope.row.configStatus === 1"
                v-permit:remove="'but:system-manage:SMSConfiguration:edit'"
                :content="$t('GlobalSetObj.stop')" class="item" effect="dark"
                placement="top"
              >
                <i class="el-icon-remove-outline textOperator2" @click="disableData(scope.row, 0)" />
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          :current-page.sync="queryForm.pageNumber"
          :page-size.sync="queryForm.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="getSizeTableData"
          @current-change="getCurrentTableData"
        />
      </div>
    </el-card>
    <!--新增短信配置-->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('Resource.SMSConfiguration')"
      :visible.sync="showSMSConfiguration"
      width="500px"
      @close="cancelSMSConfiguration"
    >
      <div>
        <el-form
          :key="showSMSConfiguration"
          ref="smsConfigForm"
          :model="smsConfigForm"
          label-position="top"
          size="small"
        >
          <!--操作节点-->
          <el-form-item
            :label="$t('Resource.OperationNode')"
            :rules="[
              { required: true, message: $t('GlobalSetObj.pleaseChoose'), trigger: ['blur', 'change'] }
            ]"
            prop="operationNode"
          >
            <el-select
              v-model="smsConfigForm.operationNode"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              style="width: 100%;"
            >
              <el-option
                v-for="(item, index) in operationNodeList"
                :key="index"
                :label="item.dictName"
                :value="Number(item.dictValue)"
              />
            </el-select>
          </el-form-item>
          <!--问题件类型-->
          <el-form-item
            v-show="smsConfigForm.operationNode === 5"
            :label="$t('orderCenterCont.problemPieceType')"
            :rules="[
              { required: smsConfigForm.operationNode === 5 ? true : false, message: $t('GlobalSetObj.pleaseChoose'), trigger: ['blur', 'change'] }
            ]"
            prop="problemTypeIdList"
          >
            <el-select
              v-model="smsConfigForm.problemTypeIdList"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              multiple
              style="width: 100%;"
            >
              <el-option
                v-for="(item, index) in causeProblemList"
                :key="index"
                :label="item.dictDescribe"
                :value="item.problemId"
              />
            </el-select>
          </el-form-item>
          <!--收件类型-->
          <el-form-item
            v-show="smsConfigForm.operationNode === 4"
            :label="$t('operatingPlatform.receivingT')"
            :rules="[
              { required: smsConfigForm.operationNode === 4 ? true : false, message: $t('GlobalSetObj.pleaseChoose'), trigger: ['blur', 'change'] }
            ]"
            prop="signType"
          >
            <el-select
              v-model="smsConfigForm.signType"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              style="width: 100%;"
            >
              <el-option :key="0" :label="$t('GlobalSetObj.all')" :value="2" /><!--全部-->
              <el-option :key="1" :label="$t('GlobalSetObj.Myself')" :value="0" /><!--本人-->
              <el-option :key="2" :label="$t('GlobalSetObj.NotMyself')" :value="1" /><!--非本人-->
            </el-select>
          </el-form-item>
          <!--州-->
          <el-form-item
            :label="$t('GlobalSetObj.Estado')"
            :rules="[
              { required: true, message: $t('GlobalSetObj.pleaseChoose'), trigger: ['blur', 'change'] }
            ]"
            prop="state"
          >
            <el-select
              v-model="smsConfigForm.state"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              style="width: 100%;"
              @change="stateChange()"
            >
              <el-option
                v-for="item in stateList"
                :key="item.id"
                :label="item.nameEn"
                :value="item.nameEn"
              />
            </el-select>
          </el-form-item>
          <!--网点-->
          <el-form-item
            v-if="smsConfigForm.id"
            :label="$t('GlobalSetObj.Outlets')"
            :rules="[
              { required: true, message: $t('GlobalSetObj.pleaseChoose'), trigger: ['blur', 'change'] }
            ]"
            prop="siteId"
          >
            <el-select
              v-model="smsConfigForm.siteId"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              style="width: 100%"
            >
              <el-option
                v-for="(item,index) in siteAddList"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <!--网点-->
          <el-form-item
            v-else
            :label="$t('GlobalSetObj.Outlets')"
            :rules="[
              { required: true, message: $t('GlobalSetObj.pleaseChoose'), trigger: ['blur', 'change'] }
            ]"
            prop="siteIdList"
          >
            <el-select
              v-model="smsConfigForm.siteIdList"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              multiple
              style="width: 100%"
            >
              <el-option
                v-for="(item,index) in siteAddList"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <!--选择短信模板-->
          <el-form-item
            :label="$t('Resource.SelectSMSTemplate')"
            :rules="[
              { required: true, message: $t('GlobalSetObj.pleaseChoose'), trigger: ['blur', 'change'] }
            ]"
            prop="templateId"
          >
            <el-select
              v-model="smsConfigForm.templateId"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              style="width: 100%"
            >
              <el-option
                v-for="(item,index) in smsTemplateList"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small"
          @click="showSMSConfiguration = false"
        >{{ $t('operationCenter.Cancel') }}
        </el-button>
        <el-button
          v-permit:remove="'but:system-manage:SMSConfiguration:edit'"
          size="small"
          type="primary"
          @click="determineSMSConfiguration"
        >{{ $t('operationCenter.determine') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getLgAreaListData } from '@/api/orderApi';
import { getAllProblemType } from '@/api/lanshou';
import {
  apiSMSAdd,
  apiSMSEdit,
  apiSMSGetOperationNode,
  apiSMSGetSiteList,
  apiSMSPageQuery,
  apiSmsTemplatePage
} from '@/api/automaticSorting';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'SmsConfig',
  data() {
    return {
      activeName: 'smsConfig',
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          operationNode: null, // 操作节点
          problemTypeIdList: [], // 问题件类型
          state: null, // 州
          siteId: null, // 网点
          configStatus: null
        }
      },
      stateList: [], // 州
      siteList: [], // 网点
      siteAddList: [], // 添加网点
      causeProblemList: [], // 问题件类型
      tableData: [], // 列表数据
      total: 0,
      showSMSConfiguration: false,
      operationNodeList: [], // 操作节点
      smsTemplateList: [], // 短信模板
      smsConfigForm: {
        id: null,
        operationNode: null,
        problemTypeIdList: [],
        signType: null,
        state: null,
        siteId: null,
        siteIdList: [],
        templateId: null,
        configStatus: 1
      }
    };
  },
  computed: {
    columnData() {
      return [
        // 创建时间
        { label: this.$t('GlobalSetObj.CreationTime'), prop: 'createTime', width: '180', align: 'center' },
        // 操作节点
        { label: this.$t('Resource.OperationNode'), prop: 'operationNodeDesc', width: '180', align: 'center' },
        // 问题件类型
        { label: this.$t('orderCenterCont.problemPieceType'), prop: 'problemTypeDesc', width: '180', align: 'center' },
        // 收件类型
        { label: this.$t('operatingPlatform.receivingT'), prop: 'signType', width: '180', align: 'center' },
        // 州
        { label: this.$t('GlobalSetObj.Estado'), prop: 'state', width: '180', align: 'center' },
        // 网点
        { label: this.$t('GlobalSetObj.Outlets'), prop: 'siteName', width: '180', align: 'center' },
        // 短信内容
        { label: this.$t('collectionCenter.messageContent'), prop: 'smsTemplateContent', width: '180', align: 'center' },
        // 状态
        { label: this.$t('GlobalSetObj.status'), prop: 'configStatus', width: '180', align: 'center' },
        // 创建人
        { label: this.$t('GlobalSetObj.CreatePeople'), prop: 'createUserName', width: '180', align: 'center' },
        // 最新操作人
        { label: this.$t('customerManagements.newOptioner'), prop: 'updateUserName', width: '180', align: 'center' },
        // 最新操作时间
        { label: this.$t('customerManagements.newOptionTime'), prop: 'updateTime', width: '180', align: 'center' }
      ];
    }
  },
  watch: {
    'queryForm.params.state'() {
      this.getSiteList();
    },
    'smsConfigForm.operationNode'(val) {
      if (val !== 5) {
        this.smsConfigForm.problemTypeIdList = [];
      }
      if (val !== 4) {
        this.smsConfigForm.signType = null;
      }
    }
  },
  created() {
    this.getOperationNode(); // 获取操作节点
    this.getAddress(); // 获取州
    this.getSiteList(); // 获取网点
    this.problemTypeChange(); // 问题件类型
    this.getTableData(); // 获取列表数据
  },
  mounted() {
    this.bus.$on('addDataTemplate', () => {
      this.getSmsTemplate();
    });
  },
  methods: {
    updateTable() {
      this.$refs.smsConfigTable.doLayout();
    },
    // 获取操作节点
    getOperationNode() {
      apiSMSGetOperationNode().then(res => {
        if (res.status === 'OK') {
          this.operationNodeList = res.data;
        }
      });
    },
    // 问题件类型
    problemTypeChange() {
      getAllProblemType(1).then(res => {
        if (res.status === 'OK') {
          this.causeProblemList = res.data;
        }
      });
    },
    // 州
    getAddress() {
      const params = {
        level: 0,
        parentId: ''
      };
      getLgAreaListData(params, false).then((res) => {
        if (res.status === 'OK') {
          this.stateList = res.data;
        }
      });
    },
    // 获取网点列表
    getSiteList() {
      const data = {
        state: this.queryForm.params.state
      };
      apiSMSGetSiteList(data).then((res) => {
        if (res.status === 'OK') {
          this.siteList = res.data.siteList;
          this.queryForm.params.siteId = null;
        }
      });
    },
    // 获取短信模板
    getSmsTemplate() {
      const data = {
        pageNumber: 1,
        pageSize: 9999999,
        params: {}
      };
      apiSmsTemplatePage(data).then((res) => {
        if (res.status === 'OK') {
          this.smsTemplateList = [];
          const temId = [];
          res.data.records.map(item => {
            if (item.state !== 0) {
              if (temId.includes(item.id) === false) {
                temId.push(item.id);
                this.smsTemplateList.push(item);
              }
            }
            if (item.id === this.smsConfigForm.templateId) {
              if (temId.includes(item.id) === false) {
                temId.push(item.id);
                this.smsTemplateList.push(item);
              }
            }
          });
        }
      });
    },
    // 新增时州省变化
    stateChange() {
      this.smsConfigForm.siteId = null;
      this.smsConfigForm.siteIdList = [];
      this.getSiteListAdd();
    },
    // 编辑
    editData(row) {
      this.smsConfigForm = {
        id: row.id,
        operationNode: Number(row.operationNode),
        problemTypeIdList: row.problemTypeIdList ? JSON.parse(row.problemTypeIdList) : [],
        state: row.state,
        signType: row.signType,
        siteId: row.siteId,
        templateId: row.templateId,
        configStatus: row.configStatus
      };
      this.getSiteListAdd();
      this.getSmsTemplate(); // 获取短信模板
      this.showSMSConfiguration = true;
    },
    // 停用\启用
    disableData(row, type) {
      const data = cloneDeep(row);
      const param = {
        id: data.id,
        operationNode: data.operationNode,
        problemTypeIdList: data.problemTypeIdList ? JSON.parse(row.problemTypeIdList) : [],
        state: data.state,
        signType: row.signType,
        siteId: data.siteId,
        templateId: data.templateId,
        configStatus: type
      };
      const typeText = type === 1 ? this.$t('basicData.AreYouSureEnabled') : this.$t('ChannelManage.sureToStop');
      this.$confirm(typeText, this.$t('operationCenter.tips'), {
        confirmButtonText: this.$t('operationCenter.determine'), // '确定',
        cancelButtonText: this.$t('operationCenter.Cancel'), // '取消',
        type: 'warning'
      }).then(() => {
        apiSMSEdit(param).then(res => {
          if (res.status === 'OK') {
            this.$message({
              type: 'success',
              message: this.$t('operationCenter.TheOperationSucceeded')
            });
            this.getTableData();
          }
        });
      });
    },
    // 搜索
    searchData() {
      this.queryForm.pageNumber = 1;
      this.getTableData();
    },
    // 重置
    resetForm() {
      this.queryForm.params = {
        operationNode: null, // 操作节点
        problemTypeIdList: [], // 问题件类型
        state: null, // 州
        siteId: null, // 网点
        configStatus: null
      };
    },
    // 获取表格数据
    getTableData() {
      apiSMSPageQuery(this.queryForm).then(res => {
        if (res.status === 'OK') {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    // 新增时跟据州获取网点列表
    getSiteListAdd() {
      const data = {
        state: this.smsConfigForm.state
      };
      apiSMSGetSiteList(data).then((res) => {
        if (res.status === 'OK') {
          this.siteAddList = res.data.siteList;
        }
      });
    },
    // 新增
    addData() {
      this.getSiteListAdd();
      this.getSmsTemplate(); // 获取短信模板
      this.showSMSConfiguration = true;
    },
    // 确定新增
    determineSMSConfiguration() {
      this.$refs.smsConfigForm.validate((valid) => {
        if (valid) {
          const params = cloneDeep(this.smsConfigForm);
          if (params.id) {
            apiSMSEdit(params).then(res => {
              if (res.status === 'OK') {
                this.$message({
                  type: 'success',
                  message: this.$t('operationCenter.TheOperationSucceeded')
                });
                this.getTableData();
              }
            });
          } else {
            apiSMSAdd(params).then(res => {
              if (res.status === 'OK') {
                this.$message({
                  type: 'success',
                  message: this.$t('operationCenter.TheOperationSucceeded')
                });
                this.getTableData();
              }
            });
          }
          this.showSMSConfiguration = false;
        }
      });
    },
    // 关闭弹窗
    cancelSMSConfiguration() {
      this.smsConfigForm = {
        id: null,
        operationNode: null,
        problemTypeIdList: [],
        signType: null,
        state: null,
        siteId: null,
        siteIdList: [],
        templateId: null,
        configStatus: 1
      };
    },
    // 每页显示数量变化
    getSizeTableData(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getTableData();
    },
    // 当前页码变化
    getCurrentTableData(val) {
      this.queryForm.pageNumber = val;
      this.getTableData();
    }
  }
};
</script>

<style scoped>
.block {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.textOperator {
  font-size: 24px;
}

.edit {
  color: #409EFF;
}

.textOperator1 {
  color: #409eff;
  cursor: pointer;
  font-size: 22px;
}

.textOperator2 {
  color: #f56c6c;
  cursor: pointer;
  font-size: 22px;
}
</style>
