var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "10px" } },
    [
      _c(
        "el-tabs",
        { attrs: { type: "border-card" } },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("GlobalSetObj.details") } },
            [
              _c("detailBox", {
                attrs: { "query-track-param": _vm.queryTrackParam },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }