<script >
import { ref, computed } from '@vue/composition-api';
import { default as SkeletonTable } from '@/components/skeletonTable';
import { default as WrapperHandleError } from '@/components/wrapperHandleError';
import { default as SectionHeader } from '@/components/sectionHeader';
import { default as Actions } from './actions';
import { default as FormCreateRoute } from './form/formCreateRoute';
import { useModal } from '@/composable/useModal';
import { useGetAllRoute } from '@/api/transport/router/query';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const queryParams = ref({
    currentPage: 1,
    perPage: 10
  });
  const {
    data,
    isError,
    isLoading,
    isFetched,
    error
  } = useGetAllRoute(queryParams);
  const tableData = computed(() => {
    return data.value?.data?.result ?? data.value;
  });
  const tableTotal = computed(() => {
    return data.value?.data?.route ?? 1;
  });
  const isLoadingTable = computed(() => {
    return isLoading.value || !isFetched.value;
  });

  /* create route */
  const {
    isOpen: isOpenModalCreate,
    handleVisibility: handleVisibilityModalCreate
  } = useModal();
  return {
    queryParams,
    isError,
    error,
    tableData,
    tableTotal,
    isLoadingTable,
    isOpenModalCreate,
    handleVisibilityModalCreate
  };
};
__sfc_main.components = Object.assign({
  WrapperHandleError,
  SectionHeader,
  SkeletonTable,
  Actions,
  FormCreateRoute
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <WrapperHandleError :error="error" :is-error="isError">
    <SectionHeader>
      <template #header>
        <h1 class="header-1">
          {{ $t('routes.routesTitle') }}
        </h1>
        <o-button variant="primary" size="md" @click="handleVisibilityModalCreate">
          {{ $t('routes.createRoutesButton') }}
        </o-button>
      </template>
    </SectionHeader>

    <SkeletonTable :is-loading="isLoadingTable">
      <o-table
        :data="tableData"
        :row-key="'id'"
        bordered
        class="space-m-y-32"
        paginated
        backend-pagination
        :total="tableTotal"
        :per-page="queryParams.perPage"
        :current-page.sync="queryParams.currentPage"
        :pagination-simple="false"
      >
        <o-table-column
          v-slot="props"
          field="route_identification"
          :label="$t('routes.idRoutesLabel')"
        >
          {{ props.row.route_identification }}
        </o-table-column>

        <o-table-column label="" width="144">
          <template slot-scope="{row}">
            <Actions :row="row" />
          </template>
        </o-table-column>

        <template #empty>
          {{ $t('routes.noResults') }}
        </template>
      </o-table>
    </SkeletonTable>

    <o-modal :active="isOpenModalCreate" width="560" @close="handleVisibilityModalCreate">
      <div>
        <h2 class="header-2">{{ $t('routes.createRoutesTitle') }}</h2>
        <p class="paragraph">{{ $t('routes.createRoutesParagraph') }}</p>
      </div>
      <FormCreateRoute :close-modal="handleVisibilityModalCreate" />
    </o-modal>
  </WrapperHandleError>
</template>
