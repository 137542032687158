var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Card",
        { attrs: { width: "100%" } },
        [
          _c("h2", { staticClass: "header-2 m-t-20" }, [
            _vm._v(_vm._s(_vm.$t("dashboard.collectionHistory"))),
          ]),
          _c("apexchart", {
            attrs: {
              type: "bar",
              height: "350",
              options: _vm.chartOptions,
              series: [
                {
                  name: _vm.$t("dashboard.collectedPackages"),
                  data: _vm.collectedPackages(_vm.listDataChart),
                },
                {
                  name: _vm.$t("dashboard.uncollectedPackages"),
                  data: _vm.uncollectedPackages(_vm.listDataChart),
                },
              ],
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }