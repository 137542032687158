import { requestZipCode } from '@/lib/axios';

export const zipCodeService = {
  getInfoZipCode(zipCode) {
    return requestZipCode({
      method: 'GET',
      url: `/cep/${zipCode}`
    });
  }
};
