// element 日期范围选择使用
import dayjs from 'dayjs';
// import 'moment-timezone';
const elDatePickerOptions = {
  shortcuts: [
    {
      text: '最近一周',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit('pick', [start, end]);
      }
    },
    {
      text: '最近一个月',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit('pick', [start, end]);
      }
    },
    {
      text: '最近三个月',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit('pick', [start, end]);
      }
    }
  ]
};

const weekArr = ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];

/**
 * 日期转换 需要删除
 * @param {*} dates 当前时间戳
 */
const setDateTime = function setDateTime(dates) {
  const newDates = new Date(dates);
  const year = newDates.getFullYear().toString().padStart(4, '0');
  const month = (newDates.getMonth() + 1).toString().padStart(2, '0');
  const day = newDates.getDate().toString().padStart(2, '0');
  const weekStr = weekArr[newDates.getDay()];
  return `${year}年${month}月${day}日 ${weekStr}`;
};

const setData = function(d) {
  const date = d || new Date();
  const year = date.getFullYear().toString().padStart(4, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hour = date.getHours().toString().padStart(2, '0');
  const minute = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  return {
    year,
    month,
    day,
    hour,
    minute,
    seconds
  };
};

/**
 * 日期转换2
 */
const setDateTime2 = function() {
  // const date = new Date();
  // const year = date.getFullYear().toString().padStart(4, '0');
  // const month = (date.getMonth() + 1).toString().padStart(2, '0');
  // const day = date.getDate().toString().padStart(2, '0');
  // const hour = date.getHours().toString().padStart(2, '0');
  // const minute = date.getMinutes().toString().padStart(2, '0');
  // const seconds = date.getSeconds().toString().padStart(2, '0');
  const {
    year,
    month,
    day,
    hour,
    minute,
    seconds
  } = setData();

  return `${year}${month}${day}${hour}${minute}${seconds}`;
};

/**
 * 日期转换3
 */
const setDateTime3 = function(date) {
  // const year = date.getFullYear().toString().padStart(4, '0');
  // const month = (date.getMonth() + 1).toString().padStart(2, '0');
  // const day = date.getDate().toString().padStart(2, '0');
  // const hour = date.getHours().toString().padStart(2, '0');
  // const minute = date.getMinutes().toString().padStart(2, '0');
  // const seconds = date.getSeconds().toString().padStart(2, '0');
  const {
    year,
    month,
    day,
    hour,
    minute,
    seconds
  } = setData(date);
  return `${year}-${month}-${day} ${hour}:${minute}:${seconds}`;
};

/**
 * 返回两个时间的相隔时间
 * @param {*} time1 当前时间戳
 * @param {*} time2 截至日期时间戳
 * @returns
 */
const getSurplusTime = function getSurplusTime(time1, time2) {
  const timeVal = (time2 - time1) / 1000;
  if (timeVal > 0) {
    const hour = Math.floor(timeVal / 60 / 60);
    const minute = Math.floor((timeVal - (hour * 60 * 60)) / 60);
    if (hour) {
      return `${hour}小时${minute}分`;
    } else {
      return `${minute}分`;
    }
  }
  return '';
};

/**
 * 日期转换
 * @param {*} dates 当前时间戳
 */
const setDateTime4 = function setDateTime(dates) {
  const newDates = new Date(dates);
  const year = newDates.getFullYear().toString().padStart(4, '0');
  const month = (newDates.getMonth() + 1).toString().padStart(2, '0');
  const day = newDates.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

/**
 * 字符串转时间戳
 * 2022-03-02 15:52:41 --> 12346548676541
 */
function date2TimeStamp(str) {
  const dP1 = /^(\d{4})-(\d{1,2})-(\d{1,2}) (\d{1,2}):(\d{1,2}):(\d{1,2})$/;
  if (!dP1.test(str)) {
    throw new Error('请传入正确的时间格式。如：2022-03-02 15:52:41');
  }

  let time = 0; // 时间戳

  str.replace(dP1, (res, $1, $2, $3, $4, $5, $6) => {
    // console.log(res);
    // console.log($1, $2, $3, $4, $5, $6);
    time = new Date($1, $2 - 1, $3, $4, $5, $6).getTime();
    // console.log(a);
    return '';
  });
  return time;
}

/**
 * @description 转换时间格式为巴西
 * @param data 日期
 * @param type 格式类型 1：YYYY-MM-DD 2：YYYY-MM-DD HH:MM:SS
 */
function convertBrazilianTimeFormat(date, type = 2) {
  console.log(date);
  if (date) {
    if (type === 1) {
      return dayjs(date).format('DD-MM-YYYY');
    } else {
      return dayjs(date).format('DD-MM-YYYY HH:MM:SS');
    }
  } else {
    return date;
  }
}
// excel导入日期格式转换
// 导入时间格式化
const formatDateExcel = (numb, format1, format2) => {
  const old = numb - 1;
  if (isNaN(old) === true) {
    return true;
  }
  const t = Math.round((old - Math.floor(old)) * 24 * 60 * 60);
  const time = new Date(1900, 0, old, 0, 0, t);
  const year = time.getFullYear();
  const month = time.getMonth() + 1;
  const date = time.getDate();
  const HH = time.getHours();
  const MM = time.getMinutes();
  const ss = time.getSeconds();
  return year + format1 + (month < 10 ? '0' +
      month : month) + format1 + (date < 10 ? '0' + date : date) +
    ' ' + (HH < 10 ? '0' + HH : HH) + format2 +
    (MM < 10 ? '0' + MM : MM) + format2 + (ss < 10 ? '0' + ss : ss);
};

/**
 * @description: 获取周期，例如2024-06 周期1
 * 总是需要 8 个周期，不管当前日期。
 * 如果当前日期超过 20 号，当月只有第一个周期。
 * 如果当前日期不超过 20 号，当月没有任何周期。
 * 每个月（除了当月可能只有第一周期）都有两个周期。
 * @param currentDate 时间戳或 ISO 日期字符串
 * @param totalPeriods 需要的周期数
 * @param text1 周期 1 的标签
 * @param text2 周期 2 的标签
 */
const generatePeriods = (currentDate, totalPeriods, text1, text2) => {
  // 现在 currentDate 可以是时间戳（毫秒数）或 ISO 日期字符串
  const date = new Date(currentDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // getMonth() 返回 0-11，所以加1
  const day = date.getDate();

  const periods = [];
  let targetMonth = month;
  let targetYear = year;

  // 辅助函数：确保月份是两位数
  const formatMonth = (month) => month.toString().padStart(2, '0');

  // 如果当前日期超过 20 号，添加当月的第一个周期
  if (day > 20) {
    periods.push({
      years: targetYear + '-' + formatMonth(targetMonth),
      period: 1,
      label: `${targetYear}-${formatMonth(targetMonth)} ${text1}`
    });
  }

  // 从上个月开始生成剩余周期
  targetMonth -= 1;
  if (targetMonth === 0) {
    targetMonth = 12;
    targetYear -= 1;
  }

  while (periods.length < totalPeriods) {
    periods.push({
      years: targetYear + '-' + formatMonth(targetMonth),
      period: 2,
      label: `${targetYear}-${formatMonth(targetMonth)} ${text2}`
    });
    periods.push({
      years: targetYear + '-' + formatMonth(targetMonth),
      period: 1,
      label: `${targetYear}-${formatMonth(targetMonth)} ${text1}`
    });

    targetMonth -= 1;
    if (targetMonth === 0) {
      targetMonth = 12;
      targetYear -= 1;
    }
  }

  return periods.slice(0, totalPeriods);
};

export {
  elDatePickerOptions,
  setDateTime,
  setDateTime2,
  setDateTime3,
  setDateTime4,
  getSurplusTime,
  date2TimeStamp,
  setData,
  convertBrazilianTimeFormat,
  formatDateExcel,
  generatePeriods
};
