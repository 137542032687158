var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("receivablePayable.SendAJDRecalculation"),
        visible: _vm.isShow,
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.isShow = $event
        },
        close: _vm.onClose,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ajdRuleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ajdRuleForm,
            rules: _vm.adjRules,
            "label-position": "top",
            size: "small",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("receivablePayable.cycle") + "：",
                prop: "cycle",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    placeholder: _vm.$t("operationCenter.PleaseSelect"),
                    clearable: "",
                    filterable: "",
                    size: "small",
                  },
                  model: {
                    value: _vm.ajdRuleForm.cycle,
                    callback: function ($$v) {
                      _vm.$set(_vm.ajdRuleForm, "cycle", $$v)
                    },
                    expression: "ajdRuleForm.cycle",
                  },
                },
                _vm._l(_vm.periodList, function (item, index) {
                  return _c("el-option", {
                    key: item.label,
                    attrs: { label: item.label, value: index },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("orderCenterCont.deliveryPerson") + "：",
                prop: "dispatcherList",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    "multiple-limit": 10,
                    placeholder: _vm.$t("operationCenter.Only10AreDisplayed"),
                    "remote-method": _vm.getDeliveryPage,
                    clearable: "",
                    filterable: "",
                    multiple: "",
                    remote: "",
                    loading: _vm.loading,
                    size: "small",
                  },
                  model: {
                    value: _vm.ajdRuleForm.dispatcherList,
                    callback: function ($$v) {
                      _vm.$set(_vm.ajdRuleForm, "dispatcherList", $$v)
                    },
                    expression: "ajdRuleForm.dispatcherList",
                  },
                },
                _vm._l(_vm.deliveryList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.saveReCalculate },
            },
            [_vm._v(_vm._s(_vm.$t("basicData.determine")))]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  _vm.isShow = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("basicData.Cancel")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }