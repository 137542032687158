<script >
import { computed, defineProps } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = ['width'];
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const widthValue = computed(() => {
    return props.width.includes('%') ? props.width : `${props.width} px`;
  });
  return {
    widthValue
  };
};
export default __sfc_main;
</script>

<template>
  <div class="card" :style="`max-width: ${widthValue}`">
    <slot />
  </div>
</template>

<style scoped>
.card {
  box-shadow: var(--o-ring-offset-shadow),var(--o-ring-shadow),var(--o-shadow);
  color: hsl(var(--o-card-foreground));
  background-color: hsl(var(--o-card));
  border-width: 1px;
  border-radius: 0.5rem;
  padding: 1.5rem;
}

</style>
