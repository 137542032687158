<template>
  <div class="ZipCodeAreaBox">
    <div style="margin-bottom: 20px;">
      <el-form>
        <el-row>
          <el-col :span="6">
            <!--邮编分区名称： -->
            <el-form-item :label="$t('CenterForSorting.ZipCodeAreaName')" label-width="150px">
              <el-input v-model.trim="queryForm.params.zipCodeName" clearable :placeholder="$t('CenterForSorting.pleaseInput')" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 城市名称： -->
            <el-form-item :label="$t('CenterForSorting.CityN')" label-width="150px">
              <el-input v-model.trim="queryForm.params.cityName" clearable :placeholder="$t('CenterForSorting.pleaseInput')" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--州二字码： -->
            <el-form-item :label="$t('CenterForSorting.StateTwoCode') + '：'" label-width="150px">
              <el-input v-model.trim="queryForm.params.twoCode" clearable :placeholder="$t('CenterForSorting.pleaseInput')" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="10">
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="searchClick"
          >{{ $t('CenterForSorting.search') }}</el-button><!-- 查询 -->
          <el-button
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('CenterForSorting.reset') }}</el-button><!-- 重置 -->
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="addClick"
          >{{ $t('CenterForSorting.AddedZipCodeArea') }}</el-button><!-- 新增邮编分区 -->
          <!-- <el-button
            icon="el-icon-upload2"
            size="mini"
            @click="download"
          >{{ $t('CenterForSorting.export') }}</el-button> --><!-- 导出 -->
        </el-col>
      </el-row>
    </div>
    <div>
      <el-table
        ref="tableRef"
        :max-height="600"
        :data="tableDate"
        @select="batchSelect"
        @select-all="batchSelectAll"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <!-- 序号 -->
        <el-table-column
          :label="$t('CenterForSorting.ind')" type="index" width="60"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="code"
          :label="$t('CenterForSorting.ZipCodeAreaNo')"
        /><!-- 邮编分区编号 -->
        <el-table-column
          prop="name"
          :label="$t('CenterForSorting.ZipCodeAreaN')"
        ><!-- 邮编分区名称 -->
          <!-- <template slot-scope="scope">
            <el-tooltip placement="top">
              <pre slot="content" style="max-height: 200px;overflow: auto;width:500px;">{{ scope.row.name }}</pre>
              <div class="paramsStyle">{{ scope.row.name }}</div>
            </el-tooltip>
          </template> -->
        </el-table-column>
        <el-table-column
          prop="state"
          :label="$t('CenterForSorting.state')"
        /><!-- 州 -->
        <el-table-column
          prop="cityName"
          :label="$t('CenterForSorting.CityName')"
        /><!-- 城市名称 -->
        <el-table-column
          prop="statusName"
          :label="$t('CenterForSorting.State')"
        /><!-- 状态 -->
        <el-table-column
          prop="remark"
          :label="$t('CenterForSorting.Note')"
          :show-overflow-tooltip="false"
        ><!-- 备注 -->
          <template slot-scope="scope">
            <el-tooltip placement="top">
              <pre slot="content" style="max-height: 200px;overflow: auto;width:500px;">{{ scope.row.remark }}</pre>
              <div class="paramsStyle">{{ scope.row.remark }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="createUserName"
          :label="$t('CenterForSorting.creator')"
        /><!-- 创建人 -->
        <el-table-column
          prop="createTime"
          :label="$t('CenterForSorting.CreationTime')"
        /><!-- 创建时间 -->
        <el-table-column
          prop="updateUserName"
          :label="$t('CenterForSorting.ModifiedBy')"
        /><!-- 修改人 -->
        <el-table-column
          prop="updateTime"
          :label="$t('CenterForSorting.ModificationTime')"
        /><!-- 修改时间 -->
        <el-table-column
          :label="$t('CenterForSorting.operation')"
          fixed="right"
          width="120"
          align="center"
        ><!-- 操作 -->
          <template slot-scope="scope">
            <el-button type="info" size="mini" @click="editClick(scope.row)">{{ $t('CenterForSorting.edit') }}</el-button><!-- 编辑 -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100, 500, 1000]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getZipcodeAreaPage"
        @size-change="searchClick"
      />
    </div>
    <div v-if="drawerIsOpen">
      <el-drawer
        :title="drawerTitle"
        :visible.sync="drawerIsOpen"
        :direction="direction"
        size="40%"
      >
        <div class="drawerClass">
          <el-form ref="drawerForm" :model="drawerForm" :rules="rules">
            <el-row>
              <el-col v-if="drawerTitle == $t('CenterForSorting.edit')" :span="20">
                <el-form-item prop="code" :label="$t('CenterForSorting.ZipCodeAreaNum')"><!-- 邮编分区编号： -->
                  <el-input
                    v-model.trim="drawerForm.code" :disabled="isDisabled" clearable
                    :placeholder="$t('CenterForSorting.pleaseInput')"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="20">
                <el-form-item prop="name" :label="$t('CenterForSorting.ZipCodeAreaName')"><!-- 邮编分区名称： -->
                  <el-input v-model.trim="drawerForm.name" clearable :placeholder="$t('CenterForSorting.pleaseInput')" />
                </el-form-item>
              </el-col>
              <el-col :span="20">
                <el-form-item prop="status" :label="$t('CenterForSorting.accountState')"><!-- 状态： -->
                  <el-select
                    v-model="drawerForm.status" :placeholder="$t('CenterForSorting.pleaseChoose')" clearable
                    filterable
                  >
                    <el-option
                      v-for="(item, index) in statusList"
                      :key="index"
                      :label="item.msg"
                      :value="item.code"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="20">
                <el-form-item prop="remark" :label="$t('CenterForSorting.note')"><!-- 备注： -->
                  <el-input v-model.trim="drawerForm.remark" clearable :placeholder="$t('CenterForSorting.pleaseInput')" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div class="submitClickBtn">
            <el-button type="primary" @click="saveClick">{{ $t('CenterForSorting.Save') }}</el-button><!-- 保存 -->
            <el-button type="delete" @click="drawerCancelClick">{{ $t('CenterForSorting.cancel') }}</el-button><!-- 取消 -->
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import { zipcodeAreaPagePaging, zipcodeAreaAdd, zipcodeAreaEdit } from '@/api/automaticSorting';
export default {
  data() {
    return {
      tableDate: [],
      total: 0,
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          zipCodeName: '',
          cityName: '',
          twoCode: '',
          status: ''
        }
      },

      drawerIsOpen: false,
      drawerTitle: this.$t('CenterForSorting.add'), // '新增'
      direction: 'ltr',
      isDisabled: false,
      drawerForm: {
        code: '',
        name: '',
        status: '',
        remark: ''
      },
      editId: '', // 编辑邮编分区id
      statusList: [
        { code: 1, msg: this.$t('CenterForSorting.enable') }, // '启用'
        { code: 0, msg: this.$t('CenterForSorting.notEnabled') } // '未启用'
      ],
      rules: {
        code: [
          // '不能为空'
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }
          // {
          //   validator: (rule, value, callback) => {
          //     if (value.length < 6 || value.length > 10) {
          //       // ''
          //       return callback(new Error('xxxx'));
          //     } else {
          //       callback();
          //     }
          //   }
          // }
        ],
        name: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        status: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }]
      }
    };
  },
  created() {
    this.getZipcodeAreaPage();
  },
  methods: {
    getZipcodeAreaPage() {
      zipcodeAreaPagePaging(this.queryForm).then((res) => {
        if (res.status === 'OK') {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        }
      }).catch({});
    },
    searchClick() { // 查询
      this.queryForm.pageNumber = 1;
      this.getZipcodeAreaPage();
    },
    resetClick() { // 重置
      this.queryForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          zipCodeName: '',
          cityName: '',
          twoCode: '',
          status: ''
        }
      };
    },
    addClick() { // 新增邮编分区
      this.drawerTitle = this.$t('CenterForSorting.add');
      this.drawerIsOpen = true;
      this.isDisabled = false;
      this.drawerForm = {
        // code: '',
        name: '',
        status: 1,
        remark: ''
      };
    },
    saveClick() { // 保存
      this.$refs['drawerForm'].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          if (this.drawerTitle === this.$t('CenterForSorting.add')) {
            const addForm = {
              name: this.drawerForm.name,
              remark: this.drawerForm.remark,
              status: this.drawerForm.status
            };
            zipcodeAreaAdd(addForm).then(res => {
              if (res.status === 'OK') {
                this.drawerIsOpen = false;
                this.getZipcodeAreaPage();
              }
            }).catch({});
          }
          if (this.drawerTitle === this.$t('CenterForSorting.edit')) {
            const editForm = {
              id: this.editId,
              name: this.drawerForm.name,
              remark: this.drawerForm.remark,
              status: this.drawerForm.status
            };
            zipcodeAreaEdit(editForm).then(res => {
              if (res.status === 'OK') {
                this.drawerIsOpen = false;
                this.getZipcodeAreaPage();
              }
            }).catch({});
          }
        }
      });
    },
    drawerCancelClick() { // 取消
      this.drawerIsOpen = false;
      this.drawerForm = {
        code: '',
        name: '',
        status: '',
        remark: ''
      };
      this.editId = '';
      this.$refs.drawerForm.clearValidate();
    },
    editClick(row) { // 操作——编辑
      this.drawerTitle = this.$t('CenterForSorting.edit');
      this.drawerIsOpen = true;
      this.isDisabled = true;
      this.drawerForm = {
        code: row.code,
        name: row.name,
        status: row.status,
        remark: row.remark
      };
      this.editId = row.id;
    },
    download() { // 导出
      //
    },
    batchSelect() {},
    batchSelectAll() {}
  }
};
</script>

<style lang="scss" scoped>
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .paramsStyle{
    width: 100%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  .ZipCodeAreaBox{
    padding: 20px;
    box-sizing: border-box;
  }
  .ZipCodeAreaBox ::v-deep .el-select{
    width: 100%;
  }
  .drawerClass{
    padding: 30px;
    box-sizing: border-box;
    margin-bottom: 50px;
  }
  .submitClickBtn{
    text-align: right;
    margin-top: 30px;
  }
</style>
