<template>
  <div style="padding:0 20px">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="$t('navbar.signCofigRef')" name="first">
        <signCofig ref="signCofigRef" />
      </el-tab-pane>
      <el-tab-pane :label="$t('navbar.electronicContactLimitRef')" name="second">
        <electronicContactLimit ref="electronicContactLimitRef" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import signCofig from './component/signCofig.vue';
import electronicContactLimit from './component/electronicContactLimit.vue';

export default {
  name: 'SignLimitConfiguration',
  components: {
    signCofig,
    electronicContactLimit
  },
  data() {
    return {
      activeName: 'first'
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
      if (tab.name === 'first') {
        this.$refs.signCofigRef.getSearch();
      } else {
        this.$refs.electronicContactLimitRef.getSearch();
      }
      console.log(tab.name, 'electronicContactLimit');
    }
  }
};
</script>

<style scoped>

</style>
