var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container box" },
    [
      _c(
        "el-form",
        {
          ref: "queryForm",
          staticClass: "queryFormClass",
          attrs: {
            size: "small",
            model: _vm.detailData,
            rules: _vm.rules,
            "label-width": "auto",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.ChineseName") + "：",
                        prop: "chineseName",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                        },
                        model: {
                          value: _vm.detailData.chineseName,
                          callback: function ($$v) {
                            _vm.$set(_vm.detailData, "chineseName", $$v)
                          },
                          expression: "detailData.chineseName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("userManage.englishName") + "：",
                        prop: "englishName",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                        },
                        model: {
                          value: _vm.detailData.englishName,
                          callback: function ($$v) {
                            _vm.$set(_vm.detailData, "englishName", $$v)
                          },
                          expression: "detailData.englishName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.CurrencyCode") + "：",
                        prop: "code",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                        },
                        model: {
                          value: _vm.detailData.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.detailData, "code", $$v)
                          },
                          expression: "detailData.code",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.$t("GlobalSetObj.DefaultExchangeRate") + "：",
                        prop: "exchangeRate",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                        },
                        model: {
                          value: _vm.detailData.exchangeRate,
                          callback: function ($$v) {
                            _vm.$set(_vm.detailData, "exchangeRate", $$v)
                          },
                          expression: "detailData.exchangeRate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.status") + "：",
                        prop: "status",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.detailData.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.detailData, "status", $$v)
                            },
                            expression: "detailData.status",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: 0 } }, [
                            _vm._v(_vm._s(_vm.$t("GlobalSetObj.disable"))),
                          ]),
                          _c("el-radio-button", { attrs: { label: 1 } }, [
                            _vm._v(_vm._s(_vm.$t("GlobalSetObj.enable"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.remark") + "：",
                        prop: "remark",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 4,
                          placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                        },
                        model: {
                          value: _vm.detailData.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.detailData, "remark", $$v)
                          },
                          expression: "detailData.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "permit",
              rawName: "v-permit:remove",
              value: "btn:currencyManagement:edit",
              expression: "'btn:currencyManagement:edit'",
              arg: "remove",
            },
          ],
          staticStyle: { "text-align": "center" },
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.saveFormData(2)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveFormData(1)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Salve")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }