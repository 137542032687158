import axios from 'axios';
import { addLoading, isCloseLoading } from '@/utils/request';
import store from '@/store';
import { getToken } from '@/utils/auth';

const requestPriceTable = axios.create({
  baseURL: process.env.VUE_APP_PRICE_TABLE,
  timeout: 30000
});

requestPriceTable.interceptors.request.use(config => {
  addLoading();
  if (store.getters.token) {
    config.headers['Authorization'] = `Bearer ${getToken()}`;
  }
  return config;
});

requestPriceTable.interceptors.response.use((response) => {
  isCloseLoading();
  return response;
}, error => {
  isCloseLoading();
  return Promise.reject(error);
});

export const getAllPriceTable = (skip = 0, take = 10, filters) => {
  const queryString = new URLSearchParams();

  if (filters) {
    for (const key in filters) {
      if (filters[key]) {
        queryString.append(key, filters[key]);
      }
    }
  }

  return requestPriceTable({
    method: 'GET',
    url: `filtro?skip=${skip}&take=${take}&${queryString.toString()}`
  });
};

export const uploadWorksheets = (data) => {
  return requestPriceTable({
    url: 'table/upload-prices',
    method: 'POST',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

export const deleteRowPriceTable = (id) => {
  return requestPriceTable({
    url: `table/${id}`,
    method: 'DELETE'
  });
};
