<script >
import { ref, defineProps } from '@vue/composition-api';
import { default as FormTransportSupply } from '@/views/transport/order/components/form/steps/formTransportSupply';
import { useCreateOrder } from '@/api/transport/order/query';
import getMessageError from '@/utils/getMessageError';
import { equipmentService } from '@/api/transport/equipment';
import { orderService } from '@/api/transport/order/index';
import { toast } from '@/utils/programaticToast';
import { getToastContentComponent } from '@/utils/getToastContentComponent';
import { addingLoading, closeLoading } from '@/utils/handleControlLoading';
const __sfc_main = {};
__sfc_main.props = {
  closeModal: {
    type: Function
  },
  routeId: {
    type: Number
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const formData = ref({
    selectedColors: [],
    quantityBags: null,
    sealCode: {
      azul: [],
      amarelo: [],
      branco: []
    }
  });
  const {
    mutate
  } = useCreateOrder();
  async function handleSubmit() {
    addingLoading();
    if (formData.value.selectedColors.length) {
      try {
        const resEquipment = await equipmentService.getByRoute(props.routeId);
        await orderService.updateTransportSupply({
          listSeals: formData.value.selectedColors,
          quantityBags: formData.value.quantityBags,
          driverDocument: resEquipment.data.driver_document,
          sealCode: formData.value.sealCode
        });
      } catch (error) {
        toast.open({
          variant: 'error',
          position: 'bottom',
          component: getToastContentComponent({
            title: 'Ocorreu um erro',
            describe: getMessageError(error),
            typeIcon: 'error'
          }),
          closable: true,
          duration: 4000
        });
        return;
      } finally {
        closeLoading();
      }
    }
    mutate(props.routeId, {
      onSuccess: () => {
        closeLoading();
        props.closeModal();
      },
      onError: () => {
        closeLoading();
      }
    });
  }
  return {
    formData,
    handleSubmit
  };
};
__sfc_main.components = Object.assign({
  FormTransportSupply
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <FormTransportSupply
    :form-data="formData"
    :handle-next="handleSubmit"
    :is-last-step="true"
    :is-first-step="true"
  />
</template>
