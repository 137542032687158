export function convert12To24(hourEN) {
  const date = new Date();
  const [hour, period] = hourEN.split(' ');
  if (period === 'PM') {
    const numericHour = parseInt(hour);
    const hourFormat24 = numericHour === 12 ? 0 : numericHour + 12;
    date.setHours(hourFormat24);
    date.setMinutes(0);
    return date;
  }

  date.setHours(hour);
  date.setMinutes(0);
  return date;
}

export function formatHours(openingHours) {
  const [initialHour, finalHour] = openingHours.split(' to ');
  const initialDate = convert12To24(initialHour);
  const finalDate = convert12To24(finalHour);

  return `${initialDate.toLocaleString('pt-BR', { hour: 'numeric', hour24: true })} hrs - ${finalDate.toLocaleString('pt-BR', { hour: 'numeric', hour24: true })} hrs`;
}
