var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tags-view-container",
      attrs: { id: "tags-view-container" },
    },
    [
      _c(
        "keep-alive",
        [
          _c(
            "scroll-pane",
            {
              ref: "scrollPane",
              staticClass: "tags-view-wrapper",
              on: { scroll: _vm.handleScroll },
            },
            _vm._l(_vm.visitedViews, function (tag) {
              return _c(
                "router-link",
                {
                  key: tag.path,
                  ref: "tag",
                  refInFor: true,
                  staticClass: "tags-view-item",
                  class: _vm.isActive(tag) ? "active" : "",
                  attrs: {
                    to: {
                      path: tag.path,
                      query: tag.query,
                      fullPath: tag.fullPath,
                    },
                    tag: "span",
                  },
                  nativeOn: {
                    mouseup: function ($event) {
                      if ("button" in $event && $event.button !== 1) {
                        return null
                      }
                      !_vm.isAffix(tag) ? _vm.closeSelectedTag(tag) : ""
                    },
                    contextmenu: function ($event) {
                      $event.preventDefault()
                      return _vm.openMenu(tag, $event)
                    },
                  },
                },
                [
                  _vm._v(" " + _vm._s(_vm.generateTitle(tag.title)) + " "),
                  !_vm.isAffix(tag)
                    ? _c("span", {
                        staticClass: "el-icon-close",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.closeSelectedTag(tag)
                          },
                        },
                      })
                    : _vm._e(),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visible,
              expression: "visible",
            },
          ],
          staticClass: "contextmenu",
          style: { left: _vm.left + "px", top: _vm.top + "px" },
        },
        [
          !_vm.isAffix(_vm.selectedTag)
            ? _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.closeSelectedTag(_vm.selectedTag)
                    },
                  },
                },
                [_vm._v("关闭")]
              )
            : _vm._e(),
          _c("li", { on: { click: _vm.closeOthersTags } }, [
            _vm._v("关闭其他标签"),
          ]),
          _c(
            "li",
            {
              on: {
                click: function ($event) {
                  return _vm.closeAllTags(_vm.selectedTag)
                },
              },
            },
            [_vm._v("关闭所有")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }