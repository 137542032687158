var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "kanban_content" }, [
    _c(
      "div",
      { staticStyle: { display: "flex", "justify-content": "space-between" } },
      [
        _c("div", [
          _vm._v(
            _vm._s(_vm.$t("customerManagements.dataUpdateTime")) +
              ": " +
              _vm._s(_vm.centerData.updateTime || "--")
          ),
        ]),
        _c(
          "div",
          [
            _c("span", { staticStyle: { "margin-right": "10px" } }, [
              _vm._v(_vm._s(_vm.$t("collectionCenter.transferCenter"))),
            ]),
            _c(
              "el-select",
              {
                attrs: {
                  placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                  filterable: "",
                },
                on: { change: _vm.getcenterData },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              },
              _vm._l(_vm.options, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "data_01" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 100 } },
          [
            _c(
              "el-col",
              { staticClass: "classGuird", attrs: { span: 5 } },
              [
                _c("div", { staticClass: "class_font" }, [
                  _vm._v(_vm._s(_vm.$t("customerManagements.NumberArrived"))),
                ]),
                _c("el-card", { attrs: { shadow: "hover" } }, [
                  _c("div", { staticClass: "class_font18" }, [
                    _vm._v(_vm._s(_vm.centerData.unload || 0)),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "el-col",
              { staticClass: "classGuird", attrs: { span: 5 } },
              [
                _c("div", { staticClass: "class_font" }, [
                  _vm._v(_vm._s(_vm.$t("customerManagements.loaderArrived"))),
                ]),
                _c("el-card", { attrs: { shadow: "hover" } }, [
                  _c("div", { staticClass: "class_font18" }, [
                    _vm._v(_vm._s(_vm.centerData.loading || 0)),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "el-col",
              { staticClass: "classGuird", attrs: { span: 5 } },
              [
                _c("div", { staticClass: "class_font" }, [
                  _vm._v(
                    _vm._s(_vm.$t("customerManagements.differenceArrived"))
                  ),
                ]),
                _c("el-card", { attrs: { shadow: "hover" } }, [
                  _c("div", { staticClass: "class_font18" }, [
                    _vm._v(_vm._s(_vm.centerData.difference || 0)),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "data_02" }, [
      _c("div", [
        _c(
          "span",
          { staticStyle: { "font-weight": "bold", "font-size": "18px" } },
          [_vm._v(_vm._s(_vm.$t("customerManagements.NumberVotes")))]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "margin-left": "10px",
              color: "#409eff",
              "text-decoration": "underline",
              cursor: "pointer",
            },
            on: { click: _vm.downloadNumber },
          },
          [_vm._v(_vm._s(_vm.$t("customerManagements.downloadNumber")))]
        ),
      ]),
      _c("div", { staticStyle: { display: "flex", "margin-top": "30px" } }, [
        _c("div", [
          _c("div", { staticStyle: { "font-weight": "bold" } }, [
            _vm._v(_vm._s(_vm.$t("customerManagements.HoursDetention24"))),
          ]),
          _c(
            "div",
            {
              staticStyle: {
                color: "#b47925",
                "font-size": "30px",
                "font-weight": "bold",
              },
            },
            [_vm._v(_vm._s(_vm.centerData.twentyFourHours || 0))]
          ),
        ]),
        _c("div", { staticStyle: { margin: "0 50px" } }, [
          _c("div", { staticStyle: { "font-weight": "bold" } }, [
            _vm._v(_vm._s(_vm.$t("customerManagements.HoursDetention48"))),
          ]),
          _c(
            "div",
            {
              staticStyle: {
                color: "#14bdbd",
                "font-size": "30px",
                "font-weight": "bold",
              },
            },
            [_vm._v(_vm._s(_vm.centerData.fortyEightHours || 0))]
          ),
        ]),
        _c("div", [
          _c("div", { staticStyle: { "font-weight": "bold" } }, [
            _vm._v(_vm._s(_vm.$t("customerManagements.HoursDetention72"))),
          ]),
          _c(
            "div",
            {
              staticStyle: {
                color: "#8482f1",
                "font-size": "30px",
                "font-weight": "bold",
              },
            },
            [_vm._v(_vm._s(_vm.centerData.seventyTwoHours || 0))]
          ),
        ]),
      ]),
    ]),
    _c("div", { staticStyle: { display: "flex", "margin-top": "50px" } }, [
      _c("div", { staticStyle: { "margin-right": "50px" } }, [
        _c("div", { staticStyle: { "font-weight": "bold" } }, [
          _vm._v(_vm._s(_vm.$t("customerManagements.DayDetention7"))),
        ]),
        _c(
          "div",
          {
            staticStyle: {
              color: "#107d80",
              "font-size": "30px",
              "font-weight": "bold",
            },
          },
          [_vm._v(_vm._s(_vm.centerData.sevenDays || 0))]
        ),
      ]),
      _c("div", [
        _c("div", { staticStyle: { "font-weight": "bold" } }, [
          _vm._v(_vm._s(_vm.$t("customerManagements.DayDetention15"))),
        ]),
        _c(
          "div",
          {
            staticStyle: {
              color: "#9d0319",
              "font-size": "30px",
              "font-weight": "bold",
            },
          },
          [_vm._v(_vm._s(_vm.centerData.fifteenDays || 0))]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }