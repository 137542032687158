<template>
  <div class="outOfWarehouseBox">
    <!-- 出库 -->
    <div class="outOfWarehouseClass">
      <div>
        <div class="scanClass">
          <!-- 网点揽收出库扫描： -->
          <span>{{ $t('operatingPlatform.outOfWarehouseScan') }}</span>
          <el-input
            v-model.trim="outOfWarehouseScan"
            :placeholder="$t('operatingPlatform.PleaseScan')"
            prefix-icon="el-icon-full-screen"
            clearable
            class="iconBtn"
            @keyup.enter.native="addTable"
          />
        </div>
      </div>
      <el-button
        class="uploadBtn onlyClickOnce"
        @click="upload"
      >{{ $t('operatingPlatform.Upload') }}</el-button><!-- 上传 -->

      <div class="tableClass">
        <el-table
          :max-height="600"
          :data="scanTableData"
          border
        >
          <el-table-column
            prop="scanWaybillNumber"
            align="center"
            :label="$t('operatingPlatform.waybillNumber')"
          /><!-- 运单号 -->
          <el-table-column
            prop="scanTime"
            align="center"
            :label="$t('operatingPlatform.scanTime')"
          /><!-- 扫描时间 -->
          <el-table-column
            :label="$t('operatingPlatform.operation')"
            fixed="right"
            width="100"
            align="center"
          ><!-- 操作 -->
            <template slot-scope="scope">
              <el-popconfirm
                :title="$t('operatingPlatform.deleteSure')"
                @confirm="scanDelClick(scope.row, scope.$index)"
              ><!-- 确定删除吗？ -->
                <el-button slot="reference" type="danger" size="mini">{{ $t('operatingPlatform.Delete') }}</el-button><!-- 删除 -->
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 隐藏提示音 -->
    <div v-show="false">
      <audio ref="errRef" :src="errorMp3" controls="controls" />
      <audio ref="okRef" :src="okMp3" controls="controls" />
    </div>
  </div>
</template>

<script>
import { pcScanWaybillNumber, pcSiteCollectOutboundSubmit, deleteScanRecord } from '@/api/operatingPlatform';
import { cloneDeep } from 'lodash-es';
import dayjs from 'dayjs';
const okMp3 = '/static/scan_success.mp3';
const errorMp3 = '/static/scan_fail.mp3';

export default {
  name: 'CollectOut',
  data() {
    return {
      okMp3,
      errorMp3,
      outOfWarehouseScan: '',
      scanTableData: []
    };
  },
  created() {
    if (localStorage.getItem('collectOutOfWarehouseData')) {
      this.scanTableData = JSON.parse(localStorage.getItem('collectOutOfWarehouseData'));
    } else {
      this.scanTableData = [];
    }
  },
  methods: {
    addTable() { // 添加
      if (this.outOfWarehouseScan) {
        // 查询有无重复值
        const isAdd = this.scanTableData.some(el => el.scanWaybillNumber === this.outOfWarehouseScan);
        if (isAdd && this.scanTableData.length > 0) {
          this.$message({
            message: this.$t('collectionCenter.waybillNumRepeat'), // '当前运单号重复'
            type: 'warning'
          });
          this.$refs.errRef.play();
        } else {
          const scanNumber = {
            scanWaybillNumber: this.outOfWarehouseScan,
            operationType: 7
          };
          pcScanWaybillNumber(scanNumber).then(res => {
            if (res.status === 'OK') {
              this.$refs.okRef.play();
              const scanTime = new Date();
              this.scanTableData.unshift({
                waybillId: res.data.waybillId,
                scanWaybillNumber: this.outOfWarehouseScan,
                scanTime: dayjs(scanTime).format('YYYY-MM-DD HH:mm:ss'),
                scanRecordId: res.data.scanRecordId
              });
              localStorage.setItem('collectOutOfWarehouseData', JSON.stringify(this.scanTableData));
              this.outOfWarehouseScan = '';
            } else {
              this.outOfWarehouseScan = '';
              this.$refs.errRef.play();
              this.$message.error(res.msg);
            }
          }).catch(() => {
            this.outOfWarehouseScan = '';
            this.$refs.errRef.play();
          });
        }
      }
    },
    scanDelClick(row, index) { // 扫件——删除
      const delForm = {
        id: row.scanRecordId
      };
      deleteScanRecord(delForm).then(res => {
        if (res.status === 'OK') {
          this.scanTableData.splice(index, 1);
          if (this.scanTableData.length <= 0 || row.scanWaybillNumber == this.outOfWarehouseScan) {
            this.outOfWarehouseScan = '';
          }
          localStorage.setItem('collectOutOfWarehouseData', JSON.stringify(this.scanTableData));
        }
      }).catch({});
    },
    upload() { // 上传
      if (this.scanTableData.length <= 0) {
        this.$message.warning(this.$t('operatingPlatform.nullSubmit')); // '无数据提交'
      } else {
        const scanTableList = cloneDeep(this.scanTableData);
        scanTableList.map(del => {
          delete del.scanRecordId;
        });
        const wForm = {
          warbillList: scanTableList
        };
        pcSiteCollectOutboundSubmit(wForm).then(res => {
          if (res.status === 'OK') {
            this.outOfWarehouseScan = '';
            this.scanTableData = [];
            localStorage.setItem('collectOutOfWarehouseData', '');
            this.$message.success(res.data);
          } else {
            this.$message.error(res.msg);
          }
        }).catch({});
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  // 防止按钮重复点击
  @keyframes throttle{
    from{
      filter: opacity(40%);
      pointer-events: none;
    }
    to{
      pointer-events: all;
    }
  }
  .onlyClickOnce{
    animation: throttle 2s step-end forwards;
  }
  .onlyClickOnce:active{
    filter: opacity(40%);
    animation: none;
  }
  .tableClass{
    margin-top: 10px;
  }
  .outOfWarehouseBox{
    padding: 20px;
    box-sizing: border-box;
  }
  .outOfWarehouseClass{
    //width: 60%;
    //border: 1px dotted #dcdfe6;
    //border-radius: 5px;
    //padding: 20px;
    //box-sizing: border-box;
    //overflow: hidden;
    //text-align: center;
    .scanClass{
      margin: 20px auto;
    }
    .iconBtn::after{
      position: absolute;
      content: "-";
      top: 10px;
      left: 15px;
      transform: scaleX(2.3);
      color: #bbb;
    }
    .uploadBtn{
      margin-top: 40px;
    }
  }
  ::v-deep .el-input{
    width: 350px;
  }
</style>
