<script >
import { ref, defineProps } from '@vue/composition-api';
import { default as FormDataBank } from './step/formDataBank';
import { useEditBank } from '@/api/transfer/bank/query';
const __sfc_main = {};
__sfc_main.props = {
  closeModal: {
    type: Function
  },
  bankData: {
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const formData = ref({
    companyId: props.bankData.shipping_company_id,
    companyName: props.bankData.shipping_company_name,
    bank: props.bankData.shipping_company_bank,
    agency: props.bankData.shipping_company_bank_agency,
    account: props.bankData.shipping_company_bank_account,
    beneficiary: props.bankData.shipping_company_bank_beneficiary,
    financialContact: props.bankData.shipping_company_bank_financial_contact,
    financialEmail: props.bankData.shipping_company_bank_financial_mail,
    financialPhone: props.bankData.shipping_company_bank_financial_phone
  });
  const {
    mutate,
    isLoading
  } = useEditBank();
  async function handleSubmit() {
    mutate({
      newBank: formData.value,
      id: props.bankData.id
    }, {
      onSuccess() {
        props.closeModal();
      }
    });
  }
  return {
    formData,
    isLoading,
    handleSubmit
  };
};
__sfc_main.components = Object.assign({
  FormDataBank
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <FormDataBank
      :handle-next="handleSubmit"
      :is-last-step="true"
      :is-first-step="true"
      :form-data="formData"
    />

    <o-loading :full-page="true" :active.sync="isLoading" />
  </div>
</template>
