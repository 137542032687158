<template>
  <div class="app-container">
    <div>
      <el-form
        ref="queryForm"
        class="queryFormClass"
        label-position="top"
        size="mini"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('receivablePayable.TicketNumber') + '：'">
              <el-input
                v-model="queryForm.params.ids"
                clearable
                type="textarea"
                :rows="3"
                style="width: 300px"
                :placeholder="$t('trajectory.multipleOrders')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <!-- 请求时间 -->
            <el-form-item :label="$t('orderCenterCont.RequestTime')">
              <el-date-picker
                v-model="queryForm.params.requestTimes"
                type="datetimerange"
                range-separator="-"
                :picker-options="pickerOptions"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                :start-placeholder="$t('GlobalSetObj.startDate')"
                :end-placeholder="$t('GlobalSetObj.endDate')"
              />
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>

      <el-button
        type="primary" size="small" icon="el-icon-search"
        @click="onQuery"
      >{{
        $t('operationCenter.Procurar')
      }}</el-button>
      <el-button size="small" icon="el-icon-refresh-left" @click="reset">{{
        $t('operationCenter.reset')
      }}</el-button>
    </div>

    <el-card>
      <div>
        <el-table
          :data="tableData"
          border
          class="table-height"
          :height="tableHeightRow.height"
        >
          <el-table-column :label="$t('operationCenter.ind')" width="80px" align="center">
            <template slot-scope="scope">
              <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>

          <!-- 业务单号 -->
          <el-table-column :label="$t('receivablePayable.TicketNumber')" prop="businessNumber" />

          <!-- 请求时间 -->
          <el-table-column :label="$t('orderCenterCont.RequestTime')" prop="createTime" />

          <!-- 请求人 -->
          <el-table-column :label="$t('routes.usernameLabel')" prop="createUserName" />

          <!-- 请求状态 -->
          <!-- <el-table-column :label="$t('receivablePayable.TicketNumber')" prop="logisticsNumber" /> -->

          <el-table-column
            :label="$t('GlobalSetObj.operate')"
            width="100"
          >
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="handleClick(scope.row)">{{ $t('GlobalSetObj.View') }}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getTableData"
          @size-change="getTableData"
        />
      </div>
    </el-card>

    <!-- 详情 -->
    <el-drawer
      :visible.sync="detailsRow.isShow"
      :close-on-click-modal="false"
      :append-to-body="true"
      direction="ltr"
      size="70%"
      custom-class="drawerClass"
      :title="$t('orderCenterCont.ViewDetails')"
    >
      <DetailsCmp :row="detailsRow.row" />
    </el-drawer>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash-es';
import { apiCarlosRequestLogPage } from '@/api/finance/basicData';
import { formData, setDisabledDate, getTextareaVal } from './utils';
import DetailsCmp from './cmp/DetailsCmp';
import dayjs from 'dayjs';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';

export default {
  name: '',

  components: {
    DetailsCmp
  },

  mixins: [estimateTableHeight],

  data() {
    return {
      queryForm: {
        orderDirection: 'DESC',
        orderProperty: '',
        pageNumber: 1,
        pageSize: 10,
        params: {
          ids: '',
          requestTimes: ''
        }
      },
      total: 0,

      tableData: [],

      // 详情
      detailsRow: {
        isShow: false,
        row: {
          ...formData
        }
      },

      choiceDate0: '',

      pickerOptions: {
        // 设置不能选择的日期
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate0 = minDate.getTime();
          if (maxDate) {
            this.choiceDate0 = '';
          }
        },
        disabledDate: (time) => {
          return setDisabledDate(time, this.choiceDate0, 6);
        }
      }
    };
  },

  created() {
    // 设置默认时间
    const firstDay = dayjs().format('YYYY-MM-DD') + ' 00:00:00';
    const lastDay = dayjs().format('YYYY-MM-DD') + ' 23:59:59';
    this.queryForm.params.requestTimes = [firstDay, lastDay];

    // this.onQuery();// 默认不查询
  },

  methods: {
    onQuery() {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = 10;
      this.getTableData();
    },

    // 重置
    reset() {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = 10;
      this.getTableData();
    },

    // 设置查询条件
    setQueryForm() {
      const queryForm = cloneDeep(this.queryForm);
      const { ids, requestTimes } = this.queryForm.params;
      if (ids) {
        queryForm.params.ids = getTextareaVal(ids);
      } else {
        queryForm.params.ids = [];
      }

      if (!Array.isArray(requestTimes)) {
        queryForm.params.requestTimes = [];
      }

      return queryForm;
    },

    getTableData() {
      const obj = this.setQueryForm();
      apiCarlosRequestLogPage(obj).then(res => {
        const { records, total } = res.data;

        this.total = total;

        this.tableData = records.map(item => {
          const { id, createTime, createUserName, businessNumber, requestInfo, responseInfo } = item;

          const obj = Object.freeze({
            id,
            businessNumber, // 业务单号
            createTime, // 请求时间
            createUserName, // 请求人
            requestInfo, // 请求信息
            responseInfo // 响应信息
          });

          return obj;
        });
      }).catch((err) => {
        console.error(err);
      });
    },

    // 查看详情
    handleClick(row) {
      this.detailsRow.isShow = true;
      Object.assign(this.detailsRow.row, { ...row });
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
