<template>
  <div class="app-container">
    <el-form label-position="top">
      <el-row :gutter="20" style="margin-bottom: 10px">
        <el-col :span="5" style="height: 70px;">
          <el-form-item :label="$t('GlobalSetObj.status')">
            <el-select
              v-model="queryForm.params.status"
              :placeholder="$t('GlobalSetObj.all')"
              clearable
              style="width: 100%;"
              @change="handleChange"
            >
              <el-option key="1" :label="$t('GlobalSetObj.all')" value="" />
              <el-option key="2" :label="$t('GlobalSetObj.enable')" :value="1" />
              <el-option key="3" :label="$t('GlobalSetObj.stop')" :value="0" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="9" style="height: 70px;">
          <el-form-item prop="searchType">
            <template slot="label">
              <div class="selectLabel">
                <el-select
                  v-model="queryForm.params.searchType"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  :style="IsWidth"
                  @change="SwitchType"
                >
                  <el-option key="1" :label="$t('GlobalSetObj.Name')" value="1" />
                  <el-option key="2" :label="$t('GlobalSetObj.Outlets')" value="2" />
                </el-select>
              </div>
            </template>
            <el-form-item class="multipleQuery">
              <div class="searchMultiple">
                <el-input
                  v-model.trim="queryForm.params.inputValue"
                  :placeholder="$t('orderDetails.pleaseInput')"
                  clearable
                  @keyup.enter.native="getSearchEnter"
                />
                <div style="width: 40px;">
                  <el-button class="searchBtn" @click="getSearchEnter">
                    <i class="iconfont icon-chaxun" style="font-size: 24px" />
                  </el-button>
                </div>
                <el-popover
                  v-model="showList"
                  placement="bottom-end"
                  trigger="manual"
                  width="400"
                >
                  <div>
                    <el-input
                      v-model="searchStr"
                      :placeholder="$t('newOrder.PreciseSearch')"
                      :rows="8"
                      type="textarea"
                    />
                    <div style="margin-top: 10px;">
                      <!--清空-->
                      <el-button size="small" @click="clearSearch">{{ $t('newOrder.empty') }}</el-button>
                      <div style="float: right">
                        <!--关闭-->
                        <el-button size="small" @click="openSearchList">{{ $t('orderExport.close') }}</el-button>
                        <!--搜索-->
                        <el-button plain size="small" type="primary" @click="getSearch(1)">
                          {{ $t('newOrder.search') }}
                        </el-button>
                      </div>
                    </div>
                  </div>
                  <el-button slot="reference" class="btnIcon" @click="openText">
                    <i class="iconfont icon-liebiao1" style="font-size: 24px" />
                  </el-button>
                </el-popover>
              </div>
            </el-form-item>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row v-show="searchList.length > 0">
      <div class="searchTip">
        <el-tooltip class="item" effect="dark" placement="bottom">
          <template slot="content">
            <pre>{{ searchStr }}</pre>
          </template>
          <div class="searchNumWarp">
            <div class="wordBreak">
              <p>{{
                switchSearchType
              }}：
                {{ searchList.join('，') }}</p>
            </div>
          </div>
        </el-tooltip>
        <div class="clearSearch" @click="clearSearch">
          <i class="el-icon-close" />
        </div>
      </div>
    </el-row>
    <div style="margin-bottom: 20px;">
      <el-button
        size="mini"
        type="primary"
        @click="getSearch"
      >{{ $t('basicData.Search') }}
      </el-button>
      <el-button
        size="mini"
        type="info"
        @click="resetForm"
      >{{ $t('basicData.reset') }}
      </el-button>
      <!--添加-->
      <el-button
        size="mini"
        @click="addRoute"
      >{{ $t('GlobalSetObj.create') }}
      </el-button>
    </div>
    <div>
      <!--  表格数据  -->
      <AnjunTableList
        ref="anjunTable"
        :format-time="isTormat"
        :has-operation="true"
        :header-list="tableHeaderList"
        :is-tip="true"
        :op-width="150"
        :table-data="tableData"
        @on-editBtn="addRoute"
        @on-stop="updatStatus"
      />
      <!--  分页  -->
      <div class="right">
        <el-pagination
          :current-page.sync="queryForm.pageNumber"
          :page-size.sync="queryForm.pageSize"
          :page-sizes="[10, 20, 50, 100, 500]"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          style="margin: 10px 0"
          @current-change="handleSizeChange"
          @size-change="handleCurrentChange"
        />
      </div>
    </div>
    <!--新建路线-->
    <el-drawer
      :append-to-body="true"
      :title="titleDialog === 1 ? `${$t('GlobalSetObj.newdescarga')}` : `${$t('GlobalSetObj.fixmodificado')}`"
      :visible.sync="showAddData"
      custom-class="drawerClass"
      direction="ltr"
      size="40%"
      @close="clearAddEditSiteForm"
    >
      <div style="padding-left: 20px;height: calc(100vh - 150px);overflow-y: auto;">
        <el-form :key="showAddData" ref="addForm" :model="addEditForm" label-position="top">
          <el-row>
            <el-col :span="23" style="position: relative;height: 110px">
              <el-button
                size="medium"
                style="position: absolute;top:0;right: 0;"
                type="primary"
                @click="copyAddress"
              >{{ $t('GlobalSetObj.MultiplexAddress') }}
              </el-button>
              <!-- 卸车点名： -->
              <el-form-item
                :rules="rulesRouteNumber"
                prop="unloadingName"
                style="margin-top: 20px"
              >
                <template slot="label">
                  <span>{{ $t('GlobalSetObj.UnloadingRollCall') }}</span>
                  <el-tooltip class="QuestionMark" placement="top">
                    <div slot="content">{{ $t('GlobalSetObj.AssociatedUnloadingPoint') }}</div>
                    <svg-icon icon-class="QuestionMark" />
                  </el-tooltip>
                </template>
                <el-input
                  v-model.trim="addEditForm.unloadingName"
                  :placeholder="$t('CenterForSorting.pleaseInput')"
                  clearable
                  maxlength="20"
                  show-word-limit
                  type="text"
                >
                  <template slot="prepend">DS-</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="23">
              <el-row :gutter="20">
                <el-col :span="12">
                  <!-- 负责人： -->
                  <el-form-item :label="$t('GlobalSetObj.PersonInCharge')" prop="principalName">
                    <el-input
                      v-model="addEditForm.principalName"
                      :placeholder="$t('GlobalSetObj.pleaseInput')"
                      clearable
                      maxlength="30"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <!-- 电话： -->
                  <el-form-item :label="$t('GlobalSetObj.contactNumber')" :rules="isPhone" prop="mobile">
                    <el-input
                      v-model.trim="addEditForm.mobile"
                      :placeholder="$t('GlobalSetObj.pleaseInput')"
                      clearable
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <!-- 所属州： -->
                  <el-form-item
                    :label="$t('GlobalSetObj.Estado')"
                    :rules="[{ required: true, message: $t('placeOrder.pleaseChoose'), trigger: ['blur', 'change'] }]"
                    prop="state"
                  >
                    <el-select
                      v-model="addEditForm.state"
                      :placeholder="$t('placeOrder.pleaseChoose')"
                      clearable
                      filterable
                      style="width: 100%;"
                    >
                      <el-option
                        v-for="item in senderStateList"
                        :key="item.id"
                        :label="item.label"
                        :value="item.label"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <!-- 所属城市： -->
                  <el-form-item
                    :label="$t('GlobalSetObj.Cidade')"
                    :rules="{ required: true, message: $t('placeOrder.pleaseInput'), trigger: ['blur','change'] }"
                    prop="city"
                  >
                    <el-input
                      v-model="addEditForm.city"
                      :placeholder="$t('GlobalSetObj.pleaseInput')"
                      clearable
                      maxlength="30"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-row :gutter="10">
                    <el-col :span="12">
                      <!-- 邮编：-->
                      <el-form-item :label="$t('GlobalSetObj.zipCode')" :rules="isZipCode" prop="zipCode">
                        <el-input
                          v-model.trim="addEditForm.zipCode"
                          :placeholder="$t('GlobalSetObj.pleaseInput')"
                          clearable
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <!-- Basecode：-->
                      <el-form-item :rules="BasecodeRule" label="Basecode" prop="baseCode">
                        <el-input
                          v-model.trim="addEditForm.baseCode"
                          :placeholder="$t('GlobalSetObj.pleaseInput')"
                          clearable
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="12">
                  <!--所属区： -->
                  <el-form-item :label="$t('GlobalSetObj.Zona')" prop="area">
                    <el-input
                      v-model="addEditForm.area"
                      :placeholder="$t('GlobalSetObj.pleaseInput')"
                      clearable
                      maxlength="30"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <!-- 所属街道： -->
                  <el-form-item :label="$t('GlobalSetObj.Bairro')" prop="street">
                    <el-input
                      v-model="addEditForm.street"
                      :placeholder="$t('GlobalSetObj.pleaseInput')"
                      clearable
                      maxlength="30"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <!-- 地址： -->
                  <el-form-item
                    :label="$t('GlobalSetObj.Endereo')"
                    :rules="[{ required: true, message: $t('placeOrder.pleaseInput'), trigger: ['blur','change'] },
                             { min: 2, max: 200, message: $t('newOrder.TdetailedAddressCharacter'), trigger: 'blur' }]"
                    prop="address"
                  >
                    <el-input
                      v-model="addEditForm.address"
                      :placeholder="$t('newOrder.StreetHouseNumber')"
                      clearable
                      maxlength="200"
                      type="text"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="22">
              <!-- 关联网点： -->
              <el-form-item
                :label="$t('GlobalSetObj.AssociatedOutlets')"
                prop="associatedOutlets"
              >
                <template slot="label">
                  <span>{{ $t('GlobalSetObj.AssociatedOutlets') }}</span>
                  <el-tooltip class="QuestionMark" placement="top">
                    <div slot="content">{{ $t('GlobalSetObj.carregamento') }}</div>
                    <svg-icon icon-class="QuestionMark" />
                  </el-tooltip>
                </template>
                <el-button
                  size="medium"
                  type="primary"
                  @click="selectSites"
                >{{ $t('GlobalSetObj.SelectSites') }}
                </el-button>
                <div v-if="addEditForm.associatedOutlets.length > 0" style="padding: 5px;border: 1px solid #ccc;">
                  <div
                    v-for="(item, ind) in addEditForm.associatedOutlets"
                    :key="ind"
                    class="tabless"
                  >
                    <div style="width: 100%;display: flex;">
                      <div class="wordBreak" style="max-width: 280px;">{{ item.name }}</div>
                      <div style="cursor: pointer;font-size: 24px;margin-left: 5px;" @click="deleteSite(item)">×</div>
                    </div>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div style="margin-right: 20px; text-align: right;">
        <el-button style="margin-right: 20px" @click="showAddData = false">{{
          $t('GlobalSetObj.Cancelar')
        }}
        </el-button>
        <el-button type="primary" @click="saveRouter">{{ $t('GlobalSetObj.confirm') }}</el-button>
      </div>
    </el-drawer>
    <!--选择网点-->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="true"
      :title="$t('GlobalSetObj.SelectSites')"
      :visible.sync="showSelectSites"
      top="2vh"
      width="70%"
      @close="allCancelSelect"
    >
      <div>
        <el-form label-position="top">
          <el-row :gutter="10">
            <el-col :span="8">
              <!-- 网点名： -->
              <el-form-item :label="$t('newOrder.NetRollCall')">
                <el-input
                  v-model.trim="querySiteForm.params.name"
                  :placeholder="$t('CenterForSorting.pleaseInput')"
                  clearable
                  @blur="searchSite"
                  @keyup.enter.native="searchSite"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <!-- 网点邮编： -->
              <el-form-item :label="$t('GlobalSetObj.dotZipCode')">
                <el-input
                  v-model.trim="querySiteForm.params.zipCode"
                  :placeholder="$t('CenterForSorting.pleaseInput')"
                  clearable
                  @blur="searchSite"
                  @keyup.enter.native="searchSite"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-row :gutter="10">
          <el-col :span="12">
            <div style="line-height: 30px">
              <!--全部-->
              <span>{{ $t('GlobalSetObj.all') }}：{{ siteTotal }}</span>
              <span style="float:right;">
                <!--全部选中-->
                <el-button size="small" type="text" @click="allSelect">{{ $t('GlobalSetObj.SelectAll') }}</el-button>
              </span>
            </div>
            <el-table
              :data="tableDataLeft"
              :height="500"
              border
              style="width: 100%"
            >
              <!--网点名-->
              <el-table-column
                :label="$t('newOrder.NetRollCall')"
                min-width="160"
                prop="name"
              />
              <!--网点邮编-->
              <el-table-column
                :label="$t('GlobalSetObj.dotZipCode')"
                align="center"
                min-width="120"
                prop="zipCode"
              />
              <el-table-column
                :label="$t('GlobalSetObj.operate')"
                align="center"
                fixed="right"
                prop="date"
              >
                <template slot-scope="scope">
                  <el-button
                    :class="selectedSiteId.includes(scope.row.id) ? 'cancelSelectColor' : 'selectColor'"
                    size="mini"
                    style="cursor: pointer"
                    type="text"
                    @click="selectedSites(scope.row)"
                  >
                    {{ $t('GlobalSetObj.Selected') }}
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              :current-page="querySiteForm.pageNumber"
              :page-size="querySiteForm.pageSize"
              :page-sizes="[10, 100]"
              :pager-count="5"
              :total="siteTotal"
              background
              layout="sizes, prev, pager, next"
              style="margin: 10px 0;float: right"
              @size-change="handleSizeChangeSite"
              @current-change="handleCurrentChangeSite"
            />
          </el-col>
          <el-col :span="12">
            <div style="line-height: 30px">
              <!--已选-->
              <span>{{ $t('GlobalSetObj.selected') }}：{{ tableDataRight.length }}</span>
              <span style="float:right;">
                <!--全部取消-->
                <el-button size="small" type="text" @click="allCancelSelect">{{
                  $t('GlobalSetObj.CancelAll')
                }}</el-button>
              </span>
            </div>
            <el-table
              :data="tableDataRight"
              :height="500"
              border
              style="width: 100%"
            >
              <!--网点名-->
              <el-table-column
                :label="$t('newOrder.NetRollCall')"
                min-width="160"
                prop="name"
              />
              <!--网点邮编-->
              <el-table-column
                :label="$t('GlobalSetObj.dotZipCode')"
                align="center"
                min-width="120"
                prop="zipCode"
              />
              <el-table-column
                :label="$t('GlobalSetObj.operate')"
                align="center"
                fixed="right"
                prop="date"
              >
                <template slot-scope="scope">
                  <div>
                    <el-button size="mini" type="text" @click="cancelSelectedSites(scope)">{{
                      $t('basicData.Cancel')
                    }}
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="showSelectSites = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button size="small" type="primary" @click="saveSelectSite">{{ $t('GlobalSetObj.confirm') }}</el-button>
      </div>
    </el-dialog>
    <!--  复用地址  -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="true"
      :title="$t('GlobalSetObj.MultiplexAddress')"
      :visible.sync="addressDialog"
      :width="IsMdfe"
      top="15vh"
      @close="clearForm"
    >
      <div class="mdfeClass">
        <el-form :key="addressDialog" ref="mdfeForm" :model="addressForm" label-position="top">
          <!-- 选择派送网点名 -->
          <el-form-item
            :label="$t('GlobalSetObj.Selecionename')"
            prop="tagNumber"
          >
            <el-select
              v-model="addressForm.netName"
              v-loadmore="loadmore"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              style="width: 80%"
              @change="confirmCopye"
            >
              <el-option
                v-for="item in addressList"
                :key="item.id"
                :label="`${item.name}`"
                :value="item.id"
              >
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right">{{ item.zipCode }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="addressDialog = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button size="small" type="primary" @click="fillIn">{{ $t('GlobalSetObj.confirm') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  createUnloading,
  getDeliverSiteInfo,
  unloadinggetSiteInfo,
  unloadinggetUnloadingInfo,
  unloadingpage,
  updateUnloading,
  updateUnloadingStatus
} from '@/api/routerPlan';
import AnjunTableList from '@/components/anjun-tableList';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { isZipCodeAsync, MobilePhoneNumber } from '@/utils/validate';
import { checkedzipcode, getLgAreaListData } from '@/api/orderApi';
import { supplierMainPage } from '@/api/SupplierMain';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'Unloadingpoint',
  components: {
    AnjunTableList
  },
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      tableHeaderList: [
        // 名称
        { id: 2, label: this.$t('GlobalSetObj.Name'), prop: 'unloadingName', cloWidth: 180 },
        // 网点
        { id: 3, label: this.$t('GlobalSetObj.Outlets'), prop: 'unloadingSiteName', cloWidth: 350 },
        // 城市州
        { id: 4, label: this.$t('GlobalSetObj.CityState'), prop: 'cityState', cloWidth: 180 },
        // 邮箱
        { id: 8, label: this.$t('GlobalSetObj.zipCode'), prop: 'zipCode', cloWidth: 180 },
        // 状态
        { id: 5, label: this.$t('GlobalSetObj.status'), prop: 'statusName', cloWidth: 100 }
      ],
      tableData: [],
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          searchType: '1',
          inputValue: '',
          status: ''
        }
      },
      addEditForm: {
        id: '',
        associatedOutlets: [],
        unloadingName: '',
        principalName: '',
        mobile: '',
        zipCode: '',
        state: '',
        city: '',
        area: '',
        street: '',
        address: '',
        baseCode: ''
      },
      total: 0,
      isZipcode1: true, // 光标离开校验邮编-发件人
      isZipcode2: true, // 光标离开校验邮编-收件人
      isBlur: 0, // 光标移出 0 未移出不调用事件  1 已移出
      showAddData: false,
      rulesRouteNumber: [
        { required: true, message: this.$t('GlobalSetObj.PleaseEnter120Characters'), trigger: ['blur', 'change'] },
        { min: 1, max: 22, message: this.$t('GlobalSetObj.PleaseEnter120Characters'), trigger: ['blur', 'change'] }
      ],
      BasecodeRule: [
        { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: ['blur', 'change'] },
        { min: 6, max: 8, message: this.$t('GlobalSetObj.PleaseEnter8Characters'), trigger: ['blur', 'change'] }
      ],
      providerNameCompany: [
        { required: true, message: this.$t('GlobalSetObj.SelecioneDestinationCompany'), trigger: ['blur', 'change'] }
      ],
      isPhone: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        { validator: MobilePhoneNumber, trigger: ['blur', 'change'] }
      ],
      isZipCode: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        { validator: isZipCodeAsync, trigger: ['blur'] }
      ],
      showSelectSites: false,
      tableDataLeft: [],
      leftTotal: 0,
      tableDataRight: [],
      selectedSiteId: [],
      siteTotal: 0,
      querySiteForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          name: '',
          zipCode: ''
        }
      },
      userIdAndNameObj: null,
      titleDialog: 1,
      cancelSelectedSitesList: [],
      senderStateList: [], // 州列表
      supplierMainList: [], // 供应商
      showAddData1: 0,
      switchSearchType: this.$t('GlobalSetObj.Name'),
      searchList: [],
      showList: false,
      searchStr: '',
      searchNumberType: '1',
      addressDialog: false, // 复用地址弹框
      addressForm: {
        netName: ''
      },
      addressList: [],
      confirmCopyeObj: {},
      statusBtn: 1,
      copyPage: { // 复用地址参数
        pageNumber: 1,
        pageSize: 10,
        params: {
          name: ''
        }
      }
    };
  },
  computed: {
    IsWidth() {
      return {
        width: this.isTormat ? '150px' : '100px'
      };
    },
    IsMdfe() {
      return this.isTormat ? '38%' : '30%';
    }
  },
  watch: {
    showAddData: function(val) {
      console.log(val);
      // this.$refs['addForm']?.resetFields();
    },
    showSelectSites: function(val) {
      // this.tableDataRight = [];
    }
  },
  created() {
    this.userIdAndNameObj = getUserIdAndNameObj();
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getSearch();
    this.getAddress();
    this.getsupplierMain();
  },
  methods: {
    // 复用地址
    copyAddress() {
      this.addressDialog = true;
      this.copyPage.pageSize = 10;
      getDeliverSiteInfo(this.copyPage).then(res => {
        if (res.status === 'OK') {
          this.addressList = res.data.records;
        }
      });
    },
    loadmore() {
      this.copyPage.pageSize = this.copyPage.pageSize + 10;
      getDeliverSiteInfo(this.copyPage).then(res => {
        if (res.status === 'OK') {
          this.addressList = res.data.records;
        }
      });
    },
    // 确认复制
    confirmCopye(val) {
      if (val) {
        const obj = this.addressList.find(item => {
          return item.id == val;
        });
        this.confirmCopyeObj = obj;
        console.log(obj, 'objobjobj确认复制');
      } else {
        this.confirmCopyeObj = {};
      }
    },
    fillIn() {
      this.addEditForm.address = this.confirmCopyeObj.address;
      this.addEditForm.unloadingName = this.confirmCopyeObj.name;
      this.addEditForm.principalName = this.confirmCopyeObj.contactsName;
      this.addEditForm.zipCode = this.confirmCopyeObj.zipCode;
      this.addEditForm.state = this.confirmCopyeObj.state;
      this.addEditForm.city = this.confirmCopyeObj.city;
      this.addEditForm.area = this.confirmCopyeObj.area;
      this.addEditForm.street = this.confirmCopyeObj.street;
      this.addEditForm.mobile = this.confirmCopyeObj.contactsTel;
      this.addEditForm.baseCode = this.confirmCopyeObj.baseCode;
      this.addressDialog = false;
    },
    // 是否停用
    updatStatus(id, status) {
      const data = {};
      data.status = status;
      // this.$nextTick(() => {
      //   this.$refs.anjunTable.elSwitchValue = status;
      // });
      data.unloadingName = 'DS-' + this.addEditForm.unloadingName;
      data.unloadingId = id;
      updateUnloadingStatus(data).then(res => {
        if (res.status === 'OK') {
          this.statusBtn = data.status;
          this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
          // this.getSearchEnter();
          this.getSearch();
        }
      });
    },
    // 切换搜索类型
    SwitchType(val) {
      if (val === '1') {
        this.switchSearchType = this.$t('GlobalSetObj.Name');
      } else {
        this.switchSearchType = this.$t('GlobalSetObj.Outlets');
      }
    },
    // 获取所有供应商
    getsupplierMain() {
      const data = {
        pageSize: 100,
        pageNumber: 1,
        params: {}
      };
      supplierMainPage(data).then((res) => {
        console.log(res);
        if (res.status === 'OK') {
          this.supplierMainList = res.data.records;
        }
      });
    },
    getSearchEnter() {
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    // 查询
    getSearch(type) {
      this.searchList = [];
      const numberList = this.searchStr.split('\n');
      numberList.map(item => {
        if (item) {
          item.toString().trim();
          if (!this.searchList.includes(item)) {
            this.searchList.push(item);
          }
        }
      });
      if (type === 1) {
        this.queryForm.params.inputValue = '';
        this.queryForm.pageNumber = 1;
        this.showList = false;
      }
      let inputValList = [];
      if (this.queryForm.params.inputValue) {
        this.queryForm.pageNumber = 1;
        inputValList.push(this.queryForm.params.inputValue);
      } else {
        inputValList = [...this.searchList];
      }
      const data = {};
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.pageNumber;
      data.params = {};
      data.params.status = this.queryForm.params.status || null;
      if (this.queryForm.params.searchType === '1') {
        data.params.unloadingNames = inputValList.length ? inputValList : null;
      } else {
        data.params.unloadingSites = inputValList.length ? inputValList : null;
      }
      unloadingpage(data).then(res => {
        if (res.status === 'OK') {
          console.log(res, 'unloadingpage');
          this.total = res.data.total;
          this.tableData = res.data.records;
          this.tableData.forEach((item, index) => {
            item.elSwitchValue = item.status;
            item.editBtn = true;
            item.cityState = item.city + '/' + item.state;
            item.statusName = item.status == 1 ? `${this.$t('GlobalSetObj.enable')}` : `${this.$t('GlobalSetObj.stop')}`;
          });
        }
      });
    },
    // 分页获取站点
    getSitePageList() {
      unloadinggetSiteInfo(this.querySiteForm).then(res => {
        if (res.status === 'OK') {
          this.siteTotal = res.data.total;
          this.tableDataLeft = res.data.records;
          if (this.titleDialog === 2) {
            if (this.querySiteForm.params.name && this.querySiteForm.params.zipCode) {
              this.cancelSelectedSitesList.map(item => {
                if ((item.name.indexOf(this.querySiteForm.params.name) !== -1) && (item.zipCode === this.querySiteForm.params.zipCode)) {
                  this.tableDataLeft.push(item);
                  this.siteTotal += 1;
                }
              });
            } else if (this.querySiteForm.params.name) {
              this.cancelSelectedSitesList.map(item => {
                if ((item.name.indexOf(this.querySiteForm.params.name) !== -1)) {
                  this.tableDataLeft.push(item);
                  this.siteTotal += 1;
                }
              });
            } else if (this.querySiteForm.params.zipCode) {
              this.cancelSelectedSitesList.map(item => {
                if ((item.zipCode === this.querySiteForm.params.zipCode)) {
                  this.tableDataLeft.push(item);
                  this.siteTotal += 1;
                }
              });
            } else {
              this.cancelSelectedSitesList.map(item => {
                this.tableDataLeft.push(item);
                this.siteTotal += 1;
              });
            }
          }
        }
      });
    },
    getSitePageList1() {
      unloadinggetSiteInfo(this.querySiteForm).then(res => {
        if (res.status === 'OK') {
          this.tableDataLeft = [];
          this.siteTotal = res.data.total;
          this.tableDataLeft = res.data.records;
        }
      });
    },
    // 切换终点公司
    handleproviderName(val) {
      console.log(val, 'handleproviderName');
      this.addEditForm.providerId = val;
      const obj = this.supplierMainList.find((item) => {
        return item.id == this.addEditForm.providerId || item.id == this.addEditForm.providerName;
      });
      console.log(obj, 'objobj');
      if (obj) {
        this.addEditForm.providerId = obj.id;
        this.addEditForm.providerName = obj.providerName;
      }
    },
    // 点击编辑 新建
    addRoute(e, row) {
      this.tableDataRight = [];
      let target = e.target;
      if (target.nodeName === 'I' || target.nodeName === 'SPAN') {
        target = e.target.parentNode;
      }
      target.blur();
      if (row) {
        console.log(row, 'row');
        this.addEditForm.unloadingName = row.unloadingName.substring(3);
        if (row.baseCode) {
          this.addEditForm.baseCode = row.baseCode;
        }
        this.addEditForm.city = row.city;
        this.addEditForm.area = row.area;
        this.addEditForm.mobile = row.mobile;
        this.addEditForm.zipCode = row.zipCode;
        this.addEditForm.state = row.state;
        this.addEditForm.street = row.street;
        this.addEditForm.principalName = row.principalName;
        this.addEditForm.address = row.address;
        this.addEditForm.id = row.id;
        this.titleDialog = 2;
        this.statusBtn = row.status;
        // 获取站点
        this.getSitePageList();
        this.getEditLineDot();
      } else {
        this.titleDialog = 1;
      }
      this.showAddData = true;
    },
    // 查询卸货点已关联的网点
    getEditLineDot() {
      const _this = this;
      const param = {
        unloadingId: this.addEditForm.id
      };
      unloadinggetUnloadingInfo(param).then(res => {
        if (res.status === 'OK') {
          // this.addEditForm.transportCompanyId = res.data[0].transportCompanyId;
          this.addEditForm.associatedOutlets = [];
          const selectOutletArray = [];
          if (res.data) {
            res.data.map(item => {
              if (item.id && item.name) {
                selectOutletArray.push({
                  id: item.id,
                  name: item.name,
                  zipCode: item.zipCode
                });
              }
            });
            _this.tableDataRight = selectOutletArray;
            _this.addEditForm.associatedOutlets = selectOutletArray;
          } else {
            _this.tableDataRight = [];
          }
        }
      });
    },
    // 确定新建路线
    saveRouter() {
      this.$refs['addForm'].validate((valid) => {
        if (valid) {
          console.log(this.addEditForm, 'this.addEditForm');
          let data = {};
          data = cloneDeep(this.addEditForm);
          delete data.associatedOutlets;
          data.unloadingName = 'DS-' + this.addEditForm.unloadingName;
          data.unloadingSite = this.addEditForm.associatedOutlets.length > 0 ? JSON.stringify(this.addEditForm.associatedOutlets) : '';
          data.address = this.addEditForm.address.replace(/^\s+|\s+$/g, '');
          console.log(data, 'datadatadatadata');
          // return;
          if (this.titleDialog === 1) {
            createUnloading(data).then(res => {
              if (res.status === 'OK') {
                this.getSearchEnter();
                this.getSitePageList();
                this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
                this.showAddData = false;
                this.$emit('siteTotal');
              }
            });
          } else {
            data.unloadingId = this.addEditForm.id;
            data.status = this.statusBtn;
            updateUnloading(data).then(res => {
              if (res.status === 'OK') {
                this.getSearchEnter();
                this.getSitePageList();
                this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
                this.showAddData = false;
                this.$emit('siteTotal');
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    // 清除详情数据
    clearAddEditSiteForm() {
      this.addEditForm = {
        id: '',
        associatedOutlets: [],
        unloadingName: '',
        principalName: '',
        mobile: '',
        zipCode: '',
        state: '',
        city: '',
        area: '',
        street: '',
        address: '',
        baseCode: ''
      };
      this.cancelSelectedSitesList = [];
    },
    // 删除已选站点
    deleteSite(row) {
      this.addEditForm.associatedOutlets.map((item, ind) => {
        if (row.id === item.id) {
          this.addEditForm.associatedOutlets.splice(ind, 1);
        }
      });
    },
    // 保存关联站点
    saveSelectSite() {
      this.addEditForm.associatedOutlets = cloneDeep(this.tableDataRight);
      console.log(this.addEditForm.associatedOutlets, 'this.addEditForm.associatedOutlets');
      this.showSelectSites = false;
    },
    // 搜索站点
    searchSite() {
      this.querySiteForm.pageNumber = 1;
      this.getSitePageList();
    },
    // 全部取消
    allCancelSelect() {
      this.querySiteForm.params.name = '';
      this.querySiteForm.params.zipCode = '';
      this.selectedSiteId = [];
      this.tableDataLeft = this.tableDataLeft.concat(this.tableDataRight);
      this.tableDataLeft = Array.from(new Set(this.tableDataLeft.map(JSON.stringify))).map(JSON.parse);
      this.tableDataRight = [];
    },
    // 取消选中
    cancelSelectedSites(scope) {
      this.tableDataRight.splice(scope.$index, 1);
      const ind = this.selectedSiteId.indexOf(scope.row.id);
      this.selectedSiteId.splice(ind, 1);
      const noSelect = [];
      if (this.titleDialog === 2) {
        this.tableDataLeft.map(item => {
          if (!noSelect.includes(item.id)) {
            noSelect.push(item.id);
          }
        });
        if (!noSelect.includes(scope.row.id)) {
          this.tableDataLeft.push(scope.row);
          this.cancelSelectedSitesList.push(scope.row);
        }
      }
    },
    // 全部选中
    allSelect() {
      this.tableDataLeft.map(item => {
        if (!this.selectedSiteId.includes(item.id)) {
          this.selectedSiteId.push(item.id);
          this.tableDataRight.push(item);
        }
      });
    },
    // 选中站点
    selectedSites(row) {
      if (!this.selectedSiteId.includes(row.id)) {
        this.selectedSiteId.push(row.id);
        this.tableDataRight.push(row);
      }
    },
    // 打开选择站点弹窗
    selectSites() {
      this.querySiteForm.pageNumber = 1;
      this.getSitePageList1();
      this.showSelectSites = true;
      this.getEditLineDot();
      this.tableDataRight.map(row => {
        this.selectedSiteId.push(row.id);
      });
    },
    // 搜索列表
    getSearchFun() {
      this.queryForm.pageNumber = 1;
      this.getSearchRouteTable();
    },
    // 查询列表
    getSearchRouteTable() {
    },
    // 重置
    resetForm() {
      this.queryForm.params.status = '';
      this.queryForm.params.inputValue = '';
      this.queryForm.params.searchType = '1';
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    clearForm() {
      this.addressForm.netName = '';
    },
    // 点击分页查询路线
    handleSizeChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    // 点击分页查询站点
    handleSizeChangeSite(val) {
      this.querySiteForm.pageSize = val;
      this.querySiteForm.pageNumber = 1;
      this.getSitePageList();
    },
    handleCurrentChangeSite(val) {
      this.querySiteForm.pageNumber = val;
      this.getSitePageList();
    },
    // 清除列表搜索
    clearSearch() {
      this.searchList = [];
      this.searchStr = '';
    },
    // 打开\关闭多个单号输入
    openSearchList() {
      this.showList = !this.showList;
      this.queryForm.params.inputValue = '';
    },
    // 打开或者关闭
    openText() {
      this.showList = !this.showList;
    },
    // 切换
    handleChange(val) {
      console.log(val, 'driverDocument');
      this.queryForm.params.status = val || '';
      this.getSearchEnter();
    },
    // 级联地区
    getAddress() { // level 0 州    1 城市   2 地区
      const params = {
        level: 0,
        parentId: ''
      };
      getLgAreaListData(params, false).then((res) => {
        if (res.status === 'OK') {
          this.senderStateList = res.data.map(item => {
            return {
              id: item.id,
              label: `${item.nameEn}`
            };
          });
        }
      });
    },
    // 邮编校验
    checkPostcode(postcode) {
      // pType === 1 发件人   pType === 2 收件人
      if (this.isBlur === 1) {
        const data = {};
        data.zipcode = postcode;
        checkedzipcode(data).then(res => {
          // console.log('验证邮编', res);
          if (res.status === 'OK') {
            if (!res.data) {
              this.$message.error(this.$t('placeOrder.inputCodeErr'));
              this.addEditForm.zipCode = '';
            }
          }
        }).catch(() => {
          this.addEditForm.zipCode = '';
        });
      }
    }
  }
};

</script>
<style scoped>
.right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.app-container >>> .el-drawer__header {
  margin-bottom: 20px !important;
}

.selectColor {
  color: #409EFF
}

.cancelSelectColor {
  color: #666666
}

.wordBreak {
  word-break: break-all; /*word-break: normal|break-all|keep-all;在恰当的断字点进行换行,break-all 允许在单词内换行,keep-all 只能在半角空格或连字符处换行,normal 使用浏览器默认的换行规则。*/
  text-overflow: ellipsis; /*溢出文字显示省略号*/
  display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 1; /** 显示的行数 **/
  overflow: hidden; /** 隐藏超出的内容 **/
}

.tabless {
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 0 10px;
  margin: 5px;
  background: #E1F7F2;
}

.app-container >>> .selectLabel .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

.app-container >>> .selectLabel .el-input__icon {
  line-height: 28px !important;
}

.multipleQuery ::v-deep .el-form-item__content {
  margin-left: 0 !important;
}

.searchMultiple {
  display: flex;
}

.searchMultiple .el-popover__reference-wrapper .btnIcon {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 40px;
}

.btnIcon {
  padding: 6px;
}

.app-container >>> .searchMultiple .el-input__inner {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.app-container >>> .searchMultiple .el-input-group__prepend {
  background-color: transparent !important;
}

.searchBtn {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  width: 40px;
  height: 40px;
  padding: 7px;
}

.searchNumWarp {
  height: 28px;
  border: 1px solid #91B8FA;
  border-right: none;
  padding: 5px 5px 5px 5px;
  background: rgba(145, 184, 250, 0.2);
}

.searchNumWarp p {
  margin: 0;
}

.clearSearch {
  height: 28px;
  border: 1px solid #91B8FA;
  border-left: none;
  background: rgba(145, 184, 250, 0.2);
  cursor: pointer;
  padding-right: 5px;
  padding-top: 5px;
}

.wordBreak {
  word-break: break-all; /*word-break: normal|break-all|keep-all;在恰当的断字点进行换行,break-all 允许在单词内换行,keep-all 只能在半角空格或连字符处换行,normal 使用浏览器默认的换行规则。*/
  text-overflow: ellipsis; /*溢出文字显示省略号*/
  display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 1; /** 显示的行数 **/
  overflow: hidden; /** 隐藏超出的内容 **/
}

.app-container >>> .el-form--label-top .el-form-item__label {
  width: 100% !important;
}

.searchTip {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 15px;
  height: 28px;
  cursor: pointer;
}

.app-container >>> .el-dialog__body {
  padding: 10px 20px;
}

.app-container /deep/ .el-table__fixed-right {
  height: 100% !important;
}
</style>
