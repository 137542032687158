var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _c(
      "div",
      { staticClass: "formClass" },
      [
        _c(
          "el-form",
          {
            ref: "queryForm",
            staticClass: "queryFormClass",
            attrs: {
              "label-position": _vm.$i18n.locale != "zh" ? "top" : "right",
              "label-width": _vm.$i18n.locale == "zh" ? "120px" : "",
              model: _vm.param,
              rules: _vm.rules,
            },
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _vm.operationType === 2
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("basicData.FeeCode"),
                              prop: "code",
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.param.code))])]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.ExpenseName"),
                          prop: "name",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: _vm.$t("basicData.PleaseEnter"),
                          },
                          model: {
                            value: _vm.param.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.param, "name", $$v)
                            },
                            expression: "param.name",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.ExpenseName") + "(PU)",
                          prop: "namePu",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: _vm.$t("basicData.PleaseEnter"),
                          },
                          model: {
                            value: _vm.param.namePu,
                            callback: function ($$v) {
                              _vm.$set(_vm.param, "namePu", $$v)
                            },
                            expression: "param.namePu",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("collectionCenter.businessT"),
                          prop: "businessType",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                              multiple: "",
                            },
                            model: {
                              value: _vm.param.businessType,
                              callback: function ($$v) {
                                _vm.$set(_vm.param, "businessType", $$v)
                              },
                              expression: "param.businessType",
                            },
                          },
                          [
                            _c("el-option", {
                              key: "0",
                              attrs: {
                                label: _vm.$t(
                                  "collectionCenter.expressDelivery"
                                ),
                                value: 0,
                              },
                            }),
                            _c("el-option", {
                              key: "1",
                              attrs: {
                                label: _vm.$t("collectionCenter.lanshou"),
                                value: 1,
                              },
                            }),
                            _c("el-option", {
                              key: "2",
                              attrs: {
                                label: _vm.$t("collectionCenter.delivery"),
                                value: 2,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.status"),
                          prop: "state",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "small" },
                            model: {
                              value: _vm.param.state,
                              callback: function ($$v) {
                                _vm.$set(_vm.param, "state", $$v)
                              },
                              expression: "param.state",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: false } }, [
                              _vm._v(_vm._s(_vm.$t("basicData.disable"))),
                            ]),
                            _c("el-radio-button", { attrs: { label: true } }, [
                              _vm._v(_vm._s(_vm.$t("basicData.enable"))),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.remark"),
                          prop: "remark",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: _vm.$t("basicData.PleaseEnter"),
                            rows: 4,
                            type: "textarea",
                          },
                          model: {
                            value: _vm.param.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.param, "remark", $$v)
                            },
                            expression: "param.remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.SpecialRules"),
                          prop: "specialRules",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            },
                            model: {
                              value: _vm.param.specialRules,
                              callback: function ($$v) {
                                _vm.$set(_vm.param, "specialRules", $$v)
                              },
                              expression: "param.specialRules",
                            },
                          },
                          [
                            _c("el-option", {
                              key: "0",
                              attrs: {
                                label: _vm.$t(
                                  "basicData.PercentageOfDeclaredAmountMinimumCanBeSet"
                                ),
                                value: 0,
                              },
                            }),
                            _c("el-option", {
                              key: "1",
                              attrs: {
                                label:
                                  _vm.$t("basicData.OrderAllCostRatioGRIS") +
                                  "（GRIS）",
                                value: 1,
                              },
                            }),
                            _c("el-option", {
                              key: "2",
                              attrs: {
                                label: _vm.$t("basicData.Issn1"),
                                value: 2,
                              },
                            }),
                            _c("el-option", {
                              key: "7",
                              attrs: {
                                label: _vm.$t("basicData.Issn"),
                                value: 7,
                              },
                            }),
                            _c("el-option", {
                              key: "3",
                              attrs: {
                                label: "ICMS" + _vm.$t("basicData.tax"),
                                value: 3,
                              },
                            }),
                            _c("el-option", {
                              key: "4",
                              attrs: {
                                label: _vm.$t(
                                  "basicData.ExcessWeightSurcharges"
                                ),
                                value: 4,
                              },
                            }),
                            _c("el-option", {
                              key: "5",
                              attrs: {
                                label:
                                  "*BRL/*KG（" +
                                  _vm.$t("basicData.roundUp") +
                                  "）",
                                value: 5,
                              },
                            }),
                            _c("el-option", {
                              key: "6",
                              attrs: {
                                label: "*BRL/" + _vm.$t("basicData.ticket"),
                                value: 6,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "permit",
            rawName: "v-permit:remove",
            value: "btn:finance:basicData:feesType:add",
            expression: "'btn:finance:basicData:feesType:add'",
            arg: "remove",
          },
        ],
        staticStyle: {
          "text-align": "right",
          padding: "10px",
          "border-top": "1px solid #ccc",
        },
      },
      [
        _c(
          "el-button",
          {
            on: {
              click: function ($event) {
                return _vm.saveFormData(2)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("basicData.Cancel")))]
        ),
        _c(
          "el-button",
          {
            attrs: { type: "primary" },
            on: {
              click: function ($event) {
                return _vm.saveFormData(1)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("basicData.Save")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }