<template>
  <div style="height: 60vh;margin: 0 -20px;overflow-y: auto;">
    <div class="msgContainer">
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="grid-content bg-purple detailStyle">
            <h2 style="text-align: center;">{{ formData.title }}</h2>
            <div v-html="formData.msgData" />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'MessageDetail',
  filters: {
    fileType: (value) => {
      if (value === null || value === '' || value === undefined) return '';
      value = value.toString();
      let typeName = '';
      switch (value) {
        case '0':
          typeName = '公告';
          break;
        case '1':
          typeName = '站内';
          break;
        case '2':
          typeName = '系统';
          break;
        case '3':
          typeName = '工单';
          break;
        case '4':
          typeName = '任务';
          break;
        case '5':
          typeName = '短信';
          break;
        case '6':
          typeName = '邮箱';
          break;
        case '7':
          typeName = '钉钉';
          break;
        case '8':
          typeName = '微信';
          break;
        default:
          typeName = '其他';
      }
      return typeName;
    }
  },
  props: {
    itemDetail: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      formData: {
        title: '',
        isPop: '0',
        msgData: '',
        msgType: '1',
        receiveId: ''
      },
      fileList: [],
      createName: null
    };
  },
  created() {
    this.createName = getUserIdAndNameObj(); // JSON.parse(sessionStorage.getItem('userIdAndName'));
    const getDetail = cloneDeep(this.itemDetail);
    const receiveName = this.createName[getDetail.receiveId];
    this.formData = getDetail;
    this.formData.receiveId = receiveName;
  },
  methods: {
  }
};
</script>

<style scoped>
.msgContainer >>> .pEditor p {
  margin: 0!important;
}
.detailStyle .el-form-item {
  margin-bottom: 10px;
}
.msgContainer {
  padding:20px;
}
</style>
