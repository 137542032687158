var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "warpp" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          {
            staticClass: "clearfix",
            attrs: { slot: "header" },
            slot: "header",
          },
          [
            _c("span", [
              _vm._v(_vm._s(_vm.$t("customerManagements.authorizationCode"))),
            ]),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "permit",
                    rawName: "v-permit:remove",
                    value: "but:customer-managements:list:AuthorizationCode",
                    expression:
                      "'but:customer-managements:list:AuthorizationCode'",
                    arg: "remove",
                  },
                ],
                staticStyle: { float: "right" },
                attrs: {
                  size: "mini",
                  type: "primary",
                  disabled: Number(_vm.customerId) === 0,
                },
                on: { click: _vm.handleMessage },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t("customerManagements.generateAuthorizationCode")
                  )
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "text item" },
          [
            Number(_vm.customerId) === 0
              ? _c("el-link", { attrs: { type: "info", underline: false } }, [
                  _vm._v(_vm._s(_vm.$t("customerManagements.CustomerNoExist"))),
                ])
              : _vm.authorizationCode.length === 0
              ? _c("el-link", { attrs: { type: "info", underline: false } }, [
                  _vm._v(
                    _vm._s(_vm.$t("customerManagements.noAuthorizationCode"))
                  ),
                ])
              : [
                  _c("p", { staticClass: "code" }, [
                    _vm._v(_vm._s(_vm.authorizationCode)),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "info",
                        size: "mini",
                        icon: "el-icon-document-copy",
                      },
                      on: { click: _vm.handleCopy },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("customerManagements.copyAuthorizationCode")
                        )
                      ),
                    ]
                  ),
                ],
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }