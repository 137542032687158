import { useQuery, useQueryClient, useMutation } from '@tanstack/vue-query';

import { routerService } from '.';
import { getSkipPage } from '@/utils/getSkipPage';
import getMessageError from '@/utils/getMessageError';
import { toast } from '@/utils/programaticToast';
import { getToastContentComponent } from '@/utils/getToastContentComponent';

export function useGetAllRoute(queryParams) {
  return useQuery({
    queryKey: ['routes', queryParams],
    queryFn: async({ signal }) => {
      try {
        const { currentPage, perPage } = queryParams.value;
        const skip = getSkipPage(currentPage, perPage);

        const params = {
          skip,
          take: perPage
        };

        const res = await routerService.getAll(params, signal);
        return res;
      } catch (error) {
        if (error.response && error.response.status === 400) {
          return [];
        }
        throw error;
      }
    }
  });
}

export function useCreateRouter() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (sellers) => routerService.create(sellers),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['routes'] });
      toast.open({
        variant: 'success',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Criado com sucesso', describe: 'Rota foi criada com sucesso em nosso sistema', typeIcon: 'success' }),
        closable: true,
        duration: 4000
      });
    },
    onError: (error) => {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Ocorreu um erro', describe: getMessageError(error), typeIcon: 'error' }),
        closable: true,
        duration: 4000
      });
    }
  });
}

export function useEditRouter() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ sellers, id }) => routerService.edit(sellers, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['routes'] });
      toast.open({
        variant: 'success',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Editado com sucesso', describe: 'Rota foi editada com sucesso em nosso sistema', typeIcon: 'success' }),
        closable: true,
        duration: 4000
      });
    },
    onError: (error) => {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Ocorreu um erro', describe: getMessageError(error), typeIcon: 'error' }),
        closable: true,
        duration: 4000
      });
    }
  });
}
