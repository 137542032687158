var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.disabled
        ? _c("ReceiveReadOnlyCmp", { attrs: { "rule-form": _vm.ruleForm } })
        : _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("newOrder.RecipientName"),
                            rules: _vm.rules.receiveName,
                            prop: "receiveName",
                          },
                        },
                        [
                          !_vm.disabled
                            ? _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "GlobalSetObj.pleaseInput"
                                  ),
                                },
                                model: {
                                  value: _vm.ruleForm.receiveName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "receiveName", $$v)
                                  },
                                  expression: "ruleForm.receiveName",
                                },
                              })
                            : _c("span", [
                                _vm._v(_vm._s(_vm.ruleForm.receiveName)),
                              ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("newOrder.RecipientCompany") },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.ruleForm.receiveCompany,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "receiveCompany", $$v)
                              },
                              expression: "ruleForm.receiveCompany",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("newOrder.Recipientphone"),
                            rules: _vm.rules.receiveName,
                            prop: "receiveMobile",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.ruleForm.receiveMobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "receiveMobile", $$v)
                              },
                              expression: "ruleForm.receiveMobile",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("driver.modalStreet") } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.ruleForm.receiveStreet,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "receiveStreet", $$v)
                              },
                              expression: "ruleForm.receiveStreet",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("newOrder.doorNumber") } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.ruleForm.receiveHouseNumber,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "receiveHouseNumber",
                                  $$v
                                )
                              },
                              expression: "ruleForm.receiveHouseNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("newOrder.RecipientDistrict"),
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.ruleForm.receiveArea,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "receiveArea", $$v)
                              },
                              expression: "ruleForm.receiveArea",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("newOrder.RecipientCity"),
                            rules: _vm.rules.receiveName,
                            prop: "receiveCity",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.ruleForm.receiveCity,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "receiveCity", $$v)
                              },
                              expression: "ruleForm.receiveCity",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("newOrder.RecipientState"),
                            rules: _vm.rules.receiveName,
                            prop: "receiveState",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.ruleForm.receiveState,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "receiveState", $$v)
                              },
                              expression: "ruleForm.receiveState",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("newOrder.RecipientZipCode"),
                            rules: _vm.rules.receiveName,
                            prop: "receiveZipcode",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.ruleForm.receiveZipcode,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "receiveZipcode", $$v)
                              },
                              expression: "ruleForm.receiveZipcode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.recipientComplement"),
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              autosize: { minRows: 2, maxRows: 4 },
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                              type: "textarea",
                            },
                            model: {
                              value: _vm.ruleForm.receiveComplement,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "receiveComplement", $$v)
                              },
                              expression: "ruleForm.receiveComplement",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("newOrder.RecipientCPF"),
                            rules: _vm.rules.receiveName,
                            prop: "receiveTax",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.ruleForm.receiveTax,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "receiveTax", $$v)
                              },
                              expression: "ruleForm.receiveTax",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("navbar.receiveIe"),
                            rules: _vm.rules.receiveName,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.ruleForm.receiveIeNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "receiveIeNumber", $$v)
                              },
                              expression: "ruleForm.receiveIeNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("newOrder.ReceivingAddress"),
                            rules: _vm.rules.receiveName,
                            prop: "receiveAddress",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              autosize: { minRows: 2, maxRows: 4 },
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                              type: "textarea",
                            },
                            model: {
                              value: _vm.ruleForm.receiveAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "receiveAddress", $$v)
                              },
                              expression: "ruleForm.receiveAddress",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("newOrder.Recipientphonenumber"),
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.ruleForm.receivePhone,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "receivePhone", $$v)
                              },
                              expression: "ruleForm.receivePhone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("newOrder.RecipientEmail") } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.ruleForm.receiveMail,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "receiveMail", $$v)
                              },
                              expression: "ruleForm.receiveMail",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }