<script >
import { ref, defineProps } from '@vue/composition-api';
import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { FormActions, FormActionsNext, FormActionsPrevious } from '@/components/wizard-form/formStepActions';
import { getToastContentComponent } from '@/utils/getToastContentComponent';
import { required } from '@/utils/validationErrors';
import { toast } from '@/utils/programaticToast';
import i18n from '@/lang';
const __sfc_main = {};
__sfc_main.props = {
  formData: {
    type: Object
  },
  handleNext: {
    type: Function,
    required: true
  },
  isLastStep: {
    type: Boolean,
    required: true
  },
  isFirstStep: {
    type: Boolean,
    required: true
  },
  handlePrevious: {
    type: Function,
    default: () => {}
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  function requiredList(value) {
    return value.length > 0;
  }
  function getRulesColors() {
    return Object.entries(props.formData.sealCode).reduce((acc, key) => {
      const keyColor = key[0];
      acc[keyColor] = {
        requiredList: helpers.withMessage('Insira pelo menos um lacre', requiredList)
      };
      return acc;
    }, {});
  }
  const rules = {
    quantityBags: {
      required
    },
    sealCode: getRulesColors()
  };
  const v$ = useVuelidate(rules, props.formData);
  const isOpenCollapse = ref(0);
  const listSealColors = [
  // i18n.t('routes.yellow'),
  // i18n.t('routes.blue'),
  // i18n.t('routes.white'),
  // i18n.t('routes.pink')
  'amarelo', 'azul', 'branco', 'rosa'];
  const optionsSealColors = ref([]);
  function filteredSealColor(text) {
    optionsSealColors.value = listSealColors.filter(color => color.startsWith(text));
  }
  function clearCodeSeals(colorValue) {
    props.formData.sealCode[colorValue] = [];
  }
  function handleAddListSeal(event, color) {
    const colorValue = event.target.value;
    if (!(colorValue.length >= 6 && colorValue.length <= 8)) {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Ocorreu um erro',
          describe: 'O número do lacre tem que ser entre 6 até 8 dígitos numéricos',
          typeIcon: 'error'
        }),
        closable: true,
        duration: 4000
      });
    } else if (props.formData.sealCode[color].includes(colorValue)) {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Ocorreu um erro',
          describe: 'Esse lacre já foi bipado',
          typeIcon: 'error'
        }),
        closable: true,
        duration: 4000
      });
    } else {
      props.formData.sealCode[color].push(colorValue);
      event.target.value = '';
    }
  }
  function removeSeal(seal, color) {
    const newListSeal = props.formData.sealCode[color].filter(sealItem => {
      return sealItem !== seal;
    });
    props.formData.sealCode[color] = newListSeal;
  }
  async function handleNextStep() {
    if (props.formData.selectedColors.length > 0) {
      for (const color of props.formData.selectedColors) {
        const isValid = await v$.value.sealCode[color].$validate();
        if (!isValid) {
          return;
        }
      }
      const isValidQtd = await v$.value.quantityBags.$validate();
      if (!isValidQtd) {
        return;
      }
    }
    console.log('entrou');
    props.handleNext();
  }
  return {
    props,
    v$,
    isOpenCollapse,
    optionsSealColors,
    filteredSealColor,
    clearCodeSeals,
    handleAddListSeal,
    removeSeal,
    handleNextStep
  };
};
__sfc_main.components = Object.assign({
  FormActions,
  FormActionsPrevious,
  FormActionsNext
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <form class="wrapper-form" @submit.prevent="handleNextStep" @keydown.enter.prevent>
    <o-field
      :label="$t('routes.selectedColorsLabel')"
      label-for="selectedColors"
      class="span-2"
    >
      <o-inputitems
        id="selectedColors"
        ref="inputColor"
        v-model="formData.selectedColors"
        :placeholder="$t('routes.selectedColorsPlaceholder')"
        :data="optionsSealColors"
        :allow-new="false"
        :open-on-focus="false"
        :autocomplete="true"
        :allow-autocomplete="true"
        @remove="clearCodeSeals"
        @typing="filteredSealColor"
      >
        <template #selected="{ items }">
          <span v-for="(item, index) in items" :key="index" class="item_selected" rounded @click="$refs.inputColor.removeItem(index, $event)">
            {{ item }}
            <div style="margin-left: 5px;cursor: pointer;" @click="$refs.inputColor.removeItem(index, $event)">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
            </div>
          </span>
        </template>
      </o-inputitems>
    </o-field>

    <o-field
      :label="$t('routes.quantityBagsLabel')"
      label-for="quantityBags"
      class="span-1"
      :message="v$.quantityBags.$errors.length ? v$.quantityBags.$errors[0].$message : null"
    >
      <o-input id="quantityBags" v-model="formData.quantityBags" :placeholder="$t('routes.quantityBagsPlaceholder')" />
    </o-field>

    <div v-if="formData.selectedColors.length" class="wrapper-colors  span-3">
      <o-collapse
        v-for="(color, index) in formData.selectedColors"
        :key="color"
        :open="isOpenCollapse == index"
        class="content-separator "
        @open="isOpenCollapse = index"
      >
        <template #trigger="props">
          <div
            role="button"
            aria-controls="contentIdForA11y5-formMeasurementVehicle"
            :aria-expanded="isOpenCollapse"
            class="wrapper-collapse"
          >
            <p class="name-color">
              {{ color }}
            </p>
            <div>
              <o-icon :icon="props.open ? 'caret-up' : 'caret-down'" />
            </div>
          </div>
        </template>

        <div class="wrapper-seal">
          <o-field
            :message="v$.sealCode[color].$errors.length ? v$.sealCode[color].$errors[0].$message : null"
          >
            <input
              id="numberSeal"
              :placeholder="$t('routes.colorPlaceholder')"
              class="o-input"
              autocomplete="off"
              @keydown.enter="(event) => {handleAddListSeal(event, color)}"
            >
          </o-field>

          <ul class="seal-list">
            <li v-for="(seal) in formData.sealCode[color]" :key="seal" class="seal-item">
              <p> {{ seal }} </p>
              <o-button slot="trigger" ref="trigger" variant="danger" size="sm" @click="removeSeal(seal, color)">
                <o-icon icon="trash" />
              </o-button>
            </li>
          </ul>
        </div>
      </o-collapse>
    </div>

    <FormActions>
      <FormActionsPrevious :is-first-step="isFirstStep" @handlePrevious="handlePrevious" />
      <FormActionsNext :is-last-step="isLastStep" />
    </FormActions>
  </form>
</template>

<style scoped>
  .wrapper-form {
    display: grid;
    gap: 1rem; /* 16px */
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .span-3 {
    grid-column: span 3 / span 3;
  }

  .span-2 {
    grid-column: span 2 / span 2;
  }

  .span-1 {
    grid-column: span 1 / span 1;
  }

  .wrapper-butons {
    display: flex;
    justify-content: flex-end;
    padding-top: 0.5rem;/* 8px */
  }

  .wrapper-colors {
    margin-bottom: 1rem/* 36px */;
    margin-top: 1rem;/* 16px */
    display: grid;
    gap: 1.25rem/* 20px */;
  }

  .content-separator {
    border-bottom-width: 1px;
    padding-bottom: 1rem/* 16px */;
  }

  .wrapper-collapse {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .name-color {
    font-weight: 700;
    text-transform: capitalize;
  }

  .wrapper-seal {
    margin-top: 0.5rem; /* 8px */

  }

  .seal-list{
    margin-top: 1rem;
    overflow: auto;
    max-height: 18.75rem; /* 300px */
    padding-left: 0.5rem; /* 8px */
  }

  .seal-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .seal-item ~ .seal-item {
    margin-top: 1rem;/* 8px */
  }
</style>
