<template>
  <div>
    <div style="margin-bottom: 10px">
      <!--下载模板-->
      <el-button
        icon="el-icon-download" type="primary" size="small"
        @click="downloadTemplate(1)">{{ $t('basicData.DownloadTheTemplate') }}</el-button>
    </div>
    <div style="margin-bottom: 10px">
      <!--导入分区-->
      <upload-excel
        :on-success="importLogistics"
        :but-name="fileName"
      />
    </div>
    <!--导入提示-->
    <el-dialog
      :title="$t('basicData.tips')"
      :visible.sync="importDialogShow"
      :append-to-body="true"
      width="30%"
    >
      <div>
        <div v-for="(item, ind) in errData" :key="ind" style="color: red;margin-bottom: 10px;">{{ item }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!--确 定-->
        <el-button type="primary" @click="importDialogShow = false">{{ $t('basicData.determine') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const ExcelJs = require('exceljs');
// import { Loading } from 'element-ui';
import { saveAs } from 'file-saver';
import { apiRouteDistanceReferenceImportAdd } from '@/api/finance/receivables';
// const options = {
//   lock: true,
//   spinner: 'el-icon-loading',
//   text: 'loading...',
//   background: 'rgba(250, 250, 250, 0.6)' // 加载动画的背景
// };
export default {
  components: {
    'upload-excel': () => import('@/components/UploadExcel/index')
  },
  props: {
    siteList: {
      type: Array,
      default: function() {
        return [];
      }
    },
    allTraceStateList: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      fileName: this.$t('GlobalSetObj.ImportRouteReference'),
      errData: [],
      importDialogShow: false
    };
  },
  methods: {
    // 下载模板
    downloadTemplate() {
      const that = this;
      async function ddd() {
        const wb = new ExcelJs.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        const Sheet2 = wb.addWorksheet('Sheet2');
        const Sheet3 = wb.addWorksheet('Sheet3');
        const columns = [
          {
            header: that.$t('GlobalSetObj.route') + '*', // 路线
            key: 'routeNumber',
            width: 40
          },
          {
            header: that.$t('operationTips.belongState') + '*', // 所属州
            key: 'provincesId',
            width: 40
          },
          {
            header: that.$t('GlobalSetObj.StartingStation1') + '*', // 始发地网点
            key: 'originSiteId',
            width: 40
          },
          {
            header: that.$t('GlobalSetObj.destinationPointSite') + '*', // 目的地网点
            key: 'destinationSiteId',
            width: 40
          }
        ];
        Sheet1.getCell('A2').value = 'RN01';
        Sheet1.getCell('A3').value = 'RN01';
        Sheet1.getCell('A4').value = 'RN01';
        Sheet1.getCell('B2').value = 'RN';
        Sheet1.getCell('B3').value = 'RN';
        Sheet1.getCell('B4').value = 'RN';
        Sheet1.getCell('C2').value = 'RN-W-H001';
        Sheet1.getCell('C3').value = 'RN-W-H004';
        Sheet1.getCell('C4').value = 'RN-W-H008';
        Sheet1.getCell('D2').value = 'RN-W-H004';
        Sheet1.getCell('D3').value = 'RN-W-H008';
        Sheet1.getCell('D4').value = 'RN-W-H007';
        Sheet1.mergeCells('A5:D5');
        Sheet1.getCell('A5').value = that.$t('GlobalSetObj.ThisTemplateCannotBeChanged'); // 以上为一个路线例子，所有都必须填，从第6行开始填写数据，不可更改此模板
        Sheet1.getCell('A5').font = {
          color: { argb: 'FF0000' }
        };
        Sheet1.getCell('A2').alignment = { wrapText: true };
        Sheet1.getRow(1).height = 25;
        Sheet1.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
        Sheet1.getRow(5).height = 25;
        Sheet1.getRow(5).alignment = { vertical: 'middle' };
        const Sheet2_data = [];
        Sheet2.columns = [
          {
            header: that.$t('operationTips.belongState'), // 所属州
            key: 'provincesId',
            width: 60
          }
        ];
        that.allTraceStateList.map(item => {
          Sheet2_data.push({
            provincesId: item.name + '->' + item.twoCode + ',' + item.id
          });
        });
        Sheet2.addRows(Sheet2_data);
        const Sheet3_data = [];
        Sheet3.columns = [
          {
            header: that.$t('GlobalSetObj.Outlets'), // 网点
            key: 'site',
            width: 60
          }
        ];
        that.siteList.map(item => {
          Sheet3_data.push({
            site: item.name + '->' + item.id
          });
        });
        Sheet3.addRows(Sheet3_data);
        Sheet1.columns = columns;
        new Array(99999).fill(0).forEach((_, idx) => {
          const row = idx + 2;
          Sheet1.getCell(row, 2).dataValidation = {
            type: 'list',
            formulae: [`=Sheet2!$A$2:$A${Sheet2_data.length + 1}`]
          };
          Sheet1.getCell(row, 3).dataValidation = {
            type: 'list',
            formulae: [`=Sheet3!$A$2:$A${Sheet3_data.length + 1}`]
          };
          Sheet1.getCell(row, 4).dataValidation = {
            type: 'list',
            formulae: [`=Sheet3!$A$2:$A${Sheet3_data.length + 1}`]
          };
        });
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        const saveName = that.$t('basicData.ImportRouteReferenceTableTemplate') + '.xlsx'; // 导入路线参照表模板

        saveAs(blob, saveName);
        return;
      }
      ddd();
    },
    // 导入
    importLogistics(arr) {
      const arrData = arr[0].results.splice(4);
      const header = arr[0].header;
      const headerKey = {
        [this.$t('GlobalSetObj.route') + '*']: 'routeNumber',
        [this.$t('operationTips.belongState') + '*']: 'provincesId',
        [this.$t('GlobalSetObj.StartingStation1') + '*']: 'originSiteId',
        [this.$t('GlobalSetObj.destinationPointSite') + '*']: 'destinationSiteId'
      };
      const allData = [];
      this.errData = [];
      
      // 封装检查函数，减少重复代码
      const checkValue = (value) => value !== '' && value !== null && value !== undefined;
      
      // 封装错误信息生成函数
      const generateErrorMessage = (rowIndex, key) => {
        const rowText = this.$t('basicData.row') + (rowIndex + 6) + this.$t('basicData.line');
        if (headerKey[key] === 'provincesId') {
          return `${rowText}：${this.$t('operationTips.belongState')}${this.$t('operationCenter.CannotBeEmpty')}`;
        } else if (headerKey[key] === 'originSiteId') {
          return `${rowText}：${this.$t('GlobalSetObj.StartingStation1')}${this.$t('operationCenter.CannotBeEmpty')}`;
        } else if (headerKey[key] === 'destinationSiteId') {
          return `${rowText}：${this.$t('GlobalSetObj.destinationPointSite')}${this.$t('operationCenter.CannotBeEmpty')}`;
        } else {
          return `${rowText}：${key}${this.$t('operationCenter.CannotBeEmpty')}`;
        }
      };
      
      arrData.forEach((item, i) => {
        const dataParam = {};
        header.forEach(key => {
          if (checkValue(item[key])) {
            if (headerKey[key] === 'provincesId') {
              const [provinces, provincesId] = item[key].split('->')[1].split(',');
              dataParam['provincesId'] = provincesId;
              dataParam['provinces'] = provinces;
            } else if (headerKey[key] === 'originSiteId' || headerKey[key] === 'destinationSiteId') {
              const [siteName, siteId] = item[key].split('->');
              dataParam[headerKey[key]] = siteId;
              dataParam[`${headerKey[key].replace('Id', 'Name')}`] = siteName;
            } else {
              dataParam[headerKey[key]] = item[key];
            }
          } else {
            this.errData.push(generateErrorMessage(i, key));
          }
        });
        allData.push(dataParam);
      });
      
      if (this.errData.length > 0) {
        this.importDialogShow = true;
        this.$message({
          type: 'error',
          message: this.$t('orderCenterCont.ImportFailed') + '！'
        });
      } else {
        apiRouteDistanceReferenceImportAdd(allData).then(res => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: this.$t('operationCenter.TheOperationSucceeded')
            });
            this.$emit('importPartition');
          }
        });
      }
    }

    // importLogistics(arr) {
    //   const arrData = arr[0].results.splice(4);
    //   const header = arr[0].header;
    //   const headerKey = {};
    //   headerKey[this.$t('GlobalSetObj.route') + '*'] = 'routeNumber';
    //   headerKey[this.$t('operationTips.belongState') + '*'] = 'provincesId';
    //   headerKey[this.$t('GlobalSetObj.StartingStation1') + '*'] = 'originSiteId';
    //   headerKey[this.$t('GlobalSetObj.destinationPointSite') + '*'] = 'destinationSiteId';
    //   const allData = [];
    //   this.errData = [];
    //   arrData.map((item, i) => {
    //     const data = {};
    //     header.map(list => {
    //       data[list] = (item[list] !== '' && item[list] !== null && item[list] !== undefined) ? item[list] : '';
    //     });
    //     const dataParam = {};
    //     Object.keys(data).forEach(key => {
    //       if (headerKey[key] === 'provincesId' || headerKey[key] === 'originSiteId' || headerKey[key] === 'destinationSiteId') {
    //         if (item[key] !== null && item[key] !== '' && item[key] !== undefined) {
    //           if (headerKey[key] === 'provincesId') {
    //             const arr = item[key].split('->');
    //             const codeId = arr[1].split(',');
    //             dataParam['provincesId'] = codeId[1];
    //             dataParam['provinces'] = codeId[0];
    //           } else if (headerKey[key] === 'originSiteId') {
    //             dataParam[headerKey[key]] = item[key].split('->')[1];
    //             dataParam['originSiteName'] = item[key].split('->')[0];
    //           } else if (headerKey[key] === 'destinationSiteId') {
    //             dataParam[headerKey[key]] = item[key].split('->')[1];
    //             dataParam['destinationSiteName'] = item[key].split('->')[0];
    //           }
    //         } else {
    //           if (headerKey[key] === 'provincesId') {
    //             this.errData.push(this.$t('basicData.row') + (i + 6) + this.$t('basicData.line') + '：' + this.$t('operationTips.belongState') + this.$t('operationCenter.CannotBeEmpty'));
    //           } else if (headerKey[key] === 'originSiteId') {
    //             this.errData.push(this.$t('basicData.row') + (i + 6) + this.$t('basicData.line') + '：' + this.$t('GlobalSetObj.StartingStation1') + this.$t('operationCenter.CannotBeEmpty'));
    //           } else {
    //             this.errData.push(this.$t('basicData.row') + (i + 6) + this.$t('basicData.line') + '：' + this.$t('GlobalSetObj.destinationPointSite') +this.$t('operationCenter.CannotBeEmpty'));
    //           }
    //         }
    //       } else {
    //         if (item[key] !== '' && item[key] !== null && item[key] !== undefined) {
    //           dataParam[headerKey[key]] = item[key];
    //         } else {
    //           this.errData.push(this.$t('basicData.row') + (i + 6) + this.$t('basicData.line') + '：' + key + this.$t('operationCenter.CannotBeEmpty'));
    //         }
    //       }
    //     });
    //     allData.push(dataParam);
    //   });
    //   if (this.errData.length > 0) {
    //     this.importDialogShow = true;
    //   } else {
    //     apiRouteDistanceReferenceImportAdd(allData).then(res => {
    //       if (res.code === 200) {
    //         this.$message({
    //           type: 'success',
    //           message: this.$t('operationCenter.TheOperationSucceeded')
    //         });
    //         this.$emit('importPartition');
    //       }
    //     });
    //   }
    // }
  }
};
</script>

<style scoped>

</style>
