const singleScan = {
  bagTable: {
    zh: '大袋信息',
    en: 'Bag Table',
    pu: 'Tabela de Saca'
  },
  bag: {
    zh: '大袋类型',
    en: 'Bag',
    pu: 'Saca'
  },
  numberBag: {
    zh: '大袋号',
    en: 'Number Bag',
    pu: 'Número da saca'
  },
  quantityPackage: {
    zh: '大袋数量',
    en: 'Quantity package',
    pu: 'Quantidade de pacotes'
  },
  action: {
    zh: '操作',
    en: 'Action',
    pu: 'Ações'
  },
  openBag: {
    zh: '打开袋',
    en: 'Open Bag',
    pu: 'Abrir saca'
  },
  closeBag: {
    zh: '封袋',
    en: 'Close bag',
    pu: 'Fechar saca'
  },
  package: {
    zh: '包裹',
    en: 'Package',
    pu: 'Pacotes'
  },
  trackingPackage: {
    zh: '轨迹',
    en: 'Tracking Package',
    pu: 'Tracking do Pacote'
  },
  beepAmount: {
    zh: '已扫数量',
    en: 'Beep amount',
    pu: 'Quantidade bipado'
  },
  numberPackage: {
    zh: '订单号',
    en: 'Number package',
    pu: 'Número do pacote'
  },
  typeBag: {
    zh: '大袋类型',
    en: 'Type bag',
    pu: 'Tipo de saca'
  },
  scanTime: {
    zh: '扫包裹时间',
    en: 'Scan time',
    pu: 'Tempo de scan'
  },
  analyzePackage: {
    zh: '分析包裹',
    en: 'Analyze package',
    pu: 'Análisar pacote'
  },
  addBag: {
    zh: '加袋',
    en: 'Add bag',
    pu: 'Adicionar saca'
  },
  send: {
    zh: '输送',
    en: 'Send',
    pu: 'Enviar'
  },
  enterTheBagNumber: {
    zh: '请输入大袋号',
    en: 'Enter the bag number',
    pu: 'Digite o número da saca'
  },
  errorProcessingPackage: {
    zh: '处理报包裹出现错误',
    en: 'Error processing package',
    pu: 'Erro ao processar pacote'
  },
  errorPackageLength: {
    zh: '包裹数量少，必须大于8件',
    en: 'Package number is too small, must be greater than 8',
    pu: 'Número do pacote é muito pequeno, deve ser maior que 8'
  },
  trackingInvalid: {
    zh: '轨迹无效，无法绑定任何大袋',
    en: 'Invalid tracking, it was not possible to associate it with any bag',
    pu: 'Tracking inválido, não foi possível associar ele a nenhuma saca'
  },
  duplicateTracking: {
    zh: '包裹已扫并已绑定大袋号',
    en: 'This package has already been biped and associated with a bag',
    pu: 'Esse pacote já foi bípado e associado a uma saca'
  },
  feature: {
    zh: '單次掃描',
    en: 'Single Scan',
    pu: 'Scan unico'
  },
  requiredField: {
    zh: 'Campo obrigatório, preencha antes de enviar',
    en: 'Required field, fill it in before submitting',
    pu: 'Campo obrigatório, preencha antes de enviar'
  },
  errorValidateBag: {
    zh: 'Campo precisa ser maior que 6 e so números',
    en: 'Field must be greater than 6 and only numbers',
    pu: 'Campo precisa ser maior que 6 e so números'
  },
  bagErrorWithoutPackage: {
    zh: `Você não pode fechar a saca sem ter nenhum pacote associada à ela`,
    en: `You cannot close bag without having any packages associated with it`,
    pu: `Você não pode fechar a saca sem ter nenhum pacote associada à ela`
  },
  errorProcessingBag: {
    zh: 'Error ao processar fechamento de saca',
    en: 'Error processing bag closing',
    pu: 'Error ao processar fechamento de saca'
  },
  packageErrorNoBagOpen: (nameBag) => ({
    zh: `Você precisa abrir uma saca do tipo ${nameBag} antes de adicionar pacotes`,
    en: `You need to open a bag of type ${nameBag} before adding packages`,
    pu: `Você precisa abrir uma saca do tipo ${nameBag} antes de adicionar pacotes`
  }),
  confirmBagClosing: {
    zh: 'Tem certeza que deseja fechar a saca ?',
    en: 'Are you sure you want to close the bag?',
    pu: 'Tem certeza que deseja fechar a saca ?'
  },
  irreversibleAction: {
    zh: 'Essa é uma ação irreversível, não é possível voltar atrás depois de fazer o fechamento',
    en: 'This is an irreversible action, it is not possible to go back after closing',
    pu: 'Essa é uma ação irreversível, não é possível voltar atrás depois de fazer o fechamento'
  },
  cancel: {
    zh: 'Cancelar',
    en: 'Cancel',
    pu: 'Cancelar'
  },
  enterPackageTracking: {
    zh: 'Digite o tracking do pacote',
    en: 'Enter package tracking',
    pu: 'Digite o tracking do pacote'
  }

};
export {
  singleScan
};
