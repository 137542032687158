import request from '@/utils/request';

// 理赔列表

// 分页
export const apiClaimformPage = data => {
  return request.post('/fa/claimform/page', data);
};

// 详情
export const apiClaimformIds = id => {
  return request.get(`fa/claimform/${id}`);
};

// 保存
export const apiClaimformAdd = data => {
  return request.post('/fa/claimform/add', data);
};

// 关闭
export const apiClaimformClose = id => {
  return request.get(`fa/claimform/close/${id}`);
};

// 修改
export const apiClaimformEdit = data => {
  return request.put('/fa/claimform/edit', data);
};

// 提交审核
export const apiClaimformCommit = id => {
  return request.post(`/fa/claimform/commit/${id}`);
};

// 关联订单详情-普通订单/备货揽收/快递单
export const apiClaimformOrderDetail = (data, isLoading = true) => {
  // return request.post('/fa/claimform/orderDetail', data);
  return request({
    method: 'POST',
    url: '/fa/claimform/orderDetail',
    data,
    isLoading // 是否显示加载中参数
  });
};

// 提交付款
export const apiClaimformCommitPay = data => {
  return request.post('/fa/claimform/commitPay', data);
};

// 审核
export const apiClaimformRejectAndPass = data => {
  return request.post('/fa/claimform/rejectAndPass', data);
};

// 关联订单-导出
export const apiClaimformExport = data => {
  return request.post('/fa/claimform/export', data);
};

// 理赔列表-导出
export const apiClaimformFormExport = data => {
  return request.post('/fa/claimform/formExport', data);
};

