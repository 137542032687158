import request from '@/utils/request';

/**
 * 操作指南——修改
 */
export const operationGuideEdit = (data) => {
  return request({
    url: '/operationGuide/update',
    method: 'put',
    data
  });
};

/**
 * 操作指南——新增
 */
export const operationGuideAdd = (data) => {
  return request({
    url: '/operationGuide/add',
    method: 'post',
    data
  });
};

/**
 * 操作指南——分页
 */
export const operationGuidePage = (data) => {
  return request({
    url: '/operationGuide/page',
    method: 'post',
    data
  });
};

/**
 * 资源管理 --- 获取已有资源
 * @param query
 * @returns
 */
export const getExistenceResources = query => {
  return request.post('/resource/page', query);
};
/**
 * 添加资源
 */
export const addResource = query => {
  return request.post('/user/resource/add', query);
};
/**
 * 删除资源
 */
export const deleteResource = query => {
  return request.post('/user/resource/delete?resourceId=' + query);
};
/**
 * 修改资源
 */
export const changeResource = query => {
  return request.post('/user/resource/edit', query);
};
/**
 * 获取资源标签列表
 */
export const getResourceTagList = query => {
  return request.post('/user/resourceTag/page', query);
};
/**
 * 新增资源标签
 */
export const addResourceTag = query => {
  return request.post('/user/resourceTag/add', query);
};
/**
 * 校验标签名称重复
 */
export const checkResourceTagName = query => {
  return request.post('/user/resourceTag/checkName?name=' + query);
};
/**
 * 资源标签修改
 */
export const editResourceTag = query => {
  return request.post('/user/resourceTag/edit', query);
};
/**
 * 资源标签删除
 */
export const deleteResourceTag = query => {
  return request.post('/user/resourceTag/delete?id=' + query);
};

/**
 * 资源导出审核
 */
export const apiFileExportVerify = (ids, verifyId, verifyName) => {
  return request({
    url: `/fileExport/verify/${ids}/${verifyId}/${verifyName}`,
    method: 'PUT'
  });
};

/**
 * 资源导出列表
 */
export const apiFileExportPage = data => {
  return request.post('/fileExport/page', data);
};
/**
 * 资源导出列表
 */
export const apiFileTaskPage = data => {
  return request({
    url: '/excelTask/page',
    method: 'post',
    data: data,
    isLoading: false
  });
};

// 消息记录管理
export const apiMessageSendRecordPage = data => {
  return request.post('/user/messageSendRecord/page', data);
};

// 发送短信
export const apiMessageSendRecordSendSms = data => {
  return request.post('/user/messageSendRecord/sendSms', data);
};

// 资源管理page查询
export const appManagePage = data => {
  return request.post('/app/manage/page', data);
};
// 资源管理——修改
export const appManageUpdate = data => {
  return request.post('/app/manage/update', data);
};
// 资源管理——新增
export const appManageAdd = data => {
  return request.post('/app/manage/add', data);
};
// 签收配置分页查询
export const restrictionPage = data => {
  return request.post('/sign/restriction/page', data);
};
// 根据州获取网点
export const getSiteByState = data => {
  return request.post(`/sign/restriction/getSiteByState`, data);
};
// 获取启用的短信模板
export const getOpenTemplate = () => {
  return request.get('/smsTemplate/getOpenTemplate');
};
// 新增签收配置
export const restrictionadd = (data) => {
  return request.post('/sign/restriction/add', data);
};
// 签收配置启用停用
export const restrictionisOpened = (data) => {
  return request.post('/sign/restriction/isOpened', data);
};
// 解除限制分页查询
export const signRescissionpage = (data) => {
  return request.post('/signRescission/page', data);
};
// 查询服务商账号
export const signRescissiongetPmUserList = (data) => {
  return request.post('/signRescission/getPmUserList', data);
};
// 新增  解除限制
export const signRescissionadd = (data) => {
  return request.post('/signRescission/add', data);
};
// 删除  解除限制
export const signRescissiondeleted = (data) => {
  return request.post(`/signRescission/deleted`, data);
};
// 导出  解除限制
export const signRescissionexport = (data) => {
  return request.post('/signRescission/export', data);
};
// 编辑 签收配置
export const restrictionupdate = (data) => {
  return request.post('/sign/restriction/update', data);
};
