<script >
import { computed, ref, watch } from '@vue/composition-api';
import { default as SkeletonTable } from '@/components/skeletonTable';
import { default as WrapperHandleError } from '@/components/wrapperHandleError';
import { default as SectionHeader } from '@/components/sectionHeader';
import { default as FormCreateEdition } from './form/formCreateEdition';
import { default as Actions } from './actions';
import { useModal } from '@/composable/useModal';
import { useGetAllEditions } from '@/api/reverse/edition/query';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const filters = ref({
    codGroup: null
  });
  const queryParams = ref({
    perPage: 10,
    currentPage: 1,
    codGroup: null
  });
  watch(filters.value, newFilters => {
    if (queryParams.value.currentPage > 1) {
      queryParams.value = {
        ...newFilters,
        currentPage: 1,
        perPage: 10
      };
    } else {
      queryParams.value = {
        ...queryParams.value,
        ...newFilters
      };
    }
  });
  const {
    data,
    isError,
    isLoading,
    isFetched,
    error
  } = useGetAllEditions(queryParams);
  const tableData = computed(() => {
    return data.value?.data?.result ?? data.value;
  });
  const tableTotal = computed(() => {
    return data.value?.data?.count ?? 1;
  });
  const isLoadingTable = computed(() => {
    return isLoading.value || !isFetched.value;
  });
  const {
    isOpen: isOpenModalCreate,
    handleVisibility: handleVisibilityModalCreate
  } = useModal();
  return {
    filters,
    queryParams,
    isError,
    error,
    tableData,
    tableTotal,
    isLoadingTable,
    isOpenModalCreate,
    handleVisibilityModalCreate
  };
};
__sfc_main.components = Object.assign({
  WrapperHandleError,
  SectionHeader,
  SkeletonTable,
  Actions,
  FormCreateEdition
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <WrapperHandleError :error="error" :is-error="isError">
    <SectionHeader>
      <template #header>
        <h1 class="header-1">
          {{ $t('editingRegistration.editions') }}
        </h1>
        <o-button variant="primary" size="md" @click="handleVisibilityModalCreate">
          {{ $t('editingRegistration.createEdition') }}
        </o-button>
      </template>

      <template #filter>
        <o-field
          :label="$t('editingRegistration.groupCode')"
          label-for="codGroupSearch"
          class="flex-1"
        >
          <o-input
            id="codGroupSearch"
            v-model="filters.codGroup"
            :placeholder="$t('editingRegistration.groupCodePlaceholder')"
          />
        </o-field>
      </template>
    </SectionHeader>

    <SkeletonTable :is-loading="isLoadingTable">
      <o-table
        :data="tableData"
        :row-key="'id'"
        bordered
        class="space-m-y-32"
        paginated
        backend-pagination
        :total="tableTotal"
        :per-page="queryParams.perPage"
        :current-page.sync="queryParams.currentPage"
        :pagination-simple="false"
      >
        <o-table-column
          v-slot="props"
          field="codGroup"
          :label="$t('editingRegistration.groupCode')"
        >
          {{ props.row.codGroup }}
        </o-table-column>

        <o-table-column
          v-slot="props"
          field="firstCod"
          :label="$t('editingRegistration.firstBatchCode')"
        >
          {{ props.row.firstCod }}
        </o-table-column>

        <o-table-column
          v-slot="props"
          field="firstDescription"
          :label="$t('editingRegistration.firstBatchName')"
        >
          {{ props.row.firstDescription }}
        </o-table-column>

        <o-table-column
          v-slot="props"
          field="firstVoulume"
          :label="$t('editingRegistration.firstBatchVolume')"
        >
          {{ props.row.firstVoulume }}
        </o-table-column>

        <o-table-column
          v-slot="props"
          field="secondCod"
          :label="$t('editingRegistration.secondBatchCode')"
        >
          {{ props.row.secondCod }}
        </o-table-column>

        <o-table-column
          v-slot="props"
          field="secondDescription"
          :label="$t('editingRegistration.secondBatchName')"
        >
          {{ props.row.secondDescription }}
        </o-table-column>

        <o-table-column
          v-slot="props"
          field="secondVoulume"
          :label="$t('editingRegistration.secondBatchVolume')"
        >
          {{ props.row.secondVoulume }}
        </o-table-column>

        <o-table-column
          v-slot="props"
          field="thirdCod"
          :label="$t('editingRegistration.thirdBatchCode')"
        >
          {{ props.row.thirdCod }}
        </o-table-column>

        <o-table-column
          v-slot="props"
          field="thirdDescription"
          :label="$t('editingRegistration.thirdBatchName')"
        >
          {{ props.row.thirdDescription }}
        </o-table-column>

        <o-table-column
          v-slot="props"
          field="thirdVoulume"
          :label="$t('editingRegistration.thirdBatchVolume')"
        >
          {{ props.row.thirdVoulume }}
        </o-table-column>

        <o-table-column
          v-slot="props"
          field="fourthCod"
          :label="$t('editingRegistration.fourthBatchCode')"
        >
          {{ props.row.fourthCod }}
        </o-table-column>

        <o-table-column
          v-slot="props"
          field="fourthDescription"
          :label="$t('editingRegistration.fourthBatchName')"
        >
          {{ props.row.fourthDescription }}
        </o-table-column>

        <o-table-column
          v-slot="props"
          field="fourthVoulume"
          :label="$t('editingRegistration.fourthBatchVolume')"
        >
          {{ props.row.fourthVoulume }}
        </o-table-column>

        <o-table-column label="">
          <template slot-scope="{ row }">
            <Actions :row="row" />
          </template>
        </o-table-column>

        <template #empty> Sem resultados </template>
      </o-table>
    </SkeletonTable>

    <o-modal :active="isOpenModalCreate" width="560" @close="handleVisibilityModalCreate">
      <div>
        <h2 class="header-2">{{ $t('editingRegistration.creatingEditionTitle') }}</h2>
        <p class="paragraph">{{ $t('editingRegistration.creatingEditionParagraph') }}</p>
      </div>
      <FormCreateEdition :close-modal="handleVisibilityModalCreate" />
    </o-modal>
  </WrapperHandleError>
</template>

<style scoped>
.flex-1 {
  flex: 1;
}
</style>
