var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DropdownTable",
        { attrs: { "aria-role": "list" } },
        [
          _c(
            "o-dropdown-item",
            {
              attrs: { "aria-role": "listitem" },
              on: { click: _vm.handleVisibilityModalClose },
            },
            [_vm._v(" Encerrar ")]
          ),
          _c(
            "o-dropdown-item",
            {
              attrs: { "aria-role": "listitem" },
              on: { click: _vm.handleVisibilityModalCancel },
            },
            [_vm._v(" Cancelar ")]
          ),
        ],
        1
      ),
      _c("Alert", {
        attrs: { active: _vm.isOpenModalClose },
        on: { handleClose: _vm.handleVisibilityModalClose },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [_vm._v(" Você tem certeza absoluta? ")]
            },
            proxy: true,
          },
          {
            key: "message",
            fn: function () {
              return [
                _vm._v(
                  " Essa ação não pode ser desfeita. Isso ira encerrar a MDFE " +
                    _vm._s(_vm.row.mdfeKey) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttons",
            fn: function () {
              return [
                _c(
                  "o-button",
                  {
                    attrs: { variant: "outline", size: "md" },
                    on: { click: _vm.handleVisibilityModalClose },
                  },
                  [_vm._v(" cancelar ")]
                ),
                _c(
                  "o-button",
                  {
                    attrs: { variant: "primary", size: "md" },
                    on: { click: _vm.handleClose },
                  },
                  [_vm._v(" sim, quero encerrar ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Alert", {
        attrs: { active: _vm.isOpenModalCancel },
        on: { handleClose: _vm.handleVisibilityModalCancel },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [_vm._v(" Você tem certeza absoluta? ")]
            },
            proxy: true,
          },
          {
            key: "message",
            fn: function () {
              return [
                _vm._v(
                  " Essa ação não pode ser desfeita. Isso ira cancelar a MDFE " +
                    _vm._s(_vm.row.mdfeKey) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttons",
            fn: function () {
              return [
                _c(
                  "o-button",
                  {
                    attrs: { variant: "outline", size: "md" },
                    on: { click: _vm.handleVisibilityModalCancel },
                  },
                  [_vm._v(" cancelar ")]
                ),
                _c(
                  "o-button",
                  {
                    attrs: { variant: "primary", size: "md" },
                    on: { click: _vm.handleCancel },
                  },
                  [_vm._v(" sim, quero encerrar ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }