import request from '@/utils/request';

// 大区管理分页查询
export const regionalPage = data => {
  return request.post('/regional/page', data);
};
// 新增大区
export const regionalAdd = data => {
  return request.post('/regional', data);
};
// 大区修改
export const regionalChange = (data, id) => {
  return request.put('/regional/' + id, data);
};
// 大区删除
export const regionalDelete = (id) => {
  return request.delete('/regional/' + id);
};
// 获取大区列表
export const regionalList = (data) => {
  return request.post('/regional/list', data);
};
