import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';

import { getSkipPage } from '@/utils/getSkipPage';
import getMessageError from '@/utils/getMessageError';
import { toast } from '@/utils/programaticToast';
import { getToastContentComponent } from '@/utils/getToastContentComponent';
import { editionService } from './edition';

export function useGetAllEditions(queryParams) {
  return useQuery({
    queryKey: ['editions', queryParams],
    queryFn: async({ signal }) => {
      try {
        const { currentPage, perPage, codGroup } = queryParams.value;
        const skip = getSkipPage(currentPage);
        const params = {
          skip,
          take: perPage,
          codGroup
        };
        const res = await editionService.getAll(params, signal);

        return res;
      } catch (error) {
        if (error.response && error.response.status === 400) {
          return [];
        }

        throw error;
      }
    },
    networkMode: 'always',
    refetchOnWindowFocus: false,
    retry: false,
    keepPreviousData: true
  });
}

export function useCreateEdition() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (newEdition) => editionService.create(newEdition),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['editions'] });
      toast.open({
        variant: 'success',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Criado com sucesso', describe: 'Edição criada com sucesso em nosso sistema', typeIcon: 'success' }),
        closable: true,
        duration: 4000
      });
    },
    onError: (error) => {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Ocorreu um erro', describe: getMessageError(error), typeIcon: 'error' }),
        closable: true,
        duration: 4000
      });
    }
  });
}

export function useUpdateEdition() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, newEdition }) => editionService.update(id, newEdition),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['editions'] });
      toast.open({
        variant: 'success',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Editado com sucesso', describe: 'Edição alterada com sucesso em nosso sistema', typeIcon: 'success' }),
        closable: true,
        duration: 4000
      });
    },
    onError: (error) => {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Ocorreu um erro', describe: getMessageError(error), typeIcon: 'error' }),
        closable: true,
        duration: 4000
      });
    }
  });
}
