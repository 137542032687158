<template>
  <div class="app-wrapper">
    <transition :name="isTransition ? 'fade-transform' : ''" mode="out-in" @before-leave="1">
      <keep-alive :include="cachedViews">
        <router-view />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'LayoutThree',
  data() {
    return { isTransition: true };
  },
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key() {
      return this.$route.path;
    }
  },
  activated() {
    this.$forceUpdate();
    this.$nextTick(() => (this.isTransition = true));
  },
  deactivated() {
    this.isTransition = false;
  }
};
</script>

<style lang="scss" scoped>
</style>
