<script >
import { ref, defineProps } from '@vue/composition-api';
import { default as FormAddress } from '@/components/steps/formAddress';
import { default as FormDataSeller } from './step/formDataSeller';
import { useWizardForm } from '@/composable/useWizardForm';
import { useEditSeller } from '@/api/transport/seller/query';
const __sfc_main = {};
__sfc_main.props = {
  seller: {
    type: Object
  },
  closeModal: {
    type: Function
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const STEP_TOTAL = 2;
  const props = __props;
  const formData = ref({
    formStep01: {
      sellerCode: props.seller.seller_code,
      sellerName: props.seller.seller_name,
      customerCode: props.seller.customer_code,
      customerName: props.seller.customer_name,
      phone: props.seller.seller_phone_number,
      email: props.seller.seller_mail,
      document: props.seller.seller_documentation,
      nameContact: props.seller.seller_contact_name
    },
    formStep02: {
      zipcode: props.seller.seller_zipcode,
      street: props.seller.seller_address,
      number: props.seller.seller_address_number,
      neighborhood: props.seller.seller_address_neighborhood,
      municipality: props.seller.seller_address_municipality,
      state: props.seller.seller_state,
      complement: props.seller.seller_address_complement
    }
  });
  const {
    mutate,
    isLoading
  } = useEditSeller();
  function handleSubmit(formData) {
    const newSeller = {
      ...formData.value.formStep01,
      ...formData.value.formStep02
    };
    mutate({
      newSeller,
      id: props.seller.id
    }, {
      onSuccess: () => {
        props.closeModal();
      }
    });
  }
  const {
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  } = useWizardForm(formData, handleSubmit, STEP_TOTAL);
  return {
    formData,
    isLoading,
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  };
};
__sfc_main.components = Object.assign({
  FormDataSeller,
  FormAddress
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <FormDataSeller
      v-show="step === 0"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      :form-data="formData.formStep01"
    />

    <FormAddress
      v-show="step === 1"
      :form-data="formData.formStep02"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      :is-edit="true"
    />

    <o-loading :full-page="true" :active.sync="isLoading" />
  </div>
</template>
