<template>
  <div class="sidebar-logo-container" :class="{'collapse':collapse}">
    <transition name="sidebarLogoFade">
      <router-link
        v-if="collapse" key="collapse" class="sidebar-logo-link"
        to="/"
      >
        <img
          v-if="logo1" :src="logo1" class="sidebar-logo1"
          alt=""
        >
        <h1 v-else class="sidebar-title">{{ title }}</h1>
      </router-link>
      <router-link
        v-else key="expand" class="sidebar-logo-link"
        to="/"
      >
        <img
          v-if="logo1" :src="logo1" class="sidebar-logo"
          alt=""
        >
        <h1 class="sidebar-title">{{ title }}</h1>
      </router-link>
    </transition>
  </div>
</template>

<script>
const logoSrc = require('@/assets/anjun.png');
const logoSrc1 = require('@/assets/ajlogo1.svg');
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      // title: '安骏跨境物流系统', // 综合管理平台
      title: '', // 综合管理平台
      // logo: 'https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46-8bd4-e9e686ef5251.png'
      logo: logoSrc,
      logo1: logoSrc1
    };
  }
};
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #2b2f3a;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      // width: 58px;
      display: inline-block;
      max-width: 100%;
      height: 36px;
      vertical-align: middle;
      margin-right: 5px;
    }

    & .sidebar-logo1 {
      width: 50px;
      height: 26px;
      vertical-align: middle;
      //margin-right: 12px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #009944;
      font-weight: 600;
      line-height: 50px;
      font-size: 17px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0;
    }
  }
}
</style>
