var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dispatchBox" },
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "scanClass" },
          [
            _c("span", [
              _vm._v(_vm._s(_vm.$t("operatingPlatform.dispatchScan"))),
            ]),
            _c("el-input", {
              staticClass: "iconBtn",
              attrs: {
                placeholder: _vm.$t("operatingPlatform.PleaseScan"),
                clearable: "",
                "prefix-icon": "el-icon-full-screen",
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.addTable($event)
                },
              },
              model: {
                value: _vm.dispatchScan,
                callback: function ($$v) {
                  _vm.dispatchScan = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "dispatchScan",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "el-button",
        { staticClass: "uploadBtn onlyClickOnce", on: { click: _vm.upload } },
        [_vm._v(_vm._s(_vm.$t("operatingPlatform.Upload")) + " ")]
      ),
      _c(
        "div",
        { staticClass: "tableClass" },
        [
          _c(
            "el-table",
            {
              attrs: { data: _vm.scanTableData, "max-height": 600, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("operatingPlatform.waybillNumber"),
                  align: "center",
                  prop: "scanWaybillNumber",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("operatingPlatform.scanTime"),
                  align: "center",
                  prop: "scanTime",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("operatingPlatform.operation"),
                  align: "center",
                  fixed: "right",
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popconfirm",
                          {
                            attrs: {
                              title: _vm.$t("operatingPlatform.deleteSure"),
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.scanDelClick(scope.row, scope.$index)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  slot: "reference",
                                  size: "mini",
                                  type: "danger",
                                },
                                slot: "reference",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("operatingPlatform.Delete"))
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
        },
        [
          _c("audio", {
            ref: "errRef",
            attrs: { src: _vm.errorMp3, controls: "controls" },
          }),
          _c("audio", {
            ref: "okRef",
            attrs: { src: _vm.okMp3, controls: "controls" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }