// @ts-check

import ExcelJs from 'exceljs';
// @ts-ignore
import { saveAs } from 'file-saver';
import i18n from '@/lang';
import { cloneDeep } from 'lodash-es';

/** @typedef {import('./types').TTableRow} TTableRow */
/** @typedef {import('./types').TTableObj} TTableObj */

/**
 * @type {TTableRow}
 * @desc 模板
 */
export const TMP_OBJ = {
  id: undefined,
  partitionAffiliateId: undefined,
  distanceModel: '', // 距离模型 1 <50 ,2 50>=
  standardPrice: 0, // 基础价格
  overshipmentPrice: 0, // 揽收商家提成
  price: 0, // 包裹价格
  standardCargo: undefined // 最低货量
};

// 距离
export const DISTANCE_MODEL = Object.freeze({
  1: {
    label: '<50KM',
    value: 1
  },
  2: {
    label: '>=50KM',
    value: 2
  }
});

// 距离枚举数量
export const DISTANCE_MODEL_COUNT = Object.keys(DISTANCE_MODEL).length;

/**
 * 距离 选项校验
 * @param {TTableRow[]} arr
 * @returns {boolean} 校验通过返回 true
 */
export const validateDistance = (arr) => {
  // 只添加了一项，则不需要校验
  if (arr.length === 1) {
    return true;
  }

  // 如果存在多个选项，则需要校验是否重复
  {
    const list = arr.map((item) => {
      return item.distanceModel;
    });
    // 去重
    const set = new Set(list);
    if (set.size !== list.length) {
      return false;
    }
  }
  return true;
};

/**
 * 校验价格详情
 * @param {TTableRow[]} arr
 * @returns {boolean}
 */
export const validatePrice = (arr) => {
  const isBool = arr.every((item) => {
    const { distanceModel, standardPrice, overshipmentPrice, price } = item;

    // 校验 距离
    if (!distanceModel) {
      return false;
    }

    // 基础价格
    if (standardPrice === undefined || standardPrice < 0) {
      return false;
    }

    // 揽收商家提成

    if (overshipmentPrice === undefined || overshipmentPrice < 0) {
      return false;
    }

    // 包裹价格

    if (price === undefined || price < 0) {
      return false;
    }

    return true;
  });

  if (!isBool) {
    return false;
  }

  // 距离重叠不能重叠
  {
    const isBool = validateDistance(arr);
    if (!isBool) {
      return false;
    }
  }
  return true;
};

// 设置数据
export const { notSetPartitionFun, setPartitionFun } = (() => {
  /**
   * 没有选择分区
   * @param {any[]} arr 导入的数据
   * @param {TTableRow[]} defaultArr 列表中已经存在的数据
   * @returns {TTableRow[]}
   */
  const notSetPartitionFun = (arr, defaultArr) => {
    // 导入的数据处理
    const list = arr.map(item => {
      const { id, partitionAffiliateId, distanceModel, standardPrice, overshipmentPrice, price, standardCargo } = item;
      /** @type {TTableRow} */
      const obj = {
        ...TMP_OBJ,
        id,
        partitionAffiliateId, distanceModel, standardPrice, overshipmentPrice, price, standardCargo
      };

      if (standardCargo === null) {
        obj.standardCargo = undefined;
      }

      return obj;
    });

    /** @type {TTableRow[]} */
    const defaultList = cloneDeep(defaultArr);

    // 当前为空
    if (defaultList.length === 0) {
      // this.defaultPartitionObj.default.arr = list;
      return list;
    }
    /** @type {TTableRow[]} */
    const listNotId = []; // 导入的列表不存在id

    list.forEach(item => {
      // 没有id
      if (!item.id) {
        listNotId.push(item);
        return;
      }

      // 查看导入的id是否在当前id中
      const index = defaultList.findIndex(key => key.id === item.id);

      // 没有相同的id
      if (index === -1) {
        item.id = undefined;
        listNotId.push(item);
        return;
      }

      // 存在相同的id
      defaultList[index] = item;
    });

    return [...defaultList, ...listNotId];
  };

  /**
   * 选择了分区
   * @param {any[]} arr 导入的数据
   * @param {TTableObj} partitionRow 列表中已经存在的数据
   * @returns {TTableObj}
   */
  const setPartitionFun = (arr, partitionRow) => {
    //

    /** @type {TTableObj} */
    const partitionObj = cloneDeep(partitionRow);

    // 没有默认数据
    {
      let size = 0;
      Object.values(partitionObj).forEach(item => {
        size += Object.keys(item.arr).length;
      });
      if (size === 0) {
        arr.forEach(item => {
          const { id, partitionAffiliateId, distanceModel, standardPrice, overshipmentPrice, price, standardCargo } = item;

          const obj = {
            ...TMP_OBJ,
            id,
            partitionAffiliateId, distanceModel, standardPrice, overshipmentPrice, price, standardCargo
          };

          if (standardCargo === null) {
            obj.standardCargo = undefined;
          }
          partitionObj[partitionAffiliateId].arr.push(obj);
        });

        return partitionObj;
      }
    }

    /** @type Map<number, TTableRow[]> */
    const map = new Map();

    // 数据整理
    arr.forEach(item => {
      const { id, partitionAffiliateId, distanceModel, standardPrice, overshipmentPrice, price, standardCargo } = item;

      const obj = {
        ...TMP_OBJ,
        id,
        partitionAffiliateId, distanceModel, standardPrice, overshipmentPrice, price, standardCargo
      };

      if (standardCargo === null) {
        obj.standardCargo = undefined;
      }

      // 将数据按照分区id划分
      if (map.has(partitionAffiliateId)) {
        map.get(partitionAffiliateId).push(obj);
      } else {
        map.set(partitionAffiliateId, [obj]);
      }
    });

    Object.entries(partitionObj).forEach(item => {
      const [key, value] = item;
      const numKey = Number(key);

      if (map.has(numKey)) {
        const vArr = map.get(numKey);
        partitionObj[key].arr = notSetPartitionFun(vArr, value.arr);
      }
    });

    return partitionObj;
  };

  /**
   * 设置数据
   * @param {any[]} arr 导入的数据
   * @param {number} partitionId 选中的分区
   * @param {TTableRow[]} defaultArr 选中的分区
   * @param {TTableObj} partitionObj 选中的分区
   * @returns {TTableRow[]}
   */
  // const setDataFun = (arr, partitionId, defaultArr, partitionObj) => {
  //   if (arr.length === 0) { return; }

  //   // 没有选择分区
  //   if (!partitionId) {
  //     const list = notPartition(arr, defaultArr);
  //     return list;
  //   }

  //   partition(arr, partitionObj);

  //   // 选择了分区
  //   // // 将数组按照分区id进行分组
  //   // arr.forEach(item => {
  //   //   const { id, partitionAffiliateId, distanceModel, standardPrice, overshipmentPrice, price, standardCargo } = item;

  //   //   const obj = {
  //   //     ...TMP_OBJ,
  //   //     id,
  //   //     partitionAffiliateId, distanceModel, standardPrice, overshipmentPrice, price, standardCargo
  //   //   };

  //   //   if (standardCargo === null) {
  //   //     obj.standardCargo = undefined;
  //   //   }
  //   //   this.partitionObj[partitionAffiliateId].arr.push(obj);
  //   // });
  // };

  return {
    notSetPartitionFun, setPartitionFun
  };
})();

export const { downloadTemplate, importTemplate, downloadQuotation, SEPARATOR } = (() => {
  const SEPARATOR = '->'; // 分隔符

  // 模板标题
  const headArr = [
    {
      header: i18n.t('basicData.PartitionName'), // 分区
      key: 'partitionAffiliateId',
      width: 30
    },
    {
      header: i18n.t('collectionCenter.distance') + '*', // 距离
      key: 'distanceModel',
      width: 30
    },
    {
      header: i18n.t('basicData.BasePrice') + '(PT003)*', // 基础价PT003
      key: 'standardPrice',
      width: 30
    },
    {
      header: i18n.t('basicData.揽收商家提成') + '(PT004)*', // 揽收商家提成PT004
      key: 'overshipmentPrice',
      width: 30
    },
    {
      header: i18n.t('basicData.揽收包裹价格') + '(PT013)*', // 揽收包裹价格PT013
      key: 'price',
      width: 30
    },
    {
      header: i18n.t('basicData.最低货量'), // 最低存货
      key: 'standardCargo',
      width: 30
    },
    {
      header: 'ID', // id 下载报价的时候存在
      key: 'id',
      width: 20
    }
  ];
  // 分区
  const PartitionArr = [
    // {
    //   header: i18n.t('basicData.PartitionName'), // 分区
    //   key: 'partitionIdentifier',
    //   width: 20
    // },
    {
      header: i18n.t('basicData.PartitionName') + '(ID)', // 分区
      key: 'partitionAffiliateId',
      width: 20
    }

  ];

  /**
   * 获取头部，选择与未选择分区，对于索引为0的标题有区别
   *   如果选择了分区则 索引为0 需要加上 *
   *   @param {any[]} subregionList 分区名称
   *   @returns {any[]}
   */
  const getHeadObj = (subregionList) => {
    const arr = cloneDeep(headArr);
    if (subregionList.length > 0) {
      arr[0].header = arr[0].header + '*';
      // Sheet1.columns = arr;
      return arr;
    }
    return arr;
  };

  /**
   * 生成空白模板
   * @param {any[]} subregionList 所有分区
   * @param {any[]} sheet1RowArr 下载报价的时候才需要传
   */
  const createTemplate = (subregionList, sheet1RowArr = []) => {
    const wb = new ExcelJs.Workbook();
    const Sheet1 = wb.addWorksheet('Sheet1');
    const Sheet2 = wb.addWorksheet('Sheet2');
    const Sheet3 = wb.addWorksheet('Sheet3');

    // 分区处理
    Sheet1.columns = getHeadObj(subregionList);

    // 下载报价的时候
    if (sheet1RowArr.length) {
      Sheet1.addRows(sheet1RowArr);
    }
    // Sheet1.addRows([
    //   {
    //     partitionAffiliateId: '测试赛->402',
    //     distanceModel: '<50KM->1',
    //     standardPrice: '4',
    //     overshipmentPrice: 3,
    //     price: 2,
    //     standardCargo: 1
    //   }
    // ]);

    // 分区列表
    Sheet2.columns = cloneDeep(PartitionArr);
    const Sheet2_data = [];
    if (subregionList && subregionList.length > 0) {
      subregionList.forEach((item) => {
        const { id, partitionIdentifier } = item;
        Sheet2_data.push({
          partitionAffiliateId: `${partitionIdentifier}${SEPARATOR}${id}`
        });
      });
    }
    Sheet2.addRows(Sheet2_data);

    // 距离
    Sheet3.columns = [
      {
        // @ts-ignore
        header: i18n.t('collectionCenter.distance'), // 距离
        key: 'distanceModel',
        width: 20
      }
    ];
    const Sheet3_data = [];
    Object.values(DISTANCE_MODEL).forEach((item) => {
      Sheet3_data.push({
        distanceModel: `${item.label}${SEPARATOR}${item.value}`
      });
    });
    Sheet3.addRows(Sheet3_data);

    new Array(50000).fill(0).forEach((_, idx) => {
      const row = idx + 2;

      // 分区名称，如果存在则设置
      if (subregionList && subregionList.length > 0) {
        Sheet1.getCell(row, 1).dataValidation = {
          type: 'list',
          formulae: [`=Sheet2!$A$2:$A${Sheet2_data.length + 1}`]
        };
      }

      // 距离
      Sheet1.getCell(row, 2).dataValidation = {
        type: 'list',
        formulae: [`=Sheet3!$A$2:$A${Sheet3_data.length + 1}`]
      };
    });

    return wb;
  };

  /**
   * 下载模板
   * @param {any[]} subregionList 所有分区
   */
  const downloadTemplate = async(subregionList) => {
    const wb = createTemplate(subregionList);

    const buffer = await wb.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/octet-stream'
    });

    const saveName = '揽收+上门揽收+第三方' + '.xlsx';

    saveAs(blob, saveName);
  };

  /**
   * 导入模板处理
   * @param {any[]} arr 导入的数据
   * @param {any[]} subregionList 分区
   * @returns {TTableRow[]}
   */
  const importTemplate = (arr, subregionList) => {
    const subregionID = subregionList.map(item => item.id);

    const headArr = getHeadObj(subregionList);

    /** @type TTableRow[] */
    const list = [];
    arr.forEach(item => {
      /** @type {TTableRow} */
      const obj = {
        ...TMP_OBJ
      };

      Object.entries(item).map(row => {
        const [key, value] = row;

        // 分区
        if (key === headArr[0].header) {
          const arr = (value || '').split(SEPARATOR);
          if (arr.length === 2) {
            const k = arr[1] > 0 ? arr[1] : undefined;

            // 选中的值符合要求
            if (k && subregionID.includes(Number(k))) {
              obj[headArr[0].key] = Number(k);
            }
          }
        }

        // 距离
        if (key === headArr[1].header) {
          const arr = (value || '').split(SEPARATOR);
          if (arr.length === 2) {
            const k = arr[1] > 0 ? arr[1] : '';
            // 填入的值符合下拉选项
            if (DISTANCE_MODEL[k]) {
              obj[headArr[1].key] = DISTANCE_MODEL[k].value;
            }
          }
        }

        // 基础价PT003
        if (key === headArr[2].header) {
          obj[headArr[2].key] = value > 0 ? value : 0;
        }

        // 揽收商家提成PT004
        if (key === headArr[3].header) {
          obj[headArr[3].key] = value > 0 ? value : 0;
        }

        // 揽收包裹价格PT013
        if (key === headArr[4].header) {
          obj[headArr[4].key] = value > 0 ? value : 0;
        }

        // 最低存货
        if (key === headArr[5].header) {
          obj[headArr[5].key] = value > 0 ? value : undefined;
        }

        // id
        if (key === headArr[6].header) {
          obj[headArr[6].key] = value > 0 ? value : undefined;
        }

        return obj;
      });

      list.push(obj);
    });

    return list;
  };

  // 下载报价
  const downloadQuotation = async(list, subregionList) => {
    const wb = createTemplate(subregionList, list);

    const buffer = await wb.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/octet-stream'
    });

    const saveName = '报价' + '.xlsx';

    saveAs(blob, saveName);
  };

  return {
    downloadTemplate,
    importTemplate,
    downloadQuotation,
    SEPARATOR
  };
})();
