var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "wrapper-form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        },
      },
    },
    [
      _c(
        "o-field",
        {
          directives: [
            {
              name: "mask",
              rawName: "v-mask",
              value: ["+## (##) ####-####", "+## (##) #####-####"],
              expression: "['+## (##) ####-####', '+## (##) #####-####']",
            },
          ],
          attrs: {
            label: _vm.$t("base.businessPhone"),
            "label-for": "comercialPhone",
            message: _vm.v$.comercialPhone.$errors.length
              ? _vm.v$.comercialPhone.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "comercialPhone",
              placeholder: _vm.$t("base.businessPhonePlaceholder"),
            },
            model: {
              value: _vm.formData.comercialPhone,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "comercialPhone", $$v)
              },
              expression: "formData.comercialPhone",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("base.contactName"),
            "label-for": "contactName",
            message: _vm.v$.contactName.$errors.length
              ? _vm.v$.contactName.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "contactName",
              placeholder: _vm.$t("base.contactNamePlaceholder"),
            },
            model: {
              value: _vm.formData.contactName,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "contactName", $$v)
              },
              expression: "formData.contactName",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          directives: [
            {
              name: "mask",
              rawName: "v-mask",
              value: ["+## (##) ####-####", "+## (##) #####-####"],
              expression: "['+## (##) ####-####', '+## (##) #####-####']",
            },
          ],
          attrs: {
            label: _vm.$t("base.mobilePhone"),
            "label-for": "phone",
            message: _vm.v$.phone.$errors.length
              ? _vm.v$.phone.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "phone",
              placeholder: _vm.$t("base.mobilePhonePlaceholder"),
            },
            model: {
              value: _vm.formData.phone,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "phone", $$v)
              },
              expression: "formData.phone",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("base.email"),
            "label-for": "email",
            message: _vm.v$.email.$errors.length
              ? _vm.v$.email.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "email",
              placeholder: _vm.$t("base.emailPlaceholder"),
            },
            model: {
              value: _vm.formData.email,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "email", $$v)
              },
              expression: "formData.email",
            },
          }),
        ],
        1
      ),
      _c(
        "FormActions",
        [
          _c("FormActionsPrevious", {
            attrs: { "is-first-step": _vm.isFirstStep },
            on: { handlePrevious: _vm.handlePrevious },
          }),
          _c("FormActionsNext", { attrs: { "is-last-step": _vm.isLastStep } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }