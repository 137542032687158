var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { width: "100%" } }, [
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "space-between",
          "margin-bottom": "20px",
        },
      },
      [
        _c("div", { staticClass: "clearfix" }, [
          _vm._v(" " + _vm._s(_vm.$t("newOrder.CustomerOrders")) + " "),
        ]),
        !_vm.clickDropdown
          ? _c(
              "el-button",
              {
                attrs: { size: "mini" },
                on: {
                  click: function ($event) {
                    _vm.clickDropdown = true
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("newOrder.ConfiguringCustomers")))]
            )
          : _c("div", [_vm._t("default")], 2),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "counter-container",
        staticStyle: {
          display: "flex",
          "justify-content": "center",
          "margin-top": "37px",
          "margin-bottom": "50px",
          "flex-wrap": "wrap",
        },
      },
      _vm._l(_vm.totalList, function (item, index) {
        return _c("CounterItem", {
          key: index,
          attrs: {
            label: item.name,
            value: item.count,
            change: item.difference,
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }