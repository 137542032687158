var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Box" }, [
    _c(
      "div",
      { staticClass: "scanClass" },
      [
        _c("span", [_vm._v(_vm._s(_vm.$t("collectionCenter.scanBtn")))]),
        _c("el-input", {
          staticClass: "iconBtn",
          attrs: {
            placeholder: _vm.$t("collectionCenter.pleaseEnterOrScan"),
            "prefix-icon": "el-icon-full-screen",
            clearable: "",
          },
          nativeOn: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.pushTable($event)
            },
          },
          model: {
            value: _vm.number,
            callback: function ($$v) {
              _vm.number = typeof $$v === "string" ? $$v.trim() : $$v
            },
            expression: "number",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { class: _vm.upLoadFile.length > 0 ? "uploadBox addH" : "uploadBox" },
      [
        _c(
          "div",
          { staticClass: "uploadClass" },
          [
            _c(
              "el-upload",
              {
                ref: "demoUpload",
                attrs: {
                  action: "",
                  limit: 1,
                  "file-list": _vm.upLoadFile,
                  "list-type": "picture",
                  "before-upload": _vm.beforeUpload,
                  "http-request": _vm.uploadDataClick,
                  "on-remove": _vm.handleRemove,
                  "on-exceed": _vm.handleExceed,
                },
              },
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value: "btn:collection-center:packet-scan:upLoadImg",
                        expression:
                          "'btn:collection-center:packet-scan:upLoadImg'",
                        arg: "remove",
                      },
                    ],
                    attrs: { size: "mini" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("WorkCentreTable.UploadPictures")) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-button",
          {
            directives: [
              {
                name: "permit",
                rawName: "v-permit:remove",
                value: "btn:collection-center:packet-scan:upLoad",
                expression: "'btn:collection-center:packet-scan:upLoad'",
                arg: "remove",
              },
            ],
            attrs: { type: "success" },
            on: { click: _vm.upload },
          },
          [_vm._v(_vm._s(_vm.$t("collectionCenter.Upload")))]
        ),
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "el-table",
          { attrs: { "max-height": 600, data: _vm.scanTablePage, border: "" } },
          [
            _c("el-table-column", {
              attrs: {
                prop: "scanNumber",
                align: "center",
                label: _vm.$t("collectionCenter.Number"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "scanTimeStr",
                align: "center",
                label: _vm.$t("collectionCenter.scanTime"),
              },
            }),
            _vm.isdelBtn
              ? _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("collectionCenter.operation"),
                    fixed: "right",
                    width: "100",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-popconfirm",
                              {
                                attrs: {
                                  title: _vm.$t("collectionCenter.deleteSure"),
                                },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.scanDelClick(
                                      scope.row,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      type: "danger",
                                      size: "mini",
                                    },
                                    slot: "reference",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("collectionCenter.Delete"))
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    391200918
                  ),
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _vm.dialogVisible
      ? _c(
          "div",
          { staticClass: "resultClass" },
          [
            _c(
              "el-dialog",
              {
                attrs: {
                  "close-on-click-modal": false,
                  visible: _vm.dialogVisible,
                  width: "40%",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogVisible = $event
                  },
                  close: _vm.closeDialog,
                },
              },
              [
                _c("div", [
                  _c("div", { staticClass: "vv" }, [
                    _vm._v(
                      _vm._s(_vm.$t("collectionCenter.auditBatchNumber")) +
                        "：" +
                        _vm._s(_vm.upLoadList.batchNumber)
                    ),
                  ]),
                  _c("div", { staticClass: "vv" }, [
                    _vm._v(
                      _vm._s(_vm.$t("collectionCenter.auditResult")) + "： "
                    ),
                    _c(
                      "span",
                      {
                        class:
                          _vm.upLoadList.auditStatus == 0
                            ? "blackText"
                            : _vm.upLoadList.auditStatus == 1
                            ? "redText"
                            : "",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.upLoadList.auditStatus == 0
                                ? _vm.$t("userManage.normal")
                                : _vm.upLoadList.auditStatus == 1
                                ? _vm.$t("newOperationCenter.abnormal")
                                : ""
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "vv" }, [
                    _vm._v(
                      _vm._s(_vm.$t("collectionCenter.checkParcel")) +
                        "：" +
                        _vm._s(_vm.upLoadList.packageNumer)
                    ),
                  ]),
                  _vm.upLoadList.auditStatus == 1
                    ? _c(
                        "div",
                        { staticClass: "vv processingResultClass" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("collectionCenter.processingResult")
                            ) + "： "
                          ),
                          _c("el-input", {
                            class: _vm.isRed ? "redBor" : "",
                            attrs: {
                              maxlength: "300",
                              "show-word-limit": "",
                              type: "textarea",
                              placeholder: _vm.$t(
                                "collectionCenter.pleaseInput"
                              ),
                            },
                            model: {
                              value: _vm.processingResult,
                              callback: function ($$v) {
                                _vm.processingResult = $$v
                              },
                              expression: "processingResult",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  { staticStyle: { "text-align": "center", margin: "20px" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "success", plain: "" },
                        on: { click: _vm.SubmitResult },
                      },
                      [_vm._v(_vm._s(_vm.$t("collectionCenter.confirm")))]
                    ),
                  ],
                  1
                ),
                _vm.upLoadList.exceptOrderList &&
                _vm.upLoadList.exceptOrderList.length >= 1
                  ? _c("div", [
                      _vm._v(
                        _vm._s(_vm.$t("collectionCenter.abnormalData")) + "："
                      ),
                    ])
                  : _vm._e(),
                _vm.upLoadList.exceptOrderList &&
                _vm.upLoadList.exceptOrderList.length >= 1
                  ? _c(
                      "div",
                      { staticClass: "errorClass" },
                      _vm._l(
                        _vm.upLoadList.exceptOrderList,
                        function (item, index) {
                          return _c("div", { key: index }, [
                            _vm._v(" " + _vm._s(item.scanNumber)),
                            _c("span", { staticClass: "space" }),
                            _vm._v(_vm._s(item.collectStatus)),
                            _c("span", { staticClass: "space" }),
                            _vm._v(_vm._s(item.bagNumber) + " "),
                          ])
                        }
                      ),
                      0
                    )
                  : _vm._e(),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
      },
      [
        _c("audio", {
          ref: "errRef",
          attrs: { src: _vm.errorMp3, controls: "controls" },
        }),
        _c("audio", {
          ref: "okRef",
          attrs: { src: _vm.okMp3, controls: "controls" },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }