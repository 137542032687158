<template>
  <div class="app-container" style="background:#fff;min-height: 100%;">
    <el-form label-position="top">
      <el-row :gutter="20">
        <el-col :span="5" style="height: 70px;">
          <el-form-item :label="$t('GlobalSetObj.status')">
            <el-select
              v-model="queryForm.params.shippingStatus"
              :placeholder="$t('GlobalSetObj.all')"
              clearable
              style="width: 100%;"
              @change="handleChange"
            >
              <el-option key="1" :label="$t('GlobalSetObj.all')" value="" />
              <!--              <el-option key="2" :label="$t('GlobalSetObj.NoCarregado')" value="0" />-->
              <el-option key="3" :label="$t('GlobalSetObj.Carregado')" value="1" />
              <el-option key="4" :label="$t('GlobalSetObj.Descarregado')" value="2" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="16" style="height: 70px;">
          <!--创建时间-->
          <el-form-item prop="searchTimeType">
            <template slot="label">
              <div class="selectLabel">
                <el-select
                  v-model="queryForm.params.searchTimeType"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  :style="IsWidthTime"
                  @change="SwitchTimeType"
                >
                  <el-option key="1" :label="$t('GlobalSetObj.LoadCarTime')" value="1" />
                  <el-option key="2" :label="$t('GlobalSetObj.unCarTime')" value="2" />
                </el-select>
              </div>
            </template>
            <el-date-picker
              v-model="queryForm.params.createStartTime"
              :clearable="false"
              :format="$i18n.locale=='zh'?zhDate:ptDate"
              :placeholder="$t('newOrder.SelectDatetime')"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
            ~
            <el-date-picker
              v-model="queryForm.params.createEndTime"
              :clearable="false"
              :format="$i18n.locale=='zh'?zhDate:ptDate"
              :placeholder="$t('newOrder.SelectDatetime')"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="9" style="height: 70px;margin: 10px 0">
          <el-form-item prop="searchType">
            <template slot="label">
              <div class="selectLabel">
                <el-select
                  v-model="queryForm.params.searchType"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  :style="IsWidth"
                  @change="SwitchType"
                >
                  <el-option key="1" :label="$t('orderCenterCont.TransitBinBagNumber')" value="1" />
                  <el-option key="2" :label="$t('GlobalSetObj.DeliveryRoutes')" value="2" />
                  <el-option key="3" :label="$t('GlobalSetObj.Carregador')" value="3" />
                  <el-option key="4" :label="$t('GlobalSetObj.Descarregador')" value="4" />
                  <el-option key="5" :label="$t('GlobalSetObj.licensePlateNumber')" value="5" />
                </el-select>
              </div>
            </template>
            <el-form-item class="multipleQuery">
              <div class="searchMultiple">
                <el-input
                  v-model.trim="queryForm.params.inputValue"
                  :placeholder="$t('orderDetails.pleaseInput')"
                  clearable
                  @keyup.enter.native="getSearchEnter"
                />
                <div style="width: 40px;">
                  <el-button class="searchBtn" @click="getSearchEnter">
                    <i class="iconfont icon-chaxun" style="font-size: 24px" />
                  </el-button>
                </div>
                <el-popover
                  v-model="showList"
                  placement="bottom-end"
                  trigger="manual"
                  width="400"
                >
                  <div>
                    <el-input
                      v-model="searchStr"
                      :placeholder="$t('newOrder.PreciseSearch')"
                      :rows="8"
                      type="textarea"
                    />
                    <div style="margin-top: 10px;">
                      <!--清空-->
                      <el-button size="small" @click="clearSearch">{{ $t('newOrder.empty') }}</el-button>
                      <div style="float: right">
                        <!--关闭-->
                        <el-button size="small" @click="openSearchList">{{ $t('orderExport.close') }}</el-button>
                        <!--搜索-->
                        <el-button
                          plain size="small" type="primary"
                          @click="getSearch(1)"
                        >
                          {{ $t('newOrder.search') }}
                        </el-button>
                      </div>
                    </div>
                  </div>
                  <el-button slot="reference" class="btnIcon" @click="openText">
                    <i class="iconfont icon-liebiao1" style="font-size: 24px" />
                  </el-button>
                </el-popover>
              </div>
            </el-form-item>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row v-show="searchList.length > 0">
      <div class="searchTip">
        <el-tooltip class="item" effect="dark" placement="bottom">
          <template slot="content">
            <pre>{{ searchStr }}</pre>
          </template>
          <div class="searchNumWarp">
            <div class="wordBreak">
              <p>{{
                switchSearchType
              }}：
                {{ searchList.join('，') }}</p>
            </div>
          </div>
        </el-tooltip>
        <div class="clearSearch" @click="clearSearch">
          <i class="el-icon-close" />
        </div>
      </div>
    </el-row>
    <div style="margin-bottom: 10px">
      <!--搜索-->
      <el-button size="small" type="primary" @click="getSearchEnter">{{ $t('newOrder.search') }}</el-button>
      <!--重置-->
      <el-button size="small" type="info" @click="resetSearch">{{ $t('newOrder.reset') }}</el-button>
      <!--卸货点清单-->
      <el-button size="small" @click="listUnloadingPoints($t('GlobalSetObj.ListUnloadingPoints'))">
        {{ $t('GlobalSetObj.ListUnloadingPoints') }}
      </el-button>
      <!--MDFE-->
      <el-button plain size="small" @click="MDFEBtn">{{ 'MDFE' }}</el-button>
      <!--Transfer Manifestos-->
      <!--        <el-button plain size="small" @click="listUnloadingPoints('Transfer Manifestos')">{{-->
      <!--            "Transfer Manifestos"-->
      <!--          }}-->
      <!--        </el-button>-->
      <!--Shipping Minfisto-->
      <!--        <el-button plain size="small" @click="ShippingMinfistoBtn">{{ "Shipping Manifestos" }}</el-button>-->
    </div>
    <!--  表格数据  -->
    <AnjunTableList
      ref="anjunTable"
      :format-time="isTormat"
      :header-list="tableHeaderList"
      :is-tip="true"
      :table-data="tableData"
      @to-OrderDetail="toOrderDetail"
      @on-click-text="onLook"
    />
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.number"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!--  MDFE弹窗  -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="true"
      :title="$t('GlobalSetObj.GeraMDFE')"
      :visible.sync="Mdfeialog"
      :width="IsMdfe"
      top="15vh"
      @close="clearForm"
    >
      <div class="mdfeClass">
        <el-form
          :key="Mdfeialog" ref="mdfeForm" :model="mdfeForm"
          label-position="top"
        >
          <!-- 车签号： -->
          <el-form-item
            :label="`${$t('GlobalSetObj.NIV')}:`"
            :rules="tagNumberRuler"
            prop="tagNumber"
          >
            <el-select
              v-model="mdfeForm.tagNumber"
              v-loadmore="loadmore"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              style="width: 80%"
              @change="handleTagNumber"
            >
              <el-option
                v-for="item in tagNumberList"
                :key="item.id"
                :label="`${item.tagNumber}`"
                :value="item.tagNumber"
              >
                <span style="float: left">{{ item.tagNumber }}</span>
                <span style="float: right">{{ item.createTime }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div v-if="crossCitySize || cteSize" style="margin-top: 50px">
          <span style="margin-right: 40px">{{ $t('GlobalSetObj.Enviepacotes') }}: {{ crossCitySize }}</span>
          <span>{{ $t('GlobalSetObj.Quantidade') }}: {{ cteSize }}</span>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="MdfeialogClose">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button
          :disabled="isBtn" :type="isBtn ? 'info' : 'primary'" size="small"
          @click="confirmMdef"
        >
          {{ $t('GlobalSetObj.confirm') }}
        </el-button>
      </div>
    </el-dialog>
    <!--  MDFE弹窗  -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="true"
      :title="$t('GlobalSetObj.GeraMDFE')"
      :visible.sync="driverMdfeialog"
      :width="IsMdfe"
      top="15vh"
    >
      <div class="driverMdfeialogClass">
        <div style="display: flex;align-items: center">
          <div style="margin-right: 10px;">{{ $t('GlobalSetObj.driver') }}</div>
          <el-select
            v-model="driversId"
            v-loadmore="loadmoreDriver"
            :placeholder="$t('GlobalSetObj.pleaseChoose')"
            clearable
            style="width: 60%;"
            @change="handleChange1"
          >
            <el-option
              v-for="item in driversList"
              :key="item.id"
              :label="item.driverName"
              :value="item.id"
            />
          </el-select>
          <div style="margin-left: 10px;cursor: pointer;color: #409eff;" @click="addMdfe">
            {{ $t('GlobalSetObj.append') }}
          </div>
        </div>
        <div style="margin-top: 100px;color: #a4a7ab;">{{ $t('GlobalSetObj.verificarEstado') }}</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="MdfeialogClose">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button size="small" type="primary" @click="confirmMdef1">{{ $t('GlobalSetObj.confirm') }}</el-button>
      </div>
    </el-dialog>
    <!--详情-->
    <el-drawer
      :append-to-body="true"
      :title="$t('GlobalSetObj.WrappedInsideTheBag')"
      :visible.sync="SummaryDetailsDrawer"
      custom-class="drawerClass"
      direction="ltr"
      size="55%"
    >
      <div style="height: calc(100vh - 67px); overflow-y: auto;padding: 20px">
        <div class="packageList">
          <!--运单号-->
          <div style="margin-bottom: 10px;">{{
            $t('GlobalSetObj.waybillNumber') + '/' + $t('GlobalSetObj.serviceLogistics')
          }}
          </div>
          <el-input
            v-model="packageListForm.params.waybillNumber"
            :placeholder="$t('GlobalSetObj.pleaseInput')"
            clearable
            style="width: 50%"
          >
            <el-button
              slot="append" icon="el-icon-search" type="primary"
              @click="searchPackageList"
            />
          </el-input>
          <div style="margin-top: 20px;">
            <AnjunTableList
              ref="PackageList"
              :format-time="isTormat"
              :has-operation="false"
              :header-list="packageTableHeaderList"
              :is-select-btn="false"
              :is-tip="true"
              :table-data="packageListTableData"
            />
            <!--  分页  -->
            <el-pagination
              :current-page="packageListForm.pageNumber"
              :page-size="packageListForm.pageSize"
              :page-sizes="[10, 20, 50, 100]"
              :pager-count="5"
              :total="packageListForm.total"
              background
              layout="total, sizes, prev, pager, next"
              style="margin: 10px 0;text-align: right"
              @size-change="packageListSizeChange"
              @current-change="packageListCurrentChange"
            />
          </div>
        </div>
      </div>
    </el-drawer>
    <!--  卸货点清单  -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="title"
      :visible.sync="showListUnloadingPoints"
      :width="IsMdfe"
      @close="clearShippingList"
    >
      <div>
        <el-form
          :key="showListUnloadingPoints"
          ref="UnloadingPointsList"
          :model="UnloadingPointsList"
          :rules="rulesData"
          label-position="top"
        >
          <el-form-item :label="$t('GlobalSetObj.PleaseSelectVehicle')" prop="vehicleNumber">
            <el-select
              v-model="UnloadingPointsList.vehicleNumber"
              :placeholder="$t('GlobalSetObj.PleaseSelectVehicle')"
              clearable
              filterable
              style="width: 100%;"
              @change="changeVehicleNumber"
            >
              <el-option
                v-for="(item, ind) in tableCarNumber"
                :key="ind"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('GlobalSetObj.PleaseSelectCarNumber')" prop="tagNumber">
            <el-select
              v-model="UnloadingPointsList.tagNumber"
              :placeholder="$t('GlobalSetObj.PleaseSelectCarNumber')"
              clearable
              filterable
              style="width: 100%;"
            >
              <el-option
                v-for="item in vehicleIdentificationNumber"
                :key="item.id"
                :label="`${item.tagNumber}`"
                :value="item.tagNumber"
              >
                <span style="float: left;margin-right: 30px">{{ item.tagNumber }}</span>
                <span>{{ item.createTime }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showListUnloadingPoints = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button type="primary" @click="clickDistributvehicle(title)">{{ $t('GlobalSetObj.confirm') }}</el-button>
      </span>
    </el-dialog>
    <!--打印清单-->
    <div style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;z-index: -100;">
      <div v-if="showShippingList" ref="logisticsNumber">
        <invoice-html ref="logisticsNumber1" :param-data="paramData" @clearShippingList="clearShippingList" />
      </div>
    </div>
    <!-- 打印TransferMinfisto模板-->
    <div style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;z-index: -900;">
      <TransferManifestHtml
        v-if="showTransferList"
        :param-transfer-data="paramTransferData"
        @clearTransferList="clearTransferList"
      />
    </div>
    <!--  Shipping Minfisto  -->
    <el-dialog
      v-loading="loading"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="showShippingMinfisto"
      :width="IsMdfe"
      element-loading-background="rgba(250, 250, 250, 0.6)"
      element-loading-spinner="el-icon-loading"
      element-loading-text="loading..."
      title="Shipping Manifestos"
      @close="clearShippingMinfisto"
    >
      <div>
        <el-form
          :key="showShippingMinfisto"
          ref="ShippingMinfistoObj"
          :model="ShippingMinfistoObj"
          :rules="rulesShippingData"
          label-position="top"
        >
          <el-form-item :label="$t('GlobalSetObj.PleaseSelectVehicle')" prop="vehicleNumber">
            <el-select
              v-model="ShippingMinfistoObj.vehicleNumber"
              :placeholder="$t('GlobalSetObj.PleaseSelectVehicle')"
              clearable
              filterable
              style="width: 100%;"
              @change="smchangeVehicleNumber"
            >
              <el-option
                v-for="(item, ind) in vehicleNumberList"
                :key="ind"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('GlobalSetObj.PleaseSelectCarNumber')" prop="tagNumber">
            <el-select
              v-model="ShippingMinfistoObj.tagNumber"
              :disabled="VehicleTagNumber.length === 0"
              :placeholder="$t('GlobalSetObj.PleaseSelectCarNumber')"
              clearable
              filterable
              style="width: 100%;"
              @change="changeTagNumber"
            >
              <el-option
                v-for="item in VehicleTagNumber"
                :key="item.id"
                :label="`${item.tagNumber}`"
                :value="item.tagNumber"
              >
                <span style="float: left;margin-right: 30px">{{ item.tagNumber }}</span>
                <span>{{ item.createTime }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <div v-if="errMsg" style="color: #f56c6c">{{ errMsg }}</div>
          <el-form-item prop="driver">
            <template slot="label">
              <span>{{ $t('GlobalSetObj.driver') }}</span>
              <span style="float: right;color: #409eff;cursor: pointer" @click="addMdfe">{{
                $t('GlobalSetObj.create')
              }}</span>
            </template>
            <el-select
              v-model="ShippingMinfistoObj.driver"
              :disabled="isSelect"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              style="width: 100%;"
              @change="changeDrivers"
            >
              <el-option
                v-for="item in allDrivers"
                :key="item.id"
                :label="item.driverName"
                :value="item.driverLicense"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showShippingMinfisto = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button type="primary" @click="clickShippingMinfisto">{{ $t('GlobalSetObj.confirm') }}</el-button>
      </span>
    </el-dialog>
    <!-- Shipping Minfisto模板  v-if="showShippingMinfistoPdf" s-->
    <div style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;z-index: -900;">
      <div v-if="showShippingMinfistoPdf" ref="ShippingMinfistoPdftHtml1">
        <ShippingMinfistoPdftHtml
          ref="ShippingMinfistoPdftHtml2"
          :param-shipping-data="paramShippingMinfistoData"
          @clearShippingMin="clearShippingMin"
        />
      </div>

    </div>
  </div>
</template>

<script>
import AnjunTableList from '@/components/anjun-tableList';
import {
  buildCostMdfe,
  findDriversAll,
  findVehicleTagAndDrivers,
  findVehicleTagNumber,
  getCteInfo,
  getRouteGuideList,
  getTagNumber,
  gettransitBagNumber,
  logisticsDrivers,
  saveVehicleTagAndDrivers,
  saveVehicleTagNumber,
  transitBagNumberPage
} from '@/api/routerPlan';
import { apiSelectByBagNumber } from '@/api/storeAdmin.js';
import invoiceHtml from './invoiceHtml';
import TransferManifestHtml from './TransferManifestHtml';
import ShippingMinfistoPdftHtml from './ShippingMinfistoPdf';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import dayjs from 'dayjs';

export default {
  name: 'TrunkStatus',
  components: {
    AnjunTableList,
    invoiceHtml,
    TransferManifestHtml,
    ShippingMinfistoPdftHtml
  },
  data() {
    return {
      Mdfeialog: false,
      SummaryDetailsDrawer: false,
      // 是否格式化成巴西时间
      isTormat: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      queryForm: {
        pageSize: 10,
        number: 1,
        total: 0,
        params: {
          createStartTime: this.getTimer().today + ' 00:00:00', // 订单创建时间
          createEndTime: this.getTimer().today + ' 23:59:59', // 订单创建时间
          shippingStatus: '',
          searchTimeType: '1',
          searchType: '1',
          inputValue: ''
        }
      },
      isLoadCar: '1',
      showList: false,
      searchList: [],
      supplierMainList: [],
      tableHeaderList: [
        { id: 1, label: this.$t('orderCenterCont.TransitBinBagNumber'), prop: 'transitBagNumber', cloWidth: '250' },
        { id: 7, label: this.$t('GlobalSetObj.status'), prop: 'statusName', cloWidth: '160' },
        { id: 2, label: this.$t('GlobalSetObj.Carregador'), prop: 'loadingUserName', cloWidth: '200' },
        { id: 8, label: this.$t('GlobalSetObj.licensePlateNumber'), prop: 'vehicleNumber', cloWidth: '160' },
        { id: 9, label: this.$t('GlobalSetObj.NIV'), prop: 'vehicleTagNumber', cloWidth: '160', clickText: true },
        { id: 5, label: this.$t('GlobalSetObj.LoadCarTime'), prop: 'loadingTime', cloWidth: '180' },
        { id: 4, label: this.$t('GlobalSetObj.DeliveryRoutes'), prop: 'routeNumber', cloWidth: '180', clickClass: false },
        { id: 6, label: this.$t('GlobalSetObj.Descarregador'), prop: 'unloadingUserName', cloWidth: '180' },
        { id: 3, label: this.$t('GlobalSetObj.unCarTime'), prop: 'unloadingTime', cloWidth: '180' }
      ],
      tableData: [],
      searchStr: '',
      switchSearchType: this.$t('orderCenterCont.TransitBinBagNumber'),
      // 勾选表格数据
      selectionList: [],
      searchNumberType: '1',
      searchNumber: '',
      // 袋内包裹
      packageListTableData: [],
      packageTableHeaderList: [
        // 运单号
        { id: 1, label: this.$t('GlobalSetObj.waybillNumber'), prop: 'waybillNumber', cloWidth: '170' },
        // 服务商物流单号
        { id: 4, label: this.$t('GlobalSetObj.serviceLogistics'), prop: 'logisticsNumber', cloWidth: '200' },
        // 状态
        { id: 2, label: this.$t('GlobalSetObj.status'), prop: 'statusNamewaybill', cloWidth: '160' }
      ],
      packageListForm: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
        params: {
          transitBagNumber: '',
          waybillNumber: ''
        }
      },
      showListUnloadingPoints: false,
      UnloadingPointsList: {
        vehicleNumber: '',
        tagNumber: ''
      },
      rulesData: {
        vehicleNumber: [
          { required: true, message: this.$t('operatingPlatform.notNull'), target: ['change', 'blur'] }
        ],
        tagNumber: [
          { required: true, message: this.$t('operatingPlatform.notNull'), target: ['change', 'blur'] }
        ]
      },
      rulesShippingData: {
        vehicleNumber: [
          { required: true, message: this.$t('operatingPlatform.notNull'), target: ['change', 'blur'] }
        ],
        tagNumber: [
          { required: true, message: this.$t('operatingPlatform.notNull'), target: ['change', 'blur'] }
        ],
        driver: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), target: ['blur'] }
        ]
      },
      tableCarNumber: [],
      vehicleIdentificationNumber: [],
      showShippingList: false,
      paramData: [],
      vehicleNumberDiff: false, // 判断车牌号是否重复
      driverMdfeialog: false,
      crossCitySize: 0, // 跨域包裹数
      cteSize: 0,
      isBtn: false,
      transitBagNumberList: [], // 袋号数组
      routerNumberDiff: false, // 判断路线号是否不同
      invoiceList: [],
      driversList: [], // 司机
      driversId: '',
      BagNumber: [],
      tagNumberRuler: [
        { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: ['blur', 'change'] }
      ],
      mdfeForm: {
        tagNumber: ''
      },
      tagNumberList: [], // 车签号
      CteInfo: {},
      nivQuery: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          tagNumber: ''
        }
      },
      queryDriver: {
        pageSize: 10,
        pageNumber: 0
      },
      showTransferList: false,
      title: '',
      vehicleNumberList: [], // 车辆 ==> 王
      VehicleTagNumber: [], // 车签号 ==> 王
      paramTransferData: [],
      showTransferMinfisto: false,
      showShippingMinfisto: false,
      ShippingMinfistoObj: {
        vehicleNumber: '',
        tagNumber: '',
        driver: ''
      },
      showShippingMinfistoPdf: false,
      paramShippingMinfistoData: {},
      allDrivers: [],
      findDriversObj: {},
      loading: false,
      errMsg: '', // 车签请求报错信息
      isSelect: true
    };
  },
  computed: {
    shippStatusName() {
      return {
        0: { label: this.$t('GlobalSetObj.NoCarregado'), code: 0 }, // 未装车
        1: { label: this.$t('GlobalSetObj.Carregado'), code: 1 }, // 已装车
        2: { label: this.$t('GlobalSetObj.Descarregado'), code: 2 } // 已卸载
      };
    },
    waybillStatusObject() {
      return {
        1: { label: this.$i18n.t('newOrder.ToBeAssigned'), code: 1 }, // 待分配
        2: { label: this.$i18n.t('newOrder.ToBeCollected'), code: 2 }, // 待揽收
        3: { label: this.$i18n.t('newOrder.Collected'), code: 3 }, // 已揽收
        4: { label: this.$i18n.t('newOrder.CollectTheRevenuePool'), code: 4 }, // 揽收入库
        5: { label: this.$i18n.t('newOrder.CollectTakeOutWarehouse'), code: 5 }, // 揽收出库
        19: { label: this.$i18n.t('GlobalSetObj.moduan'), code: 19 }, // 末端已揽收
        6: { label: this.$i18n.t('newOrder.TransshipmentIntoStorage'), code: 6 }, // 转运入库
        7: { label: this.$i18n.t('newOrder.TransferOutWarehouse'), code: 7 }, // 转运出库
        8: { label: this.$i18n.t('newOrder.DeliveryOutletsIntoStorage'), code: 8 }, // 派送网点入库
        9: { label: this.$i18n.t('newOrder.Delivering'), code: 9 }, // 派送中
        10: { label: this.$i18n.t('newOrder.Signed'), code: 10 }, // 已签收
        11: { label: this.$i18n.t('newOrder.ProblemPiece'), code: 11 }, // 问题件
        12: { label: this.$i18n.t('collectionCenter.unclaimed'), code: 12 }, // 待取件
        13: { label: this.$i18n.t('GlobalSetObj.Canceled'), code: 13 }, // 已取消

        17: { label: this.$i18n.t('GlobalSetObj.DotBack'), code: 17 }, // 网点退回
        18: { label: this.$i18n.t('GlobalSetObj.ReturnToStorage'), code: 18 }, // 退仓入库
        15: { label: this.$i18n.t('GlobalSetObj.Withdrawn'), code: 15 }, // 退件中
        16: { label: this.$i18n.t('GlobalSetObj.TheReturnComplete'), code: 16 }, // 退件完成
        200: { label: '', code: 200 } // 问题件
      };
    },
    IsWidth() {
      return {
        width: this.isTormat ? '300px' : '120px'
      };
    },
    IsMdfe() {
      return this.isTormat ? '38%' : '25%';
    },
    IsWidthTime() {
      return {
        width: this.isTormat ? '160px' : '110px'
      };
    }
  },
  watch: {
    SummaryDetailsDrawer(val) {
      this.packageListForm.params.waybillNumber = '';
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getSearch();
  },
  methods: {
    // 关闭清单弹窗
    clearShippingList() {
      this.showListUnloadingPoints = false;
      this.showShippingList = false;
      this.UnloadingPointsList = {
        vehicleNumber: '',
        tagNumber: ''
      };
    },
    // 关闭清单弹窗
    clearTransferList() {
      this.showListUnloadingPoints = false;
      this.showTransferList = false;
      this.UnloadingPointsList = {
        vehicleNumber: '',
        tagNumber: ''
      };
      this.$message.success(this.$t('collectionCenter.submitSuccessfully'));
    },
    // 车牌号更改
    changeVehicleNumber(val) {
      if (!val) {
        return;
      }
      this.vehicleIdentificationNumber = [];
      findVehicleTagNumber(val).then(res => {
        if (res.status === 'OK') {
          this.vehicleIdentificationNumber = res.data;
        }
      });
      this.UnloadingPointsList.tagNumber = '';
    },
    // 确定打印卸货点清单
    clickDistributvehicle() {
      this.$refs.UnloadingPointsList.validate((valid) => {
        if (valid) {
          if (this.title === 'Transfer Manifestos') {
            const data = {
              vehicleNumbers: [],
              vehicleTagNumbers: []
            };
            data.vehicleNumbers.push(this.UnloadingPointsList.vehicleNumber);
            data.vehicleTagNumbers.push(this.UnloadingPointsList.tagNumber);
            saveVehicleTagNumber(data).then(res => {
              if (res.status === 'OK') {
                this.paramTransferData = res.data;
                // this.paramTransferData.printTime = dayjs().format('DD/MM/YYYY HH:mm:ss');
                this.showTransferList = true;
              }
            });
          } else {
            getRouteGuideList(this.UnloadingPointsList).then(res => {
              if (res.status === 'OK') {
                this.paramData = res.data;
                this.showShippingList = true;
                setTimeout(() => {
                  const offsetWidth = this.$refs['logisticsNumber1'].$el.offsetWidth;
                  const offsetHeight = this.$refs['logisticsNumber1'].$el.offsetHeight;
                  html2canvas(this.$refs['logisticsNumber'], {
                    width: offsetWidth,
                    height: offsetHeight,
                    backgroundColor: '#ffffff',
                    scale: 1.5,
                    useCORS: true // //支持图片跨域
                  }).then((canvas) => {
                    const contentWidth = canvas.width;
                    const contentHeight = canvas.height;
                    // 一页pdf显示html页面生成的canvas高度;
                    const pageHeight = (contentWidth / 592.28) * 841.89;
                    // 未生成pdf的html页面高度
                    let leftHeight = contentHeight;
                    // 页面偏移
                    let position = 20;
                    // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
                    const imgWidth = 595.28 - 40;
                    const imgHeight = (595.28 / contentWidth) * contentHeight;
                    const url = canvas.toDataURL('image/png', 1.0); // 生成的图片
                    const pdf = new JsPDF('', 'pt', 'a4');
                    // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
                    // 当内容未超过pdf一页显示的范围，无需分页
                    if (leftHeight < pageHeight) {
                      // 在pdf.addImage(pageData, 'JPEG', 左，上，宽度，高度)设置在pdf中显示；
                      pdf.addImage(url, 'JPEG', 20, position, imgWidth, imgHeight);
                      // pdf.addImage(pageData, 'JPEG', 20, 40, imgWidth, imgHeight);
                    } else {
                      // 分页
                      while (leftHeight > 0) {
                        pdf.addImage(url, 'JPEG', 20, position, imgWidth, imgHeight);
                        leftHeight -= pageHeight;
                        position -= 841.89;
                        // 避免添加空白页
                        if (leftHeight > 0) {
                          pdf.addPage();
                        }
                      }
                    }
                    // 可动态生成
                    const date = new Date();
                    const brazilTime = date.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
                    const today = dayjs(brazilTime).format('MMYYYYDDHHmmss');
                    let number = '';
                    for (let i = 0; i < 4; i++) {
                      number = number + Math.floor(Math.random() * 10);
                    }
                    const name = this.$t('GlobalSetObj.ListUnloadingPoints') + today + number;
                    pdf.save(name);
                    this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
                    this.clearShippingList();
                  });
                }, 500);
              }
            });
          }
        }
      });
    },
    // 打开卸货点清单弹窗
    listUnloadingPoints(title) {
      this.title = title;
      // const param = {
      //   vehicleId: '',
      //   vehicleNumber: '',
      //   tagNumber: ''
      // };
      this.tableCarNumber = [];
      gettransitBagNumber().then(res => {
        if (res.status === 'OK') {
          this.tableCarNumber = res.data;
        }
      });
      this.showListUnloadingPoints = true;
      this.vehicleIdentificationNumber = [];
      // const param = {
      //   vehicleId: '',
      //   vehicleNumber: '',
      //   tagNumber: ''
      // };
      // getVehicleNumber(param).then(res => {
      //   if (res.status === 'OK') {
      //     this.tableCarNumber = [];
      //     res.data.map(item => {
      //       if (item) {
      //         this.tableCarNumber.push(item);
      //       }
      //     });
      //   }
      // });
    },
    // 点击添加
    addMdfe() {
      console.log('1235');
      const promisebutton = this.$store.getters.roles;
      const flag = promisebutton.includes('menu:transport');
      if (flag) {
        this.$router.push('/transport/createDriver');
      } else {
        this.$message.error(this.$t('GlobalSetObj.NoPermission'));
      }
    },
    // 加载更多
    loadmore() {
      console.log('wojiazai');
      this.nivQuery.pageSize = this.nivQuery.pageSize + 10;
      getTagNumber(this.nivQuery).then(res => {
        if (res.status === 'OK') {
          console.log(res.data.records, 'sdfsdf');
          this.tagNumberList = res.data.records;
          this.tagNumberList.map(item => {
            item.createTime = dayjs(item.createTime).format('HH:mm:ss DD/MM/YY');
          });
        }
      });
    },
    // 切换车签号
    handleTagNumber(val) {
      if (!val) {
        this.crossCitySize = 0;
        this.cteSize = 0;
        this.isBtn = true;
        return;
      }
      const data = {};
      data.tagNumber = val;
      getCteInfo(data).then(res => {
        if (res.status === 'OK') {
          this.crossCitySize = res.data.crossCitySize;
          this.cteSize = res.data.cteSize;
          this.CteInfo = res.data;
          this.invoiceList = res.data.invoiceList;
          this.BagNumber = res.data.transitBagNumber;
          if (this.cteSize > 0) {
            this.isBtn = false;
          } else {
            this.isBtn = true;
          }
        }
      }).catch(e => {
        this.crossCitySize = 0;
        this.cteSize = 0;
        this.isBtn = true;
      });
    },
    // 点击Mdfe按钮,弹窗
    MDFEBtn() {
      this.driversList = [];
      this.Mdfeialog = true;
      this.transitBagNumberList = [];
      this.invoiceList = [];
      this.selectionList.forEach(item => {
        this.transitBagNumberList.push(item.transitBagNumber);
      });
      // const data = {
      //   pageSize: 10,
      //   pageNumber: 1,
      //   params: {
      //     tagNumber: ''
      //   }
      // };
      getTagNumber(this.nivQuery).then(res => {
        if (res.status === 'OK') {
          console.log(res.data.records, 'sdfsdf');
          this.tagNumberList = res.data.records;
          this.tagNumberList.map(item => {
            item.createTime = dayjs(item.createTime).format('HH:mm:ss DD/MM/YY');
          });
        }
      });
    },
    MdfeialogClose() {
      this.Mdfeialog = false;
      this.driverMdfeialog = false;
    },
    clearForm() {
      this.mdfeForm.tagNumber = '';
      this.crossCitySize = 0;
      this.cteSize = 0;
    },
    loadmoreDriver() {
      this.queryDriver.pageSize = this.queryDriver.pageSize + 10;
      this.queryDriver.pageNumber = 0;
      logisticsDrivers(this.queryDriver).then(res => {
        if (res.status === 'OK') {
          this.driversList = res.data;
          this.driverMdfeialog = true;
        }
      });
      this.driversId = '';
    },
    // 请求司机
    confirmMdef() {
      this.$refs['mdfeForm'].validate((valid) => {
        if (valid) {
          this.queryDriver.pageSize = 10;
          this.queryDriver.pageNumber = 0;
          logisticsDrivers(this.queryDriver).then(res => {
            if (res.status === 'OK') {
              this.driversList = res.data;
              this.driverMdfeialog = true;
            }
          });
          this.driversId = '';
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 提交mdfe
    confirmMdef1() {
      const objDrive = this.driversList.find((item) => {
        return item.id == this.driversId;
      });
      if (!this.driversId) {
        this.$message.error(this.$t('collectionCenter.pleaseCheckDriver'));
        return;
      }
      console.log(objDrive);
      let data = {};
      data = this.CteInfo;
      data.transitBagNumber = this.BagNumber;
      data.invoiceList = this.invoiceList;
      if (objDrive) {
        data.driverName = objDrive.driverName;
        data.driverDocument = objDrive.driverDocument;
        data.driverId = objDrive.id;
      }
      // return;
      buildCostMdfe(data).then(res => {
        if (res.status === 'OK') {
          this.$message.success(this.$t('GlobalSetObj.MDFESuccess'));
          this.MdfeialogClose();
          this.getSearchEnter();
        }
      });
    },
    // 判断数组是否相同值
    isUniform(array) {
      const uniqueValues = new Set(array); // 将数组转换成一个 Set，自动去重
      return uniqueValues.size === 1;
    },
    // 重置
    resetSearch() {
      this.queryForm.number = 1;
      this.queryForm.params.createStartTime = this.getTimer().today + ' 00:00:00';
      this.queryForm.params.createEndTime = this.getTimer().today + ' 23:59:59';
      this.queryForm.params.shippingStatus = '';
      this.queryForm.params.searchTimeType = '1';
      this.queryForm.params.searchType = '1';
      this.isLoadCar = '1';
      this.queryForm.params.inputValue = '';
      this.getSearch();
    },
    getSearchEnter() {
      this.queryForm.number = 1;
      this.getSearch();
    },
    // 查看详情
    toOrderDetail(row) {
      this.SummaryDetailsDrawer = true;
      console.log(row, '445566');
      this.packageListForm.params.transitBagNumber = row.transitBagNumber;
      this.getPackageListTable(row.transitBagNumber);
    },
    // 获取包裹数量列表
    getPackageListTable(transitBagNumber) {
      this.packageListForm.params.transitBagNumber = transitBagNumber;
      apiSelectByBagNumber(this.packageListForm).then(res => {
        if (res.status === 'OK') {
          this.packageListForm.total = res.data.total;
          this.packageListTableData = res.data.records;
          this.packageListTableData.map(item => {
            if (this.waybillStatusObject[item.waybillStatus].label) {
              item.statusNamewaybill = this.waybillStatusObject[item.waybillStatus].label;
            }
          });
        }
      });
    },
    // 切换
    handleChange(val) {
      this.queryForm.params.shippingStatus = val;
      this.queryForm.number = 1;
      this.getSearch();
    },
    handleChange1(val) {
      console.log(val, 'driverDocument');
      this.driversId = val;
    },
    // 切换搜索类型
    SwitchType(val) {
      if (val === '1') {
        this.switchSearchType = this.$t('orderCenterCont.TransitBinBagNumber');
      } else if (val === '2') {
        this.switchSearchType = this.$t('GlobalSetObj.DeliveryRoutes');
      } else if (val === '3') {
        this.switchSearchType = this.$t('GlobalSetObj.Carregador');
      } else if (val === '4') {
        this.switchSearchType = this.$t('GlobalSetObj.Descarregador');
      } else {
        this.switchSearchType = this.$t('GlobalSetObj.licensePlateNumber');
      }
    },
    SwitchTimeType(val) {
      this.isLoadCar = val;
    },
    // 清除列表搜索
    clearSearch() {
      this.searchList = [];
      this.searchStr = '';
    },
    // 打开\关闭多个单号输入
    openSearchList() {
      this.showList = !this.showList;
      this.queryForm.params.inputValue = '';
    },
    // 打开或者关闭
    openText() {
      this.showList = !this.showList;
    },
    // 关闭弹窗
    clearShippingMinfisto() {
      this.ShippingMinfistoObj = {
        vehicleNumber: '',
        tagNumber: '',
        driver: ''
      };
    },
    // 触发pdf关闭
    clearShippingMin() {
      this.showShippingMinfisto = false;
      this.showShippingMinfistoPdf = false;
      this.ShippingMinfistoObj = {
        vehicleNumber: '',
        tagNumber: '',
        driver: ''
      };
      this.$message.success(this.$t('collectionCenter.submitSuccessfully'));
    },
    // 打开Shipping Minfisto弹窗
    ShippingMinfistoBtn() {
      this.vehicleNumberList = [];
      gettransitBagNumber().then(res => {
        if (res.status === 'OK') {
          this.vehicleNumberList = res.data;
        }
      });
      this.showShippingMinfisto = true;
      this.VehicleTagNumber = [];
    },
    // 切换sm司机
    smchangeVehicleNumber(val) {
      console.log(val, '切换sm车辆');
      this.VehicleTagNumber = [];
      this.allDrivers = [];
      this.ShippingMinfistoObj.tagNumber = '';
      this.ShippingMinfistoObj.driver = '';
      this.errMsg = '';
      if (!val) {
        return;
      }
      findVehicleTagNumber(val).then(res => {
        if (res.status === 'OK') {
          this.VehicleTagNumber = res.data;
          console.log(res, 'findVehicleTagNumber');
          // this.vehicleIdentificationNumber = res.data;
        }
      });
    },
    // 切换车签号
    changeTagNumber(val) {
      console.log(val, '切换sm车辆');
      this.allDrivers = [];
      this.errMsg = '';
      this.ShippingMinfistoObj.driver = '';
      if (!val) {
        return;
      }
      findVehicleTagAndDrivers(val).then(res => {
        if (res.status === 'OK') {
          this.isSelect = true;
          this.allDrivers = res.data;
          this.ShippingMinfistoObj.driver = res.data[0].driverLicense;
        }
      }).catch(e => {
        // this.getAllDriver();
        this.allDrivers = e.data;
        if (this.allDrivers.length) {
          this.isSelect = false;
        }
        if (e.data === '') {
          this.errMsg = e.msg;
        } else {
          this.errMsg = '';
        }
      });
    },
    // 请求所有的司机
    getAllDriver() {
      this.loading = true;
      this.allDrivers = [];
      findDriversAll().then(res => {
        this.allDrivers = res.data;
        this.loading = false;
        // loading.close();
      }).catch(e => {
        this.loading = false;
      });
    },
    // 选择司机
    changeDrivers(val) {
      this.ShippingMinfistoObj.driver = val;
      this.findDriversObj = {};
      if (this.allDrivers.length > 0) {
        this.findDriversObj = this.allDrivers.find(item => {
          return item.driverLicense === this.ShippingMinfistoObj.driver;
        });
      }
      console.log(this.findDriversObj, 'this.findDriversObj');
    },
    clickShippingMinfisto() {
      // return;
      this.$refs.ShippingMinfistoObj.validate((valid) => {
        if (valid) {
          const data = {
            vehicleNumbers: [],
            vehicleTagNumbers: [],
            driverId: '',
            driverDocument: '',
            driverName: '',
            driverLicense: ''
          };

          data.vehicleNumbers.push(this.ShippingMinfistoObj.vehicleNumber);
          data.vehicleTagNumbers.push(this.ShippingMinfistoObj.tagNumber);
          if (this.isSelect) {
            data.driverId = this.allDrivers[0].id;
            data.driverDocument = this.allDrivers[0].driverDocument;
            data.driverName = this.allDrivers[0].driverName;
            data.driverLicense = this.allDrivers[0].driverLicense;
          } else {
            data.driverId = this.findDriversObj.id;
            data.driverDocument = this.findDriversObj.driverDocument;
            data.driverName = this.findDriversObj.driverName;
            data.driverLicense = this.findDriversObj.driverLicense;
          }
          console.log(data, 'saveVehicleTagAndDrivers');
          // return;
          saveVehicleTagAndDrivers(data).then(res => {
            if (res.status === 'OK') {
              this.paramShippingMinfistoData = res.data;
              console.log(res.data, 'saveVehicleTagAndDrivers');
              const loading = this.$loading({
                fullscreen: true,
                lock: true,
                spinner: 'el-icon-loading',
                text: this.$i18n.t('orderCenterCont.DesperatelyLoading'), // '拼命加载中',
                background: 'rgba(250, 250, 250, 0.6)'
              });
              this.showShippingMinfistoPdf = true;
              setTimeout(() => {
                this.clearShippingMin();
                const offsetWidth = this.$refs['ShippingMinfistoPdftHtml2'].$el.offsetWidth;
                const offsetHeight = this.$refs['ShippingMinfistoPdftHtml2'].$el.offsetHeight;
                html2canvas(this.$refs['ShippingMinfistoPdftHtml1'], {
                  width: offsetWidth,
                  height: offsetHeight,
                  backgroundColor: '#ffffff',
                  scale: 1.5,
                  useCORS: true // //支持图片跨域
                }).then((canvas) => {
                  const contentWidth = canvas.width;
                  const contentHeight = canvas.height;
                  // 一页pdf显示html页面生成的canvas高度;
                  const pageHeight = (contentWidth / 592.28) * 841.89;
                  // 未生成pdf的html页面高度
                  let leftHeight = contentHeight;
                  // 页面偏移
                  let position = 20;
                  // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
                  const imgWidth = 595.28 - 40;
                  const imgHeight = (595.28 / contentWidth) * contentHeight;
                  const url = canvas.toDataURL('image/png', 1.0); // 生成的图片
                  const pdf = new JsPDF('', 'pt', 'a4');
                  // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
                  // 当内容未超过pdf一页显示的范围，无需分页
                  if (leftHeight < pageHeight) {
                    // 在pdf.addImage(pageData, 'JPEG', 左，上，宽度，高度)设置在pdf中显示；
                    pdf.addImage(url, 'JPEG', 20, position, imgWidth, imgHeight);
                    // pdf.addImage(pageData, 'JPEG', 20, 40, imgWidth, imgHeight);
                  } else {
                    // 分页
                    while (leftHeight > 0) {
                      pdf.addImage(url, 'JPEG', 20, position, imgWidth, imgHeight);
                      leftHeight -= pageHeight;
                      position -= 841.89;
                      // 避免添加空白页
                      if (leftHeight > 0) {
                        pdf.addPage();
                      }
                    }
                  }
                  // 可动态生成
                  const date = new Date();
                  const brazilTime = date.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
                  const today = dayjs(brazilTime).format('MMYYYYDDHHmmss');
                  let number = '';
                  for (let i = 0; i < 4; i++) {
                    number = number + Math.floor(Math.random() * 10);
                  }
                  const name = 'ShippingManifestos' + today + number;
                  pdf.save(name);
                  loading.close();
                  // this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
                }).finally(() => {
                  loading.close();
                });
              }, 100);
            }
          });
        }
      });
    },
    // 查询
    getSearch(type) {
      this.searchList = [];
      const numberList = this.searchStr.split('\n');
      numberList.map(item => {
        if (item) {
          item.toString().trim();
          if (!this.searchList.includes(item)) {
            this.searchList.push(item);
          }
        }
      });
      if (type === 1) {
        this.queryForm.params.inputValue = '';
        this.queryForm.number = 1;
        this.showList = false;
      }
      let inputValList = [];
      if (this.queryForm.params.inputValue) {
        this.queryForm.number = 1;
        inputValList.push(this.queryForm.params.inputValue);
      } else {
        inputValList = [...this.searchList];
      }
      const data = {};
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.number;
      data.params = {};
      if (this.isLoadCar === '1') {
        data.params.loadingBeginTime = this.queryForm.params.createStartTime;
        data.params.loadingEndTime = this.queryForm.params.createEndTime;
      } else {
        data.params.unloadingBeginTime = this.queryForm.params.createStartTime;
        data.params.unloadingEndTime = this.queryForm.params.createEndTime;
      }
      data.params.shippingStatus = this.queryForm.params.shippingStatus || null;
      if (this.queryForm.params.searchType === '1') {
        data.params.transitBagNumbers = inputValList;
      } else if (this.queryForm.params.searchType === '2') {
        data.params.routeNumbers = inputValList;
      } else if (this.queryForm.params.searchType === '3') {
        data.params.loadingUserName = inputValList;
      } else if (this.queryForm.params.searchType === '4') {
        data.params.unloadingUserName = inputValList;
      } else {
        data.params.vehicleNumbers = inputValList;
      }
      transitBagNumberPage(data).then(res => {
        if (res.status === 'OK') {
          this.tableCarNumber = [];
          this.queryForm.total = res.data.page.total;
          this.tableData = res.data.page.records;
          this.tableData.map(item => {
            if (this.shippStatusName[item.shippingStatus].label) {
              item.statusName = this.shippStatusName[item.shippingStatus].label;
            }
          });
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.number = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.number = val;
      this.getSearch();
    },
    // 点击分页
    packageListSizeChange(val) {
      this.packageListForm.pageSize = val;
      this.packageListForm.pageNumber = 1;
      this.getPackageListTable();
    },
    packageListCurrentChange(val) {
      this.packageListForm.pageNumber = val;
      this.getPackageListTable();
    },
    // 搜素包裹数列表
    searchPackageList() {
      this.packageListForm.pageNumber = 1;
      this.getPackageListTable(this.packageListForm.params.transitBagNumber);
    },
    // 获取最近一星期时间
    getTimer() {
      const day = new Date();
      const brazilTime = day.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
      console.log(brazilTime);
      const today = dayjs(brazilTime).format('YYYY-MM-DD');
      return {
        today
      };
    },
    // 改变时间
    handleTime(val) {
      this.queryForm.number = 1;
      this.getSearch();
    },
    /**
     * @description: 查看车签号
     */
    onLook(row) {
      this.$emit('setTagNumber', row.vehicleTagNumber);
    }
  }
};
</script>

<style scoped>
.mdfeClass {
  width: 100%;
  font-size: 16px;
  padding: 0 10px;
  line-height: 30px;
}

.app-container >>> .el-dialog__body {
  padding: 10px 20px;
}

.multipleQuery ::v-deep .el-form-item__content {
  margin-left: 0 !important;
}

.btnIcon {
  padding: 6px;
}

.searchMultiple {
  display: flex;
}

.searchMultiple .el-popover__reference-wrapper .btnIcon {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 40px;
}

.app-container >>> .searchMultiple .el-input__inner {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.app-container >>> .searchMultiple .el-input-group__prepend {
  background-color: transparent !important;
}

.searchBtn {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  width: 40px;
  height: 40px;
  padding: 7px;
}

.searchTip {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 15px;
  height: 28px;
  cursor: pointer;
}

.searchNumWarp {
  height: 28px;
  border: 1px solid #91B8FA;
  border-right: none;
  padding: 5px 5px 5px 5px;
  background: rgba(145, 184, 250, 0.2);
}

.searchNumWarp p {
  margin: 0;
}

.app-container >>> .drawerClass header {
  border-bottom: 1px solid #ccc !important;
  margin-bottom: 0 !important;
  padding: 20px 20px 20px !important;
}

.clearSearch {
  height: 28px;
  border: 1px solid #91B8FA;
  border-left: none;
  background: rgba(145, 184, 250, 0.2);
  cursor: pointer;
  padding-right: 5px;
  padding-top: 5px;
}

.wordBreak {
  word-break: break-all; /*word-break: normal|break-all|keep-all;在恰当的断字点进行换行,break-all 允许在单词内换行,keep-all 只能在半角空格或连字符处换行,normal 使用浏览器默认的换行规则。*/
  text-overflow: ellipsis; /*溢出文字显示省略号*/
  display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 1; /** 显示的行数 **/
  overflow: hidden; /** 隐藏超出的内容 **/
}

.app-container >>> .el-form--label-top .el-form-item__label {
  width: 100% !important;
}

.app-container >>> .selectLabel .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

.app-container >>> .selectLabel .el-input__icon {
  line-height: 28px !important;
}

.app-container >>> .packageList .el-input-group__append {
  background-color: #409EFF;
  border: 1px solid #409EFF;
  color: #fff;
}

.selectType ::v-deep.el-input--suffix .el-input__inner {
  border: none !important;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type='number'] {
  -moz-appearance: textfield !important;
}

.driverMdfeialogClass {
  height: 200px;
  padding-right: 20px;
  padding-left: 20px;
}
</style>
