import request from '@/utils/request';

// const baseURL = process.env.VUE_APP_MergeLargePackage;
const baseURL = process.env.VUE_APP_BASE_API4;
const baseURL2 = process.env.VUE_APP_BASE_API4;
console.log(baseURL2, '==baseURL2');
/**
 * 装袋列表分页
 */
export const baggingPage = data => {
  return request.post('/transitBagNumber/page', data);
};
/**
 * 所有渠道商
 */
export const getAllChannel = () => {
  return request.get('/bagging/getAllServerChannel');
};
/**
 * 所有渠道商
 */
export const getAllChannel1 = (data) => {
  return request.post('/bagging/getAllServerChannel', data);
};
/**
 * 生成大包号
 */
export const mergeLargePackage = (data) => {
  return request({
    method: 'post',
    data: data,
    url: '/wms/createBigBagNo',
    baseURL
  });
};

/**
 * 查询所有车辆
 */
export const getAllVehicle = () => {
  return request.get(`/collectVehicle/getAll`);
};

/**
 * 分配车辆
 */
export const distributvehicle = (data) => {
  return request({
    method: 'post',
    data: data,
    url: '/wms/allotCar',
    baseURL
  });
};
// 查询袋号返回
export const apiStorageRecordQuery = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/wms/storageRecord/queryBagNumber',
    data,
    baseURL: baseURL2,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 确定入库
export const apiStorageRecordSave = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/wms/storageRecord/saveStorageRecord',
    data,
    baseURL: baseURL2,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 查询入库信息
export const apiStorageRecordPage = (data, isBool = true) => {
  return request({
    method: 'POST',
    // url: '/wms/storageRecord/getAllStorageRecord',
    url: '/storageRecord/getAllStorageRecord',
    data,
    // baseURL: baseURL2,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 扫描装袋
export const apiQueryTransitBag = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/wms/transitBag/queryTransitBag',
    data,
    baseURL: baseURL2,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 查询装袋记录
export const apiPageTransitBag = (data, isBool = true) => {
  return request({
    method: 'POST',
    // url: '/wms/transitBag/pageTransitBag',
    url: '/tranSitBagNumber/pageTransitBag',
    data,
    // baseURL: baseURL2,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 确定封袋
export const apiSaveTransitBag = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/wms/transitBag/saveTransitBag',
    data,
    baseURL: baseURL2,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 查询更新袋号
export const apiGetsTransitBag = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/wms/transitBag/getsTransitBag',
    data,
    baseURL: baseURL2,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 确定更新袋号
export const apiUpdateTransitBag = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/wms/transitBag/getPackgeRecords',
    data,
    baseURL: baseURL2,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 查询扫描出库记录
export const apiGetAllScanOut = (data, isBool = true) => {
  return request({
    method: 'POST',
    // url: '/wms/scanOut/getAllScanOut',
    url: '/scanOut/pageScanOut',
    data,
    // baseURL: baseURL2,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 扫码查询出库袋号是否存在
export const apiGetScanOut = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/wms/scanOut/getScanOut',
    data,
    baseURL: baseURL2,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 确定出库
export const apiSaveScanOut = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/wms/scanOut/saveScanOut',
    data,
    baseURL: baseURL2,
    isLoading: isBool // 是否显示加载中参数
  });
};
/**
 * 大包列表分页
 */
export const lagePackgePage = data => {
  return request.post('/wms/bigBag/page', data);
};
// 总单列表导出
export const apiExportTotalList = data => {
  return request.post('/wms/bigBag/exportTotalList', data);
};
/**
 * 根据大包号查询包内袋号信息
 */
export const selectBigBagNumber = data => {
  return request.post('/wms/bigBag/selectBigBagNumber', data);
};
/**
 * 根据袋号删除
 */
export const deleteTransitBagNumber = data => {
  return request.post('/wms/bigBag/transitBagNumber/delete', data);
};
/**
 * 袋号明细分页
 */
export const transitBagNumberPage = data => {
  return request.post('/wms/bigBag/transitBagNumber/page', data);
};
// 袋号明细导出
export const apiExportTransitBig = data => {
  return request.post('/wms/bigBag/exportTransitBig', data);
};

/**
 * 运单明细分页
 */
export const bigBagwaybillNumber = data => {
  return request.post('/wms/bigBag/waybillNumber/page', data);
};

/**
 * 修改服务商的最小申报数量
 */
export const updateDeclaredCount = data => {
  return request.post('/providerSystem/updateDeclaredCount', data);
};
/**
 * 打印清单
 */
export const getLableUrl = data => {
  return request.post('/bigBagPrepostRecord/getLableUrl', data);
};
/**
 * 查询剩余包裹
 */
export const wmsSelectPackage = (data) => {
  return request({
    method: 'post',
    data: data,
    url: '/wms/selectPackage',
    baseURL
  });
};
/**
 * 移除装袋
 */
export const wmsDeleteBag = (data) => {
  return request({
    method: 'post',
    data: data,
    url: 'wms/deleteBag',
    baseURL
  });
};
/**
 * 查询包裹信息
 */
export const getByWayBillNumber = (data) => {
  return request({
    method: 'post',
    data: data,
    url: '/wms/getByWayBillNumber',
    baseURL
  });
};
// 移出出袋
export const removeTheBag = (data) => {
  return request({
    method: 'POST',
    url: '/wms/transitBag/delWaybillNumber',
    data,
    baseURL: baseURL2
  });
};
// 移出出袋
export const queryTransitBagNumber = (data) => {
  return request({
    method: 'POST',
    url: '/wms/transitBag/queryTransitBagNumber',
    data,
    baseURL: baseURL2
  });
};
// 装袋列表根据袋号查询袋内包裹信息
export const apiSelectByBagNumber = (data) => {
  return request({
    method: 'POST',
    url: '/bagging/selectByBagNumber',
    data
  });
};
// 大包列表根据袋号查询袋内包裹信息
export const apiBigBagSelectByBagNumber = (data) => {
  return request({
    method: 'POST',
    url: '/wms/bigBag/selectByNumber',
    data
  });
};
// 手动申报
export const manualDeclare = (data) => {
  return request({
    method: 'POST',
    url: '/wms/manualDeclare',
    data,
    baseURL: baseURL2
  });
};
// 查询申报数量
export const getBatchCountMin = () => {
  return request({
    method: 'POST',
    url: '/providerSystem/getBatchCountMin'
  });
};
// 入库称重分页
export const storageRecord = (data) => {
  return request({
    method: 'POST',
    data,
    url: '/storageRecord/queryWaybillNumber'
  });
};

// 确定入库操作
export const saveStorageRecordNo = (data) => {
  return request({
    method: 'POST',
    data,
    url: '/wms/storageRecordNo/saveStorageRecordNo',
    baseURL: baseURL2
  });
};
// 获取快手地址
export const saveStorageRecordNoGetLabelUrl = (data) => {
  return request({
    method: 'POST',
    data,
    url: '/wms/storageRecordNo/getLabelUrl',
    baseURL: baseURL2
  });
};
// 快手订单修改长宽高
// 获取快手地址
export const apiEditPackageVolume = (data) => {
  return request({
    method: 'POST',
    data,
    url: '/wms/storageRecordNo/editPackageVolume',
    baseURL: baseURL2
  });
};
// 校正转运重量接口
export const updateReviseWeight = (data) => {
  return request({
    method: 'POST',
    data,
    url: '/wms/storageRecordNo/updateReviseWeight',
    baseURL: baseURL2
  });
};
// 分页查询校正重量日志
export const waybillOperationLogPage = (data) => {
  return request({
    method: 'POST',
    data,
    url: '/storageRecord/waybillOperationLogPage'
  });
};
// 称重记录导出
export const apiWaybillOperationLogExportAll = (data) => {
  return request({
    method: 'POST',
    data,
    url: '/storageRecord/waybillOperationLogExportAll'
  });
};
// 分页查询重量品牌
export const getAllweightBrand = () => {
  return request({
    method: 'get',
    url: '/weightBrand/getAll'
  });
};
/**
 * 校验运单是否能称重入库
 */
export const checkWaybillStorage = (data) => {
  return request({
    method: 'post',
    data: data,
    url: '/wms/storageRecordNo/checkWaybillStorage',
    baseURL
  });
};
/**
 * 校验运单是否能校正入库
 */
export const checkGetWaybillInfo = (data) => {
  return request({
    method: 'post',
    data: data,
    url: '/storageRecord/getWaybillInfo'
  });
};
// 扫描装袋、装袋列表打印清单
export const printBagNumber = (data) => {
  return request({
    method: 'post',
    data: data,
    url: '/transitBagNumber/printBagNumber'
  });
};
/**
 * 总单打印清单
 */
export const printBagNumberList = data => {
  return request.post('/wms/bigBag/printBagNumber', data);
};
// 生成袋号
export const generateTransitBagNumber = () => {
  return request({
    method: 'POST',
    url: '/wms/transitBag/generateTransitBagNumber',
    baseURL: baseURL2
  });
};
//  查询大包分页接口
export const pageTransitBag = data => {
  return request.post('/tranSitBagNumber/pageTransitBag', data);
};
// 总单配载扫描单号添加
export const apiAddBag = data => {
  return request({
    method: 'POST',
    data,
    url: '/wms/bagManifest/addBag',
    baseURL: baseURL2
  });
};
// 总单配载->大包号删除
export const apiRemoveBag = data => {
  return request({
    method: 'POST',
    data,
    url: '/wms/bagManifest/removeBag',
    baseURL: baseURL2
  });
};
// 配载保存
export const apiComplete = data => {
  return request({
    method: 'POST',
    url: '/wms/bagManifest/complete/' + data,
    baseURL: baseURL2
  });
};

export const getSiteGridInfo = (data) => {
  return request.post('/wms/sorterGrid/getSiteGridInfo', data);
};
export const getGridSiteInfo = (data) => {
  return request.post('/wms/sorterGrid/getGridSiteInfo', data);
};
// 包牌打印分页
export const getPrintBagNumber = (data) => {
  return request.post('/wms/sorterGrid/getPrintBagNumber', data);
};
// 包牌打印
export const printBagNumberApi = data => {
  return request({
    method: 'POST',
    data,
    url: '/wms/transitBag/printBagNumber',
    baseURL: baseURL2
  });
};
// 装车文件分打印页查询
export const loadingDocuments = (data) => {
  return request.post('/printing/page/loadingDocuments', data);
};
// 装车文件打印功能
export const printLoadingDocuments = (data) => {
  return request.post('/printing/loadingDocuments', data);
};
