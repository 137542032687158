var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "warp" },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "auto" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-descriptions",
                { attrs: { colon: false, column: 3, direction: "vertical" } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.CreatePeople") } },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.orderwaybillclaim.claimsCreateUserName || "--"
                            )
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: { label: _vm.$t("ClaimsManagement.ClaimStatus") },
                    },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.stataList[
                                _vm.orderwaybillclaim.claimsStatus
                              ] || "--"
                            )
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("Resource.Reviewer") } },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.orderwaybillclaim.auditingUserName || "--"
                            )
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: { label: _vm.$t("receivablePayable.ReviewTime") },
                    },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v(
                            _vm._s(_vm.orderwaybillclaim.auditingTime || "--")
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: { label: _vm.$t("receivablePayable.PaymentTime") },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.orderwaybillclaim.trancheTime || "--")
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("ClaimsManagement.Drawee") } },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.orderwaybillclaim.trancheUserName || "--")
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: _vm.$t("receivablePayable.PaymentSlipNumber"),
                      },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.orderwaybillclaim.trancheNumber || "--")
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }