var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { height: "calc(100vh - 66px)", overflow: "hidden auto" },
    },
    [
      _c(
        "el-form",
        {
          ref: "formDataEdit",
          attrs: {
            model: _vm.formData,
            "label-position": "top",
            size: "small",
          },
        },
        [
          _c(
            "el-card",
            {
              staticClass: "box-card",
              staticStyle: { "margin-bottom": "10px" },
            },
            [
              _c(
                "el-descriptions",
                { attrs: { colon: false, direction: "horizontal" } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("basicData.siteName") + "：" } },
                    [_c("span", [_vm._v(_vm._s(_vm.detailData.name))])]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: _vm.$t("basicData.PayablePrincipal") + "：",
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.detailData.providerBody))])]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label:
                          _vm.$t("receivablePayable.TotalAmountToBePaid") +
                          "：",
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.detailData.payableUnsettledAmount)),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label:
                          _vm.$t("receivablePayable.TotalAmountPaid") + "：",
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.detailData.payedAmount))])]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label:
                          _vm.$t("receivablePayable.TotalAmountOutstanding") +
                          "：",
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.detailData.unpavedAmount))])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { label: _vm.$t("basicData.TradingHours") + "：" },
            },
            [
              _c("el-date-picker", {
                staticStyle: { width: "500px" },
                attrs: {
                  type: "datetimerange",
                  size: "small",
                  "picker-options": _vm.pickerOptions,
                  format: _vm.$i18n.locale == "zh" ? _vm.zhDate : _vm.ptDate,
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "default-time": ["00:00:00", "23:59:59"],
                  placeholder: _vm.$t("basicData.SelectADatetime"),
                },
                on: {
                  change: function ($event) {
                    return _vm.getTableDetail()
                  },
                },
                model: {
                  value: _vm.queryDate,
                  callback: function ($$v) {
                    _vm.queryDate = $$v
                  },
                  expression: "queryDate",
                },
              }),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.exportAccountInformation },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("basicData.ExportAccountRollover")) + " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pending" },
        [
          _c(
            "el-table",
            {
              key: _vm.tableKey,
              ref: "elTable",
              attrs: {
                data: _vm.tableData,
                border: "",
                "row-key": "idCopy",
                "tree-props": { children: "siteMeets", hasChildren: true },
              },
              on: { "header-dragend": _vm.headerWidthChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "acceptTime",
                  label: _vm.$t("basicData.TradingHours"),
                  align: "left",
                  width: "240",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.acceptTime))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "acceptOperateTime",
                  label: _vm.$t("receivablePayable.TimeOfPayment"),
                  align: "left",
                  width: "160",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "payableNumber",
                  label: _vm.$t("basicData.PaymentSlipNumber"),
                  align: "left",
                  width: "240",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.isParent
                          ? _c(
                              "span",
                              {
                                staticClass: "batchNumber",
                                on: {
                                  click: function ($event) {
                                    return _vm.showDetalhes(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.payableNumber))]
                            )
                          : _c("span", [
                              _vm._v(_vm._s(scope.row.payableNumber)),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "actualFeeBrl",
                  label: _vm.$t("basicData.PaymentAmount"),
                  align: "left",
                  width: "240",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.actualFeeBrl))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "billFee",
                  label: _vm.$t("basicData.TicketAmount"),
                  align: "left",
                  width: "240",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.billFee))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "accountAlias",
                  label: _vm.$t("basicData.PaymentAccount"),
                  align: "left",
                  width: "240",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.accountAlias))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "payableMethod",
                  label: _vm.$t("receivablePayable.ReceiptStatus"),
                  align: "center",
                  width: "200",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          scope.row.payableMethod === "cash"
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$t("basicData.cash"))),
                              ])
                            : _vm._e(),
                          scope.row.payableMethod === "transfer"
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("basicData.transferable"))
                                ),
                              ])
                            : _vm._e(),
                          scope.row.payableMethod === "network"
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$t("basicData.online"))),
                              ])
                            : _vm._e(),
                          scope.row.payableMethod === "sterilisation"
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$t("basicData.Elimination"))),
                              ])
                            : _vm._e(),
                          scope.row.payableMethod === "other"
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$t("basicData.other"))),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "attachmentUrl",
                  label: _vm.$t("basicData.annex"),
                  align: "center",
                  width: "200",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            scope.row.attachmentUrl
                              ? _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type: "success",
                                      href: scope.row.attachmentUrl,
                                      target: "_blank",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("basicData.View")))]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "remark",
                  label: _vm.$t("receivablePayable.remark"),
                  align: "left",
                  width: "200",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.isParent
                          ? _c("div", {
                              staticClass: "remarkStyle",
                              domProps: { innerHTML: _vm._s(scope.row.remark) },
                            })
                          : _c("div", {
                              staticClass: "remarkStyle",
                              domProps: { innerHTML: _vm._s(scope.row.remark) },
                            }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createUser",
                  label: _vm.$t("basicData.CreatePeople"),
                  align: "center",
                  width: "200",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.createUserName))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: _vm.$t("basicData.CreationTime"),
                  align: "center",
                  width: "200",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row.createTime))])]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                staticStyle: { margin: "10px 0" },
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  "current-page": _vm.queryForm.pageNumber,
                  "page-sizes": [10, 20, 50, 100],
                  "page-size": _vm.queryForm.pageSize,
                  total: _vm.total,
                  background: "",
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageNumber", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageNumber", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageSize", $event)
                  },
                  "current-change": _vm.getCurrentPage,
                  "size-change": _vm.getSizePage,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.$t("basicData.PaymentDetails"),
            "append-to-body": true,
            visible: _vm.showDetailList,
            direction: "ltr",
            size: "90%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDetailList = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("batch-number-detail", {
                key: _vm.detailKeyList,
                attrs: { "batch-number-data": _vm.batchNumberData },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "imgs" },
        [
          _vm.imageViewerObj.showViewer
            ? _c("el-image-viewer", {
                attrs: {
                  "on-close": _vm.closeViewer,
                  "url-list": _vm.imageViewerObj.imgArr,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }