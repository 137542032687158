<script >
import { defineProps } from '@vue/composition-api';
import { useQueryClient } from '@tanstack/vue-query';
import { default as DropdownTable } from '@/components/dropdownTable';
import { default as Alert } from '@/components/alert';
import { default as FormEditEdition } from './form/formEditEdition';
import { useModal } from '@/composable/useModal';
import { toast } from '@/utils/programaticToast';
import { getToastContentComponent } from '@/utils/getToastContentComponent';
import getMessageError from '@/utils/getMessageError';
import { editionService } from '@/api/reverse/edition/edition';
const __sfc_main = {};
__sfc_main.props = {
  row: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;

  /* edit driver */
  const {
    isOpen: isOpenModalEdit,
    handleVisibility: handleVisibilityModalEdit
  } = useModal();

  /* delete driver */
  const {
    isOpen: isOpenModalDelete,
    handleVisibility: handleVisibilityModalDelete
  } = useModal();
  const queryClient = useQueryClient();
  async function handleDelete() {
    try {
      await editionService.delete(props.row.codGroup);
      queryClient.invalidateQueries({
        queryKey: ['editions']
      });
      handleVisibilityModalDelete();
      toast.open({
        variant: 'success',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Deletado com sucesso',
          describe: 'Edição deletada com sucesso em nosso sistema',
          typeIcon: 'success'
        }),
        closable: true,
        duration: 4000
      });
    } catch (error) {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Ocorreu um erro',
          describe: getMessageError(error),
          typeIcon: 'error'
        }),
        closable: true,
        duration: 4000
      });
    }
  }
  return {
    isOpenModalEdit,
    handleVisibilityModalEdit,
    isOpenModalDelete,
    handleVisibilityModalDelete,
    handleDelete
  };
};
__sfc_main.components = Object.assign({
  DropdownTable,
  FormEditEdition,
  Alert
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <DropdownTable>
      <o-dropdown-item aria-role="listitem" @click="handleVisibilityModalEdit">
        {{ $t('editingRegistration.edit') }}
      </o-dropdown-item>

      <o-dropdown-item aria-role="listitem" @click="handleVisibilityModalDelete">
        {{ $t('editingRegistration.delete') }}
      </o-dropdown-item>
    </DropdownTable>

    <o-modal :active="isOpenModalEdit" width="560" @close="handleVisibilityModalEdit">
      <div>
        <h2 class="header-2">{{ $t('editingRegistration.creatingEditionTitle') }}</h2>
        <p class="paragraph">{{ $t('editingRegistration.creatingEditionParagraph') }}</p>
      </div>

      <FormEditEdition :edition="row" :close-modal="handleVisibilityModalEdit" />
    </o-modal>

    <Alert :active="isOpenModalDelete" @handleClose="handleVisibilityModalDelete">
      <template #title> {{ $t('editingRegistration.deleteTitle') }} </template>
      <template #message> {{ $t('editingRegistration.deleteParagraph') }} </template>

      <template #buttons>
        <o-button variant="outline" size="md" @click="handleVisibilityModalDelete"> {{ $t('editingRegistration.cancel') }} </o-button>
        <o-button variant="primary" size="md" @click="handleDelete"> {{ $t('editingRegistration.yesDelete') }} </o-button>
      </template>
    </Alert>
  </div>
</template>
