var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "wrapper-form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.handleNext($event)
        },
      },
    },
    [
      _c(
        "o-field",
        {
          staticClass: "col-span-2",
          attrs: { label: "Nome", "label-for": "name" },
        },
        [
          _c("o-input", {
            attrs: { id: "name", placeholder: "Nome ou razão social" },
            model: {
              value: _vm.formData.name,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "name", $$v)
              },
              expression: "formData.name",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          staticClass: "col-span-2",
          attrs: { label: "CNPJ", "label-for": "documentOwnerTrailer" },
        },
        [
          _c("o-input", {
            directives: [
              {
                name: "mask",
                rawName: "v-mask",
                value: "##.###.###/####-##",
                expression: "'##.###.###/####-##'",
              },
            ],
            attrs: {
              id: "documentOwnerTrailer",
              placeholder: "000.000.000-00",
            },
            model: {
              value: _vm.formData.document,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "document", $$v)
              },
              expression: "formData.document",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          staticClass: "col-span-2",
          attrs: { label: "IE", "label-for": "ieTrailer" },
        },
        [
          _c("o-input", {
            attrs: { id: "ieTrailer", placeholder: "Digite a IE do dono" },
            model: {
              value: _vm.formData.ie,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "ie", $$v)
              },
              expression: "formData.ie",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          staticClass: "col-span-2",
          attrs: { label: "RNTRC", "label-for": "rntrcTrailer" },
        },
        [
          _c("o-input", {
            attrs: { id: "rntrcTrailer", placeholder: "Digite o RNTRC" },
            model: {
              value: _vm.formData.rntrc,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "rntrc", $$v)
              },
              expression: "formData.rntrc",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          staticClass: "col-span-2",
          attrs: { label: "Placa", "label-for": "licensePlateTrailer" },
        },
        [
          _c("o-input", {
            directives: [
              {
                name: "mask",
                rawName: "v-mask",
                value: "AAA-XXXX",
                expression: "'AAA-XXXX'",
              },
            ],
            attrs: {
              id: "licensePlateTrailer",
              placeholder: "Digite a placa do veículo",
            },
            model: {
              value: _vm.formData.licensePlate,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "licensePlate", $$v)
              },
              expression: "formData.licensePlate",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: { label: "Capacidade (KG)", "label-for": "capacityKgTrailer" },
        },
        [
          _c("o-input", {
            attrs: { id: "capacityKgTrailer", placeholder: "100" },
            model: {
              value: _vm.formData.capacityKg,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "capacityKg", $$v)
              },
              expression: "formData.capacityKg",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        { attrs: { label: "Tara", "label-for": "taraTrailer" } },
        [
          _c("o-input", {
            attrs: { id: "taraTrailer", placeholder: "03" },
            model: {
              value: _vm.formData.tara,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "tara", $$v)
              },
              expression: "formData.tara",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          staticClass: "col-span-2",
          attrs: { label: "RENAVAM", "label-for": "renavamTrailer" },
        },
        [
          _c("o-input", {
            attrs: { id: "renavamTrailer", placeholder: "Digite o RENAVAM" },
            model: {
              value: _vm.formData.renavam,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "renavam", $$v)
              },
              expression: "formData.renavam",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: "Tipo de Carroceria",
            "label-for": "bodyTypeTrailer",
          },
        },
        [
          _c(
            "o-select",
            {
              attrs: { id: "bodyTypeTrailer", placeholder: "Tipo de corpo" },
              model: {
                value: _vm.formData.bodyType,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "bodyType", $$v)
                },
                expression: "formData.bodyType",
              },
            },
            _vm._l(_vm.optionsBodyType, function (bodyType) {
              return _c(
                "option",
                { key: bodyType.value, domProps: { value: bodyType } },
                [_vm._v(" " + _vm._s(bodyType) + " ")]
              )
            }),
            0
          ),
        ],
        1
      ),
      _c(
        "o-field",
        { attrs: { label: "UF", "label-for": "ufTrailer" } },
        [
          _c(
            "o-select",
            {
              attrs: { id: "ufTrailer", placeholder: "SP" },
              model: {
                value: _vm.formData.uf,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "uf", $$v)
                },
                expression: "formData.uf",
              },
            },
            _vm._l(_vm.listUF, function (UF) {
              return _c("option", { key: UF, domProps: { value: UF } }, [
                _vm._v(" " + _vm._s(UF) + " "),
              ])
            }),
            0
          ),
        ],
        1
      ),
      _c(
        "FormActions",
        [
          _c("FormActionsPrevious", {
            attrs: { "is-first-step": _vm.isFirstStep },
            on: { handlePrevious: _vm.handlePrevious },
          }),
          _c("FormActionsNext", { attrs: { "is-last-step": _vm.isLastStep } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }