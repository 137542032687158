var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { height: "50px" } },
        [
          _c(
            "el-button",
            {
              staticStyle: { float: "right" },
              attrs: { icon: "el-icon-plus", plain: "" },
              on: {
                click: function ($event) {
                  _vm.clickSenderDialog(
                    _vm.$t("customerManagements.createSender"),
                    null
                  )
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("GlobalSetObj.create")) + " ")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "addressClass" }, [
        _vm.customerAddressList.length > 0
          ? _c(
              "div",
              _vm._l(_vm.customerAddressList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "addressItem",
                    on: {
                      mouseleave: function ($event) {
                        return _vm.hideDeleteButton(index)
                      },
                      mouseover: function ($event) {
                        return _vm.showDeleteButton(index)
                      },
                    },
                  },
                  [
                    _c("div", [
                      _c("span", [
                        _vm._v(
                          _vm._s(item.senderName || "--") +
                            " / " +
                            _vm._s(item.senderMobile || "--") +
                            " / " +
                            _vm._s("--") +
                            " / " +
                            _vm._s(item.senderZipcode || "--")
                        ),
                      ]),
                    ]),
                    _c("div", [
                      _c("span", [
                        _vm._v(
                          _vm._s(item.senderStreet || "--") +
                            " / " +
                            _vm._s(item.senderHouseNumber || "--") +
                            " / " +
                            _vm._s(item.senderCity || "--") +
                            " / " +
                            _vm._s(item.senderState || "--") +
                            " / " +
                            _vm._s(item.senderComplement || "--")
                        ),
                      ]),
                    ]),
                    _c("div", [
                      _c("span", [_vm._v(_vm._s(item.senderAddress || "--"))]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "btnOperate",
                        class: "btnOperate" + index,
                      },
                      [
                        [
                          _c(
                            "el-popconfirm",
                            {
                              attrs: {
                                title: _vm.$t("GlobalSetObj.AreYouSureDelete"),
                              },
                              on: {
                                confirm: function ($event) {
                                  return _vm.delAddress(item)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-delete",
                                staticStyle: { color: "#f56c6c" },
                                attrs: { slot: "reference" },
                                slot: "reference",
                              }),
                            ]
                          ),
                        ],
                        _c("i", {
                          staticClass: "el-icon-edit-outline",
                          staticStyle: { color: "#409eff" },
                          on: {
                            click: function ($event) {
                              _vm.clickSenderDialog(
                                _vm.$t("customerManagements.editSender"),
                                item
                              )
                            },
                          },
                        }),
                      ],
                      2
                    ),
                  ]
                )
              }),
              0
            )
          : _c(
              "div",
              { staticClass: "nodataImage" },
              [_c("svg-icon", { attrs: { "icon-class": "noData" } })],
              1
            ),
      ]),
      _c("el-pagination", {
        staticStyle: { margin: "10px 0", float: "right" },
        attrs: {
          "current-page": _vm.queryForm.pageNumber,
          "page-size": _vm.queryForm.pageSize,
          "page-sizes": [2, 10, 20],
          total: _vm.queryForm.total,
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "show-close": true,
            title: _vm.titleDialog,
            visible: _vm.senderDialog,
            top: "10vh",
            width: "900px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.senderDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "senderRuleForm",
                  staticClass: "demo-senderRuleForm",
                  attrs: {
                    model: _vm.senderRuleForm,
                    inline: "",
                    "label-position": "top",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 50 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t("placeOrder.name"),
                                rules: _vm.checkStr50,
                                prop: "senderName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t("placeOrder.pleaseInput"),
                                  clearable: "",
                                  maxlength: "52",
                                },
                                model: {
                                  value: _vm.senderRuleForm.senderName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.senderRuleForm,
                                      "senderName",
                                      $$v
                                    )
                                  },
                                  expression: "senderRuleForm.senderName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t("GlobalSetObj.MobilePhone"),
                                rules: _vm.isPhone,
                                prop: "senderMobile",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t("placeOrder.pleaseInput"),
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.senderRuleForm.senderMobile,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.senderRuleForm,
                                      "senderMobile",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "senderRuleForm.senderMobile",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t("placeOrder.Tax"),
                                rules: _vm.isTax12,
                                prop: "senderTax",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "newOrder.PleaseEnter12Or14DigitTaxNumber"
                                  ),
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.senderRuleForm.senderTax,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.senderRuleForm,
                                      "senderTax",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "senderRuleForm.senderTax",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 50 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t("placeOrder.zipcode"),
                                rules: _vm.isZipCode,
                                prop: "senderZipcode",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t("placeOrder.pleaseInput"),
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.senderRuleForm.senderZipcode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.senderRuleForm,
                                      "senderZipcode",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "senderRuleForm.senderZipcode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t("placeOrder.State"),
                                rules: {
                                  required: true,
                                  message: _vm.$t("placeOrder.pleaseInput"),
                                  trigger: ["blur", "change"],
                                },
                                prop: "senderState",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "placeOrder.pleaseChoose"
                                    ),
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.senderRuleForm.senderState,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.senderRuleForm,
                                        "senderState",
                                        $$v
                                      )
                                    },
                                    expression: "senderRuleForm.senderState",
                                  },
                                },
                                _vm._l(_vm.senderStateList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.label,
                                      value: item.label,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t("placeOrder.city"),
                                rules: _vm.cityComplement,
                                prop: "senderCity",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t("placeOrder.pleaseInput"),
                                  clearable: "",
                                  maxlength: "32",
                                },
                                model: {
                                  value: _vm.senderRuleForm.senderCity,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.senderRuleForm,
                                      "senderCity",
                                      $$v
                                    )
                                  },
                                  expression: "senderRuleForm.senderCity",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 50 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t("placeOrder.Area"),
                                rules: _vm.addressComplement,
                                prop: "senderArea",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t("placeOrder.pleaseInput"),
                                  clearable: "",
                                  maxlength: "32",
                                },
                                model: {
                                  value: _vm.senderRuleForm.senderArea,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.senderRuleForm,
                                      "senderArea",
                                      $$v
                                    )
                                  },
                                  expression: "senderRuleForm.senderArea",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t("newOrder.Street"),
                                prop: "senderStreet",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "newOrder.ConfirmStreetNumber"
                                  ),
                                  clearable: "",
                                  maxlength: "50",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.senderRuleForm.senderStreet,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.senderRuleForm,
                                      "senderStreet",
                                      $$v
                                    )
                                  },
                                  expression: "senderRuleForm.senderStreet",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t("placeOrder.HouseNumber"),
                                rules: _vm.checkStr5,
                                prop: "senderHouseNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t("placeOrder.pleaseInput"),
                                  clearable: "",
                                  maxlength: "6",
                                },
                                model: {
                                  value: _vm.senderRuleForm.senderHouseNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.senderRuleForm,
                                      "senderHouseNumber",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "senderRuleForm.senderHouseNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t(
                                  "GlobalSetObj.NationalRegistrationNumber"
                                ),
                                rules: _vm.IeNumber,
                                prop: "senderIeNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t("placeOrder.pleaseInput"),
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.senderRuleForm.senderIeNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.senderRuleForm,
                                      "senderIeNumber",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "senderRuleForm.senderIeNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t("placeOrder.addressComplement"),
                                rules: _vm.addressComplement,
                                prop: "senderComplement",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t("placeOrder.pleaseInput"),
                                  clearable: "",
                                  maxlength: "32",
                                },
                                model: {
                                  value: _vm.senderRuleForm.senderComplement,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.senderRuleForm,
                                      "senderComplement",
                                      $$v
                                    )
                                  },
                                  expression: "senderRuleForm.senderComplement",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t("placeOrder.email"),
                                rules: _vm.checkMail,
                                prop: "senderMail",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t("placeOrder.pleaseInput"),
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.senderRuleForm.senderMail,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.senderRuleForm,
                                      "senderMail",
                                      $$v
                                    )
                                  },
                                  expression: "senderRuleForm.senderMail",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 50 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t("placeOrder.detailedAddress"),
                                rules: [
                                  {
                                    required: true,
                                    message: _vm.$t("placeOrder.pleaseInput"),
                                    trigger: ["blur", "change"],
                                  },
                                  {
                                    min: 2,
                                    max: 200,
                                    message: _vm.$t(
                                      "newOrder.TdetailedAddressCharacter"
                                    ),
                                    trigger: "blur",
                                  },
                                ],
                                prop: "senderAddress",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "newOrder.StreetHouseNumber"
                                  ),
                                  maxlength: "200",
                                  "show-word-limit": "",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.senderRuleForm.senderAddress,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.senderRuleForm,
                                      "senderAddress",
                                      $$v
                                    )
                                  },
                                  expression: "senderRuleForm.senderAddress",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.senderDialog = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.senderConfirm },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.determine")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }