import {
  required as requiredVuelidate,
  helpers,
  minLength as minLengthVuelidate,
  maxLength as maxLengthVuelidate,
  numeric as numericVuelidate,
  email as emailVuelidate,
  between as betweenVuelidate,
  alpha as alphaVuelidate,
  alphaNum as alphaNumVuelidate
} from '@vuelidate/validators';
import i18n from '@/lang';

export const required = helpers.withMessage(i18n.t('errorMessage.mandatory'), requiredVuelidate);

export const numeric = helpers.withMessage(i18n.t('errorMessage.acceptsNumeric'), numericVuelidate);

export const email = helpers.withMessage(i18n.t('errorMessage.invalidEmail'), emailVuelidate);

export const alpha = helpers.withMessage(i18n.t('errorMessage.acceptsOnlyLetters'), alphaVuelidate);

export const alphaNum = helpers.withMessage(i18n.t('errorMessage.acceptsLettersAndNumbers'), alphaNumVuelidate);

export function minLength(numberMin) { return helpers.withMessage(`${i18n.t('errorMessage.minimumCharacters')} ${numberMin} ${i18n.t('errorMessage.characters')}`, minLengthVuelidate(numberMin)); }

export function maxLength(numberMax) { return helpers.withMessage(`${i18n.t('errorMessage.minimumCharacters')} ${numberMax} ${i18n.t('errorMessage.characters')}`, maxLengthVuelidate(numberMax)); }

export function between(initialValue, finalValue) { return helpers.withMessage(`${i18n.t('errorMessage.between')} ${initialValue} ${i18n.t('errorMessage.to')} ${finalValue}`, betweenVuelidate(initialValue, finalValue)); }
