var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Box" },
    [
      _c(
        "el-form",
        { staticClass: "selectClass" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { width: "auto" }, attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("collectionCenter.createTime") } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          clearable: false,
                          type: "datetimerange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "default-time": ["00:00:00", "23:59:59"],
                          "range-separator": _vm.$t("collectionCenter.to"),
                          "start-placeholder": _vm.$t(
                            "collectionCenter.StartTime"
                          ),
                          "end-placeholder": _vm.$t("collectionCenter.EndTime"),
                        },
                        on: { change: _vm.dateChange },
                        model: {
                          value: _vm.timeValue,
                          callback: function ($$v) {
                            _vm.timeValue = $$v
                          },
                          expression: "timeValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("collectionCenter.region") + "：",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            "remote-method": _vm.remoteMethod,
                            remote: "",
                            "reserve-keyword": "",
                          },
                          model: {
                            value: _vm.queryForm.params.bigArea,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm.params, "bigArea", $$v)
                            },
                            expression: "queryForm.params.bigArea",
                          },
                        },
                        _vm._l(_vm.bigAreaList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value:
                        "btn:collection-center:collection-time-configuration:find",
                      expression:
                        "'btn:collection-center:collection-time-configuration:find'",
                      arg: "remove",
                    },
                  ],
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-search",
                  },
                  on: { click: _vm.searchClick },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.search")))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value:
                        "btn:collection-center:collection-time-configuration:reset",
                      expression:
                        "'btn:collection-center:collection-time-configuration:reset'",
                      arg: "remove",
                    },
                  ],
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetClick },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value:
                        "btn:collection-center:collection-time-configuration:add",
                      expression:
                        "'btn:collection-center:collection-time-configuration:add'",
                      arg: "remove",
                    },
                  ],
                  attrs: {
                    type: "primary",
                    icon: "el-icon-plus",
                    size: "mini",
                  },
                  on: { click: _vm.addClick },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.add")))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value:
                        "btn:collection-center:collection-time-configuration:exportAll",
                      expression:
                        "'btn:collection-center:collection-time-configuration:exportAll'",
                      arg: "remove",
                    },
                  ],
                  class: _vm.isClickAll ? "onlyClickOnce10" : "",
                  attrs: { icon: "el-icon-upload", size: "mini" },
                  on: { click: _vm.downloadAll },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-table",
        {
          ref: "tableList",
          attrs: { "max-height": 600, border: "", data: _vm.tableData },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.ind"),
              type: "index",
              width: "60",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          (_vm.queryForm.pageNumber - 1) *
                            _vm.queryForm.pageSize +
                            scope.$index +
                            1
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "nameDesc",
              label: _vm.$t("collectionCenter.prescriptionName"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "bigArea",
              label: _vm.$t("collectionCenter.region"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "standard",
              label: _vm.$t("collectionCenter.ageingStandardValue"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "createTime",
              label: _vm.$t("CenterForSorting.CreationTime"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "createUserName",
              label: _vm.$t("CenterForSorting.creator"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "updateTime",
              label: _vm.$t("CenterForSorting.ModificationTime"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "updateUserName",
              label: _vm.$t("CenterForSorting.ModifiedBy"),
            },
          }),
          _vm.edit || _vm.del
            ? _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  align: "center",
                  label: _vm.$t("collectionCenter.operation"),
                  width: "120",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "widthClass" }, [
                            _vm.edit
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      "margin-right": "10px",
                                      color: "#26b3f1",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editClick(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("collectionCenter.modify"))
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.del
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-popconfirm",
                                      {
                                        attrs: {
                                          title: _vm.$t(
                                            "collectionCenter.deleteSure"
                                          ),
                                        },
                                        on: {
                                          confirm: function ($event) {
                                            return _vm.delRow(
                                              scope.row,
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              cursor: "pointer",
                                              color: "#26b3f1",
                                            },
                                            attrs: { slot: "reference" },
                                            slot: "reference",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "collectionCenter.Delete"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2712900892
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-pagination", {
            staticStyle: { margin: "10px 0" },
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              "current-page": _vm.queryForm.pageNumber,
              "page-sizes": [10, 20, 50, 100, 500, 1000],
              "page-size": _vm.queryForm.pageSize,
              total: _vm.total,
              background: "",
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "current-change": _vm.getDatePage,
              "size-change": _vm.getPageSize,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": true,
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "submitRef",
              staticClass: "selectClass",
              attrs: { model: _vm.submitForm, rules: _vm.submitRules },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.$t("collectionCenter.prescriptionName") +
                              "：",
                            prop: "name",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", filterable: "" },
                              model: {
                                value: _vm.submitForm.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.submitForm, "name", $$v)
                                },
                                expression: "submitForm.name",
                              },
                            },
                            _vm._l(_vm.nameList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.value, value: item.key },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.$t("collectionCenter.ageingStandardValue") +
                              "：",
                            prop: "standard",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.submitForm.standard,
                              callback: function ($$v) {
                                _vm.$set(_vm.submitForm, "standard", $$v)
                              },
                              expression: "submitForm.standard",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.region") + "：",
                            prop: "bigArea",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                filterable: "",
                                "remote-method": _vm.remoteMethod,
                                remote: "",
                                "reserve-keyword": "",
                              },
                              model: {
                                value: _vm.submitForm.bigArea,
                                callback: function ($$v) {
                                  _vm.$set(_vm.submitForm, "bigArea", $$v)
                                },
                                expression: "submitForm.bigArea",
                              },
                            },
                            _vm._l(_vm.bigAreaList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item, value: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.submit },
                },
                [_vm._v(" " + _vm._s(_vm.$t("userManage.confirm")) + " ")]
              ),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.closeDialog } },
                [_vm._v(" " + _vm._s(_vm.$t("userManage.cancel")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }