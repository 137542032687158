var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    {
      staticClass: "international",
      attrs: { trigger: "click" },
      on: { command: _vm.handleSetLanguage },
    },
    [
      _c("div", [
        _c(
          "svg",
          {
            staticClass: "icon",
            attrs: {
              t: "1642340950488",
              viewBox: "0 0 1024 1024",
              xmlns: "http://www.w3.org/2000/svg",
              "p-id": "1903",
              width: "26",
              height: "26",
            },
          },
          [
            _c("path", {
              staticClass: "selected",
              attrs: {
                d: "M864 64a96 96 0 0 1 96 96v704a96 96 0 0 1-96 96H160a96 96 0 0 1-96-96V160a96 96 0 0 1 96-96h704z m0 64H160a32 32 0 0 0-32 32v704a32 32 0 0 0 32 32h704a32 32 0 0 0 32-32V160a32 32 0 0 0-32-32z m-322.4 256c0-31.456 40.64-44.032 58.4-18.08l133.6 195.168V384a32 32 0 0 1 64 0v280.48c0 31.456-40.64 44.032-58.4 18.08l-133.6-195.168v177.088a32 32 0 1 1-64 0z",
                fill: "#409eff",
                "p-id": "1904",
                "data-spm-anchor-id": "a313x.7781069.0.i15",
              },
            }),
            _c("path", {
              attrs: {
                d: "M448 352a32 32 0 0 1 0 64H288v80h160a32 32 0 0 1 31.776 28.256L480 528a32 32 0 0 1-32 32H288v72.48h160a32 32 0 1 1 0 64H256a32 32 0 0 1-32-32V384a32 32 0 0 1 32-32z",
                fill: "#FAAC08",
                "p-id": "1905",
              },
            }),
          ]
        ),
      ]),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        _vm._l(_vm.languageObj, function (val, key) {
          return _c(
            "el-dropdown-item",
            {
              key: val.code,
              attrs: { disabled: _vm.language === key, command: key },
            },
            [_vm._v(" " + _vm._s(val.txt) + " ")]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }