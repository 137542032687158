<template>
  <div>
    <!-- 普通袋标 -->
    <div v-show="expressWaybillType === 0" class="print">
      <div class="print_left">
        <el-form
          label-width="80px"
          :label-position="$i18n.locale!='zh'?'top':'right'"
          :model="ordinaryObj.formObj"
          :disabled="isDisabled"
        >
          <el-form-item :label="$t('operationCenter.length') + '(cm)'">
            <el-input-number v-model="ordinaryObj.formObj.length" :min="0" :max="5000" :placeholder="$t('operationCenter.PleaseEnterTheContent')" />
          </el-form-item>
          <el-form-item :label="$t('operationCenter.width') + '(cm)'">
            <el-input-number v-model="ordinaryObj.formObj.width" :min="0" :max="5000" :placeholder="$t('operationCenter.PleaseEnterTheContent')" />
          </el-form-item>
          <el-form-item :label="$t('operationCenter.height') + '(cm)'">
            <el-input-number v-model="ordinaryObj.formObj.height" :min="0" :max="5000" :placeholder="$t('operationCenter.PleaseEnterTheContent')" />
          </el-form-item>
        </el-form></div>
      <div class="print_right">
        <div class="prin_pdf">

          <!-- 打印样式只能写行内样式 -->
          <ul id="bagLabels" ref="bagLabelsRef" style="border: 2px solid #000;width: 577pt;padding: 0; height: 384pt; ">
            <li style="list-style: none;border-bottom: 2px solid #000;display: flex;align-items: center;justify-content: space-between;">
              <p style="height: 40pt;line-height: 40pt;width: 230px;border-right: 2px solid #000;text-align: right;margin: 0;font-weight: bold;font-size: 20px;font-family: Arial;color: #000;">{{ $t('operationCenter.bagNumber') }}:</p>
              <p style="height: 40pt;line-height: 40pt;flex: 1;text-align: center;margin: 0;font-weight: bold;font-size: 20px;font-family: Arial;color: #000;">{{ ordinaryPropsObj.bagNo }}</p>
            </li>
            <li style="list-style: none;border-bottom: 2px solid #000;display: flex;align-items: center;justify-content: space-between;">
              <p style="height: 42pt;line-height: 42pt;width: 230px;border-right: 2px solid #000;text-align: right;margin: 0;font-weight: bold;font-size: 20px;font-family: Arial;color: #000;">
                {{ $t('operationCenter.numberPieces') }}:</p>
              <p style="height: 42pt;line-height: 42pt;flex: 1;text-align: center;margin: 0;font-weight: bold;font-size: 20px;font-family: Arial;color: #000;">{{ ordinaryPropsObj.quantity }}</p>
            </li>
            <li style="list-style: none;border-bottom: 2px solid #000;display: flex;align-items: center;justify-content: space-between;">
              <p style="height: 48pt;line-height: 48pt;width: 230px;border-right: 2px solid #000;text-align: right;margin: 0;font-weight: bold;font-size: 20px;font-family: Arial;color: #000;">
                {{ $t('operationCenter.Weight') }}:</p>
              <p style="height: 48pt;line-height: 48pt;flex: 1;text-align: center;margin: 0;font-weight: bold;font-size: 20px;font-family: Arial;color: #000;">{{ ordinaryPropsObj.kg }}</p>
            </li>
            <li style="list-style: none;border-bottom: 2px solid #000;display: flex;align-items: center;justify-content: space-between;">
              <p style="height: 51pt;line-height: 51pt;width: 230px;border-right: 2px solid #000;text-align: right;margin: 0;font-weight: bold;font-size: 20px;font-family: Arial;color: #000;">
                {{ $t('operationCenter.country') }}:</p>
              <p style="height: 51pt;line-height: 51pt;flex: 1;text-align: center;margin: 0;font-weight: bold;font-size: 20px;font-family: Arial;color: #000;">{{ ordinaryPropsObj.country }}</p>
            </li>
            <!-- <li style="list-style: none;border-bottom: 2px solid #000;display: flex;align-items: center;justify-content: space-between;">
              <p style="height: 40pt;line-height: 40pt;width: 230px;border-right: 2px solid #000;text-align: right;margin: 0;font-weight: bold;font-size: 20px;font-family: Arial;color: #000;">渠道:</p>
              <p style="height: 40pt;line-height: 40pt;flex: 1;text-align: center;margin: 0;font-weight: bold;font-size: 20px;font-family: Arial;color: #000;">{{ ordinaryPropsObj.channel }}</p>
            </li> -->
            <li style="list-style: none;border-bottom: 2px solid #000;display: flex;align-items: center;justify-content: space-between;">
              <p style="height: 40pt;line-height: 40pt;width: 230px;border-right: 2px solid #000;text-align: right;margin: 0;font-weight: bold;font-size: 20px;font-family: Arial;color: #000;">
                {{ $t('operationCenter.nextHome') }}:</p>
              <p style="height: 40pt;line-height: 40pt;flex: 1;text-align: center;margin: 0;font-weight: bold;font-size: 20px;font-family: Arial;color: #000;">{{ ordinaryPropsObj.downstreamName }}</p>
            </li>
            <li style="list-style: none;border-bottom: 2px solid #000;display: flex;align-items: center;justify-content: space-between;">
              <p style="height: 40pt;line-height: 40pt;width: 230px;border-right: 2px solid #000;text-align: right;margin: 0;font-weight: bold;font-size: 20px;font-family: Arial;color: #000;">
                {{ $t('operationCenter.volume') }}:</p>
              <p style="height: 40pt;line-height: 40pt;flex: 1;text-align: center;margin: 0;font-weight: bold;font-size: 20px;font-family: Arial;color: #000;">{{ getSize }}</p>
            </li>
            <li style="list-style: none;border-bottom: 2px solid #000;display: flex;align-items: center;justify-content: space-between;">
              <p style="height: 30pt;line-height: 30pt;width: 230px;border-right: 2px solid #000;text-align: right;margin: 0;font-weight: bold;font-size: 20px;font-family: Arial;color: #000;">
                {{ $t('operationCenter.logotype') }}:</p>
              <p style="height: 30pt;line-height: 30pt;flex: 1;text-align: center;margin: 0;font-weight: bold;font-size: 20px;font-family: Arial;color: #000;">{{ ordinaryPropsObj.Sender }}</p>
            </li>
            <li class="code" style="list-style: none;display: flex;align-items: center;justify-content: center;height: 70pt;">
              <svg id="baglabcode" style="height: 63pt;" />
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 巴西袋标 -->
    <div v-show="expressWaybillType === 1" class="other">

      <ul id="other" ref="other" class="other_list">
        <li>
          <div class="left">
            <p class="left_title">{{ otherPropsObj.origemInfo }}</p>
          </div>
          <div class="right">
            <div class="imgs">
              <img :src="correlosImg" alt="logo">
            </div>
            <div class="identification imgs">
              <!-- 专线 -->
              <img v-if="otherPropsObj.serviceType === serviceTypeObj['PACKET EXPRESS'].code" :src="kdImg" alt="logo">
              <!-- 标准 -->
              <img v-else-if="otherPropsObj.serviceType === serviceTypeObj['PACKET STANDARD'].code" :src="bxImg" alt="logo">
              <!-- MINI -->
              <img v-else :src="miniImg" alt="logo">
            </div>
            <div class="txt">{{ otherPropsObj.serviceType }}</div>
          </div>
        </li>

        <li>
          <div class="left">
            <p class="left_title">
              <span>Nº Do Despacho</span>
              <span>(Dispatch No.)</span>
            </p>
            <p class="cont">{{ otherPropsObj.bigBagId }}</p>
          </div>
          <div class="right">
            <p>
              <span>Empresa Brasileira de Correios e Telégrafos</span>
              <span>Centro Internacional de Curitiba - SE / PR</span>
              <span>Rua Salgado Filho, 476, Jardim Amélia - Pinhais / PR</span>
              <span>CEP: 83.330-972</span>
              <span>CNPJ: 34.028.316/0031-29</span>
            </p>
            <p>CEI13</p>
          </div>
        </li>

        <li>
          <div class="left">
            <p class="left_title">
              <span>Nº Serial da Mala</span>
              <span>(Receptacle Serial Number)</span>
            </p>
            <p class="cont">{{ otherPropsObj.serialNumbe }}</p>
          </div>
          <div class="right">
            <p>Nº VÔO (Flight Number)</p>
            <p />
          </div>
        </li>

        <li>
          <div class="left">
            <p class="left_title">
              <span>Data do despacho (Date)</span>
            </p>
            <p class="cont">{{ otherPropsObj.date }}</p>
          </div>
          <div class="right">
            <div>
              <p>Aeroporto de Origem (Airport of departure)</p>
              <p>{{ otherPropsObj.startingStr }}</p>
            </div>
            <div>
              <p>Aeroporto de Destino(Airport of Offloading)</p>
              <p>{{ otherPropsObj.endStr }}</p>
            </div>
          </div>

        </li>
        <li>
          <div class="left">
            <div class="child">
              <p>
                <span>Quantidade de Itens(Quantity)</span>
              </p>
              <p class="cont">{{ otherPropsObj.quantity }}</p>
            </div>
            <div class="child">
              <p>
                <span>Peso Kg (Weight kg)</span>
              </p>
              <p class="cont">{{ otherPropsObj.weight }}</p>
            </div>
            <div class="child">
              <p>Correios Packet Standard</p>
            </div>
          </div>
          <div class="right">
            <svg id="barcode" />
          </div>
        </li>
      </ul>
    </div>

    <el-divider />
    <div class="buts">

      <!-- 普通打印 -->
      <!-- <el-button v-show="expressWaybillType === 0" type="primary" @click="setVolume">打印</el-button> -->
      <el-button v-show="expressWaybillType === 0" type="primary" @click="handlerConfirm(setVolume)">{{ $t('operationCenter.BagSealingPrinting') }}</el-button>
      <!-- <el-button v-show="expressWaybillType === 0" type="primary" >打印</el-button> -->
      <!-- 巴西打印 -->
      <el-button v-show="expressWaybillType === 1" type="primary" @click="setVolumeOther">{{ $t('operationCenter.BagSealingPrinting') }}</el-button>
      <!-- 浏览器打印 -->
      <el-button class="mr10" type="info" @click="handlePrint">{{ $t('operationCenter.print') }}</el-button>
      <el-button class="mr10" @click="handleClose(false)">{{ $t('operationCenter.Cancel') }}</el-button>
    </div>
  </div>
</template>

<script>
import JsBarcode from 'jsbarcode';
import print from 'print-js';

import bxImg from '@/assets/images/weight/bx-bz.png';
import kdImg from '@/assets/images/weight/bx-kd.png';
import miniImg from '@/assets/images/weight/bx-mini.png';
import correlosImg from '@/assets/images/weight/Correlos_log_90x60.png';
import {
  apiCreateExpressWaybill
} from '@/api/operationCenter';

// 巴西袋标状态
// const serviceTypeObj = {
//   'PACKET EXPRESS': {
//     code: 'PACKET EXPRESS',
//     name: this.$i18n.t('operationCenter.dedicatedLines') // '专线'
//   },
//   'PACKET STANDARD': {
//     code: 'PACKET STANDARD',
//     name: this.$i18n.t('operationCenter.standard') // '标准'
//   },
//   'PACKET MINI': {
//     code: 'PACKET MINI',
//     name: 'MINI'
//   }
// };

export default {

  props: {
    bagNo: {
      type: String,
      default: '123456'
    },
    index: {
      type: Number,
      default: 1
    },
    // 普通
    ordinaryPropsObj: {
      type: Object,
      default() {
        return {
          id: '',
          bagNo: '', // 袋号
          quantity: '', // 件数
          kg: '', // 重量
          country: '', // 国家
          channel: '', // 渠道/ 线路
          downstreamName: '', // 下家
          Sender: 'Anjun',
          height: 0, // 体积 高
          width: 0, // 体积 宽
          length: 0, // 体积 长
          expressWaybillOssLink: '', // oss 地址
          downstreamCode: ''
        };
      }
    },

    // 巴西
    otherPropsObj: {
      type: Object,
      default() {
        return {
          id: '',
          startingStr: '', // 起飞机场
          endStr: '', // 目的机场
          bagNo: '', // 袋号
          origemInfo: '', //  安骏标识
          bigBagId: '', // 袋号id
          date: '', // 日期
          quantity: '',
          weight: '', // 重量
          serviceType: '', // 类型
          serialNumbe: '',
          expressWaybillOssLink: '' // oss 地址
        };
      }
    },

    // 袋标类型
    expressWaybillType: {
      type: Number,
      required: true,
      validator(prop) {
        // 0-->普通  1--> 巴西袋标
        return (prop === 0) || (prop === 1);
      }
    }
  },

  data() {
    return {
      ordinaryObj: {
        formObj: {
          height: '',
          width: '',
          length: ''
        },

        id: '', // 袋号
        bagNo: '', // 袋号
        quantity: '', // 件数
        kg: '', // 重量
        country: '待定', // 国家
        channel: '', // 渠道/ 线路
        // size: '', // 尺寸
        Sender: 'Anjun',
        files: '' // 待上传的PDF
      },
      isDisabled: false,

      loading: false, // 下载PDF的加载动画
      ossURL: '', // 已经传到了 oss
      serviceTypeObj: {
        'PACKET EXPRESS': {
          code: 'PACKET EXPRESS',
          name: this.$i18n.t('operationCenter.dedicatedLines') // '专线'
        },
        'PACKET STANDARD': {
          code: 'PACKET STANDARD',
          name: this.$i18n.t('operationCenter.standard') // '标准'
        },
        'PACKET MINI': {
          code: 'PACKET MINI',
          name: 'MINI'
        }
      }
    };
  },

  computed: {
    getSize() {
      const { length, width, height } = this.ordinaryObj.formObj;
      if (length && width && height) {
        return `${length}*${width}*${height}`;
      }
      return '';
    }
  },

  watch: {
    // 生成二维码
    bagNo: {
      handler(value) {
        if (value) {
          this.setBarcode(value);
        }
      },
      immediate: true
    },
    index: {
      handler(value) {
        if (value) {
          // 重置数据
          this.resData();
        }
      },
      immediate: true
    },

    ordinaryPropsObj: {
      handler(val) {
        const { height, width, length } = val;
        if (height && width && length) {
          this.isDisabled = true;
          this.ordinaryObj.formObj.height = height || 0;
          this.ordinaryObj.formObj.width = width || 0;
          this.ordinaryObj.formObj.length = length || 0;
        } else {
          this.isDisabled = false;
        }
      },
      immediate: true,
      deep: true
    }
  },

  created() {
    this.bxImg = bxImg;
    this.kdImg = kdImg;
    this.miniImg = miniImg;
    this.correlosImg = correlosImg;

    // this.serviceTypeObj = serviceTypeObj;
  },

  methods: {
    // 关闭弹窗
    handleClose(isBool = true) {
      this.$emit('handleClose', isBool);
    },

    // 重置
    resData() {
      this.ossURL = '';
      this.isDisabled = false;
    },

    // 生成二维码
    setBarcode(val) {
      if (!val) {
        throw new Error('请传入参数 val');
      }

      if (this.expressWaybillType === 0) {
        this.$nextTick(() => {
          JsBarcode('#baglabcode', val, {
            height: 70,
            width: 3,
            displayValue: false // 是否在条形码下方显示文字
          });
        });
        return;
      }
      if (this.expressWaybillType === 1) {
        this.$nextTick(() => {
          JsBarcode('#barcode', val, {
            height: 45,
            width: 1.3,
            font: 'Calibri',
            textMargin: -1,
            fontSize: 12,
            fontOptions: 'bold'
          });
        });
        return;
      }

      // throw new Error('订单当前状态存在问题，请传递数字类型 0 或者 1');
    },

    // 确认答应
    handlerConfirm(func) {
      this.$confirm(this.$i18n.t('operationCenter.ConfirmPrinting'), this.$i18n.t('operationCenter.tips'), {
        confirmButtonText: this.$i18n.t('operationCenter.determine'),
        cancelButtonText: this.$i18n.t('operationCenter.Cancel'),
        type: 'warning'
      }).then(() => {
        func();
        console.log(func);
      }).catch(() => {
        console.log('取消');
      });
    },

    // 新 生成面单
    setVolume() {
      debugger;
      const { id, downstreamCode } = this.ordinaryPropsObj;
      const { height, width, length } = this.ordinaryObj.formObj;

      const obj = {
        id,
        downstreamCode,
        length,
        width,
        height
      };

      if (this.ossURL.length) {
        window.open(this.ossUR);
        return;
      }

      // 没有传入长宽高
      if (!(height && width && length)) {
        obj.height = 0;
        obj.width = 0;
        obj.length = 0;

        this.$confirm(this.$i18n.t('operationCenter.lengthWidthAndHeight'), this.$i18n.t('operationCenter.tips'), {
          confirmButtonText: this.$i18n.t('operationCenter.determine'),
          cancelButtonText: this.$i18n.t('operationCenter.Cancel'),
          type: 'warning'
        }).then(() => {
          this.asyncCreateExpressWaybill(obj);
        }).catch(() => {
          console.log('取消');
        });
      } else {
        this.asyncCreateExpressWaybill(obj);
      }
    },

    // 打印袋标
    async asyncCreateExpressWaybill(obj) {
      try {
        const res = await apiCreateExpressWaybill(obj);

        if (res.code !== 200) { return; }
        this.ossURL = res.data;
        await this.$confirm(this.$i18n.t('operationCenter.checkBagLabel'), this.$i18n.t('operationCenter.tips'), {
          confirmButtonText: this.$i18n.t('operationCenter.View'),
          cancelButtonText: this.$i18n.t('operationCenter.Cancel'),
          type: 'warning'
        });
        window.open(res.data);
        this.handleClose(true);
      } catch (error) {
        this.handleClose(true);
        console.error(error);
      }
    },

    // 巴西打印
    setVolumeOther() {
      this.$message({
        message: '巴西打印 未开放',
        type: 'warning'
      });
    },

    // 浏览器打印
    handlePrint() {
      print({
        printable: 'bagLabels', // // 文档来源：pdf或图像的url，html元素的id或json数据的对象
        type: 'html', // 可打印的类型。可用的打印选项包括：pdf，html，图像，json和raw-html。
        targetStyles: ['*'] // 库在打印HTML元素时处理任何样式
      });
    }

  }
};
</script>

<style scoped>
.print {
  display: flex;
  /* justify-content: space-between; */
}

.mr10 {
  margin-right: 10px;
}

.buts {
  display: flex;
  flex-direction: row-reverse;
}
</style>

<style scoped>
ul.other_list p {
  margin: 0;
}

ul.other_list p,
ul.other_list span {
  font-family: Calibri;
}

ul.other_list {
  margin: 0 auto;
  border: 1px solid #000;
  /* display: flex; */
  width: 595px;
  /* display: none; */
  padding-left: 0;
}

ul.other_list li {
  list-style: none;
  border-bottom: 1px solid #000;
  display: flex;
  margin: 0;
}
ul.other_list li:last-of-type {
  border-bottom: none;
}

ul.other_list li .left {
  width: 143.2px;
  border-right: 1px solid #000;
  line-height: 1.22;
}
ul.other_list li .right {
  /* flex: 1; */
  width: 450px;
}

/* 第一行开始 */
ul.other_list li:first-of-type {
  height: 55px;
}
ul.other_list li:first-of-type .left {
  display: flex;
  align-items: center;
  justify-content: center;
}
ul.other_list li:first-of-type .left_title {
  font-size: 20pt;
  font-weight: bold;
}
ul.other_list li:first-of-type .right {
  display: flex;
  align-items: center;
}
ul.other_list li:first-of-type .right .imgs {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 50px;
  /* border: 1px solid red; */
}
ul.other_list li:first-of-type .right .imgs img {
  max-width: 100%;
  max-height: 100%;
}
ul.other_list li:first-of-type .right .identification {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
}
ul.other_list li:first-of-type .right .txt {
  margin-left: 20px;
  font-size: 28px;
  font-weight: bold;
}
/* 第一行结束 */

/* 第二行开始 */
ul.other_list li:nth-of-type(2) {
  height: 58px;
}
ul.other_list li:nth-of-type(2) .left_title {
 font-size: 12px;
}
ul.other_list li:nth-of-type(2) .left_title span {
  display: inline-block;
  text-align: center;
  width: 100%;
}
ul.other_list li:nth-of-type(2) .cont {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

ul.other_list li:nth-of-type(2) .right {
  display: flex;
  align-items: center;
}
ul.other_list li:nth-of-type(2) .right p:first-of-type span {
  display: block;
  font-size: 12px;
  transform: scale(0.79);
  line-height: 1;
}
ul.other_list li:nth-of-type(2) .right p:last-of-type {
  display: block;
  font-size: 36px;
  margin: -15px 0 0 20px;
}
/* 第二行结束 */

/* 第三行 开始 */
ul.other_list li:nth-of-type(3) {
  height: 46px;
}
ul.other_list li:nth-of-type(3) .left_title {
 font-size: 12px;
 transform: scale(0.7);
}
ul.other_list li:nth-of-type(3) .left_title span {
  display: inline-block;
  text-align: center;
  width: 100%;
}
ul.other_list li:nth-of-type(3) .cont {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
ul.other_list li:nth-of-type(3) .right p:first-of-type {
  /* font-size: 10px; */
  font-size: 12px;
  transform: scale(0.83);
  margin-left: -35px;
}
/* 第三行 结束 */

/* 第四行 开始 */
ul.other_list li:nth-of-type(4) {
  height: 35px;
}
ul.other_list li:nth-of-type(4) .left_title {
  /* font-size: 10px; */
  font-size: 12px;
  transform: scale(0.83);
}
ul.other_list li:nth-of-type(4) .left_title span {
  display: inline-block;
  text-align: center;
  width: 100%;
}
ul.other_list li:nth-of-type(4) .cont {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

ul.other_list li:nth-of-type(4) .right {
  display: flex;
  align-items: center;
}
ul.other_list li:nth-of-type(4) .right div {
  flex: 1;
  height: 100%;
}
ul.other_list li:nth-of-type(4) .right div:first-of-type {
  border-right: 1px solid #000;
}
ul.other_list li:nth-of-type(4) .right div p:first-of-type {
  font-size: 12px;
  transform: scale(0.83);

  margin-left: -15px;
}
ul.other_list li:nth-of-type(4) .right div p:last-of-type {
  font-size: 14px;
  text-align: center;
}
/* 第四行 结束 */

/* 最后一行 开始 */
ul.other_list li:last-of-type .child:nth-of-type(1),
ul.other_list li:last-of-type .child:nth-of-type(2) {
  height: 28px;
  border-bottom: 1px solid #000;
}
ul.other_list li:last-of-type .child:last-of-type {
  border-bottom: none;
}
ul.other_list li:last-of-type .child:nth-of-type(1) p,
ul.other_list li:last-of-type .child:nth-of-type(2) p {
  display: flex;
  align-items: center;
  justify-content: center;
}
ul.other_list li:last-of-type .child:nth-of-type(1) span,
ul.other_list li:last-of-type .child:nth-of-type(2) span {
  font-size: 12px;
  transform: scale(0.7);
  white-space:nowrap;
}
ul.other_list li:last-of-type .child:nth-of-type(1) .cont,
ul.other_list li:last-of-type .child:nth-of-type(2) .cont {
  font-size: 12px;
  font-weight: bold;
}

ul.other_list li:last-of-type .child:nth-of-type(2) {
  height: 28px;
  border-bottom: 1px solid #000;
}

ul.other_list li:last-of-type .child:last-of-type {
  /* font-size: 12pt; */
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  line-height: 22px;
}
/* 最后一行 结束 */

.print_right {
  /* w */
  height: 380px;
  display: flex;
}

.prin_pdf {
  margin: -80px 0 0 -80px;
  transform: scale(0.7, 0.7);
}
</style>
