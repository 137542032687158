import Vue from 'vue';

import 'normalize.css/normalize.css'; // A modern alternative to CSS resets
import './styles/oruga/index-oruga.scss';
import VueCompositionAPI from '@vue/composition-api';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'vue-easytable/libs/theme-default/index.css';

import '@/styles/index.scss'; // global css
import '@/styles/tooles.css'; // 工具
import App from './App';
import store from './store';
import router from './router';

import i18n from './lang/index'; // 国际化
import '@/icons'; // icon
import '@/permission';
// import moment from 'moment'; // permission control
import '@/assets/font_express/iconfont.css';
import formCreate from '@form-create/element-ui';
// import '@/styles/media_screen.scss';
import md5 from 'js-md5';

import dayjs from 'dayjs';

import Oruga from '@oruga-ui/oruga';

import Vuelidate from 'vuelidate';

import VueTheMask from 'vue-the-mask';

import { VueQueryPlugin } from '@tanstack/vue-query';
import VueApexCharts from 'vue-apexcharts';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import vcolorpicker from 'vcolorpicker';
import vueEsign from 'vue-esign';
// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view';
// el-table 滚动翻页指令
import Directive from './directives/index.js';
import {disableExport} from "@/directives/exportDisabled";

Vue.use(VueCompositionAPI);
Vue.use(VueQueryPlugin, {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        networkMode: 'always',
        refetchOnWindowFocus: false,
        retry: false,
        keepPreviousData: true

      }
    }
  }
});

// 不建议在vue原型上绑定该方法，建议在需要的地方通过 import 去引入，该段代码会在后续中删除，请谨慎使用20240802
Vue.prototype.$dayjs = dayjs;

Vue.use(vcolorpicker);

Vue.use(formCreate);

// 不建议在vue原型上绑定该方法，建议在需要的地方通过 import 去引入，该段代码会在后续中删除，请谨慎使用20240802
Vue.prototype.$md5 = md5; // 加密挂载

Vue.use(vueEsign);

Vue.use(ElementUI, {
  // size: Cookies.get('size') || 'medium', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value)
});

library.add(fas);
library.add(fab);

Vue.use(VueApexCharts);

Vue.component('Apexchart', VueApexCharts);

Vue.component('VueFontawesome', FontAwesomeIcon);

Vue.use(Oruga, {
  iconPack: 'fas',
  iconComponent: 'vue-fontawesome',
  statusIcon: false,
  button: {
    override: true,
    rootClass: 'o-btn-base',
    sizeClass: (suffix, context) => {
      return `o-btn-${suffix}`;
    },
    variantClass: (suffix, context) => {
      return `o-btn-${suffix}`;
    }
  },
  field: {
    override: true,
    rootClass: 'o-field-root',
    messageClass: 'o-field-message',
    labelClass: 'o-field-label'
  },
  modal: {
    override: true,
    rootClass: 'o-modal-root',
    overlayClass: 'o-modal-overlay',
    contentClass: 'o-modal-content',
    closeClass: 'o-modal-close',
    fullScreenClass: 'o-modal-content-fullscreen'
  },
  pagination: {
    override: true,
    rootClass: 'o-pagination-root',
    orderClass: (suffix, context) => {
      return `o-pagination-order-${suffix}`;
    },
    linkClass: 'o-pag-link',
    linkCurrentClass: 'o-pag-link-current',
    listClass: 'o-pag-list',
    nextBtnClass: 'o-pag-next',
    prevBtnClass: 'o-pag-previous'
  },
  input: {
    override: true,
    iconRightClass: 'o-input-icon-right',
    iconLeftClass: 'o-input-icon-left',
    iconRightSpaceClass: 'o-input-iconspace-right',
    iconLeftSpaceClass: 'o-input-iconspace-left',
    rootClass: 'o-input-root',
    inputClass: 'o-input',
    variantClass: (suffix, context) => {
      return `o-input-${suffix}`;
    }
  },
  select: {
    override: true,
    placeholderClass: 'o-select-placeholder',
    selectClass: 'o-select',
    arrowClass: 'o-select-arrow',
    rootClass: 'o-select-root'
  },
  table: {
    override: true,
    wrapperClass: 'o-table-wrapper',
    tableClass: 'o-table',
    tdClass: 'o-table-td',
    thClass: 'o-table-th',
    paginationWrapperClass: 'o-table-pagination-wrapper',
    footerClass: 'o-table-footer',
    tdStickyClass: 'o-table-sticky',
    stickyHeaderClass: 'o-table-sticky',
    emptyClass: 'o-table-empty'
  },
  radio: {
    override: true,
    rootClass: 'o-radio',
    checkClass: 'o-radio-check',
    checkCheckedClass: 'o-radio-check-checked',
    labelClass: 'o-radio-label'
  },
  autocomplete: {
    override: true,
    rootClass: 'o-autocomplete',
    menuClass: 'o-autocomplete-menu',
    itemClass: 'o-autocomplete-menu-item',
    itemHoverClass: 'o-autocomplete-menu-item-hover'
  },
  inputitems: {
    rootClass: 'o-input-items',
    containerClass: 'o-input-items-container',
    autocompleteClasses: {
      rootClass: 'o-input-itens-autocomplete-root',
      inputClasses: {
        inputClass: 'o-input-itens-input'
      }
    }
  },
  checkbox: {
    labelClass: 'o-checkbox-label'
  },
  dropdown: {
    rootClass: 'o-dropdown-root',
    menuClass: 'o-dropdown-menu',
    itemClass: 'o-dropdown-menu-item'

  },
  upload: {
    draggableClass: 'o-draggable'
  },
  tabs: {
    override: true,
    navTabsClass: 'o-tab-nav',
    itemHeaderClass: 'o-tab-item-nav',
    itemHeaderActiveClass: (suffix, context) => {
      return `o-tabs-${suffix}`;
    }
  },
  skeleton: {
    override: true,
    itemClass: 'o-skeleton-item',
    rootClass: 'o-skeleton',
    animationClass: 'o-skeleton-item--animated'
  },
  notification: {
    override: true,
    rootClass: 'o-toast-root',
    wrapperClass: 'o-toast-wrapper',
    variantClass: (suffix, context) => {
      return `o-toast-${suffix}`;
    },
    positionClass: (suffix, context) => {
      return `o-toast-${suffix}`;
    },
    iconClass: 'o-toast-icon',
    contentClass: 'o-toast-content',
    closeClass: 'o-toast-close',
    noticeClass: 'o-notices',
    noticePositionClass: (suffix, context) => {
      return `o-notices-${suffix}`;
    }
  },
  loading: {
    override: true,
    rootClass: 'o-load',
    overlayClass: 'o-load-overlay',
    fullPageClass: 'o-load',
    iconClass: 'o-icon-load'
  },
  timepicker: {
    override: true,
    boxClass: 'o-timepicker-box'
  },
  switch: {
    override: true,
    rootClass: 'o-switch',
    positionClass: (suffix, context) => {
      return `o-switch-${suffix}`;
    },
    labelClass: 'o-switch-label',
    inputClass: 'o-switch-input',
    checkSwitchClass: 'o-switch-check-switch',
    checkClass: 'o-switch-check',
    checkCheckedClass: 'o-switch-check-checked'
  }

});

Vue.use(Vuelidate);

Vue.use(VueTheMask);

Vue.use(dataV);

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }
if (process.env.NODE_ENV === 'production') {
  if (window) {
    window.console.log = function() {
    };
  }
}
Vue.config.productionTip = false;
// 输入框自动聚焦
Vue.directive('focus', {
  inserted: (el, data) => {
    if (data.value === true) {
      el.querySelector('input').focus();
    }
  },
  update: (el, data) => {
    // 无绑定自动聚焦
    if (data.value === true) {
      el.querySelector('input').focus();
    }
  }
});
// 按钮权限
Vue.directive('permit', {
  inserted: function(el, binding, vnode) {
    const buttonPermissions = store.getters.button_permissions;
    const hasPermit = buttonPermissions.find(item => item === binding.value);
    if (binding.arg && binding.arg === 'disabled' && !hasPermit) {
      el.disabled = 'disabled';
      el.classList.add('is-disabled');
    } else if (binding.arg && binding.arg === 'remove' && !hasPermit) {
      el.parentNode.removeChild(el);
    }
  }
});
// 指令监听元素宽高变化
Vue.directive('resize', {
  bind(el, binding) {
    // el为绑定的元素，binding为绑定给指令的对象
    let width = '';
    let height = '';

    function isReize() {
      const style = document.defaultView.getComputedStyle(el);
      if (width !== style.width || height !== style.height) {
        // 关键(这传入的是函数,所以执行此函数)
        binding.value({ width: style.width, height: style.height });
      }
      width = style.width;
      height = style.height;
    }

    el.__vueSetInterval__ = setInterval(isReize, 0);
  }
});
// 注册全局指令

Vue.use(Directive); // 注册其他指令
Vue.use(disableExport);
// console.log(Vue.options.directives);

Vue.prototype.bus = new Vue();
// 格式化全局过滤器
// Vue.filter('dateYMDHMSFormat', function(dateStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
//   return moment(dateStr).format(pattern);
// });

// new Vue({
//   el: '#app',
//   router,
//   store,
//   i18n,
//   render: h => h(App)
// });

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app');

