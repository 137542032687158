<template>
  <div class="app-container">
    <el-card style="margin-bottom: 20px;" class="searchInputCon">
      <el-form label-position="top" size="small">
        <el-row :gutter="15">
          <el-col :span="6">
            <el-form-item prop="searchType">
              <template slot="label">
                <div class="selectLabel">
                  <el-select
                    v-model="queryForm.params.searchType"
                    :placeholder="$t('GlobalSetObj.pleaseChoose')"
                    :style="IsWidth"
                    @change="SwitchType"
                  >
                    <!--总单号-->
                    <el-option key="1" :label="$t('GlobalSetObj.totalSingNumber')" value="instoreBigBagNo" />
                    <!--袋号-->
                    <el-option key="2" :label="$t('GlobalSetObj.bagNumber')" value="bigBagNos" />
                  </el-select>
                </div>
              </template>
              <el-input
                v-model="queryForm.params.inputValue"
                :placeholder="$t('operationCenter.Multiple200')"
                clearable
                type="textarea"
                :rows="8"
              />
            </el-form-item>
          </el-col>
          <el-col :span="17">
            <el-row :gutter="15">
              <!--服务商-->
              <el-col :span="8">
                <el-form-item :label="$t('GlobalSetObj.fuwushang')">
                  <el-select
                    v-model="queryForm.params.systemCode"
                    :placeholder="$t('GlobalSetObj.pleaseChoose')"
                    clearable
                    filterable
                    style="width: 100%;"
                  >
                    <el-option
                      v-for="(item, ind) in channelVendorsList"
                      :key="ind"
                      :label="item.name + '（' + item.code + '）'"
                      :value="item.code"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <!--派送网点-->
              <el-col :span="8">
                <el-form-item :label="$t('GlobalSetObj.DeliveryOutlets')">
                  <el-select
                    v-model="queryForm.params.deliveryStationId"
                    :placeholder="$t('spareNumberPoo.pleaseChoose')"
                    style="width: 100%;"
                    clearable
                    filterable
                  >
                    <!--请先选择应收主体-->
                    <el-option
                      v-for="item in outletsList"
                      :key="item.id"
                      :label="item.name + '（' + item.code + '）'"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <!--状态-->
              <el-col :span="8">
                <el-form-item :label="$t('GlobalSetObj.status')">
                  <el-select
                    v-model="queryForm.params.assemblyStatus"
                    :placeholder="$t('GlobalSetObj.all')"
                    clearable
                    filterable
                    style="width: 100%;"
                  >
                    <el-option key="0" :label="$t('GlobalSetObj.all')" :value="null" />
                    <el-option key="1" :label="$t('operationCenter.Assembled')" :value="1" />
                    <el-option key="2" :label="$t('operationCenter.assemblyCompleted')" :value="2" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <!--装配人-->
              <el-col :span="8">
                <el-form-item :label="$t('operationCenter.assembler')">
                  <el-select
                    v-model="queryForm.params.assemblyUser"
                    filterable
                    clearable
                    :placeholder="$t('spareNumberPoo.pleaseChoose')"
                    style="width: 100%;"
                  >
                    <el-option key="all" :label="$t('GlobalSetObj.all')" :value="null" />
                    <el-option
                      v-for="(item, ind) in assemblerList"
                      :key="ind"
                      :label="item.realName + '（' + item.username + '）'"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <!--装配时间-->
              <el-col :span="16">
                <el-form-item :label="$t('operationCenter.AssemblyTime')">
                  <el-date-picker
                    v-model="assembleDate"
                    style="width: 100%"
                    type="datetimerange"
                    :range-separator="$t('operationCenter.to')"
                    :start-placeholder="$t('operationCenter.startDate')"
                    :end-placeholder="$t('operationCenter.endDate')"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :default-time="['00:00:00', '23:59:59']"
                    :picker-options="pickerOptions"
                    @blur="refreshDate"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <!--装配完结人-->
              <el-col :span="8">
                <el-form-item :label="$t('operationCenter.AssembleTheFinishedPerson')">
                  <el-select
                    v-model="queryForm.params.assemblyCompletedUser"
                    filterable
                    clearable
                    :placeholder="$t('spareNumberPoo.pleaseChoose')"
                    style="width: 100%;"
                  >
                    <el-option key="all" :label="$t('GlobalSetObj.all')" :value="null" />
                    <el-option
                      v-for="(item, ind) in assemblyCompletedList"
                      :key="ind"
                      :label="item.realName + (item.username ? '（' + item.username + '）' : '')"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <!--装配完结时间-->
              <el-col :span="16">
                <el-form-item :label="$t('operationCenter.assemblyCompletionTime')">
                  <el-date-picker
                    v-model="assemblyCompletedDate"
                    style="width: 100%"
                    type="datetimerange"
                    :range-separator="$t('operationCenter.to')"
                    :start-placeholder="$t('operationCenter.startDate')"
                    :end-placeholder="$t('operationCenter.endDate')"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :default-time="['00:00:00', '23:59:59']"
                    :picker-options="pickerOptions"
                    @blur="refreshDate"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="1">
            <div style="float: right;cursor: pointer;font-size: 21px" @click="setBrazilianPost">
              <el-tooltip placement="top-start">
                <div slot="content">{{ $t('GlobalSetObj.QuantitySetting') }}</div>
                <i class="el-icon-s-tools" />
              </el-tooltip>
            </div>
          </el-col>
        </el-row>
        <div style="margin-top: 10px;">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="getSearch(1)"
          >{{ $t('operationCenter.Procurar') }}</el-button>
          <el-button
            size="small"
            icon="el-icon-refresh-left"
            @click="getSearch(2)"
          >{{ $t('operationCenter.reset') }}</el-button>
          <el-button
            size="small"
            plain
            @click="exportList()"
          >{{ $t('operationCenter.exports') }}</el-button>
        </div>
      </el-form>
    </el-card>
    <!--  表格数据  -->
    <AnjunTableList
      ref="anjunTable"
      :format-time="isTormat"
      :has-operation="true"
      :header-list="tableHeaderList"
      :is-select-btn="true"
      :is-tip="true"
      :table-data="tableData"
      @packageList="packageList"
      @selectTable="selectTable"
      @on-PrintList="OnPrintList"
      @to-OrderDetail="toOrderDetail"
    />
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.number"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!--  分配申报数量设置  -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="brazilianPostDialog"
      width="25%"
    >
      <div style="margin-bottom: 15px;font-size: 16px;">{{ $t('GlobalSetObj.mindeclaredQuantity') }}</div>
      <div>
        <el-input
          v-model="DeclaredQuantity"
          :placeholder="$t('GlobalSetObj.pleaseInput')"
          clearable
          style="width: 50%"
          type="number"
        />
      </div>
      <p style="color: #a4a7ab;">{{ $t('GlobalSetObj.BrazilianPostGenerter') }}</p>
      <p style="color: #a4a7ab;">{{ $t('GlobalSetObj.PLPDeclaration') }}</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="brazilianPostDialog = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button type="primary" @click="setDeclaredCount">{{ $t('GlobalSetObj.Salve') }}</el-button>
      </span>
    </el-dialog>
    <!--大包内包裹-->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('GlobalSetObj.LargePackageInside')"
      :visible.sync="showPackageList"
      top="1vh"
      width="60%"
      @close="searchNumber = ''"
    >
      <div class="packageList">
        <!--运单号-->
        <div class="selectType" style="margin-bottom: 10px;">
          <el-select
            v-model="searchNumberType"
            :placeholder="$t('orderDetails.pleaseChoose')"
            style="width: 390px;"
          >
            <!--袋号-->
            <el-option :label="$t('GlobalSetObj.bagNumber')" value="3" />
            <!--运单号-->
            <el-option :label="`${$t('newOrder.waybillNumber')+'/'+$t('GlobalSetObj.serviceLogistics')}`" value="1" />
            <!--客户-->
            <el-option :label="$t('GlobalSetObj.client')" value="2" />
          </el-select>
        </div>
        <el-input
          v-model="searchNumber"
          :placeholder="$t('GlobalSetObj.pleaseInput')"
          clearable
          style="width: 50%"
        >
          <el-button
            slot="append" icon="el-icon-search" type="primary"
            @click="searchPackageList()"
          />
        </el-input>
        <div style="margin-top: 20px;">
          <AnjunTableList
            ref="PackageList"
            :format-time="isTormat"
            :has-operation="false"
            :header-list="packageTableHeaderList"
            :is-select-btn="false"
            :is-tip="true"
            :table-data="packageListTableData"
          />
          <!--  分页  -->
          <el-pagination
            :current-page="packageListForm.pageNumber"
            :page-size="packageListForm.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :pager-count="5"
            :total="packageListForm.total"
            background
            layout="total, sizes, prev, pager, next"
            small
            style="margin: 10px 0;text-align: right"
            @size-change="packageListSizeChange"
            @current-change="packageListCurrentChange"
          />
        </div>
      </div>
    </el-dialog>
    <!--总单详情-->
    <el-drawer
      :append-to-body="true"
      :title="$t('GlobalSetObj.TotalOrderDetails')"
      :visible.sync="SummaryDetailsDrawer"
      custom-class="drawerClass"
      direction="ltr"
      size="80%"
    >
      <div style="height: calc(100vh - 67px); overflow: auto">
        <SummaryDetails
          :key="detailIdKey"
          :detail-id="detailId"
          :total-single-object="totalSingleObject"
          @exportClose="exportClose"
          @updateTable="updateTable"
        />
      </div>
    </el-drawer>
    <!--  打印清单  -->
    <div style="display: none">
      <pdf ref="pdfPrint" :src="viewUrl" />
    </div>
    <!--打印大包号-->
    <div style="display: none">
      <detailListHtml v-if="showDetailListHtmlHtml" :param-data="printDetailObj" @hiddenHtml="hiddenHtml" />
    </div>
  </div>
</template>

<script>
import AnjunTableList from '@/components/anjun-tableList';
import {
  apiBigBagSelectByBagNumber, apiExportTotalList, apiExportTransitBig,
  getBatchCountMin,
  lagePackgePage,
  printBagNumberList,
  updateDeclaredCount
} from '@/api/storeAdmin.js';
import SummaryDetails from './component/SummaryDetails';
import pdf from 'vue-pdf-messy-code-fix';
import detailListHtml from './component/detailedList';
import { getUserPage } from '@/api/user';
import { apiFuzzySearchProvider, apiFuzzySearchSite } from '@/api/automaticSorting';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList.js';

export default {
  name: 'BigPackList',
  components: {
    AnjunTableList,
    SummaryDetails,
    pdf,
    detailListHtml
  },
  data() {
    return {
      showDetailListHtmlHtml: false, // 控制打印清单的显示隐藏
      printDetailObj: {}, // 打印清单数据
      viewUrl: '',
      totalListNumber: '',
      // 是否格式化成巴西时间
      isTormat: false,
      SummaryDetailsDrawer: false, // 总单详情
      detailId: null,
      totalSingleObject: {},
      zhDate: 'yyyy-MM-dd',
      ptDate: 'dd-MM-yyyy',
      queryForm: {
        pageSize: 10,
        number: 1,
        total: 0,
        params: {
          assemblyStartTime: this.getAWeek().startDate + ' 00:00:00',
          completedStartTime: this.getAWeek().startDate + ' 00:00:00',
          assemblySEndTime: this.getAWeek().endDate + ' 23:59:59',
          completedEndTime: this.getAWeek().endDate + ' 23:59:59',
          searchType: 'instoreBigBagNo',
          assemblyStatus: null, // 状态
          systemCode: null, // 服务商渠道
          systemName: '',
          deliveryStationId: null, // 网点
          deliveryStationName: '',
          inputValue: '',
          assemblyUser: this.$store.state.user.id, // 装配人
          assemblyCompletedUser: null, // 装配完结人
          assemblyUserName: this.$store.state.user.realName,
          assemblyCompletedUserName: '',
          instoreBigBagNos: [],
          bigBagNos: []
        }
      },
      channelVendorsList: [], // 渠道商
      tableHeaderList: [
        // 总单号
        { id: 1, label: this.$t('GlobalSetObj.totalSingNumber'), prop: 'instoreBigBagNo', cloWidth: '200' },
        // 大包号
        // { id: 12, label: this.$t('GlobalSetObj.LargePacketNumber'), prop: 'instoreBigBagNo', cloWidth: '200' },
        // 服务商
        { id: 2, label: this.$t('GlobalSetObj.fuwushang'), prop: 'systemName', cloWidth: '140' },
        // 派送网点
        { id: 3, label: this.$t('GlobalSetObj.DeliveryPoint'), prop: 'deliveryStationName', cloWidth: '160' },
        // 袋数
        { id: 4, label: this.$t('collectionCenter.bagsNum'), prop: 'bagCount', cloWidth: '100' },
        // 包裹数
        { id: 5, label: this.$t('GlobalSetObj.package'), prop: 'packageCount', cloWidth: '100' },
        // 总重
        { id: 6, label: this.$t('trajectory.TotalWeight') + '(kg)', prop: 'totalWeight', cloWidth: '120' },
        // 状态
        { id: 7, label: this.$t('GlobalSetObj.status'), prop: 'assemblyStatus', cloWidth: '160' },
        // 装配人
        { id: 8, label: this.$t('operationCenter.assembler'), prop: 'assemblyUserName', cloWidth: '160' },
        // 装配时间
        { id: 9, label: this.$t('operationCenter.AssemblyTime'), prop: 'assemblyTime', cloWidth: '180' },
        // 装配完结人
        { id: 10, label: this.$t('operationCenter.AssembleTheFinishedPerson'), prop: 'assemblyCompletedUserName', cloWidth: '160' },
        // 装配完结时间
        { id: 11, label: this.$t('operationCenter.assemblyCompletionTime'), prop: 'assemblyCompletionTime', cloWidth: '180' }
      ],
      tableData: [],
      switchSearchType: this.$t('GlobalSetObj.totalSingNumber'),
      brazilianPostDialog: false,
      // 勾选表格数据
      selectionList: [],
      showPackageList: false, // 查看袋内包裹
      dataPackageList: {},
      packageListTableData: [],
      packageTableHeaderList: [
        // 袋号
        { id: 1, label: this.$t('GlobalSetObj.bagNumber'), prop: 'transitBagNumber', cloWidth: '170' },
        // 运单号
        { id: 2, label: this.$t('GlobalSetObj.waybillNumber'), prop: 'waybillNumber', cloWidth: '170' },
        // 服务商物流单号
        { id: 5, label: this.$t('GlobalSetObj.serviceLogistics'), prop: 'logisticsNumber', cloWidth: '200' },
        // 客户
        { id: 3, label: this.$t('GlobalSetObj.client'), prop: 'customerName', cloWidth: '160' },
        // 转运中心称重（KG）
        { id: 4, label: this.$t('newOrder.TransferWeighing1'), prop: 'weight', cloWidth: '160' }
      ],
      packageListForm: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
        params: {
          instoreBigBagNo: '',
          waybillNumber: '',
          customerName: '',
          transitBagNumber: ''
        }
      },
      searchNumberType: '1',
      DeclaredQuantity: 110, // 最小申报数量
      searchNumber: '',
      outletsList: [], // 网点选项
      assemblerList: [],
      assemblyCompletedList: [],
      checkAll: false,
      pickerOptions: {
        // 设置不能选择的日期
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate0 = minDate.getTime();
          if (maxDate) {
            this.choiceDate0 = '';
          }
        },
        disabledDate: (time) => {
          const choiceDateTime = new Date(this.choiceDate0).getTime();
          const minTime = new Date(choiceDateTime).setDate(
            new Date(choiceDateTime).getDate() - 30
          );
          const maxTime = new Date(choiceDateTime).setDate(
            new Date(choiceDateTime).getDate() + 30
          );
          const min = minTime;
          const newDate =
            new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000;
          const max = newDate < maxTime ? newDate : maxTime;
          // 如果已经选中一个日期 则 返回 该日期前后一个月时间可选
          if (this.choiceDate0) {
            return time.getTime() < min || time.getTime() > max;
          }
          // 若一个日期也没选中 则 返回 当前日期以前日期可选
          return time.getTime() > newDate;
        }
      },
      detailIdKey: new Date().getTime(),
      assembleDate: [this.getAWeek().startDate + ' 00:00:00', this.getAWeek().endDate + ' 23:59:59'],
      assemblyCompletedDate: []
    };
  },
  computed: {
    assemblyStatus() {
      return {
        1: { label: this.$t('operationCenter.Assembled'), code: 1 }, // 已装配
        2: { label: this.$t('operationCenter.assemblyCompleted'), code: 2 } // 装配完结
      };
    },
    IsWidth() {
      return {
        width: this.isTormat ? '210px' : '120px'
      };
    }
  },
  watch: {
    'queryForm.params.systemCode'(val) {
      this.queryForm.params.systemName = '';
      this.channelVendorsList.map(item => {
        if (item.id === val) {
          this.queryForm.params.systemName = item.name;
        }
      });
    },
    'queryForm.params.deliveryStationId'(val) {
      this.queryForm.params.deliveryStationName = '';
      this.outletsList.map(item => {
        if (item.id === val) {
          this.queryForm.params.deliveryStationName = item.name;
        }
      });
    },
    'queryForm.params.assemblyUser'(val) {
      this.queryForm.params.assemblyUserName = '';
      this.assemblerList.map(item => {
        if (item.id === val) {
          this.queryForm.params.assemblyUserName = item.realName;
        }
      });
    },
    'queryForm.params.assemblyCompletedUser'(val) {
      this.queryForm.params.assemblyCompletedUserName = '';
      this.assemblerList.map(item => {
        if (item.id === val) {
          this.queryForm.params.assemblyCompletedUserName = item.realName;
        }
      });
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    const buttonPermissions = this.$store.getters.button_permissions;
    this.checkAll = buttonPermissions.includes('btn:automatic-sorting:checkAll');
    // 获取入库员选项
    this.getUserManageOption();
    this.getServiceList();
    this.getSitePageList();
    this.getSearch(1);
  },
  methods: {
    // 详情添加删除后更新列表
    updateTable() {
      this.getSearch(1);
    },
    // 详情导出关闭弹窗
    exportClose(data, paramObj) {
      const ids = data.map(item => {
        return item.id;
      });
      const param = {
        idList: ids,
        tranBagNumberCondition: paramObj
      };
      apiExportTransitBig(param).then(res => {
        if (res.status === 'OK') {
          return goExportList(this);
        }
      }).then(res => {
        if (res) {
          this.SummaryDetailsDrawer = false;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 导出
    exportList() {
      const param = {
        idList: [],
        transitBigBagCondition: this.queryFormData().params
      };
      if (this.selectionList.length > 0) {
        param['idList'] = this.selectionList.map(item => {
          return item.id;
        });
      }
      apiExportTotalList(param).then(res => {
        if (res.status === 'OK') {
          goExportList(this);
        }
      });
    },
    // 日期选择器失去焦点
    refreshDate() {
      this.choiceDate0 = '';
    },
    // 获取入库人选项
    getUserManageOption() {
      const param = {
        pageSize: 9999999,
        pageNumber: 1,
        params: {
          accountState: '1'
        }
      };
      getUserPage(param).then((response) => {
        if (response.status === 'OK') {
          const { records } = response.data;
          if (this.checkAll === false) {
            this.assemblerList = [
              {
                realName: this.$store.state.user.realName,
                username: this.$store.state.user.username,
                id: this.$store.state.user.id
              }
            ];
          } else {
            this.assemblerList = records;
            // this.assemblerList.unshift(obj);
          }
          this.assemblyCompletedList = records;
          // this.assemblyCompletedList.unshift(obj);
        }
      });
    },
    // 获取网点
    getSitePageList() {
      apiFuzzySearchSite({ siteBlur: '' }).then(res => {
        if (res.status === 'OK') {
          this.outletsList = res.data;
        }
      });
    },
    // 获取服务商
    getServiceList() {
      apiFuzzySearchProvider({ providerBlur: '' }).then(res => {
        if (res.status === 'OK') {
          this.channelVendorsList = res.data;
        }
      });
    },
    // 清空打数据
    hiddenHtml() {
      this.showDetailListHtmlHtml = false;
      this.printDetailObj = {};
    },
    // 设置巴西邮政
    setBrazilianPost() {
      getBatchCountMin().then((res) => {
        if (res.status === 'OK') {
          if (res.data) {
            this.DeclaredQuantity = res.data;
          } else {
            this.DeclaredQuantity = 110;
          }
        }
      });
      this.brazilianPostDialog = true;
    },
    // 打开查看袋内包裹弹窗
    packageList(data) {
      this.dataPackageList = data;
      this.getPackageListTable();
      this.showPackageList = true;
    },
    // 获取包裹数量列表
    getPackageListTable() {
      this.packageListForm.params.instoreBigBagNo = this.dataPackageList.instoreBigBagNo;
      // this.searchNumber
      if (this.searchNumberType === '1') {
        this.packageListForm.params.waybillNumber = this.searchNumber;
        this.packageListForm.params.customerName = '';
        this.packageListForm.params.transitBagNumber = '';
      } else if (this.searchNumberType === '2') {
        this.packageListForm.params.waybillNumber = '';
        this.packageListForm.params.customerName = this.searchNumber;
        this.packageListForm.params.transitBagNumber = '';
      } else {
        this.packageListForm.params.waybillNumber = '';
        this.packageListForm.params.customerName = '';
        this.packageListForm.params.transitBagNumber = this.searchNumber;
      }
      apiBigBagSelectByBagNumber(this.packageListForm).then(res => {
        if (res.status === 'OK') {
          this.packageListTableData = res.data.records;
          this.packageListForm.total = res.data.total;
        }
      });
    },
    // 搜素包裹数列表
    searchPackageList() {
      this.packageListForm.pageNumber = 1;
      this.getPackageListTable();
    },
    // 点击分页
    packageListSizeChange(val) {
      this.packageListForm.pageSize = val;
      this.packageListForm.pageNumber = 1;
      this.getPackageListTable();
    },
    packageListCurrentChange(val) {
      this.packageListForm.pageNumber = val;
      this.getPackageListTable();
    },
    // 列表数据勾选
    selectTable(data) {
      this.selectionList = data;
    },
    // 切换申报状态
    switchStatus(val) {
      this.queryForm.params.assemblyStatus = val;
      this.queryForm.number = 1;
      this.getSearch(1);
    },
    // 切换搜索类型
    SwitchType(val) {
      if (val === 'instoreBigBagNo') {
        this.switchSearchType = this.$t('GlobalSetObj.totalSingNumber');
      } else {
        this.switchSearchType = this.$t('GlobalSetObj.LargePacketNumber');
      }
    },
    // 翻页查询列表数据入参整理
    queryFormData() {
      const inputValList = [];
      if (this.queryForm.params.inputValue) {
        const orderList = this.queryForm.params.inputValue.split('\n');
        orderList.map(item => {
          const number = item.replace(/\s/g, '').trim();
          if (number) {
            inputValList.push(number);
          }
        });
      }
      const data = {
        pageSize: this.queryForm.pageSize,
        pageNumber: this.queryForm.number,
        params: {
          assemblyStartTime: '',
          completedStartTime: '',
          assemblySEndTime: '',
          completedEndTime: '',
          assemblyStatus: this.queryForm.params.assemblyStatus, // 状态
          systemCode: this.queryForm.params.systemCode, // 服务商渠道
          systemName: this.queryForm.params.systemName,
          deliveryStationId: this.queryForm.params.deliveryStationId, // 网点
          deliveryStationName: this.queryForm.params.deliveryStationName,
          assemblyUser: this.queryForm.params.assemblyUser, // 装配人
          assemblyUserName: this.queryForm.params.assemblyUserName,
          assemblyCompletedUser: this.queryForm.params.assemblyCompletedUser,
          assemblyCompletedUserName: this.queryForm.params.assemblyCompletedUserName,
          instoreBigBagNos: [],
          bigBagNos: []
        }
      };
      if (this.assembleDate && this.assembleDate.length > 0) {
        data.params.assemblyStartTime = this.assembleDate[0];
        data.params.assemblySEndTime = this.assembleDate[1];
      }
      if (this.assemblyCompletedDate && this.assemblyCompletedDate.length > 0) {
        data.params.completedStartTime = this.assemblyCompletedDate[0];
        data.params.completedEndTime = this.assemblyCompletedDate[1];
      }
      if (this.queryForm.params.searchType === 'instoreBigBagNo') {
        data.params.instoreBigBagNos = inputValList;
        data.params.bigBagNos = [];
      } else {
        data.params.bigBagNos = inputValList;
        data.params.instoreBigBagNos = [];
      }
      return data;
    },
    // 查询
    getSearch(type) {
      if (type === 2) {
        this.queryForm = {
          pageSize: 10,
          number: 1,
          total: 0,
          params: {
            assemblyStartTime: this.getAWeek().startDate + ' 00:00:00',
            completedStartTime: this.getAWeek().startDate + ' 00:00:00',
            assemblySEndTime: this.getAWeek().endDate + ' 23:59:59',
            completedEndTime: this.getAWeek().endDate + ' 23:59:59',
            searchType: 'instoreBigBagNo',
            assemblyStatus: null, // 状态
            systemCode: null, // 服务商渠道
            systemName: '',
            deliveryStationId: null, // 网点
            deliveryStationName: '',
            inputValue: '',
            assemblyUser: this.$store.state.user.id, // 装配人
            assemblyCompletedUser: null,
            assemblyUserName: this.$store.state.user.realName,
            assemblyCompletedUserName: '',
            instoreBigBagNos: [],
            bigBagNos: []
          }
        };
        this.assembleDate = [this.getAWeek().startDate + ' 00:00:00', this.getAWeek().endDate + ' 23:59:59'];
        this.assemblyCompletedDate = [];
      } else {
        const data = this.queryFormData();
        if (data.params.instoreBigBagNos.length > 200 || data.params.bigBagNos.length > 200) {
          // 提示最大输入两百个袋号
          this.$message({
            message: this.$t('GlobalSetObj.queried200pieces'),
            type: 'warning'
          });
          return;
        }
        console.log(data, '====data====');
        lagePackgePage(data).then(res => {
          if (res.status === 'OK') {
            this.queryForm.total = res.data.total;
            this.tableData = res.data.records;
            this.tableData.forEach(item => {
              item.PrintList = true;
              item.PrepostNumber = item.prepostNumber ? `${item.prepostNumber}` : '- -';
              item.declareUser = item.declareUser ? `${item.declareUser}` : '- -';
              item.updateTime = item.updateTime ? `${item.updateTime}` : '- -';
              if (item.assemblyStatus) {
                item.assemblyStatus1 = item.assemblyStatus;
                item.assemblyStatus = this.assemblyStatus[item.assemblyStatus].label;
              } else {
                item.assemblyStatus1 = item.assemblyStatus;
                item.assemblyStatus = '- -';
              }
            });
          }
        });
      }
    },
    // 打印详情
    OnPrintList(val) {
      this.totalListNumber = val.instoreBigBagNo;
      const data = {
        instoreBigBagNo: val.instoreBigBagNo
      };
      printBagNumberList(data).then(res => {
        this.showDetailListHtmlHtml = true;
        res.data.createTime = dayjs(res.data.createTime).format('DD/MM/YYYY HH:mm:ss');
        console.log(res.data.plpNumber, '打印详情打印详情打印详情');
        this.printDetailObj = res.data;
        // this.printDetailObj.instoreBigBagNo = this.totalListNumber;
        console.log(this.printDetailObj, 'this.printDetailObj');
      });
    },
    toOrderDetail(row) {
      this.SummaryDetailsDrawer = true;
      this.detailId = row.instoreBigBagNo;
      this.totalSingleObject = row;
      this.detailIdKey = new Date().getTime();
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.number = 1;
      this.getSearch(1);
    },
    handleCurrentChange(val) {
      this.queryForm.number = val;
      this.getSearch(1);
    },
    // 获取最近一星期时间
    getAWeek() {
      // 默认最近一周时间
      return {
        endDate: dayjs().format('YYYY-MM-DD'),
        startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD')
      };
    },
    // 设置申报数量
    setDeclaredCount() {
      if (this.DeclaredQuantity) {
        const data = {
          batchCountMin: this.DeclaredQuantity
        };
        updateDeclaredCount(data).then(res => {
          if (res.status === 'OK') {
            this.brazilianPostDialog = false;
            this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
          }
        });
      } else {
        this.$message.error(this.$t('GlobalSetObj.pleaseInput'));
      }
    }
  }
};
</script>
<style scoped>
.searchInputCon .el-form-item--small.el-form-item,
.searchInputCon .el-form-item--small.el-form-item {
  margin-bottom: 5px;
}
.app-container >>> .el-dialog__body {
  padding: 10px 20px;
}

.multipleQuery ::v-deep .el-form-item__content {
  margin-left: 0 !important;
}

.btnIcon {
  padding: 6px;
}

.searchMultiple {
  display: flex;
}

.searchMultiple .el-popover__reference-wrapper .btnIcon {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 40px;
}

.app-container >>> .searchMultiple .el-input__inner {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.app-container >>> .searchMultiple .el-input-group__prepend {
  background-color: transparent !important;
}

.searchBtn {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  width: 40px;
  height: 40px;
  padding: 7px;
}

.searchTip {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 15px;
  height: 28px;
  cursor: pointer;
}

.searchNumWarp {
  height: 28px;
  border: 1px solid #91B8FA;
  border-right: none;
  padding: 5px 5px 5px 5px;
  background: rgba(145, 184, 250, 0.2);
}

.searchNumWarp p {
  margin: 0;
}

.app-container >>> .drawerClass header {
  border-bottom: 1px solid #ccc !important;
  margin-bottom: 0 !important;
  padding: 20px 20px 20px !important;
}

.clearSearch {
  height: 28px;
  border: 1px solid #91B8FA;
  border-left: none;
  background: rgba(145, 184, 250, 0.2);
  cursor: pointer;
  padding-right: 5px;
  padding-top: 5px;
}

.wordBreak {
  word-break: break-all; /*word-break: normal|break-all|keep-all;在恰当的断字点进行换行,break-all 允许在单词内换行,keep-all 只能在半角空格或连字符处换行,normal 使用浏览器默认的换行规则。*/
  text-overflow: ellipsis; /*溢出文字显示省略号*/
  display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 1; /** 显示的行数 **/
  overflow: hidden; /** 隐藏超出的内容 **/
}

.app-container >>> .el-form--label-top .el-form-item__label {
  width: 100% !important;
}

.app-container >>> .selectLabel .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

.app-container >>> .selectLabel .el-input__icon {
  line-height: 28px !important;
}

.app-container >>> .packageList .el-input-group__append {
  background-color: #409EFF;
  border: 1px solid #409EFF;
  color: #fff;
}

.selectType ::v-deep.el-input--suffix .el-input__inner {
  border: none !important;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type='number'] {
  -moz-appearance: textfield !important;
}

.app-container >>> .packageList .el-pagination__total {
  margin-top: 4px;
}
</style>
