<script >
import { transferRouterService } from '@/api/transfer/transferRoute';
import getMessageError from '@/utils/getMessageError';
import { getToastContentComponent } from '@/utils/getToastContentComponent';
import { toast } from '@/utils/programaticToast';
import { ref, defineProps, onMounted, watch } from '@vue/composition-api';
import { useGetDataPackages } from '@/api/transfer/transferRoute/query';
const __sfc_main = {};
__sfc_main.props = {
  form: {
    type: Object
  },
  mockVTagsOut: {
    type: Array
  },
  handleGetVtags: {
    type: Function
  },
  vTags: {
    type: Array
  },
  baseData: {
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const handleAddVtag = (item, array) => {
    const index = array.indexOf(item);
    if (index !== -1) {
      array.splice(index, 1);
    }
  };
  const handleRemoveVTag = (item, array) => {
    if (!array.includes(item)) {
      array.push(item);
    }
  };
  const filteredVtags = ref(props.vTags);
  const getFilteredVtags = async text => {
    try {
      const response = await transferRouterService.getVtags();
      props.vTags.value = response.data;
      if (!text.trim()) {
        filteredVtags.value = props.vTags;
        return;
      }
      filteredVtags.value = response.data.filter(option => {
        return option.toLowerCase().indexOf(text.toLowerCase()) >= 0;
      });
    } catch (error) {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Ocorreu um erro',
          describe: getMessageError(error),
          typeIcon: 'error'
        }),
        closable: true,
        duration: 4000
      });
    }
  };
  return {
    props,
    handleAddVtag,
    handleRemoveVTag,
    filteredVtags,
    getFilteredVtags
  };
};
export default __sfc_main;
</script>

<template>
  <div class="vtag-form__container">
    <h1 class="header-3">V-Tags no veículo</h1>
    <ul class="vtags-items">
      <li v-for="(item, index) in form.vtag_maintained" :key="index" class="header-3 vtag__item">{{ item }}</li>
      <li v-if="form.vtag_maintained && form.vtag_maintained.length === 0">Nenhuma v-tag no veículo</li>
    </ul>

    <form class="wrapper-form" @submit.prevent="handleSubmit">
      <o-field
        label="V-tag de entrada"
        label-for="v-tag-in"
      >
        <o-inputitems
          id="v-tag-in"
          ref="inputVtagIn"
          v-model="form.vtag_in"
          placeholder="Selecione a v-tag que foi carregada"
          :data="filteredVtags"
          :allow-new="false"
          :open-on-focus="false"
          :autocomplete="true"
          :allow-autocomplete="true"
          @typing="getFilteredVtags"
        >
          <template #selected="{ items }">
            <span v-for="(item, index) in items" :key="index" class="item_selected" rounded @click="$refs.inputVtagIn.removeItem(index, $event)">
              {{ item }}
              <div style="margin-left: 5px;cursor: pointer;" @click="$refs.inputVtagIn.removeItem(index, $event)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
              </div>
            </span>
          </template>
        </o-inputitems>
      </o-field>

      <o-field
        label="V-tag de saída"
        label-for="v-tag-out"
      >
        <o-inputitems
          id="v-tag-out"
          ref="inputVtagOut"
          v-model="form.vtag_out"
          placeholder="Selecione a v-tag que foi descarregada"
          :data="form.vtag_maintained"
          :allow-new="false"
          :open-on-focus="false"
          :autocomplete="true"
          :allow-autocomplete="true"
          @add="item => handleAddVtag(item, props.form.vtag_maintained)"
          @remove="item => handleRemoveVTag(item, props.form.vtag_maintained)"
        >
          <template #selected="{ items }">
            <span v-for="(item, index) in items" :key="index" class="item_selected" rounded @click="$refs.inputVtagOut.removeItem(index, $event)">
              {{ item }}
              <div style="margin-left: 5px;cursor: pointer;" @click="$refs.inputVtagOut.removeItem(index, $event)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
              </div>
            </span>
          </template>
        </o-inputitems>
      </o-field>

    </form>

  </div>
</template>

<style scoped>

.vtag-form__container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.vtags-items {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 8px;
  border-radius: 6px;
  margin: 8px 0 8px 0;
  background-color: hsl(213, 18%, 90%);
  color: hsl(var(--o-primary));
}

.vtag__item {
border: solid 1px hsl(var(--o-primary));
background-color: white;
border-radius: 6px;
padding: 4px 6px;
}
.header-3 {
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 600;
}
</style>
