import i18n from '@/lang';

export const QUERY_NAME_ACCOUNT_PARAMS_OBJ = Object.freeze({
  username: {
    label: i18n.t('customerManagements.account'), // 账号
    value: 'username'
  },
  realName: {
    label: i18n.t('customerManagements.realName'), // 真实姓名
    value: 'realName'
  }

});

// 账号类型
export const ACCOUNT_TYPE_OBJ = Object.freeze({
  1: {
    label: 'AJD',
    value: 1
  },
  2: {
    label: 'DSP',
    value: 2
  }
});
