var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        {
          staticClass: "searchInputCon",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-position": "top", size: "small" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 15 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "searchType" } },
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "div",
                              { staticClass: "selectLabel" },
                              [
                                _c(
                                  "el-select",
                                  {
                                    style: _vm.IsWidth,
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "GlobalSetObj.pleaseChoose"
                                      ),
                                    },
                                    on: { change: _vm.SwitchType },
                                    model: {
                                      value: _vm.queryForm.params.searchType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryForm.params,
                                          "searchType",
                                          $$v
                                        )
                                      },
                                      expression: "queryForm.params.searchType",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      key: "1",
                                      attrs: {
                                        label: _vm.$t(
                                          "GlobalSetObj.totalSingNumber"
                                        ),
                                        value: "instoreBigBagNo",
                                      },
                                    }),
                                    _c("el-option", {
                                      key: "2",
                                      attrs: {
                                        label: _vm.$t("GlobalSetObj.bagNumber"),
                                        value: "bigBagNos",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "operationCenter.Multiple200"
                              ),
                              clearable: "",
                              type: "textarea",
                              rows: 8,
                            },
                            model: {
                              value: _vm.queryForm.params.inputValue,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm.params,
                                  "inputValue",
                                  $$v
                                )
                              },
                              expression: "queryForm.params.inputValue",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 17 } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 15 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("GlobalSetObj.fuwushang"),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "GlobalSetObj.pleaseChoose"
                                        ),
                                        clearable: "",
                                        filterable: "",
                                      },
                                      model: {
                                        value: _vm.queryForm.params.systemCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "systemCode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.systemCode",
                                      },
                                    },
                                    _vm._l(
                                      _vm.channelVendorsList,
                                      function (item, ind) {
                                        return _c("el-option", {
                                          key: ind,
                                          attrs: {
                                            label:
                                              item.name +
                                              "（" +
                                              item.code +
                                              "）",
                                            value: item.code,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "GlobalSetObj.DeliveryOutlets"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "spareNumberPoo.pleaseChoose"
                                        ),
                                        clearable: "",
                                        filterable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.queryForm.params
                                            .deliveryStationId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "deliveryStationId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.deliveryStationId",
                                      },
                                    },
                                    _vm._l(_vm.outletsList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label:
                                            item.name + "（" + item.code + "）",
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("GlobalSetObj.status"),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: _vm.$t("GlobalSetObj.all"),
                                        clearable: "",
                                        filterable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.queryForm.params.assemblyStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "assemblyStatus",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.assemblyStatus",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        key: "0",
                                        attrs: {
                                          label: _vm.$t("GlobalSetObj.all"),
                                          value: null,
                                        },
                                      }),
                                      _c("el-option", {
                                        key: "1",
                                        attrs: {
                                          label: _vm.$t(
                                            "operationCenter.Assembled"
                                          ),
                                          value: 1,
                                        },
                                      }),
                                      _c("el-option", {
                                        key: "2",
                                        attrs: {
                                          label: _vm.$t(
                                            "operationCenter.assemblyCompleted"
                                          ),
                                          value: 2,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 15 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("operationCenter.assembler"),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        filterable: "",
                                        clearable: "",
                                        placeholder: _vm.$t(
                                          "spareNumberPoo.pleaseChoose"
                                        ),
                                      },
                                      model: {
                                        value:
                                          _vm.queryForm.params.assemblyUser,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "assemblyUser",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.assemblyUser",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        key: "all",
                                        attrs: {
                                          label: _vm.$t("GlobalSetObj.all"),
                                          value: null,
                                        },
                                      }),
                                      _vm._l(
                                        _vm.assemblerList,
                                        function (item, ind) {
                                          return _c("el-option", {
                                            key: ind,
                                            attrs: {
                                              label:
                                                item.realName +
                                                "（" +
                                                item.username +
                                                "）",
                                              value: item.id,
                                            },
                                          })
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 16 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "operationCenter.AssemblyTime"
                                    ),
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "datetimerange",
                                      "range-separator":
                                        _vm.$t("operationCenter.to"),
                                      "start-placeholder": _vm.$t(
                                        "operationCenter.startDate"
                                      ),
                                      "end-placeholder": _vm.$t(
                                        "operationCenter.endDate"
                                      ),
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      "default-time": ["00:00:00", "23:59:59"],
                                      "picker-options": _vm.pickerOptions,
                                    },
                                    on: { blur: _vm.refreshDate },
                                    model: {
                                      value: _vm.assembleDate,
                                      callback: function ($$v) {
                                        _vm.assembleDate = $$v
                                      },
                                      expression: "assembleDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 15 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "operationCenter.AssembleTheFinishedPerson"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        filterable: "",
                                        clearable: "",
                                        placeholder: _vm.$t(
                                          "spareNumberPoo.pleaseChoose"
                                        ),
                                      },
                                      model: {
                                        value:
                                          _vm.queryForm.params
                                            .assemblyCompletedUser,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "assemblyCompletedUser",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.assemblyCompletedUser",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        key: "all",
                                        attrs: {
                                          label: _vm.$t("GlobalSetObj.all"),
                                          value: null,
                                        },
                                      }),
                                      _vm._l(
                                        _vm.assemblyCompletedList,
                                        function (item, ind) {
                                          return _c("el-option", {
                                            key: ind,
                                            attrs: {
                                              label:
                                                item.realName +
                                                (item.username
                                                  ? "（" + item.username + "）"
                                                  : ""),
                                              value: item.id,
                                            },
                                          })
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 16 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "operationCenter.assemblyCompletionTime"
                                    ),
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "datetimerange",
                                      "range-separator":
                                        _vm.$t("operationCenter.to"),
                                      "start-placeholder": _vm.$t(
                                        "operationCenter.startDate"
                                      ),
                                      "end-placeholder": _vm.$t(
                                        "operationCenter.endDate"
                                      ),
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      "default-time": ["00:00:00", "23:59:59"],
                                      "picker-options": _vm.pickerOptions,
                                    },
                                    on: { blur: _vm.refreshDate },
                                    model: {
                                      value: _vm.assemblyCompletedDate,
                                      callback: function ($$v) {
                                        _vm.assemblyCompletedDate = $$v
                                      },
                                      expression: "assemblyCompletedDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 1 } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          float: "right",
                          cursor: "pointer",
                          "font-size": "21px",
                        },
                        on: { click: _vm.setBrazilianPost },
                      },
                      [
                        _c(
                          "el-tooltip",
                          { attrs: { placement: "top-start" } },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("GlobalSetObj.QuantitySetting"))
                                ),
                              ]
                            ),
                            _c("i", { staticClass: "el-icon-s-tools" }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "10px" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        icon: "el-icon-search",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.getSearch(1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("operationCenter.Procurar")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", icon: "el-icon-refresh-left" },
                      on: {
                        click: function ($event) {
                          return _vm.getSearch(2)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("operationCenter.reset")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.exportList()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("operationCenter.exports")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("AnjunTableList", {
        ref: "anjunTable",
        attrs: {
          "format-time": _vm.isTormat,
          "has-operation": true,
          "header-list": _vm.tableHeaderList,
          "is-select-btn": true,
          "is-tip": true,
          "table-data": _vm.tableData,
        },
        on: {
          packageList: _vm.packageList,
          selectTable: _vm.selectTable,
          "on-PrintList": _vm.OnPrintList,
          "to-OrderDetail": _vm.toOrderDetail,
        },
      }),
      _c("el-pagination", {
        staticStyle: { margin: "10px 0", float: "right" },
        attrs: {
          "current-page": _vm.queryForm.number,
          "page-size": _vm.queryForm.pageSize,
          "page-sizes": [10, 20, 50, 100],
          total: _vm.queryForm.total,
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            visible: _vm.brazilianPostDialog,
            width: "25%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.brazilianPostDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "15px", "font-size": "16px" } },
            [_vm._v(_vm._s(_vm.$t("GlobalSetObj.mindeclaredQuantity")))]
          ),
          _c(
            "div",
            [
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: {
                  placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                  clearable: "",
                  type: "number",
                },
                model: {
                  value: _vm.DeclaredQuantity,
                  callback: function ($$v) {
                    _vm.DeclaredQuantity = $$v
                  },
                  expression: "DeclaredQuantity",
                },
              }),
            ],
            1
          ),
          _c("p", { staticStyle: { color: "#a4a7ab" } }, [
            _vm._v(_vm._s(_vm.$t("GlobalSetObj.BrazilianPostGenerter"))),
          ]),
          _c("p", { staticStyle: { color: "#a4a7ab" } }, [
            _vm._v(_vm._s(_vm.$t("GlobalSetObj.PLPDeclaration"))),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.brazilianPostDialog = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.setDeclaredCount },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Salve")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("GlobalSetObj.LargePackageInside"),
            visible: _vm.showPackageList,
            top: "1vh",
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPackageList = $event
            },
            close: function ($event) {
              _vm.searchNumber = ""
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "packageList" },
            [
              _c(
                "div",
                {
                  staticClass: "selectType",
                  staticStyle: { "margin-bottom": "10px" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "390px" },
                      attrs: {
                        placeholder: _vm.$t("orderDetails.pleaseChoose"),
                      },
                      model: {
                        value: _vm.searchNumberType,
                        callback: function ($$v) {
                          _vm.searchNumberType = $$v
                        },
                        expression: "searchNumberType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label: _vm.$t("GlobalSetObj.bagNumber"),
                          value: "3",
                        },
                      }),
                      _c("el-option", {
                        attrs: {
                          label:
                            "" +
                            (_vm.$t("newOrder.waybillNumber") +
                              "/" +
                              _vm.$t("GlobalSetObj.serviceLogistics")),
                          value: "1",
                        },
                      }),
                      _c("el-option", {
                        attrs: {
                          label: _vm.$t("GlobalSetObj.client"),
                          value: "2",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-input",
                {
                  staticStyle: { width: "50%" },
                  attrs: {
                    placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                    clearable: "",
                  },
                  model: {
                    value: _vm.searchNumber,
                    callback: function ($$v) {
                      _vm.searchNumber = $$v
                    },
                    expression: "searchNumber",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      type: "primary",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.searchPackageList()
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c("AnjunTableList", {
                    ref: "PackageList",
                    attrs: {
                      "format-time": _vm.isTormat,
                      "has-operation": false,
                      "header-list": _vm.packageTableHeaderList,
                      "is-select-btn": false,
                      "is-tip": true,
                      "table-data": _vm.packageListTableData,
                    },
                  }),
                  _c("el-pagination", {
                    staticStyle: { margin: "10px 0", "text-align": "right" },
                    attrs: {
                      "current-page": _vm.packageListForm.pageNumber,
                      "page-size": _vm.packageListForm.pageSize,
                      "page-sizes": [10, 20, 50, 100],
                      "pager-count": 5,
                      total: _vm.packageListForm.total,
                      background: "",
                      layout: "total, sizes, prev, pager, next",
                      small: "",
                    },
                    on: {
                      "size-change": _vm.packageListSizeChange,
                      "current-change": _vm.packageListCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            "append-to-body": true,
            title: _vm.$t("GlobalSetObj.TotalOrderDetails"),
            visible: _vm.SummaryDetailsDrawer,
            "custom-class": "drawerClass",
            direction: "ltr",
            size: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.SummaryDetailsDrawer = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { height: "calc(100vh - 67px)", overflow: "auto" } },
            [
              _c("SummaryDetails", {
                key: _vm.detailIdKey,
                attrs: {
                  "detail-id": _vm.detailId,
                  "total-single-object": _vm.totalSingleObject,
                },
                on: {
                  exportClose: _vm.exportClose,
                  updateTable: _vm.updateTable,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticStyle: { display: "none" } },
        [_c("pdf", { ref: "pdfPrint", attrs: { src: _vm.viewUrl } })],
        1
      ),
      _c(
        "div",
        { staticStyle: { display: "none" } },
        [
          _vm.showDetailListHtmlHtml
            ? _c("detailListHtml", {
                attrs: { "param-data": _vm.printDetailObj },
                on: { hiddenHtml: _vm.hiddenHtml },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }