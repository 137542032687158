<script >
import { defineProps } from '@vue/composition-api';
import { useVuelidate } from '@vuelidate/core';
import { FormActions, FormActionsNext, FormActionsPrevious } from '@/components/wizard-form/formStepActions';
import { required, numeric } from '@/utils/validationErrors';
import i18n from '@/lang';
const __sfc_main = {};
__sfc_main.props = {
  handleNext: {
    type: Function
  },
  isLastStep: {
    type: Boolean
  },
  isFirstStep: {
    type: Boolean
  },
  handlePrevious: {
    type: Function
  },
  formData: {
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const rules = {
    capacityKg: {
      numeric,
      required
    },
    capacityM3: {
      numeric,
      required
    },
    bodyType: {
      required
    },
    weight: {
      numeric,
      required
    }
  };
  const v$ = useVuelidate(rules, props.formData);
  async function handleSubmit() {
    const isValid = await v$.value.$validate();
    if (isValid) props.handleNext();
  }
  const optionsBodyType = [{
    value: 'NOT_APPLICABLE',
    label: i18n.t('trailer.notApplicable')
  }, {
    value: 'CLOSED_CHEST',
    label: i18n.t('trailer.closedChest')
  }, {
    value: 'OPEN',
    label: i18n.t('trailer.open')
  }, {
    value: 'BULK',
    label: i18n.t('trailer.bulk')
  }, {
    value: 'CONTAINER_DOOR',
    label: i18n.t('trailer.containerDoor')
  }, {
    value: 'SIDER',
    label: i18n.t('trailer.sider')
  }];
  const optionsWheelsetType = [{
    value: 'TRUCK',
    label: i18n.t('trailer.truck')
  }, {
    value: 'STUMP',
    label: i18n.t('trailer.stump')
  }, {
    value: 'MECHANICAL_HORSE',
    label: i18n.t('trailer.mechanicalHorse')
  }, {
    value: 'VAN',
    label: i18n.t('trailer.van')
  }, {
    value: 'UTILITY',
    label: i18n.t('trailer.utility')
  }, {
    value: 'OTHERS',
    label: i18n.t('trailer.others')
  }];
  return {
    v$,
    handleSubmit,
    optionsBodyType
  };
};
__sfc_main.components = Object.assign({
  FormActions,
  FormActionsPrevious,
  FormActionsNext
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>

  <form class="wrapper-form" @submit.prevent="handleSubmit">

    <o-field
      :label="$t('trailer.capacityKg')"
      label-for="capacityKg"
      :message="v$.capacityKg.$errors.length ? v$.capacityKg.$errors[0].$message : null"
    >
      <o-input
        id="capacityKg"
        v-model="formData.capacityKg"
        :placeholder="$t('trailer.capacityKgPlaceholder')"
      />
    </o-field>

    <o-field
      :label="$t('trailer.capacityM3')"
      label-for="capacityM3"
      :message="v$.capacityM3.$errors.length ? v$.capacityM3.$errors[0].$message : null"
    >
      <o-input
        id="capacityM3"
        v-model="formData.capacityM3"
        :placeholder="$t('trailer.capacityM3Placeholder')"
      />
    </o-field>

    <o-field
      :label="$t('trailer.bodyType')"
      label-for="bodyType"
      :message="v$.bodyType.$errors.length ? v$.bodyType.$errors[0].$message : null"
    >
      <o-select id="bodyType" v-model="formData.bodyType" :placeholder="$t('trailer.bodyTypePlaceholder')">
        <option v-for="bodyType in optionsBodyType" :key="bodyType.value" :value="bodyType.value"> {{ bodyType.label }} </option>
      </o-select>
    </o-field>

    <o-field
      :label="$t('trailer.weight')"
      label-for="weight"
      :message="v$.weight.$errors.length ? v$.weight.$errors[0].$message : null"
    >
      <o-input
        id="weight"
        v-model="formData.weight"
        :placeholder="$t('trailer.weightPlaceholder')"
      />
    </o-field>

    <FormActions>
      <FormActionsPrevious
        :is-first-step="isFirstStep"
        @handlePrevious="handlePrevious"
      />

      <FormActionsNext
        :is-last-step="isLastStep"
      />
    </FormActions>
  </form>

</template>

<style scoped>
  .wrapper-form {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem/* 16px */;
    padding-top: 0.5rem/* 8px */;
    padding-bottom: 0.5rem/* 8px */;
  }
</style>
