<!--
 * @Author: 肖强
 * @Date: 2023-11-15 11:19:27
 * @LastEditTime: 2023-11-22 15:37:38
 * @LastEditors: 肖强
 * @Description:
 * @FilePath: \anjun-express-web-management\src\views\route\TrunkCondition.vue
-->
<template>
  <div class="tabClass">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :key="0" :label="$t('GlobalSetObj.bagNumber')" name="TrunkStatus">
        <TrunkStatus v-if="isShow" @setTagNumber="setTagNumber" />
      </el-tab-pane>
      <el-tab-pane :key="1" :label="$t('GlobalSetObj.NIV')" name="CarSignature">
        <CarSignature v-show="isShow1" ref="CarSignature" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  name: 'TrunkCondition',
  components: {
    'TrunkStatus': () => import('./com/TrunkStatus'),
    'CarSignature': () => import('./com/carSignature')
  },
  data() {
    return {
      activeName: 'TrunkStatus',
      isShow: true,
      isShow1: false
    };
  },
  created() {
  },
  methods: {
    handleClick(tab, event) {
      if (tab.name === 'TrunkStatus') {
        this.isShow1 = false;
        this.isShow = true;
      }
      if (tab.name === 'CarSignature') {
        this.isShow = false;
        this.isShow1 = true;
      }
    },
    /**
     * @description: 设置车签号
     * @param {string} val 车签号
     */
    setTagNumber(val) {
      this.activeName = 'CarSignature';
      this.handleClick({ name: 'CarSignature' });
      this.$nextTick(() => {
        this.$refs.CarSignature.setGetData(val);
      });
    }
  }
};
</script>

<style scoped>

.tabClass {
  padding: 0 10px 10px 10px;
}
</style>
