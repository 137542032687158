import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query';

import { getSkipPage } from '@/utils/getSkipPage';
import { baseService } from '.';
import getMessageError from '@/utils/getMessageError';
import { toast } from '@/utils/programaticToast';
import { getToastContentComponent } from '@/utils/getToastContentComponent';

export function useGetAllBases(queryParams) {
  return useQuery({
    queryKey: ['bases', queryParams],
    queryFn: async() => {
      try {
        const { document, name, currentPage, perPage } = queryParams.value;
        const skip = getSkipPage(currentPage);
        const params = {
          name,
          document
        };
        const res = await baseService.getAll(skip, perPage, params);

        return res;
      } catch (error) {
        if (error.response && error.response.status === 400) {
          return [];
        }

        throw error;
      }
    }
  });
}

export function useEditBase() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ newBase, id }) => baseService.edit(newBase, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['bases'] });
      toast.open({
        variant: 'success',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Editado com sucesso', describe: 'Base editado com sucesso em nosso sistema', typeIcon: 'success' }),
        closable: true,
        duration: 4000
      });
    },
    onError: (error) => {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Ocorreu um erro', describe: getMessageError(error), typeIcon: 'error' }),
        closable: true,
        duration: 4000
      });
    }
  });
}

export function useCreateBase() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (newBase) => baseService.create(newBase),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['bases'] });
      toast.open({
        variant: 'success',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Criado com sucesso', describe: 'Base criada com sucesso em nosso sistema', typeIcon: 'success' }),
        closable: true,
        duration: 4000
      });
    },
    onError: (error) => {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Ocorreu um erro', describe: getMessageError(error), typeIcon: 'error' }),
        closable: true,
        duration: 4000
      });
    }
  });
}
