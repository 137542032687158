var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleSMS },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("Resource.SMSConfiguration"),
                name: "smsConfig",
              },
            },
            [_c("sms-config", { ref: "smsConfig" })],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("Resource.Templateconfiguration"),
                name: "template",
              },
            },
            [_c("sms-template", { ref: "templateConfig" })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }