var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-download",
                type: "primary",
                size: "small",
              },
              on: {
                click: function ($event) {
                  return _vm.downloadTemplate(1)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("basicData.DownloadTheTemplate")))]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c("upload-excel", {
            attrs: {
              "on-success": _vm.importLogistics,
              "but-name": _vm.fileName,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("basicData.tips"),
            visible: _vm.importDialogShow,
            "append-to-body": true,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.importDialogShow = $event
            },
          },
        },
        [
          _c(
            "div",
            _vm._l(_vm.errData, function (item, ind) {
              return _c(
                "div",
                {
                  key: ind,
                  staticStyle: { color: "red", "margin-bottom": "10px" },
                },
                [_vm._v(_vm._s(item))]
              )
            }),
            0
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.importDialogShow = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("basicData.determine")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }