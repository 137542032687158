<template>
  <div class="boxs">
    <el-timeline :reverse="true">
      <template v-for="item of list">
        <el-timeline-item :key="item.id" :timestamp="item.newTime" placement="top">
          <!-- <el-button :type="item.statusType" size="small" round class="mb">{{ item.status }}</el-button> -->
          <el-button
            v-if="item.followUpStatus === 0" type="primary" size="small"
            round class="mb"
          >{{ $t('WorkCentreTable.NormalFollowUp') }}</el-button>
          <el-button
            v-else-if="item.followUpStatus === 1" type="success" size="small"
            round class="mb"
          >{{ $t('WorkCentreTable.FinishTask') }}</el-button>
          <el-button
            v-else type="danger" size="small"
            round class="mb"
          >{{ $t('WorkCentreTable.VoidTask') }}</el-button>
          <el-card>
            <div class="comment-class">
              <span class="comment-content-class" @click.stop="getImgSrc" v-html="item.content" />
              <!-- 执行人： -->
              <p v-if="item.followerStr.length !== 0" class="follower">{{ $t('WorkCentreTable.executeUserName') }}： {{ item.followerStr }}</p>
              <!-- 提交于 -->
              <p class="row">{{ item.createUser }} {{ $t('WorkCentreTable.SubmittedOn') }} {{ item.newTime }}</p>
            </div>
          </el-card>
          <el-card v-if="item.contentTranslate.length !== 0">
            <!-- 工单内容 翻译 -->
            <el-divider content-position="left">{{ $t('WorkCentreTable.ContentTranslation') }}</el-divider>
            <div class="content" v-html="item.contentTranslate" />
          </el-card>
        </el-timeline-item>
      </template>
    </el-timeline>
    <!-- 暂无内容 -->
    <div v-if="list.length === 0" class="no_date"><p>{{ $t('WorkCentreTable.NoContent') }}</p></div>

    <!-- 图片预览 -->
    <el-image-viewer
      v-show="showViewer"
      :on-close="closeViewer"
      :url-list="imgArr"
      :z-index="9999"
    />
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
export default {
  components: {
    ElImageViewer
  },

  props: {
    list: {
      type: Array,
      default: function() {
        return [];
      }
    },

    // 高度
    height: {
      type: String,
      default: '10vh'
    }
  },

  data() {
    return {
      showViewer: false,
      // imgArr: ['https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg']
      imgArr: []
    };
  },

  methods: {
    getImgSrc(e) {
      console.log(ElImageViewer);
      console.log(e.target.nodeName);
      if (e.target.nodeName !== 'IMG') { return; }
      this.imgArr = [e.target.src];
      this.showViewer = true;
    },

    // 关闭查看器
    closeViewer() {
      this.showViewer = false;
    }
  }
};
</script>

<style scoped>
.boxs {
  /* margin-top: 20px; */
  /* width: 45%; */
  /* border: 1px dashed #bdbaba; */
  padding: 20px 20px 20px 0;
  box-sizing: border-box;
  /* overflow-y: scroll; */
  /* max-height: 500px; */
}

.boxs .row {
  color: #303133a2;
}

.no_date {
  text-align: center;
  color: #9b9999;
}

.comment-content-class {
  font-size: medium;
  white-space: break-spaces;
}

/* 控制富文本的内容 图片 大小 */
.comment-content-class /deep/ img {
  max-width: 500px;
  height: 100%;
}

.comment-class {
  word-break:break-all;
}

.per-up{
  position: relative;
}
.per-up .myimg {
  width: 100px;
  height: 100px;
}
.per-up .myimg el-image__inner {
  visibility: hidden ;
}

.box /deep/ .el-icon-circle-close {
  color: white;
}

.mb {
  margin-bottom: 10px;
}

.follower {
  color: teal;
}
</style>
