import { Message } from 'element-ui';
import $i18n from '../lang/index';

export const copyData = function(data) {
  const input = document.createElement('input');
  input.value = data;
  document.body.appendChild(input);
  input.select();
  document.execCommand('Copy');
  document.body.removeChild(input);
  Message.success($i18n.t('orderCenterCont.ReplicationSucceeded')); // '复制成功'
};
