var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-form",
            {
              ref: "queryForm",
              staticClass: "queryFormClass",
              attrs: {
                model: _vm.queryForm,
                "label-position": "top",
                size: "small",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.SubordinateSupplier"),
                            prop: "providerId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.providerInfoChange()
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.providerId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "providerId",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.providerId",
                              },
                            },
                            _vm._l(_vm.providerList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label:
                                    item.providerBody +
                                    "（" +
                                    item.providerName +
                                    "）(" +
                                    item.code +
                                    ")",
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.DeliveryPoint"),
                            prop: "sendSiteId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                                filterable: "",
                                clearable: "",
                              },
                              model: {
                                value: _vm.queryForm.params.sendSiteId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "sendSiteId",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.sendSiteId",
                              },
                            },
                            _vm._l(_vm.outletsList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.name + "（" + item.code + "）",
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.state"),
                            prop: "stateId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                clearable: "",
                                filterable: "",
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.changeState(
                                    _vm.queryForm.params.stateId
                                  )
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.stateId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm.params, "stateId", $$v)
                                },
                                expression: "queryForm.params.stateId",
                              },
                            },
                            _vm._l(_vm.allState, function (item, ind) {
                              return _c("el-option", {
                                key: ind,
                                attrs: {
                                  label: item.twoCode + "(" + item.name + ")",
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.city"),
                            prop: "cityId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                              },
                              model: {
                                value: _vm.queryForm.params.cityId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm.params, "cityId", $$v)
                                },
                                expression: "queryForm.params.cityId",
                              },
                            },
                            _vm._l(_vm.allCity, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { type: "flex", justify: "start" } },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c(
                  "div",
                  { staticStyle: { "margin-top": "10px" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          icon: "el-icon-search",
                          size: "mini",
                        },
                        on: { click: _vm.getDataSearch },
                      },
                      [_vm._v(_vm._s(_vm.$t("basicData.Search")) + " ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { icon: "el-icon-refresh", size: "mini" },
                        on: { click: _vm.queryFormReset },
                      },
                      [_vm._v(_vm._s(_vm.$t("basicData.reset")) + " ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:finance:dispatchReferenceTable:addEdit",
                      expression:
                        "'btn:finance:dispatchReferenceTable:addEdit'",
                      arg: "remove",
                    },
                  ],
                  attrs: {
                    size: "mini",
                    type: "primary",
                    icon: "el-icon-plus",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handlerOperation(1)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("basicData.Add")) + " ")]
              ),
              _c(
                "el-popover",
                { attrs: { placement: "bottom-end", trigger: "hover" } },
                [
                  _c(
                    "div",
                    [
                      _c("import-download", {
                        key: _vm.exportKey,
                        attrs: {
                          "provider-list": _vm.providerList,
                          "all-state": _vm.allState,
                          "all-city": _vm.allCity,
                          "outlets-list": _vm.outletsList,
                        },
                        on: { importPartition: _vm.importPartition },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value: "btn:finance:dispatchReferenceTable:import",
                          expression:
                            "'btn:finance:dispatchReferenceTable:import'",
                          arg: "remove",
                        },
                      ],
                      staticStyle: { "margin-left": "10px" },
                      attrs: { slot: "reference", size: "mini", plain: "" },
                      slot: "reference",
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.Import")))]
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:finance:dispatchReferenceTable:export",
                      expression: "'btn:finance:dispatchReferenceTable:export'",
                      arg: "remove",
                    },
                  ],
                  staticStyle: { "margin-left": "10px" },
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.exportData()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.export")))]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticClass: "table-height",
              attrs: {
                data: _vm.tableData,
                border: "",
                height: _vm.tableHeightRow.height,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("basicData.serialNumber"),
                  type: "index",
                  width: "60",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.queryForm.pageNumber - 1) *
                                _vm.queryForm.pageSize +
                                scope.$index +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.DeliveryPoint"),
                  prop: "name",
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.sendSiteName))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "sendDistance",
                  label: _vm.$t("basicData.DeliveryDistance") + "(KM)",
                  align: "center",
                  width: "160",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.sendDistance))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("basicData.deliveryPostcode"),
                  width: "180",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#409eff",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handlerOperation(2, scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.postalCode))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("CenterForSorting.StateTwoCode"),
                  prop: "state",
                  align: "center",
                  width: "160",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.city"),
                  prop: "city",
                  width: "100",
                  align: "center",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("basicData.TypeOfRisk"),
                  prop: "riskRegion",
                  width: "160",
                  align: "center",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.ifUse"),
                  prop: "applyType",
                  align: "center",
                  width: "120",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.applyType === 0
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v(_vm._s(_vm.$t("basicData.enable"))),
                            ])
                          : _vm._e(),
                        scope.row.applyType === 1
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v(_vm._s(_vm.$t("basicData.disable"))),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("basicData.RiskRatio"),
                  prop: "riskRatio",
                  width: "160",
                  align: "center",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.riskRatio) + "%")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.SubordinateSupplier"),
                  prop: "providerName",
                  width: "180",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("basicData.LastModifier"),
                  prop: "updateUser",
                  width: "160",
                  align: "center",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.userIdAndNameObj[scope.row.updateUser])
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("basicData.LastModified"),
                  prop: "updateTime",
                  width: "160",
                  align: "center",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("basicData.operate"),
                  fixed: "right",
                  width: "140",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-popconfirm",
                              {
                                directives: [
                                  {
                                    name: "permit",
                                    rawName: "v-permit:remove",
                                    value:
                                      "btn:finance:dispatchReferenceTable:delete",
                                    expression:
                                      "'btn:finance:dispatchReferenceTable:delete'",
                                    arg: "remove",
                                  },
                                ],
                                attrs: {
                                  title: _vm.$t("basicData.AreYouSureDelete"),
                                },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.asyncPartitionEdit(scope.row, 3)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "ml",
                                    staticStyle: { margin: "0 5px" },
                                    attrs: {
                                      slot: "reference",
                                      type: "danger",
                                      size: "mini",
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("basicData.Delete")))]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                staticStyle: { margin: "10px 0" },
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  "current-page": _vm.queryForm.pageNumber,
                  "page-sizes": [10, 20, 50, 100],
                  "page-size": _vm.queryForm.pageSize,
                  total: _vm.total,
                  background: "",
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageNumber", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageNumber", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageSize", $event)
                  },
                  "current-change": _vm.getCurrentData,
                  "size-change": _vm.getSizeData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title:
              _vm.editType === 1
                ? _vm.$t("basicData.Add")
                : _vm.$t("basicData.detail"),
            visible: _vm.showDetailCmp,
            direction: "ltr",
            size: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDetailCmp = $event
            },
          },
        },
        [
          _c("details-cmp", {
            key: _vm.editKey,
            staticStyle: { height: "100%" },
            attrs: {
              "edit-data": _vm.editData,
              "all-state": _vm.allState,
              "all-city": _vm.allCity,
              "provider-list": _vm.providerList,
              "outlets-list": _vm.outletsList,
            },
            on: { updatePage: _vm.updatePage },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }