<template>
  <div class="app-container box">
    <el-tabs v-model="activeName" type="border-card">
      <!-- 基本信息 -->
      <el-tab-pane :label="$t('customerManagements.basicInformation')" name="customerTab">
        <customer-cmp
          :customer-type="customerType"
          @updataAuthorizationCode="updataAuthorizationCode"
          @updataData="updataData"
        />
      </el-tab-pane>
      <template v-if="customerType.type !== 'add'">
        <!--
        <el-tab-pane label="客户渠道" name="channelTab">
          <channel-cmp
            :all-customer="allCustomer"
            :customer-arr="customerArr"
            :customer-id="customerType.id"
            :send-arr="sendArr"
            @updataData="updataData"
          />
        </el-tab-pane> -->

        <!-- 授权码 -->
        <el-tab-pane :label="$t('customerManagements.authorizationCode')" name="code">
          <authorization-code-cmp :code="authorizationCode" :customer-id="customerType.id" />
        </el-tab-pane>
        <!--
        <el-tab-pane label="客户报价" name="priceChannel">
          <price-channel
            :currency-obj="currencyObj"
            :customer-id="customerType.id"
            :all-customer="allCustomer"
            :customer-arr="customerArr"
            :customer-alias="customerType.customerAlias"
          />
        </el-tab-pane> -->
        <!--
        <el-tab-pane label="跟进说明" name="explainTab">
          <explain-cmp
            :list="trackingInformationArr"
            :ids="customerType.id"
            @updataList="asyncCustomerFollow(customerType.id)"
          /> orderCenterCont
        </el-tab-pane>
         -->
        <el-tab-pane :label="$t('newOrder.SendAddress')" name="senderAddress">
          <senderAddress :customer-id="customerType.id" />
        </el-tab-pane>
        <el-tab-pane :label="$t('orderCenterCont.TheDeliveryAddress')" name="recevieAddress">
          <receviceAddress :customer-id="customerType.id" />
        </el-tab-pane>
        <!--ftp信息-->
        <el-tab-pane :label="$t('customerManagements.FTPInformation')" name="FTPInformation">
          <FTPInformation :key="FTPKey" :customer="customerType" :detail-data="detailData" @updataData="updataData" />
        </el-tab-pane>
        <!--其他设置-->
        <el-tab-pane :label="$t('customerManagements.otherInformation')" name="otherInformation">
          <OtherInformation
            :key="otherKey"
            :customer="customerType"
            :detail-data="detailData"
            @updataData="updataData"
          />
        </el-tab-pane>
      </template>
    </el-tabs>
  </div>
</template>

<script>
import { TYPY_OBJ } from '@/enums/customerEnums';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
// import { apiListByCustomerId, apiCustomerChannellist, apiCustomerFollow } from '@/api/customer';
export default {
  components: {
    'customer-cmp': () => import('./cmp/CustomersCmp'),
    // 'channel-cmp': () => import('./cmp/ChannelsCmp'),
    // 'explain-cmp': () => import('./cmp/ExplainsCmp'),
    'authorization-code-cmp': () => import('./cmp/AuthorizationCodeCmp'),
    // 'price-channel': () => import('./cmp/PriceChannel')
    'senderAddress': () => import('./cmp/senderAddress'),
    'receviceAddress': () => import('./cmp/receviceAddress'),
    'FTPInformation': () => import('./cmp/FTPInformation'),
    'OtherInformation': () => import('./cmp/OtherInformation')
  },

  props: {
    ids: {
      type: [String, Number],
      default: ''
    },

    customerType: {
      type: Object,
      default() {
        return {
          id: '', // 客户ID
          customerAlias: '', // 客户别名
          type: 'add'
        };
      }
    },

    // 发件人
    sendArr: {
      type: Array,
      default() {
        return [];
      }
    },
    // 币种
    currencyObj: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  data() {
    return {
      activeName: 'customerTab',
      userID: '', // 当前用户id
      allCustomer: [], // 所有渠道
      customerArr: [], // 客户选择的渠道
      userIdAndNameObj: {}, // 所有客户名字
      trackingInformationArr: [], // 所有客户跟进信息
      authorizationCode: '', // 授权码
      detailData: null,
      FTPKey: new Date().getTime(),
      otherKey: new Date().getTime()
    };
  },

  watch: {
    customerType: {
      handler(newVal) {
        this.activeName = 'customerTab';

        // 触发更新
        const { type } = newVal;

        // this.asyncCustomerChannellist();
        this.trackingInformationArr = [];

        if (type === TYPY_OBJ.add.code) {
          // 新增
          this.customerArr = [];
        }
        // this.asyncCustomerFollow(id);
        // this.asyncListByCustomerId(id);
      },
      deep: true,
      immediate: true
    }
  },

  created() {
    try {
      // const { id:userID }
      this.userID = Number(this.$store.state.user.id);

      // 所有发起人
      this.userIdAndNameObj = getUserIdAndNameObj();
    } catch (error) {
      console.err(error);
    }
  },

  methods: {
    updataData(isBool = true) {
      this.$emit('updataData', isBool);
    },
    // 通过客户基本信息获取客户 授权码
    updataAuthorizationCode(code, detail) {
      this.authorizationCode = code;
      this.detailData = detail;
      this.FTPKey = new Date().getTime();
      this.otherKey = new Date().getTime();
    }
  }
};
</script>

<style scoped>
</style>
