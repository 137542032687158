var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      staticClass: "demo-ruleForm",
      attrs: {
        model: _vm.formData,
        "label-width": "0",
        size: "small",
        rules: _vm.rules,
      },
    },
    [
      _c(
        "el-table",
        { attrs: { data: _vm.formData.arr, border: "" } },
        [
          _c(
            "el-table-column",
            {
              attrs: { prop: "distanceModel", width: "200" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "arr." + scope.$index + ".distanceModel",
                            rules: _vm.rules.distanceModel,
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "customerManagements.pleaseChoose"
                                ),
                              },
                              model: {
                                value: scope.row.distanceModel,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "distanceModel", $$v)
                                },
                                expression: "scope.row.distanceModel",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "0-50KM", value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: ">50KM", value: 2 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("collectionCenter.distance"))),
                ]),
                _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
              ]),
            ],
            2
          ),
          _c(
            "el-table-column",
            {
              attrs: { prop: "standardPrice" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "arr." + scope.$index + ".standardPrice",
                            rules: _vm.rules.standardPrice,
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              precision: 3,
                              min: 0,
                              max: 999999999,
                              controls: false,
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: scope.row.standardPrice,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "standardPrice", $$v)
                              },
                              expression: "scope.row.standardPrice",
                            },
                          }),
                          _vm._v(
                            " " + _vm._s(_vm.$t("orderCenterCont.days")) + " "
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _c("div", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("basicData.BasePrice")) + "(PT003)"),
                  ]),
                  _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "el-table-column",
            {
              attrs: { prop: "overshipmentPrice" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "arr." + scope.$index + ".overshipmentPrice",
                            rules: _vm.rules.overshipmentPrice,
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              precision: 3,
                              min: 0,
                              max: 999999999,
                              controls: false,
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: scope.row.overshipmentPrice,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "overshipmentPrice", $$v)
                              },
                              expression: "scope.row.overshipmentPrice",
                            },
                          }),
                          _vm._v(" /" + _vm._s(_vm.$t("basicData.个")) + " "),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _c("div", [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("basicData.揽收商家提成")) + "(PT004)"
                    ),
                  ]),
                  _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "el-table-column",
            {
              attrs: { prop: "price" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "arr." + scope.$index + ".price",
                            rules: _vm.rules.price,
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              precision: 3,
                              min: 0,
                              max: 999999999,
                              controls: false,
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: scope.row.price,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "price", $$v)
                              },
                              expression: "scope.row.price",
                            },
                          }),
                          _vm._v(
                            " /" +
                              _vm._s(_vm.$t("collectionCenter.ticket")) +
                              " "
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("basicData.揽收包裹价格")) + "(PT013)"),
                ]),
                _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
              ]),
            ],
            2
          ),
          _c(
            "el-table-column",
            {
              attrs: { prop: "standardCargo" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-form-item",
                        [
                          _c("el-input-number", {
                            attrs: {
                              precision: 0,
                              min: 0,
                              max: 999999999,
                              controls: false,
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: scope.row.standardCargo,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "standardCargo", $$v)
                              },
                              expression: "scope.row.standardCargo",
                            },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("collectionCenter.ticket")) +
                              " "
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _c("div", [
                  _c("span", [_vm._v(_vm._s(_vm.$t("basicData.最低货量")))]),
                ]),
              ]),
            ],
            2
          ),
          _c("el-table-column", {
            attrs: { fixed: "right", label: "操作", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDel(scope.$index)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("basicData.Delete")))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }