<script >
import { ref, computed } from '@vue/composition-api';
import { default as SkeletonTable } from '@/components/skeletonTable';
import { default as WrapperHandleError } from '@/components/wrapperHandleError';
import { default as SectionHeader } from '@/components/sectionHeader';
import { default as FormCreateBank } from './form/formCreateBank';
import { default as Actions } from './actions';
import { useModal } from '@/composable/useModal';
import { useGetAllBanks } from '@/api/transfer/bank/query';

/* get all and filters */
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const params = ref({
    currentPage: 1,
    perPage: 10
  });
  const {
    data,
    isError,
    isLoading,
    isFetched,
    error
  } = useGetAllBanks(params);
  const tableData = computed(() => {
    return data.value?.data?.result ?? data.value;
  });
  const tableTotal = computed(() => {
    return data.value?.data?.activeVehicles ?? 1;
  });
  const isLoadingTable = computed(() => {
    return isLoading.value || !isFetched.value;
  });

  /* handle create vehicle */
  const {
    isOpen: isOpenModalCreate,
    handleVisibility: handleVisibilityModalCreate
  } = useModal();
  return {
    params,
    isError,
    error,
    tableData,
    tableTotal,
    isLoadingTable,
    isOpenModalCreate,
    handleVisibilityModalCreate
  };
};
__sfc_main.components = Object.assign({
  WrapperHandleError,
  SectionHeader,
  SkeletonTable,
  Actions,
  FormCreateBank
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <WrapperHandleError :error="error" :is-error="isError">
    <SectionHeader>
      <template #header>
        <h1 class="header-1">{{ $t('bank.bank') }}</h1>
        <o-button variant="primary" size="md" @click="handleVisibilityModalCreate">{{ $t('bank.add') }}</o-button>
      </template>
    </SectionHeader>

    <SkeletonTable :is-loading="isLoadingTable">
      <o-table
        :data="tableData"
        :row-key="'id'"
        bordered
        class="space-m-y-32"
        paginated
        backend-pagination
        :total="tableTotal"
        :per-page="params.perPage"
        :current-page.sync="params.currentPage"
        :pagination-simple="false"
      >
        <o-table-column v-slot="props" field="shipping_company_name" :label="$t('bank.companyName')">
          {{ props.row.shipping_company_name }}
        </o-table-column>

        <o-table-column v-slot="props" field="shipping_company_bank" :label="$t('bank.bank')">
          {{ props.row.shipping_company_bank }}
        </o-table-column>

        <o-table-column v-slot="props" field="shipping_company_bank_beneficiary" :label="$t('bank.companyBeneficiary')">
          {{ props.row.shipping_company_bank_beneficiary }}
        </o-table-column>

        <o-table-column label="" width="100">
          <template slot-scope="{row}">
            <Actions :row="row" />
          </template>
        </o-table-column>

        <template #empty>
          {{ $t('bank.noResults') }}
        </template>
      </o-table>
    </SkeletonTable>

    <o-modal :active="isOpenModalCreate" width="560" @close="handleVisibilityModalCreate">
      <div>
        <h2 class="header-2">{{ $t('bank.createTitle') }}</h2>
        <p class="paragraph">{{ $t('bank.createParagraph') }}</p>
      </div>

      <FormCreateBank
        :close-modal="handleVisibilityModalCreate"
      />
    </o-modal>
  </WrapperHandleError>
</template>
