/**
 * 判断是否为空
 * @param {*} value
 * @returns {boolean}
 */
export function isEmpty(value) {
  if (value === null || value === '' || value === undefined) {
    return true;
  }
  return false;
}
