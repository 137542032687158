<template> <!-- 商品信息 -->

  <el-table
    :data="arr"
    :height="tableHeight"
    border
  >

    <el-table-column :label="$t('newOrder.SerialNumber')" type="index" width="50" />
    <!-- 名称 -->
    <el-table-column
      :label="$t('GlobalSetObj.Name')"
      prop="name"
    />

    <!-- 数量 -->
    <el-table-column
      :label="$t('newOrder.Quantity')"
      prop="quantity"
      width="180"
    />

    <!-- 尺寸 -->
    <el-table-column
      :label="$t('newOrder.Size')"
    >
      <template slot-scope="scope">
        <div class="table-size">
          <!-- 长度 -->
          <p>{{ $t('operationCenter.length') }}  {{ scope.row.length }}</p>
          <p>{{ $t('operationCenter.width') }}  {{ scope.row.width }}</p>
          <p>{{ $t('operationCenter.height') }}  {{ scope.row.height }}</p>

        </div>
      </template>

    </el-table-column>

    <!-- 申报 -->
    <el-table-column
      :label="$t('newOrder.Declaration') + '*'"
    >
      <template slot-scope="scope">

        <!-- 申报单价 -->
        <div class="table-size">

          <p>{{ $t('orderCenterCont.DeclareUnitPrice') }}  {{ scope.row.declaredValue }}</p>
          <p>{{ $t('orderCenterCont.DeclaredWeight') }}  {{ scope.row.declaredWeight }}</p>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>

export default {
  name: '',

  props: {
    // 高度
    height: {
      type: Number,
      required: true
    },
    arr: {
      type: Array,
      required: true
    }
  }

};
</script>

<style scoped>
.table-size {
  display: flex;
  flex-direction: column;
  /* border: 1px solid #000; */
}

p {
  margin: 0;
  color: #666;
}
</style>
