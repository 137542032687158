const scripting = {
  packagesT: {
    zh: 'Packages',
    en: 'Packages',
    pu: 'Pacotes'
  },
  searchPackages: {
    zh: 'Search packages',
    en: 'Search packages',
    pu: 'Pesquisar pacotes'
  },
  address: {
    zh: 'Address',
    en: 'Address',
    pu: 'Endereço'
  },
  area: {
    zh: 'Area',
    en: 'Area',
    pu: 'Área'
  },
  city: {
    zh: 'City',
    en: 'City',
    pu: 'Cidade'
  },
  houseNumber: {
    zh: 'House number',
    en: 'House number',
    pu: 'Número'
  },
  email: {
    zh: 'Email',
    en: 'Email',
    pu: 'Email'
  },
  package: {
    zh: '包裹',
    en: 'Package',
    pu: 'Pacotes'
  },
  mobile: {
    zh: 'Mobile',
    en: 'Mobile',
    pu: 'Celular'
  },
  name: {
    zh: 'Name',
    en: 'Name',
    pu: 'Nome'
  },
  neighborhood: {
    zh: 'Neighborhood',
    en: 'Neighborhood',
    pu: 'Bairro'
  },
  phone: {
    zh: 'Phone',
    en: 'Phone',
    pu: 'Telefone'
  },
  street: {
    zh: 'Street',
    en: 'Street',
    pu: 'Rua'
  },
  generatingPackages: {
    zh: 'Generating packages',
    en: 'Generating packages',
    pu: 'Gerando pacotes'
  },
  equipment: {
    zh: 'Equipment',
    en: 'Equipment',
    pu: 'Equipamento'
  },
  zipCode: {
    zh: 'Zip code',
    en: 'Zip code',
    pu: 'CEP'
  },
  send: {
    zh: 'Send',
    en: 'Send',
    pu: 'Enviar'
  },
  successfullyCreated: {
    zh: 'Successfully Created',
    en: 'Successfully Created',
    pu: 'Criado com sucesso'
  },
  driver: {
    zh: 'Driver',
    en: 'Driver',
    pu: 'Motorista'
  },
  createDriver: {
    zh: 'Create driver',
    en: 'Create driver',
    pu: 'Criar motorista'
  },
  createOrder: {
    zh: 'Create order',
    en: 'Create order',
    pu: 'Criar ordem'
  },
  createEquipment: {
    zh: 'Create equipment',
    en: 'Create equipment',
    pu: 'Criar equipamento'
  },
  createRoute: {
    zh: 'Create route',
    en: 'Create route',
    pu: 'Criar Rota'
  },
  createVehicle: {
    zh: 'Create vehicle',
    en: 'Create vehicle',
    pu: 'Criar veículo'
  },
  action: {
    zh: 'Action',
    en: 'Action',
    pu: 'Ações'
  },
  edit: {
    zh: 'Edit',
    en: 'Edit',
    pu: 'Editar'
  },
  delete: {
    zh: 'Delete',
    en: 'Delete',
    pu: 'Excluir'
  },
  editingDriver: {
    zh: 'Editing driver',
    en: 'Editing driver',
    pu: 'Editar motorista'
  },
  editingEquipment: {
    zh: 'Editing equipment',
    en: 'Editing equipment',
    pu: 'Editar equipamento'
  },
  editingVehicle: {
    zh: 'Editing vehicle',
    en: 'Editing vehicle',
    pu: 'Editar veículo'
  },
  driverLicense: {
    zh: 'Driver license',
    en: 'Driver license',
    pu: 'CNH'
  },
  scoreLicense: {
    zh: 'Score license',
    en: 'Score license',
    pu: 'Pontuação da CNH'
  },
  scoreDriver: {
    zh: 'Driver score',
    en: 'Driver score',
    pu: 'Pontos do motorista'
  },
  licenseType: {
    zh: 'License type',
    en: 'License type',
    pu: 'Tipo da CNH'
  },
  licenseValidate: {
    zh: 'License validate',
    en: 'License validate',
    pu: 'Validade da CNH'
  },
  outsourced: {
    zh: 'Outsourced',
    en: 'Outsourced',
    pu: 'Motorista terceirizado'
  },
  creatingDriver: {
    zh: 'Creating driver',
    en: 'Creating driver',
    pu: 'Criando motorista'
  },
  creatingRoute: {
    zh: 'Creating route',
    en: 'Creating route',
    pu: 'Criando rota'
  },
  creatingOrder: {
    zh: 'Creating order',
    en: 'Creating order',
    pu: 'Criando ordem'
  },
  creatingEquipment: {
    zh: 'Creating equipment',
    en: 'Creating equipment',
    pu: 'Criando equipamento'
  },
  creatingVehicle: {
    zh: 'Creating vehicle',
    en: 'Creating vehicle',
    pu: 'Criando veículo'
  },
  driverDocument: {
    zh: 'Driver document',
    en: 'Driver document',
    pu: 'Documento do motorista'
  },
  vehicle: {
    zh: 'Vehicle',
    en: 'Vehicle',
    pu: 'Veículo'
  },
  created: {
    zh: 'Created',
    en: 'Created',
    pu: 'Criado'
  },
  licensePlate: {
    zh: 'License Plate',
    en: 'License Plate',
    pu: 'Placa do veículo'
  },
  route: {
    zh: 'Route',
    en: 'Route',
    pu: 'Rota'
  },
  deliveryStatus: {
    zh: 'Delivery status',
    en: 'Delivery status',
    pu: 'Status de entrega'
  },
  order: {
    zh: 'Order',
    en: 'Order',
    pu: 'Ordem'
  },
  customer: {
    zh: 'Customer',
    en: 'Customer',
    pu: 'Cliente'
  },
  merchant: {
    zh: 'Merchant',
    en: 'Merchant',
    pu: 'Mercante'
  },
  collectionsPoints: {
    zh: 'Collections points',
    en: 'Collections points',
    pu: 'Pontos de coleta'
  },
  deliverysPoints: {
    zh: 'Deliverys points',
    en: 'Deliverys points',
    pu: 'Pontos de entrega'
  },
  print: {
    zh: 'Print',
    en: 'Print',
    pu: 'Imprimir'
  },
  addLine: {
    zh: 'Add line',
    en: 'Add line',
    pu: 'Adionar linha'
  },
  cancel: {
    zh: 'Cancel',
    en: 'Cancel',
    pu: 'Cancelar'
  },
  documentation: {
    zh: 'Documentation',
    en: 'Documentation',
    pu: 'Documentação'
  },
  trailerPlate: {
    zh: 'Trailer plate',
    en: 'Trailer plate',
    pu: 'Placa da carreta'
  },
  model: {
    zh: 'Model',
    en: 'Model',
    pu: 'Modelo'
  },
  brand: {
    zh: 'Brand',
    en: 'Brand',
    pu: 'Marca'
  },
  capacity: {
    zh: 'Capacity',
    en: 'Capacity',
    pu: 'Capacidade'
  },
  type: {
    zh: 'Type',
    en: 'Type',
    pu: 'Tipo'
  },
  state: {
    zh: 'State',
    en: 'State',
    pu: 'Estado'
  },
  trackerCompanyCode: {
    zh: 'Tracker company code',
    en: 'Tracker company code',
    pu: 'Código da empresa de rastreio'
  },
  trackerCompany: {
    zh: 'Tracker company',
    en: 'Tracker company',
    pu: 'Empresa de rastreio'
  },
  idRoute: {
    zh: 'Id route',
    en: 'Id route',
    pu: 'Id rota'
  },
  merchantCode: {
    zh: 'Merchant code',
    en: 'Merchant code',
    pu: 'Código do mercante'
  },
  merchantName: {
    zh: 'Merchant name',
    en: 'Merchant name',
    pu: 'Nome do mercante'
  },
  collectType: {
    zh: 'Collect type',
    en: 'Collect type',
    pu: 'Tipo de coleta'
  },
  count: {
    zh: 'Count',
    en: 'Count',
    pu: 'Quantidade'
  },
  operationSuccess: {
    zh: 'Successfully performed operation!',
    en: 'Successfully performed operation!',
    pu: 'Operação feita com sucesso!'
  },
  nameDriverPlaceholder: {
    zh: 'Driver name',
    en: 'Driver name',
    pu: 'Nome do motorista'
  },
  phoneDriverPlaceholder: {
    zh: 'Driver\'s phone',
    en: 'driver\'s phone',
    pu: 'Telefone do motorista'
  },
  cpfDriverPlaceholder: {
    zh: 'Driver\'s CPF',
    en: 'driver\'s CPF',
    pu: 'CPF do motorista'
  },
  licenseDriverPlaceholder: {
    zh: 'Driver\'s license',
    en: 'driver\'s license',
    pu: 'CNH do motorista'
  },
  streetDriverPlaceholder: {
    zh: 'Driver\'s street',
    en: 'driver\'s street',
    pu: 'Rua do motorista'
  },
  houseNumberPlaceholder: {
    zh: 'House number',
    en: 'House number',
    pu: 'Número da residência'
  },
  zipCodePlaceholder: {
    zh: 'Enter zip code',
    en: 'Enter zip code',
    pu: 'Digite CEP do motorista'
  },
  pointsLicensePlaceholder: {
    zh: 'Enter the score on the license',
    en: 'Enter the score on the license',
    pu: 'Digite os pontos na CNH'
  },
  pointsDriverPlaceholder: {
    zh: 'Enter driver score',
    en: 'Enter driver score',
    pu: 'Digite os pontos do motorista'
  },
  licenseTypePlaceholder: {
    zh: 'Enter the type of license',
    en: 'Enter the type of license',
    pu: 'Digite o tipo de CNH'
  },
  validatyLicensePlaceholder: {
    zh: 'Enter the validity of the driving license',
    en: 'Enter the validity of the driving license',
    pu: 'Digite a validade da CNH'
  },
  vehicleDocumentationPlaceholder: {
    zh: 'Vehicle documentation',
    en: 'Vehicle documentation',
    pu: 'Documentação do veículo'
  },
  numberPlatePlaceholder: {
    zh: 'Enter the license plate number',
    en: 'Enter the license plate number',
    pu: 'Digite o número da placa'
  },
  numberPlateTrailerPlaceholder: {
    zh: 'Enter the license plate',
    en: 'Enter the license plate',
    pu: 'Digite a placa da carreta'
  },
  rntrcCodePlaceholder: {
    zh: 'RNTRC code',
    en: 'RNTRC code',
    pu: 'Código RNTRC'
  },
  carBrandPlaceholder: {
    zh: 'Vehicle brand',
    en: 'Vehicle brand',
    pu: 'Marca do veículo'
  },
  carModelPlaceholder: {
    zh: 'Vehicle model',
    en: 'Vehicle model',
    pu: 'Modelo do veículo'
  },
  batteryCapacityPlaceholder: {
    zh: 'Battery capacity',
    en: 'Battery capacity',
    pu: 'Capacidade de carga'
  },
  typeVehiclePlaceholder: {
    zh: 'Type vehice',
    en: 'Type vehice',
    pu: 'Tipo de veículo'
  },
  trackingCompanyPlaceholder: {
    zh: 'Enter tracking company',
    en: 'Enter tracking company',
    pu: 'Digite a empresa de rastreio'
  },
  codeTrackingCompanyPlaceholder: {
    zh: 'Enter tracking company code',
    en: 'Enter tracking company code',
    pu: 'Digite o código da empresa de rastreio'
  },
  selectedStatePlaceholder: {
    zh: 'Enter the state',
    en: 'Enter the state',
    pu: 'Digite o estado'
  },
  enterUsernamePlaceholder: {
    zh: 'Enter username',
    en: 'Enter username',
    pu: 'Digite o nome do usuário'
  },
  document: {
    zh: 'Document',
    en: 'Document',
    pu: 'Documento'
  },
  country: {
    zh: 'Country',
    en: 'Country',
    pu: 'País'
  },
  labelOutsourced: {
    zh: 'Outsourced',
    en: 'Outsourced',
    pu: 'Tercerizado'
  }

};
export {
  scripting
};
