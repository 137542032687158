<template>
  <div class="app-container">
    <div>
      <el-table
        ref="elTable"
        :data="tableData"
        border
        :height="tableHeight"
        @header-dragend="headerWidthChange"
      >
        <el-table-column :label="$t('operationCenter.ind')" width="60px" align="center">
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in currentColumns"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :align="item.fixed"
          :width="item.width"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>{{ scope.row[item.prop] }}</div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, ind) in getCurrentColumns"
          :key="ind + 100"
          :prop="item.prop"
          :label="item.label"
          :align="item.fixed"
          :width="item.width"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>{{ scope.row[item.prop] }}</div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getExamineTableData"
          @size-change="getExamineTableData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { apiPayableSiteExpenseGetPage } from '@/api/finance/receivables';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'BatchNumberDetail',
  props: {
    batchNumberData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          payableNumber: '' // 收款单号
        }
      },
      total: 0,
      tableData: [],
      currentColumns: [],
      getCurrentColumns: [],
      selectData: [],
      tableHeight: 0,
      totalFee: 0
    };
  },
  computed: {
    columnList() {
      return [
        {
          label: this.$i18n.t('basicData.all'), // '全部',
          prop: '',
          children: [
            // 业务单号
            { prop: 'waybillNumber', label: this.$i18n.t('receivablePayable.TicketNumber'), width: 160, fixed: 'left', highlight: false, disabled: true },
            // 应付主体
            { prop: 'providerName', label: this.$i18n.t('basicData.PayablePrincipal'), width: 120, fixed: 'left', highlight: false, disabled: true },
            // 应付网点
            { prop: 'siteName', label: this.$i18n.t('basicData.PayableOutlets'), width: 120, fixed: 'left', highlight: false, disabled: true },
            // 揽收人
            { prop: 'collectorsName', label: this.$i18n.t('collectionCenter.LanShouPeople'), width: 160, fixed: 'left', highlight: false, disabled: true },
            // 派送人
            { prop: 'dispatcherName', label: this.$i18n.t('collectionCenter.deliverer'), width: 120, fixed: 'left', highlight: true, disabled: true },
            // 备注
            { prop: 'remark', label: this.$i18n.t('basicData.remark'), width: 160, fixed: 'left', highlight: false, disabled: true },
            // 计费重量
            { prop: 'chargeWeight', label: this.$i18n.t('receivablePayable.BillableWeight'), width: 120, fixed: 'left', highlight: true, disabled: true },
            // 应付费用
            { prop: 'totalFee', label: this.$i18n.t('basicData.FeesPayable'), width: 120, fixed: 'left', highlight: true, disabled: true }
          ]
        }
      ];
    }
  },
  created() {
    this.createUser = getUserIdAndNameObj();
    this.tableHeight = document.body.clientHeight - 200;
    this.currentColumns = this.columnList[0].children;
    this.tableKey = new Date().getTime();
    this.queryForm.params.payableNumber = this.batchNumberData.payableNumber;
    this.getExamineTableData();
  },
  mounted() {
    // 页面加载的时候设置table的高度
    this.tableHeight = document.body.clientHeight - 200;
    // 页面大小该变的时候（缩放页面）设置table的高度（可加可不加）
    window.onresize = () => {
      this.tableHeight = document.body.clientHeight - 200;
    };
  },
  methods: {
    // 表头宽度变化
    headerWidthChange() {
      this.$nextTick(() => {
        this.$refs.elTable.doLayout();
      });
    },
    // 获取列表
    getExamineTableData() {
      const param = cloneDeep(this.queryForm);
      this.getCurrentColumns = [];
      // 获取列表数据
      apiPayableSiteExpenseGetPage(param).then(resp => {
        if (resp.status === 'OK') {
          this.tableData = resp.data;
          // this.tableData.map(item => {
          //   item.collectorsName = this.createUser[item.collectors];
          //   item.dispatcherName = this.createUser[item.dispatcher];
          // });
          this.total = resp.total;
          if (resp.data.length > 0) {
            this.getCurrentColumns = [];
            resp.columns.map(item => {
              const obj = {
                prop: item.prop,
                label: item.label.split('_')[0],
                width: 200,
                fixed: 'left',
                highlight: false
              };
              this.getCurrentColumns.push(obj);
              this.$nextTick(() => {
                this.$refs.elTable.doLayout();
                this.rowSelectFlag = true;
                this.tableData.map(list => {
                  const matchedIndex = this.selectData.findIndex(itemList => itemList.id === list.id);
                  this.$refs.elTable.toggleRowSelection(list, matchedIndex !== -1);
                });
                this.rowSelectFlag = false;
              });
            });
          }
        }
      }).catch(err => {
        console.error(err);
      });
    }
  }
};
</script>

<style scoped>
.alert {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 6px 16px;
  margin-bottom: 5px;
}
.alert p {
  margin: 0;
  padding: 0;
}
.alert .icon {
  color: #409eff;
  font-size: 24px;
}
.alert .title {
  /*margin-left: 8px;*/
  font-size: 14px;
}
.alert .num {
  font-weight: 600;
  font-size: 14px;
}
.alert .signal {
  color: #409eff;
}
</style>
