<template>
  <div class="box">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane :label="$t('newOrder.order')" name="1">
        <!-- 基础信息 -->
        <el-card style="margin-bottom: 20px;">
          <div slot="header" class="clearfix">
            <span>{{ $t('newOrder.BasicInformation') }}</span>
          </div>
          <BasicsCmp ref="claimBasicsRef" />
        </el-card>

        <!-- 关联订单 -->
        <el-card>
          <div slot="header" class="clearfix">
            <span>{{ $t('ClaimsManagement.LinkedOrder') }}</span>
          </div>
          <OrderCmp ref="claimOrderRef" :ids="ids" />
        </el-card>

      </el-tab-pane>
      <!-- 理赔流程 -->
      <el-tab-pane :label="$t('ClaimsManagement.ClaimSettlementProcess')" name="2">
        <HistoryCmp ref="claimHistoryRef" />
      </el-tab-pane>
    </el-tabs>

    <div v-if="activeName === '1'" class="footer">
      <div />
      <div>
        <!-- 取消 -->
        <el-button @click="() => onClose(false)">{{ $t('GlobalSetObj.cancel') }}</el-button>

        <!-- 关闭 -->
        <el-button v-if="isCloseBut" type="danger" @click="onAddClose">{{ $t('GlobalSetObj.close') }}</el-button>

        <!-- 提交付款申请 -->
        <el-button v-if="isSubmitBut" type="primary" @click="paymentRow.isBool = true">{{ $t('ClaimsManagement.SubmitPaymentRequest') }}</el-button>

        <!-- 审核 -->
        <el-button v-if="isExamineBut" type="primary" @click="examineRow.isBool = true">{{ $t('customerManagements.Approval') }}</el-button>

      </div>
    </div>

    <!-- 提交付款 -->
    <el-dialog
      :title="$t('GlobalSetObj.tips')"
      :visible.sync="paymentRow.isBool"
      width="30%"
      :append-to-body="true"
    >
      <PaymentCmp :ids="ids" @close="onPaymentClose" />
    </el-dialog>

    <!-- 审核 -->
    <el-dialog
      :title="$t('GlobalSetObj.tips')"
      :visible.sync="examineRow.isBool"
      width="30%"
      :append-to-body="true"
    >
      <ExamineCmp :ids="ids" @close="onExamineClose" />
    </el-dialog>

  </div>
</template>

<script>
import BasicsCmp from './BasicsCmp.vue';
import OrderCmp from './OrderCmp.vue';
import { apiClaimformIds, apiClaimformClose } from '@/api/claimsManagement/list';
import { CLAIMS_STATUS_SELECT_OBJ } from '@/views/claimsManagement/claimList/utils.js';

export default {
  name: '',

  components: {
    BasicsCmp,
    OrderCmp,
    HistoryCmp: () => import('./HistoryCmp.vue'),
    PaymentCmp: () => import('./PaymentCmp.vue'), // 付款
    ExamineCmp: () => import('./ExamineCmp.vue') // 审核
  },

  props: {
    ids: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      claimsStatus: 0, // 当前订单理赔状态
      reviewer: 0, // 当前审批人

      activeName: '1',

      // 审核
      examineRow: {
        isBool: false
      },

      // 付款
      paymentRow: {
        isBool: false
      }
    };
  },

  computed: {
    // 是否显示提交付款按钮
    isSubmitBut() {
      // 当前处于 待付款 状态
      if (this.claimsStatus === CLAIMS_STATUS_SELECT_OBJ['3'].value) {
        return true;
      }
      return false;
    },

    // 是否显示审核按钮
    isExamineBut() {
      // 当前处于 待审核 状态
      if (this.claimsStatus !== CLAIMS_STATUS_SELECT_OBJ['1'].value) {
        return false;
      }

      // 判断当前用户是不是审核人, 如果是，显示提交按钮
      if (this.reviewer !== this.$store.getters.userId) {
        return false;
      }

      return true;
    },

    // 关闭按钮
    isCloseBut() {
      // 当前处于 待审核 状态 才显示
      if (this.claimsStatus === CLAIMS_STATUS_SELECT_OBJ['1'].value) {
        return true;
      }

      // 当前处于 待付款 状态 才显示
      if (this.claimsStatus === CLAIMS_STATUS_SELECT_OBJ['3'].value) {
        return true;
      }

      return false;
    }
  },

  created() {
    this.asyncApiClaimformIds();
  },

  methods: {
    // 获取详情
    asyncApiClaimformIds() {
      apiClaimformIds(this.ids).then(res => {
        const { data, data: { claimsStatus, reviewer, claimRelatedOrdersList, claimFlows }} = res;
        this.claimsStatus = claimsStatus;
        this.reviewer = reviewer;

        // 基础信息
        this.$refs.claimBasicsRef.setFormData(data);

        // 关联订单
        this.$refs.claimOrderRef.setDataList(claimRelatedOrdersList || []);

        // 历史记录
        this.$refs.claimHistoryRef.setDataList(claimFlows || []);
      }).catch(err => {
        console.error(err);
      });
    },

    onClose(isBool) {
      this.$emit('close', isBool);
    },

    // 付款关闭
    onPaymentClose(isBool) {
      this.paymentRow.isBool = false;
      isBool && this.onClose(isBool);
    },

    // 审核关闭
    onExamineClose(isBool) {
      this.examineRow.isBool = false;
      isBool && this.onClose(isBool);
    },

    // 关闭
    onAddClose() {
      this.$confirm('确认关闭?', this.$t('GlobalSetObj.tips'), {
        confirmButtonText: this.$t('GlobalSetObj.determine'),
        cancelButtonText: this.$t('GlobalSetObj.cancel'),
        type: 'warning'
      }).then(() => {
        // this.$message({
        //   type: 'success',
        //   message: '删除成功!'
        // });
        return apiClaimformClose(this.ids);
      }).then(() => {
        // 操作成功
        this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
        this.onClose(true);
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
        console.error('取消操作');
      });
    }
  }

};
</script>

<style lang="scss" scoped>
.box {
  position: relative;
  top: 0;
  left: 0;
  padding: 0 20px;
  // height: 100vh;
  height: calc(100vh - 70px);
  overflow-y: auto;
}

.footer {
  z-index: 10;
  position: sticky;
  bottom: 20px;
  right: 20px;
  display: flex;
  width: auto;
  justify-content: space-between;
  margin: 20px 0;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  border-radius: 4px;
  border: 1px solid #ebeef5;
  background-color: #fff;
  transition: .3s;
  padding: 5px;
}

</style>
