var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-input", {
    attrs: {
      type: "textarea",
      autosize: { minRows: _vm.minRows, maxRows: _vm.maxRows },
      placeholder: _vm.placeholder,
    },
    on: { input: _vm.onInput },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v
      },
      expression: "value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }