<template>
  <div>
    <div
      v-for="(item,index) in paramTransferData"
      :id="`TransferManifest${index}`"
      :key="index"
      style="padding:10px 15px"
    >
      <div style="font-size: 16px;">
        <div style="text-align: center;">
          <div>Lista de Manifestos de transferencia</div>
          <div style="display: flex;justify-content: center">
            <img :id="`barcode${index}`">
          </div>
          <div style="font-size: 16px;">Data de impressão: {{ item.printingTime }}</div>
        </div>
        <hr>
        <div style="line-height: 30px">
          <table style="width: 100%;text-align: left;font-size: 16px;">
            <tr>
              <td>Local de partida:{{ item.origin }}</td>
              <td />
              <td />
            </tr>
            <tr>
              <td>Endereço:{{ item.beginAddress }}</td>
              <td />
              <td>Rua Destinatário:{{ "" }}</td>
            </tr>
            <tr>
              <td>Cidade de partida:{{ item.beginCity }}</td>
              <td>Estado:{{ item.beginState }}</td>
              <td>Código postal:{{ item.beginZipCode }}</td>
            </tr>
            <tr>
              <td>Pessoa responsável:{{ item.beginCommitment }}</td>
              <td />
              <td>Tempo de geração:{{ item.beginManiGeneraTime }}</td>
            </tr>
            <tr>
              <td>Destino:{{ item.destination }}</td>
              <td />
              <td>IATA CODE:{{ item.baseCode }}</td>
            </tr>
            <tr>
              <td>Empresa de transporte:{{ item.agent }}</td>
              <td />
              <td />
            </tr>
            <tr>
              <td>Endereço detalhado do destino:{{ item.endAddress }}</td>
              <td />
              <td>Vizinho:{{ "" }}</td>
            </tr>
            <tr>
              <td>Cidade:{{ item.endCity }}</td>
              <td style="width: 20%;">Estado:{{ item.endState }}</td>
              <td>Código postal:{{ item.endZipCode }}</td>
            </tr>
            <tr>
              <td>Pessoa responsável:{{ item.endCommitment }}</td>
              <td />
              <td>Data de geração da lista:{{ item.endManiGeneraTime }}</td>
            </tr>
          </table>
          <div style="margin-top: 30px">
            <table style="width: 100%;text-align: left">
              <tr>
                <th>Produtos</th>
                <th>Tipo de arquivo</th>
                <th>Volume do pacote</th>
                <th>Número de pacotes na lista</th>
                <th>Soma das pesagens das caixas de trânsito de pacotes</th>
              </tr>
              <tr>
                <td>{{ item.productName }}</td>
                <td>{{ item.documentType }}</td>
                <td>{{ item.quantityVolumes }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ item.totalWeight + 'kg' }}</td>
              </tr>
            </table>
          </div>
          <div style="margin: 20px 0">
            <hr>
            <table style="width: 100%;text-align: left">
              <tr>
                <th style="width: 20%;">Número de waybill</th>
                <th>Número da fatura do produto</th>
                <th>Número de rota</th>
                <th>Volume</th>
                <th>Estado</th>
              </tr>
              <tr v-for="(itemDetail ,indexDetail) in item.packageDetails" :key="indexDetail">
                <td>{{ itemDetail.waybillNumber }}</td>
                <td>{{ itemDetail.invoiceNumber }}</td>
                <td>{{ itemDetail.routeNumber }}</td>
                <td>{{ itemDetail.volume }}</td>
                <td>{{ itemDetail.recipientState }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import JsBarcode from 'jsbarcode';
// import { pdf } from '@/utils/html2canvas';
import html2Canvas from 'html2canvas';
import JsPDF from 'jspdf';
import PDFMerger from 'pdf-merger-js/browser';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

const logoSrc1 = require('@/assets/ajlogo1.svg');
export default {
  props: {
    paramTransferData: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      logo1: logoSrc1
    };
  },
  mounted() {
    // 手动显示加载中动画
    const loading = this.$loading({
      fullscreen: true,
      lock: true,
      spinner: 'el-icon-loading',
      text: this.$i18n.t('orderCenterCont.DesperatelyLoading'), // '拼命加载中',
      background: 'rgba(250, 250, 250, 0.6)'
    });
    const _this = this;
    const dataArr = [];
    const batchPdfDownload = async(arr) => {
      try {
        const merger = new PDFMerger();
        for (let i = 0; i < arr.length; i++) {
          await merger.add(arr[i]);
        }
        const mergedPdf = await merger.saveAsBlob();
        return await promiseFileReader(mergedPdf);
      } catch (error) {
        console.error(error);
        return '';
      }
    };
    const promiseFileReader = (blorStr) => {
      return new Promise((res, rej) => {
        const reader = new FileReader();
        reader.readAsDataURL(blorStr);
        reader.onload = function() {
          res(reader.result);
        };
        reader.onerror = function(e) {
          rej(e);
        };
      });
    };
    const fileName = 'TransferMinfisto' + dayjs().format('DDMMYYHHmmss') + _this.generateRandomNumber();
    this.$nextTick(async() => {
      this.paramTransferData.forEach((item, index) => {
        // const number = _this.generateRandomNumber();
        // const serialNumber = dayjs().format('DDMMYYHHmmss') + number;
        const elm = '#barcode' + index;
        JsBarcode(elm, item.serialNumber, {
          height: 60
        });
        const elId = 'TransferManifest' + index;
        const elHtml = document.getElementById(elId);
        html2Canvas(elHtml, {
          backgroundColor: '#FFFFFF'
        }).then(async function(canvas) {
          _this.$emit('clearTransferList');
          var contentWidth = canvas.width;
          var contentHeight = canvas.height;
          // 一页pdf显示html页面生成的canvas高度;
          var pageHeight = contentWidth / 592.28 * 841.89;
          // 未生成pdf的html页面高度
          var leftHeight = contentHeight;
          // 页面偏移
          var position = 0;
          // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
          var imgWidth = 595.28;
          var imgHeight = 592.28 / contentWidth * contentHeight;
          var pageData = canvas.toDataURL('image/jpeg', 1.0);
          // console.log(pageData, 'pageDatapageData');
          var pdf = new JsPDF('', 'pt', 'a4');
          // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
          // 当内容未超过pdf一页显示的范围，无需进行分页
          if (leftHeight < pageHeight) {
            pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
          } else {
            // 当内容超过pdf一页显示的高度范围时，进行分页
            while (leftHeight > 0) {
              pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
              // console.log('addImage', pdf);
              leftHeight -= pageHeight;
              position -= 841.89;
              // 避免添加空白页
              if (leftHeight > 0) {
                pdf.addPage();
              }
            }
          }
          const dataFile = pdf.output('dataurlstring');
          const bolb = _this.base64ToBob(dataFile);
          dataArr.push(bolb);
          // 判断是否循环完成
          if (index === _this.paramTransferData.length - 1) {
            batchPdfDownload(dataArr).then((res) => {
              saveAs(res, fileName);
              // const a = document.createElement('a');
              // a.download = fileName;
              // a.href = res;
              // a.click();
              // a.remove();
              // loading.close();
            }).catch(e => {
              // loading.close();
              console.error(e);
            }).finally(() => {
              loading.close();
            });
          }
        });
      });
    });
  },
  methods: {
    // base64 转bolb
    base64ToBob(base64Data) {
      // 去掉Base64数据URI前缀
      var base64String = base64Data.split(',')[1];
      // 将Base64字符串转换为Uint8Array
      var binaryString = atob(base64String);
      var len = binaryString.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      // 创建Blob对象
      var blobData = new Blob([bytes.buffer], { type: 'application/pdf' });
      return blobData;
    },
    // 随机生成6位数据
    generateRandomNumber() {
      // 生成一个0到999999之间的随机数
      const randomNumber = Math.floor(Math.random() * 1000000);
      // 将随机数转换为字符串
      let randomString = randomNumber.toString();
      // 如果随机数不足6位，在前面补0
      while (randomString.length < 6) {
        randomString = '0' + randomString;
      }
      return randomString;
    },
    promiseFileReader(blorStr) {
      return new Promise((res, rej) => {
        const reader = new FileReader();
        reader.readAsDataURL(blorStr);
        reader.onload = function() {
          // var dataURL = reader.result;
          res(reader.result);
        };
        reader.onerror = function(e) {
          // var dataURL = reader.result;
          rej(e);
        };
      });
    }
  }
};
</script>
