<script >
import { default as TableData } from './components/tableData.vue';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  return {};
};
__sfc_main.components = Object.assign({
  TableData
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div class="space-p-20 o-container">

    <TableData />
  </div>
</template>
