var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Box" },
    [
      _c(
        "div",
        { staticClass: "scanClass" },
        [
          _c("span", { staticClass: "span" }, [
            _vm._v(
              _vm._s(_vm.$t("GlobalSetObj.pleaseSelectLicenseNumber")) + "："
            ),
          ]),
          _c(
            "el-select",
            {
              attrs: {
                clearable: "",
                filterable: "",
                remote: "",
                "reserve-keyword": "",
                "remote-method": _vm.remoteMethod,
              },
              on: { change: _vm.vehicleMsg },
              model: {
                value: _vm.licenseNumber,
                callback: function ($$v) {
                  _vm.licenseNumber = $$v
                },
                expression: "licenseNumber",
              },
            },
            _vm._l(_vm.numberList, function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.vehicleNumber, value: item.vehicleNumber },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm.carList.vehicleNumber
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "",
                visible: _vm.dialogVisible,
                "close-on-click-modal": false,
                width: "40%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
                close: _vm.handleClose,
              },
            },
            [
              _c("div", { staticClass: "dialogClass" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("collectionCenter.carNum")) +
                      _vm._s(_vm.carList.id)
                  ),
                ]),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("collectionCenter.licensePlateNum")) +
                      _vm._s(_vm.carList.vehicleNumber)
                  ),
                ]),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("collectionCenter.operationM")) +
                      _vm._s(_vm.carList.operationModeName)
                  ),
                ]),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("collectionCenter.carT")) +
                      _vm._s(_vm.carList.vehicleTypeName)
                  ),
                ]),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("collectionCenter.carDriver")) +
                      _vm._s(_vm.carList.vehicleDriver)
                  ),
                ]),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("collectionCenter.belongToSite")) +
                      _vm._s(_vm.carList.siteIdName)
                  ),
                ]),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("collectionCenter.totalNumberBagsLoaded")) +
                      "：" +
                      _vm._s(_vm.carList.loadBagNumber)
                  ),
                ]),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("collectionCenter.carLoadingVotes")) +
                      "：" +
                      _vm._s(_vm.carList.loadPackageNumber)
                  ),
                ]),
              ]),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v(_vm._s(_vm.$t("collectionCenter.cancel"))),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.loadScanConfirm },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.confirm")))]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
        },
        [
          _c("audio", {
            ref: "errRef",
            attrs: { src: _vm.errorMp3, controls: "controls" },
          }),
          _c("audio", {
            ref: "okRef",
            attrs: { src: _vm.okMp3, controls: "controls" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }