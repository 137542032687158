<script >
import { default as ChartBarPackageHistory } from './components/chartBarPackageHistory';
import { default as ChartPieDataPackages } from './components/chartPieDataPackages';
import { default as ChartPieDataStores } from './components/chartPieDataStores';
import { default as SectionHeader } from '@/components/sectionHeader';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  return {};
};
__sfc_main.components = Object.assign({
  SectionHeader,
  ChartBarPackageHistory,
  ChartPieDataPackages,
  ChartPieDataStores
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div class="space-p-20 o-container">
    <SectionHeader class="m-b-32">
      <template #header>
        <h1 class="header-1">{{ $t('dashboard.orderDashboard') }}</h1>
      </template>
    </SectionHeader>

    <div class="container-charts">
      <ChartBarPackageHistory class="span-2" />

      <ChartPieDataPackages />

      <ChartPieDataStores />
    </div>

  </div>
</template>

<style scoped>

.m-b-32 {
  margin-bottom: 2rem; /* 32px */
}

.span-2 {
  grid-column: span 2 / span 2;
}

.container-charts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}

</style>
