<script >
import { ref, defineProps } from '@vue/composition-api';
import { useQueryClient } from '@tanstack/vue-query';
import { useWizardForm } from '@/composable/useWizardForm';
import { default as FormGroup } from './steps/formGroup';
import { default as FormEdition } from './steps/formEdition';
import getMessageError from '@/utils/getMessageError';
import { useCreateEdition } from '@/api/reverse/edition/query';
const __sfc_main = {};
__sfc_main.props = {
  closeModal: {
    type: Function
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const queryClient = useQueryClient();
  const STEPS_TOTAL = 5;
  const formData = ref({
    codGroup: '',
    listEditions: [{
      codEdition: '',
      volume: '',
      description: ''
    }, {
      codEdition: '',
      volume: '',
      description: ''
    }, {
      codEdition: '',
      volume: '',
      description: ''
    }, {
      codEdition: '',
      volume: '',
      description: ''
    }]
  });
  const {
    mutate,
    isLoading
  } = useCreateEdition();
  async function handleSubmit(formData) {
    mutate(formData.value, {
      onSuccess: () => {
        props.closeModal();
      }
    });
  }
  function handleChangeCodGroup(newCodGroup) {
    formData.value.codGroup = newCodGroup;
  }
  const {
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  } = useWizardForm(formData, handleSubmit, STEPS_TOTAL);
  return {
    formData,
    isLoading,
    handleChangeCodGroup,
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  };
};
__sfc_main.components = Object.assign({
  FormGroup,
  FormEdition
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <FormGroup
      v-show="step === 0"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      :cod-group="formData.codGroup"
      @codGroupChanged="handleChangeCodGroup"
    />

    <FormEdition
      v-for="(edition, index) in formData.listEditions"
      v-show="step === (index + 1)"
      :key="index"
      :number-edition="index+1"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      :form-data="edition"
    />

    <o-loading :full-page="true" :active.sync="isLoading" />
  </div>
</template>
