import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';

import { bankService } from '.';
import { getSkipPage } from '@/utils/getSkipPage';
import getMessageError from '@/utils/getMessageError';
import { toast } from '@/utils/programaticToast';
import { getToastContentComponent } from '@/utils/getToastContentComponent';

export function useGetAllBanks(queryParams) {
  return useQuery({
    queryKey: ['banks', queryParams],
    queryFn: async({ signal }) => {
      try {
        const { currentPage, perPage } = queryParams.value;
        const skip = getSkipPage(currentPage);
        const res = await bankService.getAll(signal, skip, perPage);

        return res;
      } catch (error) {
        if (error.response && error.response.status === 400) {
          return [];
        }

        throw error;
      }
    }
  });
}

export function useEditBank() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ newBank, id }) => bankService.edit(newBank, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['banks'] });
      toast.open({
        variant: 'success',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Editado com sucesso', describe: 'Dados bancários editado com sucesso em nosso sistema', typeIcon: 'success' }),
        closable: true,
        duration: 4000
      });
    },
    onError: (error) => {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Ocorreu um erro', describe: getMessageError(error), typeIcon: 'error' }),
        closable: true,
        duration: 4000
      });
    }
  });
}

export function useCreateBank() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (newBank) => bankService.create(newBank),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['banks'] });
      toast.open({
        variant: 'success',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Criado com sucesso', describe: 'Dados bancários criados com sucesso', typeIcon: 'success' }),
        closable: true,
        duration: 4000
      });
    },
    onError: (error) => {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Ocorreu um erro', describe: getMessageError(error), typeIcon: 'error' }),
        closable: true,
        duration: 4000
      });
    }
  });
}
