var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": true,
        title: _vm.$t("receivablePayable.tips"),
        visible: _vm.isBool,
        width: "50%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.isBool = $event
        },
      },
    },
    [
      _c("div", { staticStyle: { height: "40vh", overflow: "hidden" } }, [
        _c("div", { staticStyle: { height: "100%", overflow: "auto" } }, [
          _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "receivablePayable.ReconciliationBatchGenerationSuccessful"
                )
              )
            ),
          ]),
          _c(
            "div",
            [
              _vm._v(
                _vm._s(_vm.$t("receivablePayable.ReconciliationBatchNumber")) +
                  " "
              ),
              _vm._l(_vm.batchNumber, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticStyle: { margin: "0 10px 5px 0" } },
                  [_c("span", [_vm._v(_vm._s(item.replace("~", "\n")))])]
                )
              }),
            ],
            2
          ),
        ]),
        _c("textarea", { attrs: { id: "message5" } }),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.copyBatchNumber(1)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("basicData.Cancel")))]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.copyBatchNumber(2)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("receivablePayable.CopyTheBatchNumber")))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }