<template>
  <div class="carNumberBox">
    <div v-if="activeName == 'hz'" style="margin-bottom: 20px;" class="selectClass">
      <el-form ref="drawerFormRef" :model="drawerForm" :rules="rules">
        <el-row>
          <el-col :span="7" style="width: auto;">
            <!-- 所属站点： -->
            <el-form-item prop="siteId" :label="$t('collectionCenter.owningSite')">
              <el-select v-model="drawerForm.siteId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable filterable>
                <el-option
                  v-for="(item, index) in siteIdList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;">
            <!-- 打印数量： -->
            <el-form-item prop="printQty" :label="$t('collectionCenter.printQ')">
              <el-input v-model.trim="drawerForm.printQty" clearable :placeholder="$t('collectionCenter.pleaseInput')" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="12">
          <el-button
            v-permit:remove="'btn:collection-center:basicDate:carNumber:generate'"
            type="primary"
            size="mini"
            icon="el-icon-thumb"
            class="onlyClickOnce"
            @click="generateCode"
          >{{ $t('collectionCenter.generate') }}</el-button><!-- 生成车签号 -->
        </el-col>
      </el-row>
      <el-divider />
      <el-form>
        <el-row>
          <el-col :span="10" style="width: auto;">
            <!-- 创建时间： -->
            <el-form-item :label="$t('collectionCenter.createTime')">
              <el-date-picker
                v-model="timeValue"
                :clearable="false"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                :range-separator="$t('collectionCenter.to')"
                :start-placeholder="$t('collectionCenter.StartTime')"
                :end-placeholder="$t('collectionCenter.EndTime')"
                @change="dateChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;">
            <!-- 网点： -->
            <el-form-item :label="$t('collectionCenter.site')">
              <el-select v-model="queryForm.params.siteId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable filterable @focus="getNewestSite">
                <el-option
                  v-for="(item, index) in siteIdList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="12">
          <el-button
            v-permit:remove="'btn:collection-center:basicDate:carNumber:search'"
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
          <el-button
            v-permit:remove="'btn:collection-center:basicDate:carNumber:reset'"
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
        </el-col>
      </el-row>
      <el-divider />
    </div>
    <el-tabs v-model="activeName" type="border-card">
      <!-- 汇总 -->
      <el-tab-pane :label="$t('collectionCenter.summary')" name="hz">
        <div>
          <el-table
            ref="timeHZ"
            :max-height="600"
            border
            :data="tableDate"
          >
            <el-table-column
              type="selection"
              width="55"
            />
            <!-- 序号 -->
            <el-table-column :label="$t('collectionCenter.ind')" type="index" width="60" align="center">
              <template slot-scope="scope">
                <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="siteName"
              :label="$t('collectionCenter.belongSite')"
            /><!-- 所属站点 -->
            <el-table-column
              prop="printQty"
              align="center"
              :label="$t('collectionCenter.printQuantity')"
            ><!-- 打印数量 -->
              <template slot-scope="scope">
                <!-- 查看明细 -->
                <el-button
                  v-if="hzTomx"
                  style="white-space: normal;"
                  size="mini"
                  @click="viewDetails(scope.row)"
                >{{ scope.row.printQty }}</el-button>
                <span v-else>{{ scope.row.printQty }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="printTime"
              :label="$t('collectionCenter.printTime')"
            /><!-- 打印时间 -->
            <el-table-column
              prop="createTime"
              :label="$t('collectionCenter.CreationTime')"
            /><!-- 创建时间 -->
            <el-table-column
              prop="userName"
              :label="$t('collectionCenter.creator')"
            /><!-- 创建人 -->
            <el-table-column v-if="print" :label="$t('collectionCenter.operation')">
              <template slot-scope="scope">
                <el-button
                  :disabled="scope.row.printUrl ? true : false"
                  icon="el-icon-printer"
                  size="mini"
                  @click="printCode(scope.row)"
                >{{ $t('collectionCenter.Print') }}</el-button><!-- 打印 printUrl有值不打印，为空打印 -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="right">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryForm.pageNumber"
            :page-sizes="[10, 20, 30, 50, 100, 500, 1000]"
            :page-size.sync="queryForm.pageSize"
            :total="total"
            background
            style="margin: 10px 0"
            @current-change="getDatePage"
            @size-change="getPageSize"
          />
        </div>
      </el-tab-pane>
      <!-- 明细 -->
      <el-tab-pane v-if="activeName == 'mx'" :label="$t('collectionCenter.particulars')" name="mx">
        <div>
          <el-table
            ref="timeHZ"
            :max-height="600"
            border
            :data="tableDateMX"
          >
            <el-table-column
              type="selection"
              width="55"
            />
            <!-- 序号 -->
            <el-table-column :label="$t('collectionCenter.ind')" type="index" width="60" align="center">
              <template slot-scope="scope">
                <span>{{ (queryFormMX.pageNumber - 1) * queryFormMX.pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="tagNumber"
              :label="$t('collectionCenter.carNumber')"
            /><!-- 车签号 -->
            <el-table-column
              prop="siteName"
              :label="$t('collectionCenter.belongSite')"
            /><!-- 所属站点 -->
            <el-table-column
              prop="createTime"
              :label="$t('collectionCenter.printTime')"
            /><!-- 打印时间 -->
          </el-table>
        </div>
        <div class="right">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryFormMX.pageNumber"
            :page-sizes="[10, 20, 30, 50, 100, 500, 1000]"
            :page-size.sync="queryFormMX.pageSize"
            :total="totalMX"
            background
            style="margin: 10px 0"
            @current-change="getDatePageMX"
            @size-change="searchClickMX"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  searchSiteIdList,
  collectTagNumberPage,
  collectTagNumberDatailPage,
  printTagNumber,
  createTagNumber
} from '@/api/lanshou';
import dayjs from 'dayjs';

export default {
  name: 'CarNumber',
  data() {
    return {
      hzTomx: this.$store.getters.button_permissions.includes('btn:collection-center:basicDate:carNumber:hzTomx'),
      print: this.$store.getters.button_permissions.includes('btn:collection-center:basicDate:carNumber:print'),
      timeValue: [],
      drawerForm: {
        siteId: '', // 所属站点
        printQty: '' // 打印数量
      },
      rules: {
        siteId: [
          // '请选择所属站点'
          { required: true, message: this.$t('collectionCenter.chooseSite'), trigger: ['blur', 'change'] }
        ],
        printQty: [
          // '请输入打印数量'
          { required: true, message: this.$t('collectionCenter.inputPrintSum'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              const reg = /^[0-9]*$/;
              // this.$t('collectionCenter.pleaseInput')
              if (!reg.test(value)) {
                return callback(new Error(this.$t('collectionCenter.pleaseInputNumber'))); // '请输入正整数'
              } else if (Number(value) <= 0) {
                return callback(new Error(this.$t('collectionCenter.enterGreaterThan0'))); // '请输入大于0的数字'
              } else if (Number(value) > 100) {
                return callback(new Error(this.$t('collectionCenter.once100'))); // '最多一次可打印100张'
              } else {
                callback();
              }
            }
          }
        ]
      },
      activeName: 'hz', // hz 汇总  mx 明细
      siteIdList: [], // 站点编号列表
      queryForm: { // 汇总查询条件
        pageNumber: 1,
        pageSize: 10,
        params: {
          beginTime: '', // 开始时间
          endTime: '', // 结束时间
          siteId: '' // 站点id
        }
      },
      tableDate: [],
      total: 0,
      tableDateMX: [],
      totalMX: 0,
      queryFormMX: { // 明细查询条件
        pageNumber: 1,
        pageSize: 10,
        params: {
          tagNumberId: ''
        }
      }
    };
  },
  watch: {
    timeValue() {
      if (this.timeValue && this.timeValue.length <= 0) {
        this.queryForm.params.beginTime = '';
        this.queryForm.params.endTime = '';
      }
    }
  },
  created() {
    this.getNewestSite();
    this.default();
    this.searchClick();
  },
  methods: {
    // 获取最新的网点list数据
    getNewestSite() {
      searchSiteIdList().then(res => {
        if (res.status === 'OK') {
          res.data.forEach(data => {
            this.siteIdList.push({ id: data.id, name: data.name });
          });
        }
      });
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        this.queryForm.params.beginTime = '';
        this.queryForm.params.endTime = '';
        return;
      }
      this.queryForm.params.beginTime = val[0];
      this.queryForm.params.endTime = val[1];
      if (this.queryForm.params.endTime) {
        const endTime = dayjs(this.queryForm.params.beginTime).subtract(-1, 'year').format('YYYY-MM-DD') + ' 23:59:59';
        if (this.queryForm.params.endTime > endTime) {
          this.$message({
            // 最多可查询到一年的数据
            message: this.$t('collectionCenter.moreYear'),
            type: 'warning'
          });
          const d = new Date();
          const today = dayjs(d).format('YYYY-MM-DD');
          this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
          this.queryForm.params.beginTime = today + ' 00:00:00';
          this.queryForm.params.endTime = today + ' 23:59:59';
        }
      }
    },
    generateCode() { // 生成车签号
      this.$refs['drawerFormRef'].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          this.drawerForm.printQty = Number(this.drawerForm.printQty);
          this.drawerForm.siteId = Number(this.drawerForm.siteId);
          createTagNumber(this.drawerForm).then(res => {
            if (res.status === 'OK') {
              this.$message({
                // '车签号生成成功，可前往打印'
                message: this.$t('collectionCenter.sucToPrint'),
                type: 'success'
              });
              this.searchClick();
              this.scollerFunc();
              this.closeReset();
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              });
            }
          }).catch(err => {
            console.error(err);
          });
        }
      });
    },
    closeReset() { // 关闭弹窗
      this.drawerForm = {
        siteId: '', // 所属站点
        printQty: '' // 打印数量
      };
      setTimeout(() => {
        this.$refs['drawerFormRef'].clearValidate();
      }, 0);
    },
    // 行内打印车签号
    printCode(row) {
      const printList = {
        tagNumberId: row.id,
        siteId: row.siteId,
        siteName: row.siteName,
        printQty: row.printQty,
        printUrl: row.printUrl
      };
      // printUrl有值不打印，为空打印
      if (row.printUrl) {
        return;
      } else {
        printTagNumber(printList).then(res => {
          if (res.status === 'OK') {
            window.open(res.data);
            this.$message({
              // '打印成功'
              message: this.$t('collectionCenter.printSuc'),
              type: 'success'
            });
            this.searchClick();
          } else {
            const msg = res.data.msg || res.msg;
            this.$message.error(msg);
          }
        }).catch({});
      }
    },
    default() { // 汇总默认查询条件
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      this.queryForm.params = {
        beginTime: today + ' 00:00:00', // 开始时间
        endTime: today + ' 23:59:59', // 结束时间
        siteId: '' // 站点id
      };
    },
    getDatePage() {
      const queryForm = JSON.parse(localStorage.getItem('cnQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      collectTagNumberPage(queryForm).then(res => {
        if (res.status === 'OK') {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        }
      }).catch({});
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      const query = JSON.parse(localStorage.getItem('cnQueryForm')) || this.queryForm;
      const queryForm = {
        pageNumber: 1,
        pageSize: this.queryForm.pageSize,
        params: query.params
      };
      collectTagNumberPage(queryForm).then(res => {
        if (res.status === 'OK') {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        }
      }).catch({});
    },
    searchClick() {
      if (this.activeName === 'hz') {
        if (this.queryForm.params.beginTime && this.queryForm.params.endTime) {
          this.queryForm.pageNumber = 1;
          localStorage.setItem('cnQueryForm', JSON.stringify(this.queryForm));
          this.getDatePage();
        } else {
          this.$message({
            message: this.$t('collectionCenter.PleaseSelectTime'),
            type: 'warning'
          });
        }
      }
    },
    scollerFunc() {
      const dom = this.$refs.timeHZ.bodyWrapper;
      dom.scrollTop = 0;
    },
    resetClick() {
      this.default();
    },
    viewDetails(row) {
      this.activeName = 'mx';
      this.queryFormMX = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          tagNumberId: row.id
        }
      };
      collectTagNumberDatailPage(this.queryFormMX).then(res => {
        if (res.status === 'OK') {
          this.tableDateMX = res.data.records;
          this.totalMX = res.data.total;
        } else {
          this.tableDateMX = [];
          this.totalMX = 0;
        }
      }).catch({});
    },
    searchClickMX() {
      this.queryFormMX.pageNumber = 1;
      this.getDatePageMX();
    },
    getDatePageMX() {
      this.queryFormMX = {
        pageNumber: this.queryFormMX.pageNumber,
        pageSize: this.queryFormMX.pageSize,
        params: {
          tagNumberId: this.queryFormMX.params.tagNumberId
        }
      };
      collectTagNumberDatailPage(this.queryFormMX).then(res => {
        if (res.status === 'OK') {
          this.tableDateMX = res.data.records;
          this.totalMX = res.data.total;
        }
      }).catch({});
    }
  }
};
</script>

<style lang="scss" scoped>
  // 防止按钮重复点击
  @keyframes throttle{
    from{
      filter: opacity(40%);
      pointer-events: none;
    }
    to{
      pointer-events: all;
    }
  }
  .onlyClickOnce{
    animation: throttle 2s step-end forwards;
  }
  .onlyClickOnce:active{
    filter: opacity(40%);
    animation: none;
  }
  .selectClass ::v-deep .el-form-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .selectClass ::v-deep .el-form-item__label{
    padding: 0;
    line-height: 16px;
    white-space: nowrap;
    margin-left: 20px;
  }
  .selectClass ::v-deep .el-form-item__content{
    margin-left: 0 !important;
    width: 100%;
  }
  .selectClass ::v-deep .el-select{
    width: 100%;
  }
  .delMl ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
  .col3{
    width: 150px;
  }
  .carNumberBox{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
</style>
