<script >
import { defineProps, ref } from '@vue/composition-api';
import { default as FormVtag } from './steps/formVtag';
import { default as FormEletronicIdentification } from './steps/formEletronicIdentification';
import { default as FormSenderInformation } from './steps/formSenderInformation';
import { default as FormInsuranceInformation } from './steps/formInsuranceInformation';
import { useWizardForm } from '@/composable/useWizardForm';
import { mdfeService } from '@/api/mdfe';
import { toast } from '@/utils/programaticToast';
import { getToastContentComponent } from '@/utils/getToastContentComponent';
import getMessageError from '@/utils/getMessageError';
import { useQueryClient } from '@tanstack/vue-query';
import { addingLoading, closeLoading } from '@/utils/handleControlLoading';
const __sfc_main = {};
__sfc_main.props = {
  closeModal: {
    type: Function
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const formData = ref({
    formStepVtag: {
      segnAver: [],
      infCTe: [],
      listVTag: [],
      totqCTe: null,
      totvCarga: null,
      totqCarga: null
    },
    formStepEletronicIdentification: {
      cUF: 35,
      emitCnpj: '11605535000455',
      emitIe: '136821050117',
      emitxNome: 'Anjun Express Logística e Transportes Ltda',
      emitxFant: 'ANJUN EXPRESS LTDA',
      emitxLgr: 'Av Raimundo Pereira de Magalhaes',
      emitNro: '16800',
      emitxBairro: 'Vila Santa Cruz',
      emitcMun: '3550308',
      emitxMun: 'São Paulo',
      emitCEP: '05220000',
      emitUF: 'SP',
      emitfone: '1145135929',
      emitemail: 'flavio@escritoriocontabiletica.com.br',
      initialUF: null,
      initialZipCode: null,
      initialNameMunicipality: null,
      initialCodMunicipality: null,
      finalUF: null,
      finalZipCode: null,
      finalNameMunicipality: null,
      finalCodMunicipality: null
    },
    formStepSenderInformation: {
      infAnttRNTRC: null,
      nameDriver: null,
      documentDriver: null,
      documentOwnerVehicle: null,
      ie: null,
      rntrc: null,
      renavam: null,
      company: null,
      licensePlate: null,
      capacityKg: null,
      tara: null,
      bodyType: null,
      wheelsetType: null,
      uf: null,
      tpProp: null,
      veicTracaoCint: null,
      condutorUF: null
    },
    formStepInsuranceInformation: {
      name: null,
      document: null,
      ie: null,
      rntrc: null,
      licensePlate: null,
      capacityKg: null,
      tara: null,
      renavam: null,
      bodyType: null,
      uf: null
    }
  });
  const STEP_COUNT = 4;
  const queryClient = useQueryClient();
  async function handleSubmit() {
    try {
      addingLoading();
      await mdfeService.create(formData.value);
      queryClient.invalidateQueries({
        queryKey: ['mdfe']
      });
      toast.open({
        variant: 'success',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Gerado com sucesso',
          describe: 'MDFE foi gerada com sucesso',
          typeIcon: 'success'
        }),
        closable: true,
        duration: 4000
      });
    } catch (error) {
      console.log(error);
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Ocorreu um erro',
          describe: getMessageError(error),
          typeIcon: 'error'
        }),
        closable: true,
        duration: 4000
      });
    } finally {
      closeLoading();
    }
  }
  const {
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  } = useWizardForm(formData, handleSubmit, STEP_COUNT);
  return {
    formData,
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  };
};
__sfc_main.components = Object.assign({
  FormVtag,
  FormEletronicIdentification,
  FormSenderInformation,
  FormInsuranceInformation
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <FormVtag
      v-show="step === 0"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      :form-data="formData.formStepVtag"
    />

    <FormEletronicIdentification
      v-show="step === 1"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      :form-data="formData.formStepEletronicIdentification"
    />

    <FormSenderInformation
      v-show="step === 2"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      :form-data="formData.formStepSenderInformation"
    />

    <FormInsuranceInformation
      v-show="step===3"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      :form-data="formData.formStepInsuranceInformation"
    />
  </div>
</template>
