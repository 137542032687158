<template>
  <div class="app-container">
    <div class="tip01">{{ $t('placeOrder.automaticPrinting1') }}
      <el-link
        href="https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/software/安骏桌面插件/安骏桌面插件.exe"
        style="font-size: 16px"
        type="warning"
      >{{ $t('placeOrder.automaticPrinting2') }}
      </el-link>
      {{ $t('placeOrder.automaticPrinting3') }}
    </div>
    <el-form
      ref="ruleFormRef"
      :inline="false"
      :model="queryForm"
      label-position="top"
      size="large"
      @submit.native.prevent
    >
      <el-form-item :label="$t('GlobalSetObj.scannerNumber')" prop="waybillBagNumber">
        <el-input
          ref="inputRef"
          v-model.trim="queryForm.waybillBagNumber"
          clearable
          style="width:350px"
          @keyup.enter.native="arrivedOut"
        />
      </el-form-item>
    </el-form>
    <!--  表格数据  -->
    <el-table
      :data="tableData"
      :header-cell-style="{background:'#f2f2f2',color:'black'}"
      border
      style="width: 100%"
    >
      <el-table-column
        :label="$t('GlobalSetObj.serial')"
        align="center"
        type="index"
        width="100"
      />
      <el-table-column
        :label="$t('collectionCenter.printTime')"
        min-width="180"
        prop="printableTime"
      />
      <el-table-column
        :label="$t('collectionCenter.printer')"
        min-width="150"
        prop="printableUserName"
      />
      <el-table-column
        :label="$t('GlobalSetObj.waybillNumber')"
        min-width="160"
        prop="waybillNumber"
      >
        <template #default="scope">
          <div>{{ scope.row.waybillNumber || '--' }}</div>
        </template>
      </el-table-column>
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[50, 100, 200, 400]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!-- 打印面单  -->
    <div style="display: none">
      <pdf ref="pdfPrint" :src="viewUrl" />
    </div>
    <!-- 隐藏提示音 -->
    <div v-show="false">
      <audio ref="errRef" :src="errorMp3" controls="controls" />
      <audio ref="okRef" :src="okMp3" controls="controls" />
    </div>
  </div>
</template>

<script>
import { quickOrderChange, waybillPrintablePage } from '@/api/automaticSorting';
import { batchPdfDownload } from '@/utils/PDF_util';
import pdf from 'vue-pdf-messy-code-fix';
import { apiStartUp, autoPrint } from '@/api/serialPort';

const okMp3 = 'https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/static/music/su.wav';
const errorMp3 = 'https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/static/music/print_error.wav';
export default {
  name: 'QuickOrderChange',
  components: {
    pdf
  },
  data() {
    return {
      viewUrl: '',
      // 是否格式化成巴西时间
      isTormat: false,
      queryForm: {
        pageSize: 50,
        pageNumber: 1,
        total: 0,
        waybillBagNumber: ''
      },
      tableData: [],
      okMp3,
      errorMp3
    };
  },
  activated() {
    this.$nextTick(_ => {
      this.$refs.inputRef.$refs.input.focus();
    });
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.$nextTick(_ => {
      this.$refs.inputRef.$refs.input.focus();
    });
    this.getSearch();
  },
  methods: {
    printedSheet(url) {
      const labelList = [url];
      // 手动显示加载中动画
      const loading = this.$loading({
        fullscreen: true,
        lock: true,
        spinner: 'el-icon-loading',
        text: this.$i18n.t('orderCenterCont.DesperatelyLoading'), // '拼命加载中',
        background: 'rgba(250, 250, 250, 0.6)'
      });
      batchPdfDownload(labelList)
        .then((res) => {
          this.viewUrl = res;
          setTimeout(() => {
            this.$refs.pdfPrint.print();
          }, 300);
        })
        .finally(() => {
          loading.close();
        });
    },
    // 查询
    getSearch() {
      const data = {
        'pageNumber': 1,
        'pageSize': 10,
        'params': {
          'waybillNumbers': []
        }
      };
      waybillPrintablePage(data).then(res => {
        if (res.status === 'OK') {
          this.tableData = res.data.records;
          this.queryForm.total = res.data.total;
          console.log(this.tableData, 'tableData');
        }
      });
    },
    // 扫描
    arrivedOut() {
      if (this.queryForm.waybillBagNumber) {
        const _this = this;
        const data = {
          waybillNumbers: [this.queryForm.waybillBagNumber]
        };
        quickOrderChange(data).then(res => {
          if (res.status === 'OK') {
            apiStartUp().then((res1) => {
              if (res1.code === 200) {
                console.log(res1);
                autoPrint(res.data[0].labelUrl).then(async(resPrint) => {
                  if (resPrint.code === 200) {
                    await this.$refs.okRef.play();
                    this.$message.success(this.$t('collectionCenter.printSuc'));
                    _this.queryForm.waybillBagNumber = '';
                    _this.$nextTick(_ => {
                      _this.$refs.inputRef.$refs.input.focus();
                    });
                    _this.getSearch();
                  }
                }).catch(async(e) => {
                  await this.$refs.errRef.play();
                  this.$message.error(this.$t('GlobalSetObj.PrintFailure'));
                  // this.ruleForm.SingleNumber = '';
                  _this.$nextTick(_ => {
                    _this.$refs.SingleNumberInput.$refs.input.select();
                  });
                });
              }
            }).catch(() => {
              this.$refs.errRef.play();
              this.$message.error(this.$t('GlobalSetObj.setDefaultPrinter'));
              _this.$nextTick(_ => {
                _this.$refs.inputRef.$refs.input.select();
              });
            });
          }
        }).catch(() => {
          this.$nextTick(_ => {
            this.$refs.inputRef.$refs.input.select();
          });
        });
      } else {
        this.getSearch();
        // return false;
      }
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    }
  }
};
</script>

<style scoped>
.app-container >>> .el-dialog__body {
  padding: 10px 20px;
}

.tip01 {
  background-color: #ecf5ff;
  color: #409eff;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
}
</style>
