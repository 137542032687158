<script >
import { ref, defineProps } from '@vue/composition-api';
import { default as FormPersonalData } from './steps/formPersonalData';
import { default as FormAddress } from '@/components/steps/formAddress';
import { useWizardForm } from '@/composable/useWizardForm';
import { useCreateDriver } from '@/api/transport/driver/query';
const __sfc_main = {};
__sfc_main.props = {
  closeModal: {
    type: Function
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const STEPS_TOTAL = 2;
  const formData = ref({
    formStep01: {
      name: null,
      document: null,
      phone: null,
      driverLicense: null,
      scoreLicense: null,
      scoreDriver: null,
      licenseType: null,
      licenseValidate: null,
      providerName: null,
      username: null,
      outsourced: false
    },
    formStep02: {
      zipcode: null,
      street: null,
      number: null,
      neighborhood: null,
      municipality: null,
      state: null,
      complement: null
    }
  });
  const {
    mutate,
    isLoading
  } = useCreateDriver();
  const handleSubmit = async () => {
    const newDriver = {
      ...formData.value.formStep01,
      ...formData.value.formStep02
    };
    mutate(newDriver, {
      onSuccess: () => {
        props.closeModal();
      }
    });
  };
  const {
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  } = useWizardForm(formData, handleSubmit, STEPS_TOTAL);
  return {
    formData,
    isLoading,
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  };
};
__sfc_main.components = Object.assign({
  FormPersonalData,
  FormAddress
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <transition name="slide-fade">
      <FormPersonalData
        v-show="step === 0"
        key="1"
        :handle-next="handleNextStep"
        :handle-previous="handlePreviousStep"
        :is-last-step="isLastStep"
        :is-first-step="isFirstStep"
        :form-data.sync="formData.formStep01"
      />
    </Transition>

    <transition name="slide-fade">
      <FormAddress
        v-show="step === 1"
        key="2"
        :handle-next="handleNextStep"
        :handle-previous="handlePreviousStep"
        :is-last-step="isLastStep"
        :is-first-step="isFirstStep"
        :form-data.sync="formData.formStep02"
      />
    </transition>

    <o-loading :full-page="true" :active.sync="isLoading" />
  </div>
</template>

<style scoped>
.slide-fade-enter-active {
  background: violet;
  display: none;
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {

  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

.slide-enter-active {
  display: none;
  transition: all 0.1s ease-out;
}

.slide-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
