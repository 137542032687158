<template>
  <el-form label-width="120px">
    <el-form-item label="下载导入模板">
      <el-button type="text" icon="el-icon-edit">
        <a :href="importTmpUrl" :download="`${importTmpName}.xlsx`">下载</a>
      </el-button>
    </el-form-item>
    <el-form-item v-show="false" label=" ">
      <input
        ref="uploadInputRef" class="excel-upload-input" type="file"
        accept=".xlsx, .xls" @change="handleClick"
      >
    </el-form-item>
    <el-form-item label="上传">
      <el-button
        icon="el-icon-upload2" :loading="loading" size="small"
        type="primary" @click="handleUpload"
      >
        {{ butName }}
      </el-button>
    </el-form-item>
  </el-form>
  <!-- <div> -->
  <!-- <ul>
      <li>
        <a :href="importTmpUrl" :download="`${importTmpName}.xlsx`">下载导入模板</a>
      </li>
      <li>
        <input v-show="false" ref="uploadInputRef" class="excel-upload-input" type="file" accept=".xlsx, .xls" @change="handleClick">
        <el-button icon="el-icon-upload2" :loading="loading" size="small" type="primary" @click="handleUpload">
          {{ butName }}
        </el-button>
      </li>
    </ul> -->
  <!-- </div> -->
</template>

<script>
import { isExcel, isEmpty } from './utils.js';
import { Workbook } from 'exceljs';

export default {
  name: '',
  props: {
    // 下载模板的url 使用正式环境地址
    importTmpUrl: {
      type: String,
      default: ''
    },

    // 下载模板名称
    importTmpName: {
      type: String,
      default: '模板'
    },

    // 上传按钮名称
    butName: {
      type: String,
      default: '上传文件' // this.$i18n.t('basicData.UploadTheFile')
    },

    // 对照模板 格式 {  a: { key:'a', header: '客户名称', width: 10 } }
    keyAndValObj: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      loading: false
    };
  },

  computed: {
    // 读取需要的数据格式
    // excelKeyAndValue() {
    //   // return this.keyAndValObj;
    //   const values = Object.values(this.keyAndValObj).s
    // }
  },

  created() {},

  methods: {

    handleUpload() {
      this.$refs['uploadInputRef'].click();
    },

    async handleClick(e) {
      const files = e.target.files[0];
      if (!files) return;

      if (!isExcel(files)) {
        this.$message.error(this.$i18n.t('basicData.PleaseUploadExcelFiles')); // '请上传 .xlsx, .xls 文件'
        return false;
      }

      this.loading = true;

      this.$refs['uploadInputRef'].value = null;

      const workbook = new Workbook();
      try {
        const workbook2 = await workbook.xlsx.load(files);
        workbook2.eachSheet((sheet, index) => {
          const value = sheet.getSheetValues();

          const arr = value.filter((item, index) => {
            return index !== 0;
          }).map(item => {
            return item.filter((item, index) => {
              return index !== 0;
            });
          });
          const list = this.setKeyAndVal(arr);
          this.$emit('change', list);
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    // 文件 key val 对照
    setKeyAndVal(arr) {
      // 无内容
      if (arr.length === 0) {
        // this.$emit('change', arr);
        return arr;
      }

      // 未传入对照模板
      if (isEmpty(this.keyAndValObj)) {
        // this.$emit('change', arr);
        return arr;
      }

      const valueArr = Object.values(this.keyAndValObj);
      return arr.slice(1).map(item => {
        const obj = {};
        valueArr.forEach((k, i) => {
          obj[k.key] = item[i];
        });

        return obj;
      });
    }
  }

};
</script>

<style scoped>
.row {
  display: flex;
  border: 1px solid #000;
}
</style>
