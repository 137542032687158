var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "tip01" },
        [
          _vm._v(_vm._s(_vm.$t("placeOrder.automaticPrinting1")) + " "),
          _c(
            "el-link",
            {
              staticStyle: { "font-size": "16px" },
              attrs: {
                href: "https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/software/安骏桌面插件/安骏桌面插件.exe",
                type: "warning",
              },
            },
            [_vm._v(_vm._s(_vm.$t("placeOrder.automaticPrinting2")) + " ")]
          ),
          _vm._v(" " + _vm._s(_vm.$t("placeOrder.automaticPrinting3")) + " "),
        ],
        1
      ),
      _c(
        "el-radio-group",
        {
          attrs: { size: "medium" },
          on: { change: _vm.changeRadio },
          model: {
            value: _vm.radio2,
            callback: function ($$v) {
              _vm.radio2 = $$v
            },
            expression: "radio2",
          },
        },
        [
          _c("el-radio-button", { attrs: { label: "1" } }, [
            _vm._v(_vm._s(_vm.$t("GlobalSetObj.autoPrint"))),
          ]),
          _c("el-radio-button", { attrs: { label: "2" } }, [
            _vm._v(_vm._s(_vm.$t("GlobalSetObj.mualPrint"))),
          ]),
        ],
        1
      ),
      _c(
        "el-form",
        {
          staticStyle: { "margin-top": "10px" },
          attrs: { inline: false, "label-position": "top", size: "small" },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _vm.radio2 === "1"
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.scanningBag"),
                            prop: "inputValue",
                          },
                        },
                        [
                          _c("el-input", {
                            ref: "inputRef",
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.scannerBagNo($event)
                              },
                            },
                            model: {
                              value: _vm.printObj.BagNo,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.printObj,
                                  "BagNo",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "printObj.BagNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("collectionCenter.SelectNet"),
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { clearable: "", filterable: "" },
                                  on: { change: _vm.handleSiteId },
                                  model: {
                                    value: _vm.printObj.siteName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.printObj, "siteName", $$v)
                                    },
                                    expression: "printObj.siteName",
                                  },
                                },
                                _vm._l(_vm.siteData, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("collectionCenter.PrintCopies"),
                                prop: "inputValue",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: _vm.$t(
                                    "GlobalSetObj.pleaseInput"
                                  ),
                                },
                                on: { input: _vm.handlePrintNum },
                                model: {
                                  value: _vm.printObj.printNum,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.printObj,
                                      "printNum",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "printObj.printNum",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { staticStyle: { "margin-top": "28px" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickPrint()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("GlobalSetObj.Print")) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "titleClass" }, [
        _vm._v(_vm._s(_vm.$t("collectionCenter.printRecord"))),
      ]),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            "cell-style": { "text-align": "center" },
            data: _vm.tableData,
            "header-cell-style": {
              background: "#f2f2f2",
              color: "black",
              "text-align": "center",
            },
            border: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.printTime"),
              "min-width": "180",
              prop: "createTime",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("GlobalSetObj.Outlets"),
              "min-width": "180",
              prop: "siteName",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.printRecord"),
              "min-width": "150",
              prop: "printCount",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("GlobalSetObj.operator"),
              "min-width": "150",
              prop: "operatorUser",
            },
          }),
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { margin: "10px 0", float: "right" },
        attrs: {
          "current-page": _vm.queryForm.number,
          "page-size": _vm.queryForm.pageSize,
          "page-sizes": [10, 20, 50, 100],
          total: _vm.queryForm.total,
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c(
        "div",
        { staticClass: "printStyle" },
        [
          _vm.showPrintBagNumberHtml
            ? _c("printBagNumberHtml", {
                attrs: { "param-data": _vm.printBagObj },
                on: { hiddenBagNumberHtml: _vm.hiddenBagNumberHtml },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
        },
        [
          _c("audio", {
            ref: "errRef",
            attrs: { src: _vm.errorMp3, controls: "controls" },
          }),
          _c("audio", {
            ref: "okRef",
            attrs: { src: _vm.okMp3, controls: "controls" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }