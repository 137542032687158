var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "list-sellers" },
    _vm._l(_vm.listSellers, function (sellerCode, index) {
      return _c(
        "li",
        { key: index, staticClass: "item-seller" },
        [
          _c("p", { staticClass: "seller-name" }, [
            _vm._v(" " + _vm._s("" + sellerCode) + " "),
          ]),
          _c(
            "o-button",
            {
              attrs: { variant: "danger", size: "sm" },
              on: {
                click: function ($event) {
                  return _vm.removeSeller(sellerCode)
                },
              },
            },
            [_c("o-icon", { attrs: { icon: "trash" } })],
            1
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }