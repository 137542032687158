var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { height: "calc(100vh - 170px)", "overflow-y": "auto" },
    },
    [
      _c("el-divider", [
        _vm._v(_vm._s(_vm.$t("customerManagements.otherInformation"))),
      ]),
      _c(
        "el-form",
        { ref: "formDataOther", attrs: { model: _vm.formData } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("customerManagements.orderNumberType"),
                        rules: [
                          {
                            required: true,
                            message: _vm.$t("customerManagements.pleaseInput"),
                            trigger: ["blur", "change"],
                          },
                        ],
                        prop: "orderNumberType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t("customerManagements.default"),
                          },
                          model: {
                            value: _vm.formData.orderNumberType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "orderNumberType", $$v)
                            },
                            expression: "formData.orderNumberType",
                          },
                        },
                        [
                          _c("el-option", {
                            key: 1,
                            attrs: { value: 1, label: "AJ" },
                          }),
                          _c("el-option", {
                            key: 2,
                            attrs: { value: 2, label: "ANJBR" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.customer.type === "edit" || _vm.customer.type === "add"
        ? _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.clearData()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("customerManagements.cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onSubmit()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("customerManagements.confirm")))]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }