var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticStyle: { position: "relative" } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.queryForm,
                "label-width": "120px",
                size: "mini",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属平台：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.queryForm.params.platformCode,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "platformCode",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.platformCode",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "LHEV", value: "lhev" },
                              }),
                              _c("el-option", {
                                attrs: { label: "K3", value: "K3" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "消息状态：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.queryForm.params.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm.params, "status", $$v)
                                },
                                expression: "queryForm.params.status",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "Success", value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: "Failure", value: 0 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "template",
                            { slot: "label" },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.orderType,
                                    callback: function ($$v) {
                                      _vm.orderType = $$v
                                    },
                                    expression: "orderType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: "1",
                                    attrs: { label: "运单号", value: 1 },
                                  }),
                                  _c("el-option", {
                                    key: "2",
                                    attrs: { label: "客户订单号", value: 2 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 4,
                              placeholder: "请输入内容",
                            },
                            model: {
                              value: _vm.orderNumber,
                              callback: function ($$v) {
                                _vm.orderNumber = $$v
                              },
                              expression: "orderNumber",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "创建时间：" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetimerange",
                              "range-separator": "至",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                            },
                            model: {
                              value: _vm.queryDate,
                              callback: function ($$v) {
                                _vm.queryDate = $$v
                              },
                              expression: "queryDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "10px" } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-search",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.getSearch(1)
                    },
                  },
                },
                [_vm._v("查询")]
              ),
              _c("el-divider"),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "waybillNumber", label: "运单号" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "customerNumber",
                  label: "客户订单号",
                  width: "180",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "requestParams", label: "接收报文" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "top",
                              "popper-class": "copy",
                              trigger: "hover",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "black",
                                  "font-size": "12px",
                                  padding: "2px",
                                },
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.copy(scope.row.requestParams)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("orderDetails.copy")) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "omit",
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [_vm._v(_vm._s(scope.row.requestParams))]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "responseParams", label: "响应报文" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "top",
                              "popper-class": "copy",
                              trigger: "hover",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "black",
                                  "font-size": "12px",
                                  padding: "2px",
                                },
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.copy(scope.row.responseParams)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("orderDetails.copy")) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "omit",
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [_vm._v(_vm._s(scope.row.responseParams))]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间" },
              }),
              _c("el-table-column", {
                attrs: { prop: "pushStatus", label: "消息状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.status == 1 ? "Success" : "Failure"
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            staticStyle: { margin: "10px 0", float: "right" },
            attrs: {
              "current-page": _vm.queryForm.pageNumber,
              "page-size": _vm.queryForm.pageSize,
              "page-sizes": [10, 20, 50, 100],
              total: _vm.total,
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "size-change": _vm.getSearch,
              "current-change": _vm.getSearch,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }