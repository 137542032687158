var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "ul",
      [
        _vm._l(_vm.list, function (item, index) {
          return _c(
            "li",
            { key: index },
            [
              _c(
                "el-link",
                {
                  attrs: {
                    icon: "el-icon-folder",
                    target: "_blank",
                    href: item.url,
                    type: "primary",
                  },
                },
                [_vm._v(_vm._s(item.name))]
              ),
            ],
            1
          )
        }),
        _vm.list.length === 0
          ? _c("li", { staticClass: "no_data" }, [
              _vm._v(_vm._s(_vm.$t("WorkCentreTable.NoContent"))),
            ])
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }