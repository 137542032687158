export const errorMessage = {
  mandatory: {
    zh: '必填',
    en: 'Mandatory',
    pu: 'Obrigatório'
  },
  acceptsNumeric: {
    zh: '仅接受数字值',
    en: 'Only accepts numeric values',
    pu: 'Só aceita valores numéricos'
  },
  invalidEmail: {
    zh: '无效的电子邮件',
    en: 'Invalid email',
    pu: 'Email inválido'
  },
  acceptsOnlyLetters: {
    zh: '仅接受字母',
    en: 'Only accepts letters',
    pu: 'Só aceita letras'
  },
  acceptsLettersAndNumbers: {
    zh: '仅接受字母和数字',
    en: 'Only accepts letters and numbers',
    pu: 'Só aceita letras e números'
  },
  minimumCharacters: {
    zh: '最少字符数',
    en: 'Minimum characters',
    pu: 'Campo aceita no mínimo'
  },
  characters: {
    zh: '字符',
    en: 'characters',
    pu: 'Caracteres'
  },
  between: {
    zh: '介于',
    en: 'Between',
    pu: 'Valor entre'
  },
  to: {
    zh: '至',
    en: 'to',
    pu: 'Até'
  }

};
