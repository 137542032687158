
const newOperationCenter = {
  ind: {
    zh: '序号',
    en: 'index',
    pu: 'Número de série.'
  },
  NumberPackages: {
    zh: '包裹数',
    en: 'Number of packages',
    pu: 'Número de pacotes'
  },
  Weight: {
    zh: '重量',
    en: 'Weight',
    pu: 'Peso'
  },
  bigBagNum: {
    zh: '大包号',
    en: '',
    pu: 'Grande bolsa'
  },
  SealedBag: {
    zh: '已封袋',
    en: '',
    pu: 'saco selado'
  },
  haveBeenSigned: {
    zh: '已签收',
    en: '',
    pu: 'Foi assinado'
  },
  CreationTime: {
    zh: '创建时间',
    en: 'Creation time',
    pu: 'Tempo de criação'
  },
  FaceSheetUrl: {
    zh: '面单url',
    en: '',
    pu: 'URL do formulário do correios'
  },
  LargePackCondition: {
    zh: '大包状态',
    en: '',
    pu: 'Status do saco'
  },
  TotalNumberOfServiceProvider: {
    zh: '服务商总单号',
    en: '',
    pu: 'No. PLP'
  },
  Note: {
    zh: '备注',
    en: 'remark',
    pu: 'observações'
  },
  Lattice: {
    zh: '格口',
    en: 'Lattice',
    pu: 'Reticulado'
  },
  ForecastState: {
    zh: '预报状态',
    en: '',
    pu: 'Situação pendente'
  },
  SuccessfulForecast: {
    zh: '预报成功',
    en: '',
    pu: 'Pendente do sucesso'
  },
  abnormal: {
    zh: '异常',
    en: 'abnormal',
    pu: 'Anormal'
  }
};
export {
  newOperationCenter
};
