var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WrapperHandleError",
    { attrs: { error: _vm.error, "is-error": _vm.isError } },
    [
      _c("SectionHeader", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("h1", { staticClass: "header-1" }, [
                  _vm._v(_vm._s(_vm.$t("trailer.trailer"))),
                ]),
                _c(
                  "o-button",
                  {
                    attrs: { variant: "primary", size: "md" },
                    on: { click: _vm.handleVisibilityModalCreate },
                  },
                  [_vm._v(_vm._s(_vm.$t("trailer.createTrailer")))]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "filter",
            fn: function () {
              return [
                _c(
                  "o-field",
                  {
                    staticClass: "flex-1",
                    attrs: {
                      label: _vm.$t("trailer.plate"),
                      "label-for": "plate",
                    },
                  },
                  [
                    _c("o-input", {
                      attrs: {
                        id: "plate",
                        placeholder: _vm.$t("trailer.platePlaceholder"),
                      },
                      model: {
                        value: _vm.filters.plate,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "plate", $$v)
                        },
                        expression: "filters.plate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "o-field",
                  {
                    staticClass: "flex-1",
                    attrs: {
                      label: _vm.$t("trailer.trailerStatus"),
                      "label-for": "status",
                    },
                  },
                  [
                    _c(
                      "o-select",
                      {
                        attrs: {
                          id: "status",
                          placeholder: "Selecione o status",
                        },
                        model: {
                          value: _vm.filters.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "status", $$v)
                          },
                          expression: "filters.status",
                        },
                      },
                      [
                        _c("option", { domProps: { value: 1 } }, [
                          _vm._v(_vm._s(_vm.$t("trailer.activeTrailers"))),
                        ]),
                        _c("option", { domProps: { value: 0 } }, [
                          _vm._v(_vm._s(_vm.$t("trailer.disabledTrailers"))),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "SkeletonTable",
        { attrs: { "is-loading": _vm.isLoadingTable } },
        [
          _c(
            "o-table",
            {
              staticClass: "space-m-y-32",
              attrs: {
                data: _vm.tableData,
                "row-key": "id",
                bordered: "",
                paginated: "",
                "backend-pagination": "",
                total: _vm.tableTotal,
                "per-page": _vm.queryParams.perPage,
                "current-page": _vm.queryParams.currentPage,
                "pagination-simple": false,
              },
              on: {
                "update:currentPage": function ($event) {
                  return _vm.$set(_vm.queryParams, "currentPage", $event)
                },
                "update:current-page": function ($event) {
                  return _vm.$set(_vm.queryParams, "currentPage", $event)
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "empty",
                  fn: function () {
                    return [
                      _vm._v(" " + _vm._s(_vm.$t("trailer.noResults")) + " "),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("o-table-column", {
                attrs: {
                  field: "trailer_documentation",
                  label: _vm.$t("trailer.document"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(
                          " " + _vm._s(props.row.trailer_documentation) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "trailer_license_plate",
                  label: _vm.$t("trailer.plate"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(
                          " " + _vm._s(props.row.trailer_license_plate) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "trailer_brand",
                  label: _vm.$t("trailer.brand"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(" " + _vm._s(props.row.trailer_brand) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "trailer_type_ownership",
                  label: _vm.$t("trailer.owner"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              props.row.trailer_type_ownership
                                ? props.row.trailer_owner_CNPJ
                                : props.row.trailer_owner_CPF
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: { field: "trailer_RNTRC", label: "RNTRC" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(" " + _vm._s(props.row.trailer_RNTRC) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "trailer_body_type",
                  label: _vm.$t("trailer.bodyType"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(" " + _vm._s(props.row.trailer_body_type) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "trailer_state",
                  label: _vm.$t("trailer.state"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(" " + _vm._s(props.row.trailer_state) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "trailer_outsourced",
                  label: _vm.$t("trailer.outsourced"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              props.row.trailer_outsourced
                                ? _vm.$t("trailer.yes")
                                : _vm.$t("trailer.no")
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: { label: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("Actions", {
                          attrs: { row: row, status: !!_vm.filters.status },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "o-modal",
        {
          attrs: { active: _vm.isOpenModalCreate, width: "560" },
          on: { close: _vm.handleVisibilityModalCreate },
        },
        [
          _c("div", [
            _c("h2", { staticClass: "header-2" }, [
              _vm._v(_vm._s(_vm.$t("trailer.createTrailerTitle"))),
            ]),
            _c("p", { staticClass: "paragraph" }, [
              _vm._v(_vm._s(_vm.$t("trailer.createTrailerParagraph"))),
            ]),
          ]),
          _c("FormCreateTrailer", {
            attrs: { "close-modal": _vm.handleVisibilityModalCreate },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }