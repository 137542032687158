<template>
  <div class="warpp">
    <el-popover
      placement="bottom-start"
      trigger="hover"
    >
      <div>
        <div
          style="margin-bottom: 10px"
        >
          <!--下载模板-->
          <el-button type="primary" size="small" @click="onDownloadTemplate">{{ $t('basicData.DownloadTheTemplate') }}</el-button>
        </div>
        <div
          style="margin-bottom: 10px"
        >
          <!--批量导入-->
          <upload-excel
            :on-success="importLogistics"
            :but-name="$t('basicData.BulkImport')"
          />
        </div>
        <div>
          <!--下载报价-->
          <el-button type="primary" size="small" @click="downloadOffer">{{ $t('basicData.DownloadTheQuote') }}</el-button>
        </div>
      </div>
      <!--导入/下载-->
      <el-button
        slot="reference" size="small" plain
        style="margin-left: 10px"
      >{{ $t('basicData.ImportDownload') }}</el-button>
    </el-popover>
  </div>
</template>

<script>
import { downloadTemplate, importTemplate, downloadQuotation } from './utils';

export default {
  name: '',

  components: {
    'upload-excel': () => import('@/components/UploadExcel/index.vue')
  },

  props: {
    // 所有分区
    subregionList: {
      type: Array,
      default: () => {
        return [];
      }
    },

    // 下载模板需要的回调函数
    getDataFunc: {
      type: Function,
      required: true
    }

  },

  data() {
    return {

    };
  },

  created() {

  },

  methods: {
    // 下载模板
    onDownloadTemplate() {
      // console.log(JSON.stringify(this.subregionList));
      downloadTemplate(this.subregionList);
    },

    // 批量导入报价
    importLogistics(arr) {
      try {
        const list = importTemplate(arr[0].results, this.subregionList);
        // console.log(list);
        this.$emit('importList', list);
      } catch (error) {
        this.$message.warning(this.$t('orderCenterCont.ImportFailed'));
        console.error(error);
      }
    },

    // 下载报价
    downloadOffer() {
      const list = this.getDataFunc();
      // console.log(list);

      downloadQuotation(list, this.subregionList);
    }
  }

};
</script>

<style lang="scss" scoped>
.warpp {
  display: inline-flex;
}
</style>
