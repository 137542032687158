<template>
  <div class="app-container">
    <el-dialog
      :title="organizationOpt.title"
      :visible.sync="organizationOpt.visible"
      width="30%"
      @open="openOrgOpt"
      @close="closeOrgOpt"
    >
      <!--
        组织机构表单:
      -->
      <el-form
        ref="orgRef"
        :model="orgForm"
        :rules="rules"
        label-width="100px"
        label-position="left"
      >
        <el-row v-if="organizationOpt.type!=='add'">
          <el-col :span="24">
            <!-- 组织机构ID -->
            <el-form-item :label="$t('organizationManage.OrganizationID')">
              <el-input v-model="orgForm.id" disabled />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-show="orgForm.parentId!==0">
          <el-col :span="24">
            <!-- 父组织机构 -->
            <el-form-item :label="$t('organizationManage.ParentOrganization')" prop="parentId">
              <el-input v-show="false" v-model="orgForm.parentId" disabled />
              <el-input v-model="organizationOpt.parentName" disabled />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <!-- 组织名称 -->
            <el-form-item :label="$t('organizationManage.OrganizationName')" prop="name">
              <el-input v-model="orgForm.name" autocomplete="off" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <!-- 排序 -->
            <el-form-item
              :label="$t('organizationManage.sort')"
              :rules="[ { required: true, message: $t('roleManage.sortNotEmpty')},{ type: 'number', message: $t('roleManage.sortIsNumber')}]"
              prop="sort"
            >
              <!--                                  排序不能为空                            排序必须为数字值 -->
              <el-input
                v-model.number="orgForm.sort"
                type="sort"
                autocomplete="off"
                :placeholder="$t('roleManage.littleValueFront')"
              />
              <!-- 值越小越靠前 -->
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <!-- 备注 -->
            <el-form-item :label="$t('organizationManage.note')">
              <el-input v-model="orgForm.remark" type="textarea" autocomplete="off" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item size="large">
              <el-button type="primary" @click="onSubmit">{{ $t('roleManage.confirm') }}</el-button><!-- 确认 -->
              <el-button @click="organizationOpt.visible = false">{{ $t('roleManage.cancel') }}</el-button><!-- 取消 -->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>>

<script>
import { addOrganization, editOrganization } from '@/api/organization';

export default {
  name: 'OrganizationOperation',
  props: {
    organizationOpt: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      rules: {
        // 父权限ID不能为空
        parentId: [{ required: true, message: this.$t('organizationManage.PpermissionIDNotEmpty'), trigger: 'blur' }],
        // 权限名称不能为空
        name: [{ required: true, message: this.$t('organizationManage.permissionNameNotEmpty'), trigger: 'blur' }]
      },
      orgForm: {
        id: null,
        parentId: null,
        name: '',
        sort: 1,
        remark: '',
        title: ''
      }
    };
  },
  methods: {
    openOrgOpt() {
      this.orgForm.title = this.organizationOpt.title;
      this.orgForm.id = this.organizationOpt.id;
      this.orgForm.parentId = this.organizationOpt.parentId;
      this.orgForm.name = this.organizationOpt.name;
      this.orgForm.sort = this.organizationOpt.sort;
      this.orgForm.remark = this.organizationOpt.remark;
    },
    closeOrgOpt() {
      this.$refs['orgRef'].resetFields();
    },
    onSubmit() {
      this.$refs.orgRef.validate((valid) => {
        if (!valid) {
          return;
        }
        // 添加组织机构
        if (this.organizationOpt.type === 'add') {
          addOrganization(this.orgForm).then(() => {
            this.organizationOpt.visible = false;
            this.$emit('on-add-submit');
          }).catch(err => {
            console.error(err);
          });
        } else {
          editOrganization(this.orgForm).then(() => {
            this.organizationOpt.visible = false;
            this.$emit('on-add-submit');
          }).catch(err => {
            console.error(err);
          });
        }
      });
    }
  }

};
</script>

<style scoped>
</style>
