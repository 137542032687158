import axios from 'axios';
import { Loading, Message, MessageBox } from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth';

const MIN_Time = 1000; // 加载中动画显示最少 1000 毫秒
const loadingObj = {
  instance: null,
  count: 0,
  startTime: 0 // 请求开始时间
};

const noCloseLoadingUrl = ['/message/getCurrentUserMessage', '/message/receiveMessage'];

// 统一增加加载动画
export const addLoading = function() {
  const { instance, count } = loadingObj;
  if (instance !== null) {
    loadingObj.instance.close();
  }
  loadingObj.instance = Loading.service({
    fullscreen: true,
    lock: true,
    spinner: 'el-icon-loading',
    text: 'loading...',
    background: 'rgba(250, 250, 250, 0.6)'
  });
  if (count === 0) {
    // 记录第一次请求的时间
    loadingObj.startTime = Date.now();
  }

  loadingObj.count++;
};

// 取消动画
export const isCloseLoading = function() {
  loadingObj.count--;

  if (loadingObj.count >= 1) {
    return;
  }

  const endVal = Date.now() - loadingObj.startTime;

  if (endVal >= MIN_Time) {
    loadingObj.instance.close();
    return;
  }

  // 每次加载中最少显示1秒
  setTimeout(() => {
    loadingObj.instance.close();
  }, (MIN_Time - endVal));
};

// 创建一个axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_FINANCE, // url = base url + request url
  timeout: 1000000 // request timeout
});

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 在发送请求之前做一些事情
    if (store.getters.token) {
      // 让每个请求都带有令牌
      // ['X-Token'] is a custom headers key
      // 请根据实际情况进行修改
      config.headers['token'] = getToken();
    }
    // // 特殊情况：无法拿到存储语言时，判断是否是中文环境
    // const navigatorLanguage = navigator.language.toLowerCase().indexOf('zh') > -1 ? 'zh' : 'pu';
    // // const language = localStorage.getItem('language') || 'pu';
    // const language = sessionStorage.getItem('language') || navigatorLanguage;
    // 检测是否是中文环境
    const isChineseEnvironment = navigator.language.toLowerCase().indexOf('zh') > -1;
    // 从 sessionStorage 或者 navigator.language 中获取语言设置
    let language = sessionStorage.getItem('language');
    // 如果 sessionStorage 中不存在语言设置，则使用浏览器语言设置
    if (!language) {
      language = isChineseEnvironment ? 'zh' : 'pu';
    }
    // console.log('默认语言', language);
    config.headers['Accept-Language'] = language;
    // 根据配置决定是否显示加载指示器
    // 默认行为是显示加载指示器，除非config中明确指定isLoading为false
    if (config.isLoading !== false) {
      addLoading();
    }

    return config;
  },
  error => {
    // 做一些请求错误
    loadingObj.instance.close();
    loadingObj.count = 0;
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  /**
     * 通过自定义代码确定请求状态
     * 这只是一个例子
     * 您还可以通过HTTP状态代码来判断状态
     */
  response => {
    const res = response.data;
    // 部分定时器请求导致将业务接口loading关闭，限制某些定时器接口不关闭loading
    // console.log(response.config.url);

    if (!noCloseLoadingUrl.includes(response.config.url)) {
      isCloseLoading();
    }
    if (res instanceof Blob) {
      return {
        status: 'OK',
        data: res
      };
    }
    if (res.code !== 200) {
      // console.error('业务异常: ', res);
      if (res.data === 'LOG_OUT') {
        Message({
          message: res.msg,
          type: 'error',
          duration: 4 * 1000
        });
        setTimeout(() => {
          store.dispatch('user/logout');
          sessionStorage.clear();
          location.reload();
        }, 1000);
        return;
      }
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 4 * 1000
      });
      // 500: 非法令牌;  401: 令牌已过期;
      if (res.code === 401) {
        MessageBox.confirm('您已登出，可以取消停留在此页面上，或者再次登录', '确认登出',
          {
            confirmButtonText: '重新登入',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
          store.dispatch('user/resetToken').then(() => {
            location.reload();
          });
        });
      }
      return Promise.reject(res);
    }

    return res;
  },
  error => {
    console.error('err: ' + error); // for debug
    loadingObj.count = 0;
    loadingObj.instance.close();
    Message({
      message: '服务异常,请稍后再试!',
      type: 'error',
      duration: 4 * 1000
    });
    return Promise.reject(error);
  }
);

export default service;
