var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "el-form",
        {
          staticClass: "demo-form-inline",
          attrs: {
            inline: false,
            model: _vm.formInline,
            "label-position": "top",
            size: "small",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("customerManagements.CompanyName"),
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                          clearable: "",
                        },
                        model: {
                          value: _vm.formInline.companyName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "companyName", $$v)
                          },
                          expression: "formInline.companyName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("collectionCenter.compellation") },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                          clearable: "",
                        },
                        model: {
                          value: _vm.formInline.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "name", $$v)
                          },
                          expression: "formInline.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("orderCenterCont.province") } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                          clearable: "",
                        },
                        model: {
                          value: _vm.formInline.state,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "state", $$v)
                          },
                          expression: "formInline.state",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("orderCenterCont.city") } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                          clearable: "",
                        },
                        model: {
                          value: _vm.formInline.city,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "city", $$v)
                          },
                          expression: "formInline.city",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "CPF" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                          clearable: "",
                        },
                        model: {
                          value: _vm.formInline.cpf,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "cpf", $$v)
                          },
                          expression: "formInline.cpf",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "CNPJ" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                          clearable: "",
                        },
                        model: {
                          value: _vm.formInline.cnpj,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "cnpj", $$v)
                          },
                          expression: "formInline.cnpj",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.zipCode") } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                          clearable: "",
                        },
                        model: {
                          value: _vm.formInline.zipcode,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "zipcode", $$v)
                          },
                          expression: "formInline.zipcode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: { display: "flex", "justify-content": "space-between" },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "danger" },
                  on: { click: _vm.restSearch },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("customerManagements.Resetconditions")) + " "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.entrySearch },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.search")))]
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.newAddress(1)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("customerManagements.newAddress")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: _vm.multipleSelection.length > 1,
                    size: "small",
                    type: "warning",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.newAddress(2)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("collectionCenter.modify")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "danger" },
                  on: { click: _vm.delAddress },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Delete")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "success" },
                  on: { click: _vm.exportAddress },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("customerManagements.exportAddress")) + " "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "table", staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "header-cell-style": { background: "#f2f2f2", color: "black" },
                border: "",
                stripe: "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", type: "selection", width: "55" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.serial"),
                  align: "center",
                  type: "index",
                  width: "55",
                },
              }),
              _vm._l(_vm.tableHeaderList, function (item) {
                return _c("el-table-column", {
                  key: item.id,
                  attrs: {
                    align: item.colCenter,
                    label: item.label,
                    "min-width": item.cloWidth,
                    prop: item.prop,
                  },
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { margin: "10px 0", float: "right" },
        attrs: {
          "current-page": _vm.queryForm.pageNumber,
          "page-size": _vm.queryForm.pageSize,
          "page-sizes": [10, 20, 50, 100],
          total: _vm.queryForm.total,
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "show-close": true,
            title: _vm.titleDialog,
            visible: _vm.addressDialog,
            center: "",
            top: "5vh",
            width: "900px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addressDialog = $event
            },
            close: _vm.handleAddressDialog,
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    inline: "",
                    "label-position": "top",
                    size: "small",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 100 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t("customerManagements.schemeName"),
                                rules: {
                                  required: true,
                                  message: _vm.$t("placeOrder.pleaseInput"),
                                  trigger: ["blur", "change"],
                                },
                                prop: "scheme",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t("placeOrder.pleaseInput"),
                                  clearable: "",
                                  maxlength: "100",
                                },
                                model: {
                                  value: _vm.ruleForm.scheme,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "scheme",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.scheme",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t("collectionCenter.compellation"),
                                rules: {
                                  required: true,
                                  message: _vm.$t("placeOrder.pleaseInput"),
                                  trigger: ["blur", "change"],
                                },
                                prop: "name",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t("placeOrder.pleaseInput"),
                                  clearable: "",
                                  maxlength: "100",
                                },
                                model: {
                                  value: _vm.ruleForm.name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "name",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t(
                                  "customerManagements.CompanyName"
                                ),
                                prop: "companyName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t("placeOrder.pleaseInput"),
                                  clearable: "",
                                  maxlength: "100",
                                },
                                model: {
                                  value: _vm.ruleForm.companyName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "companyName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.companyName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t("customerManagements.stateTwo"),
                                rules: {
                                  required: true,
                                  message: _vm.$t("GlobalSetObj.pleaseChoose"),
                                  trigger: ["blur", "change"],
                                },
                                prop: "state",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.pleaseChoose"
                                    ),
                                    clearable: "",
                                    filterable: "",
                                  },
                                  model: {
                                    value: _vm.ruleForm.state,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "state", $$v)
                                    },
                                    expression: "ruleForm.state",
                                  },
                                },
                                _vm._l(_vm.stateList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.label,
                                      value: item.label,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t("customerManagements.cityName"),
                                rules: {
                                  required: true,
                                  message: _vm.$t("GlobalSetObj.pleaseInput"),
                                  trigger: ["blur", "change"],
                                },
                                prop: "city",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t("placeOrder.pleaseInput"),
                                  clearable: "",
                                  maxlength: "30",
                                },
                                model: {
                                  value: _vm.ruleForm.city,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "city", $$v)
                                  },
                                  expression: "ruleForm.city",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t("GlobalSetObj.userPhone"),
                                prop: "mobile",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t("placeOrder.pleaseInput"),
                                  clearable: "",
                                  maxlength: "100",
                                },
                                model: {
                                  value: _vm.ruleForm.phone,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "phone",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.phone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t("orderCenterCont.Area"),
                                prop: "area",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t("placeOrder.pleaseInput"),
                                  clearable: "",
                                  maxlength: "30",
                                },
                                model: {
                                  value: _vm.ruleForm.area,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "area", $$v)
                                  },
                                  expression: "ruleForm.area",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t(
                                  "orderCenterCont.MobilePhoneNumber"
                                ),
                                rules: _vm.isPhone,
                                prop: "mobile",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t("placeOrder.pleaseInput"),
                                  clearable: "",
                                  maxlength: "30",
                                },
                                model: {
                                  value: _vm.ruleForm.mobile,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "mobile", $$v)
                                  },
                                  expression: "ruleForm.mobile",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t(
                                  "orderCenterCont.RecipientStreet"
                                ),
                                prop: "street",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t("placeOrder.pleaseInput"),
                                  clearable: "",
                                  maxlength: "30",
                                },
                                model: {
                                  value: _vm.ruleForm.street,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "street",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.street",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                rules: _vm.cpf,
                                label: "CPF",
                                prop: "cpf",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "collectionCenter.enterpriseTaxNumTips11"
                                  ),
                                  clearable: "",
                                  maxlength: "16",
                                },
                                model: {
                                  value: _vm.ruleForm.cpf,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "cpf", $$v)
                                  },
                                  expression: "ruleForm.cpf",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                rules: _vm.signCnpj,
                                label: "CNPJ",
                                prop: "cnpj",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "collectionCenter.enterpriseTaxNumTips14"
                                  ),
                                  clearable: "",
                                  maxlength: "25",
                                },
                                model: {
                                  value: _vm.ruleForm.cnpj,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "cnpj", $$v)
                                  },
                                  expression: "ruleForm.cnpj",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t("placeOrder.addressComplement"),
                                prop: "complement",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t("placeOrder.pleaseInput"),
                                  clearable: "",
                                  maxlength: "30",
                                },
                                model: {
                                  value: _vm.ruleForm.complement,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "complement", $$v)
                                  },
                                  expression: "ruleForm.complement",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t("placeOrder.HouseNumber"),
                                rules: {
                                  required: true,
                                  message: _vm.$t("GlobalSetObj.pleaseInput"),
                                  trigger: ["blur", "change"],
                                },
                                prop: "houseNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t("placeOrder.pleaseInput"),
                                  clearable: "",
                                  maxlength: "5",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.ruleForm.houseNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "houseNumber", $$v)
                                  },
                                  expression: "ruleForm.houseNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t("placeOrder.zipcode"),
                                rules: _vm.isZipCode,
                                prop: "zipcode",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t("placeOrder.pleaseInput"),
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.ruleForm.zipcode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "zipcode",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.zipcode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t("placeOrder.detailedAddress"),
                                rules: [
                                  {
                                    required: true,
                                    message: _vm.$t("placeOrder.pleaseInput"),
                                    trigger: ["blur", "change"],
                                  },
                                  {
                                    min: 2,
                                    max: 200,
                                    message: _vm.$t(
                                      "newOrder.TdetailedAddressCharacter"
                                    ),
                                    trigger: "blur",
                                  },
                                ],
                                prop: "addressDetail",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "newOrder.StreetHouseNumber"
                                  ),
                                  clearable: "",
                                  maxlength: "200",
                                  "show-word-limit": "",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.ruleForm.addressDetail,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "addressDetail", $$v)
                                  },
                                  expression: "ruleForm.addressDetail",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t(
                                  "GlobalSetObj.NationalRegistrationNumber"
                                ),
                                rules: _vm.IeNumber1,
                                prop: "ieNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t("placeOrder.pleaseInput"),
                                  clearable: "",
                                  maxlength: "50",
                                },
                                model: {
                                  value: _vm.ruleForm.ieNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "ieNumber",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.ieNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "20px" },
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.addressDialog = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.addressConfirm },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.determine")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }