// const OSS = require('ali-oss');
const COS = require('cos-js-sdk-v5');
const dayjs = require('dayjs');

// 腾讯云COS参数
export const TX_COS = {
  SecretId: 'AKID2vc8UcWIfqskPtEKDtMfookXDD1e6JP6',
  SecretKey: 'PvNpEpU8kfYQE4qgTCoHcnlW4SfroY8W'
};
// 腾讯云COS存储桶参数与存储桶地域
export const TX_COS_BUCKET = {
  Bucket: 'express-1313947203',
  Region: 'sa-saopaulo'
};

/**
 *上传文件到oos服务器
 * @param f 文件
 * @param prefix 前缀
 */
export async function uploadOos(f, prefix) {
  // 返回参数
  const res = {
    code: 200,
    msg: '',
    url: ''
  };
  // 文件大小限制
  if (f.size > 100 * 1000 * 1000) {
    res.code = 400;
    res.msg = '文件不得大于100M!';
    return res;
  }
  // 原阿里oos对象
  // const client = new OSS({ ...ALI_OSS });
  // 腾讯云cos对象
  const client = new COS({ ...TX_COS });
  // 文件格式限制
  const suffix = f.name.substr(f.name.lastIndexOf('.'));
  const fileName = (f.name + '').split('.')[0];
  // 进度显示 开
  const obj = dayjs().format('YYYYMMDDTHHmmss');
  // 这里是生成文件名
  const storeAs = 'upload-file/' + prefix + '/' + fileName + '-' + obj + suffix; // 命名空间
  // 调用函数---return一个promise使cos可以使用await同步运行
  const result = () => {
    return new Promise((resolve, reject) => {
      client.putObject(
        {
          ...TX_COS_BUCKET, // 存储桶参数（地域and存储桶名）
          Key: storeAs, // 上传后的文件名
          StorageClass: 'STANDARD',
          Body: f, // 上传文件对象
          // 上传的进度---要用箭头函数，不然this指向不是Vue实例
          onProgress: (progressData) => {
            // console.log(progressData);
            // onProgress是上传完成才会触发的方法，progressData包含上传完的返回数据。percent是上传完成进度，从0开始到1
          }
        },
        (err, data) => {
          // 错误
          if (err) {
            res.code = 400;
            res.msg = '上传失败';
            reject(err);
            return;
          }
          resolve(data);
          // 正确时赋值
          res.code = data.statusCode;
          res.url = 'https://' + data.Location;
        }
      );
    });
  };
  // 调用函数，获取到参数
  await result();
  // console.log(res, '腾讯云cos');
  if (res.code === 200) {
    // console.log(res);
    return res;
  } else {
    // 进度显示 关
    res.code = 400;
    res.msg = '上传失败!';
    return res;
  }
}

/**
 * 因为从留言板获取的图片虽然 Object.prototype.toString.call(file) === '[object File]' 为true，但是 file instanceof File 为 false
 * 同时阿里云oss 源码内部判断是否为文件对象，使用的是 instanceof File，
 * 所以 只能将 file 转成 blob，再进行上传
 * @param {*} b blob
 * @param {*} prefix
 * @returns
 */
// 留言板文件上传
export async function OSSMessageBoardUpload(b, prefix) {
  // 返回参数
  const res = {
    code: 200,
    msg: '',
    url: ''
  };
  // 原阿里oos对象
  // const client = new OSS({ ...ALI_OSS });
  // 腾讯云cos对象
  const client = new COS({ ...TX_COS });

  const str = dayjs().format('YYYYMMDDTHHmmss');

  // 这里是生成文件名
  const storeAs = 'upload-file/' + prefix + '/' + 'message-board' + '-' + str; // 命名空间

  // 调用函数---return一个promise使cos可以使用await同步运行
  const result = () => {
    return new Promise((resolve, reject) => {
      client.putObject(
        {
          ...TX_COS_BUCKET, // 存储桶参数（地域and存储桶名）
          Key: storeAs, // 上传后的文件名
          StorageClass: 'STANDARD',
          Body: b, // 上传文件对象
          // 上传的进度---要用箭头函数，不然this指向不是Vue实例
          onProgress: (progressData) => {
            // console.log(progressData);
            // onProgress是上传完成才会触发的方法，progressData包含上传完的返回数据。percent是上传完成进度，从0开始到1
          }
        },
        (err, data) => {
          // 错误
          if (err) {
            res.code = 400;
            res.msg = '上传失败';
            reject(err);
            return;
          }
          resolve(data);
          // 正确时赋值
          res.code = data.statusCode;
          res.url = 'https://' + data.Location;
        }
      );
    });
  };
  // 调用函数，获取到参数
  await result();
  // console.log(res, '腾讯云cos');
  if (res.code === 200) {
    return res;
  } else {
    // 进度显示 关
    res.code = 400;
    res.msg = '上传失败!';
    return res;
  }
}
// 财务文件上传
export async function uploadOosFinance(f, prefix) {
  // 返回参数
  const res = {
    code: 200,
    msg: '',
    url: ''
  };
  // 文件大小限制
  if (f.size > 100 * 1000 * 1000) {
    res.code = 400;
    res.msg = '文件不得大于100M!';
    return res;
  }
  // 原阿里oos对象
  // const client = new OSS({ ...ALI_OSS });
  // 腾讯云cos对象
  // 文件格式限制
  const suffix = f.name.substr(f.name.lastIndexOf('.'));
  const fileName = (f.name + '').split('.')[0];
  // 支持的文件格式
  // const fileTypes = '视频格式:.mp4 .avi .rmvb ' +
  //   '图片格式： .jpg .jpeg .gif .pdf'
  // if (fileTypes.indexOf(suffix) === -1) {
  //   res.code = 400
  //   res.msg = '暂不支持该文件格式!'
  //   return res
  // }

  // 文件大小限制
  if (f.size > 100 * 1000 * 1000) {
    res.code = 400;
    res.msg = '文件不得大于100M!';
    return res;
  }
  // 进度显示 开
  // const obj = moment().format('YYYYMMDDTHHmmss');
  // 这里是生成文件名
  const storeAs = 'upload-file/' + prefix + '/' + fileName + suffix; // 命名空间
  const client = new COS({ ...TX_COS });
  // 调用函数---return一个promise使cos可以使用await同步运行
  const result = () => {
    return new Promise((resolve, reject) => {
      client.putObject(
        {
          ...TX_COS_BUCKET, // 存储桶参数（地域and存储桶名）
          Key: storeAs, // 上传后的文件名
          StorageClass: 'STANDARD',
          Body: f, // 上传文件对象
          // 上传的进度---要用箭头函数，不然this指向不是Vue实例
          onProgress: (progressData) => {
            // console.log(progressData);
            // onProgress是上传完成才会触发的方法，progressData包含上传完的返回数据。percent是上传完成进度，从0开始到1
          }
        },
        (err, data) => {
          // 错误
          if (err) {
            res.code = 400;
            res.msg = '上传失败';
            reject(err);
            return;
          }
          resolve(data);
          // 正确时赋值
          res.code = data.statusCode;
          res.url = 'https://' + data.Location;
        }
      );
    });
  };
  // 调用函数，获取到参数
  await result();
  // console.log(res, '腾讯云cos');
  if (res.code === 200) {
    return res;
  } else {
    // 进度显示 关
    res.code = 400;
    res.msg = '上传失败!';
    return res;
  }
}
