var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c("div", { staticClass: "wrapperHeaderTable" }, [
        _c("h2", { staticClass: "title" }, [
          _vm._v(" " + _vm._s(_vm.$t("tablePrice.tablePrice")) + " "),
        ]),
        _c("div", [
          _c(
            "button",
            {
              staticClass: "buttonOutline",
              staticStyle: { width: "160px", "margin-right": "20px" },
              attrs: { type: "button" },
              on: { click: _vm.openDrawer },
            },
            [_vm._v(" " + _vm._s(_vm.$t("tablePrice.filters")) + " ")]
          ),
          _c(
            "button",
            {
              staticClass: "button",
              staticStyle: { width: "160px" },
              attrs: { type: "button" },
              on: { click: _vm.openModal },
            },
            [_vm._v(" " + _vm._s(_vm.$t("tablePrice.add")) + " ")]
          ),
        ]),
      ]),
      _c(
        "el-table",
        {
          staticStyle: { borderradius: "0 0 8px 8px" },
          attrs: { data: _vm.tableDataPrice, "max-height": 600, border: "" },
        },
        [
          _c("el-table-column", { attrs: { type: "index" } }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("tablePrice.country"), prop: "country" },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("tablePrice.regions"),
              prop: "regions",
              width: "160px",
            },
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("tablePrice.state"), prop: "state" },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("tablePrice.city"),
              prop: "city",
              width: "190px",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("tablePrice.ibgeCodes"),
              prop: "ibgeCodes",
              width: "160px",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("tablePrice.capitalDistance"),
              prop: "capitalDistance",
              width: "180px",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("tablePrice.baseDistance"),
              prop: "baseDistance",
              width: "160px",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("tablePrice.macroRegions"),
              prop: "macroRegions",
              width: "160px",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("tablePrice.microRegions"),
              prop: "microRegions",
              width: "160px",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("tablePrice.deliveryPricesZoning"),
              prop: "deliveryPricesZoning",
              width: "160px",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("tablePrice.minimumShipping"),
              prop: "minimumShipping",
              width: "160px",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("tablePrice.minZipCode"),
              prop: "minZipCode",
              width: "160px",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("tablePrice.maxZipCode"),
              prop: "maxZipCode",
              width: "160px",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("tablePrice.indexZipCode"),
              prop: "indexZipCode",
              width: "160px",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("tablePrice.localCluster"),
              prop: "localCluster",
              width: "160px",
            },
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("tablePrice.iata"), prop: "iata" },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("tablePrice.titularPartner"),
              prop: "titularPartner",
              width: "160px",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("tablePrice.baseAddress"),
              prop: "baseAddress",
              width: "200px",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("tablePrice.segment"),
              prop: "segment",
              width: "160px",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("tablePrice.modal"),
              prop: "modal",
              width: "180px",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("tablePrice.riskArea"),
              prop: "riskArea",
              width: "160px",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("tablePrice.lastMileTerm"),
              prop: "lastMileTerm",
              width: "160px",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 0 to 1", prop: "kg0To1", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 1 to 2", prop: "kg1To2", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 2 to 3", prop: "kg2To3", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 3 to 4", prop: "kg3To4", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 4 to 5", prop: "kg4To5", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 5 to 6", prop: "kg5To6", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 6 to 7", prop: "kg6To7", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 7 to 8", prop: "kg7To8", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 8 to 9", prop: "kg8To9", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 9 to 10", prop: "kg9To10", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 10 to 11", prop: "kg10To11", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 11 to 12", prop: "kg11To12", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 12 to 13", prop: "kg12To13", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 13 to 14", prop: "kg13To14", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 14 to 15", prop: "kg14To15", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 15 to 16", prop: "kg15To16", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 16 to 17", prop: "kg16To17", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 17 to 18", prop: "kg17To18", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 18 to 19", prop: "kg18To19", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 19 to 20", prop: "kg19To20", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 20 to 21", prop: "kg20To21", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 21 to 22", prop: "kg21To22", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 22 to 23", prop: "kg22To23", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 23 to 24", prop: "kg23To24", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 24 to 25", prop: "kg24To25", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 25 to 26", prop: "kg25To26", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 26 to 27", prop: "kg26To27", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 27 to 28", prop: "kg27To28", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 28 to 29", prop: "kg28To29", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: { label: "kg 29 to 30", prop: "kg29To30", width: "130px" },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("tablePrice.addKg"),
              prop: "addKg",
              width: "160px",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("tablePrice.createdAt"),
              prop: "createdAt",
              width: "160px",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.operation"),
              align: "center",
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-popconfirm",
                      {
                        attrs: { title: _vm.$t("collectionCenter.deleteSure") },
                        on: {
                          confirm: function ($event) {
                            return _vm.deleteRow(scope.row.id)
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              size: "mini",
                              type: "danger",
                            },
                            slot: "reference",
                          },
                          [_vm._v(_vm._s(_vm.$t("collectionCenter.Delete")))]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.currentPage,
              total: _vm.totalPage,
              background: "",
              layout: "prev, pager, next",
            },
            on: {
              "update:currentPage": function ($event) {
                _vm.currentPage = $event
              },
              "update:current-page": function ($event) {
                _vm.currentPage = $event
              },
              "current-change": _vm.queryTablePrice,
            },
          }),
        ],
        1
      ),
      _vm.isOpenModal
        ? _c(
            "modal-vue",
            {
              attrs: {
                "is-open": _vm.isOpenModal,
                title: "Upload da planilha",
              },
              on: { close: _vm.hideModal },
            },
            [
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "wrapperUpload",
                      attrs: { for: "file" },
                      on: {
                        dragover: function ($event) {
                          $event.preventDefault()
                        },
                        drop: function ($event) {
                          $event.preventDefault()
                        },
                        dragleave: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "containerUpload" }, [
                        _c("input", {
                          ref: "inputFile",
                          staticClass: "hideElement",
                          attrs: {
                            id: "file",
                            accept: ".xlsx,.xls,.csv",
                            name: "file",
                            title: "Nenhum arquivo selecionado",
                            type: "file",
                          },
                          on: { change: _vm.onChangeFile },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "areaUpload",
                            on: { drop: _vm.dropFile },
                          },
                          [
                            _c("div", [
                              _c(
                                "svg",
                                {
                                  staticClass: "mx-auto",
                                  attrs: {
                                    fill: "none",
                                    height: "30",
                                    stroke: "currentColor",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                    "stroke-width": "2",
                                    viewBox: "0 0 24 24",
                                    width: "30",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4",
                                    },
                                  }),
                                  _c("polyline", {
                                    attrs: { points: "17 8 12 3 7 8" },
                                  }),
                                  _c("line", {
                                    attrs: {
                                      x1: "12",
                                      x2: "12",
                                      y1: "3",
                                      y2: "15",
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _c("p", { staticStyle: { "font-size": "16px" } }, [
                              _vm._v("Clique ou arraste aqui"),
                            ]),
                          ]
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "descriptionUpload",
                            staticStyle: { margin: "0px" },
                          },
                          [
                            _vm._v(
                              "Só é permitido arquivos com extensões de planilhas e um único arquivo"
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm.file.length > 0
                    ? _c("div", { staticStyle: { "margin-bottom": "30px" } }, [
                        _c("div", { staticClass: "cardFile" }, [
                          _c("span", { staticStyle: { color: "#009844" } }, [
                            _vm._v(" " + _vm._s(_vm.file[0].name) + " "),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "cardRemove",
                              on: { click: _vm.removeFile },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "lucide lucide-trash",
                                  attrs: {
                                    fill: "none",
                                    height: "24",
                                    stroke: "#FFF",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                    "stroke-width": "2",
                                    viewBox: "0 0 24 24",
                                    width: "16",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", { attrs: { d: "M3 6h18" } }),
                                  _c("path", {
                                    attrs: {
                                      d: "M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c(
                    "button",
                    { staticClass: "button", on: { click: _vm.uploadFile } },
                    [_vm._v("Enviar")]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.isOpenDrawer,
            direction: "ltr",
            title: "Filtros da tabela de preço",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isOpenDrawer = $event
            },
          },
        },
        [
          _c(
            "form",
            {
              staticStyle: { padding: "0 20px" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "formItem" }, [
                _c(
                  "label",
                  { staticClass: "formLabel", attrs: { for: "macroRegions" } },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("tablePrice.macroRegions")) + " "
                    ),
                  ]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.inputMacroRegions,
                      expression: "inputMacroRegions",
                    },
                  ],
                  staticClass: "formInput",
                  attrs: {
                    id: "macroRegions",
                    placeholder: _vm.$t("tablePrice.placeholderMacroRegion"),
                    autocomplete: "off",
                  },
                  domProps: { value: _vm.inputMacroRegions },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.inputMacroRegions = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "formItem" }, [
                _c(
                  "label",
                  { staticClass: "formLabel", attrs: { for: "microRegions" } },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("tablePrice.microRegions")) + " "
                    ),
                  ]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.inputMicroRegions,
                      expression: "inputMicroRegions",
                    },
                  ],
                  staticClass: "formInput",
                  attrs: {
                    id: "microRegions",
                    placeholder: _vm.$t("tablePrice.placeholderMicroRegion"),
                    autocomplete: "off",
                  },
                  domProps: { value: _vm.inputMicroRegions },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.inputMicroRegions = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "formItem" }, [
                _c(
                  "label",
                  { staticClass: "formLabel", attrs: { for: "country" } },
                  [_vm._v(" " + _vm._s(_vm.$t("tablePrice.country")) + " ")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.inputCountry,
                      expression: "inputCountry",
                    },
                  ],
                  staticClass: "formInput",
                  attrs: {
                    id: "country",
                    placeholder: _vm.$t("tablePrice.placeholderCountry"),
                    autocomplete: "off",
                  },
                  domProps: { value: _vm.inputCountry },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.inputCountry = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "formItem" }, [
                _c(
                  "label",
                  { staticClass: "formLabel", attrs: { for: "state" } },
                  [_vm._v(" " + _vm._s(_vm.$t("tablePrice.state")) + " ")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.inputState,
                      expression: "inputState",
                    },
                  ],
                  staticClass: "formInput",
                  attrs: {
                    id: "state",
                    placeholder: _vm.$t("tablePrice.placeholderState"),
                    autocomplete: "off",
                  },
                  domProps: { value: _vm.inputState },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.inputState = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "formItem" }, [
                _c(
                  "label",
                  { staticClass: "formLabel", attrs: { for: "city" } },
                  [_vm._v(" " + _vm._s(_vm.$t("tablePrice.city")) + " ")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.inputCity,
                      expression: "inputCity",
                    },
                  ],
                  staticClass: "formInput",
                  attrs: {
                    id: "city",
                    placeholder: _vm.$t("tablePrice.placeholderCity"),
                    autocomplete: "off",
                  },
                  domProps: { value: _vm.inputCity },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.inputCity = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "formItem" }, [
                _c(
                  "label",
                  { staticClass: "formLabel", attrs: { for: "maxZipCode" } },
                  [_vm._v(" " + _vm._s(_vm.$t("tablePrice.maxZipCode")) + " ")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.inputMaxZipCode,
                      expression: "inputMaxZipCode",
                    },
                  ],
                  staticClass: "formInput",
                  attrs: {
                    id: "maxZipCode",
                    placeholder: _vm.$t("tablePrice.placeholderMaxZipCode"),
                    autocomplete: "off",
                  },
                  domProps: { value: _vm.inputMaxZipCode },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.inputMaxZipCode = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "formItem" }, [
                _c(
                  "label",
                  { staticClass: "formLabel", attrs: { for: "minZipCode" } },
                  [_vm._v(" " + _vm._s(_vm.$t("tablePrice.minZipCode")) + " ")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.inputMinZipCode,
                      expression: "inputMinZipCode",
                    },
                  ],
                  staticClass: "formInput",
                  attrs: {
                    id: "minZipCode",
                    placeholder: _vm.$t("tablePrice.placeholderMinZipCode"),
                    autocomplete: "off",
                  },
                  domProps: { value: _vm.inputMinZipCode },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.inputMinZipCode = $event.target.value
                    },
                  },
                }),
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    "margin-top": "40px",
                  },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "buttonOutline",
                      staticStyle: { "margin-right": "80px" },
                      on: { click: _vm.clearFilter },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("tablePrice.clean")) + " ")]
                  ),
                  _c(
                    "button",
                    { staticClass: "button", on: { click: _vm.filter } },
                    [_vm._v(" " + _vm._s(_vm.$t("tablePrice.consult")))]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }