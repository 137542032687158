<template>
  <div class="Box">
    <el-form style="margin-bottom: 20px;" class="selectClass">
      <el-row>
        <el-col :span="10" style="width: auto;">
          <!-- 请求时间： -->
          <el-form-item :label="$t('orderCenterCont.RequestTime') + '：'">
            <el-date-picker
              v-model="timeValue"
              :clearable="false"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :range-separator="$t('collectionCenter.to')"
              :start-placeholder="$t('collectionCenter.StartTime')"
              :end-placeholder="$t('collectionCenter.EndTime')"
              @change="dateChange"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6" style="width: auto;">
          <!-- 客户名称： -->
          <el-form-item :label="$t('collectionCenter.CustomerN')">
            <el-select
              v-model="queryForm.params.customerId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
              filterable @focus="getAllCustomer"
            >
              <el-option
                v-for="(item, index) in customerIdList"
                :key="index"
                :label="item.username"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" style="width: auto;">
          <!-- 响应结果： -->
          <el-form-item :label="$t('collectionCenter.responseResult') + '：'">
            <el-select
              v-model="queryForm.params.state"
              :placeholder="$t('collectionCenter.pleaseChoose')"
              filterable
            >
              <el-option :label="$t('collectionCenter.tudo')" :value="null" /><!-- 全部 -->
              <el-option :label="$t('GlobalSetObj.succeed')" :value="1" /><!-- 成功 -->
              <el-option :label="$t('GlobalSetObj.Lose')" :value="0" /><!-- 失败 -->
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" style="width: auto;" class="m BlockNewlineClass">
          <!-- 揽收订单号 / 客户单号 / 条形码 / 包裹号 -->
          <el-form-item>
            <el-select
              v-model="selectNum" :placeholder="$t('collectionCenter.pleaseChoose')" class="autoWidth"
              @change="changeNum"
            >
              <template slot="prefix">
                {{ (numList.find(item => item.value === selectNum) || {}).label }}
              </template>
              <el-option
                v-for="(item, index) in numList"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <el-input
              v-model="oddNumber"
              type="textarea"
              @blur="ft"
              @keydown.enter.native="BlockNewline"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6" style="width: auto;">
          <!-- 订单来源： -->
          <el-form-item :label="$t('orderCenterCont.OrderSource') + '：'">
            <el-select
              v-model="queryForm.params.orderSource"
              :placeholder="$t('collectionCenter.pleaseChoose')"
              filterable
              clearable
            >
              <el-option :label="$t('collectionCenter.tudo')" :value="null" /><!-- 全部 -->
              <el-option :label="$t('collectionCenter.lanshou')" :value="1" /><!-- 揽收 -->
              <el-option :label="$t('basicData.StockingAndPickup')" :value="2" /><!-- 备货揽收 -->
              <el-option :label="$t('collectionCenter.expressDelivery')" :value="3" /><!-- 快递 -->
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row>
      <el-col :span="24">
        <el-button
          v-permit:remove="'btn:collection-center:Order:orderLogQuery:search'"
          type="primary"
          size="mini"
          icon="el-icon-search"
          @click="searchClick"
        >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
        <el-button
          v-permit:remove="'btn:collection-center:Order:orderLogQuery:reset'"
          icon="el-icon-refresh"
          size="mini"
          @click="resetClick"
        >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
        <el-button
          v-permit:remove="'btn:collection-center:Order:orderLogQuery:export'"
          icon="el-icon-upload2"
          size="mini"
          @click="download"
        >{{ $t('collectionCenter.export') }}</el-button><!-- 导出 -->
        <el-button
          v-permit:remove="'btn:collection-center:Order:orderLogQuery:allExport'"
          icon="el-icon-upload"
          size="mini"
          @click="downloadAll"
        >{{ $t('collectionCenter.allExport') }}</el-button><!-- 全部导出 -->
      </el-col>
    </el-row>
    <el-divider />
    <el-table
      ref="tableList"
      :max-height="600"
      border
      :data="tableDate"
      @select="batchSelect"
      @select-all="batchSelect"
    >
      <el-table-column
        type="selection"
        width="55"
      />
      <!-- 序号 -->
      <el-table-column
        :label="$t('collectionCenter.ind')" type="index" width="60"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="customerId"
        :label="$t('collectionCenter.clientID')"
      /><!-- 客户编号 -->
      <el-table-column
        align="center"
        prop="customerName"
        :label="$t('collectionCenter.CustomerName')"
      /><!-- 客户名称 -->
      <el-table-column
        align="center"
        prop="customerNumber"
        :label="$t('collectionCenter.customerOrderNum')"
      /><!-- 客户订单号 -->
      <el-table-column
        align="center"
        prop="scanNumber"
        :label="$t('collectionCenter.Barcode')"
      /><!-- 条形码 -->
      <el-table-column
        align="center"
        prop="orderNumber"
        :label="$t('collectionCenter.lanshouOrderNum')"
      /><!-- 揽收订单号 -->
      <el-table-column
        align="center"
        prop="packageNumber"
        :label="$t('collectionCenter.parcelNum')"
      /><!-- 包裹号 -->
      <el-table-column
        align="center"
        prop="requestParams"
        :label="$t('orderCenterCont.RequestMessages')"
      ><!-- 请求报文 -->
        <template slot-scope="scope">
          <el-tooltip placement="top">
            <div slot="content" style="max-height: 200px;overflow: auto;width:500px;">{{ scope.row.requestParams }}</div>
            <div class="paramsStyle">{{ scope.row.requestParams }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="stateDesc"
        :label="$t('collectionCenter.responseResult')"
      /><!-- 响应结果 -->
      <el-table-column
        align="center"
        prop="failureMessage"
        :label="$t('GlobalSetObj.ReasonForFailure')"
      /><!-- 失败原因 -->
      <el-table-column
        align="center"
        prop="orderSourceDesc"
        :label="$t('orderCenterCont.OrderSource')"
      /><!-- 订单来源 -->
    </el-table>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100, 500, 1000]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getDatePage"
        @size-change="getPageSize"
      />
    </div>
  </div>
</template>

<script>
import {
  queryAllCustomer,
  collectOrderLogPage,
  collectOrderLogExport,
  collectOrderLogExportAll
} from '@/api/lanshou';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList.js';

export default {
  name: 'OrderLogQuery',
  data() {
    return {
      notEnter: true, // 是否允许换行
      selectNum: 'c1',
      numList: [
        { value: 'c1', label: this.$t('collectionCenter.lanshouOrderNum') }, // '揽收订单号'
        { value: 'c2', label: this.$t('collectionCenter.customerNum') }, // '客户单号'
        { value: 'c3', label: this.$t('collectionCenter.Barcode') }, // '条形码'
        { value: 'c4', label: this.$t('collectionCenter.parcelNum') } // '包裹号'
      ],
      oddNumber: '',
      customerIdList: [], // 客户名称列表
      timeValue: [],
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: '',
          endTime: '',
          customerId: '', // 客户编号
          state: '', // 响应结果(全部空值 1:成功 0:失败)
          orderSource: '', // 订单来源(全部空值 1:揽收 2:备货揽收 3:快递)
          orderNumberList: [], // 揽收订单号
          customerNumberList: [], // 客服订单号
          scanNumberList: [], // 条形码
          packageNumberList: [] // 包裹号
        }
      },
      total: 0,
      tableDate: [],
      idList: []
    };
  },
  watch: {
    'oddNumber'(val) {
      let bgh = val.split('\n');
      // 过滤空项
      bgh = bgh.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = bgh.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      let number = [];
      if (bghTrim.length >= 1000) {
        number = bghTrim.slice(0, 1000).join('\n');
        this.oddNumber = number;
        this.notEnter = false;
      } else {
        this.notEnter = true;
      }
      if (bghTrim.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    }
  },
  created() {
    this.getAllCustomer();
    this.resetClick();
    this.searchClick();
  },
  methods: {
    getAllCustomer() {
      // 查询条件——客户列表
      queryAllCustomer().then(res => {
        if (res.status === 'OK') {
          this.customerIdList = [];
          res.data.forEach((list) => {
            this.customerIdList.push({
              id: list.id,
              username: list.username
            });
          });
        }
      });
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        this.queryForm.params.startTime = '';
        this.queryForm.params.endTime = '';
        return;
      }
      this.queryForm.params.startTime = val[0];
      this.queryForm.params.endTime = val[1];
      console.log('startTime', this.queryForm.params.startTime, 'endTime', this.queryForm.params.endTime);
      if (this.queryForm.params.endTime) {
        const endTime = dayjs(this.queryForm.params.startTime).subtract(-6, 'day').format('YYYY-MM-DD') + ' 23:59:59';
        if (this.queryForm.params.endTime > endTime) {
          this.$message({
            // 最多可查询到7天的数据
            message: this.$t('collectionCenter.max7DaysDate'),
            type: 'warning'
          });
          const d = new Date();
          const day = dayjs(d).format('YYYY-MM-DD');
          this.timeValue = [day + ' 00:00:00', day + ' 23:59:59'];
          this.queryForm.params.startTime = day + ' 00:00:00';
          this.queryForm.params.endTime = day + ' 23:59:59';
        }
      }
    },
    changeNum() {
      this.oddNumber = '';
      this.queryForm.params.orderNumberList = [];
      this.queryForm.params.customerNumberList = [];
      this.queryForm.params.scanNumberList = [];
      this.queryForm.params.packageNumberList = [];
    },
    ft() {
      const val = this.oddNumber.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = numLen.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      this.oddNumber = bghTrim.join('\n');
      this.queryForm.params.orderNumberList = [];
      this.queryForm.params.customerNumberList = [];
      this.queryForm.params.scanNumberList = [];
      this.queryForm.params.packageNumberList = [];
      if (this.selectNum === 'c1') {
        this.queryForm.params.orderNumberList = bghTrim;
      } else if (this.selectNum === 'c2') {
        this.queryForm.params.customerNumberList = bghTrim;
      } else if (this.selectNum === 'c3') {
        this.queryForm.params.scanNumberList = bghTrim;
      } else if (this.selectNum === 'c4') {
        this.queryForm.params.packageNumberList = bghTrim;
      }
    },
    BlockNewline() { // 文本域最大输入行数为1000
      const e = window.event || arguments[0];
      this.ft();
      const numberLen = this.oddNumber.split('\n');
      if (!this.notEnter) {
        if (numberLen.length >= 1000) {
          this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    resetClick() {
      const d = new Date();
      const day = dayjs(d).format('YYYY-MM-DD');
      this.timeValue = [day + ' 00:00:00', day + ' 23:59:59'];
      this.selectNum = 'c1';
      this.oddNumber = '';
      this.queryForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: day + ' 00:00:00',
          endTime: day + ' 23:59:59',
          customerId: '', // 客户编号
          state: '', // 响应结果(全部空值 1:成功 0:失败)
          orderSource: '', // 订单来源(全部空值 1:揽收 2:备货揽收 3:快递)
          orderNumberList: [], // 揽收订单号
          customerNumberList: [], // 客服订单号
          scanNumberList: [], // 条形码
          packageNumberList: [] // 包裹号
        }
      };
    },

    getDatePage() {
      const queryForm = JSON.parse(localStorage.getItem('orderLogQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      collectOrderLogPage(queryForm).then(res => {
        if (res.status === 'OK') {
          this.total = res.data.total;
          this.tableDate = res.data.records;
        }
      }).catch({});
    },
    searchClick() {
      if (!this.queryForm.params.startTime || !this.queryForm.params.endTime) {
        this.$message({
          message: this.$t('collectionCenter.PleaseSelectTime'),
          type: 'warning'
        });
        return;
      }
      this.idList = [];
      this.queryForm.pageNumber = 1;
      localStorage.setItem('orderLogQueryForm', JSON.stringify(this.queryForm));
      this.getDatePage();
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      const query = JSON.parse(localStorage.getItem('orderLogQueryForm')) || this.queryForm;
      const queryForm = {
        pageNumber: 1,
        pageSize: this.queryForm.pageSize,
        params: query.params
      };
      collectOrderLogPage(queryForm).then(res => {
        if (res.status === 'OK') {
          console.log(res, 'res');
          this.total = res.data.total;
          this.tableDate = res.data.records;
        }
      }).catch({});
    },

    batchSelect(selection) {
      const IdList = [];
      selection.forEach(list => {
        IdList.push(list.id);
      });
      this.idList = IdList;
    },
    download() {
      if (this.idList.length <= 0) {
        // '请勾选要导出的数据'
        this.$message.warning(this.$t('collectionCenter.PleaseTickTheDataExport'));
        return;
      }
      const data = {
        collectOrderLogIdList: this.idList
      };
      collectOrderLogExport(data).then(res => {
        if (res.status === 'OK') {
          goExportList(this).finally(() => {
            this.$refs.tableList.clearSelection();
          });
          // const h = this.$createElement;
          // this.$msgbox({
          //   // 提示
          //   title: this.$t('collectionCenter.tips'),
          //   message: h('p', null, [
          //     // 导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载,
          //     h('span', this.$t('collectionCenter.ExportSuccessful')),
          //     // 请勿重复导出!
          //     h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
          //   ]),
          //   showCancelButton: true,
          //   // 立即前往
          //   confirmButtonText: this.$t('collectionCenter.Immediately'),
          //   // 关闭
          //   cancelButtonText: this.$t('collectionCenter.close')
          // }).then(action => {
          //   this.$refs.tableList.clearSelection();
          //   if (action === 'confirm') {
          //     this.$router.push({ path: '/resource/exportList' });
          //   }
          // }).catch(() => {
          //   this.$refs.tableList.clearSelection();
          // });
        }
      });
    },
    downloadAll() {
      const queryForm = JSON.parse(localStorage.getItem('orderLogQueryForm')) || this.queryForm;
      collectOrderLogExportAll(queryForm.params).then(res => {
        if (res.status === 'OK') {
          goExportList(this);
        }
      }).catch({});
    }
  }
};
</script>

<style lang="scss" scoped>
  .Box{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .selectClass ::v-deep .el-form-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .selectClass ::v-deep .el-form-item__label{
    padding: 0;
    line-height: 16px;
    white-space: nowrap;
    margin-left: 20px;
  }
  .selectClass ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
  .selectClass ::v-deep .el-select{
    width: 100%;
  }

  .autoWidth ::v-deep .el-input__prefix{ // 下拉框自适应占位
    position: relative;
    left: 0;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    padding: 0 25px;
    padding-left: 0;
    margin-left: 20px;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    visibility: hidden;
    white-space: nowrap;
  }
  .autoWidth ::v-deep input {
    position: absolute;
  }
  .m ::v-deep .el-form-item__content{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0px !important;
  }
  .m ::v-deep .el-form-item__content .autoWidth{
    width: auto !important;
  }
  .m ::v-deep .el-textarea__inner{
    min-height: 40px !important;
    max-height: 200px !important;
  }
  .autoWidth ::v-deep.el-input--suffix .el-input__inner{
    border: none !important;
    font-size: 14px;
    color: #606266;
    font-weight: bold;
    text-align: right;
    padding-left: 0;
    box-sizing: border-box;
  }
  .BlockNewlineClass ::v-deep textarea.el-textarea__inner {
    min-height: 40px !important;
    max-height: 150px !important;
  }
  .BlockNewlineClass ::v-deep .el-form-item__content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .paramsStyle{
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 200px;
    max-width: 100%;
    display: inline-block;
    word-wrap: break-word;
    word-break: break-all;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

</style>
