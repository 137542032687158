import i18n from '@/lang';

// 模块
export const MODULE_OBJ = Object.freeze({
  1: {
    name: i18n.t('basicData.Receivables'), // 应收
    value: 1
  },
  2: {
    name: i18n.t('basicData.payable'), // 应付
    value: 2
  },
  3: {
    name: i18n.t('receivablePayable.ImportAddCharges'), // 导入添加费用
    value: 3
  },
  4: {
    name: i18n.t('receivablePayable.ImportModifyExpenses'), // 导入修改费用
    value: 4
  },
  5: {
    name: i18n.t('receivablePayable.BatchImportClips'), // 批量导入快录
    value: 5
  },
  6: {
    name: 'CTE',
    value: 6
  },
  7: {
    name: i18n.t('receivablePayable.揽收费异常费用'),
    value: 7
  },
  8: {
    name: i18n.t('receivablePayable.ImportReceivableGeneration'), // 导入应收生成
    value: 8
  }
});

// 操作功能
export const OPERATION_OBJ = Object.freeze({
  3: {
    name: i18n.t('receivablePayable.ImportAddCharges'), // 导入添加费用
    value: 3
  },
  4: {
    name: i18n.t('receivablePayable.ImportModifyExpenses'), // 导入修改费用
    value: 4
  },
  5: {
    name: i18n.t('receivablePayable.BatchImportClips'), // 批量导入快录
    value: 5
  },
  7: {
    name: i18n.t('receivablePayable.揽收费异常费用'), // 揽收费异常费用
    value: 7
  },
  8: {
    name: i18n.t('receivablePayable.ImportReceivableGeneration'), // 导入应收生成
    value: 8
  }
});

// 导入状态
export const IMPORT_STATUS_OBJ = Object.freeze({
  0: {
    name: i18n.t('newOrder.processing'), // 处理中
    value: 0
  },
  1: {
    name: i18n.t('newOrder.ProcessingComplete'), // 处理成功
    value: 1
  },
  2: {
    name: i18n.t('newOrder.processingFailure'), // 处理失败
    value: 2
  }
});
