<template>
  <div class="app-container">
    <el-tabs type="border-card">
      <!--列表-->
      <el-tab-pane :label="$t('orderCenterCont.list')">
        <list
          ref="listData"
          @changeOutletsData="changeOutletsData"
          @downloadTheMenuFee="downloadTheMenuFee"
          @updateSheets="updateSheets"
        />
      </el-tab-pane>
      <!--扫描-->
      <el-tab-pane :label="$t('orderCenterCont.scanTab')">
        <scan
          ref="scanData"
          @changeOutletsData="changeOutletsData"
          @downloadTheMenuFee="downloadTheMenuFee"
          @scanUpdate="scanUpdate"
          @updateSheets="updateSheets"
        />
      </el-tab-pane>
    </el-tabs>
    <!--预派送网点-->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('GlobalSetObj.ModifyPreDeliveryLocation')"
      :visible.sync="showChangeOutlets"
      width="500px"
      @close="closeData"
    >
      <modifyPreDeliveryLocation
        :key="showChangeOutlets"
        :selection-list="selectionList"
        :site-id-list="siteIdList"
        @changeOutletsDataClose="changeOutletsDataClose"
      />
    </el-dialog>
    <!--更新面单-->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('orderExport.hint')"
      :visible.sync="showFaceSheets"
      width="400px"
    >
      <div>
        <!--操作成功-->
        <p>{{ $t('GlobalSetObj.TheOperationSucceeded') }}!</p>
        <div style="display: flex;">
          <!--全部-->
          <div style="flex: 1">{{ $t('GlobalSetObj.all') }}: {{ totalFaceSheets }}</div>
          <!--更新成功-->
          <div style="flex: 1">{{ $t('newOrder.TheUpdateWasSuccessful') }}: {{ successful }}</div>
          <!--更新失败-->
          <div style="flex: 1">{{ $t('newOrder.TheUpdateFailed') }}: {{ failOrder }}</div>
        </div>
        <div v-if="errList.length">
          <div style="margin: 30px 0 10px 0;">{{ $t('newOrder.TheUpdateFailed') }}：</div>
          <div style="border: 1px solid #ccc;border-radius: 3px;padding: 10px;max-height: 260px;overflow: auto;">
            <div v-for="(item, ind) in errList" :key="ind" style="margin: 5px 0;">
              {{ item.waybillNumber }}：{{ item.failReason }}
            </div>
          </div>
        </div>
        <div style="margin-top: 40px;text-align: center;">
          <!--查看详情-->
          <el-button size="small" type="primary" @click="checkedOrder()">{{
            $t('orderCenterCont.ViewDetails')
          }}
          </el-button>
          <!--关闭-->
          <el-button size="small" type="primary" @click="showFaceSheets = false">{{
            $t('orderCenterCont.ShutDown')
          }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import list from './cmp/list';
import scan from './cmp/scan';
import modifyPreDeliveryLocation from '../orderPush/cmp/modifyPreDeliveryLocation';
import { apiUpdateLabelCql, getProviderSiteTree } from '@/api/logisticsOrders/index';
import { batchPdfDownload } from '@/utils/PDF_util';
import { setDateTime2 } from '@/utils/date_util';
import { cloneDeep } from 'lodash-es';
import { saveAs } from 'file-saver';

export default {
  name: 'PendingReturnOrders',
  components: {
    list,
    scan,
    modifyPreDeliveryLocation
  },
  data() {
    return {
      showChangeOutlets: false,
      // selectedOutlets: null,
      siteIdList: [],
      selectionList: [],
      totalFaceSheets: 0,
      successful: 0,
      failOrder: 0,
      errList: [],
      showFaceSheets: false,
      checkType: null
    };
  },
  created() {
    this.getRoleTree();
  },
  methods: {
    // 关闭弹窗
    changeOutletsDataClose() {
      this.showChangeOutlets = false;
      this.selectionList = [];
      if (this.checkType === 'list') {
        this.$refs.listData.searchResources(1);
      } else {
        this.$refs.scanData.searchResources();
      }
    },
    scanUpdate() {
      this.$refs.listData.searchResources(1);
    },
    // 下载面单
    downloadTheMenuFee(downloadArr, type) {
      this.checkType = type;
      // 手动显示加载中动画
      const loading = this.$loading({
        fullscreen: true,
        lock: true,
        spinner: 'el-icon-loading',
        text: this.$i18n.t('orderCenterCont.DesperatelyLoading'), // '拼命加载中',
        background: 'rgba(250, 250, 250, 0.6)'
      });
      batchPdfDownload(downloadArr).then((res) => {
        const dateType = setDateTime2();
        const saveName = `${dateType}` + this.$i18n.t('orderCenterCont.FaceSheet');
        saveAs(res, saveName);
        // const a = document.createElement('a');
        // a.download = `${dateType}`;
        // a.href = res;
        // a.download = `${dateType}` + this.$i18n.t('orderCenterCont.FaceSheet');
        // a.click();
        // a.remove();
      }).finally(() => {
        loading.close();
      });
    },
    // 更新面单
    updateSheets(orderList, type) {
      this.selectionList = cloneDeep(orderList);
      this.checkType = type;
      apiUpdateLabelCql(orderList).then(res => {
        if (res.status === 'OK') {
          this.successful = res.data.successRequest;
          this.failOrder = res.data.failRequest;
          this.totalFaceSheets = res.data.countRequest;
          this.errList = res.data.failList;
          this.showFaceSheets = true;
        }
      });
    },
    // 查看详情
    checkedOrder() {
      console.log(this.selectionList, '==000000');
      this.showFaceSheets = false;
      if (this.checkType === 'list') {
        this.$refs.listData.getListData(this.selectionList.join('\n'));
      } else {
        this.$refs.scanData.getListData(this.selectionList.join('\n'));
      }
    },
    // 清空已选网点
    closeData() {
      this.selectedOutlets = null;
    },
    // 修改预派送网点
    changeOutletsData(data, type) {
      this.checkType = type;
      this.selectionList = cloneDeep(data);
      this.showChangeOutlets = true;
    },
    // 获取权限树
    getRoleTree() {
      const param = {
        providerChannelName: ''
      };
      getProviderSiteTree(param).then(res => {
        if (res.status === 'OK') {
          const listData = (data) => {
            data.map(item => {
              if (item.siteList && item.siteList.length > 0) {
                item.children = item.siteList;
                listData(item.siteList);
              }
            });
            return data;
          };
          this.siteIdList = listData(res.data);
        }
      });
    },
    // 打开修改预派送网点弹窗
    changeOutlets() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('collectionCenter.checkMsg'));
        return;
      }
      this.showChangeOutlets = true;
    }
  }
};
</script>

<style>
.selectLabel .el-input__inner {
  border: none;
  height: 28px;
  line-height: 28px;
  font-weight: bold;
}

.searchInputCont .el-form-item {
  margin-bottom: 5px;
}
</style>
