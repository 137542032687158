var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      staticStyle: { width: "100%" },
      attrs: {
        loading: _vm.loading,
        placeholder: _vm.$t("spareNumberPoo.Only10AreDisplayed"),
        clearable: _vm.clearable,
        multiple: _vm.multiple,
        "multiple-limit": _vm.multipleLimit,
        filterable: "",
        remote: "",
        "reserve-keyword": _vm.multiple,
        "remote-method": _vm.getSitePageList,
      },
      on: { change: _vm.onChange },
      model: {
        value: _vm.siteId,
        callback: function ($$v) {
          _vm.siteId = $$v
        },
        expression: "siteId",
      },
    },
    [
      _vm._l(_vm.list, function (item) {
        return _c("el-option", {
          key: item.value,
          attrs: { label: item.label, value: item.value },
        })
      }),
      _vm.list.length === 0
        ? _c("el-option", {
            attrs: {
              value: -1,
              label: _vm.$t("operatingPlatform.nullMsg"),
              disabled: "",
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }