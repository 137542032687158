<template>
  <div class="app-container">
    <!--       default-expand-all -->
    <div class="pcpdaClass">PC</div>
    <el-tree
      ref="treeRefPC"
      :data="permissionList.pc"
      :default-checked-keys="defSelectedPermissionList.pc"
      :props="props"
      icon-class="el-icon-thumb"
      node-key="id"
      show-checkbox
    />
    <div class="pcpdaClass" style="margin-top: 20px;">PDA</div>
    <el-tree
      ref="treeRefPDA"
      :data="permissionList.pda"
      :default-checked-keys="defSelectedPermissionList.pda"
      :props="props"
      icon-class="el-icon-thumb"
      node-key="id"
      show-checkbox
    />
  </div>
</template>

<script>
import { getPermissionStructureAll } from '@/api/permission';

export default {
  name: 'PermissionTree',
  props: {
    permissionTreePop: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      props: {
        label: 'name',
        children: 'children'
      },
      // 权限树
      permissionList: {
        pc: [],
        pda: []
      },
      // 选中了的节点
      selectedPermissionList: {
        pc: [],
        pda: []
      },
      // 默认选中节点
      defSelectedPermissionList: {
        pc: [],
        pda: []
      }
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      getPermissionStructureAll().then((response) => {
        const { permissionStructurePc, permissionStructurePda, lastChildrenIdsPc, lastChildrenIdsPda } = response.data;
        if (permissionStructurePc) {
          this.permissionList.pc = permissionStructurePc;
          if (this.permissionTreePop.selectedPermissionList.pc) {
            this.defSelectedPermissionList.pc = this.permissionTreePop.selectedPermissionList.pc.filter(
              (item) => {
                return lastChildrenIdsPc.indexOf(item) !== -1;
              }
            );
          }
        }
        if (permissionStructurePda) {
          this.permissionList.pda = permissionStructurePda;
          if (this.permissionTreePop.selectedPermissionList.pda) {
            this.defSelectedPermissionList.pda = this.permissionTreePop.selectedPermissionList.pda.filter(
              (item) => {
                return lastChildrenIdsPda.indexOf(item) !== -1;
              }
            );
          }
        }
      });
    },
    getSelectedPermissionList() {
      const checkedKeys = this.$refs.treeRefPC.getCheckedKeys();
      const halfCheckedKeys = this.$refs.treeRefPC.getHalfCheckedKeys();
      const checkedNodes = this.$refs.treeRefPC.getCheckedNodes();
      const halfCheckedNodes = this.$refs.treeRefPC.getHalfCheckedNodes();

      const checkedKeysPDA = this.$refs.treeRefPDA.getCheckedKeys();
      const halfCheckedKeysPDA = this.$refs.treeRefPDA.getHalfCheckedKeys();
      const checkedNodesPDA = this.$refs.treeRefPDA.getCheckedNodes();
      const halfCheckedNodesPDA = this.$refs.treeRefPDA.getHalfCheckedNodes();

      checkedNodes.push(...checkedNodesPDA);
      halfCheckedNodes.push(...halfCheckedNodesPDA);
      const menuPermissions = [];
      const buttonPermissions = [];
      checkedNodes.map(item => {
        const code = item.code.split(':')[0];
        if (code === 'menu') {
          menuPermissions.push(item.code);
        } else {
          buttonPermissions.push(item.code);
        }
      });
      halfCheckedNodes.map(item => {
        const code = item.code.split(':')[0];
        if (code === 'menu') {
          menuPermissions.push(item.code);
        } else {
          buttonPermissions.push(item.code);
        }
      });
      // const permissionIds = halfCheckedKeys.concat(checkedKeys);
      const permissionIds = halfCheckedKeys.concat(checkedKeys).concat(halfCheckedKeysPDA).concat(checkedKeysPDA);

      return {
        permissionIds,
        menuPermissions,
        buttonPermissions
      }; // halfCheckedKeys.concat(checkedKeys);
    }
  }
};
</script>

<style scoped>
.pcpdaClass {
  line-height: 17px;
  font-size: 17px;
  padding: 5px;
  background: linear-gradient(to right, #ededed, #fff);
}

.el-tree {
  min-width: 100%;
  display: inline-block;
}

.tree {
  overflow: auto;
  max-height: 400px;
}
</style>
