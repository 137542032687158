<script >
import { ref, defineProps } from '@vue/composition-api';
import { useQueryClient } from '@tanstack/vue-query';
import { Message } from 'element-ui';
import { driverService } from '@/api/transport/driver';
import getMessageError from '@/utils/getMessageError';
import { toast } from '@/utils/programaticToast';
import { getToastContentComponent } from '@/utils/getToastContentComponent';
const __sfc_main = {};
__sfc_main.props = {
  driverId: {
    type: Number,
    require: true
  },
  closeModal: {
    type: Function,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const queryClient = useQueryClient();
  const observation = ref(null);
  async function handleSubmit() {
    try {
      await driverService.delete(props.driverId, observation.value);
      queryClient.invalidateQueries({
        queryKey: ['drivers']
      });
      props.closeModal();
      toast.open({
        variant: 'success',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Ecluído com sucesso',
          describe: 'As informações foram excluídas com sucesso',
          typeIcon: 'success'
        }),
        closable: true,
        duration: 4000
      });
    } catch (error) {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Ocorreu um erro',
          describe: getMessageError(error),
          typeIcon: 'error'
        }),
        closable: true,
        duration: 4000
      });
    }
  }
  return {
    observation,
    handleSubmit
  };
};
export default __sfc_main;
</script>

<template>
  <form @submit.prevent="handleSubmit">
    <o-field
      :label="$t('driver.modalDeleteObservation')"
      label-for="observation"
    >
      <o-input
        id="observation"
        v-model="observation"
        :placeholder="$t('driver.modalDeletePlaceholder')"
      />
    </o-field>

    <div class="wrapperButton">
      <o-button
        variant="primary"
        size="md"
        native-type="submit"
      >
        {{ $t('driver.modalSendButton') }}
      </o-button>
    </div>
  </form>
</template>

<style scoped>
  .wrapperButton {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }
</style>
