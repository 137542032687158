var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-form",
            { attrs: { "label-position": "top" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { staticStyle: { height: "70px" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.waybillNumber"),
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.queryForm.params.waybillNumbers,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm.params,
                                  "waybillNumbers",
                                  $$v
                                )
                              },
                              expression: "queryForm.params.waybillNumbers",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { height: "70px" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("GlobalSetObj.fuwushang") } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.queryForm.params.logisticsName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "logisticsName",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.logisticsName",
                              },
                            },
                            _vm._l(_vm.serviceList, function (item, ind) {
                              return _c("el-option", {
                                key: ind,
                                attrs: { label: item, value: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { height: "70px" }, attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.taskCreationTime"),
                          },
                        },
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "div",
                              { staticClass: "selectLabelStyle" },
                              [
                                _c(
                                  "el-select",
                                  {
                                    style: {
                                      width:
                                        _vm.$i18n.locale == "zh"
                                          ? "160px"
                                          : "280px",
                                    },
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "GlobalSetObj.pleaseChoose"
                                      ),
                                    },
                                    model: {
                                      value:
                                        _vm.queryForm.params.searchDateType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryForm.params,
                                          "searchDateType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "queryForm.params.searchDateType",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      key: "1",
                                      attrs: {
                                        label: _vm.$t(
                                          "newOrder.CancellationTime"
                                        ),
                                        value: "1",
                                      },
                                    }),
                                    _c("el-option", {
                                      key: "2",
                                      attrs: {
                                        label: _vm.$t(
                                          "GlobalSetObj.TheOperationSucceededTime"
                                        ),
                                        value: "2",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "default-time": ["00:00:00", "23:59:59"],
                              format:
                                _vm.$i18n.locale == "zh"
                                  ? _vm.zhDate
                                  : _vm.ptDate,
                              placeholder: _vm.$t("newOrder.SelectDatetime"),
                              clearable: "",
                              type: "datetimerange",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                            },
                            model: {
                              value: _vm.createStartDate,
                              callback: function ($$v) {
                                _vm.createStartDate = $$v
                              },
                              expression: "createStartDate",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "10px" } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-search",
                    size: "small",
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.searchResources(1)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("operationCenter.Procurar")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh-left", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.searchResources(2)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("operationCenter.reset")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "" } },
        _vm._l(_vm.tableHeaderList, function (item, index) {
          return _c("el-table-column", {
            key: index,
            attrs: {
              align: item.fixed,
              label: item.label,
              "min-width": item.cloWidth,
              prop: item.prop,
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      item.prop === "processingState"
                        ? _c("div", [
                            scope.row[item.prop] === 0
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "orderCenterCont.CancellationSuccessful"
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                            scope.row[item.prop] === 1
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "orderCenterCont.CancellationFailed"
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : item.prop === "failState"
                        ? _c("div", [
                            scope.row[item.prop] === 10
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.$t("GlobalSetObj.anjun"))),
                                ])
                              : _vm._e(),
                            scope.row[item.prop] === 11
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("GlobalSetObj.fuwushang"))
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _c("div", [_vm._v(_vm._s(scope.row[item.prop]))]),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _c("el-pagination", {
        staticStyle: { margin: "10px 0", float: "right" },
        attrs: {
          "current-page": _vm.queryForm.pageNumber,
          "page-size": _vm.queryForm.pageSize,
          "page-sizes": [10, 20, 50, 100],
          total: _vm.total,
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("GlobalSetObj.CancelOrderRetry"),
            visible: _vm.showCancelOrder,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showCancelOrder = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { padding: "20px" } }, [
            _c("div", [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    background: "#DADADA",
                    "border-radius": "20px",
                    height: "10px",
                  },
                },
                [
                  _c("div", {
                    staticStyle: {
                      background: "#42B983",
                      height: "100%",
                      "border-radius": "20px",
                    },
                    style: { width: _vm.widthProgress + "%" },
                  }),
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "text-align": "right",
                    color: "#13ADF0",
                    "font-size": "13px",
                    "margin-top": "10px",
                  },
                },
                [
                  _vm.widthProgress < 100
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.$t("newOrder.processing"))),
                      ])
                    : _c("span", [
                        _vm._v(_vm._s(_vm.$t("newOrder.ProcessingComplete"))),
                      ]),
                ]
              ),
            ]),
            _vm.widthProgress === 100
              ? _c("div", [
                  _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.$t("GlobalSetObj.all")) + "： "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#13ADF0",
                            "margin-right": "30px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.cancelAll.length))]
                      ),
                      _vm._v(
                        " " + _vm._s(_vm.$t("operationCenter.succeed")) + "： "
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#42B983",
                            "margin-right": "30px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.problemPieceReturn.successNumber))]
                      ),
                      _vm._v(
                        " " + _vm._s(_vm.$t("operationCenter.fail")) + "："
                      ),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v(_vm._s(_vm.problemPieceReturn.failureNumber)),
                      ]),
                    ]),
                  ]),
                  _c("div", [
                    _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("GlobalSetObj.ReasonForFailure")) + "："
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "errTable" },
                      [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              data: _vm.errTableData,
                              height: _vm.tableHeight,
                              border: "",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("GlobalSetObj.waybillNumber"),
                                "show-overflow-tooltip": true,
                                align: "center",
                                prop: "waybillNumber",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("GlobalSetObj.ReasonForFailure"),
                                "show-overflow-tooltip": true,
                                align: "left",
                                prop: "failReason",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showCancelOrder = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.close")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("GlobalSetObj.CancelOrderRetry"),
            visible: _vm.showCancelOrderTips,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showCancelOrderTips = $event
            },
          },
        },
        [
          _c("div", [
            _c(
              "div",
              { staticStyle: { "font-size": "15px", "margin-bottom": "20px" } },
              [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancelOrderSure")))]
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showCancelOrderTips = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.determineCancelOrder },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }