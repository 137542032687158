<script >
import { defineProps, ref, watch } from '@vue/composition-api';
import { useVuelidate } from '@vuelidate/core';
import { FormActions, FormActionsNext, FormActionsPrevious } from '@/components/wizard-form/formStepActions';
import { required, minLength, maxLength, alphaNum } from '@/utils/validationErrors';
import { zipCodeService } from '@/api/zipCode';
import clearString from '@/utils/clearString';
const __sfc_main = {};
__sfc_main.props = {
  handleNext: {
    type: Function
  },
  handlePrevious: {
    type: Function
  },
  isLastStep: {
    type: Boolean
  },
  isFirstStep: {
    type: Boolean,
    required: true
  },
  formData: {
    type: Object
  },
  isEdit: {
    default: false,
    type: Boolean
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const fieldsDisabled = ref({
    neighborhood: true,
    street: true,
    municipality: true,
    state: true
  });
  const rules = {
    zipcode: {
      required,
      maxLength: maxLength(9),
      minLength: minLength(9)
    },
    street: {
      required
    },
    number: {
      minLength: minLength(1),
      alphaNum,
      required
    },
    neighborhood: {
      required
    },
    municipality: {
      required
    },
    state: {
      required
    }
  };
  function verifyExistFieldsAddress() {
    const formDataKeys = Object.keys(props.formData);
    console.log(formDataKeys);
    formDataKeys.forEach(key => {
      const isExistField = fieldsDisabled.value.hasOwnProperty(key);
      if (isExistField) {
        fieldsDisabled.value[key] = !!props.formData[key];
      }
    });
  }
  async function searchZipcode() {
    const clearCEP = clearString(props.formData.zipcode);
    try {
      const res = await zipCodeService.getInfoZipCode(clearCEP);
      // eslint-disable-next-line require-atomic-updates
      props.formData.street = res.data.publicAreaReturn.publicArea.streetName;
      // eslint-disable-next-line require-atomic-updates
      props.formData.neighborhood = res.data.publicAreaReturn.neighborhood.neighborhoodName;
      // eslint-disable-next-line require-atomic-updates
      props.formData.municipality = res.data.publicAreaReturn.publicArea.municipality.municipalityName;
      // eslint-disable-next-line require-atomic-updates
      props.formData.state = res.data.publicAreaReturn.publicArea.state;
      verifyExistFieldsAddress();
    } catch (err) {
      // eslint-disable-next-line require-atomic-updates
      props.formData.street = null;
      // eslint-disable-next-line require-atomic-updates
      props.formData.neighborhood = null;
      // eslint-disable-next-line require-atomic-updates
      props.formData.municipality = null;
      // eslint-disable-next-line require-atomic-updates
      props.formData.state = null;
      if (clearCEP.length === 8) verifyExistFieldsAddress();else {
        fieldsDisabled.value = {
          neighborhood: true,
          street: true,
          municipality: true,
          state: true
        };
      }
    }
  }
  let interval;
  watch(() => props.formData.zipcode, async (newZipcode, oldZipCode) => {
    if (clearString(newZipcode) === oldZipCode && props.isEdit) {
      return;
    }
    clearTimeout(interval);
    interval = setTimeout(async () => {
      await searchZipcode();
    }, 900);
  });
  const v$ = useVuelidate(rules, props.formData);
  async function handleSubmit() {
    const isValid = await v$.value.$validate();
    if (isValid) props.handleNext();
  }
  return {
    fieldsDisabled,
    v$,
    handleSubmit
  };
};
__sfc_main.components = Object.assign({
  FormActions,
  FormActionsPrevious,
  FormActionsNext
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <form class="wrapper-form o-container" @submit.prevent="handleSubmit">
    <o-field
      :label="$t('driver.modalCep')"
      label-for="zipcode"
      class="col-span-3"
      :message="v$.zipcode.$errors.length ? v$.zipcode.$errors[0].$message : null"
    >
      <o-input
        id="zipcode"
        v-model="formData.zipcode"
        v-mask="'#####-###'"
        :placeholder="$t('driver.modalCepPlaceholder')"
      />
    </o-field>

    <o-field
      :label="$t('driver.modalStreet')"
      label-for="street"
      class="col-span-2"
      :message="v$.street.$errors.length ? v$.street.$errors[0].$message : null"
    >
      <o-input
        id="street"
        v-model="formData.street"
        :placeholder="$t('driver.modalStreetPlaceholder')"
        :disabled="fieldsDisabled.street"
      />
    </o-field>

    <o-field
      :label="$t('driver.modalNumber')"
      label-for="number"
      class="col-span-1"
      :message="v$.number.$errors.length ? v$.number.$errors[0].$message : null"
    >
      <o-input
        id="number"
        v-model="formData.number"
        :placeholder="$t('driver.modalNumberPlaceholder')"
      />
    </o-field>

    <o-field
      :label="$t('driver.modalNeighborhood')"
      label-for="neighborhood"
      class="col-span-3"
      :message="v$.neighborhood.$errors.length ? v$.neighborhood.$errors[0].$message : null"
    >
      <o-input
        id="neighborhood"
        v-model="formData.neighborhood"
        :placeholder="$t('driver.modalNeighborhoodPlaceholder')"
        :disabled="fieldsDisabled.neighborhood"
      />
    </o-field>

    <o-field
      :label="$t('driver.modalMunicipality')"
      label-for="municipality"
      class="col-span-2"
      :message="v$.municipality.$errors.length ? v$.municipality.$errors[0].$message : null"
    >
      <o-input
        id="municipality"
        v-model="formData.municipality"
        :placeholder="$t('driver.modalMunicipalityPlaceholder')"
        :disabled="fieldsDisabled.municipality"
      />
    </o-field>

    <o-field
      :label="$t('driver.modalState')"
      label-for="state"
      class="col-span-1"
      :message="v$.state.$errors.length ? v$.state.$errors[0].$message : null"
    >
      <o-input
        id="state"
        v-model="formData.state"
        :placeholder="$t('driver.modalStatePlaceholder')"
        :disabled="fieldsDisabled.state"
      />
    </o-field>

    <o-field
      :label="$t('driver.modalComplement')"
      label-for="complement"
      class="col-span-3"
    >
      <o-input
        id="complement"
        v-model="formData.complement"
        :placeholder="$t('driver.modalComplementPlaceholder')"
      />
    </o-field>

    <FormActions>
      <FormActionsPrevious :is-first-step="isFirstStep" @handlePrevious="handlePrevious" />
      <FormActionsNext :is-last-step="isLastStep" />
    </FormActions>
  </form>
</template>

<style scoped>
.wrapper-form {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1rem/* 16px */;
  padding-top: 0.5rem/* 8px */;
  padding-bottom: 0.5rem/* 8px */;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

</style>

