<template>
  <div>
    <el-tabs type="border-card">
      <!--账户详情-->
      <el-tab-pane :label="$t('basicData.AccountDetails')" style="margin: 0 -15px;">
        <check-detail :detail-data="detailData" />
      </el-tab-pane>
      <!--支付数据-->
      <el-tab-pane :label="$t('basicData.PaymentData')">
        <payment-detail
          :bank-list="bankList"
          :detail-data="detailData"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { apiGetBankList } from '@/api/newFinance';

export default {
  name: 'DetailPayment',
  components: {
    'check-detail': () => import('@/views/finance/basicData/cope/accountsPayable/cmp/checkDetail.vue'),
    'payment-detail': () => import('./paymentData.vue')
  },
  props: {
    detailData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      bankList: []
    };
  },
  created() {
    this.getBankList();
  },
  methods: {
    // 查询银行选项数据
    getBankList() {
      apiGetBankList().then(res => {
        this.bankList = res.data;
      });
    }
  }
};
</script>

<style scoped>

</style>
