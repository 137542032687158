<template>
  <el-select
    v-model="val1"
    clearable
    filterable
    remote
    :remote-method="asyncGetUserPage"
    :loading="loading"
    :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
    @change="changeUser"
  >
    <el-option
      v-for="item of userList"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    >
      <span style="float: left">{{ item.name }}</span>
      <span v-if="isUserName" style="float: right; color: #8492a6; font-size: 13px">{{ item.username }}</span>
      <span v-if="isOrganizationName" style="float: right; color: #8492a6; font-size: 13px">{{ item.organizationName }}</span>
    </el-option>
  </el-select>
</template>

<script>
import { getUserPage } from '@/api/user';

const param = {
  pageSize: 10,
  pageNumber: 1,
  params: {
    realName: '',
    accountState: '1'// 启用
  }
};

export default {
  name: '',

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    'value': {
      type: [Number, String], // Number: 0, String: ''
      default: 0
    },

    // 用户名称
    userName: {
      type: String,
      default: ''
    },

    // 是否显示账户名
    isUserName: {
      type: Boolean,
      default: false
    },

    // 是否显示部门
    isOrganizationName: {
      type: Boolean,
      default: false
    }

    // 下拉列表是否显示全部
    // isAll: {
    //   type: Boolean,
    //   default: false
    // }
  },

  data() {
    return {
      // executeUser: '',
      loading: false,
      userList: []
    };
  },

  computed: {
    val1: {
      // 动态计算值
      get: function() {
        return this.value || '';
      },
      set: function(val) {
        this.$emit('change', val);
      }
    }
  },

  watch: {

    // 传入用户名称搜索
    userName: {
      handler(val) {
        if (val) {
          this.asyncGetUserPage(val);
        }
      },
      immediate: true
    }
  },

  created() {
    this.asyncGetUserPage();
  },

  methods: {
    // 获取用户信息（含职务）
    asyncGetUserPage(val = '') {
      // if (this.loading) { return; }

      param.params.realName = val;
      // const obj = {
      //   pageSize: 20,
      //   pageNumber: 1,
      //   params: {
      //     realName: val,
      //     accountState: '1'// 启用
      //   }
      // };
      this.loading = true;
      getUserPage(param, false).then(res => {
        const { status, data } = res;
        if (status !== 'OK') { return; }

        this.userList = (data.records || []).map(item => {
          const { realName, id, organizationName, username } = item;
          return Object.freeze({
            name: realName,
            id,
            username, // 账号名
            organizationName // 部门
          });
        });
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },

    changeUser(ids) {
      this.val1 = ids;

      const row = this.userList.find(item => {
        return item.id === ids;
      });

      if (row && row.id) {
        this.$emit('row', row);
        return;
      }

      this.$emit('row', null);
    }
  }

};
</script>
