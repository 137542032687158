<template>
  <div class="app-container">
    <el-form label-position="top">
      <el-row :gutter="20">
        <el-col :span="6">
          <!-- 班次编号 -->
          <el-form-item :label="$t('routes.班次编号')">
            <ScheduleNumberSelect v-model="queryForm.params.scheduleNumber" :list="scheduleNumberList" is-all />
          </el-form-item>

<!--          <el-form-item :label="$t('routes.班次编号')">-->
<!--            <el-input v-model="queryForm.params.scheduleNumber" clearable :placeholder="$t('GlobalSetObj.pleaseInput')" />-->
<!--          </el-form-item>-->
        </el-col>
      </el-row>
    </el-form>

    <div>
      <el-button
        type="primary" size="small" icon="el-icon-search"
        @click="asyncGetList"
      >{{ $t('operationCenter.Procurar') }}</el-button>
      <el-button size="small" icon="el-icon-refresh-left" @click="reset">{{ $t('GlobalSetObj.reset') }}</el-button>
    </div>

    <el-divider />

    <el-button
      type="primary" size="small" style="margin-bottom: 10px"
      icon="el-icon-plus"
      @click="open"
    >{{ $t('routes.新增班次') }}</el-button>

    <el-card class="box-card table-height" :style="{height: `${tableHeightRow.height}px`}">

      <template v-for="item of tableData">
        <ListItemCmp :key="item.id" :row="item" @edit="edit" />
      </template>

      <el-empty v-if="!tableData.length" :image-size="200" />
    </el-card>

    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!--新增班次-->
    <el-dialog
      :title="addRow.title"
      :visible.sync="addRow.isBool"
      width="80%"
    >
      <AddCmp :key="addRow.isBool" :index="addRow.index" :row="addRow.row" @close="onClose" />
    </el-dialog>
  </div>
</template>

<script>
import ListItemCmp from './cmp/ListItemCmp.vue';
import { apiDepartureScheduleList, apiDepartureScheduleGetScheduleNumber } from '@/api/routerPlan.js';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import { DIALOG_TEXT, SCHEDULE_ROW } from './utils';
import { cloneDeep } from 'lodash-es';
import ScheduleNumberSelect from '@/views/route/dryBranchFlowManagement/cmp/ScheduleNumberSelect.vue';
import { routes } from '@/lang/type/newRoutes';

const PARAMS = {
  scheduleNumber: '' // 班次编号
};

export default {
  name: '',
  computed: {
    routes() {
      return routes;
    }
  },

  components: {
    ScheduleNumberSelect,
    ListItemCmp,
    AddCmp: () => import('./cmp/AddCmp.vue')
  },

  mixins: [estimateTableHeight],

  data() {
    return {
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          ...PARAMS
        }
      },
      total: 0,
      tableData: [],
      scheduleNumberList: [], // 班次编号
      addRow: {
        index: 0,
        isBool: false,
        title: DIALOG_TEXT.add,
        row: {
          ...cloneDeep(SCHEDULE_ROW)
          // id: 0,
          // scheduleNumber: '', // 班次编号
          // collectTimeList: [] // 揽收时间
        }
      }
    };
  },

  created() {
    this.asyncGetList();
    this.asyncApiDepartureScheduleGetScheduleNumber();
  },

  methods: {
    // 获取所有班次
    asyncApiDepartureScheduleGetScheduleNumber() {
      apiDepartureScheduleGetScheduleNumber().then(res => {
        console.log(res);
        this.scheduleNumberList = Object.freeze(res.data.map(item => {
          return {
            label: item,
            value: item
          };
        }));
      }).catch(err => {
        console.error(err);
      });
    },

    // 重置
    reset() {
      this.queryForm.pageNumber = 1;

      this.queryForm.params = {
        ...PARAMS
      };

      this.asyncGetList();
    },

    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.asyncGetList();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.asyncGetList();
    },

    asyncGetList() {
      apiDepartureScheduleList(this.queryForm).then(res => {
        // console.log(res);

        const { total, records } = res.data;
        this.total = total;
        const arr = (records || []).map(item => {
          const { id, scheduleNumber, collectTimeList } = item;
          const obj = {
            id,
            scheduleNumber,
            collectTimeList: []
          };

          obj.collectTimeList = (collectTimeList || []).map(key => {
            const { collectLoadFinishTime, collectTime, hub1ArrivalTime } = key;
            const arr = collectTime.split('-');
            return {
              collectLoadFinishTime, // 揽收装车完成时间
              collectTime: arr, // 揽收时间
              hub1ArrivalTime // HUB1到件时间
            };
          });

          return obj;
        });
        this.tableData = Object.freeze(arr);
      }).catch(err => {
        console.error(err);
      });
    },

    // 添加
    open() {
      this.addRow.index += 1;
      this.addRow.title = DIALOG_TEXT.add;
      this.addRow.row.id = 0;
      this.addRow.row.scheduleNumber = '';
      this.addRow.row.collectTimeList = [];

      this.addRow.isBool = true;
    },
    // 修改
    edit(row) {
      const { id, scheduleNumber, collectTimeList } = row;
      this.addRow.index += 1;
      this.addRow.title = DIALOG_TEXT.edit;
      this.addRow.row.id = id;
      this.addRow.row.scheduleNumber = scheduleNumber;
      this.addRow.row.collectTimeList = collectTimeList.map(item => {
        const { collectLoadFinishTime, hub1ArrivalTime, collectTime } = item;

        return {
          collectTime,
          collectLoadFinishTime,
          hub1ArrivalTime
        };
      });

      this.addRow.isBool = true;
    },

    onClose(isBool) {
      this.addRow.isBool = false;
      if (isBool) {
        this.queryForm.pageNumber = 1;
        this.asyncGetList();
        this.asyncApiDepartureScheduleGetDepartureTime();
      }
    }
  }

};
</script>

<style scoped>
.box-card {
   overflow-y: auto;
}
</style>
