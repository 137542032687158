export const formData = {
  id: 0,
  businessNumber: '', // 业务单号
  createTime: '', // 请求时间
  createUserName: '', // 请求人
  requestInfo: '', // 请求信息
  responseInfo: '' // 响应信息
};

// const dayVal = 3600 * 100 * 24;

/**
 * 设置时间范围
 * @param {*} time 时间
 * @param {*} val this绑定的val
 * @param {*} len 时间范围
 * @returns
 */
export const setDisabledDate = (time, choiceDate0, len = 30) => {
  const choiceDateTime = new Date(choiceDate0).getTime();
  const minTime = new Date(choiceDateTime).setDate(new Date(choiceDateTime).getDate() - len);
  const maxTime = new Date(choiceDateTime).setDate(new Date(choiceDateTime).getDate() + len);

  const min = minTime;
  const newDate = Date.now();

  const max = newDate < maxTime ? newDate : maxTime;
  // 如果已经选中一个日期 则 返回 该日期前后一个月时间可选
  if (choiceDate0) {
    return time.getTime() < min || time.getTime() > max;
  }
  // 若一个日期也没选中 则 返回 当前日期以前日期可选
  return time.getTime() > newDate;
};

/**
 * 获取多行输入框的值
 * @param {string} str 输入框的内容
 * @param {number} len 最大长度
 * @returns {string[]} 获取每一行的数据
 */
export const getTextareaVal = (str, len = 0) => {
  const arr = str.split(/[(\r\n)\r\n]+/).map(item => item.trim()).filter(Boolean);

  // 没有限制
  if (len === 0) {
    return arr;
  }

  return arr.slice(0, len);
};
