<template>
  <div>
    <div>
      <div>
        <p>{{ $t('GlobalSetObj.Selecionadas') }}：{{ selectionList.length }}</p>
        <div>{{ $t('GlobalSetObj.ModifyPreDeliveryLocation') }}</div>
        <div>
          <el-cascader
            ref="getCheckedNodes"
            :collapse-tags="true"
            :options="siteIdList"
            :props="{ multiple: false, checkStrictly: false, emitPath: true, label: 'name', value: 'id' }"
            clearable
            filterable
            style="width: 340px;"
            @change="getSelectedNode($event)"
          />
        </div>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center;margin-top: 40px;">
        <el-button size="small" type="primary" @click="saveChangeModify">{{ $t('GlobalSetObj.determine') }}</el-button>
      </div>
    </div>
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('orderExport.hint')"
      :visible.sync="changeOutletsResult"
      width="600px"
      @close="closeResult"
    >
      <div>
        <!--操作成功-->
        <p>{{ $t('GlobalSetObj.TheOperationSucceeded') }}!</p>
        <div style="display: flex;">
          <!--全部-->
          <div style="flex: 1">{{ $t('GlobalSetObj.all') }}: {{ totalChange }}</div>
          <!--更新成功-->
          <div style="flex: 1">{{ $t('newOrder.TheUpdateWasSuccessful') }}: {{ successful }}</div>
          <!--更新失败-->
          <div style="flex: 1">{{ $t('newOrder.TheUpdateFailed') }}: {{ failOrder }}</div>
        </div>
        <div v-if="errList.length">
          <div style="margin: 30px 0 10px 0;">{{ $t('newOrder.TheUpdateFailed') }}：</div>
          <div style="border: 1px solid #ccc;border-radius: 3px;padding: 10px;max-height: 260px;overflow: auto;">
            <div v-for="(item, ind) in errList" :key="ind" style="margin: 5px 0;">
              {{ item.waybillNumber }}：{{ item.failReason }}
            </div>
          </div>
        </div>
        <div style="margin-top: 40px;text-align: center;">
          <!--关闭-->
          <el-button size="small" type="primary" @click="changeOutletsResult = false">
            {{ $t('orderCenterCont.ShutDown') }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { allocationDeliverSite } from '@/api/logisticsOrders';

export default {
  name: 'ModifyPreDeliveryLocation',
  props: {
    siteIdList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    selectionList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      changeOutletsResult: false,
      selectedOutlets: null,
      totalChange: 0,
      successful: 0,
      failOrder: 0,
      errList: []
    };
  },
  methods: {
    // 关闭弹窗
    closeResult() {
      this.$emit('changeOutletsDataClose');
    },
    // 选择网点
    getSelectedNode(val) {
      const node = this.$refs.getCheckedNodes.panel.getNodeByValue(val); // getCheckedNodes();
      console.log(node, '====data');
      this.selectedOutlets = {
        providerChannelCode: node.parent ? node.parent.data.providerChannelCode : node.data.providerChannelCode,
        providerChannelName: node.parent ? node.parent.data.providerChannelName : node.data.providerChannelName,
        providerChannelId: node.parent ? node.parent.data.id : node.data.id,
        siteId: node.parent ? node.data.id : null,
        siteName: node.parent ? node.data.name : null,
        siteCode: node.parent ? node.data.code : null,
        providerId: node.data.providerId,
        providerName: node.data.providerName
      };
      console.log(this.selectedOutlets, '=====this.selectedOutlets');
    },
    // 保存修改预派送网点
    saveChangeModify() {
      const waybillNumbers = [];
      this.selectionList.map(item => {
        waybillNumbers.push(item.waybillNumber);
      });

      // 将数组内元素分为多个小数组
      // const group = (array, subGroupLength) => {
      //   let index = 0;
      //   const newArray = [];
      //   while (index < array.length) {
      //     newArray.push(array.slice(index, index += subGroupLength));
      //   }
      //   return newArray;
      // };
      //
      // const groupArr = group(waybillNumbers, 2);
      // //
      // console.log(groupArr, '======groupArr');
      //
      // class Iterator {
      //   constructor() {
      //     this.middlewares = [];
      //   }
      //   use(fn) {
      //     this.middlewares.push(fn); // 存入任务
      //     return this;
      //   }
      //   async run(ctx) {
      //     function createNext(middleware, oldNext) {
      //       return async() => {
      //         await middleware(ctx, oldNext);
      //       };
      //     }
      //     const len = this.middlewares.length;
      //     let next = async() => {
      //       return Promise.resolve();
      //     };
      //     for (let i = len - 1; i >= 0; i--) {
      //       const currentMiddleware = this.middlewares[i];
      //       next = createNext(currentMiddleware, next);
      //     }
      //     await next();
      //   }
      // }
      //
      // const app = new Iterator();
      // app.use(async(ctx, next) => {
      //   console.log('start:a');
      //   await next();
      //   console.log('end:a');
      // });
      //
      // app.use(async(ctx, next) => {
      //   console.log('start:b');
      //   await next();
      //   console.log('end:b');
      // });
      // app.run();

      // const getArr = (groupArr) => {
      //   let index = 0;
      //   while (index < groupArr.length) {
      //     const param = {
      //       waybillNumbers: groupArr[index],
      //       ...this.selectedOutlets
      //     };
      //     allocationDeliverSite(param).then(res => {
      //       if (res.status === 'OK') {
      //         this.totalChange = this.totalChange + res.data.data.countRequest;
      //         this.successful = this.successful + res.data.data.successRequest;
      //         this.failOrder = this.failOrder + res.data.data.failRequest;
      //         this.errList = [...this.errList, ...res.data.data.failList];
      //         index++;
      //       }
      //     }).finally(() => {
      //       index++;
      //     });
      //   }
      // };
      // getArr(groupArr);

      const param = {
        waybillNumbers: waybillNumbers,
        ...this.selectedOutlets
      };
      allocationDeliverSite(param).then(res => {
        if (res.status === 'OK') {
          this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
          this.selectedOutlets = null;
          this.changeOutletsResult = true;
          this.totalChange = res.data.countRequest;
          this.successful = res.data.successRequest;
          this.failOrder = res.data.failRequest;
          this.errList = res.data.failList;
        }
      });
    }
  }
};
</script>

<style scoped>

</style>
