<template>
  <div class="nodeTable">
    <el-table
      :data="dataList"
      border
      style="width: 100%"
    >
      <!--序号-->
      <el-table-column :label="$t('operationCenter.ind')" width="60px" type="index" />
      <!-- 订单号 -->
      <el-table-column :label="$t('ChannelManage.oddNumbers')" prop="barCode" />
    </el-table>
  </div>
</template>

<script>

export default {
  props: {
    dataList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  data() {
    return {
    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped>

</style>