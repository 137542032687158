import i18n from '@/lang';

export const TABLE_HEADER = [
  // 收款账单
  { prop: 'receiptBillName', label: i18n.t('receivablePayable.IncomingBills'), width: 160, align: 'left', highlight: false, disabled: false },
  // 账单日期
  { prop: 'billTime', label: i18n.t('receivablePayable.BillingDate'), width: 180, align: 'left', highlight: false, disabled: false },
  // 收款状态
  { prop: 'receiptStatus', label: i18n.t('receivablePayable.ReceiptStatus'), width: 160, align: 'left', highlight: false, disabled: false },
  // 客户
  { prop: 'customerName', label: i18n.t('GlobalSetObj.client'), width: 160, align: 'left', highlight: false, disabled: false },
  // 网点名称
  { prop: 'siteName', label: i18n.t('basicData.siteName'), width: 160, align: 'left', highlight: false, disabled: false },
  // 订单数量
  { prop: 'orderQty', label: i18n.t('receivablePayable.TheNumberOfOrders'), width: 160, align: 'left', highlight: true, disabled: false },
  // 费用条数
  { prop: 'feeQty', label: i18n.t('receivablePayable.TheNumberOfFees'), width: 160, align: 'left', highlight: false, disabled: false },
  // 计费总重量（kg）
  { prop: 'chargeWeight', label: i18n.t('receivablePayable.TotalBillableWeight'), width: 160, align: 'left', highlight: false, disabled: false },
  // 票单金额
  { prop: 'billFee', label: i18n.t('receivablePayable.TicketAmount'), width: 160, align: 'left', highlight: false, disabled: false },
  // 实际收款金额
  { prop: 'actualFee', label: i18n.t('receivablePayable.TheActualAmountReceived'), width: 160, align: 'left', highlight: false, disabled: false },
  // 核收人
  { prop: 'acceptUserName', label: i18n.t('receivablePayable.payee'), width: 160, align: 'left', highlight: false, disabled: false },
  // 核收时间
  { prop: 'acceptOperateTime', label: i18n.t('receivablePayable.CollectionTime'), width: 160, align: 'left', highlight: false, disabled: false },
  // 交易时间
  { prop: 'acceptTime', label: i18n.t('basicData.TradingHours'), width: 160, align: 'left', highlight: false, disabled: false },
  // 生成人员
  { prop: 'createUserName', label: i18n.t('receivablePayable.BuildThePerson'), width: 160, align: 'left', highlight: false, disabled: false },
  // 生成时间
  { prop: 'createTime', label: i18n.t('receivablePayable.BuildTime'), width: 160, align: 'left', highlight: false, disabled: false },
  // 审核人员
  { prop: 'auditUserName', label: i18n.t('receivablePayable.Auditors'), width: 160, align: 'left', highlight: false, disabled: false },
  // 审核时间
  { prop: 'auditTimeString', label: i18n.t('receivablePayable.ReviewTime'), width: 160, align: 'left', highlight: false, disabled: false },

  // 审核备注
  { prop: 'auditRemark', label: i18n.t('basicData.审核备注'), width: 160, align: 'left', highlight: false, disabled: false },

  // 备注
  { prop: 'remark', label: i18n.t('receivablePayable.remark'), width: 160, align: 'left', highlight: false, disabled: false }
];
