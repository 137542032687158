var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      attrs: {
        placeholder: _vm.$t("spareNumberPoo.Only10AreDisplayed"),
        "remote-method": _vm.asyncGetList,
        clearable: "",
        disabled: _vm.disabled,
        filterable: "",
        remote: "",
        loading: _vm.isLoading,
        "reserve-keyword": "",
      },
      on: { change: _vm.changeCustomer },
      model: {
        value: _vm.val1,
        callback: function ($$v) {
          _vm.val1 = $$v
        },
        expression: "val1",
      },
    },
    [
      _vm.isAll
        ? _c("el-option", {
            attrs: { label: _vm.$t("customerManagements.all"), value: "" },
          })
        : _vm._e(),
      _vm._l(_vm.customerList, function (item) {
        return _c("el-option", {
          key: item.id,
          attrs: {
            label: item.customerAlias + "(" + item.username + ")",
            value: item.id,
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }