export const company = {
  // tableData
  company: {
    zh: '公司',
    en: 'Company',
    pu: 'Companhia'
  },
  createCompany: {
    zh: '创建公司',
    en: 'Create Company',
    pu: 'Criar companhia'
  },
  base: {
    zh: '基地',
    en: 'Base',
    pu: 'Base'
  },
  address: {
    zh: '地址',
    en: 'Address',
    pu: 'Endereço'
  },
  noResults: {
    zh: '无结果',
    en: 'No results',
    pu: 'Sem resultados'
  },
  createCompanyTitle: {
    zh: '创建公司中',
    en: 'Creating Company',
    pu: 'Criando Companhia'
  },
  createCompanyParagraph: {
    zh: '填写下面的字段以在我们的系统中创建公司',
    en: 'Fill in the fields below to create a company in our system',
    pu: 'Preencha os campos logo abaixo para realizar a criação de uma companhia em nosso sistema'
  },

  // actions
  edit: {
    zh: '编辑',
    en: 'Edit',
    pu: 'Editar'
  },
  delete: {
    zh: '删除',
    en: 'Delete',
    pu: 'Excluir'
  },
  editCompanyTitle: {
    zh: '编辑公司',
    en: 'Editing company',
    pu: 'Editando Companhia'
  },
  editCompanyParagraph: {
    zh: '填写下面的字段以在我们的系统中编辑公司',
    en: 'Fill in the fields below to edit a company in our system',
    pu: 'Preencha os campos logo abaixo para realizar a edição de uma companhia em nosso sistema'
  },
  deleteTitle: {
    zh: '你确定吗？',
    en: 'Are you sure?',
    pu: 'Você tem certeza absoluta?'
  },
  deleteParagraph: {
    zh: '此操作无法撤销。 这将使公司的信息失效',
    en: 'This action cannot be undone. This will deactivate the company information',
    pu: 'Essa ação não pode ser desfeita. Isso irá desativar as informações da companhia'
  },
  cancel: {
    zh: '取消',
    en: 'Cancel',
    pu: 'Cancelar'
  },
  yesDelete: {
    zh: '是的，我想要删除',
    en: 'Yes, I want to delete',
    pu: 'Sim, quero excluir'
  },

  // formCompany
  name: {
    zh: '名称',
    en: 'Name',
    pu: 'Nome'
  },
  namePlaceholder: {
    zh: '输入公司名称',
    en: 'Enter company name',
    pu: 'Digite o nome da companhia'
  },
  fantasyName: {
    zh: '商号',
    en: 'Fantasy name',
    pu: 'Nome Fantasia'
  },
  fantasyNamePlaceholder: {
    zh: '输入公司的商号',
    en: 'Enter company fantasy name',
    pu: 'Digite o nome fantasia da companhia'
  },
  document: {
    zh: '文档',
    en: 'Document',
    pu: 'Documento'
  },
  documentPlaceholder: {
    zh: '输入文档',
    en: 'Enter document',
    pu: 'Digite o documento'
  },
  stateRegistration: {
    zh: '州注册',
    en: 'State registration',
    pu: 'Inscrição Estadual'
  },
  stateRegistrationPlaceholder: {
    zh: '输入州注册',
    en: 'Enter state registration',
    pu: 'Digite a inscrição estadual'
  },
  commercialPhone: {
    zh: '商业电话',
    en: 'Commercial phone',
    pu: 'Telefone comercial'
  },
  commercialPhonePlaceholder: {
    zh: '输入商业电话',
    en: 'Enter commercial phone',
    pu: 'Digite o telefone comercial'
  },
  contact: {
    zh: '联系人',
    en: 'Contact',
    pu: 'Contato'
  },
  contactPlaceholder: {
    zh: '输入联系人',
    en: 'Enter contact',
    pu: 'Digite o nome do contato'
  },
  cellPhone: {
    zh: '手机',
    en: 'Cell phone',
    pu: 'Celular'
  },
  cellPhonePlaceholder: {
    zh: '输入手机',
    en: 'Enter cell phone',
    pu: 'Digite o nome do contato'
  },
  email: {
    zh: '邮箱',
    en: 'Email',
    pu: 'Email'
  },
  emailPlaceholder: {
    zh: '输入邮箱',
    en: 'Enter email',
    pu: 'Digite o nome do email'
  },
  contractType: {
    zh: '合同类型',
    en: 'Contract type',
    pu: 'Tipo de contrato'
  }

};
