var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-main" },
    [
      _c(
        "el-card",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.queryForm, size: "small" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "searchType",
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                              },
                              model: {
                                value: _vm.queryForm.params.searchType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "searchType",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.searchType",
                              },
                            },
                            [
                              _c("el-option", {
                                key: "1",
                                attrs: {
                                  label: _vm.$t("GlobalSetObj.bagNumber"),
                                  value: "transitBagNumbers",
                                },
                              }),
                              _c("el-option", {
                                key: "2",
                                attrs: {
                                  label: _vm.$t("GlobalSetObj.totalSingNumber"),
                                  value: "instoreBigBagNos",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: _vm.$t(
                                "operatingPlatform.please200"
                              ),
                              rows: "5",
                              type: "textarea",
                            },
                            model: {
                              value: _vm.queryForm.params.transitBagNumbers,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm.params,
                                  "transitBagNumbers",
                                  $$v
                                )
                              },
                              expression: "queryForm.params.transitBagNumbers",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.fuwushang") + "：",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.queryForm.params.systemCode,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "systemCode",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.systemCode",
                              },
                            },
                            _vm._l(_vm.systemSeviceList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label:
                                    "" +
                                    item.name +
                                    (item.code ? "(" + item.code + ")" : ""),
                                  value: item.code,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.DeliveryPoint") + "：",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.queryForm.params.preDeliverStationId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "preDeliverStationId",
                                    $$v
                                  )
                                },
                                expression:
                                  "queryForm.params.preDeliverStationId",
                              },
                            },
                            _vm._l(_vm.siteIdList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label:
                                    "" +
                                    item.name +
                                    (item.code ? "(" + item.code + ")" : ""),
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.status") + "：",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                                clearable: "",
                              },
                              model: {
                                value: _vm.queryForm.params.bigBagStatus,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "bigBagStatus",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.bigBagStatus",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("newOperationCenter.SealedBag"),
                                  value: "1",
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t(
                                    "operatingPlatform.assemblyCompleted"
                                  ),
                                  value: "2",
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t(
                                    "operatingPlatform.carregamento"
                                  ),
                                  value: "3",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.BagSealer") + "：",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled:
                                  !_vm.$store.getters.button_permissions.includes(
                                    "btn:automatic-sorting:bagworkList:search-all"
                                  ),
                                placeholder: _vm.$t(
                                  "operationCenter.Only10AreDisplayed"
                                ),
                                "remote-method": _vm.getUserList,
                                clearable: "",
                                filterable: "",
                                remote: "",
                              },
                              model: {
                                value: _vm.queryForm.params.packageUserId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "packageUserId",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.packageUserId",
                              },
                            },
                            _vm._l(_vm.userList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label:
                                    item.createUserName +
                                    "(" +
                                    item.realName +
                                    ")",
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.BagSealingTime") + "：",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "default-time": ["00:00:00", "23:59:59"],
                              "end-placeholder": _vm.$t(
                                "operationCenter.endDate"
                              ),
                              "picker-options": _vm.pickerOptions,
                              "range-separator": _vm.$t("operationCenter.to"),
                              "start-placeholder": _vm.$t(
                                "operationCenter.startDate"
                              ),
                              type: "datetimerange",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                            },
                            model: {
                              value: _vm.queryDate,
                              callback: function ($$v) {
                                _vm.queryDate = $$v
                              },
                              expression: "queryDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.Estado") + "：",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.queryForm.params.siteState,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "siteState",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.siteState",
                              },
                            },
                            _vm._l(_vm.StateList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.label, value: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "10px" }, attrs: { gutter: 20 } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.getData(1)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.search")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-download", size: "small" },
                  on: { click: _vm.exportBigBagExporTwayBillBag },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("operatingPlatform.exportBagData")) +
                      " "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-download", size: "small" },
                  on: { click: _vm.exportBigBagExportWaybillDetails },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("operatingPlatform.exportWaybillDetails")) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", "margin-top": "10px" },
              attrs: { data: _vm.tableData, border: "" },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.bagNumber"),
                  prop: "transitBagNumber",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#409eff",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.getBagDetail(
                                  scope.row.transitBagNumber
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.transitBagNumber))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.totalSingNumber"),
                  prop: "instoreBigBagNo",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.fuwushang"),
                  prop: "systemProviderName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.Estado"),
                  prop: "siteState",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.DeliveryPoint"),
                  prop: "siteName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.DeliveryRoutes"),
                  prop: "pathNumber",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("trajectory.numberSmallPackets"),
                  prop: "packageCount",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.totalWeightKG"),
                  prop: "totalWeight",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.status"),
                  prop: "address",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.bigBagStatus === 1
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("newOperationCenter.SealedBag"))
                              ),
                            ])
                          : _vm._e(),
                        scope.row.bigBagStatus === 2
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("operatingPlatform.assemblyCompleted")
                                )
                              ),
                            ])
                          : _vm._e(),
                        scope.row.bigBagStatus === 3
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("operatingPlatform.carregamento"))
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.BagSealer"),
                  prop: "packageUser",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.BagSealingTime"),
                  prop: "packageTime",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.operate"),
                  prop: "address",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.getBagDetail(
                                  scope.row.transitBagNumber
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("operatingPlatform.viewUpdate")) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.printBagNo(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Print")) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            staticStyle: { margin: "10px 0", "text-align": "right" },
            attrs: {
              "current-page": _vm.queryForm.number,
              "page-size": _vm.queryForm.pageSize,
              "page-sizes": [10, 20, 50, 100],
              total: _vm.total,
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.queryForm, "number", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.queryForm, "number", $event)
              },
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "size-change": _vm.getData,
              "current-change": _vm.getData,
            },
          }),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.$t("GlobalSetObj.operate"),
            visible: _vm.drawer,
            "wrapper-closable": false,
            direction: "ltr",
            size: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-position": "right", "label-width": "120px" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.bagNumber") + "：",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.BagNumberDetail.bagNumber)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.fuwushang") + "：",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.BagNumberDetail.systemProviderName)
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.DeliveryPoint") + "：",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.BagNumberDetail.siteName)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.totalWeight") + "",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.BagNumberDetail.totalWeight)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.waybillNumber") + "：",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "80%" },
                            attrs: { size: "small" },
                            model: {
                              value: _vm.bagNumberDetailSearch.waybillNumber,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.bagNumberDetailSearch,
                                  "waybillNumber",
                                  $$v
                                )
                              },
                              expression: "bagNumberDetailSearch.waybillNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.$t("collectionCenter.customerNumber") + "：",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "80%" },
                            attrs: { size: "small" },
                            model: {
                              value: _vm.bagNumberDetailSearch.customerNumber,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.bagNumberDetailSearch,
                                  "customerNumber",
                                  $$v
                                )
                              },
                              expression:
                                "bagNumberDetailSearch.customerNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.$t("GlobalSetObj.serviceLogistics") + "：",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "80%" },
                            attrs: { size: "small" },
                            model: {
                              value: _vm.bagNumberDetailSearch.logisticsNumber,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.bagNumberDetailSearch,
                                  "logisticsNumber",
                                  $$v
                                )
                              },
                              expression:
                                "bagNumberDetailSearch.logisticsNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "30px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.getListDetail },
                    },
                    [_vm._v(_vm._s(_vm.$t("GlobalSetObj.search")) + " ")]
                  ),
                  _vm.BagNumberDetail.bigBagStatus === 1
                    ? _c("el-input", {
                        staticStyle: {
                          width: "300px",
                          "margin-left": "20px",
                          "margin-right": "20px",
                        },
                        attrs: {
                          placeholder: _vm.$t(
                            "operatingPlatform.scanOrEnterWaybillToAdd"
                          ),
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.getWaybillNumberList($event)
                          },
                        },
                        model: {
                          value: _vm.waybillNumberValue,
                          callback: function ($$v) {
                            _vm.waybillNumberValue = $$v
                          },
                          expression: "waybillNumberValue",
                        },
                      })
                    : _vm._e(),
                  _vm.BagNumberDetail.bigBagStatus === 1 &&
                  _vm.$store.getters.button_permissions.includes(
                    "btn:automatic-sorting:bagworkList:seal-the-bag"
                  )
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "warning" },
                          on: { click: _vm.sealBag },
                        },
                        [_vm._v(_vm._s(_vm.$t("GlobalSetObj.envelope")) + " ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 8 } }, [
                _c(
                  "div",
                  { staticStyle: { position: "relative", overflow: "hidden" } },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { float: "right", "margin-right": "30px" },
                        attrs: { icon: "el-icon-download" },
                        on: { click: _vm.exportDetailList },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("collectionCenter.export")) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { margin: "10px 20px", overflow: "hidden" } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { height: "70vh", "overflow-y": "auto" },
                  attrs: { data: _vm.searchTransitBagNumberList, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("GlobalSetObj.waybillNumber"),
                      prop: "waybill_number",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.waybill_number
                                    ? scope.row.waybill_number
                                    : "- -"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("collectionCenter.customerNum"),
                      prop: "customerNumber",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.customerNumber
                                    ? scope.row.customerNumber
                                    : "- -"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("GlobalSetObj.serviceLogistics"),
                      prop: "logistics_number",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.logistics_number
                                    ? scope.row.logistics_number
                                    : "- -"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("GlobalSetObj.TheInboundOrderNumber"),
                      prop: "inboundReceiptNumber",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.inboundReceiptNumber
                                    ? scope.row.inboundReceiptNumber
                                    : "- -"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("operatingPlatform.weightKG"),
                      prop: "weight",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("GlobalSetObj.operate"),
                      align: "center",
                      prop: "address",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.BagNumberDetail.bigBagStatus === 1
                              ? _c(
                                  "el-link",
                                  {
                                    attrs: { type: "danger" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.detailAddAndDel(
                                          "del",
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("GlobalSetObj.MoveOut")) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "printStyle", staticStyle: { display: "none" } },
        [
          _vm.showPrintBagNumberHtml
            ? _c("printBagNumberHtml", {
                attrs: { "param-data": _vm.printBagObj },
                on: { hiddenBagNumberHtml: _vm.hiddenBagNumberHtml },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }