var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "warp" },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "auto" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-descriptions",
                { attrs: { colon: false, column: 3, direction: "vertical" } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.SenderName") } },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _vm.showDetail
                          ? _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.encryptionSenderInfo.senderName || "--"
                                  )
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.senderInfo.senderName || "--")
                                ),
                              ]
                            ),
                        _vm.senderInfo.senderName
                          ? _c(
                              "span",
                              {
                                staticClass: "iconEye",
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerhidden("name")
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  { attrs: { placement: "top-start" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "GlobalSetObj.completionInformation"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "iconEye" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.SenderCompany") } },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _vm.showDetail5
                          ? _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.encryptionSenderInfo.senderCompany ||
                                      "--"
                                  )
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.senderInfo.senderCompany || "--")
                                ),
                              ]
                            ),
                        _vm.senderInfo.senderCompany
                          ? _c(
                              "span",
                              {
                                staticClass: "iconEye",
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerhidden("senderCompany")
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  { attrs: { placement: "top-start" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "GlobalSetObj.completionInformation"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "iconEye" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.senderPhone") } },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _vm.showDetail1
                          ? _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.encryptionSenderInfo.senderMobile ||
                                      "--"
                                  )
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.senderInfo.senderMobile || "--")
                                ),
                              ]
                            ),
                        _vm.senderInfo.senderMobile
                          ? _c(
                              "span",
                              {
                                staticClass: "iconEye",
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerhidden("senderPhone")
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  { attrs: { placement: "top-start" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "GlobalSetObj.completionInformation"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "iconEye" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.Street1") } },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.senderInfo.senderStreet || "--")),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.SenderNumber") } },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.senderInfo.senderHouseNumber || "--")
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.SenderDistrict") } },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.senderInfo.senderArea || "--")),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.SenderCity") } },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.senderInfo.senderCity || "--")),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.SenderState") } },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.senderInfo.senderState || "--")),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.SenderZipCode") } },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.senderInfo.senderZipcode || "--")),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { colon: false, column: 1, direction: "vertical" } },
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.sendderComplement"),
                      },
                    },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.senderInfo.senderComplement || "--")),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { colon: false, column: 3, direction: "vertical" } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.SenderCPF") } },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _vm.showDetail4
                          ? _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.encryptionSenderInfo.senderTax || "--"
                                  )
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [_vm._v(_vm._s(_vm.senderInfo.senderTax || "--"))]
                            ),
                        _vm.senderInfo.senderTax
                          ? _c(
                              "span",
                              {
                                staticClass: "iconEye",
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerhidden("senderTax")
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  { attrs: { placement: "top-start" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "GlobalSetObj.completionInformation"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "iconEye" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: _vm.$t(
                          "GlobalSetObj.NationalRegistrationNumber"
                        ),
                      },
                    },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.senderInfo.senderIeNumber || "--")),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { colon: false, column: 1, direction: "vertical" } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.SendingAddress") } },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.senderInfo.senderAddress || "--")),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { colon: false, column: 3, direction: "vertical" } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.SenderPhone") } },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _vm.showDetail2
                          ? _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.encryptionSenderInfo.senderPhone || "--"
                                  )
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.senderInfo.senderPhone || "--")
                                ),
                              ]
                            ),
                        _vm.senderInfo.senderPhone
                          ? _c(
                              "span",
                              {
                                staticClass: "iconEye",
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerhidden("senderMobile")
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  { attrs: { placement: "top-start" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "GlobalSetObj.completionInformation"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "iconEye" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.SenderEmail") } },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _vm.showDetail3
                          ? _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.encryptionSenderInfo.senderMail || "--"
                                  )
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.senderInfo.senderMail || "--")
                                ),
                              ]
                            ),
                        _vm.senderInfo.senderMail
                          ? _c(
                              "span",
                              {
                                staticClass: "iconEye",
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerhidden("senderMail")
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  { attrs: { placement: "top-start" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "GlobalSetObj.completionInformation"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "iconEye" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }