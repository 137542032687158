/**
 * 下家API
 */

import request from '@/utils/request';

// 下家新增
const apiDownstreamAdd = data => {
  return request.post('/downstream/add', data);
};

// 下家修改
const apiDownstreamEdit = data => {
  return request.put('/downstream/edit', data);
};

// 下家删除
const apiDownstreamDel = ids => {
  return request.delete(`/downstream/delete/${ids}`);
};

// 下家列表分页
const apiDownstreamPage = data => {
  return request.post('/downstream/page', data);
};

// 下家列表
const apiDownstreamList = (data, isBool = true) => {
  // return request.post('/downstream/list', data);
  return request({
    method: 'POST',
    data,
    url: `/downstream/list`,
    isLoading: isBool // 是否显示加载中参数
  });
};
const getDownstreamProviderServiceList = (isBool = true) => {
  return request({
    method: 'get',
    url: `/downstream/getDownstreamProviderServiceList`,
    isLoading: isBool // 是否显示加载中参数
  });
  // return request.post('/downstream/getDownstreamProviderServiceList', data);
};

export {
  apiDownstreamAdd,
  apiDownstreamEdit,
  apiDownstreamDel,
  apiDownstreamPage,
  apiDownstreamList,
  getDownstreamProviderServiceList
};
