<template>
  <StatisticiansTipsCmp :arr="arr" />
</template>

<script>
import StatisticiansTipsCmp from '@/components/StatisticiansTipsCmp/index.vue';

export default {
  name: '',

  components: {
    StatisticiansTipsCmp
  },

  props: {
    row: {
      type: Object,
      default: () => {
        return {
          providerName: 0, // 应付主体
          siteName: 0, // 应付网点
          totalFeeQty: 0, // 总票数
          totalFree: 0, // 总费用
          averageFee: 0 // 平均每票费用
        };
      }
    }
  },

  data() {
    return {
      arr: [
        {
          name: this.$t('basicData.PayablePrincipal'), // 应付主体
          value: '',
          suffix: ';'
        },
        {
          name: this.$t('basicData.Outlets'), // 应付网点
          value: '',
          suffix: ';'
        },
        {
          name: this.$t('ClaimsManagement.TotalNumberOfVotes'), // 总票数
          value: 0,
          suffix: ';'
        },
        {
          name: this.$t('orderCenterCont.TotalCost'), // 总费用
          value: 0,
          suffix: ';'
        },
        {
          name: this.$t('orderCenterCont.AverageCostPerTicket'), // 平均每票费用
          value: 0,
          suffix: ''
        }
      ]
    };
  },

  watch: {
    'row': {
      handler(val) {
        this.arr[0].value = val.providerName;
        this.arr[1].value = val.siteName;
        this.arr[2].value = val.totalFeeQty;
        this.arr[3].value = val.totalFree;
        this.arr[4].value = val.averageFee;
      },
      immediate: true,
      deep: true
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
