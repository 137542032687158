import { requestScripting } from '@/lib/axios';

export const dashboardService = {
  async getDailyVisitFee(date) {
    return requestScripting({
      url: `control-tower/getDailyVisitFee/${encodeURIComponent(date)}`,
      method: 'GET'
    });
  },
  async getDailyCollectFee(date) {
    return requestScripting({
      url: `control-tower/getDailyCollectFee/${encodeURIComponent(date)}`,
      method: 'GET'
    });
  }
};
