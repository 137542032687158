/*
 * @Date: 2022-07-07 10:32:15
 * @LastEditTime: 2022-10-31 15:06:05
 * @Description:
 * @FilePath: \admin-oa-web\src\api\serialPort.js
 */
// 串口读取
import ajaxFetch from '@/utils/ajaxFetch';

const baseURL = process.env.VUE_APP_SERIAL_PORT;
// const baseURL = 'http://127.0.0.1:3000';

// 插件是否启动
export const apiStartUp = (data) => {
  return ajaxFetch({
    url: baseURL + '/'
  });
};

// 获取串口列表
export const apiStartPortsList = () => {
  return ajaxFetch({
    url: baseURL + '/v1/scale/ports'
  });
};

// 打开指定串口并开始读数
export const apiOPenComPort = ({ comPort, scaleType }) => {
  return ajaxFetch({
    url: `${baseURL}/v1/scale/open?comPort=${comPort}&scaleType=${scaleType}`,
    method: 'POST'
  });
};

// 获取电子秤读数
export const apiGetWeight = () => {
  return ajaxFetch({
    url: baseURL + '/v1/scale/weight'
  });
};

// 关闭当前电子秤
export const apiScaleClose = () => {
  return ajaxFetch({
    url: baseURL + '/v1/scale/close'
  });
};

// 自动打印
export const autoPrint = (data) => {
  return ajaxFetch({
    url: baseURL + `/v1/printer?type=url&file=${data}`
  });
};

/**
 * @description: 机器获取长宽高
 * @param { Object } data 运单号
 * @return {*}
 */
export const agentScanInfo = (data) => {
  return ajaxFetch({
    url: baseURL + `/v1/agent/scan/info`,
    headers: {
      'content-type': 'application/json'
    },
    method: 'POST',
    data
  });
};
