import { render, staticRenderFns } from "./addDetail.vue?vue&type=template&id=873df202"
import script from "./addDetail.vue?vue&type=script&lang=js"
export * from "./addDetail.vue?vue&type=script&lang=js"
import style0 from "./addDetail.vue?vue&type=style&index=0&id=873df202&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/work/jenkins/workspace/pre预发布-anjun-express-web-management/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('873df202')) {
      api.createRecord('873df202', component.options)
    } else {
      api.reload('873df202', component.options)
    }
    module.hot.accept("./addDetail.vue?vue&type=template&id=873df202", function () {
      api.rerender('873df202', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/finance/basicData/cope/routeDistanceReference/cmp/addDetail.vue"
export default component.exports