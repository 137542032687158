var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          key: _vm.rechargeData.id,
          ref: "rechargeData",
          attrs: {
            rules: _vm.recharge,
            model: _vm.formData,
            "label-width": "auto",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.CustomerName") + "：",
                          prop: "customerAlias",
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.formData.customerAlias))])]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.TopUpTime") + "：",
                          prop: "rechargeDate",
                        },
                      },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            type: "date",
                            "value-format": "yyyy-MM-dd",
                            placeholder: _vm.$t("basicData.SelectADate"),
                          },
                          model: {
                            value: _vm.formData.rechargeDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "rechargeDate", $$v)
                            },
                            expression: "formData.rechargeDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.account") + "：",
                          prop: "account",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { filterable: "", clearable: "" },
                            model: {
                              value: _vm.formData.account,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "account", $$v)
                              },
                              expression: "formData.account",
                            },
                          },
                          _vm._l(_vm.accountOption, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: {
                                label: item.accountAlias,
                                value: item.accountNumber,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.Currency") + "：",
                          prop: "currencyCode",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { filterable: "", clearable: "" },
                            model: {
                              value: _vm.formData.currencyCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "currencyCode", $$v)
                              },
                              expression: "formData.currencyCode",
                            },
                          },
                          _vm._l(_vm.currencyList, function (item, ind) {
                            return _c("el-option", {
                              key: ind,
                              attrs: {
                                label: item.chineseName + "(" + item.code + ")",
                                value: item.code,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.TicketNumber") + "：",
                          prop: "ticketNumber",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.formData.ticketNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "ticketNumber", $$v)
                            },
                            expression: "formData.ticketNumber",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.CurrentBalance") + "：",
                          prop: "balance",
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.formData.balance))])]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.TopUpMethod") + "：",
                          prop: "receiptMethod",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: _vm.$t("basicData.PleaseSelect"),
                            },
                            model: {
                              value: _vm.formData.receiptMethod,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "receiptMethod", $$v)
                              },
                              expression: "formData.receiptMethod",
                            },
                          },
                          _vm._l(_vm.paymentMethod, function (item) {
                            return _c("el-option", {
                              key: item.code,
                              attrs: { label: item.name, value: item.code },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.TopUpAmount") + "：",
                          prop: "amount",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.formData.amount,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "amount", $$v)
                            },
                            expression: "formData.amount",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: _vm.rateKey,
                        attrs: {
                          label: _vm.$t("basicData.exchangeRate") + "：",
                          prop: "exchangeRate",
                          rules: {
                            required:
                              _vm.formData.currencyCode.toUpperCase() === "BRL"
                                ? false
                                : true,
                            suffix: 5,
                            negative: "",
                            validator: _vm.priceType,
                            trigger: ["blur", "change"],
                          },
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.formData.exchangeRate,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "exchangeRate", $$v)
                            },
                            expression: "formData.exchangeRate",
                          },
                        }),
                        _vm.adopt && _vm.exchangeRate !== 0
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "absolute",
                                  left: "0",
                                  top: "30px",
                                  color: "#c98f06ff",
                                },
                              },
                              [
                                _vm.allEqual
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "5px" },
                                      },
                                      [_vm._v("≈")]
                                    )
                                  : _vm._e(),
                                _vm._v(
                                  _vm._s(_vm.exchangeRate.toFixed(2)) + "BRL "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.remark") + "：",
                          prop: "remark",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { type: "textarea", rows: 6 },
                          model: {
                            value: _vm.formData.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "remark", $$v)
                            },
                            expression: "formData.remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.annex") + "：",
                          prop: "attachment",
                        },
                      },
                      [
                        _c(
                          "el-upload",
                          {
                            ref: "uploadFile",
                            staticClass: "upload-demo",
                            attrs: {
                              drag: "",
                              action: "",
                              multiple: "",
                              "http-request": _vm.enclosureUpload,
                              "before-upload": _vm.beforeAvatarUpload,
                              "on-remove": _vm.handleRemove,
                              "file-list": _vm.fileList,
                            },
                          },
                          [
                            _c("i", { staticClass: "el-icon-upload" }),
                            _c("div", { staticClass: "el-upload__text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "basicData.DragTheFileHereOrClickUpload"
                                  )
                                )
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "el-upload__tip",
                                attrs: { slot: "tip" },
                                slot: "tip",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "basicData.UploadTheAttachmentFormat"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px", "text-align": "center" } },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.editCreditLimit(1)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("basicData.Cancel")))]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.editCreditLimit(2)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("basicData.determine")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }