<script >
const __sfc_main = {};
export default __sfc_main;
</script>

<template>
  <div class="wapper-buttons">
    <slot />
  </div>
</template>

<style scoped>
.wapper-buttons {
  display: flex;
  justify-content: space-between;
  padding-top: 0.5rem/* 8px */;
  grid-column: 1 / -1;
}
</style>
