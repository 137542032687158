var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Box" },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            [
              _c(
                "el-row",
                {
                  staticClass: "selectClass",
                  staticStyle: { "flex-wrap": "wrap" },
                  attrs: { type: "flex", justify: "start" },
                },
                [
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("changepwd.collectLoadTime") + "：",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              clearable: false,
                              "default-time": ["00:00:00", "23:59:59"],
                              "end-placeholder": _vm.$t(
                                "collectionCenter.EndTime"
                              ),
                              "range-separator": _vm.$t("collectionCenter.to"),
                              "start-placeholder": _vm.$t(
                                "collectionCenter.StartTime"
                              ),
                              type: "datetimerange",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                            },
                            on: { change: _vm.dateChange },
                            model: {
                              value: _vm.timeValue,
                              callback: function ($$v) {
                                _vm.timeValue = $$v
                              },
                              expression: "timeValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.CustomerN"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t("basicData.PleaseSelect"),
                                filterable: "",
                                clearable: "",
                                remote: "",
                                "reserve-keyword": "",
                                "remote-method": _vm.getCustomerOption,
                              },
                              on: {
                                clear: function ($event) {
                                  return _vm.getCustomerOption("")
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.customerId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "customerId",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.customerId",
                              },
                            },
                            _vm._l(_vm.customerOption, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label:
                                    item.customerAlias +
                                    "（" +
                                    item.username +
                                    "）",
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.collector") + "：",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t("basicData.PleaseSelect"),
                                filterable: "",
                                clearable: "",
                                remote: "",
                                "reserve-keyword": "",
                                "remote-method": _vm.getCollectorOption,
                              },
                              on: {
                                clear: function ($event) {
                                  return _vm.getCollectorOption("")
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.loadUserId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "loadUserId",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.loadUserId",
                              },
                            },
                            _vm._l(_vm.loadUserList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label:
                                    item.realName + "(" + item.username + ")",
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.belongDot"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                                remote: "",
                                "reserve-keyword": "",
                                "remote-method": _vm.getSiteOption,
                              },
                              on: {
                                clear: function ($event) {
                                  return _vm.getSiteOption("")
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.loadSiteId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "loadSiteId",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.loadSiteId",
                              },
                            },
                            _vm._l(_vm.siteAllList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.stateSite"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                                remote: "",
                                "reserve-keyword": "",
                                "remote-method": _vm.getState,
                              },
                              on: {
                                clear: function ($event) {
                                  return _vm.getState("")
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.siteState,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "siteState",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.siteState",
                              },
                            },
                            _vm._l(_vm.stateList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.bagNumber"),
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "collectionCenter.pleaseInput"
                              ),
                              clearable: "",
                            },
                            model: {
                              value: _vm.queryForm.params.loadTagNumber,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm.params,
                                  "loadTagNumber",
                                  $$v
                                )
                              },
                              expression: "queryForm.params.loadTagNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.licensePlateNum"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                                remote: "",
                                "reserve-keyword": "",
                                "remote-method": _vm.getVehicleNumber,
                              },
                              on: {
                                clear: function ($event) {
                                  return _vm.getVehicleNumber("")
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.loadVehicleId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "loadVehicleId",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.loadVehicleId",
                              },
                            },
                            _vm._l(_vm.vehicleNumberList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.vehicleNumber,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "nameTop",
                      staticStyle: { width: "auto" },
                      attrs: { span: 7 },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.$t("orderCenterCont.anjunWaybillNumber") +
                              "：",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "collectionCenter.pleaseInput"
                              ),
                              clearable: "",
                              type: "textarea",
                            },
                            on: { blur: _vm.ft },
                            nativeOn: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.BlockNewNumberline($event)
                              },
                            },
                            model: {
                              value: _vm.queryForm.params.ordeNumberList,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm.params,
                                  "ordeNumberList",
                                  $$v
                                )
                              },
                              expression: "queryForm.params.ordeNumberList",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:bulky-express:large-job-management:collecting-loading-records:find",
                          expression:
                            "'btn:bulky-express:large-job-management:collecting-loading-records:find'",
                          arg: "remove",
                        },
                      ],
                      attrs: {
                        icon: "el-icon-search",
                        size: "mini",
                        type: "primary",
                      },
                      on: { click: _vm.searchClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.search")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:bulky-express:large-job-management:collecting-loading-records:reset",
                          expression:
                            "'btn:bulky-express:large-job-management:collecting-loading-records:reset'",
                          arg: "remove",
                        },
                      ],
                      attrs: { icon: "el-icon-refresh", size: "mini" },
                      on: { click: _vm.resetClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:bulky-express:large-job-management:collecting-loading-records:export",
                          expression:
                            "'btn:bulky-express:large-job-management:collecting-loading-records:export'",
                          arg: "remove",
                        },
                      ],
                      attrs: { icon: "el-icon-upload2", size: "mini" },
                      on: { click: _vm.download },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.export1")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:bulky-express:large-job-management:collecting-loading-records:exportAll",
                          expression:
                            "'btn:bulky-express:large-job-management:collecting-loading-records:exportAll'",
                          arg: "remove",
                        },
                      ],
                      attrs: { icon: "el-icon-upload2", size: "mini" },
                      on: { click: _vm.downloadAll },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "tableRef",
          attrs: { data: _vm.tableDate, "max-height": 600, border: "" },
          on: { select: _vm.batchSelect, "select-all": _vm.batchSelectAll },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.ind"),
              align: "center",
              type: "index",
              width: "60",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          (_vm.queryForm.pageNumber - 1) *
                            _vm.queryForm.pageSize +
                            scope.$index +
                            1
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("changepwd.collectLoadTime"),
              prop: "siteLoadTime",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.CustomerName"),
              prop: "customerName",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("orderCenterCont.anjunWaybillNumber"),
              prop: "orderNumber",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("GlobalSetObj.ScanNumber"),
              prop: "scanNumber",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.collector"),
              prop: "siteLoadUserName",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.belongD"),
              prop: "siteName",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.stateSite1"),
              prop: "siteState",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.carNumber"),
              prop: "siteLoadTagNumber",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.licensePlateNumber"),
              prop: "siteLoadVehicleNumber",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.driver1"),
              prop: "vehicleDriver",
              align: "center",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-pagination", {
            staticStyle: { margin: "10px 0" },
            attrs: {
              "current-page": _vm.queryForm.pageNumber,
              "page-size": _vm.queryForm.pageSize,
              "page-sizes": [10, 20, 50, 100, 500, 1000, 2000],
              total: _vm.total,
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "current-change": _vm.getPageNumber,
              "size-change": _vm.getPageSize,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }