<template>
  <el-form
    ref="ruleForm" :model="ruleForm" :rules="rules"
    label-position="top" class="demo-ruleForm"
  >
    <el-form-item :label="$t('routes.选择班次')" prop="scheduleNumber">
      <ScheduleNumberSelect v-model="ruleForm.scheduleNumber" :list="scheduleNumberList" />
    </el-form-item>
    <el-form-item :label="$t('routes.发车时间')" prop="departureTime">
      <el-time-picker
        v-model="ruleForm.departureTime"
        :placeholder="$t('GlobalSetObj.pleaseChoose')"
        format="HH:mm"
        value-format="HH:mm"
      />
    </el-form-item>
    <el-form-item :label="$t('routes.下一站流向')" prop="nextSiteIdList" :rules="rules.scheduleNumber">
      <NextSiteIdListSelect v-model="ruleForm.nextSiteIdList" :list="nextSiteIdList" />
      <!-- <el-select v-model="ruleForm.nextSiteIdList" :placeholder="$t('GlobalSetObj.pleaseChoose')">
        <el-option
          v-for="item in scheduleNumberList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select> -->
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm('ruleForm')">{{ $t('customerManagements.Save') }}</el-button>
      <el-button @click="onClose(false)">{{ $t('GlobalSetObj.cancel') }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { apiBranchLineDirectModify } from '@/api/routerPlan.js';
import ScheduleNumberSelect from './ScheduleNumberSelect.vue';
import NextSiteIdListSelect from './NextSiteIdListSelect.vue';
import { ADD_ROW } from './../utils.js';
import { cloneDeep } from 'lodash-es';

export default {
  name: '',

  components: {
    ScheduleNumberSelect,
    NextSiteIdListSelect
  },

  props: {
    index: {
      type: Number,
      default: 0
    },

    row: {
      type: Object,
      default: () => {
        return cloneDeep(ADD_ROW);
      }
    },

    // 班次编号
    scheduleNumberList: {
      type: Array,
      required: true
    },

    // 下一站流向
    nextSiteIdList: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      ruleForm: {
        ...cloneDeep(ADD_ROW)
        // scheduleNumber: '', // 班次编号
        // departureTime: '', // 发车时间
        // nextSiteIdList: [] // 下一站流向
      },

      rules: {
        scheduleNumber: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ],
        departureTime: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'blur' }
        ]
      }
    };
  },

  watch: {
    index: {
      handler() {
        const { id } = this.row;
        // 编辑
        if (id) {
          this.ruleForm = cloneDeep(this.row);
        } else {
          // 新增
          this.ruleForm = cloneDeep(ADD_ROW);
        }
      },
      immediate: true
    }
  },

  created() {

  },

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          // alert('submit!');
          return;
        }

        this.asyncApiBranchLineDirectModify();
      });
    },

    // 新增编辑
    asyncApiBranchLineDirectModify() {
      // const row = this.ruleForm;
      console.log(this.ruleForm);

      apiBranchLineDirectModify(this.ruleForm).then((res) => {
        // this.$emit('close', true);
        this.$message.success(this.$t('GlobalSetObj.SaveSuccessfully'));
        setTimeout(() => {
          this.onClose(true);
        }, 1000);
      }).catch(err => {
        console.error(err);
      });
    },

    onClose(isBool) {
      this.$emit('close', isBool);
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
