<template>
  <div class="app-container">
    <el-card style="margin-bottom: 20px;">
      <el-form
        ref="form"
        :model="queryForm"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item class="numberClassSelect">
              <template slot="label">
                <el-select :value="1" :placeholder="$t('operationCenter.PleaseSelect')">
                  <!--入库时间-->
                  <el-option key="1" :label="$t('newOrder.DepositTime')" :value="1" />
                </el-select>
              </template>
              <!--查询时间-->
              <el-date-picker
                v-model="queryDate"
                style="width: 100%"
                type="datetimerange"
                :range-separator="$t('operationCenter.to')"
                :start-placeholder="$t('operationCenter.startDate')"
                :end-placeholder="$t('operationCenter.endDate')"
                value-format="yyyy-MM-dd HH:mm:ss"
                :picker-options="pickerOptions"
                :default-time="['00:00:00', '23:59:59']"
                @blur="refreshDate"
              />
            </el-form-item>
          </el-col>
          <!--  州 -->
          <el-col :span="8">
            <el-form-item>
              <template slot="label">
                <p style="margin: 6px 0px;">
                  {{ $t('GlobalSetObj.Estado') }}
                </p>
              </template>
              <el-select
                v-model="queryForm.params.preDeliverySiteState"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                filterable
                style="width: 100%"
                @change="closePreDeliverySiteState"
              >
                <el-option
                  v-for="item in stateList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.label"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <!--   预派送网点   -->
            <el-form-item>
              <template slot="label">
                <p style="margin: 6px 0px;">
                  {{ $t('GlobalSetObj.preEntrega') }}
                </p>
              </template>
              <el-select
                v-model="queryForm.params.preDeliverySiteId"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                filterable
                style="width:100%"
              >
                <el-option
                  v-for="item in siteList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item class="numberClassSelect">
              <template slot="label">
                <el-select
                  v-model="selectionType"
                  :placeholder="$t('orderDetails.pleaseChoose')"
                >
                  <!--运单号-->
                  <el-option key="1" :label="$t('newOrder.waybillNumber')" :value="1" />
                  <!--                  袋号-->
                  <el-option key="3" :label="$t('GlobalSetObj.bagNumber')" :value="2" />
                </el-select>
              </template>
              <div class="searchOrder">
                <el-input
                  v-model="orderNumber"
                  :placeholder="$t('orderCenterCont.Multiple')"
                  clearable
                  type="textarea"
                  :rows="4"
                />
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <!--入库人-->
            <el-form-item name="customerId">
              <template slot="label">
                <p style="margin: 6px 0px;">
                  {{ $t('operationCenter.operator') }}
                </p>
              </template>
              <el-select
                v-model="queryForm.params.operatorUser"
                filterable
                clearable
                :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
                style="width: 100%;"
              >
                <el-option
                  v-for="(item, ind) in customerList"
                  :key="ind"
                  :label="item.realName + (item.username ? '（' + item.username + '）' : '')"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-button
        type="primary"
        size="small"
        icon="el-icon-search"
        @click="searchResources(1)"
      >{{ $t('operationCenter.Procurar') }}</el-button>
      <el-button size="small" icon="el-icon-refresh-left" @click="searchResources(2)">
        {{ $t('operationCenter.reset') }}
      </el-button>
    </el-card>
    <el-card>
      <div>
        <div style="margin-bottom: 10px;">
          <el-button type="primary" size="small" @click="toExportList()">
            {{ $t('operationCenter.exports') }}
          </el-button>
        </div>
        <el-table :data="tableData" border>
          <!--序号-->
          <el-table-column :label="$t('operationCenter.ind')" width="60px" align="center">
            <template slot-scope="scope">
              <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <!--运单号-->
          <el-table-column
            v-for="(item, ind) in columnList"
            :key="ind"
            :prop="item.prop"
            :label="item.label"
            :align="item.align"
            :min-width="item.width"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row[item.prop] ? scope.row[item.prop] : '--' }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getCurrentTableData"
          @size-change="getSizeTableData"
        />
      </div>
    </el-card>
  </div>
</template>

<script>
import { getUserPage } from '@/api/user';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
// import { getUserName } from '@/utils/asyncTools';
import { getLgAreaListData } from '@/api/orderApi';
import { getAllsite } from '@/api/logisticsOrders';
import { arriveRecordsExportAll, arriveRecordsPage } from '@/api/automaticSorting';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList.js';

export default {
  name: 'ArrivalRecord',
  data() {
    return {
      userIdAndNameObj: {}, // 用户列表，对象
      customerList: [], // 用户列表，数组
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          preDeliverySiteId: '', // 预派送网点
          operatorUser: '', // 操作人
          preDeliverySiteState: this.$t('GlobalSetObj.all')// 州
        }
      },
      queryDate: [this.getDefaultTime().startDate + ' 00:00:00', this.getDefaultTime().endDate + ' 23:59:59'],
      tableData: [], // 表格数据
      total: 0, // 数据总数
      columnList: [ // 表格列
        // 袋号
        { label: this.$t('GlobalSetObj.bagNumber'), prop: 'bagNumber', width: 180, align: 'center' },
        // 运单号
        { label: this.$t('newOrder.waybillNumber'), prop: 'waybillNumber', width: 180, align: 'center' },
        // 到件时间
        { label: this.$t('newOrder.DepositTime'), prop: 'arriveTime', width: 180, align: 'center' },
        // 州
        { label: this.$t('GlobalSetObj.Estado'), prop: 'stateName', width: 180, align: 'center' },
        // 预派送网点
        { label: this.$t('GlobalSetObj.preEntrega'), prop: 'siteName', width: 180, align: 'center' },
        // 总重量(KG)
        { label: this.$t('GlobalSetObj.totalWeightKG'), prop: 'totalWeight', width: 100, align: 'center' },
        // 操作人
        { label: this.$t('GlobalSetObj.operator'), prop: 'operator', width: 120, align: 'center' }
      ],
      pickerOptions: {
        // 设置不能选择的日期
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate0 = minDate.getTime();
          if (maxDate) {
            this.choiceDate0 = '';
          }
        },
        disabledDate: (time) => {
          const choiceDateTime = new Date(this.choiceDate0).getTime();
          const minTime = new Date(choiceDateTime).setDate(
            new Date(choiceDateTime).getDate() - 30
          );
          const maxTime = new Date(choiceDateTime).setDate(
            new Date(choiceDateTime).getDate() + 30
          );
          const min = minTime;
          const newDate =
            new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000;
          const max = newDate < maxTime ? newDate : maxTime;
          // 如果已经选中一个日期 则 返回 该日期前后一个月时间可选
          if (this.choiceDate0) {
            return time.getTime() < min || time.getTime() > max;
          }
          // 若一个日期也没选中 则 返回 当前日期以前日期可选
          return time.getTime() > newDate;
        }
      },
      checkAll: false, // 是否拥有查看所有用户的权限
      stateList: [], // 州列表
      siteList: [], // 预派送网点列表
      siteListObj: {}, // 预派送网点列表对象
      selectionType: 1, // 单号类型选择 1 运单号 2 袋号 3 扫描单号
      orderNumber: ''// 单号文本域
    };
  },
  created() {
    // 用户列表
    this.userIdAndNameObj = getUserIdAndNameObj();
    const buttonPermissions = this.$store.getters.button_permissions;
    this.checkAll = buttonPermissions.includes('btn:arrivalRecord:checkAll');
    if (this.checkAll === false) {
      this.customerList = [
        {
          realName: this.$store.state.user.realName,
          username: this.$store.state.user.username,
          id: this.$store.state.user.id
        }
      ];
    } else {
      // 获取入库员选项
      this.getUserManageOption();
    }
    // 获取网点和州的数据
    this.getAllSite();
    this.getAddress();
    console.log(this.$store.state.user, '====this.$store.state.user');
    console.log(this.checkAll, '====this.checkAll');
    this.searchResources(1);
  },
  methods: {
    // 导出
    toExportList() {
      const paramData = this.searchNumberCollation().params;
      arriveRecordsExportAll(paramData).then(res => {
        if (res.status === 'OK') {
          goExportList(this);
        }
      });
    },
    // 日期选择器失去焦点
    refreshDate() {
      this.choiceDate0 = '';
    },
    // 查询单号整理
    searchNumberCollation() {
      // 将字符串文本域转换为数组
      const orderNumber = [];
      if (this.orderNumber) {
        const numberList = this.orderNumber.split('\n');
        numberList.map(item => {
          const number = item.replace(/\s/g, '').trim();
          if (number) {
            orderNumber.push(number);
          }
        });
      }
      // 筛选出查询时间
      let [start, end] = ['', ''];
      if (this.queryDate && this.queryDate.length > 0) {
        start = this.queryDate[0].split(' ')[0] + ' 00:00:00';
        end = this.queryDate[1].split(' ')[0] + ' 23:59:59';
      }
      // 筛选出查询单号类型
      let [bagNumber, waybillNumber] = [[], [], []];
      const scanOrderNumber = [];
      if (this.selectionType === 1) {
        waybillNumber = orderNumber;
      } else if (this.selectionType === 2) {
        bagNumber = orderNumber;
      }
      const param = {
        pageNumber: this.queryForm.pageNumber,
        pageSize: this.queryForm.pageSize,
        params: {
          ...this.queryForm.params,
          bagNumber,
          scanOrderNumber,
          waybillNumber,
          arriveTimeStart: start,
          arriveTimeEnd: end,
          preDeliverySiteState: this.queryForm.params.preDeliverySiteState === this.$t('GlobalSetObj.all') ? '' : this.queryForm.params.preDeliverySiteState
        }
      };
      return param;
    },
    // 搜索、重置
    searchResources(type) {
      if (type === 1) {
        this.queryForm.pageNumber = 1;
        this.getTableData();
      } else {
        this.queryForm = {
          pageNumber: 1,
          pageSize: 10,
          params: {
            preDeliverySiteId: '', // 预派送网点
            operatorUser: '', // 操作人
            preDeliverySiteState: this.$t('GlobalSetObj.all')// 州
          }
        };
        this.orderNumber = '';
        this.selectionType = 1;
        this.queryDate = [this.getDefaultTime().startDate + ' 00:00:00', this.getDefaultTime().endDate + ' 23:59:59'];
      }
    },
    // 获取默认时间
    getDefaultTime() {
      return {
        endDate: dayjs().format('YYYY-MM-DD'),
        startDate: dayjs().format('YYYY-MM-DD')
      };
    },
    getTableData() {
      const paramData = this.searchNumberCollation();
      // if (this.orderNumber.length > 200) {
      //   this.$message.warning(this.$t('operationCenter.queried200pieces'));
      //   return;
      // }
      console.log(paramData, '====paramData');
      arriveRecordsPage(paramData).then(res => {
        if (res.status === 'OK') {
          this.tableData = res.data.records;
          this.total = res.data.total;
          this.tableData.map((item) => {
            item.systemName = item.systemName ? item.systemName : '- -';
            item.preDeliverStationName = item.preDeliverStationName ? item.preDeliverStationName : '- -';
            item.operator = item.operationName;
            item.stateName = item.preDeliverySiteState ? item.preDeliverySiteState : '- -';
            item.siteName = item.preDeliverySite ? item.preDeliverySite : '- -';
          });
        }
      });
      console.log(paramData, '=====param');
    },
    /**
     * @description 获取用户列表（操作人）
     */
    getUserManageOption() {
      const param = {
        pageSize: 9999999,
        pageNumber: 1,
        params: {
          accountState: '1'
        }
      };
      getUserPage(param).then((response) => {
        if (response.status === 'OK') {
          const { records } = response.data;
          const obj = {
            realName: this.$t('GlobalSetObj.all'),
            id: ''
          };
          this.customerList = records;
          this.customerList.unshift(obj);
        }
      });
    },
    // 页码变化翻页
    getCurrentTableData(val) {
      this.queryForm.pageNumber = val;
      this.getTableData();
    },
    // 每页显示数量变化
    getSizeTableData(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.getTableData();
    },
    /**
     * @description 获取州列表
     */
    getAddress() { // level 0 州    1 城市   2 地区
      const params = {
        level: 0,
        parentId: ''
      };
      getLgAreaListData(params, false).then((res) => {
        if (res.status === 'OK') {
          this.stateList = res.data.map(item => {
            return {
              id: item.id,
              label: `${item.nameEn}`
            };
          });
          this.stateList.unshift({
            id: this.$t('GlobalSetObj.all'),
            label: this.$t('GlobalSetObj.all')
          });
        }
      });
    },
    /**
     * @description 获取所有网点
     */
    getAllSite() {
      this.siteList = [];
      getAllsite().then(res => {
        if (res.status === 'OK') {
          console.log('获取所有网点', res.data);
          this.siteList = res.data;
          this.siteList.unshift({
            id: '',
            name: this.$t('GlobalSetObj.all')
          });
        }
      });
    },
    /**
     * @description 清空州
     */
    closePreDeliverySiteState() {
      if (!this.queryForm.params.preDeliverySiteState) {
        this.queryForm.params.preDeliverySiteState = this.$t('GlobalSetObj.all');
      }
    }
  }
};
</script>
<style>
.numberClassSelect .el-form-item__label .el-select .el-input__inner {
  border: none;
  padding: 0;
}
.numberClassSelect.el-form-item {
  margin-bottom: 10px;
}
</style>
<style scoped>
.imgs /deep/ .el-icon-circle-close {
  color: white;
}
.el-form--label-top .el-form-item__label{
  line-height: 28px !important;
  padding: 0 !important;
}
</style>
