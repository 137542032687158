var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "wrapper-form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        },
      },
    },
    [
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("base.company"),
            "label-for": "company",
            message: _vm.v$.companyName.$errors.length
              ? _vm.v$.companyName.$errors[0].$message
              : null,
          },
        },
        [
          _c(
            "o-autocomplete",
            {
              attrs: {
                id: "company",
                placeholder: _vm.$t("base.companyNamePlaceholder"),
                clearable: "",
                expanded: "",
                field: "name",
                data: _vm.listCompany,
              },
              on: { input: _vm.filteredCompany, select: _vm.selectedCompany },
              model: {
                value: _vm.formData.companyName,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "companyName", $$v)
                },
                expression: "formData.companyName",
              },
            },
            [_c("template", { slot: "empty" }, [_vm._v("Sem resultados")])],
            2
          ),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("base.baseName"),
            "label-for": "base",
            message: _vm.v$.base.$errors.length
              ? _vm.v$.base.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "base",
              placeholder: _vm.$t("base.baseNamePlaceholder"),
            },
            model: {
              value: _vm.formData.base,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "base", $$v)
              },
              expression: "formData.base",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          directives: [
            {
              name: "mask",
              rawName: "v-mask",
              value: ["###.###.###-##", "##.###.###/####-##"],
              expression: "['###.###.###-##', '##.###.###/####-##']",
            },
          ],
          attrs: {
            label: _vm.$t("base.document"),
            "label-for": "document",
            message: _vm.v$.document.$errors.length
              ? _vm.v$.document.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "document",
              placeholder: _vm.$t("base.documentPlaceholder"),
            },
            model: {
              value: _vm.formData.document,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "document", $$v)
              },
              expression: "formData.document",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          directives: [
            {
              name: "mask",
              rawName: "v-mask",
              value: "##############",
              expression: "'##############'",
            },
          ],
          attrs: {
            label: _vm.$t("base.stateRegistration"),
            "label-for": "stateRegistration",
            message: _vm.v$.stateRegistration.$errors.length
              ? _vm.v$.stateRegistration.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "stateRegistration",
              placeholder: _vm.$t("base.stateRegistrationPlaceholder"),
            },
            model: {
              value: _vm.formData.stateRegistration,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "stateRegistration", $$v)
              },
              expression: "formData.stateRegistration",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("base.receivingCapacity"),
            "label-for": "receivingCapacity",
            message: _vm.v$.receivingCapacity.$errors.length
              ? _vm.v$.receivingCapacity.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "receivingCapacity",
              placeholder: _vm.$t("base.receivingCapacity"),
            },
            model: {
              value: _vm.formData.receivingCapacity,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "receivingCapacity", $$v)
              },
              expression: "formData.receivingCapacity",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("base.shippingCapacity"),
            "label-for": "shippingCapacity",
            message: _vm.v$.shippingCapacity.$errors.length
              ? _vm.v$.shippingCapacity.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "shippingCapacity",
              placeholder: _vm.$t("base.shippingCapacityPlaceholder"),
            },
            model: {
              value: _vm.formData.shippingCapacity,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "shippingCapacity", $$v)
              },
              expression: "formData.shippingCapacity",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          staticClass: "span-2 ",
          attrs: {
            label: "IATA",
            "label-for": "iata",
            message: _vm.v$.iata.$errors.length
              ? _vm.v$.iata.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: { id: "iata", placeholder: _vm.$t("base.IATAPlaceholder") },
            model: {
              value: _vm.formData.iata,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "iata", $$v)
              },
              expression: "formData.iata",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("base.openingTime"),
            message: _vm.v$.initialHour.$errors.length
              ? _vm.v$.initialHour.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-timepicker", {
            attrs: {
              rounded: "",
              placeholder: _vm.$t("base.selectTime"),
              icon: "clock",
              "enable-seconds": false,
              "hour-format": "24",
              locale: "pt-BR",
              "default-minutes": 0,
            },
            model: {
              value: _vm.formData.initialHour,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "initialHour", $$v)
              },
              expression: "formData.initialHour",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("base.closingTime"),
            message: _vm.v$.finalHour.$errors.length
              ? _vm.v$.finalHour.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-timepicker", {
            attrs: {
              rounded: "",
              placeholder: _vm.$t("base.selectTime"),
              icon: "clock",
              "enable-seconds": false,
              "hour-format": "24",
              locale: "pt-BR",
              "default-minutes": 0,
            },
            model: {
              value: _vm.formData.finalHour,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "finalHour", $$v)
              },
              expression: "formData.finalHour",
            },
          }),
        ],
        1
      ),
      _c(
        "FormActions",
        [
          _c("FormActionsPrevious", {
            attrs: { "is-first-step": _vm.isFirstStep },
            on: { handlePrevious: _vm.handlePrevious },
          }),
          _c("FormActionsNext", { attrs: { "is-last-step": _vm.isLastStep } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }