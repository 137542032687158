<template>
  <div class="app-container">
    <div class="selectClass">
      <el-form
        ref="queryForm"
        class="queryFormClass"
        :model="queryForm"
        size="mini"
      >

        <el-row type="flex" justify="start" style="flex-wrap: wrap;">
          <!-- 类型 -->
          <el-col :span="7" style="width: auto;">
            <el-form-item :label="$t('WorkCentreTable.ticketType') + '：'">
              <el-select
                v-model="queryForm.params.ticketType"
                :placeholder="$t('operationCenter.PleaseSelect')"
                filterable
                style="width: 100%;"
                remote
                clearable
              >
                <el-option
                  v-for="item in ticketOption"
                  :key="item.id"
                  :label="item.typeName + ( item.englishName ? `(${item.englishName})` : '' ) "
                  :value="item.typeName"
                >
                  <span style="float: left">{{ item.typeName }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.englishName }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <!-- 创建人 -->
          <el-col v-show="userRadio !== '2'" :span="7" style="width: auto;">
            <el-form-item :label="$t('WorkCentreTable.createUserName') + '：'">
              <el-select
                v-model="queryForm.params.createUser"
                :placeholder="$t('operationCenter.PleaseSelect')"
                filterable
                remote
                clearable
                style="width: 100%;"
                :remote-method="getExecuteUser"
                :loading="userObj.loading"
              >
                <el-option
                  v-for="item in userObj.executeUserArr"
                  :key="item.id"
                  :label="item.name + (item.englishName ? `(${item.englishName})` : '')"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.name }}{{ item.englishName ? `(${item.englishName})` : '' }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.positionName }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <!-- 执行人 -->
          <el-col v-show="userRadio !== '1'" :span="7" style="width: auto;">
            <el-form-item :label="$t('WorkCentreTable.executeUserName') + '：'">
              <el-select
                v-model="queryForm.params.executeUser"
                :placeholder="$t('operationCenter.PleaseSelect')"
                filterable
                remote
                clearable
                style="width: 100%;"
                :remote-method="getExecuteUser"
                :loading="userObj.loading"
              >
                <el-option
                  v-for="item in userObj.executeUserArr"
                  :key="item.id"
                  :label="item.name + (item.englishName ? `(${item.englishName})` : '')"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.name }}{{ item.englishName ? `(${item.englishName})` : '' }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.positionName }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <!-- 状态 -->
          <el-col :span="7" style="width: auto;">
            <el-form-item :label="$t('WorkCentreTable.ticketStatus') + '：'">
              <el-radio-group v-model="queryForm.params.ticketStatus" size="medium">
                <!-- 全部 -->
                <el-radio-button label="">{{ $t('WorkCentreTable.whole') }}</el-radio-button>
                <!-- 关闭 -->
                <el-radio-button label="closed">{{ $t('WorkCentreTable.close') }}</el-radio-button>
                <!-- 实施中 -->
                <el-radio-button label="implementing">{{ $t('WorkCentreTable.implementing') }}</el-radio-button>
                <!-- 完成 -->
                <el-radio-button label="over">{{ $t('WorkCentreTable.complete') }}</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <!-- 优先级 -->
          <el-col :span="7" style="width: auto;">
            <el-form-item :label="$t('WorkCentreTable.sort') + '：'">
              <el-radio-group v-model="queryForm.params.sort" size="medium">
                <!-- 全部 -->
                <el-radio-button label="">{{ $t('WorkCentreTable.whole') }}</el-radio-button>
                <!-- 紧急 -->
                <el-radio-button :label="1">{{ $t('WorkCentreTable.urgent') }}</el-radio-button>
                <!-- 高 -->
                <el-radio-button :label="2">{{ $t('WorkCentreTable.high') }}</el-radio-button>
                <!-- 正常 -->
                <el-radio-button :label="3">{{ $t('WorkCentreTable.normal') }}</el-radio-button>
                <!-- 低 -->
                <el-radio-button :label="4">{{ $t('WorkCentreTable.low') }}</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;">
            <!-- 名称 -->
            <el-form-item :label="$t('WorkCentreTable.ticketName') + '：'">
              <el-input
                v-model="queryForm.params.ticketName"
                :placeholder="$t('operationCenter.PleaseEnterTheContent')"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div>
        <!-- 查询 -->
        <el-button
          type="primary" icon="el-icon-search" size="mini"
          @click="getData(isFind=1)"
        >{{ $t('operationCenter.Procurar') }}</el-button>
        <!-- 重置 -->
        <el-button icon="el-icon-refresh" size="mini" @click="queryFormReset">{{ $t('operationCenter.reset') }}</el-button>
      </div>
      <el-divider />
    </div>
    <div>
      <el-radio-group v-model="userRadio" size="mini" @change="handleUser">
        <!-- 全部工单 -->
        <el-radio-button label="">{{ $t('WorkCentreTable.all') }}</el-radio-button>
        <!-- 我创建的 -->
        <el-radio-button label="1">{{ $t('WorkCentreTable.ICreated') }}</el-radio-button>
        <!-- 我执行的 -->
        <el-radio-button label="2">{{ $t('WorkCentreTable.IDid') }}</el-radio-button>
      </el-radio-group>
      <!-- 新增 -->
      <el-button
        v-permit:remove="'but:work-orders-center:mini-work-drd:add'"
        type="primary"
        icon="el-icon-plus"
        size="mini"
        class="ml"
        @click="addTag"
      >{{ $t('operationCenter.add') }}</el-button>
    </div>
    <el-card>
      <el-table
        :data="tableData"
        border
      >
        <el-table-column label="ID" width="40" prop="id" />
        <!-- 优先级 -->
        <el-table-column :label="$t('WorkCentreTable.sort')" width="90" align="center">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.sort === 1"
              type="danger"
              @click="handleType('sort', scope.row.sort)"
            >{{ $t('WorkCentreTable.urgent') }}</el-tag>
            <el-tag
              v-else-if="scope.row.sort === 2"
              type="warning"
              @click="handleType('sort', scope.row.sort)"
            >{{ $t('WorkCentreTable.high') }}</el-tag>
            <el-tag
              v-else-if="scope.row.sort === 3"
              type="success"
              @click="handleType('sort', scope.row.sort)"
            >{{ $t('WorkCentreTable.normal') }}</el-tag>
            <el-tag
              v-else
              type="info"
              @click="handleType('sort', scope.row.sort)"
            >{{ $t('WorkCentreTable.low') }}</el-tag>
          </template>
        </el-table-column>
        <!-- 类型 -->
        <el-table-column :label="$t('WorkCentreTable.ticketType')" width="100" align="center">
          <template slot-scope="scope">
            <div class="clickColor" @click="handleType('ticketType', scope.row.ticketType)">{{ scope.row.ticketType }}</div>
          </template>
        </el-table-column>
        <!-- 名称 -->
        <el-table-column prop="ticketName" :label="$t('WorkCentreTable.ticketName')" width="280">
          <template slot-scope="scope">
            <div class="clickColor" @click="detailsTag(scope.row)">{{ scope.row.ticketName }}</div>
          </template>
        </el-table-column>

        <!-- 创建时间 -->
        <el-table-column prop="createTimeStr" :label="$t('WorkCentreTable.createTime')" width="100" />
        <!-- 剩余时间 -->
        <el-table-column
          prop="timeLeftStr" :label="$t('WorkCentreTable.timeLeft')" width="90"
          align="center"
        >
          <template slot-scope="scope">
            <!-- 已完成 -->
            <div v-if="scope.row.ticketStatus !== 'implementing'" />
            <!-- 未超时 -->
            <template v-else-if="scope.row.timeLeft >= 0">
              <div v-if="scope.row.timeLeft >= 25">
                {{ scope.row.timeLeftDay }}{{ $t('WorkCentreTable.Day') }}{{ scope.row.timeLeftHour }}{{ $t('WorkCentreTable.Hour') }}
              </div>
              <div v-else>{{ scope.row.timeLeftHour }}{{ $t('WorkCentreTable.Hour') }}</div>
            </template>
            <template v-else>
              <div v-if="scope.row.timeLeft <= -25">
                <span class="overtime">{{ $t('WorkCentreTable.TimedOut') }}</span>{{ scope.row.timeLeftDay }}{{ $t('WorkCentreTable.Day') }}{{ scope.row.timeLeftHour }}{{ $t('WorkCentreTable.Hour') }}
              </div>
              <div v-else><span>{{ $t('WorkCentreTable.TimedOut') }}</span>{{ scope.row.timeLeftHour }}{{ $t('WorkCentreTable.Hour') }}</div>
            </template>
          </template>
        </el-table-column>

        <!-- 最后跟进人 -->
        <el-table-column prop="lastFollowerName" :label="$t('WorkCentreTable.lastFollowerName')" width="80" />
        <!-- 最后跟进时间 -->
        <el-table-column prop="lastFollowTime" :label="$t('WorkCentreTable.lastFollowTime')" width="160" />

        <!-- 期望完成时间 -->
        <el-table-column prop="planFinishTime" :label="$t('WorkCentreTable.planFinishTime')" width="160" />
        <!-- 创建人 -->
        <el-table-column prop="createUserName" :label="$t('WorkCentreTable.createUserName')" width="100" />

        <!-- 完工时间 -->
        <el-table-column prop="actualFinishTime" :label="$t('WorkCentreTable.CompletionTime')" width="160" />
        <!-- 执行人 -->
        <el-table-column prop="executeUserName" :label="$t('WorkCentreTable.executeUserName')" width="150" />
        <!-- 状态 -->
        <el-table-column :label="$t('WorkCentreTable.ticketStatus')" width="160">
          <template slot-scope="scope">

            <el-tag
              v-if="scope.row.ticketStatus === 'closed'"
              type="info"
              @click="handleType('ticketStatus', scope.row.ticketStatus)"
            >{{ $t('WorkCentreTable.close') }}</el-tag>
            <el-tag
              v-else-if="scope.row.ticketStatus === 'implementing'"
              type="success"
              @click="handleType('ticketStatus', scope.row.ticketStatus)"
            >{{ $t('WorkCentreTable.implementing') }}</el-tag>
            <el-tag
              v-else
              @click="handleType('ticketStatus', scope.row.ticketStatus)"
            >{{ $t('WorkCentreTable.complete') }}</el-tag>

          </template>
        </el-table-column>
      </el-table>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getData(isFind=0)"
          @size-change="getData(isFind=0)"
        />
      </div>
    </el-card>

    <el-drawer
      :title="$t('WorkCentreTable.MicroWorkOrder')"
      :visible.sync="drawerObj.visible"
      direction="ltr"
      size="70%"
    >
      <tab-cmp
        :index="drawerObj.index"
        :user-id-and-name-obj="userIdAndNameObj"
        :details-obj="drawerObj.detailsObj"
        :ticket-option="ticketOption"
        @close="addClose"
        @closeDetailsCmp="closeDetailsCmp"
      />
    </el-drawer>
  </div>
</template>

<script>
import { MiniWorkOrderState, SORT_STATE } from '@/enums/workOrdersEnums';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { getUserPage } from '@/api/user';
import {
  apiWorkMicroTicketPage,
  apiWorkMicroTicketTypeGetAll
  // apiWorkMicroTicketUpdateStatus
} from '@/api/workorder';
export default {
  name: 'MiniWork',
  components: {
    'tab-cmp': () => import('./cmp/TabCmp.vue')
  },
  data() {
    return {
      total: 0,
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          ticketName: '', // 工单名称
          ticketType: '', // 类型
          sort: '', // 优先级
          createUser: '', // 创建人
          executeUser: '', // 执行人
          ticketStatus: '' // 状态
        }
      },
      tableData: [],

      drawerObj: {
        visible: false,
        index: 1,
        detailsObj: {
          id: 0,
          ticketName: '', // 名称
          ticketDescribe: '', // 内容
          appendix: [], // 附件
          createUserName: '', // 发起人
          executeUserName: '', // 执行人
          planFinishTime: '', // 预计完成时间
          ticketType: '', // 类型
          ticketStatus: '', // 状态
          sort: 4 // 优先级
        }
      },
      ticketOption: [], // 类型
      userRadio: '', // 我创建的 / 我执行的
      isClose: false, // 是否有关闭权限
      isOver: '', // 是否有完成权限
      // userIdAndNameObj: {} // 所有成员

      // 用户相关
      userObj: {
        loading: false, // 用户搜索 加载控制
        createUserArr: [], // 创建人
        executeUserArr: [] // 执行人
      }
    };
  },

  created() {
    this.MiniWorkOrderState = MiniWorkOrderState;
    this.SORT_STATE = SORT_STATE;
    const buttonPermissions = this.$store.getters.button_permissions;

    const userID = this.$store.state.user.id;
    this.userID = userID;

    try {
      // 所有发起人
      this.userIdAndNameObj = getUserIdAndNameObj();
      // console.log(this.userIdAndNameObj);
    } catch (error) {
      console.error(error);
    }

    // 当前页面是否有审核按钮
    this.isClose = buttonPermissions.includes('but:work-orders-center:mini-work-drd:close');
    this.isOver = buttonPermissions.includes('but:work-orders-center:mini-work-drd:over');

    // eslint-disable-next-line no-undef
    this.getData(1);
    setTimeout(() => {
      this.asyncWorkMicroTicketTypePage();

      this.getCreateUser('');
      this.getExecuteUser('');
    }, 50);
  },

  methods: {
    // 获取类型
    asyncWorkMicroTicketTypePage() {
      apiWorkMicroTicketTypeGetAll().then(res => {
        const { status, data } = res;
        if (status !== 'OK') { return; }
        this.ticketOption = data.map(item => {
          const { id, typeName, englishName } = item;
          return {
            id,
            typeName,
            englishName
          };
        });
      }).catch(err => {
        console.error(err);
      });
    },
    // 获取列表数据
    getData(isFind) {
      if (isFind === 1) {
        this.queryForm.pageNumber = 1;
      }
      const userIdAndNameObj = this.userIdAndNameObj;

      apiWorkMicroTicketPage(this.queryForm).then(res => {
        // const { status, records, total } = res;
        if (res.status !== 'OK') { return; }
        this.total = res.data.total;
        // console.log('微工单数据', res.status, res.data.records, res.data.total);
        this.tableData = res.data.records.map(item => {
          const { createUser, executeUser, lastFollower, timeLeft, createTime } = item;

          // 执行人
          let executeUserName = '';
          if (executeUser) {
            executeUserName = JSON.parse(executeUser).map(item => {
              return item.name;
            }).join(',');
          }
          const obj = {
            ...item,
            timeLeftDay: '',
            timeLeftHour: '',
            createTimeStr: createTime.split(' ')[0],
            createUserName: userIdAndNameObj[createUser] || this.$t('WorkCentreTable.system'), // '系统'system
            executeUserName,
            lastFollowerName: userIdAndNameObj[lastFollower] || ''
          };

          // 将小时转成天,（含已经超时）
          {
            const timeLeftNumber = timeLeft <= 0 ? -timeLeft : timeLeft;

            if (timeLeftNumber > 24) {
              obj.timeLeftDay = Math.floor(timeLeftNumber / 24);
              obj.timeLeftHour = timeLeftNumber % 24;
            } else {
              obj.timeLeftHour = timeLeftNumber;
            }
          }

          return obj;
        });
      }).catch(err => {
        console.error(err);
      });
    },

    // 新增
    addTag() {
      const { index } = this.drawerObj;
      this.drawerObj.index = index + 1;
      this.drawerObj.visible = true;
      this.drawerObj.detailsObj = {
        id: 0,
        ticketName: '', // 名称
        createUserName: '', // 发起人
        ticketDescribe: '', // 内容
        executeUserName: '', // 执行人
        planFinishTime: '', // 预计完成时间
        ticketType: '', // 类型
        ticketStatus: '', // 状态
        sort: 4, // 优先级
        appendix: [] // 附件
      };
      this.drawerObj.detailsObj.ticketDescribe = '';
    },

    // 查看
    detailsTag(row) {
      const { index } = this.drawerObj;
      const {
        id,
        ticketName,
        createUserName,
        executeUserName,
        planFinishTime,
        ticketDescribe,
        appendix,
        ticketType,
        ticketStatus,
        sort
      } = row;
      let appendixArr = [];
      if (appendix) {
        appendixArr = appendix.split(',').map(item => {
          const URLArr = item.split('/');
          const name = decodeURI(URLArr[URLArr.length - 1]);
          return {
            name,
            url: item
          };
        });
      }

      this.drawerObj.detailsObj = {
        id,
        ticketName,
        ticketDescribe, // 内容
        appendix: appendixArr, // 附件
        createUserName, // 发起人
        executeUserName, // 执行人
        planFinishTime, // 预计完成时间
        ticketType, // 类型
        ticketStatus, // 状态
        sort // 优先级
      };
      this.drawerObj.index = index + 1;
      this.drawerObj.visible = true;
    },

    // 重置
    queryFormReset() {
      this.queryForm = {
        pageSize: 10,
        pageNumber: 1,
        params: {
          ticketName: '', // 工单名称
          ticketType: '', // 工单类型
          sort: '', // 优先级
          createUser: '', // 创建人
          executeUser: '', // 执行人
          ticketStatus: '' // 状态
        }
      };
    },

    // 新增关闭
    addClose(isBool) {
      this.drawerObj.visible = false;
      // eslint-disable-next-line no-undef
      isBool && this.getData(1);
    },

    // 类型搜索
    handleType(type, value) {
      this.queryForm.params[type] = value;
      // eslint-disable-next-line no-undef
      this.getData(1);
    },

    // 我创建的 我执行的
    handleUser() {
      const userRadio = this.userRadio;

      // 全部
      if (userRadio === '') {
        this.queryForm.params.createUser = '';
        this.queryForm.params.executeUser = '';
      }

      // 我创建的
      if (userRadio === '1') {
        this.queryForm.params.createUser = this.userID + '';
        this.queryForm.params.executeUser = '';
      }

      // 我执行的
      if (userRadio === '2') {
        this.queryForm.params.executeUser = this.userID + '';
        this.queryForm.params.createUser = '';
      }

      // eslint-disable-next-line no-undef
      this.getData(1);
    },

    closeDetailsCmp({ isBool, isUpdateList }) {
      this.drawerObj.visible = isBool;
      // eslint-disable-next-line no-undef
      isUpdateList && this.getData(1);
    },

    // 创建人
    async getCreateUser(val) {
      const obj = {
        pageSize: 20,
        pageNumber: 1,
        params: {
          realName: val,
          accountState: '1'// 启用
        }
      };
      try {
        this.userObj.createUserArr = await this.asyncGetUserPage(obj);
      } catch (error) {
        console.error(error);
      }
    },

    // 执行人
    async getExecuteUser(val) {
      const obj = {
        pageSize: 20,
        pageNumber: 1,
        params: {
          realName: val,
          accountState: '1'// 启用
        }
      };
      try {
        this.userObj.executeUserArr = await this.asyncGetUserPage(obj);
      } catch (error) {
        console.error(error);
      }
    },

    // 获取用户信息（含职务）
    asyncGetUserPage(obj) {
      this.userObj.loading = true;
      return getUserPage(obj, false).then(res => {
        // const { status, tableData } = res;
        if (res.status !== 'OK') { return; }

        return res.data.records.map(item => {
          const { realName, id, positionName, englishName } = item;
          return {
            name: realName,
            id,
            englishName,
            positionName // 职位
          };
        });
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.userObj.loading = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .selectClass ::v-deep .el-form-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .selectClass ::v-deep .el-form-item__label{
    padding: 0;
    line-height: 16px;
    white-space: nowrap;
    margin-left: 20px;
  }
  .selectClass ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
  .selectClass ::v-deep .el-input__inner{
    height: 40px;
  }
.ml {
  margin-left: 5px;
}
.clickColor {
  color: #409EFF;
  cursor: pointer;
}

.overtime {
  color: #F56C6C;
  font-weight: bold;
}
</style>
