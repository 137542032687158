var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      staticClass: "demo-ruleForm",
      attrs: { model: _vm.ruleForm, rules: _vm.rules, "label-width": "100px" },
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("GlobalSetObj.client"),
                    prop: "customerId",
                  },
                },
                [
                  _c("CustomerSelectCmp", {
                    staticStyle: { width: "100%" },
                    attrs: { disabled: _vm.ids !== 0 },
                    on: {
                      change: function () {
                        _vm.onClearList()
                        _vm.onChangeCustomer()
                      },
                    },
                    model: {
                      value: _vm.ruleForm.customerId,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "customerId", $$v)
                      },
                      expression: "ruleForm.customerId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("Resource.applicant"),
                    prop: "applicant",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      maxlength: "40",
                      "show-word-limit": "",
                      placeholder: _vm.$t("GlobalSetObj.pleaseInputContent"),
                    },
                    model: {
                      value: _vm.ruleForm.applicant,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "applicant",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.applicant",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t(
                      "ClaimsManagement.ApplicantsContactInformation"
                    ),
                    prop: "applicantContactInformation",
                    "label-width": "120px",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t("GlobalSetObj.pleaseInputContent"),
                      maxlength: "40",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.ruleForm.applicantContactInformation,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "applicantContactInformation",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.applicantContactInformation",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("collectionCenter.businessT"),
                    prop: "claimsNode",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                      },
                      on: { change: _vm.onClearList },
                      model: {
                        value: _vm.ruleForm.claimsNode,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "claimsNode", $$v)
                        },
                        expression: "ruleForm.claimsNode",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label: _vm.CLAIMSNODE_TYPE_OBJ["2"].name,
                          value: _vm.CLAIMSNODE_TYPE_OBJ["2"].value,
                        },
                      }),
                      _c("el-option", {
                        attrs: {
                          label: _vm.CLAIMSNODE_TYPE_OBJ["3"].name,
                          value: _vm.CLAIMSNODE_TYPE_OBJ["3"].value,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("ClaimsManagement.AmountOfClaim"),
                    prop: "claimAmount",
                  },
                },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "300px" },
                    attrs: { min: 0, precision: 3, controls: false },
                    model: {
                      value: _vm.ruleForm.claimAmount,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "claimAmount", $$v)
                      },
                      expression: "ruleForm.claimAmount",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("Resource.Reviewer"),
                    prop: "reviewer",
                    "label-width": "120px",
                  },
                },
                [
                  _c("UserSelectCmp", {
                    ref: "userSelectRef",
                    staticStyle: { width: "100%" },
                    attrs: {
                      "user-name": _vm.userName,
                      "is-organization-name": true,
                    },
                    model: {
                      value: _vm.ruleForm.reviewer,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "reviewer", $$v)
                      },
                      expression: "ruleForm.reviewer",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _vm.ruleForm.claimsNode === 2
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("basicData.PickupCategory"),
                        prop: "orderType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                          },
                          on: { change: _vm.onClearList },
                          model: {
                            value: _vm.ruleForm.orderType,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "orderType", $$v)
                            },
                            expression: "ruleForm.orderType",
                          },
                        },
                        _vm._l(_vm.orderTypeList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.name, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("ClaimsManagement.CauseOfClaim"),
                    prop: "claimReason",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 2, maxRows: 4 },
                      placeholder: _vm.$t("GlobalSetObj.pleaseInputContent"),
                    },
                    model: {
                      value: _vm.ruleForm.claimReason,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "claimReason", $$v)
                      },
                      expression: "ruleForm.claimReason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("collectionCenter.CommercialOwner"),
                    prop: "merchantsId",
                    "label-width": "120px",
                  },
                },
                [
                  _c("CommercialOwnerCmp", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "merchants-id": _vm.ruleForm.merchantsId,
                      "merchants-name": _vm.ruleForm.merchantsName,
                      "customer-id": _vm.ruleForm.customerId,
                    },
                    on: { change: _vm.onChangeCommercial },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "upload" },
        [
          _c(
            "el-col",
            { attrs: { span: 16 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("customerManagements.enclosure"),
                    prop: "attachmentUrl",
                  },
                },
                [
                  _c("UploadCmp", {
                    attrs: {
                      drag: true,
                      multiple: true,
                      "file-arr": _vm.fileArr,
                      "auto-upload": true,
                      limit: 5,
                    },
                    on: { change: _vm.onFileChange },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }