var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "preStyle" },
    [
      _c(
        "el-form",
        {
          ref: "formData",
          staticStyle: {
            padding: "20px",
            height: "calc(100vh - 68px)",
            "overflow-y": "auto",
          },
          attrs: { "label-width": "100px", "label-position": "left" },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple-light" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          prop: "referenceNumber",
                          label: _vm.$t("receivablePayable.TicketNumber"),
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.row.businessNumber))])]
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple-light" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          prop: "type",
                          label: _vm.$t("orderCenterCont.RequestTime"),
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.row.createTime))])]
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple-light" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          prop: "createTime",
                          label: _vm.$t("routes.usernameLabel"),
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.row.createUserName))])]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple-light" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("orderCenterCont.RequestMessages"),
                        },
                      },
                      [
                        _c("PreCodeCmp", {
                          attrs: { "json-strin": _vm.row.requestInfo },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple-light" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("orderCenterCont.ResponseMessages"),
                        },
                      },
                      [
                        _c("PreCodeCmp", {
                          attrs: { "json-strin": _vm.row.responseInfo },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }