<template>
  <div class="app-container">
    <el-form
      label-width="auto"
      label-position="right"
      size="mini"
    >
      <el-row :gutter="10">
        <el-col :span="6">
          <!--客户名称-->
          <el-form-item :label="$t('basicData.CustomerName') + '：'">
            <span>{{ queryForm.customerAlias }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!--未结算总金额-->
          <el-form-item :label="$t('basicData.UnsettledTotalAmount') + '：'">
            <span>{{ queryForm.unsettledAmount }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!--账户余额-->
          <el-form-item :label="$t('basicData.AccountBalance') + '：'">
            <span>{{ queryForm.balance }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!--总计余额-->
          <el-form-item :label="$t('basicData.TotalBalance') + '：'">
            <span>{{ queryForm.totalBalance }}</span>
          </el-form-item>
        </el-col>
        <!--        <el-col :span="8">-->
        <!--          &lt;!&ndash;已收总金额&ndash;&gt;-->
        <!--          <el-form-item :label="$t('basicData.TotalAmountReceived') + '：'">-->
        <!--            <span>{{ detailForm.receivedTotalAmount }}</span>-->
        <!--          </el-form-item>-->
        <!--        </el-col>-->
      </el-row>
      <el-row :gutter="10">
        <el-col :span="8">
          <!--类别-->
          <el-form-item :label="$t('basicData.category') + '：'">
            <el-select
              v-model="accountFlowForm.params.receiptType"
              :placeholder="$t('basicData.PleaseSelect')"
              style="width: 100%"
              @change="getDetailTable(1)"
            >
              <!--全部-->
              <el-option :key="0" :label="$t('basicData.all')" :value="null" />
              <!--核收-->
              <el-option :key="1" :label="$t('basicData.acceptanceCheck')" :value="0" />
              <!--缴清(充值)-->
              <el-option :key="2" :label="$t('basicData.recharge')" :value="1" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!--业务时间-->
          <el-form-item :label="$t('basicData.BusinessTime') + '：'">
            <el-date-picker
              v-model="queryDate"
              style="width: 100%"
              type="datetimerange"
              :range-separator="$t('operationCenter.to')"
              :start-placeholder="$t('operationCenter.startDate')"
              :end-placeholder="$t('operationCenter.endDate')"
              value-format="yyyy-MM-dd HH:mm:ss"
              @change="getDetailTableData()"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <div style="text-align: right">
            <!--导出账户流水-->
            <el-button
              v-permit:remove="'but:finance:basicData:accountManagement:ExportAccountRollover'"
              size="mini"
              type="primary"
              @click="exportAccount()"
            >{{ $t('basicData.ExportAccountRollover') }}
            </el-button>
            <!--充值-->
            <el-button
              v-permit:remove="'but:finance:basicData:accountManagement:recharge'"
              size="mini"
              type="primary"
              @click="rechargeAccount()"
            >{{ $t('basicData.recharge') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <div>
      <el-table
        ref="elTable"
        :data="tableData"
        border
        row-key="id"
        :tree-props="{ children: 'siteReceipts' }"
        height="550"
      >
        <!--业务日期-->
        <el-table-column
          prop="acceptTime"
          :label="$t('orderCenterCont.DateBusiness')"
          align="left"
          width="240"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.acceptTime }}</span>
          </template>
        </el-table-column>

        <!-- 核收时间 -->
        <el-table-column
          prop="acceptOperateTime"
          :label="$t('receivablePayable.CollectionTime')"
          align="left"
          width="160"
          :show-overflow-tooltip="true"
        />

        <!--批次号-->
        <el-table-column
          prop="receiptNumber"
          :label="$t('basicData.BatchNo')"
          width="240"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.receiptType !== 1" class="batchNumber" @click="showBillBatchNumber(scope.row)">{{ scope.row.receiptNumber }}</span>
            <span v-else>{{ scope.row.receiptNumber }}</span>
          </template>
        </el-table-column>
        <!--交款金额-->
        <el-table-column
          prop="actualFeeBrl"
          :label="$t('basicData.PaymentAmount') + '(BRL)'"
          align="center"
          width="180"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.actualFeeBrl }}</div>
          </template>
        </el-table-column>
        <!--票单金额-->
        <el-table-column
          prop="billFee"
          :label="$t('basicData.TicketAmount') + '(BRL)'"
          align="center"
          width="180"
          :show-overflow-tooltip="true"
        />
        <!--原币种交款金额-->
        <el-table-column
          prop="actualFee"
          :label="$t('basicData.amountPaymentOriginal')"
          align="center"
          width="120"
          :show-overflow-tooltip="true"
        />
        <!--币种-->
        <el-table-column
          prop="currencyName"
          :label="$t('basicData.Currency')"
          align="center"
          width="120"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.currencyName }}({{ scope.row.currencyCode }})</div>
          </template>
        </el-table-column>
        <!--汇率-->
        <el-table-column
          prop="exchangeRate"
          :label="$t('basicData.exchangeRate')"
          align="center"
          width="120"
          :show-overflow-tooltip="true"
        />
        <!--类别-->
        <el-table-column
          prop="receiptType"
          :label="$t('basicData.category')"
          align="center"
          width="180"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <!--'核收' : '充值'-->
            <div>{{ scope.row.receiptType === 0 ? $t('basicData.acceptanceCheck') : $t('basicData.recharge') }}</div>
          </template>
        </el-table-column>
        <!--状态-->
        <el-table-column
          prop="receiptStatus"
          :label="$t('basicData.status')"
          align="center"
          width="180"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <!--'待核收' : '已核收'-->
            <div>{{ scope.row.receiptStatus === 0 ? $t('receivablePayable.ToBeVerified') : $t('basicData.Received') }}</div>
          </template>
        </el-table-column>
        <!--票证号-->
        <el-table-column
          prop="ticketNumber"
          :label="$t('basicData.TicketNumber')"
          align="center"
          width="180"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.ticketNumber }}</div>
          </template>
        </el-table-column>
        <!--走账户-->
        <el-table-column
          prop="accountAlias"
          :label="$t('basicData.GoAccount')"
          align="center"
          width="180"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.accountAlias }}</div>
          </template>
        </el-table-column>
        <!--付款方式-->
        <el-table-column
          prop="receiptMethod"
          :label="$t('basicData.PaymentMethod')"
          align="center"
          width="180"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.receiptMethod === 'cash'">{{ $t('basicData.cash') }}</div>
            <div v-if="scope.row.receiptMethod === 'transfer'">{{ $t('basicData.transferable') }}</div>
            <div v-if="scope.row.receiptMethod === 'network'">{{ $t('basicData.online') }}</div>
            <div v-if="scope.row.receiptMethod === 'sterilisation'">{{ $t('basicData.Elimination') }}</div>
            <div v-if="scope.row.receiptMethod === 'other'">{{ $t('basicData.other') }}</div>
          </template>
        </el-table-column>
        <!--操作前余额-->
        <el-table-column
          prop="beforeBalance"
          :label="$t('basicData.BalanceBeforeOperation')"
          align="center"
          width="180"
          :show-overflow-tooltip="true"
        />
        <!--操作后余额-->
        <el-table-column
          prop="afterBalance"
          :label="$t('basicData.BalanceAfterOperation')"
          align="center"
          width="180"
          :show-overflow-tooltip="true"
        />
        <!--附件-->
        <el-table-column
          prop="attachmentUrl"
          :label="$t('basicData.annex')"
          align="center"
          width="200"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-link
              v-if="scope.row.attachmentUrl && scope.row.attachmentUrl.length"
              type="info"
              @click="getImgSrc(scope.row.attachmentUrl)"
            >{{ $t('basicData.View') }}<!--查看--></el-link>
          </template>
        </el-table-column>
        <!--备注-->
        <el-table-column
          prop="remark"
          :label="$t('receivablePayable.remark')"
          align="left"
          min-width="200"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div class="remarkStyle" @click="showImg($event)" v-html="scope.row.remark" />
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="accountFlowForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="accountFlowForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getDetailTable"
          @size-change="getDetailTable"
        />
      </div>
    </div>
    <div class="imgs">
      <el-image-viewer v-if="imageViewerObj.showViewer" :on-close="closeViewer" :url-list="imageViewerObj.imgArr" />
    </div>
    <!--账户详情-->
    <el-drawer
      :title="$t('basicData.AccountDetails')"
      :append-to-body="true"
      :visible.sync="showDetailList"
      direction="ltr"
      size="90%"
    >
      <!-- :batch-number="batchNumber" -->
      <div>
        <batch-number-detail
          :key="detailKeyList"
          :detail-query="detailQuery"
        />
      </div>
    </el-drawer>
    <!--充值-->
    <el-dialog
      :title="$t('basicData.recharge')"
      :visible.sync="showCollection"
      :append-to-body="true"
      width="80%"
    >
      <div>
        <collection-detail
          :key="collectionKey"
          :recharge-data="queryForm"
          :account-option="accountOption"
          @collectionUpdate="collectionUpdate"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  // apiGetCustomerFinanceInfo,
  // apiCustomerReceiptPage,
  apiSiteReceiptGetPage,
  apiCustomerExportAccountStatement
} from '@/api/finance/basicData';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import { MediaTypeEnum } from '@/enums/mediaTypeEnum';
import { accountPage } from '@/api/finance/partition';
import { goExportList } from '@/utils/goExportList.js';
import { cloneDeep } from 'lodash-es';
import { EXAMINE_TABLE_QUERY } from './../utils';

export default {
  name: 'AccountDetails',
  components: {
    ElImageViewer,
    'batch-number-detail': () => import('./batchNumberDetail'),
    'collection-detail': () => import('./collectionDetail')
  },
  props: {
    // row详情数据
    queryForm: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      queryDate: [],
      tableData: [],
      total: 0,
      imageViewerObj: {
        imgArr: [],
        showViewer: false
      },
      showDetailList: false,
      detailKeyList: new Date().getTime(),
      tableDetailData: [],
      batchNumber: '',
      showCollection: false,
      collectionKey: new Date().getTime(),
      accountOption: [],
      // detailForm: {},
      accountFlowForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          beginTime: '',
          endTime: '',
          customerId: '',
          receiptType: null
        }
      },

      // 详情参数
      detailQuery: {
        ...EXAMINE_TABLE_QUERY
      }
    };
  },
  created() {
    // this.getCustomerDetail();
    this.getDetailTable();
    this.getAccountOption();
  },
  methods: {
    // 导出账户流水
    exportAccount() {
      const param = {
        customerIds: [this.queryForm.id],
        receiptType: this.accountFlowForm.params.receiptType,
        startTime: '',
        endTime: ''
      };
      if (this.queryDate && this.queryDate.length > 0) {
        param.startTime = this.queryDate[0];
        param.endTime = this.queryDate[1];
      } else {
        this.$message.warning(this.$i18n.t('collectionCenter.PleaseSelectTime')); // 请选择时间
        return;
      }
      apiCustomerExportAccountStatement(param).then(res => {
        if (res.status === 'OK') {
          return goExportList(this);
        }
      }).then(res => {
        if (res) {
          this.$emit('closeDetail');
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 更新页面
    collectionUpdate(type) {
      if (type === 2) {
        this.getDetailTable();
        this.$emit('closeDetail', true);
      }
      this.showCollection = false;
    },
    // 充值
    rechargeAccount() {
      this.collectionKey = new Date().getTime();
      this.showCollection = true;
    },
    // 获取账户
    getAccountOption() {
      const param = {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 1000000,
        params: {
          status: 1
        }
      };
      accountPage(param, false).then(res => {
        if (res.status === 'OK') {
          this.accountOption = res.data.records;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 获取账单详情
    showBillBatchNumber(row) {
      const { receiptNumber, siteId, siteName } = row;
      // this.batchNumber = row.receiptNumber;
      this.detailKeyList = new Date().getTime();
      this.showDetailList = true;

      this.detailQuery.receiptNumber = receiptNumber;
      this.detailQuery.siteId = siteId;
      this.detailQuery.name = siteName;
    },
    // 图片点击放大
    showImg(e) {
      if (e.target.tagName === 'IMG') {
        this.$set(this.imageViewerObj, 'showViewer', true);
        this.$set(this.imageViewerObj, 'imgArr', [e.target.src]);

        // this.imgPreview.img = e.target.src;
        // this.imgPreview.show = true;
      }
    },
    // 关闭查看图片
    closeViewer() {
      this.$set(this.imageViewerObj, 'showViewer', false);
    },
    // 图片/PDF 查看
    getImgSrc(url) {
      // 图片
      if (url) {
        const urlList = url.split(',');
        let isBool = [];
        const urlArr = [];
        urlList.map((list) => {
          if (list) {
            const suffix = list.substring(list.lastIndexOf('.') + 1);
            if (suffix !== 'pdf' && suffix !== 'PDF') {
              urlArr.push(list);
            }
            isBool = MediaTypeEnum.typeImg.filter((item) => {
              return list.endsWith(item);
            });
          }
          if (isBool.length === 0) {
            window.open(list);
          }
        });
        if (urlArr.length !== 0) {
          this.imageViewerObj.imgArr = urlArr;
          this.imageViewerObj.showViewer = true;
          return;
        }
      }
    },
    // // 获取账户详情
    // getCustomerDetail() {
    //   apiGetCustomerFinanceInfo(this.queryForm.id).then(res => {
    //     if (res.status === 'OK') {
    //       this.detailForm = res.data;
    //     }
    //   });
    // },
    getDetailTableData() {
      this.accountFlowForm.pageNumber = 1;
      this.getDetailTable();
    },

    // 获取账户流水 请求参数处理
    getDetailTableQuery() {
      const obj = cloneDeep(this.accountFlowForm);
      const queryDate = this.queryDate;
      const { id } = this.queryForm;

      // 时间处理
      if (queryDate && queryDate.length > 0) {
        const [startTime, endTime] = queryDate;
        obj.params.acceptStartTime = startTime;
        obj.params.acceptEndTime = endTime;
      } else {
        obj.params.beginTime = '';
        obj.params.endTime = '';
      }

      obj.params.isAccept = 1;

      obj.params.customerId = id;

      return obj;
    },

    // 获取账户流水
    getDetailTable() {
      // if (this.queryDate && this.queryDate.length > 0) {
      //   this.accountFlowForm.params.beginTime = this.queryDate[0];
      //   this.accountFlowForm.params.endTime = this.queryDate[1];
      // } else {
      //   this.accountFlowForm.params.beginTime = '';
      //   this.accountFlowForm.params.endTime = '';
      // }
      // this.accountFlowForm.params.customerId = this.queryForm.id;
      const row = this.getDetailTableQuery();
      apiSiteReceiptGetPage(row).then(res => {
        if (res.status === 'OK') {
          res.data.records.map(item => {
            for (const key in item) {
              if (key === 'acceptTime' || key === 'auditTime') {
                if (item[key]) {
                  item[key] = item[key].replace('T', ' ');
                }
              }
            }
          });
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      }).catch(err => {
        console.error(err);
      });
    }
  }
};
</script>

<style scoped>
.batchNumber {
  color: #007efd;
  cursor: pointer;
}
</style>
<style>
.remarkStyle p {
  padding: 0!important;
  margin: 0!important;
}
</style>
