var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "searchBox" },
      [
        _c(
          "el-form",
          {
            ref: "searchForm",
            attrs: { "label-position": "top" },
            model: {
              value: _vm.queryForm,
              callback: function ($$v) {
                _vm.queryForm = $$v
              },
              expression: "queryForm",
            },
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  { staticStyle: { height: "70px" }, attrs: { span: 5 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("GlobalSetObj.InvoicingStatus"),
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                              clearable: "",
                              filterable: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.searchFormGetTable(null)
                              },
                            },
                            model: {
                              value: _vm.queryForm.params.invoiceStatus,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm.params,
                                  "invoiceStatus",
                                  $$v
                                )
                              },
                              expression: "queryForm.params.invoiceStatus",
                            },
                          },
                          [
                            _c("el-option", {
                              key: "1",
                              attrs: {
                                label: _vm.$t("GlobalSetObj.all"),
                                value: "",
                              },
                            }),
                            _c("el-option", {
                              key: "2",
                              attrs: {
                                label: _vm.$t("GlobalSetObj.ToBeInvoiced"),
                                value: 1,
                              },
                            }),
                            _c("el-option", {
                              key: "3",
                              attrs: {
                                label: _vm.$t("GlobalSetObj.Invoiced"),
                                value: 2,
                              },
                            }),
                            _c("el-option", {
                              key: "4",
                              attrs: {
                                label: _vm.$t("GlobalSetObj.Rejected"),
                                value: 3,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticStyle: { height: "70px" }, attrs: { span: 5 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("GlobalSetObj.interval") } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                              clearable: "",
                              filterable: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.searchFormGetTable(null)
                              },
                            },
                            model: {
                              value: _vm.queryForm.params.section,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryForm.params, "section", $$v)
                              },
                              expression: "queryForm.params.section",
                            },
                          },
                          [
                            _c("el-option", {
                              key: "1",
                              attrs: {
                                label: _vm.$t("GlobalSetObj.all"),
                                value: "",
                              },
                            }),
                            _c("el-option", {
                              key: "2",
                              attrs: {
                                label: _vm.$t("GlobalSetObj.HarvestRange"),
                                value: 2,
                              },
                            }),
                            _c("el-option", {
                              key: "3",
                              attrs: {
                                label: _vm.$t("GlobalSetObj.DeliverySection"),
                                value: 3,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticStyle: { height: "70px" }, attrs: { span: 5 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("operationCenter.Type") } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                              clearable: "",
                              filterable: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.searchFormGetTable(null)
                              },
                            },
                            model: {
                              value: _vm.queryForm.params.invoiceType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm.params,
                                  "invoiceType",
                                  $$v
                                )
                              },
                              expression: "queryForm.params.invoiceType",
                            },
                          },
                          [
                            _c("el-option", {
                              key: "1",
                              attrs: {
                                label: _vm.$t("GlobalSetObj.all"),
                                value: "",
                              },
                            }),
                            _c("el-option", {
                              key: "2",
                              attrs: { value: 1, label: "CT-e" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticStyle: { height: "70px" }, attrs: { span: 9 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "selectType",
                            attrs: { span: _vm.$i18n.locale == "zh" ? 10 : 20 },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: _vm.$t(
                                    "orderDetails.pleaseChoose"
                                  ),
                                  size: "mini",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeNumberType(
                                      1,
                                      _vm.queryForm.params.searchDateType
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.queryForm.params.searchDateType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "searchDateType",
                                      $$v
                                    )
                                  },
                                  expression: "queryForm.params.searchDateType",
                                },
                              },
                              [
                                _c("el-option", {
                                  key: "0",
                                  attrs: {
                                    label: _vm.$t(
                                      "GlobalSetObj.LogisticsInvoiceInvoicingTime"
                                    ),
                                    value: 1,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          [
                            _c(
                              "el-form-item",
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "end-placeholder": _vm.$t(
                                      "GlobalSetObj.endDate"
                                    ),
                                    format:
                                      _vm.$i18n.locale === "zh"
                                        ? _vm.zhDate
                                        : _vm.ptDate,
                                    "range-separator":
                                      _vm.$t("GlobalSetObj.to"),
                                    "start-placeholder": _vm.$t(
                                      "GlobalSetObj.startDate"
                                    ),
                                    type: "datetimerange",
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.searchFormGetTable(null)
                                    },
                                  },
                                  model: {
                                    value: _vm.invoiceDate,
                                    callback: function ($$v) {
                                      _vm.invoiceDate = $$v
                                    },
                                    expression: "invoiceDate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "selectType",
                            attrs: { span: _vm.$i18n.locale == "zh" ? 10 : 20 },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: _vm.$t(
                                    "orderDetails.pleaseChoose"
                                  ),
                                  size: "mini",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeNumberType(
                                      2,
                                      _vm.queryForm.params.searchNumberType
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.queryForm.params.searchNumberType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "searchNumberType",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "queryForm.params.searchNumberType",
                                },
                              },
                              _vm._l(_vm.selectLabel, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c("el-form-item", { staticClass: "NumberClass" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "searchOrder",
                                  staticStyle: { "padding-left": "16px" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "orderDetails.pleaseInput"
                                      ),
                                      clearable: "",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.searchFormGetTable(1)
                                      },
                                    },
                                    model: {
                                      value: _vm.queryForm.params.numberData,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryForm.params,
                                          "numberData",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "queryForm.params.numberData",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticStyle: { width: "40px" } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "chaxunBtn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.searchFormGetTable(1)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "iconfont icon-chaxun",
                                            staticStyle: {
                                              "font-size": "24px",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { width: "38px" } },
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "bottom-end",
                                            trigger: "manual",
                                          },
                                          model: {
                                            value: _vm.showNumList,
                                            callback: function ($$v) {
                                              _vm.showNumList = $$v
                                            },
                                            expression: "showNumList",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticStyle: { width: "300px" } },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "newOrder.PreciseSearch"
                                                  ),
                                                  rows: 10,
                                                  type: "textarea",
                                                },
                                                model: {
                                                  value: _vm.searchNumberStr,
                                                  callback: function ($$v) {
                                                    _vm.searchNumberStr = $$v
                                                  },
                                                  expression: "searchNumberStr",
                                                },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "10px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: { size: "small" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.clearSearchNum()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "newOrder.empty"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        float: "right",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            size: "small",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.openNumList,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "orderExport.close"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            plain: "",
                                                            size: "small",
                                                            type: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.searchFormGetTable(
                                                                2
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "newOrder.search"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "btnIcon",
                                              attrs: { slot: "reference" },
                                              on: { click: _vm.openNumList },
                                              slot: "reference",
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "iconfont icon-liebiao1",
                                                staticStyle: {
                                                  "font-size": "24px",
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.searchNumberList.length > 0,
                    expression: "searchNumberList.length > 0",
                  },
                ],
              },
              [
                _c(
                  "div",
                  { staticClass: "searchNum" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: { effect: "dark", placement: "bottom" },
                      },
                      [
                        _c("template", { slot: "content" }, [
                          _c("pre", [_vm._v(_vm._s(_vm.searchNumberStr))]),
                        ]),
                        _c("div", { staticClass: "searchNumWarp" }, [
                          _c("div", { staticClass: "wordBreak" }, [
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.searchNameObj.label) +
                                  "： " +
                                  _vm._s(_vm.searchNumberList.join(",")) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "clearSearchNum",
                        on: {
                          click: function ($event) {
                            return _vm.clearSearchNum()
                          },
                        },
                      },
                      [_c("i", { staticClass: "el-icon-close" })]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticStyle: { padding: "10px 20px" } },
      [
        _c(
          "el-table",
          { ref: "tableData", attrs: { data: _vm.tableData, border: "" } },
          [
            _c("el-table-column", {
              attrs: {
                label: _vm.$t("operationCenter.ind"),
                align: "center",
                width: "160px",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            (_vm.queryForm.pageNumber - 1) *
                              _vm.queryForm.pageSize +
                              scope.$index +
                              1
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._l(_vm.tableColumn, function (item, ind) {
              return [
                _c(
                  "el-table-column",
                  {
                    key: ind,
                    attrs: {
                      label: item.label,
                      prop: item.prop,
                      width: item.width,
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              item.isCopy
                                ? _c("div", [
                                    scope.row[item.prop]
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "el-popover",
                                              {
                                                attrs: {
                                                  placement: "top",
                                                  "popper-class": "copy",
                                                  trigger: "hover",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      color: "black",
                                                      "font-size": "12px",
                                                      padding: "2px",
                                                    },
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.copy(
                                                          scope.row[item.prop]
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "orderDetails.copy"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    class: item.highlight
                                                      ? "WaybillNumber"
                                                      : "",
                                                    attrs: {
                                                      slot: "reference",
                                                    },
                                                    on: {
                                                      click: function (e) {
                                                        return _vm.$emit(
                                                          "to-OrderDetail",
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                    slot: "reference",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          scope.row[item.prop]
                                                            ? scope.row[
                                                                item.prop
                                                              ]
                                                            : "--"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _c("div", [_vm._v("--")]),
                                  ])
                                : item.endProp
                                ? _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.row[item.prop]) +
                                        " ~ " +
                                        _vm._s(scope.row[item.endProp]) +
                                        " "
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row[item.prop]
                                          ? scope.row[item.prop]
                                          : "--"
                                      )
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    item.tips
                      ? _c("template", { slot: "header" }, [
                          _c(
                            "div",
                            [
                              _c("span", [_vm._v(_vm._s(item.label))]),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "QuestionMark",
                                  attrs: { placement: "top" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [_vm._v(_vm._s(item.tips))]
                                  ),
                                  _c("svg-icon", {
                                    attrs: { "icon-class": "QuestionMark" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "pagination" },
          [
            _c("el-pagination", {
              staticStyle: { margin: "10px 0" },
              attrs: {
                "current-page": _vm.queryForm.pageNumber,
                "page-size": _vm.queryForm.pageSize,
                "page-sizes": [10, 20, 40, 60, 80, 100],
                total: _vm.total,
                background: "",
                layout: "total, sizes, prev, pager, next, jumper",
              },
              on: {
                "update:currentPage": function ($event) {
                  return _vm.$set(_vm.queryForm, "pageNumber", $event)
                },
                "update:current-page": function ($event) {
                  return _vm.$set(_vm.queryForm, "pageNumber", $event)
                },
                "update:pageSize": function ($event) {
                  return _vm.$set(_vm.queryForm, "pageSize", $event)
                },
                "update:page-size": function ($event) {
                  return _vm.$set(_vm.queryForm, "pageSize", $event)
                },
                "current-change": _vm.getCurrentTableData,
                "size-change": _vm.getSizeTableData,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }