<script >
import { ref, defineProps } from '@vue/composition-api';
import { default as FormCompany } from './steps/formCompany';
import { default as FormAddress } from '@/components/steps/formAddress';
import { useWizardForm } from '@/composable/useWizardForm';
import { useCreateCompany } from '@/api/transfer/company/query';
const __sfc_main = {};
__sfc_main.props = {
  closeModal: {
    type: Function
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const STEPS_TOTAL = 2;
  const formData = ref({
    formStep01: {
      name: null,
      tradeName: null,
      documentation: null,
      stateRegistration: null,
      comercialPhone: null,
      contact: null,
      cellphone: null,
      email: null,
      contractType: 0
    },
    formStep02: {
      zipcode: null,
      street: null,
      number: null,
      neighborhood: null,
      municipality: null,
      state: null,
      complement: null
    }
  });
  const {
    mutate,
    isLoading
  } = useCreateCompany();
  function handleSubmit() {
    const newBase = {
      ...formData.value.formStep01,
      ...formData.value.formStep02
    };
    mutate(newBase, {
      onSuccess() {
        props.closeModal();
      }
    });
  }
  const {
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  } = useWizardForm(formData, handleSubmit, STEPS_TOTAL);
  return {
    formData,
    isLoading,
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  };
};
__sfc_main.components = Object.assign({
  FormCompany,
  FormAddress
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <FormCompany
      v-show="step === 0"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      :form-data.sync="formData.formStep01"
    />

    <FormAddress
      v-show="step === 1"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      :form-data="formData.formStep02"
    />

    <o-loading :full-page="true" :active.sync="isLoading" />
  </div>
</template>
