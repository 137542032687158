<template>
  <div class="postalCodeCorrectionInfo">
    <div class="addFormBox">
      <el-form
        ref="queryForm"
        class="queryFormClass"
        :model="formData"
        :rules="rules"
        :label-width="$i18n.locale=='zh'?'120px':''"
        :label-position="$i18n.locale!='zh'?'top':'right'"
      >
        <el-row>
          <el-col :span="20">
            <!--错误邮编-->
            <el-form-item :label="$t('basicData.IncorrectPostalCode')" prop="errorPostalCode">
              <el-input
                v-model.trim="formData.errorPostalCode"
                :placeholder="$t('orderDetails.pleaseInput')"
                clearable
                maxlength="8"
                show-word-limit
                id="errorPostalCode"
              />
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <!--正确邮编-->
            <el-form-item :label="$t('basicData.CorrectPostalCode')" prop="correctPostalCode">
              <el-input
                v-model.trim="formData.correctPostalCode"
                :placeholder="$t('orderDetails.pleaseInput')"
                clearable
                maxlength="8"
                show-word-limit
                id="correctPostalCode"
              />
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <!--状态-->
            <el-form-item :label="$t('basicData.status') + '：'" prop="state">
              <el-radio-group v-model="formData.state" size="small">
                <el-radio-button :label="1">{{ $t('basicData.disable') }}</el-radio-button>
                <el-radio-button :label="0">{{ $t('basicData.enable') }}</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- v-permit:remove="'but:finance:basicData:partition:edit'" -->
    <div
        class="addFooter"
      >
        <!--取消-->
        <el-button @click="() => handleUpdatePage(false)">{{ $t('basicData.Cancel') }}</el-button>
        <!--保存-->
        <el-button type="primary" @click="submitForm">{{ $t('basicData.Save') }}</el-button>
      </div>
  </div>
</template>

<script>
import { apiPostalCodeCorrectionUpdate } from '@/api/finance/postalCodeCorrection';

export default {
  props: {
    // 类型标识 1新增 2编辑
    typeIndex: {
      type: Number,
      default: 1
    },
    editData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    // var validate = (rule, value, callback) => {
    //   if (!Number.isInteger(value)) {
    //     callback(new Error(this.$i18n.t('basicData.MustBeAPositiveInteger')))
    //   } else if (value < 0) {
    //     callback(new Error(this.$i18n.t('basicData.MustBeGreaterThan0')))
    //   } else if (value.toString().length < 2 || value.toString().length > 8) {
    //     callback(new Error(this.$i18n.t('basicData.TheLengthBetween2And8Characters')))
    //   } else {
    //     callback()
    //   }
    // };
    var validate = (rule, value, callback) => {  
      const regex = /^\d*$/; // 只允许数字  
      if (!regex.test(value)) {  
        callback(new Error(this.$i18n.t('basicData.MustBeAPositiveInteger')))  
      } else if (value.toString().length < 2 || value.toString().length > 8) {
        callback(new Error(this.$i18n.t('basicData.TheLengthBetween2And8Characters')))
      } else {  
        callback() 
      }  
    }  
    return {
      formData: {
        errorPostalCode: '',
        correctPostalCode: '',
        state: 0
      },
      rules: {
        errorPostalCode: [
          { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] },
          { validator: validate, trigger: ['blur', 'change'] }
        ],
        correctPostalCode: [
          { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] },
          { validator: validate, trigger: ['blur', 'change'] }
        ],
        state: { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] },
      }
    };
  },

  created() {
    if (this.typeIndex === 2) {
      this.formData = {
        ...this.editData
      }
    }
  },

  methods: {
    /**
     * 取消操作
     * @param type 
     */
    handleUpdatePage(type) {
      this.$emit('updatePage', type);
    },

    /**
     * 新增单据/更新
     * @param obj 
     */
    asyncPostalCodeCorrectionUpdate() {
      apiPostalCodeCorrectionUpdate(this.formData).then(res => {
        const { status } = res;
        if (status !== 'OK') {
          return;
        }
        this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功

        this.handleUpdatePage(true);
      }).catch(err => {
        console.error(err);
      });
    },

    /**
     * 提交保存
     */
    submitForm() {
      this.$refs.queryForm.validate((valid) => {
        if (!valid) {
          return false;
        }

        this.asyncPostalCodeCorrectionUpdate();
      });
    }

  }
};
</script>

<style scope>
.postalCodeCorrectionInfo {
  height: 100%;
}

.addFormBox {
  height: calc(100% - 61px);
  padding: 15px;
  overflow-y: auto;
}

.addFooter {
  border-top: 1px solid #ccc;
  padding: 10px;
  text-align: right;
}
</style>
