<template>
  <div>
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleSMS">
      <!--短信配置-->
      <el-tab-pane :label="$t('Resource.SMSConfiguration')" name="smsConfig">
        <sms-config ref="smsConfig" />
      </el-tab-pane>
      <!--模板配置-->
      <el-tab-pane :label="$t('Resource.Templateconfiguration')" name="template">
        <sms-template ref="templateConfig" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: 'SMSConfiguration',
  components: {
    'sms-config': () => import('./cmp/smsConfig'),
    'sms-template': () => import('./cmp/smsTemplate')
  },
  data() {
    return {
      activeName: 'smsConfig'
    };
  },
  methods: {
    handleSMS(tab, event) {
      if (tab.name === 'template') {
        this.$refs.templateConfig.updateTable();
      } else {
        this.$refs.smsConfig.updateTable();
      }
    }
  }
};
</script>

<style scoped>

</style>
