<template>
  <div class="main-box">
    <today-total-operation-data @orderTypeChange="orderTypeChange" />
    <distribution-operation-data :order-type="orderType" />

    <el-row :gutter="20" class="mt-20">
      <el-col :span="14">
        <el-card class="box-card">
          <customer-order-status :total-list="totalList">
            <el-select
              v-model="customerIds"
              :placeholder="$t('operationCenter.PleaseSelect')"
              filterable
              multiple
              style="width: 100%;"
              size="mini"
              @change="getCustomerData"
            >
              <el-option
                v-for="(item, ind) in customerList"
                :key="ind"
                :label="item.customerAlias + '(' + item.username + ')'"
                :value="item.id"
              />
            </el-select>
          </customer-order-status>
          <lineChart :chart-data="chartData" :date-data="dateData" />
        </el-card>
      </el-col>
      <el-col :span="10">
        <questionItemOperationData :order-type="orderType" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { waybillDashboardCustomerWaybillInfo } from '@/api/orderApi';
import { apiCustomerPage } from '@/api/customer';
export default {
  components: {
    CustomerOrderStatus: () => import('./component/customerOrderStatus.vue'),
    lineChart: () => import('./component/lineChart.vue'),
    todayTotalOperationData: () => import('./component/todayTotalOperationData.vue'),
    distributionOperationData: () => import('./component/distributionOperationData.vue'),
    questionItemOperationData: () => import('./component/questionItemOperationData.vue')
  },
  data() {
    return {
      date: new Date(),
      totalList: [], // 客户下单情况
      chartData: [], // 近七天客户订单走势图表数据
      dateData: [], // 近七天客户订单走势图表日期
      orderType: 1, // 1：正式 | 2：测试
      customerIds: [], // 选中的客户列表
      customerList: [] // 客户列表
    };
  },
  created() {
    this.getCustomerData();
    this.getCustomerPage();
  },
  methods: {
    /**
     * @description 获取客户下单情况（包括图表）
     */
    getCustomerData() {
      waybillDashboardCustomerWaybillInfo({ customerIds: this.customerIds })
        .then((res) => {
          if (res.status === 'OK') {
            this.totalList = res.data.totalList;
            this.chartData = res.data.chartData;
            this.dateData = res.data.dateData;
          }
        });
    },
    /**
     * @description 正式测试环境切换
     * @param type
     */
    orderTypeChange(type) {
      console.log(type);
      this.orderType = type;
    },
    /**
     * @description 获取客户下单情况
     * @param customerIds
     */
    getCustomerDataVal(customerIds) {
      this.customerIds = customerIds;
      this.getCustomerData();
    },
    /**
     * @description 分页获取客户列表
     * @param { string } name 客户名称
     */
    getCustomerPage(name = '') {
      const param = {
        pageSize: 9999,
        pageNumber: 1,
        params: {
          quickSearch: name
        }
      };
      apiCustomerPage(param).then((res) => {
        if (res.status === 'OK') {
          this.customerList = res.data.records;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.main-box{
  margin: 10px;
}
.mt-20 {
  margin-top: 20px;
}

.box-card {
  .el-card__header {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
  }
}

.clearfix{
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #1C1C1C;
  line-height: 22px;
  margin-bottom: 20px;
}

.metric-value, .segment-value, .order-value, .loss-value {
  font-size: 24px;
  font-weight: bold;
  margin: 5px 0;
  color: #303133;
}

.metric-change{
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    margin-right: 2px;
  }
}

.up {
  color: #67C23A;
}

.down {
  color: #F56C6C;
}

.highlight {
  background-color: #FEF0F0;
}

.segment-subvalue {
  font-size: 12px;
  color: #909399;
}
</style>
