<template>
  <div>
    <el-table
      :data="tableData"
      border
    >
      <!--      <el-table-column label="IP" prop="ip" align="center" :show-overflow-tooltip="true">-->
      <!--        <template slot-scope="scope">-->
      <!--          <div>{{ scope.row.ip }}</div>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <!--操作字段-->
      <el-table-column
        :label="$t('receivablePayable.ActionFields')" prop="updateField" align="center"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.updateField }}</div>
        </template>
      </el-table-column>
      <!--操作前-->
      <el-table-column
        :label="$t('receivablePayable.BeforeOperation')" prop="oldValue" align="center"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.oldValue }}</div>
        </template>
      </el-table-column>
      <!--操作后-->
      <el-table-column
        :label="$t('receivablePayable.AfterTheOperation')" prop="newValue" align="center"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.newValue }}</div>
        </template>
      </el-table-column>
      <!--操作人-->
      <el-table-column
        :label="$t('basicData.Operator')" prop="createUser" align="center"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <div>{{ createUser[scope.row.createUser] }}</div>
        </template>
      </el-table-column>
      <!--操作时间-->
      <el-table-column
        :label="$t('basicData.OperationTime')" prop="createTime" align="center"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.createTime }}</div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getLogTable"
        @size-change="getLogTable"
      />
    </div>
  </div>
</template>

<script>
import { getUserIdAndNameObj } from '@/enums/storageEnum';
export default {
  name: 'OperationLog',
  props: {
    checkData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      queryForm: {
        orderDirection: 'DESC',
        orderProperty: '',
        pageNumber: 1,
        pageSize: 10,
        params: {
          logisticsNumber: '' // 追踪号（物流单号）
        }
      },
      total: 0,
      tableData: [],
      createUser: []
    };
  },
  created() {
    this.createUser = getUserIdAndNameObj();
    this.getLogTable();
  },
  methods: {
    // 获取操作日志
    getLogTable() {
      this.queryForm.params.logisticsNumber = this.checkData.logisticsNumber;
      // listByLogisticsNo(this.queryForm).then(res => {
      //   if (res.code === 200) {
      //     this.tableData = res.tableData;
      //     this.total = res.total;
      //   }
      // });
    }
  }
};
</script>

<style scoped>

</style>
