// @ts-check
import i18n from '@/lang';
// @ts-ignore
import ExcelJS from 'exceljs';
// @ts-ignore
import { saveAs } from 'file-saver';
import { BUSINESS_TYPE } from './../../utils';

// 下载模板 + 导入
export const { downloadTemplate, parseTemplate } = (() => {
  const MAX_LEN = 100000;

  const columns = {
    serviceNumber: {
      header: i18n.t('receivablePayable.TicketNumber'), // 业务单号
      headerCn: '业务单号',
      headerPu: 'número do negócio', // 业务单号
      key: 'serviceNumber',
      width: 40
    },
    serviceType: {
      header: i18n.t('collectionCenter.businessT'), // 业务类型
      headerCn: '业务类型',
      headerPu: 'Tipo de negócio', // 业务单号
      key: 'serviceType',
      width: 40
    }
  };

  // 下载模板
  const downloadTemplate = async() => {
    const wb = new ExcelJS.Workbook();
    const Sheet1 = wb.addWorksheet('Sheet1');
    const Sheet2 = wb.addWorksheet('Sheet2');

    Sheet1.getCell('A1').value = columns.serviceNumber.headerCn;
    Sheet1.getCell('A2').value = columns.serviceNumber.headerPu;
    Sheet1.getCell('B1').value = columns.serviceType.headerCn;
    Sheet1.getCell('B2').value = columns.serviceType.headerPu;
    Sheet1.getColumn('A').width = 40;
    Sheet1.getColumn('B').width = 40;
    Sheet1.mergeCells('A3:B3');
    // 每次仅导入5w条  // 不可更改此模板;
    Sheet1.getCell('A3').value = `
      1、每次仅导入${MAX_LEN}条 /Importar apenas ${MAX_LEN} itens de cada vez
      2、全部必填 /Tudo é obrigatório
      3、不可更改此模板 /Esse modelo não pode ser alterado
    `;
    Sheet1.getRow(3).height = 60;
    // Sheet1.getRow(3).getCell(1).font = {
    //   color: { argb: 'ff0000' }
    // };
    Sheet1.getRow(3).getCell(1).style = {
      font: {
        color: { argb: 'ff0000' }
      },
      alignment: {
        vertical: 'middle', // 垂直居中
        horizontal: 'left', // 水平左对齐
        wrapText: true // 自动换行
      }
    };

    Sheet2.columns = [
      // @ts-ignore
      columns.serviceType
    ];
    const Sheet2_data = Object.values(BUSINESS_TYPE).map(item => {
      return {
        [columns.serviceType.key]: `${item.label}-${item.value}`
      };
    });
    Sheet2.addRows(Sheet2_data);

    new Array(MAX_LEN).fill(0).forEach((_, index) => {
      const row = index + 3;

      // 业务类型
      Sheet1.getCell(row, 2).dataValidation = {
        type: 'list',
        formulae: [`=Sheet2!$A$2:$A${Sheet2_data.length + 1}`]
      };
    });

    const buffer = await wb.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/octet-stream'
    });
    const saveName = i18n.t('receivablePayable.按单号导出') + '.xlsx';
    saveAs(blob, saveName);
  };

  /**
   * 解析excel
   * @param {any[]} arr 导入的数据
   * @returns {any[]}
   */
  const parseTemplate = (arr) => {
    return arr.map(item => {
      const obj = {
        [columns.serviceNumber.key]: '',
        [columns.serviceType.key]: ''
      };

      // 业务单号
      if (item[columns.serviceNumber.headerCn] !== undefined) {
        obj[columns.serviceNumber.key] = item[columns.serviceNumber.headerCn];
      }

      // 业务类型
      if (item[columns.serviceType.headerCn] !== undefined) {
        const row = item[columns.serviceType.headerCn];
        const val = row.split('-')[1];
        if (val !== undefined) {
          obj[columns.serviceType.key] = val;
        }
      }

      return obj;
    });
  };

  return {
    downloadTemplate,
    parseTemplate
  };
})();

