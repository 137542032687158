<script >
import { defineProps } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { FormActions, FormActionsNext, FormActionsPrevious } from '@/components/wizard-form/formStepActions';
import { required, numeric } from '@/utils/validationErrors';
const __sfc_main = {};
__sfc_main.props = {
  handleNext: {
    type: Function
  },
  isLastStep: {
    type: Boolean,
    required: true
  },
  isFirstStep: {
    type: Boolean,
    required: true
  },
  handlePrevious: {
    type: Function
  },
  numberEdition: {
    type: Number
  },
  formData: {
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  function validateRequiredEdition(value) {
    return props.numberEdition === 1 ? !!value : true;
  }
  const rules = {
    codEdition: {
      requiredEdition: helpers.withMessage('Obrigatório', validateRequiredEdition)
    },
    volume: {
      requiredEdition: helpers.withMessage('Obrigatório', validateRequiredEdition),
      numeric
    },
    description: {
      requiredEdition: helpers.withMessage('Obrigatório', validateRequiredEdition)
    }
  };
  const v$ = useVuelidate(rules, props.formData);
  async function handleSubmit() {
    const isValid = await v$.value.$validate();
    if (isValid) props.handleNext();
  }
  return {
    v$,
    handleSubmit
  };
};
__sfc_main.components = Object.assign({
  FormActions,
  FormActionsPrevious,
  FormActionsNext
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <form class="wrapper-form" @submit.prevent="handleSubmit">
    <fieldset class="wapper-fieldset col-span-2">
      <legend class="legend">{{ numberEdition }}° {{ $t('editingRegistration.edition') }}</legend>

      <o-field
        :label="$t('editingRegistration.editionCode')"
        label-for="codEdition"
        :message="v$.codEdition.$errors.length ? v$.codEdition.$errors[0].$message : null"
      >
        <o-input id="codEdition" v-model="formData.codEdition" />
      </o-field>

      <o-field
        :label="$t('editingRegistration.volume')"
        label-for="volume"
        :message="v$.volume.$errors.length ? v$.volume.$errors[0].$message : null"
      >
        <o-input id="volume" v-model="formData.volume" />
      </o-field>

      <o-field
        class="col-span-2"
        :label="$t('editingRegistration.description')"
        label-for="description"
        :message="v$.description.$errors.length ? v$.description.$errors[0].$message : null"
      >
        <o-input id="description" v-model="formData.description" />
      </o-field>
    </fieldset>

    <FormActions>
      <FormActionsPrevious :is-first-step="isFirstStep" @handlePrevious="handlePrevious" />

      <FormActionsNext :is-last-step="isLastStep" />
    </FormActions>
  </form>
</template>

<style scoped>
.wrapper-form {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem /* 16px */;
}

.legend {
  font-weight: 700;
  margin-bottom: 0.75rem; /* 12px */
  padding-top: 0.5rem; /* 8px */
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.wapper-fieldset {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem /* 16px */;
}
</style>
