<template> <!-- 收件人信息 -->

  <el-form
    ref="ruleForm"
    class="demo-ruleForm" label-position="top"
  >
    <el-row :gutter="20">
      <el-col :span="8">
        <!-- 姓名 -->
        <el-form-item :label="$t('newOrder.RecipientName')">
          <span>{{ ruleForm.receiveName }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 公司 -->
        <el-form-item :label="$t('newOrder.RecipientCompany')">

          <span>{{ ruleForm.receiveCompany }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 手机 -->
        <el-form-item :label="$t('newOrder.Recipientphone')">
          <span>{{ ruleForm.receiveMobile }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 街道 -->
        <el-form-item :label="$t('driver.modalStreet')">
          <span>{{ ruleForm.receiveStreet }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 门牌号 -->
        <el-form-item :label="$t('newOrder.doorNumber')">
          <span>{{ ruleForm.receiveHouseNumber }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 区 -->
        <el-form-item :label="$t('newOrder.RecipientDistrict')">
          <span>{{ ruleForm.receiveArea }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 城市 -->
        <el-form-item :label="$t('newOrder.RecipientCity')">
          <span>{{ ruleForm.receiveCity }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 州 -->
        <el-form-item :label="$t('newOrder.RecipientState')">
          <span>{{ ruleForm.receiveState }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 邮编 -->
        <el-form-item :label="$t('newOrder.RecipientZipCode')">
          <span>{{ ruleForm.receiveZipcode }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 地址补充 -->
        <el-form-item :label="$t('GlobalSetObj.recipientComplement')">
          <span>{{ ruleForm.receiveComplement }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 税号 -->
        <el-form-item :label="$t('newOrder.RecipientCPF')">
          <span>{{ ruleForm.receiveTax }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 州注册号 -->
        <el-form-item :label="$t('navbar.receiveIe')">
          <span>{{ ruleForm.receiveIeNumber }}</span>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <!-- 地址 -->
        <el-form-item :label="$t('newOrder.ReceivingAddress')">
          <span>{{ ruleForm.receiveAddress }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 电话 -->
        <el-form-item :label="$t('newOrder.Recipientphonenumber')">
          <span>{{ ruleForm.receivePhone }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 邮箱 -->
        <el-form-item :label="$t('newOrder.RecipientEmail')">
          <span>{{ ruleForm.receiveMail }}</span>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  name: '',

  props: {
    ruleForm: {
      type: Object,
      required: true
    }
  }

};
</script>

<style scoped>
span {
  color: #666;
}
</style>
