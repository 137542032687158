<!--
 * @Author: nayg
 * @Date: 2022-05-27 14:12:31
 * @LastEditTime: 2022-05-31 15:39:04
 * @LastEditors: nayg
 * @Description:
 * @FilePath: \admin-oa-web\src\views\user\organizationManage\organizationChart.vue
-->
<template>
  <div>
    <el-select
      v-model="value" :placeholder="$t('organizationManage.pleaseChoose')" size="small"
      @change="getChartData"
    >
      <el-option
        v-for="item in selectList" :key="item.id" :label="item.name"
        :value="item.id"
      />
    </el-select>
    <!-- 下载图片（.png) -->
    <el-button
      icon="el-icon-download" size="small" style="margin-left: 20px"
      @click="onExport"
    >
      {{ $t('organizationManage.DownloadPictures') }}
    </el-button>
    <div ref="html2canvas" :class="[scroll ? 'container-active' : 'container']">
      <tree-chart ref="widthHeight" :data="chartData" />
    </div>
  </div>
</template>

<script>
import { Loading } from 'element-ui';
import html2canvas from 'html2canvas';
import { getOrganizationTreeObj } from '@/enums/storageEnum';
import { getUserPage } from '@/api/user';

export default {
  components: {
    'tree-chart': () => import('./components/treeChart')
  },
  data() {
    return {
      value: 15, // 公司选中值
      selectList: [], // 组织结构下拉框
      data: [], // 组织结构数据
      chartData: [], // 选中的组织结构图
      scroll: false, // 动态修改滚动条显示，截图下载图片需要
      // 请求参数
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        orderProperty: 'id',
        orderDirection: 'DESC',
        params: {
          accountState: '1',
          phone: '',
          username: '',
          orgCodeLikeRight: '',
          realName: '',
          roleId: ''
        }
      }
    };
  },
  created() {
    // 获取组织结构数据
    this.data = getOrganizationTreeObj();
    // 获取公司下拉列表
    this.data.forEach((item) => {
      item.children.forEach((items) => {
        this.selectList.push(items);
      });
    });
    // 默认选中值
    this.$nextTick(() => {
      this.value = this.selectList[0].id;
      this.chartData = this.selectList[0];
      // 先获取本地存储的数据，判断本地存储是否存储有数据
      // 没有数据请求并且存储，有数据跳过请求
      let organizationChart = localStorage.getItem('organizationChart');
      // 有存储数据
      if (organizationChart) {
        organizationChart = JSON.parse(organizationChart);
        // 判断有没有存储这个公司的数据
        organizationChart.forEach((item, index) => {
          if (item.id === this.value) {
            this.chartData = item;
            return;
          }
        });
        // 没有获取到当前公司的数据，重新获取并且存储
        if (!Object.keys(this.chartData)) {
          // 获取到所有请求结束调用
          Promise.all(this.getChartNumber()).then((res) => {
            localStorage.setItem('organizationChart', JSON.stringify([...organizationChart, this.chartData]));
          });
        }
      } else { // 没有存储数据，直接获取，获取完成后存储数据
        Promise.all(this.getChartNumber()).then((res) => {
          localStorage.setItem('organizationChart', JSON.stringify([this.chartData]));
        });
      }
    });
  },
  methods: {
    /**
     * @description: 导出组织结构图片
     */
    onExport() {
      // 不显示滚动条截图开始
      const options = {
        lock: true,
        // '生成图片中，等待下载'
        text: this.$t('organizationManage.GenerateImageWaitDownload'), // 加载动画的文字
        spinner: 'el-icon-loading', // 加载动画的图标
        background: '#ffffff' // 加载动画的背景
      };
      Loading.service(options);
      this.scroll = true;
      this.$nextTick(() => {
        html2canvas(this.$refs.html2canvas, {
          width: this.$refs.widthHeight.$el.offsetWidth,
          height: this.$refs.widthHeight.$el.offsetHeigh,
          backgroundColor: '#ffffff',
          scale: 1.5,
          useCORS: true // //支持图片跨域
        }).then((canvas) => {
          console.log(111);
          const url = canvas.toDataURL('image/png'); // 生成的图片
          // 可以上传后端或者直接显示
          // this.imgUrl = url
          console.log(url);
          const aLink = document.createElement('a');
          aLink.style.display = 'none';
          aLink.href = url;
          // '组织架构图.png'
          aLink.download = this.$t('organizationManage.OrganizationChartPNG');
          // 触发点击-然后移除
          document.body.appendChild(aLink);
          aLink.click();
          document.body.removeChild(aLink);
          // 截图结束显示滚动条
          this.scroll = false;
          const loadingInstance = Loading.service(options);
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close();
          });
        });
      });
    },
    /**
     * @description:
     * @param { String } code 组织代码
     * @return { Promise }
     */
    getUserNumber(code) {
      // 返回一个Promise回调
      return new Promise((resolve, reject) => {
        this.queryForm.params.orgCodeLikeRight = code;
        getUserPage(this.queryForm, false)
          .then((res) => {
            resolve(res.data.total);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * @description: 获取架构图人数赋值
     */
    getChartNumber() {
      // 请求数组（获取所有请求调用完成返回）
      const ajaxList = [];
      // 获取公司总人数
      const onePromise = new Promise(async(resolve, reject) => {
        await this.getUserNumber(this.chartData.orgCode).then((res) => {
          // console.log(res,'人数：number')
          this.$set(this.chartData, 'number', res);
        });
        resolve();
      });
      ajaxList.push(onePromise);
      // 获取公司二级部门人数
      this.chartData.children.forEach((item, index) => {
        const twoPromise = new Promise(async(resolve, reject) => {
          await this.getUserNumber(item.orgCode).then((res) => {
            this.$set(this.chartData.children[index], 'number', res);
          });
          resolve();
        });
        ajaxList.push(twoPromise);
        // 获取公司三级部门人数
        item.children.forEach((items, indexs) => {
          const threePromise = new Promise(async(resolve, reject) => {
            await this.getUserNumber(items.orgCode).then((res) => {
              this.$set(this.chartData.children[index].children[indexs], 'number', res);
            });
            resolve();
          });
          ajaxList.push(threePromise);
        });
      });
      return ajaxList;
    },
    /**
     * @description: 下拉框选择公司重新赋值
     */
    getChartData() {
      // 判断是否获取到数据
      let getData = false;
      // 获取当前的组织结构
      this.chartData = this.selectList.find((item) => {
        if (item.id === this.value) {
          return item;
        }
      });
      // 获取当前组织结构人数
      const organizationChart = JSON.parse(localStorage.getItem('organizationChart'));
      // 判断有没有存储这个公司的数据
      organizationChart.forEach((item, index) => {
        if (item.id == this.value) {
          this.chartData = item;
          getData = true;
          return;
        }
      });
      // 没有获取到当前公司的数据，重新获取并且存储
      if (!getData) {
        // 获取到所有请求结束调用
        Promise.all(this.getChartNumber()).then((res) => {
          localStorage.setItem('organizationChart', JSON.stringify([...organizationChart, this.chartData]));
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  overflow-x: auto;
}

.container-active {
  overflow-x: none;
}
</style>
