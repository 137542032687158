var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "warp",
      staticStyle: { padding: "0 20px", "margin-top": "20px" },
    },
    [
      _c(
        "el-descriptions",
        {
          staticClass: "margin-top",
          attrs: { column: 4, direction: "vertical" },
        },
        [
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$t("collectionCenter.loadingPicture") } },
            [
              _vm.loadPictureList.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "fle-left",
                      on: {
                        click: function ($event) {
                          _vm.seeImage(
                            _vm.loadPictureList,
                            _vm.$t("collectionCenter.loadingPicture")
                          )
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.$t("GlobalSetObj.see")))]),
                      _c("svg-icon", {
                        staticStyle: { color: "#409EFF" },
                        attrs: { "icon-class": "iconEye" },
                      }),
                    ],
                    1
                  )
                : _c("div", { staticClass: "fle-left" }, [
                    _c("span", [_vm._v(_vm._s("--"))]),
                  ]),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$t("navbar.loadSign") } },
            [
              _vm.loadSignList.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "fle-left",
                      on: {
                        click: function ($event) {
                          _vm.seeImage(
                            _vm.loadSignList,
                            _vm.$t("navbar.loadSign")
                          )
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.$t("GlobalSetObj.see")))]),
                      _c("svg-icon", {
                        staticStyle: { color: "#409EFF" },
                        attrs: { "icon-class": "iconEye" },
                      }),
                    ],
                    1
                  )
                : _c("div", { staticClass: "fle-left" }, [
                    _c("span", [_vm._v(_vm._s("--"))]),
                  ]),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$t("navbar.unLoadSign") } },
            [
              _vm.unLoadSignList.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "fle-left",
                      on: {
                        click: function ($event) {
                          _vm.seeImage(
                            _vm.unLoadSignList,
                            _vm.$t("navbar.unLoadSign")
                          )
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.$t("GlobalSetObj.see")))]),
                      _c("svg-icon", {
                        staticStyle: { color: "#409EFF" },
                        attrs: { "icon-class": "iconEye" },
                      }),
                    ],
                    1
                  )
                : _c("div", { staticClass: "fle-left" }, [
                    _c("span", [_vm._v(_vm._s("--"))]),
                  ]),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$t("collectionCenter.sealVehiclePicture") } },
            [
              _vm.sealVehiclePictureList.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "fle-left",
                      on: {
                        click: function ($event) {
                          _vm.seeImage(
                            _vm.sealVehiclePictureList,
                            _vm.$t("collectionCenter.sealVehiclePicture")
                          )
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.$t("GlobalSetObj.see")))]),
                      _c("svg-icon", {
                        staticStyle: { color: "#409EFF" },
                        attrs: { "icon-class": "iconEye" },
                      }),
                    ],
                    1
                  )
                : _c("div", { staticClass: "fle-left" }, [
                    _c("span", [_vm._v(_vm._s("--"))]),
                  ]),
            ]
          ),
          _c(
            "el-descriptions-item",
            {
              attrs: { label: _vm.$t("collectionCenter.unsealVehiclePicture") },
            },
            [
              _vm.unsealVehiclePictureList.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "fle-left",
                      on: {
                        click: function ($event) {
                          _vm.seeImage(
                            _vm.unsealVehiclePictureList,
                            _vm.$t("collectionCenter.unsealVehiclePicture")
                          )
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.$t("GlobalSetObj.see")))]),
                      _c("svg-icon", {
                        staticStyle: { color: "#409EFF" },
                        attrs: { "icon-class": "iconEye" },
                      }),
                    ],
                    1
                  )
                : _c("div", { staticClass: "fle-left" }, [
                    _c("span", [_vm._v(_vm._s("--"))]),
                  ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "show-close": true,
            title: _vm.titleImage,
            visible: _vm.dialogVisible,
            top: "5vh",
            width: "550px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", [
            _vm.imageList.length > 0
              ? _c(
                  "div",
                  { staticStyle: { width: "500px" } },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "center",
                          color: "#409EFF",
                          "font-size": "18px",
                        },
                      },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.currentIndex + 1))]),
                        _c("span", [_vm._v("/")]),
                        _c("span", [_vm._v(_vm._s(_vm.imageList.length))]),
                      ]
                    ),
                    _c(
                      "el-carousel",
                      {
                        ref: "carousel",
                        attrs: { height: "750px", trigger: "click" },
                        on: { change: _vm.handleCurrentChange },
                      },
                      _vm._l(_vm.imageList, function (item) {
                        return _c("el-carousel-item", { key: item }, [
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "10px" } },
                            [
                              _c("el-image", {
                                staticStyle: { width: "100%" },
                                attrs: { src: item, fit: "fill" },
                              }),
                            ],
                            1
                          ),
                        ])
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  {
                    staticStyle: {
                      width: "500px",
                      "text-align": "center",
                      height: "750px",
                    },
                  },
                  [
                    _c("el-image", { staticStyle: { width: "100%" } }, [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error",
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-picture-outline",
                            staticStyle: { "font-size": "30px" },
                          }),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }