<template>
  <div>
    <el-form :key="keyInvoice" label-width="auto">
      <el-row :gutter="20">
        <el-descriptions :colon="false" :column="3" direction="vertical">
          <!--签收签名-->
          <el-descriptions-item :label="$t('GlobalSetObj.signedPhoto')">
            <div :style="`${$i18n.locale !== 'zh' ? 'width:50px' : 'width:60px'}`">
              <el-tooltip
                v-if="tableInfomation.signedNamePhoto"
                class="QuestionMark"
                placement="top"
              >
                <div slot="content">
                  <img
                    :src="tableInfomation.signedNamePhoto"
                    alt=""
                    class="image"
                    style="width: 300px;"
                  >
                </div>
                <div style="display: flex;align-items: center;justify-content: center;">
                  <span>{{ $t('GlobalSetObj.see') }}</span>
                  <svg-icon icon-class="iconEye" />
                </div>
              </el-tooltip>
              <span v-else>{{ "--" }}</span>
            </div>
          </el-descriptions-item>
          <!--签收照片-->
          <el-descriptions-item :label="$t('GlobalSetObj.signedNamePhoto')" :span="2">
            <div :style="`${$i18n.locale !== 'zh' ? 'width:50px' : 'width:60px'}`">
              <el-tooltip
                v-if="tableInfomation.signedPhoto"
                class="QuestionMark"
                placement="top"
              >
                <div slot="content">
                  <img
                    v-for="(item,index) in tableInfomation.signedPhoto.split(',')"
                    :key="index"
                    :src="item"
                    alt=""
                    class="image"
                    style="width: 300px;margin: 5px"
                  >
                </div>
                <div style="display: flex;align-items: center;justify-content: center;">
                  <span>{{ $t('GlobalSetObj.see') }}</span>
                  <svg-icon icon-class="iconEye" />
                </div>
              </el-tooltip>
              <span v-else>{{ "--" }}</span>
            </div>
          </el-descriptions-item>
          <!--签收人类型-->
          <el-descriptions-item :label="$t('GlobalSetObj.TypeRecipient')">
            <div>{{ tableInfomation.signTypeName }}</div>
          </el-descriptions-item>
          <!--签收人人名-->
          <el-descriptions-item :label="$t('GlobalSetObj.SignerName')">
            <div>{{ tableInfomation.deliverySigner || '--' }}</div>
          </el-descriptions-item>
          <!--签收人DOC-->
          <el-descriptions-item :label="$t('GlobalSetObj.RecipientDOC')">
            <div>{{ tableInfomation.receiverDocumentNumber || '--' }}</div>
          </el-descriptions-item>
          <!--签收时间-->
          <el-descriptions-item :label="$t('GlobalSetObj.SigningTime')" :span="3">
            <div>{{ tableInfomation.deliveredTime || '--' }}</div>
          </el-descriptions-item>
        </el-descriptions>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'SigneInfo',
  props: {
    tableInfomation: {
      type: Object,
      required: true,
      default: () => {
      }
    },
    queryTrackParam: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      keyInvoice: new Date().getTime()
    };
  }
};
</script>

<style scoped>
/deep/ .el-descriptions-item__label {
  font-size: 13px;
  color: #909399;
}

/deep/ .el-descriptions-item__cell {
  /*height: 35px !important;*/
  padding-bottom: 2px !important;
}

.QuestionMark {
  cursor: pointer;
  color: #409eff;
}

.el-descriptions-item__cell div {
  font-weight: bold;
  font-size: 15px;
}
</style>
