<template>
  <div>
    <el-tabs v-model="activeName" type="border-card" class="tabsClass">
      <!--基本信息-->
      <el-tab-pane :label="$t('collectionCenter.basicInformation')" name="1">
        <div class="informationCont">
          <el-form ref="queryForm" :model="queryForm" label-position="top">
            <el-row>
              <el-col :span="24">
                <!--省份-->
                <el-form-item
                  :label="$t('collectionCenter.provinceee')"
                  prop="provincesId"
                  :rules="{ required: true, message: $t('GlobalSetObj.pleaseChoose'), trigger: ['blur', 'change'] }"
                >
                  <el-select
                    v-model="queryForm.provincesId"
                    :placeholder="$t('GlobalSetObj.pleaseChoose')"
                    clearable
                    filterable
                    style="width:100%"
                  >
                    <el-option
                      v-for="item in allTraceStateList"
                      :key="item.id"
                      :label="item.twoCode + (item.name ? '（' + item.name + '）' : '')"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <!--路线编号-->
                <el-form-item
                  :label="$t('GlobalSetObj.RouteNumber1')"
                  prop="routeNumber"
                  :rules="{ required: true, message: $t('GlobalSetObj.pleaseInput'), trigger: ['blur', 'change'] }"
                >
                  <el-input
                    v-model="queryForm.routeNumber"
                    style="width:100%"
                    show-word-limit
                    maxlength="20"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <!--始发地网点-->
                <el-form-item
                  :label="$t('GlobalSetObj.StartingStation1')"
                  prop="originSiteId"
                  :rules="{ required: true, message: $t('GlobalSetObj.pleaseChoose'), trigger: ['blur', 'change'] }"
                >
                  <el-select
                    v-model="queryForm.originSiteId"
                    :placeholder="$t('GlobalSetObj.pleaseChoose')"
                    clearable
                    filterable
                    style="width:100%"
                  >
                    <el-option
                      v-for="item in siteList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <!--目的地网点-->
                <el-form-item
                  :label="$t('GlobalSetObj.destinationPointSite')"
                  prop="destinationSiteId"
                  :rules="{ required: true, message: $t('GlobalSetObj.pleaseChoose'), trigger: ['blur', 'change'] }"
                >
                  <el-select
                    v-model="queryForm.destinationSiteId"
                    :placeholder="$t('GlobalSetObj.pleaseChoose')"
                    clearable
                    filterable
                    style="width:100%"
                  >
                    <el-option
                      v-for="item in siteList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <template v-if="addOrEdit === 2">
                <el-col :span="24">
                  <!--始发地网点地址-->
                  <el-form-item
                    :label="$t('GlobalSetObj.StartingStationBranchAddress')"
                    prop="originSiteAddress"
                    :rules="{ required: true, message: $t('GlobalSetObj.pleaseInput'), trigger: ['blur', 'change'] }"
                  >
                    <el-input
                      v-model="queryForm.originSiteAddress"
                      style="width:100%"
                      show-word-limit
                      maxlength="500"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <!--始发地网点经纬度-->
                  <el-form-item
                    :label="$t('GlobalSetObj.StartingStationBranchLatitudeAndLongitude')"
                    prop="originSiteLatLongitude"
                    :rules="{ required: true, message: $t('GlobalSetObj.pleaseInput'), trigger: ['blur', 'change'] }"
                  >
                    <el-input
                      v-model="queryForm.originSiteLatLongitude"
                      style="width:100%"
                      show-word-limit
                      maxlength="30"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <!--目的地网点地址-->
                  <el-form-item
                    :label="$t('GlobalSetObj.destinationPointBranchAddress')"
                    prop="destinationSiteAddress"
                    :rules="{ required: true, message: $t('GlobalSetObj.pleaseInput'), trigger: ['blur', 'change'] }"
                  >
                    <el-input
                      v-model="queryForm.destinationSiteAddress"
                      style="width:100%"
                      show-word-limit
                      maxlength="500"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <!--目的地网点经纬度-->
                  <el-form-item
                    :label="$t('GlobalSetObj.DestinationLatitudeAndLongitude')"
                    prop="destinationSiteLatLongitude"
                    :rules="{ required: true, message: $t('GlobalSetObj.pleaseInput'), trigger: ['blur', 'change'] }"
                  >
                    <el-input
                      v-model="queryForm.destinationSiteLatLongitude"
                      style="width:100%"
                      show-word-limit
                      maxlength="30"
                    />
                  </el-form-item>
                </el-col>
              </template>
              <el-col :span="12">
                <!--始发网点-->
                <el-form-item
                  :label="$t('GlobalSetObj.StartingStation')"
                  prop="originatingPoint"
                  :rules="{ required: true, message: $t('GlobalSetObj.pleaseChoose'), trigger: ['blur', 'change'] }"
                >
                  <el-radio-group v-model="queryForm.originatingPoint" size="mini">
                    <el-radio-button :label="0">{{ $t('GlobalSetObj.yes') }}</el-radio-button>
                    <el-radio-button :label="1">{{ $t('GlobalSetObj.No') }}</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <!--状态-->
                <el-form-item
                  :label="$t('GlobalSetObj.status')"
                  prop="state"
                  :rules="{ required: true, message: $t('GlobalSetObj.pleaseChoose'), trigger: ['blur', 'change'] }"
                >
                  <el-radio-group v-model="queryForm.state" size="mini">
                    <el-radio-button :label="1">{{ $t('GlobalSetObj.stop') }}</el-radio-button>
                    <el-radio-button :label="0">{{ $t('GlobalSetObj.enable') }}</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="informationFoot">
          <el-button
            type="info" size="small"
            plain @click="cancelSave">{{ $t('GlobalSetObj.cancel') }}</el-button>
          <el-button
            v-permit:remove="'btn:payableManagement:routeDistanceReference:edit'"
            type="primary" size="small" @click="saveAdd"
          >{{ $t('GlobalSetObj.confirm') }}</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash-es';
import { apiRouteDistanceReferenceAdd, apiRouteDistanceReferenceEdit } from '@/api/finance/receivables';

export default {
  props: {
    allTraceStateList: {
      type: Array,
      default: function() {
        return [];
      }
    },
    siteList: {
      type: Array,
      default: function() {
        return [];
      }
    },
    addOrEdit: {
      type: Number,
      default: 1
    },
    detailData: {
      type: Object,
      default: function() {
        return {
          id: '',
          provincesId: '', // 州id
          provinces: '', // 州二字码
          routeNumber: '', // 路线编号
          originSiteId: '', // 起始地id
          destinationSiteId: '', // 目的地id
          originatingPoint: 1, // 始发网点 0是，1否
          state: 0, // 状态0启动，1禁用
          originSiteAddress: '', // 起始地地址
          originSiteLatLongitude: '', // 起始地经纬度
          destinationSiteAddress: '', // 目的地地址
          destinationSiteLatLongitude: '' // 目的地经纬度
        };
      }
    }
  },
  data() {
    return {
      activeName: '1',
      queryForm: {
        id: '',
        provincesId: '', // 州id
        provinces: '', // 州二字码
        routeNumber: '', // 路线编号
        originSiteId: '', // 起始地id
        destinationSiteId: '', // 目的地id
        originatingPoint: 1, // 始发网点 0是，1否
        state: 0, // 状态0启动，1禁用
        originSiteAddress: '', // 起始地地址
        originSiteLatLongitude: '', // 起始地经纬度
        destinationSiteAddress: '', // 目的地地址
        destinationSiteLatLongitude: '' // 目的地经纬度
      }
    };
  },
  watch: {
    'queryForm.provincesId'(val) {
      this.allTraceStateList.map(item => {
        if (item.id === val) {
          this.queryForm.provinces = item.twoCode;
        }
      });
    }
  },
  created() {
    const dataForm = cloneDeep(this.detailData);
    this.queryForm = {
      id: dataForm.id,
      provincesId: dataForm.provincesId, // 州id
      provinces: dataForm.provinces, // 州二字码
      routeNumber: dataForm.routeNumber, // 路线编号
      originSiteId: dataForm.originSiteId, // 起始地id
      destinationSiteId: dataForm.destinationSiteId, // 目的地id
      originatingPoint: dataForm.originatingPoint, // 始发网点 0是，1否
      state: dataForm.state, // 状态0启动，1禁用
      originSiteAddress: dataForm.originSiteAddress, // 起始地地址
      originSiteLatLongitude: dataForm.originSiteLatLongitude, // 起始地经纬度
      destinationSiteAddress: dataForm.destinationSiteAddress, // 目的地地址
      destinationSiteLatLongitude: dataForm.destinationSiteLatLongitude // 目的地经纬度
    };
    console.log(this.queryForm, '====this.queryForm');
  },
  methods: {
    // 取消
    cancelSave() {
      this.$emit('closeAdd', false);
    },
    // 保存
    saveAdd() {
      this.$refs.queryForm.validate(valid => {
        if (!valid) {
          return false;
        }
        if (this.addOrEdit === 1) {
          apiRouteDistanceReferenceAdd(this.queryForm).then(res => {
            if (res.status === 'OK') {
              this.$message.success(this.$i18n.t('GlobalSetObj.TheOperationSucceeded')); // 操作成功
              this.$emit('closeAdd', true);
            }
          });
        } else {
          apiRouteDistanceReferenceEdit(this.queryForm).then(res => {
            if (res.status === 'OK') {
              this.$message.success(this.$i18n.t('GlobalSetObj.TheOperationSucceeded')); // 操作成功
              this.$emit('closeAdd', true);
            }
          });
        }
      });
    }
  }
};
</script>

<style>
.tabsClass .el-tabs__content {
  padding: 0;
}
.informationCont {
  height: calc(100vh - 94px);
  overflow-y: auto;
  padding: 20px;
}
.informationFoot {
  padding: 10px;
  border-top: 1px solid #ccc;
  text-align: right;
}
</style>
