<template>
  <div>
    <div v-show="showTips" style="padding: 10px;width: 60%;position:relative;">
      <!--请补充多件货物详细重量体积-->
      <el-alert
        :title="$t('placeOrder.PleaseProvideDetailedWeightAndVolume')"
        type="warning"
        :closable="false"
        style="border: 1px solid #E6A23C"
        show-icon
      />
      <span style="position:absolute; right: 20px; top: 20px; color: #E6A23C;">
        {{ showTime }}
      </span>
    </div>
    <el-form
      ref="supplementDetail"
      :model="supplementDetail"
    >
      <el-row style="padding: 10px 20px;">
        <el-col :span="8">
          <!--包裹总件数-->
          <el-form-item :label="$t('GlobalSetObj.TotalNumberOfPackages') + '：'">
            <span>{{ supplementDetail.totalNumber }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!--包裹总重量-->
          <el-form-item :label="$t('GlobalSetObj.pesoTotal1') + '：'">
            <span>{{ supplementDetail.totalWeight }}</span>kg
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!--包裹总体积-->
          <el-form-item :label="$t('GlobalSetObj.PackageTotalWeight') + '：'">
            <span>{{ supplementDetail.packageVolume }}</span>m³
          </el-form-item>
        </el-col>
      </el-row>
      <div class="divider"></div>
      <div style="max-height: 360px;overflow: hidden auto;padding: 10px 20px;">
        <el-card v-for="(item,index) in supplementDetail.detailList" class="box-card" style="margin-bottom: 10px;">
          <div style="font-size: 15px;font-weight: bold;">
            {{ $t('orderCenterCont.specification') }} {{ index + 1 }}
            <el-button
              v-if="supplementDetail.detailList.length > 1"
              type="text"
              icon="el-icon-delete"
              style="float: right;padding: 0;font-size: 20px;"
              @click="deleteDetail(index)">
            </el-button>
          </div>
          <div>
            <el-row :gutter="10">
              <el-col :span="8">
                <!--件数-->
                <el-form-item
                  :key="quantityKey"
                  :label="$t('collectionCenter.quantity')"
                  :prop="'detailList.' + index + '.quantity'"
                  :rules="totalNumberPieces"
                >
                  <el-input-number
                    v-model="item.quantity"
                    :placeholder="$t('placeOrder.pleaseInput')"
                    :min="1"
                    :max="999"
                    :precision="0"
                    :step="1"
                    style="width: 100%;"
                    @change="quantityChange()"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <!--单件重量-->
                <el-form-item
                  :label="$t('GlobalSetObj.SingleWeight')"
                  :prop="'detailList.' + index + '.unitWeight'"
                  :rules="pesoTotal"
                >
                  <el-input-number
                    v-model="item.unitWeight"
                    :placeholder="$t('placeOrder.pleaseInput')"
                    :min="0.001"
                    :max="9999"
                    :precision="3"
                    :step="1"
                    style="width: 100%;"
                    @change="unitWeightChange()"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <!--单件体积-->
                <el-form-item
                  :key="volumeTotalKey"
                  :label="$t('GlobalSetObj.SingleVolume')"
                  :prop="'detailList.' + index + '.unitVolume'"
                  :rules="parcelVolume"
                >
                  <el-input-number
                    v-model="item.unitVolume"
                    :placeholder="$t('placeOrder.pleaseInput')"
                    :min="0.001"
                    :max="999"
                    :precision="3"
                    :step="1"
                    style="width: 100%;"
                    @change="unitVolumeChange(index)"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <el-row :gutter="10">
            <el-col :span="8">
              <!--长-->
              <el-form-item
                :key="volumeKey"
                :label="$t('operatingPlatform.long') + '(m)'"
                :prop="'detailList.' + index + '.unitLength'"
                :rules="lengthWeightHeightCheck"
              >
                <el-input-number
                  v-model="item.unitLength"
                  :precision="3"
                  :min="0.001"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  style="width: 100%;"
                  @change="lengthWeightHeight(index)"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <!--宽-->
              <el-form-item
                :key="volumeKey"
                :label="$t('operatingPlatform.width') + '(m)'"
                :prop="'detailList.' + index + '.unitWidth'"
                :rules="lengthWeightHeightCheck"
              >
                <el-input-number
                  v-model="item.unitWidth"
                  :precision="3"
                  :min="0.001"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  style="width: 100%;"
                  @change="lengthWeightHeight(index)"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <!--高-->
              <el-form-item
                :key="volumeKey"
                :label="$t('operatingPlatform.height') + '(m)'"
                :prop="'detailList.' + index + '.unitHeight'"
                :rules="lengthWeightHeightCheck"
              >
                <el-input-number
                  v-model="item.unitHeight"
                  :precision="3"
                  :min="0.001"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  style="width: 100%;"
                  @change="lengthWeightHeight(index)"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </div>
    </el-form>
    <!--添加其他规格-->
    <div
      v-if="supplementDetail.detailList.length < 999"
      class="addBtn"
    >
      <span @click="addDetail">
        <i class="el-icon-plus"></i>
        {{ $t('GlobalSetObj.AddOtherSpecifications') }}
      </span>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash-es';

export default {
  props: {
    clickDetail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    // 数量验证
    const quantityVerification = (rule, value, callback) => {
      // rule.int 1: 正整数 2：整数或浮点数（正数）
      // rule.length: 小数点位数
      let regex = null;
      if (rule.length > 0) {
        const reg = '^[0-9]+(.?[0-9]{1,' + rule.length + '})?$';
        regex = new RegExp(reg);
      }
      if (rule.required) {
        if (value) {
          if (rule.int === 1) {
            if (!Number.isInteger(Number(value))) {
              callback(new Error(this.$t('collectionCenter.pleaseInputInteger')));
            }
          }
          if (value < rule.min) {
            callback(new Error(this.$t('placeOrder.数字不能小于') + rule.min));
          }
          if (value > rule.max) {
            callback(new Error(this.$t('placeOrder.数字不能大于') + rule.max));
          }
          if (regex && !regex.test(value)) {
            callback(new Error(this.$t('placeOrder.最多保留3位小数')));
          }
          callback();
        } else {
          callback(new Error(this.$t('placeOrder.pleaseInput')));
        }
      } else {
        if (value) {
          if (rule.int === 1) {
            if (!Number.isInteger(Number(value))) {
              callback(new Error(this.$t('collectionCenter.pleaseInputInteger')));
            }
          }
          if (value < rule.min) {
            callback(new Error(this.$t('placeOrder.数字不能小于') + rule.min));
          }
          if (value > rule.max) {
            callback(new Error(this.$t('placeOrder.数字不能大于') + rule.max));
          }
          if (regex && !regex.test(value)) {
            callback(new Error(this.$t('placeOrder.最多保留3位小数')));
          }
          callback();
        } else {
          callback();
        }
      }
    };
    return {
      supplementDetail: {
        totalNumber: 1,
        totalWeight: 0,
        packageVolume: 0,
        detailList: [
          {
            unitLength: undefined,
            unitWidth: undefined,
            unitHeight: undefined,
            unitWeight: undefined,
            unitVolume: undefined,
            quantity: 1
          }
        ]
      },
      volumeKey: new Date().getTime(),
      volumeTotalKey: new Date().getTime(),
      quantityKey: new Date().getTime(),
      // 总件数校验
      totalNumberPieces: [
        { required: true, validator: quantityVerification, int: 1, min: 1, max: 999, length: 0, trigger: ['blur'] }
        // { validator: quantityVerification, int: 1, min: 1, max: 999, length: 0, trigger: ['blur'] }
      ],
      // 包裹总重量校验
      pesoTotal: [
        { required: true, validator: quantityVerification, int: 2, min: 0.001, max: 9999, length: 3, trigger: ['blur'] }
        // { validator: quantityVerification, int: 2, min: 0.001, max: 9999, length: 3, trigger: ['blur'] }
      ],
      // 包裹总体积校验
      parcelVolume: [
        { required: true, validator: quantityVerification, int: 2, min: 0.001, max: 999, length: 3, trigger: ['blur'] }
        // { validator: quantityVerification, int: 2, min: 0.001, max: 999, length: 3, trigger: ['blur', 'change'] }
      ],
      // 包裹总体积校验
      lengthWeightHeightCheck: [
        { required: false, validator: quantityVerification, int: 2, min: 0.001, length: 3, trigger: ['blur'] }
        // { validator: quantityVerification, int: 2, min: 0.001, max: 999, length: 3, trigger: ['blur'] }
      ],
      showTips: false,
      showTime: 4
    };
  },
  created() {
    const detailData = cloneDeep(this.clickDetail);
    this.supplementDetail.orderWaybillList = detailData.orderWaybillList;
    this.supplementDetail.totalWeight = detailData.totalParcelDeclaredWeight ? detailData.totalParcelDeclaredWeight : 0;
    this.supplementDetail.packageVolume = detailData.totalParcelDeclaredVolume ? detailData.totalParcelDeclaredVolume : 0;
    this.supplementDetail.totalNumber = detailData.totalParcel ? Number(detailData.totalParcel) : 1;
    if (detailData.detailList && detailData.detailList.length > 0) {
      this.supplementDetail.detailList = detailData.detailList;
    } else {
      this.supplementDetail.detailList = [{
        unitLength: undefined,
        unitWidth: undefined,
        unitHeight: undefined,
        unitWeight: undefined,
        unitVolume: undefined,
        quantity: this.supplementDetail.totalNumber ? this.supplementDetail.totalNumber : 1
      }];
    }
  },
  methods: {
    // 件数变化
    quantityChange() {
      this.supplementDetail.totalWeight = 0;
      this.supplementDetail.packageVolume = 0;
      this.supplementDetail.totalNumber = 0;
      let weight = 0;
      let volume = 0;
      this.supplementDetail.detailList.map(item => {
        if (!item.quantity) {
          item.quantity = 1;
          this.quantityKey = new Date().getTime();
        }
        this.supplementDetail.totalNumber += item.quantity;
        if (item.quantity) {
          if (item.unitWeight) {
            weight += (Number(item.unitWeight) * Number(item.quantity));
            this.supplementDetail.totalWeight = weight.toFixed(3);
          }
          if (item.unitVolume) {
            volume += (Number(item.unitVolume) * Number(item.quantity));
            this.supplementDetail.packageVolume = volume.toFixed(3);
          }
        } else {
          if (item.unitWeight) {
            weight += Number(item.unitWeight);
            this.supplementDetail.totalWeight = weight.toFixed(3);
          }
          if (item.unitVolume) {
            volume += Number(item.unitVolume);
            this.supplementDetail.packageVolume = volume.toFixed(3);
          }
        }
      });
    },
    // 删除
    deleteDetail(index) {
      this.supplementDetail.detailList.splice(index, 1);
      this.quantityChange();
    },

    // 添加
    addDetail() {
      if (this.supplementDetail.totalNumber < 999) {
        this.supplementDetail.detailList.push({
          unitLength: undefined,
          unitWidth: undefined,
          unitHeight: undefined,
          unitWeight: undefined,
          unitVolume: undefined,
          quantity: 1
        });
        this.quantityChange();
      } else {
        this.$message({
          message: this.$t('placeOrder.TheTotalNumberCannotExceed999'),
          type: 'warning'
        });
      }
    },
    // 单件重量变化
    unitWeightChange() {
      this.quantityChange();
    },
    // 体积变化
    unitVolumeChange(index) {
      this.supplementDetail.detailList[index].unitLength = undefined;
      this.supplementDetail.detailList[index].unitWidth = undefined;
      this.supplementDetail.detailList[index].unitHeight = undefined;
      this.quantityChange();
    },
    isNumber(value) {
      return !isNaN(Number(value));
    },
    // 长宽高变化
    lengthWeightHeight(index) {
      if (
        this.supplementDetail.detailList[index].unitLength &&
        this.supplementDetail.detailList[index].unitWidth &&
        this.supplementDetail.detailList[index].unitHeight
      ) {
        if (
          this.isNumber(this.supplementDetail.detailList[index].unitLength) &&
          this.isNumber(this.supplementDetail.detailList[index].unitWidth) &&
          this.isNumber(this.supplementDetail.detailList[index].unitHeight)
        ) {
          const total = (this.supplementDetail.detailList[index].unitLength * this.supplementDetail.detailList[index].unitWidth * this.supplementDetail.detailList[index].unitHeight).toFixed(3);
          if (total > 999) {
            this.$message({
              message: this.$t('placeOrder.ThePackageVolumeCannotExceed999'),
              type: 'warning'
            });
            this.supplementDetail.detailList[index].unitLength = undefined;
            this.supplementDetail.detailList[index].unitWidth = undefined;
            this.supplementDetail.detailList[index].unitHeight = undefined;
            this.volumeKey = new Date().getTime();
          } else {
            if (total <= 0) {
              this.$message({
                message: this.$t('placeOrder.ThePackageVolumeCannotBeLessThanOrEqualTo0001'),
                type: 'warning'
              });
              this.supplementDetail.detailList[index].unitLength = undefined;
              this.supplementDetail.detailList[index].unitWidth = undefined;
              this.supplementDetail.detailList[index].unitHeight = undefined;
              this.volumeKey = new Date().getTime();
            } else {
              this.supplementDetail.detailList[index].unitVolume = total;
              this.volumeTotalKey = new Date().getTime();
              this.quantityChange();
            }
          }
        }
      }
    },
    // 保存
    save() {
      this.$refs.supplementDetail.validate(valid => {
        if (valid) {
          if (this.supplementDetail.totalWeight > 9999) {
            this.$message({
              message: this.$t('placeOrder.TheTotalWeightCannotExceed9999'),
              type: 'warning'
            });
            return;
          }
          if (this.supplementDetail.packageVolume > 999) {
            this.$message({
              message: this.$t('placeOrder.TheTotalVolumeCannotExceed999'),
              type: 'warning'
            });
            return;
          }
          this.$emit('save', this.supplementDetail);
        } else {
          this.showTips = true;
          this.showTime = 4;
          const timer = setInterval(() => {
            if (this.showTime > 0) {
              this.showTime -= 1;
            } else {
              this.showTips = false;
              clearInterval(timer);
            }
          }, 1000);
        }
      });
    }
  }
};
</script>

<style>
.divider {
  background-color: #ccc;
  width: 100%;
  height: 1px;
  margin: 10px 0 20px 0;
}
.addBtn {
  padding: 10px 0;
  color: #409EFF;
  font-size: 12px;
  text-align: center;
  cursor: pointer
}
</style>
