<script >
import { ref, defineProps } from '@vue/composition-api';
import { default as FormDataBank } from './step/formDataBank';
import { useCreateBank } from '@/api/transfer/bank/query';
const __sfc_main = {};
__sfc_main.props = {
  closeModal: {
    type: Function
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const formData = ref({
    companyId: null,
    companyName: null,
    bank: null,
    agency: null,
    account: null,
    beneficiary: null,
    financialContact: null,
    financialEmail: null,
    financialPhone: null
  });
  const {
    mutate,
    isLoading
  } = useCreateBank();
  async function handleSubmit() {
    mutate(formData.value, {
      onSuccess() {
        props.closeModal();
      }
    });
  }
  return {
    formData,
    isLoading,
    handleSubmit
  };
};
__sfc_main.components = Object.assign({
  FormDataBank
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <FormDataBank
      :handle-next="handleSubmit"
      :is-last-step="true"
      :is-first-step="true"
      :form-data="formData"
    />

    <o-loading :full-page="true" :active.sync="isLoading" />
  </div>
</template>
