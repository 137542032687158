
// 分拣中心
const CenterForSorting = {
  pleaseChoose: {
    zh: '请选择',
    en: 'Please select',
    pu: 'Favor selecionar'
  },
  pleaseInput: {
    zh: '请输入',
    en: 'Please enter the content',
    pu: 'Por favor digite'
  },
  add: {
    zh: '新增',
    en: 'add',
    pu: 'adicionar'
  },
  search: {
    zh: '查询',
    en: '',
    pu: 'Consultar'
  },
  reset: {
    zh: '重置',
    en: 'reset',
    pu: 'Reconfigurar'
  },
  export: {
    zh: '导出',
    en: 'export',
    pu: 'exportar'
  },
  State: {
    zh: '状态',
    en: 'state',
    pu: 'status'
  },
  accountState: {
    zh: '状态：',
    en: 'state：',
    pu: 'status：'
  },
  note: {
    zh: '备注：',
    en: 'remark: ',
    pu: 'observações: '
  },
  Note: {
    zh: '备注',
    en: 'remark',
    pu: 'observações'
  },
  Save: {
    zh: '保存',
    en: 'Save',
    pu: 'Salvar'
  },
  modify: {
    zh: '修改',
    en: 'modify',
    pu: 'modificar'
  },
  cancel: {
    zh: '取消',
    en: 'cancel',
    pu: 'cancelar'
  },
  edit: {
    zh: '编辑',
    en: 'Edit',
    pu: 'Editar'
  },
  operation: {
    zh: '操作',
    en: 'operation',
    pu: 'Operar'
  },
  ind: {
    zh: '序号',
    en: 'index',
    pu: 'Número de série.'
  },
  state: {
    zh: '州',
    en: 'state',
    pu: 'Estado'
  },
  CreationTime: {
    zh: '创建时间',
    en: 'Creation time',
    pu: 'Tempo de criação'
  },
  creator: {
    zh: '创建人',
    en: 'creator',
    pu: 'Criado por'
  },
  ModificationTime: {
    zh: '修改时间',
    en: 'Modification time',
    pu: 'Tempo de modificação'
  },
  ModifiedBy: {
    zh: '修改人',
    en: 'Modified by',
    pu: 'Modificado por'
  },
  enable: {
    zh: '启用',
    en: 'enable',
    pu: 'ativo'
  },
  notEnabled: {
    zh: '未启用',
    en: '',
    pu: 'desativo'
  },
  Lattice: {
    zh: '格口',
    en: 'Lattice',
    pu: 'Reticulado'
  },
  Editor: {
    zh: '编辑人',
    en: 'Editor',
    pu: 'Editor'
  },
  EditTime: {
    zh: '编辑时间',
    en: 'Edit time',
    pu: 'Hora de edição'
  },
  Delete: {
    zh: '删除',
    en: 'Delete',
    pu: 'Excluir'
  },
  AreYouSureDelete: {
    zh: '确定删除吗？',
    en: 'Are you sure to delete it',
    pu: 'Tem certeza de apagar?'
  },
  DeleteSucceeded: {
    zh: '删除成功',
    en: 'Delete succeeded',
    pu: 'Excluir foi bem sucedido'
  },
  notNull: {
    zh: '不能为空',
    en: 'Cannot be empty',
    pu: 'Não pode estar vazio'
  },
  CityName: {
    zh: '城市名称',
    en: '',
    pu: 'Cidade'
  },
  ZipCodeAreaName: {
    zh: '邮编分区名称：',
    en: '',
    pu: 'Divisão de Código Postal：'
  },
  CityN: {
    zh: '城市名称：',
    en: '',
    pu: 'Cidade：'
  },
  StateTwoCode: {
    zh: '州二字码',
    en: '',
    pu: 'Código de Dois Caracteres do Estado'
  },
  AddedZipCodeArea: {
    zh: '新增邮编分区',
    en: '',
    pu: 'Nova Divisão de Código Postal'
  },
  ZipCodeAreaNo: {
    zh: '邮编分区编号',
    en: '',
    pu: 'Número de Divisão do Código Postal'
  },
  ZipCodeAreaNum: {
    zh: '邮编分区编号：',
    en: '',
    pu: 'Número de Divisão do Código Postal'
  },
  ZipCodeAreaN: {
    zh: '邮编分区名称',
    en: '',
    pu: 'Divisão do Código Postal'
  },
  RuleName: {
    zh: '规则名称',
    en: '',
    pu: 'Nome de Regra'
  },
  ZipCodeArea: {
    zh: '邮编分区',
    en: '',
    pu: 'Divisão de Código Postal'
  },
  ZipCodeAreaNotEmpty: {
    zh: '邮编分区不能为空',
    en: '',
    pu: 'A divisão de Código Postal não pode estar vazia'
  },
  gekouNotNull: {
    zh: '格口不能为空',
    en: 'Container cannot be empty',
    pu: 'A saca não pode estar vazia'
  },
  gekouAdd: {
    zh: '新增格口',
    en: 'Add contentor',
    pu: 'Gerar nova saca'
  },
  gekouEdit: {
    zh: '编辑格口',
    en: 'Modify container information',
    pu: 'Modificar a informação da saca'
  },
  ImmediatelyCreate: {
    zh: '立即创建',
    en: '',
    pu: 'criar imediatamente'
  },
  newAddSuc: {
    zh: '新增成功',
    en: '',
    pu: 'adicionar com sucesso'
  },
  EditSuccessful: {
    zh: '修改成功',
    en: 'Modification successful',
    pu: 'Modificação bem sucedida'
  },
  SerialNumber: {
    zh: '编号：',
    en: '',
    pu: 'Número：'
  },
  ZipCodeAreaRange: {
    zh: '邮编分区范围',
    en: '',
    pu: 'Zona de Divisão de Código Postal'
  },
  LatticePortNumber: {
    zh: '格口编号',
    en: '',
    pu: 'Número de saca'
  },
  LatticePortNum: {
    zh: '格口编号：',
    en: '',
    pu: 'Número de saca：'
  },
  ClearCache: {
    zh: '清除缓存',
    en: 'Clear cache',
    pu: 'Cache claro'
  },
  packageSum: {
    zh: '小包数量',
    en: '',
    pu: 'Número de pacotes'
  },
  Print: {
    zh: '打印',
    en: '',
    pu: 'Imprimir'
  },
  orderNumber: {
    zh: '订单号',
    en: 'orderNumber：',
    pu: 'Número do pedido：'
  },
  bigBagNum: {
    zh: '大包号',
    en: '',
    pu: 'Grande bolsa'
  },
  bigBagNumber: {
    zh: '大包号：',
    en: '',
    pu: 'Grande bolsa：'
  },
  orderNum: {
    zh: '订单号',
    en: 'The order number',
    pu: 'Número do pedido'
  },
  ReferenceNumber: {
    zh: '参考号',
    en: 'Reference number',
    pu: 'No. Ref. Da Anjun'
  },
  OutLibrary: {
    zh: '已出库',
    en: 'Out of the library',
    pu: 'Fora do armazém'
  },
  orderStatus: {
    zh: '出入库状态',
    en: '',
    pu: 'Status na armazém'
  },
  NotInStorage: {
    zh: '未入库',
    en: '',
    pu: 'Não estar na armazém'
  },
  InStorage: {
    zh: '已入库',
    en: '',
    pu: 'Está na armazém'
  },
  sorterOrder: {
    zh: '分拣订单',
    en: '',
    pu: 'ordem de classificação'
  },
  channel: {
    zh: '渠道',
    en: 'channel',
    pu: 'Canal'
  },
  RuleImport: {
    zh: '规则导入',
    en: '',
    pu: 'As regras para importar'
  },
  Import: {
    zh: '导入',
    en: 'Import',
    pu: 'Importação'
  },
  TemplateDownload: {
    zh: '模版下载',
    en: '',
    pu: 'Download do modelo'
  },
  FileUpload: {
    zh: '文件上传',
    en: '',
    pu: 'Carregamento de arquivos'
  },
  AssociationRules: {
    zh: '关联规则',
    en: '',
    pu: 'Regras de associação'
  }
};
export {
  CenterForSorting
};
