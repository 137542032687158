<template>
  <div id="detailList" ref="detailList" class="fontSizeStyle">
    <div ref="detailListContent" class="detailListContent">
      <!--  头部    -->
      <div class="headerList">
        <div style="text-align: left;padding-right: 20px">
          <img :src="require('@/assets/anjunTxt.jpg')" alt="tu" style="width: 150px;">
        </div>
        <div style="font-size: 45px;">IATA:{{ paramData.iata || "- -" }}</div>
      </div>
      <!--   内容部分   -->
      <div class="listContent">
        <div>
          <div>ANJUN EXPRESS LOGISTICA E TRANSPORTES LTDA</div>
          <div>CNPJ:11605535000455</div>
          <div>Data de impressã: {{ paramData.createTime || "- -" }}</div>
        </div>
        <!--    条形码    -->
        <div>
          <canvas id="barcode" style="height: 80px;min-width: 200px;" />
        </div>
      </div>
      <!--   末端公司名称   -->
      <div style="margin-bottom: 20px">
        <table style="width: 100%;text-align: left">
          <tr>
            <td colspan="2" style="font-size: 25px">{{ `Destino: ${paramData.siteName || "- -"}` }}</td>
          </tr>
          <tr>
            <td colspan="2">{{ `End: ${paramData.siteAddress || "- -"}` }}</td>
          </tr>
          <tr>
            <td>{{ `Bairro: ${paramData.siteArea || "- -"}` }}</td>
            <td>{{ `Cidade: ${paramData.siteCity || "- -"}` }}</td>
          </tr>
          <tr>
            <td>{{ `UF: ${paramData.siteState || "- -"}` }}</td>
            <td>{{ `CEP: ${paramData.siteZipCode || "- -"}` }}</td>
          </tr>
          <tr>
            <td>{{ `ResponsAvel:` }}</td>
            <td>{{ `Data Geracao Manifesto: ${paramData.createTime || "- -"}` }}</td>
          </tr>
        </table>
      </div>
      <!--   发运公司   -->
      <div>
        <table style="width: 100%;text-align: left">
          <tr>
            <td colspan="2">{{ `Origem: ANJUN EXPRESS LOGISTICA E TRANSPORTES LTDA` }}</td>
          </tr>
          <tr>
            <td colspan="2">{{ `End: ${paramData.senderAddress || "- -"}` }}</td>
          </tr>
          <tr>
            <td>{{ `Bairro: ${paramData.senderArea || "- -"}` }}</td>
            <td>{{ `Cidade: ${paramData.senderProvince || "- -"}` }}</td>
          </tr>
          <tr>
            <td>{{ `UF: ${paramData.senderCity || "- -"}` }}</td>
            <td>{{ `CEP: ${paramData.senderZipcode || "- -"}` }}</td>
          </tr>
          <tr>
            <td>{{ `ResponsAvel:` }}</td>
            <td>{{ `Data Geracao Manifesto:` }}</td>
          </tr>
        </table>
      </div>
      <!--  info    -->
      <div class="info">Informações da Lista</div>
      <div>
        <table style="width: 100%;text-align: center">
          <tr style="background: #808285;height: 50px;font-size: 25px">
            <th colspan="2">Seal number</th>
            <th>Qtd</th>
            <th>Kgs</th>
            <th>Pacotes</th>
          </tr>
          <tr v-for="(item,index) in paramData.transitWaybillSubVOList" :key="index">
            <td style="font-size: 20px">{{ index + 1 }}</td>
            <td style="font-size: 20px">{{ item.transitBagNumber || "- -" }}</td>
            <td style="font-size: 20px">{{ item.transitBagCount || "- -" }}</td>
            <td style="font-size: 20px">{{ item.totalWeight || "- -" }}</td>
            <td style="font-size: 20px">{{ item.packageCount || "- -" }}</td>
          </tr>
          <tr style="height: 60px">
            <td colspan="2" style="font-size: 30px">Total</td>
            <td style="font-size: 30px">{{ paramData.transitBagTotal || "- -" }}</td>
            <td style="font-size: 30px">{{ paramData.transitBagWeightTotal || "- -" }}</td>
            <td style="font-size: 30px">{{ paramData.waybillNumberTotal || "- -" }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import JsBarcode from 'jsbarcode';
import printXq from 'print-xq';

export default {
  props: {
    paramData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      dateTime: '',
      total: 0
    };
  },
  created() {
    this.$nextTick(() => {
      setTimeout(() => {
        console.log(this.paramData.plpNumber, 'this.paramData.plpNumber');
        JsBarcode('#barcode', this.paramData.plpNumber, {
          format: 'CODE128', // 选择要使用的条形码类型
          displayValue: true,
          textAlign: 'center',
          fontSize: 28
        });
        printXq({
          printable: 'detailList', // // 文档来源：pdf或图像的url，html元素的id或json数据的对象
          type: 'html', // 可打印的类型。可用的打印选项包括：pdf，html，图像，json和raw-html。
          targetStyles: ['*'] // 库在打印HTML元素时处理任何样式
        });
        this.$emit('hiddenHtml');
      }, 500);
    });
  }
};
</script>
<style scoped>
.fontSizeStyle * {
  font-size: 18px;
}

.headerList {
  display: flex;
}

.listContent {
  display: flex;
  justify-content: space-between;
}

table {
  border-collapse: collapse;
  border: 1px solid black;
}

th, td {
  border: 1px solid black;
  padding: 5px;
}

.info {
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}
</style>
