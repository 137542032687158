<script >
import { computed, defineProps, ref, watch } from '@vue/composition-api';
import { default as SkeletonTable } from '@/components/skeletonTable';
import { default as DropdownTable } from '@/components/dropdownTable';
import { default as Actions } from './actionsTableDataNotRegistered';
import { useGetAllSellersNotRegistered } from '@/api/transport/seller/query';
const __sfc_main = {};
__sfc_main.props = {
  filters: {
    type: Object
  },
  errorSellersNotRegistered: {
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const params = ref({
    ...props.filters,
    currentPage: 1,
    perPage: 10
  });
  const {
    data,
    isLoading,
    isFetched,
    isFetching,
    error,
    isError
  } = useGetAllSellersNotRegistered(params);
  const tableData = computed(() => {
    return data.value?.data?.result ?? data.value;
  });
  const tableTotal = computed(() => {
    return data.value?.data?.sellerCount ?? 1;
  });
  const isLoadingTable = computed(() => {
    return isLoading.value || !isFetched.value || isFetching.value;
  });
  watch(error, newError => {
    props.errorSellersNotRegistered.detailError = newError;
    props.errorSellersNotRegistered.isError = isError;
  });
  watch(props.filters, newFilters => {
    params.value = params.value.currentPage > 1 ? {
      ...newFilters,
      currentPage: 1,
      perPage: 10
    } : {
      ...params.value,
      ...newFilters
    };
  });
  return {
    props,
    params,
    tableData,
    tableTotal,
    isLoadingTable
  };
};
__sfc_main.components = Object.assign({
  SkeletonTable,
  Actions
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <SkeletonTable :is-loading="isLoadingTable">
    <o-table
      :data="tableData"
      :row-key="'id'"
      bordered
      class="space-m-y-32"
      paginated
      backend-pagination
      :total="tableTotal"
      :per-page="params.perPage"
      :current-page.sync="params.currentPage"
      :pagination-simple="false"
    >
      <o-table-column v-slot="props" :label="$t('seller.sellerCode')" field="seller_code">
        {{ props.row.seller_code }}
      </o-table-column>
      <o-table-column v-slot="props" :label="$t('seller.sellerName')" field="seller_name">
        {{ props.row.seller_name }}
      </o-table-column>
      <o-table-column v-slot="props" :label="$t('seller.client')" field="customer_name">
        {{ props.row.customer_name }}
      </o-table-column>
      <o-table-column v-slot="props" :label="$t('seller.cep')" field="seller_zipcode">
        {{ props.row.seller_zipcode }}
      </o-table-column>

      <o-table-column label="" width="84">
        <template slot-scope="{row}">
          <Actions :row="row" />
        </template>
      </o-table-column>
    </o-table>
  </SkeletonTable>
</template>
