var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "warpp" },
    [
      _c("div", { staticClass: "buts" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customerType.type === _vm.typeObj.set.code,
                expression: "customerType.type === typeObj.set.code",
              },
              {
                name: "permit",
                rawName: "v-permit:remove",
                value: "but:customer-managements:list:audit",
                expression: "'but:customer-managements:list:audit'",
                arg: "remove",
              },
            ],
          },
          [
            _vm.ruleForm.auditState === 0
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.dialog.examineVisible = true
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("customerManagements.AccountAudit")) + " "
                    ),
                  ]
                )
              : [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value: "but:customer-managements:list:lock",
                          expression: "'but:customer-managements:list:lock'",
                          arg: "remove",
                        },
                      ],
                    },
                    [
                      _vm.ruleForm.lockState === 1
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "warning" },
                              on: {
                                click: function ($event) {
                                  return _vm.setLockState(0)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("customerManagements.AccountOpening")
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _c(
                            "el-button",
                            {
                              attrs: { type: "danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.setLockState(1)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("customerManagements.AccountDisabled")
                                ) + " "
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
                ],
          ],
          2
        ),
      ]),
      _c("el-card", [
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-ruleForm",
                attrs: {
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-width": "150px",
                },
              },
              [
                _c("el-divider", [
                  _vm._v(_vm._s(_vm.$t("customerManagements.AccountsRelated"))),
                ]),
                _c(
                  "el-row",
                  { attrs: { justify: "space-between", type: "flex" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("customerManagements.accountName"),
                              prop: "username",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled: _vm.disabled,
                                placeholder: _vm.$t(
                                  "customerManagements.pleaseInput"
                                ),
                                "show-word-limit": "",
                              },
                              model: {
                                value: _vm.ruleForm.username,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "username",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.username",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t(
                                "customerManagements.AccountNickname"
                              ),
                              prop: "customerAlias",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: _vm.$t(
                                  "customerManagements.pleaseInput"
                                ),
                                readonly: _vm.readonly,
                              },
                              model: {
                                value: _vm.ruleForm.customerAlias,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "customerAlias",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.customerAlias",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.customerType.type === _vm.typeObj.add.code
                  ? _c(
                      "el-row",
                      { attrs: { justify: "space-between", type: "flex" } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("customerManagements.password"),
                                  prop: "password",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    placeholder: _vm.$t(
                                      "customerManagements.passwordPrompt"
                                    ),
                                    maxlength: "12",
                                    minlength: "8",
                                    "show-word-limit": "",
                                  },
                                  model: {
                                    value: _vm.ruleForm.password,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "password",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.password",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "customerManagements.AccountAudit"
                                  ),
                                  prop: "auditState",
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.ruleForm.auditState,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "auditState",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.auditState",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-button",
                                      { attrs: { label: "0" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "customerManagements.NotAudit"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-radio-button",
                                      { attrs: { label: "1" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "customerManagements.Approved"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-row",
                  { attrs: { justify: "space-between", type: "flex" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("customerManagements.AccStatus"),
                              prop: "lockState",
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.ruleForm.lockState,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "lockState", $$v)
                                  },
                                  expression: "ruleForm.lockState",
                                },
                              },
                              [
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "0" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("customerManagements.enable")
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "1" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("customerManagements.Disable")
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("customerManagements.customerType"),
                              prop: "name",
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                on: { change: _vm.changeSource },
                                model: {
                                  value: _vm.ruleForm.source,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "source", $$v)
                                  },
                                  expression: "ruleForm.source",
                                },
                              },
                              [
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "corporation" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("customerManagements.enterprise")
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "person" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("customerManagements.personal")
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { justify: "space-between", type: "flex" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("customerManagements.integral"),
                              prop: "integral",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: _vm.$t(
                                  "customerManagements.pleaseInput"
                                ),
                                disabled: "",
                              },
                              model: {
                                value: _vm.ruleForm.integral,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "integral", $$v)
                                },
                                expression: "ruleForm.integral",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("customerManagements.agent"),
                              prop: "integral",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: _vm.$t(
                                    "operationCenter.Only10AreDisplayed"
                                  ),
                                  clearable: "",
                                  filterable: "",
                                  multiple: "",
                                },
                                model: {
                                  value: _vm.ruleForm.handlerIdList,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "handlerIdList", $$v)
                                  },
                                  expression: "ruleForm.handlerIdList",
                                },
                              },
                              _vm._l(_vm.userList, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  {
                    staticClass: "siteClass",
                    attrs: { justify: "space-between", type: "flex" },
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("customerManagements.site"),
                              prop: "siteId",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  disabled: _vm.readonly,
                                  placeholder: _vm.$t(
                                    "operationCenter.PleaseSelect"
                                  ),
                                  clearable: "",
                                  filterable: "",
                                },
                                model: {
                                  value: _vm.ruleForm.siteId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "siteId", $$v)
                                  },
                                  expression: "ruleForm.siteId",
                                },
                              },
                              _vm._l(_vm.siteIdList, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t(
                                "customerManagements.CustomerPhone"
                              ),
                              prop: "customerPhone",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: _vm.$t(
                                  "operationCenter.PleaseEnterTheContent"
                                ),
                                readonly: _vm.readonly,
                              },
                              model: {
                                value: _vm.ruleForm.customerPhone,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "customerPhone", $$v)
                                },
                                expression: "ruleForm.customerPhone",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { staticClass: "siteClass" },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t(
                                "customerManagements.CustomersEmail"
                              ),
                              prop: "customerEmail",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: _vm.$t(
                                  "operationCenter.PleaseEnterTheContent"
                                ),
                                readonly: _vm.readonly,
                              },
                              model: {
                                value: _vm.ruleForm.customerEmail,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "customerEmail", $$v)
                                },
                                expression: "ruleForm.customerEmail",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("customerManagements.ParcelError"),
                              prop: "packageDeviation",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: _vm.$t(
                                  "operationCenter.PleaseEnterTheMaximumErrorValue"
                                ),
                                readonly: _vm.readonly,
                              },
                              on: {
                                blur: function ($event) {
                                  _vm.ruleForm.packageDeviation = _vm.ruleForm
                                    .packageDeviation
                                    ? parseFloat(
                                        _vm.ruleForm.packageDeviation
                                      ).toFixed(3)
                                    : ""
                                },
                              },
                              model: {
                                value: _vm.ruleForm.packageDeviation,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "packageDeviation",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.packageDeviation",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("customerManagements.priceTerms"),
                              prop: "billingMethod",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "operationCenter.PleaseSelect"
                                  ),
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.ruleForm.billingMethod,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "billingMethod", $$v)
                                  },
                                  expression: "ruleForm.billingMethod",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: {
                                    label: _vm.$t(
                                      "customerManagements.oneParagraph"
                                    ),
                                    value: 0,
                                  },
                                }),
                                _c("el-option", {
                                  attrs: {
                                    label: _vm.$t(
                                      "customerManagements.twoParagraph"
                                    ),
                                    value: 1,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t(
                                "customerManagements.VolumeBilling"
                              ),
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "volume" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "", "label-width": "5px" },
                                  },
                                  [
                                    _c("el-checkbox", {
                                      attrs: {
                                        "false-label": 0,
                                        "true-label": 1,
                                      },
                                      model: {
                                        value: _vm.ruleForm.useVolumeBilling,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "useVolumeBilling",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.useVolumeBilling",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "",
                                      "label-width": "5px",
                                      prop: "calculationMethod",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100px" },
                                        attrs: {
                                          disabled:
                                            !_vm.ruleForm.useVolumeBilling,
                                          placeholder: _vm.$t(
                                            "operationCenter.PleaseSelect"
                                          ),
                                        },
                                        model: {
                                          value: _vm.ruleForm.calculationMethod,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "calculationMethod",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm.calculationMethod",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: { value: 0, label: "*" },
                                        }),
                                        _c("el-option", {
                                          attrs: { value: 1, label: "/" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "",
                                      "label-width": "5px",
                                      prop: "calculateBase",
                                    },
                                  },
                                  [
                                    _c("el-input-number", {
                                      attrs: {
                                        controls: false,
                                        disabled:
                                          !_vm.ruleForm.useVolumeBilling,
                                        max: 999999999,
                                        min: 0,
                                        placeholder: _vm.$t(
                                          "customerManagements.pleaseInput"
                                        ),
                                        precision: 2,
                                      },
                                      model: {
                                        value: _vm.ruleForm.calculateBase,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "calculateBase",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.calculateBase",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.ruleForm.source === "corporation"
                  ? [
                      _c("el-divider", [
                        _vm._v(
                          _vm._s(_vm.$t("customerManagements.enterprise"))
                        ),
                      ]),
                      _c(
                        "el-row",
                        { attrs: { justify: "space-between", type: "flex" } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 18 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "customerManagements.CompanyName"
                                    ),
                                    prop: "company",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "customerManagements.pleaseEnterTheFullCompanyName"
                                      ),
                                      readonly: _vm.readonly,
                                      maxlength: "30",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.ruleForm.company,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "company",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "ruleForm.company",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("el-col", { attrs: { span: 5 } }, [
                            _c(
                              "div",
                              { staticClass: "mt10" },
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      href: "https://www.qcc.com/",
                                      target: "_blank",
                                      type: "primary",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "customerManagements.SkipToCompanyCheck"
                                          )
                                        )
                                    ),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-view el-icon--right",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { justify: "space-between", type: "flex" } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "customerManagements.LegalRepresentive"
                                    ),
                                    prop: "coPrincipalName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "customerManagements.pleaseInput"
                                      ),
                                      readonly: _vm.readonly,
                                    },
                                    model: {
                                      value: _vm.ruleForm.coPrincipalName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "coPrincipalName",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "ruleForm.coPrincipalName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "customerManagements.MobilePhoneOfCompanyOwner"
                                    ),
                                    prop: "coPrincipalPhone",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "customerManagements.pleaseInput"
                                      ),
                                      readonly: _vm.readonly,
                                      maxlength: "20",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.ruleForm.coPrincipalPhone,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "coPrincipalPhone",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.coPrincipalPhone",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { justify: "space-between", type: "flex" } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "customerManagements.HeadEnterprisePhone"
                                    ),
                                    prop: "coPrincipalTel",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "customerManagements.pleaseInput"
                                      ),
                                      readonly: _vm.readonly,
                                      maxlength: "20",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.ruleForm.coPrincipalTel,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "coPrincipalTel",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "ruleForm.coPrincipalTel",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "customerManagements.HeadEnterpriseEmail"
                                    ),
                                    prop: "coPrincipalEmail",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "customerManagements.pleaseInput"
                                      ),
                                      readonly: _vm.readonly,
                                    },
                                    model: {
                                      value: _vm.ruleForm.coPrincipalEmail,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "coPrincipalEmail",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "ruleForm.coPrincipalEmail",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { justify: "space-between", type: "flex" } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "customerManagements.HeadEnterpriseQQ"
                                    ),
                                    prop: "coPrincipalQq",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "customerManagements.pleaseInput"
                                      ),
                                      readonly: _vm.readonly,
                                      maxlength: "11",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.ruleForm.coPrincipalQq,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "coPrincipalQq",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "ruleForm.coPrincipalQq",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("customerManagements.companyAddress"),
                            prop: "address",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "customerManagements.pleaseInput"
                              ),
                              readonly: _vm.readonly,
                              maxlength: "200",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.ruleForm.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "address", $$v)
                              },
                              expression: "ruleForm.address",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          key: _vm.ruleForm.source,
                          attrs: {
                            label: _vm.$t(
                              "customerManagements.UnifiedSocialCodeOfCredit"
                            ),
                            prop: "identityCard",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "customerManagements.pleaseInput"
                              ),
                              readonly: _vm.readonly,
                            },
                            model: {
                              value: _vm.ruleForm.identityCard,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "identityCard", $$v)
                              },
                              expression: "ruleForm.identityCard",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          key: _vm.ruleForm.source,
                          attrs: {
                            label: _vm.$t("customerManagements.cnpjMother"),
                            prop: "cnpjMother",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "customerManagements.pleaseInput"
                              ),
                              readonly: _vm.readonly,
                            },
                            model: {
                              value: _vm.ruleForm.cnpjMother,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "cnpjMother", $$v)
                              },
                              expression: "ruleForm.cnpjMother",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          key: _vm.ruleForm.source,
                          attrs: {
                            label: _vm.$t("customerManagements.cnpjSon"),
                            prop: "cnpjSon",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "customerManagements.pleaseInput"
                              ),
                              readonly: _vm.readonly,
                            },
                            model: {
                              value: _vm.ruleForm.cnpjSon,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "cnpjSon", $$v)
                              },
                              expression: "ruleForm.cnpjSon",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("customerManagements.enclosure"),
                          },
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "upload",
                              staticClass: "avatar-uploader",
                              attrs: {
                                accept: _vm.acceptStr,
                                "before-upload": _vm.resumeBeforeAvatarUpload,
                                "file-list": _vm.fileList,
                                "http-request": _vm.resumeUpload,
                                limit: 4,
                                multiple: false,
                                "on-remove": _vm.removeFile,
                                action: "",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("customerManagements.clickUpload")
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  attrs: { slot: "tip" },
                                  slot: "tip",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "customerManagements.uploadPicturesNoMoreThan5MB"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.fileList.length > 0 || _vm.PDFFileList.length > 0
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "已上传附件" } },
                            [
                              _c(
                                "div",
                                { staticClass: "demo-image__preview" },
                                [
                                  _vm._l(_vm.fileList, function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "imglist",
                                        staticStyle: {
                                          display: "inline-block",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: {
                                            width: "100px",
                                            height: "100px",
                                            "margin-right": "10px",
                                          },
                                          attrs: { src: item.url, alt: "" },
                                        }),
                                      ]
                                    )
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "ullist" },
                                    [
                                      _vm._l(_vm.PDFFileList, function (item) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              key: item.url,
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleSee(item.url)
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          ),
                                        ]
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                2
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  : [
                      _c("el-divider", [
                        _vm._v(_vm._s(_vm.$t("customerManagements.personal"))),
                      ]),
                      _c(
                        "el-row",
                        { attrs: { justify: "space-between", type: "flex" } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "customerManagements.address"
                                    ),
                                    prop: "address",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "customerManagements.pleaseInput"
                                      ),
                                      readonly: _vm.readonly,
                                      maxlength: "40",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.ruleForm.address,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "address", $$v)
                                      },
                                      expression: "ruleForm.address",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("customerManagements.IdNumber"),
                            prop: "identityCard",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "customerManagements.pleaseInput"
                              ),
                              readonly: _vm.readonly,
                              maxlength: "18",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.ruleForm.identityCard,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "identityCard",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "ruleForm.identityCard",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("customerManagements.enclosure"),
                          },
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                accept: _vm.acceptStr,
                                "before-upload": _vm.resumeBeforeAvatarUpload,
                                "file-list": _vm.fileList,
                                "http-request": _vm.resumeUpload,
                                limit: 4,
                                multiple: false,
                                "on-remove": _vm.resumeRemove,
                                action: "",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("customerManagements.clickUpload")
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  attrs: { slot: "tip" },
                                  slot: "tip",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "customerManagements.uploadPicturesAndPDFFilesUpTo5MB"
                                      )
                                    ) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.fileList.length > 0 || _vm.PDFFileList.length > 0
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "customerManagements.attachmentHasBeenUploaded"
                                ),
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "demo-image__preview" },
                                [
                                  _vm._l(_vm.fileList, function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "imglist",
                                        staticStyle: {
                                          display: "inline-block",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: {
                                            width: "100px",
                                            height: "100px",
                                            "margin-right": "10px",
                                          },
                                          attrs: { src: item.url, alt: "" },
                                        }),
                                      ]
                                    )
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "ullist" },
                                    [
                                      _vm._l(_vm.PDFFileList, function (item) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              key: item.url,
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleSee(item.url)
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          ),
                                        ]
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                2
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                _c("el-divider", [
                  _vm._v(
                    _vm._s(_vm.$t("customerManagements.FinancialRelated"))
                  ),
                ]),
                _c(
                  "el-row",
                  { attrs: { justify: "space-between", type: "flex" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t(
                                "customerManagements.FinancialContactPerson"
                              ),
                              prop: "financeContactsName",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: _vm.$t(
                                  "customerManagements.pleaseInput"
                                ),
                                readonly: _vm.readonly,
                              },
                              model: {
                                value: _vm.ruleForm.financeContactsName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "financeContactsName",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.financeContactsName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t(
                                "customerManagements.FinancialContactPersonPhone"
                              ),
                              prop: "financeContactsPhone",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: _vm.$t(
                                  "customerManagements.pleaseInput"
                                ),
                                readonly: _vm.readonly,
                                maxlength: "20",
                                "show-word-limit": "",
                              },
                              model: {
                                value: _vm.ruleForm.financeContactsPhone,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "financeContactsPhone",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.financeContactsPhone",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { justify: "space-between", type: "flex" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t(
                                "customerManagements.FinancialContactPersonTel"
                              ),
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: _vm.$t(
                                  "customerManagements.pleaseInput"
                                ),
                                readonly: _vm.readonly,
                              },
                              model: {
                                value: _vm.ruleForm.financeContactsTel,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "financeContactsTel",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.financeContactsTel",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t(
                                "customerManagements.FinancialContactPersonEmail"
                              ),
                              prop: "financeContactsEmail",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: _vm.$t(
                                  "customerManagements.pleaseInput"
                                ),
                                readonly: _vm.readonly,
                              },
                              model: {
                                value: _vm.ruleForm.financeContactsEmail,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "financeContactsEmail",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.financeContactsEmail",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { justify: "space-between", type: "flex" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t(
                                "customerManagements.FinancialContactPersonQQ"
                              ),
                              prop: "financeContactsQq",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: _vm.$t(
                                  "customerManagements.pleaseInput"
                                ),
                                readonly: _vm.readonly,
                                maxlength: "11",
                                "show-word-limit": "",
                              },
                              model: {
                                value: _vm.ruleForm.financeContactsQq,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "financeContactsQq",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.financeContactsQq",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("el-divider", [
                  _vm._v(
                    _vm._s(_vm.$t("customerManagements.LogisticsRelated"))
                  ),
                ]),
                _c(
                  "el-row",
                  { attrs: { justify: "space-between", type: "flex" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t(
                                "customerManagements.LogisticsContact"
                              ),
                              prop: "logisticsContactsName",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: _vm.$t(
                                  "customerManagements.pleaseInput"
                                ),
                                readonly: _vm.readonly,
                              },
                              model: {
                                value: _vm.ruleForm.logisticsContactsName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "logisticsContactsName",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.logisticsContactsName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t(
                                "customerManagements.LogisticsContactPersonPhone"
                              ),
                              prop: "logisticsContactsPhone",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: _vm.$t(
                                  "customerManagements.pleaseInput"
                                ),
                                readonly: _vm.readonly,
                                maxlength: "20",
                                "show-word-limit": "",
                              },
                              model: {
                                value: _vm.ruleForm.logisticsContactsPhone,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "logisticsContactsPhone",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.logisticsContactsPhone",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { justify: "space-between", type: "flex" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t(
                                "customerManagements.LogisticsContactPersonTel"
                              ),
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: _vm.$t(
                                  "customerManagements.pleaseInput"
                                ),
                                readonly: _vm.readonly,
                              },
                              model: {
                                value: _vm.ruleForm.logisticsContactsTel,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "logisticsContactsTel",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.logisticsContactsTel",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t(
                                "customerManagements.LogisticsContactPersonEmail"
                              ),
                              prop: "logisticsContactsEmail",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: _vm.$t(
                                  "customerManagements.pleaseInput"
                                ),
                                readonly: _vm.readonly,
                              },
                              model: {
                                value: _vm.ruleForm.logisticsContactsEmail,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "logisticsContactsEmail",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.logisticsContactsEmail",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { justify: "space-between", type: "flex" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t(
                                "customerManagements.LogisticsContactPersonQQ"
                              ),
                              prop: "logisticsContactsQq",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: _vm.$t(
                                  "customerManagements.pleaseInput"
                                ),
                                readonly: _vm.readonly,
                                maxlength: "11",
                                "show-word-limit": "",
                              },
                              model: {
                                value: _vm.ruleForm.logisticsContactsQq,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "logisticsContactsQq",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.logisticsContactsQq",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.customerType.type === _vm.typeObj.add.code ||
                _vm.customerType.type === _vm.typeObj.edit.code
                  ? _c(
                      "el-form-item",
                      [
                        _c(
                          "el-row",
                          { attrs: { type: "flex" } },
                          [
                            _c("el-col", { attrs: { span: 18 } }),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onSubmit("ruleForm")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("customerManagements.submit")
                                      ) + " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.resetForm("ruleForm")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("customerManagements.reset")
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            title: _vm.$t("customerManagements.tips"),
            visible: _vm.dialog.examineVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialog, "examineVisible", $event)
            },
          },
        },
        [
          _c("p", { staticClass: "dialog" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.$t("customerManagements.IfAgreeAudit"))),
            ]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("customerManagements.NoteDefaultAccount")) + " "
              ),
              _c("strong", [
                _vm._v(_vm._s(_vm.$t("customerManagements.Disable"))),
              ]),
              _vm._v(" " + _vm._s(_vm.$t("customerManagements.accountState"))),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialog.examineVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("customerManagements.cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.setAuditState },
                },
                [_vm._v(_vm._s(_vm.$t("customerManagements.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            title: _vm.$t("customerManagements.tips"),
            visible: _vm.dialog.lockVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialog, "lockVisible", $event)
            },
          },
        },
        [
          _c("p", { staticClass: "dialog" }, [
            _c("span", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("customerManagements.ConfirmOperationAccount")
                  ) +
                  " "
              ),
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.dialog.obj.type == 0
                      ? _vm.$t("customerManagements.enable")
                      : _vm.$t("customerManagements.Disable")
                  )
                ),
              ]),
              _vm._v(
                " " + _vm._s(_vm.$t("customerManagements.operation")) + " "
              ),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialog.lockVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("customerManagements.cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.determineLockState },
                },
                [_vm._v(_vm._s(_vm.$t("customerManagements.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }