var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addDa" },
    [
      _c(
        "el-form",
        {
          ref: "updateForm",
          staticStyle: {
            padding: "20px",
            height: "calc(100vh - 130px)",
            "overflow-y": "auto",
          },
          attrs: {
            model: _vm.updateForm,
            "label-width": _vm.checkData.orderType != 1 ? "120px" : "auto",
            "label-position":
              _vm.$i18n.locale != "zh" && _vm.checkData.orderType != 1
                ? "top"
                : "right",
          },
        },
        [
          _vm.level == 0
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "level",
                    label: _vm.$t("orderCenterCont.selectAHierarchy") + "：",
                    rules: [
                      {
                        required: true,
                        message: _vm.$t("orderCenterCont.CannotBeEmpty"),
                        trigger: ["blur", "change"],
                      },
                    ],
                  },
                },
                [
                  _vm.checkData.orderType === 1
                    ? _c("div", [
                        _vm.updateForm.level === 0
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("orderCenterCont.province"))
                              ),
                            ])
                          : _vm._e(),
                        _vm.updateForm.level === 1
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.$t("orderCenterCont.city"))),
                            ])
                          : _vm._e(),
                        _vm.updateForm.level === 2
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.$t("orderCenterCont.region"))),
                            ])
                          : _vm._e(),
                      ])
                    : _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            placeholder: _vm.$t("operationCenter.PleaseSelect"),
                            clearable: "",
                          },
                          model: {
                            value: _vm.updateForm.level,
                            callback: function ($$v) {
                              _vm.$set(_vm.updateForm, "level", $$v)
                            },
                            expression: "updateForm.level",
                          },
                        },
                        _vm._l(_vm.Hierarchy, function (item) {
                          return _c("el-option", {
                            key: item.level,
                            attrs: { label: item.name, value: item.level },
                          })
                        }),
                        1
                      ),
                ],
                1
              )
            : _vm._e(),
          _c("div", [
            _vm.checkData.orderType === 1
              ? _c(
                  "div",
                  [
                    _vm.updateForm.level === 0
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "country",
                              label:
                                _vm.$t("orderCenterCont.SubordinateSuperior") +
                                "：",
                              rules: [
                                {
                                  required: true,
                                  message: _vm.$t(
                                    "orderCenterCont.CannotBeEmpty"
                                  ),
                                  trigger: ["blur", "change"],
                                },
                              ],
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.region.nameStr))])]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _vm.updateForm.level === 1 || _vm.updateForm.level === 2
                      ? _c(
                          "el-form-item",
                          {
                            staticStyle: { display: "inline-block" },
                            attrs: {
                              prop: "country",
                              label:
                                _vm.$t("orderCenterCont.SubordinateSuperior") +
                                "：",
                              rules: [
                                {
                                  required: true,
                                  message: _vm.$t(
                                    "orderCenterCont.CannotBeEmpty"
                                  ),
                                  trigger: ["blur", "change"],
                                },
                              ],
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  placeholder: _vm.$t(
                                    "operationCenter.PleaseSelect"
                                  ),
                                  clearable: "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.getSelectProvince1(
                                      1,
                                      _vm.updateForm.country
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.updateForm.country,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.updateForm, "country", $$v)
                                  },
                                  expression: "updateForm.country",
                                },
                              },
                              _vm._l(_vm.region.countryList, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.label, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.updateForm.level === 2
                      ? _c(
                          "el-form-item",
                          {
                            staticStyle: { display: "inline-block" },
                            attrs: {
                              prop: "province",
                              "label-width": "0",
                              rules: [
                                {
                                  required: true,
                                  message: _vm.$t(
                                    "orderCenterCont.CannotBeEmpty"
                                  ),
                                  trigger: ["blur", "change"],
                                },
                              ],
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  placeholder: _vm.$t(
                                    "operationCenter.PleaseSelect"
                                  ),
                                  clearable: "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.getSelectProvince1(
                                      2,
                                      _vm.updateForm.province
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.updateForm.province,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.updateForm, "province", $$v)
                                  },
                                  expression: "updateForm.province",
                                },
                              },
                              _vm._l(_vm.region.provinceList, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.label, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
          ]),
          _vm.superior.province && _vm.level !== 0
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("orderCenterCont.SubordinateState"),
                    required: "",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.superior.province) + " ")]
              )
            : _vm._e(),
          _vm.level == 2
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("orderCenterCont.BelongsToTheCity"),
                    required: "",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.superior.city) + " ")]
              )
            : _vm._e(),
          _vm.level === 1
            ? _c(
                "div",
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("orderCenterCont.startZipCode"),
                                prop: "startZipCode",
                                rules: _vm.isNumberRules,
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.updateForm.startZipCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.updateForm,
                                      "startZipCode",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "updateForm.startZipCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("orderCenterCont.endZipCode"),
                                prop: "endZipCode",
                                rules: _vm.isNumberRules,
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.updateForm.endZipCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.updateForm,
                                      "endZipCode",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "updateForm.endZipCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.updateForm.level === 0 || _vm.updateForm.level === 1
            ? _c(
                "div",
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("orderCenterCont.startZipCode"),
                                prop: "startZipCode",
                                rules: _vm.isNumberRules,
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.updateForm.startZipCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.updateForm,
                                      "startZipCode",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "updateForm.startZipCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("orderCenterCont.endZipCode"),
                                prop: "endZipCode",
                                rules: _vm.isNumberRules,
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.updateForm.endZipCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.updateForm,
                                      "endZipCode",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "updateForm.endZipCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("orderCenterCont.ChineseName") + "：",
                prop: "nameCn",
                rules: _vm.isChinese,
              },
            },
            [
              _vm.checkData.orderType === 1
                ? _c("span", [_vm._v(_vm._s(_vm.updateForm.nameCn))])
                : _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.updateForm.nameCn,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.updateForm,
                          "nameCn",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "updateForm.nameCn",
                    },
                  }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "nameEn",
                label: _vm.$t("orderCenterCont.EnglishName") + "：",
                rules: _vm.isEnglish,
              },
            },
            [
              _vm.checkData.orderType === 1
                ? _c("span", [_vm._v(_vm._s(_vm.updateForm.nameEn))])
                : _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.updateForm.nameEn,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateForm, "nameEn", $$v)
                      },
                      expression: "updateForm.nameEn",
                    },
                  }),
            ],
            1
          ),
          _vm.updateForm.level === 1 || _vm.updateForm.level === 2
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("orderCenterCont.extra") + "：",
                    prop: "extra",
                    rules: [
                      {
                        required: false,
                        message: _vm.$t("orderCenterCont.CannotBeEmpty"),
                        trigger: ["blur", "change"],
                      },
                    ],
                  },
                },
                [
                  _vm.checkData.orderType === 1
                    ? _c("span", [_vm._v(_vm._s(_vm.updateForm.extra))])
                    : _c(
                        "div",
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 5,
                              autocomplete: "off",
                              placeholder: _vm.$t(
                                "orderCenterCont.MultipleFields"
                              ),
                            },
                            model: {
                              value: _vm.updateForm.extra,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.updateForm,
                                  "extra",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "updateForm.extra",
                            },
                          }),
                        ],
                        1
                      ),
                ]
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "twoCode",
                label: _vm.$t("orderCenterCont.TwoWordCode") + "：",
                rules: [
                  {
                    required: false,
                    validator: _vm.englishLetter,
                    length: 2,
                    trigger: ["blur", "change"],
                  },
                ],
              },
            },
            [
              _vm.checkData.orderType === 1
                ? _c("span", [_vm._v(_vm._s(_vm.updateForm.twoCode))])
                : _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      maxlength: "2",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.updateForm.twoCode,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.updateForm,
                          "twoCode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "updateForm.twoCode",
                    },
                  }),
            ],
            1
          ),
          _vm.updateForm.level === 1 || _vm.level === 1
            ? _c(
                "div",
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("orderCenterCont.partition"),
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { display: "block" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: _vm.$t(
                                      "collectionCenter.pleaseInput"
                                    ),
                                  },
                                  model: {
                                    value: _vm.updateForm.zipcodeAreaCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.updateForm,
                                        "zipcodeAreaCode",
                                        $$v
                                      )
                                    },
                                    expression: "updateForm.zipcodeAreaCode",
                                  },
                                },
                                _vm._l(
                                  _vm.ZipCodeAreaList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.checkData.orderType !== 1
        ? _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: {
                padding: "10px 20px",
                "text-align": "right",
                "border-top": "1px solid #ccc",
              },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.countryContainer(1)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("operationCenter.Cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.countryContainer(2)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("operationCenter.determine")))]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }