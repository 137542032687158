var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "boxInfo1" },
    [
      _c(
        "el-tabs",
        { attrs: { type: "border-card" } },
        [
          _c(
            "el-tab-pane",
            {
              attrs: {
                label:
                  _vm.operationType === 1
                    ? _vm.$t("basicData.AddANewQuote")
                    : _vm.$t("basicData.QuoteDetails"),
              },
            },
            [
              _c("details-cmp", {
                attrs: {
                  "edit-data": _vm.editData,
                  "operation-type": _vm.operationType,
                },
                on: { saveData: _vm.saveChange },
              }),
            ],
            1
          ),
          _vm.operationType !== 1
            ? _c(
                "el-tab-pane",
                { attrs: { label: _vm.$t("basicData.OperationalLogs") } },
                [_c("operation-log", { attrs: { "edit-data": _vm.editData } })],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }