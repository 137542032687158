var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "wrapperForm",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        },
      },
    },
    [
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("driver.nameLabel"),
            "label-for": "name",
            message: _vm.v$.name.$errors.length
              ? _vm.v$.name.$errors[0].$message
              : null,
            variant: "error",
          },
        },
        [
          _c(
            "o-autocomplete",
            {
              attrs: {
                id: "name",
                disabled: _vm.isEdit,
                placeholder: _vm.$t("driver.modalNamePholder"),
                clearable: !_vm.isEdit,
                expanded: "",
                field: "name",
                data: _vm.listNames,
              },
              on: { input: _vm.filteredName, select: _vm.selectedName },
              model: {
                value: _vm.formData.name,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "name", $$v)
                },
                expression: "formData.name",
              },
            },
            [_c("template", { slot: "empty" }, [_vm._v("Sem resultados")])],
            2
          ),
        ],
        1
      ),
      _c(
        "o-field",
        {
          directives: [
            {
              name: "mask",
              rawName: "v-mask",
              value: ["+## (##) ####-####", "+## (##) #####-####"],
              expression: "['+## (##) ####-####', '+## (##) #####-####']",
            },
          ],
          attrs: {
            label: _vm.$t("driver.modalPhone"),
            "label-for": "phone",
            message: _vm.v$.phone.$errors.length
              ? _vm.v$.phone.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "phone",
              placeholder: _vm.$t("driver.modalPhonePholder"),
            },
            model: {
              value: _vm.formData.phone,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "phone", $$v)
              },
              expression: "formData.phone",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          directives: [
            {
              name: "mask",
              rawName: "v-mask",
              value: "###.###.###-##",
              expression: "'###.###.###-##'",
            },
          ],
          attrs: {
            label: _vm.$t("driver.cpfLabel"),
            "label-for": "document",
            message: _vm.v$.document.$errors.length
              ? _vm.v$.document.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "document",
              placeholder: _vm.$t("driver.modalCpfPholder"),
            },
            model: {
              value: _vm.formData.document,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "document", $$v)
              },
              expression: "formData.document",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          directives: [
            {
              name: "mask",
              rawName: "v-mask",
              value: "###########",
              expression: "'###########'",
            },
          ],
          attrs: {
            label: _vm.$t("driver.modalDriverLicence"),
            "label-for": "driverLicense",
            message: _vm.v$.driverLicense.$errors.length
              ? _vm.v$.driverLicense.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "driverLicense",
              placeholder: _vm.$t("driver.modalLicencePholder"),
            },
            model: {
              value: _vm.formData.driverLicense,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "driverLicense", $$v)
              },
              expression: "formData.driverLicense",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("driver.modalScoreLicence"),
            "label-for": "scoreLicense",
            message: _vm.v$.scoreLicense.$errors.length
              ? _vm.v$.scoreLicense.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "scoreLicense",
              placeholder: _vm.$t("driver.modalScoreLicencePholder"),
            },
            model: {
              value: _vm.formData.scoreLicense,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "scoreLicense", $$v)
              },
              expression: "formData.scoreLicense",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("driver.modalScoreDriver"),
            "label-for": "scoreDriver",
            message: _vm.v$.scoreDriver.$errors.length
              ? _vm.v$.scoreDriver.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "scoreDriver",
              placeholder: _vm.$t("driver.modalScoreDriverPholder"),
            },
            model: {
              value: _vm.formData.scoreDriver,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "scoreDriver", $$v)
              },
              expression: "formData.scoreDriver",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("driver.modalLicenseType"),
            "label-for": "licenseType",
            message: _vm.v$.licenseType.$errors.length
              ? _vm.v$.licenseType.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "licenseType",
              placeholder: _vm.$t("driver.modalLicenseTypePholder"),
            },
            model: {
              value: _vm.formData.licenseType,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "licenseType", $$v)
              },
              expression: "formData.licenseType",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("driver.modalLicenseValidate"),
            "label-for": "licenseValidate",
            message: _vm.v$.licenseValidate.$errors.length
              ? _vm.v$.licenseValidate.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "licenseValidate",
              placeholder: _vm.$t("scripting.validatyLicensePlaceholder"),
              type: "date",
            },
            model: {
              value: _vm.formData.licenseValidate,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "licenseValidate", $$v)
              },
              expression: "formData.licenseValidate",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("driver.modalBaseName"),
            "label-for": "providerName",
            message: _vm.v$.providerName.$errors.length
              ? _vm.v$.providerName.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: { id: "providerName", disabled: "" },
            model: {
              value: _vm.formData.providerName,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "providerName", $$v)
              },
              expression: "formData.providerName",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("driver.modalUsername"),
            "label-for": "username",
            message: _vm.v$.username.$errors.length
              ? _vm.v$.username.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: { id: "username", disabled: "" },
            model: {
              value: _vm.formData.username,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "username", $$v)
              },
              expression: "formData.username",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "o-checkbox",
            {
              model: {
                value: _vm.formData.outsourced,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "outsourced", $$v)
                },
                expression: "formData.outsourced",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("driver.modalOutsourcedDriver")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "FormActions",
        [
          _c("FormActionsPrevious", {
            attrs: { "is-first-step": _vm.isFirstStep },
            on: { handlePrevious: _vm.handlePrevious },
          }),
          _c("FormActionsNext", { attrs: { "is-last-step": _vm.isLastStep } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }