var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "box-card" },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [_c("span", [_vm._v(_vm._s(_vm.$t("receivablePayable.FeeBreakdown")))])]
      ),
      _c(
        "el-form",
        {
          ref: "addBatchFee",
          attrs: { model: _vm.addBatchFee, "label-width": "0", size: "small" },
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.addBatchFee.addBatchFeeList, border: "" } },
            [
              _c(
                "el-table-column",
                {
                  attrs: { width: "55", align: "center", fixed: "left" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          !scope.row.isAccept
                            ? _c(
                                "el-popconfirm",
                                {
                                  attrs: {
                                    title: _vm.$t("basicData.AreYouSureDelete"),
                                  },
                                  on: {
                                    confirm: function ($event) {
                                      return _vm.deleteCost(scope)
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    directives: [
                                      {
                                        name: "permit",
                                        rawName: "v-permit:remove",
                                        value:
                                          "btn:finance:payableManagement:feesPayable:addEdit",
                                        expression:
                                          "'btn:finance:payableManagement:feesPayable:addEdit'",
                                        arg: "remove",
                                      },
                                    ],
                                    staticClass: "el-icon-delete deleteStyle",
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("span", {
                      staticClass: "el-icon-plus addStyle",
                      on: {
                        click: function ($event) {
                          return _vm.addCost()
                        },
                      },
                    }),
                  ]),
                ],
                2
              ),
              _c(
                "el-table-column",
                {
                  attrs: {
                    prop: "feeName",
                    label: _vm.$t("basicData.ExpenseType"),
                    width: "180",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "formStyle" },
                            [
                              scope.row.isEdit === false
                                ? _c("div", [_vm._v(_vm._s(scope.row.feeName))])
                                : _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "addBatchFeeList." +
                                          scope.$index +
                                          ".feeCode",
                                        rules: {
                                          required: true,
                                          message: _vm.$t(
                                            "basicData.CannotBeEmpty"
                                          ),
                                          trigger: ["blur", "change"],
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            filterable: "",
                                            clearable: "",
                                            placeholder: _vm.$t(
                                              "operationCenter.PleaseSelect"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.addChangeFee(scope)
                                            },
                                          },
                                          model: {
                                            value: scope.row.feeCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "feeCode",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.feeCode",
                                          },
                                        },
                                        _vm._l(
                                          _vm.feeTypeOption,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.displayName,
                                                value: item.code,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticStyle: { color: "#f00", "margin-right": "5px" },
                        },
                        [_vm._v("*")]
                      ),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("basicData.ExpenseType"))),
                      ]),
                    ]),
                  ]),
                ],
                2
              ),
              _c("el-table-column", {
                attrs: {
                  prop: "isGenBill",
                  label: _vm.$t(
                    "receivablePayable.WhetherToGenerateAStatement"
                  ),
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              scope.row.isGenBill
                                ? _vm.$t("receivablePayable.Generated")
                                : _vm.$t("receivablePayable.NotGenerated")
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "isAccept",
                  label: _vm.$t("receivablePayable.WhetherToCheckOrNot"),
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              scope.row.isAccept
                                ? _vm.$t("basicData.Received")
                                : _vm.$t("basicData.NotReconciled")
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "chargeWeight",
                  label: _vm.$t("receivablePayable.BillableWeight"),
                  width: "100",
                },
              }),
              _c(
                "el-table-column",
                {
                  attrs: {
                    prop: "fee",
                    label: _vm.$t("receivablePayable.AmountDue"),
                    width: "140",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "formStyle" },
                            [
                              scope.row.isEdit === false
                                ? _c("div", [_vm._v(_vm._s(scope.row.fee))])
                                : _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "addBatchFeeList." +
                                          scope.$index +
                                          ".fee",
                                        rules: {
                                          required: true,
                                          suffix: 2,
                                          negative: "-?",
                                          validator: _vm.priceType,
                                          trigger: ["blur", "change"],
                                        },
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: scope.row.fee,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "fee", $$v)
                                          },
                                          expression: "scope.row.fee",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticStyle: { color: "#f00", "margin-right": "5px" },
                        },
                        [_vm._v("*")]
                      ),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("receivablePayable.AmountDue"))),
                      ]),
                    ]),
                  ]),
                ],
                2
              ),
              _c(
                "el-table-column",
                {
                  attrs: {
                    prop: "operateTime",
                    label: _vm.$t("newOrder.BuildTime"),
                    width: "220",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "formStyle" },
                            [
                              scope.row.isEdit === false
                                ? _c("div", [
                                    _vm._v(_vm._s(scope.row.operateTime)),
                                  ])
                                : _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "addBatchFeeList." +
                                          scope.$index +
                                          ".operateTime",
                                        rules: {
                                          required: true,
                                          message: _vm.$t(
                                            "basicData.CannotBeEmpty"
                                          ),
                                          trigger: ["blur", "change"],
                                        },
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "datetime",
                                          "value-format": "yyyy-MM-dd HH:mm:ss",
                                          placeholder: _vm.$t(
                                            "basicData.SelectADatetime"
                                          ),
                                        },
                                        model: {
                                          value: scope.row.operateTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "operateTime",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.operateTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticStyle: { color: "#f00", "margin-right": "5px" },
                        },
                        [_vm._v("*")]
                      ),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("newOrder.BuildTime"))),
                      ]),
                    ]),
                  ]),
                ],
                2
              ),
              _c("el-table-column", {
                attrs: {
                  prop: "create_user",
                  label: _vm.$t("receivablePayable.EnterThePerson"),
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "formStyle" }, [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.createUser[scope.row.createUser])
                            ),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm.addType === 1
                ? _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "flatMethod",
                        label: _vm.$t("receivablePayable.FlatSharingMethod"),
                        width: "180",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.isEdit === false
                                  ? _c("div", [
                                      _vm._v(_vm._s(scope.row.flatMethod)),
                                    ])
                                  : _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "addBatchFeeList." +
                                            scope.$index +
                                            ".flatMethod",
                                          rules: {
                                            required: true,
                                            message: _vm.$t(
                                              "basicData.CannotBeEmpty"
                                            ),
                                            trigger: ["blur", "change"],
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              filterable: "",
                                              clearable: "",
                                              placeholder: _vm.$t(
                                                "operationCenter.PleaseSelect"
                                              ),
                                            },
                                            model: {
                                              value: scope.row.flatMethod,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "flatMethod",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.flatMethod",
                                            },
                                          },
                                          [
                                            _c("el-option", {
                                              key: "0",
                                              attrs: {
                                                label: _vm.$t(
                                                  "receivablePayable.SpreadEquallyByWeight"
                                                ),
                                                value: "1",
                                              },
                                            }),
                                            _c("el-option", {
                                              key: "1",
                                              attrs: {
                                                label: _vm.$t(
                                                  "receivablePayable.SplitEquallyByTheNumberOfVotes"
                                                ),
                                                value: "2",
                                              },
                                            }),
                                            _c("el-option", {
                                              key: "2",
                                              attrs: {
                                                label: _vm.$t(
                                                  "receivablePayable.SpecifyTheAmount"
                                                ),
                                                value: "3",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3310892134
                      ),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("div", [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#f00",
                                "margin-right": "5px",
                              },
                            },
                            [_vm._v("*")]
                          ),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("receivablePayable.FlatSharingMethod")
                              )
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _c("el-table-column", {
                attrs: {
                  prop: "feeSource",
                  label: _vm.$t("receivablePayable.SourceOfFees"),
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              (_vm.FEE_SOURCE[scope.row.feeSource] &&
                                _vm.FEE_SOURCE[scope.row.feeSource].name) ||
                                ""
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "operator",
                  label: _vm.$t("basicData.Operator"),
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "formStyle" }, [
                          _c("div", [_vm._v(_vm._s(scope.row.operatorName))]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "remark",
                  label: _vm.$t("receivablePayable.remark"),
                  "min-width": "160",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.isEdit === false
                          ? _c("div", [_vm._v(_vm._s(scope.row.remark))])
                          : _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "addBatchFeeList." +
                                    scope.$index +
                                    ".remark",
                                  rules: {
                                    required: false,
                                    message: _vm.$t("basicData.CannotBeEmpty"),
                                    trigger: ["blur", "change"],
                                  },
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.pleaseInputContent"
                                    ),
                                  },
                                  model: {
                                    value: scope.row.remark,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "remark", $$v)
                                    },
                                    expression: "scope.row.remark",
                                  },
                                }),
                              ],
                              1
                            ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }