import request from '@/utils/request';

/**
 * 供应商查询分页
 */
export const supplierMainPage = data => {
  return request.post('/provider/page', data);
};

/**
 * 创建供货商信息
 */
export const addSupplierMain = data => {
  return request.post('/provider/add', data);
};

/**
 * 根据id查询供货商信息
 */
export const getSupplierMainInfo = (ids, isLoading = true) => {
  // return request.get(`/provider/get/${data}`);
  return request({
    url: `/provider/get/${ids}`,
    method: 'get',
    isLoading // 是否显示加载中参数
  });
};

/**
 * 查询供应商的所有供应商主体
 */
export const getSupplierBody = data => {
  return request.post(`/provider/getAllProviderBody`);
};

/**
 * 根据id更改供应商信息
 */
export const UpdateSupplierMain = data => {
  return request.post('/provider/update', data);
};
// 校验供应商简称是否重复
export const apiSelectProviderName = data => {
  return request.post('/provider/selectProviderName', data);
};
// 校验业务主体是否重复
export const apiSelectProviderBody = data => {
  return request.post('/provider/selectProviderBody', data);
};

/**
 * 根据id查询供货商信息
 */
export const getBankList = data => {
  return request.get(`/provider/getBankList`);
};

/**
 * @description: 供应商操作日志分页
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const providerOperationLogPage = data => {
  return request.post('/providerOperationLog/page', data);
};
/**
 * 供应商查询所有
 */
export const supplierMainList = data => {
  return request.get('/provider/list', data);
};
// 供货商状态
export const enableDisableButton = data => {
  return request.post('/provider/enableDisableButton', data);
};
