<template>
  <div id="app" :class="appSkin">
    <router-view />
    <!--    <keep-alive>-->
    <!--      <router-view v-if="$route.meta.keepAlive" />-->
    <!--    </keep-alive>-->
    <!--    <router-view v-if="!$route.meta.keepAlive" />-->
  </div>
</template>
<script>
import { getToken, removeToken } from '@/utils/auth';

export default {
  data() {
    return {
      appSkin: 'violet',
      time: 0
    };
  },
  watch: {
    $route: {
      handler: function(route) {
        if (route.path !== '/lock') {
          sessionStorage.setItem('lastHistory', route.path);
        }
      },
      immediate: true
    }
  },
  created() {
    if (localStorage.getItem('themes')) {
      const checkTheme = JSON.parse(localStorage.getItem('themes'));
      this.appSkin = checkTheme.name;
    } else {
      this.appSkin = 'default';
    }
  },
  mounted() {
    // 退出登录写进登录接口下
    // // sessionStorage.setItem('isLock', 1);
    // this.getCurrentTime();
    window.document.onmouseup = function() {
      const isToken = getToken();
      // console.log('token', isToken);
      if (!isToken && this.$router) {
        this.$router.replace({ path: '/login' });
      }
    };
  },
  methods: {
    getCurrentTime() {
      const overTime = new Date().getTime();
      sessionStorage.setItem('overTime', overTime);

      // console.log('查看调用--开始计时');
      this.time = setTimeout(() => {
        removeToken();
        this.$router.replace({ path: '/login' });
        // this.checkTimeout();
        // console.log('查看调用--超时清除');
        clearTimeout(this.time);
      }, 12 * 60 * 60 * 1000);
    },
    checkTimeout() {
      const timeOut = 12 * 60 * 60 * 1000;
      const currentTime = new Date().getTime(); // 更新当前时间
      const lastTime = sessionStorage.getItem('overTime');
      if (currentTime - lastTime > timeOut) {
        // 判断是否超时
        // setTimeout(() => {
        //   if (!localStorage.getItem('UnlockScreen') || localStorage.getItem('UnlockScreen') === 'false') {
        //     this.$router.replace({ path: '/lock' });
        //   }
        // }, 500);
      }
    }
  }
};
</script>
<style lang="scss">
.el-submenu__title i {
  color: #000;
}

.el-select-dropdown {
  //min-width: 0 !important;
  //width: 20%;
}

// 默认白色
#app.default {
  .sidebar-container {
    border-right: 1px solid #e4e7ed !important;
    box-sizing: border-box;

    .sidebar-logo-container {
      background-color: #fff !important;
    }

    .el-scrollbar,
    .el-submenu__title,
    .el-menu-item {
      background-color: #fff !important;
      min-height: 56px;
    }

    .el-menu-item:hover,
    .nest-menu .el-submenu > .el-submenu__title:hover,
    .el-submenu .el-menu-item:hover,
    .el-submenu__title:hover {
      background-color: #ececec !important;
    }

    .nest-menu .el-submenu > .el-submenu__title,
    .el-submenu .el-menu-item {
      background-color: #fff !important;
    }

    .el-menu-item.is-active {
      color: #000 !important;
      font-weight: bold;
      font-size: 15px;
    }
  }

  .navbar {
    background: #fff;
  }

  .navbar * {
    color: #000;
  }

  .navbar .msgNumCon {
    background: #fff !important;
  }
}

#app.redBlue {
  .sidebar-container {
    .sidebar-logo-container {
      background-color: #f00;
    }

    .el-scrollbar,
    .el-submenu__title,
    .el-menu-item {
      background-color: #0d47a1 !important;
    }

    .el-menu-item:hover,
    .nest-menu .el-submenu > .el-submenu__title:hover,
    .el-submenu .el-menu-item:hover,
    .el-submenu__title:hover {
      background-color: #0a2546 !important;
    }

    .nest-menu .el-submenu > .el-submenu__title,
    .el-submenu .el-menu-item {
      background-color: #042e61 !important;
    }

    .is-active {
      color: #ffda00 !important;
    }
  }

  .navbar {
    background: #f00;
  }

  .navbar * {
    color: #fff !important;
  }

  .navbar .msgNumCon {
    background: #e6a23c !important;
  }
}

#app.blueRed {
  .sidebar-container {
    .sidebar-logo-container {
      background-color: #042e61;
    }

    .el-scrollbar,
    .el-submenu__title,
    .el-menu-item {
      background-color: #0d47a1 !important;
    }

    .el-menu-item:hover,
    .nest-menu .el-submenu > .el-submenu__title:hover,
    .el-submenu .el-menu-item:hover,
    .el-submenu__title:hover {
      background-color: #0a2546 !important;
    }

    .nest-menu .el-submenu > .el-submenu__title,
    .el-submenu .el-menu-item {
      background-color: #042e61 !important;
    }

    .is-active {
      color: #ffda00 !important;
    }
  }

  .navbar {
    background: #f00;
  }

  .navbar * {
    color: #fff !important;
  }

  .navbar .msgNumCon {
    background: #e6a23c !important;
  }
}

#app.luxuryGold {
  .sidebar-container {
    .sidebar-logo-container {
      background-color: #c33f06;
    }

    .el-scrollbar,
    .el-submenu__title,
    .el-menu-item {
      color: #fff !important;
      background-color: #f57842 !important;
    }

    .el-menu-item:hover,
    .nest-menu .el-submenu > .el-submenu__title:hover,
    .el-submenu .el-menu-item:hover,
    .el-submenu__title:hover {
      background-color: #96340a !important;
    }

    .nest-menu .el-submenu > .el-submenu__title,
    .el-submenu .el-menu-item {
      background-color: #9e3b11 !important;
    }

    .is-active {
      color: #ffda00 !important;
    }
  }

  .navbar {
    background: #aaa;
  }

  .navbar * {
    color: #fff !important;
  }
}

#app.green {
  .sidebar-container {
    .sidebar-logo-container {
      background-color: #026502;
    }

    .el-scrollbar,
    .el-submenu__title,
    .el-menu-item {
      color: #fff !important;
      background-color: green !important;
    }

    .el-menu-item:hover,
    .nest-menu .el-submenu > .el-submenu__title:hover,
    .el-submenu .el-menu-item:hover,
    .el-submenu__title:hover {
      background-color: #014e01 !important;
    }

    .nest-menu .el-submenu > .el-submenu__title,
    .el-submenu .el-menu-item {
      background-color: #016101 !important;
    }

    .is-active {
      color: #ffda00 !important;
    }
  }

  .navbar {
    background: #aaa;
  }

  .navbar * {
    color: #fff !important;
  }
}

#app.violet {
  .sidebar-container {
    .sidebar-logo-container {
      background-color: #bc03cb;
    }

    //.sidebar-title{
    //  color: #000;
    //}
    .el-scrollbar,
    .el-submenu__title,
    .el-menu-item {
      color: #fff !important;
      background-color: #e303f5 !important;
    }

    .el-menu-item:hover,
    .nest-menu .el-submenu > .el-submenu__title:hover,
    .el-submenu .el-menu-item:hover,
    .el-submenu__title:hover {
      background-color: #9503a2 !important;
    }

    .nest-menu .el-submenu > .el-submenu__title,
    .el-submenu .el-menu-item {
      background-color: #9d06ab !important;
    }

    .is-active {
      color: #03f51c !important;
    }
  }

  .navbar {
    background: #aaa;
  }

  .navbar * {
    color: #fff !important;
  }
}

//  主题暂时这样替换，后续使用js 进行动态处理
#app.ui {
  .sidebar-container {
    .sidebar-logo-container {
      background-color: #2b2f3a;
    }

    //.sidebar-title{
    //  color: #000;
    //}
    .el-scrollbar,
    .el-submenu__title,
    .el-menu-item {
      color: #fff !important;
      background-color: #37547d !important;
    }

    .el-menu-item:hover,
    .nest-menu .el-submenu > .el-submenu__title:hover,
    .el-submenu .el-menu-item:hover,
    .el-submenu__title:hover {
      background-color: #001528 !important;
    }

    .nest-menu .el-submenu > .el-submenu__title,
    .el-submenu .el-menu-item {
      background-color: #1f2d3d !important;
    }

    .is-active {
      // color: #fff!important;
      color: rgb(64, 158, 255) !important;
    }
  }

  .navbar {
    background: #2b2f3a;
  }

  .navbar * {
    // color: #fff!important;
    color: #bfcbd9 !important;
  }
}
</style>
