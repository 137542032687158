export const order = {
  // tableData
  generatedOrders: {
    zh: '已生成的订单',
    en: '',
    pu: 'Ordens Geradas'
  },
  downloadMaster: {
    zh: '下载主文件',
    en: '',
    pu: 'Download master'
  },
  createOrder: {
    zh: '创建订单',
    en: '',
    pu: 'Criar ordem'
  },
  orderDate: {
    zh: '订单日期',
    en: '',
    pu: 'Data da ordem'
  },
  orderDatePlaceholder: {
    zh: '选择日期',
    en: '',
    pu: 'Selecione a data'
  },
  initialKilometers: {
    zh: '初始公里数',
    en: '',
    pu: 'Quilômetro inicial'
  },
  arrivalKilometers: {
    zh: '到达公里数',
    en: '',
    pu: 'Quilômetro final'
  },
  totalKilometers: {
    zh: '总公里数',
    en: '',
    pu: 'Quilômetro total'
  },
  driver: {
    zh: '司机',
    en: '',
    pu: 'Motorista'
  },
  licensePlate: {
    zh: '车牌号',
    en: '',
    pu: 'Placa'
  },
  totalPackages: {
    zh: '总包裹数量',
    en: '',
    pu: 'Quantidade de pacotes'
  },
  noResults: {
    zh: '没有结果',
    en: '',
    pu: 'Sem resultados'
  },
  createOrderTitle: {
    zh: '生成订单',
    en: '',
    pu: 'Gerando ordem'
  },
  createOrderParagraph: {
    zh: '请填写下方的字段以在我们的系统中生成收集订单。',
    en: '',
    pu: 'Preencha os campos logo abaixo para conseguir gerar um ordem de coleta no nosso sistema'
  },

  // formVehicle
  searchVehicle: {
    zh: '搜索车辆',
    en: '',
    pu: 'Procurar veículo'
  },
  vehiclePlate: {
    zh: '车牌号',
    en: '',
    pu: 'Placa do veículo'
  },
  vehiclePlatePlaceholder: {
    zh: '搜索车辆文件',
    en: '',
    pu: 'Busque pelo documento do veículo'
  },
  document: {
    zh: '文件',
    en: '',
    pu: 'Documento'
  },
  capacityM3: {
    zh: '容量（立方米）',
    en: '',
    pu: 'Capacidade (m³)'
  },
  capacityKg: {
    zh: '容量（公斤）',
    en: '',
    pu: 'Capacidade (kg)'
  },
  createVehicle: {
    zh: '创建车辆',
    en: '',
    pu: 'Criar veículo'
  },
  vehicleData: {
    zh: '车辆数据',
    en: '',
    pu: 'Dados do veículo'
  },
  measurementData: {
    zh: '测量数据',
    en: '',
    pu: 'Dados de medida'
  },
  additionalData: {
    zh: '额外数据',
    en: '',
    pu: 'Dados Adicionais'
  },

  // formTrailer
  trailerPlate: {
    zh: '挂车车牌',
    en: '',
    pu: 'Placa da carreta'
  },
  trailerPlatePlaceholder: {
    zh: '搜索拖车文件',
    en: '',
    pu: 'Busque pelo documento da carreta'
  }
};
