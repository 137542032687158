<template>
  <div class="detialInfo">
    <el-collapse v-model="activeNames">
      <!--运单基本信息-->
      <el-collapse-item :title="$t('newOrder.BasicInformationWaybill')" name="1">
        <el-card class="box-card">
          <basicInfo :table-infomation="tableInfomation" />
        </el-card>
      </el-collapse-item>
      <!--签收信息-->
      <el-collapse-item :title="$t('GlobalSetObj.qinashouInfo')" name="2">
        <div class="signeCss">{{ $t('GlobalSetObj.zhengxiang') }}</div>
        <el-card class="box-card">
          <signeInfo :query-track-param="queryTrackParam" :table-infomation="tableInfomation" />
        </el-card>
        <div class="signeCss" style="margin-top: 20px">{{ $t('GlobalSetObj.nixiang') }}</div>
        <el-card class="box-card">
          <signeInfo1 :query-track-param="queryTrackParam" :table-infomation="tableInfomation" />
        </el-card>
      </el-collapse-item>
      <!--发票信息-->
      <!--      <el-collapse-item :title="$t('newOrder.InvoiceInformation')" name="3">-->
      <!--        <el-card class="box-card">-->
      <!--          <invoice-info :query-track-param="queryTrackParam" :table-infomation="tableInfomation" @rebuildMinutaChange="rebuildMinutaChange" />-->
      <!--        </el-card>-->
      <!--      </el-collapse-item>-->
      <!--子单发票信息-->
      <el-collapse-item :title="$t('newOrder.InvoiceInformation')" name="4">
        <el-card class="box-card">
          <child-order-invoice-info
            :query-track-param="queryTrackParam"
            :waybill-invoice-v-o-list="waybillInvoiceVOList"
          />
        </el-card>
      </el-collapse-item>
      <!--收件人信息-->
      <el-collapse-item :title="$t('orderCenterCont.RecipientInformation')" name="5">
        <el-card class="box-card">
          <RecipientsInfo
            :encryption-receiver-info="encryptionReceiverInfo"
            :receiver-info="tableInfomation.receiverInfo"
          />
        </el-card>
      </el-collapse-item>
      <!--商品信息-->
      <el-collapse-item :title="$t('orderCenterCont.ProductInformation')" name="6">
        <el-card class="box-card">
          <GoodsInfo :goods-table-data="goodsTableData" />
        </el-card>
      </el-collapse-item>
      <!--发件人信息-->
      <el-collapse-item :title="$t('orderCenterCont.SenderInformation')" name="7">
        <el-card class="box-card">
          <senderInfo :encryption-sender-info="encryptionSenderInfo" :sender-info="tableInfomation.senderInfo" />
        </el-card>
      </el-collapse-item>
      <!--理赔信息-->
      <el-collapse-item
        v-if="tableInfomation.orderWaybillClaimVO"
        :title="$t('orderCenterCont.claimInformation')"
        name="8"
      >
        <el-card class="box-card">
          <claimInformation
            :orderwaybillclaim="tableInfomation.orderWaybillClaimVO"
          />
        </el-card>
      </el-collapse-item>
      <!-- 取消订单凭证 -->
      <el-collapse-item
        v-if="tableInfomation.cancelOrderPictureList"
        :title="$t('orderCenterCont.cancelPedido')"
        name="20"
      >
        <el-card class="box-card">
          <el-descriptions :colon="false" :column="3" direction="vertical">
            <!--签收照片-->
            <el-descriptions-item
              v-for="(item,index) in tableInfomation.cancelOrderPictureList"
              :key="index"
              :label="`${$t('orderCenterCont.Pedido1')+(index+1)}`"
              :span="1"
            >
              <div :style="`${$i18n.locale !== 'zh' ? 'width:50px' : 'width:60px'}`">
                <el-tooltip
                  class="QuestionMark"
                  placement="top"
                >
                  <div slot="content">
                    <img
                      :src="item"
                      alt=""
                      class="image"
                      style="width: 300px;margin: 5px"
                    >
                  </div>
                  <div style="display: flex;align-items: center;justify-content: center;">
                    <span>{{ $t('GlobalSetObj.see') }}</span>
                    <svg-icon icon-class="iconEye" />
                  </div>
                </el-tooltip>
              </div>
            </el-descriptions-item>
          </el-descriptions>
        </el-card>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import basicInfo from '@/views/orderCenter/logisticsOrders/components/detailBox/components/basicInfo';
import RecipientsInfo from '@/views/orderCenter/logisticsOrders/components/detailBox/components/recipientsInfo';
import GoodsInfo from '@/views/orderCenter/logisticsOrders/components/detailBox/components/goodsInfo';
import senderInfo from '@/views/orderCenter/logisticsOrders/components/detailBox/components/senderInfo';
import signeInfo from '@/views/orderCenter/logisticsOrders/components/detailBox/components/SignInformation';
import signeInfo1 from '@/views/orderCenter/logisticsOrders/components/detailBox/components/SignInformation1';
import childOrderInvoiceInfo
from '@/views/orderCenter/logisticsOrders/components/detailBox/components/childOrderInvoiceInfo.vue';
import claimInformation from '@/views/orderCenter/logisticsOrders/components/detailBox/components/claimInformation.vue';
import { getOrderInfo } from '@/api/logisticsOrders/index.js';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'Detail',
  components: {
    basicInfo,
    RecipientsInfo,
    GoodsInfo,
    senderInfo,
    signeInfo,
    signeInfo1,
    childOrderInvoiceInfo,
    claimInformation
  },
  props: {
    queryTrackParam: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      activeNames: ['1', '2', '3', '4', '5', '6'],
      tableInfomation: {},
      goodsTableData: [],
      waybillInvoiceVOList: [], // 合单发票数据
      encryptionReceiverInfo: {},
      encryptionSenderInfo: {}
    };
  },
  mounted() {
    this.getOrderInfoFun();
  },
  destroyed() {
    this.bus.$off('todetailBox');
  },
  methods: {
    // 重新生成Minuta后刷新数据
    rebuildMinutaChange() {
      this.getOrderInfoFun();
    },
    // 获取订单详情
    getOrderInfoFun() {
      getOrderInfo(this.queryTrackParam.id).then((res) => {
        if (res.status === 'OK') {
          this.tableInfomation = res.data;
          if (this.tableInfomation.signType === 0) {
            this.tableInfomation.signTypeName = this.$t('GlobalSetObj.Myself');
          } else if (this.tableInfomation.signType === 1) {
            this.tableInfomation.signTypeName = this.$t('GlobalSetObj.NotMyself');
          } else {
            this.tableInfomation.signTypeName = '--';
          }
          if (this.tableInfomation.reverseSignType === 0) {
            this.tableInfomation.reverseSignTypeName = this.$t('GlobalSetObj.Myself');
          } else if (this.tableInfomation.reverseSignType === 1) {
            this.tableInfomation.reverseSignTypeName = this.$t('GlobalSetObj.NotMyself');
          } else {
            this.tableInfomation.reverseSignTypeName = '--';
          }
          res.data.orderItemList.forEach((item) => {
            const objSize = {};
            const declared = {};
            objSize.length = item.length;
            objSize.width = item.width;
            objSize.height = item.height;
            declared.declaredValue = item.declaredValue;
            declared.declaredWeight = item.declaredWeight;
            item.objSize = objSize;
            item.declared = declared;
            item.goodName = item.name;
          });
          this.goodsTableData = res.data.orderItemList;
          // 合单发票信息
          this.waybillInvoiceVOList = res.data.waybillInvoiceVOList;
          const copyObj = cloneDeep(this.tableInfomation);
          this.encryptionReceiverInfo = copyObj.receiverInfo;
          this.encryptionSenderInfo = copyObj.senderInfo;
          this.encryptionReceiverInfo.receiveTax = this.encryptionReceiverInfo.receiveTax ? '*'.repeat(this.encryptionReceiverInfo.receiveTax.length) : '';
          this.encryptionReceiverInfo.receiveCompany = this.encryptionReceiverInfo.receiveCompany ? '*'.repeat(this.encryptionReceiverInfo.receiveCompany.length) : '';
          this.encryptionReceiverInfo.receiveName = this.encryptionReceiverInfo.receiveName ? '*'.repeat(this.encryptionReceiverInfo.receiveName.length) : '';
          this.encryptionReceiverInfo.receivePhone = this.encryptionReceiverInfo.receivePhone ? this.encryptionReceiverInfo.receivePhone.replace(/^(...).*(.{4})$/, '$1****$2') : '';
          if (this.encryptionReceiverInfo.receiveMobile) {
            if (this.encryptionReceiverInfo.receiveMobile.length < 7) {
              this.encryptionReceiverInfo.receiveMobile = this.encryptionReceiverInfo.receiveMobile.slice(0, 3) + '*'.repeat(this.encryptionReceiverInfo.receiveMobile.length - 3);
            } else {
              this.encryptionReceiverInfo.receiveMobile = this.encryptionReceiverInfo.receiveMobile.replace(/^(...).*(.{4})$/, '$1****$2');
            }
          } else {
            this.encryptionReceiverInfo.receiveMobile = '';
          }
          // this.encryptionReceiverInfo.receiveMobile = this.encryptionReceiverInfo.receiveMobile ? this.encryptionReceiverInfo.receiveMobile.replace(/^(...).*(.{4})$/, '$1****$2') : '';
          this.encryptionReceiverInfo.receiveCertificateCode = this.encryptionReceiverInfo.receiveCertificateCode ? this.encryptionReceiverInfo.receiveCertificateCode.replace(/^(...).*(.{4})$/, '$1****$2') : '';
          if (this.encryptionReceiverInfo.receiveMail) {
            const str = this.encryptionReceiverInfo.receiveMail;
            const atIndex = str.indexOf('@');
            this.encryptionReceiverInfo.receiveMail = str.slice(0, atIndex).replace(/./g, '*') + str.slice(atIndex);
          }
          this.encryptionSenderInfo = copyObj.senderInfo;
          this.encryptionSenderInfo.senderName = this.encryptionSenderInfo.senderName ? '*'.repeat(this.encryptionSenderInfo.senderName.length) : '';
          this.encryptionSenderInfo.senderTax = this.encryptionSenderInfo.senderTax ? '*'.repeat(this.encryptionSenderInfo.senderTax.length) : '';
          this.encryptionSenderInfo.senderCompany = this.encryptionSenderInfo.senderCompany ? '*'.repeat(this.encryptionSenderInfo.senderCompany.length) : '';
          this.encryptionSenderInfo.senderPhone = this.encryptionSenderInfo.senderPhone ? this.encryptionSenderInfo.senderPhone.replace(/^(...).*(.{4})$/, '$1****$2') : '';
          if (this.encryptionSenderInfo.senderMobile) {
            if (this.encryptionSenderInfo.senderMobile.length < 7) {
              this.encryptionSenderInfo.senderMobile = this.encryptionSenderInfo.senderMobile.slice(0, 3) + '*'.repeat(this.encryptionSenderInfo.senderMobile.length - 3);
            } else {
              this.encryptionSenderInfo.senderMobile = this.encryptionSenderInfo.senderMobile.replace(/^(...).*(.{4})$/, '$1****$2');
            }
          } else {
            this.encryptionSenderInfo.senderMobile = '';
          }
          // this.encryptionSenderInfo.senderMobile = this.encryptionSenderInfo.senderMobile ? this.encryptionSenderInfo.senderMobile.replace(/^(...).*(.{4})$/, '$1****$2') : '';
          this.encryptionSenderInfo.senderCertificateCode = this.encryptionSenderInfo.senderCertificateCode ? this.encryptionSenderInfo.senderCertificateCode.replace(/^(...).*(.{4})$/, '$1****$2') : '';
          if (this.encryptionSenderInfo.senderMail) {
            const str = this.encryptionSenderInfo.senderMail;
            const atIndex = str.indexOf('@');
            this.encryptionSenderInfo.senderMail = str.slice(0, atIndex).replace(/./g, '*') + str.slice(atIndex);
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.detialInfo /deep/ .el-collapse-item__header {
  color: #409eff;
  font-size: 16px;
}

.detialInfo /deep/ .el-descriptions-item__label {
  font-size: 13px;
  color: #909399;
}

.detialInfo /deep/ .el-descriptions-item__cell {
  /*height: 35px !important;*/
  padding-bottom: 2px !important;
}

.QuestionMark {
  cursor: pointer;
  color: #409eff;
}

.el-descriptions-item__cell div {
  font-weight: bold;
  font-size: 15px;
}

.detialInfo {
  /*height: calc(100vh - 90px);*/
  /*overflow-y: auto;*/
  /*padding: 10px;*/
}

.signeCss {
  font-size: 16px;
  font-weight: bold;
}
</style>
