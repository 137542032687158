var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WrapperHandleError",
    { attrs: { error: _vm.error, "is-error": _vm.isError } },
    [
      _c("SectionHeader", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("h1", { staticClass: "header-1" }, [
                  _vm._v(_vm._s(_vm.$t("driver.driverTitle"))),
                ]),
                _c(
                  "o-button",
                  {
                    attrs: { variant: "primary", size: "md" },
                    on: { click: _vm.handleVisibilityModalCreate },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("driver.createDriver")) + " ")]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "filter",
            fn: function () {
              return [
                _c(
                  "o-field",
                  {
                    staticClass: "flex-1",
                    attrs: {
                      label: _vm.$t("driver.cpfLabel"),
                      "label-for": "cpf",
                    },
                  },
                  [
                    _c("o-input", {
                      attrs: {
                        id: "cpf",
                        placeholder: _vm.$t("driver.cpfPlaceholder"),
                      },
                      model: {
                        value: _vm.filters.cpf,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "cpf", $$v)
                        },
                        expression: "filters.cpf",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "o-field",
                  {
                    staticClass: "flex-1",
                    attrs: {
                      label: _vm.$t("driver.nameLabel"),
                      "label-for": "name",
                    },
                  },
                  [
                    _c("o-input", {
                      attrs: {
                        id: "name",
                        placeholder: _vm.$t("driver.namePlaceholder"),
                      },
                      model: {
                        value: _vm.filters.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "name", $$v)
                        },
                        expression: "filters.name",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "o-field",
                  {
                    staticClass: "flex-1",
                    attrs: {
                      label: _vm.$t("driver.statusDriver"),
                      "label-for": "status",
                    },
                  },
                  [
                    _c(
                      "o-select",
                      {
                        attrs: {
                          id: "status",
                          placeholder: "Selecione o status",
                        },
                        model: {
                          value: _vm.filters.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "status", $$v)
                          },
                          expression: "filters.status",
                        },
                      },
                      [
                        _c("option", { domProps: { value: 1 } }, [
                          _vm._v(_vm._s(_vm.$t("driver.activeDrivers"))),
                        ]),
                        _c("option", { domProps: { value: 0 } }, [
                          _vm._v(_vm._s(_vm.$t("driver.disabledDrivers"))),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "SkeletonTable",
        { attrs: { "is-loading": _vm.isLoadingTable } },
        [
          _c(
            "o-table",
            {
              staticClass: "space-m-y-32",
              attrs: {
                data: _vm.tableData,
                "row-key": "id",
                bordered: "",
                paginated: "",
                "backend-pagination": "",
                total: _vm.tableTotal,
                "per-page": _vm.queryParams.perPage,
                "current-page": _vm.queryParams.currentPage,
                "pagination-simple": false,
              },
              on: {
                "update:currentPage": function ($event) {
                  return _vm.$set(_vm.queryParams, "currentPage", $event)
                },
                "update:current-page": function ($event) {
                  return _vm.$set(_vm.queryParams, "currentPage", $event)
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "empty",
                  fn: function () {
                    return [_vm._v(" Sem resultados ")]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("o-table-column", {
                attrs: {
                  field: "driver_document",
                  label: _vm.$t("driver.document"),
                  width: "260",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(" " + _vm._s(props.row.driver_document) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "driver_name",
                  label: _vm.$t("driver.nameLabel"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [_vm._v(" " + _vm._s(props.row.driver_name) + " ")]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "licenseType",
                  label: _vm.$t("driver.cnhType"),
                  width: "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(
                          " " + _vm._s(props.row.driver_license_category) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "driver_score",
                  label: _vm.$t("driver.driverPoints"),
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(" " + _vm._s(props.row.driver_score) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "driver_outsourced",
                  label: _vm.$t("driver.outsourcing"),
                  width: "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              props.row.driver_outsourced
                                ? _vm.$t("driver.yes")
                                : _vm.$t("driver.no")
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: { label: "", width: "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("Actions", {
                          attrs: { status: !!_vm.filters.status, row: row },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "o-modal",
        {
          attrs: { active: _vm.isOpenModalCreate, width: "560" },
          on: { close: _vm.handleVisibilityModalCreate },
        },
        [
          _c("div", [
            _c("h2", { staticClass: "header-2" }, [
              _vm._v(_vm._s(_vm.$t("driver.modalTitle"))),
            ]),
            _c("p", { staticClass: "paragraph" }, [
              _vm._v(_vm._s(_vm.$t("driver.modalParagraph"))),
            ]),
          ]),
          _c("FormCreateDriver", {
            attrs: { "close-modal": _vm.handleVisibilityModalCreate },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }