var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          staticStyle: { "margin-top": "10px" },
          attrs: { size: "small", type: "primary" },
          on: {
            click: function ($event) {
              _vm.dialogVisible = true
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("receivablePayable.ImportReceivableGeneration")))]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("receivablePayable.ImportReceivableGeneration"),
            visible: _vm.dialogVisible,
            width: "500px",
            "append-to-body": "",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "20px" } },
            [
              _c("div", { staticStyle: { "margin-bottom": "5px" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("receivablePayable.SelectGenerateAccrualDate")
                    ) +
                    "： "
                ),
              ]),
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "end-placeholder": _vm.$t("operationCenter.endDate"),
                  "start-placeholder": _vm.$t("operationCenter.startDate"),
                  "range-separator": "-",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.TimeCollection,
                  callback: function ($$v) {
                    _vm.TimeCollection = $$v
                  },
                  expression: "TimeCollection",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "10px" },
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.downloadTemplate },
                },
                [_vm._v(_vm._s(_vm.$t("basicData.DownloadTheTemplate")))]
              ),
              _c("upload-excel", {
                attrs: {
                  "but-name": _vm.$t("operationCenter.Import"),
                  "on-success": _vm.importLogistics,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }