<template>
  <div class="cameraUpload">
    <el-upload
      ref="changeImgList"
      multiple
      :limit="5"
      :file-list="fileList"
      list-type="picture-card"
      :before-upload="beforeUpload"
      :http-request="uploadDataClick"
      action
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :on-exceed="handleExceed"
    >
      <i class="el-icon-plus" />
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img :src="dialogImageUrl" alt="" style="width: 100%; height: auto;margin: 0 auto;display: inherit;">
    </el-dialog>
  </div>
</template>
<script>
import { uploadOos } from '@/utils/ali_oos_util';
import { getTimes } from '@/utils/watermark/watermark_time';
export default {
  props: {
    fileList1: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      fileList: this.fileList1,
      dialogImageUrl: null, // 点击打开该图
      dialogVisible: false // 打开当前图的弹窗
    };
  },
  methods: {
    handleRemove(file, fileList) {
      this.fileList = fileList;
      this.$emit('uploadImg', this.fileList);
    },
    handlePreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleExceed() {
      // 最多上传5张照片
      this.$message.warning(this.$t('collectionCenter.upload5Photos'));
    },
    beforeUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1);
      const whiteList = ['png', 'bmp', 'jpg', 'jpeg'];
      if (whiteList.indexOf(fileSuffix) === -1) {
        // 仅支持png、bmp、jpg格式的图片
        this.$message.warning(this.$t('collectionCenter.OnlyImageFormatIsSupported'));
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        // 上传文件大小不能超过 2MB
        this.$message.warning(this.$t('collectionCenter.uploadNot2MB'));
        return false;
      }
      return new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file); // file转base64
        reader.onload = e => {
          const canvas = document.createElement('canvas');
          const img = new Image();
          img.src = e.target.result;
          img.onload = () => {
            // 图片加载后再添加水印，否则可能报错
            const width = img.width;
            const height = img.height;
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d'); // 绘制2d图形
            ctx.drawImage(img, 0, 0, width, height);
            var basePx = canvas.width;
            // 字体大小  照片添加水印
            var fontSize = basePx / 28; // 水印文字尺寸
            ctx.font = fontSize + 'px Open Sans';
            ctx.fillStyle = 'rgba(255,255,255, 0.5)';
            ctx.shadowOffsetY = 4;
            ctx.shadowBlur = 2;
            ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
            let time = getTimes();
            time = time.split(' ');
            const fontHeight = ctx.measureText(time[0]).fontBoundingBoxAscent + ctx.measureText(time[0]).fontBoundingBoxDescent;
            ctx.fillText(time[0], width - ctx.measureText(time[0]).width - 20, fontHeight);
            ctx.fillText(time[1], width - ctx.measureText(time[1]).width - 20, fontHeight * 2);
            var dataBase64 = canvas.toDataURL(file.type); // 输出压缩后的base64
            // base64转file
            const arr = dataBase64.split(',');
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
            }
            const files = new File(
              [new Blob([u8arr], { type: mime })],
              file.name,
              { type: file.type }
            );
            files.uid = file.uid;
            resolve(files);
          };
        };
      });
    },
    uploadDataClick(option) { // 导入xlsx模版 // 上传数据
      const files = option.file;
      if (files) {
        uploadOos(files, 'car').then(res => {
          if (res.code === 200) {
            this.dialogImageUrl = res.url;
            const obj = {
              name: files.name,
              url: res.url
            };
            this.fileList = [...this.fileList, obj];
            this.$emit('uploadImg', this.fileList);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
  .cameraUpload ::v-deep .el-upload-list__item.is-ready{
    display: none !important;
  }
  .cameraUpload ::v-deep .el-upload--picture-card{
    position: relative;
  }
  .cameraUpload ::v-deep .el-icon-plus{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .cameraUpload ::v-deep .el-upload--picture-card, .cameraUpload ::v-deep .el-upload-list--picture-card .el-upload-list__item{
    width: 80px;
    height: 80px;
  }
</style>
