import { requestScripting } from '@/lib/axios';
import clearString from '@/utils/clearString';

import { removeNullParams } from '@/utils/removeNullParams';

export const trailerService = {
  async delete(id) {
    return requestScripting({
      method: 'DELETE',
      url: `trailer/${id}`
    });
  },
  async getAll(skip = 0, take = 10, plate, status = 1) {
    return requestScripting({
      method: 'GET',
      url: `trailer`,
      params: removeNullParams({
        skip,
        take,
        plate,
        active: status
      })
    });
  },
  async get(id) {
    return requestScripting({
      method: 'GET',
      url: `trailer/${id}`
    });
  },
  async create(data) {
    const body = this.getBody(data);

    return requestScripting({
      method: 'POST',
      data: body,
      url: 'trailer'
    });
  },
  async edit(data, id, isFormatedBody = true) {
    const body = isFormatedBody ? this.getBody(data) : data;

    return requestScripting({
      method: 'PATCH',
      data: body,
      url: `trailer/${id}`
    });
  },
  getBody(data) {
    const clearDocumentOwner = clearString(data.documentOwner);
    const typeDocument = clearDocumentOwner.length === 11 ? 0 : 1;

    return {
      'trailer_documentation': data.document,
      'provider_name': data.provider,
      'trailer_license_plate': clearString(data.licensePlate),
      'trailer_brand': data.brand,
      'trailer_type_ownership': typeDocument,
      'trailer_owner_CNPJ': typeDocument === 1 ? clearDocumentOwner : null,
      'trailer_owner_CPF': typeDocument === 0 ? clearDocumentOwner : null,
      'trailer_state_registration': data.stateRegistration,
      'trailer_RNTRC': data.rntrc,
      'trailer_capacity_m3': data.capacityM3,
      'trailer_capacity_kg': data.capacityKg,
      'trailer_tare_weight': data.weight,
      'trailer_body_type': data.bodyType,
      'trailer_tracker_company_name': data.trackerCompany,
      'trailer_tracker_id': data.trackerCompanyCode,
      'trailer_state': data.state,
      'trailer_country': 'BR',
      'trailer_outsourced': data.outsourced ? 1 : 0,
      'trailer_is_active': 1
    };
  }
};
