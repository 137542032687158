<template>
  <div class="edit-table">
    <el-table
      ref="multipleTable"
      :cell-style="{'text-align':'center'}"
      :data="tableData"
      :header-cell-style="{'text-align':'center'}"
      border
      class="elTable"
      row-key="id"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <!-- 序列号/复选框 -->

      <el-table-column
        v-if="isSelectBtn"
        :reserve-selection="true"
        align="center"
        type="selection"
        width="50"
      />
      <el-table-column
        v-if="isIndex"
        :label="$t('newOrder.SerialNumber')"
        align="center"
        type="index"
        width="70"
      >
        <template slot-scope="scope">
          <span>{{ scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <template v-for="item in headerList">
        <el-table-column
          v-if="item.prop === 'UnloadingpointState'"
          :key="item.id"
          min-width="100"
          prop="Mdfestate"
        >
          <template slot="header">
            <div class="header-container">
              <span style="margin-right: 10px">{{ $t('GlobalSetObj.state') }}</span>
              <el-tooltip placement="top">
                <div slot="content">{{ $t('GlobalSetObj.MDFEuningDot') }}</div>
                <svg-icon icon-class="QuestionMark" />
              </el-tooltip>
            </div>
          </template>
          <template slot-scope="scope">
            <div>{{ scope.row.UnloadingpointState || '- -' }}</div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.prop!=='UnloadingpointState'"
          :key="item.id"
          :label="item.label"
          :min-width="item.cloWidth"
          :prop="item.prop"
          :show-overflow-tooltip="isTip"
          label-class-name="DisabledSelection"
        >
          <template slot-scope="scope">
            <!--    运单号        -->
            <div
              v-if="item.prop === 'waybillNumber'
                || item.prop === 'customerNumber'
                || item.prop === 'transitBagNumber'
                || item.prop === 'routeNumber'
                || item.prop === 'instoreBigBagNo'
                || item.prop === 'logisticsNumber'"
              :class="[hide?'multiline':'']"
            >
              <el-popover
                placement="top"
                popper-class="copy"
                trigger="hover"
              >
                <el-button
                  style="color: black;font-size: 12px;padding: 2px"
                  type="text"
                  @click="copy(scope.row[item.prop])"
                >
                  {{ $t('orderDetails.copy') }}
                </el-button>
                <div
                  slot="reference"
                  :class="((item.prop === 'waybillNumber' || item.prop === 'logisticsNumber' || item.prop === 'customerNumber'
                    || item.prop === 'instoreBigBagNo'|| item.prop === 'transitBagNumber' || item.prop === 'routeNumber') && item.clickClass === false) ? 'cursorPoniter' : 'WaybillNumber'"
                  @click="(e) => $emit('to-OrderDetail',scope.row)"
                >
                  {{ scope.row[item.prop] ? `${scope.row[item.prop]}` : '- -' }}
                </div>
              </el-popover>
            </div>
            <!--      渠道状态      -->
            <div
              v-else-if="item.prop === 'stateName'"
              :class="[hide?'multiline':'']"
            >
              <el-tag :type="scope.row.state == 0 ? 'danger' : 'success'">{{ '已' + scope.row[item.prop] }}</el-tag>
            </div>
            <!--      供应商状态      -->
            <div
              v-else-if="item.prop === 'isStop'"
              :class="[hide?'multiline':'']"
            >
              <el-tag v-if="scope.row.isStop === 0" type="danger">{{ $t('ChannelManage.Disable') }}</el-tag>
              <el-tag v-else type="primary">{{ $t('ChannelManage.enable') }}</el-tag>
            </div>
            <!--    订单备注        -->
            <div
              v-else-if="item.prop === 'waybillRemark'"
              class="multiline"
            >
              <div>{{ scope.row[item.prop] || '- -' }}</div>
            </div>
            <!--    尺寸        -->
            <div
              v-else-if="item.prop === 'objSize'"
            >
              <div>{{ $t('operationCenter.length') }}: {{ scope.row.objSize.length || '- -' }}</div>
              <div>{{ $t('operationCenter.width') }}: {{ scope.row.objSize.width || '- -' }}</div>
              <div>{{ $t('operationCenter.height') }}: {{ scope.row.objSize.height || '- -' }}</div>
            </div>
            <!--      收件地区     -->
            <div
              v-else-if="item.prop === 'receiveAddress'"
            >
              <div>{{ $t('placeOrder.State') }}: {{ scope.row.receiveAddress.receiveState || '- -' }}</div>
              <div>{{ $t('newOrder.City') }} {{ scope.row.receiveAddress.receiveCity || '- -' }}</div>
              <div>{{ $t('orderDetails.Area') }}: {{ scope.row.receiveAddress.receiveArea || '- -' }}</div>
            </div>
            <!--      申报      -->
            <div
              v-else-if="item.prop === 'declared'"
              :class="[hide?'multiline':'']"
            >
              <div>{{ $t('orderCenterCont.DeclareUnitPrice') }}: {{ scope.row.declared.declaredValue || '- -' }}</div>
              <div>{{ $t('orderCenterCont.DeclaredWeight') }}: {{ scope.row.declared.declaredWeight || '- -' }}</div>
            </div>
            <!--      规则类型      -->
            <div
              v-else-if="item.prop === 'specialRuleFlag'"
            >
              <div>
                <!--'特殊' : '普通'-->
                {{ scope.row.specialRuleFlag ? $t('GlobalSetObj.Especial') : $t('GlobalSetObj.ordinary') }}
              </div>
            </div>
            <!--      系统服务商名称      -->
            <div
              v-else-if="item.prop === 'name'
                || item.prop === 'providerChannelName'
                || item.prop === 'supplierCode'"
              :class="[hide?'multiline':'']"
            >
              <div class="systemName" @click="(e) => $emit('to-SystemName',scope.row)">
                {{ scope.row[item.prop] ? `${scope.row[item.prop]}` : '- -' }}
              </div>
            </div>
            <!--    时间        -->
            <div
              v-else-if="item.prop === 'createTime' || item.prop === 'updateTime' ||
                item.prop === 'lastTrackTime' || item.prop === 'changeOrderTime' ||
                item.prop === 'loadingTime' || item.prop === 'unloadingTime' ||
                item.prop === 'packageTime' || item.prop === 'pushSucceedTime' || item.prop === 'receiptTime'"
              :class="[hide?'multiline':'']"
            >
              <div v-if="formatTime">
                {{ scope.row[item.prop] ? `${dayjs(scope.row[item.prop]).format('DD-MM-YYYY HH:mm:ss')}` : '- -' }}
              </div>
              <div v-else>{{ scope.row[item.prop] ? `${scope.row[item.prop]}` : '- -' }}</div>
            </div>
            <!--    日期        -->
            <div
              v-else-if="item.prop === 'date'"
              :class="[hide?'multiline':'']"
            >
              <div v-if="formatTime">
                {{ scope.row[item.prop] ? `${dayjs(scope.row[item.prop]).format('DD-MM-YYYY')}` : '- -' }}
              </div>
              <div v-else>{{ scope.row[item.prop] ? `${scope.row[item.prop]}` : '- -' }}</div>
            </div>
            <!--换单状态-->
            <div v-else-if="item.prop === 'changeLableStatus'">
              <div v-if="scope.row[item.prop]">{{ scope.row[item.prop] }}</div>
              <div v-else>{{ $t('GlobalSetObj.PendingNumber') }}</div>
            </div>
            <!--包裹数-->
            <div v-else-if="item.prop === 'packageCount'">
              <div
                :class="((item.prop === 'packageCount') && item.clickClass === false) ? 'cursorPoniter' : 'WaybillNumber'"
                @click="() => $emit('packageList',scope.row)"
              >{{ scope.row[item.prop] }}
              </div>
            </div>
            <!--供应商网点-->
            <div v-else-if="item.prop === 'provideSiteName'">
              <div>{{ scope.row['providerName'] }}</div>
              <div>{{ '>' + scope.row['siteName'] }}</div>
            </div>
            <!--Minuta-->
            <div v-else-if="item.prop === 'minuta'">
              <div
                :class="((item.prop === 'minuta') && item.clickClass === false) ? 'cursorPoniter' : 'WaybillNumber'"
                @click="() => $emit('clickMinuta',scope.row)"
              >{{ scope.row[item.prop] ? `${$t('GlobalSetObj.clickDown')}` : `${$t('GlobalSetObj.Rebuild')}` }}
              </div>
            </div>
            <!--申报状态-->
            <div v-else-if="item.prop === 'DeclareStatus'">
              <div
                v-if="scope.row.declareStatus == 4"
                style="color: #f56c6c"
              >{{ scope.row.DeclareStatus }}
              </div>
              <div
                v-else-if="scope.row.declareStatus == 3"
                style="color: #ff7849"
              >{{ scope.row.DeclareStatus }}
              </div>
              <div
                v-else
              >{{ scope.row.DeclareStatus }}
              </div>
            </div>
            <!--            点击文字-->
            <div
              v-else-if="item.clickText"
              style="color:#409eff;cursor: pointer"
              @click="(e) => $emit('on-click-text',scope.row)"
            >
              <span>{{ scope.row[item.prop] }}</span>
            </div>
            <!--            <div v-else class="multiline" v-text="scope.row[item.prop]" />-->
            <div v-else :class="[hide?'multiline':'']">{{
              scope.row[item.prop] ? `${scope.row[item.prop]}` : '- -'
            }}
            </div>
          </template>
        </el-table-column>
      </template>
      <el-table-column
        v-if="hasOperation"
        :label="$t('newOrder.operation')"
        :width="opWidth"
        fixed="right"
      >
        <template slot-scope="scope">
          <div class="operator-wrap">
            <div v-if="scope.row.checkTrajectory1" class="checkTrajectoryBox">
              <el-tooltip
                :content="$t('orderCenterCont.TrajectoryQuery')" class="item" effect="dark"
                placement="top"
              >
                <div
                  class="el-icon-location span" style="font-size: 20px;"
                  @click="(e) => $emit('on-checkTrajectory',scope.row)"
                />
              </el-tooltip>
            </div>
            <div v-if="scope.row.checkTrajectory" class="checkTrajectoryBox">
              <el-tooltip
                :content="$t('newOrder.orderNote')" class="item" effect="dark"
                placement="top"
              >
                <div class="el-icon-tickets span" @click="(e) => $emit('on-editWaybillRemark',scope.row)" />
              </el-tooltip>
              <el-tooltip
                :content="$t('orderCenterCont.TrajectoryQuery')" class="item" effect="dark"
                placement="top"
              >
                <div class="el-icon-location span" @click="(e) => $emit('on-checkTrajectory',scope.row)" />
              </el-tooltip>
            </div>
            <!--
            <el-button
              v-if="scope.row.checkTrajectory"
              size="mini"
              @click="(e) => $emit('on-checkTrajectory',scope.row)"
            >{{ $t('GlobalSetObj.TrackSearch') }}
            </el-button>-->
            <div
              v-if="scope.row.printBagNumber"
              style="color:#409eff"
              @click="(e) => $emit('on-printBagNumber',scope.row)"
            >
              <!--打印袋号-->
              <el-tooltip :content="$t('GlobalSetObj.PrintBagNumber')" class="item" placement="top">
                <el-button style="margin-right: 10px;" type="text">
                  <span class="bgImgUrlPrintBag" />
                  <!--                  <el-image src="/static/PrintBagNumber.svg" style="width: 20px;" />-->
                </el-button>
              </el-tooltip>
            </div>
            <div
              v-if="scope.row.doPrint"
              style="color:#409eff"
              @click="(e) => $emit('on-doPrint',scope.row)"
            >{{ $t('CenterForSorting.Print') }}
            </div>
            <div
              v-if="scope.row.JdoPrint"
              style="color:#409eff"
            >
              <a :href="scope.row.mdfeUrl" download target="_blank">{{ $t('GlobalSetObj.download') }}</a>
            </div>
            <div
              v-if="scope.row.doDownloadMdfe"
              style="color:#409eff"
              @click="(e) => $emit('on-doDownloadMdfe',scope.row)"
            >
              <span>{{ $t('GlobalSetObj.Download') }}</span>
            </div>
            <div
              v-if="scope.row.completed"
              style="color:#409eff"
              @click="(e) => $emit('on-doCompleted',scope.row)"
            >{{ $t('GlobalSetObj.Terminar') }}
            </div>
            <!--重新生成-->
            <div
              v-if="scope.row.rebuild"
              style="color:#409eff"
              @click="(e) => $emit('on-rebuild',scope.row)"
            >{{ $t('GlobalSetObj.Rebuild') }}
            </div>
            <div
              v-if="scope.row.retry"
              style="color:#409eff"
              @click="(e) => $emit('on-doRetry',scope.row)"
            >{{ '重试' }}
            </div>
            <div
              v-if="scope.row.doDownload"
              style="color:#409eff"
              @click="(e) => $emit('on-doDownload',scope.row)"
            >
              <span>{{ $t('GlobalSetObj.Download') }}</span>
            </div>
            <el-tooltip
              :content="$t('GlobalSetObj.View')" class="item" effect="dark"
              placement="top"
            >
              <el-button
                v-if="scope.row.look"
                class="buttonStyle"
                size="mini"
                type="text"
                @click="(e) => $emit('on-look',scope.row)"
              ><i class="iconfont icon-a-ziyuan511" style="font-size: 15px;" />
              </el-button>
            </el-tooltip>
            <!--    编辑        -->
            <el-tooltip
              :content="$t('GlobalSetObj.edit')" class="item" effect="dark"
              placement="top"
            >
              <el-button
                v-if="scope.row.edit"
                class="buttonStyle"
                size="mini"
                style="margin-left: 0;"
                type="text"
                @click="(e) => $emit('on-edit',scope.row)"
              ><i class="el-icon-edit-outline" style="font-size: 25px;" />
              </el-button>
            </el-tooltip>
            <!-- 删除 -->
            <el-tooltip
              :content="$t('GlobalSetObj.Delete')" class="item" effect="dark"
              placement="top"
            >
              <el-popconfirm
                v-if="scope.row.del"
                :title="$t('GlobalSetObj.AreYouSureDelete')"
                @confirm="$emit('on-del',scope.row)"
              >
                <el-button
                  slot="reference"
                  class="buttonStyleDe"
                  size="mini"
                  type="text"
                ><i class="iconfont icon-a-ziyuan54" style="font-size: 22px;" />
                </el-button>
              </el-popconfirm>
            </el-tooltip>
            <!-- 字符串删除 -->
            <el-popconfirm
              v-if="scope.row.delStr"
              :title="$t('GlobalSetObj.AreYouSureDelete')"
              @confirm="$emit('on-delStr',scope.row)"
            >
              <el-link slot="reference" :underline="false" type="danger">
                {{ $t('GlobalSetObj.MoveOut') }}
              </el-link>
            </el-popconfirm>
            <!--            <el-link-->
            <!--              v-if="scope.row.delStr && scope.row.declareStatus !== 4"-->
            <!--              slot="reference"-->
            <!--              :underline="false"-->
            <!--              disabled-->
            <!--              type="danger"-->
            <!--            >-->
            <!--              {{ $t('GlobalSetObj.Delete') }}-->
            <!--            </el-link>-->
            <!--     打印清单       -->
            <div v-if="scope.row.PrintList" class="print">
              <el-popconfirm
                v-if="scope.row.declareStatus == 3"
                :title="$t('GlobalSetObj.sureToPrint')"
                placement="left-start"
                width="250"
                @confirm="$emit('on-PrintList',scope.row)"
              >
                <template slot="reference">
                  <span style="color: #409eff">{{ onPrintName ? onPrintName : $t('GlobalSetObj.PrintList') }}</span>
                </template>
              </el-popconfirm>
              <span v-else-if="scope.row.declareStatus == 4" style="color: #409eff" />
              <span v-else-if="scope.row.declareStatus == 1" />
              <span
                v-else
                style="color: #409eff"
                @click="$emit('on-PrintList',scope.row)"
              >{{ onPrintName ? onPrintName : $t('GlobalSetObj.PrintList') }}</span>
            </div>
            <el-switch
              v-if="scope.row.elSwitchValue == 0"
              v-model="scope.row.elSwitchValue"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="$emit('on-stop',scope.row.id,1)"
            />
            <el-switch
              v-if="scope.row.elSwitchValue == 1"
              v-model="scope.row.elSwitchValue"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="$emit('on-stop',scope.row.id,0)"
            />
            <el-popconfirm
              v-if="scope.row.isState == 0"
              :title="$t('basicData.AreYouSureEnabled')"
              @confirm="$emit('on-stop',scope.row.id,1)"
            >
              <!-- 启用 -->
              <el-button
                slot="reference"
                size="mini"
                type="success"
              >{{ $t('ChannelManage.enable') }}
              </el-button>
            </el-popconfirm>
            <!-- 停用 -->
            <el-popconfirm
              v-if="scope.row.isState == 1"
              :title=" $t('ChannelManage.sureToStop')"
              @confirm="$emit('on-stop',scope.row.id,0)"
            >
              <el-button
                slot="reference"
                size="mini"
                type="danger"
              >{{ $t('ChannelManage.Disable') }}
              </el-button>
            </el-popconfirm>
            <!--    按钮编辑        -->
            <el-button
              v-if="scope.row.editBtn"
              size="mini"
              style="margin-left: 0;color: #666"
              type="text"
              @click="(e) => $emit('on-editBtn',e,scope.row)"
            ><i class="el-icon-edit-outline hoverClass" style="font-size: 25px;" />
            </el-button>
            <el-button
              v-if="scope.row.operation === 0"
              size="mini"
              type="text"
              @click="() => $emit('clickInvoiceInfo',scope.row)"
            >
              <i class="el-icon-edit-outline hoverClass" style="font-size: 25px;" />
            </el-button>

          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { copyData } from '@/utils/copyData';
import dayjs from 'dayjs';

export default {
  name: 'TableList',
  props: {
    formatTime: {
      type: Boolean,
      required: false
    },
    isTip: {
      type: Boolean,
      required: true
    },
    selectTable: {
      type: Object,
      default: null
    },
    // table 所需要数据
    tableData: {
      type: Array,
      default: () => [],
      require: true
    },
    isSelectBtn: {
      type: Boolean,
      required: false
    },
    isIndex: {
      type: Boolean,
      required: false
    },
    headerList: {
      type: Array,
      default: () => [],
      require: true
    },
    opWidth: {
      type: Number,
      default: 100
    },
    hasOperation: {
      type: Boolean,
      default: false
    },
    // 打印清单操作按钮文字
    onPrintName: {
      type: String,
      default: ''
    },
    // 是否超出隐藏
    hide: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      visible: false,
      elSwitchValue: 1
    };
  },

  created() {
    this.dayjs = dayjs;
  },

  methods: {
    changeVisible() {
      console.log(212);
      this.visible = true;
    },
    // 点击复制
    copy(data) {
      copyData(data);
    },
    // 勾选表格数据
    handleSelectionChange(data, row) {
      this.$emit('selectTable', data);
    },
    // 取消勾选
    toggleSelection() {
      this.$refs.multipleTable.clearSelection();
    }
  }
};
</script>

<style>
.el-tooltip__popper.is-dark {
  max-width: 500px !important;
}
</style>
<style scoped>
.checkTrajectoryBox {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

/* .checkTrajectoryBox.span {} */
.buttonStyle,
.buttonStyleDe {
  color: #666;
}

.buttonStyle:hover {
  color: #409eff;
}

.buttonStyleDe:hover {
  color: #F04B52;
}

.operator-wrap {
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.WaybillNumber {
  color: #409eff;
  cursor: pointer;
  padding: 0;
}

.fontColor1 {
  color: #f56c6c;
}

.fontColor2 {
  color: #ff7849;
}

.cursorPoniter {
  cursor: pointer;
}

.systemName {
  color: #409eff;
  font-weight: bold;
  cursor: pointer;
}

/*/deep/ .el-table thead {*/
/*  !*color: #333333;*!*/
/*}*/

.elTable /deep/ .el-table__fixed-right {
  height: 100% !important;
}

/*.elTable /deep/ .el-table__row td:not(.is-hidden):last-child {*/
/*  border-left: 1px solid #EBEEF5;*/
/*}*/

/*.elTable /deep/ .el-table__header th:not(.is-hidden):last-child {*/
/*  border-left: 1px solid #EBEEF5;*/
/*}*/

/*.elTable /deep/ .el-table__header-wrapper tr th:nth-last-child(1) {*/
/*  border-right: none;*/
/*}*/

/*.elTable /deep/ .el-table__body-wrapper tr td:nth-last-child(1) {*/
/*  border-right: none;*/
/*}*/

/*table 表格边框颜色更改*/
/*.elTable {*/
/*  !*border: 1px solid #c9cdd4;*!*/
/*  !*border-right: none;*!*/
/*}*/
.bgImgUrlShipping:hover {
  background: url('/static/ShippingList.svg') no-repeat 100%;
  display: block;
  width: 18px;
  height: 20px;
}

.bgImgUrlShipping {
  background: url('/static/ShippingListHui.svg') no-repeat 100%;
  display: block;
  width: 18px;
  height: 20px;
}

.bgImgUrlPrintBag {
  background: url('/static/PrintBagNumberHui.svg') no-repeat 100% 100%;
  display: block;
  width: 20px;
  height: 20px;
}

.bgImgUrlPrintBag:hover {
  background: url('/static/PrintBagNumber.svg') no-repeat 100% 100%;
  display: block;
  width: 20px;
  height: 20px;
}

.hoverClass:hover {
  color: #409eff;
}
</style>
