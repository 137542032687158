var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "addFormBox" },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$t("basicData.UnderlyingData"))),
          ]),
          _c(
            "el-form",
            {
              ref: "queryForm",
              staticClass: "queryFormClass",
              attrs: {
                size: "small",
                model: _vm.formData,
                rules: _vm.rules,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "basicData.TheNameOfTheQuoteProposal"
                            ),
                            prop: "name",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("orderDetails.pleaseInput"),
                              clearable: "",
                              maxlength: "50",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.formData.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formData.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("basicData.TheValidityPeriodBegins"),
                            prop: "startOfValidityPeriod",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "datetime",
                              format:
                                _vm.$i18n.locale == "zh"
                                  ? _vm.zhDate
                                  : _vm.ptDate,
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              disabled:
                                _vm.operationType === 2 &&
                                _vm.editData &&
                                _vm.editData.offerPriceStatus === 1,
                              placeholder: _vm.$t("basicData.SelectADatetime"),
                            },
                            model: {
                              value: _vm.formData.startOfValidityPeriod,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "startOfValidityPeriod",
                                  $$v
                                )
                              },
                              expression: "formData.startOfValidityPeriod",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.businessT"),
                            prop: "businessType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                clearable: "",
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.changeBusinessType(
                                    _vm.formData.businessType
                                  )
                                },
                              },
                              model: {
                                value: _vm.formData.businessType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "businessType", $$v)
                                },
                                expression: "formData.businessType",
                              },
                            },
                            _vm._l(_vm.BUSINESS_TYPE_TWO, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.formData.businessType === 2
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                rules: {
                                  required: _vm.formData.businessType === 2,
                                  message: _vm.$t("basicData.CannotBeEmpty"),
                                  trigger: ["blur", "change"],
                                },
                                label: _vm.$t("collectionCenter.lanshouTypee"),
                                prop: "collectType",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: _vm.$t(
                                      "basicData.PleaseSelect"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.collectTypeChange()
                                    },
                                  },
                                  model: {
                                    value: _vm.formData.collectType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "collectType", $$v)
                                    },
                                    expression: "formData.collectType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: 1,
                                    attrs: {
                                      label: _vm.$t("collectionCenter.dropOff"),
                                      value: 1,
                                    },
                                  }),
                                  _c("el-option", {
                                    key: 2,
                                    attrs: {
                                      label: _vm.$t(
                                        "collectionCenter.doorPickUp"
                                      ),
                                      value: 2,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operationCenter.partition"),
                            prop: "partitionId",
                            rules: {
                              required: false,
                              message: _vm.$t("basicData.CannotBeEmpty"),
                              trigger: ["blur", "change"],
                            },
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t("basicData.PleaseSelect"),
                                filterable: "",
                                clearable: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getOtherFeeDetail()
                                },
                              },
                              model: {
                                value: _vm.formData.partitionId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "partitionId", $$v)
                                },
                                expression: "formData.partitionId",
                              },
                            },
                            _vm._l(_vm.partitionSchemeList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("basicData.PayablePrincipal"),
                            prop: "payableEntityId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: _vm.$t("basicData.PleaseSelect"),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.providerInfoChange(
                                    _vm.formData.payableEntityId
                                  )
                                },
                              },
                              model: {
                                value: _vm.formData.payableEntityId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "payableEntityId", $$v)
                                },
                                expression: "formData.payableEntityId",
                              },
                            },
                            _vm._l(_vm.businessPrincipalList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label:
                                    item.providerBody +
                                    "（" +
                                    item.providerName +
                                    "）(" +
                                    item.code +
                                    ")",
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("basicData.Outlets"),
                            prop: "siteIds",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                multiple: _vm.editData.id ? false : true,
                                placeholder: _vm.$t("basicData.PleaseSelect"),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.siteIdChange(_vm.formData.siteIds)
                                },
                              },
                              model: {
                                value: _vm.formData.siteIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "siteIds", $$v)
                                },
                                expression: "formData.siteIds",
                              },
                            },
                            [
                              !_vm.formData.payableEntityId
                                ? _c("el-option", { attrs: { value: "" } }, [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#f00",
                                          "font-weight": "bold",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "basicData.PleaseSelectThePayableEntity"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._l(_vm.outletsList, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.formData.businessType === 3
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                rules: {
                                  required: _vm.formData.businessType === 3,
                                  message: _vm.$t("basicData.CannotBeEmpty"),
                                  trigger: ["blur", "change"],
                                },
                                label: _vm.$t("basicData.SettlementNode"),
                                prop: "settlementNode",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: _vm.$t(
                                      "basicData.PleaseSelect"
                                    ),
                                  },
                                  model: {
                                    value: _vm.formData.settlementNode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "settlementNode",
                                        $$v
                                      )
                                    },
                                    expression: "formData.settlementNode",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: 1,
                                    attrs: {
                                      label: _vm.$t("basicData.DeliveryTask"),
                                      value: 1,
                                    },
                                  }),
                                  _c("el-option", {
                                    key: 2,
                                    attrs: {
                                      label: _vm.$t("basicData.signFor"),
                                      value: 2,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formData.businessType === 5
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("basicData.报价模式"),
                                prop: "quotationMode",
                                rules: {
                                  required: true,
                                  message: _vm.$t("basicData.CannotBeEmpty"),
                                  trigger: ["blur", "change"],
                                },
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: _vm.$t(
                                      "basicData.PleaseSelect"
                                    ),
                                  },
                                  model: {
                                    value: _vm.formData.quotationMode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "quotationMode",
                                        $$v
                                      )
                                    },
                                    expression: "formData.quotationMode",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: 0,
                                    attrs: {
                                      label: _vm.$t("basicData.保底价"),
                                      value: 1,
                                    },
                                  }),
                                  _c("el-option", {
                                    key: 1,
                                    attrs: {
                                      label: _vm.$t("basicData.区间取最高"),
                                      value: 2,
                                    },
                                  }),
                                  _c("el-option", {
                                    key: 2,
                                    attrs: {
                                      label: _vm.$t("basicData.直乘"),
                                      value: 3,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("basicData.PriceDetails"))),
          ]),
          [1, 4, 5].includes(_vm.formData.businessType)
            ? [
                _vm.formData.partitionId
                  ? _c(
                      "div",
                      { staticStyle: { "margin-bottom": "10px" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "small" },
                            model: {
                              value: _vm.formData.partitionAffiliateId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "partitionAffiliateId",
                                  $$v
                                )
                              },
                              expression: "formData.partitionAffiliateId",
                            },
                          },
                          _vm._l(_vm.subregionList, function (item, ind) {
                            return _c(
                              "el-radio-button",
                              { key: ind, attrs: { label: item.id } },
                              [
                                _vm._v(
                                  " " + _vm._s(item.partitionIdentifier) + " "
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                        _c("div", { staticStyle: { clear: "both" } }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticStyle: { "margin-bottom": "10px" } },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permit",
                            rawName: "v-permit:remove",
                            value: "btn:payable:quoteManagement:addEdit",
                            expression: "'btn:payable:quoteManagement:addEdit'",
                            arg: "remove",
                          },
                        ],
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleAdd()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("basicData.AddARow")))]
                    ),
                    _c(
                      "el-popover",
                      {
                        attrs: { placement: "bottom-start", trigger: "hover" },
                      },
                      [
                        _c("import-download", {
                          key: _vm.exportKey,
                          attrs: {
                            "expense-type-list": _vm.expenseTypeList,
                            "operation-mode": _vm.formData.operationMode,
                            "table-data-obj":
                              _vm.subregionList && _vm.subregionList.length > 0
                                ? _vm.tableDataObj
                                : _vm.tableDataArr,
                            "table-data": {
                              formData: _vm.formData,
                              subregionList: _vm.subregionList,
                            },
                          },
                          on: { importPartition: _vm.importPartition },
                        }),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              slot: "reference",
                              size: "small",
                              plain: "",
                            },
                            slot: "reference",
                          },
                          [_vm._v(_vm._s(_vm.$t("basicData.ImportDownload")))]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-popover",
                      {
                        attrs: { placement: "bottom-start", trigger: "hover" },
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { "margin-bottom": "10px" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.clearOffers(1)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("basicData.EmptyCurrentPartition")
                                  ) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.clearOffers(2)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("basicData.EmptyAllPartitions")) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit:remove",
                                value: "btn:payable:quoteManagement:addEdit",
                                expression:
                                  "'btn:payable:quoteManagement:addEdit'",
                                arg: "remove",
                              },
                            ],
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              slot: "reference",
                              size: "small",
                              plain: "",
                            },
                            slot: "reference",
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("basicData.ClearTheOffer")) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.isCollectionForwardingCentreHomeDeliveryCmp
            ? _c("CollectionForwardingCentreHomeDeliveryCmp", {
                ref: "collectionForwardingCentreHomeDeliveryRef",
                attrs: {
                  "subregion-list": _vm.subregionList,
                  "partition-id": _vm.formData.partitionId,
                },
              })
            : _vm.isCollectionAndDoorToDoorCollectionAndThirdParty
            ? _c("CollectionAndDoorToDoorCollectionAndThirdPartyCmp", {
                ref: "collectionAndDoorToDoorCollectionAndThirdPartyRef",
                attrs: {
                  "subregion-list": _vm.subregionList,
                  "partition-id": _vm.formData.partitionId,
                },
              })
            : _vm.isBusinessType3AndOperationMode1
            ? _c("AJDAddCmp", {
                ref: "ajdAddRef",
                attrs: {
                  "subregion-list": _vm.subregionList,
                  "partition-id": _vm.formData.partitionId,
                },
              })
            : _vm._e(),
          _vm.formData.businessType === 4
            ? [
                _c(
                  "div",
                  {
                    staticClass: "priceTableForm",
                    staticStyle: { "margin-top": "10px" },
                  },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "tableForm1",
                        staticClass: "demo-ruleForm",
                        attrs: {
                          model: _vm.formData,
                          "label-width": "0",
                          "label-position": "top",
                          size: "mini",
                        },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              data: _vm.formData.collectFeesList,
                              border: "",
                            },
                          },
                          [
                            _c(
                              "el-table-column",
                              {
                                attrs: {
                                  label: _vm.$t("basicData.ExpenseType"),
                                  "min-width": "180",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop:
                                                      "collectFeesList." +
                                                      scope.$index +
                                                      ".feeTypeCode",
                                                    rules: {
                                                      required: true,
                                                      message: _vm.$t(
                                                        "basicData.CannotBeEmpty"
                                                      ),
                                                      trigger: [
                                                        "blur",
                                                        "change",
                                                      ],
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        placeholder: _vm.$t(
                                                          "basicData.PleaseSelect"
                                                        ),
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.chargeTypeChange(
                                                            scope
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row.feeTypeCode,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "feeTypeCode",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.feeTypeCode",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.expenseTypeList,
                                                      function (item) {
                                                        return _c("el-option", {
                                                          key: item.id,
                                                          attrs: {
                                                            label:
                                                              item.displayName +
                                                              "(" +
                                                              item.code +
                                                              ")",
                                                            value: item.code,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  877004256
                                ),
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c("div", [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("basicData.ExpenseType"))
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ],
                              2
                            ),
                            _c(
                              "el-table-column",
                              {
                                attrs: {
                                  label: _vm.$t("basicData.WeightStartKG"),
                                  "min-width": "180",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop:
                                                      "collectFeesList." +
                                                      scope.$index +
                                                      ".startWeight",
                                                    rules: {
                                                      required: true,
                                                      negative: "",
                                                      suffix: 3,
                                                      negative1: 1,
                                                      package:
                                                        scope.row.endWeight,
                                                      validator: _vm.priceType,
                                                      trigger: [
                                                        "blur",
                                                        "change",
                                                      ],
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    model: {
                                                      value:
                                                        scope.row.startWeight,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "startWeight",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.startWeight",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1364555921
                                ),
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c("div", [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("basicData.WeightStartKG")
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ],
                              2
                            ),
                            _c(
                              "el-table-column",
                              {
                                attrs: {
                                  label: _vm.$t("basicData.WeightEndKG"),
                                  "min-width": "180",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop:
                                                      "collectFeesList." +
                                                      scope.$index +
                                                      ".endWeight",
                                                    rules: {
                                                      required: true,
                                                      negative: "",
                                                      suffix: 3,
                                                      negative1: 2,
                                                      package:
                                                        scope.row.startWeight,
                                                      validator: _vm.priceType,
                                                      trigger: [
                                                        "blur",
                                                        "change",
                                                      ],
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    model: {
                                                      value:
                                                        scope.row.endWeight,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "endWeight",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.endWeight",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1893245597
                                ),
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c("div", [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("basicData.WeightEndKG"))
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ],
                              2
                            ),
                            _c(
                              "el-table-column",
                              {
                                attrs: {
                                  label: "0-31KM" + _vm.$t("basicData.Price"),
                                  "min-width": "180",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "collectFeesList." +
                                                  scope.$index +
                                                  ".innerDistancePrice",
                                                rules: {
                                                  required: true,
                                                  suffix: 5,
                                                  negative: "",
                                                  validator: _vm.priceType,
                                                  trigger: ["blur", "change"],
                                                },
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                model: {
                                                  value:
                                                    scope.row
                                                      .innerDistancePrice,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "innerDistancePrice",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.innerDistancePrice",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3393395536
                                ),
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c("div", [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          "0-31KM" + _vm.$t("basicData.Price")
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ],
                              2
                            ),
                            _c(
                              "el-table-column",
                              {
                                attrs: {
                                  label: "31-101KM" + _vm.$t("basicData.Price"),
                                  "min-width": "180",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "collectFeesList." +
                                                  scope.$index +
                                                  ".outerDistancePrice",
                                                rules: {
                                                  required: false,
                                                  suffix: 5,
                                                  negative: "",
                                                  validator: _vm.priceType,
                                                  trigger: ["blur", "change"],
                                                },
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                model: {
                                                  value:
                                                    scope.row
                                                      .outerDistancePrice,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "outerDistancePrice",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.outerDistancePrice",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  810767355
                                ),
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c("div", [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          "31-101KM" + _vm.$t("basicData.Price")
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ],
                              2
                            ),
                            _c(
                              "el-table-column",
                              {
                                attrs: {
                                  label: ">=101KM" + _vm.$t("basicData.Price"),
                                  "min-width": "180",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "collectFeesList." +
                                                  scope.$index +
                                                  ".greaterThanDistancePrice",
                                                rules: {
                                                  required: false,
                                                  suffix: 5,
                                                  negative: "",
                                                  validator: _vm.priceType,
                                                  trigger: ["blur", "change"],
                                                },
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                model: {
                                                  value:
                                                    scope.row
                                                      .greaterThanDistancePrice,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "greaterThanDistancePrice",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.greaterThanDistancePrice",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1817193211
                                ),
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c("div", [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          ">=101KM" + _vm.$t("basicData.Price")
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ],
                              2
                            ),
                            _c(
                              "el-table-column",
                              {
                                attrs: {
                                  label:
                                    "0-31KM" +
                                    _vm.$t(
                                      "basicData.ExcessWeightFareIncrease"
                                    ),
                                  "min-width": "200",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "collectFeesList." +
                                                  scope.$index +
                                                  ".overWeightExtraPrice",
                                                rules: {
                                                  required: false,
                                                  suffix: 5,
                                                  negative: "",
                                                  validator: _vm.priceType,
                                                  trigger: ["blur", "change"],
                                                },
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                model: {
                                                  value:
                                                    scope.row
                                                      .overWeightExtraPrice,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "overWeightExtraPrice",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.overWeightExtraPrice",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1497901883
                                ),
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        "0-31KM" +
                                          _vm.$t(
                                            "basicData.ExcessWeightFareIncrease"
                                          )
                                      )
                                    ),
                                  ]),
                                ]),
                              ],
                              2
                            ),
                            _c(
                              "el-table-column",
                              {
                                attrs: {
                                  label:
                                    "31-101KM" +
                                    _vm.$t(
                                      "basicData.ExcessWeightFareIncrease"
                                    ),
                                  "min-width": "240",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "collectFeesList." +
                                                  scope.$index +
                                                  ".overKilometerExtraPrice",
                                                rules: {
                                                  required: false,
                                                  suffix: 5,
                                                  negative: "",
                                                  validator: _vm.priceType,
                                                  trigger: ["blur", "change"],
                                                },
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                model: {
                                                  value:
                                                    scope.row
                                                      .overKilometerExtraPrice,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "overKilometerExtraPrice",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.overKilometerExtraPrice",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2301594875
                                ),
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        "31-101KM" +
                                          _vm.$t(
                                            "basicData.ExcessWeightFareIncrease"
                                          )
                                      )
                                    ),
                                  ]),
                                ]),
                              ],
                              2
                            ),
                            _c(
                              "el-table-column",
                              {
                                attrs: {
                                  label:
                                    ">=101KM" +
                                    _vm.$t(
                                      "basicData.ExcessWeightFareIncrease"
                                    ),
                                  "min-width": "240",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "collectFeesList." +
                                                  scope.$index +
                                                  ".greaterThanKilometerExtraPrice",
                                                rules: {
                                                  required: false,
                                                  suffix: 5,
                                                  negative: "",
                                                  validator: _vm.priceType,
                                                  trigger: ["blur", "change"],
                                                },
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                model: {
                                                  value:
                                                    scope.row
                                                      .greaterThanKilometerExtraPrice,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "greaterThanKilometerExtraPrice",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.greaterThanKilometerExtraPrice",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3555679899
                                ),
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        ">=101KM" +
                                          _vm.$t(
                                            "basicData.ExcessWeightFareIncrease"
                                          )
                                      )
                                    ),
                                  ]),
                                ]),
                              ],
                              2
                            ),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("basicData.operate"),
                                width: "120",
                                fixed: "right",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-popconfirm",
                                          {
                                            attrs: {
                                              title: _vm.$t(
                                                "basicData.AreYouSureYouWantToDelete"
                                              ),
                                            },
                                            on: {
                                              confirm: function ($event) {
                                                return _vm.handleDel(
                                                  scope.$index,
                                                  "collectFeesList"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  slot: "reference",
                                                  type: "danger",
                                                  size: "mini",
                                                },
                                                slot: "reference",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("basicData.Delete")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                735384107
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.formData.businessType === 5
            ? [
                _c(
                  "el-form",
                  {
                    ref: "tableForm1",
                    staticClass: "demo-ruleForm",
                    attrs: {
                      model: _vm.formData,
                      "label-width": "0",
                      "label-position": "top",
                      size: "mini",
                    },
                  },
                  [
                    _c(
                      "el-table",
                      {
                        attrs: {
                          data: _vm.formData.collectFeesList,
                          border: "",
                        },
                      },
                      [
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: _vm.$t("basicData.ExpenseType"),
                              "min-width": "160",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "collectFeesList." +
                                                  scope.$index +
                                                  ".feeTypeCode",
                                                rules: {
                                                  required: true,
                                                  message: _vm.$t(
                                                    "basicData.CannotBeEmpty"
                                                  ),
                                                  trigger: ["blur", "change"],
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    placeholder: _vm.$t(
                                                      "basicData.PleaseSelect"
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.chargeTypeChange(
                                                        scope
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.feeTypeCode,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "feeTypeCode",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.feeTypeCode",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.expenseTypeList,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.id,
                                                      attrs: {
                                                        label:
                                                          item.displayName +
                                                          "(" +
                                                          item.code +
                                                          ")",
                                                        value: item.code,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              877004256
                            ),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("div", [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("basicData.ExpenseType"))
                                  ),
                                ]),
                              ]),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: _vm.$t("vehicle.modelLabel"),
                              "min-width": "160",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "collectFeesList." +
                                                  scope.$index +
                                                  ".vehicleType",
                                                rules: {
                                                  required: true,
                                                  message: _vm.$t(
                                                    "basicData.CannotBeEmpty"
                                                  ),
                                                  trigger: ["blur", "change"],
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    placeholder: _vm.$t(
                                                      "basicData.PleaseSelect"
                                                    ),
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.vehicleType,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "vehicleType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.vehicleType",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.VEHICLE_MODEL,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.value,
                                                      attrs: {
                                                        label: item.label,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              115383552
                            ),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("div", [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("vehicle.modelLabel"))),
                                ]),
                              ]),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: _vm.$t("basicData.KilometersStartKM"),
                              "min-width": "160",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "collectFeesList." +
                                                  scope.$index +
                                                  ".startDistance",
                                                rules: {
                                                  required: true,
                                                  negative: "",
                                                  suffix: 3,
                                                  negative1: 1,
                                                  package:
                                                    scope.row.endDistance,
                                                  validator: _vm.priceType,
                                                  trigger: ["blur", "change"],
                                                },
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                model: {
                                                  value:
                                                    scope.row.startDistance,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "startDistance",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.startDistance",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3861105586
                            ),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("div", [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("basicData.KilometersStartKM")
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: _vm.$t("basicData.MileageEndKM"),
                              "min-width": "160",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "collectFeesList." +
                                              scope.$index +
                                              ".endDistance",
                                            rules: {
                                              required: true,
                                              negative: "",
                                              suffix: 3,
                                              negative1: 2,
                                              package: scope.row.startDistance,
                                              validator: _vm.priceType,
                                              trigger: ["blur", "change"],
                                            },
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: scope.row.endDistance,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "endDistance",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.endDistance",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2423789359
                            ),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("div", [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("basicData.MileageEndKM"))
                                  ),
                                ]),
                              ]),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: _vm.$t("basicData.Price"),
                              "min-width": "160",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "collectFeesList." +
                                              scope.$index +
                                              ".price",
                                            rules: {
                                              required: true,
                                              negative: "",
                                              suffix: 3,
                                              negative1: 2,
                                              validator: _vm.priceType,
                                              trigger: ["blur", "change"],
                                            },
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: scope.row.price,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "price",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.price",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1913029482
                            ),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("div", [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("basicData.Price") + "/KM")
                                  ),
                                ]),
                              ]),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: _vm.$t("basicData.保底价公里数"),
                              "min-width": "160",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "collectFeesList." +
                                              scope.$index +
                                              ".standardDistance",
                                            rules: {
                                              required: false,
                                              negative: "",
                                              suffix: 3,
                                              negative1: 2,
                                              validator: _vm.priceType,
                                              trigger: ["blur", "change"],
                                            },
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: scope.row.standardDistance,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "standardDistance",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.standardDistance",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              552560129
                            ),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("div", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("basicData.保底价公里数") + "KM"
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                          ],
                          2
                        ),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("basicData.operate"),
                            width: "120",
                            fixed: "right",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-popconfirm",
                                      {
                                        attrs: {
                                          title: _vm.$t(
                                            "basicData.AreYouSureYouWantToDelete"
                                          ),
                                        },
                                        on: {
                                          confirm: function ($event) {
                                            return _vm.handleDel(
                                              scope.$index,
                                              "collectFeesList"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              slot: "reference",
                                              type: "danger",
                                              size: "mini",
                                            },
                                            slot: "reference",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("basicData.Delete"))
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            735384107
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.formData.businessType === 1
            ? [
                _c(
                  "el-form",
                  {
                    ref: "tableForm1",
                    staticClass: "demo-ruleForm",
                    attrs: {
                      model: _vm.formData,
                      "label-width": "0",
                      "label-position": "top",
                      size: "mini",
                    },
                  },
                  [
                    _c(
                      "el-table",
                      {
                        attrs: {
                          data: _vm.formData.collectFeesList,
                          border: "",
                        },
                      },
                      [
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: _vm.$t("basicData.ExpenseType"),
                              "min-width": "180",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "collectFeesList." +
                                                  scope.$index +
                                                  ".feeTypeCode",
                                                rules: {
                                                  required: true,
                                                  message: _vm.$t(
                                                    "basicData.CannotBeEmpty"
                                                  ),
                                                  trigger: ["blur", "change"],
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    placeholder: _vm.$t(
                                                      "basicData.PleaseSelect"
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.chargeTypeChange(
                                                        scope
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.feeTypeCode,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "feeTypeCode",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.feeTypeCode",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.expenseTypeList,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.id,
                                                      attrs: {
                                                        label:
                                                          item.displayName +
                                                          "(" +
                                                          item.code +
                                                          ")",
                                                        value: item.code,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              877004256
                            ),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("div", [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("basicData.ExpenseType"))
                                  ),
                                ]),
                              ]),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: _vm.$t(
                                "basicData.QuantityStartTicketNotIncluded"
                              ),
                              "min-width": "180",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "collectFeesList." +
                                                  scope.$index +
                                                  ".startOfGoodsQuantity",
                                                rules: {
                                                  required: true,
                                                  suffix: "",
                                                  negative: "",
                                                  negative1: 1,
                                                  package:
                                                    scope.row
                                                      .endOfGoodsQuantity,
                                                  validator: _vm.Integer0,
                                                  trigger: ["blur", "change"],
                                                },
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                model: {
                                                  value:
                                                    scope.row
                                                      .startOfGoodsQuantity,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "startOfGoodsQuantity",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.startOfGoodsQuantity",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3500882979
                            ),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("div", [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "basicData.QuantityStartTicketNotIncluded"
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: _vm.$t(
                                "basicData.EndOfVolumeTicketIncluded"
                              ),
                              "min-width": "180",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "collectFeesList." +
                                                  scope.$index +
                                                  ".endOfGoodsQuantity",
                                                rules: {
                                                  required: true,
                                                  suffix: "",
                                                  negative: "",
                                                  negative1: 2,
                                                  package:
                                                    scope.row
                                                      .startOfGoodsQuantity,
                                                  validator: _vm.Integer0,
                                                  trigger: ["blur", "change"],
                                                },
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                model: {
                                                  value:
                                                    scope.row
                                                      .endOfGoodsQuantity,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "endOfGoodsQuantity",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.endOfGoodsQuantity",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3793049231
                            ),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("div", [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "basicData.EndOfVolumeTicketIncluded"
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: _vm.$t("basicData.PickUpPrice"),
                              "min-width": "180",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "collectFeesList." +
                                                  scope.$index +
                                                  ".price",
                                                rules: {
                                                  required: true,
                                                  suffix: 2,
                                                  negative: "",
                                                  validator: _vm.priceType,
                                                  trigger: ["blur", "change"],
                                                },
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                model: {
                                                  value: scope.row.price,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "price",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "scope.row.price",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1204659366
                            ),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("div", [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("basicData.PickUpPrice"))
                                  ),
                                ]),
                              ]),
                            ]),
                          ],
                          2
                        ),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("basicData.operate"),
                            width: "120",
                            fixed: "right",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-popconfirm",
                                      {
                                        attrs: {
                                          title: _vm.$t(
                                            "basicData.AreYouSureYouWantToDelete"
                                          ),
                                        },
                                        on: {
                                          confirm: function ($event) {
                                            return _vm.handleDel(
                                              scope.$index,
                                              "collectFeesList"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              slot: "reference",
                                              type: "danger",
                                              size: "mini",
                                            },
                                            slot: "reference",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("basicData.Delete"))
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            735384107
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "addFooter" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.handleUpdatePage(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("basicData.Cancel")))]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: "btn:payable:quoteManagement:addEdit",
                  expression: "'btn:payable:quoteManagement:addEdit'",
                  arg: "remove",
                },
              ],
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.submitForm },
            },
            [_vm._v(_vm._s(_vm.$t("basicData.Save")))]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("basicData.tips"),
            visible: _vm.dialogVisible,
            "append-to-body": true,
            width: "400",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            _vm._l(_vm.errData, function (item, ind) {
              return _c(
                "div",
                {
                  key: ind,
                  staticStyle: { color: "red", "margin-bottom": "8px" },
                },
                [_vm._v(_vm._s(item))]
              )
            }),
            0
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("basicData.determine")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }