/**
 * 强密码 大写、小写、数字、特殊字符! @#,.
 * 登录密码规则
 */
const strongPwd = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[_@.!]).{4,16}$/;

/**
 * 弱密码
 * 大写或者、小写 和数字组成
 */
// const weakPwd = /^[A-z0-9]{4,16}$/g;

/**
 * 邮箱
 */
// const emailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/; // /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/;

/**
 * 新邮箱正则
 * 电子邮箱的基本格式：hello@abc.com.cn
 *  hello部分:表示任意的字母数字以及小圆点(.),如abc.abc@qq.com,不过小圆点的部分是可有可无的，可以表示为：\w{3,}(\.\w+)*
 *  @:可以表示为：@
 *  abc部分：表示任意的字母数字,长度大于1，[A-z0-9]+
 *  .com部分：表示任意字母，长度为2-5位，可以表示为：\.[A-z]{2,5}
 *  .cn部分：表示任意字符，长度为2-5位，由于.cn部分和.com部分规则相同，所以可以将两部分合并为：(\.[A-z]{2,5}){1,2}
 */
const emailReg = /^\w{1,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/;

/**
 * 手机号
 */
const phoneReg = /^1\d{10}$/;

/**
 * 电话
 */
const telReg = /^((d{3,4})|d{3,4}-|s)?d{7,14}$/;

/**
 * QQ号
 * 5至11位
 */
const qqReg = /^[1-9][0-9]{4,10}$/;

/**
 * 统一社会信用代码
 */
const unifiedSocialCreditCodeReg = /[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}/;

/**
 * 身份证号
 */
const IDReg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;

/**
 * 千分位
 * ("456454456").replace(reg, ","); // 456,454,456
 */
const thousandthPercentileReg = /\B(?=(\d{3})+$)/g;
const getThousandthPercentileFunc = (str, $ = ',') => {
  if (typeof (str) !== 'string') {
    throw new Error('请传入字符串');
  }
  return str.replace(thousandthPercentileReg, $);
};

/**
 * url 地址
 */
// const urlReg = /^((https?|ftp|file):\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
// const isURLFun = (str = '') => {
//   return urlReg.test(str);
// };

export {
  strongPwd,
  // weakPwd,
  emailReg,
  phoneReg,
  telReg,
  qqReg,
  unifiedSocialCreditCodeReg,
  IDReg,
  thousandthPercentileReg,
  getThousandthPercentileFunc
  // isURLFun
};

