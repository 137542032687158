var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 13 } },
                [
                  _c(
                    "el-form",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: {
                        model: _vm.scanForm,
                        "label-position": "right",
                        "label-width": "auto",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.bagNumber"),
                            prop: "scanNumber",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "60%" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.scanningLoading($event)
                              },
                            },
                            model: {
                              value: _vm.scanForm.scanNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.scanForm, "scanNumber", $$v)
                              },
                              expression: "scanForm.scanNumber",
                            },
                          }),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "20px" },
                              attrs: { type: "warning" },
                              on: { click: _vm.stowagePreservation },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("operationCenter.stowageSave"))
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "line-height": "24px",
                                color: "#f00",
                              },
                            },
                            [_vm._v(_vm._s(_vm.errorMessage))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 11 } }, [
                _c(
                  "div",
                  { staticClass: "form-el-form-item" },
                  [
                    _c(
                      "el-form",
                      {
                        attrs: {
                          "label-width": "auto",
                          "label-position": "right",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.fuwushang") + "：",
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.serviceName))])]
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label:
                                _vm.$t("GlobalSetObj.DeliveryPoint") + "：",
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.deliveryPoint))])]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c("el-col", { attrs: { span: 14 } }, [
                _c("div", { staticClass: "tableContent" }, [
                  _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                    _c("span", { staticStyle: { "margin-right": "10px" } }, [
                      _vm._v(_vm._s(_vm.$t("operationCenter.CurrentStowage"))),
                    ]),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-right": "10px",
                          "font-size": "14px",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("operationCenter.largePacketNumber")) +
                            "：" +
                            _vm._s(_vm.largePacketNumber)
                        ),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-right": "10px",
                          "font-size": "14px",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("trajectory.TotalWeight")) +
                            "(KG)：" +
                            _vm._s(_vm.totalWeight.toFixed(3))
                        ),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-right": "10px",
                          "font-size": "14px",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("operationCenter.totalNumberOfPackets")
                          ) +
                            "：" +
                            _vm._s(_vm.totalNumberOfPackets)
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        { attrs: { border: "", data: _vm.tableLeft } },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.totalSingNumber"),
                              prop: "bagManifestNo",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.bagNumber"),
                              prop: "bigBagNo",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.weight"),
                              prop: "bagWeight",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t(
                                "operationCenter.BagSealingOperator"
                              ),
                              prop: "operator",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("operationCenter.operate"),
                              align: "center",
                              width: "120",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "red",
                                          cursor: "pointer",
                                          padding: "3px 10px",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteItem(scope)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("GlobalSetObj.MoveOut"))
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("el-col", { attrs: { span: 10 } }, [
                _c("div", { staticClass: "tableContent" }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "inline-block",
                        width: "50%",
                        "margin-bottom": "10px",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("operationCenter.ListOutstandingOrders"))
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "inline-block",
                        width: "50%",
                        "padding-left": "20px",
                      },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            size: "small",
                            placeholder: _vm.$t(
                              "GlobalSetObj.enterTrackingNumber"
                            ),
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.searchRightData($event)
                            },
                          },
                          model: {
                            value: _vm.queryFormRight.params.instoreBigBagNo,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryFormRight.params,
                                "instoreBigBagNo",
                                $$v
                              )
                            },
                            expression: "queryFormRight.params.instoreBigBagNo",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: { click: _vm.searchRightData },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        { attrs: { border: "", data: _vm.tableRight } },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.totalSingNumber"),
                              prop: "instoreBigBagNo",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.fuwushang"),
                              prop: "systemName",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.Outlets"),
                              prop: "deliveryStationName",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("operationCenter.operate"),
                              align: "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#409EFF",
                                          cursor: "pointer",
                                          padding: "3px 10px",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.stowageItem(scope.row)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "operationCenter.AllocationOnboarding"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "pagination" },
                    [
                      _c("el-pagination", {
                        staticStyle: { margin: "10px 0" },
                        attrs: {
                          layout: "total, prev, pager, next, jumper",
                          "current-page": _vm.queryFormRight.pageNumber,
                          "page-size": _vm.queryFormRight.pageSize,
                          "page-sizes": [10, 20, 50, 100],
                          total: _vm.total,
                          small: "",
                          background: "",
                        },
                        on: {
                          "update:currentPage": function ($event) {
                            return _vm.$set(
                              _vm.queryFormRight,
                              "pageNumber",
                              $event
                            )
                          },
                          "update:current-page": function ($event) {
                            return _vm.$set(
                              _vm.queryFormRight,
                              "pageNumber",
                              $event
                            )
                          },
                          "update:pageSize": function ($event) {
                            return _vm.$set(
                              _vm.queryFormRight,
                              "pageSize",
                              $event
                            )
                          },
                          "update:page-size": function ($event) {
                            return _vm.$set(
                              _vm.queryFormRight,
                              "pageSize",
                              $event
                            )
                          },
                          "current-change": _vm.getCurrentPage,
                          "size-change": _vm.getSizePage,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }