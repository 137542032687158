<template> <!-- 修改费用 -->

  <div>
    <div>
      <!--下载模板-->
      <el-button
        icon="el-icon-download"
        size="small"
        type="primary"
        @click="operationBtn"
      >
        {{ $t('basicData.DownloadTheTemplate') }}
      </el-button>
      <!--导入修改费用-->
      <upload-excel
        :on-success="importLogistics"
        :but-name="$t('receivablePayable.ImportModifyExpenses')"
        style="display: inline-block;margin-left: 20px;"
      />
    </div>
    <!--导入提示-->
    <!-- <el-dialog
      :title="$t('receivablePayable.ImportPrompts')"
      :append-to-body="true"
      :visible.sync="showImportFailed"
      width="50%"
    >
      <div class="errTable">
        <div style="margin-bottom: 10px;color: #f00;">
          总共导入*条数据
          {{ $t('receivablePayable.TotalImport') }}{{ errData.totalCount }}{{ $t('receivablePayable.piecesDataImported') }}，
          全部成功
          {{ $t('receivablePayable.AllSuccessful') }}{{ errData.allSuccessCount }}{{ $t('receivablePayable.dataSuccessful') }}，
          部分成功
          {{ $t('receivablePayable.PartiallySuccessful') }}{{ errData.partialSuccessCount }}{{ $t('receivablePayable.piecesData') }}，
          全部失败
          {{ $t('receivablePayable.AllFail') }}{{ errData.allErrorCount }}{{ $t('receivablePayable.piecesData') }}
        </div>
        <el-table border :data="errData.errorItemList">
          业务单号
          <el-table-column :label="$t('receivablePayable.TicketNumber')" prop="waybillNumber" width="200" />
          费用类型
          <el-table-column :label="$t('basicData.ExpenseType')" prop="feeCode" width="160">
            <template slot-scope="scope">{{ feeType[scope.row.feeCode] }}</template>
          </el-table-column>
          失败原因
          <el-table-column :label="$t('receivablePayable.ReasonForFailure')" prop="errorMsg" />
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        确 定
        <el-button type="primary" @click="showImportFailed = false">{{ $t('GlobalSetObj.confirm') }}</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import uploadExcel from '@/components/UploadExcel';
import { apiCustomerExpenseImportEditFee, apiImportEditFeeTemplate } from '@/api/finance/receivables';
import { goFileUploadManagement } from '@/utils/goExportList.js';
import { saveAs } from 'file-saver';

const MAX_LENGTH = 100000; // 最大导入长度

export default {
  name: 'BatchAddChange',
  components: {
    uploadExcel
  },
  data() {
    return {
      // errData: [],
      // showImportFailed: false,
      feeType: null
    };
  },

  created() {
    // 每次最多导入xxxx条数据
    this.lenTips = this.$t('receivablePayable.Only50000PiecesImport').replace('50000', MAX_LENGTH);
  },

  methods: {
    // 导入文件为填项补充
    dataSorting(header, result) {
      const arrData = result.map(item => {
        header.map(list => {
          item[list] = item[list];
          for (const k in item) {
            if (list === k) {
              item[list] = (item[list] !== null && item[list] !== undefined && item[list] !== '') ? item[list] : '';
            }
          }
        });
        return item;
      });
      return arrData;
    },
    // 将文件头按组分类
    groupHeader(arr, length) {
      let ind = 0;
      const newArr = [];
      while (ind < arr.length) {
        newArr.push(arr.slice(ind, ind += length));
      }
      return newArr;
    },
    // 导入时间格式化
    // formatDate(numb) {
    //   const reg = /^((\d{2}(([02468][048])|([13579][26]))[-\/\s]?((((0?[13578])|(1[02]))[-\/\s]?((0?[1-9])|([1-2][0-9])|(3[01])))|(((0?[469])|(11))[-\/\s]?((0?[1-9])|([1-2][0-9])|(30)))|(0?2[-\/\s]?((0?[1-9])|([1-2][0-9])))))|(\d{2}(([02468][1235679])|([13579][01345789]))[-\/\s]?((((0?[13578])|(1[02]))[-\/\s]?((0?[1-9])|([1-2][0-9])|(3[01])))|(((0?[469])|(11))[-\/\s]?((0?[1-9])|([1-2][0-9])|(30)))|(0?2[-\/\s]?((0?[1-9])|(1[0-9])|(2[0-8]))))))(\s((([0-1][0-9])|(2?[0-3]))\:([0-5]?[0-9])((\s)|(\:([0-5]?[0-9])))))?$/;
    //   if (reg.test(numb)) {
    //     const date = new Date(numb);
    //     return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
    //   } else {
    //     const old = numb - 1;
    //     if (isNaN(old) === true) {
    //       return true;
    //     } else {
    //       const t = Math.round((old - Math.floor(old)) * 24 * 60 * 60);
    //       const time = new Date(1900, 0, old, 0, 0, t);
    //       return dayjs(time).format('YYYY-MM-DD HH:mm:ss');
    //     }
    //   }
    // },
    // 导入
    importLogistics(arr) {
      const feeType1 = arr[2].results[0];
      this.feeType = {};
      for (const k1 in feeType1) {
        this.feeType[feeType1[k1]] = k1;
      }
      const headerDataArr = [];
      arr[0].header.map(item => {
        if (item.indexOf('UNKNOWN') === -1) {
          headerDataArr.push(item);
        }
      });
      const logisticsNo = {};
      logisticsNo[headerDataArr[0]] = 'waybillNumber';
      logisticsNo[headerDataArr[1]] = 'orderNumber';
      logisticsNo[headerDataArr[2]] = 'chargeWeight';
      const headerArr = headerDataArr.slice(3);
      const header = this.groupHeader(headerArr, 3);
      const headObj = header.map(item => {
        const obj = {};
        item.map((list, ind) => {
          switch (ind) {
            case 0:
              obj[list] = 'businessType';
              break;
            case 1:
              obj[list] = 'fee';
              break;
            case 2:
              obj[list] = 'remark';
              break;
          }
        });
        return obj;
      });
      const results = arr[0].results.slice(2);
      const allData = this.dataSorting(headerDataArr, results);
      const dataArr = [];
      // this.errData = [];
      allData.map((item) => {
        const dataObj = {};
        const priceItemList = [];
        headObj.map(list => {
          const dataKey = {};
          let chargeWeight = '';
          let orderNumber = '';
          for (const k in item) {
            if (k === headerDataArr[0]) {
              dataObj[logisticsNo[k]] = item[k];
              dataObj['priceItemList'] = [];
            }
            if (k === headerDataArr[1]) {
              orderNumber = item[k] ? item[k] : '';
              dataObj[logisticsNo[k]] = orderNumber;
            }
            if (k === headerDataArr[2]) {
              chargeWeight = item[k] ? item[k] : '';
              dataObj[logisticsNo[k]] = chargeWeight;
            }
            dataKey[list[k]] = list[k] ? item[k] : '';
            if (list[k] === 'fee') {
              dataKey['feeCode'] = feeType1[k];
            }
          }
          if (dataKey.fee || dataKey.remark) {
            const obj = {
              fee: (dataKey.fee !== null && dataKey.fee !== undefined && dataKey.fee !== '') ? dataKey.fee.toString().trim() : '',
              businessType: (dataKey.businessType !== null && dataKey.businessType !== undefined && dataKey.businessType !== '') ? dataKey.businessType.split('-')[1] : '',
              feeCode: (dataKey.feeCode !== null && dataKey.feeCode !== undefined && dataKey.feeCode !== '') ? dataKey.feeCode.toString().trim() : '',
              remark: (dataKey.remark !== null && dataKey.remark !== undefined && dataKey.remark !== '') ? dataKey.remark.toString().trim() : ''
            };
            priceItemList.push(obj);
          }
        });
        dataObj['priceItemList'] = priceItemList;
        dataArr.push(dataObj);
      });

      // 长度提示
      if (dataArr.length > MAX_LENGTH) {
        this.$message.warning(this.lenTips);
        return;
      }

      apiCustomerExpenseImportEditFee(dataArr).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功');
          // this.errData = res.data;
          // this.showImportFailed = true;
          goFileUploadManagement(this);
          this.$emit('changeFeeList', true);
        }
      });
    },
    // 下载模板
    operationBtn() {
      apiImportEditFeeTemplate().then(res => {
        saveAs(res.data, this.$t('receivablePayable.ImportModifyExpenses') + '.xlsx');
      });
    }
  }
};
</script>

<style>
.errTable .el-table .el-table__cell{
  padding: 5px 0;
}
</style>
