var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value: "btn:supplierManagement:VendorRole:add",
                        expression: "'btn:supplierManagement:VendorRole:add'",
                        arg: "remove",
                      },
                    ],
                    attrs: {
                      size: "small",
                      type: "primary",
                      disabled: _vm.operationType === 2,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.addVendorRole(1)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("roleManage.addRole")))]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value: "btn:supplierManagement:VendorRole:edit",
                        expression: "'btn:supplierManagement:VendorRole:edit'",
                        arg: "remove",
                      },
                    ],
                    attrs: {
                      size: "small",
                      type: "primary",
                      disabled: _vm.operationType !== null,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.editVendorRole(2)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("GlobalSetObj.edit")))]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value: "btn:supplierManagement:VendorRole:enable",
                        expression:
                          "'btn:supplierManagement:VendorRole:enable'",
                        arg: "remove",
                      },
                    ],
                    attrs: {
                      size: "small",
                      type: "primary",
                      disabled: _vm.isQt,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.updateStatusType(1)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("GlobalSetObj.enable")))]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value: "btn:supplierManagement:VendorRole:stop",
                        expression: "'btn:supplierManagement:VendorRole:stop'",
                        arg: "remove",
                      },
                    ],
                    attrs: {
                      size: "small",
                      type: "primary",
                      disabled: _vm.isQt,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.updateStatusType(0)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("GlobalSetObj.stop")))]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      disabled: _vm.isSave,
                      size: "small",
                      type: "primary",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.saveVendorRole()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("basicData.Save")))]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      disabled: _vm.isSave,
                      size: "small",
                      type: "danger",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.clearVendorRole()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("basicData.Cancel")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "vendorRoleList",
                staticStyle: { "margin-top": "10px" },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "vendorRoleData",
                    staticStyle: { height: "100%" },
                    attrs: {
                      size: "mini",
                      model: _vm.vendorRoleData,
                      "label-width": "0",
                    },
                  },
                  [
                    _c(
                      "el-table",
                      {
                        ref: "vendorRoleList",
                        attrs: {
                          data: _vm.vendorRoleData.addVendorRoleList,
                          height: _vm.tableHeight,
                          border: "",
                          "header-cell-class-name": _vm.disabledCheckAll,
                        },
                        on: {
                          select: _vm.handleSelectionChange,
                          "row-click": _vm.selectionRow,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            type: "selection",
                            width: "55",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("operationCenter.ind"),
                            width: "60px",
                            align: "center",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.total -
                                          ((_vm.queryForm.pageNumber - 1) *
                                            _vm.queryForm.pageSize +
                                            scope.$index)
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "roleName",
                            label: _vm.$t("GlobalSetObj.ChineseRoleName"),
                            align: "center",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "formStyle" },
                                    [
                                      scope.row.isEdit === false
                                        ? _c("div", [
                                            _vm._v(_vm._s(scope.row.roleName)),
                                          ])
                                        : _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "addVendorRoleList." +
                                                  scope.$index +
                                                  ".roleName",
                                                rules: {
                                                  required: true,
                                                  message: _vm.$t(
                                                    "basicData.CannotBeEmpty"
                                                  ),
                                                  trigger: ["blur", "change"],
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        scope.row.isChange = true
                                                      },
                                                    },
                                                    model: {
                                                      value: scope.row.roleName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "roleName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.roleName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "portugal",
                            label: _vm.$t(
                              "GlobalSetObj.PortugueseCharacterNames"
                            ),
                            align: "center",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "formStyle" },
                                    [
                                      scope.row.isEdit === false
                                        ? _c("div", [
                                            _vm._v(_vm._s(scope.row.portugal)),
                                          ])
                                        : _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "addVendorRoleList." +
                                                  scope.$index +
                                                  ".portugal",
                                                rules: {
                                                  required: true,
                                                  message: _vm.$t(
                                                    "basicData.CannotBeEmpty"
                                                  ),
                                                  trigger: ["blur", "change"],
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        scope.row.isChange = true
                                                      },
                                                    },
                                                    model: {
                                                      value: scope.row.portugal,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "portugal",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.portugal",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "state",
                            label: _vm.$t("GlobalSetObj.RoleStatus"),
                            align: "center",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "formStyle" },
                                    [
                                      scope.row.isEdit === false
                                        ? _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.state === 0
                                                  ? _vm.$t("GlobalSetObj.stop")
                                                  : _vm.$t(
                                                      "GlobalSetObj.enable"
                                                    )
                                              )
                                            ),
                                          ])
                                        : _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "addVendorRoleList." +
                                                  scope.$index +
                                                  ".state",
                                                rules: {
                                                  required: true,
                                                  message: _vm.$t(
                                                    "basicData.CannotBeEmpty"
                                                  ),
                                                  trigger: ["blur", "change"],
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    filterable: "",
                                                    placeholder: _vm.$t(
                                                      "operationCenter.PleaseSelect"
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      scope.row.isChange = true
                                                    },
                                                  },
                                                  model: {
                                                    value: scope.row.state,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "state",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.state",
                                                  },
                                                },
                                                [
                                                  _c("el-option", {
                                                    key: 0,
                                                    attrs: {
                                                      label:
                                                        _vm.$t(
                                                          "GlobalSetObj.stop"
                                                        ),
                                                      value: 0,
                                                    },
                                                  }),
                                                  _c("el-option", {
                                                    key: 1,
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "GlobalSetObj.enable"
                                                      ),
                                                      value: 1,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "updateTime",
                            label: _vm.$t("GlobalSetObj.LastModified"),
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "updateUserName",
                            label: _vm.$t("GlobalSetObj.LatestModifiedBy"),
                            align: "center",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "pagination" },
              [
                _c("el-pagination", {
                  staticStyle: { margin: "10px 0" },
                  attrs: {
                    layout: "total, sizes, prev, pager, next, jumper",
                    "current-page": _vm.queryForm.pageNumber,
                    "page-sizes": [10, 20, 50, 100],
                    "page-size": _vm.queryForm.pageSize,
                    total: _vm.total,
                    background: "",
                  },
                  on: {
                    "update:currentPage": function ($event) {
                      return _vm.$set(_vm.queryForm, "pageNumber", $event)
                    },
                    "update:current-page": function ($event) {
                      return _vm.$set(_vm.queryForm, "pageNumber", $event)
                    },
                    "update:pageSize": function ($event) {
                      return _vm.$set(_vm.queryForm, "pageSize", $event)
                    },
                    "update:page-size": function ($event) {
                      return _vm.$set(_vm.queryForm, "pageSize", $event)
                    },
                    "current-change": _vm.currentTableData,
                    "size-change": _vm.sizeChangeTableData,
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-tabs",
                { attrs: { type: "border-card" } },
                [
                  _c("el-tab-pane", { attrs: { label: "PC" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          height: "calc(100vh - 180px)",
                          overflow: "auto",
                        },
                      },
                      [
                        _c("el-tree", {
                          key: _vm.treeKey,
                          ref: "pcTree",
                          attrs: {
                            "show-checkbox": "",
                            "node-key": "id",
                            "check-strictly": true,
                            "check-on-click-node": "",
                            "default-expand-all": "",
                            data: _vm.permissionPcList,
                            "expand-on-click-node": false,
                            props: _vm.defaultRole,
                            "default-checked-keys": _vm.defaultRolePcCheck,
                          },
                          on: { check: _vm.handleCheckPcChange },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (ref) {
                                var node = ref.node
                                var data = ref.data
                                return _c(
                                  "span",
                                  { staticClass: "custom-tree-node mb" },
                                  [
                                    _c("span", { staticClass: "titlts" }, [
                                      data.parentId === 0
                                        ? _c("i", {
                                            staticClass:
                                              "iconfont icon-folder-open",
                                          })
                                        : _c("i", {
                                            staticClass:
                                              "iconfont icon-wenjian",
                                          }),
                                      _vm._v(" " + _vm._s(data.name) + " "),
                                    ]),
                                  ]
                                )
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-tab-pane", { attrs: { label: "PDA" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          height: "calc(100vh - 180px)",
                          overflow: "auto",
                        },
                      },
                      [
                        _c("el-tree", {
                          key: _vm.treeKey,
                          ref: "pdaTree",
                          attrs: {
                            "show-checkbox": "",
                            "node-key": "id",
                            "check-strictly": true,
                            "check-on-click-node": "",
                            "default-expand-all": "",
                            data: _vm.permissionPadList,
                            "expand-on-click-node": false,
                            props: _vm.defaultRole,
                            "default-checked-keys": _vm.defaultRolePadCheck,
                          },
                          on: { check: _vm.handleCheckPadChange },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (ref) {
                                var node = ref.node
                                var data = ref.data
                                return _c(
                                  "span",
                                  { staticClass: "custom-tree-node mb" },
                                  [
                                    _c("span", { staticClass: "titlts" }, [
                                      data.parentId === 0
                                        ? _c("i", {
                                            staticClass:
                                              "iconfont icon-folder-open",
                                          })
                                        : _c("i", {
                                            staticClass:
                                              "iconfont icon-wenjian",
                                          }),
                                      _vm._v(" " + _vm._s(data.name) + " "),
                                    ]),
                                  ]
                                )
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }