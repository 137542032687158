var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Box" },
    [
      _c(
        "el-form",
        {
          staticClass: "selectClass",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    width: "auto",
                    display: "flex",
                    "justify-content": "flex-start",
                    "align-items": "flex-start",
                  },
                  attrs: { span: 10 },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "selectTimeClass autoWidth",
                      attrs: {
                        placeholder: _vm.$t("collectionCenter.pleaseChoose"),
                      },
                      on: { change: _vm.changeTime },
                      model: {
                        value: _vm.selectTime,
                        callback: function ($$v) {
                          _vm.selectTime = $$v
                        },
                        expression: "selectTime",
                      },
                    },
                    [
                      _c("template", { slot: "prefix" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              (
                                _vm.timeList.find(function (item) {
                                  return item.value === _vm.selectTime
                                }) || {}
                              ).time
                            ) +
                            " "
                        ),
                      ]),
                      _vm._l(_vm.timeList, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.time, value: item.value },
                        })
                      }),
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-date-picker", {
                        attrs: {
                          clearable: false,
                          type: "datetimerange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "default-time": ["00:00:00", "23:59:59"],
                          "range-separator": _vm.$t("collectionCenter.to"),
                          "start-placeholder": _vm.$t(
                            "collectionCenter.StartTime"
                          ),
                          "end-placeholder": _vm.$t("collectionCenter.EndTime"),
                        },
                        on: { change: _vm.dateChange },
                        model: {
                          value: _vm.timeValue,
                          callback: function ($$v) {
                            _vm.timeValue = $$v
                          },
                          expression: "timeValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              staticStyle: { "flex-wrap": "wrap" },
              attrs: { type: "flex", justify: "start" },
            },
            [
              _c(
                "el-col",
                { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                [
                  _c("el-form-item", [
                    _c(
                      "div",
                      { staticClass: "flex-start" },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "autoWidth",
                            attrs: {
                              placeholder: _vm.$t(
                                "collectionCenter.pleaseChoose"
                              ),
                            },
                            on: { change: _vm.chooseON },
                            model: {
                              value: _vm.chooseNum,
                              callback: function ($$v) {
                                _vm.chooseNum = $$v
                              },
                              expression: "chooseNum",
                            },
                          },
                          [
                            _c("template", { slot: "prefix" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (
                                      _vm.orderList.find(function (item) {
                                        return item.value === _vm.chooseNum
                                      }) || {}
                                    ).label
                                  ) +
                                  " "
                              ),
                            ]),
                            _vm._l(_vm.orderList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                          ],
                          2
                        ),
                        _vm.chooseNum == 1
                          ? _c("el-input", {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseInput"
                                ),
                                type: "textarea",
                                clearable: "",
                              },
                              on: { blur: _vm.ft1 },
                              nativeOn: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.BlockNewline1($event)
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.orderNumber,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "orderNumber",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.orderNumber",
                              },
                            })
                          : _vm._e(),
                        _vm.chooseNum == 2
                          ? _c("el-input", {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseInput"
                                ),
                                type: "textarea",
                                clearable: "",
                              },
                              on: { blur: _vm.ft2 },
                              nativeOn: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.BlockNewline2($event)
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.scanNumber,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "scanNumber",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.scanNumber",
                              },
                            })
                          : _vm._e(),
                        _vm.chooseNum == 3
                          ? _c("el-input", {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseInput"
                                ),
                                type: "textarea",
                                clearable: "",
                              },
                              on: { blur: _vm.ft3 },
                              nativeOn: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.BlockNewline3($event)
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.oneLicencePlate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "oneLicencePlate",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.oneLicencePlate",
                              },
                            })
                          : _vm._e(),
                        _vm.chooseNum == 4
                          ? _c("el-input", {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseInput"
                                ),
                                type: "textarea",
                                clearable: "",
                              },
                              on: { blur: _vm.ft4 },
                              nativeOn: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.BlockNewline4($event)
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.twoLicencePlate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "twoLicencePlate",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.twoLicencePlate",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("collectionCenter.PlatformWhichBelongs"),
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: _vm.$t(
                              "collectionCenter.pleaseChoose"
                            ),
                          },
                          model: {
                            value: _vm.queryForm.params.platform,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm.params, "platform", $$v)
                            },
                            expression: "queryForm.params.platform",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("collectionCenter.other"),
                              value: 0,
                            },
                          }),
                          _c("el-option", {
                            attrs: { label: "shein", value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: "kwai", value: 2 },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("collectionCenter.expressDelivery"),
                              value: 3,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("collectionCenter.receivingMode") + "：",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            placeholder: _vm.$t("collectionCenter.pleaseInput"),
                          },
                          model: {
                            value: _vm.queryForm.params.customerCollectType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "customerCollectType",
                                $$v
                              )
                            },
                            expression: "queryForm.params.customerCollectType",
                          },
                        },
                        [
                          _c("el-option", {
                            key: 0,
                            attrs: {
                              label: _vm.$t("collectionCenter.tudo"),
                              value: null,
                            },
                          }),
                          _c("el-option", {
                            key: 2,
                            attrs: {
                              label: _vm.$t("collectionCenter.toCollect"),
                              value: 2,
                            },
                          }),
                          _c("el-option", {
                            key: 1,
                            attrs: {
                              label: _vm.$t("collectionCenter.dropOff"),
                              value: 1,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("collectionCenter.orderStatus") },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t(
                              "collectionCenter.pleaseChoose"
                            ),
                            clearable: "",
                            filterable: "",
                            multiple: "",
                          },
                          model: {
                            value: _vm.queryForm.params.collectStatusList,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "collectStatusList",
                                $$v
                              )
                            },
                            expression: "queryForm.params.collectStatusList",
                          },
                        },
                        _vm._l(_vm.orderTypeList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.collectStatusName,
                              value: item.collectStatus,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("collectionCenter.postState") } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("collectionCenter.BatchQuery"),
                          autosize: "",
                          type: "textarea",
                        },
                        on: { blur: _vm.ftS },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.BlockNewlineS($event)
                          },
                        },
                        model: {
                          value: _vm.queryForm.params.senderState,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm.params, "senderState", $$v)
                          },
                          expression: "queryForm.params.senderState",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("collectionCenter.postCity") } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("collectionCenter.BatchQuery"),
                          autosize: "",
                          type: "textarea",
                        },
                        on: { blur: _vm.ftC },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.BlockNewlineC($event)
                          },
                        },
                        model: {
                          value: _vm.queryForm.params.senderCity,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm.params, "senderCity", $$v)
                          },
                          expression: "queryForm.params.senderCity",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:collection-center:collect-time-report:find",
                      expression:
                        "'btn:collection-center:collect-time-report:find'",
                      arg: "remove",
                    },
                  ],
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-search",
                  },
                  on: { click: _vm.searchClick },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.search")))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:collection-center:collect-time-report:reset",
                      expression:
                        "'btn:collection-center:collect-time-report:reset'",
                      arg: "remove",
                    },
                  ],
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetClick },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value:
                        "btn:collection-center:collect-time-report:exportAll",
                      expression:
                        "'btn:collection-center:collect-time-report:exportAll'",
                      arg: "remove",
                    },
                  ],
                  class: _vm.isClickAll ? "onlyClickOnce10" : "",
                  attrs: {
                    icon: "el-icon-upload2",
                    size: "mini",
                    type: "primary",
                  },
                  on: { click: _vm.downloadAll },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-table",
        {
          ref: "tableRef",
          attrs: { "max-height": 600, border: "", data: _vm.tableDate },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.ind"),
              type: "index",
              width: "60",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          (_vm.queryForm.pageNumber - 1) *
                            _vm.queryForm.pageSize +
                            scope.$index +
                            1
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "orderNumber",
              label: _vm.$t("collectionCenter.lanshouOrderNum"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "scanNumber",
              label: _vm.$t("collectionCenter.Barcode"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "collectStatusName",
              label: _vm.$t("collectionCenter.OrderStatus"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "customerId",
              label: _vm.$t("collectionCenter.clientID"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "customerName",
              label: _vm.$t("collectionCenter.CustomerName"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "merchantCode",
              label: _vm.$t("collectionCenter.MerchantNumber"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "merchantName",
              label: _vm.$t("collectionCenter.MerchantName"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "senderState",
              label: _vm.$t("collectionCenter.sendState"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "senderCity",
              label: _vm.$t("collectionCenter.sendCity"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "senderZipcode",
              label: _vm.$t("collectionCenter.postZ"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "sendMacroRegion",
              label: _vm.$t("collectionCenter.senderSubregion"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "sendMicroRegion",
              label: _vm.$t("collectionCenter.senderOwningRegion"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "oneLoadedNumber",
              label: _vm.$t("collectionCenter.firstLoadingLicensePlate"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "twoLoadedNumber",
              label: _vm.$t("collectionCenter.secondLoadingLicensePlate"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "customerCollectTypeName",
              label: _vm.$t("collectionCenter.receivingMode"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "platform",
              label: _vm.$t("collectionCenter.platform"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            scope.row.platform == 0
                              ? _vm.$t("collectionCenter.other")
                              : scope.row.platform == 1
                              ? "shein"
                              : scope.row.platform == 2
                              ? "kwai"
                              : scope.row.platform == 3
                              ? _vm.$t("collectionCenter.expressDelivery")
                              : ""
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "siteName",
              label: _vm.$t("collectionCenter.LanShouSite"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "collectMacroRegion",
              label: _vm.$t("collectionCenter.collectionNetworkSubregion"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "collectMicroRegion",
              label: _vm.$t("collectionCenter.collectionNetworkOwningRegion"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "scanTime",
              label: _vm.$t("collectionCenter.ActualCollectionTime"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "oneLoadedTime",
              label: _vm.$t("collectionCenter.firstLoadingTime"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "oneUnloadTime",
              label: _vm.$t("collectionCenter.firstUnloadingTime"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "twoLoadedTime",
              label: _vm.$t("collectionCenter.secondLoadingTime"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "closeTime",
              label: _vm.$t("collectionCenter.closeTime"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "signerTime",
              label: _vm.$t("collectionCenter.signT"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "reachTime",
              label: _vm.$t("collectionCenter.vehicleArrivalTime"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "collectDuration",
              label: _vm.$t("collectionCenter.collectDuration"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "transferDuration",
              label: _vm.$t("collectionCenter.transferDuration"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "transportDuration",
              label: _vm.$t("collectionCenter.transportDuration"),
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-pagination", {
            staticStyle: { margin: "10px 0" },
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              "current-page": _vm.queryForm.pageNumber,
              "page-sizes": [10, 20, 50, 100, 500, 1000],
              "page-size": _vm.queryForm.pageSize,
              total: _vm.total,
              background: "",
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "current-change": _vm.getPage,
              "size-change": _vm.getPageSize,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }