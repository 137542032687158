var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { staticClass: "demo-ruleForm", attrs: { "label-width": "120px" } },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("GlobalSetObj.client") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.customerName))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("Resource.applicant") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.applicant))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t(
                      "ClaimsManagement.ApplicantsContactInformation"
                    ),
                  },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.ruleForm.applicantContactInformation)),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("collectionCenter.businessT") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.claimsNodeStr))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("ClaimsManagement.AmountOfClaim") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.claimAmount))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("ClaimsManagement.ActualClaimAmount"),
                  },
                },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.actualClaimAmount))])]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("ClaimsManagement.CauseOfClaim") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.claimReason))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$t("collectionCenter.CommercialOwner") },
                },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.merchantsName))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("customerManagements.enclosure") } },
                [
                  _c(
                    "ul",
                    _vm._l(_vm.ruleForm.attachmentUrl, function (item) {
                      return _c(
                        "li",
                        { key: item.url },
                        [
                          _c(
                            "el-link",
                            {
                              attrs: {
                                type: "primary",
                                target: "_blank",
                                href: item.url,
                              },
                            },
                            [
                              _vm._v(_vm._s(item.name)),
                              _c("i", {
                                staticClass: "el-icon-view el-icon--right",
                              }),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }