var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { height: "calc(100vh - 170px)", "overflow-y": "auto" },
    },
    [
      _c("el-divider", [
        _vm._v(_vm._s(_vm.$t("customerManagements.FTPInformation"))),
      ]),
      _c(
        "el-form",
        { ref: "formDataFTP", attrs: { model: _vm.formData } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("customerManagements.FTPAddress"),
                        prop: "ftpHost",
                        rules: [
                          {
                            required: true,
                            message: _vm.$t("customerManagements.pleaseInput"),
                            trigger: ["blur", "change"],
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t(
                            "customerManagements.pleaseInput"
                          ),
                        },
                        model: {
                          value: _vm.formData.ftpHost,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "ftpHost", $$v)
                          },
                          expression: "formData.ftpHost",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("customerManagements.FTPAccount"),
                        prop: "ftpUsername",
                        rules: [
                          {
                            required: true,
                            message: _vm.$t("customerManagements.pleaseInput"),
                            trigger: ["blur", "change"],
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t(
                            "customerManagements.pleaseInput"
                          ),
                        },
                        model: {
                          value: _vm.formData.ftpUsername,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "ftpUsername", $$v)
                          },
                          expression: "formData.ftpUsername",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("customerManagements.FTPPassword"),
                        prop: "ftpPassword",
                        rules: [
                          {
                            required: true,
                            message: _vm.$t("customerManagements.pleaseInput"),
                            trigger: ["blur", "change"],
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t(
                            "customerManagements.pleaseInput"
                          ),
                        },
                        model: {
                          value: _vm.formData.ftpPassword,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "ftpPassword", $$v)
                          },
                          expression: "formData.ftpPassword",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("customerManagements.ConnectionType"),
                        prop: "ftpType",
                        rules: [
                          {
                            required: true,
                            message: _vm.$t("customerManagements.pleaseInput"),
                            trigger: ["blur", "change"],
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t(
                              "customerManagements.pleaseInput"
                            ),
                          },
                          model: {
                            value: _vm.formData.ftpType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "ftpType", $$v)
                            },
                            expression: "formData.ftpType",
                          },
                        },
                        [
                          _c("el-option", {
                            key: 0,
                            attrs: { label: "FTP", value: "FTP" },
                          }),
                          _c("el-option", {
                            key: 1,
                            attrs: {
                              label: "SFTP OVER SSH",
                              value: "SFTP OVER SSH",
                            },
                          }),
                          _c("el-option", {
                            key: 2,
                            attrs: {
                              label: "FTP using implicit SSL",
                              value: "FTP using implicit SSL",
                            },
                          }),
                          _c("el-option", {
                            key: 3,
                            attrs: {
                              label: "FTP using explicit SSL(Auth SSL)",
                              value: "FTP using explicit SSL(Auth SSL)",
                            },
                          }),
                          _c("el-option", {
                            key: 4,
                            attrs: {
                              label: "FTP using explicit SSL(Auth TLS)",
                              value: "FTP using explicit SSL(Auth TLS)",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("customerManagements.FTPPort") + "(P)",
                        prop: "ftpPort",
                        rules: [
                          {
                            required: true,
                            message: _vm.$t("customerManagements.pleaseInput"),
                            trigger: ["blur", "change"],
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "number",
                          placeholder: _vm.$t(
                            "customerManagements.pleaseInput"
                          ),
                        },
                        model: {
                          value: _vm.formData.ftpPort,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "ftpPort", $$v)
                          },
                          expression: "formData.ftpPort",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("customerManagements.fileReadingPath"),
                        prop: "ftpPath",
                        rules: [
                          {
                            required: true,
                            message: _vm.$t("customerManagements.pleaseInput"),
                            trigger: ["blur", "change"],
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t(
                            "customerManagements.pleaseInput"
                          ),
                        },
                        model: {
                          value: _vm.formData.ftpPath,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "ftpPath", $$v)
                          },
                          expression: "formData.ftpPath",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t(
                          "customerManagements.TrajectoryUploadPath"
                        ),
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t(
                            "customerManagements.pleaseInput"
                          ),
                        },
                        model: {
                          value: _vm.formData.ftpUploadPath,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "ftpUploadPath",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.ftpUploadPath",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.customer.type === "edit" || _vm.customer.type === "add"
        ? _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.clearData()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("customerManagements.cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onSubmit()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("customerManagements.confirm")))]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }