var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container pda" },
    [
      _c("el-tree", {
        attrs: {
          data: _vm.permissionList,
          "expand-on-click-node": false,
          props: _vm.defaultProps,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var node = ref.node
              var data = ref.data
              return _c("span", { staticClass: "custom-tree-node mb" }, [
                _c("span", { staticClass: "titlts" }, [
                  _c("i", { staticClass: "iconfont el-icon-mobile-phone" }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.language == "zh" ? data.name : data.puName) +
                      " "
                  ),
                ]),
                _c(
                  "span",
                  { staticClass: "custom-tree-node-btn" },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permit",
                            rawName: "v-permit:remove",
                            value: "btn:user-center:permission:add2",
                            expression: "'btn:user-center:permission:add2'",
                            arg: "remove",
                          },
                        ],
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function () {
                            return _vm.addPdaChild(node, data)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("organizationManage.addNextLevel"))
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "custom-tree-node-btn" },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permit",
                            rawName: "v-permit:remove",
                            value: "btn:user-center:permission:add1",
                            expression: "'btn:user-center:permission:add1'",
                            arg: "remove",
                          },
                        ],
                        attrs: { size: "mini", type: "success" },
                        on: {
                          click: function () {
                            return _vm.addPdaBrother(node, data)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("organizationManage.addSameLevel"))
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "custom-tree-node-btn" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "info" },
                        on: {
                          click: function () {
                            return _vm.editNode(node, data)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("organizationManage.edit")))]
                    ),
                  ],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "custom-tree-node-btn" },
                  [
                    _c(
                      "el-popconfirm",
                      {
                        attrs: { title: _vm.$t("roleManage.AreYouSureDelete") },
                        on: {
                          confirm: function ($event) {
                            return _vm.delNode(node, data)
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              size: "mini",
                              type: "danger",
                            },
                            slot: "reference",
                          },
                          [_vm._v(_vm._s(_vm.$t("organizationManage.Delete")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            },
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "pdaPrmClass" },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.pda.title,
                visible: _vm.pda.visible,
                width: "30%",
                "close-on-click-modal": true,
              },
              on: {
                "update:visible": function ($event) {
                  return _vm.$set(_vm.pda, "visible", $event)
                },
                close: _vm.closePDAPermission,
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "pdaPermissionRef",
                  attrs: {
                    model: _vm.pdaPermissonForm,
                    rules: _vm.pdaPermissonRules,
                    "label-width": "100px",
                    "label-position": "left",
                  },
                },
                [
                  _vm.pda.title !== _vm.$t("roleManage.addPermissions")
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("roleManage.permissionsID"),
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.pdaPermissonForm.id,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.pdaPermissonForm,
                                          "id",
                                          $$v
                                        )
                                      },
                                      expression: "pdaPermissonForm.id",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.pdaPermissonForm.parentId !== 0,
                          expression: "pdaPermissonForm.parentId!==0",
                        },
                      ],
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("roleManage.parentPermissionID"),
                                prop: "parentId",
                              },
                            },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: false,
                                    expression: "false",
                                  },
                                ],
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.pdaPermissonForm.parentId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.pdaPermissonForm,
                                      "parentId",
                                      $$v
                                    )
                                  },
                                  expression: "pdaPermissonForm.parentId",
                                },
                              }),
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.pdaPermissonForm.parentName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.pdaPermissonForm,
                                      "parentName",
                                      $$v
                                    )
                                  },
                                  expression: "pdaPermissonForm.parentName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("roleManage.permissionsName"),
                                prop: "name",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { autocomplete: "off" },
                                model: {
                                  value: _vm.pdaPermissonForm.name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.pdaPermissonForm,
                                      "name",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "pdaPermissonForm.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("roleManage.permissionsNamePu"),
                                prop: "puName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { autocomplete: "off" },
                                model: {
                                  value: _vm.pdaPermissonForm.puName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.pdaPermissonForm,
                                      "puName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "pdaPermissonForm.puName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("roleManage.permissionsNameEn"),
                                prop: "enName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { autocomplete: "off" },
                                model: {
                                  value: _vm.pdaPermissonForm.enName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.pdaPermissonForm,
                                      "enName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "pdaPermissonForm.enName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("roleManage.permissionsTag"),
                                prop: "code",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { autocomplete: "off" },
                                model: {
                                  value: _vm.pdaPermissonForm.code,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.pdaPermissonForm,
                                      "code",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "pdaPermissonForm.code",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("roleManage.sort"),
                                prop: "sort",
                                rules: [
                                  {
                                    required: true,
                                    message: _vm.$t("roleManage.sortNotEmpty"),
                                  },
                                  {
                                    type: "number",
                                    message: _vm.$t("roleManage.sortIsNumber"),
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "sort",
                                  placeholder: _vm.$t(
                                    "roleManage.littleValueFront"
                                  ),
                                  autocomplete: "off",
                                },
                                model: {
                                  value: _vm.pdaPermissonForm.sort,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.pdaPermissonForm,
                                      "sort",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "pdaPermissonForm.sort",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("roleManage.note") } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autocomplete: "off",
                                },
                                model: {
                                  value: _vm.pdaPermissonForm.remark,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.pdaPermissonForm,
                                      "remark",
                                      $$v
                                    )
                                  },
                                  expression: "pdaPermissonForm.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { size: "large" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "primary" },
                                  on: { click: _vm.onSubmit },
                                },
                                [_vm._v(_vm._s(_vm.$t("roleManage.confirm")))]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      _vm.pda.visible = false
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("roleManage.cancel")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }