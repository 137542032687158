var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container pwd" }, [
    _c(
      "div",
      { staticClass: "box" },
      [
        _c(
          "el-card",
          [
            _c("h3", [_vm._v(_vm._s(_vm.$t("changepwd.setNewPwd")))]),
            _c(
              "el-form",
              {
                ref: "ruleForm",
                attrs: {
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-width": "100px",
                },
              },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("changepwd.oldPwd"),
                      prop: "oldPassword",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        "show-password": "",
                        placeholder: _vm.$t("changepwd.inputOldPwd"),
                      },
                      model: {
                        value: _vm.ruleForm.oldPassword,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "oldPassword", $$v)
                        },
                        expression: "ruleForm.oldPassword",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("changepwd.newPwd"),
                      prop: "newPassword",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: _vm.$t("changepwd.inputNewPwd"),
                        "show-password": "",
                        minlength: "8",
                        maxlength: "12",
                      },
                      model: {
                        value: _vm.ruleForm.newPassword,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.ruleForm,
                            "newPassword",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "ruleForm.newPassword",
                      },
                    }),
                    _c("span", { staticClass: "tips" }, [
                      _vm._v(_vm._s(_vm.$t("changepwd.inputHint"))),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("changepwd.checkPwd"),
                      prop: "newPasswordConfirm",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: _vm.$t("changepwd.inputConfirmCode"),
                        "show-password": "",
                        minlength: "8",
                        maxlength: "12",
                      },
                      model: {
                        value: _vm.ruleForm.newPasswordConfirm,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.ruleForm,
                            "newPasswordConfirm",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "ruleForm.newPasswordConfirm",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.submitForm("ruleForm")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("changepwd.confirm")))]
                    ),
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.resetForm("ruleForm")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("changepwd.reset")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }