var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "seal__container" },
    [
      _c(
        "o-field",
        { attrs: { label: "Lacres de entrada" } },
        [
          _c("o-inputitems", {
            ref: "inputSealIn",
            attrs: {
              icon: "tag",
              placeholder: "Digite os lacres a serem fixados",
              "aria-close-label": "Retirar lacre",
              "allow-new": false,
              "open-on-focus": false,
            },
            scopedSlots: _vm._u([
              {
                key: "selected",
                fn: function (ref) {
                  var items = ref.items
                  return _vm._l(items, function (item, index) {
                    return _c(
                      "span",
                      {
                        key: index,
                        staticClass: "item_selected",
                        attrs: { rounded: "" },
                        on: {
                          click: function ($event) {
                            return _vm.$refs.inputSealIn.removeItem(
                              index,
                              $event
                            )
                          },
                        },
                      },
                      [
                        _vm._v(" " + _vm._s(item) + " "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "5px",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.inputSealIn.removeItem(
                                  index,
                                  $event
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "lucide lucide-x",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "16",
                                  height: "16",
                                  viewBox: "0 0 24 24",
                                  fill: "none",
                                  stroke: "currentColor",
                                  "stroke-width": "2",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                },
                              },
                              [
                                _c("path", { attrs: { d: "M18 6 6 18" } }),
                                _c("path", { attrs: { d: "m6 6 12 12" } }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  })
                },
              },
            ]),
            model: {
              value: _vm.form.truck_seal_in,
              callback: function ($$v) {
                _vm.$set(_vm.form, "truck_seal_in", $$v)
              },
              expression: "form.truck_seal_in",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        { attrs: { label: "Lacres de saída" } },
        [
          _c("o-inputitems", {
            ref: "inputSealOut",
            attrs: {
              icon: "tag",
              placeholder: "Digite os lacres a serem retirados",
              "aria-close-label": "Retirar lacre",
              "allow-new": false,
              "open-on-focus": false,
            },
            scopedSlots: _vm._u([
              {
                key: "selected",
                fn: function (ref) {
                  var items = ref.items
                  return _vm._l(items, function (item, index) {
                    return _c(
                      "span",
                      {
                        key: index,
                        staticClass: "item_selected",
                        attrs: { rounded: "" },
                        on: {
                          click: function ($event) {
                            return _vm.$refs.inputSealOut.removeItem(
                              index,
                              $event
                            )
                          },
                        },
                      },
                      [
                        _vm._v(" " + _vm._s(item) + " "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "5px",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.inputSealOut.removeItem(
                                  index,
                                  $event
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "lucide lucide-x",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "16",
                                  height: "16",
                                  viewBox: "0 0 24 24",
                                  fill: "none",
                                  stroke: "currentColor",
                                  "stroke-width": "2",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                },
                              },
                              [
                                _c("path", { attrs: { d: "M18 6 6 18" } }),
                                _c("path", { attrs: { d: "m6 6 12 12" } }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  })
                },
              },
            ]),
            model: {
              value: _vm.form.truck_seal_out,
              callback: function ($$v) {
                _vm.$set(_vm.form, "truck_seal_out", $$v)
              },
              expression: "form.truck_seal_out",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }