var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "o-button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isFirstStep,
              expression: "!isFirstStep",
            },
          ],
          attrs: { variant: "outline", size: "md" },
          on: {
            click: function ($event) {
              return _vm.emit("handlePrevious")
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("driver.modalPreviousButton")) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }