module.exports = {

  title: '安骏快递系统',
  // title: 'OA',

  /**
   * @type {boolean} true | false
   * @description 是否固定Header
   */
  fixedHeader: false,
  tagsView: true,
  /**
   * @type {boolean} true | false
   * @description 是否在侧边栏中显示LOGO
   */
  sidebarLogo: true
};
