var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticStyle: { "margin-bottom": "10px" } },
      [
        _c(
          "el-card",
          {
            staticClass: "box-card",
            staticStyle: {
              background: "#CEF5ED",
              "margin-bottom": "10px",
              "font-size": "16px",
            },
          },
          [
            _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
              _vm._v(
                " " + _vm._s(_vm.$t("GlobalSetObj.PackageScanningRemove"))
              ),
            ]),
            _c("div", [
              _vm._v(_vm._s(_vm.$t("GlobalSetObj.beforeScannerTip"))),
            ]),
          ]
        ),
        _c("el-card", { staticClass: "box-card" }, [
          _c(
            "div",
            {
              staticClass: "titleStyle",
              staticStyle: { "margin-bottom": "10px" },
            },
            [_vm._v(_vm._s(_vm.$t("GlobalSetObj.ScanToRemove")))]
          ),
          _c(
            "div",
            [
              _c("span", { staticStyle: { "font-size": "16px" } }, [
                _vm._v(_vm._s(_vm.$t("GlobalSetObj.waybillNumber")) + "："),
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "inline-block",
                    width: "50%",
                    "margin-right": "20px",
                  },
                },
                [
                  _c("el-input", {
                    ref: "inputVal",
                    attrs: {
                      autofocus: _vm.autofocus,
                      placeholder: _vm.$t("GlobalSetObj.ScanOrwaybillNumber"),
                      clearable: "",
                    },
                    on: { blur: _vm.delShowErr },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.eventEnter()
                      },
                    },
                    model: {
                      value: _vm.waybillNumber,
                      callback: function ($$v) {
                        _vm.waybillNumber =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "waybillNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.remove } },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.RemoveOutBag")))]
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "el-row",
          { attrs: { gutter: 10 } },
          [
            _c("el-col", { attrs: { span: 10 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple" },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      staticStyle: { height: "625px" },
                    },
                    [
                      _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                        _c("span", { staticClass: "titleStyle" }, [
                          _vm._v(
                            _vm._s(_vm.$t("GlobalSetObj.CurrentlyRemoving"))
                          ),
                        ]),
                      ]),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.tableDataLeft,
                            height: _vm.height,
                            border: "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.waybillNumber"),
                              "min-width": "200",
                              prop: "waybillNumber",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.client"),
                              prop: "customerName",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.weight"),
                              prop: "weight",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("el-col", { attrs: { span: 14 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple" },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      staticStyle: { height: "625px" },
                    },
                    [
                      _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                        _c("span", { staticClass: "titleStyle" }, [
                          _vm._v(_vm._s(_vm.$t("GlobalSetObj.restTheBag"))),
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("GlobalSetObj.bagNumber")) +
                            "：" +
                            _vm._s(_vm.BagNumber || "") +
                            " "
                        ),
                        _c("span", { staticStyle: { float: "right" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("GlobalSetObj.package")) + "："
                          ),
                          _c("span", [_vm._v(_vm._s(_vm.number))]),
                        ]),
                      ]),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.tableDataLeftShow,
                            height: _vm.height,
                            border: "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.serial"),
                              prop: "index",
                              width: "80",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.indexNo)),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.waybillNumber"),
                              "min-width": "200",
                              prop: "waybillNumber",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.weight"),
                              prop: "weight",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.operator"),
                              prop: "create_user",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.operationTime"),
                              prop: "create_time",
                              width: "160",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("el-pagination", {
                        staticStyle: {
                          "margin-top": "10px",
                          float: "right",
                          "margin-bottom": "20px",
                        },
                        attrs: {
                          "current-page": _vm.page.pageNumber,
                          "page-size": _vm.page.pageSize,
                          "page-sizes": [10, 20, 50, 100],
                          "pager-count": 5,
                          total: _vm.page.total,
                          background: "",
                          layout: "total, sizes, prev, pager, next",
                          small: "",
                        },
                        on: {
                          "size-change": _vm.SizeChange,
                          "current-change": _vm.CurrentChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
      },
      [
        _c("audio", {
          ref: "errRef",
          attrs: { src: _vm.errorMp3, controls: "controls" },
        }),
        _c("audio", {
          ref: "okRef",
          attrs: { src: _vm.okMp3, controls: "controls" },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }