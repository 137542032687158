
// 职务管理
const jobManagement = {
  add: {
    zh: '新增',
    en: 'add',
    pu: 'adicionar'
  },
  name: {
    zh: '名称',
    en: 'name',
    pu: 'nome'
  },
  SubordinateDepartments: {
    zh: '所属部门',
    en: '',
    pu: 'departamento a qual se pertence'
  },
  operation: {
    zh: '操作',
    en: 'operation',
    pu: 'Operar'
  },
  edit: {
    zh: '编辑',
    en: 'Edit',
    pu: 'editar'
  },
  cancel: {
    zh: '取消',
    en: 'cancel',
    pu: 'Cancelar'
  },
  confirm: {
    zh: '确定',
    en: 'determine',
    pu: 'determinar'
  },
  AddedSuccessfully: {
    zh: '添加成功',
    en: 'Added successfully',
    pu: 'Adicionado com sucesso'
  },
  EditSuccessful: {
    zh: '编辑成功',
    en: 'Edit successful',
    pu: 'Editar bem-sucedido'
  },
  department: {
    zh: '部门',
    en: '',
    pu: 'departamento'
  },
  JobTitle: {
    zh: '职位名称',
    en: '',
    pu: 'nome da posição'
  },
  choose: {
    zh: '请选择',
    en: 'Please select',
    pu: 'Favor selecionar'
  },
  input: {
    zh: '请输入',
    en: 'Please enter the content',
    pu: 'por favor digitar'
  },
  pleaseChoose: {
    zh: '请选择部门',
    en: '',
    pu: 'favor selecionar o departamento'
  },
  pleaseInput: {
    zh: '请输入职位名称',
    en: '',
    pu: 'favor digitar o nome da posição'
  }
};
export {
  jobManagement
};
