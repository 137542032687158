var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      attrs: {
        clearable: "",
        filterable: "",
        remote: "",
        "remote-method": _vm.asyncGetUserPage,
        loading: _vm.loading,
        placeholder: _vm.$t("spareNumberPoo.Only10AreDisplayed"),
      },
      on: { change: _vm.changeUser },
      model: {
        value: _vm.val1,
        callback: function ($$v) {
          _vm.val1 = $$v
        },
        expression: "val1",
      },
    },
    _vm._l(_vm.userList, function (item) {
      return _c(
        "el-option",
        { key: item.id, attrs: { label: item.name, value: item.id } },
        [
          _c("span", { staticStyle: { float: "left" } }, [
            _vm._v(_vm._s(item.name)),
          ]),
          _vm.isUserName
            ? _c(
                "span",
                {
                  staticStyle: {
                    float: "right",
                    color: "#8492a6",
                    "font-size": "13px",
                  },
                },
                [_vm._v(_vm._s(item.username))]
              )
            : _vm._e(),
          _vm.isOrganizationName
            ? _c(
                "span",
                {
                  staticStyle: {
                    float: "right",
                    color: "#8492a6",
                    "font-size": "13px",
                  },
                },
                [_vm._v(_vm._s(item.organizationName))]
              )
            : _vm._e(),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }