var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.partitionId
      ? _c(
          "div",
          [
            _c(
              "el-radio-group",
              {
                attrs: { size: "small" },
                model: {
                  value: _vm.partitionAffiliateId,
                  callback: function ($$v) {
                    _vm.partitionAffiliateId = $$v
                  },
                  expression: "partitionAffiliateId",
                },
              },
              _vm._l(_vm.subregionList, function (item) {
                return _c(
                  "el-radio-button",
                  { key: item.id, attrs: { label: item.id } },
                  [_vm._v(" " + _vm._s(item.partitionIdentifier) + " ")]
                )
              }),
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "priceTableForm", staticStyle: { "margin-top": "10px" } },
      [
        _c(
          "div",
          { staticStyle: { "margin-bottom": "10px" } },
          [
            _c(
              "el-button",
              {
                attrs: {
                  size: "small",
                  type: "primary",
                  disabled: _vm.isAddDisabled,
                },
                on: { click: _vm.handleAdd },
              },
              [_vm._v(_vm._s(_vm.$t("basicData.AddARow")))]
            ),
            _c("importAndDowloadCmp", {
              attrs: {
                "partition-id": _vm.partitionId,
                "subregion-list": _vm.subregionList,
                "get-data-func": _vm.onDownloadOffer,
              },
              on: { importList: _vm.onImportList },
            }),
            _c(
              "el-popover",
              { attrs: { placement: "bottom-start", trigger: "hover" } },
              [
                _c("div", [
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "10px" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.onClear },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("basicData.EmptyCurrentPartition")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.onAllClear },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("basicData.EmptyAllPartitions")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { slot: "reference", size: "small" },
                    slot: "reference",
                  },
                  [_vm._v(_vm._s(_vm.$t("basicData.ClearTheOffer")))]
                ),
              ],
              1
            ),
          ],
          1
        ),
        !_vm.partitionId
          ? _c("TableCmp", {
              ref: "defaultTableRef",
              attrs: { "table-data": _vm.defaultPartitionObj.default.arr },
              on: {
                change: function (arr) {
                  return (_vm.defaultPartitionObj.default.arr = arr)
                },
              },
            })
          : _vm._e(),
        _vm.partitionId && _vm.subregionList.length > 0
          ? _c("TableCmp", {
              ref: "tableRef",
              attrs: { "table-data": _vm.getPartitionArr },
              on: { change: _vm.changePartition },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }