var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      staticStyle: { width: "100%" },
      attrs: {
        data: _vm.tableData,
        "min-height": 480,
        height: 480,
        border: "",
      },
    },
    [
      _c("el-table-column", {
        attrs: {
          type: "index",
          label: _vm.$t("operationCenter.ind"),
          width: "60px",
          align: "center",
        },
      }),
      _c("el-table-column", {
        attrs: {
          label: _vm.$t("GlobalSetObj.operationTime"),
          prop: "createTime",
        },
      }),
      _c("el-table-column", {
        attrs: { label: _vm.$t("userManage.node"), prop: "operationNodeStr" },
      }),
      _c("el-table-column", {
        attrs: {
          label: _vm.$t("GlobalSetObj.operator"),
          prop: "createUserStr",
        },
      }),
      _c("el-table-column", {
        attrs: { label: _vm.$t("GlobalSetObj.details"), prop: "remarks" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }