<script >
import dayjs from 'dayjs';
import { ref, computed } from '@vue/composition-api';
import { default as Card } from '@/components/card';
import { dashboardService } from '@/api/towerControl/dashboard';
import { useQueries } from '@tanstack/vue-query';
import i18n from '@/lang';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const RANGE_DAYS = 7;
  const mounth = [i18n.t('dashboard.jan'), i18n.t('dashboard.feb'), i18n.t('dashboard.mar'), i18n.t('dashboard.apr'), i18n.t('dashboard.may'), i18n.t('dashboard.jun'), i18n.t('dashboard.jul'), i18n.t('dashboard.aug'), i18n.t('dashboard.sep'), i18n.t('dashboard.oct'), i18n.t('dashboard.nov'), i18n.t('dashboard.dec')];
  function getListWeekDays() {
    const currentDate = new Date();
    const listDays = [];
    for (let i = 0; i < RANGE_DAYS; i++) {
      listDays.push({
        shortDateFormat: `${currentDate.getDate()} / ${mounth[currentDate.getMonth()]}`,
        longDateFormat: dayjs(currentDate).format('YYYY/MM/DD')
      });
      currentDate.setDate(currentDate.getDate() - 1);
    }
    return listDays;
  }
  const queries = computed(() => {
    const listWeekDays = getListWeekDays();
    return listWeekDays.map((day, index) => {
      return {
        queryKey: ['getHistoricDailyCollectFee', index],
        queryFn: async () => {
          try {
            const res = await dashboardService.getDailyCollectFee(day.longDateFormat);
            return res;
          } catch (error) {
            if (error.response && error.response.status === 400) {
              return {};
            }
            throw error;
          }
        },
        networkMode: 'always',
        refetchOnWindowFocus: false,
        retry: 3,
        keepPreviousData: false,
        refetchInterval: 300000
      };
    });
  });
  const listDataChart = useQueries({
    queries: queries.value
  });
  function collectedPackages(listQueryResults) {
    return listQueryResults.map(queryResult => {
      return queryResult.data?.data?.packagesCollect ?? 0;
    });
  }
  function uncollectedPackages() {
    return listDataChart.map(queryResult => {
      return queryResult.data?.data?.packagesToBeCollect ?? 0;
    });
  }
  const chartOptions = ref({
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 2,
        columnWidth: '66%',
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: '12px',
              fontWeight: 900
            }
          }
        }
      }
    },
    xaxis: {
      categories: getListWeekDays().map(day => day.shortDateFormat)
    },
    colors: ['#219F54', '#FF4040'],
    grid: {
      show: false,
      borderColor: '#e2e8f0'
    }
  });
  return {
    listDataChart,
    collectedPackages,
    uncollectedPackages,
    chartOptions
  };
};
__sfc_main.components = Object.assign({
  Card
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <Card width="100%">
      <h2 class="header-2 m-t-20">{{ $t('dashboard.collectionHistory') }}</h2>
      <apexchart
        type="bar"
        height="350"
        :options="chartOptions"
        :series="[{
          name: $t('dashboard.collectedPackages'),
          data: collectedPackages(listDataChart)

        }, {
          name: $t('dashboard.uncollectedPackages'),
          data: uncollectedPackages(listDataChart)
        }]"
      />
    </Card>
  </div>
</template>

<style scoped>
.m-t-20 {
  margin-bottom: 1.25rem; /* 20px */
}
</style>
