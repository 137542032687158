import i18n from '@/lang';
import store from '@/store';

// 业务类型
export const BUSINESS_TYPE = Object.freeze({
  1: {
    label: i18n.t('collectionCenter.turnTransport'), // 转运
    value: 1
  },
  2: {
    label: i18n.t('collectionCenter.lanshou'), // 揽收
    value: 2
  },
  3: {
    label: i18n.t('collectionCenter.dispatch'), // 派送
    value: 3
  }
});

export const BUSINESS_TYPE_TWO = Object.freeze({
  1: {
    label: i18n.t('receivablePayable.AnjunCottage'), // 安俊小屋
    value: 1
  },
  2: {
    label: i18n.t('receivablePayable.ThirdPartyCollection'), // 第三方揽收
    value: 2
  },
  3: {
    label: i18n.t('receivablePayable.AJDDispatch'), // AJD派送
    value: 3
  },
  4: {
    label: i18n.t('receivablePayable.DSPDispatch'), // DSP派送
    value: 4
  },
  5: {
    label: i18n.t('receivablePayable.Transportation_trunk_branch'), // 运输（干/支线)
    value: 5
  }
});

// 车型
export const VEHICLE_MODEL = Object.freeze({
  0: {
    label: 'FIORINO',
    value: 0
  },
  1: {
    label: 'VAN',
    value: 1
  },
  2: {
    label: 'VUC',
    value: 2
  },
  3: {
    label: '3/4',
    value: 3
  },
  4: {
    label: 'TOCO',
    value: 4
  },
  5: {
    label: 'TRUCK',
    value: 5
  },
  6: {
    label: 'CARRETA',
    value: 6
  }
});
// 运输干支线报价车型
export const Transport_VEHICLE_MODEL = Object.freeze({
  0: {
    label: 'vuc',
    value: 0
  },
  1: {
    label: '3/4',
    value: 1
  },
  3: {
    label: 'ToCo',
    value: 3
  },
  4: {
    label: 'van',
    value: 4
  },
  5: {
    label: 'Fiorino',
    value: 5
  },
  6: {
    label: 'Truck',
    value: 6
  },
  7: {
    label: 'Carreta',
    value: 7
  }
  // 100: {
  //   label: i18n.t('basicData.other'),
  //   value: 100
  // }
});

// 状态
export const STATUS = Object.freeze({
  0: {
    label: i18n.t('basicData.Expired'), // 过期
    type: 'info',
    value: 0
  },
  1: {
    label: i18n.t('basicData.Used'), // 已使用
    type: 'success',
    value: 1
  },
  2: {
    label: i18n.t('basicData.NotUsed'), // 未使用
    type: 'warning',
    value: 2
  },
  3: {
    label: i18n.t('basicData.disable'), // 禁用
    type: 'danger',
    value: 3
  }
});

// 揽收类别
export const COLLECTION_CATEGORY_TYPE = Object.freeze({
  1: {
    label: i18n.t('basicData.OrdinaryPickUp'), // 一般
    value: 1
  },
  2: {
    label: i18n.t('basicData.StockingAndPickup'), // 特殊
    value: 2
  }
});

// 物流订单
export const WAYBILL_STATUS = Object.freeze([
  { label: i18n.t('newOrder.all'), code: 0 }, // 待分配
  { label: i18n.t('newOrder.ToBeAssigned'), code: 1 }, // 待分配
  { label: i18n.t('newOrder.ToBeCollected'), code: 2 }, // 待揽收
  { label: i18n.t('newOrder.Collected'), code: 3 }, // 已揽收
  { label: i18n.t('newOrder.CollectTheRevenuePool'), code: 4 }, // 揽收入库
  { label: i18n.t('newOrder.CollectTakeOutWarehouse'), code: 5 }, // 揽收出库
  { label: i18n.t('GlobalSetObj.moduan'), code: 19 }, // 末端已揽收
  { label: i18n.t('newOrder.TransshipmentIntoStorage'), code: 6 }, // 转运入库
  { label: i18n.t('newOrder.TransferOutWarehouse'), code: 7 }, // 转运出库
  { label: i18n.t('newOrder.DeliveryOutletsIntoStorage'), code: 8 }, // 派送网点入库
  { label: i18n.t('newOrder.Delivering'), code: 9 }, // 派送中
  { label: i18n.t('newOrder.Signed'), code: 10 }, // 已签收
  { label: i18n.t('newOrder.ProblemPiece'), code: 11 }, // 问题件
  { label: i18n.t('GlobalSetObj.Canceled'), code: 13 }, // 已取消
  { label: i18n.t('GlobalSetObj.deliveryFailure'), code: 14 }, // 投递失败
  { label: i18n.t('GlobalSetObj.DotBack'), code: 17 }, // 网点退回
  { label: i18n.t('GlobalSetObj.ReturnToStorage'), code: 18 }, // 退仓入库
  { label: i18n.t('GlobalSetObj.Withdrawn'), code: 15 }, // 退件中
  { label: i18n.t('GlobalSetObj.TheReturnComplete'), code: 16 }, // 退件完成
  { label: i18n.t('GlobalSetObj.mutilSign'), code: 20 }, // 多次签收
  { label: i18n.t('GlobalSetObj.processSettlement'), code: 21 }, // 理赔中
  { label: i18n.t('GlobalSetObj.ClaimsCompleted'), code: 22 } // 理赔完成
]);

// 揽收订单
export const ORDER_TYPE_LIST = Object.freeze([
  { collectStatus: 0, collectStatusName: i18n.t('collectionCenter.notLanShou') }, // '未揽收'
  { collectStatus: 1, collectStatusName: i18n.t('collectionCenter.hasLanShou') }, // '已揽收'
  { collectStatus: 2, collectStatusName: i18n.t('collectionCenter.cancelled') }, // 已取消
  { collectStatus: 4, collectStatusName: i18n.t('collectionCenter.scheduled') }, // 已调度
  { collectStatus: 5, collectStatusName: i18n.t('collectionCenter.AbnormalLanShou') }, // 揽收异常
  { collectStatus: 6, collectStatusName: i18n.t('collectionCenter.haveBeenSigned') }, // 已签收
  { collectStatus: 7, collectStatusName: i18n.t('collectionCenter.ProblemPiece') }, // 问题件
  { collectStatus: 8, collectStatusName: i18n.t('collectionCenter.SealedBag') }, // 已封袋
  { collectStatus: 9, collectStatusName: i18n.t('collectionCenter.truckLoading') }, // 装车
  { collectStatus: 10, collectStatusName: i18n.t('collectionCenter.unloading') }, // 卸车
  { collectStatus: 11, collectStatusName: i18n.t('collectionCenter.Bagged') }, // 已装袋
  { collectStatus: 12, collectStatusName: i18n.t('collectionCenter.closedLoop') }, // 已闭环
  { collectStatus: 13, collectStatusName: i18n.t('collectionCenter.unclaimed') }, // 待取件
  { collectStatus: 14, collectStatusName: i18n.t('collectionCenter.collectionPointInventory') }// 揽收网点入库
]);

// 备货揽收订单
export const BH_ORDER_TYPE_LIST = Object.freeze([ // 订单状态
  { collectStatus: 0, collectStatusName: i18n.t('collectionCenter.notLanShou') }, // '未揽收'
  { collectStatus: 1, collectStatusName: i18n.t('collectionCenter.hasLanShou') }, // '已揽收'
  { collectStatus: 2, collectStatusName: i18n.t('collectionCenter.haveBeenSigned') }, // 已签收
  { collectStatus: 3, collectStatusName: i18n.t('collectionCenter.cancelled') }, // 已取消
  { collectStatus: 4, collectStatusName: i18n.t('collectionCenter.truckLoading') }, // 装车
  { collectStatus: 5, collectStatusName: i18n.t('collectionCenter.unloading') }, // 卸车
  { collectStatus: 6, collectStatusName: i18n.t('operatingPlatform.collectWarehousing') } // 网点揽收入库
]);

// 税费类型
export const TAX_TYPE = Object.freeze({
  0: {
    label: i18n.t('GlobalSetObj.No'), // 否
    value: 0
  },
  1: {
    label: i18n.t('basicData.sourceWithholdingTaxSestSenat'), // 源头代扣税Sest Senat
    value: 1
  },
  2: {
    label: i18n.t('basicData.sourceWithholdingTaxINSS'), // 源头代扣税INSS
    value: 2
  },
  3: {
    label: i18n.t('basicData.sourceWithholdingTaxIRRF'), // 代扣个人所得税IRRF
    value: 3
  }
});

/**
 * 获取是否存在权限
 * @param {string} key 权限字段
 * @returns {boolean}
 */
export const getIsAuth = (key) => {
  // return store.getters.permissions.includes(key)
  return store.getters.button_permissions.includes(key);
  // return false;
};

/**
 * 校验是否存在区间重复 重复返回 true
 * @param {[number, number][]} arr
 * @param {string} type
 * @returns {boolean} 重复返回 true
 */
export function checkIntervalsOverlap(arr, type = '[)') {
  const len = arr.length;
  if (len === 1) {
    return false;
  }

  arr.sort((a, b) => {
    return a[0] - b[0];
  });

  for (let i = 0; i <= len - 2; i++) {
    // 如果是 [0,1] [1,2] 则使用 >=
    if (type === '[]') {
      if (arr[i][1] >= arr[i + 1][0]) {
        return true;
      }
    }

    if (type === '[)') {
      // 如果是 [0,1) [1,2) 则使用 >
      if (arr[i][1] > arr[i + 1][0]) {
        return true;
      }
    }
  }
  return false;
}
