var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-container box" }, [
    _c("div", { staticClass: "warp" }, [
      _c(
        "div",
        { ref: "imgRef", staticClass: "imgs" },
        [
          _c(
            "el-carousel",
            { attrs: { height: _vm.carouselHeight, trigger: "click" } },
            _vm._l(_vm.carouselArr, function (item) {
              return _c("el-carousel-item", { key: item.imgSrc }, [
                _c("div", { staticClass: "carousel-img", style: item }),
              ])
            }),
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "login" }, [
        _c("div", { staticClass: "login-box" }, [
          _c("div", { staticClass: "title" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("login.anjunKD")))]),
            _c("p", [_vm._v(_vm._s(_vm.$t("login.WELCOME")))]),
          ]),
          _c("div", { staticClass: "inp_box" }, [
            _c("div", { staticClass: "name" }, [
              _c("div", [
                _c("i", { staticClass: "el-icon-user" }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.loginForm.username,
                      expression: "loginForm.username",
                      modifiers: { trim: true },
                    },
                  ],
                  attrs: {
                    min: "3",
                    max: "20",
                    autofocus: "",
                    placeholder: _vm.$t("login.inputNumber"),
                    type: "text",
                  },
                  domProps: { value: _vm.loginForm.username },
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.handleLogin($event)
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.loginForm,
                        "username",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "name pwd" }, [
              _c("div", [
                _c("i", { staticClass: "el-icon-lock" }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.loginForm.password,
                      expression: "loginForm.password",
                      modifiers: { trim: true },
                    },
                  ],
                  attrs: {
                    type: "password",
                    min: "3",
                    max: "20",
                    placeholder: _vm.$t("login.passwordPrompt"),
                  },
                  domProps: { value: _vm.loginForm.password },
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.handleLogin($event)
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.loginForm,
                        "password",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "but" }, [
            _c(
              "button",
              { attrs: { type: "button" }, on: { click: _vm.handleLogin } },
              [_vm._v(_vm._s(_vm.$t("login.login")))]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }