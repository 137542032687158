<template>
  <div class="app-container">
    <el-form :inline="false" label-position="top" size="small">
      <el-row :gutter="20">
        <el-col :span="6">
          <!--  选择车签号   -->
          <el-form-item :label="$t('navbar.selectNIG')">
            <el-select
              v-model="printObj.vehicleTagNumber"
              :placeholder="$t('GlobalSetObj.Only10AreDisplayed')"
              :remote-method="getTagNumber"
              clearable
              filterable
              remote
              reserve-keyword
              size="small"
              style="width: 100%;"
              @focus="focegetTagNumber"
            >
              <el-option
                v-for="(item,index) in tagNumberList"
                :key="index"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!--  打印分数   -->
          <el-form-item :label="$t('collectionCenter.PrintCopies')" prop="inputValue">
            <el-select
              v-model.trim="printObj.printCount"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              filterable
              style="width: 100%"
            >
              <el-option
                v-for="i in 10" :key="i" :label="i"
                :value="i"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item style="margin-top: 28px">
            <!-- 打印 -->
            <el-button size="small" type="primary" @click="clickPrint">{{
              $t('GlobalSetObj.Print')
            }}
            </el-button>
            <!-- 导出PDF -->
            <el-button size="small" @click="clickPdf">{{ "导出PDF" }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="titleClass">{{ $t('collectionCenter.printRecord') }}</div>
    <!--  表格数据  -->
    <el-table
      :cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      :header-cell-style="{background:'#f2f2f2',color:'black','text-align': 'center'}"
      border
      style="width: 100%"
    >
      <el-table-column :label="$t('GlobalSetObj.serial')" type="index" width="80" />
      <el-table-column
        :label="$t('collectionCenter.printTime')"
        min-width="180"
        prop="createTime"
      />
      <el-table-column
        :label="$t('GlobalSetObj.NIV')"
        min-width="180"
        prop="vehicleTagNumber"
      />
      <el-table-column
        :label="$t('GlobalSetObj.operator')"
        min-width="150"
        prop="operatorUser"
      />
      <el-table-column
        :label="$t('collectionCenter.PrintCopies')"
        min-width="150"
        prop="printCount"
      />
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.number"
      :page-size="queryForm.pageSize"
      :page-sizes="[15, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!--打印大包号-->
    <div class="printStyle">
      <printBagNumberHtml
        v-if="showPrintBagNumberHtml"
        :param-data="printBagObj"
        @hiddenBagNumberHtml="hiddenBagNumberHtml"
      />
    </div>
    <!--打印大包号-->
    <div v-if="isPdfContent">
      <div id="pdfContent" class="fontSizeStyle">
        <div style="display: flex;align-items: center;width: 100%">
          <img :src="require('@/assets/ajlogo1.svg')" alt="tu" style="width: 100px;">
          <div style="font-size: 20px;margin-left: 20px;">RELATÓRIO DE EXPEDIÇÃO-DESTINO ✖ IATAS</div>
        </div>
        <table style="width: 100%;text-align: left;margin-top: 15px;border-collapse: collapse;">
          <tr>
            <td>Data: {{ tagDatasiteObj.uploadTime || '--' }}</td>
            <td>Vtag: {{ tagDatasiteObj.vehicleTagNumber || '--' }}</td>
          </tr>
          <tr style="background-color: #ffc000">
            <td colspan="2">DADOS MOTORISTA/ VEÍCULO:</td>
          </tr>
          <tr>
            <td>Nome: {{ tagDatasiteObj.vehicleDriver || '--' }}</td>
            <td style="background-color: #add88d">Outras informações</td>
          </tr>
          <tr>
            <td>RG/CPF: {{ tagDatasiteObj.itin || '--' }}</td>
            <td rowspan="4">{{ tagDatasiteObj.remark || '--' }}</td>
          </tr>
          <tr>
            <td>Veiculo: {{ tagDatasiteObj.vehicleTypeName || '--' }}</td>
          </tr>
          <tr>
            <td>Inicio Carregamento/Horário: {{ tagDatasiteObj.startLoadingTime || '--' }}</td>
          </tr>
          <tr>
            <td>Fim Carregamento/Horário: {{ tagDatasiteObj.endLoadingTime || '--' }}</td>
          </tr>
          <tr>
            <td colspan="2" style="background-color: #ffc000">Destino das lATAS (Expedicao/ Local)</td>
          </tr>
          <tr>
            <td>
              <div>Placa:</div>
              <div style="text-align: center;">{{ tagDatasiteObj.vehicleNumber || '--' }}</div>
              <div style="display: flex;justify-content: center">
                <div id="qrCanvas1" />
              </div>
            </td>
            <td>
              <div>Vtag:</div>
              <div style="text-align: center;">{{ tagDatasiteObj.vehicleTagNumber || '--' }}</div>
              <div style="display: flex;justify-content: center">
                <div id="barcode1" />
              </div>
            </td>
          </tr>
        </table>
        <div style="margin: 10px 0;">CONTAGEM</div>
        <table style="width: 100%;border-collapse: collapse;text-align: center;">
          <thead>
            <tr style="background-color: #add88d">
              <th>No.</th>
              <th style="width: 140px;">IATA</th>
              <th>Número de sacolas</th>
              <th>Quantidade de pacote grande</th>
              <th>Quantidade de pacote</th>
              <th>Tempo de descarga</th>
              <th>Número de descargas</th>
              <th>Confirmação da assinatura</th>
            </tr>
          </thead>
          <tbody
            v-for="(item1, index1) in tagDatasiteObj.siteAddressesSort01" :key="index1"
            style="text-align: center;"
          >
            <tr>
              <td rowspan="2">{{ item1.sort || '--' }}</td>
              <td style="width: 140px;">{{ item1.name || '--' }}</td>
              <td>{{ item1.bagPackageCount || '--' }}</td>
              <td>{{ item1.cargoPackageCount || '--' }}</td>
              <td>{{ item1.totalPackageCount || '--' }}</td>
              <td />
              <td />
              <td />
            </tr>
            <tr style="text-align: left">
              <td colspan="5">Endereço: {{ item1.originAddress || '--' }}</td>
            </tr>
          </tbody>
        </table>
        <div style="display: flex;justify-content: space-between;margin-top: 20px;">
          <div style="border-bottom: 1px solid #000000">
            <div style="margin-bottom: 30px;">Nome Motorista</div>
          </div>
          <div style="border-bottom: 1px solid #000000">
            <div style="margin-bottom: 30px;">Nome Anjun (Expedição Perus)</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import printBagNumberHtml from './printBagNumber';
import { loadingDocuments, printLoadingDocuments } from '@/api/storeAdmin';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { getUserName } from '@/utils/asyncTools';
import { getTagNumberAll } from '@/api/logisticsOrders';
import { pdf } from './html2canvas';
import QRCode from 'qrcodejs2';

export default {
  name: 'LoadingDocument',
  components: {
    printBagNumberHtml
  },
  data() {
    return {
      // 是否格式化成巴西时间
      showPrintBagNumberHtml: false,
      printBagObj: [],
      isTormat: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      timeValue: [],
      queryForm: {
        pageSize: 15,
        number: 1,
        total: 0
      },
      printObj: {
        printCount: 1,
        vehicleTagNumber: null
      },
      tagNumberList: [], // 车签号
      tableData: [],
      userIdAndNameObj: null,
      tagDatasiteObj: {},
      isPdfContent: false
    };
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.userIdAndNameObj = getUserIdAndNameObj();
    this.getTagNumber();
    this.getSearch();
  },
  methods: {
    // 清空打印袋号数据
    hiddenBagNumberHtml() {
      this.showPrintBagNumberHtml = false;
      this.resetForm();
      this.enterSearch();
    },
    enterSearch() {
      this.queryForm.number = 1;
      this.getSearch();
    },
    generateQRCode01() {
      this.$nextTick(() => {
        const qrCanvas = document.getElementById('qrCanvas1');
        new QRCode(qrCanvas, {
          width: 100,
          height: 100,
          text: this.tagDatasiteObj?.vehicleId, // 二维码地址
          colorDark: '#000',
          colorLight: '#fff'
        });
      });
    },
    generateQRCode02() {
      this.$nextTick(() => {
        const qrCanvas = document.getElementById('barcode1');
        new QRCode(qrCanvas, {
          width: 100,
          height: 100,
          text: this.tagDatasiteObj?.vehicleTagNumber, // 二维码地址
          colorDark: '#000',
          colorLight: '#fff'
        });
      });
    },
    clickPdf() {
      if (!this.printObj.vehicleTagNumber) {
        this.$message.error('请选择车签号');
        return;
      }
      const data = {
        printCount: this.printObj.printCount,
        vehicleTagNumber: this.printObj.vehicleTagNumber
      };
      printLoadingDocuments(data).then(res => {
        if (res.status === 'OK') {
          let siteAddressesSort01 = [];
          if (res.data.siteAddressesSort) {
            siteAddressesSort01 = JSON.parse(res.data.siteAddressesSort);
            siteAddressesSort01.sort((a, b) => {
              return a.sort - b.sort;
            });
          }
          res.data.siteAddressesSort01 = siteAddressesSort01;
          this.tagDatasiteObj = res.data;
          this.isPdfContent = true;
          this.generateQRCode01();
          this.generateQRCode02();
          this.$nextTick(() => {
            const element = document.getElementById('pdfContent');
            pdf('printHtml', element).then(() => {
              this.isPdfContent = false;
              this.resetForm();
            }).catch((err) => {
              this.isPdfContent = false;
            });
          });
        }
      });
    },
    // 点击打印
    clickPrint() {
      if (!this.printObj.vehicleTagNumber) {
        this.$message.error('请选择车签号');
        return;
      }
      if (!this.printObj.printCount) {
        this.$message.error(this.$t('collectionCenter.inputcopies'));
        return;
      }
      const data = {
        printCount: this.printObj.printCount,
        vehicleTagNumber: this.printObj.vehicleTagNumber
      };
      printLoadingDocuments(data).then(res => {
        if (res.status === 'OK') {
          let siteAddressesSort01 = [];
          if (res.data.siteAddressesSort) {
            siteAddressesSort01 = JSON.parse(res.data.siteAddressesSort);
            siteAddressesSort01.sort((a, b) => {
              return a.sort - b.sort;
            });
          }
          res.data.siteAddressesSort01 = siteAddressesSort01;
          this.printBagObj = [];
          for (let i = 0; i < this.printObj.printCount; i++) {
            this.printBagObj.push(res.data);
          }
          this.showPrintBagNumberHtml = true;
        }
      });
    },
    // 重置
    resetForm() {
      this.printBagObj = [];
      this.printObj = {
        printCount: 1,
        vehicleTagNumber: null
      };
      this.tagDatasiteObj = {};
    },
    // 查询
    getSearch() {
      const data = {
        params: {}
      };
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.number;
      loadingDocuments(data).then(res => {
        if (res.status === 'OK') {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
          this.tableData.forEach((item) => {
            item.operatorUser = getUserName(item.createUser, this.userIdAndNameObj);
          });
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.number = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.number = val;
      this.getSearch();
    },
    // 查询车签号
    getTagNumber(val = '') {
      const data = {
        pageSize: 10,
        pageNumber: 1,
        params: {
          vehicleTagNumber: val
        }
      };
      getTagNumberAll(data).then(res => {
        if (res.status === 'OK') {
          this.tagNumberList = res.data;
        }
      });
    },
    focegetTagNumber() {
      if (this.tagNumberList.length < 10) {
        const data = {
          pageSize: 10,
          pageNumber: 1,
          params: {
            vehicleTagNumber: ''
          }
        };
        getTagNumberAll(data).then(res => {
          if (res.status === 'OK') {
            this.tagNumberList = res.data;
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.titleClass {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.printStyle {
  display: none;
}

>>> .el-alert {
  background-color: #ecf5ff;
  color: #409eff;
}

.fontSizeStyle * {
  font-size: 16px;
  box-sizing: border-box;
}

table {
  border: 1px solid black;
  border-collapse: collapse;
}

th, td {
  border: 1px solid black;
  padding: 5px;
  border-collapse: collapse;
}
</style>
