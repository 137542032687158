<template>
  <div class="Box">
    <div style="margin-bottom: 20px;">
      <el-form class="findClass">
        <el-row>
          <el-col :span="7" style="width: auto;">
            <!-- 所属网点： -->
            <el-form-item :label="$t('collectionCenter.belongDot')">
              <el-select
                v-model="queryForm.params.siteId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                filterable
              >
                <el-option
                  v-for="(item, index) in siteIdList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;">
            <!-- 业务类型： -->
            <el-form-item :label="$t('collectionCenter.businessT') + '：'">
              <el-select v-model="queryForm.params.businessType" :placeholder="$t('collectionCenter.pleaseChoose')" clearable>
                <el-option :label="$t('collectionCenter.collectParcel')" :value="1" /><!-- 取件 -->
                <el-option :label="$t('collectionCenter.delivery')" :value="2" /><!-- 派件 -->
                <el-option :label="$t('collectionCenter.mixed')" :value="3" /><!-- 混合 -->

              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;">
            <!-- 是否启用： -->
            <el-form-item :label="$t('collectionCenter.ifEnabled')">
              <el-select v-model="queryForm.params.isActive" :placeholder="$t('collectionCenter.pleaseChoose')" clearable>
                <el-option :label="$t('collectionCenter.enable')" :value="0" />
                <el-option :label="$t('collectionCenter.disable')" :value="1" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="24" style="margin-left: 20px">
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
          <el-button
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
        </el-col>
      </el-row>
    </div>
    <div class="overflowBtn">
      <el-table
        ref="tableRef"
        border
        :max-height="600"
        :data="tableDate"
      >
        <el-table-column
          prop="lockerNumber"
          align="center"
          :label="$t('collectionCenter.cabinetNumber')"
        /><!-- 柜子编号 -->
        <el-table-column
          prop="lockerType"
          align="center"
          :label="$t('collectionCenter.cabinetType')"
        ><!-- 柜子类型 -->
          <template slot-scope="scope">
            <div>
              <!-- 0 一拖一  -->
              {{ scope.row.lockerType == 0 ? $t('collectionCenter.towOne') : '' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="latticeNumber"
          align="center"
          :label="$t('collectionCenter.gridNumber')"
        /><!-- 格子编号 -->
        <el-table-column
          prop="latticeNorm"
          align="center"
          :label="$t('collectionCenter.latticeSize')"
        /><!-- 格子规格 -->
        <el-table-column
          align="center"
          prop="businessType"
          :label="$t('collectionCenter.businessT')"
        ><!-- 业务类型 -->
          <template slot-scope="scope">
            <div>
              <!-- 1：取件，2：派件，3：混合 -->
              {{
                scope.row.businessType == 1 ? $t('collectionCenter.collectParcel') :
                scope.row.businessType == 2 ? $t('collectionCenter.delivery') :
                scope.row.businessType == 3 ? $t('collectionCenter.mixed') : ''
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          align="center"
          :label="$t('collectionCenter.cabinet')"
        /><!-- 柜子位置 -->
        <el-table-column
          prop="latLongitude"
          align="center"
          :label="$t('collectionCenter.LAL')"
        /><!-- 经纬度 -->
        <el-table-column
          prop="isActive"
          align="center"
          :label="$t('collectionCenter.ifUse')"
        ><!-- 是否启用 -->
          <template slot-scope="scope">
            <div>
              <!-- 0 启用    1 禁用 -->
              {{ scope.row.isActive == 0 ? $t('collectionCenter.enable') :
                scope.row.isActive == 1 ? $t('collectionCenter.disable') : '' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="remark"
          align="center"
          :label="$t('collectionCenter.Note')"
        /><!-- 备注 -->
        <el-table-column
          :label="$t('operatingPlatform.operation')"
          fixed="right"
          align="center"
        ><!-- 操作 -->
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="viewDetails(scope.row)"
            >{{ $t('collectionCenter.ViewDetails') }}</el-button><!-- 查看详情 -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getPage"
        @size-change="searchClick"
      />
    </div>

    <!--    查看详情-->
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="50%"
    >
      <div class="detailsBox">
        <div class="detailsTitle">
          <!--             柜子编号：-->
          <span class="dt">{{ $t('collectionCenter.cabinetNum') }}<span>{{ gui.lockerNumber }}</span></span>
          <!--             待取件：-->
          <span class="dt">{{ $t('collectionCenter.unclaimedItem') }}<span class="dtRed">{{ gui.pickupQty }}</span></span>
          <!--             待揽件：-->
          <span class="dt">{{ $t('collectionCenter.unsolicitedItem') }}<span class="dtRed">{{ gui.collectQty }}</span></span>
          <!--             滞留件：-->
          <span class="dt">{{ $t('collectionCenter.detentionShipment') }}<span class="dtRed">{{ gui.holdUpsQty }}</span></span>
          <!--             空白格：-->
          <!--          <span class="dt">{{ $t('collectionCenter.space') }}<span class="dtRed">{{ gui.blankQty }}</span></span>-->
          <br>
          <!--                     红色：                                     待取件   -->
          <span class="dt">{{ $t('collectionCenter.red') }}<span class="dtRedColor">{{ $t('collectionCenter.unclaimed') }}</span></span>
          <!--             绿色：                    待揽件   -->
          <span class="dt">{{ $t('collectionCenter.green') }}<span class="dtGreenColor">{{ $t('collectionCenter.unsolicited') }}</span></span>
          <!--             橙色：                    滞留件   -->
          <span class="dt">{{ $t('collectionCenter.orange') }}<span class="dtOrangeColor">{{ $t('collectionCenter.detentionShip') }}</span></span>
          <!--             白色：                    空白格   -->
          <span class="dt">{{ $t('collectionCenter.white') }}<span class="dtWhiteColor">{{ $t('collectionCenter.blankGrid') }}</span></span>
        </div>
        <div class="detailsGe">
          <div v-for="(item, index) in geList" :key="index" class="dGe">
            <!-- 格子状态,0:空格子(白),  1:待取件(红色),  2:待揽件(绿),  3:滞留件(橙) -->
            <div
              :class="item.status == 0 ? 'dGeColor' :
                item.status == 1 ? 'dGeColor red' :
                item.status == 2 ? 'dGeColor green' :
                item.status == 3 ? 'dGeColor orange' : 'dGeColor'"
            >
              <span class="geClass">
                <span>{{ item.latticeNumber }}</span>
                <span v-if="!item.latticeNorm" style="opacity: 0;">null</span>
                <span>
                  <!-- 1:小   2:中  3:大  4:超大 -->
                  {{ item.latticeNorm == 1 ? $t('collectionCenter.small') :
                    item.latticeNorm == 2 ? $t('collectionCenter.middle') :
                    item.latticeNorm == 3 ? $t('collectionCenter.big') :
                    item.latticeNorm == 4 ? $t('collectionCenter.oversized') : '' }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  searchSiteIdList
} from '@/api/lanshou';
import {
  cabinetPage,
  queryLockerSumDoor
} from '@/api/smartCabinet';
export default {
  data() {
    return {
      dialogVisible: false,
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          siteId: '',
          businessType: '',
          isActive: ''
        }
      },
      siteIdList: [],
      total: 0,
      tableDate: [{}],
      gui: {}, // 详情——柜子
      geList: [] // 详情——格子数据
    };
  },
  created() {
    searchSiteIdList().then(res => {
      if (res.status === 'OK') {
        res.data.forEach(data => {
          this.siteIdList.push({ id: data.id, name: data.name });
        });
      }
    });
    this.searchClick();
  },
  methods: {
    searchClick() {
      this.queryForm.pageNumber = 1;
      this.getPage();
    },
    resetClick() {
      this.queryForm.params = {
        siteId: '',
        businessType: '',
        isActive: ''
      };
    },
    // 分页
    getPage() {
      cabinetPage(this.queryForm).then(res => {
        if (res.status === 'OK') {
          this.total = res.data.total;
          const aa = res.data.records;
          for (let i = 0; i < aa.length; i++) {
            if (aa[i].latticeNorm) {
              aa[i].latticeNorm = aa[i].latticeNorm.split(',');
              aa[i].latticeNorm = [... new Set(aa[i].latticeNorm)].join(',');
              aa[i].latticeNorm = aa[i].latticeNorm.replace(/1/g, this.$t('collectionCenter.small'))
                .replace(/2/g, this.$t('collectionCenter.middle'))
                .replace(/3/g, this.$t('collectionCenter.big'))
                .replace(/4/g, this.$t('collectionCenter.oversized'));
            }
          }
          this.tableDate = aa;
        }
      });
    },
    // 查看详情
    viewDetails(row) {
      this.dialogVisible = true;
      const idForm = {
        id: row.id
      };
      queryLockerSumDoor(idForm).then(res => {
        if (res.status === 'OK') {
          this.gui = {
            lockerNumber: res.data.lockerNumber, // 柜子编号
            pickupQty: res.data.pickupQty, // 待取件数量
            collectQty: res.data.collectQty, // 待揽收数量
            holdUpsQty: res.data.holdUpsQty, // 滞留件数量
            blankQty: res.data.blankQty // 空白格数量
          };
          this.geList = res.data.doorList;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
 .overflowBtn ::v-deep .el-table .cell{
   padding: 0;
 }
.Box{
  padding: 20px;
  box-sizing: border-box;
}

.findClass ::v-deep .el-form-item{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.findClass ::v-deep .el-form-item__label{
  padding: 0;
  line-height: 16px;
  white-space: nowrap;
  margin-left: 20px;
}
.findClass ::v-deep .el-form-item__content{
  margin-left: 0 !important;
  width: 100%;
}
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
::v-deep .el-table .cell{
  text-overflow: unset;
}
.detailsBox{
  font-size: 16px;
  font-weight: bold;
}
.detailsTitle{
  .dt{
    display: inline-block;
    margin: 5px 10px;
    white-space: nowrap;
    .dtRed{
      color: red;
    }
    .dtRedColor, .dtGreenColor, .dtOrangeColor, .dtWhiteColor{
      padding: 3px 10px 2px;
      border: 1px solid #c9c9c9;
      box-sizing: border-box;
    }
    .dtRedColor{
      background-color: #ff2442;
    }
    .dtGreenColor{
      background-color: #cbf983;
    }
    .dtOrangeColor{
      background-color: #f59b1e;
    }
    .dtWhiteColor{
    }
  }
}
.detailsGe{
  margin: 10px auto;
  border: 1px solid #EBEEF5;
  .dGe{ // 每个格子
    display: inline-block;
    width: 25%;
    text-align: center;
    border: 1px solid #EBEEF5;
    box-sizing: border-box;
    .dGeColor{
      padding: 10px 0;
      box-sizing: border-box;
    }
    .geClass{
      span{
        display: block;
      }
    }
    .dGeColor.red{ background-color: #ff2442; }
    .dGeColor.green{ background-color: #cbf983; }
    .dGeColor.orange{ background-color: #f59b1e; }
  }
}
</style>
