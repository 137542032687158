<template>
  <div class="app-container">
    <el-card style="margin-bottom: 20px;">
      <el-form
        ref="form"
        :model="queryForm"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item class="numberClassSelect">
              <template slot="label">
                <el-select
                  v-model="selectionType"
                  :placeholder="$t('orderDetails.pleaseChoose')"
                  @change="handleChangeLable"
                >
                  <!--运单号-->
                  <el-option
                    key="1"
                    :label="$t('newOrder.waybillNumber')+'/'+$t('GlobalSetObj.serviceLogistics')"
                    :value="1"
                  />
                  <el-option key="2" :label="$t('orderCenterCont.TheOrderNumberCustomer')" :value="2" />
                  <el-option key="3" :label="$t('placeOrder.customerNumber')" :value="3" />
                </el-select>
              </template>
              <div class="searchOrder">
                <el-input
                  v-model.trim="queryForm.params.orderNumber"
                  :placeholder="$t('orderDetails.pleaseInput')"
                  clearable
                  @keyup.enter.native="searchFun(1)"
                />
                <div style="width: 40px;">
                  <el-button class="chaxunBtn" @click="searchFun(1)">
                    <i class="iconfont icon-chaxun" style="font-size: 24px" />
                  </el-button>
                </div>
                <div style="width: 38px;">
                  <el-popover
                    v-model="showNumList"
                    placement="bottom-end"
                    trigger="manual"
                  >
                    <div style="width: 300px;">
                      <el-input
                        v-model="searchNumberStr"
                        :placeholder="$t('newOrder.PreciseSearch')"
                        :rows="10"
                        type="textarea"
                      />
                      <div style="margin-top: 10px;">
                        <!--清空-->
                        <el-button size="small" @click="clearSearchNum()">{{ $t('newOrder.empty') }}</el-button>
                        <div style="float: right">
                          <!--关闭-->
                          <el-button size="small" @click="openNumList">{{ $t('orderExport.close') }}</el-button>
                          <!--搜索-->
                          <el-button plain size="small" type="primary" @click="searchFun(2)">
                            {{ $t('newOrder.search') }}
                          </el-button>
                        </div>
                      </div>
                    </div>
                    <el-button slot="reference" class="btnIcon" @click="openText">
                      <i class="iconfont icon-liebiao1" style="font-size: 24px" />
                    </el-button>
                  </el-popover>
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item class="numberClassSelect">
              <template slot="label">
                <el-select v-model="queryForm.params.timeField" :placeholder="$t('operationCenter.PleaseSelect')">
                  <!--创建时间-->
                  <el-option key="1" :label="$t('GlobalSetObj.CreationTime')" :value="1" />
                </el-select>
              </template>
              <!--查询时间-->
              <el-date-picker
                v-model="queryDate"
                :default-time="['00:00:00', '23:59:59']"
                :end-placeholder="$t('operationCenter.endDate')"
                :range-separator="$t('operationCenter.to')"
                :start-placeholder="$t('operationCenter.startDate')"
                style="width: 100%"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--客户-->
            <el-form-item name="customerId">
              <template slot="label">
                <p style="margin: 6px 0px;">
                  {{ $t('receivablePayable.customer') }}
                </p>
              </template>
              <el-select
                v-model="queryForm.params.customerId"
                :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
                :remote-method="getCustomerPage"
                clearable
                filterable
                remote
                reserve-keyword
                style="width: 100%;"
                @focus="getCustomerPage()"
              >
                <el-option
                  v-for="(item, ind) in customerList"
                  :key="ind"
                  :label="item.customerAlias + '(' + item.username + ')'"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <!--客户-->
            <el-form-item :label="$t('orderCenterCont.WhetherSuccessful')" name="customerId" style="margin-top: 12px;">
              <el-select
                v-model="queryForm.params.state"
                style="width: 100%;"
              >
                <el-option :label="$t('GlobalSetObj.all')" :value="null" />
                <el-option :label="$t('GlobalSetObj.yes')" :value="1" />
                <el-option :label="$t('GlobalSetObj.No')" :value="0" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="searchNumberList.length > 0">
          <div class="searchNum">
            <el-tooltip class="item" effect="dark" placement="bottom">
              <template slot="content">
                <pre>{{ searchNumberStr }}</pre>
              </template>
              <div class="searchNumWarp">
                <div class="wordBreak">
                  <p>
                    {{ searchNameObj.label }}：
                    {{ searchNumberList.join('，') }}
                  </p>
                </div>
              </div>
            </el-tooltip>
            <div class="clearSearchNum" @click="clearSearchNum()">
              <i class="el-icon-close" />
            </div>
          </div>
        </el-row>
      </el-form>
      <el-button
        icon="el-icon-search"
        size="small"
        type="primary"
        @click="searchResources(1)"
      >{{ $t('operationCenter.Procurar') }}
      </el-button>
      <el-button icon="el-icon-refresh-left" size="small" @click="searchResources(2)">
        {{ $t('operationCenter.reset') }}
      </el-button>
    </el-card>
    <el-card>
      <div>
        <el-table :data="tableData" border>
          <!--序号-->
          <el-table-column :label="$t('operationCenter.ind')" align="center" width="60px">
            <template slot-scope="scope">
              <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <!--订单号-->
          <el-table-column
            :label="$t('orderCenterCont.TheOrderNumber')"
            :show-overflow-tooltip="true"
            align="left"
            min-width="160"
            prop="orderNumber"
          >
            <template slot-scope="scope">
              <div>
                <el-popover
                  placement="top"
                  popper-class="copy"
                  trigger="hover"
                >
                  <el-button
                    style="color: black;font-size: 12px;padding: 2px"
                    type="text"
                    @click="copy(scope.row.orderNumber)"
                  >
                    {{ $t('orderDetails.copy') }}
                  </el-button>
                  <div
                    slot="reference"
                    class="is-click-column"
                    @click="operationBtn(scope.row)"
                  >
                    {{ scope.row.orderNumber }}
                  </div>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <!--运单号-->
          <el-table-column
            :label="$t('newOrder.waybillNumber')"
            :show-overflow-tooltip="true"
            align="left"
            min-width="160"
            prop="waybillNumber"
          >
            <template slot-scope="scope">
              <div>
                <el-popover
                  placement="top"
                  popper-class="copy"
                  trigger="hover"
                >
                  <el-button
                    style="color: black;font-size: 12px;padding: 2px"
                    type="text"
                    @click="copy(scope.row.waybillNumber)"
                  >
                    {{ $t('orderDetails.copy') }}
                  </el-button>
                  <div
                    slot="reference"
                  >
                    {{ scope.row.waybillNumber }}
                  </div>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <!--服务商单号-->
          <el-table-column
            :label="$t('GlobalSetObj.serviceLogistics')"
            min-width="160"
            prop="logisticsNumber"
          >
            <template slot-scope="scope">
              <div>
                <el-popover
                  placement="top"
                  popper-class="copy"
                  trigger="hover"
                >
                  <el-button
                    style="color: black;font-size: 12px;padding: 2px"
                    type="text"
                    @click="copy(scope.row.logisticsNumber)"
                  >
                    {{ $t('orderDetails.copy') }}
                  </el-button>
                  <div
                    slot="reference"
                  >
                    {{ scope.row.logisticsNumber }}
                  </div>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <!--客户-->
          <el-table-column
            :label="$t('operationCenter.client')"
            :show-overflow-tooltip="true"
            align="left"
            min-width="160"
            prop="customerName"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.customerName }}</div>
            </template>
          </el-table-column>
          <!--客户单号-->
          <el-table-column
            :label="$t('placeOrder.customerNumber')"
            :show-overflow-tooltip="true"
            align="left"
            min-width="160"
            prop="customerNumber"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.customerNumber }}</div>
            </template>
          </el-table-column>
          <!--是否成功-->
          <el-table-column
            :label="$t('orderCenterCont.WhetherSuccessful')"
            :show-overflow-tooltip="true"
            align="center"
            min-width="120"
            prop="state"
          >
            <template slot-scope="scope">
              <el-tag v-if="scope.row.state == 0" type="danger">
                {{ $t('operationCenter.fail') }}
              </el-tag>
              <el-tag v-if="scope.row.state == 1" type="success">
                {{ $t('operationCenter.succeed') }}
              </el-tag>
            </template>
          </el-table-column>
          <!--失败原因-->
          <el-table-column :label="$t('GlobalSetObj.ReasonForFailure')" prop="failureMessage" />
          <el-table-column
            :label="$t('orderCenterCont.CreationTime')"
            :show-overflow-tooltip="true"
            align="left"
            min-width="180"
            prop="createTime"
          />
          <!--安骏报文-->
          <el-table-column
            :label="$t('orderCenterCont.AnjunDatagram')"
            :show-overflow-tooltip="false"
            align="left"
            min-width="200"
            prop="dealedRequestParams"
          >
            <template slot-scope="scope">
              <el-tooltip placement="top">
                <pre slot="content" style="max-height: 400px; overflow: auto">
                  {{ scope.row.dealedRequestParams }}
                </pre>
                <div class="paramsStyle">{{ scope.row.dealedRequestParams }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <!--客户报文-->
          <el-table-column
            :label="$t('orderCenterCont.CustomerDatagram')"
            :show-overflow-tooltip="false"
            align="left"
            min-width="200"
            prop="requestParams"
          >
            <template slot-scope="scope">
              <el-tooltip placement="top">
                <pre slot="content" style="max-height: 400px; overflow: auto">
                  {{ scope.row.requestParams }}
                </pre>
                <div class="paramsStyle">{{ scope.row.requestParams }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          :current-page.sync="queryForm.pageNumber"
          :page-size.sync="queryForm.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          style="margin: 10px 0"
          @current-change="getCurrentTableData"
          @size-change="getSizeTableData"
        />
      </div>
    </el-card>
    <el-drawer
      :append-to-body="true"
      :close-on-click-modal="false"
      :title="$t('orderCenterCont.ViewDetails')"
      :visible.sync="drawerTag"
      custom-class="drawerClass"
      direction="ltr"
      size="70%"
    >
      <div class="preStyle">
        <el-form
          ref="updateForm"
          :model="updateForm"
          style="padding: 20px; height: calc(100vh - 68px); overflow-y: auto"
        >
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple">
                <!--订单号-->
                <el-form-item :label="$t('orderCenterCont.TheOrderNumber') + '：'" prop="orderNumber">
                  <span>{{ updateForm.orderNumber }}</span>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                <!--客户-->
                <el-form-item :label="$t('operationCenter.client') + '：'" prop="customerName">
                  <span>{{ updateForm.customerName }}</span>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                <!--请求时间-->
                <el-form-item :label="$t('orderCenterCont.RequestTime') + '：'" prop="createTime">
                  <span>{{ updateForm.createTime }}</span>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                <!--安骏参数-->
                <el-form-item
                  label-width="0"
                >
                  <div>
                    {{ $t('orderCenterCont.AnjunParameters') }}
                  </div>
                  <div class="messageCon">
                    <div
                      v-if="updateForm.dealedRequestParams"
                      style="text-align: right; line-height: 25px; padding: 5px"
                    >
                      <i
                        :title="$t('orderCenterCont.copy')"
                        class="el-icon-copy-document"
                        style="font-size: 20px; cursor: pointer"
                        @click="copy(updateForm.dealedRequestParams)"
                      />
                    </div>
                    <pre>{{ updateForm.dealedRequestParams }}</pre>
                  </div>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                <!--安骏返回参数-->
                <el-form-item label-width="0">
                  <div>
                    {{ $t('orderCenterCont.AnjunReturnsParameters') }}
                  </div>
                  <div class="messageCon">
                    <div
                      v-if="updateForm.dealedResponseParams"
                      style="text-align: right; line-height: 25px; padding: 5px"
                    >
                      <i
                        :title="$t('orderCenterCont.copy')"
                        class="el-icon-copy-document"
                        style="font-size: 20px; cursor: pointer"
                        @click="copy(updateForm.dealedResponseParams)"
                      />
                    </div>
                    <pre>{{ updateForm.dealedResponseParams }}</pre>
                  </div>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                <!--供应商参数-->
                <el-form-item label-width="0">
                  <div>
                    {{ $t('orderCenterCont.SupplierParameters') }}
                  </div>
                  <div class="messageCon">
                    <div
                      v-if="updateForm.originalRequestParams"
                      style="text-align: right; line-height: 25px; padding: 5px"
                    >
                      <i
                        :title="$t('orderCenterCont.copy')"
                        class="el-icon-copy-document"
                        style="font-size: 20px; cursor: pointer"
                        @click="copy(updateForm.originalRequestParams)"
                      />
                    </div>
                    <pre>{{ updateForm.originalRequestParams }}</pre>
                  </div>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                <!--供应商返回参数-->
                <el-form-item label-width="0">
                  <div>
                    {{ $t('orderCenterCont.SupplierReturnsParameters') }}
                  </div>
                  <div class="messageCon">
                    <div
                      v-if="updateForm.originalResponseParams"
                      style="text-align: right; line-height: 25px; padding: 5px"
                    >
                      <i
                        :title="$t('orderCenterCont.copy')"
                        class="el-icon-copy-document"
                        style="font-size: 20px; cursor: pointer"
                        @click="copy(updateForm.originalResponseParams)"
                      />
                    </div>
                    <pre>{{ updateForm.originalResponseParams }}</pre>
                  </div>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                <!--客户参数-->
                <el-form-item label-width="0">
                  <div>
                    {{ $t('orderCenterCont.CustomerParameters') }}
                  </div>
                  <div class="messageCon">
                    <div v-if="updateForm.requestParams" style="text-align: right; line-height: 25px; padding: 5px">
                      <i
                        :title="$t('orderCenterCont.copy')"
                        class="el-icon-copy-document"
                        style="font-size: 20px; cursor: pointer"
                        @click="copy(updateForm.requestParams)"
                      />
                    </div>
                    <pre>{{ updateForm.requestParams }}</pre>
                  </div>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                <!--客户返回参数-->
                <el-form-item label-width="0">
                  <div>
                    {{ $t('orderCenterCont.CustomerReturnParameters') }}
                  </div>
                  <div class="messageCon">
                    <div v-if="updateForm.responseParams" style="text-align: right; line-height: 25px; padding: 5px">
                      <i
                        :title="$t('orderCenterCont.copy')"
                        class="el-icon-copy-document"
                        style="font-size: 20px; cursor: pointer"
                        @click="copy(updateForm.responseParams)"
                      />
                    </div>
                    <pre>{{ updateForm.responseParams }}</pre>
                  </div>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { copyData } from '@/utils/copyData';
import { apiOrderLog } from '@/api/orderApi';
import { apiCustomerPage } from '@/api/customer';
import dayjs from 'dayjs';

export default {
  name: 'OrderLogs',
  data() {
    return {
      queryForm: {
        pageNumber: 1,
        pageSize: 100,
        params: {
          orderNumber: '',
          state: null,
          timeField: 1,
          customerId: ''// 客户id
        }
      },
      queryDate: [this.getAWeek().startDate + ' 00:00:00', this.getAWeek().endDate + ' 23:59:59'],
      selectionType: 1,
      tableData: [],
      total: 0,
      drawerTag: false,
      updateForm: {},
      showNumList: false,
      searchNumberStr: '',
      searchNumberList: [],
      searchNameObj: {
        id: 1,
        label: this.$t('newOrder.waybillNumber') + '/' + this.$t('GlobalSetObj.serviceLogistics'),
        value: 1
      },
      // 查询列表数据
      selectLabel: [
        { id: 1, label: this.$t('newOrder.waybillNumber') + '/' + this.$t('GlobalSetObj.serviceLogistics'), value: 1 },
        { id: 2, label: this.$t('orderCenterCont.TheOrderNumberCustomer'), value: 2 },
        { id: 3, label: this.$t('placeOrder.customerNumber'), value: 3 }
      ],
      customerList: []// 客户列表
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    // 获取最近30天时间
    getAWeek() {
      // 默认最近30天时间
      return {
        endDate: dayjs().format('YYYY-MM-DD'),
        startDate: dayjs().subtract(30, 'day').format('YYYY-MM-DD')
      };
    },
    handleChangeLable(val) {
      const index = val - 1;
      this.searchNameObj = this.selectLabel[index];
    },
    searchFun(type) {
      this.queryForm.pageNumber = 1;
      if (type === 1) {
        this.clearSearchNum();
      } else if (type === 2) {
        this.searchNumberCollation();
        this.showNumList = !this.showNumList;
        this.queryForm.params.orderNumber = '';
      }
      this.getTableData();
    },
    // 清除列表搜索
    clearSearchNum() {
      this.searchNumberList = [];
      this.searchNumberStr = '';
    },
    // 打开\关闭多个单号输入
    openNumList() {
      this.showNumList = !this.showNumList;
      this.queryForm.params.orderNumber = '';
    },
    // 打开或者关闭
    openText() {
      this.showNumList = !this.showNumList;
    },
    // 查询单号整理
    searchNumberCollation() {
      if (this.searchNumberStr) {
        this.searchNumberList = [];
        const orderList = this.searchNumberStr.split('\n');
        orderList.map(item => {
          item.replace(/^\s/g, '');
          if (item) {
            this.searchNumberList.push(item.trim());
          }
        });
      }
    },
    // 搜索、重置
    searchResources(type) {
      if (type === 1) {
        this.queryForm.pageNumber = 1;
        this.getTableData();
      } else {
        this.selectionType = 1;
        this.queryForm.params.orderNumber = '';
        this.queryForm.params.timeField = 1;
        this.queryForm.params.customerId = '';
        this.queryForm.params.state = null;
        this.searchNumberList = [];
        this.searchNumberStr = '';
        this.queryDate = [this.getAWeek().startDate + ' 00:00:00', this.getAWeek().endDate + ' 23:59:59'];
      }
    },
    // 查看详情
    operationBtn(row) {
      this.updateForm = row;
      this.drawerTag = true;
    },
    // 点击复制
    copy(data) {
      copyData(data);
    },
    // 获取订单日志
    getTableData() {
      let list = [];
      if (this.queryForm.params.orderNumber) {
        this.searchNumberList = [];
        this.searchNumberStr = '';
        list = [this.queryForm.params.orderNumber];
      } else {
        list = this.searchNumberList;
      }
      const param = {
        pageSize: this.queryForm.pageSize,
        pageNumber: this.queryForm.pageNumber,
        params: {
          orderNumberList: [],
          logisticsNumberList: [],
          customerNumberList: [],
          startTime: '',
          endTime: '',
          customerId: this.queryForm.params.customerId,
          state: this.queryForm.params.state
        }
      };
      if (this.selectionType === 1) {
        param.params.logisticsNumberList = list;
      } else if (this.selectionType === 2) {
        param.params.orderNumberList = list;
      } else {
        param.params.customerNumberList = list;
      }
      if (this.queryDate && this.queryDate.length > 0) {
        param.params.startTime = this.queryDate[0];
        param.params.endTime = this.queryDate[1];
      }
      apiOrderLog(param).then(res => {
        console.log(res, '===res');
        if (res.status === 'OK') {
          this.total = res.data.total;
          this.tableData = res.data.records;
          this.tableData.map((item) => {
            if (item.requestParams) {
              try {
                item.requestParams = JSON.stringify(JSON.parse(item.requestParams), null, 2);
              } catch (e) {
                console.log(e, '===item.requestParams');
              }
            }
            if (item.responseParams) {
              try {
                item.responseParams = JSON.stringify(JSON.parse(item.responseParams), null, 2);
              } catch (e) {
                console.log(e, '===item.responseParams');
              }
            }
            if (item.originalRequestParams) {
              try {
                item.originalRequestParams = JSON.stringify(JSON.parse(item.originalRequestParams), null, 2);
              } catch (e) {
                console.log(e, '===item.originalRequestParams');
              }
            }
            if (item.originalResponseParams) {
              try {
                item.originalResponseParams = JSON.stringify(JSON.parse(item.originalResponseParams), null, 2);
              } catch (e) {
                console.log(e, '===item.originalResponseParams');
              }
            }
            if (item.dealedRequestParams) {
              try {
                item.dealedRequestParams = JSON.stringify(JSON.parse(item.dealedRequestParams), null, 2);
              } catch (e) {
                console.log(e, '===item.dealedRequestParams');
              }
            }
            if (item.dealedResponseParams) {
              try {
                item.dealedResponseParams = JSON.stringify(JSON.parse(item.dealedResponseParams), null, 2);
              } catch (e) {
                console.log(e, '===item.dealedResponseParams');
              }
            }
          });
        }
      });
      console.log(this.tableData, 'this.tableData');
    },
    // 页码变化翻页
    getCurrentTableData(val) {
      this.queryForm.pageNumber = val;
      this.getTableData();
    },
    // 每页显示数量变化
    getSizeTableData(val) {
      this.queryForm.pageSize = val;
      this.getTableData();
    },
    /**
     * @description 分页获取客户列表
     * @param { string } name 客户名称
     */
    getCustomerPage(name = '') {
      const param = {
        pageSize: 10,
        pageNumber: 1,
        params: {
          quickSearch: name
        }
      };
      apiCustomerPage(param).then((res) => {
        if (res.status === 'OK') {
          this.customerList = res.data.records;
        }
      });
    }
  }
};
</script>
<style>
.numberClassSelect .el-form-item__label .el-select .el-input__inner {
  font-weight: bold;
  border: none;
  padding: 0;
}

.numberClassSelect.el-form-item {
  margin-bottom: 10px;
}
</style>
<style scoped>
.m-b-sm {
  margin-bottom: 10px;
}

.padding-10 {
  padding: 10px;
}

.putAway {
  position: absolute;
  right: 0;
  top: 0;
  color: #1989fa;
  cursor: pointer;
  z-index: 100;
}

.app-container >>> .drawerClass header {
  border-bottom: 1px solid #ccc;
  margin-bottom: 0;
  padding: 20px 20px 20px;
}

.app-container >>> .handleClick .el-tabs__content {
  padding: 0;
}

.ml {
  margin-left: 10px;
}

.preStyle {
  height: calc(100vh - 70px);
  overflow: hidden;
}

.preStyle .messageCon {
  background: #eee;
}

.preStyle pre {
  height: 500px;
  overflow-y: auto;
  white-space: break-spaces;
  margin: 0;
  padding: 0 10px;
  line-height: 26px;
}

.paramsStyle {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selectType ::v-deep.el-input--suffix .el-input__inner {
  border: none !important;
  font-weight: bold;
  font-size: 14px;
  padding: 0;
  text-align: left;
}

.btnIcon {
  padding: 6px;
}

.searchOrder {
  display: flex;
}

.searchOrder .el-popover__reference-wrapper .btnIcon {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 40px;
}

.app-container >>> .searchOrder .el-input__inner {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.app-container >>> .searchOrder .el-input-group__prepend {
  background-color: transparent !important;
}

.chaxunBtn {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  width: 40px;
  height: 40px;
  padding: 7px;
}

.searchNum {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 15px;
  height: 28px;
}

.searchNumWarp {
  height: 28px;
  font-size: 14px;
  border: 1px solid #91B8FA;
  border-right: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 3px 5px 5px 5px;
  background: rgba(145, 184, 250, 0.2);
}

.searchNumWarp p {
  margin: 0;
}

.clearSearchNum {
  height: 28px;
  border: 1px solid #91B8FA;
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: rgba(145, 184, 250, 0.2);
  cursor: pointer;
  padding-right: 5px;
  padding-top: 3px;
}

.wordBreak {
  word-break: break-all; /*word-break: normal|break-all|keep-all;在恰当的断字点进行换行,break-all 允许在单词内换行,keep-all 只能在半角空格或连字符处换行,normal 使用浏览器默认的换行规则。*/
  text-overflow: ellipsis; /*溢出文字显示省略号*/
  display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 1; /** 显示的行数 **/
  overflow: hidden; /** 隐藏超出的内容 **/
}

>>> .numberClassSpan .el-form--label-top .el-form-item__label {
  line-height: 0px !important;
}
</style>
