import { useQuery } from '@tanstack/vue-query';

import { getSkipPage } from '@/utils/getSkipPage';
import { mdfeService } from '.';

export function useGetAllMDFE(queryParams) {
  return useQuery({
    queryKey: ['mdfe', queryParams],
    queryFn: () => {
      const params = {
        skip: getSkipPage(queryParams.value.currentPage),
        take: queryParams.value.perPage,
        keyMDFE: queryParams.value.keyMDFE
      };

      return mdfeService.getAll(params);
    },
    networkMode: 'always',
    refetchOnWindowFocus: false,
    retry: true,
    keepPreviousData: true
  });
}
