import { getLanguage } from '@/lang';

const state = {

  language: getLanguage()
  // isZh: false, // 是否是中文
  // isPu: false // 是否是葡语
  // size: Cookies.get('size') || 'medium'
};

const mutations = {

  SET_LANGUAGE: (state, language) => {
    state.language = language;
    // Cookies.set('language', language)
    window.sessionStorage.setItem('language', language);
  }
};

const actions = {

  setLanguage({ commit }, language) {
    commit('SET_LANGUAGE', language);
  }
  // setSize({ commit }, size) {
  //   commit('SET_SIZE', size)
  // }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};

