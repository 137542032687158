var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "searchContainer" },
        [
          _c(
            "el-form",
            {
              ref: "queryFormRef",
              staticClass: "queryFormClass",
              attrs: {
                model: _vm.queryForm,
                "label-width": "100px",
                size: "mini",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("collectionCenter.name") } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.queryForm.params.username,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryForm.params, "username", $$v)
                              },
                              expression: "queryForm.params.username",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.status") + "：",
                            prop: "state",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.queryForm.params.state,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm.params, "state", $$v)
                                },
                                expression: "queryForm.params.state",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  value: 1,
                                  label: _vm.$t("userManage.onJob"),
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  value: 2,
                                  label: _vm.$t("userManage.leaveJob"),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("userManage.roleName") + "：",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.queryForm.params.role,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm.params, "role", $$v)
                                },
                                expression: "queryForm.params.role",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("userManage.temporaryWorker"),
                                  value: 1,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("userManage.deliveryRider"),
                                  value: 2,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("userManage.courier"),
                                  value: 3,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("userManage.workLocation") + "：",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.queryForm.params.location,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "location",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.location",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("userManage.warehouse1"),
                                  value: 1,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("userManage.warehouse2"),
                                  value: 2,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.serialNumber") + "：",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 4,
                              clearable: "",
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.serialNumber,
                              callback: function ($$v) {
                                _vm.serialNumber = $$v
                              },
                              expression: "serialNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { type: "flex", justify: "start" } },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permit",
                            rawName: "v-permit:remove",
                            value:
                              "btn:user-center:personnel-registration:find",
                            expression:
                              "'btn:user-center:personnel-registration:find'",
                            arg: "remove",
                          },
                        ],
                        attrs: {
                          type: "primary",
                          size: "mini",
                          icon: "el-icon-search",
                        },
                        on: { click: _vm.getData },
                      },
                      [_vm._v(_vm._s(_vm.$t("GlobalSetObj.search")))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { icon: "el-icon-refresh", size: "mini" },
                        on: { click: _vm.queryFormReset },
                      },
                      [_vm._v(_vm._s(_vm.$t("userManage.reset")))]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
                margin: "10px 0",
              },
            },
            [
              _c(
                "span",
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value: "btn:user-center:personnel-registration:add",
                          expression:
                            "'btn:user-center:personnel-registration:add'",
                          arg: "remove",
                        },
                      ],
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.addUser },
                    },
                    [_vm._v(_vm._s(_vm.$t("GlobalSetObj.append")))]
                  ),
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "bottom",
                        trigger: "hover",
                        "popper-class": "popperBox",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permit",
                              rawName: "v-permit:remove",
                              value:
                                "btn:user-center:personnel-registration:import",
                              expression:
                                "'btn:user-center:personnel-registration:import'",
                              arg: "remove",
                            },
                          ],
                          staticStyle: { margin: "0 10px" },
                          attrs: {
                            slot: "reference",
                            size: "small",
                            type: "primary",
                          },
                          slot: "reference",
                        },
                        [_vm._v(_vm._s(_vm.$t("collectionCenter.Import")))]
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                display: "block",
                                "margin-bottom": "10px",
                              },
                              attrs: {
                                size: "small",
                                type: "primary",
                                icon: "el-icon-download",
                              },
                              on: { click: _vm.downloadTemplate },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("basicData.DownloadTheTemplate"))
                              ),
                            ]
                          ),
                          _c("upload-excel", {
                            staticStyle: { display: "inline-block" },
                            attrs: {
                              "on-success": _vm.importUserData,
                              "but-name": _vm.$t("spareNumberPoo.ImportData"),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value: "btn:user-center:personnel-registration:entry",
                          expression:
                            "'btn:user-center:personnel-registration:entry'",
                          arg: "remove",
                        },
                      ],
                      attrs: { size: "small", type: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.updateState(1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("userManage.onboarding")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:user-center:personnel-registration:retire",
                          expression:
                            "'btn:user-center:personnel-registration:retire'",
                          arg: "remove",
                        },
                      ],
                      attrs: { size: "small", type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.updateState(2)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("userManage.leaveJob")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:user-center:personnel-registration:estimation",
                          expression:
                            "'btn:user-center:personnel-registration:estimation'",
                          arg: "remove",
                        },
                      ],
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = true
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("userManage.evaluation")))]
                  ),
                ],
                1
              ),
              _c(
                "span",
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:user-center:personnel-registration:export",
                          expression:
                            "'btn:user-center:personnel-registration:export'",
                          arg: "remove",
                        },
                      ],
                      attrs: { size: "small" },
                      on: { click: _vm.exportUser },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.export")))]
                  ),
                ],
                1
              ),
            ]
          ),
          _c("el-card", [
            _c(
              "div",
              [
                _c(
                  "el-table",
                  {
                    attrs: { data: _vm.tableData, border: "", "row-key": "id" },
                    on: { "selection-change": _vm.selectionChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        width: "45",
                        align: "center",
                        "reserve-selection": true,
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.serial"),
                        type: "index",
                        width: "55",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    (_vm.queryForm.pageNumber - 1) *
                                      _vm.queryForm.pageSize +
                                      scope.$index +
                                      1
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.serialNumber"),
                        prop: "serialNumber",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "#409EFF",
                                    cursor: "pointer",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getUserInfo(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.serialNumber))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("collectionCenter.compellation"),
                        prop: "username",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("basicData.status"),
                        prop: "state",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.state === 1
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$t("userManage.onJob"))),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("userManage.leaveJob"))
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("userManage.workLocation"),
                        prop: "location",
                        width: "130px",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.location == 1
                                      ? _vm.$t("userManage.warehouse1")
                                      : _vm.$t("userManage.warehouse2")
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("userManage.roleName"),
                        prop: "role",
                        width: "130px",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.role === 1
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("userManage.temporaryWorker")
                                      )
                                    ),
                                  ])
                                : scope.row.role === 2
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("userManage.deliveryRider"))
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("userManage.courier"))
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("userManage.age"),
                        prop: "age",
                        width: "110px",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("userManage.gender1"),
                        prop: "sex",
                        width: "90px",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.sex === 1
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$t("userManage.male"))),
                                  ])
                                : _vm._e(),
                              scope.row.sex === 2
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$t("userManage.female"))),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "CPF",
                        prop: "cpf",
                        width: "120px",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("userManage.userPhone"),
                        width: "120px",
                        align: "center",
                        prop: "phone",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("login.email"),
                        prop: "email",
                        width: "160px",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("userManage.latestHiringDate"),
                        prop: "entryTime",
                        width: "120px",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("userManage.latestDepartureDate"),
                        width: "155",
                        align: "center",
                        prop: "resignationTime",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("userManage.latestIntegrityLevel"),
                        prop: "creditGrade",
                        width: "120px",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.creditGrade
                                ? _c("div", [
                                    _c(
                                      "span",
                                      {
                                        class: [
                                          "level" + scope.row.creditGrade,
                                        ],
                                        staticStyle: { opacity: "1" },
                                      },
                                      [_vm._v(_vm._s(scope.row.creditGrade))]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.CreationTime"),
                        prop: "createTime",
                        width: "120px",
                        align: "left",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.CreatePeople"),
                        prop: "createName",
                        width: "120px",
                        align: "left",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.operate"),
                        prop: "createUserNameStr",
                        width: "120px",
                        align: "left",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getUserInfo(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("userManage.viewEdit")))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "pagination" },
              [
                _c("el-pagination", {
                  staticStyle: { margin: "10px 0" },
                  attrs: {
                    layout: "total, sizes, prev, pager, next, jumper",
                    "current-page": _vm.queryForm.pageNumber,
                    "page-sizes": [10, 20, 50, 100],
                    "page-size": _vm.queryForm.pageSize,
                    total: _vm.total,
                    background: "",
                  },
                  on: {
                    "update:currentPage": function ($event) {
                      return _vm.$set(_vm.queryForm, "pageNumber", $event)
                    },
                    "update:current-page": function ($event) {
                      return _vm.$set(_vm.queryForm, "pageNumber", $event)
                    },
                    "update:pageSize": function ($event) {
                      return _vm.$set(_vm.queryForm, "pageSize", $event)
                    },
                    "update:page-size": function ($event) {
                      return _vm.$set(_vm.queryForm, "pageSize", $event)
                    },
                    "current-change": _vm.getData,
                    "size-change": _vm.getData,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.$t("userManage.viewEdit"),
            visible: _vm.drawer,
            direction: "ltr",
            size: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
            close: _vm.closeDrawer,
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                padding: "20px",
                height: "calc(100vh - 80px)",
                "overflow-x": "auto",
              },
            },
            [
              _c("p", [_vm._v(_vm._s(_vm.$t("basicData.UnderlyingData")))]),
              _c("el-divider"),
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "100px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("collectionCenter.name"),
                                prop: "username",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  clearable: "",
                                  placeholder: _vm.$t(
                                    "GlobalSetObj.pleaseInput"
                                  ),
                                },
                                model: {
                                  value: _vm.ruleForm.username,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "username", $$v)
                                  },
                                  expression: "ruleForm.username",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "CPF：", prop: "cpf" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  clearable: "",
                                  placeholder: _vm.$t(
                                    "GlobalSetObj.pleaseInput"
                                  ),
                                },
                                model: {
                                  value: _vm.ruleForm.cpf,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "cpf", $$v)
                                  },
                                  expression: "ruleForm.cpf",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("userManage.workLocation") + "：",
                                prop: "location",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  model: {
                                    value: _vm.ruleForm.location,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "location", $$v)
                                    },
                                    expression: "ruleForm.location",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("userManage.warehouse1"),
                                      value: 1,
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("userManage.warehouse2"),
                                      value: 2,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("userManage.roleName") + "：",
                                prop: "role",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  model: {
                                    value: _vm.ruleForm.role,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "role", $$v)
                                    },
                                    expression: "ruleForm.role",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t(
                                        "userManage.temporaryWorker"
                                      ),
                                      value: 1,
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("userManage.deliveryRider"),
                                      value: 2,
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("userManage.courier"),
                                      value: 3,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("userManage.age") + "：",
                                prop: "age",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "GlobalSetObj.pleaseInput"
                                  ),
                                },
                                model: {
                                  value: _vm.ruleForm.age,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "age", $$v)
                                  },
                                  expression: "ruleForm.age",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("userManage.birthday"),
                                prop: "birthday",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  placeholder: _vm.$t("basicData.SelectADate"),
                                  "value-format": "yyyy-MM-dd",
                                },
                                model: {
                                  value: _vm.ruleForm.birthday,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "birthday", $$v)
                                  },
                                  expression: "ruleForm.birthday",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("userManage.userPhone") + "：",
                                prop: "phone",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  clearable: "",
                                  placeholder: _vm.$t(
                                    "GlobalSetObj.pleaseInput"
                                  ),
                                },
                                model: {
                                  value: _vm.ruleForm.phone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "phone", $$v)
                                  },
                                  expression: "ruleForm.phone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("login.email") + "：",
                                prop: "email",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  clearable: "",
                                  placeholder: _vm.$t(
                                    "GlobalSetObj.pleaseInput"
                                  ),
                                },
                                model: {
                                  value: _vm.ruleForm.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "email", $$v)
                                  },
                                  expression: "ruleForm.email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("userManage.gender"),
                                prop: "sex",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  model: {
                                    value: _vm.ruleForm.sex,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "sex", $$v)
                                    },
                                    expression: "ruleForm.sex",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("userManage.male"),
                                      value: 1,
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("userManage.female"),
                                      value: 2,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("userManage.entryTime"),
                                prop: "entryTime",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  placeholder: _vm.$t("basicData.SelectADate"),
                                  "value-format": "yyyy-MM-dd",
                                },
                                model: {
                                  value: _vm.ruleForm.entryTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "entryTime", $$v)
                                  },
                                  expression: "ruleForm.entryTime\t",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label:
                                  _vm.$t("userManage.detailedAddress") + "：",
                                prop: "address",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 4,
                                  clearable: "",
                                  placeholder: _vm.$t(
                                    "GlobalSetObj.pleaseInput"
                                  ),
                                },
                                model: {
                                  value: _vm.ruleForm.address,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "address", $$v)
                                  },
                                  expression: "ruleForm.address",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-divider"),
              _c("p", [_vm._v(_vm._s(_vm.$t("userManage.workRecord")))]),
              _c(
                "el-table",
                { attrs: { data: _vm.wordList, border: "" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("trajectory.Time"),
                      prop: "createTime",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("userManage.node"), prop: "node" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.node === 1
                                    ? _vm.$t("userManage.onJob")
                                    : _vm.$t("userManage.leaveJob")
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("basicData.Operator"),
                      prop: "createName",
                    },
                  }),
                ],
                1
              ),
              _c("el-divider"),
              _c("p", [_vm._v(_vm._s(_vm.$t("userManage.evaluationRecord")))]),
              _c(
                "el-table",
                { attrs: { data: _vm.evaluateList, border: "" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("userManage.time"),
                      prop: "createTime",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("userManage.evaluationLevel") },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.level
                              ? _c("div", [
                                  _c(
                                    "span",
                                    {
                                      class: ["level" + scope.row.level],
                                      staticStyle: { opacity: "1" },
                                    },
                                    [_vm._v(_vm._s(scope.row.level))]
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("WorkCentreTable.ticketDescribe"),
                      prop: "content",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("basicData.Operator"),
                      prop: "createName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "right", "margin-top": "20px" },
                },
                [
                  _vm.detailType === 1
                    ? [
                        _vm.$store.getters.button_permissions.includes(
                          "btn:user-center:personnel-registration:update"
                        )
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.addAndUpdateUser },
                              },
                              [_vm._v(_vm._s(_vm.$t("userManage.confirm")))]
                            )
                          : _vm._e(),
                      ]
                    : _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addAndUpdateUser },
                        },
                        [_vm._v(_vm._s(_vm.$t("userManage.confirm")))]
                      ),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("userManage.employeeEvaluation"),
            visible: _vm.dialogVisible,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$t("customerManagements.grade") + "：" },
                },
                _vm._l([1, 2, 3, 4], function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class: [
                        "level" + item,
                        _vm.level === item ? "level-active" : "",
                      ],
                      on: {
                        click: function ($event) {
                          _vm.level = item
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(item) + " ")]
                  )
                }),
                0
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("WorkCentreTable.ticketDescribe") + "：",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "80%" },
                    attrs: {
                      type: "textarea",
                      rows: 8,
                      placeholder: _vm.$t("userManage.pleaseInput"),
                    },
                    model: {
                      value: _vm.content,
                      callback: function ($$v) {
                        _vm.content = $$v
                      },
                      expression: "content",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.evaluateUser } },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }