<template>
  <!-- 网点选择器 虚拟列表 -->
  <ElSelectV2
    v-model="value"
    :multiple="multiple"
    :clearable="clearable"
    :multiple-limit="multipleLimit"
    :loading="loading"
    :options="list"
    :disabled="disabled"
    filterable
    :placeholder="$t('operationCenter.PleaseSelect')"
    @change="onChange"
  />
</template>

<script>
import ElSelectV2 from 'el-select-v2';
// import { getDistributionDotList } from '@/api/logisticsOrders';
import { apiCommonSite } from '@/api/logisticsOrders';

const SITE_LIST = 'siteList';

const param = {
  pageNumber: 1,
  pageSize: 9999999,
  params: {
    siteStatus: undefined,
    providerId: ''
  }
};

export default {
  name: '',

  components: {
    ElSelectV2
  },

  props: {
    // 是否多选
    multiple: {
      type: Boolean,
      default: false
    },

    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },

    // 是否可以清空选项
    clearable: {
      type: Boolean,
      default: true
    },

    // 多选时用户最多可以选择的项目数，为 0 则不限制
    multipleLimit: {
      type: Number,
      default: 0
    },

    // 应付主体id
    providerId: {
      type: [Number, String],
      required: true
    },

    // 网点id 用于回显
    val: {
      type: [Array, String, Number],
      default: () => []
    },

    // 网点状态 0未启用 1启用 undefined全部
    siteStatus: {
      type: Number,
      default: undefined
    }
  },

  data() {
    return {
      loading: false,
      list: [],
      value: []
    };
  },

  watch: {

    'val': {
      handler(val) {
        if (Array.isArray(val)) {
          this.value = [...val];
          return;
        }
        this.value = val;

        if (val) {
          setTimeout(() => {
            // 抛出事件
            this.onRow(val);
          }, 1000);
        }
      },
      immediate: true
    },

    'providerId': {
      handler() {
        // 清空数据
        this.value = [];
        this.list = [];
        this.onChange([]);
        this.getSitePageList();
      }
      // immediate: true
    }

  },

  created() {
    // 是否有临时缓存
    const siteList = window.sessionStorage.getItem(SITE_LIST);
    if (siteList) {
      this.list = JSON.parse(siteList).map(item => Object.freeze(item));
      // this.onChange(this.value);
      return;
    }

    // 不存在缓存
    setTimeout(() => {
      this.getSitePageList();
    }, 500);
  },

  methods: {
    // 分页获取站点
    getSitePageList() {
      param.params.providerId = this.providerId;
      param.params.siteStatus = this.siteStatus;

      this.loading = true;

      apiCommonSite(param, false).then(res => {
        const arr = (res.data.records || []).map(item => {
          const { name, id, providerId, providerName } = item;
          const obj = {
            label: name,
            value: id,
            providerId, // 主体
            providerName // 主体名称
          };
          return Object.freeze(obj);
        });

        this.list = arr;

        // 抛出事件
        if (this.val) {
          this.onRow(this.val);
        }

        // 临时缓存
        window.sessionStorage.setItem(SITE_LIST, JSON.stringify(arr));
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },

    onChange(val) {
      this.$emit('change', val);
    },

    // 抛出事件
    onRow(val) {
      const row = this.list.find(item => {
        return item.value === val;
      });
      if (row) {
        this.$emit('row', row);
      }
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
