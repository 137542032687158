var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      !_vm.level
        ? _c("tableCmp", { attrs: { superior: _vm.superior, level: 0 } })
        : _c("editDetails", {
            attrs: {
              row: _vm.row,
              superior: _vm.superior,
              "dad-data": _vm.dadData,
              parent: _vm.parentId,
            },
            on: { getLevel: _vm.getLevel },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }