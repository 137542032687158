var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "font-size": "16px" }, attrs: { id: "tagNumberPdf" } },
    [
      _c(
        "div",
        {
          staticStyle: { display: "flex", "justify-content": "space-between" },
        },
        [
          _c("div", [
            _c("img", {
              staticStyle: {
                width: "120px",
                height: "40px",
                "margin-left": "40px",
              },
              attrs: { src: require("@/assets/ajlogo1.svg") },
            }),
            _c(
              "div",
              {
                staticStyle: {
                  width: "600px",
                  "word-wrap": "break-word",
                  "word-break": "break-all",
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.paramShippingData.providerCode
                      ? _vm.paramShippingData.providerCode
                      : "- -"
                  ) + " "
                ),
              ]
            ),
            _c("div", [
              _vm._v(
                "CNPJ:" +
                  _vm._s(
                    _vm.paramShippingData.cnpj
                      ? _vm.paramShippingData.cnpj
                      : "- -"
                  )
              ),
            ]),
            _c("div", [
              _vm._v("Data de impressão:" + _vm._s(_vm.generateBrazilTime())),
            ]),
          ]),
          _vm._m(0),
        ]
      ),
      _c("div", [
        _c(
          "div",
          {
            staticStyle: {
              "font-weight": "600",
              "margin-bottom": "20px",
              "margin-top": "10px",
            },
          },
          [_vm._v("Responsible:")]
        ),
        _c("div", [
          _c(
            "table",
            {
              staticStyle: {
                width: "100%",
                "text-align": "center",
                "font-size": "16px",
                border: "1px solid #000000",
              },
              attrs: { border: "1", cellpadding: "0", cellspacing: "0" },
            },
            [
              _vm._m(1),
              _vm._l(
                _vm.paramShippingData.packNumListItems,
                function (item, index) {
                  return _c(
                    "tr",
                    {
                      key: index,
                      staticStyle: {
                        border: "1px solid #000000",
                        padding: "5px 0px",
                      },
                    },
                    [
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "1px solid #000000",
                            padding: "5px 0px",
                          },
                        },
                        [_vm._v(_vm._s(item.transitWarehouseBagNumber))]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "1px solid #000000",
                            padding: "5px 0px",
                          },
                        },
                        [_vm._v(_vm._s(item.baseCode))]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "1px solid #000000",
                            padding: "5px 0px",
                          },
                        },
                        [_vm._v(_vm._s(item.totalWeight))]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "1px solid #000000",
                            padding: "5px 0px",
                          },
                        },
                        [_vm._v("1")]
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { display: "flex", "justify-content": "center" } },
      [_c("img", { attrs: { id: "SHIPPING" } })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c(
        "tr",
        { staticStyle: { border: "1px solid #000000", padding: "5px 0px" } },
        [
          _c(
            "th",
            {
              staticStyle: { border: "1px solid #000000", padding: "5px 0px" },
            },
            [_vm._v("Numero do saca")]
          ),
          _c(
            "th",
            {
              staticStyle: { border: "1px solid #000000", padding: "5px 0px" },
            },
            [_vm._v("Codlate")]
          ),
          _c(
            "th",
            {
              staticStyle: { border: "1px solid #000000", padding: "5px 0px" },
            },
            [_vm._v("Peso total(kg)")]
          ),
          _c(
            "th",
            {
              staticStyle: { border: "1px solid #000000", padding: "5px 0px" },
            },
            [_vm._v("Volumes(m3)")]
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }