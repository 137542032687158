<template>
  <div>
    <el-card class="box-card">
      <div class="clearfix">
        <span>{{$t('newOrder.ProblematicParcelOperationData')}}</span>
      </div>
      <el-row :gutter="20">
        <el-col
          v-for="(item, index) in lossMetrics" :key="index"
          :xl="12"
          :lg="12"
          style="margin-bottom: 20px"
        >
          <div class="loss-item">
            <div>
              <div class="loss-title">{{ item.title }}</div>
              <div class="loss-value">{{ item.value|formatNumber }}</div>
              <div class="loss-change">
                {{ item.change|formatNumber(true) }} {{$t('newOrder.ComparedToYesterday')}}
              </div>
            </div>
            <div class="loss-img">
              <img :src="item.change<0?getImageUrl('problemItemLessThan'):item.change==0?getImageUrl('equivalenceProblemPieces'):getImageUrl('questionItemGreaterThan')">
            </div>
          </div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import { waybillDashboardProblemData } from '@/api/orderApi';

export default {
  name: 'QuestionItemOperationData',
  filters: {
    // 数字格式化函数
    formatNumber(num, showPositiveSign = false) {
      // 如果不是数字，直接返回原值
      if (isNaN(num) || num === null) return num;

      // 将数字转换为字符串
      let numStr = Number(num).toString();

      // 处理负数
      const isNegative = numStr.startsWith('-');
      if (isNegative) {
        numStr = numStr.slice(1);
      }

      // 分离整数部分和小数部分
      const parts = numStr.split('.');
      let integerPart = parts[0];
      const decimalPart = parts[1];

      // 添加千分位逗号
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      // 重组数字
      let result = integerPart;
      if (decimalPart) {
        result += '.' + decimalPart;
      }

      // 添加正负号
      if (isNegative) {
        result = '-' + result;
      } else if (showPositiveSign && num > 0) {
        result = '+' + result;
      }

      return result;
    }
  },
  props: {
    orderType: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      lossMetrics: [
        { title: this.$t('newOrder.Total'), value: 0, change: 0, valueKey: 'total', changeKey: 'totalDifference' },
        { title: this.$t('newOrder.ConfirmLost'), value: 0, change: 0, valueKey: 'lost', changeKey: 'lostDifference' },
        { title: this.$t('newOrder.AllDamaged'), value: 0, change: 0, valueKey: 'damaged', changeKey: 'damagedDifference' },
        { title: this.$t('newOrder.AllStolen'), value: 0, change: 0, valueKey: 'robbed', changeKey: 'robbedDifference' },
        { title: this.$t('newOrder.DeliveryAddressOutOfRange'), value: 0, change: 0, valueKey: 'detained', changeKey: 'detainedDifference' },
        { title: this.$t('newOrder.Others'), value: 0, change: 0, valueKey: 'other', changeKey: 'otherDifference' }
      ]
    };
  },
  watch: {
    orderType() {
      this.getData();
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      waybillDashboardProblemData(this.orderType)
        .then((res) => {
          if (res.status === 'OK') {
            this.lossMetrics.forEach((item) => {
              item.value = res.data[item.valueKey];
              item.change = res.data[item.changeKey];
            });
          }
        });
    },
    /**
     * @description 动态读取图片
     * @param fileName
     * @returns {*}
     */
    getImageUrl(fileName) {
      return require(`@/assets/images/kanban/${fileName}.png`);
    }
  }
};
</script>

<style scoped>
.clearfix{
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #1C1C1C;
  line-height: 22px;
  margin-bottom: 20px;
}
.loss-item {
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #E5E5E5;
  padding: 15px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .loss-title{
    font-family: PingFang SC;
    font-weight: bold;
    font-size: 16px;
    color: #1C1C1C;
    line-height: 22px;
  }
  .loss-value {
    font-family: PingFang SC;
    font-weight: bold;
    font-size: 28px;
    color: #009C37;
    line-height: 40px;
  }
  .loss-change{
    font-family: PingFang SC;
    font-weight: bold;
    font-size: 14px;
    color: #1C1C1C;
    line-height: 20px;
  }
}

.loss-change {
  justify-content: flex-start;
  margin-top: 5px;
}
</style>
