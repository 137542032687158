var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input", {
        ref: "excel-upload-input",
        staticClass: "excel-upload-input",
        attrs: { type: "file", accept: ".xlsx, .xls" },
        on: { change: _vm.handleClick },
      }),
      _c(
        "el-button",
        {
          attrs: {
            icon: "el-icon-upload2",
            loading: _vm.loading,
            size: "small",
            type: "primary",
          },
          on: { click: _vm.handleUpload },
        },
        [_vm._v(" " + _vm._s(_vm.butName) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }