// @ts-check

import i18n from '@/lang';
import { Message } from 'element-ui';
import { PAYABLE_TYPE } from '@/views/finance/payableManage/feesPayable/enums.js';

const pleaseChoose = i18n.t('GlobalSetObj.pleaseChoose');
const PleaseSelectTheBusinessTypeFirst = i18n.t('basicData.PleaseSelectTheBusinessTypeFirst'); // 请选择业务类型
const checkMsg = i18n.t('collectionCenter.checkMsg'); // 请勾选数据
const LanShouPeople = i18n.t('basicData.揽收操作人'); // 揽收人
const deliverer = i18n.t('basicData.派送操作人'); // 派送人
const lanshouTypee = i18n.t('collectionCenter.lanshouTypee'); // 揽收方式
const OperationMode = i18n.t('newOrder.OperationMode'); // 派送模式：

// 提交校验 (业务类型 + 揽收方式/派送模式)
export const submitCheckObj = {

  /**
   * 揽收-上门揽收/上门交件
   * 条件：判断必须选择揽收人，并且生成对账单弹窗内“账单类型”默认选中“第三方上门揽收”并且不可更改
   * @param {object} params 搜索条件
   * @returns {boolean} 校验不通过返回false
   */
  '220': (params) => {
    const { collectorIdList = [] } = params;
    if (!collectorIdList.length) {
      Message.warning(`${pleaseChoose}${LanShouPeople}`); // 请选择揽收人
      return false;
    }
    return true;
  },

  /**
   * 派送-DSP
   * 条件：无限制条件
   * @param {object} params 搜索条件
   * @returns {boolean} 校验不通过返回false
   */
  '301': (params) => {
    return true;
  },

  /**
   * 派送-AJD
   * 条件：判断必须选择派送人，并且生成对账单弹窗内“账单类型”默认选中“派送DSP/派送AJD”并且不可更改
   * @param {object} params 搜索条件
   * @returns {boolean} 校验不通过返回false
   */
  '300': (params) => {
    // 复用判断条件
    const { dispatcherList = [] } = params;

    if (!dispatcherList.length) {
      Message.warning(`${pleaseChoose}${deliverer}`); // 请选择派送人
      return false;
    }
    return true;
  }
};

/**
 * 设置 账单类型
 * @param {object} params 搜索条件
 * @param {number} businessType 业务类型 2 揽收 3 派送
 * @param {number} collectType 揽收方式 1 上门交件  2 上门揽收
 * @param {number} operationMode 派送模式：0 AJD 1 DSP
 * @returns {[boolean, number]}
 */
const setPayableType = (params, businessType, collectType, operationMode) => {
  let val = 0;
  let isBool = false;

  // 选择了揽收生成应付需要验证揽收人
  if (businessType === 2 && collectType === 2) {
    isBool = submitCheckObj['220'](params);
    val = PAYABLE_TYPE['2'].value;
    if (!isBool) {
      return [false, 0];
    }
    return [true, val];
  }

  // AJD --需要验证派件人
  if (businessType === 3 && operationMode === 0) {
    isBool = submitCheckObj['300'](params);
    val = PAYABLE_TYPE['3'].value;
    if (!isBool) {
      return [false, 0];
    }
    return [isBool, val];
  }

  return [true, 0];
};

/**
 * 生成应付校验
 * @param {any[]} selectData 勾选则数据
 * @param {object} queryForm 搜索条件
 * @returns {[boolean, number]} 校验不通过返回 [false, 0]
 */
export const setGenerateCheck = (selectData, queryForm) => {
  const len = selectData.length;
  const { params } = queryForm;
  const { businessType, operationMode, collectType } = params;

  // 未勾选默认全部数据
  if (len === 0) {
    // 暂时不考虑勾选模式
    return [true, 0];
  } else {
    // 搜索栏控制
    const [isBool, val] = setPayableType(params, businessType, collectType, operationMode);
    return [isBool, val];
  }
};

