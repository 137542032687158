<script >
import { defineProps, computed, defineEmits } from '@vue/composition-api';
import i18n from '@/lang';
const __sfc_main = {};
__sfc_main.props = {
  isLastStep: {
    type: Boolean,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const buttonText = computed(() => {
    return props.isLastStep ? i18n.t('driver.modalSendButton') : i18n.t('driver.modalNextButton');
  });
  const emit = __ctx.emit;
  return {
    buttonText,
    emit
  };
};
export default __sfc_main;
</script>

<template>
  <o-button variant="primary" size="md" native-type="submit" @click="() => emit('handleSubmit')">
    {{ buttonText }}
  </o-button>
</template>
