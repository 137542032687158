<template>
  <div class="container">
    <div class="searchBox">
      <el-form
        ref="searchForm"
        v-model="queryForm"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="5" style="height: 70px">
            <!--开票状态-->
            <el-form-item :label="$t('GlobalSetObj.InvoicingStatus')">
              <el-select
                v-model="queryForm.params.invoiceStatus"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                filterable
                style="width: 100%;"
                @change="searchFormGetTable(null)"
              >
                <!--全部-->
                <el-option key="1" :label="$t('GlobalSetObj.all')" value="" />
                <!--待开票-->
                <el-option key="2" :label="$t('GlobalSetObj.ToBeInvoiced')" :value="1" />
                <!--已开票-->
                <el-option key="3" :label="$t('GlobalSetObj.Invoiced')" :value="2" />
                <!--已拒绝-->
                <el-option key="4" :label="$t('GlobalSetObj.Rejected')" :value="3" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5" style="height: 70px">
            <!--区间-->
            <el-form-item :label="$t('GlobalSetObj.interval')">
              <el-select
                v-model="queryForm.params.section"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                filterable
                style="width: 100%;"
                @change="searchFormGetTable(null)"
              >
                <!--全部-->
                <el-option key="1" :label="$t('GlobalSetObj.all')" value="" />
                <!--揽收区间-->
                <el-option key="2" :label="$t('GlobalSetObj.HarvestRange')" :value="2" />
                <!--派送区间-->
                <el-option key="3" :label="$t('GlobalSetObj.DeliverySection')" :value="3" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5" style="height: 70px">
            <!--类型-->
            <el-form-item :label="$t('operationCenter.Type')">
              <el-select
                v-model="queryForm.params.invoiceType"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                filterable
                style="width: 100%;"
                @change="searchFormGetTable(null)"
              >
                <!--全部-->
                <el-option key="1" :label="$t('GlobalSetObj.all')" value="" />
                <el-option key="2" :value="1" label="CT-e" />
                <!--                <el-option key="3" :value="2" label="NFS-e" />-->
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="9" style="height: 70px">
            <!--物流发票开票时间-->
            <el-row>
              <el-col :span="$i18n.locale=='zh' ? 10 : 20" class="selectType">
                <el-select
                  v-model="queryForm.params.searchDateType"
                  :placeholder="$t('orderDetails.pleaseChoose')"
                  size="mini"
                  style="width: 100%;"
                  @change="changeNumberType(1, queryForm.params.searchDateType)"
                >
                  <el-option key="0" :label="$t('GlobalSetObj.LogisticsInvoiceInvoicingTime')" :value="1" />
                  <!--                  <el-option key="1" :label="$t('GlobalSetObj.TimeOfOrder')" :value="2" />-->
                </el-select>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item>
                  <el-date-picker
                    v-model="invoiceDate"
                    :end-placeholder="$t('GlobalSetObj.endDate')"
                    :format="$i18n.locale==='zh'?zhDate:ptDate"
                    :range-separator="$t('GlobalSetObj.to')"
                    :start-placeholder="$t('GlobalSetObj.startDate')"
                    style="width: 100%;"
                    type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @change="searchFormGetTable(null)"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8">
            <el-row>
              <el-col :span="$i18n.locale=='zh'?10:20" class="selectType">
                <el-select
                  v-model="queryForm.params.searchNumberType"
                  :placeholder="$t('orderDetails.pleaseChoose')"
                  size="mini"
                  style="width: 100%;"
                  @change="changeNumberType(2, queryForm.params.searchNumberType)"
                >
                  <el-option v-for="item in selectLabel" :key="item.id" :label="item.label" :value="item.value" />
                </el-select>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item class="NumberClass">
                  <div class="searchOrder" style="padding-left: 16px">
                    <el-input
                      v-model.trim="queryForm.params.numberData"
                      :placeholder="$t('orderDetails.pleaseInput')"
                      clearable
                      @keyup.enter.native="searchFormGetTable(1)"
                    />
                    <div style="width: 40px;">
                      <el-button class="chaxunBtn" @click="searchFormGetTable(1)">
                        <i class="iconfont icon-chaxun" style="font-size: 24px" />
                      </el-button>
                    </div>
                    <div style="width: 38px;">
                      <el-popover
                        v-model="showNumList"
                        placement="bottom-end"
                        trigger="manual"
                      >
                        <div style="width: 300px;">
                          <el-input
                            v-model="searchNumberStr"
                            :placeholder="$t('newOrder.PreciseSearch')"
                            :rows="10"
                            type="textarea"
                          />
                          <div style="margin-top: 10px;">
                            <!--清空-->
                            <el-button size="small" @click="clearSearchNum()">{{ $t('newOrder.empty') }}</el-button>
                            <div style="float: right">
                              <!--关闭-->
                              <el-button size="small" @click="openNumList">{{ $t('orderExport.close') }}</el-button>
                              <!--搜索-->
                              <el-button plain size="small" type="primary" @click="searchFormGetTable(2)">
                                {{ $t('newOrder.search') }}
                              </el-button>
                            </div>
                          </div>
                        </div>
                        <el-button slot="reference" class="btnIcon" @click="openNumList">
                          <i class="iconfont icon-liebiao1" style="font-size: 24px" />
                        </el-button>
                      </el-popover>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <div v-show="searchNumberList.length > 0">
          <div class="searchNum">
            <el-tooltip class="item" effect="dark" placement="bottom">
              <template slot="content">
                <pre>{{ searchNumberStr }}</pre>
              </template>
              <div class="searchNumWarp">
                <div class="wordBreak">
                  <p>
                    {{ searchNameObj.label }}：
                    {{ searchNumberList.join(',') }}
                  </p>
                </div>
              </div>
            </el-tooltip>
            <div class="clearSearchNum" @click="clearSearchNum()">
              <i class="el-icon-close" />
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <div style="padding: 10px 20px">
      <el-table
        ref="tableData"
        :data="tableData"
        border
      >
        <el-table-column :label="$t('operationCenter.ind')" align="center" width="160px">
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <template v-for="(item, ind) in tableColumn">
          <el-table-column
            :key="ind"
            :label="item.label"
            :prop="item.prop"
            :width="item.width"
            align="center"
          >
            <template v-if="item.tips" slot="header">
              <div>
                <span>{{ item.label }}</span>
                <el-tooltip class="QuestionMark" placement="top">
                  <div slot="content">{{ item.tips }}</div>
                  <svg-icon icon-class="QuestionMark" />
                </el-tooltip>
              </div>
            </template>
            <template slot-scope="scope">
              <div v-if="item.isCopy">
                <div v-if="scope.row[item.prop]">
                  <el-popover
                    placement="top"
                    popper-class="copy"
                    trigger="hover"
                  >
                    <el-button
                      style="color: black;font-size: 12px;padding: 2px"
                      type="text"
                      @click="copy(scope.row[item.prop])"
                    >
                      {{ $t('orderDetails.copy') }}
                    </el-button>
                    <div
                      slot="reference"
                      :class="item.highlight ? 'WaybillNumber' : ''"
                      @click="(e) => $emit('to-OrderDetail',scope.row)"
                    >
                      {{ scope.row[item.prop] ? scope.row[item.prop] : '--' }}
                    </div>
                  </el-popover>
                </div>
                <div v-else>--</div>
              </div>
              <div v-else-if="item.endProp">
                {{ scope.row[item.prop] }} ~ {{ scope.row[item.endProp] }}
              </div>
              <span v-else>{{ scope.row[item.prop] ? scope.row[item.prop] : '--' }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <div class="pagination">
        <el-pagination
          :current-page.sync="queryForm.pageNumber"
          :page-size.sync="queryForm.pageSize"
          :page-sizes="[10, 20, 40, 60, 80, 100]"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          style="margin: 10px 0"
          @current-change="getCurrentTableData"
          @size-change="getSizeTableData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { copyData } from '@/utils/copyData';
import { apiLogisticsInvoicePage } from '@/api/logisticsOrders';
import dayjs from 'dayjs';

export default {
  name: 'AllInvoice',
  data() {
    return {
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          invoiceStatus: '', // 状态
          section: '', // 区间
          invoiceType: '', // 类型
          searchDateType: 1, // 时间类型 1：物流发票开票时间
          invoiceIssueStartTime: '', // 开始时间
          invoiceIssueEndTime: '', // 结束时间
          searchNumberType: 1, // 单号类型
          waybillLogisticsNumber: [], // 运单号/物流单号
          invoiceNumbers: [], // 发票号
          invoiceSecretKeys: [] // 发票密钥
        }
      },
      total: 0,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      invoiceDate: this.getCurrentDate(),
      showNumList: false,
      searchNumberStr: '',
      searchNumberList: [],
      selectLabel: [
        // 运单号/物流单号
        { id: 1, label: this.$t('newOrder.waybillNumber') + '/' + this.$t('GlobalSetObj.serviceLogistics'), value: 1 },
        // 物流发票号
        { id: 2, label: this.$t('GlobalSetObj.LogisticsInvoiceNumber'), value: 2 },
        // 物流发票密钥
        { id: 3, label: this.$t('GlobalSetObj.LogisticsInvoiceKey'), value: 3 }
      ],
      searchNameObj: {
        id: 1,
        label: this.$t('newOrder.waybillNumber') + '/' + this.$t('GlobalSetObj.serviceLogistics'),
        value: 1
      },
      tableData: [],
      tableColumn: [
        // 运单号
        { label: this.$t('GlobalSetObj.waybillNumber'), prop: 'waybillNumber', width: 200, isCopy: true },
        // 服务商单号
        { label: this.$t('GlobalSetObj.serviceLogistics'), prop: 'logisticsNumber', width: 220, isCopy: true },
        // 物流发票号
        { label: this.$t('GlobalSetObj.LogisticsInvoiceNumber'), prop: 'invoiceNumber', width: 200, isCopy: true },
        // 物流发票密钥
        { label: this.$t('GlobalSetObj.LogisticsInvoiceKey'), prop: 'invoiceSecretKey', width: 220, isCopy: true },
        // 揽收费
        { label: this.$t('GlobalSetObj.Charge') + '(R$/$)', prop: 'collectFee', width: 160 },
        // 派送费
        { label: this.$t('GlobalSetObj.DeliveryFee') + '(R$/$)', prop: 'deliverFee', width: 160 },
        // 运费
        { label: this.$t('GlobalSetObj.freight') + '(R$/$)', prop: 'estimateFee', width: 160 },
        // 税率
        { label: this.$t('GlobalSetObj.rate'), prop: 'taxRate', width: 160 },
        // 含税运费
        { label: this.$t('GlobalSetObj.ShippingCostsIncludeTax') + '(R$/$)', prop: 'freightIncludeRate1', width: 320 },
        // 发票金额
        { label: this.$t('GlobalSetObj.TheInvoiceAmount'), prop: 'freightIncludeRate', width: 160 }, // logisticsInvoiceKey
        // 开票时间
        { label: this.$t('GlobalSetObj.InvoicingTime'), prop: 'invoiceIssueTime', width: 200 },
        // 开票状态
        { label: this.$t('GlobalSetObj.InvoicingStatus'), prop: 'invoiceStatusName', width: 180 },
        // 区间
        { label: this.$t('GlobalSetObj.interval'), prop: 'sectionName', width: 160 },
        // 类型
        { label: this.$t('operationCenter.Type'), prop: 'invoiceTypeName', width: 160 },
        // 运输范围
        { label: this.$t('GlobalSetObj.ScopeOfTransportation'), prop: 'startCity', endProp: 'endCity', width: 200 },
        // 商品发票
        { label: this.$t('GlobalSetObj.CommodityInvoices'), prop: 'owInvoiceNumber', width: 200 },
        // 商品发票密钥
        { label: this.$t('GlobalSetObj.CommodityInvoiceKey'), prop: 'owInvoiceAccessKey', width: 230 },
        // 开票时间
        { label: this.$t('GlobalSetObj.InvoicingTime'), prop: 'owInvoiceIssueDate', width: 200 },
        // 失败原因
        { label: this.$t('GlobalSetObj.ReasonForFailure'), prop: 'generateFailReason', width: 200 },
        // 下单时间
        { label: this.$t('GlobalSetObj.TimeOfOrder'), prop: 'owCreateTime', width: 200 }
      ],
      searchType: null
    };
  },
  computed: {
    BillingStatus() {
      return {
        1: { label: this.$t('GlobalSetObj.ToBeInvoiced'), code: 1 }, // 待开票
        2: { label: this.$t('GlobalSetObj.Invoiced'), code: 2 }, // 已开票
        3: { label: this.$t('GlobalSetObj.Rejected'), code: 3 } // 已拒绝
      };
    },
    rangeStatus() {
      return {
        2: { label: this.$t('GlobalSetObj.HarvestRange'), code: 2 }, // 揽收区间
        3: { label: this.$t('GlobalSetObj.DeliverySection'), code: 3 } // 派送区间
      };
    }
  },
  created() {
    this.searchFormGetTable(null);
  },
  methods: {
    // 搜索
    searchFormGetTable(type) {
      this.searchType = type;
      this.queryForm.pageNumber = 1;
      this.changeFormGetTable();
    },
    // 重新生成
    rebuildInvoice(data) {
    },
    // 点击复制
    copy(data) {
      copyData(data);
    },
    // 获取当前日期
    getCurrentDate() {
      const date = new Date();
      const brazilTime = date.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
      const today = dayjs(brazilTime).format('YYYY-MM-DD');
      return [today + ' 00:00:00', today + ' 23:59:59'];
    },
    // 搜索
    changeFormGetTable() {
      this.searchNumberList = [];
      const param = {
        pageSize: this.queryForm.pageSize,
        pageNumber: this.queryForm.pageNumber,
        params: {
          invoiceStatus: this.queryForm.params.invoiceStatus,
          section: this.queryForm.params.section,
          invoiceType: this.queryForm.params.invoiceType,
          invoiceIssueStartTime: '',
          invoiceIssueEndTime: '',
          waybillLogisticsNumber: [], // 运单号/物流单号
          invoiceNumbers: [], // 发票号
          invoiceSecretKeys: [] // 发票密钥
        }
      };
      if (this.searchType === 1) {
        if (this.searchNumberStr) {
          this.searchNumberStr = '';
        }
      } else if (this.searchType === 2) {
        if (this.queryForm.params.numberData) {
          this.queryForm.params.numberData = '';
        }
      }
      if (this.queryForm.params.numberData) {
        if (this.queryForm.params.searchNumberType === 1) {
          param.params.waybillLogisticsNumber = [this.queryForm.params.numberData];
          param.params.invoiceNumbers = [];
          param.params.invoiceSecretKeys = [];
        } else if (this.queryForm.params.searchNumberType === 2) {
          param.params.waybillLogisticsNumber = [];
          param.params.invoiceNumbers = [this.queryForm.params.numberData];
          param.params.invoiceSecretKeys = [];
        } else {
          param.params.waybillLogisticsNumber = [];
          param.params.invoiceNumbers = [];
          param.params.invoiceSecretKeys = [this.queryForm.params.numberData];
        }
      } else if (this.searchNumberStr) {
        const num = this.searchNumberStr.split(/([\n\r])/);
        num.map(item => {
          const reg = /\s/g;
          const a = item.replace(reg, '');
          const b = a.trim();
          if (b) {
            this.searchNumberList.push(b);
          }
        });
        if (this.queryForm.params.searchNumberType === 1) {
          param.params.waybillLogisticsNumber = this.searchNumberList;
          param.params.invoiceNumbers = [];
          param.params.invoiceSecretKeys = [];
        } else if (this.queryForm.params.searchNumberType === 2) {
          param.params.waybillLogisticsNumber = [];
          param.params.invoiceNumbers = this.searchNumberList;
          param.params.invoiceSecretKeys = [];
        } else {
          param.params.waybillLogisticsNumber = [];
          param.params.invoiceNumbers = [];
          param.params.invoiceSecretKeys = this.searchNumberList;
        }
      } else {
        param.params.waybillLogisticsNumber = [];
        param.params.invoiceNumbers = [];
        param.params.invoiceSecretKeys = [];
      }
      if (this.invoiceDate && this.invoiceDate.length > 0) {
        param.params.invoiceIssueStartTime = this.invoiceDate[0];
        param.params.invoiceIssueEndTime = this.invoiceDate[1];
      } else {
        param.params.invoiceIssueStartTime = '';
        param.params.invoiceIssueEndTime = '';
      }
      this.showNumList = false;
      console.log(param, '===param');
      apiLogisticsInvoicePage(param).then(res => {
        if (res.status === 'OK') {
          this.tableData = res.data.records;
          this.total = res.data.total;
          this.tableData.map(item => {
            if (item.invoiceType == 2) {
              item.freightIncludeRate1 = item.estimateFee;
            } else {
              item.freightIncludeRate1 = item.freightIncludeRate;
            }
            if (this.BillingStatus[item.invoiceStatus].label) {
              item.invoiceStatusName = this.BillingStatus[item.invoiceStatus].label;
            }
            if (this.rangeStatus[item.section].label) {
              item.sectionName = this.rangeStatus[item.section].label;
            }
          });
        }
      });
    },
    // 打开\关闭多个单号输入
    openNumList() {
      this.showNumList = !this.showNumList;
    },
    // 清除多行列表搜索
    clearSearchNum() {
      this.searchNumberList = [];
      this.searchNumberStr = '';
    },
    // 单号类型切换
    changeNumberType(type, data) {
      if (type === 2) {
        this.selectLabel.map(item => {
          if (data === item.value) {
            this.searchNameObj = item;
          }
        });
      }
      this.changeFormGetTable();
    },
    // 分页
    getCurrentTableData(val) {
      this.queryForm.pageNumber = val;
      this.changeFormGetTable();
    },
    getSizeTableData(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.changeFormGetTable();
    }
  }
};
</script>

<style scoped>
.searchBox {
  padding: 10px 20px 0 20px;
}

/*.NumberClass ::v-deep .el-form-item__content {*/
/*  margin-left: 0 !important;*/
/*}*/

.selectType ::v-deep.el-input--suffix .el-input__inner {
  border: none !important;
  font-weight: bold;
  font-size: 14px;
  padding: 0;
  text-align: left;
}

.btnIcon {
  padding: 6px;
}

.searchOrder {
  display: flex;
  margin-left: -15px;
}

.searchOrder .el-popover__reference-wrapper .btnIcon {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 40px;
}

.container >>> .searchOrder .el-input__inner {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.container >>> .searchOrder .el-input-group__prepend {
  background-color: transparent !important;
}

.chaxunBtn {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  width: 40px;
  height: 40px;
  padding: 7px;
}

.searchNum {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 15px;
  height: 28px;
}

.searchNumWarp {
  max-width: 60%;
  height: 28px;
  border: 1px solid #91B8FA;
  border-right: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 5px 5px 5px 5px;
  background: rgba(145, 184, 250, 0.2);
}

.searchNumWarp p {
  margin: 0;
}

.clearSearchNum {
  height: 28px;
  border: 1px solid #91B8FA;
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: rgba(145, 184, 250, 0.2);
  cursor: pointer;
  padding-right: 5px;
  padding-top: 5px;
}

.wordBreak {
  word-break: break-all; /*word-break: normal|break-all|keep-all;在恰当的断字点进行换行,break-all 允许在单词内换行,keep-all 只能在半角空格或连字符处换行,normal 使用浏览器默认的换行规则。*/
  text-overflow: ellipsis; /*溢出文字显示省略号*/
  display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 1; /** 显示的行数 **/
  overflow: hidden; /** 隐藏超出的内容 **/
}

>>> .el-form--label-top .el-form-item__label {
  width: 100% !important;
}

.searchBox .el-form-item {
  margin-bottom: 6px;
}

.QuestionMark {
  text-align: center;
  cursor: pointer;
}

.WaybillNumber {
  color: #409eff;
  cursor: pointer;
  padding: 0;
}

>>> .errTable .el-table thead > tr > th {
  background-color: #eee !important;
  color: #666;
}

>>> .errTable .el-table__header * {
  border-color: #ddd !important;
}
</style>
