export const bank = {
  // tableData
  bank: {
    zh: '银行',
    en: '',
    pu: 'Banco'
  },
  add: {
    zh: '添加',
    en: '',
    pu: 'Adicionar'
  },
  companyName: {
    zh: '公司名称',
    en: '',
    pu: 'Companhia'
  },
  companyBeneficiary: {
    zh: '公司受益人',
    en: '',
    pu: 'Beneficiário'
  },
  noResults: {
    zh: '没有结果',
    en: '',
    pu: 'Sem resultados'
  },
  createTitle: {
    zh: '添加银行资料',
    en: '',
    pu: 'Adicionando dados bancários'
  },
  createParagraph: {
    zh: '请填写下面的字段，以添加公司的所有银行数据',
    en: '',
    pu: 'Preencha os campos logo abaixo para adicionar todos os dados bancários da companhia'
  },

  // actions
  edit: {
    zh: '编辑',
    en: '',
    pu: 'Editar'
  },
  delete: {
    zh: '删除',
    en: '',
    pu: 'Excluir'
  },
  editTitle: {
    zh: '编辑车辆',
    en: '',
    pu: 'Editando veículo'
  },
  editParagraph: {
    zh: '请填写下方的字段以完成在我们系统中编辑车辆的操作。',
    en: '',
    pu: 'Preencha os campos logo abaixo para realizar a edição de um veículo em nosso sistema'
  },
  deleteTitle: {
    zh: '您确定吗？',
    en: '',
    pu: 'Você tem certeza absoluta?'
  },
  deleteParagraph: {
    zh: '此操作无法撤销。它将使公司的银行信息无效。',
    en: '',
    pu: 'Essa ação não pode ser desfeita. Isso irá desativar as informações bancárias da companhia'
  },
  buttonCancel: {
    zh: '取消',
    en: '',
    pu: 'Cancelar'
  },
  buttonDelete: {
    zh: '是的，我想要删除',
    en: '',
    pu: 'Sim, quero excluir'
  },

  // formDataBank
  companyNamePlaceholder: {
    zh: '输入公司名称',
    en: '',
    pu: 'Digite o nome da companhia'
  },
  bankPlaceholder: {
    zh: '输入银行名称',
    en: '',
    pu: 'Digite o banco'
  },
  agency: {
    zh: '机构',
    en: '',
    pu: 'Agência'
  },
  agencyPlaceholder: {
    zh: '输入机构名称',
    en: '',
    pu: 'Digite a agência'
  },
  account: {
    zh: '账号',
    en: '',
    pu: 'Conta'
  },
  accountPlaceholder: {
    zh: '输入账号',
    en: '',
    pu: 'Digite o número da conta'
  },
  beneficiaryPlaceholder: {
    zh: '输入受益人名称',
    en: '',
    pu: 'Digite o nome do beneficiário'
  },
  contact: {
    zh: '联系人',
    en: '',
    pu: 'Contato'
  },
  contactPlaceholder: {
    zh: '输入联系人名称',
    en: '',
    pu: 'Digite o contato'
  },
  email: {
    zh: '电子邮件',
    en: '',
    pu: 'Email'
  },
  emailPlaceholder: {
    zh: '输入电子邮件',
    en: '',
    pu: 'Digite o nome do email'
  },
  phone: {
    zh: '电话',
    en: '',
    pu: 'Telefone'
  },
  phonePlaceholder: {
    zh: '输入电话号码',
    en: '',
    pu: 'Digite o nome do telefone'
  },

  a: {
    zh: '',
    en: '',
    pu: ''
  }
};
