// 新操作中心
// 路由
const operationCenterRoute = {
  operationCenter: {
    zh: '新操作中心',
    en: 'operation center',
    pu: 'ovo Centro de Ação'
  },
  operation: {
    zh: '出/入库操作',
    en: 'operation',
    pu: 'Operações de saída/saída'
  },
  bagLabel: {
    zh: '大包列表',
    en: 'bag label',
    pu: 'Lista de pacotes grandes'
  },
  packingList: {
    zh: '总单列表',
    en: 'packing list',
    pu: 'Lista Total'
  },
  airBillOfLadingNo: {
    zh: '航空提单列表',
    en: 'air bill of lading no',
    pu: 'Lista de contas de companhias aéreas de embarque'
  },
  sorterBagSmall: {
    zh: '分拣机小包记录',
    en: 'sorter bag small',
    pu: 'ravação do pacote Sorter'
  },
  sorterBagBig: {
    zh: '分拣机大包记录',
    en: 'sorter bag big',
    pu: 'Registros de grandes parcelas do Sorter'
  }
};

// 电子秤插件提示
const ElectronicScalePlugin = {
  WeighingMethod: {
    zh: '称重方式',
    en: 'Weighing method',
    pu: 'Método de peso'
  },
  SerialPort: {
    zh: '串口',
    en: 'Serial port',
    pu: 'Porto série'
  },
  Other: {
    zh: '其它',
    en: 'other',
    pu: 'outros'
  },
  PleaseSelectSerialPort: {
    zh: '请选择串口',
    en: 'Please select serial port',
    pu: 'Por favor seleccione o porto em série'
  },
  PlugInNotStarted: {
    zh: '插件未启动，请联系管理员',
    en: 'The plug-in is not started, please contact the administrator',
    pu: 'O plug-in não está iniciado, por favor contacte o administrador'
  },
  FailedToOpenSerialPort: {
    zh: '串口打开失败',
    en: 'Failed to open serial port',
    pu: 'Fallou a abertura do porto em série'
  },
  FailedToGetScaleReading: {
    zh: '获取电子秤读数失败',
    en: 'Failed to get scale reading',
    pu: 'Não conseguimos ler a escala'
  },
  FailedToCloseTheScale: {
    zh: '关闭电子秤失败',
    en: 'Failed to close the scale',
    pu: 'Fallou em fechar a escala'
  },
  SerialPortOpenedSuccessfully: {
    zh: '串口打开成功',
    en: 'Serial port opened successfully',
    pu: 'Porto série aberto com sucesso'
  },
  TheScaleReadingIsNormal: {
    zh: '电子秤读数正常',
    en: 'The scale reading is normal',
    pu: 'A leitura em escala é normal'
  }
};

// 按钮
const operationCenter = {
  all: {
    zh: '全部',
    en: 'all',
    pu: 'Todos'
  },
  ElectronicWeighing: {
    zh: '电子称重',
    en: 'Electronic weighing',
    pu: 'Pesagem eletronica'
  },
  WeighTheQuery: {
    zh: '称重查询',
    en: 'Weigh the query',
    pu: 'Pesquisar o peso'
  },
  LoadOperation: {
    zh: '装载操作',
    en: 'Load operation',
    pu: 'Adicionar o pacote na lista'
  },
  ListOfLargePackages: {
    zh: '大包列表',
    en: 'List of large packages',
    pu: 'Consolidação'
  },
  TotalSingleStowage: {
    zh: '总单配载',
    en: 'Total single stowage',
    pu: 'Saida do armazém'
  },
  ListOfTotalOrders: {
    zh: '总单列表',
    en: 'List of total orders',
    pu: 'Lista final de saída'
  },
  VoiceTesting: {
    zh: '语音测试',
    en: 'Voice testing',
    pu: 'Teste de voz'
  },
  Console: {
    zh: '控制台',
    en: 'Console',
    pu: 'Centro de controle'
  },

  OrderSaving: {
    zh: '订单保存',
    en: 'Order saving',
    pu: 'Salvar pedio'
  },
  Save: {
    zh: '保存',
    en: 'Save',
    pu: 'Salvar'
  },
  Type: {
    zh: '类型',
    en: 'Type',
    pu: 'tipo'
  },
  Number: {
    zh: '单号',
    en: '',
    pu: 'Número de pedido'
  },
  TransferNumber: {
    zh: '转单号',
    en: 'Transfer number',
    pu: 'No. rastreamento (correios ou transportadora)'
  },
  channelConfiguration: {
    zh: '客户渠道配置的预报重量',
    en: 'The forecast weight of the customer\'s channel configuration',
    pu: 'O peso de previsão da configuração do canal do cliente'
  },
  Weight: {
    zh: '重量',
    en: 'Weight',
    pu: 'Peso'
  },
  ManualEntry: {
    zh: '手工录入',
    en: 'Manual entry',
    pu: 'Entrada manual'
  },
  TheResultOfTheOperation: {
    zh: '操作结果',
    en: 'The result of the operation',
    pu: 'O resultado da operação'
  },
  'IfTheWeighingDoesNotRespond, PleaseRemoveTheAnimalProduct': {
    zh: '(若称重无响应，请挪动物品)',
    en: 'If the weighing does not respond, please remove the animal product',
    pu: '(Se não houver retorno da pesagem, mova o objeto)'
  },
  PleaseScan: {
    zh: '请扫描',
    en: 'Please scan',
    pu: 'Favor de escanear'
  },
  ExceedForecastWeightTreatment: {
    zh: '超出预报重量处理',
    en: 'Exceed forecast weight treatment',
    pu: 'Tratamento para peso acima do limite previsto'
  },
  WhetherToProcessManually: {
    zh: '是否手动处理',
    en: 'Whether to process manually',
    pu: 'Limite de excesso de peso'
  },
  Manual: {
    zh: '手动',
    en: 'Manual',
    pu: 'manual'
  },
  Automatic: {
    zh: '自动',
    en: 'Automatic',
    pu: 'automatico'
  },
  'MaximumExcessWeight(g)': {
    zh: '最大超出重量(g)',
    en: 'Maximum excess weight(g)',
    pu: 'limite maximo de excesso de peso (g)'
  },
  RepeatOperation: {
    zh: '复重操作',
    en: 'Repeat operation',
    pu: 'Repetir operação'
  },
  WhetherToRepeat: {
    zh: '是否复重',
    en: 'WhetherToRepeat',
    pu: 'gostaria de repetir'
  },
  No: {
    zh: '否',
    en: 'No',
    pu: 'não'
  },
  Yes: {
    zh: '是',
    en: 'Yes',
    pu: 'sim'
  },

  PacketNumber: {
    zh: '小包单号',
    en: 'Packet number',
    pu: 'No. de rastreamento'
  },
  channel: {
    zh: '渠道',
    en: 'channel',
    pu: 'Canal'
  },
  country: {
    zh: '国家',
    en: 'country',
    pu: 'País de destino'
  },
  partition: {
    zh: '分区',
    en: 'partition',
    pu: 'Zona'
  },
  category: {
    zh: '类别',
    en: 'category',
    pu: 'tipo de envio'
  },
  CustomerWeight: {
    zh: '客户重量',
    en: 'Customer weight',
    pu: 'Peso declarado'
  },
  client: {
    zh: '客户',
    en: 'client',
    pu: 'Cliente'
  },
  freight: {
    zh: '运费',
    en: 'freight',
    pu: 'Frete'
  },
  TotalWeight: {
    zh: '总重量',
    en: 'Total weight',
    pu: 'Peso total'
  },
  'TotalNumberOfPieces': {
    zh: '总件数(件)',
    en: 'Total number of pieces (pieces)',
    pu: 'Total de peça (peças)'
  },
  VoiceBroadcast: {
    zh: '语音播报',
    en: 'Voice broadcast',
    pu: 'Notificação por voz'
  },

  MoreActions: {
    zh: '更多操作',
    en: 'More actions',
    pu: 'Outras operações'
  },
  ExportOrders: {
    zh: '导出订单',
    en: 'Export orders',
    pu: 'Exportar pedido'
  },
  EmptyTheList: {
    zh: '清空列表',
    en: 'Empty the list',
    pu: 'Limpar lista'
  },

  date: {
    zh: '日期',
    en: 'date',
    pu: 'Data'
  },

  Procurar: {
    zh: '搜索',
    en: 'Search',
    pu: 'Pesquisar'
  },

  reset: {
    zh: '重置',
    en: 'reset',
    pu: 'Reconfigurar'
  },

  exports: {
    zh: '导出',
    en: 'exports',
    pu: 'Exportação'
  },

  ind: {
    zh: '序号',
    en: 'index',
    pu: 'No.'
  },

  consoles: {
    zh: '工作台',
    en: 'consoles',
    pu: 'Bancada'
  },

  ReferenceNumber: {
    zh: '参考号',
    en: 'Reference number',
    pu: 'No. Ref. Da Anjun'
  },

  TheInboundOrderNumber: {
    zh: '入库单号',
    en: 'The inbound order number',
    pu: 'Codigo de entrada (codigo que foi escaneado na entrada de armazém)'
  },

  InboundTime: {
    zh: '入库时间',
    en: 'Inbound time',
    pu: 'data de entrada no armazém'
  },
  TheIngestorOperator: {
    zh: '入库操作人',
    en: 'The inbound operator',
    pu: 'O operador do ingestão'
  },
  OperatorCountry: {
    zh: '操作人国家',
    en: 'Operator country',
    pu: 'Operator country'
  },

  operate: {
    zh: '操作',
    en: 'operate',
    pu: 'Operar'
  },

  nextHome: {
    zh: '下家',
    en: 'next home',
    pu: 'transportador'
  },
  bagNumber: {
    zh: '袋号',
    en: 'bag number',
    pu: 'No. da saca'
  },
  add: {
    zh: '添加',
    en: 'add',
    pu: 'adicionar'
  },
  loadSave: {
    zh: '装载保存',
    en: 'load save',
    pu: 'Salvar dados'
  },
  importTheTXTFile: {
    zh: '导入TxT文件',
    en: 'import the TXT file',
    pu: 'exportar TXT'
  },
  mountHistory: {
    zh: '装载历史记录',
    en: 'mount history',
    pu: 'Historico'
  },
  printNowLargePackageNumber: {
    zh: '立即打印大包号',
    en: 'print now large package number',
    pu: 'Imprimir etiqueta Saca'
  },

  whetherToCheckTheWeight: {
    zh: '是否校验重量',
    en: 'whether to check the weight',
    pu: 'verificação de peso'
  },

  state: {
    zh: '状态',
    en: 'state',
    pu: 'status'
  },

  largePackageNumber: {
    zh: '大包号',
    en: 'large package number',
    pu: 'Saca'
  },

  TemporaryPrinting: {
    zh: '临时打印大包标签',
    en: 'TemporaryPrinting',
    pu: 'Temporary printing of large package labels'
  },

  startDate: {
    zh: '开始日期',
    en: 'Start date',
    pu: 'Data de início'
  },
  endDate: {
    zh: '结束日期',
    en: 'End date',
    pu: 'Data final'
  },
  numberOfPackets: {
    zh: '小包数',
    en: 'Number of packets',
    pu: 'Qtd de pacote'
  },
  totalNumberOfPackets: {
    zh: '总小包数',
    en: 'Number total of packets',
    pu: 'Qtd total de pacote'
  },
  operator: {
    zh: '操作人',
    en: 'operator',
    pu: 'Operador'
  },
  BagSealingOperator: {
    zh: '封袋操作人',
    en: 'Bag sealing operator',
    pu: 'Operador de selar a saca'
  },
  ListOutstandingOrders: {
    zh: '未完结总单列表',
    en: 'List of outstanding orders',
    pu: 'Tela de lista de manifesto pendente'
  },
  operationTime: {
    zh: '操作时间',
    en: 'Operation time',
    pu: 'Hora da operação'
  },
  // 渠道名称
  ChannelName: {
    zh: '渠道名称',
    en: 'ChannelName',
    pu: 'Nome do canal'
  },
  // 长宽高
  Size: {
    zh: '长*宽*高',
    en: 'length*width*height',
    pu: 'Longitude * largura * altura'
  },
  creator: {
    zh: '创建人',
    en: 'creator',
    pu: 'Criado por'
  },
  viewUnblock: {
    zh: '查看/解封',
    en: 'View/unblock',
    pu: 'Procurar/Abir'
  },
  AreYouSureItIsVoid: {
    zh: '确定作废？',
    en: 'Are you sure it is void？',
    pu: 'Tem certeza que é vazio?'
  },
  void: {
    zh: '作废',
    en: 'void',
    pu: 'invalido'
  },
  bagSealingPrinting: {
    zh: '封袋打印',
    en: 'Bag sealing printing',
    pu: 'Impressão de vedação de saco'
  },
  anew: {
    zh: '重新',
    en: 'anew',
    pu: 'de novo'
  },
  print: {
    zh: '打印',
    en: 'print',
    pu: 'imprimir'
  },
  BagSealingPrinting: {
    zh: '封袋打印',
    en: 'Bag sealing printing',
    pu: 'Impressão de selagem de sacos'
  },
  SelectTheAirport: {
    zh: '选择机场',
    en: 'Select the airport',
    pu: 'Selecione o aeroporto'
  },
  DepartureAirport: {
    zh: '起飞机场',
    en: 'Departure airport',
    pu: 'Aeroporto de Partida'
  },
  DestinationAirport: {
    zh: '目的机场',
    en: 'Destination airport',
    pu: 'Aeroporto de Destino'
  },
  ConfirmPrinting: {
    zh: '确定打印？',
    en: 'Confirm printing?',
    pu: 'Confirmar a impressão?'
  },
  determine: {
    zh: '确定',
    en: 'determine',
    pu: 'determinar'
  },
  Cancel: {
    zh: '取消',
    en: 'Cancel',
    pu: 'Cancelar'
  },
  detail: {
    zh: '详情',
    en: 'detail',
    pu: 'detalhe'
  },
  LargeBags: {
    zh: '大包',
    en: 'Large bags',
    pu: 'Sacos grandes'
  },
  waitForTheStigmaToLoad: {
    zh: '待配载',
    en: 'wait for the stigma to load',
    pu: 'Atualizar'
  },
  AllocationOnboarding: {
    zh: '配载',
    en: 'Allocation on boarding',
    pu: 'Onboarding de alocação'
  },
  loadsTheSelectedItem: {
    zh: '配载选中项',
    en: 'loads the selected item',
    pu: 'Consolidar sacas'
  },
  totalOrderNumber: {
    zh: '总单号',
    en: 'total order number',
    pu: 'No. PLP'
  },
  flight: {
    zh: '航班',
    en: 'flight',
    pu: 'Vôo'
  },
  remark: {
    zh: '备注',
    en: 'remark',
    pu: 'Observação'
  },
  empty: {
    zh: '清空',
    en: 'empty',
    pu: 'Limpar'
  },
  stowageSave: {
    zh: '配载保存',
    en: 'stowage save',
    pu: 'Guardar'
  },
  CurrentStowage: {
    zh: '当前配载',
    en: 'Current stowage',
    pu: 'Estiva atual'
  },
  totalSingleStatistics: {
    zh: '总单统计',
    en: 'total single statistics',
    pu: 'Total de pedido'
  },
  View: {
    zh: '查看',
    en: 'View',
    pu: 'consultar'
  },
  Edit: {
    zh: '编辑',
    en: 'Edit',
    pu: 'editar'
  },
  Delete: {
    zh: '删除',
    en: 'Delete',
    pu: 'Excluir'
  },
  AreYouSureDelete: {
    zh: '确定删除吗？',
    en: 'Are you sure to delete it',
    pu: 'Tem certeza de apagar?'
  },
  assemblyCompleted: {
    zh: '装配完结',
    en: 'assembly completed',
    pu: 'Montagem concluída'
  },
  AssembleTheFinishedPerson: {
    zh: '装配完结人',
    en: 'Assemble the finished person',
    pu: 'Montar a pessoa acabada'
  },
  tips: {
    zh: '提示',
    en: 'tips',
    pu: 'Dica'
  },
  AssemblyStatus: {
    zh: '装配状态',
    en: 'Assembly status',
    pu: 'Status de montagem'
  },
  largePacketNumber: {
    zh: '大包数',
    en: 'large packet number',
    pu: 'Status de montageGrande número de pacotes'
  },
  flightNumber: {
    zh: '航班号',
    en: 'flight number',
    pu: 'número de voo'
  },
  assembler: {
    zh: '装配人',
    en: 'assembler',
    pu: 'Operador'
  },
  totalOrderType: {
    zh: '总单类型',
    en: 'total order type',
    pu: 'Tipo de pedido total'
  },
  assemblyCompletionTime: {
    zh: '装配完结时间',
    en: 'assembly completion time',
    pu: 'horario de finalização'
  },
  AssemblyTime: {
    zh: '装配时间',
    en: 'Assembly time',
    pu: 'Tempo de montagem'
  },
  CreationTime: {
    zh: '创建时间',
    en: 'Creation time',
    pu: 'data de criação'
  },
  ModifyTheType: {
    zh: '修改类型',
    en: 'Modify the type',
    pu: 'Modificar'
  },
  FinishOfStowage: {
    zh: '完结配载',
    en: 'Finish of stowage',
    pu: 'Lacrar'
  },
  ThreeExemptionLists: {
    zh: '三免清单',
    en: 'three exemption lists',
    pu: 'Exportar'
  },
  DeliveryList: {
    zh: '交货清单',
    en: 'Delivery list',
    pu: 'Lista de entrega'
  },
  BaggingList: {
    zh: '装袋清单',
    en: 'Bagging list',
    pu: 'Bagging list'
  },
  TotalOrderDetails: {
    zh: '总单详情',
    en: 'total order details',
    pu: 'Três listas de isenção'
  },
  totalWeightOfTheSmallPacket: {
    zh: '小包总重',
    en: 'total weight of the small packet',
    pu: 'Peso total de pacotes'
  },
  totalNumberOfSmallPackets: {
    zh: '小包总件数',
    en: 'total number of small packets',
    pu: 'Qtd total de pacotes'
  },
  correctVoice: {
    zh: '正确语音',
    en: 'correct voice',
    pu: 'voz correta'
  },
  wrongVoice: {
    zh: '错误语音',
    en: 'wrong voice',
    pu: 'voz incorreta'
  },
  selectConsole: {
    zh: '选择控制台',
    en: 'select console',
    pu: 'Escolha a bancada de Bipagem'
  },
  synchronizeScans: {
    zh: '同步扫描',
    en: 'synchronize scans',
    pu: 'Sincronizar varreduras'
  },
  manualEntry: {
    zh: '手动录入',
    en: 'manual entry',
    pu: 'lançar info manualmente'
  },
  WhetherToTurnForecastWeightStorage: {
    zh: '是否开启预报重量入库',
    en: 'Whether to turn on forecast weight storage',
    pu: 'Se ligar a previsão de armazenamento de peso'
  },
  Open: {
    zh: '开启',
    en: 'Open',
    pu: 'Abrir'
  },
  ShutDown: {
    zh: '关闭',
    en: 'Shut down',
    pu: 'Desligar'
  },
  saveTheSettings: {
    zh: '保存设置',
    en: 'save the settings',
    pu: 'Salvar configuração'
  },
  PleaseSetIdentificationNumber: {
    zh: '请设置当前设备识别号（当前页面 -> 控制台 -> 选择 -> 保存 进行设置），或者刷新页面',
    en: 'Set the current device identification number (current page -> console -> select -> save for setup), or refresh the page',
    pu: 'Consulte o número de identificação do dispositivo atual (pagina atual -> painel de controle -> escolha -> salvar, executar o ajuste) ou atualiza o site'
  },
  TipSetOperator: {
    zh: '提示：设置操作台',
    en: 'Tip: Set up the operator station',
    pu: 'Aviso: configurar tela de operação'
  },
  select: {
    zh: '选择',
    en: 'select',
    pu: 'escolher'
  },
  toProcess: {
    zh: '去处理',
    en: 'to process',
    pu: 'para processar'
  },
  currentlyIn: {
    zh: '你当前存在',
    en: 'You currently exist',
    pu: 'Você existe atualmente'
  },
  EndStowage: {
    zh: '个总单未点击“完结配载”，请及时点击“完结配载”',
    en: 'The total order did not click "End Stowage", please click "Finish Stowage" in time',
    pu: 'A ordem total não clique em "End Stowage", clique em "Finish Stowage" a tempo'
  },
  notConnected: {
    zh: '未连接操作台',
    en: 'The operator station is not connected',
    pu: 'Detectado que não foi conectado com equipamento'
  },
  sobrepeso: {
    zh: '若物品“实际重量” - “预报重量” <=',
    en: 'If the item "Actual Weight" - "Forecast Weight" <=',
    pu: 'Se o item "Peso Real" - "Peso de Previsão" <='
  },
  intoStorage: {
    zh: '将会自动入库',
    en: 'Will be automatically put into storage',
    pu: 'Será automaticamente colocado em armazenamento'
  },
  SelectDate: {
    zh: '选择日期',
    en: 'Select a date',
    pu: 'Selecione uma data'
  },
  PleaseEnterTheContent: {
    zh: '请输入',
    en: 'Please enter the content',
    pu: 'Inserir'
  },
  Preview: {
    zh: '预览',
    en: 'Preview',
    pu: 'Visualizar'
  },
  PleaseSelect: {
    zh: '请选择',
    en: 'Please select',
    pu: 'Favor selecionar'
  },
  Only10AreDisplayed: {
    zh: '只显示10条，可搜索',
    en: 'Only 10 are displayed and can be searched',
    pu: 'Mostrar apenas 10 mensagens, pesquisáveis'
  },
  TheStowageSaveWasSuccessful: {
    zh: '配载保存成功',
    en: 'The stowage save was successful',
    pu: 'A poupança foi bem sucedida.'
  },
  DeleteSucceeded: {
    zh: '删除成功',
    en: 'Delete succeeded',
    pu: 'Excluir foi bem sucedido'
  },
  noUpload: {
    zh: '暂无上传失败内容下载',
    en: 'There are no upload failures to download content',
    pu: 'Não há falhas de upload para baixar conteúdo'
  },
  fail: {
    zh: '失败',
    en: 'fail',
    pu: 'falhar'
  },
  toBePushed: {
    zh: '待推送',
    en: 'To be pushed',
    pu: 'Para ser empurrado'
  },
  ClickRefreshList: {
    zh: '点击刷新列表',
    en: 'Click Refresh list',
    pu: 'Clique na lista de atualização'
  },
  PleaseUploadTXT: {
    zh: '请上传 .txt 文件',
    en: 'Please upload .txt file',
    pu: 'Por favor, carregue .txt arquivo'
  },
  maximumNumber: {
    zh: '上传数据最大行数不能超过',
    en: 'The maximum number of rows of uploaded data cannot be exceeded',
    pu: 'O número máximo de linhas de dados carregados não pode ser excedido'
  },
  rows: {
    zh: '行',
    en: 'rows',
    pu: 'Linhas'
  },
  FileReadFailed: {
    zh: '文件读取失败，请稍后再试',
    en: 'File read failed, please try again later',
    pu: 'A leitura do arquivo falhou, por favor tente novamente mais tarde'
  },
  SelectManualEntry: {
    zh: '请在控制台选项选择《手工录入》后，才能进行该操作',
    en: 'Select Manual Entry in the console options before you can do this',
    pu: 'Selecione Entrada Manual nas opções do console antes de fazer isso'
  },
  TotalQuantity: {
    zh: '总数量',
    en: 'Total quantity',
    pu: 'Quantidade total'
  },
  NumberOfSuccesses: {
    zh: '成功数量',
    en: 'Number of successes',
    pu: 'Número de sucessos'
  },
  NumberOfFailures: {
    zh: '失败数量',
    en: 'Number of failures',
    pu: 'Número de falhas'
  },
  Download: {
    zh: '下载',
    en: 'Download',
    pu: 'Baixar'
  },
  UploadFailed: {
    zh: '上传失败Excel下载',
    en: 'Upload failed Excel download',
    pu: 'Upload de download do Excel com falha'
  },
  to: {
    zh: '至',
    en: 'to',
    pu: 'Para'
  },
  Multiple100: {
    zh: '多个换行输入，最多100个',
    en: 'Multiple line break inputs, up to 100',
    pu: 'Entradas de quebra de linha múltipla, até 100'
  },
  Multiple50: {
    zh: '多个换行输入，最多50个',
    en: 'Multiple line break inputs, up to 50',
    pu: 'Entradas de quebra de linha múltipla, até 50'
  },
  Multiple200: {
    zh: '多个换行输入，最多200个',
    en: 'Multiple line break inputs, up to 200',
    pu: 'Entradas de quebra de linha múltipla, até 200'
  },
  queried200pieces: {
    zh: '每次最多查询200条数据',
    en: 'Up to 200 pieces of data can be queried at a time',
    pu: 'Até 200 dados podem ser consultados de cada vez'
  },
  log: {
    zh: '日志',
    en: 'log',
    pu: 'Registro'
  },
  WeighPhotos: {
    zh: '称重照片',
    en: 'Weigh photos',
    pu: 'Foto de pesagem'
  },
  OperationalLogs: {
    zh: '操作日志',
    en: 'Operational logs',
    pu: 'Diário de operações'
  },
  Number1: {
    zh: '号',
    en: 'Number',
    pu: 'num'
  },
  preservation: {
    zh: '确定进行封袋保存？',
    en: 'Are you sure you want to seal the bag for preservation?',
    pu: 'Tem certeza que quer selar o saco para preservação?'
  },
  SealPreservation: {
    zh: '封袋保存',
    en: 'Seal the bag for preservation',
    pu: 'Selar o saco para preservação'
  },
  BagNumberRecord: {
    zh: '袋号记录',
    en: 'Bag number record',
    pu: 'Registro de número de saco'
  },
  DeleteSuccessful: {
    zh: '删除成功',
    en: 'Delete successful',
    pu: 'Excluir bem-sucedido'
  },
  succeed: {
    zh: '成功',
    en: 'succeed',
    pu: 'suceder'
  },
  packagingOperation: {
    zh: '请先进行打包操作',
    en: 'Please do the packaging operation first',
    pu: 'Por favor, faça a operação de embalagem primeiro'
  },
  dataMalformed: {
    zh: '上传数据格式错误',
    en: 'The upload data is malformed',
    pu: 'Os dados de upload estão malformados'
  },
  haveBeenStowed: {
    zh: '已经配载的大包',
    en: 'Large bags that have been stowed',
    pu: 'Grandes sacos que foram guardados'
  },
  AddStatistics: {
    zh: '添加统计',
    en: 'Add statistics',
    pu: 'Adicionar estatísticas'
  },
  AllBigBags: {
    zh: '所有大包',
    en: 'All big bags',
    pu: 'Todos os sacos grandes'
  },
  ViewThePackets: {
    zh: '查看小包',
    en: 'View the packets',
    pu: 'Veja os pacotes'
  },
  packet: {
    zh: '小包',
    en: 'packet',
    pu: 'pacote'
  },
  CustomerChannels: {
    zh: '客户渠道',
    en: 'Customer channels',
    pu: 'Canal'
  },
  AddLargePackages: {
    zh: '添加大包',
    en: 'Add large packages',
    pu: 'Adicionar pacotes grandes'
  },
  ModifyOrderType: {
    zh: '修改总单类型',
    en: 'Modify the total order type',
    pu: 'Modifique o tipo total de pedido'
  },
  modify: {
    zh: '修改',
    en: 'modify',
    pu: 'modificar'
  },
  modifiedSuccessfully: {
    zh: '总单类型修改成功',
    en: 'The total order type was modified successfully',
    pu: 'O tipo total de pedidos foi modificado com sucesso'
  },
  AreYouSureYouWantToSave: {
    zh: '确定保存吗？',
    en: 'Are you sure you want to save?',
    pu: 'Tem certeza que quer salvar?'
  },
  withStowage: {
    zh: '确定完结配载吗？',
    en: 'Are you sure you are finished with stowage?',
    pu: 'Tem certeza que terminou de estocagem?'
  },
  SetUp: {
    zh: '设置',
    en: 'Set up',
    pu: 'Preparar'
  },
  startTimeLessEndTime: {
    zh: '起始时间必须小于结束时间',
    en: 'The start time must be less than the end time',
    pu: 'O tempo de início deve ser menor do que o tempo final'
  },
  checkBagLabel: {
    zh: '是否查看袋标?',
    en: 'Do you want to check the bag label?',
    pu: 'Quer checar o rótulo da bolsa?'
  },
  GridSlogan: {
    zh: '格口号',
    en: 'Grid slogan',
    pu: 'Slogan de grade'
  },
  orderNumber: {
    zh: '订单号',
    en: 'The order number',
    pu: 'Número do pedido'
  },
  length: {
    zh: '长度',
    en: 'length',
    pu: 'comprimento'
  },
  width: {
    zh: '宽度',
    en: 'width',
    pu: 'Largura'
  },
  height: {
    zh: '高度',
    en: 'height',
    pu: 'altura'
  },
  numberPieces: {
    zh: '件数',
    en: 'number Pieces',
    pu: 'números Peças'
  },
  volume: {
    zh: '体积',
    en: 'volume',
    pu: 'volume'
  },
  logotype: {
    zh: '标识',
    en: 'logotype',
    pu: 'logotipo'
  },
  lengthWidthAndHeight: {
    zh: '确定不传入完整体积(长宽高)参数吗?',
    en: 'Are you sure not to pass in the full volume (length, width and height) parameters?',
    pu: 'Tem certeza de que não deve passar nos parâmetros de volume completo (comprimento, largura e altura) ?'
  },
  lengthWidthAndHeightAll: {
    zh: '长、宽、高必须全都填写或者全都不填',
    en: 'Length, width, and height must be filled in all or none at all',
    pu: 'Comprimento, largura e altura devem ser preenchidos em todos ou nenhum'
  },
  Unblock: {
    zh: '解封',
    en: 'unblock',
    pu: 'desbloqueio'
  },
  PleaseAddSmallPacket: {
    zh: '请添加小包',
    en: 'Please add a small packet',
    pu: 'Por favor, adicione um pequeno pacote'
  },
  currentlyNoLargePackage: {
    zh: '当前无大包号',
    en: 'There are currently no large package numbers',
    pu: 'Atualmente, não há grandes números de pacotes'
  },
  SealingBags: {
    zh: '封袋',
    en: 'Sealing bags',
    pu: 'Sacos de vedação'
  },
  searches100: {
    zh: '最大搜索100条大包号',
    en: 'The maximum number of searches is 100 large packets',
    pu: 'O número máximo de pesquisas é de 100 pacotes grandes'
  },
  current: {
    zh: '当前',
    en: 'current',
    pu: 'atual'
  },
  PrintError: {
    zh: '打印错误',
    en: 'Print error',
    pu: 'Erro de impressão'
  },
  ReturnDataError: {
    zh: '返回数据错误，联系后台人员',
    en: 'Return a data error, contact the background person',
    pu: 'Devolva um erro de dados, entre em contato com a pessoa de fundo'
  },
  operationFailed: {
    zh: '操作失败，请稍后再试',
    en: 'The operation failed, please try again later',
    pu: 'A operação falhou, por favor, tente novamente mais tarde.'
  },
  ProcessingSucceeded: {
    zh: '处理成功',
    en: 'Processing succeeded',
    pu: 'O processamento foi bem sucedido'
  },
  processingFailure: {
    zh: '处理失败',
    en: 'processing failure',
    pu: 'falha no processamento'
  },
  BillLadingNumber: {
    zh: '提单号',
    en: 'Bill of lading number',
    pu: 'Bill de número de embarque'
  },
  AirBillLadingNumber: {
    zh: '航空提单号',
    en: 'Air bill of lading number',
    pu: 'AWB'
  },
  LargeBagWeight: {
    zh: '大包重量',
    en: 'Large bag weight',
    pu: 'Peso grande da bolsa'
  },
  ModifiedBy: {
    zh: '修改人',
    en: 'Modified by',
    pu: 'Modificado por'
  },
  ModificationTime: {
    zh: '修改时间',
    en: 'Modification time',
    pu: 'Tempo de modificação'
  },
  GenerateForecasts: {
    zh: '生成预报',
    en: 'Generate forecasts',
    pu: 'Gerar previsões'
  },
  LargePackageFormat: {
    zh: '大包号格式',
    en: 'Large package number format',
    pu: 'Formato de número de pacote grande'
  },
  verify: {
    zh: '校验',
    en: 'verify',
    pu: 'verificar'
  },
  NotValidated: {
    zh: '不校验',
    en: 'Not validated',
    pu: 'Não validado'
  },
  submit: {
    zh: '提交',
    en: 'submit',
    pu: 'Enviar'
  },
  update: {
    zh: '更新',
    en: 'update',
    pu: 'atualização'
  },
  ErrorLargePacket: {
    zh: '错误大包号',
    en: 'Error large packet number',
    pu: 'Erro grande número de pacote'
  },
  TotalNumberLargePackages: {
    zh: '大包总数（件）',
    en: 'Total number of large bags (pieces)',
    pu: 'Número total de sacos grandes (pedaços)'
  },
  TotalNumberPackages: {
    zh: '小包总数（件）',
    en: 'Total number of packets (pieces)',
    pu: 'Número total de pacotes (peças)'
  },
  Assembled: {
    zh: '已装配',
    en: 'Assembled',
    pu: 'consolidado'
  },
  NotAssembled: {
    zh: '未装配',
    en: 'Not assembled',
    pu: 'sem consolidar'
  },
  Packaged: {
    zh: '已打包',
    en: 'Packaged',
    pu: 'Embalado'
  },
  NotPackaged: {
    zh: '未打包',
    en: 'Packaged',
    pu: 'Saca aberto'
  },
  SinglePackaging: {
    zh: '单个打包',
    en: 'Single packaging',
    pu: 'Embalagem única'
  },
  BulkPackaging: {
    zh: '批量打包',
    en: 'Bulk packaging',
    pu: 'Embalagem a granel'
  },
  needNext: {
    zh: '需要选择下家',
    en: 'You need to choose your next home',
    pu: 'Você precisa escolher sua próxima casa'
  },
  GetNextHome: {
    zh: '获取下家',
    en: 'Get the next home',
    pu: 'Pegue a próxima casa.'
  },
  BillLading: {
    zh: '已提单',
    en: 'Bill of Lading',
    pu: 'Com AWB'
  },
  isSealed: {
    zh: '已封袋',
    en: 'The bag is sealed',
    pu: 'Lacrado'
  },
  Stowed: {
    zh: '已配载',
    en: 'Stowed',
    pu: 'Saca fechado'
  },
  StowageOver: {
    zh: '配载完结',
    en: 'Stowage is over',
    pu: 'A estiva acabou'
  },
  PureElectricity: {
    zh: '纯电',
    en: 'Pure electricity',
    pu: 'c/ bateria'
  },
  GeneralCargo: {
    zh: '普货',
    en: 'General cargo',
    pu: 'Normal'
  },
  InnerSingleNumber: {
    zh: '内单号内单号',
    en: 'Inner single number',
    pu: 'Número único interno'
  },
  bag: {
    zh: '袋',
    en: 'bag',
    pu: 'saco'
  },
  pieces: {
    zh: '件',
    en: 'pieces',
    pu: 'Peças'
  },
  Loading: {
    zh: '正在努力加载中',
    en: 'Loading',
    pu: 'Carregamento'
  },
  dedicatedLines: {
    zh: '专线',
    en: 'dedicated lines',
    pu: 'Linha'
  },
  standard: {
    zh: '标准',
    en: 'standard',
    pu: 'padrão'
  },
  noDataExported: {
    zh: '列表暂无数据，无法导出',
    en: 'The list has no data and cannot be exported',
    pu: 'A lista não tem dados e não pode ser exportada'
  },
  InboundOrderExport: {
    zh: '入库订单导出',
    en: 'Inbound order export',
    pu: 'Exportação de pedidos de entrada'
  },
  emptyInboundData: {
    zh: '确定清空入库数据？',
    en: 'Are you sure you want to empty inbound data?',
    pu: 'Tem certeza que quer esvaziar os dados de entrada?'
  },
  ClearDataSuccessfully: {
    zh: '清空数据成功',
    en: 'Clear data successfully',
    pu: 'Limpar dados com sucesso'
  },
  connectionError: {
    zh: '连接出错',
    en: 'There was a connection error',
    pu: 'Houve um erro de conexão'
  },
  CancelsTheTaking: {
    zh: '取消入库',
    en: 'Cancels the taking',
    pu: 'Cancelar armazenagem'
  },
  DeterminesTheShipping: {
    zh: '确定入库',
    en: 'Determines the shipping',
    pu: 'Determina o envio'
  },
  UnknownError: {
    zh: '未知错误，请联系管理员',
    en: 'Unknown error, contact your administrator',
    pu: 'Erro desconhecido, entre em contato com seu administrador'
  },
  serverSuccessful: {
    zh: '服务器连接成功',
    en: 'The server connection was successful',
    pu: 'A conexão do servidor foi bem sucedida'
  },
  ReloadSuccessfully: {
    zh: '复重入库成功',
    en: 'Reload successfully',
    pu: 'Recarga com sucesso'
  },
  IngestionSucceeded: {
    zh: '入库成功',
    en: 'Ingestion succeeded',
    pu: 'Entrada sucedido'
  },
  ExceededForecastWeight: {
    zh: '超出预报重量',
    en: 'Exceeded forecast weight',
    pu: 'Peso acima do limite previsto'
  },
  PleaseSelectProcess: {
    zh: '请选择处理',
    en: 'Please select Process',
    pu: 'Selecione Processo'
  },
  inbound: {
    zh: '入库',
    en: 'inbound',
    pu: 'inserir'
  },
  NotInbound: {
    zh: '不入库',
    en: 'Not inbound',
    pu: 'não inserir'
  },
  overweightUnprocessed: {
    zh: '存在一条超重未处理订单',
    en: 'There is an overweight unprocessed order',
    pu: 'Há uma ordem não processada com excesso de peso'
  },
  IsItInbound: {
    zh: '是否入库？',
    en: 'Is it inbound?',
    pu: 'Está chegando?'
  },
  enterGreaterThan0: {
    zh: '请输入大于0的数字',
    en: 'Please enter a number greater than 0',
    pu: 'Por favor, digite um número maior que 0'
  },
  enterLessThan: {
    zh: '请输入小于',
    en: 'Please enter less than',
    pu: 'Por favor, digite menos do que'
  },
  AnInteger: {
    zh: '的整数',
    en: 'An integer',
    pu: 'Um inteiro'
  },
  selectTreatmentMethod: {
    zh: '请选择处理方式',
    en: 'Please select the treatment method',
    pu: 'Selecione o método de tratamento'
  },
  uploadxlsx: {
    zh: '请上传后缀 .xlsx 的文件',
    en: 'Please upload a file with the suffix .xlsx',
    pu: 'Por favor, carregue um arquivo com o sufixo .xlsx'
  },
  uploadSmallerThan1MB: {
    zh: '请上传小于1MB文件',
    en: 'Please upload a file smaller than 1MB',
    pu: 'Por favor, carregue um arquivo menor que 1MB'
  },
  uploadSmallerThan2MB: {
    zh: '请上传小于2MB文件',
    en: 'Please upload a file smaller than 2MB',
    pu: 'Por favor, carregue um arquivo menor que 2MB'
  },
  UploadExcelEmpty: {
    zh: '上传excel数据不能为空',
    en: 'Upload excel data cannot be empty',
    pu: 'Os dados do Excel de upload não podem estar vazios'
  },
  uploadedExcelRows: {
    zh: '上传excel数据最大行数不能超过',
    en: 'The maximum number of rows of uploaded excel data cannot be exceeded',
    pu: 'O número máximo de linhas de dados de excel carregados não pode ser excedido'
  },
  OrderExport: {
    zh: '订单导出',
    en: 'Order export',
    pu: 'Exportação de pedidos'
  },
  voidWasSuccessful: {
    zh: '作废处理成功',
    en: 'The void treatment was successful',
    pu: 'O tratamento vazio foi bem sucedido'
  },
  CorrectTone: {
    zh: '正确提示音',
    en: 'Correct tone',
    pu: 'Tom correto'
  },
  ErrorTone: {
    zh: '错误提示音',
    en: 'Error tone',
    pu: 'Tom de erro'
  },
  BatchImportSuccessful: {
    zh: '批量导入成功',
    en: 'Batch import successful',
    pu: 'Importação de lote bem sucedida'
  },
  InboundOperator: {
    zh: '入库操作员',
    en: 'Inbound operator',
    pu: 'Operador de entrada'
  },

  AssemblerCountry: {
    zh: '装配人国家',
    en: 'Assembler country',
    pu: 'País agrupador'
  },

  China: {
    zh: '中国',
    en: 'China',
    pu: 'China'
  },
  Brazil: {
    zh: '巴西',
    en: 'Brazil',
    pu: 'Brasil'
  },
  SetTheMaterialAccumulationWeight: {
    zh: '设置材积重',
    en: 'Set the material accumulation weight',
    pu: 'Definir o peso de acumulação do material'
  },
  MaterialAccumulationWeight: {
    zh: '材积重',
    en: 'Material accumulation weight',
    pu: 'Peso de acumulação de material'
  },
  WeighAndWeigh: {
    zh: '称重重量',
    en: 'Weigh and weigh',
    pu: 'Pesar e pesar'
  },
  WeightDifference: {
    zh: '重量差',
    en: 'Weight difference',
    pu: 'Diferença de peso'
  },
  ImportSettings: {
    zh: '导入设置',
    en: 'Import settings',
    pu: 'Configurações de importação'
  },
  illustrate: {
    zh: '说明',
    en: 'illustrate',
    pu: 'ilustrar'
  },
  PleaseTickTheDataExport: {
    zh: '请勾选要导出的数据',
    en: 'Please tick the data you want to export',
    pu: 'Por favor, marque os dados que deseja exportar'
  },
  TheOperationSucceeded: {
    zh: '操作成功',
    en: 'The operation succeeded',
    pu: 'A operação foi bem sucedida.'
  },
  DownloadTheTemplate: {
    zh: '下载模板',
    en: 'Download the template',
    pu: 'Download do modelo'
  },
  Import: {
    zh: '导入',
    en: 'Import',
    pu: 'Importação'
  },
  ThrowRatio: {
    zh: '抛比',
    en: 'Throw ratio',
    pu: 'Proporção de arremesso'
  },
  CannotBeEmpty: {
    zh: '不能为空',
    en: 'Cannot be empty',
    pu: 'Não pode estar vazio'
  },
  TheFormatIsIncorrect: {
    zh: '格式不正确',
    en: 'The format is incorrect',
    pu: 'O formato está incorreto'
  },
  PleaseEnterLogisticsNumber: {
    zh: '请输入物流单号',
    en: 'Please enter the logistics slip number',
    pu: 'Por favor, digite o número do deslizamento logístico'
  },
  ThereDuplicateLogisticsNumbers: {
    zh: '存在重复物流单号，已过滤处理',
    en: 'There are duplicate logistics order numbers, filtered',
    pu: 'Há números de pedidos logísticos duplicados, filtrados'
  },
  SetupSucceeded: {
    zh: '设置成功',
    en: 'Setup succeeded',
    pu: 'Configuração bem sucedida'
  },
  UpTo50Pieces: {
    zh: '一次最多导入50条数据，多出的，未填写完全或填写格式不正确的会自动忽略，是否确定导入？',
    en: 'Up to 50 pieces of data can be imported at a time, and the extra, incomplete or incorrectly filled in will be automatically ignored, is the import determined?',
    pu: 'Até 50 pedaços de dados podem ser importados de cada vez, e o extra, incompleto ou incorretamente preenchido será automaticamente ignorado, a importação é determinada?'
  },
  TheImportWasSuccessful: {
    zh: '导入成功',
    en: 'The import was successful',
    pu: 'A importação foi bem sucedida'
  },
  TheOrderWasNotQueried: {
    zh: '未查询到订单',
    en: 'The order was not queried',
    pu: 'A ordem não foi consultada'
  },
  EditorialDescription: {
    zh: '编辑说明',
    en: 'Editorial description',
    pu: 'Descrição editorial'
  },
  UpTo50PiecesDataImported: {
    zh: '一次最多导入50条数据，多出的或重复的已自动忽略',
    en: 'Up to 50 pieces of data can be imported at a time, and excess or duplicate ones are automatically ignored',
    pu: 'Até 50 pedaços de dados podem ser importados por vez, e os excedentes ou duplicados são automaticamente ignorados'
  },
  PleaseEnterTheMaximumErrorValue: {
    zh: '请输入误差最大值',
    en: 'Please enter the maximum error value',
    pu: 'Insira o valor máximo de erro'
  },
  // 电子秤插件提示
  ...ElectronicScalePlugin
};

// 提示
const operationTips = {
  connectionError: {
    zh: '网络连接错误，请联系管理员',
    en: 'Network connection error, please contact the administrator',
    pu: 'Network connection error, please contact the administrator'
  },
  belongState: {
    zh: '所属州',
    en: '',
    pu: 'Estado ao que pertence'
  },
  siteLost: {
    zh: '网点遗失',
    en: '',
    pu: 'Perdas dos pontos'
  },
  fenboLost: {
    zh: '分拨遗失',
    en: '',
    pu: 'Perdas de distribuição'
  },
  fenboNome: {
    zh: '分拨名称',
    en: '',
    pu: 'Nome de distribuição'
  },
  arrivedNumber: {
    zh: '到件量',
    en: '',
    pu: 'Qtd，de chegada dos pacotes'
  },
  yisiLost: {
    zh: '疑似丢失',
    en: '',
    pu: 'Possível perda de pacote'
  },
  Lost: {
    zh: '丢失',
    en: '',
    pu: 'Perda'
  },
  singedNumber: {
    zh: '已签收票数',
    en: '',
    pu: 'Qtd，de pacotes entregues'
  },
  lostType: {
    zh: '丢件类型',
    en: '',
    pu: 'Tipo de perdas'
  }
};

export {
  operationCenterRoute,
  operationCenter,
  operationTips
};
