var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Box LoadBox" },
    [
      _c(
        "div",
        { staticClass: "scanClass" },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("collectionCenter.scanBtn")))]),
          _c("el-input", {
            staticClass: "iconBtn",
            attrs: {
              placeholder: _vm.$t("collectionCenter.pleaseEnterOrScan"),
              "prefix-icon": "el-icon-full-screen",
              clearable: "",
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.pushTable($event)
              },
            },
            model: {
              value: _vm.signBagNumber,
              callback: function ($$v) {
                _vm.signBagNumber = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "signBagNumber",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "recordClass" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("collectionCenter.scannedNumber")))]),
        _c("span", { staticClass: "red" }, [
          _vm._v(_vm._s(_vm.scanPage.length)),
        ]),
      ]),
      _c(
        "div",
        { staticStyle: { "text-align": "center", "margin-bottom": "20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "success", disabled: !_vm.scanPage.length },
              on: { click: _vm.openBagDialog },
            },
            [_vm._v(_vm._s(_vm.$t("collectionCenter.SealingBags")))]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tableClass" },
        [
          _c(
            "el-table",
            { attrs: { "max-height": 600, data: _vm.scanPage, border: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "scanNumber",
                  align: "center",
                  label: _vm.$t("collectionCenter.Number"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "scanTimeStr",
                  align: "center",
                  label: _vm.$t("collectionCenter.scanTime"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.operation"),
                  fixed: "right",
                  width: "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popconfirm",
                          {
                            attrs: {
                              title: _vm.$t("collectionCenter.deleteSure"),
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.scanDelClick(scope.row, scope.$index)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  slot: "reference",
                                  type: "danger",
                                  size: "mini",
                                },
                                slot: "reference",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("collectionCenter.Delete"))
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("collectionCenter.SealingBags"),
            visible: _vm.dialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "65px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.handleSubmit($event)
                },
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("operationCenter.largePackageNumber"),
                    prop: "bagNumber",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "bagBox" },
                    [
                      _c("el-input", {
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return (function () {})($event)
                          },
                        },
                        model: {
                          value: _vm.ruleForm.bagNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "bagNumber", $$v)
                          },
                          expression: "ruleForm.bagNumber",
                        },
                      }),
                      _c(
                        "el-checkbox",
                        {
                          staticStyle: { "margin-left": "10px" },
                          on: { change: _vm.choose },
                          model: {
                            value: _vm.checked,
                            callback: function ($$v) {
                              _vm.checked = $$v
                            },
                            expression: "checked",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("receivablePayable.SystemGenerated"))
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sealedBag } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
        },
        [
          _c("audio", {
            ref: "errRef",
            attrs: { src: _vm.errorMp3, controls: "controls" },
          }),
          _c("audio", {
            ref: "okRef",
            attrs: { src: _vm.okMp3, controls: "controls" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }