<template>
  <div>
    <div style="font-size: 20px;">
      <div>
        <el-image :src="logo1" style="height: 50px;" />
      </div>
      <div>
        <p style="margin: 15px 0 5px 0;">Rota：{{ paramData[0].routeNumber ? paramData[0].routeNumber : '--' }}</p>
        <p style="margin: 15px 0 5px 0;">Data de impressão：{{
          paramData[0].createTime ? paramData[0].createTime : '--'
        }}</p>
      </div>
      <div>
        <table border="1" style="width: 100%;border-collapse:collapse">
          <tr>
            <td colspan="2" style="color: #000;padding: 10px;height: 60px;">
              Origem：ANJUN EXPRESS LOGISTICA E TRANSPORTES LTDA
            </td>
          </tr>
          <tr>
            <td colspan="2" style="color: #000;padding: 10px;height: 60px;">
              Endereço completo：Avenida Raimundo Pereira de Magalhães, 16800,Estr. da Boiada,17481-ANJUN-EXPRESS
            </td>
          </tr>
          <tr>
            <td style="color: #000;padding: 10px;height: 60px;">
              Bairro：Perus
            </td>
            <td style="color: #000;padding: 10px;height: 60px;">
              Cidade：São Paulo
            </td>
          </tr>
          <tr>
            <td style="color: #000;padding: 10px;height: 60px;">
              UF：SP
            </td>
            <td style="color: #000;padding: 10px;height: 60px;">
              CEP：05220-000
            </td>
          </tr>
        </table>
        <div style="margin-top: 20px;">
          <table border="1" style="color: #000;width: 100%;border-collapse:collapse;">
            <tr>
              <th style="padding: 10px;width: 80px;height: 60px;text-align: left;" />
              <th style="padding: 10px;width: 200px;height: 60px;text-align: left;">quantidade de sacas</th>
              <th style="padding: 10px;text-align: left;height: 60px;">Destinatário</th>
              <th style="padding: 10px;width: 160px;height: 60px;text-align: left;">CEP</th>
              <th style="padding: 10px;width: 200px;height: 60px;text-align: left;">Cidade</th>
              <th style="padding: 10px;width: 100px;height: 60px;text-align: left;">UF</th>
            </tr>
            <tr v-for="(item, index) in paramData" :key="index">
              <td style="padding: 10px;width: 80px;height: 60px;" />
              <td style="padding: 10px;width: 100px;height: 60px;">
                {{ item.bagNumber ? item.bagNumber : '--' }}
              </td>
              <td style="padding: 10px;height: 60px;">
                <span v-if="item.unloadingPoint">{{
                  item.unloadingPoint.address ? item.unloadingPoint.address : '--'
                }}</span>
                <span v-else>{{ item.site.address ? item.site.address : '--' }}</span>
              </td>
              <td style="padding: 10px;width: 160px;height: 60px;">
                {{ item.zipCode }}
              </td>
              <td style="padding: 10px;width: 200px;height: 60px;">
                <span>{{ item.city ? item.city : '--' }}</span>
              </td>
              <td style="padding: 10px;width: 100px;height: 60px;">
                <span>{{ item.state ? item.state : '--' }}</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import printXq from 'print-xq';
const logoSrc1 = require('@/assets/ajlogo1.svg');
export default {
  props: {
    paramData: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      logo1: logoSrc1
    };
  },
  created() {
    // this.$nextTick(() => {
    //   setTimeout(() => {
    //     printXq({
    //       printable: 'logisticsNumber', // // 文档来源：pdf或图像的url，html元素的id或json数据的对象
    //       type: 'html', // 可打印的类型。可用的打印选项包括：pdf，html，图像，json和raw-html。
    //       targetStyles: ['*'] // 库在打印HTML元素时处理任何样式
    //     });
    //     this.$emit('clearShippingList');
    //   }, 500);
    // });
  }
};
</script>
