var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "app-container",
        staticStyle: { height: "calc(100vh - 130px)", "overflow-y": "auto" },
      },
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.queryForm,
              "label-width": "160px",
              "label-position": _vm.$i18n.locale !== "zh" ? "top" : "right",
            },
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c("el-col", { attrs: { span: 24 } }, [
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("basicData.businessPrincipal"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "spareNumberPoo.Only10AreDisplayed"
                                ),
                                "remote-method": _vm.getProviderPage,
                                clearable: "",
                                filterable: "",
                                remote: "",
                                "reserve-keyword": "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.providerInfoChange()
                                },
                                focus: function ($event) {
                                  return _vm.getProviderPage()
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.providerId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "providerId",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.providerId",
                              },
                            },
                            _vm._l(_vm.providerList, function (item, ind) {
                              return _c("el-option", {
                                key: ind,
                                attrs: {
                                  label:
                                    item.providerName + "（" + item.code + "）",
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("el-col", { attrs: { span: 24 } }, [
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("basicData.Outlets") } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                loading: _vm.isSiteId,
                                placeholder: _vm.$t(
                                  "spareNumberPoo.Only10AreDisplayed"
                                ),
                                "remote-method": _vm.getSitePageList,
                                clearable: "",
                                filterable: "",
                                remote: "",
                                "reserve-keyword": "",
                              },
                              on: {
                                change: _vm.changeOutlets,
                                focus: function ($event) {
                                  return _vm.getSitePageList()
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.siteId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm.params, "siteId", $$v)
                                },
                                expression: "queryForm.params.siteId",
                              },
                            },
                            _vm._l(_vm.outletsList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("el-col", { attrs: { span: 24 } }, [
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("receivablePayable.customer"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "spareNumberPoo.Only10AreDisplayed"
                                ),
                                "remote-method": _vm.getCustomerPage,
                                clearable: "",
                                filterable: "",
                                remote: "",
                                "reserve-keyword": "",
                              },
                              on: {
                                change: _vm.changeCustomer,
                                focus: function ($event) {
                                  return _vm.getCustomerPage()
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.customerId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "customerId",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.customerId",
                              },
                            },
                            _vm._l(_vm.customerList, function (item, ind) {
                              return _c("el-option", {
                                key: ind,
                                attrs: {
                                  label:
                                    item.customerAlias +
                                    "(" +
                                    item.username +
                                    ")",
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("collectionCenter.CommercialOwner"),
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            clearable: "",
                          },
                          model: {
                            value: _vm.queryForm.params.merchantName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "merchantName",
                                $$v
                              )
                            },
                            expression: "queryForm.params.merchantName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("el-col", { attrs: { span: 24 } }, [
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "template",
                            { slot: "label" },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "operationCenter.PleaseSelect"
                                    ),
                                  },
                                  model: {
                                    value: _vm.queryForm.params.timeField,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm.params,
                                        "timeField",
                                        $$v
                                      )
                                    },
                                    expression: "queryForm.params.timeField",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("basicData.BusinessTime"),
                                      value: "operate_time",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("newOrder.clientExitTime"),
                                      value: "customer_send_time",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "default-time": ["00:00:00", "23:59:59"],
                              "end-placeholder": _vm.$t(
                                "operationCenter.endDate"
                              ),
                              "picker-options": _vm.dateLimit,
                              "range-separator": _vm.$t("operationCenter.to"),
                              "start-placeholder": _vm.$t(
                                "operationCenter.startDate"
                              ),
                              type: "datetimerange",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                            },
                            model: {
                              value: _vm.queryDate,
                              callback: function ($$v) {
                                _vm.queryDate = $$v
                              },
                              expression: "queryDate",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
                _c("el-col", { attrs: { span: 24 } }, [
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "template",
                            { slot: "label" },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "operationCenter.PleaseSelect"
                                    ),
                                  },
                                  model: {
                                    value: _vm.queryForm.params.numberField,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm.params,
                                        "numberField",
                                        $$v
                                      )
                                    },
                                    expression: "queryForm.params.numberField",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: "1",
                                    attrs: {
                                      label: _vm.$t(
                                        "receivablePayable.TicketNumber"
                                      ),
                                      value: "waybill_number",
                                    },
                                  }),
                                  _c("el-option", {
                                    key: "2",
                                    attrs: {
                                      label: _vm.$t(
                                        "receivablePayable.CustomerTrackingNumber"
                                      ),
                                      value: "customer_number",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.numberListTips,
                              rows: 5,
                              type: "textarea",
                            },
                            model: {
                              value: _vm.numberList,
                              callback: function ($$v) {
                                _vm.numberList = $$v
                              },
                              expression: "numberList",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
                _c("el-col", { attrs: { span: 24 } }, [
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "receivablePayable.WhetherItHasBeenPaid"
                            ),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "operationCenter.PleaseSelect"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.queryForm.params.isAccept,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "isAccept",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.isAccept",
                              },
                            },
                            [
                              _c("el-option", {
                                key: "0",
                                attrs: {
                                  label: _vm.$t("basicData.all"),
                                  value: null,
                                },
                              }),
                              _c("el-option", {
                                key: "1",
                                attrs: {
                                  label: _vm.$t("receivablePayable.Paid"),
                                  value: true,
                                },
                              }),
                              _c("el-option", {
                                key: "2",
                                attrs: {
                                  label: _vm.$t("receivablePayable.NonPayment"),
                                  value: false,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("el-col", { attrs: { span: 24 } }, [
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("ClaimsManagement.PaymentMethod"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "operationCenter.PleaseSelect"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.queryForm.params.payType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm.params, "payType", $$v)
                                },
                                expression: "queryForm.params.payType",
                              },
                            },
                            [
                              _c("el-option", {
                                key: "0",
                                attrs: {
                                  label: _vm.$t("basicData.all"),
                                  value: null,
                                },
                              }),
                              _c("el-option", {
                                key: "1",
                                attrs: { value: 0, label: "OnLine" },
                              }),
                              _c("el-option", {
                                key: "2",
                                attrs: { value: 1, label: "OffLine" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("el-col", { attrs: { span: 24 } }, [
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "receivablePayable.WhetherToGenerateAStatement"
                            ),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "operationCenter.PleaseSelect"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.queryForm.params.isGenBill,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "isGenBill",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.isGenBill",
                              },
                            },
                            [
                              _c("el-option", {
                                key: "0",
                                attrs: {
                                  label: _vm.$t("basicData.all"),
                                  value: null,
                                },
                              }),
                              _c("el-option", {
                                key: "1",
                                attrs: {
                                  label: _vm.$t("receivablePayable.Generated"),
                                  value: true,
                                },
                              }),
                              _c("el-option", {
                                key: "2",
                                attrs: {
                                  label: _vm.$t(
                                    "receivablePayable.NotGenerated"
                                  ),
                                  value: false,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("el-col", { attrs: { span: 24 } }, [
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.businessT"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "operationCenter.PleaseSelect"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.changeBusinessType(
                                    _vm.queryForm.params.businessType
                                  )
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.businessType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "businessType",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.businessType",
                              },
                            },
                            [
                              _c("el-option", {
                                key: "0",
                                attrs: {
                                  label: _vm.$t("basicData.all"),
                                  value: null,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.BUSINESS_TYPE_ARR[1].label,
                                  value: _vm.BUSINESS_TYPE_ARR[1].value,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.BUSINESS_TYPE_ARR[0].label,
                                  value: _vm.BUSINESS_TYPE_ARR[0].value,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("el-col", { attrs: { span: 24 } }, [
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("basicData.明细业务类型") } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "operationCenter.PleaseSelect"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.queryForm.params.subBusinessType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "subBusinessType",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.subBusinessType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("basicData.all"),
                                  value: null,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t(
                                    "basicData.CollectionSystemBusiness"
                                  ),
                                  value: 1,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label:
                                    "" +
                                    _vm.$t("basicData.CourierSystemBusiness"),
                                  value: 0,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label:
                                    "" + _vm.$t("collectionCenter.dispatch"),
                                  value: 2,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("el-col", { attrs: { span: 24 } }, [
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("basicData.PickupCategory") },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled:
                                  _vm.queryForm.params.businessType !== 1,
                                placeholder: _vm.$t(
                                  "operationCenter.PleaseSelect"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              on: { change: _vm.changePush },
                              model: {
                                value: _vm.queryForm.params.collectionCategory,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "collectionCategory",
                                    $$v
                                  )
                                },
                                expression:
                                  "queryForm.params.collectionCategory",
                              },
                            },
                            [
                              _c("el-option", {
                                key: "0",
                                attrs: {
                                  label: _vm.$t("basicData.all"),
                                  value: null,
                                },
                              }),
                              _c("el-option", {
                                key: "1",
                                attrs: {
                                  label: _vm.$t("basicData.OrdinaryPickUp"),
                                  value: 0,
                                },
                              }),
                              _c("el-option", {
                                key: "2",
                                attrs: {
                                  label: _vm.$t("basicData.StockingAndPickup"),
                                  value: 1,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.selectKey === 1
                  ? _c("el-col", { attrs: { span: 24 } }, [
                      _c(
                        "div",
                        { staticClass: "grid-content bg-purple" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("basicData.BatchNo"),
                                prop: "billBatchNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { readonly: _vm.isReadOnly },
                                model: {
                                  value: _vm.queryForm.params.billBatchNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "billBatchNumber",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "queryForm.params.billBatchNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.selectKey === 2
                  ? _c("el-col", { attrs: { span: 24 } }, [
                      _c(
                        "div",
                        { staticClass: "grid-content bg-purple" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("basicData.ReceiptNumber"),
                                prop: "receiptNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { readonly: _vm.isReadOnly },
                                model: {
                                  value: _vm.queryForm.params.receiptNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "receiptNumber",
                                      $$v
                                    )
                                  },
                                  expression: "queryForm.params.receiptNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c("el-col", { attrs: { span: 24 } }, [
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("basicData.ExpenseType"),
                            prop: "feeName",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "operationCenter.PleaseSelect"
                                ),
                                clearable: "",
                                filterable: "",
                                remote: "",
                              },
                              model: {
                                value: _vm.queryForm.params.feeCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm.params, "feeCode", $$v)
                                },
                                expression: "queryForm.params.feeCode",
                              },
                            },
                            _vm._l(_vm.feeTypeOption, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.displayName,
                                  value: item.code,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("el-col", { attrs: { span: 24 } }, [
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.OrderStatus"),
                          },
                        },
                        [
                          _vm.queryForm.params.businessType == 0 ||
                          _vm.queryForm.params.businessType == 7
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "operationCenter.PleaseSelect"
                                        ),
                                        clearable: "",
                                        filterable: "",
                                      },
                                      model: {
                                        value: _vm.queryForm.params.status,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "status",
                                            $$v
                                          )
                                        },
                                        expression: "queryForm.params.status",
                                      },
                                    },
                                    _vm._l(
                                      _vm.WAYBILL_STATUS,
                                      function (item, ind) {
                                        return _c("el-option", {
                                          key: ind,
                                          attrs: {
                                            label: item.label,
                                            value: item.code,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm.queryForm.params.businessType == 1 &&
                              _vm.queryForm.params.collectionCategory == 0
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "operationCenter.PleaseSelect"
                                        ),
                                        clearable: "",
                                        filterable: "",
                                      },
                                      model: {
                                        value: _vm.queryForm.params.status,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "status",
                                            $$v
                                          )
                                        },
                                        expression: "queryForm.params.status",
                                      },
                                    },
                                    _vm._l(
                                      _vm.ORDER_TYPE_LIST,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.collectStatusName,
                                            value: item.collectStatus,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm.queryForm.params.businessType == 1 &&
                              _vm.queryForm.params.collectionCategory == 1
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "operationCenter.PleaseSelect"
                                        ),
                                        clearable: "",
                                        filterable: "",
                                      },
                                      model: {
                                        value: _vm.queryForm.params.status,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "status",
                                            $$v
                                          )
                                        },
                                        expression: "queryForm.params.status",
                                      },
                                    },
                                    _vm._l(
                                      _vm.BH_ORDER_TYPE_LIST,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.collectStatusName,
                                            value: item.collectStatus,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "operationCenter.PleaseSelect"
                                        ),
                                        clearable: "",
                                        filterable: "",
                                      },
                                      model: {
                                        value: _vm.queryForm.params.status,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "status",
                                            $$v
                                          )
                                        },
                                        expression: "queryForm.params.status",
                                      },
                                    },
                                    _vm._l(
                                      _vm.nullList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.x,
                                            value: item.x,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("basicData.Handler") } },
                      [
                        _c("AgentCmp", {
                          attrs: {
                            disabled: _vm.isJurisdiction,
                            val: _vm.queryForm.params.handlerId,
                          },
                          on: { change: _vm.agentChange },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("newOrder.RecipientState") } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                          },
                          model: {
                            value: _vm.queryForm.params.receiveState,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "receiveState",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "queryForm.params.receiveState",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("newOrder.SenderState") } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                          },
                          model: {
                            value: _vm.queryForm.params.senderState,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "senderState",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "queryForm.params.senderState",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticStyle: {
          "text-align": "center",
          "border-top": "1px solid #ccc",
          "padding-top": "10px",
        },
      },
      [
        _c(
          "el-button",
          {
            attrs: { size: "small", type: "primary" },
            on: {
              click: function ($event) {
                return _vm.searchForm(1)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("basicData.Search")))]
        ),
        _c(
          "el-button",
          {
            attrs: { size: "small", type: "success" },
            on: {
              click: function ($event) {
                return _vm.searchForm(3)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("basicData.reset")))]
        ),
        _c(
          "el-button",
          {
            attrs: { size: "small" },
            on: {
              click: function ($event) {
                return _vm.searchForm(2)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("basicData.Cancel")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }