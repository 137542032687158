<!--
 * @Author: 肖强
 * @Date: 2022-06-15 14:55:28
 * @LastEditTime: 2022-06-29 17:14:14
 * @LastEditors: 肖强
 * @Description: 表格组件
 * @FilePath: \admin-oa-web\src\views\orderCenter\region\cmp\tableCmp.vue
-->
<template>
  <div>
    <div style="position:relative;">
      <el-button
        class="putAway"
        size="small"
        @click="queryBoxShow = !queryBoxShow"
      >{{ queryBoxShow?$t('orderCenterCont.CollapseQuery'):$t('orderCenterCont.ExpandQuery') }}</el-button>
      <div
        v-show="queryBoxShow"
        class="searchContainer"
      >
        <el-form
          ref="form"
          :model="queryForm"
          label-width="120px"
          :label-position="$i18n.locale!='zh'?'top':'right'"
        >
          <el-row :gutter="20">
            <!-- 选择层级--已删除 -->
            <!-- <el-col :span="7">
              <div class="grid-content bg-purple">
                <el-form-item :label="$t('orderCenterCont.selectAHierarchy') + '：'">
                  <el-select
                    v-model="queryForm.params.level"
                    filterable
                    :placeholder="$t('operationCenter.PleaseSelect')"
                    clearable
                    style="width: 100%;"
                  >
                    <el-option
                      v-for="item in Hierarchy"
                      :key="item.level"
                      :label="item.name"
                      :value="item.level"
                    />
                  </el-select>
                </el-form-item>
              </div>
            </el-col> -->
            <el-col :span="7">
              <div class="grid-content bg-purple">
                <el-form-item :label="$t('orderCenterCont.ChineseName') + '：'">
                  <el-input v-model.trim="queryForm.params.nameCn" />
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="7">
              <div class="grid-content bg-purple">
                <el-form-item :label="$t('orderCenterCont.EnglishName') + '：'">
                  <el-input v-model.trim="queryForm.params.nameEn" />
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="7">
              <div class="grid-content bg-purple">
                <el-form-item :label="$t('orderCenterCont.TwoWordCode') + '：'">
                  <el-input v-model.trim="queryForm.params.twoCode" />
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </el-form>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="searchResources(1)"
        >{{ $t('operationCenter.Procurar') }}</el-button>
        <el-button
          size="small"
          icon="el-icon-refresh-left"
          @click="searchResources(2)"
        >{{ $t('operationCenter.reset') }}</el-button>
        <el-divider />
      </div>
    </div>
    <div class="m-b-sm">
      <el-button
        slot="trigger"
        v-permit:remove="'btn:order-center:add-region'"
        size="small"
        type="primary"
        icon="el-icon-plus"
        style="margin-right: 5px;"
        @click="operationBtn(4)"
      >{{ $t('orderCenterCont.add') }}</el-button>
    </div>
    <el-table
      :data="tableData"
      border
    >
      <el-table-column
        :label="$t('operationCenter.ind')"
        width="60px"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column
        v-if="level === 0"
        prop="regionalNameCn"
        :label="$t('orderCenterCont.BelongsToTheRegion')"
        align="left"
        :show-overflow-tooltip="true"
      /> -->
      <el-table-column
        v-if="level==0 || level==1"
        prop="startZipCode"
        :label="$t('orderCenterCont.startZipCode')"
        align="left"
        :show-overflow-tooltip="true"
      /><!-- 起始邮编 -->
      <el-table-column
        v-if="level==0 || level==1"
        prop="endZipCode"
        :label="$t('orderCenterCont.endZipCode')"
        align="left"
        :show-overflow-tooltip="true"
      /><!-- 结束邮编 -->
      <!--      <el-table-column-->
      <!--        v-if="level==0"-->
      <!--        prop="zipCode"-->
      <!--        :label="$t('orderCenterCont.Zip')"-->
      <!--        align="left"-->
      <!--        width="160"-->
      <!--        :show-overflow-tooltip="true"-->
      <!--      >-->
      <!--        <template slot-scope="scope">-->
      <!--          <div-->
      <!--            v-if="scope.row.tableStatus===0"-->
      <!--            class="cursor"-->
      <!--            @click="editZipCode(scope.$index)"-->
      <!--          >{{ scope.row.zipCode }}</div>-->
      <!--          <el-input-->
      <!--            v-if="scope.row.tableStatus===1"-->
      <!--            v-model="info.zipCode"-->
      <!--            placeholder="请输入内容"-->
      <!--            size="small"-->
      <!--            @keyup.enter.native="enterToChange"-->
      <!--            @blur="enterToChange"-->
      <!--          />-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column
        prop="nameCn"
        :label="`${ level==0?$t('orderCenterCont.state'):'' }${ level==1?$t('orderCenterCont.city'):'' }${ level==2?$t('orderCenterCont.region'):'' } ${$t('orderCenterCont.ChineseName')}`"
        align="left"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        prop="nameEn"
        :label="`${ level==0?$t('orderCenterCont.state'):'' }${ level==1?$t('orderCenterCont.city'):'' }${ level==2?$t('orderCenterCont.region'):'' } ${$t('orderCenterCont.EnglishName')}`"
        align="left"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        prop="twoCode"
        :label="$t('orderCenterCont.TwoWordCode')"
        align="left"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        prop="操作"
        :label="$t('operationCenter.operate')"
        align="center"
        :width="$i18n.locale=='zh'?'240px':'280px'"
      >
        <template slot-scope="scope">
          <el-button
            v-permit:remove="'btn:region-center:btn-change'"
            type="success"
            size="small"
            @click="operationBtn(2, scope.row)"
          >{{ $t('operationCenter.modify') }}</el-button>
          <el-button
            v-permit:remove="'btn:region-center:btn-delete'"
            type="danger"
            size="small"
            @click="operationBtn(3, scope.row)"
          >{{ $t('operationCenter.Delete') }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页工具 -->
    <div class="pagination">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-count="tableData.length"
        :page-sizes="[10, 20, 50, 100]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getMsgSetTableData"
        @size-change="getMsgSetTableData"
      />
    </div>
    <el-drawer
      :visible.sync="drawerTag"
      :append-to-body="true"
      direction="ltr"
      size="65%"
      custom-class="drawerClass"
      :title="checkData.orderType==4?$t('orderCenterCont.add'):(checkData.orderType==1?$t('orderCenterCont.ViewDetails'):$t('operationCenter.modify'))"
    >
      <addDetails
        :key="newKey"
        :update-form="updateForm"
        :region="region"
        :check-data="checkData"
        :level="level"
        :superior="superior"
        @changeData="changeData"
      />
    </el-drawer>
  </div>
</template>

<script>
import { deleteLgAreaList, getLgAreaList, getLgAreaListData } from '@/api/orderApi';
import addDetails from '@/views/orderCenter/region/cmp/addDetails';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'TableCmp',
  components: {
    addDetails
  },
  props: {
    level: {
      type: Number
    },
    parent: {
      type: String,
      default: ''
    },
    // 国家省份城市名称对象
    superior: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      tableData: [], // 表格数据
      queryForm: {
        // 查询数据
        orderDirection: 'DESC',
        orderProperty: '',
        pageNumber: 1,
        pageSize: 10,
        params: {
          level: this.level ? this.level : 0,
          nameCn: '',
          nameEn: '',
          parentId: this.parent,
          twoCode: '',
          extra: '',
          startZipCode: '',
          endZipCode: ''
        }
      },
      total: 0, // 表格总数
      queryBoxShow: true,
      newKey: new Date().getTime(),
      drawerTag: false, // 新增弹出层
      updateForm: {
        id: '',
        country: '',
        province: '',
        city: '',
        region: '',
        level: '',
        nameCn: '',
        nameEn: '',
        parentId: '',
        twoCode: '',
        extra: '',
        startZipCode: '',
        endZipCode: ''
      },
      region: {
        countryList: [],
        provinceList: [],
        cityList: [],
        nameStr: ''
      },
      checkData: {
        orderId: '',
        orderType: '',
        status: ''
      },
      info: {} // 当前修改邮编的对象
    };
  },
  watch: {
    level: {
      handler(newVal, oldVal) {
        // console.log('更新数据', newVal, oldVal);
        this.queryForm.params.level = newVal;
        this.getMsgSetTableData();
      }
    }
  },
  created() {
    this.getMsgSetTableData();
  },
  methods: {
    // 获取区列表
    getMsgSetTableData() {
      this.queryForm.params.parentId = this.parent;
      getLgAreaList(this.queryForm).then((res) => {
        if (res.status === 'OK') {
          this.tableData = res.data.records.map((item, index) => {
            item.tableStatus = 0;
            return item;
          });
          this.total = res.data.total;
        }
      });
    },
    // 操作
    operationBtn(type, row) {
      this.newPage = false;
      this.checkData.orderType = type;
      this.newKey = new Date().getTime();
      if (row) {
        this.updateForm = cloneDeep(row);
        this.extraOld = cloneDeep(row).extra;
        if (row !== 0) {
          if (type === 2) {
            this.bus.$emit('switchLevel', row);
          }
        }
      }
      if (type === 4) {
        this.resetFormData();
        this.region.nameStr = '';
        this.drawerTag = true;
      } else if (type === 3) {
        this.queryForm.pageNumber = 1;
        this.$confirm(
          this.$i18n.t('orderCenterCont.operationWillDeleteCity'),
          this.$i18n.t('operationCenter.tips'),
          {
            confirmButtonText: this.$i18n.t('operationCenter.determine'), // '确定',
            cancelButtonText: this.$i18n.t('operationCenter.Cancel'), // '取消',
            type: 'warning'
          }
        )
          .then(() => {
            deleteLgAreaList(row.id)
              .then((res) => {
                if (res.status === 'OK') {
                  this.$message.success(this.$i18n.t('operationCenter.DeleteSucceeded'));
                  this.getMsgSetTableData();
                }
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch(() => {});
      }
    },

    // 查询
    searchResources(type) {
      if (type === 1) {
        this.queryForm.pageNumber = 1;
        this.getMsgSetTableData();
      } else {
        this.queryForm.params.nameCn = '';
        this.queryForm.params.nameEn = '';
        this.queryForm.params.twoCode = '';
        this.queryForm.pageNumber = 1;
        this.getMsgSetTableData();
      }
    },
    // 重置表单
    resetFormData() {
      // console.log(this.parent, '父级id');
      this.updateForm.level = '';
      this.updateForm.nameCn = '';
      this.updateForm.nameEn = '';
      this.updateForm.parentId = this.parent;
      this.updateForm.id = '';
      this.updateForm.twoCode = '';
      this.updateForm.extra = '';
      this.updateForm.startZipCode = '';
      this.updateForm.endZipCode = '';
      this.updateForm.country = '';
      this.updateForm.province = '';
      this.updateForm.city = '';
      // this.$refs.updateForm.resetFields();
    },
    // 添加、保存
    changeData(data) {
      this.drawerTag = false;
      if (data) {
        this.getMsgSetTableData();
      }
    },
    // 根据parentId获取上级
    getLgArea(value) {
      // console.log('根据parentId获取上级', value);
      const params = {
        level: value.level === 0 ? 0 : value.level - 1,
        id: value.parentId
      };
      getLgAreaListData(params).then((res) => {
        if (res.status === 'OK') {
          if (value.level === 1) {
            for (let j = 0; j < res.data.length; j++) {
              if (res.data[j].id === value.parentId) {
                this.country = res.data[j];
                this.$set(this.updateForm, 'country', res.data[j].id);
                this.nameArr.unshift(this.country.nameCn);
                break;
              }
            }
          } else if (value.level === 2) {
            for (let k = 0; k < res.data.length; k++) {
              if (res.data[k].id === value.parentId) {
                this.province = res.data[k];
                this.$set(this.updateForm, 'province', res.data[k].id);
                this.nameArr.unshift(this.province.nameCn);
                break;
              }
            }
            this.getLgArea(this.province);
          }
          /* else if (value.level === 3) {
            for (let l = 0; l < res.data.length; l++) {
              if (res.data[l].id === value.parentId) {
                this.city = res.data[l];
                this.$set(this.updateForm, 'city', res.data[l].id);
                this.nameArr.unshift(this.city.nameCn);
                break;
              }
            }
            this.getLgArea(this.city);
          }*/
        }
      });
    },
    // 地区选项
    getSelectProvince1(level, parentId) {
      if (level !== 0 && !parentId) {
        return;
      } else if (this.updateForm.level === 1 && level === 1) {
        return;
      } else if (this.updateForm.level === 2 && level === 2) {
        return;
      }

      const params = {
        level: level,
        parentId: parentId
      };
      getLgAreaListData(params, false).then((res) => {
        // 换成你的接口方法
        if (res.status === 'OK') {
          const { data } = res;
          const arr = data.map((item) => {
            const { id, nameEn, nameCn, twoCode } = item;
            return {
              id,
              label: `${nameEn}(${nameCn})${twoCode ? '(' + twoCode + ')' : ''}`
            };
          });
          if (level === 0) {
            this.$set(this.region, 'countryList', arr);
          } else if (level === 1) {
            this.$set(this.region, 'provinceList', arr);
          } else if (level === 2) {
            this.$set(this.region, 'cityList', arr);
          }
        }
      });
    },
    /**
     * @description: 修改邮编
     * @param { Object } row 当前选择修改邮编的省、市、区
     */
    editZipCode(index) {
      this.tableData = this.tableData.map((item, inx) => {
        if (index === inx) {
          item.tableStatus = 1;
        } else {
          item.tableStatus = 0;
        }
        return item;
      });
      this.info = this.tableData[index];
    },
    /**
     * @description: 回车修改邮编
     */
    enterToChange() {
      console.log(this.info);
      this.$emit('editLgArea', this.info, 1);
      this.tableData = this.tableData.map((item, inx) => {
        item.tableStatus = 0;
        return item;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.putAway {
  position: absolute;
  right: 0;
  top: 0;
  color: #1989fa;
  cursor: pointer;
  z-index: 100;
}
.m-b-sm {
  margin-bottom: 10px;
}
.cursor {
  color: #1989fa;
  cursor: pointer;
  min-height: 30px;
}
</style>
