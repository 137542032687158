var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("el-tree", {
        attrs: {
          data: _vm.permissionList,
          "expand-on-click-node": false,
          props: _vm.defaultProps,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var node = ref.node
              var data = ref.data
              return _c("span", { staticClass: "custom-tree-node mb" }, [
                _c("span", { staticClass: "titlts" }, [
                  data.isParent
                    ? _c("i", { staticClass: "iconfont icon-folder-open" })
                    : _c("i", {
                        staticClass: "iconfont",
                        class: [
                          data.name === "查询"
                            ? "icon-chaxun"
                            : data.name === "添加"
                            ? "icon-icon--"
                            : data.name === "删除"
                            ? "icon-shanchu"
                            : data.name === "编辑"
                            ? "icon-bianji"
                            : "icon-wenjian",
                        ],
                      }),
                  _vm._v(" " + _vm._s(data.name) + " "),
                ]),
                _c(
                  "span",
                  { staticClass: "custom-tree-node-btn" },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permit",
                            rawName: "v-permit:remove",
                            value: "btn:user-center:permission:add2",
                            expression: "'btn:user-center:permission:add2'",
                            arg: "remove",
                          },
                        ],
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function () {
                            return _vm.addChild(node, data)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("organizationManage.addNextLevel"))
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "custom-tree-node-btn" },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permit",
                            rawName: "v-permit:remove",
                            value: "btn:user-center:permission:add1",
                            expression: "'btn:user-center:permission:add1'",
                            arg: "remove",
                          },
                        ],
                        attrs: { size: "mini", type: "success" },
                        on: {
                          click: function () {
                            return _vm.addBrother(node, data)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("organizationManage.addSameLevel"))
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "custom-tree-node-btn" },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permit",
                            rawName: "v-permit:remove",
                            value: "btn:user-center:permission:edit",
                            expression: "'btn:user-center:permission:edit'",
                            arg: "remove",
                          },
                        ],
                        attrs: { size: "mini", type: "info" },
                        on: {
                          click: function () {
                            return _vm.editNode(node, data)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("organizationManage.edit")))]
                    ),
                  ],
                  1
                ),
                !data.isParent
                  ? _c(
                      "span",
                      { staticClass: "custom-tree-node-btn" },
                      [
                        _c(
                          "el-popconfirm",
                          {
                            attrs: {
                              title: _vm.$t(
                                "organizationManage.AreYouSureDelete"
                              ),
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.remove(node, data)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permit",
                                    rawName: "v-permit:remove",
                                    value: "btn:user-center:permission:delete",
                                    expression:
                                      "'btn:user-center:permission:delete'",
                                    arg: "remove",
                                  },
                                ],
                                attrs: {
                                  slot: "reference",
                                  size: "mini",
                                  type: "danger",
                                },
                                slot: "reference",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("organizationManage.Delete"))
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            },
          },
        ]),
      }),
      _c("permission-operation", {
        attrs: { "permission-opt": _vm.permissionOptPop },
        on: { "on-add-submit": _vm.addSubmit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }