var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        },
      },
    },
    [
      _c(
        "fieldset",
        { staticClass: "search-vtag" },
        [
          _c(
            "o-field",
            {
              staticClass: "flex-1",
              attrs: {
                label: "Digite a Vtag",
                "label-for": "vtag",
                message: _vm.v$.listVTag.$errors.length
                  ? _vm.v$.listVTag.$errors[0].$message
                  : null,
              },
            },
            [
              _c("o-input", {
                attrs: { id: "vtag", placeholder: "Digite a vtag" },
                model: {
                  value: _vm.vtag,
                  callback: function ($$v) {
                    _vm.vtag = $$v
                  },
                  expression: "vtag",
                },
              }),
            ],
            1
          ),
          _c(
            "o-button",
            {
              attrs: { variant: "primary", size: "md" },
              on: { click: _vm.handleAddVtag },
            },
            [_c("o-icon", { attrs: { icon: "search" } })],
            1
          ),
        ],
        1
      ),
      _c(
        "ul",
        { staticClass: "list-vtags" },
        _vm._l(_vm.formData.listVTag, function (vtag) {
          return _c("li", { key: vtag, staticClass: "vtag" }, [
            _vm._v(" " + _vm._s("" + vtag) + " "),
          ])
        }),
        0
      ),
      _c(
        "FormActions",
        [
          _c(
            "div",
            [
              _c(
                "o-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !!_vm.formData.listVTag.length,
                      expression: "!!formData.listVTag.length",
                    },
                  ],
                  attrs: { variant: "danger", size: "md" },
                  on: { click: _vm.handleClearVtags },
                },
                [_vm._v(" Limpar tudo ")]
              ),
            ],
            1
          ),
          _c("FormActionsNext", { attrs: { "is-last-step": _vm.isLastStep } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }