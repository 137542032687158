var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "el-form",
        { attrs: { inline: true } },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("newOrder.orderCreateTime") } },
            [
              _c("el-date-picker", {
                attrs: {
                  clearable: true,
                  format: _vm.$i18n.locale == "zh" ? _vm.zhDate : _vm.ptDate,
                  placeholder: _vm.$t("newOrder.SelectDatetime"),
                  "range-separator": "~",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.timeValue,
                  callback: function ($$v) {
                    _vm.timeValue = $$v
                  },
                  expression: "timeValue",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-search", type: "primary" },
                  on: { click: _vm.getSearchBtn },
                },
                [_vm._v(_vm._s(_vm.$t("newOrder.search")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh-left", type: "info" },
                  on: { click: _vm.resetForm },
                },
                [_vm._v(_vm._s(_vm.$t("newOrder.reset")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-upload2", plain: "" },
              on: { click: _vm.exportToExcel },
            },
            [_vm._v(_vm._s(_vm.$t("newOrder.export")))]
          ),
          _c(
            "el-radio-group",
            {
              staticStyle: { "margin-left": "20px" },
              attrs: { size: "large" },
              on: { change: _vm.getSearchBtn },
              model: {
                value: _vm.queryForm.params.customerType,
                callback: function ($$v) {
                  _vm.$set(_vm.queryForm.params, "customerType", $$v)
                },
                expression: "queryForm.params.customerType",
              },
            },
            [
              _c("el-radio-button", { attrs: { label: 1 } }, [
                _vm._v(_vm._s(_vm.$t("newOrder.Formal"))),
              ]),
              _c("el-radio-button", { attrs: { label: 2 } }, [
                _vm._v(_vm._s(_vm.$t("newOrder.test1"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticStyle: { overflow: "auto" } }, [
        _c(
          "table",
          {
            staticStyle: {
              width: "100%",
              "text-align": "center",
              "font-size": "14px",
            },
          },
          [
            _c("thead", [
              _c("tr", [
                _c(
                  "th",
                  {
                    staticStyle: { "min-width": "130px" },
                    attrs: { colspan: "2" },
                  },
                  [_vm._v(_vm._s(_vm.$t("GlobalSetObj.scannerRate")))]
                ),
                _c("th", { staticStyle: { "min-width": "90px" } }, [
                  _vm._v(_vm._s(_vm.$t("orderCenterCont.total"))),
                ]),
                _c("th", { staticStyle: { "min-width": "120px" } }, [
                  _vm._v(_vm._s(_vm.$t("newOrder.ToBeCollected"))),
                ]),
                _c("th", { staticStyle: { "min-width": "100px" } }, [
                  _vm._v(_vm._s(_vm.$t("newOrder.Collected"))),
                ]),
                _c("th", { staticStyle: { "min-width": "130px" } }, [
                  _vm._v(_vm._s(_vm.$t("newOrder.CollectTheRevenuePool"))),
                ]),
                _c("th", { staticStyle: { "min-width": "160px" } }, [
                  _vm._v(_vm._s(_vm.$t("newOrder.CollectTakeOutWarehouse"))),
                ]),
                _c("th", { staticStyle: { "min-width": "130px" } }, [
                  _vm._v(_vm._s(_vm.$t("newOrder.TransshipmentIntoStorage"))),
                ]),
                _c("th", { staticStyle: { "min-width": "160px" } }, [
                  _vm._v(_vm._s(_vm.$t("newOrder.TransferOutWarehouse"))),
                ]),
                _c("th", { staticStyle: { "min-width": "160px" } }, [
                  _vm._v(_vm._s(_vm.$t("newOrder.DeliveryOutletsIntoStorage"))),
                ]),
                _c("th", { staticStyle: { "min-width": "90px" } }, [
                  _vm._v(_vm._s(_vm.$t("newOrder.Delivering"))),
                ]),
                _c("th", { staticStyle: { "min-width": "90px" } }, [
                  _vm._v(_vm._s(_vm.$t("newOrder.Signed"))),
                ]),
                _c("th", { staticStyle: { "min-width": "140px" } }, [
                  _vm._v(_vm._s(_vm.$t("newOrder.ProblemPiece"))),
                ]),
                _c("th", { staticStyle: { "min-width": "140px" } }, [
                  _vm._v(_vm._s(_vm.$t("GlobalSetObj.deliveryFailure"))),
                ]),
                _c("th", { staticStyle: { "min-width": "130px" } }, [
                  _vm._v(_vm._s(_vm.$t("GlobalSetObj.Withdrawn"))),
                ]),
                _c("th", { staticStyle: { "min-width": "130px" } }, [
                  _vm._v(_vm._s(_vm.$t("GlobalSetObj.TheReturnComplete"))),
                ]),
              ]),
            ]),
            _c("tbody", { ref: "contentbOdy" }),
          ]
        ),
      ]),
      _c("el-pagination", {
        staticStyle: { margin: "10px 0", float: "right" },
        attrs: {
          "current-page": _vm.queryForm.pageNumber,
          "page-size": _vm.queryForm.pageSize,
          "page-sizes": [10, 50, 200, 400],
          total: _vm.total,
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }