<template>
  <div class="preStyle">
    <el-form
      ref="formData"
      label-width="100px"
      label-position="left"
      style="padding: 20px; height: calc(100vh - 68px); overflow-y: auto"
    >
      <el-row>
        <!-- 业务单号 -->
        <el-col :span="8">
          <div class="grid-content bg-purple-light">
            <el-form-item
              prop="referenceNumber"
              :label="$t('receivablePayable.TicketNumber') "
            >
              <span>{{ row.businessNumber }}</span>
            </el-form-item>
          </div>
        </el-col>
        <!-- 请求时间 -->
        <el-col :span="8">
          <div class="grid-content bg-purple-light">
            <el-form-item
              prop="type"
              :label="$t('orderCenterCont.RequestTime')"
            >
              <span>{{ row.createTime }}</span>
            </el-form-item>
          </div>
        </el-col>

        <!-- 请求人 -->
        <el-col :span="8">
          <div class="grid-content bg-purple-light">
            <el-form-item
              prop="createTime"
              :label="$t('routes.usernameLabel')"
            >
              <span>{{ row.createUserName }}</span>
            </el-form-item>
          </div>
        </el-col>
      </el-row>

      <!-- 请求信息 -->
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-light">
            <el-form-item :label="$t('orderCenterCont.RequestMessages')">
              <PreCodeCmp :json-strin="row.requestInfo" />
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-light">
            <el-form-item :label="$t('orderCenterCont.ResponseMessages')">
              <PreCodeCmp :json-strin="row.responseInfo" />
            </el-form-item>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { formData } from './../utils';
import PreCodeCmp from './PreCodeCmp.vue';

export default {
  name: '',

  components: {
    PreCodeCmp
  },

  props: {
    row: {
      type: Object,
      default: () => {
        return {
          ...formData
        };
      }
    }
  }

};
</script>

<style lang="scss" scoped>
.preStyle {
  height: calc(100vh - 70px);
  overflow: hidden;
}
</style>
