var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "wrapperForm",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        },
      },
    },
    [
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("bank.companyName"),
            "label-for": "company",
            message: _vm.v$.companyName.$errors.length
              ? _vm.v$.companyName.$errors[0].$message
              : null,
          },
        },
        [
          _c(
            "o-autocomplete",
            {
              staticClass: "col-2",
              attrs: {
                id: "company",
                placeholder: _vm.$t("bank.companyNamePlaceholder"),
                clearable: "",
                expanded: "",
                field: "name",
                data: _vm.listCompany,
              },
              on: { input: _vm.filteredCompany, select: _vm.selectedCompany },
              model: {
                value: _vm.formData.companyName,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "companyName", $$v)
                },
                expression: "formData.companyName",
              },
            },
            [
              _c("template", { slot: "empty" }, [
                _vm._v(_vm._s(_vm.$t("bank.noResults"))),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("bank.bank"),
            "label-for": "bank",
            message: _vm.v$.bank.$errors.length
              ? _vm.v$.bank.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: { id: "bank", placeholder: _vm.$t("bank.bankPlaceholder") },
            model: {
              value: _vm.formData.bank,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "bank", $$v)
              },
              expression: "formData.bank",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          directives: [
            {
              name: "mask",
              rawName: "v-mask",
              value: "####",
              expression: "'####'",
            },
          ],
          attrs: {
            label: _vm.$t("bank.agency"),
            "label-for": "agency",
            message: _vm.v$.agency.$errors.length
              ? _vm.v$.agency.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "agency",
              placeholder: _vm.$t("bank.agencyPlaceholder"),
            },
            model: {
              value: _vm.formData.agency,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "agency", $$v)
              },
              expression: "formData.agency",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("bank.account"),
            "label-for": "account",
            message: _vm.v$.account.$errors.length
              ? _vm.v$.account.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "account",
              placeholder: _vm.$t("bank.accountPlaceholder"),
            },
            model: {
              value: _vm.formData.account,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "account", $$v)
              },
              expression: "formData.account",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("bank.companyBeneficiary"),
            "label-for": "beneficiary",
            message: _vm.v$.beneficiary.$errors.length
              ? _vm.v$.beneficiary.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "beneficiary",
              placeholder: _vm.$t("bank.beneficiaryPlaceholder"),
            },
            model: {
              value: _vm.formData.beneficiary,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "beneficiary", $$v)
              },
              expression: "formData.beneficiary",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("bank.contact"),
            "label-for": "financialContact",
            message: _vm.v$.financialContact.$errors.length
              ? _vm.v$.financialContact.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "financialContact",
              placeholder: _vm.$t("bank.contactPlaceholder"),
            },
            model: {
              value: _vm.formData.financialContact,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "financialContact", $$v)
              },
              expression: "formData.financialContact",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("bank.email"),
            "label-for": "financialEmail",
            message: _vm.v$.financialEmail.$errors.length
              ? _vm.v$.financialEmail.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "financialEmail",
              placeholder: _vm.$t("bank.emailPlaceholder"),
            },
            model: {
              value: _vm.formData.financialEmail,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "financialEmail", $$v)
              },
              expression: "formData.financialEmail",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          directives: [
            {
              name: "mask",
              rawName: "v-mask",
              value: ["+## (##) ####-####", "+## (##) #####-####"],
              expression: "['+## (##) ####-####', '+## (##) #####-####']",
            },
          ],
          attrs: {
            label: _vm.$t("bank.phone"),
            "label-for": "financialPhone",
            message: _vm.v$.financialPhone.$errors.length
              ? _vm.v$.financialPhone.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "financialPhone",
              placeholder: _vm.$t("bank.phonePlaceholder"),
            },
            model: {
              value: _vm.formData.financialPhone,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "financialPhone", $$v)
              },
              expression: "formData.financialPhone",
            },
          }),
        ],
        1
      ),
      _c(
        "FormActions",
        [
          _c("FormActionsPrevious", {
            attrs: { "is-first-step": _vm.isFirstStep },
            on: { handlePrevious: _vm.handlePrevious },
          }),
          _c("FormActionsNext", { attrs: { "is-last-step": _vm.isLastStep } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }