<template>
  <div class="container">
    <div class="searchBox">
      <el-form
        ref="searchForm"
        v-model="queryForm"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="6" style="height: 70px">
            <!--开票状态-->
            <el-form-item :label="$t('GlobalSetObj.InvoicingStatus')">
              <el-select
                v-model="queryForm.params.invoiceStatus"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                filterable
                style="width: 100%;"
                @change="searchFormGetTable(null)"
              >
                <!--全部-->
                <el-option key="1" :label="$t('GlobalSetObj.all')" value="" />
                <!--待开票-->
                <el-option key="2" :label="$t('GlobalSetObj.ToBeInvoiced')" :value="1" />
                <!--已开票-->
                <el-option key="3" :label="$t('GlobalSetObj.Invoiced')" :value="2" />
                <!--已拒绝-->
                <el-option key="4" :label="$t('GlobalSetObj.Rejected')" :value="3" />
                <!--无需开票-->
                <el-option key="5" :label="$t('GlobalSetObj.NoNeedInvoice')" :value="4" />
                <!--已关闭-->
                <el-option key="6" :label="$t('GlobalSetObj.Closed')" :value="5" />
                <!--已取消-->
                <el-option key="7" :label="$t('GlobalSetObj.Canceled')" :value="6" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="height: 70px">
            <!--区间-->
            <el-form-item :label="$t('GlobalSetObj.interval')">
              <el-select
                v-model="queryForm.params.section"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                filterable
                style="width: 100%;"
                @change="searchFormGetTable(null)"
              >
                <!--全部-->
                <el-option key="1" :label="$t('GlobalSetObj.all')" value="" />
                <!--揽收区间-->
                <el-option key="2" :label="$t('GlobalSetObj.HarvestRange')" :value="2" />
                <!--派送区间-->
                <el-option key="3" :label="$t('GlobalSetObj.DeliverySection')" :value="3" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="height: 70px">
            <!--物流发票开票时间-->
            <el-row>
              <el-col :span="$i18n.locale=='zh' ? 10 : 20" class="selectType">
                <el-select
                  v-model="queryForm.params.searchDateType"
                  :placeholder="$t('orderDetails.pleaseChoose')"
                  size="mini"
                  style="width: 100%;"
                  @change="changeNumberType(1, queryForm.params.searchDateType)"
                >
                  <el-option key="0" :label="$t('GlobalSetObj.LogisticsInvoiceInvoicingTime')" :value="1" />
                  <!--                  <el-option key="1" :label="$t('GlobalSetObj.TimeOfOrder')" :value="2" />-->
                </el-select>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item>
                  <el-date-picker
                    v-model="invoiceDate"
                    :end-placeholder="$t('GlobalSetObj.endDate')"
                    :format="$i18n.locale==='zh'?zhDate:ptDate"
                    :range-separator="$t('GlobalSetObj.to')"
                    :start-placeholder="$t('GlobalSetObj.startDate')"
                    style="width: 100%;"
                    type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @change="searchFormGetTable(null)"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8">
            <el-row>
              <el-col :span="$i18n.locale=='zh'?10:20" class="selectType">
                <el-select
                  v-model="queryForm.params.searchNumberType"
                  :placeholder="$t('orderDetails.pleaseChoose')"
                  size="mini"
                  style="width: 100%;"
                  @change="changeNumberType(2, queryForm.params.searchNumberType)"
                >
                  <el-option
                    v-for="item in selectLabel" :key="item.id" :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item :key="showNumList" class="NumberClass">
                  <div class="searchOrder" style="padding-left: 16px">
                    <el-input
                      v-model.trim="queryForm.params.numberData"
                      :placeholder="$t('orderDetails.pleaseInput')"
                      clearable
                      @keyup.enter.native="searchFormGetTable(1)"
                    />
                    <div style="width: 40px;">
                      <el-button class="chaxunBtn" @click="searchFormGetTable(1)">
                        <i class="iconfont icon-chaxun" style="font-size: 24px" />
                      </el-button>
                    </div>
                    <div style="width: 38px;">
                      <el-popover
                        v-model="showNumList"
                        placement="bottom-end"
                        trigger="manual"
                      >
                        <div style="width: 300px;">
                          <el-input
                            v-model="searchNumberStr"
                            :placeholder="$t('newOrder.PreciseSearch')"
                            :rows="10"
                            type="textarea"
                          />
                          <div style="margin-top: 10px;">
                            <!--清空-->
                            <el-button size="small" @click="clearSearchNum()">{{ $t('newOrder.empty') }}</el-button>
                            <div style="float: right">
                              <!--关闭-->
                              <el-button size="small" @click="openNumList">{{ $t('orderExport.close') }}</el-button>
                              <!--搜索-->
                              <el-button
                                plain size="small" type="primary"
                                @click="searchFormGetTable(2)"
                              >
                                {{ $t('newOrder.search') }}
                              </el-button>
                            </div>
                          </div>
                        </div>
                        <el-button slot="reference" class="btnIcon" @click="openNumList">
                          <i class="iconfont icon-liebiao1" style="font-size: 24px" />
                        </el-button>
                      </el-popover>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <div v-show="searchNumberList.length > 0">
          <div class="searchNum">
            <el-tooltip class="item" effect="dark" placement="bottom">
              <template slot="content">
                <pre>{{ searchNumberStr }}</pre>
              </template>
              <div class="searchNumWarp">
                <div class="wordBreak">
                  <p>
                    {{ searchNameObj.label }}：
                    {{ searchNumberList.join(',') }}
                  </p>
                </div>
              </div>
            </el-tooltip>
            <div class="clearSearchNum" @click="clearSearchNum()">
              <i class="el-icon-close" />
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <div style="padding: 0 20px;">
      <!--下载发票-->
      <el-button size="small" type="primary" @click="downloadInvoice()">{{
        $t('GlobalSetObj.DownloadTheInvoice')
      }}
      </el-button>
      <!--取消发票-->
      <el-button
        v-permit:remove="'but:order-center:logisticsInvoice:clear'"
        size="small"
        @click="cancelInvoice()"
      >{{ $t('GlobalSetObj.CancelTheInvoice') }}
      </el-button>
      <!--打印清单-->
      <!--      <el-button size="small" :disabled="queryForm.params.invoiceStatus !== 2" @click="printChecklist()">{{ $t('GlobalSetObj.PrintTheChecklist') }}</el-button>-->
      <!--导出-->
      <el-button size="small" style="float: right;" @click="downloadData()">{{ $t('newOrder.export') }}</el-button>
    </div>
    <div style="padding: 10px 20px">
      <el-table
        ref="tableData"
        :data="tableData"
        border
        row-key="id"
        @selection-change="selectTableData"
      >
        <el-table-column
          :reserve-selection="true"
          align="center"
          fixed="left"
          type="selection"
          width="50"
        />
        <template v-for="(item, ind) in tableColumn">
          <el-table-column
            :key="ind"
            :label="item.label"
            :prop="item.prop"
            :width="item.width"
            align="center"
          >
            <template v-if="item.tips" slot="header">
              <div>
                <span>{{ item.label }}</span>
                <el-tooltip class="QuestionMark" placement="top">
                  <div slot="content">{{ item.tips }}</div>
                  <svg-icon icon-class="QuestionMark" />
                </el-tooltip>
              </div>
            </template>
            <template slot-scope="scope">
              <div v-if="item.isCopy">
                <div v-if="scope.row[item.prop]">
                  <el-popover
                    placement="top"
                    popper-class="copy"
                    trigger="hover"
                  >
                    <el-button
                      style="color: black;font-size: 12px;padding: 2px"
                      type="text"
                      @click="copy(scope.row[item.prop])"
                    >
                      {{ $t('orderDetails.copy') }}
                    </el-button>
                    <div
                      slot="reference"
                      :class="item.highlight ? 'WaybillNumber' : ''"
                      @click="(e) => $emit('to-OrderDetail',scope.row)"
                    >
                      {{ scope.row[item.prop] ? scope.row[item.prop] : '--' }}
                    </div>
                  </el-popover>
                </div>
                <div v-else>--</div>
              </div>
              <div v-else-if="item.endProp">
                {{ scope.row[item.prop] }} ~ {{ scope.row[item.endProp] }}
              </div>
              <span v-else>{{ scope.row[item.prop] ? scope.row[item.prop] : '--' }}</span>
            </template>
          </el-table-column>
        </template>
        <!--操作-->
        <el-table-column
          :label="$t('GlobalSetObj.operate')" align="center" fixed="right"
          width="140"
        >
          <template slot-scope="scope">
            <div>
              <el-button
                v-if="scope.row.invoiceStatus === 2 && getDiff(scope.row.invoiceIssueTime, new Date())"
                v-permit:remove="'but:order-center:logisticsInvoice:clear'"
                size="small"
                type="text"
                @click="cancelInvoice(scope.row)"
              >{{ $t('GlobalSetObj.CancelTheInvoice') }}
              </el-button>
              <!--       重新生成       -->
              <el-button
                v-if="scope.row.invoiceStatus === 3"
                size="small"
                type="text"
                @click="regenerate(scope.row)"
              >{{ $t('GlobalSetObj.Rebuild') }}
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          :current-page.sync="queryForm.pageNumber"
          :page-size.sync="queryForm.pageSize"
          :page-sizes="[10, 20, 40, 60, 80, 100]"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          style="margin: 10px 0"
          @current-change="getCurrentTableData"
          @size-change="getSizeTableData"
        />
      </div>
    </div>
    <el-dialog
      :title="$t('GlobalSetObj.DownloadTheInvoice')"
      :visible.sync="showExportTips"
      width="500px"
    >
      <div>
        <div v-if="$i18n.locale=='zh'" style="font-size: 15px;margin-bottom: 10px;">已选中{{
          validInvoice.length
        }}条可下载发票
        </div>
        <div v-else style="font-size: 15px;margin-bottom: 10px;">{{
          $t('GlobalSetObj.Selecionadas')
        }}{{ validInvoice.length }}{{ $t('GlobalSetObj.paraDownload') }}
        </div>
        <div v-if="validInvoice.length === 0" style="color: #f00;">{{ $t('GlobalSetObj.NoDownloadableInvoices') }}</div>
        <div v-else style="color: #8c939d;">
          {{ $t('GlobalSetObj.RejectedAndCanceledInvoices') }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showExportTips = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button type="primary" @click="determineDownloadInvoice">{{ $t('GlobalSetObj.confirm') }}</el-button>
      </span>
    </el-dialog>
    <!--取消发票确认提示-->
    <el-dialog
      :title="$t('GlobalSetObj.CancelTheInvoice')"
      :visible.sync="showCancelInvoiceTips"
      width="500px"
    >
      <div>
        <div style="font-size: 15px;margin-bottom: 20px;">{{ $t('GlobalSetObj.sureCancelInvoice') }}</div>
        <div style="color: #8c939d;">
          {{ $t('GlobalSetObj.invoicingTimeExceeds24') }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showCancelInvoiceTips = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button type="primary" @click="determineCancelInvoice">{{ $t('GlobalSetObj.confirm') }}</el-button>
      </span>
    </el-dialog>
    <!--取消发票进度-->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('GlobalSetObj.CancelTheInvoice')"
      :visible.sync="showCancelInvoiceProgress"
      width="600px"
    >
      <div style="padding: 20px;">
        <div>
          <div style="width: 100%;background:#DADADA;border-radius: 20px;height: 10px;">
            <div
              :style="{ width: widthProgress + '%' }"
              style="background:#42B983;height: 100%;border-radius: 20px;"
            />
          </div>
          <div style="text-align: right;color: #13ADF0;font-size: 13px;margin-top: 10px;">
            <span v-if="widthProgress < 100">{{ $t('newOrder.processing') }}</span>
            <span v-else>{{ $t('newOrder.ProcessingComplete') }}</span>
            <!--            {{ (widthProgress < 100) ? $t('newOrder.processing') : $t('newOrder.ProcessingComplete') }}-->
          </div>
        </div>
        <div v-if="widthProgress === 100">
          <div style="margin-bottom: 20px;">
            <span>
              {{ $t('GlobalSetObj.all') }}：
              <span style="color:#13ADF0;margin-right: 30px;">{{ cancelAll.length }}</span>
              {{ $t('operationCenter.succeed') }}：
              <span style="color:#42B983;margin-right: 30px;">{{ problemPieceReturn.successNumber }}</span>
              {{ $t('operationCenter.fail') }}：<span style="color:red;">{{ problemPieceReturn.failureNumber }}</span>
            </span>
          </div>
          <div>
            <!--失败原因-->
            <div style="margin-bottom: 10px;">{{ $t('GlobalSetObj.ReasonForFailure') }}：</div>
            <div class="errTable">
              <el-table :data="errTableData" :height="tableHeight" border>
                <el-table-column
                  :label="$t('GlobalSetObj.waybillNumber')"
                  :show-overflow-tooltip="true"
                  align="center"
                  prop="waybillNumber"
                />
                <el-table-column
                  :label="$t('GlobalSetObj.ReasonForFailure')"
                  :show-overflow-tooltip="true"
                  align="left"
                  prop="failReason"
                />
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="showCancelInvoiceProgress = false">{{ $t('GlobalSetObj.close') }}</el-button>
      </div>
    </el-dialog>
    <!--打印清单-->
    <el-dialog
      :title="$t('GlobalSetObj.PrintTheChecklist')"
      :visible.sync="showPrintChecklist"
      width="500px"
    >
      <div>
        <div v-if="$i18n.locale === 'zh'" style="font-size: 15px;margin-bottom: 20px;">当前筛选条件下有已开票数据{{ total }}条</div>
        <div v-else>{{ $t('GlobalSetObj.ThereAre') }}{{ total }}{{ $t('GlobalSetObj.invoicedDataFilter') }}</div>
        <div v-if="total > 5000" style="margin-bottom: 20px;">
          <div style="color:#f00;">{{ $t('GlobalSetObj.maximumNumber5000') }}</div>
        </div>
        <div style="color: #8c939d;">
          {{ $t('GlobalSetObj.RejectedAndCanceled') }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showPrintChecklist = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button
          :disabled="total > 5000"
          type="primary"
          @click="determinePrintChecklist"
        >{{ $t('operationCenter.Download') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash-es';
import { copyData } from '@/utils/copyData';
import { batchPdfDownload } from '@/utils/PDF_util';
import { setDateTime2 } from '@/utils/date_util';
import { apiInvoiceCancel, apiLogisticsCteExport, apiLogisticsInvoiceCtePage, rebuild } from '@/api/logisticsOrders';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList.js';
import { saveAs } from 'file-saver';

export default {
  name: 'Cte',
  data() {
    return {
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          invoiceStatus: '', // 状态
          section: '', // 区间
          searchDateType: 1, // 时间类型 1：物流发票开票时间
          invoiceIssueStartTime: '', // 开始时间
          invoiceIssueEndTime: '', // 结束时间
          searchNumberType: 1, // 单号类型
          waybillLogisticsNumber: [], // 运单号/物流单号
          invoiceNumbers: [], // 发票号
          invoiceSecretKeys: [], // 发票密钥
          bagNumbers: [] // 揽收袋号
        }
      },
      total: 0,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      invoiceDate: this.getCurrentDate(),
      showNumList: false,
      searchNumberStr: '',
      searchNumberList: [],
      selectLabel: [
        // 运单号/物流单号
        { id: 1, label: this.$t('newOrder.waybillNumber') + '/' + this.$t('GlobalSetObj.serviceLogistics'), value: 1 },
        // 物流发票号
        { id: 2, label: this.$t('GlobalSetObj.LogisticsInvoiceNumber'), value: 2 },
        // 物流发票密钥
        { id: 3, label: this.$t('GlobalSetObj.LogisticsInvoiceKey'), value: 3 },
        // 揽收袋号
        { id: 4, label: this.$t('GlobalSetObj.PickUpBagNumber'), value: 4 }
      ],
      searchNameObj: {
        id: 1,
        label: this.$t('newOrder.waybillNumber') + '/' + this.$t('GlobalSetObj.serviceLogistics'),
        value: 1
      },
      tableData: [],
      selectData: [],
      tableColumn: [
        // 运单号
        {
          label: this.$t('GlobalSetObj.waybillNumber'),
          prop: 'waybillNumber',
          width: 200,
          isCopy: true,
          highlight: true
        },
        // 服务商单号
        { label: this.$t('GlobalSetObj.serviceLogistics'), prop: 'logisticsNumber', width: 220, isCopy: true },
        // 物流发票号
        { label: this.$t('GlobalSetObj.LogisticsInvoiceNumber'), prop: 'invoiceNumber', width: 200 },
        // 物流发票密钥
        {
          label: this.$t('GlobalSetObj.LogisticsInvoiceKey'),
          prop: 'invoiceSecretKey',
          width: 230,
          tips: this.$t('GlobalSetObj.InvoiceKey')
        },
        // 运费
        { label: this.$t('GlobalSetObj.freight') + '(R$/$)', prop: 'estimateFee', width: 160 },
        // 税率
        { label: this.$t('GlobalSetObj.rate'), prop: 'taxRate', width: 160 },
        // 含税运费
        { label: this.$t('GlobalSetObj.ShippingCostsIncludeTax') + '(R$/$)', prop: 'freightIncludeRate', width: 320 },
        // 开票时间
        {
          label: this.$t('GlobalSetObj.InvoicingTime'),
          prop: 'invoiceIssueTime',
          width: 220,
          tips: this.$t('GlobalSetObj.LogisticsInvoiceGenerationTime')
        },
        // 开票状态
        { label: this.$t('GlobalSetObj.InvoicingStatus'), prop: 'invoiceStatusName', width: 200 },
        // 类型
        { label: this.$t('operationCenter.Type'), prop: 'invoiceTypeName', width: 200 },
        // 运输范围
        {
          label: this.$t('GlobalSetObj.ScopeOfTransportation'),
          prop: 'startCity',
          endProp: 'endCity',
          width: 200,
          tips: this.$t('GlobalSetObj.LogisticsInvoiceInvoicingScope')
        },
        // 商品发票
        { label: this.$t('GlobalSetObj.CommodityInvoices'), prop: 'owInvoiceNumber', width: 200 },
        // 商品发票密钥
        {
          label: this.$t('GlobalSetObj.CommodityInvoiceKey'),
          prop: 'owInvoiceAccessKey',
          width: 260,
          tips: this.$t('GlobalSetObj.productInvoiceKeyCorresponding')
        },
        // 开票时间
        {
          label: this.$t('GlobalSetObj.InvoicingTime'),
          prop: 'owInvoiceIssueDate',
          width: 230,
          tips: this.$t('GlobalSetObj.CommodityInvoiceActivationTime')
        },
        // 区间
        { label: this.$t('GlobalSetObj.interval'), prop: 'sectionName', width: 160 },
        // 揽收袋号
        { label: this.$t('GlobalSetObj.PickUpBagNumber'), prop: 'bagNumber', width: 210 },
        // 失败原因
        { label: this.$t('receivablePayable.ReasonForFailure'), prop: 'generateFailReason', width: 200 },
        // 下单时间
        { label: this.$t('GlobalSetObj.TimeOfOrder'), prop: 'owCreateTime', width: 200 }
      ],
      validInvoice: [],
      showExportTips: false,
      showCancelInvoiceTips: false,
      cancelAll: [],
      showCancelInvoiceProgress: false,
      widthProgress: 0,
      errTableData: [],
      problemPieceReturn: {
        successNumber: 0,
        failureNumber: 0
      },
      tableHeight: 200,
      showPrintChecklist: false,
      searchType: null
    };
  },
  computed: {
    BillingStatus() {
      return {
        1: { label: this.$t('GlobalSetObj.ToBeInvoiced'), code: 1 }, // 待开票
        2: { label: this.$t('GlobalSetObj.Invoiced'), code: 2 }, // 已开票
        3: { label: this.$t('GlobalSetObj.Rejected'), code: 3 }, // 已拒绝
        4: { label: this.$t('GlobalSetObj.NoNeedInvoice'), code: 4 }, // 无需开票
        5: { label: this.$t('GlobalSetObj.Closed'), code: 5 }, // 已关闭
        6: { label: this.$t('GlobalSetObj.Canceled'), code: 6 } // 已取消
      };
    },
    rangeStatus() {
      return {
        2: { label: this.$t('GlobalSetObj.HarvestRange'), code: 2 }, // 揽收区间
        3: { label: this.$t('GlobalSetObj.DeliverySection'), code: 3 } // 派送区间
      };
    }
  },
  created() {
    this.searchFormGetTable(null);
  },
  methods: {
    // 搜索
    searchFormGetTable(type) {
      this.searchType = type;
      this.queryForm.pageNumber = 1;
      this.changeFormGetTable();
    },
    // 导出
    downloadData() {
      this.searchNumberList = [];
      const param = {
        invoiceStatus: this.queryForm.params.invoiceStatus,
        section: this.queryForm.params.section,
        invoiceIssueStartTime: '',
        invoiceIssueEndTime: '',
        waybillLogisticsNumber: [], // 运单号/物流单号
        invoiceNumbers: [], // 发票号
        invoiceSecretKeys: [], // 发票密钥
        bagNumbers: [] // 揽收袋号
      };
      if (this.searchType === 1) {
        if (this.searchNumberStr) {
          this.searchNumberStr = '';
        }
      } else {
        if (this.queryForm.params.numberData) {
          this.queryForm.params.numberData = '';
        }
      }
      if (this.searchNumberStr) {
        const num = this.searchNumberStr.split(/([\n\r])/);
        num.map(item => {
          const reg = /\s/g;
          const a = item.replace(reg, '');
          const b = a.trim();
          if (b) {
            this.searchNumberList.push(b);
          }
        });
        if (this.queryForm.params.searchNumberType === 1) {
          param.waybillLogisticsNumber = this.searchNumberList;
          param.invoiceNumbers = [];
          param.invoiceSecretKeys = [];
          param.bagNumbers = [];
        } else if (this.queryForm.params.searchNumberType === 2) {
          param.waybillLogisticsNumber = [];
          param.invoiceNumbers = this.searchNumberList;
          param.invoiceSecretKeys = [];
          param.bagNumbers = [];
        } else if (this.queryForm.params.searchNumberType === 3) {
          param.waybillLogisticsNumber = [];
          param.invoiceNumbers = [];
          param.invoiceSecretKeys = this.searchNumberList;
          param.bagNumbers = [];
        } else {
          param.waybillLogisticsNumber = [];
          param.invoiceNumbers = [];
          param.invoiceSecretKeys = [];
          param.bagNumbers = this.searchNumberList;
        }
      } else if (this.queryForm.params.numberData) {
        if (this.queryForm.params.searchNumberType === 1) {
          param.waybillLogisticsNumber = [cloneDeep(this.queryForm.params.numberData)];
          param.invoiceNumbers = [];
          param.invoiceSecretKeys = [];
          param.bagNumbers = [];
        } else if (this.queryForm.params.searchNumberType === 2) {
          param.waybillLogisticsNumber = [];
          param.invoiceNumbers = [cloneDeep(this.queryForm.params.numberData)];
          param.invoiceSecretKeys = [];
          param.bagNumbers = [];
        } else if (this.queryForm.params.searchNumberType === 3) {
          param.waybillLogisticsNumber = [];
          param.invoiceNumbers = [];
          param.invoiceSecretKeys = [cloneDeep(this.queryForm.params.numberData)];
          param.bagNumbers = [];
        } else {
          param.waybillLogisticsNumber = [];
          param.invoiceNumbers = [];
          param.invoiceSecretKeys = [];
          param.bagNumbers = [cloneDeep(this.queryForm.params.numberData)];
        }
      } else {
        param.waybillLogisticsNumber = [];
        param.invoiceNumbers = [];
        param.invoiceSecretKeys = [];
        param.bagNumbers = [];
      }
      if (this.invoiceDate && this.invoiceDate.length > 0) {
        param.invoiceIssueStartTime = this.invoiceDate[0];
        param.invoiceIssueEndTime = this.invoiceDate[1];
      } else {
        param.invoiceIssueStartTime = '';
        param.invoiceIssueEndTime = '';
      }
      apiLogisticsCteExport(param).then(res => {
        goExportList(this);
      });
    },
    // 打印清单
    printChecklist() {
      this.showPrintChecklist = true;
    },
    // 确定打印清单
    determinePrintChecklist() {
    },
    // 列表数据勾选
    selectTableData(data) {
      this.selectData = data;
      this.cancelAll = [];
      this.selectData.map(item => {
        if (this.getDiff(item.invoiceIssueTime, new Date())) {
          this.cancelAll.push(item);
        }
      });
    },
    // 点击复制
    copy(data) {
      copyData(data);
    },
    // 获取当前日期
    getCurrentDate() {
      const date = new Date();
      const brazilTime = date.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
      const today = dayjs(brazilTime).format('YYYY-MM-DD');
      return [today + ' 00:00:00', today + ' 23:59:59'];
    },
    // 搜索
    changeFormGetTable() {
      this.searchNumberList = [];
      const param = {
        pageSize: this.queryForm.pageSize,
        pageNumber: this.queryForm.pageNumber,
        params: {
          invoiceStatus: this.queryForm.params.invoiceStatus,
          section: this.queryForm.params.section,
          invoiceIssueStartTime: '',
          invoiceIssueEndTime: '',
          waybillLogisticsNumber: [], // 运单号/物流单号
          invoiceNumbers: [], // 发票号
          invoiceSecretKeys: [], // 发票密钥
          bagNumbers: [] // 揽收袋号
        }
      };
      if (this.searchType === 1) {
        if (this.searchNumberStr) {
          this.searchNumberStr = '';
        }
      } else if (this.searchType === 2) {
        if (this.queryForm.params.numberData) {
          this.queryForm.params.numberData = '';
        }
      }
      if (this.queryForm.params.numberData) {
        if (this.searchNumberStr) {
          this.searchNumberStr = '';
        }
        if (this.queryForm.params.searchNumberType === 1) {
          param.params.waybillLogisticsNumber = [cloneDeep(this.queryForm.params.numberData)];
          param.params.invoiceNumbers = [];
          param.params.invoiceSecretKeys = [];
          param.params.bagNumbers = [];
        } else if (this.queryForm.params.searchNumberType === 2) {
          param.params.waybillLogisticsNumber = [];
          param.params.invoiceNumbers = [cloneDeep(this.queryForm.params.numberData)];
          param.params.invoiceSecretKeys = [];
          param.params.bagNumbers = [];
        } else if (this.queryForm.params.searchNumberType === 3) {
          param.params.waybillLogisticsNumber = [];
          param.params.invoiceNumbers = [];
          param.params.invoiceSecretKeys = [cloneDeep(this.queryForm.params.numberData)];
          param.params.bagNumbers = [];
        } else {
          param.params.waybillLogisticsNumber = [];
          param.params.invoiceNumbers = [];
          param.params.invoiceSecretKeys = [];
          param.params.bagNumbers = [cloneDeep(this.queryForm.params.numberData)];
        }
      } else if (this.searchNumberStr) {
        const num = this.searchNumberStr.split(/([\n\r])/);
        num.map(item => {
          const reg = /\s/g;
          const a = item.replace(reg, '');
          const b = a.trim();
          if (b) {
            this.searchNumberList.push(b);
          }
        });
        if (this.queryForm.params.searchNumberType === 1) {
          param.params.waybillLogisticsNumber = this.searchNumberList;
          param.params.invoiceNumbers = [];
          param.params.invoiceSecretKeys = [];
          param.params.bagNumbers = [];
        } else if (this.queryForm.params.searchNumberType === 2) {
          param.params.waybillLogisticsNumber = [];
          param.params.invoiceNumbers = this.searchNumberList;
          param.params.invoiceSecretKeys = [];
          param.params.bagNumbers = [];
        } else if (this.queryForm.params.searchNumberType === 3) {
          param.params.waybillLogisticsNumber = [];
          param.params.invoiceNumbers = [];
          param.params.invoiceSecretKeys = this.searchNumberList;
          param.params.bagNumbers = [];
        } else {
          param.params.waybillLogisticsNumber = [];
          param.params.invoiceNumbers = [];
          param.params.invoiceSecretKeys = [];
          param.params.bagNumbers = this.searchNumberList;
        }
      } else {
        param.params.waybillLogisticsNumber = [];
        param.params.invoiceNumbers = [];
        param.params.invoiceSecretKeys = [];
        param.params.bagNumbers = [];
      }
      if (this.invoiceDate && this.invoiceDate.length > 0) {
        param.params.invoiceIssueStartTime = this.invoiceDate[0];
        param.params.invoiceIssueEndTime = this.invoiceDate[1];
      } else {
        param.params.invoiceIssueStartTime = '';
        param.params.invoiceIssueEndTime = '';
      }
      this.showNumList = false;
      apiLogisticsInvoiceCtePage(param).then(res => {
        if (res.status === 'OK') {
          if (res.data.records.length > 0) {
            this.tableData = res.data.records;
          } else {
            this.tableData = [];
          }
          this.total = res.data.total;
          this.tableData.map(item => {
            if (this.BillingStatus[item.invoiceStatus].label) {
              item.invoiceStatusName = this.BillingStatus[item.invoiceStatus].label;
            }
            if (this.rangeStatus[item.section].label) {
              item.sectionName = this.rangeStatus[item.section].label;
            }
          });
        }
      });
    },
    // 打开\关闭多个单号输入
    openNumList() {
      this.showNumList = !this.showNumList;
    },
    // 清除多行列表搜索
    clearSearchNum() {
      this.searchNumberList = [];
      this.searchNumberStr = '';
    },
    // 单号类型切换
    changeNumberType(type, data) {
      if (type === 2) {
        this.selectLabel.map(item => {
          if (data === item.value) {
            this.searchNameObj = item;
          }
        });
      }
      this.changeFormGetTable();
    },
    // 下载发票
    downloadInvoice() {
      this.validInvoice = [];
      if (this.selectData.length === 0) {
        this.$message.warning(this.$t('GlobalSetObj.PleaseSelectAnInvoice'));
      } else {
        this.selectData.map(item => {
          if (item.invoiceStatus === 2) {
            this.validInvoice.push(item);
          }
        });
        console.log(this.validInvoice, 'this.validInvoice');
        this.showExportTips = true;
      }
    },
    // 确定下载发票
    determineDownloadInvoice() {
      const downloadArr = [];
      this.validInvoice.map((item) => {
        if (item.invoiceUrl) {
          downloadArr.push(item.invoiceUrl);
        }
      });
      console.log(downloadArr, '==downloadArr');
      if (downloadArr.length > 0) {
        // 手动显示加载中动画
        const loading = this.$loading({
          fullscreen: true,
          lock: true,
          spinner: 'el-icon-loading',
          text: this.$i18n.t('orderCenterCont.DesperatelyLoading'), // '拼命加载中',
          background: 'rgba(250, 250, 250, 0.6)'
        });
        batchPdfDownload(downloadArr).then((res) => {
          const dateType = setDateTime2();
          const saveName = `${dateType}` + this.$i18n.t('orderCenterCont.FaceSheet');
          saveAs(res, saveName);
          // const a = document.createElement('a');
          // a.download = `${dateType}`;
          // a.href = res;
          // a.download = `${dateType}` + this.$i18n.t('orderCenterCont.FaceSheet');
          // a.click();
          // a.remove();
        }).finally(() => {
          loading.close();
          this.showExportTips = false;
        });
      } else {
        this.showExportTips = false;
      }
    },
    // 判断两个时间是否相差24小时；
    getDiff(fromTime, toTime) {
      // 时间转化；
      const a1 = new Date(fromTime);
      const brazilTime = toTime.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
      const a2 = new Date(brazilTime).getTime();
      // 判断时间差;
      const total = (a2 - a1.getTime()) / 1000;// 相差的秒数;
      const endTime = parseInt(total / (60 * 60));// 计算是否超过24小时;
      console.log(endTime, '==endTime');
      return endTime <= 24;
    },
    // 取消发票
    cancelInvoice(row) {
      if (row) {
        this.cancelAll = [];
        if (this.getDiff(row.invoiceIssueTime, new Date())) {
          this.cancelAll.push(row);
        }
      }
      if (this.cancelAll.length > 0) {
        this.showCancelInvoiceTips = true;
      } else {
        this.$alert(this.$t('GlobalSetObj.SelectedInvoicesMoreThan24'), this.$t('GlobalSetObj.tips'), {
          confirmButtonText: this.$t('GlobalSetObj.determine'),
          type: 'warning',
          callback: action => {
            this.showCancelInvoiceTips = false;
          }
        });
      }
    },
    // 重新生成
    regenerate(row) {
      console.log(row, '重新生成');
      rebuild(row).then(res => {
        console.log(res, '重新生成');
        if (res.status === 'OK') {
          this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
          this.changeFormGetTable();
        }
      });
    },
    // 确定取消发票
    determineCancelInvoice() {
      apiInvoiceCancel(this.cancelAll).then(res => {
        if (res.status === 'OK') {
          this.showCancelInvoiceTips = false;
          this.showCancelInvoiceProgress = true;
          this.widthProgress = 0;
          if (this.errTableData.length > 5) {
            this.tableHeight = 300;
          }
          this.errTableData = res.data.failList;
          this.problemPieceReturn.failureNumber = res.data.failRequest;
          this.problemPieceReturn.successNumber = res.data.successRequest;
          const time = setInterval(() => {
            if (this.widthProgress < 100) {
              this.widthProgress++;
            }
            if (this.widthProgress >= 100) {
              clearInterval(time);
            }
          }, 50);
        }
      });
    },
    // 分页
    getCurrentTableData(val) {
      this.queryForm.pageNumber = val;
      this.changeFormGetTable();
    },
    getSizeTableData(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.changeFormGetTable();
    }
  }
};
</script>

<style scoped>
.searchBox {
  padding: 10px 20px 0 20px;
}

/*.NumberClass ::v-deep .el-form-item__content {*/
/*  margin-left: 0 !important;*/
/*}*/

.selectType ::v-deep.el-input--suffix .el-input__inner {
  border: none !important;
  font-weight: bold;
  font-size: 14px;
  padding: 0;
  text-align: left;
}

.btnIcon {
  padding: 6px;
}

.searchOrder {
  display: flex;
  margin-left: -15px;
}

.searchOrder .el-popover__reference-wrapper .btnIcon {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 40px;
}

.container >>> .searchOrder .el-input__inner {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.container >>> .searchOrder .el-input-group__prepend {
  background-color: transparent !important;
}

.chaxunBtn {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  width: 40px;
  height: 40px;
  padding: 7px;
}

.searchNum {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 15px;
  height: 28px;
}

.searchNumWarp {
  max-width: 60%;
  height: 28px;
  border: 1px solid #91B8FA;
  border-right: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 5px 5px 5px 5px;
  background: rgba(145, 184, 250, 0.2);
}

.searchNumWarp p {
  margin: 0;
}

.clearSearchNum {
  height: 28px;
  border: 1px solid #91B8FA;
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: rgba(145, 184, 250, 0.2);
  cursor: pointer;
  padding-right: 5px;
  padding-top: 5px;
}

.wordBreak {
  word-break: break-all; /*word-break: normal|break-all|keep-all;在恰当的断字点进行换行,break-all 允许在单词内换行,keep-all 只能在半角空格或连字符处换行,normal 使用浏览器默认的换行规则。*/
  text-overflow: ellipsis; /*溢出文字显示省略号*/
  display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 1; /** 显示的行数 **/
  overflow: hidden; /** 隐藏超出的内容 **/
}

.container >>> .el-form--label-top .el-form-item__label {
  width: 100% !important;
}

.searchBox .el-form-item {
  margin-bottom: 6px;
}

.QuestionMark {
  text-align: center;
  cursor: pointer;
}

.WaybillNumber {
  color: #409eff;
  cursor: pointer;
  padding: 0;
}

.container >>> .errTable .el-table thead > tr > th {
  background-color: #eee !important;
  color: #666;
}

.container >>> .errTable .el-table__header * {
  border-color: #ddd !important;
}
</style>
