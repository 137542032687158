import { addResizeListener, removeResizeListener } from 'element-ui/src/utils/resize-event';
import { debounce } from 'lodash-es';

export default {
  data() {
    return {
      tableHeightRow: {
        height: 0,
        bottomOffset: 85 // 底部分页的高度
      }
    };
  },

  created() {
    this.resize = debounce(() => {
      this.doResize();
    }, 500);

    // this.resize();
  },

  methods: {
    doResize() {
      const el = document.querySelector('.table-height');

      if (!el) {
        throw new Error('类目 table-height 未找到');
      }

      // 获取调用传递过来的数据
      const { bottomOffset } = this.tableHeightRow;
      // 获取距底部距离（用于展示页码等信息）
      const customHeight = bottomOffset || 60;
      // 计算列表高度
      const height = window.innerHeight - el.getBoundingClientRect().top - customHeight;

      // 设置高度
      // return height;
      this.tableHeightRow.height = (height <= 200) ? 200 : height;
    }
  },

  mounted() {
    this.resize();
    addResizeListener(document.body, this.resize);
  },

  beforeDestroy() {
    removeResizeListener(document.body, this.resize);
  }
};
