var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "wrapper-form-search-trailer",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.handleNextStep($event)
        },
      },
    },
    [
      _c(
        "o-field",
        {
          staticClass: "col-span-2",
          attrs: { label: _vm.$t("order.trailerPlate") },
        },
        [
          _c(
            "o-autocomplete",
            {
              attrs: {
                rounded: "",
                expanded: "",
                data: _vm.listTrailer,
                placeholder: _vm.$t("order.trailerPlatePlaceholder"),
                clearable: "",
                field: "trailer_license_plate",
              },
              on: {
                input: _vm.filteredDataTrailers,
                select: _vm.handleSelectedTrailer,
              },
              model: {
                value: _vm.formData.trailerPlate,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "trailerPlate", $$v)
                },
                expression: "formData.trailerPlate",
              },
            },
            [_c("template", { slot: "empty" }, [_vm._v("No results found")])],
            2
          ),
        ],
        1
      ),
      _c(
        "o-field",
        {
          staticClass: "col-span-2",
          attrs: { label: _vm.$t("order.document") },
        },
        [
          _c("o-input", {
            attrs: { disabled: "" },
            model: {
              value: _vm.trailerData.document,
              callback: function ($$v) {
                _vm.$set(_vm.trailerData, "document", $$v)
              },
              expression: "trailerData.document",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        { attrs: { label: _vm.$t("order.capacityM3") } },
        [
          _c("o-input", {
            attrs: { disabled: "" },
            model: {
              value: _vm.trailerData.capacityM3,
              callback: function ($$v) {
                _vm.$set(_vm.trailerData, "capacityM3", $$v)
              },
              expression: "trailerData.capacityM3",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        { attrs: { label: _vm.$t("order.capacityKg") } },
        [
          _c("o-input", {
            attrs: { disabled: "" },
            model: {
              value: _vm.trailerData.capacityKG,
              callback: function ($$v) {
                _vm.$set(_vm.trailerData, "capacityKG", $$v)
              },
              expression: "trailerData.capacityKG",
            },
          }),
        ],
        1
      ),
      _c(
        "FormActions",
        [
          _c("FormActionsPrevious", {
            attrs: { "is-first-step": _vm.isFirstStep },
            on: { handlePrevious: _vm.handlePrevious },
          }),
          _c("FormActionsNext", { attrs: { "is-last-step": _vm.isLastStep } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }