<template>
  <!-- md滞留表 -->
  <div class="Box">
    <el-card class="box-card">
      <div class="timeClass">
        <div v-if="activeName === 'hz'" class="allExportClass" @click="allExport">{{ $t('collectionCenter.allExport') }}</div>
        <div v-if="activeName === 'mx'" class="allExportClass" @click="exportDetail">{{ $t('collectionCenter.明细导出') }}</div><!--导出明细-->
        <div>
          <!-- 更新时间 -->
          <span v-show="updateTime">{{ $t('GlobalSetObj.UpdateTime') }}：{{ updateTime }}</span>
        </div>
        <div class="dateBox">
          <span :class="{'d1': type == 0}" @click="getData(0)">{{ $t('collectionCenter.yesterday') }}</span><!-- 昨日 -->
          <span :class="{'d1': type == 1}" @click="getData(1)">{{ $t('collectionCenter.sameDay') }}</span><!-- 当天 -->
          <span :class="{'d1': type == 2}" @click="getData(2)">{{ $t('collectionCenter.nearlyAWeek') }}</span><!-- 近一周 -->
          <span :class="{'d1': type == 3}" @click="getData(3)">{{ $t('collectionCenter.sameMonth') }}</span><!-- 当月 -->
        </div>
      </div>
      <el-tabs v-model="activeName">
        <el-tab-pane :label="tabName + $t('collectionCenter.summary')" name="hz">
          <div class="tableClass">
            <el-table
              :data="tableData1"
              border
              class="t1"
            >
              <el-table-column
                prop="collectTotalNum"
                :label="$t('collectionCenter.MDScanVolume')"
              ><!-- MD扫描总量 -->
                <template slot-scope="scope">
                  <div @click="toMX(scope.row, 1)" style="font-size: 20px;font-weight: bold;color:#3972BA;" class="skipClass">
                    {{ scope.row.collectTotalNum }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="collectLoadTotalNum"
                :label="$t('collectionCenter.loadCapacity')"
              ><!-- 已装车量 -->
                <template slot-scope="scope">
                  <div @click="toMX(scope.row, 2)" style="font-size: 20px;font-weight: bold;color:#22B956;" class="skipClass">
                    {{ scope.row.collectLoadTotalNum }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="pendingLoadTotalNum"
                :label="$t('collectionCenter.stayTruckload')"
              ><!-- 待装车量 -->
                <template slot-scope="scope">
                  <div @click="toMX(scope.row, 3)" style="font-size: 20px;font-weight: bold;color:#EF9F03;" class="skipClass">
                    {{ scope.row.pendingLoadTotalNum }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="collectLoadRate"
                :label="$t('collectionCenter.loadingRate')"
              ><!-- 装车率 -->
                <template slot-scope="scope">
                  <div style="font-size: 20px;font-weight: bold;color:#22B956;">{{ scope.row.collectLoadRate }}%</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="pendingLoadRate"
                :label="$t('collectionCenter.stayLoadRate')"
              ><!-- 待装车率 -->
                <template slot-scope="scope">
                  <div style="font-size: 20px;font-weight: bold;color:#F23237;">{{ scope.row.pendingLoadRate }}%</div>
                </template>
              </el-table-column>
            </el-table>
            <el-table
              :data="tableData2"
              :max-height="600"
              border
              class="t2"
            >
              <el-table-column
                prop="siteName"
                :label="$t('collectionCenter.MDName')"
              ><!-- MD名称 -->
                <template slot-scope="scope">
                  <div class="clickClass">{{ scope.row.siteName }}</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="collectLoadNum"
                :label="$t('collectionCenter.loadCapacity')"
              ><!-- 已装车量 -->
                <template slot-scope="scope">
                  <div @click="toMX(scope.row, 4)" class="skipClass">{{ scope.row.collectLoadNum }}</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="estimatedLoadNum"
                :label="$t('collectionCenter.predictTruckload')"
              ><!-- 预计装车量 -->
                <template slot-scope="scope">
                  <div @click="toMX(scope.row, 5)" class="skipClass pC">
<!--                    <div :style="{ width: scope.row.estimatedLoadNum + '%' }" class="progressClass" />-->
<!--                    <span class="yujiClass">{{ scope.row.estimatedLoadNum }}</span>-->
                    <span>{{ scope.row.estimatedLoadNum }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="collectLoadScanNum"
                :label="$t('collectionCenter.loadedScansNumber')"
              ><!-- 已装车扫描量 -->
                <template slot-scope="scope">
                  <div @click="toMX(scope.row, 6)" class="skipClass">{{ scope.row.collectLoadScanNum }}</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="loadScanRate"
                :label="$t('collectionCenter.loadingScanRate')"
              ><!-- 装车扫描率 -->
                <template slot-scope="scope">
                  <div>{{ scope.row.loadScanRate }}%</div>
                </template>
              </el-table-column>
            </el-table>
            <div class="right">
              <el-pagination
                layout="total, sizes, prev, pager, next, jumper"
                :current-page.sync="form2.pageNumber"
                :page-sizes="[10, 20, 50, 100, 500, 1000]"
                :page-size.sync="form2.pageSize"
                :total="total2"
                background
                style="margin: 10px 0"
                @current-change="getDatePage"
                @size-change="getPageSize"
              />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane v-if="activeName == 'mx'" :label="tabName + $t('collectionCenter.particulars')" name="mx">
          <el-table
            :data="tableDataMX"
            :max-height="600"
            border
            class="t2"
          >
            <el-table-column
              prop="scanNumber"
              :label="$t('collectionCenter.Barcode')"
            /><!-- 条形码 -->
            <el-table-column
              prop="bagNumber"
              :label="$t('collectionCenter.BagNumber')"
            /><!-- 袋号 -->
            <el-table-column
              prop="collectTime"
              :label="$t('collectionCenter.lanshouT')"
            /><!-- 揽收时间 -->
            <el-table-column
              prop="collectUserName"
              :label="$t('collectionCenter.LanShouPeople')"
            /><!-- 揽收人 -->
            <el-table-column
              prop="bagTime"
              :label="$t('collectionCenter.sealingBagTime')"
            /><!-- 封袋时间 -->
            <el-table-column
              prop="bagUserName"
              :label="$t('collectionCenter.BagSealer')"
            /><!-- 封袋人 -->
            <el-table-column
              prop="siteLoadTime"
              :label="$t('collectionCenter.networkLoadingTime')"
            /><!-- 网点装车时间 -->
            <el-table-column
              prop="siteLoadSiteName"
              :label="$t('customerManagements.loadSiteIdList')"
            /><!-- 装车网点 -->
            <el-table-column
              prop="siteLoadUserName"
              :label="$t('collectionCenter.networkLoader')"
            /><!-- 网点装车人 -->
            <el-table-column
              prop="siteLoadVehicleNumber"
              :label="$t('collectionCenter.licensePlateNumber')"
            /><!-- 车牌号 -->
          </el-table>
          <div class="right">
            <el-pagination
              layout="total, sizes, prev, pager, next, jumper"
              :current-page.sync="formMX.pageNumber"
              :page-sizes="[10, 20, 50, 100, 500, 1000]"
              :page-size.sync="formMX.pageSize"
              :total="totalMX"
              background
              style="margin: 10px 0"
              @current-change="getDatePageMX"
              @size-change="getPageSizeMX"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import {
  getMdStaySummaryData,
  getMdStayDetailDataPage,
  getMdStayOrderDataPage,
  mdStayExportAll,
  mdStayExportDetail
} from '@/api/lanshou';
import { goExportList } from '@/utils/goExportList.js';

export default {
  name: 'Xxxxx',
  data() {
    return {
      activeName: 'hz',
      updateTime: '',
      type: 1,
      timeForm: {
        startTime: '',
        endTime: '',
        mdStayType: ''
      },
      tableData1: [],
      tableData2: [],
      total2: 0,
      form2: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: '',
          endTime: '',
          mdStayType: null
        }
      },
      formMX: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: '',
          endTime: '',
          siteId: '',
          mdStayType: '',
          mdStayOrderType: '',
          latestBatchTime: ''
        }
      },
      totalMX: 0,
      tableDataMX: [],
      tabName: ''
    };
  },
  created() {
    this.type = 1;
    this.getData(1);
  },
  activated() {
    this.activeName = 'hz';
    if (!this.$route.query.mdStayType) {
      this.tabName = '';
      this.timeForm.mdStayType = null;
      console.log('没有入参');
      this.type = 1;
      this.getData(1);
    }

    // 考虑到从‘揽收营运数据’进入页面时携带参数
    if (this.$route.query.mdStayType) {
      // 回显到选定的时间范围按钮
      console.log('有入参');
      const { t1, t2, mdStayType } = this.$route.query;
      const tabNameType = mdStayType === 1 ? 'SP-MD' : mdStayType === 2 ? 'Franca-MD' : mdStayType === 3 ? 'Jau-MD' : mdStayType === 4 ? 'RJ-MD' : mdStayType === 5 ? 'MG-MD' : '';
      this.tabName = tabNameType ? tabNameType + this.$t('collectionCenter.retention') : '';
      this.timeForm = {
        startTime: t1,
        endTime: t2,
        mdStayType: mdStayType
      };
      this.whatTimeType(t1, t2);
      this.getData(this.type);
    }
  },
  methods: {
    whatTimeType(t1, t2) {
      const yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
      const today = dayjs().format('YYYY-MM-DD');
      const week = dayjs().subtract(6, 'day').format('YYYY-MM-DD');
      const month = dayjs().startOf('month').format('YYYY-MM-DD');
      if (t1 === today && t2 === today) {
        this.type = 1;
      } else if (t1 === week && t2 === today) {
        this.type = 2;
      } else if (t1 === month && t2 === today) {
        this.type = 3;
      } else if (t1 === yesterday && t2 === yesterday) {
        this.type = 0;
      } else {
        this.type = null;
      }
    },
    timeFn(val) {
      let today = '';
      let date = '';
      let timeForm = {};
      today = dayjs().format('YYYY-MM-DD');
      if (val === 0) {
        date = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
        timeForm = {
          startTime: date,
          endTime: date
        };
      }
      if (val === 1) {
        timeForm = {
          startTime: today,
          endTime: today
        };
      }
      if (val === 2) {
        date = dayjs().subtract(6, 'day').format('YYYY-MM-DD');
        timeForm = {
          startTime: date,
          endTime: today
        };
      }
      if (val === 3) {
        date = dayjs().startOf('month').format('YYYY-MM-DD');
        timeForm = {
          startTime: date,
          endTime: today
        };
      }
      return timeForm;
    },
    getData(val) {
      this.type = val;
      const time = this.timeFn(val);
      if (!this.$route.query.mdStayType) {
        this.timeForm = {
          startTime: time.startTime,
          endTime: time.endTime,
          mdStayType: this.timeForm.mdStayType || null
        };
      } else {
        const { t1, t2, mdStayType } = this.$route.query;
        this.timeForm = {
          startTime: t1,
          endTime: t2,
          mdStayType: mdStayType
        };
      }
      getMdStaySummaryData(this.timeForm).then(res => {
        this.tableData1 = [];
        if (res.status === 'OK') {
          this.tableData1.push(res.data);
          this.updateTime = res.data.createTime;
          // 查询完后清空掉传值，避免切换页面重进时查询参数错误
          this.$router.push({
            path: this.$route.path,
            query: {}
          });
        }
      }).catch();
      this.getData2();
    },
    getData2() {
      this.form2 = {
        pageNumber: this.form2.pageNumber,
        pageSize: this.form2.pageSize,
        params: {
          startTime: this.timeForm.startTime,
          endTime: this.timeForm.endTime,
          mdStayType: this.timeForm.mdStayType
        }
      };
      getMdStayDetailDataPage(this.form2).then(res => {
        this.tableData2 = [];
        if (res.status === 'OK') {
          this.total2 = res.data.total;
          this.tableData2 = res.data.records;
        }
      }).catch();
    },
    getDatePage() {
      getMdStayDetailDataPage(this.form2).then(res => {
        this.tableData2 = [];
        if (res.status === 'OK') {
          this.total2 = res.data.total;
          this.tableData2 = res.data.records;
        }
      }).catch();
    },
    getPageSize() {
      this.form2.pageNumber = 1;
      getMdStayDetailDataPage(this.form2).then(res => {
        this.tableData2 = [];
        if (res.status === 'OK') {
          this.total2 = res.data.total;
          this.tableData2 = res.data.records;
        }
      }).catch();
    },
    toMX(row, type) {
      this.formMX = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: this.timeForm.startTime,
          endTime: this.timeForm.endTime,
          siteId: row.siteId || null,
          mdStayType: this.timeForm.mdStayType || null,
          mdStayOrderType: type,
          latestBatchTime: this.type === 1 ? this.updateTime : null
        }
      };
      this.getDatePageMX();
      this.activeName = 'mx';
    },
    getDatePageMX() {
      getMdStayOrderDataPage(this.formMX).then(res => {
        this.tableDataMX = [];
        if (res.status === 'OK') {
          this.totalMX = res.data.total;
          this.tableDataMX = res.data.records;
        }
      }).catch();
    },
    getPageSizeMX() {
      this.formMX.pageNumber = 1;
      getMdStayOrderDataPage(this.formMX).then(res => {
        this.tableDataMX = [];
        if (res.status === 'OK') {
          this.totalMX = res.data.total;
          this.tableDataMX = res.data.records;
        }
      }).catch();
    },
    // 导出明细
    exportDetail() {
      mdStayExportDetail(this.formMX.params).then(res => {
        if (res.status === 'OK') {
          goExportList(this, '/resource/exportTaskList');
        }
      });
    },
    allExport() {
      const formE = {
        startTime: this.timeForm.startTime,
        endTime: this.timeForm.endTime,
        mdStayType: this.timeForm.mdStayType
      };
      mdStayExportAll(formE).then(res => {
        if (res.status === 'OK') {
          goExportList(this);
          // const h = this.$createElement;
          // this.$msgbox({
          //   // '提示'
          //   title: this.$t('collectionCenter.tips'),
          //   message: h('p', null, [
          //     // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源任务列表中下载，'
          //     h('span', this.$t('collectionCenter.exportChangeTip')),
          //     // '请勿重复导出！'
          //     h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
          //   ]),
          //   showCancelButton: true,
          //   // '立即前往'
          //   confirmButtonText: this.$t('collectionCenter.Immediately'),
          //   // '关闭'
          //   cancelButtonText: this.$t('collectionCenter.close')
          // }).then(action => {
          //   if (action === 'confirm') {
          //     this.$router.push({ path: '/resource/exportTaskList' });
          //   }
          // }).catch(() => {
          // });
        }
      }).catch(() => {});
    }
  }
};
</script>

<style lang="scss" scoped>

.skipClass.pC {
  position: relative;
  padding: 1px;
  box-sizing: border-box;
}
.yujiClass{
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
}
.skipClass{
  display: block;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border: 1px solid transparent;
}
.skipClass:hover{
  border: 1px solid #0080fc;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
}
.clickClass:hover{
  cursor: pointer;
}
.allExportClass{
  cursor: pointer;
  background: #019a39;
  color: #fff;
  font-size: 13px !important;
  padding: 4px 10px;
  border-radius: 5px;
  box-sizing: border-box;
  position: absolute;
  left: 0;
}
.Box{
  padding: 20px;
  box-sizing: border-box;
}
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.dateBox{
  display: flex; justify-content: flex-start; align-items: center;
  margin-left: 5px;
  span{display: inline-block;border: 1px solid #5EBC7F;padding: 0 15px;box-sizing: border-box;cursor: pointer; text-align: center;color: #5EBC7F;}
  span:hover, span.d1:hover{background: #51d96b;color: #fff;}
  span.d1:hover{border: 1px solid #51D96B;box-sizing: border-box;}
  span.d1{background: linear-gradient(90deg, #51D96B, transparent);box-sizing: border-box;color: #009C37}
}
.timeClass{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  div{font-size: 17px;}
}
.tableClass ::v-deep th{
  background: #EDF7F0;
  color: #000;
}
.tableClass .t1 ::v-deep th{
  font-size: 16px;
}
.tableClass .t2 ::v-deep th{
  font-size: 15px;
}
.tableClass .t2 ::v-deep .el-table__cell{
  padding: 8px 0 !important;
}
.tableClass .t2 ::v-deep td.el-table__cell{
  padding: 2px 0 !important;
  height: 35px;
}
.tableClass .t2 .progressClass{
  background: linear-gradient(90deg, #F4E7D0 0%, #EF9F03 100%);
  height: 31px !important;
  line-height: 31px !important;
}
.tableClass .t2 ::v-deep td.el-table__cell div{
  height: inherit;
  line-height: 35px;
  padding-left: 0 !important;
  text-indent: 10px;
}
</style>
