<script >
import { useVuelidate } from '@vuelidate/core';
import { ref, defineProps, onMounted } from '@vue/composition-api';
import { default as FormPersonalData } from '../../../../driver/components/form/steps/formPersonalData';
import { default as FormAddress } from '@/components/steps/formAddress';
import { FormActions, FormActionsNext, FormActionsPrevious } from '@/components/wizard-form/formStepActions';
import { driverService } from '@/api/transport/driver';
import getMessageError from '@/utils/getMessageError';
import { required } from '@/utils/validationErrors';
import { toast } from '@/utils/programaticToast';
import { getToastContentComponent } from '@/utils/getToastContentComponent';
const __sfc_main = {};
__sfc_main.props = {
  formData: {
    type: Object
  },
  handleNext: {
    type: Function
  },
  isLastStep: {
    type: Boolean
  },
  isFirstStep: {
    type: Boolean
  },
  handlePrevious: {
    type: Function
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const rules = {
    document: {
      required
    },
    username: {
      required
    },
    name: {
      required
    }
  };
  const validateSearchForm = useVuelidate(rules, props.formData);
  const activeTabsDriver = ref(0);

  /* search driver */
  const listNames = ref([]);
  async function filteredNames() {
    try {
      const params = {
        name: props.formData.name
      };
      const res = await driverService.getAll(params);
      listNames.value = res.data.result;
    } catch (error) {
      listNames.value = [];
    }
  }
  function selectedNames(selectedValue) {
    props.formData.document = selectedValue ? selectedValue.driver_document : '';
    props.formData.username = selectedValue ? selectedValue.driver_username : '';
  }
  const listHelpers = ref([]);
  async function filteredDataUsernameHelpers(realName) {
    try {
      const res = await driverService.getAllByRealName(realName);
      listHelpers.value = res.data.users.map(item => item.username);
    } catch (error) {
      listHelpers.value = [];
    }
  }
  onMounted(async () => {
    try {
      if (props.formData.document) {
        const res = await driverService.getAll({
          document: props.formData.document
        });
        // eslint-disable-next-line require-atomic-updates
        props.formData.name = res.data.result[0].driver_name;
        // eslint-disable-next-line require-atomic-updates
        props.formData.username = res.data.result[0].driver_username;
      }
    } catch (error) {
      // eslint-disable-next-line require-atomic-updates
      props.formData.name = null;
      // eslint-disable-next-line require-atomic-updates
      props.formData.username = null;
      // eslint-disable-next-line require-atomic-updates
      props.formData.document = null;
    }
  });

  /* create driver */
  function defaultValueFormDriver() {
    return {
      formStep01: {
        name: null,
        document: null,
        phone: null,
        driverLicense: null,
        scoreLicense: null,
        scoreDriver: null,
        licenseType: null,
        licenseValidate: null,
        providerName: null,
        username: null,
        outsourced: false
      },
      formStep02: {
        zipcode: null,
        street: null,
        number: null,
        neighborhood: null,
        municipality: null,
        state: null,
        complement: null
      }
    };
  }
  const isOpenCollapse = ref(0);
  const formDataDriver = ref(defaultValueFormDriver());
  async function nextCollapseItem() {
    try {
      if (isOpenCollapse.value < 1) {
        isOpenCollapse.value = isOpenCollapse.value + 1;
      } else {
        const newDriver = {
          ...formDataDriver.value.formStep01,
          ...formDataDriver.value.formStep02
        };
        const res = await driverService.create(newDriver);
        props.formData.document = res.data.driver_document;
        props.formData.name = res.data.driver_name;
        props.formData.username = res.data.driver_username;
        // eslint-disable-next-line require-atomic-updates
        formDataDriver.value = defaultValueFormDriver();
        // eslint-disable-next-line require-atomic-updates
        isOpenCollapse.value = 0;
        activeTabsDriver.value = 0;
      }
    } catch (error) {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Ocorreu um erro',
          describe: getMessageError(error),
          typeIcon: 'error'
        }),
        closable: true,
        duration: 4000
      });
    }
  }
  async function previousCollapseItem() {
    isOpenCollapse.value = isOpenCollapse.value - 1;
  }

  /* next step*/
  async function handleSubmit() {
    const isDocumentValid = await validateSearchForm.value.document.$validate();
    const isNameValid = await validateSearchForm.value.name.$validate();
    const isUsernameValid = await validateSearchForm.value.username.$validate();
    console.log(validateSearchForm.value);
    if (isDocumentValid && isNameValid && isUsernameValid) {
      props.handleNext();
    }
  }
  return {
    props,
    validateSearchForm,
    activeTabsDriver,
    listNames,
    filteredNames,
    selectedNames,
    listHelpers,
    filteredDataUsernameHelpers,
    isOpenCollapse,
    formDataDriver,
    nextCollapseItem,
    previousCollapseItem,
    handleSubmit
  };
};
__sfc_main.components = Object.assign({
  FormActions,
  FormActionsPrevious,
  FormActionsNext,
  FormPersonalData,
  FormAddress
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <o-tabs v-model="activeTabsDriver" :multiline="false">
      <o-tab-item :value="0" :label="$t('routes.searchDriverTitle')">
        <form class="wrapper-form-search-driver" @submit.prevent="handleSubmit">
          <o-field
            :message="validateSearchForm.name.$errors.length ? validateSearchForm.name.$errors[0].$message : null"
            :label="$t('routes.nameLabel')"
            label-for="name"
            class="col-span-2"
          >
            <o-autocomplete
              id="name"
              v-model="formData.name"
              :placeholder="$t('routes.namePlaceholder')"
              clearable
              expanded
              field="driver_name"
              :data="listNames"
              @input="filteredNames"
              @select="selectedNames"
            >
              <template slot="empty">Sem resultados</template>
            </o-autocomplete>
          </o-field>

          <o-field
            :label="$t('routes.documentLabel')"
            :message="validateSearchForm.document.$errors.length ? validateSearchForm.document.$errors[0].$message : null"
          >
            <o-input v-model="formData.document" disabled />
          </o-field>

          <o-field
            :label="$t('routes.usernameLabel')"
            :message="validateSearchForm.username.$errors.length ? validateSearchForm.username.$errors[0].$message : null"
          >
            <o-input v-model="formData.username" disabled />
          </o-field>

          <o-field :label="$t('routes.helpersLabel')" class="col-span-2">
            <o-inputitems
              ref="inputHelpers"
              v-model="formData.helpers"
              :placeholder="$t('routes.helpersPlaceholder')"
              :data="listHelpers"
              :allow-new="false"
              :open-on-focus="false"
              :autocomplete="true"
              :allow-autocomplete="true"
              @typing="filteredDataUsernameHelpers"
            >
              <template #selected="{ items }">
                <span v-for="(item, index) in items" :key="index" class="item_selected" rounded @click="$refs.inputHelpers.removeItem(index, $event)">
                  {{ item }}
                  <div style="margin-left: 5px;cursor: pointer;" @click="$refs.inputHelpers.removeItem(index, $event)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
                  </div>
                </span>
              </template>
            </o-inputitems>
          </o-field>

          <FormActions>
            <FormActionsPrevious
              :is-first-step="isFirstStep"
              @handlePrevious="handlePrevious"
            />

            <FormActionsNext
              :is-last-step="isLastStep"
            />
          </FormActions>
        </form>
      </o-tab-item>

      <o-tab-item :value="1" :label="$t('routes.createDriverTitle')">
        <o-collapse
          :open="isOpenCollapse == 0"
          class="content-separator"
          @open="isOpenCollapse = 0"
        >
          <template #trigger="props">
            <div
              role="button"
              aria-controls="contentIdForA11y5-formDataUser"
              :aria-expanded="isOpenCollapse"
              class="wrapper-tab-item"
            >
              <p class="step-name">
                {{ $t('routes.dataDriver') }}
              </p>
              <div>
                <o-icon :icon="props.open ? 'caret-up' : 'caret-down'" />
              </div>
            </div>
          </template>
          <div class="wrapper-form-step">
            <FormPersonalData
              :handle-next="nextCollapseItem"
              :handle-previous="previousCollapseItem"
              :is-last-step="false"
              :is-first-step="true"
              :form-data="formDataDriver.formStep01"
            />
          </div>
        </o-collapse>

        <o-collapse
          :open="isOpenCollapse == 1"
          class="content-separator"
          @open="isOpenCollapse = 1"
        >
          <template #trigger="props">
            <div
              role="button"
              aria-controls="contentIdForA11y5-formDataAddress"
              :aria-expanded="isOpenCollapse"
              class="wrapper-tab-item"
            >
              <p class="step-name">
                {{ $t('routes.dataAddress') }}
              </p>
              <div>
                <o-icon :icon="props.open ? 'caret-up' : 'caret-down'" />
              </div>
            </div>
          </template>
          <div class="wrapper-form-step">
            <FormAddress
              :handle-next="nextCollapseItem"
              :handle-previous="previousCollapseItem"
              :is-last-step="true"
              :is-first-step="false"
              :form-data.sync="formDataDriver.formStep02"
            />
          </div>
        </o-collapse>
      </o-tab-item>
    </o-tabs>
  </div>
</template>

<style scoped>
  .wrapper-form-search-driver {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.5rem/* 24px */;
  }

  .col-span-2 {
    grid-column: span 2 / span 2;
  }

  .wrapper-buttons-form-search-driver {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem/* 32px */;
  }

  .content-separator {
    border-bottom-width: 1px;
    padding-bottom: 1rem/* 16px */;
    margin-bottom: 1rem/* 16px */;
  }

  .wrapper-tab-item {
    display: flex;
    justify-content: space-between;
  }

  .step-name {
    font-weight: 700;
    font-size: 0.875rem/* 14px */;
    line-height: 1.25rem/* 20px */;
  }

  .wrapper-form-step {
    height: 20rem/* 320px */;
    overflow-y: scroll;
    padding-left: 0.5rem/* 8px */;
    padding-right: 0.5rem/* 8px */;
  }

</style>
