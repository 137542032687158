import request from '@/utils/request';

// 银行付款
export const apiSitePayablePay = (data, isLoading = true) => {
  return request({
    method: 'POST',
    url: '/fa/sitePayable/pay',
    data,
    isLoading // 是否显示加载中参数
  });
};

// 查询结果
export const apiSitePayableQuirePay = (data, isLoading = true) => {
  return request({
    method: 'POST',
    url: '/fa/sitePayable/quirePay',
    data,
    isLoading // 是否显示加载中参数
  });
};
