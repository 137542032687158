var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "qMBox" },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c(
            "el-form",
            { staticClass: "findClass" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 10 } },
                    [
                      _c(
                        "el-row",
                        { attrs: { type: "flex", justify: "flex-start" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "collectionCenter.effectiveStartTime"
                                ),
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "block" },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "datetime",
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      placeholder: _vm.$t(
                                        "collectionCenter.StartTime"
                                      ),
                                      clearable: false,
                                    },
                                    model: {
                                      value: _vm.queryForm.params.startTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryForm.params,
                                          "startTime",
                                          $$v
                                        )
                                      },
                                      expression: "queryForm.params.startTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.CustomerN"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              on: { focus: _vm.getAllCustomer },
                              model: {
                                value: _vm.queryForm.params.customerId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "customerId",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.customerId",
                              },
                            },
                            _vm._l(_vm.customerIdList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.username, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.billingMode"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.queryForm.params.chargePattern,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "chargePattern",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.chargePattern",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("collectionCenter.distance"),
                                  value: 0,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("collectionCenter.ballot"),
                                  value: 1,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("collectionCenter.Weight"),
                                  value: 2,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.businessT") + "：",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.queryForm.params.businessType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "businessType",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.businessType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("collectionCenter.dropOff"),
                                  value: 1,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("collectionCenter.toCollect"),
                                  value: 2,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.belongDot"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              on: { focus: _vm.getNewestSite },
                              model: {
                                value: _vm.queryForm.params.belongToSiteId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "belongToSiteId",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.belongToSiteId",
                              },
                            },
                            _vm._l(_vm.siteIdList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:finance:quotationMaintenance:search",
                          expression:
                            "'btn:collection-center:finance:quotationMaintenance:search'",
                          arg: "remove",
                        },
                      ],
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-search",
                        disabled: _vm.btnDisablad.searBtn,
                      },
                      on: { click: _vm.searchClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.search")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:finance:quotationMaintenance:reset",
                          expression:
                            "'btn:collection-center:finance:quotationMaintenance:reset'",
                          arg: "remove",
                        },
                      ],
                      attrs: {
                        icon: "el-icon-refresh",
                        size: "mini",
                        disabled: _vm.btnDisablad.resBtn,
                      },
                      on: { click: _vm.resetClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:finance:quotationMaintenance:trialCalculation",
                          expression:
                            "'btn:collection-center:finance:quotationMaintenance:trialCalculation'",
                          arg: "remove",
                        },
                      ],
                      attrs: { size: "mini", disabled: _vm.btnDisablad.tryBtn },
                      on: { click: _vm.tryCalculationClick },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("collectionCenter.trialCalculation"))
                      ),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:finance:quotationMaintenance:Delete",
                          expression:
                            "'btn:collection-center:finance:quotationMaintenance:Delete'",
                          arg: "remove",
                        },
                      ],
                      attrs: {
                        type: "danger",
                        size: "mini",
                        disabled: _vm.btnDisablad.delBtn,
                      },
                      on: { click: _vm.delClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.Delete")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:finance:quotationMaintenance:add",
                          expression:
                            "'btn:collection-center:finance:quotationMaintenance:add'",
                          arg: "remove",
                        },
                      ],
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-plus",
                        disabled: _vm.btnDisablad.addBtn,
                      },
                      on: { click: _vm.addClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.add")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:finance:quotationMaintenance:modify",
                          expression:
                            "'btn:collection-center:finance:quotationMaintenance:modify'",
                          arg: "remove",
                        },
                      ],
                      attrs: {
                        size: "mini",
                        icon: "el-icon-edit-outline",
                        disabled: _vm.btnDisablad.editBtn,
                      },
                      on: { click: _vm.editClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.modify")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:finance:quotationMaintenance:Save",
                          expression:
                            "'btn:collection-center:finance:quotationMaintenance:Save'",
                          arg: "remove",
                        },
                      ],
                      attrs: {
                        type: "success",
                        size: "mini",
                        disabled: _vm.btnDisablad.saveBtn,
                      },
                      on: { click: _vm.saveClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.Save")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:finance:quotationMaintenance:cancel",
                          expression:
                            "'btn:collection-center:finance:quotationMaintenance:cancel'",
                          arg: "remove",
                        },
                      ],
                      attrs: {
                        type: "info",
                        size: "mini",
                        disabled: _vm.btnDisablad.cancelBtn,
                      },
                      on: { click: _vm.cancelClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.cancel")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShowSubmitForm,
                  expression: "isShowSubmitForm",
                },
              ],
              class: _vm.findDisable ? "disabledClass" : "",
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "bRClass", attrs: { span: 14 } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "submitFormRef",
                          staticClass: "submitClass",
                          attrs: {
                            model: _vm.submitForm,
                            rules: _vm.submitRules,
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { staticClass: "lineH", attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-row",
                                    {
                                      attrs: {
                                        type: "flex",
                                        justify: "space-between",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 11 } },
                                        [
                                          _c("el-form-item", [
                                            _c(
                                              "span",
                                              { staticClass: "redClass" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "collectionCenter.sendArea"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "areaClass",
                                                style:
                                                  _vm.zipcodeSelectList
                                                    .length <= 0 &&
                                                  _vm.isShowSubmitForm &&
                                                  _vm.clickSavaBtn
                                                    ? "border:1px solid #F56C6C;"
                                                    : "",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.chooseListV(
                                                      "send"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm.submitForm.areaName.length >
                                                0
                                                  ? _c(
                                                      "span",
                                                      _vm._l(
                                                        _vm.zipcodeSelectList,
                                                        function (item, index) {
                                                          return _c(
                                                            "span",
                                                            { key: index },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.areaName
                                                                    ) +
                                                                    _vm._s(
                                                                      index +
                                                                        1 !=
                                                                        _vm
                                                                          .zipcodeSelectList
                                                                          .length
                                                                        ? ", "
                                                                        : ""
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                class:
                                                  _vm.zipcodeSelectList
                                                    .length <= 0 &&
                                                  _vm.isShowSubmitForm &&
                                                  _vm.clickSavaBtn
                                                    ? "areaRedClass"
                                                    : "hideClass",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "collectionCenter.notNull"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 11 } },
                                        [
                                          _c("el-form-item", [
                                            _c(
                                              "span",
                                              { staticClass: "redClass" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "collectionCenter.collectionSite"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "areaClass",
                                                style:
                                                  _vm.selectedSiteList.length <=
                                                    0 &&
                                                  _vm.isShowSubmitForm &&
                                                  _vm.clickSavaBtn
                                                    ? "border:1px solid #F56C6C;"
                                                    : "",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.chooseListV(
                                                      "site"
                                                    )
                                                  },
                                                },
                                              },
                                              _vm._l(
                                                _vm.selectedSiteList,
                                                function (itemS, index) {
                                                  return _c(
                                                    "span",
                                                    { key: index },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(itemS.name) +
                                                            _vm._s(
                                                              index + 1 !=
                                                                _vm
                                                                  .selectedSiteList
                                                                  .length
                                                                ? ", "
                                                                : ""
                                                            )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                            _c(
                                              "span",
                                              {
                                                class:
                                                  _vm.selectedSiteList.length <=
                                                    0 &&
                                                  _vm.isShowSubmitForm &&
                                                  _vm.clickSavaBtn
                                                    ? "areaRedClass"
                                                    : "hideClass",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "collectionCenter.notNull"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 16 } },
                                [
                                  _c(
                                    "el-row",
                                    { staticClass: "flexStart" },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  "collectionCenter.CustomerN"
                                                ),
                                                prop: "customerId",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    disabled: _vm.findDisable,
                                                    placeholder: _vm.$t(
                                                      "collectionCenter.pleaseChoose"
                                                    ),
                                                    clearable: "",
                                                    filterable: "",
                                                  },
                                                  on: {
                                                    focus: _vm.getAllCustomer,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.submitForm.customerId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.submitForm,
                                                        "customerId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "submitForm.customerId",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.customerIdList,
                                                  function (item, index) {
                                                    return _c("el-option", {
                                                      key: index,
                                                      attrs: {
                                                        label: item.username,
                                                        value: item.id,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label:
                                                  _vm.$t(
                                                    "collectionCenter.businessT"
                                                  ) + "：",
                                                prop: "businessType",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    disabled: _vm.findDisable,
                                                    placeholder: _vm.$t(
                                                      "collectionCenter.pleaseChoose"
                                                    ),
                                                    clearable: "",
                                                    filterable: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.submitForm
                                                        .businessType,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.submitForm,
                                                        "businessType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "submitForm.businessType",
                                                  },
                                                },
                                                [
                                                  _c("el-option", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "collectionCenter.dropOff"
                                                      ),
                                                      value: 1,
                                                    },
                                                  }),
                                                  _c("el-option", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "collectionCenter.toCollect"
                                                      ),
                                                      value: 2,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  "collectionCenter.billingMode"
                                                ),
                                                prop: "chargePattern",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    disabled: _vm.findDisable,
                                                    placeholder: _vm.$t(
                                                      "collectionCenter.pleaseChoose"
                                                    ),
                                                    clearable: "",
                                                    filterable: "",
                                                  },
                                                  on: { change: _vm.jfChange },
                                                  model: {
                                                    value:
                                                      _vm.submitForm
                                                        .chargePattern,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.submitForm,
                                                        "chargePattern",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "submitForm.chargePattern",
                                                  },
                                                },
                                                [
                                                  _c("el-option", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "collectionCenter.distance"
                                                      ),
                                                      value: 0,
                                                    },
                                                  }),
                                                  _c("el-option", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "collectionCenter.ballot"
                                                      ),
                                                      value: 1,
                                                    },
                                                  }),
                                                  _c("el-option", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "collectionCenter.Weight"
                                                      ),
                                                      value: 2,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  "collectionCenter.belongDot"
                                                ),
                                                prop: "belongToSiteId",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    disabled: _vm.findDisable,
                                                    placeholder: _vm.$t(
                                                      "collectionCenter.pleaseChoose"
                                                    ),
                                                    clearable: "",
                                                    filterable: "",
                                                  },
                                                  on: {
                                                    focus: _vm.getNewestSite,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.submitForm
                                                        .belongToSiteId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.submitForm,
                                                        "belongToSiteId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "submitForm.belongToSiteId",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.siteIdList,
                                                  function (item, index) {
                                                    return _c("el-option", {
                                                      key: index,
                                                      attrs: {
                                                        label: item.name,
                                                        value: item.id,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  "collectionCenter.minimumCharge"
                                                ),
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  disabled: _vm.findDisable,
                                                  placeholder: _vm.$t(
                                                    "collectionCenter.pleaseInput"
                                                  ),
                                                  clearable: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.submitForm
                                                      .minimumCharge,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.submitForm,
                                                      "minimumCharge",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "submitForm.minimumCharge",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop: "enableFlag",
                                                label: _vm.$t(
                                                  "collectionCenter.ifEnabled"
                                                ),
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    disabled: _vm.findDisable,
                                                    placeholder: _vm.$t(
                                                      "collectionCenter.pleaseChoose"
                                                    ),
                                                    clearable: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.submitForm.enableFlag,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.submitForm,
                                                        "enableFlag",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "submitForm.enableFlag",
                                                  },
                                                },
                                                [
                                                  _c("el-option", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "collectionCenter.enable"
                                                      ),
                                                      value: 0,
                                                    },
                                                  }),
                                                  _c("el-option", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "collectionCenter.notEnabled"
                                                      ),
                                                      value: 1,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "dateChooseClass",
                                          attrs: { span: 24 },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  "collectionCenter.effectiveStartTime"
                                                ),
                                                prop: "startTime",
                                              },
                                            },
                                            [
                                              _c("el-date-picker", {
                                                attrs: {
                                                  disabled: _vm.findDisable,
                                                  type: "datetime",
                                                  "value-format":
                                                    "yyyy-MM-dd HH:mm:ss",
                                                  placeholder: _vm.$t(
                                                    "collectionCenter.StartTime"
                                                  ),
                                                  clearable: false,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.submitChangeDate(
                                                      $event
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.submitForm.startTime,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.submitForm,
                                                      "startTime",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "submitForm.startTime",
                                                },
                                              }),
                                              _vm._v(" ~ "),
                                              _c("el-date-picker", {
                                                attrs: {
                                                  disabled: _vm.findDisable,
                                                  type: "datetime",
                                                  "value-format":
                                                    "yyyy-MM-dd HH:mm:ss",
                                                  placeholder: _vm.$t(
                                                    "collectionCenter.EndTime"
                                                  ),
                                                  clearable: false,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.submitChangeDate(
                                                      $event
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: _vm.submitForm.endTime,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.submitForm,
                                                      "endTime",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "submitForm.endTime",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 11 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  "collectionCenter.note"
                                                ),
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  disabled: _vm.findDisable,
                                                  placeholder: _vm.$t(
                                                    "collectionCenter.pleaseInput"
                                                  ),
                                                  clearable: "",
                                                },
                                                model: {
                                                  value: _vm.submitForm.remark,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.submitForm,
                                                      "remark",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "submitForm.remark",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticClass: "modelTableClass", attrs: { span: 10 } },
                    [
                      _c(
                        "div",
                        [
                          _c("div", { staticClass: "captionClass" }, [
                            _c("div", {
                              staticClass: "captionRed",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("collectionCenter.tableTips")
                                ),
                              },
                            }),
                          ]),
                          _c(
                            "el-form",
                            {
                              ref: "submitRef",
                              attrs: {
                                model: _vm.longTableForm,
                                rules: _vm.longTableForm.longTableRules,
                              },
                            },
                            [
                              _c(
                                "el-table",
                                {
                                  attrs: {
                                    border: "",
                                    "max-height": 200,
                                    data: _vm.longTableForm.longTable,
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "startValue",
                                      align: "center",
                                      width: "100",
                                      label:
                                        _vm.submitForm.chargePattern == 0
                                          ? _vm.$t(
                                              "collectionCenter.distanceMin"
                                            )
                                          : _vm.submitForm.chargePattern == 1
                                          ? _vm.$t("collectionCenter.pollMin")
                                          : _vm.submitForm.chargePattern == 2
                                          ? _vm.$t("collectionCenter.weightMin")
                                          : "",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "longTable." +
                                                    scope.$index +
                                                    ".startValue",
                                                  rules:
                                                    _vm.longTableForm
                                                      .longTableRules
                                                      .startValue,
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled: _vm.findDisable,
                                                    clearable: "",
                                                    placeholder: _vm.$t(
                                                      "collectionCenter.pleaseInput"
                                                    ),
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.toNumber(
                                                        scope.row,
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: scope.row.startValue,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "startValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.startValue",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "endValue",
                                      align: "center",
                                      width: "100",
                                      label:
                                        _vm.submitForm.chargePattern == 0
                                          ? _vm.$t(
                                              "collectionCenter.distanceMax"
                                            )
                                          : _vm.submitForm.chargePattern == 1
                                          ? _vm.$t("collectionCenter.pollMax")
                                          : _vm.submitForm.chargePattern == 2
                                          ? _vm.$t("collectionCenter.weightMax")
                                          : "",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "longTable." +
                                                    scope.$index +
                                                    ".endValue",
                                                  rules:
                                                    _vm.longTableForm
                                                      .longTableRules.endValue,
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled: _vm.findDisable,
                                                    clearable: "",
                                                    placeholder: _vm.$t(
                                                      "collectionCenter.pleaseInput"
                                                    ),
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.toNumber(
                                                        scope.row,
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: scope.row.endValue,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "endValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.endValue",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "formula",
                                      align: "center",
                                      label: _vm.$t("collectionCenter.formula"),
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "longTable." +
                                                    scope.$index +
                                                    ".formula",
                                                  rules:
                                                    _vm.longTableForm
                                                      .longTableRules.formula,
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled: _vm.findDisable,
                                                    type: "text",
                                                    clearable: "",
                                                    placeholder: _vm.$t(
                                                      "collectionCenter.pleaseInput"
                                                    ),
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.toNumber(
                                                        scope.row,
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: scope.row.formula,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "formula",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.formula",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "remark",
                                      align: "center",
                                      label: _vm.$t("collectionCenter.Note"),
                                      width: "80",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.findDisable,
                                                clearable: "",
                                                placeholder: "",
                                              },
                                              model: {
                                                value: scope.row.remark,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "remark",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.remark",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "",
                                      width: "29",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: !_vm.findDisable,
                                                    expression: "!findDisable",
                                                  },
                                                ],
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          scope.$index != 0,
                                                        expression:
                                                          "scope.$index != 0",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "delRowBtnClass",
                                                  },
                                                  [
                                                    _c("el-button", {
                                                      attrs: {
                                                        icon: "el-icon-delete",
                                                        type: "danger",
                                                        size: "mini",
                                                        circle: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.delRow(
                                                            scope.row,
                                                            scope.$index
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.findDisable,
                                  expression: "!findDisable",
                                },
                              ],
                              staticStyle: { "text-align": "center" },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-plus",
                                  size: "mini",
                                  circle: "",
                                },
                                on: { click: _vm.addRow },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("el-divider"),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "currentRowClass" },
        [
          _c(
            "el-table",
            {
              ref: "tableRef",
              attrs: {
                border: "",
                "max-height": 600,
                data: _vm.tableDate,
                "highlight-current-row": "",
              },
              on: {
                select: _vm.batchSelect,
                "select-all": _vm.batchSelectAll,
                "row-click": _vm.rowClick,
                "current-change": _vm.handleCurrentChange,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.ind"),
                  type: "index",
                  width: "60",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.queryForm.pageNumber - 1) *
                                _vm.queryForm.pageSize +
                                scope.$index +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "customerName",
                  align: "center",
                  label: _vm.$t("collectionCenter.CustomerName"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "areaName",
                  align: "center",
                  label: _vm.$t("collectionCenter.sendRegional"),
                  "show-overflow-tooltip": false,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-tooltip", { attrs: { placement: "top" } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "max-height": "200px",
                                overflow: "auto",
                                width: "500px",
                              },
                              attrs: { slot: "content" },
                              slot: "content",
                            },
                            [_vm._v(_vm._s(scope.row.areaNameStr))]
                          ),
                          _c("div", { staticClass: "paramsStyle" }, [
                            _vm._v(_vm._s(scope.row.areaNameStr) + " "),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "collectSiteName",
                  align: "center",
                  label: _vm.$t("collectionCenter.collectionDot"),
                  "show-overflow-tooltip": false,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-tooltip", { attrs: { placement: "top" } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "max-height": "200px",
                                overflow: "auto",
                                width: "500px",
                              },
                              attrs: { slot: "content" },
                              slot: "content",
                            },
                            [_vm._v(_vm._s(scope.row.collectSiteName))]
                          ),
                          _c("div", { staticClass: "paramsStyle" }, [
                            _vm._v(
                              _vm._s(
                                scope.row.collectSiteName == "[]"
                                  ? ""
                                  : scope.row.collectSiteName
                              )
                            ),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "belongToSiteName",
                  align: "center",
                  label: _vm.$t("collectionCenter.belongD"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "startTime",
                  align: "center",
                  label: _vm.$t("collectionCenter.eStartTime"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "endTime",
                  align: "center",
                  label: _vm.$t("collectionCenter.effectiveEndTime"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "businessType",
                  label: _vm.$t("collectionCenter.businessT"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                scope.row.businessType == 2
                                  ? _vm.$t("collectionCenter.toCollect")
                                  : scope.row.businessType == 1
                                  ? _vm.$t("collectionCenter.dropOff")
                                  : ""
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "enableFlag",
                  align: "center",
                  label: _vm.$t("collectionCenter.ifUse"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                scope.row.enableFlag == 0
                                  ? _vm.$t("collectionCenter.enable")
                                  : scope.row.enableFlag == 1
                                  ? _vm.$t("collectionCenter.Disable")
                                  : ""
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "remark",
                  align: "center",
                  label: _vm.$t("collectionCenter.Note"),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-pagination", {
            staticStyle: { margin: "10px 0" },
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              "current-page": _vm.queryForm.pageNumber,
              "page-sizes": [10, 20, 50, 100, 500, 1000],
              "page-size": _vm.queryForm.pageSize,
              total: _vm.total,
              background: "",
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "current-change": _vm.getPage,
              "size-change": _vm.getPageSize,
            },
          }),
        ],
        1
      ),
      _vm.drawerIsOpen
        ? _c(
            "div",
            [
              _c(
                "el-drawer",
                {
                  attrs: {
                    title: _vm.drawerTitle,
                    visible: _vm.drawerIsOpen,
                    direction: _vm.direction,
                    size: "40%",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.drawerIsOpen = $event
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "drawerClass" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "tryCDrawerFormRef",
                          attrs: {
                            model: _vm.tryCDrawerForm,
                            rules: _vm.tryCRules,
                            "label-position": "top",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            {
                              attrs: { type: "flex", justify: "space-between" },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 11 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "customerId",
                                        label: _vm.$t(
                                          "collectionCenter.CustomerN"
                                        ),
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "collectionCenter.pleaseChoose"
                                            ),
                                            clearable: "",
                                            filterable: "",
                                          },
                                          on: { focus: _vm.getAllCustomer },
                                          model: {
                                            value:
                                              _vm.tryCDrawerForm.customerId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tryCDrawerForm,
                                                "customerId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "tryCDrawerForm.customerId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.customerIdList,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.username,
                                                value: item.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 11 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "divisionId",
                                        label: _vm.$t("collectionCenter.sendR"),
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "collectionCenter.pleaseChoose"
                                            ),
                                            clearable: "",
                                            filterable: "",
                                          },
                                          model: {
                                            value:
                                              _vm.tryCDrawerForm.divisionId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tryCDrawerForm,
                                                "divisionId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "tryCDrawerForm.divisionId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.tryZipcodeTableDate,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.areaName,
                                                value: item.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            {
                              attrs: { type: "flex", justify: "space-between" },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 11 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "businessType",
                                        label:
                                          _vm.$t("collectionCenter.businessT") +
                                          "：",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "collectionCenter.pleaseChoose"
                                            ),
                                            clearable: "",
                                            filterable: "",
                                          },
                                          model: {
                                            value:
                                              _vm.tryCDrawerForm.businessType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tryCDrawerForm,
                                                "businessType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "tryCDrawerForm.businessType",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: _vm.$t(
                                                "collectionCenter.dropOff"
                                              ),
                                              value: 1,
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: _vm.$t(
                                                "collectionCenter.toCollect"
                                              ),
                                              value: 2,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 11 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "siteId",
                                        label: _vm.$t(
                                          "collectionCenter.belongDot"
                                        ),
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "collectionCenter.pleaseChoose"
                                            ),
                                            clearable: "",
                                            filterable: "",
                                          },
                                          on: { focus: _vm.getNewestSite },
                                          model: {
                                            value: _vm.tryCDrawerForm.siteId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tryCDrawerForm,
                                                "siteId",
                                                $$v
                                              )
                                            },
                                            expression: "tryCDrawerForm.siteId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.siteIdList,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.name,
                                                value: item.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            {
                              attrs: { type: "flex", justify: "space-between" },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 11 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "chargePatternClass",
                                      attrs: {
                                        prop: "value",
                                        label: _vm.$t(
                                          "collectionCenter.billingMode"
                                        ),
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "collectionCenter.pleaseChoose"
                                            ),
                                          },
                                          model: {
                                            value:
                                              _vm.tryCDrawerForm.chargePattern,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tryCDrawerForm,
                                                "chargePattern",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "tryCDrawerForm.chargePattern",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: _vm.$t(
                                                "collectionCenter.distance"
                                              ),
                                              value: 0,
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: _vm.$t(
                                                "collectionCenter.ballot"
                                              ),
                                              value: 1,
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: _vm.$t(
                                                "collectionCenter.Weight"
                                              ),
                                              value: 2,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("el-input", {
                                        attrs: {
                                          clearable: "",
                                          placeholder: _vm.$t(
                                            "collectionCenter.pleaseInput"
                                          ),
                                        },
                                        model: {
                                          value: _vm.tryCDrawerForm.value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tryCDrawerForm,
                                              "value",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "tryCDrawerForm.value",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 11 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "collectSiteId",
                                        label: _vm.$t(
                                          "collectionCenter.collectionSite"
                                        ),
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          clearable: "",
                                          placeholder: _vm.$t(
                                            "collectionCenter.pleaseChoose"
                                          ),
                                        },
                                        on: { focus: _vm.openSiteDialog },
                                        model: {
                                          value:
                                            _vm.tryCDrawerForm.collectSiteId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tryCDrawerForm,
                                              "collectSiteId",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression:
                                            "tryCDrawerForm.collectSiteId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "submitClickBtn tryC" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "success" },
                              on: { click: _vm.trySave },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("collectionCenter.trialCalculation")
                                )
                              ),
                            ]
                          ),
                          _c("el-button", { on: { click: _vm.tryCancel } }, [
                            _vm._v(_vm._s(_vm.$t("collectionCenter.cancel"))),
                          ]),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "tryResultBoxClass" }, [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("collectionCenter.trialResult")) +
                              " "
                          ),
                        ]),
                        _c("span", { staticClass: "tryResult" }, [
                          _vm._v(" " + _vm._s(_vm.tryCDrawerData) + " "),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("collectionCenter.selectReceivingOutlets"),
            visible: _vm.trySiteVisible,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.trySiteVisible = $event
            },
            close: _vm.tryCloseDialog,
          },
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "searchClass" },
              [
                _c("span", [_vm._v(_vm._s(_vm.$t("collectionCenter.query")))]),
                _c(
                  "el-input",
                  {
                    attrs: {
                      clearable: "",
                      placeholder: _vm.$t("collectionCenter.querySiteCode"),
                    },
                    model: {
                      value: _vm.siteDialogForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.siteDialogForm, "name", $$v)
                      },
                      expression: "siteDialogForm.name",
                    },
                  },
                  [
                    _c("el-button", {
                      attrs: { slot: "append", icon: "el-icon-search" },
                      on: { click: _vm.searchSiteTry },
                      slot: "append",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "span",
              { staticStyle: { display: "block", "text-align": "center" } },
              [_vm._v(_vm._s(_vm.chooseSite.name))]
            ),
            _c(
              "div",
              [
                _c(
                  "el-card",
                  { staticClass: "scoll trySiteClass" },
                  [
                    _c("el-tree", {
                      ref: "trySiteRef",
                      attrs: {
                        data: _vm.treeData,
                        "default-expand-all": true,
                        props: _vm.defaultProps,
                        "highlight-current": true,
                        "node-key": "id",
                        "current-node-key": _vm.currentNodekey,
                      },
                      on: { "node-click": _vm.treeCheck },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var data = ref.data
                            return _c(
                              "span",
                              { staticClass: "custom-tree-node" },
                              [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(data.code) +
                                      " - " +
                                      _vm._s(data.name)
                                  ),
                                ]),
                              ]
                            )
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.trySiteSaveClick },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.Save")))]
                ),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.trySiteVisible = false
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.cancel")))]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm.chooseListVisible
        ? _c(
            "div",
            [
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: _vm.chooseTitle,
                    visible: _vm.chooseListVisible,
                    width: "50%",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.chooseListVisible = $event
                    },
                    close: _vm.closeDialog,
                  },
                },
                [
                  _vm.openTheDialog == "send"
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "searchClass" },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("collectionCenter.ZipCodeAreaName")
                                )
                              ),
                            ]),
                            _c("el-input", {
                              attrs: {
                                clearable: "",
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseInput"
                                ),
                              },
                              model: {
                                value: _vm.zipcodeDrawerForm.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.zipcodeDrawerForm, "name", $$v)
                                },
                                expression: "zipcodeDrawerForm.name",
                              },
                            }),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.chooseSearchClick },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("collectionCenter.search")) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "zipcodeTableClass" },
                          [
                            _c(
                              "el-table",
                              {
                                ref: "zipcodeRef",
                                attrs: {
                                  border: "",
                                  "max-height": 400,
                                  data: _vm.zipcodeTableDate,
                                },
                                on: {
                                  select: _vm.zipcodeBatchSelect,
                                  "select-all": _vm.zipcodeBatchSelectAll,
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: { type: "selection", width: "55" },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "id",
                                    align: "center",
                                    label: _vm.$t(
                                      "collectionCenter.SerialNumber"
                                    ),
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "areaName",
                                    align: "center",
                                    label: _vm.$t(
                                      "collectionCenter.partitionName"
                                    ),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "dialog-footer",
                            attrs: { slot: "footer" },
                            slot: "footer",
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.saveZipCodeClick },
                              },
                              [_vm._v(_vm._s(_vm.$t("collectionCenter.Save")))]
                            ),
                            _c(
                              "el-button",
                              { on: { click: _vm.searchZipCodeClose } },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("collectionCenter.cancel"))
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.openTheDialog == "site"
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "searchClass" },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("collectionCenter.query"))),
                            ]),
                            _c(
                              "el-input",
                              {
                                attrs: {
                                  clearable: "",
                                  placeholder: _vm.$t(
                                    "collectionCenter.querySiteCode"
                                  ),
                                },
                                model: {
                                  value: _vm.siteDrawerForm.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.siteDrawerForm, "name", $$v)
                                  },
                                  expression: "siteDrawerForm.name",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.searchSite },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "el-card",
                              { staticClass: "scoll highlightClass" },
                              [
                                _c("el-tree", {
                                  ref: "siteRef",
                                  attrs: {
                                    data: _vm.treeData,
                                    "show-checkbox": "",
                                    "default-expand-all": true,
                                    props: _vm.defaultProps,
                                    "check-strictly": true,
                                    "node-key": "id",
                                    "default-checked-keys": _vm.defaultChecked,
                                    "highlight-current": _vm.highlightCurrent,
                                    "current-node-key": _vm.currentNodekey,
                                  },
                                  on: { "check-change": _vm.changeEvent },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var data = ref.data
                                          return _c(
                                            "span",
                                            { staticClass: "custom-tree-node" },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(data.code) +
                                                    " - " +
                                                    _vm._s(data.name)
                                                ),
                                              ]),
                                            ]
                                          )
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    686732213
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "dialog-footer",
                            attrs: { slot: "footer" },
                            slot: "footer",
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.searchSiteClick },
                              },
                              [_vm._v(_vm._s(_vm.$t("collectionCenter.Save")))]
                            ),
                            _c(
                              "el-button",
                              { on: { click: _vm.searchSiteClose } },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("collectionCenter.cancel"))
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }