var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        },
      },
    },
    [
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("driver.modalDeleteObservation"),
            "label-for": "observation",
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "observation",
              placeholder: _vm.$t("driver.modalDeletePlaceholder"),
            },
            model: {
              value: _vm.observation,
              callback: function ($$v) {
                _vm.observation = $$v
              },
              expression: "observation",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "wrapperButton" },
        [
          _c(
            "o-button",
            {
              attrs: {
                variant: "primary",
                size: "md",
                "native-type": "submit",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("driver.modalSendButton")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }