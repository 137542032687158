<template>
  <div style="padding: 10px;">
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <!--运单详情-->
      <el-tab-pane name="1">
        <span slot="label"><i class="el-icon-s-grid" /> {{ $t('orderDetails.waybillMsg') }}</span>
        <detailBox :query-track-param="queryTrackParam" />
      </el-tab-pane>
      <!--操作日志-->
      <el-tab-pane name="3">
        <span slot="label"><i class="el-icon-document" /> {{ $t('spareNumberPoo.OperationalLogs') }}</span>
        <OperationalLogs
          v-if="isShow3" :key="2" :query-track-param="queryTrackParam"
          query-type="2"
        />
      </el-tab-pane>
      <!--轨迹查询-->
      <el-tab-pane name="2">
        <span slot="label"><i class="el-icon-location-outline" /> {{ $t('GlobalSetObj.TrackSearch') }}</span>
        <trackQuery
          v-if="isShow2" :key="1" :query-track-param="queryTrackParam"
          query-type="1"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

export default {
  name: 'WaybillInformation',
  components: {
    'detailBox': () => import('./detailBox/index'),
    'trackQuery': () => import('./trackQuery/trackQuery'),
    'OperationalLogs': () => import('./OperateLogs/OperationalLogs')
  },
  props: {
    queryTrackParam: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      activeName: '1',
      isShow2: false,
      isShow3: false
    };
  },
  methods: {
    handleClick(tab) {
      if (tab.name === '2') {
        this.isShow2 = true;
      }
      if (tab.name === '3') {
        this.isShow3 = true;
      }
    }
  }
};
</script>
