<template>
  <div class="detialInfo">
    <el-collapse v-model="activeNames">
      <!--运单基本信息-->
      <el-collapse-item :title="$t('newOrder.BasicInformationWaybill')" name="1">
        <el-card class="box-card">
          <basicInfo :table-infomation="tableInfomation" />
        </el-card>
      </el-collapse-item>
      <!--签收信息-->
      <el-collapse-item :title="$t('GlobalSetObj.qinashouInfo')" name="2">
        <el-card class="box-card">
          <signeInfo :table-infomation="tableInfomation" />
        </el-card>
      </el-collapse-item>
      <!--子单发票信息-->
      <el-collapse-item :title="$t('newOrder.InvoiceInformation')" name="4">
        <el-card class="box-card">
          <child-order-invoice-info
            :query-track-param="queryTrackParam"
            :waybill-invoice-v-o-list="waybillInvoiceVOList"
          />
        </el-card>
      </el-collapse-item>
      <!--收件人信息-->
      <el-collapse-item :title="$t('orderCenterCont.RecipientInformation')" name="5">
        <el-card class="box-card">
          <RecipientsInfo
            :encryption-receiver-info="encryptionReceiverInfo"
            :receiver-info="tableInfomation.receiverInfo"
          />
        </el-card>
      </el-collapse-item>
      <!--商品信息-->
      <el-collapse-item :title="$t('orderCenterCont.ProductInformation')" name="6">
        <el-card class="box-card">
          <GoodsInfo :goods-table-data="goodsTableData" />
        </el-card>
      </el-collapse-item>
      <!--发件人信息-->
      <el-collapse-item :title="$t('orderCenterCont.SenderInformation')" name="7">
        <el-card class="box-card">
          <senderInfo :encryption-sender-info="encryptionSenderInfo" :sender-info="tableInfomation.senderInfo" />
        </el-card>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import basicInfo from './components/basicInfo';
import RecipientsInfo from './components/recipientsInfo';
import GoodsInfo from './components/goodsInfo';
import senderInfo from './components/senderInfo';
import signeInfo from './components/SignInformation';
import childOrderInvoiceInfo from './components/childOrderInvoiceInfo.vue';
import { getOrderInfo } from '@/api/logisticsOrders/index.js';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'Detail',
  components: {
    basicInfo,
    RecipientsInfo,
    GoodsInfo,
    senderInfo,
    signeInfo,
    childOrderInvoiceInfo
  },
  props: {
    queryTrackParam: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      activeNames: ['1', '2', '3', '4', '5', '6'],
      tableInfomation: {},
      goodsTableData: [],
      waybillInvoiceVOList: [], // 合单发票数据
      encryptionReceiverInfo: {},
      encryptionSenderInfo: {}
    };
  },
  mounted() {
    this.getOrderInfo();
  },
  destroyed() {
    this.bus.$off('todetailBox');
  },
  methods: {
    // 重新生成Minuta后刷新数据
    rebuildMinutaChange() {
      this.getOrderInfo();
    },
    // 获取订单详情
    getOrderInfo() {
      this.bus.$on('todetailBox', (id) => {
        console.log(id, 'ididididididididididididididididid');
        getOrderInfo(this.queryTrackParam.waybillId).then((res) => {
          if (res.status === 'OK') {
            this.tableInfomation = res.data;
            this.tableInfomation.waybillId = id;
            console.log(this.tableInfomation, 'this.tableInfomation13232');
            if (this.tableInfomation.signType === 0) {
              this.tableInfomation.signTypeName = this.$t('GlobalSetObj.Myself');
            } else if (this.tableInfomation.signType === 1) {
              this.tableInfomation.signTypeName = this.$t('GlobalSetObj.NotMyself');
            } else if (this.tableInfomation.signType === 2) {
              this.tableInfomation.signTypeName = this.$t('GlobalSetObj.Myself');
            } else {
              this.tableInfomation.signTypeName = '--';
            }
            res.data.orderItemList.forEach((item) => {
              const objSize = {};
              const declared = {};
              objSize.length = item.length;
              objSize.width = item.width;
              objSize.height = item.height;
              declared.declaredValue = item.declaredValue;
              declared.declaredWeight = item.declaredWeight;
              item.objSize = objSize;
              item.declared = declared;
              item.goodName = item.name;
            });
            this.goodsTableData = res.data.orderItemList;
            // 合单发票信息
            this.waybillInvoiceVOList = res.data.waybillInvoiceVOList;
            const copyObj = cloneDeep(this.tableInfomation);
            this.encryptionReceiverInfo = copyObj.receiverInfo;
            this.encryptionSenderInfo = copyObj.senderInfo;
            this.encryptionReceiverInfo.receiveTax = this.encryptionReceiverInfo.receiveTax ? '*'.repeat(this.encryptionReceiverInfo.receiveTax.length) : '';
            this.encryptionReceiverInfo.receiveCompany = this.encryptionReceiverInfo.receiveCompany ? '*'.repeat(this.encryptionReceiverInfo.receiveCompany.length) : '';
            this.encryptionReceiverInfo.receiveName = this.encryptionReceiverInfo.receiveName ? '*'.repeat(this.encryptionReceiverInfo.receiveName.length) : '';
            this.encryptionReceiverInfo.receivePhone = this.encryptionReceiverInfo.receivePhone ? this.encryptionReceiverInfo.receivePhone.replace(/^(...).*(.{4})$/, '$1****$2') : '';
            if (this.encryptionReceiverInfo.receiveMobile) {
              if (this.encryptionReceiverInfo.receiveMobile.length < 7) {
                this.encryptionReceiverInfo.receiveMobile = this.encryptionReceiverInfo.receiveMobile.slice(0, 3) + '*'.repeat(this.encryptionReceiverInfo.receiveMobile.length - 3);
              } else {
                this.encryptionReceiverInfo.receiveMobile = this.encryptionReceiverInfo.receiveMobile.replace(/^(...).*(.{4})$/, '$1****$2');
              }
            } else {
              this.encryptionReceiverInfo.receiveMobile = '';
            }
            // this.encryptionReceiverInfo.receiveMobile = this.encryptionReceiverInfo.receiveMobile ? this.encryptionReceiverInfo.receiveMobile.replace(/^(...).*(.{4})$/, '$1****$2') : '';
            this.encryptionReceiverInfo.receiveCertificateCode = this.encryptionReceiverInfo.receiveCertificateCode ? this.encryptionReceiverInfo.receiveCertificateCode.replace(/^(...).*(.{4})$/, '$1****$2') : '';
            if (this.encryptionReceiverInfo.receiveMail) {
              const str = this.encryptionReceiverInfo.receiveMail;
              const atIndex = str.indexOf('@');
              this.encryptionReceiverInfo.receiveMail = str.slice(0, atIndex).replace(/./g, '*') + str.slice(atIndex);
            }
            this.encryptionSenderInfo = copyObj.senderInfo;
            this.encryptionSenderInfo.senderName = this.encryptionSenderInfo.senderName ? '*'.repeat(this.encryptionSenderInfo.senderName.length) : '';
            this.encryptionSenderInfo.senderTax = this.encryptionSenderInfo.senderTax ? '*'.repeat(this.encryptionSenderInfo.senderTax.length) : '';
            this.encryptionSenderInfo.senderCompany = this.encryptionSenderInfo.senderCompany ? '*'.repeat(this.encryptionSenderInfo.senderCompany.length) : '';
            this.encryptionSenderInfo.senderPhone = this.encryptionSenderInfo.senderPhone ? this.encryptionSenderInfo.senderPhone.replace(/^(...).*(.{4})$/, '$1****$2') : '';
            if (this.encryptionSenderInfo.senderMobile) {
              if (this.encryptionSenderInfo.senderMobile.length < 7) {
                this.encryptionSenderInfo.senderMobile = this.encryptionSenderInfo.senderMobile.slice(0, 3) + '*'.repeat(this.encryptionSenderInfo.senderMobile.length - 3);
              } else {
                this.encryptionSenderInfo.senderMobile = this.encryptionSenderInfo.senderMobile.replace(/^(...).*(.{4})$/, '$1****$2');
              }
            } else {
              this.encryptionSenderInfo.senderMobile = '';
            }
            // this.encryptionSenderInfo.senderMobile = this.encryptionSenderInfo.senderMobile ? this.encryptionSenderInfo.senderMobile.replace(/^(...).*(.{4})$/, '$1****$2') : '';
            this.encryptionSenderInfo.senderCertificateCode = this.encryptionSenderInfo.senderCertificateCode ? this.encryptionSenderInfo.senderCertificateCode.replace(/^(...).*(.{4})$/, '$1****$2') : '';
            if (this.encryptionSenderInfo.senderMail) {
              const str = this.encryptionSenderInfo.senderMail;
              const atIndex = str.indexOf('@');
              this.encryptionSenderInfo.senderMail = str.slice(0, atIndex).replace(/./g, '*') + str.slice(atIndex);
            }
          }
        });
      });
    }
  }
};
</script>

<style scoped>
.detialInfo /deep/ .el-collapse-item__header {
  color: #409eff;
  font-size: 16px;
}

</style>
