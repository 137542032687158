import Vue from 'vue';

const icons = {
  error: {
    bgColor: '0 100% 50%',
    children: (h) => [
      h('path', {
        attrs: {
          d: 'M18 6 6 18'
        }
      }),
      h('path', {
        attrs: {
          d: 'm6 6 12 12'
        }
      })
    ]
  },
  success: {
    bgColor: '146 100% 29%',
    children: (h) => [
      h('path', {
        attrs: {
          d: 'M20 6 9 17l-5-5'
        }
      })
    ]
  }
};

export function getToastContentComponent({ title, describe, typeIcon }) {
  return Vue.extend({
    render(h) {
      return h('div', {
        style: {
          display: 'flex',
          alignItems: 'center',
          gap: '1rem'
        }
      }, [
        h('div', { style: { padding: '0.3rem', backgroundColor: `hsl(${icons[typeIcon].bgColor})`, borderRadius: '9999px', display: 'flex', alignItems: 'center' }}, [
          h('svg', {
            attrs: {
              xmlns: 'http://www.w3.org/2000/svg',
              width: '24',
              height: '24',
              viewBox: '0 0 24 24',
              fill: 'none',
              stroke: 'white',
              'stroke-width': 2,
              'stroke-linecap': 'round',
              'stroke-linejoin': 'round'
            }
          }, icons[typeIcon].children(h))
        ]),
        h('div', { style: { flex: 1 }}, [
          h('h2', {
            style: {
              fontWeight: 'bold',
              fontSize: '14px',
              margin: '0px'
            }
          }, title),
          h('p', {
            style: {
              fontSize: '14px',
              margin: '0px'
            }
          }, describe)
        ])
      ]);
    }
  });
}
