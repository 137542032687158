import { useQuery, useQueryClient, useMutation } from '@tanstack/vue-query';

import { transferRouterService } from '.';
import { equipmentService } from '../../transport/equipment/index';
import { getSkipPage } from '@/utils/getSkipPage';
import getMessageError from '@/utils/getMessageError';
import { toast } from '@/utils/programaticToast';
import { getToastContentComponent } from '@/utils/getToastContentComponent';

export function useGetAllTransferRoute(queryParams) {
  return useQuery({
    queryKey: ['transfer-route', queryParams],
    queryFn: async({ signal }) => {
      try {
        const { currentPage, perPage, status } = queryParams.value;
        const skip = getSkipPage(currentPage);
        const params = {
          skip,
          take: perPage,
          status
        };
        const res = await transferRouterService.getAllTransferRoute(params, signal);
        return res;
      } catch (error) {
        if (error.response && error.response.status === 400) {
          return [];
        }
        throw error;
      }
    }
  });
}

export function useCreateTansferRouter() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (transferRoute) => transferRouterService.create(transferRoute),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['transfer-route'] });
      toast.open({
        variant: 'success',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Criada com sucesso!', describe: 'Rota de transferência criada com sucesso!', typeIcon: 'success' }),
        closable: true,
        duration: 4000
      });
    },
    onError: (error) => {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Ocorreu um erro', describe: getMessageError(error), typeIcon: 'error' }),
        closable: true,
        duration: 4000
      });
    }
  });
}

export function updateRotes() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ route, id }) => transferRouterService.edit(route, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKeys: ['transfer-route'] });
      toast.open({
        variant: 'success',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Editado com sucesso', describe: 'Rotas foi editada com sucesso em nosso sistema', typeIcon: 'success' }),
        closable: true,
        duration: 4000
      });
    },
    onError: (error) => {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Ocorreu um erro', describe: getMessageError(error), typeIcon: 'error' }),
        closable: true,
        duration: 4000
      });
    }
  });
}

export function createEquipamento() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async(transferRoute) => {
      try {
        // Primeiro, chama a função createEquipment
        await transferRouterService.createEquipment(transferRoute);

        // Em seguida, chama a função changeStatus
        await transferRouterService.changeStatusRoute(transferRoute.route_id);

        queryClient.invalidateQueries({ queryKey: ['transfer-route'] });

        // Exibe o toast de sucesso
        toast.open({
          variant: 'success',
          position: 'bottom',
          component: getToastContentComponent({ title: 'Criado com sucesso!', describe: 'Equipamento criado com sucesso!', typeIcon: 'success' }),
          closable: true,
          duration: 4000
        });
      } catch (error) {
        // Exibe um toast de erro em caso de falha
        toast.open({
          variant: 'error',
          position: 'bottom',
          component: getToastContentComponent({ title: 'Ocorreu um erro', describe: getMessageError(error), typeIcon: 'error' }),
          closable: true,
          duration: 4000
        });

        // Lança o erro novamente para que o estado de erro da mutação seja atualizado
        throw error;
      }
    }
  });
}

export function useUpdateTruckPackage() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (transferRoute) => transferRouterService.updateTruckPackage(transferRoute),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['transfer-packages'] });
      toast.open({
        variant: 'success',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Atualizado!', describe: 'Atualização efetuada com sucesso!', typeIcon: 'success' }),
        closable: true,
        duration: 4000
      });
    },
    onError: (error) => {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Ocorreu um erro', describe: getMessageError(error), typeIcon: 'error' }),
        closable: true,
        duration: 4000
      });
    }
  });
}

export function useGetDataPackages(id) {
  return useQuery({
    queryKey: ['transfer-packages'],
    queryFn: async(id) => {
      try {
        const res = await transferRouterService.getDataPackages(id);
        return res;
      } catch (error) {
        if (error.response && error.response.status === 400) {
          return [];
        }
        throw error;
      }
    }
  });
}
