<template>
  <el-breadcrumb class="app-breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item,index) in levelList" :key="item.path">
        <span v-if="item.redirect==='noRedirect'|| index === levelList.length-1" class="no-redirect">
          {{ generateTitle(item.meta.title) }}
        </span>
        <a v-else @click.prevent="handleLink(item)">{{ generateTitle(item.meta.title) }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
import pathToRegexp from 'path-to-regexp';
import { generateTitle } from '@/utils/i18n';

export default {
  data() {
    return {
      levelList: null,
      isShowNotify: false
    };
  },
  watch: {
    $route() {
      this.getBreadcrumb();
      // this.getNotice();
    }
  },
  created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb() {
      // only show routes with meta.title
      let matched = this.$route.matched.filter(item => item.meta && item.meta.title);
      const first = matched[0];

      if (!this.isDashboard(first)) {
        matched = [{ path: '/dashboard', meta: { title: this.$t('GlobalSetObj.dashboard') }}].concat(matched);
      }

      this.levelList = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false);
    },
    // 获取系统通知 在路由变化时候
    // getNotice() {
    //   // 后台获取的通知列表 todo
    //   getCurrentUserMessage().then(response => {
    //     if (response.code === 200) {
    //       const noticeList = response.data;
    //       const len = noticeList.length;
    //       if (len > 0 && this.isShowNotify === false) {
    //         setTimeout(() => {
    //           this.$notify({
    //             title: '通知',
    //             duration: 7000,
    //             type: 'warning',
    //             dangerouslyUseHTMLString: true,
    //             message: '您有 <span style="color: red;font-size: 16px;font-weight: bold;">' + len + '</span> 条消息需处理',
    //             offset: 60
    //           });
    //         }, 1000);
    //         this.isShowNotify = true;
    //         // return;
    //       }
    //
    //       // noticeList.forEach((value, index) => {
    //       //   setTimeout(() => {
    //       //     this.$notify({
    //       //       title: value.title,
    //       //       message: value.message
    //       //     });
    //       //   }, (1000 * (index + 1) * 0.75));
    //       // });
    //     }
    //   });
    // },
    isDashboard(route) {
      const name = route && route.name;
      if (!name) {
        return false;
      }
      return name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase();
    },
    pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      const { params } = this.$route;
      const toPath = pathToRegexp.compile(path);
      return toPath(params);
    },
    handleLink(item) {
      const { redirect, path } = item;
      if (redirect) {
        this.$router.push(redirect);
        return;
      }
      this.$router.push(this.pathCompile(path));
    },

    // 面包屑 - 显示国际化
    generateTitle
  }
};
</script>

<style lang="scss" scoped>
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 16px;

  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>
