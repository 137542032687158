import Vue from 'vue';
import VueI18n from 'vue-i18n';
// import Cookies from 'js-cookie';
// import elementEnLocale from 'element-ui/lib/locale/lang/en'; // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'; // element-ui lang
import ptBrLang from 'element-ui/lib/locale/lang/pt-br'; // 葡萄牙-巴西
// import enLocale from './en';
import zhLocale from './zh';
import ptBrLocale from './ptBr';

Vue.use(VueI18n);

const messages = {
  // en: {
  //   ...enLocale,
  //   ...elementEnLocale
  // },
  zh: {
    ...zhLocale,
    ...elementZhLocale
  },
  pu: {
    ...ptBrLocale,
    ...ptBrLang
  }

};

export function getLanguage() {
  const chooseLanguage = window.sessionStorage.getItem('language');
  if (chooseLanguage) {
    return chooseLanguage;
  } else {
    // if has not choose language
    const language = (navigator.language || navigator.browserLanguage).toLowerCase();
    const locales = Object.keys(messages);
    for (const locale of locales) {
      if (language.indexOf(locale) > -1) {
        window.sessionStorage.setItem('language', locale);
        // console.log('语言1：', locale);
        return locale;
      } else {
        window.sessionStorage.setItem('language', 'pu');
      }
    }
    return 'pu';
  }
}

const i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  // set locale messages
  messages,
  silentTranslationWarn: true // 去国际化警告
});

export default i18n;
