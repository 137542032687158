<template>
  <div id="printBagNumber" ref="printBagNumber" class="fontSizeStyle">
    <div v-for="(item, index) in paramData" :key="index">
      <div
        ref="printBagNumberContent"
        class="printBagNumberContent"
        style="page-break-before: always;
        break-inside: avoid;
        "
      >
        <div style="display: flex;align-items: center;">
          <img :src="require('@/assets/ajlogo1.svg')" alt="tu" style="width: 100px;">
          <div style="font-size: 20px;margin-left: 20px;">RELATÓRIO DE EXPEDIÇÃO-DESTINO ✖ IATAS</div>
        </div>
        <table style="width: 100%;text-align: left;margin-top: 15px;border-collapse: collapse;">
          <tr>
            <td>Data: {{ item.uploadTime || '--' }}</td>
            <td>Vtag: {{ item.vehicleTagNumber || '--' }}</td>
          </tr>
          <tr style="background-color: #ffc000">
            <td colspan="2">DADOS MOTORISTA/ VEÍCULO:</td>
          </tr>
          <tr>
            <td>Nome: {{ item.vehicleDriver || '--' }}</td>
            <td style="background-color: #add88d">Outras informações</td>
          </tr>
          <tr>
            <td>RG/CPF: {{ item.itin || '--' }}</td>
            <td rowspan="4">{{ item.remark || '--' }}</td>
          </tr>
          <tr>
            <td>Veiculo: {{ item.vehicleTypeName || '--' }}</td>
          </tr>
          <tr>
            <td>Inicio Carregamento/Horário: {{ item.startLoadingTime || '--' }}</td>
          </tr>
          <tr>
            <td>Fim Carregamento/Horário: {{ item.endLoadingTime || '--' }}</td>
          </tr>
          <tr>
            <td colspan="2" style="background-color: #ffc000">Destino das lATAS (Expedicao/ Local)</td>
          </tr>
          <tr>
            <td>
              <div>Placa:</div>
              <div style="text-align: center;">{{ item.vehicleNumber || '--' }}</div>
              <div style="display: flex;justify-content: center">
                <div id="qrCanvas" />
              </div>
            </td>
            <td>
              <div>Vtag:</div>
              <div style="text-align: center;">{{ item.vehicleTagNumber || '--' }}</div>
              <div style="display: flex;justify-content: center">
                <div id="barcode" />
              </div>
            </td>
          </tr>
        </table>
        <div style="margin: 10px 0;">CONTAGEM</div>
        <table style="width: 100%;border-collapse: collapse;text-align: center;">
          <thead>
            <tr style="background-color: #add88d">
              <th>No.</th>
              <th style="width: 140px;">IATA</th>
              <th>Número de sacolas</th>
              <th>Quantidade de pacote grande</th>
              <th>Quantidade de pacote</th>
              <th>Tempo de descarga</th>
              <th>Número de descargas</th>
              <th>Confirmação da assinatura</th>
            </tr>
          </thead>
          <tbody v-for="(item1, index1) in item.siteAddressesSort01" :key="index1" style="text-align: center;">
            <tr>
              <td rowspan="2">{{ item1.sort || '--' }}</td>
              <td style="width: 140px;">{{ item1.name || '--' }}</td>
              <td>{{ item1.bagPackageCount || '--' }}</td>
              <td>{{ item1.cargoPackageCount || '--' }}</td>
              <td>{{ item1.totalPackageCount || '--' }}</td>
              <td />
              <td />
              <td />
            </tr>
            <tr style="text-align: left">
              <td colspan="5">Endereço: {{ item1.originAddress || '--' }}</td>
            </tr>
          </tbody>
        </table>
        <div style="display: flex;justify-content: space-between;margin-top: 20px;">
          <div style="border-bottom: 1px solid #000000">
            <div style="margin-bottom: 30px;">Nome Motorista</div>
          </div>
          <div style="border-bottom: 1px solid #000000">
            <div style="margin-bottom: 30px;">Nome Anjun (Expedição Perus)</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import printXq from 'print-js';
import QRCode from 'qrcodejs2';

export default {
  props: {
    paramData: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      qrData: ''
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.print01();
    },
    print01() {
      const _this = this;
      console.log(132132, this.paramData);
      this.generateQRCode();
      // JsBarcode('#barcode', this.paramData[0]?.vehicleId, {
      //   height: 60,
      //   displayValue: true
      // });
      this.generateQRCode2();
      _this.$nextTick(() => {
        printXq({
          printable: 'printBagNumber', // // 文档来源：pdf或图像的url，html元素的id或json数据的对象
          type: 'html', // 可打印的类型。可用的打印选项包括：pdf，html，图像，json和raw-html。
          targetStyles: ['*'], // 库在打印HTML元素时处理任何样式
          font_size: ''
        });
        console.log(11);
        _this.$emit('hiddenBagNumberHtml');
      });
    },
    generateQRCode() {
      this.$nextTick(() => {
        const qrCanvas = document.getElementById('qrCanvas');
        new QRCode(qrCanvas, {
          width: 100,
          height: 100,
          text: this.paramData[0]?.vehicleId, // 二维码地址
          colorDark: '#000',
          colorLight: '#fff'
        });
      });
    },
    generateQRCode2() {
      this.$nextTick(() => {
        const qrCanvas = document.getElementById('barcode');
        new QRCode(qrCanvas, {
          width: 100,
          height: 100,
          text: this.paramData[0]?.vehicleTagNumber, // 二维码地址
          colorDark: '#000',
          colorLight: '#fff'
        });
      });
    }
  }
};

</script>
<style scoped>
.fontSizeStyle * {
  font-size: 16px;
  box-sizing: border-box;
}

table {
  border: 1px solid black;
  border-collapse: collapse;
}

th, td {
  border: 1px solid black;
  padding: 5px;
  border-collapse: collapse;
}

#printBagNumber {
  page-break-before: always;
  break-inside: avoid; /* 避免元素内容在列间断开 */
}
</style>
