var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        display: "flex",
        "justify-content": "center",
        "align-items": "center",
        height: "calc(100vh - 85px)",
      },
    },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("h1", [
            _vm._v(_vm._s(_vm.$t("operatingPlatform.bagNumberReplacement"))),
          ]),
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticStyle: { width: "600px" },
              attrs: {
                "label-width": "200px",
                "label-position": "top",
                model: _vm.form,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("operatingPlatform.currentBagNumber"),
                    prop: "oldBagNumber",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t(
                        "operatingPlatform.enterTheCurrentBagNumber"
                      ),
                    },
                    model: {
                      value: _vm.form.oldBagNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "oldBagNumber", $$v)
                      },
                      expression: "form.oldBagNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("operatingPlatform.newBagNumber"),
                    prop: "newBagNumber",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t(
                        "operatingPlatform.enterTheNewBagNumber"
                      ),
                    },
                    model: {
                      value: _vm.form.newBagNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "newBagNumber", $$v)
                      },
                      expression: "form.newBagNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("operatingPlatform.swap")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }