<template>
  <div>
    <el-form
      ref="ruleForm" :model="ruleForm" :rules="rules"
      label-width="100px" class="demo-ruleForm"
    >
      <el-row>
        <el-col :span="12">
          <!-- 应付客户 -->
          <el-form-item :label="$t('ClaimsManagement.DealWithCustomers')">
            <span>{{ row.customerName }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- 理赔单号 -->
          <el-form-item :label="$t('ClaimsManagement.ClaimNumber')">
            <span>{{ row.claimsNumber }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- 理赔申请金额 -->
          <el-form-item :label="$t('ClaimsManagement.ClaimAmount')">
            <span>{{ row.claimAmount }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- 付款时间 -->
          <el-form-item :label="$t('receivablePayable.PaymentTime')" prop="payedTime">
            <el-date-picker
              v-model="ruleForm.payedTime"
              type="datetime"
              :placeholder="$t('newOrder.SelectDate')"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- 付款方式 -->
          <el-form-item :label="$t('basicData.PaymentMethod')" prop="payedType">
            <el-select v-model="ruleForm.payedType" :placeholder="$t('GlobalSetObj.pleaseChoose')">
              <el-option
                v-for="item in PAYTYPE_LIST"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- 付款账号 -->
          <el-form-item :label="$t('ClaimsManagement.PaymentAccountNumber')" prop="payAccount">
            <PaymentAccountNumberCmp v-model="ruleForm.payAccount" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- 付款金额 -->
          <el-form-item :label="$t('receivablePayable.TheAmountOfThePayment')" prop="actualClaimAmount">
            <el-input-number
              v-model="ruleForm.actualClaimAmount" :min="0.01" :max="999999999"
              :precision="2" :controls="false"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- 票证号 -->
          <el-form-item :label="$t('basicData.TicketNumber')">
            <el-input v-model.trim="ruleForm.claimsTicketNumber" maxlength="50" show-word-limit />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- 备注 -->
          <el-form-item :label="$t('GlobalSetObj.remark')">
            <el-input
              v-model.trim="ruleForm.remarks"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              maxlength="300"
              show-word-limit
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- 附件 -->
          <el-form-item :label="$t('customerManagements.enclosure')">
            <UploadCmp
              :drag="true" :multiple="true" :auto-upload="true"
              :limit="5" @change="onFileChange"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">{{ $t('GlobalSetObj.determine') }}</el-button>
        <el-button @click="onClose(false)">{{ $t('GlobalSetObj.cancel') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import PaymentAccountNumberCmp from './PaymentAccountNumberCmp.vue';
import { PAYTYPE_LIST } from './../utils';
import UploadCmp from './UploadCmp.vue';
import { apiClaimBillPayTodo } from '@/api/claimsManagement/statement';

export default {
  name: '',

  components: {
    UploadCmp,
    PaymentAccountNumberCmp
  },

  props: {
    row: {
      type: Object,
      default: () => {
        return {
          id: 0,
          customerId: 0, // 应付客户
          customerName: 0, // 应付客户
          claimsNumber: '', // 理赔单号
          claimAmount: 0// 理赔申请金额
        };
      }
    }
  },

  data() {
    const actualClaimAmountCheck = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('GlobalSetObj.pleaseInput'))); // 请输入付款金额
      }

      // 不能大于理赔申请金额
      if (value > this.row.claimAmount) {
        return callback(new Error('付款金额不能大于理赔申请金额'));
      }

      callback();
    };

    return {
      ruleForm: {
        payedTime: '', // 付款时间
        payedType: '', // 付款方式
        payAccount: '', // 付款账号
        actualClaimAmount: 0, // 付款金额
        claimsTicketNumber: '', // 票证号
        remarks: '', // 备注
        attachmentUrl: []// 附件
      },

      rules: {
        payedTime: [
          {
            required: true,
            message: this.$t('GlobalSetObj.pleaseChoose'), // 请选择付款时间
            trigger: 'change' }
        ],
        payedType: [
          {
            required: true,
            message: this.$t('GlobalSetObj.pleaseChoose'), // 请选择付款方式
            trigger: 'change' }
        ],
        payAccount: [
          {
            required: true,
            message: this.$t('GlobalSetObj.pleaseChoose'), // 请选择付款账号
            trigger: 'change' }
        ],
        actualClaimAmount: [
          { required: true, validator: actualClaimAmountCheck, trigger: 'blur' }
        ]
      }
    };
  },

  created() {
    this.PAYTYPE_LIST = PAYTYPE_LIST;
  },

  methods: {
    // 附件
    onFileChange(val) {
      this.ruleForm.attachmentUrl = val;
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        }

        const data = this.setData();

        this.asyncapiClaimBillPayTodo(data);
      });
    },

    setData() {
      const { payedTime, payedType, payAccount, actualClaimAmount, claimsTicketNumber, remarks, attachmentUrl } = this.ruleForm;

      let attachmentUrlStr = '';
      if (attachmentUrl && attachmentUrl.length) {
        attachmentUrlStr = attachmentUrl.map(item => item.url).join(',');
      }

      const obj = {
        id: this.row.id,
        payedTime,
        payedType,
        payAccount,
        actualClaimAmount,
        claimsTicketNumber,
        remarks,
        attachmentUrl: attachmentUrlStr
      };

      return obj;
    },

    onClose(isBool = false) {
      this.$emit('close', isBool);
    },

    asyncapiClaimBillPayTodo(data) {
      apiClaimBillPayTodo(data).then(res => {
        this.onClose(true);
      }).catch(err => {
        console.error(err);
      });
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
