var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Box" },
    [
      _c(
        "el-form",
        { staticClass: "selectClass" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { width: "auto" }, attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("collectionCenter.lanshouT") + "：",
                      },
                    },
                    [
                      _c("el-date-picker", {
                        ref: "dateRef",
                        attrs: {
                          clearable: false,
                          type: "datetimerange",
                          "default-time": ["00:00:00", "23:59:59"],
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "range-separator": _vm.$t("collectionCenter.to"),
                          "start-placeholder": _vm.$t(
                            "collectionCenter.StartTime"
                          ),
                          "end-placeholder": _vm.$t("collectionCenter.EndTime"),
                        },
                        on: { change: _vm.dateChange },
                        model: {
                          value: _vm.timeValue,
                          callback: function ($$v) {
                            _vm.timeValue = $$v
                          },
                          expression: "timeValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { width: "auto" }, attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("collectionCenter.LanShouSite") + "：",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            clearable: "",
                          },
                          on: { focus: _vm.getNewestSite },
                          model: {
                            value: _vm.queryForm.params.collectSiteId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "collectSiteId",
                                $$v
                              )
                            },
                            expression: "queryForm.params.collectSiteId",
                          },
                        },
                        _vm._l(_vm.siteIdList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { width: "auto" }, attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("collectionCenter.platform") + "：",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t(
                              "collectionCenter.pleaseChoose"
                            ),
                            clearable: "",
                          },
                          model: {
                            value: _vm.queryForm.params.platform,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm.params, "platform", $$v)
                            },
                            expression: "queryForm.params.platform",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("collectionCenter.tudo"),
                              value: null,
                            },
                          }),
                          _c("el-option", {
                            attrs: { value: 1, label: "shein" },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("collectionCenter.expressDelivery"),
                              value: 3,
                            },
                          }),
                          _c("el-option", {
                            attrs: { value: 2, label: "kwai" },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("collectionCenter.other"),
                              value: 0,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("collectionCenter.Barcode") + "：",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "BlockNewlineClass" },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              autosize: { maxRows: 5 },
                              placeholder: _vm.$t(
                                "collectionCenter.BatchQuery"
                              ),
                            },
                            on: { blur: _vm.ft },
                            nativeOn: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.BlockNewline($event)
                              },
                            },
                            model: {
                              value: _vm.queryForm.params.scanNumberList,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm.params,
                                  "scanNumberList",
                                  $$v
                                )
                              },
                              expression: "queryForm.params.scanNumberList",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value:
                        "btn:collection-center:collectNode-errData-report:find",
                      expression:
                        "'btn:collection-center:collectNode-errData-report:find'",
                      arg: "remove",
                    },
                  ],
                  attrs: {
                    icon: "el-icon-search",
                    size: "mini",
                    type: "primary",
                  },
                  on: { click: _vm.searchClick },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.search")) + " ")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value:
                        "btn:collection-center:collectNode-errData-report:reset",
                      expression:
                        "'btn:collection-center:collectNode-errData-report:reset'",
                      arg: "remove",
                    },
                  ],
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetClick },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")) + " ")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value:
                        "btn:collection-center:collectNode-errData-report:allExport",
                      expression:
                        "'btn:collection-center:collectNode-errData-report:allExport'",
                      arg: "remove",
                    },
                  ],
                  class: _vm.isClickAll ? "onlyClickOnce10" : "",
                  attrs: { icon: "el-icon-upload", size: "mini" },
                  on: { click: _vm.downloadAll },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-table",
        {
          ref: "tableRef",
          attrs: { border: "", "max-height": 600, data: _vm.tableData },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "scanNumber",
              align: "center",
              label: _vm.$t("collectionCenter.Barcode"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "orderNumber",
              align: "center",
              label: _vm.$t("collectionCenter.lanshouOrderNumber"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "orderState",
              align: "center",
              label: _vm.$t("collectionCenter.OrderStatus"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            scope.row.orderState == 0
                              ? _vm.$t("collectionCenter.notLanShou")
                              : scope.row.orderState == 1
                              ? _vm.$t("collectionCenter.hasLanShou")
                              : scope.row.orderState == 2
                              ? _vm.$t("collectionCenter.cancelled")
                              : scope.row.orderState == 4
                              ? _vm.$t("collectionCenter.scheduled")
                              : scope.row.orderState == 5
                              ? _vm.$t("collectionCenter.AbnormalLanShou")
                              : scope.row.orderState == 6
                              ? _vm.$t("collectionCenter.haveBeenSigned")
                              : scope.row.orderState == 7
                              ? _vm.$t("collectionCenter.ProblemPiece")
                              : scope.row.orderState == 8
                              ? _vm.$t("collectionCenter.SealedBag")
                              : scope.row.orderState == 9
                              ? _vm.$t("collectionCenter.truckLoading")
                              : scope.row.orderState == 10
                              ? _vm.$t("collectionCenter.unloading")
                              : scope.row.orderState == 11
                              ? _vm.$t("collectionCenter.Bagged")
                              : scope.row.orderState == 12
                              ? _vm.$t("collectionCenter.closedLoop")
                              : scope.row.orderState == 13
                              ? _vm.$t("collectionCenter.unclaimed")
                              : scope.row.orderState == 14
                              ? _vm.$t(
                                  "collectionCenter.collectionPointInventory"
                                )
                              : ""
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "customerId",
              align: "center",
              label: _vm.$t("collectionCenter.clientID"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "customerName",
              align: "center",
              label: _vm.$t("collectionCenter.CustomerName"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "platform",
              align: "center",
              label: _vm.$t("collectionCenter.platform"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            scope.row.platform == 0
                              ? _vm.$t("collectionCenter.other")
                              : scope.row.platform == 1
                              ? "shein"
                              : scope.row.platform == 2
                              ? "kwai"
                              : scope.row.platform == 3
                              ? _vm.$t("collectionCenter.expressDelivery")
                              : ""
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "merchantCode",
              align: "center",
              label: _vm.$t("collectionCenter.MerchantNum"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "merchantName",
              align: "center",
              label: _vm.$t("collectionCenter.MerchantName"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "collectTime",
              align: "center",
              label: _vm.$t("collectionCenter.lanshouT"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "collectUserName",
              align: "center",
              label: _vm.$t("collectionCenter.LanShouPeople"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "collectStationName",
              align: "center",
              label: _vm.$t("collectionCenter.LanShouSite"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "collectTimeLength",
              align: "center",
              label: _vm.$t("collectionCenter.cDuration"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "transportTimeLength",
              align: "center",
              label: _vm.$t("collectionCenter.tDuration"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "transitTimeLength",
              align: "center",
              label: _vm.$t("collectionCenter.tfDuration"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "arrivalTimeLength",
              align: "center",
              label: _vm.$t("collectionCenter.prescriptionArrival"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "senderState",
              align: "center",
              label: _vm.$t("collectionCenter.sendState"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "senderCity",
              align: "center",
              label: _vm.$t("collectionCenter.sendCity"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "senderZipcode",
              align: "center",
              label: _vm.$t("collectionCenter.SendZipcode"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "senderBigArea",
              align: "center",
              label: _vm.$t("collectionCenter.senderSubregion"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "senderZone",
              align: "center",
              label: _vm.$t("collectionCenter.senderOwningRegion"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "firstVehicleNumber",
              align: "center",
              label: _vm.$t("collectionCenter.firstLoadingLicensePlate"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "firstLoadedUser",
              align: "center",
              label: _vm.$t("collectionCenter.firstLoadedUser"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "firstLoadSite",
              align: "center",
              label: _vm.$t("collectionCenter.firstLoadSite"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "firstUnloadUser",
              align: "center",
              label: _vm.$t("collectionCenter.firstUnloadUser"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "firstUnloadSite",
              align: "center",
              label: _vm.$t("collectionCenter.firstUnloadSite"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "secondVehicleNumber",
              align: "center",
              label: _vm.$t("collectionCenter.secondLoadingLicensePlate"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "secondLoadedUser",
              align: "center",
              label: _vm.$t("collectionCenter.secondLoadedUser"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "secondLoadSite",
              align: "center",
              label: _vm.$t("collectionCenter.secondLoadSite"),
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-pagination", {
            staticStyle: { margin: "10px 0" },
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              "current-page": _vm.queryForm.pageNumber,
              "page-sizes": [10, 20, 50, 100, 500, 1000],
              "page-size": _vm.queryForm.pageSize,
              total: _vm.total,
              background: "",
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "current-change": _vm.getPage,
              "size-change": _vm.getPageSize,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }