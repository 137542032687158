<script >
import { ref, defineProps } from '@vue/composition-api';
import { default as ListSellers } from './step/listSeller';
import { default as AvailableSellers } from './step/availableSellers';
import { useEditRouter } from '@/api/transport/router/query';
const __sfc_main = {};
__sfc_main.props = {
  closeModal: {
    type: Function
  },
  route: {
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  function getListSellersRoute() {
    return props.route.rm_route_collect.rm_seller_route.map(seller => seller.seller_code);
  }
  const sellers = ref(getListSellersRoute());
  function onUpdateSellers(newSellers) {
    sellers.value = newSellers;
  }
  const sellerType = ref(0);
  const {
    mutate
  } = useEditRouter();
  async function handleSubmit() {
    mutate({
      sellers: sellers.value,
      id: props.route.route_id
    }, {
      onSuccess: () => {
        props.closeModal();
      }
    });
  }
  return {
    sellers,
    onUpdateSellers,
    sellerType,
    handleSubmit
  };
};
__sfc_main.components = Object.assign({
  ListSellers,
  AvailableSellers
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div class="wrapper">
    <o-tabs v-model="sellerType" :multiline="false">
      <o-tab-item
        :value="0"
        :label="$t('routes.listSeller')"
        class="wrapper-tab-item"
      >
        <ListSellers
          :sellers="sellers"
          @updateSeller="onUpdateSellers"
        />
      </o-tab-item>

      <o-tab-item
        :value="1"
        :label="$t('routes.addSeller')"
        class="wrapper-tab-item"
      >
        <AvailableSellers
          :sellers="sellers"
          @updateSeller="onUpdateSellers"
        />
      </o-tab-item>
    </o-tabs>

    <div class="wrapper-buttons">
      <o-button
        variant="primary"
        size="md"
        @click="handleSubmit"
      >
        {{ $t('routes.sendButton') }}
      </o-button>
    </div>
  </div>
</template>

<style scoped>
.wrapper{
  display: grid;
  gap: 1rem; /* 16px */
}
.wrapper-tab-item {
  display: grid;
  gap: 1.25rem/* 20px */;
}

.wrapper-buttons{
  display: flex;
  justify-content: flex-end;
  padding-top: 0.5rem; /* 8px */
}
</style>
