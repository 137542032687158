<template>
  <div class="app-container">
    <div class="selectClass" style="position: relative">
      <!-- <el-button class="putAway" size="small" @click="queryBoxShow = !queryBoxShow">{{ queryBoxShow?'收起查询':'展开查询' }}</el-button> -->
      <!-- <div v-show="queryBoxShow" class="searchContainer"> -->
      <el-form
        ref="queryForm"
        :model="queryForm.params"
        class="queryFormClass"
        size="mini"
        @submit.native.prevent
      >
        <el-row justify="start" style="flex-wrap: wrap;" type="flex">
          <el-col :span="7" style="width: auto;">
            <!-- 客户类型 -->
            <el-form-item :label="$t('customerManagements.customerType') + '：'">
              <el-radio-group v-model="queryForm.params.source" size="medium">
                <!-- 全部 -->
                <el-radio-button label="">{{ $t('customerManagements.all') }}</el-radio-button>
                <!-- 企业 -->
                <el-radio-button label="corporation">{{
                  $t('customerManagements.enterprise')
                }}
                </el-radio-button>
                <!-- 个人 -->
                <el-radio-button label="person">{{
                  $t('customerManagements.personal')
                }}
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;">
            <!-- 账号名称 -->
            <el-form-item :label="$t('customerManagements.AccountName') + '：'">
              <el-input
                v-model="queryForm.params.username"
                :placeholder="$t('operationCenter.PleaseEnterTheContent')"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;">
            <!-- 公司名称 -->
            <el-form-item :label="$t('customerManagements.CompanyName') + '：'">
              <el-input
                v-model="queryForm.params.company"
                :placeholder="$t('operationCenter.PleaseEnterTheContent')"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;">
            <!-- 网点： -->
            <el-form-item :label="$t('collectionCenter.site')">
              <el-select
                v-model="queryForm.params.siteId"
                :placeholder="$t('operationCenter.PleaseSelect')"
                clearable
                filterable
              >
                <el-option
                  v-for="(item, index) in siteIdList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row>

          </el-row> -->
      </el-form>
      <el-row justify="start" type="flex">
        <el-col :span="8">
          <div>
            <!-- 搜索 -->
            <el-button icon="el-icon-search" size="mini" type="primary" @click="getData(find=1)">{{
              $t('operationCenter.Procurar')
            }}
            </el-button>
            <!-- 重置 -->
            <el-button icon="el-icon-refresh" size="mini" @click="queryFormReset">{{
              $t('operationCenter.reset')
            }}
            </el-button>
          </div>
        </el-col>
      </el-row>
      <el-divider />
      <!-- </div> -->
    </div>
    <div style="display: flex; justify-content: space-between; margin-bottom: 20px">
      <span>
        <!-- 新增客户 -->
        <el-button
          v-permit:remove="'but:customer-managements:list:add'"
          icon="el-icon-plus"
          size="mini"
          type="primary"
          @click.stop="goPage({}, typeObj.add.code)"
        >{{ $t('operationCenter.add') }}</el-button>

        <!-- 发送客户信息 -->
        <!-- <msg-cmp
          :message-type="NODE_TYPE['arrearage'].code"
          :msg-arr="msgArr"
        /> -->
      </span>
    </div>
    <el-card>
      <el-table :data="tableData" border @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" />
        <!-- 序号 -->
        <el-table-column :label="$t('operationCenter.ind')" align="center" type="index" width="65">
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!-- 账户状态 -->
        <el-table-column
          :label="$t('customerManagements.AccountStatus')"
          align="center"
          prop="coPrincipalName"
          width="80"
        >
          <template slot-scope="scope">
            <!-- 启用 -->
            <el-tag v-if="scope.row.lockState === 0" type="success">{{ $t('customerManagements.enable') }}</el-tag>
            <!-- 停用 -->
            <el-tag v-else type="danger">{{ $t('customerManagements.Disable') }}</el-tag>
          </template>
        </el-table-column>
        <!-- 审核状态 -->
        <el-table-column
          :label="$t('customerManagements.ApprovalStatus')"
          align="center"
          prop="coPrincipalName"
          width="100"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.auditState === 0" type="info">未审核</el-tag>
            <el-tag v-else type="success">审核通过</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('customerManagements.clientID')"
          prop="id"
          width="100"
        /><!-- 客户编号 -->
        <!-- 客户类型 -->
        <el-table-column
          :label="$t('customerManagements.customerType')"
          align="center"
          prop="coPrincipalName"
          width="100"
        >
          <template slot-scope="scope">
            <!-- 企业 -->
            <el-tag v-if="scope.row.source === 'corporation'">{{ $t('customerManagements.enterprise') }}</el-tag>
            <!-- 个人 -->
            <el-tag v-else-if="scope.row.source === 'person'" type="success">{{
              $t('customerManagements.personal')
            }}
            </el-tag>
            <span v-else>/</span>
          </template>
        </el-table-column>
        <!-- 账号名称 -->
        <el-table-column :label="$t('customerManagements.AccountName')" width="120">
          <template slot-scope="scope">
            <div
              class="is-click-column"
            >
              {{ scope.row.username }}
            </div>
          </template>
        </el-table-column>
        <!-- 账户别称 -->
        <el-table-column :label="$t('customerManagements.AccountNickname')">
          <template slot-scope="scope">
            <div>{{ scope.row.customerAlias }}</div>
          </template>
        </el-table-column>
        <!-- 企业负责人 -->
        <el-table-column
          :label="$t('customerManagements.LegalRepresentive')"
          prop="coPrincipalName"
          width="100"
        />
        <!-- 物流联系人 -->
        <el-table-column
          :label="$t('customerManagements.LogisticsContact')"
          prop="logisticsContactsName"
          width="100"
        />
        <!-- 公司名称 -->
        <el-table-column :label="$t('customerManagements.CompanyName')" prop="company" />

        <el-table-column
          :label="$t('customerManagements.FinancialStatus')"
          prop="accountStatus"
          width="80"
        >
          <!-- 财务状态 -->
          <template slot-scope="scope">
            <!-- 欠费 -->
            <el-tag v-if="scope.row.accountStatus == 1">{{ $t('userManage.owe') }}</el-tag>
            <!-- 正常 -->
            <el-tag v-else-if="scope.row.accountStatus == 0" type="success">{{ $t('userManage.normal') }}</el-tag>
            <el-tag v-else type="danger">{{ $t('customerManagements.Disable') }}</el-tag>
          </template>
        </el-table-column>
        <!-- 账户余额 -->
        <el-table-column
          :label="$t('customerManagements.AccountBalance')"
          prop="balance"
          width="90"
        />
        <!-- 信用额度 -->
        <el-table-column
          :label="$t('customerManagements.credits')"
          prop="initialCreditLimit"
          width="90"
        />
        <!-- 积分 -->
        <el-table-column :label="$t('customerManagements.integral')" prop="integral" width="50" />
        <!-- 等级 -->
        <el-table-column :label="$t('customerManagements.grade')" prop="level" width="50" />
        <!-- 站点 -->
        <el-table-column :label="$t('userManage.site')" prop="siteName" width="90" />
        <!-- 创建人 -->
        <el-table-column :label="$t('userManage.creator')" prop="createName" width="120" />
        <!-- 创建时间 -->
        <el-table-column :label="$t('CenterForSorting.CreationTime')" prop="createTime" width="160" />
        <!-- 创建网点 -->
        <el-table-column :label="$t('customerManagements.createSiteName')" prop="createSiteName" width="90" />
        <!-- 修改人 -->
        <el-table-column :label="$t('CenterForSorting.ModifiedBy')" prop="updateName" width="120" />
        <!-- 修改时间 -->
        <el-table-column :label="$t('CenterForSorting.ModificationTime')" prop="updateTime" width="160" />
        <!-- 修改网点 -->
        <el-table-column :label="$t('customerManagements.updateSiteName')" prop="updateSiteName" width="90" />

        <!-- 操作 -->
        <el-table-column :label="$t('operationCenter.operate')" fixed="right" width="155">
          <template slot-scope="scope">
            <el-popover placement="bottom-start" trigger="click">
              <div>
                <!-- 查看 -->
                <el-button
                  v-permit:remove="'but:customer-managements:list:get'"
                  size="mini"
                  type="info"
                  @click.stop="goPage(scope.row, typeObj.examine.code, scope.$index)"
                >{{ $t('operationCenter.View') }}
                </el-button>
                <!-- 编辑 -->
                <el-button
                  v-permit:remove="'but:customer-managements:list:edit'"
                  size="mini"
                  type="success"
                  @click.stop="goPage(scope.row, typeObj.edit.code, scope.$index)"
                >{{ $t('operationCenter.Edit') }}
                </el-button>
                <div v-permit:remove="'but:customer-managements:list:audit'" class="fl">
                  <!-- 账户审核 -->
                  <el-button
                    v-if="scope.row.auditState === 0"
                    class="ml10"
                    size="mini"
                    type="warning"
                    @click.stop="goPage(scope.row, typeObj.set.code)"
                  >{{ $t('customerManagements.Approval') }}
                  </el-button>
                  <template v-else>
                    <div v-permit:remove="'but:customer-managements:list:lock'">
                      <!-- 账户启用 -->
                      <el-button
                        v-if="scope.row.lockState === 1"
                        class="ml10"
                        size="mini"
                        type="warning"
                        @click.stop="goPage(scope.row, typeObj.set.code)"
                      >{{ $t('ChannelManage.enable') }}
                      </el-button>
                      <!-- 账户停用 -->
                      <el-button
                        v-else
                        class="ml10"
                        size="mini"
                        type="danger"
                        @click.stop="goPage(scope.row, typeObj.set.code)"
                      >{{ $t('ChannelManage.Disable') }}
                      </el-button>
                    </div>
                  </template>
                </div>
                <!-- 重置密码 -->
                <el-button
                  v-permit:remove="'but:customer-managements:list:reset-pwd'"
                  class="ml10"
                  size="mini"
                  type="danger"
                  @click.stop="handleResetPwd(scope.row.id)"
                >{{ $t('customerManagements.resetPassword') }}
                </el-button>
              </div>
              <el-button slot="reference" size="mini">{{
                $t('operationCenter.MoreActions')
              }}
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <!-- 分页工具 -->
        <el-pagination
          :current-page.sync="queryForm.pageNumber"
          :page-size.sync="queryForm.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          style="margin: 10px 0"
          @current-change="getData(find=0)"
          @size-change="getData(find=0)"
        />
      </div>
    </el-card>
    <div v-if="drawerObjVisible">
      <el-drawer
        :title="drawerObj.title"
        :visible.sync="drawerObjVisible"
        :wrapper-closable="true"
        direction="ltr"
        size="65%"
      >
        <tab-cmp
          ref="tabCmps"
          :currency-obj="currencyObj"
          :customer-type="drawerObj.customerType"
          :send-arr="sendArr"
          @updataData="updataData"
        />
      </el-drawer>
    </div>
    <!-- 重置密码 -->
    <el-dialog
      :close-on-click-modal="true"
      :title="$t('customerManagements.resetPassword')"
      :visible.sync="resetPwdObj.visible"
      width="30%"
    >
      <reset-pwd-cmp
        v-if="resetPwdObj.visible"
        :ids="resetPwdObj.ids"
        @handleCancel="resetPwdObj.visible = false"
        @handleDetermine="handleDetermine"
      />
    </el-dialog>

    <!--    充值金额-->
    <!--                          '充值金额' : '修改信用额度' -->
    <el-dialog
      :close-on-click-modal="false"
      :title="rechargeType === 1 ? '充值金额' : $t('customerManagements.ModifyCreditLimit')"
      :visible.sync="showType"
      width="40%"
    >
      <div>
        <el-form
          ref="recharge"
          :model="recharge"
          :rules="rules"
          class="queryFormClass"
          label-width="100px"
        >
          <el-form-item v-if="rechargeType === 1" label="充值金额:" prop="rechargeAmount">
            <el-input v-model="recharge.rechargeAmount" :placeholder="$t('customerManagements.pleaseInput')" clearable />
          </el-form-item>
          <!-- 信用额度： -->
          <el-form-item v-if="rechargeType === 2" :label="$t('customerManagements.creditsL')" prop="creditLimit">
            <el-input v-model="recharge.creditLimit" :placeholder="$t('customerManagements.pleaseInput')" clearable />
          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: right">
        <el-button size="small" type="info" @click="updataAmount(0)">{{ $t('customerManagements.cancel') }}</el-button>
        <!-- 取消 -->
        <el-button size="small" type="primary" @click="updataAmount(1)">{{
          $t('customerManagements.confirm')
        }}
        </el-button><!-- 确定 -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { searchSiteIdList } from '@/api/lanshou';
import { apiCustomerPage, apiResetPassword } from '@/api/customer';
// import { apiAddressBook } from '@/api/orderApi';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { getUserName } from '@/utils/asyncTools';
import { TYPY_OBJ } from '@/enums/customerEnums';
import { priceType } from '@/utils/validate';
import { NODE_TYPE } from '@/enums/messageRecordEnums';
// import { currencyTypePage, changeCreditLimit } from '@/api/finance/partition';

export default {
  name: 'CustomerList',
  components: {
    // 'customer-cmp': CustomerCmp
    'tab-cmp': () => import('./TabsCmp'),
    'reset-pwd-cmp': () => import('./cmp/ResetPwdCmp')
    // 'msg-cmp': () => import('@/components/SendMessage/index')
  },

  data() {
    return {
      drawerObj: {
        title: '新增客户',
        customerType: {
          id: '', // 客户id
          customerAlias: '', // 客户别名
          type: ''
        }
      },
      drawerObjVisible: false,
      queryForm: {
        pageSize: 20,
        pageNumber: 1,
        params: {
          source: '',
          username: '',
          company: '',
          siteId: ''
        }
      },
      siteIdList: [], // 站点list

      tableData: [],
      total: 0,

      userObj: [],

      typeObj: TYPY_OBJ,
      // queryBoxShow: true,

      resetPwdObj: {
        visible: false,
        ids: 0
      },
      sendArr: [], // 手机人地址簿
      recharge: {
        creditLimit: '', // 信用额度
        rechargeAmount: '' // 充值金额
      },
      rechargeType: 1,
      showType: false,
      rechargeId: 1,
      rules: {
        creditLimit: [{ required: true, validator: priceType, trigger: ['blur', 'change'] }],
        rechargeAmount: [{ required: true, validator: priceType, trigger: ['blur', 'change'] }]
      },

      msgArr: [], // 发送信息
      currencyObj: [] // 币种
    };
  },

  created() {
    this.getData(1);
    this.userObj = getUserIdAndNameObj();

    this.NODE_TYPE = NODE_TYPE;

    searchSiteIdList().then(res => {
      if (res.status === 'OK') {
        res.data.forEach(data => {
          this.siteIdList.push({ id: data.id, name: data.name });
        });
      }
    });
  },

  methods: {
    // 充值、修改信用额度
    rechargeClick(id, type) {
      this.rechargeId = id;
      this.rechargeType = type;
      this.showType = true;
    },

    getData(find) {
      if (find === 1) {
        this.queryForm.pageNumber = 1;
      }
      apiCustomerPage(this.queryForm)
        .then((res) => {
          if (res.status === 'OK') {
            this.total = res.data.total;
            this.tableData = res.data.records.map((item) => {
              const creatUserName = getUserName(item.createUser, this.userObj);
              return {
                ...item,
                creatUserName,
                integral: item.integral || 0,
                level: item.level || 0
              };
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // table 多选框
    handleSelectionChange(arr) {
      this.msgArr = arr.map((item) => {
        const { id, customerAlias } = item;
        return {
          id,
          name: customerAlias
        };
      });
    },

    /**
     * 置空
     */
    queryFormReset() {
      for (const key in this.queryForm.params) {
        this.queryForm.params[key] = '';
      }
    },

    /**
     * 跳转到详情或者新增页面
     */
    goPage({ id, customerAlias }, type, index) {
      this.drawerObjVisible = true;
      this.drawerObj.customerType.id = id;
      this.drawerObj.customerType.type = type;
      this.drawerObj.customerType.customerAlias = customerAlias;
      // this.drawerObj.title = TYPY_OBJ[type].name;

      if (type === TYPY_OBJ.add.code) {
        // 新增
        this.drawerObj.title = this.$t('customerManagements.add');
        this.$store.dispatch('user/getManagerList', '');
      } else if (type === TYPY_OBJ.edit.code) {
        this.drawerObj.title = this.$t('customerManagements.edit');
        // 判断有没有经办人，没有请求第一页列表
        if (index && this.tableData[index].handlerId) {
          this.$store
            .dispatch('user/getManagerList', this.tableData[index].handlerName)
            .then((res) => {
              this.getData(1);
            });
        } else {
          this.$store.dispatch('user/getManagerList', '');
        }
        // 编辑
      } else if (type === TYPY_OBJ.examine.code) {
        this.drawerObj.title = this.$t('customerManagements.View');
        // 判断有没有经办人，没有请求第一页列表
        if (index && this.tableData[index].handlerId) {
          this.$store.dispatch('user/getManagerList', this.tableData[index].handlerName);
          this.getData(1);
        } else {
          this.$store.dispatch('user/getManagerList', '');
        }
        // 查看
      } else {
        this.drawerObj.title = this.$t('customerManagements.Approval');
        console.log(type);
      }
    },

    updataData(isBool = true) {
      this.getData(1);
      if (isBool) {
        this.drawerObjVisible = false;
      }
    },

    // 重置
    handleResetPwd(ids) {
      this.resetPwdObj.ids = ids;
      this.resetPwdObj.visible = true;
    },

    // 修改密码
    handleDetermine(obj) {
      apiResetPassword(obj)
        .then((res) => {
          if (res.status !== 'OK') {
            return;
          }
          this.$message({
            message: this.$t('customerManagements.resetSucceeded'), // 重置密码成功
            type: 'success'
          });
          this.resetPwdObj.visible = false;
          this.getData(find);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>

.selectClass ::v-deep .el-form-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.selectClass ::v-deep .el-form-item__label {
  padding: 0;
  line-height: 16px;
  white-space: nowrap;
  margin-left: 20px;
}

.selectClass ::v-deep .el-form-item__content {
  margin-left: 0 !important;
}

.selectClass ::v-deep .el-select {
  width: 100%;
}

.selectClass ::v-deep .el-input__inner {
  height: 40px;
}

.ml10 {
  margin-left: 10px;
}

.fl {
  display: inline-block;
}

.putAway {
  position: absolute;
  right: 0;
  top: 0;
  color: #1989fa;
  cursor: pointer;
  z-index: 100;
}
</style>
