var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "counter-item" }, [
    _c("div", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))]),
    _c("div", { staticClass: "value" }, [
      _vm._v(" " + _vm._s(_vm._f("formatNumber")(_vm.value)) + " "),
    ]),
    _c("span", { staticClass: "reduce", class: { down: _vm.change < 0 } }, [
      _vm._v(" " + _vm._s(_vm._f("formatNumber")(_vm.change, true)) + " "),
      _c("span", [_vm._v(_vm._s(_vm.$t("newOrder.ComparedToYesterday")))]),
    ]),
    _vm.change != 0
      ? _c("img", {
          class: ["image", _vm.change < 0 ? "" : "rise"],
          attrs: {
            src: require("@/assets/images/kanban/customerOrderReduction.png"),
          },
        })
      : _c("img", {
          staticClass: "image",
          attrs: {
            src: require("@/assets/images/kanban/customerOrderReduction0.png"),
          },
        }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }