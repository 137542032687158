var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WrapperHandleError",
    { attrs: { error: _vm.error, "is-error": _vm.isError } },
    [
      _c("SectionHeader", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("h1", { staticClass: "header-1" }, [
                  _vm._v(_vm._s(_vm.$t("vehicle.vehicleTitle"))),
                ]),
                _c(
                  "o-button",
                  {
                    attrs: { variant: "primary", size: "md" },
                    on: { click: _vm.handleModalCreate },
                  },
                  [_vm._v(_vm._s(_vm.$t("vehicle.createVehicle")))]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "filter",
            fn: function () {
              return [
                _c(
                  "o-field",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.filters.status !== 2,
                        expression: "filters.status !== 2",
                      },
                    ],
                    staticClass: "flex-2",
                    attrs: {
                      label: _vm.$t("vehicle.plateLabel"),
                      "label-for": "plate",
                    },
                  },
                  [
                    _c("o-input", {
                      attrs: {
                        id: "plate",
                        placeholder: _vm.$t("vehicle.platePlaceholder"),
                      },
                      model: {
                        value: _vm.filters.plate,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "plate", $$v)
                        },
                        expression: "filters.plate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "o-field",
                  {
                    staticClass: "flex-1",
                    attrs: {
                      label: _vm.$t("vehicle.statusLabel"),
                      "label-for": "status",
                    },
                  },
                  [
                    _c(
                      "o-select",
                      {
                        attrs: {
                          id: "status",
                          placeholder: "Selecione o status",
                        },
                        model: {
                          value: _vm.filters.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "status", $$v)
                          },
                          expression: "filters.status",
                        },
                      },
                      [
                        _c("option", { domProps: { value: 1 } }, [
                          _vm._v(_vm._s(_vm.$t("vehicle.activeStatus"))),
                        ]),
                        _c("option", { domProps: { value: 0 } }, [
                          _vm._v(_vm._s(_vm.$t("vehicle.disabledStatus"))),
                        ]),
                        _c("option", { domProps: { value: 2 } }, [
                          _vm._v(_vm._s(_vm.$t("vehicle.rotationStatus"))),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "SkeletonTable",
        { attrs: { "is-loading": _vm.isLoadingTable } },
        [
          _c(
            "o-table",
            {
              staticClass: "space-m-y-32",
              attrs: {
                data:
                  _vm.filters.status === 2
                    ? _vm.vehicleDataOnRotation
                    : _vm.tableData,
                "row-key": "id",
                bordered: "",
                paginated: "",
                "backend-pagination": "",
                total:
                  _vm.filters.status === 2
                    ? _vm.totalVehicleOnRotation
                    : _vm.tableTotal,
                "per-page":
                  _vm.filters.status === 2
                    ? _vm.queryParamsRotateVehicles.perPage
                    : _vm.queryParams.perPage,
                "current-page": _vm.currentPagePagination,
                "pagination-simple": false,
              },
              on: {
                "update:currentPage": function ($event) {
                  _vm.currentPagePagination = $event
                },
                "update:current-page": function ($event) {
                  _vm.currentPagePagination = $event
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "empty",
                  fn: function () {
                    return [
                      _vm._v(" " + _vm._s(_vm.$t("vehicle.noResults")) + " "),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("o-table-column", {
                attrs: {
                  field: "vehicle_documentation",
                  label: _vm.$t("vehicle.documentLabel"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(
                          " " + _vm._s(props.row.vehicle_documentation) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "vehicle_license_plate",
                  label: _vm.$t("vehicle.plateLabel"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(
                          " " + _vm._s(props.row.vehicle_license_plate) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "vehicle_RNTRC",
                  label: _vm.$t("vehicle.rntrc"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(" " + _vm._s(props.row.vehicle_RNTRC) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "vehicle_model",
                  label: _vm.$t("vehicle.modelLabel"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(" " + _vm._s(props.row.vehicle_model) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "vehicle_brand",
                  label: _vm.$t("vehicle.brandLabel"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(" " + _vm._s(props.row.vehicle_brand) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "vehicle_body_type",
                  label: _vm.$t("vehicle.bodyTypeLabel"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(" " + _vm._s(props.row.vehicle_body_type) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "vehicle_wheelset_type",
                  label: _vm.$t("vehicle.wheelTypeLabel"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(
                          " " + _vm._s(props.row.vehicle_wheelset_type) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "vehicle_type_ownership",
                  label: _vm.$t("vehicle.ownerLabel"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              props.row.vehicle_type_ownership
                                ? props.row.vehicle_owner_CNPJ
                                : props.row.vehicle_owner_CPF
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "vehicle_state",
                  label: _vm.$t("vehicle.stateLabel"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(" " + _vm._s(props.row.vehicle_state) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: {
                  field: "vehicle_outsourced",
                  label: _vm.$t("vehicle.outsourcedLabel"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              props.row.vehicle_outsourced
                                ? _vm.$t("vehicle.yes")
                                : _vm.$t("vehicle.no")
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: { label: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("Actions", {
                          attrs: { row: row, status: _vm.filters.status },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "o-modal",
        {
          attrs: { active: _vm.isOpenModalCreate, width: "560" },
          on: { close: _vm.handleVisibilityModalCreate },
        },
        [
          _c("div", [
            _c("h2", { staticClass: "header-2" }, [
              _vm._v(_vm._s(_vm.$t("vehicle.createTitle"))),
            ]),
            _c("p", { staticClass: "paragraph" }, [
              _vm._v(_vm._s(_vm.$t("vehicle.createParagraph"))),
            ]),
          ]),
          _c("FormCreateVehicle", {
            attrs: { "close-modal": _vm.handleVisibilityModalCreate },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }