// 备用号码池
const spareNumberPoo = {
  ThemRoughlyName: {
    zh: '号段名称',
    en: '',
    pu: 'nome de campo numérico'
  },
  pleaseInput: {
    zh: '请输入',
    en: 'Please enter the content',
    pu: 'por favor digitir'
  },
  pleaseEnterInteger: {
    zh: '请输入整数',
    en: '',
    pu: 'Por favor, insira o número inteiro'
  },
  pleaseChoose: {
    zh: '请选择',
    en: 'Please select',
    pu: 'Favor selecionar'
  },
  inputPrompt: {
    zh: '请输入内容',
    en: '',
    pu: 'Por favor digite conteúdo'
  },
  Only10AreDisplayed: {
    zh: '只显示10条，可搜索',
    en: 'Only 10 are displayed and can be searched',
    pu: 'Mostrar apenas 10 mensagens, pesquisáveis'
  },
  Only20AreDisplayed: {
    zh: '只显示20条，可搜索',
    en: 'Only 20 are displayed and can be searched',
    pu: 'Mostrar apenas 20 mensagens, pesquisáveis'
  },
  CollapseQuery: {
    zh: '收起查询',
    en: 'Collapse the Query button',
    pu: 'Recolher o botão Consulta'
  },
  ExpandQuery: {
    zh: '展开查询',
    en: 'Expand the Query button',
    pu: 'Expanda o botão Consulta'
  },
  search: {
    zh: '查询',
    en: '',
    pu: 'consultar'
  },
  reset: {
    zh: '重置',
    en: 'reset',
    pu: 'Reconfigurar'
  },
  add: {
    zh: '新增',
    en: 'add',
    pu: 'adicionar'
  },
  addNumberSegment: {
    zh: '新增号码段',
    en: '',
    pu: 'adicionar campo numérico'
  },
  BeganNumber: {
    zh: '开始号码',
    en: '',
    pu: 'número de início'
  },
  endNumber: {
    zh: '结束号码',
    en: '',
    pu: 'número de fim'
  },
  HasBeenUsed: {
    zh: '已用到',
    en: '',
    pu: 'já foi utilizado'
  },
  operation: {
    zh: '操作',
    en: 'operation',
    pu: 'Operar'
  },
  edit: {
    zh: '编辑',
    en: 'Edit',
    pu: 'editar'
  },
  View: {
    zh: '查看',
    en: 'View',
    pu: 'ver'
  },
  ThemRoughlyExtracted: {
    zh: '号段提取',
    en: '',
    pu: 'extrair campo numérico'
  },
  Delete: {
    zh: '删除',
    en: 'Delete',
    pu: 'Excluir'
  },
  AreYouSureDelete: {
    zh: '确定删除吗？',
    en: 'Are you sure to delete it',
    pu: 'Tem certeza de apagar?'
  },
  SetUp: {
    zh: '设置',
    en: 'Set up',
    pu: 'configurar'
  },
  PleaseUploadTXT: {
    zh: '请上传 .txt 文件',
    en: 'Please upload .txt file',
    pu: 'Por favor, carregue .txt arquivo'
  },
  cancel: {
    zh: '取消',
    en: 'cancel',
    pu: 'Cancelar'
  },
  confirm: {
    zh: '确定',
    en: 'determine',
    pu: 'determinar'
  },
  detail: {
    zh: '详情',
    en: 'detail',
    pu: 'detalhe'
  },
  DeleteSucceeded: {
    zh: '删除成功',
    en: 'Delete succeeded',
    pu: 'Excluir foi bem sucedido'
  },
  numberSegmentStartValue: {
    zh: '号段开始值',
    en: '',
    pu: 'Valor inicial do campo numérico'
  },
  numberSegmentEndValue: {
    zh: '号段结束值',
    en: '',
    pu: 'Valor final do campo numérico'
  },
  ImmediatelyCreate: {
    zh: '立即创建',
    en: '',
    pu: 'criar imediatamente'
  },
  OperationalLogs: {
    zh: '操作日志',
    en: 'Operational logs',
    pu: 'Diário de operações'
  },
  noMore: {
    zh: '没有更多了',
    en: '',
    pu: 'Nada mais'
  },
  notNull: {
    zh: '不能为空',
    en: 'Cannot be empty',
    pu: 'Não pode estar vazio'
  },
  long1to4: {
    zh: '长度在 1 到 4 个',
    en: '',
    pu: 'Comprimento entre 1 e 4'
  },
  inputCapitalLetters: {
    zh: '请输入纯大写字母',
    en: '',
    pu: 'Favor digitar em letras maiúsculas'
  },
  PleaseEnterTheLengthAs: {
    zh: '请输入长度为',
    en: '',
    pu: 'Por favor, insira...dígitos'
  },
  numberOf: {
    zh: '的数字',
    en: '',
    pu: 'de número'
  },
  MaximumStartingNumber: {
    zh: '最大开始数字',
    en: '',
    pu: 'Número máximo de início'
  },
  largestNumber: {
    zh: '最大数字',
    en: '',
    pu: 'Número máximo'
  },
  startValueNotThanEndValue: {
    zh: '开始值不能大于等于结束值',
    en: '',
    pu: 'O valor inicial não pode ser maior ou igual ao valor final'
  },
  PleaseEnterName: {
    zh: '请输入名称',
    en: '',
    pu: 'Favor inserir o nome'
  },
  long3to10: {
    zh: '长度在 3 到 10 个字符',
    en: '',
    pu: 'Comprimento entre 3 e 10 caracteres'
  },
  addSuc: {
    zh: '新增成功',
    en: '',
    pu: 'adicionar com sucesso'
  },
  ModificationSuccessful: {
    zh: '修改成功',
    en: 'Modification successful',
    pu: 'Modificação bem sucedida'
  },
  DeleteSuccessful: {
    zh: '删除成功',
    en: 'Delete successful',
    pu: 'Excluir bem-sucedido'
  },
  ExtractionSuccess: {
    zh: '提取成功',
    en: '',
    pu: 'extrair com sucesso'
  },
  CustomerID: {
    zh: '客户ID:',
    en: '',
    pu: 'ID do cliente: '
  },
  in: {
    zh: '在',
    en: '',
    pu: 'Em'
  },
  Extract: {
    zh: '提取了',
    en: '',
    pu: 'extrairam'
  },
  number: {
    zh: '个号码',
    en: '',
    pu: 'números'
  },
  loading: {
    zh: '正在努力加载中...',
    en: '',
    pu: 'Carregamento em andamento...'
  },
  to: {
    zh: '至',
    en: '',
    pu: 'até'
  }, // 00至12
  OperationDescription: {
    zh: '操作描述：',
    en: '',
    pu: 'Descrição da operação:'
  },
  OperationIP: {
    zh: '操作IP：',
    en: '',
    pu: 'IP da operação:'
  },
  OperationName: {
    zh: '操作人姓名：',
    en: '',
    pu: 'nome do operador'
  },
  OperationType: {
    zh: '操作类型：',
    en: 'The type of operation：',
    pu: 'O tipo de operação:'
  },
  typeOperation: {
    zh: '操作类型',
    en: 'The type of operation',
    pu: 'O tipo de operação'
  },
  update: {
    zh: '更新',
    en: 'update',
    pu: 'atualizar'
  },
  append: {
    zh: '添加',
    en: '',
    pu: 'adicionar'
  },
  delete: {
    zh: '删除',
    en: '',
    pu: 'excluir'
  },
  UpdateParameter: {
    zh: '更新参数：',
    en: '',
    pu: 'Parâmetros atualizados:'
  },
  ExtractOdd: {
    zh: '提取单号',
    en: '',
    pu: 'extrair número do pedido'
  },
  NumberStart: {
    zh: '号码开始',
    en: '',
    pu: 'Número Início'
  },
  NumberEnd: {
    zh: '号码结束',
    en: '',
    pu: 'Número Fim'
  },
  ExtractNumber: {
    zh: '提取数量',
    en: '',
    pu: 'quantidade de extracto'
  },
  extractMax: {
    zh: '提取最大',
    en: '',
    pu: 'extrato Max'
  },
  extractType: {
    zh: '提取类型',
    en: '',
    pu: 'Tipo de extracto'
  },
  oneselfUse: {
    zh: '自用',
    en: '',
    pu: 'Auto-utilização'
  },
  CustomersUse: {
    zh: '客户使用',
    en: '',
    pu: 'Uso pelo cliente'
  },
  note: {
    zh: '备注',
    en: 'remark',
    pu: 'observações'
  },
  tipLongMax: {
    zh: '提示2：一次提取号码段长度最多',
    en: '',
    pu: 'Dica 2: Comprimento máximo dos campos numéricos a serem extraídos de uma só vez'
  },
  wan5: {
    zh: ' 5万',
    en: '',
    pu: '50 mil'
  },
  strip: {
    zh: '条',
    en: 'strip',
    pu: 'Artigo'
  },
  ID: {
    zh: 'ID',
    en: '',
    pu: 'ID'
  },
  NumberPoolName: {
    zh: '号码池名称',
    en: '',
    pu: 'Nome da piscina de números'
  },
  unUsed: {
    zh: '未使用',
    en: '',
    pu: 'Não utilizado'
  },
  minimumThreshold: {
    zh: '最低阀值',
    en: '',
    pu: 'Limiar mínimo'
  },
  NotifyingPeople: {
    zh: '通知人',
    en: '',
    pu: 'Notificador'
  },
  BelowTheThresholdNotifyingPeople: {
    zh: '低于阈值通知人',
    en: '',
    pu: 'Abaixo do limiar notificador'
  },
  EnterPeople: {
    zh: '录入人',
    en: '',
    pu: 'registrado por'
  },
  EnterTime: {
    zh: '录入时间',
    en: '',
    pu: 'Tempo de registro'
  },
  ImportData: {
    zh: '导入数据',
    en: '',
    pu: 'Importar dados'
  },
  NumberSegmentToBeUploaded: {
    zh: '待上传号码段',
    en: '',
    pu: 'campos numéricos a serem carregados'
  },
  NoContentYet: {
    zh: '暂无内容',
    en: 'No content yet',
    pu: 'Nenhum conteúdo ainda'
  },
  Upload: {
    zh: '上传',
    en: '',
    pu: 'Upload'
  },
  SelectTheFile: {
    zh: '选取文件',
    en: '',
    pu: 'selecionar Selecione o arquivo'
  },
  onlyTxtFile: {
    zh: '只能上传 txt 文件',
    en: '',
    pu: 'Somente arquivos txt podem ser carregados'
  },
  Import: {
    zh: '导入',
    en: 'Import',
    pu: 'Importação'
  },

  PlatformName: {
    zh: '平台名称：',
    en: '',
    pu: 'Nome da Plataforma:'
  },
  ind: {
    zh: '序号',
    en: 'index',
    pu: 'Número de série.'
  },
  PlatformTitle: {
    zh: '平台名称',
    en: '',
    pu: 'Nome da Plataforma'
  },
  PlatformCode: {
    zh: '平台代码',
    en: '',
    pu: 'código da Plataforma'
  },
  Editor: {
    zh: '编辑人',
    en: 'Editor',
    pu: 'Editor'
  },
  EditTime: {
    zh: '编辑时间',
    en: 'Edit time',
    pu: 'Hora de edição'
  },
  client: {
    zh: '客户',
    en: 'client',
    pu: 'Cliente'
  },
  modify: {
    zh: '修改',
    en: 'modify',
    pu: 'modificar'
  },
  AreYouSureDeletePlatform: {
    zh: '确定删除该平台？',
    en: '',
    pu: 'Tem certeza de excluir a plataforma?'
  },
  long2to8: {
    zh: '长度在 2 到 8 个字符',
    en: '',
    pu: 'Comprimento entre 2 e 8 caracteres'
  },
  PleaseSelectNotifier: {
    zh: '请选择通知人',
    en: '',
    pu: 'Favor selecionar o notificador'
  },
  PleaseEnterMinimumThreshold: {
    zh: '请输入最低阀值',
    en: '',
    pu: 'Favor inserir um valor limiar mínimo'
  },
  long2to10: {
    zh: '请输入2-10个英文字母',
    en: '',
    pu: 'Comprimento entre 2 e 10 caracteres'
  },
  AddedSuccessfully: {
    zh: '添加成功',
    en: 'Added successfully',
    pu: 'Adicionado com sucesso'
  },
  pleaseEnter: {
    zh: '请输入提取数量',
    en: '',
    pu: 'Por favor, digite o número de extratos'
  },
  largestNum: {
    zh: '该号段最大号码为',
    en: '',
    pu: 'O número máximo para este número é'
  },
  MaximumExtractionQuantity: {
    zh: '最大提取数量为',
    en: '',
    pu: 'O número máximo a ser extraído é'
  },
  Only10Numbers: {
    zh: '注：只展示10个号码',
    en: '',
    pu: 'Nota: Somente 10 números serão exibidos'
  },
  uploadTaskHasStartedPleaseWait: {
    zh: '已开始执行上传任务，请稍等',
    en: '',
    pu: 'A tarefa de upload já começou, por favor aguarde'
  },
  FileReadingFailurePleaseWait: {
    zh: '文件读取失败，请稍后再试',
    en: '',
    pu: 'A leitura do arquivo falhou, por favor tente novamente mais tarde'
  },
  noMore100Code: {
    zh: '输入不能超出100个字符',
    en: '',
    pu: 'A entrada não pode exceder 100 caracteres'
  },
  supplierSite: {
    zh: '预派送供应商 / 网点',
    en: '',
    pu: 'Fornecedor / ponto de entrega previsto'
  },
  supplierSite2: {
    zh: '实际派送供应商 / 网点',
    en: '',
    pu: 'Fornecedor / ponto de entrega atual'
  },
  SortingLog: {
    zh: '分拣日志',
    en: '',
    pu: 'Registro de distribuição'
  },
  trackingNumber: {
    zh: '分拣快递单号',
    en: '',
    pu: 'Nº de rastreio'
  },
  SortingTime: {
    zh: '分拣时间',
    en: '',
    pu: 'Tempo de distribuição'
  },
  DropGrille: {
    zh: '掉落格口',
    en: '',
    pu: 'O grid entrado'
  },
  CarNumber: {
    zh: '小车号',
    en: '',
    pu: 'Nº de esteira transportadora'
  },
  SortingCenter: {
    zh: '分拣中心',
    en: '',
    pu: 'Centro de distribuição'
  },
  packageWeight: {
    zh: '分拣机包裹重量',
    en: '',
    pu: 'peso de pacote pesado por sorter'
  },
  deviceID: {
    zh: '分拣机设备名称或设备ID',
    en: '',
    pu: 'Nome ou ID de equipa de sorter'
  },
  label1: {
    zh: '退仓供应商 / 网点',
    en: '',
    pu: 'Fornecedor de gestão / ponto de devoluções'
  },
  nextStation: {
    zh: '下一站',
    en: '',
    pu: 'Próxima paragem'
  },
  returnProvider: {
    zh: '退仓供应商',
    en: '',
    pu: 'Fornecedor de gestão de devoluções'
  },
  returnSite: {
    zh: '退仓网点',
    en: '',
    pu: 'Ponto de devolução'
  }
};
export {
  spareNumberPoo
};
