<script >
import { ref, defineProps } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  form: {
    type: Object
  },
  handlePreviousStep: {
    type: Function
  },
  handleNextStep: {
    type: Function
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  return {};
};
export default __sfc_main;
</script>

<template>
  <div class="seal__container">

    <o-field label="Lacres de entrada">
      <o-inputitems
        ref="inputSealIn"
        v-model="form.truck_seal_in"
        icon="tag"
        placeholder="Digite os lacres a serem fixados"
        aria-close-label="Retirar lacre"
        :allow-new="false"
        :open-on-focus="false"
      >
        <template #selected="{ items }">
          <span v-for="(item, index) in items" :key="index" class="item_selected" rounded @click="$refs.inputSealIn.removeItem(index, $event)">
            {{ item }}
            <div style="margin-left: 5px;cursor: pointer;" @click="$refs.inputSealIn.removeItem(index, $event)">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
            </div>
          </span>
        </template>
      </o-inputitems>

    </o-field>

    <o-field label="Lacres de saída">
      <o-inputitems
        ref="inputSealOut"
        v-model="form.truck_seal_out"
        icon="tag"
        placeholder="Digite os lacres a serem retirados"
        aria-close-label="Retirar lacre"
        :allow-new="false"
        :open-on-focus="false"
      >
        <template #selected="{ items }">
          <span v-for="(item, index) in items" :key="index" class="item_selected" rounded @click="$refs.inputSealOut.removeItem(index, $event)">
            {{ item }}
            <div style="margin-left: 5px;cursor: pointer;" @click="$refs.inputSealOut.removeItem(index, $event)">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
            </div>
          </span>
        </template>
      </o-inputitems>
    </o-field></div>
</template>

<style scoped>
.seal__container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.seals-items {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 8px;
  border-radius: 6px;
  margin: 8px 0 8px 0;
  background-color: hsl(213, 18%, 90%);
  color: hsl(var(--o-primary));
}

.seal__item {
border: solid 1px hsl(var(--o-primary));
background-color: white;
border-radius: 6px;
padding: 4px 6px;
}

.header-3 {
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 600;
}
</style>
