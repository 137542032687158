<template>
  <div class="app-container">
    <el-row :gutter="10">
      <el-col :span="8">
        <el-card class="scoll">
          <el-tree
            :default-expand-all="true"
            :data="organizationList"
            :expand-on-click-node="false"
            :props="defaultProps"
          >
            <span slot-scope="{ node,data }" class="custom-tree-node right">
              <!--Tile-->
              <span @click="()=>findByOrgCode(node,data)">
                <i v-if="data.isParent" class="iconfont icon-folder-open" style="font-size: 20px" />
                <i v-else class="iconfont icon-wenjian" style="font-size: 20px" />
                {{ data.name }}
              </span>
              <!--添加下级按钮-->
              <span class="custom-tree-node">
                <el-button
                  v-permit:remove="'btn:user-center:organization:add2'"
                  size="mini"
                  type="text"
                  @click="() =>
                    addChild(node,data)"
                >{{ $t('organizationManage.addNextLevel') }}</el-button>
                <!-- 添加下级 -->

                <el-button
                  v-permit:remove="'btn:user-center:organization:add1'"
                  size="mini"
                  type="text"
                  @click="() => addBrother(node,data)"
                >{{ $t('organizationManage.addSameLevel') }}</el-button>
                <!-- 添加同级 -->

                <el-button
                  v-permit:remove="'btn:user-center:organization:edit'"
                  size="mini"
                  type="text"
                  @click="() => editNode(node,data)"
                >{{ $t('organizationManage.edit') }}</el-button>
                <!-- 编辑 -->

                <el-popconfirm
                  v-permit:remove="'btn:user-center:organization:delete'"
                  :title="$t('organizationManage.AreYouSureDelete')"
                  @confirm="() => remove(node,data)"
                >
                  <!-- 确定删除吗？ -->
                  <el-button
                    slot="reference"
                    size="mini"
                    type="text"
                    class="ml"
                  >{{ $t('organizationManage.Delete') }}</el-button>
                  <!-- 删除 -->
                </el-popconfirm>

              </span>
            </span>
          </el-tree>
        </el-card>
      </el-col>
      <el-col :span="16">
        <!--  员工列别      -->
        <user-manage ref="userManage" />
      </el-col>
    </el-row>
    <!-- 组织机构操作弹出层 -->
    <organization-operation :organization-opt="organizationPop" @on-add-submit="init" />
  </div>
</template>

<script>
import { removeOrganization } from '@/api/organization';
import OrganizationOperation from './organizationOperation.vue';
import UserManage from '@/views/user/userManage/userManage';
import { asyncGetOrganizationTree, asyncGetUserAdditionalInformation } from '@/utils/asyncTools';
import { getOrganizationTreeObj } from '@/enums/storageEnum';

export default {
  name: 'OrganizationManage',
  components: { UserManage, OrganizationOperation },
  data() {
    return {
      // 覆盖默认变量名
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      organizationPop: {
        title: '',
        type: '',
        visible: false,
        id: null,
        parentId: null,
        name: '',
        remark: ''
      },
      organizationList: []
    };
  },
  created() {
    this.init();
    this.organizationList = getOrganizationTreeObj();
  },
  methods: {
    init() {
      // 更新缓存
      asyncGetOrganizationTree().then(data => {
        this.organizationList = data;
      }).catch(err => {
        console.error(err);
      });
      // 更新缓存
      asyncGetUserAdditionalInformation(1);
    },
    // 添加兄弟节点
    addBrother(node, data) {
      // 添加组织机构
      this.organizationPop.title = this.$t('organizationManage.AddingOrganization');
      this.organizationPop.type = 'add';
      this.organizationPop.visible = true;
      const parent = node.parent; //
      this.organizationPop.id = null;
      this.organizationPop.parentId = parent.data.id || 0;
      this.organizationPop.parentName = parent.data.name || '';
      this.organizationPop.name = '';
      this.organizationPop.remark = '';
    },
    addChild(node, data) {
      // 添加组织机构
      this.organizationPop.title = this.$t('organizationManage.AddingOrganization');
      this.organizationPop.type = 'add';
      this.organizationPop.visible = true;
      this.organizationPop.id = null;
      this.organizationPop.parentId = data.id || 0;
      this.organizationPop.parentName = data.name || '';
      this.organizationPop.name = '';
      this.organizationPop.remark = '';
    },
    editNode(node, data) {
      // 编辑组织机构
      this.organizationPop.title = this.$t('organizationManage.editOrganization');
      this.organizationPop.type = 'edit';
      this.organizationPop.visible = true;
      this.organizationPop.id = data.id;
      this.organizationPop.parentId = data.parentId;
      const parent = node.parent; //
      this.organizationPop.parentName = parent.data.name || '';
      this.organizationPop.name = data.name;
      this.organizationPop.remark = data.remark;
      this.organizationPop.sort = data.sort;
    },
    remove(node, data) {
      removeOrganization(data.id).then(data => {
        if (data.status === 'OK') {
          this.init();
          // 操作成功
          this.$message.success(this.$t('userManage.TheOperationSucceeded'));
        }
      });
    },
    findByOrgCode(node, data) {
      this.$refs.userManage.queryForm.params.orgCodeLikeRight = data.orgCode;
      this.$refs.userManage.getData();
    }

  }
};
</script>
<style>
div.el-tree-node>div.el-tree-node__children {
  overflow: visible;
}
</style>
<style lang="scss" scoped>
.custom-tree-node {
  font-size: 18px;
  .custom-tree-node-btn {
    margin-left: 10px;
  }
}

.right {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scoll {
  overflow-x: scroll;
  min-width: 100px;
}

.ml {
  margin-left: 10px;
}

</style>
