<template>
  <div>
    <!-- 所有分区 -->
    <div v-if="partitionId">
      <el-radio-group v-model="partitionAffiliateId" size="small">
        <el-radio-button
          v-for="item in subregionList"
          :key="item.id"
          :label="item.id"
        >
          {{ item.partitionIdentifier }}
        </el-radio-button>
      </el-radio-group>
    </div>

    <div style="margin-top: 10px;" class="priceTableForm">
      <div>
        <!-- 增加一行 -->
        <el-button
          v-permit:remove="'btn:payable:quoteManagement:addEdit'"
          size="small"
          type="primary"
          @click="handleAdd"
        >{{ $t('basicData.AddARow') }}</el-button>
      </div>

      <!--没有选择分区 -->
      <TableCmp
        v-if="!partitionId" ref="defaultTableRef" :table-data="defaultPartitionObj.default.arr"
        @change="changeDefaultPartition"
      />

      <!--选择了分区 -->
      <TableCmp
        v-if="partitionId && subregionList.length > 0" ref="tableRef" :table-data="getPartitionArr"
        @change="changePartition"
      />

    </div>
  </div>
</template>

<script>
import TableCmp from './TableCmp';
import { validatePrice, ROW_TMP } from './utils';

export default {
  name: '',

  components: {
    TableCmp
  },

  props: {
    // 分区
    partitionId: {
      type: [Number, String],
      default: () => {
        return 0;
      }
    },

    // 所有分区
    subregionList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      partitionAffiliateId: '', // 分区id

      // 如果没有选择分区
      defaultPartitionObj: {
        default: {
          arr: []
        }
      },

      // 如果选择了分区
      partitionObj: {
        // 分区id1: { arr: [] },
        // 分区id2: { arr: [] },
      }
    };
  },

  computed: {
    partitionComputed() {
      const a = this.partitionId;// 分区
      const b = this.subregionList;// 所有子分区
      return {
        a, b
      };
    },

    getPartitionArr() {
      if (this.partitionAffiliateId) {
        return this.partitionObj[this.partitionAffiliateId].arr || [];
      }
      return [];
    }
  },

  watch: {
    partitionComputed: {
      handler() {
        // this.getPartition();

        // 没有选择分区
        if (!this.partitionId) {
          this.defaultPartitionObj.default.arr = [];
          return;
        }

        // 选择了分区 但是分区暂时没有数据
        if (this.subregionList.length === 0) {
          return;
        }

        // 默认选中
        this.partitionAffiliateId = this.subregionList[0].id;

        // 先将之前的数据清空
        for (const key in this.partitionObj) {
          this.$delete(this.partitionObj, key);
        }

        // 将分区数据赋值
        this.subregionList.map(item => item.id).forEach(item => {
          this.$set(this.partitionObj || {}, item, { arr: [] });
        });
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    // 增加一行
    handleAdd() {
      // 没有选择分区
      if (!this.partitionId) {
        this.defaultPartitionObj.default.arr.push({
          ...ROW_TMP
        });
        return;
      }

      // 选择了分区
      this.partitionObj[this.partitionAffiliateId].arr.push({ ...ROW_TMP });
    },

    // 默认分区
    changeDefaultPartition(arr) {
      this.defaultPartitionObj.default.arr = arr;
    },

    // 选择分区
    changePartition(arr) {
      this.partitionObj[this.partitionAffiliateId].arr = arr;
    },

    /**
     * 提交校验
     *    1. 直接校验当前分区
     *    2. 其他分区填写的数据校验
     */
    async submitForm() {
      // 没有选择分区
      if (!this.partitionId) {
        // 没有添加分区
        if (this.defaultPartitionObj.default.arr.length === 0) {
          // this.$message.error('请添加详情');
          this.$message.error(this.$t('basicData.PleaseAddDetails'));
          return false;
        }

        //  直接调用子组件的方法检验
        const isBool = await this.$refs.defaultTableRef.submitForm();

        if (!isBool) {
          // this.$message.error('价格详情校验不通过');
          this.$message.error(this.$t('operatingPlatform.PleaseFillInTheRequiredFields'));
          return false;
        }
        return true;
      }

      // 选择了分区

      // 校验是否添加了数据
      {
        const arr = []; // 二维数组
        for (const key in this.partitionObj) {
          if (this.partitionObj[key].arr.length > 0) {
            arr.push(this.partitionObj[key].arr);
          }
        }

        if (arr.length === 0) {
          // this.$message.error('请添加最少一个价格详情');
          this.$message.error(this.$t('basicData.PleaseAddDetails'));
          return false;
        }
      }

      // 先校验当前选中的分区
      {
        const isBool = await this.$refs.tableRef.submitForm();
        if (!isBool) {
          // this.$message.error('价格详情校验不通过');
          this.$message.error(this.$t('basicData.CheckFail'));
          return false;
        }
      }

      // 校验其他分区
      {
        const arr = [];
        for (const key in this.partitionObj) {
          const isBool = validatePrice(this.partitionObj[key].arr);
          if (!isBool) {
            arr.push(key);
          }
        }

        // 有校验不通过的分区
        if (arr.length > 0) {
          // 获取不通过的分区名称
          const list = this.subregionList.filter(item => {
            return arr.includes(String(item.id));
          }).map(item => {
            return item.partitionIdentifier;
          }).join(',');

          // this.$message.error(`分区${list}价格详情校验不通过`);
          this.$message.error(list + '' + this.$t('basicData.CheckFail'));
          return false;
        }
      }

      return true;
    },

    // 设置数据(主要是为父组件提供操作)
    setData(arr) {
      console.log(arr, 'AJD价格详情');
      if (arr.length === 0) { return; }

      // 没有选择分区
      if (!this.partitionId) {
        this.defaultPartitionObj.default.arr = arr;

        console.log('没有选择分区', this.defaultPartitionObj);
        return;
      }

      // 选择了分区
      // 将数组按照分区id进行分组
      arr.forEach(item => {
        const { partitionAffiliateId } = item;
        this.partitionObj[partitionAffiliateId].arr.push(item);
      });
    },

    // 获取数据
    getData() {
      let arr = [];

      // 没有选择分区
      if (!this.partitionId) {
        arr = this.defaultPartitionObj.default.arr.map(item => {
          return {
            ...item,
            partitionAffiliateId: undefined
          };
        });
        return arr;
      }

      // 选择了分区，将所有数据（二维数组）放入arr（一维数组）中
      Object.entries(this.partitionObj).filter(item => {
        const [, value] = item;
        if (value.arr.length > 0) {
          return true;
        }
        return false;
      }).map(item => {
        const [key, value] = item;
        const list = value.arr.map(item => {
          return {
            ...item,
            partitionAffiliateId: key
          };
        });
        arr.push(...list);
      });

      return arr;
    }
  }

};
</script>

<style lang="scss" scoped>

</style>
