<script >
import { ref, computed } from '@vue/composition-api';
import dayjs from 'dayjs';
import { default as Card } from '@/components/card';
import { dashboardService } from '@/api/towerControl/dashboard';
import { useQuery } from '@tanstack/vue-query';
import i18n from '@/lang';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    data
  } = useQuery({
    queryKey: ['getDailyVisitFee'],
    queryFn: async () => {
      try {
        const currentDate = new Date();
        const formatedDate = dayjs(currentDate).format('YYYY/MM/DD');
        const res = await dashboardService.getDailyVisitFee(formatedDate);
        return res;
      } catch (error) {
        if (error.response && error.response.status === 400) {
          return {};
        }
        throw error;
      }
    },
    networkMode: 'always',
    refetchOnWindowFocus: false,
    retry: 3,
    keepPreviousData: false,
    refetchInterval: 300000
  });
  const dataVisitedStore = computed(() => {
    const visited = data.value?.data?.storesThatWereVisited ?? 0;
    const notVisited = data.value?.data?.storesToBeVisited ?? 0;
    return [visited, notVisited];
  });
  const chartOptions = ref({
    chart: {
      width: 380,
      type: 'donut'
    },
    dataLabels: {
      enabled: true,
      formatter(val, opts) {
        return [val.toFixed(0) + '%'];
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          show: false
        }
      }
    }],
    colors: ['#219F54', '#FF4040'],
    labels: [i18n.t('dashboard.visitedStores'), i18n.t('dashboard.unvisitedStores')],
    legend: {
      show: false,
      position: 'right',
      offsetY: 0,
      height: 230
    }
  });
  return {
    dataVisitedStore,
    chartOptions
  };
};
__sfc_main.components = Object.assign({
  Card
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <Card width="100%" class="container">
    <div class="container-header">
      <h2 class="header-2 m-t-20">{{ $t('dashboard.storeVisits') }}</h2>

      <div class="legends">
        <p class="label-1">{{ $t('dashboard.visitedStores') }}: {{ dataVisitedStore[0] }}</p>
        <p class="label-2">{{ $t('dashboard.unvisitedStores') }}: {{ dataVisitedStore[1] }}</p>
      </div>
    </div>
    <apexchart type="donut" width="400" :options="chartOptions" :series="dataVisitedStore" />
  </Card>
</template>

<style scoped>
.container{
  display: grid;
  gap: 1.25rem; /* 20px */
  justify-items: center;
}

.container-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}

.label-1::before, .label-2::before {
  content: '';
  display: inline-block;
  border-radius: 2px;
  width: 12px;
  height: 12px;
  margin-right: 4px;
  vertical-align: text-top;
}

.label-1::before {
  background: #219F54;
}

.label-2::before {
  background: #FF4040;
}

.label-1, .label-2 {
  font-size: 0.75rem; /* 12px */
}

.legends {
  display: flex;
  gap: 1rem; /* 16px */
}
</style>
