<template>
  <div class="app-container">
    <div>
      <el-button
        v-permit:remove="'but:automatic-sorting:grid-management:add'"
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="handlerAdd"
      >{{ $t('CenterForSorting.add') }}
      </el-button>
      <el-divider />
    </div>
    <el-card>
      <el-table :data="tableData" border :max-height="600">
        <el-table-column :label="$t('CenterForSorting.ind')" width="60px">
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!-- 格口编号 -->
        <el-table-column
          prop="gridNum"
          :label="$t('CenterForSorting.LatticePortNumber')"
          align="center"
          :show-overflow-tooltip="true"
        />
        <!--        设备名称-->
        <el-table-column
          prop="deviceName"
          :label="$t('GlobalSetObj.deviceName')"
          align="center"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="ruleName"
          :label="$t('CenterForSorting.AssociationRules')"
          align="center"
          :show-overflow-tooltip="true"
        />
        <!-- 关联规则 -->
        <!--        <el-table-column-->
        <!--          prop="orderCount"-->
        <!--          :label="$t('CenterForSorting.packageSum')"-->
        <!--        />&lt;!&ndash; 小包数量 &ndash;&gt;-->
        <el-table-column
          prop="remark"
          :label="$t('CenterForSorting.Note')"
          align="left"
          :show-overflow-tooltip="true"
        /><!-- 备注 -->
        <el-table-column
          prop="createUserName"
          :label="$t('CenterForSorting.creator')"
        /><!-- 创建人 -->
        <el-table-column
          prop="createTime"
          :label="$t('CenterForSorting.CreationTime')"
        /><!-- 创建时间 -->
        <el-table-column
          prop="updateUserName"
          :label="$t('CenterForSorting.Editor')"
        /><!-- 编辑人 -->
        <el-table-column
          prop="updateTime"
          :label="$t('CenterForSorting.EditTime')"
        /><!-- 编辑时间 -->
        <el-table-column
          prop="操作"
          :label="$t('CenterForSorting.operation')"
          align="center"
          width="160px"
          fixed="right"
        ><!-- 操作 -->
          <template slot-scope="scope">
            <el-button
              v-permit:remove="'but:automatic-sorting:grid-management:edit'"
              type="success"
              size="mini"
              @click="handleEdit(scope.row)"
            >{{ $t('CenterForSorting.edit') }}<!-- 编辑 -->
            </el-button>
            <el-button
              v-if="scope.row.labelUrl"
              size="mini"
              @click="handlePrint(scope.row)"
            >{{ $t('CenterForSorting.Print') }}<!-- 打印 -->
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 30, 50, 100, 500, 1000]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getData"
          @size-change="getData"
        />
      </div>
    </el-card>

    <!-- 新增 / 编辑 -->
    <el-drawer
      :title="drawer.title"
      :visible.sync="drawer.visible"
      direction="ltr"
      size="50%"
      :show-close="false"
      :wrapper-closable="false"
    >
      <add-cmp
        :ids="drawer.ids"
        :grid-num="drawer.gridNum"
        :remark="drawer.remark"
        :device-id="drawer.deviceId"
        :title="drawer.title"
        @updataData="updataData"
      />
    </el-drawer>
  </div>
</template>

<script>
import AddCmp from './cmp/AddsCmp.vue';
import { apiDelete, apiPage } from '@/api/automaticSorting';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { getUserName } from '@/utils/asyncTools';

export default {
  name: 'GridManagement',
  components: {
    'add-cmp': AddCmp
  },

  data() {
    return {
      queryForm: {
        pageNumber: 1,
        pageSize: 1000,
        params: {
          gridNum: '',
          ruleName: ''
          // id: '',
          // deleted: '',
          // remark: '',
          // ruleId: ''
        }
      },

      tableData: [],
      total: 0,

      drawer: {
        title: this.$t('CenterForSorting.add'),
        visible: false,
        ids: 0,
        gridNum: undefined,
        remark: '',
        deviceId: '' // 设备ID
      }
    };
  },

  created() {
    this.getData();
    this.userIdAndNameObj = getUserIdAndNameObj();
  },

  methods: {
    handlePrint(down) {
      window.open(down.labelUrl);
    },

    // 分页
    getData() {
      apiPage(this.queryForm).then(res => {
        if (res.status !== 'OK') {
          return;
        }
        this.total = res.data.total;

        this.tableData = res.data.records.map(item => {
          const createUserName = getUserName(item.createUser, this.userIdAndNameObj);
          const updateUserName = getUserName(item.updateUser, this.userIdAndNameObj);
          return {
            ...item,
            createUserName,
            updateUserName
          };
        });
      }).catch(err => {
        console.error(err);
      });
    },

    // 新增格口
    handlerAdd() {
      this.drawer.title = this.$t('CenterForSorting.gekouAdd'); // '新增格口'
      this.drawer.visible = true;
      this.drawer.ids = 0;
      this.drawer.gridNum = undefined;
      this.drawer.remark = '';
      this.drawer.deviceId = '';
    },

    // 编辑
    handleEdit(row) {
      const { id, gridNum, remark, deviceId } = row;
      this.drawer.title = this.$t('CenterForSorting.gekouEdit'); // '编辑格口'
      this.drawer.visible = true;
      this.drawer.ids = id;
      this.drawer.gridNum = gridNum;
      this.drawer.remark = remark;
      this.drawer.deviceId = deviceId;
    },

    // 父元素更新
    updataData(isBool) {
      console.log(1);
      this.drawer.visible = false;
      if (isBool) {
        this.getData();
      }
    },

    // 删除格口
    asyncDel(ids) {
      apiDelete(ids).then(res => {
        const { code } = res;
        if (code !== 200) {
          return;
        }
        this.$message({
          message: this.$t('CenterForSorting.DeleteSucceeded'),
          type: 'success'
        });
        setTimeout(() => {
          this.getData();
        }, 200);
      }).catch(err => {
        console.error(err);
      });
    }
  }

};
</script>

<style scoped>
.ml {
  margin-left: 5px;
}
</style>
