var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "msgBox" },
    [
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-upload2", size: "mini" },
              on: { click: _vm.download },
            },
            [_vm._v(_vm._s(_vm.$t("collectionCenter.export")))]
          ),
          _c("el-divider"),
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("collectionCenter.loadingInfo"),
                name: "hz",
              },
            },
            [
              _c(
                "el-table",
                {
                  ref: "ref",
                  attrs: {
                    data: _vm.tableDate1,
                    "max-height": 600,
                    border: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("collectionCenter.ind"),
                      align: "center",
                      type: "index",
                      width: "60",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  (_vm.queryForm1.pageNumber - 1) *
                                    _vm.queryForm1.pageSize +
                                    scope.$index +
                                    1
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("collectionCenter.licensePlateNumber"),
                      prop: "vehicleNumber",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("collectionCenter.operationMode"),
                      prop: "operationModeName",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("collectionCenter.totalNumberBagsLoaded"),
                      prop: "loadBagCount",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "skipClass",
                                on: {
                                  click: function ($event) {
                                    return _vm.openBagging(
                                      scope.row.vehicleNumber,
                                      scope.row.loadBagCount
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.loadBagCount))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("collectionCenter.totalBagsUnloaded"),
                      prop: "unloadBagCount",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "skipClass",
                                on: {
                                  click: function ($event) {
                                    return _vm.openBagging(
                                      scope.row.vehicleNumber,
                                      scope.row.unloadBagCount
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.unloadBagCount))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("collectionCenter.bagCountDiscrepancy"),
                      prop: "loadUnloadBagDiffCount",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "skipClass",
                                on: {
                                  click: function ($event) {
                                    return _vm.openBagging(
                                      scope.row.vehicleNumber,
                                      scope.row.loadUnloadBagDiffCount
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.loadUnloadBagDiffCount))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("collectionCenter.carLoadingVotes"),
                      prop: "loadTicketCount",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "skipClass",
                                on: {
                                  click: function ($event) {
                                    return _vm.openBag(
                                      scope.row.vehicleNumber,
                                      scope.row.loadTicketCount
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.loadTicketCount))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("collectionCenter.carUnloadingVotes"),
                      prop: "unloadTicketCount",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "skipClass",
                                on: {
                                  click: function ($event) {
                                    return _vm.openBag(
                                      scope.row.vehicleNumber,
                                      scope.row.unloadTicketCount
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.unloadTicketCount))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("collectionCenter.votesDiscrepancy"),
                      prop: "loadUnloadTicketDiffCount",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "skipClass",
                                on: {
                                  click: function ($event) {
                                    return _vm.openBag(
                                      scope.row.vehicleNumber,
                                      scope.row.loadUnloadTicketDiffCount
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(scope.row.loadUnloadTicketDiffCount)
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c("el-pagination", {
                    staticStyle: { margin: "10px 0" },
                    attrs: {
                      "current-page": _vm.queryForm1.pageNumber,
                      "page-size": _vm.queryForm1.pageSize,
                      "page-sizes": [10, 20, 50, 100],
                      total: _vm.total1,
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "update:currentPage": function ($event) {
                        return _vm.$set(_vm.queryForm1, "pageNumber", $event)
                      },
                      "update:current-page": function ($event) {
                        return _vm.$set(_vm.queryForm1, "pageNumber", $event)
                      },
                      "update:pageSize": function ($event) {
                        return _vm.$set(_vm.queryForm1, "pageSize", $event)
                      },
                      "update:page-size": function ($event) {
                        return _vm.$set(_vm.queryForm1, "pageSize", $event)
                      },
                      "current-change": _vm.getNumPage1,
                      "size-change": _vm.getPageSize1,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.activeName == "mx1"
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("collectionCenter.baggingInfo"),
                    name: "mx1",
                  },
                },
                [
                  _c(
                    "el-table",
                    {
                      ref: "ref",
                      attrs: {
                        data: _vm.tableDate2,
                        "max-height": 600,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("collectionCenter.ind"),
                          align: "center",
                          type: "index",
                          width: "60",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        (_vm.queryForm2.pageNumber - 1) *
                                          _vm.queryForm2.pageSize +
                                          scope.$index +
                                          1
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3351327512
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("collectionCenter.BagNumber"),
                          prop: "bagNumber",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("collectionCenter.loadingScanningTime"),
                          prop: "loadScanTime",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("customerManagements.loadSiteIdList"),
                          prop: "loadSiteName",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("collectionCenter.loader"),
                          prop: "loadUserName",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t(
                            "collectionCenter.unloadingScanningTime"
                          ),
                          prop: "unloadScanTime",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("collectionCenter.unloadDot"),
                          prop: "unloadSiteName",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("collectionCenter.unloader"),
                          prop: "unloadUserName",
                          align: "center",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-pagination", {
                        staticStyle: { margin: "10px 0" },
                        attrs: {
                          "current-page": _vm.queryForm2.pageNumber,
                          "page-size": _vm.queryForm2.pageSize,
                          "page-sizes": [10, 20, 50, 100],
                          total: _vm.total2,
                          background: "",
                          layout: "total, sizes, prev, pager, next, jumper",
                        },
                        on: {
                          "update:currentPage": function ($event) {
                            return _vm.$set(
                              _vm.queryForm2,
                              "pageNumber",
                              $event
                            )
                          },
                          "update:current-page": function ($event) {
                            return _vm.$set(
                              _vm.queryForm2,
                              "pageNumber",
                              $event
                            )
                          },
                          "update:pageSize": function ($event) {
                            return _vm.$set(_vm.queryForm2, "pageSize", $event)
                          },
                          "update:page-size": function ($event) {
                            return _vm.$set(_vm.queryForm2, "pageSize", $event)
                          },
                          "current-change": _vm.getNumPage2,
                          "size-change": _vm.getPageSize2,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.activeName == "mx2"
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("collectionCenter.bagInfo"),
                    name: "mx2",
                  },
                },
                [
                  _c(
                    "el-table",
                    {
                      ref: "ref",
                      attrs: {
                        data: _vm.tableDate3,
                        "max-height": 600,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("collectionCenter.ind"),
                          align: "center",
                          type: "index",
                          width: "60",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        (_vm.queryForm3.pageNumber - 1) *
                                          _vm.queryForm3.pageSize +
                                          scope.$index +
                                          1
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3219837880
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("collectionCenter.Barcode"),
                          prop: "scanNumber",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("collectionCenter.parcelNum"),
                          prop: "packageNumber",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("collectionCenter.loadingScanningTime"),
                          prop: "loadScanTime",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("customerManagements.loadSiteIdList"),
                          prop: "loadSiteName",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("collectionCenter.loader"),
                          prop: "loadUserName",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t(
                            "collectionCenter.unloadingScanningTime"
                          ),
                          prop: "unloadScanTime",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("collectionCenter.unloadDot"),
                          prop: "unloadSiteName",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("collectionCenter.unloader"),
                          prop: "unloadUserName",
                          align: "center",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-pagination", {
                        staticStyle: { margin: "10px 0" },
                        attrs: {
                          "current-page": _vm.queryForm3.pageNumber,
                          "page-size": _vm.queryForm3.pageSize,
                          "page-sizes": [10, 20, 50, 100],
                          total: _vm.total3,
                          background: "",
                          layout: "total, sizes, prev, pager, next, jumper",
                        },
                        on: {
                          "update:currentPage": function ($event) {
                            return _vm.$set(
                              _vm.queryForm3,
                              "pageNumber",
                              $event
                            )
                          },
                          "update:current-page": function ($event) {
                            return _vm.$set(
                              _vm.queryForm3,
                              "pageNumber",
                              $event
                            )
                          },
                          "update:pageSize": function ($event) {
                            return _vm.$set(_vm.queryForm3, "pageSize", $event)
                          },
                          "update:page-size": function ($event) {
                            return _vm.$set(_vm.queryForm3, "pageSize", $event)
                          },
                          "current-change": _vm.getNumPage3,
                          "size-change": _vm.getPageSize3,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }