<script >
import { defineProps, ref, watch } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { FormActions, FormActionsNext, FormActionsPrevious } from '@/components/wizard-form/formStepActions';
import { required, numeric } from '@/utils/validationErrors';
import getMessageError from '@/utils/getMessageError';
import { getToastContentComponent } from '@/utils/getToastContentComponent';
import { addingLoading, closeLoading } from '@/utils/handleControlLoading';
import { toast } from '@/utils/programaticToast';
import { mdfeService } from '@/api/mdfe';
import { zipCodeService } from '@/api/zipCode';
import clearString from '@/utils/clearString';
const __sfc_main = {};
__sfc_main.props = {
  formData: {
    type: Object
  },
  handleNext: {
    type: Function,
    required: true
  },
  isLastStep: {
    type: Boolean,
    required: true
  },
  isFirstStep: {
    type: Boolean,
    required: true
  },
  handlePrevious: {
    type: Function
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const rules = {
    initialZipCode: {
      required
    },
    initialNameMunicipality: {
      required
    },
    initialCodMunicipality: {
      required
    },
    initialUF: {
      required
    },
    finalUF: {
      required
    },
    finalZipCode: {
      required
    },
    finalNameMunicipality: {
      required
    },
    finalCodMunicipality: {
      required
    },
    cUF: {
      required,
      numeric
    }
  };
  const v$ = useVuelidate(rules, props.formData);
  async function handleSubmit() {
    const isValid = await v$.value.$validate();
    if (isValid) props.handleNext();
  }
  async function searchZipcode(zipcode) {
    try {
      const res = await zipCodeService.getInfoZipCode(zipcode);
      return {
        uf: res.data.publicAreaReturn.publicArea.state,
        municipalityName: res.data.publicAreaReturn.publicArea.municipality.municipalityName,
        municipalityCod: res.data.publicAreaReturn.publicArea.municipality.codMun
      };
    } catch (error) {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Ocorreu um erro',
          describe: getMessageError(error),
          typeIcon: 'error'
        }),
        closable: true,
        duration: 4000
      });
      return {
        municipalityName: '',
        municipalityCod: ''
      };
    }
  }
  let interval;
  watch(() => props.formData.initialZipCode, async newInitialValue => {
    clearTimeout(interval);
    interval = setTimeout(async () => {
      const zipCode = clearString(newInitialValue);
      const {
        municipalityName,
        municipalityCod,
        uf
      } = await searchZipcode(zipCode);
      props.formData.initialNameMunicipality = municipalityName;
      props.formData.initialUF = uf;
      props.formData.initialCodMunicipality = municipalityCod;
    }, 900);
  });
  watch(() => props.formData.finalZipCode, async newFinalValue => {
    clearTimeout(interval);
    interval = setTimeout(async () => {
      const zipCode = clearString(newFinalValue);
      const {
        municipalityName,
        municipalityCod,
        uf
      } = await searchZipcode(zipCode);
      props.formData.finalUF = uf;
      props.formData.finalNameMunicipality = municipalityName;
      props.formData.finalCodMunicipality = municipalityCod;
    }, 900);
  });
  const selectCompany = ref(props.formData.emitxNome);
  const optionsCompanies = ref([]);
  (async function getAllOptionsCompanies() {
    const res = await mdfeService.getAllCompanies();
    optionsCompanies.value = res.data;
  })();
  async function handleSelectedCompany() {
    try {
      addingLoading();
      const res = await mdfeService.getAllCompanies(selectCompany.value);
      props.formData.emitCnpj = res.data.CNPJ;
      props.formData.emitIe = res.data.IE;
      props.formData.emitxNome = res.data.xNome;
      props.formData.emitxFant = res.data.xFant;
      props.formData.emitxLgr = res.data.xLgr;
      props.formData.emitNro = res.data.nro;
      props.formData.emitxBairro = res.data.xBairro;
      props.formData.emitcMun = res.data.cMun;
      props.formData.emitxMun = res.data.xMun;
      props.formData.emitCEP = res.data.CEP;
      props.formData.emitUF = res.data.UF;
      props.formData.emitfone = res.data.fone;
      props.formData.emitemail = res.data.email;
      toast.open({
        variant: 'success',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Adicionado com sucesso',
          describe: 'Empresa foi selecionada com sucesso',
          typeIcon: 'success'
        }),
        closable: true,
        duration: 4000
      });
    } catch (error) {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Ocorreu um erro',
          describe: getMessageError(error),
          typeIcon: 'error'
        }),
        closable: true,
        duration: 4000
      });
    } finally {
      closeLoading();
    }
  }
  const listUF = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];
  return {
    v$,
    handleSubmit,
    selectCompany,
    optionsCompanies,
    handleSelectedCompany,
    listUF
  };
};
__sfc_main.components = Object.assign({
  FormActions,
  FormActionsPrevious,
  FormActionsNext
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <form class="form" @submit.prevent="handleSubmit">
    <o-field
      label="Selecione a empresa"
      label-for="company"
      class="col-span-3"
    >
      <o-select
        id="company"
        v-model="selectCompany"
        placeholder="Selecione a empresa"
        @input="handleSelectedCompany"
      >
        <option
          v-for="company in optionsCompanies"
          :key="company"
          :value="company"
        >
          {{ company }}
        </option>
      </o-select>
    </o-field>

    <o-field
      label="Cód. UF Inicial"
      label-for="cUF"
      :message="v$.cUF.$errors.length ? v$.cUF.$errors[0].$message : null"
    >
      <o-input
        id="cUF"
        v-model="formData.cUF"
        placeholder="Digite o código da UF"
      />
    </o-field>

    <o-field
      label="CEP inicial"
      label-for="initialZipCode"
      class="col-span-3"
      :message="v$.initialZipCode.$errors.length ? v$.initialZipCode.$errors[0].$message : null"
    >
      <o-input
        id="initialZipCode"
        v-model="formData.initialZipCode"
        v-mask="'#####-###'"
        placeholder="Digite o CEP inicial"
      />
    </o-field>

    <o-field
      label="UF inicial"
      label-for="initialUF"
      :message="v$.initialUF.$errors.length ? v$.initialUF.$errors[0].$message : null"
    >
      <o-select id="initialUF" v-model="formData.initialUF" placeholder="SP">
        <option v-for="UF in listUF" :key="UF" :value="UF"> {{ UF }} </option>
      </o-select>
    </o-field>

    <o-field
      label="Nome do município inicial"
      label-for="initialNameMunicipality"
      class="col-span-2"
      :message="v$.initialNameMunicipality.$errors.length ? v$.initialNameMunicipality.$errors[0].$message : null"
    >
      <o-input
        id="initialNameMunicipality"
        v-model="formData.initialNameMunicipality"
        placeholder="Nome do município"
      />
    </o-field>

    <o-field
      label="Código do município inicial"
      label-for="initialCodMunicipality"
      class="col-span-2"
      :message="v$.initialCodMunicipality.$errors.length ? v$.initialCodMunicipality.$errors[0].$message : null"
    >
      <o-input
        id="initialCodMunicipality"
        v-model="formData.initialCodMunicipality"
        placeholder="Código do município"
      />
    </o-field>

    <o-field
      label="CEP final"
      label-for="finalZipCode"
      class="col-span-3"
      :message="v$.finalZipCode.$errors.length ? v$.finalZipCode.$errors[0].$message : null"
    >
      <o-input
        id="finalZipCode"
        v-model="formData.finalZipCode"
        placeholder="Digite o CEP final"
      />
    </o-field>

    <o-field
      label="UF Final"
      label-for="finalUF"
      :message="v$.finalUF.$errors.length ? v$.finalUF.$errors[0].$message : null"
    >
      <o-select id="finalUF" v-model="formData.finalUF" placeholder="SP">
        <option v-for="UF in listUF" :key="UF" :value="UF"> {{ UF }} </option>
      </o-select>
    </o-field>

    <o-field
      label="Nome do município final"
      label-for="finalnameMunicipality"
      class="col-span-2"
      :message="v$.finalNameMunicipality.$errors.length ? v$.finalNameMunicipality.$errors[0].$message : null"
    >
      <o-input
        id="finalnameMunicipality"
        v-model="formData.finalNameMunicipality"
        placeholder="Nome do município"
      />
    </o-field>

    <o-field
      label="Código do município final"
      label-for="finalCodMunicipality"
      class="col-span-2"
      :message="v$.finalCodMunicipality.$errors.length ? v$.finalCodMunicipality.$errors[0].$message : null"
    >
      <o-input
        id="finalCodMunicipality"
        v-model="formData.finalCodMunicipality"
        placeholder="Código do município"
      />
    </o-field>

    <FormActions>
      <FormActionsPrevious
        :is-first-step="isFirstStep"
        @handlePrevious="handlePrevious"
      />

      <FormActionsNext :is-last-step="isLastStep" />
    </FormActions>
  </form>
</template>

<style scoped>
.form{
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 1rem
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}
</style>
