<script >
import { defineProps } from '@vue/composition-api';
import { FormActions, FormActionsNext, FormActionsPrevious } from '@/components/wizard-form/formStepActions';
const __sfc_main = {};
__sfc_main.props = {
  isLastStep: {
    type: Boolean,
    required: true
  },
  isFirstStep: {
    type: Boolean,
    required: true
  },
  handleNext: {
    type: Function,
    required: true
  },
  handlePrevious: {
    type: Function
  },
  formData: {
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const optionsBodyType = ['carreta', 'reboque', 'semirreboque'];
  const listUF = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];
  return {
    optionsBodyType,
    listUF
  };
};
__sfc_main.components = Object.assign({
  FormActions,
  FormActionsPrevious,
  FormActionsNext
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <form class="wrapper-form" @submit.prevent="handleNext">
    <o-field
      label="Nome"
      label-for="name"
      class="col-span-2"
    >
      <o-input
        id="name"
        v-model="formData.name"
        placeholder="Nome ou razão social"
      />
    </o-field>

    <o-field
      label="CNPJ"
      label-for="documentOwnerTrailer"
      class="col-span-2"
    >
      <o-input
        id="documentOwnerTrailer"
        v-model="formData.document"
        v-mask="'##.###.###/####-##'"
        placeholder="000.000.000-00"
      />
    </o-field>

    <o-field
      label="IE"
      label-for="ieTrailer"
      class="col-span-2"
    >
      <o-input
        id="ieTrailer"
        v-model="formData.ie"
        placeholder="Digite a IE do dono"
      />
    </o-field>

    <o-field
      label="RNTRC"
      label-for="rntrcTrailer"
      class="col-span-2"
    >
      <o-input
        id="rntrcTrailer"
        v-model="formData.rntrc"
        placeholder="Digite o RNTRC"
      />
    </o-field>

    <o-field
      label="Placa"
      label-for="licensePlateTrailer"
      class="col-span-2"
    >
      <o-input
        id="licensePlateTrailer"
        v-model="formData.licensePlate"
        v-mask="'AAA-XXXX'"
        placeholder="Digite a placa do veículo"
      />
    </o-field>

    <o-field
      label="Capacidade (KG)"
      label-for="capacityKgTrailer"
    >
      <o-input
        id="capacityKgTrailer"
        v-model="formData.capacityKg"
        placeholder="100"
      />
    </o-field>

    <o-field
      label="Tara"
      label-for="taraTrailer"
    >
      <o-input
        id="taraTrailer"
        v-model="formData.tara"
        placeholder="03"
      />
    </o-field>

    <o-field
      label="RENAVAM"
      label-for="renavamTrailer"
      class="col-span-2"
    >
      <o-input
        id="renavamTrailer"
        v-model="formData.renavam"
        placeholder="Digite o RENAVAM"
      />
    </o-field>

    <o-field
      label="Tipo de Carroceria"
      label-for="bodyTypeTrailer"
    >
      <o-select id="bodyTypeTrailer" v-model="formData.bodyType" placeholder="Tipo de corpo">
        <option v-for="bodyType in optionsBodyType" :key="bodyType.value" :value="bodyType"> {{ bodyType }} </option>
      </o-select>
    </o-field>

    <o-field
      label="UF"
      label-for="ufTrailer"
    >
      <o-select id="ufTrailer" v-model="formData.uf" placeholder="SP">
        <option v-for="UF in listUF" :key="UF" :value="UF"> {{ UF }} </option>
      </o-select>
    </o-field>

    <FormActions>
      <FormActionsPrevious :is-first-step="isFirstStep" @handlePrevious="handlePrevious" />
      <FormActionsNext :is-last-step="isLastStep" />
    </FormActions>
  </form>
</template>

<style scope>
.wrapper-form {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 1rem/* 16px */;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}
</style>
