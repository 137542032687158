export const routes = {
  // tableData
  routesTitle: {
    zh: '路线',
    en: '',
    pu: 'Rotas'
  },
  createRoutesButton: {
    zh: '创建路线',
    en: '',
    pu: 'Criar rota'
  },
  idRoutesLabel: {
    zh: '路线 ID',
    en: '',
    pu: 'Rota ID'
  },
  noResults: {
    zh: '没有结果',
    en: '',
    pu: 'Sem resultados'
  },
  createRoutesTitle: {
    zh: '创建路线',
    en: '',
    pu: 'Criando Rota'
  },
  createRoutesParagraph: {
    zh: '请填写下方的字段以完成在我们系统中创建路线的操作。',
    en: '',
    pu: 'Preencha os campos logo abaixo para realizar a criação de uma rota em nosso sistema'
  },

  // actions
  edit: {
    zh: '编辑',
    en: '',
    pu: 'Editar'
  },
  seeSellers: {
    zh: '查看卖家',
    en: '',
    pu: 'Ver sellers'
  },
  equipment: {
    zh: '设备',
    en: '',
    pu: 'Equipamento'
  },
  generateOrder: {
    zh: '生成订单',
    en: '',
    pu: 'Gerar ordem'
  },
  editTitle: {
    zh: '编辑路线',
    en: '',
    pu: 'Editando Rota'
  },
  editParagraph: {
    zh: '请填写下方的字段以完成在我们系统中编辑路线的操作。',
    en: '',
    pu: 'Preencha os campos logo abaixo para realizar a edição de uma rota em nosso sistema'
  },
  sellerTitle: {
    zh: '卖家列表',
    en: '',
    pu: 'Lista de sellers'
  },
  sellerParagraph: {
    zh: '这是该路线所需收集的卖家列表，显示了卖家的代码和名称。',
    en: '',
    pu: 'Essa é a lista de sellers que essa rota tem para a coleta, mostrando o código e o nome do seller.'
  },
  equipmentTitle: {
    zh: '设备',
    en: '',
    pu: 'Equipamento'
  },
  equipmentParagraph: {
    zh: '填写所有字段以关联路线的设备。',
    en: '',
    pu: 'Preencha todos os campos para poder associar um equipamento a rota.'
  },
  orderTitle: {
    zh: '订单',
    en: '',
    pu: 'Ordem'
  },
  orderParagraph: {
    zh: '填写所有字段以在所选路线上生成订单。',
    en: '',
    pu: 'Preencha todos os campos para poder gerar um ordem nessa rota selecionada.'
  },

  // formEditRoute
  listSeller: {
    zh: '卖家列表',
    en: '',
    pu: 'Lista de sellers'
  },
  addSeller: {
    zh: '添加卖家',
    en: '',
    pu: 'Adicionar sellers'
  },
  sendButton: {
    zh: '发送',
    en: '',
    pu: 'Enviar'
  },

  // avaliableSellers
  sellerCode: {
    zh: '卖家代码',
    en: '',
    pu: 'Código do Seller'
  },
  sellerName: {
    zh: '卖家名称',
    en: '',
    pu: 'Nome do Seller'
  },
  sellerLabel: {
    zh: '卖家',
    en: '',
    pu: 'Seller'
  },
  cepLabel: {
    zh: '邮政编码',
    en: '',
    pu: 'CEP'
  },
  sellerQuantity: {
    zh: '卖家数量',
    en: '',
    pu: 'Quantidade de sellers'
  },
  sellerAlert: {
    zh: '请选择至少一个卖家',
    en: '',
    pu: 'Selecione pelo menos um seller'
  },

  // formDriver
  searchDriverTitle: {
    zh: '查找司机',
    en: '',
    pu: 'Procurar motorista'
  },
  nameLabel: {
    zh: '姓名',
    en: '',
    pu: 'Nome'
  },
  namePlaceholder: {
    zh: '输入司机姓名',
    en: '',
    pu: 'Digite o nome do motorista'
  },
  documentLabel: {
    zh: '个人/法人税号',
    en: '',
    pu: 'Documento'
  },
  usernameLabel: {
    zh: '用户名',
    en: '',
    pu: 'Nome de Usuário'
  },
  helpersLabel: {
    zh: '助手',
    en: '',
    pu: 'Ajudantes'
  },
  helpersPlaceholder: {
    zh: '选择助手',
    en: '',
    pu: 'Selecione os ajudantes'
  },
  createDriverTitle: {
    zh: '创建司机',
    en: '',
    pu: 'Criar motorista'
  },
  dataDriver: {
    zh: '司机数据',
    en: '',
    pu: 'Dados do motorista'
  },
  dataAddress: {
    zh: '地址数据',
    en: '',
    pu: 'Dados do endereço'
  },

  // formTransportSupply
  selectedColorsLabel: {
    zh: '选定的封条颜色',
    en: '',
    pu: 'Cores de lacre'
  },
  selectedColorsPlaceholder: {
    zh: '选择颜色',
    en: '',
    pu: 'Selecione as cores'
  },
  quantityBagsLabel: {
    zh: '袋子数量',
    en: '',
    pu: 'Qtd. Sacas'
  },
  quantityBagsPlaceholder: {
    zh: '输入袋子数量',
    en: '',
    pu: 'Digite a quantidade de sacas'
  },
  yellow: {
    zh: '黄色',
    en: '',
    pu: 'amarelo'
  },
  blue: {
    zh: '蓝色',
    en: '',
    pu: 'azul'
  },
  white: {
    zh: '白色',
    en: '',
    pu: 'branco'
  },
  pink: {
    zh: '粉色',
    en: '',
    pu: 'rosa'
  },
  colorPlaceholder: {
    zh: '输入封条编号',
    en: '',
    pu: 'Digite o número do lacre'
  },
  '班次编号': {
    zh: '班次编号',
    en: '',
    pu: 'Número da Viagem'
  },
  '对应揽收时间': {
    zh: '对应揽收时间',
    en: '',
    pu: 'Tempo de Coleta Correspondente'
  },
  '对应揽收装车完成时间': {
    zh: '对应揽收装车完成时间',
    en: '',
    pu: 'Tempo Correspondente de Conclusão da Carga da Coleta'
  },
  '对应HUB1到件时间': {
    zh: '对应HUB1到件时间',
    en: '',
    pu: 'Tempo de Chegada no HUB1 Correspondente'
  },
  '发车时间': {
    zh: '发车时间',
    en: '',
    pu: 'Hora de Partida'
  },
  '下一站流向': {
    zh: '下一站流向',
    en: '',
    pu: 'Próximo Fluxo de Destino'
  },
  '选择班次': {
    zh: '选择班次',
    en: '',
    pu: 'Selecionar Viagem'
  },
  '揽收装车完成时间,HUB1到件时间, 不能小于揽收结束时间': {
    zh: '揽收装车完成时间,HUB1到件时间, 不能小于揽收结束时间',
    en: '',
    pu: 'O tempo de conclusão da carga da coleta e o tempo de chegada no HUB1 não podem ser menores que o tempo de término da coleta.'
  },
  '新增班次': {
    zh: '新增班次',
    en: '',
    pu: 'Adicionar turno'
  },
  '对应揽收时间不能大于对应揽收装车完成时间': {
    zh: '对应揽收时间不能大于对应揽收装车完成时间',
    en: '',
    pu: 'O tempo de coleta correspondente não pode ser maior que o tempo de conclusão da carga da coleta correspondente.'
  },
  '新增流向': {
    zh: '新增流向',
    en: '',
    pu: 'Adicionar fluxo'
  },
  '新增干支线流向': {
    zh: '新增干支线流向',
    en: '',
    pu: 'Adicionar fluxo de linha-dry'
  },
  'CannotBeLessThanTheMaximumValue': {
    zh: '不能小于对应揽收时间的最大值',
    en: 'Cannot be less than the maximum value of the corresponding collection time',
    pu: 'Não pode ser menor que o valor máximo do horário de coleta correspondente'
  },
  'correspondingHUB1': {
    zh: '不能大于对应HUB1到件时间',
    en: 'Cannot exceed the corresponding HUB1 delivery time',
    pu: 'Não pode ser mais maior que o horário de chegada correspondente ao HUB1'
  },
  'CannotBeLessThanTheCorresponding': {
    zh: '不能小于对应揽收装车完成时间',
    en: 'Cannot be less than the corresponding collection and loading completion time',
    pu: 'Não pode ser menor que o tempo de conclusão da carga da coleta correspondente'
  }
};
