var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "10px" } },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { name: "1" } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _c("i", { staticClass: "el-icon-s-grid" }),
                _vm._v(" " + _vm._s(_vm.$t("orderDetails.waybillMsg"))),
              ]),
              _c("detailBox", {
                attrs: { "query-track-param": _vm.queryTrackParam },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { name: "3" } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _c("i", { staticClass: "el-icon-document" }),
                _vm._v(" " + _vm._s(_vm.$t("spareNumberPoo.OperationalLogs"))),
              ]),
              _vm.isShow3
                ? _c("OperationalLogs", {
                    key: 2,
                    attrs: {
                      "query-track-param": _vm.queryTrackParam,
                      "query-type": "2",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { name: "2" } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _c("i", { staticClass: "el-icon-location-outline" }),
                _vm._v(" " + _vm._s(_vm.$t("GlobalSetObj.TrackSearch"))),
              ]),
              _vm.isShow2
                ? _c("trackQuery", {
                    key: 1,
                    attrs: {
                      "query-track-param": _vm.queryTrackParam,
                      "query-type": "1",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }