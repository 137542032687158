<template>
  <div class="app-container">
    <el-form :inline="true" label-position="top">
      <el-form-item :label="$t('newOrder.BuildTime')">
        <el-date-picker
          v-model="queryForm.timeValue"
          :default-time="['00:00:00', '23:59:59']"
          :end-placeholder="$t('operationCenter.endDate')"
          :format="$i18n.locale==='zh'?zhDate:ptDate"
          :start-placeholder="$t('operationCenter.startDate')"
          range-separator="~"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
        />
      </el-form-item>
      <!-- 运单号 -->
      <el-form-item :label="$t('newOrder.waybillNumber')" prop="waybillNumber">
        <el-input
          v-model="queryForm.params.waybillNumber"
          :placeholder="$t('orderDetails.pleaseInput')"
          clearable
          style="width: 250px"
          @keyup.enter.native.prevent="getSearchEnter"
        />
      </el-form-item>
      <!--类型-->
      <el-form-item :label="$t('GlobalSetObj.Type')" prop="minutaType">
        <el-select
          v-model="queryForm.params.minutaType"
          :placeholder="$t('GlobalSetObj.pleaseChoose')"
        >
          <el-option :label="$t('GlobalSetObj.all')" :value="-1" />
          <el-option :label="$t('newOrder.forwardDirection')" :value="1" />
          <el-option :label="$t('newOrder.reverse')" :value="2" />
        </el-select>
      </el-form-item>
      <!--生成状态-->
      <el-form-item :label="$t('newOrder.GeneratingState')" prop="createStatus">
        <el-select
          v-model="queryForm.params.createStatus"
          :placeholder="$t('GlobalSetObj.pleaseChoose')"
        >
          <el-option :label="$t('GlobalSetObj.all')" :value="-1" />
          <el-option :label="$t('GlobalSetObj.GeneratedSuccessfully')" :value="2" />
          <el-option :label="$t('GlobalSetObj.BuildFailure')" :value="3" />
        </el-select>
      </el-form-item>
      <el-form-item style="margin-top: 28px">
        <!--搜索-->
        <el-button icon="el-icon-search" type="primary" @click="getSearchEnter">{{ $t('newOrder.search') }}</el-button>
        <!--重置-->
        <el-button icon="el-icon-refresh-left" type="info" @click="resetForm">{{ $t('newOrder.reset') }}</el-button>
      </el-form-item>
    </el-form>
    <div style="margin-bottom: 10px">
      <el-button icon="el-icon-download" @click="downloadTheMenu">{{
        $t('GlobalSetObj.download')
      }}
      </el-button>
    </div>
    <!--  表格数据  -->
    <AnjunTableList
      ref="anjunTable"
      :format-time="isTormat"
      :has-operation="true"
      :header-list="tableHeaderList"
      :is-select-btn="true"
      :is-tip="true"
      :table-data="tableData"
      @selectTable="selectTable"
      @on-rebuild="regenerate"
      @to-OrderDetail="toOrderDetail"
    />
    <!--  分页  -->
    <el-pagination
      :current-page.sync="queryForm.pageNumber"
      :page-size.sync="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!--详情-->
    <el-drawer
      :append-to-body="true"
      :visible.sync="waybillDescription"
      custom-class="drawerClass"
      direction="ltr"
      size="90%"
    >
      <div style="height: calc(100vh - 67px); overflow: auto">
        <minutaListInformation :key="detailKey" :query-track-param="queryTrackParam" query-type="1" />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import AnjunTableList from '@/components/anjun-tableList';
import { rebuild, waybillMinutaPage } from '@/api/logisticsOrders';
import { batchPdfDownload } from '@/utils/PDF_util';
import { setDateTime2 } from '@/utils/date_util';
import minutaListInformation from './component/minutaInfo.vue';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

export default {
  name: 'MinutaList',
  components: {
    AnjunTableList,
    minutaListInformation
  },
  data() {
    return {
      // 详情
      waybillDescription: false,
      detailKey: new Date().getTime(),
      queryTrackParam: {},
      // 是否格式化成巴西时间
      isTormat: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      queryForm: {
        timeValue: [],
        pageSize: 10,
        pageNumber: 1,
        total: 0,
        params: {
          createStatus: -1,
          waybillNumber: '',
          minutaType: -1
        }
      },
      tableHeaderList: [
        { id: 1, label: this.$t('GlobalSetObj.waybillNumber'), prop: 'waybillNumber', cloWidth: '180' },
        { id: 2, label: this.$t('GlobalSetObj.Type'), prop: 'waybillTypeName', cloWidth: '180' },
        { id: 3, label: this.$t('newOrder.BuildTime'), prop: 'createTime', cloWidth: '180' },
        { id: 4, label: this.$t('newOrder.GeneratingState'), prop: 'minutaStatusName', cloWidth: '140' },
        { id: 5, label: this.$t('GlobalSetObj.ReasonForFailure'), prop: 'failReason', cloWidth: '210' }
      ],
      tableData: [],
      searchNumber: '',
      selectionList: [], // 表格勾选数据
      downloadArr: [] // 下载的链接
    };
  },
  computed: {
    StatusName() {
      return {
        2: { label: this.$t('GlobalSetObj.GeneratedSuccessfully'), code: 2 },
        3: { label: this.$t('GlobalSetObj.BuildFailure'), code: 3 }
      };
    },
    typeName() {
      return {
        1: { label: this.$t('newOrder.forwardDirection'), code: 1 }, // 正向
        2: { label: this.$t('newOrder.reverse'), code: 2 } // 逆向
      };
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.initTime();
    this.getSearch();
  },
  methods: {
    // 初始化时间
    initTime() {
      const day = new Date();
      const brazilTime = day.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
      const today = dayjs(brazilTime).format('YYYY-MM-DD'); // 获取当前日期
      this.queryForm.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
    },
    // 列表数据勾选
    selectTable(data) {
      this.selectionList = data;
      this.downloadArr = [];
      this.selectionList.forEach(item => {
        if (item.minutaUrl) {
          this.downloadArr.push(item.minutaUrl);
        }
      });
    },
    getSearchEnter() {
      this.queryForm.pageNumber = 1;
      this.$refs.anjunTable.toggleSelection();
      this.getSearch();
    },
    // 重置
    resetForm() {
      this.queryForm.params = {
        createStatus: -1,
        waybillNumber: '',
        minutaType: -1
      };
      this.initTime();
      this.getSearchEnter();
    },
    // 进入运单详情
    toOrderDetail(row) {
      this.queryTrackParam = row;
      console.log(row, ':query-track-param="queryTrackParam"');
      console.log(row.waybillId, 'row.waybillIdrow.waybillIdrow.waybillId', this.queryTrackParam.waybillId);
      this.waybillDescription = true;
      this.detailKey = new Date().getTime();
      setTimeout(() => {
        this.bus.$emit('todetailBox', row.waybillId);
      }, 100);
    },
    // 查询
    getSearch() {
      const data = {};
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.pageNumber;
      data.params = { ...this.queryForm.params };
      if (this.queryForm.timeValue) {
        data.params.createStartTime = this.queryForm.timeValue[0];
        data.params.createEndTime = this.queryForm.timeValue[1];
      }
      waybillMinutaPage(data).then(res => {
        if (res.status === 'OK') {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
          this.tableData.map(item => {
            if (item.minutaStatus === 3) {
              item.rebuild = true;
            }
            if (item.minutaStatus) {
              if (this.StatusName[item.minutaStatus].label) {
                item.minutaStatusName = this.StatusName[item.minutaStatus].label;
              }
            }
            if (item.waybillType) {
              if (this.typeName[item.waybillType].label) {
                item.waybillTypeName = this.typeName[item.waybillType].label;
              }
            }
          });
          console.log(this.tableData, 'this.tableDatathis.tableData');
        }
      });
    },
    // 重新生成
    regenerate(row) {
      rebuild(row).then(res => {
        if (res.status === 'OK') {
          this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
          this.getSearch();
        }
      });
    },
    // 下载所选数据
    downloadTheMenu() {
      const downloadArr = this.downloadArr;
      console.log(this.downloadArr, 'this.downloadArrthis.downloadArrthis.downloadArr');
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$i18n.t('GlobalSetObj.CheckForm')); // 请选择
        return;
      }
      // 手动显示加载中动画
      const loading = this.$loading({
        fullscreen: true,
        lock: true,
        spinner: 'el-icon-loading',
        text: this.$i18n.t('orderCenterCont.DesperatelyLoading'), // '拼命加载中',
        background: 'rgba(250, 250, 250, 0.6)'
      });
      batchPdfDownload(downloadArr).then((res) => {
        const dateType = setDateTime2();
        const saveName = `${dateType}` + 'Minuta';
        saveAs(res, saveName);
        // const a = document.createElement('a');
        // a.download = `${dateType}`;
        // a.href = res;
        // a.download = `${dateType}` + 'Minuta';
        // a.click();
        // a.remove();
        // this.$refs.anjunTable.toggleSelection();
      }).finally(() => {
        loading.close();
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.number = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.number = val;
      this.getSearch();
    }
  }
};
</script>

<style scoped>
.app-container >>> .drawerClass header {
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.app-container >>> .el-dialog__body {
  padding: 10px 20px;
}

.app-container >>> .el-form--label-top .el-form-item__label {
  width: 100% !important;
}
</style>
