var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Box LoadBox" }, [
    _c(
      "div",
      { staticClass: "scanClass" },
      [
        _c("span", [_vm._v(_vm._s(_vm.$t("collectionCenter.scanBtn")))]),
        _vm.isReceiveSignBtn
          ? _c("el-input", {
              staticClass: "iconBtn",
              attrs: {
                placeholder: _vm.$t("collectionCenter.pleaseEnterOrScan"),
                "prefix-icon": "el-icon-full-screen",
                clearable: "",
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.pushTable($event)
                },
              },
              model: {
                value: _vm.signBagNumber,
                callback: function ($$v) {
                  _vm.signBagNumber = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "signBagNumber",
              },
            })
          : _c(
              "el-tooltip",
              {
                attrs: {
                  effect: "dark",
                  content: _vm.$t("userManage.unauthorized"),
                  placement: "right",
                },
              },
              [
                _c("el-input", {
                  staticClass: "iconBtn",
                  attrs: {
                    placeholder: _vm.$t("collectionCenter.pleaseEnterOrScan"),
                    "prefix-icon": "el-icon-full-screen",
                    clearable: "",
                  },
                  model: {
                    value: _vm.signBagNumber,
                    callback: function ($$v) {
                      _vm.signBagNumber =
                        typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "signBagNumber",
                  },
                }),
              ],
              1
            ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "uploadBtn" },
      [
        _vm.isReceiveSignBtn
          ? _c(
              "el-button",
              { attrs: { type: "success" }, on: { click: _vm.signature } },
              [_vm._v(_vm._s(_vm.$t("collectionCenter.signature")))]
            )
          : _vm._e(),
      ],
      1
    ),
    _c("div", { staticClass: "recordClass" }, [
      _c("span", [_vm._v(_vm._s(_vm.$t("collectionCenter.scannedNumber")))]),
      _c("span", { staticClass: "red" }, [_vm._v(_vm._s(_vm.scanPageTotal))]),
    ]),
    _c(
      "div",
      { staticClass: "tableClass" },
      [
        _c(
          "el-table",
          { attrs: { "max-height": 600, data: _vm.scanPage, border: "" } },
          [
            _c("el-table-column", {
              attrs: {
                prop: "signBagNum",
                align: "center",
                label: _vm.$t("collectionCenter.BagNumber"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "scanTimeStr",
                align: "center",
                label: _vm.$t("collectionCenter.scanTime"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: _vm.$t("collectionCenter.operation"),
                fixed: "right",
                width: "100",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-popconfirm",
                        {
                          attrs: {
                            title: _vm.$t("collectionCenter.deleteSure"),
                          },
                          on: {
                            confirm: function ($event) {
                              return _vm.scanDelClick(scope.row, scope.$index)
                            },
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                slot: "reference",
                                type: "danger",
                                size: "mini",
                              },
                              slot: "reference",
                            },
                            [_vm._v(_vm._s(_vm.$t("collectionCenter.Delete")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm.signVisible
      ? _c("div", { staticClass: "signMask" }, [
          _c("div", { staticClass: "signVisibleClass" }, [
            _c("div", {
              staticClass: "el-icon-close signClose",
              on: { click: _vm.signCloseClick },
            }),
            _c("div", { staticClass: "descriptionsClass" }, [
              _c(
                "div",
                { ref: "image", staticStyle: { border: "1px solid #a8a9ad" } },
                [
                  _c("div", { staticClass: "desTitle" }, [
                    _vm._v(_vm._s(_vm.$t("collectionCenter.lanshouMsg"))),
                  ]),
                  _c(
                    "el-descriptions",
                    {
                      staticClass: "margin-top",
                      attrs: {
                        column: 1,
                        border: "",
                        "content-style": _vm.rowClass,
                        "label-style": _vm.labelRowClass,
                      },
                    },
                    [
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  display: "block",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("collectionCenter.operator"))
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" " + _vm._s(_vm.scanList.name) + " "),
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  display: "block",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("collectionCenter.OperationSite")
                                  )
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" " + _vm._s(_vm.scanList.dotName) + " "),
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  display: "block",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "collectionCenter.totalNumberOfPackages"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" " + _vm._s(_vm.scanList.bagSum) + " "),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "desTitle" }, [
                    _vm._v(
                      _vm._s(_vm.$t("collectionCenter.customerSignature"))
                    ),
                  ]),
                  _c("div", { staticClass: "page-content" }, [
                    _c(
                      "div",
                      { staticClass: "content" },
                      [
                        _c("vue-esign", {
                          ref: "esignImg",
                          attrs: {
                            width: 500,
                            height: 300,
                            "line-width": 5,
                            "line-color": "#000",
                            "bg-color": "#FFF",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: { type: "danger" },
                      on: { click: _vm.handleReset },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.empty")))]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: { type: "success" },
                      on: { click: _vm.handleGenerate },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.submitSignature")))]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm.isLoading ? _c("div", { staticClass: "loadingClass" }) : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
      },
      [
        _c("audio", {
          ref: "errRef",
          attrs: { src: _vm.errorMp3, controls: "controls" },
        }),
        _c("audio", {
          ref: "okRef",
          attrs: { src: _vm.okMp3, controls: "controls" },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }