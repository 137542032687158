var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Box" },
    [
      _c(
        "div",
        [
          _c(
            "el-steps",
            {
              attrs: {
                active: _vm.active,
                "finish-status": "success",
                "align-center": "",
              },
            },
            [
              _c("el-step", {
                attrs: { title: _vm.$t("collectionCenter.scanningVehicle") },
              }),
              _c("el-step", {
                attrs: { title: _vm.$t("collectionCenter.scanningBag") },
              }),
              _c("el-step", {
                attrs: { title: _vm.$t("collectionCenter.scanningCarTag") },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active == 0,
              expression: "active == 0",
            },
          ],
          staticClass: "scanClass",
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("collectionCenter.carScan")))]),
          _c("el-input", {
            attrs: {
              placeholder: _vm.$t("collectionCenter.scanQRCodeOrNum"),
              clearable: "",
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.scanCar($event)
              },
            },
            model: {
              value: _vm.carMsg,
              callback: function ($$v) {
                _vm.carMsg = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "carMsg",
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c("div", { staticClass: "dialogClass" }, [
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.carNum")) +
                  _vm._s(_vm.carList.id)
              ),
            ]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.licensePlateNum")) +
                  _vm._s(_vm.carList.vehicleNumber)
              ),
            ]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.operationM")) +
                  _vm._s(_vm.carList.operationModeName)
              ),
            ]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.carT")) +
                  _vm._s(_vm.carList.vehicleTypeName)
              ),
            ]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.carDriver")) +
                  _vm._s(_vm.carList.vehicleDriver)
              ),
            ]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.belongToSite")) +
                  _vm._s(_vm.carList.siteIdName)
              ),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:collection-center:loading-scan:nextAllowShow",
                      expression:
                        "'btn:collection-center:loading-scan:nextAllowShow'",
                      arg: "remove",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.next },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.next")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active == 1,
              expression: "active == 1",
            },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "scanClass" },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("collectionCenter.bagNumScan"))),
              ]),
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("collectionCenter.pleaseScanBagNum"),
                  clearable: "",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.scanBag($event)
                  },
                },
                model: {
                  value: _vm.bagMsg,
                  callback: function ($$v) {
                    _vm.bagMsg = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "bagMsg",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "scanClass" },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("GlobalSetObj.nextStation")) + "："),
              ]),
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: _vm.$t("collectionCenter.pleaseChoose"),
                    clearable: "",
                    filterable: "",
                    remote: "",
                    "reserve-keyword": "",
                    "remote-method": _vm.remoteMethod,
                  },
                  model: {
                    value: _vm.nextSite,
                    callback: function ($$v) {
                      _vm.nextSite = $$v
                    },
                    expression: "nextSite",
                  },
                },
                _vm._l(_vm.siteIdList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: {
                      label: item.name,
                      value: item.id + "/" + item.name,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: "btn:collection-center:loading-scan:lastAllowShow",
                  expression:
                    "'btn:collection-center:loading-scan:lastAllowShow'",
                  arg: "remove",
                },
              ],
              staticStyle: { "margin-top": "12px" },
              on: { click: _vm.last },
            },
            [_vm._v(_vm._s(_vm.$t("userManage.Previous")))]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: "btn:collection-center:loading-scan:nextAllowShow",
                  expression:
                    "'btn:collection-center:loading-scan:nextAllowShow'",
                  arg: "remove",
                },
              ],
              staticStyle: { "margin-top": "12px" },
              attrs: { type: "primary" },
              on: { click: _vm.next },
            },
            [_vm._v(_vm._s(_vm.$t("collectionCenter.next")))]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active == 2 && _vm.signatureFilling,
              expression: "active == 2 && signatureFilling",
            },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "scanClass" },
            [
              _c("span", { staticClass: "requiredClass" }, [
                _vm._v(_vm._s(_vm.$t("collectionCenter.carNumScan"))),
              ]),
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("collectionCenter.pleaseScanCarNum"),
                  clearable: "",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.scanCarTag($event)
                  },
                },
                model: {
                  value: _vm.carTagMsg,
                  callback: function ($$v) {
                    _vm.carTagMsg = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "carTagMsg",
                },
              }),
            ],
            1
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: "btn:collection-center:loading-scan:lastAllowShow",
                  expression:
                    "'btn:collection-center:loading-scan:lastAllowShow'",
                  arg: "remove",
                },
              ],
              staticStyle: { "margin-top": "12px" },
              on: { click: _vm.last },
            },
            [_vm._v(_vm._s(_vm.$t("userManage.Previous")))]
          ),
        ],
        1
      ),
      _vm.active == 1
        ? _c(
            "div",
            { staticClass: "tableClass" },
            [
              _c("div", { staticClass: "titleTop" }, [
                _vm._v(_vm._s(_vm.$t("collectionCenter.scanned")) + "："),
                _c("span", { staticClass: "totalClass" }, [
                  _vm._v(_vm._s(_vm.tablePage.length)),
                ]),
              ]),
              _c(
                "el-table",
                {
                  staticClass: "scanTable",
                  attrs: { "max-height": 600, data: _vm.tablePage, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "bagNumber",
                      label: _vm.$t("collectionCenter.BagNumber"),
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "scanTimeStr",
                      label: _vm.$t("collectionCenter.scanTime"),
                    },
                  }),
                  _vm.delAllowShow
                    ? _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("collectionCenter.operation"),
                          fixed: "right",
                          width: "100",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-popconfirm",
                                    {
                                      attrs: {
                                        title: _vm.$t(
                                          "collectionCenter.deleteSure"
                                        ),
                                      },
                                      on: {
                                        confirm: function ($event) {
                                          return _vm.scanDelClick(
                                            scope.row,
                                            scope.$index
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            slot: "reference",
                                            disabled: _vm.active == 2,
                                            type: "danger",
                                            size: "mini",
                                          },
                                          slot: "reference",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("collectionCenter.Delete")
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4189632334
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.carTagDialogVisible,
            "close-on-click-modal": false,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.carTagDialogVisible = $event
            },
            close: _vm.carTagHandleClose,
          },
        },
        [
          _c("div", { staticClass: "dialogClass" }, [
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.bagNumber")) +
                  _vm._s(_vm.carTagMsg)
              ),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-top": "12px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.finish },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.loadingEnd")))]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.signVisible
        ? _c("div", { staticClass: "signMask" }, [
            _c("div", { staticClass: "signVisibleClass" }, [
              _c("div", {
                staticClass: "el-icon-close signClose",
                on: { click: _vm.closeSign },
              }),
              _c("div", { staticClass: "descriptionsClass" }, [
                _c(
                  "div",
                  { ref: "image" },
                  [
                    _c(
                      "el-descriptions",
                      {
                        attrs: {
                          title: "",
                          column: 1,
                          border: "",
                          "content-style": _vm.rowClass,
                          "label-style": _vm.labelRowClass,
                        },
                      },
                      [
                        _c("el-descriptions-item", { attrs: { label: "" } }, [
                          _c("div", { staticClass: "top1" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("collectionCenter.licensePlateNum")
                                ) + _vm._s(_vm.scanSign.carNum)
                              ),
                            ]),
                            _c("img", { attrs: { src: _vm.LOGO, alt: "" } }),
                          ]),
                        ]),
                        _c("el-descriptions-item", { attrs: { label: "" } }, [
                          _c("div", { staticClass: "isolateClass" }),
                        ]),
                        _c("el-descriptions-item", { attrs: { label: "" } }, [
                          _c("div", { staticClass: "two" }, [
                            _c("div", { staticClass: "dd" }, [
                              _vm._v(
                                _vm._s(_vm.$t("collectionCenter.loadingPoint"))
                              ),
                            ]),
                            _c("div", { staticClass: "dd" }, [
                              _vm._v(_vm._s(_vm.scanSign.loadedSite)),
                            ]),
                          ]),
                        ]),
                        _c("el-descriptions-item", { attrs: { label: "" } }, [
                          _c("div", { staticClass: "two" }, [
                            _c("div", { staticClass: "dd" }, [
                              _vm._v(
                                _vm._s(_vm.$t("collectionCenter.loader")) + "："
                              ),
                            ]),
                            _c("div", { staticClass: "dd" }, [
                              _vm._v(_vm._s(_vm.scanSign.loadedPeople)),
                            ]),
                          ]),
                        ]),
                        _c("el-descriptions-item", { attrs: { label: "" } }, [
                          _c("div", { staticClass: "two" }, [
                            _c("div", { staticClass: "dd" }, [
                              _vm._v(
                                _vm._s(_vm.$t("collectionCenter.loadTime")) +
                                  "："
                              ),
                            ]),
                            _c("div", { staticClass: "dd" }, [
                              _vm._v(_vm._s(_vm.scanSign.signerTime)),
                            ]),
                          ]),
                        ]),
                        _c("el-descriptions-item", { attrs: { label: "" } }, [
                          _c("div", { staticClass: "isolateClass" }, [
                            _vm._v(
                              _vm._s(_vm.$t("collectionCenter.infoPackage"))
                            ),
                          ]),
                        ]),
                        _c("el-descriptions-item", { attrs: { label: "" } }, [
                          _c("div", { staticClass: "two" }, [
                            _c("div", { staticClass: "dd" }, [
                              _vm._v(
                                _vm._s(_vm.$t("collectionCenter.totalLoad"))
                              ),
                            ]),
                            _c("div", { staticClass: "dd" }, [
                              _vm._v(
                                _vm._s(_vm.scanSign.realityNumber) +
                                  _vm._s(_vm.$t("collectionCenter.bag")) +
                                  "（" +
                                  _vm._s(_vm.scanSign.bagNumberCount) +
                                  "）" +
                                  _vm._s(_vm.$t("collectionCenter.ticket"))
                              ),
                            ]),
                          ]),
                        ]),
                        _c("el-descriptions-item", { attrs: { label: "" } }, [
                          _c("div", { staticClass: "isolateClass" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("collectionCenter.signatureConfirmation")
                              )
                            ),
                          ]),
                        ]),
                        _c("el-descriptions-item", { attrs: { label: "" } }, [
                          _c("div", { staticClass: "two" }, [
                            _c(
                              "div",
                              { staticClass: "dd isDriver" },
                              [
                                _c("span", { staticClass: "req" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("collectionCenter.driverIsExpect")
                                    )
                                  ),
                                ]),
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.scanSign.isDriver,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.scanSign, "isDriver", $$v)
                                      },
                                      expression: "scanSign.isDriver",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v(_vm._s(_vm.$t("Resource.yes"))),
                                    ]),
                                    _c("el-radio", { attrs: { label: 2 } }, [
                                      _vm._v(_vm._s(_vm.$t("Resource.No"))),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.isNull && !_vm.scanSign.isDriver
                                  ? _c("div", { staticClass: "redTip" }, [
                                      _vm._v(
                                        "- " +
                                          _vm._s(
                                            _vm.$t(
                                              "collectionCenter.pleaseChoose"
                                            )
                                          ) +
                                          " -"
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "dd textarea" },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("collectionCenter.Note")) +
                                      "："
                                  ),
                                ]),
                                _vm.showTime
                                  ? _c("div", { staticClass: "remarkShow" }, [
                                      _vm._v(
                                        " " + _vm._s(_vm.scanSign.remark) + " "
                                      ),
                                    ])
                                  : _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        autosize: "",
                                        placeholder: _vm.$t(
                                          "collectionCenter.driverRemark"
                                        ),
                                      },
                                      model: {
                                        value: _vm.scanSign.remark,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.scanSign, "remark", $$v)
                                        },
                                        expression: "scanSign.remark",
                                      },
                                    }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("el-descriptions-item", { attrs: { label: "" } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "550px",
                                height: "260px",
                                overflow: "hidden",
                              },
                            },
                            [
                              _c("vue-esign", {
                                ref: "esign",
                                attrs: {
                                  width: 550,
                                  height: 260,
                                  "line-width": 5,
                                  "line-color": "#000",
                                  "bg-color": "#FFF",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "statementClass" }, [
                      _vm._v(
                        " Declaro para todos os fins legais que li e verifiquei a exatidão e veracidade de todas as informações contidas neste formulário, e assumo total responsabilidade civil e criminal por quaisquer danos materiais e morais decorrentes de qualquer não conformidade das informações aqui contidas. "
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "signBtn" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { type: "success" },
                        on: { click: _vm.handleGenerate },
                      },
                      [_vm._v(_vm._s(_vm.$t("collectionCenter.Confirm")))]
                    ),
                    _c(
                      "el-button",
                      { staticClass: "btn", on: { click: _vm.resetSign } },
                      [_vm._v(_vm._s(_vm.$t("collectionCenter.empty")))]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.active !== 0
        ? _c(
            "div",
            { staticClass: "cameraClass" },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t("collectionCenter.Upload") +
                      _vm.$t("collectionCenter.truckLoading") +
                      _vm.$t("collectionCenter.picture") +
                      "："
                  )
                ),
              ]),
              _c("camera", {
                ref: "changeImg",
                attrs: { "file-list1": _vm.fileList1 },
                on: { uploadImg: _vm.getImg },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
        },
        [
          _c("audio", {
            ref: "errRef",
            attrs: { src: _vm.errorMp3, controls: "controls" },
          }),
          _c("audio", {
            ref: "okRef",
            attrs: { src: _vm.okMp3, controls: "controls" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }