var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "selectClass" },
        [
          _c(
            "el-form",
            {
              ref: "queryForm",
              staticClass: "queryFormClass",
              attrs: { model: _vm.queryForm, size: "mini" },
            },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "flex-wrap": "wrap" },
                  attrs: { type: "flex", justify: "start" },
                },
                [
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("WorkCentreTable.ticketType") + "：",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "operationCenter.PleaseSelect"
                                ),
                                filterable: "",
                                remote: "",
                                clearable: "",
                              },
                              model: {
                                value: _vm.queryForm.params.ticketType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "ticketType",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.ticketType",
                              },
                            },
                            _vm._l(_vm.ticketOption, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.id,
                                  attrs: {
                                    label:
                                      item.typeName +
                                      (item.englishName
                                        ? "(" + item.englishName + ")"
                                        : ""),
                                    value: item.typeName,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { float: "left" } },
                                    [_vm._v(_vm._s(item.typeName))]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        float: "right",
                                        color: "#8492a6",
                                        "font-size": "13px",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.englishName))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.userRadio !== "2",
                          expression: "userRadio !== '2'",
                        },
                      ],
                      staticStyle: { width: "auto" },
                      attrs: { span: 7 },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.$t("WorkCentreTable.createUserName") + "：",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "operationCenter.PleaseSelect"
                                ),
                                filterable: "",
                                remote: "",
                                clearable: "",
                                "remote-method": _vm.getExecuteUser,
                                loading: _vm.userObj.loading,
                              },
                              model: {
                                value: _vm.queryForm.params.createUser,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "createUser",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.createUser",
                              },
                            },
                            _vm._l(_vm.userObj.executeUserArr, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.id,
                                  attrs: {
                                    label:
                                      item.name +
                                      (item.englishName
                                        ? "(" + item.englishName + ")"
                                        : ""),
                                    value: item.id,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { float: "left" } },
                                    [
                                      _vm._v(
                                        _vm._s(item.name) +
                                          _vm._s(
                                            item.englishName
                                              ? "(" + item.englishName + ")"
                                              : ""
                                          )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        float: "right",
                                        color: "#8492a6",
                                        "font-size": "13px",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.positionName))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.userRadio !== "1",
                          expression: "userRadio !== '1'",
                        },
                      ],
                      staticStyle: { width: "auto" },
                      attrs: { span: 7 },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.$t("WorkCentreTable.executeUserName") + "：",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "operationCenter.PleaseSelect"
                                ),
                                filterable: "",
                                remote: "",
                                clearable: "",
                                "remote-method": _vm.getExecuteUser,
                                loading: _vm.userObj.loading,
                              },
                              model: {
                                value: _vm.queryForm.params.executeUser,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "executeUser",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.executeUser",
                              },
                            },
                            _vm._l(_vm.userObj.executeUserArr, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.id,
                                  attrs: {
                                    label:
                                      item.name +
                                      (item.englishName
                                        ? "(" + item.englishName + ")"
                                        : ""),
                                    value: item.id,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { float: "left" } },
                                    [
                                      _vm._v(
                                        _vm._s(item.name) +
                                          _vm._s(
                                            item.englishName
                                              ? "(" + item.englishName + ")"
                                              : ""
                                          )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        float: "right",
                                        color: "#8492a6",
                                        "font-size": "13px",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.positionName))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.$t("WorkCentreTable.ticketStatus") + "：",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "medium" },
                              model: {
                                value: _vm.queryForm.params.ticketStatus,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "ticketStatus",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.ticketStatus",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: "" } }, [
                                _vm._v(_vm._s(_vm.$t("WorkCentreTable.whole"))),
                              ]),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "closed" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("WorkCentreTable.close"))
                                  ),
                                ]
                              ),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "implementing" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("WorkCentreTable.implementing")
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "over" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("WorkCentreTable.complete"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("WorkCentreTable.sort") + "：",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "medium" },
                              model: {
                                value: _vm.queryForm.params.sort,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm.params, "sort", $$v)
                                },
                                expression: "queryForm.params.sort",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: "" } }, [
                                _vm._v(_vm._s(_vm.$t("WorkCentreTable.whole"))),
                              ]),
                              _c("el-radio-button", { attrs: { label: 1 } }, [
                                _vm._v(
                                  _vm._s(_vm.$t("WorkCentreTable.urgent"))
                                ),
                              ]),
                              _c("el-radio-button", { attrs: { label: 2 } }, [
                                _vm._v(_vm._s(_vm.$t("WorkCentreTable.high"))),
                              ]),
                              _c("el-radio-button", { attrs: { label: 3 } }, [
                                _vm._v(
                                  _vm._s(_vm.$t("WorkCentreTable.normal"))
                                ),
                              ]),
                              _c("el-radio-button", { attrs: { label: 4 } }, [
                                _vm._v(_vm._s(_vm.$t("WorkCentreTable.low"))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("WorkCentreTable.ticketName") + "：",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "operationCenter.PleaseEnterTheContent"
                              ),
                              clearable: "",
                            },
                            model: {
                              value: _vm.queryForm.params.ticketName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm.params,
                                  "ticketName",
                                  $$v
                                )
                              },
                              expression: "queryForm.params.ticketName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.getData((_vm.isFind = 1))
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("operationCenter.Procurar")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.queryFormReset },
                },
                [_vm._v(_vm._s(_vm.$t("operationCenter.reset")))]
              ),
            ],
            1
          ),
          _c("el-divider"),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-radio-group",
            {
              attrs: { size: "mini" },
              on: { change: _vm.handleUser },
              model: {
                value: _vm.userRadio,
                callback: function ($$v) {
                  _vm.userRadio = $$v
                },
                expression: "userRadio",
              },
            },
            [
              _c("el-radio-button", { attrs: { label: "" } }, [
                _vm._v(_vm._s(_vm.$t("WorkCentreTable.all"))),
              ]),
              _c("el-radio-button", { attrs: { label: "1" } }, [
                _vm._v(_vm._s(_vm.$t("WorkCentreTable.ICreated"))),
              ]),
              _c("el-radio-button", { attrs: { label: "2" } }, [
                _vm._v(_vm._s(_vm.$t("WorkCentreTable.IDid"))),
              ]),
            ],
            1
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: "but:work-orders-center:mini-work-drd:add",
                  expression: "'but:work-orders-center:mini-work-drd:add'",
                  arg: "remove",
                },
              ],
              staticClass: "ml",
              attrs: { type: "primary", icon: "el-icon-plus", size: "mini" },
              on: { click: _vm.addTag },
            },
            [_vm._v(_vm._s(_vm.$t("operationCenter.add")))]
          ),
        ],
        1
      ),
      _c(
        "el-card",
        [
          _c(
            "el-table",
            { attrs: { data: _vm.tableData, border: "" } },
            [
              _c("el-table-column", {
                attrs: { label: "ID", width: "40", prop: "id" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("WorkCentreTable.sort"),
                  width: "90",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.sort === 1
                          ? _c(
                              "el-tag",
                              {
                                attrs: { type: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleType(
                                      "sort",
                                      scope.row.sort
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("WorkCentreTable.urgent")))]
                            )
                          : scope.row.sort === 2
                          ? _c(
                              "el-tag",
                              {
                                attrs: { type: "warning" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleType(
                                      "sort",
                                      scope.row.sort
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("WorkCentreTable.high")))]
                            )
                          : scope.row.sort === 3
                          ? _c(
                              "el-tag",
                              {
                                attrs: { type: "success" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleType(
                                      "sort",
                                      scope.row.sort
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("WorkCentreTable.normal")))]
                            )
                          : _c(
                              "el-tag",
                              {
                                attrs: { type: "info" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleType(
                                      "sort",
                                      scope.row.sort
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("WorkCentreTable.low")))]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("WorkCentreTable.ticketType"),
                  width: "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "clickColor",
                            on: {
                              click: function ($event) {
                                return _vm.handleType(
                                  "ticketType",
                                  scope.row.ticketType
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.ticketType))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "ticketName",
                  label: _vm.$t("WorkCentreTable.ticketName"),
                  width: "280",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "clickColor",
                            on: {
                              click: function ($event) {
                                return _vm.detailsTag(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.ticketName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createTimeStr",
                  label: _vm.$t("WorkCentreTable.createTime"),
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "timeLeftStr",
                  label: _vm.$t("WorkCentreTable.timeLeft"),
                  width: "90",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.ticketStatus !== "implementing"
                          ? _c("div")
                          : scope.row.timeLeft >= 0
                          ? [
                              scope.row.timeLeft >= 25
                                ? _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.row.timeLeftDay) +
                                        _vm._s(_vm.$t("WorkCentreTable.Day")) +
                                        _vm._s(scope.row.timeLeftHour) +
                                        _vm._s(_vm.$t("WorkCentreTable.Hour")) +
                                        " "
                                    ),
                                  ])
                                : _c("div", [
                                    _vm._v(
                                      _vm._s(scope.row.timeLeftHour) +
                                        _vm._s(_vm.$t("WorkCentreTable.Hour"))
                                    ),
                                  ]),
                            ]
                          : [
                              scope.row.timeLeft <= -25
                                ? _c("div", [
                                    _c("span", { staticClass: "overtime" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("WorkCentreTable.TimedOut")
                                        )
                                      ),
                                    ]),
                                    _vm._v(
                                      _vm._s(scope.row.timeLeftDay) +
                                        _vm._s(_vm.$t("WorkCentreTable.Day")) +
                                        _vm._s(scope.row.timeLeftHour) +
                                        _vm._s(_vm.$t("WorkCentreTable.Hour")) +
                                        " "
                                    ),
                                  ])
                                : _c("div", [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("WorkCentreTable.TimedOut")
                                        )
                                      ),
                                    ]),
                                    _vm._v(
                                      _vm._s(scope.row.timeLeftHour) +
                                        _vm._s(_vm.$t("WorkCentreTable.Hour"))
                                    ),
                                  ]),
                            ],
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "lastFollowerName",
                  label: _vm.$t("WorkCentreTable.lastFollowerName"),
                  width: "80",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "lastFollowTime",
                  label: _vm.$t("WorkCentreTable.lastFollowTime"),
                  width: "160",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "planFinishTime",
                  label: _vm.$t("WorkCentreTable.planFinishTime"),
                  width: "160",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createUserName",
                  label: _vm.$t("WorkCentreTable.createUserName"),
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "actualFinishTime",
                  label: _vm.$t("WorkCentreTable.CompletionTime"),
                  width: "160",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "executeUserName",
                  label: _vm.$t("WorkCentreTable.executeUserName"),
                  width: "150",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("WorkCentreTable.ticketStatus"),
                  width: "160",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.ticketStatus === "closed"
                          ? _c(
                              "el-tag",
                              {
                                attrs: { type: "info" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleType(
                                      "ticketStatus",
                                      scope.row.ticketStatus
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("WorkCentreTable.close")))]
                            )
                          : scope.row.ticketStatus === "implementing"
                          ? _c(
                              "el-tag",
                              {
                                attrs: { type: "success" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleType(
                                      "ticketStatus",
                                      scope.row.ticketStatus
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("WorkCentreTable.implementing"))
                                ),
                              ]
                            )
                          : _c(
                              "el-tag",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.handleType(
                                      "ticketStatus",
                                      scope.row.ticketStatus
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("WorkCentreTable.complete"))
                                ),
                              ]
                            ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                staticStyle: { margin: "10px 0" },
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  "current-page": _vm.queryForm.pageNumber,
                  "page-sizes": [10, 20, 50, 100],
                  "page-size": _vm.queryForm.pageSize,
                  total: _vm.total,
                  background: "",
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageNumber", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageNumber", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageSize", $event)
                  },
                  "current-change": function ($event) {
                    return _vm.getData((_vm.isFind = 0))
                  },
                  "size-change": function ($event) {
                    return _vm.getData((_vm.isFind = 0))
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.$t("WorkCentreTable.MicroWorkOrder"),
            visible: _vm.drawerObj.visible,
            direction: "ltr",
            size: "70%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.drawerObj, "visible", $event)
            },
          },
        },
        [
          _c("tab-cmp", {
            attrs: {
              index: _vm.drawerObj.index,
              "user-id-and-name-obj": _vm.userIdAndNameObj,
              "details-obj": _vm.drawerObj.detailsObj,
              "ticket-option": _vm.ticketOption,
            },
            on: { close: _vm.addClose, closeDetailsCmp: _vm.closeDetailsCmp },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }