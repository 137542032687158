// import request from '@/utils/request';
import axios from 'axios';
const baseURL = process.env.VUE_APP_BASE_API;

// 上传发票
export const apiSitePayableUpdateSitePayFile = (data) => {
  return axios({
    method: 'POST',
    url: '/fa/sitePayable/updateSitePayFile',
    data,
    baseURL
  });

//   return request({
//     method: 'POST',
//     url: '/fa/sitePayable/updateSitePayFile',
//     data,
//     isLoading: isBool // 是否显示加载中参数
//   });
};
