<template>
  <div class="boxInfo1">
    <el-tabs type="border-card">
      <!--'新增报价'\'报价详情'-->
      <el-tab-pane :label="operationType === 1 ? $t('basicData.AddANewQuote') : $t('basicData.QuoteDetails')">
        <details-cmp :edit-data="editData" :operation-type="operationType" @saveData="saveChange" />
      </el-tab-pane>
      <!--操作日志-->
      <el-tab-pane v-if="operationType !== 1" :label="$t('basicData.OperationalLogs')">
        <operation-log :edit-data="editData" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: 'DetailLog',
  components: {
    'details-cmp': () => import('./DetailsCmp'),
    'operation-log': () => import('./operationLog')
  },
  props: {
    // 类型标识 1新增 2查看 3 编辑
    operationType: {
      type: Number,
      default: 1
    },
    editData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    saveChange(data) {
      this.$emit('saveChange', data);
    }
  }
};
</script>

<style>
.boxInfo1 .el-tabs__content{
  padding: 0;
}
</style>
