var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AnjunTableList", {
    attrs: {
      "header-list": _vm.tableHeaderList,
      "is-index": true,
      "is-tip": true,
      "table-data": _vm.goodsTableData,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }