var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Box" },
    [
      _c(
        "div",
        [
          _c(
            "el-steps",
            {
              attrs: {
                active: _vm.active,
                "finish-status": "success",
                "align-center": "",
              },
            },
            [
              _c("el-step", {
                attrs: { title: _vm.$t("collectionCenter.scanningVehicle") },
              }),
              _c("el-step", {
                attrs: { title: _vm.$t("collectionCenter.scanPgTrc") },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active == 0,
              expression: "active == 0",
            },
          ],
          staticClass: "scanClass",
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("collectionCenter.carScan")))]),
          _vm.isScanCar
            ? _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("collectionCenter.scanQRCodeOrNum"),
                  clearable: "",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.scanCar($event)
                  },
                },
                model: {
                  value: _vm.carMsg,
                  callback: function ($$v) {
                    _vm.carMsg = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "carMsg",
                },
              })
            : _c(
                "el-tooltip",
                {
                  attrs: {
                    effect: "dark",
                    content: _vm.$t("userManage.unauthorized"),
                    placement: "right",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t("collectionCenter.scanQRCodeOrNum"),
                      clearable: "",
                    },
                    model: {
                      value: _vm.carMsg,
                      callback: function ($$v) {
                        _vm.carMsg = typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "carMsg",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c("div", { staticClass: "dialogClass" }, [
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.carNum")) +
                  _vm._s(_vm.carList.id)
              ),
            ]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.licensePlateNum")) +
                  _vm._s(_vm.carList.vehicleNumber)
              ),
            ]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.operationM")) +
                  _vm._s(_vm.carList.operationModeName)
              ),
            ]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.carT")) +
                  _vm._s(_vm.carList.vehicleTypeName)
              ),
            ]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.carDriver")) +
                  _vm._s(_vm.carList.vehicleDriver)
              ),
            ]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.belongToSite")) +
                  _vm._s(_vm.carList.siteIdName)
              ),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.next } },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.next")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active == 1,
              expression: "active == 1",
            },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "scanClass" },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("collectionCenter.scanPgTrcN"))),
              ]),
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("collectionCenter.PleaseScan"),
                  clearable: "",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.scanBagNumber($event)
                  },
                },
                model: {
                  value: _vm.bagNumber,
                  callback: function ($$v) {
                    _vm.bagNumber = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "bagNumber",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active == 1,
              expression: "active == 1",
            },
          ],
        },
        [
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.sucTable.length > 0 },
              on: { click: _vm.last },
            },
            [_vm._v(_vm._s(_vm.$t("userManage.Previous")))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-top": "12px" },
              attrs: { type: "primary" },
              on: { click: _vm.finish },
            },
            [_vm._v(_vm._s(_vm.$t("collectionCenter.endOfUnloading")))]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active == 1,
              expression: "active == 1",
            },
          ],
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.superiorTransshipmentCenter,
                  expression: "superiorTransshipmentCenter",
                },
              ],
              staticClass: "titleTop",
            },
            [
              _vm._v(
                _vm._s(_vm.$t("customerManagements.upTransferName")) +
                  "：" +
                  _vm._s(_vm.superiorTransshipmentCenter)
              ),
            ]
          ),
          _c("div", { staticClass: "titleTop" }, [
            _vm._v(
              _vm._s(_vm.$t("collectionCenter.currentUnloadingVehicle")) +
                "：" +
                _vm._s(_vm.vehicleNumber)
            ),
          ]),
          _c("div", { staticClass: "titleTop" }, [
            _c("span", [
              _vm._v(
                " " + _vm._s(_vm.$t("collectionCenter.shouldUnloaded")) + "："
              ),
              _c("span", { staticClass: "totalClass" }, [
                _vm._v(_vm._s(_vm.loadedVehicle.loadedVehicleBagNum)),
              ]),
              _vm._v(_vm._s(_vm.$t("collectionCenter.bags")) + " "),
            ]),
            _c("span", { staticStyle: { "margin-left": "50px" } }, [
              _vm._v(
                " " + _vm._s(_vm.$t("collectionCenter.actualUnloaded")) + "："
              ),
              _c("span", { staticClass: "totalClass green" }, [
                _vm._v(_vm._s(_vm.actualUnloadedBags)),
              ]),
              _vm._v(_vm._s(_vm.$t("collectionCenter.bags")) + " "),
            ]),
          ]),
          _c("div", { staticClass: "titleTop" }, [
            _c("span", [
              _vm._v(
                " " + _vm._s(_vm.$t("collectionCenter.shouldUnload")) + "："
              ),
              _c("span", { staticClass: "totalClass" }, [
                _vm._v(_vm._s(_vm.shouldUnloadNum)),
              ]),
              _vm._v(_vm._s(_vm.$t("basicData.ticket")) + " "),
            ]),
            _c("span", { staticStyle: { "margin-left": "50px" } }, [
              _vm._v(" " + _vm._s(_vm.$t("GlobalSetObj.Descarregado")) + "："),
              _c("span", { staticClass: "totalClass green" }, [
                _vm._v(_vm._s(_vm.unloadedNum)),
              ]),
              _vm._v(_vm._s(_vm.$t("basicData.ticket")) + " "),
            ]),
          ]),
          _c("div", { staticClass: "titleTop" }, [
            _vm._v(_vm._s(_vm.$t("collectionCenter.scanned")) + " "),
            _c("span", { staticClass: "totalClass" }, [
              _vm._v(_vm._s(_vm.tablePage.length)),
            ]),
          ]),
          _c(
            "el-table",
            { attrs: { "max-height": 600, data: _vm.tablePage, border: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "bagNumber",
                  label: _vm.$t("collectionCenter.BagTrcNumber"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "scanTime",
                  label: _vm.$t("collectionCenter.scanTime"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.operation"),
                  fixed: "right",
                  width: "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popconfirm",
                          {
                            attrs: {
                              title: _vm.$t("collectionCenter.deleteSure"),
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.scanDelClick(scope.row, scope.$index)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  slot: "reference",
                                  type: "danger",
                                  size: "mini",
                                },
                                slot: "reference",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("collectionCenter.Delete"))
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.signVisible
        ? _c("div", { staticClass: "signMask" }, [
            _c("div", { staticClass: "signVisibleClass" }, [
              _c("div", {
                staticClass: "el-icon-close signClose",
                on: { click: _vm.closeSign },
              }),
              _c("div", { staticClass: "descriptionsClass" }, [
                _c(
                  "div",
                  { ref: "image" },
                  [
                    _c(
                      "el-descriptions",
                      {
                        attrs: {
                          title: "",
                          column: 1,
                          border: "",
                          "content-style": _vm.rowClass,
                          "label-style": _vm.labelRowClass,
                        },
                      },
                      [
                        _c("el-descriptions-item", { attrs: { label: "" } }, [
                          _c("div", { staticClass: "top1" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("collectionCenter.licensePlateNum")
                                ) + _vm._s(_vm.scanSign.vehicleNumber)
                              ),
                            ]),
                            _c("img", { attrs: { src: _vm.LOGO, alt: "" } }),
                          ]),
                        ]),
                        _c("el-descriptions-item", { attrs: { label: "" } }, [
                          _c("div", { staticClass: "isolateClass" }),
                        ]),
                        _c("el-descriptions-item", { attrs: { label: "" } }, [
                          _c("div", { staticClass: "two" }, [
                            _c("div", { staticClass: "dd" }, [
                              _vm._v(
                                _vm._s(_vm.$t("collectionCenter.unloadPoint"))
                              ),
                            ]),
                            _c("div", { staticClass: "dd" }, [
                              _vm._v(_vm._s(_vm.scanSign.unloadSite)),
                            ]),
                          ]),
                        ]),
                        _c("el-descriptions-item", { attrs: { label: "" } }, [
                          _c("div", { staticClass: "two" }, [
                            _c("div", { staticClass: "dd" }, [
                              _vm._v(
                                _vm._s(_vm.$t("collectionCenter.unloader")) +
                                  "："
                              ),
                            ]),
                            _c("div", { staticClass: "dd" }, [
                              _vm._v(_vm._s(_vm.scanSign.unloadUser)),
                            ]),
                          ]),
                        ]),
                        _c("el-descriptions-item", { attrs: { label: "" } }, [
                          _c("div", { staticClass: "two" }, [
                            _c("div", { staticClass: "dd" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "collectionCenter.unloadCompletionTime"
                                  )
                                ) + "："
                              ),
                            ]),
                            _c("div", { staticClass: "dd" }, [
                              _vm._v(_vm._s(_vm.scanSign.signerTime)),
                            ]),
                          ]),
                        ]),
                        _c("el-descriptions-item", { attrs: { label: "" } }, [
                          _c("div", { staticClass: "isolateClass" }, [
                            _vm._v(
                              _vm._s(_vm.$t("collectionCenter.infoPackage"))
                            ),
                          ]),
                        ]),
                        _c("el-descriptions-item", { attrs: { label: "" } }, [
                          _c("div", { staticClass: "two" }, [
                            _c("div", { staticClass: "dd" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("collectionCenter.totalUnloading")
                                )
                              ),
                            ]),
                            _c("div", { staticClass: "dd" }, [
                              _vm._v(
                                _vm._s(_vm.scanSign.bagNumberCount) +
                                  _vm._s(_vm.$t("collectionCenter.bag")) +
                                  "（" +
                                  _vm._s(_vm.scanSign.realityNumber) +
                                  "）" +
                                  _vm._s(_vm.$t("collectionCenter.ticket"))
                              ),
                            ]),
                          ]),
                        ]),
                        _c("el-descriptions-item", { attrs: { label: "" } }, [
                          _c("div", { staticClass: "isolateClass" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("collectionCenter.signatureConfirmation")
                              )
                            ),
                          ]),
                        ]),
                        _c("el-descriptions-item", { attrs: { label: "" } }, [
                          _c("div", { staticClass: "two" }, [
                            _c("div", { staticClass: "dd" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("collectionCenter.w1")) +
                                  _vm._s(_vm.scanSign.loadBagNumber) +
                                  " " +
                                  _vm._s(_vm.$t("collectionCenter.w2")) +
                                  _vm._s(_vm.scanSign.loadPackageNumber) +
                                  " " +
                                  _vm._s(_vm.$t("collectionCenter.w3")) +
                                  " "
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "dd textarea" },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("collectionCenter.Note")) +
                                      "："
                                  ),
                                ]),
                                _vm.showTime
                                  ? _c("div", { staticClass: "remarkShow" }, [
                                      _vm._v(
                                        " " + _vm._s(_vm.scanSign.remark) + " "
                                      ),
                                    ])
                                  : _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        autosize: "",
                                        placeholder: _vm.$t(
                                          "collectionCenter.errRemark"
                                        ),
                                      },
                                      model: {
                                        value: _vm.scanSign.remark,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.scanSign, "remark", $$v)
                                        },
                                        expression: "scanSign.remark",
                                      },
                                    }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("el-descriptions-item", { attrs: { label: "" } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "550px",
                                height: "260px",
                                overflow: "hidden",
                              },
                            },
                            [
                              _c("vue-esign", {
                                ref: "esign",
                                attrs: {
                                  width: 550,
                                  height: 260,
                                  "line-width": 5,
                                  "line-color": "#000",
                                  "bg-color": "#FFF",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "statementClass" }, [
                      _vm._v(
                        " Declaro para todos os fins legais que li e verifiquei a exatidão e veracidade de todas as informações contidas neste formulário, e assumo total responsabilidade civil e criminal por quaisquer danos materiais e morais decorrentes de qualquer não conformidade das informações aqui contidas. "
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "signBtn" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { type: "success" },
                        on: { click: _vm.handleGenerate },
                      },
                      [_vm._v(_vm._s(_vm.$t("collectionCenter.Confirm")))]
                    ),
                    _c(
                      "el-button",
                      { staticClass: "btn", on: { click: _vm.resetSign } },
                      [_vm._v(_vm._s(_vm.$t("collectionCenter.empty")))]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
        },
        [
          _c("audio", {
            ref: "errRef",
            attrs: { src: _vm.errorMp3, controls: "controls" },
          }),
          _c("audio", {
            ref: "okRef",
            attrs: { src: _vm.okMp3, controls: "controls" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }