var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WrapperHandleError",
    { attrs: { error: _vm.error, "is-error": _vm.isError } },
    [
      _c("SectionHeader", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("h1", { staticClass: "header-1" }, [
                  _vm._v(" " + _vm._s(_vm.$t("routes.routesTitle")) + " "),
                ]),
                _c(
                  "o-button",
                  {
                    attrs: { variant: "primary", size: "md" },
                    on: { click: _vm.handleVisibilityModalCreate },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("routes.createRoutesButton")) + " "
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "SkeletonTable",
        { attrs: { "is-loading": _vm.isLoadingTable } },
        [
          _c(
            "o-table",
            {
              staticClass: "space-m-y-32",
              attrs: {
                data: _vm.tableData,
                "row-key": "id",
                bordered: "",
                paginated: "",
                "backend-pagination": "",
                total: _vm.tableTotal,
                "per-page": _vm.queryParams.perPage,
                "current-page": _vm.queryParams.currentPage,
                "pagination-simple": false,
              },
              on: {
                "update:currentPage": function ($event) {
                  return _vm.$set(_vm.queryParams, "currentPage", $event)
                },
                "update:current-page": function ($event) {
                  return _vm.$set(_vm.queryParams, "currentPage", $event)
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "empty",
                  fn: function () {
                    return [
                      _vm._v(" " + _vm._s(_vm.$t("routes.noResults")) + " "),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("o-table-column", {
                attrs: {
                  field: "route_identification",
                  label: _vm.$t("routes.idRoutesLabel"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(
                          " " + _vm._s(props.row.route_identification) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: { label: "", width: "144" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("Actions", { attrs: { row: row } })]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "o-modal",
        {
          attrs: { active: _vm.isOpenModalCreate, width: "560" },
          on: { close: _vm.handleVisibilityModalCreate },
        },
        [
          _c("div", [
            _c("h2", { staticClass: "header-2" }, [
              _vm._v(_vm._s(_vm.$t("routes.createRoutesTitle"))),
            ]),
            _c("p", { staticClass: "paragraph" }, [
              _vm._v(_vm._s(_vm.$t("routes.createRoutesParagraph"))),
            ]),
          ]),
          _c("FormCreateRoute", {
            attrs: { "close-modal": _vm.handleVisibilityModalCreate },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }