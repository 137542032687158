<script >
import { ref, defineProps, computed } from '@vue/composition-api';
import { required, helpers } from '@vuelidate/validators';
import { baseService } from '@/api/transfer/base';
import { useVuelidate } from '@vuelidate/core';
import { useCreateTansferRouter, updateRotes } from '@/api/transfer/transferRoute/query';
import { transferRouterService } from '@/api/transfer/transferRoute';
const __sfc_main = {};
__sfc_main.props = {
  closeModal: {
    type: Function
  },
  data: {
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const {
    mutate,
    isLoading
  } = useCreateTansferRouter();
  const updateRoute = updateRotes();
  const formData = ref({
    base_start_iata: '',
    base_finished_iata: '',
    base_stops_iata: []
  });
  const formDataNew = ref({
    base_start_iata: '',
    base_finished_iata: '',
    base_stops_iata: []
  });
  const props = __props;
  function requiredList(value) {
    return value.length > 0;
  }

  // Props.data is for verify if the screen is for edit or create, if have props.data so is  edit
  if (props.data) {
    props.data.rm_route_transfer.rm_transfer_route.forEach(route => {
      const {
        shipping_company_base_IATA_fineshed,
        shipping_company_base_IATA_initial,
        shipping_company_base_IATA_stops
      } = route;
      if (shipping_company_base_IATA_fineshed !== null) {
        formData.value.base_finished_iata = shipping_company_base_IATA_fineshed;
      } else if (shipping_company_base_IATA_initial !== null) {
        formData.value.base_start_iata = shipping_company_base_IATA_initial;
      } else if (shipping_company_base_IATA_stops !== null) {
        formData.value.base_stops_iata.push(shipping_company_base_IATA_stops);
      }
    });
  }
  const rules = {
    base_start_iata: {
      required
    },
    base_finished_iata: {
      required
    },
    base_stops_iata: {
      requiredList: helpers.withMessage('Insira um valor', requiredList)
    }
  };

  // Props.data is for verify if the screen is for edit or create, if have props.data so is  edit
  if (props.data) {
    props.data.rm_route_transfer.rm_transfer_route.forEach(route => {
      const {
        shipping_company_base_IATA_fineshed,
        shipping_company_base_IATA_initial,
        shipping_company_base_IATA_stops
      } = route;
      if (shipping_company_base_IATA_fineshed !== null) {
        formData.value.base_finished_iata = shipping_company_base_IATA_fineshed;
      } else if (shipping_company_base_IATA_initial !== null) {
        formData.value.base_start_iata = shipping_company_base_IATA_initial;
      } else if (shipping_company_base_IATA_stops !== null) {
        formData.value.base_stops_iata.push(shipping_company_base_IATA_stops);
      }
    });
  }
  const v$ = useVuelidate(rules, formData);
  const handlePushNewItem = item => {
    formDataNew.value.base_stops_iata = [...formDataNew.value.base_stops_iata, item.rm_shipping_company_base_IATA];
    return item.shipping_company_base;
  };
  function changeInfoForm(value, input) {
    if (input === 'base_start') {
      formDataNew.value.base_start_iata = value.rm_shipping_company_base_IATA;
    } else formDataNew.value.base_finished_iata = value.rm_shipping_company_base_IATA;
  }
  const bases = ref([]);
  const handleGetBases = async value => {
    try {
      const response = await baseService.getAll(0, 10, {
        name: value
      });
      bases.value = response.data?.result;
    } catch (error) {
      // bases.value = [];
    }
  };
  const handleSubmit = async () => {
    var conjuntoUnico = new Set(formDataNew.value.base_stops_iata);
    formDataNew.value.base_stops_iata = Array.from(conjuntoUnico);
    formDataNew.value.base_stops_iata = formDataNew.value.base_stops_iata.filter(item => item !== undefined && item !== null);
    if (!props.data) {
      mutate(formDataNew.value, {
        onSuccess() {
          props.closeModal();
        }
      });
    } else {
      const route = {
        ...formDataNew.value,
        route_id: props.data.route_id,
        route_transfer_id: props.data.rm_route_transfer.id
      };
      updateRoute.mutate({
        route,
        id: props.data.route_id
      });
    }
  };
  function removeItem(remove, value) {
    const a = value.find(base => base.shipping_company_base === remove);
    formDataNew.value.base_stops_iata = formDataNew.value.base_stops_iata.map(stop => {
      if (stop !== a.rm_shipping_company_base_IATA) {
        return stop;
      }
    });
  }
  return {
    isLoading,
    formData,
    v$,
    handlePushNewItem,
    changeInfoForm,
    bases,
    handleGetBases,
    handleSubmit,
    removeItem
  };
};
export default __sfc_main;
</script>

<template>
  <form class="wrapper-form" @submit.prevent="handleSubmit">
    <o-field
      label="Base inicial"
      label-for="base_start"
      :message="v$.base_start_iata.$errors.length ? v$.base_start_iata.$errors[0].$message : null"
    >

      <o-autocomplete
        id="base_start"
        v-model="formData.base_start_iata"
        class="col-2"
        placeholder="Digite a base inicial"
        clearable
        expanded
        field="shipping_company_base"
        :data="bases"
        @input="handleGetBases"
        @select="res => changeInfoForm(res, 'base_start')"
      >
        <template slot="empty">Sem resultados</template>

      </o-autocomplete>
    </o-field>

    <o-field
      label="Pontos de parada"
      label-for="base_stops"
      :message="v$.base_stops_iata.$errors.length ? v$.base_stops_iata.$errors[0].$message : null"
    >
      <o-inputitems
        id="base_stops"
        ref="inputColor"
        v-model="formData.base_stops_iata"
        placeholder="Selecione os pontos de parada"
        :data="bases"
        :allow-new="false"
        :open-on-focus="false"
        :autocomplete="true"
        :allow-autocomplete="true"
        field="shipping_company_base"
        :create-item="handlePushNewItem"
        @typing="handleGetBases"
        @remove="remove => removeItem(remove, bases)"
      >
        <template #selected="{ items }">
          <span v-for="(item, index) in items" :key="index" class="item_selected" rounded @click="$refs.inputColor.removeItem(index, $event)">
            {{ item }}
            <div style="margin-left: 5px;cursor: pointer;" @click="$refs.inputColor.removeItem(index, $event)">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
            </div>
          </span>
        </template>
      </o-inputitems>
    </o-field>

    <o-field
      label="Base final"
      label-for="base_finished"
      :message="v$.base_finished_iata.$errors.length ? v$.base_finished_iata.$errors[0].$message : null"
    >

      <o-autocomplete
        id="base_finished"
        v-model="formData.base_finished_iata"
        class="col-2"
        placeholder="Digite a base final"
        clearable
        expanded
        field="shipping_company_base"
        :data="bases"
        @input="handleGetBases"
        @select="res => changeInfoForm(res, 'base_finished')"
      >
        <template slot="empty">Sem resultados</template>

      </o-autocomplete>
    </o-field>

    <o-button class="button-submit" variant="primary" size="md" native-type="submit">Enviar</o-button>

    <o-loading :full-page="true" :active.sync="isLoading" />
  </form>

</template>

<style scoped>
.wrapper-form{
  display: grid;
  gap: 1rem; /* 16px */
}
.button-submit {
  margin-top: 10px;
  float: right;
}
</style>
