var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Box" },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c(
            "el-form",
            [
              _c(
                "el-row",
                {
                  staticClass: "selectClass",
                  staticStyle: { "flex-wrap": "wrap" },
                  attrs: { justify: "start", type: "flex" },
                },
                [
                  _c(
                    "el-col",
                    { staticClass: "col2" },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "autoWidth",
                          attrs: {
                            placeholder: _vm.$t(
                              "collectionCenter.pleaseChoose"
                            ),
                          },
                          on: { change: _vm.changeTime },
                          model: {
                            value: _vm.selectTime,
                            callback: function ($$v) {
                              _vm.selectTime = $$v
                            },
                            expression: "selectTime",
                          },
                        },
                        [
                          _c("template", { slot: "prefix" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (
                                    _vm.timeList.find(function (item) {
                                      return item.value === _vm.selectTime
                                    }) || {}
                                  ).time
                                ) +
                                " "
                            ),
                          ]),
                          _vm._l(_vm.timeList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.time, value: item.value },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "delMl" },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              clearable: false,
                              "end-placeholder": _vm.$t(
                                "collectionCenter.EndTime"
                              ),
                              "range-separator": _vm.$t("collectionCenter.to"),
                              "start-placeholder": _vm.$t(
                                "collectionCenter.StartTime"
                              ),
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              "default-time": ["00:00:00", "23:59:59"],
                              type: "datetimerange",
                            },
                            on: { change: _vm.dateChange },
                            model: {
                              value: _vm.timeValue,
                              callback: function ($$v) {
                                _vm.timeValue = $$v
                              },
                              expression: "timeValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.CustomerN"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              on: { focus: _vm.getAllCustomer },
                              model: {
                                value: _vm.queryForm.params.customerId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "customerId",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.customerId",
                              },
                            },
                            _vm._l(
                              _vm.customerNameList,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.username,
                                    value: item.id,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "collectionCenter.PlatformWhichBelongs"
                            ),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                clearable: "",
                              },
                              model: {
                                value: _vm.queryForm.params.platform,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "platform",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.platform",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { value: 1, label: "shein" },
                              }),
                              _c("el-option", {
                                attrs: { value: 2, label: "kwai" },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t(
                                    "collectionCenter.expressDelivery"
                                  ),
                                  value: 3,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("collectionCenter.other"),
                                  value: 0,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:report:deliveryData:find",
                          expression:
                            "'btn:collection-center:report:deliveryData:find'",
                          arg: "remove",
                        },
                      ],
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-search",
                      },
                      on: { click: _vm.searchClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.search")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:report:deliveryData:reset",
                          expression:
                            "'btn:collection-center:report:deliveryData:reset'",
                          arg: "remove",
                        },
                      ],
                      attrs: { icon: "el-icon-refresh", size: "mini" },
                      on: { click: _vm.resetClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:report:deliveryData:export",
                          expression:
                            "'btn:collection-center:report:deliveryData:export'",
                          arg: "remove",
                        },
                      ],
                      attrs: { icon: "el-icon-upload2", size: "mini" },
                      on: { click: _vm.download },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.export")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          model: {
            value: _vm.active,
            callback: function ($$v) {
              _vm.active = $$v
            },
            expression: "active",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("collectionCenter.summary"),
                name: "gather",
              },
            },
            [
              _c(
                "el-table",
                {
                  ref: "hzTable",
                  attrs: {
                    "max-height": 600,
                    border: "",
                    data: _vm.tableDateHZ,
                    "show-summary": "",
                    "summary-method": _vm.getSummaries,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("collectionCenter.ind"),
                      type: "index",
                      width: "60",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  (_vm.queryForm.pageNumber - 1) *
                                    _vm.queryForm.pageSize +
                                    scope.$index +
                                    1
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "customerId",
                      label: _vm.$t("collectionCenter.clientID"),
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "customerName",
                      label: _vm.$t("collectionCenter.CustomerName"),
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "platformName",
                      label: _vm.$t("collectionCenter.platform"),
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "scanTime",
                      label: _vm.$t("collectionCenter.lanshouT"),
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "signerTime",
                      label: _vm.$t("collectionCenter.signT"),
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "closeTime",
                      label: _vm.$t("collectionCenter.closeTime"),
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "collectNumberCount",
                      label: _vm.$t("collectionCenter.receivedParcelsTotal"),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.detailClick(scope.row, 1)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.collectNumberCount))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "closeNumberCount",
                      label: _vm.$t("collectionCenter.closedTotalParcels"),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.detailClick(scope.row, 2)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.closeNumberCount))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "unloadTimeCount",
                      label: _vm.$t("collectionCenter.unloadedParcelsTotalNum"),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.detailClick(scope.row, 3)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.unloadTimeCount))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "signerNumberCount",
                      label: _vm.$t("collectionCenter.signTotalParcels"),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.detailClick(scope.row, 4)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.signerNumberCount))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "signerUnloadDiscrepancyCount",
                      label: _vm.$t(
                        "collectionCenter.signedUnloadedDiffPackages"
                      ),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.detailClick(scope.row, 7)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(scope.row.signerUnloadDiscrepancyCount)
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "closeSignerDiscrepancyCount",
                      label: _vm.$t(
                        "collectionCenter.closedSignedDiffPackages"
                      ),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.detailClick(scope.row, 8)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(scope.row.closeSignerDiscrepancyCount)
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        align: "center",
                        label: _vm.$t(
                          "collectionCenter.warehouseDeliveryAbnormalData"
                        ),
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "suspectedLostPackages",
                          label: _vm.$t(
                            "collectionCenter.suspectedMissingItem"
                          ),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.detailClick(scope.row, 5)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(scope.row.suspectedLostPackages)
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "suspectedMissScanPackages",
                          label: _vm.$t("collectionCenter.suspectedMissedScan"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.detailClick(scope.row, 6)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.suspectedMissScanPackages
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c("el-pagination", {
                    staticStyle: { margin: "10px 0" },
                    attrs: {
                      layout: "total, sizes, prev, pager, next, jumper",
                      "current-page": _vm.queryForm.pageNumber,
                      "page-sizes": [10, 20, 50, 100, 500, 1000],
                      "page-size": _vm.queryForm.pageSize,
                      total: _vm.total,
                      background: "",
                    },
                    on: {
                      "update:currentPage": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageNumber", $event)
                      },
                      "update:current-page": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageNumber", $event)
                      },
                      "update:pageSize": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageSize", $event)
                      },
                      "update:page-size": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageSize", $event)
                      },
                      "current-change": _vm.getTablePage,
                      "size-change": _vm.getPageSize,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.active == "detail"
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("collectionCenter.particulars"),
                    name: "detail",
                  },
                },
                [
                  _c(
                    "el-table",
                    {
                      ref: "hzTable",
                      attrs: {
                        "max-height": 600,
                        border: "",
                        data: _vm.tableDateMX,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("collectionCenter.ind"),
                          type: "index",
                          width: "60",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        (_vm.queryFormMX.pageNumber - 1) *
                                          _vm.queryFormMX.pageSize +
                                          scope.$index +
                                          1
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2346069176
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "orderNumber",
                          label: _vm.$t("collectionCenter.orderNumber"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "collectTime",
                          label: _vm.$t("collectionCenter.lanshouT"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "collectName",
                          label: _vm.$t("collectionCenter.LanShouPeople"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "collectSiteName",
                          label: _vm.$t("collectionCenter.LanShouSite"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "unloadTime",
                          label: _vm.$t("collectionCenter.unloadTime"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "unloadUserName",
                          label: _vm.$t("collectionCenter.unloader"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "unloadSiteName",
                          label: _vm.$t("collectionCenter.unloadDot"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "signerTime",
                          label: _vm.$t("collectionCenter.signT"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "closeTime",
                          label: _vm.$t("collectionCenter.closeTime"),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-pagination", {
                        staticStyle: { margin: "10px 0" },
                        attrs: {
                          layout: "total, sizes, prev, pager, next, jumper",
                          "current-page": _vm.queryFormMX.pageNumber,
                          "page-sizes": [10, 20, 50, 100, 500, 1000],
                          "page-size": _vm.queryFormMX.pageSize,
                          total: _vm.totalMX,
                          background: "",
                        },
                        on: {
                          "update:currentPage": function ($event) {
                            return _vm.$set(
                              _vm.queryFormMX,
                              "pageNumber",
                              $event
                            )
                          },
                          "update:current-page": function ($event) {
                            return _vm.$set(
                              _vm.queryFormMX,
                              "pageNumber",
                              $event
                            )
                          },
                          "update:pageSize": function ($event) {
                            return _vm.$set(_vm.queryFormMX, "pageSize", $event)
                          },
                          "update:page-size": function ($event) {
                            return _vm.$set(_vm.queryFormMX, "pageSize", $event)
                          },
                          "current-change": _vm.getTablePage,
                          "size-change": _vm.getPageSize,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }