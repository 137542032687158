var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticStyle: { "margin-bottom": "20px" } },
      [
        _c("div", { staticStyle: { position: "relative" } }, [
          _c("div", { staticClass: "headerTitle" }, [
            _vm._v(_vm._s(_vm.$t("customerManagements.tip100"))),
          ]),
          _c(
            "div",
            { staticClass: "chajian" },
            [
              _vm._v(
                "(" + _vm._s(_vm.$t("placeOrder.automaticPrinting1")) + " "
              ),
              _c(
                "el-link",
                {
                  staticStyle: { "font-size": "16px" },
                  attrs: {
                    href: "https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/software/安骏桌面插件/安骏桌面插件.exe",
                    type: "primary",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("placeOrder.automaticPrinting2")) + " ")]
              ),
              _vm._v(" ) "),
            ],
            1
          ),
        ]),
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c(
              "el-col",
              { attrs: { span: 15 } },
              [
                _c("el-card", { attrs: { shadow: "never" } }, [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "margin-right": "15px",
                            "font-weight": "bold",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("GlobalSetObj.ScanNumber")) +
                              " "
                          ),
                        ]
                      ),
                      _c("el-input", {
                        directives: [
                          {
                            name: "focus",
                            rawName: "v-focus",
                            value: _vm.isFocus,
                            expression: "isFocus",
                          },
                        ],
                        ref: "SingleNumberInput",
                        staticStyle: { width: "280px" },
                        attrs: {
                          placeholder: _vm.$t(
                            "GlobalSetObj.PleaseInputScanNumber"
                          ),
                          clearable: "",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.PrintSingleNumber($event)
                          },
                        },
                        model: {
                          value: _vm.ruleForm.SingleNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "SingleNumber", $$v)
                          },
                          expression: "ruleForm.SingleNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { span: 9 } },
              [
                _c("el-card", { attrs: { shadow: "never" } }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "font-weight": "bold",
                        "margin-bottom": "15px",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("customerManagements.scannerRecord")) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticStyle: { height: "600px", "overflow-y": "auto" } },
                    _vm._l(_vm.tableData, function (item, index) {
                      return _c("div", { key: index }, [
                        _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.tableData.length - index) + ". "
                          ),
                        ]),
                        _c("span", [_vm._v(_vm._s(item))]),
                      ])
                    }),
                    0
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
      },
      [
        _c("audio", {
          ref: "errRef",
          attrs: { src: _vm.errorMp3, controls: "controls" },
        }),
        _c("audio", {
          ref: "okRef",
          attrs: { src: _vm.okMp3, controls: "controls" },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }