<script >
import { ref, defineProps } from '@vue/composition-api';
import { default as FormVehicleData } from './steps/formVehicleData';
import { default as FormAdditionalData } from './steps/formAdditionalData';
import { default as FormMeasurementData } from './steps/formMeasurementData';
import { useWizardForm } from '@/composable/useWizardForm';
import { useEditVehicle, useEditVehicleOnRotate } from '@/api/transport/vehicle/query';
const __sfc_main = {};
__sfc_main.props = {
  closeModal: {
    type: Function
  },
  vehicle: {
    type: Object
  },
  status: {
    type: Number
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const STEPS_TOTAL = 3;
  const formData = ref({
    formStep01: {
      document: props.vehicle.vehicle_documentation,
      licensePlate: props.vehicle.vehicle_license_plate,
      trailerPlate: props.vehicle.trailer_plate,
      rntrc: props.vehicle.vehicle_RNTRC,
      model: props.vehicle.vehicle_model,
      brand: props.vehicle.vehicle_brand,
      state: props.vehicle.vehicle_state,
      provider: props.vehicle.provider_name,
      traction: !!props.vehicle.vehicle_traction,
      outsourced: !!props.vehicle.vehicle_outsourced
    },
    formStep02: {
      capacityKg: props.vehicle.vehicle_capacity_kg,
      capacityM3: props.vehicle.vehicle_capacity_m3,
      wheelsetType: props.vehicle.vehicle_wheelset_type,
      bodyType: props.vehicle.vehicle_body_type,
      weight: props.vehicle.vehicle_tare_weight
    },
    formStep03: {
      documentOwner: props.vehicle.vehicle_type_ownership ? props.vehicle.vehicle_owner_CNPJ : props.vehicle.vehicle_owner_CPF,
      stateRegistration: props.vehicle.vehicle_state_registration,
      trackerCompany: props.vehicle.car_tracker_company_name,
      trackerCompanyCode: props.vehicle.car_tracker_id
    }
  });
  const {
    mutate,
    isLoading
  } = useEditVehicle();
  const {
    mutate: mutateRotate,
    isLoading: isLoadingRotate
  } = useEditVehicleOnRotate();
  function handleSubmit() {
    const newVehicle = {
      ...formData.value.formStep01,
      ...formData.value.formStep02,
      ...formData.value.formStep03
    };
    if (props.status === 2) {
      mutateRotate({
        newVehicle,
        id: props.vehicle.id
      }, {
        onSuccess() {
          props.closeModal();
        }
      });
    } else {
      mutate({
        newVehicle,
        id: props.vehicle.id
      }, {
        onSuccess() {
          props.closeModal();
        }
      });
    }
  }
  const {
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  } = useWizardForm(formData, handleSubmit, STEPS_TOTAL);
  return {
    formData,
    isLoading,
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  };
};
__sfc_main.components = Object.assign({
  FormVehicleData,
  FormMeasurementData,
  FormAdditionalData
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <FormVehicleData
      v-show="step === 0"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      :form-data.sync="formData.formStep01"
    />

    <FormMeasurementData
      v-show="step === 1"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      :form-data.sync="formData.formStep02"
    />

    <FormAdditionalData
      v-show="step === 2"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      :form-data.sync="formData.formStep03"
    />

    <o-loading :full-page="true" :active.sync="isLoading" />
  </div>
</template>
