/**
 * 客户
 */
const TYPY_OBJ = {
  'add': {
    code: 'add',
    name: '新增'
  },
  'edit': {
    code: 'edit',
    name: '编辑'
  },
  'examine': {
    code: 'examine',
    name: '查看'
  },
  'set': {
    code: 'set',
    name: '账户操作'
  }
};

export {
  TYPY_OBJ
};
