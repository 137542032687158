var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "queryForm",
              staticClass: "queryFormClass",
              attrs: {
                model: _vm.queryForm,
                "label-position": "top",
                size: "small",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.InvoicingTime"),
                            prop: "invoicingTime",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "datetimerange",
                              format:
                                _vm.$i18n.locale === "zh"
                                  ? _vm.zhDate
                                  : _vm.ptDate,
                              placeholder: _vm.$t("basicData.SelectADatetime"),
                              "picker-options": _vm.pickerOptions,
                              "default-time": ["00:00:00", "23:59:59"],
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                            },
                            on: { blur: _vm.refreshDate },
                            model: {
                              value: _vm.queryDate,
                              callback: function ($$v) {
                                _vm.queryDate = $$v
                              },
                              expression: "queryDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.businessT"),
                            prop: "businessType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                clearable: "",
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                              },
                              model: {
                                value: _vm.queryForm.params.businessType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "businessType",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.businessType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("collectionCenter.collect"),
                                  value: "COLLECT",
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("collectionCenter.dispatch"),
                                  value: "DELIVERY",
                                },
                              }),
                              _c("el-option", {
                                attrs: { label: "SFS", value: "SFS" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("basicData.InvoiceStatus"),
                            prop: "invoiceStatus",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                clearable: "",
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                              },
                              model: {
                                value: _vm.queryForm.params.invoiceStatus,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "invoiceStatus",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.invoiceStatus",
                              },
                            },
                            _vm._l(_vm.invoiceStatusObj, function (value, key) {
                              return _c("el-option", {
                                key: key,
                                attrs: { label: value, value: key },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "searchNumberType" },
                        [
                          _c(
                            "template",
                            { slot: "label" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.pleaseChoose"
                                    ),
                                  },
                                  model: {
                                    value: _vm.searchNumberType,
                                    callback: function ($$v) {
                                      _vm.searchNumberType = $$v
                                    },
                                    expression: "searchNumberType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: "1",
                                    attrs: {
                                      label: _vm.$t("basicData.InvoiceNumber"),
                                      value: 1,
                                    },
                                  }),
                                  _c("el-option", {
                                    key: "2",
                                    attrs: {
                                      label: _vm.$t(
                                        "receivablePayable.TicketNumber"
                                      ),
                                      value: 2,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 4,
                              placeholder: _vm.$t("newOrder.PreciseSearch1"),
                            },
                            model: {
                              value: _vm.searchNumber,
                              callback: function ($$v) {
                                _vm.searchNumber = $$v
                              },
                              expression: "searchNumber",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { type: "flex", justify: "start" } },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          icon: "el-icon-search",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getDataSearch()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("basicData.Search")) + " ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { icon: "el-icon-refresh", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.getDataSearch(0)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("basicData.reset")) + " ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { "margin-top": "10px" } },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.bulkDownload()
                    },
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("orderCenterCont.BulkDownload")) + " "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.exportData()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("collectionCenter.export")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.onExportusinessOrderNumberInvoiceNumber },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("basicData.numberOrInvoiceNumber")) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "elTable",
              staticClass: "table-height",
              attrs: {
                data: _vm.tableData,
                height: _vm.tableHeightRow.height,
                border: "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  "reserve-selection": true,
                  width: "55",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("basicData.serialNumber"),
                  width: "80px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.queryForm.pageNumber - 1) *
                                _vm.queryForm.pageSize +
                                scope.$index +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c(
                "el-table-column",
                {
                  attrs: {
                    label: _vm.$t("collectionCenter.invoiceT"),
                    width: "160",
                    align: "center",
                  },
                },
                [[_c("p", [_vm._v("CTE")])]],
                2
              ),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.InvoicingTime"),
                  width: "160",
                  align: "center",
                  prop: "invoiceIssueTime",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.businessT"),
                  width: "120",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.businessType) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("basicData.InvoiceNumber"),
                  width: "160",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "top-end",
                              "popper-class": "copy",
                              trigger: "hover",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.copy(scope.row.invoiceNumber)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("orderDetails.copy")))]
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#409eff",
                                  cursor: "pointer",
                                },
                                attrs: { slot: "reference" },
                                on: {
                                  click: function ($event) {
                                    return _vm.checkDetail(scope.row.id)
                                  },
                                },
                                slot: "reference",
                              },
                              [_vm._v(_vm._s(scope.row.invoiceNumber))]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("newOrder.InvoiceKey"),
                  width: "160",
                  align: "center",
                  prop: "invoiceSecretKey",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("receivablePayable.TicketNumber"),
                  width: "160",
                  align: "center",
                  prop: "businessNumber",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("basicData.BillingBody"),
                  width: "160",
                  align: "center",
                  prop: "invoiceEntity",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("basicData.status"),
                  width: "120",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.invoiceStatus) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("receivablePayable.totalInvoiceAmount"),
                  align: "center",
                  prop: "invoiceTotalAmount",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("receivablePayable.ICMSAmount"),
                  align: "center",
                  prop: "icmsAmount",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("receivablePayable.shippingAmount"),
                  align: "center",
                  prop: "freightAmount",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("receivablePayable.BillingSubjectCNPJ"),
                  align: "center",
                  prop: "invoiceEntityCnpj",
                  width: "140",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                staticStyle: { margin: "10px 0" },
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  "current-page": _vm.queryForm.pageNumber,
                  "page-sizes": [10, 20, 50, 100],
                  "page-size": _vm.queryForm.pageSize,
                  total: _vm.total,
                  background: "",
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageNumber", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageNumber", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageSize", $event)
                  },
                  "current-change": _vm.getTableData,
                  "size-change": _vm.getTableData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("ExportusinessOrderNumberInvoiceNumberCmp", {
        ref: "exportusinessOrderNumberInvoiceNumberRef",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }