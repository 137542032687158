<template>
  <div class="app-container">
    <el-form
      :key="tableKey" inline
      label-position="top" size="small"
    >
      <!--运单状态-->
      <el-form-item v-if="searchProp.includes('status')" :label="$t('newOrder.waybillStatus')">
        <el-select
          v-model="queryForm.params.waybillStatus"
          :placeholder="$t('GlobalSetObj.all')"
          clearable
          collapse-tags
          filterable
          multiple
          size="small"
          style="width:330px"
          @change="selectStatus"
        >
          <el-option
            v-for="(item, ind) in waybillStatusList"
            :key="ind"
            :label="item.label"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <!--客户名称-->
      <el-form-item v-if="searchProp.includes('customerName')" :label="$t('collectionCenter.CustomerName')">
        <el-select
          v-model="queryForm.params.customerIds"
          :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
          :remote-method="getCustomerPage"
          clearable
          filterable
          multiple
          remote
          reserve-keyword
          style="width:250px"
          @focus="getCustomerPage()"
        >
          <el-option
            v-for="(item) in customerList"
            :key="item.id"
            :label="item.customerAlias + '(' + item.username + ')'"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <!--派送网点-->
      <el-form-item
        v-if="isArrayInclude(searchProp, ['preDeliverStationName', 'deliverStationName', 'warehouseStationName'])"
        prop="siteDataId"
      >
        <template slot="label">
          <div class="selectLabel">
            <el-select
              v-model="queryForm.params.providerType"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
            >
              <!--预派送网点-->
              <el-option
                v-if="searchProp.includes('preDeliverStationName')"
                key="1"
                :label="$t('GlobalSetObj.preEntrega')"
                :value="1"
              />
              <!--实际派送网点-->
              <el-option
                v-if="searchProp.includes('deliverStationName')"
                key="2"
                :label="$t('GlobalSetObj.entrega')"
                :value="2"
              />
              <!--实际入库网点-->
              <el-option
                v-if="searchProp.includes('warehouseStationName')"
                key="3"
                :label="$t('GlobalSetObj.infeatSite')"
                :value="3"
              />
            </el-select>
          </div>
        </template>
        <el-select
          v-model="queryForm.params.siteDataId"
          :placeholder="$t('GlobalSetObj.pleaseChoose')"
          :remote-method="getSupperSite"
          clearable
          filterable
          multiple
          remote
          reserve-keyword
          size="small"
          style="width: 320px"
          @change="hanleSiteId"
          @focus="getSupperSite('')"
        >
          <el-option
            v-for="item in siteData"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <!--   是否超时   -->
      <el-form-item :label="$t('orderCenterCont.TimeoutOrNot')">
        <el-select
          v-model="queryForm.params.isTimeOut"
        >
          <el-option :label="$t('GlobalSetObj.all')" :value="-1" />
          <el-option :label="$t('GlobalSetObj.yes')" :value="1" />
          <el-option :label="$t('GlobalSetObj.No')" :value="0" />
        </el-select>
      </el-form-item>
      <!--州-->
      <el-form-item
        v-if="isArrayInclude(searchProp, ['receiveState', 'warehouseState'])"
        prop="providerType"
      >
        <template slot="label">
          <div class="selectLabel">
            <el-select
              v-model="queryForm.params.stateType"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              :style="IsWidth"
            >
              <!--收件人州-->
              <el-option
                v-if="searchProp.includes('receiveState')"
                key="1"
                :label="$t('newOrder.RecipientState')"
                :value="0"
              />
              <!--实际入库网点州-->
              <el-option
                v-if="searchProp.includes('warehouseState')"
                key="2"
                :label="$t('GlobalSetObj.storageOutletsState')"
                :value="1"
              />
            </el-select>
          </div>
        </template>
        <el-select
          v-model="queryForm.params.stateList"
          :placeholder="$t('GlobalSetObj.pleaseChoose')"
          clearable
          filterable
          multiple
          style="width: 250px;"
        >
          <el-option
            v-for="item in StateList"
            :key="item.id"
            :label="item.label"
            :value="item.label"
          />
        </el-select>
      </el-form-item>

      <!-- 丢失类型 -->
      <el-form-item v-if="searchProp.includes('lostType')" :label="$t('newOrder.LostType')">
        <el-select
          v-model="queryForm.params.lostType"
          :placeholder="$t('GlobalSetObj.pleaseChoose')"
          style="width: 250px;"
        >
          <el-option :label="$t('GlobalSetObj.all')" :value="null" />
          <el-option
            v-for="item in LOST_TYPE"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <!--创建时间-->
      <el-form-item
        v-if="isArrayInclude(searchProp, [
          'createTime',
          'customerSendTime',
          'collectScanTime',
          'receiptTime',
          'sendTime',
          'deliverUploadScanTime',
          'dispatchReceiptTime',
          'lastDeliveryTime',
          'deliveredTime',
          'startDeliveryTime',
          'customerAgingEndTime'
        ])"
        prop="searchTimeType"
      >
        <template slot="label">
          <div class="selectLabel" style="width:450px">
            <el-select
              v-model="queryForm.params.timeType"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
            >
              <!--订单创建时间-->
              <el-option
                v-if="searchProp.includes('createTime')" key="1"
                :label="$t('newOrder.orderCreateTime')" :value="1"
              />
              <!--揽收时间-->
              <el-option
                v-if="searchProp.includes('collectScanTime')" key="2"
                :label="$t('newOrder.lanshouT')" :value="2"
              />
              <!--一级分拨到件时间-->
              <el-option
                v-if="searchProp.includes('receiptTime')"
                key="3" :label="$t('GlobalSetObj.oneTransitInboundTime')" :value="3"
              />
              <!--一级分拨发件时间-->
              <el-option
                v-if="searchProp.includes('sendTime')"
                key="4" :label="$t('GlobalSetObj.oneshipmentTime')" :value="4"
              />
              <!--二级分拨到件时间-->
              <el-option
                v-if="searchProp.includes('transferInStorageTime')"
                key="13" :label="$t('GlobalSetObj.twoTransitInboundTime')" :value="13"
              />
              <!--二级分拨发件时间-->
              <el-option
                v-if="searchProp.includes('transferOutStorageTime')"
                key="14" :label="$t('GlobalSetObj.twoshipmentTime')" :value="14"
              />
              <!--网点入库时间-->
              <el-option
                v-if="searchProp.includes('dispatchReceiptTime')"
                key="5" :label="$t('newOrder.StorageTimeOutlets')" :value="5"
              />
              <!--首派时间-->
              <el-option
                v-if="searchProp.includes('startDeliveryTime')"
                key="8" :label="$t('orderCenterCont.FirstDeliveryTime')" :value="8"
              />
              <!--网点派件时间-->
              <el-option
                v-if="searchProp.includes('lastDeliveryTime')"
                key="9" :label="$t('orderCenterCont.deliveryTime')" :value="9"
              />
              <!--签收时间-->
              <el-option
                v-if="searchProp.includes('deliveredTime')"
                key="6" :label="$t('orderCenterCont.SigningTime')" :value="6"
              />
              <!--客户出仓时间-->
              <el-option
                v-if="searchProp.includes('customerSendTime')"
                key="10" :label="$t('orderCenterCont.customerDeliveryTime')" :value="10"
              />
              <!--理应送达时间-->
              <el-option
                v-if="searchProp.includes('customerAgingEndTime')"
                key="12" :label="$t('orderCenterCont.dueTime01')" :value="12"
              />
            </el-select>
          </div>
        </template>
        <el-date-picker
          v-model="timeValue"
          :clearable="true"
          :default-time="['00:00:00', '23:59:59']"
          :format="$i18n.locale=='zh'?zhDate:ptDate"
          :picker-options="pickerOptions"
          :placeholder="$t('newOrder.SelectDatetime')"
          range-separator="~"
          style="width:450px"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="handleTime"
          @input="changeInput($event)"
        />
      </el-form-item>
      <!--  运单号   -->
      <el-form-item v-if="searchProp.includes('waybillNumber')" :label="$t('newOrder.waybillNumber')" prop="inputValue">
        <el-input
          v-model="queryForm.params.inputValue"
          :placeholder="$t('newOrder.PreciseSearch1')"
          :rows="1"
          style="width: 260px;"
          type="textarea"
        />
      </el-form-item>
      <div style="margin-top: 10px">
        <el-form-item>
          <!--搜索-->
          <el-button
            icon="el-icon-search" size="small" type="primary"
            @click="enterSearch"
          >{{ $t('newOrder.search') }}
          </el-button>
          <!--重置-->
          <el-button
            icon="el-icon-refresh-left" size="small" type="info"
            @click="resetForm()"
          >{{ $t('newOrder.reset') }}
          </el-button>
          <el-button
            icon="el-icon-upload2" size="small" style="margin-bottom: 10px"
            @click="allExportOrder"
          >{{ $t('newOrder.export') }}
          </el-button>
          <!--批量单号导出-->
          <el-button
            size="small"
            type="primary"
            @click="clickBtchExport"
          >{{ $t('placeOrder.BatchExport') }}
          </el-button>
          <el-radio-group
            v-model="queryForm.params.customerType"
            size="small"
            style="margin-left: 10px"
            @change="enterSearch"
          >
            <el-radio-button :label="1">{{ $t('newOrder.Formal') }}</el-radio-button>
            <el-radio-button :label="2">{{ $t('newOrder.test1') }}</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <!--自定义显示列-->
          <el-tooltip
            :content="$t('orderCenterCont.customizingDisplayColumns')"
            class="item"
            effect="dark"
            placement="top"
          >
            <el-button
              icon="el-icon-setting"
              style="font-size: 25px;color: #333;padding: 3px;"
              type="text"
              @click="customColumn()"
            />
          </el-tooltip>
        </el-form-item>
      </div>
    </el-form>
    <!--  表格数据  -->
    <el-table
      :key="tableKey"
      ref="timelinessWaybillTable"
      :data="tableData"
      border
      style="width: 100%"
    >
      <el-table-column
        v-for="(item, ind) in currentColumns"
        :key="ind"
        :label="item.label"
        :min-width="item.width"
        :prop="item.prop"
        :show-overflow-tooltip="!item.tips"
      >
        <template v-if="item.tips" slot="header">
          <div style="display: flex;align-items: center">
            <span style="margin-right: 2px">{{ item.label }}</span>
            <el-tooltip placement="top">
              <div slot="content">{{ item.tips }}</div>
              <svg-icon icon-class="QuestionMark" />
            </el-tooltip>
          </div>
        </template>
        <template slot-scope="scope">
          <div
            v-if="item.prop === 'waybillNumber'"
            style="color: #409eff;cursor: pointer"
            @click="toOrderDetail(scope.row)"
          >
            {{ scope.row.waybillNumber }}
          </div>
          <div v-else>
            {{ scope.row[item.prop] }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.number"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!--详情-->
    <el-drawer
      :append-to-body="true"
      :visible.sync="waybillDescription"
      custom-class="drawerClass"
      direction="ltr"
      size="90%"
    >
      <div style="height: calc(100vh - 67px); overflow: auto">
        <detailPage :key="detailKey" :query-track-param="queryTrackParam" query-type="1" />
      </div>
    </el-drawer>
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="true"
      :title="$t('placeOrder.BatchExport')"
      :visible.sync="dialogVisibleExport"
      top="10vh"
      width="500px"
      @close="clearForm"
    >
      <el-form
        ref="exportRuleForm"
        :model="exportRuleForm"
        class="demo-exportRuleForm"
        label-position="top"
      >
        <el-form-item
          :rules="{
            required: true,
            message: $t('placeOrder.pleaseInput'),
            trigger: ['blur', 'change']
          }"
          prop="NumberType"
        >
          <el-select v-model="exportRuleForm.NumberType" style="width: 80%">
            <!--运单号-->
            <el-option key="1" :label="$t('orderCenterCont.anjunWaybillNumber')" value="1" />
            <!--单号-->
            <el-option key="5" :label="$t('GlobalSetObj.ScanNumber')" value="5" />
          </el-select>
        </el-form-item>
        <el-form-item
          :rules="{
            required: true,
            message: $t('placeOrder.pleaseInput'),
            trigger: ['blur']
          }"
          prop="inputValeu"
          style="width: 100%"
        >
          <el-input
            v-model="exportRuleForm.inputValeu"
            :placeholder="$t('orderCenterCont.Multiple')"
            clearable
            rows="6"
            type="textarea"
          />
        </el-form-item>
      </el-form>
      <div style="text-align: right;margin: 15px 0;">
        <el-button size="small" @click="dialogVisibleExport = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button size="small" type="primary" @click="comfireBtchExport">{{ $t('collectionCenter.CheckItOutNow') }}
        </el-button>
      </div>
    </el-dialog>
    <!--自定义栏目-->
    <el-drawer
      :append-to-body="true"
      :title="$t('orderCenterCont.SelectColumn')"
      :visible.sync="showColumn"
      custom-class="drawerClass"
      direction="ltr"
      size="600px"
      @close="closeDrawer()"
    >
      <div class="columnTree" style="padding: 20px;height: calc(100vh - 140px);overflow: auto">
        <el-tree
          :key="dragging"
          ref="timelinessWaybillTree"
          :allow-drag="allowDrag"
          :allow-drop="allowDrop"
          :data="columnList"
          :default-checked-keys="checkedColumns"
          :props="props"
          check-on-click-node
          default-expand-all
          draggable
          node-key="prop"
          show-checkbox
          @check="handleCheckChange"
          @node-drop="handleDrop"
        >
          <template slot-scope="{ node, data }">
            <span v-if="data.tips">
              <span style="margin-right: 2px">{{ data.label }}</span>
              <el-tooltip placement="top">
                <div slot="content">{{ data.tips }}</div>
                <svg-icon icon-class="QuestionMark" />
              </el-tooltip>
            </span>
            <span v-else>{{ data.label }}</span>
          </template>
        </el-tree>
      </div>
      <div style="padding: 10px 20px;text-align: right;border-top: 1px solid #ccc;">
        <el-button type="primary" @click="determineColumns()">{{ $t('operationCenter.determine') }}</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { apiCustomerPage } from '@/api/customer';
import { getLgAreaListData, orderWaybillTimeRecord, orderWaybillTimeRecordExport } from '@/api/orderApi';
import detailPage from '@/components/detailComponent/detailPage.vue';
import { orderWaybillTimeRecordexportAll } from '@/api/logisticsOrders';
import dayjs from 'dayjs';
import { LOST_TYPE } from './utils';
import { cloneDeep } from 'lodash-es';
import { waybillStatusList, waybillStatusObj } from '@/utils/enumData';
import { siteFuzzyQuery } from '@/api/lanshou';

const PARAMS = {
  siteDataId: [],
  lostType: null, // 丢失类型
  inputValue: '',
  waybillStatus: [],
  customerIds: [],
  isTimeOut: -1,
  timeType: 1,
  providerType: 1,
  customerType: 1, // 1：正式2：测试
  stateType: 0, // 0 收件人州, 1 实际入库州
  stateList: []
};

export default {
  name: 'WaybillTimelinessStatistics',
  components: {
    detailPage
  },
  data() {
    return {
      // 详情
      waybillDescription: false,
      detailKey: new Date().getTime(),
      queryTrackParam: {},
      // 是否格式化成巴西时间
      isTormat: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      timeValue: [],
      queryForm: {
        pageSize: 10,
        number: 1,
        total: 0,
        params: cloneDeep(PARAMS)
        // params: {
        // siteDataId: [],
        // lostType: 0, // 丢失类型
        // inputValue: '',
        // waybillStatus: [],
        // customerIds: [],
        // isTimeOut: -1,
        // timeType: 1,
        // providerType: 1,
        // customerType: 1, // 1：正式2：测试
        // stateType: 0, // 0 收件人州, 1 实际入库州
        // stateList: []
        // }
      },
      siteData: [], // 网点
      tableData: [],
      // 勾选表格数据
      selectionList: [],
      searchNumberType: '1',
      searchNumber: '',
      customerList: [], // 客户列表
      isExport: false,
      exportType: 'CSV',
      exportParam: {},
      StateList: [], // 州
      selectDate: '',
      // 日期时间范围在一个月以内
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime();
          if (maxDate) {
            this.selectDate = '';
          }
        },
        disabledDate: (time) => {
          if (this.selectDate !== '') {
            const one = 30 * 24 * 3600 * 1000;
            const minTime = this.selectDate - one;
            const maxTime = this.selectDate + one;
            return time.getTime() < minTime || time.getTime() > maxTime;
          }
        }
      },
      dialogVisibleExport: false,
      exportRuleForm: {
        NumberType: '1',
        inputValeu: ''
      },
      showColumn: false,
      dragging: new Date().getTime(),
      props: {
        label: 'label',
        children: 'children'
      },
      checkedColumns: [],
      checkedSelectColumns: [],
      currentColumns: [],
      searchProp: [],
      tableKey: new Date().getTime(),
      waybillStatusList: waybillStatusList,
      waybillStatusObject: waybillStatusObj
    };
  },
  computed: {
    IsWidth() {
      return {
        width: this.isTormat ? '200px' : '140px'
      };
    },
    columnList() {
      return [
        {
          label: this.$i18n.t('basicData.all'), // '全部',
          prop: '',
          children: [
            // 运单号
            { prop: 'waybillNumber', label: this.$i18n.t('newOrder.waybillNumber'), width: 160 },
            // 扫描单号
            { prop: 'scanOrderNumber', label: this.$i18n.t('placeOrder.scanOrderNumber'), width: 160 },
            // 运单状态
            { prop: 'status', label: this.$i18n.t('newOrder.waybillStatus'), width: 200 },
            // 最新状态时间
            { prop: 'lastTrackTime', label: this.$i18n.t('orderCenterCont.latestStatusTime'), width: 180 },
            // 最新轨迹内容
            { prop: 'lastTrackContent', label: this.$i18n.t('orderCenterCont.zuixinguijineir'), width: 180 },
            // 丢失类型
            { prop: 'lostType', label: this.$i18n.t('newOrder.LostType'), width: 100 },
            // 收件人州
            { prop: 'receiveState', label: this.$i18n.t('newOrder.RecipientState'), width: 160 },
            // 收件人城市
            { prop: 'receiveCity', label: this.$i18n.t('newOrder.RecipientCity'), width: 160 },
            // 收件人区
            { prop: 'receiveArea', label: this.$i18n.t('newOrder.RecipientDistrict'), width: 160 },
            // 街道
            { prop: 'receiveStreet', label: this.$i18n.t('orderCenterCont.RecipientStreet'), width: 160 },
            // 收件人邮编
            { prop: 'receiveZipcode', label: this.$i18n.t('newOrder.RecipientZipCode'), width: 110 },
            // 区域
            { prop: 'region', label: this.$i18n.t('placeOrder.area'), width: 160 },
            // 城镇类型
            { prop: 'cluster', label: this.$i18n.t('placeOrder.cityType'), width: 160 },
            // 客户名称
            { prop: 'customerName', label: this.$i18n.t('collectionCenter.CustomerName'), width: 180 },
            // 商户名称
            { prop: 'merchantName', label: this.$i18n.t('collectionCenter.MerchantName'), width: 160 },
            // 预派送网点
            { prop: 'preDeliverStationName', label: this.$i18n.t('GlobalSetObj.preEntrega'), width: 160 },
            // 实际入库网点
            { prop: 'warehouseStationName', label: this.$i18n.t('GlobalSetObj.storageOutlets'), width: 160 },
            // 实际入库网点州
            { prop: 'warehouseState', label: this.$i18n.t('GlobalSetObj.storageOutletsState'), width: 190 },
            // 实际派送网点
            { prop: 'deliverStationName', label: this.$i18n.t('GlobalSetObj.entrega'), width: 160 },
            // 派送员
            { prop: 'delivererName', label: this.$i18n.t('orderCenterCont.deliveryPerson'), width: 160 },
            // 揽收类型
            { prop: 'collectTypeName', label: this.$i18n.t('newOrder.CollectionType'), width: 160 },
            // 订单创建时间
            { prop: 'createTime', label: this.$i18n.t('newOrder.orderCreateTime'), width: 160 },
            // 客户出仓时间
            { prop: 'customerSendTime', label: this.$i18n.t('newOrder.clientExitTime'), width: 160 },
            // 末端揽收时间
            { prop: 'collectScanTime', label: this.$i18n.t('newOrder.lanshouT0'), width: 180 },
            // 揽收时长(H)
            {
              prop: 'collectCreateDuration',
              label: this.$i18n.t('newOrder.TimeLanshou'),
              width: 180,
              tips: this.$i18n.t('placeOrder.tip3')
            },
            // 揽收封车时间
            { prop: 'collectSealVehicleTime', label: this.$i18n.t('GlobalSetObj.collectSealVehicleTime'), width: 180 },
            // HUB1解封车时间
            { prop: 'firstUnsealVehicleTime', label: this.$i18n.t('GlobalSetObj.firstUnsealVehicleTime'), width: 180 },
            // 运输时长(H)
            {
              prop: 'firstTransportDuration',
              label: this.$i18n.t('GlobalSetObj.transportDuration'),
              width: 180,
              tips: this.$i18n.t('GlobalSetObj.tip_firstTransportDuration')
            },
            // 一级分拨到件时间
            { prop: 'receiptTime', label: this.$i18n.t('GlobalSetObj.oneTransitInboundTime'), width: 180 },
            // 一级分拨发件时间
            { prop: 'sendTime', label: this.$i18n.t('GlobalSetObj.oneshipmentTime'), width: 180 },
            // 下一站
            { prop: 'firstTransferNextStation', label: this.$i18n.t('GlobalSetObj.nextStation'), width: 180 },
            // 一级分拨操作时长
            {
              prop: 'receiptSendDuration',
              label: this.$i18n.t('placeOrder.tip4'),
              width: 180,
              tips: this.$i18n.t('placeOrder.tip1')
            },
            // HUB1封车时间
            { prop: 'firstSealVehicleTime', label: this.$i18n.t('GlobalSetObj.firstSealVehicleTime'), width: 180 },
            // HUB2解封车时间
            { prop: 'secondUnsealVehicleTime', label: this.$i18n.t('GlobalSetObj.secondUnsealVehicleTime'), width: 180 },
            // 运输时长(H)
            {
              prop: 'secondTransportDuration',
              label: this.$i18n.t('GlobalSetObj.transportDuration'),
              width: 180,
              tips: this.$i18n.t('GlobalSetObj.tip_secondTransportDuration')
            },
            // 二级分拨到件时间
            { prop: 'transferInStorageTime', label: this.$i18n.t('GlobalSetObj.twoTransitInboundTime'), width: 180 },
            // 实际到件分拨中心
            { prop: 'transferSiteName', label: this.$i18n.t('placeOrder.shijicenter'), width: 180 },
            // 二级分拨发件时间
            { prop: 'transferOutStorageTime', label: this.$i18n.t('GlobalSetObj.twoshipmentTime'), width: 180 },
            // 下一站
            { prop: 'secondTransferNextStation', label: this.$i18n.t('GlobalSetObj.nextStation'), width: 180 },
            // 二级分拨操作时长
            {
              prop: 'transferInOutStorageDuration',
              label: this.$i18n.t('placeOrder.tip5'),
              width: 180,
              tips: this.$i18n.t('placeOrder.tip2')
            },
            // HUB2封车时间
            { prop: 'secondSealVehicleTime', label: this.$i18n.t('GlobalSetObj.secondSealVehicleTime'), width: 180 },
            // HUB3解封车时间
            { prop: 'thirdUnsealVehicleTime', label: this.$i18n.t('GlobalSetObj.thirdUnsealVehicleTime'), width: 180 },
            // 运输时长(H)
            {
              prop: 'thirdTransportDuration',
              label: this.$i18n.t('GlobalSetObj.transportDuration'),
              width: 180,
              tips: this.$i18n.t('GlobalSetObj.tip_thirdTransportDuration')
            },
            // 三级分拨到件时间
            {
              prop: 'thirdTransferInStorageTime',
              label: this.$i18n.t('GlobalSetObj.threeTransitInboundTime'),
              width: 180
            },
            // 实际到件分拨中心
            { prop: 'thirdTransferSite', label: this.$i18n.t('GlobalSetObj.ActualDistributionCenter'), width: 180 },
            // 三级分拨发件时间
            { prop: 'thirdTransferOutStorageTime', label: this.$i18n.t('GlobalSetObj.threeshipmentTime'), width: 180 },
            // 下一站
            { prop: 'thirdTransferNextStation', label: this.$i18n.t('GlobalSetObj.nextStation'), width: 180 },
            // 三级分拨操作时长
            {
              prop: 'thirdTransferDuration',
              label: this.$i18n.t('placeOrder.tip300'),
              width: 180,
              tips: this.$i18n.t('placeOrder.tip301')
            },
            // HUB3封车时间
            { prop: 'thirdSealVehicleTime', label: this.$i18n.t('GlobalSetObj.thirdSealVehicleTime'), width: 180 },
            // 网点解封车时间
            { prop: 'unsealVehicleTime', label: this.$i18n.t('GlobalSetObj.siteUnsealVehicleTime'), width: 180 },
            // 运输时长(H)
            {
              prop: 'transportDuration',
              label: this.$i18n.t('GlobalSetObj.transportDuration'),
              width: 180,
              tips: this.$i18n.t('GlobalSetObj.tip_transportDuration')
            },
            // 网点入库时间
            { prop: 'dispatchReceiptTime', label: this.$i18n.t('newOrder.StorageTimeOutlets'), width: 180 },
            // 网点派件时间
            { prop: 'lastDeliveryTime', label: this.$i18n.t('orderCenterCont.deliveryTime'), width: 180 },
            // 签收时间
            { prop: 'deliveredTime', label: this.$i18n.t('orderCenterCont.SigningTime'), width: 180 },
            // 签收时长(H)
            { prop: 'deliverDispatchReceiptDuration', label: this.$i18n.t('newOrder.DurationSigning'), width: 180 },
            // 首派时间
            {
              prop: 'startDeliveryTime',
              label: this.$i18n.t('orderCenterCont.FirstDeliveryTime'),
              width: 180,
              tips: this.$i18n.t('orderCenterCont.TimeoutDay1')
            },
            // 首派所用时长(H)
            {
              prop: 'startDeliveryDuration',
              label: this.$i18n.t('orderCenterCont.firstSendLong'),
              width: 180,
              tips: this.$i18n.t('orderCenterCont.TimeoutDay2')
            },
            // 客户时效要求天数
            { prop: 'customerAging', label: this.$i18n.t('orderCenterCont.customerTime'), width: 180 },
            // 理应送达时间
            { prop: 'customerAgingEndTime', label: this.$i18n.t('orderCenterCont.dueTime01'), width: 180 },
            // 运单耗时
            {
              prop: 'timeSpend',
              label: this.$i18n.t('orderCenterCont.TimeSpent'),
              width: 160,
              tips: this.$i18n.t('orderCenterCont.TimeoutDay3')
            },
            // 超时时长
            {
              prop: 'timeOutDuration',
              label: this.$i18n.t('orderCenterCont.TimeoutDuration'),
              width: 180,
              tips: this.$i18n.t('orderCenterCont.TimeoutDay4')
            },
            // 超时天数
            {
              prop: 'dayOutDuration',
              label: this.$i18n.t('orderCenterCont.TimeoutDay'),
              width: 180,
              tips: this.$i18n.t('orderCenterCont.TimeoutDay5')
            },
            // 问题件登记时间
            { prop: 'problemRecordTime', label: this.$i18n.t('orderCenterCont.problemTime'), width: 180 },
            // 问题件登记网点
            { prop: 'problemRecordSite', label: this.$i18n.t('orderCenterCont.problemSite'), width: 180 },
            // 问题件原因
            { prop: 'deliveryFailedReason', label: this.$i18n.t('GlobalSetObj.problemReason'), width: 180 }
          ]
        }
      ];
    }
  },
  created() {
    this.LOST_TYPE = LOST_TYPE;

    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getTimer();
    this.getAddress();
    this.getSupperSite('');
    this.getSearch(1);
    this.getHeaderColumn();
  },
  methods: {
    // 判断一个数组中是否包含另一个数组中的元素
    isArrayInclude(arr1, arr2) {
      return arr2.some(element => arr1.includes(element));
    },
    // 找出两个数组中相同的元素
    findCommonElements(arr1, arr2) {
      return arr1.filter((element) => arr2.includes(element));
    },
    // 找出两个数组中不相同的元素
    findDifferentElements(arr1, arr2) {
      // 找出arr1中不存在于arr2的元素
      const uniqueToArr1 = arr1.filter(element => !arr2.includes(element));
      // 找出arr2中不存在于arr1的元素
      const uniqueToArr2 = arr2.filter(element => !arr1.includes(element));
      // 将两个数组中的不同元素合并
      return [...uniqueToArr1, ...uniqueToArr2];
    },
    // 设置搜索类型默认值
    setDefaultData() {
      // 设置网点搜索类型默认值
      if (this.searchProp.includes('preDeliverStationName')) {
        // 如果列表展示了预派送网点，搜索类型值默认为预派送网点 1
        this.queryForm.params.providerType = 1;
      } else if (this.searchProp.includes('deliverStationName')) {
        // 如果列表没有展示预派送网点，展示了实际派送网点，搜索类型值默认为实际派送网点 2
        this.queryForm.params.providerType = 2;
      } else if (this.searchProp.includes('warehouseStationName')) {
        // 如果列表没有展示预派送网点和实际派送网点，只展示了实际入库网点，搜索类型值默认为实际入库网点 3
        this.queryForm.params.providerType = 3;
      } else {
        // 如果列表没有展示预派送网点和实际派送网点也没有实际入库网点，，搜索类型值默认为null
        this.queryForm.params.providerType = 1;
      }
      // 设置搜索州类型默认值
      if (this.searchProp.includes('receiveState')) {
        // 如果列表展示了收件人州，搜索类型值默认为收件人州 0
        this.queryForm.params.stateType = 0;
      } else if (this.searchProp.includes('warehouseState')) {
        // 否则默认为实际入库网点州 1
        this.queryForm.params.stateType = 1;
      } else {
        this.queryForm.params.stateType = 0;
      }
      // 设置搜索时间类型默认值
      if (this.searchProp.includes('createTime')) {
        // 如果列表展示了订单创建时间，搜索类型值默认为订单创建时间 1
        this.queryForm.params.timeType = 1;
      } else if (this.searchProp.includes('collectScanTime')) {
        // 默认为揽收时间 2
        this.queryForm.params.timeType = 2;
      } else if (this.searchProp.includes('receiptTime')) {
        // 一级分拨到件时间
        this.queryForm.params.timeType = 3;
      } else if (this.searchProp.includes('sendTime')) {
        // 一级分拨发件时间
        this.queryForm.params.timeType = 4;
      } else if (this.searchProp.includes('transferInStorageTime')) {
        // 二级分拨到件时间
        this.queryForm.params.timeType = 13;
      } else if (this.searchProp.includes('transferOutStorageTime')) {
        // 二级分拨发件时间
        this.queryForm.params.timeType = 14;
      } else if (this.searchProp.includes('dispatchReceiptTime')) {
        // 网点入库时间
        this.queryForm.params.timeType = 5;
      } else if (this.searchProp.includes('startDeliveryTime')) {
        // 首派时间
        this.queryForm.params.timeType = 8;
      } else if (this.searchProp.includes('lastDeliveryTime')) {
        // 网点派件时间
        this.queryForm.params.timeType = 9;
      } else if (this.searchProp.includes('deliveredTime')) {
        // 签收时间
        this.queryForm.params.timeType = 6;
      } else if (this.searchProp.includes('customerSendTime')) {
        // 客户出仓时间
        this.queryForm.params.timeType = 10;
      } else if (this.searchProp.includes('customerAgingEndTime')) {
        // 理应送达时间
        this.queryForm.params.timeType = 12;
      } else {
        this.queryForm.params.timeType = 1;
      }
    },
    // 获取当前用户自定义列表表头
    getHeaderColumn() {
      // 获取浏览器缓存
      const columnList = localStorage.getItem('timelinessWaybillColumnList');
      let selectedColumn = [];
      if (columnList) {
        selectedColumn = JSON.parse(columnList);
      } else {
        selectedColumn = [];
      }
      this.currentColumns = [];
      this.searchProp = [];
      const selectedProp = [];
      const allProp = [];
      console.log(selectedColumn, '===selectedColumn');
      // 获取已选中的栏目标识
      selectedColumn.map(list => {
        if (selectedProp.includes(list.prop) === false) {
          selectedProp.push(list.prop);
        }
      });
      console.log(this.columnList[0].children, '==this.columnList[0].children');
      // 获取全部标识
      this.columnList[0].children.map(item => {
        if (allProp.includes(item.prop) === false) {
          allProp.push(item.prop);
        }
      });
      const commonElements = this.findCommonElements(selectedProp, allProp);
      if (commonElements.length > 0) {
        commonElements.map(list => {
          this.columnList[0].children.map(item => {
            if (list === item.prop) {
              this.currentColumns.push(item);
              this.searchProp.push(item.prop);
            }
          });
        });
      } else {
        this.columnList[0].children.map(item => {
          if (selectedProp.includes(item.prop) === false) {
            selectedProp.push(item.prop);
          }
          this.currentColumns.push(item);
          this.searchProp.push(item.prop);
        });
      }
      // allColumn = cloneDeep(this.currentColumns);
      // const differentElements = this.findDifferentElements(allProp, selectedProp);
      let allNodes = JSON.parse(localStorage.getItem('timelinessWaybillAllNodes'));
      if (!allNodes) {
        allNodes = cloneDeep(allProp);
      }
      // 排序
      const orderMap = new Map(allNodes.map((prop, index) => [prop, index]));
      this.columnList[0].children.sort((a, b) => {
        return orderMap.get(a.prop) - orderMap.get(b.prop);
      });
      // 页面加载的时候设置table的高度
      this.$nextTick(() => {
        this.setDefaultData();
        this.columnList[0].children = cloneDeep(this.columnList[0].children);
        this.tableKey = new Date().getTime();
      });
    },
    // 打开自定义栏目弹窗
    customColumn() {
      const selectedColumn = cloneDeep(this.currentColumns);
      this.checkedColumns = [];
      this.searchProp = [];
      let allNodes = JSON.parse(localStorage.getItem('timelinessWaybillAllNodes'));
      if (!allNodes) {
        allNodes = this.columnList[0].children.map(item => {
          return item.prop;
        });
      }
      // 排序
      const orderMap = new Map(allNodes.map((prop, index) => [prop, index]));
      this.columnList[0].children.sort((a, b) => {
        return orderMap.get(a.prop) - orderMap.get(b.prop);
      });
      this.checkedSelectColumns = cloneDeep(this.columnList[0].children);
      this.checkedSelectColumns.map(item => {
        if (selectedColumn.length > 0) {
          selectedColumn.map(list => {
            if (item.prop === list.prop) {
              this.checkedColumns.push(item.prop);
              this.searchProp.push(item.prop);
            }
          });
        } else {
          this.checkedColumns.push(item.prop);
          this.searchProp.push(item.prop);
        }
      });
      this.showColumn = true;
      this.dragging = new Date().getTime();
    },
    // 确定保存已选栏目
    determineColumns() {
      const columns = [];
      const neKey = this.$refs.timelinessWaybillTree.getCheckedKeys();
      if (neKey.length > 0) {
        this.columnList[0].children.map(list => {
          if (neKey.includes(list.prop)) {
            columns.push(list);
          }
        });
      }
      if (columns.length === 0) {
        this.$message.warning(this.$t('receivablePayable.SelectAtLeastOnePieceOfData'));
        return;
      }
      const allNodes = this.getAllTreeNodes(this.columnList[0].children);
      localStorage.setItem('timelinessWaybillAllNodes', JSON.stringify(allNodes));
      localStorage.setItem('timelinessWaybillColumnList', JSON.stringify(columns));
      this.getHeaderColumn();
      this.showColumn = false;
      this.$nextTick(() => {
        this.queryForm.number = 1;
        this.getTimer();
        this.queryForm.params = cloneDeep(PARAMS);
        this.setDefaultData();
        this.$refs.timelinessWaybillTable.doLayout();
      });
    },
    // 获取栏目树上所有节点的值
    getAllTreeNodes(nodes) {
      let result = [];
      nodes.forEach(node => {
        result.push(node.prop);
        if (node.children && node.children.length > 0) {
          result = result.concat(this.getAllTreeNodes(node.children));
        }
      });
      return result;
    },
    // 关闭自定义栏目弹窗事件将未保存的数据还原
    closeDrawer() {
      this.columnList[0].children = this.checkedSelectColumns;
    },
    // 判断节点是否能被拖拽
    allowDrag(node) {
      return node.level === 2;
    },
    // 判断节点是否可放置
    allowDrop(draggingNode, dropNode, type) {
      if (dropNode.level === 2 && type !== 'inner') {
        return true;
      } else {
        return false;
      }
    },
    // 拖拽成功后触发事件
    handleDrop(draggedNode, insertNode, dropLocation, event) {
      this.dragging = new Date().getTime();
    },
    // 栏目勾选
    handleCheckChange(code, node) {
      this.checkedColumns = node.checkedKeys;
    },
    enterSearch() {
      this.queryForm.number = 1;
      this.getSearch();
    },
    // 重置
    resetForm() {
      this.queryForm.number = 1;
      this.getTimer();
      this.queryForm.params = cloneDeep(PARAMS);
      this.setDefaultData();
      this.getSearch();
    },
    // 批量导出
    clickBtchExport() {
      this.dialogVisibleExport = true;
    },
    // 确定批量导出
    comfireBtchExport() {
      this.$refs['exportRuleForm'].validate((valid) => {
        if (valid) {
          const numList = this.exportRuleForm.inputValeu ? this.exportRuleForm.inputValeu.split('\n') : [];
          const filteredArray = numList.filter(item => /\S/.test(item));
          if (filteredArray.length === 0) {
            this.$message.error(this.$t('GlobalSetObj.notMore10wTip'));
            return;
          }
          if (filteredArray.length > 100000) {
            this.$message.error(this.$t('GlobalSetObj.notMore10w'));
            return;
          }
          const data = {};
          if (this.exportRuleForm.NumberType === '1') {
            data.waybillNumberList = filteredArray;
            data.scanOrderNumberList = [];
          } else {
            data.waybillNumberList = [];
            data.scanOrderNumberList = filteredArray;
          }
          orderWaybillTimeRecordexportAll(data).then((res) => {
            if (res.status === 'OK') {
              this.dialogVisibleExport = false;
              const h = this.$createElement;
              this.$msgbox({
                title: this.$i18n.t('operationCenter.tips'),
                message: h('p', null, [
                  h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
                  h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
                ]),
                showCancelButton: true,
                confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
                cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
              })
                .then((action) => {
                  if (action === 'confirm') {
                    this.showExport = false;
                    if (res.data.taskId) {
                      this.$router.push({ path: '/resource/exportTaskList' });
                    } else {
                      this.$router.push({ path: '/resource/exportList' });
                    }
                  }
                })
                .catch(() => {
                });
            }
          });
        } else {
          return false;
        }
      });
    },
    // 导出
    allExportOrder() {
      // console.log(this.timeValue, 'timeValue');
      // let diff = 0;
      // if (this.timeValue && this.timeValue.length > 0) {
      //   diff = dayjs(this.timeValue[1]).diff(dayjs(this.timeValue[0]), 'day');
      // } else {
      //   this.$message.warning(this.$t('newOrder.timeIsNotNull'));
      //   return;
      // }
      // if (diff > 31) {
      //   this.$message.error('时间范围不能超过31天');
      //   return;
      // }
      const param = {
        ...this.exportParam.params,
        exportType: 'CSV'
      };
      if (param.waybillNumberList.length === 0) {
        if (this.timeValue && this.timeValue.length === 0) {
          this.$message.warning(this.$t('newOrder.timeIsNotNull'));
          return;
        }
      }
      orderWaybillTimeRecordExport(param).then((res) => {
        if (res.status === 'OK') {
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          })
            .then((action) => {
              if (action === 'confirm') {
                if (res.data.taskId) {
                  this.$router.push({ path: '/resource/exportTaskList' });
                } else {
                  this.$router.push({ path: '/resource/exportList' });
                }
              }
            })
            .catch(() => {
            });
        }
      });
    },
    // 查询
    getSearch(val) {
      let inputValList = [];
      if (this.queryForm.params.inputValue) {
        inputValList = this.queryForm.params.inputValue.split('\n');
      }
      if (inputValList.length > 2000) {
        this.$message.warning(this.$t('newOrder.searchMax1000'));
        inputValList = inputValList.slice(0, 2000); // 截取前2000个单号
      }
      // return;
      const data = {
        params: {}
      };
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.number;
      if (this.timeValue && this.timeValue.length > 0) {
        data.params.startTime = this.timeValue[0];
        data.params.endTime = this.timeValue[1];
      } else {
        data.params.startTime = '';
        data.params.endTime = '';
      }
      data.params.waybillNumberList = inputValList;
      data.params.timeType = this.queryForm.params.timeType;
      data.params.providerType = this.queryForm.params.providerType;
      data.params.customerIds = this.queryForm.params.customerIds;
      data.params.isTimeOut = this.queryForm.params.isTimeOut;
      // data.params.waybillStatus = this.queryForm.params.waybillStatus;
      let statusList = [];
      if (this.queryForm.params.waybillStatus.includes(3)) {
        statusList = cloneDeep(this.queryForm.params.waybillStatus);
        if (!statusList.includes(19)) {
          statusList.push(19);
        }
      } else {
        statusList = this.queryForm.params.waybillStatus;
      }
      data.params.waybillStatus = statusList;
      data.params.stateType = this.queryForm.params.stateType;
      data.params.stateList = this.queryForm.params.stateList;
      data.params.lostType = this.queryForm.params.lostType;
      // data.params.providerIdList = [];
      data.params.deliverStationIdList = [];
      data.params.customerType = this.queryForm.params.customerType;
      if (this.queryForm.params.siteDataId.length > 0) {
        data.params.deliverStationIdList = this.queryForm.params.siteDataId;
      }
      this.exportParam = data;
      if (!this.queryForm.params.inputValue) {
        if (!data.params.startTime && !data.params.endTime) {
          this.$message.warning(this.$t('newOrder.timeIsNotNull'));
          return;
        }
      }
      let loading = null;
      if (val === 1) {
        loading = this.$loading({
          fullscreen: true,
          lock: true,
          spinner: 'el-icon-loading',
          text: 'loading...',
          background: 'rgba(250, 250, 250, 0.6)'
        });
      }
      orderWaybillTimeRecord(data).then(res => {
        if (res.status === 'OK') {
          this.queryForm.total = res.data.total;
          // 需要转换为巴西时间格式的字段名列表
          const propArr = [
            'lastTrackTime',
            'createTime',
            'customerSendTime',
            'collectScanTime',
            'receiptTime',
            'sendTime',
            'deliverUploadScanTime',
            'dispatchReceiptTime',
            'lastDeliveryTime',
            'deliveredTime',
            'startDeliveryTime',
            'customerAgingEndTime'
          ];
          if (this.$i18n.locale !== 'zh') {
            // 转换时间格式
            this.tableData = res.data.records.map((item) => {
              for (const key in item) {
                if (propArr.includes(key)) {
                  item[key] = item[key] ? dayjs(item[key]).format('DD-MM-YYYY HH:mm:ss') : item[key];
                }
              }
              return item;
            });
          } else {
            this.tableData = res.data.records;
          }
          this.exportParam = data;
          this.tableData.map(item => {
            item.supplierName = item.providerChannelName;
            // if (this.waybillStatusObject[item.waybillStatus].label) {
            //   item.statusNamewaybill = this.waybillStatusObject[item.waybillStatus].label;
            // }
          });
          if (val === 1) {
            loading.close();
          }
        }
      }).catch(() => {
        if (val === 1) {
          loading.close();
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.number = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.number = val;
      this.getSearch();
    },
    // 获取最近一星期时间
    getTimer() {
      const day = new Date();
      const brazilTime = day.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
      const today = dayjs(brazilTime).format('YYYY-MM-DD');
      // const oneWeekAgo = dayjs(brazilTime).subtract(1, 'week').format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
    },
    // 切换网点
    hanleSiteId(val) {
      this.queryForm.params.siteDataId = val;
    },
    /**
     * @description 分页获取客户列表
     * @param { string } name 客户名称
     */
    getCustomerPage(name = '') {
      const param = {
        pageSize: 10,
        pageNumber: 1,
        params: {
          quickSearch: name
        }
      };
      apiCustomerPage(param).then((res) => {
        if (res.status === 'OK') {
          this.customerList = res.data.records;
        }
      });
    },
    // 获取对应的供应商下的网点
    getSupperSite(val) {
      const value = val || '';
      siteFuzzyQuery(value).then(res => {
        if (res.status === 'OK') {
          this.siteData = res.data;
        }
      });
    },
    changeInput(val) {
      this.$emit('input', val);
    },
    // 改变时间
    handleTime() {
      // let diff = 0;
      // if (this.timeValue && this.timeValue.length > 0) {
      //   diff = dayjs(this.timeValue[1]).diff(dayjs(this.timeValue[0]), 'day');
      // }
      // if (diff > 31) {
      //   this.$message.error('时间范围不能超过31天');
      //   return;
      // }
      this.queryForm.number = 1;
      // this.getSearch();
    },
    clearForm() {
      this.exportRuleForm.NumberType = '1';
      this.exportRuleForm.inputValeu = '';
    },
    selectStatus(val) {
      if (val.indexOf(0) !== -1) {
        this.queryForm.params.waybillStatus = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];
      }
    },
    // 进入运单详情
    toOrderDetail(row) {
      this.queryTrackParam = row;
      this.waybillDescription = true;
      this.detailKey = new Date().getTime();
      setTimeout(() => {
        this.bus.$emit('todetailBox', row.waybillId);
      }, 100);
    },
    // 州
    getAddress() { // level 0 州    1 城市   2 地区
      const params = {
        level: 0,
        parentId: ''
      };
      getLgAreaListData(params, false).then((res) => {
        if (res.status === 'OK') {
          this.StateList = res.data.map(item => {
            return {
              id: item.id,
              label: `${item.nameEn}`
            };
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.app-container >>> .el-dialog__body {
  padding: 10px 20px;
}

.app-container >>> .drawerClass header {
  border-bottom: 1px solid #ccc !important;
  margin-bottom: 0 !important;
  padding: 20px 20px 20px !important;
}

.app-container >>> .el-form--label-top .el-form-item__label {
  width: 100% !important;
}

.app-container >>> .selectLabel .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

.app-container >>> .selectLabel .el-input__icon {
  line-height: 28px !important;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type='number'] {
  -moz-appearance: textfield !important;
}
</style>
