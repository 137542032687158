<template> <!-- 收件人信息 -->
  <div>
    <!-- 查看 -->
    <SenderReadOnlyCmp v-if="disabled" :rule-form="ruleForm" />
    <!-- 编辑 -->
    <el-form
      v-else
      ref="ruleForm" :model="ruleForm" :rules="rules"
      class="demo-ruleForm" label-position="top"
      :disabled="disabled"
    >
      <el-row :gutter="20">
        <el-col :span="8">
          <!-- 姓名 -->
          <el-form-item :label="$t('newOrder.SenderName')" :rules="rules.senderName" prop="senderName">
            <el-input v-model="ruleForm.senderName" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 公司 -->
          <el-form-item :label="$t('newOrder.SenderCompany')">
            <el-input v-model="ruleForm.senderCompany" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 手机 -->
          <el-form-item :label="$t('newOrder.senderPhone')" :rules="rules.senderName" prop="senderMobile">
            <el-input v-model="ruleForm.senderMobile" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 街道 -->
          <el-form-item :label="$t('driver.modalStreet')">
            <el-input v-model="ruleForm.senderStreet" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 门牌号 -->
          <el-form-item :label="$t('newOrder.SenderNumber')">
            <el-input v-model="ruleForm.senderHouseNumber" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 区 -->
          <el-form-item :label="$t('newOrder.SenderDistrict')">
            <el-input v-model="ruleForm.senderArea" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 城市 -->
          <el-form-item :label="$t('newOrder.SenderCity')" :rules="rules.senderName" prop="senderCity">
            <el-input v-model="ruleForm.senderCity" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 州 -->
          <el-form-item :label="$t('newOrder.SenderState')" :rules="rules.senderName" prop="senderState">
            <el-input v-model="ruleForm.senderState" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 邮编 -->
          <el-form-item :label="$t('newOrder.SenderZipCode')" :rules="rules.senderName" prop="senderZipcode">
            <el-input v-model="ruleForm.senderZipcode" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 地址补充 -->
          <el-form-item :label="$t('GlobalSetObj.sendderComplement')">
            <el-input
              v-model="ruleForm.senderComplement" :autosize="{ minRows: 2, maxRows: 4}"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              type="textarea"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 税号 -->
          <el-form-item :label="$t('newOrder.SenderCPF')" :rules="rules.senderName" prop="senderTax">
            <el-input v-model="ruleForm.senderTax" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 州注册号 -->
          <el-form-item :label="$t('navbar.senderIe')">
            <el-input v-model="ruleForm.senderIeNumber" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <!-- 地址 -->
          <el-form-item :label="$t('newOrder.SendingAddress')" :rules="rules.senderName" prop="senderAddress">
            <el-input
              v-model="ruleForm.senderAddress" :autosize="{ minRows: 2, maxRows: 4}"
              :placeholder="$t('GlobalSetObj.pleaseInput')" type="textarea"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 电话 -->
          <el-form-item :label="$t('newOrder.SenderPhone')">
            <el-input v-model="ruleForm.senderPhone" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 邮箱 -->
          <el-form-item :label="$t('newOrder.SenderEmail')">
            <el-input v-model="ruleForm.senderMail" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import SenderReadOnlyCmp from './SenderReadOnlyCmp.vue';

export default {
  name: '',

  components: {
    SenderReadOnlyCmp
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    // 姓名*，电话*，税号*，省份*，城市*，区*，街道，门牌号*，收发件人地址*，邮编*，收发邮箱
    return {
      ruleForm: {
        senderName: '', // 发件人姓名
        senderCompany: '', // 发件人公司
        senderMobile: '', // 发件人手机
        senderStreet: '', // 发件人街道
        senderHouseNumber: '', // 发件人门牌号
        senderArea: '', // 发件人区
        senderCity: '', // 发件人城市
        senderState: '', // 发件人州
        senderZipcode: '', // 发件人邮编
        senderComplement: '', // 地址补充
        senderTax: '', // 发件人税号
        senderIeNumber: '', // 州注册号
        senderAddress: '', // 发件人地址
        senderPhone: '', // 发件人电话
        senderMail: '' // 发件人邮箱
      },

      rules: {
        senderName: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'blur' }
        ]
      }
    };
  },

  created() {

  },

  methods: {
    getData() {
      return this.ruleForm;
    },
    setData(row) {
      Object.assign(this.ruleForm, { ...row });
      // const { senderName, senderCompany, senderMobile, senderStreet, senderHouseNumber, senderArea, senderCity, senderState, senderZipcode, senderComplement, senderTax, senderIeNumber, senderAddress, senderPhone, senderMail } = row;

      // Object.assign(this.ruleForm, {
      //   senderName,
      //   senderCompany,
      //   senderMobile,
      //   senderStreet,
      //   senderHouseNumber,
      //   senderArea,
      //   senderCity,
      //   senderState,
      //   senderZipcode,
      //   senderComplement,
      //   senderTax,
      //   senderIeNumber,
      //   senderAddress,
      //   senderPhone,
      //   senderMail
      // });
    },

    // 提交
    submit() {
      let isBool = false;
      this.$refs['ruleForm'].validate((valid) => {
        if (!valid) {
          // alert('submit!');

        } else {
          isBool = true;
          return false;
        }
      });

      return isBool;
    }

  }

};
</script>

<style lang="scss" scoped>
</style>
