var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DropdownTable",
        [
          _c(
            "o-dropdown-item",
            {
              attrs: { "aria-role": "listitem" },
              on: { click: _vm.handleVisibilityModalEdit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("routes.edit")) + " ")]
          ),
          _c(
            "o-dropdown-item",
            {
              attrs: { "aria-role": "listitem" },
              on: { click: _vm.handleVisibilityModalShow },
            },
            [_vm._v(" " + _vm._s(_vm.$t("routes.seeSellers")) + " ")]
          ),
          _c(
            "o-dropdown-item",
            {
              attrs: { "aria-role": "listitem" },
              on: { click: _vm.handleVisibilityEquipment },
            },
            [_vm._v(" " + _vm._s(_vm.$t("routes.equipment")) + " ")]
          ),
          _c(
            "o-dropdown-item",
            {
              attrs: { "aria-role": "listitem" },
              on: { click: _vm.handleVisibilityOrder },
            },
            [_vm._v(" " + _vm._s(_vm.$t("routes.generateOrder")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "o-modal",
        {
          attrs: { active: _vm.isOpenModalEdit, width: "560" },
          on: { close: _vm.handleVisibilityModalEdit },
        },
        [
          _c("div", [
            _c("h2", { staticClass: "header-2" }, [
              _vm._v(_vm._s(_vm.$t("routes.editTitle"))),
            ]),
            _c("p", { staticClass: "paragraph" }, [
              _vm._v(_vm._s(_vm.$t("routes.editParagraph"))),
            ]),
          ]),
          _c("FormEditRoute", {
            attrs: {
              route: _vm.row,
              "close-modal": _vm.handleVisibilityModalEdit,
            },
          }),
        ],
        1
      ),
      _c(
        "o-modal",
        {
          attrs: { active: _vm.isOpenModalShow, width: "560" },
          on: { close: _vm.handleVisibilityModalShow },
        },
        [
          _c("div", [
            _c("h2", { staticClass: "header-2" }, [
              _vm._v(_vm._s(_vm.$t("routes.sellerTitle"))),
            ]),
            _c("p", { staticClass: "paragraph" }, [
              _vm._v(_vm._s(_vm.$t("routes.sellerParagraph"))),
            ]),
          ]),
          _c(
            "ul",
            { staticClass: "list-seller" },
            _vm._l(_vm.row.rm_route_collect.rm_seller_route, function (seller) {
              return _c("li", { key: seller.seller_code }, [
                _vm._v(
                  " " +
                    _vm._s(seller.seller_code + " - " + seller.seller_name) +
                    " "
                ),
              ])
            }),
            0
          ),
        ]
      ),
      _c(
        "o-modal",
        {
          attrs: { active: _vm.isOpenModalEquipment, width: "600" },
          on: { close: _vm.handleVisibilityEquipment },
        },
        [
          _c("div", [
            _c("h2", { staticClass: "header-2" }, [
              _vm._v(_vm._s(_vm.$t("routes.equipmentTitle"))),
            ]),
            _c("p", { staticClass: "paragraph" }, [
              _vm._v(_vm._s(_vm.$t("routes.equipmentParagraph"))),
            ]),
          ]),
          _c("FormEquipment", {
            attrs: {
              "close-modal": _vm.handleVisibilityEquipment,
              "route-id": _vm.row.route_id,
            },
          }),
        ],
        1
      ),
      _c(
        "o-modal",
        {
          attrs: { active: _vm.isOpenModalOrder, width: "600" },
          on: { close: _vm.handleVisibilityOrder },
        },
        [
          _c("div", [
            _c("h2", { staticClass: "header-2" }, [
              _vm._v(_vm._s(_vm.$t("routes.orderTitle"))),
            ]),
            _c("p", { staticClass: "paragraph" }, [
              _vm._v(_vm._s(_vm.$t("routes.orderParagraph"))),
            ]),
          ]),
          _c("FormGenerateOrder", {
            attrs: {
              "close-modal": _vm.handleVisibilityOrder,
              "route-id": _vm.row.route_id,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }