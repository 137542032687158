var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-table" },
    [
      _c(
        "el-table",
        {
          ref: "multipleTable",
          staticClass: "elTable",
          staticStyle: { width: "100%" },
          attrs: {
            "cell-style": { "text-align": "center" },
            data: _vm.tableData,
            "header-cell-style": { "text-align": "center" },
            border: "",
            "row-key": "id",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _vm.isSelectBtn
            ? _c("el-table-column", {
                attrs: {
                  "reserve-selection": true,
                  align: "center",
                  type: "selection",
                  width: "50",
                },
              })
            : _vm._e(),
          _vm.isIndex
            ? _c("el-table-column", {
                attrs: {
                  label: _vm.$t("newOrder.SerialNumber"),
                  align: "center",
                  type: "index",
                  width: "70",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.$index + 1))])]
                      },
                    },
                  ],
                  null,
                  false,
                  4178052834
                ),
              })
            : _vm._e(),
          _vm._l(_vm.headerList, function (item) {
            return [
              item.prop === "UnloadingpointState"
                ? _c(
                    "el-table-column",
                    {
                      key: item.id,
                      attrs: { "min-width": "100", prop: "Mdfestate" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.UnloadingpointState || "- -"
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c(
                          "div",
                          { staticClass: "header-container" },
                          [
                            _c(
                              "span",
                              { staticStyle: { "margin-right": "10px" } },
                              [_vm._v(_vm._s(_vm.$t("GlobalSetObj.state")))]
                            ),
                            _c(
                              "el-tooltip",
                              { attrs: { placement: "top" } },
                              [
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("GlobalSetObj.MDFEuningDot")
                                      )
                                    ),
                                  ]
                                ),
                                _c("svg-icon", {
                                  attrs: { "icon-class": "QuestionMark" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              item.prop !== "UnloadingpointState"
                ? _c("el-table-column", {
                    key: item.id,
                    attrs: {
                      label: item.label,
                      "min-width": item.cloWidth,
                      prop: item.prop,
                      "show-overflow-tooltip": _vm.isTip,
                      "label-class-name": "DisabledSelection",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              item.prop === "waybillNumber" ||
                              item.prop === "customerNumber" ||
                              item.prop === "transitBagNumber" ||
                              item.prop === "routeNumber" ||
                              item.prop === "instoreBigBagNo" ||
                              item.prop === "logisticsNumber"
                                ? _c(
                                    "div",
                                    { class: [_vm.hide ? "multiline" : ""] },
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "top",
                                            "popper-class": "copy",
                                            trigger: "hover",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                color: "black",
                                                "font-size": "12px",
                                                padding: "2px",
                                              },
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.copy(
                                                    scope.row[item.prop]
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("orderDetails.copy")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              class:
                                                (item.prop ===
                                                  "waybillNumber" ||
                                                  item.prop ===
                                                    "logisticsNumber" ||
                                                  item.prop ===
                                                    "customerNumber" ||
                                                  item.prop ===
                                                    "instoreBigBagNo" ||
                                                  item.prop ===
                                                    "transitBagNumber" ||
                                                  item.prop ===
                                                    "routeNumber") &&
                                                item.clickClass === false
                                                  ? "cursorPoniter"
                                                  : "WaybillNumber",
                                              attrs: { slot: "reference" },
                                              on: {
                                                click: function (e) {
                                                  return _vm.$emit(
                                                    "to-OrderDetail",
                                                    scope.row
                                                  )
                                                },
                                              },
                                              slot: "reference",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    scope.row[item.prop]
                                                      ? "" +
                                                          scope.row[item.prop]
                                                      : "- -"
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : item.prop === "stateName"
                                ? _c(
                                    "div",
                                    { class: [_vm.hide ? "multiline" : ""] },
                                    [
                                      _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            type:
                                              scope.row.state == 0
                                                ? "danger"
                                                : "success",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s("已" + scope.row[item.prop])
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : item.prop === "isStop"
                                ? _c(
                                    "div",
                                    { class: [_vm.hide ? "multiline" : ""] },
                                    [
                                      scope.row.isStop === 0
                                        ? _c(
                                            "el-tag",
                                            { attrs: { type: "danger" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "ChannelManage.Disable"
                                                  )
                                                )
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "el-tag",
                                            { attrs: { type: "primary" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("ChannelManage.enable")
                                                )
                                              ),
                                            ]
                                          ),
                                    ],
                                    1
                                  )
                                : item.prop === "waybillRemark"
                                ? _c("div", { staticClass: "multiline" }, [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(scope.row[item.prop] || "- -")
                                      ),
                                    ]),
                                  ])
                                : item.prop === "objSize"
                                ? _c("div", [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("operationCenter.length")
                                        ) +
                                          ": " +
                                          _vm._s(
                                            scope.row.objSize.length || "- -"
                                          )
                                      ),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("operationCenter.width")
                                        ) +
                                          ": " +
                                          _vm._s(
                                            scope.row.objSize.width || "- -"
                                          )
                                      ),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("operationCenter.height")
                                        ) +
                                          ": " +
                                          _vm._s(
                                            scope.row.objSize.height || "- -"
                                          )
                                      ),
                                    ]),
                                  ])
                                : item.prop === "receiveAddress"
                                ? _c("div", [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(_vm.$t("placeOrder.State")) +
                                          ": " +
                                          _vm._s(
                                            scope.row.receiveAddress
                                              .receiveState || "- -"
                                          )
                                      ),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(_vm.$t("newOrder.City")) +
                                          " " +
                                          _vm._s(
                                            scope.row.receiveAddress
                                              .receiveCity || "- -"
                                          )
                                      ),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(_vm.$t("orderDetails.Area")) +
                                          ": " +
                                          _vm._s(
                                            scope.row.receiveAddress
                                              .receiveArea || "- -"
                                          )
                                      ),
                                    ]),
                                  ])
                                : item.prop === "declared"
                                ? _c(
                                    "div",
                                    { class: [_vm.hide ? "multiline" : ""] },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "orderCenterCont.DeclareUnitPrice"
                                            )
                                          ) +
                                            ": " +
                                            _vm._s(
                                              scope.row.declared
                                                .declaredValue || "- -"
                                            )
                                        ),
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "orderCenterCont.DeclaredWeight"
                                            )
                                          ) +
                                            ": " +
                                            _vm._s(
                                              scope.row.declared
                                                .declaredWeight || "- -"
                                            )
                                        ),
                                      ]),
                                    ]
                                  )
                                : item.prop === "specialRuleFlag"
                                ? _c("div", [
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.specialRuleFlag
                                              ? _vm.$t("GlobalSetObj.Especial")
                                              : _vm.$t("GlobalSetObj.ordinary")
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ])
                                : item.prop === "name" ||
                                  item.prop === "providerChannelName" ||
                                  item.prop === "supplierCode"
                                ? _c(
                                    "div",
                                    { class: [_vm.hide ? "multiline" : ""] },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "systemName",
                                          on: {
                                            click: function (e) {
                                              return _vm.$emit(
                                                "to-SystemName",
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row[item.prop]
                                                  ? "" + scope.row[item.prop]
                                                  : "- -"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : item.prop === "createTime" ||
                                  item.prop === "updateTime" ||
                                  item.prop === "lastTrackTime" ||
                                  item.prop === "changeOrderTime" ||
                                  item.prop === "loadingTime" ||
                                  item.prop === "unloadingTime" ||
                                  item.prop === "packageTime" ||
                                  item.prop === "pushSucceedTime" ||
                                  item.prop === "receiptTime"
                                ? _c(
                                    "div",
                                    { class: [_vm.hide ? "multiline" : ""] },
                                    [
                                      _vm.formatTime
                                        ? _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  scope.row[item.prop]
                                                    ? "" +
                                                        _vm
                                                          .dayjs(
                                                            scope.row[item.prop]
                                                          )
                                                          .format(
                                                            "DD-MM-YYYY HH:mm:ss"
                                                          )
                                                    : "- -"
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                scope.row[item.prop]
                                                  ? "" + scope.row[item.prop]
                                                  : "- -"
                                              )
                                            ),
                                          ]),
                                    ]
                                  )
                                : item.prop === "date"
                                ? _c(
                                    "div",
                                    { class: [_vm.hide ? "multiline" : ""] },
                                    [
                                      _vm.formatTime
                                        ? _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  scope.row[item.prop]
                                                    ? "" +
                                                        _vm
                                                          .dayjs(
                                                            scope.row[item.prop]
                                                          )
                                                          .format("DD-MM-YYYY")
                                                    : "- -"
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                scope.row[item.prop]
                                                  ? "" + scope.row[item.prop]
                                                  : "- -"
                                              )
                                            ),
                                          ]),
                                    ]
                                  )
                                : item.prop === "changeLableStatus"
                                ? _c("div", [
                                    scope.row[item.prop]
                                      ? _c("div", [
                                          _vm._v(_vm._s(scope.row[item.prop])),
                                        ])
                                      : _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "GlobalSetObj.PendingNumber"
                                              )
                                            )
                                          ),
                                        ]),
                                  ])
                                : item.prop === "packageCount"
                                ? _c("div", [
                                    _c(
                                      "div",
                                      {
                                        class:
                                          item.prop === "packageCount" &&
                                          item.clickClass === false
                                            ? "cursorPoniter"
                                            : "WaybillNumber",
                                        on: {
                                          click: function () {
                                            return _vm.$emit(
                                              "packageList",
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(scope.row[item.prop]) + " "
                                        ),
                                      ]
                                    ),
                                  ])
                                : item.prop === "provideSiteName"
                                ? _c("div", [
                                    _c("div", [
                                      _vm._v(_vm._s(scope.row["providerName"])),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(">" + scope.row["siteName"])
                                      ),
                                    ]),
                                  ])
                                : item.prop === "minuta"
                                ? _c("div", [
                                    _c(
                                      "div",
                                      {
                                        class:
                                          item.prop === "minuta" &&
                                          item.clickClass === false
                                            ? "cursorPoniter"
                                            : "WaybillNumber",
                                        on: {
                                          click: function () {
                                            return _vm.$emit(
                                              "clickMinuta",
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            scope.row[item.prop]
                                              ? "" +
                                                  _vm.$t(
                                                    "GlobalSetObj.clickDown"
                                                  )
                                              : "" +
                                                  _vm.$t("GlobalSetObj.Rebuild")
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                  ])
                                : item.prop === "DeclareStatus"
                                ? _c("div", [
                                    scope.row.declareStatus == 4
                                      ? _c(
                                          "div",
                                          { staticStyle: { color: "#f56c6c" } },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.DeclareStatus) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : scope.row.declareStatus == 3
                                      ? _c(
                                          "div",
                                          { staticStyle: { color: "#ff7849" } },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.DeclareStatus) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c("div", [
                                          _vm._v(
                                            _vm._s(scope.row.DeclareStatus) +
                                              " "
                                          ),
                                        ]),
                                  ])
                                : item.clickText
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#409eff",
                                        cursor: "pointer",
                                      },
                                      on: {
                                        click: function (e) {
                                          return _vm.$emit(
                                            "on-click-text",
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(scope.row[item.prop])),
                                      ]),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    { class: [_vm.hide ? "multiline" : ""] },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          scope.row[item.prop]
                                            ? "" + scope.row[item.prop]
                                            : "- -"
                                        ) + " "
                                      ),
                                    ]
                                  ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                : _vm._e(),
            ]
          }),
          _vm.hasOperation
            ? _c("el-table-column", {
                attrs: {
                  label: _vm.$t("newOrder.operation"),
                  width: _vm.opWidth,
                  fixed: "right",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "operator-wrap" },
                            [
                              scope.row.checkTrajectory1
                                ? _c(
                                    "div",
                                    { staticClass: "checkTrajectoryBox" },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            content: _vm.$t(
                                              "orderCenterCont.TrajectoryQuery"
                                            ),
                                            effect: "dark",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c("div", {
                                            staticClass:
                                              "el-icon-location span",
                                            staticStyle: {
                                              "font-size": "20px",
                                            },
                                            on: {
                                              click: function (e) {
                                                return _vm.$emit(
                                                  "on-checkTrajectory",
                                                  scope.row
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              scope.row.checkTrajectory
                                ? _c(
                                    "div",
                                    { staticClass: "checkTrajectoryBox" },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            content:
                                              _vm.$t("newOrder.orderNote"),
                                            effect: "dark",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "el-icon-tickets span",
                                            on: {
                                              click: function (e) {
                                                return _vm.$emit(
                                                  "on-editWaybillRemark",
                                                  scope.row
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            content: _vm.$t(
                                              "orderCenterCont.TrajectoryQuery"
                                            ),
                                            effect: "dark",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c("div", {
                                            staticClass:
                                              "el-icon-location span",
                                            on: {
                                              click: function (e) {
                                                return _vm.$emit(
                                                  "on-checkTrajectory",
                                                  scope.row
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              scope.row.printBagNumber
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { color: "#409eff" },
                                      on: {
                                        click: function (e) {
                                          return _vm.$emit(
                                            "on-printBagNumber",
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            content: _vm.$t(
                                              "GlobalSetObj.PrintBagNumber"
                                            ),
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-right": "10px",
                                              },
                                              attrs: { type: "text" },
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "bgImgUrlPrintBag",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              scope.row.doPrint
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { color: "#409eff" },
                                      on: {
                                        click: function (e) {
                                          return _vm.$emit(
                                            "on-doPrint",
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("CenterForSorting.Print")
                                        ) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              scope.row.JdoPrint
                                ? _c(
                                    "div",
                                    { staticStyle: { color: "#409eff" } },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: scope.row.mdfeUrl,
                                            download: "",
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("GlobalSetObj.download")
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              scope.row.doDownloadMdfe
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { color: "#409eff" },
                                      on: {
                                        click: function (e) {
                                          return _vm.$emit(
                                            "on-doDownloadMdfe",
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("GlobalSetObj.Download")
                                          )
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              scope.row.completed
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { color: "#409eff" },
                                      on: {
                                        click: function (e) {
                                          return _vm.$emit(
                                            "on-doCompleted",
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("GlobalSetObj.Terminar")
                                        ) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              scope.row.rebuild
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { color: "#409eff" },
                                      on: {
                                        click: function (e) {
                                          return _vm.$emit(
                                            "on-rebuild",
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("GlobalSetObj.Rebuild")) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              scope.row.retry
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { color: "#409eff" },
                                      on: {
                                        click: function (e) {
                                          return _vm.$emit(
                                            "on-doRetry",
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s("重试") + " ")]
                                  )
                                : _vm._e(),
                              scope.row.doDownload
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { color: "#409eff" },
                                      on: {
                                        click: function (e) {
                                          return _vm.$emit(
                                            "on-doDownload",
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("GlobalSetObj.Download")
                                          )
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    content: _vm.$t("GlobalSetObj.View"),
                                    effect: "dark",
                                    placement: "top",
                                  },
                                },
                                [
                                  scope.row.look
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "buttonStyle",
                                          attrs: { size: "mini", type: "text" },
                                          on: {
                                            click: function (e) {
                                              return _vm.$emit(
                                                "on-look",
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "iconfont icon-a-ziyuan511",
                                            staticStyle: {
                                              "font-size": "15px",
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    content: _vm.$t("GlobalSetObj.edit"),
                                    effect: "dark",
                                    placement: "top",
                                  },
                                },
                                [
                                  scope.row.edit
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "buttonStyle",
                                          staticStyle: { "margin-left": "0" },
                                          attrs: { size: "mini", type: "text" },
                                          on: {
                                            click: function (e) {
                                              return _vm.$emit(
                                                "on-edit",
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-edit-outline",
                                            staticStyle: {
                                              "font-size": "25px",
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    content: _vm.$t("GlobalSetObj.Delete"),
                                    effect: "dark",
                                    placement: "top",
                                  },
                                },
                                [
                                  scope.row.del
                                    ? _c(
                                        "el-popconfirm",
                                        {
                                          attrs: {
                                            title: _vm.$t(
                                              "GlobalSetObj.AreYouSureDelete"
                                            ),
                                          },
                                          on: {
                                            confirm: function ($event) {
                                              return _vm.$emit(
                                                "on-del",
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "buttonStyleDe",
                                              attrs: {
                                                slot: "reference",
                                                size: "mini",
                                                type: "text",
                                              },
                                              slot: "reference",
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "iconfont icon-a-ziyuan54",
                                                staticStyle: {
                                                  "font-size": "22px",
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              scope.row.delStr
                                ? _c(
                                    "el-popconfirm",
                                    {
                                      attrs: {
                                        title: _vm.$t(
                                          "GlobalSetObj.AreYouSureDelete"
                                        ),
                                      },
                                      on: {
                                        confirm: function ($event) {
                                          return _vm.$emit(
                                            "on-delStr",
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            slot: "reference",
                                            underline: false,
                                            type: "danger",
                                          },
                                          slot: "reference",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("GlobalSetObj.MoveOut")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              scope.row.PrintList
                                ? _c(
                                    "div",
                                    { staticClass: "print" },
                                    [
                                      scope.row.declareStatus == 3
                                        ? _c(
                                            "el-popconfirm",
                                            {
                                              attrs: {
                                                title: _vm.$t(
                                                  "GlobalSetObj.sureToPrint"
                                                ),
                                                placement: "left-start",
                                                width: "250",
                                              },
                                              on: {
                                                confirm: function ($event) {
                                                  return _vm.$emit(
                                                    "on-PrintList",
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "reference" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#409eff",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.onPrintName
                                                            ? _vm.onPrintName
                                                            : _vm.$t(
                                                                "GlobalSetObj.PrintList"
                                                              )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            2
                                          )
                                        : scope.row.declareStatus == 4
                                        ? _c("span", {
                                            staticStyle: { color: "#409eff" },
                                          })
                                        : scope.row.declareStatus == 1
                                        ? _c("span")
                                        : _c(
                                            "span",
                                            {
                                              staticStyle: { color: "#409eff" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$emit(
                                                    "on-PrintList",
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.onPrintName
                                                    ? _vm.onPrintName
                                                    : _vm.$t(
                                                        "GlobalSetObj.PrintList"
                                                      )
                                                )
                                              ),
                                            ]
                                          ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              scope.row.elSwitchValue == 0
                                ? _c("el-switch", {
                                    attrs: {
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "active-color": "#13ce66",
                                      "inactive-color": "#ff4949",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$emit(
                                          "on-stop",
                                          scope.row.id,
                                          1
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.elSwitchValue,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "elSwitchValue",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.elSwitchValue",
                                    },
                                  })
                                : _vm._e(),
                              scope.row.elSwitchValue == 1
                                ? _c("el-switch", {
                                    attrs: {
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "active-color": "#13ce66",
                                      "inactive-color": "#ff4949",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$emit(
                                          "on-stop",
                                          scope.row.id,
                                          0
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.elSwitchValue,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "elSwitchValue",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.elSwitchValue",
                                    },
                                  })
                                : _vm._e(),
                              scope.row.isState == 0
                                ? _c(
                                    "el-popconfirm",
                                    {
                                      attrs: {
                                        title: _vm.$t(
                                          "basicData.AreYouSureEnabled"
                                        ),
                                      },
                                      on: {
                                        confirm: function ($event) {
                                          return _vm.$emit(
                                            "on-stop",
                                            scope.row.id,
                                            1
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            slot: "reference",
                                            size: "mini",
                                            type: "success",
                                          },
                                          slot: "reference",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("ChannelManage.enable")
                                            ) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              scope.row.isState == 1
                                ? _c(
                                    "el-popconfirm",
                                    {
                                      attrs: {
                                        title: _vm.$t(
                                          "ChannelManage.sureToStop"
                                        ),
                                      },
                                      on: {
                                        confirm: function ($event) {
                                          return _vm.$emit(
                                            "on-stop",
                                            scope.row.id,
                                            0
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            slot: "reference",
                                            size: "mini",
                                            type: "danger",
                                          },
                                          slot: "reference",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("ChannelManage.Disable")
                                            ) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              scope.row.editBtn
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        "margin-left": "0",
                                        color: "#666",
                                      },
                                      attrs: { size: "mini", type: "text" },
                                      on: {
                                        click: function (e) {
                                          return _vm.$emit(
                                            "on-editBtn",
                                            e,
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-edit-outline hoverClass",
                                        staticStyle: { "font-size": "25px" },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              scope.row.operation === 0
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "text" },
                                      on: {
                                        click: function () {
                                          return _vm.$emit(
                                            "clickInvoiceInfo",
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-edit-outline hoverClass",
                                        staticStyle: { "font-size": "25px" },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2408181757
                ),
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }