var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        _vm.gridData.length > 0
          ? _c("div", [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "infinite-scroll",
                      rawName: "v-infinite-scroll",
                      value: _vm.getUnreadMsgList,
                      expression: "getUnreadMsgList",
                    },
                  ],
                  staticClass: "msgContainer",
                },
                _vm._l(_vm.gridData, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "msgItemContainer",
                      on: {
                        click: function ($event) {
                          return _vm.checkMsg(item)
                        },
                      },
                    },
                    [
                      item.msgType !== 10
                        ? _c(
                            "div",
                            { staticStyle: { "padding-left": "50px" } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "msgTag",
                                  class:
                                    item.msgType === 0
                                      ? "msgTagDefault"
                                      : item.msgType === 1
                                      ? "msgTagInfo"
                                      : item.msgType === 2
                                      ? "msgTagDanger"
                                      : item.msgType === 3
                                      ? "msgTagWarning"
                                      : "msgTagSuccess",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm._f("msgType")(item.msgType)) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: item.title,
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "ellipsis",
                                          staticStyle: { margin: "15px 0" },
                                        },
                                        [_vm._v(_vm._s(item.title))]
                                      ),
                                    ]
                                  ),
                                  _c("p", [_vm._v(_vm._s(item.receiveTime))]),
                                ],
                                1
                              ),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticStyle: {
                                padding: "10px",
                                display: "flex",
                                "align-items": "center",
                              },
                            },
                            [
                              _c("div", { staticStyle: { flex: "1" } }, [
                                _c("span", [_vm._v(_vm._s(item.receiveTime))]),
                              ]),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "text-align": "center",
                                    flex: "1",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-right": "10px" } },
                                    [_vm._v(_vm._s(item.title))]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-right": "10px" } },
                                    [
                                      _vm._v(
                                        _vm._s(item.msgData.successCount) +
                                          _vm._s(_vm.$t("GlobalSetObj.succeed"))
                                      ),
                                    ]
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(item.msgData.failureCount) +
                                        _vm._s(_vm.$t("GlobalSetObj.Lose"))
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "text-align": "right",
                                    flex: "1",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    { attrs: { type: "text", size: "small" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "orderCenterCont.ViewTheImportResults"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                    ]
                  )
                }),
                0
              ),
            ])
          : _c("div", { staticStyle: { "text-align": "center" } }, [
              _vm._v(_vm._s(_vm.$t("GlobalSetObj.NoNewsYet"))),
            ]),
      ]),
      _vm.dialogTableVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm._f("msgType")(_vm.updateForm.msgType),
                visible: _vm.dialogTableVisible,
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                "show-close": false,
                width: "70%",
                "append-to-body": true,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogTableVisible = $event
                },
              },
            },
            [
              _c("messages-detail", {
                key: _vm.newKey,
                attrs: { "item-detail": _vm.updateForm },
              }),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogTableVisible = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.startUploadFile },
                    },
                    [_vm._v(_vm._s(_vm.$t("GlobalSetObj.confirm")))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showImportOrderReturn
        ? _c(
            "el-dialog",
            {
              attrs: {
                "append-to-body": true,
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                title: _vm.$t("orderCenterCont.ImportOrdersBulk"),
                visible: _vm.showImportOrderReturn,
                width: "400px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showImportOrderReturn = $event
                },
                close: _vm.cloceMessage,
              },
            },
            [
              _c("div", { key: _vm.showImportOrderReturn }, [
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("orderCenterCont.TheOrderImportComplete")) +
                      "!"
                  ),
                ]),
                _c("div", { staticStyle: { display: "flex" } }, [
                  _c("div", { staticStyle: { flex: "1" } }, [
                    _vm._v(
                      _vm._s(_vm.$t("GlobalSetObj.all")) +
                        ": " +
                        _vm._s(_vm.resultData.msgData.allData)
                    ),
                  ]),
                  _c("div", { staticStyle: { flex: "1" } }, [
                    _vm._v(
                      _vm._s(_vm.$t("orderCenterCont.ImportWasSuccessful")) +
                        ": " +
                        _vm._s(_vm.resultData.msgData.successCount)
                    ),
                  ]),
                  _c("div", { staticStyle: { flex: "1" } }, [
                    _vm._v(
                      _vm._s(_vm.$t("orderCenterCont.ImportFailed")) +
                        ": " +
                        _vm._s(_vm.resultData.msgData.failureCount)
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticStyle: { "margin-top": "40px" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.operationBtn()
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "orderCenterCont.DownloadImportFailureOrderDetails"
                            )
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }