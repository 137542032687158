import axios from 'axios';

export default function getMessageError(err) {
  if (axios.isAxiosError(err)) {
    if (err.response) {
      return Array.isArray(err.response.data.message) ? err.response.data.message[0] : err.response.data.message;
    } else if (!err.response) {
      return 'Erro de conexão, recarregue a página';
    }
  }

  return err.message;
}
