import request from '@/utils/request';
import qs from 'qs';

/**
 * 分页查询
 * @param query
 */
export const workOrdersPage = query => {
  return request.post('/workOrder/page', query);
};

/**
 * 添加工单
 * @param data
 * @returns
 */
export const workOrderAdd = data => {
  return request.post('/workOrder/add', data);
};

/**
 * 修改工单
 * @param data
 * @returns
 */
export const workOrderEdit = data => {
  return request.post('/workOrder/edit', data);
};

/**
 * 查询 我发布的 列表
 */
export const apiMyMissionList = data => {
  return request.post('/workOrder/page', data);
};

/**
 * 工单审核
 * 含评分
 */
export const apiWorkOrderAudit = data => {
  return request.post('/workOrder/auditWorkOrder', data);
};

/**
 * 添加子任务
 */
export const apiAddSubtasks = data => {
  return request.post('/workOrderTask/add', data);
};

/**
 * 编辑任务
 */
export const apiEditSubtasks = data => {
  return request.post('/workOrderTask/edit', data);
};

/**
 * 删除任务
 * @param {*} data
 * @returns
 */
export const apiDelSubtasks = data => {
  return request({
    url: '/workOrderTask/delete',
    method: 'post',
    params: { ...data }
  });
};

/**
 * 获取子任务列表
 */
export const apiGetSubtasksList = data => {
  return request.post('/workOrderTask/page', data);
};

/**
 * 开始任务
 */
export const apiSetStartTask = data => {
  return request({
    url: '/workOrderTask/begin',
    method: 'post',
    params: { ...data }
  });
};

/**
 * 结束任务
 */
export const apiSetEndTask = data => {
  return request({
    url: '/workOrderTask/finishTask',
    method: 'post',
    params: { ...data }
  });
};

/**
 * 操作日志
 */
export const apiGetOperationLog = data => {
  return request({
    url: '/workOrderOperationLog/list',
    method: 'post',
    params: { ...data }
  });
};

/**
 * 我的工单 + 我创建的
 * 获取对应状态的任务数
 */
export const apiGetOrdersStateAngle = data => {
  return request.post('/workOrder/getOrdersStateAngle', data);
};

/**
 * 获取评价列表
 */
export const apiGetCommentList = data => {
  return request({
    url: '/workOrderComment/list',
    method: 'post',
    params: { ...data }
  });
};

/**
 * 添加评价
 */
export const apiAddComment = data => {
  return request.post('/workOrderComment/add', data);
};

/**
 * 获取不同状态任务此用户角标
 */
export const apiGetTaskStateAngle = (data) => {
  return request.post('/workOrderTask/getTaskStateAngle', data);
};

/**
 * 任务驳回
 */
export const apiGetTaskCallback = (data) => {
  return request.post('/workOrderTask/taskCallback', data);
};

/**
 * 任务评价
 */
export const apisetTaskEvaluation = data => {
  return request.post('/workOrderTask/taskEvaluation', data);
};

/**
 * 工单评价
 */
export const apiSetOrderEvaluation = data => {
  return request.post('/workOrder/orderEvaluation', data);
};

/**
 * 工单标签新增
 * @param data
 * @returns {*}
 */
export const apiAddOrderTag = data => {
  return request.post('/workOrderTag/add', data);
};

/**
 * 工单标签修改
 * @param data
 * @returns {*}
 */
export const apiEditOrderTag = data => {
  return request.post('/workOrderTag/edit', data);
};

/**
 * 工单标签列表
 * @param data
 * @returns {*}
 */
export const apiListOrderTag = data => {
  return request.post('/workOrderTag/list', data);
};

/**
 * 工单标签列表分页
 * @param data
 * @returns {*}
 */
export const apiPageOrderTag = data => {
  return request.post('/workOrderTag/page', data);
};

/**
 * 修改协作部门
 */
export const apiEditCooperation = data => {
  return request({
    url: '/workOrder/editCooperation',
    method: 'post',
    params: { ...data }
  });
};
/**
 * 任务统计
 * @param data
 * @returns {*}
 */
export const taskDistribution = data => {
  return request.get('/workOrderStatistics/taskDistribution', { params: { ...data }});
};

/**
 * 工单统计
 * @param data
 * @returns {*}
 */
export const orderDistribution = data => {
  return request.get('/workOrderStatistics/orderDistribution', { params: { ...data }});
};

/**
 * 导出工单数据
 */
export const apiWorkOrderExport = data => {
  return request.post('/workOrder/export', data);
};

// 微工单类型添加
export const apiWorkMicroTicketTypeAdd = data => {
  return request.post('/workMicroTicketType/add', data);
};

// 微工单类型 状态 更新
export const apiWorkMicroTicketTypeEdit = data => {
  return request.post('/workMicroTicketType/edit', data);
};

// 微工单类型分页
export const apiWorkMicroTicketTypePage = (data, isBool = true) => {
  // return request.post('/hr/workMicroTicketType/page', data);
  return request({
    method: 'post',
    url: '/workMicroTicketType/page',
    data,
    isLoading: isBool
  });
};

// 微工单列表
export const apiWorkMicroTicketTypeGetAll = (data) => {
  return request.post('/workMicroTicketType/getAll', data);
};

//  微工单新增
export const apiWorkMicroTicketAdd = data => {
  return request.post('/workMicroTicket', data);
};

//  微工单分页
export const apiWorkMicroTicketPage = data => {
  return request.post('/workMicroTicket/page', data);
};

// 增加跟进信息
export const apiWorkMicroTicketFollowUpInfoAdd = data => {
  // return request.post('/workMicroTicketFollowUpInfo', qs.stringify(data));
  return request.post('/workMicroTicketFollowUpInfo', data);
};

// 获取跟进信息
export const apiWorkMicroTicketFollowUpInfoGetFollowUp = id => {
  return request.post(`/workMicroTicketFollowUpInfo/getFollowUp/${id}`);
};

// 更新微工单状态
export const apiWorkMicroTicketUpdateStatus = data => {
  return request.post('/workMicroTicket/updateStatus', qs.stringify(data));
};

