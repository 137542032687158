<template>
  <div class="app-container">
    <div ref="searchForm" v-resize="handleResize" style="margin-bottom: 10px;">
      <el-card>
        <el-form
          ref="form" :model="queryForm" label-width="auto"
          :label-position="'top'" size="small"
        >
          <el-row :gutter="10">
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <!--账单日期  :range-separator="$t('operationCenter.to')"-->
                <el-form-item :label="$t('receivablePayable.BillingDate')">
                  <el-date-picker
                    v-model="queryForm.params.payableTimes"
                    style="width: 100%"
                    range-separator="-"
                    type="daterange"
                    :start-placeholder="$t('operationCenter.startDate')"
                    :end-placeholder="$t('operationCenter.endDate')"
                    value-format="yyyy-MM-dd"
                  />
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <!--收款状态-->
                <el-form-item :label="$t('receivablePayable.ReceiptStatus')">
                  <el-select
                    v-model="queryForm.params.receiptStatus"
                    filterable
                    multiple
                    :placeholder="$t('operationCenter.PleaseSelect')"
                    clearable
                    style="width: 100%;"
                  >
                    <!--待核收-->
                    <el-option key="1" :label="$t('receivablePayable.ToBeVerified')" :value="0" />
                    <!--已核收-->
                    <el-option key="2" :label="$t('basicData.Received')" :value="1" />
                    <!--部分核收-->
                    <el-option key="3" :label="$t('receivablePayable.PartialResurvision')" :value="2" />
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <!--应收主体-->
                <el-form-item :label="$t('basicData.businessPrincipal')">
                  <el-select
                    v-model="queryForm.params.providerId"
                    filterable
                    :placeholder="$t('operationCenter.Only10AreDisplayed')"
                    :remote-method="getProviderPage"
                    remote
                    clearable
                    style="width: 100%;"
                    @focus="getProviderPage('')"
                  >
                    <el-option
                      v-for="item in providerList"
                      :key="item.id"
                      :label="item.providerName + '（' + item.code + '）'"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <!--客户-->
                <el-form-item :label="$t('GlobalSetObj.client')">
                  <CustomerSelectCmp style="width: 100%" @change="val => queryForm.params.customerId = val" />
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple selectInput">
                <!--账单批次号-->
                <el-form-item :label="$t('receivablePayable.BillingBatchNumber')" label-width="">
                  <template slot="label">
                    <el-select
                      v-model="typeNumber"
                      filterable
                      :placeholder="$t('operationCenter.PleaseSelect')"
                      clearable
                      style="width: 100%;"
                    >
                      <!--收款单号-->
                      <el-option key="0" :label="$t('basicData.ReceiptNumber')" :value="1" />
                      <!--账单批次号-->
                      <el-option key="1" :label="$t('receivablePayable.BillingBatchNumber')" :value="2" />
                    </el-select>
                  </template>
                  <InputTextareaCmp
                    ref="inputTextareaRef"
                    :val="searchNum"
                    :max-length="200"
                    @input="(_, val) => searchNum = val"
                  />
                  <!-- <el-input v-model="searchNum" clearable /> -->
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </el-form>

        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="searchResources()"
        >
          {{ $t('operationCenter.Procurar') }}
        </el-button>
        <!--重置-->
        <el-button
          icon="el-icon-refresh"
          size="small"
          @click="onReset"
        >{{ $t('basicData.reset') }}
        </el-button>
      </el-card>
    </div>

    <el-card>
      <div class="row">
        <div>
          <!--核收-->
          <el-button
            v-permit:remove="'btn:receivableManagementSite:billCollection:approved'"
            size="small"
            type="primary"
            @click="operationBtn(1)"
          >{{ $t('receivablePayable.receivables') }}</el-button>
          <!--自定义栏目-->
          <el-button
            style="margin-left: 10px;"
            size="small"
            type="primary"
            @click="customColumn()"
          >{{ $t('orderCenterCont.CustomizeListColumn') }}</el-button>
        </div>
        <div>
          <el-button
            v-permit:remove="'btn:receivableManagementSite:billCollection:exportDetail'"
            icon="el-icon-download"
            size="small"
            @click="onExport"
          >{{ $t('basicData.账单导出') }}</el-button>
          <!-- <el-button
            v-permit:remove="'btn:receivableManagementSite:billCollection:export'"
            icon="el-icon-download"
            size="small"
            @click="onExportDetail"
          >{{ $t('basicData.账单明细导出') }}</el-button> -->
        </div>
      </div>

      <div class="pending">
        <el-table
          ref="elTable"
          :key="tableKey"
          :data="tableData"
          border
          class="table-height"
          :height="tableHeightRow.height"
          row-key="idCopy"
          :tree-props="{ children: 'siteBill', hasChildren: true }"
          :row-class-name="rowClass"
          @header-dragend="headerWidthChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection" width="55" align="center"
            :selectable="selectable"
          />
          <!--收款批次号-->
          <el-table-column
            prop="receiptNumber"
            :label="$t('receivablePayable.ReceiptBatchNumber')"
            width="240"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.isParent === true" class="batchNumber" @click="showBillBatchNumber(scope.row)">{{ scope.row.receiptNumber }}</span>
              <span v-else>{{ scope.row.billBatchNumber }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(item, ind) in currentColumns"
            :key="ind"
            :prop="item.prop"
            :label="item.label"
            :align="item.align"
            :min-width="item.width"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div v-if="item.prop === 'receiptBillName'">
                <span v-if="scope.row.isParent === true">{{ scope.row.receiptBillName }}</span>
                <span v-else>{{ scope.row.billName }}</span>
              </div>
              <div v-else-if="item.prop === 'receiptStatus'">
                <!--待收款-->
                <span v-if="scope.row.receiptStatus === 0">{{ $t('receivablePayable.PendingPayment') }}</span>
                <!--已收款-->
                <span v-if="scope.row.receiptStatus === 1">{{ $t('receivablePayable.Received') }}</span>
                <!--部分收款-->
                <span v-if="scope.row.receiptStatus === 2">{{ $t('receivablePayable.PartialCollection') }}</span>
              </div>
              <div v-else-if="item.prop === 'billFee'">
                <span v-if="scope.row.isParent === true">{{ scope.row.billFee }}</span>
                <span v-else>{{ scope.row.totalReceivable }}</span>
              </div>
              <div v-else-if="item.prop === 'remark'">
                <div class="remarkStyle" @click="showImg($event)" v-html="scope.row.remark" />
              </div>
              <div v-else>{{ scope.row[item.prop] }}</div>
            </template>
          </el-table-column>
          <!--操作-->
          <el-table-column
            :label="$t('basicData.operate')"
            align="center"
            width="140"
            fixed="right"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.receiptStatus === 0">
                <el-button
                  v-permit:remove="'btn:receivableManagementSite:billCollection:recall'"
                  type="danger"
                  size="mini"
                  @click="recall(scope.row)"
                >{{ $t('receivablePayable.withdraw') }}</el-button> <!--撤回-->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页工具 -->
        <div class="pagination">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryForm.pageNumber"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size.sync="queryForm.pageSize"
            :total="total"
            background
            style="margin: 10px 0"
            @current-change="getExaminePage"
            @size-change="getExaminePage"
          />
        </div>
      </div>
    </el-card>

    <!--选择栏目-->
    <el-drawer
      :append-to-body="true"
      :visible.sync="showColumn"
      direction="ltr"
      custom-class="drawerClass"
      :title="$t('orderCenterCont.SelectColumn')"
      width="500px"
      @close="closeDrawer()"
    >
      <div style="padding: 20px;height: calc(100vh - 140px);overflow: auto" class="columnTree">
        <el-tree
          ref="elTree"
          :key="dragging"
          :data="columnList"
          :props="props"
          node-key="prop"
          default-expand-all
          check-on-click-node
          :default-checked-keys="checkedColumns"
          show-checkbox
          draggable
          :allow-drop="allowDrop"
          :allow-drag="allowDrag"
          @check="handleCheckChange"
          @node-drop="handleDrop"
        />
      </div>
      <div style="padding: 10px 20px;text-align: right;border-top: 1px solid #ccc;">
        <el-button type="primary" @click="determineColumns()">{{ $t('operationCenter.determine') }}</el-button>
      </div>
    </el-drawer>
    <!--  对账批次详情  -->
    <el-drawer
      :title="$t('receivablePayable.ReconcileBatchDetails') + '-' + batchNumber"
      :append-to-body="true"
      :visible.sync="showDetail"
      direction="ltr"
      custom-class="drawerClass"
      :label="$t('receivablePayable.ReconcileBatchDetails')"
      size="80%"
    >
      <div>
        <batch-number-detail
          :key="batchNumber"
          :batch-number="batchNumber"
          :bill-batch-data="billBatchData"
          @closeDetail="closeDetail"
        />
      </div>
    </el-drawer>
    <!--收款-->
    <el-dialog
      :title="$t('basicData.acceptanceCheck')"
      :visible.sync="showCollection"
      width="80%"
    >
      <div>
        <collection-detail
          :key="collectionKey"
          :recharge-data="selectData[0]"
          :account-option="accountOption"
          @collectionUpdate="collectionUpdate"
        />
      </div>
    </el-dialog>
    <div class="imgs">
      <el-image-viewer v-if="imageViewerObj.showViewer" :on-close="closeViewer" :url-list="imageViewerObj.imgArr" />
    </div>
  </div>
</template>

<script>
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import batchNumberDetail from './cmp/batchNumberDetail';
import { apiProviderPage, apiSiteReceiptGetPage, apiSiteReceiptExport, apiSiteReceiptExportDetailed } from '@/api/finance/basicData';
import { accountPage } from '@/api/finance/partition';
import {
  apiCustomColumnTemplateInsert,
  apiCustomColumnTemplateList,
  apiCustomColumnTemplateUpdate,
  apiSiteBillCancellationOfReceipt
} from '@/api/finance/receivables';
import CustomerSelectCmp from '@/components/CustomerSelectCmp/CustomerSelectCmp.vue';
import { cloneDeep } from 'lodash-es';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import { goExportList } from '@/utils/goExportList.js';
import { TABLE_HEADER } from './utils';
import InputTextareaCmp from '@/views/finance/cmp/Query/InputTextareaCmp.vue';

const PARAM = {
  receiptNumber: '', // 收款单号
  billBatchNumber: '', // 账单批次号
  providerId: '', // 业务主体
  receiptStatus: [], // 收款状态
  customerId: '', // 客户
  payableTimes: [] // 账单日期
};

export default {
  name: 'BillCollection',
  components: {
    batchNumberDetail,
    'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer'),
    'collection-detail': () => import('./cmp/collectionDetail'),
    CustomerSelectCmp,
    InputTextareaCmp
  },

  mixins: [estimateTableHeight],

  data() {
    return {
      queryForm: {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 10,
        params: {
          ...PARAM
          // receiptNumber: '', // 收款单号
          // billBatchNumber: '', // 账单批次号
          // providerId: '', // 业务主体
          // receiptStatus: [], // 收款状态
          // customerId: '' // 客户
        }
      },
      createUser: [],
      showDetail: false,
      tableData: [],
      total: 0,
      height: 100,
      tableKey: new Date().getTime(),
      batchNumber: '',
      billBatchData: {},
      providerList: [],
      showReceivableManage: false,
      selectData: [],
      rowSelectFlag: false,
      showCollection: false,
      collectionKey: new Date().getTime(),
      accountOption: [],
      imageViewerObj: {
        imgArr: [],
        showViewer: false
      },
      typeNumber: 1,
      searchNum: '',
      currentColumns: [],
      showColumn: false,
      dragging: new Date().getTime(),
      props: {
        label: 'label',
        children: 'children'
      },
      checkedColumns: [],
      checkedSelectColumns: [],
      customColumnId: ''
    };
  },
  computed: {
    columnList() {
      return [
        {
          label: this.$i18n.t('basicData.all'), // '全部',
          prop: '',
          children: [
            ...TABLE_HEADER
            // // 收款账单
            // { prop: 'receiptBillName', label: this.$i18n.t('receivablePayable.IncomingBills'), width: 160, align: 'left', highlight: false, disabled: false },
            // // 收款状态
            // { prop: 'receiptStatus', label: this.$i18n.t('receivablePayable.ReceiptStatus'), width: 160, align: 'left', highlight: false, disabled: false },
            // // 客户
            // { prop: 'customerName', label: this.$i18n.t('GlobalSetObj.client'), width: 160, align: 'left', highlight: false, disabled: false },
            // // 网点名称
            // { prop: 'siteName', label: this.$i18n.t('basicData.siteName'), width: 160, align: 'left', highlight: false, disabled: false },
            // // 订单数量
            // { prop: 'orderQty', label: this.$i18n.t('receivablePayable.TheNumberOfOrders'), width: 160, align: 'left', highlight: true, disabled: false },
            // // 费用条数
            // { prop: 'feeQty', label: this.$i18n.t('receivablePayable.TheNumberOfFees'), width: 160, align: 'left', highlight: false, disabled: false },
            // // 计费总重量（kg）
            // { prop: 'chargeWeight', label: this.$i18n.t('receivablePayable.TotalBillableWeight'), width: 160, align: 'left', highlight: false, disabled: false },
            // // 票单金额
            // { prop: 'billFee', label: this.$i18n.t('receivablePayable.TicketAmount'), width: 160, align: 'left', highlight: false, disabled: false },
            // // 实际收款金额
            // { prop: 'actualFee', label: this.$i18n.t('receivablePayable.TheActualAmountReceived'), width: 160, align: 'left', highlight: false, disabled: false },
            // // 核收人
            // { prop: 'acceptUserName', label: this.$i18n.t('receivablePayable.payee'), width: 160, align: 'left', highlight: false, disabled: false },
            // // 核收时间
            // { prop: 'acceptOperateTime', label: this.$i18n.t('receivablePayable.CollectionTime'), width: 160, align: 'left', highlight: false, disabled: false },
            // // 交易时间
            // { prop: 'acceptTime', label: this.$i18n.t('basicData.TradingHours'), width: 160, align: 'left', highlight: false, disabled: false },
            // // 生成人员
            // { prop: 'createUserName', label: this.$i18n.t('receivablePayable.BuildThePerson'), width: 160, align: 'left', highlight: false, disabled: false },
            // // 生成时间
            // { prop: 'createTime', label: this.$i18n.t('receivablePayable.BuildTime'), width: 160, align: 'left', highlight: false, disabled: false },
            // // 审核人员
            // { prop: 'auditUserName', label: this.$i18n.t('receivablePayable.Auditors'), width: 160, align: 'left', highlight: false, disabled: false },
            // // 审核时间
            // { prop: 'auditTimeString', label: this.$i18n.t('receivablePayable.ReviewTime'), width: 160, align: 'left', highlight: false, disabled: false },

            // // 审核备注
            // { prop: 'auditRemark', label: this.$i18n.t('basicData.审核备注'), width: 160, align: 'left', highlight: false, disabled: false },

            // // 备注
            // { prop: 'remark', label: this.$i18n.t('receivablePayable.remark'), width: 160, align: 'left', highlight: false, disabled: false }
          ]
        }
      ];
    }
  },
  created() {
    this.createUser = getUserIdAndNameObj();
    this.getHeaderColumn();
    this.getExaminePage();
    this.getAccountOption();
  },
  mounted() {
    // // 页面加载的时候设置table的高度
    // this.$nextTick(() => {
    //   this.height = this.tableHeight('searchForm');
    // });
    // // 页面大小该变的时候（缩放页面）设置table的高度（可加可不加）
    // window.onresize = () => {
    //   this.$nextTick(() => {
    //     this.height = this.tableHeight('searchForm');
    //   });
    // };
    this.bus.$on('pendingData', () => {
      this.getExaminePage();
    });
  },
  methods: {
    // 找出两个数组中相同的元素
    findCommonElements(arr1, arr2) {
      return arr1.filter((element) => arr2.includes(element));
    },
    // 找出两个数组中不相同的元素
    findDifferentElements(arr1, arr2) {
      // 找出arr1中不存在于arr2的元素
      const uniqueToArr1 = arr1.filter(element => !arr2.includes(element));
      // 找出arr2中不存在于arr1的元素
      const uniqueToArr2 = arr2.filter(element => !arr1.includes(element));
      // 将两个数组中的不同元素合并
      return [...uniqueToArr1, ...uniqueToArr2];
    },
    // 获取当前用户自定义列表表头
    getHeaderColumn() {
      const param = {
        type: 0,
        menuEnum: 'companyToSiteBillCollection'
      };
      apiCustomColumnTemplateList(param).then(res => {
        if (res.status === 'OK') {
          this.customColumnId = '';
          let selectedColumn = [];
          if (res.data.length > 0) {
            this.customColumnId = res.data[0].id;
            selectedColumn = JSON.parse(res.data[0].templateField);
          }
          this.currentColumns = [];
          let allColumn = [];
          const selectedProp = [];
          const allProp = [];
          selectedColumn.map(list => {
            if (selectedProp.includes(list.prop) === false) {
              selectedProp.push(list.prop);
            }
          });
          this.columnList[0].children.map(item => {
            if (allProp.includes(item.prop) === false) {
              allProp.push(item.prop);
            }
          });
          const commonElements = this.findCommonElements(selectedProp, allProp);
          if (commonElements.length > 0) {
            commonElements.map(list => {
              this.columnList[0].children.map(item => {
                if (list === item.prop) {
                  this.currentColumns.push(item);
                }
              });
            });
          } else {
            this.columnList[0].children.map(item => {
              if (selectedProp.includes(item.prop) === false) {
                selectedProp.push(item.prop);
              }
              this.currentColumns.push(item);
              // if (item.disabled) {
              //   if (selectedProp.includes(item.prop) === false) {
              //     selectedProp.push(item.prop);
              //   }
              //   this.currentColumns.push(item);
              // }
            });
          }
          allColumn = cloneDeep(this.currentColumns);
          const differentElements = this.findDifferentElements(allProp, selectedProp);
          this.columnList[0].children.map(item => {
            if (differentElements.includes(item.prop)) {
              allColumn.push(item);
            }
          });
          // 页面加载的时候设置table的高度
          this.$nextTick(() => {
            this.columnList[0].children = cloneDeep(allColumn);
            this.tableKey = new Date().getTime();
          });
        }
      });
    },
    // 打开自定义栏目弹窗
    customColumn() {
      const selectedColumn = cloneDeep(this.currentColumns);
      this.checkedColumns = [];
      this.checkedSelectColumns = cloneDeep(this.columnList[0].children);
      this.checkedSelectColumns.map(item => {
        if (selectedColumn.length > 0) {
          selectedColumn.map(list => {
            if (item.prop === list.prop) {
              this.checkedColumns.push(item.prop);
            }
          });
        } else {
          this.checkedColumns.push(item.prop);
          // if (item.disabled === true) {
          //   this.checkedColumns.push(item.prop);
          // }
        }
      });
      this.showColumn = true;
      this.dragging = new Date().getTime();
    },
    // 确定保存已选栏目
    determineColumns() {
      const columns = [];
      const neKey = this.$refs.elTree.getCheckedKeys();
      if (neKey.length > 0) {
        this.columnList[0].children.map(list => {
          if (neKey.includes(list.prop)) {
            columns.push(list);
          }
        });
      }
      const params = {
        templateField: JSON.stringify(columns),
        templateName: '公司对网点账单核收',
        type: 0,
        menuEnum: 'companyToSiteBillCollection',
        userId: this.$store.state.user.id
      };
      if (this.customColumnId) {
        params.id = this.customColumnId;
        apiCustomColumnTemplateUpdate(params).then(res => {
          if (res.status === 'OK') {
            this.$message.success(this.$t('basicData.TheOperationSucceeded'));
            this.getHeaderColumn();
          }
        });
      } else {
        apiCustomColumnTemplateInsert(params).then(res => {
          if (res.status === 'OK') {
            this.$message.success(this.$t('basicData.TheOperationSucceeded'));
            this.getHeaderColumn();
          }
        });
      }
      this.showColumn = false;
      this.$nextTick(() => {
        this.$refs.elTable.doLayout();
        this.tableData.map(list => {
          const matchedIndex = this.selectData.findIndex(itemList => itemList.id === list.id);
          this.$refs.elTable.toggleRowSelection(list, matchedIndex !== -1);
        });
      });
    },
    // 关闭自定义栏目弹窗事件将未保存的数据还原
    closeDrawer() {
      this.columnList[0].children = this.checkedSelectColumns;
    },
    // 判断节点是否能被拖拽
    allowDrag(node) {
      return node.level === 2;
    },
    // 判断节点是否可放置
    allowDrop(draggingNode, dropNode, type) {
      if (dropNode.level === 2 && type !== 'inner') {
        return true;
      } else {
        return false;
      }
    },
    // 拖拽成功后触发事件
    handleDrop(draggedNode, insertNode, dropLocation, event) {
      this.dragging = new Date().getTime();
    },
    // 栏目勾选
    handleCheckChange(code, node) {
      this.checkedColumns = node.checkedKeys;
    },
    // 图片点击放大
    showImg(e) {
      if (e.target.tagName === 'IMG') {
        this.$set(this.imageViewerObj, 'showViewer', true);
        this.$set(this.imageViewerObj, 'imgArr', [e.target.src]);
      }
    },
    // 关闭查看图片
    closeViewer() {
      this.$set(this.imageViewerObj, 'showViewer', false);
    },
    // 子级禁止选择
    selectable(row) {
      return row.isParent;
    },
    // 更新页面
    collectionUpdate(type) {
      if (type === 2) {
        this.getExaminePage();
      }
      this.showCollection = false;
    },
    // 获取账户
    getAccountOption() {
      const param = {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 1000000,
        params: {
          status: 1
        }
      };
      accountPage(param, false).then(res => {
        if (res.status === 'OK') {
          this.accountOption = res.data.records;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 核收
    recall(data) {
      const param = {
        receiptNumbers: [data.receiptNumber]
      };
      this.$confirm(this.$i18n.t('receivablePayable.AreYouSureYouWantToWithdraw'), this.$i18n.t('basicData.tips'), { // '确定要撤回?'
        confirmButtonText: this.$i18n.t('basicData.determine'), // '确定',
        cancelButtonText: this.$i18n.t('basicData.Cancel'), // '取消',
        type: 'warning'
      }).then(() => {
        apiSiteBillCancellationOfReceipt(param).then(res => {
          if (res.status === 'OK') {
            this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
            this.getExaminePage();
          }
        });
      });
    },
    // 表头宽度变化
    headerWidthChange() {
      this.$nextTick(() => {
        this.$refs.elTable.doLayout();
      });
    },
    // 监听元素宽高变化
    handleResize() {
      // this.$nextTick(() => {
      //   this.height = this.tableHeight('searchForm');
      // });
    },
    // 列表高度计算
    tableHeight(name) {
      const searchFormHeight = this.$refs[name].clientHeight; // 可变的查询高度
      const pageHeight = document.documentElement.clientHeight; // 可用窗口的高度
      const tableHeight = Number(pageHeight - searchFormHeight - 190); // 计算完之后剩余table可用的高度
      this.tableKey = new Date().getTime();
      this.$nextTick(() => {
        this.rowSelectFlag = true;
        this.tableData.map(list => {
          const matchedIndex = this.selectData.findIndex(itemList => itemList.id === list.id);
          this.$refs.elTable.toggleRowSelection(list, matchedIndex !== -1);
        });
        this.rowSelectFlag = false;
      });
      return tableHeight;
    },
    // 关闭详情
    closeDetail() {
      this.showDetail = false;
    },
    // 给反审行添加背景色
    rowClass({ row, column, rowIndex, columnIndex }) {
      const rowBackground = {};
      if (row.isAuditRollback) {
        rowBackground.background = '#f00';
      }
      return rowBackground;
    },
    // 获取业务主体
    getProviderPage(name = '') {
      const param = {
        pageSize: 10,
        pageNumber: 1,
        params: {
          providerName: name
        }
      };
      apiProviderPage(param, false).then(res => {
        if (res.status === 'OK') {
          this.providerList = res.data.records;
        }
      });
    },

    getQueryData() {
      const { pageNumber, pageSize, params: { providerId, receiptStatus, customerId }} = this.queryForm;

      const param = {
        orderDirection: 'DESC',
        pageNumber,
        pageSize,
        params: {
          receiptNumbers: [], // 收款单号
          billBatchNumbers: [], // 对账单批次号
          providerId, // 业务主体
          receiptStatus, // 收款状态
          customerId // 客户
        }
      };

      // 收款单号 / 账单批次号
      if (this.searchNum.length >= 1) {
        const val = this.$refs.inputTextareaRef.getValue();
        if (this.typeNumber === 1) {
          param.params.receiptNumbers = val;
        } else {
          param.params.billBatchNumbers = val;
        }
      }

      // if (this.searchNum) {
      //   if (this.typeNumber === 1) {
      //     param.params.receiptNumber = this.searchNum;
      //     param.params.billBatchNumber = '';
      //   } else {
      //     param.params.billBatchNumber = this.searchNum;
      //     param.params.receiptNumber = '';
      //   }
      // } else {
      //   param.params.receiptNumber = '';
      //   param.params.billBatchNumber = '';
      // }
      return param;
    },

    // 获取列表数据
    getExaminePage() {
      const randomString = (e, t) => {
        // e选取字符长度; t字符串
        e = e || 4;
        const a = t.length;
        let n = '';
        for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
        return n;
      };
      function repeat(receiptNumber, random) {
        const randomStr = randomString(4, receiptNumber);
        if (!random.includes(randomStr)) {
          random.push(randomStr);
          return randomStr;
        } else {
          repeat(receiptNumber, random);
        }
      }
      function recurrence(table) {
        const randomArr = [];
        for (let i = 0; i < table.length; i++) {
          const randomId = repeat(table[i].receiptNumber, randomArr);
          table[i]['idCopy'] = table[i].id + randomId;
          if (table[i].siteBill && table[i].siteBill.length > 0) {
            recurrence(table[i].siteBill);
          }
        }
        return table;
      }

      const param = this.getQueryData();

      // if (this.searchNum) {
      //   if (this.typeNumber === 1) {
      //     param.params.receiptNumber = this.searchNum;
      //     param.params.billBatchNumber = '';
      //   } else {
      //     param.params.billBatchNumber = this.searchNum;
      //     param.params.receiptNumber = '';
      //   }
      // } else {
      //   param.params.receiptNumber = '';
      //   param.params.billBatchNumber = '';
      // }
      apiSiteReceiptGetPage(param).then(res => {
        if (res.status === 'OK') {
          res.data.records.map(item => {
            for (const key in item) {
              if (key === 'acceptTime' || key === 'auditTime') {
                if (item[key]) {
                  item[key] = item[key].replace('T', ' ');
                }
              }
            }
          });
          this.tableData = recurrence(res.data.records).map(item => {
            const { acceptUserId, createUser, auditUserId } = item;
            const obj = {
              ...item,
              siteName: '', // 主收款单网点名称不显示
              acceptUserName: this.createUser[acceptUserId] || '', // 核收人
              createUserName: this.createUser[createUser] || '', // 生成人员
              auditUserName: this.createUser[auditUserId] || '' // 审核人员
            };

            return Object.freeze(obj);
          });
          this.total = res.data.total;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 列表数据勾选
    handleSelectionChange(arr) {
      if (this.rowSelectFlag) return;
      this.selectData = arr;
    },
    // 获取对账批次详情
    showBillBatchNumber(data) {
      this.batchNumber = data.receiptNumber;
      this.billBatchData = data;
      this.showDetail = true;
    },
    // 搜索
    searchResources() {
      this.queryForm.pageNumber = 1;
      this.getExaminePage();
    },
    // 重置
    onReset() {
      this.queryForm.params = {
        ...PARAM
      }

      this.searchResources();
    },
    // 操作
    operationBtn(type) {
      if (this.selectData.length === 0) {
        this.$message.warning(this.$i18n.t('receivablePayable.SelectAtLeastOnePieceOfData')); // 最少要选择一条数据
        return;
      }
      if (type === 1) {
        if (this.selectData.length > 1) {
          this.$message.warning(this.$i18n.t('receivablePayable.OnlyOnePieceOfDataCanBeSelected')); // 最少要选择一条数据
          return;
        }
        this.collectionKey = new Date().getTime();
        this.showCollection = true;
        return false;
      }
    },

    // 账单导出
    onExport() {
      const { params } = this.getQueryData();
      const ids = this.selectData.map(item => {
        return item.id;
      });
      params.ids = ids;

      apiSiteReceiptExport(params).then(res => {
        goExportList(this);
      }).catch(err => {
        console.error(err);
      });
    },

    // 账单明细导出
    onExportDetail() {
      const { params } = this.getQueryData();
      const ids = this.selectData.map(item => {
        return item.id;
      });
      params.ids = ids;

      apiSiteReceiptExportDetailed(params).then(res => {
        goExportList(this);
      }).catch(err => {
        console.error(err);
      });
    }
  }
};
</script>

<style scoped>
.batchNumber {
  color: #007efd;
  cursor: pointer;
}
</style>
<style>
.columnTree .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  border-color: #aaa;
}
.columnTree .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: #aaa;
}
.remarkStyle p {
  padding: 0!important;
  margin: 0!important;
}
.pending .el-table tr.background {
  background-color: #FF0000!important;
  color: #FFF;
}
.pending .el-table tr.background:hover td {
  background-color: #FF0000!important;
  color: #FFF;
}
.selectInput .el-form-item__label .el-input__inner {
  border: 0;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
