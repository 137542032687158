/*
 * @Date: 2022-07-21 09:31:30
 * @LastEditTime: 2023-05-23 10:59:14
 * @Description:
 * @FilePath: \anjun-express-web-management\src\utils\ajaxFetch.js
 */
// const baseURL = process.env.VUE_APP_SERIAL_PORT;
const ajaxFetch = ({
  url,
  method = 'GET',
  headers = { 'content-type': 'multipart/form-data' },
  data
}) => {
  const obj = {
    method,
    headers,
    mode: 'cors',
    body: JSON.stringify(data)
  };
  if (!data) {
    delete obj.body;
  }
  return fetch(url, obj)
    .then((res) => res.json())
    .catch((err) => err);
};

export default ajaxFetch;
