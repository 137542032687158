<template>
  <div class="receiveDailyReportBox">
    <!-- <el-tabs v-model="findActive" type="border-card">
      <el-tab-pane :label="$t('collectionCenter.byTimeRange')" name="timeFind">
        <timeFind />
      </el-tab-pane>
    </el-tabs> -->
    <timeFind />
  </div>
</template>

<script>
import timeFind from '@/views/collectionCenter/Report/receiveDailyReport/timeFind';
export default {
  name: 'ReceiveDailyReport',
  components: {
    timeFind
  },
  data() {
    return {
      // findActive: 'timeFind' // 按时间 timeFind   按单号 oddFind
    };
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped></style>
