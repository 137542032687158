<template>
  <div>
    <div style="height:50px">
      <el-button
        icon="el-icon-plus"
        plain
        style="float: right"
        @click="clickSenderDialog($t('customerManagements.createSender'),null)"
      >
        {{ $t('GlobalSetObj.create') }}
      </el-button>
    </div>
    <div class="addressClass">
      <div v-if="customerAddressList.length>0">
        <div
          v-for="(item,index) in customerAddressList"
          :key="item.id"
          class="addressItem"
          @mouseleave="hideDeleteButton(index)"
          @mouseover="showDeleteButton(index)"
        >
          <div>
            <span>{{ item.senderName || '--' }} / {{ item.senderMobile || '--' }} / {{
              '--'
            }} / {{ item.senderZipcode || '--' }}</span>
          </div>
          <div>
            <span>{{ item.senderStreet || '--' }} / {{ item.senderHouseNumber || '--' }} / {{
              item.senderCity || '--'
            }} / {{ item.senderState || '--' }} / {{ item.senderComplement || '--' }}</span>
          </div>
          <div>
            <span>{{ item.senderAddress || '--' }}</span>
          </div>
          <div
            :class="`btnOperate${index}`"
            class="btnOperate"
          >
            <template>
              <el-popconfirm
                :title="$t('GlobalSetObj.AreYouSureDelete')"
                @confirm="delAddress(item)"
              >
                <i slot="reference" class="el-icon-delete" style="color: #f56c6c" /></el-popconfirm>
            </template>
            <i
              class="el-icon-edit-outline"
              style="color: #409eff"
              @click="clickSenderDialog($t('customerManagements.editSender'),item)"
            />
          </div>
        </div>
      </div>
      <div v-else class="nodataImage">
        <svg-icon icon-class="noData" />
      </div>
    </div>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[2, 10, 20]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!--  新建收件人  -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="true"
      :title="titleDialog"
      :visible.sync="senderDialog"
      top="10vh"
      width="900px"
    >
      <div>
        <el-form
          ref="senderRuleForm"
          :model="senderRuleForm"
          class="demo-senderRuleForm"
          inline
          label-position="top"
        >
          <el-row :gutter="50">
            <el-col :span="8">
              <!--姓名-->
              <el-form-item
                :label="$t('placeOrder.name')"
                :rules="checkStr50"
                prop="senderName"
                style="width: 100%"
              >
                <el-input
                  v-model="senderRuleForm.senderName"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                  maxlength="52"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :label="$t('GlobalSetObj.MobilePhone')"
                :rules="isPhone"
                prop="senderMobile"
                style="width: 100%"
              >
                <el-input
                  v-model.trim="senderRuleForm.senderMobile"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('placeOrder.Tax')" :rules="isTax12" prop="senderTax" style="width: 100%">
                <el-input
                  v-model.trim="senderRuleForm.senderTax"
                  :placeholder="$t('newOrder.PleaseEnter12Or14DigitTaxNumber')"
                  clearable
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="8">
              <el-form-item
                :label="$t('placeOrder.zipcode')"
                :rules="isZipCode"
                prop="senderZipcode"
                style="width: 100%"
              >
                <el-input
                  v-model.trim="senderRuleForm.senderZipcode"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :label="$t('placeOrder.State')"
                :rules="{ required: true, message: $t('placeOrder.pleaseInput'), trigger: ['blur','change']}"
                prop="senderState"
                style="width: 100%"
              >
                <el-select
                  v-model="senderRuleForm.senderState"
                  :placeholder="$t('placeOrder.pleaseChoose')"
                  clearable
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in senderStateList"
                    :key="item.id"
                    :label="item.label"
                    :value="item.label"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :label="$t('placeOrder.city')"
                :rules="cityComplement"
                prop="senderCity"
                style="width: 100%"
              >
                <el-input
                  v-model="senderRuleForm.senderCity"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                  maxlength="32"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="8">
              <el-form-item
                :label="$t('placeOrder.Area')"
                :rules="addressComplement"
                prop="senderArea"
                style="width: 100%"
              >
                <el-input
                  v-model="senderRuleForm.senderArea"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                  maxlength="32"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :label="$t('newOrder.Street')"
                prop="senderStreet"
                style="width: 100%"
              >
                <el-input
                  v-model="senderRuleForm.senderStreet"
                  :placeholder="$t('newOrder.ConfirmStreetNumber')"
                  clearable
                  maxlength="50"
                  show-word-limit
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :label="$t('placeOrder.HouseNumber')"
                :rules="checkStr5"
                prop="senderHouseNumber"
                style="width: 100%"
              >
                <el-input
                  v-model="senderRuleForm.senderHouseNumber"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                  maxlength="6"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :label="$t('GlobalSetObj.NationalRegistrationNumber')"
                :rules="IeNumber"
                prop="senderIeNumber"
                style="width: 100%"
              >
                <el-input
                  v-model.trim="senderRuleForm.senderIeNumber"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :label="$t('placeOrder.addressComplement')"
                :rules="addressComplement"
                prop="senderComplement"
                style="width: 100%"
              >
                <el-input
                  v-model="senderRuleForm.senderComplement"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                  maxlength="32"
                />
              </el-form-item>
            </el-col>
            <!--    邮箱        -->
            <el-col :span="8">
              <el-form-item
                :label="$t('placeOrder.email')"
                :rules="checkMail"
                prop="senderMail"
                style="width: 100%"
              >
                <el-input
                  v-model="senderRuleForm.senderMail"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="24">
              <el-form-item
                :label="$t('placeOrder.detailedAddress')"
                :rules="[{ required: true, message: $t('placeOrder.pleaseInput'), trigger: ['blur','change'] },
                         { min: 2, max: 200, message: $t('newOrder.TdetailedAddressCharacter'), trigger: 'blur' }]"
                prop="senderAddress"
                style="width: 100%"
              >
                <el-input
                  v-model="senderRuleForm.senderAddress"
                  :placeholder="$t('newOrder.StreetHouseNumber')"
                  maxlength="200"
                  show-word-limit
                  type="text"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="senderDialog = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button type="primary" @click="senderConfirm">{{ $t('GlobalSetObj.determine') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  checkedzipcode,
  createCustomerSender,
  deleteCustomerSender,
  getLgAreaListData,
  pageCustomerSender,
  updateCustomerSender
} from '@/api/orderApi';
import {
  checkAddressComplement,
  checkCityComplement,
  E_Mail,
  IENumber,
  isMobile,
  isTax,
  isZipCodeAsync
} from '@/utils/validate';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'SenderAddress',
  props: {
    // 客户ID
    customerId: {
      type: [Number, String],
      default: ''
    }
  },

  data() {
    return {
      customerAddressList: [],
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        total: 0
      },
      senderDialog: false,
      titleDialog: this.$t('customerManagements.createSender'),
      senderRuleForm: {
        senderName: '', // （收/发）名字
        senderMobile: '', // 电话
        senderTax: '', // 税号
        senderZipcode: '', // 邮编
        senderStreet: '', // 街道（精确到街道号）
        // senderNeighborhood: '', // 邻里
        senderAddress: '', // 详细地址
        senderHouseNumber: '', // 门牌号
        senderState: '', // 州
        senderCity: '', // 城市
        senderArea: '', // 区
        senderIeNumber: '', // 国家注册号
        senderMail: '' // 邮箱
      },
      senderEditForm: {}, // 编辑
      isZipcode1: true, // 光标离开校验邮编-发件人
      isBlur: 0, // 光标移出 0 未移出不调用事件  1 已移出
      senderStateList: [], // 州列表
      // 校验50个字符
      checkStr50: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        {
          validator: (rule, value, callback) => {
            if (value.trim().length === 0) {
              return callback(new Error(this.$t('placeOrder.pleaseInput')));
            } else if (value.length > 50) {
              return callback(new Error(this.$t('placeOrder.input50Str')));
            } else {
              callback();
            }
          }, trigger: ['blur', 'change']
        }
      ],
      // 邮箱 E_Mail
      checkMail: [
        { required: false },
        { validator: E_Mail, trigger: ['blur', 'change'] }
      ],
      // 城市
      cityComplement: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        { validator: checkCityComplement, trigger: ['blur', 'change'] }
      ],
      // 地址补充
      addressComplement: [
        { required: false },
        { validator: checkAddressComplement, trigger: ['blur', 'change'] }
      ],
      // 校验5个字符
      checkStr5: [
        { required: false, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        { min: 1, max: 5, message: this.$t('placeOrder.input5'), trigger: ['blur', 'change'] }
      ],
      IeNumber: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        { validator: IENumber, trigger: ['blur', 'change'] }
      ],
      isPhone: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        {
          validator: async(rule, value, callback) => {
            if (!isMobile(value)) {
              return callback(new Error(this.$t('placeOrder.input11PhoneNumber')));
            } else {
              callback();
            }
          }
        }
      ],
      isZipCode: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        { validator: isZipCodeAsync, trigger: ['blur'] }
      ],
      isTax12: [ // 112125456431
        { required: true, message: this.$t('newOrder.TheTaxIDCannotBeEmpty'), trigger: ['blur', 'change'] },
        { validator: isTax, trigger: ['blur', 'change'] }
      ]
    };
  },
  watch: {
    senderDialog: {
      handler: function(val, oldVal) {
        if (this.titleDialog === this.$t('customerManagements.createSender')) {
          this.senderRuleForm = {
            senderName: '', // （收/发）名字
            senderMobile: '', // 电话
            senderTax: '', // 税号
            senderZipcode: '', // 邮编
            senderStreet: '', // 街道（精确到街道号）
            // senderNeighborhood: '', // 邻里
            senderAddress: '', // 详细地址
            senderHouseNumber: '', // 门牌号
            senderState: '', // 州
            senderCity: '', // 城市
            senderArea: '', // 区
            senderIeNumber: ''// 国家注册号
          };
          this.$refs['senderRuleForm']?.resetFields();
        }
      }
    }
  },
  created() {
    this.getAddress();
    this.getState();
  },
  methods: {
    // 确认
    senderConfirm() {
      this.$refs['senderRuleForm'].validate((valid) => {
        if (valid) {
          let data = {};
          data = this.senderRuleForm;
          const str1 = data.senderTax;
          const pattern = /\d+/g;
          const numbers = str1.match(pattern);
          data.senderTax = numbers.join('');
          data.customerId = this.customerId;
          if (this.titleDialog === this.$t('customerManagements.createSender')) {
            if (this.senderRuleForm.id) {
              delete data.id;
            }
            createCustomerSender(data).then(res => {
              if (res.status === 'OK') {
                this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
                this.senderDialog = false;
                this.getAddress();
              }
            });
          } else {
            updateCustomerSender(data).then(res => {
              if (res.status === 'OK') {
                this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
                this.senderDialog = false;
                this.getAddress();
              }
            });
          }
        }
      });
    },
    // 删除
    delAddress(item) {
      deleteCustomerSender(item.id).then(res => {
        if (res.status === 'OK') {
          this.$message.success(this.$t('GlobalSetObj.DeleteSuccessful'));
          this.getAddress();
        }
      });
    },
    // 编辑
    clickSenderDialog(title, item) {
      this.senderDialog = true;
      this.titleDialog = title;
      if (item) {
        // const obj = cloneDeep(item);
        // for (const val in obj) {
        //   this.$set(this.senderRuleForm, val, obj[val]);
        // }
        this.senderRuleForm = cloneDeep(item);
      }
    },
    getAddress() {
      const data = {
        params: {}
      };
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.pageNumber;
      data.params.customerId = this.customerId;
      data.params.senderZipcode = '';
      data.params.senderName = '';
      pageCustomerSender(data).then(res => {
        if (res.status === 'OK') {
          this.customerAddressList = res.data.records;
          this.queryForm.total = res.data.total;
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.getAddress();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getAddress();
    },
    // 发件人——级联地区
    getState() { // level 0 州    1 城市   2 地区
      const params = {
        level: 0,
        parentId: ''
      };
      getLgAreaListData(params, false).then((res) => {
        if (res.status === 'OK') {
          this.senderStateList = res.data.map(item => {
            return {
              id: item.id,
              label: `${item.nameEn}${item.nameCn ? '(' + item.nameCn + ')' : ''}${item.twoCode ? '(' + item.twoCode + ')' : ''}`
            };
          });
        }
      });
    },
    // 鼠标移入
    showDeleteButton(index) {
      const ele = '.btnOperate' + index;
      const btnOperateElement = document.querySelector(ele);
      btnOperateElement.style.opacity = 1;
    },
    hideDeleteButton(index) {
      const ele = '.btnOperate' + index;
      const btnOperateElement = document.querySelector(ele);
      btnOperateElement.style.opacity = 0;
    },
    // 邮编校验
    checkPostcode(postcode) {
      // pType === 1 发件人   pType === 2 收件人
      if (this.isBlur === 1) {
        const data = {};
        data.zipcode = postcode;
        checkedzipcode(data).then(res => {
          // console.log('验证邮编', res);
          if (res.status === 'OK') {
            if (!res.data) {
              this.$message.error(this.$t('placeOrder.inputCodeErr'));
              this.senderRuleForm.senderZipcode = '';
            }
          }
        }).catch(() => {
          this.senderRuleForm.senderZipcode = '';
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__title {
  font-size: 16px;
  font-weight: bold;
}

.addressClass {
  height: 680px;
  margin-top: 10px;
  overflow: auto;
  padding-right: 10px;

  .nodataImage {
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;

    .svg-icon {
      width: 120px !important;
      height: 120px !important;
    }
  }

  .addressItem {
    font-size: 14px;
    color: #606266;
    line-height: 25px;
    margin-bottom: 15px;
    cursor: pointer;
    padding: 15px 10px 15px 10px;
    position: relative;
    border: 1px solid #e4e7ed;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: #ddf0f8;
    }

    div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .btnOperate {
      position: absolute;
      right: 20px;
      top: 10px;
      font-size: 16px;
      width: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      opacity: 0;
    }
  }
}
</style>
