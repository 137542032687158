<template>
  <div class="Box">

    <div style="margin-bottom: 20px;" class="selectClass">
      <div v-show="changeVal == 'hzVal'">
        <el-form>
          <el-row
            type="flex" justify="start" style="flex-wrap: wrap;"
            class="timeClass"
          >
            <el-col :span="7" style="width: auto;">
              <el-row>
                <el-col class="col2">
                  <el-select
                    v-model="selectTime" :placeholder="$t('collectionCenter.pleaseChoose')" class="selectTimeClass autoWidth"
                    @change="changeTime"
                  >
                    <template slot="prefix">
                      {{ (timeList.find(item => item.value === selectTime) || {}).time }}
                    </template>
                    <el-option
                      v-for="(item, index) in timeList"
                      :key="index"
                      :label="item.time"
                      :value="item.value"
                    />
                  </el-select>
                </el-col>
                <el-col style="width: auto;">
                  <!-- 装车时间 't1' -->
                  <!-- 卸车时间 't2' -->
                  <el-form-item class="delMl">
                    <el-date-picker
                      v-model="timeValue"
                      :clearable="false"
                      type="datetimerange"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      :default-time="['00:00:00', '23:59:59']"
                      :range-separator="$t('collectionCenter.to')"
                      :start-placeholder="$t('collectionCenter.StartTime')"
                      :end-placeholder="$t('collectionCenter.EndTime')"
                      @change="dateChange"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="8" style="width: auto;">
              <el-row>
                <el-col class="col2">
                  <el-select v-model="selectNumber" class="selectConditionClass selectTimeClass autoWidth" @change="selectNumberClick">
                    <template slot="prefix">
                      {{ (numberList.find(item => item.value === selectNumber) || {}).num }}
                    </template>
                    <el-option
                      v-for="(item, index) in numberList"
                      :key="index"
                      :label="item.num"
                      :value="item.value"
                    />
                  </el-select>
                </el-col>
                <el-col v-if="selectNumber == 'n1'" style="width: auto;" class="colWill">
                  <el-form-item class="textarea">
                    <el-input
                      v-model="queryForm.params.tagNumberList"
                      type="textarea"
                      autosize
                      :placeholder="$t('collectionCenter.BatchQuery')"
                      @blur="isNumber"
                      @keydown.enter.native="BlockNewlineCarTag"
                    /><!-- 支持批量查询(回车换行分割) -->
                  </el-form-item>
                </el-col>
                <el-col v-if="selectNumber == 'n2'" style="width: auto;" class="colWill">
                  <el-form-item class="textarea">
                    <el-input
                      v-model="queryForm.params.vehicleNumberList"
                      type="textarea"
                      autosize
                      :placeholder="$t('collectionCenter.BatchQuery')"
                      @blur="isNumber"
                      @keydown.enter.native="BlockNewlineCarCard"
                    /><!-- 支持批量查询(回车换行分割) -->
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="7" style="width: auto;">
              <!-- 所属网点： -->
              <el-form-item :label="$t('collectionCenter.belongDot')">
                <el-select
                  v-model="queryForm.params.siteId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                  filterable @focus="getNewestSite"
                >
                  <el-option
                    v-for="(item, index) in siteIdList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" style="width: auto;">
              <el-row>
                <el-col class="col2">
                  <el-select v-model="selectCar" class="selectConditionClass selectTimeClass autoWidth" @change="selectCarClick">
                    <template slot="prefix">
                      {{ (carList.find(item => item.value === selectCar) || {}).car }}
                    </template>
                    <el-option
                      v-for="(item, index) in carList"
                      :key="index"
                      :label="item.car"
                      :value="item.value"
                    />
                  </el-select>
                </el-col>
                <el-col v-if="selectCar == 'c1'" style="width: auto;" class="colWill">
                  <el-form-item>
                    <el-select
                      v-model="queryForm.params.loadedUser" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                      filterable @focus="getNewestPeople"
                    >
                      <el-option
                        v-for="(item, index) in employeeList"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col v-if="selectCar == 'c2'" style="width: auto;" class="colWill">
                  <el-form-item>
                    <el-select
                      v-model="queryForm.params.unloadedUser" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                      filterable @focus="getNewestPeople"
                    >
                      <el-option
                        v-for="(item, index) in employeeList"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="7" style="width: auto;">
              <!-- 下一站： -->
              <el-form-item :label="$t('GlobalSetObj.nextStation') + '：'">
                <el-select
                  v-model="queryForm.params.nextSiteId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                  filterable @focus="getNewestSite"
                >
                  <el-option
                    v-for="(item, index) in siteIdList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <el-row type="flex" justify="space-between">
        <el-col :span="12">
          <el-button
            v-show="changeVal == 'hzVal'"
            v-permit:remove="'btn:collection-center:report:carMonitoring:search'"
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
          <el-button
            v-show="changeVal == 'hzVal'"
            v-permit:remove="'btn:collection-center:report:carMonitoring:reset'"
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
          <el-button
            v-show="changeVal == 'hzVal'"
            v-permit:remove="'btn:collection-center:report:carMonitoring:allExport-hz'"
            icon="el-icon-upload"
            size="mini"
            :class="isClickAll1 ? 'onlyClickOnce10' : ''"
            @click="downloadAll"
          >{{ $t('collectionCenter.allExport') }}</el-button><!-- 汇总——全部导出 -->
          <el-button
            v-show="changeVal == 'mxVal'"
            v-permit:remove="'btn:collection-center:report:carMonitoring:allExport-mx'"
            icon="el-icon-upload"
            size="mini"
            :class="isClickAll2 ? 'onlyClickOnce10' : ''"
            @click="downloadAllMX"
          >{{ $t('collectionCenter.allExport') }}</el-button><!-- 明细——全部导出 -->
        </el-col>
      </el-row>
      <el-divider />
    </div>

    <el-tabs v-model="changeVal" type="border-card">
      <!-- 汇总 -->
      <el-tab-pane :label="$t('collectionCenter.summary')" name="hzVal">
        <div>
          <el-table
            ref="tableList"
            :max-height="600"
            border
            show-summary
            :summary-method="getSummaries"
            :data="tableDate"
          >
            <el-table-column
              type="selection"
              width="55"
            />
            <!-- 序号 -->
            <el-table-column
              :label="$t('collectionCenter.ind')" type="index" width="60"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="tagNumber"
              :label="$t('collectionCenter.carNumber')"
            /><!-- 车签号 -->
            <el-table-column
              align="center"
              prop="vehicleNumber"
              :label="$t('collectionCenter.licensePlateNumber')"
            /><!-- 车牌号 -->
            <el-table-column
              align="center"
              prop="loadedUserName"
              :label="$t('collectionCenter.loader')"
            /><!-- 装车人 -->
            <el-table-column
              align="center"
              prop="loadedSiteName"
              :label="$t('collectionCenter.loaderDot')"
            /><!-- 装车人所属网点 -->
            <el-table-column
              align="center"
              prop="loadedAddress"
              :label="$t('collectionCenter.loadingAddress')"
              :show-overflow-tooltip="true"
            /><!-- 装车地址 -->
            <el-table-column
              align="center"
              prop="loadedTime"
              :label="$t('collectionCenter.loadTime')"
            /><!-- 装车时间 -->
            <el-table-column
              align="center"
              prop="nextSiteName"
              :label="$t('GlobalSetObj.nextStation')"
            /><!-- 下一站 -->
            <el-table-column
              align="center"
              prop="unloadedUserName"
              :label="$t('collectionCenter.unloader')"
            /><!-- 卸车人 -->
            <el-table-column
              align="center"
              prop="unloadedSiteName"
              :label="$t('collectionCenter.unloaderDot')"
            /><!-- 卸车人所属网点 -->
            <el-table-column
              align="center"
              prop="unloadedAddress"
              :label="$t('collectionCenter.unloadingAddress')"
              :show-overflow-tooltip="true"
            /><!-- 卸车地址 -->
            <el-table-column
              align="center"
              prop="unloadTime"
              :label="$t('collectionCenter.unloadTime')"
            /><!-- 卸车时间 -->
            <el-table-column
              align="center"
              prop="loadedBagCount"
              :label="$t('collectionCenter.totalNumberBagsLoaded')"
            ><!-- 装车总袋数 -->
              <template slot-scope="scope">
                <el-button v-if="hzTomx" size="mini" @click="cellClick(scope.row, 0)">{{ scope.row.loadedBagCount }}</el-button>
                <span v-else>{{ scope.row.loadedBagCount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="unloadedBagCount"
              :label="$t('collectionCenter.totalBagsUnloaded')"
            ><!-- 卸车总袋数 -->
              <template slot-scope="scope">
                <el-button v-if="hzTomx" size="mini" @click="cellClick(scope.row, 1)">{{ scope.row.unloadedBagCount }}</el-button>
                <span v-else>{{ scope.row.unloadedBagCount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="loadedUnloadedBagDiffCount"
              :label="$t('collectionCenter.bagCountDiscrepancy')"
            ><!-- 装卸车总袋数差异 -->
              <template slot-scope="scope">
                <el-button v-if="hzTomx" size="mini" @click="cellDiffClick(scope.row, 'd')">{{ scope.row.loadedUnloadedBagDiffCount }}</el-button>
                <span v-else>{{ scope.row.loadedUnloadedBagDiffCount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="vehicleType"
              :label="$t('collectionCenter.carType')"
            /><!-- 车辆类型 -->
            <el-table-column
              align="center"
              prop="operationMode"
              :label="$t('collectionCenter.operationMode')"
            /><!-- 运营模式 -->
            <el-table-column
              align="center"
              prop="loadedTicketCount"
              :label="$t('collectionCenter.carLoadingVotes')"
            ><!-- 装车总票数 -->
              <template slot-scope="scope">
                <el-button v-if="hzTomx" size="mini" @click="cellPClick(scope.row, 0)">{{ scope.row.loadedTicketCount }}</el-button>
                <span v-else>{{ scope.row.loadedTicketCount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="unloadedTicketCount"
              :label="$t('collectionCenter.carUnloadingVotes')"
            ><!-- 卸车总票数 -->
              <template slot-scope="scope">
                <el-button v-if="hzTomx" size="mini" @click="cellPClick(scope.row, 1)">{{ scope.row.unloadedTicketCount }}</el-button>
                <span v-else>{{ scope.row.unloadedTicketCount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="loadedUnloadedTicketDiffCount"
              :label="$t('collectionCenter.votesDiscrepancy')"
            ><!-- 装卸车总票数差异 -->
              <template slot-scope="scope">
                <el-button v-if="hzTomx" size="mini" @click="cellDiffClick(scope.row, 'p')">{{ scope.row.loadedUnloadedTicketDiffCount }}</el-button>
                <span v-else>{{ scope.row.loadedUnloadedTicketDiffCount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="carImg"
              :label="$t('collectionCenter.operation')"
              fixed="right"
              align="center"
              width="120"
            ><!-- 操作 -->
              <template slot-scope="scope">
                <!-- 装车图片 -->
                <span
                  style="color: #26b3f1;cursor: pointer;"
                  @click="loadedImg(scope.row, 0)"
                >{{ $t('collectionCenter.loadingPicture') }}</span>
                <br>
                <!-- 卸车图片 -->
                <span
                  style="color: #26b3f1;cursor: pointer;"
                  @click="loadedImg(scope.row, 1)"
                >{{ $t('collectionCenter.unloadingPicture') }}</span>
                <br>
                <!-- 装车签名图片 -->
                <span
                  style="color: #26b3f1;cursor: pointer;"
                  @click="loadedSignImg(scope.row, 0)"
                >{{ $t('collectionCenter.loadingSignPicture') }}</span>
                <br>
                <!-- 卸车签名图片 -->
                <span
                  style="color: #26b3f1;cursor: pointer;"
                  @click="loadedSignImg(scope.row, 1)"
                >{{ $t('collectionCenter.unloadSignImg') }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="right">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryForm.pageNumber"
            :page-sizes="[10, 20, 50, 100, 500, 1000]"
            :page-size.sync="queryForm.pageSize"
            :total="total"
            background
            style="margin: 10px 0"
            @current-change="getTablePage"
            @size-change="getPageSize"
          />
        </div>
        <!-- 查看装卸车图片 -->
        <el-dialog :visible.sync="dialogVisible" @close="closeDialog">
          <div v-if="loadingTruckPicture.length > 0" style="overflow-x: auto; white-space:nowrap;">
            <span v-for="(img, index) in loadingTruckPicture" :key="index" style="margin: 0 10px;">
              <img :src="img" alt="" style="width: auto; height: 500px;">
            </span>
          </div>
          <div v-else style="text-align: center">
            - {{ $t('collectionCenter.NoContentYet') }} -
          </div>
        </el-dialog>

      </el-tab-pane>
      <!-- 明细 -->
      <el-tab-pane v-if="changeVal == 'mxVal'" :label="$t('collectionCenter.particulars')" name="mxVal">
        <div v-if="mxIndex == 0">
          <el-table
            ref="tableList"
            :max-height="600"
            border
            show-summary
            :summary-method="getSummariesMX"
            :data="tableDateMX"
          >
            <el-table-column
              type="selection"
              width="55"
            />
            <!-- 序号 -->
            <el-table-column
              :label="$t('collectionCenter.ind')" type="index" width="60"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="bagNumber"
              :label="$t('collectionCenter.BagNumber')"
            /><!-- 袋号 -->
            <el-table-column
              align="center"
              prop="bagSealingTime"
              :label="$t('collectionCenter.sealingBagTime')"
            /><!-- 封袋时间 -->
            <el-table-column
              align="center"
              prop="bagSealingUserName"
              :label="$t('collectionCenter.BagSealer')"
            /><!-- 封袋人 -->
            <el-table-column
              align="center"
              prop="bagSiteName"
              :label="$t('collectionCenter.bagPackerDot')"
            /><!-- 封袋人所属网点 -->
            <el-table-column
              align="center"
              prop="packageCount"
              :label="$t('collectionCenter.InPocketTotal')"
            /><!-- 袋内总包裹数 -->
          </el-table>
        </div>
        <div v-if="mxIndex == 1">
          <el-table
            ref="tableList"
            :max-height="600"
            border
            :data="tableDateMX"
          >
            <el-table-column
              type="selection"
              width="55"
            />
            <!-- 序号 -->
            <el-table-column
              :label="$t('collectionCenter.ind')" type="index" width="60"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="scanNumber"
              :label="$t('collectionCenter.Barcode')"
            /><!-- 条形码 -->
            <el-table-column
              align="center"
              prop="packageNumber"
              :label="$t('collectionCenter.parcelNum')"
            /><!-- 包裹号 -->
            <el-table-column
              align="center"
              prop="scanTime"
              :label="$t('collectionCenter.lanshouT')"
            /><!-- 揽收时间 -->
            <el-table-column
              align="center"
              prop="scanUserName"
              :label="$t('collectionCenter.LanShouPeople')"
            /><!-- 揽收人 -->
            <el-table-column
              align="center"
              prop="scanSiteName"
              :label="$t('collectionCenter.receiverBelongsSite')"
            /><!-- 揽收人所属网点 -->
          </el-table>
        </div>
        <div class="right">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryFormMX.pageNumber"
            :page-sizes="[10, 20, 50, 100, 500, 1000]"
            :page-size.sync="queryFormMX.pageSize"
            :total="totalMX"
            background
            style="margin: 10px 0"
            @current-change="getTablePageMX"
            @size-change="getPageSizeMX"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  carSummaryPage,
  queryAllUser,
  queryAllSite,
  searchSiteIdList,
  collectVehicleScanPage,
  carSummaryExportAll,
  carDetailExportAll,
  collectVehicleVotePage,
  carVoteDetailExportAll,
  queryLoadAndUnLoadImg,
  queryLoadSignImg,
  voteDiffPage,
  bagDiffPage,
  bagDiffPageExport
} from '@/api/lanshou';
import dayjs from 'dayjs';

export default {
  name: 'CarMonitoring',
  data() {
    return {
      isClickAll1: false,
      isClickAll2: false,
      hzTomx: this.$store.getters.button_permissions.includes('btn:collection-center:report:carMonitoring:hzTomx'),
      carImg: this.$store.getters.button_permissions.includes('btn:collection-center:report:carMonitoring:carImg'),
      dialogVisible: false,
      loadingTruckPicture: [],
      mxIndex: 0, // 0 展示袋数明细  1 展示票数明细
      bagDiff: null, // null 其他   2 袋子差异详情导出   1 票差异详情
      changeVal: 'hzVal',
      selectTime: 't1',
      timeList: [
        { value: 't1', time: this.$t('collectionCenter.loadTime') }, // '装车时间'
        { value: 't2', time: this.$t('collectionCenter.unloadTime') } // '卸车时间'
      ],
      timeValue: [], // 选择日期时间
      selectNumber: 'n1', // 单号查询——选择条件
      numberList: [
        { value: 'n1', num: this.$t('collectionCenter.carNumber') }, // '车签号'
        { value: 'n2', num: this.$t('collectionCenter.licensePlateNumber') } // '车牌号'
      ],
      notEnter: true,
      siteIdList: [], // （启用及未启用）网点list
      selectCar: 'c1',
      carList: [
        { value: 'c1', car: this.$t('collectionCenter.loader') }, // '装车人'
        { value: 'c2', car: this.$t('collectionCenter.unloader') } // '卸车人'
      ],
      employeeList: [], // （启用及未启用）员工list
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          loadedStartTime: '', // 装车开始时间
          loadedEndTime: '', // 装车结束时间
          unloadedStartTime: '', // 卸车开始时间
          unloadedEndTime: '', // 卸车结束时间
          vehicleNumberList: '', // 车牌号
          tagNumberList: '', // 车签号
          siteId: '', // 所属网点
          loadedUser: '', // 装车人
          unloadedUser: '', // 卸车人
          nextSiteId: '' // 下一站
        }
      },
      total: 0,
      tableDate: [],
      queryFormMX: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          tagNumber: '',
          operationType: '',
          nextSiteId: ''
        }
      },
      totalMX: 0,
      tableDateMX: []
    };
  },
  watch: {
    'queryForm.params.tagNumberList'(val) {
      const bgh1 = val.split('\n');
      let resetScanNumber = [];
      if (bgh1.length >= 1000) {
        resetScanNumber = bgh1.slice(0, 1000).join('\n');
        this.queryForm.params.tagNumberList = resetScanNumber;
        this.notEnter = false;
      } else {
        this.notEnter = true;
      }
      if (bgh1.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    },
    'queryForm.params.vehicleNumberList'(val) {
      const bgh1 = val.split('\n');
      let resetScanNumber = [];
      if (bgh1.length >= 1000) {
        resetScanNumber = bgh1.slice(0, 1000).join('\n');
        this.queryForm.params.vehicleNumberList = resetScanNumber;
        this.notEnter = false;
      } else {
        this.notEnter = true;
      }
      if (bgh1.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    }
  },
  created() {
    this.default();
    this.searchClick();
    this.isClickAll1 = false;
    this.isClickAll2 = false;
  },
  activated() {
    this.isClickAll1 = false;
    this.isClickAll2 = false;
  },
  methods: {
    closeDialog() {
      this.loadingTruckPicture = [];
    },
    // 装卸车签名图片
    loadedSignImg(row, type) {
      const imgForm = {
        tagNumber: row.tagNumber,
        nextSiteId: row.nextSiteId,
        operationType: type
      };
      queryLoadSignImg(imgForm).then(res => {
        if (res.status === 'OK') {
          this.loadingTruckPicture = res.data;
          this.dialogVisible = true;
        }
      }).catch({});
    },
    // 查看装卸车图片
    loadedImg(row, type) {
      const imgForm = {
        tagNumber: row.tagNumber,
        operationType: type
      };
      queryLoadAndUnLoadImg(imgForm).then(res => {
        if (res.status === 'OK') {
          this.loadingTruckPicture = res.data;
          this.dialogVisible = true;
        }
      }).catch({});
    },
    getNewestSite() {
      // 获取（启用及未启用）网点
      queryAllSite().then(res => {
        if (res.status === 'OK') {
          this.siteIdList = [];
          res.data.forEach(data => {
            this.siteIdList.push({ id: data.id, name: data.name });
          });
        }
      });
    },
    getNewestPeople() {
      // 获取最新员工数据
      queryAllUser().then(res => {
        if (res.status === 'OK') {
          this.employeeList = [];
          res.data.forEach(data => {
            this.employeeList.push({ id: data.id, name: data.realName });
          });
        }
      });
    },
    // 切换时间
    changeTime() { // 选择扫描时间 / 创建时间
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      if (this.selectTime === 't1') {
        this.queryForm.params.loadedStartTime = today + ' 00:00:00';
        this.queryForm.params.loadedEndTime = today + ' 23:59:59';
        this.queryForm.params.unloadedStartTime = '';
        this.queryForm.params.unloadedEndTime = '';
      }
      if (this.selectTime === 't2') {
        this.queryForm.params.unloadedStartTime = today + ' 00:00:00';
        this.queryForm.params.unloadedEndTime = today + ' 23:59:59';
        this.queryForm.params.loadedStartTime = '';
        this.queryForm.params.loadedEndTime = '';
      }
    },
    // 选择日期时间
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        this.queryForm.params.loadedStartTime = '';
        this.queryForm.params.loadedEndTime = '';
        this.queryForm.params.unloadedStartTime = '';
        this.queryForm.params.unloadedEndTime = '';
        return;
      }
      this.queryForm.params.loadedStartTime = '';
      this.queryForm.params.loadedEndTime = '';
      this.queryForm.params.unloadedStartTime = '';
      this.queryForm.params.unloadedEndTime = '';
      if (this.selectTime === 't1') {
        this.queryForm.params.loadedStartTime = val[0];
        this.queryForm.params.loadedEndTime = val[1];
      }
      if (this.selectTime === 't2') {
        this.queryForm.params.unloadedStartTime = val[0];
        this.queryForm.params.unloadedEndTime = val[1];
      }
      if (val[1]) {
        const endTime = dayjs(val[0]).subtract(-6, 'month').format('YYYY-MM-DD 23:59:59');
        if (val[1] > endTime) {
          this.$message({
            // 最多可查询到180天的数据
            message: this.$t('collectionCenter.max180DaysDate'),
            type: 'warning'
          });
          this.changeTime();
        }
      }
    },

    selectNumberClick() { // 切换 车签号 / 车牌号
      this.queryForm.params.vehicleNumberList = '';
      this.queryForm.params.tagNumberList = '';
    },
    selectCarClick() {
      this.queryForm.params.loadedUser = '';
      this.queryForm.params.unloadedUser = '';
    },
    BlockNewlineCarTag() {
      const e = window.event || arguments[0];
      const packageNumberLen = this.queryForm.params.tagNumberList.split('\n');
      if (!this.notEnter) {
        if (packageNumberLen.length >= 1000) {
          this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    BlockNewlineCarCard() {
      const e = window.event || arguments[0];
      const packageNumberLen = this.queryForm.params.vehicleNumberList.split('\n');
      if (!this.notEnter) {
        if (packageNumberLen.length >= 1000) {
          this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    default() {
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      this.selectTime = 't1';
      this.selectNumber = 'n1';
      this.selectCar = 'c1';
      this.queryForm.params = {
        loadedStartTime: today + ' 00:00:00', // 装车开始时间
        loadedEndTime: today + ' 23:59:59', // 装车结束时间
        unloadedStartTime: '', // 卸车开始时间
        unloadedEndTime: '', // 卸车结束时间
        vehicleNumberList: '', // 车牌号
        tagNumberList: '', // 车签号
        siteId: '', // 所属网点
        loadedUser: '', // 装车人
        unloadedUser: '', // 卸车人
        nextSiteId: '' // 下一站
      };
    },
    isNumber() {
      /**
       *  单号去除空值
       */
      if (this.selectNumber === 'n1') {
        let tagNumberList = this.queryForm.params.tagNumberList.split('\n');
        tagNumberList = tagNumberList.filter(s => {
          return s && s.trim();
        });
        this.queryForm.params.tagNumberList = tagNumberList.join('\n');
      }
      if (this.selectNumber === 'n2') {
        let vehicleNumberList = this.queryForm.params.vehicleNumberList.split('\n');
        vehicleNumberList = vehicleNumberList.filter(s => {
          return s && s.trim();
        });
        this.queryForm.params.vehicleNumberList = vehicleNumberList.join('\n');
      }
    },
    searchClick() {
      // this.isNumber();
      if (((this.selectTime === 't1' && !this.queryForm.params.loadedStartTime && !this.queryForm.params.loadedEndTime) ||
        (this.selectTime === 't2' && !this.queryForm.params.unloadedStartTime && !this.queryForm.params.unloadedEndTime)) &&
        (!this.queryForm.params.vehicleNumberList && !this.queryForm.params.tagNumberList)) {
        this.$message({
          message: this.$t('collectionCenter.PleaseSelectTime'),
          type: 'warning'
        });
        return;
      } else {
        if (this.queryForm.params.vehicleNumberList || this.queryForm.params.tagNumberList) {
          this.queryForm.params.loadedStartTime = '';
          this.queryForm.params.loadedEndTime = '';
          this.queryForm.params.unloadedStartTime = '';
          this.queryForm.params.unloadedEndTime = '';
          this.timeValue = [];
        }
        this.queryForm.pageNumber = 1;
        localStorage.setItem('carMQueryForm', JSON.stringify(this.queryForm));
        this.getTablePage();
      }
    },
    getTablePage() {
      const queryForm = JSON.parse(localStorage.getItem('carMQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      const vehicleNumberList = queryForm.params.vehicleNumberList;
      const tagNumberList = queryForm.params.tagNumberList;
      queryForm.params.vehicleNumberList = vehicleNumberList ? queryForm.params.vehicleNumberList.split('\n') : [];
      queryForm.params.tagNumberList = tagNumberList ? queryForm.params.tagNumberList.split('\n') : [];
      carSummaryPage(queryForm).then(res => {
        if (res.status === 'OK') {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        } else {
          this.tableDate = [];
          this.total = 0;
        }
      });
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      const query = JSON.parse(localStorage.getItem('carMQueryForm')) || this.queryForm;
      const queryForm = {
        pageNumber: 1,
        pageSize: this.queryForm.pageSize,
        params: query.params
      };
      const vehicleNumberList = queryForm.params.vehicleNumberList;
      const tagNumberList = queryForm.params.tagNumberList;
      queryForm.params.vehicleNumberList = vehicleNumberList ? queryForm.params.vehicleNumberList.split('\n') : [];
      queryForm.params.tagNumberList = tagNumberList ? queryForm.params.tagNumberList.split('\n') : [];
      carSummaryPage(queryForm).then(res => {
        if (res.status === 'OK') {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        } else {
          this.tableDate = [];
          this.total = 0;
        }
      });
    },
    // 总计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('collectionCenter.all');
          return;
        }
        if (
          column.label === this.$t('collectionCenter.totalNumberBagsLoaded') ||
          column.label === this.$t('collectionCenter.totalBagsUnloaded') ||
          column.label === this.$t('collectionCenter.carLoadingVotes') ||
          column.label === this.$t('collectionCenter.carUnloadingVotes')
        ) {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += '';
          } else {
            sums[index] = '';
          }
        }
      });
      return sums;
    },
    resetClick() {
      this.default();
    },
    downloadAll() {
      this.isClickAll1 = true;
      const query = JSON.parse(localStorage.getItem('carMQueryForm')) || this.queryForm;
      const vehicleNumberList = query.params.vehicleNumberList;
      const tagNumberList = query.params.tagNumberList;
      query.params.vehicleNumberList = vehicleNumberList ? query.params.vehicleNumberList.split('\n') : [];
      query.params.tagNumberList = tagNumberList ? query.params.tagNumberList.split('\n') : [];
      carSummaryExportAll(query).then(res => {
        if (res.status === 'OK') {
          const h = this.$createElement;
          this.$msgbox({
            // '提示'
            title: this.$t('collectionCenter.tips'),
            message: h('p', null, [
              // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源任务列表中下载，'
              h('span', this.$t('collectionCenter.exportChangeTip')),
              // '请勿重复导出！'
              h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
            ]),
            showCancelButton: true,
            // '立即前往'
            confirmButtonText: this.$t('collectionCenter.Immediately'),
            // '关闭'
            cancelButtonText: this.$t('collectionCenter.close')
          }).then(action => {
            if (action === 'confirm') {
              this.$router.push({ path: '/resource/exportTaskList' });
            }
          }).catch(() => {});
        }
      }).catch({});
    },
    /**
     * 差异明细
     */
    cellDiffClick(row, pOrd) {
      this.queryFormMX = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          tagNumber: row.tagNumber,
          operationType: 2,
          nextSiteId: row.nextSiteId
        }
      };
      this.changeVal = 'mxVal';
      if (pOrd === 'p') {
        this.bagDiff = 1;
        this.mxIndex = 1;
        voteDiffPage(this.queryFormMX).then(res => {
          if (res.status === 'OK') {
            this.tableDateMX = res.data.records;
            this.totalMX = res.data.total;
          }
        }).catch({});
      }
      if (pOrd === 'd') {
        this.bagDiff = 2;
        this.mxIndex = 0;
        bagDiffPage(this.queryFormMX).then(res => {
          if (res.status === 'OK') {
            this.tableDateMX = res.data.records;
            this.totalMX = res.data.total;
          }
        }).catch({});
      }
    },
    // 汇总跳转明细
    cellClick(row, type) {
      this.bagDiff = null;
      this.mxIndex = 0;
      this.queryFormMX.pageNumber = 1;
      this.queryFormMX.pageSize = 10;
      this.queryFormMX.params.tagNumber = row.tagNumber;
      this.queryFormMX.params.operationType = type;
      this.queryFormMX.params.nextSiteId = row.nextSiteId;
      this.changeVal = 'mxVal';
      this.getTablePageMX();
    },
    cellPClick(row, type) {
      this.bagDiff = null;
      this.mxIndex = 1;
      this.queryFormMX.pageNumber = 1;
      this.queryFormMX.pageSize = 10;
      // 总票数
      this.queryFormMX.params.tagNumber = row.tagNumber;
      this.queryFormMX.params.operationType = type;
      this.queryFormMX.params.nextSiteId = row.nextSiteId;
      this.changeVal = 'mxVal';
      collectVehicleVotePage(this.queryFormMX).then(res => {
        if (res.status === 'OK') {
          this.tableDateMX = res.data.records;
          this.totalMX = res.data.total;
        }
      }).catch({});
    },
    getTablePageMX() {
      if (this.bagDiff === 1) {
        // 票差异
        voteDiffPage(this.queryFormMX).then(res => {
          if (res.status === 'OK') {
            this.tableDateMX = res.data.records;
            this.totalMX = res.data.total;
          }
        }).catch({});
      } else if (this.bagDiff === 2) {
        // 袋子差异
        bagDiffPage(this.queryFormMX).then(res => {
          if (res.status === 'OK') {
            this.tableDateMX = res.data.records;
            this.totalMX = res.data.total;
          }
        }).catch({});
      } else if (this.mxIndex === 0) {
        // 袋数
        collectVehicleScanPage(this.queryFormMX).then(res => {
          if (res.status === 'OK') {
            this.tableDateMX = res.data.records;
            this.totalMX = res.data.total;
          }
        }).catch({});
      } else if (this.mxIndex === 1) {
        // 票数
        collectVehicleVotePage(this.queryFormMX).then(res => {
          if (res.status === 'OK') {
            this.tableDateMX = res.data.records;
            this.totalMX = res.data.total;
          }
        }).catch({});
      }
    },
    getPageSizeMX() {
      this.queryFormMX.pageNumber = 1;
      this.getTablePageMX();
    },
    getSummariesMX(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('collectionCenter.all');
          return;
        }
        if (column.label === this.$t('collectionCenter.InPocketTotal')) {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += '';
          } else {
            sums[index] = '';
          }
        }
      });
      return sums;
    },
    downloadAllMX() {
      this.isClickAll2 = true;
      // mxIndex: 0, // 0 展示袋数明细  1 展示票数明细
      if (this.bagDiff === 2) {
        bagDiffPageExport(this.queryFormMX).then(res => {
          if (res.status === 'OK') {
            this.isClickAll2 = false;
            const h = this.$createElement;
            this.$msgbox({
              // '提示'
              title: this.$t('collectionCenter.tips'),
              message: h('p', null, [
                // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源任务列表中下载，'
                h('span', this.$t('collectionCenter.exportChangeTip')),
                // '请勿重复导出！'
                h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
              ]),
              showCancelButton: true,
              // '立即前往'
              confirmButtonText: this.$t('collectionCenter.Immediately'),
              // '关闭'
              cancelButtonText: this.$t('collectionCenter.close')
            }).then(action => {
              if (action === 'confirm') {
                this.$router.push({ path: '/resource/exportTaskList' });
              }
            }).catch(() => {});
          }
        }).catch({});
      } else {
        if (this.mxIndex === 0) {
          carDetailExportAll(this.queryFormMX).then(res => {
            if (res.status === 'OK') {
              this.isClickAll2 = false;
              const h = this.$createElement;
              this.$msgbox({
                // '提示'
                title: this.$t('collectionCenter.tips'),
                message: h('p', null, [
                  // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源任务列表中下载，'
                  h('span', this.$t('collectionCenter.exportChangeTip')),
                  // '请勿重复导出！'
                  h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
                ]),
                showCancelButton: true,
                // '立即前往'
                confirmButtonText: this.$t('collectionCenter.Immediately'),
                // '关闭'
                cancelButtonText: this.$t('collectionCenter.close')
              }).then(action => {
                if (action === 'confirm') {
                  this.$router.push({ path: '/resource/exportTaskList' });
                }
              }).catch(() => {});
            }
          }).catch({});
        }
        if (this.mxIndex === 1) {
          carVoteDetailExportAll(this.queryFormMX).then(res => {
            if (res.status === 'OK') {
              this.isClickAll2 = false;
              const h = this.$createElement;
              this.$msgbox({
                // '提示'
                title: this.$t('collectionCenter.tips'),
                message: h('p', null, [
                  // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源任务列表中下载，'
                  h('span', this.$t('collectionCenter.exportChangeTip')),
                  // '请勿重复导出！'
                  h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
                ]),
                showCancelButton: true,
                // '立即前往'
                confirmButtonText: this.$t('collectionCenter.Immediately'),
                // '关闭'
                cancelButtonText: this.$t('collectionCenter.close')
              }).then(action => {
                if (action === 'confirm') {
                  this.$router.push({ path: '/resource/exportTaskList' });
                }
              }).catch(() => {});
            }
          }).catch({});
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>

  // 防止按钮重复点击
  @keyframes throttle{
    from{
      filter: opacity(40%);
      pointer-events: none;
    }
    to{
      pointer-events: all;
    }
  }
  .onlyClickOnce2{
    animation: throttle 2s step-end forwards;
  }
  .onlyClickOnce2:active{
    filter: opacity(40%);
    animation: none;
  }
  .onlyClickOnce10{
    animation: throttle 10s step-end forwards;
  }
  .onlyClickOnce10:active{
    filter: opacity(40%);
    animation: none;
  }
  .timeClass .delMl ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
  .col2 {
    width: auto !important;
  }
  .colWill{
    //width: calc(100% - 200px);
    width: auto;
  }
  .colWill ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
  .autoWidth ::v-deep .el-input__prefix{ // 下拉框自适应占位
    position: relative;
    left: 0;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    padding: 0 25px;
    padding-left: 0;
    margin-left: 20px;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    visibility: hidden;
    white-space: nowrap;
  }
  .autoWidth ::v-deep input {
    position: absolute;
  }
  .selectTimeClass ::v-deep.el-input--suffix .el-input__inner{
    border: none !important;
    font-size: 14px;
    color: #606266;
    font-weight: bold;
    text-align: right;
    padding: 0 25px;
    padding-left: 0;
  }
  .selectClass .textarea ::v-deep .el-textarea__inner{
    max-height: 150px;
  }
  .selectClass ::v-deep .el-form-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .selectClass ::v-deep .el-form-item__label{
    padding: 0;
    line-height: 16px;
    white-space: nowrap;
    margin-left: 20px;
  }
  .selectClass ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }

  .selectClass ::v-deep textarea.el-textarea__inner {
    min-height: 40px !important;
    max-height: 200px !important;
    line-height: 30px;
  }

  .Box{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
</style>
