var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "signForBox" }, [
    _c("div", [
      _c(
        "div",
        { staticClass: "scanClass" },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("operatingPlatform.signForScan")))]),
          _c("el-input", {
            staticClass: "iconBtn",
            attrs: {
              placeholder: _vm.$t("operatingPlatform.PleaseScan"),
              "prefix-icon": "el-icon-full-screen",
              clearable: "",
            },
            on: { blur: _vm.getrecipient },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.getTable($event)
              },
            },
            model: {
              value: _vm.scanForm.signForScan,
              callback: function ($$v) {
                _vm.$set(
                  _vm.scanForm,
                  "signForScan",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "scanForm.signForScan",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "scanClass" }, [
        _c("span", { staticClass: "requiredClass" }, [
          _vm._v(_vm._s(_vm.$t("operatingPlatform.receivingType"))),
        ]),
        _c(
          "div",
          { staticClass: "box" },
          [
            _c(
              "el-select",
              {
                attrs: {
                  placeholder: _vm.$t("collectionCenter.pleaseChoose"),
                  filterable: "",
                },
                on: { change: _vm.changeSignType },
                model: {
                  value: _vm.scanForm.signType,
                  callback: function ($$v) {
                    _vm.$set(_vm.scanForm, "signType", $$v)
                  },
                  expression: "scanForm.signType",
                },
              },
              [
                _c("el-option", {
                  attrs: {
                    label: _vm.$t("operatingPlatform.inPerson"),
                    value: 0,
                  },
                }),
                _c("el-option", {
                  attrs: {
                    label: _vm.$t("operatingPlatform.notOneself"),
                    value: 1,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "scanClass" }, [
        _c("span", { staticClass: "requiredClass" }, [
          _vm._v(_vm._s(_vm.$t("operatingPlatform.recipient"))),
        ]),
        _c(
          "div",
          { staticClass: "box" },
          [
            _c("el-input", {
              attrs: {
                disabled: _vm.scanForm.signType == 0,
                placeholder: _vm.$t("collectionCenter.pleaseInput"),
                clearable: "",
              },
              on: { input: _vm.changeMessage, change: _vm.changeMessage },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.getTable($event)
                },
              },
              model: {
                value: _vm.recipient,
                callback: function ($$v) {
                  _vm.recipient = $$v
                },
                expression: "recipient",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "scanClass" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("operatingPlatform.file")))]),
        _c(
          "div",
          { staticClass: "box fileClass" },
          [
            _c("el-input", {
              attrs: {
                placeholder: _vm.$t("operatingPlatform.pleaseInput"),
                clearable: "",
                maxlength: "255",
                "show-word-limit": "",
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.getTable($event)
                },
              },
              model: {
                value: _vm.scanForm.signFile,
                callback: function ($$v) {
                  _vm.$set(_vm.scanForm, "signFile", $$v)
                },
                expression: "scanForm.signFile",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "uploadBtn" },
      [
        _c("el-button", { on: { click: _vm.signature } }, [
          _vm._v(_vm._s(_vm.$t("operatingPlatform.signature"))),
        ]),
        _c(
          "div",
          { staticClass: "uploadClass" },
          [
            _c(
              "el-upload",
              {
                ref: "resourcesUp",
                attrs: {
                  action: "",
                  "before-upload": _vm.beforeUpload,
                  "http-request": _vm.imgUpload,
                  "on-remove": _vm.handleRemove,
                  multiple: "",
                  "file-list": _vm.fileList,
                  "show-file-list": false,
                },
              },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-right": "5px" },
                    attrs: { slot: "trigger", type: "primary" },
                    slot: "trigger",
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.enclosureFJ")))]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "tableClass" },
      [
        _c(
          "el-table",
          { attrs: { "max-height": 600, data: _vm.scanTableData, border: "" } },
          [
            _c("el-table-column", {
              attrs: {
                prop: "wayBillNumber",
                align: "center",
                label: _vm.$t("operatingPlatform.waybillNumber"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "signType",
                align: "center",
                label: _vm.$t("operatingPlatform.receivingT"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "signUserName",
                align: "center",
                label: _vm.$t("operatingPlatform.addressee"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "scanTime",
                align: "center",
                label: _vm.$t("operatingPlatform.scanTime"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: _vm.$t("operatingPlatform.operation"),
                fixed: "right",
                width: "100",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-popconfirm",
                        {
                          attrs: {
                            title: _vm.$t("operatingPlatform.deleteSure"),
                          },
                          on: {
                            confirm: function ($event) {
                              return _vm.scanDelClick(scope.row, scope.$index)
                            },
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                slot: "reference",
                                type: "danger",
                                size: "mini",
                              },
                              slot: "reference",
                            },
                            [_vm._v(_vm._s(_vm.$t("operatingPlatform.Delete")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm.fileList.length > 0
      ? _c(
          "div",
          { staticStyle: { "margin-top": "20px" } },
          [
            _vm._v(_vm._s(_vm.$t("collectionCenter.enclosure")) + " "),
            _vm._l(_vm.fileList, function (item, index) {
              return _c(
                "span",
                { key: index, staticClass: "imgClass" },
                [
                  _c("img", { attrs: { src: item.url, alt: "" } }),
                  _c("el-button", {
                    staticClass: "imgDelClass",
                    attrs: {
                      size: "mini",
                      type: "danger",
                      icon: "el-icon-delete",
                      circle: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.delImgFile(item)
                      },
                    },
                  }),
                ],
                1
              )
            }),
          ],
          2
        )
      : _vm._e(),
    _vm.signVisible
      ? _c("div", { staticClass: "signMask" }, [
          _c("div", { staticClass: "signVisibleClass" }, [
            _c("div", {
              staticClass: "el-icon-close signClose",
              on: { click: _vm.closeSign },
            }),
            _c("div", { staticClass: "descriptionsClass" }, [
              _c(
                "div",
                { ref: "image" },
                [
                  _c(
                    "el-descriptions",
                    {
                      attrs: {
                        title: "",
                        column: 1,
                        border: "",
                        "content-style": _vm.rowClass,
                        "label-style": _vm.labelRowClass,
                      },
                    },
                    [
                      _c("el-descriptions-item", { attrs: { label: "" } }, [
                        _c("div", { staticClass: "topTitle" }, [
                          _vm._v(
                            _vm._s(_vm.$t("operatingPlatform.salesmanInfo"))
                          ),
                        ]),
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "" } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("operatingPlatform.deliverer"))),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.scanEsignForm.deliverer)),
                        ]),
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "" } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("operatingPlatform.belongToSite"))
                          ),
                        ]),
                        _c("span", [_vm._v(_vm._s(_vm.scanEsignForm.dot))]),
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "" } }, [
                        _c("div", { staticClass: "topTitle" }, [
                          _vm._v(
                            _vm._s(_vm.$t("operatingPlatform.receivingInfo"))
                          ),
                        ]),
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "" } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("operatingPlatform.receivingType"))
                          ),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.scanEsignForm.signType)),
                        ]),
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "" } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("operatingPlatform.recipient"))),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.scanEsignForm.signUser)),
                        ]),
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "" } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("operatingPlatform.signTime"))),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.scanEsignForm.brazilTime)),
                        ]),
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "" } }, [
                        _c("div", { staticClass: "topTitle" }, [
                          _vm._v(_vm._s(_vm.$t("operatingPlatform.signature"))),
                        ]),
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "450px",
                              height: "205px",
                              overflow: "hidden",
                            },
                          },
                          [
                            _c("vue-esign", {
                              ref: "esign",
                              attrs: {
                                width: 455,
                                height: 205,
                                "line-width": 5,
                                "line-color": "#000",
                                "bg-color": "#FFF",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: { type: "danger" },
                      on: { click: _vm.handleReset },
                    },
                    [_vm._v(_vm._s(_vm.$t("operatingPlatform.empty")))]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "btn onlyClickOnce",
                      attrs: { disabled: _vm.signImgOk, type: "success" },
                      on: { click: _vm.handleGenerate },
                    },
                    [_vm._v(_vm._s(_vm.$t("operatingPlatform.confirm")))]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
      },
      [
        _c("audio", {
          ref: "errRef",
          attrs: { src: _vm.errorMp3, controls: "controls" },
        }),
        _c("audio", {
          ref: "okRef",
          attrs: { src: _vm.okMp3, controls: "controls" },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }