<template>
  <el-select
    v-model="val1"
    clearable
    filterable
    remote
    :remote-method="asyncQuery"
    :loading="loading"
    :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
    style="width: 300px"
    @change="onChange"
  >
    <el-option
      v-for="item in list"
      :key="item.id"
      :label="item.accountAlias"
      :value="item.id"
    />
  </el-select>
</template>

<script>
import { throttle } from 'lodash-es';
import { accountPage } from '@/api/finance/partition';

const query = {
  pageNumber: 1,
  pageSize: 20,
  params: {
    accountAlias: ''
  }
};

export default {
  name: '',

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    'value': {
      type: [Number, String], // Number: 0, String: ''
      default: 0
    },

    // 账户名称
    accountAlias: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      loading: false,
      list: []
    };
  },

  computed: {
    val1: {
      // 动态计算值
      get: function() {
        return this.value || '';
      },
      set: function(val) {
        this.$emit('change', val);
      }
    }
  },

  watch: {

    // 传入用户名称搜索
    accountAlias: {
      handler(val) {
        if (val) {
          this.asyncGetUserPage(val);
        }
      },
      immediate: true
    }
  },

  created() {
    this.asyncQuery = throttle((val) => this.asyncGetUserPage(val), 1000);

    this.asyncGetUserPage('');
  },

  methods: {
    // 获取信息
    asyncGetUserPage(val = '') {
      const data = {
        ...query
      };

      data.params.accountAlias = val;
      this.loading = true;

      accountPage(data, false).then(res => {
        const { records } = res.data;

        this.list = (records || []).map(item => {
          const { id, accountAlias, accountNumber, accountName } = item;
          const obj = {
            id,
            accountAlias, // 账户名称
            accountNumber, // 账户号码
            accountName // 户名
          };

          return Object.freeze(obj);
        });
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },

    onChange(ids) {
      this.val1 = ids;

      // const row = this.userList.find(item => {
      //   return item.id === ids;
      // });

      // if (row && row.id) {
      //   this.$emit('row', row);
      //   return;
      // }

      // this.$emit('row', null);
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
