<template>
  <div id="tagNumberPdf" style="font-size: 16px">
    <!--  头部  -->
    <div style="display: flex;justify-content: space-between">
      <div>
        <img src="@/assets/ajlogo1.svg" style="width: 120px;height: 40px;margin-left: 40px">
        <div
          style="width: 600px;word-wrap:break-word;word-break:break-all;"
        >{{ paramShippingData.providerCode ? paramShippingData.providerCode : '- -' }}
        </div>
        <div>CNPJ:{{ paramShippingData.cnpj ? paramShippingData.cnpj : '- -' }}</div>
        <div>Data de impressão:{{ generateBrazilTime() }}</div>
      </div>
      <div style="display: flex;justify-content: center">
        <img id="SHIPPING">
      </div>
    </div>
    <!--    列表-->
    <div>
      <div style="font-weight: 600;margin-bottom: 20px;margin-top: 10px">Responsible:</div>
      <!--      表格-->
      <div>
        <table
          border="1"
          cellpadding="0"
          cellspacing="0"
          style="width: 100%;text-align: center;font-size: 16px;border: 1px solid #000000;"
        >
          <thead>
            <tr style="border: 1px solid #000000;padding: 5px 0px">
              <th style="border: 1px solid #000000;padding: 5px 0px">Numero do saca</th>
              <th style="border: 1px solid #000000;padding: 5px 0px">Codlate</th>
              <th style="border: 1px solid #000000;padding: 5px 0px">Peso total(kg)</th>
              <th style="border: 1px solid #000000;padding: 5px 0px">Volumes(m3)</th>
            </tr>
          </thead>
          <tr
            v-for="(item,index) in paramShippingData.packNumListItems"
            :key="index"
            style="border: 1px solid #000000;padding: 5px 0px"
          >
            <td style="border: 1px solid #000000;padding: 5px 0px">{{ item.transitWarehouseBagNumber }}</td>
            <td style="border: 1px solid #000000;padding: 5px 0px">{{ item.baseCode }}</td>
            <td style="border: 1px solid #000000;padding: 5px 0px">{{ item.totalWeight }}</td>
            <td style="border: 1px solid #000000;padding: 5px 0px">1</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import JsBarcode from 'jsbarcode';
// import moment from 'moment';
// import 'moment-timezone';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import print from 'print-js';

dayjs.extend(utc);
dayjs.extend(timezone);

const logoSrc1 = require('@/assets/ajlogo1.svg');
export default {
  name: 'ShippingMinfistoPdf',
  props: {
    paramShippingData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      logo1: logoSrc1
    };
  },
  mounted() {
    JsBarcode('#SHIPPING', this.paramShippingData.vehicleTagNumber, {
      height: 60
    });
    if (this.paramShippingData.destinations?.length > 0) {
      this.paramShippingData.destinations.forEach((item, index) => {
        const elm = '#barcode' + index;
        if (item.mdfeKey) {
          JsBarcode(elm, item.mdfeKey, {
            height: 60,
            displayValue: false
          });
        } else {
          item.mdfeKey = '--';
        }
      });
    }
  },
  methods: {
    // 随机生成6位数据
    generateRandomNumber() {
      // 生成一个0到999999之间的随机数
      const randomNumber = Math.floor(Math.random() * 1000000);
      // 将随机数转换为字符串
      let randomString = randomNumber.toString();
      // 如果随机数不足6位，在前面补0
      while (randomString.length < 6) {
        randomString = '0' + randomString;
      }
      return randomString;
    },
    // 生成当前巴西时间，精确到秒
    generateBrazilTime() {
      // 设置时区为巴西
      // 获取当前时间
      const brazilTime = dayjs().tz('America/Sao_Paulo').format('DD-MM-YYYY HH:mm:ss');
      return brazilTime;
    },
    // 浏览器打印
    handlePrint() {
      console.log(document.title);
      const title = document.title;
      document.title = 'Lista de números de bolsas' + this.generateBrazilTime() + this.generateRandomNumber();
      print({
        printable: 'tagNumberPdf', // // 文档来源：pdf或图像的url，html元素的id或json数据的对象
        type: 'html', // 可打印的类型。可用的打印选项包括：pdf，html，图像，json和raw-html。
        targetStyles: ['*'], // 库在打印HTML元素时处理任何样式
        onPrintDialogOpen: function() {
          console.log(111);
          document.title = title;
          // 打印对话框打开时执行的回调函数
          // 可以留空或不执行任何操作
        },
        onPrintDialogClose: function() {
          console.log(222);
          document.title = title;
          // 打印对话框关闭时执行的回调函数
          // 可以留空或不执行任何操作
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
table tr th, table tr td {
  border-color: #000000;
  border: 1px solid #000000;
}
</style>
