<script >
import { defineProps } from '@vue/composition-api';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, maxLength } from '@/utils/validationErrors';
import { FormActions, FormActionsNext, FormActionsPrevious } from '@/components/wizard-form/formStepActions';
const __sfc_main = {};
__sfc_main.props = {
  isLastStep: {
    type: Boolean,
    required: true
  },
  isFirstStep: {
    type: Boolean,
    required: true
  },
  handlePrevious: {
    type: Function
  },
  handleNext: {
    type: Function,
    required: true
  },
  formData: {
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const rules = {
    name: {
      required
    },
    tradeName: {
      required
    },
    documentation: {
      required,
      minLength: minLength(14),
      maxLength: maxLength(18)
    },
    stateRegistration: {
      required,
      minLength: minLength(9),
      maxLength: maxLength(14)
    },
    comercialPhone: {
      required
    },
    contact: {
      required
    },
    cellphone: {
      required,
      minLength: minLength(18),
      maxLength: maxLength(19)
    },
    email: {
      required
    },
    contractType: {
      required
    }
  };
  const v$ = useVuelidate(rules, props.formData);
  async function handleSubmit() {
    const isValid = await v$.value.$validate();
    if (isValid) props.handleNext();
  }
  return {
    v$,
    handleSubmit
  };
};
__sfc_main.components = Object.assign({
  FormActions,
  FormActionsPrevious,
  FormActionsNext
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <form class="wrapper-form" @submit.prevent="handleSubmit">
    <o-field
      :label="$t('company.name')"
      label-for="name"
      :message="v$.name.$errors.length ? v$.name.$errors[0].$message : null"
    >
      <o-input id="name" v-model="formData.name" :placeholder="$t('company.namePlaceholder')" />
    </o-field>

    <o-field
      :label="$t('company.fantasyName')"
      label-for="tradeName"
      :message="v$.tradeName.$errors.length ? v$.tradeName.$errors[0].$message : null"
    >
      <o-input id="tradeName" v-model="formData.tradeName" :placeholder="$t('company.fantasyNamePlaceholder')" />
    </o-field>

    <o-field
      v-mask="['###.###.###-##', '##.###.###/####-##']"
      :label="$t('company.document')"
      label-for="documentation"
      :message="v$.documentation.$errors.length ? v$.documentation.$errors[0].$message : null"
    >
      <o-input id="documentation" v-model="formData.documentation" :placeholder="$t('company.documentPlaceholder')" />
    </o-field>

    <o-field
      :label="$t('company.stateRegistration')"
      label-for="stateRegistration"
      :message="v$.stateRegistration.$errors.length ? v$.stateRegistration.$errors[0].$message : null"
    >
      <o-input id="stateRegistration" v-model="formData.stateRegistration" :placeholder="$t('company.stateRegistrationPlaceholder')" />
    </o-field>

    <o-field
      v-mask="['+## (##) ####-####', '+## (##) #####-####']"
      :label="$t('company.commercialPhone')"
      label-for="comercialPhone"
      :message="v$.comercialPhone.$errors.length ? v$.comercialPhone.$errors[0].$message : null"
    >
      <o-input id="comercialPhone" v-model="formData.comercialPhone" :placeholder="$t('company.commercialPhonePlaceholder')" />
    </o-field>

    <o-field
      :label="$t('company.contact')"
      label-for="contact"
      :message="v$.contact.$errors.length ? v$.contact.$errors[0].$message : null"
    >
      <o-input id="contact" v-model="formData.contact" :placeholder="$t('company.contactPlaceholder')" />
    </o-field>

    <o-field
      v-mask="['+## (##) ####-####', '+## (##) #####-####']"
      :label="$t('company.cellPhone')"
      label-for="cellphone"
      :message="v$.cellphone.$errors.length ? v$.cellphone.$errors[0].$message : null"
    >
      <o-input id="cellphone" v-model="formData.cellphone" :placeholder="$t('company.cellPhonePlaceholder')" />
    </o-field>

    <o-field
      :label="$t('company.email')"
      label-for="email"
      :message="v$.email.$errors.length ? v$.email.$errors[0].$message : null"
    >
      <o-input id="email" v-model="formData.email" :placeholder="$t('company.emailPlaceholder')" />
    </o-field>

    <div>
      <o-checkbox v-model="formData.contractType">
        {{ $t('company.contractType') }}
      </o-checkbox>
    </div>

    <FormActions>
      <FormActionsPrevious :is-first-step="isFirstStep" @handlePrevious="handlePrevious" />
      <FormActionsNext :is-last-step="isLastStep" />
    </FormActions>
  </form>
</template>

<style scoped>
.wrapper-form {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem/* 16px */;
  padding-top: 0.5rem/* 8px */;
  padding-bottom: 0.5rem/* 8px */;
}

</style>
