var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    { ref: "elTable", attrs: { border: "", data: _vm.tableData } },
    [
      _c(
        "el-table-column",
        {
          attrs: {
            label:
              _vm.name !== "szanjun"
                ? _vm.$t("newOrder.pickUpDate")
                : _vm.$t("newOrder.TransitInboundTime"),
            align: "center",
            width: "200px",
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (scope) {
                return [_c("span", [_vm._v(_vm._s(scope.row.date))])]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "header" },
            [
              _vm.name !== "szanjun"
                ? [
                    _c("p", [
                      _vm._v(_vm._s(_vm.$t("newOrder.pickUpDate", "zh"))),
                    ]),
                    _c("p", [
                      _vm._v(_vm._s(_vm.$t("newOrder.pickUpDate", "pu"))),
                    ]),
                  ]
                : [
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.$t("newOrder.TransitInboundTime", "zh"))
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.$t("newOrder.TransitInboundTime", "pu"))
                      ),
                    ]),
                  ],
            ],
            2
          ),
        ],
        2
      ),
      _c(
        "el-table-column",
        {
          attrs: {
            label:
              _vm.name !== "szanjun"
                ? _vm.$t("newOrder.pickUpDay")
                : _vm.$t("newOrder.transitWarehouseWeek"),
            width: "170px",
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (scope) {
                return [
                  _c("span", [_vm._v(_vm._s(_vm.weekObj[scope.row.week]))]),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "header" },
            [
              _vm.name !== "szanjun"
                ? [
                    _c("p", [
                      _vm._v(_vm._s(_vm.$t("newOrder.pickUpDay", "zh"))),
                    ]),
                    _c("p", [
                      _vm._v(_vm._s(_vm.$t("newOrder.pickUpDay", "pu"))),
                    ]),
                  ]
                : [
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.$t("newOrder.transitWarehouseWeek", "zh"))
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.$t("newOrder.transitWarehouseWeek", "pu"))
                      ),
                    ]),
                  ],
            ],
            2
          ),
        ],
        2
      ),
      _c(
        "el-table-column",
        {
          attrs: { label: _vm.$t("newOrder.orderVolume"), width: "130px" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (scope) {
                return [_c("span", [_vm._v(_vm._s(scope.row.createTotal))])]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("newOrder.orderVolume", "zh")))]),
            _c("p", [_vm._v(_vm._s(_vm.$t("newOrder.orderVolume", "pu")))]),
          ]),
        ],
        2
      ),
      _c(
        "el-table-column",
        {
          attrs: { label: _vm.$t("newOrder.pickUpVolume"), width: "140px" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (scope) {
                return [_c("span", [_vm._v(_vm._s(scope.row.collectTotal))])]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("newOrder.pickUpVolume", "zh")))]),
            _c("p", [_vm._v(_vm._s(_vm.$t("newOrder.pickUpVolume", "pu")))]),
          ]),
        ],
        2
      ),
      _c(
        "el-table-column",
        {
          attrs: { label: _vm.$t("newOrder.D0deliveryVolume"), width: "200px" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (scope) {
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        scope.row.zeroDayPer === "-" ? "-" : scope.row.zeroDay
                      )
                    ),
                  ]),
                ]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _c("p", [
              _vm._v(_vm._s(_vm.$t("newOrder.D0deliveryVolume", "zh"))),
            ]),
            _c("p", [
              _vm._v(_vm._s(_vm.$t("newOrder.D0deliveryVolume", "pu"))),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "el-table-column",
        {
          attrs: { label: _vm.$t("newOrder.D0DeliveryRate"), width: "210px" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (scope) {
                return [_c("span", [_vm._v(_vm._s(scope.row.zeroDayPer))])]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("newOrder.D0DeliveryRate", "zh")))]),
            _c("p", [_vm._v(_vm._s(_vm.$t("newOrder.D0DeliveryRate", "pu")))]),
          ]),
        ],
        2
      ),
      _c(
        "el-table-column",
        {
          attrs: { label: _vm.$t("newOrder.D1deliveryVolume"), width: "200px" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (scope) {
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        scope.row.firstDayPer === "-" ? "-" : scope.row.firstDay
                      )
                    ),
                  ]),
                ]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _c("p", [
              _vm._v(_vm._s(_vm.$t("newOrder.D1deliveryVolume", "zh"))),
            ]),
            _c("p", [
              _vm._v(_vm._s(_vm.$t("newOrder.D1deliveryVolume", "pu"))),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "el-table-column",
        {
          attrs: { label: _vm.$t("newOrder.D1DeliveryRate"), width: "210px" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (scope) {
                return [_c("span", [_vm._v(_vm._s(scope.row.firstDayPer))])]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("newOrder.D1DeliveryRate", "zh")))]),
            _c("p", [_vm._v(_vm._s(_vm.$t("newOrder.D1DeliveryRate", "pu")))]),
          ]),
        ],
        2
      ),
      _c(
        "el-table-column",
        {
          attrs: { label: _vm.$t("newOrder.D2deliveryVolume"), width: "200px" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (scope) {
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        scope.row.secondDayPer === "-"
                          ? "-"
                          : scope.row.secondDay
                      )
                    ),
                  ]),
                ]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _c("p", [
              _vm._v(_vm._s(_vm.$t("newOrder.D2deliveryVolume", "zh"))),
            ]),
            _c("p", [
              _vm._v(_vm._s(_vm.$t("newOrder.D2deliveryVolume", "pu"))),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "el-table-column",
        {
          attrs: { label: _vm.$t("newOrder.D2DeliveryRate"), width: "210px" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (scope) {
                return [_c("span", [_vm._v(_vm._s(scope.row.secondDayPer))])]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("newOrder.D2DeliveryRate", "zh")))]),
            _c("p", [_vm._v(_vm._s(_vm.$t("newOrder.D2DeliveryRate", "pu")))]),
          ]),
        ],
        2
      ),
      _c(
        "el-table-column",
        {
          attrs: { label: _vm.$t("newOrder.D3deliveryVolume"), width: "200px" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (scope) {
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        scope.row.thirdDayPer === "-" ? "-" : scope.row.thirdDay
                      )
                    ),
                  ]),
                ]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _c("p", [
              _vm._v(_vm._s(_vm.$t("newOrder.D3deliveryVolume", "zh"))),
            ]),
            _c("p", [
              _vm._v(_vm._s(_vm.$t("newOrder.D3deliveryVolume", "pu"))),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "el-table-column",
        {
          attrs: { label: _vm.$t("newOrder.D3DeliveryRate"), width: "210px" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (scope) {
                return [_c("span", [_vm._v(_vm._s(scope.row.thirdDayPer))])]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("newOrder.D3DeliveryRate", "zh")))]),
            _c("p", [_vm._v(_vm._s(_vm.$t("newOrder.D3DeliveryRate", "pu")))]),
          ]),
        ],
        2
      ),
      _c(
        "el-table-column",
        {
          attrs: { label: _vm.$t("newOrder.D4deliveryVolume"), width: "200px" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (scope) {
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        scope.row.fourthDayPer === "-"
                          ? "-"
                          : scope.row.fourthDay
                      )
                    ),
                  ]),
                ]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _c("p", [
              _vm._v(_vm._s(_vm.$t("newOrder.D4deliveryVolume", "zh"))),
            ]),
            _c("p", [
              _vm._v(_vm._s(_vm.$t("newOrder.D4deliveryVolume", "pu"))),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "el-table-column",
        {
          attrs: { label: _vm.$t("newOrder.D4DeliveryRate"), width: "210px" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (scope) {
                return [_c("span", [_vm._v(_vm._s(scope.row.fourthDayPer))])]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("newOrder.D4DeliveryRate", "zh")))]),
            _c("p", [_vm._v(_vm._s(_vm.$t("newOrder.D4DeliveryRate", "pu")))]),
          ]),
        ],
        2
      ),
      _c(
        "el-table-column",
        {
          attrs: { label: _vm.$t("newOrder.D5deliveryVolume"), width: "200px" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (scope) {
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        scope.row.fifthDayPer === "-" ? "-" : scope.row.fifthDay
                      )
                    ),
                  ]),
                ]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _c("p", [
              _vm._v(_vm._s(_vm.$t("newOrder.D5deliveryVolume", "zh"))),
            ]),
            _c("p", [
              _vm._v(_vm._s(_vm.$t("newOrder.D5deliveryVolume", "pu"))),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "el-table-column",
        {
          attrs: { label: _vm.$t("newOrder.D5DeliveryRate"), width: "210px" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (scope) {
                return [_c("span", [_vm._v(_vm._s(scope.row.fifthDayPer))])]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("newOrder.D5DeliveryRate", "zh")))]),
            _c("p", [_vm._v(_vm._s(_vm.$t("newOrder.D5DeliveryRate", "pu")))]),
          ]),
        ],
        2
      ),
      _c(
        "el-table-column",
        {
          attrs: { label: _vm.$t("newOrder.D6deliveryVolume"), width: "200px" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (scope) {
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        scope.row.sixthDayPer === "-" ? "-" : scope.row.sixthDay
                      )
                    ),
                  ]),
                ]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _c("p", [
              _vm._v(_vm._s(_vm.$t("newOrder.D6deliveryVolume", "zh"))),
            ]),
            _c("p", [
              _vm._v(_vm._s(_vm.$t("newOrder.D6deliveryVolume", "pu"))),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "el-table-column",
        {
          attrs: { label: _vm.$t("newOrder.D6DeliveryRate"), width: "210px" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (scope) {
                return [_c("span", [_vm._v(_vm._s(scope.row.sixthDayPer))])]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("newOrder.D6DeliveryRate", "zh")))]),
            _c("p", [_vm._v(_vm._s(_vm.$t("newOrder.D6DeliveryRate", "pu")))]),
          ]),
        ],
        2
      ),
      _c(
        "el-table-column",
        {
          attrs: { label: _vm.$t("newOrder.D7deliveryVolume"), width: "200px" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (scope) {
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        scope.row.seventhDayPer === "-"
                          ? "-"
                          : scope.row.seventhDay
                      )
                    ),
                  ]),
                ]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _c("p", [
              _vm._v(_vm._s(_vm.$t("newOrder.D7deliveryVolume", "zh"))),
            ]),
            _c("p", [
              _vm._v(_vm._s(_vm.$t("newOrder.D7deliveryVolume", "pu"))),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "el-table-column",
        {
          attrs: { label: _vm.$t("newOrder.D7DeliveryRate"), width: "210px" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (scope) {
                return [_c("span", [_vm._v(_vm._s(scope.row.seventhDayPer))])]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("newOrder.D7DeliveryRate", "zh")))]),
            _c("p", [_vm._v(_vm._s(_vm.$t("newOrder.D7DeliveryRate", "pu")))]),
          ]),
        ],
        2
      ),
      _c(
        "el-table-column",
        {
          attrs: { label: _vm.$t("newOrder.D8deliveryVolume"), width: "200px" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (scope) {
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        scope.row.eighthDayPer === "-"
                          ? "-"
                          : scope.row.eighthDay
                      )
                    ),
                  ]),
                ]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _c("p", [
              _vm._v(_vm._s(_vm.$t("newOrder.D8deliveryVolume", "zh"))),
            ]),
            _c("p", [
              _vm._v(_vm._s(_vm.$t("newOrder.D8deliveryVolume", "pu"))),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "el-table-column",
        {
          attrs: { label: _vm.$t("newOrder.D8DeliveryRate"), width: "210px" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (scope) {
                return [_c("span", [_vm._v(_vm._s(scope.row.eighthDayPer))])]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("newOrder.D8DeliveryRate", "zh")))]),
            _c("p", [_vm._v(_vm._s(_vm.$t("newOrder.D8DeliveryRate", "pu")))]),
          ]),
        ],
        2
      ),
      _c(
        "el-table-column",
        {
          attrs: {
            label: _vm.$t("newOrder.deliverySuccessRate"),
            width: "160px",
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (scope) {
                return [_c("span", [_vm._v(_vm._s(scope.row.completedPer))])]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _c("p", [
              _vm._v(_vm._s(_vm.$t("newOrder.deliverySuccessRate", "zh"))),
            ]),
            _c("p", [
              _vm._v(_vm._s(_vm.$t("newOrder.deliverySuccessRate", "pu"))),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "el-table-column",
        {
          attrs: { label: _vm.$t("newOrder.undeliveredCount"), width: "180px" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (scope) {
                return [_c("span", [_vm._v(_vm._s(scope.row.unCompleted))])]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _c("p", [
              _vm._v(_vm._s(_vm.$t("newOrder.undeliveredCount", "zh"))),
            ]),
            _c("p", [
              _vm._v(_vm._s(_vm.$t("newOrder.undeliveredCount", "pu"))),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }