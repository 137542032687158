import axios from 'axios';

import { getToken } from '@/utils/auth';
import { addingLoading, closeLoading } from '@/utils/handleControlLoading';

// resource focused on transport data
export const requestScripting = axios.create({
  baseURL: process.env.VUE_APP_ROUTE_GUIDE,
  headers: {
    Authorization: `Bearer ${getToken()}`
  },
  timeout: 180000
});

// resource single scan
const requestSingleScan = axios.create({
  baseURL: process.env.VUE_APP_BASE_API5,
  headers: {
    Authorization: `Bearer ${getToken()}`
  },
  timeout: 180000
});

requestSingleScan.interceptors.request.use(config => {
  closeLoading();
  addingLoading();
  return config;
});

requestSingleScan.interceptors.response.use((response) => {
  closeLoading();
  return response;
}, error => {
  closeLoading();
  return Promise.reject(error);
});

// resource focused on reverse
export const requestReverse = axios.create({
  baseURL: process.env.VUE_APP_REVERSE_PANINI,
  headers: {
    Authorization: `Bearer ${getToken()}`
  }
});

// resource focused on zip code search
const requestZipCode = axios.create({
  baseURL: process.env.VUE_APP_ZIP_CODE,
  headers: {
    Authorization: `Bearer ${process.env.VUE_APP_TOKEN_ZIP_CODE}`
  },
  timeout: 180000
});

requestZipCode.interceptors.request.use(config => {
  addingLoading();
  return config;
});

requestZipCode.interceptors.response.use((response) => {
  closeLoading();
  return response;
}, error => {
  closeLoading();
  return Promise.reject(error);
});

// resource cte
const apiCTE = axios.create({
  baseURL: process.env.VUE_APP_CTE_BASE_URL,
  headers: {
    Authorization: `Bearer ${process.env.VUE_APP_TOKEN_CTE}`
  },
  timeout: 580000
});

// resource mdfe
const apiMDFE = axios.create({
  baseURL: process.env.VUE_APP_MDFE_EMITER,
  headers: {
    Authorization: `Bearer ${process.env.VUE_APP_TOKEN_MDFE}`
  },
  timeout: 580000
});

export { requestZipCode, requestSingleScan, apiCTE, apiMDFE };
