var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              key: 0,
              attrs: {
                label: _vm.$t("GlobalSetObj.BagNumbeDetail"),
                name: "first",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    height: "calc(100vh - 145px)",
                    overflow: "hidden auto",
                    padding: "0 10px",
                  },
                },
                [
                  _c(
                    "el-form",
                    { attrs: { "label-position": "top" } },
                    [
                      _c(
                        "el-descriptions",
                        {
                          attrs: {
                            colon: false,
                            column: 4,
                            direction: "vertical",
                          },
                        },
                        [
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: {
                                label: _vm.$t("GlobalSetObj.totalSingNumber"),
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.totalSingleObject.instoreBigBagNo ||
                                      "--"
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: {
                                label: _vm.$t("GlobalSetObj.fuwushang"),
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.totalSingleObject.systemName || "--"
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: {
                                label: _vm.$t("GlobalSetObj.DeliveryPoint"),
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.totalSingleObject.deliveryStationName ||
                                      "--"
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: {
                                label: _vm.$t("placeOrder.totalWeight"),
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(_vm._s(_vm.sumTotalWeight || "--")),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { margin: "20px 0" } },
                    [
                      _c(
                        "el-form",
                        {
                          attrs: { "label-width": "auto" },
                          nativeOn: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("GlobalSetObj.bagNumber"),
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "GlobalSetObj.pleaseInput"
                                          ),
                                          clearable: "",
                                          size: "small",
                                        },
                                        nativeOn: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.getSearchData(1)
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.queryForm1.params
                                              .transitBagNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryForm1.params,
                                              "transitBagNumber",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "queryForm1.params.transitBagNumber",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                size: "small",
                                icon: "el-icon-search",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.getSearchData(1)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("operationCenter.Procurar")))]
                          ),
                          _vm.assemblyStatus === 1
                            ? _c(
                                "div",
                                { staticStyle: { display: "inline-block" } },
                                [
                                  _c("el-input", {
                                    staticStyle: {
                                      display: "inline-block",
                                      width: "260px",
                                      margin: "0 10px 0 20px",
                                    },
                                    attrs: {
                                      size: "small",
                                      placeholder: _vm.$t(
                                        "collectionCenter.scanBag"
                                      ),
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.addTableData()
                                      },
                                    },
                                    model: {
                                      value: _vm.scanTransitBagNumber,
                                      callback: function ($$v) {
                                        _vm.scanTransitBagNumber = $$v
                                      },
                                      expression: "scanTransitBagNumber",
                                    },
                                  }),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addTableData()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("operationCenter.add"))
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "small", type: "warning" },
                                      on: { click: _vm.loadingStorage },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("operationCenter.stowageSave")
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-button",
                            {
                              staticStyle: { float: "right" },
                              attrs: { size: "small" },
                              on: { click: _vm.exportTransitBagList },
                            },
                            [_vm._v(_vm._s(_vm.$t("operationCenter.exports")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-top": "10px" } },
                    [
                      _c("AnjunTableList", {
                        ref: "PackageList1",
                        attrs: {
                          "has-operation": true,
                          "header-list": _vm.TableHeaderList1,
                          "is-select-btn": true,
                          "is-tip": true,
                          "op-width": 200,
                          "table-data": _vm.TableData1,
                        },
                        on: {
                          selectTable: _vm.selectTable,
                          "on-delStr": _vm.onDel,
                        },
                      }),
                      _c("el-pagination", {
                        staticStyle: {
                          margin: "10px 0",
                          "text-align": "right",
                        },
                        attrs: {
                          "current-page": _vm.queryForm1.pageNumber,
                          "page-size": _vm.queryForm1.pageSize,
                          "page-sizes": [10, 20, 50, 100],
                          total: _vm.queryForm1.total,
                          background: "",
                          layout: "total, sizes, prev, pager, next, jumper",
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.handleCurrentChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-tab-pane",
            {
              key: 1,
              attrs: {
                label: _vm.$t("GlobalSetObj.DetailOfwaybill"),
                name: "second",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    height: "calc(100vh - 145px)",
                    overflow: "hidden auto",
                    padding: "0 10px",
                  },
                },
                [
                  _c(
                    "el-form",
                    { attrs: { "label-position": "top" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 9 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "searchType" } },
                                [
                                  _c("template", { slot: "label" }, [
                                    _c(
                                      "div",
                                      { staticClass: "selectLabel" },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              placeholder: _vm.$t(
                                                "GlobalSetObj.pleaseChoose"
                                              ),
                                            },
                                            model: {
                                              value:
                                                _vm.queryForm1.params
                                                  .searchType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.queryForm1.params,
                                                  "searchType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "queryForm1.params.searchType",
                                            },
                                          },
                                          [
                                            _c("el-option", {
                                              key: "1",
                                              attrs: {
                                                label: _vm.$t(
                                                  "newOrder.waybillNumber"
                                                ),
                                                value: 1,
                                              },
                                            }),
                                            _c("el-option", {
                                              key: "2",
                                              attrs: {
                                                label: _vm.$t(
                                                  "placeOrder.customerNumber"
                                                ),
                                                value: 2,
                                              },
                                            }),
                                            _c("el-option", {
                                              key: "3",
                                              attrs: {
                                                label: _vm.$t(
                                                  "GlobalSetObj.serviceLogistics"
                                                ),
                                                value: 3,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "operationCenter.Multiple200"
                                      ),
                                      clearable: "",
                                      type: "textarea",
                                      rows: 2,
                                    },
                                    model: {
                                      value: _vm.queryForm1.params.inputValue,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryForm1.params,
                                          "inputValue",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "queryForm1.params.inputValue",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticStyle: { height: "80px" },
                              attrs: { span: 9 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("GlobalSetObj.bagNumber"),
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "GlobalSetObj.pleaseInput"
                                      ),
                                      clearable: "",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.getSearch1(1)
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.queryForm1.params.transitBagNumber1,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryForm1.params,
                                          "transitBagNumber1",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "queryForm1.params.transitBagNumber1",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "25px" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.getSearch1(1)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("GlobalSetObj.search")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("AnjunTableList", {
                        ref: "PackageList",
                        attrs: {
                          "has-operation": false,
                          "header-list": _vm.TableHeaderList12,
                          "is-select-btn": false,
                          "is-tip": true,
                          "table-data": _vm.TableData2,
                        },
                        on: { selectTable: _vm.selectTable },
                      }),
                      _c("el-pagination", {
                        staticStyle: {
                          margin: "10px 0",
                          "text-align": "right",
                        },
                        attrs: {
                          "current-page": _vm.queryForm1.pageNumber1,
                          "page-size": _vm.queryForm1.pageSize1,
                          "page-sizes": [10, 20, 50, 100],
                          total: _vm.queryForm1.total1,
                          background: "",
                          layout: "total, sizes, prev, pager, next, jumper",
                        },
                        on: {
                          "size-change": _vm.handleSizeChange1,
                          "current-change": _vm.handleCurrentChange1,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            visible: _vm.manualDeclarationDialog,
            width: "25%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.manualDeclarationDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "25px", "font-size": "16px" } },
            [_vm._v(_vm._s(_vm.$t("GlobalSetObj.ImmediateDeclaration")))]
          ),
          _c("p", { staticStyle: { color: "#a4a7ab" } }, [
            _vm._v(_vm._s(_vm.$t("GlobalSetObj.AutomaticFiltration"))),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.manualDeclarationDialog = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmDeclaration },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }