var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          directives: [
            {
              name: "permit",
              rawName: "v-permit:remove",
              value: _vm.permission,
              expression: "permission",
              arg: "remove",
            },
          ],
          staticStyle: { "margin-right": "10px" },
          attrs: { size: "small" },
          on: { click: _vm.open },
        },
        [_vm._v(_vm._s(_vm.$t("receivablePayable.remark")))]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            title: _vm.$t("receivablePayable.AddANote"),
            visible: _vm.isBool,
            width: "500px",
            loading: _vm.loading,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isBool = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "10px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "remarks" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                      autosize: { minRows: 5, maxRows: 10 },
                    },
                    model: {
                      value: _vm.ruleForm.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "remarks", $$v)
                      },
                      expression: "ruleForm.remarks",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.isBool = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("basicData.Cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("basicData.determine")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }