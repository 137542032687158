var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticClass: "mt-20", attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c("span", { staticClass: "clearfix" }, [
                    _vm._v(
                      _vm._s(_vm.$t("newOrder.SortingCenterOperationData"))
                    ),
                  ]),
                  _vm.segmentMetrics && _vm.segmentMetrics.length
                    ? _c(
                        "el-row",
                        {
                          staticStyle: { "margin-top": "20px" },
                          attrs: { gutter: 20 },
                        },
                        _vm._l(_vm.segmentMetrics, function (item, index) {
                          return _c(
                            "el-col",
                            {
                              key: index,
                              attrs: { span: 4, md: 6, sm: 8, lg: 4 },
                            },
                            [
                              _c("div", { staticClass: "segment-item" }, [
                                _c("div", { staticClass: "segment-title" }, [
                                  _vm._v(_vm._s(item.operateSiteName)),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "center",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "segment-item-box" },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "segment-item-box-title",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("GlobalSetObj.arrive")
                                                )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "segment-value" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatNumber")(
                                                  item.leaveCount
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "segment-item-box" },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "segment-item-box-title",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("GlobalSetObj.sender")
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "segment-subvalue" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatNumber")(
                                                  item.arriveCount
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        }),
                        1
                      )
                    : _c("el-empty", { attrs: { "image-size": 100 } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }