import { requestScripting } from '@/lib/axios';
import { removeNullParams } from '@/utils/removeNullParams';

export const transferRouterService = {
  async create(data) {
    return requestScripting({
      method: 'POST',
      url: `transfer-route`,
      data
    });
  },

  async createEquipment(data) {
    return requestScripting({
      method: 'POST',
      url: 'equipment',
      data
    });
  },

  async updateTruckPackage(data) {
    return requestScripting({
      method: 'POST',
      url: 'transfer-packages/transfer-packages',
      data
    });
  },

  async getAllTransferRoute(params, signal) {
    return requestScripting({
      method: 'GET',
      url: `transfer-route`,
      params: {
        ...removeNullParams(params),
        take: params.take ?? 10
      },
      signal
    });
  },
  getById(id) {
    return requestScripting({
      method: 'GET',
      url: `transfer-route/${id}`
    });
  },
  async delete(id) {
    return requestScripting({
      method: 'DELETE',
      url: `transfer-route/${id}`
    });
  },
  async getVtags() {
    return requestScripting({
      method: 'GET',
      url: 'transfer-packages/loadPackages'
    });
  },

  async edit(data, id) {
    return requestScripting({
      method: 'PUT',
      url: `transfer-route/${id}`,
      data
    });
  },

  async changeStatusRoute(id) {
    return requestScripting({
      method: 'PATCH',
      url: `transfer-route/updateRouteStatus/${id}`
    });
  },

  async getDataPackages(id) {
    return requestScripting({
      method: 'GET',
      url: `transfer-packages/findPackges/${id}`
    });
  }

};

