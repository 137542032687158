var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      staticStyle: { width: "100%" },
      attrs: {
        loading: _vm.loading,
        placeholder: _vm.$t("spareNumberPoo.Only10AreDisplayed"),
        clearable: "",
        filterable: "",
        remote: "",
        "remote-method": _vm.asyncGetPage,
      },
      on: { change: _vm.onChange, clear: _vm.asyncGetPage },
      model: {
        value: _vm.customerId,
        callback: function ($$v) {
          _vm.customerId = $$v
        },
        expression: "customerId",
      },
    },
    _vm._l(_vm.list, function (item) {
      return _c("el-option", {
        key: item.value,
        attrs: { label: item.label, value: item.value },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }