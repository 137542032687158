import request from '@/utils/request';
import qs from 'qs';


// 分页+查询
export const apiPostalCodeCorrectionPage = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/fa/postalCodeCorrection/page',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};

// 新增邮编/更新操作/状态变更
export const apiPostalCodeCorrectionUpdate = data => {
  return request.post('/fa/postalCodeCorrection/saveOrEdit', data);
};

