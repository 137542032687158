<template>
  <div class="bulkImportBox">
    <!-- 订单模版下载 -->
    <div style="margin-bottom:15px;line-height: 28px;color: #000;font-size: 17px;font-weight: bold;">
      {{ $t('orderImport.demoUpload') }}
    </div>
    <div class="downBtnBox">
      <div ref="download1" class="downBtn" @click="initDown()">
        <span class="el-icon-tickets" />
        <span class="downBtnText">TPL_ANJUN.xlsx</span>
        <span class="el-icon-download" />
      </div>
      <!-- <span class="el-icon-delete downDelete" /> -->
    </div>
    <div class="uploadBox">
      <!-- 运单上传 -->
      <div style="margin-bottom:15px;line-height: 28px;color: #000;font-size: 17px;font-weight: bold;">
        {{ $t('orderImport.orderUpload') }}
      </div>
      <el-upload
        ref="demoUpload"
        :before-upload="beforeUpload"
        :http-request="upLoad"
        :limit="1"
        accept=".xlsx"
        action
        class="upload-demo"
        drag
        multiple
      >
        <i class="el-icon-plus" style="margin: 50px auto 20px;" />
        <!-- 点击或拖拽文件到此处上传 -->
        <div class="el-upload__text">{{ $t('orderImport.cldrUpload') }}</div>
        <!-- * 只能上传xlsx文件 -->
        <div slot="tip" class="el-upload__tip">{{ $t('orderImport.onlyXlsx') }}</div>
      </el-upload>
    </div>
    <div style="margin-bottom:50px;">
      <!-- 客户 -->
      <div style="line-height: 28px;color: #000;font-size: 17px;font-weight: bold;margin-bottom:15px;">
        {{ $t('orderImport.client') }}
      </div>
      <el-select
        v-model="chooseCustomer" :placeholder="$t('orderImport.pleaseChoose')" clearable
        filterable
      >
        <el-option
          v-for="item in customer"
          :key="item.id"
          :label="item.username"
          :value="item.id"
        />
      </el-select>
    </div>
    <!-- 上传 -->
    <el-button class="primary" @click="handleUpload">{{ $t('orderImport.Upload') }}</el-button>
  </div>
</template>

<script>
// import XLSX from 'xlsx';
import { orderImport } from '@/api/orderApi';
import { apiCustomerPage } from '@/api/customer';
import { goExportList } from '@/utils/goExportList.js';

export default {
  data() {
    return {
      upLoadFile: new FormData(),
      xlsxData: [],
      chooseCustomer: '', // 当前选择客户
      // bug——>下拉框数据在active切换后值拿不到
      customer: [] // 客户
    };
  },
  created() {
    this.getCustomerPage();
  },
  methods: {
    // 客户列表
    getCustomerPage() {
      const customerPageDate = {
        pageNumber: 1,
        pageSize: 20,
        params: {
          company: '',
          source: '',
          username: ''
        }
      };
      apiCustomerPage(customerPageDate).then(res => {
        // console.log('客户列表 customer', res.data.records);
        res.data.records.map(arr => {
          this.customer.push({
            id: arr.id,
            username: arr.username
          });
        });
      });
    },
    initDown() {
      const a = document.createElement('a');
      a.href = '/static/TPL_ANJUN.xlsx';
      // console.log('文件地址——>', a.href);
      a.download = 'TPL_ANJUN.xlsx';
      a.style.display = 'none';
      this.$nextTick(() => {
        this.$refs.download1.appendChild(a);
      });
      a.click();
      a.remove();
    },
    beforeUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1);
      // const whiteList = ['pdf', 'doc', 'docx', 'xls', 'xlsx'];
      const whiteList = ['xlsx'];
      if (whiteList.indexOf(fileSuffix) === -1) {
        // 上传文件只能是xlsx格式
        this.$message.error(this.$t('orderImport.uploadOnlyXlsx'));
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        // 上传文件大小不能超过 2MB
        this.$message.error(this.$t('orderImport.uploadNot2MB'));
        return false;
      }
    },
    upLoad(option) { // 导入xlsx模版
      const files = option.file;
      this.upLoadFile = new FormData();
      this.upLoadFile.append('file', files);
      // console.log('监控upLoadFile---', typeof (this.upLoadFile));
      // 不需要解析文件，直接将文件传到接口
      // const fileReader = new FileReader();
      // fileReader.readAsBinaryString(files);
      // fileReader.onload = (ev) => {
      //   const data = ev.target.result;
      //   const workbook = XLSX.read(data, {
      //     type: 'binary'
      //   }); // 以二进制流方式读取得到整份excel表格对象
      //   let fromTo = '';
      //   const sheetContent = [];
      //   // 遍历每张表读取
      //   for (const sheet in workbook.Sheets) {
      //     // eslint-disable-next-line no-prototype-builtins
      //     if (workbook.Sheets.hasOwnProperty(sheet)) {
      //       fromTo = workbook.Sheets[sheet]['!ref'];
      //       sheetContent.push(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
      //       // break; // 如果只取第一张表，就取消注释这行
      //     }
      //   }
      //   this.xlsxData = sheetContent[0];
      //   console.log(sheetContent, '==respondBody');
      // };
    },
    handleUpload() { // 上传
      // console.log('上传按钮打印', this.upLoadFile, this.chooseCustomer);
      const dataImport = {
        customerId: this.chooseCustomer,
        file: this.upLoadFile
      };
      if (!this.chooseCustomer) {
        this.$message({
          message: this.$t('placeOrder.pleaseChooseClient'),
          offset: 150,
          type: 'warning'
        });
      } else {
        orderImport(dataImport).then(res => {
          if (res.status === 'OK') {
            // console.log('xxxxx上传结果xxxxx');
            this.$message({
              message: res.msg,
              type: 'success',
              duration: 2000,
              center: true,
              offset: 100
            });
            setTimeout(() => {
              goExportList(this);
            }, 1000);
          }
        }).catch(err => {
          console.log(err);
        });
      }
    },
    isExcel(file) {
      return /\.(xlsx|xls)$/.test(file.name);
    }
  }
};
</script>

<style lang="scss" scoped>
.primary {
  background: #12D2AC;
  color: #fff;
}

.bulkImportBox ::v-deep .el-button--primary {
  border-color: #12d2ac;
}

.bulkImportBox ::v-deep .el-upload-dragger {
  background-color: #f2f3f5;
  border: 1px dashed #e6e7ec;
  border-radius: 3px;
}

.bulkImportBox {
  padding: 40px;
  box-sizing: border-box;

  .downBtn {
    width: 350px;
    height: 30px;
    padding: 0 10px;
    font-size: 13px;
    color: #000;
    background: #f7f8fa;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .downBtnText {
      display: inline-block;
      width: 100%;
      height: 100%;
      padding-left: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 30px;
    }
  }

  .downDelete {
    background: none;
    border: none;
    outline: none;
    transform: scale(0.8);
    margin-left: 10px;
  }

  .downBtnBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .uploadBox {
    margin: 50px 0 30px;
  }

}
</style>
