<script >
import { defineProps } from '@vue/composition-api';
import { useQueryClient } from '@tanstack/vue-query';
import QRCode from 'qrcode-generator';
import { Message } from 'element-ui';
import { default as DropdownTable } from '@/components/dropdownTable';
import { default as FormEditVehicle } from './form/formEditVehicle';
import { default as Alert } from '@/components/alert';
import { useModal } from '@/composable/useModal';
import { vehicleService } from '@/api/transport/vehicle';
import { useEditVehicle } from '@/api/transport/vehicle/query';
import getMessageError from '@/utils/getMessageError';
import { addingLoading, closeLoading } from '@/utils/handleControlLoading';
const __sfc_main = {};
__sfc_main.props = {
  status: {
    type: Number
  },
  row: {
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  /* edit vehicle */
  const {
    isOpen: isOpenModalEdit,
    handleVisibility: handleVisibilityModalEdit
  } = useModal();

  /* handle print QR CODE */
  function handlePrintQRcode() {
    const qrCodeText = `{brand: ${props.row.vehicle_brand}, model: ${props.row.vehicle_model}, plate: ${props.row.vehicle_license_plate}, documentation: ${props.row.vehicle_documentation}}`;
    const qr = QRCode(0, 'L');
    qr.addData(qrCodeText);
    qr.make();
    const qrCodeData = qr.createDataURL(8, 0);
    const newWindow = window.open();
    newWindow.document.write(`<img src="${qrCodeData}"/>`);
    newWindow.document.close();
    newWindow.onload = () => {
      newWindow.document.getElementsByTagName('img')[0].style.width = '100%';
      newWindow?.print();
      newWindow?.close();
    };
  }

  /* delete vehicle */
  const {
    isOpen: isOpenModalDelete,
    handleVisibility: handleVisibilityModalDelete
  } = useModal();
  const queryClient = useQueryClient();
  async function handleDelete() {
    try {
      addingLoading();
      await vehicleService.delete(props.row.id);
      Message({
        message: 'Apagado com sucesso',
        type: 'success',
        duration: 4 * 1000
      });
      queryClient.invalidateQueries({
        queryKey: props.status === 2 ? ['vehiclesRotate'] : ['vehicles']
      });
      handleVisibilityModalDelete();
    } catch (error) {
      Message({
        message: getMessageError(error),
        type: 'error',
        duration: 4 * 1000
      });
    } finally {
      closeLoading();
    }
  }

  /* restore vehicle */
  const {
    isOpen: isOpenModalRestore,
    handleVisibility: handleVisibilityModalRestore
  } = useModal();
  const {
    mutate: mutateRestore,
    isLoading: isLoadingRestore
  } = useEditVehicle();
  function handleRestore() {
    const newVehicle = {
      ...props.row,
      'vehicle_is_active': 1
    };
    mutateRestore({
      newVehicle,
      id: props.row.id,
      isFormatedBody: false
    }, {
      onSuccess() {
        handleVisibilityModalRestore();
      }
    });
  }
  return {
    isOpenModalEdit,
    handleVisibilityModalEdit,
    handlePrintQRcode,
    isOpenModalDelete,
    handleVisibilityModalDelete,
    handleDelete,
    isOpenModalRestore,
    handleVisibilityModalRestore,
    isLoadingRestore,
    handleRestore
  };
};
__sfc_main.components = Object.assign({
  DropdownTable,
  FormEditVehicle,
  Alert
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <DropdownTable aria-role="list">
      <o-dropdown-item v-show="!!status" aria-role="listitem" @click="handleVisibilityModalEdit">
        {{ $t('vehicle.edit') }}
      </o-dropdown-item>

      <o-dropdown-item v-show="!!status" aria-role="listitem" @click="handleVisibilityModalDelete">
        {{ $t('vehicle.delete') }}
      </o-dropdown-item>

      <o-dropdown-item v-show="!!status" aria-role="listitem" @click="handlePrintQRcode">
        {{ $t('vehicle.qrCode') }}
      </o-dropdown-item>

      <o-dropdown-item v-show="!(!!status)" aria-role="listitem" @click="handleVisibilityModalRestore">
        {{ $t('vehicle.restore') }}
      </o-dropdown-item>
    </DropdownTable>

    <o-modal :active="isOpenModalEdit" width="560" @close="handleVisibilityModalEdit">
      <div>
        <h2 class="header-2">{{ $t('vehicle.editTitle') }}</h2>
        <p class="paragraph">{{ $t('vehicle.editParagraph') }}</p>
      </div>

      <FormEditVehicle :status="status" :vehicle="row" :close-modal="handleVisibilityModalEdit" />
    </o-modal>

    <Alert
      :active="isOpenModalDelete"
      @handleClose="handleVisibilityModalDelete"
    >
      <template #title> {{ $t('vehicle.deleteTitle') }} </template>
      <template #message> {{ $t('vehicle.deleteParagraph') }} </template>

      <template #buttons>
        <o-button variant="outline" size="md" @click="handleVisibilityModalDelete"> {{ $t('vehicle.buttonCancel') }} </o-button>
        <o-button variant="primary" size="md" @click="handleDelete"> {{ $t('vehicle.buttonDelete') }} </o-button>
      </template>
    </Alert>

    <Alert
      :active="isOpenModalRestore"
      @handleClose="handleVisibilityModalRestore"
    >
      <template #title> {{ $t('vehicle.restoreTitle') }} </template>
      <template #message> {{ $t('vehicle.retoreParagraph') }} </template>

      <template #buttons>
        <o-button variant="outline" size="md" @click="handleVisibilityModalRestore"> {{ $t('vehicle.buttonCancel') }} </o-button>
        <o-button variant="primary" size="md" @click="handleRestore"> {{ $t('vehicle.buttonRestore') }} </o-button>
      </template>
    </Alert>

    <o-loading :full-page="true" :active.sync="isLoadingRestore" />
  </div>
</template>
