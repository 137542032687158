var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, height: _vm.isHeight, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("newOrder.SerialNumber"),
              align: "center",
              type: "index",
              width: "70",
            },
          }),
          _vm._l(_vm.tableHeaderList, function (item) {
            return [
              _c("el-table-column", {
                key: item.id,
                attrs: {
                  label: item.label,
                  "min-width": item.cloWidth,
                  prop: item.prop,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                scope.row[item.prop]
                                  ? "" + scope.row[item.prop]
                                  : "- -"
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ]
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("newOrder.operation"),
              align: "center",
              fixed: "right",
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.operation === 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: { click: _vm.clickInvoiceInfo },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit-outline hoverClass",
                              staticStyle: { "font-size": "25px" },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("newOrder.UpdateInvoiceInformation"),
            visible: _vm.showUpdateInvoice,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showUpdateInvoice = $event
            },
            close: _vm.updateInvoiceData,
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  key: _vm.showUpdateInvoice,
                  ref: "updateInvoiceForm",
                  attrs: { model: _vm.updateInvoiceForm, rules: _vm.rules },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("placeOrder.DateOfInvoicing"),
                        prop: "invoiceIssueDate",
                      },
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          format:
                            _vm.$i18n.locale == "zh" ? _vm.zhDate : _vm.ptDate,
                          "picker-options": _vm.pickerOptions,
                          placeholder: _vm.$t("newOrder.SelectDatetime"),
                          "value-format":
                            _vm.$i18n.locale == "zh" ? _vm.zhDate : _vm.ptDate,
                          clearable: "",
                          type: "date",
                        },
                        model: {
                          value: _vm.updateInvoiceForm.invoiceIssueDate,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.updateInvoiceForm,
                              "invoiceIssueDate",
                              $$v
                            )
                          },
                          expression: "updateInvoiceForm.invoiceIssueDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("newOrder.InvoiceNumber"),
                        prop: "invoiceNumber",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: _vm.$t("orderDetails.pleaseInput"),
                          clearable: "",
                        },
                        model: {
                          value: _vm.updateInvoiceForm.invoiceNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.updateInvoiceForm,
                              "invoiceNumber",
                              $$v
                            )
                          },
                          expression: "updateInvoiceForm.invoiceNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "" + _vm.$t("placeOrder.InvoiceAmount"),
                        prop: "invoiceMoney",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: _vm.$t("orderDetails.pleaseInput"),
                          clearable: "",
                        },
                        model: {
                          value: _vm.updateInvoiceForm.invoiceMoney,
                          callback: function ($$v) {
                            _vm.$set(_vm.updateInvoiceForm, "invoiceMoney", $$v)
                          },
                          expression: "updateInvoiceForm.invoiceMoney",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("newOrder.InvoiceKey"),
                        prop: "invoiceAccessKey",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: _vm.$t("orderDetails.pleaseInput"),
                          clearable: "",
                          rows: "4",
                          type: "textarea",
                        },
                        model: {
                          value: _vm.updateInvoiceForm.invoiceAccessKey,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.updateInvoiceForm,
                              "invoiceAccessKey",
                              $$v
                            )
                          },
                          expression: "updateInvoiceForm.invoiceAccessKey",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showUpdateInvoice = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.saveUpdateInvoice()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Salve")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }