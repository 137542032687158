<template>
  <div class="app-content">
    <el-card style="margin-bottom: 5px;">
      <!-- 搜索栏 -->
      <el-form
        ref="form"
        :model="params"
        label-width="auto"
        :label-position="'top'"
        size="small">
        <el-row :gutter="20" type="flex">
          <!-- 标题 -->
          <el-col :span="6">
            <el-form-item :label="$t('customerManagements.title')" >
              <el-input v-model.trim="params.title" :placeholder="$t('GlobalSetObj.pleaseInputContent')" clearable />
            </el-form-item>
          </el-col>
          <!-- 单号 -->
          <el-col :span="6">
            <el-form-item :label="$t('compensationManagement.ClaimNumber')">
              <el-input 
                v-model="params.claimsNumberList" 
                clearable 
                :placeholder="$t('collectionCenter.BatchQuery') + '，' + $t('compensationManagement.Maximum500Orders')" 
                :autosize="{ minRows: 1, maxRows: 5}"
                type="textarea"/>
            </el-form-item>
          </el-col>
          <!--供应商-->
          <!-- <el-col :span="6">
            <el-form-item :label="$t('GlobalSetObj.supplier')">
              <el-select
                v-model="params.customerId"
                filterable
                :placeholder="$t('operationCenter.PleaseSelect')"
                clearable
                style="width: 100%;"
              >
                <el-option
                  v-for="item in providerList"
                  :key="item.id"
                  :label="item.providerBody + '（' + item.providerName + '）'"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="6">
            <!--索赔状态-->
            <el-form-item :label="$t('compensationManagement.ClaimStatus')">
              <el-select v-model="params.claimsStatus" :placeholder="$t('GlobalSetObj.pleaseChoose')" clearable>
                <el-option
                  v-for="item in STATUS"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 索赔类型 -->
            <el-form-item :label="$t('compensationManagement.ClaimType')">
              <el-select
                v-model="params.claimsType"
                filterable
                :placeholder="$t('operationCenter.PleaseSelect')"
                clearable
                style="width: 100%;"
              >
                <el-option
                  v-for="item in CLAIMS_TYPE"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--创建人-->
            <el-form-item :label="$t('GlobalSetObj.CreatePeople')">
              <UserSelectCmp v-model="params.createUser" :is-user-name="true" class="form-bottom" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--开始时间/结束时间-->
            <el-form-item label="" class="selectInner">
              <template slot="label">
                <el-select v-model="times" :placeholder="$t('GlobalSetObj.pleaseChoose')" size="mini" style="width:180px; height: 28px;">
                  <!-- 申请时间 -->
                  <el-option :label="$t('compensationManagement.ApplicationTime')" value="startedTime" />
                  <el-option :label="$t('collectionCenter.EndTime')" value="endedTime" />
                </el-select>
              </template>
              <el-date-picker
                v-model="params.val2"
                type="datetimerange"
                :start-placeholder="$t('GlobalSetObj.startDate')"
                :end-placeholder="$t('GlobalSetObj.endDate')"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['09:00:00', '18:00:00']"
                style="display: block;width: 100%;"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--业务类型-->
            <el-form-item :label="$t('collectionCenter.businessT')">
              <el-select
                v-model="params.claimsNode" :placeholder="$t('GlobalSetObj.pleaseChoose')" class="form-bottom"
                style="width: 100%" @change="onClaimsNodeChange" clearable
              >
                <el-option
                  v-for="item in claimNode"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--订单号/客户单号-->
            <el-form-item label="" class="selectInner">
              <template slot="label">
                <el-select v-model="orderType" :placeholder="$t('GlobalSetObj.pleaseChoose')" size="mini" style="width:180px; height: 28px;">
                  <el-option :label="$t('basicData.OrderNumber')" value="orderNumber" />
                  <el-option :label="$t('collectionCenter.customerNum')" value="customerNumber" />
                </el-select>
              </template>
              <el-input
                v-model="params.val1" :placeholder="$t('GlobalSetObj.pleaseInputContent')" clearable
                class="input-with-select"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-button
        type="primary"
        size="small"
        icon="el-icon-search"
        @click="onQuery"
      >{{ $t('operationCenter.Procurar') }}</el-button>
      <el-button size="small" icon="el-icon-refresh-left" @click="onReset">
        {{ $t('operationCenter.reset') }}
      </el-button>
    </el-card>

    <el-card>
      <div style="margin-bottom: 10px;">
        <!-- 申请索赔 -->
        <el-button
          v-permit:remove="'but:compensationManagement:compensationList:add'"
          type="primary"
          size="small"
          @click="() =>onAddClaims()"
        >{{ $t('compensationManagement.ApplyForClaim') }}</el-button>
        <!-- 导出 -->
        <el-button
          v-permit:remove="'but:compensationManagement:compensationList:export'"
          type="info"
          size="small"
          @click="onExport"
        >{{ $t('CenterForSorting.export') }}</el-button>
      </div>

      <el-table
        :data="tableData"
        border
        style="width: 100%;min-height: 300px;"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <!--序号-->
        <!-- <el-table-column :label="$t('operationCenter.ind')" width="60px" type="index" /> -->
        <el-table-column :label="$t('operationCenter.ind')" width="60px">
          <template slot-scope="scope">
            <span>{{ (pageNumber - 1) * pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!-- 标题 -->
        <el-table-column :label="$t('customerManagements.title')" prop="title" width="180" />
        <!-- 索赔单号 -->
        <el-table-column :label="$t('compensationManagement.ClaimNumber')" prop="claimsNumber" width="140">
          <template slot-scope="scope">
            <el-link type="primary" @click="onOpen(scope.row)">{{ scope.row.claimsNumber }}</el-link>
          </template>
        </el-table-column>
        <!-- 索赔状态 -->
        <el-table-column :label="$t('compensationManagement.ClaimStatus')" prop="claimsStatus" width="140">
          <template slot-scope="scope">
            <el-tag :type="scope.row.tagType.type">{{ scope.row.tagType.name }}</el-tag>
          </template>
        </el-table-column>
        <!-- 业务类型 -->
        <el-table-column :label="$t('collectionCenter.businessT')" prop="claimsNodeStr" width="140" />
        <!-- 索赔类型 -->
        <el-table-column :label="$t('compensationManagement.ClaimType')" prop="claimsType" width="140">
          <template slot-scope="scope">
            <span>{{ CLAIMS_TYPE[scope.row.claimsType] && CLAIMS_TYPE[scope.row.claimsType].name || ''}}</span>
          </template>
        </el-table-column>
        <!-- 供应商 -->
        <!-- <el-table-column :label="$t('GlobalSetObj.supplier')" prop="customerName" width="180"/> -->
        <!-- 申请时间 -->
        <el-table-column :label="$t('compensationManagement.ApplicationTime')" prop="applicationTime" width="160" />
        <!-- 结束时间 -->
        <el-table-column :label="$t('collectionCenter.EndTime')" prop="finishTime" width="160" />
        <!-- 申请索赔金额 -->
        <el-table-column :label="$t('compensationManagement.ApplyForClaimAmount')" prop="claimAmount" width="120" />
        <!-- 实际索赔金额 -->
        <el-table-column :label="$t('compensationManagement.ActualClaimAmount')" prop="actualClaimAmount" width="120" />
        <!-- 创建人 -->
        <el-table-column :label="$t('GlobalSetObj.CreatePeople')" prop="createUserStr" />
        <!-- 审核人 -->
        <el-table-column :label="$t('Resource.Reviewer')" prop="reviewerStr" />
        <!-- 审核时间 -->
        <el-table-column :label="$t('receivablePayable.ReviewTime')" prop="auditingTime" width="170" />
        <!-- 付款人 -->
        <el-table-column :label="$t('compensationManagement.Drawee')" prop="payerStr" />
        <!-- 付款时间 -->
        <el-table-column :label="$t('receivablePayable.PaymentTime')" prop="paymentTime" width="170" />
      </el-table>

      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getCurrentTableData"
          @size-change="getSizeTableData"
        />
      </div>
    </el-card>

    <!-- 申请索赔 -->
    <el-drawer
      :title="$t('compensationManagement.ClaimApplication')"
      direction="ltr"
      :visible.sync="createdVisible"
      :destroy-on-close="true"
      :before-close="onAddClose"
      size="80%"
    >
      <CreatedForm @close="onAddClose" />
    </el-drawer>

    <!-- 详情/编辑 -->
    <el-drawer
      :title="editTitle"
      direction="ltr"
      :visible.sync="detailsVisible"
      :destroy-on-close="true"
      :before-close="onEditClose"
      size="80%"
    >
      <Details :Id='id' @close="onEditClose" />
    </el-drawer>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash-es';
import UserSelectCmp from '@/views/claimsManagement/claimsReviewRules/cmp/UserSelectCmp.vue';
import { apidDmageClaimformPage, apidDmageClaimformExport } from '@/api/compensationManagement/list';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { goExportList } from '@/utils/goExportList.js';
import { claimantMixin } from '../claimantMinxin.js';
import CreatedForm from './components/createForm.vue';
import Details from './components/details.vue';

import dayjs from 'dayjs';
import i18n from '@/lang/index.js';

const firstDay = dayjs().startOf('month').format('YYYY-MM-DD') + ' 00:00:00';
const lastDay = dayjs().endOf('month').format('YYYY-MM-DD') + ' 23:59:59';

const PARAM = {
  title: '', // 标题
  claimsNumberList: '', // 索赔单号
  customerId: '', // 供应商
  claimsStatus: '', // 索赔状态
  createUser: '', // 创建人
  claimsNode: '', // 业务类型
  val1: '', // 订单号、客户单号
  val2: [firstDay, lastDay] // 申请时间、结束时间
};
const title = i18n.t('compensationManagement.ClaimDetails');

export default {
  name: 'CompensationList',

  components: {
    UserSelectCmp,
    CreatedForm,
    Details
  },

  data() {
    return {
      params: {
        ...PARAM
      },
      times: 'startedTime', // 申请时间/结束时间
      orderType: 'orderNumber', // 订单号/客户单号

      tableData: [], // 列表数据
      multipleSelection: [], // 多选
      pageNumber: 1,
      pageSize: 10,
      total: 0, // 总条数
      createdVisible: false, // 详情/编辑
      detailsVisible: false, // 详情
      id: '',
      editTitle: title
    };
  },

  mixins: [claimantMixin],

  beforeCreate() {
    this.userObj = getUserIdAndNameObj();
  },

  created() {
    this.getTableData();
  },

  // activated() {
  //   console.log('进入')
  // },
  // deactivated() {
  //   console.log('离开')
  // },

  methods: {
    // 搜索
    onQuery() {
      this.searchList();
    },

    // 重置
    onReset() {
      this.params = {
        ...PARAM
      };

      this.searchList();
    },

    searchList() {
      this.pageNumber = 1;
      this.pageSize = 10;
      this.getTableData();
    },

    /**
     * 切换页码
     * @param val
     */
    getCurrentTableData(val) {
      this.pageNumber = val;
      this.getTableData();
    },

    /**
     * 切换每页条数
     * @param val
     */
    getSizeTableData(val) {
      this.pageSize = val;
      this.pageNumber = 1;
      this.getTableData();
    },

    // 获取列表数据
    getTableData() {
      let obj = cloneDeep(this.params);
      obj[this.times] = obj.val2;
      obj[this.orderType] = obj.val1;
      obj.claimsNumberList =  (obj.claimsNumberList.split('\n')).filter(item => item && item.trim()).map(items => items.replace(/\s/g, '').trim())
      if (obj.claimsNumberList.length > 500) {
        return this.$message.warning(i18n.t('compensationManagement.Maximum500Orders'));
      }
      delete obj.val1;
      delete obj.val2;
      obj = removeEmptyProperties(obj);
      apidDmageClaimformPage({
        params: obj,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      }).then(res => {
        if (res.status === 'OK') {
          const { total, records } = res.data;
          this.total = total;

          this.tableData = (records || []).map(item => {
            item.createUserStr = this.userObj[item.createUser] || '';
            item.reviewerStr = this.userObj[item.reviewer] || '';
            item.payerStr = this.userObj[item.payer] || '';
            item.claimsNodeStr = this.claimNode[item.claimsNode]?.name || '';
            item.tagType = this.STATUS[item.claimsStatus];

            return item;
          });
        }
      }).catch(err => {
        console.error(err);
      });
    },

    // 切换业务类型
    onClaimsNodeChange(val) {
      this.orderType = val === 4 ? 'orderNumber' : 'customerNumber';
    },

    // 申请索赔
    onAddClaims() {
      this.createdVisible = true;
    },

    // 编辑/详情
    onOpen(row) {
      const { id, claimsStatus } = row;
      this.id = id;
      // 待提交 待审核 待付款 已完成 已关闭 已驳回
      this.onEditClaims(row);
    },

    // 编辑
    onEditClaims(row) {
      const { title, claimsNumber } = row;
      this.editTitle = this.editTitle + '-' + title + '-' + claimsNumber;
      this.detailsVisible = true;
    },

    // 新增/关闭/取消
    onAddClose(isBool) {
      this.id = '';
      this.createdVisible = false;

      if (isBool) {
        this.getTableData();
      }
    },

    // 编辑关闭
    onEditClose(isBool) {
      this.id = '';
      this.editTitle = title;
      this.detailsVisible = false;

      if (isBool) {
        this.getTableData();
      }
    },

    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 导出
    onExport() {
      let obj = {};

      // 默认导出查询条件
      if (this.multipleSelection.length === 0) {
        obj = {
          ...this.params
        };
        obj.claimsNumberList =  (obj.claimsNumberList.split('\n')).filter(item => item && item.trim()).map(items => items.replace(/\s/g, '').trim())
      } else {
        obj = {
          ids: this.multipleSelection.map(item => {
            return item.id;
          })
        };
      }
      apidDmageClaimformExport(obj).then(res => {
        if (res.status === 'OK') {
          goExportList(this);
        }
      }).catch(err => {
        console.error(err);
      });
    }
  }
};

function removeEmptyProperties(obj) {
  const newObj = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
      newObj[key] = obj[key];
    }
  }

  return newObj;
}
</script>

<style scoped lang="scss">
@import '../style/index.scss';
</style>
