var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { attrs: { "label-position": "top" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("newOrder.RouteNumber") } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("CenterForSorting.pleaseInput"),
                          clearable: "",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.getSearchRouter($event)
                          },
                        },
                        model: {
                          value: _vm.queryForm.params.pathNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.queryForm.params,
                              "pathNumber",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.params.pathNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("newOrder.NetRollCall") } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("CenterForSorting.pleaseInput"),
                          clearable: "",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.getSearchRouter($event)
                          },
                        },
                        model: {
                          value: _vm.queryForm.params.relevanceSiteName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.queryForm.params,
                              "relevanceSiteName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.params.relevanceSiteName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "searchCompanyType" } },
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "div",
                          { staticClass: "selectLabel" },
                          [
                            _c(
                              "el-select",
                              {
                                style: _vm.IsWidth,
                                attrs: {
                                  placeholder: _vm.$t(
                                    "GlobalSetObj.pleaseChoose"
                                  ),
                                },
                                on: { change: _vm.handleCompanyType },
                                model: {
                                  value: _vm.queryForm.searchCompanyType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm,
                                      "searchCompanyType",
                                      $$v
                                    )
                                  },
                                  expression: "queryForm.searchCompanyType",
                                },
                              },
                              [
                                _c("el-option", {
                                  key: "1",
                                  attrs: {
                                    label: _vm.$t(
                                      "GlobalSetObj.DestinationCompany"
                                    ),
                                    value: "1",
                                  },
                                }),
                                _c("el-option", {
                                  key: "2",
                                  attrs: {
                                    label: _vm.$t(
                                      "GlobalSetObj.TransportCompany"
                                    ),
                                    value: "2",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("CenterForSorting.pleaseInput"),
                          clearable: "",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.getSearchRouter($event)
                          },
                        },
                        model: {
                          value: _vm.queryForm.params.company,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.queryForm.params,
                              "company",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.params.company",
                        },
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.getSearchRouter },
            },
            [_vm._v(_vm._s(_vm.$t("basicData.Search")) + " ")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "info" },
              on: { click: _vm.resetForm },
            },
            [_vm._v(_vm._s(_vm.$t("basicData.reset")) + " ")]
          ),
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.addRoute } },
            [_vm._v(_vm._s(_vm.$t("GlobalSetObj.create")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("AnjunTableList", {
            ref: "anjunTable",
            attrs: {
              "format-time": _vm.isTormat,
              "has-operation": true,
              "header-list": _vm.tableHeaderList,
              "is-index": true,
              "is-tip": true,
              "op-width": 100,
              "table-data": _vm.tableData,
            },
            on: { "on-editBtn": _vm.addRoute },
          }),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c("el-pagination", {
                staticStyle: { margin: "10px 0" },
                attrs: {
                  "current-page": _vm.queryForm.pageNumber,
                  "page-size": _vm.queryForm.pageSize,
                  "page-sizes": [10, 20, 50, 100, 500],
                  total: _vm.total,
                  background: "",
                  layout: "total, sizes, prev, pager, next, jumper",
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageNumber", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageNumber", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageSize", $event)
                  },
                  "current-change": _vm.handleSizeChange,
                  "size-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            "append-to-body": true,
            title:
              _vm.titleDialog === 1
                ? "" + _vm.$t("GlobalSetObj.newRouter")
                : "" + _vm.$t("GlobalSetObj.editRouter"),
            visible: _vm.showAddData,
            "custom-class": "drawerClass",
            direction: "ltr",
            size: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAddData = $event
            },
            close: _vm.clearAddEditSiteForm,
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "padding-left": "20px",
                height: "calc(100vh - 120px)",
                "overflow-y": "auto",
              },
            },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    {
                      key: 0,
                      attrs: {
                        label: _vm.$t("GlobalSetObj.other"),
                        name: "first",
                      },
                    },
                    [
                      _c(
                        "el-form",
                        {
                          key: _vm.showAddData,
                          ref: "addForm",
                          attrs: {
                            model: _vm.addEditForm,
                            "label-position": "top",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("newOrder.RouteNumber"),
                                        rules: _vm.rulesRouteNumber,
                                        prop: "pathNumber",
                                      },
                                    },
                                    [
                                      _c(
                                        "template",
                                        { slot: "label" },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("newOrder.RouteNumber")
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "QuestionMark",
                                              attrs: { placement: "top" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "content" },
                                                  slot: "content",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "GlobalSetObj.ThisRouteNumber"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c("svg-icon", {
                                                attrs: {
                                                  "icon-class": "QuestionMark",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "CenterForSorting.pleaseInput"
                                          ),
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.addEditForm.pathNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.addEditForm,
                                              "pathNumber",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "addEditForm.pathNumber",
                                        },
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c("el-col", { attrs: { span: 24 } }, [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  _vm._s(_vm.$t("GlobalSetObj.RouteEndAddress"))
                                ),
                              ]),
                              _c(
                                "el-col",
                                {
                                  staticStyle: { "margin-left": "25px" },
                                  attrs: { span: 22 },
                                },
                                [
                                  _c(
                                    "el-row",
                                    {
                                      staticStyle: {
                                        "background-color": "#f2f2f2",
                                        padding: "10px 20px",
                                      },
                                      attrs: { gutter: 50 },
                                    },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 18 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  "GlobalSetObj.DestinationCompany"
                                                ),
                                                rules: _vm.providerNameCompany,
                                                prop: "providerName",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "loadmore",
                                                      rawName: "v-loadmore",
                                                      value: _vm.loadmoreQuery,
                                                      expression:
                                                        "loadmoreQuery",
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    placeholder: _vm.$t(
                                                      "GlobalSetObj.pleaseChoose"
                                                    ),
                                                    clearable: "",
                                                    filterable: "",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.handleproviderName,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.addEditForm
                                                        .providerName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.addEditForm,
                                                        "providerName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "addEditForm.providerName",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.supplierMainList,
                                                  function (item, ind) {
                                                    return _c("el-option", {
                                                      key: ind,
                                                      attrs: {
                                                        label:
                                                          item.providerName,
                                                        value: item.id,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 9 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  "GlobalSetObj.userPhone"
                                                ),
                                                rules: _vm.isPhone,
                                                prop: "telephone",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "GlobalSetObj.pleaseInput"
                                                  ),
                                                  clearable: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.addEditForm.telephone,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.addEditForm,
                                                      "telephone",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "addEditForm.telephone",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 9 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  "GlobalSetObj.zipCode"
                                                ),
                                                rules: _vm.isZipCode,
                                                prop: "postcode",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "GlobalSetObj.pleaseInput"
                                                  ),
                                                  clearable: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.addEditForm.postcode,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.addEditForm,
                                                      "postcode",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "addEditForm.postcode",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 9 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label:
                                                  _vm.$t("placeOrder.State"),
                                                rules: [
                                                  {
                                                    required: true,
                                                    message: _vm.$t(
                                                      "placeOrder.pleaseChoose"
                                                    ),
                                                    trigger: ["blur", "change"],
                                                  },
                                                ],
                                                prop: "state",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    placeholder: _vm.$t(
                                                      "placeOrder.pleaseChoose"
                                                    ),
                                                    clearable: "",
                                                    filterable: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.addEditForm.state,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.addEditForm,
                                                        "state",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "addEditForm.state",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.senderStateList,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.id,
                                                      attrs: {
                                                        label: item.label,
                                                        value: item.label,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 9 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label:
                                                  _vm.$t("placeOrder.city"),
                                                rules: {
                                                  required: true,
                                                  message: _vm.$t(
                                                    "placeOrder.pleaseInput"
                                                  ),
                                                  trigger: ["blur", "change"],
                                                },
                                                prop: "city",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "GlobalSetObj.pleaseInput"
                                                  ),
                                                  clearable: "",
                                                },
                                                model: {
                                                  value: _vm.addEditForm.city,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.addEditForm,
                                                      "city",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "addEditForm.city",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 9 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label:
                                                  _vm.$t("placeOrder.Area"),
                                                prop: "area",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "GlobalSetObj.pleaseInput"
                                                  ),
                                                  clearable: "",
                                                },
                                                model: {
                                                  value: _vm.addEditForm.area,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.addEditForm,
                                                      "area",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "addEditForm.area",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 9 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label:
                                                  _vm.$t("newOrder.Street"),
                                                prop: "Street",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "GlobalSetObj.pleaseInput"
                                                  ),
                                                  clearable: "",
                                                  maxlength: "50",
                                                  "show-word-limit": "",
                                                },
                                                model: {
                                                  value: _vm.addEditForm.Street,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.addEditForm,
                                                      "Street",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "addEditForm.Street",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 9 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  "placeOrder.HouseNumber"
                                                ),
                                                prop: "HouseNumber",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "GlobalSetObj.pleaseInput"
                                                  ),
                                                  clearable: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.addEditForm.HouseNumber,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.addEditForm,
                                                      "HouseNumber",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "addEditForm.HouseNumber",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  "placeOrder.detailedAddress"
                                                ),
                                                rules: [
                                                  {
                                                    required: true,
                                                    message: _vm.$t(
                                                      "placeOrder.pleaseInput"
                                                    ),
                                                    trigger: ["blur", "change"],
                                                  },
                                                  {
                                                    min: 2,
                                                    max: 200,
                                                    message: _vm.$t(
                                                      "newOrder.TdetailedAddressCharacter"
                                                    ),
                                                    trigger: "blur",
                                                  },
                                                ],
                                                prop: "Address",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "newOrder.StreetHouseNumber"
                                                  ),
                                                  clearable: "",
                                                  maxlength: "200",
                                                  "show-word-limit": "",
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.addEditForm.Address,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.addEditForm,
                                                      "Address",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "addEditForm.Address",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 22 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "GlobalSetObj.AssociatedOutlets"
                                        ),
                                        prop: "associatedOutlets",
                                      },
                                    },
                                    [
                                      _c(
                                        "template",
                                        { slot: "label" },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "GlobalSetObj.AssociatedOutlets"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "QuestionMark",
                                              attrs: { placement: "top" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "content" },
                                                  slot: "content",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "GlobalSetObj.OutletsThatShareTheRouteNumber"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c("svg-icon", {
                                                attrs: {
                                                  "icon-class": "QuestionMark",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "medium",
                                            type: "primary",
                                          },
                                          on: { click: _vm.selectSites },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("GlobalSetObj.SelectSites")
                                            ) + " "
                                          ),
                                        ]
                                      ),
                                      _vm.addEditForm.associatedOutlets.length >
                                      0
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                padding: "5px",
                                                border: "1px solid #ccc",
                                              },
                                            },
                                            _vm._l(
                                              _vm.addEditForm.associatedOutlets,
                                              function (item, ind) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: ind,
                                                    staticClass: "tabless",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                          display: "flex",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "wordBreak",
                                                            staticStyle: {
                                                              "max-width":
                                                                "280px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.name)
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              cursor: "pointer",
                                                              "font-size":
                                                                "24px",
                                                              "margin-left":
                                                                "5px",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.deleteSite(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("× ")]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 22 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "GlobalSetObj.TransportCompany"
                                        ),
                                        rules: _vm.rulesTransportCompany,
                                        prop: "transportCompanyId",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "50%" },
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "GlobalSetObj.pleaseChoose"
                                            ),
                                            clearable: "",
                                            filterable: "",
                                          },
                                          on: {
                                            change: _vm.handleTransportCompany,
                                            focus: _vm.gettransportCompany,
                                          },
                                          model: {
                                            value:
                                              _vm.addEditForm
                                                .transportCompanyId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.addEditForm,
                                                "transportCompanyId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "addEditForm.transportCompanyId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.transportCompanyList,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.providerName,
                                                value: item.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 22 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("GlobalSetObj.remark"),
                                        prop: "remark",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "CenterForSorting.pleaseInput"
                                          ),
                                          clearable: "",
                                          maxlength: "200",
                                          rows: "2",
                                          "show-word-limit": "",
                                          type: "textarea",
                                        },
                                        model: {
                                          value: _vm.addEditForm.remark,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.addEditForm,
                                              "remark",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "addEditForm.remark",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    {
                      key: 1,
                      attrs: {
                        label: _vm.$t("GlobalSetObj.UnloadingPoint"),
                        name: "second",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 23 } },
                            [
                              _c(
                                "el-table-draggable",
                                {
                                  ref: "el-table-draggable",
                                  attrs: { animate: 300 },
                                  on: { drop: _vm.dropTable },
                                },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      ref: "machineTable",
                                      attrs: {
                                        data: _vm.UnloadingPointList,
                                        border: "",
                                        "row-key": "siteId",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-table-column",
                                        {
                                          attrs: {
                                            label: _vm.$t("GlobalSetObj.sort"),
                                            "min-width": "60",
                                          },
                                        },
                                        [
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-s-operation",
                                            }),
                                          ],
                                        ],
                                        2
                                      ),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: _vm.$t(
                                            "GlobalSetObj.nomePoint"
                                          ),
                                          "min-width": "300",
                                          prop: "UnloadingPointNameOrnetName",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: _vm.$t(
                                            "GlobalSetObj.CityState"
                                          ),
                                          "min-width": "200",
                                          prop: "cityZhou",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: _vm.$t("GlobalSetObj.zipCode"),
                                          "show-overflow-tooltip": true,
                                          "min-width": "90",
                                          prop: "zipCode",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: _vm.$t("GlobalSetObj.operate"),
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (props) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "justify-content":
                                                        "center",
                                                      "align-items": "center",
                                                    },
                                                  },
                                                  [
                                                    props.row.pathwayState
                                                      ? _c("div", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                cursor:
                                                                  "pointer",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.stateDialog(
                                                                      props.row
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-tooltip",
                                                                {
                                                                  staticClass:
                                                                    "item",
                                                                  attrs: {
                                                                    content:
                                                                      _vm.$t(
                                                                        "GlobalSetObj.pathwayState"
                                                                      ),
                                                                    effect:
                                                                      "dark",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "svg-icon",
                                                                    {
                                                                      attrs: {
                                                                        "icon-class":
                                                                          "pathwayState",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    props.row.editBtn &&
                                                    props.row.pathwayState
                                                      ? _c("div", {
                                                          staticStyle: {
                                                            padding: "0 10px",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    props.row.editBtn
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "el-tooltip",
                                                              {
                                                                staticClass:
                                                                  "item",
                                                                attrs: {
                                                                  content:
                                                                    (props.row
                                                                      .operatorTip
                                                                      ? _vm.$t(
                                                                          "GlobalSetObj.EditUnloadPoint"
                                                                        )
                                                                      : _vm.$t(
                                                                          "GlobalSetObj.EditingDot"
                                                                        )) +
                                                                    " ",
                                                                  effect:
                                                                    "dark",
                                                                  placement:
                                                                    "top",
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-edit",
                                                                  staticStyle: {
                                                                    color:
                                                                      "#409eff",
                                                                    "font-size":
                                                                      "18px",
                                                                    cursor:
                                                                      "pointer",
                                                                    "margin-top":
                                                                      "10px",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.toDot(
                                                                          props.row
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                "padding-top": "6px",
                "padding-right": "10px",
                "text-align": "right",
                "border-top": "1px solid #ccc",
              },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "20px" },
                  on: {
                    click: function ($event) {
                      _vm.showAddData = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
              ),
              _vm.activeName === "first"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.saveRouter },
                    },
                    [_vm._v(_vm._s(_vm.$t("GlobalSetObj.confirm")) + " ")]
                  )
                : _vm._e(),
              _vm.activeName === "second"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.saveUnloading },
                    },
                    [_vm._v(_vm._s(_vm.$t("GlobalSetObj.confirm")) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "show-close": true,
            title: _vm.$t("GlobalSetObj.SelectSites"),
            visible: _vm.showSelectSites,
            top: "2vh",
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSelectSites = $event
            },
            close: _vm.allCancelSelect,
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                { attrs: { "label-position": "top" } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: _vm.$t("newOrder.NetRollCall") },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "CenterForSorting.pleaseInput"
                                  ),
                                  clearable: "",
                                },
                                on: { blur: _vm.searchSite },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.searchSite($event)
                                  },
                                },
                                model: {
                                  value: _vm.querySiteForm.params.name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.querySiteForm.params,
                                      "name",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "querySiteForm.params.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("GlobalSetObj.dotZipCode"),
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "CenterForSorting.pleaseInput"
                                  ),
                                  clearable: "",
                                },
                                on: { blur: _vm.searchSite },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.searchSite($event)
                                  },
                                },
                                model: {
                                  value: _vm.querySiteForm.params.zipCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.querySiteForm.params,
                                      "zipCode",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "querySiteForm.params.zipCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("div", { staticStyle: { "line-height": "30px" } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("GlobalSetObj.all")) +
                              "：" +
                              _vm._s(_vm.siteTotal)
                          ),
                        ]),
                        _c(
                          "span",
                          { staticStyle: { float: "right" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: { click: _vm.allSelect },
                              },
                              [_vm._v(_vm._s(_vm.$t("GlobalSetObj.SelectAll")))]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.tableDataLeft,
                            height: 500,
                            border: "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("newOrder.NetRollCall"),
                              "min-width": "160",
                              prop: "name",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.dotZipCode"),
                              align: "center",
                              "min-width": "120",
                              prop: "zipCode",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.operate"),
                              align: "center",
                              fixed: "right",
                              prop: "date",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        class: _vm.selectedSiteId.includes(
                                          scope.row.id
                                        )
                                          ? "cancelSelectColor"
                                          : "selectColor",
                                        staticStyle: { cursor: "pointer" },
                                        attrs: { size: "mini", type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectedSites(scope.row)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("GlobalSetObj.Selected")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c("el-pagination", {
                        staticStyle: { margin: "10px 0", float: "right" },
                        attrs: {
                          "current-page": _vm.querySiteForm.pageNumber,
                          "page-size": _vm.querySiteForm.pageSize,
                          "page-sizes": [10, 20],
                          "pager-count": 5,
                          total: _vm.siteTotal,
                          background: "",
                          layout: "sizes, prev, pager, next",
                        },
                        on: {
                          "size-change": _vm.handleSizeChangeSite,
                          "current-change": _vm.handleCurrentChangeSite,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("div", { staticStyle: { "line-height": "30px" } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("GlobalSetObj.selected")) +
                              "：" +
                              _vm._s(_vm.tableDataRight.length)
                          ),
                        ]),
                        _c(
                          "span",
                          { staticStyle: { float: "right" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: { click: _vm.allCancelSelect },
                              },
                              [_vm._v(_vm._s(_vm.$t("GlobalSetObj.CancelAll")))]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.tableDataRight,
                            height: 500,
                            border: "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("newOrder.NetRollCall"),
                              "min-width": "160",
                              prop: "name",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.dotZipCode"),
                              align: "center",
                              "min-width": "120",
                              prop: "zipCode",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.operate"),
                              align: "center",
                              fixed: "right",
                              prop: "date",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.cancelSelectedSites(
                                                  scope
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("basicData.Cancel")
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showSelectSites = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.saveSelectSite },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "show-close": true,
            title: _vm.$t("GlobalSetObj.pathwayState"),
            visible: _vm.StateDialog,
            width: _vm.IsMdfe,
            top: "15vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.StateDialog = $event
            },
            close: _vm.clearForm,
          },
        },
        [
          _c(
            "el-row",
            { staticClass: "mdfeClass" },
            [
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        color: "#f96514",
                        "font-size": "16px",
                        "margin-bottom": "10px",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("GlobalSetObj.selectTheRoute")))]
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: _vm.$t("placeOrder.pleaseChoose"),
                        clearable: "",
                        filterable: "",
                        multiple: "",
                      },
                      model: {
                        value: _vm.selectrouteState,
                        callback: function ($$v) {
                          _vm.selectrouteState = $$v
                        },
                        expression: "selectrouteState",
                      },
                    },
                    _vm._l(_vm.trouteStateList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.label, value: item.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c("el-image", {
                    attrs: {
                      src: require("@/assets/images/stateMap.png"),
                      fit: "fill",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-right": "10px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.StateDialog = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.changerouteState },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }