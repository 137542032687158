import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { Message } from 'element-ui';

import { vehicleService } from '.';
import { getSkipPage } from '@/utils/getSkipPage';
import getMessageError from '@/utils/getMessageError';

export function useGetAllVehicles(queryParams) {
  return useQuery({
    queryKey: ['vehicles', queryParams],
    queryFn: async({ signal }) => {
      try {
        const { status, plate, currentPage, perPage } = queryParams.value;
        const skip = getSkipPage(currentPage);
        const params = {
          skip,
          take: perPage,
          plate,
          active: status
        };

        const res = await vehicleService.getAll(params, signal);

        return res;
      } catch (error) {
        if (error.response && error.response.status === 400) {
          return [];
        }

        throw error;
      }
    }
  });
}

export function useGetRotateVehicles(queryParams) {
  return useQuery({
    queryKey: ['vehiclesRotate', queryParams],
    queryFn: async() => {
      try {
        const { currentPage, perPage } = queryParams.value;
        const skip = getSkipPage(currentPage);
        const params = {
          active: 1,
          skip: skip,
          take: perPage
        };
        const res = await vehicleService.getAllRotateVehicles(params);

        return res;
      } catch (error) {
        if (error.response && error.response.status === 400) {
          return [];
        }

        throw error;
      }
    }
  });
}

export function useCreateVehicle() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (newVehicle) => vehicleService.create(newVehicle),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['vehicles'] });
      Message({
        message: 'Criado com sucesso',
        type: 'success',
        duration: 4 * 1000
      });
    },
    onError: (error) => {
      Message({
        message: getMessageError(error),
        type: 'error',
        duration: 4 * 1000
      });
    }
  });
}

export function useEditVehicle() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ newVehicle, id, isFormatedBody }) => vehicleService.edit(newVehicle, id, isFormatedBody),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['vehicles'] });
      Message({
        message: 'Editado com sucesso',
        type: 'success',
        duration: 4 * 1000
      });
    },
    onError: (error) => {
      Message({
        message: getMessageError(error),
        type: 'error',
        duration: 4 * 1000
      });
    }
  });
}

export const useEditVehicleOnRotate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ newVehicle, id, isFormatedBody }) => vehicleService.edit(newVehicle, id, isFormatedBody),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['vehiclesRotate'] });
      Message({
        message: 'Editado com sucesso',
        type: 'success',
        duration: 4 * 1000
      });
    },

    onError: (error) => {
      Message({
        message: getMessageError(error),
        type: 'success',
        duration: 4 * 1000
      });
    }
  });
};
