<script >
import { ref, watch, computed } from '@vue/composition-api';
import dayjs from 'dayjs';
import { default as SkeletonTable } from '@/components/skeletonTable';
import { default as WrapperHandleError } from '@/components/wrapperHandleError';
import { default as SectionHeader } from '@/components/sectionHeader';
import { default as FormCreateOrder } from './form/formCreateOrder';
import { useGetAllOrders } from '@/api/transport/order/query';
import { orderService } from '@/api/transport/order';
import { useModal } from '@/composable/useModal';
import { addingLoading, closeLoading } from '@/utils/handleControlLoading';
import { toast } from '@/utils/programaticToast';
import { getToastContentComponent } from '@/utils/getToastContentComponent';
import getMessageError from '@/utils/getMessageError';
import { saveAs } from 'file-saver';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const currentDate = ref(dayjs(new Date()).format('YYYY-MM-DD'));
  const queryParams = ref({
    currentDate: dayjs(new Date()).format('YYYY/MM/DD'),
    currentPage: 1,
    perPage: 10
  });
  watch(currentDate, newDate => {
    if (queryParams.value.currentPage > 1) {
      queryParams.value = {
        currentDate: dayjs(newDate).format('YYYY/MM/DD'),
        currentPage: 1,
        perPage: 10
      };
    } else {
      queryParams.value = {
        ...queryParams.value,
        currentDate: dayjs(newDate).format('YYYY/MM/DD')
      };
    }
  });
  const {
    data,
    isError,
    isLoading,
    isFetched,
    error
  } = useGetAllOrders(queryParams);
  const tableData = computed(() => {
    return data.value?.data?.normalizedResult ?? data.value;
  });
  const tableTotal = computed(() => {
    return data.value?.data?.total ?? 1;
  });
  const isLoadingTable = computed(() => {
    return isLoading.value || !isFetched.value;
  });

  /* handle download */
  async function handleDownload() {
    try {
      addingLoading();
      const res = await orderService.getMaster();
      const blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      saveAs(blob, `Master_${dayjs(new Date()).format('YYYY-MM-DD')}.xlsx`);
      // const url = window.URL.createObjectURL(blob);
      // const link = document.createElement('a');
      // link.href = url;
      // link.download = `Master_${dayjs(new Date()).format('YYYY-MM-DD')}.xlsx`; // Set the desired file name

      // // Trigger the download
      // link.click();

      // // Clean up
      // window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Ocorreu um erro',
          describe: getMessageError(error),
          typeIcon: 'error'
        }),
        closable: true,
        duration: 4000
      });
    } finally {
      closeLoading();
    }
  }

  /* create order */
  const {
    isOpen: isOpenModalCreate,
    handleVisibility: handleVisibilityModalCreate
  } = useModal();
  return {
    currentDate,
    queryParams,
    isError,
    isLoading,
    error,
    tableData,
    tableTotal,
    isLoadingTable,
    handleDownload,
    isOpenModalCreate,
    handleVisibilityModalCreate
  };
};
__sfc_main.components = Object.assign({
  WrapperHandleError,
  SectionHeader,
  SkeletonTable,
  FormCreateOrder
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <WrapperHandleError :error="error" :is-error="isError">
    <SectionHeader>
      <template #header>
        <h1 class="header-1">
          {{ $t('order.generatedOrders') }}
        </h1>
        <div class="wrapperButtons">
          <o-button variant="outline" size="md" @click="handleDownload">
            {{ $t('order.downloadMaster') }}
          </o-button>
          <o-button variant="primary" size="md" @click="handleVisibilityModalCreate">
            {{ $t('order.createOrder') }}
          </o-button>
        </div>
      </template>

      <template #filter>
        <o-field :label="$t('order.orderDate')" label-for="date" class="flex-1">
          <o-input
            id="date" v-model="currentDate" type="date"
            :placeholder="$t('order.orderDatePlaceholder')"
          />
        </o-field>
      </template>
    </SectionHeader>

    <SkeletonTable :is-loading="isLoadingTable">
      <o-table
        v-if="!isLoading"
        :data="tableData"
        :row-key="'routeId'"
        bordered
        class="space-m-y-32"
        paginated
        backend-pagination
        :total="tableTotal"
        :per-page="queryParams.perPage"
        :current-page.sync="queryParams.currentPage"
        :pagination-simple="false"
      >
        <o-table-column
          v-slot="props"
          field="order_vehicle_initial_kilometers"
          :label="$t('order.initialKilometers')"
          width="160"
        >
          {{ props.row.order_vehicle_initial_kilometers || 0 }}
        </o-table-column>

        <o-table-column
          v-slot="props"
          field="order_vehicle_arrival_kilometers"
          :label="$t('order.arrivalKilometers')"
          width="160"
        >
          {{ props.row.order_vehicle_arrival_kilometers || 0 }}
        </o-table-column>

        <o-table-column
          v-slot="props"
          field="order_vehicle_total_kilometers"
          :label="$t('order.totalKilometers')"
          width="160"
        >
          {{ props.row.order_vehicle_total_kilometers || 0 }}
        </o-table-column>

        <o-table-column
          v-slot="props"
          field="driver_name"
          :label="$t('order.driver')"
          width="160"
        >
          {{ props.row.driver_name }}
        </o-table-column>

        <o-table-column
          v-slot="props"
          field="vehicle_license_plate"
          :label="$t('order.licensePlate')"
          width="160"
        >
          {{ props.row.vehicle_license_plate }}
        </o-table-column>

        <o-table-column
          v-slot="props"
          field="totalPackages"
          :label="$t('order.totalPackages')"
          width="160"
        >
          {{ props.row.totalPackages }}
        </o-table-column>

        <template #empty>
          {{ $t('order.noResults') }}
        </template>
      </o-table>
    </SkeletonTable>

    <o-modal :active="isOpenModalCreate" width="720" @close="handleVisibilityModalCreate">
      <div>
        <h2 class="header-2">{{ $t('order.createOrderTitle') }}</h2>
        <p class="paragraph">{{ $t('order.createOrderParagraph') }}</p>
      </div>

      <FormCreateOrder :close-modal="handleVisibilityModalCreate" />
    </o-modal>
  </WrapperHandleError>
</template>

<style scoped>
.wrapperButtons {
  display: flex;
  gap: 1rem/* 16px */;
}

.flex-1{
  flex: 1;
}
</style>
