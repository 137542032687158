var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Box" },
    [
      _c(
        "div",
        { staticClass: "searchClass search" },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("collectionCenter.regionName")))]),
          _c("el-input", {
            attrs: {
              clearable: "",
              placeholder: _vm.$t("collectionCenter.pleaseInput"),
            },
            model: {
              value: _vm.tableQueryForm.params.areaName,
              callback: function ($$v) {
                _vm.$set(_vm.tableQueryForm.params, "areaName", $$v)
              },
              expression: "tableQueryForm.params.areaName",
            },
          }),
          _c(
            "span",
            { staticStyle: { "margin-left": "20px" } },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("collectionCenter.zipCode")))]),
              _c("el-input", {
                attrs: {
                  clearable: "",
                  placeholder: _vm.$t("collectionCenter.pleaseInput"),
                },
                model: {
                  value: _vm.tableQueryForm.params.startZipCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableQueryForm.params, "startZipCode", $$v)
                  },
                  expression: "tableQueryForm.params.startZipCode",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:collection-center:finance:mailingArea:search",
                      expression:
                        "'btn:collection-center:finance:mailingArea:search'",
                      arg: "remove",
                    },
                  ],
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-search",
                    disabled: _vm.btnDisablad.searBtn,
                  },
                  on: { click: _vm.searchClick },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.search")))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:collection-center:finance:mailingArea:add",
                      expression:
                        "'btn:collection-center:finance:mailingArea:add'",
                      arg: "remove",
                    },
                  ],
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-plus",
                    disabled: _vm.btnDisablad.addBtn,
                  },
                  on: { click: _vm.addClick },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.add")))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:collection-center:finance:mailingArea:Save",
                      expression:
                        "'btn:collection-center:finance:mailingArea:Save'",
                      arg: "remove",
                    },
                  ],
                  attrs: {
                    type: "success",
                    size: "mini",
                    disabled: _vm.btnDisablad.saveBtn,
                  },
                  on: { click: _vm.saveClick },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.Save")))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:collection-center:finance:mailingArea:cancel",
                      expression:
                        "'btn:collection-center:finance:mailingArea:cancel'",
                      arg: "remove",
                    },
                  ],
                  attrs: {
                    type: "info",
                    size: "mini",
                    disabled: _vm.btnDisablad.cancelBtn,
                  },
                  on: { click: _vm.cancelClick },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.cancel")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.open,
              expression: "open",
            },
          ],
          staticClass: "areaDateClass",
        },
        [
          _c("el-button", { on: { click: _vm.chooseDateClick } }, [
            _vm._v(_vm._s(_vm.$t("collectionCenter.selectiveData"))),
          ]),
          _c("div", { staticClass: "betweenClass" }, [
            _c(
              "div",
              { staticClass: "zipcodeContentClass" },
              _vm._l(_vm.areaDate.zipcode, function (item, index) {
                return _c("span", { key: index, staticClass: "span" }, [
                  _c("span", [
                    _vm._v(_vm._s(item)),
                    _c("span", {
                      staticClass: "el-icon-circle-close",
                      staticStyle: { "margin-left": "2px" },
                      on: {
                        click: function ($event) {
                          return _vm.delZC(item, index)
                        },
                      },
                    }),
                  ]),
                ])
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "btClass" },
              [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("collectionCenter.regionName"))),
                ]),
                _c("el-input", {
                  attrs: {
                    clearable: "",
                    placeholder: _vm.$t("collectionCenter.pleaseInput"),
                  },
                  model: {
                    value: _vm.areaDate.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.areaDate, "name", $$v)
                    },
                    expression: "areaDate.name",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm.dialogVisible
        ? _c(
            "div",
            [
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: _vm.dialogTitle,
                    visible: _vm.dialogVisible,
                    width: "50%",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogVisible = $event
                    },
                    close: _vm.closeDialog,
                  },
                },
                [
                  _c("div", { staticClass: "zipCodeParentClass" }, [
                    _c(
                      "div",
                      { staticClass: "searchClass" },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("collectionCenter.query"))),
                        ]),
                        _c(
                          "el-input",
                          {
                            attrs: {
                              clearable: "",
                              placeholder: _vm.$t(
                                "collectionCenter.pleaseInput"
                              ),
                            },
                            on: {
                              input: function ($event) {
                                return _vm.onInput()
                              },
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.searchZipCode($event)
                              },
                            },
                            model: {
                              value: _vm.areaForm.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.areaForm,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "areaForm.name",
                            },
                          },
                          [
                            _c("template", { slot: "append" }, [
                              _c("div", { on: { click: _vm.searchZipCode } }, [
                                _vm._v(
                                  _vm._s(_vm.$t("collectionCenter.confirm"))
                                ),
                              ]),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-card",
                          { staticClass: "scoll highlightClass" },
                          [
                            _c("el-tree", {
                              ref: "zipcodeRef",
                              attrs: {
                                data: _vm.zipcodeTreeData,
                                "show-checkbox": "",
                                "default-expand-all": true,
                                props: _vm.defaultProps,
                                "node-key": "id",
                                "current-node-key": _vm.currentNodekey,
                                "default-expanded-keys": _vm.defaultOpen,
                                "highlight-current": _vm.highlightCurrent,
                                "default-checked-keys": _vm.defaultChecked,
                                "render-content": _vm.renderContent,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var data = ref.data
                                      return _c(
                                        "span",
                                        { staticClass: "custom-tree-node" },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(data.zipCode)),
                                          ]),
                                        ]
                                      )
                                    },
                                  },
                                ],
                                null,
                                false,
                                2547650468
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.zipcodeSave },
                          },
                          [_vm._v(_vm._s(_vm.$t("collectionCenter.Save")))]
                        ),
                        _c("el-button", { on: { click: _vm.closeDialog } }, [
                          _vm._v(_vm._s(_vm.$t("collectionCenter.cancel"))),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticStyle: { "margin-top": "30px" } },
        [
          _c(
            "el-table",
            {
              ref: "tableRef",
              attrs: { border: "", "max-height": 600, data: _vm.tableDate },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.ind"),
                  type: "index",
                  width: "60",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.tableQueryForm.pageNumber - 1) *
                                _vm.tableQueryForm.pageSize +
                                scope.$index +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "id",
                  align: "center",
                  label: _vm.$t("collectionCenter.SerialNumber"),
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "areaName",
                  align: "center",
                  label: _vm.$t("collectionCenter.regionN"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "startEndzipCode",
                  align: "center",
                  label: _vm.$t("collectionCenter.sendRegional"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(" " + _vm._s(scope.row.startEndzipCode) + " "),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm.modify
                ? _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: _vm.$t("collectionCenter.operation"),
                      width: "100",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-edit-outline",
                                    disabled: _vm.btnDisablad.editBtn,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editClick(scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("collectionCenter.modify"))
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2892097553
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c("el-pagination", {
                staticStyle: { margin: "10px 0" },
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  "current-page": _vm.tableQueryForm.pageNumber,
                  "page-sizes": [10, 20, 50, 100, 500, 1000],
                  "page-size": _vm.tableQueryForm.pageSize,
                  total: _vm.tableTotal,
                  background: "",
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.tableQueryForm, "pageNumber", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.tableQueryForm, "pageNumber", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.tableQueryForm, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.tableQueryForm, "pageSize", $event)
                  },
                  "current-change": _vm.getTableData,
                  "size-change": _vm.getPageSize,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }