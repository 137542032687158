import request from '@/utils/request';

// 财务分区分页
export const apiByServiceCode = (data, isBool = true) => {
  // return request.post('/fa/partition/page', data);
  return request({
    method: 'POST',
    url: '/fa/siteExpense/export/byServiceCode',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
