import { requestScripting } from '@/lib/axios';

export const uploadFileService = {
  async getAll(skip = 0, take = 10, numLote = '') {
    const queryString = numLote ? `?numLote=${numLote}` : '';

    return requestScripting({
      baseURL: process.env.VUE_APP_REVERSE_PANINI,
      url: `panini/getLote/${take}/${skip}${queryString}`
    });
  },
  async create(numLote, codGroup, body) {
    return requestScripting({
      'Content-Type': 'multipart/form-data',
      baseURL: process.env.VUE_APP_REVERSE_PANINI,
      url: `panini/uploadExcel?numLote=${numLote}&codGroup=${codGroup}`,
      method: 'POST',
      data: body
    });
  },
  async downloadFile(numLote, barcode) {
    return requestScripting({
      responseType: 'blob',
      baseURL: process.env.VUE_APP_REVERSE_PANINI,
      url: `edition/createRomaneio?numLote=${numLote}&barcode=${barcode}`,
      method: 'GET'
    });
  }
};
