<script >
import { ref, defineProps } from '@vue/composition-api';
import { default as FormVehicle } from '../../../../transport/order/components/form/steps/formVehicle.vue';
import { default as FormDriver } from '../../../../transport/order/components/form/steps/formDriver';
import { default as FormTrailer } from '../../../../transport/order/components/form/steps/formTrailer';
import { useWizardForm } from '@/composable/useWizardForm';
import { createEquipamento } from '@/api/transfer/transferRoute/query';
const __sfc_main = {};
__sfc_main.props = {
  closeModal: {
    type: Function
  },
  routeId: {
    type: Number
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const createEquipment = createEquipamento();
  const formSteps = ref({
    formStepDriver: {
      document: null,
      username: null,
      name: null,
      helpers: []
    },
    formStepVehicle: {
      plate: null
    },
    formStepTrailer: {
      trailerPlate: ''
    }
  });
  async function handleSubmit(formStepsData) {
    const dataEquipment = {
      ...formStepsData.value.formStepDriver,
      ...formStepsData.value.formStepVehicle,
      ...formStepsData.value.formStepTrailer,
      routeId: props.routeId
    };
    const data = getBody(dataEquipment);
    await createEquipment.mutate(data, {
      onSuccess: () => {
        props.closeModal();
      }
    });
  }
  function getBody(data) {
    const {
      document,
      plate,
      trailerPlate,
      username,
      helpers,
      routeId
    } = data;
    return {
      driver_document: document,
      vehicle_license_plate: plate,
      trailer_license_plate: trailerPlate,
      username,
      helpers,
      route_id: parseInt(routeId),
      equipment_is_active: 1
    };
  }
  const STEPS_TOTAL = 3;
  const {
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  } = useWizardForm(formSteps, handleSubmit, STEPS_TOTAL);
  return {
    formSteps,
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  };
};
__sfc_main.components = Object.assign({
  FormDriver,
  FormVehicle,
  FormTrailer
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <FormDriver
      v-show="step === 0"
      :form-data.sync="formSteps.formStepDriver"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
    />

    <FormVehicle
      v-show="step === 1"
      :form-data.sync="formSteps.formStepVehicle"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
    />

    <FormTrailer
      v-show="step === 2"
      :form-data.sync="formSteps.formStepTrailer"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
    />
  </div>

</template>
