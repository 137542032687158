import { render, staticRenderFns } from "./zipZone.vue?vue&type=template&id=31e11c54&scoped=true"
import script from "./zipZone.vue?vue&type=script&lang=js"
export * from "./zipZone.vue?vue&type=script&lang=js"
import style0 from "./zipZone.vue?vue&type=style&index=0&id=31e11c54&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31e11c54",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/work/jenkins/workspace/pre预发布-anjun-express-web-management/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('31e11c54')) {
      api.createRecord('31e11c54', component.options)
    } else {
      api.reload('31e11c54', component.options)
    }
    module.hot.accept("./zipZone.vue?vue&type=template&id=31e11c54&scoped=true", function () {
      api.rerender('31e11c54', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/collectionCenter/BasicData/zipZone.vue"
export default component.exports