<!-- 运输干支线 -->
<template>
  <div class="meet-collect">
    <el-card style="margin-bottom: 5px;">
      <div class="operational">
        <!-- 操作栏 -->
        <ActionCenter
          ref="operationalRef"
          :query-form="queryForm"
          :select-data="selectionList"
          :business-type="businessType"
          :payable-type="payableType"
          @refresh="onRefresh"
          @recalculatePrice="onRecalculatePrice"
        />

        <!-- 查询 -->
        <el-button
          type="primary" icon="el-icon-search" size="small"
          @click="isSearch = true"
        >{{ $t('receivablePayable.Search') }}</el-button>
      </div>
    </el-card>

    <el-card>
      <!-- 列表 -->
      <TableCmp
        ref="tableCmpRef"
        :query-form="queryForm"
        :table-header="diffCustomColumn"
        :payable-type="payableType"
        @editFee="editFee"
        @selection-change="handleSelectionChange"
      />
    </el-card>

    <!-- 搜索弹出框 -->
    <el-drawer
      :append-to-body="true"
      :title="$t('receivablePayable.Search')"
      :visible.sync="isSearch"
      custom-class="drawerClass"
      direction="rtl"
      size="50%"
    >
      <QueryCmp @search="onSearch" @close="isSearch = false;" :payable-type="payableType" />
    </el-drawer>

    <!-- 重算价格 -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('receivablePayable.RecalculateThePrice')"
      :visible.sync="recalculatePriceObj.isBool"
      width="80%"
      @close="onCloseRecalculatePrice(false)"
    >
      <RecalculatePrice
        :key="recalculatePriceObj.key"
        ref="recalculatePriceRef"
        :calculation-type="recalculatePriceObj.calculationType"
        :collecting-dispatch="collectingDispatch"
        :get-param="queryForm.params"
        :import-data="importData"
        :import-list="importList"
        :post-data="recalculatePriceObj.postData"
        :select-data="selectionList"
        @changePage="onCloseRecalculatePrice"
      />
    </el-dialog>
  </div>
</template>

<script>
import ActionCenter from '@/views/finance/payableManage/feesPayable/component/ActionCenter.vue';
import TableCmp from '@/views/finance/payableManage/feesPayable/component/TableCmp.vue';
import { queryStore, beforeSearch, TF_TABLE_HEADER } from '@/views/finance/payableManage/feesPayable/enums.js';
import { COLLECTING_DISPATCH_OBJ } from './enums';
import { commonMinXin } from '@/views/finance/payableManage/feesPayable/commonMInXin.js';

export default {
  name: 'TransportationTrunkFeeder',

  components: {
    TableCmp,
    ActionCenter,
    QueryCmp: () => import('@/views/finance/payableManage/feesPayable/component/QuerySearchCmp.vue'),
    RecalculatePrice: () => import('@/views/finance/payableManage/feesPayable/component/RecalculatePrice.vue')
  },

  mixins: [commonMinXin],

  data() {
    return {
      businessType: 1, // 业务类型
      payableType: 5, // 账单类型 运输干支线
      operationMode: 1, // 派送模式 0 AJD 1 DSP
      isSearch: false,
      // 搜索
      queryForm: {
        ...queryStore,
        params: {
          ...queryStore.params
        }
      },
      selectionList: [], // 勾选

      recalculatePriceObj: { // 重新计算价格
        isBool: false,
        calculationType: 0, // 重算的类型
        postData: {} // 重算提交数据
      },

      // 重算的参数
      collectingDispatch: {
        ...COLLECTING_DISPATCH_OBJ
      },
      importData: [], // 导入数据
      importList: [], // 导入列表
      TF_TABLE_HEADER,
    };
  },

  created() {
    this.updaQueryForm();
  },

  methods: {
    updaQueryForm() {
      this.queryForm.params.businessType = this.businessType;
      this.queryForm.params.payableType = this.payableType;
      this.queryForm.params.operationMode = '';
    },
    // 搜索
    onSearch(queryForm) {
      this.isSearch = false;
      Object.assign(this.queryForm.params, { ...queryForm });
      this.updaQueryForm();
      this.onRefresh();
    },

    // 更新数据重新刷新列表
    onRefresh() {
      // 若查询条件为空，不刷新
      // 校验时间和单号二选一
      {
        const isBool = beforeSearch(this.queryForm.params);
        if (!isBool) {
          return;
        }
      }
      this.$refs.tableCmpRef.onSearch();
    },

    // 详情查看
    editFee(row) {
      const { feeSource } = row;
      row.payableType = this.payableType;
      // 快录生成
      if (feeSource === 3) {
        this.$refs.operationalRef.onQREditFee(row);
        return;
      }
      this.$refs.operationalRef.openNotQRDetail(row);
    },

    // 勾选
    handleSelectionChange(arr) {
      this.selectionList = arr;
    },

    /**
     * 加载重算
     * @param isBool
     */
    onRecalculatePrice(data) {
      this.recalculatePriceObj = {
        ...data
      };
      if (data.calculationType === 2) {
        this.importData = data.importData;
      }
      if (data.calculationType === 3) {
        this.importList = data.importData;
      }
      this.$nextTick(() => {
        // 获取重算数据
        this.$refs.recalculatePriceRef.getRecalculateTable();
      });
    },
    // 重算关闭
    onCloseRecalculatePrice(isBool) {
      this.recalculatePriceObj.isBool = false;
      if (isBool) {
        // 刷新
        this.onRefresh();
      }
    }
  }

};
</script>

<style scoped>
@import '../index.scss';

::v-deep .el-drawer__body {
	height: 100% !important;
}
</style>

