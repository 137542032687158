var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WrapperHandleError",
    { attrs: { error: _vm.error, "is-error": _vm.isError } },
    [
      _c("SectionHeader", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("h1", { staticClass: "header-1" }, [
                  _vm._v("Rotas de transferência"),
                ]),
                _c(
                  "o-button",
                  {
                    attrs: { variant: "primary", size: "md" },
                    on: { click: _vm.handleVisibilityModalCreate },
                  },
                  [_vm._v("Criar rota")]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "filter",
            fn: function () {
              return [
                _c(
                  "o-field",
                  {
                    staticClass: "flex-1",
                    attrs: { label: "Status da rota", "label-for": "status" },
                  },
                  [
                    _c(
                      "o-select",
                      {
                        attrs: {
                          id: "status",
                          placeholder: "Selecione o status",
                        },
                        model: {
                          value: _vm.filters.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "status", $$v)
                          },
                          expression: "filters.status",
                        },
                      },
                      [
                        _c(
                          "option",
                          { domProps: { value: _vm.FiltersEnum.ACTIVE } },
                          [_vm._v("Ativa")]
                        ),
                        _c(
                          "option",
                          { domProps: { value: _vm.FiltersEnum.IN_PROGRESS } },
                          [_vm._v("Em andamento")]
                        ),
                        _c(
                          "option",
                          { domProps: { value: _vm.FiltersEnum.FINISHED } },
                          [_vm._v("Finalizados")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "SkeletonTable",
        { attrs: { "is-loading": _vm.isLoading } },
        [
          _c(
            "o-table",
            {
              staticClass: "space-m-y-32",
              attrs: {
                data: _vm.dataTable,
                paginated: "",
                "backend-pagination": "",
                total: _vm.totalTransferRoute,
                "per-page": _vm.queryParams.perPage,
                "current-page": _vm.queryParams.currentPage,
                "pagination-simple": false,
              },
              on: {
                "update:currentPage": function ($event) {
                  return _vm.$set(_vm.queryParams, "currentPage", $event)
                },
                "update:current-page": function ($event) {
                  return _vm.$set(_vm.queryParams, "currentPage", $event)
                },
              },
            },
            [
              _c("o-table-column", {
                attrs: { field: "route_identification", label: "Rota ID" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(
                          " " + _vm._s(props.row.route_identification) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("o-table-column", {
                attrs: { labe: "", width: "144" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("Actions", {
                          attrs: { filters: _vm.filters, row: row },
                          on: {
                            "update:filters": function ($event) {
                              _vm.filters = $event
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "o-modal",
        {
          attrs: { active: _vm.isOpenModalCreate, width: "560" },
          on: { close: _vm.handleVisibilityModalCreate },
        },
        [
          _c("div", [
            _c("h2", { staticClass: "header-2" }, [_vm._v("Criando rota")]),
            _c("p", { staticClass: "paragraph" }, [
              _vm._v(
                "Preencha os campos abaixo para criar uma rota de transferência em nosso sistema"
              ),
            ]),
          ]),
          _c("FormCreateTransferRoute", {
            attrs: { "close-modal": _vm.handleVisibilityModalCreate },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }