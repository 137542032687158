var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ProblemDeliveryBox" },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c(
            "el-form",
            { staticClass: "selectClass" },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "flex-wrap": "wrap" },
                  attrs: { justify: "start", type: "flex" },
                },
                [
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 10 } },
                    [
                      _c("el-form-item", [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "flex-start",
                              "align-items": "center",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "selectTimeClass autoWidth wid",
                                attrs: {
                                  placeholder: _vm.$t(
                                    "collectionCenter.pleaseChoose"
                                  ),
                                },
                                on: { change: _vm.changeTime },
                                model: {
                                  value: _vm.selectTime,
                                  callback: function ($$v) {
                                    _vm.selectTime = $$v
                                  },
                                  expression: "selectTime",
                                },
                              },
                              [
                                _c("template", { slot: "prefix" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (
                                          _vm.timeList.find(function (item) {
                                            return item.value === _vm.selectTime
                                          }) || {}
                                        ).time
                                      ) +
                                      " "
                                  ),
                                ]),
                                _vm._l(_vm.timeList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.time,
                                      value: item.value,
                                    },
                                  })
                                }),
                              ],
                              2
                            ),
                            _c("el-date-picker", {
                              attrs: {
                                clearable: false,
                                "default-time": ["00:00:00", "23:59:59"],
                                "end-placeholder": _vm.$t(
                                  "collectionCenter.EndTime"
                                ),
                                "range-separator": _vm.$t(
                                  "collectionCenter.to"
                                ),
                                "start-placeholder": _vm.$t(
                                  "collectionCenter.StartTime"
                                ),
                                type: "daterange",
                                "value-format": "yyyy-MM-dd",
                              },
                              on: { change: _vm.dateChange },
                              model: {
                                value: _vm.timeValue,
                                callback: function ($$v) {
                                  _vm.timeValue = $$v
                                },
                                expression: "timeValue",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { staticClass: "col2" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "selectTimeClass autoWidth",
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "collectionCenter.pleaseChoose"
                                    ),
                                  },
                                  on: { change: _vm.changeCode },
                                  model: {
                                    value: _vm.selectCode,
                                    callback: function ($$v) {
                                      _vm.selectCode = $$v
                                    },
                                    expression: "selectCode",
                                  },
                                },
                                [
                                  _c("template", { slot: "prefix" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          (
                                            _vm.selectCodeList.find(function (
                                              item
                                            ) {
                                              return (
                                                item.value === _vm.selectCode
                                              )
                                            }) || {}
                                          ).label
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _vm._l(
                                    _vm.selectCodeList,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm.selectCode == "c3"
                            ? _c(
                                "el-col",
                                { staticStyle: { width: "auto" } },
                                [
                                  _c(
                                    "el-form-item",
                                    { staticClass: "siteClass" },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "collectionCenter.BatchQuery"
                                          ),
                                          autosize: "",
                                          type: "textarea",
                                        },
                                        nativeOn: {
                                          keydown: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.BlockNewlineLogistics(
                                              $event
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.queryForm.params.scanNumberList,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryForm.params,
                                              "scanNumberList",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "queryForm.params.scanNumberList",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.selectCode == "c1"
                            ? _c(
                                "el-col",
                                { staticStyle: { width: "auto" } },
                                [
                                  _c(
                                    "el-form-item",
                                    { staticClass: "siteClass" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "packageNumberClass" },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              autosize: { maxRows: 5 },
                                              placeholder: _vm.$t(
                                                "collectionCenter.BatchQuery"
                                              ),
                                              type: "textarea",
                                            },
                                            nativeOn: {
                                              keydown: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.BlockNewline($event)
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.queryForm.params
                                                  .packageNumber,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.queryForm.params,
                                                  "packageNumber",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "queryForm.params.packageNumber",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.selectCode == "c2"
                            ? _c(
                                "el-col",
                                { staticStyle: { width: "auto" } },
                                [
                                  _c(
                                    "el-form-item",
                                    { staticClass: "siteClass" },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "collectionCenter.BatchQuery"
                                          ),
                                          autosize: "",
                                          type: "textarea",
                                        },
                                        nativeOn: {
                                          keydown: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.BlockNewlineOrder($event)
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.queryForm.params
                                              .orderNumberList,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryForm.params,
                                              "orderNumberList",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "queryForm.params.orderNumberList",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.orderStatus"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                value: 5,
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.queryForm.params.collectStatus,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "collectStatus",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.collectStatus",
                              },
                            },
                            _vm._l(_vm.orderTypeList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.collectStatusName,
                                  value: item.collectStatus,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.CustomerN"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              on: {
                                change: _vm.changeMerchant,
                                focus: _vm.getAllCustomer,
                              },
                              model: {
                                value: _vm.queryForm.params.customerId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "customerId",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.customerId",
                              },
                            },
                            _vm._l(
                              _vm.customerNameList,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.username,
                                    value: item.id,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "col10",
                      staticStyle: { width: "auto" },
                      attrs: { span: 6 },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "m" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selectTimeClass autoWidth",
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                              },
                              on: { change: _vm.changeMerchant },
                              model: {
                                value: _vm.merchantChoose,
                                callback: function ($$v) {
                                  _vm.merchantChoose = $$v
                                },
                                expression: "merchantChoose",
                              },
                            },
                            [
                              _c("template", { slot: "prefix" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      (
                                        _vm.merchantCodeOrName.find(function (
                                          item
                                        ) {
                                          return item.m === _vm.merchantChoose
                                        }) || {}
                                      ).name
                                    ) +
                                    " "
                                ),
                              ]),
                              _vm._l(
                                _vm.merchantCodeOrName,
                                function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: { label: item.name, value: item.m },
                                  })
                                }
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-select",
                            {
                              attrs: {
                                loading: _vm.loading,
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseInput"
                                ),
                                "remote-method": _vm.remoteMethod,
                                clearable: "",
                                filterable: "",
                                remote: "",
                                "reserve-keyword": "",
                              },
                              on: { change: _vm.changeValue },
                              model: {
                                value: _vm.merchantCodeValue,
                                callback: function ($$v) {
                                  _vm.merchantCodeValue = $$v
                                },
                                expression: "merchantCodeValue",
                              },
                            },
                            _vm._l(
                              _vm.merchantCodeList,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: item.name },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "collectionCenter.SourceProblemPiece"
                            ),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.queryForm.params.problemSource,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "problemSource",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.problemSource",
                              },
                            },
                            _vm._l(
                              _vm.problemSourceList,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: item.come },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.TypesProblem"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                clearable: "",
                              },
                              model: {
                                value: _vm.queryForm.params.problemItemType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "problemItemType",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.problemItemType",
                              },
                            },
                            [
                              _c("el-option", {
                                key: "0",
                                attrs: {
                                  label: _vm.$t("collectionCenter.tudo"),
                                  value: null,
                                },
                              }),
                              _c("el-option", {
                                key: "1",
                                attrs: {
                                  label: _vm.$t("collectionCenter.collect"),
                                  value: 0,
                                },
                              }),
                              _c("el-option", {
                                key: "2",
                                attrs: {
                                  label: _vm.$t("collectionCenter.dispatch"),
                                  value: 1,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.$t("collectionCenter.registrationNetwork") +
                              "：",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              on: {
                                focus: _vm.getNewestSite,
                                change: _vm.setSite,
                              },
                              model: {
                                value: _vm.problemSite,
                                callback: function ($$v) {
                                  _vm.problemSite = $$v
                                },
                                expression: "problemSite",
                              },
                            },
                            _vm._l(_vm.siteIdList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.name,
                                  value: item.id + "/" + item.name,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "collectionCenter.PlatformWhichBelongs"
                            ),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                clearable: "",
                              },
                              model: {
                                value: _vm.queryForm.params.platform,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "platform",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.platform",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { value: 1, label: "shein" },
                              }),
                              _c("el-option", {
                                attrs: { value: 2, label: "kwai" },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t(
                                    "collectionCenter.expressDelivery"
                                  ),
                                  value: 3,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("collectionCenter.other"),
                                  value: 0,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:problem:ProblemDelivery:search",
                          expression:
                            "'btn:collection-center:problem:ProblemDelivery:search'",
                          arg: "remove",
                        },
                      ],
                      attrs: {
                        icon: "el-icon-search",
                        size: "mini",
                        type: "primary",
                      },
                      on: { click: _vm.searchClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.search")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:problem:ProblemDelivery:reset",
                          expression:
                            "'btn:collection-center:problem:ProblemDelivery:reset'",
                          arg: "remove",
                        },
                      ],
                      attrs: { icon: "el-icon-refresh", size: "mini" },
                      on: { click: _vm.resetClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:problem:ProblemDelivery:register",
                          expression:
                            "'btn:collection-center:problem:ProblemDelivery:register'",
                          arg: "remove",
                        },
                      ],
                      attrs: { size: "mini" },
                      on: { click: _vm.ProblemClick },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("collectionCenter.ProblemFileRegistration")
                        ) + " "
                      ),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:problem:ProblemDelivery:allExport",
                          expression:
                            "'btn:collection-center:problem:ProblemDelivery:allExport'",
                          arg: "remove",
                        },
                      ],
                      attrs: { icon: "el-icon-upload2", size: "mini" },
                      on: { click: _vm.download },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              attrs: { data: _vm.tableDate, "max-height": 600, border: "" },
              on: { select: _vm.batchSelect, "select-all": _vm.batchSelectAll },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.ind"),
                  align: "center",
                  type: "index",
                  width: "60",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.queryForm.pageNumber - 1) *
                                _vm.queryForm.pageSize +
                                scope.$index +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.CustomerName"),
                  prop: "customerName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.MerchantName"),
                  prop: "merchantName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.platform"),
                  align: "center",
                  prop: "platform",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.platformObj[scope.row.platform]) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.parcelNum"),
                  prop: "packageNumber",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.logisticsNum"),
                  prop: "scanNumber",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.lanshouOrderNum"),
                  prop: "orderNumber",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.lanshouT"),
                  prop: "scanTime",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("newOrder.clientExitTime"),
                  prop: "customerSendTime",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.SourcePPiece"),
                  prop: "problemSource",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                scope.row.problemSource === 0
                                  ? "pc"
                                  : scope.row.problemSource === 1
                                  ? "pda"
                                  : ""
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.CreationTime"),
                  prop: "orderCreateTime",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.OrderStatus"),
                  prop: "collectStatus",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                scope.row.collectStatus == 0
                                  ? _vm.$t("collectionCenter.notLanShou")
                                  : scope.row.collectStatus == 1
                                  ? _vm.$t("collectionCenter.hasLanShou")
                                  : scope.row.collectStatus == 2
                                  ? _vm.$t("collectionCenter.cancelled")
                                  : scope.row.collectStatus == 4
                                  ? _vm.$t("collectionCenter.scheduled")
                                  : scope.row.collectStatus == 5
                                  ? _vm.$t("collectionCenter.AbnormalLanShou")
                                  : scope.row.collectStatus == 6
                                  ? _vm.$t("collectionCenter.haveBeenSigned")
                                  : scope.row.collectStatus == 7
                                  ? _vm.$t("collectionCenter.ProblemPiece")
                                  : scope.row.collectStatus == 8
                                  ? _vm.$t("collectionCenter.SealedBag")
                                  : scope.row.collectStatus == 9
                                  ? _vm.$t("collectionCenter.truckLoading")
                                  : scope.row.collectStatus == 10
                                  ? _vm.$t("collectionCenter.unloading")
                                  : scope.row.collectStatus == 11
                                  ? _vm.$t("collectionCenter.Bagged")
                                  : scope.row.collectStatus == 12
                                  ? _vm.$t("collectionCenter.closedLoop")
                                  : scope.row.collectStatus == 13
                                  ? _vm.$t("collectionCenter.unclaimed")
                                  : scope.row.collectStatus == 14
                                  ? _vm.$t(
                                      "collectionCenter.collectionPointInventory"
                                    )
                                  : ""
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.problemPieceType"),
                  prop: "problemItemTypeName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.CauseOfTheP"),
                  prop: "problemTypeName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.registrar"),
                  prop: "problemName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.registrantSiteName"),
                  prop: "registrantSiteName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.RegistrationT"),
                  prop: "createTime",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.enclosureFJ"),
                  align: "center",
                  prop: "problemImg",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              title: _vm.$t("collectionCenter.enclosureFJ"),
                              content: "",
                              placement: "left-start",
                              trigger: "hover",
                              width: "300",
                            },
                          },
                          [
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: scope.row.problemImg,
                                  expression: "scope.row.problemImg",
                                },
                              ],
                              staticStyle: { width: "26px", height: "36px" },
                              attrs: {
                                slot: "reference",
                                src: scope.row.problemImg,
                                alt: "",
                              },
                              slot: "reference",
                            }),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !scope.row.problemImg,
                                    expression: "!scope.row.problemImg",
                                  },
                                ],
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [_vm._v(_vm._s(_vm.$t("collectionCenter.Null")))]
                            ),
                            scope.row.problemImg
                              ? _c("img", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                  attrs: { src: scope.row.problemImg, alt: "" },
                                })
                              : _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("collectionCenter.NoContentYet")
                                    )
                                  ),
                                ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.Note"),
                  prop: "problemReason",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-pagination", {
            staticStyle: { margin: "10px 0" },
            attrs: {
              "current-page": _vm.queryForm.pageNumber,
              "page-size": _vm.queryForm.pageSize,
              "page-sizes": [10, 20, 50, 100, 500, 1000],
              total: _vm.total,
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "current-change": _vm.getProblemDeliveryPage,
              "size-change": _vm.getPageSize,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("collectionCenter.ProblemFileRegistration"),
            visible: _vm.dialogVisible,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c("div", { staticClass: "registerBox" }, [
            _c(
              "div",
              { staticClass: "registerContent" },
              [
                _c(
                  "el-form",
                  {
                    ref: "registerQueryRef",
                    attrs: { model: _vm.registerQueryForm, rules: _vm.rules },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "typesProblemClass",
                                attrs: {
                                  label: _vm.$t(
                                    "collectionCenter.TypesProblem"
                                  ),
                                  prop: "problemItemType",
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    on: { change: _vm.problemTypeChange },
                                    model: {
                                      value:
                                        _vm.registerQueryForm.problemItemType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.registerQueryForm,
                                          "problemItemType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "registerQueryForm.problemItemType",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: 0 } }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("collectionCenter.collect")
                                        )
                                      ),
                                    ]),
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("collectionCenter.dispatch")
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "collectionCenter.CauseOfTheProblem"
                                  ),
                                  prop: "problemType",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "collectionCenter.pleaseChoose"
                                      ),
                                      clearable: "",
                                      filterable: "",
                                    },
                                    on: {
                                      change: _vm.changeProblem,
                                      focus: function ($event) {
                                        return _vm.getProblemList(
                                          _vm.registerQueryForm.problemItemType
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.registerQueryForm.problemType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.registerQueryForm,
                                          "problemType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "registerQueryForm.problemType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.CauseProblemList,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.dictDescribe,
                                          value:
                                            item.dictValue +
                                            "~" +
                                            item.problemTypeSub,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("collectionCenter.note"),
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "collectionCenter.pleaseInput"
                                    ),
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.registerQueryForm.problemReason,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.registerQueryForm,
                                        "problemReason",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "registerQueryForm.problemReason",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c(
                            "div",
                            { staticClass: "enclosureClass" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("collectionCenter.enclosure"),
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "uploadClass" },
                                    [
                                      _c(
                                        "el-upload",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.upLoadFile,
                                              expression: "!upLoadFile",
                                            },
                                          ],
                                          ref: "demoUpload",
                                          staticClass: "upload-demo",
                                          attrs: {
                                            "before-upload": _vm.beforeUpload,
                                            "http-request": _vm.upLoad,
                                            limit: 1,
                                            action: "",
                                            drag: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "el-upload__tip",
                                              attrs: { slot: "tip" },
                                              slot: "tip",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "collectionCenter.OnlyImageFormatIsSupported"
                                                  )
                                                ) + " "
                                              ),
                                            ]
                                          ),
                                          _c("i", {
                                            staticClass: "el-icon-plus",
                                            staticStyle: {
                                              margin: "50px auto 20px",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "el-upload__text" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "collectionCenter.cldrUpload"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.upLoadFile,
                                          expression: "upLoadFile",
                                        },
                                      ],
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          width: "auto",
                                          height: "200px",
                                        },
                                        attrs: { src: _vm.upLoadFile, alt: "" },
                                        on: {
                                          load: function ($event) {
                                            return _vm.loadImg(_vm.upLoadFile)
                                          },
                                        },
                                      }),
                                      _c("div", {
                                        staticClass:
                                          "el-icon-delete-solid imgDelClass",
                                        on: { click: _vm.delImg },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-delete", type: "danger" },
                  on: { click: _vm.drawerResetClick },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.empty")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-check", type: "primary" },
                  on: { click: _vm.saveClick },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.Save")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
        },
        [
          _c("audio", {
            ref: "errRef",
            attrs: { src: _vm.errorMp3, controls: "controls" },
          }),
          _c("audio", {
            ref: "okRef",
            attrs: { src: _vm.okMp3, controls: "controls" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }