<script >
import { ref, watch, computed } from '@vue/composition-api';
// import { useQuery } from '@tanstack/vue-query';

import { default as SkeletonTable } from '@/components/skeletonTable';
import { default as WrapperHandleError } from '@/components/wrapperHandleError';
import { default as SectionHeader } from '@/components/sectionHeader';
import { default as FormCreateVehicle } from './form/formCreateVehicle';
import { default as Actions } from './actions';
import { useModal } from '@/composable/useModal';
import { useGetAllVehicles, useGetRotateVehicles } from '@/api/transport/vehicle/query';
// import { getSkipPage } from '@/utils/getSkipPage';
// import { vehicleService } from '@/api/transport/vehicle';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const filters = ref({
    plate: null,
    status: 1
  });
  const queryParams = ref({
    status: 1,
    plate: null,
    currentPage: 1,
    perPage: 10
  });
  const queryParamsRotateVehicles = ref({
    currentPage: 1,
    perPage: 10
  });
  const {
    data,
    isError,
    isLoading,
    isFetched,
    error
  } = useGetAllVehicles(queryParams);
  const {
    data: dataRotateVehicle,
    isLoading: isLoadingRotateVehicle,
    isFetched: isFetchedRotateVehicle
  } = useGetRotateVehicles(queryParamsRotateVehicles);
  const tableData = computed(() => {
    return data.value?.data?.result ?? data.value;
  });
  const tableTotal = computed(() => {
    return data.value?.data?.activeVehicles ?? 1;
  });
  const vehicleDataOnRotation = computed(() => {
    return dataRotateVehicle.value?.data?.result ?? dataRotateVehicle.value;
  });
  const totalVehicleOnRotation = computed(() => {
    return dataRotateVehicle.value?.data?.allRestrictVehicles ?? 1;
  });
  const isLoadingTable = computed(() => {
    if (filters.value.status === 2) {
      return isLoadingRotateVehicle.value || !isFetchedRotateVehicle.value;
    } else {
      return isLoading.value || !isFetched.value;
    }
  });
  const currentPagePagination = computed({
    get() {
      return filters.value.status === 2 ? queryParamsRotateVehicles.value.currentPage : queryParams.value.currentPage;
    },
    set(newValue) {
      if (filters.value.status === 2) {
        queryParamsRotateVehicles.value.currentPage = newValue;
      } else {
        queryParams.value.currentPage = newValue;
      }
    }
  });
  watch(filters.value, newFilters => {
    if (filters.value.status === 2) {
      if (queryParamsRotateVehicles.value.currentPage > 1) {
        queryParamsRotateVehicles.value = {
          ...newFilters,
          currentPage: 1,
          perPage: 10
        };
      } else {
        queryParamsRotateVehicles.value = {
          ...queryParamsRotateVehicles.value,
          ...newFilters
        };
      }
    } else {
      if (queryParams.value.currentPage > 1) {
        queryParams.value = {
          ...newFilters,
          currentPage: 1,
          perPage: 10
        };
      } else {
        queryParams.value = {
          ...queryParams.value,
          ...newFilters
        };
      }
    }
  });
  const handleModalCreate = () => {
    handleVisibilityModalCreate();
    filters.value.status = 1;
  };

  /* handle create vehicle */
  const {
    isOpen: isOpenModalCreate,
    handleVisibility: handleVisibilityModalCreate
  } = useModal();
  return {
    filters,
    queryParams,
    queryParamsRotateVehicles,
    isError,
    error,
    tableData,
    tableTotal,
    vehicleDataOnRotation,
    totalVehicleOnRotation,
    isLoadingTable,
    currentPagePagination,
    handleModalCreate,
    isOpenModalCreate,
    handleVisibilityModalCreate
  };
};
__sfc_main.components = Object.assign({
  WrapperHandleError,
  SectionHeader,
  SkeletonTable,
  Actions,
  FormCreateVehicle
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <WrapperHandleError :error="error" :is-error="isError">

    <SectionHeader>
      <template #header>
        <h1 class="header-1">{{ $t('vehicle.vehicleTitle') }}</h1>
        <o-button variant="primary" size="md" @click="handleModalCreate">{{ $t('vehicle.createVehicle') }}</o-button>
      </template>

      <template #filter>
        <o-field
          v-show="filters.status !== 2" :label="$t('vehicle.plateLabel')" label-for="plate"
          class="flex-2"
        >
          <o-input id="plate" v-model="filters.plate" :placeholder="$t('vehicle.platePlaceholder')" />
        </o-field>

        <o-field :label="$t('vehicle.statusLabel')" label-for="status" class="flex-1">
          <o-select id="status" v-model="filters.status" placeholder="Selecione o status">
            <option :value="1">{{ $t('vehicle.activeStatus') }}</option>
            <option :value="0">{{ $t('vehicle.disabledStatus') }}</option>
            <option :value="2">{{ $t('vehicle.rotationStatus') }}</option>
          </o-select>
        </o-field>
      </template>
    </SectionHeader>

    <SkeletonTable :is-loading="isLoadingTable">
      <o-table
        :data="filters.status === 2 ? vehicleDataOnRotation : tableData "
        :row-key="'id'"
        bordered
        class="space-m-y-32"
        paginated
        backend-pagination
        :total="filters.status === 2 ? totalVehicleOnRotation : tableTotal"
        :per-page="filters.status === 2 ? queryParamsRotateVehicles.perPage : queryParams.perPage"
        :current-page.sync="currentPagePagination"
        :pagination-simple="false"
      >
        <o-table-column v-slot="props" field="vehicle_documentation" :label="$t('vehicle.documentLabel')">
          {{ props.row.vehicle_documentation }}
        </o-table-column>

        <o-table-column v-slot="props" field="vehicle_license_plate" :label="$t('vehicle.plateLabel')">
          {{ props.row.vehicle_license_plate }}
        </o-table-column>

        <o-table-column v-slot="props" field="vehicle_RNTRC" :label="$t('vehicle.rntrc')">
          {{ props.row.vehicle_RNTRC }}
        </o-table-column>
        <o-table-column v-slot="props" field="vehicle_model" :label="$t('vehicle.modelLabel')">
          {{ props.row.vehicle_model }}
        </o-table-column>

        <o-table-column v-slot="props" field="vehicle_brand" :label="$t('vehicle.brandLabel')">
          {{ props.row.vehicle_brand }}
        </o-table-column>

        <o-table-column v-slot="props" field="vehicle_body_type" :label="$t('vehicle.bodyTypeLabel')">
          {{ props.row.vehicle_body_type }}
        </o-table-column>

        <o-table-column v-slot="props" field="vehicle_wheelset_type" :label="$t('vehicle.wheelTypeLabel')">
          {{ props.row.vehicle_wheelset_type }}
        </o-table-column>

        <o-table-column v-slot="props" field="vehicle_type_ownership" :label="$t('vehicle.ownerLabel')">
          {{
            props.row.vehicle_type_ownership ?
              props.row.vehicle_owner_CNPJ : props.row.vehicle_owner_CPF
          }}
        </o-table-column>

        <o-table-column v-slot="props" field="vehicle_state" :label="$t('vehicle.stateLabel')">
          {{ props.row.vehicle_state }}
        </o-table-column>

        <o-table-column v-slot="props" field="vehicle_outsourced" :label="$t('vehicle.outsourcedLabel')">
          {{ props.row.vehicle_outsourced ? $t('vehicle.yes') : $t('vehicle.no') }}
        </o-table-column>

        <o-table-column label="">
          <template slot-scope="{row}">
            <Actions :row="row" :status="filters.status" />
          </template>
        </o-table-column>

        <template #empty>
          {{ $t('vehicle.noResults') }}
        </template>
      </o-table>
    </SkeletonTable>

    <o-modal :active="isOpenModalCreate" width="560" @close="handleVisibilityModalCreate">
      <div>
        <h2 class="header-2">{{ $t('vehicle.createTitle') }}</h2>
        <p class="paragraph">{{ $t('vehicle.createParagraph') }}</p>
      </div>

      <FormCreateVehicle :close-modal="handleVisibilityModalCreate" />
    </o-modal>
  </WrapperHandleError>
</template>

<style scoped>
.flex-1{
  flex: 1;
}

.flex-2 {
  flex: 2;
}
</style>

