var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "lanShouOrdersBox" }, [
    _c(
      "div",
      { staticStyle: { "margin-bottom": "20px" } },
      [
        _c(
          "el-form",
          [
            _c(
              "el-row",
              { staticClass: "selectClass" },
              [
                _c(
                  "el-col",
                  { staticStyle: { width: "auto" }, attrs: { span: 7 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticClass: "col2" },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "selectTimeClass autoWidth",
                                attrs: {
                                  placeholder: _vm.$t(
                                    "collectionCenter.pleaseChoose"
                                  ),
                                },
                                on: { change: _vm.changeTime },
                                model: {
                                  value: _vm.selectTime,
                                  callback: function ($$v) {
                                    _vm.selectTime = $$v
                                  },
                                  expression: "selectTime",
                                },
                              },
                              [
                                _c("template", { slot: "prefix" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (
                                          _vm.timeList.find(function (item) {
                                            return item.value === _vm.selectTime
                                          }) || {}
                                        ).time
                                      ) +
                                      " "
                                  ),
                                ]),
                                _vm._l(_vm.timeList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.time,
                                      value: item.value,
                                    },
                                  })
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "timeSelectClass",
                            staticStyle: { width: "auto" },
                          },
                          [
                            _c(
                              "el-form-item",
                              { staticClass: "delMl" },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    clearable: false,
                                    type: "datetimerange",
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    "default-time": ["00:00:00", "23:59:59"],
                                    "range-separator": _vm.$t(
                                      "collectionCenter.to"
                                    ),
                                    "start-placeholder": _vm.$t(
                                      "collectionCenter.StartTime"
                                    ),
                                    "end-placeholder": _vm.$t(
                                      "collectionCenter.EndTime"
                                    ),
                                  },
                                  on: { change: _vm.dateChange },
                                  model: {
                                    value: _vm.timeValue,
                                    callback: function ($$v) {
                                      _vm.timeValue = $$v
                                    },
                                    expression: "timeValue",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "widthClass", attrs: { span: 6 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("collectionCenter.orderStatus"),
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              multiple: "",
                              placeholder: _vm.$t(
                                "collectionCenter.pleaseChoose"
                              ),
                              clearable: "",
                              filterable: "",
                            },
                            model: {
                              value: _vm.queryForm.params.collectStatus,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm.params,
                                  "collectStatus",
                                  $$v
                                )
                              },
                              expression: "queryForm.params.collectStatus",
                            },
                          },
                          _vm._l(_vm.orderTypeList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: {
                                label: item.cStatusName,
                                value: item.cStatus,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "widthClass", attrs: { span: 6 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("collectionCenter.invoiceType"),
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: _vm.$t(
                                "collectionCenter.pleaseChoose"
                              ),
                              clearable: "",
                              filterable: "",
                            },
                            model: {
                              value: _vm.queryForm.params.invoiceType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm.params,
                                  "invoiceType",
                                  $$v
                                )
                              },
                              expression: "queryForm.params.invoiceType",
                            },
                          },
                          _vm._l(_vm.invoiceTypeList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.name, value: item.val },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "widthClass", attrs: { span: 6 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: _vm.$t("collectionCenter.CustomerN") },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: _vm.$t(
                                "collectionCenter.pleaseChoose"
                              ),
                              clearable: "",
                              filterable: "",
                            },
                            on: { focus: _vm.focusCustomer },
                            model: {
                              value: _vm.queryForm.params.customerId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm.params,
                                  "customerId",
                                  $$v
                                )
                              },
                              expression: "queryForm.params.customerId",
                            },
                          },
                          _vm._l(_vm.customerIdList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.username, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "widthClass", attrs: { span: 6 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticClass: "col2" },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "selectTimeClass autoWidth",
                                attrs: {
                                  placeholder: _vm.$t(
                                    "collectionCenter.pleaseChoose"
                                  ),
                                },
                                on: { change: _vm.changeCode },
                                model: {
                                  value: _vm.selectNumber,
                                  callback: function ($$v) {
                                    _vm.selectNumber = $$v
                                  },
                                  expression: "selectNumber",
                                },
                              },
                              [
                                _c("template", { slot: "prefix" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (
                                          _vm.selectCodeList.find(function (
                                            item
                                          ) {
                                            return (
                                              item.value === _vm.selectNumber
                                            )
                                          }) || {}
                                        ).label
                                      ) +
                                      " "
                                  ),
                                ]),
                                _vm._l(
                                  _vm.selectCodeList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _vm.selectNumber == "n1"
                          ? _c(
                              "el-col",
                              { staticClass: "widthClass" },
                              [
                                _c(
                                  "el-form-item",
                                  { staticClass: "siteClass" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        autosize: "",
                                        placeholder: _vm.$t(
                                          "collectionCenter.BatchQuery"
                                        ),
                                      },
                                      on: { blur: _vm.ft1 },
                                      nativeOn: {
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.BlockNewlineOrder($event)
                                        },
                                      },
                                      model: {
                                        value: _vm.queryForm.params.orderNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "orderNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.orderNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.selectNumber == "n2"
                          ? _c(
                              "el-col",
                              { staticClass: "widthClass" },
                              [
                                _c(
                                  "el-form-item",
                                  { staticClass: "siteClass" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        autosize: "",
                                        placeholder: _vm.$t(
                                          "collectionCenter.BatchQuery"
                                        ),
                                      },
                                      on: { blur: _vm.ft2 },
                                      nativeOn: {
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.BlockNewlinePackage($event)
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.queryForm.params.packageNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "packageNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.packageNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.selectNumber == "n3"
                          ? _c(
                              "el-col",
                              { staticClass: "widthClass" },
                              [
                                _c(
                                  "el-form-item",
                                  { staticClass: "siteClass" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        autosize: "",
                                        placeholder: _vm.$t(
                                          "collectionCenter.BatchQuery"
                                        ),
                                      },
                                      on: { blur: _vm.ft3 },
                                      nativeOn: {
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.BlockNewlineCNumber($event)
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.queryForm.params.customerNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "customerNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.customerNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.selectNumber == "n4"
                          ? _c(
                              "el-col",
                              { staticClass: "widthClass" },
                              [
                                _c(
                                  "el-form-item",
                                  { staticClass: "siteClass" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        autosize: "",
                                        placeholder: _vm.$t(
                                          "collectionCenter.BatchQuery"
                                        ),
                                      },
                                      on: { blur: _vm.ft4 },
                                      nativeOn: {
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.BlockNewlineBatchNo($event)
                                        },
                                      },
                                      model: {
                                        value: _vm.queryForm.params.batchNo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "batchNo",
                                            $$v
                                          )
                                        },
                                        expression: "queryForm.params.batchNo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.selectNumber == "n5"
                          ? _c(
                              "el-col",
                              { staticClass: "widthClass" },
                              [
                                _c(
                                  "el-form-item",
                                  { staticClass: "siteClass" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        autosize: "",
                                        placeholder: _vm.$t(
                                          "collectionCenter.BatchQuery"
                                        ),
                                      },
                                      on: { blur: _vm.ft5 },
                                      nativeOn: {
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.BlockNewline5($event)
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.queryForm.params.vehicleNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "vehicleNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.vehicleNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.selectNumber == "n6"
                  ? _c(
                      "el-col",
                      { staticClass: "widthClass" },
                      [
                        _c(
                          "el-form-item",
                          { staticClass: "siteClass" },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                autosize: "",
                                placeholder: _vm.$t(
                                  "collectionCenter.BatchQuery"
                                ),
                              },
                              on: { blur: _vm.ft6 },
                              nativeOn: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.BlockNewline6($event)
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.bagNumber,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "bagNumber",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.bagNumber",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-col",
                  {
                    staticClass: "stateClass",
                    staticStyle: { width: "auto" },
                    attrs: { span: 6 },
                  },
                  [
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "selectTimeClass autoWidth",
                            attrs: {
                              placeholder: _vm.$t(
                                "collectionCenter.pleaseChoose"
                              ),
                            },
                            on: { change: _vm.changeState },
                            model: {
                              value: _vm.selectState,
                              callback: function ($$v) {
                                _vm.selectState = $$v
                              },
                              expression: "selectState",
                            },
                          },
                          [
                            _c("template", { slot: "prefix" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (
                                      _vm.stateList.find(function (item) {
                                        return item.value === _vm.selectState
                                      }) || {}
                                    ).name
                                  ) +
                                  " "
                              ),
                            ]),
                            _vm._l(_vm.stateList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.value },
                              })
                            }),
                          ],
                          2
                        ),
                        _vm.selectState == "s1"
                          ? _c("el-input", {
                              attrs: {
                                type: "textarea",
                                autosize: "",
                                placeholder: _vm.$t(
                                  "collectionCenter.BatchQuery"
                                ),
                              },
                              nativeOn: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.BlockNewlineSenderState($event)
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.senderState,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "senderState",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.senderState",
                              },
                            })
                          : _vm._e(),
                        _vm.selectState == "s2"
                          ? _c("el-input", {
                              attrs: {
                                type: "textarea",
                                autosize: "",
                                placeholder: _vm.$t(
                                  "collectionCenter.BatchQuery"
                                ),
                              },
                              nativeOn: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.BlockNewlineReceiveState($event)
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.receiveState,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "receiveState",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.receiveState",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    staticClass: "stateClass",
                    staticStyle: { width: "auto" },
                    attrs: { span: 6 },
                  },
                  [
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "selectTimeClass autoWidth",
                            attrs: {
                              placeholder: _vm.$t(
                                "collectionCenter.pleaseChoose"
                              ),
                            },
                            on: { change: _vm.changeCity },
                            model: {
                              value: _vm.selectCity,
                              callback: function ($$v) {
                                _vm.selectCity = $$v
                              },
                              expression: "selectCity",
                            },
                          },
                          [
                            _c("template", { slot: "prefix" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (
                                      _vm.cityList.find(function (item) {
                                        return item.value === _vm.selectCity
                                      }) || {}
                                    ).name
                                  ) +
                                  " "
                              ),
                            ]),
                            _vm._l(_vm.cityList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.value },
                              })
                            }),
                          ],
                          2
                        ),
                        _vm.selectCity == "c1"
                          ? _c("el-input", {
                              attrs: {
                                type: "textarea",
                                autosize: "",
                                placeholder: _vm.$t(
                                  "collectionCenter.BatchQuery"
                                ),
                              },
                              nativeOn: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.BlockNewlineSenderCity($event)
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.senderCity,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "senderCity",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.senderCity",
                              },
                            })
                          : _vm._e(),
                        _vm.selectCity == "c2"
                          ? _c("el-input", {
                              attrs: {
                                type: "textarea",
                                autosize: "",
                                placeholder: _vm.$t(
                                  "collectionCenter.BatchQuery"
                                ),
                              },
                              nativeOn: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.BlockNewlineReceiveCity($event)
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.receiveCity,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "receiveCity",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.receiveCity",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticStyle: { width: "auto" }, attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("collectionCenter.LanShouSite") + "：",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              filterable: "",
                              placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                              clearable: "",
                              multiple: "",
                            },
                            on: { focus: _vm.getBranchList },
                            model: {
                              value: _vm.queryForm.params.siteIdList,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm.params,
                                  "siteIdList",
                                  $$v
                                )
                              },
                              expression: "queryForm.params.siteIdList",
                            },
                          },
                          _vm._l(_vm.siteIdL, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c(
              "el-col",
              { attrs: { span: 24 } },
              [
                _c(
                  "el-popover",
                  {
                    staticClass: "popoverClass",
                    attrs: {
                      placement: "bottom-start",
                      title: "",
                      trigger: "hover",
                      content: "x",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permit",
                            rawName: "v-permit:remove",
                            value:
                              "btn:collection-center:order:receive-data-processing:find",
                            expression:
                              "'btn:collection-center:order:receive-data-processing:find'",
                            arg: "remove",
                          },
                        ],
                        attrs: {
                          slot: "reference",
                          type: "primary",
                          size: "mini",
                          icon: "el-icon-search",
                        },
                        slot: "reference",
                      },
                      [_vm._v(_vm._s(_vm.$t("collectionCenter.search")))]
                    ),
                    _c("div", [
                      _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                type: "primary",
                                plain: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.searchClick("part")
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("collectionCenter.oneselfQuery"))
                              ),
                            ]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                type: "primary",
                                plain: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.searchClick()
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("collectionCenter.criterionQuery")
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value:
                          "btn:collection-center:order:receive-data-processing:reset",
                        expression:
                          "'btn:collection-center:order:receive-data-processing:reset'",
                        arg: "remove",
                      },
                    ],
                    attrs: { icon: "el-icon-refresh", size: "mini" },
                    on: { click: _vm.resetClick },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")))]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value:
                          "btn:collection-center:order:receive-data-processing:allExport",
                        expression:
                          "'btn:collection-center:order:receive-data-processing:allExport'",
                        arg: "remove",
                      },
                    ],
                    class: _vm.isClickAll ? "onlyClickOnce10" : "",
                    attrs: { icon: "el-icon-upload2", size: "mini" },
                    on: { click: _vm.downloadAll },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")))]
                ),
                _c(
                  "el-popover",
                  {
                    staticClass: "uploadClass",
                    attrs: {
                      placement: "bottom-start",
                      title: "",
                      trigger: "hover",
                      content: "x",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permit",
                            rawName: "v-permit:remove",
                            value:
                              "btn:collection-center:order:receive-data-processing:import",
                            expression:
                              "'btn:collection-center:order:receive-data-processing:import'",
                            arg: "remove",
                          },
                        ],
                        attrs: {
                          slot: "reference",
                          size: "mini",
                          icon: "el-icon-download",
                        },
                        slot: "reference",
                      },
                      [_vm._v(_vm._s(_vm.$t("collectionCenter.Import")))]
                    ),
                    _c("div", [
                      _c(
                        "div",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "onlyClickOnce2",
                              staticStyle: { margin: "10px" },
                              attrs: { size: "mini" },
                              on: { click: _vm.downloadClick },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("collectionCenter.TemplateDownload")
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "uploadFileClass",
                              attrs: {
                                action: "",
                                limit: 1,
                                "show-file-list": false,
                                "file-list": _vm.fileList,
                                "before-upload": _vm.beforeUpload,
                                "http-request": _vm.importt,
                              },
                            },
                            [
                              _c("el-button", { attrs: { size: "mini" } }, [
                                _vm._v(
                                  _vm._s(_vm.$t("operationCenter.Import"))
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("el-divider"),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "tableClass" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            ref: "tableList",
            attrs: { "max-height": 600, border: "", data: _vm.tableDate },
          },
          [
            _c("el-table-column", {
              attrs: { type: "selection", width: "55" },
            }),
            _c("el-table-column", {
              attrs: {
                label: _vm.$t("collectionCenter.ind"),
                type: "index",
                width: "60",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            (_vm.queryForm.pageNumber - 1) *
                              _vm.queryForm.pageSize +
                              scope.$index +
                              1
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm.defaultSelection.length <= 0
              ? _c("el-table-column", { attrs: { align: "center", label: "" } })
              : _vm._e(),
            _vm.allSelectList[0].isShow
              ? _c("el-table-column", {
                  key: "k0",
                  attrs: {
                    align: "center",
                    prop: "orderNumber",
                    label: _vm.$t("collectionCenter.lanshouOrderNum"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[1].isShow
              ? _c("el-table-column", {
                  key: "k1",
                  attrs: {
                    align: "center",
                    prop: "scanTime",
                    label: _vm.$t("collectionCenter.collectScanTime"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[2].isShow
              ? _c("el-table-column", {
                  key: "k2",
                  attrs: {
                    align: "center",
                    prop: "signForScanTime",
                    label: _vm.$t("collectionCenter.signatureScanTime"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[3].isShow
              ? _c("el-table-column", {
                  key: "k3",
                  attrs: {
                    align: "center",
                    prop: "uploadTime",
                    label: _vm.$t("collectionCenter.collectionScanUploadTime"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[4].isShow
              ? _c("el-table-column", {
                  key: "k4",
                  attrs: {
                    align: "center",
                    prop: "orderStatus",
                    label: _vm.$t("collectionCenter.OrderStatus"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[5].isShow
              ? _c("el-table-column", {
                  key: "k5",
                  attrs: {
                    align: "center",
                    prop: "trackCreateTime",
                    label: _vm.$t("collectionCenter.collectionTrackCreateTime"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[6].isShow
              ? _c("el-table-column", {
                  key: "k6",
                  attrs: {
                    align: "center",
                    prop: "trackPushTime",
                    label: _vm.$t("collectionCenter.receivingTrackPushTime"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[7].isShow
              ? _c("el-table-column", {
                  key: "k7",
                  attrs: {
                    align: "center",
                    prop: "signForUploadTime",
                    label: _vm.$t("collectionCenter.signatureUploadTime"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[8].isShow
              ? _c("el-table-column", {
                  key: "k8",
                  attrs: {
                    align: "center",
                    prop: "customerCode",
                    label: _vm.$t("collectionCenter.clientID"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[9].isShow
              ? _c("el-table-column", {
                  key: "k9",
                  attrs: {
                    align: "center",
                    prop: "customerName",
                    label: _vm.$t("collectionCenter.CustomerName"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[10].isShow
              ? _c("el-table-column", {
                  key: "k10",
                  attrs: {
                    align: "center",
                    prop: "signForTrackCreateTime",
                    label: _vm.$t("collectionCenter.signatureTrackCreateTime"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[11].isShow
              ? _c("el-table-column", {
                  key: "k11",
                  attrs: {
                    align: "center",
                    prop: "signForTrackPushTime",
                    label: _vm.$t("collectionCenter.signatureTrackPushTime"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[12].isShow
              ? _c("el-table-column", {
                  key: "k12",
                  attrs: {
                    align: "center",
                    prop: "merchantName",
                    label: _vm.$t("collectionCenter.MerchantName"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[13].isShow
              ? _c("el-table-column", {
                  key: "k13",
                  attrs: {
                    align: "center",
                    prop: "merchantCode",
                    label: _vm.$t("collectionCenter.MerchantNum"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[14].isShow
              ? _c("el-table-column", {
                  key: "k14",
                  attrs: {
                    align: "center",
                    prop: "uploadUser",
                    label: _vm.$t("collectionCenter.LanShouPeople"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[15].isShow
              ? _c("el-table-column", {
                  key: "k15",
                  attrs: {
                    align: "center",
                    prop: "siteName",
                    label: _vm.$t("collectionCenter.collectionDot"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[16].isShow
              ? _c("el-table-column", {
                  key: "k16",
                  attrs: {
                    align: "center",
                    prop: "signForUploadUser",
                    label: _vm.$t("collectionCenter.signer"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[17].isShow
              ? _c("el-table-column", {
                  key: "k17",
                  attrs: {
                    align: "center",
                    prop: "signerSiteName",
                    label: _vm.$t("collectionCenter.deliveryDot"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[18].isShow
              ? _c("el-table-column", {
                  key: "k18",
                  attrs: {
                    align: "center",
                    prop: "operationType",
                    label: _vm.$t("collectionCenter.actualCollectionMode"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[19].isShow
              ? _c("el-table-column", {
                  key: "k19",
                  attrs: {
                    align: "center",
                    prop: "collectTypeNmae",
                    label: _vm.$t("collectionCenter.receivingMode"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[20].isShow
              ? _c("el-table-column", {
                  key: "k20",
                  attrs: {
                    align: "center",
                    prop: "weight",
                    label: _vm.$t("collectionCenter.bagWeight"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[21].isShow
              ? _c("el-table-column", {
                  key: "k21",
                  attrs: {
                    align: "center",
                    prop: "goodsValue",
                    label: _vm.$t("collectionCenter.declaredValue"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[22].isShow
              ? _c("el-table-column", {
                  key: "k22",
                  attrs: {
                    align: "center",
                    prop: "collectCost",
                    label: _vm.$t("collectionCenter.collectionFee"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[23].isShow
              ? _c("el-table-column", {
                  key: "k23",
                  attrs: {
                    align: "center",
                    prop: "expectTime",
                    label: _vm.$t("collectionCenter.ExpectedCollectionTime"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[24].isShow
              ? _c("el-table-column", {
                  key: "k24",
                  attrs: {
                    align: "center",
                    prop: "platformName",
                    label: _vm.$t("collectionCenter.platform"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[25].isShow
              ? _c("el-table-column", {
                  key: "k25",
                  attrs: {
                    align: "center",
                    prop: "createTime",
                    label: _vm.$t("collectionCenter.orderCreateT"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[26].isShow
              ? _c("el-table-column", {
                  key: "k26",
                  attrs: {
                    align: "center",
                    prop: "receiveCity",
                    label: _vm.$t("collectionCenter.receiveCity"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[27].isShow
              ? _c("el-table-column", {
                  key: "k27",
                  attrs: {
                    align: "center",
                    prop: "senderCity",
                    label: _vm.$t("collectionCenter.SenderCity"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[28].isShow
              ? _c("el-table-column", {
                  key: "k28",
                  attrs: {
                    align: "center",
                    prop: "receivePhone",
                    label: _vm.$t("collectionCenter.RecipientPhoneNumber"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[29].isShow
              ? _c("el-table-column", {
                  key: "k29",
                  attrs: {
                    align: "center",
                    prop: "senderPhone",
                    label: _vm.$t("collectionCenter.senderPhone"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[30].isShow
              ? _c("el-table-column", {
                  key: "k30",
                  attrs: {
                    align: "center",
                    prop: "senderZipcode",
                    label: _vm.$t("collectionCenter.senderZipCode"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[31].isShow
              ? _c("el-table-column", {
                  key: "k31",
                  attrs: {
                    align: "center",
                    prop: "receiveZipcode",
                    label: _vm.$t("collectionCenter.Recipientzc"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[32].isShow
              ? _c("el-table-column", {
                  key: "k32",
                  attrs: {
                    align: "center",
                    prop: "senderAddress",
                    label: _vm.$t("collectionCenter.SendAddress"),
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-tooltip", { attrs: { placement: "top" } }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "max-height": "200px",
                                    overflow: "auto",
                                    width: "500px",
                                  },
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [_vm._v(_vm._s(scope.row.senderAddress))]
                              ),
                              _c("div", { staticClass: "paramsStyle" }, [
                                _vm._v(_vm._s(scope.row.senderAddress) + " "),
                              ]),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3877539473
                  ),
                })
              : _vm._e(),
            _vm.allSelectList[33].isShow
              ? _c("el-table-column", {
                  key: "k33",
                  attrs: {
                    align: "center",
                    prop: "receiveAddress",
                    label: _vm.$t("collectionCenter.receiptArs"),
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-tooltip", { attrs: { placement: "top" } }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "max-height": "200px",
                                    overflow: "auto",
                                    width: "500px",
                                  },
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [_vm._v(_vm._s(scope.row.receiveAddress))]
                              ),
                              _c("div", { staticClass: "paramsStyle" }, [
                                _vm._v(_vm._s(scope.row.receiveAddress) + " "),
                              ]),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    223447665
                  ),
                })
              : _vm._e(),
            _vm.allSelectList[34].isShow
              ? _c("el-table-column", {
                  key: "k34",
                  attrs: {
                    align: "center",
                    prop: "receiveName",
                    label: _vm.$t("collectionCenter.recipientS"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[35].isShow
              ? _c("el-table-column", {
                  key: "k35",
                  attrs: {
                    align: "center",
                    prop: "senderName",
                    label: _vm.$t("collectionCenter.addresser"),
                  },
                })
              : _vm._e(),
            _vm.allSelectList[36].isShow
              ? _c("el-table-column", {
                  key: "k36",
                  attrs: {
                    align: "center",
                    prop: "invoiceType",
                    label: _vm.$t("collectionCenter.invoiceT"),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allSelectList[37].isShow
              ? _c("el-table-column", {
                  key: "k37",
                  attrs: {
                    align: "center",
                    prop: "receiveState",
                    label: _vm.$t("collectionCenter.RecipientProvince"),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allSelectList[38].isShow
              ? _c("el-table-column", {
                  key: "k38",
                  attrs: {
                    align: "center",
                    prop: "packageNumber",
                    label: _vm.$t("collectionCenter.parcelNum"),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allSelectList[39].isShow
              ? _c("el-table-column", {
                  key: "k39",
                  attrs: {
                    align: "center",
                    prop: "closeTime",
                    label: _vm.$t("collectionCenter.closeTime"),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allSelectList[40].isShow
              ? _c("el-table-column", {
                  key: "k40",
                  attrs: {
                    align: "center",
                    prop: "siteLoadedUserName",
                    label: _vm.$t("collectionCenter.networkLoader"),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allSelectList[41].isShow
              ? _c("el-table-column", {
                  key: "k41",
                  attrs: {
                    align: "center",
                    prop: "siteLoadedScanTime",
                    label: _vm.$t("collectionCenter.networkLoadScanTime"),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allSelectList[42].isShow
              ? _c("el-table-column", {
                  key: "k42",
                  attrs: {
                    align: "center",
                    prop: "siteLoadedVehicleNumber",
                    label: _vm.$t("collectionCenter.networkLoadVehicleNum"),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allSelectList[43].isShow
              ? _c("el-table-column", {
                  key: "k43",
                  attrs: {
                    align: "center",
                    prop: "siteLoadedUploadTime",
                    label: _vm.$t("collectionCenter.networkLoadUploadingTime"),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allSelectList[44].isShow
              ? _c("el-table-column", {
                  key: "k44",
                  attrs: {
                    align: "center",
                    prop: "transferLoadedUserName",
                    label: _vm.$t("collectionCenter.transferCenterLoader"),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allSelectList[45].isShow
              ? _c("el-table-column", {
                  key: "k45",
                  attrs: {
                    align: "center",
                    prop: "transferLoadedScanTime",
                    label: _vm.$t("collectionCenter.transferLoadScanTime"),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allSelectList[46].isShow
              ? _c("el-table-column", {
                  key: "k46",
                  attrs: {
                    align: "center",
                    prop: "transferLoadedVehicleNumber",
                    label: _vm.$t("collectionCenter.transferLoadVehicleNum"),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allSelectList[47].isShow
              ? _c("el-table-column", {
                  key: "k47",
                  attrs: {
                    align: "center",
                    prop: "transferLoadedUploadTime",
                    label: _vm.$t("collectionCenter.transferLoadUploadingTime"),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allSelectList[48].isShow
              ? _c("el-table-column", {
                  key: "k48",
                  attrs: {
                    align: "center",
                    prop: "siteUnloadUserName",
                    label: _vm.$t("collectionCenter.outletUnloader"),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allSelectList[49].isShow
              ? _c("el-table-column", {
                  key: "k49",
                  attrs: {
                    align: "center",
                    prop: "siteUnloadTime",
                    label: _vm.$t("collectionCenter.outletUnloadingTime"),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allSelectList[50].isShow
              ? _c("el-table-column", {
                  key: "k50",
                  attrs: {
                    align: "center",
                    prop: "transferUnloadUserName",
                    label: _vm.$t("collectionCenter.unloadingTransferCenterP"),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allSelectList[51].isShow
              ? _c("el-table-column", {
                  key: "k51",
                  attrs: {
                    align: "center",
                    prop: "transferUnloadTime",
                    label: _vm.$t(
                      "collectionCenter.unloadingTransferCenterTime"
                    ),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allSelectList[52].isShow
              ? _c("el-table-column", {
                  key: "k52",
                  attrs: {
                    align: "center",
                    prop: "senderState",
                    label: _vm.$t("orderCenterCont.SenderProvince"),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allSelectList[53].isShow
              ? _c("el-table-column", {
                  key: "k53",
                  attrs: {
                    label: _vm.$t("GlobalSetObj.BagSealingTime"),
                    align: "center",
                    prop: "bagSealingTime",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allSelectList[54].isShow
              ? _c("el-table-column", {
                  key: "k54",
                  attrs: {
                    label: _vm.$t("GlobalSetObj.BagSealer"),
                    align: "center",
                    prop: "bagSealUserName",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allSelectList[55].isShow
              ? _c("el-table-column", {
                  key: "k55",
                  attrs: {
                    label: _vm.$t("GlobalSetObj.LogisticsInvoiceNumber"),
                    align: "center",
                    prop: "logisticsInvoiceNo",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allSelectList[56].isShow
              ? _c("el-table-column", {
                  key: "k56",
                  attrs: {
                    label: _vm.$t("GlobalSetObj.LogisticsInvoiceKey"),
                    align: "center",
                    prop: "logisticsInvoiceSecretKey",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allSelectList[57].isShow
              ? _c("el-table-column", {
                  key: "k57",
                  attrs: {
                    label: _vm.$t("operatingPlatform.volumeWeight"),
                    align: "center",
                    prop: "volumeWeight",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allSelectList[58].isShow
              ? _c("el-table-column", {
                  key: "k58",
                  attrs: {
                    label: _vm.$t("operatingPlatform.scanWeight"),
                    align: "center",
                    prop: "weighingWeight",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allSelectList[59].isShow
              ? _c("el-table-column", {
                  key: "k59",
                  attrs: {
                    align: "center",
                    prop: "finishSignUrl",
                    label: _vm.$t("collectionCenter.lanshouImg"),
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-popover",
                              {
                                key: "" + scope.row.finishSignUrl,
                                attrs: {
                                  title: _vm.$t("collectionCenter.lanshouImg"),
                                  content: "",
                                  placement: "left",
                                  trigger: "hover",
                                  width: "400",
                                },
                              },
                              [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: scope.row.finishSignUrl,
                                      expression: "scope.row.finishSignUrl",
                                    },
                                  ],
                                  staticStyle: {
                                    width: "26px",
                                    height: "36px",
                                  },
                                  attrs: {
                                    slot: "reference",
                                    src: scope.row.finishSignUrl,
                                    alt: "",
                                  },
                                  slot: "reference",
                                }),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !scope.row.finishSignUrl,
                                        expression: "!scope.row.finishSignUrl",
                                      },
                                    ],
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("collectionCenter.Null"))
                                    ),
                                  ]
                                ),
                                scope.row.finishSignUrl
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%",
                                      },
                                      attrs: {
                                        src: scope.row.finishSignUrl,
                                        alt: "",
                                      },
                                    })
                                  : _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "collectionCenter.NoContentYet"
                                          )
                                        )
                                      ),
                                    ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1369677557
                  ),
                })
              : _vm._e(),
            _vm.allSelectList[60].isShow
              ? _c("el-table-column", {
                  key: "k60",
                  attrs: {
                    align: "center",
                    prop: "signerImg",
                    label: _vm.$t("collectionCenter.signImage"),
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-popover",
                              {
                                key: "" + scope.row.signerImg,
                                attrs: {
                                  title: _vm.$t("collectionCenter.signImage"),
                                  content: "",
                                  placement: "left",
                                  trigger: "hover",
                                  width: "400",
                                },
                              },
                              [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: scope.row.signerImg,
                                      expression: "scope.row.signerImg",
                                    },
                                  ],
                                  staticStyle: {
                                    width: "26px",
                                    height: "36px",
                                  },
                                  attrs: {
                                    slot: "reference",
                                    src: scope.row.signerImg,
                                    alt: "",
                                  },
                                  slot: "reference",
                                }),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !scope.row.signerImg,
                                        expression: "!scope.row.signerImg",
                                      },
                                    ],
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("collectionCenter.Null"))
                                    ),
                                  ]
                                ),
                                scope.row.signerImg
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%",
                                      },
                                      attrs: {
                                        src: scope.row.signerImg,
                                        alt: "",
                                      },
                                    })
                                  : _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "collectionCenter.NoContentYet"
                                          )
                                        )
                                      ),
                                    ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    781532064
                  ),
                })
              : _vm._e(),
            _vm.allSelectList[61].isShow
              ? _c("el-table-column", {
                  key: "k61",
                  attrs: {
                    align: "center",
                    prop: "customerNumber",
                    label: _vm.$t("collectionCenter.customerOrderNum"),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allSelectList[62].isShow
              ? _c("el-table-column", {
                  key: "k62",
                  attrs: {
                    align: "center",
                    prop: "batchNo",
                    label: _vm.$t("collectionCenter.receiveBatchNumber"),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allSelectList[63].isShow
              ? _c("el-table-column", {
                  key: "k63",
                  attrs: {
                    align: "center",
                    prop: "wmsSiteLoadSignImg",
                    label: _vm.$t(
                      "collectionCenter.networkLoadingSignaturePicture"
                    ),
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-popover",
                              {
                                key: "" + scope.row.wmsSiteLoadSignImg,
                                attrs: {
                                  title: _vm.$t(
                                    "collectionCenter.networkLoadingSignaturePicture"
                                  ),
                                  content: "",
                                  placement: "left-start",
                                  trigger: "hover",
                                  width: "300",
                                },
                              },
                              [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: scope.row.wmsSiteLoadSignImg,
                                      expression:
                                        "scope.row.wmsSiteLoadSignImg",
                                    },
                                  ],
                                  staticStyle: {
                                    width: "26px",
                                    height: "36px",
                                  },
                                  attrs: {
                                    slot: "reference",
                                    src: scope.row.wmsSiteLoadSignImg,
                                    alt: "",
                                  },
                                  slot: "reference",
                                }),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !scope.row.wmsSiteLoadSignImg,
                                        expression:
                                          "!scope.row.wmsSiteLoadSignImg",
                                      },
                                    ],
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("collectionCenter.Null"))
                                    ),
                                  ]
                                ),
                                scope.row.wmsSiteLoadSignImg
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%",
                                      },
                                      attrs: {
                                        src: scope.row.wmsSiteLoadSignImg,
                                        alt: "",
                                      },
                                    })
                                  : _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "collectionCenter.NoContentYet"
                                          )
                                        )
                                      ),
                                    ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    635439502
                  ),
                })
              : _vm._e(),
            _vm.allSelectList[64].isShow
              ? _c("el-table-column", {
                  key: "k64",
                  attrs: {
                    align: "center",
                    prop: "transferCenterLoadSignImg",
                    label: _vm.$t(
                      "collectionCenter.transferCenterLoadingSignaturePicture"
                    ),
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-popover",
                              {
                                key: "" + scope.row.transferCenterLoadSignImg,
                                attrs: {
                                  title: _vm.$t(
                                    "collectionCenter.transferCenterLoadingSignaturePicture"
                                  ),
                                  content: "",
                                  placement: "left-start",
                                  trigger: "hover",
                                  width: "300",
                                },
                              },
                              [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        scope.row.transferCenterLoadSignImg,
                                      expression:
                                        "scope.row.transferCenterLoadSignImg",
                                    },
                                  ],
                                  staticStyle: {
                                    width: "26px",
                                    height: "36px",
                                  },
                                  attrs: {
                                    slot: "reference",
                                    src: scope.row.transferCenterLoadSignImg,
                                    alt: "",
                                  },
                                  slot: "reference",
                                }),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          !scope.row.transferCenterLoadSignImg,
                                        expression:
                                          "!scope.row.transferCenterLoadSignImg",
                                      },
                                    ],
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("collectionCenter.Null"))
                                    ),
                                  ]
                                ),
                                scope.row.transferCenterLoadSignImg
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%",
                                      },
                                      attrs: {
                                        src: scope.row
                                          .transferCenterLoadSignImg,
                                        alt: "",
                                      },
                                    })
                                  : _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "collectionCenter.NoContentYet"
                                          )
                                        )
                                      ),
                                    ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    558744240
                  ),
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "right" },
      [
        _c("el-pagination", {
          staticStyle: { margin: "10px 0" },
          attrs: {
            layout: "total, sizes, prev, pager, next, jumper",
            "current-page": _vm.queryForm.pageNumber,
            "page-sizes": [10, 20, 50, 100, 500, 1000],
            "page-size": _vm.queryForm.pageSize,
            total: _vm.total,
            background: "",
          },
          on: {
            "update:currentPage": function ($event) {
              return _vm.$set(_vm.queryForm, "pageNumber", $event)
            },
            "update:current-page": function ($event) {
              return _vm.$set(_vm.queryForm, "pageNumber", $event)
            },
            "update:pageSize": function ($event) {
              return _vm.$set(_vm.queryForm, "pageSize", $event)
            },
            "update:page-size": function ($event) {
              return _vm.$set(_vm.queryForm, "pageSize", $event)
            },
            "current-change": _vm.getDate,
            "size-change": function ($event) {
              return _vm.searchClick("size")
            },
          },
        }),
      ],
      1
    ),
    _vm.dialogVisible
      ? _c(
          "div",
          [
            _c(
              "el-dialog",
              {
                attrs: {
                  title: _vm.dialogTitle,
                  visible: _vm.dialogVisible,
                  "close-on-click-modal": false,
                  width: "80%",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogVisible = $event
                  },
                  close: _vm.closeSelectDialog,
                },
              },
              [
                _c("div", { staticClass: "dialogBox" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "span",
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: { indeterminate: _vm.isIndeterminate },
                              on: { change: _vm.allSelect },
                              model: {
                                value: _vm.checkAll,
                                callback: function ($$v) {
                                  _vm.checkAll = $$v
                                },
                                expression: "checkAll",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("collectionCenter.allSelect"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticStyle: { margin: "15px 0" } }),
                      _c(
                        "el-checkbox-group",
                        {
                          on: { change: _vm.partSelect },
                          model: {
                            value: _vm.defaultSelection,
                            callback: function ($$v) {
                              _vm.defaultSelection = $$v
                            },
                            expression: "defaultSelection",
                          },
                        },
                        _vm._l(_vm.allSelectList, function (item, index) {
                          return _c(
                            "el-checkbox",
                            { key: index, attrs: { label: item.val } },
                            [_vm._v(_vm._s(item.selVal))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.dialogConfirm },
                      },
                      [_vm._v(_vm._s(_vm.$t("collectionCenter.confirm")))]
                    ),
                    _c("el-button", { on: { click: _vm.dialogCancel } }, [
                      _vm._v(_vm._s(_vm.$t("collectionCenter.cancel"))),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }