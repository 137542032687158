<!--
 * @Author: 肖强
 * @Date: 2022-05-30 11:24:04
 * @LastEditTime: 2022-06-29 17:27:45
 * @LastEditors: 肖强
 * @Description:
 * @FilePath: \admin-oa-web\src\views\orderCenter\region\regionList.vue
-->
<template>
  <div class="app-container">
    <tableCmp
      v-if="!level"
      :superior="superior"
      :level="0"
    />
    <editDetails
      v-else
      :row="row"
      :superior="superior"
      :dad-data="dadData"
      :parent="parentId"
      @getLevel="getLevel"
    />
  </div>
</template>

<script>
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { englishLetter } from '@/utils/validate';
import editDetails from './cmp/editDetails';
import tableCmp from './cmp/tableCmp';
export default {
  name: 'RegionList',
  components: {
    tableCmp,
    editDetails
  },
  data() {
    return {
      createUser: [],
      country: '',
      province: '',
      city: '',
      level: false,
      nameArr: [],
      extraOld: '',
      newKey: new Date().getTime(),
      newPage: false,
      englishLetter: englishLetter,
      row: {}, // 修改的数据
      superior: {
        country: '',
        province: '',
        city: '',
        countryParentId: '',
        provinceParentId: '',
        cityParentId: ''
      },
      dadData: [], // 存储的零一二级对象数据
      parentId: '' // 上级id
    };
  },
  computed: {
    Hierarchy() {
      return [
        { name: this.$i18n.t('orderCenterCont.country'), level: 0 }, // 国家
        { name: this.$i18n.t('orderCenterCont.province'), level: 1 }, // 省份
        { name: this.$i18n.t('orderCenterCont.city'), level: 2 }, // 城市
        { name: this.$i18n.t('orderCenterCont.region'), level: 3 } // 地区
      ];
    }
  },
  created() {
    this.createUser = getUserIdAndNameObj();
  },
  mounted() {
    // 监听切换
    this.bus.$on('switchLevel', (data) => {
      this.level = 1;
      this.row = data;
      // 判断当前是否存储同级的数据，没有即存储(数组没有数据时直接存储)
      if (this.dadData.length === 0) {
        this.dadData.push(data);
      } else if (data.level !== 2) {
        this.dadData.forEach((item) => {
          if (item.level == data.level) {
            return;
          } else {
            this.dadData.push(data);
          }
        });
      } else {
        return;
      }

      // if (data.level == 0) {
      //   this.superior.country = data.nameCn;
      //   this.superior['0'] = data.id;
      //   this.parentId = data.id;
      //   this.parentId = this.parentId.toString();
      // } else
      if (data.level == 0) {
        this.superior.province = data.nameCn ? data.nameCn : data.nameEn;
        this.superior['0'] = data.id;
        this.parentId = data.id;
        this.parentId = this.parentId.toString();
      } else if (data.level == 1) {
        this.superior.city = data.nameCn ? data.nameCn : data.nameEn;
        this.superior['1'] = data.id;
        this.parentId = data.id;
        this.parentId = this.parentId.toString();
      }
    });
  },
  beforeDestroy() {
    // 关闭监听
    this.bus.$off(['switchLevel']);
  },
  methods: {
    /**
     * @description: 获取当前级数，如果有返回则是返回到国家列表，加载另一个组件
     */
    getLevel() {
      // console.log('切换组件啦');
      // 清空零一二级
      this.dadData = [];
      this.level = false;
    }
  }
};
</script>

<style scoped>
.padding-10 {
  padding: 10px;
}
.drawerClass header {
  border-bottom: 1px solid #ccc;
  margin-bottom: 0;
  padding: 20px 20px 20px;
}
</style>
