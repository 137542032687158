import requestFinance from '@/utils/request-finance';

// 揽收应付账户分页（新）:
export const apiDeliveryPayableAccountPageNew = (data) => {
  return requestFinance({
    method: 'POST',
    url: '/deliveryPayableAccount/page',
    data
  });
};
// 揽收应付账户编辑
export const apiDeliveryPayableAccountEdit = (data) => {
  return requestFinance({
    method: 'POST',
    url: '/deliveryPayableAccount/edit',
    data
  });
};
// santander批量发起支付
export const apiSitePayableSantanderPay = (data) => {
  return requestFinance({
    method: 'POST',
    url: '/sitePayable/santander/pay',
    data
  });
};
// Santander结果查询
export const apiSitePayableSantanderQuery = (data) => {
  return requestFinance({
    method: 'POST',
    url: '/sitePayable/santander/query',
    data
  });
};
// 获取银行下拉选项
export const apiGetBankList = (data) => {
  return requestFinance({
    method: 'get',
    url: '/deliveryPayableAccount/bank/list',
    data
  });
};
