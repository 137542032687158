<template>
  <div>
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-width="100px"
    >
      <!-- 新密码 -->
      <el-form-item :label="$t('customerManagements.newPwd')" prop="newPassword">
        <!-- 请输入新密码 -->
        <el-input
          v-model="ruleForm.newPassword"
          :placeholder="$t('customerManagements.inputNewPwd')"
          minlength="8"
          maxlength="12"
          show-password
        />
        <!-- 必须由 8-12 位数字和字母组成，可选特殊符号 -->
        <span class="tips">{{ $t('customerManagements.inputHint') }}</span><br>
      </el-form-item>
      <!-- 确认密码 -->
      <el-form-item :label="$t('customerManagements.checkPwd')" prop="newPasswordConfirm">
        <!-- 请再次输入新密码 -->
        <el-input
          v-model="ruleForm.newPasswordConfirm"
          :placeholder="$t('customerManagements.inputNewCodeAgain')"
          minlength="8"
          maxlength="12"
          show-password
        />
      </el-form-item>
    </el-form>
    <div class="footer">
      <el-button @click.stop="handleCancel">{{ $t('customerManagements.cancel') }}</el-button><!-- 取消 -->
      <el-button type="primary" class="mr5" @click.stop="handleDetermine('ruleForm')">{{ $t('customerManagements.confirm') }}</el-button><!-- 确定 -->
    </div>

  </div>

</template>

<script>
import md5 from 'js-md5';

export default {

  props: {
    ids: {
      type: Number,
      default: 0
    }
  },

  data() {
    // 密码暂时不做限制
    const passwordCheck = (rule, value, callback) => {
      if (!value.length) {
        // '密码不能为空'
        return callback(new Error(this.$t('customerManagements.passwordNotEmpty')));
      }
      // const reg = /^[\w]{8,12}$/;
      const reg = /(?=.*[0-9].*)(?=.*[A-Z].*)|(?=.*[0-9].*)(?=.*[a-z].*).{8,12}$/;
      if (!reg.test(value)) {
        // '请输入8-12位数字和英文组合'
        // return callback(new Error(this.$t('customerManagements.passwordNotEmpty')));
        return callback(new Error('请输入8-12位数字和英文组合'));
      }
      callback();
    };

    // 确认新密码
    const newPasswordConfirmCheck = (rule, value, callback) => {
      const { newPassword } = this.ruleForm;
      if (value === '') {
        // '请输入确认密码'
        callback(new Error(this.$t('customerManagements.inputConfirmCode')));
        return;
      }
      if (newPassword !== value) {
        // '确认密码与新密码不相同'
        callback(new Error(this.$t('customerManagements.checkPwdNotNewPwd')));
        return;
      }
      callback();
    };

    return {
      ruleForm: {
        newPassword: '',
        newPasswordConfirm: ''
      },
      rules: {
        newPassword: [
          { required: true, validator: passwordCheck, trigger: ['blur', 'change'] }
        ],
        newPasswordConfirm: [
          { required: true, validator: newPasswordConfirmCheck, trigger: ['blur', 'change'] }
        ]
      }
    };
  },

  methods: {
    // 取消
    handleCancel() {
      this.$emit('handleCancel');
    },

    // 确定
    handleDetermine(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        }

        let { newPassword } = this.ruleForm;
        newPassword = md5(md5(newPassword));

        const obj = {
          verificationFlag: false, // 是否需要验证旧密码
          newPassword,
          oldPassword: '',
          customerId: this.ids
        };
        this.$emit('handleDetermine', obj);
      });
    }
  }
};
</script>

<style scoped>
.footer {
  display: flex;
  flex-direction: row-reverse;

}

.mr5 {
  margin-right: 10px;
}
</style>
