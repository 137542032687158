var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.paramTransferData, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          staticStyle: { padding: "10px 15px" },
          attrs: { id: "TransferManifest" + index },
        },
        [
          _c("div", { staticStyle: { "font-size": "16px" } }, [
            _c("div", { staticStyle: { "text-align": "center" } }, [
              _c("div", [_vm._v("Lista de Manifestos de transferencia")]),
              _c(
                "div",
                {
                  staticStyle: { display: "flex", "justify-content": "center" },
                },
                [_c("img", { attrs: { id: "barcode" + index } })]
              ),
              _c("div", { staticStyle: { "font-size": "16px" } }, [
                _vm._v("Data de impressão: " + _vm._s(item.printingTime)),
              ]),
            ]),
            _c("hr"),
            _c("div", { staticStyle: { "line-height": "30px" } }, [
              _c(
                "table",
                {
                  staticStyle: {
                    width: "100%",
                    "text-align": "left",
                    "font-size": "16px",
                  },
                },
                [
                  _c("tr", [
                    _c("td", [
                      _vm._v("Local de partida:" + _vm._s(item.origin)),
                    ]),
                    _c("td"),
                    _c("td"),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("Endereço:" + _vm._s(item.beginAddress))]),
                    _c("td"),
                    _c("td", [_vm._v("Rua Destinatário:" + _vm._s(""))]),
                  ]),
                  _c("tr", [
                    _c("td", [
                      _vm._v("Cidade de partida:" + _vm._s(item.beginCity)),
                    ]),
                    _c("td", [_vm._v("Estado:" + _vm._s(item.beginState))]),
                    _c("td", [
                      _vm._v("Código postal:" + _vm._s(item.beginZipCode)),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", [
                      _vm._v(
                        "Pessoa responsável:" + _vm._s(item.beginCommitment)
                      ),
                    ]),
                    _c("td"),
                    _c("td", [
                      _vm._v(
                        "Tempo de geração:" + _vm._s(item.beginManiGeneraTime)
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("Destino:" + _vm._s(item.destination))]),
                    _c("td"),
                    _c("td", [_vm._v("IATA CODE:" + _vm._s(item.baseCode))]),
                  ]),
                  _c("tr", [
                    _c("td", [
                      _vm._v("Empresa de transporte:" + _vm._s(item.agent)),
                    ]),
                    _c("td"),
                    _c("td"),
                  ]),
                  _c("tr", [
                    _c("td", [
                      _vm._v(
                        "Endereço detalhado do destino:" +
                          _vm._s(item.endAddress)
                      ),
                    ]),
                    _c("td"),
                    _c("td", [_vm._v("Vizinho:" + _vm._s(""))]),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("Cidade:" + _vm._s(item.endCity))]),
                    _c("td", { staticStyle: { width: "20%" } }, [
                      _vm._v("Estado:" + _vm._s(item.endState)),
                    ]),
                    _c("td", [
                      _vm._v("Código postal:" + _vm._s(item.endZipCode)),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", [
                      _vm._v(
                        "Pessoa responsável:" + _vm._s(item.endCommitment)
                      ),
                    ]),
                    _c("td"),
                    _c("td", [
                      _vm._v(
                        "Data de geração da lista:" +
                          _vm._s(item.endManiGeneraTime)
                      ),
                    ]),
                  ]),
                ]
              ),
              _c("div", { staticStyle: { "margin-top": "30px" } }, [
                _c(
                  "table",
                  { staticStyle: { width: "100%", "text-align": "left" } },
                  [
                    _vm._m(0, true),
                    _c("tr", [
                      _c("td", [_vm._v(_vm._s(item.productName))]),
                      _c("td", [_vm._v(_vm._s(item.documentType))]),
                      _c("td", [_vm._v(_vm._s(item.quantityVolumes))]),
                      _c("td", [_vm._v(_vm._s(item.quantity))]),
                      _c("td", [_vm._v(_vm._s(item.totalWeight + "kg"))]),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticStyle: { margin: "20px 0" } }, [
                _c("hr"),
                _c(
                  "table",
                  { staticStyle: { width: "100%", "text-align": "left" } },
                  [
                    _vm._m(1, true),
                    _vm._l(
                      item.packageDetails,
                      function (itemDetail, indexDetail) {
                        return _c("tr", { key: indexDetail }, [
                          _c("td", [_vm._v(_vm._s(itemDetail.waybillNumber))]),
                          _c("td", [_vm._v(_vm._s(itemDetail.invoiceNumber))]),
                          _c("td", [_vm._v(_vm._s(itemDetail.routeNumber))]),
                          _c("td", [_vm._v(_vm._s(itemDetail.volume))]),
                          _c("td", [_vm._v(_vm._s(itemDetail.recipientState))]),
                        ])
                      }
                    ),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Produtos")]),
      _c("th", [_vm._v("Tipo de arquivo")]),
      _c("th", [_vm._v("Volume do pacote")]),
      _c("th", [_vm._v("Número de pacotes na lista")]),
      _c("th", [_vm._v("Soma das pesagens das caixas de trânsito de pacotes")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticStyle: { width: "20%" } }, [
        _vm._v("Número de waybill"),
      ]),
      _c("th", [_vm._v("Número da fatura do produto")]),
      _c("th", [_vm._v("Número de rota")]),
      _c("th", [_vm._v("Volume")]),
      _c("th", [_vm._v("Estado")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }