<template>
  <div class="app-container">
    <el-tabs type="border-card">
      <!-- lazy="true" 会报错 -->
      <!-- 组织管理 -->
      <el-tab-pane :label="$t('organizationManage.OrganizationManagement')" lazy>
        <organization-manage />
        <organization-operation />
      </el-tab-pane>
      <!-- 组织架构图 -->
      <el-tab-pane :label="$t('organizationManage.OrganizationChart')" lazy>
        <organization-chart />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  components: {
    'organization-manage': () => import('./organizationManage'),
    'organization-operation': () => import('./organizationOperation'),
    'organization-chart': () => import('./organizationChart')
  }
};
</script>

<style lang="scss" scoped>
</style>
