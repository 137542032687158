<template>
  <div>
    <div class="app-container" style="height: calc(100vh - 130px);overflow-y: auto;">
      <el-form
        ref="formDataDetail"
        class="queryFormClass"
        :model="formData"
        label-position="right"
        label-width="auto"
        size="small"
      >
        <el-row :gutter="10">
          <el-col :span="24">
            <!--所属供应商-->
            <el-form-item
              :label="$t('GlobalSetObj.SubordinateSupplier')"
              prop="providerId"
              :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            >
              <el-select
                v-model="formData.providerId"
                filterable
                clearable
                style="width: 60%;"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                @change="providerInfoChange()"
              >
                <el-option
                  v-for="item in providerList"
                  :key="item.id"
                  :label="item.providerBody + '（' + item.providerName + '）(' + item.code + ')'"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--派送网点-->
            <el-form-item
              :label="$t('GlobalSetObj.DeliveryPoint') + '：'"
              prop="sendSiteId"
              :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            >
              <el-select
                v-model="formData.sendSiteId"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                style="width: 60%"
                filterable
                clearable
              >
                <!--请先选择应收主体-->
                <el-option
                  v-for="item in outletsList"
                  :key="item.id"
                  :label="item.name + '（' + item.code + '）'"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--派送省份-->
            <el-form-item
              :label="$t('basicData.DeliveryProvince') + '：'"
              prop="stateId"
              :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            >
              <el-select
                v-model="formData.stateId"
                clearable
                filterable
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                style="width: 60%"
                @change="changeState(formData.stateId)"
              >
                <el-option
                  v-for="(item, ind) in allState"
                  :key="ind"
                  :label="item.twoCode + '(' + item.name + ')'"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--城市-->
            <el-form-item
              :label="$t('collectionCenter.city') + '：'"
              prop="cityId"
              :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            >
              <el-select
                v-model="formData.cityId"
                filterable
                clearable
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                style="width: 60%"
              >
                <el-option
                  v-for="item in allCity"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--邮编区间-->
            <el-form-item
              :label="$t('basicData.ZipCodeSection') + '：'"
              prop="postalCode"
              :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            >
              <el-input
                v-model="formData.postalCode"
                style="width: 60%"
                type="textarea"
                :placeholder="$t('basicData.ZipCodeExample')"
                :rows="4"
                @change="zipcodeCollation(formData.postalCode)"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--派送距离-->
            <el-form-item
              :label="$t('basicData.DeliveryDistance') + '：'"
              prop="sendDistance"
              :rules="{ required: true, suffix: 2, negative: '', validator: priceType, trigger: ['blur', 'change'] }"
            >
              <el-input v-model="formData.sendDistance" style="width: 60%" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--风险区域-->
            <el-form-item
              :label="$t('basicData.RiskAreas') + '：'"
              prop="riskRegion"
              :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            >
              <el-select
                v-model="formData.riskRegion"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                style="width: 60%;"
                filterable
                clearable
              >
                <el-option :key="0" label="Sem Risco" value="Sem Risco" />
                <el-option :key="1" label="Risco" value="Risco" />
                <el-option :key="2" label="Zona Rural" value="Zona Rural" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--风险比例-->
            <el-form-item
              :label="$t('basicData.RiskRatio') + '：'"
              prop="riskRatio"
              :rules="{ required: true, suffix: 2, negative: '', validator: priceType, trigger: ['blur', 'change'] }"
            >
              <el-input v-model="formData.riskRatio" style="display: inline-block;width: 60%" /> %
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--状态-->
            <el-form-item
              :label="$t('basicData.status') + '：'"
              prop="applyType"
              :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            >
              <el-radio-group v-model="formData.applyType">
                <el-radio-button :label="0">{{ $t('basicData.enable') }}</el-radio-button>
                <el-radio-button :label="1">{{ $t('basicData.disable') }}</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div style="text-align: right;padding: 10px 20px;border-top: 1px solid #ccc;">
      <el-button size="small" @click="closeSaveDetail(0)">{{ $t('collectionCenter.close') }}</el-button>
      <el-button
        v-permit:remove="'btn:finance:dispatchReferenceTable:addEdit'"
        size="small"
        type="primary"
        @click="closeSaveDetail(1)"
      >{{ $t('collectionCenter.Save') }}</el-button>
    </div>
  </div>
</template>

<script>
import { apiAreaPage, apiDispatchReferenceInsert, apiDispatchReferenceUpdate } from '@/api/finance/basicData';
import { compressList } from '@/utils/zipcode';
import { apiCommonSite } from '@/api/logisticsOrders';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'DetailsCmp',
  props: {
    editData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    allState: {
      type: Array,
      default: () => {
        return [];
      }
    },
    providerList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    // 正负保留小数点
    const priceType = (rule, value, callback) => {
      const reg = new RegExp('(^' + rule.negative + '[1-9]\\d*(\\.\\d{1,' + rule.suffix + '})?$)|(^' + rule.negative + '0(\\.\\d{1,' + rule.suffix + '})?$)');
      const { required } = rule;
      if (!required) {
        // 非必填
        if (value !== null && value !== '' && value !== undefined) {
          if (!reg.test(value)) {
            this.adopt = false;
            return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // '格式不正确'
          } else {
            this.adopt = true;
            if (rule.package !== null && rule.package !== '' && rule.package !== undefined) {
              if (rule.negative1 === 2) {
                if (parseFloat(value) <= parseFloat(rule.package)) {
                  return callback(new Error(this.$i18n.t('basicData.MustGreaterThanStartingValue'))); // 必须大于起始值
                } else if (parseFloat(value) <= 0) {
                  return callback(new Error(this.$i18n.t('basicData.MustBeGreaterThan0'))); // 必须大于0
                }
              } else {
                if (parseFloat(value) >= parseFloat(rule.package)) {
                  return callback(new Error(this.$i18n.t('basicData.MustLessThanEndValue'))); // 必须小于结束值
                } else {
                  return callback();
                }
              }
            }
            return callback();
          }
        } else {
          return callback();
        }
      } else {
        if (value !== null && value !== '' && value !== undefined) {
          if (!reg.test(value)) {
            this.adopt = false;
            return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // '格式不正确'
          } else {
            this.adopt = true;
            if (rule.negative1 === 2) {
              if (parseFloat(value) <= parseFloat(rule.package)) {
                return callback(new Error(this.$i18n.t('basicData.MustGreaterThanStartingValue'))); // 必须大于起始值
              } else if (parseFloat(value) <= 0) {
                return callback(new Error(this.$i18n.t('basicData.MustBeGreaterThan0'))); // 必须大于0
              }
            } else {
              if (parseFloat(value) >= parseFloat(rule.package)) {
                return callback(new Error(this.$i18n.t('basicData.MustLessThanEndValue'))); // 必须小于结束值
              } else {
                return callback();
              }
            }
            return callback();
          }
        } else {
          this.adopt = false;
          return callback(this.$i18n.t('basicData.CannotBeEmpty')); // '不能为空'
        }
      }
    };
    return {
      formData: {
        providerId: '', // 所属供应商
        sendSiteId: '', // 派送网点
        sendDistance: '', // 派送距离
        postalCode: '', // 派送邮编(区间)
        stateId: '', // 州(省份)二字码
        cityId: '', // 城市
        riskRegion: 'Sem Risco', // 风险区域
        riskRatio: '', // 风险比例(%)
        applyType: 0, // 是否启用
        remark: '', // 备注
        id: ''
      },
      allCity: [],
      outletsList: [],
      priceType: priceType
    };
  },
  created() {
    if (this.editData && this.editData.id) {
      const detail = cloneDeep(this.editData);
      this.formData = {
        providerId: detail.providerId, // 所属供应商
        sendSiteId: detail.sendSiteId, // 派送网点
        sendDistance: detail.sendDistance, // 派送距离
        postalCode: detail.postalCode, // 派送邮编(区间)
        stateId: detail.stateId, // 州(省份)二字码
        cityId: detail.cityId, // 城市
        riskRegion: detail.riskRegion, // 风险区域
        riskRatio: detail.riskRatio, // 风险比例(%)
        applyType: detail.applyType, // 是否启用
        remark: detail.remark, // 备注
        id: detail.id
      };
      if (detail.providerId) {
        this.getSitePageList();
      }
      if (detail.stateId) {
        this.getAllState({ level: 2, parentId: detail.stateId });
      }
    }
  },
  methods: {
    // 供应商变化查询网点
    providerInfoChange() {
      this.formData.sendSiteId = '';
      this.getSitePageList();
    },
    // 获取派送网点
    getSitePageList() {
      const param = {
        pageNumber: 1,
        pageSize: 9999999,
        params: {
          siteStatus: undefined,
          providerId: this.formData.providerId
        }
      };
      apiCommonSite(param, false).then(res => {
        if (res.status === 'OK') {
          this.outletsList = res.data.records;
        }
      });
    },
    // 邮编整理
    zipcodeCollation(zipcode) {
      this.formData.postalCode = compressList(zipcode).join(',');
    },
    // 州变化查询城市
    changeState(val) {
      this.formData.cityId = '';
      this.getAllState({ level: 2, parentId: val });
    },
    // 州省ID获取所有的城市
    getAllState(row) {
      const param = {
        pageNumber: 1,
        pageSize: 99999,
        params: {
          level: row.level,
          parentId: row.parentId
        }
      };
      apiAreaPage(param, false).then(res => {
        if (res.status === 'OK') {
          if (row.level === 1) {
            this.allState = res.data.records;
          } else {
            this.allCity = res.data.records;
          }
        }
      });
    },
    // 保存、关闭
    closeSaveDetail(type) {
      if (type === 0) {
        this.$emit('updatePage', false);
      } else {
        this.$refs.formDataDetail.validate((valid) => {
          if (valid) {
            if (this.formData.id) {
              apiDispatchReferenceUpdate(this.formData).then(res => {
                if (res.status === 'OK') {
                  this.$message.success(this.$t('basicData.TheOperationSucceeded'));
                  this.$emit('updatePage', true);
                }
              });
            } else {
              apiDispatchReferenceInsert(this.formData).then(res => {
                if (res.status === 'OK') {
                  this.$message.success(this.$t('basicData.TheOperationSucceeded'));
                  this.$emit('updatePage', true);
                }
              });
            }
          }
        });
      }
    }
  }
};
</script>

<style scoped>

</style>
