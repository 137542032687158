// @ts-check

/** @typedef {import('./type').TDatail} TDatail */
/** @typedef {import('./type').TDatailHead} TDatailHead */
/** @typedef {import('./type').TFeeCode} TFeeCode */

const FY = 'FY';

/**
 * 获取明细字段
 * @param {string[][]} arr 明细字段
 * @param {TFeeCode[]} codeArr 费用代码
 * @returns {TDatailHead[]} 显示的表头
 */
export const getTableHeader = (arr, codeArr) => {
  // console.log(codeArr);

  /** @type {Set<string>} */
  const set = new Set();

  // 将所有字段添加到set中
  arr.forEach(list => {
    // console.log(list);
    list.map(item => {
      return Object.keys(item)[0];
    }).forEach(item => {
      set.add(item);
    });
  });
  console.log(set.keys());

  /** @type {TDatailHead[]} */
  const headArr = [];
  // @ts-ignore
  for (const key of set.keys()) {
    const val = Number(key.split(FY)[1]);
    // const label = /** @type {string} */ (particulars) + val;

    const label = codeArr.find(item => item.code === key)?.label || '';

    /** @type {TDatailHead} */
    const obj = {
      prop: key,
      val,
      label
    };

    headArr.push(obj);
  }

  // 按照顺序排列
  return headArr.sort((a, b) => a.val - b.val);
};
