var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "form-filters",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        },
      },
    },
    [
      _c(
        "o-field",
        { attrs: { label: "Chave MDFE", "label-for": "keyMDFE" } },
        [
          _c("o-input", {
            attrs: {
              id: "keyMDFE",
              placeholder: "Digite uma chave existente do MDFE",
            },
            model: {
              value: _vm.keyMDFE,
              callback: function ($$v) {
                _vm.keyMDFE = $$v
              },
              expression: "keyMDFE",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "button-actions" },
        [
          _c(
            "o-button",
            {
              attrs: { variant: "outline", size: "md" },
              on: { click: _vm.handleClearFilter },
            },
            [_vm._v(" Limpar ")]
          ),
          _c(
            "o-button",
            {
              attrs: {
                variant: "primary",
                size: "md",
                "native-type": "submit",
              },
            },
            [_vm._v(" Enviar ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }