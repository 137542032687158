<template>
  <div>
    <div
      class="app-container"
      style="height: calc(100vh - 130px);overflow: auto;"
    >
      <el-form
        ref="form"
        :model="queryForm"
        label-width="auto"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--应付主体-->
              <el-form-item :label="$t('basicData.PayablePrincipal') + '：'">
                <el-select
                  v-model="queryForm.params.providerId"
                  filterable
                  clearable
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  style="width: 100%;"
                  @change="providerInfoChange()"
                >
                  <el-option
                    v-for="item in providerList"
                    :key="item.id"
                    :label="item.providerBody + '（' + item.providerName + '）(' + item.code + ')'"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--网点-->
              <el-form-item :label="$t('basicData.Outlets') + '：'">
                <QuerySiteCmp
                  :val="queryForm.params.siteId"
                  :provider-id="queryForm.params.providerId"
                  @change="val => queryForm.params.siteId = val"
                />
                <!-- <el-select
                  v-model="queryForm.params.siteId"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  style="width: 100%;"
                  clearable
                  filterable
                  :loading="siteIdLoading"
                  @focus="getSitePageList()"
                  @change="changeOutlets"
                >
                  请先选择应收主体
                  <el-option
                    v-for="item in outletsList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select> -->
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--客户-->
              <el-form-item :label="$t('receivablePayable.customer') + '：'">
                <el-select
                  v-model="queryForm.params.customerId"
                  filterable
                  clearable
                  remote
                  reserve-keyword
                  :remote-method="getCustomerPage"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  style="width: 100%;"
                  @focus="getCustomerPage()"
                  @change="changeCustomer"
                >
                  <el-option
                    v-for="(item, ind) in customerList"
                    :key="ind"
                    :label="item.customerAlias + '(' + item.username + ')'"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <el-form-item>
                <template slot="label">
                  <el-select
                    v-model="queryForm.params.timeField"
                    :placeholder="$t('operationCenter.PleaseSelect')"
                  >
                    <!--业务时间-->
                    <el-option
                      key="1"
                      :label="$t('basicData.BusinessTime')"
                      value="operate_time"
                    />
                    <!--揽收入库时间-->
                    <!--                    <el-option key="2" :label="$t('basicData.TimeCollectIntoTreasury')" value="input_time" />-->
                    <!--揽收出库时间-->
                    <!--                    <el-option key="3" :label="$t('basicData.PickUpAndOutboundTime')" value="out_time" />-->
                  </el-select>
                </template>
                <!--查询时间-->
                <el-date-picker
                  v-model="queryDate"
                  style="width: 100%"
                  type="datetimerange"
                  :picker-options="dateLimit"
                  :default-time="['00:00:00', '23:59:59']"
                  :range-separator="$t('operationCenter.to')"
                  :start-placeholder="$t('operationCenter.startDate')"
                  :end-placeholder="$t('operationCenter.endDate')"
                  value-format="yyyy-MM-dd HH:mm:ss"
                />
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <el-form-item>
                <template slot="label">
                  <el-select
                    v-model="queryForm.params.numberField"
                    :placeholder="$t('operationCenter.PleaseSelect')"
                  >
                    <!--业务单号-->
                    <el-option
                      key="1"
                      :label="$t('receivablePayable.TicketNumber')"
                      value="waybill_number"
                    />
                    <!--条形码-->
                    <el-option
                      key="2"
                      :label="$t('collectionCenter.orderNumber')"
                      value="order_number"
                    />
                    <!--客户单号-->
                    <!--                    <el-option key="2" :label="$t('receivablePayable.CustomerTrackingNumber')" value="customer_number" />-->
                  </el-select>
                </template>
                <el-input
                  v-model="numberList"
                  type="textarea"
                  :rows="5"
                  :placeholder="numberListTips"
                />
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <el-form-item>
                <template slot="label">
                  <el-select
                    v-model="selectPeople"
                    :placeholder="$t('collectionCenter.pleaseChoose')"
                    class="selectTimeClass autoWidth"
                    @change="changePeople"
                  >
                    <el-option
                      v-for="(item, index) in selectPeopleList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </template>
                <!-- 揽收人 -->
                <CollectorsCmp
                  v-show="selectPeople === 'n1'" key="n1" :val="queryForm.params.collectorIdList"
                  @change="val => queryForm.params.collectorIdList = val"
                />
                <!-- <el-select
                  v-if="selectPeople === 'n1'"
                  key="n1"
                  v-model="queryForm.params.collectors"
                  filterable
                  clearable
                  remote
                  reserve-keyword
                  :placeholder="$t('collectionCenter.pleaseInput')"
                  :remote-method="driverNameMethod"
                  :loading="loading"
                  style="width: 100%;"
                >
                  <el-option
                    v-for="(item, index) in driverNameList"
                    :key="index"
                    :label="item.driverName + '（' + item.username + '）'"
                    :value="item.driverId"
                    @click.native="changeLP"
                  />
                </el-select> -->

                <!-- 派送人 -->
                <SendCmp
                  v-show="selectPeople === 'n2'"
                  key="n2"
                  :val="queryForm.params.dispatcherList"
                  @change="val => queryForm.params.dispatcherList = val"
                />

              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--是否已支付-->
              <el-form-item :label="$t('receivablePayable.WhetherItHasBeenPaid') + '：'">
                <el-select
                  v-model="queryForm.params.isPaid"
                  filterable
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  style="width: 100%;"
                >
                  <!--全部-->
                  <el-option
                    key="0"
                    :label="$t('basicData.all')"
                    :value="null"
                  />
                  <!--是-->
                  <el-option
                    key="1"
                    :label="$t('receivablePayable.Paid')"
                    :value="1"
                  />
                  <!--否-->
                  <el-option
                    key="2"
                    :label="$t('receivablePayable.NonPayment')"
                    :value="0"
                  />
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--是否生成对账单-->
              <el-form-item :label="$t('receivablePayable.WhetherToGenerateAStatement') + '：'">
                <el-select
                  v-model="queryForm.params.isGenBill"
                  filterable
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  style="width: 100%;"
                >
                  <!--全部-->
                  <el-option
                    key="0"
                    :label="$t('basicData.all')"
                    :value="null"
                  />
                  <!--是 已生成-->
                  <el-option
                    key="1"
                    :label="$t('receivablePayable.Generated')"
                    :value="1"
                  />
                  <!--否 未生成-->
                  <el-option
                    key="2"
                    :label="$t('receivablePayable.NotGenerated')"
                    :value="0"
                  />
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--业务类型-->
              <el-form-item :label="$t('collectionCenter.businessT') + '：'">
                <el-select
                  v-model="queryForm.params.businessType"
                  filterable
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  style="width: 100%;"
                  @change="changeBusinessType"
                >
                  <!--全部-->
                  <el-option
                    key="0"
                    :label="$t('basicData.all')"
                    :value="null"
                  />
                  <!--揽收-->
                  <el-option
                    key="1"
                    :label="$t('collectionCenter.lanshou')"
                    :value="2"
                  />
                  <!--派送-->
                  <el-option
                    key="2"
                    :label="$t('collectionCenter.dispatch')"
                    :value="3"
                  />
                  <!--转运-->
                  <!--                  <el-option key="3" :label="$t('collectionCenter.turnTransport')" :value="1" />-->
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <!-- 网点类型： -->
            <el-form-item :label="$t('collectionCenter.dotType')">
              <el-select
                v-model="queryForm.params.siteType"
                placeholder=""
                clearable
                filterable
                style="width: 100%;"
              >
                <el-option
                  :label="$t('collectionCenter.hut')"
                  :value="1"
                /><!-- 小屋 -->
                <el-option
                  :label="$t('collectionCenter.dot')"
                  :value="2"
                /><!-- 网点 -->
                <el-option
                  :label="$t('collectionCenter.transferCenter')"
                  :value="3"
                /><!-- 转运中心 -->
                <el-option
                  :label="$t('collectionCenter.headOffice')"
                  :value="4"
                /><!-- 总部 -->
                <el-option
                  :label="$t('collectionCenter.merchant')"
                  :value="5"
                /><!-- 商家 -->
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            v-if="queryForm.params.businessType===3"
            :span="24"
          >
            <!-- 派送模式： -->
            <el-form-item :label="$t('newOrder.OperationMode')">
              <el-select
                v-model="queryForm.params.operationMode"
                placeholder=""
                clearable
                filterable
                style="width: 100%;"
              >
                <el-option
                  label="AJD(自营)"
                  :value="0"
                /><!-- AJD(自营) -->
                <el-option
                  label="DSP(第三方)"
                  :value="1"
                /><!-- DSP(第三方) -->
              </el-select>
            </el-form-item>
          </el-col>
          <!-- 如果“网点类型”选中“安骏小屋”并且“业务类型”为揽收的数据则“揽收方式”输入框隐藏，否则都显示并且必填 -->
          <el-col
            v-if="queryForm.params.businessType == 2 && queryForm.params.siteType != 1"
            :span="24"
          >
            <!--揽收方式-->
            <el-form-item
              :label="$t('collectionCenter.lanshouTypee') + '：'"
              prop="collectType"
            >
              <el-select
                v-model="queryForm.params.collectType"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                style="width: 100%;"
              >
                <!--上门揽收-->
                <el-option
                  :label="$t('collectionCenter.doorPickUp')"
                  :value="2"
                />
                <!--上门交件-->
                <el-option
                  :label="$t('collectionCenter.dropOff')"
                  :value="1"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            v-if="selectKey === 1"
            :span="24"
          >
            <div class="grid-content bg-purple">
              <!--批次号-->
              <el-form-item
                :label="$t('basicData.BatchNo') + '：'"
                prop="billBatchNumber"
              >
                <el-input
                  v-model="queryForm.params.billBatchNumber"
                  :readonly="isReadOnly"
                />
              </el-form-item>
            </div>
          </el-col>
          <el-col
            v-if="selectKey === 2"
            :span="24"
          >
            <div class="grid-content bg-purple">
              <!--收款单号-->
              <el-form-item
                :label="$t('receivablePayable.PaymentSlipNumber') + '：'"
                prop="payableNumber"
              >
                <el-input
                  v-model="queryForm.params.payableNumber"
                  :readonly="isReadOnly"
                />
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--费用类型-->
              <el-form-item
                :label="$t('basicData.ExpenseType') + '：'"
                prop="feeName"
              >
                <el-select
                  v-model="queryForm.params.feeCode"
                  filterable
                  clearable
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  style="width: 100%;"
                >
                  <el-option
                    v-for="item in feeTypeOption"
                    :key="item.id"
                    :label="item.displayName"
                    :value="item.code"
                  />
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--订单状态-->
              <el-form-item :label="$t('collectionCenter.orderStatus')">
                <div v-if="queryForm.params.businessType == 2">
                  <el-select
                    v-model="queryForm.params.status"
                    filterable
                    clearable
                    :placeholder="$t('operationCenter.PleaseSelect')"
                    style="width: 100%;"
                  >
                    <el-option
                      v-for="(item, index) in orderTypeList"
                      :key="index"
                      :label="item.collectStatusName"
                      :value="item.collectStatus"
                    />
                  </el-select>
                </div>
                <div v-else-if="queryForm.params.businessType == 3">
                  <el-select
                    v-model="queryForm.params.status"
                    filterable
                    clearable
                    :placeholder="$t('operationCenter.PleaseSelect')"
                    style="width: 100%;"
                  >
                    <el-option
                      v-for="(item, index) in waybillStatus"
                      :key="index"
                      :label="item.label"
                      :value="item.code"
                    />
                  </el-select>
                </div>
                <div v-else>
                  <el-select
                    v-model="queryForm.params.status"
                    filterable
                    clearable
                    :placeholder="$t('operationCenter.PleaseSelect')"
                    style="width: 100%;"
                  >
                    <el-option
                      v-for="(item, index) in nullList"
                      :key="index"
                      :label="item.x"
                      :value="item.x"
                    />
                  </el-select>
                </div>
              </el-form-item>
            </div>
          </el-col>

          <el-col :span="24">
            <!-- 收件人州 -->
            <el-form-item :label="$t('newOrder.RecipientState')">
              <el-input
                v-model="queryForm.params.receiveState"
                :placeholder="$t('GlobalSetObj.pleaseInput')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!-- 发件人州 -->
            <el-form-item :label="$t('newOrder.SenderState')">
              <el-input
                v-model="queryForm.params.senderState"
                :placeholder="$t('GlobalSetObj.pleaseInput')"
              />
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>
    </div>
    <div style="text-align: center;border-top: 1px solid #ccc;padding-top: 10px;">
      <!--查 询-->
      <el-button
        type="primary"
        size="small"
        @click="searchForm(1)"
      >{{ $t('basicData.Search') }}</el-button>
      <!--重 置-->
      <el-button
        type="success"
        size="small"
        @click="searchForm(3)"
      >{{ $t('basicData.reset') }}</el-button>
      <!--取 消-->
      <el-button
        size="small"
        @click="searchForm(2)"
      >{{ $t('basicData.Cancel') }}</el-button>
    </div>
  </div>
</template>

<script>
import { feePage } from '@/api/finance/partition';
import { apiProviderPage } from '@/api/finance/basicData';
// import { getDistributionDotList } from '@/api/logisticsOrders';
// import {
//   queryCollectMan
// } from '@/api/lanshou';
import { apiCustomerPage } from '@/api/customer';
import SendCmp from './cmp/Search/SendCmp.vue';
import CollectorsCmp from './cmp/Search/CollectorsCmp.vue';
import { waybillStatus, orderTypeList, SEARCH_PARAMS } from '../utils';
import { cloneDeep } from 'lodash-es';
import QuerySiteCmp from '@/views/finance/cmp/Query/QuerySiteCmp';
import { getTextareaVal } from '@/views/finance/basicData/PriceInquiryLog/utils.js';
import { NUMBERLIST_MAX_LENGTH } from '@/views/finance/receivableManagementSite/aRGenerationNew/cmp/searchForm/utils.js';

export default {
  name: 'SearchForm',

  components: {
    SendCmp,
    CollectorsCmp,
    QuerySiteCmp
  },

  props: {
    queryForm: {
      type: Object,
      default: () => {
        return {
          orderDirection: 'DESC',
          orderProperty: '',
          pageNumber: 1,
          pageSize: 10,
          params: {
            ...SEARCH_PARAMS
            // providerId: '', // 应收主体id
            // customerId: '',
            // siteId: '', // 战点id
            // startTime: '', // 业务开始时间
            // endTime: '', // 业务结束时间
            // timeField: 'operate_time', // 查询时间类型 入库时间:input_time 出库时间:out_time 业务时间:operate_time
            // numberList: [], // 单号
            // numberField: 'waybill_number', // waybill_number: 业务单号 customer_number: 客户单号 order_number：条形码
            // billBatchNumber: '', // // 批次号
            // feeName: '', // 费用类型名称
            // isGenBill: null, // 是否生成对账单
            // isPaid: null, // 是否已支付
            // businessType: null,
            // collectType: null,
            // collectorIdList: '',
            // dispatcherList: '',
            // siteType: '',
            // operationMode: '', // 派送模式 0:AJD(自营) 1:DSP(第三方)
            // status: '', // 订单状态
            // receiveState: '', // 收件人州
            // senderState: '' // 发件人州
          }
        };
      }
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    selectKey: {
      type: Number,
      default: 1
    },
    searchSelectArr: {
      type: Object,
      default: () => {
        return {
          providerItem: null,
          outletsItem: null,
          customerItem: null
        };
      }
    },
    showSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // driverNameList: [], // 联想输入查询
      loading: false,
      selectPeople: 'n1',
      selectPeopleList: [
        { value: 'n1', label: this.$t('basicData.揽收操作人') }, // '揽收人'
        { value: 'n2', label: this.$t('basicData.派送操作人') } // '派送人'
      ],
      billBatchNumber: '',
      feeTypeOption: [],
      queryDate: [],
      numberList: '',
      providerList: [], // 应收主体选项
      outletsList: [], // 站点选项
      searchSelect: {
        providerItem: null,
        outletsItem: null,
        customerItem: null
      },
      customerList: [],

      // deliveryList: [], // 派送人
      // dispatcherLoading: false, // 派送人加载状态

      nullList: [],

      siteIdLoading: false, // 网点加载动画
      // 日期限制
      pickerMinDate: '',
      pickerRange: 15 * 24 * 60 * 60 * 1000 // 可选择日期范围 为15天
    };
  },
  // watch: {
  //   showSearch: {
  //     handler(newVal) {
  //       if (!newVal) { return; }
  //       if (this.queryForm.params.collectors || this.queryForm.params.dispatcherList) {
  //         // this.driverNameList = JSON.parse(sessionStorage.getItem('driverList')).driver;
  //         // this.deliveryList = JSON.parse(sessionStorage.getItem('driverList')).deliveryList;
  //         this.selectPeople = JSON.parse(sessionStorage.getItem('driverList')).selectPeople;
  //       }
  //     },
  //     immediate: true,
  //     deep: true
  //   }
  // },

  computed: {

    /**
     * 日期限制处理
     *    默认情况下限制15天
     *    如果输入了 业务单号 或 客户单号 或 批次号，则不限制时间
     */
    dateLimit() {
      const { numberList, pickerMinDate, pickerRange } = this;
      const { billBatchNumber } = this.queryForm.params;

      // 默认限制15天
      return {
        // 设置不能选择的日期
        onPick: ({ minDate }) => {
          // 输入了内容
          if (numberList || billBatchNumber) {
            // 取消限制
            this.pickerMinDate = null;
            return;
          }

          if (minDate && pickerMinDate) {
            this.pickerMinDate = null;
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }
        },
        disabledDate: (time) => {
          // 输入了内容
          if (numberList || billBatchNumber) {
            // 取消时间限制
            return false;
          }

          if (this.pickerMinDate) {
            return (time.getTime() > (pickerMinDate + pickerRange)) || (time.getTime() < (pickerMinDate - pickerRange));
          }
          return false;
        }
      };
    }
  },

  created() {
    this.waybillStatus = waybillStatus;
    this.orderTypeList = orderTypeList;

    // 输入框提示符
    this.numberListTips = (this.$i18n.t('newOrder.PreciseSearch')).replace('200', NUMBERLIST_MAX_LENGTH + '');

    this.searchSelect = cloneDeep(this.searchSelectArr);
    this.getCustomerPage('');
    this.getProviderPage();
    // this.getSitePageList();
    if (this.queryForm.params.numberList.length > 0) {
      this.numberList = this.queryForm.params.numberList.join('\n');
    }
    if (this.queryForm.params.startTime) {
      this.queryDate[0] = this.queryForm.params.startTime;
    }
    if (this.queryForm.params.endTime) {
      this.queryDate[1] = this.queryForm.params.endTime;
    }
    // 获取费用类型
    this.getExpenseType();
  },
  methods: {
    // 业务类型变化
    changeBusinessType() {
      if (this.queryForm.params.businessType !== 2) {
        this.queryForm.params.collectType = null;
      }
      if (this.queryForm.params.businessType !== 3) {
        this.queryForm.params.operationMode = '';
      }
    },
    changePeople() { // 切换揽收人 / 派送人
      this.queryForm.params.collectorIdList = '';
      this.queryForm.params.dispatcherList = '';
    },
    changeLP() {
      this.$forceUpdate();
    },
    // driverNameMethod(value) { // 司机list
    //   if (value !== '') {
    //     this.loading = true;
    //     setTimeout(() => {
    //       const mixed = true;
    //       queryCollectMan(value, mixed).then(res => {
    //         if (res.status === 'OK') {
    //           this.driverNameList = [];
    //           res.data.forEach(item => {
    //             const driverId = item.driverId ? item.driverId : item.customerId;
    //             const driverName = item.driverName ? item.driverName : item.customerName;
    //             this.driverNameList.push({
    //               driverId,
    //               driverName,
    //               username: item.username
    //             });
    //           });
    //         }
    //       });
    //       this.loading = false;
    //     }, 200);
    //   }
    // },

    // 获取客户
    getCustomerPage(name = '') {
      const param = {
        pageSize: 10,
        pageNumber: 1,
        params: {
          quickSearch: name
        }
      };
      apiCustomerPage(param).then((res) => {
        if (res.status === 'OK') {
          const ids = [];
          res.data.records.map(item => {
            ids.push(item.id);
          });
          if (this.searchSelect.customerItem) {
            const id = this.searchSelect.customerItem.id;
            if (ids.includes(id) === false) {
              res.data.records.unshift(this.searchSelect.customerItem);
            }
          }
          this.customerList = res.data.records;
        }
      });
    },
    // 客户选项变化
    changeCustomer() {
      this.customerList.map(item => {
        if (this.queryForm.params.customerId === item.id) {
          this.searchSelect.customerItem = item;
        }
      });
    },
    // 网点选项变化
    changeOutlets() {
      this.outletsList.map(item => {
        if (this.queryForm.params.siteId === item.id) {
          this.searchSelect.outletsItem = item;
        }
      });
    },
    // 分页获取站点
    // getSitePageList() {
    //   const param = {
    //     pageNumber: 1,
    //     pageSize: 10,
    //     params: {
    //       siteStatus: undefined,
    //       providerId: this.queryForm.params.providerId
    //     }
    //   };
    //   this.siteIdLoading = true;

    //   getDistributionDotList(param, false).then(res => {
    //     if (res.status === 'OK') {
    //       this.outletsList = res.data.records;
    //     }
    //   }).catch(err => {
    //     console.error(err);
    //   }).finally(() => {
    //     this.siteIdLoading = false;
    //   });
    // },
    // 应付主体变化
    providerInfoChange() {
      this.queryForm.params.siteId = '';
      // this.outletsList = [];
      this.providerList.map(item => {
        if (item.id === this.queryForm.params.providerId) {
          this.searchSelect.providerItem = item;
        }
      });
      // this.getSitePageList();
    },
    // 获取费用类型
    getExpenseType() {
      const param = {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 9999999,
        params: {
          state: 1,
          attribute: 2
        }
      };
      feePage(param, false).then(res => {
        if (res.status === 'OK') {
          this.feeTypeOption = res.data.records;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 获取应付主体选项
    getProviderPage() {
      const param = {
        pageSize: 9999999,
        pageNumber: 1,
        params: {
          state: 1
        }
      };
      apiProviderPage(param).then(res => {
        if (res.status === 'OK') {
          this.providerList = res.data.records;
        }
      });
    },
    // 搜索、取消、重置
    searchForm(type) {
      // if (type === 1 || type === 2) {
      // 判断collectors / dispatcher是否存在
      // 不存在则 driverNameList 为空 存在则缓存
      // const driverList = {
      //   // driver: this.driverNameList,
      //   // deliveryList: this.deliveryList,
      //   selectPeople: this.selectPeople
      // };
      // if (this.queryForm.params.collectors || this.queryForm.params.dispatcherList) {
      //   sessionStorage.setItem('driverList', JSON.stringify(driverList));
      // } else {
      //   sessionStorage.setItem('driverList', {});
      // }
      // }
      if (type === 3) {
        this.selectPeople = 'n1';
        // this.driverNameList = [];
        this.changePeople();
        this.numberList = '';
        this.queryDate = [];
        this.$emit('search', this.queryForm, this.searchSelect, type);
        return;
      }
      if (this.queryDate && this.queryDate.length > 1) {
        const start = new Date(this.queryDate[0]).getTime();
        const end = new Date(this.queryDate[1]).getTime();
        if (start > end) {
          this.$message.warning(this.$i18n.t('receivablePayable.TheStartTimeCannotBeGreaterThanTheEndTime')); // '起始时间不能大于结束时间'
          return;
        }
        this.queryForm.params.startTime = this.queryDate[0];
        this.queryForm.params.endTime = this.queryDate[1];
      } else {
        this.queryForm.params.startTime = '';
        this.queryForm.params.endTime = '';
      }
      // const searchArr = [];
      // if (this.numberList) {
      //   const dataArr = this.numberList.split(/[(\r\n)\r\n]+/);
      //   for (let i = 0; i < dataArr.length; i++) {
      //     if (dataArr[i]) {
      //       searchArr.push(dataArr[i].trim());
      //     }
      //   }
      // }
      this.queryForm.params.numberList = getTextareaVal(this.numberList, NUMBERLIST_MAX_LENGTH);
      this.$emit('search', this.queryForm, this.searchSelect, type);
    }
  }
};
</script>

<style scoped>

</style>
