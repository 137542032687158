<template>
  <div class="wrapper" @mousedown="hideModal">
    <div class="card">
      <div class="cardTitle">
        <h2 class="title">{{ title }}</h2>
        <button class="closeButton">X</button>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    title: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    hideModal(event) {
      if (event.target.className === 'wrapper' || event.target.className === 'closeButton') {
        this.$emit('close');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.title{
  margin: 0px;
  font-size: 20px;
}

.wrapper{
  top: 0px;
  left: 0px;
  z-index: 1002;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
}

.card{
  flex-basis: 500px;
  max-width: 500px;
  padding: 20px 24px;
  background: #fff;
  border-radius: 4px;
}

.cardTitle{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.closeButton{
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
}
</style>
