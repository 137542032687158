var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "tabClass" },
      [
        _c(
          "el-tabs",
          {
            attrs: { type: "card" },
            on: { "tab-click": _vm.handleClick },
            model: {
              value: _vm.activeName,
              callback: function ($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName",
            },
          },
          [
            _c(
              "el-tab-pane",
              {
                key: 0,
                attrs: {
                  label: _vm.$t("navbar.tDuration1"),
                  name: "transportationTime",
                },
              },
              [_vm.isShow ? _c("transportationTime") : _vm._e()],
              1
            ),
            _c(
              "el-tab-pane",
              {
                key: 1,
                attrs: {
                  label: _vm.$t("navbar.durationTransit"),
                  name: "durationTransit",
                },
              },
              [_vm.isShow1 ? _c("durationTransit") : _vm._e()],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }