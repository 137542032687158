const userManage = {
  site: {
    zh: '网点',
    en: '',
    pu: 'Ponto de rede'
  },
  pleaseCheckSite: {
    zh: '请选择站点',
    en: '',
    pu: 'favor selecionar o ponto'
  },
  confirmCheck: {
    zh: '确定同意审核？',
    en: '',
    pu: 'Tem certeza de concordar em rever?'
  },
  AuditSuccess: {
    zh: '审核成功',
    en: '',
    pu: 'Revisão bem-sucedida'
  },

  importSuccessNotErr: {
    zh: '导入成功无错误数据',
    en: '',
    pu: 'Importar dados com sucesso sem dados errados'
  },
  state: {
    zh: '状态',
    en: 'state',
    pu: 'status'
  },
  accountState: {
    zh: '状态：',
    en: 'state',
    pu: 'status'
  },
  all: {
    zh: '全部',
    en: 'all',
    pu: 'tudo'
  },
  enable: {
    zh: '启用',
    en: 'enable',
    pu: 'ativar'
  },
  Disable: {
    zh: '停用',
    en: 'Disable',
    pu: 'Desativar'
  },
  realName: {
    zh: '名称：',
    en: 'name',
    pu: 'nome'
  },
  username: {
    zh: '账户名：',
    en: '',
    pu: 'nome da conta'
  },
  phone: {
    zh: '电话：',
    en: 'Phone',
    pu: 'Telefone'
  },
  roleId: {
    zh: '角色：',
    en: '',
    pu: 'papel'
  },
  userPhone: {
    zh: '电话',
    en: 'Phone',
    pu: 'Telefone'
  },
  name: {
    zh: '名称',
    en: 'name',
    pu: 'nome'
  },
  roleName: {
    zh: '角色',
    en: '',
    pu: 'papel'
  },
  Null: {
    zh: '暂无',
    en: 'No',
    pu: 'Não'
  },
  userNameL: {
    zh: '账户名',
    en: '',
    pu: 'nome da conta'
  },
  CollapseQuery: {
    zh: '收起查询',
    en: 'Collapse the Query button',
    pu: 'Recolher o botão Consulta'
  },
  ExpandQuery: {
    zh: '展开查询',
    en: 'Expand the Query button',
    pu: 'Expandir o botão Consulta'
  },
  search: {
    zh: '查询',
    en: '',
    pu: 'consultar'
  },
  reset: {
    zh: '重置',
    en: 'reset',
    pu: 'Redefinir'
  },
  add: {
    zh: '新增',
    en: 'add',
    pu: 'adicionar'
  },
  Delete: {
    zh: '删除',
    en: 'Delete',
    pu: 'Excluir'
  },
  download: {
    zh: '用户导出',
    en: '',
    pu: 'Exportação do usuário'
  },
  userStop: {
    zh: '账号停用',
    en: '',
    pu: 'Desativar a conta'
  },
  userStart: {
    zh: '账号启用',
    en: '',
    pu: 'Ativar a conta'
  },
  ind: {
    zh: '序号',
    en: 'index',
    pu: 'Número de série.'
  },
  englishName: {
    zh: '英文名称',
    en: '',
    pu: 'nome em inglês'
  },
  organizationName: {
    zh: '所属部门',
    en: '',
    pu: 'departamento'
  },
  AccountStatus: {
    zh: '账户状态',
    en: 'Account status',
    pu: 'Status da Conta'
  },
  permissionType: {
    zh: '账户权限状态',
    en: '',
    pu: 'Status de permissão de conta'
  },
  normal: {
    zh: '正常',
    en: 'normal',
    pu: 'normal'
  },
  personality: {
    zh: '个性化',
    en: 'personalized',
    pu: 'personalizado'
  },
  unauthorized: {
    zh: '未授权',
    en: 'unauthorized',
    pu: 'não autorizado'
  },
  createTime: {
    zh: '创建日期',
    en: '',
    pu: 'Data de criação'
  },
  creator: {
    zh: '创建人',
    en: 'creator',
    pu: 'Criado por'
  },
  operation: {
    zh: '操作',
    en: 'operation',
    pu: 'Operação'
  },
  edit: {
    zh: '编辑',
    en: 'Edit',
    pu: 'editar'
  },
  authorization: {
    zh: '授权',
    en: '',
    pu: 'autorizar'
  },
  resetPasswordL: {
    zh: '重置密码：',
    en: 'reset password：',
    pu: 'redefinir a senha：'
  },
  resetPassword: {
    zh: '重置密码',
    en: 'reset password',
    pu: 'redefinir a senha'
  },
  newPassword: {
    zh: '新密码',
    en: 'new password',
    pu: 'nova senha'
  },
  requestedInputCode: {
    zh: '请按照要求输入密码',
    en: 'Please enter the password as required',
    pu: 'Por favor digitar a senha conforme requerido'
  },
  checkPwd: {
    zh: '确认密码',
    en: 'Confirm password',
    pu: 'Confirmar senha'
  },
  inputConfirmCode: {
    zh: '请输入确认密码',
    en: 'Please confirm your password',
    pu: 'Introduza a senha de confirmação, por favor'
  },
  checkPwdNotNewPwd: {
    zh: '确认密码与新密码不相同',
    en: '',
    pu: 'a senha de confirmação não é a mesma que a nova senha'
  },
  SelectPerson: {
    zh: '请选择要发送消息的人员',
    en: '',
    pu: 'Favor selecionar a pessoa para quem você deseja enviar a mensagem'
  },
  addUser: {
    zh: '添加用户',
    en: '',
    pu: 'Adicionar usuário'
  },
  editUser: {
    zh: '编辑用户',
    en: '',
    pu: 'Editar usuário'
  },
  rightChoiceData: {
    zh: '请正确选择需要操作的数据!',
    en: '',
    pu: 'Por favor, selecione corretamente os dados a serem manipulados!'
  },
  selectData: {
    zh: '请选择需要操作的数据!',
    en: '',
    pu: 'Por favor, selecione os dados a serem manipulados!'
  },
  selectDataError: {
    zh: '选择数据用户状态不正确,请重新勾选!',
    en: '',
    pu: 'A seleção o status de usuário dos dados está incorreta, por favor selecione novamente!'
  },
  enableUser: {
    zh: '是否启用用户账户?',
    en: '',
    pu: 'ativar a conta do usuário?'
  },
  disableUser: {
    zh: '是否停用用户账户?',
    en: '',
    pu: 'desativar a conta do usuário?'
  },
  tips: {
    zh: '提示',
    en: 'tips',
    pu: 'Dica'
  },
  confirm: {
    zh: '确定',
    en: 'determine',
    pu: 'determinar'
  },
  cancel: {
    zh: '取消',
    en: 'cancel',
    pu: 'Cancelar'
  },
  TheOperationSucceeded: {
    zh: '操作成功!',
    en: 'The operation succeeded!',
    pu: 'A operação foi bem sucedida.'
  },
  operationCancel: {
    zh: '操作取消',
    en: '',
    pu: 'A operação foi cancelada.'
  },
  authorizationOperationSucceeded: {
    zh: '授权操作成功!',
    en: '',
    pu: 'Operação autorizada com sucesso!'
  },
  succeed: {
    zh: '成功',
    en: 'succeed',
    pu: 'suceder'
  },
  resetSucceeded: {
    zh: '重置成功',
    en: '',
    pu: 'redefinir com sucesso'
  },
  chooseExportUser: {
    zh: '请选择需要导出的用户',
    en: '',
    pu: 'Favor selecionar o usuário para o qual você deseja exportar'
  },
  ExportSuccessful: {
    zh: '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，',
    en: 'If the export is successful, the data will be generated within 1~10 minutes, please download it from the resource export list in system management.',
    pu: 'Se a exportação for bem sucedida, os dados serão gerados dentro de 1~10 minutos, por favor, baixe-os da lista de exportação de recursos no gerenciamento do sistema.'
  },
  notRepeatTheExport: {
    zh: '请勿重复导出',
    en: 'Do not repeat the export！',
    pu: 'Não repita a exportação！'
  },
  GoNow: {
    zh: '立即前往',
    en: 'Go now',
    pu: 'Vá agora.'
  },
  ShutDown: {
    zh: '关闭',
    en: 'Shut down',
    pu: 'Desligar'
  },
  basicInformation: {
    zh: '基本信息',
    en: '',
    pu: 'Informações básicas'
  },
  usernameL: {
    zh: '账号：',
    en: '',
    pu: 'número da conta：'
  },
  usernameId: {
    zh: '账号ID',
    en: '',
    pu: 'Número da conta ID'
  },
  userPhoneL: {
    zh: '电话：',
    en: 'Phone：',
    pu: 'Telefone：'
  },
  realNameL: {
    zh: '真实姓名：',
    en: '',
    pu: 'nome real：'
  },
  password: {
    zh: '密码：',
    en: '',
    pu: 'Senha：'
  },
  EnglishName: {
    zh: '英文名：',
    en: 'English name：',
    pu: 'Nome em inglês：'
  },
  PlatformWhichBelongs: {
    zh: '所属平台：',
    en: 'The platform to which it belongs：',
    pu: 'A plataforma à qual pertence：'
  },
  orgCode: {
    zh: '组织机构：',
    en: '',
    pu: 'Organização：'
  },
  hasAudit: {
    zh: '是否为审核人：',
    en: '',
    pu: 'É o revisor：'
  },
  Yes: {
    zh: '是',
    en: 'Yes',
    pu: 'sim'
  },
  No: {
    zh: '否',
    en: 'No',
    pu: 'não'
  },
  roles: {
    zh: '用户角色：',
    en: '',
    pu: 'Papel do usuário：'
  },
  userAccountStatus: {
    zh: '账户状态：',
    en: 'Account status：',
    pu: 'Status da Conta：'
  },
  position: {
    zh: '职位：',
    en: '',
    pu: 'Posição：'
  },
  pleaseChoose: {
    zh: '请选择',
    en: 'Please select',
    pu: 'Favor selecionar'
  },
  pleaseInput: {
    zh: '请输入',
    en: 'Please enter the content',
    pu: 'por favor digite'
  },
  code: {
    zh: '员工编号：',
    en: '',
    pu: 'número do funcionário：'
  },
  userState: {
    zh: '用户状态：',
    en: '',
    pu: 'status do usuário：'
  },
  onJob: {
    zh: '在职',
    en: '',
    pu: 'no trabalho'
  },
  leaveJob: {
    zh: '离职',
    en: '',
    pu: 'sem trabalho'
  },
  vacation: {
    zh: '休假',
    en: '',
    pu: 'Licença de ausência'
  },
  entryTime: {
    zh: '入职日期:',
    en: '',
    pu: 'data de entrada:'
  },
  entryTime1: {
    zh: '入职日期',
    en: '',
    pu: 'data de entrada'
  },
  avatar: {
    zh: '头像:',
    en: '',
    pu: 'foto de perfil:'
  },
  resume: {
    zh: '简历:',
    en: '',
    pu: 'CV：'
  },
  onlyPDFnoMore2M: {
    zh: '只能上传PDF文件，且不超过2M',
    en: '',
    pu: 'Carregar somente arquivos em PDF e não maior que 2M'
  },
  remark: {
    zh: '备注：',
    en: 'remark：',
    pu: 'Observação：'
  },
  Next: {
    zh: '下一步',
    en: 'Next',
    pu: 'Próximo'
  },
  additionalInformation: {
    zh: '附加信息',
    en: '',
    pu: 'Informações adicionais'
  },
  identityCard: {
    zh: '身份证:',
    en: 'identityCard:',
    pu: 'bilhete de identidade:'
  },
  birthday: {
    zh: '生日:',
    en: '',
    pu: 'aniversário:'
  },
  birthday1: {
    zh: '生日',
    en: '',
    pu: 'aniversário'
  },
  gender: {
    zh: '性别:',
    en: '',
    pu: 'Gênero:'
  },
  gender1: {
    zh: '性别',
    en: '',
    pu: 'Gênero'
  },
  male: {
    zh: '男',
    en: '',
    pu: 'masculino'
  },
  female: {
    zh: '女',
    en: '',
    pu: 'feminino'
  },
  educationBackground: {
    zh: '学历:',
    en: '',
    pu: 'qualificações acadêmicas：'
  },
  primarySchool: {
    zh: '小学',
    en: '',
    pu: 'ensino primário'
  },
  juniorHighSchool: {
    zh: '初中',
    en: '',
    pu: 'ensino secundário'
  },
  highSchool: {
    zh: '高中',
    en: '',
    pu: 'ensino médio'
  },
  college: {
    zh: '大专',
    en: '',
    pu: 'colégio júnior'
  },
  undergraduateCourse: {
    zh: '本科',
    en: '',
    pu: 'licenciatura'
  },
  master: {
    zh: '硕士',
    en: '',
    pu: 'mestrado'
  },
  doctor: {
    zh: '博士',
    en: '',
    pu: 'doutoramento'
  },
  socialSecurity: {
    zh: '社保:',
    en: '',
    pu: 'seguridade social：'
  },
  accumulationFund: {
    zh: '公积金:',
    en: '',
    pu: 'fundo de previdência habitacional:'
  },
  QQ: {
    zh: 'QQ:',
    en: '',
    pu: 'QQ:'
  },
  dingding: {
    zh: '钉钉:',
    en: '',
    pu: 'DingDing:'
  },
  termDate: {
    zh: '离职日期:',
    en: '',
    pu: 'data de saída:'
  },
  weixin: {
    zh: '微信:',
    en: '',
    pu: 'WeChat:'
  },
  email: {
    zh: '邮箱：',
    en: 'mailbox',
    pu: 'correio electrônico/email：'
  },
  email1: {
    zh: '邮箱',
    en: 'mailbox',
    pu: 'correio electrônico/email'
  },
  weibo: {
    zh: '微博:',
    en: '',
    pu: 'WeiBo:'
  },
  emergencyContact: {
    zh: '紧急联系人:',
    en: '',
    pu: 'contacto de emergência:'
  },
  emergencyTelephoneNumber: {
    zh: '紧急联系电话:',
    en: '',
    pu: 'contacto de emergência:'
  },
  address: {
    zh: '住址:',
    en: '',
    pu: 'endereço:'
  },
  Previous: {
    zh: '上一步',
    en: 'Previous',
    pu: 'Anterior'
  },
  inputName: {
    zh: '请填写账户名',
    en: '',
    pu: 'Favor preencher o nome de sua conta'
  },
  nameIsLetterOrSumber: {
    zh: '账号必须为字母或者数字',
    en: '',
    pu: 'O número da conta deve ser alfanumérico ou numérico'
  },
  longIs5To15: {
    zh: '长度在 5 到 15 个字符',
    en: '',
    pu: 'Comprimento entre 5 e 15 caracteres'
  },
  nameRepeat: {
    zh: '用户名重复,请重新填写',
    en: '',
    pu: 'nome de usuário repetido, por favor preencha novamente'
  },
  longIs2To30: {
    zh: '长度在 2 到 100 个字符',
    en: '',
    pu: 'Comprimento entre 2 e 100 caracteres'
  },
  inputRealName: {
    zh: '请填写用户真实姓名',
    en: '',
    pu: 'Por favor, preencha nome real do usuário'
  },
  ChooseUserState: {
    zh: '请选择用户状态',
    en: '',
    pu: 'Por favor, selecione o status do usuário'
  },
  inputPWD: {
    zh: '请填写密码',
    en: '',
    pu: 'Favor preencher a senha'
  },
  pwdNotChineses: {
    zh: '密码不能有中文字符.',
    en: '',
    pu: 'A senha não pode ter caracteres chineses.'
  },
  pwdNotEmoji: {
    zh: '密码不能使用表情.',
    en: '',
    pu: 'As senhas não podem usar emoji.'
  },
  passwordPrompt: {
    zh: '密码必须由 8-12位字母、数字组成，可选特殊符号.',
    en: '',
    pu: 'A senha deve ser composta de 8-12 letras e números, com a opção de símbolos especiais.'
  },
  defaultPassword: {
    zh: '默认密码',
    en: '',
    pu: 'senha padrão'
  },
  inputPwdAgian: {
    zh: '请再次输入新密码',
    en: '',
    pu: 'Favor digitar nova senha novamente'
  },
  chooseAccountState: {
    zh: '请选择账户状态',
    en: '',
    pu: 'Favor selecionar o status da conta'
  },
  chooseOrgCode: {
    zh: '请选择用户组织架构',
    en: '',
    pu: 'Por favor, selecione a organização do usuário'
  },
  choosePosition: {
    zh: '请选择职位',
    en: '',
    pu: 'Por favor, selecione uma posição'
  },
  chooseRoles: {
    zh: '请选择用户角色',
    en: '',
    pu: 'Favor selecionar o papel do usuário'
  },
  chooseEntryTime: {
    zh: '请选择用户入职日期',
    en: '',
    pu: 'Favor selecionar a data de entrada do usuário'
  },
  chooseQQ: {
    zh: 'QQ长度不合法',
    en: '',
    pu: 'O comprimento QQ não é legal'
  },
  emailNotFormat: {
    zh: '邮箱格式不正确',
    en: '',
    pu: 'Formato de e-mail incorreto'
  },
  inputIdcard: {
    zh: '请输入身份证',
    en: '',
    pu: 'Por favor, digite seu número de identificação'
  },
  chooseSex: {
    zh: '请选择性别',
    en: '',
    pu: 'Por favor, selecione um gênero'
  },
  inputEmergencyContact: {
    zh: '请输入紧急联系人',
    en: '',
    pu: 'Favor digitar um contato de emergência'
  },
  inputPhone: {
    zh: '请填写联系电话',
    en: '',
    pu: 'Favor preencher o número de contato'
  },
  warning: {
    zh: '警告',
    en: '',
    pu: 'aviso'
  },
  warningContent: {
    zh: '当前部门下暂无职位，如需添加请前往《用户中心-职务管理》添加',
    en: '',
    pu: 'Não há empregos no departamento atual, se você quiser adicionar um, vá ao "Centro do Usuário - Gerenciamento de empregos" para adicionar um.'
  },
  onlyJPG: {
    zh: '上传头像图片只能是 JPG 格式!',
    en: '',
    pu: 'imagem carregada de foto de perfil somente em formato JPG!'
  },
  imgNoMore2MB: {
    zh: '上传头像图片大小不能超过 2MB!',
    en: '',
    pu: 'imagem carregada de foto de perfil não maior que 2MB!'
  },
  nolyPDF: {
    zh: '上传简历只能是 PDF 格式!',
    en: '',
    pu: 'Faça o upload de seu CV somente em formato PDF!'
  },
  test5: {
    zh: '上传简历大小不能超过 2MB!',
    en: '',
    pu: 'O tamanho do upload de seu CV não deve exceder 2MB!'
  },
  userSetPermission: {
    zh: '用户授权',
    en: '',
    pu: 'Autorização do usuário'
  },
  notNull: {
    zh: '不能为空',
    en: 'Cannot be empty',
    pu: 'Não pode estar vazio'
  },
  longIs: {
    zh: '长度在',
    en: '',
    pu: 'Comprimento entre'
  },
  longTo: {
    zh: '到',
    en: '',
    pu: 'e'
  },
  longCode: {
    zh: ' 个字符',
    en: '',
    pu: 'caracteres'
  },
  dot: {
    zh: '网点：',
    en: '',
    pu: 'Ponto：'
  },
  useRange: {
    zh: '使用范围：',
    en: '',
    pu: 'Âmbito de utilização.：'
  },
  userCategory: {
    zh: '用户类别：',
    en: '',
    pu: 'Categoria do utilizador.：'
  },
  onlyDotUse: {
    zh: '仅在网点使用',
    en: '',
    pu: 'Para utilização apenas em Pontos'
  },
  codeUse: {
    zh: '员工编号可用',
    en: '',
    pu: 'Número de funcionários disponível'
  },
  codeRepeat: {
    zh: '员工编号重复，不可用',
    en: '',
    pu: 'Número de funcionários em duplicado, não disponível'
  },
  ModifyThePerson: {
    zh: '修改人',
    en: 'Modify the person',
    pu: 'Pessoa de modificação'
  },
  ModificationTime: {
    zh: '修改时间',
    en: 'Modification time',
    pu: 'Tempo de modificação'
  },
  updateUserBelongs: {
    zh: '修改人所属网点',
    en: '',
    pu: 'Ponto de modificador'
  },
  business: {
    zh: '业务范围：',
    en: '',
    pu: 'Âmbito de negócios：'
  },
  business1: {
    zh: '因邮编不连续，如出现多个同名州、城市，请同时勾选',
    en: '',
    pu: 'Devido à falta de continuidade nos códigos postais, ao encontrar vários municípios e estados com o mesmo nome, por favor, selecione-os simultaneamente'
  },
  temporaryWorker: {
    zh: '临时工',
    en: '',
    pu: 'Diarista'
  },
  deliveryRider: {
    zh: '骑手',
    en: '',
    pu: 'Motorista'
  },
  courier: {
    zh: '快递员',
    en: '',
    pu: 'Entregador'
  },
  workLocation: {
    zh: '工作地点',
    en: '',
    pu: 'Local de trabalho'
  },
  warehouse1: {
    zh: '1号仓库',
    en: '',
    pu: 'Armazém 1'
  },
  warehouse2: {
    zh: '2号仓库',
    en: '',
    pu: 'Armazém 2'
  },
  pleaseEnterYourIdNumber: {
    zh: '请输入编号',
    en: '',
    pu: 'Favor digitar o código'
  },
  onboarding: {
    zh: '入职',
    en: '',
    pu: 'Integração'
  },
  evaluation: {
    zh: '评价',
    en: '',
    pu: 'Avaliação'
  },
  age: {
    zh: '年龄',
    en: '',
    pu: 'Idade'
  },
  latestHiringDate: {
    zh: '最新入职时间',
    en: '',
    pu: 'Data da integração mais recente'
  },
  latestDepartureDate: {
    zh: '最新离职时间',
    en: '',
    pu: 'Data de separação mais recente'
  },
  latestIntegrityLevel: {
    zh: '最新诚信等级',
    en: '',
    pu: 'Nível de integridade mais recente'
  },
  viewEdit: {
    zh: '查看/编辑',
    en: '',
    pu: 'Exibir/Editar'
  },
  detailedAddress: {
    zh: '详细住址',
    en: '',
    pu: 'Endereço completo'
  },
  workRecord: {
    zh: '工作记录',
    en: '',
    pu: 'Registro de trabalho'
  },
  node: {
    zh: '节点',
    en: '',
    pu: 'Nó'
  },
  evaluationRecord: {
    zh: '评价记录',
    en: '',
    pu: 'Registro de avaliação'
  },
  evaluationLevel: {
    zh: '评价等级',
    en: '',
    pu: 'Nível de avaliação'
  },
  employeeEvaluation: {
    zh: '员工评价',
    en: '',
    pu: 'Avaliação de funcionário'
  },
  upTo500ID: {
    zh: '最多支持500个编号查询',
    en: '',
    pu: 'Suporta até 500 pesquisas de códigos'
  },
  thisOperation: {
    zh: '是否继续此操作？',
    en: '',
    pu: 'Se deve continuar essa operação'
  },
  employeeToEvaluate: {
    zh: '请选择一个员工进行评价',
    en: '',
    pu: 'Selecione um funcionário para avaliar'
  },
  pleaseSelectevaluationLevel: {
    zh: '请选择评价等级',
    en: '',
    pu: 'Selecione o nível de avaliação'
  },
  pleaseEnterEvaluationContent: {
    zh: '请输入评价内容',
    en: '',
    pu: 'Digite sua avaliação'
  },
  evaluationSuccessful: {
    zh: '评价成功',
    en: '',
    pu: 'Avaliação do sucesso'
  },
  importTemplate: {
    zh: '人员登记导入模板',
    en: '',
    pu: 'Modelo de importação de registro de pessoal'
  },
  ImportDataIsEmpty: {
    zh: '导入数据为空',
    en: '',
    pu: 'Os dados de importação estão vazios'
  },
  pleaseCheck: {
    zh: '填写有误，请检查',
    en: '',
    pu: 'Preenchido incorretamente, favor verificar'
  },
  failedToImport: {
    zh: '导入失败人员列表',
    en: '',
    pu: 'Falha ao importar a lista de pessoal'
  },
  exceedsLengthLimit: {
    zh: '超出长度限制',
    en: '',
    pu: 'Excede o limite de comprimento'
  },
  time: {
    zh: '时间',
    en: '',
    pu: 'Tempo de'
  },
  provider: {
    zh: '服务商',
    en: '',
    pu: 'Parceiro'
  }
};
export {
  userManage
};
