var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Box" },
    [
      _c(
        "div",
        {
          staticClass: "selectClass",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.changeVal == "hzVal",
                  expression: "changeVal == 'hzVal'",
                },
              ],
            },
            [
              _c(
                "el-form",
                [
                  _c(
                    "el-row",
                    {
                      staticClass: "timeClass",
                      staticStyle: { "flex-wrap": "wrap" },
                      attrs: { type: "flex", justify: "start" },
                    },
                    [
                      _c(
                        "el-col",
                        { staticStyle: { width: "auto" }, attrs: { span: 7 } },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { staticClass: "col2" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "selectTimeClass autoWidth",
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "collectionCenter.pleaseChoose"
                                        ),
                                      },
                                      on: { change: _vm.changeTime },
                                      model: {
                                        value: _vm.selectTime,
                                        callback: function ($$v) {
                                          _vm.selectTime = $$v
                                        },
                                        expression: "selectTime",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "prefix" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              (
                                                _vm.timeList.find(function (
                                                  item
                                                ) {
                                                  return (
                                                    item.value ===
                                                    _vm.selectTime
                                                  )
                                                }) || {}
                                              ).time
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _vm._l(
                                        _vm.timeList,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.time,
                                              value: item.value,
                                            },
                                          })
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { staticStyle: { width: "auto" } },
                                [
                                  _c(
                                    "el-form-item",
                                    { staticClass: "delMl" },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          clearable: false,
                                          type: "datetimerange",
                                          "value-format": "yyyy-MM-dd HH:mm:ss",
                                          "default-time": [
                                            "00:00:00",
                                            "23:59:59",
                                          ],
                                          "range-separator": _vm.$t(
                                            "collectionCenter.to"
                                          ),
                                          "start-placeholder": _vm.$t(
                                            "collectionCenter.StartTime"
                                          ),
                                          "end-placeholder": _vm.$t(
                                            "collectionCenter.EndTime"
                                          ),
                                        },
                                        on: { change: _vm.dateChange },
                                        model: {
                                          value: _vm.timeValue,
                                          callback: function ($$v) {
                                            _vm.timeValue = $$v
                                          },
                                          expression: "timeValue",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticStyle: { width: "auto" }, attrs: { span: 8 } },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { staticClass: "col2" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass:
                                        "selectConditionClass selectTimeClass autoWidth",
                                      on: { change: _vm.selectNumberClick },
                                      model: {
                                        value: _vm.selectNumber,
                                        callback: function ($$v) {
                                          _vm.selectNumber = $$v
                                        },
                                        expression: "selectNumber",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "prefix" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              (
                                                _vm.numberList.find(function (
                                                  item
                                                ) {
                                                  return (
                                                    item.value ===
                                                    _vm.selectNumber
                                                  )
                                                }) || {}
                                              ).num
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _vm._l(
                                        _vm.numberList,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.num,
                                              value: item.value,
                                            },
                                          })
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _vm.selectNumber == "n1"
                                ? _c(
                                    "el-col",
                                    {
                                      staticClass: "colWill",
                                      staticStyle: { width: "auto" },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { staticClass: "textarea" },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "textarea",
                                              autosize: "",
                                              placeholder: _vm.$t(
                                                "collectionCenter.BatchQuery"
                                              ),
                                            },
                                            on: { blur: _vm.isNumber },
                                            nativeOn: {
                                              keydown: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.BlockNewlineCarTag(
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.queryForm.params
                                                  .tagNumberList,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.queryForm.params,
                                                  "tagNumberList",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "queryForm.params.tagNumberList",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.selectNumber == "n2"
                                ? _c(
                                    "el-col",
                                    {
                                      staticClass: "colWill",
                                      staticStyle: { width: "auto" },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { staticClass: "textarea" },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "textarea",
                                              autosize: "",
                                              placeholder: _vm.$t(
                                                "collectionCenter.BatchQuery"
                                              ),
                                            },
                                            on: { blur: _vm.isNumber },
                                            nativeOn: {
                                              keydown: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.BlockNewlineCarCard(
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.queryForm.params
                                                  .vehicleNumberList,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.queryForm.params,
                                                  "vehicleNumberList",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "queryForm.params.vehicleNumberList",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticStyle: { width: "auto" }, attrs: { span: 7 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("collectionCenter.belongDot"),
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "collectionCenter.pleaseChoose"
                                    ),
                                    clearable: "",
                                    filterable: "",
                                  },
                                  on: { focus: _vm.getNewestSite },
                                  model: {
                                    value: _vm.queryForm.params.siteId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm.params,
                                        "siteId",
                                        $$v
                                      )
                                    },
                                    expression: "queryForm.params.siteId",
                                  },
                                },
                                _vm._l(_vm.siteIdList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticStyle: { width: "auto" }, attrs: { span: 8 } },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { staticClass: "col2" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass:
                                        "selectConditionClass selectTimeClass autoWidth",
                                      on: { change: _vm.selectCarClick },
                                      model: {
                                        value: _vm.selectCar,
                                        callback: function ($$v) {
                                          _vm.selectCar = $$v
                                        },
                                        expression: "selectCar",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "prefix" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              (
                                                _vm.carList.find(function (
                                                  item
                                                ) {
                                                  return (
                                                    item.value === _vm.selectCar
                                                  )
                                                }) || {}
                                              ).car
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _vm._l(
                                        _vm.carList,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.car,
                                              value: item.value,
                                            },
                                          })
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _vm.selectCar == "c1"
                                ? _c(
                                    "el-col",
                                    {
                                      staticClass: "colWill",
                                      staticStyle: { width: "auto" },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: _vm.$t(
                                                  "collectionCenter.pleaseChoose"
                                                ),
                                                clearable: "",
                                                filterable: "",
                                              },
                                              on: {
                                                focus: _vm.getNewestPeople,
                                              },
                                              model: {
                                                value:
                                                  _vm.queryForm.params
                                                    .loadedUser,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.queryForm.params,
                                                    "loadedUser",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "queryForm.params.loadedUser",
                                              },
                                            },
                                            _vm._l(
                                              _vm.employeeList,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.id,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.selectCar == "c2"
                                ? _c(
                                    "el-col",
                                    {
                                      staticClass: "colWill",
                                      staticStyle: { width: "auto" },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: _vm.$t(
                                                  "collectionCenter.pleaseChoose"
                                                ),
                                                clearable: "",
                                                filterable: "",
                                              },
                                              on: {
                                                focus: _vm.getNewestPeople,
                                              },
                                              model: {
                                                value:
                                                  _vm.queryForm.params
                                                    .unloadedUser,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.queryForm.params,
                                                    "unloadedUser",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "queryForm.params.unloadedUser",
                                              },
                                            },
                                            _vm._l(
                                              _vm.employeeList,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.id,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticStyle: { width: "auto" }, attrs: { span: 7 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label:
                                  _vm.$t("GlobalSetObj.nextStation") + "：",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "collectionCenter.pleaseChoose"
                                    ),
                                    clearable: "",
                                    filterable: "",
                                  },
                                  on: { focus: _vm.getNewestSite },
                                  model: {
                                    value: _vm.queryForm.params.nextSiteId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm.params,
                                        "nextSiteId",
                                        $$v
                                      )
                                    },
                                    expression: "queryForm.params.nextSiteId",
                                  },
                                },
                                _vm._l(_vm.siteIdList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { type: "flex", justify: "space-between" } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.changeVal == "hzVal",
                          expression: "changeVal == 'hzVal'",
                        },
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:report:carMonitoring:search",
                          expression:
                            "'btn:collection-center:report:carMonitoring:search'",
                          arg: "remove",
                        },
                      ],
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-search",
                      },
                      on: { click: _vm.searchClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.search")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.changeVal == "hzVal",
                          expression: "changeVal == 'hzVal'",
                        },
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:report:carMonitoring:reset",
                          expression:
                            "'btn:collection-center:report:carMonitoring:reset'",
                          arg: "remove",
                        },
                      ],
                      attrs: { icon: "el-icon-refresh", size: "mini" },
                      on: { click: _vm.resetClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.changeVal == "hzVal",
                          expression: "changeVal == 'hzVal'",
                        },
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:report:carMonitoring:allExport-hz",
                          expression:
                            "'btn:collection-center:report:carMonitoring:allExport-hz'",
                          arg: "remove",
                        },
                      ],
                      class: _vm.isClickAll1 ? "onlyClickOnce10" : "",
                      attrs: { icon: "el-icon-upload", size: "mini" },
                      on: { click: _vm.downloadAll },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.changeVal == "mxVal",
                          expression: "changeVal == 'mxVal'",
                        },
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:report:carMonitoring:allExport-mx",
                          expression:
                            "'btn:collection-center:report:carMonitoring:allExport-mx'",
                          arg: "remove",
                        },
                      ],
                      class: _vm.isClickAll2 ? "onlyClickOnce10" : "",
                      attrs: { icon: "el-icon-upload", size: "mini" },
                      on: { click: _vm.downloadAllMX },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          model: {
            value: _vm.changeVal,
            callback: function ($$v) {
              _vm.changeVal = $$v
            },
            expression: "changeVal",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("collectionCenter.summary"),
                name: "hzVal",
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      ref: "tableList",
                      attrs: {
                        "max-height": 600,
                        border: "",
                        "show-summary": "",
                        "summary-method": _vm.getSummaries,
                        data: _vm.tableDate,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "55" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("collectionCenter.ind"),
                          type: "index",
                          width: "60",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      (_vm.queryForm.pageNumber - 1) *
                                        _vm.queryForm.pageSize +
                                        scope.$index +
                                        1
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "tagNumber",
                          label: _vm.$t("collectionCenter.carNumber"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "vehicleNumber",
                          label: _vm.$t("collectionCenter.licensePlateNumber"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "loadedUserName",
                          label: _vm.$t("collectionCenter.loader"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "loadedSiteName",
                          label: _vm.$t("collectionCenter.loaderDot"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "loadedAddress",
                          label: _vm.$t("collectionCenter.loadingAddress"),
                          "show-overflow-tooltip": true,
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "loadedTime",
                          label: _vm.$t("collectionCenter.loadTime"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "nextSiteName",
                          label: _vm.$t("GlobalSetObj.nextStation"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "unloadedUserName",
                          label: _vm.$t("collectionCenter.unloader"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "unloadedSiteName",
                          label: _vm.$t("collectionCenter.unloaderDot"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "unloadedAddress",
                          label: _vm.$t("collectionCenter.unloadingAddress"),
                          "show-overflow-tooltip": true,
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "unloadTime",
                          label: _vm.$t("collectionCenter.unloadTime"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "loadedBagCount",
                          label: _vm.$t(
                            "collectionCenter.totalNumberBagsLoaded"
                          ),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm.hzTomx
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.cellClick(scope.row, 0)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(scope.row.loadedBagCount))]
                                    )
                                  : _c("span", [
                                      _vm._v(_vm._s(scope.row.loadedBagCount)),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "unloadedBagCount",
                          label: _vm.$t("collectionCenter.totalBagsUnloaded"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm.hzTomx
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.cellClick(scope.row, 1)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(scope.row.unloadedBagCount)
                                        ),
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(scope.row.unloadedBagCount)
                                      ),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "loadedUnloadedBagDiffCount",
                          label: _vm.$t("collectionCenter.bagCountDiscrepancy"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm.hzTomx
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.cellDiffClick(
                                              scope.row,
                                              "d"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.loadedUnloadedBagDiffCount
                                          )
                                        ),
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.loadedUnloadedBagDiffCount
                                        )
                                      ),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "vehicleType",
                          label: _vm.$t("collectionCenter.carType"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "operationMode",
                          label: _vm.$t("collectionCenter.operationMode"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "loadedTicketCount",
                          label: _vm.$t("collectionCenter.carLoadingVotes"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm.hzTomx
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.cellPClick(scope.row, 0)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(scope.row.loadedTicketCount)
                                        ),
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(scope.row.loadedTicketCount)
                                      ),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "unloadedTicketCount",
                          label: _vm.$t("collectionCenter.carUnloadingVotes"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm.hzTomx
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.cellPClick(scope.row, 1)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(scope.row.unloadedTicketCount)
                                        ),
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(scope.row.unloadedTicketCount)
                                      ),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "loadedUnloadedTicketDiffCount",
                          label: _vm.$t("collectionCenter.votesDiscrepancy"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm.hzTomx
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.cellDiffClick(
                                              scope.row,
                                              "p"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            scope.row
                                              .loadedUnloadedTicketDiffCount
                                          )
                                        ),
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row
                                            .loadedUnloadedTicketDiffCount
                                        )
                                      ),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm.carImg
                        ? _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("collectionCenter.operation"),
                              fixed: "right",
                              align: "center",
                              width: "120",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "#26b3f1",
                                            cursor: "pointer",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.loadedImg(scope.row, 0)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "collectionCenter.loadingPicture"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("br"),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "#26b3f1",
                                            cursor: "pointer",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.loadedImg(scope.row, 1)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "collectionCenter.unloadingPicture"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("br"),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "#26b3f1",
                                            cursor: "pointer",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.loadedSignImg(
                                                scope.row,
                                                0
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "collectionCenter.loadingSignPicture"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("br"),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "#26b3f1",
                                            cursor: "pointer",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.loadedSignImg(
                                                scope.row,
                                                1
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "collectionCenter.unloadSignImg"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2131473498
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c("el-pagination", {
                    staticStyle: { margin: "10px 0" },
                    attrs: {
                      layout: "total, sizes, prev, pager, next, jumper",
                      "current-page": _vm.queryForm.pageNumber,
                      "page-sizes": [10, 20, 50, 100, 500, 1000],
                      "page-size": _vm.queryForm.pageSize,
                      total: _vm.total,
                      background: "",
                    },
                    on: {
                      "update:currentPage": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageNumber", $event)
                      },
                      "update:current-page": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageNumber", $event)
                      },
                      "update:pageSize": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageSize", $event)
                      },
                      "update:page-size": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageSize", $event)
                      },
                      "current-change": _vm.getTablePage,
                      "size-change": _vm.getPageSize,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-dialog",
                {
                  attrs: { visible: _vm.dialogVisible },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogVisible = $event
                    },
                    close: _vm.closeDialog,
                  },
                },
                [
                  _vm.loadingTruckPicture.length > 0
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "overflow-x": "auto",
                            "white-space": "nowrap",
                          },
                        },
                        _vm._l(_vm.loadingTruckPicture, function (img, index) {
                          return _c(
                            "span",
                            { key: index, staticStyle: { margin: "0 10px" } },
                            [
                              _c("img", {
                                staticStyle: { width: "auto", height: "500px" },
                                attrs: { src: img, alt: "" },
                              }),
                            ]
                          )
                        }),
                        0
                      )
                    : _c("div", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(
                          " - " +
                            _vm._s(_vm.$t("collectionCenter.NoContentYet")) +
                            " - "
                        ),
                      ]),
                ]
              ),
            ],
            1
          ),
          _vm.changeVal == "mxVal"
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("collectionCenter.particulars"),
                    name: "mxVal",
                  },
                },
                [
                  _vm.mxIndex == 0
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-table",
                            {
                              ref: "tableList",
                              attrs: {
                                "max-height": 600,
                                border: "",
                                "show-summary": "",
                                "summary-method": _vm.getSummariesMX,
                                data: _vm.tableDateMX,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { type: "selection", width: "55" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("collectionCenter.ind"),
                                  type: "index",
                                  width: "60",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                (_vm.queryForm.pageNumber - 1) *
                                                  _vm.queryForm.pageSize +
                                                  scope.$index +
                                                  1
                                              )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3840461880
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "bagNumber",
                                  label: _vm.$t("collectionCenter.BagNumber"),
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "bagSealingTime",
                                  label: _vm.$t(
                                    "collectionCenter.sealingBagTime"
                                  ),
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "bagSealingUserName",
                                  label: _vm.$t("collectionCenter.BagSealer"),
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "bagSiteName",
                                  label: _vm.$t(
                                    "collectionCenter.bagPackerDot"
                                  ),
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "packageCount",
                                  label: _vm.$t(
                                    "collectionCenter.InPocketTotal"
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.mxIndex == 1
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-table",
                            {
                              ref: "tableList",
                              attrs: {
                                "max-height": 600,
                                border: "",
                                data: _vm.tableDateMX,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { type: "selection", width: "55" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("collectionCenter.ind"),
                                  type: "index",
                                  width: "60",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                (_vm.queryForm.pageNumber - 1) *
                                                  _vm.queryForm.pageSize +
                                                  scope.$index +
                                                  1
                                              )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3840461880
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "scanNumber",
                                  label: _vm.$t("collectionCenter.Barcode"),
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "packageNumber",
                                  label: _vm.$t("collectionCenter.parcelNum"),
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "scanTime",
                                  label: _vm.$t("collectionCenter.lanshouT"),
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "scanUserName",
                                  label: _vm.$t(
                                    "collectionCenter.LanShouPeople"
                                  ),
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "scanSiteName",
                                  label: _vm.$t(
                                    "collectionCenter.receiverBelongsSite"
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-pagination", {
                        staticStyle: { margin: "10px 0" },
                        attrs: {
                          layout: "total, sizes, prev, pager, next, jumper",
                          "current-page": _vm.queryFormMX.pageNumber,
                          "page-sizes": [10, 20, 50, 100, 500, 1000],
                          "page-size": _vm.queryFormMX.pageSize,
                          total: _vm.totalMX,
                          background: "",
                        },
                        on: {
                          "update:currentPage": function ($event) {
                            return _vm.$set(
                              _vm.queryFormMX,
                              "pageNumber",
                              $event
                            )
                          },
                          "update:current-page": function ($event) {
                            return _vm.$set(
                              _vm.queryFormMX,
                              "pageNumber",
                              $event
                            )
                          },
                          "update:pageSize": function ($event) {
                            return _vm.$set(_vm.queryFormMX, "pageSize", $event)
                          },
                          "update:page-size": function ($event) {
                            return _vm.$set(_vm.queryFormMX, "pageSize", $event)
                          },
                          "current-change": _vm.getTablePageMX,
                          "size-change": _vm.getPageSizeMX,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }