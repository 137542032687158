export const WorkOrderState = {
  codes: ['created', 'reviewing', 'reviewed', 'review_callback', 'closed', 'not_started',
    'implementing', 'implemented', 'reviewer_acceptance', 'demander_acceptance', 'over'],
  remarks: ['草稿', '审核中', '审核通过', '审核驳回', '关闭', '任务未开始', '实施中', '实施完成', '审核人验收', '需求方验收', '完成'],
  CREATED: {
    code: 'created',
    remark: '草稿',
    color: 'info'
  },
  REVIEWING: {
    code: 'reviewing',
    remark: '审核中',
    color: 'success'
  },
  REVIEWED: {
    code: 'reviewed',
    remark: '审核通过',
    color: ''
  },
  REVIEW_CALLBACK: {
    code: 'review_callback',
    remark: '审核驳回',
    color: 'danger'
  },
  CLOSED: {
    code: 'closed',
    remark: '关闭',
    color: 'info'
  },
  NOT_STARTED: {
    code: 'not_started',
    remark: '任务未开始',
    color: 'info'
  },
  IMPLEMENTING: {
    code: 'implementing',
    remark: '实施中',
    color: 'success'
  },
  IMPLEMENTED: {
    code: 'implemented',
    remark: '实施完成',
    color: ''
  },
  REVIEWER_ACCEPTANCE: {
    code: 'reviewer_acceptance',
    remark: '审核人验收',
    color: 'warning'
  },
  REVIEWER_ACCEPTANCE_CALLBACK: {
    code: 'reviewer_acceptance_callback',
    remark: '审核人驳回',
    color: 'danger'
  },
  DEMANDER_ACCEPTANCE: {
    code: 'demander_acceptance',
    remark: '需求方验收',
    color: 'warning'
  },
  DEMANDER_ACCEPTANCE_CALLBACK: {
    code: 'demander_acceptance_callback',
    remark: 'danger'
  },

  OVER: {
    code: 'over',
    remark: '完成',
    color: ''
  }
};

// 审批通过状态变化
export const WorkOrderPassedState = {
  [WorkOrderState.REVIEWING.code]: WorkOrderState.REVIEWED.code, // 审核中 --> 审核通过
  [WorkOrderState.REVIEWER_ACCEPTANCE.code]: WorkOrderState.REVIEWER_ACCEPTANCE.code, // 审核人验收 --> 需求方验收
  [WorkOrderState.DEMANDER_ACCEPTANCE.code]: WorkOrderState.DEMANDER_ACCEPTANCE.code // 需求方验收 --> 完成
};

// 审批驳回状态变化
export const WorkOrderRejectState = {
  [WorkOrderState.REVIEWING.code]: WorkOrderState.REVIEW_CALLBACK.code, // 审核中 --> 审核驳回
  // [WorkOrderState.REVIEWER_ACCEPTANCE.code]: WorkOrderState.REVIEWER_ACCEPTANCE.code, // 审核人验收
  [WorkOrderState.REVIEWER_ACCEPTANCE.code]: WorkOrderState.REVIEWER_ACCEPTANCE_CALLBACK.code, // 审核人验收 --> 审核人驳回
  [WorkOrderState.DEMANDER_ACCEPTANCE.code]: WorkOrderState.DEMANDER_ACCEPTANCE_CALLBACK.code // 需求方验收 --> 需求方驳回
};

// 优先级
export const SORT_STATE = {
  1: {
    code: 1,
    remark: '紧急',
    color: 'danger'
  },
  2: {
    code: 2,
    remark: '高',
    color: 'warning'
  },
  3: {
    code: 3,
    remark: '正常',
    color: 'success'
  },
  4: {
    code: 4,
    remark: '低',
    color: 'info'
  }
};

// 微工单状态
export const MiniWorkOrderState = {

  CLOSED: {
    code: 'closed',
    remark: '关闭',
    color: 'info'
  },

  IMPLEMENTING: {
    code: 'implementing',
    remark: '实施中',
    color: 'success'
  },

  OVER: {
    code: 'over',
    remark: '完成',
    color: ''
  }
};

export const FOLLOW_UP_STATUS = {
  0: {
    code: '0',
    remark: '正常跟进'
  },
  1: {
    code: '1',
    remark: '完结任务'
  },
  2: {
    code: '2',
    remark: '作废任务'
  }
};

// 语言种类
export const LANGS = {
  '中文': 'zh',
  'English': 'en', // 英语
  'Portugal': 'pt' // 葡萄牙语
};

