<template>
  <div style="height: calc(100vh - 67px); overflow: auto;padding: 10px">
    <el-tabs v-model="activeName" type="border-card">
      <!--订单详情-->
      <el-tab-pane name="1">
        <span slot="label"><i class="el-icon-s-grid" /> {{ $t('orderCenterCont.OrderDetails') }}</span>
        <DetailsCmp ref="DetailsCmp" v-bind="$attrs" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import DetailsCmp from './showDetail';

export default {
  name: 'Detail',
  components: {
    DetailsCmp
  },
  data() {
    return {
      activeName: '1',
      isShow2: false,
      isShow3: false,
      isShow4: false
    };
  },
  methods: {}
};
</script>

<style scoped>

</style>
