var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "tip01" },
        [
          _vm._v(_vm._s(_vm.$t("placeOrder.automaticPrinting1")) + " "),
          _c(
            "el-link",
            {
              staticStyle: { "font-size": "16px" },
              attrs: {
                href: "https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/software/安骏桌面插件/安骏桌面插件.exe",
                type: "warning",
              },
            },
            [_vm._v(_vm._s(_vm.$t("placeOrder.automaticPrinting2")) + " ")]
          ),
          _vm._v(" " + _vm._s(_vm.$t("placeOrder.automaticPrinting3")) + " "),
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "ruleFormRef",
          attrs: {
            inline: false,
            model: _vm.queryForm,
            "label-position": "top",
            size: "large",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("GlobalSetObj.scannerNumber"),
                prop: "waybillBagNumber",
              },
            },
            [
              _c("el-input", {
                ref: "inputRef",
                staticStyle: { width: "350px" },
                attrs: { clearable: "" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.arrivedOut($event)
                  },
                },
                model: {
                  value: _vm.queryForm.waybillBagNumber,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.queryForm,
                      "waybillBagNumber",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "queryForm.waybillBagNumber",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            "header-cell-style": { background: "#f2f2f2", color: "black" },
            border: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("GlobalSetObj.serial"),
              align: "center",
              type: "index",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.printTime"),
              "min-width": "180",
              prop: "printableTime",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.printer"),
              "min-width": "150",
              prop: "printableUserName",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("GlobalSetObj.waybillNumber"),
              "min-width": "160",
              prop: "waybillNumber",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(_vm._s(scope.row.waybillNumber || "--")),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { margin: "10px 0", float: "right" },
        attrs: {
          "current-page": _vm.queryForm.pageNumber,
          "page-size": _vm.queryForm.pageSize,
          "page-sizes": [50, 100, 200, 400],
          total: _vm.queryForm.total,
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c(
        "div",
        { staticStyle: { display: "none" } },
        [_c("pdf", { ref: "pdfPrint", attrs: { src: _vm.viewUrl } })],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
        },
        [
          _c("audio", {
            ref: "errRef",
            attrs: { src: _vm.errorMp3, controls: "controls" },
          }),
          _c("audio", {
            ref: "okRef",
            attrs: { src: _vm.okMp3, controls: "controls" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }