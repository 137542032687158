import defaultSettings from '@/settings';
import { RouterObj } from '@/lang/type/route';

const title = defaultSettings.title || 'OA';

// 直接通过监听路由设置浏览器title
export default function getPageTitle(pageTitle) {
  const lang = sessionStorage.getItem('language') || 'pu';

  if (pageTitle === undefined) {
    return `${title}`;
  }

  // 未进行国际化语言处理
  if ((RouterObj[pageTitle] === undefined)) {
    return `${pageTitle} - ${title}`;
  }

  // 国际化
  if (RouterObj[pageTitle][lang]) {
    return `${RouterObj[pageTitle][lang]} - ${title}`;
  }

  return `${title}`;
}

// 通过右上角切换语言来设置页面titile
export function setPageTitle(pageTitle) {
  const lang = sessionStorage.getItem('language') || 'pu';

  for (const [k, v] of Object.entries(RouterObj)) {
    if (k === pageTitle) {
      // return v[lang];
      return `${v[lang]} - ${title}`;
    }
  }

  return title;
}
