import { requestScripting } from '@/lib/axios';
import clearString from '@/utils/clearString';

export const companyService = {
  async delete(id) {
    return requestScripting({
      method: 'DELETE',
      url: `shipping-company/${id}`
    });
  },
  async getAll(skip = 0, take = 10, params) {
    const queryString = new URLSearchParams();

    for (const key in params) {
      if (params[key]) {
        queryString.append(key, params[key]);
      }
    }

    return requestScripting({
      method: 'GET',
      url: `shipping-company?skip=${skip}&take=${take}&${queryString.toString()}`
    });
  },
  async get(id) {
    return requestScripting({
      method: 'GET',
      url: `shipping-company/${id}`
    });
  },
  async create(data) {
    const body = this.getBody(data);

    return requestScripting({
      method: 'POST',
      data: body,
      url: 'shipping-company'
    });
  },
  async edit(data, id) {
    const body = this.getBody(data);

    return requestScripting({
      method: 'PUT',
      data: body,
      url: `shipping-company/${id}`
    });
  },
  getBody(data) {
    return {
      'shipping_company_name': data.name,
      'shipping_company_trade_name': data.tradeName,
      'shipping_company_documentation': clearString(data.documentation),
      'shipping_company_state_registration': clearString(data.stateRegistration),
      'shipping_company_address': data.street,
      'shipping_company_address_number': data.number,
      'shipping_company_address_complement': data.complement,
      'shipping_company_address_neighborhood': data.neighborhood,
      'shipping_company_address_municipality': data.municipality,
      'shipping_company_state': data.state,
      'shipping_company_country': 'BR',
      'shipping_company_zipcode': clearString(data.zipcode),
      'shipping_company_commercial_phone_number': clearString(data.comercialPhone),
      'shipping_company_contact_name': data.contact,
      'shipping_company_cellphone': clearString(data.cellphone),
      'shipping_company_mail': data.email,
      'shipping_company_contract_type': data.contractType ? 1 : 0,
      'shipping_company_is_active': 1
    };
  }
};
