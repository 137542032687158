var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "searchBox" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              attrs: { inline: false, "label-position": "top" },
              model: {
                value: _vm.queryForm,
                callback: function ($$v) {
                  _vm.queryForm = $$v
                },
                expression: "queryForm",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("navbar.providerOrderStatus"),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: _vm.$t("GlobalSetObj.all"),
                                        clearable: "",
                                        filterable: "",
                                        size: "small",
                                      },
                                      model: {
                                        value:
                                          _vm.queryForm.params
                                            .providerOrderStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "providerOrderStatus",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.providerOrderStatus",
                                      },
                                    },
                                    _vm._l(
                                      _vm.orderStatusList,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label: item.label,
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("newOrder.CustomerName"),
                                    name: "customerId",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "spareNumberPoo.Only10AreDisplayed"
                                        ),
                                        clearable: "",
                                        filterable: "",
                                        size: "small",
                                      },
                                      model: {
                                        value: _vm.queryForm.params.customerId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "customerId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.customerId",
                                      },
                                    },
                                    _vm._l(_vm.customerList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label:
                                            item.customerAlias +
                                            "(" +
                                            item.username +
                                            ")",
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("navbar.providerName") } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t("GlobalSetObj.all"),
                                clearable: "",
                                filterable: "",
                                size: "small",
                              },
                              model: {
                                value: _vm.queryForm.params.providerId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "providerId",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.providerId",
                              },
                            },
                            _vm._l(_vm.provideNameList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.providerChannelName,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "searchTimeType" } },
                        [
                          _c(
                            "template",
                            { slot: "label" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "selectClass",
                                  style: _vm.IsWidthTime,
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.pleaseChoose"
                                    ),
                                    size: "small",
                                  },
                                  model: {
                                    value: _vm.queryForm.params.searchTimeType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm.params,
                                        "searchTimeType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "queryForm.params.searchTimeType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: "1",
                                    attrs: {
                                      label: _vm.$t("newOrder.createTime"),
                                      value: "1",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "default-time": ["00:00:00", "23:59:59"],
                              "end-placeholder": _vm.$t(
                                "operationCenter.endDate"
                              ),
                              format:
                                _vm.$i18n.locale === "zh"
                                  ? _vm.zhDate
                                  : _vm.ptDate,
                              "picker-options": _vm.pickerOptions,
                              "start-placeholder": _vm.$t(
                                "operationCenter.startDate"
                              ),
                              "range-separator": "~",
                              size: "small",
                              type: "datetimerange",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                            },
                            model: {
                              value: _vm.timeValue,
                              callback: function ($$v) {
                                _vm.timeValue = $$v
                              },
                              expression: "timeValue",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "template",
                            { slot: "label" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "selectClass",
                                  style: _vm.IsWidth,
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "orderDetails.pleaseChoose"
                                    ),
                                    size: "small",
                                  },
                                  model: {
                                    value:
                                      _vm.queryForm.params.searchNumberType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm.params,
                                        "searchNumberType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "queryForm.params.searchNumberType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: "1",
                                    attrs: {
                                      label: _vm.$t(
                                        "orderCenterCont.anjunWaybillNumber"
                                      ),
                                      value: "1",
                                    },
                                  }),
                                  _c("el-option", {
                                    key: "4",
                                    attrs: {
                                      label: _vm.$t("GlobalSetObj.ScanNumber"),
                                      value: "4",
                                    },
                                  }),
                                  _c("el-option", {
                                    key: "2",
                                    attrs: {
                                      label: _vm.$t(
                                        "newOrder.PlatformOrderNumber"
                                      ),
                                      value: "2",
                                    },
                                  }),
                                  _c("el-option", {
                                    key: "5",
                                    attrs: {
                                      label: _vm.$t(
                                        "GlobalSetObj.serviceLogistics"
                                      ),
                                      value: "5",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "100%",
                                "margin-top": "5px",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "newOrder.PreciseSearch2"
                                  ),
                                  rows: 3,
                                  size: "small",
                                  type: "textarea",
                                },
                                model: {
                                  value: _vm.queryForm.params.searchNumberStr,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "searchNumberStr",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "queryForm.params.searchNumberStr",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "32px" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              icon: "el-icon-search",
                              size: "small",
                              type: "primary",
                            },
                            on: { click: _vm.enterGetSearch },
                          },
                          [_vm._v(_vm._s(_vm.$t("newOrder.search")) + " ")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "20px" },
                            attrs: { size: "small", type: "info" },
                            on: { click: _vm.resetData },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("orderCenterCont.clearCurrentContent")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: "btn:logisticsOrders:distribution-dot",
                  expression: "'btn:logisticsOrders:distribution-dot'",
                  arg: "remove",
                },
              ],
              attrs: { size: "small", type: "danger" },
              on: { click: _vm.clickapplyForProvider },
            },
            [_vm._v(" " + _vm._s(_vm.$t("navbar.applyForProvider")) + " ")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "danger" },
              on: { click: _vm.reallocate },
            },
            [_vm._v(" " + _vm._s(_vm.$t("navbar.reallocate")) + " ")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.downloadTheMenu },
            },
            [_vm._v(" " + _vm._s(_vm.$t("navbar.downloadTheMenu")) + " ")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "success" },
              on: { click: _vm.clickChageInfo },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("customerManagements.butchInfoSender")) +
                  " "
              ),
            ]
          ),
          _c(
            "el-button",
            {
              staticStyle: { float: "right" },
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.operationTableDialog },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("orderCenterCont.customizingDisplayColumns")) +
                  " "
              ),
            ]
          ),
          _c(
            "el-button",
            {
              staticStyle: { float: "right" },
              attrs: { size: "small", type: "success" },
              on: { click: _vm.allExportOrder },
            },
            [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")) + " ")]
          ),
        ],
        1
      ),
      _c("AnjunTableList", {
        ref: "anjunTable",
        attrs: {
          "format-time": _vm.isTormat,
          "has-operation": true,
          "header-list": _vm.tableHeaderList,
          hide: false,
          "is-index": true,
          "is-select-btn": true,
          "is-tip": true,
          "table-data": _vm.tableData,
        },
        on: {
          selectTable: _vm.selectTable,
          "on-checkTrajectory": _vm.trajectoryQuery,
          "to-OrderDetail": _vm.toOrderDetail,
        },
      }),
      _c("el-pagination", {
        staticStyle: { margin: "20px 0", "text-align": "right" },
        attrs: {
          "current-page": _vm.queryForm.pageNumber,
          "page-size": _vm.queryForm.pageSize,
          "page-sizes": [10, 50, 100, 200, 400],
          total: _vm.queryForm.total,
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "update:currentPage": function ($event) {
            return _vm.$set(_vm.queryForm, "pageNumber", $event)
          },
          "update:current-page": function ($event) {
            return _vm.$set(_vm.queryForm, "pageNumber", $event)
          },
          "update:pageSize": function ($event) {
            return _vm.$set(_vm.queryForm, "pageSize", $event)
          },
          "update:page-size": function ($event) {
            return _vm.$set(_vm.queryForm, "pageSize", $event)
          },
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c(
        "el-drawer",
        {
          attrs: {
            "append-to-body": true,
            title: _vm.$t("orderCenterCont.LogisticsOrders"),
            visible: _vm.waybillDescription.visible,
            "custom-class": "drawerClass",
            direction: "ltr",
            size: "90%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.waybillDescription, "visible", $event)
            },
          },
        },
        [
          _c("EditCmp", {
            key: _vm.showQueryTrack.trackKey,
            attrs: {
              ids: _vm.waybillDescription.ids,
              "is-edit": _vm.waybillDescription.isEdit,
            },
            on: {
              cancel: function ($event) {
                _vm.waybillDescription.visible = false
              },
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("orderCenterCont.customizingDisplayColumns"),
            visible: _vm.isoperationTable,
            width: "" + (_vm.$i18n.locale === "zh" ? "30%" : "45%"),
          },
          on: {
            "update:visible": function ($event) {
              _vm.isoperationTable = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "tableHeaderForm",
              attrs: { model: _vm.tableHeaderForm, "label-width": "auto" },
            },
            [
              _c("el-form-item", { attrs: { label: "" } }, [
                _c("div", { staticStyle: { "font-size": "18px" } }, [
                  _vm._v(_vm._s(_vm.$t("GlobalSetObj.HideTheColumn"))),
                ]),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.tableHeaderForm.tableHeaderList,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableHeaderForm, "tableHeaderList", $$v)
                        },
                        expression: "tableHeaderForm.tableHeaderList",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: { display: "flex", "flex-flow": "wrap" },
                          attrs: { gutter: 0 },
                        },
                        _vm._l(_vm.bindTableColumns, function (item) {
                          return _c(
                            "el-col",
                            { key: item.id, attrs: { span: 8 } },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "140px",
                                    "word-wrap": "break-word",
                                    "word-break": "break-all",
                                  },
                                },
                                [
                                  _c("el-checkbox", {
                                    attrs: { label: item.label },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { float: "left" },
                  on: { click: _vm.resetTable },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.reset")))]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.isoperationTable = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.operationTable },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            "append-to-body": true,
            title: _vm.$t("orderCenterCont.TrajectoryQuery"),
            visible: _vm.showQueryTrack.visible,
            "custom-class": "drawerClass",
            direction: "ltr",
            size: "60%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.showQueryTrack, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { height: "calc(100vh - 67px)", overflow: "auto" } },
            [
              _c("trackQuery", {
                key: _vm.showQueryTrack.trackKey,
                attrs: { "query-track-param": _vm.queryTrackParam },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("navbar.reallocate"),
            visible: _vm.reDistruForm.reProviderDialog,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.reDistruForm, "reProviderDialog", $event)
            },
            close: _vm.handleReProviderDialog,
          },
        },
        [
          _c(
            "div",
            [
              _c("div", { staticClass: "titleLabel" }, [
                _vm._v(_vm._s(_vm.$t("navbar.pleaseSelectProvider"))),
              ]),
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                    clearable: "",
                    filterable: "",
                    size: "small",
                  },
                  model: {
                    value: _vm.reDistruForm.providerId,
                    callback: function ($$v) {
                      _vm.$set(_vm.reDistruForm, "providerId", $$v)
                    },
                    expression: "reDistruForm.providerId",
                  },
                },
                _vm._l(_vm.provideNameList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.providerChannelName,
                      value:
                        item.id +
                        "," +
                        item.providerChannelName +
                        "," +
                        item.providerChannelCode,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.reDistruForm.reProviderDialog = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("newOrder.cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.comfirmDistru },
                },
                [_vm._v(_vm._s(_vm.$t("newOrder.save")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("customerManagements.butchInfoSender"),
            visible: _vm.senderInformationDialog,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.senderInformationDialog = $event
            },
            close: _vm.handleReProviderDialog,
          },
        },
        [
          _c(
            "div",
            [
              _c("div", { staticClass: "titleLabel" }, [
                _vm._v(_vm._s(_vm.$t("customerManagements.tip102"))),
              ]),
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                    clearable: "",
                    filterable: "",
                    size: "small",
                  },
                  on: { change: _vm.handleSenderInformationId },
                  model: {
                    value: _vm.senderInformationId,
                    callback: function ($$v) {
                      _vm.senderInformationId = $$v
                    },
                    expression: "senderInformationId",
                  },
                },
                _vm._l(_vm.senderInformationList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.scheme, value: "" + item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.senderInformationDialog = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("newOrder.cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.comfirmChange },
                },
                [_vm._v(_vm._s(_vm.$t("newOrder.save")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }