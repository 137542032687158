<!--
用户信息操作
-->
<template>
  <div class="app-container userOperation">
    <el-dialog
      :close-on-click-modal="true"
      :title="userOperationPop.title"
      :visible.sync="userOperationPop.visible"
      width="60%"
      @close="closeUserOperation"
      @open="openUserOperation"
    >
      <div>
        <el-form
          ref="userOperationRefOne"
          :model="userForm"
          :rules="userOperationRules"
          label-width="150px"
        >
          <el-row justify="start" style="flex-wrap: wrap;" type="flex">
            <el-col :span="12">
              <!-- label="账号："-->
              <el-form-item :label="$t('userManage.usernameL')" prop="username">
                <el-input v-model.trim="userForm.username" :disabled="isUsername" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 电话： -->
              <el-form-item :label="$t('userManage.userPhoneL')" prop="phone" property="电话">
                <el-input v-model="userForm.phone" />  <!--:disabled="isEdit" -->
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- CPF 个人税号： -->
              <el-form-item label="CPF：" prop="cpf">
                <el-input v-model.trim="userForm.cpf" :placeholder="$t('collectionCenter.enterpriseTaxNumTips11')" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 真实姓名： -->
              <el-form-item :label="$t('userManage.realNameL')" prop="realName" property="真实姓名">
                <el-input v-model.trim="userForm.realName" clearable />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 网点： -->
              <el-form-item :label="$t('collectionCenter.belongToSite')" prop="siteId">
                <el-select
                  v-model="userForm.siteId"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  filterable
                  @focus="getSiteList"
                >
                  <el-option
                    v-for="(item, index) in siteIdList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 签约CNPJ： -->
              <el-form-item
                :label="$t('collectionCenter.QYcnpj') + '：'"
                :rules="{required: isDriver, validator: cnpjValidator, trigger: ['blur', 'change']}"
                prop="signCnpj"
              >
                <el-input
                  v-model="userForm.signCnpj" :placeholder="$t('collectionCenter.enterpriseTaxNumTips14')"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 驾驶证： -->
              <el-form-item
                :label="$t('collectionCenter.modalDriverLicence') + '：'"
                :rules="{required: isDriver, message: $t('collectionCenter.notNull'), trigger: ['blur', 'change']}"
                prop="driverLicense"
              >
                <el-input
                  v-model="userForm.driverLicense" :placeholder="$t('collectionCenter.pleaseInputNumber')" clearable
                  @input="inputDriverLicense"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 英文名： -->
              <el-form-item :label="$t('userManage.EnglishName')">
                <el-input v-model.trim="userForm.englishName" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 所属平台： -->
              <el-form-item :label="$t('userManage.PlatformWhichBelongs')" prop="platformCode" property="所属平台">
                <el-select v-model.trim="userForm.platformCode" clearable filterable>
                  <el-option
                    v-for="(item,index) in platform"
                    :key="'platformCode' + index"
                    :label="item.platformName"
                    :value="item.platformCode"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="uploaderClass">
              <!-- 附件： -->
              <el-form-item :label="$t('collectionCenter.enclosure')" prop="attachmentUrl">
                <el-upload
                  :before-upload="beforeUpload"
                  :file-list="fileList"
                  :http-request="Upload"
                  :limit="1"
                  :on-remove="handleRemove"
                  :show-file-list="true"
                  action
                  class="avatar-uploader"
                >
                  <span v-if="!userForm.attachmentUrl || userForm.attachmentUrl.length === 0">
                    <i class="el-icon-plus avatar-uploader-icon bd" />
                  </span>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 用户角色： -->
              <el-form-item :label="$t('userManage.roles')" prop="roles" property="用户角色">
                <el-select
                  ref="rolesRef" v-model="userForm.roles" clearable
                  filterable @change="chooseRoles"
                >
                  <el-option
                    v-for="item in roleList"
                    :key="'roleID' + item.id"
                    :label="item.roleName"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 用户状态： -->
              <el-form-item :label="$t('userManage.userState')" prop="userState" property="用户状态">
                <el-radio-group v-model.trim="userForm.userState" size="small">
                  <!-- 在职 -->
                  <el-radio-button label="1">{{ $t('userManage.onJob') }}</el-radio-button>
                  <!-- 离职 -->
                  <el-radio-button label="2">{{ $t('userManage.leaveJob') }}</el-radio-button>
                  <!-- 休假 -->
                  <el-radio-button label="3">{{ $t('userManage.vacation') }}</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 员工编号： -->
              <el-form-item :label="$t('userManage.code')" prop="code" property="员工编号">
                <el-input
                  v-model.trim="userForm.code"
                  :disabled="userOperationPop.type == 'add' ? false : true"
                  @blur="userCodeCheck(userForm.code)"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 是否为审核人： -->
              <el-form-item :label="$t('userManage.hasAudit')" prop="hasAudit">
                <el-radio-group v-model.trim="userForm.hasAudit" size="small">
                  <!-- 是 -->
                  <el-radio-button label="1">{{ $t('userManage.Yes') }}</el-radio-button>
                  <!-- 否 -->
                  <el-radio-button label="0">{{ $t('userManage.No') }}</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 组织机构： -->
              <el-form-item :label="$t('userManage.orgCode')" prop="orgCode">
                <el-cascader
                  ref="cascader"
                  v-model="userForm.orgCode"
                  :options="organizationTree"
                  :props="organizationTreeProps"
                  :show-all-levels="false"
                  clearable
                  filterable
                  @change="organizationTreeChange"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 账户状态： -->
              <el-form-item :label="$t('userManage.userAccountStatus')" prop="accountState" property="账户状态">
                <el-radio-group v-model.trim="userForm.accountState" :disabled="isEdit" size="small">
                  <!-- 启用 -->
                  <el-radio-button label="1">{{ $t('userManage.enable') }}</el-radio-button>
                  <!-- 停用 -->
                  <el-radio-button label="0">{{ $t('userManage.Disable') }}</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 职位： -->
              <el-form-item :label="$t('userManage.position')" prop="position" property="职位">
                <el-select v-model="userForm.position" :placeholder="$t('userManage.pleaseChoose')" clearable>
                  <el-option
                    v-for="item in positionList"
                    :key="'id' + item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 使用范围： -->
              <el-form-item :label="$t('userManage.useRange')">
                <el-checkbox-group v-model="scopeOfUseCheck">
                  <el-checkbox label="1">PC</el-checkbox>
                  <el-checkbox label="2">PDA</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 邮箱： -->
              <el-form-item :label="$t('userManage.email')" prop="email">
                <el-input v-model.trim="userForm.email" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 业务范围： -->
              <el-form-item prop="location">
                <template slot="label">
                  <div style="display: inline-block;">
                    <span>
                      <el-tooltip class="QuestionMark" placement="top">
                        <div slot="content">{{ $t('userManage.business1') }}</div>
                        <svg-icon icon-class="QuestionMark" />
                      </el-tooltip>
                    </span>
                    <span>{{ $t('userManage.business') }}</span>
                  </div>
                </template>
                <el-cascader
                  v-model="userForm.location"
                  :options="AreaList"
                  :props="modifiedProps"
                  :show-all-levels="false"
                  clearable
                  filterable
                  @change="handlelineBusiness"
                />
              </el-form-item>
            </el-col>
            <el-col v-if="!isEdit" :span="12">
              <!-- 密码： -->
              <el-form-item
                :label="$t('userManage.password')"
                :prop="!isEdit?'password':'password'"
                property="密码"
              >
                <el-input v-model.trim="userForm.password" maxlength="12" minlength="8" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- document number： -->
              <el-form-item label="document number：" prop="documentNumber">
                <el-input v-model="userForm.documentNumber" type="number" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 用户类别： -->
              <el-form-item :label="$t('userManage.userCategory')" prop="userType">
                <el-checkbox v-model="getUserTypee" @change="changeUserType">{{
                  $t('userManage.onlyDotUse')
                }}
                </el-checkbox><!-- 仅在网点使用 -->
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <!-- 备注： -->
              <el-form-item :label="$t('userManage.remark')" prop="remark" property="备注">
                <el-input v-model.trim="userForm.remark" type="textarea" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" style="text-align: right;">
              <!-- 确定 -->
              <el-button
                icon="el-icon-search" size="small" type="primary"
                @click="submit('userOperationRefOne')"
              >
                {{ $t('userManage.confirm') }}
              </el-button>
              <el-button
                icon="el-icon-refresh"
                size="small"
                type="warning"
                @click="$emit('closeUserOperationDialog')"
              >
                <!-- 取消 -->
                {{ $t('userManage.cancel') }}
              </el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { searchSiteIdList } from '@/api/lanshou';
import { isChinese, isEmoge, isLetterOrNum, phoneNumberCode, weakPwd } from '@/utils/validate';
import md5 from 'js-md5';
// import { getRoleList } from '@/api/role';
// import { getOrganizationTree } from '@/api/organization';
import {
  addUser,
  apiGetPositionList,
  areaList,
  checkCodeUnique,
  checkUserParameter,
  editUser,
  roleByOrg
} from '@/api/user';
import { pagePlatformInfo } from '@/api/channelManage';
// import { MessageBox } from 'element-ui';
import { uploadOos } from '@/utils/ali_oos_util';

export default {
  name: 'UserOperation',
  props: {
    userOperationPop: {
      type: Object,
      required: true
    },

    // 组织架构树结构图
    organizationTree: {
      type: Array,
      required: true
    }
  },
  data() {
    const cnpjValidator = (rule, value, callback) => {
      if (!this.isDriver && !this.userForm.signCnpj) {
        return callback();
      }
      const reg = /^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}\-[0-9]{2}$/;
      if (!reg.test(value)) {
        return callback(new Error(this.$t('collectionCenter.enterpriseTaxNumTips14'))); // 数字长度为14，格式为`xx.xxx.xxx/yyyy-zz`
      } else {
        return callback();
      }
    };
    return {
      uploadOK: false,
      isUploadImg: false,
      cnpjValidator,
      isDriver: false, // 当角色为司机时必填
      getUserTypee: false,
      scopeOfUseCheck: [],
      password: '', // 赋值给userOperationRules.password
      userOperationRules: {
        code: [
          // '不能为空'
          { required: true, message: this.$t('userManage.notNull'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (value.length < 6 || value.length > 10) {
                // '请输入6到10位编号'
                return callback(new Error(this.$t('collectionCenter.inputNumber')));
              } else {
                callback();
              }
            }
          }
        ],
        // '请选择站点'
        siteId: [{ required: true, message: this.$t('userManage.pleaseCheckSite'), trigger: ['blur', 'change'] }],
        username: [
          // '请填写账户名'
          { required: true, message: this.$t('userManage.inputName'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (!isLetterOrNum(value)) {
                // '账户名必须为字母或者数字'
                return callback(new Error(this.$t('userManage.nameIsLetterOrSumber')));
              } else {
                callback();
              }
            },
            trigger: ['blur', 'change']
          },
          // '长度在 5 到 15 个字符'
          { min: 5, max: 15, message: this.$t('userManage.longIs5To15'), trigger: ['blur', 'change'] },
          {
            validator: async(rule, value, callback) => {
              if (this.isEdit) {
                // 编辑
                return callback();
              }

              try {
                const { status } = await checkUserParameter(1, value);
                if (status === 'OK') {
                  return callback();
                } else {
                  // '用户名重复,请重新填写'
                  return callback(new Error(this.$t('userManage.nameRepeat')));
                }
              } catch (error) {
                return callback(new Error(this.$t('userManage.nameRepeat')));
              }
            },
            trigger: ['blur', 'change']
          }
        ],
        realName: [
          // '请填写用户真实名'
          { required: true, message: this.$t('userManage.inputRealName'), trigger: ['blur', 'change'] },
          // '长度在 2 到 30 个字符'
          { min: 2, max: 100, message: this.$t('userManage.longIs2To30'), trigger: ['blur', 'change'] }
        ],
        // '请选择用户状态'
        userState: [{ required: true, message: this.$t('userManage.ChooseUserState'), trigger: ['blur', 'change'] }],
        password: [
          // '请填写密码'
          { required: true, message: this.$t('userManage.inputPWD'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              // console.log('查看输入密码内容', value);
              if (isChinese(value)) {
                // '密码不能有中文字符.'
                return callback(new Error(this.$t('userManage.pwdNotChineses')));
              } else if (isEmoge(value)) {
                // '密码不能使用表情.'
                return callback(new Error(this.$t('userManage.pwdNotEmoji')));
              } else if (!weakPwd(value)) {
                // '新密码必须由 8-12位字母、数字组成，可选特殊符号.'
                return callback(new Error(this.$t('userManage.passwordPrompt')));
              } else {
                callback();
              }
              this.password = md5(md5(this.userForm.password));
            },
            trigger: ['blur', 'change']
          }
        ],
        // '请选择账户状态'
        accountState: [{
          required: true,
          message: this.$t('userManage.chooseAccountState'),
          trigger: ['blur', 'change']
        }],
        orgCode: [
          // '不能为空'
          { required: true, message: this.$t('userManage.notNull'), trigger: ['blur', 'change'] }
        ],

        // '请选择用户角色'
        roles: [{ required: true, message: this.$t('userManage.chooseRoles'), trigger: ['blur', 'change'] }],
        phone: [
          // '请填写联系电话'
          { required: true, message: this.$t('userManage.inputPhone'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (value && !phoneNumberCode(value)) {
                return callback(new Error(this.$t('collectionCenter.brazilPhoneRestrict'))); // 长度不低于10位，允许包含+-空格等特殊字符
              } else {
                callback();
              }
            }
          }
        ],
        // 业务范围
        location: [{ required: false, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: ['blur', 'change'] }],
        cpf: [
          // '不能为空'
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              const reg = /^[0-9]{3}\.[0-9]{3}\.[0-9]{3}\-[0-9]{2}$/;
              if (!reg.test(value)) {
                return callback(new Error(this.$t('collectionCenter.enterpriseTaxNumTips11'))); // 数字长度为11，格式为`xxx.xxx.xxx-yy`
              } else {
                return callback();
              }
            }
          }
        ],
        signCnpj: [
          // '不能为空'
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              const reg = /^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}\-[0-9]{2}$/;
              if (!reg.test(value)) {
                return callback(new Error(this.$t('collectionCenter.enterpriseTaxNumTips14'))); // 数字长度为14，格式为`xx.xxx.xxx/yyyy-zz`
              } else {
                return callback();
              }
            }
          }
        ],
        driverLicense: [
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              const reg1 = /[^0-9]/; // 校验不是0-9的数字
              if (!reg1.test(value)) {
                return callback(new Error(this.$t('collectionCenter.inputNum'))); // '请输入数字'
              } else {
                return callback();
              }
            }
          }
        ]
      },

      positionList: [], // 组织机构下的职位列表
      siteIdList: [], // 站点list
      userForm: {
        username: '',
        englishName: '', // 英文名称
        useLimit: [],
        platformCode: '',
        platformName: '',
        siteId: '',
        phone: '',
        remark: '',
        realName: '',
        roles: '',
        password: '',
        userState: 1,
        accountState: 1,
        code: '', // 员工编号
        hasAudit: 0,
        email: '',
        orgCode: '',
        position: '',
        userType: 2,
        documentNumber: '',
        location: [], // 业务范围
        cpf: '',
        signCnpj: '',
        driverLicense: '',
        attachmentUrl: ''
      },
      userFormReset: {},

      resumeUploadName: '', // 上传PDF名称

      // 角色列表  选择
      roleList: [],
      // 平台选择
      platform: [],
      // // 组织架构树结构图
      organizationTreeProps: {
        checkStrictly: true,
        value: 'orgCode',
        label: 'name'
      },
      isUsername: false, // 能否在编辑状态下，编辑用户账号（条件：在编辑状态下同时，存在可以编辑的权限）
      fileList: [],
      orgId: '',
      userId: '',
      siteCopyCode: '', // 将原始值与保存时的网点参数对比，有变化就退出登录
      userFormRoles: '', // 将原始值与保存时的角色参数对比，有变化就刷新
      AreaList: [], // 州  城市列表
      locationList: [],
      modifiedProps: {
        value: 'id',
        label: 'nameEn',
        children: 'city',
        multiple: true,
        checkStrictly: true
      }
    };
  },
  computed: {
    isEdit: function() {
      return this.userOperationPop.type === 'edit';
    }
  },
  watch: {
    userOperationPop: {
      handler(newValue, oldValue) {
        if (this.userOperationPop.visible) {
          setTimeout(() => {
            this.$refs.userOperationRefOne.clearValidate();
          }, 0);
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.getAreaList();
    this.init();
    this.getSiteList();
    this.getUserTypee = false;
    this.getUserOrg(1);
  },
  methods: {
    chooseRoles() {
      this.roleList.forEach(arr => {
        if (arr.id === this.userForm.roles) {
          this.isDriver = arr.roleName === this.$t('collectionCenter.driver1');
          setTimeout(() => {
            this.$refs['userOperationRefOne'].validateField('signCnpj');
            this.$refs['userOperationRefOne'].validateField('driverLicense');
          }, 300);
        }
      });
    },
    inputDriverLicense(e) {
      let value = e.replace(/[^\d]/g, '');
      value = value.replace(/^0+(\d)/, '$1');
      this.userForm.driverLicense = value;
    },
    beforeUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1);
      const whiteList = ['PDF', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'png', 'jpg', 'bmg', 'jpeg', 'txt', 'MP4', 'mp4', 'GIF', 'gif'];
      if (whiteList.indexOf(fileSuffix) === -1) {
        // 上传文件支持PDF、doc、docx、xls、xlsx、csv、png、jpg、bmg、jpeg、txt、MP4、GIF等格式
        this.$message.error(this.$t('collectionCenter.uploadFileFormat'));
        return false;
      }
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        // 上传文件大小不能超过 10MB
        this.$message.error(this.$t('collectionCenter.uploadNot10MB'));
        return false;
      }
    },
    async Upload(option) {
      try {
        const file = option.file;
        if (file) {
          this.isUploadImg = true;
        }
        const res = await uploadOos(file, 'user');
        if (res.code === 200) {
          this.uploadOK = true;
          this.userForm.attachmentUrl = res.url;
        } else {
          this.uploadOK = false;
          this.$message.error(res.msg);
        }
      } catch (error) {
        console.error(error);
      }
    },
    handleRemove(file, fileList) {
      this.userForm.attachmentUrl = fileList;
    },
    // 切换业务范围
    handlelineBusiness(val) {
      console.log(val, 'valvalval');
      this.locationList = [];
      if (val) {
        val.forEach((item) => {
          const obj = {};
          if (item.length > 1) {
            obj.stateId = item[0];
            obj.cityId = item[1];
          } else {
            obj.stateId = item[0];
            obj.cityId = null;
          }
          this.locationList.push(obj);
        });
      }
    },
    // 获取州
    getAreaList() {
      this.AreaList = [];
      areaList().then(res => {
        if (res.status === 'OK') {
          this.AreaList = res.data;
        }
      });
    },
    getSiteList() {
      searchSiteIdList().then(res => {
        if (res.status === 'OK') {
          this.siteIdList = [];
          res.data.forEach(data => {
            this.siteIdList.push({ id: data.id, name: data.name });
          });
        }
      });
    },
    changeUserType(data) { // 选择用户类别更新用户角色列
      this.roleList = [];
      this.userForm.roles = '';
      let userType = 1;
      if (data) {
        userType = 2;
        this.getUserTypee = true;
      } else {
        userType = 1;
        this.getUserTypee = false;
      }
      this.getUserOrg(userType);
    },
    getUserOrg(userType) { // 获取用户角色列
      // // 获取所有角色列
      // getRoleList().then((response) => {
      //   this.roleList = response.data;
      // }).catch(err => {
      //   console.error(err);
      // });
      roleByOrg(userType).then(res => {
        if (res.status === 'OK') {
          const language = sessionStorage.getItem('language') || 'pu';
          // this.roleList = res.data;
          this.roleList = res.data.map(list => {
            let roleName = '';
            const id = list.id;
            if (language === 'zh') {
              roleName = list.roleName;
            }
            if (language === 'pu') {
              roleName = list.portugal;
            }
            return {
              roleName,
              id
            };
          });
          this.chooseRoles();
          // 根据授权提供对应的角色列表
        }
      }).catch({});
    },
    init() {
      this.userFormReset = { ...this.userForm };
      this.getPlatformList();
      // this.getUserOrg();
    },
    // 获取平台列表userForm
    getPlatformList() {
      const param = {
        orderDirection: 'DESC',
        orderProperty: '',
        pageNumber: 1,
        pageSize: 10000,
        params: {
          createUser: '',
          id: '',
          remark: '',
          platformCode: '',
          platformName: ''
        }
      };
      pagePlatformInfo(param).then(res => {
        if (res.status === 'OK') {
          // this.platform = res.tableData;
          this.platform = res.data.records;
        }
      }).catch(err => {
        console.log(err);
      });
    },
    closeUserOperation() {
      // 移除校验结果
      this.$nextTick(() => {
        this.scopeOfUseCheck = [];
        this.$refs['userOperationRefOne'].clearValidate();
        this.$refs.userOperationRefOne.resetFields();
        this.fileList = [];
        this.isDriver = false;
        this.userOperationPop.user.attachmentUrl = '';
        this.$refs['userOperationRefOne'].clearValidate('signCnpj');
        this.$refs['userOperationRefOne'].clearValidate('driverLicense');
      });
      // this.$emit('UserTableDataRefresh');
    },
    openUserOperation() {
      this.cloneFormData(); // 先清空数据
      this.$nextTick(() => {
        this.$refs['userOperationRefOne'].clearValidate();
      });
      this.userFormRoles = this.userOperationPop.user.roles;
      this.siteCopyCode = this.userOperationPop.user.siteId;
      this.userId = this.userOperationPop.user.id;
      const data = { ...this.userOperationPop };
      const cpfCope = data.user.cpf ? data.user.cpf.slice(0, 3) + '.' + data.user.cpf.slice(3, 6) + '.' + data.user.cpf.slice(6, 9) + '-' + data.user.cpf.slice(9, 11) : '';
      const signCnpjCope = data.user.signCnpj ? data.user.signCnpj.slice(0, 2) + '.' + data.user.signCnpj.slice(2, 5) + '.' + data.user.signCnpj.slice(5, 8) + '/' + data.user.signCnpj.slice(8, 12) + '-' + data.user.signCnpj.slice(12, 14) : '';
      data.user.cpf = cpfCope;
      data.user.signCnpj = signCnpjCope;
      // 如果角色列为空
      let getUserType = '';
      if (this.roleList.length <= 0 || !this.orgId) {
        if (this.isEdit) {
          this.getUserTypee = data.user.userType === 2 ? true : data.user.userType === 1 ? false : 1;
          getUserType = data.user.userType || 1;
        } else {
          getUserType = 1;
          this.getUserTypee = false;
        }
        this.getUserOrg(getUserType);
      }
      if (this.isEdit) {
        this.userForm.orgCode = data.user.orgCode;
      }
      if (data.user && this.isEdit) {
        {
          // 能否编辑账号
          const isBool = this.$store.getters.button_permissions.includes('btn:user-center:user:edit-user-name');
          if (isBool) {
            this.isUsername = false;
          } else {
            this.isUsername = true;
          }
        }
        data.user.location = [];
        this.userForm = data.user;
        if (data.user.userAreaList.length) {
          data.user.userAreaList.forEach(item => {
            let arr = [];
            if (item.stateId && item.cityId) {
              arr = [item.stateId, item.cityId];
            } else {
              if (item.stateId) {
                arr.push(item.stateId);
              }
              if (item.cityId) {
                arr.push(item.cityId);
              }
            }

            this.userForm.location.push(arr);
          });
        }
        // 格式化角色id为int类型 不然不会默认选中
        if (this.userForm.roles) {
          this.userForm.roles = parseInt(data.user.roles);
        }
        if (this.userForm.useLimit) {
          this.scopeOfUseCheck = this.userForm.useLimit.split(',');
        }
      } else {
        this.userForm = this.userFormReset;
        this.isUsername = false;
      }

      if (this.userOperationPop.user.attachmentUrl) {
        const ind = this.userOperationPop.user.attachmentUrl.lastIndexOf('/');
        const file = this.userOperationPop.user.attachmentUrl.substring(ind + 1, this.userOperationPop.user.attachmentUrl.length);
        this.fileList = [{
          name: file,
          url: data.attachmentUrl
        }];
      } else {
        this.fileList = [];
      }
    },

    // 数据清空操作
    cloneFormData() {
      for (const key in this.userForm) {
        this.userForm[key] = '';
      }
      this.userForm.userState = 1;
      this.userForm.accountState = 1;
      this.userForm.hasAudit = 0;
    },

    // 员工编号校验
    userCodeCheck(code) {
      if (this.userOperationPop.type === 'add') {
        if (code && code.length >= 6 && code.length <= 10) {
          checkCodeUnique(code).then(res => {
            if (res.status === 'OK') {
              this.$message.success(this.$t('userManage.codeUse')); // '员工编号可用'
            } else {
              this.$message.success(this.$t('userManage.codeRepeat')); // '员工编号重复，不可用'
            }
          }).catch({});
        }
      }
    },

    /**
     * 提交用户信息
     */
    async submit(formName) {
      try { // 校验form表单
        this.$refs[formName].validate((valid) => {
          if (!valid) {
            return;
          }
          if (this.userForm.platformCode) {
            this.platform.forEach(item => {
              if (item.platformCode === this.userForm.platformCode) {
                this.userForm.platformName = item.platformName;
              }
            });
          }
          // this.userForm.password = this.password;
          this.userForm.useLimit = this.scopeOfUseCheck.toString();
          if (this.isUploadImg && !this.uploadOK) {
            this.$message.warning(this.$t('collectionCenter.FileReadingFailurePleaseWait'));
            return;
          }
          const data = { ...this.userForm };
          data.password = this.password;
          if (this.locationList.length > 0) {
            data.userAreaList = this.locationList;
          } else {
            data.userAreaList = null;
          }
          delete data.location;
          this.userForm.userType = this.getUserTypee;
          data.userType = this.userForm.userType === true ? 2 : this.userForm.userType === false ? 1 : '';
          if (data.orgCode) {
            data.orgCode = data.orgCode.toString();
          }

          // 匹配职位
          if (data.position) {
            this.positionList.forEach(item => {
              if (item.id === data.position) {
                data.positionName = item.name;
              }
            });
          }
          data.documentNumber = Number(data.documentNumber);
          data.attachmentUrl = data.attachmentUrl ? data.attachmentUrl.toString() : '';
          const cpfStr = this.filterNumbersFromString(data.cpf);
          const signCnpjStr = this.filterNumbersFromString(data.signCnpj);
          data.cpf = cpfStr;
          data.signCnpj = signCnpjStr;
          if (!this.isEdit) {
            addUser(data).then((response) => {
              const { status } = response;
              if (status === 'OK') {
                this.isUploadImg = false;
                this.uploadOK = false;
                // '操作成功!'
                this.$message.success(this.$t('userManage.TheOperationSucceeded'));
                this.$emit('closeUserOperationDialog');
              }
            }).catch(err => {
              console.log(err);
            });
          } else {
            editUser(data).then((response) => {
              const { status } = response;
              if (status === 'OK') {
                this.isUploadImg = false;
                this.uploadOK = false;
                // '操作成功!'
                this.$message.success(this.$t('userManage.TheOperationSucceeded'));
                this.$emit('closeUserOperationDialog');
                if (this.siteCopyCode !== this.userForm.siteId) {
                  if (this.$store.state.user.id === this.userId) {
                    setTimeout(() => {
                      // 退出登录
                      this.$store.dispatch('user/logout');
                      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
                    }, 1500);
                  }
                  return;
                }
                if (data.roles !== Number(this.userFormRoles)) {
                  setTimeout(() => {
                    location.reload();
                  }, 1500);
                  return;
                }
              }
            }).catch(err => {
              console.log(err);
            });
          }
        });
      } catch (err) {
        console.error(err);
      }
    },
    filterNumbersFromString(str) {
      return str.match(/\d/g)?.join('') || '';
    },

    /**
     * 选择组织结构
     */
    organizationTreeChange(val) {
      if (this.$refs['cascader']) {
        // const obj = this.$refs['cascader'].getCheckedNodes()[0] || {};
        const num = val[val.length - 1];
        this.userForm.orgCode = num;
        this.userForm.position = '';
        this.positionList = [];
        this.asyncGetPositionPage(num);
      }
    },

    /**
     * 获取指定组织机构下的的职务
     */
    asyncGetPositionPage(orgCode) {
      apiGetPositionList({ orgCode }).then(res => {
        const { status, data } = res;
        if (status === 'OK') {
          // const arr = data.data;

          if (data.length === 0) {
            this.$notify({
              // '警告'
              title: this.$t('userManage.warning'),
              // '当前部门下暂无职位，如需添加请前往《用户中心-职务管理》添加'
              message: this.$t('userManage.warningContent'),
              type: 'warning'
            });
            return;
          }

          this.positionList = data.map(itme => {
            const { id, name } = itme;
            return {
              id,
              name
            };
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.uploaderClass ::v-deep .el-form-item__content {
  line-height: 0;
}

.avatar-uploader {
  line-height: 0;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: auto;
  line-height: 100px;
  text-align: center;
  border: 1px dashed #8c939d77;
}

.userOperation ::v-deep .el-form-item__label {
  padding: 0;
  line-height: 40px !important;
}

.el-dialog-div {
  height: 55vh;
  overflow: auto;
}

.userOperation ::v-deep .el-dialog__wrapper {
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  margin: 5vh 0;
}

.userOperation ::v-deep .el-dialog {
  margin: 0 auto !important;
  margin-top: 10vh;
}

.userOperation ::v-deep textarea.el-textarea__inner {
  max-height: 150px;
}

.userOperation ::v-deep .el-select {
  width: 100%;
}

.userOperation ::v-deep .el-cascader {
  width: 100%;
}

::v-deep .el-tree-node__label {
  padding-right: 10px;
}
</style>
