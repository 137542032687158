<template>
  <section class="app-main">
    <transition mode="out-in" name="fade-transform">
      <keep-alive :include="cachedViews">
        <router-view :key="key" />
      </keep-alive>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  data() {
    return {
    };
  },

  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key() {
      return this.$route.path;
    }
  },

  created() {
  },

  methods: {

  }
};
</script>

<style scoped>
.app-main {

  /*50 = navbar  */
  height: calc(100vh - 85px);
  width: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.fixed-header + .app-main {
  padding-top: 50px;
}

.im {
  position: fixed;
  right: 0;
  bottom: 10px;
  /* border: 1px solid red; */
  z-index: 500;
}
</style>

<style lang="scss">
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}

</style>
