var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "siteBox" },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c(
            "el-form",
            { staticClass: "findClass" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("collectionCenter.dotCode") },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "collectionCenter.pleaseInput"
                              ),
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.searchClick($event)
                              },
                            },
                            model: {
                              value: _vm.queryForm.code,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm,
                                  "code",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "queryForm.code",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("collectionCenter.dotName") },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "collectionCenter.pleaseInput"
                              ),
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.searchClick($event)
                              },
                            },
                            model: {
                              value: _vm.queryForm.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "queryForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.accountState"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                clearable: "",
                              },
                              model: {
                                value: _vm.queryForm.siteStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "siteStatus", $$v)
                                },
                                expression: "queryForm.siteStatus",
                              },
                            },
                            _vm._l(_vm.StatusList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.siteStatusName,
                                  value: item.siteStatus,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.deliveryPostcode"),
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              "show-word-limit": "",
                              placeholder: _vm.$t(
                                "collectionCenter.pleaseInput"
                              ),
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.searchClick($event)
                              },
                            },
                            model: {
                              value: _vm.queryForm.deliveryZipcode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm,
                                  "deliveryZipcode",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "queryForm.deliveryZipcode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.businessType"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                clearable: "",
                              },
                              model: {
                                value: _vm.queryForm.businessType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "businessType", $$v)
                                },
                                expression: "queryForm.businessType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("collectionCenter.tudo"),
                                  value: null,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t(
                                    "collectionCenter.turnTransport"
                                  ),
                                  value: 1,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t(
                                    "collectionCenter.smallCollect"
                                  ),
                                  value: 2,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t(
                                    "collectionCenter.smallDelivery"
                                  ),
                                  value: 3,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t(
                                    "collectionCenter.largeDelivery"
                                  ),
                                  value: 4,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.stateSite"),
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "BlockNewlineClass" },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: { maxRows: 5 },
                                  placeholder: _vm.$t(
                                    "collectionCenter.BatchQuery"
                                  ),
                                },
                                on: { blur: _vm.ftS },
                                nativeOn: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.BlockNewStateline($event)
                                  },
                                },
                                model: {
                                  value: _vm.queryForm.state,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryForm, "state", $$v)
                                  },
                                  expression: "queryForm.state",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("collectionCenter.citySite") },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "BlockNewlineClass" },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: { maxRows: 5 },
                                  placeholder: _vm.$t(
                                    "collectionCenter.BatchQuery"
                                  ),
                                },
                                on: { blur: _vm.ftC },
                                nativeOn: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.BlockNewCityline($event)
                                  },
                                },
                                model: {
                                  value: _vm.queryForm.city,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryForm, "city", $$v)
                                  },
                                  expression: "queryForm.city",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "businessWeekDictId" } },
                        [
                          _c(
                            "template",
                            { slot: "label" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "120px" },
                                  attrs: {
                                    placeholder: _vm.$t("base.openingHours"),
                                    clearable: "",
                                    filterable: "",
                                  },
                                  model: {
                                    value: _vm.queryForm.businessWeekDictId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm,
                                        "businessWeekDictId",
                                        $$v
                                      )
                                    },
                                    expression: "queryForm.businessWeekDictId",
                                  },
                                },
                                _vm._l(_vm.weekList, function (item) {
                                  return _c("el-option", {
                                    key: item.dictId,
                                    attrs: {
                                      label: item.dictDescribe,
                                      value: item.dictId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c("el-time-picker", {
                            attrs: { "value-format": "HH:mm", format: "HH:mm" },
                            model: {
                              value: _vm.queryForm.businessTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryForm, "businessTime", $$v)
                              },
                              expression: "queryForm.businessTime",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value: "btn:collection-center:basicDate:site:search",
                          expression:
                            "'btn:collection-center:basicDate:site:search'",
                          arg: "remove",
                        },
                      ],
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-search",
                      },
                      on: { click: _vm.searchClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.search")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value: "btn:collection-center:basicDate:site:reset",
                          expression:
                            "'btn:collection-center:basicDate:site:reset'",
                          arg: "remove",
                        },
                      ],
                      attrs: { icon: "el-icon-refresh", size: "mini" },
                      on: { click: _vm.resetClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value: "btn:collection-center:basicDate:site:add",
                          expression:
                            "'btn:collection-center:basicDate:site:add'",
                          arg: "remove",
                        },
                      ],
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-plus",
                        disabled: _vm.isModify,
                      },
                      on: { click: _vm.addClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.add")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value: "btn:collection-center:basicDate:site:edit",
                          expression:
                            "'btn:collection-center:basicDate:site:edit'",
                          arg: "remove",
                        },
                      ],
                      attrs: {
                        size: "mini",
                        icon: "el-icon-edit-outline",
                        disabled: _vm.isModify,
                      },
                      on: { click: _vm.editClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.edit")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:basicDate:site:allExport",
                          expression:
                            "'btn:collection-center:basicDate:site:allExport'",
                          arg: "remove",
                        },
                      ],
                      class: _vm.isClickAll ? "onlyClickOnce10" : "",
                      attrs: { icon: "el-icon-upload2", size: "mini" },
                      on: { click: _vm.download },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c(
                  "div",
                  {
                    ref: "treeBoxRef",
                    staticClass: "treeClass highlightClass",
                    class:
                      _vm.isModify && _vm.saveBtnName == "add"
                        ? "placeholderClass"
                        : "",
                  },
                  [
                    _c("el-tree", {
                      ref: "treeRef",
                      staticClass: "treeCursor",
                      attrs: {
                        data: _vm.treeData,
                        props: _vm.defaultProps,
                        "highlight-current": _vm.highlightCurrent,
                        accordion: true,
                        "node-key": "id",
                        "current-node-key": _vm.currentNodekey,
                        "default-expanded-keys": _vm.defaultOpen,
                        draggable: "",
                      },
                      on: {
                        "node-click": _vm.handleNodeClick,
                        "node-drag-start": _vm.handleDragStart,
                        "node-drag-end": _vm.handleDragEnd,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var data = ref.data
                            return _c(
                              "span",
                              { staticClass: "custom-tree-node" },
                              [_c("span", [_vm._v(" " + _vm._s(data.code))])]
                            )
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]),
              _c("el-col", { staticClass: "rightClass", attrs: { span: 18 } }, [
                _c(
                  "div",
                  { staticClass: "modifyClass" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "modifyFormRef",
                        attrs: { model: _vm.modifyForm, rules: _vm.rules },
                      },
                      [
                        _c(
                          "el-row",
                          {
                            staticStyle: { "flex-wrap": "wrap" },
                            attrs: { type: "flex", justify: "space-around" },
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "code",
                                      label: _vm.$t("collectionCenter.dotCode"),
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "",
                                        disabled: !_vm.isModify,
                                      },
                                      model: {
                                        value: _vm.modifyForm.code,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.modifyForm, "code", $$v)
                                        },
                                        expression: "modifyForm.code",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "name",
                                      label: _vm.$t("collectionCenter.dotName"),
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        maxlength: "100",
                                        "show-word-limit": "",
                                        clearable: "",
                                        placeholder: "",
                                        disabled: !_vm.isModify,
                                      },
                                      model: {
                                        value: _vm.modifyForm.name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.modifyForm,
                                            "name",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "modifyForm.name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "businessPrincipal",
                                      label: _vm.$t(
                                        "collectionCenter.businessSubject"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "",
                                          clearable: "",
                                          filterable: "",
                                          disabled: !_vm.isModify,
                                        },
                                        on: {
                                          focus: _vm.getProviderList,
                                          change: _vm.changeBusinessPrincipal,
                                        },
                                        model: {
                                          value:
                                            _vm.modifyForm.businessPrincipal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.modifyForm,
                                              "businessPrincipal",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "modifyForm.businessPrincipal",
                                        },
                                      },
                                      _vm._l(
                                        _vm.businessPrincipalList,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.providerCode,
                                              value: item.providerId,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "providerChannelCode",
                                      label: _vm.$t(
                                        "collectionCenter.providerChannel"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "",
                                          clearable: "",
                                          filterable: "",
                                          disabled: !_vm.isModify,
                                        },
                                        on: {
                                          focus: _vm.checkProviderChannelValue,
                                          change: _vm.changeProviderChannel,
                                        },
                                        model: {
                                          value:
                                            _vm.modifyForm.providerChannelCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.modifyForm,
                                              "providerChannelCode",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "modifyForm.providerChannelCode",
                                        },
                                      },
                                      _vm._l(
                                        _vm.providerChannelNameList,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.name,
                                              value: item.code,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "businessModel",
                                      label: _vm.$t(
                                        "collectionCenter.businessModel"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "",
                                          clearable: "",
                                          filterable: "",
                                          disabled: !_vm.isModify,
                                        },
                                        on: { change: _vm.changeBusinessModel },
                                        model: {
                                          value: _vm.modifyForm.businessModel,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.modifyForm,
                                              "businessModel",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "modifyForm.businessModel",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.isAll == 1 ||
                                                _vm.isAll == 0,
                                              expression:
                                                "isAll == 1 || isAll == 0",
                                            },
                                          ],
                                          attrs: {
                                            label: _vm.$t(
                                              "collectionCenter.directSales"
                                            ),
                                            value: 1,
                                          },
                                        }),
                                        _c("el-option", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.isAll == 2 ||
                                                _vm.isAll == 0,
                                              expression:
                                                "isAll == 2 || isAll == 0",
                                            },
                                          ],
                                          attrs: {
                                            label: _vm.$t(
                                              "collectionCenter.joinSales"
                                            ),
                                            value: 2,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "siteType",
                                      label: _vm.$t("collectionCenter.dotType"),
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "",
                                          clearable: "",
                                          filterable: "",
                                          disabled: !_vm.isModify,
                                        },
                                        on: { change: _vm.changeSiteType },
                                        model: {
                                          value: _vm.modifyForm.siteType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.modifyForm,
                                              "siteType",
                                              $$v
                                            )
                                          },
                                          expression: "modifyForm.siteType",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: _vm.$t(
                                              "collectionCenter.hut"
                                            ),
                                            value: 1,
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: _vm.$t(
                                              "collectionCenter.merchant"
                                            ),
                                            value: 5,
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: _vm.$t(
                                              "collectionCenter.dot"
                                            ),
                                            value: 2,
                                          },
                                        }),
                                        _c("el-option", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.modifyForm.businessModel !=
                                                2,
                                              expression:
                                                "modifyForm.businessModel != 2",
                                            },
                                          ],
                                          attrs: {
                                            label: _vm.$t(
                                              "collectionCenter.transferCenter"
                                            ),
                                            value: 3,
                                          },
                                        }),
                                        _c("el-option", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.modifyForm.businessModel !=
                                                2,
                                              expression:
                                                "modifyForm.businessModel != 2",
                                            },
                                          ],
                                          attrs: {
                                            label: _vm.$t(
                                              "collectionCenter.headOffice"
                                            ),
                                            value: 4,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "ywlxClass",
                                    attrs: {
                                      prop: "businessType",
                                      label: _vm.$t(
                                        "collectionCenter.businessType"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          multiple: "",
                                          placeholder: "",
                                          clearable: "",
                                          filterable: "",
                                          disabled: !_vm.isModify,
                                        },
                                        on: { change: _vm.changeBusinessType },
                                        model: {
                                          value: _vm.modifyForm.businessType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.modifyForm,
                                              "businessType",
                                              $$v
                                            )
                                          },
                                          expression: "modifyForm.businessType",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.modifyForm.siteType != 1 &&
                                                _vm.modifyForm.siteType != 2 &&
                                                _vm.modifyForm.siteType != 5,
                                              expression:
                                                "modifyForm.siteType != 1 && modifyForm.siteType != 2 && modifyForm.siteType != 5",
                                            },
                                          ],
                                          attrs: {
                                            label: _vm.$t(
                                              "collectionCenter.turnTransport"
                                            ),
                                            value: 1,
                                          },
                                        }),
                                        _c("el-option", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.modifyForm.siteType != 3,
                                              expression:
                                                "modifyForm.siteType != 3",
                                            },
                                          ],
                                          attrs: {
                                            label: _vm.$t(
                                              "collectionCenter.smallCollect"
                                            ),
                                            value: 2,
                                          },
                                        }),
                                        _c("el-option", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.modifyForm.siteType != 3,
                                              expression:
                                                "modifyForm.siteType != 3",
                                            },
                                          ],
                                          attrs: {
                                            label: _vm.$t(
                                              "collectionCenter.smallDelivery"
                                            ),
                                            value: 3,
                                          },
                                        }),
                                        _c("el-option", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.modifyForm.siteType != 3,
                                              expression:
                                                "modifyForm.siteType != 3",
                                            },
                                          ],
                                          attrs: {
                                            label: _vm.$t(
                                              "collectionCenter.largeDelivery"
                                            ),
                                            value: 4,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "contactsName",
                                      label: _vm.$t("collectionCenter.head"),
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "",
                                        disabled: !_vm.isModify,
                                      },
                                      model: {
                                        value: _vm.modifyForm.contactsName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.modifyForm,
                                            "contactsName",
                                            $$v
                                          )
                                        },
                                        expression: "modifyForm.contactsName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "contactsTel",
                                      label: _vm.$t(
                                        "collectionCenter.contactPhone"
                                      ),
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "",
                                        disabled: !_vm.isModify,
                                      },
                                      model: {
                                        value: _vm.modifyForm.contactsTel,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.modifyForm,
                                            "contactsTel",
                                            $$v
                                          )
                                        },
                                        expression: "modifyForm.contactsTel",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "bank",
                                      label: _vm.$t(
                                        "collectionCenter.openTheBank"
                                      ),
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "",
                                        disabled: !_vm.isModify,
                                      },
                                      model: {
                                        value: _vm.modifyForm.bank,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.modifyForm, "bank", $$v)
                                        },
                                        expression: "modifyForm.bank",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "siteStatus",
                                      label: _vm.$t(
                                        "collectionCenter.ifEnabled"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "",
                                          clearable: "",
                                          filterable: "",
                                          disabled: !_vm.isModify,
                                        },
                                        model: {
                                          value: _vm.modifyForm.siteStatus,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.modifyForm,
                                              "siteStatus",
                                              $$v
                                            )
                                          },
                                          expression: "modifyForm.siteStatus",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: _vm.$t(
                                              "collectionCenter.notEnabled"
                                            ),
                                            value: 0,
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: _vm.$t(
                                              "collectionCenter.enable"
                                            ),
                                            value: 1,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { staticClass: "NumClass", attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "zipCode",
                                      label: _vm.$t(
                                        "collectionCenter.dotZipCode"
                                      ),
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "",
                                        disabled: !_vm.isModify,
                                      },
                                      model: {
                                        value: _vm.modifyForm.zipCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.modifyForm,
                                            "zipCode",
                                            $$v
                                          )
                                        },
                                        expression: "modifyForm.zipCode",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "state",
                                      label: _vm.$t(
                                        "collectionCenter.dotState"
                                      ),
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "",
                                        disabled: !_vm.isModify,
                                      },
                                      model: {
                                        value: _vm.modifyForm.state,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.modifyForm, "state", $$v)
                                        },
                                        expression: "modifyForm.state",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "city",
                                      label: _vm.$t("collectionCenter.dotCity"),
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "",
                                        disabled: !_vm.isModify,
                                      },
                                      model: {
                                        value: _vm.modifyForm.city,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.modifyForm, "city", $$v)
                                        },
                                        expression: "modifyForm.city",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "area",
                                      label: _vm.$t("collectionCenter.dotArea"),
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "",
                                        disabled: !_vm.isModify,
                                      },
                                      model: {
                                        value: _vm.modifyForm.area,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.modifyForm, "area", $$v)
                                        },
                                        expression: "modifyForm.area",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "area",
                                      label: _vm.$t(
                                        "collectionCenter.dotStreet"
                                      ),
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "",
                                        disabled: !_vm.isModify,
                                      },
                                      model: {
                                        value: _vm.modifyForm.street,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.modifyForm,
                                            "street",
                                            $$v
                                          )
                                        },
                                        expression: "modifyForm.street",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { staticClass: "NumClass", attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "address",
                                      label: _vm.$t(
                                        "collectionCenter.dotAdress"
                                      ),
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        maxlength: "255",
                                        "show-word-limit": "",
                                        placeholder: "",
                                        disabled: !_vm.isModify,
                                      },
                                      model: {
                                        value: _vm.modifyForm.address,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.modifyForm,
                                            "address",
                                            $$v
                                          )
                                        },
                                        expression: "modifyForm.address",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "longitude",
                                      label: _vm.$t(
                                        "orderCenterCont.longitude"
                                      ),
                                      rules: {
                                        required: _vm.modifyForm.longitude
                                          ? true
                                          : false,
                                        validator: _vm.longitudeValidator,
                                        trigger: ["blur", "change"],
                                      },
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "",
                                        disabled: !_vm.isModify,
                                      },
                                      model: {
                                        value: _vm.modifyForm.longitude,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.modifyForm,
                                            "longitude",
                                            $$v
                                          )
                                        },
                                        expression: "modifyForm.longitude",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "latitude",
                                      label: _vm.$t("orderCenterCont.latitude"),
                                      rules: {
                                        required: _vm.modifyForm.latitude
                                          ? true
                                          : false,
                                        validator: _vm.latitudeValidator,
                                        trigger: ["blur", "change"],
                                      },
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "",
                                        disabled: !_vm.isModify,
                                      },
                                      model: {
                                        value: _vm.modifyForm.latitude,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.modifyForm,
                                            "latitude",
                                            $$v
                                          )
                                        },
                                        expression: "modifyForm.latitude",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "paymentCycle",
                                      label: _vm.$t(
                                        "collectionCenter.settlementCycle"
                                      ),
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "",
                                        clearable: "",
                                        disabled: !_vm.isModify,
                                      },
                                      model: {
                                        value: _vm.modifyForm.paymentCycle,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.modifyForm,
                                            "paymentCycle",
                                            $$v
                                          )
                                        },
                                        expression: "modifyForm.paymentCycle",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { staticClass: "NumClass", attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "collectRange",
                                      label: _vm.$t(
                                        "collectionCenter.smallCollectRange"
                                      ),
                                      rules: {
                                        required: _vm.collectRangeIsRules,
                                        message: _vm.$t(
                                          "collectionCenter.notNull"
                                        ),
                                        trigger: ["blur", "change"],
                                      },
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        clearable: "",
                                        placeholder: "",
                                        disabled: !_vm.isModify,
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.checkCollectRange(
                                            _vm.modifyForm.collectRange,
                                            "lanshou"
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.modifyForm.collectRange,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.modifyForm,
                                            "collectRange",
                                            $$v
                                          )
                                        },
                                        expression: "modifyForm.collectRange",
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              !_vm.isCollectRange &&
                                              _vm.modifyForm.siteType != 4,
                                            expression:
                                              "!isCollectRange && modifyForm.siteType != 4",
                                          },
                                        ],
                                        staticClass: "zipcodeClass",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("collectionCenter.format1")
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              !_vm.isCollectRange &&
                                              _vm.modifyForm.siteType != 4,
                                            expression:
                                              "!isCollectRange && modifyForm.siteType != 4",
                                          },
                                        ],
                                        staticClass: "zipcodeClass",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "collectionCenter.commaSeparationTips"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { staticClass: "NumClass", attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    key: _vm.timer,
                                    attrs: {
                                      prop: "deliveryRange",
                                      label: _vm.$t(
                                        "collectionCenter.smallDeliveryRange"
                                      ),
                                      rules: {
                                        required: _vm.deliveryRangeIsRules,
                                        message: _vm.$t(
                                          "collectionCenter.notNull"
                                        ),
                                        trigger: ["blur", "change"],
                                      },
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        clearable: "",
                                        placeholder: "",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.checkDeliveryRange(
                                            _vm.modifyForm.deliveryRange,
                                            "paisong"
                                          )
                                        },
                                        focus: function ($event) {
                                          return _vm.handOut(
                                            _vm.modifyForm.deliveryRange,
                                            "paisong"
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.modifyForm.deliveryRange,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.modifyForm,
                                            "deliveryRange",
                                            $$v
                                          )
                                        },
                                        expression: "modifyForm.deliveryRange",
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              !_vm.isDeliveryRange &&
                                              _vm.modifyForm.siteType != 4,
                                            expression:
                                              "!isDeliveryRange && modifyForm.siteType != 4",
                                          },
                                        ],
                                        staticClass: "zipcodeClass",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("collectionCenter.format1")
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              !_vm.isDeliveryRange &&
                                              _vm.modifyForm.siteType != 4,
                                            expression:
                                              "!isDeliveryRange && modifyForm.siteType != 4",
                                          },
                                        ],
                                        staticClass: "zipcodeClass",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "collectionCenter.commaSeparationTips"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { staticClass: "NumClass", attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    key: _vm.timer,
                                    attrs: {
                                      prop: "bulkyDeliverZipCode",
                                      label: _vm.$t(
                                        "collectionCenter.largeDeliveryRange"
                                      ),
                                      rules: {
                                        required: _vm.bulkyRangeIsRules,
                                        message: _vm.$t(
                                          "collectionCenter.notNull"
                                        ),
                                        trigger: ["blur", "change"],
                                      },
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        clearable: "",
                                        placeholder: "",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.checkCollectRange(
                                            _vm.modifyForm.bulkyDeliverZipCode,
                                            "largeDelivery"
                                          )
                                        },
                                        focus: function ($event) {
                                          return _vm.handOut(
                                            _vm.modifyForm.bulkyDeliverZipCode,
                                            "largeDelivery"
                                          )
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.modifyForm.bulkyDeliverZipCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.modifyForm,
                                            "bulkyDeliverZipCode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "modifyForm.bulkyDeliverZipCode",
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              !_vm.isLargeDelivery &&
                                              _vm.modifyForm.siteType != 4,
                                            expression:
                                              "!isLargeDelivery && modifyForm.siteType != 4",
                                          },
                                        ],
                                        staticClass: "zipcodeClass",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("collectionCenter.format1")
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              !_vm.isLargeDelivery &&
                                              _vm.modifyForm.siteType != 4,
                                            expression:
                                              "!isLargeDelivery && modifyForm.siteType != 4",
                                          },
                                        ],
                                        staticClass: "zipcodeClass",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "collectionCenter.commaSeparationTips"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "balance",
                                      label: _vm.$t(
                                        "collectionCenter.accountBalance"
                                      ),
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "",
                                        disabled: !_vm.isModify,
                                      },
                                      model: {
                                        value: _vm.modifyForm.balance,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.modifyForm,
                                            "balance",
                                            $$v
                                          )
                                        },
                                        expression: "modifyForm.balance",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "totalDeductions",
                                      label: _vm.$t(
                                        "collectionCenter.accumulationDeduction"
                                      ),
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "",
                                        disabled: !_vm.isModify,
                                      },
                                      model: {
                                        value: _vm.modifyForm.totalDeductions,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.modifyForm,
                                            "totalDeductions",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "modifyForm.totalDeductions",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "department",
                                      label: _vm.$t(
                                        "collectionCenter.owningTransitCenter"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "",
                                          clearable: "",
                                          filterable: "",
                                          disabled: !_vm.isModify,
                                        },
                                        on: { focus: _vm.getDepartment },
                                        model: {
                                          value: _vm.modifyForm.department,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.modifyForm,
                                              "department",
                                              $$v
                                            )
                                          },
                                          expression: "modifyForm.department",
                                        },
                                      },
                                      _vm._l(
                                        _vm.departmentList,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.name,
                                              value: item.name,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("collectionCenter.mailbox"),
                                      prop: "mailBox",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "",
                                        clearable: "",
                                        maxlength: "64",
                                        "show-word-limit": "",
                                        disabled: !_vm.isModify,
                                      },
                                      model: {
                                        value: _vm.modifyForm.mailBox,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.modifyForm,
                                            "mailBox",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "modifyForm.mailBox",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "collectionCenter.spareMailbox"
                                      ),
                                      prop: "alternateEmail",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "",
                                        clearable: "",
                                        maxlength: "64",
                                        "show-word-limit": "",
                                        disabled: !_vm.isModify,
                                        rules: {
                                          required: _vm.isAlternateEmail,
                                          message: _vm.$t(
                                            "collectionCenter.notNull"
                                          ),
                                          trigger: "blur",
                                        },
                                      },
                                      model: {
                                        value: _vm.modifyForm.alternateEmail,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.modifyForm,
                                            "alternateEmail",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "modifyForm.alternateEmail",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "collectionCenter.enterpriseTaxNumber"
                                      ),
                                      prop: "cnpjNumber",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "",
                                        clearable: "",
                                        disabled: !_vm.isModify,
                                      },
                                      model: {
                                        value: _vm.modifyForm.cnpjNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.modifyForm,
                                            "cnpjNumber",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "modifyForm.cnpjNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "fileClass",
                                    attrs: {
                                      label: "basecode：",
                                      prop: "baseCode",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "",
                                        clearable: "",
                                        maxlength: "30",
                                        "show-word-limit": "",
                                        disabled: !_vm.isModify,
                                      },
                                      model: {
                                        value: _vm.modifyForm.baseCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.modifyForm,
                                            "baseCode",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "modifyForm.baseCode",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "fileClass",
                                    attrs: {
                                      label: _vm.$t("base.openingHours") + "：",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        clearable: "",
                                        type: "textarea",
                                        "show-word-limit": "",
                                        value: _vm.getWeekStr,
                                        rows: 6,
                                      },
                                      on: {
                                        focus: function ($event) {
                                          _vm.siteWeekPop = true
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("el-col", { attrs: { span: 7 } }),
                            _c("el-col", { attrs: { span: 7 } }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.isModify
                      ? _c(
                          "div",
                          { staticClass: "submitClickBtn" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.saveClick($event)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("collectionCenter.Save")))]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "delete" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.modifyResetClick($event)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("collectionCenter.empty")))]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "delete" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.closeClick($event)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("collectionCenter.cancel"))
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "codeDialog" },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                width: "55%",
                "show-close": false,
                "close-on-click-modal": false,
                visible: _vm.openDialog,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.openDialog = $event
                },
              },
            },
            [
              _c("div", {
                staticClass: "el-icon-close closeBtn",
                on: { click: _vm.closeDialog },
              }),
              _vm.isModify
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "medium" },
                      on: { click: _vm.saveCodeClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.Save")))]
                  )
                : _vm._e(),
              _vm.isModify
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "medium" },
                      on: { click: _vm.cancelCodeClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.cancel")))]
                  )
                : _vm._e(),
              _c("br"),
              _vm.isModify
                ? _c("span", { staticStyle: { color: "#f56c6c" } }, [
                    _vm._v(
                      "1. " +
                        _vm._s(
                          _vm.$t("collectionCenter.zipCodeRangeSizePrompts")
                        ) +
                        "；2. " +
                        _vm._s(_vm.$t("collectionCenter.noDuplicationAllowed"))
                    ),
                  ])
                : _vm._e(),
              _c(
                "el-form",
                { ref: "submitRef", attrs: { model: _vm.psForm } },
                [
                  _c(
                    "el-table",
                    {
                      ref: "tableRef",
                      attrs: {
                        border: "",
                        "max-height": 600,
                        data: _vm.psForm.psTable,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "startValue",
                          align: "center",
                          label: _vm.$t("collectionCenter.startZipCode"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  [
                                    !_vm.isModify
                                      ? _c("span", [
                                          _vm._v(_vm._s(scope.row.startValue)),
                                        ])
                                      : _c("el-input", {
                                          attrs: {
                                            clearable: "",
                                            placeholder: _vm.$t(
                                              "collectionCenter.pleaseInput"
                                            ),
                                          },
                                          model: {
                                            value: scope.row.startValue,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "startValue",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.startValue",
                                          },
                                        }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "endValue",
                          align: "center",
                          label: _vm.$t("collectionCenter.endZipCode"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  [
                                    !_vm.isModify
                                      ? _c("span", [
                                          _vm._v(_vm._s(scope.row.endValue)),
                                        ])
                                      : _c("el-input", {
                                          attrs: {
                                            clearable: "",
                                            placeholder: _vm.$t(
                                              "collectionCenter.pleaseInput"
                                            ),
                                          },
                                          model: {
                                            value: scope.row.endValue,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "endValue",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.endValue",
                                          },
                                        }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm.isModify
                        ? _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: _vm.$t("collectionCenter.operation"),
                              width: "100",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-popconfirm",
                                        {
                                          attrs: {
                                            title: _vm.$t(
                                              "collectionCenter.deleteSure"
                                            ),
                                          },
                                          on: {
                                            confirm: function ($event) {
                                              return _vm.delRow(
                                                scope.row,
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                color: "#da0014",
                                                "font-size": "15px",
                                              },
                                              attrs: { slot: "reference" },
                                              slot: "reference",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "collectionCenter.Delete"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4039850470
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "addClass" },
                [
                  _vm.isModify
                    ? _c("el-button", {
                        attrs: {
                          type: "primary",
                          icon: "el-icon-plus",
                          size: "mini",
                        },
                        on: { click: _vm.addRow },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("base.openingHours"),
            visible: _vm.siteWeekPop,
            width: "800px",
            center: "",
            "close-on-press-escape": !_vm.isModify,
            "close-on-click-modal": !_vm.isModify,
            "show-close": !_vm.isModify,
          },
          on: {
            "update:visible": function ($event) {
              _vm.siteWeekPop = $event
            },
          },
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-table",
                {
                  ref: "tableRef",
                  staticStyle: { height: "500px" },
                  attrs: {
                    border: "",
                    "max-height": 500,
                    data: _vm.modifyForm.siteBusinessTimeData,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "startValue",
                      align: "center",
                      label: _vm.$t("GlobalSetObj.Date"),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              [
                                !_vm.isModify
                                  ? _c("span", [
                                      _vm._v(_vm._s(scope.row.dictDescribe)),
                                    ])
                                  : _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "",
                                          clearable: "",
                                          filterable: "",
                                        },
                                        model: {
                                          value: scope.row.sysDictId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "sysDictId",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.sysDictId",
                                        },
                                      },
                                      _vm._l(_vm.weekList, function (item) {
                                        return _c("el-option", {
                                          key: item.dictId,
                                          attrs: {
                                            label: item.dictDescribe,
                                            value: item.dictId,
                                            disabled:
                                              _vm.modifyForm.siteBusinessTimeData.some(
                                                function (item1, index) {
                                                  return (
                                                    item1.sysDictId ===
                                                      item.dictId &&
                                                    index !== scope.$index
                                                  )
                                                }
                                              ),
                                          },
                                        })
                                      }),
                                      1
                                    ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "endValue",
                      align: "center",
                      label: _vm.$t("trajectory.Time"),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              [
                                !_vm.isModify
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.businessStartTime +
                                            " ~ " +
                                            scope.row.businessEndTime
                                        )
                                      ),
                                    ])
                                  : [
                                      _c("el-time-picker", {
                                        attrs: {
                                          "value-format": "HH:mm",
                                          format: "HH:mm",
                                          placeholder: _vm.$t(
                                            "collectionCenter.StartTime"
                                          ),
                                        },
                                        on: {
                                          change: function (val) {
                                            return _vm.changeStartTime(
                                              val,
                                              scope.$index
                                            )
                                          },
                                        },
                                        model: {
                                          value: scope.row.businessStartTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "businessStartTime",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scope.row.businessStartTime",
                                        },
                                      }),
                                      _c("el-time-picker", {
                                        attrs: {
                                          "value-format": "HH:mm",
                                          format: "HH:mm",
                                          placeholder: _vm.$t(
                                            "collectionCenter.EndTime"
                                          ),
                                        },
                                        on: {
                                          change: function (val) {
                                            return _vm.changeEndTime(
                                              val,
                                              scope.$index
                                            )
                                          },
                                        },
                                        model: {
                                          value: scope.row.businessEndTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "businessEndTime",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scope.row.businessEndTime",
                                        },
                                      }),
                                    ],
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.isModify
                    ? _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: _vm.$t("collectionCenter.operation"),
                          width: "100",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-popconfirm",
                                    {
                                      attrs: {
                                        title: _vm.$t(
                                          "collectionCenter.deleteSure"
                                        ),
                                      },
                                      on: {
                                        confirm: function ($event) {
                                          return _vm.deleteWeekRow(
                                            scope.row,
                                            scope.$index
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            color: "#da0014",
                                            "font-size": "15px",
                                            cursor: "pointer",
                                          },
                                          attrs: { slot: "reference" },
                                          slot: "reference",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("collectionCenter.Delete")
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          956434185
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm.modifyForm.siteBusinessTimeData.length < 6
            ? _c(
                "div",
                { staticClass: "addClass" },
                [
                  _vm.isModify
                    ? _c("el-button", {
                        attrs: {
                          type: "primary",
                          icon: "el-icon-plus",
                          size: "mini",
                        },
                        on: { click: _vm.addWeekRow },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.isModify
            ? _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.resetBusinessHours } }, [
                    _vm._v(_vm._s(_vm.$t("collectionCenter.cancel"))),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.saveBusinessHours },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.Save")))]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }