// get brower
// export function GetCurrentBrowser() {
const ua = navigator.userAgent.toLocaleLowerCase();
let browserType = null;
if (ua.match(/msie/) != null || ua.match(/trident/) != null) {
  browserType = 'IE';
} else if (ua.match(/firefox/) != null) {
  browserType = 'firefox';
} else if (ua.match(/ucbrowser/) != null) {
  browserType = 'UC';
} else if (ua.match(/opera/) != null || ua.match(/opr/) != null) {
  browserType = 'opera';
} else if (ua.match(/bidubrowser/) != null) {
  browserType = 'baidu';
} else if (ua.match(/metasr/) != null) {
  browserType = 'sougou';
} else if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
  browserType = 'QQ';
} else if (ua.match(/maxthon/) != null) {
  browserType = 'maxthon';
} else if (ua.match(/chrome/) != null) {
  const is360 = _mime('type', 'application/vnd.chromium.remoting-viewer');
  if (is360) {
    browserType = '360';
  } else {
    browserType = 'chrome';
  }
} else if (ua.match(/safari/) != null) {
  browserType = 'Safari';
} else {
  browserType = 'others';
}
// return browserType;
// }

function _mime(option, value) {
  const mimeTypes = navigator.mimeTypes;
  for (const mt in mimeTypes) {
    if (mimeTypes[mt][option] === value) {
      return true;
    }
  }
  return false;
}

// get os
// export function GetOs() {
let osInfo;
const sUserAgent = navigator.userAgent.toLocaleLowerCase();
const isWin = (navigator.platform === 'Win32') || (navigator.platform === 'Windows');
const isMac = (navigator.platform === 'Mac68k') || (navigator.platform === 'Macppc') || (navigator.platform === 'Macintosh') || (navigator.platform === 'Macintel');
if (isMac) osInfo = 'Mac';
const isUnix = (navigator.platform === 'x11') && !isWin && !isMac;
if (isUnix) osInfo = 'Unix';
const isLinux = (String(navigator.platform).indexOf('linux') > -1);
if (isLinux) osInfo = 'Linux';
if (isWin) {
  const isWin2K = sUserAgent.indexOf('windows nt 5.0') > -1 || sUserAgent.indexOf('windows 2000') > -1;
  if (isWin2K) osInfo = 'Win2000';
  const isWinXP = sUserAgent.indexOf('windows nt 5.1') > -1 || sUserAgent.indexOf('windows xp') > -1;
  if (isWinXP) osInfo = 'WinXP';
  const isWin2003 = sUserAgent.indexOf('windows nt 5.2') > -1 || sUserAgent.indexOf('windows 2003') > -1;
  if (isWin2003) osInfo = 'Win2003';
  const isWinVista = sUserAgent.indexOf('windows nt 6.0') > -1 || sUserAgent.indexOf('windows vista') > -1;
  if (isWinVista) osInfo = 'WinVista';
  const isWin7 = sUserAgent.indexOf('windows nt 6.1') > -1 || sUserAgent.indexOf('windows 7') > -1;
  if (isWin7) osInfo = 'Win7';
  const isWin10 = sUserAgent.indexOf('windows nt 10.0') > -1 || sUserAgent.indexOf('windows 10') > -1;
  if (isWin10) osInfo = 'Win10';
}
if (sUserAgent.indexOf('android') > -1) osInfo = 'Android';
if (sUserAgent.indexOf('iphone') > -1) osInfo = 'iPhone';
if (sUserAgent.indexOf('symbianos') > -1) osInfo = 'SymbianOS';
if (sUserAgent.indexOf('windows phone') > -1) osInfo = 'Windows Phone';
if (sUserAgent.indexOf('ipad') > -1) osInfo = 'iPad';
if (sUserAgent.indexOf('ipod') > -1) osInfo = 'iPod';
// osInfo = 'others';
// }

export const computer = {
  browserInfo: browserType,
  osInfo: osInfo
};

// getIp
// export function getIpAddress() {
//   // eslint-disable-next-line no-undef
//   return returnCitySN;
// }
