var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SkeletonTable",
    { attrs: { "is-loading": _vm.isLoadingTable } },
    [
      _c(
        "o-table",
        {
          staticClass: "space-m-y-32",
          attrs: {
            data: _vm.tableData,
            "row-key": "id",
            bordered: "",
            paginated: "",
            "backend-pagination": "",
            total: _vm.tableTotal,
            "per-page": _vm.params.perPage,
            "current-page": _vm.params.currentPage,
            "pagination-simple": false,
          },
          on: {
            "update:currentPage": function ($event) {
              return _vm.$set(_vm.params, "currentPage", $event)
            },
            "update:current-page": function ($event) {
              return _vm.$set(_vm.params, "currentPage", $event)
            },
          },
        },
        [
          _c("o-table-column", {
            attrs: { label: _vm.$t("seller.sellerCode"), field: "seller_code" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [_vm._v(" " + _vm._s(props.row.seller_code) + " ")]
                },
              },
            ]),
          }),
          _c("o-table-column", {
            attrs: { label: _vm.$t("seller.sellerName"), field: "seller_name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [_vm._v(" " + _vm._s(props.row.seller_name) + " ")]
                },
              },
            ]),
          }),
          _c("o-table-column", {
            attrs: { label: _vm.$t("seller.client"), field: "customer_name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [_vm._v(" " + _vm._s(props.row.customer_name) + " ")]
                },
              },
            ]),
          }),
          _c("o-table-column", {
            attrs: { label: _vm.$t("seller.cep"), field: "seller_zipcode" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [_vm._v(" " + _vm._s(props.row.seller_zipcode) + " ")]
                },
              },
            ]),
          }),
          _c("o-table-column", {
            attrs: {
              label: _vm.$t("seller.address"),
              width: "300",
              field: "seller_address",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [_vm._v(" " + _vm._s(_vm.getAddress(props.row)) + " ")]
                },
              },
            ]),
          }),
          _c("o-table-column", {
            attrs: { label: "", width: "84" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("Actions", { attrs: { row: row } })]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }