
<script >
import { defineProps } from '@vue/composition-api';
import html2pdf from 'html2pdf.js';
import JsBarcode from 'jsbarcode';
import { default as DropdownTable } from '@/components/dropdownTable';
import { toast } from '@/utils/programaticToast';
import { getToastContentComponent } from '@/utils/getToastContentComponent';
import getMessageError from '@/utils/getMessageError';
import { uploadService } from '@/api/reverse/upload';
import { addingLoading, closeLoading } from '@/utils/handleControlLoading';
const __sfc_main = {};
__sfc_main.props = {
  row: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  function aleatorySevenDigitNumber() {
    const randomNumber = Math.floor(Math.random() * 10000000);
    const sevenDigitNumber = randomNumber.toString().padStart(7, '0');
    return sevenDigitNumber;
  }
  async function handleDownload() {
    try {
      addingLoading();
      const randomNumber = aleatorySevenDigitNumber();
      const res = await uploadService.downloadFile({
        numLote: props.row.numLote,
        barcode: parseInt(randomNumber)
      });
      const decoder = new TextDecoder();
      const buffer = Buffer.from(res.data.data);
      const bufferText = decoder.decode(buffer);
      const options = {
        filename: `romaneio-${props.row.numLote}.pdf`,
        margin: 0,
        image: {
          type: 'jpeg',
          quality: 0.98
        },
        html2canvas: {
          scale: 1
        },
        jsPDF: {
          unit: 'in',
          format: 'letter',
          orientation: 'portrait'
        }
      };
      const htmlList = bufferText.split("<div style='page-break-after: always'></div> <div style='margin-bottom: 2mm'></div>");
      const canvas = document.createElement('canvas');
      JsBarcode(canvas, randomNumber, {
        format: 'CODE128'
      });
      const barcodeDataUrl = canvas.toDataURL('image/png');
      const htmlString = htmlList.join("<div style='page-break-after: always'></div> <div style='margin-bottom: 2mm'></div>").replaceAll('<div id="barcode" class="svg-container"></div>', `<div id="barcode" class="svg-container"><img src=${barcodeDataUrl} height="60" /></div>`);
      html2pdf().set(options).from(`${htmlString}`).save();
    } catch (error) {
      console.log(error);
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Ocorreu um erro',
          describe: getMessageError(error),
          typeIcon: 'error'
        }),
        closable: true,
        duration: 4000
      });
    } finally {
      closeLoading();
    }
  }
  return {
    handleDownload
  };
};
__sfc_main.components = Object.assign({
  DropdownTable
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <DropdownTable>
      <o-dropdown-item aria-role="listitem" @click="handleDownload">
        Download
      </o-dropdown-item>
    </DropdownTable>
  </div>
</template>
