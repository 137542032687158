<script >
import { ref, computed, watch } from '@vue/composition-api';
import dayjs from 'dayjs';
import { default as SkeletonTable } from '@/components/skeletonTable';
import { default as WrapperHandleError } from '@/components/wrapperHandleError';
import { default as SectionHeader } from '@/components/sectionHeader';
import { default as FormUploadFile } from './form/formUploadFile';
import { default as Actions } from './actions';
import { useGetAllUploads } from '@/api/reverse/upload/query';
import { useModal } from '@/composable/useModal';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const filters = ref({
    numLote: ''
  });
  const queryParams = ref({
    numLote: '',
    currentPage: 1,
    perPage: 10
  });
  watch(filters.value, newFilters => {
    if (queryParams.value.currentPage > 1) {
      queryParams.value = {
        ...newFilters,
        currentPage: 1,
        perPage: 10
      };
    } else {
      queryParams.value = {
        ...queryParams.value,
        ...newFilters
      };
    }
  });
  const {
    data,
    isError,
    isLoading,
    isFetched,
    error
  } = useGetAllUploads(queryParams);
  const tableData = computed(() => {
    return data.value?.data?.lote ?? data.value;
  });
  const tableTotal = computed(() => {
    return data.value?.data?.count ?? 1;
  });
  const isLoadingTable = computed(() => {
    return isLoading.value || !isFetched.value;
  });
  const {
    isOpen: isOpenModalCreate,
    handleVisibility: handleVisibilityModalCreate
  } = useModal();
  return {
    dayjs,
    filters,
    queryParams,
    isError,
    error,
    tableData,
    tableTotal,
    isLoadingTable,
    isOpenModalCreate,
    handleVisibilityModalCreate
  };
};
__sfc_main.components = Object.assign({
  WrapperHandleError,
  SectionHeader,
  SkeletonTable,
  Actions,
  FormUploadFile
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <WrapperHandleError :error="error" :is-error="isError">
    <SectionHeader>
      <template #header>
        <h1 class="header-1">
          {{ $t('uploadFile.uploadFiles') }}
        </h1>
        <o-button variant="primary" size="md" @click="handleVisibilityModalCreate">
          {{ $t('uploadFile.uploadSheet') }}
        </o-button>
      </template>

      <template #filter>
        <o-field :label="$t('uploadFile.lotNumber')" label-for="numLoteSearch" class="flex-1">
          <o-input
            id="numLoteSearch"
            v-model="filters.numLote"
            :placeholder="$t('uploadFile.lotNumberPlaceholder')"
          />
        </o-field>
      </template>
    </SectionHeader>

    <SkeletonTable :is-loading="isLoadingTable">
      <o-table
        :data="tableData"
        :row-key="'id'"
        bordered
        class="space-m-y-32"
        paginated
        backend-pagination
        :total="tableTotal"
        :per-page="queryParams.perPage"
        :current-page.sync="queryParams.currentPage"
        :pagination-simple="false"
      >
        <o-table-column
          v-slot="props"
          field="numLote"
          :label="$t('uploadFile.lotNumber')"
          width="220"
        >
          {{ props.row.numLote }}
        </o-table-column>

        <o-table-column
          v-slot="props"
          field="importDate"
          :label="$t('uploadFile.date')"
        >
          {{ dayjs(new Date(props.row.importDate)).format('DD/MM/YYYY') }}
        </o-table-column>

        <o-table-column
          v-slot="props"
          field="qtde"
          :label="$t('uploadFile.filesQuantity')"
        >
          {{ props.row.qtde }}
        </o-table-column>

        <o-table-column label="" width="150">
          <template slot-scope="{row}">
            <Actions :row="row" />
          </template>
        </o-table-column>

        <template #empty>
          Sem resultados
        </template>
      </o-table>
    </SkeletonTable>

    <o-modal :active="isOpenModalCreate" width="560" @close="handleVisibilityModalCreate">
      <div>
        <h2 class="header-2">{{ $t('route.editionUpload') }}</h2>
        <p class="paragraph">
          {{ $t('uploadFile.uploadFileParagraph') }}
        </p>
      </div>
      <FormUploadFile :close-modal="handleVisibilityModalCreate" />
    </o-modal>

  </WrapperHandleError>
</template>

<style scoped>
.flex-1 {
  flex: 1;
}
</style>
