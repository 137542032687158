var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoading
    ? _c(
        "div",
        [
          _c("FormDriver", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.step === 0,
                expression: "step === 0",
              },
            ],
            attrs: {
              "form-data": _vm.formSteps.formStepDriver,
              "handle-next": _vm.handleNextStep,
              "handle-previous": _vm.handlePreviousStep,
              "is-last-step": _vm.isLastStep,
              "is-first-step": _vm.isFirstStep,
            },
            on: {
              "update:formData": function ($event) {
                return _vm.$set(_vm.formSteps, "formStepDriver", $event)
              },
              "update:form-data": function ($event) {
                return _vm.$set(_vm.formSteps, "formStepDriver", $event)
              },
            },
          }),
          _c("FormVehicle", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.step === 1,
                expression: "step === 1",
              },
            ],
            attrs: {
              "form-data": _vm.formSteps.formStepVehicle,
              "handle-next": _vm.handleNextStep,
              "handle-previous": _vm.handlePreviousStep,
              "is-last-step": _vm.isLastStep,
              "is-first-step": _vm.isFirstStep,
            },
            on: {
              "update:formData": function ($event) {
                return _vm.$set(_vm.formSteps, "formStepVehicle", $event)
              },
              "update:form-data": function ($event) {
                return _vm.$set(_vm.formSteps, "formStepVehicle", $event)
              },
            },
          }),
          _c("FormTrailer", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.step === 2,
                expression: "step === 2",
              },
            ],
            attrs: {
              "form-data": _vm.formSteps.formStepTrailer,
              "handle-next": _vm.handleNextStep,
              "handle-previous": _vm.handlePreviousStep,
              "is-last-step": _vm.isLastStep,
              "is-first-step": _vm.isFirstStep,
            },
            on: {
              "update:formData": function ($event) {
                return _vm.$set(_vm.formSteps, "formStepTrailer", $event)
              },
              "update:form-data": function ($event) {
                return _vm.$set(_vm.formSteps, "formStepTrailer", $event)
              },
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }