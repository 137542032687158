// @ts-check

// @ts-ignore
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import JSZip from 'jszip';
import axios from 'axios';
import { Message } from 'element-ui';
import i18n from '@/lang';

/**
 * 判断是否存在文件
 * @param {any[]} arr 数据项
 * @returns {boolean} 只要有一项存在文件就true
 */
export const isExistFile = (arr) => {
  return arr.some(item => {
    return item.emailSenderFileUrlArr.length !== 0;
  });
};

/** @typedef {import('./types').TExportFileItem} TExportFileItem */

/**
 * 获取文件url
 * @param {any[]} arr
 * @returns {TExportFileItem[]}
 */
export const getFileUrl = (arr) => {
  /** @type {TExportFileItem[]} */
  const list = [];
  arr.map(item => {
    return {
      fileName: item.payableNumber,
      urlArr: item.emailSenderFileUrlArr
    };
  }).filter(item => {
    return item.urlArr.length !== 0;
  }).forEach(item => {
    const { fileName, urlArr } = item;
    urlArr.forEach((url, index) => {
      /** TExportFileItem */
      const obj = {
        fileName: `${fileName}-${index + 1}`,
        fileUrl: url
      };
      list.push(obj);
    });
  });
  return list;
};

const EXPORT_BILL_FILE_NAME = i18n.t('basicData.导出账单文件');
const EXPORTING = i18n.t('Resource.exporting'); // 正在导出
const NOT_NULL = i18n.t('operatingPlatform.nullMsg'); // 暂无数据

/**
 * 导出文件
 * @param {TExportFileItem[]} arr 文件链接
 */
export const exportFile = (arr) => {
  if (arr.length === 0) {
    // @ts-ignore
    Message.warning({
      message: NOT_NULL
    });
    return;
  }

  // 只有一个文件则直接导出
  if (arr.length === 1) {
    const { fileName, fileUrl } = arr[0];
    saveAs(fileUrl, fileName);
    return;
  }

  // @ts-ignore
  Message(EXPORTING);

  // 2个以上的文件使用压缩包
  const zip = new JSZip();
  // 使用 Axios 库下载每个附件，并将其添加到压缩包中
  Promise.all(arr.map((item) => axios.get(item.fileUrl, { responseType: 'blob' })))
    .then((responses) => {
      responses.forEach((response, index) => {
        // 处理每一个文件name
        const filename = `${arr[index].fileName}.${arr[index].fileUrl.split('.').pop()}`;
        zip.file(filename, response.data);
      });

      // 生成压缩包并将其下载到本地
      zip.generateAsync({ type: 'blob' }).then((content) => {
        const name = EXPORT_BILL_FILE_NAME + dayjs().format('YYYYMMDDHHmmss') + '.zip';
        saveAs(content, name);
      });
    })
    .catch((error) => {
      console.error(error);
    });
};

