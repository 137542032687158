<template>
  <div>
    <el-button
      icon="el-icon-download"
      size="small"
      type="primary"
      @click="operationBtn()"
    >{{ $t('receivablePayable.DownloadTheTemplate') }}</el-button>
    <upload-excel
      :on-success="importLogistics"
      :but-name="$t('receivablePayable.BatchImportClips')"
      style="display: inline-block;margin-left: 20px;"
    />
    <!--导入提示-->
    <!-- <el-dialog
      :title="$t('basicData.tips')"
      :visible.sync="importDialogVisible"
      :append-to-body="true"
      width="30%"
    >
      <div>
        <div v-for="(item, ind) in errData" :key="ind" style="color: red;margin-bottom: 10px;">{{ item }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        确 定
        <el-button type="primary" @click="importDialogVisible = false">{{ $t('basicData.determine') }}</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import { feePage } from '@/api/finance/partition';
import uploadExcel from '@/components/UploadExcel';
import { saveAs } from 'file-saver';
const ExcelJS = require('exceljs');

export default {
  name: 'BatchImportClips',
  components: {
    uploadExcel
  },
  props: {
    providerList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    outletsList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      feeTypeList: []
      // errData: [],
      // importDialogVisible: false
    };
  },
  created() {
    this.getFeeTypeList();
  },
  methods: {
    // 获取费用类型
    getFeeTypeList() {
      const param = {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 10000,
        params: {
          state: 1
        }
      };
      feePage(param, false).then(res => {
        if (res.status === 'OK') {
          this.feeTypeList = res.data.records;
        }
      });
    },
    // 下载模板
    operationBtn() {
      const that = this;
      async function ddd() {
        const wb = new ExcelJS.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        const Sheet2 = wb.addWorksheet('Sheet2');
        const Sheet3 = wb.addWorksheet('Sheet3');
        const Sheet4 = wb.addWorksheet('Sheet4');
        // const Sheet5 = wb.addWorksheet('Sheet5');
        const Sheet6 = wb.addWorksheet('Sheet6');
        const Sheet7 = wb.addWorksheet('Sheet7');
        Sheet1.columns = [
          {
            header: '*' + that.$t('receivablePayable.TicketNumber'), // 业务单号',
            key: 'waybillNumber',
            width: 40
          },
          {
            header: '*' + that.$t('orderCenterCont.CustomerAccountNumber'), // 客户账号',
            key: 'customerName',
            width: 40
          },
          // {
          //   header: '*' + that.$t('basicData.businessPrincipal'), // 应收主体,
          //   key: 'providerId',
          //   width: 40
          // },
          {
            header: '*' + that.$t('basicData.Outlets'), // 网点',
            key: 'siteId',
            width: 40
          },
          {
            header: '*' + that.$t('collectionCenter.businessT'), // 业务类型',
            key: 'businessType',
            width: 40
          },
          {
            header: that.$t('newOrder.CollectionType'), // '揽收类型',
            key: 'collectionCategory',
            width: 40
          },
          {
            header: '*' + that.$t('receivablePayable.BillableWeight'), // 计费重量',
            key: 'chargeWeight',
            width: 40
          },
          {
            header: '*' + that.$t('basicData.ExpenseType'), // 费用类型',
            key: 'feeCode',
            width: 40
          },
          {
            header: '*' + that.$t('collectionCenter.businessT') + '(' + that.$t('receivablePayable.FeeBreakdown') + ')', // 业务类型(费用明细)',
            key: 'subBusinessType',
            width: 40
          },
          {
            header: '*' + that.$t('receivablePayable.AmountReceivable'), // 应收金额',
            key: 'fee',
            width: 40
          },
          {
            header: '*' + that.$t('basicData.BusinessTime') + '(' + that.$t('customerManagements.format') + '：2022-01-01 00:00:00)', // '业务时间(格式：2022-01-01 00:00:00)',
            key: 'operateTime',
            width: 40
          },
          {
            header: that.$t('receivablePayable.remark'), // '备注',
            key: 'remark',
            width: 40
          },
          {
            header: that.$t('receivablePayable.TheCompanyNetworkOutletsHasIncreasedSimultaneously'), // '公司对网点同步增加'
            key: 'isSynchronous',
            width: 40
          }
        ];
        Sheet1.mergeCells('A2:M2');
        // 每次仅导入5w条  // 不可更改此模板;
        Sheet1.getCell('A2').value = '1、' + that.$t('receivablePayable.Only50000PiecesImport') + '  2、' + that.$t('receivablePayable.ThisTemplateCannotBeChanged');
        Sheet1.getRow(2).getCell(1).font = {
          color: { argb: 'ff0000' }
        };

        Sheet2.columns = [
          {
            header: that.$t('basicData.ExpenseType'), // '费用类型',
            key: 'feeCode',
            width: 40
          }
        ];
        const Sheet2_data = [];
        that.feeTypeList.map(item => {
          Sheet2_data.push({
            feeCode: item.name + '-' + item.code,
            width: 20
          });
        });
        Sheet2.addRows(Sheet2_data);
        Sheet3.columns = [
          {
            header: that.$t('collectionCenter.businessT'), // '业务类型',
            key: 'businessType',
            width: 40
          }
        ];
        const Sheet3_data = [
          {
            businessType: that.$t('collectionCenter.lanshou') + '-' + 1, // 揽收
            width: 20
          },
          {
            businessType: that.$t('collectionCenter.expressDelivery') + '-' + 0, // 快递
            width: 20
          },
          {
            businessType: that.$t('collectionCenter.dispatch') + '-' + 2, // 派送
            width: 20
          }
        ];
        Sheet3.addRows(Sheet3_data);
        Sheet4.columns = [
          {
            header: that.$t('newOrder.CollectionType'), // 揽收类型',
            key: 'collectionCategory',
            width: 40
          }
        ];
        const Sheet4_data = [
          { collectionCategory: that.$t('basicData.OrdinaryPickUp') + '-' + 0 },
          { collectionCategory: that.$t('basicData.StockingAndPickup') + '-' + 1 }
        ];
        Sheet4.addRows(Sheet4_data);
        // Sheet5.columns = [
        //   {
        //     header: that.$t('basicData.businessPrincipal'), // 应收主体',
        //     key: 'providerId',
        //     width: 40
        //   }
        // ];
        // const Sheet5_data = [];
        // that.providerList.map(item => {
        //   Sheet5_data.push({
        //     providerId: item.providerName + '-' + item.id
        //   });
        // });
        // Sheet5.addRows(Sheet5_data);
        Sheet6.columns = [
          {
            header: that.$t('basicData.Outlets'), // 网点',
            key: 'siteId',
            width: 40
          }
        ];
        const Sheet6_data = [];
        that.outletsList.map(item => {
          Sheet6_data.push({
            siteId: item.name + '-' + item.id
          });
        });
        Sheet6.addRows(Sheet6_data);
        Sheet7.columns = [
          {
            header: that.$t('receivablePayable.TheCompanyNetworkOutletsHasIncreasedSimultaneously'), // '公司对网点同步增加'
            key: 'isSynchronous',
            width: 40
          }
        ];
        const Sheet7_data = [
          { isSynchronous: that.$t('GlobalSetObj.No') + '-' + 0 },
          { isSynchronous: that.$t('GlobalSetObj.yes') + '-' + 1 }
        ];
        Sheet7.addRows(Sheet7_data);
        new Array(55000).fill(0).forEach((_, idx) => {
          const row = idx + 2;
          // 渲染应收主体下拉框
          // Sheet1.getCell(row, 3).dataValidation = {
          //   type: 'list',
          //   formulae: [`=Sheet5!$A$3:$A${Sheet5_data.length + 1}`]
          // };
          // 网点
          Sheet1.getCell(row, 3).dataValidation = {
            type: 'list',
            formulae: [`=Sheet6!$A$2:$A${Sheet6_data.length + 1}`]
          };
          // 业务类型
          Sheet1.getCell(row, 4).dataValidation = {
            type: 'list',
            formulae: [`=Sheet3!$A$2:$A${Sheet3_data.length + 1}`]
          };
          // 揽收类型
          Sheet1.getCell(row, 5).dataValidation = {
            type: 'list',
            formulae: [`=Sheet4!$A$2:$A${Sheet4_data.length + 1}`]
          };
          // 费用类型
          Sheet1.getCell(row, 7).dataValidation = {
            type: 'list',
            formulae: [`=Sheet2!$A$2:$A${Sheet2_data.length + 1}`]
          };
          // 业务类型(明细费用)
          Sheet1.getCell(row, 8).dataValidation = {
            type: 'list',
            formulae: [`=Sheet3!$A$2:$A${Sheet3_data.length + 1}`]
          };
          // 业务时间
          Sheet1.getCell(row, 10).numFmt = '@';

          // 公司对网点同步增加
          Sheet1.getCell(row, 12).dataValidation = {
            type: 'list',
            formulae: [`=Sheet7!$A$2:$A${Sheet7_data.length + 1}`]
          };
        });
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        const saveName = that.$t('receivablePayable.BatchImportClips') + '(' + that.$t('receivablePayable.SiteToCustomer') + ')' + '.xlsx';
        saveAs(blob, saveName);
        return;
      }
      ddd();
    },
    // 导入时间格式化
    // formatDate(numb, format1, format2) {
    //   const old = numb - 1;
    //   if (isNaN(old) === true) {
    //     return true;
    //   }
    //   const t = Math.round((old - Math.floor(old)) * 24 * 60 * 60);
    //   const time = new Date(1900, 0, old, 0, 0, t);
    //   const year = time.getFullYear();
    //   const month = time.getMonth() + 1;
    //   const date = time.getDate();
    //   const HH = time.getHours();
    //   const MM = time.getMinutes();
    //   const ss = time.getSeconds();
    //   return year + format1 + (month < 10 ? '0' +
    //       month : month) + format1 + (date < 10 ? '0' + date : date) +
    //     ' ' + (HH < 10 ? '0' + HH : HH) + format2 +
    //     (MM < 10 ? '0' + MM : MM) + format2 + (ss < 10 ? '0' + ss : ss);
    // },
    // 导入
    importLogistics(arr) {
      const header = arr[0].header;
      const arrData = arr[0].results.splice(1);
      // const required = [
      //   '*' + this.$t('receivablePayable.TicketNumber'), // 业务单号',
      //   '*' + this.$t('orderCenterCont.CustomerAccountNumber'), // 客户账号',
      //   '*' + this.$t('basicData.businessPrincipal'), // 应收主体,
      //   '*' + this.$t('basicData.Outlets'), // 网点',
      //   '*' + this.$t('collectionCenter.businessT'), // 业务类型',
      //   '*' + this.$t('receivablePayable.BillableWeight'), // 计费重量',
      //   '*' + this.$t('basicData.ExpenseType'), // 费用类型',
      //   '*' + this.$t('collectionCenter.businessT') + '(' + this.$t('receivablePayable.FeeBreakdown') + ')', // 业务类型(费用明细)',
      //   '*' + this.$t('receivablePayable.AmountReceivable'), // 应收金额',
      //   '*' + this.$t('basicData.BusinessTime') + '(' + this.$t('customerManagements.format') + '：2022-01-01 00:00:00)' // '业务时间(格式：2022-01-01 00:00:00)',
      // ];
      const headObj = [
        'waybillNumber', // 业务单号
        'customerName', // 客户账号
        // 'providerId', // 应收主体
        'siteId', // 网点
        'businessType', // 业务类型
        'collectionCategory', // 揽收类型
        'chargeWeight', // 计费重量
        'feeCode', // 费用类型
        'subBusinessType', // 业务类型(费用明细)',
        'fee', // 应收金额
        'operateTime', // '业务时间(格式：2022-01-01 00:00:00)'
        'remark', // 备注
        'isSynchronous' // '公司对网点同步增加'
      ];
      // this.errData = [];
      const dataArrList = [];
      arrData.map((item, ind) => {
        const keyArr = [];
        const data = {};
        header.map(list => {
          data[list] = (item[list] !== '' && item[list] !== null && item[list] !== undefined) ? item[list] : '';
        });
        dataArrList.push(data);
        Object.keys(item).forEach((key) => {
          keyArr.push(key);
        });
        // required.map(list => {
        //   if (!keyArr.includes(list)) {
        //     const text = this.$t('basicData.row') + (ind + 1) + this.$t('basicData.line') + ',' + list + this.$t('basicData.CannotBeEmpty');
        //     this.errData.push(text);
        //   }
        // });
      });
      const dataList = [];
      dataArrList.map((item, ind) => {
        const data = {};
        Object.keys(item).forEach((key, i) => {
          if (
            // headObj[i] === 'providerId' ||
            headObj[i] === 'siteId' ||
            headObj[i] === 'businessType' ||
            headObj[i] === 'collectionCategory' ||
            headObj[i] === 'feeCode' ||
            headObj[i] === 'subBusinessType' ||
            headObj[i] === 'isSynchronous'
          ) {
            if (item[key]) {
              const index = item[key].lastIndexOf('-');
              const sId = item[key].slice(index + 1);
              data[headObj[i]] = sId;
            } else {
              data[headObj[i]] = '';
            }
            // data[headObj[i]] = item[key] ? item[key].split('-')[1] : '';
          } else if (headObj[i] === 'operateTime') {
            // if (this.formatDate(item[key]) === true) {
            //   const text = this.$t('basicData.row') + (ind + 1) + this.$t('basicData.line') + ',' + key + this.$t('basicData.TheFormatContentIncorrect');
            //   this.errData.push(text);
            // }
            // data[headObj[i]] = this.formatDate(item[key], '-', ':');
            data[headObj[i]] = item[key];
          } else {
            data[headObj[i]] = item[key] ? item[key] : '';
          }
        });
        dataList.push(data);
      });
      // if (this.errData.length > 0) {
      //   this.importDialogVisible = true;
      // } else {
      //   this.$emit('importClips', dataList);
      // }

      this.$emit('importClips', dataList);
    }
  }
};
</script>

<style scoped>

</style>
