<script >
import { ref, defineProps } from '@vue/composition-api';
import { default as AvailableSellers } from '../../../router/components/form/step/availableSellers';
import { default as FormDriver } from './steps/formDriver';
import { default as FormVehicle } from './steps/formVehicle';
import { default as FormTrailer } from './steps/formTrailer';
import { default as FormTransportSupply } from './steps/formTransportSupply';
import { useCreateOrder } from '@/api/transport/order/query';
import { useWizardForm } from '@/composable/useWizardForm';
import { routerService } from '@/api/transport/router';
import { equipmentService } from '@/api/transport/equipment';
import getMessageError from '@/utils/getMessageError';
import { orderService } from '@/api/transport/order';
import { useQueryClient } from '@tanstack/vue-query';
import { toast } from '@/utils/programaticToast';
import { getToastContentComponent } from '@/utils/getToastContentComponent';
import { addingLoading, closeLoading } from '@/utils/handleControlLoading';
const __sfc_main = {};
__sfc_main.props = {
  closeModal: {
    type: Function
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const STEPS_TOTAL = 5;
  const formData = ref({
    formStep01: {
      sellers: []
    },
    formStep02: {
      document: '',
      username: '',
      name: '',
      helpers: []
    },
    formStep03: {
      plate: ''
    },
    formStep04: {
      trailerPlate: ''
    },
    formStep05: {
      selectedColors: [],
      quantityBags: null,
      sealCode: {
        azul: [],
        amarelo: [],
        branco: []
      }
    }
  });
  function onUpdateSellers(newSellers) {
    formData.value.formStep01.sellers = newSellers;
  }
  const queryClient = useQueryClient();
  async function handleSubmit() {
    const {
      formStep01,
      formStep02,
      formStep03,
      formStep04,
      formStep05
    } = formData.value;
    try {
      addingLoading();
      const resRoute = await routerService.create(formStep01.sellers);
      const listworld = resRoute.data['Seller Route Created'].split(' ');
      const routeId = listworld[listworld.length - 1];
      await equipmentService.create({
        ...formStep02,
        ...formStep03,
        ...formStep04,
        routeId: parseInt(routeId)
      });
      if (formStep05.selectedColors.length) {
        await orderService.updateTransportSupply({
          listSeals: formStep05.selectedColors,
          quantityBags: formStep05.quantityBags,
          driverDocument: formStep02.document,
          sealCode: formStep05.sealCode
        });
      }
      await orderService.create(routeId);
      toast.open({
        variant: 'success',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Criado com sucesso',
          describe: 'Ordem foi criada com sucesso em nosso sistema',
          typeIcon: 'success'
        }),
        closable: true,
        duration: 4000
      });
      queryClient.invalidateQueries({
        queryKey: ['orders']
      });
      props.closeModal();
    } catch (error) {
      console.log(error);
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Ocorreu um erro',
          describe: getMessageError(error),
          typeIcon: 'error'
        }),
        closable: true,
        duration: 4000
      });
      return;
    } finally {
      closeLoading();
    }
  }
  const {
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  } = useWizardForm(formData, handleSubmit, STEPS_TOTAL);
  return {
    formData,
    onUpdateSellers,
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  };
};
__sfc_main.components = Object.assign({
  AvailableSellers,
  FormDriver,
  FormVehicle,
  FormTrailer,
  FormTransportSupply
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <AvailableSellers
      v-show="step === 0"
      :sellers="formData.formStep01.sellers"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      @updateSeller="onUpdateSellers"
    />

    <FormDriver
      v-show="step === 1"
      :form-data.sync="formData.formStep02"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
    />

    <FormVehicle
      v-show="step === 2"
      :form-data.sync="formData.formStep03"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
    />

    <FormTrailer
      v-show="step === 3"
      :form-data.sync="formData.formStep04"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
    />

    <FormTransportSupply
      v-show="step === 4"
      :form-data.sync="formData.formStep05"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
    />
  </div>
</template>
