<script >
import { ref, defineProps } from '@vue/composition-api';
import dayjs from 'dayjs';
import { default as FormPersonalData } from './steps/formPersonalData';
import { default as FormAddress } from '@/components/steps/formAddress';
import { useWizardForm } from '@/composable/useWizardForm';
import { useEditDriver } from '@/api/transport/driver/query';
import { toast } from '@/utils/programaticToast';
import { getToastContentComponent } from '@/utils/getToastContentComponent';
const __sfc_main = {};
__sfc_main.props = {
  closeModal: {
    type: Function
  },
  driver: {
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const formData = ref({
    formStep01: {
      name: props.driver.driver_name,
      document: props.driver.driver_document,
      phone: props.driver.driver_phone_number,
      driverLicense: props.driver.driver_license,
      scoreLicense: props.driver.driver_license_score,
      scoreDriver: props.driver.driver_score,
      licenseType: props.driver.driver_license_category,
      licenseValidate: dayjs(props.driver.driver_license_validate.replace('Z', '')).format('YYYY-MM-DD'),
      providerName: props.driver.provider_name,
      username: props.driver.driver_username,
      outsourced: !!props.driver.driver_outsourced
    },
    formStep02: {
      zipcode: props.driver.driver_zipcode,
      street: props.driver.driver_address.split(',')[0],
      number: props.driver.driver_address_number,
      neighborhood: props.driver.driver_address_neighborhood,
      municipality: props.driver.driver_address_municipality,
      state: props.driver.driver_state,
      complement: props.driver.driver_address_complement
    }
  });
  const {
    mutate,
    isLoading
  } = useEditDriver();
  const handleSubmit = async () => {
    const newDriver = {
      ...formData.value.formStep01,
      ...formData.value.formStep02
    };
    mutate({
      newDriver,
      id: props.driver.id
    }, {
      onSuccess: () => {
        props.closeModal();
        toast.open({
          variant: 'success',
          position: 'bottom',
          component: getToastContentComponent({
            title: 'Editado com sucesso',
            describe: 'As informações foram alteradas com sucesso',
            typeIcon: 'success'
          }),
          closable: true,
          duration: 4000
        });
      }
    });
  };
  const {
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  } = useWizardForm(formData, handleSubmit, 2);
  return {
    formData,
    isLoading,
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  };
};
__sfc_main.components = Object.assign({
  FormPersonalData,
  FormAddress
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <FormPersonalData
      v-show="step === 0"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      :form-data.sync="formData.formStep01"
      :is-edit="true"
    />

    <FormAddress
      v-show="step === 1"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      :form-data.sync="formData.formStep02"
      :is-edit="true"
    />

    <o-loading :full-page="true" :active.sync="isLoading" />
  </div>
</template>
