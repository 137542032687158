<template>
  <div class="container">
    <div class="headeContainer">
      <div class="searchWrap">
        <el-form
          ref="queryForm"
          :label-position="$i18n.locale!='zh'?'top':'right'"
          :model="queryForm"
          inline
          label-width="150px"
          style="width: 100%;"
        >
          <el-form-item :label="$t('ChannelManage.ServiceProviderName')">
            <el-select
              v-model="queryForm.params.name"
              :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
              :remote-method="remoteMethod"
              clearable
              filterable
              remote
              style="width: 380px"
            >
              <el-option
                v-for="item in systemNameList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('ChannelManage.ServiceProviderChannelName')">
            <el-input
              v-model.trim="queryForm.params.providerChannelName"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              clearable
              style="width: 380px"
              @keyup.enter.native="getSearch"
            />
          </el-form-item>
          <el-form-item :label="$t('ChannelManage.channelCode')">
            <el-input
              v-model.trim="queryForm.params.providerChannelCode"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              clearable
              style="width: 350px"
              @keyup.enter.native="getSearch"
            />
          </el-form-item>
        </el-form>
      </div>
      <div class="btnList">
        <!--搜索-->
        <el-button
          icon="el-icon-search" size="small" type="primary"
          @click="getSearch"
        >{{
          $t('newOrder.search')
        }}
        </el-button>
        <!--重置-->
        <el-button
          icon="el-icon-refresh-left" plain size="small"
          @click="resetForm"
        >{{
          $t('GlobalSetObj.reset')
        }}
        </el-button>
      </div>
      <el-divider />
    </div>
    <!-- 新增 -->
    <el-button
      icon="el-icon-plus" size="small" style="margin-bottom: 15px"
      type="primary" @click="addDrawer"
    >
      {{ $t('GlobalSetObj.add') }}
    </el-button>
    <el-card>
      <!--  表格数据  -->
      <AnjunTableList
        ref="anjunTable"
        :format-time="isTormat"
        :has-operation="true"
        :header-list="tableHeaderList"
        :is-index="true"
        :is-tip="true"
        :op-width="180"
        :table-data="tableData"
        @on-look="toLook"
        @to-SystemName="toChannelName"
        @on-stop="UpdateState"
      />
      <!--  分页  -->
      <el-pagination
        :current-page="queryForm.number"
        :page-size="queryForm.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="queryForm.total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 10px 0;float: right"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <!--添加系统服务商-->
    <el-drawer
      ref="drawer"
      :append-to-body="true"
      :title="$t('ChannelManage.NewProviderChannels')"
      :visible.sync="drawer"
      custom-class="demo-drawer"
      direction="ltr"
      size="50%"
    >
      <div class="demo-drawer__content" style="padding-right: 30px;padding-left: 30px">
        <el-form
          ref="addForm"
          :label-position="$i18n.locale!='zh'?'top':'right'"
          :model="ruleForm"
          :rules="rules"
          class="demo-ruleForm"
          label-width="150px"
        >
          <el-form-item :label="$t('ChannelManage.ServiceProviderChannelName')" prop="providerChannelName">
            <el-input v-model="ruleForm.providerChannelName" />
          </el-form-item>
          <el-form-item :label="$t('ChannelManage.channelCode')" prop="providerChannelCode">
            <el-input v-model="ruleForm.providerChannelCode" />
          </el-form-item>
          <el-form-item prop="takeNumber">
            <template slot="label">
              <el-tooltip class="QuestionMark" placement="top">
                <div slot="content">{{ $t('ChannelManage.takeNumberTip') }}</div>
                <svg-icon icon-class="QuestionMark" />
              </el-tooltip>
              <span>{{ $t('ChannelManage.TakeNumberOrNot') }}</span>
            </template>
            <el-radio-group v-model="ruleForm.takeNumber" size="mini">
              <el-radio-button label="1">{{ $t('GlobalSetObj.yes') }}</el-radio-button>
              <el-radio-button label="0">{{ $t('GlobalSetObj.No') }}</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="whetherOrder">
            <template slot="label">
              <el-tooltip class="QuestionMark" placement="top">
                <div slot="content">{{ $t('ChannelManage.whetherOrderTip') }}</div>
                <svg-icon icon-class="QuestionMark" />
              </el-tooltip>
              <span>{{ $t('ChannelManage.changeOrderOrNot') }}</span>
            </template>
            <el-radio-group v-model="ruleForm.whetherOrder" size="mini">
              <el-radio-button label="1">{{ $t('GlobalSetObj.yes') }}</el-radio-button>
              <el-radio-button label="0">{{ $t('GlobalSetObj.No') }}</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="packageMaxWeight">
            <template slot="label">
              <el-tooltip class="QuestionMark" placement="top">
                <div slot="content">{{ $t('ChannelManage.ChannelWeight') }}</div>
                <svg-icon icon-class="QuestionMark" />
              </el-tooltip>
              <span>{{ $t('ChannelManage.maximumParcelWeight') }}</span>
            </template>
            <el-input
              v-model="ruleForm.packageMaxWeight"
              :placeholder="$t('placeOrder.input500')"
              type="number"
            />
          </el-form-item>
          <el-form-item :label="$t('ChannelManage.AssociatedServiceProvider')" prop="systemName">
            <el-select
              v-model="ruleForm.systemName"
              :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
              :remote-method="remoteMethod"
              clearable
              filterable
              remote
              @change="selectSystemName"
            >
              <el-option
                v-for="item in systemNameList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
        <div v-if="variableList.length > 0" class="createdForm">
          <form-create
            :key="newKey"
            v-model="fApi"
            :option="option"
            :rule="variableList"
          />
        </div>
      </div>

      <div class="demo-drawer__footer">
        <el-button style="margin-right: 20px" @click="cancelForm">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button type="primary" @click="saveProviderChannel">{{ $t('GlobalSetObj.Salve') }}</el-button>
      </div>
    </el-drawer>

    <!--  查看详情  -->
    <el-drawer
      ref="drawer"
      :append-to-body="true"
      :title="drawerInfoName"
      :visible.sync="drawerInfo"
      custom-class="demo-drawer"
      direction="ltr"
      size="50%"
    >
      <el-tabs type="border-card">
        <el-tab-pane :label="$t('ChannelManage.ServiceChannelDetails')" class="elTabs">
          <div class="systemInfo">
            <el-form :label-position="$i18n.locale!='zh'?'top':'right'" label-width="150px">
              <el-form-item :label="$t('ChannelManage.ServiceProviderChannelName')">
                <div>{{ infoForm.providerChannelName }}</div>
              </el-form-item>
              <el-form-item :label="$t('ChannelManage.channelCode')">
                <div>{{ infoForm.providerChannelCode }}</div>
              </el-form-item>
              <el-form-item>
                <template slot="label">
                  <el-tooltip class="QuestionMark" placement="top">
                    <div slot="content">{{ $t('ChannelManage.takeNumberTip') }}</div>
                    <svg-icon icon-class="QuestionMark" />
                  </el-tooltip>
                  <span>{{ $t('ChannelManage.TakeNumberOrNot') }}</span>
                </template>
                <el-radio-group v-model="updateRuleForm.takeNumber" size="small">
                  <el-radio-button :disabled="infoForm.takeNumber == 0" label="1">{{
                    $t('GlobalSetObj.yes')
                  }}
                  </el-radio-button>
                  <el-radio-button :disabled="infoForm.takeNumber == 1" label="0">{{
                    $t('GlobalSetObj.No')
                  }}
                  </el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item>
                <template slot="label">
                  <el-tooltip class="QuestionMark" placement="top">
                    <div slot="content">{{ $t('ChannelManage.whetherOrderTip') }}</div>
                    <svg-icon icon-class="QuestionMark" />
                  </el-tooltip>
                  <span>{{ $t('ChannelManage.changeOrderOrNot') }}</span>
                </template>
                <el-radio-group v-model="infoForm.whetherOrder" size="small">
                  <el-radio-button :disabled="infoForm.whetherOrder == 0" label="1">{{
                    $t('GlobalSetObj.yes')
                  }}
                  </el-radio-button>
                  <el-radio-button :disabled="infoForm.whetherOrder == 1" label="0">{{
                    $t('GlobalSetObj.No')
                  }}
                  </el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item>
                <template slot="label">
                  <el-tooltip class="QuestionMark" placement="top">
                    <div slot="content">{{ $t('ChannelManage.ChannelWeight') }}</div>
                    <svg-icon icon-class="QuestionMark" />
                  </el-tooltip>
                  <span>{{ $t('ChannelManage.maximumParcelWeight') }}</span>
                </template>
                <div>{{ infoForm.packageMaxWeight }}</div>
              </el-form-item>
              <el-form-item :label="$t('ChannelManage.AssociatedServiceProvider')">
                <div>{{ infoForm.systemName }}</div>
              </el-form-item>
            </el-form>
            <div class="createdForm">
              <form-create
                :key="newKey"
                v-model="fApi"
                :option="option"
                :rule="infovariableList"
              />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('ChannelManage.fixServiceChannel')" class="elTabs1">
          <div class="updateInfo">
            <el-form
              ref="editRuleForm"
              :label-position="$i18n.locale!='zh'?'top':'right'"
              :model="updateRuleForm"
              :rules="updateRules"
              class="demo-ruleForm"
              label-width="180px"
            >
              <el-form-item :label="$t('ChannelManage.ServiceProviderChannelName')" prop="providerChannelName">
                <el-input v-model="updateRuleForm.providerChannelName" />
              </el-form-item>
              <el-form-item :label="$t('ChannelManage.channelCode')" prop="providerChannelCode">
                <el-input v-model="updateRuleForm.providerChannelCode" />
              </el-form-item>
              <el-form-item prop="takeNumber">
                <template slot="label">
                  <el-tooltip class="QuestionMark" placement="top">
                    <div slot="content">{{ $t('ChannelManage.takeNumberTip') }}</div>
                    <svg-icon icon-class="QuestionMark" />
                  </el-tooltip>
                  <span>{{ $t('ChannelManage.TakeNumberOrNot') }}</span>
                </template>
                <el-radio-group v-model="updateRuleForm.takeNumber" size="mini">
                  <el-radio-button label="1">{{ $t('GlobalSetObj.yes') }}</el-radio-button>
                  <el-radio-button label="0">{{ $t('GlobalSetObj.No') }}</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item prop="whetherOrder">
                <template slot="label">
                  <el-tooltip class="QuestionMark" placement="top">
                    <div slot="content">{{ $t('ChannelManage.whetherOrderTip') }}</div>
                    <svg-icon icon-class="QuestionMark" />
                  </el-tooltip>
                  <span>{{ $t('ChannelManage.changeOrderOrNot') }}</span>
                </template>
                <el-radio-group v-model="updateRuleForm.whetherOrder" size="mini">
                  <el-radio-button label="1">{{ $t('GlobalSetObj.yes') }}</el-radio-button>
                  <el-radio-button label="0">{{ $t('GlobalSetObj.No') }}</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item prop="packageMaxWeight">
                <template slot="label">
                  <el-tooltip class="QuestionMark" placement="top">
                    <div slot="content">{{ $t('ChannelManage.ChannelWeight') }}</div>
                    <svg-icon icon-class="QuestionMark" />
                  </el-tooltip>
                  <span>{{ $t('ChannelManage.maximumParcelWeight') }}</span>
                </template>
                <el-input
                  v-model="updateRuleForm.packageMaxWeight"
                  :placeholder="$t('placeOrder.input500')"
                  type="number"
                />
              </el-form-item>
              <el-form-item :label="$t('ChannelManage.AssociatedServiceProvider')" prop="systemName">
                <el-select
                  v-model="updateRuleForm.systemName"
                  :placeholder="$t('operationCenter.Only10AreDisplayed')"
                  :remote-method="remoteMethod"
                  clearable
                  filterable
                  remote
                  @change="updateSelectSystemName"
                >
                  <el-option
                    v-for="item in systemNameList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-form>
            <div v-if="updateVariableList.length !== 0" class="createdForm">
              <form-create
                :key="newKey"
                v-model="editApi"
                :option="option"
                :rule="updateVariableList"
              />
            </div>
          </div>
          <div class="demo_footer">
            <el-button style="margin-right: 20px" @click="cancelForm">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
            <el-button type="primary" @click="updateSystem">{{ $t('GlobalSetObj.Salve') }}</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-drawer>
  </div>
</template>

<script>
import AnjunTableList from '@/components/anjun-tableList';
import {
  addProviderChannel,
  getProviderChannel,
  providerChannelPage,
  updateproviderChannel,
  updateState
} from '@/api/channelManage';
import { getUserName } from '@/utils/asyncTools';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { checkFloatWeight } from '@/utils/validate';

export default {
  name: 'ServiceProvider',
  components: {
    AnjunTableList
  },
  data() {
    return {
      newKey: new Date().getTime(),
      variableList: [],
      fApi: {},
      editApi: {},
      option: {
        // 显示重置表单按扭
        submitBtn: false,
        resetBtn: false
      },
      // 是否收起
      isDropQuery: true,
      // 是否格式化成巴西时间
      isTormat: false,
      queryForm: {
        pageSize: 10,
        number: 1,
        total: 0,
        params: {
          name: '',
          providerChannelCode: '',
          providerChannelName: ''
        }
      },
      tableHeaderList: [
        { id: 1, label: this.$t('ChannelManage.ServiceProviderChannelName'), prop: 'providerChannelName', cloWidth: 250 },
        { id: 2, label: this.$t('ChannelManage.channelStatus'), prop: 'stateName', cloWidth: 130 },
        { id: 3, label: this.$t('ChannelManage.AssociatedServiceProvider'), prop: 'systemName', cloWidth: 250 },
        { id: 7, label: this.$t('ChannelManage.channelCode'), prop: 'providerChannelCode', cloWidth: 250 },
        { id: 6, label: this.$t('GlobalSetObj.Updater'), prop: 'updateUser', cloWidth: 200 },
        { id: 4, label: this.$t('GlobalSetObj.UpdateTime'), prop: 'updateTime', cloWidth: 160 },
        { id: 5, label: this.$t('GlobalSetObj.CreatePeople'), prop: 'userName', cloWidth: 200 }
      ],
      tableData: [],
      // 添加弹窗
      drawer: false,
      ruleForm: {
        systemName: '',
        whetherOrder: '0',
        takeNumber: '0',
        providerChannelName: '',
        providerChannelCode: '',
        packageMaxWeight: null // 包裹最大重量
      },
      rules: {
        providerChannelCode: [
          { required: true, message: this.$t('ChannelManage.ChannelCode'), trigger: ['blur', 'change'] }
        ],
        providerChannelName: [
          {
            required: true,
            message: this.$t('ChannelManage.inputServiceProviderChannelName'),
            trigger: ['blur', 'change']
          }
        ],
        systemName: [
          { required: true, message: this.$t('ChannelManage.Associatedprovider'), trigger: ['blur', 'change'] }
        ],
        packageMaxWeight: [
          { required: false },
          { validator: checkFloatWeight, trigger: ['blur', 'change'] }
        ]
      },
      drawerInfo: false,
      systemNameList: [],
      detailInfoId: '',
      updateRuleForm: {
        systemName: '',
        whetherOrder: '0',
        takeNumber: '0',
        providerChannelName: '',
        providerChannelCode: '',
        packageMaxWeight: null // 包裹最大重量
      },
      infoForm: {
        systemName: '',
        whetherOrder: '0',
        takeNumber: '0',
        providerChannelName: '',
        providerChannelCode: '',
        packageMaxWeight: null // 包裹最大重量
      },
      updateRules: {
        providerChannelCode: [
          { required: true, message: this.$t('ChannelManage.ChannelCode'), trigger: ['blur', 'change'] }
        ],
        providerChannelName: [
          {
            required: true,
            message: this.$t('ChannelManage.inputServiceProviderChannelName'),
            trigger: ['blur', 'change']
          }
        ],
        systemName: [
          { required: true, message: this.$t('ChannelManage.Associatedprovider'), trigger: ['blur', 'change'] }
        ],
        packageMaxWeight: [
          { required: false },
          { validator: checkFloatWeight, trigger: ['blur', 'change'] }
        ]
      },
      updateVariableList: [],
      infovariableList: [],
      drawerInfoName: '',
      userIdAndNameObj: null
    };
  },
  watch: {
    drawer: {
      handler: function(val) {
        this.$refs['addForm']?.resetFields();
        this.variableList = [];
      },
      immediate: true
    },
    drawerInfo: {
      handler: function(val) {
        this.$refs['editRuleForm']?.resetFields();
      },
      immediate: true
    }
  },
  created() {
    this.userIdAndNameObj = getUserIdAndNameObj();
    this.getSearch();
    this.getALL();
  },
  methods: {
    // 查询系统服务商 付款主体
    getALL() {
      getProviderChannel('').then(res => {
        if (res.status === 'OK') {
          this.systemNameList = res.data;
        }
      });
    },
    // 是否收起查询
    dropQuery() {
      this.isDropQuery = !this.isDropQuery;
    },
    // 重置
    resetForm() {
      this.queryForm.number = 1;
      this.queryForm.params.name = '';
      this.queryForm.params.providerChannelCode = '';
      this.queryForm.params.providerChannelName = '';
      this.$refs['queryForm']?.resetFields();
      this.getSearch();
    },
    // 搜索
    getSearch() {
      const data = {};
      data.pageSize = this.queryForm.pageSize;
      if (this.queryForm.params.name || this.queryForm.params.providerChannelCode || this.queryForm.params.providerChannelName) {
        this.queryForm.number = 1;
      }
      data.pageNumber = this.queryForm.number;
      data.params = this.queryForm.params;
      console.log(data);
      providerChannelPage(data).then(res => {
        if (res.status === 'OK') {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
          this.tableData.map(item => {
            item['look'] = true;
            item.isState = item.state;
            // item.updateUser ? getUserName(item.updateUser, this.userIdAndNameObj) : '- -';
            if (item.updateUser) {
              item.updateUser = getUserName(item.updateUser, this.userIdAndNameObj);
            }
          });
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.number = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.number = val;
      this.getSearch();
    },
    // 查看
    toLook(row) {
      console.log(row, 'ORW');
      console.log(this.systemNameList, '系统服务商: []');
      this.drawerInfo = true;
      this.getALL();
      this.drawerInfoName = row.providerChannelName;
      this.$nextTick(_ => {
        this.infoForm.providerChannelName = row.providerChannelName;
        this.infoForm.providerChannelCode = row.providerChannelCode;
        this.infoForm.takeNumber = row.takeNumber;
        this.infoForm.whetherOrder = row.whetherOrder;
        this.infoForm.systemName = row.systemName;
        this.infoForm.packageMaxWeight = row.packageMaxWeight;
        try {
          if (row.systemVariableList.length < 20) {
            this.infovariableList = [];
            this.updateVariableList = [];
          } else {
            if (row.systemVariableList) {
              this.infovariableList = JSON.parse(row.systemVariableList);
              this.updateVariableList = JSON.parse(row.systemVariableList);
            } else {
              this.infovariableList = [];
              this.updateVariableList = [];
            }
          }
        } catch (e) {
          this.infovariableList = [];
          this.updateVariableList = [];
        }
        this.updateRuleForm.providerChannelName = row.providerChannelName;
        this.updateRuleForm.providerChannelCode = row.providerChannelCode;
        this.updateRuleForm.takeNumber = row.takeNumber;
        this.updateRuleForm.whetherOrder = row.whetherOrder;
        this.updateRuleForm.systemName = row.systemName;
        this.updateRuleForm.systemId = row.systemId;
        this.updateRuleForm.packageMaxWeight = row.packageMaxWeight;
        this.detailInfoId = row.id;
      });
    },
    // 点开详情
    toChannelName(row) {
      console.log(row, 'ORW');
      console.log(this.systemNameList, '系统服务商: []');
      this.toLook(row);
    },
    // 保存修改
    updateSystem() {
      this.$refs['editRuleForm'].validate((valid) => {
        if (valid) {
          const data = {};
          data.providerChannelCode = this.updateRuleForm.providerChannelCode;
          data.providerChannelName = this.updateRuleForm.providerChannelName;
          data.takeNumber = this.updateRuleForm.takeNumber;
          data.whetherOrder = this.updateRuleForm.whetherOrder;
          data.packageMaxWeight = this.updateRuleForm.packageMaxWeight;
          data.id = this.detailInfoId;
          data.systemVariableList = JSON.stringify(this.updateVariableList);
          console.log(data.systemVariableList.length, 'data.systemVariableList');
          if (data.systemVariableList.length < 20) {
            data.systemVariableList = '[]';
          }
          const objSystemName = this.systemNameList.find((item) => {
            return item.id == this.updateRuleForm.systemId || item.id == this.updateRuleForm.systemName;
          });
          if (objSystemName) {
            data.systemName = objSystemName.name;
            data.systemId = objSystemName.id;
          } else {
            data.systemName = this.updateRuleForm.systemName;
            data.systemId = this.updateRuleForm.systemId;
          }
          console.log(objSystemName, 'objSystemName');
          console.log(data, '修改渠道datadatadata');
          // return;
          // return;
          updateproviderChannel(data).then(res => {
            if (res.status === 'OK') {
              this.$message.success(this.$t('GlobalSetObj.ModificationSuccessful'));
              this.drawerInfo = false;
              this.getSearch();
            } else {
              this.drawerInfo = false;
            }
          });
        }
      });
    },
    // 点击新增, 弹出窗口
    addDrawer() {
      this.drawer = true;
      this.getALL();
    },
    // 关联系统服务商
    remoteMethod(query) {
      console.log(query, 'LSKDJFLKS');
      if (query !== '') {
        getProviderChannel(query).then(res => {
          if (res.status === 'OK') {
            this.systemNameList = res.data;
          }
        });
      } else {
        getProviderChannel(query).then(res => {
          if (res.status === 'OK') {
            this.systemNameList = res.data;
          }
        });
      }
    },
    // 选择系统名称
    selectSystemName(val) {
      this.variableList = [];
      const obj = this.systemNameList.find((item) => {
        return val === item.id;
      });
      try {
        if (obj.variableList.length < 20) {
          this.variableList = [];
        } else {
          this.variableList = JSON.parse(obj.variableList);
        }
      } catch (e) {
        this.variableList = [];
      }
    },
    // 选择系统名称
    updateSelectSystemName(val) {
      console.log(this.systemNameList, 'this.systemNameList');
      console.log(val, 'updateRuleForm.systemName');
      this.updateRuleForm.systemId = val;
      this.updateVariableList = [];
      const obj = this.systemNameList.find((item) => {
        return val === item.id;
      });
      try {
        if (obj.variableList.length < 20) {
          this.updateVariableList = [];
        } else {
          this.updateVariableList = JSON.parse(obj.variableList);
        }
      } catch (e) {
        this.updateVariableList = [];
      }
    },
    cancelForm() {
      this.drawer = false;
      this.drawerInfo = false;
    },
    saveProviderChannel() {
      this.$refs['addForm'].validate((valid) => {
        if (valid) {
          if (this.ruleForm.packageMaxWeight < 0) {
            this.$message.error(this.$t('ChannelManage.greaterThan0'));
            return;
          }
          const objSystemName = this.systemNameList.find((item) => {
            return item.id === this.ruleForm.systemName;
          });
          const data = {};
          data.providerChannelCode = this.ruleForm.providerChannelCode;
          data.providerChannelName = this.ruleForm.providerChannelName;
          data.takeNumber = this.ruleForm.takeNumber;
          data.whetherOrder = this.ruleForm.whetherOrder;
          data.packageMaxWeight = this.ruleForm.packageMaxWeight;
          data.systemName = objSystemName.name;
          data.systemId = objSystemName.id;
          // let obj = {};
          // try {
          //   this.fApi.submit((formData, fAPI) => {
          //     obj = formData;
          //   });
          // } catch (e) {
          //   obj = {};
          // }
          // data.variableList = JSON.stringify(obj);
          // data.variableList = obj;
          data.systemVariableList = JSON.stringify(this.variableList);
          if (data.systemVariableList.length < 20) {
            data.systemVariableList = '[]';
          }
          addProviderChannel(data).then(res => {
            if (res.status === 'OK') {
              this.$message.success(this.$t('GlobalSetObj.SaveSuccessfully'));
              this.drawer = false;
              this.queryForm.number = 1;
              this.getSearch();
            } else {
              this.drawer = false;
            }
          });
        }
      });
    },
    // 是否停用
    UpdateState(id, status) {
      const data = {
        id: id,
        state: status
      };
      updateState(data).then((res) => {
        if (res.status === 'OK') {
          this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
          this.getSearch();
        }
      });
    }
  }
};
</script>

<style scoped>
.container {
  padding: 15px;
}

.searchWrap {
  width: 100%;
  /*display: flex;*/
  /*justify-content: space-between;*/
  margin-top: 20px;
}

.demo-drawer {
  position: relative;
}

.demo-drawer__footer {
  width: 100%;
  border-top: 1px solid #cccccc;
  position: absolute;
  display: flex;
  justify-content: right;
  padding: 10px;
  right: 0;
  bottom: 0;
  background-color: white;
}

.demo-drawer__content {
  height: calc(100vh - 100px);
  overflow: auto;
}

.container /deep/ .el-drawer__header {
  margin-bottom: 15px;
}

.container /deep/ .el-tabs__content {
  height: 100%;
  padding-right: 20px;
}

.elTabs {
  position: relative;
  height: calc(100vh - 100px);
  overflow: auto;
}

.elTabs1 {
  /*height: 100%;*/
}

.updateInfo {
  overflow: auto;
  height: calc(100vh - 170px);
}

.demo_footer {
  float: right;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type='number'] {
  -moz-appearance: textfield !important;
}

.QuestionMark {
  cursor: pointer;
  margin-bottom: 1px;
  margin-right: 5px;
}
</style>
