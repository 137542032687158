var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      staticStyle: { width: "100%" },
      attrs: {
        filterable: "",
        clearable: "",
        remote: "",
        "remote-method": _vm.getExpenseType,
        placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
        loading: _vm.loading,
      },
      on: {
        change: function ($event) {
          return _vm.$emit("change", _vm.value)
        },
      },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    _vm._l(_vm.list, function (item) {
      return _c("el-option", {
        key: item.id,
        attrs: { label: item.label, value: item.value },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }