var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("ClaimsManagement.DealWithCustomers"),
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.row.customerName))])]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("ClaimsManagement.ClaimNumber") },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.row.claimsNumber))])]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("ClaimsManagement.ClaimAmount") },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.row.claimAmount))])]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("receivablePayable.PaymentTime"),
                        prop: "payedTime",
                      },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          placeholder: _vm.$t("newOrder.SelectDate"),
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        model: {
                          value: _vm.ruleForm.payedTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "payedTime", $$v)
                          },
                          expression: "ruleForm.payedTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("basicData.PaymentMethod"),
                        prop: "payedType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                          },
                          model: {
                            value: _vm.ruleForm.payedType,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "payedType", $$v)
                            },
                            expression: "ruleForm.payedType",
                          },
                        },
                        _vm._l(_vm.PAYTYPE_LIST, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.name, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("ClaimsManagement.PaymentAccountNumber"),
                        prop: "payAccount",
                      },
                    },
                    [
                      _c("PaymentAccountNumberCmp", {
                        model: {
                          value: _vm.ruleForm.payAccount,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "payAccount", $$v)
                          },
                          expression: "ruleForm.payAccount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t(
                          "receivablePayable.TheAmountOfThePayment"
                        ),
                        prop: "actualClaimAmount",
                      },
                    },
                    [
                      _c("el-input-number", {
                        attrs: {
                          min: 0.01,
                          max: 999999999,
                          precision: 2,
                          controls: false,
                        },
                        model: {
                          value: _vm.ruleForm.actualClaimAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "actualClaimAmount", $$v)
                          },
                          expression: "ruleForm.actualClaimAmount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("basicData.TicketNumber") } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "50", "show-word-limit": "" },
                        model: {
                          value: _vm.ruleForm.claimsTicketNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.ruleForm,
                              "claimsTicketNumber",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "ruleForm.claimsTicketNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.remark") } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 2, maxRows: 4 },
                          maxlength: "300",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.ruleForm.remarks,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.ruleForm,
                              "remarks",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "ruleForm.remarks",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("customerManagements.enclosure") },
                    },
                    [
                      _c("UploadCmp", {
                        attrs: {
                          drag: true,
                          multiple: true,
                          "auto-upload": true,
                          limit: 5,
                        },
                        on: { change: _vm.onFileChange },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.determine")))]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.onClose(false)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }