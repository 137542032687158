export const formData = {
  id: '',
  createTime: '', // 创建时间
  createUser: '', // 创建人
  orderNumber: '', // 订单号
  state: 0, // 0：推送失败，1：推送成功
  requestMessage: '', // 请求报文
  responseMessage: '', // 响应信息
  logisticsNumber: '', // 物流单号
  customerName: '' // 客户名称
};
