import request from '@/utils/request';
import qs from 'qs';

/**
 * 查询系统服务商全部
 */
export const getSystemServiceList = (data) => {
  return request.get(`/providerSystemError/get`);
};

/**
 * 删除系统服务商错误信息
 */
export const deleteProviderSystemError = (data) => {
  return request.post(`/providerSystemError/delete/${data}`);
};

/**
 * 修改系统服务商错误信息
 */
export const updateProviderSystemError = (data) => {
  return request.post('/providerSystemError/update', data);
};

/**
 * 查询系统服务商错误信息详情
 */
export const getSystemErrorInfo = (data) => {
  return request.get(`/providerSystemError/${data}`);
};

/**
 * 增加系统服务商错误信息
 */
export const addproviderSystemError = (data) => {
  return request.post('/providerSystemError/add', data);
};
/**
 * 查询系统服务商错误信息列表
 */
export const providerSystemErrorPage = (data) => {
  return request.post('/providerSystemError/page', data);
};
/**
 * 服务商渠道状态更改
 */
export const updateState = (data) => {
  return request.post('/providerChannel/updateState', data);
};
/**
 * 修改服务商渠道
 */
export const updateproviderChannel = (data) => {
  return request.post('/providerChannel/update', data);
};
/**
 * 查询系统服务名称
 */
export const getProviderChannelInfo = (data) => {
  return request.get(`providerChannel/${data}`);
};
/**
 * 查询服务商渠道列表
 */
export const providerChannelPage = (data) => {
  return request.post('/providerChannel/page', data);
};
/**
 * 查询系统服务商列表
 */
export const providerSystemPage = (data) => {
  return request.post('/providerSystem/page', data);
};
/**
 * 查询系统服务名称
 */
export const getProviderChannel = (data) => {
  return request.get(`providerChannel/get?condition=${data}`);
};
/**
 * 查询供应商名称
 */
export const getProvider = (data) => {
  return request.get(`/providerChannel/getProvider?condition=${data}`);
};
/**
 * 添加系统服务商
 */
export const addSystemProvider = (data) => {
  return request.post('/providerSystem/add', data);
};
/**
 * 查询系统服务商详情
 */
export const selectSystemInfo = (data) => {
  return request.get(`/providerSystem/${data}`);
};
/**
 * 修改系统服务商详情
 */
export const updatSystemProvider = (data) => {
  return request.post('/providerSystem/update', data);
};
/**
 * 系统服务商修改
 */
export const editSystemProvider = (data) => {
  return request.post('/providerSystem/edit', data);
};
/**
 * 删除系统服务商
 */
export const deleteSystemProvider = (id) => {
  return request.post('/providerSystem/delete?id=' + id);
};
/**
 * 服务商渠道查询
 */
export const pageProviderChannel = (data) => {
  return request.post('/providerChannel/page', data);
};
/**
 * 添加服务商渠道
 */
export const addProviderChannel = (data) => {
  return request.post('/providerChannel/add', data);
};
/**
 * 修改服务商渠道
 */
export const editProviderChannel = (data) => {
  return request.post('/providerChannel/edit', data);
};
/**
 * 服务商渠道删除
 */
// export const deleteProviderChannel = (id) => {
//   return request.post('/providerChannel/delete?id=' + id);
// };
/**
 * 获取支持国家信息
 */
// export const getEnableCountryInfo = (channelId, countryTwoCode) => {
//   return request.get('/logistics/customerChannel/getEnableCountryInfo/' + channelId + '/' + countryTwoCode);
// };
/**
 * 删除支持国家
 */
// export const deleteEnableCountry = (channelId, countryTwoCode) => {
//   return request.post('/logistics/customerChannel/deleteEnableCountry/' + channelId + '/' + countryTwoCode);
// };
/**
 * 获取渠道规则
 */
export const getProviderChannelRule = (data) => {
  return request.post('/providerChannelRule/page', data);
};
/**
 * 新增渠道规则
 */
export const addProviderChannelRule = (data) => {
  return request.post('/providerChannelRule/add', data);
};
/**
 * 修改渠道规则
 */
export const editProviderChannelRule = (data) => {
  return request.post('/providerChannelRule/edit', data);
};
/**
 * 渠道规则删除
 */
export const deleteProviderChannelRule = (data) => {
  return request.post('/providerChannelRule/delete?id=' + data);
};
/**
 * 获取字段列表
 */
export const getProviderChannelField = (data) => {
  return request.post('/providerChannelField/page', data);
};
/**
 * 添加字段
 */
export const addProviderChannelField = (data) => {
  return request.post('/providerChannelField/add', data);
};
/**
 * 修改字段
 */
export const editProviderChannelField = (data) => {
  return request.post('/providerChannelField/edit', data);
};
/**
 * 删除字段名称
 */
export const deleteProviderChannelField = (id, userId) => {
  return request.post('/providerChannelField/delete?id=' + id + '&updateUserId=' + userId);
};
/**
 * 获取所有字段
 */
export const listProviderChannelField = (data) => {
  return request.post('/providerChannelField/list', data);
};
/**
 * 查询检验规则
 */
export const getProviderChannelCheck = (data) => {
  return request.post('/providerChannelCheck/page', data);
};
/**
 * 添加校验规则
 */
export const addProviderChannelCheck = (data) => {
  return request.post('/providerChannelCheck/add', data);
};
/**
 * 校验规则修改
 */
export const editProviderChannelCheck = (data) => {
  return request.post('/providerChannelCheck/edit', data);
};
/**
 * 删除校验规则
 */
export const deleteProviderChannelCheck = (id, userId) => {
  return request.post('/providerChannelCheck/delete?id=' + id + '&updateUserId=' + userId);
};
/**
 * 获取单号池
 */
// export const getOrderNumPool = (data) => {
//   return request.post('/orderNumPool/page', data);
// };
/**
 * 获取所有校验规则
 */
export const getAllProviderChannelCheck = (data) => {
  return request.post('/providerChannelCheck/list', data);
};
/**
 * 客户渠道启用
 */
export const onOrOffCustomerChannel = (id, userId) => {
  return request.put('/logistics/customerChannel/enable/' + id + '/' + userId);
};
/**
 * 客户渠道停用
 */
export const disableCustomerChannel = (id, userId) => {
  return request.put('/logistics/customerChannel/disable/' + id + '/' + userId);
};
/**
 * 服务商渠道启用，停用
 */
export const changeStatus = (data) => {
  return request.post('/providerChannel/changestatus?id=' + data.id + '&status=' + data.status + '&updateUserId=' + data.updateUserId);
};
// 获取服务商渠道所有支持国家
export const getSupportCountry = (id) => {
  return request.get('/providerChannel/country/list/' + id);
};
// 设置服务商渠道支持国家
export const setProviderChannelEnableCountry = (data) => {
  return request.post('/providerChannel/enableCountry', data);
};
// 获取系统服务商详情
export const getProviderSystem = (code) => {
  return request.get('/providerSystem/getByCode/' + code);
};
// 面单模板分页查询
export const getLabelTemplatePage = (data) => {
  return request.post('/labelTemplate/page', data);
};
// 查询所有面单
export const getLabelTemplateAll = (data) => {
  return request.post('/labelTemplate/list', data);
};

// 新增面单模板
export const addLabelTemplate = (data) => {
  return request.post('/labelTemplate/add', data);
};
// 修改面单模板
export const editLabelTemplate = (data) => {
  return request.post('/labelTemplate/edit', data);
};
// 删除面单模板
export const deleteLabelTemplate = (data) => {
  return request.post('/labelTemplate/delete/' + data);
};
// 下家列表
// export const downstreamList = (data) => {
//   return request.post('/customerChannelCountryDownstream/list', data);
// };
// 单号池
export const orderNumPoolList = (data) => {
  return request.post('/orderNumPool/list', data);
};
// 新增平台
export const addPlatformInfo = (data) => {
  return request.post('/platformInfo', data);
};
// 平台分页
export const pagePlatformInfo = (data, isBool = true) => {
  return request({
    method: 'post',
    data,
    url: `/platformInfo/page`,
    isLoading: isBool // 是否显示加载中参数
  });
  // return request.post('/platformInfo/page', data);
};
// 修改平台
export const editPlatformInfo = (id, data) => {
  return request.put('/platformInfo/' + id, data);
};
// 删除平台
export const deletePlatformInfo = (data) => {
  return request.delete('/platformInfo/' + data);
};

// 获取所有已绑定平台的客户
export const apiPlatformCustomer = () => {
  return request.post('/platformInfo/platformCustomer');
};

// 获取详情
export const apiGetPlatformInfo = platformCode => {
  return request.post('/platformInfo/getPlatformInfo', qs.stringify({ platformCode }));
};

// 解绑平台
export const apiUnbindPlatform = customerIds => {
  return request.post('/platformInfo/unbindPlatform', qs.stringify({ customerIds }));
};

// 获取客户信息
export const apiGetCustomerName = customerIds => {
  return request.post('/platformInfo/getCustomerName', qs.stringify({ customerIds }));
};

// 应用国家/客户
export const apiSetCustomer = data => {
  return request.post('/logistics/customerChannel/setCustomer', data);
};

// 获取所有钮门下家
export const temporaryQuery = data => {
  return request.post('/temporary/data/add/query', data);
};
// 添加钮门下家
export const addDownInfo = data => {
  return request.post('/temporary/data/add/downInfo', data);
};
// 删除钮门下家
export const deleteTemporary = data => {
  return request.post('/temporary/data/add/delete?channelId=' + data);
};

// 客户渠道支持国家
// export const apiCustomerChannelCountryPage = data => {
//   return request.post('/logistics/customerChannelCountry/page', data);
// };

// 客户渠道支持国家
export const apiLgAreaPage = data => {
  return request.post('/logistics/customerChannelCountry/getIdleCountryPageList', data);
};

// 新增所有国家配置
export const apiAddAllCountryConfig = data => {
  return request.post('/logistics/customerChannelCountry/addAllCountryConfig', data);
};

// 批量新增
export const apiAddBatch = data => {
  return request.post('/logistics/customerChannelCountry/addBatch', data);
};

// 批量修改
export const apiEditBatch = data => {
  return request.post('/logistics/customerChannelCountry/editBatch', data);
};

// 批量删除
export const apiRemoveBatch = data => {
  return request.delete(`/logistics/customerChannelCountry/removeBatch/${data}`);
};

// 编辑所有已选国家配置
export const apiEditAllCountryConfig = data => {
  return request.post('/logistics/customerChannelCountry/editAllCountryConfig', data);
};

// 已配置国家分页
export const apiCustomerChannelCountry = data => {
  return request.post('/logistics/customerChannelCountry/page', data);
};
// 获取服务商错误信息对照列表
export const getServiceProviderError = data => {
  return request.post('/serviceProviderError/page', data);
};
// 添加服务商错误信息
export const serviceProviderErrorAdd = data => {
  return request.post('/serviceProviderError/add', data);
};
// 修改服务商错误信息
export const serviceProviderErrorEdit = data => {
  return request.post('/serviceProviderError/edit', data);
};
// 删除服务商错误信息
export const serviceProviderErrorDelete = data => {
  return request.post('/serviceProviderError/delete?id=' + data);
};

// 获取服务商渠道可选发件人信息
// export const apiGetList = (data, isBool = true) => {
//   return request({
//     method: 'post',
//     data,
//     url: `/logistics/addressBook/getList`,
//     isLoading: isBool // 是否显示加载中参数
//   });
// };
// 导出换单订单
export const orderExchange = data => {
  return request.post('/logistics/order/export/order/exchange', data);
};

// 客户渠道报价分页
export const apiOfferPricePage = data => {
  return request.post('/fa/offerPrice/page', data);
};

// 渠道报价获取详情
export const apiAffiliateGetAffiliateById = ids => {
  return request.post(`/fa/offerPrice/affiliate/getAffiliateById?id=${ids}`);
};

// 校验重量区间是否存在重复
export const apiAffiliateCheckWeight = data => {
  return request.post('/fa/offerPrice/affiliate/checkWeight', data);
};

// 导出报价模板
export const apiExportTemplate = data => {
  return request.post('/fa/offerPrice/exportTemplate', data);
};

/**
 * 查询启动的渠道
 */
export const getStartupProviderChannel = (data) => {
  return request.post('/provider/getProviderChannel', data);
};
