import { requestScripting } from '@/lib/axios';

export const equipmentService = {
  async create(data) {
    const body = this.getBody(data);

    return requestScripting({
      method: 'POST',
      data: body,
      url: `equipment`
    });
  },
  async edit(data, id) {
    const body = this.getBody(data);

    return requestScripting({
      method: 'PATCH',
      data: body,
      url: `equipment/${id}`
    });
  },
  async getByRoute(id) {
    return requestScripting({
      method: 'GET',
      url: `equipment/route/${id}`
    });
  },
  getBody(data) {
    return {
      'driver_document': data.document,
      'vehicle_license_plate': data.plate,
      'trailer_license_plate': data.trailerPlate,
      'username': data.username,
      'helpers': data.helpers,
      'route_id': parseInt(data.routeId),
      'equipment_is_active': 1
    };
  }
};
