var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "wrapper-form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.handleNextStep($event)
        },
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          $event.preventDefault()
        },
      },
    },
    [
      _c(
        "o-field",
        {
          staticClass: "span-2",
          attrs: {
            label: _vm.$t("routes.selectedColorsLabel"),
            "label-for": "selectedColors",
          },
        },
        [
          _c("o-inputitems", {
            ref: "inputColor",
            attrs: {
              id: "selectedColors",
              placeholder: _vm.$t("routes.selectedColorsPlaceholder"),
              data: _vm.optionsSealColors,
              "allow-new": false,
              "open-on-focus": false,
              autocomplete: true,
              "allow-autocomplete": true,
            },
            on: { remove: _vm.clearCodeSeals, typing: _vm.filteredSealColor },
            scopedSlots: _vm._u([
              {
                key: "selected",
                fn: function (ref) {
                  var items = ref.items
                  return _vm._l(items, function (item, index) {
                    return _c(
                      "span",
                      {
                        key: index,
                        staticClass: "item_selected",
                        attrs: { rounded: "" },
                        on: {
                          click: function ($event) {
                            return _vm.$refs.inputColor.removeItem(
                              index,
                              $event
                            )
                          },
                        },
                      },
                      [
                        _vm._v(" " + _vm._s(item) + " "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "5px",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.inputColor.removeItem(
                                  index,
                                  $event
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "lucide lucide-x",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "16",
                                  height: "16",
                                  viewBox: "0 0 24 24",
                                  fill: "none",
                                  stroke: "currentColor",
                                  "stroke-width": "2",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                },
                              },
                              [
                                _c("path", { attrs: { d: "M18 6 6 18" } }),
                                _c("path", { attrs: { d: "m6 6 12 12" } }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  })
                },
              },
            ]),
            model: {
              value: _vm.formData.selectedColors,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "selectedColors", $$v)
              },
              expression: "formData.selectedColors",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          staticClass: "span-1",
          attrs: {
            label: _vm.$t("routes.quantityBagsLabel"),
            "label-for": "quantityBags",
            message: _vm.v$.quantityBags.$errors.length
              ? _vm.v$.quantityBags.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "quantityBags",
              placeholder: _vm.$t("routes.quantityBagsPlaceholder"),
            },
            model: {
              value: _vm.formData.quantityBags,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "quantityBags", $$v)
              },
              expression: "formData.quantityBags",
            },
          }),
        ],
        1
      ),
      _vm.formData.selectedColors.length
        ? _c(
            "div",
            { staticClass: "wrapper-colors  span-3" },
            _vm._l(_vm.formData.selectedColors, function (color, index) {
              return _c(
                "o-collapse",
                {
                  key: color,
                  staticClass: "content-separator ",
                  attrs: { open: _vm.isOpenCollapse == index },
                  on: {
                    open: function ($event) {
                      _vm.isOpenCollapse = index
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "trigger",
                        fn: function (props) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "wrapper-collapse",
                                attrs: {
                                  role: "button",
                                  "aria-controls":
                                    "contentIdForA11y5-formMeasurementVehicle",
                                  "aria-expanded": _vm.isOpenCollapse,
                                },
                              },
                              [
                                _c("p", { staticClass: "name-color" }, [
                                  _vm._v(" " + _vm._s(color) + " "),
                                ]),
                                _c(
                                  "div",
                                  [
                                    _c("o-icon", {
                                      attrs: {
                                        icon: props.open
                                          ? "caret-up"
                                          : "caret-down",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c(
                    "div",
                    { staticClass: "wrapper-seal" },
                    [
                      _c(
                        "o-field",
                        {
                          attrs: {
                            message: _vm.v$.sealCode[color].$errors.length
                              ? _vm.v$.sealCode[color].$errors[0].$message
                              : null,
                          },
                        },
                        [
                          _c("input", {
                            staticClass: "o-input",
                            attrs: {
                              id: "numberSeal",
                              placeholder: _vm.$t("routes.colorPlaceholder"),
                              autocomplete: "off",
                            },
                            on: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return (function (event) {
                                  _vm.handleAddListSeal(event, color)
                                })($event)
                              },
                            },
                          }),
                        ]
                      ),
                      _c(
                        "ul",
                        { staticClass: "seal-list" },
                        _vm._l(_vm.formData.sealCode[color], function (seal) {
                          return _c(
                            "li",
                            { key: seal, staticClass: "seal-item" },
                            [
                              _c("p", [_vm._v(" " + _vm._s(seal) + " ")]),
                              _c(
                                "o-button",
                                {
                                  ref: "trigger",
                                  refInFor: true,
                                  attrs: {
                                    slot: "trigger",
                                    variant: "danger",
                                    size: "sm",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeSeal(seal, color)
                                    },
                                  },
                                  slot: "trigger",
                                },
                                [_c("o-icon", { attrs: { icon: "trash" } })],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ]
              )
            }),
            1
          )
        : _vm._e(),
      _c(
        "FormActions",
        [
          _c("FormActionsPrevious", {
            attrs: { "is-first-step": _vm.isFirstStep },
            on: { handlePrevious: _vm.handlePrevious },
          }),
          _c("FormActionsNext", { attrs: { "is-last-step": _vm.isLastStep } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }