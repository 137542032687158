var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "wrapper-form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        },
      },
    },
    [
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("editingRegistration.clientName"),
            "label-for": "customer",
          },
        },
        [
          _c("o-input", {
            attrs: { id: "customer", disabled: "", value: "PANINI BRASIL S.A" },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("editingRegistration.groupCode"),
            "label-for": "codGroup",
            message: _vm.v$.codGroup.$errors.length
              ? _vm.v$.codGroup.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "codGroup",
              disabled: _vm.isEdit,
              placeholder: _vm.$t("editingRegistration.groupCodePlaceholder"),
            },
            model: {
              value: _vm.codGroupLocal,
              callback: function ($$v) {
                _vm.codGroupLocal = $$v
              },
              expression: "codGroupLocal",
            },
          }),
        ],
        1
      ),
      _c(
        "FormActions",
        [
          _c("FormActionsPrevious", {
            attrs: { "is-first-step": _vm.isFirstStep },
            on: { handlePrevious: _vm.handlePrevious },
          }),
          _c("FormActionsNext", { attrs: { "is-last-step": _vm.isLastStep } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }