var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("AvailableSellers", {
        attrs: {
          sellers: _vm.sellers,
          "handle-next": _vm.handleSubmit,
          "is-last-step": true,
          "is-first-step": true,
        },
        on: { updateSeller: _vm.onUpdateSellers },
      }),
      _c("o-loading", {
        attrs: { "full-page": true, active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }