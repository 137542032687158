var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { "padding-top": "5px" } },
    [
      _c("el-tree", {
        ref: "treeRef",
        attrs: {
          data: _vm.permissionList,
          "default-checked-keys": _vm.defSelectedPermissionList,
          props: _vm.props,
          "icon-class": "el-icon-thumb",
          "node-key": "id",
          "show-checkbox": "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }