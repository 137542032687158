<script >
import { ref, watch, defineProps } from '@vue/composition-api';
import { useModal } from '@/composable/useModal';
export const {
  isOpen,
  handleVisibility: handleVisibilityPopover
} = useModal();
const __sfc_main = {};
__sfc_main.props = {
  width: {
    type: Number,
    default: 300
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const popover = ref(null);
  function calculatePositionPopover() {
    const [trigger, content] = popover.value.childNodes;
    const {
      x,
      y,
      height,
      width
    } = trigger.getBoundingClientRect();
    const top = y + height;
    const left = x;
    content.style.position = 'fixed';
    content.style.top = `${top + 6}px`;
    content.style.left = `${left - (props.width - width)}px`;
  }
  function outsideClick(e) {
    const selectedElement = e.target;
    const isContainElement = popover.value.contains(selectedElement);
    if (!isContainElement) handleVisibilityPopover();
  }
  watch(isOpen, newValue => {
    if (newValue) {
      window.addEventListener('click', outsideClick);
      window.addEventListener('resize', calculatePositionPopover);
      calculatePositionPopover();
    } else {
      window.removeEventListener('click', outsideClick);
      window.removeEventListener('resize', calculatePositionPopover);
    }
  });
  return {
    isOpen,
    handleVisibilityPopover,
    popover
  };
};
export default __sfc_main;
</script>

<template>
  <div ref="popover">
    <div slot="trigger" @click="handleVisibilityPopover">
      <slot name="trigger" />
    </div>

    <div v-show="isOpen" class="popover-content" :style="`width: ${width}px`">
      <slot name="content" />
    </div>
  </div>
</template>

<style scoped>

.popover-content{
  padding: 1rem;
  background-color: hsl(var(--o-popover));
  color: hsl(var(--o-popover-foreground));
  border-color: hsl(var(--o-border));
  z-index: 1002;
  overflow: hidden;
  border-radius: 0.375rem/* 6px */;
  border-width: 1px;
  --o-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--o-ring-offset-shadow, 0 0 #0000), var(--o-ring-shadow, 0 0 #0000), var(--o-shadow);
}

</style>
