var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      staticClass: "demo-ruleForm",
      attrs: { "label-position": "top" },
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.SenderName") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.senderName))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.SenderCompany") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.senderCompany))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.senderPhone") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.senderMobile))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("driver.modalStreet") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.senderStreet))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.SenderNumber") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.senderHouseNumber))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.SenderDistrict") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.senderArea))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.SenderCity") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.senderCity))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.SenderState") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.senderState))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.SenderZipCode") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.senderZipcode))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("GlobalSetObj.sendderComplement") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.senderComplement))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.SenderCPF") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.senderTax))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("navbar.senderIe") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.senderIeNumber))])]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.SendingAddress") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.senderAddress))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.SenderPhone") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.senderPhone))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.SenderEmail") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.senderMail))])]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }