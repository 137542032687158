var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { padding: "0 10px" } }, [
    _c(
      "div",
      { staticClass: "addTable" },
      [
        _vm.addType === 2
          ? _c(
              "div",
              [
                _c("div", { staticClass: "headerStyle" }, [
                  _vm._v(_vm._s(_vm.$t("collectionCenter.basicInformation"))),
                ]),
                _c(
                  "el-card",
                  { staticClass: "box-card" },
                  [
                    _c(
                      "el-form",
                      { attrs: { "label-width": "auto" } },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label:
                                        _vm.$t(
                                          "receivablePayable.TicketNumber"
                                        ) + "：",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.addBatchFee.waybillNumber) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label:
                                        _vm.$t(
                                          "receivablePayable.CustomerTrackingNumber"
                                        ) + "：",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.addBatchFee.customerNumber) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("basicData.Outlets") + "：",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.addBatchFee.siteName) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label:
                                        _vm.$t("receivablePayable.customer") +
                                        "：",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.addBatchFee.customerAlias) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label:
                                        _vm.$t("placeOrder.packageType") + "：",
                                    },
                                  },
                                  [
                                    _vm.addBatchFee.orderPackageType === 1
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("placeOrder.zhifa"))
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.addBatchFee.orderPackageType === 2
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("placeOrder.CoPacking")
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.addBatchFee.orderPackageType === 3
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("basicData.other"))
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label:
                                        _vm.$t(
                                          "receivablePayable.BillableWeight"
                                        ) + "：",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.addBatchFee.chargeWeight) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label:
                                        _vm.$t("receivablePayable.remark") +
                                        "：",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.queryFeeParam.remark) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "headerStyle" }, [
          _vm._v(_vm._s(_vm.$t("receivablePayable.FeeBreakdown"))),
        ]),
        _c(
          "el-form",
          {
            ref: "addBatchFee",
            staticClass: "error-form",
            attrs: {
              model: _vm.addBatchFee,
              "label-width": "0px",
              size: "small",
            },
          },
          [
            _c(
              "el-table",
              {
                attrs: {
                  data: _vm.addBatchFee.expenseSubList,
                  height: "300",
                  border: "",
                },
              },
              [
                _c(
                  "el-table-column",
                  {
                    attrs: { width: "55", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.feeSource === 1 &&
                            scope.row.isAccept === false
                              ? _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      title: _vm.$t(
                                        "basicData.AreYouSureDelete"
                                      ),
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.deleteCost(scope)
                                      },
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "el-icon-delete deleteStyle",
                                      attrs: { slot: "reference" },
                                      slot: "reference",
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c("span", {
                        staticClass: "el-icon-plus addStyle",
                        on: {
                          click: function ($event) {
                            return _vm.addCost()
                          },
                        },
                      }),
                    ]),
                  ],
                  2
                ),
                _c("el-table-column", {
                  attrs: {
                    prop: "businessType",
                    label: _vm.$t("collectionCenter.businessT"),
                    align: "center",
                    width: "180",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "formStyle" },
                            [
                              scope.row.isEdit === false
                                ? _c("div", [
                                    scope.row.businessType === 0
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "collectionCenter.expressDelivery"
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    scope.row.businessType === 1
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("collectionCenter.lanshou")
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    scope.row.businessType === 2
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "collectionCenter.dispatch"
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "expenseSubList." +
                                          scope.$index +
                                          ".businessType",
                                        rules: {
                                          required: true,
                                          message: _vm.$t(
                                            "basicData.CannotBeEmpty"
                                          ),
                                          trigger: ["blur", "change"],
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            filterable: "",
                                            placeholder: _vm.$t(
                                              "operationCenter.PleaseSelect"
                                            ),
                                            clearable: "",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.businessTypeChange(
                                                scope
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.businessType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "businessType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.businessType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.businessTypeList,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "feeName",
                    label: _vm.$t("basicData.ExpenseType"),
                    align: "center",
                    width: "180",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "formStyle" },
                            [
                              scope.row.isEdit === false
                                ? _c("div", [
                                    _vm._v(
                                      " " + _vm._s(scope.row.feeName) + " "
                                    ),
                                  ])
                                : _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "expenseSubList." +
                                          scope.$index +
                                          ".feeCode",
                                        rules: {
                                          required: true,
                                          message: _vm.$t(
                                            "basicData.CannotBeEmpty"
                                          ),
                                          trigger: ["blur", "change"],
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            filterable: "",
                                            clearable: "",
                                            placeholder: _vm.$t(
                                              "operationCenter.PleaseSelect"
                                            ),
                                          },
                                          model: {
                                            value: scope.row.feeCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "feeCode",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.feeCode",
                                          },
                                        },
                                        _vm._l(
                                          scope.row.feeTypeOptionList,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.displayName,
                                                value: item.code,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "isGenBill",
                    label: _vm.$t(
                      "receivablePayable.WhetherToGenerateAStatement"
                    ),
                    width: "180",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                scope.row.isGenBill
                                  ? _vm.$t("receivablePayable.Generated")
                                  : _vm.$t("receivablePayable.NotGenerated")
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "isAccept",
                    label: _vm.$t("receivablePayable.WhetherToCheckOrNot"),
                    width: "100",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                scope.row.isAccept
                                  ? _vm.$t("basicData.Received")
                                  : _vm.$t("basicData.NotReconciled")
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c(
                  "el-table-column",
                  {
                    attrs: {
                      prop: "chargeWeight",
                      label: _vm.$t("receivablePayable.BillableWeight"),
                      width: "100",
                      align: "center",
                    },
                  },
                  [_c("div", [_vm._v(_vm._s(_vm.addBatchFee.chargeWeight))])]
                ),
                _c("el-table-column", {
                  attrs: {
                    prop: "fee",
                    label: _vm.$t("receivablePayable.AmountReceivable"),
                    width: "100",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "formStyle" },
                            [
                              scope.row.isEdit === false
                                ? _c("div", [
                                    _vm._v(" " + _vm._s(scope.row.fee) + " "),
                                  ])
                                : _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "expenseSubList." +
                                          scope.$index +
                                          ".fee",
                                        rules: {
                                          required: true,
                                          suffix: 2,
                                          negative: "-?",
                                          validator: _vm.priceType,
                                          trigger: ["blur", "change"],
                                        },
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: scope.row.fee,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "fee", $$v)
                                          },
                                          expression: "scope.row.fee",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "operateTime",
                    label: _vm.$t("basicData.BusinessTime"),
                    align: "center",
                    width: "240",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "formStyle" },
                            [
                              scope.row.isEdit === false
                                ? _c("div", [
                                    _vm._v(
                                      " " + _vm._s(scope.row.operateTime) + " "
                                    ),
                                  ])
                                : _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "expenseSubList." +
                                          scope.$index +
                                          ".operateTime",
                                        rules: {
                                          required: true,
                                          message: _vm.$t(
                                            "basicData.CannotBeEmpty"
                                          ),
                                          trigger: ["blur", "change"],
                                        },
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "datetime",
                                          "value-format": "yyyy-MM-dd HH:mm:ss",
                                          placeholder: _vm.$t(
                                            "basicData.SelectADatetime"
                                          ),
                                        },
                                        model: {
                                          value: scope.row.operateTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "operateTime",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.operateTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "createUser",
                    label: _vm.$t("receivablePayable.EnterThePerson"),
                    align: "center",
                    width: "100",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "formStyle" },
                            [
                              scope.row.isEdit === false
                                ? _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.createUser[scope.row.createUser]
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "expenseSubList." +
                                          scope.$index +
                                          ".createUser",
                                      },
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.createUser[scope.row.createUser]
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm.addType === 1
                  ? _c("el-table-column", {
                      attrs: {
                        prop: "flatMethod",
                        label: _vm.$t("receivablePayable.FlatSharingMethod"),
                        align: "center",
                        width: "180",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop:
                                        "expenseSubList." +
                                        scope.$index +
                                        ".flatMethod",
                                      rules: {
                                        required: true,
                                        message: _vm.$t(
                                          "basicData.CannotBeEmpty"
                                        ),
                                        trigger: ["blur", "change"],
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          filterable: "",
                                          clearable: "",
                                          placeholder: _vm.$t(
                                            "operationCenter.PleaseSelect"
                                          ),
                                        },
                                        model: {
                                          value: scope.row.flatMethod,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "flatMethod",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.flatMethod",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          key: "0",
                                          attrs: {
                                            label: _vm.$t(
                                              "receivablePayable.SpreadEquallyByWeight"
                                            ),
                                            value: "1",
                                          },
                                        }),
                                        _c("el-option", {
                                          key: "1",
                                          attrs: {
                                            label: _vm.$t(
                                              "receivablePayable.SplitEquallyByTheNumberOfVotes"
                                            ),
                                            value: "2",
                                          },
                                        }),
                                        _c("el-option", {
                                          key: "2",
                                          attrs: {
                                            label: _vm.$t(
                                              "receivablePayable.SpecifyTheAmount"
                                            ),
                                            value: "3",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3325319906
                      ),
                    })
                  : _vm._e(),
                _c("el-table-column", {
                  attrs: {
                    prop: "feeSource",
                    label: _vm.$t("receivablePayable.SourceOfFees"),
                    align: "center",
                    width: "180",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            scope.row.feeSource === 0
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "receivablePayable.SystemGenerated"
                                      )
                                    )
                                  ),
                                ])
                              : scope.row.feeSource === 1
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("receivablePayable.AddManually")
                                    )
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "receivablePayable.CollectionSystemPush"
                                      )
                                    )
                                  ),
                                ]),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "receiveZipcode",
                    label: _vm.$t("collectionCenter.Recipientzc"),
                    align: "center",
                    "min-width": "120",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "senderZipcode",
                    label: _vm.$t("collectionCenter.senderZipCode"),
                    align: "center",
                    "min-width": "120",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "remark",
                    label: _vm.$t("receivablePayable.remark"),
                    align: "center",
                    "min-width": "160",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.isEdit === false
                            ? _c("div", [
                                _vm._v(" " + _vm._s(scope.row.remark) + " "),
                              ])
                            : _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop:
                                      "expenseSubList." +
                                      scope.$index +
                                      ".remark",
                                    rules: {
                                      required: false,
                                      message: _vm.$t(
                                        "basicData.CannotBeEmpty"
                                      ),
                                      trigger: ["blur", "change"],
                                    },
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "GlobalSetObj.pleaseInputContent"
                                      ),
                                    },
                                    model: {
                                      value: scope.row.remark,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "remark", $$v)
                                      },
                                      expression: "scope.row.remark",
                                    },
                                  }),
                                ],
                                1
                              ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticStyle: { padding: "20px", "text-align": "center" } },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary", size: "small" },
            on: {
              click: function ($event) {
                return _vm.saveData(2)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("basicData.Save")))]
        ),
        _c(
          "el-button",
          {
            attrs: { type: "primary", size: "small" },
            on: {
              click: function ($event) {
                return _vm.saveData(1)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("collectionCenter.close")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }