var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c(
      "section",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.step === 0,
            expression: "step === 0",
          },
        ],
        staticClass: "space-m-y-32 time-line__container",
      },
      [
        _c("section", { staticClass: "time-line__section" }, [
          _c(
            "div",
            { staticClass: "section-icon" },
            [
              _c("o-icon", {
                staticClass: "time-line__icon",
                attrs: { icon: "map-marker-alt", size: "large" },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              on: {
                click: function ($event) {
                  return _vm.setAddressInfoRoute("initial")
                },
              },
            },
            [
              _c("h2", { staticClass: "header-2 time-line__title" }, [
                _vm._v(_vm._s(_vm.baseData.initialPoint.pointName)),
              ]),
              _c("p", { staticClass: "paragraph" }, [
                _vm._v(_vm._s(_vm.baseData.initialPoint.addressPoint)),
              ]),
            ]
          ),
        ]),
        _vm._l(_vm.baseData.stopPoints, function (item, key) {
          return _c(
            "section",
            { key: key, staticClass: "section-stops-point" },
            [
              _vm._m(0, true),
              _c("div", { staticClass: "time-line__section" }, [
                _c(
                  "div",
                  { staticClass: "section-icon" },
                  [
                    _c("o-icon", {
                      staticClass: "time-line__icon time-line__icon-points",
                      attrs: { icon: "map-pin", size: "large" },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.setAddressInfoRoute(item)
                      },
                    },
                  },
                  [
                    _c("h2", { staticClass: "header-2 time-line__title" }, [
                      _vm._v(_vm._s(item.pointName)),
                    ]),
                    _c("p", { staticClass: "paragraph" }, [
                      _vm._v(_vm._s(item.addressPoint)),
                    ]),
                  ]
                ),
              ]),
            ]
          )
        }),
        _c("section", { staticClass: "section__full-stop " }, [
          _vm._m(1),
          _c("div", { staticClass: "time-line__section" }, [
            _c(
              "div",
              { staticClass: "section-icon" },
              [
                _c("o-icon", {
                  staticClass: "time-line__icon",
                  attrs: { icon: "map-marker-alt", size: "large" },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                on: {
                  click: function ($event) {
                    return _vm.setAddressInfoRoute("final")
                  },
                },
              },
              [
                _c("h2", { staticClass: "header-2 time-line__title" }, [
                  _vm._v(_vm._s(_vm.baseData.fullStop.pointName)),
                ]),
                _c("p", { staticClass: "paragraph" }, [
                  _vm._v(_vm._s(_vm.baseData.fullStop.addressPoint)),
                ]),
              ]
            ),
          ]),
        ]),
      ],
      2
    ),
    _c(
      "section",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.step !== 0,
            expression: "step !== 0",
          },
        ],
        staticClass: "section__info-route",
      },
      [
        _c("div", { staticClass: "info-route__title" }, [
          _c("h2", { staticClass: "header-3" }, [
            _vm._v(_vm._s(_vm.addressInfoRoute.pointName)),
          ]),
          _c("p", { staticClass: "paragraph" }, [
            _vm._v(_vm._s(_vm.addressInfoRoute.addressPoint)),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "info__route-form" },
          [
            _c("VtagForm", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.step === 1,
                  expression: "step === 1",
                },
              ],
              attrs: {
                "handle-previous-step": _vm.handlePreviousStep,
                "handle-next-step": _vm.handleNextStep,
                form: _vm.form,
                "mock-v-tags-out": _vm.vTagsToOut,
                "handle-get-vtags": _vm.handleGetVtags,
                "v-tags": _vm.vTags,
                "base-data": _vm.baseData,
              },
            }),
            _c("SealForm", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.step === 2,
                  expression: "step === 2",
                },
              ],
              attrs: { form: _vm.form },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "info__route-footer" },
          [
            _c(
              "o-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.step !== 0,
                    expression: "step !== 0",
                  },
                ],
                staticClass: "back-button",
                attrs: { variant: "outline", size: "md" },
                on: { click: _vm.handlePreviousStep },
              },
              [_vm._v("Voltar")]
            ),
            _c(
              "o-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.step !== 0,
                    expression: "step !== 0",
                  },
                ],
                staticClass: "back-button",
                attrs: { variant: "primary", size: "md" },
                on: { click: _vm.handleNext },
              },
              [_vm._v(_vm._s(_vm.step === 2 ? "Enviar" : "Próximo"))]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section-icon-dots" }, [
      _c("span", { staticClass: "time-line_dot" }, [_vm._v("●")]),
      _c("span", { staticClass: "time-line_dot" }, [_vm._v("●")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section-icon-dots" }, [
      _c("span", { staticClass: "time-line_dot" }, [_vm._v("●")]),
      _c("span", { staticClass: "time-line_dot" }, [_vm._v("●")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }