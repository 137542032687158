var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "row", staticStyle: { "margin-bottom": "20px" } },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "small",
                disabled: _vm.multipleSelection.length === 0,
              },
              on: { click: _vm.asyncApiClaimformExport },
            },
            [_vm._v(_vm._s(_vm.$t("CenterForSorting.export")))]
          ),
          _c("TipsCmp", {
            attrs: { len: _vm.tableData.length, "total-value": _vm.totalValue },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            "min-height": 300,
            height: 480,
            border: "",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: _vm.$t("operationCenter.ind"),
              width: "60px",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.OperationSite"),
              prop: "siteName",
              width: "120",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("basicData.OrderNumber"),
              prop: "barCode",
              width: "170",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.customerNum"),
              prop: "customerNumber",
              width: "170",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("newOrder.CollectionType"),
              prop: "collectTypeStr",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.bagWeight"),
              prop: "totalDeclaredWeight",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("orderCenterCont.DeclaredValue"),
              prop: "totalDeclaredValue",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("orderCenterCont.OrderStatus"),
              prop: "statusStr",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("ClaimsManagement.CurrentTrajectory"),
              prop: "trajectory",
              width: "170",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("ClaimsManagement.TrackTime"),
              prop: "trajectoryTime",
              width: "170",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("GlobalSetObj.remark"),
              prop: "remarks",
              fixed: "right",
              width: "200",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }