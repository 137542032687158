// 自定义栏目获取
import { cloneDeep, differenceWith } from 'lodash-es';
import { apiCustomColumnTemplateList } from '@/api/finance/receivables';
import { L_TABLE_HEADER, P_TABLE_HEADER, TF_TABLE_HEADER } from '@/views/finance/payableManage/feesPayable/enums.js';

const templateList = {
  1: {
    type: 0,
    menuEnum: 'hut'
  },
  2: {
    type: 0,
    menuEnum: 'ThreeCollect'
  },
  3: {
    type: 0,
    menuEnum: 'AJDDispatch'
  },
  4: {
    type: 0,
    menuEnum: 'DSPDispatch'
  },
  5: {
    type: 0,
    menuEnum: 'TransportationTrunkFeeder'
  },
};

const TABLEHEADER = {
  1: L_TABLE_HEADER,
  2: L_TABLE_HEADER,
  3: P_TABLE_HEADER,
  4: P_TABLE_HEADER,
  5: TF_TABLE_HEADER,
};

export const commonMinXin = {
  data() {
    return {
      customColumnId: '', // 自定义栏目id
      selectedColumn: [], // 自定义栏目列表
      checkedColumns: [], // 自定义栏目选中列表

      diffCustomColumn: [], // table展示columns
      treeColumns: [], // 自定义栏目树

      L_TABLE_HEADER,
      P_TABLE_HEADER,
      TF_TABLE_HEADER,

      tableKey: new Date().getTime(),
    }
  },

  provide() {
    return {
      checkedColumns: this.checkedColumns,
      customColumnId: this.customColumnId,
      treeColumns: this.treeColumns,
      tableKey: this.tableKey,
      getCheckedColumns: () => this.checkedColumns,
      getCustomColumnId: () => this.customColumnId,
      getTreeColumns: () => this.treeColumns,
      getCustomColumnTemplateList: () => this.getCustomColumnTemplateList(),
      getTableKey: () => this.tableKey,
    }
  },

  created() {
    this.getCustomColumnTemplateList();
  },

  methods: {
    // 获取自定义栏目
    getCustomColumnTemplateList() {
      const params = templateList[this.payableType];
      apiCustomColumnTemplateList(params).then((res) => {
        if (res.status === 'OK') {
          if (res.data.length) {
            this.customColumnId = res.data[0].id;
            this.selectedColumn = JSON.parse(res.data[0].templateField);
          }

          this.customColumnData();
        }
      });
    },

    // 自定义栏目数据处理
    customColumnData() {
      console.log(this.payableType, '选中的自定义栏目');
      // const columnsList = [1, 2].includes(this.payableType) ? this.L_TABLE_HEADER : this.P_TABLE_HEADER;
      const columnsList = TABLEHEADER[this.payableType];
    
      // 清空checkedColumns以防重复添加
      this.checkedColumns = [];
    
      if (!this.selectedColumn.length) {
        columnsList.forEach(item => {
          this.checkedColumns.push(item.prop);
        });
      } else {
        this.selectedColumn.forEach(item => {
          this.checkedColumns.push(item.prop);
        });
      }
    
      // console.log(this.checkedColumns, '选中的columns');
    
      // 更新列表columns数据
      const allColumns = [];
      this.checkedColumns.forEach(col => {
        columnsList.forEach(item => {
          if (item.prop === col) {
            allColumns.push(item);
          }
        });
      });
      
      this.$nextTick(() => {
        this.diffCustomColumn = cloneDeep(allColumns);
        this.tableKey = new Date().getTime();
      });

      // console.log(allColumns, '列表呈现的columns');

      // 更新自定义栏目的数据
      const columns = this.getObjectArrayDifference(columnsList, allColumns, 'prop');
      // console.log(columns, '自定义栏目的数据');
      this.treeColumns = [...allColumns, ...columns];

    },

    // 自定义栏目columns数据更新
    getObjectArrayDifference(array1, array2, propName) {
      return differenceWith(array1, array2, (a, b) => a[propName] === b[propName]);
    }
  },
};