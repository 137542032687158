<template>
  <div class="app-container">
    <el-form label-position="top">
      <el-row :gutter="20">
        <el-col :span="3" style="height: 70px;">
          <el-form-item :label="$t('collectionCenter.operationType')">
            <el-select
              v-model="queryForm.params.pushType"
              :placeholder="$t('GlobalSetObj.all')"
              clearable
              filterable
              style="width: 100%;"
              @change="handleChange1"
            >
              <el-option key="1" :label="$t('GlobalSetObj.DeliveryPoint')" value="1" />
              <el-option key="2" :label="$t('GlobalSetObj.CancellationOutletDelivery')" value="2" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="3" style="height: 70px;">
          <el-form-item :label="$t('GlobalSetObj.Receptor')">
            <el-select
              v-model="queryForm.params.recipientCode"
              :placeholder="$t('GlobalSetObj.Only10AreDisplayed')"
              :remote-method="getProviderPage"
              clearable
              filterable
              remote
              style="width: 100%;"
              @change="handleChange2"
              @focus="getProviderPage('')"
            >
              <el-option
                v-for="(item, ind) in supplierMainList"
                :key="ind"
                :label="item.providerName"
                :value="item.code"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="3" style="height: 70px;">
          <el-form-item :label="$t('GlobalSetObj.ProcessingStatus')">
            <el-select
              v-model="queryForm.params.pushStatus"
              :placeholder="$t('GlobalSetObj.all')"
              clearable
              style="width: 100%;"
              @change="handleChange3"
            >
              <el-option
                v-for="(item, ind) in pushStatusList"
                :key="ind"
                :label="item.label"
                :value="item.code"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="3" style="height: 70px;">
          <el-form-item :label="$t('GlobalSetObj.orderStatus')">
            <el-select
              v-model="queryForm.params.createOrdersStatus"
              :placeholder="$t('GlobalSetObj.all')"
              clearable
              style="width: 100%;"
              @change="handleChange4"
            >
              <el-option
                v-for="(item, ind) in orderStatusList"
                :key="ind"
                :label="item.label"
                :value="item.code"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" style="height: 70px;">
          <!--创建时间-->
          <el-form-item :label="$t('GlobalSetObj.taskCreationTime')">
            <el-date-picker
              v-model="createStartDate"
              :clearable="false"
              :default-time="['00:00:00', '23:59:59']"
              :format="$i18n.locale=='zh'?zhDate:ptDate"
              :placeholder="$t('newOrder.SelectDatetime')"
              range-separator="~"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              @change="handleTime"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="9" style="height: 70px;margin: 10px 0">
          <el-form-item prop="searchType">
            <template slot="label">
              <div class="selectLabel">
                <el-select
                  v-model="queryForm.params.searchType"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  :style="IsWidth"
                  @change="SwitchType"
                >
                  <el-option
                    key="1"
                    :label="`${$t('newOrder.waybillNumber')+'/'+$t('GlobalSetObj.serviceLogistics')}`"
                    value="1"
                  />
                  <el-option key="2" :label="$t('GlobalSetObj.serviceLogistics')" value="2" />
                </el-select>
              </div>
            </template>
            <el-form-item class="multipleQuery">
              <div class="searchMultiple">
                <el-input
                  v-model.trim="queryForm.params.inputValue"
                  :placeholder="$t('orderDetails.pleaseInput')"
                  clearable
                  @keyup.enter.native="getSeacherBtn"
                />
                <div style="width: 40px;">
                  <el-button class="searchBtn" @click="getSeacherBtn">
                    <i class="iconfont icon-chaxun" style="font-size: 24px" />
                  </el-button>
                </div>
                <el-popover
                  v-model="showList"
                  placement="bottom-end"
                  trigger="manual"
                  width="400"
                >
                  <div>
                    <el-input
                      v-model="searchStr"
                      :placeholder="$t('newOrder.PreciseSearch')"
                      :rows="8"
                      type="textarea"
                    />
                    <div style="margin-top: 10px;">
                      <!--清空-->
                      <el-button size="small" @click="clearSearch">{{ $t('newOrder.empty') }}</el-button>
                      <div style="float: right">
                        <!--关闭-->
                        <el-button size="small" @click="openSearchList">{{ $t('orderExport.close') }}</el-button>
                        <!--搜索-->
                        <el-button
                          plain size="small" type="primary"
                          @click="getSeacherBtn(1)"
                        >
                          {{ $t('newOrder.search') }}
                        </el-button>
                      </div>
                    </div>
                  </div>
                  <el-button slot="reference" class="btnIcon" @click="openText">
                    <i class="iconfont icon-liebiao1" style="font-size: 24px" />
                  </el-button>
                </el-popover>
              </div>
            </el-form-item>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row v-show="searchList.length > 0">
      <div class="searchTip">
        <el-tooltip class="item" effect="dark" placement="bottom">
          <template slot="content">
            <pre>{{ searchStr }}</pre>
          </template>
          <div class="searchNumWarp">
            <div class="wordBreak">
              <p>{{
                switchSearchType
              }}：
                {{ searchList.join('，') }}</p>
            </div>
          </div>
        </el-tooltip>
        <div class="clearSearch" @click="clearSearch">
          <i class="el-icon-close" />
        </div>
      </div>
    </el-row>
    <el-row>
      <div style="margin-bottom: 10px">
        <el-button
          size="small" style="margin-bottom: 10px" type="primary"
          @click="manualPushBtn"
        >
          {{ $t('GlobalSetObj.PushManual') }}
        </el-button>
        <!--修改预派送网点-->
        <el-button size="small" type="primary" @click="changeOutlets">{{
          $t('GlobalSetObj.ModifyPreDeliveryLocation')
        }}
        </el-button>
      </div>
    </el-row>
    <!--  表格数据  -->
    <AnjunTableList
      ref="anjunTable"
      :format-time="isTormat"
      :header-list="tableHeaderList"
      :is-select-btn="true"
      :is-tip="true"
      :table-data="tableData"
      @selectTable="selectTable"
    />
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.number"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!--  手动推送  -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('GlobalSetObj.PushManual')"
      :visible.sync="pushDialog"
      width="25%"
    >
      <p style="color: #a4a7ab;">{{ $t('GlobalSetObj.filtration') }}</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelPushOrder">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button type="primary" @click="pushOrder">{{ $t('GlobalSetObj.Salve') }}</el-button>
      </span>
    </el-dialog>
    <!--修改预派送网点-->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('GlobalSetObj.ModifyPreDeliveryLocation')"
      :visible.sync="showChangeOutlets"
      width="500px"
    >
      <modifyPreDeliveryLocation
        :key="showChangeOutlets"
        :selection-list="selectionList"
        :site-id-list="siteIdList"
        @changeOutletsDataClose="changeOutletsDataClose"
      />
    </el-dialog>
  </div>
</template>

<script>
import AnjunTableList from '@/components/anjun-tableList';
import modifyPreDeliveryLocation from './cmp/modifyPreDeliveryLocation';
import { getProviderSiteTree, manualPush, orderWaybillPushPage } from '@/api/logisticsOrders/index.js';
import { apiProviderPage } from '@/api/finance/basicData';
import { searchSiteIdList } from '@/api/lanshou';
import dayjs from 'dayjs';
import { waybillStatusObj } from '@/utils/enumData';

export default {
  name: 'OrderPush1',
  components: {
    AnjunTableList,
    modifyPreDeliveryLocation
  },
  props: {
    supplierMainList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      createStartDate: [this.getTimer().today + ' 00:00:00', this.getTimer().endDate + ' 23:59:59'],
      queryForm: {
        pageSize: 50,
        number: 1,
        total: 0,
        params: {
          createStartTime: this.getTimer().today + ' 00:00:00', // 订单创建时间
          createEndTime: this.getTimer().endDate + ' 23:59:59', // 订单创建时间
          pushType: null,
          searchType: '1',
          pushStatus: null,
          recipientCode: null,
          inputValue: '',
          createOrdersStatus: null
        }
      },
      showList: false,
      searchList: [],
      tableHeaderList: [
        { id: 1, label: this.$t('newOrder.waybillNumber'), prop: 'waybillNumber', cloWidth: '160' },
        {
          id: 7,
          label: this.$t('GlobalSetObj.serviceLogistics'),
          prop: 'logisticsNumber',
          cloWidth: '200',
          clickClass: false
        },
        { id: 2, label: this.$t('newOrder.waybillStatus'), prop: 'statusNamewaybill', cloWidth: '230' },
        { id: 5, label: this.$t('GlobalSetObj.Receptor'), prop: 'recipient', cloWidth: '210' },
        // 操作类型
        { id: 4, label: this.$t('collectionCenter.operationType'), prop: 'pushTypeObjectLabel', cloWidth: '180' },
        // 预派送网点
        { id: 8, label: this.$t('GlobalSetObj.preEntrega'), prop: 'preDeliverStationName', cloWidth: '180' },
        { id: 6, label: this.$t('GlobalSetObj.taskCreationTime'), prop: 'createTime', cloWidth: '190' },
        // 处理状态
        { id: 9, label: this.$t('GlobalSetObj.ProcessingStatus'), prop: 'statusName', cloWidth: '180' },
        // 操作成功时间
        { id: 3, label: this.$t('GlobalSetObj.TheOperationSucceededTime'), prop: 'pushSucceedTime', cloWidth: '190' },
        { id: 10, label: this.$t('GlobalSetObj.orderStatus'), prop: 'createStatus', cloWidth: '160' },
        { id: 11, label: this.$t('orderCenterCont.NumberOfPushes'), prop: 'pushCount', cloWidth: '160' }, // 推送次数
        { id: 12, label: this.$t('GlobalSetObj.ReasonForFailure'), prop: 'errorReason', cloWidth: '160' } // 失败原因
      ],
      tableData: [],
      searchStr: '',
      switchSearchType: this.$t('newOrder.waybillNumber') + '/' + this.$t('GlobalSetObj.serviceLogistics'),
      // 勾选表格数据
      selectionList: [],
      searchNumberType: '1',
      searchNumber: '',
      pushDialog: false, // 推送的弹窗
      showChangeOutlets: false,
      siteIdList: []
    };
  },
  computed: {
    orderStatusList() {
      return [
        { label: this.$i18n.t('newOrder.all'), code: null }, // 全部
        { label: this.$t('GlobalSetObj.succeed'), code: 1 }, // 成功
        { label: this.$t('GlobalSetObj.Lose'), code: 0 } // 失败
      ];
    },
    pushStatusList() {
      return [
        { label: this.$i18n.t('newOrder.all'), code: null }, // 全部
        { label: this.$t('GlobalSetObj.Lose'), code: 3 }, // 失败
        { label: this.$t('GlobalSetObj.succeed'), code: 2 }, // 成功
        { label: this.$t('newOrder.processing'), code: 1 } // 处理中
      ];
    },
    pushStatusObject() {
      return {
        null: { label: this.$i18n.t('newOrder.all'), code: null }, // 全部
        3: { label: this.$t('GlobalSetObj.Falha'), code: 3 }, // 推送失败
        2: { label: this.$t('GlobalSetObj.pushSucess'), code: 2 }, // 推送成功
        1: { label: this.$t('GlobalSetObj.pushing'), code: 1 } // 推送中
      };
    },
    pushTypeObject() { // GlobalSetObj.DeliveryPoint
      return {
        1: { label: this.$t('GlobalSetObj.DeliveryPoint'), code: 1 } // 派送网点
      };
    },
    IsWidth() {
      return {
        width: this.isTormat ? '400px' : '170px'
      };
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    // this.getNewestSite();
    this.getRoleTree();
    this.getSearch();
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    // 关闭修改网点弹窗
    changeOutletsDataClose() {
      this.showChangeOutlets = false;
      this.selectionList = [];
      this.$refs.anjunTable.$refs.multipleTable.clearSelection();
      this.getSearch();
    },
    // 搜索列表
    getSeacherBtn(type) {
      this.queryForm.number = 1;
      this.$refs.anjunTable.toggleSelection();
      this.getSearch(type);
    },
    // 获取服务商网点树
    getRoleTree() {
      const param = {
        providerChannelName: ''
      };
      getProviderSiteTree(param).then(res => {
        if (res.status === 'OK') {
          const listData = (data) => {
            data.map(item => {
              if (item.siteList && item.siteList.length > 0) {
                item.children = item.siteList;
                listData(item.siteList);
              }
            });
            return data;
          };
          this.siteIdList = listData(res.data);
        }
      });
    },
    // 打开修改预派送网点弹窗
    changeOutlets() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('collectionCenter.checkMsg'));
        return;
      }
      this.showChangeOutlets = true;
    },
    getNewestSite() {
      searchSiteIdList().then(res => {
        if (res.status === 'OK') {
          this.siteIdList = res.data;
        }
      });
    },
    // 处理 resize 事件的逻辑
    handleResize(event) {
      var windowWidth = window.innerWidth;
      // var windowHeight = window.innerHeight;
      console.log('窗口大小发生变化', windowWidth);
    },
    // 点击手动推送
    manualPushBtn() {
      if (!this.selectionList.length) {
        this.$message.error(this.$t('GlobalSetObj.selectOrderNumber'));
        return;
      }
      this.pushDialog = true;
    },
    // 取消推送
    cancelPushOrder() {
      this.pushDialog = false;
      this.$refs.anjunTable.toggleSelection();
    },
    // 推送请求
    pushOrder() {
      const data = {
        waybillNumbers: []
      };
      const waybillNumberList = [];
      this.selectionList.forEach(item => {
        if (item.pushStatus !== 2) {
          waybillNumberList.push(item.waybillNumber);
        }
      });
      data.waybillNumbers = waybillNumberList;
      console.log(data, 'datadatadata');
      // return;
      if (!data.waybillNumbers.length) {
        this.$message.warning(this.$t('GlobalSetObj.NonePushFailed'));
        this.pushDialog = false;
        this.$refs.anjunTable.toggleSelection();
        return;
      }
      manualPush(data).then((res) => {
        console.log(res);
        if (res.status === 'OK') {
          this.$message.success(this.$t('GlobalSetObj.pushSucess'));
          this.$refs.anjunTable.toggleSelection();
          this.getSearch();
          this.pushDialog = false;
        }
      }).catch(e => {
        this.pushDialog = false;
        this.$refs.anjunTable.toggleSelection();
      });
    },
    // 列表数据勾选
    selectTable(data) {
      console.log(data, '列表数据勾选');
      this.selectionList = data;
    },
    // 切换
    handleChange1(val) {
      this.queryForm.params.pushType = val;
      this.queryForm.number = 1;
      this.getSeacherBtn();
    },
    handleChange2(val) {
      this.queryForm.params.recipientCode = val;
      this.queryForm.number = 1;
      this.getSeacherBtn();
    },
    handleChange3(val) {
      this.queryForm.params.pushStatus = val;
      this.queryForm.number = 1;
      this.getSeacherBtn();
    },
    handleChange4(val) {
      this.queryForm.params.createOrdersStatus = val;
      this.queryForm.number = 1;
      this.getSeacherBtn();
    },
    // 切换搜索类型
    SwitchType(val) {
      if (val === '1') {
        this.switchSearchType = this.$t('newOrder.waybillNumber') + '/' + this.$t('GlobalSetObj.serviceLogistics');
      } else {
        this.switchSearchType = this.$t('GlobalSetObj.serviceLogistics');
      }
    },
    // 清除列表搜索
    clearSearch() {
      this.searchList = [];
      this.searchStr = '';
    },
    // 打开\关闭多个单号输入
    openSearchList() {
      this.showList = !this.showList;
      this.queryForm.params.inputValue = '';
    },
    // 打开或者关闭
    openText() {
      this.showList = !this.showList;
    },
    // 查询
    getSearch(type) {
      this.searchList = [];
      const numberList = this.searchStr.split('\n');
      numberList.map(item => {
        if (item) {
          item.toString().trim();
          if (!this.searchList.includes(item)) {
            this.searchList.push(item);
          }
        }
      });
      if (type === 1) {
        this.queryForm.params.inputValue = '';
        this.queryForm.number = 1;
        this.showList = false;
      }
      let inputValList = [];
      if (this.queryForm.params.inputValue) {
        this.queryForm.number = 1;
        inputValList.push(this.queryForm.params.inputValue);
      } else {
        inputValList = [...this.searchList];
      }
      const data = {};
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.number;
      data.params = {};
      if (this.createStartDate && this.createStartDate.length > 0) {
        data.params.createStartTime = this.createStartDate[0];
        data.params.createEndTime = this.createStartDate[1];
      } else {
        data.params.createStartTime = '';
        data.params.createEndTime = '';
      }
      data.params.pushType = this.queryForm.params.pushType;
      data.params.pushStatus = this.queryForm.params.pushStatus;
      data.params.recipientCode = this.queryForm.params.recipientCode;
      data.params.createOrdersStatus = this.queryForm.params.createOrdersStatus;
      if (this.queryForm.params.searchType === '1') {
        data.params.waybillNumbers = inputValList;
      } else {
        data.params.logisticsNumbers = inputValList;
      }
      orderWaybillPushPage(data).then(res => {
        if (res.status === 'OK') {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
          this.tableData.map(item => {
            if (this.pushStatusObject[item.pushStatus].label) {
              item.statusName = this.pushStatusObject[item.pushStatus].label;
            }
            if (waybillStatusObj[item.waybillStatus]?.label) {
              item.statusNamewaybill = waybillStatusObj[item.waybillStatus].label;
            }
            if (this.pushTypeObject[item.pushType].label) {
              item.pushTypeObjectLabel = this.pushTypeObject[item.pushType].label;
            }
            if (item.createOrdersStatus !== null) {
              item.createStatus = item.createOrdersStatus === 1 ? this.$t('GlobalSetObj.succeed') : this.$t('GlobalSetObj.Lose');
            }
          });
          console.log(this.tableData, 'this.tableDatathis.tableData  ');
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.number = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.number = val;
      this.getSearch();
    },
    // 获取最近一星期时间
    getTimer() {
      const day = new Date();
      const brazilTime = day.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
      const datae = new Date(brazilTime);
      console.log(datae, '===datae');
      datae.setTime(datae.getTime() - 14 * 24 * 60 * 60 * 1000);
      console.log(datae);
      const today = dayjs(datae).format('YYYY-MM-DD');
      const endDate = dayjs(brazilTime).format('YYYY-MM-DD');
      console.log(today, endDate);
      return {
        today,
        endDate
      };
    },
    // 改变时间
    handleTime(val) {
      this.queryForm.number = 1;
      this.getSeacherBtn();
    },
    /**
     * @description 获取接收方列表
     * @param name
     */
    getProviderPage(name = '') {
      const param = {
        pageSize: 10,
        pageNumber: 1,
        params: {
          providerName: name,
          state: 1
        }
      };
      apiProviderPage(param, false).then(res => {
        if (res.status === 'OK') {
          this.supplierMainList = res.data.records;
        }
      });
    }
  }
};
</script>

<style scoped>
.app-container >>> .el-dialog__body {
  padding: 10px 20px;
}

.multipleQuery ::v-deep .el-form-item__content {
  margin-left: 0 !important;
}

.btnIcon {
  padding: 6px;
}

.searchMultiple {
  display: flex;
}

.searchMultiple .el-popover__reference-wrapper .btnIcon {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 40px;
}

.app-container >>> .searchMultiple .el-input__inner {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.app-container >>> .searchMultiple .el-input-group__prepend {
  background-color: transparent !important;
}

.searchBtn {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  width: 40px;
  height: 40px;
  padding: 7px;
}

.searchTip {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 15px;
  height: 28px;
  cursor: pointer;
}

.searchNumWarp {
  height: 28px;
  border: 1px solid #91B8FA;
  border-right: none;
  padding: 5px 5px 5px 5px;
  background: rgba(145, 184, 250, 0.2);
}

.searchNumWarp p {
  margin: 0;
}

.app-container >>> .drawerClass header {
  border-bottom: 1px solid #ccc !important;
  margin-bottom: 0 !important;
  padding: 20px 20px 20px !important;
}

.clearSearch {
  height: 28px;
  border: 1px solid #91B8FA;
  border-left: none;
  background: rgba(145, 184, 250, 0.2);
  cursor: pointer;
  padding-right: 5px;
  padding-top: 5px;
}

.wordBreak {
  word-break: break-all; /*word-break: normal|break-all|keep-all;在恰当的断字点进行换行,break-all 允许在单词内换行,keep-all 只能在半角空格或连字符处换行,normal 使用浏览器默认的换行规则。*/
  text-overflow: ellipsis; /*溢出文字显示省略号*/
  display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 1; /** 显示的行数 **/
  overflow: hidden; /** 隐藏超出的内容 **/
}

.app-container >>> .el-form--label-top .el-form-item__label {
  width: 100% !important;
}

.app-container >>> .selectLabel .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

.app-container >>> .selectLabel .el-input__icon {
  line-height: 28px !important;
}

.app-container >>> .packageList .el-input-group__append {
  background-color: #409EFF;
  border: 1px solid #409EFF;
  color: #fff;
}

.selectType ::v-deep.el-input--suffix .el-input__inner {
  border: none !important;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type='number'] {
  -moz-appearance: textfield !important;
}
</style>
