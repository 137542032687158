<template>
  <div class="app-container">
    <div style="position:relative;min-height: 40px;">
      <el-button
        v-if="queryBoxShow"
        class="putAway"
        size="small"
        @click="queryBoxShow = !queryBoxShow"
      >{{ $t('Resource.CollapseQuery') }}
      </el-button>
      <el-button
        v-else
        class="putAway"
        size="small"
        @click="queryBoxShow = !queryBoxShow"
      >{{ $t('Resource.ExpandQuery') }}
      </el-button>
      <div
        v-show="queryBoxShow"
        class="searchContainer"
      >
        <el-form
          ref="queryForm"
          :model="queryForm.params"
          class="queryFormClass"
          size="small"
          @submit.native.prevent
        >
          <el-row
            :gutter="20" align="center" justify="left"
            style="flex-wrap: wrap;" type="flex"
          >
            <el-col :span="8" style="width: auto;">
              <!-- 审核状态 -->
              <el-form-item :label="$t('Resource.ApprovalStatus') + '：'">
                <el-radio-group v-model="queryForm.params.verifyStatus" size="large">
                  <!-- 全部 -->
                  <el-radio-button label="">{{ $t('customerManagements.all') }}</el-radio-button>
                  <!-- 审核中 -->
                  <el-radio-button :label="0">{{ $t('Resource.underReview') }}</el-radio-button>
                  <!-- 已审核 -->
                  <el-radio-button :label="1">{{ $t('Resource.Approved') }}</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8" style="width: auto;">
              <!-- 名称 -->
              <el-form-item :label="$t('Resource.name') + '：'">
                <el-input
                  v-model="queryForm.params.operationModule"
                  :placeholder="$t('Resource.pleaseInput')"
                  clearable
                  style="width:240px;"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8" style="width: auto;">
              <el-button
                v-permit:remove="'but:system-manage:exportList:find'"
                icon="el-icon-search"
                size="medium"
                type="primary"
                @click="getData"
              >{{ $t('operationCenter.Procurar') }}
              </el-button>
              <el-button
                icon="el-icon-refresh"
                size="medium"
                @click="queryFormReset"
              >{{ $t('operationCenter.reset') }}
              </el-button>
            </el-col>
          </el-row>
        </el-form>
        <el-divider />
      </div>
    </div>
    <el-table
      :data="tableData"
      border
    >
      <el-table-column
        :label="$t('Resource.ind')"
        align="center"
        width="50"
      >
        <template slot-scope="scope">
          <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <!-- 文档导出状态 -->
      <el-table-column
        :label="$t('Resource.statusOfExportFile')"
        prop="verifyName"
      >
        <template slot-scope="scope">
          <!-- 已完成 -->
          <el-tag
            v-if="scope.row.fileUrl !== ''"
            type="success"
          >{{ $t('Resource.completed') }}
          </el-tag>
          <!-- 导出失败 -->
          <el-tag
            v-else-if="(scope.row.fileUrl === '') && (scope.row.exportResult)"
            type="danger"
          >{{ $t('Resource.exportErr') }}
          </el-tag>
          <!-- 正在导出 -->
          <el-tag
            v-else
            type="info"
          >{{ $t('Resource.exporting') }}
          </el-tag>
        </template>
      </el-table-column>
      <!-- 文档导出结果 -->
      <el-table-column
        :label="$t('Resource.documentExportResults')"
        prop="exportResult"
      />
      <!-- 文件名称 -->
      <el-table-column
        :label="$t('Resource.fileName')"
        prop="operationModule"
      />
      <!-- 文件类型 -->
      <el-table-column
        :label="$t('Resource.fileType')"
        prop="fileType"
      />
      <!-- 申请人 -->
      <el-table-column
        :label="$t('Resource.applicant')"
        prop="operator"
      />
      <!-- 申请日期 -->
      <el-table-column
        :label="$t('Resource.DateOfRequest')"
        prop="createTime"
        width="160"
      />
      <!-- 操作 -->
      <el-table-column
        :label="$t('operationCenter.operate')"
        fixed="right"
        width="145"
      >
        <template slot-scope="scope">
          <!-- 确定同意审核？ -->
          <el-popconfirm
            :title="$t('Resource.confirmCheck')"
            @confirm="asyncVerify(scope.row)"
          />
          <el-button
            v-if="scope.row.isDownload && isDownload && (scope.row.fileUrl.length !== 0)"
            size="mini"
            type="info"
            @click="handleDownload(scope.row.newFileUrl)"
          >{{ $t('Resource.download') }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <!-- 分页工具 -->
      <el-pagination
        :current-page.sync="queryForm.pageNumber"
        :page-size.sync="queryForm.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 10px 0"
        @current-change="getData"
        @size-change="getData"
      />
    </div>
  </div>
</template>

<script>
import { apiFileExportPage, apiFileExportVerify } from '@/api/resources';
import { getRSA } from '@/utils/RSA_util';
import { setHttpTurnHttps } from '@/utils/tools';

export default {
  data() {
    return {
      total: 0,
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          createUser: '',
          verifyStatus: '',
          operationModule: ''
        }
      },
      tableData: [],
      queryBoxShow: true,
      userObj: {
        ids: 0,
        name: ''
      },
      isDownload: false, // 当前页面是否有下载权限
      isExamine: false // 当前页面是否有审核权限
    };
  },

  created() {
    const { id, realName } = this.$store.state.user;
    this.userObj.ids = id;
    this.userObj.name = realName;

    const buttonPermissions = this.$store.getters.button_permissions;
    // 当前页面是否有审核按钮
    this.isExamine = buttonPermissions.includes('but:system-manage:exportList:examine');
    this.isDownload = buttonPermissions.includes('but:system-manage:exportList:download');

    if (this.isExamine) {
      // 审核人员能看到所有审批
      this.queryForm.params.createUser = '';
    } else {
      this.queryForm.params.createUser = id;
    }

    this.getData();
  },

  methods: {
    getData() {
      apiFileExportPage(this.queryForm)
        .then((res) => {
          if (res.status === 'OK') {
            let errTip = false;
            const { records, total } = res.data;
            this.total = total;
            this.tableData = records.map((item, index) => {
              const { isVerify, verifyStatus, fileUrl, exportResult = '' } = item;
              if (fileUrl === '' && exportResult && this.queryForm.pageNumber === 1 && index === 0) {
                errTip = true;
              }
              let isExamine = false;
              // 需要 审核 && 审核中 && 没有错误提示
              if (isVerify === 1 && verifyStatus === 0) {
                isExamine = true;
              }
              // 是否显示下载按钮
              let isDownload = false;
              if (this.isExamine && fileUrl !== '' && exportResult) {
                isDownload = true;
              }
              if (verifyStatus === 1 && fileUrl !== '') {
                isDownload = true;
              }

              if (!isExamine) {
                // 不需要审核
                isDownload = true;
              }

              let newFileUrl = '';
              if (fileUrl.length) {
                // 解密后的路径
                newFileUrl = getRSA(fileUrl, ',');
              }
              return {
                ...item,
                isExamine,
                newFileUrl,
                isDownload,
                exportResult
              };
            });
            if (errTip) {
              this.$message({
                message: this.$t('collectionCenter.exportFailure'), // 未导出成功，请点击搜索刷新或重新导出
                type: 'warning',
                offset: 150
              });
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    /**
     * 重置
     */
    queryFormReset() {
      this.queryForm.params.verifyStatus = '';
      this.queryForm.params.operationModule = '';
      this.getData();
    },

    /**
     * 审核
     */
    asyncVerify(obj) {
      const { id } = obj;
      apiFileExportVerify(id, this.userObj.ids, this.userObj.name)
        .then((res) => {
          if (res.status === 'OK') {
            this.$message({
              // '审核成功'
              message: this.$t('Resource.AuditSuccess'),
              type: 'success'
            });
            this.getData();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     * 文件下载
     */
    handleDownload(url) {
      if (url === 'ok') {
        this.$message({
          // '导入成功无错误数据'
          message: this.$t('Resource.importSuccessNotErr'),
          type: 'warning'
        });
        return;
      }
      if (url.length) {
        const a = document.createElement('a');
        a.href = setHttpTurnHttps(url);
        a.click();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.queryFormClass ::v-deep .el-form-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.queryFormClass ::v-deep .el-form-item__label {
  padding: 0;
  white-space: nowrap;
  margin-left: 20px;
}

.queryFormClass ::v-deep .el-form-item__content {
  margin-left: 0 !important;
}

.queryFormClass ::v-deep .el-input__inner {
  height: 40px;
}

.mr10 {
  margin-right: 10px;
}

.putAway {
  position: absolute;
  right: 0;
  top: 0;
  color: #1989fa;
  cursor: pointer;
  z-index: 100;
}
</style>
