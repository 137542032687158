<script >
import { ref, defineProps } from '@vue/composition-api';
import { default as FormGroup } from './steps/formGroup';
import { default as FormEdition } from './steps/formEdition';
import { useUpdateEdition } from '@/api/reverse/edition/query';
import { useWizardForm } from '@/composable/useWizardForm';
const __sfc_main = {};
__sfc_main.props = {
  closeModal: {
    type: Function
  },
  edition: {
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const STEPS_TOTAL = 5;
  const formData = ref({
    codGroup: props.edition.codGroup,
    listEditions: [{
      codEdition: props.edition.firstCod,
      volume: props.edition.firstVoulume,
      description: props.edition.firstDescription
    }, {
      codEdition: props.edition.secondCod,
      volume: props.edition.secondVoulume,
      description: props.edition.secondDescription
    }, {
      codEdition: props.edition.thirdCod,
      volume: props.edition.thirdVoulume,
      description: props.edition.thirdDescription
    }, {
      codEdition: props.edition.fourthCod,
      volume: props.edition.fourthVoulume,
      description: props.edition.fourthDescription
    }]
  });
  const {
    mutate,
    isLoading
  } = useUpdateEdition();
  async function handleSubmit() {
    mutate({
      id: props.edition.codGroup,
      newEdition: formData.value
    }, {
      onSuccess: () => {
        props.closeModal();
      }
    });
  }
  function handleChangeCodGroup(newCodGroup) {
    formData.value.codGroup = newCodGroup;
  }
  const {
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  } = useWizardForm(formData, handleSubmit, STEPS_TOTAL);
  return {
    formData,
    isLoading,
    handleChangeCodGroup,
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  };
};
__sfc_main.components = Object.assign({
  FormGroup,
  FormEdition
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <FormGroup
      v-show="step === 0"
      :is-edit="true"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      :cod-group="formData.codGroup"
      @codGroupChanged="handleChangeCodGroup"
    />

    <FormEdition
      v-for="(edition, index) in formData.listEditions"
      v-show="step === (index + 1)"
      :key="index"
      :number-edition="index+1"
      :form-data="edition"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
    />

    <o-loading :full-page="true" :active.sync="isLoading" />
  </div>
</template>
