<template>
  <div class="messageCon">
    <div style="text-align: right; line-height: 25px; padding: 5px">
      <i
        class="el-icon-copy-document"
        style="font-size: 20px; cursor: pointer"
        :title="$t('orderCenterCont.copy')"
        @click="copyData(jsonStrin)"
      />
    </div>
    <pre>{{ jsonStrin | setStyle }}</pre>
  </div>
</template>

<script>
export default {
  name: '',

  filters: {
    setStyle(val) {
      if (val === '') return '';

      return JSON.stringify(
        JSON.parse(val),
        null,
        2
      );
    }
  },

  props: {
    jsonStrin: {
      type: String,
      default: ''
    }
  },

  methods: {
    // 复制
    copyData(data) {
      navigator.clipboard.writeText(data).then(() => {
        this.$message.success(this.$i18n.t('orderCenterCont.ReplicationSucceeded')); // '复制成功'
      }).catch((err) => { console.error(err); });

      // const input = document.getElementById('message2');
      // input.value = data; // 修改文本框的内容
      // input.select(); // 选中文本
      // document.execCommand('copy'); // 执行浏览器复制命令
    }
  }

};
</script>

<style  scoped>
.messageCon {
  background: #eee;
}
.messageCon pre {
  max-height: calc(100vh - 280px);
  overflow-y: auto;
  white-space: break-spaces;
  margin: 0;
  padding: 0 10px;
  line-height: 26px;
}
</style>
