var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "permissionBox" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "PC", name: "PCpage" } }, [
            _c(
              "div",
              { staticClass: "permissionClass" },
              [_c("positionManage")],
              1
            ),
          ]),
          _c("el-tab-pane", { attrs: { label: "PDA", name: "PDApage" } }, [
            _c(
              "div",
              { staticClass: "permissionClass" },
              [_c("pdaPermission")],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }