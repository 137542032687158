var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("FormPersonalData", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.step === 0,
            expression: "step === 0",
          },
        ],
        attrs: {
          "handle-next": _vm.handleNextStep,
          "handle-previous": _vm.handlePreviousStep,
          "is-last-step": _vm.isLastStep,
          "is-first-step": _vm.isFirstStep,
          "form-data": _vm.formData.formStep01,
          "is-edit": true,
        },
        on: {
          "update:formData": function ($event) {
            return _vm.$set(_vm.formData, "formStep01", $event)
          },
          "update:form-data": function ($event) {
            return _vm.$set(_vm.formData, "formStep01", $event)
          },
        },
      }),
      _c("FormAddress", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.step === 1,
            expression: "step === 1",
          },
        ],
        attrs: {
          "handle-next": _vm.handleNextStep,
          "handle-previous": _vm.handlePreviousStep,
          "is-last-step": _vm.isLastStep,
          "is-first-step": _vm.isFirstStep,
          "form-data": _vm.formData.formStep02,
          "is-edit": true,
        },
        on: {
          "update:formData": function ($event) {
            return _vm.$set(_vm.formData, "formStep02", $event)
          },
          "update:form-data": function ($event) {
            return _vm.$set(_vm.formData, "formStep02", $event)
          },
        },
      }),
      _c("o-loading", {
        attrs: { "full-page": true, active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }