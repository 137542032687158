// 重算
export const COLLECTING_DISPATCH_OBJ = {
  businessType: 2, // 业务类型 2 揽收 3 派送
  payableType: 2, // 账单类型 1 安俊 2 第三方 3 AJD 4 DSP
  collectType: null, // 揽收方式 2上门揽收 1上门交件
  siteIds: [], // 网点id列表
  siteType: '',
  startTime: '', // 业务开始时间
  endTime: '', // 业务结束时间
  startDate: '', 
  endDate: '', 
};
