var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Box" },
    [
      _vm.changeVal == "hz"
        ? _c(
            "div",
            { staticStyle: { "margin-bottom": "20px" } },
            [
              _c(
                "el-form",
                [
                  _c(
                    "el-row",
                    {
                      staticClass: "selectClass",
                      staticStyle: { "flex-wrap": "wrap" },
                      attrs: { type: "flex", justify: "start" },
                    },
                    [
                      _c(
                        "el-col",
                        { staticClass: "col2" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "autoWidth",
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                              },
                              on: { change: _vm.changeTime },
                              model: {
                                value: _vm.selectTime,
                                callback: function ($$v) {
                                  _vm.selectTime = $$v
                                },
                                expression: "selectTime",
                              },
                            },
                            [
                              _c("template", { slot: "prefix" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      (
                                        _vm.timeList.find(function (item) {
                                          return item.value === _vm.selectTime
                                        }) || {}
                                      ).time
                                    ) +
                                    " "
                                ),
                              ]),
                              _vm._l(_vm.timeList, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.time,
                                    value: item.value,
                                  },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { staticClass: "delMl" },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  clearable: false,
                                  type: "daterange",
                                  "value-format": "yyyy-MM-dd",
                                  "default-time": ["00:00:00", "23:59:59"],
                                  "range-separator": _vm.$t(
                                    "collectionCenter.to"
                                  ),
                                  "start-placeholder": _vm.$t(
                                    "collectionCenter.StartTime"
                                  ),
                                  "end-placeholder": _vm.$t(
                                    "collectionCenter.EndTime"
                                  ),
                                },
                                on: { change: _vm.dateChange },
                                model: {
                                  value: _vm.timeValue,
                                  callback: function ($$v) {
                                    _vm.timeValue = $$v
                                  },
                                  expression: "timeValue",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("collectionCenter.CustomerN"),
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "collectionCenter.pleaseChoose"
                                    ),
                                    clearable: "",
                                    filterable: "",
                                  },
                                  on: { focus: _vm.getNewestCustomer },
                                  model: {
                                    value: _vm.queryForm.params.customerId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm.params,
                                        "customerId",
                                        $$v
                                      )
                                    },
                                    expression: "queryForm.params.customerId",
                                  },
                                },
                                _vm._l(
                                  _vm.customerIdList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.username,
                                        value: item.id,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "collectionCenter.PlatformWhichBelongs"
                                ),
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    placeholder: _vm.$t(
                                      "collectionCenter.pleaseChoose"
                                    ),
                                  },
                                  model: {
                                    value: _vm.queryForm.params.platform,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm.params,
                                        "platform",
                                        $$v
                                      )
                                    },
                                    expression: "queryForm.params.platform",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "shein", value: 1 },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "kwai", value: 2 },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t(
                                        "collectionCenter.expressDelivery"
                                      ),
                                      value: 3,
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("collectionCenter.other"),
                                      value: 0,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label:
                                  _vm.$t("collectionCenter.receivingMode") +
                                  "：",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    placeholder: _vm.$t(
                                      "collectionCenter.pleaseChoose"
                                    ),
                                  },
                                  model: {
                                    value: _vm.queryForm.params.collectType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm.params,
                                        "collectType",
                                        $$v
                                      )
                                    },
                                    expression: "queryForm.params.collectType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("collectionCenter.dropOff"),
                                      value: 1,
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t(
                                        "collectionCenter.toCollect"
                                      ),
                                      value: 2,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.selectTime == "cq1"
                        ? _c(
                            "el-col",
                            {
                              staticStyle: { width: "auto" },
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label:
                                      _vm.$t(
                                        "collectionCenter.actualCollectionMode"
                                      ) + "：",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        filterable: "",
                                        placeholder: _vm.$t(
                                          "collectionCenter.pleaseChoose"
                                        ),
                                      },
                                      model: {
                                        value:
                                          _vm.queryForm.params.collectModel,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "collectModel",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.collectModel",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "APP", value: 0 },
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "PC", value: 1 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("collectionCenter.stateOri"),
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: "",
                                  placeholder: _vm.$t(
                                    "collectionCenter.BatchQuery"
                                  ),
                                },
                                on: { blur: _vm.ftS },
                                nativeOn: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.BlockNewlineState($event)
                                  },
                                },
                                model: {
                                  value: _vm.queryForm.params.state,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryForm.params, "state", $$v)
                                  },
                                  expression: "queryForm.params.state",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("collectionCenter.sendingCt"),
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: "",
                                  placeholder: _vm.$t(
                                    "collectionCenter.BatchQuery"
                                  ),
                                },
                                on: { blur: _vm.ftC },
                                nativeOn: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.BlockNewlineCity($event)
                                  },
                                },
                                model: {
                                  value: _vm.queryForm.params.city,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryForm.params, "city", $$v)
                                  },
                                  expression: "queryForm.params.city",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { type: "flex", justify: "space-between" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permit",
                              rawName: "v-permit:remove",
                              value:
                                "btn:collection-center:report:collect-order:find",
                              expression:
                                "'btn:collection-center:report:collect-order:find'",
                              arg: "remove",
                            },
                          ],
                          attrs: {
                            type: "primary",
                            size: "mini",
                            icon: "el-icon-search",
                          },
                          on: { click: _vm.searchClick },
                        },
                        [_vm._v(_vm._s(_vm.$t("collectionCenter.search")))]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permit",
                              rawName: "v-permit:remove",
                              value:
                                "btn:collection-center:report:collect-order:reset",
                              expression:
                                "'btn:collection-center:report:collect-order:reset'",
                              arg: "remove",
                            },
                          ],
                          attrs: { icon: "el-icon-refresh", size: "mini" },
                          on: { click: _vm.resetClick },
                        },
                        [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")))]
                      ),
                      _vm.changeVal == "hz"
                        ? _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permit",
                                  rawName: "v-permit:remove",
                                  value:
                                    "btn:collection-center:report:collect-order:allExport",
                                  expression:
                                    "'btn:collection-center:report:collect-order:allExport'",
                                  arg: "remove",
                                },
                              ],
                              class: _vm.isClickAll1 ? "onlyClickOnce10" : "",
                              attrs: { icon: "el-icon-upload", size: "mini" },
                              on: { click: _vm.downloadAll },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("collectionCenter.allExport"))
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.changeVal == "mx"
        ? _c(
            "div",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value:
                        "btn:collection-center:report:collect-order:allExportMX",
                      expression:
                        "'btn:collection-center:report:collect-order:allExportMX'",
                      arg: "remove",
                    },
                  ],
                  class: _vm.isClickAll2 ? "onlyClickOnce10" : "",
                  attrs: { icon: "el-icon-upload", size: "mini" },
                  on: { click: _vm.downloadAllMX },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")))]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("el-divider"),
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          model: {
            value: _vm.changeVal,
            callback: function ($$v) {
              _vm.changeVal = $$v
            },
            expression: "changeVal",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: { label: _vm.$t("collectionCenter.summary"), name: "hz" },
            },
            [
              _c(
                "el-table",
                {
                  ref: "tableList",
                  attrs: {
                    "max-height": 600,
                    border: "",
                    "show-summary": "",
                    "summary-method": _vm.getSummaries,
                    data: _vm.tableDate,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("collectionCenter.ind"),
                      type: "index",
                      width: "60",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  (_vm.queryForm.pageNumber - 1) *
                                    _vm.queryForm.pageSize +
                                    scope.$index +
                                    1
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "platformName",
                      label: _vm.$t("collectionCenter.platform"),
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "customerId",
                      label: _vm.$t("collectionCenter.clientID"),
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "customerName",
                      label: _vm.$t("collectionCenter.CustomerName"),
                    },
                  }),
                  _vm.cacheSTime == "cq0"
                    ? _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "createDate",
                          label: _vm.$t("collectionCenter.orderCreateT"),
                        },
                      })
                    : _vm._e(),
                  _vm.cacheSTime == "cq1"
                    ? _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "collectDate",
                          label: _vm.$t(
                            "collectionCenter.ActualCollectionTime"
                          ),
                        },
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "collectTypeName",
                      label: _vm.$t("collectionCenter.receivingMode"),
                    },
                  }),
                  _vm.cacheSTime == "cq1"
                    ? _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "collectModel",
                          label: _vm.$t(
                            "collectionCenter.actualCollectionMode"
                          ),
                        },
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "state",
                      label: _vm.$t("collectionCenter.state"),
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "city",
                      label: _vm.$t("collectionCenter.city"),
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "orderNum",
                      label: _vm.$t("collectionCenter.orderNum"),
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "collectNum",
                      label: _vm.$t("collectionCenter.collectNum"),
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "unreceivedNum",
                      label: _vm.$t("collectionCenter.unreceivedNum"),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.cacheSTime == "cq0" && _vm.hzTomx
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.detailClick(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row.unreceivedNum))]
                                )
                              : _c("div", [
                                  _vm._v(_vm._s(scope.row.unreceivedNum)),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c("el-pagination", {
                    staticStyle: { margin: "10px 0" },
                    attrs: {
                      layout: "total, sizes, prev, pager, next, jumper",
                      "current-page": _vm.queryForm.pageNumber,
                      "page-sizes": [10, 20, 50, 100, 500, 1000],
                      "page-size": _vm.queryForm.pageSize,
                      total: _vm.total,
                      background: "",
                    },
                    on: {
                      "update:currentPage": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageNumber", $event)
                      },
                      "update:current-page": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageNumber", $event)
                      },
                      "update:pageSize": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageSize", $event)
                      },
                      "update:page-size": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageSize", $event)
                      },
                      "current-change": _vm.getTable,
                      "size-change": _vm.getPageSize,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.changeVal == "mx"
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("collectionCenter.particulars"),
                    name: "mx",
                  },
                },
                [
                  _c(
                    "el-table",
                    {
                      ref: "tableList",
                      attrs: {
                        "max-height": 600,
                        border: "",
                        "show-summary": "",
                        "summary-method": _vm.getSummariesMX,
                        data: _vm.tableDateMX,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "55" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("collectionCenter.ind"),
                          type: "index",
                          width: "60",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        (_vm.queryFormMX.pageNumber - 1) *
                                          _vm.queryFormMX.pageSize +
                                          scope.$index +
                                          1
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2346069176
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "merchantCode",
                          label: _vm.$t("collectionCenter.MerchantNum"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "merchantName",
                          label: _vm.$t("collectionCenter.MerchantName"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "unreceivedNum",
                          label: _vm.$t("collectionCenter.unreceivedOrder"),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-pagination", {
                        staticStyle: { margin: "10px 0" },
                        attrs: {
                          layout: "total, sizes, prev, pager, next, jumper",
                          "current-page": _vm.queryFormMX.pageNumber,
                          "page-sizes": [10, 20, 50, 100, 500, 1000],
                          "page-size": _vm.queryFormMX.pageSize,
                          total: _vm.totalMX,
                          background: "",
                        },
                        on: {
                          "update:currentPage": function ($event) {
                            return _vm.$set(
                              _vm.queryFormMX,
                              "pageNumber",
                              $event
                            )
                          },
                          "update:current-page": function ($event) {
                            return _vm.$set(
                              _vm.queryFormMX,
                              "pageNumber",
                              $event
                            )
                          },
                          "update:pageSize": function ($event) {
                            return _vm.$set(_vm.queryFormMX, "pageSize", $event)
                          },
                          "update:page-size": function ($event) {
                            return _vm.$set(_vm.queryFormMX, "pageSize", $event)
                          },
                          "current-change": _vm.getTableMX,
                          "size-change": _vm.getPageSizeMX,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }