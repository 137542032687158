<template>
  <div class="Box">
    <el-form class="selectClass">
      <el-row>
        <el-col :span="10" style="width: auto;">
          <!-- 创建时间： -->
          <el-form-item :label="$t('collectionCenter.createTime')">
            <el-date-picker
              v-model="timeValue"
              :clearable="false"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :range-separator="$t('collectionCenter.to')"
              :start-placeholder="$t('collectionCenter.StartTime')"
              :end-placeholder="$t('collectionCenter.EndTime')"
              @change="dateChange"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('collectionCenter.region') + '：'">
            <el-select
              v-model="queryForm.params.bigArea"
              clearable
              filterable
              :remote-method="remoteMethod"
              remote
              reserve-keyword
            >
              <el-option
                v-for="(item, index) in bigAreaList"
                :key="index"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row>
      <el-col :span="24">
        <el-button
          v-permit:remove="'btn:collection-center:collection-time-configuration:find'"
          type="primary"
          size="mini"
          icon="el-icon-search"
          @click="searchClick"
        >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
        <el-button
          v-permit:remove="'btn:collection-center:collection-time-configuration:reset'"
          icon="el-icon-refresh"
          size="mini"
          @click="resetClick"
        >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
        <el-button
          v-permit:remove="'btn:collection-center:collection-time-configuration:add'"
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="addClick"
        >{{ $t('collectionCenter.add') }}</el-button><!-- 新增 -->
        <el-button
          v-permit:remove="'btn:collection-center:collection-time-configuration:exportAll'"
          icon="el-icon-upload"
          size="mini"
          :class="isClickAll ? 'onlyClickOnce10' : ''"
          @click="downloadAll"
        >{{ $t('collectionCenter.allExport') }}</el-button><!-- 全部导出 -->
      </el-col>
    </el-row>
    <el-divider />
    <el-table
      ref="tableList"
      :max-height="600"
      border
      :data="tableData"
    >
      <el-table-column
        type="selection"
        width="55"
      />
      <!-- 序号 -->
      <el-table-column
        :label="$t('collectionCenter.ind')" type="index" width="60"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="nameDesc"
        :label="$t('collectionCenter.prescriptionName')"
      /><!-- 时效名称 -->
      <el-table-column
        align="center"
        prop="bigArea"
        :label="$t('collectionCenter.region')"
      /><!-- 大区 -->
      <el-table-column
        align="center"
        prop="standard"
        :label="$t('collectionCenter.ageingStandardValue')"
      /><!-- 时效标准值（单位：h） -->
      <el-table-column
        align="center"
        prop="createTime"
        :label="$t('CenterForSorting.CreationTime')"
      /><!-- 创建时间 -->
      <el-table-column
        align="center"
        prop="createUserName"
        :label="$t('CenterForSorting.creator')"
      /><!-- 创建人 -->
      <el-table-column
        align="center"
        prop="updateTime"
        :label="$t('CenterForSorting.ModificationTime')"
      /><!-- 修改时间 -->
      <el-table-column
        align="center"
        prop="updateUserName"
        :label="$t('CenterForSorting.ModifiedBy')"
      /><!-- 修改人 -->
      <el-table-column
        v-if="edit || del"
        fixed="right"
        align="center"
        :label="$t('collectionCenter.operation')"
        width="120"
      >
        <template slot-scope="scope">
          <div class="widthClass">
            <div v-if="edit" style="cursor: pointer;margin-right: 10px;color: #26b3f1;" @click="editClick(scope.row)">{{ $t('collectionCenter.modify') }}</div>
            <div v-if="del">
              <el-popconfirm
                :title="$t('collectionCenter.deleteSure')"
                @confirm="delRow(scope.row,scope.$index)"
              ><!-- 确定删除吗？ -->
                <div slot="reference" style="cursor: pointer;color: #26b3f1;">{{ $t('collectionCenter.Delete') }}</div><!-- 删除 -->
              </el-popconfirm>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100, 500, 1000]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getDatePage"
        @size-change="getPageSize"
      />
    </div>

    <el-dialog
      :close-on-click-modal="true"
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="40%"
      @close="closeDialog"
    >
      <el-form
        ref="submitRef"
        :model="submitForm"
        :rules="submitRules"
        class="selectClass"
      >
        <el-row>
          <el-col :span="24">
            <!-- 时效名称 -->
            <el-form-item :label="$t('collectionCenter.prescriptionName') + '：'" prop="name">
              <el-select
                v-model="submitForm.name"
                clearable
                filterable
              >
                <el-option
                  v-for="(item, index) in nameList"
                  :key="index"
                  :label="item.value"
                  :value="item.key"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <!-- 时效标准值 -->
            <el-form-item :label="$t('collectionCenter.ageingStandardValue') + '：'" prop="standard">
              <el-input v-model="submitForm.standard" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item :label="$t('collectionCenter.region') + '：'" prop="bigArea">
              <el-select
                v-model="submitForm.bigArea"
                clearable
                filterable
                :remote-method="remoteMethod"
                remote
                reserve-keyword
              >
                <el-option
                  v-for="(item, index) in bigAreaList"
                  :key="index"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div style="text-align: right;">
        <!-- 确定 -->
        <el-button
          type="primary"
          size="small"
          @click="submit"
        >
          {{ $t('userManage.confirm') }}
        </el-button>
        <el-button
          size="small"
          @click="closeDialog"
        >
          <!-- 取消 -->
          {{ $t('userManage.cancel') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  collectionTimePage,
  collectionTimeExport,
  collectionTimeDel,
  collectionTimeAdd,
  collectionTimeUpdate,
  getAgeingType,
  getBigArea
} from '@/api/lanshou';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'CollectionTimeConfiguration',
  data() {
    return {
      bigAreaList: [],
      nameList: [],
      edit: this.$store.getters.button_permissions.includes('btn:collection-center:collection-time-configuration:edit'),
      del: this.$store.getters.button_permissions.includes('btn:collection-center:collection-time-configuration:del'),
      timeValue: [],
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: '',
          endTime: '',
          bigArea: ''
        }
      },
      total: 0,
      tableData: [],
      isClickAll: false,
      dialogTitle: '',
      dialogVisible: false,
      submitForm: {
        name: '',
        standard: '',
        bigArea: ''
      },
      submitRules: {
        name: [{ required: true, message: this.$t('userManage.notNull'), trigger: ['blur', 'change'] }],
        standard: [
          { required: true, message: this.$t('userManage.notNull'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              const reg = /^[0-9]*$/;
              if (!reg.test(value)) {
                return callback(new Error(this.$t('collectionCenter.pleaseInputInteger')));
              } else {
                callback();
              }
            }
          }
        ],
        bigArea: [{ required: true, message: this.$t('userManage.notNull'), trigger: ['blur', 'change'] }]
      },
      editId: ''
    };
  },
  watch: {
    'queryForm.params.bigArea'(val) {
      if (!val) {
        this.remoteMethod();
      }
    },
    'submitForm.bigArea'(val) {
      if (!val) {
        this.remoteMethod();
      }
    }
  },
  created() {
    this.default();
    this.searchClick();
    this.isClickAll = false;
    this.getAgeing();
    this.remoteMethod();
  },
  methods: {
    // 大区列表
    remoteMethod(value) {
      const val = value || '';
      getBigArea(val).then(res => {
        if (res.status === 'OK') {
          this.bigAreaList = res.data;
        }
      });
    },
    // 原时效名称 改为 下拉框
    getAgeing() {
      getAgeingType().then(res => {
        if (res.status === 'OK') {
          this.nameList = res.data;
        }
      });
    },
    resetClick() {
      this.default();
    },
    default() {
      const d = new Date();
      const day = dayjs(d).subtract(1, 'year').format('YYYY-MM-DD 00:00:00');
      const today = dayjs(d).format('YYYY-MM-DD 23:59:59');
      this.timeValue = [day, today];
      this.queryForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: day,
          endTime: today,
          bigArea: ''
        }
      };
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        this.queryForm.params.startTime = '';
        this.queryForm.params.endTime = '';
        return;
      }
      this.queryForm.params.startTime = val[0];
      this.queryForm.params.endTime = val[1];
    },
    searchClick() {
      if (!this.queryForm.params.startTime || !this.queryForm.params.endTime) {
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
        return;
      }
      this.queryForm.pageNumber = 1;
      localStorage.setItem('ctcQueryForm', JSON.stringify(this.queryForm));
      this.getDatePage();
    },
    getDatePage() { // 表格page
      const queryForm = JSON.parse(localStorage.getItem('ctcQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      collectionTimePage(queryForm).then(res => {
        if (res.status === 'OK') {
          this.total = res.data.total;
          this.tableData = res.data.records;
        }
      }).catch({});
    },
    getPageSize() { // 表格page
      this.queryForm.pageNumber = 1;
      const query = JSON.parse(localStorage.getItem('ctcQueryForm')) || this.queryForm;
      const queryForm = {
        pageNumber: 1,
        pageSize: this.queryForm.pageSize,
        params: query.params
      };
      collectionTimePage(queryForm).then(res => {
        if (res.status === 'OK') {
          this.total = res.data.total;
          this.tableData = res.data.records;
        }
      }).catch({});
    },

    delRow(row) {
      collectionTimeDel(row.id).then(res => {
        if (res.status === 'OK') {
          this.$message.success(this.$t('GlobalSetObj.DeleteSuccessful'));
          this.searchClick();
        }
      }).catch({});
    },
    addClick() {
      this.dialogTitle = this.$t('collectionCenter.add');
      this.dialogVisible = true;
    },
    submit() {
      this.$refs.submitRef.validate((valid) => {
        if (!valid) return;
        if (this.dialogTitle === this.$t('collectionCenter.add')) {
          collectionTimeAdd(this.submitForm).then(res => {
            this.closeDialog();
            this.default();
            this.searchClick();
            this.$message({
              message: this.$t('collectionCenter.addSuccessful'), // '新增成功'
              type: 'success'
            });
          }).catch({});
        }
        if (this.dialogTitle === this.$t('collectionCenter.modify')) {
          const submitForm = cloneDeep(this.submitForm);
          submitForm.id = this.editId;
          collectionTimeUpdate(submitForm).then(res => {
            this.closeDialog();
            this.searchClick();
            this.$message({
              message: this.$t('collectionCenter.ModificationSuccessful'), // '修改成功'
              type: 'success'
            });
          }).catch({});
        }
      });
    },
    closeDialog() {
      this.dialogVisible = false;
      this.submitForm = {
        name: '',
        standard: '',
        bigArea: ''
      };
      this.$nextTick(() => {
        this.$refs['submitRef'].clearValidate();
      });
    },
    editClick(row) {
      this.dialogTitle = this.$t('collectionCenter.modify');
      this.dialogVisible = true;
      this.editId = row.id;
      this.submitForm = {
        name: row.name,
        standard: row.standard,
        bigArea: row.bigArea
      };
    },

    downloadAll() {
      this.isClickAll = true;
      const queryForm = JSON.parse(localStorage.getItem('ctcQueryForm')) || this.queryForm;
      collectionTimeExport(queryForm.params).then(res => {
        if (res.status === 'OK') {
          this.isClickAll = false;
          const h = this.$createElement;
          this.$msgbox({
            // 提示
            title: this.$t('collectionCenter.tips'),
            message: h('p', null, [
              // 导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载,
              h('span', this.$t('collectionCenter.ExportSuccessful')),
              // 请勿重复导出!
              h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
            ]),
            showCancelButton: true,
            // 立即前往
            confirmButtonText: this.$t('collectionCenter.Immediately'),
            // 关闭
            cancelButtonText: this.$t('collectionCenter.close')
          }).then(action => {
            if (action === 'confirm') {
              this.$router.push({ path: '/resource/exportTaskList' });
            }
          }).catch(() => {});
        }
      }).catch({});
    }
  }
};
</script>

<style lang="scss" scoped>
.Box{
  padding: 20px;
  box-sizing: border-box;
}
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.selectClass ::v-deep .el-form-item{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.selectClass ::v-deep .el-form-item__label{
  padding: 0;
  line-height: 16px;
  white-space: nowrap;
  margin-left: 20px;
}
.selectClass ::v-deep .el-form-item__content{
  margin-left: 0 !important;
  width: 100%;
}

// 防止按钮重复点击
@keyframes throttle{
  from{
    filter: opacity(40%);
    pointer-events: none;
  }
  to{
    pointer-events: all;
  }
}
.onlyClickOnce10{
  animation: throttle 10s step-end forwards;
}
.onlyClickOnce10:active{
  filter: opacity(40%);
  animation: none;
}

.widthClass{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
</style>
