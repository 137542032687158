<template>
  <div class="TimeLimitForSigningBox">
    <div style="margin-bottom: 20px;">
      <el-form>
        <el-row>
          <el-col class="col2">
            <el-select
              v-model="selectNum" :placeholder="$t('collectionCenter.pleaseChoose')" class="selectTimeClass autoWidth"
              @change="changeNum"
            >
              <template slot="prefix">
                {{ (selectNumList.find(item => item.value === selectNum) || {}).label }}
              </template>
              <el-option
                v-for="(item, index) in selectNumList"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
              <!--              <el-option :label="$t('collectionCenter.lanshouOrderNum')" value="n1" />&lt;!&ndash; 揽收订单号 &ndash;&gt;-->
              <!--              <el-option :label="$t('collectionCenter.Barcode')" value="n2" />&lt;!&ndash; 条形码 &ndash;&gt;-->
            </el-select>
          </el-col>
          <el-col v-if="selectNum == 'n1'" :span="5">
            <el-form-item class="NumClass">
              <el-input
                v-model="findForm.orderNumber" type="textarea" clearable
                :placeholder="$t('collectionCenter.BatchQuery')" @keydown.enter.native="BlockNewline"
              />
            </el-form-item>
          </el-col>
          <el-col v-if="selectNum == 'n2'" :span="5">
            <el-form-item class="NumClass">
              <el-input
                v-model="findForm.scanNumber" type="textarea" clearable
                :placeholder="$t('collectionCenter.BatchQuery')" @keydown.enter.native="BlockNewline"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="24">
          <el-button
            v-permit:remove="'btn:collection-center:report:signAgeing:odd-find'"
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
          <el-button
            v-permit:remove="'btn:collection-center:report:signAgeing:odd-reset'"
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
          <el-button
            v-if="findActiveOdd=='hzOddFind' && oddExportHZ"
            icon="el-icon-upload2"
            size="mini"
            :class="exportListOdd.length <=0 && isClick ? 'onlyClickOnce10' : ''"
            @click="download"
          >{{ $t('collectionCenter.export') }}</el-button><!-- 导出 汇总 -->
          <el-button
            v-if="findActiveOdd=='mxOddFind' && oddExportMX"
            icon="el-icon-upload2"
            size="mini"
            :class="exportMXListOdd.length <=0 && isClick ? 'onlyClickOnce10' : ''"
            @click="downloadLogistics"
          >{{ $t('collectionCenter.export') }}</el-button><!-- 导出 明细 -->
          <el-button
            v-if="findActiveOdd=='hzOddFind' && oddAllExportHZ"
            icon="el-icon-upload"
            size="mini"
            :class="isClickAll3 ? 'onlyClickOnce10' : ''"
            @click="downloadAll"
          >{{ $t('collectionCenter.allExport') }}</el-button><!-- 单号—汇总—全部导出 -->
          <el-button
            v-if="findActiveOdd=='mxOddFind' && oddAllExportMX"
            icon="el-icon-upload"
            size="mini"
            :class="isClickAll4 ? 'onlyClickOnce10' : ''"
            @click="downloadAllMX"
          >{{ $t('collectionCenter.allExport') }}</el-button><!-- 单号—明细—全部导出 -->
        </el-col>
      </el-row>
      <el-divider />
    </div>
    <el-tabs v-model="findActiveOdd" type="border-card">
      <!-- 汇总 -->
      <el-tab-pane :label="$t('collectionCenter.summary')" name="hzOddFind">
        <div>
          <el-table
            ref="hzTable"
            :max-height="600"
            border
            show-summary
            :summary-method="getSummaries"
            :data="tableDate"
            @select="batchSelect"
            @select-all="batchSelectAll"
          >
            <el-table-column
              type="selection"
              width="55"
            />
            <el-table-column
              min-width="150"
              align="center"
              prop="driverName"
              :label="$t('collectionCenter.LanShouPeople')"
            /><!-- 揽收人 -->
            <el-table-column
              min-width="150"
              align="center"
              prop="siteName"
              :label="$t('collectionCenter.collectionDot')"
            /><!-- 揽收网点 -->
            <el-table-column
              min-width="150"
              align="center"
              prop="scanDate"
              :label="$t('collectionCenter.lanshouT')"
            /><!-- 揽收时间 -->
            <el-table-column
              min-width="150"
              align="center"
              prop="sourceType"
              :label="$t('collectionCenter.DataSource')"
            ><!-- 数据来源 -->
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.sourceType === 0 ? 'pda' :
                    scope.row.sourceType === 1 ? 'pc' : ''
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              align="center"
              prop="collectedCount"
              :label="$t('collectionCenter.receivedPackagesTotalNumber')"
            ><!-- 已揽收包裹总数 -->
              <template slot-scope="scope">
                <el-button v-if="hzTomx" size="mini" @click="details(scope.row, 'collectBool')">{{ scope.row.collectedCount }}</el-button>
                <span v-else>{{ scope.row.collectedCount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              align="center"
              prop="signedInCount"
              :label="$t('collectionCenter.TotalNumberOfParcelsSignedIn24Hours')"
            ><!-- 24小时内签收包裹总数 -->
              <template slot-scope="scope">
                <el-button v-if="hzTomx" size="mini" @click="details(scope.row, 'twentyFoursignBool')">{{ scope.row.signedInCount }}</el-button>
                <span v-else>{{ scope.row.signedInCount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              align="center"
              prop="unsignedQty"
              :label="$t('collectionCenter.in24UnsignedPackagesTotalNumber')"
            ><!-- 24小时内未签收包裹总数 -->
              <template slot-scope="scope">
                <el-button v-if="hzTomx" size="mini" @click="details(scope.row, 'unsignedBool')">{{ scope.row.unsignedQty }}</el-button>
                <span v-else>{{ scope.row.unsignedQty }}</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              align="center"
              prop="unsignedCount"
              :label="$t('collectionCenter.over24HoursUnsignedPackagesTotalNumber')"
            ><!-- 超24小时未签收包裹总数 -->
              <template slot-scope="scope">
                <el-button v-if="hzTomx" size="mini" @click="details(scope.row, 'overtwentyFoursignBool')">{{ scope.row.unsignedCount }}</el-button>
                <span v-else>{{ scope.row.unsignedCount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              align="center"
              prop="collectSuccessRate"
              :label="$t('collectionCenter.SigningRateWithin24Hours')"
            ><!-- 24小时内签收率 -->
              <template slot-scope="scope">
                <div>{{ scope.row.collectSuccessRate }}%</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <!-- 明细 -->
      <el-tab-pane v-if="findActiveOdd=='mxOddFind'" :label="$t('collectionCenter.particulars')" name="mxOddFind">
        <div>
          <el-table
            ref="mxTable"
            :max-height="600"
            border
            :data="mxtableDate"
            @select="batchSelectMX"
            @select-all="batchSelectAllMX"
          >
            <el-table-column
              type="selection"
              width="55"
            />
            <!-- 序号 -->
            <el-table-column
              :label="$t('collectionCenter.ind')" type="index" width="60"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{ (queryFormOddMX.pageNumber - 1) * queryFormOddMX.pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="orderNumber"
              :label="$t('collectionCenter.lanshouOrderNum')"
            /><!-- 揽收订单号 -->
            <el-table-column
              prop="scanNumber"
              :label="$t('collectionCenter.Barcode')"
            /><!-- 条形码 -->
            <el-table-column
              prop="driverName"
              :label="$t('collectionCenter.LanShouPeople')"
            /><!-- 揽收人 -->
            <el-table-column
              prop="scanTime"
              :label="$t('collectionCenter.lanshouT')"
            /><!-- 揽收时间 -->
            <el-table-column
              prop="siteName"
              :label="$t('collectionCenter.collectionDot')"
            /><!-- 揽收网点 -->
            <el-table-column
              prop="signerSiteName"
              :label="$t('collectionCenter.signerBelongsSite')"
            /><!-- 签收人所属站点 -->
            <el-table-column
              prop="signerUsername"
              :label="$t('collectionCenter.signer')"
            /><!-- 签收人 -->
            <el-table-column
              prop="signerTime"
              :label="$t('collectionCenter.signT')"
            /><!-- 签收时间 -->
          </el-table>
        </div>
        <div class="right">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryFormOddMX.pageNumber"
            :page-sizes="[10, 20, 50, 100, 500, 1000]"
            :page-size.sync="queryFormOddMX.pageSize"
            :total="totalMX"
            background
            style="margin: 10px 0"
            @current-change="getTablePageMX"
            @size-change="searchClickMX"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  queryDetailedPage,
  findNumberPage,
  queryDetailedPageExport,
  numberExportAll,
  exportAllByNumber
} from '@/api/lanshou';
import ExcelJS from 'exceljs';
import { goExportList } from '@/utils/goExportList.js';
import { saveAs } from 'file-saver';

export default {
  data() {
    return {
      isClickAll3: false,
      isClickAll4: false,
      isClick: false,
      oddExportHZ: this.$store.getters.button_permissions.includes('btn:collection-center:report:signAgeing:odd-export-hz'),
      oddExportMX: this.$store.getters.button_permissions.includes('btn:collection-center:report:signAgeing:odd-export-mx'),
      oddAllExportHZ: this.$store.getters.button_permissions.includes('btn:collection-center:report:signAgeing:odd-allExport-hz'),
      oddAllExportMX: this.$store.getters.button_permissions.includes('btn:collection-center:report:signAgeing:odd-allExport-mx'),
      hzTomx: this.$store.getters.button_permissions.includes('btn:collection-center:report:signAgeing:odd-hzTomx'),
      selectNumList: [
        { label: this.$t('collectionCenter.lanshouOrderNum'), value: 'n1' }, // 揽收订单号
        { label: this.$t('collectionCenter.Barcode'), value: 'n2' } // 条形码
      ],
      selectNum: 'n1',
      findActiveOdd: 'hzOddFind', // 汇总 hzOddFind   明细 mxOddFind
      tableDate: [],
      total: 0,
      // 导出列
      exportListOdd: [], // 汇总
      exportMXListOdd: {
        idList: []
      }, // 明细
      // 明细
      mxtableDate: [],
      totalMX: 0,
      findForm: {
        orderNumber: '',
        scanNumber: ''
      },
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          beginTime: '',
          endTime: '',
          collectstatusList: '',
          orderNumber: '',
          scanNumber: ''
        }
      },
      queryFormOddMX: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          beginTime: '',
          endTime: '',
          siteId: '',
          driverId: '',
          sourceType: '', // 数据来源
          customerId: '', // 客户id
          orderNumber: '',
          scanNumber: '',
          collectBool: false,
          twentyFoursignBool: false,
          unsignedBool: false,
          overtwentyFoursignBool: false
        }
      },
      notEnter: true // 是否允许换行
    };
  },
  watch: {
    'findForm.orderNumber'(val) {
      const xxx = val.split('\n');
      let orderNumber = [];
      if (xxx.length >= 1000) {
        orderNumber = xxx.slice(0, 1000).join('\n');
        this.findForm.orderNumber = orderNumber;
        this.notEnter = false;
      } else {
        this.notEnter = true;
      }
      if (xxx.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    },
    'findForm.scanNumber'(val) {
      const zzz = val.split('\n');
      let scanNumber = [];
      if (zzz.length >= 1000) {
        scanNumber = zzz.slice(0, 1000).join('\n');
        this.findForm.scanNumber = scanNumber;
        this.notEnter = false;
      } else {
        this.notEnter = true;
      }
      if (zzz.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    },
    findActiveOdd() {
      // findActiveOdd:  汇总 hzOddFind   明细 mxOddFind
      if (this.findActiveOdd === 'hzOddFind') {
        this.queryFormOddMX = {
          pageNumber: 1,
          pageSize: 10,
          params: {
            beginTime: '',
            endTime: '',
            scanUserId: '',
            siteId: '',
            driverId: '',
            sourceType: '', // 数据来源
            customerId: '', // 客户id
            orderNumber: '',
            scanNumber: '',
            collectBool: false,
            twentyFoursignBool: false,
            unsignedBool: false,
            overtwentyFoursignBool: false
          }
        };
      }
    }
  },
  created() {
    this.isClick = false;
    this.isClickAll3 = false;
    this.isClickAll4 = false;
  },
  destroyed() {
    this.isClick = false;
  },
  methods: {
    BlockNewline() { // 文本域最大输入行数为1000
      const e = window.event || arguments[0];
      const orderNumberLen = this.findForm.orderNumber.split('\n');
      const scanNumberLen = this.findForm.scanNumber.split('\n');
      if (this.findForm.orderNumber) {
        if (!this.notEnter) {
          if (orderNumberLen.length >= 1000) {
            this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
          }
          e.returnValue = false;
          return true;
        }
      }
      if (this.findForm.scanNumber) {
        if (!this.notEnter) {
          if (scanNumberLen.length >= 1000) {
            this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
          }
          e.returnValue = false;
          return true;
        }
      }
    },
    // 汇总---------------------------------------
    changeNum() { // 切换揽收订单号 / 条形码
      this.findForm.orderNumber = '';
      this.findForm.scanNumber = '';
    },
    getTablePage() {
      const queryF = JSON.parse(localStorage.getItem('TFOddHZQueryForm')) || this.findForm;
      queryDetailedPage(queryF).then(res => {
        if (res.status === 'OK') {
          if (res.data) {
            this.tableDate = res.data;
          }
        } else {
          this.tableDate = [];
        }
      }).catch((err) => {
        console.log(err);
        this.tableDate = [];
      });
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('collectionCenter.all');
          return;
        }
        if (column.label === this.$t('collectionCenter.receivedPackagesTotalNumber') ||
            column.label === this.$t('collectionCenter.TotalNumberOfParcelsSignedIn24Hours') ||
            column.label === this.$t('collectionCenter.over24HoursUnsignedPackagesTotalNumber') ||
            column.label === this.$t('collectionCenter.in24UnsignedPackagesTotalNumber')) {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += '';
          } else {
            sums[index] = '';
          }
        }
      });
      return sums;
    },
    batchSelect(selection, row) {
      this.exportListOdd = selection;
    },
    batchSelectAll(selection) {
      this.exportListOdd = selection;
    },
    // 下载签收时效报表模板
    download() { // 汇总导出
      this.isClick = true;
      if (this.exportListOdd.length <= 0) {
        // '请勾选要导出的数据'
        this.$message.warning(this.$t('collectionCenter.PleaseTickTheDataExport'));
        return;
      }
      const that = this;
      async function ddd() {
        const wb = new ExcelJS.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        Sheet1.getRow(1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '009944' }};
        Sheet1.getRow(1).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
        Sheet1.getRow(1).height = 30;
        Sheet1.getRow(1).font = { bold: true, color: { argb: '000000' }, sz: 30 };
        Sheet1.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
        Sheet1.views = [
          { state: 'frozen', xSplit: undefined, ySplit: 1 }
        ];
        let Sheet1_data = [];
        Sheet1.columns = [
          {
            header: that.$t('collectionCenter.LanShouPeople'), // '揽收人'
            key: 'driverName',
            width: 15
          },
          {
            header: that.$t('collectionCenter.collectionDot'), // '揽收网点'
            key: 'siteName',
            width: 15
          },
          {
            header: that.$t('collectionCenter.lanshouT'), // '揽收时间'
            key: 'scanDate',
            width: 15
          },
          {
            header: that.$t('collectionCenter.DataSource'), // '数据来源'
            key: 'sourceType',
            width: 15
          },
          {
            header: that.$t('collectionCenter.receivedPackagesTotalNumber'), // '已揽收包裹总数'
            key: 'collectedCount',
            width: 15
          },
          {
            header: that.$t('collectionCenter.TotalNumberOfParcelsSignedIn24Hours'), // '24小时内签收包裹总数'
            key: 'signedInCount',
            width: 20
          },
          {
            header: that.$t('collectionCenter.in24UnsignedPackagesTotalNumber'), // '24小时内未签收包裹总数'
            key: 'unsignedQty',
            width: 20
          },
          {
            header: that.$t('collectionCenter.over24HoursUnsignedPackagesTotalNumber'), // '超24小时未签收包裹总数'
            key: 'unsignedCount',
            width: 20
          },
          {
            header: that.$t('collectionCenter.SigningRateWithin24Hours'), // '24小时内签收率'
            key: 'collectSuccessRate',
            width: 15
          }
        ];
        Sheet1_data = [];
        that.exportListOdd.map((item) => {
          Sheet1_data.push({
            driverName: item.driverName,
            siteName: item.siteName,
            scanDate: item.scanDate,
            sourceType: item.sourceType === 0 ? 'pda' : item.sourceType === 1 ? 'pc' : '',
            collectedCount: item.collectedCount,
            signedInCount: item.signedInCount,
            unsignedQty: item.unsignedQty,
            unsignedCount: item.unsignedCount,
            collectSuccessRate: item.collectSuccessRate + '%'
          });
        });
        Sheet1.addRows(Sheet1_data);
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        // const url = blob;
        const saveName = that.$t('collectionCenter.SignPrescriptionStatement') + '.xlsx'; // '签收时效报表汇总.xlsx'
        saveAs(blob, saveName);
        // if (typeof url === 'object' && url instanceof Blob) {
        //   url = URL.createObjectURL(url);
        // }
        // const aLink = document.createElement('a');
        // aLink.href = url;
        // aLink.download = saveName || '';
        // let event;
        // if (window.MouseEvent) event = new MouseEvent('click');
        // else {
        //   event = document.createEvent('MouseEvents');
        //   event.initMouseEvent(
        //     'click',
        //     true,
        //     false,
        //     window,
        //     0,
        //     0,
        //     0,
        //     0,
        //     0,
        //     false,
        //     false,
        //     false,
        //     false,
        //     0,
        //     null
        //   );
        // }
        // aLink.dispatchEvent(event);
        return;
      }
      ddd();
    },
    details(row, boolCode) { // 跳转到明细
      const OddFind = JSON.parse(localStorage.getItem('TFOddHZQueryForm'));
      this.findActiveOdd = 'mxOddFind';
      this.queryFormOddMX.pageNumber = 1;
      this.queryFormOddMX = {
        pageNumber: 1,
        pageSize: this.queryFormOddMX.pageSize,
        params: {
          beginTime: '',
          endTime: '',
          siteId: row.siteId,
          driverId: row.driverId,
          scanUserId: row.scanUserId,
          sourceType: row.sourceType, // 数据来源
          // customerId: row.customerId, // 客户id
          orderNumber: OddFind.orderNumber,
          scanNumber: OddFind.scanNumber,
          collectBool: false,
          twentyFoursignBool: false,
          unsignedBool: false,
          overtwentyFoursignBool: false
        }
      };
      if (boolCode === 'collectBool') {
        this.queryFormOddMX.params.collectBool = true;
      } else if (boolCode === 'twentyFoursignBool') {
        this.queryFormOddMX.params.twentyFoursignBool = true;
      } else if (boolCode === 'unsignedBool') {
        this.queryFormOddMX.params.unsignedBool = true;
      } else if (boolCode === 'overtwentyFoursignBool') {
        this.queryFormOddMX.params.overtwentyFoursignBool = true;
      } else {
        this.queryFormOddMX.params.collectBool = false;
        this.queryFormOddMX.params.twentyFoursignBool = false;
        this.queryFormOddMX.params.unsignedBool = false;
        this.queryFormOddMX.params.overtwentyFoursignBool = false;
      }
      localStorage.setItem('TFOddMXQueryForm', JSON.stringify(this.queryFormOddMX));
      this.getTablePageMX();
    },
    searchClick() {
      if (this.findActiveOdd === 'hzOddFind') {
        this.exportListOdd = [];
        localStorage.setItem('TFOddHZQueryForm', JSON.stringify(this.findForm));
        this.getTablePage();
      }
      if (this.findActiveOdd === 'mxOddFind') {
        this.exportMXListOdd.idList = [];
        if (!this.findForm.orderNumber && !this.findForm.scanNumber) {
          this.$message.warning(this.$t('collectionCenter.pleaseCheckAndInput')); // '请选择条件查询并输入内容'
          return;
        }
        this.queryFormOddMX = {
          pageNumber: this.queryFormOddMX.pageNumber,
          pageSize: this.queryFormOddMX.pageSize,
          params: {
            beginTime: '',
            endTime: '',
            siteId: this.queryFormOddMX.params.siteId,
            driverId: this.queryFormOddMX.params.driverId,
            scanUserId: this.queryFormOddMX.params.scanUserId,
            sourceType: this.queryFormOddMX.params.sourceType, // 数据来源
            // customerId: row.customerId, // 客户id
            orderNumber: this.findForm.orderNumber,
            scanNumber: this.findForm.scanNumber,
            collectBool: this.queryFormOddMX.params.collectBool,
            twentyFoursignBool: this.queryFormOddMX.params.twentyFoursignBool,
            unsignedBool: this.queryFormOddMX.params.unsignedBool,
            overtwentyFoursignBool: this.queryFormOddMX.params.overtwentyFoursignBool
          }
        };
        localStorage.setItem('TFOddMXQueryForm', JSON.stringify(this.queryFormOddMX));
        this.getTablePageMX();
      }
    },
    resetClick() {
      this.changeNum();
    },
    // 明细---------------------------------------
    searchClickMX() {
      this.queryFormOddMX.pageNumber = 1;
      const queryF = JSON.parse(localStorage.getItem('TFOddMXQueryForm')) || this.queryFormOddMX;
      queryF.pageNumber = 1;
      queryF.pageSize = this.queryFormOddMX.pageSize;
      findNumberPage(queryF).then(res => {
        if (res.status === 'OK') {
          this.mxtableDate = res.data.records;
          this.totalMX = res.data.total;
        }
      }).catch({});
    },
    getTablePageMX() {
      const queryF = JSON.parse(localStorage.getItem('TFOddMXQueryForm')) || this.queryFormOddMX;
      queryF.pageNumber = this.queryFormOddMX.pageNumber;
      queryF.pageSize = this.queryFormOddMX.pageSize;
      findNumberPage(queryF).then(res => {
        if (res.status === 'OK') {
          this.mxtableDate = res.data.records;
          this.totalMX = res.data.total;
        }
      }).catch({});
    },
    choose(selection) {
      const exportMXListOdd = [];
      selection.forEach(list => {
        exportMXListOdd.push(list.id);
      });
      this.exportMXListOdd.idList = exportMXListOdd;
    },
    batchSelectMX(selection, row) {
      this.choose(selection);
    },
    batchSelectAllMX(selection) {
      this.choose(selection);
    },
    downloadLogistics() { // 明细导出
      this.isClick = true;
      if (this.exportMXListOdd.idList.length <= 0) {
        // '请勾选要导出的数据'
        this.$message.warning(this.$t('collectionCenter.PleaseTickTheDataExport'));
        return;
      }
      queryDetailedPageExport(this.exportMXListOdd).then(res => {
        if (res.status === 'OK') {
          goExportList(this);
        }
      }).catch({});
    },
    downloadAll() { // 汇总——全部导出
      this.isClickAll3 = true;
      const dataForm = {
        orderNumber: this.findForm.orderNumber,
        scanNumber: this.findForm.scanNumber
      };
      const queryForm = JSON.parse(localStorage.getItem('TFOddHZQueryForm')) || dataForm;
      exportAllByNumber(queryForm).then(res => {
        if (res.status === 'OK') {
          goExportList(this);
        }
      }).catch({});
    },
    downloadAllMX() { // 明细——全部导出
      this.isClickAll4 = true;
      const dataForm = {
        beginTime: this.queryFormOddMX.params.beginTime,
        endTime: this.queryFormOddMX.params.endTime,
        siteId: this.queryFormOddMX.params.siteId,
        driverId: this.queryFormOddMX.params.driverId,
        sourceType: this.queryFormOddMX.params.sourceType,
        scanUserId: this.queryFormOddMX.params.scanUserId,
        orderNumber: this.findForm.orderNumber,
        scanNumber: this.findForm.scanNumber,
        collectBool: this.queryFormOddMX.params.collectBool,
        twentyFoursignBool: this.queryFormOddMX.params.twentyFoursignBool,
        unsignedBool: this.queryFormOddMX.params.unsignedBool,
        overtwentyFoursignBool: this.queryFormOddMX.params.overtwentyFoursignBool
      };
      const query = JSON.parse(localStorage.getItem('TFOddMXQueryForm'));
      const queryForm = query.params || dataForm;
      numberExportAll(queryForm).then(res => {
        goExportList(this);
      }).catch({});
    }
  }
};
</script>

<style lang="scss" scoped>

// 防止按钮重复点击
@keyframes throttle{
  from{
    filter: opacity(40%);
    pointer-events: none;
  }
  to{
    pointer-events: all;
  }
}
.onlyClickOnce2{
  animation: throttle 2s step-end forwards;
}
.onlyClickOnce2:active{
  filter: opacity(40%);
  animation: none;
}
.onlyClickOnce10{
  animation: throttle 10s step-end forwards;
}
.onlyClickOnce10:active{
  filter: opacity(40%);
  animation: none;
}

.autoWidth ::v-deep .el-input__prefix{ // 下拉框自适应占位
  position: relative;
  left: 0;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  padding: 0 25px;
  padding-left: 0;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  visibility: hidden;
  white-space: nowrap;
}
.autoWidth ::v-deep input {
  position: absolute;
}
  .TimeLimitForSigningBox{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .TimeLimitForSigningBox .delMl ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
  .dialogC ::v-deep .el-select{
    width: 100%;
  }
  .col2 {
    width: auto !important;
  }
  .NumClass ::v-deep textarea.el-textarea__inner {
    max-height: 150px;
  }
  .NumClass ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
  .TimeLimitForSigningBox .selectTimeClass ::v-deep.el-input--suffix .el-input__inner{
    border: none !important;
    font-size: 14px;
    color: #606266;
    font-weight: bold;
    text-align: right;
    padding: 0 25px;
    padding-left: 0;
  }
</style>
