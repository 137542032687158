<template>
  <div class="warp">
    <el-form label-width="auto">
      <el-row :gutter="20">
        <el-descriptions :colon="false" :column="3" direction="vertical">
          <!--发件人-->
          <el-descriptions-item :label="$t('newOrder.SenderName')">
            <div style="display: flex;">
              <span v-if="showDetail" style="font-weight: bold">{{ encryptionSenderInfo.senderName || '--' }}</span>
              <span v-else style="font-weight: bold">{{ senderInfo.senderName || '--' }}</span>
              <span
                v-if="senderInfo.senderName"
                class="iconEye"
                @click="handlerhidden('name')"
              >
                <el-tooltip placement="top-start">
                  <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                  <svg-icon icon-class="iconEye" />
                </el-tooltip>
              </span>
            </div>
          </el-descriptions-item>
          <!--发件人公司-->
          <el-descriptions-item :label="$t('newOrder.SenderCompany')">
            <div style="display: flex;">
              <span v-if="showDetail5" style="font-weight: bold">{{ encryptionSenderInfo.senderCompany || '--' }}</span>
              <span v-else style="font-weight: bold">{{ senderInfo.senderCompany || '--' }}</span>
              <span
                v-if="senderInfo.senderCompany"
                class="iconEye"
                @click="handlerhidden('senderCompany')"
              >
                <el-tooltip placement="top-start">
                  <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                  <svg-icon icon-class="iconEye" />
                </el-tooltip>
              </span>
            </div>
          </el-descriptions-item>
          <!--发件人手机-->
          <el-descriptions-item :label="$t('newOrder.senderPhone')">
            <div style="display: flex;">
              <span v-if="showDetail1" style="font-weight: bold">{{ encryptionSenderInfo.senderMobile || '--' }}</span>
              <span v-else style="font-weight: bold">{{ senderInfo.senderMobile || '--' }}</span>
              <span
                v-if="senderInfo.senderMobile"
                class="iconEye"
                @click="handlerhidden('senderPhone')"
              >
                <el-tooltip placement="top-start">
                  <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                  <svg-icon icon-class="iconEye" />
                </el-tooltip>
              </span>
            </div>
          </el-descriptions-item>
          <!--街道-->
          <el-descriptions-item :label="$t('newOrder.Street1')">
            <div>{{ senderInfo.senderStreet || '--' }}</div>
          </el-descriptions-item>
          <!--发件人门牌号-->
          <el-descriptions-item :label="$t('newOrder.SenderNumber')">
            <div>{{ senderInfo.senderHouseNumber || '--' }}</div>
          </el-descriptions-item>
          <!--发件人区-->
          <el-descriptions-item :label="$t('newOrder.SenderDistrict')">
            <div>{{ senderInfo.senderArea || '--' }}</div>
          </el-descriptions-item>
          <!--发件人城市-->
          <el-descriptions-item :label="$t('newOrder.SenderCity')">
            <div>{{ senderInfo.senderCity || '--' }}</div>
          </el-descriptions-item>
          <!--发件人州-->
          <el-descriptions-item :label="$t('newOrder.SenderState')">
            <div>{{ senderInfo.senderState || '--' }}</div>
          </el-descriptions-item>
          <!--发件人邮编-->
          <el-descriptions-item :label="$t('newOrder.SenderZipCode')">
            <div>{{ senderInfo.senderZipcode || '--' }}</div>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :colon="false" :column="1" direction="vertical">
          <!--发件人地址补充-->
          <el-descriptions-item :label="$t('GlobalSetObj.sendderComplement')">
            <div>{{ senderInfo.senderComplement || '--' }}</div>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :colon="false" :column="3" direction="vertical">
          <!--发件人税号-->
          <el-descriptions-item :label="$t('newOrder.SenderCPFCNPJ')">
            <div style="display: flex;">
              <span v-if="showDetail4" style="font-weight: bold">{{ encryptionSenderInfo.senderTax || '--' }}</span>
              <span v-else style="font-weight: bold">{{ senderInfo.senderTax || '--' }}</span>
              <span
                v-if="senderInfo.senderTax"
                class="iconEye"
                @click="handlerhidden('senderTax')"
              >
                <el-tooltip placement="top-start">
                  <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                  <svg-icon icon-class="iconEye" />
                </el-tooltip>
              </span>
            </div>
          </el-descriptions-item>
          <!--国家注册号-->
          <el-descriptions-item :label="$t('GlobalSetObj.NationalRegistrationNumber')">
            <div>{{ senderInfo.senderIeNumber || '--' }}</div>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :colon="false" :column="1" direction="vertical">
          <!--发件地址-->
          <el-descriptions-item :label="$t('newOrder.SendingAddress')">
            <div>{{ senderInfo.senderAddress || '--' }}</div>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :colon="false" :column="3" direction="vertical">
          <!--发件人电话-->
          <el-descriptions-item :label="$t('newOrder.SenderPhone')">
            <div style="display: flex;">
              <span v-if="showDetail2" style="font-weight: bold">{{ encryptionSenderInfo.senderPhone || '--' }}</span>
              <span v-else style="font-weight: bold">{{ senderInfo.senderPhone || '--' }}</span>
              <span
                v-if="senderInfo.senderPhone"
                class="iconEye"
                @click="handlerhidden('senderMobile')"
              >
                <el-tooltip placement="top-start">
                  <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                  <svg-icon icon-class="iconEye" />
                </el-tooltip>
              </span>
            </div>
          </el-descriptions-item>
          <!--发件人邮箱-->
          <el-descriptions-item :label="$t('newOrder.SenderEmail')">
            <div style="display: flex;">
              <span v-if="showDetail3" style="font-weight: bold">{{ encryptionSenderInfo.senderMail || '--' }}</span>
              <span v-else style="font-weight: bold">{{ senderInfo.senderMail || '--' }}</span>
              <span
                v-if="senderInfo.senderMail"
                class="iconEye"
                @click="handlerhidden('senderMail')"
              >
                <el-tooltip placement="top-start">
                  <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                  <svg-icon icon-class="iconEye" />
                </el-tooltip>
              </span>
            </div>
          </el-descriptions-item>
        </el-descriptions>
      </el-row>
    </el-form>
  </div>
</template>

<script>

export default {
  name: 'SenderInfo',
  props: {
    senderInfo: {
      type: Object,
      required: true,
      default: () => {
      }
    },
    encryptionSenderInfo: {
      type: Object,
      required: true,
      default: () => {
      }
    }
  },
  data() {
    return {
      showDetail: true,
      showDetail1: true,
      showDetail2: true,
      showDetail3: true,
      showDetail4: true,
      showDetail5: true
    };
  },
  methods: {
    handlerhidden(item, value) {
      const buttonPermissions = this.$store.getters.button_permissions;
      const promise = 'field:customer-sensitive-info';
      const hasPromise = buttonPermissions.includes(promise);
      if (hasPromise) {
        if (item === 'name') {
          this.showDetail = !this.showDetail;
        }
        if (item === 'senderPhone') {
          this.showDetail1 = !this.showDetail1;
        }
        if (item === 'senderMobile') {
          this.showDetail2 = !this.showDetail2;
        }
        if (item === 'senderMail') {
          this.showDetail3 = !this.showDetail3;
        }
        if (item === 'senderTax') {
          this.showDetail4 = !this.showDetail4;
        }
        if (item === 'senderCompany') {
          this.showDetail5 = !this.showDetail5;
        }
      } else {
        this.$message.error(this.$t('GlobalSetObj.sensitiveInfoPermission'));
      }
    }
  }
};
</script>

<style scoped>
.warp /deep/ .el-col {
  height: 50px;
}

.iconEye {
  cursor: pointer;
  margin-left: 10px;
}

.warp /deep/ .el-descriptions-item__label {
  font-size: 13px;
  color: #909399;
}

.warp /deep/ .el-descriptions-item__cell {
  /*height: 35px !important;*/
  padding-bottom: 2px !important;
}

.el-descriptions-item__cell div {
  font-weight: bold;
  font-size: 15px;
}
</style>
