var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "appBox" },
    [
      _c(
        "el-form",
        { staticClass: "selectClass", attrs: { "label-width": "120px" } },
        [
          _c(
            "el-row",
            { staticClass: "listQueryTime" },
            [
              _c(
                "el-col",
                { staticStyle: { width: "auto" }, attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("collectionCenter.lanshouT") } },
                    [
                      _c("el-date-picker", {
                        ref: "dateRef",
                        attrs: {
                          clearable: false,
                          type: "datetimerange",
                          "default-time": ["00:00:00", "23:59:59"],
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "range-separator": _vm.$t("collectionCenter.to"),
                          "start-placeholder": _vm.$t(
                            "collectionCenter.StartTime"
                          ),
                          "end-placeholder": _vm.$t("collectionCenter.EndTime"),
                        },
                        model: {
                          value: _vm.queryDate,
                          callback: function ($$v) {
                            _vm.queryDate = $$v
                          },
                          expression: "queryDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { width: "auto" }, attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("collectionCenter.LanShouPeople"),
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            placeholder: _vm.$t(
                              "operationCenter.Only10AreDisplayed"
                            ),
                            "remote-method": _vm.getScanUserId,
                            remote: "",
                            clearable: "",
                          },
                          on: {
                            focus: function ($event) {
                              return _vm.getScanUserId()
                            },
                          },
                          model: {
                            value: _vm.queryForm.params.collectManId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "collectManId",
                                $$v
                              )
                            },
                            expression: "queryForm.params.collectManId",
                          },
                        },
                        _vm._l(_vm.customerList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.realName, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { width: "auto" }, attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("collectionCenter.LanShouSite") },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            clearable: "",
                          },
                          model: {
                            value: _vm.queryForm.params.collectManSiteId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "collectManSiteId",
                                $$v
                              )
                            },
                            expression: "queryForm.params.collectManSiteId",
                          },
                        },
                        _vm._l(_vm.siteIdList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { width: "auto" }, attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("collectionCenter.Number") } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: _vm.$t("collectionCenter.BatchQuery"),
                          type: "textarea",
                          rows: 4,
                        },
                        model: {
                          value: _vm.queryForm.params.scanNumberList,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.queryForm.params,
                              "scanNumberList",
                              $$v
                            )
                          },
                          expression: "queryForm.params.scanNumberList",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value:
                        "btn:collection-center:order:no-scheduled-pick-up-orders:find",
                      expression:
                        "'btn:collection-center:order:no-scheduled-pick-up-orders:find'",
                      arg: "remove",
                    },
                  ],
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-search",
                  },
                  on: { click: _vm.searchClick },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.search")))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value:
                        "btn:collection-center:order:no-scheduled-pick-up-orders:reset",
                      expression:
                        "'btn:collection-center:order:no-scheduled-pick-up-orders:reset'",
                      arg: "remove",
                    },
                  ],
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetClick },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value:
                        "btn:collection-center:order:no-scheduled-pick-up-orders:export",
                      expression:
                        "'btn:collection-center:order:no-scheduled-pick-up-orders:export'",
                      arg: "remove",
                    },
                  ],
                  class: _vm.isClickAll ? "onlyClickOnce10" : "",
                  attrs: {
                    icon: "el-icon-upload2",
                    size: "mini",
                    type: "primary",
                  },
                  on: { click: _vm.download },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "div",
        [
          _c(
            "el-table",
            { ref: "tableRef", attrs: { border: "", data: _vm.tableDate } },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.ind"),
                  type: "index",
                  width: "60",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.queryForm.pageNumber - 1) *
                                _vm.queryForm.pageSize +
                                scope.$index +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "scanNumber",
                  label: _vm.$t("collectionCenter.Number"),
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "collectUserName",
                  label: _vm.$t("collectionCenter.LanShouPeople"),
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "collectUserSiteName",
                  label: _vm.$t("collectionCenter.LanShouSite"),
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "collectTime",
                  label: _vm.$t("collectionCenter.lanshouTime"),
                  align: "center",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-pagination", {
            staticStyle: { margin: "10px 0" },
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              "current-page": _vm.queryForm.pageNumber,
              "page-sizes": [10, 20, 50, 100, 500, 1000],
              "page-size": _vm.queryForm.pageSize,
              total: _vm.total,
              background: "",
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "current-change": _vm.searchClick,
              "size-change": _vm.searchClick,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }