var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "searchBox" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              attrs: { "label-position": "top" },
              model: {
                value: _vm.queryForm,
                callback: function ($$v) {
                  _vm.queryForm = $$v
                },
                expression: "queryForm",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { staticStyle: { height: "70px" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.InvoicingStatus"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.searchFormGetTable(null)
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.invoiceStatus,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "invoiceStatus",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.invoiceStatus",
                              },
                            },
                            [
                              _c("el-option", {
                                key: "1",
                                attrs: {
                                  label: _vm.$t("GlobalSetObj.all"),
                                  value: "",
                                },
                              }),
                              _c("el-option", {
                                key: "2",
                                attrs: {
                                  label: _vm.$t("GlobalSetObj.ToBeInvoiced"),
                                  value: 1,
                                },
                              }),
                              _c("el-option", {
                                key: "3",
                                attrs: {
                                  label: _vm.$t("GlobalSetObj.Invoiced"),
                                  value: 2,
                                },
                              }),
                              _c("el-option", {
                                key: "4",
                                attrs: {
                                  label: _vm.$t("GlobalSetObj.Rejected"),
                                  value: 3,
                                },
                              }),
                              _c("el-option", {
                                key: "5",
                                attrs: {
                                  label: _vm.$t("GlobalSetObj.NoNeedInvoice"),
                                  value: 4,
                                },
                              }),
                              _c("el-option", {
                                key: "6",
                                attrs: {
                                  label: _vm.$t("GlobalSetObj.Closed"),
                                  value: 5,
                                },
                              }),
                              _c("el-option", {
                                key: "7",
                                attrs: {
                                  label: _vm.$t("GlobalSetObj.Canceled"),
                                  value: 6,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { height: "70px" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("GlobalSetObj.interval") } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.searchFormGetTable(null)
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.section,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm.params, "section", $$v)
                                },
                                expression: "queryForm.params.section",
                              },
                            },
                            [
                              _c("el-option", {
                                key: "1",
                                attrs: {
                                  label: _vm.$t("GlobalSetObj.all"),
                                  value: "",
                                },
                              }),
                              _c("el-option", {
                                key: "2",
                                attrs: {
                                  label: _vm.$t("GlobalSetObj.HarvestRange"),
                                  value: 2,
                                },
                              }),
                              _c("el-option", {
                                key: "3",
                                attrs: {
                                  label: _vm.$t("GlobalSetObj.DeliverySection"),
                                  value: 3,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { height: "70px" }, attrs: { span: 8 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "selectType",
                              attrs: {
                                span: _vm.$i18n.locale == "zh" ? 10 : 20,
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "orderDetails.pleaseChoose"
                                    ),
                                    size: "mini",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeNumberType(
                                        1,
                                        _vm.queryForm.params.searchDateType
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.queryForm.params.searchDateType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm.params,
                                        "searchDateType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "queryForm.params.searchDateType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: "0",
                                    attrs: {
                                      label: _vm.$t(
                                        "GlobalSetObj.LogisticsInvoiceInvoicingTime"
                                      ),
                                      value: 1,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "end-placeholder": _vm.$t(
                                        "GlobalSetObj.endDate"
                                      ),
                                      format:
                                        _vm.$i18n.locale === "zh"
                                          ? _vm.zhDate
                                          : _vm.ptDate,
                                      "range-separator":
                                        _vm.$t("GlobalSetObj.to"),
                                      "start-placeholder": _vm.$t(
                                        "GlobalSetObj.startDate"
                                      ),
                                      type: "datetimerange",
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.searchFormGetTable(null)
                                      },
                                    },
                                    model: {
                                      value: _vm.invoiceDate,
                                      callback: function ($$v) {
                                        _vm.invoiceDate = $$v
                                      },
                                      expression: "invoiceDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "selectType",
                              attrs: {
                                span: _vm.$i18n.locale == "zh" ? 10 : 20,
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "orderDetails.pleaseChoose"
                                    ),
                                    size: "mini",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeNumberType(
                                        2,
                                        _vm.queryForm.params.searchNumberType
                                      )
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.queryForm.params.searchNumberType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm.params,
                                        "searchNumberType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "queryForm.params.searchNumberType",
                                  },
                                },
                                _vm._l(_vm.selectLabel, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: _vm.showNumList,
                                  staticClass: "NumberClass",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "searchOrder",
                                      staticStyle: { "padding-left": "16px" },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "orderDetails.pleaseInput"
                                          ),
                                          clearable: "",
                                        },
                                        nativeOn: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.searchFormGetTable(1)
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.queryForm.params.numberData,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryForm.params,
                                              "numberData",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression:
                                            "queryForm.params.numberData",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticStyle: { width: "40px" } },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "chaxunBtn",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.searchFormGetTable(
                                                    1
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "iconfont icon-chaxun",
                                                staticStyle: {
                                                  "font-size": "24px",
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticStyle: { width: "38px" } },
                                        [
                                          _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                placement: "bottom-end",
                                                trigger: "manual",
                                              },
                                              model: {
                                                value: _vm.showNumList,
                                                callback: function ($$v) {
                                                  _vm.showNumList = $$v
                                                },
                                                expression: "showNumList",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    width: "300px",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder: _vm.$t(
                                                        "newOrder.PreciseSearch"
                                                      ),
                                                      rows: 10,
                                                      type: "textarea",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.searchNumberStr,
                                                      callback: function ($$v) {
                                                        _vm.searchNumberStr =
                                                          $$v
                                                      },
                                                      expression:
                                                        "searchNumberStr",
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "margin-top": "10px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            size: "small",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.clearSearchNum()
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "newOrder.empty"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            float: "right",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                size: "small",
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.openNumList,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "orderExport.close"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                plain: "",
                                                                size: "small",
                                                                type: "primary",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.searchFormGetTable(
                                                                      2
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "newOrder.search"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-button",
                                                {
                                                  staticClass: "btnIcon",
                                                  attrs: { slot: "reference" },
                                                  on: {
                                                    click: _vm.openNumList,
                                                  },
                                                  slot: "reference",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "iconfont icon-liebiao1",
                                                    staticStyle: {
                                                      "font-size": "24px",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.searchNumberList.length > 0,
                      expression: "searchNumberList.length > 0",
                    },
                  ],
                },
                [
                  _c(
                    "div",
                    { staticClass: "searchNum" },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: { effect: "dark", placement: "bottom" },
                        },
                        [
                          _c("template", { slot: "content" }, [
                            _c("pre", [_vm._v(_vm._s(_vm.searchNumberStr))]),
                          ]),
                          _c("div", { staticClass: "searchNumWarp" }, [
                            _c("div", { staticClass: "wordBreak" }, [
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.searchNameObj.label) +
                                    "： " +
                                    _vm._s(_vm.searchNumberList.join(",")) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "clearSearchNum",
                          on: {
                            click: function ($event) {
                              return _vm.clearSearchNum()
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-close" })]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { padding: "0 20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.downloadInvoice()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("GlobalSetObj.DownloadTheInvoice")) + " ")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: "but:order-center:logisticsInvoice:clear",
                  expression: "'but:order-center:logisticsInvoice:clear'",
                  arg: "remove",
                },
              ],
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.cancelInvoice()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("GlobalSetObj.CancelTheInvoice")) + " ")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { float: "right" },
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.downloadData()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("newOrder.export")))]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { padding: "10px 20px" } },
        [
          _c(
            "el-table",
            {
              ref: "tableData",
              attrs: { data: _vm.tableData, border: "", "row-key": "id" },
              on: { "selection-change": _vm.selectTableData },
            },
            [
              _c("el-table-column", {
                attrs: {
                  "reserve-selection": true,
                  align: "center",
                  fixed: "left",
                  type: "selection",
                  width: "50",
                },
              }),
              _vm._l(_vm.tableColumn, function (item, ind) {
                return [
                  _c(
                    "el-table-column",
                    {
                      key: ind,
                      attrs: {
                        label: item.label,
                        prop: item.prop,
                        width: item.width,
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                item.isCopy
                                  ? _c("div", [
                                      scope.row[item.prop]
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    placement: "top",
                                                    "popper-class": "copy",
                                                    trigger: "hover",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      staticStyle: {
                                                        color: "black",
                                                        "font-size": "12px",
                                                        padding: "2px",
                                                      },
                                                      attrs: { type: "text" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.copy(
                                                            scope.row[item.prop]
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "orderDetails.copy"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      class: item.highlight
                                                        ? "WaybillNumber"
                                                        : "",
                                                      attrs: {
                                                        slot: "reference",
                                                      },
                                                      on: {
                                                        click: function (e) {
                                                          return _vm.$emit(
                                                            "to-OrderDetail",
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                      slot: "reference",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            scope.row[item.prop]
                                                              ? scope.row[
                                                                  item.prop
                                                                ]
                                                              : "--"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _c("div", [_vm._v("--")]),
                                    ])
                                  : item.endProp
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(scope.row[item.prop]) +
                                          " ~ " +
                                          _vm._s(scope.row[item.endProp]) +
                                          " "
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row[item.prop]
                                            ? scope.row[item.prop]
                                            : "--"
                                        )
                                      ),
                                    ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      item.tips
                        ? _c("template", { slot: "header" }, [
                            _c(
                              "div",
                              [
                                _c("span", [_vm._v(_vm._s(item.label))]),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "QuestionMark",
                                    attrs: { placement: "top" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [_vm._v(_vm._s(item.tips))]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "QuestionMark" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.operate"),
                  align: "center",
                  fixed: "right",
                  width: "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            scope.row.invoiceStatus === 2 &&
                            _vm.getDiff(scope.row.invoiceIssueTime, new Date())
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permit",
                                        rawName: "v-permit:remove",
                                        value:
                                          "but:order-center:logisticsInvoice:clear",
                                        expression:
                                          "'but:order-center:logisticsInvoice:clear'",
                                        arg: "remove",
                                      },
                                    ],
                                    attrs: { size: "small", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.cancelInvoice(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("GlobalSetObj.CancelTheInvoice")
                                      ) + " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            scope.row.invoiceStatus === 3
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.regenerate(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("GlobalSetObj.Rebuild")) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                staticStyle: { margin: "10px 0" },
                attrs: {
                  "current-page": _vm.queryForm.pageNumber,
                  "page-size": _vm.queryForm.pageSize,
                  "page-sizes": [10, 20, 40, 60, 80, 100],
                  total: _vm.total,
                  background: "",
                  layout: "total, sizes, prev, pager, next, jumper",
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageNumber", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageNumber", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageSize", $event)
                  },
                  "current-change": _vm.getCurrentTableData,
                  "size-change": _vm.getSizeTableData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("GlobalSetObj.DownloadTheInvoice"),
            visible: _vm.showExportTips,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showExportTips = $event
            },
          },
        },
        [
          _c("div", [
            _vm.$i18n.locale == "zh"
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "font-size": "15px",
                      "margin-bottom": "10px",
                    },
                  },
                  [
                    _vm._v(
                      "已选中" +
                        _vm._s(_vm.validInvoice.length) +
                        "条可下载发票 "
                    ),
                  ]
                )
              : _c(
                  "div",
                  {
                    staticStyle: {
                      "font-size": "15px",
                      "margin-bottom": "10px",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("GlobalSetObj.Selecionadas")) +
                        _vm._s(_vm.validInvoice.length) +
                        _vm._s(_vm.$t("GlobalSetObj.paraDownload")) +
                        " "
                    ),
                  ]
                ),
            _vm.validInvoice.length === 0
              ? _c("div", { staticStyle: { color: "#f00" } }, [
                  _vm._v(_vm._s(_vm.$t("GlobalSetObj.NoDownloadableInvoices"))),
                ])
              : _c("div", { staticStyle: { color: "#8c939d" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("GlobalSetObj.RejectedAndCanceledInvoices")
                      ) +
                      " "
                  ),
                ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showExportTips = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.determineDownloadInvoice },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("GlobalSetObj.CancelTheInvoice"),
            visible: _vm.showCancelInvoiceTips,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showCancelInvoiceTips = $event
            },
          },
        },
        [
          _c("div", [
            _c(
              "div",
              { staticStyle: { "font-size": "15px", "margin-bottom": "20px" } },
              [_vm._v(_vm._s(_vm.$t("GlobalSetObj.sureCancelInvoice")))]
            ),
            _c("div", { staticStyle: { color: "#8c939d" } }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("GlobalSetObj.invoicingTimeExceeds24")) +
                  " "
              ),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showCancelInvoiceTips = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.determineCancelInvoice },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("GlobalSetObj.CancelTheInvoice"),
            visible: _vm.showCancelInvoiceProgress,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showCancelInvoiceProgress = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { padding: "20px" } }, [
            _c("div", [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    background: "#DADADA",
                    "border-radius": "20px",
                    height: "10px",
                  },
                },
                [
                  _c("div", {
                    staticStyle: {
                      background: "#42B983",
                      height: "100%",
                      "border-radius": "20px",
                    },
                    style: { width: _vm.widthProgress + "%" },
                  }),
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "text-align": "right",
                    color: "#13ADF0",
                    "font-size": "13px",
                    "margin-top": "10px",
                  },
                },
                [
                  _vm.widthProgress < 100
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.$t("newOrder.processing"))),
                      ])
                    : _c("span", [
                        _vm._v(_vm._s(_vm.$t("newOrder.ProcessingComplete"))),
                      ]),
                ]
              ),
            ]),
            _vm.widthProgress === 100
              ? _c("div", [
                  _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.$t("GlobalSetObj.all")) + "： "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#13ADF0",
                            "margin-right": "30px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.cancelAll.length))]
                      ),
                      _vm._v(
                        " " + _vm._s(_vm.$t("operationCenter.succeed")) + "： "
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#42B983",
                            "margin-right": "30px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.problemPieceReturn.successNumber))]
                      ),
                      _vm._v(
                        " " + _vm._s(_vm.$t("operationCenter.fail")) + "："
                      ),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v(_vm._s(_vm.problemPieceReturn.failureNumber)),
                      ]),
                    ]),
                  ]),
                  _c("div", [
                    _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("GlobalSetObj.ReasonForFailure")) + "："
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "errTable" },
                      [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              data: _vm.errTableData,
                              height: _vm.tableHeight,
                              border: "",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("GlobalSetObj.waybillNumber"),
                                "show-overflow-tooltip": true,
                                align: "center",
                                prop: "waybillNumber",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("GlobalSetObj.ReasonForFailure"),
                                "show-overflow-tooltip": true,
                                align: "left",
                                prop: "failReason",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showCancelInvoiceProgress = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.close")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("GlobalSetObj.PrintTheChecklist"),
            visible: _vm.showPrintChecklist,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPrintChecklist = $event
            },
          },
        },
        [
          _c("div", [
            _vm.$i18n.locale === "zh"
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "font-size": "15px",
                      "margin-bottom": "20px",
                    },
                  },
                  [
                    _vm._v(
                      "当前筛选条件下有已开票数据" + _vm._s(_vm.total) + "条"
                    ),
                  ]
                )
              : _c("div", [
                  _vm._v(
                    _vm._s(_vm.$t("GlobalSetObj.ThereAre")) +
                      _vm._s(_vm.total) +
                      _vm._s(_vm.$t("GlobalSetObj.invoicedDataFilter"))
                  ),
                ]),
            _vm.total > 5000
              ? _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
                  _c("div", { staticStyle: { color: "#f00" } }, [
                    _vm._v(_vm._s(_vm.$t("GlobalSetObj.maximumNumber5000"))),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticStyle: { color: "#8c939d" } }, [
              _vm._v(
                " " + _vm._s(_vm.$t("GlobalSetObj.RejectedAndCanceled")) + " "
              ),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showPrintChecklist = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.total > 5000, type: "primary" },
                  on: { click: _vm.determinePrintChecklist },
                },
                [_vm._v(_vm._s(_vm.$t("operationCenter.Download")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }