var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isError
      ? _c(
          "div",
          { staticClass: "wrapper-error" },
          [
            _c("h2", { staticClass: "header-1" }, [
              _vm._v(
                "Error ao mostrar dados: " + _vm._s(_vm.customError) + " "
              ),
            ]),
            _c(
              "o-button",
              {
                attrs: { variant: "primary", size: "md" },
                on: { click: _vm.handleReloadWindow },
              },
              [_vm._v(" Tentar novamente ")]
            ),
          ],
          1
        )
      : _c("div", [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }