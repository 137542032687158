<template>
  <div class="login-container box">
    <div class="warp">

      <div
        ref="imgRef"
        class="imgs"
      >
        <el-carousel
          :height="carouselHeight"
          trigger="click"
        >
          <el-carousel-item
            v-for="item in carouselArr"
            :key="item.imgSrc"
          >
            <div
              class="carousel-img"
              :style="item"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="login">
        <div class="login-box">
          <div class="title">
            <!-- <p>{{ $t('login.anJun') }}</p> -->
            <p>{{ $t('login.anjunKD') }}</p><!-- 安骏快递系统 -->
            <p>{{ $t('login.WELCOME') }}</p>
          </div>
          <div class="inp_box">
            <div class="name">
              <!-- <p>账号</p> -->
              <div>
                <i class="el-icon-user" />
                <input
                  v-model.trim="loginForm.username"
                  min="3"
                  max="20"
                  autofocus
                  :placeholder="$t('login.inputNumber')"
                  type="text"
                  @keyup.enter.prevent="handleLogin"
                >
              </div>
            </div>
            <div class="name pwd">
              <!-- <p>密码</p> -->
              <div>
                <i class="el-icon-lock" />
                <input
                  v-model.trim="loginForm.password"
                  type="password"
                  min="3"
                  max="20"
                  :placeholder="$t('login.passwordPrompt')"
                  @keyup.enter.prevent="handleLogin"
                >
              </div>
            </div>
          </div>
          <div class="but">
            <button
              type="button"
              @click="handleLogin"
            >{{ $t('login.login') }}</button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  // setToken,
  removeToken
} from '@/utils/auth';
// import { setRSAEncrypt } from '@/utils/RSA_util';
import { MessageBox } from 'element-ui';
import { getUserIpInformation } from '@/api/user';
import { computer } from '@/utils/current_browser_util';
import { Base64 } from 'js-base64';
import md5 from 'js-md5';

// import { login } from '@/api/user';
export default {
  name: 'Login',
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      passwordType: 'password',
      redirect: undefined,

      carouselArr: [
        {
          backgroundImage: 'url(' + require('@/assets/images/carousel/1.jpg') + ')'
        },
        {
          backgroundImage: 'url(' + require('@/assets/images/carousel/2.jpg') + ')'
        },
        {
          backgroundImage: 'url(' + require('@/assets/images/carousel/3.jpg') + ')'
        },
        {
          backgroundImage: 'url(' + require('@/assets/images/carousel/4.jpg') + ')'
        },
        {
          backgroundImage: 'url(' + require('@/assets/images/carousel/5.jpg') + ')'
        },

        {
          backgroundImage: 'url(' + require('@/assets/images/carousel/6.jpg') + ')'
        }
      ],
      carouselHeight: '' // 轮播图高度
      // showPasswordStrength: false
    };
  },

  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },

  created() {
    const goURLStr = this.goHTTPS();

    goURLStr && window.location.replace(goURLStr);
  },

  mounted() {
    // 绑定enter事件
    this.enterKeyup();
    this.carouselHeight = this.getImgsHeight(this.$refs.imgRef);
    window.addEventListener('resize', () => {
      this.carouselHeight = this.getImgsHeight(this.$refs.imgRef);
    });
  },

  destroyed() {
    // 销毁enter事件
    this.enterKeyupDestroyed();
  },
  methods: {
    // 检测是否是正式环境
    /**
     * 检测如果是正式环境，访问的是http（非https）
     * 返回https的地址
     */
    goHTTPS() {
      const ENV = process.env.ENV;
      // console.log(ENV);
      const href = window.location.href;

      // 非正式环境
      if (ENV !== 'production') {
        return '';
      }

      // 正式环境
      const { protocol, pathname, host } = new URL(href);

      // 当前已经是https了
      if (protocol === 'https:') {
        return '';
      }
      // 强制转https
      return `https://${host}${pathname}`;
    },

    /**
     * 注册
     */
    enterKeyup() {
      document.addEventListener('keyup', this.enterKey);
    },

    /**
     * 销毁
     */
    enterKeyupDestroyed() {
      document.removeEventListener('keyup', this.enterKey);
    },

    /**
     * 在当前页面监听回车键
     */
    enterKey(event) {
      const componentName = this.$options.name;
      if (componentName !== 'Login') {
        return;
      }
      if (event.key !== 'Enter') {
        return;
      }
      this.handleLogin();
    },

    // 账户密码 简单检测
    checkInut() {
      const { username, password } = this.loginForm;

      if (!username.length) {
        this.$message({
          message: this.$t('login.inputNumber'),
          type: 'warning'
        });
        return false;
      }
      if (!password.length) {
        this.$message({
          message: this.$t('login.passwordPrompt'),
          type: 'warning'
        });
        return false;
      }
      return true;
    },
    handleLogin() {
      let { password } = this.loginForm;
      password = md5(md5(password));
      const loginForm = {
        username: this.loginForm.username,
        password: password
      };
      {
        const isBool = this.checkInut();
        if (!isBool) {
          return;
        }
      }

      this.$store.state.tagsView.cachedViews = [];
      this.$store.state.tagsView.visitedViews = [];

      this.$store
        .dispatch('user/login', loginForm)
        .then((data) => {
          // localStorage.setItem('Lock', setRSAEncrypt(password));
          const overTime = new Date().getTime() + 12 * 60 * 60 * 1000;
          sessionStorage.setItem('overTime', overTime);
          this.throttling(12 * 60 * 60 * 1000);
          this.getUserIpInformationData();
        })
        .then((res) => {
          if (this.loginForm.password === '123456' || this.loginForm.password === 'anjun123') {
            MessageBox.confirm(this.$t('login.pwdTipContent'), this.$t('login.pwdTipTop'),
              {
                confirmButtonText: this.$t('login.confirm'),
                type: 'warning',
                center: true,
                showClose: false
              }).then((arr) => {
              // console.log('进入修改密码页');
              this.$router.push({ path: '/profile' }); // 进入修改密码页
            }).catch(() => {
              // console.log('进入首页');
              this.$router.push({ path: '/' }); // 直接进入首页
            });
          } else {
            // console.log('直接进入首页', this.loginForm.password);
            this.$router.push({ path: '/' }); // 直接进入首页
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 定时退出登录
    throttling(wait) {
      // console.log('等待时长：', wait);
      const thatt = this;
      let tiems = 0;
      const now = new Date().getTime();
      if (now - tiems > wait) {
        tiems = setTimeout(function() {
          // console.log('已到时');
          removeToken();
          thatt.$router.push({ path: '/login' });
          clearTimeout(tiems);
          return;
        }, wait);
      }
    },
    // 登录日志信息
    getUserIpInformationData() {
      const userToken = this.$store.state.user.token.split('.')[1];
      const userStr = Base64.decode(userToken); // window.atob(userToken);
      const user = JSON.parse(userStr);
      const getIp = sessionStorage.getItem('ip');
      const getIpAddress = sessionStorage.getItem('ipAddress');
      const param = {
        browserInfo: computer.browserInfo,
        createUser: user.id,
        // ip: returnCitySN.cip,
        ip: getIp,
        // ipAddress: returnCitySN.cname,
        ipAddress: getIpAddress,
        osInfo: computer.osInfo,
        source: '',
        username: user.realName
      };
      return getUserIpInformation(param);
    },

    /**
     * 获取高度
     */
    getImgsHeight(doms) {
      return window.getComputedStyle(doms, null).height;
    }
  }
};
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #283443;
$light_gray: #fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  height: 100%;
  width: 100%;
  // background-color: $bg;
  overflow: hidden;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}
</style>
<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d6e9ff;
  .warp {
    // border: 1px solid red;
    width: 80%;
    height: 80%;
    display: flex;
    border-radius: 30px;
    overflow: hidden;
  }

  .imgs,
  .login {
    height: 100%;
  }
  .imgs {
    width: 60%;
    border: 1px solid transparent;
  }

  .login {
    background: #fff;
    width: 40%;
    display: flex;
    justify-content: center;

    .login-box {
      margin-top: 15%;
      width: 80%;

      .title {
        p {
          color: #000;
          margin: 0;
          padding: 0;
          font-weight: bold;
          color: #007efd;
        }
        p:nth-child(1) {
          // font-size: 35px;
          font-size: 50px;
        }
        p:nth-child(2) {
          margin-top: 5px;
          // font-size: 18px;
          font-size: 40px;
        }
      }
    }

    .inp_box {
      margin-top: 10%;
      .name {
        div {
          // border: 1px solid red;
          display: flex;
          align-items: center;
          padding: 10px;
          border: 1px solid #dcdfe6;
          border-radius: 20px;

          i {
            width: 20px;
            height: 20px;
            margin-right: 5px;
            color: #c0c4cc;
          }

          input {
            width: 100%;
            border: none;
            background: none;
            outline: none;
            color: #606266;
          }
          input::-webkit-input-placeholder {
            display: flex;
            margin-top: -5px;
            color: #c0c4cc;
            font-size: 12px;
          }
        }
      }

      .pwd {
        margin-top: 20px;
      }
    }

    .but {
      width: 100%;
      // margin-top: 100px;
      margin-top: 10%;
      // border: 1px solid #000;
      button {
        width: 100%;
        height: 40px;
        border-radius: 5px;
        border: none;
        background-color: rgb(0, 126, 253);
        color: #fff;
        outline: none;
        cursor: pointer;
      }
      button::after {
        border: none;
      }
    }
  }
}

.carousel-img {
  // border: 1px solid #000;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
