<template>
  <!-- 供应商选择器 -->
  <ElSelectV2
    v-model="value"
    :multiple="multiple"
    :clearable="clearable"
    :multiple-limit="multipleLimit"
    :loading="loading"
    :options="list"
    filterable
    :placeholder="$t('operationCenter.PleaseSelect')"
    @change="onChange"
  />
</template>

<script>
import ElSelectV2 from 'el-select-v2';
import {
  apiProviderPage
} from '@/api/finance/basicData';

const param = {
  pageSize: 99999999,
  pageNumber: 1,
  params: {}
};

export default {
  name: '',

  components: {
    ElSelectV2
  },

  props: {

    // 是否多选
    multiple: {
      type: Boolean,
      default: false
    },

    // 是否可以清空选项
    clearable: {
      type: Boolean,
      default: true
    },

    // 多选时用户最多可以选择的项目数，为 0 则不限制
    multipleLimit: {
      type: Number,
      default: 0
    },

    // id 用于回显
    val: {
      type: [Array, String, Number],
      default: () => []
    }
  },

  data() {
    return {
      loading: false,
      list: [],
      value: []
    };
  },

  watch: {
    val() {
      // this.value = [...this.val];
      if (Array.isArray(this.val)) {
        this.value = [...this.val];
        return;
      }
      this.value = this.val;
    }
  },

  created() {
    setTimeout(() => {
      this.getVendorList();
    }, 500);
  },

  methods: {
    // 获取供应商账号分页列表
    getVendorList() {
      this.loading = true;
      apiProviderPage(param, false).then(res => {
        if (res.status === 'OK') {
          this.list = (res.data.records || []).map(item => {
            const { id, providerName, code } = item;
            const obj = {
              label: `${providerName}(${code})`,
              value: id
            };

            return Object.freeze(obj);
          });
        }
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },

    onChange(val) {
      this.$emit('change', val);
    }
  }

};
</script>

<style scoped>
</style>
