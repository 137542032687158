var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detialInfo" },
    [
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.activeNames,
            callback: function ($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames",
          },
        },
        [
          _c(
            "el-collapse-item",
            {
              attrs: {
                title: _vm.$t("newOrder.BasicInformationWaybill"),
                name: "1",
              },
            },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c("basicInfo", {
                    attrs: { "table-infomation": _vm.tableInfomation },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-collapse-item",
            {
              attrs: { title: _vm.$t("GlobalSetObj.qinashouInfo"), name: "2" },
            },
            [
              _c("div", { staticClass: "signeCss" }, [
                _vm._v(_vm._s(_vm.$t("GlobalSetObj.zhengxiang"))),
              ]),
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c("signeInfo", {
                    attrs: {
                      "query-track-param": _vm.queryTrackParam,
                      "table-infomation": _vm.tableInfomation,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "signeCss",
                  staticStyle: { "margin-top": "20px" },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.nixiang")))]
              ),
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c("signeInfo1", {
                    attrs: {
                      "query-track-param": _vm.queryTrackParam,
                      "table-infomation": _vm.tableInfomation,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-collapse-item",
            {
              attrs: {
                title: _vm.$t("newOrder.InvoiceInformation"),
                name: "4",
              },
            },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c("child-order-invoice-info", {
                    attrs: {
                      "query-track-param": _vm.queryTrackParam,
                      "waybill-invoice-v-o-list": _vm.waybillInvoiceVOList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-collapse-item",
            {
              attrs: {
                title: _vm.$t("orderCenterCont.RecipientInformation"),
                name: "5",
              },
            },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c("RecipientsInfo", {
                    attrs: {
                      "encryption-receiver-info": _vm.encryptionReceiverInfo,
                      "receiver-info": _vm.tableInfomation.receiverInfo,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-collapse-item",
            {
              attrs: {
                title: _vm.$t("orderCenterCont.ProductInformation"),
                name: "6",
              },
            },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c("GoodsInfo", {
                    attrs: { "goods-table-data": _vm.goodsTableData },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-collapse-item",
            {
              attrs: {
                title: _vm.$t("orderCenterCont.SenderInformation"),
                name: "7",
              },
            },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c("senderInfo", {
                    attrs: {
                      "encryption-sender-info": _vm.encryptionSenderInfo,
                      "sender-info": _vm.tableInfomation.senderInfo,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.tableInfomation.orderWaybillClaimVO
            ? _c(
                "el-collapse-item",
                {
                  attrs: {
                    title: _vm.$t("orderCenterCont.claimInformation"),
                    name: "8",
                  },
                },
                [
                  _c(
                    "el-card",
                    { staticClass: "box-card" },
                    [
                      _c("claimInformation", {
                        attrs: {
                          orderwaybillclaim:
                            _vm.tableInfomation.orderWaybillClaimVO,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.tableInfomation.cancelOrderPictureList
            ? _c(
                "el-collapse-item",
                {
                  attrs: {
                    title: _vm.$t("orderCenterCont.cancelPedido"),
                    name: "20",
                  },
                },
                [
                  _c(
                    "el-card",
                    { staticClass: "box-card" },
                    [
                      _c(
                        "el-descriptions",
                        {
                          attrs: {
                            colon: false,
                            column: 3,
                            direction: "vertical",
                          },
                        },
                        _vm._l(
                          _vm.tableInfomation.cancelOrderPictureList,
                          function (item, index) {
                            return _c(
                              "el-descriptions-item",
                              {
                                key: index,
                                attrs: {
                                  label:
                                    "" +
                                    (_vm.$t("orderCenterCont.Pedido1") +
                                      (index + 1)),
                                  span: 1,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    style:
                                      "" +
                                      (_vm.$i18n.locale !== "zh"
                                        ? "width:50px"
                                        : "width:60px"),
                                  },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "QuestionMark",
                                        attrs: { placement: "top" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "content" },
                                            slot: "content",
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "image",
                                              staticStyle: {
                                                width: "300px",
                                                margin: "5px",
                                              },
                                              attrs: { src: item, alt: "" },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "align-items": "center",
                                              "justify-content": "center",
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("GlobalSetObj.see")
                                                )
                                              ),
                                            ]),
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class": "iconEye",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }