var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Box" },
    [
      _c(
        "div",
        { staticClass: "scanClass" },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("collectionCenter.scanBtn")))]),
          _c("el-input", {
            staticClass: "iconBtn",
            attrs: {
              placeholder: _vm.$t("collectionCenter.pleaseEnterOrScan"),
              clearable: "",
              "prefix-icon": "el-icon-full-screen",
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.addTable($event)
              },
            },
            model: {
              value: _vm.number,
              callback: function ($$v) {
                _vm.number = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "number",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "uploadBtn" },
        [
          _vm.isSignBtn
            ? _c(
                "el-button",
                { attrs: { type: "success" }, on: { click: _vm.signature } },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.signature")))]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "uploadBtn" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("collectionCenter.scannedNumber")))]),
        _c("span", { staticClass: "red" }, [
          _vm._v(_vm._s(_vm.scanPage.length)),
        ]),
      ]),
      _c(
        "el-table",
        { attrs: { "max-height": 600, data: _vm.scanPage, border: "" } },
        [
          _c("el-table-column", {
            attrs: {
              prop: "subpackageNo",
              align: "center",
              label: _vm.$t("collectionCenter.Number"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "scanTime",
              align: "center",
              label: _vm.$t("collectionCenter.scanTime"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.operation"),
              fixed: "right",
              width: "100",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-popconfirm",
                      {
                        attrs: { title: _vm.$t("collectionCenter.deleteSure") },
                        on: {
                          confirm: function ($event) {
                            return _vm.scanDelClick(scope.row, scope.$index)
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              type: "danger",
                              size: "mini",
                            },
                            slot: "reference",
                          },
                          [_vm._v(_vm._s(_vm.$t("collectionCenter.Delete")))]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.signVisible
        ? _c("div", { staticClass: "signMask" }, [
            _c("div", { staticClass: "signVisibleClass" }, [
              _c("div", {
                staticClass: "el-icon-close signClose",
                on: { click: _vm.closeSign },
              }),
              _c("div", { staticClass: "descriptionsClass" }, [
                _c(
                  "div",
                  { ref: "image" },
                  [
                    _c(
                      "el-descriptions",
                      {
                        attrs: {
                          title: "",
                          column: 1,
                          border: "",
                          "content-style": _vm.rowClass,
                          "label-style": _vm.labelRowClass,
                        },
                      },
                      [
                        _c("el-descriptions-item", { attrs: { label: "" } }, [
                          _c("div", { staticClass: "top1" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("collectionCenter.LSSite")) +
                                  _vm._s(_vm.scanSign.siteName)
                              ),
                            ]),
                            _c("img", { attrs: { src: _vm.LOGO, alt: "" } }),
                          ]),
                        ]),
                        _c("el-descriptions-item", { attrs: { label: "" } }, [
                          _c("div", { staticClass: "two" }, [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.$t("collectionCenter.adrser")) +
                                  _vm._s(_vm.scanSign.senderName)
                              ),
                            ]),
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.$t("collectionCenter.scanner")) +
                                  "：" +
                                  _vm._s(_vm.scanSign.scanUserName)
                              ),
                            ]),
                          ]),
                        ]),
                        _c("el-descriptions-item", { attrs: { label: "" } }, [
                          _c("div", { staticClass: "two" }, [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "collectionCenter.estimatedCollectedQuantity"
                                  )
                                ) +
                                  "：" +
                                  _vm._s(_vm.scanSign.estimatedCount)
                              ),
                            ]),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "collectionCenter.actualCollectedQuantity"
                                  )
                                ) +
                                  "：" +
                                  _vm._s(_vm.scanSign.actualCollectionNum)
                              ),
                            ]),
                          ]),
                        ]),
                        _c("el-descriptions-item", { attrs: { label: "" } }, [
                          _c("div", { staticClass: "padClass pl" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("collectionCenter.lanshouTime"))
                              ),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.scanSign.collectionTime)),
                            ]),
                          ]),
                        ]),
                        _c("el-descriptions-item", { attrs: { label: "" } }, [
                          _c("div", { staticClass: "topTitle" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("collectionCenter.customerSignature")
                              )
                            ),
                          ]),
                        ]),
                        _c("el-descriptions-item", { attrs: { label: "" } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "500px",
                                height: "205px",
                                overflow: "hidden",
                              },
                            },
                            [
                              _c("vue-esign", {
                                ref: "esign",
                                attrs: {
                                  width: 500,
                                  height: 205,
                                  "line-width": 5,
                                  "line-color": "#000",
                                  "bg-color": "#FFF",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "signBtn" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { type: "success" },
                        on: { click: _vm.handleGenerate },
                      },
                      [_vm._v(_vm._s(_vm.$t("collectionCenter.Confirm")))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "info", plain: "" },
                        on: { click: _vm.resetSign },
                      },
                      [_vm._v(_vm._s(_vm.$t("collectionCenter.empty")))]
                    ),
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.closeSign($event)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("collectionCenter.cancel")))]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
        },
        [
          _c("audio", {
            ref: "errRef",
            attrs: { src: _vm.errorMp3, controls: "controls" },
          }),
          _c("audio", {
            ref: "okRef",
            attrs: { src: _vm.okMp3, controls: "controls" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }