var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { attrs: { shadow: "never" } },
        [
          _c(
            "el-form",
            {
              attrs: { inline: false, "label-position": "top", size: "small" },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("GlobalSetObj.senderTime") } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              clearable: true,
                              "default-time": ["00:00:00", "23:59:59"],
                              format:
                                _vm.$i18n.locale == "zh"
                                  ? _vm.zhDate
                                  : _vm.ptDate,
                              placeholder: _vm.$t("newOrder.SelectDatetime"),
                              "range-separator": "~",
                              type: "datetimerange",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                            },
                            model: {
                              value: _vm.timeValue,
                              callback: function ($$v) {
                                _vm.timeValue = $$v
                              },
                              expression: "timeValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-search",
                            size: "small",
                            type: "primary",
                          },
                          on: { click: _vm.enterSearch },
                        },
                        [_vm._v(_vm._s(_vm.$t("newOrder.search")) + " ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-refresh-left",
                            size: "small",
                            type: "info",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.resetForm()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("newOrder.reset")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-card", { attrs: { shadow: "never" } }, [
                    _c("div", [_c("span", [_vm._v("分拣机有效分拣票数")])]),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-card", { attrs: { shadow: "never" } }, [
                    _c("div", [_c("span", [_vm._v("分拣机有效分拣票数")])]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }