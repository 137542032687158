export const trailer = {
  // tableData
  trailer: {
    zh: '挂车',
    en: '',
    pu: 'Carreta'
  },
  createTrailer: {
    zh: '创建挂车',
    en: '',
    pu: 'Criar carreta'
  },
  plate: {
    zh: '车牌',
    en: '',
    pu: 'Placa'
  },
  platePlaceholder: {
    zh: '按车牌搜索',
    en: '',
    pu: 'Pesquise pela placa'
  },
  trailerStatus: {
    zh: '挂车状态',
    en: '',
    pu: 'Status Carreta'
  },
  trailerStatusPlaceholder: {
    zh: '请选择状态',
    en: '',
    pu: 'Selecione o status'
  },
  activeTrailers: {
    zh: '活动挂车',
    en: '',
    pu: 'carretas ativas'
  },
  disabledTrailers: {
    zh: '停用挂车',
    en: '',
    pu: 'carretas desativadas'
  },
  document: {
    zh: '个人/法人税号',
    en: '',
    pu: 'Documento'
  },
  brand: {
    zh: '品牌',
    en: '',
    pu: 'Marca'
  },
  owner: {
    zh: '所有者',
    en: '',
    pu: 'Dono'
  },
  bodyType: {
    zh: '车身类型',
    en: '',
    pu: 'Tipo do Corpo'
  },
  state: {
    zh: '州',
    en: '',
    pu: 'Estado'
  },
  outsourced: {
    zh: '外包',
    en: '',
    pu: 'Terceirizado'
  },
  noResults: {
    zh: '没有结果',
    en: '',
    pu: 'Sem resultados'
  },
  yes: {
    zh: '是',
    en: '',
    pu: 'Sim'
  },
  no: {
    zh: '否',
    en: '',
    pu: 'Não'
  },
  createTrailerTitle: {
    zh: '创建挂车',
    en: '',
    pu: 'Criando Carreta'
  },
  createTrailerParagraph: {
    zh: '在下方填写字段以在我们的系统中创建挂车',
    en: '',
    pu: 'Preencha os campos logo abaixo para realizar a criação de uma carreta em nosso sistema'
  },
  // actions
  edit: {
    zh: '编辑',
    en: '',
    pu: 'Editar'
  },
  delete: {
    zh: '删除',
    en: '',
    pu: 'Excluir'
  },
  restore: {
    zh: '恢复',
    en: '',
    pu: 'Restaurar'
  },
  editTitle: {
    zh: '编辑挂车',
    en: '',
    pu: 'Editando Carreta'
  },
  editParagraph: {
    zh: '在下方填写字段以在我们的系统中编辑挂车',
    en: '',
    pu: 'Preencha os campos logo abaixo para realizar a edição de uma carreta em nosso sistema'
  },
  deleteTitle: {
    zh: '你确定吗？',
    en: '',
    pu: 'Você tem certeza absoluta?'
  },
  deleteParagraph: {
    zh: '此操作无法撤消。将禁用挂车的所有相关操作。',
    en: '',
    pu: 'Essa ação não pode ser desfeita. Isso ira desativar a carreta de todas as operações relacionados a esses dados'
  },
  buttonCancel: {
    zh: '取消',
    en: '',
    pu: 'Cancelar'
  },
  buttonDelete: {
    zh: '是的，我想要删除',
    en: '',
    pu: 'Sim, quero excluir'
  },
  buttonRestore: {
    zh: '是的，我想要恢复',
    en: '',
    pu: 'Sim, quero restaurar'
  },
  restoreTitle: {
    zh: '你确定吗？',
    en: '',
    pu: 'Você tem certeza absoluta?'
  },
  restoreParagraph: {
    zh: '此操作将恢复挂车的所有数据，使其在我们的系统中再次变为活动状态，可进行各种操作。',
    en: '',
    pu: 'Essa ação vai restaurar todos os dados da carreta, tornando ele ativo para fazer todas as operações possíveis no nosso sistema'
  },

  // formTrailerData
  documentPlaceholder: {
    zh: '输入用户的文件',
    en: '',
    pu: 'Digite o documento do usuário'
  },
  createPlatePlaceholder: {
    zh: '',
    en: '',
    pu: 'Digite a placa do trailer'
  },
  createBrandPlaceholder: {
    zh: '',
    en: '',
    pu: 'Digite a marca do veículo'
  },
  createShed: {
    zh: '仓库',
    en: '',
    pu: 'Barracão'
  },
  createShedPlaceholder: {
    zh: '输入仓库名称',
    en: '',
    pu: 'Digite o nome do barracão'
  },
  rntrcPlaceholder: {
    zh: '输入RNTRC',
    en: '',
    pu: 'Digite o RNTRC'
  },
  statePlaceholder: {
    zh: '选择一个州',
    en: '',
    pu: 'Selecione um estado'
  },
  outsourcedDriver: {
    zh: '外包司机',
    en: '',
    pu: 'Motorista terceirizado'
  },

  // formMeasurementData
  capacityKg: {
    zh: '重量（公斤）',
    en: '',
    pu: 'Capacidade (KG)'
  },
  capacityKgPlaceholder: {
    zh: '输入以公斤为单位的容量',
    en: '',
    pu: 'Digite a capacidade em KG'
  },
  capacityM3: {
    zh: '容积（立方米）',
    en: '',
    pu: 'Capacidade (m³)'
  },
  capacityM3Placeholder: {
    zh: '输入立方米为单位的容量',
    en: '',
    pu: 'Digite a capacidade em metros cúbicos (m³)'
  },
  bodyTypePlaceholder: {
    zh: '选择一个车身类型',
    en: '',
    pu: 'Selecione um tipo de corpo'
  },
  weight: {
    zh: '重量',
    en: '',
    pu: 'Peso'
  },
  weightPlaceholder: {
    zh: '输入重量',
    en: '',
    pu: 'Digite o peso'
  },
  notApplicable: {
    zh: '不适用',
    en: '',
    pu: 'Não aplicavel'
  },
  closedChest: {
    zh: '封闭箱',
    en: '',
    pu: 'Caixa fechada'
  },
  open: {
    zh: '开放式',
    en: '',
    pu: 'Aberto'
  },
  bulk: {
    zh: '体积货',
    en: '',
    pu: 'Volume'
  },
  containerDoor: {
    zh: '集装箱门',
    en: '',
    pu: 'Porta do contêiner'
  },
  sider: {
    zh: '侧开式',
    en: '',
    pu: 'Sider'
  },
  truck: {
    zh: '卡车',
    en: '',
    pu: 'Caminhão'
  },
  stump: {
    zh: '树桩',
    en: '',
    pu: 'Toco'
  },
  mechanicalHorse: {
    zh: '机械马',
    en: '',
    pu: 'Cavalo mecânico'
  },
  van: {
    zh: '货车',
    en: '',
    pu: 'Van'
  },
  utility: {
    zh: '公用事业',
    en: '',
    pu: 'Utilitário'
  },
  others: {
    zh: '其他',
    en: '',
    pu: 'Outros'
  },
  // fornAdditionalData
  documentOwner: {
    zh: '文件所有者',
    en: '',
    pu: 'Documento do proprietário'
  },
  documentOwnerPlaceholder: {
    zh: '输入车辆文件',
    en: '',
    pu: 'Digite o documento do veículo'
  },
  stateRegistration: {
    zh: '州注册',
    en: '',
    pu: 'Registro Estadual'
  },
  stateRegistrationPlaceholder: {
    zh: '输入州注册',
    en: '',
    pu: 'Digite o registro estadual'
  },
  trackerCompany: {
    zh: '追踪公司',
    en: '',
    pu: 'Empresa de Rastreio'
  },
  trackerCompanyPlaceholder: {
    zh: '输入追踪公司',
    en: '',
    pu: 'Digite a empresa de rastreio'
  },
  trackerCompanyCode: {
    zh: '追踪公司代码',
    en: '',
    pu: 'Código da Empresa de Rastreio'
  },
  trackerCompanyCodePlaceholder: {
    zh: '输入追踪公司代码',
    en: '',
    pu: 'Digite o código da empresa de rastreio'
  }
}
