var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "billBox" },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c(
            "el-form",
            { staticClass: "selectClass" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "collectionCenter.billGenerationTime"
                            ),
                          },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              clearable: false,
                              type: "datetimerange",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              "default-time": ["00:00:00", "23:59:59"],
                              "range-separator": _vm.$t("collectionCenter.to"),
                              "start-placeholder": _vm.$t(
                                "collectionCenter.StartTime"
                              ),
                              "end-placeholder": _vm.$t(
                                "collectionCenter.EndTime"
                              ),
                            },
                            on: { change: _vm.dateChange },
                            model: {
                              value: _vm.timeValue,
                              callback: function ($$v) {
                                _vm.timeValue = $$v
                              },
                              expression: "timeValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.CustomerN"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              on: { focus: _vm.getAllCustomer },
                              model: {
                                value: _vm.queryForm.params.customerName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "customerName",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.customerName",
                              },
                            },
                            _vm._l(
                              _vm.customerNameList,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.username,
                                    value: item.username,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.pushStatus"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                clearable: "",
                              },
                              model: {
                                value: _vm.queryForm.params.pushType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "pushType",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.pushType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("collectionCenter.unpushed"),
                                  value: 0,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("collectionCenter.pushed"),
                                  value: 1,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("collectionCenter.pushFailure"),
                                  value: 2,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 8 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { staticClass: "col2" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "selectNumberClass autoWidth",
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "collectionCenter.pleaseChoose"
                                    ),
                                  },
                                  on: { change: _vm.changeNumberClick },
                                  model: {
                                    value: _vm.changeNumber,
                                    callback: function ($$v) {
                                      _vm.changeNumber = $$v
                                    },
                                    expression: "changeNumber",
                                  },
                                },
                                [
                                  _c("template", { slot: "prefix" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          (
                                            _vm.numberList.find(function (
                                              item
                                            ) {
                                              return (
                                                item.value === _vm.changeNumber
                                              )
                                            }) || {}
                                          ).label
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _vm._l(
                                    _vm.numberList,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.changeNumber === 0,
                                  expression: "changeNumber === 0",
                                },
                              ],
                              staticStyle: { width: "auto" },
                            },
                            [
                              _c("el-form-item", { staticClass: "delMl" }, [
                                _c(
                                  "div",
                                  { staticClass: "MaxHeight" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        autosize: "",
                                        placeholder: _vm.$t(
                                          "collectionCenter.BatchQuery"
                                        ),
                                      },
                                      nativeOn: {
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.deliveryNumberEnter($event)
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.queryForm.params.deliveryNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "deliveryNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.deliveryNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.changeNumber === 1,
                                  expression: "changeNumber === 1",
                                },
                              ],
                              staticStyle: { width: "auto" },
                            },
                            [
                              _c("el-form-item", { staticClass: "delMl" }, [
                                _c(
                                  "div",
                                  { staticClass: "MaxHeight" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        autosize: "",
                                        placeholder: _vm.$t(
                                          "collectionCenter.BatchQuery"
                                        ),
                                      },
                                      nativeOn: {
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.customerOrderNumberEnter(
                                            $event
                                          )
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.queryForm.params
                                            .customerOrderNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "customerOrderNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.customerOrderNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value: "btn:collection-center:finance:bill:search",
                          expression:
                            "'btn:collection-center:finance:bill:search'",
                          arg: "remove",
                        },
                      ],
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-search",
                      },
                      on: { click: _vm.searchClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.search")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value: "btn:collection-center:finance:bill:reset",
                          expression:
                            "'btn:collection-center:finance:bill:reset'",
                          arg: "remove",
                        },
                      ],
                      attrs: { icon: "el-icon-refresh", size: "mini" },
                      on: { click: _vm.resetClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value: "btn:collection-center:finance:bill:export",
                          expression:
                            "'btn:collection-center:finance:bill:export'",
                          arg: "remove",
                        },
                      ],
                      class:
                        _vm.idList.length <= 0 && _vm.isClick
                          ? "onlyClickOnce10"
                          : "",
                      attrs: { icon: "el-icon-upload2", size: "mini" },
                      on: { click: _vm.download },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.export")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:finance:bill:BulkExport",
                          expression:
                            "'btn:collection-center:finance:bill:BulkExport'",
                          arg: "remove",
                        },
                      ],
                      class: _vm.isClickAll ? "onlyClickOnce10" : "",
                      attrs: { size: "mini" },
                      on: { click: _vm.downloadBatch },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.BulkExport")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value: "btn:collection-center:finance:bill:BatchPush",
                          expression:
                            "'btn:collection-center:finance:bill:BatchPush'",
                          arg: "remove",
                        },
                      ],
                      class:
                        _vm.idList.length <= 0 && _vm.isClick1
                          ? "onlyClickOnce10"
                          : "",
                      attrs: { size: "mini" },
                      on: { click: _vm.batchPush },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.BatchPush")))]
                  ),
                  _c(
                    "el-popover",
                    {
                      staticClass: "popoverClass",
                      attrs: {
                        placement: "bottom-start",
                        title: "",
                        trigger: "hover",
                        content: "x",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permit",
                              rawName: "v-permit:remove",
                              value:
                                "btn:collection-center:finance:bill:Import",
                              expression:
                                "'btn:collection-center:finance:bill:Import'",
                              arg: "remove",
                            },
                          ],
                          attrs: { slot: "reference", size: "mini" },
                          slot: "reference",
                        },
                        [_vm._v(_vm._s(_vm.$t("collectionCenter.Import")))]
                      ),
                      _c("div", [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { margin: "10px" },
                                attrs: { size: "mini" },
                                on: { click: _vm.templateDownloadClick },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("collectionCenter.TemplateDownload")
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "el-upload",
                              {
                                staticClass: "uploadFileClass",
                                attrs: {
                                  action: "",
                                  limit: 1,
                                  "show-file-list": false,
                                  "file-list": _vm.fileList,
                                  "before-upload": _vm.beforeUpload,
                                  "http-request": _vm.uploadDataClick,
                                },
                              },
                              [
                                _c("el-button", { attrs: { size: "mini" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("collectionCenter.uploadData")
                                    )
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              ref: "tableRef",
              attrs: { border: "", data: _vm.tableDate, "max-height": 600 },
              on: { select: _vm.batchSelect, "select-all": _vm.batchSelectAll },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.ind"),
                  type: "index",
                  width: "60",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.queryForm.pageNumber - 1) *
                                _vm.queryForm.pageSize +
                                scope.$index +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "customerName",
                  align: "center",
                  label: _vm.$t("collectionCenter.CustomerName"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "customerOrderNumber",
                  align: "center",
                  label: _vm.$t("collectionCenter.customerOrderNum"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "deliveryNumber",
                  align: "center",
                  label: _vm.$t("collectionCenter.logisticsNum"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  align: "center",
                  label: _vm.$t("collectionCenter.billGenerationT"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "locusPushTime",
                  align: "center",
                  label: _vm.$t("collectionCenter.trackPushTime"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "collectFees",
                  align: "center",
                  label: _vm.$t("collectionCenter.collectCost"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "distributionFees",
                  align: "center",
                  label: _vm.$t("collectionCenter.distributionCost"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "operatingFees",
                  align: "center",
                  label: _vm.$t("collectionCenter.operatingCost"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "exceptionalFees",
                  align: "center",
                  label: _vm.$t("collectionCenter.warehouseAnomalyCharge"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "orderWeight",
                  align: "center",
                  label: _vm.$t("collectionCenter.orderWeight"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "costWeight",
                  align: "center",
                  label: _vm.$t("collectionCenter.chargedWeight"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "pushTypeName",
                  align: "center",
                  label: _vm.$t("collectionCenter.stateZT"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "pushCount",
                  align: "center",
                  label: _vm.$t("collectionCenter.NumberOfPushes"),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-pagination", {
            staticStyle: { margin: "10px 0" },
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              "current-page": _vm.queryForm.pageNumber,
              "page-sizes": [10, 20, 50, 100, 500, 1000],
              "page-size": _vm.queryForm.pageSize,
              total: _vm.total,
              background: "",
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "current-change": _vm.getTablePage,
              "size-change": _vm.getPageSize,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "90%",
            "show-close": false,
            "close-on-click-modal": false,
            visible: _vm.openDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.openDialog = $event
            },
          },
        },
        [
          _c("Import", {
            attrs: { "bill-import-list": _vm.billImportList },
            on: {
              closeDialog: _vm.closeImportDialog,
              getTable: _vm.importToGetTable,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }