<template>
  <div class="warehousingBox">
    <!-- 入库 -->
    <div class="warehousingClass">
      <div class="changeClass">
        <div class="scanClass scanRecords">
          <!-- 网点派件入库扫描： -->
          <span style="display: block;">{{ $t('operatingPlatform.dispatchwarehouseScan') }}</span>
          <el-input
            v-model.trim="warehousingScan"
            :placeholder="$t('operatingPlatform.PleaseScan')"
            prefix-icon="el-icon-full-screen"
            clearable
            class="iconBtn"
            @keyup.enter.native="addTable"
          >
            <!-- 已扫描 -->                                                                               <!-- 票 -->
            <template slot="append">{{ $t('collectionCenter.scanned') }}<span>{{ scanTableData.length }}</span>{{ $t('collectionCenter.ticket') }}</template>
          </el-input>
        </div>
        <div class="scanClass">
          <span style="display: block;">{{ $t('operatingPlatform.weighingMethod') }}</span><!-- 称重方式： -->
          <el-radio-group v-model="weighingMode" @input="DeviceSetup" @change="setDevice">
            <el-radio-button :label="1">{{ $t('operatingPlatform.Other') }}</el-radio-button><!-- 其它 -->
            <el-radio-button :label="2">{{ $t('operatingPlatform.SerialPort') }}</el-radio-button><!-- 串口 -->
            <el-radio-button :label="3">{{ $t('operatingPlatform.racerDevices') }}</el-radio-button><!-- 快手设备 -->
          </el-radio-group>
        </div>
        <div v-show="weighingMode===2" class="scanClass">
          <span style="display: block;">{{ $t('operatingPlatform.weighingEquipmentBrand') }}</span><!-- 称重设备品牌： -->
          <el-radio-group v-model="equipmentBrand" @input="setupElectronicScales">
            <el-radio-button label="TSC-L">TSC-L</el-radio-button>
            <el-radio-button label="PRIX-3FIT">PRIX-3FIT</el-radio-button>
          </el-radio-group>
        </div>
        <div class="scanClass">
          <!-- 输入重量： -->
          <span style="display: block;">{{ $t('operatingPlatform.inputWeight') }}</span>
          <el-input
            v-model="warehousingKG"
            :placeholder="$t('operatingPlatform.pleaseInput')"
            class="warehousingKGClass"
            clearable
            :disabled="weighingMode===2"
            @blur="checkNumber"
            @keyup.enter.native="addTable"
          /> kg
          <!-- 请填写最大重量不能超过500kg的数字，可保留3位有效小数 -->
          <div v-show="!isSuc" class="warehousingKGTitle">{{ $t('operatingPlatform.inputNumberHint') }}</div>
          <div v-show="isSuc" class="warehousingKGTitle" />
        </div>
        <div class="scanClass">
          <!-- 长*宽*高 (cm) -->
          <span style="display: block;">{{ $t('operatingPlatform.Comprimento') }}</span>
          <el-input
            v-model="long"
            :placeholder="$t('operatingPlatform.long')"
            class="volume"
            clearable
            @blur="checkVolume"
            @keyup.enter.native="addTable"
          />x
          <el-input
            v-model="width"
            :placeholder="$t('operatingPlatform.width')"
            class="volume"
            clearable
            @blur="checkVolume"
            @keyup.enter.native="addTable"
          />x
          <el-input
            v-model="height"
            :placeholder="$t('operatingPlatform.height')"
            class="volume"
            clearable
            @blur="checkVolume"
            @keyup.enter.native="addTable"
          />
          <div v-show="!isLong || !isWidth || !isHeight" class="warehousingKGTitle">{{ $t('collectionCenter.enterNumber3significantDecimals') }}</div>
        </div>
        <el-button
          class="onlyClickOnce"
          icon="el-icon-upload2"
          @click="upload"
        >{{ $t('operatingPlatform.Upload') }}</el-button><!-- 上传 -->
      </div>

      <el-divider />
      <div class="tableClass">
        <el-table
          :max-height="600"
          :data="scanTableData"
          border
        >
          <el-table-column
            prop="bagNumber"
            align="center"
            :label="$t('collectionCenter.BagNumber')"
          /><!-- 袋号 -->
          <el-table-column
            prop="scanWaybillNumber"
            align="center"
            :label="$t('operatingPlatform.waybillNumber')"
          /><!-- 运单号 -->
          <el-table-column
            prop="weight"
            align="center"
            :label="$t('operatingPlatform.scanWeight')"
          /><!-- 称重重量 -->
          <el-table-column
            prop="volumeWeight"
            align="center"
            :label="$t('operatingPlatform.volumeWeight')"
          /><!-- 体积重 -->
          <el-table-column
            prop="scanTime"
            align="center"
            :label="$t('operatingPlatform.scanTime')"
          /><!-- 扫描时间 -->
          <el-table-column
            prop="operator"
            align="center"
            :label="$t('operatingPlatform.operator')"
          /><!-- 操作人 -->
          <el-table-column
            :label="$t('operatingPlatform.operation')"
            fixed="right"
            width="100"
            align="center"
          ><!-- 操作 -->
            <template slot-scope="scope">
              <el-popconfirm
                :title="$t('operatingPlatform.deleteSure')"
                @confirm="scanDelClick(scope.row, scope.$index)"
              ><!-- 确定删除吗？ -->
                <el-button slot="reference" type="danger" size="mini">{{ $t('operatingPlatform.Delete') }}</el-button><!-- 删除 -->
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 隐藏提示音 -->
    <div v-show="false">
      <audio ref="errRef" :src="errorMp3" controls="controls" />
      <audio ref="okRef" :src="okMp3" controls="controls" />
    </div>
  </div>
</template>

<script>
const okMp3 = '/static/scan_success.mp3';
const errorMp3 = '/static/scan_fail.mp3';
import {
  pcSiteDeliveryWarehouseSubmit,
  pcSiteDeliveryWarehouseScanDeleted,
  pcSiteDeliveryWarehouseScan
} from '@/api/operatingPlatform';
import { apiGetWeight, apiStartPortsList, apiOPenComPort, apiStartUp } from '@/api/serialPort';
import { Loading } from 'element-ui';
import { checkSumber } from '@/utils/validate';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'Dispatch',
  data() {
    return {
      okMp3,
      errorMp3,
      isLong: true,
      isWidth: true,
      isHeight: true,
      long: '', // 长宽高
      width: '',
      height: '',
      scanTableData: [],
      warehousingScan: '',
      warehousingKG: 0,
      isSuc: true, // 用户输入是否正确
      equipmentBrand: '', // 称重品牌 TSC-L| PRIX-3FIT
      weighingMode: 1, // 称重方式 1：其它 2：串口 3:快手
      SetDevice: [], // 串口列表
      connectStatus: 0, // 0：未连接 1：已连接
      timer: null, // 循环获取电子秤重量定时器
      scanList: {}, // 缓存用
      bagNumberBox: [], // 保存上一次扫描的袋号(删除袋号后扫描运单号，取上一次的袋号)
      // ws
      wsObj: {
        ws: null, // WebSocket
        timeVal: 30 * 1000, // 心跳检测间隔事件
        index: 0, // 重试次数
        time: 0
      }
    };
  },
  watch: {
    warehousingKG(value) {
      const warehousingKG = Number(value);
      const reg = new RegExp(/^[0-9]+\.?[0-9]{0,3}$/);
      if (!reg.test(warehousingKG)) {
        // this.$message.warning('请填写数字，可保留两位有效小数');
        this.isSuc = false;
      } else if (warehousingKG < 0 || warehousingKG > 500) {
        // this.$message.warning('最大重量不能超过30kg');
        this.isSuc = false;
      } else {
        this.isSuc = true;
      }
    },
    long(val) {
      if (!checkSumber(val) && val) {
        this.isLong = false;
      } else {
        this.isLong = true;
      }
    },
    width(val) {
      if (!checkSumber(val) && val) {
        this.isWidth = false;
      } else {
        this.isWidth = true;
      }
    },
    height(val) {
      if (!checkSumber(val) && val) {
        this.isHeight = false;
      } else {
        this.isHeight = true;
      }
    },
    scanTableData() {
      // 扫描数据为空时，删除袋号及其缓存，禁止直接扫描进运单号
      if (this.scanTableData.length <= 0) {
        this.warehousingScan = '';
        this.bagNumberBox = [];
        this.scanList['dw' + this.$store.state.user.id] = {
          scanTableData: this.scanTableData,
          bagNumberBox: this.bagNumberBox
        };
        const a = JSON.stringify(this.scanList);
        localStorage.setItem('scanListLocal', a);
      }
    }
  },
  created() {
    if (localStorage.getItem('scanListLocal')) {
      this.scanList = JSON.parse(window.atob(localStorage.getItem('scanListLocal'))) || {};
      const who = 'dw' + this.$store.state.user.id;
      if (this.scanList[who]) {
        this.scanTableData = this.scanList[who].scanTableData;
        this.bagNumberBox = this.scanList[who].bagNumberBox;
      } else {
        this.scanTableData = [];
        this.bagNumberBox = [];
      }
    }
  },
  beforeDestroy() {
    // 页面销毁前清除定时器
    clearInterval(this.timer);
  },
  methods: {
    checkVolume() {
      if (this.long && this.isLong) {
        this.long = Number(this.long);
      }
      if (this.width && this.isWidth) {
        this.width = Number(this.width);
      }
      if (this.height && this.isHeight) {
        this.height = Number(this.height);
      }
    },
    checkNumber() {
      const reg = new RegExp(/^[0-9]+\.?[0-9]{0,3}$/);
      if (reg.test(this.warehousingKG)) {
        this.warehousingKG = Number(this.warehousingKG);
      }
    },
    addTable() {
      const long = this.long ? 't' : 'f';
      const width = this.width ? 't' : 'f';
      const height = this.height ? 't' : 'f';
      if (long !== width || long !== height || width !== height) {
        this.isLong = false;
        return;
      } else {
        this.isLong = true;
      }
      if (!this.isLong || !this.isWidth || !this.isHeight || !this.isSuc) return;
      if ((this.long && !this.isLong) || (this.width && !this.isWidth) || (this.height && !this.isHeight)) return;

      if (this.warehousingScan) {
        // 判断是袋号还是包裹号   袋号：6 - 8位数字、前缀pn
        const bagReg = /^[0-9]{6,8}|BG[0-9]{6,8}$/;
        const bagPrefix = this.warehousingScan.slice(0, 2);
        let isAdd = '';
        let isBagNum = false; // 代替 bagReg.test(this.warehousingScan) || bagPrefix === 'pn' || bagPrefix === 'PN' 判断
        // 判断扫描的是袋号还是包裹号
        if (bagReg.test(this.warehousingScan) || bagPrefix === 'pn' || bagPrefix === 'PN') {
          isBagNum = true;
          isAdd = this.scanTableData.some(el => el.bagNumber === this.warehousingScan);
        } else {
          isBagNum = false;
          isAdd = this.scanTableData.some(el => el.scanWaybillNumber === this.warehousingScan);
        }
        // 查询有无重复值
        if (isAdd && this.scanTableData.length > 0) {
          this.$message({
            message: this.$t('operatingPlatform.bagOrPackageRepeat'), // '当前袋号/运单号重复'
            type: 'warning'
          });
          this.$refs.errRef.play();
          return;
        }
        this.checkNumber();
        const warehousingKG = this.warehousingKG === 0 || !this.warehousingKG ? '' : this.warehousingKG;
        let scanForm = {};
        if (isBagNum) {
          scanForm = {
            transitBagNumber: this.warehousingScan, // 袋号
            scanNumber: '', // 运单号
            pdaCode: '',
            length: '',
            width: '',
            height: ''
          };
        } else {
          scanForm = {
            transitBagNumber: '', // 袋号
            scanNumber: this.warehousingScan, // 运单号
            pdaCode: '',
            length: this.long,
            width: this.width,
            height: this.height
          };
        }
        pcSiteDeliveryWarehouseScan(scanForm).then(res => {
          if (res.status === 'OK') {
            this.$refs.okRef.play();
            if (isBagNum) {
              this.scanTableData.unshift({
                bagNumber: this.warehousingScan,
                scanWaybillNumber: '',
                waybillId: res.data.waybillId,
                weight: '',
                deliveryScanId: res.data.scanId,
                volumeWeight: res.data.volumeWeight,
                scanTime: res.data.scanTime,
                operator: this.$store.getters.realName
              });
              this.bagNumberBox.push(this.warehousingScan);
            } else {
              // 包裹号
              this.scanTableData.unshift({
                bagNumber: '',
                scanWaybillNumber: this.warehousingScan,
                waybillId: res.data.waybillId,
                weight: warehousingKG,
                deliveryScanId: res.data.scanId,
                volumeWeight: res.data.volumeWeight,
                scanTime: res.data.scanTime,
                operator: this.$store.getters.realName
              });
            }
            this.scanList['dw' + this.$store.state.user.id] = {
              scanTableData: this.scanTableData,
              bagNumberBox: this.bagNumberBox
            };
            const a = JSON.stringify(this.scanList);
            localStorage.setItem('scanListLocal', a);
            this.isLong = true;
            this.isWidth = true;
            this.isHeight = true;
            this.warehousingScan = '';
          } else {
            this.warehousingScan = '';
          }
        }).catch(() => {
          this.warehousingScan = '';
          this.$refs.errRef.play();
          this.long = '';
          this.width = '';
          this.height = '';
          this.warehousingKG = '';
          this.$nextTick(() => {
            this.isLong = true;
            this.isWidth = true;
            this.isHeight = true;
          });
        });
      }
    },
    scanDelClick(row, index) { // 扫件——删除
      const delForm = {
        scanIdList: row.deliveryScanId
      };
      pcSiteDeliveryWarehouseScanDeleted(delForm).then(res => {
        if (res.status === 'OK') {
          this.scanTableData.splice(index, 1);
          if (row.scanNumber == this.warehousingScan || row.transitBagNumber == this.warehousingScan) {
            this.warehousingScan = '';
          }
          if (this.scanTableData.length <= 0) {
            this.warehousingScan = '';
            this.bagNumberBox = [];
          } else {
            // 判断是袋号还是包裹号   袋号：6 - 8位数字、前缀pn
            const bagReg = /^[0-9]{6,8}|BG[0-9]{6,8}$/;
            const bagPrefix = row.bagNumber.slice(0, 2);
            if (!row.scanWaybillNumber && (bagReg.test(row.bagNumber) || bagPrefix === 'pn' || bagPrefix === 'PN')) {
              this.bagNumberBox.pop();
            }
          }
          this.scanList['dw' + this.$store.state.user.id] = {
            scanTableData: this.scanTableData,
            bagNumberBox: this.bagNumberBox
          };
          const a = JSON.stringify(this.scanList);
          localStorage.setItem('scanListLocal', a);
        }
      }).catch({});
    },
    upload() {
      if (this.scanTableData.length <= 0) {
        this.$message.warning(this.$t('operatingPlatform.nullSubmit')); // '无数据提交'
      } else {
        //                    '是否确认上传?'                        '提示'
        this.$confirm(this.$t('operatingPlatform.AreYouSureSubmit'), this.$t('operatingPlatform.tips'), {
          confirmButtonText: this.$t('operatingPlatform.confirm'), // '确定'
          cancelButtonText: this.$t('operatingPlatform.cancel') // '取消'
        }).then(() => {
          const scanTableList = cloneDeep(this.scanTableData);

          // 过滤空袋列 filter
          const stdF = scanTableList.filter(list => list.scanWaybillNumber);
          const submit = stdF.map(del => {
            const weight = del.weight || 0;
            const scanWaybillNumber = del.scanWaybillNumber;
            const waybillId = del.waybillId;
            const deliveryScanId = del.deliveryScanId;
            const volumeWeight = del.volumeWeight;
            const scanTime = del.scanTime;
            return {
              scanWaybillNumber,
              waybillId,
              weight,
              deliveryScanId,
              volumeWeight,
              scanTime
            };
          });
          const cwForm = {
            warbillList: submit
          };
          pcSiteDeliveryWarehouseSubmit(cwForm).then(res => {
            if (res.status === 'OK') {
              this.warehousingScan = '';
              this.warehousingKG = 0;
              this.scanTableData = [];
              this.bagNumberBox = [];
              this.scanList['dw' + this.$store.state.user.id] = {
                scanTableData: this.scanTableData,
                bagNumberBox: this.bagNumberBox
              };
              const a = JSON.stringify(this.scanList);
              localStorage.setItem('scanListLocal', a);
              this.$message.success(res.data);
            } else {
              this.scanTableData = [];
              this.bagNumberBox = [];
              this.scanList['dw' + this.$store.state.user.id] = {
                scanTableData: this.scanTableData,
                bagNumberBox: this.bagNumberBox
              };
              const a = JSON.stringify(this.scanList);
              localStorage.setItem('scanListLocal', a);
              this.warehousingKG = 0;
              this.$message.error(res.msg);
            }
          }).catch({});
        }).catch(() => {
          //
        });
      }
    },
    /**
     * @description: 检测插件是否启动
     * @return {*}
     */
    DeviceSetup() {
      // 选择串口时进行插件开启校验
      if (this.weighingMode === 2) {
        // 进行load
        const load = Loading.service({
          fullscreen: true,
          lock: true,
          spinner: 'el-icon-loading',
          text: this.$t('operatingPlatform.plugInBeingConnected'),
          background: 'rgba(250, 250, 250, 0.6)'
        });
        apiStartUp().then((res) => {
          if (res.code !== 200) {
            this.$message.error(this.$t('operatingPlatform.pluginIsNotStarted'));
            // 关闭load
            load.close();
            // 称重设备评判清空
            this.equipmentBrand = '';
            return;
          }
          // 获取串口列表
          this.getSerialPortList();
          // 关闭load
          load.close();
        }).catch(() => {
          // 关闭load
          load.close();
        });
      } else {
        // 选择其它时关闭电子称重量获取
        this.closeGetWeight();
        this.equipmentBrand = '';
      }
    },
    /**
     * @description: 获取串口列表
     * @return {*}
     */
    // getSerialPortList() {
    //   apiStartPortsList().then((res) => {
    //     if (res.code === 200) {
    //       this.SetDevice = res.data;
    //     }
    //   });
    // },
    setupElectronicScales() {
      // 是否初始化成功电子秤
      apiStartUp().then((res) => {
        if (res.code !== 200) {
          this.$message.error(this.$t('operatingPlatform.pluginIsNotStarted'));
          this.equipmentBrand = '';
          return;
        }
        this.connectDevice();
      });
    },
    /**
     * @description: 连接设备
     * @return {*}
     */
    async connectDevice() {
      const load = Loading.service({
        fullscreen: true,
        lock: true,
        spinner: 'el-icon-loading',
        text: this.$t('operatingPlatform.DeviceInitializationInProgress') + '...',
        background: 'rgba(250, 250, 250, 0.6)'
      });
      // 是否连接
      let isConnected = false;
      for (let i = 0; i < this.SetDevice.length; i++) {
        // 已经连接成功不再继续连接
        if (isConnected) {
          return;
        }
        await this.initElectronicScales(this.SetDevice[i])
          .then((res) => {
            // 该串口连接成功
            isConnected = !!res;
          });
      }
      if (isConnected) {
        this.$message({
          message: this.$t('operatingPlatform.DeviceInitializationSuccessful'),
          type: 'success'
        });
        this.connectStatus = 1;
        this.$nextTick(() => {
          this.$refs.inputRef.$refs.input.focus();
        });
        // 循环获取电子秤重量
        this.getWeight();
        load.close();
      } else {
        load.close();
        this.$message.error(this.$t('operatingPlatform.ElectronicScaleInitializationFailed'));
        this.equipmentBrand = '';
        this.connectStatus = 0;
      }
    },
    /**
     * @description: 获取串口列表
     * @return {*}
     */
    getSerialPortList() {
      apiStartPortsList().then((res) => {
        if (res.code === 200) {
          this.SetDevice = res.data;
        }
      });
    },
    /**
     * @description: 初始化电子秤
     * @param { String } device 串口（电脑通道）
     * @return {*}
     */
    initElectronicScales(device) {
      return new Promise((resolve, reject) => {
        // 请求接口选中指定品牌和串口进行读数
        apiOPenComPort({
          comPort: device,
          scaleType: this.equipmentBrand
        })
          .then((res) => {
            // 连接失败直接跳出
            if (res.code === 500) {
              reject();
            } else {
              // 连接成功并且获取电子秤读书三次成功后跳出
              const getArr = [apiGetWeight(), apiGetWeight(), apiGetWeight()];
              Promise.all(getArr).then((res) => {
                console.log(res);
                // 0：连接失败 1：连接成功
                let connected = 0;
                // 循环判断是否获取到电子秤读数进行处理
                res.forEach((item) => {
                  if (item.code === 200) {
                    connected = 1;
                  } else {
                    connected = 0;
                  }
                });
                // 对连接成功与连接失败进行处理
                console.log('connected', connected);
                resolve(connected);
              });
            }
          })
          .catch(() => {
            // 直接跳出
            reject();
          });
      });
    },
    /**
     * @description: 循环获取重量
     * @return {*}
     */
    getWeight() {
      this.timer = setInterval(() => {
        apiGetWeight()
          .then((res) => {
            if (res.code === 200) {
              this.warehousingKG = res.data;
            } else {
              clearInterval(this.timer);
            }
          });
      }, 1000);
    },
    /**
     * @description: 停止获取电子秤重量
     * @return {*}
     */
    closeGetWeight() {
      clearInterval(this.timer);
    },
    /**
     * @description: 设置设备
     */
    setDevice() {
      if (this.weighingMode === 3) {
        // 连接快手设备
        // 进行load
        const load = Loading.service({
          fullscreen: true,
          lock: true,
          spinner: 'el-icon-loading',
          text: this.$t('operatingPlatform.plugInBeingConnected'), // 正在连接插件
          background: 'rgba(250, 250, 250, 0.6)'
        });
        apiStartUp().then((res) => {
          if (res.code !== 200) {
            this.$message.error(this.$t('operatingPlatform.pluginIsNotStarted')); // 插件未启动，请打开插件
            // 关闭load
            load.close();
            // 称重设备评判清空
            this.equipmentBrand = '';
            return;
          }
          // 关闭load
          load.close();
          // 连接插件快手设备
          this.creatWs();
        }).catch(() => {
          // 关闭load
          load.close();
        });
      } else {
        this.wsMsgClose();
        // 删除快手设备心跳
        clearInterval(this.onlineTime);
      }
    },
    // WebSocket 连接
    creatWs() {
      // ws连接
      this.wsObj.ws = new WebSocket('ws://127.0.0.1:3000/v1/wms/express/collect/storage/register');

      if (this.wsObj.ws instanceof WebSocket) {
        this.wsObj.ws.addEventListener('open', this.handleWsOpend);
        this.wsObj.ws.addEventListener('message', this.handleWsMessage);
        this.wsObj.ws.addEventListener('close', this.hanldeWsClose);
        this.wsObj.ws.addEventListener('error', this.handleWsError);
      }
      // 关闭设置设备弹窗
      this.SetDeviceDialog = false;
    },
    // 首次连接ws
    handleWsOpend() {
      console.log('操作中心 连接成功');
      // 注册用户
      this.setWsRegisteredUser();
    },
    // 注册用户
    setWsRegisteredUser() {
      const { ws } = this.wsObj;
      const data = {
        token: this.$store.state.user.token,
        language: this.$store.state.language.language
      };
      ws.send(JSON.stringify(data));
    },
    // 收到消息
    handleWsMessage(e) {
      const res = JSON.parse(e.data);
      if (res.status === 'OK') {
        this.warehousingScan = '';
        this.warehousingKG = '';
        this.long = '';
        this.width = '';
        this.height = '';
        this.wsMsgSuccess(res);
        return;
      }
      // 失败处理
      if (!res.code) {
        this.wsMsgErr(res);
        return;
      }
      // 成功处理
      this.wsMsgSuccess(res);
    },
    // 失败处理
    wsMsgErr(res) {
      this.$message.error(res.msg);
    },
    // 成功处理
    wsMsgSuccess(res) {
      // console.log('收到消息，成功');
      // msg会在第一连接成功时返回，这里判断msg给一个提示
      const { code, height, length, weight, width, msg } = res;
      if (msg) {
        this.$message.success(msg);
        // 进行心跳连接
        this.setWsHeartbeat();
        return;
      }
      console.log('快手数据', code, weight, height, length, width);
      this.warehousingScan = code;
      this.warehousingKG = weight;
      this.long = length;
      this.width = width;
      this.height = height;
      // 这里是成功获取到数据的处理
      // this.$message.success('这里是成功获取到参数的提示');
      this.addTable();
    },
    // ws连接出错
    handleWsError() {
      console.log('连接出错');
      this.wsObj.index = 0;
      clearTimeout(this.wsObj.time);
      const str = this.$i18n.t('operationTips.connectionError');
      this.$message.error(str);
    },
    // 进行心跳连接
    setWsHeartbeat() {
      // 删除电子秤获取重量电子秤
      clearInterval(this.timer);
      // 删除快手设备心跳
      clearInterval(this.onlineTime);
      const { ws } = this.wsObj;
      const data = {
        path: '/v1/wms/express/storage/register',
        params: {}
      };
      console.log(data, JSON.stringify(data), '心跳请求');

      ws.send(JSON.stringify(data));
      this.onlineTime = setInterval(() => {
        ws.send(JSON.stringify(data));
      }, 3000);
    },
    /**
     * @description 关闭ws
     */
    wsMsgClose() {
      const { ws } = this.wsObj;
      ws.close();
    }
  }
};
</script>

<style lang="scss" scoped>
  .changeClass{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .scanRecords ::v-deep .el-input-group__append{
    background-color: #fff;
    color: #4D4D4D;
    span{
      color: #F56C6C;
      font-size: 19px;
      margin: 0 5px;
    }
  }
  .volume{
    width: 100px !important;
    margin: 0 5px;
  }
  .changeClass ::v-deep .el-button{
    background: #42b983;
    color: #fff;
  }
  // 防止按钮重复点击
  @keyframes throttle{
    from{
      filter: opacity(40%);
      pointer-events: none;
    }
    to{
      pointer-events: all;
    }
  }
  .onlyClickOnce{
    margin-top: 25px;
    animation: throttle 2s step-end forwards;
  }
  .onlyClickOnce:active{
    filter: opacity(40%);
    animation: none;
  }
.tableClass{
  margin-top: 20px;
}
.warehousingBox{
  padding: 20px;
  box-sizing: border-box;
}
.warehousingClass{
  .scanClass{
    margin-right: 40px;
  }
  .iconBtn::after{
    position: absolute;
    content: "-";
    top: 10px;
    left: 15px;
    transform: scaleX(2.3);
    color: #bbb;
  }
  .warehousingKGTitle{
    height: 20px;
    animation: show 0.5s;
    color: #F56C6C;
    font-size: 13px;
    margin-top: 5px;
  }
  @keyframes show{
    0%{opacity:0}
    100%{opacity:1}
  }
}
::v-deep .el-input{
  width: 350px;
}
::v-deep .el-input.warehousingKGClass{
  width: 100px !important;
}
.warehousingKGClass ::v-deep .el-input__inner{
  text-align: center;
}
</style>
