import i18n from '@/lang';

// 付款状态
export const PAYABLE_STATUS = Object.freeze({
  0: {
    label: i18n.t('receivablePayable.PendingPayment1'), // 待核付
    value: 0
  },
  1: {
    label: i18n.t('receivablePayable.Paid1'), // 已核付
    value: 1
  },
  2: {
    label: i18n.t('receivablePayable.PartialPayment'), // 部分核付
    value: 2
  },
  3: {
    label: i18n.t('receivablePayable.付款中'), // 付款中
    value: 3
  },

  4: {
    label: i18n.t('receivablePayable.付款失败'), // 付款失败
    value: 4
  }
});
