<template>
  <div class="warp">
    <el-form label-width="auto">
      <el-row :gutter="20">
        <el-descriptions :colon="false" :column="3" direction="vertical">
          <!--收件人-->
          <el-descriptions-item :label="$t('newOrder.RecipientName')">
            <div style="display: flex;">
              <span v-if="showDetail" style="font-weight: bold">{{ encryptionReceiverInfo.receiveName || '--' }}</span>
              <span v-else style="font-weight: bold">{{ receiverInfo.receiveName || '--' }}</span>
              <span
                v-if="receiverInfo.receiveName"
                class="iconEye"
                @click="handlerhidden('name')"
              >
                <el-tooltip placement="top-start">
                  <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                  <svg-icon icon-class="iconEye" />
                </el-tooltip>
              </span>
            </div>
          </el-descriptions-item>
          <!--收件人公司-->
          <el-descriptions-item :label="$t('newOrder.RecipientCompany')">
            <div style="display: flex;">
              <span v-if="showDetail5" style="font-weight: bold">
                {{ encryptionReceiverInfo.receiveCompany || '--' }}
              </span>
              <span v-else style="font-weight: bold">{{ receiverInfo.receiveCompany || '--' }}</span>
              <span
                v-if="receiverInfo.receiveCompany"
                class="iconEye"
                @click="handlerhidden('receiveCompany')"
              >
                <el-tooltip placement="top-start">
                  <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                  <svg-icon icon-class="iconEye" />
                </el-tooltip>
              </span>
            </div>
          </el-descriptions-item>
          <!--收件人手机-->
          <el-descriptions-item :label="$t('newOrder.Recipientphone')">
            <div style="display: flex;">
              <span v-if="showDetail1" style="font-weight: bold">{{
                encryptionReceiverInfo.receiveMobile || '--'
              }}</span>
              <span v-else style="font-weight: bold">{{ receiverInfo.receiveMobile || '--' }}</span>
              <span
                v-if="receiverInfo.receiveMobile"
                class="iconEye"
                @click="handlerhidden('receivePhone')"
              >
                <el-tooltip placement="top-start">
                  <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                  <svg-icon icon-class="iconEye" />
                </el-tooltip>
              </span>
            </div>
          </el-descriptions-item>
          <!--街道-->
          <el-descriptions-item :label="$t('newOrder.Street1')">
            <div>{{ receiverInfo.receiveStreet || '--' }}</div>
          </el-descriptions-item>
          <!--收件人门牌号-->
          <el-descriptions-item :label="$t('newOrder.doorNumber')">
            <div>{{ receiverInfo.receiveHouseNumber || '--' }}</div>
          </el-descriptions-item>
          <!--收件人区-->
          <el-descriptions-item :label="$t('newOrder.RecipientDistrict')">
            <div>{{ receiverInfo.receiveArea || '--' }}</div>
          </el-descriptions-item>
          <!--收件人城市-->
          <el-descriptions-item :label="$t('newOrder.RecipientCity')">
            <div>{{ receiverInfo.receiveCity || '--' }}</div>
          </el-descriptions-item>
          <!--收件人州-->
          <el-descriptions-item :label="$t('newOrder.RecipientState')">
            <div>{{ receiverInfo.receiveState || '--' }}</div>
          </el-descriptions-item>
          <!--收件人邮编-->
          <el-descriptions-item :label="$t('newOrder.RecipientZipCode')">
            <div>{{ receiverInfo.receiveZipcode || '--' }}</div>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :colon="false" :column="1" direction="vertical">
          <!--收件人地址补充-->
          <el-descriptions-item :label="$t('GlobalSetObj.recipientComplement')">
            <div>{{ receiverInfo.receiveComplement || '--' }}</div>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :colon="false" :column="3" direction="vertical">
          <!--收件人税号-->
          <el-descriptions-item :label="$t('newOrder.RecipientCPF')">
            <div style="display: flex;">
              <span v-if="showDetail4" style="font-weight: bold">
                {{ encryptionReceiverInfo.receiveTax || '--' }}
              </span>
              <span v-else style="font-weight: bold">{{ receiverInfo.receiveTax || '--' }}</span>
              <span
                v-if="receiverInfo.receiveTax"
                class="iconEye"
                @click="handlerhidden('receiveTax')"
              >
                <el-tooltip placement="top-start">
                  <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                  <svg-icon icon-class="iconEye" />
                </el-tooltip>
              </span>
            </div>
          </el-descriptions-item>
          <!--国家注册号-->
          <el-descriptions-item :label="$t('GlobalSetObj.NationalRegistrationNumber')">
            <div>{{ receiverInfo.receiveIeNumber || '--' }}</div>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :colon="false" :column="1" direction="vertical">
          <!--收件地址-->
          <el-descriptions-item :label="$t('newOrder.ReceivingAddress')">
            <div>{{ receiverInfo.receiveAddress || '--' }}</div>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :colon="false" :column="3" direction="vertical">
          <!--收件人电话-->
          <el-descriptions-item :label="$t('newOrder.Recipientphonenumber')">
            <div style="display: flex;">
              <span v-if="showDetail2" style="font-weight: bold">{{
                encryptionReceiverInfo.receivePhone || '--'
              }}</span>
              <span v-else style="font-weight: bold">{{ receiverInfo.receivePhone || '--' }}</span>
              <span
                v-if="receiverInfo.receivePhone"
                class="iconEye"
                @click="handlerhidden('receiveMobile')"
              >
                <el-tooltip placement="top-start">
                  <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                  <svg-icon icon-class="iconEye" />
                </el-tooltip>
              </span>
            </div>
          </el-descriptions-item>
          <!--收件人邮箱-->
          <el-descriptions-item :label="$t('newOrder.RecipientEmail')">
            <div style="display: flex;">
              <span v-if="showDetail3" style="font-weight: bold">{{ encryptionReceiverInfo.receiveMail || '--' }}</span>
              <span v-else style="font-weight: bold">{{ receiverInfo.receiveMail || '--' }}</span>
              <span
                v-if="receiverInfo.receiveMail"
                class="iconEye"
                @click="handlerhidden('receiveMail')"
              >
                <el-tooltip placement="top-start">
                  <div slot="content">{{ $t('GlobalSetObj.completionInformation') }}</div>
                  <svg-icon icon-class="iconEye" />
                </el-tooltip>
              </span>
            </div>
          </el-descriptions-item>
        </el-descriptions>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'RecipientsInfo',
  props: {
    receiverInfo: {
      type: Object,
      required: true,
      default: () => {
      }
    },
    encryptionReceiverInfo: {
      type: Object,
      required: true,
      default: () => {
      }
    }
  },
  data() {
    return {
      showDetail: true,
      showDetail1: true,
      showDetail2: true,
      showDetail3: true,
      showDetail4: true,
      showDetail5: true
    };
  },
  methods: {
    handlerhidden(item, value) {
      const buttonPermissions = this.$store.getters.button_permissions;
      const promise = 'field:customer-sensitive-info';
      const hasPromise = buttonPermissions.includes(promise);
      if (hasPromise) {
        if (item === 'name') {
          this.showDetail = !this.showDetail;
        }
        if (item === 'receivePhone') {
          this.showDetail1 = !this.showDetail1;
        }
        if (item === 'receiveMobile') {
          this.showDetail2 = !this.showDetail2;
        }
        if (item === 'receiveMail') {
          this.showDetail3 = !this.showDetail3;
        }
        if (item === 'receiveTax') {
          this.showDetail4 = !this.showDetail4;
        }
        if (item === 'receiveCompany') {
          this.showDetail5 = !this.showDetail5;
        }
      } else {
        this.$message.error(this.$t('GlobalSetObj.sensitiveInfoPermission'));
      }
    }
  }
};
</script>

<style scoped>
.warp /deep/ .el-col {
  height: 50px;
}

.iconEye {
  cursor: pointer;
  margin-left: 10px;
}

.warp /deep/ .el-descriptions-item__label {
  font-size: 13px;
  color: #909399;
}

.warp /deep/ .el-descriptions-item__cell {
  /*height: 35px !important;*/
  padding-bottom: 2px !important;
}

.el-descriptions-item__cell div {
  font-weight: bold;
  font-size: 15px;
}
</style>
