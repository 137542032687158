<template>
  <el-dialog
    :append-to-body="true"
    :title="$t('receivablePayable.tips')"
    :visible.sync="isBool"
    width="50%"
  >
    <div style="height: 40vh;overflow: hidden;">
      <div style="height: 100%;overflow: auto;">
        <!--对账批次生成成功！-->
        <div style="margin-bottom: 20px;">{{ $t('receivablePayable.ReconciliationBatchGenerationSuccessful') }}</div>
        <!--对账批次号：-->
        <div>{{ $t('receivablePayable.ReconciliationBatchNumber') }}
          <div
            v-for="(item, index) in batchNumber"
            :key="index"
            style="margin: 0 10px 5px 0;"
          >
            <span>{{ item.replace('~', '\n') }}</span>
          </div>
        </div>
      </div>
      <textarea id="message5" />
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <!--取 消-->
      <el-button size="small" @click="copyBatchNumber(1)">{{ $t('basicData.Cancel') }}</el-button>
      <!--复制批次号-->
      <el-button
        size="small"
        type="primary"
        @click="copyBatchNumber(2)"
      >{{
        $t('receivablePayable.CopyTheBatchNumber')
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: '',

  data() {
    return {
      isBool: false,
      batchNumber: []
    };
  },

  created() {

  },

  methods: {
    open(list) {
      this.batchNumber = list;
      this.isBool = true;
    },
    // 复制
    async copyBatchNumber(type) {
      if (type === 1) {
        this.isBool = false;
        return false;
      }
      const num = this.batchNumber.map(item => item.split('~')[0]);
      const str = num.join(',');
      try {
        await navigator.clipboard.writeText(str); // 修改文本框的内容
        this.$message.success(this.$i18n.t('orderCenterCont.ReplicationSucceeded')); // '复制成功'
        this.isBool = false;
      } catch (err) {
        if (!navigator.clipboard) {
          const textarea = document.createElement('textarea');
          textarea.style.position = 'fixed';
          textarea.style.left = '-9999px';
          textarea.value = str;
          try {
            document.body.appendChild(textarea);
            textarea.select(); // 选择对象
            document.execCommand('copy'); // 执行浏览器复制命令
            this.$message.success(this.$i18n.t('orderCenterCont.ReplicationSucceeded')); // '复制成功'
            this.isBool = false;
          } catch (error) {
            console.log('浏览器不支持复制', error);
            this.$message.success(this.$i18n.t('GlobalSetObj.Lose')); // '失败'
          } finally {
            document.body.removeChild(textarea);
          }
        } else {
          console.log('浏览器不支持复制');
          this.$message.error(this.$i18n.t('GlobalSetObj.Lose'));
        }
      } 
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
