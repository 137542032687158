<template>
  <!--  表格数据  -->
  <AnjunTableList
    :header-list="tableHeaderList"
    :is-index="true"
    :is-tip="true"
    :table-data="goodsTableData"
  />
</template>
<script>
import AnjunTableList from '@/components/anjun-tableList';

export default {
  name: 'GoodsInfo',
  components: {
    AnjunTableList
  },
  props: {
    goodsTableData: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      tableHeaderList: [
        { id: 1, label: this.$t('newOrder.designation'), prop: 'goodName', cloWidth: '220' },
        { id: 2, label: this.$t('newOrder.Quantity'), prop: 'quantity', cloWidth: '100' },
        { id: 3, label: this.$t('newOrder.Unit'), prop: 'company', cloWidth: '100' },
        { id: 4, label: this.$t('newOrder.Declaration'), prop: 'declared', cloWidth: '220' },
        { id: 5, label: this.$t('newOrder.Brand'), prop: 'brand', cloWidth: '100' },
        { id: 6, label: this.$t('newOrder.Size'), prop: 'objSize', cloWidth: '150' },
        { id: 7, label: this.$t('newOrder.Parameters'), prop: 'parameter', cloWidth: '160' },
        { id: 8, label: this.$t('GlobalSetObj.RegistrationNumber'), prop: 'cadastralNumber', cloWidth: '100' },
        { id: 9, label: 'HSCODE', prop: 'hscode', cloWidth: '100' },
        { id: 10, label: this.$t('orderCenterCont.Material'), prop: 'texture', cloWidth: '100' },
        { id: 11, label: this.$t('orderCenterCont.use'), prop: 'purpose', cloWidth: '150' },
        { id: 12, label: this.$t('GlobalSetObj.Link'), prop: 'interlinkage', cloWidth: '150' },
        { id: 13, label: this.$t('orderCenterCont.Origin'), prop: 'placeOfProduction', cloWidth: '150' },
        { id: 14, label: this.$t('orderCenterCont.specification'), prop: 'specification', cloWidth: '120' },
        { id: 15, label: 'SKU', prop: 'sku', cloWidth: '120' },
        { id: 16, label: this.$t('orderCenterCont.insurance'), prop: 'insurance', cloWidth: '120' },
        { id: 17, label: this.$t('orderCenterCont.BatteryType'), prop: 'typeOfBattery', cloWidth: '220' }
      ],
      tableData: []
    };
  },
  created() {

  }
};
</script>

<style scoped>

</style>
