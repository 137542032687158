import { ref, computed } from '@vue/composition-api';

export function useWizardForm(formData, handleSubmit, legthStep) {
  const step = ref(0);

  async function handleNextStep(data) {
    const canGoNext = step.value < (legthStep - 1);
    if (canGoNext) {
      step.value++;
      return;
    }
    await handleSubmit(formData);
  }

  function handlePreviousStep() {
    step.value--;
  }

  const isLastStep = computed(() => {
    return step.value >= (legthStep - 1);
  });

  const isFirstStep = computed(() => {
    return step.value === 0;
  });

  return {
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  };
}
