<template>
  <el-form
    ref="ruleForm" :model="ruleForm" label-width="100px"
    class="demo-ruleForm"
  >
    <el-form-item :label="$t('GlobalSetObj.remark')" prop="mark">
      <el-input
        v-model="ruleForm.mark"
        maxlength="200"
        type="textarea"
        show-word-limit
        :placeholder="$t('GlobalSetObj.pleaseInputContent')"
      />
    </el-form-item>
    <el-form-item>
      <!-- 提交付款 -->
      <el-button type="primary" @click="onSubmit">{{ $t('ClaimsManagement.SubmitPayment') }}</el-button>
      <el-button @click="() => onClose(false)">{{ $t('GlobalSetObj.cancel') }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { apiClaimformCommitPay } from '@/api/claimsManagement/list';

export default {
  name: '',

  props: {
    ids: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      ruleForm: {
        mark: ''
      }
      // rules: {}
    };
  },

  created() {

  },

  methods: {
    onClose(isBool) {
      this.$emit('close', isBool);
    },

    // 提交
    onSubmit() {
      const { mark } = this.ruleForm;
      const obj = {
        id: this.ids,
        mark
      };
      apiClaimformCommitPay(obj).then(res => {
        // 操作成功
        this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
        setTimeout(() => {
          this.onClose(true);
        }, 500);
      }).catch(err => {
        console.error(err);
      });
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
