<template>
  <!-- 班次选择 下拉列表 -->
  <el-select
    v-model="value" filterable multiple
    clearable
    style="width: 100%;"
    class="select-ban-ci"
    :multiple-limit="multipleLimit" :placeholder="$t('GlobalSetObj.pleaseChoose')"
  >
    <!-- <el-option
      v-if="isAll"
      :label="$t('GlobalSetObj.all')"
      value=""
    /> -->

    <el-option
      v-for="item in list"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
export default {
  name: '',

  model: {
    prop: 'val',
    event: 'change'
  },

  props: {
    val: {
      type: Array,
      required: true
    },

    list: {
      type: Array,
      required: true
    },

    // 是否显示全部下拉菜单
    isAll: {
      type: Boolean,
      default: false
    },

    // 最多可以选择的项目数，为 0 则不限制
    multipleLimit: {
      type: Number,
      default: 0
    }

  },

  data() {
    return {

    };
  },

  computed: {
    value: {
      get() {
        return this.val;
      },
      set(val) {
        this.$emit('change', val);
      }
    }
  },

  created() {

  },

  methods: {

  }

};
</script>

<style lang="scss">
.select-ban-ci .el-select__tags {
  max-height: 120px;
  overflow-y: auto;
  padding: 10px 40px 10px 5px;
  width: 100%;
  max-width: 100% !important;
}
.select-ban-ci .el-input__suffix {
  right: 15px;
  z-index: 2;
}
</style>
