<template>
  <main>

    <div v-if="isSubmit" class="tips">
      <ul>
        <li>
          <p>Aviso: </p>
          <div>
            Para garantir a segurança de seus dados, esse link permite apenas um único upload de uma NOTA FISCAL. Portanto, preencha corretamente o conteúdo da NOTA FISCAL ao emiti-la para garantir que a fatura carregada esteja correta. Muito obrigado!</div>
        </li>
        <li>
          <p>注意：</p>
          <div>
            为了您的数据安全此链接只允许单次上传发票。因此，请在开具发票时正确填写发票内容，以确保上传的发票正确无误。非常感谢！
          </div>
        </li>
      </ul>

      <el-form
        ref="ruleForm" v-loading="loading" :model="ruleForm"
        :rules="rules" label-width="350px" class="demo-ruleForm"
        size="medium"
      >
        <el-form-item label="上传发票/fazer upload da nota fiscal" prop="attachmentUrl">
          <UploadCmp
            :drag="true"
            :multiple="true"
            :auto-upload="true"
            :limit="5"
            :upload-text="uploadText"
            @change="onFileChange"
          />
        </el-form-item>
        <el-form-item>
          <el-button :disabled="!isSubmitBut" type="primary" @click="submitForm('ruleForm')">确定/Determinar</el-button>

        </el-form-item>
      </el-form>
    </div>

    <el-result
      v-else icon="success" title="成功/success"
      sub-title=""
    >
      <template slot="extra">
        <el-button type="primary" size="medium" @click="closePage">关闭/fechar</el-button>
      </template>
    </el-result>
  </main>
</template>

<script>
import UploadCmp from '@/views/claimsManagement/claimStatement/cmp/UploadCmp.vue';
import { apiSitePayableUpdateSitePayFile } from '@/api/finance/uploadContas';

export default {
  name: '',

  components: {
    UploadCmp
  },

  data() {
    return {
      ruleForm: {
        attachmentUrl: ''
      },
      rules: {
        attachmentUrl: [
          { required: true, message: '请上传发票/Please upload the invoice', trigger: 'blur' }
        ]
      },

      isSubmit: true,

      // 此参数都为链接带过来的
      pathObj: {
        payableNumber: '', // 批次号
        timeStamp: '' // 时间戳
      },

      loading: false
    };
  },

  computed: {
    isSubmitBut() {
      const { attachmentUrl } = this.ruleForm;
      if (attachmentUrl.length > 0) {
        return true;
      }
      return false;
    }
  },

  created() {
    this.uploadText = '将文件拖到此处，或点击上传/Arraste os arquivos para cá ou clique em upload';

    const { timeStamp, payableNumber } = this.$route.query;
    this.pathObj.payableNumber = payableNumber;
    this.pathObj.timeStamp = timeStamp;

    // this.getCurrentTableData();
  },

  methods: {

    onFileChange(val) {
      this.ruleForm.attachmentUrl = val;
    },

    // 查看当前链接是否失效
    getCurrentTableData() {
      this.loading = true;
      const { payableNumber, timeStamp } = this.pathObj;
      const data = {
        payFile: '',
        payableNumber,
        timeStamp

      };

      apiSitePayableUpdateSitePayFile(data, false).then((res) => {
        console.error(res);
        const { code, data } = res.data;
        if (code === 200 && data === false) {
          // 当前链接失效
          this.$router.replace('/linkFailure');

          return;
        }
      }).catch((err) => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },

    submitForm(formName) {
      const { payableNumber, timeStamp } = this.pathObj;

      // 不存在参数
      if (!payableNumber || !timeStamp) {
        this.$message({
          message: '参数丢失/Parameter loss',
          type: 'warning'
        });
        return false;
      }

      this.$refs[formName].validate((valid) => {
        if (!valid) {
          // alert('submit!');
          return false;
        }

        const { attachmentUrl } = this.ruleForm;
        const obj = {
          payFile: attachmentUrl.map(item => item.url).join(','),
          payableNumber,
          timeStamp
        };

        this.asyncApiSitePayableUpdateSitePayFile(obj);
      });
    },

    asyncApiSitePayableUpdateSitePayFile(data) {
      this.loading = true;
      apiSitePayableUpdateSitePayFile(data, false).then((res) => {
        console.error(res);
        const { code, data } = res.data;
        if (code === 200) {
          if (data === false) {
            // 当前链接失效
            this.$message.error('当前链接失效/current link is invalid');
            return;
          }

          this.isSubmit = false;
          this.$message.success('操作成功/operation successful');
        }
      }).catch((err) => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },

    // 关闭当前窗口
    closePage() {
      this.$router.go(-1);
    }
  }

};
</script>

<style lang="scss" scoped>
main {
  width: 100%;
  height: 100%;
  // border: 1px solid red;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
}

.demo-ruleForm {
  // border: 1px solid #000;
  display: flex;
  flex-direction: column;
  align-items: center;

  ::v-deep {
  .el-form-item__label {
    font-size: 18px;
    font-weight: bold;
  }
  .el-upload-dragger {
    width: 550px;
    height: 240px;
  }

  .el-upload__text {
    font-size: 16px;
  }
  .el-button {
    font-size: 30px;
  }
}
}

ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  // border: 1px solid #000;
  font-size: 16px;
  color: red;
  margin: 0;
  padding: 60px 0;

  li {
    width: 55%;
    display: flex;
    margin-bottom: 10px;
    // border: 1px solid red;

    p {
      margin: 0;
      width: 60px;
    }
    div {
      flex: 1;
    }
  }
}

</style>
