<template>
  <div class="app-container">
    <div>
      <el-form
        ref="queryForm"
        class="queryFormClass"
        :model="queryForm"
        :label-width="$i18n.locale=='zh'?'120px':''"
        :label-position="$i18n.locale!='zh'?'top':'right'"
        size="mini"
      >
        <el-row :gutter="10">
          <el-col :span="7">
            <!--账户名称-->
            <el-form-item :label="$t('basicData.AccountName')" prop="accountAlias">
              <el-input v-model="queryForm.params.accountAlias" :placeholder="$t('orderDetails.pleaseInput')" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex" justify="start">
        <el-col :span="8">
          <div>
            <!--查询-->
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="getDataSearch"
            >{{ $t('basicData.Search') }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh"
              size="mini"
              @click="queryFormReset"
            >{{ $t('basicData.reset') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
      <el-divider />
    </div>

    <div style="margin-bottom: 10px;">
      <!--添加-->
      <el-button
        v-permit:remove="'but:finance:basicData:accountManagement:add'"
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="handlerOperation(1)"
      >{{ $t('basicData.Add') }}
      </el-button>
    </div>

    <el-card>
      <el-table
        ref="elTable"
        :data="tableData"
        border
        class="table-height"
        :height="tableHeightRow.height"
      >
        <!--序号-->
        <el-table-column :label="$t('basicData.serialNumber')" width="60px" align="center">
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!--账户名称-->
        <el-table-column
          prop="accountAlias"
          :label="$t('basicData.AccountName')"
          align="left"
          width="160"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div style="color: #409eff;cursor: pointer;" @click="handlerOperation(2, scope.row)">{{ scope.row.accountAlias }}</div>
          </template>
        </el-table-column>
        <!--账户号码-->
        <el-table-column
          prop="accountNumber"
          :label="$t('basicData.AccountNumber')"
          align="left"
          width="160"
          :show-overflow-tooltip="true"
        />
        <!--户名-->
        <el-table-column
          prop="accountName"
          :label="$t('basicData.AccountOpeningName')"
          align="center"
          width="140"
          :show-overflow-tooltip="true"
        />
        <!--开户行-->
        <el-table-column
          prop="bank"
          :label="$t('basicData.TheBankWithWhichTheAccountOpened')"
          align="center"
          width="140"
          :show-overflow-tooltip="true"
        />
        <!--状态-->
        <el-table-column
          prop="status"
          :label="$t('basicData.status')"
          align="center"
          width="140"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <!--启用-->
            <el-tag v-if="scope.row.status === 1">{{ $t('basicData.enable') }}</el-tag>
            <!--禁用-->
            <el-tag v-else-if="scope.row.status === 0" type="danger">{{ $t('basicData.disable') }}</el-tag>
            <!--未定义-->
            <el-tag v-else type="info">{{ $t('basicData.Undefined') }}</el-tag>
          </template>
        </el-table-column>
        <!--创建人-->
        <el-table-column
          prop="createUserStr"
          :label="$t('basicData.CreatePeople')"
          align="center"
          width="120"
          :show-overflow-tooltip="true"
        />
        <!--创建时间-->
        <el-table-column
          prop="createTime"
          :label="$t('basicData.CreationTime')"
          align="center"
          width="160"
          :show-overflow-tooltip="true"
        />
        <!--最后修改人-->
        <el-table-column
          prop="updateUserStr"
          :label="$t('basicData.LastModifier')"
          align="center"
          width="120"
          :show-overflow-tooltip="true"
        />
        <!--最后修改时间-->
        <el-table-column
          prop="updateTime"
          :label="$t('basicData.LastModified')"
          align="center"
          width="160"
          :show-overflow-tooltip="true"
        />
        <!--备注-->
        <el-table-column
          prop="remark"
          :label="$t('basicData.remark')"
          align="left"
          :show-overflow-tooltip="true"
        />
        <el-table-column :label="$t('basicData.operate')" fixed="right" width="200">
          <template slot-scope="scope">
            <div>
              <!--确定禁用吗？-->
              <el-popconfirm
                v-if="scope.row.status === 1"
                v-permit="'but:finance:basicData:accountManagement:edit'"
                :title="$t('basicData.AreYouSureDisabled')"
                @confirm="asyncPartitionEdit(scope.row, 0)"
              >
                <!--禁用-->
                <el-button
                  slot="reference" class="ml" type="danger"
                  size="mini"
                >{{ $t('basicData.disable') }}</el-button>
              </el-popconfirm>
              <!--确定启用吗？-->
              <el-popconfirm
                v-if="scope.row.status === 0"
                v-permit="'but:finance:basicData:accountManagement:edit'"
                :title="$t('basicData.AreYouSureEnabled')"
                @confirm="asyncPartitionEdit(scope.row, 1)"
              >
                <!--启用-->
                <el-button
                  slot="reference" class="ml" type="success"
                  size="mini"
                >{{ $t('basicData.enable') }}</el-button>
              </el-popconfirm>
              <!--删除-->
              <el-popconfirm
                v-permit="'but:finance:basicData:accountManagement:delete'"
                :title="$t('basicData.AreYouSureYouWantToDelete')"
                @confirm="deleteAccount(scope.row)"
              >
                <!--启用-->
                <el-button
                  slot="reference" class="ml" type="danger"
                  size="mini"
                >{{ $t('basicData.Delete') }}</el-button>
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getData"
          @size-change="getData"
        />
      </div>
    </el-card>

    <!-- 账户详情 -->
    <el-drawer
      :visible.sync="drawerObj.visible"
      :title="drawerObj.typeIndex === 1 ? $t('basicData.AddAnAccount') : $t('basicData.AccountDetails')"
      direction="ltr"
      size="60%"
    >
      <check-detail
        :key="editKey"
        :detail-data="drawerObj.editData"
        :operation-type="drawerObj.typeIndex"
        @saveChange="updatePage"
      />
    </el-drawer>

  </div>
</template>

<script>
import { getUserName } from '@/utils/asyncTools';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { changeState, accountPage, accountDelete } from '@/api/finance/partition';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';

export default {
  name: 'AccountManagement',
  components: {
    'check-detail': () => import('./cmp/checkDetail')
  },
  mixins: [estimateTableHeight],

  data() {
    return {
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          accountAlias: ''
        }
      },
      tableData: [],
      total: 0,
      drawerObj: {
        visible: false,
        editData: {},
        typeIndex: 1
      },
      editKey: new Date().getTime(),
      userIdAndNameObj: null
    };
  },
  created() {
    this.userIdAndNameObj = getUserIdAndNameObj();
    this.getData();
  },
  methods: {
    // 获取列表数据
    getData() {
      accountPage(this.queryForm).then(res => {
        if (res.status === 'OK') {
          this.tableData = res.data.records.map(item => {
            const updateUserStr = getUserName(item.updateUser, this.userIdAndNameObj);
            const createUserStr = getUserName(item.createUser, this.userIdAndNameObj);
            return {
              ...item,
              updateUserStr,
              createUserStr
            };
          });
          this.total = res.data.total;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 搜索
    getDataSearch() {
      this.queryForm.pageNumber = 1;
      this.getData();
    },
    // 重置
    queryFormReset() {
      this.queryForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          accountAlias: ''
        }
      };
    },
    // 添加、详情
    handlerOperation(type, row) {
      this.drawerObj.typeIndex = type;
      if (row) {
        const {
          accountAlias,
          accountName,
          accountNumber,
          bank,
          id,
          status,
          remark
        } = row;
        this.drawerObj.editData = {
          accountAlias,
          accountName,
          accountNumber,
          bank,
          id,
          status,
          remark
        };
      } else {
        this.drawerObj.editData = {
          accountAlias: '',
          accountName: '',
          accountNumber: '',
          bank: '',
          status: 1,
          remark: ''
        };
      }
      this.drawerObj.visible = true;
      this.editKey = new Date().getTime();
    },
    // 启用、禁用
    asyncPartitionEdit(row, type) {
      const param = {
        ids: row.id,
        status: type
      };
      changeState(param).then(res => {
        if (res.status === 'OK') {
          this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
          this.getData();
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 删除
    deleteAccount(row) {
      accountDelete({ id: row.id }).then(res => {
        if (res.status === 'OK') {
          this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
          this.getData();
        }
      });
    },
    // 数据更新
    updatePage(type) {
      this.drawerObj.visible = false;
      if (type === 1) {
        this.getData();
      }
    }
  }
};
</script>

<style scoped>
.ml {
  margin-left: 10px;
}
</style>
