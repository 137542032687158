var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        { attrs: { type: "border-card" } },
        [
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("organizationManage.OrganizationManagement"),
                lazy: "",
              },
            },
            [_c("organization-manage"), _c("organization-operation")],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("organizationManage.OrganizationChart"),
                lazy: "",
              },
            },
            [_c("organization-chart")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }