<template>
  <div class="counter-item">
    <div class="label">{{ label }}</div>
    <div class="value">
      {{ value|formatNumber }}
    </div>
    <span :class="{'down': change < 0}" class="reduce">
      {{ change|formatNumber(true) }}
      <span>{{$t('newOrder.ComparedToYesterday')}}</span>
    </span>
    <img v-if="change!=0" src="@/assets/images/kanban/customerOrderReduction.png" :class="['image',change<0?'':'rise']">
    <img v-else src="@/assets/images/kanban/customerOrderReduction0.png" class="image">
  </div>
</template>

<script>
export default {
  filters: {
    // 数字格式化函数
    formatNumber(num, showPositiveSign = false) {
      // 如果不是数字，直接返回原值
      if (isNaN(num) || num === null) return num;

      // 将数字转换为字符串
      let numStr = Number(num).toString();

      // 处理负数
      const isNegative = numStr.startsWith('-');
      if (isNegative) {
        numStr = numStr.slice(1);
      }

      // 分离整数部分和小数部分
      const parts = numStr.split('.');
      let integerPart = parts[0];
      const decimalPart = parts[1];

      // 添加千分位逗号
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      // 重组数字
      let result = integerPart;
      if (decimalPart) {
        result += '.' + decimalPart;
      }

      // 添加正负号
      if (isNegative) {
        result = '-' + result;
      } else if (showPositiveSign && num > 0) {
        result = '+' + result;
      }

      return result;
    }
  },
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    change: {
      type: Number,
      required: true
    }
  }
};
</script>

<style lang="scss">
.counter-item {
  padding: 0px 5px;
  border: 1px solid #eaeaea;
  display: flex;
  align-items: center;

  .label {
    font-family: PingFang SC;
    font-weight: bold;
    font-size: 13px;
    color: #303133;
    line-height: 17px;
    margin: 0px 2px;
  }
  .reduce{
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 13px;
    color: #767676;
    line-height: 18px;
    margin: 0px 3px;
  }
  .value {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin: 0px 5px;
    span {
      font-size: 12px;
      color: #999;
      margin-left: 3px;

      &.down {
        color: #d32f2f; // Red color for negative change
      }

      .arrow-down {
        display: inline-block;
        margin-left: 2px;
        border: solid #999;
        border-width: 0 2px 2px 0;
        padding: 2px;
        transform: rotate(45deg);
      }
    }
  }
  .image{
    width: 14px;
    height: 13px;
  }
  .rise{
    // 图片旋转180度
    transform: rotate(180deg);
  }
}
</style>
