var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container box" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("customerManagements.basicInformation"),
                name: "customerTab",
              },
            },
            [
              _c("customer-cmp", {
                attrs: { "customer-type": _vm.customerType },
                on: {
                  updataAuthorizationCode: _vm.updataAuthorizationCode,
                  updataData: _vm.updataData,
                },
              }),
            ],
            1
          ),
          _vm.customerType.type !== "add"
            ? [
                _c(
                  "el-tab-pane",
                  {
                    attrs: {
                      label: _vm.$t("customerManagements.authorizationCode"),
                      name: "code",
                    },
                  },
                  [
                    _c("authorization-code-cmp", {
                      attrs: {
                        code: _vm.authorizationCode,
                        "customer-id": _vm.customerType.id,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  {
                    attrs: {
                      label: _vm.$t("newOrder.SendAddress"),
                      name: "senderAddress",
                    },
                  },
                  [
                    _c("senderAddress", {
                      attrs: { "customer-id": _vm.customerType.id },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  {
                    attrs: {
                      label: _vm.$t("orderCenterCont.TheDeliveryAddress"),
                      name: "recevieAddress",
                    },
                  },
                  [
                    _c("receviceAddress", {
                      attrs: { "customer-id": _vm.customerType.id },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  {
                    attrs: {
                      label: _vm.$t("customerManagements.FTPInformation"),
                      name: "FTPInformation",
                    },
                  },
                  [
                    _c("FTPInformation", {
                      key: _vm.FTPKey,
                      attrs: {
                        customer: _vm.customerType,
                        "detail-data": _vm.detailData,
                      },
                      on: { updataData: _vm.updataData },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  {
                    attrs: {
                      label: _vm.$t("customerManagements.otherInformation"),
                      name: "otherInformation",
                    },
                  },
                  [
                    _c("OtherInformation", {
                      key: _vm.otherKey,
                      attrs: {
                        customer: _vm.customerType,
                        "detail-data": _vm.detailData,
                      },
                      on: { updataData: _vm.updataData },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }