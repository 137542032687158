var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DropdownTable",
        [
          _c(
            "o-dropdown-item",
            {
              attrs: { "aria-role": "listitem" },
              on: { click: _vm.handleDownload },
            },
            [_vm._v(" Download ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }