<template>
  <div style="width: 100%">
    <div style="display: flex;justify-content: space-between;margin-bottom: 20px">
      <div class="clearfix">
        {{$t('newOrder.CustomerOrders')}}
      </div>
      <el-button v-if="!clickDropdown" size="mini" @click="clickDropdown=true">{{$t('newOrder.ConfiguringCustomers')}}</el-button>
      <div v-else>
        <slot />
      </div>
    </div>
    <div class="counter-container" style="display: flex;justify-content: center;margin-top: 37px;margin-bottom: 50px;flex-wrap: wrap;">
      <CounterItem
        v-for="(item,index) in totalList" :key="index" :label="item.name"
        :value="item.count" :change="item.difference"
      />
    </div>
  </div>
</template>
<script>

export default {
  name: 'CustomerOrderStatus',
  components: {
    CounterItem: () => import('./CounterItem.vue')
  },
  props: {
    totalList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      clickDropdown: false, // 点击下拉
    };
  }
};
</script>
<style scoped>
.clearfix{
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #1C1C1C;
  line-height: 22px;
  display: flex;
  justify-content:space-between;
  width: 100%;
}
</style>
