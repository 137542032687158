<template>
  <div style="height: calc(100vh - 130px);overflow: auto;padding: 15px;">
    <div>
      <el-form
        ref="queryForm"
        class="queryFormClass"
        label-width="auto"
        label-position="top"
        size="mini"
        :model="queryForm"
      >
        <el-row :gutter="10">
          <el-col :span="7">
            <!--州名称-->
            <el-form-item :label="$t('GlobalSetObj.state')">
              <el-input v-model="queryForm.params.stateName" :placeholder="$t('orderDetails.pleaseInput')" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!--城市名称-->
            <el-form-item :label="$t('orderCenterCont.city')">
              <el-input v-model="queryForm.params.marketName" :placeholder="$t('orderDetails.pleaseInput')" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex" justify="start">
        <el-col :span="8">
          <div>
            <!--查询-->
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="getDataSearch"
            >{{ $t('basicData.Search') }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh"
              size="mini"
              @click="queryFormReset"
            >{{ $t('basicData.reset') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
      <el-divider />
    </div>

    <div style="margin-bottom: 10px;">
      <!--添加-->
      <el-button
        v-permit:remove="'btn:finance:basicData:districtManagement:add'"
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="handlerOperation(1)"
      >{{ $t('basicData.Add') }}
      </el-button>
      <!--导入-->
      <el-button
        v-permit:remove="'btn:finance:basicData:districtManagement:import'"
        size="mini"
        type="primary"
        icon="el-icon-download"
        @click="handlerOperation(3)"
      >{{ $t('CenterForSorting.Import') }}
      </el-button>
      <!--导出-->
      <el-button
        v-permit:remove="'btn:finance:basicData:districtManagement:export'"
        size="mini"
        type="primary"
        icon="el-icon-upload2"
        @click="handlerOperation(4)"
      >{{ $t('CenterForSorting.export') }}
      </el-button>
    </div>

    <div>
      <el-table
        ref="elTable"
        :data="tableData"
        border
        class="table-height"
        :height="tableHeightRow.height"
      >
        <el-table-column :label="$t('operationCenter.ind')" width="80px" align="center">
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!--州-->
        <el-table-column
          prop="name"
          :label="$t('GlobalSetObj.state')"
          align="center"
          min-width="160"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.level === 1">
              <div style="color: #409eff;cursor: pointer;" @click="handlerOperation(2, scope.row)">{{ scope.row.name }}</div>
            </div>
            <div v-else>
              <div style="color: #409eff;cursor: pointer;" @click="handlerOperation(2, scope.row.parentNode)">
                {{ scope.row.parentNode.name }}
              </div>
            </div>
          </template>
        </el-table-column>
        <!--州二字码-->
        <el-table-column
          prop="twoCode"
          :label="$t('CenterForSorting.StateTwoCode')"
          align="center"
          min-width="160"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.level === 1">
              {{ scope.row.twoCode }}
            </div>
            <div v-else>{{ scope.row.parentNode.twoCode }}</div>
          </template>
        </el-table-column>
        <!--城市-->
        <el-table-column
          prop="name"
          :label="$t('orderCenterCont.city')"
          align="center"
          min-width="160"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.level === 2" style="color: #409eff;cursor: pointer;" @click="handlerOperation(2, scope.row)">
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <!--邮编-->
        <el-table-column
          prop="zipCode"
          :label="$t('GlobalSetObj.zipCode')"
          align="center"
          min-width="160"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.zipCode }}</div>
          </template>
        </el-table-column>
        <!--ISS税率（%）-->
        <el-table-column
          prop="issTaxation"
          :label="'ISS' + $t('GlobalSetObj.rate') + '(%)'"
          align="center"
          min-width="160"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.issTaxation }}</div>
          </template>
        </el-table-column>
        <!--创建人-->
        <el-table-column
          prop="createUserStr"
          :label="$t('basicData.CreatePeople')"
          align="center"
          min-width="160"
          :show-overflow-tooltip="true"
        />
        <!--创建时间-->
        <el-table-column
          prop="createTime"
          :label="$t('basicData.CreationTime')"
          align="center"
          min-width="160"
          :show-overflow-tooltip="true"
        />
        <!--最后修改人-->
        <el-table-column
          prop="updateUserStr"
          :label="$t('basicData.LastModifier')"
          align="center"
          min-width="160"
          :show-overflow-tooltip="true"
        />
        <!--最后修改时间-->
        <el-table-column
          prop="updateTime"
          :label="$t('basicData.LastModified')"
          align="center"
          min-width="160"
          :show-overflow-tooltip="true"
        />
      </el-table>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getCurrencyTableData"
          @size-change="getSizeTableData"
        />
      </div>
    </div>
    <el-drawer
      :title="$t('basicData.Add')"
      :append-to-body="true"
      :visible.sync="showDetail"
      direction="ltr"
      size="600px"
    >
      <div style="padding: 0 25px;">
        <el-form
          ref="formParamData"
          :key="showDetail"
          class="queryFormClass"
          label-width="auto"
          label-position="top"
          size="small"
          :model="formParam"
        >
          <!--父级-->
          <el-form-item :label="$t('basicData.Parent')">
            <el-select
              v-model="formParam.parentId"
              :placeholder="$t('orderDetails.pleaseChoose')"
              :disabled="operationType === 2"
            >
              <el-option
                v-for="(item, ind) in parentList"
                :key="ind"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <!--名称-->
          <el-form-item
            :label="$t('GlobalSetObj.Name')"
            prop="name"
            :rules="[
              { required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
            ]"
          >
            <el-input v-model="formParam.name" :placeholder="$t('orderDetails.pleaseInput')" clearable />
          </el-form-item>
          <!--二字码-->
          <el-form-item
            v-if="!formParam.parentId"
            :label="$t('basicData.twoCode')"
            prop="twoCode"
            :rules="[
              { required: true, validator: englishLetter1, length: 2, trigger: ['blur', 'change'] }
            ]"
          >
            <el-input v-model="formParam.twoCode" :placeholder="$t('orderDetails.pleaseInput')" clearable />
          </el-form-item>
          <!--邮编-->
          <el-form-item
            v-if="formParam.parentId"
            :label="$t('basicData.zipCode')"
            prop="zipCode"
            :rules="[
              { required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
            ]"
          >
            <el-input
              v-model="formParam.zipCode"
              type="textarea"
              :rows="6"
              :placeholder="$t('basicData.ZipCodeExample')"
              @change="zipcodeCollation(formParam.zipCode)"
            />
          </el-form-item>
          <!--ISS税率（%）-->
          <el-form-item
            v-if="formParam.parentId"
            :label="'ISS' + $t('GlobalSetObj.rate') + '(%)'"
            prop="issTaxation"
            :rules="[
              { required: true, suffix: 2, negative: '', validator: priceType, trigger: ['blur', 'change'] }
            ]"
          >
            <el-input
              v-model="formParam.issTaxation"
              :placeholder="$t('orderDetails.pleaseInput')"
            />
          </el-form-item>
          <el-form-item>
            <div v-if="operationType === 1" v-permit:remove="'btn:finance:basicData:districtManagement:add'">
              <el-button type="primary" @click="formSave()">{{ $t('basicData.Save') }}</el-button>
              <el-button @click="showDetail = false">{{ $t('basicData.Cancel') }}</el-button>
              <el-button type="danger" @click="deleteData()">{{ $t('basicData.Delete') }}</el-button>
            </div>
            <div v-if="operationType === 2" v-permit:remove="'btn:finance:basicData:districtManagement:edit'">
              <el-button type="primary" @click="formSave()">{{ $t('basicData.Save') }}</el-button>
              <el-button @click="showDetail = false">{{ $t('basicData.Cancel') }}</el-button>
              <el-button type="danger" @click="deleteData()">{{ $t('basicData.Delete') }}</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
    <!--导入新增地区-->
    <el-dialog
      :title="$t('basicData.ImportAddRegion')"
      :visible.sync="showImportAddRegion"
      width="500px"
      :append-to-body="true"
    >
      <importArea @importChange="importChange" />
    </el-dialog>
  </div>
</template>

<script>
import { getUserName } from '@/utils/asyncTools';
import { apiAreaAdd, apiAreaExport, apiAreaPage, apiAreaEdit, apiAreaDelete } from '@/api/finance/basicData';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { compressList } from '@/utils/zipcode';
import importArea from './importArea';
import { cloneDeep } from 'lodash-es';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import { goExportList } from '@/utils/goExportList.js';

export default {
  name: 'Region',
  components: {
    importArea
  },
  mixins: [estimateTableHeight],
  data() {
    const englishLetter1 = (rule, value, callback) => {
      const { required } = rule;
      if (!required) {
        // 非必填
        if (value) {
          if (rule.length && value.length !== rule.length) {
            return callback(new Error(this.$t('basicData.TheCharacterLength') + rule.length));
          } else {
            return callback();
          }
        } else {
          return callback();
        }
      } else {
        if (value) {
          if (rule.length && value.length !== rule.length) {
            return callback(new Error(this.$t('basicData.TheCharacterLength') + rule.length));
          } else {
            return callback();
          }
        } else {
          return callback(new Error(this.$t('basicData.CannotBeEmpty')));
        }
      }
    };
    // 正负保留小数点
    const priceType = (rule, value, callback) => {
      const reg = new RegExp('(^' + rule.negative + '[1-9]\\d*(\\.\\d{1,' + rule.suffix + '})?$)|(^' + rule.negative + '0(\\.\\d{1,' + rule.suffix + '})?$)');
      const { required } = rule;
      if (!required) {
        // 非必填
        if (value !== null && value !== '' && value !== undefined) {
          if (!reg.test(value)) {
            return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // '格式不正确'
          } else {
            if (rule.package !== null && rule.package !== '' && rule.package !== undefined) {
              if (rule.negative1 === 2) {
                if (parseFloat(value) <= parseFloat(rule.package)) {
                  return callback(new Error(this.$i18n.t('basicData.MustGreaterThanStartingValue'))); // 必须大于起始值
                } else if (parseFloat(value) <= 0) {
                  return callback(new Error(this.$i18n.t('basicData.MustBeGreaterThan0'))); // 必须大于0
                }
              } else {
                if (parseFloat(value) >= parseFloat(rule.package)) {
                  return callback(new Error(this.$i18n.t('basicData.MustLessThanEndValue'))); // 必须小于结束值
                } else {
                  return callback();
                }
              }
            }
            return callback();
          }
        } else {
          return callback();
        }
      } else {
        if (value !== null && value !== '' && value !== undefined) {
          if (!reg.test(value)) {
            return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // '格式不正确'
          } else {
            if (rule.negative1 === 2) {
              if (parseFloat(value) <= parseFloat(rule.package)) {
                return callback(new Error(this.$i18n.t('basicData.MustGreaterThanStartingValue'))); // 必须大于起始值
              } else if (parseFloat(value) <= 0) {
                return callback(new Error(this.$i18n.t('basicData.MustBeGreaterThan0'))); // 必须大于0
              }
            } else {
              if (parseFloat(value) >= parseFloat(rule.package)) {
                return callback(new Error(this.$i18n.t('basicData.MustLessThanEndValue'))); // 必须小于结束值
              } else {
                return callback();
              }
            }
            return callback();
          }
        } else {
          return callback(this.$i18n.t('basicData.CannotBeEmpty')); // '不能为空'
        }
      }
    };
    return {
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          stateName: '',
          marketName: ''
        }
      },
      tableData: [],
      total: 0,
      userIdAndNameObj: null,
      showDetail: false,
      formParam: {
        id: '',
        parentId: null,
        level: null,
        name: '',
        zipCode: '',
        twoCode: '',
        issTaxation: ''
      },
      parentList: [],
      englishLetter1: englishLetter1,
      priceType: priceType,
      operationType: 1,
      showImportAddRegion: false
    };
  },

  watch: {
    'formParam.twoCode'() {
      if (this.formParam.twoCode) {
        this.formParam.twoCode = this.formParam.twoCode.toUpperCase();
      }
    }
  },
  created() {
    this.userIdAndNameObj = getUserIdAndNameObj();
    this.getTableDate();
  },
  methods: {
    // 删除
    deleteData() {
      this.$confirm(this.$t('basicData.AreYouSureDelete'), this.$t('basicData.tips'), {
        confirmButtonText: this.$t('basicData.determine'), // '确定',
        cancelButtonText: this.$t('basicData.Cancel'), // '取消',
        type: 'warning'
      }).then(() => {
        apiAreaDelete(this.formParam.id).then(res => {
          if (res.status === 'OK') {
            this.$message.success(this.$t('basicData.TheOperationSucceeded'));
            this.showDetail = false;
            this.getDataSearch();
          }
        });
      });
    },
    // 导入更新数据
    importChange() {
      this.showImportAddRegion = false;
      this.getDataSearch();
    },
    // 保存
    formSave() {
      this.$refs['formParamData'].validate((valid) => {
        if (valid) {
          const param = {
            id: this.formParam.id,
            parentId: this.formParam.parentId,
            level: this.formParam.parentId ? 2 : 1,
            name: this.formParam.name,
            zipCode: this.formParam.parentId ? this.formParam.zipCode : '',
            twoCode: this.formParam.parentId ? '' : this.formParam.twoCode,
            issTaxation: this.formParam.parentId ? this.formParam.issTaxation : ''
          };
          if (this.operationType === 1) {
            apiAreaAdd(param).then(res => {
              if (res.status === 'OK') {
                this.$message.success(this.$t('basicData.TheOperationSucceeded'));
                this.showDetail = false;
                this.getDataSearch();
              }
            });
          } else {
            apiAreaEdit(param).then(res => {
              if (res.status === 'OK') {
                this.$message.success(this.$t('basicData.TheOperationSucceeded'));
                this.showDetail = false;
                this.getDataSearch();
              }
            });
          }
        }
      });
    },
    // 邮编整理
    zipcodeCollation(zipcode) {
      this.formParam.zipCode = compressList(zipcode).join(',');
    },
    // 搜索
    getDataSearch() {
      this.queryForm.pageNumber = 1;
      this.getTableDate();
    },
    // 重置
    queryFormReset() {
      for (const key in this.queryForm.params) {
        this.queryForm.params[key] = '';
      }
    },
    // 添加、编辑、导入、导出
    handlerOperation(type, row) {
      this.operationType = type;
      if (type !== 4) {
        this.getParentListData();
      }
      if (type === 1) {
        this.formParam = {
          id: '',
          parentId: null,
          level: null,
          name: '',
          zipCode: '',
          twoCode: '',
          issTaxation: ''
        };
        this.showDetail = true;
        return;
      }
      if (type === 2) {
        this.formParam = cloneDeep(row);
        this.showDetail = true;
        return;
      }
      if (type === 3) {
        this.showImportAddRegion = true;
        return;
      }
      if (type === 4) {
        apiAreaExport(this.queryForm.params).then(res => {
          goExportList(this);
        });
      }
    },
    // 当前页面变化
    getCurrencyTableData(val) {
      this.queryForm.pageNumber = val;
      this.getTableDate();
    },
    // 每页显示数量变化
    getSizeTableData(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.getTableDate();
    },
    // 获取父级选项
    getParentListData() {
      const param = {
        pageSize: 100000,
        pageNumber: 1,
        params: {}
      };
      apiAreaPage(param).then(res => {
        if (res.status === 'OK') {
          this.parentList = [];
          res.data.records.map(item => {
            if (item.level === 1) {
              this.parentList.push(item);
            }
          });
        }
      });
    },
    // 获取列表数据
    getTableDate() {
      apiAreaPage(this.queryForm).then(res => {
        if (res.status === 'OK') {
          res.data.records.map(item => {
            item.createUserStr = getUserName(item.createUser, this.userIdAndNameObj);
            item.updateUserStr = getUserName(item.updateUser, this.userIdAndNameObj);
          });
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    }
  }
};
</script>

<style scoped>

</style>
