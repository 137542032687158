var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      ref: "resourcesUp",
      attrs: {
        action: "",
        accept: _vm.accept,
        "auto-upload": _vm.autoUpload,
        "http-request": _vm.enclosureUpload,
        "on-exceed": _vm.handleExceed,
        "on-remove": _vm.handleRemove,
        multiple: _vm.multiple,
        limit: _vm.limit,
        drag: _vm.drag,
        "before-upload": _vm.beforeUpload,
        "file-list": _vm.fileList,
        "on-error": _vm.onError,
        "on-change": _vm.handleChange,
      },
    },
    [
      _vm.drag
        ? [
            _c("i", { staticClass: "el-icon-upload" }),
            _c("div", { staticClass: "el-upload__text" }, [
              _vm._v(_vm._s(_vm.uploadText)),
            ]),
            _c(
              "div",
              {
                staticClass: "el-upload__tip",
                attrs: { slot: "tip" },
                slot: "tip",
              },
              [_vm._v(_vm._s(_vm.tips))]
            ),
          ]
        : [
            _c(
              "el-button",
              {
                staticStyle: { "margin-right": "5px" },
                attrs: { slot: "trigger", size: "small", type: "primary" },
                slot: "trigger",
              },
              [_vm._v(_vm._s(_vm.$t("spareNumberPoo.SelectTheFile")))]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }