var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "wrapper-form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        },
      },
    },
    [
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("company.name"),
            "label-for": "name",
            message: _vm.v$.name.$errors.length
              ? _vm.v$.name.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "name",
              placeholder: _vm.$t("company.namePlaceholder"),
            },
            model: {
              value: _vm.formData.name,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "name", $$v)
              },
              expression: "formData.name",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("company.fantasyName"),
            "label-for": "tradeName",
            message: _vm.v$.tradeName.$errors.length
              ? _vm.v$.tradeName.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "tradeName",
              placeholder: _vm.$t("company.fantasyNamePlaceholder"),
            },
            model: {
              value: _vm.formData.tradeName,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "tradeName", $$v)
              },
              expression: "formData.tradeName",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          directives: [
            {
              name: "mask",
              rawName: "v-mask",
              value: ["###.###.###-##", "##.###.###/####-##"],
              expression: "['###.###.###-##', '##.###.###/####-##']",
            },
          ],
          attrs: {
            label: _vm.$t("company.document"),
            "label-for": "documentation",
            message: _vm.v$.documentation.$errors.length
              ? _vm.v$.documentation.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "documentation",
              placeholder: _vm.$t("company.documentPlaceholder"),
            },
            model: {
              value: _vm.formData.documentation,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "documentation", $$v)
              },
              expression: "formData.documentation",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("company.stateRegistration"),
            "label-for": "stateRegistration",
            message: _vm.v$.stateRegistration.$errors.length
              ? _vm.v$.stateRegistration.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "stateRegistration",
              placeholder: _vm.$t("company.stateRegistrationPlaceholder"),
            },
            model: {
              value: _vm.formData.stateRegistration,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "stateRegistration", $$v)
              },
              expression: "formData.stateRegistration",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          directives: [
            {
              name: "mask",
              rawName: "v-mask",
              value: ["+## (##) ####-####", "+## (##) #####-####"],
              expression: "['+## (##) ####-####', '+## (##) #####-####']",
            },
          ],
          attrs: {
            label: _vm.$t("company.commercialPhone"),
            "label-for": "comercialPhone",
            message: _vm.v$.comercialPhone.$errors.length
              ? _vm.v$.comercialPhone.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "comercialPhone",
              placeholder: _vm.$t("company.commercialPhonePlaceholder"),
            },
            model: {
              value: _vm.formData.comercialPhone,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "comercialPhone", $$v)
              },
              expression: "formData.comercialPhone",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("company.contact"),
            "label-for": "contact",
            message: _vm.v$.contact.$errors.length
              ? _vm.v$.contact.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "contact",
              placeholder: _vm.$t("company.contactPlaceholder"),
            },
            model: {
              value: _vm.formData.contact,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "contact", $$v)
              },
              expression: "formData.contact",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          directives: [
            {
              name: "mask",
              rawName: "v-mask",
              value: ["+## (##) ####-####", "+## (##) #####-####"],
              expression: "['+## (##) ####-####', '+## (##) #####-####']",
            },
          ],
          attrs: {
            label: _vm.$t("company.cellPhone"),
            "label-for": "cellphone",
            message: _vm.v$.cellphone.$errors.length
              ? _vm.v$.cellphone.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "cellphone",
              placeholder: _vm.$t("company.cellPhonePlaceholder"),
            },
            model: {
              value: _vm.formData.cellphone,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "cellphone", $$v)
              },
              expression: "formData.cellphone",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("company.email"),
            "label-for": "email",
            message: _vm.v$.email.$errors.length
              ? _vm.v$.email.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "email",
              placeholder: _vm.$t("company.emailPlaceholder"),
            },
            model: {
              value: _vm.formData.email,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "email", $$v)
              },
              expression: "formData.email",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "o-checkbox",
            {
              model: {
                value: _vm.formData.contractType,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "contractType", $$v)
                },
                expression: "formData.contractType",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("company.contractType")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "FormActions",
        [
          _c("FormActionsPrevious", {
            attrs: { "is-first-step": _vm.isFirstStep },
            on: { handlePrevious: _vm.handlePrevious },
          }),
          _c("FormActionsNext", { attrs: { "is-last-step": _vm.isLastStep } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }