var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-top": "200px", "font-size": "16px" } },
    [
      _vm._m(0),
      _c(
        "div",
        {
          staticStyle: {
            "line-height": "30px",
            "margin-top": "15px",
            "margin-bottom": "35px",
          },
        },
        [
          _c(
            "table",
            {
              staticStyle: {
                width: "100%",
                "text-align": "left",
                "font-size": "16px",
              },
            },
            [
              _c("tr", [
                _c("td", [
                  _vm._v(
                    "Local de partida:" + _vm._s(_vm.paramShippingData.origin)
                  ),
                ]),
                _c("td", [
                  _vm._v("CNPJ:" + _vm._s(_vm.paramShippingData.beginCnpj)),
                ]),
              ]),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    "Endereço:" + _vm._s(_vm.paramShippingData.beginAddress)
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    "Empresa de transporte:" +
                      _vm._s(_vm.paramShippingData.agent)
                  ),
                ]),
                _c("td", [
                  _vm._v("CNPJ:" + _vm._s(_vm.paramShippingData.agentCnpj)),
                ]),
              ]),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    "Número da placa:" + _vm._s(_vm.paramShippingData.trailer)
                  ),
                ]),
                _c("td", [
                  _vm._v(
                    "Número da placa do cavalo:" +
                      _vm._s(_vm.paramShippingData.saleTrailer)
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    "Nome do Motorista:" +
                      _vm._s(_vm.paramShippingData.driverName)
                  ),
                ]),
                _c("td", [
                  _vm._v(
                    "CPF do Motorista:" +
                      _vm._s(_vm.paramShippingData.driverCpf)
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", [
                  _vm._v("Rota:" + _vm._s(_vm.paramShippingData.routeNumber)),
                ]),
              ]),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    "Empresa de gerenciamento de risco:" +
                      _vm._s(_vm.paramShippingData.riskManager)
                  ),
                ]),
                _c("td", [
                  _vm._v(
                    "Solicitação de monitoramento:" +
                      _vm._s(_vm.paramShippingData.monitoringRequest)
                  ),
                ]),
              ]),
            ]
          ),
          _c("hr"),
        ]
      ),
      _vm._l(_vm.paramShippingData.destinations, function (item, index) {
        return _c(
          "div",
          { key: index, staticStyle: { "line-height": "25px" } },
          [
            _c("div", { staticStyle: { "margin-bottom": "30px" } }, [
              _c(
                "table",
                {
                  staticStyle: {
                    width: "100%",
                    "text-align": "left",
                    "font-size": "16px",
                  },
                },
                [
                  _c("tr", [
                    _c("td", [
                      _vm._v("Nome do destino:" + _vm._s(item.destinationName)),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("Endereço:" + _vm._s(item.endAddress))]),
                  ]),
                ]
              ),
              _c("div", [
                _c(
                  "table",
                  { staticStyle: { width: "100%", "text-align": "left" } },
                  [
                    _vm._m(1, true),
                    _c("tr", [
                      _c("td", { staticStyle: { width: "33%" } }, [
                        _vm._v(_vm._s(item.transferNumber)),
                      ]),
                      _c("td", { staticStyle: { width: "33%" } }, [
                        _vm._v(_vm._s(item.quantityBag)),
                      ]),
                      _c("td", { staticStyle: { width: "33%" } }, [
                        _vm._v(_vm._s(item.quantityDeliveries)),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _c("div", [
                _c(
                  "table",
                  { staticStyle: { width: "100%", "text-align": "left" } },
                  [
                    _vm._m(2, true),
                    _c("tr", [
                      _c("td", { staticStyle: { width: "33%" } }, [
                        _vm._v(_vm._s(item.mdfePro || "--")),
                      ]),
                      _c("td", { staticStyle: { width: "33%" } }, [
                        _vm._v(_vm._s(item.mdfeKey)),
                      ]),
                      _c("td", { staticStyle: { width: "33%" } }, [
                        _vm._v(_vm._s(item.mdfeCreateTime || "--")),
                      ]),
                    ]),
                  ]
                ),
                _c("div", { staticStyle: { "margin-left": "16%" } }, [
                  _c("img", { attrs: { id: "barcode" + index } }),
                ]),
              ]),
              _c("hr"),
            ]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "text-align": "center" } }, [
      _c("div", { staticStyle: { "font-weight": "700" } }, [
        _vm._v("Manifesto de Carga"),
      ]),
      _c(
        "div",
        { staticStyle: { display: "flex", "justify-content": "center" } },
        [_c("img", { attrs: { id: "SHIPPING" } })]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticStyle: { width: "33%" } }, [
        _vm._v("Número do Manifesto de Transferência"),
      ]),
      _c("th", { staticStyle: { width: "33%" } }, [
        _vm._v("Quantidade de sacas"),
      ]),
      _c("th", { staticStyle: { width: "33%" } }, [
        _vm._v("Quantidade de pacotes"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticStyle: { width: "33%" } }, [_vm._v("Número de MDFE")]),
      _c("th", { staticStyle: { width: "33%" } }, [_vm._v("Chave do MDFE")]),
      _c("th", { staticStyle: { width: "33%" } }, [
        _vm._v("Tempo de compilação"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }