var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Box" },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c(
            "el-form",
            { staticClass: "selectClass" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.lanshouTime"),
                          },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              clearable: false,
                              type: "daterange",
                              "value-format": "yyyy-MM-dd",
                              "range-separator": _vm.$t("collectionCenter.to"),
                              "start-placeholder": _vm.$t(
                                "collectionCenter.StartTime"
                              ),
                              "end-placeholder": _vm.$t(
                                "collectionCenter.EndTime"
                              ),
                            },
                            on: { change: _vm.dateChange },
                            model: {
                              value: _vm.timeValue,
                              callback: function ($$v) {
                                _vm.timeValue = $$v
                              },
                              expression: "timeValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.CustomerN"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              on: { focus: _vm.getAllCustomer },
                              model: {
                                value: _vm.queryForm.params.customerId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "customerId",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.customerId",
                              },
                            },
                            _vm._l(
                              _vm.customerNameList,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.username,
                                    value: item.id,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "aClass",
                      staticStyle: { width: "auto" },
                      attrs: { span: 6 },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.region") + "：",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                multiple: "",
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              on: {
                                focus: function ($event) {
                                  return _vm.getAList(1)
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.region,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm.params, "region", $$v)
                                },
                                expression: "queryForm.params.region",
                              },
                            },
                            _vm._l(_vm.AList1, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.value, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "aClass",
                      staticStyle: { width: "auto" },
                      attrs: { span: 6 },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.area") + "：",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                multiple: "",
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              on: {
                                focus: function ($event) {
                                  return _vm.getAList(2)
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.area,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm.params, "area", $$v)
                                },
                                expression: "queryForm.params.area",
                              },
                            },
                            _vm._l(_vm.AList2, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.value, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "collectionCenter.PlatformWhichBelongs"
                            ),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                clearable: "",
                              },
                              model: {
                                value: _vm.queryForm.params.platform,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "platform",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.platform",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { value: 1, label: "shein" },
                              }),
                              _c("el-option", {
                                attrs: { value: 2, label: "kwai" },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t(
                                    "collectionCenter.expressDelivery"
                                  ),
                                  value: 3,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("collectionCenter.other"),
                                  value: 0,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:KPI-monitoring-reports:search",
                          expression:
                            "'btn:collection-center:KPI-monitoring-reports:search'",
                          arg: "remove",
                        },
                      ],
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-search",
                      },
                      on: { click: _vm.searchClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.search")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:KPI-monitoring-reports:reset",
                          expression:
                            "'btn:collection-center:KPI-monitoring-reports:reset'",
                          arg: "remove",
                        },
                      ],
                      attrs: { icon: "el-icon-refresh", size: "mini" },
                      on: { click: _vm.resetClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")))]
                  ),
                  _vm.active === "hzFind"
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permit",
                              rawName: "v-permit:remove",
                              value:
                                "btn:collection-center:KPI-monitoring-reports:export",
                              expression:
                                "'btn:collection-center:KPI-monitoring-reports:export'",
                              arg: "remove",
                            },
                          ],
                          attrs: { icon: "el-icon-upload2", size: "mini" },
                          on: { click: _vm.download },
                        },
                        [_vm._v(_vm._s(_vm.$t("collectionCenter.export")))]
                      )
                    : _vm._e(),
                  _vm.active === "mxFind"
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permit",
                              rawName: "v-permit:remove",
                              value:
                                "btn:collection-center:KPI-monitoring-reports:export",
                              expression:
                                "'btn:collection-center:KPI-monitoring-reports:export'",
                              arg: "remove",
                            },
                          ],
                          attrs: { icon: "el-icon-upload2", size: "mini" },
                          on: { click: _vm.downloadMX },
                        },
                        [_vm._v(_vm._s(_vm.$t("collectionCenter.export")))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          model: {
            value: _vm.active,
            callback: function ($$v) {
              _vm.active = $$v
            },
            expression: "active",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("collectionCenter.summary"),
                name: "hzFind",
              },
            },
            [
              _c(
                "el-table",
                {
                  ref: "hzTable",
                  attrs: { "max-height": 600, border: "", data: _vm.tableDate },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("collectionCenter.ind"),
                      type: "index",
                      width: "60",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  (_vm.queryForm.pageNumber - 1) *
                                    _vm.queryForm.pageSize +
                                    scope.$index +
                                    1
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "scanDate",
                      label: _vm.$t("collectionCenter.lanshouT"),
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "customerName",
                      label: _vm.$t("collectionCenter.CustomerName"),
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "customerId",
                      label: _vm.$t("collectionCenter.clientID"),
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "platformName",
                      label: _vm.$t("collectionCenter.platform"),
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "region",
                      label: _vm.$t("collectionCenter.region"),
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "area",
                      label: _vm.$t("collectionCenter.area"),
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "callCollectCount",
                      label: _vm.$t("collectionCenter.doorToLanSTotal"),
                      width: "110",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.callCollectCount
                              ? _c(
                                  "el-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.toDetail(scope.row, 2)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row.callCollectCount))]
                                )
                              : _c("span", [
                                  _vm._v(_vm._s(scope.row.callCollectCount)),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "callDeliveryCount",
                      label: _vm.$t("collectionCenter.doorToDoorTotal"),
                      width: "110",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.callDeliveryCount
                              ? _c(
                                  "el-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.toDetail(scope.row, 1)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row.callDeliveryCount))]
                                )
                              : _c("span", [
                                  _vm._v(_vm._s(scope.row.callDeliveryCount)),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "calculateP90",
                      label: "P90" + _vm.$t("collectionCenter.value") + "(h)",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "sitePunctualRate",
                      label: _vm.$t(
                        "collectionCenter.punctualDepartureRateOutlets"
                      ),
                      width: "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.sitePunctualRate &&
                            Number(scope.row.sitePunctualRate) != 0
                              ? _c(
                                  "el-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.toDetail(scope.row, 3)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row.sitePunctualRate))]
                                )
                              : _c("span", [
                                  _vm._v(_vm._s(scope.row.sitePunctualRate)),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "transferPunctualRate",
                      label: _vm.$t(
                        "collectionCenter.transitCenterPunctualDepartureRate"
                      ),
                      width: "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.transferPunctualRate &&
                            Number(scope.row.transferPunctualRate) != 0
                              ? _c(
                                  "el-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.toDetail(scope.row, 4)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(scope.row.transferPunctualRate)
                                    ),
                                  ]
                                )
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(scope.row.transferPunctualRate)
                                  ),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        align: "center",
                        label: _vm.$t("collectionCenter.OnTimeArrivalRate"),
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "within4hRate",
                          label: "4h",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.within4hRate &&
                                Number(scope.row.within4hRate) != 0
                                  ? _c(
                                      "el-button",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.toDetail(scope.row, 5)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(scope.row.within4hRate))]
                                    )
                                  : _c("span", [
                                      _vm._v(_vm._s(scope.row.within4hRate)),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "within10hRate",
                          label: "10h",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.within10hRate &&
                                Number(scope.row.within10hRate) != 0
                                  ? _c(
                                      "el-button",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.toDetail(scope.row, 6)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(scope.row.within10hRate))]
                                    )
                                  : _c("span", [
                                      _vm._v(_vm._s(scope.row.within10hRate)),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "within16hRate",
                          label: "16h",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.within16hRate &&
                                Number(scope.row.within16hRate) != 0
                                  ? _c(
                                      "el-button",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.toDetail(scope.row, 7)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(scope.row.within16hRate))]
                                    )
                                  : _c("span", [
                                      _vm._v(_vm._s(scope.row.within16hRate)),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c("el-pagination", {
                    staticStyle: { margin: "10px 0" },
                    attrs: {
                      layout: "total, sizes, prev, pager, next, jumper",
                      "current-page": _vm.queryForm.pageNumber,
                      "page-sizes": [10, 20, 50, 100, 500, 1000],
                      "page-size": _vm.queryForm.pageSize,
                      total: _vm.total,
                      background: "",
                    },
                    on: {
                      "update:currentPage": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageNumber", $event)
                      },
                      "update:current-page": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageNumber", $event)
                      },
                      "update:pageSize": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageSize", $event)
                      },
                      "update:page-size": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageSize", $event)
                      },
                      "current-change": _vm.getDatePage,
                      "size-change": _vm.getPageSize,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.active == "mxFind"
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("collectionCenter.particulars"),
                    name: "mxFind",
                  },
                },
                [
                  _c(
                    "el-table",
                    {
                      ref: "mxTable",
                      attrs: {
                        "max-height": 600,
                        border: "",
                        data: _vm.tableDateMX,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "55" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "scanNumber",
                          label: _vm.$t("collectionCenter.Barcode"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "scanTime",
                          label: _vm.$t("collectionCenter.lanshouT"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "collectSiteName",
                          label: _vm.$t("collectionCenter.LanShouSite"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "collectUserName",
                          label: _vm.$t("collectionCenter.LanShouPeople"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "signerTime",
                          label: _vm.$t("collectionCenter.signT"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "siteLoadTime",
                          label: _vm.$t("collectionCenter.networkLoadingTime"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "siteLoadUserName",
                          label: _vm.$t("collectionCenter.networkLoader"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "hubUnloadTime",
                          label: _vm.$t(
                            "collectionCenter.unloadingTransferCenterTime"
                          ),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "hubUnloadUserName",
                          label: _vm.$t(
                            "collectionCenter.unloadingTransferCenterP"
                          ),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "hubLoadTime",
                          label: _vm.$t(
                            "collectionCenter.transferCenterLoadingTime"
                          ),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "hubLoadUserName",
                          label: _vm.$t(
                            "collectionCenter.transferCenterLoader"
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-pagination", {
                        staticStyle: { margin: "10px 0" },
                        attrs: {
                          layout: "total, sizes, prev, pager, next, jumper",
                          "current-page": _vm.queryFormMX.pageNumber,
                          "page-sizes": [10, 20, 50, 100, 500, 1000],
                          "page-size": _vm.queryFormMX.pageSize,
                          total: _vm.totalMX,
                          background: "",
                        },
                        on: {
                          "update:currentPage": function ($event) {
                            return _vm.$set(
                              _vm.queryFormMX,
                              "pageNumber",
                              $event
                            )
                          },
                          "update:current-page": function ($event) {
                            return _vm.$set(
                              _vm.queryFormMX,
                              "pageNumber",
                              $event
                            )
                          },
                          "update:pageSize": function ($event) {
                            return _vm.$set(_vm.queryFormMX, "pageSize", $event)
                          },
                          "update:page-size": function ($event) {
                            return _vm.$set(_vm.queryFormMX, "pageSize", $event)
                          },
                          "current-change": _vm.getDatePageMX,
                          "size-change": _vm.getPageSizeMX,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }