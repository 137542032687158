<template>
  <el-select
    v-model="val" filterable :filter-method="filterMethod"
    :disabled="disabled" placeholder="请选择"
  >
    <el-option
      v-for="item in copyList"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    >
      <span style="float: left">{{ item.label }}</span>
      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.name }}</span>
    </el-option>
  </el-select>
</template>

<script>
import { cloneDeep } from 'lodash-es';

export default {
  name: '',

  props: {
    // 所有州
    list: {
      type: Array,
      default: () => []
    },

    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },

    value: {
      type: [String, Number],
      default: ''
    }
  },

  data() {
    return {
      copyList: []
    };
  },

  computed: {
    val: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit('input', newVal);
      }
    }
  },

  created() {
    this.copyList = cloneDeep(this.list);
  },

  methods: {
    // 搜索
    filterMethod(query, option) {
      const arr = cloneDeep(this.list);

      this.copyList = arr.filter(item => {
        return item.label.toLowerCase().includes(query.toLowerCase()) || item.name.includes(query);
      });
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
