<template>
  <div>
    <div>
      <el-button
        icon="el-icon-download"
        size="small"
        type="primary"
        @click="operationBtn()"
      >{{ $t('receivablePayable.DownloadTheTemplate') }}</el-button>
      <upload-excel
        :on-success="importLogistics"
        :but-name="$t('receivablePayable.ImportCalculatePrices')"
        style="display: inline-block;margin-left: 20px;"
      />
    </div>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import uploadExcel from '@/components/UploadExcel';
const ExcelJS = require('exceljs');

export default {
  name: 'ImportAddFee',
  components: {
    uploadExcel
  },
  props: {
    calculationType: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      outletsList: [],
      codePrice: '/static/codePrice.xlsx'
    };
  },
  methods: {
    // 下载模板
    operationBtn() {
      const that = this;
      if (that.calculationType === 5) {
        // const link = document.createElement('a');
        // link.style.display = 'none';
        // 特殊情况：无法拿到存储语言时，判断是否是中文环境
        const navigatorLanguage = navigator.language.toLowerCase().indexOf('zh') > -1 ? 'zh' : 'pu';
        const language = sessionStorage.getItem('language') || navigatorLanguage;
        const date = new Date().getTime();
        // link.href = this.codePrice;
        let saveName = '';
        if (language === 'zh') {
          // link.setAttribute('download', '导入邮编重新核算价格模板' + date + '.xlsx');
          saveName = '导入邮编重新核算价格模板' + date + '.xlsx';
        } else {
          // link.setAttribute('download', 'Importar CEP para recalculação do modelo de preços' + date + '.xlsx');
          saveName = 'Importar CEP para recalculação do modelo de preços' + date + '.xlsx';
        }
        // document.body.appendChild(link);
        // link.click();
        // link.parentNode.removeChild(link);
        saveAs(this.codePrice, saveName);
        return;
      }
      async function ddd() {
        const wb = new ExcelJS.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        if (that.calculationType === 2) {
          Sheet1.columns = [
            {
              header: '业务单号',
              key: 'waybillNumber',
              width: 40
            },
            {
              header: '订单号',
              key: 'orderNumber',
              width: 40
            }
          ];
          Sheet1.getCell('A2').value = 'número do negócio';
        } else if (that.calculationType === 3) {
          Sheet1.columns = [
            {
              header: '业务单号',
              key: 'waybillNumber',
              width: 40
            },
            {
              header: '订单号',
              key: 'orderNumber',
              width: 40
            },
            {
              header: '计费重量（KG）',
              key: 'weight',
              width: 40
            }
          ];
          Sheet1.getCell('A2').value = 'número do negócio';
          Sheet1.getCell('B2').value = 'Peso faturável（KG）';
          Sheet1.mergeCells('A3:B3');
        }
        Sheet1.getCell('A3').value = '如果订单的“包裹类型”为“合单”时，计费重量请填写总单的重量。每次仅导入40000条（Se o "tipo de pacote" da ordem for "fechado", por favor, preencha o peso total da ordem.Importar apenas 40000 itens de cada vez）';
        Sheet1.getRow(3).getCell(1).font = {
          color: { argb: 'ff0000' }
        };
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        const saveName =
          that.calculationType === 2 ? that.$t('receivablePayable.ImportRecalculatePricesTemplate') + '.xlsx'
            : that.$t('receivablePayable.ImportWeightRecalculationPriceTemplate') + '.xlsx';
        saveAs(blob, saveName);
        return;
      }
      ddd();
    },
    // 导入费用
    importLogistics(array) {
      const dataArr = [];
      const arrData = array[0].results.splice(2);
      let logisticsKey = {};
      if (this.calculationType === 5) {
        logisticsKey = {
          '业务单号': 'waybillNumber',
          '订单号': 'orderNumber',
          '收件人邮编': 'receiveZipcode',
          '发件人邮编': 'senderZipcode',
          '业务类型': 'businessType'
        };
      } else if (this.calculationType === 2) {
        logisticsKey = {
          '业务单号': 'waybillNumber',
          '订单号': 'orderNumber'
        };
      } else {
        logisticsKey = {
          '业务单号': 'waybillNumber',
          '计费重量（KG）': 'weight'
        };
      }
      arrData.map((item) => {
        const dataObj = {};
        for (const k in logisticsKey) {
          if (item[k]) {
            const ITEM = item[k] === this.$t('collectionCenter.expressDelivery') ? 0
              : item[k] === this.$t('collectionCenter.lanshou') ? 1
                : item[k] === this.$t('collectionCenter.dispatch') ? 2 : item[k];

            dataObj[logisticsKey[k]] = ITEM;
          } else {
            dataObj[logisticsKey[k]] = '';
          }
        }
        dataArr.push(dataObj);
      });
      this.$emit('importPrice', dataArr);
    }
  }
};
</script>

<style scoped>

</style>
