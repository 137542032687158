var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "warpp" }, [
    _c("img", {
      attrs: { src: require("@/assets/LinkFailure.png"), alt: "LinkFailure" },
    }),
    _c("div", [
      _c("p", [_vm._v("当前链接失效/Current link is invalid")]),
      _c(
        "p",
        {
          on: {
            click: function ($event) {
              return _vm.$router.go(-1)
            },
          },
        },
        [_vm._v("返回上一级/Return to the previous level")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }