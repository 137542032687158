// 付款方式
export const PAYTYPE_LIST = {
  // 现金
  1: {
    name: '现金',
    value: 1
  },

  // 转账
  2: {
    name: '转账',
    value: 2
  },

  // 网咯
  3: {
    name: '网络',
    value: 3
  },
  // 其他
  4: {
    name: '冲销',
    value: 4
  },

  // 其他
  5: {
    name: '其他',
    value: 5
  }
};

// 支付状态
export const PAYSTATUS_STATUS = {
  1: {
    name: '已支付',
    value: 1
  },
  2: {
    name: '待付款 ',
    value: 2
  },
  3: {
    name: '已关闭',
    value: 3
  }
};
