var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "el-form",
        { attrs: { "label-position": "top" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("customerManagements.title"),
                        "label-width": "60px",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t(
                            "GlobalSetObj.pleaseInputContent"
                          ),
                          clearable: "",
                        },
                        model: {
                          value: _vm.params.title,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.params,
                              "title",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "params.title",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("ClaimsManagement.ClaimNumber") },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: _vm.$t(
                            "GlobalSetObj.pleaseInputContent"
                          ),
                        },
                        model: {
                          value: _vm.params.claimsNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.params,
                              "claimsNumber",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "params.claimsNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.client"),
                        "label-width": "70px",
                      },
                    },
                    [
                      _c("CustomerSelectCmp", {
                        staticStyle: { width: "100%" },
                        attrs: { "is-all": true },
                        model: {
                          value: _vm.params.customerId,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "customerId", $$v)
                          },
                          expression: "params.customerId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("ClaimsManagement.ClaimStatus") },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                          },
                          model: {
                            value: _vm.params.claimsStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "claimsStatus", $$v)
                            },
                            expression: "params.claimsStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.all"),
                              value: "",
                            },
                          }),
                          _vm._l(_vm.CLAIMS_STATUS_SELECT_OBJ, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.name, value: item.value },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.CreatePeople") } },
                    [
                      _c("UserSelectCmp", {
                        staticClass: "form-bottom",
                        staticStyle: { width: "100%" },
                        attrs: { "is-user-name": true },
                        model: {
                          value: _vm.params.createUser,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "createUser", $$v)
                          },
                          expression: "params.createUser",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "110px" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                          },
                          model: {
                            value: _vm.type2,
                            callback: function ($$v) {
                              _vm.type2 = $$v
                            },
                            expression: "type2",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("ClaimsManagement.ApplicationTime"),
                              value: "startedTime",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("collectionCenter.EndTime"),
                              value: "endedTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("el-date-picker", {
                        staticStyle: { display: "block", width: "100%" },
                        attrs: {
                          type: "datetimerange",
                          "start-placeholder": _vm.$t("GlobalSetObj.startDate"),
                          "end-placeholder": _vm.$t("GlobalSetObj.endDate"),
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "default-time": ["09:00:00", "18:00:00"],
                        },
                        model: {
                          value: _vm.params.val2,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "val2", $$v)
                          },
                          expression: "params.val2",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("ClaimsManagement.ClaimsNode") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "form-bottom",
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                          },
                          model: {
                            value: _vm.params.claimsNode,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "claimsNode", $$v)
                            },
                            expression: "params.claimsNode",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.all"),
                              value: "",
                            },
                          }),
                          _vm._l(_vm.CLAIMSNODE_TYPE_OBJ, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.name, value: item.value },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "box", attrs: { label: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "110px" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                          },
                          model: {
                            value: _vm.type1,
                            callback: function ($$v) {
                              _vm.type1 = $$v
                            },
                            expression: "type1",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("collectionCenter.customerNum"),
                              value: "customerNumber",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("basicData.OrderNumber"),
                              value: "orderNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("el-input", {
                        staticClass: "input-with-select",
                        attrs: {
                          placeholder: _vm.$t(
                            "GlobalSetObj.pleaseInputContent"
                          ),
                          clearable: "",
                        },
                        model: {
                          value: _vm.params.val1,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "val1", $$v)
                          },
                          expression: "params.val1",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-button",
        {
          attrs: { type: "primary", size: "small", icon: "el-icon-search" },
          on: { click: _vm.onQuery },
        },
        [_vm._v(_vm._s(_vm.$t("operationCenter.Procurar")))]
      ),
      _c(
        "el-button",
        {
          attrs: { size: "small", icon: "el-icon-refresh-left" },
          on: { click: _vm.onReset },
        },
        [_vm._v(" " + _vm._s(_vm.$t("operationCenter.reset")) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }