var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container box" },
    [
      _c(
        "div",
        { staticClass: "head" },
        [
          _c("div", { staticClass: "head_h" }, [
            _c("h3", [_vm._v(_vm._s(_vm.detailsObj.ticketName))]),
          ]),
          _c("div", { staticClass: "head_tips" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("WorkCentreTable.sort")))]),
            _vm.detailsObj.sort === 1
              ? _c("span", { attrs: { type: "danger" } }, [
                  _vm._v(_vm._s(_vm.$t("WorkCentreTable.urgent"))),
                ])
              : _vm.detailsObj.sort === 2
              ? _c("span", { attrs: { type: "warning" } }, [
                  _vm._v(_vm._s(_vm.$t("WorkCentreTable.high"))),
                ])
              : _vm.detailsObj.sort === 3
              ? _c("span", { attrs: { type: "success" } }, [
                  _vm._v(_vm._s(_vm.$t("WorkCentreTable.normal"))),
                ])
              : _c("span", { attrs: { type: "info" } }, [
                  _vm._v(_vm._s(_vm.$t("WorkCentreTable.low"))),
                ]),
          ]),
          _c("div", { staticClass: "head_tips" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.$t("WorkCentreTable.executeUserName"))),
            ]),
            _c("span", [_vm._v(_vm._s(_vm.detailsObj.executeUserName))]),
          ]),
          _c("div", { staticClass: "head_tips" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("WorkCentreTable.ticketType")))]),
            _c("span", [_vm._v(_vm._s(_vm.detailsObj.ticketType))]),
          ]),
          _c("div", { staticClass: "head_tips" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.$t("WorkCentreTable.ticketStatus"))),
            ]),
            _vm.detailsObj.ticketStatus === "closed"
              ? _c("span", { attrs: { type: "info" } }, [
                  _vm._v(_vm._s(_vm.$t("WorkCentreTable.close"))),
                ])
              : _vm.detailsObj.ticketStatus === "implementing"
              ? _c("span", { attrs: { type: "success" } }, [
                  _vm._v(_vm._s(_vm.$t("WorkCentreTable.implementing"))),
                ])
              : _c("span", [
                  _vm._v(_vm._s(_vm.$t("WorkCentreTable.complete"))),
                ]),
          ]),
          _c("div", { staticClass: "head_tips" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.$t("WorkCentreTable.planFinishTime"))),
            ]),
            _c("span", [_vm._v(_vm._s(_vm.detailsObj.planFinishTime))]),
          ]),
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _vm._v(_vm._s(_vm.$t("WorkCentreTable.ticketDescribe"))),
          ]),
          _c("div", {
            staticClass: "content",
            domProps: { innerHTML: _vm._s(_vm.detailsObj.ticketDescribe) },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.getImgSrc($event)
              },
            },
          }),
          _vm.translateObj.ticketDescribe.length
            ? [
                _c("el-divider", { attrs: { "content-position": "left" } }, [
                  _vm._v(_vm._s(_vm.$t("WorkCentreTable.ContentTranslation"))),
                ]),
                _c("div", {
                  staticClass: "content",
                  domProps: {
                    innerHTML: _vm._s(_vm.translateObj.ticketDescribe),
                  },
                }),
              ]
            : _vm._e(),
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _vm._v(_vm._s(_vm.$t("WorkCentreTable.enclosure"))),
          ]),
          _c("resource-list-cmp", { attrs: { list: _vm.detailsObj.appendix } }),
        ],
        2
      ),
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v(_vm._s(_vm.$t("WorkCentreTable.FollowUpRecord"))),
      ]),
      _c(
        "div",
        { staticClass: "follow_up_record" },
        [_c("log-cmp", { attrs: { list: _vm.translateObj.logArr } })],
        1
      ),
      _c("div", { staticClass: "foot" }, [
        _c(
          "div",
          { staticClass: "foot_left" },
          [
            _c(
              "el-select",
              {
                attrs: { size: "mini", placeholder: "原语言" },
                model: {
                  value: _vm.translateObj.fromType,
                  callback: function ($$v) {
                    _vm.$set(_vm.translateObj, "fromType", $$v)
                  },
                  expression: "translateObj.fromType",
                },
              },
              _vm._l(_vm.LANGS, function (val, key) {
                return _c("el-option", {
                  key: val,
                  attrs: { label: key, value: val },
                })
              }),
              1
            ),
            _vm._v(" TO "),
            _c(
              "el-select",
              {
                attrs: { size: "mini", placeholder: "目标语言" },
                model: {
                  value: _vm.translateObj.toTyle,
                  callback: function ($$v) {
                    _vm.$set(_vm.translateObj, "toTyle", $$v)
                  },
                  expression: "translateObj.toTyle",
                },
              },
              _vm._l(_vm.LANGS, function (val, key) {
                return _c("el-option", {
                  key: val,
                  attrs: { label: key, value: val },
                })
              }),
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "ml",
                attrs: { type: "primary", size: "mini" },
                on: { click: _vm.handlerTranslate },
              },
              [_vm._v(_vm._s(_vm.$t("WorkCentreTable.ContentTranslation")))]
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _vm.isFollowUpStatus
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.handlerOpenDialog },
                  },
                  [_vm._v(_vm._s(_vm.$t("WorkCentreTable.followUp")))]
                )
              : _vm._e(),
            _c(
              "el-button",
              {
                staticClass: "ml",
                on: {
                  click: function ($event) {
                    return _vm.close(false, false)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("WorkCentreTable.cancel")))]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("WorkCentreTable.tips"),
            visible: _vm.dialogObj.visible,
            "append-to-body": true,
            width: "65%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialogObj, "visible", $event)
            },
          },
        },
        [
          _c("add-follow-up-record-cmp", {
            attrs: {
              "ticket-id": _vm.detailsObj.id,
              index: _vm.dialogObj.index,
            },
            on: { updataData: _vm.updataData },
          }),
        ],
        1
      ),
      _c("el-image-viewer", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.imageViewerObj.visible,
            expression: "imageViewerObj.visible",
          },
        ],
        attrs: {
          "on-close": _vm.closeViewer,
          "url-list": _vm.imageViewerObj.imgArr,
          "z-index": 9999,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }