<template>
  <div class="app-container">
    <el-form
      ref="ruleFormRef"
      :inline="false"
      :model="queryForm"
      :rules="rulesObj"
      label-position="top"
      size="large"
      @submit.native.prevent
    >
      <el-row :gutter="20">
        <el-col :span="5">
          <!--   下一站   -->
          <el-form-item :label="$t('GlobalSetObj.nextStation')" prop="siteId">
            <el-select
              v-model="queryForm.siteId"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              style="width:100%"
              @change="handleSiteChange"
            >
              <el-option
                v-for="item in siteList"
                :key="item.id"
                :label="item.code"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-permit:remove="'btn:automaticSorting:provider'" :span="6">
          <!--  系统服务商   -->
          <el-form-item :label="$t('ChannelManage.SystemServiceProvider')" :prop="hasPromise">
            <el-select
              v-model="queryForm.providerId"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              style="width:100%"
              @change="handleProviderChange"
            >
              <el-option
                v-for="item in providerList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('GlobalSetObj.scannerBig')" prop="waybillBagNumber">
            <el-input
              ref="inputRef"
              v-model.trim="queryForm.waybillBagNumber"
              clearable
              style="width:350px"
              @keyup.enter.native="senderOut"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="btnRow">
      <!--  上传    -->
      <el-button size="medium" type="primary" @click="UploadData">
        {{ $t("GlobalSetObj.Upload") }}
      </el-button>
      <!--    已扫  -->
      <div class="header-button-ri">
        <span
          class="hasScan"
        >{{ $t("GlobalSetObj.swept") }}:<i>{{ tableData.length }}</i></span>
      </div>
    </div>
    <!--  表格数据  -->
    <el-table
      :data="tableDataShow"
      :header-cell-style="{background:'#f2f2f2',color:'black'}"
      border
      style="width: 100%"
    >
      <el-table-column
        :label="$t('GlobalSetObj.serial')"
        align="center"
        prop="serial"
        width="80"
      >
        <template #default="scope">
          <i v-if="scope.row.first" class="firstOrder centerBtn">{{ "New" }}</i>
          <div v-else class="centerBtn">{{ scope.row.serial }}</div>
        </template>
      </el-table-column>
      <!--      <el-table-column-->
      <!--        :label="$t('newOrder.waybillNumber')"-->
      <!--        min-width="160"-->
      <!--        prop="waybillNumber"-->
      <!--      >-->
      <!--        <template #default="scope">-->
      <!--          <div :class="{ firstOrder: scope.row.first }">{{ scope.row.waybillNumber || '&#45;&#45;' }}</div>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column
        :label="$t('GlobalSetObj.ScanNumber')"
        min-width="160"
        prop="scanNumber"
      >
        <template #default="scope">
          <div :class="{ firstOrder: scope.row.first }">{{ scope.row.scanNumber || '--' }}</div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('collectionCenter.scanTime')"
        min-width="180"
        prop="scanTime"
      />
      <el-table-column
        :label="$t('collectionCenter.scanner')"
        min-width="150"
        prop="scanUser"
      />
      <el-table-column
        :label="$t('GlobalSetObj.operate')"
        align="center"
        width="120"
      >
        <template slot-scope="scope">
          <div class="centerBtn">
            <i
              class="el-icon-delete"
              style="color:red;cursor:pointer;font-size: 20px"
              @click="handleDelete(scope.row)"
            />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[50, 100, 200, 400]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!-- 隐藏提示音 -->
    <div v-show="false">
      <audio ref="errRef" :src="errorMp3" controls="controls" />
      <audio ref="okRef" :src="okMp3" controls="controls" />
    </div>
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="true"
      :title="$t('collectionCenter.ScannerFail')"
      :visible.sync="tipDialog"
      top="10vh"
      width="600px"
    >
      <div class="tipList">
        <div style="font-size: 18px;margin-bottom: 10px">{{ $t('collectionCenter.tip01') }}</div>
        <div v-for="(item, index) in tipList" :key="index" class="rowNumber">
          <div style="margin-right: 10px">{{ index + 1 + '.' }}</div>
          <div>{{ item }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getAllsite } from '@/api/logisticsOrders';
import { removeShipment, scanShipment, uploadShipment, warehouSenderPage } from '@/api/automaticSorting';
import { providerSystemPage } from '@/api/channelManage';

const okMp3 = 'https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/static/music/su.wav';
const errorMp3 = 'https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/static/music/print_error.wav';
export default {
  name: 'SenderBox',
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      okMp3,
      errorMp3,
      queryForm: {
        pageSize: 50,
        pageNumber: 1,
        total: 0,
        waybillBagNumber: '',
        siteId: '',
        providerId: ''
      },
      siteList: [], // 网点
      tableData: [],
      siteObj: {},
      hasPromise: '',
      rulesObj: {
        siteId: [
          { required: false, message: this.$t('CenterForSorting.pleaseChoose'), trigger: ['blur', 'change'] }
        ],
        providerId: [
          { required: false, message: this.$t('CenterForSorting.pleaseChoose'), trigger: ['blur', 'change'] }
        ]
      },
      tableDataShow: [],
      providerList: [],
      providerObj: null,
      tipDialog: false,
      tipList: []
    };
  },
  created() {
    this.siteObj = {};
    this.queryForm.siteId = '';
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getAllSite();
    const buttonPermissions = this.$store.getters.button_permissions;
    const promise = 'btn:automaticSorting:provider';
    if (buttonPermissions.includes(promise)) {
      this.hasPromise = 'providerId';
    } else {
      this.hasPromise = '';
    }
    // this.hasPromise = buttonPermissions.includes(promise)
    console.log(this.hasPromise, 'hasPromisehasPromise');
    this.getAllProvider();
  },
  methods: {
    // 前端分页
    getscannedData() {
      const startIndex = (this.queryForm.pageNumber - 1) * this.queryForm.pageSize;
      const endIndex = startIndex + this.queryForm.pageSize;
      this.tableDataShow = this.tableData.slice(startIndex, endIndex);
    },
    // 查询
    getSearch(val) {
      warehouSenderPage().then(res => {
        if (res.status === 'OK') {
          this.tableData = res.data;
          this.tableData.forEach((item, index) => {
            item.serial = index + 1;
            if (item.serial === 1) {
              item.first = true;
            }
          });
          this.queryForm.total = this.tableData.length;
          if (val) {
            if (this.tableData.length) {
              this.queryForm.siteId = this.tableData[0].nextSiteId;
              const obj = this.siteList.find(item => {
                return item.id === this.tableData[0].nextSiteId;
              });
              console.log(obj, 'objobj');
              this.siteObj = {
                id: obj.id,
                name: obj.name,
                code: obj.code,
                siteType: obj.siteType
              };
            }
          }
          this.getscannedData();
          console.log(this.tableData, 'tableData');
        }
      });
    },
    // 扫描
    senderOut() {
      this.$refs['ruleFormRef'].validate((valid) => {
        if (valid) {
          if (this.queryForm.waybillBagNumber) {
            const flag = this.tableData.some(item => {
              return item.nextSiteId !== this.queryForm.siteId;
            });
            const flag1 = this.tableData.some(item => {
              return item.scanNumber === this.queryForm.waybillBagNumber;
            });
            if (flag) {
              this.$message.error(this.$t('orderCenterCont.notrepeatOperation'));
              this.$refs.errRef.play();
              this.$nextTick(_ => {
                this.$refs.inputRef.$refs.input.select();
              });
              return;
            }
            if (flag1) {
              this.$message.error(this.$t('GlobalSetObj.notrepeatOperation'));
              this.$refs.errRef.play();
              this.$nextTick(_ => {
                this.$refs.inputRef.$refs.input.select();
              });
              return;
            }
            const data = {
              waybillBagNumber: this.queryForm.waybillBagNumber,
              sourceType: 1,
              site: this.siteObj
            };
            scanShipment(data).then(res => {
              if (res.status === 'OK') {
                if (res.data.nextSite) {
                  this.queryForm.siteId = res.data.nextSite.id;
                  this.siteObj = res.data.nextSite;
                  console.log(this.queryForm.providerId, 'this.queryForm.providerId');
                }
                if (res.data.noArrivalsNumbers) {
                  this.$refs.errRef.play();
                  this.tipList = res.data.noArrivalsNumbers;
                  this.$nextTick(_ => {
                    this.$refs.inputRef.$refs.input.select();
                  });
                  this.tipDialog = true;
                } else {
                  this.tipDialog = false;
                  this.queryForm.waybillBagNumber = '';
                  this.$refs.okRef.play();
                  this.$nextTick(_ => {
                    this.$refs.inputRef.$refs.input.focus();
                  });
                  this.getSearch();
                }
              }
            }).catch(() => {
              this.$refs.errRef.play();
              this.$nextTick(_ => {
                this.$refs.inputRef.$refs.input.select();
              });
            });
          } else {
            this.getSearch();
            // return false;
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 点击删除
    handleDelete(row) {
      removeShipment(row.scanId).then(res => {
        if (res.status === 'OK') {
          this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
          this.getSearch();
        }
      }).catch(() => {
        this.$refs.errRef.play();
      });
    },
    // 上传
    UploadData() {
      if (this.tableData.length === 0) {
        this.$message.warning(this.$t('GlobalSetObj.notDataUpload'));
        return;
      }
      this.$refs['ruleFormRef'].validate((valid) => {
        if (valid) {
          // 运单号
          const waybillBagNumbers = [];
          // 扫描的id
          const scanIds = [];
          this.tableData.forEach(item => {
            waybillBagNumbers.push(item.scanNumber);
            scanIds.push(item.scanId);
          });
          const data = {
            waybillBagNumbers, // 运单号
            scanIds,
            site: this.siteObj,
            providerSystem: this.providerObj
          };
          const loading = this.$loading({
            fullscreen: true,
            lock: true,
            spinner: 'el-icon-loading',
            text: 'loading...',
            background: 'rgba(250, 250, 250, 0.6)'
          });
          uploadShipment(data).then(res => {
            if (res.status === 'OK') {
              loading.close();
              this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
              this.getSearch();
            }
          }).catch(() => {
            loading.close();
            this.$refs.errRef.play();
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 切换下一站
    handleSiteChange(val) {
      if (val) {
        this.queryForm.siteId = val;
        console.log(this.queryForm.siteId, 'this.queryForm.site');
        const obj = this.siteList.find(item => {
          return item.id === val;
        });
        this.siteObj = {
          id: obj.id,
          name: obj.name,
          code: obj.code,
          siteType: obj.siteType
        };
      } else {
        this.siteObj = {};
      }
    },
    // 切换服务商
    handleProviderChange(val) {
      if (val) {
        this.queryForm.providerId = val;
        console.log(this.queryForm.providerId, 'this.queryForm.providerId');
        const obj = this.providerList.find(item => {
          return item.id === val;
        });
        console.log(obj, 'obj');
        this.providerObj = {
          id: obj.id,
          name: obj.name,
          code: obj.code
        };
      } else {
        this.providerObj = null;
      }
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    },
    // 获取所有网点
    async getAllSite() {
      this.siteList = [];
      const res = await getAllsite();
      if (res.status === 'OK') {
        console.log('获取所有网点', res.data);
        this.siteList = res.data;
        this.getSearch(1);
      }
    },
    // 获取所有 服务商
    getAllProvider() {
      const data = {
        pageSize: 10000,
        pageNumber: 1,
        params: {
          name: ''
        }
      };
      providerSystemPage(data).then(res => {
        if (res.status === 'OK') {
          this.providerList = res.data.records;
        }
      });
    }
  }
};
</script>

<style scoped>
.app-container >>> .el-dialog__body {
  padding: 10px 20px;
}

.btnRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.hasScan {
  padding: 3px 10px;
  font-weight: 600;
  margin-left: 10px;
  font-size: 16px;
}

.hasScan i {
  font-style: normal;
  font-size: 22px;
  margin-left: 10px;
  color: #409eff;
}

.centerBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.firstOrder {
  font-size: 16px;
  color: #409eff;
  font-weight: bold;
}

.tipList {
  line-height: 30px;
  height: 400px;
  overflow-y: auto;
}

.rowNumber {
  display: flex;
  font-size: 18px;
  font-weight: bold;
}
</style>
