var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      staticClass: "demo-ruleForm",
      attrs: { model: _vm.ruleForm, "label-width": "100px" },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("GlobalSetObj.remark"), prop: "mark" } },
        [
          _c("el-input", {
            attrs: {
              maxlength: "200",
              type: "textarea",
              "show-word-limit": "",
              placeholder: _vm.$t("GlobalSetObj.pleaseInputContent"),
            },
            model: {
              value: _vm.ruleForm.mark,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "mark", $$v)
              },
              expression: "ruleForm.mark",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
            [_vm._v(_vm._s(_vm.$t("ClaimsManagement.SubmitPayment")))]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function () {
                  return _vm.onClose(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }