<script >
import { ref, defineProps, onMounted } from '@vue/composition-api';
import { default as BaseData } from './steps/baseData';
import { default as ContactData } from './steps/contactData';
import { default as FormAddress } from '@/components/steps/formAddress';
import { useWizardForm } from '@/composable/useWizardForm';
import { useEditBase } from '@/api/transfer/base/query';
import { addingLoading, closeLoading } from '@/utils/handleControlLoading';
import { convert12To24 } from '../../utils/formatHours';
import { companyService } from '@/api/transfer/company';
const __sfc_main = {};
__sfc_main.props = {
  closeModal: {
    type: Function
  },
  baseData: {
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const STEPS_TOTAL = 3;
  function getInitialTime() {
    return props.baseData.shipping_company_base_opening_hours.split(' to ')[0];
  }
  function getFinalTime() {
    return props.baseData.shipping_company_base_opening_hours.split(' to ')[1];
  }
  const formData = ref({
    formStep01: {
      companyName: null,
      companyId: props.baseData.shipping_company_id,
      base: props.baseData.shipping_company_base,
      iata: null,
      document: props.baseData.shipping_company_base_document,
      stateRegistration: props.baseData.shipping_company_base_state_registration,
      receivingCapacity: props.baseData.shipping_company_base_receiving_capacity,
      shippingCapacity: props.baseData.shipping_company_base_shipping_capacity,
      initialHour: convert12To24(getInitialTime()),
      finalHour: convert12To24(getFinalTime())
    },
    formStep02: {
      comercialPhone: props.baseData.shipping_company_base_commercial_phone_number,
      contactName: props.baseData.shipping_company_base_contact_name,
      phone: props.baseData.shipping_company_base_cellphone,
      email: props.baseData.shipping_company_base_email
    },
    formStep03: {
      zipcode: props.baseData.shipping_company_base_zipcode,
      street: props.baseData.shipping_company_base_address,
      number: props.baseData.shipping_company_base_address_number,
      neighborhood: props.baseData.shipping_company_base_neighborhood,
      municipality: props.baseData.shipping_company_base_municipality,
      state: props.baseData.shipping_company_base_state,
      complement: props.baseData.shipping_company_base_address_complement
    }
  });
  async function getCompanyName() {
    try {
      addingLoading();
      const res = await companyService.get(props.baseData.shipping_company_id);
      formData.value.formStep01.companyName = res.data.findShippingCompany.shipping_company_name;
    } catch (error) {
      formData.value.formStep01.companyName = null;
      formData.value.formStep01.companyId = null;
    } finally {
      closeLoading();
    }
  }
  onMounted(async () => {
    await getCompanyName();
  });
  const {
    mutate,
    isLoading
  } = useEditBase();
  function handleSubmit() {
    const newBase = {
      ...formData.value.formStep01,
      ...formData.value.formStep02,
      ...formData.value.formStep03
    };
    mutate({
      newBase,
      id: props.baseData.id
    }, {
      onSuccess() {
        props.closeModal();
      }
    });
  }
  const {
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  } = useWizardForm(formData, handleSubmit, STEPS_TOTAL);
  return {
    formData,
    isLoading,
    step,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
    isFirstStep
  };
};
__sfc_main.components = Object.assign({
  BaseData,
  ContactData,
  FormAddress
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <BaseData
      v-show="step === 0"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      :form-data.sync="formData.formStep01"
    />

    <ContactData
      v-show="step === 1"
      :form-data.sync="formData.formStep02"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
    />

    <FormAddress
      v-show="step === 2"
      :form-data.sync="formData.formStep03"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      :is-edit="true"
    />

    <o-loading :full-page="true" :active.sync="isLoading" />
  </div>
</template>

