<template>
  <div style="width: 100%;height: 100%;">
    <!--    <sortingLargeScreen />-->
    <!-- <div style="font-size: 50px;font-weight: bold;text-align: center;padding-top: 20%;">{{ $t('home.welcomeAnJun') }}</div> -->
    <div style="font-size: 50px;font-weight: bold;text-align: center;padding-top: 20%;">{{ $t('home.welcomeanjunKD') }}</div>
    <!-- 欢迎使用安骏跨境物流系统 -->
    <el-dialog
      :title="$t('login.changePwd')"
      :visible.sync="centerDialogVisible"
      width="500px"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <!-- 请设置新的密码 -->
      <p class="color-DF303A">{{ $t('GlobalSetObj.changePasswordMonth') }}</p>

      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px">
        <!-- 旧密码 -->
        <el-form-item
          :label="$t('changepwd.oldPwd')"
          prop="oldPassword"
        >
          <!-- 请输入旧密码 -->
          <el-input v-model="ruleForm.oldPassword" show-password :placeholder="$t('changepwd.inputOldPwd')" />
        </el-form-item>
        <!-- 新密码 -->
        <el-form-item
          :label="$t('changepwd.newPwd')"
          prop="newPassword"
        >
          <!-- 请输入新密码 -->
          <el-input v-model.trim="ruleForm.newPassword" :placeholder="$t('changepwd.inputNewPwd')" show-password minlength="8" maxlength="12" />

          <!-- 必须由 4-16 位小写或大写字母、数字组成 -->
          <span class="tips">{{ $t('changepwd.inputHint') }}</span>
        </el-form-item>
        <!-- 确认密码 -->
        <el-form-item
          :label="$t('changepwd.checkPwd')"
          prop="newPasswordConfirm"
        >
          <!-- 请再次输入新密码 -->
          <el-input v-model.trim="ruleForm.newPasswordConfirm" :placeholder="$t('changepwd.inputConfirmCode')" show-password minlength="8" maxlength="12" />
        </el-form-item>
        <el-form-item>
          <!-- 确认 -->
          <el-button type="primary" @click="submitForm('ruleForm')">{{ $t('changepwd.confirm') }}</el-button>
          <!-- 重置 -->
          <el-button @click="resetForm('ruleForm')">{{ $t('GlobalSetObj.cancel') }}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
// import sortingLargeScreen from '@/views/largeScreen/sortingLargeScreen';
import {
  asyncGetOrganizationTree,
  asyncGetPositionList,
  asyncGetUserAdditionalInformation
} from '@/utils/asyncTools';
import { weakPwd } from '@/utils/validate';
import { apiResetPassword } from '@/api/user';
import { GlobalSetObj } from '@/lang/type/global';
import md5 from 'js-md5';

export default {
  name: 'IndexSorting',
  components: {
    // sortingLargeScreen
  },
  data() {
    return {
      // 修改密码弹窗
      centerDialogVisible: false,
      ruleForm: {
        newPassword: '', // 新密码
        newPasswordConfirm: '', // 确认新密码
        oldPassword: '', // 旧密码
        userId: this.$store.getters.userId
      },

      rules: {
        oldPassword: [{ required: true, message: this.$t('changepwd.inputOldPwd'), trigger: ['blur', 'change'] }],
        newPassword: [
          { required: true, message: this.$t('changepwd.inputNewPwd'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              const { oldPassword } = this.ruleForm;
              if (!weakPwd(value)) {
                callback(new Error(this.$t('changepwd.requestedInputCode')));
              } else if (value === oldPassword) {
                callback(new Error(this.$t('changepwd.newCodeNotOldCode')));
                return;
              } else {
                callback();
              }
            }
          }
        ],
        newPasswordConfirm: [
          // { required: true, validator: newPasswordConfirmCheck, trigger: 'blur' }
          { required: true, message: this.$t('changepwd.inputConfirmCode'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              const { newPassword } = this.ruleForm;
              if (value === '') {
                callback(new Error(this.$t('changepwd.inputConfirmCode')));
                return;
              }
              if (newPassword !== value) {
                callback(new Error('确认密码与新密码不相同'));
                return;
              }
              callback();
            }
          }
        ]
      }
    };
  },
  computed: {
    GlobalSetObj() {
      return GlobalSetObj;
    }
  },
  async created() {
    // 获取基本信息
    const arr = [
      asyncGetUserAdditionalInformation(),
      asyncGetOrganizationTree(),
      asyncGetPositionList()
    ];
    await Promise.all(arr);
    // this.$store.getters.passwordLastModified为年月日时分秒，转换成时间戳大于当前时间戳三十天时做判断
    const passwordLastModified = new Date(this.$store.getters.passwordLastModified).getTime();
    const now = new Date().getTime();
    if (now - passwordLastModified > 30 * 24 * 60 * 60 * 1000) {
      this.centerDialogVisible = true;
    } else {
      this.centerDialogVisible = false;
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        }
        this.asyncResetPassword();
      });
    },
    resetForm(formName) {
      this.centerDialogVisible = false;
      this.$refs[formName].resetFields();
      this.$store.dispatch('user/logout');
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },

    /**
     * 设置新密码
     */
    asyncResetPassword() {
      // const { newPassword, oldPassword } = this.ruleForm;
      const newPassword = md5(md5(this.ruleForm.newPassword));
      const oldPassword = md5(md5(this.ruleForm.oldPassword));
      const obj = {
        isVerification: 1, // 是否需要验证旧密码
        newPassword,
        oldPassword,
        id: this.$store.getters.userId
      };
      apiResetPassword(obj).then(data => {
        if (data.status === 'OK') {
          return this.$store.dispatch('user/logout');
        }
      }).then(data => {
        this.$notify({
          title: this.$t('changepwd.succeed'),
          message: this.$t('changepwd.loginAgain'),
          type: 'success'
        });
        setTimeout(() => {
          this.$router.push(`/login?redirect=${this.$route.fullPath}`);
        }, 1000);
      }).catch(err => {
        console.log(err);
      });
    }
  }
};
</script>

<style scoped>
.tips {
  color: #b4b2b2;
  font-size: 12px;
  line-height: 13px;
  display: inline-block;
}
.color-DF303A {
  color: #df303a;
  font-size: 14px;
  font-weight: bold;
}
</style>
