var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "60vh", margin: "0 -20px", "overflow-y": "auto" },
    },
    [
      _c(
        "div",
        { staticClass: "msgContainer" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple detailStyle" },
                  [
                    _c("h2", { staticStyle: { "text-align": "center" } }, [
                      _vm._v(_vm._s(_vm.formData.title)),
                    ]),
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.formData.msgData) },
                    }),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }