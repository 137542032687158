var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("collectionCenter.cancelOrder"),
            visible: _vm.tipDialog,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.tipDialog = $event
            },
          },
        },
        [
          _c("div", [
            _c(
              "div",
              {
                staticStyle: {
                  "font-size": "16px",
                  "line-height": "35px",
                  color: "black",
                },
              },
              [
                _c("div", [
                  _vm._v(
                    _vm._s(_vm.$t("orderCenterCont.selected")) +
                      ": " +
                      _vm._s(_vm.selectionList.length)
                  ),
                ]),
                _c("div", [_vm._v(_vm._s(_vm.$t("orderCenterCont.upLoad3")))]),
              ]
            ),
            _c(
              "div",
              [
                _c(
                  "el-upload",
                  {
                    ref: "uploadRef",
                    staticClass: "upload-demo",
                    attrs: {
                      "before-upload": _vm.beforeAvatarUpload,
                      "file-list": _vm.imageList,
                      "http-request": _vm.upLoad,
                      limit: 3,
                      "on-change": _vm.handleChangeFile,
                      "on-remove": _vm.handleDeleteImgRemove,
                      action: "",
                      multiple: "",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "font-weight": "bold" },
                        attrs: { icon: "el-icon-upload", type: "text" },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("GlobalSetObj.UploadPictures")) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.clickConfirm },
                },
                [_vm._v(" " + _vm._s(_vm.$t("GlobalSetObj.determine")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: { click: _vm.clearOrderSelect },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.close")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("collectionCenter.cancelOrder"),
            visible: _vm.clearOrder.showCancelOrder,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.clearOrder, "showCancelOrder", $event)
            },
            close: _vm.clearOrderOver,
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c(
              "div",
              { staticStyle: { "font-weight": "bold", "font-size": "20px" } },
              [_vm._v(_vm._s(_vm.$t("GlobalSetObj.TheOrderBeingCancelled")))]
            ),
          ]),
          _c("div", { staticStyle: { padding: "20px" } }, [
            _c("div", [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    background: "#DADADA",
                    "border-radius": "20px",
                    height: "10px",
                  },
                },
                [
                  _c("div", {
                    staticStyle: {
                      background: "#42B983",
                      height: "100%",
                      "border-radius": "20px",
                    },
                    style: { width: _vm.widthProgress + "%" },
                  }),
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "text-align": "right",
                    color: "#13ADF0",
                    "font-size": "13px",
                    "margin-top": "10px",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.widthProgress < 100
                          ? _vm.$t("newOrder.processing")
                          : _vm.$t("newOrder.ProcessingComplete")
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
            _vm.widthProgress === 100
              ? _c("div", [
                  _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
                    _c("span", [
                      _vm._v(
                        " " + _vm._s(_vm.$t("operationCenter.succeed")) + "："
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#42B983",
                            "margin-right": "30px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.clearOrder.clearSuccess))]
                      ),
                      _vm._v(
                        " " + _vm._s(_vm.$t("operationCenter.fail")) + "："
                      ),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v(_vm._s(_vm.clearOrder.falharOrder)),
                      ]),
                    ]),
                  ]),
                  _vm.clearOrder.falharOrder > 0
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            border: "1px solid #ccc",
                            padding: "10px",
                            "max-height": "170px",
                            "overflow-y": "auto",
                          },
                        },
                        _vm._l(
                          _vm.clearOrder.errorList,
                          function (item, key, ind) {
                            return _c(
                              "div",
                              {
                                key: ind,
                                staticStyle: { "margin-bottom": "10px" },
                              },
                              [_vm._v(_vm._s(key) + "：" + _vm._s(item) + " ")]
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.clearOrder.showCancelOrder = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.close")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }