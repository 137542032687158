
<template>
  <!-- SignForTheQuery 签收查询 -->
  <div class="SignForTheQueryBox">
    <div style="margin-bottom: 20px;">
      <el-form>
        <el-row class="listQueryTime">
          <el-col :span="10" style="width: auto;">
            <!-- 签收时间： -->
            <el-form-item :label="$t('collectionCenter.signTime')">
              <el-date-picker
                v-model="timeValue"
                :clearable="false"
                type="daterange"
                value-format="yyyy-MM-dd"
                :default-time="['00:00:00', '23:59:59']"
                :range-separator="$t('collectionCenter.to')"
                :start-placeholder="$t('collectionCenter.StartTime')"
                :end-placeholder="$t('collectionCenter.EndTime')"
                @change="dateChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="9" style="width: auto;">
            <el-row>
              <!-- 大包号： -->
              <el-form-item :label="$t('collectionCenter.bigBagNumber')">
                <el-input v-model.trim="queryForm.params.bagNumber" clearable :placeholder="$t('collectionCenter.pleaseInput')" />
              </el-form-item>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="8">
          <el-button
            v-permit:remove="'btn:collection-center:report:signForQuery:find'"
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
          <el-button
            v-permit:remove="'btn:collection-center:report:signForQuery:reset'"
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
        </el-col>
      </el-row>
      <el-divider />
    </div>
    <div>
      <el-table
        border
        :data="tableDate"
        :max-height="600"
      >
        <!-- <el-table-column
          type="selection"
          width="55"
        /><el-table-column label="序号" type="index" width="60" align="center">
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="bagNumber"
          :label="$t('collectionCenter.bigBagNumber')"
        /><!-- 大包号 -->
        <el-table-column
          prop="expectedNumber"
          :label="$t('collectionCenter.ExpectedNumberOfDeliveries')"
        /><!-- 预计派件数 -->
        <el-table-column
          prop="realityNumber"
          :label="$t('collectionCenter.ActualNumberOfDispatches')"
        /><!-- 实际派件数 -->
        <el-table-column
          prop="driverName"
          :label="$t('collectionCenter.SendPerson')"
        /><!-- 派件人 -->
        <el-table-column
          prop="signerImg"
          :label="$t('collectionCenter.signImage')"
        ><!-- 签收图片 -->
          <template slot-scope="scope">
            <el-popover
              placement="top"
              :title="$t('collectionCenter.signImage')"
              width="300"
              trigger="click"
              content=""
            >
              <img v-if="scope.row.signerImg" slot="reference" :src="scope.row.signerImg" alt="" style="width: 26px;height: 36px;" @click.stop>
              <div v-else slot="reference" @click.stop>{{ $t('collectionCenter.Null') }}</div><!-- 暂无 -->
              <img v-if="scope.row.signerImg" :src="scope.row.signerImg" alt="" style="width: 100%;height: 100%;">
              <div v-else>{{ $t('collectionCenter.NoContentYet') }}</div><!-- 暂无内容 -->
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          prop="signerTime"
          :label="$t('collectionCenter.signTime')"
        /><!-- 签收时间 -->
      </el-table>
    </div>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getDatePage"
        @size-change="getPageSize"
      />
    </div>
  </div>
</template>

<script>
import { bagPageQuery } from '@/api/lanshou';
import dayjs from 'dayjs';

export default {
  name: 'SignForTheQuery',
  data() {
    return {
      timeValue: [],
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: '', // 开始时间
          endTime: '', // 结束时间
          bagNumber: '' // 大包号
        }
      },
      tableDate: [],
      total: 0
    };
  },
  created() {
    this.defaultCondition();
    this.getDatePage();
  },
  methods: {
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        this.queryForm.params.startTime = '';
        this.queryForm.params.endTime = '';
        return;
      }
      this.queryForm.params.startTime = val[0] + ' 00:00:00';
      this.queryForm.params.endTime = val[1] + ' 23:59:59';
      if (this.queryForm.params.endTime) {
        const endTime = dayjs(this.queryForm.params.startTime).subtract(-1, 'month').format('YYYY-MM-DD') + ' 23:59:59';
        if (this.queryForm.params.endTime > endTime) {
          this.$message({
            // '只允许查1个月内的数据'
            message: this.$t('collectionCenter.OnlyAllowedOneMonthsOfData'),
            type: 'warning'
          });
          const d = new Date();
          const today = dayjs(d).format('YYYY-MM-DD 23:59:59');
          const day = dayjs(d).subtract(3, 'day').format('YYYY-MM-DD 00:00:00');
          this.timeValue = [day, today];
          this.queryForm.params.startTime = day;
          this.queryForm.params.endTime = today;
        }
      }
    },
    defaultCondition() { // 默认查询条件
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD 23:59:59');
      const day = dayjs(d).subtract(3, 'day').format('YYYY-MM-DD 00:00:00');
      this.timeValue = [day, today];
      this.queryForm.params = {
        startTime: day, // 开始时间
        endTime: today, // 结束时间
        bagNumber: '' // 大包号
      };
    },
    getDatePage() { // 表格数据
      const queryForm = JSON.parse(localStorage.getItem('sftqQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      bagPageQuery(queryForm).then(res => {
        if (res.status === 'OK') {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      const query = JSON.parse(localStorage.getItem('sftqQueryForm')) || this.queryForm;
      const queryForm = {
        pageNumber: 1,
        pageSize: this.queryForm.pageSize,
        params: query.params
      };
      bagPageQuery(queryForm).then(res => {
        if (res.status === 'OK') {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    searchClick() {
      if (!this.queryForm.params.startTime || !this.queryForm.params.endTime) {
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
      } else {
        this.queryForm.pageNumber = 1;
        localStorage.setItem('sftqQueryForm', JSON.stringify(this.queryForm));
        this.getDatePage();
      }
    },
    resetClick() {
      this.defaultCondition();
    }
  }
};
</script>

<style lang="scss" scoped>

  .listQueryTime ::v-deep .el-form-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .listQueryTime ::v-deep .el-form-item__label{
    padding: 0;
    line-height: 16px;
    white-space: nowrap;
    margin-left: 20px;
  }
  .listQueryTime ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
  .SignForTheQueryBox{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .SignForTheQueryBox .listQueryTime .delMl ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
</style>
