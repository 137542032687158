var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "wrapper-form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        },
      },
    },
    [
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("uploadFile.lotNumber"),
            "label-for": "numLote",
          },
        },
        [
          _c("o-input", {
            attrs: { id: "numLote", disabled: "" },
            model: {
              value: _vm.formData.numLote,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "numLote", $$v)
              },
              expression: "formData.numLote",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("uploadFile.importDate"),
            "label-for": "dateImport",
          },
        },
        [
          _c("o-input", {
            attrs: { id: "dateImport", disabled: "" },
            model: {
              value: _vm.formData.dateImport,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "dateImport", $$v)
              },
              expression: "formData.dateImport",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          staticClass: "col-span-2",
          attrs: {
            label: _vm.$t("uploadFile.editionCode"),
            "label-for": "codEdition",
            message: _vm.v$.codEdition.$errors.length
              ? _vm.v$.codEdition.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: { id: "codEdition" },
            model: {
              value: _vm.formData.codEdition,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "codEdition", $$v)
              },
              expression: "formData.codEdition",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          staticClass: "col-span-2",
          attrs: { label: _vm.$t("uploadFile.uploadSheet") },
        },
        [
          _c(
            "o-upload",
            {
              attrs: { multiple: false, "drag-drop": "" },
              model: {
                value: _vm.formData.dropFile,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "dropFile", $$v)
                },
                expression: "formData.dropFile",
              },
            },
            [
              _c("section", { staticClass: "wrapper-upload" }, [
                _c(
                  "p",
                  { staticClass: "wrapper-icon" },
                  [
                    _c("o-icon", {
                      attrs: { icon: "upload", size: "is-large" },
                    }),
                  ],
                  1
                ),
                _c("p", { staticClass: "max-w-xs text-center" }, [
                  _vm._v(_vm._s(_vm.$t("uploadFile.dragFile"))),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm.formData.dropFile
        ? _c(
            "div",
            { staticClass: "wrapper-file col-span-2" },
            [
              _c("p", [_vm._v(_vm._s(_vm.formData.dropFile.name))]),
              _c(
                "o-button",
                {
                  attrs: { variant: "danger", size: "sm" },
                  on: { click: _vm.removeFile },
                },
                [_c("o-icon", { attrs: { icon: "trash" } })],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "wapper-buttons col-span-2" },
        [
          _c(
            "o-button",
            {
              attrs: {
                variant: "primary",
                size: "md",
                "native-type": "submit",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("uploadFile.submit")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }