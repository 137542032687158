// 理赔

export const ClaimsManagement = {
  LinkedOrder: {
    zh: '关联订单',
    en: 'Linked order',
    pu: 'Ordem de associação'
  },
  ClaimSettlementProcess: {
    zh: '理赔流程',
    en: 'Claim settlement process',
    pu: 'Processo de reclamação'
  },
  ApplicantsContactInformation: {
    zh: '申请人联系方式',
    en: "Applicant's contact information",
    pu: 'Contato para candidatos'
  },
  ClaimsNode: {
    zh: '理赔节点',
    en: 'Claims node',
    pu: 'Nó de reclamações'
  },
  AmountOfClaim: {
    zh: '理赔金额',
    en: 'Amount of claim',
    pu: 'Montante da reclamação'
  },
  CauseOfClaim: {
    zh: '理赔原因',
    en: 'Cause of claim',
    pu: 'Motivo da reclamação'
  },
  DealWithCustomers: {
    zh: '应付客户',
    en: 'Deal with customers',
    pu: 'Lidar com clientes'
  },
  ClaimNumber: {
    zh: '理赔单号',
    en: 'Claim number',
    pu: 'Número de reclamação'
  },
  ApplyForClaimAmount: {
    zh: '申请理赔金额',
    en: 'Apply for a claim amount',
    pu: 'Pedido de indemnização'
  },
  ActualClaimAmount: {
    zh: '实际理赔金额',
    en: 'Actual claim amount',
    pu: 'Montante real da reclamação'
  },
  ClaimApplicationImportTemplate: {
    zh: '理赔申请导入模板',
    en: 'Claim application import template',
    pu: 'Modelo de importação de solicitação de reclamação'
  },
  Drawee: {
    zh: '付款人',
    en: 'drawee',
    pu: 'Pagador'
  },
  ClaimApplication: {
    zh: '理赔申请',
    en: 'Claim application',
    pu: 'Pedido de reclamação'
  },
  ClaimStatus: {
    zh: '理赔状态',
    en: 'Claim status',
    pu: 'Status da reclamação'
  },
  ApplicationTime: {
    zh: '申请时间',
    en: 'Application time',
    pu: 'Tempo de aplicação'
  },
  CurrentTrajectory: {
    zh: '当前轨迹',
    en: 'Current trajectory',
    pu: 'A trajetória atual'
  },
  TrackTime: {
    zh: '轨迹时间',
    en: 'Track time',
    pu: 'Tempo da trilha'
  },
  SubmitPaymentRequest: {
    zh: '提交付款申请',
    en: 'Submit payment request',
    pu: 'Apresentação do pedido de pagamento'
  },
  Pass: {
    zh: '通过',
    en: 'pass',
    pu: 'Através de'
  },
  TurnDown: {
    zh: '驳回',
    en: 'Turn down',
    pu: 'Recusado.'
  },
  SubmitForReview: {
    zh: '提交审核',
    en: 'Submit for review',
    pu: 'Submeter uma avaliação'
  },
  LastUpdater: {
    zh: '最后更新人',
    en: 'Last updater',
    pu: 'Última atualização homem'
  },
  LastUpdateTime: {
    zh: '最后更新时间',
    en: 'Last update time',
    pu: 'Hora da última atualização'
  },
  DeclarationValueThreshold: {
    zh: '申报价值阈值',
    en: 'Declaration value threshold',
    pu: 'Limiar de valor declarado'
  },
  TimeOfPayment: {
    zh: '支付时间',
    en: 'Time of payment',
    pu: 'Tempo de pagamento'
  },
  PaymentMethod: {
    zh: '支付方式',
    en: 'Payment method',
    pu: 'Formas de pagamento'
  },
  PaymentAccountNumber: {
    zh: '支付账号',
    en: 'Payment account number',
    pu: 'Número da conta'
  },
  Payment: {
    zh: '付款',
    en: 'payment',
    pu: 'pagamentos'
  },
  BillCredit: {
    zh: '账单抵扣',
    en: 'Bill credit',
    pu: 'Dedução de contas'
  },
  BillNumber: {
    zh: '账单编号',
    en: 'Bill number',
    pu: 'Número da fatura'
  },
  ApplyForClaim: {
    zh: '申请理赔',
    en: 'Apply for claim',
    pu: 'Solicitar uma reclamação'
  },
  DefaultAuditor: {
    zh: '默认审核人',
    en: 'Default auditor',
    pu: 'Moderador padrão por'
  },
  PaymentStatus: {
    zh: '支付状态',
    en: 'Payment status',
    pu: 'Estado do pagamento'
  },
  AmountActuallyPaid: {
    zh: '实际支付金额',
    en: 'Amount actually paid',
    pu: 'Montante efectivamente pago'
  },
  ClaimAmount: {
    zh: '理赔申请金额',
    en: 'Claim amount',
    pu: 'Montante do pedido de indemnização'
  },
  ClaimsClient: {
    zh: '理赔客户',
    en: 'Claims client',
    pu: 'Reclamações de clientes'
  },
  Rejected: {
    zh: '已驳回',
    en: 'rejected',
    pu: 'Foi rejeitado'
  },
  Due: {
    zh: '待付款',
    en: 'due',
    pu: 'A ser pago'
  },
  Examine: {
    zh: '审核',
    en: 'examine',
    pu: 'auditoria'
  },
  ReturnCompleted: {
    zh: '退件完成',
    en: 'Return completed',
    pu: 'Devolução de peças concluída'
  },
  ToBeSubmitted: {
    zh: '待提交',
    en: 'To be Submitted',
    pu: 'Para ser submetido'
  },
  Remove: {
    zh: '移除',
    en: 'Remove',
    pu: 'remover'
  },
  TotalNumberOfVotes: {
    zh: '总票数',
    en: 'Total number of votes',
    pu: 'Total de votos'
  },
  TotalDeclaredValue: {
    zh: '总申报价值',
    en: 'Total declared value',
    pu: 'Valor declarado total'
  },
  SubmitPayment: {
    zh: '提交付款',
    en: 'Submit payment',
    pu: 'Enviar um pagamento'
  }
};
