var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detialInfo" },
    [
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.activeNames,
            callback: function ($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames",
          },
        },
        [
          _c(
            "el-collapse-item",
            {
              attrs: {
                title: _vm.$t("newOrder.BasicInformationWaybill"),
                name: "1",
              },
            },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c("basicInfo", {
                    attrs: { "table-infomation": _vm.tableInfomation },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-collapse-item",
            {
              attrs: { title: _vm.$t("GlobalSetObj.qinashouInfo"), name: "2" },
            },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c("signeInfo", {
                    attrs: { "table-infomation": _vm.tableInfomation },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-collapse-item",
            {
              attrs: {
                title: _vm.$t("newOrder.InvoiceInformation"),
                name: "4",
              },
            },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.waybillInvoiceVOList,
                        height: _vm.isHeight,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("newOrder.SerialNumber"),
                          align: "center",
                          type: "index",
                          width: "70",
                        },
                      }),
                      _vm._l(_vm.tableHeaderList, function (item) {
                        return [
                          _c("el-table-column", {
                            key: item.id,
                            attrs: {
                              label: item.label,
                              "min-width": item.cloWidth,
                              prop: item.prop,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row[item.prop]
                                              ? "" + scope.row[item.prop]
                                              : "- -"
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-collapse-item",
            {
              attrs: {
                title: _vm.$t("orderCenterCont.RecipientInformation"),
                name: "5",
              },
            },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c("RecipientsInfo", {
                    attrs: {
                      "encryption-receiver-info": _vm.encryptionReceiverInfo,
                      "receiver-info": _vm.tableInfomation.receiverInfo,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-collapse-item",
            {
              attrs: {
                title: _vm.$t("orderCenterCont.ProductInformation"),
                name: "6",
              },
            },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c("GoodsInfo", {
                    attrs: { "goods-table-data": _vm.goodsTableData },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-collapse-item",
            {
              attrs: {
                title: _vm.$t("orderCenterCont.SenderInformation"),
                name: "7",
              },
            },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c("senderInfo", {
                    attrs: {
                      "encryption-sender-info": _vm.encryptionSenderInfo,
                      "sender-info": _vm.tableInfomation.senderInfo,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }