var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      staticClass: "demo-ruleForm",
      attrs: { "label-position": "top" },
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.RecipientName") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.receiveName))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.RecipientCompany") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.receiveCompany))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.Recipientphone") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.receiveMobile))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("driver.modalStreet") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.receiveStreet))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.doorNumber") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.receiveHouseNumber))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.RecipientDistrict") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.receiveArea))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.RecipientCity") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.receiveCity))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.RecipientState") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.receiveState))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.RecipientZipCode") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.receiveZipcode))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$t("GlobalSetObj.recipientComplement") },
                },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.receiveComplement))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.RecipientCPF") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.receiveTax))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("navbar.receiveIe") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.receiveIeNumber))])]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.ReceivingAddress") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.receiveAddress))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.Recipientphonenumber") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.receivePhone))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.RecipientEmail") } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.receiveMail))])]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }