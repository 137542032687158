var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "detailsBox",
      staticStyle: {
        padding: "20px",
        "box-sizing": "border-box",
        "margin-bottom": "100px",
      },
    },
    [
      _c(
        "div",
        { staticStyle: { width: "250px", height: "30px" } },
        [
          _c(
            "el-popover",
            {
              attrs: {
                placement: "top",
                "popper-class": "copy",
                trigger: "hover",
              },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: {
                    color: "black",
                    "font-size": "12px",
                    padding: "2px",
                  },
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.copyContext(_vm.getDetailsList.orderNumber)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("orderDetails.copy")) + " ")]
              ),
              _c("div", { attrs: { slot: "reference" }, slot: "reference" }, [
                _vm._v(" " + _vm._s(_vm.getDetailsList.orderNumber) + " "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("orderDetails.desRem"), name: "x1" } },
            [
              _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: { "margin-bottom": "20px" },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-bottom": "20px",
                        "font-size": "14px",
                        color: "#12d2ac",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("orderCenterCont.RecipientInformation"))
                      ),
                    ]
                  ),
                  _c(
                    "el-form",
                    { attrs: { "label-width": "auto" } },
                    [
                      _c(
                        "el-descriptions",
                        {
                          attrs: {
                            colon: false,
                            column: 2,
                            direction: "vertical",
                          },
                        },
                        [
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: {
                                label: _vm.$t("newOrder.RecipientName"),
                              },
                            },
                            [
                              _c("div", { staticStyle: { display: "flex" } }, [
                                _vm.showDetail
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.encryptionInfo.receiveName ||
                                              "--"
                                          )
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getDetailsList.receiveName ||
                                              "--"
                                          )
                                        ),
                                      ]
                                    ),
                                _vm.getDetailsList.receiveName
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "iconEye",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlerhidden(
                                              "receiveName"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          { attrs: { placement: "top-start" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "content" },
                                                slot: "content",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "GlobalSetObj.completionInformation"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class": "iconEye",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: {
                                label: _vm.$t("newOrder.RecipientCompany"),
                              },
                            },
                            [
                              _c("div", { staticStyle: { display: "flex" } }, [
                                _vm.showDetail5
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.encryptionInfo.receiveCompany ||
                                              "--"
                                          )
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getDetailsList.receiveCompany ||
                                              "--"
                                          )
                                        ),
                                      ]
                                    ),
                                _vm.getDetailsList.receiveCompany
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "iconEye",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlerhidden(
                                              "receiveCompany"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          { attrs: { placement: "top-start" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "content" },
                                                slot: "content",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "GlobalSetObj.completionInformation"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class": "iconEye",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: {
                                label: _vm.$t("newOrder.Recipientphone"),
                              },
                            },
                            [
                              _c("div", { staticStyle: { display: "flex" } }, [
                                _vm.showDetail1
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.encryptionInfo
                                                .receiveMobile || "--"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getDetailsList.receiveMobile ||
                                              "--"
                                          )
                                        ),
                                      ]
                                    ),
                                _vm.getDetailsList.receiveMobile
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "iconEye",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlerhidden(
                                              "receiveMobile"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          { attrs: { placement: "top-start" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "content" },
                                                slot: "content",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "GlobalSetObj.completionInformation"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class": "iconEye",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: _vm.$t("newOrder.Street1") } },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getDetailsList.receiveStreet || "--"
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: _vm.$t("newOrder.doorNumber") } },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getDetailsList.receiveHouseNumber ||
                                      "--"
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: {
                                label: _vm.$t("newOrder.RecipientDistrict"),
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.getDetailsList.receiveArea || "--")
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: {
                                label: _vm.$t("newOrder.RecipientCity"),
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.getDetailsList.receiveCity || "--")
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: {
                                label: _vm.$t("newOrder.RecipientState"),
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getDetailsList.receiveState || "--"
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: {
                                label: _vm.$t("newOrder.RecipientZipCode"),
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getDetailsList.receiveZipcode || "--"
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-descriptions",
                        {
                          attrs: {
                            colon: false,
                            column: 1,
                            direction: "vertical",
                          },
                        },
                        [
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "GlobalSetObj.recipientComplement"
                                ),
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getDetailsList.receiveComplement || "--"
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-descriptions",
                        {
                          attrs: {
                            colon: false,
                            column: 2,
                            direction: "vertical",
                          },
                        },
                        [
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: { label: _vm.$t("newOrder.RecipientCPF") },
                            },
                            [
                              _c("div", { staticStyle: { display: "flex" } }, [
                                _vm.showDetail11
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.encryptionInfo.receiveTax ||
                                              "--"
                                          )
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getDetailsList.receiveTax ||
                                              "--"
                                          )
                                        ),
                                      ]
                                    ),
                                _vm.getDetailsList.receiveTax
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "iconEye",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlerhidden(
                                              "receiveTax"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          { attrs: { placement: "top-start" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "content" },
                                                slot: "content",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "GlobalSetObj.completionInformation"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class": "iconEye",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "GlobalSetObj.NationalRegistrationNumber"
                                ),
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getDetailsList.receiveIeNumber || "--"
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-descriptions",
                        {
                          attrs: {
                            colon: false,
                            column: 1,
                            direction: "vertical",
                          },
                        },
                        [
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: {
                                label: _vm.$t("newOrder.ReceivingAddress"),
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getDetailsList.receiveAddress || "--"
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-descriptions",
                        {
                          attrs: {
                            colon: false,
                            column: 2,
                            direction: "vertical",
                          },
                        },
                        [
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: {
                                label: _vm.$t("newOrder.Recipientphonenumber"),
                              },
                            },
                            [
                              _c("div", { staticStyle: { display: "flex" } }, [
                                _vm.showDetail2
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.encryptionInfo.receivePhone ||
                                                "--"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getDetailsList.receivePhone ||
                                              "--"
                                          )
                                        ),
                                      ]
                                    ),
                                _vm.getDetailsList.receivePhone
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "iconEye",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlerhidden(
                                              "receivePhone"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          { attrs: { placement: "top-start" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "content" },
                                                slot: "content",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "GlobalSetObj.completionInformation"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class": "iconEye",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: {
                                label: _vm.$t("newOrder.RecipientEmail"),
                              },
                            },
                            [
                              _c("div", { staticStyle: { display: "flex" } }, [
                                _vm.showDetail3
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.encryptionInfo.receiveMail ||
                                              "--"
                                          )
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getDetailsList.receiveMail ||
                                              "--"
                                          )
                                        ),
                                      ]
                                    ),
                                _vm.getDetailsList.receiveMail
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "iconEye",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlerhidden(
                                              "receiveMail"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          { attrs: { placement: "top-start" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "content" },
                                                slot: "content",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "GlobalSetObj.completionInformation"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class": "iconEye",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-bottom": "20px",
                        "font-size": "14px",
                        color: "#12d2ac",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("orderCenterCont.SenderInformation"))
                      ),
                    ]
                  ),
                  _c(
                    "el-form",
                    { attrs: { "label-width": "auto" } },
                    [
                      _c(
                        "el-descriptions",
                        {
                          attrs: {
                            colon: false,
                            column: 2,
                            direction: "vertical",
                          },
                        },
                        [
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: _vm.$t("newOrder.SenderName") } },
                            [
                              _c("div", { staticStyle: { display: "flex" } }, [
                                _vm.showDetail6
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.encryptionInfo.senderName ||
                                              "--"
                                          )
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getDetailsList.senderName ||
                                              "--"
                                          )
                                        ),
                                      ]
                                    ),
                                _vm.getDetailsList.senderName
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "iconEye",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlerhidden(
                                              "senderName"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          { attrs: { placement: "top-start" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "content" },
                                                slot: "content",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "GlobalSetObj.completionInformation"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class": "iconEye",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: {
                                label: _vm.$t("newOrder.SenderCompany"),
                              },
                            },
                            [
                              _c("div", { staticStyle: { display: "flex" } }, [
                                _vm.showDetail7
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.encryptionInfo.senderCompany ||
                                              "--"
                                          )
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getDetailsList.senderCompany ||
                                              "--"
                                          )
                                        ),
                                      ]
                                    ),
                                _vm.getDetailsList.senderCompany
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "iconEye",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlerhidden(
                                              "senderCompany"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          { attrs: { placement: "top-start" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "content" },
                                                slot: "content",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "GlobalSetObj.completionInformation"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class": "iconEye",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: { label: _vm.$t("newOrder.senderPhone") },
                            },
                            [
                              _c("div", { staticStyle: { display: "flex" } }, [
                                _vm.showDetail8
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.encryptionInfo.senderMobile ||
                                              "--"
                                          )
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getDetailsList.senderMobile ||
                                              "--"
                                          )
                                        ),
                                      ]
                                    ),
                                _vm.getDetailsList.senderMobile
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "iconEye",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlerhidden(
                                              "senderMobile"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          { attrs: { placement: "top-start" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "content" },
                                                slot: "content",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "GlobalSetObj.completionInformation"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class": "iconEye",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: _vm.$t("newOrder.Street1") } },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getDetailsList.senderStreet || "--"
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: { label: _vm.$t("newOrder.SenderNumber") },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getDetailsList.senderHouseNumber || "--"
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: {
                                label: _vm.$t("newOrder.SenderDistrict"),
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.getDetailsList.senderArea || "--")
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: _vm.$t("newOrder.SenderCity") } },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.getDetailsList.senderCity || "--")
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: { label: _vm.$t("newOrder.SenderState") },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.getDetailsList.senderState || "--")
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: {
                                label: _vm.$t("newOrder.SenderZipCode"),
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getDetailsList.senderZipcode || "--"
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-descriptions",
                        {
                          attrs: {
                            colon: false,
                            column: 1,
                            direction: "vertical",
                          },
                        },
                        [
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: {
                                label: _vm.$t("GlobalSetObj.sendderComplement"),
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getDetailsList.senderComplement || "--"
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-descriptions",
                        {
                          attrs: {
                            colon: false,
                            column: 2,
                            direction: "vertical",
                          },
                        },
                        [
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: _vm.$t("newOrder.SenderCPF") } },
                            [
                              _c("div", { staticStyle: { display: "flex" } }, [
                                _vm.showDetail12
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.encryptionInfo.senderTax || "--"
                                          )
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getDetailsList.senderTax || "--"
                                          )
                                        ),
                                      ]
                                    ),
                                _vm.getDetailsList.senderTax
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "iconEye",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlerhidden(
                                              "senderTax"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          { attrs: { placement: "top-start" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "content" },
                                                slot: "content",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "GlobalSetObj.completionInformation"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class": "iconEye",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "GlobalSetObj.NationalRegistrationNumber"
                                ),
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getDetailsList.senderIeNumber || "--"
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-descriptions",
                        {
                          attrs: {
                            colon: false,
                            column: 1,
                            direction: "vertical",
                          },
                        },
                        [
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: {
                                label: _vm.$t("newOrder.SendingAddress"),
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getDetailsList.senderAddress || "--"
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-descriptions",
                        {
                          attrs: {
                            colon: false,
                            column: 2,
                            direction: "vertical",
                          },
                        },
                        [
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: { label: _vm.$t("newOrder.SenderPhone") },
                            },
                            [
                              _c("div", { staticStyle: { display: "flex" } }, [
                                _vm.showDetail9
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.encryptionInfo.senderPhone ||
                                              "--"
                                          )
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getDetailsList.senderPhone ||
                                              "--"
                                          )
                                        ),
                                      ]
                                    ),
                                _vm.getDetailsList.senderPhone
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "iconEye",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlerhidden(
                                              "senderPhone"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          { attrs: { placement: "top-start" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "content" },
                                                slot: "content",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "GlobalSetObj.completionInformation"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class": "iconEye",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            {
                              attrs: { label: _vm.$t("newOrder.SenderEmail") },
                            },
                            [
                              _c("div", { staticStyle: { display: "flex" } }, [
                                _vm.showDetail10
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.encryptionInfo.senderMail ||
                                              "--"
                                          )
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getDetailsList.senderMail ||
                                              "--"
                                          )
                                        ),
                                      ]
                                    ),
                                _vm.getDetailsList.senderMail
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "iconEye",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlerhidden(
                                              "senderMail"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          { attrs: { placement: "top-start" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "content" },
                                                slot: "content",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "GlobalSetObj.completionInformation"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class": "iconEye",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("orderDetails.wpMsg"), name: "x2" } },
            [
              _c(
                "el-form",
                {
                  attrs: { "label-position": "top", "label-width": "auto" },
                  model: {
                    value: _vm.getDetailsList.orderWaybillList,
                    callback: function ($$v) {
                      _vm.$set(_vm.getDetailsList, "orderWaybillList", $$v)
                    },
                    expression: "getDetailsList.orderWaybillList",
                  },
                },
                _vm._l(
                  _vm.getDetailsList.orderWaybillList,
                  function (item, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c(
                          "el-card",
                          {
                            staticClass: "box-card",
                            staticStyle: { "margin-bottom": "20px" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  margin: "10px 0",
                                  "font-size": "15px",
                                  color: "#12d2ac",
                                  "font-weight": "bold",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("placeOrder.package")) +
                                    _vm._s(index + 1)
                                ),
                              ]
                            ),
                            _c(
                              "el-descriptions",
                              {
                                attrs: {
                                  colon: false,
                                  column: 2,
                                  direction: "vertical",
                                },
                              },
                              [
                                _c(
                                  "el-descriptions-item",
                                  {
                                    attrs: { label: _vm.$t("placeOrder.long") },
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(_vm._s(item.length || "--")),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-descriptions-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("placeOrder.width"),
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(_vm._s(item.width || "--")),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-descriptions-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("placeOrder.height"),
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(_vm._s(item.height || "--")),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-descriptions-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("placeOrder.weight"),
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(_vm._s(item.weight || "--")),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-descriptions-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("placeOrder.packageType"),
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(_vm._s(item.waybillType || "--")),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-descriptions-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("placeOrder.FaceSheet"),
                                    },
                                  },
                                  [
                                    item.labelUrl
                                      ? _c("div", [
                                          _c(
                                            "div",
                                            {
                                              ref: "invoiceDownLoad",
                                              refInFor: true,
                                              staticClass: "downBtn",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.initDown(item)
                                                },
                                              },
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "el-icon-tickets",
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "downBtnText" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "placeOrder.FaceSheet"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#12d2ac",
                                                    cursor: "pointer",
                                                    "margin-left": "10px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "GlobalSetObj.clickDown"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              item.orderItemList,
                              function (wpItem, index1) {
                                return _c(
                                  "div",
                                  { key: index1, staticClass: "wpXXX" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          margin: "20px 0",
                                          "font-size": "15px",
                                          "font-weight": "bold",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("placeOrder.wp")) +
                                            _vm._s(index1 + 1)
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-descriptions",
                                      {
                                        attrs: {
                                          colon: false,
                                          column: 2,
                                          direction: "vertical",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-descriptions-item",
                                          {
                                            attrs: {
                                              label:
                                                _vm.$t("placeOrder.wpName"),
                                            },
                                          },
                                          [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(wpItem.name || "--")
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "el-descriptions-item",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "placeOrder.declaredValue"
                                              ),
                                            },
                                          },
                                          [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  wpItem.declaredValue || "--"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "el-descriptions-item",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "placeOrder.quantity"
                                              ),
                                            },
                                          },
                                          [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(wpItem.quantity || "--")
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  }
                ),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }