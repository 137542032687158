<template>
  <div class="app-container box">

    <div class="head">
      <div class="head_h">
        <!-- 名称 -->
        <h3>{{ detailsObj.ticketName }}</h3>

      </div>
      <!-- 优先级 -->
      <div class="head_tips">
        <span>{{ $t('WorkCentreTable.sort') }}</span>

        <span
          v-if="detailsObj.sort === 1"
          type="danger"
        >{{ $t('WorkCentreTable.urgent') }}</span>
        <span
          v-else-if="detailsObj.sort === 2"
          type="warning"
        >{{ $t('WorkCentreTable.high') }}</span>
        <span
          v-else-if="detailsObj.sort === 3"
          type="success"
        >{{ $t('WorkCentreTable.normal') }}</span>
        <span
          v-else
          type="info"
        >{{ $t('WorkCentreTable.low') }}</span>
      </div>

      <!-- 执行人 -->
      <div class="head_tips">
        <span>{{ $t('WorkCentreTable.executeUserName') }}</span>
        <span>{{ detailsObj.executeUserName }}</span>
      </div>

      <!-- 类型 -->
      <div class="head_tips">
        <span>{{ $t('WorkCentreTable.ticketType') }}</span>
        <span>{{ detailsObj.ticketType }}</span>
      </div>

      <!-- 状态 -->
      <div class="head_tips">
        <span>{{ $t('WorkCentreTable.ticketStatus') }}</span>

        <span
          v-if="detailsObj.ticketStatus === 'closed'"
          type="info"
        >{{ $t('WorkCentreTable.close') }}</span>
        <span
          v-else-if="detailsObj.ticketStatus === 'implementing'"
          type="success"
        >{{ $t('WorkCentreTable.implementing') }}</span>
        <span
          v-else
        >{{ $t('WorkCentreTable.complete') }}</span>
      </div>

      <!-- 期望完成时间 -->
      <div class="head_tips">
        <span>{{ $t('WorkCentreTable.planFinishTime') }}</span>
        <span>{{ detailsObj.planFinishTime }}</span>
      </div>

      <el-divider content-position="left">{{ $t('WorkCentreTable.ticketDescribe') }}</el-divider>
      <!-- 工单内容 -->
      <div class="content" @click.stop="getImgSrc" v-html="detailsObj.ticketDescribe" />
      <!-- 工单内容 翻译 -->
      <template v-if="translateObj.ticketDescribe.length">
        <el-divider content-position="left">{{ $t('WorkCentreTable.ContentTranslation') }}</el-divider>
        <div class="content" v-html="translateObj.ticketDescribe" />
      </template>

      <el-divider content-position="left">{{ $t('WorkCentreTable.enclosure') }}</el-divider>
      <!-- 附件 -->
      <resource-list-cmp :list="detailsObj.appendix" />

    </div>
    <el-divider content-position="left">{{ $t('WorkCentreTable.FollowUpRecord') }}</el-divider>
    <!-- 跟进记录 -->
    <div class="follow_up_record">
      <log-cmp :list="translateObj.logArr" />
    </div>

    <div class="foot">
      <!-- 翻译 -->
      <div class="foot_left">
        <el-select v-model="translateObj.fromType" size="mini" placeholder="原语言">
          <el-option
            v-for="(val, key) in LANGS"
            :key="val"
            :label="key"
            :value="val"
          />
        </el-select>
        TO
        <el-select v-model="translateObj.toTyle" size="mini" placeholder="目标语言">
          <el-option
            v-for="(val, key) in LANGS"
            :key="val"
            :label="key"
            :value="val"
          />
        </el-select>
        <el-button
          class="ml" type="primary" size="mini"
          @click="handlerTranslate"
        >{{ $t('WorkCentreTable.ContentTranslation') }}</el-button>
      </div>
      <div>

        <!-- 只有实施中才能点击跟进 -->
        <el-button
          v-if="isFollowUpStatus"
          type="primary"
          @click="handlerOpenDialog"
        >{{ $t('WorkCentreTable.followUp') }}</el-button>

        <el-button class="ml" @click="close( false, false )">{{ $t('WorkCentreTable.cancel') }}</el-button>
      </div>
    </div>

    <el-dialog
      :title="$t('WorkCentreTable.tips')"
      :visible.sync="dialogObj.visible"
      :append-to-body="true"
      width="65%"
    >
      <add-follow-up-record-cmp
        :ticket-id="detailsObj.id"
        :index="dialogObj.index"
        @updataData="updataData"
      />
    </el-dialog>

    <!-- 图片预览 -->
    <el-image-viewer
      v-show="imageViewerObj.visible"
      :on-close="closeViewer"
      :url-list="imageViewerObj.imgArr"
      :z-index="9999"
    />

  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import {
  MiniWorkOrderState,
  SORT_STATE,
  FOLLOW_UP_STATUS,
  LANGS
} from '@/enums/workOrdersEnums';
import { apiWorkMicroTicketFollowUpInfoGetFollowUp } from '@/api/workorder';
import { translateSingleText } from '@/utils/translate_util';

export default {

  components: {
    'resource-list-cmp': () => import('@/views/workCentre/components/ResourceListCmp'),
    'add-follow-up-record-cmp': () => import('./AddFollowUpRecordCmp.vue'),
    'log-cmp': () => import('./LogCmp.vue'),
    ElImageViewer
  },

  props: {
    // 工单信息
    detailsObj: {
      type: Object,
      required: true
      // id: 0,
      // ticketName: '', // 名称
      // ticketDescribe: '' // 内容
      // createUserName: '', // 发起人
      // executeUserName: '', // 执行人
      // planFinishTime: '', // 预计完成时间
      // ticketType: '', // 类型
      // ticketStatus: '', // 状态
      // sort: 4 // 优先级
    },

    // 所有发起人
    userIdAndNameObj: {
      type: Object,
      default: () => {}
    },

    index: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      dialogObj: {
        visible: false,
        index: 1
      },

      imageViewerObj: {
        visible: false,
        imgArr: []
      },

      // 翻译
      translateObj: {
        toTyle: '', // 翻译目标语言
        fromType: '', // 原语言
        // langType: LANGS['中文'], // 语言种类
        ticketDescribe: '', // 工单内容
        logArr: [ // // 所有跟进记录
        // {
        //   newTime: '2019-01-01',
        //   createUser: 'xgg',
        //   content: '<p>这是一段文本</p>'
        // }
        ]
      },

      isFollowUpStatus: '' // 跟进状态
    };
  },

  watch: {
    index: {
      handler(newVal) {
        const { ticketStatus } = this.detailsObj;
        this.isFollowUpStatus = ticketStatus === MiniWorkOrderState.IMPLEMENTING.code;

        this.translateObj.ticketDescribe = '';

        this.asyncWorkMicroTicketFollowUpInfoGetFollowUp();
        console.log(this.detailsObj);
      },
      immediate: true
    }
  },

  created() {
    this.MiniWorkOrderState = MiniWorkOrderState;
    this.SORT_STATE = SORT_STATE;
    // this.FOLLOW_UP_STATUS = FOLLOW_UP_STATUS;

    try {
      // 从临时存储中获取翻译语种
      this.translateObj.toTyle = sessionStorage.getItem('toTyle') || LANGS['Portugal'];
      this.translateObj.fromType = sessionStorage.getItem('fromType') || LANGS['中文'];
    } catch (error) {
      console.error(error);
    }

    this.LANGS = LANGS;
  },

  methods: {
    // 打开弹窗
    handlerOpenDialog() {
      const { index } = this.dialogObj;
      this.dialogObj.index = index + 1;
      this.dialogObj.visible = true;
    },

    // 关闭弹窗
    updataData({ isBool, isFollowUpStatus }) {
      console.log(isBool);
      this.dialogObj.visible = false;
      isBool && this.asyncWorkMicroTicketFollowUpInfoGetFollowUp();
      isBool && this.close(true, true);

      this.isFollowUpStatus = isFollowUpStatus;
    },

    // 获取跟进记录
    asyncWorkMicroTicketFollowUpInfoGetFollowUp() {
      const userIdAndNameObj = this.userIdAndNameObj;
      const statusArr = ['primary', 'success', 'danger'];
      apiWorkMicroTicketFollowUpInfoGetFollowUp(this.detailsObj.id).then(res => {
        console.log(res);
        const { status, data } = res;
        if (status !== 'OK') { return; }
        this.translateObj.logArr = data.map(item => {
          const { followUpDescribe, createTime, updateUser, followUpStatus, follower } = item;
          const status = FOLLOW_UP_STATUS[followUpStatus] ? FOLLOW_UP_STATUS[followUpStatus].remark : this.$t('WorkCentreTable.UndefinedState'); // '未定义状态'
          let followerStr = '';
          // 兼容旧数据
          if (follower) {
            followerStr = JSON.parse(follower).map(item => {
              const { englishName, name } = item;
              return name + (englishName ? `(${englishName})` : '');
            }).join('、');
          }

          return {
            newTime: createTime,
            createUser: userIdAndNameObj[updateUser] || this.$t('WorkCentreTable.system'), // '系统'
            content: followUpDescribe,
            contentTranslate: '', // 翻译后的内容
            status,
            followUpStatus,
            statusType: statusArr[followUpStatus] || '',
            followerStr: followerStr

          };
        });
      }).catch(err => {
        console.log(err);
      });
    },

    // 翻译
    handlerTranslate(text) {
      // translateSingleText(text);
      const { ticketDescribe } = this.detailsObj;
      const { fromType, toTyle, logArr } = this.translateObj;

      // 本地存储上次选择的语言
      sessionStorage.setItem('toTyle', toTyle);
      sessionStorage.setItem('fromType', fromType);

      // 翻译内容
      translateSingleText(ticketDescribe, toTyle, fromType).then(res => {
        // console.log(res);
        // console.log('---');
        this.translateObj.ticketDescribe = res;
      }).catch(err => {
        console.log(err);
      });
      // 翻译跟进信息
      Promise.allSettled(logArr.map(item => translateSingleText(item.content, toTyle, fromType))).then(arr => {
        this.translateObj.logArr = logArr.map((item, index) => {
          return {
            ...item,
            contentTranslate: arr[index].status === 'fulfilled' ? arr[index].value : item.content
          };
        });
        console.log(this.translateObj.logArr);
      });
    },

    // 关闭
    close(isBool = true, isUpdateList = false) {
      const obj = {
        isBool, // 关闭弹窗
        isUpdateList // 更新列表
      };

      this.$emit('closeDetailsCmp', obj);
    },

    // 图片预览
    getImgSrc(e) {
      if (e.target.nodeName !== 'IMG') { return; }
      this.imageViewerObj.imgArr = [e.target.src];
      this.imageViewerObj.visible = true;
    },

    // 关闭图片预览
    closeViewer() {
      this.imageViewerObj.visible = false;
    }
  }
};
</script>

<style scoped>
.box {
  height: 84vh;
  overflow-y: scroll;
  /* white-space: nowrap; */
}

.foot {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 68%;
  /* background-color: #dcdfe6; */
  border-top: 2px solid #dcdfe6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 0 20px;
}
.foot div {
  display: flex;
  /* flex-direction: row-reverse; */
  /* border: 1px solid red; */
  padding: 10px 0;

}

.ml {
  margin-left: 20px;
}

.content {
  /* width: 80%; */
  white-space: break-spaces;
  border-left: 4px solid red;
  padding: 5px 8px;
  background-color: #fafafa;
  font-size: 14px;
}

/* 控制富文本的内容 图片 大小 */
.content /deep/ img {
  max-width: 500px;
  height: 100%;
}

.follow_up_record {
  margin-bottom: 80px;
}

.head_h {
  /* display: flex;
  align-items: center; */
  /* width: 50%; */
  display: flex;
}

.head_h h3 {
  margin: 0 0 0 10px;
  /* width: 100px; */
  word-wrap: break-word;
  word-break: normal;
}

.head_tips {
  margin-top: -10px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #817f7f;
}
.head_tips {
  margin-top: 15px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.head_tips span:first-child {
  width: 100px;
  font-weight: bold;
  color: black;
  /* text-align: right; */
  margin-right: 10px;
}

.foot_left {
  display: flex;
  align-items: center;
}

</style>
