var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.row.model === _vm.MODULE_OBJ["6"].value ||
    _vm.row.model === _vm.MODULE_OBJ["7"].value ||
    _vm.row.model === _vm.MODULE_OBJ["8"].value
      ? _c(
          "div",
          [
            _vm.row.status === _vm.IMPORT_STATUS_OBJ["2"].value
              ? _c(
                  "el-link",
                  {
                    attrs: {
                      href: _vm.row.failureDescription,
                      target: "_blank",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("GlobalSetObj.clickDown")))]
                )
              : _c(
                  "el-link",
                  {
                    attrs: {
                      href: _vm.row.failureDescription,
                      target: "_blank",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("GlobalSetObj.clickDown")))]
                ),
          ],
          1
        )
      : _c("span", [_vm._v(_vm._s(_vm.row.failureDescription))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }