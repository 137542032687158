<template><!-- 商户下拉选择器 -->

  <el-select
    v-model="value"
    :loading="loading"
    :placeholder="$t('GlobalSetObj.pleaseChoose')"
    :remote-method="asyncQueryAllMerchantCode"
    filterable
    remote
    @change="onChange"
  >
    <el-option
      v-for="item in list"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
import { queryAllMerchantCode } from '@/api/lanshou.js';

const QUERY = {
  customerId: 0,
  merchantCode: '',
  merchantName: ''
};

export default {
  name: '',

  props: {
    // 商户id
    merchantsId: {
      type: [String, Number],
      required: true
    },

    // 商户名称 便于回显
    merchantsName: {
      type: String,
      required: true
    },

    // 客户id
    customerId: {
      type: [String, Number],
      required: true
    }
  },

  data() {
    return {
      loading: false,
      list: [],
      value: ''
    };
  },

  computed: {
    disabled() {
      return !this.customerId || !this.list.length;
    }
  },

  watch: {
    // 客户变化之后商户也要变化
    'customerId': {
      handler(val) {
        // console.log(564);

        this.value = this.merchantsId;
        this.list = [];
        // this.onChange(this.merchantsId);

        // if (this.merchantsId) {
        //   this.value = this.merchantsId;
        // } else {
        //   this.value = '';
        // }
        this.onChange(this.value);

        if (val) {
          // console.log('564546546564');

          this.asyncQueryAllMerchantCode(this.merchantsName || '');
        }
      }
    }
  },

  methods: {
    // 获取商户列表
    asyncQueryAllMerchantCode(name = '') {
      if (!this.customerId) {
        return;
      }

      QUERY.customerId = this.customerId;
      QUERY.merchantName = name;
      this.loading = true;
      this.list = [];
      queryAllMerchantCode(QUERY, false).then(res => {
        // console.log(res);
        const { records } = res.data;
        this.list = (records || []).map(res => {
          const { merchantCode, merchantName, id } = res;
          const obj = {
            label: `${merchantName}(${merchantCode})`,
            value: id
          };
          return Object.freeze(obj);
        });

        // this.value = this.merchantsId;
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },

    /**
     * @param {number} 商户id
     */
    onChange(val) {
      this.$emit('change', val);
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
