<template> <!-- 客户搜索 -->

  <el-select
    v-model="customerId"
    :loading="loading"
    :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
    style="width: 100%;"
    clearable
    filterable
    remote
    :remote-method="asyncGetPage"
    @change="onChange"
    @clear="asyncGetPage"
  >
    <el-option
      v-for="item in list"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
import { apiCustomerPage } from '@/api/customer.js';

const PARAM = {
  pageNumber: 1,
  pageSize: 10,
  params: {
    quickSearch: ''
  }
};
export default {
  name: '',

  props: {

    // 默认值
    val: {
      type: [String, Number],
      default: ''
    }
  },

  data() {
    return {
      customerId: '',
      list: [],
      loading: false
    };
  },

  watch: {
    val: {
      handler(val) {
        this.customerId = val;
      },
      immediate: true
    }
  },

  created() {
    setTimeout(() => {
      this.asyncGetPage();
    }, 500);
  },

  methods: {
    asyncGetPage(name = '') {
      PARAM.params.quickSearch = name;
      this.loading = true;
      this.list = [];
      apiCustomerPage(PARAM, false).then(res => {
        this.list = (res.data.records || []).map(item => {
          const { id, customerAlias, username } = item;
          const obj = {
            value: id,
            label: `${customerAlias}(${username})`
          };
          return Object.freeze(obj);
        });
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },

    onChange(e) {
      this.$emit('change', e);
    }
  }

};
</script>

<style scoped>
</style>
