import { requestScripting } from '@/lib/axios';
import { removeNullParams } from '@/utils/removeNullParams';

export const orderService = {
  async create(id) {
    return requestScripting({
      method: 'POST',
      url: `orders/${id}`
    });
  },
  async getAll(skip = 0, take = 10, date) {
    return requestScripting({
      method: 'GET',
      url: `orders`,
      params: removeNullParams({
        skip,
        take,
        date
      })
    });
  },
  async updateTransportSupply(data) {
    const body = this.getBodyTransportSupply(data);

    return requestScripting({
      method: 'PATCH',
      data: body,
      url: 'transport-supply/orderSupply'
    });
  },
  async getMaster() {
    return requestScripting({
      method: 'GET',
      url: 'reports/download-file',
      responseType: 'blob'
    });
  },
  getBodyTransportSupply(data) {
    const { listSeals, quantityBags, driverDocument, sealCode } = data;
    const seals = listSeals.map((colorItem) => {
      return {
        'color': colorItem,
        'quantity': sealCode[colorItem].length,
        'codeBar': sealCode[colorItem]
      };
    });

    const body = {
      'supply': [
        {
          'total_sacks': quantityBags,
          'seals': seals,
          'total_seals': seals.length
        }
      ],
      'driver_document': driverDocument
    };

    return body;
  }
};
