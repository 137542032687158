<template>
  <el-dialog
    :append-to-body="true"
    :close-on-click-modal="false"
    :show-close="true"
    :title="$t('placeOrder.BatchExport')"
    :visible.sync="dialogVisibleExport"
    top="10vh"
    width="500px"
    @close="clearForm"
  >
    <el-form
      ref="exportRuleForm"
      :model="exportRuleForm"
      class="demo-exportRuleForm"
      label-position="top"
    >
      <el-form-item
        :rules="{
          required: true,
          message: $t('placeOrder.pleaseInput'),
          trigger: ['blur', 'change']
        }"
        prop="NumberType"
      >
        <el-select v-model="exportRuleForm.NumberType" style="width: 80%">
          <!--运单号-->
          <el-option key="1" :label="$t('orderCenterCont.anjunWaybillNumber')" value="1" />
          <!--服务商物流单号-->
          <el-option key="5" :label="$t('GlobalSetObj.serviceLogistics')" value="5" />
          <!--客户订单号-->
          <el-option key="2" :label="$t('newOrder.PlatformOrderNumber')" value="2" />
          <!--中转仓袋号-->
          <el-option key="4" :label="$t('orderCenterCont.TransitBinBagNumber')" value="4" />
          <!--子包号-->
          <el-option key="7" :label="$t('orderCenterCont.SubpackageNumber')" value="7" />
          <!--派送路线-->
          <!--          <el-option key="6" :label="$t('newOrder.InvoiceNumber')" value="6" />-->
        </el-select>
      </el-form-item>
      <el-form-item
        :rules="{
          required: true,
          message: $t('placeOrder.pleaseInput'),
          trigger: ['blur']
        }"
        prop="inputValeu"
        style="width: 100%"
      >
        <el-input
          v-model="exportRuleForm.inputValeu"
          :placeholder="$t('orderCenterCont.Multiple')"
          clearable
          rows="6"
          type="textarea"
        />
      </el-form-item>
    </el-form>
    <div>
      <el-radio v-model="radio" label="1">{{ $t('GlobalSetObj.ExportingList') }}</el-radio>
      <el-radio v-model="radio" label="2">{{ $t('GlobalSetObj.ExportReceipt') }}</el-radio>
      <el-radio v-model="radio" label="3">{{ $t('GlobalSetObj.ExportSubPackage') }}</el-radio>
    </div>
    <div style="text-align: right;margin: 15px 0;">
      <el-button size="small" @click="dialogVisibleExport = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
      <el-button size="small" type="primary" @click="comfire">{{ $t('collectionCenter.CheckItOutNow') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { exportSignedInfo, waybillExportAll, waybillExportAllSubpackage } from '@/api/logisticsOrders';

export default {
  name: 'OrderNumberExport',
  data() {
    return {
      dialogVisibleExport: false,
      exportRuleForm: {
        NumberType: '1',
        inputValeu: ''
      },
      radio: '1'
    };
  },
  methods: {
    handleOpen() {
      this.dialogVisibleExport = true;
      this.radio = '1';
    },
    clearForm() {
      console.log('clearForm');
      this.exportRuleForm.NumberType = '1';
      this.exportRuleForm.inputValeu = '';
    },
    comfire() {
      this.$refs['exportRuleForm'].validate((valid) => {
        if (valid) {
          const numberList = this.exportRuleForm.inputValeu ? this.exportRuleForm.inputValeu.split('\n') : [];
          const numList = numberList.filter((item) => item !== '');
          if (numList.length > 100000) {
            this.$message.error(this.$t('GlobalSetObj.notMore10w'));
            return;
          }
          if (this.radio === '1') {
            const param = {
              operator: this.$store.state.user.realName,
              operatorId: this.$store.state.user.id,
              exportType: 'CSV',
              sourceType: 2
            };
            console.log('comfire');
            if (this.exportRuleForm.NumberType === '2') {
              param.customerNumbers = numList;
            } else if (this.exportRuleForm.NumberType === '1') {
              param.waybillNumbers = numList;
            } else if (this.exportRuleForm.NumberType === '3') {
              param.bagNumbers = numList;
            } else if (this.exportRuleForm.NumberType === '5') {
              param.logisticsNumbers = numList;
            } else if (this.exportRuleForm.NumberType === '6') {
              param.invoiceNumbers = numList;
            } else if (this.exportRuleForm.NumberType === '7') {
              param.subPackageNumbers = numList;
            } else {
              param.transitBagNumbers = numList;
            }
            waybillExportAll(param).then((res) => {
              if (res.status === 'OK') {
                this.dialogVisibleExport = false;
                const h = this.$createElement;
                this.$msgbox({
                  title: this.$i18n.t('operationCenter.tips'),
                  message: h('p', null, [
                    h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
                    h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
                  ]),
                  showCancelButton: true,
                  confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
                  cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
                })
                  .then((action) => {
                    if (action === 'confirm') {
                      this.showExport = false;
                      if (res.data.taskId) {
                        this.$router.push({ path: '/resource/exportTaskList' });
                      } else {
                        this.$router.push({ path: '/resource/exportList' });
                      }
                    }
                  })
                  .catch(() => {
                  });
              }
            });
          } else if (this.radio === '2') {
            const data = {
              packageType: this.exportRuleForm.NumberType,
              numberLists: numList
            };
            exportSignedInfo(data).then((res) => {
              if (res.status === 'OK') {
                this.dialogVisibleExport = false;
                const h = this.$createElement;
                this.$msgbox({
                  title: this.$i18n.t('operationCenter.tips'),
                  message: h('p', null, [
                    h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
                    h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
                  ]),
                  showCancelButton: true,
                  confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
                  cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
                })
                  .then((action) => {
                    if (action === 'confirm') {
                      this.showExport = false;
                      if (res.data.taskId) {
                        this.$router.push({ path: '/resource/exportTaskList' });
                      } else {
                        this.$router.push({ path: '/resource/exportList' });
                      }
                    }
                  })
                  .catch(() => {
                  });
              }
            });
          } else if (this.radio === '3') {
            const param = { params: {}};
            if (this.exportRuleForm.NumberType === '2') {
              param.params.customerNumbers = numList;
            } else if (this.exportRuleForm.NumberType === '1') {
              param.params.waybillNumbers = numList;
            } else if (this.exportRuleForm.NumberType === '3') {
              param.params.bagNumbers = numList;
            } else if (this.exportRuleForm.NumberType === '5') {
              param.params.logisticsNumbers = numList;
            } else if (this.exportRuleForm.NumberType === '6') {
              param.params.invoiceNumbers = numList;
            } else if (this.exportRuleForm.NumberType === '7') {
              param.params.subPackageNumbers = numList;
            } else {
              param.params.transitBagNumbers = numList;
            }
            waybillExportAllSubpackage(param).then((res) => {
              if (res.status === 'OK') {
                this.dialogVisibleExport = false;
                const h = this.$createElement;
                this.$msgbox({
                  title: this.$i18n.t('operationCenter.tips'),
                  message: h('p', null, [
                    h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
                    h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
                  ]),
                  showCancelButton: true,
                  confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
                  cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
                })
                  .then((action) => {
                    if (action === 'confirm') {
                      this.showExport = false;
                      if (res.data.taskId) {
                        this.$router.push({ path: '/resource/exportTaskList' });
                      } else {
                        this.$router.push({ path: '/resource/exportList' });
                      }
                    }
                  })
                  .catch(() => {
                  });
              }
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style scoped>

</style>
