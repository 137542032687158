import Vue from 'vue';

// 注册 按钮导出禁用指令
const disableExport = Vue.directive('disableExport', {
  // 元素在点击之后添加css10秒禁用样式
  bind(el, binding) {
    el.addEventListener('click', () => {
      el.classList.add('onlyClickOnce10');
      setTimeout(() => {
        el.classList.remove('onlyClickOnce10');
      }, 10000);
    });
  }
});
export { disableExport };
