import { requestScripting } from '@/lib/axios';
import clearString from '@/utils/clearString';
import { removeNullParams } from '@/utils/removeNullParams';

export const vehicleService = {
  async create(data) {
    const body = this.getBody(data);

    return requestScripting({
      method: 'POST',
      data: body,
      url: `vehicle`
    });
  },
  async edit(data, id, isFormatedBody = true) {
    const body = isFormatedBody ? this.getBody(data) : data;

    return requestScripting({
      method: 'PATCH',
      data: body,
      url: `vehicle/${id}`
    });
  },
  async getAll(params, signal) {
    return requestScripting({
      method: 'GET',
      url: `vehicle`,
      params: {
        ...removeNullParams(params),
        take: params.take ?? 10,
        active: params.active ?? 1
      },
      signal
    });
  },

  async getAllRotateVehicles(params) {
    return requestScripting({
      method: 'GET',
      url: 'vehicle/restrict',
      params: {
        ...removeNullParams(params),
        take: params.take ?? 10
      }
    });
  },

  async get(id) {
    return requestScripting({
      method: 'GET',
      url: `vehicle/${id}`
    });
  },
  async delete(id) {
    return requestScripting({
      method: 'DELETE',
      url: `vehicle/${id}`
    });
  },
  getBody(data) {
    const clearDocumentOwner = clearString(data.documentOwner);
    const typeDocument = clearDocumentOwner.length === 11 ? 0 : 1;

    return {
      'vehicle_documentation': data.document,
      'provider_name': data.provider,
      'vehicle_license_plate': clearString(data.licensePlate),
      'trailer_plate': data.trailerPlate ? clearString(data.trailerPlate) : null,
      'vehicle_model': data.model,
      'vehicle_brand': data.brand,
      'vehicle_traction': data.traction ? 1 : 0,
      'vehicle_type_ownership': typeDocument,
      'vehicle_owner_CNPJ': typeDocument === 1 ? clearDocumentOwner : null,
      'vehicle_owner_CPF': typeDocument === 0 ? clearDocumentOwner : null,
      'vehicle_state_registration': data.stateRegistration,
      'vehicle_RNTRC': data.rntrc,
      'vehicle_capacity_m3': data.capacityM3,
      'vehicle_capacity_kg': data.capacityKg,
      'vehicle_tare_weight': data.weight,
      'vehicle_body_type': data.bodyType,
      'vehicle_wheelset_type': data.wheelsetType,
      'car_tracker_company_name': data.trackerCompany,
      'car_tracker_id': data.trackerCompanyCode,
      'vehicle_state': data.state,
      'vehicle_country': 'BR',
      'vehicle_outsourced': data.outsourced ? 1 : 0,
      'vehicle_is_active': 1
    };
  }
};
