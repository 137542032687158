<template>
  <el-form
    ref="ruleForm" v-loading="loading" :model="ruleForm"
    :rules="rules" label-width="100px" class="demo-ruleForm"
  >
    <!-- 供应商 -->
    <el-form-item :label="$t('GlobalSetObj.supplier')">
      <span>{{ providerName }}</span>
    </el-form-item>
    <!-- 邮件 -->
    <el-form-item :label="$t('bank.email')" prop="email">
      <el-input
        v-model.trim="ruleForm.email"
        :placeholder="$t('GlobalSetObj.pleaseInput')"
      />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm('ruleForm')">{{ $t('GlobalSetObj.determine') }}</el-button>
      <el-button @click="() =>onClose(false)">{{ $t('GlobalSetObj.cancel') }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
// import { emailReg } from '@/utils/reg_util.js';
import { getSupplierMainInfo } from '@/api/SupplierMain';
import { apiSitePayableSendMail } from '@/api/finance/receivables';

const emailReg = /.{1,}@.{1,}(\..{1,}){1,2}/g; // 开放邮箱格式，是要 xxx@xx.xxx 格式就行

const RES_OBJ = {
  callBackUrl: `${document.location.origin}/finance/payableManagement/UploadContas`, // 外链地址
  // callBackUrl: `http://192.168.2.20:9529/finance/payableManagement/UploadContas`, // 外链地址
  payableNumber: '', // 批次号
  providerMail: '' // 邮箱
};

export default {
  name: '',

  props: {
    row: {
      type: Object,
      required: true
    }
  },

  data() {
    var checkEmail = (rule, value, callback) => {
      if (!value) {
        const str = this.$t('GlobalSetObj.pleaseInput');
        const str2 = this.$t('bank.email');
        return callback(new Error(str + str2));
      }

      emailReg.lastIndex = 0;

      if (!emailReg.test(value)) {
        const str = this.$t('collectionCenter.emailNotFormat'); // 请输入正确的邮箱
        return callback(new Error(str));
      }

      callback();
    };

    return {
      providerName: '', // 供应商名称
      ruleForm: {
        email: ''
      },

      rules: {
        email: [
          { required: true, validator: checkEmail, trigger: 'blur' }
        ]
      },

      loading: false
    };
  },

  created() {
    this.asyncApiProviderIds();
  },

  methods: {
    // 根据供应商id获取供应商信息
    asyncApiProviderIds() {
      const { providerId } = this.row;
      this.loading = false;
      getSupplierMainInfo(providerId).then(res => {
        const { providerName, email } = res.data;
        this.providerName = providerName;
        this.ruleForm.email = email || '';
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        }
        const { email } = this.ruleForm;
        const { payableNumber } = this.row;
        RES_OBJ.providerMail = email;
        RES_OBJ.payableNumber = payableNumber;
        this.asyncApiSitePayableSendMail(RES_OBJ);
      });
    },

    // 发送邮件
    asyncApiSitePayableSendMail(data) {
      this.loading = true;
      apiSitePayableSendMail(data, false).then(res => {
        this.$message.success(this.$t('GlobalSetObj.succeed'));
        this.onClose(true);
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },

    onClose(isBool) {
      this.$emit('close', isBool);
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
