<template>
  <div class="TimeLimitForSigningBox">
    <el-tabs v-model="findActive" type="border-card">
      <el-tab-pane :label="$t('collectionCenter.byTimeRange')" name="timeFind">
        <timeF />
      </el-tab-pane>
      <el-tab-pane :label="$t('collectionCenter.byOdd')" name="oddFind">
        <odd />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import timeF from '@/views/collectionCenter/Report/TimeLimitForSigning/time';
import odd from '@/views/collectionCenter/Report/TimeLimitForSigning/odd';
export default {
  name: 'TimeLimitForSigning',
  components: {
    timeF,
    odd
  },
  data() {
    return {
      findActive: 'timeFind' // 按时间 timeFind   按单号 oddFind
    };
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped></style>
