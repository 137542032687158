<script >
import { defineProps } from '@vue/composition-api';
import { default as DropdownTable } from '@/components/dropdownTable';
import { default as FormEditRoute } from './form/formEditRoute';
import { default as FormEquipment } from './form/formEquipment';
import { default as FormGenerateOrder } from './form/formGenerateOrder';
import { useModal } from '@/composable/useModal';
const __sfc_main = {};
__sfc_main.props = {
  row: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;

  /* edit route */
  const {
    isOpen: isOpenModalEdit,
    handleVisibility: handleVisibilityModalEdit
  } = useModal();

  /* show sellers */
  const {
    isOpen: isOpenModalShow,
    handleVisibility: handleVisibilityModalShow
  } = useModal();

  /* equipment */
  const {
    isOpen: isOpenModalEquipment,
    handleVisibility: handleVisibilityEquipment
  } = useModal();

  /* order */
  const {
    isOpen: isOpenModalOrder,
    handleVisibility: handleVisibilityOrder
  } = useModal();
  return {
    isOpenModalEdit,
    handleVisibilityModalEdit,
    isOpenModalShow,
    handleVisibilityModalShow,
    isOpenModalEquipment,
    handleVisibilityEquipment,
    isOpenModalOrder,
    handleVisibilityOrder
  };
};
__sfc_main.components = Object.assign({
  DropdownTable,
  FormEditRoute,
  FormEquipment,
  FormGenerateOrder
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <DropdownTable>
      <o-dropdown-item aria-role="listitem" @click="handleVisibilityModalEdit">
        {{ $t('routes.edit') }}
      </o-dropdown-item>

      <o-dropdown-item aria-role="listitem" @click="handleVisibilityModalShow">
        {{ $t('routes.seeSellers') }}
      </o-dropdown-item>

      <o-dropdown-item aria-role="listitem" @click="handleVisibilityEquipment">
        {{ $t('routes.equipment') }}
      </o-dropdown-item>

      <o-dropdown-item aria-role="listitem" @click="handleVisibilityOrder">
        {{ $t('routes.generateOrder') }}
      </o-dropdown-item>
    </DropdownTable>

    <o-modal :active="isOpenModalEdit" width="560" @close="handleVisibilityModalEdit">
      <div>
        <h2 class="header-2">{{ $t('routes.editTitle') }}</h2>
        <p class="paragraph">{{ $t('routes.editParagraph') }}</p>
      </div>
      <FormEditRoute :route="row" :close-modal="handleVisibilityModalEdit" />
    </o-modal>

    <o-modal :active="isOpenModalShow" width="560" @close="handleVisibilityModalShow">
      <div>
        <h2 class="header-2">{{ $t('routes.sellerTitle') }}</h2>
        <p class="paragraph">{{ $t('routes.sellerParagraph') }}</p>
      </div>
      <ul class="list-seller">
        <li v-for="seller in row.rm_route_collect.rm_seller_route" :key="seller.seller_code">
          {{ `${seller.seller_code} - ${seller.seller_name}` }}
        </li>
      </ul>
    </o-modal>

    <o-modal
      :active="isOpenModalEquipment"
      width="600"
      @close="handleVisibilityEquipment"
    >
      <div>
        <h2 class="header-2">{{ $t('routes.equipmentTitle') }}</h2>
        <p class="paragraph">{{ $t('routes.equipmentParagraph') }}</p>
      </div>

      <FormEquipment :close-modal="handleVisibilityEquipment" :route-id="row.route_id" />
    </o-modal>

    <o-modal
      :active="isOpenModalOrder"
      width="600"
      @close="handleVisibilityOrder"
    >
      <div>
        <h2 class="header-2">{{ $t('routes.orderTitle') }}</h2>
        <p class="paragraph">{{ $t('routes.orderParagraph') }}</p>
      </div>

      <FormGenerateOrder :close-modal="handleVisibilityOrder" :route-id="row.route_id" />
    </o-modal>

  </div>
</template>
