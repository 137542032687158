var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      staticStyle: { width: "100%" },
      attrs: {
        filterable: "",
        "filter-method": _vm.filteMethod,
        placeholder: "请选择",
        loading: _vm.loading,
        disabled: _vm.disabled,
        clearable: "",
      },
      on: { change: _vm.change },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    _vm._l(_vm.list, function (item) {
      return _c(
        "el-option",
        { key: item.value, attrs: { label: item.label, value: item.value } },
        [
          _c("span", { staticStyle: { float: "left" } }, [
            _vm._v(_vm._s(item.label)),
          ]),
          _c(
            "span",
            {
              staticStyle: {
                float: "right",
                color: "#8492a6",
                "font-size": "13px",
              },
            },
            [_vm._v(_vm._s(item.username))]
          ),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }