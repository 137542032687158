var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DropdownTable",
        { attrs: { "aria-role": "list" } },
        [
          _c(
            "o-dropdown-item",
            {
              attrs: { "aria-role": "listitem" },
              on: { click: _vm.handleVisibilityModalEdit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("base.edit")) + " ")]
          ),
          _c(
            "o-dropdown-item",
            {
              attrs: { "aria-role": "listitem" },
              on: { click: _vm.handleVisibilityModalDelete },
            },
            [_vm._v(" " + _vm._s(_vm.$t("base.delete")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "o-modal",
        {
          attrs: { active: _vm.isOpenModalEdit, width: "560" },
          on: { close: _vm.handleVisibilityModalEdit },
        },
        [
          _c("div", [
            _c("h2", { staticClass: "header-2" }, [
              _vm._v(_vm._s(_vm.$t("base.editBaseTitle"))),
            ]),
            _c("p", { staticClass: "paragraph" }, [
              _vm._v(_vm._s(_vm.$t("base.editBaseParagraph"))),
            ]),
          ]),
          _c("FormEditBase", {
            attrs: {
              "base-data": _vm.row,
              "close-modal": _vm.handleVisibilityModalEdit,
            },
          }),
        ],
        1
      ),
      _c("Alert", {
        attrs: { active: _vm.isOpenModalDelete },
        on: { handleClose: _vm.handleVisibilityModalDelete },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _vm._v(" " + _vm._s(_vm.$t("base.confirmDeletionTitle")) + " "),
              ]
            },
            proxy: true,
          },
          {
            key: "message",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("base.confirmDeletionParagraph")) +
                    " " +
                    _vm._s(_vm.row.shipping_company_base) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttons",
            fn: function () {
              return [
                _c(
                  "o-button",
                  {
                    attrs: { variant: "outline", size: "md" },
                    on: { click: _vm.handleVisibilityModalDelete },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("base.cancel")) + " ")]
                ),
                _c(
                  "o-button",
                  {
                    attrs: { variant: "primary", size: "md" },
                    on: { click: _vm.handleDelete },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("base.confirmDelete")) + " ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }