<script >
import { defineProps, ref } from '@vue/composition-api';
import { FormActions, FormActionsNext } from '@/components/wizard-form/formStepActions';
import { toast } from '@/utils/programaticToast';
import { getToastContentComponent } from '@/utils/getToastContentComponent';
import { mdfeService } from '@/api/mdfe';
import getMessageError from '@/utils/getMessageError';
import { required, minLength, maxLength, numeric, between, alpha } from '@/utils/validationErrors';
import { addingLoading, closeLoading } from '@/utils/handleControlLoading';
import useVuelidate from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
const __sfc_main = {};
__sfc_main.props = {
  formData: {
    type: Object,
    require: true
  },
  isLastStep: {
    type: Boolean,
    required: true
  },
  handleNext: {
    type: Function
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const vtag = ref('');
  const rules = {
    listVTag: {
      requiredList: helpers.withMessage('Selecione uma Vtag', value => {
        return !!value.length;
      })
    }
  };
  const v$ = useVuelidate(rules, props.formData);
  async function handleSubmit() {
    const isValid = await v$.value.$validate();
    if (isValid) props.handleNext();
  }
  async function handleAddVtag() {
    try {
      addingLoading();
      const res = await mdfeService.getInfoVtag(vtag.value);
      if (res.data.listPackages?.message) {
        throw new Error('Vtag sem nenhum pacote disponível');
      }
      if (props.formData.listVTag.includes(vtag.value)) {
        throw new Error('Essa vtag já foi selecionada, não é possível repetir');
      }
      const {
        listAver,
        listChaveCTE
      } = res.data.listPackages.listPackageAfterCTEs.reduce((acc, itemPackage) => {
        acc.listAver.push(itemPackage.averb);
        acc.listChaveCTE.push({
          chCTe: itemPackage.chaveCTe
        });
        return acc;
      }, {
        listAver: [],
        listChaveCTE: []
      });
      props.formData.segnAver.push(...listAver);
      props.formData.infCTe.push(...listChaveCTE);
      props.formData.listVTag.push(res.data.listPackages.vTag);
      props.formData.totqCTe = res.data.listPackages.qtdPackages.toString();
      props.formData.totvCarga = res.data.listPackages.totalValue;
      props.formData.totqCarga = res.data.listPackages.totalWeight;
      toast.open({
        variant: 'success',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Adicionado com sucesso',
          describe: 'Vtag foi adicionado com sucesso na lista',
          typeIcon: 'success'
        }),
        closable: true,
        duration: 4000
      });
      console.log(res);
    } catch (error) {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Ocorreu um erro',
          describe: getMessageError(error),
          typeIcon: 'error'
        }),
        closable: true,
        duration: 4000
      });
    } finally {
      closeLoading();
    }
  }
  function handleClearVtags() {
    props.formData.segnAver = [];
    props.formData.infCTe = [];
    props.formData.listVTag = [];
    props.formData.totqCTe = null;
    props.formData.totvCarga = null;
    props.formData.totqCarga = null;
  }
  return {
    vtag,
    v$,
    handleSubmit,
    handleAddVtag,
    handleClearVtags
  };
};
__sfc_main.components = Object.assign({
  FormActions,
  FormActionsNext
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <form class="form" @submit.prevent="handleSubmit">
    <fieldset class="search-vtag">
      <o-field
        label="Digite a Vtag"
        label-for="vtag"
        class="flex-1"
        :message="v$.listVTag.$errors.length ? v$.listVTag.$errors[0].$message : null"
      >
        <o-input
          id="vtag"
          v-model="vtag"
          placeholder="Digite a vtag"
        />
      </o-field>

      <o-button
        variant="primary"
        size="md"
        @click="handleAddVtag"
      >
        <o-icon icon="search" />
      </o-button>
    </fieldset>

    <ul class="list-vtags">
      <li
        v-for="vtag in formData.listVTag"
        :key="vtag"
        class="vtag"
      >
        {{ `${vtag}` }}
      </li>
    </ul>

    <FormActions>
      <div>
        <o-button
          v-show="!!formData.listVTag.length"
          variant="danger"
          size="md"
          @click="handleClearVtags"
        >
          Limpar tudo
        </o-button>
      </div>

      <FormActionsNext :is-last-step="isLastStep" />
    </FormActions>

  </form>
</template>

<style scoped>
.form {
  display:grid;
  gap: 1rem;
}

.search-vtag {
  display: flex;
  align-items: flex-end;
  gap: 0.8rem;
}

.flex-1 {
  flex: 1;
}

.list-vtags {
  max-height: 24rem/* 384px */;
  overflow-y: auto;
  display: grid;
  gap: 1rem/* 16px */;
}

.vtag {
  font-weight: 700;
}
</style>
