var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm box",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "200px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("WorkCentreTable.ticketName"),
                        prop: "ticketName",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t(
                            "operationCenter.PleaseEnterTheContent"
                          ),
                        },
                        model: {
                          value: _vm.ruleForm.ticketName,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "ticketName", $$v)
                          },
                          expression: "ruleForm.ticketName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("WorkCentreTable.ticketType"),
                prop: "ticketType",
              },
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: _vm.$t("operationCenter.PleaseSelect"),
                    filterable: "",
                  },
                  model: {
                    value: _vm.ruleForm.ticketType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "ticketType", $$v)
                    },
                    expression: "ruleForm.ticketType",
                  },
                },
                _vm._l(_vm.ticketOption, function (item) {
                  return _c(
                    "el-option",
                    {
                      key: item.id,
                      attrs: {
                        label:
                          item.typeName +
                          (item.englishName
                            ? "(" + item.englishName + ")"
                            : ""),
                        value: item.typeName,
                      },
                    },
                    [
                      _c("span", { staticStyle: { float: "left" } }, [
                        _vm._v(_vm._s(item.typeName)),
                      ]),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            float: "right",
                            color: "#8492a6",
                            "font-size": "13px",
                          },
                        },
                        [_vm._v(_vm._s(item.englishName))]
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("WorkCentreTable.ticketDescribe"),
                prop: "ticketDescribe",
              },
            },
            [
              _c("tinymce", {
                attrs: { height: 200 },
                model: {
                  value: _vm.ruleForm.ticketDescribe,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "ticketDescribe", $$v)
                  },
                  expression: "ruleForm.ticketDescribe",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("WorkCentreTable.executeUserName") } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    clearable: "",
                    filterable: "",
                    remote: "",
                    "remote-method": _vm.asyncGetUserPage,
                    loading: _vm.userObj.loading,
                    placeholder: _vm.$t("operationCenter.PleaseSelect"),
                  },
                  model: {
                    value: _vm.ruleForm.executeUser,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "executeUser", $$v)
                    },
                    expression: "ruleForm.executeUser",
                  },
                },
                _vm._l(_vm.userObj.arr, function (item) {
                  return _c(
                    "el-option",
                    {
                      key: item.id,
                      attrs: {
                        label:
                          item.name +
                          (item.englishName
                            ? "(" + item.englishName + ")"
                            : ""),
                        value: JSON.stringify({
                          id: item.id,
                          name: item.name,
                          englishName: item.englishName || "",
                        }),
                      },
                    },
                    [
                      _c("span", { staticStyle: { float: "left" } }, [
                        _vm._v(
                          _vm._s(item.name) +
                            _vm._s(
                              item.englishName
                                ? "(" + item.englishName + ")"
                                : ""
                            )
                        ),
                      ]),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            float: "right",
                            color: "#8492a6",
                            "font-size": "13px",
                          },
                        },
                        [_vm._v(_vm._s(item.positionName))]
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("WorkCentreTable.planFinishTime"),
                prop: "planFinishTime",
              },
            },
            [
              _c("el-date-picker", {
                attrs: {
                  "picker-options": _vm.pickerOptions,
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  type: "datetime",
                  placeholder: _vm.$t("operationCenter.PleaseSelect"),
                },
                model: {
                  value: _vm.ruleForm.planFinishTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "planFinishTime", $$v)
                  },
                  expression: "ruleForm.planFinishTime",
                },
              }),
              _c("span", { staticClass: "red" }, [
                _vm._v(_vm._s(_vm.$t("WorkCentreTable.DongbaDistrict"))),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("WorkCentreTable.sort"), prop: "sort" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  model: {
                    value: _vm.ruleForm.sort,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "sort", $$v)
                    },
                    expression: "ruleForm.sort",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("WorkCentreTable.urgent"))),
                  ]),
                  _c("el-radio-button", { attrs: { label: 2 } }, [
                    _vm._v(_vm._s(_vm.$t("WorkCentreTable.high"))),
                  ]),
                  _c("el-radio-button", { attrs: { label: 3 } }, [
                    _vm._v(_vm._s(_vm.$t("WorkCentreTable.normal"))),
                  ]),
                  _c("el-radio-button", { attrs: { label: 4 } }, [
                    _vm._v(_vm._s(_vm.$t("WorkCentreTable.low"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "appendix",
                        label: _vm.$t("WorkCentreTable.enclosure"),
                      },
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "resourcesUpload",
                          staticClass: "upload-demo",
                          attrs: {
                            action: "",
                            multiple: "",
                            "http-request": _vm.avatarUpload,
                            "auto-upload": false,
                            limit: 5,
                            "file-list": _vm.fileList,
                            "on-remove": _vm.removeFile,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "5px" },
                              attrs: {
                                slot: "trigger",
                                size: "small",
                                type: "primary",
                              },
                              slot: "trigger",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("WorkCentreTable.selection"))
                              ),
                            ]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "5px" },
                              attrs: { size: "small", type: "success" },
                              on: { click: _vm.resourcesUpload },
                            },
                            [_vm._v(_vm._s(_vm.$t("WorkCentreTable.upload")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "btnStyle" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("ruleForm")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("operationCenter.add")))]
          ),
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(_vm._s(_vm.$t("operationCenter.Cancel"))),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }