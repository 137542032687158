<template>
  <div class="warehousingBox">
    <!-- 入库 -->
    <div class="warehousingClass">
      <div class="changeClass">
        <div class="scanClass scanRecords">
          <!-- 网点揽收入库扫描： -->
          <span style="display: block;">{{ $t('operatingPlatform.warehousingScan') }}</span>
          <el-input
            v-model.trim="warehousingScan"
            :placeholder="$t('operatingPlatform.PleaseScan')"
            prefix-icon="el-icon-full-screen"
            clearable
            class="iconBtn"
            @keyup.enter.native="addTable"
          >
            <!-- 已扫描 -->                                                                               <!-- 票 -->
            <template slot="append">{{ $t('collectionCenter.scanned') }}<span>{{ scanTableData.length }}</span>{{ $t('collectionCenter.ticket') }}</template>
          </el-input>
        </div>
        <div class="scanClass">
          <span style="display: block;">{{ $t('operatingPlatform.weighingMethod') }}</span><!-- 称重方式： -->
          <el-radio-group v-model="weighingMode" @input="DeviceSetup" @change="radioChange">
            <el-radio-button :label="1">{{ $t('operatingPlatform.Other') }}</el-radio-button><!-- 其它 -->
            <el-radio-button :label="2">{{ $t('operatingPlatform.SerialPort') }}</el-radio-button><!-- 快手设备 -->
            <el-radio-button :label="3">{{ $t('operatingPlatform.racerDevices') }}</el-radio-button><!-- 快手设备 -->
          </el-radio-group>
        </div>
        <div v-show="weighingMode===2" class="scanClass">
          <span style="display: block;">{{ $t('operatingPlatform.weighingEquipmentBrand') }}</span><!-- 称重设备品牌： -->
          <el-radio-group v-model="equipmentBrand" @input="setupElectronicScales">
            <el-radio-button label="TSC-L">TSC-L</el-radio-button>
            <el-radio-button label="PRIX-3FIT">PRIX-3FIT</el-radio-button>
          </el-radio-group>
        </div>
        <div class="scanClass">
          <!-- 输入重量： -->
          <span style="display: block;">{{ $t('operatingPlatform.inputWeight') }}</span>
          <el-input
            ref="inputRef"
            v-model="warehousingKG"
            :placeholder="$t('operatingPlatform.pleaseInput')"
            class="warehousingKGClass"
            clearable
            :disabled="weighingMode===2"
            @blur="checkNumber"
            @keyup.enter.native="addTable"
          /> kg
          <!-- 请填写最大重量不能超过500kg的数字，可保留两位有效小数 -->
          <div v-show="!isSuc" class="warehousingKGTitle">{{ $t('operatingPlatform.inputNumberHint') }}</div>
          <div v-show="isSuc" class="warehousingKGTitle" />
        </div>
        <div class="scanClass">
          <!-- 长*宽*高 (cm) -->
          <span style="display: block;">{{ $t('operatingPlatform.Comprimento') }}</span>
          <el-input
            v-model="long"
            :placeholder="$t('operatingPlatform.long')"
            class="volume"
            clearable
            @blur="checkVolume"
            @keyup.enter.native="addTable"
          />x
          <el-input
            v-model="width"
            :placeholder="$t('operatingPlatform.width')"
            class="volume"
            clearable
            @blur="checkVolume"
            @keyup.enter.native="addTable"
          />x
          <el-input
            v-model="height"
            :placeholder="$t('operatingPlatform.height')"
            class="volume"
            clearable
            @blur="checkVolume"
            @keyup.enter.native="addTable"
          />
          <div v-show="!isLong || !isWidth || !isHeight" class="warehousingKGTitle">{{ $t('collectionCenter.enterNumber3significantDecimals') }}</div>
        </div>
        <!-- <el-button
          class="onlyClickOnce"
          icon="el-icon-upload2"
          @click="upload"
        >{{ $t('operatingPlatform.Upload') }}</el-button>上传 -->
        <el-button
          class="onlyClickOnce"
          icon="el-icon-upload2"
          @click="bagSealing"
        >{{ $t('collectionCenter.SealingBags') }}</el-button><!-- 封袋 -->
      </div>

      <div class="tableClass">
        <el-table
          :max-height="600"
          :data="scanTableData"
          border
        >
          <el-table-column
            prop="scanWaybillNumber"
            align="center"
            :label="$t('operatingPlatform.waybillNumber')"
          /><!-- 运单号 -->
          <el-table-column
            prop="length"
            align="center"
            :label="$t('operatingPlatform.long')"
          /><!-- 长 -->
          <el-table-column
            prop="width"
            align="center"
            :label="$t('operatingPlatform.width')"
          /><!-- 宽 -->
          <el-table-column
            prop="height"
            align="center"
            :label="$t('operatingPlatform.height')"
          /><!-- 高 -->
          <el-table-column
            prop="weight"
            align="center"
            :label="$t('operatingPlatform.scanWeight')"
          /><!-- 重量 -->
          <el-table-column
            prop="volumeWeight"
            align="center"
            :label="$t('operatingPlatform.volumeWeight')"
          /><!-- 体积重 -->
          <el-table-column
            prop="scanTime"
            align="center"
            :label="$t('operatingPlatform.scanTime')"
          /><!-- 扫描时间 -->
          <el-table-column
            prop="operator"
            align="center"
            :label="$t('operatingPlatform.operator')"
          /><!-- 操作人 -->

          <el-table-column
            align="center"
            :label="$t('collectionCenter.operation')"
            width="100"
          ><!-- 删除 -->
            <template slot-scope="scope">
              <div style="cursor: pointer">
                <el-popconfirm
                  :title="$t('collectionCenter.deleteSure')"
                  @confirm="delRow(scope.row,scope.$index)"
                ><!-- 确定删除吗？ -->
                  <div slot="reference" style="color: #da0014;font-size: 15px">{{ $t('collectionCenter.Delete') }}</div><!-- 删除 -->
                </el-popconfirm>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div>
      <el-dialog
        :visible.sync="dialogVisible"
        width="50%"
        @close="handleClose"
      >
        <div class="bagBox">
          {{ $t("collectionCenter.bagsNumber") }}
          <el-input
            v-model.trim="bagNumber"
            :placeholder="$t('operatingPlatform.inputNumbers')"
            minlength="6"
            maxlength="8"
            show-word-limit
            clearable
          />
          <el-checkbox v-model="checked" style="margin-left: 10px;" @change="choose">{{ $t('receivablePayable.SystemGenerated') }}</el-checkbox>

        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">{{ $t('basicData.Cancel') }}</el-button>
          <el-button type="primary" @click="submitBagSealing">{{ $t('basicData.determine') }}</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 隐藏提示音 -->
    <div v-show="false">
      <audio ref="errRef" :src="errorMp3" controls="controls" />
      <audio ref="okRef" :src="okMp3" controls="controls" />
    </div>
  </div>
</template>

<script>
const okMp3 = '/static/scan_success.mp3';
const errorMp3 = '/static/scan_fail.mp3';
import {
  pcSiteCollectWarehouseSubmit,
  deleteScanRecord,
  pcSiteCollectWarehouseScan,
  pcSiteCollectInStoreBagSeal,
  generatingBagNum,
  printLabel
} from '@/api/operatingPlatform';
import { apiGetWeight, apiStartPortsList, apiOPenComPort, apiStartUp } from '@/api/serialPort';
import { Loading } from 'element-ui';
import { checkSumber } from '@/utils/validate';
import { cloneDeep } from 'lodash-es';
import dayjs from 'dayjs';

export default {
  name: 'CollectWarehous',
  data() {
    return {
      barCodeImg: '',
      dot: this.$store.state.user.dotName,
      numVisible: true, // 袋号条形码截图
      checked: false,
      okMp3,
      errorMp3,
      submitForm: [],
      bagNumber: '',
      dialogVisible: false,
      isLong: true,
      isWidth: true,
      isHeight: true,
      scanList: {}, // 缓存用
      long: '', // 长宽高
      width: '',
      height: '',
      scanTableData: [],
      warehousingScan: '',
      warehousingKG: 0,
      isSuc: true, // 用户输入是否正确
      equipmentBrand: '', // 称重品牌 TSC-L| PRIX-3FIT
      weighingMode: 1, // 称重方式 1：其它 2：串口 3:快手
      SetDevice: [], // 串口列表
      connectStatus: 0, // 0：未连接 1：已连接
      timer: null, // 循环获取电子秤重量定时器
      // ws
      wsObj: {
        ws: null, // WebSocket
        timeVal: 30 * 1000, // 心跳检测间隔事件
        index: 0, // 重试次数
        time: 0
      },
      scanCustomerList: {}, // 缓存用扫描客户id列表

    };
  },
  watch: {
    weighingMode(val) {
      if (val === 1) {
        this.isSuc = true;
      }
    },
    warehousingKG(value) {
      const warehousingKG = Number(value);
      const reg = new RegExp(/^[0-9]+\.?[0-9]{0,3}$/);
      if (!reg.test(warehousingKG)) {
        // this.$message.warning('请填写数字，可保留两位有效小数');
        this.isSuc = false;
      } else if (warehousingKG < 0 || warehousingKG > 500) {
        // this.$message.warning('最大重量不能超过30kg');
        this.isSuc = false;
      } else {
        this.isSuc = true;
      }
    },
    long(val) {
      const n1 = this.sumberLen(val);
      if (!checkSumber(val) && val || !n1) {
        this.isLong = false;
      } else {
        this.isLong = true;
      }
    },
    width(val) {
      const n2 = this.sumberLen(val);
      if (!checkSumber(val) && val || !n2) {
        this.isWidth = false;
      } else {
        this.isWidth = true;
      }
    },
    height(val) {
      const n3 = this.sumberLen(val);
      if (!checkSumber(val) && val || !n3) {
        this.isHeight = false;
      } else {
        this.isHeight = true;
      }
    }
  },
  created() {
    if (localStorage.getItem('collectWarehousingData')) {
      this.scanList = JSON.parse(localStorage.getItem('collectWarehousingData')) || {};
      this.scanCustomerList = JSON.parse(localStorage.getItem('collectWarehousingCustomerIdData')) || {};
      const who = 'cw' + this.$store.state.user.id;
      if (this.scanList[who]) {
        this.scanTableData = this.scanList[who].scanTableData;
      } else {
        this.scanTableData = [];
      }
      // 如果本地存储中没有当前用户扫描存储的客户id，使用列表第一次扫描的存储的客户id
      if (!this.scanCustomerList[who]) {
        if (this.scanTableData.length > 0) {
          this.scanCustomerList[who] = {
            customerId: this.scanTableData[this.scanTableData.length - 1].customerId || ''
          };
          localStorage.setItem('collectWarehousingCustomerIdData', JSON.stringify(this.scanCustomerList));
        }
      }
    }
  },
  beforeDestroy() {
    // 页面销毁前清除定时器
    clearInterval(this.timer);
  },
  methods: {
    sumberLen(val) {
      const value = val.split('.');
      if (value[1]) {
        if (value[0].length > 10 || (value[0].length + value[1].length) > 10) {
          return false;
        } else {
          return true;
        }
      } else {
        if (value[0].length > 10) {
          return false;
        } else {
          return true;
        }
      }
    },
    checkVolume() {
      if (this.long && this.isLong) {
        this.long = Number(this.long);
      }
      if (this.width && this.isWidth) {
        this.width = Number(this.width);
      }
      if (this.height && this.isHeight) {
        this.height = Number(this.height);
      }
    },
    radioChange() {
      this.warehousingScan = '';
      this.warehousingKG = '';
      if (this.weighingMode === 3) {
        // 连接快手设备
        // 进行load
        const load = Loading.service({
          fullscreen: true,
          lock: true,
          spinner: 'el-icon-loading',
          text: this.$t('operatingPlatform.plugInBeingConnected'), // 正在连接插件
          background: 'rgba(250, 250, 250, 0.6)'
        });
        apiStartUp().then((res) => {
          if (res.code !== 200) {
            this.$message.error(this.$t('operatingPlatform.pluginIsNotStarted')); // 插件未启动，请打开插件
            // 关闭load
            load.close();
            // 称重设备评判清空
            this.equipmentBrand = '';
            return;
          }
          // 关闭load
          load.close();
          // 连接插件快手设备
          this.creatWs();
        }).catch(() => {
          // 关闭load
          load.close();
        });
      } else {
        this.wsMsgClose();
        // 删除快手设备心跳
        clearInterval(this.onlineTime);
      }
    },
    checkNumber() {
      const reg = new RegExp(/^[0-9]+\.?[0-9]{0,3}$/);
      if (reg.test(this.warehousingKG)) {
        this.warehousingKG = Number(this.warehousingKG);
      }
    },
    delRow(row, index) {
      this.scanTableData.splice(index, 1);
      if (this.scanTableData.length <= 0 || row.scanWaybillNumber == this.warehousingScan) {
        this.warehousingScan = '';
      }
      this.scanList['cw' + this.$store.state.user.id] = {
        scanTableData: this.scanTableData
      };
      // 所有数据都删除了，清空customerId
      if (this.scanTableData && !this.scanTableData.length) {
        this.scanCustomerList['cw' + this.$store.state.user.id] = {
          customerId: ''
        };
      }
      localStorage.setItem('collectWarehousingCustomerIdData', JSON.stringify(this.scanCustomerList));
      localStorage.setItem('collectWarehousingData', JSON.stringify(this.scanList));
    },
    addTable() {
      if (!this.long || !this.width || !this.height) {
        this.$message({
          message: this.$t('collectionCenter.inputLWH'),
          type: 'warning'
        });
        this.$refs.errRef.play();
        return;
      }
      if (!this.isLong || !this.isWidth || !this.isHeight || !this.isSuc || !this.long || !this.width || !this.height) return;
      if ((this.long && !this.isLong) || (this.width && !this.isWidth) || (this.height && !this.isHeight)) return;
      if (this.warehousingScan) {
        // 查询有无重复值
        const isAdd = this.scanTableData.some(el => el.scanWaybillNumber === this.warehousingScan);
        if (isAdd && this.scanTableData.length > 0) {
          this.$message({
            message: this.$t('operatingPlatform.waybillNumRepeat'), // '当前运单号重复'
            type: 'warning'
          });
          this.$refs.errRef.play();
          return;
        } else if (this.weighingMode !== 1 && (this.warehousingKG === 0 || !this.warehousingKG || !this.isSuc)) {
          this.isSuc = false;
          this.$message({
            message: this.$t('operatingPlatform.pleaseInputRightWeight'), // '请输入正确的重量'
            type: 'warning'
          });
          this.$refs.errRef.play();
          return;
        } else {
          this.checkNumber();
          const scanNumber = {
            scanWaybillNumber: this.warehousingScan,
            operationType: 6,
            length: this.long,
            width: this.width,
            height: this.height
          };
          // 如果存储了客户id，那么将客户id传递过去
          if (this.scanCustomerList['cw' + this.$store.state.user.id] && this.scanCustomerList['cw' + this.$store.state.user.id].customerId) {
            scanNumber.customerId = this.scanCustomerList['cw' + this.$store.state.user.id].customerId;
          }
          pcSiteCollectWarehouseScan(scanNumber).then(res => {
            if (res.status === 'OK') {
              this.$refs.okRef.play();
              const scanTime = new Date();
              this.submitForm = [{
                waybillId: res.data.waybillId,
                scanWaybillNumber: this.warehousingScan,
                collectOrderId: res.data.collectOrderId,
                volumeWeight: res.data.volumeWeight,
                weight: this.warehousingKG,
                scanTime: dayjs(scanTime).format('HH:mm:ss YYYY-MM-DD'),
                operator: this.$store.getters.realName,
                orderSourceType: res.data.orderSourceType,
                length: res.data.length,
                height: res.data.height,
                width: res.data.width,
                customerId: res.data.customerId
              }];
              this.scanTableData.unshift({
                waybillId: res.data.waybillId,
                scanWaybillNumber: this.warehousingScan,
                collectOrderId: res.data.collectOrderId,
                volumeWeight: res.data.volumeWeight,
                weight: this.warehousingKG,
                scanTime: dayjs(scanTime).format('HH:mm:ss YYYY-MM-DD'),
                scanRecordId: res.data.scanRecordId,
                operator: this.$store.getters.realName,
                length: res.data.length,
                height: res.data.height,
                width: res.data.width,
                orderSourceType: res.data.orderSourceType,
                customerId: res.data.customerId
              });

              // 如果是首次扫描，存储customerId
              this.scanCustomerList['cw' + this.$store.state.user.id] = {
                customerId: res.data.customerId
              };
              localStorage.setItem('collectWarehousingCustomerIdData', JSON.stringify(this.scanCustomerList));
              this.scanList['cw' + this.$store.state.user.id] = {
                scanTableData: this.scanTableData
              };
              localStorage.setItem('collectWarehousingData', JSON.stringify(this.scanList));
              this.long = '';
              this.width = '';
              this.height = '';
              this.warehousingKG = '';
              this.$nextTick(() => {
                this.isLong = true;
                this.isWidth = true;
                this.isHeight = true;
              });
              // 自动上传
              this.upload(1);
              this.warehousingScan = '';
            } else {
              this.warehousingScan = '';
              this.$refs.errRef.play();
              this.$message.error(res.msg);
              this.long = '';
              this.width = '';
              this.height = '';
              this.warehousingKG = '';
              this.$nextTick(() => {
                this.isLong = true;
                this.isWidth = true;
                this.isHeight = true;
              });
            }
          }).catch(() => {
            this.warehousingScan = '';
            console.log('错误处理');
            this.$refs.errRef.play();
            this.long = '';
            this.width = '';
            this.height = '';
            this.warehousingKG = '';
            this.$nextTick(() => {
              this.isLong = true;
              this.isWidth = true;
              this.isHeight = true;
            });
          });
        }
      }
    },
    scanDelClick(row, index) { // 扫件——删除
      const delForm = {
        id: row.scanRecordId
      };
      deleteScanRecord(delForm).then(res => {
        if (res.status === 'OK') {
          this.scanTableData.splice(index, 1);
          this.scanList['cw' + this.$store.state.user.id] = {
            scanTableData: this.scanTableData
          };
          if (this.scanTableData && !this.scanTableData.length) {
            this.scanCustomerList['cw' + this.$store.state.user.id] = {
              customerId: ''
            };
          }
          localStorage.setItem('collectWarehousingCustomerIdData', JSON.stringify(this.scanCustomerList));
          localStorage.setItem('collectWarehousingData', JSON.stringify(this.scanList));
        }
      }).catch({});
    },
    /**
     * @description 上传
     * @param type 是否自动上传 0：否 1：是
     */
    upload(type = 0) {
      if (this.warehousingScan) {
        this.$message.warning(this.$t('operatingPlatform.pleaseCheckContent')); // 请检查扫描框是否有内容
      } else {
        const submitForm = cloneDeep(this.submitForm);
        if (type === 1) {
          const cwForm = {
            // weight: this.warehousingKG,
            warbillList: submitForm
          };
          pcSiteCollectWarehouseSubmit(cwForm).then(res => {
            if (res.status === 'OK') {
              this.outOfWarehouseScan = '';
              this.warehousingKG = 0;
              this.long = '';
              this.width = '';
              this.height = '';
              this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded')); // 操作成功
            } else {
              this.$message.error(res.msg);
            }
          }).catch({});
          return;
        }
        //                    '是否确认上传?'                        '提示'
        this.$confirm(this.$t('operatingPlatform.AreYouSureSubmit'), this.$t('operatingPlatform.tips'), {
          confirmButtonText: this.$t('operatingPlatform.confirm'), // '确定'
          cancelButtonText: this.$t('operatingPlatform.cancel') // '取消'
        }).then(() => {
          const cwForm = {
            // weight: this.warehousingKG,
            warbillList: submitForm
          };
          pcSiteCollectWarehouseSubmit(cwForm).then(res => {
            if (res.status === 'OK') {
              this.outOfWarehouseScan = '';
              this.warehousingKG = 0;
              this.long = '';
              this.width = '';
              this.height = '';
              // this.scanTableData = [];
              // localStorage.setItem('collectWarehousingData', '');
              this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded')); // 操作成功
            } else {
              this.$message.error(res.msg);
            }
          }).catch({});
        }).catch(() => {
          //
        });
      }
    },

    handleClose() {
      this.dialogVisible = false;
      this.bagNumber = '';
    },
    bagSealing() {
      if (this.scanTableData.length <= 0) {
        this.$message.warning(this.$t('operatingPlatform.nullSubmit'));
        return;
      }
      this.checked = false;
      this.dialogVisible = true;
    },
    choose(val) {
      if (!val) return;
      const data = {};
      generatingBagNum(data).then((ress) => {
        if (ress.status === 'OK') {
          this.bagNumber = ress.data.number;
          setTimeout(() => {
            /**
             * 提交
             */
            const collectOrderList = this.scanTableData.map(arr => {
              return {
                scanWaybillNumber: arr.scanWaybillNumber,
                orderSourceType: arr.orderSourceType
              };
            });
            const bagForm = {
              bagNumber: this.bagNumber,
              collectOrderList
            };
            pcSiteCollectInStoreBagSeal(bagForm).then(res => {
              if (res.status === 'OK') {
                this.outOfWarehouseScan = '';
                this.warehousingKG = 0;
                this.long = '';
                this.width = '';
                this.height = '';
                this.scanTableData = [];
                localStorage.setItem('collectWarehousingData', '');
                this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded')); // 操作成功
                this.dialogVisible = false;
                /**
                 * 跳转到打印页面
                 */
                setTimeout(() => {
                  this.print(ress.data.number);
                }, 200);
              }
            });
          }, 1000);
        }
      }).catch(() => {
        this.bagNumber = '';
      });
    },
    print(data) {
      const printForm = {
        number: data.split(',')
      };
      printLabel(printForm).then(res => {
        if (res.status === 'OK') {
          window.open(res.data);
        }
      });
    },
    submitBagSealing() {
      const reg = /^[0-9]{6,8}$/;
      if (!reg.test(this.bagNumber)) {
        this.$message.error(this.$t('operatingPlatform.inputNumbers'));
        this.bagNumber = '';
        return;
      }
      const collectOrderList = this.scanTableData.map(arr => {
        return {
          scanWaybillNumber: arr.scanWaybillNumber,
          orderSourceType: arr.orderSourceType
        };
      });
      const bagForm = {
        bagNumber: this.bagNumber,
        collectOrderList
      };
      pcSiteCollectInStoreBagSeal(bagForm).then(res => {
        if (res.status === 'OK') {
          this.warehousingScan = '';
          this.outOfWarehouseScan = '';
          this.warehousingKG = 0;
          this.long = '';
          this.width = '';
          this.height = '';
          this.scanTableData = [];
          delete this.scanList['cw' + this.$store.state.user.id];
          delete this.scanCustomerList['cw' + this.$store.state.user.id];
          localStorage.setItem('collectWarehousingData', JSON.stringify(this.scanList));
          localStorage.setItem('collectWarehousingCustomerIdData', JSON.stringify(this.scanCustomerList));
          this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded')); // 操作成功
          this.dialogVisible = false;
        }
      });
    },

    /**
     * @description: 检测插件是否启动
     * @return {*}
     */
    DeviceSetup() {
      // 选择串口时进行插件开启校验
      if (this.weighingMode === 2) {
        // 进行load
        const load = Loading.service({
          fullscreen: true,
          lock: true,
          spinner: 'el-icon-loading',
          text: this.$t('operatingPlatform.plugInBeingConnected'), // 正在连接插件
          background: 'rgba(250, 250, 250, 0.6)'
        });
        apiStartUp().then((res) => {
          if (res.code !== 200) {
            this.$message.error(this.$t('operatingPlatform.pluginIsNotStarted')); // 插件未启动，请打开插件
            // 关闭load
            load.close();
            // 称重设备评判清空
            this.equipmentBrand = '';
            return;
          }
          // 获取串口列表
          this.getSerialPortList();
          // 关闭load
          load.close();
        }).catch(() => {
          // 关闭load
          load.close();
        });
      } else {
        // 选择其它时关闭电子称重量获取
        this.closeGetWeight();
        this.equipmentBrand = '';
      }
    },
    /**
     * @description: 获取串口列表
     * @return {*}
     */
    getSerialPortList() {
      apiStartPortsList().then((res) => {
        if (res.code === 200) {
          this.SetDevice = res.data;
        }
      });
    },
    setupElectronicScales() {
      // 是否初始化成功电子秤
      apiStartUp().then((res) => {
        if (res.code !== 200) {
          this.$message.error(this.$t('operatingPlatform.pluginIsNotStarted')); // 插件未启动，请打开插件
          this.equipmentBrand = '';
          return;
        }
        this.connectDevice();
      });
    },
    /**
     * @description: 连接设备
     * @return {*}
     */
    async connectDevice() {
      const load = Loading.service({
        fullscreen: true,
        lock: true,
        spinner: 'el-icon-loading',
        text: this.$t('operatingPlatform.DeviceInitializationInProgress') + '...',
        background: 'rgba(250, 250, 250, 0.6)'
      });
      // 是否连接
      let isConnected = false;
      for (let i = 0; i < this.SetDevice.length; i++) {
        // 已经连接成功不再继续连接
        if (isConnected) {
          return;
        }
        await this.initElectronicScales(this.SetDevice[i])
          .then((res) => {
            // 该串口连接成功
            isConnected = !!res;
          });
      }
      if (isConnected) {
        this.$message({
          message: this.$t('operatingPlatform.DeviceInitializationSuccessful'),
          type: 'success'
        });
        this.connectStatus = 1;
        this.$nextTick(() => {
          this.$refs.inputRef.$refs.input.focus();
        });
        // 循环获取电子秤重量
        this.getWeight();
        load.close();
      } else {
        load.close();
        this.$message.error(this.$t('operatingPlatform.ElectronicScaleInitializationFailed'));
        this.equipmentBrand = '';
        this.connectStatus = 0;
      }
    },
    // /**
    //  * @description: 获取串口列表
    //  * @return {*}
    //  */
    // getSerialPortList() {
    //   apiStartPortsList().then((res) => {
    //     if (res.code === 200) {
    //       this.SetDevice = res.data;
    //     }
    //   });
    // },
    /**
     * @description: 初始化电子秤
     * @param { String } device 串口（电脑通道）
     * @return {*}
     */
    initElectronicScales(device) {
      return new Promise((resolve, reject) => {
        // 请求接口选中指定品牌和串口进行读数
        apiOPenComPort({
          comPort: device,
          scaleType: this.equipmentBrand
        })
          .then((res) => {
            // 连接失败直接跳出
            if (res.code === 500) {
              reject();
            } else {
              // 连接成功并且获取电子秤读书三次成功后跳出
              const getArr = [apiGetWeight(), apiGetWeight(), apiGetWeight()];
              Promise.all(getArr).then((res) => {
                console.log(res);
                // 0：连接失败 1：连接成功
                let connected = 0;
                // 循环判断是否获取到电子秤读数进行处理
                res.forEach((item) => {
                  if (item.code === 200) {
                    connected = 1;
                  } else {
                    connected = 0;
                  }
                });
                // 对连接成功与连接失败进行处理
                console.log('connected', connected);
                resolve(connected);
              });
            }
            // eslint-disable-next-line handle-callback-err
          }).catch((err) => {
          // 直接跳出
            reject();
          });
      });
    },
    /**
     * @description: 循环获取重量
     * @return {*}
     */
    getWeight() {
      this.timer = setInterval(() => {
        apiGetWeight()
          .then((res) => {
            if (res.code === 200) {
              this.warehousingKG = res.data;
            } else {
              clearInterval(this.timer);
            }
          });
      }, 1000);
    },
    /**
     * @description: 停止获取电子秤重量
     * @return {*}
     */
    closeGetWeight() {
      clearInterval(this.timer);
    },
    // WebSocket 连接
    creatWs() {
      // ws连接
      this.wsObj.ws = new WebSocket('ws://127.0.0.1:3000/v1/wms/express/collect/storage/register');

      if (this.wsObj.ws instanceof WebSocket) {
        this.wsObj.ws.addEventListener('open', this.handleWsOpend);
        this.wsObj.ws.addEventListener('message', this.handleWsMessage);
        this.wsObj.ws.addEventListener('close', this.hanldeWsClose);
        this.wsObj.ws.addEventListener('error', this.handleWsError);
      }
      // 关闭设置设备弹窗
      this.SetDeviceDialog = false;
    },
    // 首次连接ws
    handleWsOpend() {
      console.log('操作中心 连接成功');
      // 注册用户
      this.setWsRegisteredUser();
    },
    // 注册用户
    setWsRegisteredUser() {
      const { ws } = this.wsObj;
      const data = {
        token: this.$store.state.user.token,
        language: this.$store.state.language.language
      };
      console.log(data);
      ws.send(JSON.stringify(data));
    },
    // 收到消息
    handleWsMessage(e) {
      console.log('收到消息', e);
      // console.log(e);
      const res = JSON.parse(e.data);
      console.log(res, '收到的消息');
      // 首次连接
      if (res.status === 'OK') {
        this.wsMsgSuccess(res);
        return;
      }
      // 失败处理
      if (!res.code) {
        this.wsMsgErr(res);
        return;
      }
      // 成功处理
      this.wsMsgSuccess(res);
    },
    // 失败处理
    wsMsgErr(res) {
      this.$message.error(res.msg);
    },
    // 成功处理
    wsMsgSuccess(res) {
      console.log('收到消息，成功');
      // msg会在第一连接成功时返回，这里判断msg给一个提示
      const { code, height, length, weight, width, msg } = res;
      if (msg) {
        this.$message.success(msg);
        // 进行心跳连接
        this.setWsHeartbeat();
        return;
      }
      console.log(code, height, length, weight, width);
      this.warehousingScan = code;
      this.warehousingKG = weight;
      this.long = length;
      this.width = width;
      this.height = height;
      // 这里是成功获取到数据的处理
      // this.$message.success('这里是成功获取到参数的提示');
      this.addTable();
    },
    // ws连接出错
    handleWsError() {
      console.log('连接出错');
      this.wsObj.index = 0;
      clearTimeout(this.wsObj.time);
      const str = this.$i18n.t('operationTips.connectionError');
      this.$message.error(str);
    },
    // 进行心跳连接
    setWsHeartbeat() {
      // 删除电子秤获取重量电子秤
      clearInterval(this.timer);
      // 删除快手设备心跳
      clearInterval(this.onlineTime);
      const { ws } = this.wsObj;
      const data = {
        path: '/v1/wms/express/storage/register',
        params: {}
      };
      console.log(data, JSON.stringify(data), '心跳请求');

      ws.send(JSON.stringify(data));
      this.onlineTime = setInterval(() => {
        ws.send(JSON.stringify(data));
      }, 3000);
    },
    /**
     * @description 关闭ws
     */
    wsMsgClose() {
      const { ws } = this.wsObj;
      ws.close();
    }
  }
};
</script>

<style lang="scss" scoped>
.bagBox{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.bagBox ::v-deep .el-checkbox__label{
  padding-left: 0 !important;
}
.changeClass{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.scanRecords ::v-deep .el-input-group__append{
  background-color: #fff;
  color: #4D4D4D;
  span{
    color: #F56C6C;
    font-size: 19px;
    margin: 0 5px;
  }
}
.volume{
  width: 150px !important;
  margin: 0 5px;
}
// 防止按钮重复点击
@keyframes throttle{
  from{
    filter: opacity(40%);
    pointer-events: none;
  }
  to{
    pointer-events: all;
  }
}
.changeClass ::v-deep .el-button{
  background: #42b983;
  color: #fff;
}
.onlyClickOnce{
  margin-top: 25px;
  animation: throttle 2s step-end forwards;
}
.onlyClickOnce:active{
  filter: opacity(40%);
  animation: none;
}
.tableClass{
  margin-top: 10px;
}
.warehousingBox{
  padding: 20px;
  box-sizing: border-box;
}
.warehousingClass{
  .scanClass{
    margin-right: 40px;
  }
  .iconBtn::after{
    position: absolute;
    content: "-";
    top: 10px;
    left: 15px;
    transform: scaleX(2.3);
    color: #bbb;
  }
  .warehousingKGTitle{
    height: 20px;
    animation: show 0.5s;
    color: #F56C6C;
    font-size: 13px;
    margin-top: 5px;
  }
  @keyframes show{
    0%{opacity:0}
    100%{opacity:1}
  }
}
::v-deep .el-input{
  width: 350px;
}
::v-deep .el-input.warehousingKGClass{
  width: 100px !important;
}
.warehousingKGClass ::v-deep .el-input__inner{
  text-align: center;
}
</style>
