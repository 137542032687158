var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "o-tabs",
        {
          attrs: { multiline: false },
          model: {
            value: _vm.sellerType,
            callback: function ($$v) {
              _vm.sellerType = $$v
            },
            expression: "sellerType",
          },
        },
        [
          _c(
            "o-tab-item",
            {
              staticClass: "wrapper-tab-item",
              attrs: { value: 0, label: _vm.$t("routes.listSeller") },
            },
            [
              _c("ListSellers", {
                attrs: { sellers: _vm.sellers },
                on: { updateSeller: _vm.onUpdateSellers },
              }),
            ],
            1
          ),
          _c(
            "o-tab-item",
            {
              staticClass: "wrapper-tab-item",
              attrs: { value: 1, label: _vm.$t("routes.addSeller") },
            },
            [
              _c("AvailableSellers", {
                attrs: { sellers: _vm.sellers },
                on: { updateSeller: _vm.onUpdateSellers },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "wrapper-buttons" },
        [
          _c(
            "o-button",
            {
              attrs: { variant: "primary", size: "md" },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("routes.sendButton")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }