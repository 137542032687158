var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", on: { mousedown: _vm.hideModal } },
    [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c("div", { staticClass: "cardTitle" }, [
            _c("h2", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
            _c("button", { staticClass: "closeButton" }, [_vm._v("X")]),
          ]),
          _vm._t("default"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }