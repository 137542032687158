<template> <!-- 收件人信息 -->

  <div>
    <!-- 查看 -->
    <ReceiveReadOnlyCmp v-if="disabled" :rule-form="ruleForm" />

    <!-- 编辑 -->
    <el-form
      v-else
      ref="ruleForm" :model="ruleForm" :rules="rules"
      class="demo-ruleForm" label-position="top"
    >
      <el-row :gutter="20">
        <el-col :span="8">
          <!-- 姓名 -->
          <el-form-item :label="$t('newOrder.RecipientName')" :rules="rules.receiveName" prop="receiveName">
            <el-input v-if="!disabled" v-model="ruleForm.receiveName" :placeholder="$t('GlobalSetObj.pleaseInput')" />
            <span v-else>{{ ruleForm.receiveName }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 公司 -->
          <el-form-item :label="$t('newOrder.RecipientCompany')">
            <el-input v-model="ruleForm.receiveCompany" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 手机 -->
          <el-form-item :label="$t('newOrder.Recipientphone')" :rules="rules.receiveName" prop="receiveMobile">
            <el-input v-model="ruleForm.receiveMobile" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 街道 -->
          <el-form-item :label="$t('driver.modalStreet')">
            <el-input v-model="ruleForm.receiveStreet" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 门牌号 -->
          <el-form-item :label="$t('newOrder.doorNumber')">
            <el-input v-model="ruleForm.receiveHouseNumber" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 区 -->
          <el-form-item :label="$t('newOrder.RecipientDistrict')">
            <el-input v-model="ruleForm.receiveArea" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 城市 -->
          <el-form-item :label="$t('newOrder.RecipientCity')" :rules="rules.receiveName" prop="receiveCity">
            <el-input v-model="ruleForm.receiveCity" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 州 -->
          <el-form-item :label="$t('newOrder.RecipientState')" :rules="rules.receiveName" prop="receiveState">
            <el-input v-model="ruleForm.receiveState" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 邮编 -->
          <el-form-item :label="$t('newOrder.RecipientZipCode')" :rules="rules.receiveName" prop="receiveZipcode">
            <el-input v-model="ruleForm.receiveZipcode" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 地址补充 -->
          <el-form-item :label="$t('GlobalSetObj.recipientComplement')">
            <el-input
              v-model="ruleForm.receiveComplement" :autosize="{ minRows: 2, maxRows: 4}"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              type="textarea"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 税号 -->
          <el-form-item :label="$t('newOrder.RecipientCPF')" :rules="rules.receiveName" prop="receiveTax">
            <el-input v-model="ruleForm.receiveTax" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 州注册号 -->
          <el-form-item :label="$t('navbar.receiveIe')" :rules="rules.receiveName">
            <el-input v-model="ruleForm.receiveIeNumber" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <!-- 地址 -->
          <el-form-item :label="$t('newOrder.ReceivingAddress')" :rules="rules.receiveName" prop="receiveAddress">
            <el-input
              v-model="ruleForm.receiveAddress" :autosize="{ minRows: 2, maxRows: 4}"
              :placeholder="$t('GlobalSetObj.pleaseInput')" type="textarea"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 电话 -->
          <el-form-item :label="$t('newOrder.Recipientphonenumber')">
            <el-input v-model="ruleForm.receivePhone" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 邮箱 -->
          <el-form-item :label="$t('newOrder.RecipientEmail')">
            <el-input v-model="ruleForm.receiveMail" :placeholder="$t('GlobalSetObj.pleaseInput')" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import ReceiveReadOnlyCmp from './ReceiveReadOnlyCmp.vue';

export default {
  name: '',

  components: {
    ReceiveReadOnlyCmp
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      ruleForm: {
        receiveName: '', // 收件人姓名
        receiveCompany: '', // 收件人公司
        receiveMobile: '', // 收件人手机
        receiveStreet: '', // 收件人街道
        receiveHouseNumber: '', // 收件人门牌号
        receiveArea: '', // 收件人区
        receiveCity: '', // 收件人城市
        receiveState: '', // 收件人州
        receiveZipcode: '', // 收件人邮编
        receiveComplement: '', // 地址补充
        receiveTax: '', // 收件人税号
        receiveIeNumber: '', // 州注册号
        receiveAddress: '', // 收件人地址
        receivePhone: '', // 收件人电话
        receiveMail: '' // 收件人邮箱
      },

      rules: {
        receiveName: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'blur' }
        ]
      }
    };
  },

  created() {

  },

  methods: {
    getData() {
      return this.ruleForm;
    },

    setData(row) {
      // const { receiveName, receiveCompany, receiveMobile, receiveStreet, receiveHouseNumber, receiveArea, receiveCity, receiveState, receiveZipcode, receiveComplement, receiveTax, receiveIeNumber, receiveAddress, receivePhone, receiveMail } = row;

      Object.assign(this.ruleForm, { ...row });
      // Object.assign(this.ruleForm, {
      //   receiveName, receiveCompany, receiveMobile, receiveStreet, receiveHouseNumber, receiveArea, receiveCity, receiveState, receiveZipcode, receiveComplement, receiveTax, receiveIeNumber, receiveAddress, receivePhone, receiveMail
      // });
    },

    // 提交
    submit() {
      let isBool = false;
      this.$refs['ruleForm'].validate((valid) => {
        if (!valid) {
          // alert('submit!');

        } else {
          isBool = true;
          return false;
        }
      });

      return isBool;
    }

  }

};
</script>

<style lang="scss" scoped>
</style>
