import i18n from '@/lang';

// 丢失类型
export const LOST_TYPE = Object.freeze({
  1: {
    label: i18n.t('collectionCenter.Confirm') + i18n.t('operationTips.Lost'), // 确认丢失
    value: 1
  },
  2: {
    label: i18n.t('operationTips.yisiLost'), // 疑似丢失
    value: 2
  }
});
