import request from '@/utils/request';

/**
 * @description 获取车辆管理列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getVehiclePage = (data) => {
  return request.post('/vehicle/page', data);
};

/**
 * @description 新增车辆
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const vehicleAddApi = (data) => {
  return request.post('/vehicle/add', data);
};

/**
 * @description 新增保养/维修
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const vehicleServiceAdd = (data) => {
  return request.post('/vehicle/service/add', data);
};

/**
 * @description 维修保养记录
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const vehicleServicePage = (data) => {
  return request.post('vehicle/service/page', data);
};

/**
 * @description 盘点车辆
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const vehicleUpdate = (data) => {
  return request.post('/vehicle/update', data);
};

/**
 * @description 盘点记录
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const vehicleMaintenancePage = (data) => {
  return request.post('/vehicle/maintenance/page', data);
};
