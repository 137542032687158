<script >
import { defineEmits } from '@vue/composition-api';

// const props = defineProps({
//   active: {
//     type: Boolean
//   }
// });
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  return {
    emit
  };
};
export default __sfc_main;
</script>

<template>
  <o-modal
    :active="active" width="500" class="o-container"
    @close="emit('handleClose')"
  >
    <div>
      <h2 class="header-2">
        <slot name="title" />
      </h2>

      <p class="paragraph space-paragraph">
        <slot name="message" />
      </p>
    </div>

    <div class="wapper-buttons">
      <slot name="buttons" />
    </div>
  </o-modal>
</template>

<style scoped>
.wapper-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1.25rem/* 20px */;
}

.space-paragraph {
  margin-top: 0.5rem/* 8px */;
  margin-bottom: 0.5rem/* 8px */;
}
</style>
