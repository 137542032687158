<template>
  <div style="padding: 10px;">
    <el-tabs type="border-card">
      <!--运单详情-->
      <el-tab-pane :label="$t('GlobalSetObj.details')">
        <detailBox :query-track-param="queryTrackParam" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import detailBox from './detailBox/index';

export default {
  name: 'MinutaInfo',
  components: {
    detailBox
  },
  props: {
    queryTrackParam: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {};
  }
};
</script>
