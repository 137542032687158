var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DropdownTable",
        [
          _c(
            "o-dropdown-item",
            {
              attrs: { "aria-role": "listitem" },
              on: { click: _vm.handleClearSteps },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.props.filters.status == _vm.FiltersEnum.ACTIVE ||
                      _vm.props.filters.status == _vm.FiltersEnum.FINISHED
                      ? "Visualizar rota"
                      : "Atualizar carga"
                  ) +
                  " "
              ),
            ]
          ),
          _vm.props.filters.status == _vm.FiltersEnum.ACTIVE
            ? _c(
                "o-dropdown-item",
                {
                  attrs: { "aria-role": "listitem" },
                  on: { click: _vm.handleShowEquipmentModal },
                },
                [_vm._v(" Equipamento ")]
              )
            : _vm._e(),
          _vm.props.filters.status == _vm.FiltersEnum.ACTIVE
            ? _c(
                "o-dropdown-item",
                {
                  attrs: { "aria-role": "listitem" },
                  on: { click: _vm.handleShowDeleteModal },
                },
                [_vm._v(" Excluir ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "o-modal",
        {
          attrs: { width: "560", active: _vm.isOpenRouteModal },
          on: { close: _vm.handleShowRouteModal },
        },
        [
          _c(
            "div",
            [
              _c("h2", { staticClass: "header-2" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.props.filters.status == _vm.FiltersEnum.ACTIVE ||
                        _vm.props.filters.status == _vm.FiltersEnum.FINISHED
                        ? "Visualizar rota"
                        : "Atualizar carga"
                    ) +
                    " "
                ),
              ]),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.filters.status === _vm.FiltersEnum.IN_PROGRESS &&
                        _vm.step === 0,
                      expression:
                        "filters.status === FiltersEnum.IN_PROGRESS && step === 0",
                    },
                  ],
                  staticClass: "paragraph",
                },
                [
                  _vm._v(
                    "Clique em uma base abaixo para obter mais informações"
                  ),
                ]
              ),
              _c("TimeLinePoint", {
                attrs: {
                  row: _vm.row,
                  step: _vm.step,
                  "handle-previous-step": _vm.handlePreviousStep,
                  "handle-next-step": _vm.handleNextStep,
                  "handle-show-route-modal": _vm.handleShowRouteModal,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "o-modal",
        {
          attrs: { width: "560", active: _vm.isOpenEquipmentModal },
          on: { close: _vm.handleShowEquipmentModal },
        },
        [
          _c("div", [
            _c("h2", { staticClass: "header-2" }, [
              _vm._v("Criando equipamento"),
            ]),
            _c("p", { staticClass: "paragraph" }, [
              _vm._v(
                "Preencha os campos logo abaixo para realizar a criação de um equipamento em nosso sistema"
              ),
            ]),
          ]),
          _c("FormEquipament", {
            attrs: {
              "close-modal": _vm.handleShowEquipmentModal,
              "route-id": _vm.row.route_id,
            },
          }),
        ],
        1
      ),
      _c("Alert", {
        attrs: { active: _vm.isOpenDeleteModal },
        on: { handleClose: _vm.handleShowDeleteModal },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [_vm._v(" Você tem certeza absoluta? ")]
            },
            proxy: true,
          },
          {
            key: "message",
            fn: function () {
              return [
                _vm._v(
                  " Esta ação não pode ser desfeita. Isso irá desativar as informações desta rota. "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttons",
            fn: function () {
              return [
                _c(
                  "o-button",
                  {
                    attrs: { variant: "outline", size: "md" },
                    on: { click: _vm.handleShowDeleteModal },
                  },
                  [_vm._v(" Cancelar ")]
                ),
                _c(
                  "o-button",
                  {
                    attrs: { variant: "primary", size: "md" },
                    on: { click: _vm.handleDelete },
                  },
                  [_vm._v(" Sim, quero excluir ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }