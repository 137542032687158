import { requestScripting } from '@/lib/axios';
import clearString from '@/utils/clearString';
import { removeNullParams } from '@/utils/removeNullParams';

export const sellerService = {
  async getAllRegistered(params, signal) {
    return requestScripting({
      method: 'GET',
      url: `/sellers`,
      params: removeNullParams(params),
      signal
    });
  },
  async getAllNotRegistered(params, signal) {
    return requestScripting({
      method: 'GET',
      url: `/collect`,
      params: removeNullParams(params),
      signal
    });
  },
  async create(data) {
    const body = this.getBody(data);

    return requestScripting({
      method: 'POST',
      data: body,
      url: '/sellers'
    });
  },
  async edit(data, id) {
    const body = this.getBody(data);

    return requestScripting({
      method: 'PATCH',
      data: body,
      url: `/sellers/${id}`
    });
  },
  async delete(id) {
    return requestScripting({
      method: 'DELETE',
      url: `/sellers/${id}`
    });
  },
  async consultAddress(sellerCode) {
    return requestScripting({
      method: 'GET',
      url: `/sellers/sellerAddress`,
      params: removeNullParams({ sellerCode })
    });
  },
  getBody(data) {
    return {
      'customer_code': parseInt(data.customerCode),
      'customer_name': data.customerName,
      'seller_code': parseInt(data.sellerCode),
      'seller_name': data.sellerName,
      'seller_documentation': clearString(data.document),
      'seller_address': data.street,
      'seller_address_number': data.number,
      'seller_address_complement': data.complement,
      'seller_address_neighborhood': data.neighborhood,
      'seller_address_municipality': data.municipality,
      'seller_state': data.state,
      'seller_country': 'BR',
      'seller_zipcode': clearString(data.zipcode),
      'seller_phone_number': clearString(data.phone),
      'seller_contact_name': data.nameContact,
      'seller_mail': data.email,
      'seller_is_active': 1
    };
  }
};
