var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticStyle: { "margin-bottom": "20px" } },
      [
        _c("span", { staticStyle: { color: "#f00", "margin-right": "3px" } }, [
          _vm._v("*"),
        ]),
        _vm._v(_vm._s(_vm.$t("orderCenterCont.SelectCustomers")) + "： "),
        _c(
          "el-select",
          {
            attrs: { placeholder: _vm.$t("GlobalSetObj.pleaseChoose") },
            model: {
              value: _vm.customId,
              callback: function ($$v) {
                _vm.customId = $$v
              },
              expression: "customId",
            },
          },
          _vm._l(_vm.customerList, function (item) {
            return _c("el-option", {
              key: item.id,
              attrs: {
                label: item.username + "（" + item.customerAlias + "）",
                value: item.id,
              },
            })
          }),
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticStyle: { display: "flex", "margin-bottom": "20px" } },
      [
        _c(
          "el-upload",
          {
            ref: "upload",
            staticClass: "upload-demo",
            attrs: {
              action: "",
              multiple: false,
              limit: 1,
              "on-error": _vm.onError,
              "on-change": _vm.handleChange,
              "before-upload": _vm.fileFormatLimit,
              "auto-upload": false,
              "http-request": _vm.uploadBulkUpdateFiles,
            },
          },
          [
            _c(
              "el-button",
              {
                attrs: {
                  size: "small",
                  type: "primary",
                  icon: "el-icon-upload2",
                },
              },
              [
                _vm._v(
                  _vm._s(_vm.$t("orderCenterCont.OrderInformationFileUpload"))
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { flex: "1", "text-align": "center" } },
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.downloadTemplate()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("orderCenterCont.DownloadTemplate")))]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticStyle: { "text-align": "center", "margin-top": "40px" } },
      [
        _c(
          "el-button",
          {
            attrs: { size: "small", type: "primary" },
            on: {
              click: function ($event) {
                return _vm.onsubmitFile()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("orderCenterCont.confirm")))]
        ),
        _c(
          "el-button",
          {
            attrs: { size: "small" },
            on: {
              click: function ($event) {
                return _vm.clearImportOrder()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("orderCenterCont.cancel")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }