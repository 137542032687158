<template>
  <div>
    <el-button
      v-permit:remove="'btn:finance:DataDheet:refresh'"
      type="primary"
      size="small"
      icon="el-icon-refresh-left"
      @click="onOpen"
    >{{ $t('basicData.刷新数据') }}</el-button>

    <el-dialog
      :title="$t('basicData.刷新数据')"
      :visible.sync="isDialog"
      width="500px"
    >
      <el-form
        ref="form" :model="form" :rules="rules"
        label-width="120px"
      >
        <!-- 业务时间 -->
        <el-form-item :label="$t('basicData.BusinessTime')" prop="operatorTime">
          <el-date-picker
            v-model="form.operatorTime"
            :picker-options="pickerOptions"
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder="$t('operationCenter.PleaseSelect')"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isDialog = false">{{ $t('GlobalSetObj.cancel') }}</el-button>

        <!-- 确定 -->
        <el-button type="primary" @click="submitForm">{{ $t('GlobalSetObj.determine') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiStatisticsAgainStatistics } from '@/api/finance/dataDheet';

export default {
  name: '',
  data() {
    return {
      form: {
        operatorTime: ''
      },

      rules: {
        operatorTime: [{ required: true, message: this.$t('operationCenter.PleaseSelect'), trigger: 'change' }]
      },

      isDialog: false,

      pickerOptions: {
        // 不能选择今天及以后的时间
        disabledDate: (time) => {
          return time.getTime() >= Date.now() - 8.64e7;
        }
      }

    };
  },

  methods: {
    onOpen() {
      this.isDialog = true;
      this.form.operatorTime = '';
    },

    submitForm() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        const { operatorTime } = this.form;

        apiStatisticsAgainStatistics(operatorTime).then(res => {
          this.isDialog = false;
          this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
        }).catch(err => {
          // this.$message.error(this.$t('basicData.刷新失败'));
          console.error(err);
        });
      });
    }
  }

};
</script>

<style scoped>
</style>
