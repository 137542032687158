<script >
import { defineProps } from '@vue/composition-api';
import dayjs from 'dayjs';
import { default as DropdownTable } from '@/components/dropdownTable';
import { default as Alert } from '@/components/alert';
import { default as FormEditDriver } from './form/formEditDriver';
import { default as FormDeleteDriver } from './form/formDeleteDriver';
import { useModal } from '@/composable/useModal';
import { useEditDriver } from '@/api/transport/driver/query';
import { toast } from '@/utils/programaticToast';
import { getToastContentComponent } from '@/utils/getToastContentComponent';
const __sfc_main = {};
__sfc_main.props = {
  status: {
    type: Boolean,
    required: true
  },
  row: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;

  /* edit driver */
  const {
    isOpen: isOpenModalEdit,
    handleVisibility: handleVisibilityModalEdit
  } = useModal();

  /* delete driver */
  const {
    isOpen: isOpenModalDelete,
    handleVisibility: handleVisibilityModalDelete
  } = useModal();

  /* restore driver */
  const {
    isOpen: isOpenModalRestore,
    handleVisibility: handleVisibilityModalRestore
  } = useModal();
  const {
    mutate
  } = useEditDriver();
  function handleRestore() {
    const newDriver = {
      ...props.row,
      driver_is_active: 1,
      driver_license_validate: dayjs(props.row.driver_license_validate).format('YYYY/MM/DD')
    };
    mutate({
      newDriver,
      id: newDriver.id,
      isFormatedBody: false
    }, {
      onSuccess: () => {
        handleVisibilityModalRestore();
        toast.open({
          variant: 'success',
          position: 'bottom',
          component: getToastContentComponent({
            title: 'Restaurado com sucesso',
            describe: 'As informações foram restauradas com sucesso',
            typeIcon: 'success'
          }),
          closable: true,
          duration: 4000
        });
      }
    });
  }
  return {
    isOpenModalEdit,
    handleVisibilityModalEdit,
    isOpenModalDelete,
    handleVisibilityModalDelete,
    isOpenModalRestore,
    handleVisibilityModalRestore,
    handleRestore
  };
};
__sfc_main.components = Object.assign({
  DropdownTable,
  FormEditDriver,
  FormDeleteDriver,
  Alert
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <DropdownTable>
      <o-dropdown-item v-show="status" aria-role="listitem" @click="handleVisibilityModalEdit">
        {{ $t('driver.edit') }}
      </o-dropdown-item>

      <o-dropdown-item v-show="status" aria-role="listitem" @click="handleVisibilityModalDelete">
        {{ $t('driver.delete') }}
      </o-dropdown-item>

      <o-dropdown-item v-show="!status" aria-role="listitem" @click="handleVisibilityModalRestore">
        {{ $t('driver.restore') }}
      </o-dropdown-item>
    </DropdownTable>

    <o-modal :active="isOpenModalEdit" width="560" @close="handleVisibilityModalEdit">
      <div>
        <h2 class="header-2">{{ $t('driver.modalEditTitle') }}</h2>
        <p class="paragraph">{{ $t('driver.modalEditParagraph') }}</p>
      </div>

      <FormEditDriver :driver="row" :close-modal="handleVisibilityModalEdit" />
    </o-modal>

    <o-modal :active="isOpenModalDelete" width="560" @close="handleVisibilityModalDelete">
      <div>
        <h2 class="header-2">{{ $t('driver.modalDeleteTitle') }}</h2>
        <p class="paragraph">{{ $t('driver.modalDeleteParagraph') }}</p>
      </div>
      <FormDeleteDriver :driver-id="row.id" :close-modal="handleVisibilityModalDelete" />
    </o-modal>

    <Alert
      :active="isOpenModalRestore"
      @handleClose="handleVisibilityModalRestore"
    >
      <template #title> {{ $t('driver.modalRestoreTitle') }} </template>
      <template #message>  {{ $t('driver.modalRestoreParagraph') }} </template>

      <template #buttons>
        <o-button variant="outline" size="md" @click="handleVisibilityModalRestore"> {{ $t('driver.modalButtonCancel') }} </o-button>
        <o-button variant="primary" size="md" @click="handleRestore"> {{ $t('driver.modalButtonRestore') }} </o-button>
      </template>
    </Alert>
  </div>
</template>
