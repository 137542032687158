<template>
  <!-- 班次选择 下拉列表 -->
  <el-select
    v-model="value" filterable clearable
    :placeholder="$t('GlobalSetObj.pleaseChoose')"
    style="width: 100%;"
  >
    <!-- <el-option
      v-if="isAll"
      :label="$t('GlobalSetObj.all')"
      value=""
    /> -->

    <el-option
      v-for="item in list"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
export default {
  name: '',

  model: {
    prop: 'val',
    event: 'change'
  },

  props: {
    val: {
      type: String,
      required: true
    },

    list: {
      type: Array,
      required: true
    },

    // 是否显示全部下拉菜单
    isAll: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {

    };
  },

  computed: {
    value: {
      get() {
        return this.val;
      },
      set(val) {
        this.$emit('change', val);
      }
    }
  },

  created() {

  },

  methods: {

  }

};
</script>

<style lang="scss" scoped>
</style>
