// @ts-check
import JSEncrypt from 'jsencrypt';

/**
 * RSA 加密 公钥
 */
const PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDMpkV/EUX35Lp57YaAJ34DTc7nKfDjzCX/vjAdqI57CQadoMDBzn+BbKkLEbG45D52I1ko2gLMEA/rpY/qQw1+4mvivBAl68q3SUd/c9HnqhZjKGd/fcsLnebMDns0ZeG331q0rNEmwhZB4gGwjzNMLQ1nVt1YSQLosgV4t08P0wIDAQAB
-----END PUBLIC KEY-----`;

/**
 * RSA 加密 密钥
 */
const PRIVATE_KEY = `-----BEGIN PUBLIC KEY-----
MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBAMymRX8RRffkunnthoAnfgNNzucp8OPMJf++MB2ojnsJBp2gwMHOf4FsqQsRsbjkPnYjWSjaAswQD+ulj+pDDX7ia+K8ECXryrdJR39z0eeqFmMoZ399ywud5swOezRl4bffWrSs0SbCFkHiAbCPM0wtDWdW3VhJAuiyBXi3Tw/TAgMBAAECgYAIbqVtvzwfIwkynZXYJp3A7G4lBOEMZyH9r/bIPLoPhGdbC/+akglGP5WWLiwgznlrDA56v240EvQE+p+7wE2IT5xbYETEiVpZiX8Diq/GpG2Z0GpKfNQKPIMU/g+bxeVe4GyXgvmC9PAuCXTqDls8jk79b2TPEzYsf/k4mUKm0QJBAPgLfFVTUGKoraOPqS0J6ridiq8ZGbtK0W0HJxFHl+KnivG7pSfJ94FbObwVC3MflBWa7pSFXZvRvlqacw3YJ6sCQQDTNn+PRKRqjc7B8uVQvKzg49r4BDUdTckyTA0qBKLpUQtrQRXRV6W2z4sS9+jWLey4BgJ4wNmmyPj3eN9Q3PB5AkBILCdgOF6yZjwn1Dw4Y6NNVmGs8xO+oFVxcNxYNcmm5HMURTIX8rc2h1G0PTdYpv6SjsMOqgODvDKz8C67/4IDAkBRkoe7blhNqs0CjOvRvbzK8lVcWkulMKiW21ZuNvIwSBOv7agefMpMi2V8AvES3xPAktmbh2fICXBWK4Gb+miJAkEAiPkt5R5pcqjpvpK9mG40rWGIDNM1vZHlo7ys5ftGvjCzP7+MxuBe1SRm3n7L4GjM3pREanJdJUxflNxu7OOaQQ==
-----END PUBLIC KEY-----`;

const decrypt = new JSEncrypt();

decrypt.setPublicKey(PUBLIC_KEY); // 设置 加密公钥
decrypt.setPrivateKey(PRIVATE_KEY); // 加密 密钥

/**
 * 加密
 */
const setRSAEncrypt = function setRSA(str) {
  return decrypt.encrypt(str); // 进行加密
};

/**
 * 解密
 */
const getRSAEncrypt = function getRSA(str) {
  return decrypt.decrypt(str);
};

/**
 * 因为在js 中 RSA 加密字符串长度有限制
 * 加密前分割字符串
 * @param {string} str 字符串
 * @param {number} len 字符串最大长度 默认100
 * @returns {string[]}
 */
const strSlice = function(str, len = 100) {
  const arr = [];
  let nextLen = 0;
  const strLength = str.length;
  if (strLength < 100) {
    arr.push(str);
    return arr;
  }

  const strLen = Math.ceil(strLength / len);
  for (let i = 0; i < strLen; i++) {
    const newNextLen = nextLen + len;

    // 最后一次循环
    if (newNextLen > strLength) {
      const sliceStr = str.slice(nextLen, (strLength + 1));
      arr.push(sliceStr);
      break;
    }

    const sliceStr = str.slice(nextLen, newNextLen);
    arr.push(sliceStr);
    nextLen = newNextLen;
  }
  return arr;
};

/**
 * 对字符串进行加密
 * @param {string} str
 * 如果长度超过 100 的字符串，会分别加密，最后用 @ 连接，例如 aaaa -> xx@xx
 * 没有超过则直接进行加密 aa -> xx
 */
const strRSA = function strRSA(str) {
  return strSlice(str).map(key => {
    return setRSAEncrypt(key);
  }).join('@');
};

/**
 * 解密
 * @param {*} str
 * @param {*} smy
 * @returns
 */
const getRSA = function getRSA(str, smy = '@') {
  return str.split(smy).map(key => {
    return getRSAEncrypt(key);
  }).join('');
};

export {
  strRSA,
  getRSA,
  setRSAEncrypt,
  getRSAEncrypt
};

