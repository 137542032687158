<script >
import { defineProps } from '@vue/composition-api';
import { useVuelidate } from '@vuelidate/core';
import { FormActions, FormActionsNext, FormActionsPrevious } from '@/components/wizard-form/formStepActions';
import { required, numeric, maxLength, minLength } from '@/utils/validationErrors';
const __sfc_main = {};
__sfc_main.props = {
  handleNext: {
    type: Function
  },
  isLastStep: {
    type: Boolean
  },
  isFirstStep: {
    type: Boolean
  },
  handlePrevious: {
    type: Function
  },
  formData: {
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const rules = {
    documentOwner: {
      required,
      maxLength: maxLength(18),
      minLength: minLength(14)
    },
    stateRegistration: {
      required,
      numeric,
      maxLength: maxLength(14),
      minLength: minLength(9)
    }
  };
  const v$ = useVuelidate(rules, props.formData);
  async function handleSubmit() {
    const isValid = await v$.value.$validate();
    if (isValid) props.handleNext();
  }
  return {
    v$,
    handleSubmit
  };
};
__sfc_main.components = Object.assign({
  FormActions,
  FormActionsPrevious,
  FormActionsNext
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>

  <form class="wrapper-form" @submit.prevent="handleSubmit">

    <o-field
      v-mask="['###.###.###-##', '##.###.###/####-##']"
      :label="$t('trailer.documentOwner')"
      label-for="documentOwner"
      :message="v$.documentOwner.$errors.length ? v$.documentOwner.$errors[0].$message : null"
    >
      <o-input
        id="documentOwner"
        v-model="formData.documentOwner"
        :placeholder="$t('trailer.documentOwnerPlaceholder')"
      />
    </o-field>

    <o-field
      :label="$t('trailer.stateRegistration')"
      label-for="stateRegistration"
      :message="v$.stateRegistration.$errors.length ? v$.stateRegistration.$errors[0].$message : null"
    >
      <o-input
        id="stateRegistration"
        v-model="formData.stateRegistration"
        :placeholder="$t('trailer.stateRegistrationPlaceholder')"
      />
    </o-field>

    <o-field
      :label="$t('trailer.trackerCompany')"
      label-for="trackerCompany"
    >
      <o-input
        id="trackerCompany"
        v-model="formData.trackerCompany"
        :placeholder="$t('trailer.trackerCompanyPlaceholder')"
      />
    </o-field>

    <o-field
      :label="$t('trailer.trackerCompanyCode')"
      label-for="trackerCompanyCode"
    >
      <o-input
        id="trackerCompanyCode"
        v-model="formData.trackerCompanyCode"
        :placeholder="$t('trailer.trackerCompanyCodePlaceholder')"
      />
    </o-field>

    <FormActions>
      <FormActionsPrevious
        :is-first-step="isFirstStep"
        @handlePrevious="handlePrevious"
      />

      <FormActionsNext
        :is-last-step="isLastStep"
      />
    </FormActions>
  </form>

</template>

<style scoped>
  .wrapper-form {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem/* 16px */;
    padding-top: 0.5rem/* 8px */;
    padding-bottom: 0.5rem/* 8px */;
  }
</style>
