var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "warrp" },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "auto" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-descriptions",
                { attrs: { colon: false, column: 3, direction: "vertical" } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.waybillNumber") } },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.tableInfomation.waybillNumber || "--")
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.createTime") } },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.tableInfomation.createTime || "--")),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.waybillStatus") } },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.tableInfomation.waybillStatus || "--")
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: { label: _vm.$t("GlobalSetObj.serviceLogistics") },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.tableInfomation.logisticsNumber || "--")
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.fuwushang") } },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm.tableInfomation.providerChannelName || "--"
                          )
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("placeOrder.CommercialOwner") } },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.tableInfomation.merchantName || "--")
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.CustomerName") } },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.tableInfomation.customerName || "--")
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.orderNumber") } },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.tableInfomation.customerNumber || "--")
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.CustomerWeight") } },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.tableInfomation.customerWeight || "--")
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("orderCenterCont.WhetherReturnNot")
                                )
                              ),
                            ]),
                            _c(
                              "span",
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "QuestionMark",
                                    attrs: { placement: "top" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("GlobalSetObj.jushou"))
                                        ),
                                      ]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "QuestionMark" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.tableInfomation.hasBack
                                ? _vm.$t("GlobalSetObj.yes")
                                : _vm.$t("GlobalSetObj.No")
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.CollectionType") } },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.tableInfomation.collectType || "--")),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.TypeService") } },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm.tableInfomation.expressType ||
                              _vm.$t("GlobalSetObj.localTrain")
                          )
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("placeOrder.packageType") } },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.tableInfomation.orderPackageType || "--")
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("GlobalSetObj.xiadanzhongliang"))
                              ),
                            ]),
                            _c(
                              "span",
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "QuestionMark",
                                    attrs: { placement: "top" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("GlobalSetObj.coleta"))
                                        ),
                                      ]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "QuestionMark" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(_vm._s(_vm.tableInfomation.weight || "--")),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("newOrder.DepotWeighing") + "(kg)"
                                )
                              ),
                            ]),
                            _c(
                              "span",
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "QuestionMark",
                                    attrs: { placement: "top" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("GlobalSetObj.coleta"))
                                        ),
                                      ]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "QuestionMark" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(
                          _vm._s(_vm.tableInfomation.outletsWeight || "--")
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.TransferWeighing") } },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.tableInfomation.transportWeight || "--")
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("GlobalSetObj.comprimento"))
                              ),
                            ]),
                            _c(
                              "span",
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "QuestionMark",
                                    attrs: { placement: "top" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("GlobalSetObj.ParcelLength")
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "QuestionMark" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(_vm._s(_vm.tableInfomation.length || "--")),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("GlobalSetObj.largura"))),
                            ]),
                            _c(
                              "span",
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "QuestionMark",
                                    attrs: { placement: "top" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("GlobalSetObj.ParcelWidth")
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "QuestionMark" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(_vm._s(_vm.tableInfomation.width || "--")),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("GlobalSetObj.altura"))),
                            ]),
                            _c(
                              "span",
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "QuestionMark",
                                    attrs: { placement: "top" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("GlobalSetObj.ParcelHeight")
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "QuestionMark" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(_vm._s(_vm.tableInfomation.height || "--")),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.length") } },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.tableInfomation.transportLength || "--")
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.width") } },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.tableInfomation.transportWidth || "--")
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.height") } },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.tableInfomation.transportHeight || "--")
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: _vm.$t("newOrder.TransitPhotos"),
                        span: 3,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          style:
                            "" +
                            (_vm.$i18n.locale !== "zh"
                              ? "width:50px"
                              : "width:60px"),
                        },
                        [
                          _vm.tableInfomation.photoUrl
                            ? _c(
                                "el-tooltip",
                                {
                                  staticClass: "QuestionMarkPhoto",
                                  attrs: { placement: "top" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "image",
                                        staticStyle: { width: "300px" },
                                        attrs: {
                                          src: _vm.tableInfomation.photoUrl,
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        "justify-content": "center",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("GlobalSetObj.see"))
                                        ),
                                      ]),
                                      _c("svg-icon", {
                                        attrs: { "icon-class": "iconEye" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _c("span", [_vm._v(_vm._s("--"))]),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.PreCollectionDepot") } },
                    [
                      _c("div", { staticStyle: { width: "100%" } }, [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.tableInfomation.preCollectStationName)
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { span: 2 } },
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("newOrder.ActualCollection"))
                              ),
                            ]),
                            _c(
                              "span",
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "QuestionMark",
                                    attrs: { placement: "top" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("GlobalSetObj.NetworkName")
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "QuestionMark" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(
                          _vm._s(_vm.tableInfomation.collectStationName || "--")
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("GlobalSetObj.preEntrega"))),
                            ]),
                            _c(
                              "span",
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "QuestionMark",
                                    attrs: { placement: "top" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "GlobalSetObj.SortingCompleted"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "QuestionMark" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.tableInfomation.preDeliverStationName || "--"
                          )
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("GlobalSetObj.entrega"))),
                            ]),
                            _c(
                              "span",
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "QuestionMark",
                                    attrs: { placement: "top" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "GlobalSetObj.DeliveToStorage"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "QuestionMark" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(
                          _vm._s(_vm.tableInfomation.deliverStationName || "--")
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.transitCenter") } },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.tableInfomation.transitCenter || "--")
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("orderCenterCont.PickUpBagNumber")
                                )
                              ),
                            ]),
                            _c(
                              "span",
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "QuestionMark",
                                    attrs: { placement: "top" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("GlobalSetObj.sealBagNumber")
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "QuestionMark" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(
                          _vm._s(_vm.tableInfomation.collectNumber || "--")
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("orderCenterCont.TransitBinBagNumber")
                                )
                              ),
                            ]),
                            _c(
                              "span",
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "QuestionMark",
                                    attrs: { placement: "top" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "GlobalSetObj.TransitWarehouse"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "QuestionMark" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(
                          _vm._s(_vm.tableInfomation.transitBagNumber || "--")
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("GlobalSetObj.DeliveryRoutes"))
                              ),
                            ]),
                            _c(
                              "span",
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "QuestionMark",
                                    attrs: { placement: "top" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "GlobalSetObj.TransferOrSupplier"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "QuestionMark" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(_vm._s(_vm.tableInfomation.pathNumber || "--")),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { colon: false, column: 1, direction: "vertical" } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.problemReason") } },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.tableInfomation.problemType || "--")),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { colon: false, column: 3, direction: "vertical" } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.problemRemark") } },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.tableInfomation.problemReason || "--")
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("placeOrder.goodType") } },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.tableInfomation.cargoType || "--")),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: _vm.$t("collectionCenter.verificationCode"),
                      },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.tableInfomation.signVerifyCode || "--")
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.DIr") } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            cursor: "pointer",
                            color: "#409eff",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.dirSee(
                                _vm.tableInfomation.waybillNumber
                              )
                            },
                          },
                        },
                        [
                          _c("div", [
                            _vm._v(_vm._s(_vm.$t("GlobalSetObj.View"))),
                          ]),
                          _c(
                            "span",
                            { staticClass: "iconEye" },
                            [
                              _c("svg-icon", {
                                attrs: { "icon-class": "iconEye" },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }