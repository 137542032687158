<template>
  <div>
    <div>
      <el-button
        v-loading="loading"
        icon="el-icon-download"
        size="small"
        type="primary"
        @click="operationBtn"
      >{{ $t('receivablePayable.DownloadTheTemplate') }}</el-button>
      <upload-excel
        :on-success="importLogistics"
        :but-name="$t('receivablePayable.ImportCalculatePrices')"
        style="display: inline-block;margin-left: 20px;"
      />
    </div>
  </div>
</template>

<script>
// import { getDistributionDotList } from '@/api/logisticsOrders';
import { saveAs } from 'file-saver';
import { apiCommonSite } from '@/api/logisticsOrders';
const ExcelJS = require('exceljs');
import uploadExcel from '@/components/UploadExcel';

export default {
  name: 'ImportAddFee',
  components: {
    uploadExcel
  },
  props: {
    calculationType: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      logisticsKey: {
        '业务单号': 'waybillNumber',
        '网点': 'siteId',
        '计费重量（KG）': 'weight'
      },
      outletsList: [],
      codePrice: '/static/codePrice.xlsx',
      loading: false
    };
  },
  created() {
    // this.getSitePageList();
  },
  methods: {
    // 下载模板
    async operationBtn() {
      const that = this;

      await this.getSitePageList();

      if (that.calculationType === 5) {
        // const link = document.createElement('a');
        // link.style.display = 'none';
        // 特殊情况：无法拿到存储语言时，判断是否是中文环境
        const navigatorLanguage = navigator.language.toLowerCase().indexOf('zh') > -1 ? 'zh' : 'pu';
        const language = sessionStorage.getItem('language') || navigatorLanguage;
        const date = new Date().getTime();
        // link.href = this.codePrice;
        let saveName = '';
        if (language === 'zh') {
          saveName = '导入邮编重新核算价格模板' + date + '.xlsx';
          // link.setAttribute('download', '导入邮编重新核算价格模板' + date + '.xlsx');
        } else {
          // link.setAttribute('download', 'Importar CEP para recalculação do modelo de preços' + date + '.xlsx');
          saveName = 'Importar CEP para recalculação do modelo de preços' + date + '.xlsx';
        }
        // document.body.appendChild(link);
        // link.click();
        // link.parentNode.removeChild(link);
        saveAs(this.codePrice, saveName);
        return;
      }

      async function ddd() {
        const wb = new ExcelJS.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        const Sheet2 = wb.addWorksheet('Sheet2');

        if (that.calculationType === 2) {
          Sheet1.columns = [
            {
              header: '业务单号',
              key: 'waybillNumber',
              width: 40
            },
            {
              header: '订单号',
              key: 'orderNumber',
              width: 40
            }
          ];
          Sheet1.getCell('A2').value = 'número do negócio';
          Sheet1.getCell('B2').value = 'Local';
          Sheet1.mergeCells('A3:B3');
        } else if (that.calculationType === 3) {
          Sheet1.columns = [
            {
              header: '业务单号',
              key: 'waybillNumber',
              width: 40
            },
            {
              header: '订单号',
              key: 'orderNumber',
              width: 40
            },
            {
              header: '计费重量（KG）',
              key: 'weight',
              width: 40
            }
          ];
          Sheet1.getCell('A2').value = 'número do negócio';
          Sheet1.getCell('B2').value = 'Local';
          Sheet1.getCell('C2').value = 'Peso faturável（KG）';
          Sheet1.mergeCells('A3:C3');
        }
        Sheet1.getCell('A3').value = '如果订单的“包裹类型”为“合单”时，计费重量请填写总单的重量。每次仅导入40000条（Se o "tipo de pacote" da ordem for "fechado", por favor, preencha o peso total da ordem.Importar apenas 40000 itens de cada vez）';
        Sheet1.getRow(3).getCell(1).font = {
          color: { argb: 'ff0000' }
        };

        if (that.calculationType !== 2 && that.calculationType !== 3) {
          Sheet2.columns = [
            {
              header: '网点（Outlets）',
              key: 'siteId',
              width: 40
            }
          ];
          const Sheet2_data = [];
          that.outletsList.map(item => {
            Sheet2_data.push({
              siteId: item.name + '-' + item.id,
              width: 20
            });
          });
          Sheet2.addRows(Sheet2_data);
          new Array(40000).fill(0).forEach((_, idx) => {
            const row = idx + 2;
            // 渲染费用类型下拉框
            Sheet1.getCell(row, 2).dataValidation = {
              type: 'list',
              formulae: [`=Sheet2!$A$2:$A${Sheet2_data.length + 1}`]
            };
          });
        }
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        const saveName =
          that.calculationType === 2 ? that.$t('receivablePayable.ImportRecalculatePricesTemplate') + '.xlsx'
            : that.$t('receivablePayable.ImportWeightRecalculationPriceTemplate') + '.xlsx';
        saveAs(blob, saveName);
        return;
      }
      ddd();
    },
    // 获取网点
    getSitePageList() {
      const param = {
        pageNumber: 1,
        pageSize: 10000000,
        params: {
          siteStatus: undefined
        }
      };
      this.loading = true;
      return apiCommonSite(param, false).then(res => {
        if (res.status === 'OK') {
          this.outletsList = res.data.records;
        }
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },
    // 导入费用
    importLogistics(array) {
      const dataArr = [];
      const arrData = array[0].results.splice(2);
      let logisticsKey = {};
      if (this.calculationType === 5) {
        logisticsKey = {
          '业务单号': 'waybillNumber',
          '订单号': 'orderNumber',
          '收件人邮编': 'receiveZipcode',
          '发件人邮编': 'senderZipcode',
          '业务类型': 'businessType'
        };
        // const navigatorLanguage = navigator.language.toLowerCase().indexOf('zh') > -1 ? 'zh' : 'pu';
        // const language = sessionStorage.getItem('language') || navigatorLanguage;
        // if (language === 'zh') {
        //   logisticsKey = {
        //     '业务单号': 'waybillNumber',
        //     '客户订单号': 'orderNumber',
        //     '收件人邮编': 'receiveZipcode',
        //     '发件人邮编': 'senderZipcode',
        //     '业务类型': 'businessType'
        //   };
        // } else {
        //   logisticsKey = {
        //     'número do negócio': 'waybillNumber',
        //     'número do pedido docliente': 'orderNumber',
        //     'tipo de negócio': 'receiveZipcode',
        //     'CEP do destinatário': 'senderZipcode',
        //     'CEP do remetente': 'businessType'
        //   };
        // }
      } else if (this.calculationType === 2) {
        logisticsKey = {
          '业务单号': 'waybillNumber',
          '订单号': 'orderNumber'
        };
      } else {
        logisticsKey = {
          '业务单号': 'waybillNumber',
          // '网点': 'siteId',
          '订单号': 'orderNumber',
          '计费重量（KG）': 'weight'
        };
      }
      arrData.map((item) => {
        const dataObj = {};
        for (const k in logisticsKey) {
          if (item[k]) {
            if (k === '网点') {
              const index = item[k].lastIndexOf('-');
              const lastSegment = item[k].substring(index + 1);
              if (lastSegment) {
                dataObj[logisticsKey[k]] = lastSegment;
              } else {
                dataObj[logisticsKey[k]] = '';
              }
            } else {
              const ITEM = item[k] === this.$t('collectionCenter.expressDelivery') ? 0
                : item[k] === this.$t('collectionCenter.lanshou') ? 1
                  : item[k] === this.$t('collectionCenter.dispatch') ? 2 : item[k];
              dataObj[logisticsKey[k]] = ITEM;
            }
          } else {
            dataObj[logisticsKey[k]] = '';
          }
        }
        dataArr.push(dataObj);
      });
      this.$emit('importPrice', dataArr);
    }
  }
};
</script>

<style scoped>

</style>
