<script >
const __sfc_main = {};
export default __sfc_main;
</script>

<template>
  <div class="wrapper o-container">
    <div class="wrapperHeaderPrimary">
      <slot name="header" />
    </div>
    <div v-if="$slots.filter" class="warpperFilter">
      <slot name="filter" />
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  border-width: 1px;
  border-radius: 0.375rem/* 6px */;
}

.wrapperHeaderPrimary {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.5rem/* 24px */;
  padding-bottom: 1.5rem/* 24px */;
  padding-left: 1rem/* 16px */;
  padding-right: 1rem/* 16px */;
}

.warpperFilter {
  border-top-width: 1px;
  padding-top: 1.25rem/* 20px */;
  padding-bottom: 1.25rem/* 20px */;
  padding-left: 1rem/* 16px */;
  padding-right: 1rem/* 16px */;
  display: flex;
  gap: 1.25rem/* 20px */;
}

@media only screen and (max-width: 560px) {
  .warpperFilter {
    flex-direction: column;
  }
}

</style>
