import request from '@/utils/request';

/**
 * 索赔列表
 */

// 分页查询
export const apidDmageClaimformPage = data => {
  return request.post('/fa/demageClaimform/page', data);
};

// 列表导出
export const apidDmageClaimformExport = data => {
  return request.post('/fa/demageClaimform/formExport', data);
};

// 详情
export const apiDamageClaimFormId = id => {
  return request.get(`fa/demageClaimform/${id}`);
};

// 新增/保存
export const apiDemageClaimFormAdd = data => {
  return request.post('/fa/demageClaimform/add', data);
};

// 修改
export const apiDemageClaimFormEdit = data => {
  return request.put('/fa/demageClaimform/edit', data);
};

// 关联订单导出
export const apiDemageClaimformExport = data => {
  return request.post('/fa/demageClaimform/export', data);
};

// 关联单号校验/导入
export const apiDemageClaimformOrderDetail = (data, isLoading = true) => {
  return request({
    method: 'POST',
    url: '/fa/demageClaimform/orderDetail',
    data,
    isLoading // 是否显示加载中参数
  });
};

// 移除关联订单
export const apiDemageClaimFormRemoveBill = data => {
  return request.post('/fa/demageClaimform/removeBill', data);
};

// 提交变更节点
export const apiDemageClaimform = id => {
  return request.post(`/fa/demageClaimform/commit/${id}`);
};

// 审核--变更节点
export const apiDemageClaimformRejectAndPass = data => {
  return request.post('/fa/demageClaimform/rejectAndPass', data);
};

// 审批流
export const apiDemageClaimformCommit = id => {
  return request.post(`/fa/demageClaimform/commit/${id}`);
};

// 关闭
export const apiDemageClaimformClose = id => {
  return request.get(`fa/demageClaimform/close/${id}`);
};

// 付款
export const apiDemageClaimformCommitPay = data => {
  return request.post('/fa/demageClaimform/commitPay', data);
};
// 取消付款
export const apiDemageClaimformUnCommitPay = data => {
  return request.post('/fa/demageClaimform/unCommitPay', data);
};
// 索赔账单取消付款
export const apiDemageClaimBillUnCommitPay = data => {
  return request.post('/fa/demageClaimBill/unCommitPay', data);
};
// 关联订单分页查询
export const apiDemageClaimformUnClimFromDetail = data => {
  return request.post('/fa/demageClaimform/climFromDetail', data);
};
