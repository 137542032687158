export const transferRoute = {
  // tableData
  transferRoutes: {
    zh: '转运路线',
    en: 'Transfer Routes',
    pu: 'Rotas de transferência'
  },
  createRouteButton: {
    zh: '创建路线',
    en: 'Create Route',
    pu: 'Criar rota'
  },
  routeIDLabel: {
    zh: '路线ID',
    en: 'Route ID',
    pu: 'Rota ID'
  },
  creatingRouteTitle: {
    zh: '创建路线',
    en: 'Creating Route',
    pu: 'Criando rota'
  },
  creatingRouteParagraph: {
    zh: '填写下面的字段以在我们的系统中创建一条路线',
    en: 'Fill in the fields below to create a route in our system',
    pu: 'Preencha os campos abaixo para a criação de uma rota em nosso sistema'
  },

  // formCreateTransferRoute
  initialRoute: {
    zh: '起始路线',
    en: 'Initial Route',
    pu: 'Rota inicial'
  },
  initialRoutePlaceholder: {
    zh: '输入起始点',
    en: 'Enter initial point',
    pu: 'Digite o ponto inicial'
  },
  finalRoute: {
    zh: '结束路线',
    en: 'Final Route',
    pu: 'Rota final'
  },
  finalRoutePlaceholder: {
    zh: '输入终点',
    en: 'Enter final point',
    pu: 'Digite o ponto final'
  },
  stopPoints: {
    zh: '停靠点',
    en: 'Stop Points',
    pu: 'Pontos de parada'
  },
  stopPointsPlaceholder: {
    zh: '选择停靠点',
    en: 'Select stop points',
    pu: 'Selecione os pontos de parada'
  },
  noResults: {
    zh: '无结果',
    en: 'No results',
    pu: 'Sem resultados'
  },
  submit: {
    zh: '提交',
    en: 'Submit',
    pu: 'Enviar'
  }
};
