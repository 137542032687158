export const base = {
  // tableData
  base: {
    zh: '基地',
    en: 'Base',
    pu: 'Base'
  },
  createBase: {
    zh: '创建基地',
    en: 'Create base',
    pu: 'Criar base'
  },
  name: {
    zh: '名称',
    en: 'Name',
    pu: 'Nome'
  },
  namePlaceholder: {
    zh: '按名称搜索',
    en: 'Search by name',
    pu: 'Pesquise pelo nome'
  },
  document: {
    zh: '文件',
    en: 'Document',
    pu: 'Documento'
  },
  documentPlaceholder: {
    zh: '按文件搜索',
    en: 'Search by document',
    pu: 'Pesquise pelo documento'
  },
  stateRegistration: {
    zh: '州登记',
    en: 'State registration',
    pu: 'Registro estadual'
  },
  address: {
    zh: '地址',
    en: 'Address',
    pu: 'Endereço'
  },
  openingHours: {
    zh: '营业时间',
    en: 'Opening hours',
    pu: 'Horário de funcionamento'
  },
  noResults: {
    zh: '没有结果',
    en: 'No results',
    pu: 'Sem resultados'
  },
  createBaseTitle: {
    zh: '创建基地',
    en: 'Creating Base',
    pu: 'Criando Base'
  },
  createBaseParagraph: {
    zh: '填写下面的字段以在我们的系统中创建一个基地',
    en: 'Fill in the fields below to create a base in our system',
    pu: 'Preencha os campos logo abaixo para realizar a criação de uma base em nosso sistema'
  },

  // actions
  edit: {
    zh: '编辑',
    en: 'Edit',
    pu: 'Editar'
  },
  delete: {
    zh: '删除',
    en: 'Delete',
    pu: 'Excluir'
  },
  editBaseTitle: {
    zh: '编辑基地',
    en: 'Editing base',
    pu: 'Editando base'
  },
  editBaseParagraph: {
    zh: '填写下面的字段以在我们的系统中编辑基地',
    en: 'Fill in the fields below to edit a base in our system',
    pu: 'Preencha os campos logo abaixo para realizar a edição de uma base em nosso sistema'
  },
  confirmDeletionTitle: {
    zh: '您确定吗？',
    en: 'Are you sure?',
    pu: 'Você tem certeza absoluta?'
  },
  confirmDeletionParagraph: {
    zh: '此操作无法撤消。这将使基地信息无效',
    en: 'This action cannot be undone. This will deactivate the base information',
    pu: 'Essa ação não pode ser desfeita. Isso ira desativar as informações da base'
  },
  cancel: {
    zh: '取消',
    en: 'Cancel',
    pu: 'Cancelar'
  },
  confirmDelete: {
    zh: '是的，我想要删除',
    en: 'Yes, I want to delete',
    pu: 'sim, quero excluir'
  },

  // baseData
  company: {
    zh: '公司',
    en: 'Company',
    pu: 'Companhia'
  },
  companyNamePlaceholder: {
    zh: '输入公司名称',
    en: 'Enter company name',
    pu: 'Digite o nome da companhia'
  },
  baseName: {
    zh: '基地名称',
    en: 'Base Name',
    pu: 'Nome da base'
  },
  baseNamePlaceholder: {
    zh: '输入基地名称',
    en: 'Enter base name',
    pu: 'Digite o nome da base'
  },
  baseDocumentPlaceholder: {
    zh: '输入基地文件',
    en: 'Enter base document',
    pu: 'Digite o documento da base'
  },
  stateRegistrationPlaceholder: {
    zh: '输入注册状态',
    en: 'Enter state registration',
    pu: 'Digite o registro estadual'
  },
  receivingCapacity: {
    zh: '接收能力',
    en: 'Receiving Capacity',
    pu: 'Capacidade de recebimento'
  },
  receivingCapacityPlaceholder: {
    zh: '输入接收能力',
    en: 'Enter receiving capacity',
    pu: 'Digite a capacidade de recebimento'
  },
  shippingCapacity: {
    zh: '发送能力',
    en: 'Shipping Capacity',
    pu: 'Capacidade de envio'
  },
  shippingCapacityPlaceholder: {
    zh: '输入发送能力',
    en: 'Enter shipping capacity',
    pu: 'Digite a capacidade de envio'
  },
  IATAPlaceholder: {
    zh: '输入基地IATA',
    en: 'Enter base IATA',
    pu: 'Digite a IATA da base'
  },
  openingTime: {
    zh: '开放时间',
    en: 'Opening Time',
    pu: 'Horário de abertura'
  },
  closingTime: {
    zh: '关闭时间',
    en: 'Closing Time',
    pu: 'Horário de fechamento'
  },
  selectTime: {
    zh: '选择时间',
    en: 'Select Time',
    pu: 'selecione a hora'
  },

  // contactData
  businessPhone: {
    zh: '商业电话',
    en: 'Business Phone',
    pu: 'Telefone comercial'
  },
  businessPhonePlaceholder: {
    zh: '输入商业电话号码',
    en: 'Enter business phone number',
    pu: 'Digite um número comercial'
  },
  contactName: {
    zh: '联系人姓名',
    en: 'Contact Name',
    pu: 'Contato'
  },
  contactNamePlaceholder: {
    zh: '输入联系人姓名',
    en: 'Enter contact name',
    pu: 'Digite o nome do contato'
  },
  mobilePhone: {
    zh: '手机',
    en: 'Mobile Phone',
    pu: 'Celular'
  },
  mobilePhonePlaceholder: {
    zh: '输入手机号码',
    en: 'Enter mobile phone number',
    pu: 'Digite um número celular'
  },
  email: {
    zh: '邮箱',
    en: 'Email',
    pu: 'Email'
  },
  emailPlaceholder: {
    zh: '输入邮箱',
    en: 'Enter email',
    pu: 'Digite um email'
  }

};
