var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.disabled
        ? _c("SenderReadOnlyCmp", { attrs: { "rule-form": _vm.ruleForm } })
        : _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-position": "top",
                disabled: _vm.disabled,
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("newOrder.SenderName"),
                            rules: _vm.rules.senderName,
                            prop: "senderName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.ruleForm.senderName,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "senderName", $$v)
                              },
                              expression: "ruleForm.senderName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("newOrder.SenderCompany") } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.ruleForm.senderCompany,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "senderCompany", $$v)
                              },
                              expression: "ruleForm.senderCompany",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("newOrder.senderPhone"),
                            rules: _vm.rules.senderName,
                            prop: "senderMobile",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.ruleForm.senderMobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "senderMobile", $$v)
                              },
                              expression: "ruleForm.senderMobile",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("driver.modalStreet") } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.ruleForm.senderStreet,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "senderStreet", $$v)
                              },
                              expression: "ruleForm.senderStreet",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("newOrder.SenderNumber") } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.ruleForm.senderHouseNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "senderHouseNumber", $$v)
                              },
                              expression: "ruleForm.senderHouseNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("newOrder.SenderDistrict") } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.ruleForm.senderArea,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "senderArea", $$v)
                              },
                              expression: "ruleForm.senderArea",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("newOrder.SenderCity"),
                            rules: _vm.rules.senderName,
                            prop: "senderCity",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.ruleForm.senderCity,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "senderCity", $$v)
                              },
                              expression: "ruleForm.senderCity",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("newOrder.SenderState"),
                            rules: _vm.rules.senderName,
                            prop: "senderState",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.ruleForm.senderState,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "senderState", $$v)
                              },
                              expression: "ruleForm.senderState",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("newOrder.SenderZipCode"),
                            rules: _vm.rules.senderName,
                            prop: "senderZipcode",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.ruleForm.senderZipcode,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "senderZipcode", $$v)
                              },
                              expression: "ruleForm.senderZipcode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.sendderComplement"),
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              autosize: { minRows: 2, maxRows: 4 },
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                              type: "textarea",
                            },
                            model: {
                              value: _vm.ruleForm.senderComplement,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "senderComplement", $$v)
                              },
                              expression: "ruleForm.senderComplement",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("newOrder.SenderCPF"),
                            rules: _vm.rules.senderName,
                            prop: "senderTax",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.ruleForm.senderTax,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "senderTax", $$v)
                              },
                              expression: "ruleForm.senderTax",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("navbar.senderIe") } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.ruleForm.senderIeNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "senderIeNumber", $$v)
                              },
                              expression: "ruleForm.senderIeNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("newOrder.SendingAddress"),
                            rules: _vm.rules.senderName,
                            prop: "senderAddress",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              autosize: { minRows: 2, maxRows: 4 },
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                              type: "textarea",
                            },
                            model: {
                              value: _vm.ruleForm.senderAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "senderAddress", $$v)
                              },
                              expression: "ruleForm.senderAddress",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("newOrder.SenderPhone") } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.ruleForm.senderPhone,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "senderPhone", $$v)
                              },
                              expression: "ruleForm.senderPhone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("newOrder.SenderEmail") } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.ruleForm.senderMail,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "senderMail", $$v)
                              },
                              expression: "ruleForm.senderMail",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }