var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          staticClass: "tabsClass",
          attrs: { type: "border-card" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("collectionCenter.basicInformation"),
                name: "1",
              },
            },
            [
              _c(
                "div",
                { staticClass: "informationCont" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "queryForm",
                      attrs: { model: _vm.queryForm, "label-position": "top" },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "collectionCenter.provinceee"
                                    ),
                                    prop: "provincesId",
                                    rules: {
                                      required: true,
                                      message: _vm.$t(
                                        "GlobalSetObj.pleaseChoose"
                                      ),
                                      trigger: ["blur", "change"],
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "GlobalSetObj.pleaseChoose"
                                        ),
                                        clearable: "",
                                        filterable: "",
                                      },
                                      model: {
                                        value: _vm.queryForm.provincesId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm,
                                            "provincesId",
                                            $$v
                                          )
                                        },
                                        expression: "queryForm.provincesId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.allTraceStateList,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label:
                                              item.twoCode +
                                              (item.name
                                                ? "（" + item.name + "）"
                                                : ""),
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("GlobalSetObj.RouteNumber1"),
                                    prop: "routeNumber",
                                    rules: {
                                      required: true,
                                      message: _vm.$t(
                                        "GlobalSetObj.pleaseInput"
                                      ),
                                      trigger: ["blur", "change"],
                                    },
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "show-word-limit": "",
                                      maxlength: "20",
                                    },
                                    model: {
                                      value: _vm.queryForm.routeNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryForm,
                                          "routeNumber",
                                          $$v
                                        )
                                      },
                                      expression: "queryForm.routeNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "GlobalSetObj.StartingStation1"
                                    ),
                                    prop: "originSiteId",
                                    rules: {
                                      required: true,
                                      message: _vm.$t(
                                        "GlobalSetObj.pleaseChoose"
                                      ),
                                      trigger: ["blur", "change"],
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "GlobalSetObj.pleaseChoose"
                                        ),
                                        clearable: "",
                                        filterable: "",
                                      },
                                      model: {
                                        value: _vm.queryForm.originSiteId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm,
                                            "originSiteId",
                                            $$v
                                          )
                                        },
                                        expression: "queryForm.originSiteId",
                                      },
                                    },
                                    _vm._l(_vm.siteList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "GlobalSetObj.destinationPointSite"
                                    ),
                                    prop: "destinationSiteId",
                                    rules: {
                                      required: true,
                                      message: _vm.$t(
                                        "GlobalSetObj.pleaseChoose"
                                      ),
                                      trigger: ["blur", "change"],
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "GlobalSetObj.pleaseChoose"
                                        ),
                                        clearable: "",
                                        filterable: "",
                                      },
                                      model: {
                                        value: _vm.queryForm.destinationSiteId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm,
                                            "destinationSiteId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.destinationSiteId",
                                      },
                                    },
                                    _vm._l(_vm.siteList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.addOrEdit === 2
                            ? [
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "GlobalSetObj.StartingStationBranchAddress"
                                          ),
                                          prop: "originSiteAddress",
                                          rules: {
                                            required: true,
                                            message: _vm.$t(
                                              "GlobalSetObj.pleaseInput"
                                            ),
                                            trigger: ["blur", "change"],
                                          },
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            "show-word-limit": "",
                                            maxlength: "500",
                                          },
                                          model: {
                                            value:
                                              _vm.queryForm.originSiteAddress,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryForm,
                                                "originSiteAddress",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "queryForm.originSiteAddress",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "GlobalSetObj.StartingStationBranchLatitudeAndLongitude"
                                          ),
                                          prop: "originSiteLatLongitude",
                                          rules: {
                                            required: true,
                                            message: _vm.$t(
                                              "GlobalSetObj.pleaseInput"
                                            ),
                                            trigger: ["blur", "change"],
                                          },
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            "show-word-limit": "",
                                            maxlength: "30",
                                          },
                                          model: {
                                            value:
                                              _vm.queryForm
                                                .originSiteLatLongitude,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryForm,
                                                "originSiteLatLongitude",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "queryForm.originSiteLatLongitude",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "GlobalSetObj.destinationPointBranchAddress"
                                          ),
                                          prop: "destinationSiteAddress",
                                          rules: {
                                            required: true,
                                            message: _vm.$t(
                                              "GlobalSetObj.pleaseInput"
                                            ),
                                            trigger: ["blur", "change"],
                                          },
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            "show-word-limit": "",
                                            maxlength: "500",
                                          },
                                          model: {
                                            value:
                                              _vm.queryForm
                                                .destinationSiteAddress,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryForm,
                                                "destinationSiteAddress",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "queryForm.destinationSiteAddress",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "GlobalSetObj.DestinationLatitudeAndLongitude"
                                          ),
                                          prop: "destinationSiteLatLongitude",
                                          rules: {
                                            required: true,
                                            message: _vm.$t(
                                              "GlobalSetObj.pleaseInput"
                                            ),
                                            trigger: ["blur", "change"],
                                          },
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            "show-word-limit": "",
                                            maxlength: "30",
                                          },
                                          model: {
                                            value:
                                              _vm.queryForm
                                                .destinationSiteLatLongitude,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryForm,
                                                "destinationSiteLatLongitude",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "queryForm.destinationSiteLatLongitude",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "GlobalSetObj.StartingStation"
                                    ),
                                    prop: "originatingPoint",
                                    rules: {
                                      required: true,
                                      message: _vm.$t(
                                        "GlobalSetObj.pleaseChoose"
                                      ),
                                      trigger: ["blur", "change"],
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: { size: "mini" },
                                      model: {
                                        value: _vm.queryForm.originatingPoint,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm,
                                            "originatingPoint",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.originatingPoint",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: 0 } },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("GlobalSetObj.yes"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: 1 } },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("GlobalSetObj.No"))
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("GlobalSetObj.status"),
                                    prop: "state",
                                    rules: {
                                      required: true,
                                      message: _vm.$t(
                                        "GlobalSetObj.pleaseChoose"
                                      ),
                                      trigger: ["blur", "change"],
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: { size: "mini" },
                                      model: {
                                        value: _vm.queryForm.state,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.queryForm, "state", $$v)
                                        },
                                        expression: "queryForm.state",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: 1 } },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("GlobalSetObj.stop"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: 0 } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("GlobalSetObj.enable")
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "informationFoot" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "info", size: "small", plain: "" },
                      on: { click: _vm.cancelSave },
                    },
                    [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:payableManagement:routeDistanceReference:edit",
                          expression:
                            "'btn:payableManagement:routeDistanceReference:edit'",
                          arg: "remove",
                        },
                      ],
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.saveAdd },
                    },
                    [_vm._v(_vm._s(_vm.$t("GlobalSetObj.confirm")))]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }