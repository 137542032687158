<template>
  <div>
    <el-card style="margin-bottom: 20px;">
      <el-form inline>
        <el-row>
          <el-col :span="6">
            <el-form-item label="" class="box">
              <el-input
                v-model="query.params.val1" :placeholder="$t('GlobalSetObj.pleaseInputContent')" clearable
                style="width: 350px;" class="input-with-select"
              >
                <el-select slot="prepend" v-model="type1" :placeholder="$t('GlobalSetObj.pleaseChoose')">
                  <!-- 账单编号 -->
                  <el-option :label="$t('ClaimsManagement.BillNumber')" value="claimsBillNumber" />
                  <!-- 理赔单号 -->
                  <el-option :label="$t('ClaimsManagement.ClaimNumber')" value="claimsNumber" />
                </el-select>
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="9">
            <!--支付时间-->
            <el-form-item :label="$t('ClaimsManagement.TimeOfPayment')">
              <el-date-picker
                v-model="query.params.patyedTimeList"
                style="width: 380px;"
                type="datetimerange"
                :start-placeholder="$t('GlobalSetObj.startDate')"
                :end-placeholder="$t('GlobalSetObj.endDate')"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['09:00:00', '18:00:00']"
              />
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <!--客户-->
            <el-form-item :label="$t('GlobalSetObj.client')">
              <CustomerSelectCmp v-model="query.params.customerId" :is-all="true" />
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <!--状态-->
            <el-form-item :label="$t('GlobalSetObj.status')">
              <el-select v-model="query.params.payedStatus" :placeholder="$t('GlobalSetObj.pleaseChoose')">
                <el-option :label="$t('GlobalSetObj.all')" value="" />
                <el-option
                  v-for="item in PAYSTATUS_STATUS"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-button
        type="primary"
        size="small"
        icon="el-icon-search"
        @click="getTableData"
      >{{ $t('operationCenter.Procurar') }}</el-button>
      <el-button size="small" icon="el-icon-refresh-left" @click="onReset">
        {{ $t('operationCenter.reset') }}
      </el-button>
    </el-card>

    <el-card>
      <!-- <el-button
        type="primary"
        size="small"
        style="margin-bottom: 20px;"
        @click="onOpenPayment"
      >付款</el-button>
      <el-button
        type="primary"
        size="small"
      >账单抵扣</el-button> -->

      <el-table
        :data="tableData"
        :height="580"
        border
        style="width: 100%"
      >
        <!--序号-->
        <el-table-column :label="$t('operationCenter.ind')" width="60px" align="center">
          <template slot-scope="scope">
            <span>{{ (query.pageNumber - 1) * query.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!-- 账单编号 -->
        <el-table-column :label="$t('ClaimsManagement.BillNumber')" prop="claimsBillNumber" width="130" />
        <!-- 理赔单号 -->
        <el-table-column :label="$t('ClaimsManagement.ClaimNumber')" prop="claimsNumber" width="130" />
        <!-- 理赔客户 -->
        <el-table-column :label="$t('ClaimsManagement.ClaimsClient')" prop="customerName" width="130" />
        <!--  理赔申请金额-->
        <el-table-column :label="$t('ClaimsManagement.ClaimAmount')" prop="claimAmount" width="110" />
        <!-- 实际支付金额 -->
        <el-table-column :label="$t('ClaimsManagement.AmountActuallyPaid')" prop="actualClaimAmount" width="110" />
        <!-- 支付状态 -->
        <el-table-column :label="$t('ClaimsManagement.PaymentStatus')" prop="name" width="120">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.payedStatus === PAYSTATUS_STATUS[1].value" type="success">{{ PAYSTATUS_STATUS[1].name }}</el-tag>
            <el-tag v-else-if="scope.row.payedStatus === PAYSTATUS_STATUS[2].value" type="info">{{ PAYSTATUS_STATUS[2].name }}</el-tag>
            <el-tag v-else-if="scope.row.payedStatus === PAYSTATUS_STATUS[3].value">{{ PAYSTATUS_STATUS[3].name }}</el-tag>
            <span v-else />
          </template>
        </el-table-column>
        <!-- 支付时间 -->
        <el-table-column :label="$t('ClaimsManagement.TimeOfPayment')" prop="payedTime" width="170" />
        <!-- 支付方式 -->
        <el-table-column :label="$t('ClaimsManagement.PaymentMethod')" prop="payedTypeStr" width="100" />
        <!-- 支付账号 -->
        <el-table-column :label="$t('ClaimsManagement.PaymentAccountNumber')" prop="payAccount" />
        <!-- 附件 -->
        <el-table-column :label="$t('customerManagements.enclosure')" prop="attachmentUrl">
          <template slot-scope="scope">

            <el-link
              v-for="item of scope.row.attachmentUrl" :key="item.url" type="primary"
              :href="item.url" target="_blank"
            >{{ item.name }}</el-link>
          </template>
        </el-table-column>
        <!-- 备注 -->
        <el-table-column :label="$t('GlobalSetObj.remark')" prop="remarks" />
        <!-- 操作 -->
        <el-table-column
          fixed="right"
          :label="$t('GlobalSetObj.operate')"
          width="170"
        >
          <template slot-scope="scope">
            <!-- 付款 -->
            <el-button
              v-if="scope.row.payedStatus === PAYSTATUS_STATUS[2].value" size="small" type="success"
              @click="onOpenPayment(scope.row)"
            >{{ $t('ClaimsManagement.Payment') }}</el-button>
            <!-- 账单抵扣 -->
            <el-button
              v-if="scope.row.payedStatus === PAYSTATUS_STATUS[2].value" size="small" type="primary"
              @click="asyncApiClaimBillDeduction(scope.row.id)"
            >{{ $t('ClaimsManagement.BillCredit') }}</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="query.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="query.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getCurrentTableData"
          @size-change="getSizeTableData"
        />
      </div>
    </el-card>

    <el-dialog
      :title="$t('GlobalSetObj.tips')"
      :visible.sync="paymentRow.isBool"
      width="950px"
    >
      <PagamentosCmp v-if="paymentRow.isBool" :row="paymentRow.row" @close="onClose" />
    </el-dialog>

  </div>
</template>

<script>
import CustomerSelectCmp from '@/components/CustomerSelectCmp/index.vue';
import { PAYSTATUS_STATUS, PAYTYPE_LIST } from './utils.js';
import { apiClaimBillPage, apiClaimBillDeduction } from '@/api/claimsManagement/statement';
import dayjs from 'dayjs';

const firstDay = dayjs().startOf('month').format('YYYY-MM-DD') + ' 00:00:00';
const lastDay = dayjs().endOf('month').format('YYYY-MM-DD') + ' 23:59:59';
const patyedTimeList = [firstDay, lastDay];

export default {
  name: '',

  components: {
    CustomerSelectCmp,
    PagamentosCmp: () => import('./cmp/PagamentosCmp.vue')
  },

  data() {
    return {
      query: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          val1: '',
          // claimsNumber: '', // 理赔单号
          payedStatus: '', // 支付状态 1 已支付 2待付款 3 已关闭
          customerId: '', // 客户id
          patyedTimeList: '' // 支付时间段

        }
      },
      total: 0,
      type1: 'claimsBillNumber',

      tableData: [],

      // 付款
      paymentRow: {
        isBool: false,
        row: {
          id: 0,
          customerId: 0, // 应付客户
          customerName: 0, // 应付客户
          claimsNumber: '', // 理赔单号
          claimAmount: 0// 理赔申请金额
        }
      }
    };
  },

  created() {
    this.PAYSTATUS_STATUS = PAYSTATUS_STATUS;

    this.query.params.patyedTimeList = [...patyedTimeList];

    this.onReset();
  },

  methods: {

    getData() {
      const { pageNumber, pageSize, params } = this.query;
      const { val1, payedStatus, customerId, patyedTimeList } = params;

      const obj = {
        claimsNumber: undefined,
        claimsBillNumber: undefined,
        payedStatus,
        customerId,
        patyedTimeList
      };

      obj[this.type1] = val1;

      if ((patyedTimeList === '') || (Array.isArray(patyedTimeList).length === 0)) {
        obj.patyedTimeList = undefined;
      }

      return {
        pageNumber,
        pageSize,
        params: obj
      };
    },

    // 重置
    onReset() {
      this.query.pageNumber = 1;
      this.query.pageSize = 10;
      this.query.params.val1 = '';
      this.query.params.payedStatus = '';
      this.query.params.customerId = '';
      this.query.params.patyedTimeList = [...patyedTimeList];
      this.type1 = 'claimsBillNumber';

      this.getTableData();
    },

    // 页码变化翻页
    getCurrentTableData(val) {
      this.query.pageNumber = val;
      this.getTableData();
    },
    // 每页显示数量变化
    getSizeTableData(val) {
      this.query.pageNumber = 1;
      this.query.pageSize = val;
      this.getTableData();
    },

    // 获取列表数据
    getTableData() {
      const data = this.getData();

      apiClaimBillPage(data).then(res => {
        const { total, records } = res.data;
        this.total = total;
        this.tableData = (records || []).map(item => {
          const { id, claimsBillNumber, claimsNumber, customerId, customerName, claimAmount, actualClaimAmount, payedStatus, payedTime, payedType, payAccount, attachmentUrl, remarks } = item;

          let payedStatusStr = '';
          if (payedStatus) {
            payedStatusStr = PAYSTATUS_STATUS[payedStatus]?.name || '';
          }

          let payedTypeStr = '';
          if (payedType) {
            payedTypeStr = PAYTYPE_LIST[payedType]?.name || '';
          }

          let attachmentUrlArr = [];
          if (attachmentUrl) {
            attachmentUrlArr = attachmentUrl.split(',').map((item, index) => {
              return {
                name: 'file' + (index + 1),
                url: item
              };
            });
            // attachmentUrl.split(',');
          }

          const obj = {
            id,
            claimsBillNumber, // 账单编号
            claimsNumber, // 理赔单号
            customerId, // 理赔客户
            customerName, // 理赔客户
            claimAmount, // 理赔申请金额
            actualClaimAmount, // 实际支付金额
            payedStatus, // 支付状态
            payedStatusStr, // 支付状态
            payedTime, // 支付时间
            payedType, // 支付方式
            payedTypeStr, // 支付方式
            payAccount, // 支付账户
            attachmentUrl: attachmentUrlArr, // 附件
            remarks// 备注
          };

          return Object.freeze(obj);
        });
      }).catch(err => {
        console.error(err);
      });
    },

    // 打开
    onOpenPayment(row) {
      const { id, customerId, customerName, claimsNumber, claimAmount } = row;
      this.paymentRow.isBool = true;

      this.paymentRow.row.id = id;
      this.paymentRow.row.customerId = customerId;
      this.paymentRow.row.customerName = customerName;
      this.paymentRow.row.claimsNumber = claimsNumber;
      this.paymentRow.row.claimAmount = claimAmount;
    },

    // 关闭
    onClose(isBool) {
      this.paymentRow.isBool = false;
      isBool && this.onReset();
    },

    // 账单抵扣
    asyncApiClaimBillDeduction(id) {
      // apiClaimBillDeduction
      this.$confirm('确认操作?', this.$t('GlobalSetObj.tips'), {
        confirmButtonText: this.$t('GlobalSetObj.determine'),
        cancelButtonText: this.$t('GlobalSetObj.cancel'),
        type: 'warning'
      }).then(() => {
        return apiClaimBillDeduction({ id });
      }).then(res => {
        // 操作成功
        this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
        this.getTableData();
      }).catch(() => {
        console.error('取消操作');
      });
    }
  }

};
</script>

<style  scoped>
.box /deep/ .el-select .el-input {
  width: 110px;
}

.input-with-select /deep/ .el-input-group__prepend {
  background-color: #fff;
}
</style>
