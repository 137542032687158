<script >
import { defineProps, ref } from '@vue/composition-api';
import { useVuelidate } from '@vuelidate/core';
import { FormActions, FormActionsNext, FormActionsPrevious } from '@/components/wizard-form/formStepActions';
import { required, minLength, maxLength, numeric, email } from '@/utils/validationErrors';
import { companyService } from '@/api/transfer/company';
const __sfc_main = {};
__sfc_main.props = {
  formData: {
    type: Object
  },
  isLastStep: {
    type: Boolean,
    required: true
  },
  isFirstStep: {
    type: Boolean,
    required: true
  },
  handlePrevious: {
    type: Function,
    default: () => {}
  },
  handleNext: {
    type: Function,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const rules = {
    companyName: {
      required
    },
    bank: {
      required
    },
    financialPhone: {
      minLength: minLength(18),
      maxLength: maxLength(19)
    },
    agency: {
      required,
      numeric,
      minLength: minLength(3),
      maxLength: maxLength(4)
    },
    account: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(13)
    },
    beneficiary: {
      required
    },
    financialContact: {
      required
    },
    financialEmail: {
      email
    }
  };
  const v$ = useVuelidate(rules, props.formData);
  async function handleSubmit() {
    const isValid = await v$.value.$validate();
    if (isValid) {
      props.handleNext();
    }
  }
  const listCompany = ref([]);
  async function filteredCompany() {
    try {
      const res = await companyService.getAll(0, 10, {
        name: props.formData.companyName
      });
      listCompany.value = res.data.result.map(company => {
        return {
          name: company.shipping_company_name,
          id: company.id
        };
      });
    } catch (error) {
      console.log(error);
      listCompany.value = [];
    }
  }
  function selectedCompany(value) {
    props.formData.companyId = value ? value.id : '';
  }
  return {
    v$,
    handleSubmit,
    listCompany,
    filteredCompany,
    selectedCompany
  };
};
__sfc_main.components = Object.assign({
  FormActions,
  FormActionsPrevious,
  FormActionsNext
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <form class="wrapperForm" @submit.prevent="handleSubmit">
    <o-field
      :label="$t('bank.companyName')"
      label-for="company"
      :message="v$.companyName.$errors.length ? v$.companyName.$errors[0].$message : null"
    >
      <o-autocomplete
        id="company"
        v-model="formData.companyName"
        class="col-2"
        :placeholder="$t('bank.companyNamePlaceholder')"
        clearable
        expanded
        field="name"
        :data="listCompany"
        @input="filteredCompany"
        @select="selectedCompany"
      >
        <template slot="empty">{{ $t('bank.noResults') }}</template>
      </o-autocomplete>
    </o-field>

    <o-field
      :label="$t('bank.bank')"
      label-for="bank"
      :message="v$.bank.$errors.length ? v$.bank.$errors[0].$message : null"
    >
      <o-input id="bank" v-model="formData.bank" :placeholder="$t('bank.bankPlaceholder')" />
    </o-field>

    <o-field
      v-mask="'####'"
      :label="$t('bank.agency')"
      label-for="agency"
      :message="v$.agency.$errors.length ? v$.agency.$errors[0].$message : null"
    >
      <o-input id="agency" v-model="formData.agency" :placeholder="$t('bank.agencyPlaceholder')" />
    </o-field>

    <o-field
      :label="$t('bank.account')"
      label-for="account"
      :message="v$.account.$errors.length ? v$.account.$errors[0].$message : null"
    >
      <o-input id="account" v-model="formData.account" :placeholder="$t('bank.accountPlaceholder')" />
    </o-field>

    <o-field
      :label="$t('bank.companyBeneficiary')"
      label-for="beneficiary"
      :message="v$.beneficiary.$errors.length ? v$.beneficiary.$errors[0].$message : null"
    >
      <o-input id="beneficiary" v-model="formData.beneficiary" :placeholder="$t('bank.beneficiaryPlaceholder')" />
    </o-field>

    <o-field
      :label="$t('bank.contact')"
      label-for="financialContact"
      :message="v$.financialContact.$errors.length ? v$.financialContact.$errors[0].$message : null"
    >
      <o-input id="financialContact" v-model="formData.financialContact" :placeholder="$t('bank.contactPlaceholder')" />
    </o-field>

    <o-field
      :label="$t('bank.email')"
      label-for="financialEmail"
      :message="v$.financialEmail.$errors.length ? v$.financialEmail.$errors[0].$message : null"
    >
      <o-input id="financialEmail" v-model="formData.financialEmail" :placeholder="$t('bank.emailPlaceholder')" />
    </o-field>

    <o-field
      v-mask="['+## (##) ####-####', '+## (##) #####-####']"
      :label="$t('bank.phone')"
      label-for="financialPhone"
      :message="v$.financialPhone.$errors.length ? v$.financialPhone.$errors[0].$message : null"
    >
      <o-input id="financialPhone" v-model="formData.financialPhone" :placeholder="$t('bank.phonePlaceholder')" />
    </o-field>

    <FormActions>
      <FormActionsPrevious
        :is-first-step="isFirstStep"
        @handlePrevious="handlePrevious"
      />

      <FormActionsNext :is-last-step="isLastStep" />
    </FormActions>
  </form>
</template>

<style scoped>
.wrapperForm {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem/* 16px */;
  padding-top: 0.5rem/* 8px */;
  padding-bottom: 0.5rem/* 8px */;
}

.col-2 {
  grid-column: span 2 / span 2;
}
</style>
