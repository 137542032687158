<template>
  <div class="app-container">
    <el-form label-width="80px" label-position="top">
      <el-row :gutter="10">
        <el-col :span="8">
          <!-- 执行时间 -->
          <el-form-item :label="$t('Resource.执行时间')">
            <el-date-picker
              v-model="queryForm.params.time"
              type="datetimerange"
              range-separator="-"
              :default-time="['00:00:00', '23:59:59']"
              :start-placeholder="$t('GlobalSetObj.startDate')"
              :end-placeholder="$t('GlobalSetObj.endDate')"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <!-- 运单号 -->
          <el-form-item :label="$t('GlobalSetObj.waybillNumber')">
            <!-- <el-input v-model="queryForm.params.val" placeholder="请输入内容" /> -->
            <InputTextareaCmp
              ref="inputTextareaRef"
              :val="queryForm.params.waybillNumbers"
              :max-length="200"
              @input="(_, val) => queryForm.params.waybillNumbers = val"
            />

          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div>

      <el-button
        type="primary" size="small" icon="el-icon-search"
        @click="asyncGetTableData"
      >{{ $t('newOrder.search') }}</el-button>
      <el-button
        v-permit:remove="'but:system-manage:trajectoryStatusMonitoring:export'"
        icon="el-icon-refresh"
        size="mini"
        @click="onReset"
      >{{ $t('basicData.reset') }}
      </el-button>

    </div>

    <el-divider />

    <el-card>
      <div class="operation">
        <div />
        <!-- 导出 -->
        <el-button
          size="small"
          type="primary"
          icon="el-icon-download"
          @click="asyncExport"
        >{{ $t('newOrder.export') }}</el-button>
      </div>

      <el-table
        :data="tableData"
        border
        style="width: 100%"
        class="table-height"
        :height="tableHeightRow.height"
      >
        <el-table-column :label="$t('operationCenter.ind')" width="80px" align="center">
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!-- 执行时间 -->
        <el-table-column
          prop="createTime"
          :label="$t('Resource.执行时间')"
          width="180"
        />

        <!-- 运单号 -->
        <el-table-column
          prop="waybillNumber"
          :label="$t('GlobalSetObj.waybillNumber')"
          width="180"
        />

        <!-- 最新状态时间 -->
        <el-table-column
          prop="lastStatusTime"
          :label="$t('orderCenterCont.latestStatusTime')"
          width="180"
        />

        <!-- 运单状态 -->
        <el-table-column
          prop="waybillStatusStr"
          :label="$t('newOrder.waybillStatus')"
          width="180"
        />

        <!-- 最新轨迹时间 -->
        <el-table-column
          prop="lastTrackTime"
          :label="$t('newOrder.LatestTime')"
          width="180"
        />

        <!-- 最新轨迹内容 -->
        <el-table-column
          prop="lastTrackDescribe"
          :label="$t('orderCenterCont.zuixinguijineir')"
        />
      </el-table>

      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="asyncGetTableData"
          @size-change="asyncGetTableData"
        />
      </div>

    </el-card>
  </div>
</template>

<script>
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import InputTextareaCmp from '@/views/finance/cmp/Query/InputTextareaCmp.vue';
import dayjs from 'dayjs';
import { apiReportTrackStateSyncMonitorPage, apiReportTrackStateSyncMonitorExport } from '@/api/resource/trajectoryStatusMonitoring.js';
import { WAYBILL_STATUS } from './enums.js';
import { goExport } from '@/utils/goExportList.js';

// 默认开始时间
const startTim = dayjs().subtract(30, 'day').format('YYYY-MM-DD') + ' 00:00:00';
// 默认结束时间
const endTim = dayjs().format('YYYY-MM-DD') + ' 23:59:59';
export default {
  name: '',

  components: {
    InputTextareaCmp
  },

  mixins: [estimateTableHeight],

  data() {
    return {
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          time: [], // 执行时间
          waybillNumbers: '' // 运单号
        }
      },
      total: 0,
      tableData: []
    };
  },

  created() {
    this.queryForm.params.time = [startTim, endTim];

    setTimeout(() => {
      this.asyncGetTableData();
    }, 100);
  },

  methods: {

    // 设置请求参数
    setQuery() {
      const { pageNumber, pageSize, params } = this.queryForm;

      const row = {
        pageNumber,
        pageSize,
        params: {
          createStartTime: '', // 执行开始时间
          createEndTime: '', // 执行结束时间
          waybillNumbers: [] // 运单号
        }
      };

      // 单号处理
      row.params.waybillNumbers = this.$refs.inputTextareaRef.getValue();

      // 时间处理
      if (Array.isArray(params.time) && params.time.length === 2) {
        row.params.createStartTime = params.time[0];
        row.params.createEndTime = params.time[1];
      }

      return row;
    },

    // 重置
    onReset() {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = 10;
      this.queryForm.params.time = [startTim, endTim];
      this.queryForm.params.waybillNumbers = '';

      // 照顾多行文本（watch 监听）值异步更新的问题
      setTimeout(() => {
        this.asyncGetTableData();
      }, 100);
    },

    // 获取列表
    asyncGetTableData() {
      const row = this.setQuery();
      apiReportTrackStateSyncMonitorPage(row).then(res => {
        console.log(res);

        const { records, total } = res.data;
        this.total = total;
        this.tableData = Object.freeze((records || []).map(item => {
          const { id, createTime, waybillNumber, waybillStatus, lastStatusTime, lastTrackTime, lastTrackDescribe } = item;

          // 运单状态
          let waybillStatusStr = '';
          {
            const row = WAYBILL_STATUS.find(item => item.code === waybillStatus);
            if (row) {
              waybillStatusStr = row.label;
            }
          }

          const obj = {
            id,
            createTime, // 执行时间
            waybillNumber, // 运单号
            waybillStatus, // 运单状态
            lastStatusTime, // 最新状态时间
            waybillStatusStr, // 运单状态
            lastTrackTime, // 最新轨迹时间
            lastTrackDescribe// 最新轨迹内容
          };

          return obj;
        }));
      }).catch(err => {
        console.error(err);
      });
    },

    // 导出
    asyncExport() {
      const { params } = this.setQuery();
      apiReportTrackStateSyncMonitorExport(params).then(res => {
        // console.log(res);
        goExport(this, res.data);
      }).catch(err => {
        console.error(err);
      });
    }
  }

};
</script>

<style scoped>
.operation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
