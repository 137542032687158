const state = {
  visitedViews: [],
  cachedViews: [],
  ScanBaggings: 0
};

const mutations = {
  ScanBaggings: (state, view) => {
    state.ScanBaggings = view;
  },
  ADD_VISITED_VIEW: (state, view) => {
    if (state.visitedViews.some(v => v.path === view.path)) return;
    state.visitedViews.push(
      Object.assign({}, view, {
        title: view.meta.title || 'no-name'
      })
    );
  },
  ADD_CACHED_VIEW: (state, view) => {
    // if (state.cachedViews.includes(view.name)) return;
    // if (!view.meta.noCache) {
    //   state.cachedViews.push(view.name);
    // }
    if (state.cachedViews.includes(view.name)) return;
    const { matched, meta, name } = view;
    if (!meta.noCache) {
      if (matched.length > 2) {
        // 路由层级大于2时，将父级也加入cachedViews
        for (let i = 1; i < matched.length; i++) {
          if (!state.cachedViews.includes(matched[i].name)) {
            state.cachedViews.push(matched[i].name);
          }
        }
      } else {
        state.cachedViews.push(name);
      }
    }
  },

  DEL_VISITED_VIEW: (state, view) => {
    for (const [i, v] of state.visitedViews.entries()) {
      if (v.path === view.path) {
        state.visitedViews.splice(i, 1);
        break;
      }
    }
  },
  DEL_CACHED_VIEW: (state, view) => {
    // const index = state.cachedViews.indexOf(view.name);
    // index > -1 && state.cachedViews.splice(index, 1);
    const index = state.cachedViews.indexOf(view.name);
    index > -1 && state.cachedViews.splice(index, 1);
    if (view.matched.length > 2) {
      // 路由层级大于2，检查并移除没有子页面缓存的上级路由
      view.matched.every(() => {
        const originLength = state.cachedViews.length;
        const inuseCacheNames = state.visitedViews.reduce(
          (inuseCacheNames, { matched }) => inuseCacheNames.concat(matched.map(({ name }) => name)),
          []
        );
        state.cachedViews = state.cachedViews.filter((E) => inuseCacheNames.includes(E));
        return originLength === state.cachedViews.length;
      });
    }
  },

  DEL_OTHERS_VISITED_VIEWS: (state, view) => {
    state.visitedViews = state.visitedViews.filter(v => {
      return v.meta.affix || v.path === view.path;
    });
  },
  DEL_OTHERS_CACHED_VIEWS: (state, view) => {
    const index = state.cachedViews.indexOf(view.name);
    if (index > -1) {
      state.cachedViews = state.cachedViews.slice(index, index + 1);
    } else {
      // if index = -1, there is no cached tags
      state.cachedViews = [];
    }
  },

  DEL_ALL_VISITED_VIEWS: state => {
    // keep affix tags
    state.visitedViews = state.visitedViews.filter(tag => tag.meta.affix);
  },
  DEL_ALL_CACHED_VIEWS: state => {
    state.cachedViews = [];
  },
  // 更新路由缓存
  UP_VIEWS_CACHED_LIST: (stata, views) => {
    // 判断当前缓存中思否存在三级路由，存在三级路由时面包屑不会存在三级路由的Name，所以需要重新加进来
    if (state.cachedViews.includes('LayoutThree')) {
      const Views = views.map((item) => {
        return item.name;
      });
      state.cachedViews = [...Views, 'LayoutThree'];
    } else {
      state.cachedViews = views.map((item) => {
        return item.name;
      });
    }
  },
  UPDATE_VISITED_VIEW: (state, view) => {
    for (let v of state.visitedViews) {
      if (v.path === view.path) {
        v = Object.assign(v, view);
        break;
      }
    }
  }
};

const actions = {
  scannerBagg({ commit }, view) {
    commit('ScanBaggings', view);
  },
  addView({ dispatch }, view) {
    dispatch('addVisitedView', view);
    dispatch('addCachedView', view);
  },
  addVisitedView({ commit }, view) {
    commit('ADD_VISITED_VIEW', view);
  },
  addCachedView({ commit }, view) {
    commit('ADD_CACHED_VIEW', view);
  },

  delView({ dispatch, state }, view) {
    return new Promise(resolve => {
      dispatch('delVisitedView', view);
      dispatch('delCachedView', view);
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews]
      });
    });
  },
  delVisitedView({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_VISITED_VIEW', view);
      resolve([...state.visitedViews]);
    });
  },
  delCachedView({ commit, state }, view) {
    return new Promise(resolve => {
      // commit('DEL_CACHED_VIEW', view);
      resolve([...state.cachedViews]);
    });
  },

  delOthersViews({ dispatch, state }, view) {
    return new Promise(resolve => {
      dispatch('delOthersVisitedViews', view);
      dispatch('delOthersCachedViews', view);
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews]
      });
    });
  },
  delOthersVisitedViews({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_OTHERS_VISITED_VIEWS', view);
      resolve([...state.visitedViews]);
    });
  },
  delOthersCachedViews({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_OTHERS_CACHED_VIEWS', view);
      resolve([...state.cachedViews]);
    });
  },

  delAllViews({ dispatch, state }, view) {
    return new Promise(resolve => {
      dispatch('delAllVisitedViews', view);
      dispatch('delAllCachedViews', view);
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews]
      });
    });
  },
  delAllVisitedViews({ commit, state }) {
    return new Promise(resolve => {
      commit('DEL_ALL_VISITED_VIEWS');
      resolve([...state.visitedViews]);
    });
  },
  delAllCachedViews({ commit, state }) {
    return new Promise(resolve => {
      commit('DEL_ALL_CACHED_VIEWS');
      resolve([...state.cachedViews]);
    });
  },

  updateVisitedView({ commit }, view) {
    commit('UPDATE_VISITED_VIEW', view);
  },
  upDataViewCachedList({ commit }, views) {
    commit('UP_VIEWS_CACHED_LIST', views);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
