<template>
  <el-form
    ref="ruleForm"
    :model="formData"
    label-width="0"
    class="demo-ruleForm"
    size="small"
    :rules="rules"
  >
    <el-table
      :data="formData.arr"
      border
    >
      <!-- 距离开始(KM) 不包含 -->
      <el-table-column
        prop="startWeight"
        width="200"
      >
        <template slot="header">
          <div>
            <span>{{ $t('basicData.KilometersStartKM') }}</span>
            <span style="color: red;">*</span>
          </div>
        </template>

        <template slot-scope="scope">
          <el-form-item
            :prop="`arr.${scope.$index}.startWeight`"
            :rules="{
              required: true,
              trigger: 'blur',
              validator:(rule, value, callback)=>startCheck(rule, value, callback, scope.row)
            }"
          >
            <el-input-number
              v-model="scope.row.startWeight" :precision="2" :min="0"
              :max="999999999" :controls="false" :placeholder="$t('GlobalSetObj.pleaseInput')"
            />
          </el-form-item>
        </template>
      </el-table-column>

      <!-- 距离结束(KM) 包含 -->
      <el-table-column
        prop="endWeight"
        width="200"
      >
        <template slot="header">
          <div>
            <span>{{ $t('basicData.MileageEndKM') }}</span>
            <span style="color: red;">*</span>
          </div>
        </template>

        <template slot-scope="scope">
          <el-form-item
            :prop="`arr.${scope.$index}.endWeight`"
            :rules="{
              required: true,
              trigger: 'blur',
              validator:(rule, value, callback)=>startCheck(rule, value, callback, scope.row)
            }"
          >
            <el-input-number
              v-model="scope.row.endWeight" :precision="2" :min="0"
              :max="999999999" :controls="false" :placeholder="$t('GlobalSetObj.pleaseInput')"
            />
          </el-form-item>
        </template>
      </el-table-column>

      <!-- 安俊小屋基础价格PT014 -->
      <el-table-column
        prop="price"
        min-width="200"
      >
        <template slot="header">
          <div>
            <span>{{ $t('collectionCenter.hut') }}{{ $t('collectionCenter.turnTransport') }}{{ $t('basicData.BasePrice') }}(PT014)</span>
            <span style="color: red;">*</span>
          </div>
        </template>

        <template slot-scope="scope">
          <el-form-item
            :prop="`arr.${scope.$index}.price`"
            :rules="rules.price"
          >
            <el-input-number
              v-model="scope.row.price" :precision="3" :min="0"
              :max="999999999" :controls="false" :placeholder="$t('GlobalSetObj.pleaseInput')"
            />&nbsp;&nbsp;{{ $t('orderCenterCont.days') }}
          </el-form-item>
        </template>
      </el-table-column>

      <!-- 安俊小屋转运商家提成PT015 -->
      <el-table-column
        prop="overshipmentPrice"
        min-width="200"
      >
        <template slot="header">
          <div>
            <span>{{ $t('collectionCenter.hut') }}{{ $t('collectionCenter.turnTransport') }}{{ $t('basicData.商家提成') }}(PT015)</span>
            <span style="color: red;">*</span>
          </div>
        </template>

        <template slot-scope="scope">
          <el-form-item
            :prop="`arr.${scope.$index}.overshipmentPrice`"
            :rules="rules.overshipmentPrice"
          >
            <el-input-number
              v-model="scope.row.overshipmentPrice" :precision="3" :min="0"
              :max="999999999" :controls="false" :placeholder="$t('GlobalSetObj.pleaseInput')"
            />&nbsp;&nbsp;/{{ $t('basicData.个') }}
          </el-form-item>
        </template>
      </el-table-column>

      <!-- 安俊小屋转运包裹价格PT016 -->
      <el-table-column
        prop="price"
        min-width="200"
      >
        <template slot="header">
          <div>
            <span>{{ $t('collectionCenter.hut') }}{{ $t('collectionCenter.turnTransport') }}{{ $t('basicData.包裹价格') }}(PT016)</span>
            <span style="color: red;">*</span>
          </div>
        </template>

        <template slot-scope="scope">
          <el-form-item
            :prop="`arr.${scope.$index}.standardPrice`"
            :rules="rules.standardPrice"
          >
            <el-input-number
              v-model="scope.row.standardPrice" :precision="3" :min="0"
              :max="999999999" :controls="false" :placeholder="$t('GlobalSetObj.pleaseInput')"
            />&nbsp;&nbsp;/{{ $t('collectionCenter.ticket') }}
          </el-form-item>
        </template>
      </el-table-column>

      <!-- 最低存货 -->
      <el-table-column min-width="200" >
        <template slot="header">
          <div>
            <span>{{ $t('basicData.最低货量') }}</span>
          </div>
        </template>

        <template slot-scope="scope">
          <el-form-item>
            <el-input-number
              v-model="scope.row.overWeightExtraPrice" :precision="0" :min="0"
              :max="999999999" :controls="false" :placeholder="$t('GlobalSetObj.pleaseInput')"
            />&nbsp;&nbsp;{{ $t('collectionCenter.ticket') }}
          </el-form-item>
        </template>
      </el-table-column>

      <el-table-column :label="$t('basicData.operate')" fixed="right" width="100">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="danger"
            @click="handleDel(scope.$index)"
          >{{ $t('basicData.Delete') }}</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-form>
</template>

<script>
import { cloneDeep } from 'lodash-es';
import { weightCheck, isOverLap } from './utils';

/** @typedef {import('./types').TTableRow} TTableRow */

export default {
  name: '',

  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  },

  data() {
    // 校验 起始/结束公里数
    const startCheck = (rule, value, callback, row) => {
      const { startWeight, endWeight } = row;

      // 开始 结束校验
      const [isBool, str] = weightCheck(startWeight, endWeight);
      if (!isBool) {
        return callback(new Error(str));
      }

      // 同一分区下所有的 开始与结束不能重叠
      {
        // console.log(this.formData.arr);

        const isBool = isOverLap(this.formData.arr);
        // console.log(isBool);

        if (isBool) {
          return callback(new Error(this.$i18n.t('basicData.区间不能重叠')));
        }
      }

      callback();
    };

    return {
      formData: {
        /** @type {TTableRow[]} */
        arr: []
      },

      rules: {
        // distanceModel: [
        //   { required: true, validator: distanceModelCheck, trigger: 'change' }
        // ],
        price: [
          { required: true, message: this.$i18n.t('GlobalSetObj.pleaseInput'), trigger: 'blur' }
        ],

        overshipmentPrice: [
          { required: true, message: this.$i18n.t('GlobalSetObj.pleaseInput'), trigger: 'blur' }
        ],
        standardPrice: [
          { required: true, message: this.$i18n.t('GlobalSetObj.pleaseInput'), trigger: 'blur' }
        ]
      },

      startCheck
    };
  },

  watch: {
    'tableData': {
      handler(val, oldVal) {
        // console.log('子组件监听', JSON.stringify(val), JSON.stringify(oldVal));
        if (oldVal === undefined) {
          // 第一次赋值
          this.formData.arr = [];
          return;
        }

        if (val.length === 0 && oldVal.length === 0) {
          return;
        }

        // 新的值与旧的值一样
        if (JSON.stringify(val) === JSON.stringify(this.formData.arr)) {
          // console.log('新的值与旧的值一样');
          return;
        }
        // console.log(123987, val);
        this.formData.arr = cloneDeep(this.tableData);
        // this.formData.arr.push(val);
      },
      immediate: true
    },

    // 将数据传给父组件
    formData: {

      handler(val, oldVal) {
        // console.log('子组件提交');
        if (!oldVal || !val) {
          return;
        }
        // console.log('父元素提交', val.arr);
        this.$emit('change', val.arr);
      },
      deep: true
      // immediate: true
    }
  },

  created() {

  },

  methods: {
    // 提交校验
    submitForm() {
      return this.$refs['ruleForm'].validate().then(() => {
        return true;
      }).catch(res => {
        return false;
      });
    },

    // 删除
    handleDel(index) {
      this.formData.arr.splice(index, 1);
    }
  }

};
</script>

<style  scoped>
.demo-ruleForm /deep/ .el-form-item__error {
  position: static !important;
}
</style>
