<template>
  <div class="app-container">
    <el-card style="margin-bottom: 20px;">
      <el-row :gutter="10">
        <el-col :span="13">
          <el-form
            :model="scanForm"
            label-position="right"
            label-width="auto"
            style="margin-top: 10px;"
            @submit.native.prevent
          >
            <el-form-item
              :label="$t('GlobalSetObj.bagNumber')"
              prop="scanNumber"
            >
              <el-input v-model="scanForm.scanNumber" style="width: 60%;" @keyup.enter.native="scanningLoading" />
              <!--配载保存-->
              <el-button type="warning" style="margin-left: 20px;" @click="stowagePreservation">{{ $t('operationCenter.stowageSave') }}</el-button>
              <div style="line-height: 24px;color: #f00;">{{ errorMessage }}</div>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="11">
          <div class="form-el-form-item">
            <el-form label-width="auto" label-position="right">
              <el-form-item
                :label="$t('GlobalSetObj.fuwushang') + '：'"
              >
                <span>{{ serviceName }}</span>
              </el-form-item>
              <el-form-item
                :label="$t('GlobalSetObj.DeliveryPoint') + '：'"
              >
                <span>{{ deliveryPoint }}</span>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-card>
      <el-row :gutter="10">
        <el-col :span="14">
          <div class="tableContent">
            <div style="margin-bottom: 10px;">
              <!--当前配载-->
              <span style="margin-right: 10px;">{{ $t('operationCenter.CurrentStowage') }}</span>
              <!--大包数-->
              <span style="margin-right: 10px;font-size: 14px">{{ $t('operationCenter.largePacketNumber') }}：{{ largePacketNumber }}</span>
              <!--总重-->
              <span style="margin-right: 10px;font-size: 14px">{{ $t('trajectory.TotalWeight') }}(KG)：{{ totalWeight.toFixed(3) }}</span>
              <!--总小包数-->
              <span style="margin-right: 10px;font-size: 14px">{{ $t('operationCenter.totalNumberOfPackets') }}：{{ totalNumberOfPackets }}</span>
            </div>
            <div>
              <el-table border :data="tableLeft">
                <!--总单号-->
                <el-table-column :label="$t('GlobalSetObj.totalSingNumber')" prop="bagManifestNo" />
                <!--袋号-->
                <el-table-column :label="$t('GlobalSetObj.bagNumber')" prop="bigBagNo" />
                <!--重量（KG）-->
                <el-table-column :label="$t('GlobalSetObj.weight')" prop="bagWeight" />
                <!--封袋操作人-->
                <el-table-column :label="$t('operationCenter.BagSealingOperator')" prop="operator" />
                <!--操作-->
                <el-table-column :label="$t('operationCenter.operate')" align="center" width="120">
                  <!--移出-->
                  <template slot-scope="scope">
                    <span style="color: red;cursor: pointer;padding: 3px 10px;" @click="deleteItem(scope)">{{ $t('GlobalSetObj.MoveOut') }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-col>
        <el-col :span="10">
          <!--未完结总单列表-->
          <div class="tableContent">
            <div style="display: inline-block;width: 50%;margin-bottom: 10px;">{{ $t('operationCenter.ListOutstandingOrders') }}</div>
            <div style="display: inline-block;width: 50%;padding-left: 20px;">
              <el-input
                v-model="queryFormRight.params.instoreBigBagNo"
                size="small"
                :placeholder="$t('GlobalSetObj.enterTrackingNumber')"
                @keyup.enter.native="searchRightData"
              >
                <el-button slot="append" icon="el-icon-search" @click="searchRightData" />
              </el-input>
            </div>
            <div>
              <el-table border :data="tableRight">
                <!--总单号-->
                <el-table-column :label="$t('GlobalSetObj.totalSingNumber')" prop="instoreBigBagNo" />
                <!--服务商-->
                <el-table-column :label="$t('GlobalSetObj.fuwushang')" prop="systemName" />
                <!--网点-->
                <el-table-column :label="$t('GlobalSetObj.Outlets')" prop="deliveryStationName" />
                <!--操作-->
                <el-table-column :label="$t('operationCenter.operate')" align="center">
                  <!--配载-->
                  <template slot-scope="scope">
                    <span style="color: #409EFF;cursor: pointer;padding: 3px 10px;" @click="stowageItem(scope.row)">{{ $t('operationCenter.AllocationOnboarding') }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="pagination">
              <el-pagination
                layout="total, prev, pager, next, jumper"
                :current-page.sync="queryFormRight.pageNumber"
                :page-size.sync="queryFormRight.pageSize"
                :page-sizes="[10, 20, 50, 100]"
                :total="total"
                small
                background
                style="margin: 10px 0"
                @current-change="getCurrentPage"
                @size-change="getSizePage"
              />
            </div>
          </div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { apiAddBag, apiComplete, apiRemoveBag, lagePackgePage, selectBigBagNumber } from '@/api/storeAdmin';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { getUserName } from '@/utils/asyncTools';
export default {
  name: 'TotalSingleStowage',
  data() {
    return {
      scanForm: {
        scanNumber: ''
      },
      serviceName: '', // 服务商
      deliveryPoint: '', // 派送网点
      errorMessage: '', // 错误提示
      largePacketNumber: 0, // 大包数
      totalWeight: 0, // 总重
      totalNumberOfPackets: 0,
      tableLeft: [],
      tableRight: [],
      queryFormRight: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          instoreBigBagNo: '',
          assemblyUser: this.$store.state.user.id,
          assemblyStatus: 1
        }
      },
      total: 0,
      bagManifestNo: '',
      userIdAndNameObj: null
    };
  },
  created() {
    this.userIdAndNameObj = getUserIdAndNameObj();
    this.searchRightData();
  },
  methods: {
    // 配载保存
    stowagePreservation() {
      this.$confirm(this.$t('operationCenter.withStowage'), this.$t('GlobalSetObj.tips'), {
        confirmButtonText: this.$t('GlobalSetObj.determine'), // '确定',
        cancelButtonText: this.$t('orderCenterCont.cancel'), // '取消',
        type: 'warning'
      }).then(() => {
        if (this.tableLeft.length > 0) {
          apiComplete(this.bagManifestNo).then(res => {
            if (res.status === 'OK') {
              this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
              this.bagManifestNo = '';
              this.serviceName = '';
              this.deliveryPoint = '';
              this.tableLeft = [];
              this.largePacketNumber = 0;
              this.totalWeight = 0;
              this.totalNumberOfPackets = 0;
              this.searchRightData();
            }
          });
        } else {
          this.$message.error(this.$t('orderCenterCont.theNumberOfLargePackagesIs0'));
        }
      });
    },
    // 扫描袋号
    scanningLoading() {
      const param = {
        bagNumber: this.scanForm.scanNumber,
        bagManifestNo: this.bagManifestNo
      };
      apiAddBag(param).then(res => {
        if (res.status === 'OK') {
          this.scanForm.scanNumber = '';
          this.errorMessage = '';
          if (this.serviceName === '') {
            this.searchRightData();
          }
          const obj = {
            bagManifestNo: res.data.bagManifestNo,
            bagWeight: res.data.bagWeight,
            bigBagNo: res.data.bigBagNo,
            packageCount: res.data.packageCount,
            packageTime: res.data.packageTime,
            packageUserId: res.data.packageUserId,
            operator: getUserName(res.data.packageUserId, this.userIdAndNameObj),
            preDeliverStationName: res.data.preDeliverStationName,
            systemName: res.data.systemName
          };
          this.tableLeft.unshift(obj);
          this.largePacketNumber = this.tableLeft.length;
          this.totalWeight = 0;
          this.totalNumberOfPackets = 0;
          this.tableLeft.map(item => {
            this.totalWeight = this.totalWeight + item.bagWeight;
            this.totalNumberOfPackets = this.totalNumberOfPackets + item.packageCount;
          });
          if (!this.bagManifestNo) {
            this.$nextTick(() => {
              this.bagManifestNo = res.data.bagManifestNo;
              this.serviceName = res.data.systemName;
              this.deliveryPoint = res.data.preDeliverStationName;
            });
          }
        } else {
          this.errorMessage = res.msg;
        }
      }).catch((err) => {
        this.errorMessage = err.msg;
      });
    },
    // 获取右边未完结总单列表
    getRightTableData() {
      const param = {
        pageSize: this.queryFormRight.pageSize,
        pageNumber: this.queryFormRight.pageNumber,
        params: {
          instoreBigBagNos: [],
          assemblyUser: this.$store.state.user.id,
          assemblyStatus: 1
        }
      };
      if (this.queryFormRight.params.instoreBigBagNo) {
        param.params.instoreBigBagNos = [this.queryFormRight.params.instoreBigBagNo];
      }
      lagePackgePage(param).then(res => {
        if (res.status === 'OK') {
          this.total = res.data.total;
          this.tableRight = res.data.records;
        }
      });
    },
    // 右侧列表页码切换
    getCurrentPage(val) {
      this.queryFormRight.pageNumber = val;
      this.getRightTableData();
    },
    // 右侧列表每页显示数量切换
    getSizePage(val) {
      this.queryFormRight.pageNumber = 1;
      this.queryFormRight.pageSize = val;
      this.getRightTableData();
    },
    // 移出
    deleteItem(scope) {
      this.$confirm(this.$t('GlobalSetObj.AreYouSureDelete'), this.$t('GlobalSetObj.tips'), {
        confirmButtonText: this.$t('GlobalSetObj.determine'), // '确定',
        cancelButtonText: this.$t('orderCenterCont.cancel'), // '取消',
        type: 'warning'
      }).then(() => {
        const data = {
          bagManifestNo: scope.row.bagManifestNo,
          bagNumber: scope.row.bigBagNo
        };
        apiRemoveBag(data).then(res => {
          if (res.status === 'OK') {
            this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
            this.tableLeft.splice(scope.$index, 1);
            this.largePacketNumber = this.tableLeft.length;
            this.totalWeight = 0;
            this.totalNumberOfPackets = 0;
            this.tableLeft.map(item => {
              this.totalWeight = this.totalWeight + item.bagWeight;
              this.totalNumberOfPackets = this.totalNumberOfPackets + item.packageCount;
            });
          }
        });
      });
    },
    // 配载
    stowageItem(row) {
      const data = {
        pageSize: 9999,
        pageNumber: 1,
        params: {
          instoreBigBagNo: row.instoreBigBagNo
        }
      };
      selectBigBagNumber(data).then(res => {
        if (res.status === 'OK') {
          this.tableLeft = [];
          this.bagManifestNo = res.data.instoreBigBagNo;
          this.serviceName = res.data.systemName;
          this.deliveryPoint = res.data.deliveryStationName;
          this.totalWeight = 0;
          this.totalNumberOfPackets = 0;
          this.largePacketNumber = 0;
          if (res.data.transitWaybillBagBOPage.records && res.data.transitWaybillBagBOPage.records.length > 0) {
            res.data.transitWaybillBagBOPage.records.map(item => {
              const obj = {
                bagManifestNo: item.instoreBigBagNo,
                bigBagNo: item.transitBagNumber,
                bagWeight: item.totalWeight,
                operator: getUserName(item.packageUserId, this.userIdAndNameObj),
                ...item
              };
              this.tableLeft.push(obj);
            });
            this.largePacketNumber = this.tableLeft.length;
            this.tableLeft.map(item => {
              this.totalWeight = this.totalWeight + item.bagWeight;
              this.totalNumberOfPackets = this.totalNumberOfPackets + item.packageCount;
            });
          }
        }
      });
    },
    // 右侧搜索
    searchRightData() {
      this.queryFormRight.pageNumber = 1;
      this.getRightTableData();
    }
  }
};
</script>

<style scoped>
.form-el-form-item .el-form-item {
  margin-bottom: 0;
}
.tableContent {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}

.app-container >>> .tableContent .el-table .cell {
  line-height: 16px;
}
</style>
