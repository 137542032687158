var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-card",
            { staticClass: "box-card", attrs: { shadow: "never" } },
            [
              _c("div", { staticStyle: { display: "flex" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      flex: "6",
                    },
                  },
                  [
                    _c("div", { staticStyle: { "margin-right": "10px" } }, [
                      _vm._v(_vm._s(_vm.$t("ChannelManage.oddNumbers")) + ":"),
                    ]),
                    _c("el-input", {
                      ref: "inputRef",
                      staticStyle: { width: "40%" },
                      attrs: {
                        autofocus: _vm.autofocus,
                        placeholder: _vm.$t("ChannelManage.inputoddNumbers"),
                        clearable: "",
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.eventEnter()
                        },
                      },
                      model: {
                        value: _vm.bagNo,
                        callback: function ($$v) {
                          _vm.bagNo = typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "bagNo",
                      },
                    }),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "20px" },
                        attrs: {
                          disabled: _vm.tableDataLeft.length === 0,
                          type: "warning",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.determineClosure()
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("GlobalSetObj.envelope")) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      flex: "4",
                      "line-height": "35px",
                      "font-size": "18px",
                    },
                  },
                  [
                    _c("div", [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("ChannelManage.serviceProvider"))),
                      ]),
                      _vm._v(":"),
                      _c("span", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(_vm._s(_vm.systemName || "- -")),
                      ]),
                    ]),
                    _c("div", [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("GlobalSetObj.DeliveryPoint"))),
                      ]),
                      _vm._v(":"),
                      _c("span", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(_vm._s(_vm.dispthSite || "- -")),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 13 } },
            [
              _c(
                "el-card",
                { staticClass: "box-card", attrs: { shadow: "never" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "currentBagHead",
                      staticStyle: { "margin-bottom": "10px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c("div", [
                            _c("span", { staticClass: "titleStyle" }, [
                              _vm._v(
                                _vm._s(_vm.$t("GlobalSetObj.CurrentBagging"))
                              ),
                            ]),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "20px" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("ChannelManage.waybillNumber")
                                  ) + ": "
                                ),
                              ]
                            ),
                            _c("span", { staticStyle: { color: "#dd1b33" } }, [
                              _vm._v(_vm._s(_vm.tableDataLeft.length)),
                            ]),
                          ]),
                          _c("div", [
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "30px" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("ChannelManage.totalWeight")) +
                                    "(KG): "
                                ),
                              ]
                            ),
                            _c("span", { staticStyle: { color: "#dd1b33" } }, [
                              _vm._v(_vm._s(_vm.TotalWeight.toFixed(3))),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-refresh-left",
                            size: "small",
                          },
                          on: { click: _vm.resetClick },
                        },
                        [_vm._v(_vm._s(_vm.$t("newOrder.reset")) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableDataLeftShow,
                        border: "",
                        height: "590px",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("GlobalSetObj.waybillNumber"),
                          "min-width": "150",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(scope.row.waybill_number || "- -")
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("placeOrder.customerNumber"),
                          "min-width": "150",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(scope.row.customerNumber || "- -")
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("GlobalSetObj.serviceLogistics"),
                          "min-width": "150",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(scope.row.logistics_number || "- -")
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("GlobalSetObj.weight"),
                          align: "center",
                          "min-width": "130",
                          prop: "weight",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("GlobalSetObj.operate"),
                          align: "center",
                          fixed: "right",
                          prop: "date",
                          width: "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#f56c6c",
                                      cursor: "pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeItem(scope)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("GlobalSetObj.MoveOut")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c("el-pagination", {
                    staticStyle: {
                      "margin-top": "10px",
                      float: "right",
                      "margin-bottom": "20px",
                    },
                    attrs: {
                      "current-page": _vm.packageList.pageNumber,
                      "page-size": _vm.packageList.pageSize,
                      "page-sizes": [10, 20, 50, 100],
                      "pager-count": 5,
                      total: _vm.packageList.total,
                      background: "",
                      layout: "total, sizes, prev, pager, next",
                      small: "",
                    },
                    on: {
                      "size-change": _vm.packageSizeChange,
                      "current-change": _vm.packageCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 11 } },
            [
              _c(
                "el-card",
                { staticClass: "box-card", attrs: { shadow: "never" } },
                [
                  _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                    _c("span", { staticClass: "titleStyle" }, [
                      _vm._v(_vm._s(_vm.$t("GlobalSetObj.BaggingRecord"))),
                    ]),
                  ]),
                  _c(
                    "el-form",
                    { attrs: { inline: "", "label-position": "top" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("GlobalSetObj.bagNumber") } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.bagNumber"),
                              clearable: "",
                              size: "small",
                            },
                            model: {
                              value: _vm.queryForm.params.bagNumber,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm.params,
                                  "bagNumber",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "queryForm.params.bagNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("ChannelManage.BagSealingTime"),
                            prop: "searchTimeType",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              clearable: false,
                              "default-time": ["00:00:00", "23:59:59"],
                              format:
                                _vm.$i18n.locale == "zh"
                                  ? _vm.zhDate
                                  : _vm.ptDate,
                              "picker-options": _vm.pickerOptions,
                              placeholder: _vm.$t("newOrder.SelectDatetime"),
                              "range-separator": "~",
                              size: "small",
                              type: "datetimerange",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                            },
                            on: { change: _vm.handleTime },
                            model: {
                              value: _vm.timeValue,
                              callback: function ($$v) {
                                _vm.timeValue = $$v
                              },
                              expression: "timeValue",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-top": "27px" },
                          attrs: { label: "" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.getBagSealingRecords },
                            },
                            [_vm._v(_vm._s(_vm.$t("newOrder.search")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "elTable" },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.tableDataRight,
                            border: "",
                            height: "500px",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.bagNumber"),
                              "min-width": "160",
                              prop: "transitBagNumber",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("ChannelManage.totalWeightkg"),
                              align: "center",
                              "min-width": "110",
                              prop: "totalWeight",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("ChannelManage.BagSealingTime"),
                              align: "center",
                              "min-width": "150",
                              prop: "packageTime",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.operate"),
                              align: "center",
                              fixed: "right",
                              "min-width": "120",
                              prop: "date",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "operator-wrap" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "small",
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.updateFun(scope.row)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("GlobalSetObj.update")
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "small",
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.printBagNo(scope.row)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("GlobalSetObj.Print")
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-pagination", {
                    staticStyle: {
                      "margin-top": "10px",
                      float: "right",
                      "margin-bottom": "20px",
                    },
                    attrs: {
                      "current-page": _vm.queryForm.pageNumber,
                      "page-size": _vm.queryForm.pageSize,
                      "page-sizes": [10, 20, 50, 100],
                      "pager-count": 5,
                      total: _vm.queryForm.total,
                      background: "",
                      layout: "total, sizes, prev, pager, next",
                      small: "",
                    },
                    on: {
                      "size-change": _vm.SizeChange,
                      "current-change": _vm.CurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
        },
        [
          _c("audio", {
            ref: "errRef",
            attrs: { src: _vm.errorMp3, controls: "controls" },
          }),
          _c("audio", {
            ref: "okRef",
            attrs: { src: _vm.okMp3, controls: "controls" },
          }),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "scannerBag",
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("GlobalSetObj.EntryBagNumber"),
            visible: _vm.scannerDialog,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.scannerDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.bagForm,
                rules: _vm.rules,
                inline: "",
                "label-width": "auto",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("GlobalSetObj.bagNumber"),
                    prop: "scannerBagNo",
                  },
                },
                [
                  _c("el-input", {
                    ref: "inputBagNo",
                    staticStyle: { width: "250px" },
                    attrs: {
                      placeholder: _vm.$t("GlobalSetObj.scannerOrinput"),
                      clearable: "",
                    },
                    model: {
                      value: _vm.bagForm.scannerBagNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.bagForm, "scannerBagNo", $$v)
                      },
                      expression: "bagForm.scannerBagNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: { click: _vm.generateBagNo },
                    },
                    [_vm._v(_vm._s(_vm.$t("ChannelManage.GenerateBag")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "info" },
                  on: { click: _vm.cancelClick },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.confirmBagger },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.determine")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "printStyle" },
        [
          _vm.showPrintBagNumberHtml
            ? _c("printBagNumberHtml", {
                attrs: { "param-data": _vm.printBagObj },
                on: { hiddenBagNumberHtml: _vm.hiddenBagNumberHtml },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }