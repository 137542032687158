<template>
  <div class="Box">
    <!-- 为了初始加载时替代背景图展示，多加一层 -->
    <div v-if="firstTab" class="boxUrl containerData">
      <div class="topBox"><span>{{ $t('collectionCenter.dataVisual') }}</span></div>
      <div v-show="updateTime" class="updateTimeClass">{{ $t('GlobalSetObj.UpdateTime') }}：{{ updateTime }}</div>
      <div ref="parentRef" style="padding:20px;box-sizing: border-box">
        <el-row class="grid-row">
          <el-col :span="8" class="left-column">
            <div ref="childRef1">
              <div ref="childRef11" class="dateBox">
                <span :class="{'d1': type == 0}" @click="getData(0)">{{ $t('collectionCenter.yesterday') }}</span><!-- 昨日 -->
                <span :class="{'d1': type == 1}" @click="getData(1)">{{ $t('collectionCenter.sameDay') }}</span><!-- 当天 -->
                <span :class="{'d1': type == 2}" @click="getData(2)">{{ $t('collectionCenter.nearlyAWeek') }}</span><!-- 近一周 -->
                <span :class="{'d1': type == 3}" @click="getData(3)">{{ $t('collectionCenter.sameMonth') }}</span><!-- 当月 -->
              </div>
              <div ref="childRef12" style="width: 100%;">
                <div class="leftBox" style="width: 95%;">
                  <span class="angle lt" /><span class="angle rt" /><span class="angle lb" /><span class="angle rb" />
                  <div style="color: #47E3FF;font-size: 20px;font-weight: bold;letter-spacing: 1px;line-height: 20px;">
                    <!-- 签收情况概览 -->
                    {{ $t('collectionCenter.signConditionView') }}
                  </div>
                  <el-row>
                    <el-col :span="12">
                      <div class="totalBox orange">
                        <!-- 应签收总量 -->
                        <span class="s1 org" style="line-height: 18px;margin-bottom: 5px;">{{ $t('collectionCenter.shouldSignedGrossAmount') }}</span><br>
                        <span class="s2" style="line-height: 18px;">{{ leftAll.shouldSignCount }}</span>
                      </div>
                      <div class="totalBox blue">
                        <!-- 实际签收总量 -->
                        <span class="s1 bl" style="line-height: 18px;margin-bottom: 5px;">{{ $t('collectionCenter.actualSignGrossAmount') }}</span><br>
                        <span class="s2" style="line-height: 18px;">{{ leftAll.actualSign }}</span>
                      </div>
                      <div class="totalBox green">
                        <!-- 未签收总量 -->
                        <span class="s1 gr" style="line-height: 18px;margin-bottom: 5px;">{{ $t('collectionCenter.unsignedGrossAmount') }}</span><br>
                        <span class="s2" style="line-height: 18px;">{{ leftAll.unSignCount }}</span>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="progressClass">
                        <div class="progressBox">
                          <img :src="ring" alt="" style="width: 60%; height: 60%">
                          <span>{{ leftAll.signRate }}%</span>
                        </div>
                        <br>
                        <!-- 签收率 -->
                        <span style="color: #01f2ff;font-weight: bold;font-size: 17px">{{ $t('collectionCenter.RateOfSigning') }}</span>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div class="leftBox" style="margin: 10px 0;width: 95%;">
                  <span class="angle lt" /><span class="angle rt" /><span class="angle lb" /><span class="angle rb" />
                  <div>
                    <!--疑似丢失包裹-->
                    <span style="color: #47E3FF;font-size: 20px;font-weight: bold;letter-spacing: 1px;">{{ $t('collectionCenter.SuspectedLostPackage') }}</span>
                    <span style="cursor: pointer;" @click="toSecondaryLose()">
                      <span style="color: #f42048;margin-left: 20px;font-size: 18px;font-weight: bold;">{{ tableData5.suspectedLostCount }}</span>
                      <span style="margin-left: 10px;color: #f42048;font-size: 16px;">{{ $t('operationCenter.pieces') }}</span>
                    </span>
                  </div>
                </div>
                <div class="leftBox" style="margin: 10px 0;width: 95%;">
                  <span class="angle lt" /><span class="angle rt" /><span class="angle lb" /><span class="angle rb" />
                  <div style="color: #47E3FF;font-size: 20px;font-weight: bold;letter-spacing: 1px;line-height: 20px;">
                    <!-- 揽收准时率 -->
                    {{ $t('collectionCenter.CollectionPunctualityRate') }}
                  </div>
                  <div style="width: 100%;">
                    <el-row type="flex" justify="start" style="flex-wrap: wrap;">
                      <el-col :span="8">
                        <div class="progressClass">
                          <div class="progressBox" style="cursor: pointer;" @click="toSecondarySummary(0)">
                            <img :src="national" alt="" style="width: 90%; height: 90%;">
                            <span style="font-size: 14px;">{{ tableData5.nationalCount }}%</span>
                          </div>
                          <br>
                          <!-- 全国准时率 -->
                          <span style="color: #01f2ff;;font-size: 14px">{{ $t('collectionCenter.NationalPunctualityRate') }}</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="progressClass">
                          <div class="progressBox" style="cursor: pointer;" @click="toSecondarySummary(1)">
                            <img :src="sp" alt="" style="width: 90%; height: 90%;">
                            <span style="font-size: 14px;font-weight: 100; color: #fbcd50;">{{ tableData5.spCount }}%</span>
                          </div>
                          <br>
                          <!-- sp准时率 -->
                          <span style="color: #01f2ff;font-size: 14px">{{ $t('collectionCenter.SPPunctualityRate') }}</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="progressClass">
                          <div class="progressBox" style="cursor: pointer;" @click="toSecondarySummary(2)">
                            <img :src="franca" alt="" style="width: 90%; height: 90%;">
                            <span style="font-size: 14px;font-weight: 100;color: #2ad42a;">{{ tableData5.francaCount }}%</span>
                          </div>
                          <br>
                          <!-- Franca准时率 -->
                          <span style="color: #01f2ff;font-size: 14px">{{ $t('collectionCenter.FrancaPunctualityRate') }}</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="progressClass">
                          <div class="progressBox" style="cursor: pointer;" @click="toSecondarySummary(3)">
                            <img :src="jau" alt="" style="width: 90%; height: 90%;">
                            <span style="font-size: 14px;font-weight: 100;color: #dd32e8;">{{ tableData5.jauCount }}%</span>
                          </div>
                          <br>
                          <!-- Jau准时率 -->
                          <span style="color: #01f2ff;font-size: 14px">{{ $t('collectionCenter.JauPunctualityRate') }}</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="progressClass">
                          <div class="progressBox" style="cursor: pointer;" @click="toSecondarySummary(5)">
                            <img :src="mg" alt="" style="width: 90%; height: 90%;">
                            <span style="font-size: 14px;font-weight: 100;color: #fc5831;">{{ tableData5.mgCount }}%</span>
                          </div>
                          <br>
                          <!-- MG准时率 -->
                          <span style="color: #01f2ff;font-size: 14px">{{ $t('collectionCenter.MGPunctualityRate') }}</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="progressClass">
                          <div class="progressBox" style="cursor: pointer;" @click="toSecondarySummary(4)">
                            <img :src="rj" alt="" style="width: 90%; height: 90%;">
                            <span style="font-size: 14px;font-weight: 100;color: #9b39ff;">{{ tableData5.rjCount }}%</span>
                          </div>
                          <br>
                          <!-- RJ准时率 -->
                          <span style="color: #01f2ff;font-size: 14px">{{ $t('collectionCenter.RJPunctualityRate') }}</span>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="16">
            <div ref="childRef2" class="rightBox right-column" style="height: calc(100% - 10px)">
              <span class="angle lt" /><span class="angle rt" /><span class="angle lb" /><span class="angle rb" />
              <div style="color: #47E3FF;font-size: 20px;font-weight: bold;letter-spacing: 1px;margin-bottom: 14px">
                <!-- 未签收各环节明细 -->
                {{ $t('collectionCenter.unsignEachLinkdetail') }}
              </div>
              <span style="color: #47E3FF;font-size: 16px;font-weight: bold;letter-spacing: 1px">
                <!-- MD揽收量 -->
                {{ $t('collectionCenter.mdLanshou') }}
              </span>
              <el-table
                border
                class="tableBox"
                :data="tableData1"
                style="width: 500px;"
              >
                <el-table-column label="SP-MD" width="100" align="center">
                  <template slot-scope="scope">
                    <span class="skipClass" @click="routeClick(1)">{{ scope.row.spMdStayCount }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Franca-MD" width="100" align="center">
                  <template slot-scope="scope">
                    <span class="skipClass" @click="routeClick(2)">{{ scope.row.francaMdStayCount }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="RJ-MD" width="100" align="center">
                  <template slot-scope="scope">
                    <span class="skipClass" @click="routeClick(4)">{{ scope.row.rjMdStayCount }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="MG-MD" width="100" align="center">
                  <template slot-scope="scope">
                    <span class="skipClass" @click="routeClick(5)">{{ scope.row.mgMdStayCount }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Jau-MD" align="center">
                  <template slot-scope="scope">
                    <span class="skipClass" @click="routeClick(3)">{{ scope.row.jauMdStayCount }}</span>
                  </template>
                </el-table-column>
              </el-table>
              <span style="color: #47E3FF;font-size: 16px;font-weight: bold;letter-spacing: 1px">
                <!-- MD至***转运中 -->
                {{ $t('collectionCenter.mdToxxx') }}
              </span>
              <el-table
                border
                class="tableBox"
                :data="tableData2"
                style="width: 600px;"
              >
                <el-table-column label="SP-Z-H005" width="100" align="center">
                  <template slot-scope="scope">
                    <span class="skipClass" @click="detailClick(1, 1)">{{ scope.row.mdSpH5Count }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="SP-Z-H006" width="100" align="center">
                  <template slot-scope="scope">
                    <span class="skipClass" @click="detailClick(1, 2)">{{ scope.row.mdSpH6Count }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="SP-Z-H002" width="100" align="center">
                  <template slot-scope="scope">
                    <span class="skipClass" @click="detailClick(1, 3)">{{ scope.row.mdSpH2Count }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="SP-Z-H004" width="100" align="center">
                  <template slot-scope="scope">
                    <span class="skipClass" @click="detailClick(1, 4)">{{ scope.row.mdSpH4Count }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="MG-Z-H001" width="100" align="center">
                  <template slot-scope="scope">
                    <span class="skipClass" @click="detailClick(1, 5)">{{ scope.row.mdMgH1Count }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="RJ-W-H001" align="center">
                  <template slot-scope="scope">
                    <span class="skipClass" @click="detailClick(1, 6)">{{ scope.row.mdRjH1count }}</span>
                  </template>
                </el-table-column>
              </el-table>
              <span style="color: #47E3FF;font-size: 16px;font-weight: bold;letter-spacing: 1px">
                <!-- 到达***转运中心 -->
                {{ $t('collectionCenter.arrivexxx') }}
              </span>
              <el-table
                border
                class="tableBox"
                :data="tableData3"
                style="width: 600px;"
              >
                <el-table-column label="SP-Z-H005" width="100" align="center">
                  <template slot-scope="scope">
                    <span class="skipClass" @click="detailClick(2, 1)">{{ scope.row.arrivalSpH5Count }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="SP-Z-H006" width="100" align="center">
                  <template slot-scope="scope">
                    <span class="skipClass" @click="detailClick(2, 2)">{{ scope.row.arrivalSpH6Count }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="SP-Z-H002" width="100" align="center">
                  <template slot-scope="scope">
                    <span class="skipClass" @click="detailClick(2, 3)">{{ scope.row.arrivalSpH2Count }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="SP-Z-H004" width="100" align="center">
                  <template slot-scope="scope">
                    <span class="skipClass" @click="detailClick(2, 4)">{{ scope.row.arrivalSpH4Count }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="MG-Z-H001" width="100" align="center">
                  <template slot-scope="scope">
                    <span class="skipClass" @click="detailClick(2, 5)">{{ scope.row.arrivalMgH1Count }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="RJ-W-H001" align="center">
                  <template slot-scope="scope">
                    <span class="skipClass" @click="detailClick(2, 6)">{{ scope.row.arrivalRjH1Count }}</span>
                  </template>
                </el-table-column>
              </el-table>
              <span style="color: #47E3FF;font-size: 16px;font-weight: bold;letter-spacing: 1px">
                <!-- ****至WR-GLP-01 -->
                {{ $t('collectionCenter.xxxToWrglp01') }}
              </span>
              <el-table
                border
                class="tableBox"
                :data="tableData4"
                style="width: 600px;"
              >
                <el-table-column label="SP-Z-H005" width="100" align="center">
                  <template slot-scope="scope">
                    <span class="skipClass" @click="detailClick(3, 1)">{{ scope.row.spH5ToGlp1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="SP-Z-H006" width="100" align="center">
                  <template slot-scope="scope">
                    <span class="skipClass" @click="detailClick(3, 2)">{{ scope.row.spH6ToGlp1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="SP-Z-H002" width="100" align="center">
                  <template slot-scope="scope">
                    <span class="skipClass" @click="detailClick(3, 3)">{{ scope.row.spH2ToGlp1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="SP-Z-H004" width="100" align="center">
                  <template slot-scope="scope">
                    <span class="skipClass" @click="detailClick(3, 4)">{{ scope.row.spH4ToGlp1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="MG-Z-H001" width="100" align="center">
                  <template slot-scope="scope">
                    <span class="skipClass" @click="detailClick(3, 5)">{{ scope.row.mgH1ToGlp1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="RJ-W-H001" align="center">
                  <template slot-scope="scope">
                    <span class="skipClass" @click="detailClick(3, 6)">{{ scope.row.rjH1ToGlp1 }}</span>
                  </template>
                </el-table-column>
              </el-table>
              <span style="color: #47E3FF;font-size: 16px;font-weight: bold;letter-spacing: 1px">
                <!-- GLP未卸车总量 -->
                {{ $t('collectionCenter.glpUnloadTotal') }}
              </span><br>
              <div class="table-cell">{{ glpNoLoadCount }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div v-else class="tabsBox">
      <div v-if="showPunctualityCountry" style="position:absolute;top: 0;left: 0;bottom: 0;right: 0;z-index: 1000;background:#fff;">
        <punctuality :key="showPunctualityCountry" :time-form="timeForm" @closeWindow="closeWindow" />
      </div>
      <div v-if="showSuspectedLoss" style="position:absolute;top: 0;left: 0;bottom: 0;right: 0;z-index: 1000;background:#fff;">
        <suspected-loss :key="showSuspectedLoss" :time-form="timeForm" @closeWindow="closeWindow" />
      </div>
      <div v-if="showDetailMsg">
        <div class="tabsClose">
          <el-button
            type="primary"
            plain
            size="small"
            @click="closeTabsClick">
            {{ $t('collectionCenter.close') }}
          </el-button>
        </div>
        <detailMsg :form="queryFormList" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  dashboard
} from '@/api/lanshou';
import dayjs from 'dayjs';
import detailMsg from '@/views/collectionCenter/CollectKanban/components/yy/index';

export default {
  name: 'CollectOperationalData',
  components: {
    detailMsg,
    punctuality: () => import('./cmp/punctuality'),
    'suspected-loss': () => import('./cmp/suspectedLoss')
  },
  data() {
    return {
      ring: '/static/cod/ring.png',
      national: '/static/cod/NationalPunctualityRate.png', // 全国
      franca: '/static/cod/FrancaPunctualityRate.png', // Fr
      jau: '/static/cod/JauPunctualityRate.png', // Ja
      mg: '/static/cod/MGPunctualityRate.png', // MG
      rj: '/static/cod/RJPunctualityRate.png', // RJ
      sp: '/static/cod/SPPunctualityRate.png', // SP
      backgroundUrl: '/static/cod/background.png',
      leftAll: {
        shouldSignCount: 0,
        actualSign: 0,
        unSignCount: 0,
        signRate: 0
      },
      tableData1: [],
      tableData2: [],
      tableData3: [],
      tableData4: [],
      tableData5: {},
      glpNoLoadCount: 0,
      type: 1,
      updateTime: '',
      time: [],
      firstTab: true,
      showDetailMsg: false,
      queryForm: {},
      queryFormList: {},
      showPunctualityCountry: false,
      showSuspectedLoss: false,
      timeForm: {
        startTime: '',
        endTime: '',
        mdStayType: '',
        timeType: '',
        updateTime: ''
      }
    };
  },
  mounted() {
    // 父元素自适应高度，左侧子元素内容撑高，右侧子元素需同高
    // this.$refs.childRef2.offsetHeight = this.$refs.childRef1.offsetHeight;
  },
  created() {
    this.type = 1;
    this.firstTab = true;
    this.getData(1);
  },
  activated() {
    this.type = 1;
    this.firstTab = true;
    this.getData(1);
  },
  methods: {
    closeWindow() {
      this.showSuspectedLoss = false;
      this.showPunctualityCountry = false;
      this.firstTab = true;
    },
    // 进入二级页面
    toSecondarySummary(type) {
      this.firstTab = false;
      this.showPunctualityCountry = true;
      this.timeForm = {
        startTime: this.time[0],
        endTime: this.time[1],
        mdStayType: type,
        timeType: this.type,
        updateTime: this.updateTime
      };
    },
    toSecondaryLose() {
      this.firstTab = false;
      this.showSuspectedLoss = true;
      this.timeForm = {
        startTime: this.time[0],
        endTime: this.time[1],
        mdStayType: '',
        timeType: this.type,
        updateTime: this.updateTime
      };
    },
    getData(type) {
      this.type = type;
      const d = new Date();
      const day0 = dayjs(d).subtract(1, 'day').format('YYYY-MM-DD');
      const day1 = dayjs(d).format('YYYY-MM-DD');
      const day2 = dayjs(d).subtract(6, 'day').format('YYYY-MM-DD');
      const day3 = dayjs(d).startOf('month').format('YYYY-MM-DD');
      let date = {};
      if (type === 0) {
        date = {
          startTime: day0 + ' 00:00:00',
          endTime: day0 + ' 23:59:59'
        };
        this.time = [day0, day0];
      }
      if (type === 1) {
        date = {
          startTime: day1 + ' 00:00:00',
          endTime: day1 + ' 23:59:59'
        };
        this.time = [day1, day1];
      }
      if (type === 2) {
        date = {
          startTime: day2 + ' 00:00:00',
          endTime: day1 + ' 23:59:59'
        };
        this.time = [day2, day1];
      }
      if (type === 3) {
        date = {
          startTime: day3 + ' 00:00:00',
          endTime: day1 + ' 23:59:59'
        };
        this.time = [day3, day1];
      }
      dashboard(date).then(res => {
        if (res.status === 'OK') {
          this.updateTime = res.data.createTime;
          this.leftAll = {
            shouldSignCount: res.data.shouldSignCount,
            actualSign: res.data.actualSign,
            unSignCount: res.data.unSignCount,
            signRate: res.data.signRate
          };
          this.glpNoLoadCount = res.data.glpNoLoadCount;
          this.tableData1 = [{
            spMdStayCount: res.data.spMdStayCount,
            francaMdStayCount: res.data.francaMdStayCount,
            rjMdStayCount: res.data.rjMdStayCount,
            mgMdStayCount: res.data.mgMdStayCount,
            jauMdStayCount: res.data.jauMdStayCount
          }];
          this.tableData2 = [{
            mdSpH5Count: res.data.mdSpH5Count,
            mdSpH6Count: res.data.mdSpH6Count,
            mdSpH2Count: res.data.mdSpH2Count,
            mdSpH4Count: res.data.mdSpH4Count,
            mdMgH1Count: res.data.mdMgH1Count,
            mdRjH1count: res.data.mdRjH1count
          }];
          this.tableData3 = [{
            arrivalSpH5Count: res.data.arrivalSpH5Count,
            arrivalSpH6Count: res.data.arrivalSpH6Count,
            arrivalSpH2Count: res.data.arrivalSpH2Count,
            arrivalSpH4Count: res.data.arrivalSpH4Count,
            arrivalMgH1Count: res.data.arrivalMgH1Count,
            arrivalRjH1Count: res.data.arrivalRjH1Count
          }];
          this.tableData4 = [{
            spH5ToGlp1: res.data.spH5ToGlp1,
            spH6ToGlp1: res.data.spH6ToGlp1,
            spH4ToGlp1: res.data.spH4ToGlp1,
            spH2ToGlp1: res.data.spH2ToGlp1,
            mgH1ToGlp1: res.data.mgH1ToGlp1,
            rjH1ToGlp1: res.data.rjH1ToGlp1
          }];
          // suspectedLostCount（添加昨日时间，除疑似丢失包裹指标除外，其他的指标均需要增加该时间维度的统计及展示）
          let suspectedLostCount = '';
          if (this.time[0] !== day0 && this.time[1] !== day0) {
            suspectedLostCount = res.data.suspectedLostCount;
            localStorage.setItem('suspectedLostCountVal', JSON.stringify(res.data.suspectedLostCount));
          } else {
            if (localStorage.getItem('suspectedLostCountVal')) {
              suspectedLostCount = JSON.parse(localStorage.getItem('suspectedLostCountVal'));
            }
          }
          this.tableData5 = {
            nationalCount: res.data.nationalPunctualRate,
            spCount: res.data.spPunctualRate,
            mgCount: res.data.mgPunctualRate,
            rjCount: res.data.rjPunctualRate,
            jauCount: res.data.jauPunctualRate,
            francaCount: res.data.francaPunctualRate,
            suspectedLostCount
          };
        }
      });
    },
    routeClick(type) {
      this.$router.push({
        path: '/collectionCenter/CollectKanban/mdHoldupTable',
        query: {
          t1: this.time[0],
          t2: this.time[1],
          mdStayType: type
        }
      });
    },
    closeTabsClick() {
      this.firstTab = true;
      this.showDetailMsg = false;
    },
    detailClick(detailType, siteType) {
      // detailType  营运看板明细类型 1:MD至转运中心 2:到达转运中心 3:转运中心至WR-GLP-01
      // siteType  转运类型 1:SP-Z-H005 2:SP-Z-H006 3:SP-Z-H002 4:SP-Z-H004 5:MG-Z-H001 6:RJ-W-H001
      this.queryForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: this.time[0],
          endTime: this.time[1],
          dashboardDetailType: detailType,
          transitType: siteType
        }
      };
      this.firstTab = false;
      this.showDetailMsg = true;
      this.queryFormList = {
        queryForm: this.queryForm,
        activeName: 'hz'
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.grid-row {
  display: flex;
}

.left-column {
  height: 100%;
}
.tabsBox{
  min-height: calc(100vh - 85px);padding: 20px;box-sizing: border-box;background: #fff;
  position: relative;
  .tabsClose{
    z-index: 9;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    span{
      transform: scale(2);
    }
  }
}
.containerData .updateTimeClass{
  position: absolute;
  right: 20px;
  top: 10px;
}
.containerData .skipClass{
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
.containerData .skipClass:hover{
  border: 1px solid #00fcf9;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
}
.Box{
  background-color: #03043d;
}
.boxUrl{
  box-sizing: border-box;
  color: #fff;
  padding-top: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  background: url("/static/cod/background.png") no-repeat;
  background-size: 100% 100%;
}
.containerData .topBox{
  background: url("/static/cod/top.png") no-repeat;
  background-size: 100% 100%;
  width: 873px;
  height: 57px;
  margin: 0 auto;
  text-align: center;
  line-height: 38px;
  span{
    font-size: 23px;
    background-image: linear-gradient(0deg, #60c7fc, #fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }
}
.containerData .dateBox{
  margin-bottom: 20px;
  span{display: inline-block;border: 1px solid #71AFFF;padding: 3px 15px;box-sizing: border-box;cursor: pointer;}
  span:hover, span.d1:hover{background: #71afff;}
  span.d1:hover{border: 1px solid #71AFFF;box-sizing: border-box;}
  span.d1{border: none;background: linear-gradient(90deg, #0161C0, transparent);padding: 4px 20px;box-sizing: border-box;}
}
.containerData .leftBox, .containerData .rightBox{
  padding: 20px;
  border: 1px solid rgba(113, 175, 255, 0.3);
  box-sizing: border-box;
  min-height: 50px;
  position: relative;
  .angle{width: 8px;height: 8px;position: absolute;}
  .angle.lt{top: -1px; left: -1px; border-left: 2px solid #206ED4; border-top: 2px solid #206ED4;}
  .angle.rt{top: -1px; right: -1px; border-right: 2px solid #206ED4; border-top: 2px solid #206ED4;}
  .angle.lb{bottom: -1px; left: -1px; border-left: 2px solid #206ED4; border-bottom: 2px solid #206ED4;}
  .angle.rb{bottom: -1px; right: -1px; border-right: 2px solid #206ED4; border-bottom: 2px solid #206ED4;}

  .totalBox{padding: 10px;padding-left: 15px;box-sizing: border-box;margin-top: 15px;min-width: 100%;}
  .totalBox.orange{border: 1px solid #CB7429;box-shadow: inset 0px 0px 7px 0px #CB7429;}
  .totalBox.blue{border: 1px solid #5DB1FF;box-shadow: inset 0px 0px 7px 0px #5DB1FF;}
  .totalBox.green{border: 1px solid #47E3FF;box-shadow: inset 0px 0px 7px 0px #47E3FF;}
  .s1{display: inline-block;font-size: 17px;font-weight: bold;letter-spacing: 1px;}
  .s2{font-size: 26px;font-weight: bold;letter-spacing: 3px;word-wrap: break-word}
  .org.s1{color: #CB7429}
  .bl.s1{color: #5DB1FF}
  .gr.s1{color: #47E3FF}
}
.containerData .leftBox {
  margin-right: 20px;
  .progressClass{
    margin-top: 20px;
    text-align: center;
    .progressBox{
      position: relative;
      display: inline-block;
      span{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #00FCF9;
        font-size: 1.5vw;
        font-weight: bold;
        letter-spacing: 1px;
      }
    }
  }
}
.containerData .rightBox{flex: 1;min-width: 300px;}

.containerData .tableBox, .containerData .tableBox ::v-deep .el-table__cell, .containerData .tableBox ::v-deep tr{
  background: transparent !important;
}
.containerData .tableBox{
  margin-top: 5px;
  margin-bottom: 25px;
}
.containerData .table-cell{
  border: 1px solid #86C4FD;
  color: #00FCF9;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  margin-top: 7px;
}
.containerData .tableBox ::v-deep .el-table__row {line-height: 30px;}
.containerData .tableBox ::v-deep .el-table__empty-block{height: 30px !important;min-height: 30px !important;}
.containerData .tableBox ::v-deep .el-table__cell {padding: 0 !important;height: 35px !important;}

.containerData .tableBox.el-table::before, .tableBox.el-table::after{
  background-color: #86C4FD !important;
}
.containerData .tableBox ::v-deep .el-table__header th,
.containerData .tableBox ::v-deep .el-table__body td,
.containerData .tableBox ::v-deep el-table__body-wrapper,
.containerData .tableBox ::v-deep .el-table__empty-block,
.containerData ::v-deep .el-table--border
{
  color: #00FCF9 !important;
  border-color: #86C4FD !important;
  border-bottom-color: #86C4FD !important;
  border-right-color: #86C4FD !important;
}
.containerData .tableBox ::v-deep .el-table__empty-text{color: #00FCF9 !important;}

.containerData .tableBox ::v-deep .el-table__header th{
  color: #86C4FD !important;
}
</style>
