<template>
  <div class="app-container">
    <div>
      <el-form
        ref="queryForm"
        class="queryFormClass"
        label-width="auto"
        label-position="top"
        size="mini"
        inline
      >
        <el-row>
          <el-col :span="6">
            <el-form-item :label="$t('orderCenterCont.WhetherPushWasSuccessful')">
              <el-select
                v-model="queryForm.params.state"
                filterable
                :placeholder="$t('operationCenter.PleaseSelect')"
                sttyle="width: 100%"
              >
                <el-option
                  v-for="item in PushWasSuccessful"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('receivablePayable.TicketNumber')">
              <el-input
                v-model="queryForm.params.logisticsNumberList"
                clearable
                type="textarea"
                :rows="3"
                style="width: 300px"
                :placeholder="$t('receivablePayable.TicketNumber')"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-button
        type="primary" size="small" icon="el-icon-search"
        @click="onQuery"
      >{{
        $t('operationCenter.Procurar')
      }}</el-button>
      <el-button size="small" icon="el-icon-refresh-left" @click="reset">{{
        $t('operationCenter.reset')
      }}</el-button>

      <el-divider />

      <!-- 导出 -->
      <!-- <el-button
        v-permit:remove="'btn:finance:dataPushLogs:export'"
        size="small"
        type="primary"
      >{{ $t('operationCenter.exports') }}</el-button> -->

      <el-card>
        <div>
          <el-table
            :data="tableData"
            border
            class="table-height"
            :height="tableHeightRow.height"
          >
            <el-table-column :label="$t('operationCenter.ind')" width="80px" align="center">
              <template slot-scope="scope">
                <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>

            <!-- 业务单号 -->
            <el-table-column
              :label="$t('receivablePayable.TicketNumber')"
              width="200px"
              align="left"
              prop="logisticsNumber"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <div class="is-click-column" @click="showDetail(scope.row)">
                  {{ scope.row.logisticsNumber }}
                </div>
              </template>
            </el-table-column>
            <!-- 订单号 -->
            <!-- <el-table-column
              :label="$t('orderCenterCont.TheOrderNumber')"
              width="200px"
              align="left"
              prop="orderNumber"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <div class="is-click-column" @click="showDetail(scope.row)">
                  {{ scope.row.orderNumber }}
                </div>
              </template>
            </el-table-column> -->
            <!-- 是否推送成功 -->
            <el-table-column
              :label="$t('orderCenterCont.WhetherPushWasSuccessful')"
              width="120"
              align="center"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <div>
                  <!-- 推送成功 -->
                  <el-tag v-if="scope.row.state === 1" type="success">{{
                    $t('operationCenter.succeed')
                  }}</el-tag>
                  <!-- 失败 -->
                  <el-tag
                    v-else
                    type="danger"
                  >{{ $t('operationCenter.fail') }}</el-tag>
                </div>
              </template>
            </el-table-column>
            <!--创建时间-->
            <el-table-column
              :label="$t('orderCenterCont.CreateTime')"
              width="160px"
              align="left"
              prop="sendTime"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.createTime }}</div>
              </template>
            </el-table-column>
            <!--日志描述-->
            <el-table-column
              :label="$t('orderCenterCont.LogDescription')"
              align="left"
              prop="responseMessage"
              :show-overflow-tooltip="true"
            >
              <!-- <template slot-scope="scope">
                <div>{{ scope.row.description }}</div>
              </template> -->
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页工具 -->
        <div class="pagination">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryForm.pageNumber"
            :page-sizes="[10, 20, 50, 100]"
            :page-size.sync="queryForm.pageSize"
            :total="total"
            background
            style="margin: 10px 0"
            @current-change="asyncGetTableData"
            @size-change="asyncGetTableData"
          />
        </div>
      </el-card>
    </div>

    <!-- 详情 -->
    <el-drawer
      :visible.sync="isDrawer"
      :close-on-click-modal="false"
      :append-to-body="true"
      direction="ltr"
      size="70%"
      custom-class="drawerClass"
      :title="$t('orderCenterCont.ViewDetails')"
    >
      <DetailsCmp :row="rowData" />
    </el-drawer>
  </div>
</template>

<script>
import DetailsCmp from './cmp/DetailsCmp';
import { formData } from './utils';
import { cloneDeep } from 'lodash-es';
import { apiExpressFeePushLog } from '@/api/finance/basicData';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';

export default {
  name: '',

  components: {
    DetailsCmp
  },

  mixins: [estimateTableHeight],

  data() {
    return {
      queryForm: {
        orderDirection: 'DESC',
        orderProperty: '',
        pageNumber: 1,
        pageSize: 20,
        params: {
          logisticsNumberList: '',
          state: undefined
        }
      },
      total: 0,

      tableData: [],

      rowData: {
        ...formData
      },

      isDrawer: false
    };
  },

  computed: {
    PushWasSuccessful: function() {
      return [
        { name: this.$i18n.t('operationCenter.all'), code: undefined },
        { name: this.$i18n.t('operationCenter.fail'), code: 0 },
        { name: this.$i18n.t('operationCenter.succeed'), code: 1 }
        // { name: this.$i18n.t('operationCenter.toBePushed'), code: 2 },
      ];
    }
  },

  created() {
    this.onQuery();
  },

  methods: {
    // 重置
    reset() {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = 20;
      this.queryForm.params.logisticsNumberList = '';
      this.queryForm.params.state = undefined;

      this.asyncGetTableData();
    },

    // 搜索
    onQuery() {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = 20;
      this.asyncGetTableData();
    },

    // 设置查询条件
    setQueryForm() {
      const queryForm = cloneDeep(this.queryForm);
      const { logisticsNumberList } = this.queryForm.params;
      if (logisticsNumberList) {
        queryForm.params.logisticsNumberList = logisticsNumberList
          .split(/[(\r\n)\r\n]+/).filter(Boolean);
      } else {
        queryForm.params.logisticsNumberList = [];
      }

      return queryForm;
    },

    // 获取列表数据
    asyncGetTableData() {
      const obj = this.setQueryForm();
      apiExpressFeePushLog(obj)
        .then((res) => {
          if (res.code === 200) {
            const { records, total } = res.data;

            this.total = total;

            this.tableData = records.map(item => {
              const { id, createTime, createUser, orderNumber, state, requestMessage, responseMessage, logisticsNumber, customerName } = item;

              const obj = Object.freeze({
                id,
                createTime, // 创建时间
                createUser: createUser || 0, // 创建人
                orderNumber, // 订单号
                state, // 0：推送失败，1：推送成功
                requestMessage, // 请求报文
                responseMessage, // 响应信息
                logisticsNumber, // 物流单号
                customerName // 客户名称
              });

              return obj;
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // 查看详情
    showDetail(row) {
      const obj = cloneDeep(row);
      Object.assign(this.rowData, obj);
      this.isDrawer = true;
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
