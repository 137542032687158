<template>
  <div class="app-container">
    <div style="position:relative;">
      <div class="searchContainer">
        <el-form
          ref="form"
          :label-position="$i18n.locale!=='zh'?'top':'right'"
          :model="queryForm"
          label-width="120px"
        >
          <el-row :gutter="10">
            <!--
            <el-col :span="7">
              <div class="grid-content bg-purple">
                <el-form-item :label="$t('orderCenterCont.CountryOrigin') + '：'">
                  <el-select
                    v-model="queryForm.params.areaNameCn"
                    filterable
                    :placeholder="$t('operationCenter.PleaseSelect')"
                    clearable
                    style="width: 100%;"
                  >
                    <el-option
                      v-for="(item, index) in countryList"
                      :key="index"
                      :label="item.key + '(' + item.label + ')'"
                      :value="item.label"
                    />
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
             -->
            <el-col :span="7">
              <div class="grid-content bg-purple">
                <el-form-item :label="$t('orderCenterCont.ChineseName') + '：'">
                  <el-input v-model="queryForm.params.nameCn" />
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="7">
              <div class="grid-content bg-purple">
                <el-form-item :label="$t('orderCenterCont.EnglishName') + '：'">
                  <el-input v-model="queryForm.params.nameEn" />
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </el-form>
        <el-button
          icon="el-icon-search"
          size="small"
          type="primary"
          @click="searchResources(1)"
        >{{ $t('operationCenter.Procurar') }}
        </el-button><!-- 搜索 -->
        <el-button
          icon="el-icon-refresh-left"
          size="small"
          @click="searchResources(2)"
        >{{ $t('operationCenter.reset') }}
        </el-button><!-- 重置 -->
        <el-divider />
      </div>
    </div>
    <div class="m-b-sm">
      <el-button
        slot="trigger"
        v-permit:remove="'btn:order-center:add-region'"
        icon="el-icon-plus"
        size="small"
        style="margin-right: 5px;"
        type="primary"
        @click="operationBtn(1)"
      >{{ $t('orderCenterCont.add') }}
      </el-button><!-- 添加 -->
    </div>
    <el-card>
      <div>
        <el-table
          :data="tableData"
          border
        >
          <el-table-column
            :label="$t('operationCenter.ind')"
            align="center"
            width="60px"
          >
            <template slot-scope="scope">
              <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <!--
          <el-table-column
            prop="areaNameCn"
            :label="$t('orderCenterCont.CountryOrigin')"
            align="left"
            :show-overflow-tooltip="true"
          />
           -->
          <el-table-column
            :label="$t('orderCenterCont.ChineseName')"
            :show-overflow-tooltip="true"
            align="left"
            prop="nameCn"
          />
          <el-table-column
            :label="$t('orderCenterCont.EnglishName')"
            :show-overflow-tooltip="true"
            align="left"
            prop="nameEn"
          />
          <el-table-column
            :label="$t('operationCenter.operate')"
            :width="$i18n.locale==='zh'?'240px':'280px'"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                v-permit:remove="'btn:region-center:btn-change'"
                size="small"
                type="success"
                @click="operationBtn(2, scope.row)"
              >{{ $t('operationCenter.modify') }}
              </el-button>
              <el-button
                v-permit:remove="'btn:region-center:btn-delete'"
                size="small"
                type="danger"
                @click="operationBtn(3, scope.row)"
              >{{ $t('operationCenter.Delete') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          :current-page.sync="queryForm.pageNumber"
          :page-size.sync="queryForm.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          style="margin: 10px 0"
          @current-change="getTableData"
          @size-change="getTableData"
        />
      </div>
    </el-card>
    <el-drawer
      :append-to-body="true"
      :title="operationType === 1 ? $t('orderCenterCont.add') : $t('operationCenter.modify')"
      :visible.sync="drawerTag"
      custom-class="drawerClass"
      direction="ltr"
      size="65%"
    >
      <!-- :title="operationType === 4 ? $t('orderCenterCont.add') : $t('operationCenter.modify')" -->
      <addDetails
        :key="newKey"
        :country-list="countryList"
        :operation-type="operationType"
        :update-form="updateForm"
        @changeData="changeData"
      />
    </el-drawer>
  </div>
</template>

<script>
// import { getLgAreaListData } from '@/api/orderApi';
import { regionalDelete, regionalPage } from '@/api/regionalManagement/regional';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import addDetails from './cmp/addDetails';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'RegionalManage',
  components: {
    addDetails
  },
  data() {
    return {
      total: 0,
      queryBoxShow: false,
      queryForm: {
        orderDirection: 'DESC',
        orderProperty: '',
        pageNumber: 1,
        pageSize: 10,
        params: {
          // areaId: '', // 国家id
          // areaNameCn: '', // 国家中文名
          // areaTwoCode: '', // 国家二字码
          nameCn: '', // 大区中文名
          nameEn: '', // 大区英文名
          id: ''
        }
      },
      updateForm: {
        // areaId: '',
        // areaNameCn: '',
        // areaTwoCode: '',
        id: '',
        nameCn: '',
        nameEn: ''
      },
      drawerTag: false,
      createUser: [],
      tableData: [],
      countryList: [],
      newKey: new Date().getTime(),
      operationType: 1
    };
  },
  created() {
    this.createUser = getUserIdAndNameObj();
    this.getTableData();
    // this.getAllCountry();
  },
  methods: {
    // // 获取所有国家
    // getAllCountry() {
    //   getLgAreaListData({ level: 0 })
    //     .then((res) => {
    //       if (res.status === 'OK') {
    //         this.countryList = [];
    //         res.data.forEach((city) => {
    //           this.countryList.push({
    //             label: city.nameCn,
    //             key: city.twoCode,
    //             id: city.id
    //           });
    //         });
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // 查询
    searchResources(type) {
      this.queryForm.pageNumber = 1;
      if (type === 2) {
        for (const k in this.queryForm.params) {
          this.queryForm.params[k] = '';
        }
      }
      this.getTableData();
    },
    // 获取列表数据
    getTableData() {
      regionalPage(this.queryForm)
        .then((res) => {
          if (res.status === 'OK') {
            this.tableData = res.data.records;
            this.total = res.data.total;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 清空添加表单操作
    clearUpdateForm() {
      for (const k in this.updateForm) {
        this.updateForm[k] = '';
      }
    },
    // 操作
    operationBtn(type, row) {
      // console.log('添加——', type, row);
      this.operationType = type;
      this.clearUpdateForm();
      if (row) {
        this.updateForm = cloneDeep(row);
      }
      if (type === 3) {
        this.queryForm.pageNumber = 1;
        this.$confirm(
          this.$i18n.t('operationCenter.AreYouSureDelete'),
          this.$i18n.t('operationCenter.tips'),
          {
            confirmButtonText: this.$i18n.t('operationCenter.determine'), // '确定',
            cancelButtonText: this.$i18n.t('operationCenter.Cancel'), // '取消',
            type: 'warning'
          }
        )
          .then(() => {
            regionalDelete(this.updateForm.id)
              .then((res) => {
                if (res.status === 'OK') {
                  this.$message.success(this.$i18n.t('operationCenter.TheOperationSucceeded')); // '删除成功'
                  this.getTableData();
                }
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch(() => {
          });
      } else {
        this.newKey = new Date().getTime();
        this.drawerTag = true;
      }
    },
    // 更新操作
    changeData(type) {
      if (type === true) {
        this.getTableData();
      }
      this.drawerTag = false;
    }
  }
};
</script>

<style scoped>
.m-b-sm {
  margin-bottom: 10px;
}

.drawerClass header {
  border-bottom: 1px solid #ccc;
  margin-bottom: 0;
  padding: 20px 20px 20px;
}
</style>
