var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-option",
    {
      key: _vm.label + _vm.value,
      attrs: { label: _vm.source[_vm.label], value: _vm.source[_vm.value] },
    },
    [
      _c("span", [_vm._v(_vm._s(_vm.source[_vm.label]))]),
      _vm.isRight
        ? _c("span", { staticStyle: { float: "right", color: "#939393" } }, [
            _vm._v(_vm._s(_vm.source[_vm.value])),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }