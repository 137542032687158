<template>
  <div class="app-container">
    <el-row :gutter="20">
      <el-col :span="4">
        <div class="grid-content bg-purple">
          <el-input
            v-model="orderContainer"
            type="textarea"
            :rows="20"
            :placeholder="$t('GlobalSetObj.PleaseEnterTheWaybillNumber')"
            @blur="getQueryNumber()"
          />
          <div style="margin-top: 10px;">{{ $t('trajectory.NumberOfQueries') }}：{{ queryNumber }}； </div><!-- 查询数量： -->
          <div style="color: #f00;margin-top: 10px;">{{ $t('trajectory.UpTo500Queries') }}</div><!-- 每次最多查询500条 -->
          <el-button
            type="primary"
            size="small"
            style="margin-top: 10px;"
            @click="queryOrder()"
          >{{ $t('operationCenter.Procurar') }}</el-button><!-- 查询 -->
          <el-button style="margin-top: 10px;" size="small" @click="resetInput">{{ $t('operationCenter.reset') }}</el-button><!-- 清空 -->
        </div>
      </el-col>
      <el-col :span="20">
        <div class="grid-content bg-purple order-Type">
          <el-tabs v-model="selectedName" type="border-card">
            <el-tab-pane name="all">
              <template slot="label">
                <i class="iconfont icon-zichan-quanbushebei" style="margin-right: 5px;color:#0d47a1!important;" />
                <span style="color:#0d47a1!important;">
                  {{ $t('trajectory.all') }}（{{ orderNumber.orderAll.length }}） <!-- 全部 -->
                </span>
              </template>
              <el-collapse
                v-if="orderNumber.orderAll.length>0"
                v-model="activeName"
                accordion
              >
                <el-collapse-item
                  v-for="(orderItem, orderIndex) in orderNumber.orderAll"
                  :key="orderIndex"
                  :title="orderItem.waybillNumber"
                  :name="orderItem.waybillNumber"
                >
                  <template slot="title">
                    <div style="display: flex;">
                      <div style="display: flex;align-items: center;">
                        <div style="width: 30px;">
                          <span
                            v-if="orderItem.tabStatus === 'noTrajectory'"
                            class="iconStyle"
                            style="background:#757575;"
                          >
                            <!--未查询到数据-->
                            <span class="iconfont icon-shalou" style="font-size: 18px;color:#fff;" />
                          </span>
                          <span
                            v-else-if="orderItem.tabStatus === 'inTransit'"
                            class="iconStyle"
                            style="background:#2196f3;"
                          >
                            <!--运输途中-->
                            <span class="iconfont icon-airTransport" style="font-size: 16px;color:#fff;" />
                          </span>
                          <span
                            v-else-if="orderItem.tabStatus === 'delivery'"
                            class="iconStyle"
                            style="background:#0d47a1;"
                          >
                            <!--派送中-->
                            <img :src="paisong1" style="width: 19px;height: 19px;margin-top: 5px" />
                          </span>
                          <span
                            v-else-if="orderItem.tabStatus === 'signed'"
                            class="iconStyle"
                            style="background:#43a047;"
                          >
                            <!--已签收-->
                            <span class="iconfont icon-chenggong" style="font-size: 16px;color:#fff;" />
                          </span>
                          <span
                            v-else-if="orderItem.tabStatus === 'abnormal'"
                            class="iconStyle"
                            style="background:#ff6f00;"
                          >
                            <!--可能异常-->
                            <span class="iconfont icon-yichangtishi" style="font-size: 18px;color:#fff;" />
                          </span>
                          <span
                            v-else
                            class="iconStyle"
                            style="background:#e6a23c;"
                          >
                            <!--其他状态或没有轨迹-->
                            <i class="el-icon-more" style="font-size: 17px;color:#fff;" />
                          </span>
                        </div>
                        <div style="display: flex;align-items: center;">
                          <span style="margin-left: 10px;">{{ orderItem.waybillNumber }}</span>
                        </div>
                        <div style="display: flex;align-items: center;margin-left: 20px;">
                          <span v-if="orderItem.waybillStatusName">{{ orderItem.waybillStatusName }}</span>
                          <span v-else-if="orderItem.tabStatus === 'noTrajectory'">{{ $t('trajectory.NoDataWasQueried') }}</span><!-- 未查询到数据 -->
                          <span v-else-if="orderItem.tabStatus === 'other'">{{ $t('GlobalSetObj.other') }}</span><!-- 其他 -->
                          <span v-else-if="orderItem.tabStatus === 'inTransit'">{{ $t('trajectory.delivering') }}</span><!-- 运输途中 -->
                          <span v-else-if="orderItem.tabStatus === 'delivery'">{{ $t('trajectory.awaitingForPickUp') }}</span><!-- 派送中 -->
                          <span v-else-if="orderItem.tabStatus === 'signed'">{{ $t('trajectory.Signed') }}</span><!-- 已签收 -->
                          <span v-else-if="orderItem.tabStatus === 'abnormal'">{{ $t('trajectory.mayBeAbnormal') }}</span><!-- 可能异常 -->
                        </div>
                        <div v-if="orderItem.trackNodes && orderItem.trackNodes.length > 0" style="margin-left: 20px;">
                          {{ orderItem.trackNodes[0].status }}
                        </div>
                      </div>
                      <div style="margin-left: 20px;width: 130px;display: flex;align-items: center;">
                        <span v-if="orderItem.dateTime">{{ orderItem.dateTime }}</span>
                      </div>
                      <div
                        v-if="orderItem.trackNodes && orderItem.trackNodes.length > 0"
                        style="margin-left: 20px;display: flex;align-items: center;"
                      >
                        {{ orderItem.trackNodes[0].address }}
                      </div>
                    </div>
                  </template>
                  <div
                    v-if="orderItem.trackNodes && orderItem.trackNodes.length > 0"
                    style="border-top: 1px solid #ccc;padding-top: 20px;"
                  >
                    <el-timeline>
                      <el-timeline-item
                        v-for="(item, index) in orderItem.trackNodes"
                        :key="index"
                        :color="index===0?'#409EFF':''"
                        placement="top"
                        :timestamp="`${item.dateTime}；  ${item.status}；  ${item.address}`"
                      />
                    </el-timeline>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-tab-pane>
            <el-tab-pane name="notFound">
              <template slot="label">
                <i class="iconfont icon-shalou" style="margin-right: 5px;color:#757575;" />
                <span style="color:#757575;">
                  {{ $t('trajectory.NoDataWasQueried') }}（{{ orderNumber.notFound.length }}）<!-- 查询不到 -->
                </span>
              </template>
              <el-collapse
                v-if="orderNumber.notFound.length>0"
                v-model="activeName"
                accordion
              >
                <el-collapse-item
                  v-for="(orderItem, orderIndex) in orderNumber.notFound"
                  :key="orderIndex"
                  :title="orderItem.waybillNumber"
                  :name="orderItem.waybillNumber"
                >
                  <template slot="title">
                    <div style="display: flex;">
                      <div style="display: flex;align-items: center;">
                        <div style="width: 30px;">
                          <span
                            class="iconStyle"
                            style="background:#757575;"
                          >
                            <span class="iconfont icon-shalou" style="font-size: 18px;color:#fff;" />
                          </span>
                        </div>
                        <div style="display: flex;align-items: center;">
                          <span style="margin-left: 10px;">{{ orderItem.waybillNumber }}</span>
                        </div>
                        <div style="display: flex;align-items: center;margin-left: 20px;">
                          <span v-if="orderItem.waybillStatusName">{{ orderItem.waybillStatusName }}</span>
                          <span v-else>{{ $t('trajectory.NoDataWasQueried') }}</span><!-- 查询不到 -->
                        </div>
                        <div
                          v-if="orderItem.trackNodes && orderItem.trackNodes.length > 0"
                          style="margin-left: 20px;"
                        >
                          {{ orderItem.trackNodes[0].status }}
                        </div>
                      </div>
                      <div style="margin-left: 20px;width: 130px;display: flex;align-items: center;">
                        <span v-if="orderItem.dateTime">{{ orderItem.dateTime }}</span>
                      </div>
                      <div
                        v-if="orderItem.trackNodes && orderItem.trackNodes.length > 0"
                        style="margin-left: 20px;display: flex;align-items: center;"
                      >
                        {{ orderItem.trackNodes[0].address }}
                      </div>
                    </div>
                  </template>
                </el-collapse-item>
              </el-collapse>
            </el-tab-pane>
            <el-tab-pane name="inTransit">
              <template slot="label">
                <i class="iconfont icon-airTransport" style="margin-right: 5px;color: #2196f3" />
                <span style="color: #2196f3">
                  {{ $t('trajectory.delivering') }}（{{ orderNumber.inTransit.length }}）<!-- 运输途中 -->
                </span>
              </template>
              <el-collapse
                v-if="orderNumber.inTransit.length>0"
                v-model="activeName"
                accordion
              >
                <el-collapse-item
                  v-for="(orderItem, orderIndex) in orderNumber.inTransit"
                  :key="orderIndex"
                  :title="orderItem.waybillNumber"
                  :name="orderItem.waybillNumber"
                >
                  <template slot="title">
                    <div style="display: flex;">
                      <div style="display: flex;align-items: center;">
                        <div style="width: 30px;">
                          <span
                            class="iconStyle"
                            style="background:#2196f3;"
                          >
                            <span class="iconfont icon-airTransport" style="font-size: 16px;color:#fff;" />
                          </span>
                        </div>
                        <div style="display: flex;align-items: center;">
                          <span style="margin-left: 10px;">{{ orderItem.waybillNumber }}</span>
                        </div>
                        <div style="display: flex;align-items: center;margin-left: 20px;">
                          <span v-if="orderItem.waybillStatusName">{{ orderItem.waybillStatusName }}</span>
                          <span v-else>{{ $t('trajectory.delivering') }}</span><!-- 运输途中 -->
                        </div>
                        <div
                          v-if="orderItem.trackNodes && orderItem.trackNodes.length > 0"
                          style="margin-left: 20px;flex: 1;display: flex;align-items: center;"
                        >
                          <span>
                            {{ orderItem.trackNodes[0].status }}
                          </span>
                        </div>
                      </div>
                      <div style="margin-left: 20px;width: 130px;display: flex;align-items: center;">
                        <span>{{ orderItem.dateTime }}</span>
                      </div>
                      <div
                        v-if="orderItem.trackNodes && orderItem.trackNodes.length > 0"
                        style="margin-left: 20px;display: flex;align-items: center;"
                      >
                        <span>
                          {{ orderItem.trackNodes[0].address }}
                        </span>
                      </div>
                    </div>
                  </template>
                  <div
                    v-if="orderItem.trackNodes && orderItem.trackNodes.length > 0"
                    style="border-top: 1px solid #ccc;padding-top: 20px;"
                  >
                    <el-timeline>
                      <el-timeline-item
                        v-for="(item, index) in orderItem.trackNodes"
                        :key="index"
                        :color="index===0?'#409EFF':''"
                        placement="top"
                        :timestamp="`${item.dateTime}；  ${item.status}；  ${item.address}`"
                      />
                    </el-timeline>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-tab-pane>
            <el-tab-pane name="arriveCollect">
              <template slot="label">
                <img :src="paisong" style="width: 19px;height: 19px;float:left;margin-top: 10px;margin-right: 5px;" />
                <span style="color:#0d47a1;">
                  {{ $t('newOrder.Delivering') }}（{{ orderNumber.arriveCollect.length }}）<!-- 派送中 -->
                </span>
              </template>
              <el-collapse
                v-if="orderNumber.arriveCollect.length>0"
                v-model="activeName"
                accordion
              >
                <el-collapse-item
                  v-for="(orderItem, orderIndex) in orderNumber.arriveCollect"
                  :key="orderIndex"
                  :title="orderItem.waybillNumber"
                  :name="orderItem.waybillNumber"
                >
                  <template slot="title">
                    <div style="display: flex;">
                      <div style="display: flex;align-items: center;">
                        <div style="width: 30px;">
                          <span
                            class="iconStyle"
                            style="background:#0d47a1;"
                          >
                            <img :src="paisong1" style="width: 19px;height: 19px;margin-top: 5px;" />
                          </span>
                        </div>
                        <div style="display: flex;align-items: center;">
                          <span style="margin-left: 10px;">{{ orderItem.waybillNumber }}</span>
                        </div>
                        <div style="display: flex;align-items: center;margin-left: 20px;">
                          <span v-if="orderItem.waybillStatusName">{{ orderItem.waybillStatusName }}</span>
                          <span v-else>{{ $t('newOrder.Delivering') }}</span><!-- 派送中 -->
                        </div>
                        <div
                          v-if="orderItem.trackNodes && orderItem.trackNodes.length > 0"
                          style="margin-left: 20px;flex: 1;display: flex;align-items: center;"
                        >
                          <span>
                            {{ orderItem.trackNodes[0].status }}
                          </span>
                        </div>
                      </div>
                      <div style="margin-left: 20px;width: 130px;display: flex;align-items: center;">
                        <span>{{ orderItem.dateTime }}</span>
                      </div>
                      <div
                        v-if="orderItem.trackNodes && orderItem.trackNodes.length > 0"
                        style="margin-left: 20px;display: flex;align-items: center;"
                      >
                        <span>
                          {{ orderItem.trackNodes[0].address }}
                        </span>
                      </div>
                    </div>
                  </template>
                  <div
                    v-if="orderItem.trackNodes && orderItem.trackNodes.length > 0"
                    style="border-top: 1px solid #ccc;padding-top: 20px;"
                  >
                    <el-timeline>
                      <el-timeline-item
                        v-for="(item, index) in orderItem.trackNodes"
                        :key="index"
                        :color="index===0?'#409EFF':''"
                        placement="top"
                        :timestamp="`${item.dateTime}；  ${item.status}；  ${item.address}`"
                      />
                    </el-timeline>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-tab-pane>
            <el-tab-pane name="successfulSignIn">
              <template slot="label">
                <i class="iconfont icon-chenggong" style="margin-right: 5px;color:#43a047;font-size: 13px;" />
                <span style="color:#43a047;">
                  {{ $t('trajectory.Signed') }}（{{ orderNumber.successfulSignIn.length }}）<!-- 已签收 -->
                </span>
              </template>
              <el-collapse
                v-if="orderNumber.successfulSignIn.length>0"
                v-model="activeName"
                accordion
              >
                <el-collapse-item
                  v-for="(orderItem, orderIndex) in orderNumber.successfulSignIn"
                  :key="orderIndex"
                  :title="orderItem.waybillNumber"
                  :name="orderItem.waybillNumber"
                >
                  <template slot="title">
                    <div style="display: flex;">
                      <div style="display: flex;align-items: center;">
                        <div style="width: 30px;">
                          <span
                            class="iconStyle"
                            style="background:#43a047;"
                          >
                            <span class="iconfont icon-chenggong" style="font-size: 16px;color:#fff;" />
                          </span>
                        </div>
                        <div style="display: flex;align-items: center;">
                          <span style="margin-left: 10px;">{{ orderItem.waybillNumber }}</span>
                        </div>
                        <div style="display: flex;align-items: center;margin-left: 20px;">
                          <span v-if="orderItem.waybillStatusName">{{ orderItem.waybillStatusName }}</span>
                          <span v-else>{{ $t('trajectory.DeliveredSuccessfully') }}</span><!-- 成功签收 -->
                        </div>
                        <div
                          v-if="orderItem.trackNodes && orderItem.trackNodes.length > 0"
                          style="margin-left: 20px;flex: 1;display: flex;align-items: center;"
                        >
                          <span>
                            {{ orderItem.trackNodes[0].status }}
                          </span>
                        </div>
                      </div>
                      <div style="margin-left: 20px;width: 130px;display: flex;align-items: center;">
                        <span>{{ orderItem.dateTime }}</span>
                      </div>
                      <div
                        v-if="orderItem.trackNodes && orderItem.trackNodes.length > 0"
                        style="margin-left: 20px;display: flex;align-items: center;"
                      >
                        <span>
                          {{ orderItem.trackNodes[0].address }}
                        </span>
                      </div>
                    </div>
                  </template>
                  <div
                    v-if="orderItem.trackNodes && orderItem.trackNodes.length > 0"
                    style="border-top: 1px solid #ccc;padding-top: 20px;"
                  >
                    <el-timeline>
                      <el-timeline-item
                        v-for="(item, index) in orderItem.trackNodes"
                        :key="index"
                        :color="index===0?'#409EFF':''"
                        placement="top"
                        :timestamp="`${item.dateTime}；  ${item.status}；  ${item.address}`"
                      />
                    </el-timeline>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-tab-pane>
            <el-tab-pane name="itMayBeAbnormal">
              <template slot="label">
                <i class="iconfont icon-yichangtishi" style="margin-right: 5px;color:#ff6f00;" />
                <span style="color: #ff6f00">
                  {{ $t('trajectory.mayBeAbnormal') }}（{{ orderNumber.itMayBeAbnormal.length }}）<!-- 可能异常 -->
                </span>
              </template>
              <el-collapse
                v-if="orderNumber.itMayBeAbnormal.length>0"
                v-model="activeName"
                accordion
              >
                <el-collapse-item
                  v-for="(orderItem, orderIndex) in orderNumber.itMayBeAbnormal"
                  :key="orderIndex"
                  :title="orderItem.waybillNumber"
                  :name="orderItem.waybillNumber"
                >
                  <template slot="title">
                    <div style="display: flex;">
                      <div style="display: flex;align-items: center;">
                        <div style="width: 30px;">
                          <span
                            class="iconStyle"
                            style="background:#ff6f00;"
                          >
                            <span class="iconfont icon-yichangtishi" style="font-size: 18px;color:#fff;" />
                          </span>
                        </div>
                        <div style="display: flex;align-items: center;">
                          <span style="margin-left: 10px;">{{ orderItem.waybillNumber }}</span>
                        </div>
                        <div style="display: flex;align-items: center;margin-left: 20px;">
                          <span v-if="orderItem.waybillStatusName">{{ orderItem.waybillStatusName }}</span>
                          <span v-else>{{ $t('trajectory.mayBeAbnormal') }}</span><!-- 可能异常 -->
                        </div>
                        <div
                          v-if="orderItem.trackNodes && orderItem.trackNodes.length > 0"
                          style="margin-left: 20px;flex: 1;display: flex;align-items: center;"
                        >
                          <span>
                            {{ orderItem.trackNodes[0].status }}
                          </span>
                        </div>
                      </div>
                      <div style="margin-left: 20px;width: 130px;display: flex;align-items: center;">
                        <span>{{ orderItem.dateTime }}</span>
                      </div>
                      <div
                        v-if="orderItem.trackNodes && orderItem.trackNodes.length > 0"
                        style="margin-left: 20px;display: flex;align-items: center;"
                      >
                        <span>
                          {{ orderItem.trackNodes[0].address }}
                        </span>
                      </div>
                    </div>
                  </template>
                  <div
                    v-if="orderItem.trackNodes && orderItem.trackNodes.length > 0"
                    style="border-top: 1px solid #ccc;padding-top: 20px;"
                  >
                    <el-timeline>
                      <el-timeline-item
                        v-for="(item, index) in orderItem.trackNodes"
                        :key="index"
                        :color="index===0?'#409EFF':''"
                        placement="top"
                        :timestamp="`${item.dateTime}；  ${item.status}；  ${item.address}`"
                      />
                    </el-timeline>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-tab-pane>
            <el-tab-pane name="other">
              <template slot="label">
                <span style="padding: 1px 3px;border-radius: 50%;margin-right: 5px;background:#e6a23c;">
                  <i class="el-icon-more" style="color: #fff;font-size: 12px" />
                </span>
                <span style="color: #e6a23c">
                  {{ $t('GlobalSetObj.other') }}（{{ orderNumber.other.length }}）<!-- 其他 -->
                </span>
              </template>
              <el-collapse
                v-if="orderNumber.other.length>0"
                v-model="activeName"
                accordion
              >
                <el-collapse-item
                  v-for="(orderItem, orderIndex) in orderNumber.other"
                  :key="orderIndex"
                  :title="orderItem.waybillNumber"
                  :name="orderItem.waybillNumber"
                >
                  <template slot="title">
                    <div style="display: flex;">
                      <div style="display: flex;align-items: center;">
                        <div style="width: 30px;">
                          <span
                            class="iconStyle"
                            style="background:#e6a23c;"
                          >
                            <span class="el-icon-more" style="font-size: 17px;color:#fff;" />
                          </span>
                        </div>
                        <div style="display: flex;align-items: center;">
                          <span style="margin-left: 10px;">{{ orderItem.waybillNumber }}</span>
                        </div>
                        <div style="display: flex;align-items: center;margin-left: 20px;">
                          <span v-if="orderItem.waybillStatusName">{{ orderItem.waybillStatusName }}</span>
                          <span v-else>{{ $t('GlobalSetObj.other') }}</span><!-- 其他 -->
                        </div>
                        <div
                          v-if="orderItem.trackNodes && orderItem.trackNodes.length > 0"
                          style="margin-left: 20px;flex: 1;display: flex;align-items: center;"
                        >
                          <span>
                            {{ orderItem.trackNodes[0].status }}
                          </span>
                        </div>
                      </div>
                      <div style="margin-left: 20px;width: 130px;display: flex;align-items: center;">
                        <span>{{ orderItem.dateTime }}</span>
                      </div>
                      <div
                        v-if="orderItem.trackNodes && orderItem.trackNodes.length > 0"
                        style="margin-left: 20px;display: flex;align-items: center;"
                      >
                        <span>
                          {{ orderItem.trackNodes[0].address }}
                        </span>
                      </div>
                    </div>
                  </template>
                  <div
                    v-if="orderItem.trackNodes && orderItem.trackNodes.length > 0"
                    style="border-top: 1px solid #ccc;padding-top: 20px;"
                  >
                    <el-timeline>
                      <el-timeline-item
                        v-for="(item, index) in orderItem.trackNodes"
                        :key="index"
                        :color="index===0?'#409EFF':''"
                        placement="top"
                        :timestamp="`${item.dateTime}；  ${item.status}；  ${item.address}`"
                      />
                    </el-timeline>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getTrackByWaybillNumbers } from '@/api/trackQuery';
export default {
  name: 'TrackQuery',
  data() {
    return {
      orderNumber: {
        orderAll: [], // 全部
        notFound: [], // 查询不到
        inTransit: [], // 运输途中
        arriveCollect: [], // 派送中
        successfulSignIn: [], // 已签收
        itMayBeAbnormal: [], // 可能异常
        other: [] // 其他
      },
      orderContainer: '',
      activeName: '',
      queryNumber: 0,
      selectedName: 'all',
      updateCardKey: new Date().getTime(),
      paisong: '/static/paisong.svg',
      paisong1: '/static/paisong1.svg'
    };
  },
  created() {
    // this.getOrderDataList();
  },
  methods: {
    // 重置
    resetInput() {
      this.selectedName = 'all';
      this.orderContainer = '';
      const dataArr = this.getQueryNumber();
      this.queryNumber = dataArr.length;
      this.orderNumber.orderAll = []; // 全部
      this.orderNumber.arriveCollect = []; // 派送中
      this.orderNumber.inTransit = []; // 运输途中
      this.orderNumber.notFound = []; // 查询不到
      this.orderNumber.itMayBeAbnormal = []; // 可能异常
      this.orderNumber.successfulSignIn = []; // 已签收
      this.orderNumber.other = []; // 其他
    },
    // 获取查询订单数量
    getQueryNumber() {
      const orderNum = this.orderContainer.split(/[(\r\n)\r\n]+/);
      const orderNumArr = [];
      for (let j = 0; j < orderNum.length; j++) {
        const orderNumber = orderNum[j].replace(/\s+/g, '');
        if (orderNumber !== '') {
          if (orderNumArr.indexOf(orderNumber) === -1) {
            orderNumArr.push(orderNumber.trim());
          }
        }
      }
      return orderNumArr;
    },
    // 获取订单轨迹
    queryOrder() {
      this.orderNumber.orderAll = []; // 全部
      this.orderNumber.arriveCollect = []; // 派送中
      this.orderNumber.inTransit = []; // 运输途中
      this.orderNumber.notFound = []; // 查询不到
      this.orderNumber.itMayBeAbnormal = []; // 可能异常
      this.orderNumber.successfulSignIn = []; // 已签收
      this.orderNumber.other = []; // 其他
      const dataArr = this.getQueryNumber();
      this.queryNumber = dataArr.length;
      if (dataArr.length === 0) {
        this.$message({
          message: this.$t('orderCenterCont.SearchCriteriaCannotEmpty'),
          type: 'warning'
        });
      } else if (dataArr.length > 500) {
        this.$message({
          message: this.$t('trajectory.UpTo500Queries'),
          type: 'warning'
        });
      } else {
        getTrackByWaybillNumbers(dataArr).then(res => {
          if (res.status === 'OK') {
            this.orderNumber.orderAll = res.data.trackWaybillInfoVoList;
            res.data.trackWaybillInfoVoList.map(item => {
              switch (item.tabStatus) {
                case 'noTrajectory':
                  this.orderNumber.notFound.push(item); // 查询不到
                  break;
                case 'inTransit':
                  this.orderNumber.inTransit.push(item); // 运输途中
                  break;
                case 'delivery':
                  this.orderNumber.arriveCollect.push(item); // 派送中
                  break;
                case 'signed':
                  this.orderNumber.successfulSignIn.push(item); // 已签收
                  break;
                case 'abnormal':
                  this.orderNumber.itMayBeAbnormal.push(item); // 可能异常
                  break;
                case 'other': // 其他
                  this.orderNumber.other.push(item);
                  break;
              }
            });
            this.updateCardKey = new Date().getTime();
            this.activeName = '';
            if (dataArr.length === 1) {
              if (this.orderNumber.notFound.length > 0) {
                this.selectedName = 'notFound';
              } else if (this.orderNumber.inTransit.length > 0) {
                this.selectedName = 'inTransit';
              } else if (this.orderNumber.arriveCollect.length > 0) {
                this.selectedName = 'arriveCollect';
              } else if (this.orderNumber.successfulSignIn.length > 0) {
                this.selectedName = 'successfulSignIn';
              } else if (this.orderNumber.itMayBeAbnormal.length > 0) {
                this.selectedName = 'itMayBeAbnormal';
              } else if (this.orderNumber.other.length > 0) {
                this.selectedName = 'other';
              } else {
                this.selectedName = 'all';
              }
            } else {
              this.selectedName = 'all';
            }
          }
        });
      }
    }
  }
};
</script>

<style>
.order-Type .el-tabs__item{
  padding: 0 10px;
}
.order-Type .iconStyle{
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  text-align: center;
  line-height: 30px;
}
.order-Type .el-timeline-item__timestamp.is-bottom{
  margin-top: 0;
}
.order-Type .el-timeline-item__timestamp{
  color: #021b43;
  font-size: 15px;
  font-weight: bold;
}
.order-Type .el-collapse-item__header{
  line-height: 18px;
}
</style>
