<template>
  <div style="padding: 20px;">
    <div class="wrapperHeaderTable">
      <h2 class="title"> {{ $t('tablePrice.tablePrice') }} </h2>
      <div>
        <button class="buttonOutline" style="width: 160px; margin-right: 20px;" type="button" @click="openDrawer">
          {{ $t('tablePrice.filters') }}
        </button>
        <button class="button" style="width: 160px" type="button" @click="openModal"> {{
          $t('tablePrice.add')
        }}
        </button>
      </div>
    </div>

    <el-table :data="tableDataPrice" :max-height="600" border style="borderradius: 0 0 8px 8px">
      <el-table-column type="index" />

      <el-table-column :label="$t('tablePrice.country')" prop="country" />

      <el-table-column :label="$t('tablePrice.regions')" prop="regions" width="160px" />

      <el-table-column :label="$t('tablePrice.state')" prop="state" />

      <el-table-column :label="$t('tablePrice.city')" prop="city" width="190px" />

      <el-table-column :label="$t('tablePrice.ibgeCodes')" prop="ibgeCodes" width="160px" />

      <el-table-column :label="$t('tablePrice.capitalDistance')" prop="capitalDistance" width="180px" />

      <el-table-column :label="$t('tablePrice.baseDistance')" prop="baseDistance" width="160px" />

      <el-table-column :label="$t('tablePrice.macroRegions')" prop="macroRegions" width="160px" />

      <el-table-column :label="$t('tablePrice.microRegions')" prop="microRegions" width="160px" />

      <el-table-column :label="$t('tablePrice.deliveryPricesZoning')" prop="deliveryPricesZoning" width="160px" />

      <el-table-column :label="$t('tablePrice.minimumShipping')" prop="minimumShipping" width="160px" />

      <el-table-column :label="$t('tablePrice.minZipCode')" prop="minZipCode" width="160px" />

      <el-table-column :label="$t('tablePrice.maxZipCode')" prop="maxZipCode" width="160px" />

      <el-table-column :label="$t('tablePrice.indexZipCode')" prop="indexZipCode" width="160px" />

      <el-table-column :label="$t('tablePrice.localCluster')" prop="localCluster" width="160px" />

      <el-table-column :label="$t('tablePrice.iata')" prop="iata" />

      <el-table-column :label="$t('tablePrice.titularPartner')" prop="titularPartner" width="160px" />

      <el-table-column :label="$t('tablePrice.baseAddress')" prop="baseAddress" width="200px" />

      <el-table-column :label="$t('tablePrice.segment')" prop="segment" width="160px" />

      <el-table-column :label="$t('tablePrice.modal')" prop="modal" width="180px" />

      <el-table-column :label="$t('tablePrice.riskArea')" prop="riskArea" width="160px" />

      <el-table-column :label="$t('tablePrice.lastMileTerm')" prop="lastMileTerm" width="160px" />

      <el-table-column label="kg 0 to 1" prop="kg0To1" width="130px" />

      <el-table-column label="kg 1 to 2" prop="kg1To2" width="130px" />

      <el-table-column label="kg 2 to 3" prop="kg2To3" width="130px" />

      <el-table-column label="kg 3 to 4" prop="kg3To4" width="130px" />

      <el-table-column label="kg 4 to 5" prop="kg4To5" width="130px" />

      <el-table-column label="kg 5 to 6" prop="kg5To6" width="130px" />

      <el-table-column label="kg 6 to 7" prop="kg6To7" width="130px" />

      <el-table-column label="kg 7 to 8" prop="kg7To8" width="130px" />

      <el-table-column label="kg 8 to 9" prop="kg8To9" width="130px" />

      <el-table-column label="kg 9 to 10" prop="kg9To10" width="130px" />

      <el-table-column label="kg 10 to 11" prop="kg10To11" width="130px" />

      <el-table-column label="kg 11 to 12" prop="kg11To12" width="130px" />

      <el-table-column label="kg 12 to 13" prop="kg12To13" width="130px" />

      <el-table-column label="kg 13 to 14" prop="kg13To14" width="130px" />

      <el-table-column label="kg 14 to 15" prop="kg14To15" width="130px" />

      <el-table-column label="kg 15 to 16" prop="kg15To16" width="130px" />

      <el-table-column label="kg 16 to 17" prop="kg16To17" width="130px" />

      <el-table-column label="kg 17 to 18" prop="kg17To18" width="130px" />

      <el-table-column label="kg 18 to 19" prop="kg18To19" width="130px" />

      <el-table-column label="kg 19 to 20" prop="kg19To20" width="130px" />

      <el-table-column label="kg 20 to 21" prop="kg20To21" width="130px" />

      <el-table-column label="kg 21 to 22" prop="kg21To22" width="130px" />

      <el-table-column label="kg 22 to 23" prop="kg22To23" width="130px" />

      <el-table-column label="kg 23 to 24" prop="kg23To24" width="130px" />

      <el-table-column label="kg 24 to 25" prop="kg24To25" width="130px" />

      <el-table-column label="kg 25 to 26" prop="kg25To26" width="130px" />

      <el-table-column label="kg 26 to 27" prop="kg26To27" width="130px" />

      <el-table-column label="kg 27 to 28" prop="kg27To28" width="130px" />

      <el-table-column label="kg 28 to 29" prop="kg28To29" width="130px" />

      <el-table-column label="kg 29 to 30" prop="kg29To30" width="130px" />

      <el-table-column :label="$t('tablePrice.addKg')" prop="addKg" width="160px" />

      <el-table-column :label="$t('tablePrice.createdAt')" prop="createdAt" width="160px" />

      <el-table-column
        :label="$t('collectionCenter.operation')"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          <el-popconfirm
            :title="$t('collectionCenter.deleteSure')"
            @confirm="deleteRow(scope.row.id)"
          >
            <el-button slot="reference" size="mini" type="danger">{{ $t('collectionCenter.Delete') }}</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <el-pagination
        :current-page.sync="currentPage"
        :total="totalPage"
        background
        layout="prev, pager, next"
        @current-change="queryTablePrice"
      />
    </div>

    <modal-vue
      v-if="isOpenModal"
      :is-open="isOpenModal"
      title="Upload da planilha"
      @close="hideModal"
    >
      <form @submit.prevent>
        <label class="wrapperUpload" for="file" @dragover.prevent @drop.prevent @dragleave.prevent>
          <div class="containerUpload">
            <input
              id="file"
              ref="inputFile"
              accept=".xlsx,.xls,.csv"
              class="hideElement"
              name="file"
              title="Nenhum arquivo selecionado"
              type="file"
              @change="onChangeFile"
            >
            <div class="areaUpload" @drop="dropFile">
              <div>
                <svg
                  class="mx-auto"
                  fill="none"
                  height="30"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  width="30"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                  <polyline points="17 8 12 3 7 8" />
                  <line x1="12" x2="12" y1="3" y2="15" />
                </svg>
              </div>
              <p style="font-size: 16px;">Clique ou arraste aqui</p>
            </div>

            <p class="descriptionUpload" style="margin: 0px;">Só é permitido arquivos com extensões de planilhas e um
              único arquivo</p>
          </div>
        </label>

        <div v-if="file.length > 0" style="margin-bottom: 30px;">
          <div class="cardFile">
            <span style="color: #009844;"> {{ file[0].name }} </span>
            <div class="cardRemove" @click="removeFile">
              <svg
                class="lucide lucide-trash"
                fill="none"
                height="24"
                stroke="#FFF"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                width="16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M3 6h18" />
                <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
              </svg>
            </div>
          </div>
        </div>

        <button class="button" @click="uploadFile">Enviar</button>
      </form>
    </modal-vue>

    <el-drawer
      :visible.sync="isOpenDrawer"
      direction="ltr"
      title="Filtros da tabela de preço"
    >
      <form style="padding: 0 20px; " @submit.prevent>
        <div class="formItem">
          <label class="formLabel" for="macroRegions"> {{ $t('tablePrice.macroRegions') }} </label>
          <input
            id="macroRegions"
            v-model="inputMacroRegions"
            :placeholder="$t('tablePrice.placeholderMacroRegion')"
            autocomplete="off"
            class="formInput"
          >
        </div>

        <div class="formItem">
          <label class="formLabel" for="microRegions"> {{ $t('tablePrice.microRegions') }} </label>
          <input
            id="microRegions"
            v-model="inputMicroRegions"
            :placeholder="$t('tablePrice.placeholderMicroRegion')"
            autocomplete="off"
            class="formInput"
          >
        </div>

        <div class="formItem">
          <label class="formLabel" for="country"> {{ $t('tablePrice.country') }} </label>
          <input
            id="country"
            v-model="inputCountry"
            :placeholder="$t('tablePrice.placeholderCountry')"
            autocomplete="off"
            class="formInput"
          >
        </div>

        <div class="formItem">
          <label class="formLabel" for="state"> {{ $t('tablePrice.state') }} </label>
          <input
            id="state"
            v-model="inputState"
            :placeholder="$t('tablePrice.placeholderState')"
            autocomplete="off"
            class="formInput"
          >
        </div>

        <div class="formItem">
          <label class="formLabel" for="city"> {{ $t('tablePrice.city') }} </label>
          <input
            id="city"
            v-model="inputCity"
            :placeholder="$t('tablePrice.placeholderCity')"
            autocomplete="off"
            class="formInput"
          >
        </div>

        <div class="formItem">
          <label class="formLabel" for="maxZipCode"> {{ $t('tablePrice.maxZipCode') }} </label>
          <input
            id="maxZipCode"
            v-model="inputMaxZipCode"
            :placeholder="$t('tablePrice.placeholderMaxZipCode')"
            autocomplete="off"
            class="formInput"
          >
        </div>

        <div class="formItem">
          <label class="formLabel" for="minZipCode"> {{ $t('tablePrice.minZipCode') }} </label>
          <input
            id="minZipCode"
            v-model="inputMinZipCode"
            :placeholder="$t('tablePrice.placeholderMinZipCode')"
            autocomplete="off"
            class="formInput"
          >
        </div>

        <div style="display: flex; justify-content: space-between; margin-top: 40px;">
          <button class="buttonOutline" style="margin-right: 80px;" @click="clearFilter"> {{
            $t('tablePrice.clean')
          }}
          </button>
          <button class="button" @click="filter"> {{ $t('tablePrice.consult') }}</button>
        </div>
      </form>
    </el-drawer>
  </div>
</template>

<script>
import modalVue from './Operation/SingleScan/components/modal';
import { deleteRowPriceTable, getAllPriceTable, uploadWorksheets } from '../../api/priceTableResource';
import dayjs from 'dayjs';

export default {
  components: {
    modalVue
  },
  data() {
    return {
      currentPage: 1,
      totalPage: 1,
      tableDataPrice: [],
      isOpenModal: false,
      file: [],
      inputMacroRegions: '',
      inputMicroRegions: '',
      inputCountry: '',
      inputCity: '',
      inputState: '',
      inputMaxZipCode: '',
      inputMinZipCode: '',
      isOpenDrawer: false
    };
  },
  created() {
    getAllPriceTable()
      .then((res) => {
        if (res.status === 200) {
          this.totalPage = res.data.length;
          this.tableDataPrice = res.data.data.map((item) => {
            return {
              id: item.id,
              country: item.country,
              regions: item.regions,
              state: item.state,
              city: item.city,
              ibgeCodes: item.ibgeCodes,
              capitalDistance: item.capitalDistance,
              baseDistance: item.baseDistance,
              macroRegions: item.macroRegions,
              microRegions: item.microRegions,
              deliveryPricesZoning: item.deliveryPricesZoning,
              minimumShipping: item.minimumShipping,
              minZipCode: item.minZipCode,
              maxZipCode: item.maxZipCode,
              indexZipCode: item.indexZipCode,
              localCluster: item.localCluster,
              iata: item.iata,
              titularPartner: item.titularPartner,
              baseAddress: item.baseAddress,
              segment: item.segment,
              modal: item.modal,
              riskArea: item.riskArea,
              lastMileTerm: item.lastMileTerm,
              kg0To1: item.kg_0_to_1,
              kg1To2: item.kg_1_to_2,
              kg2To3: item.kg_2_to_3,
              kg3To4: item.kg_3_to_4,
              kg4To5: item.kg_4_to_5,
              kg5To6: item.kg_5_to_6,
              kg6To7: item.kg_6_to_7,
              kg7To8: item.kg_7_to_8,
              kg8To9: item.kg_8_to_9,
              kg9To10: item.kg_9_to_10,
              kg10To11: item.kg_10_to_11,
              kg11To12: item.kg_11_to_12,
              kg12To13: item.kg_12_to_13,
              kg13To14: item.kg_13_to_14,
              kg14To15: item.kg_14_to_15,
              kg15To16: item.kg_15_to_16,
              kg16To17: item.kg_16_to_17,
              kg17To18: item.kg_17_to_18,
              kg18To19: item.kg_18_to_19,
              kg19To20: item.kg_19_to_20,
              kg20To21: item.kg_20_to_21,
              kg21To22: item.kg_21_to_22,
              kg22To23: item.kg_22_to_23,
              kg23To24: item.kg_23_to_24,
              kg24To25: item.kg_24_to_25,
              kg25To26: item.kg_25_to_26,
              kg26To27: item.kg_26_to_27,
              kg27To28: item.kg_27_to_28,
              kg28To29: item.kg_28_to_29,
              kg29To30: item.kg_29_to_30,
              createdAt: dayjs(item.createdAt).format('DD/MM/YYYY HH:mm:ss'),
              addKg: item.addKg
            };
          });
        }
      })
      .catch(() => {
        this.$message({
          message: 'Não foi possível carregar os dados',
          type: 'error'
        });
      });
  },
  methods: {
    deleteRow(id) {
      deleteRowPriceTable(id)
        .then((res) => {
          this.$message({
            message: this.$t('tablePrice.success'),
            type: 'success'
          });

          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
        .catch((err) => {
          this.$message({
            message: err.message,
            type: 'error'
          });
        });
    },
    clearFilter() {
      this.inputMacroRegions = '';
      this.inputMicroRegions = '';
      this.inputCountry = '';
      this.inputCity = '';
      this.inputState = '';
      this.inputMaxZipCode = '';
      this.inputMinZipCode = '';
      this.queryTablePrice();
      this.isOpenDrawer = false;
    },
    changeValueTablePriceTable(listPriceTable) {
      this.tableDataPrice = listPriceTable.map((item) => {
        return {
          id: item.id,
          country: item.country,
          regions: item.regions,
          state: item.state,
          city: item.city,
          ibgeCodes: item.ibgeCodes,
          capitalDistance: item.capitalDistance,
          baseDistance: item.baseDistance,
          macroRegions: item.macroRegions,
          microRegions: item.microRegions,
          deliveryPricesZoning: item.deliveryPricesZoning,
          minimumShipping: item.minimumShipping,
          minZipCode: item.minZipCode,
          maxZipCode: item.maxZipCode,
          indexZipCode: item.indexZipCode,
          localCluster: item.localCluster,
          iata: item.iata,
          titularPartner: item.titularPartner,
          baseAddress: item.baseAddress,
          segment: item.segment,
          modal: item.modal,
          riskArea: item.riskArea,
          lastMileTerm: item.lastMileTerm,
          kg0To1: item.kg_0_to_1,
          kg1To2: item.kg_1_to_2,
          kg2To3: item.kg_2_to_3,
          kg3To4: item.kg_3_to_4,
          kg4To5: item.kg_4_to_5,
          kg5To6: item.kg_5_to_6,
          kg6To7: item.kg_6_to_7,
          kg7To8: item.kg_7_to_8,
          kg8To9: item.kg_8_to_9,
          kg9To10: item.kg_9_to_10,
          kg10To11: item.kg_10_to_11,
          kg11To12: item.kg_11_to_12,
          kg12To13: item.kg_12_to_13,
          kg13To14: item.kg_13_to_14,
          kg14To15: item.kg_14_to_15,
          kg15To16: item.kg_15_to_16,
          kg16To17: item.kg_16_to_17,
          kg17To18: item.kg_17_to_18,
          kg18To19: item.kg_18_to_19,
          kg19To20: item.kg_19_to_20,
          kg20To21: item.kg_20_to_21,
          kg21To22: item.kg_21_to_22,
          kg22To23: item.kg_22_to_23,
          kg23To24: item.kg_23_to_24,
          kg24To25: item.kg_24_to_25,
          kg25To26: item.kg_25_to_26,
          kg26To27: item.kg_26_to_27,
          kg27To28: item.kg_27_to_28,
          kg28To29: item.kg_28_to_29,
          kg29To30: item.kg_29_to_30,
          createdAt: dayjs(item.createdAt).format('DD/MM/YYYY HH:mm:ss'),
          addKg: item.addKg
        };
      });
    },
    openDrawer() {
      this.isOpenDrawer = true;
    },
    hideModal() {
      this.isOpenModal = false;
      this.removeFile();
    },
    openModal() {
      this.isOpenModal = true;
    },
    dropFile(e) {
      e.preventDefault();
      const fileList = e.dataTransfer.files;
      this.file = fileList;
      this.$refs.inputFile.files = fileList;
    },
    onChangeFile(e) {
      this.file = e.target.files;
    },
    removeFile() {
      this.file = [];
      this.$refs.inputFile.value = '';
    },
    uploadFile() {
      const formData = new FormData();

      formData.append('file', this.file[0]);
      uploadWorksheets(formData)
        .then((data) => {
          this.$message({
            message: this.$t('tablePrice.success'),
            type: 'success'
          });

          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
        .catch((err) => {
          console.log(err);
          this.$message({
            message: err.message,
            type: 'error'
          });
        });
    },
    filter() {
      const filters = {
        macroRegions: this.inputMacroRegions,
        microRegions: this.inputMicroRegions,
        country: this.inputCountry,
        city: this.inputCity,
        state: this.state,
        maxZipCode: this.maxZipCode,
        minZipCode: this.inputMinZipCode
      };

      getAllPriceTable(0, 10, filters)
        .then((res) => {
          if (res.status === 200) {
            this.totalPage = res.data.length;
            this.changeValueTablePriceTable(res.data.data);
            this.currentPage = 1;
            this.isOpenDrawer = false;
          }
        })
        .catch((e) => {
          this.$message({
            message: 'Não foi possível carregar os dados',
            type: 'error'
          });
        });
    },
    queryTablePrice() {
      const filters = {
        macroRegions: this.inputMacroRegions,
        microRegions: this.inputMicroRegions,
        country: this.inputCountry,
        city: this.inputCity,
        state: this.state,
        maxZipCode: this.maxZipCode,
        minZipCode: this.inputMinZipCode
      };

      const skip = (this.currentPage * 10) - 10;
      getAllPriceTable(skip, 10, filters)
        .then((res) => {
          if (res.status === 200) {
            this.totalPage = res.data.length;
            this.changeValueTablePriceTable(res.data.data);
          }
        })
        .catch((e) => {
          this.$message({
            message: 'Não foi possível carregar os dados',
            type: 'error'
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>

.wrapperHeaderTable {
  border: 1px solid #ebeef5;
  padding: 20px;
  position: relative;
  top: 1px;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 24px;
  margin: 0px;
}

.button {
  cursor: pointer;
  background: #009844;
  border: 2px solid #009844;
  outline: none;
  color: #fff;
  width: 100%;
  border-radius: 4px;
  height: 50px;
  transition: background 0.3s ease-out;
}

.buttonOutline {
  cursor: pointer;
  background: #fff;
  border: 2px solid #009844;
  outline: none;
  width: 100%;
  border-radius: 4px;
  height: 50px;
  transition: background 0.3s ease-out;
}

.button:hover {
  background: #1ba655;
}

.buttonOutline:hover {
  background: #009844;
  color: white;
}

.wrapperUpload {
  border: 2px solid #ebeef5;
  border-radius: 8px;
  padding: 30px 20px;
  margin-bottom: 20px;
  display: block;
  cursor: pointer;
}

.descriptionUpload {
  font-weight: normal;
  text-align: center;
  font-size: 16px;
}

.areaUpload {
  width: 180px;
  height: 180px;
  padding: 10px;
  margin: 0 auto 26px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ebeef5;
}

.hideElement {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.cardFile {
  padding: 10px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardRemove {
  background: #FF1919;
  border-radius: 4px;
  cursor: pointer;
  padding: 3px 8px;
}

.pagination {
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
}

.formItem {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 1rem;
}

.formItem ~ .formItem {
  padding-top: 16px;
}

.inputGroup {
  display: flex;
}

.formLabel {
  font-size: 14px;
  font-weight: normal;
}

.formInput {
  border: 1px solid #d9d9d9;
  line-height: 1.5;
  border-radius: 4px;
  padding: 12px 15px;
  transition: border 0.3s ease-out;
  background: transparent;
  height: 50px;
}

.inputGroup input.formInput {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.formInput:disabled {
  background: #d9d9d9;
}

.formInput:focus {
  border-color: #009844;
}

</style>

