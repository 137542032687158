<script >
import { defineProps, ref } from '@vue/composition-api';
import { useVuelidate } from '@vuelidate/core';
import { FormActions, FormActionsNext, FormActionsPrevious } from '@/components/wizard-form/formStepActions';
import { required, minLength, maxLength, numeric, between, alpha } from '@/utils/validationErrors';
import { driverService } from '@/api/transport/driver';
export async function checkFields() {
  return await v$.value.$validate();
}
const __sfc_main = {};
__sfc_main.props = {
  handleNext: {
    type: Function,
    required: true
  },
  formData: {
    type: Object,
    required: true
  },
  isEdit: {
    type: Boolean,
    default: false
  },
  isLastStep: {
    type: Boolean,
    required: true
  },
  isFirstStep: {
    type: Boolean,
    required: true
  },
  handlePrevious: {
    type: Function
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const rules = {
    name: {
      required
    },
    document: {
      required,
      minLength: minLength(14)
    },
    phone: {
      required,
      minLength: minLength(18),
      maxLength: maxLength(19)
    },
    driverLicense: {
      required,
      minLength: minLength(11)
    },
    scoreLicense: {
      required,
      numeric,
      between: between(1, 40)
    },
    scoreDriver: {
      required,
      numeric,
      between: between(1, 10)
    },
    licenseType: {
      required,
      alpha
    },
    providerName: {
      required
    },
    username: {
      required
    },
    licenseValidate: {
      required
    }
  };
  const v$ = useVuelidate(rules, props.formData);
  async function handleSubmit() {
    const isValid = await v$.value.$validate();
    if (isValid) props.handleNext();
  }
  const listNames = ref([]);
  async function filteredName() {
    try {
      const res = await driverService.getAllByRealName(props.formData.name);
      listNames.value = res.data.users.map(user => {
        return {
          name: user.real_name,
          username: user.username,
          provider: user.provider_code
        };
      });
    } catch (error) {
      listNames.value = [];
      console.log(error);
    }
  }
  function selectedName(value) {
    props.formData.username = value ? value.username : '';
    props.formData.providerName = value ? value.provider : '';
  }
  return {
    v$,
    handleSubmit,
    listNames,
    filteredName,
    selectedName
  };
};
__sfc_main.components = Object.assign({
  FormActions,
  FormActionsPrevious,
  FormActionsNext
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <form class="wrapperForm" @submit.prevent="handleSubmit">
    <o-field
      :label="$t('driver.nameLabel')"
      label-for="name"
      :message="v$.name.$errors.length ? v$.name.$errors[0].$message : null"
      variant="error"
    >
      <o-autocomplete
        id="name"
        v-model="formData.name"
        :disabled="isEdit"
        :placeholder="$t('driver.modalNamePholder')"
        :clearable="!isEdit"
        expanded
        field="name"
        :data="listNames"
        @input="filteredName"
        @select="selectedName"
      >
        <template slot="empty">Sem resultados</template>
      </o-autocomplete>
    </o-field>

    <o-field
      v-mask="['+## (##) ####-####', '+## (##) #####-####']"
      :label="$t('driver.modalPhone')"
      label-for="phone"
      :message="v$.phone.$errors.length ? v$.phone.$errors[0].$message : null"
    >
      <o-input id="phone" v-model="formData.phone" :placeholder="$t('driver.modalPhonePholder')" />
    </o-field>

    <o-field
      v-mask="'###.###.###-##'"
      :label="$t('driver.cpfLabel')"
      label-for="document"
      :message="v$.document.$errors.length ? v$.document.$errors[0].$message : null"
    >
      <o-input id="document" v-model="formData.document" :placeholder="$t('driver.modalCpfPholder')" />
    </o-field>

    <o-field
      v-mask="'###########'"
      :label="$t('driver.modalDriverLicence')"
      label-for="driverLicense"
      :message="v$.driverLicense.$errors.length ? v$.driverLicense.$errors[0].$message : null"
    >
      <o-input id="driverLicense" v-model="formData.driverLicense" :placeholder="$t('driver.modalLicencePholder')" />
    </o-field>

    <o-field
      :label="$t('driver.modalScoreLicence')"
      label-for="scoreLicense"
      :message="v$.scoreLicense.$errors.length ? v$.scoreLicense.$errors[0].$message : null"
    >
      <o-input id="scoreLicense" v-model="formData.scoreLicense" :placeholder="$t('driver.modalScoreLicencePholder')" />
    </o-field>

    <o-field
      :label="$t('driver.modalScoreDriver')"
      label-for="scoreDriver"
      :message="v$.scoreDriver.$errors.length ? v$.scoreDriver.$errors[0].$message : null"
    >
      <o-input id="scoreDriver" v-model="formData.scoreDriver" :placeholder="$t('driver.modalScoreDriverPholder')" />
    </o-field>

    <o-field
      :label="$t('driver.modalLicenseType')"
      label-for="licenseType"
      :message="v$.licenseType.$errors.length ? v$.licenseType.$errors[0].$message : null"
    >
      <o-input id="licenseType" v-model="formData.licenseType" :placeholder="$t('driver.modalLicenseTypePholder')" />
    </o-field>

    <o-field
      :label="$t('driver.modalLicenseValidate')"
      label-for="licenseValidate"
      :message="v$.licenseValidate.$errors.length ? v$.licenseValidate.$errors[0].$message : null"
    >
      <o-input id="licenseValidate" v-model="formData.licenseValidate" :placeholder="$t('scripting.validatyLicensePlaceholder')" type="date" />
    </o-field>

    <o-field
      :label="$t('driver.modalBaseName')"
      label-for="providerName"
      :message="v$.providerName.$errors.length ? v$.providerName.$errors[0].$message : null"
    >
      <o-input id="providerName" v-model="formData.providerName" disabled />
    </o-field>

    <o-field
      :label="$t('driver.modalUsername')"
      label-for="username"
      :message="v$.username.$errors.length ? v$.username.$errors[0].$message : null"
    >
      <o-input id="username" v-model="formData.username" disabled />
    </o-field>

    <div>
      <o-checkbox v-model="formData.outsourced">
        {{ $t('driver.modalOutsourcedDriver') }}
      </o-checkbox>
    </div>

    <FormActions>
      <FormActionsPrevious :is-first-step="isFirstStep" @handlePrevious="handlePrevious" />
      <FormActionsNext :is-last-step="isLastStep" />
    </FormActions>
  </form>
</template>

<style scoped>
.wrapperForm {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem/* 16px */;
  padding-top: 0.5rem/* 8px */;
  padding-bottom: 0.5rem/* 8px */;
}

</style>
