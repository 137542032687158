<template>
  <div>
    <div
      v-permit:remove="'but:finance:basicData:partition:edit'"
      style="margin-bottom: 10px"
    >
      <!--下载模板-->
      <el-button type="primary" size="small" @click="downloadTemplate(1)">{{ $t('basicData.DownloadTheTemplate') }}</el-button>
    </div>
    <div
      v-permit:remove="'but:finance:basicData:partition:edit'"
      style="margin-bottom: 10px"
    >
      <!--导入分区-->
      <upload-excel
        :on-success="importLogistics"
        :but-name="fileName"
      />
    </div>
    <div>
      <!--下载分区-->
      <el-button type="primary" size="small" @click="downloadLogistics">{{ $t('basicData.DownloadThePartition') }}</el-button>
    </div>
  </div>
</template>

<script>
import i18n from '@/lang';
const ExcelJs = require('exceljs');
import { saveAs } from 'file-saver';

const columns = [
  {
    header: i18n.t('basicData.PartitionName'), // 分区名称
    key: 'name',
    width: 40
  },
  {
    header: i18n.t('orderDetails.desRem') + i18n.t('GlobalSetObj.Estado'), // 始发州
    key: 'state',
    width: 40
  },
  {
    header: i18n.t('orderDetails.desRem') + i18n.t('basicData.zipCode'), // 目的地邮编
    key: 'zipcode',
    width: 40
  }
];

const columnsID = {
  header: 'ID', // 目的地邮编
  key: 'id',
  width: 20
};

export default {
  name: 'ImportDownload',
  components: {
    'upload-excel': () => import('@/components/UploadExcel/index')
  },
  props: {
    tableData: {
      type: Object,
      default: () => {
        return {};
      }
    },

    // 所有一级州
    stateList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      fileName: this.$i18n.t('basicData.ImportPartitions')
    };
  },
  methods: {
    elcelStateSelect(wb) {
      // let Sheet2_data = [];

      const Sheet2 = wb.addWorksheet('Sheet2');
      Sheet2.columns = [
        {
          header: this.$t('orderDetails.desRem') + this.$t('GlobalSetObj.Estado'), // '费用类型',
          key: 'state',
          width: 40
        }
      ];
      const arr = this.stateList.map(item => {
        return {
          state: `${item.label}-${item.value}`,
          width: 20
        };
      });
      // Sheet2.addRows(Sheet2_data);
      return arr;
    },

    // 下载模板\下载分区
    downloadTemplate(type) {
      const that = this;
      async function ddd() {
        const wb = new ExcelJs.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');

        Sheet1.mergeCells('A2:B2');
        Sheet1.getCell('A2').value = that.$i18n.t('basicData.PartitionImportRules'); // 注意：分区名称（必填）；邮编（必填，邮编号码段连接符“~”，比如：1001~2001，代表1001到2001之间全部1000个邮编号码）
        Sheet1.getCell('A2').font = {
          color: { argb: 'FF0000' }
        };
        Sheet1.getCell('A2').alignment = { wrapText: true };
        Sheet1.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
        Sheet1.getRow(1).height = 20;
        Sheet1.getRow(2).height = 40;
        Sheet1.columns = columns;

        // 始发站下拉菜单
        let Sheet2_data = [];
        {
          const Sheet2 = wb.addWorksheet('Sheet2');
          Sheet2.columns = [
            {
              header: that.$t('orderDetails.desRem') + that.$t('GlobalSetObj.Estado'), // 始发州,
              key: 'state',
              width: 40
            }
          ];
          Sheet2_data = that.stateList.map(item => {
            return {
              state: `${item.label}-${item.value}`,
              width: 20
            };
          });
          Sheet2.addRows(Sheet2_data);
        }

        new Array(55000).fill(0).forEach((_, idx) => {
          const row = idx + 2;

          // 始发州
          Sheet1.getCell(row, 2).dataValidation = {
            type: 'list',
            formulae: [`=Sheet2!$A$2:$A${Sheet2_data.length + 1}`]
          };
        });

        if (type === 2) {
          columns.push({
            header: 'ID', // 邮编
            key: 'id',
            width: 20
          });
          Sheet1.columns = columns;
          const Sheet1_data = [];
          that.tableData.partitionAffiliateList.map((item) => {
            Sheet1_data.push({
              name: item.partitionIdentifier,
              state: item.state,
              zipcode: item.postalCode,
              id: item.id
            });
          });
          Sheet1.addRows(Sheet1_data);
        }
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        const url = blob;
        const date = new Date().getTime();
        const saveName = type === 2 ? that.tableData.name + date + '.xlsx' : that.$i18n.t('basicData.PartitionImportTemplate') + '.xlsx'; // 导入分区模板
        saveAs.saveAs(url, `${saveName}.xlsx`);
        return;
      }
      ddd();
    },

    // 下载分区
    downloadLogistics() {
      // const wb = new ExcelJs.Workbook();
      const workbook = new ExcelJs.Workbook();
      const Sheet1 = workbook.addWorksheet('Sheet1');

      Sheet1.mergeCells('A2:B2');
      Sheet1.getCell('A2').value = this.$i18n.t('basicData.PartitionImportRules'); // 注意：分区名称（必填）；邮编（必填，邮编号码段连接符“~”，比如：1001~2001，代表1001到2001之间全部1000个邮编号码）
      Sheet1.getCell('A2').font = {
        color: { argb: 'FF0000' }
      };
      Sheet1.getCell('A2').alignment = { wrapText: true };
      Sheet1.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
      Sheet1.getRow(1).height = 20;
      Sheet1.getRow(2).height = 40;
      Sheet1.columns = columns;

      // const worksheet = workbook.addWorksheet('guang111');
      {
        Sheet1.columns = [
          ...columns,
          columnsID
        ];

        const data = this.tableData.partitionAffiliateList.map(item => {
          const { partitionIdentifier, state, postalCode, id } = item;

          let stateStr = '';
          if (state) {
            const row = this.stateList.find(item => item.value === state);
            stateStr = row ? `${row.label}-${row.value}` : '';
          }

          return {
            name: partitionIdentifier,
            state: stateStr,
            zipcode: postalCode,
            id
          };
        });
        Sheet1.addRows(data);
      }

      // 始发站下拉菜单
      let Sheet2_data = [];
      {
        const Sheet2 = workbook.addWorksheet('Sheet2');
        Sheet2.columns = [
          {
            header: this.$t('receivablePayable.OriginatingState'), // '费用类型',
            key: 'state',
            width: 40
          }
        ];
        Sheet2_data = this.stateList.map(item => {
          return {
            state: `${item.label}-${item.value}`,
            width: 20
          };
        });
        Sheet2.addRows(Sheet2_data);
      }

      new Array(55000).fill(0).forEach((_, idx) => {
        const row = idx + 2;

        // 始发州
        Sheet1.getCell(row, 2).dataValidation = {
          type: 'list',
          formulae: [`=Sheet2!$A$2:$A${Sheet2_data.length + 1}`]
        };
      });

      // 浏览器下载
      workbook.xlsx.writeBuffer().then(data => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        saveAs.saveAs(blob, `${Date.now()}.xlsx`);
      });
    },

    // 导入分区
    importLogistics(arr) {
      const arrData = arr[0].results.splice(1);
      const headObj = ['name', 'state', 'zipcode', 'id'];
      const headTextArr = [this.$i18n.t('basicData.PartitionName'), this.$t('orderDetails.desRem') + this.$t('GlobalSetObj.Estado'), this.$t('orderDetails.desRem') + this.$t('basicData.zipCode'), 'ID'];
      // const allData = [];
      const allData = arrData.map(item => {
        const data = {};

        Object.entries(item).forEach((item, i) => {
          const [key, val] = item;

          // 分区名称
          if (key === headTextArr[0]) {
            data[headObj[0]] = val;
          }

          // 始发州(存在的情况)
          if (key === headTextArr[1]) {
            // data[headObj[0]] = val;
            data[headObj[1]] = val.split('-')[1];
          }

          // 邮编
          if (key === headTextArr[2]) {
            data[headObj[2]] = val.toString().replace(/[\uff0c]/g, ',');
          }

          // id
          if (key === headTextArr[3]) {
            data[headObj[3]] = val;
          }

          // 始发州(为非必填项可能不存在)
          if (!data[headObj[1]]) {
            data[headObj[1]] = '';
          }
        });

        return data;
      }).map(item => {
        const { name, state, zipcode } = item;
        return {
          name: name || '',
          state: Number(state) || '',
          zipcode: (zipcode || '').trim(),
          id: item.id || ''

        };
      }).filter(item => {
        if (item.name && item.zipcode) {
          return true;
        }
        return false;
      });

      if (!allData.length) {
        this.$message.error(this.$t('collectionCenter.nullSubmit'));
        return;
      }

      this.$emit('importPartition', allData);
    }
  }
};
</script>

<style scoped>

</style>
