<template>
  <div class="warp">
    <div class="bgcs" :style="lockBgcImgObj" />
    <div class="box">
      <div class="imgs">
        <!-- <img :src="avatar" alt="头像"> -->
        <el-image
          style="width: 150px; height: 150px"
          :src="avatar"
          fit="cover"
        />
      </div>
      <div class="pls">
        <el-input v-model="pwsVal" autocomplete="off" show-password placeholder="请输入" @keyup.enter.native="handlleInp" />
        <el-button type="primary" @click="handlleInp">解锁</el-button>
      </div>
      <p>锁屏密码为登录密码</p>
      <p v-show="isTips" class="tips">密码错误，请重新输入</p>
    </div>

  </div>
</template>

<script>
import { getRSAEncrypt } from '@/utils/RSA_util';
export default {
  data() {
    return {
      lockPws: '',
      avatar: '', // 头像
      lockBgcImgObj: {
        backgroundImage: 'url(' + require('@/assets/images/lockLogin.png') + ')'
      },
      pwsVal: '',
      isTips: false
    };
  },
  created() {
    this.lockPws = getRSAEncrypt(localStorage.getItem('Lock'));
    this.avatar = this.$store.state.user.avatar;
  },

  methods: {
    handleLock() {
      // 解锁
      sessionStorage.setItem('isLock', 0);
      let lastHistory = '';
      try {
        sessionStorage.setItem('isLock', 0);
        lastHistory = sessionStorage.getItem('lastHistory') || '/dashboard';
      } catch (error) {
        console.error(error);
      }

      setTimeout(() => {
        this.$router.replace({ path: lastHistory });
      }, 500);
    },

    // 输入
    handlleInp() {
      const { pwsVal, lockPws, isTips } = this;
      if (pwsVal === lockPws) {
        this.handleLock();
        return;
      }

      if (!isTips) {
        this.isTips = true;
      } else {
        this.isTips = false;
        setTimeout(() => {
          this.isTips = true;
        }, 300);
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    let isLock = 0;
    try {
      isLock = Number(sessionStorage.getItem('isLock')) || 0;
    } catch (error) {
      console.error(error);
    }

    if (isLock === 1) {
      // 锁屏状态
      next(false);
      return;
    }
    next();
  }
};
</script>

<style scoped>
.warp {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.bgcs {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(10px);
}

.box {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10%;
  /* border: 1px solid #000; */
}

.imgs {
  margin: 0 auto;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  /* border: 1px solid red; */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imgs img {
  /* max-height: 100%; */
  max-width: 100%;
}

.pls {
  margin-top: 50px;
  display: flex;
  align-items: center;
}

.tips {
  color: red;
}
</style>
