var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper-collect-day" },
    [
      _c(
        "form",
        { staticClass: "wrapper-filters" },
        [
          _c(
            "o-field",
            {
              staticClass: "flex-1",
              attrs: {
                label: _vm.$t("routes.sellerCode"),
                "label-for": "sellerCode",
              },
            },
            [
              _c("o-input", {
                attrs: { id: "sellerCode" },
                model: {
                  value: _vm.filters.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters, "code", $$v)
                  },
                  expression: "filters.code",
                },
              }),
            ],
            1
          ),
          _c(
            "o-field",
            {
              staticClass: "flex-1",
              attrs: {
                label: _vm.$t("routes.sellerName"),
                "label-for": "sellerName",
              },
            },
            [
              _c("o-input", {
                attrs: { id: "sellerName" },
                model: {
                  value: _vm.filters.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters, "name", $$v)
                  },
                  expression: "filters.name",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "SkeletonTable",
        { attrs: { "is-loading": _vm.isLoadingTable, "qtd-rows": 5 } },
        [
          !_vm.isLoading
            ? _c(
                "o-table",
                {
                  attrs: {
                    data: _vm.tableData,
                    "custom-is-checked": _vm.handleCustomChecked,
                    "row-key": "id",
                    bordered: "",
                    "backend-pagination": "",
                    paginated: "",
                    total: _vm.tableTotal,
                    "per-page": _vm.queryParams.perPage,
                    "current-page": _vm.queryParams.currentPage,
                    "pagination-simple": false,
                    checkable: "",
                    "checked-rows": _vm.listSellers,
                  },
                  on: {
                    "update:currentPage": function ($event) {
                      return _vm.$set(_vm.queryParams, "currentPage", $event)
                    },
                    "update:current-page": function ($event) {
                      return _vm.$set(_vm.queryParams, "currentPage", $event)
                    },
                    check: _vm.handleCheck,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "empty",
                        fn: function () {
                          return [
                            _vm._v(
                              " " + _vm._s(_vm.$t("routes.noResults")) + " "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1173743563
                  ),
                },
                [
                  _c("o-table-column", {
                    attrs: {
                      field: "seller",
                      label: _vm.$t("routes.sellerLabel"),
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    props.row.seller_code +
                                      " - " +
                                      props.row.seller_name
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2680173278
                    ),
                  }),
                  _c("o-table-column", {
                    attrs: {
                      field: "seller_zipcode",
                      label: _vm.$t("routes.cepLabel"),
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _vm._v(
                                " " + _vm._s(props.row.seller_zipcode) + " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      463152141
                    ),
                  }),
                  _c("template", { slot: "footer" }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("routes.sellerQuantity") +
                            ": " +
                            _vm.listSellers.length
                        )
                      ),
                    ]),
                  ]),
                ],
                2
              )
            : _vm._e(),
          !!_vm.v$.listSellers.$errors.length
            ? _c("p", { staticClass: "o-field-message" }, [
                _vm._v(" " + _vm._s(_vm.$t("routes.sellerAlert")) + " "),
              ])
            : _vm._e(),
        ],
        1
      ),
      !!_vm.handleNext
        ? _c(
            "FormActions",
            [
              _c("FormActionsPrevious", {
                attrs: { "is-first-step": _vm.isFirstStep },
              }),
              _c("FormActionsNext", {
                attrs: { "is-last-step": _vm.isLastStep },
                on: { handleSubmit: _vm.handleSubmit },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }