var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c("today-total-operation-data", {
        on: { orderTypeChange: _vm.orderTypeChange },
      }),
      _c("distribution-operation-data", {
        attrs: { "order-type": _vm.orderType },
      }),
      _c(
        "el-row",
        { staticClass: "mt-20", attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 14 } },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "customer-order-status",
                    { attrs: { "total-list": _vm.totalList } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("operationCenter.PleaseSelect"),
                            filterable: "",
                            multiple: "",
                            size: "mini",
                          },
                          on: { change: _vm.getCustomerData },
                          model: {
                            value: _vm.customerIds,
                            callback: function ($$v) {
                              _vm.customerIds = $$v
                            },
                            expression: "customerIds",
                          },
                        },
                        _vm._l(_vm.customerList, function (item, ind) {
                          return _c("el-option", {
                            key: ind,
                            attrs: {
                              label:
                                item.customerAlias + "(" + item.username + ")",
                              value: item.id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c("lineChart", {
                    attrs: {
                      "chart-data": _vm.chartData,
                      "date-data": _vm.dateData,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c("questionItemOperationData", {
                attrs: { "order-type": _vm.orderType },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }