import { apiCloseBag, getRandomBagNumber } from '@/api/singleScanResources';

export async function closeBag(listTrackings, userId, date) {
  if (listTrackings.length > 0) {
    const bagList = listTrackings.map((tracking) => {
      return tracking.packageNumber;
    });

    const res = await getRandomBagNumber();

    const bodyClosebag = {
      // scanUserId: this.$store.state.user.id,
      scanUserId: userId,
      scanTime: date,
      bagNumber: res.data.bagNumber,
      bagList: bagList
    };
    console.log(bodyClosebag);

    await apiCloseBag(bodyClosebag);
  }
}

