<template>
  <div class="app-container">
    <el-card class="box-card" style="margin-bottom: 10px">
      <el-form
        ref="queryForm"
        class="queryFormClass"
        :model="queryForm"
        label-position="top"
        size="small"
      >
        <el-row :gutter="10">
          <el-col :span="7">
            <!--所属供应商-->
            <el-form-item :label="$t('GlobalSetObj.SubordinateSupplier')" prop="providerId">
              <el-select
                v-model="queryForm.params.providerId"
                filterable
                clearable
                style="width: 100%;"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                @change="providerInfoChange()"
              >
                <el-option
                  v-for="item in providerList"
                  :key="item.id"
                  :label="item.providerBody + '（' + item.providerName + '）(' + item.code + ')'"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!--派送网点-->
            <el-form-item :label="$t('GlobalSetObj.DeliveryPoint')" prop="sendSiteId">
              <el-select
                v-model="queryForm.params.sendSiteId"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                style="width: 100%;"
                filterable
                clearable
              >
                <!-- 请先选择应收主体 -->
                <el-option
                  v-for="item in outletsList"
                  :key="item.id"
                  :label="item.name + '（' + item.code + '）'"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!--州-->
            <el-form-item :label="$t('GlobalSetObj.state')" prop="stateId">
              <el-select
                v-model="queryForm.params.stateId"
                clearable
                filterable
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                style="width: 100%;"
                @change="changeState(queryForm.params.stateId)"
              >
                <el-option
                  v-for="(item, ind) in allState"
                  :key="ind"
                  :label="item.twoCode + '(' + item.name + ')'"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!--城市-->
            <el-form-item :label="$t('collectionCenter.city')" prop="cityId">
              <el-select
                v-model="queryForm.params.cityId"
                filterable
                clearable
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                style="width: 100%;"
              >
                <el-option
                  v-for="item in allCity"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex" justify="start">
        <el-col :span="8">
          <div style="margin-top: 10px;">
            <!--查询-->
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="getDataSearch"
            >{{ $t('basicData.Search') }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh"
              size="mini"
              @click="queryFormReset"
            >{{ $t('basicData.reset') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-card>
      <div style="margin-bottom: 10px;">
        <!--添加-->
        <el-button
          v-permit:remove="'btn:finance:dispatchReferenceTable:addEdit'"
          size="mini"
          type="primary"
          icon="el-icon-plus"
          @click="handlerOperation(1)"
        >{{ $t('basicData.Add') }}
        </el-button>
        <!--导入-->
        <el-popover
          placement="bottom-end"
          trigger="hover"
        >
          <div>
            <import-download
              :key="exportKey"
              :provider-list="providerList"
              :all-state="allState"
              :all-city="allCity"
              :outlets-list="outletsList"
              @importPartition="importPartition"
            />
          </div>
          <!--导入-->
          <el-button
            slot="reference"
            v-permit:remove="'btn:finance:dispatchReferenceTable:import'"
            size="mini"
            plain
            style="margin-left: 10px"
          >{{ $t('collectionCenter.Import') }}</el-button>
        </el-popover>
        <!--导出-->
        <el-button
          v-permit:remove="'btn:finance:dispatchReferenceTable:export'"
          size="mini"
          type="primary"
          style="margin-left: 10px"
          @click="exportData()"
        >{{ $t('collectionCenter.export') }}</el-button>
      </div>
      <el-table
        :data="tableData"
        border
        class="table-height"
        :height="tableHeightRow.height"
      >
        <!--序号-->
        <el-table-column
          :label="$t('basicData.serialNumber')" type="index" width="60"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!--派送网点-->
        <el-table-column :label="$t('GlobalSetObj.DeliveryPoint')" prop="name" width="180">
          <template slot-scope="scope">
            <div>{{ scope.row.sendSiteName }}</div>
          </template>
        </el-table-column>
        <!--派送距离（KM）-->
        <el-table-column
          prop="sendDistance"
          :label="$t('basicData.DeliveryDistance') + '(KM)'"
          align="center"
          width="160"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.sendDistance }}</div>
          </template>
        </el-table-column>
        <!--派送邮编-->
        <el-table-column :label="$t('basicData.deliveryPostcode')" width="180" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div style="color: #409eff;cursor: pointer;" @click="handlerOperation(2, scope.row)">{{ scope.row.postalCode }}</div>
          </template>
        </el-table-column>
        <!--州二字码-->
        <el-table-column
          :label="$t('CenterForSorting.StateTwoCode')" prop="state" align="center"
          width="160" :show-overflow-tooltip="true"
        />
        <!--城市-->
        <el-table-column
          :label="$t('collectionCenter.city')" prop="city" width="100"
          align="center" :show-overflow-tooltip="true"
        />
        <!--风险类型-->
        <el-table-column
          :label="$t('basicData.TypeOfRisk')" prop="riskRegion" width="160"
          align="center" :show-overflow-tooltip="true"
        />
        <!--是否启用-->
        <el-table-column
          :label="$t('collectionCenter.ifUse')" prop="applyType" align="center"
          width="120" :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.applyType === 0" type="success">{{ $t('basicData.enable') }}</el-tag>
            <el-tag v-if="scope.row.applyType === 1" type="danger">{{ $t('basicData.disable') }}</el-tag>
          </template>
        </el-table-column>
        <!--风险比例-->
        <el-table-column
          :label="$t('basicData.RiskRatio')" prop="riskRatio" width="160"
          align="center" :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.riskRatio }}%</div>
          </template>
        </el-table-column>
        <!--所属供应商-->
        <el-table-column
          :label="$t('GlobalSetObj.SubordinateSupplier')" prop="providerName" width="180"
          :show-overflow-tooltip="true"
        />
        <!--最后修改人-->
        <el-table-column
          :label="$t('basicData.LastModifier')" prop="updateUser" width="160"
          align="center" :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>{{ userIdAndNameObj[scope.row.updateUser] }}</div>
          </template>
        </el-table-column>
        <!--最后修改时间-->
        <el-table-column
          :label="$t('basicData.LastModified')" prop="updateTime" width="160"
          align="center" :show-overflow-tooltip="true"
        />
        <!--操作-->
        <el-table-column
          :label="$t('basicData.operate')" fixed="right" width="140"
          align="center"
        >
          <template slot-scope="scope">
            <div>
              <!--确定禁用吗？-->
              <!--              <el-popconfirm-->
              <!--                v-if="scope.row.applyType === 1"-->
              <!--                v-permit:remove="'btn:finance:dispatchReferenceTable:addEdit'"-->
              <!--                :title="$t('basicData.AreYouSureDisabled')"-->
              <!--                @confirm="asyncPartitionEdit(scope.row, 0)"-->
              <!--              >-->
              <!--                &lt;!&ndash;禁用&ndash;&gt;-->
              <!--                <el-button slot="reference" class="ml" type="warning" size="mini" style="margin: 0 5px;">{{ $t('basicData.disable') }}</el-button>-->
              <!--              </el-popconfirm>-->
              <!--              &lt;!&ndash;确定启用吗？&ndash;&gt;-->
              <!--              <el-popconfirm-->
              <!--                v-if="scope.row.applyType === 0"-->
              <!--                v-permit:remove="'btn:finance:dispatchReferenceTable:addEdit'"-->
              <!--                :title="$t('basicData.AreYouSureEnabled')"-->
              <!--                @confirm="asyncPartitionEdit(scope.row, 1)"-->
              <!--              >-->
              <!--                &lt;!&ndash;启用&ndash;&gt;-->
              <!--                <el-button slot="reference" class="ml" type="success" size="mini" style="margin: 0 5px;">{{ $t('basicData.enable') }}</el-button>-->
              <!--              </el-popconfirm>-->
              <!--确定删除吗？-->
              <el-popconfirm
                v-permit:remove="'btn:finance:dispatchReferenceTable:delete'"
                :title="$t('basicData.AreYouSureDelete')"
                @confirm="asyncPartitionEdit(scope.row, 3)"
              >
                <!--删除-->
                <el-button
                  slot="reference" class="ml" type="danger"
                  size="mini" style="margin: 0 5px;"
                >{{ $t('basicData.Delete') }}</el-button>
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getCurrentData"
          @size-change="getSizeData"
        />
      </div>
    </el-card>
    <!--添加、详情-->
    <el-drawer
      :title="editType === 1 ? $t('basicData.Add') : $t('basicData.detail')"
      :visible.sync="showDetailCmp"
      direction="ltr"
      size="60%"
    >
      <details-cmp
        :key="editKey"
        :edit-data="editData"
        :all-state="allState"
        :all-city="allCity"
        :provider-list="providerList"
        :outlets-list="outletsList"
        style="height: 100%;"
        @updatePage="updatePage"
      />
    </el-drawer>
  </div>
</template>

<script>
import {
  apiDispatchReferenceExportAll,
  apiAreaPage,
  apiDispatchReferenceImportAdd,
  apiDispatchReferencePage,
  apiProviderPage, apiDispatchReferenceDelete
} from '@/api/finance/basicData';
// import { getDistributionDotList } from '@/api/logisticsOrders';
import detailsCmp from './cmp/detailsCmp';
import ImportDownload from './cmp/ImportDownload';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import { goExportList } from '@/utils/goExportList.js';
import { apiCommonSite } from '@/api/logisticsOrders';

export default {
  name: 'DispatchReferenceTable',
  components: {
    detailsCmp,
    ImportDownload
  },
  mixins: [estimateTableHeight],

  data() {
    return {
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          providerId: '',
          sendSiteId: '',
          cityId: '',
          stateId: ''
        }
      },
      providerList: [],
      outletsList: [],
      allState: [],
      allCity: [],
      tableData: [],
      total: 0,
      showDetailCmp: false,
      editKey: new Date().getTime(),
      editData: null,
      editType: 1,
      exportKey: new Date().getTime()
    };
  },
  created() {
    this.userIdAndNameObj = getUserIdAndNameObj();
    // 获取供应商账号分页列表
    this.getVendorList();
    // 获取派送网点
    this.getSitePageList();
    // 获取所有的州
    this.getAllState({ level: 1, parentId: '' });
    // 获取所有的城市
    this.getAllState({ level: 2, parentId: '' });
    // 查询列表数据
    this.getTableDataList();
  },
  methods: {
    // 导出
    exportData() {
      apiDispatchReferenceExportAll(this.queryForm.params).then(res => {
        goExportList(this);
      });
    },
    // 导入
    importPartition(data) {
      apiDispatchReferenceImportAdd(data).then(res => {
        if (res.status === 'OK') {
          this.$message.success(this.$t('basicData.TheOperationSucceeded'));
          this.queryForm.pageNumber = 1;
          this.getTableDataList();
        }
      });
    },
    // 添加、编辑更新列表
    updatePage(type) {
      if (type === true) {
        this.queryForm.pageNumber = 1;
        this.getTableDataList();
      }
      this.showDetailCmp = false;
    },
    // 翻页
    getCurrentData(val) {
      this.queryForm.pageNumber = val;
      this.getTableDataList();
    },
    // 每页显示数量变化
    getSizeData(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.getTableDataList();
    },
    // 删除
    asyncPartitionEdit(row) {
      apiDispatchReferenceDelete({ id: row.id }).then(res => {
        if (res.status === 'OK') {
          this.$message.success(this.$t('basicData.TheOperationSucceeded'));
          this.queryForm.pageNumber = 1;
          this.getTableDataList();
        }
      });
    },
    // 添加
    handlerOperation(type, row) {
      this.editType = type;
      if (row) {
        this.editData = row;
      } else {
        this.editData = null;
      }
      this.showDetailCmp = true;
      this.editKey = new Date().getTime();
    },
    // 搜索
    getDataSearch() {
      this.queryForm.pageNumber = 1;
      this.getTableDataList();
    },
    // 重置
    queryFormReset() {
      this.queryForm = {
        pageSize: 10,
        pageNumber: 1,
        params: {
          providerId: '',
          sendSiteId: '',
          cityId: '',
          stateId: ''
        }
      };
    },
    // 获取参照列表
    getTableDataList() {
      apiDispatchReferencePage(this.queryForm).then(res => {
        if (res.status === 'OK') {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    // 供应商变化查询网点
    providerInfoChange() {
      this.queryForm.params.sendSiteId = '';
      this.getSitePageList();
    },
    // 州变化查询城市
    changeState(val) {
      this.queryForm.params.city = '';
      this.getAllState({ level: 2, parentId: val });
    },
    // 获取供应商账号分页列表
    getVendorList() {
      const param = {
        pageSize: 99999999,
        pageNumber: 1,
        params: {}
      };
      apiProviderPage(param, false).then(res => {
        if (res.status === 'OK') {
          this.providerList = res.data.records;
        }
      });
    },
    // 获取派送网点
    getSitePageList() {
      const param = {
        pageNumber: 1,
        pageSize: 9999999,
        params: {
          siteStatus: 1,
          providerId: this.queryForm.params.providerId
        }
      };
      apiCommonSite(param, false).then(res => {
        if (res.status === 'OK') {
          this.outletsList = res.data.records;
        }
      });
    },
    // 获取所有的州
    getAllState(row) {
      const param = {
        pageNumber: 1,
        pageSize: 99999,
        params: {
          level: row.level,
          parentId: row.parentId
        }
      };
      apiAreaPage(param, false).then(res => {
        if (res.status === 'OK') {
          if (row.level === 1) {
            this.allState = res.data.records;
          } else {
            this.allCity = res.data.records;
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.queryFormClass .el-form-item--mini.el-form-item,
.queryFormClass .el-form-item--small.el-form-item {
  margin-bottom: 0;
}
</style>
