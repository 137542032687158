var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Box" },
    [
      _c(
        "el-form",
        {
          staticClass: "selectClass",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { width: "auto" }, attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("orderCenterCont.RequestTime") + "：",
                      },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          clearable: false,
                          type: "datetimerange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "default-time": ["00:00:00", "23:59:59"],
                          "range-separator": _vm.$t("collectionCenter.to"),
                          "start-placeholder": _vm.$t(
                            "collectionCenter.StartTime"
                          ),
                          "end-placeholder": _vm.$t("collectionCenter.EndTime"),
                        },
                        on: { change: _vm.dateChange },
                        model: {
                          value: _vm.timeValue,
                          callback: function ($$v) {
                            _vm.timeValue = $$v
                          },
                          expression: "timeValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("collectionCenter.CustomerN") } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t(
                              "collectionCenter.pleaseChoose"
                            ),
                            clearable: "",
                            filterable: "",
                          },
                          on: { focus: _vm.getAllCustomer },
                          model: {
                            value: _vm.queryForm.params.customerId,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm.params, "customerId", $$v)
                            },
                            expression: "queryForm.params.customerId",
                          },
                        },
                        _vm._l(_vm.customerIdList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.username, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("collectionCenter.responseResult") + "：",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t(
                              "collectionCenter.pleaseChoose"
                            ),
                            filterable: "",
                          },
                          model: {
                            value: _vm.queryForm.params.state,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm.params, "state", $$v)
                            },
                            expression: "queryForm.params.state",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("collectionCenter.tudo"),
                              value: null,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.succeed"),
                              value: 1,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.Lose"),
                              value: 0,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                {
                  staticClass: "m BlockNewlineClass",
                  staticStyle: { width: "auto" },
                  attrs: { span: 6 },
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "autoWidth",
                          attrs: {
                            placeholder: _vm.$t(
                              "collectionCenter.pleaseChoose"
                            ),
                          },
                          on: { change: _vm.changeNum },
                          model: {
                            value: _vm.selectNum,
                            callback: function ($$v) {
                              _vm.selectNum = $$v
                            },
                            expression: "selectNum",
                          },
                        },
                        [
                          _c("template", { slot: "prefix" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (
                                    _vm.numList.find(function (item) {
                                      return item.value === _vm.selectNum
                                    }) || {}
                                  ).label
                                ) +
                                " "
                            ),
                          ]),
                          _vm._l(_vm.numList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                        ],
                        2
                      ),
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        on: { blur: _vm.ft },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.BlockNewline($event)
                          },
                        },
                        model: {
                          value: _vm.oddNumber,
                          callback: function ($$v) {
                            _vm.oddNumber = $$v
                          },
                          expression: "oddNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("orderCenterCont.OrderSource") + "：",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t(
                              "collectionCenter.pleaseChoose"
                            ),
                            filterable: "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.queryForm.params.orderSource,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm.params, "orderSource", $$v)
                            },
                            expression: "queryForm.params.orderSource",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("collectionCenter.tudo"),
                              value: null,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("collectionCenter.lanshou"),
                              value: 1,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("basicData.StockingAndPickup"),
                              value: 2,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("collectionCenter.expressDelivery"),
                              value: 3,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:collection-center:Order:orderLogQuery:search",
                      expression:
                        "'btn:collection-center:Order:orderLogQuery:search'",
                      arg: "remove",
                    },
                  ],
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-search",
                  },
                  on: { click: _vm.searchClick },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.search")))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:collection-center:Order:orderLogQuery:reset",
                      expression:
                        "'btn:collection-center:Order:orderLogQuery:reset'",
                      arg: "remove",
                    },
                  ],
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetClick },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:collection-center:Order:orderLogQuery:export",
                      expression:
                        "'btn:collection-center:Order:orderLogQuery:export'",
                      arg: "remove",
                    },
                  ],
                  attrs: { icon: "el-icon-upload2", size: "mini" },
                  on: { click: _vm.download },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.export")))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value:
                        "btn:collection-center:Order:orderLogQuery:allExport",
                      expression:
                        "'btn:collection-center:Order:orderLogQuery:allExport'",
                      arg: "remove",
                    },
                  ],
                  attrs: { icon: "el-icon-upload", size: "mini" },
                  on: { click: _vm.downloadAll },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-table",
        {
          ref: "tableList",
          attrs: { "max-height": 600, border: "", data: _vm.tableDate },
          on: { select: _vm.batchSelect, "select-all": _vm.batchSelect },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.ind"),
              type: "index",
              width: "60",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          (_vm.queryForm.pageNumber - 1) *
                            _vm.queryForm.pageSize +
                            scope.$index +
                            1
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "customerId",
              label: _vm.$t("collectionCenter.clientID"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "customerName",
              label: _vm.$t("collectionCenter.CustomerName"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "customerNumber",
              label: _vm.$t("collectionCenter.customerOrderNum"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "scanNumber",
              label: _vm.$t("collectionCenter.Barcode"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "orderNumber",
              label: _vm.$t("collectionCenter.lanshouOrderNum"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "packageNumber",
              label: _vm.$t("collectionCenter.parcelNum"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "requestParams",
              label: _vm.$t("orderCenterCont.RequestMessages"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-tooltip", { attrs: { placement: "top" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "max-height": "200px",
                            overflow: "auto",
                            width: "500px",
                          },
                          attrs: { slot: "content" },
                          slot: "content",
                        },
                        [_vm._v(_vm._s(scope.row.requestParams))]
                      ),
                      _c("div", { staticClass: "paramsStyle" }, [
                        _vm._v(_vm._s(scope.row.requestParams)),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "stateDesc",
              label: _vm.$t("collectionCenter.responseResult"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "failureMessage",
              label: _vm.$t("GlobalSetObj.ReasonForFailure"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "orderSourceDesc",
              label: _vm.$t("orderCenterCont.OrderSource"),
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-pagination", {
            staticStyle: { margin: "10px 0" },
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              "current-page": _vm.queryForm.pageNumber,
              "page-sizes": [10, 20, 50, 100, 500, 1000],
              "page-size": _vm.queryForm.pageSize,
              total: _vm.total,
              background: "",
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "current-change": _vm.getDatePage,
              "size-change": _vm.getPageSize,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }