var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "o-modal",
    {
      staticClass: "o-container",
      attrs: { active: _vm.active, width: "500" },
      on: {
        close: function ($event) {
          return _vm.emit("handleClose")
        },
      },
    },
    [
      _c("div", [
        _c("h2", { staticClass: "header-2" }, [_vm._t("title")], 2),
        _c(
          "p",
          { staticClass: "paragraph space-paragraph" },
          [_vm._t("message")],
          2
        ),
      ]),
      _c("div", { staticClass: "wapper-buttons" }, [_vm._t("buttons")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }