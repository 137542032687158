var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "el-button",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: { icon: "el-icon-download", size: "small", type: "primary" },
            on: { click: _vm.operationBtn },
          },
          [_vm._v(_vm._s(_vm.$t("receivablePayable.DownloadTheTemplate")))]
        ),
        _c("upload-excel", {
          staticStyle: { display: "inline-block", "margin-left": "20px" },
          attrs: {
            "on-success": _vm.importLogistics,
            "but-name": _vm.$t("receivablePayable.ImportCalculatePrices"),
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }