import Vue from 'vue';

// 注册 表格滚动底部指令
const cascaderLoadmore = Vue.directive('cascaderLoadmore', {
  bind(el, binding) {
    // 获取element-ui定义好的scroll盒子 表格滚动底部事件
    // 获取element-ui定义好的scroll盒子
    const select_dom = el.querySelector('.el-cascader__dropdown .el-cascader-menu__wrap');
    select_dom.addEventListener('scroll', function() {
      const CONDITION = this.scrollHeight - this.scrollTop <= this.clientHeight;
      if (CONDITION) {
        binding.value();
      }
    });
  }
});

export { cascaderLoadmore };
