var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "o-tabs",
        {
          attrs: { multiline: false },
          model: {
            value: _vm.activeTabsVehicle,
            callback: function ($$v) {
              _vm.activeTabsVehicle = $$v
            },
            expression: "activeTabsVehicle",
          },
        },
        [
          _c(
            "o-tab-item",
            { attrs: { value: 0, label: _vm.$t("order.searchVehicle") } },
            [
              _c(
                "form",
                {
                  staticClass: "wrapper-form-search-vehicle",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.handleNextStep($event)
                    },
                  },
                },
                [
                  _c(
                    "o-field",
                    {
                      staticClass: "col-span-2",
                      attrs: {
                        label: _vm.$t("order.vehiclePlate"),
                        message: _vm.v$.plate.$errors.length
                          ? _vm.v$.plate.$errors[0].$message
                          : null,
                      },
                    },
                    [
                      _c(
                        "o-autocomplete",
                        {
                          attrs: {
                            rounded: "",
                            expanded: "",
                            data: _vm.listVehicles,
                            placeholder: _vm.$t(
                              "order.vehiclePlatePlaceholder"
                            ),
                            clearable: "",
                            field: "vehicle_license_plate",
                          },
                          on: {
                            input: _vm.filteredDataVehicles,
                            select: _vm.handleSelectedVehicle,
                          },
                          model: {
                            value: _vm.formData.plate,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "plate", $$v)
                            },
                            expression: "formData.plate",
                          },
                        },
                        [
                          _c("template", { slot: "empty" }, [
                            _vm._v("No results found"),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "o-field",
                    {
                      staticClass: "col-span-2",
                      attrs: { label: _vm.$t("order.document") },
                    },
                    [
                      _c("o-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.vehicleData.document,
                          callback: function ($$v) {
                            _vm.$set(_vm.vehicleData, "document", $$v)
                          },
                          expression: "vehicleData.document",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "o-field",
                    { attrs: { label: _vm.$t("order.capacityM3") } },
                    [
                      _c("o-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.vehicleData.capacityM3,
                          callback: function ($$v) {
                            _vm.$set(_vm.vehicleData, "capacityM3", $$v)
                          },
                          expression: "vehicleData.capacityM3",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "o-field",
                    { attrs: { label: _vm.$t("order.capacityKg") } },
                    [
                      _c("o-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.vehicleData.capacityKG,
                          callback: function ($$v) {
                            _vm.$set(_vm.vehicleData, "capacityKG", $$v)
                          },
                          expression: "vehicleData.capacityKG",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "FormActions",
                    [
                      _c("FormActionsPrevious", {
                        attrs: { "is-first-step": _vm.isFirstStep },
                        on: { handlePrevious: _vm.handlePrevious },
                      }),
                      _c("FormActionsNext", {
                        attrs: { "is-last-step": _vm.isLastStep },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "o-tab-item",
            {
              staticClass: "wrapper-tab",
              attrs: { value: 1, label: _vm.$t("order.createVehicle") },
            },
            [
              _c(
                "o-collapse",
                {
                  staticClass: "content-separator",
                  attrs: { open: _vm.isOpenCollapse == 0 },
                  on: {
                    open: function ($event) {
                      _vm.isOpenCollapse = 0
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "trigger",
                      fn: function (props) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "wrapper-tab-item",
                              attrs: {
                                role: "button",
                                "aria-controls":
                                  "contentIdForA11y5-formDataVehicle",
                                "aria-expanded": _vm.isOpenCollapse,
                              },
                            },
                            [
                              _c("p", { staticClass: "step-name" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("order.vehicleData")) +
                                    " "
                                ),
                              ]),
                              _c(
                                "div",
                                [
                                  _c("o-icon", {
                                    attrs: {
                                      icon: props.open
                                        ? "caret-up"
                                        : "caret-down",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "div",
                    { staticClass: "wrapper-form-step" },
                    [
                      _c("FormVehicleData", {
                        attrs: {
                          "handle-next": _vm.nextCollapseItem,
                          "handle-previous": _vm.previousCollapseItem,
                          "is-last-step": false,
                          "is-first-step": true,
                          "form-data": _vm.formDataVehicle.formStep01,
                        },
                        on: {
                          "update:formData": function ($event) {
                            return _vm.$set(
                              _vm.formDataVehicle,
                              "formStep01",
                              $event
                            )
                          },
                          "update:form-data": function ($event) {
                            return _vm.$set(
                              _vm.formDataVehicle,
                              "formStep01",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "o-collapse",
                {
                  staticClass: "content-separator",
                  attrs: { open: _vm.isOpenCollapse == 1 },
                  on: {
                    open: function ($event) {
                      _vm.isOpenCollapse = 1
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "trigger",
                      fn: function (props) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "wrapper-tab-item",
                              attrs: {
                                role: "button",
                                "aria-controls":
                                  "contentIdForA11y5-formMeasurementVehicle",
                                "aria-expanded": _vm.isOpenCollapse,
                              },
                            },
                            [
                              _c("p", { staticClass: "step-name" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("order.measurementData")) +
                                    " "
                                ),
                              ]),
                              _c(
                                "div",
                                [
                                  _c("o-icon", {
                                    attrs: {
                                      icon: props.open
                                        ? "caret-up"
                                        : "caret-down",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "div",
                    { staticClass: "wrapper-form-step" },
                    [
                      _c("FormMeasurementData", {
                        attrs: {
                          "handle-next": _vm.nextCollapseItem,
                          "handle-previous": _vm.previousCollapseItem,
                          "is-last-step": false,
                          "is-first-step": false,
                          "form-data": _vm.formDataVehicle.formStep02,
                        },
                        on: {
                          "update:formData": function ($event) {
                            return _vm.$set(
                              _vm.formDataVehicle,
                              "formStep02",
                              $event
                            )
                          },
                          "update:form-data": function ($event) {
                            return _vm.$set(
                              _vm.formDataVehicle,
                              "formStep02",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "o-collapse",
                {
                  staticClass: "content-separator",
                  attrs: { open: _vm.isOpenCollapse == 2 },
                  on: {
                    open: function ($event) {
                      _vm.isOpenCollapse = 2
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "trigger",
                      fn: function (props) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "wrapper-tab-item",
                              attrs: {
                                role: "button",
                                "aria-controls":
                                  "contentIdForA11y5-formDataVehicle",
                                "aria-expanded": _vm.isOpenCollapse,
                              },
                            },
                            [
                              _c("p", { staticClass: "step-name" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("order.additionalData")) +
                                    " "
                                ),
                              ]),
                              _c(
                                "div",
                                [
                                  _c("o-icon", {
                                    attrs: {
                                      icon: props.open
                                        ? "caret-up"
                                        : "caret-down",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "div",
                    { staticClass: "wrapper-form-step" },
                    [
                      _c("FormAdditionalData", {
                        attrs: {
                          "handle-next": _vm.nextCollapseItem,
                          "handle-previous": _vm.previousCollapseItem,
                          "is-last-step": true,
                          "is-first-step": false,
                          "form-data": _vm.formDataVehicle.formStep03,
                        },
                        on: {
                          "update:formData": function ($event) {
                            return _vm.$set(
                              _vm.formDataVehicle,
                              "formStep03",
                              $event
                            )
                          },
                          "update:form-data": function ($event) {
                            return _vm.$set(
                              _vm.formDataVehicle,
                              "formStep03",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }