<template>
  <div>
    <el-dialog
      :before-close="emitNetdot"
      :title="$t('orderExport.hint')"
      :visible.sync="netdot"
      width="25%"
    >
      <div class="wrapDialog">
        <div class="selectDot">{{ $t('newOrder.collect') }}</div>
        <el-form ref="addForm" :model="addForm" :rules="addRules" label-width="auto">
          <el-form-item label="" prop="websitId">
            <el-select
              v-model="addForm.websitId"
              :placeholder="$t('newOrder.selectDot')"
              clearable
              filterable
              style="width: 80%"
            >
              <el-option
                v-for="(item, ind) in dotList"
                :key="ind"
                :label="`${item.name}  ${item.zipCode}`"
                :value="item.id"
              >
                <span style="float: left">{{ item.name || "- -" }}</span>
                <span style="font-size: 13px;margin-left: 20px">{{ item.zipCode || "- -" }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="undistributed">{{ $t('newOrder.unallocated') }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="emitNetdot">{{ $t('newOrder.cancel') }}</el-button>
        <el-button type="primary" @click="NetdotSave">{{ $t('newOrder.save') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { DistributionDot } from '@/api/logisticsOrders/index.js';

export default {
  name: 'DistributionDot',
  props: {
    netdot: {
      type: Boolean,
      require: true,
      default: false
    },
    anjunTable: {
      require: true
    },
    closeDot: {
      type: Object,
      require: true,
      default: () => {
      }
    },
    dotList: {
      type: Array,
      require: true,
      default: () => []
    },
    selectionList: {
      type: Array,
      require: true,
      default: () => []
    }
  },
  data() {
    return {
      dialogVisible: true,
      addForm: {
        websitId: ''
      },
      addRules: {
        websitId: [{ required: true, message: this.$t('newOrder.selectDot'), trigger: ['change', 'blur'] }]
      }
    };
  },
  watch: {
    netdot: {
      handler: function(val) {
        console.log(val);
        this.$refs['addForm']?.resetFields();
        this.addForm.websitId = null;
      },
      immediate: true
    }
  },
  created() {
  },
  destroyed() {
  },
  methods: {
    // 点击关闭弹窗
    emitNetdot() {
      this.$emit('closeDot', false);
    },
    // 点击保存,分配网点
    NetdotSave() {
      const _this = this;
      this.$refs['addForm'].validate((valid) => {
        if (valid) {
          if (this.selectionList.length === 0) {
            this.$message.warning(this.$t('newOrder.PleaseSelect'));
            return;
          }
          let ids = [];
          const newArray = this.selectionList.filter((item) => item.waybillStatus == 1);
          if (newArray.length === 0) {
            this.$message.error(this.$t('GlobalSetObj.beenDistributed'));
            return;
          }
          newArray.forEach(item => {
            ids.push(item.id);
          });
          const obj = this.dotList.find(function(item) {
            return item.id === _this.addForm.websitId;
          });
          const data = {};
          data.id = ids;
          data.preCollectStationId = this.addForm.websitId;
          data.preCollectStationName = obj.name;
          DistributionDot(data).then(res => {
            if (res.status === 'OK') {
              _this.$message.success(this.$t('newOrder.OperationSuccessful'));
            }
            ids = [];
            this.emitNetdot();
            this.anjunTable.toggleSelection();
            this.$emit('getSearchFun');
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapDialog {

  .selectDot {
    margin-bottom: 25px;
    color: black;

  }

  .undistributed {
    margin-top: 10px;
    font-size: 12px;
    color: #a4a7ab;
  }
}
</style>
