import { NotificationProgrammatic } from '@oruga-ui/oruga';

export const toast = {
  open(params) {
    NotificationProgrammatic.open(params);
  },
  close() {
    NotificationProgrammatic.closeAll();
  }
};
