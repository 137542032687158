<script >
import { transferRouterService } from '@/api/transfer/transferRoute';
import { useGetDataPackages, useUpdateTruckPackage } from '@/api/transfer/transferRoute/query';
import { default as VtagForm } from './form/VtagForm.vue';
import { default as SealForm } from './form/SealForm.vue';
import { addingLoading, closeLoading } from '@/utils/handleControlLoading';
import { defineProps, inject, onMounted, ref, watch } from '@vue/composition-api';
import { toast } from '@/utils/programaticToast';
import { getToastContentComponent } from '@/utils/getToastContentComponent';
import getMessageError from '@/utils/getMessageError';
const __sfc_main = {};
__sfc_main.props = {
  row: {
    type: Object
  },
  step: {
    type: Number
  },
  handlePreviousStep: {
    type: Function
  },
  handleNextStep: {
    type: Function
  },
  handleShowRouteModal: {
    type: Function
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const vTagData = [];
  const vTagsToOut = ref(vTagData);
  const isInitialPoint = ref(false);
  const form = ref({
    route_transfer_id: null,
    vtag_in: [],
    vtag_out: [],
    vtag_maintained: [],
    transfer_route_id: null,
    truck_seal_in: [],
    truck_seal_out: []
  });
  const baseData = ref({
    initialPoint: {
      pointName: '',
      addressPoint: '',
      idStop: null
    },
    stopPoints: [],
    fullStop: {
      pointName: '',
      addressPoint: '',
      idStop: null
    }
  });
  const addressInfoRoute = ref({
    pointName: '',
    addressPoint: ''
  });
  const dataPackages = ref([]);
  const setAddressInfoRoute = base => {
    addressInfoRoute.value.pointName = '';
    addressInfoRoute.value.addressPoint = '';
    props.handleNextStep();
    if (base === 'initial') {
      addressInfoRoute.value.pointName = baseData.value.initialPoint.pointName;
      addressInfoRoute.value.addressPoint = baseData.value.initialPoint.addressPoint;
      form.value.transfer_route_id = baseData.value.initialPoint.idStop;
      // isInitialPoint.value = true;
      return;
    }
    if (base === 'final') {
      addressInfoRoute.value.pointName = baseData.value.fullStop.pointName;
      addressInfoRoute.value.addressPoint = baseData.value.fullStop.addressPoint;
      form.value.transfer_route_id = baseData.value.fullStop.idStop;
      return;
    }
    addressInfoRoute.value.pointName = base.pointName;
    addressInfoRoute.value.addressPoint = base.addressPoint;
    form.value.transfer_route_id = base.idStop;
  };
  onMounted(async () => {
    try {
      addingLoading();
      const data = await transferRouterService.getById(props.row.route_id);
      form.value.route_transfer_id = data.data.rm_route_transfer.id;
      form.value.transfer_route_id = props.row.route_id;
      const {
        data: packages
      } = await transferRouterService.getDataPackages(data.data.route_transfer_id);

      // PACKAGES MANAGER - VTAG
      if (packages) {
        dataPackages.value = packages;
        const vtagsIn = ref([]);
        const vtagsOut = ref([]);
        packages.forEach(item => {
          vtagsIn.value.push(item.vtag_in);
          vtagsOut.value.push(item.vtag_out);
        });
        const vtagsFiltered = vtagsIn.value.filter(item => !vtagsOut.value.includes(item));
        form.value.vtag_maintained = vtagsFiltered;
      }

      // short hand initial path
      const initialPointPath = data.data.rm_route_transfer.rm_transfer_route[1].rm_shipping_company_base_rm_transfer_route_shipping_company_base_id_initialTorm_shipping_company_base;

      // initial base name
      baseData.value.initialPoint.pointName = initialPointPath.shipping_company_base;

      // set id
      baseData.value.initialPoint.idStop = data.data.rm_route_transfer.rm_transfer_route[1].id;
      baseData.value.fullStop.idStop = data.data.rm_route_transfer.rm_transfer_route[0].id;

      // initial base address
      const {
        shipping_company_base_address: initialBaseAddress,
        shipping_company_base_address_number: initialAddressNumber,
        shipping_company_base_address_complement: initialAddressComplement,
        shipping_company_base_state: initialBaseState,
        shipping_company_base_zipcode: initialBaseZipcode
      } = initialPointPath;
      baseData.value.initialPoint.addressPoint = `${initialBaseAddress}, ${initialAddressNumber}, ${initialAddressComplement}, ${initialBaseState} - ${initialBaseZipcode}`;

      // short hand stops path
      const stopsPath = data.data.rm_route_transfer.rm_transfer_route;

      // point name and address stop points
      for (let i = 0; i < stopsPath.length; i++) {
        const stops = [stopsPath[i].rm_shipping_company_base_rm_transfer_route_shipping_company_base_id_stopsTorm_shipping_company_base];
        if (stops) {
          const stopsFiltered = stops.filter(value => value !== null);
          stopsFiltered.forEach(stop => {
            const pointName = stop.shipping_company_base;
            const idStop = stopsPath[i].id;
            const addressPoint = stop.shipping_company_base_address.concat(', ') + stop.shipping_company_base_address_number.concat(', ') + stop.shipping_company_base_address_complement.concat(', ') + stop.shipping_company_base_state.concat(' - ') + stop.shipping_company_base_zipcode;
            baseData.value.stopPoints.push({
              pointName,
              addressPoint,
              idStop
            });
          });
        }
      }

      // short hand final path
      const finalPointPath = data.data.rm_route_transfer.rm_transfer_route[0].rm_shipping_company_base_rm_transfer_route_shipping_company_base_id_finalTorm_shipping_company_base;

      // final base name
      baseData.value.fullStop.pointName = finalPointPath.shipping_company_base;

      // final base address
      const {
        shipping_company_base_address: baseAddress,
        shipping_company_base_address_number: addressNumber,
        shipping_company_base_address_complement: addressComplement,
        shipping_company_base_state: baseState,
        shipping_company_base_zipcode: baseZipcode
      } = finalPointPath;
      baseData.value.fullStop.addressPoint = `${baseAddress}, ${addressNumber}, ${addressComplement}, ${baseState} - ${baseZipcode}`;
      handleGetVtags();
    } catch (error) {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Ocorreu um erro',
          describe: getMessageError(error),
          typeIcon: 'error'
        }),
        closable: true,
        duration: 4000
      });
    } finally {
      closeLoading();
    }
  });
  const refetchTables = inject('refetchData');
  const {
    mutate
  } = useUpdateTruckPackage();
  const handleSubmit = () => {
    const onSuccess = () => {
      props.handleShowRouteModal();
      refetchTables();
    };
    const onError = () => {
      props.handleShowRouteModal();
    };
    mutate(form.value, {
      onSuccess,
      onError
    });
  };
  const handleNext = () => {
    const conditionInitial = form.value.transfer_route_id !== baseData.value.initialPoint.idStop;
    if (conditionInitial && !form.value.vtag_in.length && !form.value.vtag_out.length) {
      return toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Preencha o campo de saída ou entrada de V-Tags. ',
          typeIcon: 'error'
        }),
        closable: true,
        duration: 4000
      });
    }
    if (form.value.vtag_in.length && form.value.transfer_route_id === baseData.value.fullStop.idStop) {
      return toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Não é permitido adicionar V-Tag no ponto final.',
          typeIcon: 'error'
        }),
        closable: true,
        duration: 4000
      });
    }
    if (props.step === 2) {
      if (conditionInitial && !form.value.truck_seal_in.length && !form.value.truck_seal_out.length) {
        return toast.open({
          variant: 'error',
          position: 'bottom',
          component: getToastContentComponent({
            title: 'Preencha o campo de saída ou entrada de lacres. ',
            typeIcon: 'error'
          }),
          closable: true,
          duration: 4000
        });
      }
      handleSubmit();
      return;
    }
    props.handleNextStep();
  };
  const vTags = ref([]);
  const handleGetVtags = async () => {
    try {
      const response = await transferRouterService.getVtags();
      vTags.value = response.data;
    } catch (error) {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Ocorreu um erro',
          describe: getMessageError(error),
          typeIcon: 'error'
        }),
        closable: true,
        duration: 4000
      });
    }
  };
  return {
    vTagsToOut,
    form,
    baseData,
    addressInfoRoute,
    setAddressInfoRoute,
    handleNext,
    vTags,
    handleGetVtags
  };
};
__sfc_main.components = Object.assign({
  VtagForm,
  SealForm
}, __sfc_main.components);
export default __sfc_main;
</script>
<template>
  <section>

    <section v-show="step === 0" class="space-m-y-32 time-line__container">
      <section class="time-line__section">
        <div class="section-icon">
          <o-icon icon="map-marker-alt" size="large" class="time-line__icon" />
        </div>
        <div @click="setAddressInfoRoute('initial')">
          <h2 class="header-2 time-line__title">{{ baseData.initialPoint.pointName }}</h2>
          <p class="paragraph">{{ baseData.initialPoint.addressPoint }}</p>
        </div>
      </section>

      <section v-for="(item, key) in baseData.stopPoints" :key="key" class="section-stops-point">
        <div class="section-icon-dots">
          <span class="time-line_dot">●</span>
          <span class="time-line_dot">●</span>
        </div>
        <div class="time-line__section">
          <div class="section-icon">
            <o-icon icon="map-pin" size="large" class="time-line__icon time-line__icon-points" />
          </div>
          <div @click="setAddressInfoRoute(item)">
            <h2 class="header-2 time-line__title">{{ item.pointName }}</h2>
            <p class="paragraph">{{ item.addressPoint }}</p>
          </div>
        </div>
      </section>

      <section class="section__full-stop ">
        <div class="section-icon-dots">
          <span class="time-line_dot">●</span>
          <span class="time-line_dot">●</span>
        </div>

        <div class="time-line__section">
          <div class="section-icon">
            <o-icon icon="map-marker-alt" size="large" class="time-line__icon" />
          </div>
          <div @click="setAddressInfoRoute('final')">
            <h2 class="header-2 time-line__title">{{ baseData.fullStop.pointName }}</h2>
            <p class="paragraph">{{ baseData.fullStop.addressPoint }}</p>
          </div>
        </div>

      </section>
    </section>

    <section v-show="step !== 0" class="section__info-route">

      <div class="info-route__title">
        <h2 class="header-3">{{ addressInfoRoute.pointName }}</h2>
        <p class="paragraph">{{ addressInfoRoute.addressPoint }}</p>
      </div>

      <div class="info__route-form">

        <VtagForm
          v-show="step === 1"
          :handle-previous-step="handlePreviousStep"
          :handle-next-step="handleNextStep"
          :form="form"
          :mock-v-tags-out="vTagsToOut"
          :handle-get-vtags="handleGetVtags"
          :v-tags="vTags"
          :base-data="baseData"
        />

        <SealForm v-show="step === 2" :form="form" />

      </div>
      <div class="info__route-footer">
        <o-button
          v-show="step !== 0"
          class="back-button"
          variant="outline"
          size="md"
          @click="handlePreviousStep"
        >Voltar</o-button>
        <o-button v-show="step !== 0" class="back-button" variant="primary" size="md" @click="handleNext">{{ step === 2 ?
          'Enviar' : 'Próximo' }}</o-button>
      </div>

    </section>

  </section>
</template>

<style scoped>
.time-line__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.time-line__section {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  padding: 2px 8px 2px 0;
  cursor: pointer;
}

.time-line__section:hover {
  background-color: hsl(213, 18%, 88%)
}

.paragraph-2 {
  color: hsl(219, 10%, 29%);
  font-size: 0.875rem
    /* 14px */
  ;
  line-height: 1.25rem
    /* 20px */
  ;
}

.section-stops-point {
  display: flex;
  flex-direction: column;
}

.section__full-stop {
  display: flex;
  flex-direction: column;
}

.section-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
}

.time-line__icon {
  color: hsl(var(--o-destructive));
}

.time-line_dot {
  color: hsl(var(--o-muted-foreground));
}

.time-line__icon-points {
  color: orange;
}

.section-icon-dots {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
}

.info-route__title {
  margin: 15px 0 15px 0
}

.back-button {
  margin: 10px 0 10px 0;
}

.header-3 {
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 600;
}

.header-details-route {
  margin-bottom: 10px;
}

.content-separator {
  margin-bottom: 1rem
    /* 16px */
  ;
}

.title-icon {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}

.section__info-route {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.info__route-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
