// 轨迹与状态同步监控

import request from '@/utils/request';

// 分页
export const apiReportTrackStateSyncMonitorPage = data => {
  return request.post('/report/track/state/sync/monitor/page', data);
};

// 导出
export const apiReportTrackStateSyncMonitorExport = data => {
  return request.post('/report/track/state/sync/monitor/export', data);
};
