<template>
  <div>
    <el-form
      ref="ruleForm" :model="ruleForm" :rules="rules"
      label-width="165px" class="demo-ruleForm"
    >
      <el-form-item :label="$t('WorkCentreTable.ticketDescribe')" prop="followUpDescribe">
        <tinymce v-if="isTinymce" v-model="ruleForm.followUpDescribe" :height="200" />
      </el-form-item>
      <!--  -->
      <el-form-item :label="$t('WorkCentreTable.FollowUpStatus')" prop="followUpStatus">
        <el-radio-group v-model="ruleForm.followUpStatus">
          <!-- <el-radio-button label="0">正常跟进</el-radio-button>
          <el-radio-button label="1">完结任务</el-radio-button>
          <el-radio-button label="2">作废任务</el-radio-button> -->
          <el-radio-button label="0">{{ $t('WorkCentreTable.NormalFollowUp') }}</el-radio-button>
          <el-radio-button label="1">{{ $t('WorkCentreTable.FinishTask') }}</el-radio-button>
          <el-radio-button label="2">{{ $t('WorkCentreTable.VoidTask') }}</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <!-- 跟进人 -->
      <el-form-item :label="$t('WorkCentreTable.FollowUpPerson')">
        <el-select
          v-model="ruleForm.follower"
          multiple
          clearable
          filterable
          remote
          :remote-method="asyncGetUserPage"
          :loading="userObj.loading"
          :placeholder="$t('operationCenter.PleaseSelect')"
        >
          <el-option
            v-for="item of userObj.arr"
            :key="item.id"
            :label="item.name + (item.englishName ? `(${item.englishName})` : '')"
            :value="JSON.stringify({ id: item.id, name: item.name, englishName: item.englishName || '' })"
          >
            <span style="float: left">{{ item.name }}{{ item.englishName ? `(${item.englishName})` : '' }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.positionName }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <div class="foot">
          <div />
          <div>
            <el-button type="primary" @click="submitForm('ruleForm')">{{ $t('WorkCentreTable.add') }}</el-button>
            <el-button @click="() => updataData()">{{ $t('WorkCentreTable.cancel') }}</el-button>
          </div>
        </div>

      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Tinymce from '@/components/Tinymce/index';
import { getUserPage } from '@/api/user';
import { FOLLOW_UP_STATUS } from '@/enums/workOrdersEnums';
import { apiWorkMicroTicketFollowUpInfoAdd } from '@/api/workorder';
export default {
  components: {
    Tinymce
  },

  props: {
    ticketId: {
      type: Number,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      isTinymce: false, // 富文本编辑器 第二次进入焦点问题处理
      ruleForm: {
        followUpDescribe: ' ', // 跟进描述
        followUpStatus: this.$t('WorkCentreTable.empty'), // '清空' 跟进状态(0:正常跟进1:完结任务2:作废任务)
        follower: [] // 跟进人
      },
      rules: {
        followUpDescribe: [
          { required: true, message: this.$t('WorkCentreTable.PleaseEnterFollowUpContent'), trigger: 'blur' } // '请输入跟进内容'
        ],
        followUpStatus: [
          { required: true, message: this.$t('WorkCentreTable.PleaseSelectFollowUpStatus'), trigger: 'change' } // '请选择跟进状态'
        ]
      },

      userObj: {
        loading: false,
        arr: []
      }
    };
  },

  watch: {
    index: {
      handler() {
        console.log(111);
        // 清空数据
        this.ruleForm = {
          followUpDescribe: '', // 跟进描述
          followUpStatus: '0', // 跟进状态(0:正常跟进1:完结任务2:作废任务)
          follower: [] // 跟进状态(0:正常跟进1:完结任务2:作废任务)
        };

        this.isTinymce = false;
        setTimeout(() => {
          this.isTinymce = true;
        }, 100);
      },
      immediate: true
    }
  },

  created() {
    this.FOLLOW_UP_STATUS = FOLLOW_UP_STATUS;

    this.asyncGetUserPage('');
  },

  methods: {
    // 取消

    // 提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        }
        const { followUpDescribe, followUpStatus, follower } = this.ruleForm;

        const followerArr = follower.map(item => JSON.parse(item));

        const obj = {
          followUpDescribe,
          followUpStatus,
          ticketId: this.ticketId,
          follower: JSON.stringify(followerArr)
        };
        // console.log('微工单——提交记录', obj);
        this.asyncWorkMicroTicketFollowUpInfoAdd(obj);
      });
    },

    // 添加提交记录
    asyncWorkMicroTicketFollowUpInfoAdd(obj) {
      apiWorkMicroTicketFollowUpInfoAdd(obj).then(res => {
        // const { code } = res;
        if (res.status !== 'OK') { return; }
        this.$message.success(this.$t('WorkCentreTable.AddedSuccessfully')); // '添加成功'

        setTimeout(() => {
          this.updataData(true);
        }, 500);
      }).catch(err => {
        console.error(err);
      });
    },

    updataData(isBool = false) {
      const { followUpStatus } = this.ruleForm;
      this.isTinymce = false;
      const obj = {
        isBool,
        isFollowUpStatus: followUpStatus === '0'
      };
      this.$emit('updataData', obj);
    },

    asyncGetUserPage(val) {
      const obj = {
        pageSize: 20,
        pageNumber: 1,
        params: {
          realName: val,
          accountState: '1'// 启用
        }
      };
      this.userObj.loading = true;
      getUserPage(obj, false).then(res => {
        // const { code, tableData } = res;
        if (res.status !== 'OK') { return; }

        this.userObj.arr = res.data.records.map(item => {
          const { realName, id, positionName, englishName } = item;
          return {
            name: realName,
            id,
            englishName,
            positionName // 职位
          };
        });
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.userObj.loading = false;
      });
    }
  }
};
</script>

<style scoped>
.foot {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
