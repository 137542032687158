<template>
  <div class="appBox">
    <el-form label-width="120px" class="selectClass">
      <el-row class="listQueryTime">
        <el-col :span="10" style="width: auto;">
          <!-- 揽收时间： -->
          <el-form-item :label="$t('collectionCenter.lanshouT')">
            <el-date-picker
              ref="dateRef"
              v-model="queryDate"
              :clearable="false"
              type="datetimerange"
              :default-time="['00:00:00', '23:59:59']"
              value-format="yyyy-MM-dd HH:mm:ss"
              :range-separator="$t('collectionCenter.to')"
              :start-placeholder="$t('collectionCenter.StartTime')"
              :end-placeholder="$t('collectionCenter.EndTime')"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8" style="width: auto;">
          <!-- 揽收人： -->
          <el-form-item :label="$t('collectionCenter.LanShouPeople')">
            <el-select
              v-model="queryForm.params.collectManId"
              filterable
              :placeholder="$t('operationCenter.Only10AreDisplayed')"
              :remote-method="getScanUserId"
              remote
              clearable
              style="width: 100%"
              @focus="getScanUserId()"
            >
              <el-option
                v-for="item in customerList"
                :key="item.id"
                :label="item.realName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8" style="width: auto;">
          <!-- 揽收网点： -->
          <el-form-item :label="$t('collectionCenter.LanShouSite')">
            <el-select
              v-model="queryForm.params.collectManSiteId"
              filterable
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              style="width: 100%"
            >
              <el-option
                v-for="item in siteIdList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8" style="width: auto;">
          <!-- 单号： -->
          <el-form-item :label="$t('collectionCenter.Number')">
            <el-input
              v-model="queryForm.params.scanNumberList"
              clearable
              :placeholder="$t('collectionCenter.BatchQuery')"
              type="textarea"
              :rows="4"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row>
      <el-col :span="12">
        <el-button
          v-permit:remove="'btn:collection-center:order:no-scheduled-pick-up-orders:find'"
          type="primary"
          size="mini"
          icon="el-icon-search"
          @click="searchClick"
        >{{ $t('collectionCenter.search') }}</el-button>
        <!-- 查询 -->
        <el-button
          v-permit:remove="'btn:collection-center:order:no-scheduled-pick-up-orders:reset'"
          icon="el-icon-refresh"
          size="mini"
          @click="resetClick"
        >{{ $t('collectionCenter.reset') }}</el-button>
        <!-- 重置 -->
        <el-button
          v-permit:remove="'btn:collection-center:order:no-scheduled-pick-up-orders:export'"
          icon="el-icon-upload2"
          size="mini"
          type="primary"
          :class="isClickAll ? 'onlyClickOnce10' : ''"
          @click="download"
        >{{ $t('collectionCenter.allExport') }}</el-button>
        <!-- 全部导出 -->
      </el-col>
    </el-row>
    <el-divider />
    <div>
      <el-table
        ref="tableRef"
        border
        :data="tableDate"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <!-- 序号 -->
        <el-table-column
          :label="$t('collectionCenter.ind')" type="index" width="60"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="scanNumber"
          :label="$t('collectionCenter.Number')"
          align="center"
        />
        <el-table-column
          prop="collectUserName"
          :label="$t('collectionCenter.LanShouPeople')"
          align="center"
        />
        <el-table-column
          prop="collectUserSiteName"
          :label="$t('collectionCenter.LanShouSite')"
          align="center"
        />
        <el-table-column
          prop="collectTime"
          :label="$t('collectionCenter.lanshouTime')"
          align="center"
        />
      </el-table>
    </div>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100,500,1000]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="searchClick"
        @size-change="searchClick"
      />
    </div>
  </div>
</template>
<script>
import { unForecastOrderExportAll, unForecastOrderPage } from '@/api/lanshou';
import { getUserPage } from '@/api/user';
import { searchSiteIdList } from '@/api/lanshou';
import dayjs from 'dayjs';
import { setDateTime3 } from '@/utils/date_util';
import { goExportList } from '@/utils/goExportList.js';

export default {
  data() {
    return {
      isClickAll: false,
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          collectManId: '',
          collectManSiteId: '',
          scanNumberList: '' // 单号
        }
      },
      tableDate: [],
      total: 0,
      queryDate: [], // 时间
      customerList: [], // 揽收人列表
      siteIdList: [] // 网点列表
      // pickerOptions: {
      //   disabledDate: (time) => {
      //     // 禁用开始时间大于结束时间的日期时间
      //     if (this.queryDate && this.queryDate.length >= 2) {
      //       return time.getTime() > new Date(this.queryDate[1]).getTime();
      //     }
      //     return false;
      //   }
      // }
    };
  },
  created() {
    // 获取网点列表
    this.getBranchList();
    // queryDate默认显示最近三天的时间
    const currentTime = dayjs();

    // 计算三天前的开始时间
    const startTime = currentTime.clone().subtract(2, 'days').startOf('day');

    // 计算当前时间的前一天的结束时间
    const endTime = currentTime.clone().subtract(0, 'day').endOf('day');

    // 格式化开始时间和结束时间
    const formattedStartTime = startTime.format('YYYY-MM-DD HH:mm:ss');
    const formattedEndTime = endTime.format('YYYY-MM-DD HH:mm:ss');
    this.queryDate = [formattedStartTime, formattedEndTime];
    // 获取列表数据
    this.searchClick();

    this.isClickAll = false;
  },
  methods: {
    /**
       * @description 查询
       */
    searchClick() {
      if (!this.queryDate || !this.queryDate.length) {
        this.$message.error(this.$t('receivablePayable.TheTimeCannotBeEmpty'));
        return;
      }
      // 字符串去除空格和回车符转换为数组
      let scanNumberList = [];
      if (this.queryForm.params.scanNumberList && this.queryForm.params.scanNumberList.length) {
        const order = this.queryForm.params.scanNumberList.split(/[(\r\n)\r\n]+/);
        const NumberList = [];
        order.map((item) => {
          if (item) {
            NumberList.push(item.trim());
          }
        });
        scanNumberList = NumberList;
      } else {
        scanNumberList = [];
      }
      if (scanNumberList.length > 1000) {
        // 提示最大查询1000条
        this.$message.error(this.$t('collectionCenter.maxQuery1000'));
        return;
      }
      let [endTime, startTime] = ['', ''];
      if (this.queryDate && this.queryDate.length) {
        startTime = this.queryDate[0];
        endTime = this.queryDate[1];
      }
      const params = {
        ...this.queryForm,
        params: {
          ...this.queryForm.params,
          scanNumberList,
          endTime,
          startTime
        }
      };
      unForecastOrderPage(params)
        .then((res) => {
          if (res.data.status === 'OK' || res.status === 'OK') {
            this.tableDate = res.data.records.map((item) => {
              if (this.$i18n.locale !== 'zh') {
                item.collectTime = dayjs(item.collectTime).format('DD/MM/YYYY HH:mm:ss');
              } else {
                item.collectTime = setDateTime3(new Date(item.collectTime));
              }
              return item;
            });
            this.total = res.data.total;
          }
        });
    },
    /**
       * @description 重置
       */
    resetClick() {
      this.queryForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          collectManId: '',
          collectManSiteId: '',
          scanNumberList: '' // 单号
        }
      };
      // queryDate默认显示最近三天的时间
      const currentTime = dayjs();
      // 计算三天前的开始时间
      const startTime = currentTime.clone().subtract(2, 'days').startOf('day');
      // 计算当前时间的前一天的结束时间
      const endTime = currentTime.clone().subtract(0, 'day').endOf('day');
      // 格式化开始时间和结束时间
      const formattedStartTime = startTime.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndTime = endTime.format('YYYY-MM-DD HH:mm:ss');
      this.queryDate = [formattedStartTime, formattedEndTime];
    },
    /**
       * @description 导出
       */
    download() {
      this.isClickAll = true;
      if (!this.queryDate || !this.queryDate.length) {
        this.$message.error(this.$t('receivablePayable.TheTimeCannotBeEmpty'));
        return;
      }
      // 字符串去除空格和回车符转换为数组
      let scanNumberList = [];
      if (this.queryForm.params.scanNumberList && this.queryForm.params.scanNumberList.length) {
        const order = this.queryForm.params.scanNumberList.split(/[(\r\n)\r\n]+/);
        const NumberList = [];
        order.map((item) => {
          if (item) {
            NumberList.push(item.trim());
          }
        });
        scanNumberList = NumberList;
      } else {
        scanNumberList = [];
      }
      let [endTime, startTime] = ['', ''];
      if (this.queryDate && this.queryDate.length) {
        startTime = this.queryDate[0];
        endTime = this.queryDate[1];
      }
      const params = {
        ...this.queryForm.params,
        scanNumberList,
        endTime,
        startTime
      };
      unForecastOrderExportAll(params)
        .then((res) => {
          if (res.data.status === 'OK' || res.status === 'OK') {
            this.isClickAll = false;
            goExportList(this);
          }
        }).catch(() => {
          this.isClickAll = false;
        });
    },
    /**
     * @description 获取揽收人列表
     * @param { string } name 用户名称
     */
    getScanUserId(name) {
      const param = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          realName: ''
        }
      };
      if (name) {
        param.params.realName = name;
      }
      getUserPage(param).then(res => {
        if (res.code === 200) {
          this.customerList = res.data.records;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    /**
     * @description 获取网点列表
     */
    getBranchList() {
      searchSiteIdList().then(res => {
        if (res.status === 'OK') {
          res.data.forEach(data => {
            this.siteIdList.push({ id: data.id, name: data.name });
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
// 防止按钮重复点击
@keyframes throttle{
  from{
    filter: opacity(40%);
    pointer-events: none;
  }
  to{
    pointer-events: all;
  }
}
.onlyClickOnce2{
  animation: throttle 2s step-end forwards;
}
.onlyClickOnce2:active{
  filter: opacity(40%);
  animation: none;
}
.onlyClickOnce10{
  animation: throttle 10s step-end forwards;
}
.onlyClickOnce10:active{
  filter: opacity(40%);
  animation: none;
}
.appBox{
  padding: 20px;
  box-sizing: border-box;
}

.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
