<template>
  <div>
    <el-form label-position="top">
      <el-row :gutter="10">
        <el-col :span="6">
          <!-- 路线号： -->
          <el-form-item :label="$t('newOrder.RouteNumber')">
            <el-input
              v-model.trim="queryForm.params.pathNumber"
              :placeholder="$t('CenterForSorting.pleaseInput')"
              clearable
              @keyup.enter.native="getSearchRouter"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!-- 网点名： -->
          <el-form-item :label="$t('newOrder.NetRollCall')">
            <el-input
              v-model.trim="queryForm.params.relevanceSiteName"
              :placeholder="$t('CenterForSorting.pleaseInput')"
              clearable
              @keyup.enter.native="getSearchRouter"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!-- 公司： -->
          <el-form-item prop="searchCompanyType">
            <template slot="label">
              <div class="selectLabel">
                <el-select
                  v-model="queryForm.searchCompanyType"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  :style="IsWidth"
                  @change="handleCompanyType"
                >
                  <el-option key="1" :label="$t('GlobalSetObj.DestinationCompany')" value="1" />
                  <el-option key="2" :label="$t('GlobalSetObj.TransportCompany')" value="2" />
                </el-select>
              </div>
            </template>
            <el-input
              v-model.trim="queryForm.params.company"
              :placeholder="$t('CenterForSorting.pleaseInput')"
              clearable
              @keyup.enter.native="getSearchRouter"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div style="margin-bottom: 20px;">
      <el-button
        size="mini"
        type="primary"
        @click="getSearchRouter"
      >{{ $t('basicData.Search') }}
      </el-button>
      <el-button
        size="mini"
        type="info"
        @click="resetForm"
      >{{ $t('basicData.reset') }}
      </el-button>
      <!--添加-->
      <el-button
        size="mini"
        @click="addRoute"
      >{{ $t('GlobalSetObj.create') }}
      </el-button>
    </div>
    <div>
      <!--  表格数据  -->
      <AnjunTableList
        ref="anjunTable"
        :format-time="isTormat"
        :has-operation="true"
        :header-list="tableHeaderList"
        :is-index="true"
        :is-tip="true"
        :op-width="100"
        :table-data="tableData"
        @on-editBtn="addRoute"
      />
      <!--  分页  -->
      <div class="right">
        <el-pagination
          :current-page.sync="queryForm.pageNumber"
          :page-size.sync="queryForm.pageSize"
          :page-sizes="[10, 20, 50, 100, 500]"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          style="margin: 10px 0"
          @current-change="handleSizeChange"
          @size-change="handleCurrentChange"
        />
      </div>
    </div>
    <!--新建路线-->
    <el-drawer
      :append-to-body="true"
      :title="titleDialog === 1 ? `${$t('GlobalSetObj.newRouter')}` : `${$t('GlobalSetObj.editRouter')}`"
      :visible.sync="showAddData"
      custom-class="drawerClass"
      direction="ltr"
      size="60%"
      @close="clearAddEditSiteForm"
    >
      <div style="padding-left: 20px;height: calc(100vh - 120px);overflow-y: auto;">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :key="0" :label="$t('GlobalSetObj.other')" name="first">
            <el-form :key="showAddData" ref="addForm" :model="addEditForm" label-position="top">
              <el-row>
                <el-col :span="12">
                  <!-- 路线号： -->
                  <el-form-item
                    :label="$t('newOrder.RouteNumber')"
                    :rules="rulesRouteNumber"
                    prop="pathNumber"
                  >
                    <template slot="label">
                      <span>{{ $t('newOrder.RouteNumber') }}</span>
                      <el-tooltip class="QuestionMark" placement="top">
                        <div slot="content">{{ $t('GlobalSetObj.ThisRouteNumber') }}</div>
                        <svg-icon icon-class="QuestionMark" />
                      </el-tooltip>
                    </template>
                    <el-input
                      v-model.trim="addEditForm.pathNumber"
                      :placeholder="$t('CenterForSorting.pleaseInput')"
                      clearable
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="24"><span style="color: red">*</span>{{ $t('GlobalSetObj.RouteEndAddress') }}</el-col>
                <el-col :span="22" style="margin-left: 25px">
                  <el-row :gutter="50" style="background-color: #f2f2f2;padding: 10px 20px">
                    <el-col :span="18">
                      <!-- 终点公司： -->
                      <el-form-item
                        :label="$t('GlobalSetObj.DestinationCompany')"
                        :rules="providerNameCompany"
                        prop="providerName"
                      >
                        <el-select
                          v-model="addEditForm.providerName"
                          v-loadmore="loadmoreQuery"
                          :placeholder="$t('GlobalSetObj.pleaseChoose')"
                          clearable
                          filterable
                          style="width: 100%;"
                          @change="handleproviderName"
                        >
                          <el-option
                            v-for="(item, ind) in supplierMainList"
                            :key="ind"
                            :label="item.providerName"
                            :value="item.id"
                          />
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="9">
                      <!-- 电话： -->
                      <el-form-item :label="$t('GlobalSetObj.userPhone')" :rules="isPhone" prop="telephone">
                        <el-input
                          v-model.trim="addEditForm.telephone"
                          :placeholder="$t('GlobalSetObj.pleaseInput')"
                          clearable
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="9">
                      <!-- 邮编：-->
                      <el-form-item :label="$t('GlobalSetObj.zipCode')" :rules="isZipCode" prop="postcode">
                        <el-input
                          v-model.trim="addEditForm.postcode"
                          :placeholder="$t('GlobalSetObj.pleaseInput')"
                          clearable
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="9">
                      <!-- 州： -->
                      <el-form-item
                        :label="$t('placeOrder.State')"
                        :rules="[{ required: true, message: $t('placeOrder.pleaseChoose'), trigger: ['blur', 'change'] }]"
                        prop="state"
                      >
                        <el-select
                          v-model="addEditForm.state"
                          :placeholder="$t('placeOrder.pleaseChoose')"
                          clearable
                          filterable
                          style="width: 100%;"
                        >
                          <el-option
                            v-for="item in senderStateList"
                            :key="item.id"
                            :label="item.label"
                            :value="item.label"
                          />
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="9">
                      <!-- 城市： -->
                      <el-form-item
                        :label="$t('placeOrder.city')"
                        :rules="{ required: true, message: $t('placeOrder.pleaseInput'), trigger: ['blur','change'] }"
                        prop="city"
                      >
                        <el-input
                          v-model.trim="addEditForm.city"
                          :placeholder="$t('GlobalSetObj.pleaseInput')"
                          clearable
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="9">
                      <!-- 区： -->
                      <el-form-item :label="$t('placeOrder.Area')" prop="area">
                        <el-input
                          v-model.trim="addEditForm.area"
                          :placeholder="$t('GlobalSetObj.pleaseInput')"
                          clearable
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="9">
                      <!-- 街道： -->
                      <el-form-item :label="$t('newOrder.Street')" prop="Street">
                        <el-input
                          v-model.trim="addEditForm.Street"
                          :placeholder="$t('GlobalSetObj.pleaseInput')"
                          clearable
                          maxlength="50"
                          show-word-limit
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="9">
                      <!-- 门牌号： -->
                      <el-form-item :label="$t('placeOrder.HouseNumber')" prop="HouseNumber">
                        <el-input
                          v-model.trim="addEditForm.HouseNumber"
                          :placeholder="$t('GlobalSetObj.pleaseInput')"
                          clearable
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <!-- 详细地址： -->
                      <el-form-item
                        :label="$t('placeOrder.detailedAddress')"
                        :rules="[{ required: true, message: $t('placeOrder.pleaseInput'), trigger: ['blur','change'] },
                                 { min: 2, max: 200, message: $t('newOrder.TdetailedAddressCharacter'), trigger: 'blur' }]"
                        prop="Address"
                      >
                        <el-input
                          v-model="addEditForm.Address"
                          :placeholder="$t('newOrder.StreetHouseNumber')"
                          clearable
                          maxlength="200"
                          show-word-limit
                          type="text"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="22">
                  <!-- 关联网点： -->
                  <el-form-item
                    :label="$t('GlobalSetObj.AssociatedOutlets')"
                    prop="associatedOutlets"
                  >
                    <template slot="label">
                      <span>{{ $t('GlobalSetObj.AssociatedOutlets') }}</span>
                      <el-tooltip class="QuestionMark" placement="top">
                        <div slot="content">{{ $t('GlobalSetObj.OutletsThatShareTheRouteNumber') }}</div>
                        <svg-icon icon-class="QuestionMark" />
                      </el-tooltip>
                    </template>
                    <el-button
                      size="medium"
                      type="primary"
                      @click="selectSites"
                    >{{ $t('GlobalSetObj.SelectSites') }}
                    </el-button>
                    <div v-if="addEditForm.associatedOutlets.length > 0" style="padding: 5px;border: 1px solid #ccc;">
                      <div
                        v-for="(item, ind) in addEditForm.associatedOutlets"
                        :key="ind"
                        class="tabless"
                      >
                        <div style="width: 100%;display: flex;">
                          <div class="wordBreak" style="max-width: 280px;">{{ item.name }}</div>
                          <div style="cursor: pointer;font-size: 24px;margin-left: 5px;" @click="deleteSite(item)">×
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="22">
                  <!-- 运输公司： -->
                  <el-form-item
                    :label="$t('GlobalSetObj.TransportCompany')"
                    :rules="rulesTransportCompany"
                    prop="transportCompanyId"
                  >
                    <el-select
                      v-model="addEditForm.transportCompanyId"
                      :placeholder="$t('GlobalSetObj.pleaseChoose')"
                      clearable
                      filterable
                      style="width: 50%"
                      @change="handleTransportCompany"
                      @focus="gettransportCompany"
                    >
                      <el-option
                        v-for="(item) in transportCompanyList"
                        :key="item.id"
                        :label="item.providerName"
                        :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="22">
                  <!-- 备注： -->
                  <el-form-item :label="$t('GlobalSetObj.remark')" prop="remark">
                    <el-input
                      v-model.trim="addEditForm.remark"
                      :placeholder="$t('CenterForSorting.pleaseInput')"
                      clearable
                      maxlength="200"
                      rows="2"
                      show-word-limit
                      type="textarea"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-tab-pane>
          <!--  卸货点    -->
          <el-tab-pane :key="1" :label="$t('GlobalSetObj.UnloadingPoint')" name="second">
            <el-row>
              <el-col :span="23">
                <el-table-draggable
                  ref="el-table-draggable"
                  :animate="300"
                  @drop="dropTable"
                >
                  <el-table
                    ref="machineTable"
                    :data="UnloadingPointList"
                    border
                    row-key="siteId"
                  >
                    <el-table-column
                      :label="$t('GlobalSetObj.sort')"
                      min-width="60"
                    >
                      <template>
                        <i class="el-icon-s-operation" />
                      </template>
                    </el-table-column>
                    <el-table-column
                      :label="$t('GlobalSetObj.nomePoint')"
                      min-width="300"
                      prop="UnloadingPointNameOrnetName"
                    />
                    <el-table-column
                      :label="$t('GlobalSetObj.CityState')"
                      min-width="200"
                      prop="cityZhou"
                    />
                    <el-table-column
                      :label="$t('GlobalSetObj.zipCode')"
                      :show-overflow-tooltip="true"
                      min-width="90"
                      prop="zipCode"
                    />
                    <el-table-column :label="$t('GlobalSetObj.operate')">
                      <template slot-scope="props">
                        <div style="display: flex;justify-content: center;align-items: center;">
                          <div v-if="props.row.pathwayState">
                            <span style="cursor: pointer" @click="stateDialog(props.row)">
                              <el-tooltip
                                :content="$t('GlobalSetObj.pathwayState')"
                                class="item"
                                effect="dark"
                                placement="top"
                              >
                                <svg-icon icon-class="pathwayState" />
                              </el-tooltip>
                            </span>
                          </div>
                          <div v-if="props.row.editBtn && props.row.pathwayState" style="padding: 0 10px" />
                          <div v-if="props.row.editBtn">
                            <el-tooltip
                              :content="`${props.row.operatorTip ? $t('GlobalSetObj.EditUnloadPoint') : $t('GlobalSetObj.EditingDot')} `"
                              class="item"
                              effect="dark"
                              placement="top"
                            >
                              <i
                                class="el-icon-edit"
                                style="color: #409eff;font-size: 18px;cursor: pointer;margin-top: 10px"
                                @click="toDot(props.row)"
                              />
                            </el-tooltip>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-table-draggable>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div style="padding-top: 6px;padding-right: 10px; text-align: right;border-top: 1px solid #ccc;">
        <el-button style="margin-right: 20px" @click="showAddData = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button v-if="activeName === 'first'" type="primary" @click="saveRouter">{{
          $t('GlobalSetObj.confirm')
        }}
        </el-button>
        <el-button v-if="activeName === 'second'" type="primary" @click="saveUnloading">{{
          $t('GlobalSetObj.confirm')
        }}
        </el-button>
      </div>
    </el-drawer>
    <!--选择网点-->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="true"
      :title="$t('GlobalSetObj.SelectSites')"
      :visible.sync="showSelectSites"
      top="2vh"
      width="70%"
      @close="allCancelSelect"
    >
      <div>
        <el-form label-position="top">
          <el-row :gutter="10">
            <el-col :span="8">
              <!-- 网点名： -->
              <el-form-item :label="$t('newOrder.NetRollCall')">
                <el-input
                  v-model.trim="querySiteForm.params.name"
                  :placeholder="$t('CenterForSorting.pleaseInput')"
                  clearable
                  @blur="searchSite"
                  @keyup.enter.native="searchSite"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <!-- 网点邮编： -->
              <el-form-item :label="$t('GlobalSetObj.dotZipCode')">
                <el-input
                  v-model.trim="querySiteForm.params.zipCode"
                  :placeholder="$t('CenterForSorting.pleaseInput')"
                  clearable
                  @blur="searchSite"
                  @keyup.enter.native="searchSite"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-row :gutter="10">
          <el-col :span="12">
            <div style="line-height: 30px">
              <!--全部-->
              <span>{{ $t('GlobalSetObj.all') }}：{{ siteTotal }}</span>
              <span style="float:right;">
                <!--全部选中-->
                <el-button size="small" type="text" @click="allSelect">{{ $t('GlobalSetObj.SelectAll') }}</el-button>
              </span>
            </div>
            <el-table
              :data="tableDataLeft"
              :height="500"
              border
              style="width: 100%"
            >
              <!--网点名-->
              <el-table-column
                :label="$t('newOrder.NetRollCall')"
                min-width="160"
                prop="name"
              />
              <!--网点邮编-->
              <el-table-column
                :label="$t('GlobalSetObj.dotZipCode')"
                align="center"
                min-width="120"
                prop="zipCode"
              />
              <el-table-column
                :label="$t('GlobalSetObj.operate')"
                align="center"
                fixed="right"
                prop="date"
              >
                <template slot-scope="scope">
                  <el-button
                    :class="selectedSiteId.includes(scope.row.id) ? 'cancelSelectColor' : 'selectColor'"
                    size="mini"
                    style="cursor: pointer"
                    type="text"
                    @click="selectedSites(scope.row)"
                  >
                    {{ $t('GlobalSetObj.Selected') }}
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              :current-page="querySiteForm.pageNumber"
              :page-size="querySiteForm.pageSize"
              :page-sizes="[10, 20]"
              :pager-count="5"
              :total="siteTotal"
              background
              layout="sizes, prev, pager, next"
              style="margin: 10px 0;float: right"
              @size-change="handleSizeChangeSite"
              @current-change="handleCurrentChangeSite"
            />
          </el-col>
          <el-col :span="12">
            <div style="line-height: 30px">
              <!--已选-->
              <span>{{ $t('GlobalSetObj.selected') }}：{{ tableDataRight.length }}</span>
              <span style="float:right;">
                <!--全部取消-->
                <el-button size="small" type="text" @click="allCancelSelect">{{
                  $t('GlobalSetObj.CancelAll')
                }}</el-button>
              </span>
            </div>
            <el-table
              :data="tableDataRight"
              :height="500"
              border
              style="width: 100%"
            >
              <!--网点名-->
              <el-table-column
                :label="$t('newOrder.NetRollCall')"
                min-width="160"
                prop="name"
              />
              <!--网点邮编-->
              <el-table-column
                :label="$t('GlobalSetObj.dotZipCode')"
                align="center"
                min-width="120"
                prop="zipCode"
              />
              <el-table-column
                :label="$t('GlobalSetObj.operate')"
                align="center"
                fixed="right"
                prop="date"
              >
                <template slot-scope="scope">
                  <div>
                    <el-button size="mini" type="text" @click="cancelSelectedSites(scope)">{{
                      $t('basicData.Cancel')
                    }}
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="showSelectSites = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button size="small" type="primary" @click="saveSelectSite">{{ $t('GlobalSetObj.confirm') }}</el-button>
      </div>
    </el-dialog>
    <!--  途径州  -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="true"
      :title="$t('GlobalSetObj.pathwayState')"
      :visible.sync="StateDialog"
      :width="IsMdfe"
      top="15vh"
      @close="clearForm"
    >
      <el-row class="mdfeClass">
        <el-col :span="24" style="margin-bottom: 20px">
          <div style="color: #f96514;font-size: 16px;margin-bottom: 10px">{{ $t('GlobalSetObj.selectTheRoute') }}</div>
          <el-select
            v-model="selectrouteState"
            :placeholder="$t('placeOrder.pleaseChoose')"
            clearable
            filterable
            multiple
            style="width: 100%;"
          >
            <el-option
              v-for="item in trouteStateList"
              :key="item.id"
              :label="item.label"
              :value="item.label"
            />
          </el-select>
        </el-col>
        <el-col :span="24">
          <el-image :src="require(`@/assets/images/stateMap.png`)" fit="fill" />
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer" style="margin-right: 10px">
        <el-button size="small" @click="StateDialog = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button size="small" type="primary" @click="changerouteState">{{ $t('GlobalSetObj.confirm') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  deliveryPathCreate,
  deliveryPathPage,
  deliveryPathUpdate,
  getDeliveryPathInfo,
  getRouteState,
  getSiteInfo,
  getUnloadingPoint,
  transportCompanyFun,
  updatePoint,
  updateSort
} from '@/api/routerPlan';
import AnjunTableList from '@/components/anjun-tableList';
import { getUserName } from '@/utils/asyncTools';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { isZipCodeAsync, MobilePhoneNumber } from '@/utils/validate';
import { checkedzipcode, getLgAreaListData } from '@/api/orderApi';
import { supplierMainPage } from '@/api/SupplierMain';
import ElTableDraggable from './dragTable';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'RouteData',
  components: {
    AnjunTableList,
    ElTableDraggable
  },
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      tableHeaderList: [
        // 路线号
        { id: 2, label: this.$t('newOrder.RouteNumber'), prop: 'pathNumber', cloWidth: 180 },
        // 网点名
        { id: 3, label: this.$t('newOrder.NetRollCall'), prop: 'relevanceSiteName', cloWidth: 350 },
        // 终点公司
        { id: 4, label: this.$t('GlobalSetObj.DestinationCompany'), prop: 'providerName', cloWidth: 180 },
        // 运输公司
        { id: 8, label: this.$t('GlobalSetObj.TransportCompany'), prop: 'transportCompany', cloWidth: 180 },
        // 备注
        { id: 5, label: this.$t('operationCenter.remark'), prop: 'remark', cloWidth: 230 },
        // 更新人
        { id: 6, label: this.$t('GlobalSetObj.Updater'), prop: 'updateUser', cloWidth: 160 },
        // 更新时间
        { id: 7, label: this.$t('GlobalSetObj.UpdateTime'), prop: 'updateTime', cloWidth: 160 }
      ],
      tableData: [],
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        searchCompanyType: '1',
        params: {
          pathNumber: '',
          relevanceSiteName: '',
          company: ''
        }
      },
      addEditForm: {
        id: '',
        providerId: '',
        pathNumber: '',
        associatedOutlets: [],
        remark: '',
        providerName: '', // 终点公司名称
        telephone: '', // 电话
        postcode: '', // 邮编
        state: '', // 州
        city: '', // 城市
        area: '', // 区
        Street: '', // 街道
        HouseNumber: '', // 门牌号
        Address: '', // 详细地址
        transportCompany: '', // 运输公司
        transportCompanyId: null
      },
      total: 0,
      isZipcode1: true, // 光标离开校验邮编-发件人
      isZipcode2: true, // 光标离开校验邮编-收件人
      isBlur: 0, // 光标移出 0 未移出不调用事件  1 已移出
      showAddData: false,
      rulesRouteNumber: [
        { required: true, message: this.$t('GlobalSetObj.PleaseEnter68Characters'), trigger: ['blur', 'change'] },
        { min: 6, max: 8, message: this.$t('GlobalSetObj.PleaseEnter68Characters'), trigger: ['blur', 'change'] }
      ],
      providerNameCompany: [
        { required: true, message: this.$t('GlobalSetObj.SelecioneDestinationCompany'), trigger: ['blur', 'change'] }
      ],
      rulesTransportCompany: [
        { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: ['blur', 'change'] }
      ],
      isPhone: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        { validator: MobilePhoneNumber, trigger: ['blur', 'change'] }
      ],
      isZipCode: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        { validator: isZipCodeAsync, trigger: ['blur'] }
      ],
      showSelectSites: false,
      tableDataLeft: [],
      leftTotal: 0,
      tableDataRight: [],
      selectedSiteId: [],
      siteTotal: 0,
      querySiteForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          name: '',
          zipCode: ''
        }
      },
      userIdAndNameObj: null,
      titleDialog: 1,
      cancelSelectedSitesList: [],
      senderStateList: [], // 州列表
      supplierMainList: [], // 供应商
      transportCompanyList: [], // 运输公司列表
      activeName: 'first',
      UnloadingPointList: [],
      StateDialog: false,
      selectrouteState: [],
      trouteStateList: [],
      sitePointId: '',
      aferList: [], // 拖动排序后
      unloadingPointId: '', // 修改途径州字段
      moreQuery: { // 滚动查询
        pageSize: 1000,
        pageNumber: 1,
        params: {}
      }
    };
  },
  computed: {
    IsWidth() {
      return {
        width: this.isTormat ? '180px' : '110px'
      };
    },
    IsMdfe() {
      return this.isTormat ? '25%' : '25%';
    }
  },
  watch: {},
  created() {
    this.userIdAndNameObj = getUserIdAndNameObj();
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getSearchRouter();
    // this.gettransportCompany();
    this.getAddress();
    this.getsupplierMain();
  },
  methods: {
    // 是否有网点页面权限
    toDot(row) {
      const promisePage = this.$store.getters.roles;
      const flagNetDot = promisePage.includes('menu:collection-center:siteManagement');
      const unloadingPoint = promisePage.includes('menu:basicData:UnloadingPoint');
      console.log(row, '1235');
      // return;
      if (row.unloadingPointName !== null) {
        if (unloadingPoint) {
          // this.$router.push({ name: 'Unloadingpoint', path: `/basicData/UnloadingPoint`, params: { id: 1 }});
          this.$router.push('/basicData/UnloadingPoint');
        } else {
          this.$message.error(this.$t('GlobalSetObj.Nonetworkmanagement'));
        }
      } else {
        if (flagNetDot) {
          this.$router.push('/collectionCenter/basicDate/site');
        } else {
          this.$message.error(this.$t('GlobalSetObj.Nonetworkmanagement'));
        }
      }
    },
    stateDialog(row) {
      console.log(row, ' this.selectrouteState = [];');
      this.unloadingPointId = row.unloadingPointId;
      getRouteState(row.siteId).then(res => {
        console.log(res, 'RESSSSSSS');
        if (res.status === 'OK') {
          if (res.data) {
            this.selectrouteState = res.data.split(',');
          } else {
            this.selectrouteState = [];
          }
        }
      }).catch(e => {
        this.selectrouteState = [];
      });
      this.StateDialog = true;
      // if (row.routeState) {
      //   this.selectrouteState = row.routeState.split(',');
      // }
      this.trouteStateList = cloneDeep(this.senderStateList);
      const valueToRemove = row.state;
      this.sitePointId = row.siteId;
      const uniqueArray = [];
      let stateList = [];
      const index1 = this.trouteStateList.findIndex(item => {
        return item.label === valueToRemove;
      });
      this.UnloadingPointList.forEach(item => {
        uniqueArray.push(item.state);
      });
      stateList = [...new Set(uniqueArray)];
      console.log(stateList, 'stateListstateListstateList');
      const stateIndex = stateList.findIndex(item => {
        return item === valueToRemove;
      });
      if (stateIndex !== -1) {
        this.trouteStateList.splice(index1, 1);
        if (stateIndex !== 0) {
          if (stateIndex === stateList.length - 1) {
            const index2 = this.trouteStateList.findIndex(item => {
              return item.label === stateList[stateIndex - 1];
            });
            this.trouteStateList.splice(index2, 1);
          } else {
            const index3 = this.trouteStateList.findIndex(item => {
              return item.label === stateList[stateIndex - 1];
            });
            // const index4 = this.trouteStateList.findIndex(item => {
            //   return item.label === stateList[stateIndex + 1];
            // });
            this.trouteStateList.splice(index3, 1);
            // this.trouteStateList.splice(index4, 1);
          }
        } else {
          const index5 = this.trouteStateList.findIndex(item => {
            return item.label === stateList[stateIndex + 1];
          });
          this.trouteStateList.splice(index5, 1);
        }
      }
      console.log(this.senderStateList, 'this.senderStateList');
      console.log(this.trouteStateList, 'this.senderStateList');
    },
    // 切换tab
    handleClick(tab, event) {
      if (tab.index == '0') {
        this.activeName = 'first';
      }
      if (tab.index == '1') {
        this.activeName = 'second';
        this.searchUnloading();
      }
    },
    // 切换运输公司
    handleTransportCompany(val) {
      if (val) {
        console.log(val, 'transportCompany');
        const obj = this.supplierMainList.find((item) => {
          return item.id === val;
        });
        console.log(obj, 'objobj');
        if (Object.keys(obj).length) {
          this.addEditForm.transportCompanyId = null;
          this.addEditForm.transportCompany = '';
          this.addEditForm.transportCompany = obj.providerName;
          this.addEditForm.transportCompanyId = obj.id;
        }
      }
    },
    // 滚动查询
    loadmoreQuery() {
      this.moreQuery.pageSize = this.moreQuery.pageSize + 20;
      supplierMainPage(this.moreQuery).then((res) => {
        console.log(res);
        if (res.status === 'OK') {
          this.supplierMainList = res.data.records;
        }
      });
    },
    // 获取所有供应商
    getsupplierMain() {
      supplierMainPage(this.moreQuery).then((res) => {
        console.log(res);
        if (res.status === 'OK') {
          this.supplierMainList = res.data.records;
        }
      });
    },
    // 获取所有运输公司
    gettransportCompany() {
      transportCompanyFun().then((res) => {
        console.log(res);
        if (res.status === 'OK') {
          this.transportCompanyList = res.data;
        }
      });
    },
    handleCompanyType(val) {
      this.queryForm.searchCompanyType = val;
    },
    // 分页查询
    getSearchRouter() {
      if (this.queryForm.searchCompanyType === '1') {
        this.queryForm.params.providerName = this.queryForm.params.company;
        this.queryForm.params.transportCompany = '';
      } else {
        this.queryForm.params.transportCompany = this.queryForm.params.company;
        this.queryForm.params.providerName = '';
      }
      if (this.queryForm.params.pathNumber || this.queryForm.params.relevanceSiteName || this.queryForm.params.company) {
        this.queryForm.pageNumber = 1;
      }
      deliveryPathPage(this.queryForm).then(res => {
        if (res.status === 'OK') {
          this.total = res.data.total;
          this.tableData = res.data.records;
          this.tableData.forEach((item, index) => {
            if (item.updateUser) {
              item.updateUser = getUserName(item.updateUser, this.userIdAndNameObj);
            } else {
              item.updateUser = getUserName(item.createUser, this.userIdAndNameObj);
            }
            item.updateTime = item.updateTime || item.createTime;
            item.editBtn = true;
          });
        }
      });
    },
    // 查询卸货点
    searchUnloading() {
      if (!this.addEditForm.id) {
        return;
      }
      const data = {};
      data.routeId = this.addEditForm.id;
      const siteidList = [];
      if (this.addEditForm.associatedOutlets.length) {
        this.addEditForm.associatedOutlets.forEach(item => {
          siteidList.push(item.id);
        });
      }
      data.siteId = siteidList;
      getUnloadingPoint(data).then(res => {
        if (res.status === 'OK') {
          this.UnloadingPointList = res.data;
          this.UnloadingPointList.map(item => {
            item.editBtn = true;
            item.pathwayState = false;
            if (item.unloadingPointName !== null) {
              item.UnloadingPointNameOrnetName = item.unloadingPointName;
              item.operatorTip = true;
            } else {
              item.UnloadingPointNameOrnetName = item.siteName;
              item.operatorTip = false;
            }
            item.cityZhou = item.city + '/' + item.state;
          });
          // 查找指定对象的索引
          const index = this.UnloadingPointList.findIndex(function(obj) {
            return obj.siteSort === -1;
          });
          // 如果找到指定对象
          if (index !== -1) {
            const Obj = this.UnloadingPointList[index];
            console.log(Obj, 'ObjObjObj');
            // 将对象从原数组中删除
            this.UnloadingPointList.splice(index, 1);
            // 将对象插入到数组的第一位
            this.UnloadingPointList.unshift(Obj);
          }
          this.aferList = this.UnloadingPointList;
          if (this.UnloadingPointList.length > 0) {
            this.compareAdjacentValues(this.UnloadingPointList);
          }
        }
      });
    },
    // 拖拽完毕的回调 因为每次拖拽会重新初始化列表 所以需要将拖拽前选中的数据重新选中
    dropTable(obj) {
      const UnloadingPointNameOrnetName = obj.targetObject.UnloadingPointNameOrnetName;
      if (UnloadingPointNameOrnetName === 'Armazém de Perus') {
        this.$message.error(this.$t('GlobalSetObj.notBeDragged'));
      }
      this.$nextTick(() => {
        // 查找指定对象的索引
        const index = this.UnloadingPointList.findIndex(function(obj) {
          return obj.siteSort === -1;
        });
        // 如果找到指定对象
        if (index !== -1) {
          const Obj = this.UnloadingPointList[index];
          // 将对象从原数组中删除
          this.UnloadingPointList.splice(index, 1);
          // 将对象插入到数组的第一位
          this.UnloadingPointList.unshift(Obj);
        }
        this.aferList = obj.list;
        console.log(this.UnloadingPointList, 'this.UnloadingPointList');
        // this.UnloadingPointList = obj.list;
        // return;
        this.compareAdjacentValues(obj.list, obj);
      });
    },
    // 对比相邻的是否一样
    compareAdjacentValues(arr, obj) {
      if (arr.length === 0) return;
      // arr.reduce(function(pre, cur, index, arr) {
      //   console.log(pre, 'pre');
      //   if (pre.state === cur.state) {
      //     cur.pathwayState = false;
      //   } else {
      //     cur.pathwayState = true;
      //   }
      //   console.log(cur, 'cur');
      //   return cur;
      // });
      // return;
      console.log(obj, 'obj', arr);
      for (let i = 0; i < arr.length - 1; i++) {
        // 判断当前州与下一个州是否相同
        if (arr[i].state === arr[i + 1].state) {
          // 使用 $set 方法更新数组元素的属性
          // this.$set(this.UnloadingPointList[i + 1], 'pathwayState', false);
          arr[i + 1].pathwayState = false;
          console.log(1);
          // this.$set(this.UnloadingPointList[i + 1], 'pathwayState', false);
        } else {
          // this.$set(this.UnloadingPointList[i], 'pathwayState', true);
          // this.$set(this.UnloadingPointList[i + 1], 'pathwayState', true);
          arr[i + 1].pathwayState = true;
          console.log(2);
        }
      }
      arr[0].pathwayState = false;
      arr[0].editBtn = false;
      arr[0].UnloadingPointNameOrnetName = 'Armazém de Perus';
      // this.$set(this.UnloadingPointList[0], 'pathwayState', false);
      // this.$set(this.UnloadingPointList[0], 'editBtn', false);
      // this.$set(this.UnloadingPointList[0], 'UnloadingPointNameOrnetName', 'Armazém de Perus');
      console.log(this.UnloadingPointList, 'this.UnloadingPointList');
      this.UnloadingPointList = [];
      this.$nextTick(() => {
        this.UnloadingPointList = arr;
      });
      // setTimeout(() => {
      //   this.UnloadingPointList = arr;
      // }, 100);
    },
    saveUnloading() {
      console.log(this.aferList, ' this.aferList');
      const preStateList = [];
      const endStateList = [];
      this.aferList.forEach((item) => {
        if (item.routeState) {
          preStateList.push(item.routeState + ',' + item.state);
        } else {
          preStateList.push('');
        }
      });
      this.aferList.forEach((item) => {
        if (item.routeState) {
          endStateList.push(item.routeState);
        } else {
          endStateList.push(item.routeState);
        }
      });
      for (let i = 0; i < this.aferList.length; i++) {
        if (this.aferList[i].routeState) {
          this.aferList[i].routeStateList = this.aferList[i].routeState;
        } else {
          this.aferList[i].routeStateList = '';
          this.aferList[i].routeState = '';
        }
        if (i > 1) {
          let arr1 = [];
          let str1 = '';
          try {
            arr1 = preStateList[i - 1].split(',');
          } catch (e) {
            arr1 = [];
          }
          if (arr1.length > 0) {
            str1 = arr1[arr1.length - 1];
          } else {
            str1 = '';
          }
          this.aferList[i].routeStateList = this.aferList[i - 1].routeStateList + ',' + str1 + ',' + this.aferList[i].routeState;
        }
      }
      let array = [];
      let newArray = [];
      this.aferList.forEach((item) => {
        array = item.routeStateList.split(',');
        newArray = array.filter(item => item !== '');
        item.pathwayStateList = newArray.join(',');
      });
      console.log(this.aferList, 'this.newArray');
      // return;
      const data = [];
      this.aferList.forEach((item, index) => {
        const obj = {};
        obj.routeId = this.addEditForm.id;
        obj.sitePointId = item.siteId;
        obj.unloadingPointId = item.unloadingPointId;
        obj.sortJson = JSON.stringify(this.aferList);
        data.push(obj);
      });
      updateSort(data).then(res => {
        if (res.status === 'OK') {
          this.searchUnloading();
          this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
          this.showAddData = false;
        }
      });
    },
    changerouteState() {
      const data = {};
      data.routeId = this.addEditForm.id;
      data.sitePointId = this.sitePointId;
      data.unloadingPointId = this.unloadingPointId;
      data.routeState = this.selectrouteState.join(',');
      updatePoint(data).then(res => {
        if (res.status === 'OK') {
          this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
          // this.searchUnloading();
          this.StateDialog = false;
        }
      });
    },
    getSitePageList1() {
      getSiteInfo(this.querySiteForm).then(res => {
        if (res.status === 'OK') {
          this.tableDataLeft = [];
          this.siteTotal = res.data.total;
          this.tableDataLeft = res.data.records;
        }
      });
    },
    // 分页获取站点
    getSitePageList() {
      getSiteInfo(this.querySiteForm).then(res => {
        if (res.status === 'OK') {
          this.siteTotal = res.data.total;
          this.tableDataLeft = res.data.records;
          if (this.titleDialog === 2) {
            if (this.querySiteForm.params.name && this.querySiteForm.params.zipCode) {
              this.cancelSelectedSitesList.map(item => {
                if ((item.name.indexOf(this.querySiteForm.params.name) !== -1) && (item.zipCode === this.querySiteForm.params.zipCode)) {
                  this.tableDataLeft.push(item);
                  this.siteTotal += 1;
                }
              });
            } else if (this.querySiteForm.params.name) {
              this.cancelSelectedSitesList.map(item => {
                if ((item.name.indexOf(this.querySiteForm.params.name) !== -1)) {
                  this.tableDataLeft.push(item);
                  this.siteTotal += 1;
                }
              });
            } else if (this.querySiteForm.params.zipCode) {
              this.cancelSelectedSitesList.map(item => {
                if ((item.zipCode === this.querySiteForm.params.zipCode)) {
                  this.tableDataLeft.push(item);
                  this.siteTotal += 1;
                }
              });
            } else {
              this.cancelSelectedSitesList.map(item => {
                this.tableDataLeft.push(item);
                this.siteTotal += 1;
              });
            }
          }
        }
      });
    },
    // 切换终点公司
    handleproviderName(val) {
      console.log(val, 'handleproviderName');
      this.addEditForm.providerId = val;
      const obj = this.supplierMainList.find((item) => {
        return item.id == this.addEditForm.providerId || item.id == this.addEditForm.providerName;
      });
      console.log(obj, 'objobj');
      if (obj) {
        this.addEditForm.providerId = obj.id;
        this.addEditForm.providerName = obj.providerName;
      }
    },
    // 点击编辑 新建
    addRoute(e, row) {
      this.gettransportCompany();
      this.tableDataRight = [];
      let target = e.target;
      if (target.nodeName === 'I' || target.nodeName === 'SPAN') {
        target = e.target.parentNode;
      }
      target.blur();
      if (row) {
        console.log(row, 'row');
        // const obj = this.supplierMainList.find((item) => {
        //   return item.id == this.addEditForm.providerId || item.id == row.providerId;
        // });
        // console.log(obj, 'objobj');
        // if (obj) {
        //   this.addEditForm.providerId = obj.providerId;
        //   this.addEditForm.providerName = obj.providerName;
        // }
        this.addEditForm.pathNumber = row.pathNumber;
        this.addEditForm.providerName = row.providerName;
        this.addEditForm.telephone = row.providerMobile;
        this.addEditForm.postcode = row.providerZipCode;
        this.addEditForm.state = row.providerState;
        this.addEditForm.city = row.providerCity;
        this.addEditForm.area = row.providerArea;
        this.addEditForm.Street = row.providerStreet;
        this.addEditForm.HouseNumber = row.providerHouseNumber;
        this.addEditForm.Address = row.providerAddress;
        this.addEditForm.remark = row.remark;
        this.addEditForm.transportCompany = row.transportCompany;
        this.addEditForm.transportCompanyId = row.transportCompanyId;
        this.addEditForm.id = row.id;
        this.titleDialog = 2;
        // 获取站点
        this.getSitePageList();
        this.getEditLineDot();
      } else {
        this.titleDialog = 1;
        this.UnloadingPointList = [];
      }
      this.showAddData = true;
      this.activeName = 'first';
      this.aferList = [];
    },
    // 查询卸货点已关联的网点
    getEditLineDot() {
      const _this = this;
      const param = {
        pathNumber: this.addEditForm.pathNumber,
        routeId: this.addEditForm.id
      };
      getDeliveryPathInfo(param).then(res => {
        if (res.status === 'OK') {
          // _this.addEditForm.transportCompanyId = res.data[0].transportCompanyId;
          _this.addEditForm.associatedOutlets = [];
          const selectOutletArray = [];
          if (res.data) {
            res.data.map(item => {
              if (item.id && item.name) {
                selectOutletArray.push({
                  id: item.id,
                  name: item.name,
                  zipCode: item.zipCode
                });
              }
            });
            _this.tableDataRight = selectOutletArray;
            _this.addEditForm.associatedOutlets = selectOutletArray;
          } else {
            _this.tableDataRight = [];
          }
        }
      });
    },
    // 确定新建路线
    async saveRouter() {
      const valid = await this.$refs['addForm'].validate();
      // this.$refs['addForm'].validate((valid) => {
      if (valid) {
        const data = {};
        data.pathNumber = this.addEditForm.pathNumber;
        data.relevanceSite = this.addEditForm.associatedOutlets.length > 0 ? JSON.stringify(this.addEditForm.associatedOutlets) : '';
        data.remark = this.addEditForm.remark;
        data.providerId = this.addEditForm.providerId;
        data.providerName = this.addEditForm.providerName;
        data.providerMobile = this.addEditForm.telephone;
        data.providerZipCode = this.addEditForm.postcode;
        data.providerState = this.addEditForm.state;
        data.providerCity = this.addEditForm.city;
        data.providerArea = this.addEditForm.area;
        data.providerStreet = this.addEditForm.Street;
        data.providerHouseNumber = this.addEditForm.HouseNumber;
        data.providerAddress = this.addEditForm.Address.replace(/^\s+|\s+$/g, '');
        data.transportCompanyId = this.addEditForm.transportCompanyId;
        data.transportCompany = this.addEditForm.transportCompany;
        // return;
        if (this.titleDialog === 1) {
          deliveryPathCreate(data).then(res => {
            if (res.status === 'OK') {
              this.getSearchRouter();
              this.getSitePageList();
              this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
              this.showAddData = false;
              this.$emit('siteTotal');
            }
          });
        } else {
          data.routeId = this.addEditForm.id;
          deliveryPathUpdate(data).then(res => {
            if (res.status === 'OK') {
              this.getSearchRouter();
              this.getSitePageList();
              this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
              this.showAddData = false;
              this.$emit('siteTotal');
            }
          });
        }
      } else {
        console.log('error submit!!');
        return false;
      }
      // });
    },
    // 清除详情数据
    clearAddEditSiteForm() {
      this.addEditForm = {
        id: '',
        providerId: '',
        pathNumber: '',
        associatedOutlets: [],
        remark: '',
        providerName: '', // 终点公司名称
        telephone: '', // 电话
        postcode: '', // 邮编
        state: '', // 州
        city: '', // 城市
        area: '', // 区
        Street: '', // 街道
        HouseNumber: '', // 门牌号
        Address: '', // 详细地址
        transportCompany: '', // 运输公司
        transportCompanyId: null
      };
      this.cancelSelectedSitesList = [];
      this.activeName = 'first';
      this.aferList = [];
      this.UnloadingPointList = [];
    },
    // 删除已选站点
    deleteSite(row) {
      this.addEditForm.associatedOutlets.map((item, ind) => {
        if (row.id === item.id) {
          this.addEditForm.associatedOutlets.splice(ind, 1);
        }
      });
    },
    // 保存关联站点
    saveSelectSite() {
      this.addEditForm.associatedOutlets = cloneDeep(this.tableDataRight);
      console.log(this.addEditForm.associatedOutlets, 'this.addEditForm.associatedOutlets');
      this.showSelectSites = false;
    },
    // 搜索站点
    searchSite() {
      this.querySiteForm.pageNumber = 1;
      this.getSitePageList();
    },
    // 全部取消
    allCancelSelect() {
      this.querySiteForm.params.name = '';
      this.querySiteForm.params.zipCode = '';
      this.selectedSiteId = [];
      this.tableDataLeft = this.tableDataLeft.concat(this.tableDataRight);
      this.tableDataLeft = Array.from(new Set(this.tableDataLeft.map(JSON.stringify))).map(JSON.parse);
      this.tableDataRight = [];
    },
    // 取消选中
    cancelSelectedSites(scope) {
      this.tableDataRight.splice(scope.$index, 1);
      const ind = this.selectedSiteId.indexOf(scope.row.id);
      this.selectedSiteId.splice(ind, 1);
      const noSelect = [];
      if (this.titleDialog === 2) {
        this.tableDataLeft.map(item => {
          if (!noSelect.includes(item.id)) {
            noSelect.push(item.id);
          }
        });
        if (!noSelect.includes(scope.row.id)) {
          this.tableDataLeft.push(scope.row);
          this.cancelSelectedSitesList.push(scope.row);
        }
      }
    },
    // 全部选中
    allSelect() {
      this.tableDataLeft.map(item => {
        if (!this.selectedSiteId.includes(item.id)) {
          this.selectedSiteId.push(item.id);
          this.tableDataRight.push(item);
        }
      });
    },
    // 选中站点
    selectedSites(row) {
      if (!this.selectedSiteId.includes(row.id)) {
        this.selectedSiteId.push(row.id);
        this.tableDataRight.push(row);
      }
    },
    // 打开选择站点弹窗
    selectSites() {
      this.querySiteForm.pageNumber = 1;
      this.getSitePageList1();
      this.showSelectSites = true;
      this.getEditLineDot();
      this.tableDataRight.map(row => {
        this.selectedSiteId.push(row.id);
      });
    },
    // 搜索列表
    getSearchFun() {
      this.queryForm.pageNumber = 1;
      this.getSearchRouteTable();
    },
    // 查询列表
    getSearchRouteTable() {
    },
    // 重置
    resetForm() {
      this.queryForm.params.pathNumber = '';
      this.queryForm.params.relevanceSiteName = '';
      this.queryForm.params.company = '';
      this.queryForm.searchCompanyType = '1';
      this.queryForm.pageNumber = 1;
      this.getSearchRouter();
    },
    // 点击分页查询路线
    handleSizeChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearchRouter();
    },
    handleCurrentChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getSearchRouter();
    },
    // 点击分页查询站点
    handleSizeChangeSite(val) {
      this.querySiteForm.pageSize = val;
      this.querySiteForm.pageNumber = 1;
      this.getSitePageList();
    },
    handleCurrentChangeSite(val) {
      this.querySiteForm.pageNumber = val;
      this.getSitePageList();
    },
    clearForm() {
      console.log('LJI');
      this.selectrouteState = [];
    },
    // 级联地区
    getAddress() { // level 0 州    1 城市   2 地区
      const params = {
        level: 0,
        parentId: ''
      };
      getLgAreaListData(params, false).then((res) => {
        if (res.status === 'OK') {
          this.senderStateList = res.data.map(item => {
            return {
              id: item.id,
              label: `${item.nameEn}`
            };
          });
        }
      });
    },
    // 邮编校验
    checkPostcode(postcode) {
      // pType === 1 发件人   pType === 2 收件人
      if (this.isBlur === 1) {
        const data = {};
        data.zipcode = postcode;
        checkedzipcode(data).then(res => {
          // console.log('验证邮编', res);
          if (res.status === 'OK') {
            if (!res.data) {
              this.$message.error(this.$t('placeOrder.inputCodeErr'));
              this.addEditForm.postcode = '';
            }
          }
        }).catch((err) => {
          this.addEditForm.postcode = '';
        });
      }
    }
  }
};
</script>

<style scoped>
.right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

>>> .el-drawer__header {
  margin-bottom: 20px !important;
}

.selectColor {
  color: #409EFF
}

.cancelSelectColor {
  color: #666666
}

.wordBreak {
  word-break: break-all; /*word-break: normal|break-all|keep-all;在恰当的断字点进行换行,break-all 允许在单词内换行,keep-all 只能在半角空格或连字符处换行,normal 使用浏览器默认的换行规则。*/
  text-overflow: ellipsis; /*溢出文字显示省略号*/
  display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 1; /** 显示的行数 **/
  overflow: hidden; /** 隐藏超出的内容 **/
}

.tabless {
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 0 10px;
  margin: 5px;
  background: #E1F7F2;
}

>>> .selectLabel .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

>>> .selectLabel .el-input__icon {
  line-height: 28px !important;
}

.demo-table-expand {
  background-color: #e1f7f2;
  height: 60px;
}

>>> .demo-table-expand .el-form-item {
  margin-bottom: 0;
}

>>> .el-dialog__body {
  padding: 10px 30px;
}

/deep/ .el-table__fixed-right {
  height: 100% !important;
}
</style>
