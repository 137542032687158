<template>
  <div class="TABLE_LIST">
    <virtual-scroll
      ref="virtualScroll"
      :data="tableData"
      :item-size="62"
      key-prop="id"
      @change="(virtualList) => list = virtualList"
    >
      <el-table
        border
        ref="table"
        :data="list"
        class="table-height"
        :height="tableHeightRow.height"
        row-key="id"
        style="width: 100%"
        @selection-change="handleSelectionChange">
        <virtual-column width="50" type="selection" />
        <virtual-column :label="$t('GlobalSetObj.serial')" width="50" type="index" />
        <el-table-column
          v-for="item in tableHeader"
          :key="item.prop"
          :align="item.fixed"
          :label="item.label"
          :min-width="item.width"
          :prop="item.prop"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div
              v-if="item.prop === 'waybillNumber'"
              style="color: #409eff;cursor: pointer;"
              @click="editFee(scope.row)"
            >{{ scope.row[item.prop] }}</div>

            <div v-else-if="item.prop === 'status'">{{ scope.row.statusStr }}</div>

            <div v-else-if="item.prop === 'collectType'">{{ scope.row.collectTypeStr }}</div>

            <div v-else-if="item.prop === 'feeSource'">{{ scope.row.feeSourceStr }}</div>
            <!-- 商户号(商家编码) -->
            <div v-else-if="item.prop === 'merchantCode'">
              {{ scope.row[item.prop] }}{{scope.row['merchantName']?`(${scope.row['merchantName']})`:''}}
            </div>

            <div v-else-if="item.prop === 'vehicleType'">{{ scope.row.vehicleTypeStr }}</div>

            <!--用户类型-->
            <div v-else-if="item.prop === 'customerType'">{{
              scope.row.customerType===1?$t('GlobalSetObj.selfSupport'):$t('GlobalSetObj.three')
            }}</div>

            <div v-else>{{ scope.row[item.prop] }}</div>
          </template>
        </el-table-column>

        <el-table-column
          v-for="item in currentColumnsArr"
          :key="item.key"
          :label="item.label"
          :prop="item.prop"
          :show-overflow-tooltip="true"
          :render-header="renderHeader"
        >
          <!-- <template slot-scope="scope">
            <div>{{ scope.row[item.prop] }}</div>
          </template> -->
        </el-table-column>
      </el-table>
    </virtual-scroll>

    <!-- 分页工具 -->
    <div class="pagination">
      <el-pagination
        :current-page.sync="queryForm.pageNumber"
        :page-size.sync="queryForm.pageSize"
        :page-sizes="[100, 200, 500, 1000]"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 10px 0"
        @current-change="onCurrentChange"
        @size-change="onSinzeChange"
      />
    </div>
  </div>
</template>

<script>
import VirtualScroll, { VirtualColumn } from 'el-table-virtual-scroll';
import ElTableInfiniteScroll from 'el-table-infinite-scroll';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import { WAYBILL_STATUS, ORDER_TYPE_LIST, FEE_SOURCE, COLLECT_TYPE } from '@/views/finance/enums.js';
import { Transport_VEHICLE_MODEL } from '@/views/finance/utils.js';
import { asyncGetList } from './utils.js';
import { apiPayableTransportSiteExpenseGetPage } from '@/api/finance/receivables';

export default {

  directives: {
    ElTableInfiniteScroll
  },

  components: {
    VirtualScroll,
    VirtualColumn
  },

  mixins: [estimateTableHeight],

  props: {
    // 搜索条件
    queryForm: {
      type: Object,
      required: true
    },

    // 列表需要展示的列
    tableHeader: {
      type: Array,
      required: true
    },

    // 账单类型
    payableType: {
      type: [Number, String],
      default: '', // 1 安俊 2 第三方 3 AJD 4 DSP 5 运输（干/支线）
      // required: true
    }
  },

  data() {
    return {
      isSearch: false,
      tableData: [], // 总数据
      list: [],
      currentColumnsArr: [], // 扩展列
      total: 0,
      Transport_VEHICLE_MODEL: Transport_VEHICLE_MODEL
    };
  },

  inject: ['tableKey', 'getTableKey'],

  computed: {
    key() {
      return this.getTableKey();
    }
  },

  methods: {
    // 搜索
    onSearch() {
      this.isSearch = false;
      this.asyncGetTable();
    },

    // 刷新
    onRefresh() {
      if (this.tableData.length === 0) {
        return;
      }
      this.asyncGetTable();
    },

    // 翻页
    onCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.asyncGetTable();
    },

    // 每页显示数量变化
    onSinzeChange(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.asyncGetTable();
    },

    // 获取列表数据
    processTableData(res) {
      if (res.status === 'OK') {
        const { data, total, columns } = res;
        this.total = total;
        // console.log(data, '列表数据');
        if (data.length > 0) {
          const { list, currentColumnsRow } = this.setColumns(columns);
          this.currentColumnsArr = list;
          this.tableData = this.setRowData(data, currentColumnsRow);
        } else {
          this.tableData = [];
          this.$message.warning(this.$t('operatingPlatform.nullMsg'));
        }
      }
    },

    asyncGetTable() {
      if (this.payableType === 5) {
        this.queryForm.params.ids = [];
        apiPayableTransportSiteExpenseGetPage(this.queryForm).then(this.processTableData);
      } else {
        asyncGetList(this.queryForm, this).then(this.processTableData);
      }
    },

    // 扩展列
    setColumns(arr) {
      // console.log(arr, '动态列');
      const currentColumnsRow = {};
      const list = arr.map((item, index) => ({
        prop: item.prop,
        key: item.prop + index,
        label: item.label
        // label: label.split('_')[0],
        // fixed: 'left',
        // highlight: false
      }));

      arr.forEach(el => {
        currentColumnsRow[el.prop] = '';
      });

      return { list, currentColumnsRow };
    },

    // 列表行数据处理
    setRowData(arr, currentColumnsRow) {
      const ORDER_TYPE_LIST_EDIT = [...ORDER_TYPE_LIST,
        { collectStatus: 100, collectStatusName: this.$t('GlobalSetObj.financialProcessing') }, // 待取件
        { collectStatus: 101, collectStatusName: this.$t('GlobalSetObj.financialClosedLoop') }];
      const WAYBILL_STATUS_EDIT = [...WAYBILL_STATUS,
        { code: 100, label: this.$t('GlobalSetObj.financialProcessing') }, // 待取件
        { code: 101, label: this.$t('GlobalSetObj.financialClosedLoop') }];
      // 将数组
      return arr.map((item, index) => {
        const {
          id, waybillNumber, orderNumber, customerNumber, payableType,
          providerName, siteName, businessType, collectType,
          operationMode, collectorsName, dispatcherName,
          remark, chargeWeight, totalFee, status, partitionName,
          receiveCity, receiveZipcode, receiveState, senderState,
          customerAlias, customerId, feeSource, siteId,
          startingSiteName, destinationSiteName, loadUserName,
          kilometers, vehicleType, tagNumber,
          siteType, createUser, vehicleNumber, merchantCode, merchantName,
          settlementType,deliveryVehicleType,customerType
        } = item;

        // 订单状态
        const statusStr = this.queryForm.params.businessType === 2
          ? ORDER_TYPE_LIST_EDIT.find(item => item.collectStatus === status)?.collectStatusName || ''
          : WAYBILL_STATUS_EDIT.find(item => item.code === status)?.label || '';

        // 来源
        const feeSourceStr = FEE_SOURCE[feeSource]?.label || '';

        // 揽收类型
        const collectTypeStr = COLLECT_TYPE[collectType]?.label || '';

        // 车型类型
        const vehicleTypeStr = Transport_VEHICLE_MODEL[vehicleType]?.label || '';

        // 扩展列
        const row = {
          ...currentColumnsRow
        };
        for (const key in row) {
          // 存在
          if (item[key]) {
            row[key] = item[key];
          }
        }

        const obj = {
          id,
          waybillNumber, // 业务单号
          orderNumber, // 揽收单号
          customerNumber, // 客户编号
          providerName, // 应付主体
          siteName, // 应付网点
          siteId, // 网点id
          customerId, // 客户
          customerAlias,
          status, // 订单状态
          statusStr,
          collectType, // 揽收方式
          collectTypeStr,
          collectorsName, // 揽收人
          senderState, // 发件人州
          feeSource, // 来源
          feeSourceStr,
          remark, // 备注
          chargeWeight, // 计费重量
          totalFee, // 应付总费用
          businessType, // 业务类型
          operationMode, // 派送模式 0 AJD 1 DSP
          payableType, // 应付类型
          dispatcherName, // 派送人
          partitionName, // 所属分区
          receiveCity, // 收件人城市
          receiveZipcode, // 收件人邮编
          receiveState, // 收件人州
          startingSiteName,
          destinationSiteName,
          loadUserName,
          kilometers,
          vehicleType,
          vehicleTypeStr,
          tagNumber, // 车签号
          vehicleNumber, // 车牌号
          merchantCode, // 商家编码
          merchantName, // 商家名称
          settlementType, // 结算方式
          deliveryVehicleType, // 派送车辆类型
          customerType, // 用户类型
          // operationModeStr,
          // statusStr,
          // siteType, // 网点
          // siteTypeStr,
          // createUser,
          ...row
        };
        return obj;
      });
    },

    // 多选
    handleSelectionChange(arr) {
      console.log('勾选数据1', arr);
      this.$emit('selection-change', arr);
    },

    // 详情查看
    editFee(row) {
      this.$emit('editFee', row);
    },

    /**
     * 重新table中el-columns，防止动态height丢失滚动条
     * @param h { createElement }
     */
    renderHeader(h, { column, $index }) {
      let realWidth = 0;
      const span = document.createElement('span');
      span.textContent = column.label;
      document.body.appendChild(span);
      realWidth = span.offsetWidth; // 内容宽度+左右padding
      // realWidth = span.getBoundingClientRect().width;
      // console.log(realWidth, '999999999999999', column.label);
      column.minWidth = realWidth + 10;
      document.body.removeChild(span);
      return h('span', column.label);
    },

    // 获取总数量
    getTotalCount() {
      return this.total;
    },
  }
};
</script>

<style scoped>
/* 设置列宽超出... */
::v-deep .el-table th.el-table__cell > .cell {
  /* display: inline-block; */
  /* -webkit-box-sizing: border-box; */
  /* box-sizing: border-box; */
  /* position: relative; */
  /* vertical-align: middle; */
  /* padding-left: 10px; */
  /* padding-right: 10px; */
  width: 100%;
  text-overflow: clip !important;
  white-space: nowrap !important;
}
</style>
