// AJD派送重算
export const COLLECTING_DISPATCH_OBJ = {
  businessType: 3, // 业务类型 2 揽收 3 派送
  collectType: null, // 揽收方式 2上门揽收 1上门交件
  payableType: 3, // 账单类型  1安骏小屋，2第三方揽收，3AJD派送, 4DSP派送
  operationMode: 0, // 派送模式 0:AJD(自营) 1:DSP(第三方)
  reCalculatePriceSource: 4, // 重算方式
  dispatcherList: [], // 操作人列表
  siteType: 1, // 网点类型 1 默认小屋
  years: '', // 周期
  cycle: 1,
};

