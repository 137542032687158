var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ScanCourierBox" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          model: {
            value: _vm.changeTab,
            callback: function ($$v) {
              _vm.changeTab = $$v
            },
            expression: "changeTab",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("collectionCenter.customerHandFindTable"),
                name: "second",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "margin-bottom": "20px" } },
                [
                  _c(
                    "el-form",
                    { staticClass: "selectClass" },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: { "flex-wrap": "wrap" },
                          attrs: { type: "flex", justify: "start" },
                        },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: { width: "auto" },
                              attrs: { span: 10 },
                            },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "collectionCenter.lanshouTime"
                                        ),
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          clearable: false,
                                          type: "daterange",
                                          "value-format": "yyyy-MM-dd",
                                          "default-time": [
                                            "00:00:00",
                                            "23:59:59",
                                          ],
                                          "range-separator": _vm.$t(
                                            "collectionCenter.to"
                                          ),
                                          "start-placeholder": _vm.$t(
                                            "collectionCenter.StartTime"
                                          ),
                                          "end-placeholder": _vm.$t(
                                            "collectionCenter.EndTime"
                                          ),
                                        },
                                        on: { change: _vm.dateChange },
                                        model: {
                                          value: _vm.timeValue,
                                          callback: function ($$v) {
                                            _vm.timeValue = $$v
                                          },
                                          expression: "timeValue",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticStyle: { width: "auto" },
                              attrs: { span: 7 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("collectionCenter.siteTitle"),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "collectionCenter.pleaseChoose"
                                        ),
                                        clearable: "",
                                        filterable: "",
                                      },
                                      on: { focus: _vm.getNewestSite },
                                      model: {
                                        value: _vm.queryForm.params.siteId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "siteId",
                                            $$v
                                          )
                                        },
                                        expression: "queryForm.params.siteId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.siteIdList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.name,
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticStyle: { width: "auto" },
                              attrs: { span: 7 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "collectionCenter.receivingListNumber"
                                    ),
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: _vm.$t(
                                        "collectionCenter.pleaseInput"
                                      ),
                                    },
                                    model: {
                                      value: _vm.queryForm.params.listNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryForm.params,
                                          "listNumber",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "queryForm.params.listNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticStyle: { width: "auto" },
                              attrs: { span: 7 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("collectionCenter.CustomerN"),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "collectionCenter.pleaseChoose"
                                        ),
                                        clearable: "",
                                        filterable: "",
                                      },
                                      on: {
                                        focus: _vm.getNewestCustomer,
                                        change: _vm.changeCustomerName,
                                      },
                                      model: {
                                        value: _vm.queryForm.params.customerId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "customerId",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.customerId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.customerIdList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.username,
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "col10",
                              staticStyle: { width: "auto" },
                              attrs: { span: 7 },
                            },
                            [
                              _c(
                                "el-form-item",
                                { staticClass: "m" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "selectTimeClass",
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "collectionCenter.pleaseChoose"
                                        ),
                                      },
                                      on: { change: _vm.changeMerchant },
                                      model: {
                                        value: _vm.merchantChoose,
                                        callback: function ($$v) {
                                          _vm.merchantChoose = $$v
                                        },
                                        expression: "merchantChoose",
                                      },
                                    },
                                    _vm._l(
                                      _vm.merchantCodeOrName,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.name,
                                            value: item.m,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        clearable: "",
                                        remote: "",
                                        "reserve-keyword": "",
                                        placeholder: _vm.$t(
                                          "collectionCenter.pleaseInput"
                                        ),
                                        "remote-method": _vm.remoteMethod,
                                        loading: _vm.loading,
                                      },
                                      on: { change: _vm.changeValue },
                                      model: {
                                        value: _vm.merchantCodeValue,
                                        callback: function ($$v) {
                                          _vm.merchantCodeValue = $$v
                                        },
                                        expression: "merchantCodeValue",
                                      },
                                    },
                                    _vm._l(
                                      _vm.merchantCodeList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.name,
                                            value: item.name,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticStyle: { width: "auto" },
                              attrs: { span: 7 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "collectionCenter.LanShouPerson"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        remote: "",
                                        "reserve-keyword": "",
                                        "remote-method": _vm.getNewestUser,
                                        placeholder: _vm.$t(
                                          "collectionCenter.pleaseChoose"
                                        ),
                                        clearable: "",
                                        filterable: "",
                                      },
                                      model: {
                                        value: _vm.queryForm.params.collectId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "collectId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.collectId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.userNameList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.name,
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticStyle: { width: "auto" },
                              attrs: { span: 7 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "collectionCenter.bagsNumber"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "BlockNewlineClass" },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          placeholder: _vm.$t(
                                            "collectionCenter.BatchQuery"
                                          ),
                                        },
                                        on: { blur: _vm.ft },
                                        nativeOn: {
                                          keydown: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.BlockNewline($event)
                                          },
                                        },
                                        model: {
                                          value: _vm.queryForm.params.bagNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryForm.params,
                                              "bagNumber",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "queryForm.params.bagNumber",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permit",
                                    rawName: "v-permit:remove",
                                    value:
                                      "btn:collection-center:operation:ScanCourier:find",
                                    expression:
                                      "'btn:collection-center:operation:ScanCourier:find'",
                                    arg: "remove",
                                  },
                                  {
                                    name: "loading",
                                    rawName: "v-loading.fullscreen.lock",
                                    value: _vm.fullscreenLoading,
                                    expression: "fullscreenLoading",
                                    modifiers: { fullscreen: true, lock: true },
                                  },
                                ],
                                attrs: {
                                  type: "primary",
                                  size: "mini",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.searchClick },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("collectionCenter.search"))
                                ),
                              ]
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permit",
                                    rawName: "v-permit:remove",
                                    value:
                                      "btn:collection-center:operation:ScanCourier:reset",
                                    expression:
                                      "'btn:collection-center:operation:ScanCourier:reset'",
                                    arg: "remove",
                                  },
                                ],
                                attrs: {
                                  icon: "el-icon-refresh",
                                  size: "mini",
                                },
                                on: { click: _vm.resetClick },
                              },
                              [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")))]
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permit",
                                    rawName: "v-permit:remove",
                                    value:
                                      "btn:collection-center:operation:ScanCourier:print",
                                    expression:
                                      "'btn:collection-center:operation:ScanCourier:print'",
                                    arg: "remove",
                                  },
                                ],
                                class:
                                  _vm.collectListIdList.length <= 0 &&
                                  _vm.isClick
                                    ? "onlyClickOnce10"
                                    : "",
                                attrs: {
                                  icon: "el-icon-printer",
                                  size: "mini",
                                },
                                on: { click: _vm.uploadClick },
                              },
                              [_vm._v(_vm._s(_vm.$t("collectionCenter.Print")))]
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permit",
                                    rawName: "v-permit:remove",
                                    value:
                                      "btn:collection-center:operation:ScanCourier:export",
                                    expression:
                                      "'btn:collection-center:operation:ScanCourier:export'",
                                    arg: "remove",
                                  },
                                ],
                                class:
                                  _vm.selectionList.length <= 0 &&
                                  _vm.isClickAll
                                    ? "onlyClickOnce10"
                                    : "",
                                attrs: {
                                  icon: "el-icon-upload2",
                                  size: "mini",
                                },
                                on: { click: _vm.downloadLogistics },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("collectionCenter.export"))
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("el-divider"),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      ref: "queryTableRef",
                      attrs: {
                        border: "",
                        "show-summary": "",
                        "summary-method": _vm.getSummaries,
                        data: _vm.tableList,
                        "max-height": 600,
                      },
                      on: {
                        select: _vm.batchSelect,
                        "select-all": _vm.batchSelectAll,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "55" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("collectionCenter.ind"),
                          type: "index",
                          width: "60",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      (_vm.queryForm.pageNumber - 1) *
                                        _vm.queryForm.pageSize +
                                        scope.$index +
                                        1
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "listNumber",
                          label: _vm.$t("collectionCenter.receivingListNum"),
                          width: "200",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "customerId",
                          label: _vm.$t("collectionCenter.clientID"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "customerName",
                          label: _vm.$t("collectionCenter.CustomerName"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "merchantCode",
                          label: _vm.$t("collectionCenter.MerchantNum"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "merchantName",
                          label: _vm.$t("collectionCenter.MerchantName"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "bagNumber",
                          label: _vm.$t("collectionCenter.BagNumber"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "realNumber",
                          label: _vm.$t("collectionCenter.ballot"),
                          width: "100",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                !_vm.isBallot
                                  ? _c("span", [
                                      _vm._v(_vm._s(scope.row.realNumber)),
                                    ])
                                  : _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.cellClick(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(scope.row.realNumber))]
                                    ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "code",
                          label: _vm.$t("collectionCenter.siteNum"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "name",
                          label: _vm.$t("collectionCenter.siteName"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "userName",
                          label: _vm.$t("collectionCenter.LanShouPeople"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "scanTime",
                          label: _vm.$t("collectionCenter.lanshouT"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "printCount",
                          label: _vm.$t("collectionCenter.printNumber"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "printTime",
                          label: _vm.$t("collectionCenter.printTime"),
                        },
                      }),
                      _vm.isBallot
                        ? _c("el-table-column", {
                            attrs: {
                              prop: "moreActionsOrder",
                              label: _vm.$t("collectionCenter.MoreActions"),
                              width: "160",
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.cellClick(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "collectionCenter.orderDetails"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              481143355
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c("el-pagination", {
                    staticStyle: { margin: "10px 0" },
                    attrs: {
                      layout: "total, sizes, prev, pager, next, jumper",
                      "current-page": _vm.queryForm.pageNumber,
                      "page-sizes": [10, 20, 50, 100, 500, 1000],
                      "page-size": _vm.queryForm.pageSize,
                      total: _vm.tableTotal,
                      background: "",
                    },
                    on: {
                      "update:currentPage": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageNumber", $event)
                      },
                      "update:current-page": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageNumber", $event)
                      },
                      "update:pageSize": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageSize", $event)
                      },
                      "update:page-size": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageSize", $event)
                      },
                      "current-change": _vm.getDatePage,
                      "size-change": _vm.getPageSize,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dialogUpload" },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "",
                visible: _vm.dialogUploadVisible,
                fullscreen: true,
                "show-close": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogUploadVisible = $event
                },
                opened: _vm.openDialog,
              },
            },
            [
              _c(
                "div",
                {
                  ref: "dialogUpload",
                  staticClass: "upLoadContentBox",
                  staticStyle: { padding: "0" },
                },
                _vm._l(_vm.printBox.printList, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "upLoadContent" },
                    [
                      _c("div", { staticClass: "blackBoxLine" }, [
                        _c("div", { staticClass: "rowTopClass" }, [
                          _c(
                            "div",
                            {
                              staticClass: "l",
                              staticStyle: { "font-size": "26px" },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("collectionCenter.LSSite")) +
                                  _vm._s(item.siteName)
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "r" }, [
                            _c("img", {
                              staticStyle: { width: "80%", height: "auto" },
                              attrs: { src: _vm.LOGO, alt: "" },
                            }),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "titleTop",
                            staticStyle: { height: "30px" },
                          },
                          [_c("p")]
                        ),
                        _c("div", { staticClass: "rowClass" }, [
                          _c(
                            "div",
                            {
                              staticClass: "l",
                              staticStyle: {
                                "font-size": "26px",
                                "min-height": "60px",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("collectionCenter.LanShouP"))
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "r",
                              staticStyle: {
                                "font-size": "26px",
                                "min-height": "60px",
                              },
                            },
                            [_vm._v(_vm._s(item.lanshouName))]
                          ),
                        ]),
                        _c("div", { staticClass: "rowClass" }, [
                          _c(
                            "div",
                            {
                              staticClass: "l",
                              staticStyle: {
                                "font-size": "26px",
                                "min-height": "60px",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("collectionCenter.lanshouTime"))
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "r",
                              staticStyle: {
                                "font-size": "26px",
                                "min-height": "60px",
                              },
                            },
                            [_vm._v(_vm._s(item.lanshouTime))]
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "titleTop",
                            staticStyle: { height: "62px" },
                          },
                          [
                            _c("p", { staticStyle: { "font-size": "26px" } }, [
                              _vm._v(
                                _vm._s(_vm.$t("collectionCenter.lanshouMsg"))
                              ),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "rowClass" }, [
                          _c(
                            "div",
                            {
                              staticClass: "l",
                              staticStyle: {
                                "font-size": "26px",
                                "min-height": "60px",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("collectionCenter.LSSum")))]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "r bigSize",
                              staticStyle: {
                                "font-size": "26px",
                                "min-height": "60px",
                              },
                            },
                            [_vm._v(_vm._s(item.lanshouSum))]
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "titleTop",
                            staticStyle: { height: "62px" },
                          },
                          [
                            _c("p", { staticStyle: { "font-size": "26px" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("collectionCenter.customerSignature")
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c("div", {}, [
                          _c("div", { staticClass: "signatureBox" }, [
                            _c("img", {
                              staticStyle: { width: "auto", height: "198px" },
                              attrs: {
                                src: item.uploadSignImg,
                                fit: "contain",
                              },
                            }),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "printBtnClass" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "onlyClickOnce",
                      attrs: { type: "success" },
                      on: { click: _vm.saveCollectioniOrder },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.Save")))]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm.dayinVisible
        ? _c(
            "div",
            [
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "",
                    visible: _vm.dayinVisible,
                    fullscreen: true,
                    "show-close": false,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dayinVisible = $event
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "dayinContentBox" },
                    _vm._l(_vm.printBox.printList, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "dayinImg" },
                        [
                          _c("div", { staticClass: "dyImg" }, [
                            _c("img", {
                              staticStyle: {
                                width: "900px",
                                height: "auto",
                                "max-width": "100%",
                              },
                              attrs: {
                                src: item.uploadSignImg || "",
                                fit: "contain",
                              },
                              on: {
                                load: function ($event) {
                                  return _vm.loadImg(index)
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "dayinBox" }, [
                            _c("div", { staticClass: "dayinCenter" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "rowTopClass",
                                  staticStyle: {
                                    "border-top": "1px solid #000",
                                    "border-bottom": "none",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "l" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("collectionCenter.LSSite")
                                      ) + _vm._s(item.siteName)
                                    ),
                                  ]),
                                  _c("div", { staticClass: "r" }, [
                                    _c("img", {
                                      attrs: { src: _vm.LOGO, alt: "" },
                                    }),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "titleTop packageMsg",
                                  staticStyle: {
                                    height: "40px",
                                    "font-size": "20px",
                                  },
                                },
                                [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(_vm.$t("collectionCenter.pgMsg"))
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {},
                                [
                                  _c(
                                    "div",
                                    { staticClass: "pgTableRowTitle" },
                                    [
                                      _c("div", { staticClass: "pgTableRow" }, [
                                        _c("span", { staticClass: "one" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("collectionCenter.ind")
                                            )
                                          ),
                                        ]),
                                        _c("span", { staticClass: "two" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "collectionCenter.pgBarCode"
                                              )
                                            )
                                          ),
                                        ]),
                                        _c("span", { staticClass: "three" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "collectionCenter.scanTime"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("div", { staticClass: "pgTableRow" }, [
                                        _c("span", { staticClass: "one" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("collectionCenter.ind")
                                            )
                                          ),
                                        ]),
                                        _c("span", { staticClass: "two" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "collectionCenter.pgBarCode"
                                              )
                                            )
                                          ),
                                        ]),
                                        _c("span", { staticClass: "three" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "collectionCenter.scanTime"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _vm._l(
                                    item.packageNumberList,
                                    function (itemm, indexx) {
                                      return _c(
                                        "div",
                                        {
                                          key: indexx,
                                          staticClass: "tableClass",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "pgTableRow" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "one" },
                                                [_vm._v(_vm._s(indexx + 1))]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "two" },
                                                [
                                                  _vm._v(
                                                    _vm._s(itemm.scanNumber)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "three" },
                                                [_vm._v(_vm._s(itemm.scanTime))]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  item.packageNumberList &&
                                  item.packageNumberList.length % 2 !== 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "tableClass",
                                          staticStyle: { float: "right" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "pgTableRow" },
                                            [
                                              _c("span", {
                                                staticClass: "one",
                                              }),
                                              _c("span", {
                                                staticClass: "two",
                                              }),
                                              _c("span", {
                                                staticClass: "three",
                                              }),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        height: "43px",
                                        "line-height": "43px",
                                        "font-size": "24px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("collectionCenter.pgTotal")
                                        ) + _vm._s(item.lanshouSum)
                                      ),
                                    ]
                                  ),
                                ],
                                2
                              ),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    { staticClass: "printBtnClass" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "success" },
                          on: { click: _vm.printBtnClick },
                        },
                        [_vm._v(_vm._s(_vm.$t("collectionCenter.Print")))]
                      ),
                      _c("el-button", { on: { click: _vm.cancelBtnClick } }, [
                        _vm._v(_vm._s(_vm.$t("collectionCenter.cancel"))),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.pollVisible
        ? _c(
            "div",
            { staticClass: "pollVisible" },
            [
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: _vm.$t("collectionCenter.pollList"),
                    visible: _vm.pollVisible,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.pollVisible = $event
                    },
                  },
                },
                [
                  _c("div", { staticClass: "pollTop" }, [
                    _vm._v(
                      _vm._s(_vm.$t("collectionCenter.NumName")) +
                        _vm._s(_vm.pollListName)
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "pollBox" },
                    _vm._l(_vm.pollListNumber, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "pollContent" },
                        [
                          _c("span", { staticClass: "pollIndex" }, [
                            _vm._v(_vm._s(index + 1) + "."),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("collectionCenter.BarcodeL")) +
                                _vm._s(item)
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.signVisible
        ? _c("div", { staticClass: "signMask" }, [
            _c("div", { staticClass: "signVisibleClass" }, [
              _c("div", {
                staticClass: "el-icon-close signClose",
                on: {
                  click: function ($event) {
                    _vm.signVisible = false
                  },
                },
              }),
              _c("div", { staticClass: "page-content" }, [
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c("vue-esign", {
                      ref: "esign",
                      attrs: {
                        width: 1024,
                        height: 728,
                        "line-width": 5,
                        "line-color": "#000",
                        "bg-color": "#FFF",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { type: "danger" },
                        on: { click: _vm.handleReset },
                      },
                      [_vm._v(_vm._s(_vm.$t("collectionCenter.empty")))]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { type: "success" },
                        on: { click: _vm.handleGenerate },
                      },
                      [_vm._v(_vm._s(_vm.$t("collectionCenter.Confirm")))]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "audioClass" }, [
        _c("audio", { ref: "audioSuc", attrs: { src: _vm.audioSucUrl } }),
        _c("audio", { ref: "audioFail", attrs: { src: _vm.audioFailUrl } }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }