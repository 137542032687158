// @ts-check
import ExcelJs from 'exceljs';
// @ts-ignore
import { saveAs } from 'file-saver';
import i18n from '@/lang';
import { cloneDeep } from 'lodash-es';
import { checkIntervalsOverlap } from '@/views/finance/utils.js';

/** @typedef {import('./types').TTableRow} TTableRow */
/** @typedef {import('./types').TTableObj} TTableObj */

/**
 * @type {TTableRow}
 * @desc 模板
 */
export const TMP_OBJ = {
  id: undefined,
  partitionAffiliateId: undefined, // 分区id 文件导入的时候才存在
  startWeight: 0, // 开始距离
  endWeight: 1, //  结束距离
  price: 0, //  安俊小屋基础价格
  standardPrice: 0, // 包裹价格
  overshipmentPrice: 0, // 揽收商家提成
  overWeightExtraPrice: undefined // 最低货量
};

/** @typedef {[boolean, string]} weightR */

// #region 校验
/**
 * 校验 起始/结束公里数
 * @param {TTableRow['startWeight']} startWeight 开始公里
 * @param {TTableRow['endWeight']} endWeight 结束公里
 * @returns {weightR}
 */
export const weightCheck = (startWeight, endWeight) => {
  /** @type {weightR} */
  const arr = [false, ''];

  if (startWeight === undefined) {
    // 公里数不能为空
    // @ts-ignore
    arr[1] = i18n.t('customerManagements.notNull');
    return arr;
  }
  if (endWeight === undefined) {
    // @ts-ignore
    arr[1] = i18n.t('customerManagements.notNull');
    return arr;
  }

  // 校验 必须大于起始KM
  if (endWeight <= startWeight) {
    // @ts-ignore
    arr[1] = i18n.t('basicData.TheStartValueCannotBeGreaterThanTheEndValue');
    return arr;
  }

  // 同一分区开始与结束不能重叠
  // {
  //   const isBool = isOverLap(list);
  //   if (!isBool) {
  //     arr[1] = i18n.t('basicData.区间不能重叠');
  //     return arr;
  //   }
  // }

  arr[0] = true;
  return arr;
};

/**
 * 区间是否重叠 如果返回false 代表没有重叠
 * @param {TTableRow[]} arr 当前分区的数据
 * @returns {boolean} 如果返回false 代表没有重叠
 */
export const isOverLap = (arr) => {
  /** @type {[number, number][]} */
  const list = arr.map(itme => {
    return [itme.startWeight, itme.endWeight];
  });
  return checkIntervalsOverlap(list);
};
// #endregion

/**
 * 校验价格详情
 * @param {TTableRow[]} arr
 * @returns {boolean}
 */
export const validatePrice = (arr) => {
  if (arr.length === 0) {
    return true;
  }

  const isBool = arr.every((item) => {
    const { startWeight, endWeight, price, overshipmentPrice, standardPrice } = item;

    // 校验 开始/结束 距离
    {
      const [isBool] = weightCheck(startWeight, endWeight);

      if (!isBool) {
        return false;
      }
    }

    // 安俊小屋基础价格
    if (price === undefined || price < 0) {
      return false;
    }

    // 包裹价格
    if (standardPrice === undefined || standardPrice < 0) {
      return false;
    }

    // 揽收商家提成

    if (overshipmentPrice === undefined || overshipmentPrice < 0) {
      return false;
    }

    return true;
  });

  if (!isBool) {
    return false;
  }

  // 当前分区下距离重叠不能重叠
  {
    const isBool = isOverLap(arr);
    if (isBool) {
      return false;
    }
  }
  return true;
};

// #region set设置数据
export const { notSetPartitionFun, setPartitionFun } = (() => {
  /**
   * 没有选择分区
   * @param {any[]} arr 导入的数据
   * @param {TTableRow[]} defaultArr 列表中已经存在的数据
   * @returns {TTableRow[]}
   */
  const notSetPartitionFun = (arr, defaultArr) => {
    // 导入的数据处理
    const list = arr.map(item => {
      const { id, partitionAffiliateId, startWeight, endWeight, price, standardPrice, overshipmentPrice, overWeightExtraPrice } = item;
      /** @type {TTableRow} */
      const obj = {
        ...TMP_OBJ,
        id,
        partitionAffiliateId, startWeight, endWeight, price, standardPrice, overshipmentPrice, overWeightExtraPrice
      };

      if (overWeightExtraPrice === null) {
        obj.overWeightExtraPrice = undefined;
      }

      return obj;
    });

    /** @type {TTableRow[]} */
    const defaultList = cloneDeep(defaultArr);

    // 当前为空
    if (defaultList.length === 0) {
      // this.defaultPartitionObj.default.arr = list;
      return list;
    }
    /** @type {TTableRow[]} */
    const listNotId = []; // 导入的列表不存在id

    list.forEach(item => {
      // 没有id
      if (!item.id) {
        listNotId.push(item);
        return;
      }

      // 查看导入的id是否在当前id中
      const index = defaultList.findIndex(key => key.id === item.id);

      // 没有相同的id
      if (index === -1) {
        item.id = undefined;
        listNotId.push(item);
        return;
      }

      // 存在相同的id
      defaultList[index] = item;
    });

    return [...defaultList, ...listNotId];
  };

  /**
   * 选择了分区
   * @param {any[]} arr 导入的数据
   * @param {TTableObj} partitionRow 列表中已经存在的数据
   * @returns {TTableObj}
   */
  const setPartitionFun = (arr, partitionRow) => {
    //

    /** @type {TTableObj} */
    const partitionObj = cloneDeep(partitionRow);

    // 没有默认数据
    {
      let size = 0;
      Object.values(partitionObj).forEach(item => {
        size += Object.keys(item.arr).length;
      });
      if (size === 0) {
        arr.forEach(item => {
          const { id, partitionAffiliateId, startWeight, endWeight, price, standardPrice, overshipmentPrice, overWeightExtraPrice } = item;
          /** @type {TTableRow} */
          const obj = {
            ...TMP_OBJ,
            id,
            partitionAffiliateId, startWeight, endWeight, price, standardPrice, overshipmentPrice, overWeightExtraPrice
          };

          if (overWeightExtraPrice === null) {
            obj.overWeightExtraPrice = undefined;
          }
          partitionObj[partitionAffiliateId].arr.push(obj);
        });

        return partitionObj;
      }
    }

    /** @type Map<number, TTableRow[]> */
    const map = new Map();

    // 数据整理
    arr.forEach(item => {
      const { id, partitionAffiliateId, startWeight, endWeight, price, standardPrice, overshipmentPrice, overWeightExtraPrice } = item;

      const obj = {
        ...TMP_OBJ,
        id,
        partitionAffiliateId, startWeight, endWeight, price, standardPrice, overshipmentPrice, overWeightExtraPrice
      };

      if (overWeightExtraPrice === null) {
        obj.overWeightExtraPrice = undefined;
      }

      // 将数据按照分区id划分
      if (map.has(partitionAffiliateId)) {
        map.get(partitionAffiliateId).push(obj);
      } else {
        map.set(partitionAffiliateId, [obj]);
      }
    });

    Object.entries(partitionObj).forEach(item => {
      const [key, value] = item;
      const numKey = Number(key);

      if (map.has(numKey)) {
        const vArr = map.get(numKey);
        partitionObj[key].arr = notSetPartitionFun(vArr, value.arr);
      }
    });

    return partitionObj;
  };

  return {
    notSetPartitionFun, setPartitionFun
  };
})();
// #endregion

// #region 导入导出
export const { downloadTemplate, importTemplate, downloadQuotation, SEPARATOR } = (() => {
  const SEPARATOR = '->'; // 分隔符
  const str1 = i18n.t('receivablePayable.ThirdPartyCollection'); // 第三方揽收
  const str2 = i18n.t('collectionCenter.dropOff'); // 上门交件

  // 模板标题
  const headArr = [
    {
      header: i18n.t('basicData.PartitionName'), // 分区
      key: 'partitionAffiliateId',
      width: 30
    },
    {
      header: i18n.t('basicData.KilometersStartKM') + '*', // 距离开始(KM) 不包含
      key: 'startWeight',
      width: 30
    },
    {
      header: i18n.t('basicData.MileageEndKM') + '*', // 距离结束(KM) 包含
      key: 'endWeight',
      width: 30
    },
    {
      // @ts-ignore
      header: i18n.t('collectionCenter.hut') + i18n.t('collectionCenter.turnTransport') + i18n.t('basicData.BasePrice') + '(PT014)*', // 安俊小屋基础价格PT014
      key: 'price',
      width: 30
    },
    {
      // @ts-ignore
      header: i18n.t('collectionCenter.hut') + i18n.t('collectionCenter.turnTransport') + i18n.t('basicData.商家提成') + '(PT0015)*', // 安俊小屋转运商家提成PT015
      key: 'overshipmentPrice',
      width: 30
    },
    {
      // @ts-ignore
      header: i18n.t('collectionCenter.hut') + i18n.t('collectionCenter.turnTransport') + i18n.t('basicData.包裹价格') + '(PT016)*', // 安俊小屋转运包裹价格PT016
      key: 'standardPrice',
      width: 30
    },
    {
      header: i18n.t('basicData.最低货量'), // 最低货量
      key: 'overWeightExtraPrice',
      width: 30
    },
    {
      header: 'ID', // id 下载报价的时候存在
      key: 'id',
      width: 20
    }
  ];
  // 分区
  const PartitionArr = [
    // {
    //   header: i18n.t('basicData.PartitionName'), // 分区
    //   key: 'partitionIdentifier',
    //   width: 20
    // },
    {
      header: i18n.t('basicData.PartitionName') + '(ID)', // 分区
      key: 'partitionAffiliateId',
      width: 20
    }

  ];

  /**
   * 获取头部，选择与未选择分区，对于索引为0的标题有区别
   *   如果选择了分区则 索引为0 需要加上 *
   *   @param {any[]} subregionList 分区名称
   *   @returns {any[]}
   */
  const getHeadObj = (subregionList) => {
    const arr = cloneDeep(headArr);
    if (subregionList.length > 0) {
      arr[0].header = arr[0].header + '*';
      // Sheet1.columns = arr;
      return arr;
    }
    return arr;
  };

  /**
   * 生成空白模板
   * @param {any[]} subregionList 所有分区
   * @param {any[]} sheet1RowArr 下载报价的时候才需要传
   */
  const createTemplate = (subregionList, sheet1RowArr = []) => {
    const wb = new ExcelJs.Workbook();
    const Sheet1 = wb.addWorksheet('Sheet1');
    const Sheet2 = wb.addWorksheet('Sheet2');

    // 分区处理
    Sheet1.columns = getHeadObj(subregionList);

    // 下载报价的时候
    if (sheet1RowArr.length) {
      Sheet1.addRows(sheet1RowArr);
    }
    // Sheet1.addRows([
    //   {
    //     partitionAffiliateId: '测试赛->402',
    //     distanceModel: '<50KM->1',
    //     price: '4',
    //     overshipmentPrice: 3,
    //     price: 2,
    //     standardCargo: 1
    //   }
    // ]);

    // 分区列表
    Sheet2.columns = cloneDeep(PartitionArr);
    const Sheet2_data = [];
    if (subregionList && subregionList.length > 0) {
      subregionList.forEach((item) => {
        const { id, partitionIdentifier } = item;
        Sheet2_data.push({
          partitionAffiliateId: `${partitionIdentifier}${SEPARATOR}${id}`
        });
      });
    }
    Sheet2.addRows(Sheet2_data);

    new Array(50000).fill(0).forEach((_, idx) => {
      const row = idx + 2;

      // 分区名称，如果存在则设置
      if (subregionList && subregionList.length > 0) {
        Sheet1.getCell(row, 1).dataValidation = {
          type: 'list',
          formulae: [`=Sheet2!$A$2:$A${Sheet2_data.length + 1}`]
        };
      }
    });

    return wb;
  };

  /**
   * 下载模板
   * @param {any[]} subregionList 所有分区
   */
  const downloadTemplate = async(subregionList) => {
    const wb = createTemplate(subregionList);

    const buffer = await wb.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/octet-stream'
    });

    // @ts-ignore
    const saveName = `${str1} + ${str2}.xlsx`;
    saveAs(blob, saveName);
  };

  /**
   * 导入模板处理
   * @param {any[]} arr 导入的数据
   * @param {any[]} subregionList 分区
   * @returns {TTableRow[]}
   */
  const importTemplate = (arr, subregionList) => {
    // console.log('导入的数据', arr);

    const subregionID = subregionList.map(item => item.id);

    const headArr = getHeadObj(subregionList);
    console.log(headArr);

    /** @type TTableRow[] */
    const list = [];
    arr.forEach(item => {
      /** @type {TTableRow} */
      const obj = {
        ...TMP_OBJ
      };

      Object.entries(item).map(row => {
        const [key, value] = row;

        // 分区
        if (key === headArr[0].header) {
          const arr = (value || '').split(SEPARATOR);
          if (arr.length === 2) {
            const k = arr[1] > 0 ? arr[1] : undefined;

            // 选中的值符合要求
            if (k && subregionID.includes(Number(k))) {
              obj[headArr[0].key] = Number(k);
            }
          }
        }

        // 开始距离
        if (key === headArr[1].header) {
          obj[headArr[1].key] = value > 0 ? value : 0;
        }

        // 结束距离
        if (key === headArr[2].header) {
          obj[headArr[2].key] = value > 0 ? value : 0;
        }

        // 安俊小屋转运包裹价格PT016
        if (key === headArr[5].header) {
          obj[headArr[5].key] = value > 0 ? value : 0;
        }

        // 安俊小屋转运商家提成PT015
        if (key === headArr[4].header) {
          obj[headArr[4].key] = value > 0 ? value : 0;
        }

        // 安俊小屋基础价格PT014
        if (key === headArr[3].header) {
          obj[headArr[3].key] = value > 0 ? value : 0;
        }

        // 最低存货
        if (headArr[6] && key === headArr[6].header) {
          obj[headArr[6].key] = value > 0 ? value : undefined;
        }

        // id
        if (headArr[7] && key === headArr[7].header) {
          obj[headArr[7].key] = value > 0 ? value : undefined;
        }

        return obj;
      });

      list.push(obj);
    });

    return list;
  };

  // 下载报价
  const downloadQuotation = async(list, subregionList) => {
    const wb = createTemplate(subregionList, list);

    const buffer = await wb.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/octet-stream'
    });

    const saveName = '报价' + '.xlsx';

    saveAs(blob, saveName);
  };

  return {
    downloadTemplate,
    importTemplate,
    downloadQuotation,
    SEPARATOR
  };
})();
// #endregion
