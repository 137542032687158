var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container userOperation" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": true,
            title: _vm.userOperationPop.title,
            visible: _vm.userOperationPop.visible,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.userOperationPop, "visible", $event)
            },
            close: _vm.closeUserOperation,
            open: _vm.openUserOperation,
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "userOperationRefOne",
                  attrs: {
                    model: _vm.userForm,
                    rules: _vm.userOperationRules,
                    "label-width": "150px",
                  },
                },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: { "flex-wrap": "wrap" },
                      attrs: { justify: "start", type: "flex" },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("userManage.usernameL"),
                                prop: "username",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: _vm.isUsername },
                                model: {
                                  value: _vm.userForm.username,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.userForm,
                                      "username",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "userForm.username",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("userManage.userPhoneL"),
                                prop: "phone",
                                property: "电话",
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.userForm.phone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.userForm, "phone", $$v)
                                  },
                                  expression: "userForm.phone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "CPF：", prop: "cpf" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "collectionCenter.enterpriseTaxNumTips11"
                                  ),
                                },
                                model: {
                                  value: _vm.userForm.cpf,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.userForm,
                                      "cpf",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "userForm.cpf",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("userManage.realNameL"),
                                prop: "realName",
                                property: "真实姓名",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { clearable: "" },
                                model: {
                                  value: _vm.userForm.realName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.userForm,
                                      "realName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "userForm.realName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("collectionCenter.belongToSite"),
                                prop: "siteId",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "operationCenter.PleaseSelect"
                                    ),
                                    clearable: "",
                                    filterable: "",
                                  },
                                  on: { focus: _vm.getSiteList },
                                  model: {
                                    value: _vm.userForm.siteId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.userForm, "siteId", $$v)
                                    },
                                    expression: "userForm.siteId",
                                  },
                                },
                                _vm._l(_vm.siteIdList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("collectionCenter.QYcnpj") + "：",
                                rules: {
                                  required: _vm.isDriver,
                                  validator: _vm.cnpjValidator,
                                  trigger: ["blur", "change"],
                                },
                                prop: "signCnpj",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "collectionCenter.enterpriseTaxNumTips14"
                                  ),
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.userForm.signCnpj,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.userForm, "signCnpj", $$v)
                                  },
                                  expression: "userForm.signCnpj",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label:
                                  _vm.$t(
                                    "collectionCenter.modalDriverLicence"
                                  ) + "：",
                                rules: {
                                  required: _vm.isDriver,
                                  message: _vm.$t("collectionCenter.notNull"),
                                  trigger: ["blur", "change"],
                                },
                                prop: "driverLicense",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "collectionCenter.pleaseInputNumber"
                                  ),
                                  clearable: "",
                                },
                                on: { input: _vm.inputDriverLicense },
                                model: {
                                  value: _vm.userForm.driverLicense,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.userForm, "driverLicense", $$v)
                                  },
                                  expression: "userForm.driverLicense",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("userManage.EnglishName"),
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.userForm.englishName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.userForm,
                                      "englishName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "userForm.englishName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "userManage.PlatformWhichBelongs"
                                ),
                                prop: "platformCode",
                                property: "所属平台",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { clearable: "", filterable: "" },
                                  model: {
                                    value: _vm.userForm.platformCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.userForm,
                                        "platformCode",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "userForm.platformCode",
                                  },
                                },
                                _vm._l(_vm.platform, function (item, index) {
                                  return _c("el-option", {
                                    key: "platformCode" + index,
                                    attrs: {
                                      label: item.platformName,
                                      value: item.platformCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticClass: "uploaderClass", attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("collectionCenter.enclosure"),
                                prop: "attachmentUrl",
                              },
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  staticClass: "avatar-uploader",
                                  attrs: {
                                    "before-upload": _vm.beforeUpload,
                                    "file-list": _vm.fileList,
                                    "http-request": _vm.Upload,
                                    limit: 1,
                                    "on-remove": _vm.handleRemove,
                                    "show-file-list": true,
                                    action: "",
                                  },
                                },
                                [
                                  !_vm.userForm.attachmentUrl ||
                                  _vm.userForm.attachmentUrl.length === 0
                                    ? _c("span", [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-plus avatar-uploader-icon bd",
                                        }),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("userManage.roles"),
                                prop: "roles",
                                property: "用户角色",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  ref: "rolesRef",
                                  attrs: { clearable: "", filterable: "" },
                                  on: { change: _vm.chooseRoles },
                                  model: {
                                    value: _vm.userForm.roles,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.userForm, "roles", $$v)
                                    },
                                    expression: "userForm.roles",
                                  },
                                },
                                _vm._l(_vm.roleList, function (item) {
                                  return _c("el-option", {
                                    key: "roleID" + item.id,
                                    attrs: {
                                      label: item.roleName,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("userManage.userState"),
                                prop: "userState",
                                property: "用户状态",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { size: "small" },
                                  model: {
                                    value: _vm.userForm.userState,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.userForm,
                                        "userState",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "userForm.userState",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "1" } },
                                    [_vm._v(_vm._s(_vm.$t("userManage.onJob")))]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "2" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("userManage.leaveJob"))
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "3" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("userManage.vacation"))
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("userManage.code"),
                                prop: "code",
                                property: "员工编号",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled:
                                    _vm.userOperationPop.type == "add"
                                      ? false
                                      : true,
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.userCodeCheck(_vm.userForm.code)
                                  },
                                },
                                model: {
                                  value: _vm.userForm.code,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.userForm,
                                      "code",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "userForm.code",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("userManage.hasAudit"),
                                prop: "hasAudit",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { size: "small" },
                                  model: {
                                    value: _vm.userForm.hasAudit,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.userForm,
                                        "hasAudit",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "userForm.hasAudit",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "1" } },
                                    [_vm._v(_vm._s(_vm.$t("userManage.Yes")))]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "0" } },
                                    [_vm._v(_vm._s(_vm.$t("userManage.No")))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("userManage.orgCode"),
                                prop: "orgCode",
                              },
                            },
                            [
                              _c("el-cascader", {
                                ref: "cascader",
                                attrs: {
                                  options: _vm.organizationTree,
                                  props: _vm.organizationTreeProps,
                                  "show-all-levels": false,
                                  clearable: "",
                                  filterable: "",
                                },
                                on: { change: _vm.organizationTreeChange },
                                model: {
                                  value: _vm.userForm.orgCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.userForm, "orgCode", $$v)
                                  },
                                  expression: "userForm.orgCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("userManage.userAccountStatus"),
                                prop: "accountState",
                                property: "账户状态",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: {
                                    disabled: _vm.isEdit,
                                    size: "small",
                                  },
                                  model: {
                                    value: _vm.userForm.accountState,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.userForm,
                                        "accountState",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "userForm.accountState",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "1" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("userManage.enable"))
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "0" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("userManage.Disable"))
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("userManage.position"),
                                prop: "position",
                                property: "职位",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "userManage.pleaseChoose"
                                    ),
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.userForm.position,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.userForm, "position", $$v)
                                    },
                                    expression: "userForm.position",
                                  },
                                },
                                _vm._l(_vm.positionList, function (item) {
                                  return _c("el-option", {
                                    key: "id" + item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("userManage.useRange") } },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.scopeOfUseCheck,
                                    callback: function ($$v) {
                                      _vm.scopeOfUseCheck = $$v
                                    },
                                    expression: "scopeOfUseCheck",
                                  },
                                },
                                [
                                  _c("el-checkbox", { attrs: { label: "1" } }, [
                                    _vm._v("PC"),
                                  ]),
                                  _c("el-checkbox", { attrs: { label: "2" } }, [
                                    _vm._v("PDA"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("userManage.email"),
                                prop: "email",
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.userForm.email,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.userForm,
                                      "email",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "userForm.email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "location" } },
                            [
                              _c("template", { slot: "label" }, [
                                _c(
                                  "div",
                                  { staticStyle: { display: "inline-block" } },
                                  [
                                    _c(
                                      "span",
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "QuestionMark",
                                            attrs: { placement: "top" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "content" },
                                                slot: "content",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "userManage.business1"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class": "QuestionMark",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("userManage.business"))
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c("el-cascader", {
                                attrs: {
                                  options: _vm.AreaList,
                                  props: _vm.modifiedProps,
                                  "show-all-levels": false,
                                  clearable: "",
                                  filterable: "",
                                },
                                on: { change: _vm.handlelineBusiness },
                                model: {
                                  value: _vm.userForm.location,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.userForm, "location", $$v)
                                  },
                                  expression: "userForm.location",
                                },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      !_vm.isEdit
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("userManage.password"),
                                    prop: !_vm.isEdit ? "password" : "password",
                                    property: "密码",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { maxlength: "12", minlength: "8" },
                                    model: {
                                      value: _vm.userForm.password,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.userForm,
                                          "password",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "userForm.password",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "document number：",
                                prop: "documentNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { type: "number" },
                                model: {
                                  value: _vm.userForm.documentNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.userForm,
                                      "documentNumber",
                                      $$v
                                    )
                                  },
                                  expression: "userForm.documentNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("userManage.userCategory"),
                                prop: "userType",
                              },
                            },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  on: { change: _vm.changeUserType },
                                  model: {
                                    value: _vm.getUserTypee,
                                    callback: function ($$v) {
                                      _vm.getUserTypee = $$v
                                    },
                                    expression: "getUserTypee",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("userManage.onlyDotUse")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("userManage.remark"),
                                prop: "remark",
                                property: "备注",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea" },
                                model: {
                                  value: _vm.userForm.remark,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.userForm,
                                      "remark",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "userForm.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "right" },
                          attrs: { span: 24 },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                icon: "el-icon-search",
                                size: "small",
                                type: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.submit("userOperationRefOne")
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("userManage.confirm")) + " "
                              ),
                            ]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                icon: "el-icon-refresh",
                                size: "small",
                                type: "warning",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("closeUserOperationDialog")
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("userManage.cancel")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }