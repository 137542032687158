
// 组织架构
const organizationManage = {
  OrganizationManagement: {
    zh: '组织管理',
    en: '',
    pu: 'Gestão organizacional'
  },
  OrganizationChart: {
    zh: '组织架构图',
    en: '',
    pu: 'Organograma'
  },
  DownloadPictures: {
    zh: '下载图片（.png）',
    en: '',
    pu: 'Download imagem (.png)'
  },
  GenerateImageWaitDownload: {
    zh: '生成图片中，等待下载',
    en: '',
    pu: 'Em geração de imagens, esperando para download'
  },
  OrganizationChartPNG: {
    zh: '组织架构图.png',
    en: '',
    pu: 'Organograma.png'
  },
  // 添加下级、添加同级、编辑
  addNextLevel: {
    zh: '添加下级',
    en: '',
    pu: 'adicionar permissão subordinada'
  },
  addSameLevel: {
    zh: '添加同级',
    en: '',
    pu: 'adicionar permissão de pares'
  },
  edit: {
    zh: '编辑',
    en: 'Edit',
    pu: 'editar'
  },
  Delete: {
    zh: '删除',
    en: 'Delete',
    pu: 'Excluir'
  },
  AreYouSureDelete: {
    zh: '确定删除吗？',
    en: 'Are you sure to delete it',
    pu: 'Tem certeza de apagar?'
  },
  AddingOrganization: {
    zh: '添加组织机构',
    en: '',
    pu: 'Adicionar Organização'
  },
  editOrganization: {
    zh: '编辑组织机构',
    en: '',
    pu: 'editar Organização'
  },
  OrganizationID: {
    zh: '组织机构ID',
    en: '',
    pu: 'ID da organização'
  },
  ParentOrganization: {
    zh: '父组织机构',
    en: '',
    pu: 'Organização de pai'
  },
  OrganizationName: {
    zh: '组织名称',
    en: '',
    pu: 'Nome da organização'
  },
  sort: {
    zh: '排序',
    en: '',
    pu: 'ordenar'
  },
  permissionNameNotEmpty: {
    zh: '权限名称不能为空',
    en: '',
    pu: 'O nome da permissão não pode estar vazio'
  },
  PpermissionIDNotEmpty: {
    zh: '父权限ID不能为空',
    en: '',
    pu: 'O ID de permissão do pai não pode estar vazio'
  },
  permissionLogoNotEmpty: {
    zh: '权限标识不能为空',
    en: '',
    pu: 'Os marcadores de permissão não podem estar vazios'
  },
  sortNotEmpty: {
    zh: '排序不能为空',
    en: '',
    pu: 'A ordenação não pode ser vazia'
  },
  sortIsNumber: {
    zh: '排序必须为数字值',
    en: '',
    pu: 'A ordenação deve ser valores numéricos'
  },
  state: {
    zh: '状态',
    en: 'state',
    pu: 'status'
  },
  all: {
    zh: '全部',
    en: 'all',
    pu: 'tudo'
  },
  enable: {
    zh: '启用',
    en: 'enable',
    pu: 'ativar'
  },
  Disable: {
    zh: '停用',
    en: 'Disable',
    pu: 'Desativar'
  },
  name: {
    zh: '名称',
    en: 'name',
    pu: 'nome'
  },
  test13: {
    zh: '账户名',
    en: '',
    pu: 'Nome da conta'
  },
  phone14: {
    zh: '电话',
    en: 'Phone',
    pu: 'Telefone'
  },
  test15: {
    zh: '角色',
    en: '',
    pu: 'papel'
  },
  CollapseQuery: {
    zh: '收起查询',
    en: 'Collapse the Query button',
    pu: 'Recolher o botão Consulta'
  },
  ExpandQuery: {
    zh: '展开查询',
    en: 'Expand the Query button',
    pu: 'Expandir o botão Consulta'
  },
  add: {
    zh: '新增',
    en: 'add',
    pu: 'adicionar'
  },
  test16: {
    zh: '用户导出',
    en: '',
    pu: 'Exportação do usuário'
  },
  test17: {
    zh: '账号停用',
    en: '',
    pu: 'desativar a conta'
  },
  test18: {
    zh: '账号启用',
    en: '',
    pu: 'ativar a conta'
  },
  ind: {
    zh: '序号',
    en: 'index',
    pu: 'Número de série.'
  },
  name19: {
    zh: '名称',
    en: 'name',
    pu: 'nome'
  },
  test20: {
    zh: '英文名称',
    en: '',
    pu: 'nome em inglês'
  },
  test21: {
    zh: '账户名',
    en: '',
    pu: 'nome da conta'
  },
  test22: {
    zh: '所属部门',
    en: '',
    pu: 'departamento'
  },
  AccountStatus: {
    zh: '账户状态',
    en: 'Account status',
    pu: 'Status da Conta'
  },
  test23: {
    zh: '账户权限状态',
    en: '',
    pu: 'status da permissão de conta'
  },
  test24: {
    zh: '创建日期',
    en: '',
    pu: 'data de criação'
  },
  creator: {
    zh: '创建人',
    en: 'creator',
    pu: 'Criado por'
  },
  operation: {
    zh: '操作',
    en: 'operation',
    pu: 'Operar'
  },
  MoreActions: {
    zh: '更多操作',
    en: 'More actions',
    pu: 'Mais ações'
  },
  test25: {
    zh: '授权',
    en: '',
    pu: 'autorizar'
  },
  resetPassword: {
    zh: '重置密码',
    en: 'reset password',
    pu: 'redefinir a senha'
  },
  newPwd: {
    zh: '新密码',
    en: '',
    pu: 'novas enha'
  },
  inputNewPwd: {
    zh: '请输入新密码',
    en: '',
    pu: 'Favor nova senha'
  },
  inputHint: {
    zh: '必须由 8-12 位小写或大写字母、数字组成，可选特殊符号',
    en: '',
    pu: 'Deve consistir de 8-12 letras minúsculas ou maiúsculas, números, símbolos especiais opcionais'
  },
  checkPwd: {
    zh: '确认密码',
    en: '',
    pu: 'Confirmar senha'
  },
  inputNewCodeAgain: {
    zh: '请再次输入新密码',
    en: '',
    pu: 'Por favor digite novamente a nova senha'
  },
  requestedInputCode: {
    zh: '请按照要求输入密码',
    en: '',
    pu: 'Por favor digitar a senha conforme requerido'
  },
  newCodeNotOldCode: {
    zh: '新密码与旧密码不能相同',
    en: '',
    pu: 'A nova senha não pode ser a mesma que a antiga'
  },
  inputConfirmCode: {
    zh: '请输入确认密码',
    en: '',
    pu: 'Introduza a senha de confirmação, por favor'
  },
  loginAgain: {
    zh: '密码修改成功，请重新登录',
    en: '',
    pu: 'Senha alterada com sucesso, por favor faça o login novamente'
  },
  succeed: {
    zh: '成功',
    en: 'succeed',
    pu: 'suceder'
  },
  test26: {
    zh: '编辑用户',
    en: '',
    pu: 'Editar usuário'
  },
  basicInformation: {
    zh: '基本信息',
    en: '',
    pu: 'Informações básicas'
  },
  test28: {
    zh: '账号:',
    en: '',
    pu: 'número da conta'
  },
  userPhone: {
    zh: '电话:',
    en: 'Phone',
    pu: 'Telefone'
  },
  test29: {
    zh: '真实姓名:',
    en: '',
    pu: 'nome real'
  },
  test30: {
    zh: '英文名:',
    en: '',
    pu: 'nome em inglês'
  },
  test31: {
    zh: '所属平台:',
    en: '',
    pu: 'plataforma'
  },
  pleaseChoose: {
    zh: '请选择',
    en: 'Please select',
    pu: 'Favor selecionar'
  },
  pleaseInput: {
    zh: '请输入',
    en: 'Please enter the content',
    pu: 'por favor digitar'
  },
  test32: {
    zh: '组织机构:',
    en: '',
    pu: 'Organização:'
  },
  test33: {
    zh: '是否为审核人:',
    en: '',
    pu: 'É o revisor:'
  },
  test34: {
    zh: '用户角色:',
    en: '',
    pu: 'Papel do usuário'
  },
  headerAccountStatus: {
    zh: '账户状态：',
    en: 'Account status：',
    pu: 'Status da Conta：'
  },
  test35: {
    zh: '职位:',
    en: '',
    pu: 'posiçaõ'
  },
  test36: {
    zh: '员工编号:',
    en: '',
    pu: 'número do funcionário'
  },
  test37: {
    zh: '用户状态:',
    en: '',
    pu: 'status do usuário'
  },
  test38: {
    zh: '入职日期:',
    en: '',
    pu: 'data de entrada:'
  },
  test39: {
    zh: '头像:',
    en: '',
    pu: 'foto de perfil'
  },
  test40: {
    zh: '简历:',
    en: '',
    pu: 'CV'
  },
  test41: {
    zh: '只能上传PDF文件，且不超过2M',
    en: '',
    pu: 'Carregar somente arquivos em PDF e não maior que 2M'
  },
  note: {
    zh: '备注',
    en: 'remark',
    pu: 'observações'
  },
  Next: {
    zh: '下一步',
    en: 'Next',
    pu: 'Próximo'
  },
  identityCard: {
    zh: '身份证:',
    en: 'identityCard:',
    pu: 'bilhete de identidade:'
  },
  birthday: {
    zh: '生日:',
    en: '',
    pu: 'aniversário:'
  },
  gender: {
    zh: '性别:',
    en: '',
    pu: 'gênero:'
  },
  male: {
    zh: '男',
    en: '',
    pu: 'masculino'
  },
  female: {
    zh: '女',
    en: '',
    pu: 'feminino'
  },
  educationBackground: {
    zh: '学历:',
    en: '',
    pu: 'qualificações acadêmicas：'
  },
  primarySchool: {
    zh: '小学',
    en: '',
    pu: 'ensino primário'
  },
  juniorHighSchool: {
    zh: '初中',
    en: '',
    pu: 'ensino secundário'
  },
  highSchool: {
    zh: '高中',
    en: '',
    pu: 'ensino médio'
  },
  college: {
    zh: '大专',
    en: '',
    pu: 'colégio júnior'
  },
  undergraduateCourse: {
    zh: '本科',
    en: '',
    pu: 'licenciatura'
  },
  master: {
    zh: '硕士',
    en: '',
    pu: 'mestrado'
  },
  doctor: {
    zh: '博士',
    en: '',
    pu: 'doutoramento'
  },
  socialSecurity: {
    zh: '社保:',
    en: '',
    pu: 'seguridade social'
  },
  accumulationFund: {
    zh: '公积金:',
    en: '',
    pu: 'fundo de acumulação:'
  },
  QQ: {
    zh: 'QQ:',
    en: '',
    pu: 'QQ:'
  },
  dingding: {
    zh: '钉钉:',
    en: '',
    pu: 'DingDing:'
  },
  termDate: {
    zh: '离职日期:',
    en: '',
    pu: 'data de saída:'
  },
  weixin: {
    zh: '微信:',
    en: '',
    pu: 'WeChat:'
  },
  email: {
    zh: '邮箱:',
    en: 'mailbox',
    pu: 'correio electrônico/email:'
  },
  weibo: {
    zh: '微博:',
    en: '',
    pu: 'WeiBo:'
  },
  emergencyContact: {
    zh: '紧急联系人:',
    en: '',
    pu: 'contacto de emergência:'
  },
  emergencyTelephoneNumber: {
    zh: '紧急联系电话:',
    en: '',
    pu: 'contacto de emergência:'
  },
  address: {
    zh: '住址:',
    en: '',
    pu: 'endereço:'
  },
  Previous: {
    zh: '上一步',
    en: 'Previous',
    pu: 'Anterior'
  },
  userAuthorization: {
    zh: '用户授权',
    en: '',
    pu: 'Autorização do usuário'
  },
  confirm: {
    zh: '确定',
    en: 'determine',
    pu: 'determinar'
  },
  cancel: {
    zh: '取消',
    en: 'cancel',
    pu: 'Cancelar'
  },
  inputName: {
    zh: '请填写账户名',
    en: '',
    pu: 'Favor preencher o nome de sua conta'
  },
  test42: {
    zh: '请填写联系电话',
    en: '',
    pu: 'Favor preencher o número de contato'
  },
  test43: {
    zh: '请填写用户真实姓名',
    en: '',
    pu: 'Por favor, preencha nome real do usuário'
  },
  test44: {
    zh: '请填写密码',
    en: '',
    pu: 'Favor preencher a senha'
  },
  test45: {
    zh: '请选择用户组织架构',
    en: '',
    pu: 'Por favor, selecione a organização do usuário'
  },
  test46: {
    zh: '请选择用户角色',
    en: '',
    pu: 'Favor selecionar o papel do usuário'
  },
  test47: {
    zh: '请选择职位',
    en: '',
    pu: 'Por favor, selecione uma posição'
  },
  test48: {
    zh: '请选择用户入职日期',
    en: '',
    pu: 'Favor selecionar a data de entrada do usuário'
  },
  test49: {
    zh: '请输入身份证',
    en: '',
    pu: 'Por favor, digite seu número de identificação'
  },
  test50: {
    zh: '请输入紧急联系人',
    en: '',
    pu: 'Favor digitar um contato de emergência'
  },
  test51: {
    zh: '请选择需要导出的用户',
    en: '',
    pu: 'Favor selecionar o usuário para o qual você deseja exportar'
  },
  test52: {
    zh: '是否启用用户账户?',
    en: '',
    pu: 'ativar a conta do usuário?'
  },
  test53: {
    zh: '是否停用用户账户?',
    en: '',
    pu: 'desativar a conta do usuário?'
  },
  tips: {
    zh: '提示',
    en: 'tips',
    pu: 'Dica'
  }
};
export {
  organizationManage
};
