export const editingRegistration = {
  // index
  editions: {
    zh: '版本',
    en: 'Editions',
    pu: 'Edições'
  },
  createEdition: {
    zh: '创建版本',
    en: 'Create edition',
    pu: 'Cadastrar edição'
  },
  groupCode: {
    zh: '组代码',
    en: 'Group Code',
    pu: 'Cód. do grupo'
  },
  //   groupCodePlaceholder: {
  //     zh: '按组代码搜索',
  //     en: 'Search by group code',
  //     pu: 'Pesquise pelo código do grupo'
  //   },
  firstBatchCode: {
    zh: '第一批代码',
    en: '1st Batch Code',
    pu: '1° lote código'
  },
  firstBatchName: {
    zh: '第一批名称',
    en: '1st Batch Name',
    pu: '1° lote nome'
  },
  firstBatchVolume: {
    zh: '第一批容量',
    en: '1st Batch Volume',
    pu: '1° lote volume'
  },
  secondBatchCode: {
    zh: '第二批代码',
    en: '2nd Batch Code',
    pu: '2° lote código'
  },
  secondBatchName: {
    zh: '第二批名称',
    en: '2nd Batch Name',
    pu: '2° lote nome'
  },
  secondBatchVolume: {
    zh: '第二批容量',
    en: '2nd Batch Volume',
    pu: '2° lote volume'
  },
  thirdBatchCode: {
    zh: '第三批代码',
    en: '3rd Batch Code',
    pu: '3° lote código'
  },
  thirdBatchName: {
    zh: '第三批名称',
    en: '3rd Batch Name',
    pu: '3° lote nome'
  },
  thirdBatchVolume: {
    zh: '第三批容量',
    en: '3rd Batch Volume',
    pu: '3° lote volume'
  },
  fourthBatchCode: {
    zh: '第四批代码',
    en: '4th Batch Code',
    pu: '4° lote código'
  },
  fourthBatchName: {
    zh: '第四批名称',
    en: '4th Batch Name',
    pu: '4° lote nome'
  },
  fourthBatchVolume: {
    zh: '第四批容量',
    en: '4th Batch Volume',
    pu: '4° lote volume'
  },
  edit: {
    zh: '编辑',
    en: 'Edit',
    pu: 'Editar'
  },
  delete: {
    zh: '删除',
    en: 'Delete',
    pu: 'Excluir'
  },
  creatingEditionTitle: {
    zh: '创建版本中',
    en: 'Creating edition',
    pu: 'Cadastrando edição'
  },
  creatingEditionParagraph: {
    zh: '填写下面的字段以在我们的系统中创建一个版本',
    en: 'Fill in the fields below to create an edition in our system',
    pu: 'Preencha os campos logo abaixo para realizar o cadastro de uma edição em nosso sistema'
  },
  editingEditionTitle: {
    zh: '编辑版本',
    en: 'Editing edition',
    pu: 'Editando edição'
  },
  editingEditionParagraph: {
    zh: '填写下面的字段以编辑版本',
    en: 'Fill in the fields below to edit an edition',
    pu: 'Preencha os campos logo abaixo para realizar as modificações de uma edição em nosso sistema'
  },
  deleteTitle: {
    zh: '您确定吗？',
    en: 'Are you sure?',
    pu: 'Você tem certeza absoluta?'
  },
  deleteParagraph: {
    zh: '此操作不可撤销。这将删除此版本以及与这些数据相关的所有操作。',
    en: 'This action cannot be undone. It will delete this edition and all operations related to these data.',
    pu: 'Essa ação não pode ser desfeita. Isso ira apagar essa edição e todas as operações relacionados a esses dados'
  },
  cancel: {
    zh: '取消',
    en: 'Cancel',
    pu: 'Cancelar'
  },
  yesDelete: {
    zh: '是的，我想要删除',
    en: 'Yes, I want to delete',
    pu: 'Sim, quero excluir'
  },

  // formGroup
  clientName: {
    zh: '客户名称',
    en: 'Client Name',
    pu: 'Nome do cliente'
  },
  groupCodeLabel: {
    zh: '组代码',
    en: 'Group Code',
    pu: 'Código do grupo'
  },
  groupCodePlaceholder: {
    zh: '输入组代码',
    en: 'Enter group code',
    pu: 'Digite o código do grupo'
  },
  next: {
    zh: '下一步',
    en: 'Next',
    pu: 'Próximo'
  },

  // formEdition
  editionCode: {
    zh: '版本代码',
    en: 'Edition Code',
    pu: 'Cód. Edição'
  },
  volume: {
    zh: '容量',
    en: 'Volume',
    pu: 'Volume'
  },
  description: {
    zh: '描述',
    en: 'Description',
    pu: 'Descrição'
  },
  previous: {
    zh: '上一页',
    en: 'Previous',
    pu: 'Anterior'
  },
  submit: {
    zh: '提交',
    en: 'Submit',
    pu: 'Enviar'
  },
  edition: {
    zh: '版本',
    en: 'Edition',
    pu: 'Edição'
  }

};
