<template>
  <div class="app-container">
    <list-cmp
      :tab-name="tabName"
      card-name="bagLabel"
    />
  </div>
</template>

<script>
export default {

  components: {
    'list-cmp': () => import('./List.vue')
  },
  props: {
    tabName: {
      type: String,
      default: ''
    }
  }
};
</script>

<style>

</style>
