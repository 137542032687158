<template>
  <StatisticiansTipsCmp :arr="arr" />
</template>

<script>
import StatisticiansTipsCmp from '@/components/StatisticiansTipsCmp/index.vue';

export default {
  name: '',

  components: {
    StatisticiansTipsCmp
  },

  props: {
  // 总票数
    len: {
      type: Number,
      default: 0
    },

    // 总申报价值
    totalValue: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      arr: [
        {
          name: this.$t('ClaimsManagement.TotalNumberOfVotes'), // 总票数
          value: '',
          suffix: ';'
        },
        {
          name: this.$t('ClaimsManagement.TotalDeclaredValue'), // 总申报价值
          value: '',
          suffix: ''
        }
      ]
    };
  },

  watch: {
    'len': {
      immediate: true,
      handler(val) {
        this.arr[0].value = val;
      }
    },
    'totalValue': {
      immediate: true,
      handler(val) {
        this.arr[1].value = val;
      }
    }
  }

};
</script>

<style scoped>
</style>
