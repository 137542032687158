<script >
// import { default as TableData } from './components/tableData';
import { ref, computed, watch } from '@vue/composition-api';
import { default as TableDataNotRegistered } from './components/tableDataNotRegistered';
import { default as TableDataRegistered } from './components/tableDataRegistered';
import { default as WrapperHandleError } from '@/components/wrapperHandleError';
import { default as SectionHeader } from '@/components/sectionHeader';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const filters = ref({
    status: 1,
    name: null,
    code: null
  });
  const initialValueErrorSeller = {
    detailError: null,
    isError: false
  };
  const errorSellersRegistered = ref(initialValueErrorSeller);
  const errorSellersNotRegistered = ref(initialValueErrorSeller);
  const error = computed(() => {
    return filters.value.status ? errorSellersRegistered.value.detailError : errorSellersNotRegistered.value.detailError;
  });
  const isError = computed(() => {
    return filters.value.status ? errorSellersRegistered.value.isError : errorSellersNotRegistered.value.isError;
  });
  return {
    filters,
    errorSellersRegistered,
    errorSellersNotRegistered,
    error,
    isError
  };
};
__sfc_main.components = Object.assign({
  WrapperHandleError,
  SectionHeader,
  TableDataRegistered,
  TableDataNotRegistered
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div class="space-p-20 o-container">
    <WrapperHandleError :error="error" :is-error="isError">
      <SectionHeader>
        <template #header>
          <h1 class="header-1">{{ $t('seller.seller') }}</h1>
        </template>

        <template #filter>
          <o-field :label="$t('seller.sellerName')" label-for="name" class="flex-1">
            <o-input id="name" v-model="filters.name" :placeholder="$t('seller.sellerNamePlaceholder')" />
          </o-field>

          <o-field :label="$t('seller.sellerCode')" label-for="code" class="flex-1">
            <o-input id="code" v-model="filters.code" :placeholder="$t('seller.sellerCodePlaceholder')" />
          </o-field>

          <o-field :label="$t('seller.driverStatus')" label-for="status" class="flex-1">
            <o-select id="status" v-model="filters.status" :placeholder="$t('seller.driverStatusPlaceholder')">
              <option :value="1">{{ $t('seller.registeredSellers') }}</option>
              <option :value="0">{{ $t('seller.unregisteredSellers') }}</option>
            </o-select>
          </o-field>
        </template>
      </SectionHeader>

      <TableDataRegistered
        v-if="!!filters.status"
        :filters="filters"
        :error-sellers-registered="errorSellersRegistered"
      />

      <TableDataNotRegistered
        v-if="!filters.status"
        :filters="filters"
        :error-sellers-not-registered="errorSellersNotRegistered"
      />
    </WrapperHandleError>
  </div>
</template>

<style scoped>
.flex-1 {
  flex: 1;
}
</style>
