var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      attrs: {
        filterable: "",
        clearable: "",
        remote: "",
        "reserve-keyword": "",
        loading: _vm.loading,
        "remote-method": _vm.getCustomerPage,
        placeholder: _vm.$t("spareNumberPoo.Only10AreDisplayed"),
      },
      on: { change: _vm.onChange },
      model: {
        value: _vm.customerId,
        callback: function ($$v) {
          _vm.customerId = $$v
        },
        expression: "customerId",
      },
    },
    _vm._l(_vm.customerList, function (item) {
      return _c("el-option", {
        key: item.id,
        attrs: {
          label: item.customerAlias + "(" + item.username + ")",
          value: item.id,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }