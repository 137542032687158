export const seller = {
  // index
  seller: {
    zh: '销售商',
    en: '',
    pu: 'Sellers'
  },
  sellerName: {
    zh: '销售商姓名',
    en: '',
    pu: 'Nome do Seller'
  },
  sellerNamePlaceholder: {
    zh: '搜索销售商姓名',
    en: '',
    pu: 'Pesquise pelo nome do seller'
  },
  sellerCode: {
    zh: '销售商代码',
    en: '',
    pu: 'Código do seller'
  },
  sellerCodePlaceholder: {
    zh: '搜索销售商代码',
    en: '',
    pu: 'Pesquise pelo código do seller'
  },
  driverStatus: {
    zh: '司机状态',
    en: '',
    pu: 'Status motoristas'
  },
  driverStatusPlaceholder: {
    zh: '选择销售商状态',
    en: '',
    pu: 'Selecione o status do seller'
  },
  registeredSellers: {
    zh: '已注册销售商',
    en: '',
    pu: 'Sellers cadastrados'
  },
  unregisteredSellers: {
    zh: '未注册销售商',
    en: '',
    pu: 'Sellers não cadastrados'
  },

  // tableDataRegistered
  client: {
    zh: '客户',
    en: '',
    pu: 'Cliente'
  },
  cep: {
    zh: '邮政编码',
    en: '',
    pu: 'CEP'
  },
  address: {
    zh: '地址',
    en: '',
    pu: 'Endereço'
  },

  // actionsTableDataRegistered
  edit: {
    zh: '编辑',
    en: '',
    pu: 'Editar'
  },
  delete: {
    zh: '删除',
    en: '',
    pu: 'Excluir'
  },
  editTitle: {
    zh: '编辑销售商',
    en: '',
    pu: 'Editando Seller'
  },
  editParagraph: {
    zh: '填写下面的字段以编辑我们系统中的销售商',
    en: '',
    pu: 'Preencha os campos logo abaixo para realizar a edição de um seller em nosso sistema'
  },
  deleteTitle: {
    zh: '您确定吗？',
    en: '',
    pu: 'Você tem certeza absoluta?'
  },
  deleteParagraph: {
    zh: '此操作无法撤销。这将禁用销售商及其所有相关操作。',
    en: '',
    pu: 'Essa ação não pode ser desfeita. Isso ira desativar o seller e todas as operações relacionados a esses dados'
  },
  buttonCancel: {
    zh: '取消',
    en: '',
    pu: 'Cancelar'
  },
  buttonDelete: {
    zh: '是的，我想要删除',
    en: '',
    pu: 'Sim, quero excluir'
  },

  // actionsTableDataNotRegistered
  create: {
    zh: '创建',
    en: '',
    pu: 'Criar'
  },
  createTitle: {
    zh: '创建销售商',
    en: '',
    pu: 'Criando Seller'
  },
  createParagraph: {
    zh: '填写下面的字段以在我们系统中创建销售商',
    en: '',
    pu: 'Preencha os campos logo abaixo para realizar a criação de um seller em nosso sistema'
  },

  // formDataSeller
  phone: {
    zh: '电话',
    en: '',
    pu: 'Telefone'
  },
  phonePlaceholder: {
    zh: '输入销售商的电话',
    en: '',
    pu: 'Digite o telefone do seller'
  },
  email: {
    zh: '电子邮件',
    en: '',
    pu: 'Email'
  },
  emailPlaceholder: {
    zh: '输入销售商的邮箱',
    en: '',
    pu: 'Digite o email do seller'
  },
  document: {
    zh: '个人/法人税号',
    en: '',
    pu: 'Documento'
  },
  documentPlaceholder: {
    zh: '输入CNPJ或CPF号码',
    en: '',
    pu: 'Digite o número do CNPJ ou CPF'
  },
  nameContact: {
    zh: '联系人姓名',
    en: '',
    pu: 'Nome do Contato'
  },
  nameContactPlaceholder: {
    zh: '输入联系人姓名',
    en: '',
    pu: 'Digite o nome do contato'
  },
  state: {
    zh: '州',
    en: '',
    pu: 'Estado'
  },
  outsourced: {
    zh: '外包',
    en: '',
    pu: 'Terceirizado'
  },

  a: {
    zh: '',
    en: '',
    pu: ''
  }
};
