var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("div", { staticClass: "clearfix" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.$t("newOrder.ProblematicParcelOperationData"))),
            ]),
          ]),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            _vm._l(_vm.lossMetrics, function (item, index) {
              return _c(
                "el-col",
                {
                  key: index,
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { xl: 12, lg: 12 },
                },
                [
                  _c("div", { staticClass: "loss-item" }, [
                    _c("div", [
                      _c("div", { staticClass: "loss-title" }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                      _c("div", { staticClass: "loss-value" }, [
                        _vm._v(_vm._s(_vm._f("formatNumber")(item.value))),
                      ]),
                      _c("div", { staticClass: "loss-change" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("formatNumber")(item.change, true)) +
                            " " +
                            _vm._s(_vm.$t("newOrder.ComparedToYesterday")) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "loss-img" }, [
                      _c("img", {
                        attrs: {
                          src:
                            item.change < 0
                              ? _vm.getImageUrl("problemItemLessThan")
                              : item.change == 0
                              ? _vm.getImageUrl("equivalenceProblemPieces")
                              : _vm.getImageUrl("questionItemGreaterThan"),
                        },
                      }),
                    ]),
                  ]),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }