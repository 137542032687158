var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        { attrs: { inline: false, "label-position": "top", size: "small" } },
        [
          _c(
            "el-row",
            {
              staticStyle: { "flex-wrap": "wrap" },
              attrs: { gutter: 20, justify: "start", type: "flex" },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.operationTime") } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          clearable: false,
                          "default-time": ["00:00:00", "23:59:59"],
                          format:
                            _vm.$i18n.locale == "zh" ? _vm.zhDate : _vm.ptDate,
                          placeholder: _vm.$t("newOrder.SelectDatetime"),
                          "range-separator": "~",
                          type: "datetimerange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        model: {
                          value: _vm.timeValue,
                          callback: function ($$v) {
                            _vm.timeValue = $$v
                          },
                          expression: "timeValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.operator") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            "remote-method": _vm.getUserList,
                            clearable: "",
                            filterable: "",
                            remote: "",
                            "reserve-keyword": "",
                          },
                          model: {
                            value: _vm.queryForm.params.operateUserName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "operateUserName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "queryForm.params.operateUserName",
                          },
                        },
                        _vm._l(_vm.userList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.operateUserName,
                              value: item.keyId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.OperationSite") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.queryForm.params.operateSiteId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "operateSiteId",
                                $$v
                              )
                            },
                            expression: "queryForm.params.operateSiteId",
                          },
                        },
                        _vm._l(_vm.opearateSitelist, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("newOrder.waybillNumber") } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: _vm.$t("newOrder.PreciseSearch1"),
                          rows: 1,
                          type: "textarea",
                        },
                        model: {
                          value: _vm.queryForm.params.inputValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm.params, "inputValue", $$v)
                          },
                          expression: "queryForm.params.inputValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("orderImport.client") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t(
                              "collectionCenter.pleaseChoose"
                            ),
                            clearable: "",
                            filterable: "",
                            multiple: "",
                          },
                          model: {
                            value: _vm.queryForm.params.customerIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm.params, "customerIds", $$v)
                            },
                            expression: "queryForm.params.customerIds",
                          },
                        },
                        _vm._l(_vm.customerList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.username, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("orderCenterCont.typeOperation") },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t(
                              "collectionCenter.pleaseChoose"
                            ),
                            clearable: "",
                          },
                          model: {
                            value: _vm.queryForm.params.operationType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "operationType",
                                $$v
                              )
                            },
                            expression: "queryForm.params.operationType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("navbar.ReturnToSender"),
                              value: 1,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.ReturnToStorage"),
                              value: 2,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("navbar.reverseShipping"),
                              value: 3,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("navbar.reverseSigning"),
                              value: 4,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.TheStateOperatorOutlet"),
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            clearable: "",
                            filterable: "",
                            multiple: "",
                          },
                          model: {
                            value: _vm.queryForm.params.operateStateList,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "operateStateList",
                                $$v
                              )
                            },
                            expression: "queryForm.params.operateStateList",
                          },
                        },
                        _vm._l(_vm.allTraceStateList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.nameEn, value: item.twoCode },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-search",
                        size: "small",
                        type: "primary",
                      },
                      on: { click: _vm.enterSearch },
                    },
                    [_vm._v(_vm._s(_vm.$t("newOrder.search")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-refresh-left",
                        size: "small",
                        type: "info",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.resetForm()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("newOrder.reset")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { icon: "el-icon-upload2", size: "small" },
                      on: { click: _vm.allExportOrder },
                    },
                    [_vm._v(_vm._s(_vm.$t("newOrder.export")) + " ")]
                  ),
                  _c(
                    "el-radio-group",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value: "btn:SortingCenter:outGoRecordData",
                          expression: "'btn:SortingCenter:outGoRecordData'",
                          arg: "remove",
                        },
                      ],
                      staticStyle: { "margin-left": "10px" },
                      attrs: { size: "small" },
                      on: { change: _vm.handleTest },
                      model: {
                        value: _vm.isTest,
                        callback: function ($$v) {
                          _vm.isTest = $$v
                        },
                        expression: "isTest",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 0 } }, [
                        _vm._v(_vm._s(_vm.$t("newOrder.Formal"))),
                      ]),
                      _c("el-radio-button", { attrs: { label: 1 } }, [
                        _vm._v(_vm._s(_vm.$t("newOrder.test1"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            "header-cell-style": { background: "#f2f2f2", color: "black" },
            border: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("GlobalSetObj.serial"),
              align: "center",
              type: "index",
              width: "80",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("newOrder.waybillNumber"),
              align: "center",
              "min-width": "160",
              prop: "waybillNumber",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "top",
                              "popper-class": "copy",
                              trigger: "hover",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "black",
                                  "font-size": "12px",
                                  padding: "2px",
                                },
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.copy(scope.row.waybillNumber)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("orderDetails.copy")) + " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "WaybillNumber",
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.waybillNumber || "--") +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("newOrder.waybillStatus"),
              align: "center",
              "min-width": "160",
              prop: "statusNamewaybill",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.operationType"),
              align: "center",
              "min-width": "150",
              prop: "operationTypeName",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("orderImport.client"),
              align: "center",
              prop: "customerName",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("GlobalSetObj.operationTime"),
              align: "center",
              "min-width": "170",
              prop: "operationTime",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("GlobalSetObj.nextStation"),
              align: "center",
              "min-width": "170",
              prop: "nextSiteName",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("GlobalSetObj.operator"),
              align: "center",
              "min-width": "150",
              prop: "operateUserName",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("GlobalSetObj.OperationSite"),
              align: "center",
              "min-width": "120",
              prop: "operateSiteName",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("GlobalSetObj.TheStateOperatorOutlet"),
              align: "center",
              "min-width": "120",
              prop: "operateState",
            },
          }),
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { margin: "10px 0", float: "right" },
        attrs: {
          "current-page": _vm.queryForm.number,
          "page-size": _vm.queryForm.pageSize,
          "page-sizes": [10, 20, 50, 100],
          total: _vm.queryForm.total,
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }