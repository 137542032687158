<template>
  <div class="addDa">
    <el-form
      ref="updateForm"
      :model="updateForm"
      :label-width="checkData.orderType!=1?'120px':'auto'"
      :label-position="($i18n.locale!='zh'&&checkData.orderType!=1)?'top':'right'"
      style="padding: 20px;height: calc(100vh - 130px);overflow-y: auto;"
    >
      <!--选择层级 只在为国家时显示-->
      <el-form-item
        v-if="level==0"
        prop="level"
        :label="$t('orderCenterCont.selectAHierarchy') + '：'"
        :rules="[
          { required: true, message: $t('orderCenterCont.CannotBeEmpty'), trigger: ['blur','change'] }
        ]"
      >
        <div v-if="checkData.orderType===1">
          <!-- 省份 -->
          <span v-if="updateForm.level === 0">{{ $t('orderCenterCont.province') }}</span>
          <!-- 城市 -->
          <span v-if="updateForm.level === 1">{{ $t('orderCenterCont.city') }}</span>
          <!-- 地区 -->
          <span v-if="updateForm.level === 2">{{ $t('orderCenterCont.region') }}</span>
        </div>
        <el-select
          v-else
          v-model="updateForm.level"
          filterable
          :placeholder="$t('operationCenter.PleaseSelect')"
          clearable
        >
          <el-option
            v-for="item in Hierarchy"
            :key="item.level"
            :label="item.name"
            :value="item.level"
          />
        </el-select>
      </el-form-item>
      <div>
        <div v-if="checkData.orderType===1">
          <!--所属上级-->
          <el-form-item
            v-if="updateForm.level === 0"
            prop="country"
            :label="$t('orderCenterCont.SubordinateSuperior') + '：'"
            :rules="[
              { required: true, message: $t('orderCenterCont.CannotBeEmpty'), trigger: ['blur','change'] }
            ]"
          >
            <span>{{ region.nameStr }}</span>
          </el-form-item>
        </div>
        <div v-else>
          <!--所属上级-->
          <el-form-item
            v-if="updateForm.level === 1 || updateForm.level === 2"
            style="display: inline-block"
            prop="country"
            :label="$t('orderCenterCont.SubordinateSuperior') + '：'"
            :rules="[
              { required: true, message: $t('orderCenterCont.CannotBeEmpty'), trigger: ['blur','change'] }
            ]"
          >
            <el-select
              v-model="updateForm.country"
              filterable
              :placeholder="$t('operationCenter.PleaseSelect')"
              clearable
              @change="getSelectProvince1(1, updateForm.country)"
            >
              <el-option
                v-for="item in region.countryList"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="updateForm.level === 2"
            style="display: inline-block"
            prop="province"
            label-width="0"
            :rules="[
              { required: true, message: $t('orderCenterCont.CannotBeEmpty'), trigger: ['blur','change'] }
            ]"
          >
            <el-select
              v-model="updateForm.province"
              filterable
              :placeholder="$t('operationCenter.PleaseSelect')"
              clearable
              @change="getSelectProvince1(2, updateForm.province)"
            >
              <el-option
                v-for="item in region.provinceList"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </div>
      </div>
      <el-form-item
        v-if="superior.province&&level!==0"
        :label="$t('orderCenterCont.SubordinateState')"
        required
      >
        {{ superior.province }}
      </el-form-item>
      <el-form-item
        v-if="level==2"
        :label="$t('orderCenterCont.BelongsToTheCity')"
        required
      >
        {{ superior.city }}
      </el-form-item>
      <!-- <div v-if="updateForm.level === 0">
        所属大区
        <el-form-item
          prop="regionalId"
          :label="$t('orderCenterCont.BelongsToTheRegion') + '：'"
          :rules="[
            { required: false, message: $t('orderCenterCont.CannotBeEmpty'), trigger: ['blur','change'] }
          ]"
        >
          <div v-if="checkData.orderType===1">
            <span>{{ $t('orderCenterCont.region') }}</span>
          </div>
          <el-select
            v-else
            v-model="updateForm.regionalId"
            filterable
            :placeholder="$t('operationCenter.PleaseSelect')"
            clearable
            @change="changeRegional(updateForm.regionalId)"
          >
            <el-option
              v-for="item in regionList"
              :key="item.id"
              :label="item.nameCn + (item.nameEn ? '(' + item.nameEn + ')' : '')"
              :value="item.id"
              class="daSize"
            />
          </el-select>
        </el-form-item>
      </div> -->
      <!--邮编-->
      <div v-if="level === 1">
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('orderCenterCont.startZipCode')" prop="startZipCode" :rules="isNumberRules">
              <el-input v-model.trim="updateForm.startZipCode" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('orderCenterCont.endZipCode')" prop="endZipCode" :rules="isNumberRules">
              <el-input v-model.trim="updateForm.endZipCode" />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div v-if="updateForm.level === 0 || updateForm.level === 1">
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('orderCenterCont.startZipCode')" prop="startZipCode" :rules="isNumberRules">
              <el-input v-model.trim="updateForm.startZipCode" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('orderCenterCont.endZipCode')" prop="endZipCode" :rules="isNumberRules">
              <el-input v-model.trim="updateForm.endZipCode" />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <!--中文名-->
      <el-form-item
        :label="$t('orderCenterCont.ChineseName') + '：'"
        prop="nameCn"
        :rules="isChinese"
      >
        <!-- :rules="[
          { required: true, message: $t('orderCenterCont.CannotBeEmpty'), trigger: ['blur','change'] }
        ]" -->
        <span v-if="checkData.orderType===1">{{ updateForm.nameCn }}</span>
        <el-input
          v-else
          v-model.trim="updateForm.nameCn"
          autocomplete="off"
        />
      </el-form-item>
      <!--英文名-->
      <el-form-item
        prop="nameEn"
        :label="$t('orderCenterCont.EnglishName') + '：'"
        :rules="isEnglish"
      >
        <!-- :rules="[
          { required: true, validator: englishLetter, trigger: ['blur','change'] }
        ]" -->
        <span v-if="checkData.orderType===1">{{ updateForm.nameEn }}</span>
        <el-input
          v-else
          v-model="updateForm.nameEn"
          autocomplete="off"
        />
      </el-form-item>
      <!--扩展字段-->
      <el-form-item
        v-if="updateForm.level === 1 || updateForm.level === 2"
        :label="$t('orderCenterCont.extra') + '：'"
        prop="extra"
        :rules="[
          { required: false, message: $t('orderCenterCont.CannotBeEmpty'), trigger: ['blur','change'] }
        ]"
      >
        <span v-if="checkData.orderType===1">{{ updateForm.extra }}</span>
        <div v-else>
          <el-input
            v-model.trim="updateForm.extra"
            type="textarea"
            :rows="5"
            autocomplete="off"
            :placeholder="$t('orderCenterCont.MultipleFields')"
          />
        </div>
      </el-form-item>
      <!--二字代码-->
      <el-form-item
        prop="twoCode"
        :label="$t('orderCenterCont.TwoWordCode') + '：'"
        :rules="[
          { required: false, validator: englishLetter, length: 2, trigger: ['blur','change'] }
        ]"
      >
        <span v-if="checkData.orderType===1">{{ updateForm.twoCode }}</span>
        <el-input
          v-else
          v-model.trim="updateForm.twoCode"
          autocomplete="off"
          maxlength="2"
          show-word-limit
        />
      </el-form-item>
      <!-- 邮编分区 -->
      <div v-if="updateForm.level === 1 || level === 1">
        <el-row>
          <el-col :span="24">
            <el-form-item :label="$t('orderCenterCont.partition')"><!-- 分区： -->
              <!-- <el-select
                v-model="updateForm.zipcodeAreaCode"
                style="display: block;"
                filterable
                clearable
                remote
                reserve-keyword
                :placeholder="$t('collectionCenter.pleaseInput')"
                :remote-method="remoteMethod"
                @change="changeValue"
              > -->
              <el-select
                v-model="updateForm.zipcodeAreaCode"
                style="display: block;"
                filterable
                clearable
                :placeholder="$t('collectionCenter.pleaseInput')"
              >
                <el-option
                  v-for="(item, index) in ZipCodeAreaList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <div
      v-if="checkData.orderType!==1"
      slot="footer"
      class="dialog-footer"
      style="padding: 10px 20px;text-align: right;border-top: 1px solid #ccc;"
    >
      <el-button @click="countryContainer(1)">{{ $t('operationCenter.Cancel') }}</el-button>
      <el-button
        type="primary"
        @click="countryContainer(2)"
      >{{ $t('operationCenter.determine') }}</el-button>
    </div>
  </div>
</template>

<script>
import { addLgAreaList, editLgAreaList, getLgAreaListData } from '@/api/orderApi';
import { regionalList } from '@/api/regionalManagement/regional';
import { englishLetter, isNumber, isChinese, isEn } from '@/utils/validate';
import { isEmpty } from '@/utils/strUtil';
import { zipcodeAreaPage } from '@/api/automaticSorting';
export default {
  name: 'Details',
  props: {
    checkData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    updateForm: {
      type: Object,
      default: () => {
        return {};
      }
    },
    region: {
      type: Object,
      default: () => {
        return {};
      }
    },
    // 当前添加的层级
    level: {
      type: Number,
      default: 0
    },
    // 国家省份城市名称对象
    superior: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      ZipCodeAreaList: [],
      country: '',
      province: '',
      city: '',
      nameArr: [],
      extraOld: '',
      newPage: false,
      regionList: [],
      englishLetter: englishLetter,
      isNumberRules: [
        { required: true, message: this.$t('orderCenterCont.CannotBeEmpty'), trigger: ['blur', 'change'] },
        {
          validator: async(rule, value, callback) => {
            if (!isNumber(value)) {
              return callback(new Error(this.$t('orderCenterCont.inputNum'))); // '请输入数字'
            } else if (value.length !== 8) {
              return callback(new Error(this.$t('orderCenterCont.input8Sum'))); // '请输入8位数字'
            }
          }
        }
      ],
      isChinese: [
        { message: this.$t('orderCenterCont.CannotBeEmpty'), trigger: ['blur', 'change'] },
        {
          validator: async(rule, value, callback) => {
            if (isEmpty(value)) {
              return true;
            } else {
              if (!isChinese(value)) {
                return callback(new Error(this.$t('orderCenterCont.inputChinese'))); // '请输入中文'
              }
              if (isEn(value)) {
                return callback(new Error(this.$t('orderCenterCont.notEnglish'))); // '不能有英文字符'
              }
            }
          }
        }
      ],
      isEnglish: [
        { required: true, message: this.$t('orderCenterCont.CannotBeEmpty'), trigger: ['blur', 'change'] },
        {
          validator: async(rule, value, callback) => {
            if (!isEn(value)) {
              return callback(new Error(this.$t('orderCenterCont.inputEnglish'))); // '请输入英文'
            }
            if (isChinese(value)) {
              return callback(new Error(this.$t('orderCenterCont.notChinese'))); // '不能有中文字符'
            }
          }
        }
      ]
    };
  },
  computed: {
    Hierarchy() {
      return [
        { name: this.$i18n.t('orderCenterCont.state'), level: 0 }, // 州
        { name: this.$i18n.t('orderCenterCont.city'), level: 1 }, // 城市
        { name: this.$i18n.t('orderCenterCont.region'), level: 2 } // 地区
      ];
    }
  },
  watch: {
    'updateForm.zipcodeAreaCode'() {
      if (!this.updateForm.zipcodeAreaCode) {
        this.ZipCodeAreaList = [];
      }
    },
    'updateForm.level'() {
      console.log('updateForm.level', this.updateForm.level);
      this.parentId = [];
      this.$set(this.updateForm, 'parentId', '');
      this.region.provinceList = [];
      this.region.cityList = [];
      this.$set(this.updateForm, 'country', '');
      this.$set(this.updateForm, 'province', '');
      this.$set(this.updateForm, 'city', '');
      this.region.nameStr = this.$i18n.t('operationCenter.PleaseSelect');
      if (this.updateForm.level !== 0) {
        this.getSelectProvince1(0, '');
      }
    },
    'queryForm.params.parentId'() {
      this.queryForm.pageNumber = 1;
      this.getMsgSetTableData();
    },
    'updateForm.twoCode'() {
      if (this.updateForm.twoCode) {
        this.updateForm.twoCode = this.updateForm.twoCode.toUpperCase();
      }
    },
    'updateForm.country'() {
      if (this.newPage) {
        this.region.provinceList = [];
        this.region.cityList = [];
        this.$set(this.updateForm, 'province', '');
        this.$set(this.updateForm, 'city', '');
        this.$set(this.updateForm, 'regionalId', '');
        this.$set(this.updateForm, 'regionalNameCn', '');
      }
      this.getRegionalList();
    },
    'updateForm.province'() {
      if (this.newPage) {
        this.region.cityList = [];
        this.$set(this.updateForm, 'city', '');
      }
    }
  },
  created() {
    if (this.checkData.orderType === 4) {
      this.getSelectProvince1(0, '');
    }
    setTimeout(() => {
      this.newPage = true;
    }, 2000);
    this.getRegionalList();

    const zipcodeAreaCodeForm = {
      pageNumber: 1,
      pageSize: 20,
      params: {
        zipCodeName: '',
        cityName: '',
        twoCode: '',
        status: 1
      }
    };
    zipcodeAreaPage(zipcodeAreaCodeForm).then((res) => {
      if (res.status === 'OK') {
        const ZipCodeAreaList = [];
        res.data.forEach(list => {
          ZipCodeAreaList.push({
            id: list.id,
            name: list.name
          });
        });
        this.ZipCodeAreaList = ZipCodeAreaList;
      }
    }).catch({});
  },
  methods: {
    // remoteMethod(value) {
    //   if (value !== '') {
    //     const zipcodeAreaCodeForm = {
    //       pageNumber: 1,
    //       pageSize: 20,
    //       params: {
    //         zipCodeName: value,
    //         cityName: '',
    //         twoCode: '',
    //         status: 1
    //       }
    //     };
    //     zipcodeAreaPage(zipcodeAreaCodeForm).then((res) => {
    //       if (res.status === 'OK') {
    //         const ZipCodeAreaList = [];
    //         res.data.records.forEach(list => {
    //           ZipCodeAreaList.push({
    //             id: list.id,
    //             name: list.name
    //           });
    //         });
    //         this.ZipCodeAreaList = ZipCodeAreaList;
    //         if (this.ZipCodeAreaList.length <= 0) {
    //           this.updateForm.zipcodeAreaCode = '';
    //         }
    //       }
    //     }).catch({});
    //   }
    // },
    // changeValue(value) { // 邮编分区 赋值
    //   if (value) {
    //     this.updateForm.zipcodeAreaCode = value;
    //   } else {
    //     this.updateForm.zipcodeAreaCode = '';
    //   }
    // },
    // 大区选项变化
    changeRegional(id) {
      for (let i = 0; i < this.regionList.length; i++) {
        if (this.regionList[i].id === id) {
          this.updateForm.regionalNameCn = this.regionList[i].nameCn;
        }
      }
    },
    // 获取大区选择 regionalList
    getRegionalList() {
      const param = {
        areaId: this.updateForm.country
      };
      regionalList(param)
        .then((res) => {
          if (res.status === 'OK') {
            this.regionList = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 地区选项
    getSelectProvince1(level, parentId) {
      if (level !== 0 && !parentId) {
        return;
      } else if (this.updateForm.level === 0 && level === 0) {
        return;
      } else if (this.updateForm.level === 1 && level === 1) {
        return;
      } else if (this.updateForm.level === 2 && level === 2) {
        return;
      }
      const params = {
        level: level,
        parentId: parentId
      };
      getLgAreaListData(params, false).then((res) => {
        // 换成你的接口方法
        const { status, data } = res;
        if (status !== 'OK') {
          return;
        }
        const arr = data.map((item) => {
          const { id, nameEn, nameCn, twoCode } = item;
          return {
            id,
            label: `${nameEn}(${nameCn})${twoCode ? '(' + twoCode + ')' : ''}`
          };
        });
        if (level === 0) {
          this.region.countryList = arr;
        } else if (level === 1) {
          this.region.provinceList = arr;
        } else if (level === 2) {
          this.region.cityList = arr;
        }
      });
    },
    // 重置表单
    resetFormData() {
      for (const k in this.updateForm) {
        this.updateForm[k] = '';
      }
    },
    // 保存、取消
    countryContainer(type) {
      if (type === 1) {
        this.resetFormData();
        this.$emit('changeData', false);
      } else {
        // 为国家时选中的层级为父ID
        let parentId = '';
        if (this.level === 0) {
          if (this.updateForm.country) {
            parentId = this.updateForm.country;
          }
          if (this.updateForm.province) {
            parentId = this.updateForm.province;
          }
          if (this.updateForm.city) {
            parentId = this.updateForm.city;
          }
        } else {
          parentId = this.updateForm.parentId;
        }
        const param = {
          level: this.updateForm.level ? this.updateForm.level : this.level,
          nameCn: this.updateForm.nameCn,
          nameEn: this.updateForm.nameEn,
          id: this.updateForm.id,
          twoCode: this.updateForm.twoCode,
          extra: this.updateForm.extra,
          zipcodeAreaId: this.updateForm.zipcodeAreaCode,
          startZipCode: this.updateForm.startZipCode,
          endZipCode: this.updateForm.endZipCode,
          zipCodeName: this.updateForm.zipCodeName,
          regionalId: this.updateForm.regionalId,
          regionalNameCn: this.updateForm.regionalNameCn,
          parentId
        };
        if (param.extra) {
          param.extra = param.extra.replace(/，/gi, ',');
        }
        if (this.checkData.orderType === 4) {
          param.createUser = this.$store.state.user.id;
          this.$refs['updateForm'].validate((valid) => {
            if (valid) {
              addLgAreaList(param)
                .then((res) => {
                  if (res.status === 'OK') {
                    this.$message.success(this.$i18n.t('orderCenterCont.SaveSuccessfully'));
                    this.resetFormData();
                    this.$emit('changeData', true);
                    // this.drawerTag = false;
                    // this.getMsgSetTableData();
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          });
        } else if (this.checkData.orderType === 2) {
          delete param.parentNode;
          param.extraold = this.extraOld;
          this.$refs['updateForm'].validate((valid) => {
            if (valid) {
              editLgAreaList(param)
                .then((res) => {
                  if (res.status === 'OK') {
                    this.$message.success(this.$i18n.t('orderCenterCont.ModificationSuccessful'));
                    this.resetFormData();
                    this.$emit('changeData', true);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          });
        }
      }
    }
  }
};
</script>

<style scoped>
.daSize{
  width: 200px;
}
.addDa ::v-deep .el-select-dropdown{
  max-width: 202px !important;
}
</style>
