var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "ruleForm",
      staticClass: "demo-ruleForm",
      attrs: { model: _vm.ruleForm, rules: _vm.rules, "label-width": "100px" },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("GlobalSetObj.supplier") } },
        [_c("span", [_vm._v(_vm._s(_vm.providerName))])]
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("bank.email"), prop: "email" } },
        [
          _c("el-input", {
            attrs: { placeholder: _vm.$t("GlobalSetObj.pleaseInput") },
            model: {
              value: _vm.ruleForm.email,
              callback: function ($$v) {
                _vm.$set(
                  _vm.ruleForm,
                  "email",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "ruleForm.email",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("ruleForm")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("GlobalSetObj.determine")))]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function () {
                  return _vm.onClose(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }