<template>
  <div class="app-container">
    <div style="position:relative;">
      <!-- '收起查询':'展开查询' -->
      <el-button class="putAway" size="small" @click="queryBoxShow = !queryBoxShow">
        {{ queryBoxShow ? $t('roleManage.CollapseQuery') : $t('roleManage.ExpandQuery') }}
      </el-button>
      <div v-show="queryBoxShow" class="searchContainer">
        <el-form
          ref="queryFormRef" :model="queryForm" class="queryFormClass"
          label-width="120px" size="mini"
        >
          <el-row>
            <el-col :span="7">
              <!-- 角色名称： -->
              <el-form-item :label="$t('roleManage.roleName') + '：'" prop="roleName">
                <el-input v-model.trim="queryForm.params.roleName" :placeholder="$t('roleManage.pleaseInput')" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div>
          <!-- 查询 -->
          <el-button
            v-permit:remove="'btn:user-center:role:find'"
            icon="el-icon-search"
            size="mini"
            type="primary"
            @click="queryFormSearch(find=1)"
          >{{ $t('roleManage.search') }}
          </el-button>
          <!-- 重置 -->
          <el-button icon="el-icon-refresh" size="mini" @click="queryFormReset">{{ $t('roleManage.reset') }}</el-button>
        </div>
        <el-divider />
      </div>
    </div>
    <div style="display: flex; justify-content: space-between; margin-bottom: 20px;">
      <span>
        <!-- 新增 -->
        <el-button
          v-permit:remove="'btn:user-center:role:add'"
          icon="el-icon-plus"
          size="small"
          type="primary"
          @click="addRole"
        >{{ $t('roleManage.add') }}</el-button>
        <!-- <el-button size="small" type="danger" icon="el-icon-delete" @click="deleteRole">删除</el-button> -->
      </span>
    </div>
    <el-card>
      <div class="tableClass">
        <el-table
          :data="ttableData"
          :row-key="getRowKeyOfTarget"
          border
          @selection-change="selectionChange"
        >
          <el-table-column
            :reserve-selection="true" align="center" type="selection"
            width="45"
          />
          <!-- 序号 -->
          <el-table-column
            :label="$t('roleManage.ind')" align="center" type="index"
            width="55"
          >
            <template slot-scope="scope">
              <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <!-- 角色名称 -->
          <!-- <el-table-column :label="$t('roleManage.roleName')" prop="roleName" min-width="50px" align="left">
            <template slot-scope="scope">
              <el-link
                type="primary"
                :underline="false"
                @click="editRole(scope.row.id)"
              >{{ scope.row.roleName }}</el-link>
            </template>
          </el-table-column> -->
          <!-- 角色名称(中文) -->
          <el-table-column :label="$t('roleManage.roleNameCH1')" align="canter" prop="roleName" />
          <!-- 角色名称(葡语)： -->
          <el-table-column :label="$t('roleManage.roleNamePU1')" align="canter" prop="portugal" />
          <!-- 创建日期 -->
          <el-table-column :label="$t('roleManage.createTime')" align="center" prop="createTime" />
          <!-- 创建人 -->
          <el-table-column :label="$t('roleManage.creator')" align="center" prop="createUserName" />
          <!-- 创建人所属网点 -->
          <el-table-column :label="$t('roleManage.createSite')" align="center" prop="createUserSiteName" />
          <!-- 修改人 -->
          <el-table-column :label="$t('roleManage.ModifiedBy')" align="center" prop="updateUserName" />
          <!-- 修改人所属网点 -->
          <el-table-column :label="$t('roleManage.updateUserBelongs')" align="center" prop="updateUserSiteName" />
          <!-- 操作 -->
          <el-table-column :label="$t('roleManage.operation')" width="235">
            <template slot-scope="scope">
              <el-popover
                placement="bottom-start"
                trigger="click"
              >
                <div>
                  <!-- 编辑 -->
                  <el-button
                    v-permit:remove="'btn:user-center:role:edit'"
                    size="mini"
                    type="info"
                    @click="editRole(scope.row.id)"
                  >{{ $t('roleManage.edit') }}
                  </el-button>
                  <!-- 应用权限 -->
                  <el-button
                    v-permit:remove="'btn:user-center:role:permission'"
                    size="mini"
                    type="warning"
                    @click="openApplyRole(scope.row)"
                  >{{ $t('roleManage.usePermissions') }}
                  </el-button>
                  <!-- 确定删除吗？ -->
                  <el-popconfirm
                    v-permit:remove="'btn:user-center:role:delete'"
                    :title="$t('roleManage.AreYouSureDelete')"
                    @confirm="deleteRole(scope.row.id)"
                  >
                    <!-- 删除 -->
                    <el-button
                      slot="reference" class="ml" size="mini"
                      type="danger"
                    >{{
                      $t('roleManage.delete')
                    }}
                    </el-button>
                  </el-popconfirm>
                </div>
                <!--       更多操作       -->
                <el-button slot="reference" size="mini">{{ $t('operationCenter.MoreActions') }}</el-button>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          :current-page.sync="queryForm.pageNumber"
          :page-size.sync="queryForm.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          style="margin: 10px 0"
          @current-change="getData"
          @size-change="queryFormSearch(find=0)"
        />
      </div>
    </el-card>
    <!-- 应用权限 -->
    <el-dialog
      :title="$t('roleManage.usePermissionsTip')"
      :visible.sync="applyRoleForm.visible"
      custom-class="drawerClass"
      width="30%"
    >
      <el-form>
        <el-row justify="start" type="flex">
          <el-col>
            <!-- 用户权限类型 -->
            <el-form-item :label="$t('roleManage.userPermissionsType')">
              <el-checkbox-group v-model="applyRoleForm.types" size="mini">
                <!-- 普通 -->
                <el-checkbox-button border label="1">{{ $t('roleManage.ordinary') }}</el-checkbox-button>
                <!-- 个性 -->
                <el-checkbox-button border label="2">{{ $t('roleManage.personality') }}</el-checkbox-button>
                <!-- 未授权 -->
                <el-checkbox-button border label="3">{{ $t('roleManage.unauthorized') }}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- 应用 -->
        <el-button size="small" type="primary" @click="applyRole">{{ $t('roleManage.use') }}</el-button>
        <!-- 取消 -->
        <el-button size="small" type="warning" @click="applyRoleForm.visible=false">{{
          $t('roleManage.cancel')
        }}</el-button>
      </span>
    </el-dialog>
    <role-operation
      :role-operation-pop="roleOperationPop"
      @RoleTableDataRefresh="getData"
      @closeRoleOperationDialog="closeRoleOperationDialog"
    />
  </div>
</template>

<script>
// import RoleOperation from '@/views/user/roleManage/components/roleOperation';
import { applyRole, deleteRole, getRoleInfo, getRolePage } from '@/api/role';

export default {
  name: 'RoleManager',
  components: {
    RoleOperation: () => import('@/views/user/roleManage/components/roleOperation')
  },
  data() {
    return {
      queryBoxShow: true,
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          roleName: ''
        }

      },
      roleOperationPop: {
        type: '',
        visible: false,
        roles: [],
        roleId: '',
        roleInfo: ''
      },
      // 表格相关
      ttableData: [],
      total: 0,
      selectedLis: [],
      applyRoleForm: {
        id: '',
        visible: false,
        types: []
      }
    };
  },
  created() {
    this.getData();
  },
  methods: {
    // 表格多选框翻页记忆
    getRowKeyOfTarget(row) {
      return row.id;
    },
    queryFormSearch(find) {
      if (find === 1) {
        this.queryForm.pageNumber = 1;
      }
      this.getData();
    },
    // 多选操作
    selectionChange(val) {
      this.selectedLis = val;
    },
    // 重置表单
    queryFormReset() {
      // this.$refs.queryFormRef.resetFields();
      // this.queryFormSearch();
      this.queryForm.params.roleName = '';
    },
    getData() {
      getRolePage(this.queryForm).then((response) => {
        if (response.status === 'OK') {
          const { records, total } = response.data;
          this.total = total;
          this.ttableData = records;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    addRole() {
      this.roleOperationPop.type = 'add';
      this.roleOperationPop.title = this.$t('roleManage.addRole'); // '新增角色'
      this.roleOperationPop.visible = true;
    },
    editRole(roleId) {
      getRoleInfo(roleId).then((response) => {
        if (response.status === 'OK') {
          this.roleOperationPop.type = 'edit';
          this.roleOperationPop.roleInfo = { ...response.data };
          this.roleOperationPop.title = this.$t('roleManage.changeRole'); // '修改角色'
          this.roleOperationPop.visible = true;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    deleteRole(ids) {
      this.queryForm.pageNumber = 1;
      deleteRole(ids).then(data => {
        if (data.status === 'OK') {
          // '操作成功'
          this.$message.success(this.$t('roleManage.TheOperationSucceeded'));
          this.getData();
        }
      }).catch(err => {
        console.error(err);
      });
      // const selectedLis = this.selectedLis;
      // if (selectedLis.length !== 1) {
      //   this.$message.warning('请选择合适的数据进行操作,且不允许批量删除');
      //   return;
      // }
      // this.$confirm('是否确定删除角色!', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // })
      //   .then(() => {
      //     const roleId = this.selectedLis.map((item) => item.id).toString();
      //     deleteRole(roleId).then((response) => {
      //       if (response.code === 200) {
      //         this.$message.success('操作成功');
      //         this.getData();
      //       }
      //     });
      //   })
      //   .catch(() => {
      //     this.$message.info('操作取消');
      //   });
    },
    openApplyRole(row) {
      // console.log('应用权限查看', row);
      this.applyRoleForm.visible = true;
      this.applyRoleForm.id = row.id;
      this.applyRoleForm.types = [];
    },
    applyRole() {
      this.applyRoleForm.types = this.applyRoleForm.types.toString();
      applyRole(this.applyRoleForm).then((response) => {
        if (response.status === 'OK') {
          this.$message.success(this.$t('roleManage.TheOperationSucceeded')); // '操作成功'
          this.applyRoleForm.visible = false;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    closeRoleOperationDialog() {
      this.roleOperationPop.visible = false;
    }
  }
};
</script>

<style lang="scss" scoped>

.searchContainer ::v-deep .el-input__inner{
  height: 40px;
}
.tableClass ::v-deep .el-table th, ::v-deep .el-table td {
  text-align: center;
}

.ml {
  margin-left: 10px;
}

.putAway {
  position: absolute;
  right: 0;
  top: 0;
  color: #1989fa;
  cursor: pointer;
  z-index: 100;
}

/* >>> .drawerClass .el-dialog__header{
  border-bottom: 1px solid #ccc;
  margin-bottom: 0;
  padding: 15px 15px;
}
>>> .drawerClass .el-dialog__footer{
  border-top: 1px solid #ccc;
  padding: 15px 15px;
} */
</style>
