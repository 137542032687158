var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "app-container",
        staticStyle: { height: "calc(100vh - 130px)", "overflow-y": "auto" },
      },
      [
        _c(
          "el-form",
          {
            ref: "formDataDetail",
            staticClass: "queryFormClass",
            attrs: {
              model: _vm.formData,
              "label-position": "right",
              "label-width": "auto",
              size: "small",
            },
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 10 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("GlobalSetObj.SubordinateSupplier"),
                          prop: "providerId",
                          rules: {
                            required: true,
                            message: _vm.$t("basicData.CannotBeEmpty"),
                            trigger: ["blur", "change"],
                          },
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "60%" },
                            attrs: {
                              filterable: "",
                              clearable: "",
                              placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.providerInfoChange()
                              },
                            },
                            model: {
                              value: _vm.formData.providerId,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "providerId", $$v)
                              },
                              expression: "formData.providerId",
                            },
                          },
                          _vm._l(_vm.providerList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: {
                                label:
                                  item.providerBody +
                                  "（" +
                                  item.providerName +
                                  "）(" +
                                  item.code +
                                  ")",
                                value: item.id,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("GlobalSetObj.DeliveryPoint") + "：",
                          prop: "sendSiteId",
                          rules: {
                            required: true,
                            message: _vm.$t("basicData.CannotBeEmpty"),
                            trigger: ["blur", "change"],
                          },
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "60%" },
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                              filterable: "",
                              clearable: "",
                            },
                            model: {
                              value: _vm.formData.sendSiteId,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "sendSiteId", $$v)
                              },
                              expression: "formData.sendSiteId",
                            },
                          },
                          _vm._l(_vm.outletsList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: {
                                label: item.name + "（" + item.code + "）",
                                value: item.id,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.DeliveryProvince") + "：",
                          prop: "stateId",
                          rules: {
                            required: true,
                            message: _vm.$t("basicData.CannotBeEmpty"),
                            trigger: ["blur", "change"],
                          },
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "60%" },
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.changeState(_vm.formData.stateId)
                              },
                            },
                            model: {
                              value: _vm.formData.stateId,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "stateId", $$v)
                              },
                              expression: "formData.stateId",
                            },
                          },
                          _vm._l(_vm.allState, function (item, ind) {
                            return _c("el-option", {
                              key: ind,
                              attrs: {
                                label: item.twoCode + "(" + item.name + ")",
                                value: item.id,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("collectionCenter.city") + "：",
                          prop: "cityId",
                          rules: {
                            required: true,
                            message: _vm.$t("basicData.CannotBeEmpty"),
                            trigger: ["blur", "change"],
                          },
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "60%" },
                            attrs: {
                              filterable: "",
                              clearable: "",
                              placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            },
                            model: {
                              value: _vm.formData.cityId,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "cityId", $$v)
                              },
                              expression: "formData.cityId",
                            },
                          },
                          _vm._l(_vm.allCity, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.ZipCodeSection") + "：",
                          prop: "postalCode",
                          rules: {
                            required: true,
                            message: _vm.$t("basicData.CannotBeEmpty"),
                            trigger: ["blur", "change"],
                          },
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "60%" },
                          attrs: {
                            type: "textarea",
                            placeholder: _vm.$t("basicData.ZipCodeExample"),
                            rows: 4,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.zipcodeCollation(
                                _vm.formData.postalCode
                              )
                            },
                          },
                          model: {
                            value: _vm.formData.postalCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "postalCode", $$v)
                            },
                            expression: "formData.postalCode",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.DeliveryDistance") + "：",
                          prop: "sendDistance",
                          rules: {
                            required: true,
                            suffix: 2,
                            negative: "",
                            validator: _vm.priceType,
                            trigger: ["blur", "change"],
                          },
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "60%" },
                          model: {
                            value: _vm.formData.sendDistance,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "sendDistance", $$v)
                            },
                            expression: "formData.sendDistance",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.RiskAreas") + "：",
                          prop: "riskRegion",
                          rules: {
                            required: true,
                            message: _vm.$t("basicData.CannotBeEmpty"),
                            trigger: ["blur", "change"],
                          },
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "60%" },
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                              filterable: "",
                              clearable: "",
                            },
                            model: {
                              value: _vm.formData.riskRegion,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "riskRegion", $$v)
                              },
                              expression: "formData.riskRegion",
                            },
                          },
                          [
                            _c("el-option", {
                              key: 0,
                              attrs: { label: "Sem Risco", value: "Sem Risco" },
                            }),
                            _c("el-option", {
                              key: 1,
                              attrs: { label: "Risco", value: "Risco" },
                            }),
                            _c("el-option", {
                              key: 2,
                              attrs: {
                                label: "Zona Rural",
                                value: "Zona Rural",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.RiskRatio") + "：",
                          prop: "riskRatio",
                          rules: {
                            required: true,
                            suffix: 2,
                            negative: "",
                            validator: _vm.priceType,
                            trigger: ["blur", "change"],
                          },
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: {
                            display: "inline-block",
                            width: "60%",
                          },
                          model: {
                            value: _vm.formData.riskRatio,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "riskRatio", $$v)
                            },
                            expression: "formData.riskRatio",
                          },
                        }),
                        _vm._v(" % "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.status") + "：",
                          prop: "applyType",
                          rules: {
                            required: true,
                            message: _vm.$t("basicData.CannotBeEmpty"),
                            trigger: ["blur", "change"],
                          },
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.formData.applyType,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "applyType", $$v)
                              },
                              expression: "formData.applyType",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: 0 } }, [
                              _vm._v(_vm._s(_vm.$t("basicData.enable"))),
                            ]),
                            _c("el-radio-button", { attrs: { label: 1 } }, [
                              _vm._v(_vm._s(_vm.$t("basicData.disable"))),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticStyle: {
          "text-align": "right",
          padding: "10px 20px",
          "border-top": "1px solid #ccc",
        },
      },
      [
        _c(
          "el-button",
          {
            attrs: { size: "small" },
            on: {
              click: function ($event) {
                return _vm.closeSaveDetail(0)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("collectionCenter.close")))]
        ),
        _c(
          "el-button",
          {
            directives: [
              {
                name: "permit",
                rawName: "v-permit:remove",
                value: "btn:finance:dispatchReferenceTable:addEdit",
                expression: "'btn:finance:dispatchReferenceTable:addEdit'",
                arg: "remove",
              },
            ],
            attrs: { size: "small", type: "primary" },
            on: {
              click: function ($event) {
                return _vm.closeSaveDetail(1)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("collectionCenter.Save")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }