<template>
  <el-form  ref="ruleForm" :model="formData" label-width="0" class="demo-ruleForm" size="small" :rules="rules">
    <el-table :data="formData.arr"  border>
      <!-- 距离 -->
      <el-table-column prop="distanceModel" width="200">
        <template slot="header">
          <span>{{ $t('collectionCenter.distance') }}</span>
          <span style="color: red;">*</span>
        </template>

        <template slot-scope="scope">
          <el-form-item
            :prop="`arr.${scope.$index}.distanceModel`"
            :rules="rules.distanceModel"
          >
            <el-select v-model="scope.row.distanceModel" :placeholder="$t('customerManagements.pleaseChoose')">
              <el-option label="0-50KM" :value="1" />
              <el-option label=">50KM" :value="2" />
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>

      <!-- 基础价PT003 -->
      <el-table-column prop="standardPrice">
        <template slot="header">
          <div>
            <span>{{ $t('basicData.BasePrice') }}(PT003)</span>
            <span style="color: red;">*</span>
          </div>
        </template>

        <template slot-scope="scope">
          <el-form-item
            :prop="`arr.${scope.$index}.standardPrice`"
            :rules="rules.standardPrice"
          >
            <el-input-number
              v-model="scope.row.standardPrice" :precision="3" :min="0"
              :max="999999999" :controls="false" :placeholder="$t('GlobalSetObj.pleaseInput')"
            />&nbsp;&nbsp;{{ $t('orderCenterCont.days') }}
          </el-form-item>
        </template>
      </el-table-column>

      <!-- 揽收商家提成PT004 -->
      <el-table-column  prop="overshipmentPrice">
        <template slot="header">
          <div>
            <span>{{ $t('basicData.揽收商家提成') }}(PT004)</span>
            <span style="color: red;">*</span>
          </div>
        </template>

        <template slot-scope="scope">
          <el-form-item
            :prop="`arr.${scope.$index}.overshipmentPrice`"
            :rules="rules.overshipmentPrice"
          >
            <el-input-number
              v-model="scope.row.overshipmentPrice" :precision="3" :min="0"
              :max="999999999" :controls="false" :placeholder="$t('GlobalSetObj.pleaseInput')"
            />&nbsp;&nbsp;/{{ $t('basicData.个') }}
          </el-form-item>
        </template>
      </el-table-column>

      <!-- 揽收包裹价格PT013 -->
      <el-table-column prop="price">
        <template slot="header">
          <span>{{ $t('basicData.揽收包裹价格') }}(PT013)</span>
          <span style="color: red;">*</span>
        </template>

        <template slot-scope="scope">
          <el-form-item
            :prop="`arr.${scope.$index}.price`"
            :rules="rules.price"
          >
            <el-input-number
              v-model="scope.row.price" :precision="3" :min="0"
              :max="999999999" :controls="false" :placeholder="$t('GlobalSetObj.pleaseInput')"
            />&nbsp;&nbsp;/{{ $t('collectionCenter.ticket') }}
          </el-form-item>
        </template>
      </el-table-column>

      <!-- 最低存货 -->
      <el-table-column prop="standardCargo">
        <template slot="header">
          <div>
            <span>{{ $t('basicData.最低货量') }}</span>
          </div>
        </template>
        <template slot-scope="scope">
          <el-form-item>
            <el-input-number
              v-model="scope.row.standardCargo" :precision="0" :min="0"
              :max="999999999" :controls="false" :placeholder="$t('GlobalSetObj.pleaseInput')"
            />&nbsp;&nbsp;{{ $t('collectionCenter.ticket') }}
          </el-form-item>
        </template>
      </el-table-column>

      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="danger"
            @click="handleDel(scope.$index)"
          >{{ $t('basicData.Delete') }}</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-form>
</template>

<script>
import { cloneDeep } from 'lodash-es';

/** @typedef {import('./types').TTableRow} TTableRow */

export default {
  name: '',

  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  },

  data() {
    /**
     * 距离校验
     * 不能存在相同的距离选项
     */
    const distanceModelCheck = (rule, value, callback) => {
      /** @type {TTableRow[]} */
      const arr = this.formData.arr;

      if (!value) {
        return callback(new Error(this.$i18n.t('GlobalSetObj.pleaseInput')));
      }

      // 只添加了一项，则不需要校验
      if (arr.length === 1) {
        return callback();
      }

      // 如果存在多个选项，则需要校验是否重复
      {
        const list = arr.map(item => {
          return item.distanceModel;
        });
        // 去重
        const set = new Set(list);
        if (set.size !== list.length) {
          return callback(new Error(this.$i18n.t('basicData.重复')));
        }
        return callback();
      }
    };

    return {
      formData: {
        /** @type {TTableRow[]} */
        arr: []
      },

      rules: {
        distanceModel: [
          { required: true, validator: distanceModelCheck, trigger: 'change' }
        ],
        standardPrice: [
          { required: true, message: this.$i18n.t('GlobalSetObj.pleaseInput'), trigger: 'blur' }
        ],

        overshipmentPrice: [
          { required: true, message: this.$i18n.t('GlobalSetObj.pleaseInput'), trigger: 'blur' }
        ],
        price: [
          { required: true, message: this.$i18n.t('GlobalSetObj.pleaseInput'), trigger: 'blur' }
        ]
      }
    };
  },

  watch: {
    'tableData': {
      handler(val, oldVal) {
        // console.log('子组件监听', JSON.stringify(val), JSON.stringify(oldVal));
        if (oldVal === undefined) {
          // 第一次赋值
          this.formData.arr = [];
          return;
        }

        if (val.length === 0 && oldVal.length === 0) {
          return;
        }

        // 新的值与旧的值一样
        if (JSON.stringify(val) === JSON.stringify(this.formData.arr)) {
          // console.log('新的值与旧的值一样');
          return;
        }
        // console.log(123987, val);
        this.formData.arr = cloneDeep(this.tableData);
        // this.formData.arr.push(val);
      },
      immediate: true
    },

    // 将数据传给父组件
    formData: {

      handler(val, oldVal) {
        // console.log('子组件提交');
        if (!oldVal || !val) {
          return;
        }
        // console.log('父元素提交', val.arr);
        this.$emit('change', val.arr);
      },
      deep: true
      // immediate: true
    }
  },

  created() {

  },

  methods: {
    // 提交校验
    submitForm() {
      return this.$refs['ruleForm'].validate().then(() => {
        return true;
      }).catch(res => {
        return false;
      });
    },

    // 删除
    handleDel(index) {
      this.formData.arr.splice(index, 1);
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
