var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-container" },
    [
      _c(
        "el-button",
        {
          attrs: { type: "success", size: "mini" },
          on: {
            click: function ($event) {
              _vm.dialogVisible = true
            },
          },
        },
        [
          _vm._v(_vm._s(_vm.$t("WorkCentreTable.UploadPictures"))),
          _c("i", { staticClass: "el-icon-upload el-icon--right" }),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, "append-to-body": true },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              attrs: {
                accept: _vm.filtTypeArr.join(","),
                multiple: true,
                "file-list": _vm.fileList,
                "show-file-list": true,
                "on-remove": _vm.handleRemove,
                "before-upload": _vm.beforeUpload,
                "http-request": _vm.enclosureUpload,
                action: "",
                "list-type": "picture-card",
              },
            },
            [_c("i", { staticClass: "el-icon-plus" })]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("WorkCentreTable.cancel")))]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v(_vm._s(_vm.$t("WorkCentreTable.confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }