// 修改密码
const changepwd = {
  setNewPwd: {
    zh: '请设置新密码',
    en: '',
    pu: 'Favor definir nova senha'
  },
  oldPwd: {
    zh: '旧密码',
    en: '',
    pu: 'Senha antiga'
  },
  inputOldPwd: {
    zh: '请输入旧密码',
    en: '',
    pu: 'Favor digitar senha antiga'
  },
  newPwd: {
    zh: '新密码',
    en: '',
    pu: 'Senha nova'
  },
  inputNewPwd: {
    zh: '请输入新密码',
    en: '',
    pu: 'Favor senha nova'
  },
  inputHint: {
    zh: '必须由 8-12 位小写或大写字母、数字组成',
    en: '',
    pu: 'Deve consistir de 8 a 12 letras minúsculas ou maiúsculas e números'
  },
  checkPwd: {
    zh: '确认密码',
    en: '',
    pu: 'Confirmar senha'
  },
  inputNewCodeAgain: {
    zh: '请再次输入新密码',
    en: '',
    pu: 'Por favor digite novamente a nova senha'
  },
  requestedInputCode: {
    zh: '请按照要求输入密码',
    en: '',
    pu: 'Por favor digitar a senha conforme requerido'
  },
  newCodeNotOldCode: {
    zh: '新密码与旧密码不能相同',
    en: '',
    pu: 'A nova senha não pode ser a mesma que a antiga'
  },
  inputConfirmCode: {
    zh: '请输入确认密码',
    en: '',
    pu: 'Introduza a senha de confirmação, por favor'
  },
  loginAgain: {
    zh: '密码修改成功，请重新登录',
    en: '',
    pu: 'Senha alterada com sucesso, por favor faça o login novamente'
  },
  succeed: {
    zh: '成功',
    en: 'succeed',
    pu: 'suceder'
  },
  confirm: {
    zh: '确认',
    en: '',
    pu: 'Confirmar'
  },
  reset: {
    zh: '重置',
    en: 'reset',
    pu: 'Reconfigurar'
  },
  orderQuantity: {
    zh: '下单票数',
    en: '',
    pu: 'Qtd. de Pedidos Realizados'
  },
  customerSendQuantity: {
    zh: '客户出仓票数',
    en: '',
    pu: 'Qtd. de Pacotes Expedidos de Cliente'
  },
  collectQuantity: {
    zh: '揽收票数',
    en: 'reset',
    pu: 'Qtd. de Pacotes Coletados'
  },
  collectDifference: {
    zh: '揽收差异',
    en: '',
    pu: 'Diferença de Coleta'
  },
  collectMissScan: {
    zh: '疑似揽收漏扫',
    en: '',
    pu: 'Possível Faltou de Bipagem na Coleta'
  },
  collectLost: {
    zh: '疑似揽收丢失',
    en: '',
    pu: 'Possível Perda na Coleta'
  },
  collectLoadQuantity: {
    zh: '揽收装车票数',
    en: '',
    pu: 'Qtd. de Pacotes Carregados'
  },
  collectLoadDifference: {
    zh: '揽收装车差异',
    en: '',
    pu: 'Diferença no Carregamento de Coleta'
  },
  loadMissScan: {
    zh: '疑似装车漏扫',
    en: '',
    pu: 'Possível Faltou de Bipagem no Carregamento'
  },
  loadLost: {
    zh: '疑似揽收装车丢失',
    en: '',
    pu: 'Possível Perda no Carregamento de Coleta'
  },
  collectUserName: {
    zh: '揽收操作人',
    en: '',
    pu: 'Operador de Coleta'
  },
  collectLoadTime: {
    zh: '揽收装车时间',
    en: '',
    pu: 'Hora de Carregamento da Coleta'
  },
  collectLoadUserName: {
    zh: '揽收装车操作人',
    en: '',
    pu: 'Operador de Carregamento da Coleta'
  }
};
export {
  changepwd
};
