var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "preStyle" },
    [
      _c(
        "el-form",
        {
          ref: "formData",
          staticStyle: {
            padding: "20px",
            height: "calc(100vh - 68px)",
            "overflow-y": "auto",
          },
          attrs: {
            "label-width": "120px",
            "label-position": "left",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple-light" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          prop: "referenceNumber",
                          label: _vm.$t("receivablePayable.TicketNumber"),
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.row.logisticsNumber))])]
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple-light" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          prop: "type",
                          label: _vm.$t("orderCenterCont.TheOrderNumber"),
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.row.orderNumber))])]
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple-light" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          prop: "createTime",
                          label: _vm.$t("orderCenterCont.CreateTime"),
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.row.createTime))])]
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple-light" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          prop: "createUser",
                          label: _vm.$t("orderCenterCont.CustomerName"),
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.row.customerName))])]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-light" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "type",
                              label: _vm.$t(
                                "orderCenterCont.WhetherPushWasSuccessful"
                              ),
                            },
                          },
                          [
                            _vm.row.state === 1
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("operationCenter.succeed"))
                                  ),
                                ])
                              : _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("operationCenter.fail"))
                                  ),
                                ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-light" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "description",
                              label: _vm.$t("orderCenterCont.LogDescription"),
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.row.responseMessage)),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple-light" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("orderCenterCont.RequestMessages"),
                        },
                      },
                      [
                        _c("div", { staticClass: "messageCon" }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "text-align": "right",
                                "line-height": "25px",
                                padding: "5px",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-copy-document",
                                staticStyle: {
                                  "font-size": "20px",
                                  cursor: "pointer",
                                },
                                attrs: {
                                  title: _vm.$t("orderCenterCont.copy"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.copyData(_vm.row.requestMessage)
                                  },
                                },
                              }),
                            ]
                          ),
                          _c("pre", [
                            _vm._v(
                              _vm._s(_vm._f("setStyle")(_vm.row.requestMessage))
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }