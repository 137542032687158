var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper o-container" }, [
    _c("div", { staticClass: "wrapperHeaderPrimary" }, [_vm._t("header")], 2),
    _vm.$slots.filter
      ? _c("div", { staticClass: "warpperFilter" }, [_vm._t("filter")], 2)
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }