var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "TimeLimitForSigningBox" },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c(
            "el-form",
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "col2" },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selectTimeClass autoWidth",
                          attrs: {
                            placeholder: _vm.$t(
                              "collectionCenter.pleaseChoose"
                            ),
                          },
                          on: { change: _vm.changeNum },
                          model: {
                            value: _vm.selectNum,
                            callback: function ($$v) {
                              _vm.selectNum = $$v
                            },
                            expression: "selectNum",
                          },
                        },
                        [
                          _c("template", { slot: "prefix" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (
                                    _vm.selectNumList.find(function (item) {
                                      return item.value === _vm.selectNum
                                    }) || {}
                                  ).label
                                ) +
                                " "
                            ),
                          ]),
                          _vm._l(_vm.selectNumList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm.selectNum == "n1"
                    ? _c(
                        "el-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "el-form-item",
                            { staticClass: "NumClass" },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  clearable: "",
                                  placeholder: _vm.$t(
                                    "collectionCenter.BatchQuery"
                                  ),
                                },
                                nativeOn: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.BlockNewline($event)
                                  },
                                },
                                model: {
                                  value: _vm.findForm.orderNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.findForm, "orderNumber", $$v)
                                  },
                                  expression: "findForm.orderNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectNum == "n2"
                    ? _c(
                        "el-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "el-form-item",
                            { staticClass: "NumClass" },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  clearable: "",
                                  placeholder: _vm.$t(
                                    "collectionCenter.BatchQuery"
                                  ),
                                },
                                nativeOn: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.BlockNewline($event)
                                  },
                                },
                                model: {
                                  value: _vm.findForm.scanNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.findForm, "scanNumber", $$v)
                                  },
                                  expression: "findForm.scanNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:report:signAgeing:odd-find",
                          expression:
                            "'btn:collection-center:report:signAgeing:odd-find'",
                          arg: "remove",
                        },
                      ],
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-search",
                      },
                      on: { click: _vm.searchClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.search")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:report:signAgeing:odd-reset",
                          expression:
                            "'btn:collection-center:report:signAgeing:odd-reset'",
                          arg: "remove",
                        },
                      ],
                      attrs: { icon: "el-icon-refresh", size: "mini" },
                      on: { click: _vm.resetClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")))]
                  ),
                  _vm.findActiveOdd == "hzOddFind" && _vm.oddExportHZ
                    ? _c(
                        "el-button",
                        {
                          class:
                            _vm.exportListOdd.length <= 0 && _vm.isClick
                              ? "onlyClickOnce10"
                              : "",
                          attrs: { icon: "el-icon-upload2", size: "mini" },
                          on: { click: _vm.download },
                        },
                        [_vm._v(_vm._s(_vm.$t("collectionCenter.export")))]
                      )
                    : _vm._e(),
                  _vm.findActiveOdd == "mxOddFind" && _vm.oddExportMX
                    ? _c(
                        "el-button",
                        {
                          class:
                            _vm.exportMXListOdd.length <= 0 && _vm.isClick
                              ? "onlyClickOnce10"
                              : "",
                          attrs: { icon: "el-icon-upload2", size: "mini" },
                          on: { click: _vm.downloadLogistics },
                        },
                        [_vm._v(_vm._s(_vm.$t("collectionCenter.export")))]
                      )
                    : _vm._e(),
                  _vm.findActiveOdd == "hzOddFind" && _vm.oddAllExportHZ
                    ? _c(
                        "el-button",
                        {
                          class: _vm.isClickAll3 ? "onlyClickOnce10" : "",
                          attrs: { icon: "el-icon-upload", size: "mini" },
                          on: { click: _vm.downloadAll },
                        },
                        [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")))]
                      )
                    : _vm._e(),
                  _vm.findActiveOdd == "mxOddFind" && _vm.oddAllExportMX
                    ? _c(
                        "el-button",
                        {
                          class: _vm.isClickAll4 ? "onlyClickOnce10" : "",
                          attrs: { icon: "el-icon-upload", size: "mini" },
                          on: { click: _vm.downloadAllMX },
                        },
                        [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          model: {
            value: _vm.findActiveOdd,
            callback: function ($$v) {
              _vm.findActiveOdd = $$v
            },
            expression: "findActiveOdd",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("collectionCenter.summary"),
                name: "hzOddFind",
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      ref: "hzTable",
                      attrs: {
                        "max-height": 600,
                        border: "",
                        "show-summary": "",
                        "summary-method": _vm.getSummaries,
                        data: _vm.tableDate,
                      },
                      on: {
                        select: _vm.batchSelect,
                        "select-all": _vm.batchSelectAll,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "55" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "min-width": "150",
                          align: "center",
                          prop: "driverName",
                          label: _vm.$t("collectionCenter.LanShouPeople"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "min-width": "150",
                          align: "center",
                          prop: "siteName",
                          label: _vm.$t("collectionCenter.collectionDot"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "min-width": "150",
                          align: "center",
                          prop: "scanDate",
                          label: _vm.$t("collectionCenter.lanshouT"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "min-width": "150",
                          align: "center",
                          prop: "sourceType",
                          label: _vm.$t("collectionCenter.DataSource"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.sourceType === 0
                                          ? "pda"
                                          : scope.row.sourceType === 1
                                          ? "pc"
                                          : ""
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "min-width": "150",
                          align: "center",
                          prop: "collectedCount",
                          label: _vm.$t(
                            "collectionCenter.receivedPackagesTotalNumber"
                          ),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm.hzTomx
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.details(
                                              scope.row,
                                              "collectBool"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(scope.row.collectedCount))]
                                    )
                                  : _c("span", [
                                      _vm._v(_vm._s(scope.row.collectedCount)),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "min-width": "150",
                          align: "center",
                          prop: "signedInCount",
                          label: _vm.$t(
                            "collectionCenter.TotalNumberOfParcelsSignedIn24Hours"
                          ),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm.hzTomx
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.details(
                                              scope.row,
                                              "twentyFoursignBool"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(scope.row.signedInCount))]
                                    )
                                  : _c("span", [
                                      _vm._v(_vm._s(scope.row.signedInCount)),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "min-width": "150",
                          align: "center",
                          prop: "unsignedQty",
                          label: _vm.$t(
                            "collectionCenter.in24UnsignedPackagesTotalNumber"
                          ),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm.hzTomx
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.details(
                                              scope.row,
                                              "unsignedBool"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(scope.row.unsignedQty))]
                                    )
                                  : _c("span", [
                                      _vm._v(_vm._s(scope.row.unsignedQty)),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "min-width": "150",
                          align: "center",
                          prop: "unsignedCount",
                          label: _vm.$t(
                            "collectionCenter.over24HoursUnsignedPackagesTotalNumber"
                          ),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm.hzTomx
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.details(
                                              scope.row,
                                              "overtwentyFoursignBool"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(scope.row.unsignedCount))]
                                    )
                                  : _c("span", [
                                      _vm._v(_vm._s(scope.row.unsignedCount)),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "min-width": "150",
                          align: "center",
                          prop: "collectSuccessRate",
                          label: _vm.$t(
                            "collectionCenter.SigningRateWithin24Hours"
                          ),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(scope.row.collectSuccessRate) + "%"
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm.findActiveOdd == "mxOddFind"
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("collectionCenter.particulars"),
                    name: "mxOddFind",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          ref: "mxTable",
                          attrs: {
                            "max-height": 600,
                            border: "",
                            data: _vm.mxtableDate,
                          },
                          on: {
                            select: _vm.batchSelectMX,
                            "select-all": _vm.batchSelectAllMX,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { type: "selection", width: "55" },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("collectionCenter.ind"),
                              type: "index",
                              width: "60",
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            (_vm.queryFormOddMX.pageNumber -
                                              1) *
                                              _vm.queryFormOddMX.pageSize +
                                              scope.$index +
                                              1
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3031082008
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "orderNumber",
                              label: _vm.$t("collectionCenter.lanshouOrderNum"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "scanNumber",
                              label: _vm.$t("collectionCenter.Barcode"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "driverName",
                              label: _vm.$t("collectionCenter.LanShouPeople"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "scanTime",
                              label: _vm.$t("collectionCenter.lanshouT"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "siteName",
                              label: _vm.$t("collectionCenter.collectionDot"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "signerSiteName",
                              label: _vm.$t(
                                "collectionCenter.signerBelongsSite"
                              ),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "signerUsername",
                              label: _vm.$t("collectionCenter.signer"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "signerTime",
                              label: _vm.$t("collectionCenter.signT"),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-pagination", {
                        staticStyle: { margin: "10px 0" },
                        attrs: {
                          layout: "total, sizes, prev, pager, next, jumper",
                          "current-page": _vm.queryFormOddMX.pageNumber,
                          "page-sizes": [10, 20, 50, 100, 500, 1000],
                          "page-size": _vm.queryFormOddMX.pageSize,
                          total: _vm.totalMX,
                          background: "",
                        },
                        on: {
                          "update:currentPage": function ($event) {
                            return _vm.$set(
                              _vm.queryFormOddMX,
                              "pageNumber",
                              $event
                            )
                          },
                          "update:current-page": function ($event) {
                            return _vm.$set(
                              _vm.queryFormOddMX,
                              "pageNumber",
                              $event
                            )
                          },
                          "update:pageSize": function ($event) {
                            return _vm.$set(
                              _vm.queryFormOddMX,
                              "pageSize",
                              $event
                            )
                          },
                          "update:page-size": function ($event) {
                            return _vm.$set(
                              _vm.queryFormOddMX,
                              "pageSize",
                              $event
                            )
                          },
                          "current-change": _vm.getTablePageMX,
                          "size-change": _vm.searchClickMX,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }