var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$i18n.t("ClaimsManagement.Payment") + "-Bradesco",
        visible: _vm.visible,
        "before-close": _vm.handleClose,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { attrs: { size: "small", "label-position": "top", disabled: true } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("receivablePayable.PaymentBatchNumber"),
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.payForm.paymentBatchNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.payForm, "paymentBatchNumber", $$v)
                  },
                  expression: "payForm.paymentBatchNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$i18n.t("basicData.付款总金额") } },
            [
              _c("el-input", {
                model: {
                  value: _vm.payForm.feeTotal,
                  callback: function ($$v) {
                    _vm.$set(_vm.payForm, "feeTotal", $$v)
                  },
                  expression: "payForm.feeTotal",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.onClose } },
            [_vm._v(_vm._s(_vm.$i18n.t("GlobalSetObj.cancel")))]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.paySumbit },
            },
            [_vm._v(_vm._s(_vm.$i18n.t("GlobalSetObj.determine")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }