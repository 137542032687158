var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "warpp" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "queryFormRef",
                  staticClass: "queryFormClass",
                  attrs: {
                    model: _vm.queryForm.params,
                    "label-width": "100px",
                    size: "small",
                    "label-position":
                      _vm.$i18n.locale != "zh" ? "top" : "right",
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "operationCenter.largePackageNumber"
                                ),
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", disabled: "" },
                                model: {
                                  value: _vm.queryForm.bigBagNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryForm, "bigBagNo", $$v)
                                  },
                                  expression: "queryForm.bigBagNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("operationCenter.nextHome"),
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", disabled: "" },
                                model: {
                                  value: _vm.queryForm.downstreamName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm,
                                      "downstreamName",
                                      $$v
                                    )
                                  },
                                  expression: "queryForm.downstreamName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: _vm.$t("operationCenter.state") },
                            },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", disabled: "" },
                                model: {
                                  value:
                                    _vm.radioOb[_vm.queryForm.bigBagStatus]
                                      .name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.radioOb[_vm.queryForm.bigBagStatus],
                                      "name",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "radioOb[queryForm.bigBagStatus].name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("operationCenter.Number"),
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  clearable: "",
                                  placeholder: _vm.$t(
                                    "operationCenter.PleaseEnterTheContent"
                                  ),
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleFind($event)
                                  },
                                },
                                model: {
                                  value: _vm.queryForm.orderNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm,
                                      "orderNo",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "queryForm.orderNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("operationCenter.TransferNumber"),
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  clearable: "",
                                  placeholder: _vm.$t(
                                    "operationCenter.PleaseEnterTheContent"
                                  ),
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleFindLogistics($event)
                                  },
                                },
                                model: {
                                  value: _vm.queryForm.logisticsNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm,
                                      "logisticsNumber",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "queryForm.logisticsNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { type: "flex", justify: "start" } },
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: { click: _vm.handleFind },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("operationCenter.Procurar"))
                                ),
                              ]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-refresh",
                                  size: "mini",
                                },
                                on: { click: _vm.queryFormReset },
                              },
                              [_vm._v(_vm._s(_vm.$t("operationCenter.reset")))]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-divider"),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled:
                      _vm.queryForm.bigBagStatus !== _vm.radioOb.unpacked.code,
                  },
                  on: { click: _vm.handleArchive },
                },
                [_vm._v(_vm._s(_vm.$t("operationCenter.SealPreservation")))]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "warning",
                    disabled:
                      _vm.queryForm.bigBagStatus !== _vm.radioOb.printed.code &&
                      _vm.queryForm.bigBagStatus !== _vm.radioOb.packaged.code,
                  },
                  on: { click: _vm.handleRelieve },
                },
                [_vm._v(_vm._s(_vm.$t("operationCenter.Unblock")))]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.bigBagNoArr, border: "", height: "50vh" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "index",
                  label: _vm.$t("operationCenter.ind"),
                  width: "50",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "orderNo",
                  label: _vm.$t("operationCenter.Number"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "trackingNo",
                  label: _vm.$t("operationCenter.TheInboundOrderNumber"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "customerChannelName",
                  label: _vm.$t("operationCenter.CustomerChannels"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "receiveCountryName",
                  label: _vm.$t("operationCenter.country"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "weight",
                  label: _vm.$t("operationCenter.Weight") + "KG",
                  width: "90",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("operationCenter.operate"),
                  width: "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popconfirm",
                          {
                            attrs: {
                              title: _vm.$t("operationCenter.AreYouSureDelete"),
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.asyncRemoveSmallBag(
                                  scope.$index,
                                  scope.row.id
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  slot: "reference",
                                  disabled:
                                    _vm.queryForm.bigBagStatus !==
                                    _vm.radioOb.unpacked.code,
                                  type: "danger",
                                  size: "mini",
                                },
                                slot: "reference",
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("operationCenter.Delete"))
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt" },
            [
              _c(
                "el-row",
                { staticClass: "mb10 mt" },
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("span", { staticClass: "linheight" }, [
                      _vm._v(_vm._s(_vm.$t("operationCenter.Number"))),
                    ]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c("el-input", {
                        ref: "weightRef",
                        attrs: {
                          clearable: "",
                          disabled:
                            _vm.queryForm.bigBagStatus !==
                            _vm.radioOb.unpacked.code,
                          placeholder: _vm.$t(
                            "operationCenter.PleaseEnterTheContent"
                          ),
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleAdd($event)
                          },
                        },
                        model: {
                          value: _vm.otherObj.sumWeight,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.otherObj,
                              "sumWeight",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "otherObj.sumWeight",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled:
                              _vm.queryForm.bigBagStatus !==
                              _vm.radioOb.unpacked.code,
                          },
                          on: { click: _vm.handleAdd },
                        },
                        [_vm._v("添加")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "check",
                          model: {
                            value: _vm.otherObj.validateWeightFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.otherObj, "validateWeightFlag", $$v)
                            },
                            expression: "otherObj.validateWeightFlag",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("operationCenter.whetherToCheckTheWeight")
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }