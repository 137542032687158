var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("AvailableSellers", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.step === 0,
            expression: "step === 0",
          },
        ],
        attrs: {
          sellers: _vm.formData.formStep01.sellers,
          "handle-next": _vm.handleNextStep,
          "handle-previous": _vm.handlePreviousStep,
          "is-last-step": _vm.isLastStep,
          "is-first-step": _vm.isFirstStep,
        },
        on: { updateSeller: _vm.onUpdateSellers },
      }),
      _c("FormDriver", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.step === 1,
            expression: "step === 1",
          },
        ],
        attrs: {
          "form-data": _vm.formData.formStep02,
          "handle-next": _vm.handleNextStep,
          "handle-previous": _vm.handlePreviousStep,
          "is-last-step": _vm.isLastStep,
          "is-first-step": _vm.isFirstStep,
        },
        on: {
          "update:formData": function ($event) {
            return _vm.$set(_vm.formData, "formStep02", $event)
          },
          "update:form-data": function ($event) {
            return _vm.$set(_vm.formData, "formStep02", $event)
          },
        },
      }),
      _c("FormVehicle", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.step === 2,
            expression: "step === 2",
          },
        ],
        attrs: {
          "form-data": _vm.formData.formStep03,
          "handle-next": _vm.handleNextStep,
          "handle-previous": _vm.handlePreviousStep,
          "is-last-step": _vm.isLastStep,
          "is-first-step": _vm.isFirstStep,
        },
        on: {
          "update:formData": function ($event) {
            return _vm.$set(_vm.formData, "formStep03", $event)
          },
          "update:form-data": function ($event) {
            return _vm.$set(_vm.formData, "formStep03", $event)
          },
        },
      }),
      _c("FormTrailer", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.step === 3,
            expression: "step === 3",
          },
        ],
        attrs: {
          "form-data": _vm.formData.formStep04,
          "handle-next": _vm.handleNextStep,
          "handle-previous": _vm.handlePreviousStep,
          "is-last-step": _vm.isLastStep,
          "is-first-step": _vm.isFirstStep,
        },
        on: {
          "update:formData": function ($event) {
            return _vm.$set(_vm.formData, "formStep04", $event)
          },
          "update:form-data": function ($event) {
            return _vm.$set(_vm.formData, "formStep04", $event)
          },
        },
      }),
      _c("FormTransportSupply", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.step === 4,
            expression: "step === 4",
          },
        ],
        attrs: {
          "form-data": _vm.formData.formStep05,
          "handle-next": _vm.handleNextStep,
          "handle-previous": _vm.handlePreviousStep,
          "is-last-step": _vm.isLastStep,
          "is-first-step": _vm.isFirstStep,
        },
        on: {
          "update:formData": function ($event) {
            return _vm.$set(_vm.formData, "formStep05", $event)
          },
          "update:form-data": function ($event) {
            return _vm.$set(_vm.formData, "formStep05", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }