import axios from 'axios';
import PDFMerger from 'pdf-merger-js/browser';
import { setHttpTurnHttps } from '@/utils/tools';

const getFile = url => {
  return new Promise((resolve, reject) => {
    const obj = {
      method: 'get',
      url,
      responseType: 'blob'
      // responseType: 'arraybuffer'
    };
    axios(obj).then(data => {
      // console.log(data);
      resolve(data.data);
    }).catch(error => {
      // console.log(1);
      reject(error.toString());
    });
  });
};

/**
 * 批量现在
 */
const batchPdfDownload = async(arr) => {
  try {
    const promisePDFUrlArr = arr.map((item) => {
      // 将 http 升级到 https
      const newURl = setHttpTurnHttps(item);
      return getFile(newURl);
    });
    const merger = new PDFMerger();

    const resultsArr = await Promise.allSettled(promisePDFUrlArr);
    // 过滤出成功的请求
    const successfulPromises = resultsArr.filter(item => {
      return item.status === 'fulfilled';
    });
    for (const { value } of successfulPromises) {
      await merger.add(value);
    }
    const mergedPdf = await merger.saveAsBlob();
    const strURl = await promiseFileReader(mergedPdf);
    return strURl;
  } catch (error) {
    console.error(error);
    return '';
  }
};

const promiseFileReader = (blorStr) => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.readAsDataURL(blorStr);
    reader.onload = function() {
      // var dataURL = reader.result;
      res(reader.result);
    };
    reader.onerror = function(e) {
      // var dataURL = reader.result;
      rej(e);
    };
  });
};

export {
  // creationBlob
  batchPdfDownload
};

