<template>
  <div class="app-container">
    <el-card style="margin-bottom: 20px;">
      <el-form
        ref="form"
        :model="queryForm"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item :label="$t('trajectory.Time')">
              <!--查询时间-->
              <el-date-picker
                v-model="queryDateTime"
                style="width: 100%"
                type="datetimerange"
                :range-separator="$t('operationCenter.to')"
                :start-placeholder="$t('operationCenter.startDate')"
                :end-placeholder="$t('operationCenter.endDate')"
                value-format="yyyy-MM-dd HH:mm:ss"
              />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!--供应商-->
            <el-form-item :label="$t('GlobalSetObj.supplier')">
              <el-select
                v-model="queryForm.params.providerId"
                filterable
                :placeholder="$t('operationCenter.PleaseSelect')"
                clearable
                style="width: 100%;"
              >
                <el-option
                  v-for="item in providerList"
                  :key="item.id"
                  :label="item.providerName + '（' + item.code + '）'"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!-- 网点： -->
            <el-form-item :label="$t('collectionCenter.site')">
              <el-select
                v-model="queryForm.params.siteId"
                :placeholder="$t('collectionCenter.pleaseChoose')"
                clearable
                filterable
                style="width: 100%;"
              >
                <el-option
                  v-for="(item, index) in siteIdList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-button
        type="primary"
        size="small"
        icon="el-icon-search"
        @click="searchResources(1)"
      >{{ $t('operationCenter.Procurar') }}</el-button>
      <el-button size="small" icon="el-icon-refresh-left" @click="searchResources(2)">
        {{ $t('operationCenter.reset') }}
      </el-button>
    </el-card>
    <el-card>
      <div style="margin-bottom: 10px">
        <el-button size="small" type="primary" @click="exportData()">
          {{ $t('operationCenter.exports') }}
        </el-button>
      </div>
      <el-table
        ref="elTable"
        :key="tableKey"
        border
        row-key="id"
        :data="tableData"
        :tree-props="{ children: 'siteList', hasChildren: true }"
      >
        <el-table-column
          width="60"
          align="center"
          fixed="left"
        />
        <el-table-column
          v-for="(item, ind) in tableColumn"
          :key="ind"
          :prop="item.prop"
          :label="item.label"
          :align="item.align"
          :min-width="item.cloWidth"
        />
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { apiProviderPage } from '@/api/finance/basicData';
import { searchSiteIdList } from '@/api/lanshou';
import { apiGetProviderWaybill, apiProviderExport } from '@/api/orderApi';
import { cloneDeep } from 'lodash-es';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList.js';

export default {
  name: 'SupplierData',
  data() {
    return {
      queryDateTime: [], // [this.getTimer()[0], this.getTimer()[1]],
      providerList: [],
      siteIdList: [],
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          providerId: '',
          startTime: '',
          endTime: '',
          siteId: ''
        }
      },
      tableData: [],
      tableKey: new Date().getTime(),
      tableColumn: [
        // 供应商名称
        { label: this.$t('GlobalSetObj.VendorName'), prop: 'providerName', cloWidth: '200', align: 'left' },
        // 网点名称
        { label: this.$t('basicData.siteName'), prop: 'siteName', cloWidth: '200', align: 'left' },
        // 预接收包裹数
        { label: this.$t('GlobalSetObj.NumberPackagesReceivedAdvance'), prop: 'perceivePackageCount', cloWidth: '200', align: 'center' },
        // 入库扫描数
        // { label: this.$t('GlobalSetObj.TheNumberInboundScans'), prop: 'waybillNumber', cloWidth: '200', align: 'center' },
        // 待派送包裹数
        { label: this.$t('GlobalSetObj.TheNumberPackagesDelivered'), prop: 'stayDeliveryPackageCount', cloWidth: '200', align: 'center' },
        // 派件中包裹数
        { label: this.$t('GlobalSetObj.TheNumberPackagesDelivery'), prop: 'deliveryPackageCount', cloWidth: '200', align: 'center' },
        // 签收包裹数
        { label: this.$t('GlobalSetObj.NumberPackagesSigned'), prop: 'signPackageCount', cloWidth: '200', align: 'center' },
        // 投递失败数
        { label: this.$t('GlobalSetObj.NumberDeliveryFailures'), prop: 'deliveryFailureCount', cloWidth: '200', align: 'center' },
        // 异常包裹数
        { label: this.$t('GlobalSetObj.NumberAbnormalPackages'), prop: 'exceptionPackageCount', cloWidth: '200', align: 'center' },
        // 待退回包裹数
        { label: this.$t('GlobalSetObj.NumberPackagesReturned'), prop: 'tobeReturnedPackageCount', cloWidth: '200', align: 'center' }
      ],
      zhDate: 'yyyy-MM-DD HH:mm:ss',
      ptDate: 'DD-MM-yyyy HH:mm:ss'
    };
  },
  created() {
    this.getNewestSite();
    this.getVendorList();
    this.getTimer();
  },
  methods: {
    // 导出
    exportData() {
      const data = cloneDeep(this.queryForm);
      const param = {
        params: {
          createStartTime: '',
          createEndTime: '',
          providerId: '',
          siteId: ''
        }
      };
      if (this.queryDateTime && this.queryDateTime.length > 0) {
        param.params.createStartTime = this.queryDateTime[0];
        param.params.createEndTime = this.queryDateTime[1];
      }
      param.params.providerId = data.params.providerId;
      param.params.siteId = data.params.siteId;
      apiProviderExport(param).then(res => {
        goExportList(this);
      });
    },
    // 获取当前巴西时间
    getTimer() {
      const day = new Date().getTime();
      const dayCode = new Date(day).getDay() > 0 ? new Date(day).getDay() : 7;
      const dayTime = 24 * 60 * 60 * 1000;
      let Mon = new Date(day - dayTime * (dayCode - 1));// 传入时间的周一
      Mon = dayjs(Mon).format('YYYY-MM-DD HH:mm:ss');
      const endDate = new Date(new Date(Mon).getTime() - dayTime);// 上周日
      const startDate = new Date(new Date(endDate).getTime() - 6 * dayTime);// 上周一
      const startTime = dayjs(startDate).format('YYYY-MM-DD') + ' 00:00:00';
      const endTime = dayjs(endDate).format('YYYY-MM-DD') + ' 23:59:59';
      this.queryDateTime = [startTime, endTime];
      this.getTableData();
    },
    // 查询
    searchResources(type) {
      if (type === 1) {
        this.getTableData();
      } else {
        this.queryForm.pageNumber = 1;
        this.queryForm.pageSize = 10;
        this.queryForm.params = {
          providerId: '',
          startTime: '',
          endTime: '',
          siteId: ''
        };
        this.getTimer();
      }
    },
    // 查询数据
    getTableData() {
      const data = cloneDeep(this.queryForm);
      const param = {
        params: {
          createStartTime: '',
          createEndTime: '',
          providerId: '',
          siteId: ''
        }
      };
      if (this.queryDateTime && this.queryDateTime.length > 0) {
        param.params.createStartTime = this.queryDateTime[0];
        param.params.createEndTime = this.queryDateTime[1];
      }
      param.params.providerId = data.params.providerId;
      param.params.siteId = data.params.siteId;
      apiGetProviderWaybill(param).then(res => {
        if (res.status === 'OK') {
          const getRandomNumbers = (arr) => {
            const result = []; // 存放结果的数组
            for (let i = 0; i < arr.length; i++) {
              const randomIndex = Math.floor(Math.random() * arr.length); // 生成随机索引
              result.push(arr[randomIndex]); // 如果未被选中则添加至结果数组
            }
            return result;
          };
          const dataList = (data, name) => {
            data.map((item, ind) => {
              if (name) {
                item.providerName = name;
              }
              const date = new Date().getTime().toString().split('');
              item.id = getRandomNumbers(date).join('') + new Date().getTime() + ind;
              if (item.siteList && item.siteList.length > 0) {
                dataList(item.siteList, item.providerName);
              }
            });
            return data;
          };
          this.tableData = dataList(res.data);
        }
      });
    },
    // 获取最新的网点list数据
    getNewestSite() {
      searchSiteIdList().then(res => {
        if (res.status === 'OK') {
          this.siteIdList = res.data;
        }
      });
    },
    // 获取供应商账号分页列表
    getVendorList() {
      const param = {
        pageSize: 99999999,
        pageNumber: 1,
        params: {}
      };
      apiProviderPage(param, false).then(res => {
        if (res.status === 'OK') {
          this.providerList = res.data.records;
        }
      });
    }
  }
};
</script>

<style scoped>

</style>
