<script >
import { defineProps, defineEmits } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  isFirstStep: {
    type: Boolean,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  return {
    emit
  };
};
export default __sfc_main;
</script>

<template>
  <div>
    <o-button v-show="!isFirstStep" variant="outline" size="md" @click="emit('handlePrevious')">
      {{ $t('driver.modalPreviousButton') }}
    </o-button>
  </div>
</template>
