<template>
  <div class="app-container">
    <div style="margin-bottom: 20px">
      <el-form
        ref="formSearch"
        :model="queryForm"
        label-position="top"
        size="small"
      >
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item :label="$t('GlobalSetObj.Date')">
              <!--日期-->
              <el-date-picker
                v-model="queryDateTime"
                :end-placeholder="$t('operationCenter.endDate')"
                :range-separator="$t('operationCenter.to')"
                :start-placeholder="$t('operationCenter.startDate')"
                style="width: 100%"
                type="daterange"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
          </el-col>
          <!-- 州 -->
          <el-col :span="4">
            <el-form-item :label="$t('GlobalSetObj.Estado')">
              <el-select
                v-model="queryForm.params.states"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                filterable
                multiple
                style="width: 100%"
              >
                <el-option
                  v-for="item in StateList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.label"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--转运中心-->
            <el-form-item :label="$t('collectionCenter.transferCenter')">
              <el-select
                v-model="queryForm.params.siteIds"
                :placeholder="$t('GlobalSetObj.all')"
                clearable
                collapse-tags
                filterable
                multiple
                style="width: 100%;"
              >
                <el-option
                  v-for="item in siteList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <div style="margin-top: 27px;">
              <el-button
                icon="el-icon-search"
                size="small"
                type="primary"
                @click="searchResources(1)"
              >{{ $t('operationCenter.Procurar') }}
              </el-button>
              <el-button icon="el-icon-refresh-left" size="small" @click="searchResources(2)">
                {{ $t('operationCenter.reset') }}
              </el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#f2f2f2',color:'black'}" border>
      <el-table-column
        :label="$t('GlobalSetObj.serial')" align="center" type="index"
        width="80"
      />
      <el-table-column
        v-for="(item, ind) in columnList"
        :key="ind"
        :align="item.align"
        :min-width="item.width"
        :prop="item.prop"
        :show-overflow-tooltip="true"
      >
        <template slot="header">
          <div>{{ item.label }}</div>
        </template>
        <template slot-scope="scope">
          <div
            :class="strARR.includes(item.prop) && scope.row[item.prop] > 0 ? 'table-active' :''"
            @click="seeDetial(scope.row,item.prop,scope.row[item.prop], item.ticketType)"
          >
            {{ scope.row[item.prop] || "--" }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page.sync="queryForm.pageNumber"
        :page-size.sync="queryForm.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 10px 0"
        @current-change="getCurrentTableData"
        @size-change="getSizeTableData"
      />
    </div>
    <!-- 包裹详情-->
    <el-drawer
      :title="$t('collectionCenter.particulars')"
      :visible.sync="dialogVisible"
      center
      direction="ltr"
      size="1200px"
      @close="closeDrawer"
    >
      <packageDetail
        v-if="dialogVisible"
        ref="votesDetails"
        :dataobj="dataObj"
        :query-date-time="queryDateTime01"
        @call-parent-method="parentMethod"
      />
    </el-drawer>
  </div>
</template>

<script>
import { dispatchPage, getLgAreaListData, getSiteTransfer } from '@/api/orderApi';
import dayjs from 'dayjs';

export default {
  name: 'AllocateDetentionMonitoring',
  components: {
    'packageDetail': () => import('./component/packageDetail')
  },
  data() {
    return {
      dialogVisible: false, // 详情弹窗
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          siteIds: [],
          states: [],
          startTime: '',
          endTime: ''
        }
      },
      tableData: [],
      total: 0,
      StateList: [], // 州
      columnList: [
        // 日期
        { label: this.$t('GlobalSetObj.Date'), prop: 'date', width: 160, align: 'center' },
        // 州
        { label: this.$t('GlobalSetObj.Estado'), prop: 'state', width: 120, align: 'center' },
        // 转运中心
        { label: this.$t('collectionCenter.transferCenter'), prop: 'transferCenterName', width: 180, align: 'center' },
        // 卸车票数
        {
          label: this.$t('navbar.unloadTicketCount'),
          prop: 'unloadTicketCount',
          width: 180,
          ticketType: 1,
          align: 'center'
        },
        // 到件票数
        {
          label: this.$t('navbar.arrivalTicketCount'),
          prop: 'arrivalTicketCount',
          width: 180,
          ticketType: 2,
          align: 'center'
        },
        // 装车票数
        {
          label: this.$t('customerManagements.loaderArrived'),
          prop: 'loadTicketCount',
          width: 180,
          ticketType: 3,
          align: 'center'
        },
        // 发件票数
        {
          label: this.$t('navbar.shipmentTicketCount'),
          prop: 'shipmentTicketCount',
          width: 180,
          ticketType: 4,
          align: 'center'
        },
        // 差异
        { label: this.$t('navbar.difference'), prop: 'difference', width: 180, ticketType: 5, align: 'center' },
        // 疑似漏扫
        {
          label: this.$t('collectionCenter.suspectedMissedScan'),
          prop: 'lackLoadingCount',
          width: 170,
          ticketType: 6,
          align: 'center'
        },
        // 24小时滞留
        { label: this.$t('navbar.twentyFourHours'), prop: 'twentyFourHours', width: 180, ticketType: 7, align: 'center' },
        // 48小时滞留
        { label: this.$t('navbar.fortyEightHours'), prop: 'fortyEightHours', width: 180, ticketType: 8, align: 'center' },
        // 72小时滞留
        { label: this.$t('navbar.seventyTwoHours'), prop: 'seventyTwoHours', width: 180, ticketType: 9, align: 'center' },
        // 7天滞留
        { label: this.$t('navbar.sevenDays'), prop: 'sevenDays', width: 180, ticketType: 10, align: 'center' },
        // 15天滞留
        { label: this.$t('navbar.fifteenDays'), prop: 'fifteenDays', width: 180, ticketType: 11, align: 'center' }
      ],
      queryDateTime: [this.getAWeek().startDate, this.getAWeek().endDate],
      siteList: [],
      dataObj: {},
      strARR: ['unloadTicketCount', 'arrivalTicketCount', 'loadTicketCount', 'difference', 'twentyFourHours',
        'fortyEightHours', 'seventyTwoHours', 'sevenDays', 'fifteenDays', 'shipmentTicketCount', 'lackLoadingCount'],
      selectDate: '',
      queryDateTime01: []
    };
  },
  created() {
    // 获取加盟商选项
    this.getAddress();
    this.getSiteTransfer();
    this.getTableData(1);
  },
  methods: {
    getSiteTransfer() {
      getSiteTransfer().then(res => {
        if (res.status === 'OK') {
          this.siteList = res.data;
        }
      });
    },
    // 搜索、重置
    searchResources(type) {
      if (type === 1) {
        this.queryForm.pageNumber = 1;
        this.getTableData();
      } else {
        this.queryForm = {
          pageSize: 10,
          pageNumber: 1,
          params: {
            siteIds: [],
            states: [],
            startTime: '',
            endTime: ''
          }
        };
        this.queryDateTime = [this.getAWeek().startDate, this.getAWeek().endDate];
        this.getTableData();
      }
    },
    // 获取最近30天时间
    getAWeek() {
      return {
        endDate: dayjs().format('YYYY-MM-DD'),
        startDate: dayjs().subtract(6, 'day').format('YYYY-MM-DD')
      };
    },
    // 获取加盟商时效统计列表
    getTableData(val) {
      if (this.queryDateTime && this.queryDateTime.length > 0) {
        this.queryForm.params.startTime = this.queryDateTime[0];
        this.queryForm.params.endTime = this.queryDateTime[1];
      } else {
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
        return;
      }
      // if (ids.includes(this.queryForm.params.))
      if (this.queryForm.params.siteIds.includes(90) || this.queryForm.params.siteIds.includes(18)) {
        console.log('数组中存在154或18');
        this.queryForm.params.siteIds.push(90);
        this.queryForm.params.siteIds.push(18);
        const siteIds = this.queryForm.params.siteIds;
        const idSet = new Set(siteIds);
        // 将 Set 转换回数组并更新 this.queryForm.params.siteIds
        this.queryForm.params.siteIds = Array.from(idSet);
      } else {
        console.log('数组中不存在154或18');
      }
      let loading = null;
      if (val === 1) {
        loading = this.$loading({
          fullscreen: true,
          lock: true,
          spinner: 'el-icon-loading',
          text: 'loading...',
          background: 'rgba(250, 250, 250, 0.6)'
        });
      }
      dispatchPage(this.queryForm).then(res => {
        if (res.status === 'OK') {
          this.total = res.data.total;
          this.tableData = res.data.records;
          this.tableData.forEach(item => {
            item.date = item.date || '--';
          });
          if (val === 1) {
            loading.close();
          }
        }
      }).catch(() => {
        if (val === 1) {
          loading.close();
        }
      });
    },
    // 页码变化翻页
    getCurrentTableData(val) {
      this.queryForm.pageNumber = val;
      this.getTableData();
    },
    parentMethod(arg) {
      this.dialogVisible = arg;
    },
    // 每页显示数量变化
    getSizeTableData(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.getTableData();
    },
    closeDrawer() {
      this.dialogVisible = false;
    },
    // 州
    getAddress() {
      const params = {
        level: 0,
        parentId: ''
      };
      getLgAreaListData(params, false).then((res) => {
        if (res.status === 'OK') {
          this.StateList = res.data.map(item => {
            return {
              id: item.id,
              label: `${item.nameEn}`
            };
          });
        }
      });
    },
    seeDetial(row, porp, number, ticketType) {
      console.log(row, porp);
      if (this.strARR.includes(porp)) {
        if (number) {
          this.dataObj = {
            state: row.state,
            siteId: row.transferCenterId,
            ticketType: ticketType
          };
          this.queryDateTime01 = [row.date + ' 00:00:00', row.date + ' 23:59:59'];
          this.dialogVisible = true;
        }
      }
    }
  }
};
</script>

<style scoped>
.app-container >>> .el-drawer__header {
  margin-bottom: 10px;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 20px;
  color: black;
}

.table-active {
  color: #409EFF;
  cursor: pointer;
}
</style>
