var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showTips,
              expression: "showTips",
            },
          ],
          staticStyle: { padding: "10px", width: "60%", position: "relative" },
        },
        [
          _c("el-alert", {
            staticStyle: { border: "1px solid #E6A23C" },
            attrs: {
              title: _vm.$t("placeOrder.PleaseProvideDetailedWeightAndVolume"),
              type: "warning",
              closable: false,
              "show-icon": "",
            },
          }),
          _c(
            "span",
            {
              staticStyle: {
                position: "absolute",
                right: "20px",
                top: "20px",
                color: "#E6A23C",
              },
            },
            [_vm._v(" " + _vm._s(_vm.showTime) + " ")]
          ),
        ],
        1
      ),
      _c(
        "el-form",
        { ref: "supplementDetail", attrs: { model: _vm.supplementDetail } },
        [
          _c(
            "el-row",
            { staticStyle: { padding: "10px 20px" } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.$t("GlobalSetObj.TotalNumberOfPackages") + "：",
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.supplementDetail.totalNumber)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.pesoTotal1") + "：",
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.supplementDetail.totalWeight)),
                      ]),
                      _vm._v("kg "),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.PackageTotalWeight") + "：",
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.supplementDetail.packageVolume)),
                      ]),
                      _vm._v("m³ "),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "divider" }),
          _c(
            "div",
            {
              staticStyle: {
                "max-height": "360px",
                overflow: "hidden auto",
                padding: "10px 20px",
              },
            },
            _vm._l(_vm.supplementDetail.detailList, function (item, index) {
              return _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: { "margin-bottom": "10px" },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "15px",
                        "font-weight": "bold",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("orderCenterCont.specification")) +
                          " " +
                          _vm._s(index + 1) +
                          " "
                      ),
                      _vm.supplementDetail.detailList.length > 1
                        ? _c("el-button", {
                            staticStyle: {
                              float: "right",
                              padding: "0",
                              "font-size": "20px",
                            },
                            attrs: { type: "text", icon: "el-icon-delete" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteDetail(index)
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 10 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: _vm.quantityKey,
                                  attrs: {
                                    label: _vm.$t("collectionCenter.quantity"),
                                    prop: "detailList." + index + ".quantity",
                                    rules: _vm.totalNumberPieces,
                                  },
                                },
                                [
                                  _c("el-input-number", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "placeOrder.pleaseInput"
                                      ),
                                      min: 1,
                                      max: 999,
                                      precision: 0,
                                      step: 1,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.quantityChange()
                                      },
                                    },
                                    model: {
                                      value: item.quantity,
                                      callback: function ($$v) {
                                        _vm.$set(item, "quantity", $$v)
                                      },
                                      expression: "item.quantity",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("GlobalSetObj.SingleWeight"),
                                    prop: "detailList." + index + ".unitWeight",
                                    rules: _vm.pesoTotal,
                                  },
                                },
                                [
                                  _c("el-input-number", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "placeOrder.pleaseInput"
                                      ),
                                      min: 0.001,
                                      max: 9999,
                                      precision: 3,
                                      step: 1,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.unitWeightChange()
                                      },
                                    },
                                    model: {
                                      value: item.unitWeight,
                                      callback: function ($$v) {
                                        _vm.$set(item, "unitWeight", $$v)
                                      },
                                      expression: "item.unitWeight",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: _vm.volumeTotalKey,
                                  attrs: {
                                    label: _vm.$t("GlobalSetObj.SingleVolume"),
                                    prop: "detailList." + index + ".unitVolume",
                                    rules: _vm.parcelVolume,
                                  },
                                },
                                [
                                  _c("el-input-number", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "placeOrder.pleaseInput"
                                      ),
                                      min: 0.001,
                                      max: 999,
                                      precision: 3,
                                      step: 1,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.unitVolumeChange(index)
                                      },
                                    },
                                    model: {
                                      value: item.unitVolume,
                                      callback: function ($$v) {
                                        _vm.$set(item, "unitVolume", $$v)
                                      },
                                      expression: "item.unitVolume",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: _vm.volumeKey,
                              attrs: {
                                label: _vm.$t("operatingPlatform.long") + "(m)",
                                prop: "detailList." + index + ".unitLength",
                                rules: _vm.lengthWeightHeightCheck,
                              },
                            },
                            [
                              _c("el-input-number", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  precision: 3,
                                  min: 0.001,
                                  placeholder: _vm.$t("placeOrder.pleaseInput"),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.lengthWeightHeight(index)
                                  },
                                },
                                model: {
                                  value: item.unitLength,
                                  callback: function ($$v) {
                                    _vm.$set(item, "unitLength", $$v)
                                  },
                                  expression: "item.unitLength",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: _vm.volumeKey,
                              attrs: {
                                label:
                                  _vm.$t("operatingPlatform.width") + "(m)",
                                prop: "detailList." + index + ".unitWidth",
                                rules: _vm.lengthWeightHeightCheck,
                              },
                            },
                            [
                              _c("el-input-number", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  precision: 3,
                                  min: 0.001,
                                  placeholder: _vm.$t("placeOrder.pleaseInput"),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.lengthWeightHeight(index)
                                  },
                                },
                                model: {
                                  value: item.unitWidth,
                                  callback: function ($$v) {
                                    _vm.$set(item, "unitWidth", $$v)
                                  },
                                  expression: "item.unitWidth",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: _vm.volumeKey,
                              attrs: {
                                label:
                                  _vm.$t("operatingPlatform.height") + "(m)",
                                prop: "detailList." + index + ".unitHeight",
                                rules: _vm.lengthWeightHeightCheck,
                              },
                            },
                            [
                              _c("el-input-number", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  precision: 3,
                                  min: 0.001,
                                  placeholder: _vm.$t("placeOrder.pleaseInput"),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.lengthWeightHeight(index)
                                  },
                                },
                                model: {
                                  value: item.unitHeight,
                                  callback: function ($$v) {
                                    _vm.$set(item, "unitHeight", $$v)
                                  },
                                  expression: "item.unitHeight",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm.supplementDetail.detailList.length < 999
        ? _c("div", { staticClass: "addBtn" }, [
            _c("span", { on: { click: _vm.addDetail } }, [
              _c("i", { staticClass: "el-icon-plus" }),
              _vm._v(
                " " +
                  _vm._s(_vm.$t("GlobalSetObj.AddOtherSpecifications")) +
                  " "
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }