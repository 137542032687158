var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      staticStyle: { width: "300px" },
      attrs: {
        clearable: "",
        filterable: "",
        remote: "",
        "remote-method": _vm.asyncQuery,
        loading: _vm.loading,
        placeholder: _vm.$t("spareNumberPoo.Only10AreDisplayed"),
      },
      on: { change: _vm.onChange },
      model: {
        value: _vm.val1,
        callback: function ($$v) {
          _vm.val1 = $$v
        },
        expression: "val1",
      },
    },
    _vm._l(_vm.list, function (item) {
      return _c("el-option", {
        key: item.id,
        attrs: { label: item.accountAlias, value: item.id },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }