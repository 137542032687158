
const tablePrice = {
  country: {
    zh: 'Country',
    en: 'Country',
    pu: 'País'
  },
  state: {
    zh: 'State',
    en: 'State',
    pu: 'Estado'
  },
  regions: {
    zh: 'Regions',
    en: 'Regions',
    pu: 'Regiões'
  },
  city: {
    zh: 'City',
    en: 'City',
    pu: 'Cidade'
  },
  ibgeCodes: {
    zh: 'IBGE Codes',
    en: 'IBGE Codes',
    pu: 'Código IBGE'
  },
  capitalDistance: {
    zh: 'Capital distance',
    en: 'Capital distance',
    pu: 'Distância da capital'
  },
  baseDistance: {
    zh: 'Base distance',
    en: 'Base distance',
    pu: 'Distância da base'
  },
  macroRegions: {
    zh: 'Macro regions',
    en: 'Macro regions',
    pu: 'Macro regiões'
  },
  microRegions: {
    zh: 'Micro regions',
    en: 'Micro regions',
    pu: 'Micro regiões'
  },
  deliveryPricesZoning: {
    zh: 'Delivery prices zoning',
    en: 'Delivery prices zoning',
    pu: 'Preço de entrega'
  },
  minimumShipping: {
    zh: 'Minimum shipping',
    en: 'Minimum shipping',
    pu: 'Frete mínimo'
  },
  minZipCode: {
    zh: 'Min zip code',
    en: 'Min zip code',
    pu: 'CEP mínimo'
  },
  maxZipCode: {
    zh: 'Max zip code',
    en: 'Max zip code',
    pu: 'CEP máximo'
  },
  indexZipCode: {
    zh: 'Index zip code',
    en: 'Index zip code',
    pu: 'CEP índice'
  },
  localCluster: {
    zh: 'Local cluster',
    en: 'Local cluster',
    pu: 'Cluster local'
  },
  iata: {
    zh: 'IATA',
    en: 'IATA',
    pu: 'IATA'
  },
  titularPartner: {
    zh: 'Titular partner',
    en: 'Titular partner',
    pu: 'Sócio titular'
  },
  baseAddress: {
    zh: 'Base address',
    en: 'Base address',
    pu: 'Endereço base'
  },
  segment: {
    zh: 'Segment',
    en: 'Segment',
    pu: 'Segmento'
  },
  modal: {
    zh: 'Modal',
    en: 'Modal',
    pu: 'Modal'
  },
  riskArea: {
    zh: 'Risk area',
    en: 'Risk area',
    pu: 'Área de risco'
  },
  lastMileTerm: {
    zh: 'Last mile term',
    en: 'Last mile term',
    pu: 'Prazo last mile'
  },
  addKg: {
    zh: 'Add KG',
    en: 'Add KG',
    pu: 'Adiocionar KG'
  },
  createdAt: {
    zh: 'Created at',
    en: 'Created at',
    pu: 'Criado em'
  },
  add: {
    zh: 'Add worksheet',
    en: 'Add worksheet',
    pu: 'Adicionar'
  },
  tablePrice: {
    zh: 'Table price',
    en: 'Table price',
    pu: 'Tabela de preços'
  },
  success: {
    zh: 'Successfully performed operation',
    en: 'Successfully performed operation',
    pu: 'Operação realizada com sucesso'
  },
  filters: {
    zh: 'Filters',
    en: 'Filters',
    pu: 'Filtros'
  },
  placeholderMacroRegion: {
    zh: 'Enter the macro region',
    en: 'Enter the macro region',
    pu: 'Digite a macro região'
  },
  placeholderMicroRegion: {
    zh: 'Enter the micro region',
    en: 'Enter the micro region',
    pu: 'Digite a micro região'
  },
  placeholderCountry: {
    zh: 'Enter the country',
    en: 'Enter the country',
    pu: 'Digite o país'
  },
  placeholderCity: {
    zh: 'Enter the city',
    en: 'Enter the city',
    pu: 'Digite a cidade'
  },
  placeholderState: {
    zh: 'Enter the state',
    en: 'Enter the state',
    pu: 'Digite o estado'
  },
  placeholderMaxZipCode: {
    zh: 'Enter the max zip code',
    en: 'Enter the max zip code',
    pu: 'Digite o CEP máximo'
  },
  placeholderMinZipCode: {
    zh: 'Enter the min zip code',
    en: 'Enter the min zip code',
    pu: 'Digite o CEP mínimo'
  },
  consult: {
    zh: 'Consult',
    en: 'Consult',
    pu: 'Pesquisar'
  },
  clean: {
    zh: 'Clean',
    en: 'Clean',
    pu: 'Limpar'
  }
};
export {
  tablePrice
};

