<script >
import { ref, watch, computed } from '@vue/composition-api';
import { default as SkeletonTable } from '@/components/skeletonTable';
import { default as WrapperHandleError } from '@/components/wrapperHandleError';
import { default as SectionHeader } from '@/components/sectionHeader';
import { default as Actions } from './actions';
import { default as FormCreateTrailer } from './form/formCreateTrailer';
import { useGetAllTrailers } from '@/api/transport/trailer/query';
import { useModal } from '@/composable/useModal';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const filters = ref({
    plate: null,
    status: 1
  });
  const queryParams = ref({
    status: 1,
    plate: null,
    currentPage: 1,
    perPage: 10
  });
  watch(filters.value, newFilters => {
    if (queryParams.value.currentPage > 1) {
      queryParams.value = {
        ...newFilters,
        currentPage: 1,
        perPage: 10
      };
    } else {
      queryParams.value = {
        ...queryParams.value,
        ...newFilters
      };
    }
  });
  const {
    data,
    isError,
    isLoading,
    isFetched,
    error
  } = useGetAllTrailers(queryParams);
  const tableData = computed(() => {
    return data.value?.data?.result ?? data.value;
  });
  const tableTotal = computed(() => {
    return data.value?.data?.activeVehicles ?? 1;
  });
  const isLoadingTable = computed(() => {
    return isLoading.value || !isFetched.value;
  });

  /* handle create trailer */
  const {
    isOpen: isOpenModalCreate,
    handleVisibility: handleVisibilityModalCreate
  } = useModal();
  return {
    filters,
    queryParams,
    isError,
    error,
    tableData,
    tableTotal,
    isLoadingTable,
    isOpenModalCreate,
    handleVisibilityModalCreate
  };
};
__sfc_main.components = Object.assign({
  WrapperHandleError,
  SectionHeader,
  SkeletonTable,
  Actions,
  FormCreateTrailer
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <WrapperHandleError :error="error" :is-error="isError">
    <SectionHeader>
      <template #header>
        <h1 class="header-1">{{ $t('trailer.trailer') }}</h1>
        <o-button variant="primary" size="md" @click="handleVisibilityModalCreate">{{ $t('trailer.createTrailer') }}</o-button>
      </template>

      <template #filter>
        <o-field :label="$t('trailer.plate')" label-for="plate" class="flex-1">
          <o-input id="plate" v-model="filters.plate" :placeholder="$t('trailer.platePlaceholder')" />
        </o-field>

        <o-field :label="$t('trailer.trailerStatus')" label-for="status" class="flex-1">
          <o-select id="status" v-model="filters.status" placeholder="Selecione o status">
            <option :value="1">{{ $t('trailer.activeTrailers') }}</option>
            <option :value="0">{{ $t('trailer.disabledTrailers') }}</option>
          </o-select>
        </o-field>
      </template>
    </SectionHeader>

    <SkeletonTable :is-loading="isLoadingTable">
      <o-table :data="tableData" :row-key="'id'" bordered class="space-m-y-32" paginated backend-pagination
        :total="tableTotal" :per-page="queryParams.perPage" :current-page.sync="queryParams.currentPage"
        :pagination-simple="false">
        <o-table-column v-slot="props" field="trailer_documentation" :label="$t('trailer.document')">
          {{ props.row.trailer_documentation }}
        </o-table-column>

        <o-table-column v-slot="props" field="trailer_license_plate" :label="$t('trailer.plate')">
          {{ props.row.trailer_license_plate }}
        </o-table-column>

        <o-table-column v-slot="props" field="trailer_brand" :label="$t('trailer.brand')">
          {{ props.row.trailer_brand }}
        </o-table-column>

        <o-table-column v-slot="props" field="trailer_type_ownership" :label="$t('trailer.owner')">
          {{
            props.row.trailer_type_ownership ?
            props.row.trailer_owner_CNPJ : props.row.trailer_owner_CPF
          }}
        </o-table-column>

        <o-table-column v-slot="props" field="trailer_RNTRC" label="RNTRC">
          {{ props.row.trailer_RNTRC }}
        </o-table-column>

        <o-table-column v-slot="props" field="trailer_body_type" :label="$t('trailer.bodyType')">
          {{ props.row.trailer_body_type }}
        </o-table-column>

        <o-table-column v-slot="props" field="trailer_state" :label="$t('trailer.state')">
          {{ props.row.trailer_state }}
        </o-table-column>

        <o-table-column v-slot="props" field="trailer_outsourced" :label="$t('trailer.outsourced')">
          {{ props.row.trailer_outsourced ? $t('trailer.yes') : $t('trailer.no') }}
        </o-table-column>

        <o-table-column label="">
          <template slot-scope="{row}">
            <Actions :row="row" :status="!!filters.status" />
          </template>
        </o-table-column>

        <template #empty>
          {{ $t('trailer.noResults') }}
        </template>
      </o-table>
    </SkeletonTable>

    <o-modal :active="isOpenModalCreate" width="560" @close="handleVisibilityModalCreate">
      <div>
        <h2 class="header-2">{{ $t('trailer.createTrailerTitle') }}</h2>
        <p class="paragraph">{{ $t('trailer.createTrailerParagraph') }}</p>
      </div>

      <FormCreateTrailer :close-modal="handleVisibilityModalCreate" />
    </o-modal>

  </WrapperHandleError>
</template>

<style scoped>
.flex-1 {
  flex: 1;
}
</style>
