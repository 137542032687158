var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "warpp" },
    [
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, "max-height": 600, border: "" } },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "40" } }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("newOperationCenter.ind"), align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          (_vm.queryForm.pageNumber - 1) *
                            _vm.queryForm.pageSize +
                            scope.$index +
                            1
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "bagNumber",
              label: _vm.$t("newOperationCenter.bigBagNum"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "packageCount",
              label: _vm.$t("newOperationCenter.NumberPackages"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "grid",
              label: _vm.$t("newOperationCenter.Lattice"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "weight",
              label: _vm.$t("newOperationCenter.Weight"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "bagStatus",
              label: _vm.$t("newOperationCenter.LargePackCondition"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            scope.row.bagStatus == 0
                              ? _vm.$t("newOperationCenter.SealedBag")
                              : scope.row.bagStatus == 1
                              ? _vm.$t("newOperationCenter.haveBeenSigned")
                              : ""
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "plpLink",
              label: _vm.$t("newOperationCenter.ForecastState"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            scope.row.plpLink
                              ? _vm.$t("newOperationCenter.SuccessfulForecast")
                              : _vm.$t("newOperationCenter.abnormal")
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              label: _vm.$t("newOperationCenter.CreationTime"),
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "remark", label: _vm.$t("newOperationCenter.Note") },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.operation"),
              width: "150",
              fixed: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: scope.row.labelUrl,
                                expression: "scope.row.labelUrl",
                              },
                            ],
                            staticStyle: {
                              width: "100%",
                              overflow: "hidden",
                              "white-space": "nowrap",
                              "text-overflow": "ellipsis",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.downClick(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 打印面单 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: scope.row.bagNumber,
                                expression: "scope.row.bagNumber",
                              },
                            ],
                            staticStyle: {
                              width: "100%",
                              overflow: "hidden",
                              "white-space": "normal",
                              "word-break": "break-all",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.bigBagClick(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 小包列表 ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%", display: "none" },
          attrs: {
            data: _vm.tableData,
            border: "",
            "row-style": { height: 0 + "px" },
            "cell-style": { padding: 0 + "px" },
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "40" } }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("operationCenter.ind"), align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          (_vm.queryForm.pageNumber - 1) *
                            _vm.queryForm.pageSize +
                            scope.$index +
                            1
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "bigBagNo",
              label: _vm.$t("operationCenter.bagNumber"),
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "apiBigBagNo", label: "服务商袋号" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "downstreamName",
              label: _vm.$t("operationCenter.nextHome"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "smallBagCount",
              width: "70",
              label: _vm.$t("operationCenter.numberOfPackets"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "weight",
              label: _vm.$t("operationCenter.Weight") + "(KG)",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "smallBagState",
              label: _vm.$t("operationCenter.state"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createUserName",
              label: _vm.$t("operationCenter.creator"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              label: _vm.$t("operationCenter.CreationTime"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "packageUserName",
              label: _vm.$t("operationCenter.operator"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateTime",
              label: _vm.$t("operationCenter.operationTime"),
            },
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("operationCenter.operate"), width: "210" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { margin: "5px" },
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDetails(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("operationCenter.viewUnblock")))]
                    ),
                    _c(
                      "el-popover",
                      {
                        attrs: { placement: "bottom-start", trigger: "click" },
                      },
                      [
                        _c(
                          "div",
                          [
                            scope.row.bigBagStatus === _vm.radioOb.unpacked.code
                              ? [
                                  _c(
                                    "el-popconfirm",
                                    {
                                      attrs: {
                                        title: _vm.$t(
                                          "operationCenter.AreYouSureItIsVoid"
                                        ),
                                      },
                                      on: {
                                        confirm: function ($event) {
                                          return _vm.asyncCancelBag(
                                            scope.row.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "ml5",
                                          attrs: {
                                            slot: "reference",
                                            type: "danger",
                                            size: "mini",
                                          },
                                          slot: "reference",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("operationCenter.void")
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            scope.row.bigBagStatus === _vm.radioOb.packaged.code
                              ? [
                                  scope.row.apiBigBagNo.length === 0
                                    ? _c(
                                        "el-button",
                                        {
                                          staticStyle: { margin: "5px" },
                                          attrs: {
                                            type: "success",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleBagLabel(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "operationCenter.bagSealingPrinting"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-popconfirm",
                                    {
                                      attrs: {
                                        title: _vm.$t(
                                          "operationCenter.AreYouSureItIsVoid"
                                        ),
                                      },
                                      on: {
                                        confirm: function ($event) {
                                          return _vm.asyncCancelBag(
                                            scope.row.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "ml5",
                                          attrs: {
                                            slot: "reference",
                                            type: "danger",
                                            size: "mini",
                                          },
                                          slot: "reference",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("operationCenter.void")
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            scope.row.bigBagStatus === _vm.radioOb.printed.code
                              ? [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "5px" },
                                      attrs: { type: "info", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.asyncRetryGetBigBagExpressWaybill(
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("operationCenter.anew")
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.$t("operationCenter.print")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                            scope.row.bigBagStatus === _vm.radioOb.fited.code
                              ? [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "5px" },
                                      attrs: { type: "info", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.asyncRetryGetBigBagExpressWaybill(
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("operationCenter.anew")
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.$t("operationCenter.print")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { slot: "reference", size: "mini" },
                            slot: "reference",
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("operationCenter.MoreActions"))
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination" },
        [
          _c("el-pagination", {
            staticStyle: { margin: "10px 0" },
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              "current-page": _vm.queryForm.pageNumber,
              "page-sizes": [10, 20, 50, 100, 500],
              "page-size": _vm.queryForm.pageSize,
              total: _vm.total,
              background: "",
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "current-change": _vm.getData,
              "size-change": _vm.getData,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("operationCenter.SelectTheAirport"),
            "close-on-click-modal": false,
            visible: _vm.airportObj.visible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.airportObj, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.airportObj.ruleForm,
                rules: _vm.airportObj.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("operationCenter.DepartureAirport"),
                    prop: "startingStr",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("operationCenter.PleaseSelect"),
                      },
                      model: {
                        value: _vm.airportObj.ruleForm.startingStr,
                        callback: function ($$v) {
                          _vm.$set(_vm.airportObj.ruleForm, "startingStr", $$v)
                        },
                        expression: "airportObj.ruleForm.startingStr",
                      },
                    },
                    [
                      _vm._l(_vm.startingArr, function (item) {
                        return [
                          _c("el-option", {
                            key: item.key,
                            attrs: { label: item.val, value: item.key },
                          }),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("operationCenter.DestinationAirport"),
                    prop: "endStr",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("operationCenter.PleaseSelect"),
                      },
                      model: {
                        value: _vm.airportObj.ruleForm.endStr,
                        callback: function ($$v) {
                          _vm.$set(_vm.airportObj.ruleForm, "endStr", $$v)
                        },
                        expression: "airportObj.ruleForm.endStr",
                      },
                    },
                    [
                      _vm._l(_vm.endArr, function (item) {
                        return [
                          _c("el-option", {
                            key: item.key,
                            attrs: { label: item.val, value: item.key },
                          }),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.airportObj.visible = false
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("operationCenter.Cancel")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitAirportForm("ruleForm")
                    },
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("operationCenter.determine")) + " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title:
              _vm.$t("operationCenter.LargeBags") +
              " " +
              _vm.$t("operationCenter.detail"),
            visible: _vm.unsealObj.visible,
            direction: "ltr",
            size: "50%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.unsealObj, "visible", $event)
            },
          },
        },
        [
          _c("details-list-cmp", {
            attrs: {
              "big-bag-no": _vm.unsealObj.bigBagNo,
              "big-bag-status": _vm.unsealObj.bigBagStatus,
              "downstream-code": _vm.unsealObj.downstreamCode,
              ids: _vm.unsealObj.ids,
              "downstream-name": _vm.unsealObj.downstreamName,
              "random-val": _vm.unsealObj.randomVal,
            },
            on: { handleUpdata: _vm.getData },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("operationCenter.print"),
            "close-on-click-modal": false,
            visible: _vm.printObj.visible,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.printObj, "visible", $event)
            },
            close: _vm.getData,
          },
        },
        [
          _c("bag-label-cmp", {
            attrs: {
              index: _vm.printObj.index,
              "bag-no": _vm.printObj.bagNo,
              "express-waybill-type": _vm.printObj.expressWaybillType,
              "ordinary-props-obj": _vm.printObj.ordinaryObj,
              "other-props-obj": _vm.printObj.otherObj,
            },
            on: { handleClose: _vm.handleClose },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }