<template>
  <div class="warpp">
    <div class="buts">
      <div
        v-show="customerType.type === typeObj.set.code"
        v-permit:remove="'but:customer-managements:list:audit'"
      >
        <el-button
          v-if="ruleForm.auditState === 0"
          type="primary"
          @click="dialog.examineVisible = true"
        >{{ $t('customerManagements.AccountAudit') }}
        </el-button>
        <!-- 账户审核 -->
        <template v-else>
          <div v-permit:remove="'but:customer-managements:list:lock'">
            <!-- 账户启用 -->
            <el-button v-if="ruleForm.lockState === 1" type="warning" @click="setLockState(0)">
              {{ $t('customerManagements.AccountOpening') }}
            </el-button>
            <!-- 账户停用 -->
            <el-button v-else type="danger" @click="setLockState(1)">{{
              $t('customerManagements.AccountDisabled')
            }}
            </el-button>
          </div>
        </template>
      </div>
    </div>
    <el-card>
      <div>
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          class="demo-ruleForm"
          label-width="150px"
        >
          <!-- 账户相关 -->
          <el-divider>{{ $t('customerManagements.AccountsRelated') }}</el-divider>
          <el-row justify="space-between" type="flex">
            <el-col :span="12">
              <!-- 账户名称 -->
              <el-form-item :label="$t('customerManagements.accountName')" prop="username">
                <el-input
                  v-model.trim="ruleForm.username"
                  :disabled="disabled"
                  :placeholder="$t('customerManagements.pleaseInput')"
                  show-word-limit
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 账户别称 -->
              <el-form-item :label="$t('customerManagements.AccountNickname')" prop="customerAlias">
                <el-input
                  v-model.trim="ruleForm.customerAlias"
                  :placeholder="$t('customerManagements.pleaseInput')"
                  :readonly="readonly"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="customerType.type === typeObj.add.code" justify="space-between" type="flex">
            <el-col :span="12">
              <!-- 密码只能在新增的时候出现 -->
              <!-- 密码 -->
              <el-form-item :label="$t('customerManagements.password')" prop="password">
                <el-input
                  v-model.trim="ruleForm.password"
                  :disabled="disabled"
                  :placeholder="$t('customerManagements.passwordPrompt')"
                  maxlength="12"
                  minlength="8"
                  show-word-limit
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 账户审核 -->
              <el-form-item :label="$t('customerManagements.AccountAudit')" prop="auditState">
                <el-radio-group v-model="ruleForm.auditState" disabled>
                  <!-- 未审核 -->
                  <el-radio-button label="0">{{ $t('customerManagements.NotAudit') }}</el-radio-button>
                  <!-- 已审核 -->
                  <el-radio-button label="1">{{ $t('customerManagements.Approved') }}</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row justify="space-between" type="flex">
            <el-col :span="12">
              <!-- 账号状态 -->
              <el-form-item :label="$t('customerManagements.AccStatus')" prop="lockState">
                <el-radio-group v-model="ruleForm.lockState" disabled>
                  <!-- 启用 -->
                  <el-radio-button label="0">{{ $t('customerManagements.enable') }}</el-radio-button>
                  <!-- 停用 -->
                  <el-radio-button label="1">{{ $t('customerManagements.Disable') }}</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 客户类型 -->
              <el-form-item :label="$t('customerManagements.customerType')" prop="name">
                <el-radio-group v-model="ruleForm.source" @change="changeSource">
                  <!-- 企业 -->
                  <el-radio-button label="corporation">{{ $t('customerManagements.enterprise') }}</el-radio-button>
                  <!-- 个人 -->
                  <el-radio-button label="person">{{ $t('customerManagements.personal') }}</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row justify="space-between" type="flex">
            <el-col :span="12">
              <!-- 积分 -->
              <el-form-item :label="$t('customerManagements.integral')" prop="integral">
                <el-input v-model="ruleForm.integral" :placeholder="$t('customerManagements.pleaseInput')" disabled />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 经办人 -->
              <el-form-item :label="$t('customerManagements.agent')" prop="integral">
                <el-select
                  v-model="ruleForm.handlerIdList"
                  :placeholder="$t('operationCenter.Only10AreDisplayed')"
                  clearable
                  filterable
                  multiple
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in userList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                  <!-- <el-option
                    v-for="item in managerList"
                    :key="item.id"
                    :label="item.realName"
                    :value="item.id"
                  /> -->
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="siteClass" justify="space-between" type="flex">
            <el-col :span="12">
              <!-- 站点 -->
              <el-form-item :label="$t('customerManagements.site')" prop="siteId">
                <el-select
                  v-model="ruleForm.siteId"
                  :disabled="readonly"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in siteIdList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 客户手机 -->
              <el-form-item :label="$t('customerManagements.CustomerPhone')" prop="customerPhone">
                <el-input
                  v-model="ruleForm.customerPhone"
                  :placeholder="$t('operationCenter.PleaseEnterTheContent')"
                  :readonly="readonly"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="siteClass">
            <el-col :span="12">
              <!-- 客户邮箱 -->
              <el-form-item :label="$t('customerManagements.CustomersEmail')" prop="customerEmail">
                <el-input
                  v-model="ruleForm.customerEmail"
                  :placeholder="$t('operationCenter.PleaseEnterTheContent')"
                  :readonly="readonly"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 包裹误差（KG） -->
              <el-form-item :label="$t('customerManagements.ParcelError')" prop="packageDeviation">
                <el-input
                  v-model="ruleForm.packageDeviation"
                  :placeholder="$t('operationCenter.PleaseEnterTheMaximumErrorValue')"
                  :readonly="readonly"
                  @blur="ruleForm.packageDeviation = ruleForm.packageDeviation
                    ? parseFloat(ruleForm.packageDeviation).toFixed(3)
                    : ''"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 报价方式 -->
              <el-form-item :label="$t('customerManagements.priceTerms')" prop="billingMethod">
                <el-select
                  v-model="ruleForm.billingMethod"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                >
                  <el-option :label="$t('customerManagements.oneParagraph')" :value="0" />
                  <el-option :label="$t('customerManagements.twoParagraph')" :value="1" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 使用材积计费 -->
              <el-form-item :label="$t('customerManagements.VolumeBilling')">
                <div class="volume">
                  <!-- 是否使用材积计费，（0：否；1：是） -->
                  <el-form-item label="" label-width="5px">
                    <el-checkbox v-model="ruleForm.useVolumeBilling" :false-label="0" :true-label="1" />
                  </el-form-item>

                  <!-- 计算方式(0:*,1:/) -->
                  <el-form-item label="" label-width="5px" prop="calculationMethod">
                    <el-select
                      v-model="ruleForm.calculationMethod"
                      :disabled="!ruleForm.useVolumeBilling"
                      :placeholder="$t('operationCenter.PleaseSelect')"
                      style="width: 100px"
                    >
                      <el-option :value="0" label="*" />
                      <el-option :value="1" label="/" />
                    </el-select>
                  </el-form-item>

                  <!-- 计算基数 -->
                  <el-form-item label="" label-width="5px" prop="calculateBase">
                    <el-input-number
                      v-model="ruleForm.calculateBase"
                      :controls="false"
                      :disabled="!ruleForm.useVolumeBilling"
                      :max="999999999"
                      :min="0"
                      :placeholder="$t('customerManagements.pleaseInput')"
                      :precision="2"
                    />
                  </el-form-item>
                </div>

              </el-form-item>
            </el-col>
          </el-row>
          <template v-if="ruleForm.source === 'corporation'">
            <!-- 企业 -->
            <el-divider>{{ $t('customerManagements.enterprise') }}</el-divider>

            <el-row justify="space-between" type="flex">
              <el-col :span="18">
                <!-- 公司名称 -->
                <el-form-item :label="$t('customerManagements.CompanyName')" prop="company">
                  <el-input
                    v-model.trim="ruleForm.company"
                    :placeholder="$t('customerManagements.pleaseEnterTheFullCompanyName')"
                    :readonly="readonly"
                    maxlength="30"
                    show-word-limit
                  />
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <div class="mt10">
                  <!-- 跳转到企查查 -->
                  <el-link href="https://www.qcc.com/" target="_blank" type="primary">
                    {{ $t('customerManagements.SkipToCompanyCheck') }}<i class="el-icon-view el-icon--right" /></el-link>
                </div>
              </el-col>
            </el-row>

            <el-row justify="space-between" type="flex">
              <el-col :span="12">
                <!-- 企业负责人 -->
                <el-form-item :label="$t('customerManagements.LegalRepresentive')" prop="coPrincipalName">
                  <el-input
                    v-model.trim="ruleForm.coPrincipalName"
                    :placeholder="$t('customerManagements.pleaseInput')"
                    :readonly="readonly"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <!-- 企业负责人手机 -->
                <el-form-item :label="$t('customerManagements.MobilePhoneOfCompanyOwner')" prop="coPrincipalPhone">
                  <el-input
                    v-model="ruleForm.coPrincipalPhone"
                    :placeholder="$t('customerManagements.pleaseInput')"
                    :readonly="readonly"
                    maxlength="20"
                    show-word-limit
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row justify="space-between" type="flex">
              <el-col :span="12">
                <!-- 企业负责人电话 -->
                <el-form-item :label="$t('customerManagements.HeadEnterprisePhone')" prop="coPrincipalTel">
                  <el-input
                    v-model.trim="ruleForm.coPrincipalTel"
                    :placeholder="$t('customerManagements.pleaseInput')"
                    :readonly="readonly"
                    maxlength="20"
                    show-word-limit
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <!-- 企业负责人邮箱 -->
                <el-form-item :label="$t('customerManagements.HeadEnterpriseEmail')" prop="coPrincipalEmail">
                  <el-input
                    v-model.trim="ruleForm.coPrincipalEmail"
                    :placeholder="$t('customerManagements.pleaseInput')"
                    :readonly="readonly"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row justify="space-between" type="flex">
              <el-col :span="12">
                <!-- 企业负责人QQ -->
                <el-form-item :label="$t('customerManagements.HeadEnterpriseQQ')" prop="coPrincipalQq">
                  <el-input
                    v-model.trim="ruleForm.coPrincipalQq"
                    :placeholder="$t('customerManagements.pleaseInput')"
                    :readonly="readonly"
                    maxlength="11"
                    show-word-limit
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 公司地址 -->
            <el-form-item :label="$t('customerManagements.companyAddress')" prop="address">
              <el-input
                v-model="ruleForm.address"
                :placeholder="$t('customerManagements.pleaseInput')"
                :readonly="readonly"
                maxlength="200"
                show-word-limit
              />
            </el-form-item>
            <!-- 统一信用社会代码 -->
            <el-form-item
              :key="ruleForm.source" :label="$t('customerManagements.UnifiedSocialCodeOfCredit')"
              prop="identityCard"
            >
              <el-input
                v-model="ruleForm.identityCard"
                :placeholder="$t('customerManagements.pleaseInput')"
                :readonly="readonly"
              />
            </el-form-item>
            <!-- CNPJ-母 -->
            <el-form-item :key="ruleForm.source" :label="$t('customerManagements.cnpjMother')" prop="cnpjMother">
              <el-input
                v-model="ruleForm.cnpjMother"
                :placeholder="$t('customerManagements.pleaseInput')"
                :readonly="readonly"
              />
            </el-form-item>
            <!-- CNPJ-子 -->
            <el-form-item :key="ruleForm.source" :label="$t('customerManagements.cnpjSon')" prop="cnpjSon">
              <el-input
                v-model="ruleForm.cnpjSon"
                :placeholder="$t('customerManagements.pleaseInput')"
                :readonly="readonly"
              />
            </el-form-item>
            <!-- 附件 -->
            <el-form-item :label="$t('customerManagements.enclosure')">
              <el-upload
                ref="upload"
                :accept="acceptStr"
                :before-upload="resumeBeforeAvatarUpload"
                :file-list="fileList"
                :http-request="resumeUpload"
                :limit="4"
                :multiple="false"
                :on-remove="removeFile"
                action
                class="avatar-uploader"
              >
                <!-- 点击上传 -->
                <el-button size="small" type="primary">{{ $t('customerManagements.clickUpload') }}</el-button>
                <!-- 可以上传图片，不超过5MB -->
                <div slot="tip" class="el-upload__tip">{{ $t('customerManagements.uploadPicturesNoMoreThan5MB') }}</div>
              </el-upload>
            </el-form-item>
            <el-form-item
              v-if="fileList.length > 0 || PDFFileList.length > 0"
              label="已上传附件"
            ><!-- 已上传附件 -->
              <div class="demo-image__preview">
                <!-- 图片 -->
                <div
                  v-for="(item,index) of fileList" :key="index" class="imglist"
                  style="display: inline-block"
                >
                  <img :src="item.url" alt="" style="width: 100px; height: 100px; margin-right: 10px">
                </div>
                <!-- PDF -->
                <div class="ullist">
                  <template v-for="item of PDFFileList">
                    <span :key="item.url" @click="handleSee(item.url)">{{ item.name }}</span>
                  </template>
                </div>
              </div>
            </el-form-item>
          </template>

          <template v-else>
            <!-- 个人 -->
            <el-divider>{{ $t('customerManagements.personal') }}</el-divider>
            <el-row justify="space-between" type="flex">
              <el-col :span="24">
                <!-- 地址 -->
                <el-form-item :label="$t('customerManagements.address')" prop="address">
                  <el-input
                    v-model="ruleForm.address"
                    :placeholder="$t('customerManagements.pleaseInput')"
                    :readonly="readonly"
                    maxlength="40"
                    show-word-limit
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 身份证号 -->
            <el-form-item :label="$t('customerManagements.IdNumber')" prop="identityCard">
              <el-input
                v-model.trim="ruleForm.identityCard"
                :placeholder="$t('customerManagements.pleaseInput')"
                :readonly="readonly"
                maxlength="18"
                show-word-limit
              />
            </el-form-item>
            <!-- 附件 -->
            <el-form-item :label="$t('customerManagements.enclosure')">
              <el-upload
                :accept="acceptStr"
                :before-upload="resumeBeforeAvatarUpload"
                :file-list="fileList"
                :http-request="resumeUpload"
                :limit="4"
                :multiple="false"
                :on-remove="resumeRemove"
                action
                class="avatar-uploader"
              >
                <!-- 点击上传 -->
                <el-button size="small" type="primary">{{ $t('customerManagements.clickUpload') }}</el-button>
                <!-- 可以上传图片及PDF文件，且不超过5MB -->
                <div slot="tip" class="el-upload__tip">{{
                  $t('customerManagements.uploadPicturesAndPDFFilesUpTo5MB')
                }}
                </div>
              </el-upload>
            </el-form-item>

            <el-form-item
              v-if="fileList.length > 0 || PDFFileList.length > 0"
              :label="$t('customerManagements.attachmentHasBeenUploaded')"
            ><!-- 已上传附件 -->
              <div class="demo-image__preview">
                <!-- 图片 -->
                <div
                  v-for="(item,index) of fileList" :key="index" class="imglist"
                  style="display: inline-block"
                >
                  <img :src="item.url" alt="" style="width: 100px; height: 100px; margin-right: 10px">
                </div>
                <!-- PDF -->
                <div class="ullist">
                  <template v-for="item of PDFFileList">
                    <span :key="item.url" @click="handleSee(item.url)">{{ item.name }}</span>
                  </template>
                </div>
              </div>
            </el-form-item>
          </template>
          <!-- 财务相关 -->
          <el-divider>{{ $t('customerManagements.FinancialRelated') }}</el-divider>
          <el-row justify="space-between" type="flex">
            <el-col :span="12">
              <!-- 财务联系人 -->
              <el-form-item :label="$t('customerManagements.FinancialContactPerson')" prop="financeContactsName">
                <el-input
                  v-model.trim="ruleForm.financeContactsName"
                  :placeholder="$t('customerManagements.pleaseInput')"
                  :readonly="readonly"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 财务联系人手机 -->
              <el-form-item :label="$t('customerManagements.FinancialContactPersonPhone')" prop="financeContactsPhone">
                <el-input
                  v-model.trim="ruleForm.financeContactsPhone"
                  :placeholder="$t('customerManagements.pleaseInput')"
                  :readonly="readonly"
                  maxlength="20"
                  show-word-limit
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row justify="space-between" type="flex">
            <el-col :span="12">
              <!-- 财务联系人电话 -->
              <el-form-item :label="$t('customerManagements.FinancialContactPersonTel')">
                <el-input
                  v-model.trim="ruleForm.financeContactsTel"
                  :placeholder="$t('customerManagements.pleaseInput')"
                  :readonly="readonly"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 财务联系人邮箱 -->
              <el-form-item :label="$t('customerManagements.FinancialContactPersonEmail')" prop="financeContactsEmail">
                <el-input
                  v-model.trim="ruleForm.financeContactsEmail"
                  :placeholder="$t('customerManagements.pleaseInput')"
                  :readonly="readonly"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row justify="space-between" type="flex">
            <el-col :span="12">
              <!-- 财务联系人QQ -->
              <el-form-item :label="$t('customerManagements.FinancialContactPersonQQ')" prop="financeContactsQq">
                <el-input
                  v-model.trim="ruleForm.financeContactsQq"
                  :placeholder="$t('customerManagements.pleaseInput')"
                  :readonly="readonly"
                  maxlength="11"
                  show-word-limit
                />
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 物流相关 -->
          <el-divider>{{ $t('customerManagements.LogisticsRelated') }}</el-divider>
          <el-row justify="space-between" type="flex">
            <el-col :span="12">
              <!-- 物流联系人 -->
              <el-form-item :label="$t('customerManagements.LogisticsContact')" prop="logisticsContactsName">
                <el-input
                  v-model.trim="ruleForm.logisticsContactsName"
                  :placeholder="$t('customerManagements.pleaseInput')"
                  :readonly="readonly"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 物流联系人手机 -->
              <el-form-item
                :label="$t('customerManagements.LogisticsContactPersonPhone')"
                prop="logisticsContactsPhone"
              >
                <el-input
                  v-model.trim="ruleForm.logisticsContactsPhone"
                  :placeholder="$t('customerManagements.pleaseInput')"
                  :readonly="readonly"
                  maxlength="20"
                  show-word-limit
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row justify="space-between" type="flex">
            <el-col :span="12">
              <!-- 物流联系人电话 -->
              <el-form-item :label="$t('customerManagements.LogisticsContactPersonTel')">
                <el-input
                  v-model.trim="ruleForm.logisticsContactsTel"
                  :placeholder="$t('customerManagements.pleaseInput')"
                  :readonly="readonly"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 物流联系人邮箱 -->
              <el-form-item
                :label="$t('customerManagements.LogisticsContactPersonEmail')"
                prop="logisticsContactsEmail"
              >
                <el-input
                  v-model.trim="ruleForm.logisticsContactsEmail"
                  :placeholder="$t('customerManagements.pleaseInput')"
                  :readonly="readonly"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row justify="space-between" type="flex">
            <el-col :span="12">
              <!-- 物流联系人QQ -->
              <el-form-item :label="$t('customerManagements.LogisticsContactPersonQQ')" prop="logisticsContactsQq">
                <el-input
                  v-model.trim="ruleForm.logisticsContactsQq"
                  :placeholder="$t('customerManagements.pleaseInput')"
                  :readonly="readonly"
                  maxlength="11"
                  show-word-limit
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item
            v-if="customerType.type === typeObj.add.code || customerType.type === typeObj.edit.code"
          >
            <el-row type="flex">
              <el-col :span="18" />
              <el-col :span="6">
                <!-- 提交 -->
                <el-button type="primary" @click="onSubmit('ruleForm')">{{
                  $t('customerManagements.submit')
                }}
                </el-button>
                <!-- 重置 -->
                <el-button @click="resetForm('ruleForm')">{{ $t('customerManagements.reset') }}</el-button>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <!-- 账户审核 -->
    <!-- 提示 -->
    <el-dialog
      :append-to-body="true"
      :title="$t('customerManagements.tips')"
      :visible.sync="dialog.examineVisible"
      width="30%"
    >
      <p class="dialog">
        <!-- 是否同意审核？ -->
        <span>{{ $t('customerManagements.IfAgreeAudit') }}</span>
        <!--  备注：审核通过后，该账户默认为                         停用                                          状态 -->
        <span>{{ $t('customerManagements.NoteDefaultAccount') }} <strong>{{
          $t('customerManagements.Disable')
        }}</strong> {{ $t('customerManagements.accountState') }}</span>
      </p>
      <span slot="footer" class="dialog-footer">
        <!-- 取消 -->
        <el-button @click="dialog.examineVisible = false">{{ $t('customerManagements.cancel') }}</el-button>
        <!-- 确定 -->
        <el-button type="primary" @click="setAuditState">{{ $t('customerManagements.confirm') }}</el-button>
      </span>
    </el-dialog>

    <!-- 账户启用/停用 -->
    <el-dialog
      :append-to-body="true"
      :title="$t('customerManagements.tips')"
      :visible.sync="dialog.lockVisible"
      width="30%"
    >
      <p class="dialog">
        <span>
          <!-- 确定对该账户进行 -->
          {{ $t('customerManagements.ConfirmOperationAccount') }}
          <!-- '启用' : '停用' -->
          <strong>{{
            dialog.obj.type == 0 ? $t('customerManagements.enable') : $t('customerManagements.Disable')
          }}</strong>
          {{ $t('customerManagements.operation') }}
          <!-- 操作？ -->
        </span>
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog.lockVisible = false">{{ $t('customerManagements.cancel') }}</el-button><!-- 取消 -->
        <el-button type="primary" @click="determineLockState">{{ $t('customerManagements.confirm') }}</el-button>
        <!-- 确定 -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { searchSiteIdList } from '@/api/lanshou';
import { emailReg } from '@/utils/reg_util';
import { uploadOos } from '@/utils/ali_oos_util';
import { TYPY_OBJ } from '@/enums/customerEnums';
import { imgTypeArr, pdfTypeArr } from '@/enums/evaluateEnums';
import { apiCustomerAdd, apiCustomerEdit, apiCustomerFindById, apiCustomerStateHandler } from '@/api/customer';
import { getRSA, strRSA } from '@/utils/RSA_util';
import { mapState } from 'vuex';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { cloneDeep } from 'lodash-es';
import md5 from 'js-md5';

export default {
  props: {
    ids: {
      type: [String, Number],
      default: ''
    },

    customerType: {
      type: Object,
      default() {
        return {
          id: '',
          type: 'add'
        };
      }
    }
  },
  computed: mapState({
    managerList: (state) => state.user.managerList
  }),
  data() {
    /**
     * 账户名校验
     * 防止出现重复用户名
     */
    const usernameCheck = (rule, value, callback) => {
      if (!value.length) {
        return callback(new Error(this.$t('customerManagements.accountNameNotEmpty'))); // '账户名称不能为空'
      }
      callback();
      if (this.ruleForm.id) {
        callback();
      }
    };

    // 密码后台暂时不做限制，只有前端校验
    const passwordCheck = (rule, value, callback) => {
      if (!value.length) {
        return callback(new Error(this.$t('customerManagements.notNull'))); // '密码不能为空'
      }
      // const reg = /^[\w]{8,12}$/;
      const reg = /(?=.*[0-9].*)(?=.*[A-Z].*)|(?=.*[0-9].*)(?=.*[a-z].*).{8,12}$/;
      if (!reg.test(value)) {
        return callback(new Error(this.$t('customerManagements.input8to12SumCode'))); // '请输入8-12位数字和英文组合'
      }
      this.pwd = md5(md5(value));
      callback();
    };
    // 包裹误差
    const priceType = (rule, value, callback) => {
      const reg = new RegExp(
        '(^[1-9]\\d*(\\.\\d{1,' +
        rule.suffix +
        '})?$)|(^0(\\.\\d{1,' +
        rule.suffix +
        '})?$)'
      );
      const { required } = rule;
      if (!required) {
        // 非必填
        if (value) {
          if (!reg.test(value)) {
            this.adopt = false;
            return callback(new Error(this.$t('customerManagements.IncorrectFormatting'))); // 格式不正确
          } else {
            this.adopt = true;
            return callback();
          }
        } else {
          return callback();
        }
      } else {
        if (value) {
          if (!reg.test(value)) {
            this.adopt = false;
            return callback(new Error(this.$t('customerManagements.IncorrectFormatting'))); // '格式不正确'
          } else {
            this.adopt = true;
            return callback();
          }
        } else {
          this.adopt = false;
          return callback(this.$t('customerManagements.notNull')); // '不能为空'
        }
      }
    };

    // 计算方式
    const calculationMethodCheck = (rule, value, callback) => {
      const { useVolumeBilling } = this.ruleForm;

      console.log('useVolumeBilling', useVolumeBilling);

      // 没有选中
      if (useVolumeBilling === 0 || useVolumeBilling === '') {
        return callback();
      }

      if (value === '') {
        return callback(new Error(this.$t('operationCenter.PleaseSelect'))); // '不能为空'
      }
      return callback();
    };

    // 计算基数
    const calculateBaseCheck = (rule, value, callback) => {
      const { useVolumeBilling } = this.ruleForm;

      // 没有选中
      if (useVolumeBilling === 0 || useVolumeBilling === '') {
        return callback();
      }

      if (!value) {
        return callback(new Error(this.$t('customerManagements.pleaseInput'))); // '不能为空'
      }
      return callback();
    };

    return {
      pwd: '', // 加密的密码
      disabled: false, // 是否禁用
      readonly: false, // 仅在查看时使用
      ruleForm: {
        id: '',
        source: 'corporation', // corporation:企业，person:个人
        customerAlias: '', // 账户别称
        company: '', // 公司名称
        coPrincipalName: '', // 企业负责人名字
        coPrincipalPhone: '', // 企业负责人手机电话
        coPrincipalTel: '', // 企业负责人电话
        coPrincipalEmail: '', // 企业负责人邮箱
        packageDeviation: '', // 包裹误差
        useVolumeBilling: 0, // 是否使用材积计费，（0：否；1：是）
        calculationMethod: '', // 计算方式(0:*,1:/)
        calculateBase: 0, // 计算基数
        coPrincipalQq: '', // 企业负责人QQ
        address: '', // 公司(个人)地址
        identityCard: '', // 营业执照或身份证号 '9144030059073074XQ'
        identityCardAttachment: '', // 证件照附件
        // parentId: '', // 上级id(客户公司的上下级关系)
        // customServiceUserId: '', // 客服id(oa系统中的用户id)
        financeContactsName: '', // 财务联系人名字
        financeContactsPhone: '', // 财务联系人手机
        financeContactsTel: '', // 财务联系人电话
        financeContactsEmail: '', // 财务联系人邮箱
        financeContactsQq: '', // 财务联系人qq
        logisticsContactsName: '', // 物流联系人名字
        logisticsContactsPhone: '', // 物流联系人手机
        logisticsContactsTel: '', // 物流联系人电话
        logisticsContactsEmail: '', // 物流联系人邮箱
        logisticsContactsQq: '', // 物流联系人QQ
        password: '', // 密码
        auditState: 0, // 账户审核，0：未审核，1：已审核
        billingMethod: 0, // 新增报价方式
        lockState: 1, // 账号锁定，0：正常，1：锁定
        integral: '0', // 积分
        // handlerId: '', // 经办人id
        handlerIdList: [], // 经办人id
        username: '', // 账户名称
        siteId: '', // 站点
        customerPhone: '', // 客户手机
        customerEmail: '', // 客户邮箱
        orderNumberType: '',
        cnpjMother: '',
        cnpjSon: ''
      },
      siteIdList: [], // 站点list
      rules: {
        cnpjMother: [
          { required: true, message: this.$t('customerManagements.inputFormat'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
              if (value === '') {
                return callback();
              }
              if (!cnpjRegex.test(value)) {
                return callback(new Error(this.$t('customerManagements.inputFormat'))); // '请输入客户邮箱'
              } else {
                callback();
              }
            }
          }
        ],
        cnpjSon: [
          { required: false, trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                return callback();
              }
              const cnpjListRegex = /^(?:\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})(?:,\s*\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})*$/;
              if (!cnpjListRegex.test(value)) {
                return callback(new Error(this.$t('customerManagements.inputFormat1'))); // '请输入客户邮箱'
              } else {
                callback();
              }
            }
          }
        ],
        // '请选择站点'
        billingMethod: [{
          required: true,
          message: this.$t('customerManagements.pleaseChoose'),
          trigger: ['blur', 'change']
        }],
        packageDeviation: [
          { required: false, suffix: 3, validator: priceType, trigger: ['blur', 'change'] }
        ],
        // '请选择站点'
        siteId: [{
          required: true,
          message: this.$t('customerManagements.pleaseCheckSite'),
          trigger: ['blur', 'change']
        }],
        customerPhone: [{
          required: true,
          message: this.$t('customerManagements.pleaseInput'),
          trigger: ['blur', 'change']
        }],
        customerEmail: [
          { required: true, message: this.$t('customerManagements.pleaseInput'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (!emailReg.test(value)) {
                return callback(new Error(this.$t('customerManagements.pleaseInput'))); // '请输入客户邮箱'
              } else {
                callback();
              }
            }
          }
        ],
        password: [{ required: true, validator: passwordCheck, trigger: ['blur', 'change'] }],
        username: [{ required: true, validator: usernameCheck, trigger: ['blur', 'change'] }],
        customerAlias: [{
          required: true,
          message: this.$t('customerManagements.pleaseInput'),
          trigger: ['blur', 'change']
        }],

        calculationMethod: [{ validator: calculationMethodCheck, trigger: 'change' }],

        calculateBase: [{ validator: calculateBaseCheck, trigger: 'blur' }]
      },

      acceptStr: '', // 上传附件类型

      fileList: [], // 上传

      // editFileList: [],
      imgFileList: [], // 图片
      PDFFileList: [], // PDF

      typeObj: TYPY_OBJ, // 当前状态

      dialog: {
        examineVisible: false,
        lockVisible: false,
        obj: {
          ids: '',
          type: 1, // 状态类型: 1:账号锁定状态，2.账户审核状态
          value: 0
        }
      },

      // 用户列表
      userList: []
    };
  },
  watch: {
    customerType: {
      handler(newVal) {
        this.resetRuleForm();
        // console.log('传进来的数据', newVal.type);
        if (newVal.type === 'edit' || newVal.type === 'examine' || newVal.type === 'set') {
          this.disabled = true;
        } else {
          this.disabled = false;
        }
        if (newVal.type === 'add') {
          this.fileList = []; // 清空文件列表
        }
        if (newVal.type === 'examine') {
          // readonly="true" 仅在查看时有效
          this.readonly = true;
        } else {
          this.readonly = false;
        }
        if (newVal.type === TYPY_OBJ.add.code) {
          // 新增
          return;
        }
        this.ruleForm.id = newVal.id;
        this.getCustimerData(newVal.id);
      },
      immediate: true,
      deep: true
    }
  },

  created() {
    this.acceptStr = [...imgTypeArr, ...pdfTypeArr].join(',');

    // console.log(getUserIdAndNameObj(), 'getUserIdAndNameObj');
    this.userList = Object.entries(getUserIdAndNameObj()).map(item => {
      const obj = {
        label: item[1],
        value: item[0]
      };
      return Object.freeze(obj);
    });
    // getUserIdAndNameObj().map(item => {
    //   const obj =
    // })

    searchSiteIdList().then(res => {
      if (res.status === 'OK') {
        res.data.forEach(data => {
          this.siteIdList.push({ id: data.id, name: data.name });
        });
      }
    });
  },

  methods: {
    // ...mapActions({ getManager: 'user/getManagerList' }),
    // 清空数据
    resetRuleForm() {
      for (const key in this.ruleForm) {
        this.ruleForm[key] = '';
      }
      this.ruleForm.source = 'corporation';
      this.ruleForm.auditState = 0;
      this.ruleForm.lockState = 1;
      this.ruleForm.integral = '0';
      this.ruleForm.billingMethod = 0; // 报价方式
    },
    changeSource() {
      this.ruleForm.identityCard = '';
    },
    /**
     * 获取指定客户数据
     */
    getCustimerData(ids) {
      apiCustomerFindById(ids)
        .then((res) => {
          if (res.status === 'OK') {
            const {
              id,
              source,
              company,
              coPrincipalName,
              coPrincipalPhone,
              packageDeviation,
              coPrincipalTel,
              coPrincipalEmail,
              coPrincipalQq,
              address,
              identityCard,
              identityCardAttachment,
              financeContactsName,
              financeContactsPhone,
              financeContactsTel,
              financeContactsEmail,
              financeContactsQq,
              logisticsContactsName,
              logisticsContactsPhone,
              logisticsContactsTel,
              logisticsContactsEmail,
              logisticsContactsQq,
              ftpUploadPath,
              username,
              siteId, // 站点
              customerPhone, // 客户手机
              customerEmail, // 客户邮箱
              customerAlias,
              auditState,
              lockState,
              integral,
              authorizationCode,
              handlerId,
              useVolumeBilling,
              calculationMethod,
              calculateBase,
              billingMethod, // 报价方式
              ftpHost, // ftp地址
              ftpUsername, // ftp账号
              ftpPassword, // ftp密码
              ftpPort, // ftp端口
              ftpPath, // ftp路径
              ftpType, // 连接类型
              orderNumberType,
              cnpjMother,
              cnpjSon
            } = res.data;
            let enclosureArr = [];
            if (identityCardAttachment != null) {
              if (identityCardAttachment.length) {
                enclosureArr = identityCardAttachment
                  .split(',')
                  .map((item) => {
                    return getRSA(item);
                  })
                  .map((item) => {
                    return this.getLastUploadArr(item);
                  });
              }
            }

            // this.editFileList = enclosureArr;
            {
              // 附件
              this.fileList = cloneDeep(enclosureArr);
              const { imgArr, pdfArr } = this.getImgAndPDFType(enclosureArr);
              this.imgFileList = imgArr;
              this.PDFFileList = pdfArr;
            }

            const obj = {
              id,
              source, // corporation:企业，person:个人
              company, // 公司名称
              coPrincipalName, // 企业负责人名字
              coPrincipalPhone, // 企业负责人手机电话
              coPrincipalTel, // 企业负责人电话
              coPrincipalEmail, // 企业负责人邮箱
              coPrincipalQq, // 企业负责人QQ
              address, // 公司(个人)地址
              identityCard, // 营业执照或身份证号
              packageDeviation, // 包裹误差
              identityCardAttachment, // 证件照附件
              // parentId: '', // 上级id(客户公司的上下级关系)
              // customServiceUserId: '', // 客服id(oa系统中的用户id)
              financeContactsName, // 财务联系人名字
              financeContactsPhone, // 财务联系人手机
              financeContactsTel, // 财务联系人电话
              financeContactsEmail, // 财务联系人邮箱
              financeContactsQq, // 财务联系人qq
              logisticsContactsName, // 物流联系人名字
              logisticsContactsPhone, // 物流联系人手机
              logisticsContactsTel, // 物流联系人电话
              logisticsContactsEmail, // 物流联系人邮箱
              logisticsContactsQq, // 物流联系人QQ
              ftpUploadPath,
              username, // 账户名称
              password: '', // 密码 不显示
              siteId, // 站点
              customerPhone, // 客户手机
              customerEmail, // 客户邮箱
              auditState,
              lockState,
              customerAlias,
              integral: integral || '0', // 积分，
              // handlerId: handlerId ? Number(handlerId) : '',
              handlerIdList: [],
              billingMethod, // 报价方式
              ftpHost, // ftp地址
              ftpUsername, // ftp账号
              ftpPassword, // ftp密码
              ftpPort, // ftp端口
              ftpPath, // ftp路径
              ftpType, // 连接类型
              orderNumberType,
              cnpjMother,
              cnpjSon
            };

            // 经办人
            if (handlerId) {
              obj.handlerIdList = handlerId.split(',');
            }

            // 材积计费处理
            if (useVolumeBilling === 1) {
              // 选中了
              obj.useVolumeBilling = useVolumeBilling;
              obj.calculationMethod = calculationMethod;
              obj.calculateBase = calculateBase;
            } else {
              obj.useVolumeBilling = 0;
              obj.calculationMethod = '';
              obj.calculateBase = 0;
            }

            Object.assign(this.ruleForm, obj);
            this.$emit('updataAuthorizationCode', authorizationCode || '', this.ruleForm);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // 区分图片和PDF
    getImgAndPDFType(arr) {
      const imgArr = [];
      const pdfArr = [];
      const pdfReg = /.pdf$/g;
      arr.forEach((item) => {
        if (pdfReg.test(item.url)) {
          pdfArr.push(item);
        } else {
          imgArr.push(item.url);
        }
      });
      return {
        imgArr,
        pdfArr
      };
    },

    // 提交
    onSubmit(formName) {
      let isValid = true;
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          this.$message({
            message: this.$t('customerManagements.PleaseFillRequiredFieldsCorrectly'), // '请正确填写必填项'
            type: 'warning'
          });
          isValid = false;
          return false;
        }
      });
      if (!isValid) {
        return;
      }

      const ruleForm = this.setData();

      // console.log(ruleForm);
      // return;

      if (this.ruleForm.id) {
        // 编辑
        // console.log('编辑需传入信息newRuleForm', newRuleForm);
        this.asyncUserEdit(ruleForm);
      } else {
        this.asyncUserAdd(ruleForm);
      }
    },

    setData() {
      const ruleForm = cloneDeep(this.ruleForm);

      // 因为字符串太长 rsa 分段加密
      ruleForm.identityCardAttachment = this.fileList
        .map((item) => {
          return strRSA(item.url);
        })
        .join(',');

      ruleForm.password = this.pwd;

      // 选择个人，清空企业数据
      if (ruleForm.source === 'person') {
        ruleForm.company = '';
        ruleForm.coPrincipalName = '';
        ruleForm.coPrincipalPhone = '';
        ruleForm.coPrincipalTel = '';
        ruleForm.coPrincipalEmail = '';
        ruleForm.coPrincipalQq = '';
      }

      // 如果没有勾选 使用材积计费，则不传字段
      if (ruleForm.useVolumeBilling === 0) {
        ruleForm.calculationMethod = undefined;
        ruleForm.calculateBase = undefined;
      }

      // 获取到当前选择的经办人id与名字
      // const row = this.userList.find(item => item.value === ruleForm.handlerId);
      // const handler = this.managerList.find((item) => item.id === ruleForm.handlerId);

      // 为空不传经办人
      // let newRuleForm;
      // if (handler) {
      //   newRuleForm = this.cleanType(
      //     JSON.parse(
      //       JSON.stringify({
      //         ...this.ruleForm,
      //         handlerName: handler.realName,
      //         handlerId: handler.id
      //       })
      //     )
      //   );
      // } else {
      //   // 删除经办人id字段
      //   this.$delete(this.ruleForm, 'handlerId');
      //   newRuleForm = this.cleanType(
      //     JSON.parse(
      //       JSON.stringify({
      //         ...this.ruleForm
      //       })
      //     )
      //   );
      //   // 再把经办人添加回来
      //   this.$set(this.ruleForm, 'handlerId', '');
      // }

      return ruleForm;
    },

    // 重置
    resetForm(formName) {
      // this.$refs[formName].resetFields();
      this.fileList = [];
      this.disabled = false;
      for (const key in this.ruleForm) {
        if (key === 'username' || key === 'integral' || key === 'id') {
          this.disabled = true;
        } else {
          this.ruleForm[key] = '';
        }
      }
      this.ruleForm.source = 'corporation';
      this.ruleForm.auditState = 0;
      this.ruleForm.lockState = 1;
      this.ruleForm.integral = '0';
      this.ruleForm.billingMethod = 0; // 报价方式
    },

    /**
     * 删除
     */
    resumeRemove(file, fileList) {
      console.log(file, fileList);
      this.fileList = fileList;
    },

    /**
     * 上传前的控制
     */
    resumeBeforeAvatarUpload(file) {
      const typeArr = [...imgTypeArr, ...pdfTypeArr];
      const { type, size } = file;
      // const isPDF = type === 'application/pdf';
      // console.log(1);
      const isType = typeArr.includes(type);

      const isLt5M = size / 1024 / 1024 < 5;
      if (!isType) {
        //                 上传只能是                                                        格式!
        this.$message.error(` ${this.$t('customerManagements.UploadOnlyBe')}${typeArr.join('/')}${this.$t('customerManagements.format')} `);
      }
      if (!isLt5M) {
        this.$message.error(this.$t('customerManagements.sizeNoMoreThan2MB')); // '上传大小不能超过 2MB!'
      }
      return isType && isLt5M;
    },

    /**
     * 上传
     */
    resumeUpload(option) {
      const { file } = option;
      uploadOos(file, 'customer')
        .then((res) => {
          if (res.code === 200) {
            const obj = {
              name: file.name,
              url: res.url
            };
            this.fileList = [...this.fileList, obj];
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          // this.$refs['excel-upload-input'].value = '';
        });
    },

    // 移除文件触发
    removeFile(file, fileList) {
      this.fileList = fileList;
    },

    /**
     * 新增
     */
    asyncUserAdd(obj) {
      apiCustomerAdd(obj)
        .then((res) => {
          if (res.status === 'OK') {
            this.$message({
              message: this.$t('customerManagements.AddedSuccessfully'), // '添加成功'
              type: 'success'
            });

            setTimeout(() => {
              this.$emit('updataData');
            }, 1500);
            this.ruleForm.password = '';
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    /**
     * 编辑
     */
    asyncUserEdit(obj) {
      // 根据需求将账户名称进行置空处理
      const newObj = cloneDeep(obj);
      newObj.username = '';
      newObj.password = '';

      apiCustomerEdit(obj)
        .then((res) => {
          if (res.status === 'OK') {
            this.$message({
              message: this.$t('customerManagements.ModificationSuccessful'), // '修改成功'
              type: 'success'
            });

            setTimeout(() => {
              this.$emit('updataData');
            }, 1500);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // 显示之前上传的附件
    getLastUploadArr(str) {
      if (str) {
        const newStr = decodeURI(str);
        const URLArr = newStr.split('/');
        const name = URLArr[URLArr.length - 1];
        return {
          name,
          url: str
        };
      }
    },

    /**
     * 选择企业就清空个人相关数据
     * 选择个人 就清空企业相关数据
     */
    cleanType(obj) {
      if (obj.source === 'person') {
        // 选择个人，清空企业数据
        obj.company = '';
        obj.coPrincipalName = '';
        obj.coPrincipalPhone = '';
        obj.coPrincipalTel = '';
        obj.coPrincipalEmail = '';
        obj.coPrincipalQq = '';
      }
      return obj;
    },

    /**
     * 账户审核通过
     */
    setAuditState() {
      this.dialog.obj.ids = this.ruleForm.id;
      this.dialog.obj.type = 2;
      this.dialog.obj.value = 1;
      this.asyncStateHandler(this.dialog.obj, this.$t('customerManagements.AccountVerificationSucceeded')); // '账号审核成功'
    },

    /**
     * 账户 启用/停用
     */
    setLockState(value) {
      this.dialog.lockVisible = true;
      this.dialog.obj.ids = this.ruleForm.id;
      this.dialog.obj.type = value;
      this.dialog.obj.value = value;
    },

    /**
     * 确定
     */
    determineLockState() {
      const { value } = this.dialog.obj;
      if (value === 1) {
        // 账户停用
        this.asyncStateHandler(this.dialog.obj, this.$t('customerManagements.accountHasBeenDeactivated')); // '该账号已停用'
      } else {
        // 账户启用
        this.asyncStateHandler(this.dialog.obj, this.$t('customerManagements.accountHasBeenEnabled')); // '该账号已启用'
      }
    },

    /**
     * 状态控制
     */
    asyncStateHandler(obj, message = '') {
      apiCustomerStateHandler(obj)
        .then((res) => {
          if (res.status === 'OK') {
            this.dialog.examineVisible = false;
            this.dialog.lockVisible = false;
            this.$message({
              message,
              type: 'success'
            });
            this.getCustimerData(this.ruleForm.id);

            setTimeout(() => {
              this.$emit('updataData', false);
            }, 1500);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // pdf 预览
    handleSee(url) {
      window.open(url);
    }
  }
};
</script>

<style scoped>

.siteClass ::v-deep .el-select {
  width: 100%;
}

.warpp {
  height: 85vh;
  overflow-y: scroll;
  width: 60vw;
  /* border: 1px solid red; */
}

.mt10 {
  margin-top: 10px;
}

.dialog {
  display: flex;
  flex-direction: column;
}

.dialog span:first-child {
  font-weight: bold;
  font-size: 16px;
}

.dialog span:last-child {
  margin-top: 15px;
  color: #909399;
}

.dialog span strong {
  color: #e6a23c;
}

.buts {
  /* width: 95%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid #000; */
  margin-bottom: 10px;
}

a.links {
  display: block;
}

.ullist {
  display: flex;
  flex-direction: column;
}

.ullist span {
  cursor: pointer;
  color: #409eff;
  font-size: 14px;
  font-weight: 500;
}

.volume {
  display: flex;
  /* border: 1px solid #000; */
}
</style>
