<template>
  <div>
    <div>
      <el-form label-position="top">
        <el-row :gutter="20">
          <el-col :span="4">
            <!-- 关联状态： -->
            <el-form-item :label="$t('GlobalSetObj.AssociationStatus')">
              <el-select v-model="queryForm.params.status" style="width: 100%" @change="handleStatus">
                <el-option key="0" :label="$t('GlobalSetObj.all')" value="" />
                <!--未关联-->
                <el-option key="1" :label="$t('GlobalSetObj.NotAssociated')" :value="2" />
                <!--已关联-->
                <el-option key="2" :label="$t('GlobalSetObj.Associated')" :value="1" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <!-- 网点状态： -->
            <el-form-item :label="$t('GlobalSetObj.PointStatus')">
              <el-select v-model="queryForm.params.siteStatus" style="width: 100%" @change="handleSiteStatus">
                <el-option key="0" :label="$t('GlobalSetObj.all')" value="" />
                <!--停用-->
                <el-option key="1" :label="$t('collectionCenter.Disable')" :value="0" />
                <!--启用-->
                <el-option key="2" :label="$t('collectionCenter.enable')" :value="1" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 网点名： -->
            <el-form-item :label="$t('newOrder.NetRollCall')">
              <el-input
                v-model.trim="queryForm.params.siteName"
                :placeholder="$t('CenterForSorting.pleaseInput')"
                clearable
                @keyup.enter.native="getSearchFun"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div style="margin-bottom: 20px;">
      <el-button
        size="mini"
        type="primary"
        @click="getSearchFun()"
      >{{ $t('basicData.Search') }}
      </el-button>
      <el-button
        size="mini"
        type="info"
        @click="resetForm()"
      >{{ $t('basicData.reset') }}
      </el-button>
    </div>
    <div>
      <!--  表格数据  -->
      <el-table
        ref="outletsTable"
        :data="tableData"
        border
        class="elTable"
        row-key="id"
        style="width: 100%"
      >
        <el-table-column
          v-for="(item, ind) in tableHeaderList"
          :key="ind + queryForm.pageNumber"
          :fixed="item.fixed"
          :label="item.label"
          :prop="item.prop"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="item.prop === 'siteStatus'">
              {{ scope.row.siteStatus === 0 ? $t('collectionCenter.Disable') : $t('collectionCenter.enable') }}
            </span>
            <span v-else-if="item.prop === 'pathStatus'">
              {{ scope.row.pathStatus == 1 ? $t('GlobalSetObj.Associated') : $t('GlobalSetObj.NotAssociated') }}
            </span>
            <span v-else>{{ scope.row[item.prop] || '- -' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('GlobalSetObj.operate')"
          align="center"
          fixed="right"
          width="120"
        >
          <template slot-scope="scope">
            <!--关联-->
            <el-button
              v-if="scope.row.pathStatus === 2"
              size="mini"
              type="text"
              @click="linkUnbind(0, scope.row)"
            >{{ $t('GlobalSetObj.relational') }}
            </el-button>
            <!--解绑-->
            <el-button
              v-if="scope.row.pathStatus === 1"
              size="mini"
              type="text"
              @click="linkUnbind(1, scope.row)"
            >{{ $t('GlobalSetObj.Untie') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--  分页  -->
      <div class="right">
        <el-pagination
          :current-page.sync="queryForm.pageNumber"
          :page-size.sync="queryForm.pageSize"
          :page-sizes="[10, 20, 50, 100, 500]"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          style="margin: 10px 0"
          @current-change="handleSizeChange"
          @size-change="handleCurrentChange"
        />
      </div>
    </div>
    <!--绑定路线-->
    <el-dialog
      :title="$t('GlobalSetObj.BindTheRoute')"
      :visible.sync="showBindRoute"
      width="500px"
      @close="bindRouteForm.bindRouteCode = ''"
    >
      <div>
        <!--请选择路线-->
        <el-form ref="bindRoute" :model="bindRouteForm" label-position="top">
          <el-form-item
            :label="$t('GlobalSetObj.PleaseSelectRoute')"
            :rules="[{ required: true, message: $t('GlobalSetObj.pleaseChoose'), trigger: ['blur','change'] }]"
            prop="bindRouteCode"
          >
            <el-select
              v-model="bindRouteForm.bindRouteCode"
              :placeholder="$t('GlobalSetObj.OnlyTheFirst10')"
              :remote-method="getRouteList"
              filterable
              remote
              reserve-keyword
              style="width: 100%;"
              @change="selectedRoute"
              @focus="getRouteList('')"
            >
              <el-option v-for="(item, ind) in routeList" :key="ind" :label="item.pathNumber" :value="item.id" />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showBindRoute = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button type="primary" @click="relevancePathData()">{{ $t('GlobalSetObj.confirm') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deliveryPathPage, getSitePath, relevancePath, updatePath } from '@/api/routerPlan';

export default {
  name: 'Outlets',
  data() {
    return {
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          siteName: '',
          status: 2,
          siteStatus: ''
        }
      },
      total: 0,
      tableData: [],
      tableHeaderList: [
        // 网点名
        { id: 1, label: this.$t('newOrder.NetRollCall'), prop: 'name' },
        // 网点状态
        { id: 2, label: this.$t('GlobalSetObj.PointStatus'), prop: 'siteStatus' },
        // 邮编
        { id: 2, label: this.$t('GlobalSetObj.zipCode'), prop: 'zipCode' },
        // 城市州
        { id: 2, label: this.$t('GlobalSetObj.CityState'), prop: 'cityState' },
        // 路线号
        { id: 3, label: this.$t('GlobalSetObj.RouteNumber'), prop: 'pathNumber' },
        // 状态
        { id: 4, label: this.$t('GlobalSetObj.status'), prop: 'pathStatus' }
      ],
      linkType: 0,
      linkData: {},
      showBindRoute: false,
      bindRouteForm: {
        bindRouteCode: ''
      },
      routeList: [],
      selectedRouteItem: {},
      siteId: ''
    };
  },
  watch: {
    showBindRoute: {
      handler: function(va) {
        this.$refs['bindRoute']?.resetFields();
      }
    }
  },
  created() {
    this.getSearchRouteTable();
  },
  methods: {
    // 切换关联状态
    handleStatus(val) {
      console.log(val, '切换关联状态');
      this.queryForm.params.status = val;
      this.queryForm.pageNumber = 1;
      this.getSearchRouteTable();
    },
    // 切换网点状态
    handleSiteStatus(val) {
      console.log(val, '切换网点状态');
      this.queryForm.params.siteStatus = val;
      this.queryForm.pageNumber = 1;
      this.getSearchRouteTable();
    },
    // 搜索
    getSearchFun() {
      this.queryForm.pageNumber = 1;
      this.getSearchRouteTable();
    },
    // 选中路线变化
    selectedRoute(data) {
      this.siteId = data;
      console.log(data, '选中路线变化');
      this.routeList.map(item => {
        if (data === item.pathNumber) {
          this.selectedRouteItem = item;
        }
      });
    },
    // 重置
    resetForm() {
      this.queryForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          siteName: '',
          siteStatus: '',
          status: ''
        }
      };
      this.getSearchRouteTable();
    },
    // 关联、解绑
    linkUnbind(type, data) {
      console.log(data, 'datadatadata');
      this.linkType = type;
      this.linkData = data;
      if (data.siteStatus) {
        if (type === 0) {
          this.showBindRoute = true;
        } else {
          this.$confirm(this.$t('GlobalSetObj.unbindingRoute'), this.$t('GlobalSetObj.UntieRoute'), {
            confirmButtonText: this.$t('GlobalSetObj.determine'), // '确定',
            cancelButtonText: this.$t('newOrder.cancel'), // '取消',
            type: 'warning'
          }).then(() => {
            updatePath({ siteId: data.id, pathNumber: data.pathNumber }).then(res => {
              if (res.status === 'OK') {
                this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded')); // '操作成功'
                this.getSearchRouteTable();
                this.$emit('siteTotal');
              }
            });
          });
        }
      } else {
        this.$message.error(this.$t('GlobalSetObj.dotStop'));
      }
    },
    // 确定关联
    relevancePathData() { // bindRoute
      this.$refs['bindRoute'].validate((valid) => {
        if (valid) {
          // if (this.bindRouteForm.bindRouteCode) {
          // this.$confirm(this.$t('GlobalSetObj.AreYouSureAssociate'), this.$t('GlobalSetObj.UntieRoute'), {
          //   confirmButtonText: this.$t('GlobalSetObj.determine'), // '确定',
          //   cancelButtonText: this.$t('newOrder.cancel'), // '取消',
          //   type: 'warning'
          // }).then(() => {
          const param = {
            routeId: this.siteId,
            pathNumber: this.selectedRouteItem.pathNumber,
            siteId: this.linkData.id,
            name: this.linkData.name,
            code: this.linkData.code,
            zipCode: this.linkData.zipCode,
            city: this.linkData.city,
            state: this.linkData.state,
            remark: this.selectedRouteItem.remark
          };
          relevancePath(param).then(res => {
            if (res.status === 'OK') {
              this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded')); // '操作成功'
              this.showBindRoute = false;
              this.getSearchRouteTable();
              this.$emit('siteTotal');
            }
          });
          // });
          // }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 路线查询getPathNumber
    getRouteList(val) {
      const param = {
        pageNumber: 1,
        pageSize: 100,
        params: {
          pathNumber: val
        }
      };
      deliveryPathPage(param).then(res => {
        if (res.status === 'OK') {
          console.log(res, '==res444');
          this.routeList = res.data.records;
        }
      });
    },
    // 查询列表
    getSearchRouteTable() {
      getSitePath(this.queryForm).then(res => {
        if (res.status === 'OK') {
          this.total = res.data.total;
          this.tableData = res.data.records;
          console.log(this.tableData, 'this.tableData');
        }
      });
    },
    // 点击分页查询站点
    handleSizeChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearchRouteTable();
    },
    handleCurrentChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getSearchRouteTable();
    }
  }
};
</script>

<style scoped>
.right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.selectColor {
  color: #409EFF
}

.cancelSelectColor {
  color: #666666
}
</style>
