var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "o-dropdown",
    {
      ref: "dropdown",
      staticClass: "o-container",
      attrs: { "aria-role": "list" },
      on: { "active-change": _vm.handleActive },
    },
    [
      _c(
        "o-button",
        {
          ref: "trigger",
          attrs: { slot: "trigger", variant: "ghost", size: "sm" },
          slot: "trigger",
        },
        [
          _c(
            "svg",
            {
              staticClass: "lucide lucide-more-horizontal",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "24",
                height: "24",
                viewBox: "0 0 24 24",
                fill: "none",
                stroke: "currentColor",
                "stroke-width": "2",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
              },
            },
            [
              _c("circle", { attrs: { cx: "12", cy: "12", r: "1" } }),
              _c("circle", { attrs: { cx: "19", cy: "12", r: "1" } }),
              _c("circle", { attrs: { cx: "5", cy: "12", r: "1" } }),
            ]
          ),
        ]
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }