var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          attrs: {
            "label-width": "auto",
            "label-position": "right",
            size: "mini",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("basicData.siteName") + "：" } },
                    [_c("span", [_vm._v(_vm._s(_vm.queryForm.name))])]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("basicData.UnsettledTotalAmount") + "：",
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.queryForm.unsettledAmount)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("basicData.TotalAmountReceived") + "：",
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.queryForm.receivedTotalAmount)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("basicData.BusinessTime") + "：" },
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "datetimerange",
                          "range-separator": _vm.$t("operationCenter.to"),
                          "start-placeholder": _vm.$t(
                            "operationCenter.startDate"
                          ),
                          "end-placeholder": _vm.$t("operationCenter.endDate"),
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.getDetailTableData()
                          },
                        },
                        model: {
                          value: _vm.queryDate,
                          callback: function ($$v) {
                            _vm.queryDate = $$v
                          },
                          expression: "queryDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 16 } }, [
                _c(
                  "div",
                  { staticStyle: { "text-align": "right" } },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permit",
                            rawName: "v-permit:remove",
                            value:
                              "but:finance:basicData:network:ExportAccountRollover",
                            expression:
                              "'but:finance:basicData:network:ExportAccountRollover'",
                            arg: "remove",
                          },
                        ],
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.exportAccount()
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("basicData.ExportAccountRollover")) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              ref: "elTable",
              attrs: {
                data: _vm.tableData,
                border: "",
                "row-key": "id",
                "tree-props": { children: "siteReceipts" },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "acceptTime",
                  label: _vm.$t("orderCenterCont.DateBusiness"),
                  align: "left",
                  width: "240",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.acceptTime))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "receiptNumber",
                  label: _vm.$t("basicData.BatchNo"),
                  width: "240",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.isParent
                          ? _c(
                              "span",
                              {
                                staticClass: "batchNumber",
                                on: {
                                  click: function ($event) {
                                    return _vm.showBillBatchNumber(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.receiptNumber))]
                            )
                          : _c("span", [
                              _vm._v(_vm._s(scope.row.receiptNumber)),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "actualFeeBrl",
                  label: _vm.$t("basicData.PaymentAmount") + "(BRL)",
                  align: "center",
                  width: "180",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.actualFeeBrl))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "acceptOperateTime",
                  label: _vm.$t("receivablePayable.CollectionTime"),
                  align: "left",
                  width: "160",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "billFee",
                  label: _vm.$t("basicData.TicketAmount") + "(BRL)",
                  align: "center",
                  width: "180",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "actualFee",
                  label: _vm.$t("basicData.amountPaymentOriginal"),
                  align: "center",
                  width: "120",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "currencyName",
                  label: _vm.$t("basicData.Currency"),
                  align: "center",
                  width: "120",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(scope.row.currencyName) +
                              "(" +
                              _vm._s(scope.row.currencyCode) +
                              ")"
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "exchangeRate",
                  label: _vm.$t("basicData.exchangeRate"),
                  align: "center",
                  width: "120",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "accountAlias",
                  label: _vm.$t("basicData.GoAccount"),
                  align: "center",
                  width: "180",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.accountAlias))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "receiptMethod",
                  label: _vm.$t("basicData.PaymentMethod"),
                  align: "center",
                  width: "180",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.receiptMethod === "cash"
                          ? _c("div", [
                              _vm._v(_vm._s(_vm.$t("basicData.cash"))),
                            ])
                          : _vm._e(),
                        scope.row.receiptMethod === "transfer"
                          ? _c("div", [
                              _vm._v(_vm._s(_vm.$t("basicData.transferable"))),
                            ])
                          : _vm._e(),
                        scope.row.receiptMethod === "network"
                          ? _c("div", [
                              _vm._v(_vm._s(_vm.$t("basicData.online"))),
                            ])
                          : _vm._e(),
                        scope.row.receiptMethod === "sterilisation"
                          ? _c("div", [
                              _vm._v(_vm._s(_vm.$t("basicData.Elimination"))),
                            ])
                          : _vm._e(),
                        scope.row.receiptMethod === "other"
                          ? _c("div", [
                              _vm._v(_vm._s(_vm.$t("basicData.other"))),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "attachmentUrl",
                  label: _vm.$t("basicData.annex"),
                  align: "center",
                  width: "200",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.attachmentUrl &&
                        scope.row.attachmentUrl.length
                          ? _c(
                              "el-link",
                              {
                                attrs: { type: "info" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getImgSrc(
                                      scope.row.attachmentUrl
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("basicData.View")))]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "remark",
                  label: _vm.$t("receivablePayable.remark"),
                  align: "left",
                  "min-width": "200",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", {
                          staticClass: "remarkStyle",
                          domProps: { innerHTML: _vm._s(scope.row.remark) },
                          on: {
                            click: function ($event) {
                              return _vm.showImg($event)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                staticStyle: { margin: "10px 0" },
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  "current-page": _vm.getTableForm.pageNumber,
                  "page-sizes": [10, 20, 50, 100],
                  "page-size": _vm.getTableForm.pageSize,
                  total: _vm.total,
                  background: "",
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.getTableForm, "pageNumber", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.getTableForm, "pageNumber", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.getTableForm, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.getTableForm, "pageSize", $event)
                  },
                  "current-change": _vm.getDetailTable,
                  "size-change": _vm.getDetailTable,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "imgs" },
        [
          _vm.imageViewerObj.showViewer
            ? _c("el-image-viewer", {
                attrs: {
                  "on-close": _vm.closeViewer,
                  "url-list": _vm.imageViewerObj.imgArr,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.$t("basicData.AccountDetails"),
            "append-to-body": true,
            visible: _vm.showDetailList,
            direction: "ltr",
            size: "90%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDetailList = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("batch-number-detail", {
                key: _vm.detailKeyList,
                attrs: { "detail-row": _vm.detailRow },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }