var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Box" }, [
    _c(
      "div",
      [
        _c(
          "el-table",
          {
            attrs: {
              border: "",
              data: _vm.importTableDate,
              "max-height": 600,
              "cell-style": _vm.styleFun,
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                label: _vm.$t("collectionCenter.ind"),
                type: "index",
                width: "60",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.$index + 1))])]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "customerName",
                align: "center",
                label: _vm.$t("collectionCenter.CustomerName"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "deliveryNumber",
                align: "center",
                width: "150",
                label: _vm.$t("collectionCenter.logisticsNum"),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.$index === _vm.changeCellIndex && _vm.hiddenInput
                        ? _c("el-input", {
                            attrs: { clearable: "" },
                            on: { blur: _vm.leaveInput },
                            model: {
                              value: scope.row.deliveryNumber,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "deliveryNumber", $$v)
                              },
                              expression: "scope.row.deliveryNumber",
                            },
                          })
                        : _c(
                            "span",
                            { class: scope.row.isSome ? "redColor" : "" },
                            [_vm._v(_vm._s(scope.row.deliveryNumber))]
                          ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "customerOrderNumber",
                align: "center",
                label: _vm.$t("collectionCenter.customerOrderNum"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "costWeight",
                align: "center",
                label: _vm.$t("collectionCenter.chargedWeight"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "distributionFees",
                align: "center",
                label: _vm.$t("collectionCenter.distributionCost"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "operatingFees",
                align: "center",
                label: _vm.$t("collectionCenter.operatingCost"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "exceptionalFees",
                align: "center",
                label: _vm.$t("collectionCenter.warehouseAnomalyCharge"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: _vm.$t("collectionCenter.operation"),
                fixed: "right",
                width: "160",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.costTypeCheck == false ||
                      scope.row.deliveryNumberCheck == false ||
                      scope.row.isSome
                        ? _c(
                            "span",
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: scope.row.duplicate != true,
                                      expression: "scope.row.duplicate != true",
                                    },
                                  ],
                                  attrs: {
                                    type: "primary",
                                    plain: "",
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editClick(
                                        scope.row,
                                        scope.$index
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("collectionCenter.modify"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-popconfirm",
                        {
                          attrs: {
                            title: _vm.$t("collectionCenter.deleteSure"),
                          },
                          on: {
                            confirm: function ($event) {
                              return _vm.delClick(scope.row, scope.$index)
                            },
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                slot: "reference",
                                type: "danger",
                                plain: "",
                                size: "mini",
                              },
                              slot: "reference",
                            },
                            [_vm._v(_vm._s(_vm.$t("collectionCenter.Delete")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "importSumbitClass" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.saveClick } },
          [_vm._v(_vm._s(_vm.$t("collectionCenter.Save")))]
        ),
        _c(
          "el-button",
          { attrs: { type: "info" }, on: { click: _vm.closeClick } },
          [_vm._v(_vm._s(_vm.$t("collectionCenter.cancel")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }