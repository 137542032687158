var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-bottom": "20px" } },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c("div", { staticClass: "today-total-operation-data-box" }, [
                    _c("div", [
                      _c(
                        "span",
                        { staticClass: "today-total-operation-data-title" },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("newOrder.TotalOperationsToday"))
                          ),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticStyle: { position: "relative" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { size: "mini" },
                            on: { change: _vm.orderTypeChange },
                            model: {
                              value: _vm.orderType,
                              callback: function ($$v) {
                                _vm.orderType = $$v
                              },
                              expression: "orderType",
                            },
                          },
                          _vm._l(_vm.options, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    _vm._l(_vm.topMetrics, function (item, index) {
                      return _c(
                        "el-col",
                        { key: index, attrs: { span: 4, md: 6, sm: 8, lg: 4 } },
                        [
                          _c("div", { staticClass: "metric-item" }, [
                            _c("div", { staticClass: "metric-box" }, [
                              _c("div", [
                                _c("div", { staticClass: "metric-title" }, [
                                  _vm._v(_vm._s(item.title)),
                                ]),
                                _c("div", { staticClass: "metric-value" }, [
                                  _vm._v(
                                    _vm._s(_vm._f("formatNumber")(item.value))
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "metric-change" }, [
                                _c("img", {
                                  attrs: {
                                    src:
                                      item.change > 0
                                        ? _vm.getImageUrl("todayGreaterThan")
                                        : item.change == 0
                                        ? _vm.getImageUrl("todayEquals")
                                        : _vm.getImageUrl("todayLessThan"),
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      display: "block",
                                      "text-align": "center",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatNumber")(
                                          item.change,
                                          true
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }