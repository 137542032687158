import { useQuery } from '@tanstack/vue-query';

import { getSkipPage } from '@/utils/getSkipPage';
import { uploadService } from '.';

export function useGetAllUploads(queryParams) {
  return useQuery({
    queryKey: ['uploads', queryParams],
    queryFn: async({ signal }) => {
      try {
        const { currentPage, perPage, numLote } = queryParams.value;
        const skip = getSkipPage(currentPage);
        const params = {
          skip,
          take: perPage,
          numLote
        };
        const res = await uploadService.getAll(params, signal);

        return res;
      } catch (error) {
        if (error.response && error.response.status === 400) {
          return [];
        }

        throw error;
      }
    },
    networkMode: 'always',
    refetchOnWindowFocus: false,
    retry: false,
    keepPreviousData: true
  });
}
