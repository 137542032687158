var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vehicleManagementBox" }, [
    _c(
      "div",
      { staticStyle: { "margin-bottom": "20px" } },
      [
        _c(
          "el-form",
          { staticClass: "selectClass" },
          [
            _c(
              "el-row",
              {
                staticClass: "selectClass",
                staticStyle: { "flex-wrap": "wrap" },
                attrs: { type: "flex", justify: "start" },
              },
              [
                _c(
                  "el-col",
                  { staticStyle: { width: "auto" }, attrs: { span: 10 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("collectionCenter.RegistrationTime"),
                        },
                      },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            clearable: false,
                            type: "datetimerange",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            "default-time": ["00:00:00", "23:59:59"],
                            "range-separator": _vm.$t("collectionCenter.to"),
                            "start-placeholder": _vm.$t(
                              "collectionCenter.StartTime"
                            ),
                            "end-placeholder": _vm.$t(
                              "collectionCenter.EndTime"
                            ),
                          },
                          on: { change: _vm.dateChange },
                          model: {
                            value: _vm.timeValue,
                            callback: function ($$v) {
                              _vm.timeValue = $$v
                            },
                            expression: "timeValue",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("collectionCenter.carT") } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: _vm.$t(
                                "collectionCenter.pleaseChoose"
                              ),
                              clearable: "",
                              filterable: "",
                              multiple: "",
                            },
                            model: {
                              value: _vm.queryForm.params.vehicleTypeList,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm.params,
                                  "vehicleTypeList",
                                  $$v
                                )
                              },
                              expression: "queryForm.params.vehicleTypeList",
                            },
                          },
                          _vm._l(_vm.vTypeList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.name, value: item.code },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: _vm.$t("collectionCenter.operationM") },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: _vm.$t(
                                "collectionCenter.pleaseChoose"
                              ),
                              clearable: "",
                              filterable: "",
                            },
                            model: {
                              value: _vm.queryForm.params.operationMode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm.params,
                                  "operationMode",
                                  $$v
                                )
                              },
                              expression: "queryForm.params.operationMode",
                            },
                          },
                          _vm._l(_vm.operationModeList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.name, value: item.code },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: _vm.$t("collectionCenter.belongDot") },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: _vm.$t(
                                "collectionCenter.pleaseChoose"
                              ),
                              clearable: "",
                              filterable: "",
                            },
                            model: {
                              value: _vm.queryForm.params.siteId,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryForm.params, "siteId", $$v)
                              },
                              expression: "queryForm.params.siteId",
                            },
                          },
                          _vm._l(_vm.siteList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("collectionCenter.driversLicenseType"),
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: _vm.$t(
                                "collectionCenter.pleaseChoose"
                              ),
                              multiple: "",
                            },
                            model: {
                              value:
                                _vm.queryForm.params.driversLicenseTypeList,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm.params,
                                  "driversLicenseTypeList",
                                  $$v
                                )
                              },
                              expression:
                                "queryForm.params.driversLicenseTypeList",
                            },
                          },
                          _vm._l(
                            _vm.driversLicenseTypeList,
                            function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.type, value: item.type },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("collectionCenter.licensePlateNum"),
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "BlockNewlineClass" },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                autosize: { maxRows: 5 },
                                placeholder: _vm.$t(
                                  "collectionCenter.BatchQuery"
                                ),
                              },
                              on: { blur: _vm.ft },
                              nativeOn: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.BlockNewline($event)
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.vehicleNumberList,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "vehicleNumberList",
                                    $$v
                                  )
                                },
                                expression:
                                  "queryForm.params.vehicleNumberList",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c(
              "el-col",
              { attrs: { span: 24 } },
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value:
                          "btn:collection-center:basicDate:vehicleManagement:search",
                        expression:
                          "'btn:collection-center:basicDate:vehicleManagement:search'",
                        arg: "remove",
                      },
                    ],
                    attrs: {
                      type: "primary",
                      size: "mini",
                      icon: "el-icon-search",
                    },
                    on: { click: _vm.searchClick },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.search")))]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value:
                          "btn:collection-center:basicDate:vehicleManagement:reset",
                        expression:
                          "'btn:collection-center:basicDate:vehicleManagement:reset'",
                        arg: "remove",
                      },
                    ],
                    attrs: { icon: "el-icon-refresh", size: "mini" },
                    on: { click: _vm.resetClick },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")))]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value:
                          "btn:collection-center:basicDate:vehicleManagement:add",
                        expression:
                          "'btn:collection-center:basicDate:vehicleManagement:add'",
                        arg: "remove",
                      },
                    ],
                    attrs: {
                      type: "primary",
                      icon: "el-icon-plus",
                      size: "mini",
                    },
                    on: { click: _vm.addClick },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.add")))]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value:
                          "btn:collection-center:basicDate:vehicleManagement:export",
                        expression:
                          "'btn:collection-center:basicDate:vehicleManagement:export'",
                        arg: "remove",
                      },
                    ],
                    class:
                      _vm.vehicleIdsList.length <= 0 && _vm.isClick
                        ? "onlyClickOnce10"
                        : "",
                    attrs: { icon: "el-icon-upload2", size: "mini" },
                    on: { click: _vm.download },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.export")))]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value:
                          "btn:collection-center:basicDate:vehicleManagement:allExport",
                        expression:
                          "'btn:collection-center:basicDate:vehicleManagement:allExport'",
                        arg: "remove",
                      },
                    ],
                    class: _vm.isClickAll ? "onlyClickOnce10" : "",
                    attrs: { icon: "el-icon-upload", size: "mini" },
                    on: { click: _vm.downloadAll },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")))]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("el-divider"),
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "el-table",
          {
            ref: "tableList",
            attrs: { "max-height": 600, border: "", data: _vm.tableDate },
            on: { select: _vm.batchSelect, "select-all": _vm.batchSelectAll },
          },
          [
            _c("el-table-column", {
              attrs: { type: "selection", width: "55" },
            }),
            _c("el-table-column", {
              attrs: {
                label: _vm.$t("collectionCenter.ind"),
                type: "index",
                width: "60",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            (_vm.queryForm.pageNumber - 1) *
                              _vm.queryForm.pageSize +
                              scope.$index +
                              1
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "id",
                label: _vm.$t("collectionCenter.carID"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "vehicleName",
                label: _vm.$t("collectionCenter.carName"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "vehicleNumber",
                label: _vm.$t("collectionCenter.licensePlateNumber"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "vehicleType",
                label: _vm.$t("collectionCenter.carType"),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.vehicleType == 0
                                ? "vuc"
                                : scope.row.vehicleType == 1
                                ? "3/4"
                                : scope.row.vehicleType == 3
                                ? "ToCo"
                                : scope.row.vehicleType == 4
                                ? "van"
                                : scope.row.vehicleType == 5
                                ? "Fiorino"
                                : scope.row.vehicleType == 6
                                ? "Truck"
                                : scope.row.vehicleType == 7
                                ? "Carreta"
                                : scope.row.vehicleType == 100
                                ? _vm.$t("collectionCenter.other")
                                : ""
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "vehicleServiceLife",
                label: _vm.$t("collectionCenter.vehicleYear"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "siteName",
                label: _vm.$t("collectionCenter.belongD"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "vehicleDriver",
                label: _vm.$t("collectionCenter.vehicleDriver"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "operationMode",
                label: _vm.$t("collectionCenter.operationMode"),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.operationMode == 0
                                ? _vm.$t("collectionCenter.directOperation")
                                : scope.row.operationMode == 1
                                ? _vm.$t(
                                    "collectionCenter.thirdPartyCooperation"
                                  )
                                : ""
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "itin",
                label: _vm.$t("collectionCenter.personalTaxNum"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "driversLicenseNumber",
                label: _vm.$t("collectionCenter.drivingLicenseNum"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "driversLicenseType",
                label: _vm.$t("collectionCenter.driversLicenseT"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "enabled",
                label: _vm.$t("collectionCenter.ifUse"),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.enabled == 0
                                ? _vm.$t("collectionCenter.enable")
                                : scope.row.enabled == 1
                                ? _vm.$t("collectionCenter.disable")
                                : ""
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "driversLicenseLimitedPeriod",
                label: _vm.$t("collectionCenter.driveValidT"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "driversLicenseIssuingAgency",
                label: _vm.$t("collectionCenter.driveIssuingA"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "vehicleVolume",
                label: _vm.$t("collectionCenter.Volume"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "vehiclePayload",
                label: _vm.$t("collectionCenter.Payload"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "vehicleGrossWeight",
                label: _vm.$t("collectionCenter.GrossWeight"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "vehicleCNPJ",
                label: _vm.$t("collectionCenter.CNPJ"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "vehicleTrailerPlates",
                label: _vm.$t("collectionCenter.trailerPlates"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "vehicleRegistration",
                label: _vm.$t("collectionCenter.vehicleStateRgtNumber"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "vehicleRNTRC",
                label: _vm.$t("collectionCenter.RNTRC"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "vehicleDocumentation",
                label: _vm.$t("collectionCenter.documentation"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "remark",
                label: _vm.$t("collectionCenter.Note"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "userName",
                label: _vm.$t("collectionCenter.registrant"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "createTime",
                label: _vm.$t("collectionCenter.RegistrationT"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "updateName",
                label: _vm.$t("collectionCenter.ModifiedBy"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "updateTime",
                label: _vm.$t("collectionCenter.ModificationTime"),
              },
            }),
            _vm.codeDownload || _vm.edit
              ? _c("el-table-column", {
                  attrs: {
                    fixed: "right",
                    align: "center",
                    width: "130",
                    prop: "collectSuccessRate",
                    label: _vm.$t("collectionCenter.operation"),
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "widthClass" },
                              [
                                _vm.codeDownload1
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.oneCodeDownloadClick(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "collectionCenter.codeDownload1"
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.codeDownload
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.codeDownloadClick(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "collectionCenter.codeDownload"
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.edit
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.putClick(scope.row)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("collectionCenter.modify")
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1835088906
                  ),
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "right" },
      [
        _c("el-pagination", {
          staticStyle: { margin: "10px 0" },
          attrs: {
            layout: "total, sizes, prev, pager, next, jumper",
            "current-page": _vm.queryForm.pageNumber,
            "page-sizes": [10, 20, 50, 100, 500, 1000],
            "page-size": _vm.queryForm.pageSize,
            total: _vm.total,
            background: "",
          },
          on: {
            "update:currentPage": function ($event) {
              return _vm.$set(_vm.queryForm, "pageNumber", $event)
            },
            "update:current-page": function ($event) {
              return _vm.$set(_vm.queryForm, "pageNumber", $event)
            },
            "update:pageSize": function ($event) {
              return _vm.$set(_vm.queryForm, "pageSize", $event)
            },
            "update:page-size": function ($event) {
              return _vm.$set(_vm.queryForm, "pageSize", $event)
            },
            "current-change": _vm.getDatePage,
            "size-change": _vm.getPageSize,
          },
        }),
      ],
      1
    ),
    _vm.drawerIsOpen
      ? _c(
          "div",
          { staticClass: "overflowClass" },
          [
            _c(
              "el-drawer",
              {
                attrs: {
                  title: _vm.drawerTitle,
                  visible: _vm.drawerIsOpen,
                  direction: _vm.direction,
                  size: "50%",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.drawerIsOpen = $event
                  },
                  close: _vm.closeReset,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "drawerClass" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "drawerFormRef",
                        attrs: { model: _vm.drawerForm, rules: _vm.rules },
                      },
                      [
                        _c(
                          "el-row",
                          { attrs: { type: "flex", justify: "space-between" } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "vehicleNumber",
                                      label: _vm.$t(
                                        "collectionCenter.licensePlateNum"
                                      ),
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        clearable: "",
                                        placeholder: _vm.$t(
                                          "collectionCenter.pleaseInput"
                                        ),
                                        disabled:
                                          _vm.drawerTitle ===
                                          _vm.$t(
                                            "collectionCenter.editVehicleRegistration"
                                          ),
                                      },
                                      model: {
                                        value: _vm.drawerForm.vehicleNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.drawerForm,
                                            "vehicleNumber",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "drawerForm.vehicleNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "siteId",
                                      label: _vm.$t(
                                        "collectionCenter.belongDot"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "collectionCenter.pleaseChoose"
                                          ),
                                          clearable: "",
                                          filterable: "",
                                        },
                                        model: {
                                          value: _vm.drawerForm.siteId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.drawerForm,
                                              "siteId",
                                              $$v
                                            )
                                          },
                                          expression: "drawerForm.siteId",
                                        },
                                      },
                                      _vm._l(
                                        _vm.siteList,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.name,
                                              value: item.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { type: "flex", justify: "space-between" } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "vehicleName",
                                      label: _vm.$t("collectionCenter.carN"),
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        clearable: "",
                                        placeholder: _vm.$t(
                                          "collectionCenter.pleaseInput"
                                        ),
                                      },
                                      model: {
                                        value: _vm.drawerForm.vehicleName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.drawerForm,
                                            "vehicleName",
                                            $$v
                                          )
                                        },
                                        expression: "drawerForm.vehicleName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "vehicleType",
                                      label: _vm.$t("collectionCenter.carT"),
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "collectionCenter.pleaseChoose"
                                          ),
                                          clearable: "",
                                          filterable: "",
                                        },
                                        model: {
                                          value: _vm.drawerForm.vehicleType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.drawerForm,
                                              "vehicleType",
                                              $$v
                                            )
                                          },
                                          expression: "drawerForm.vehicleType",
                                        },
                                      },
                                      _vm._l(
                                        _vm.vTypeList,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.name,
                                              value: item.code,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { type: "flex", justify: "space-between" } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "vehicleDriverId",
                                      label: _vm.$t(
                                        "collectionCenter.vehicleD"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "collectionCenter.pleaseChoose"
                                          ),
                                          clearable: "",
                                          filterable: "",
                                          multiple: "",
                                          "multiple-limit": 2,
                                        },
                                        on: { focus: _vm.getNewestScanUser },
                                        model: {
                                          value: _vm.drawerForm.vehicleDriverId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.drawerForm,
                                              "vehicleDriverId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "drawerForm.vehicleDriverId",
                                        },
                                      },
                                      _vm._l(
                                        _vm.allDriverList,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.realName,
                                              value:
                                                item.id + "/" + item.realName,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "operationMode",
                                      label: _vm.$t(
                                        "collectionCenter.operationM"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "collectionCenter.pleaseChoose"
                                          ),
                                          clearable: "",
                                          filterable: "",
                                        },
                                        model: {
                                          value: _vm.drawerForm.operationMode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.drawerForm,
                                              "operationMode",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "drawerForm.operationMode",
                                        },
                                      },
                                      _vm._l(
                                        _vm.operationModeList,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.name,
                                              value: item.code,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { type: "flex", justify: "space-between" } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "vehicleServiceLife",
                                      label: _vm.$t(
                                        "collectionCenter.vehicleLife"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "yaerClass" },
                                      [
                                        _c("el-date-picker", {
                                          attrs: {
                                            type: "year",
                                            placeholder: _vm.$t(
                                              "collectionCenter.pleaseChoose"
                                            ),
                                            "value-format": "yyyy",
                                          },
                                          model: {
                                            value:
                                              _vm.drawerForm.vehicleServiceLife,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.drawerForm,
                                                "vehicleServiceLife",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "drawerForm.vehicleServiceLife",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "itin",
                                      label: _vm.$t(
                                        "collectionCenter.personalTaxNumber"
                                      ),
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "11",
                                        "show-word-limit": "",
                                        placeholder: _vm.$t(
                                          "collectionCenter.pleaseInput"
                                        ),
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.drawerForm.itin,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.drawerForm,
                                            "itin",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "drawerForm.itin",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { type: "flex", justify: "space-between" } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "driversLicenseNumber",
                                      label: _vm.$t(
                                        "collectionCenter.drivingLicenseNumber"
                                      ),
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "11",
                                        "show-word-limit": "",
                                        placeholder: _vm.$t(
                                          "collectionCenter.pleaseInput"
                                        ),
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.drawerForm.driversLicenseNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.drawerForm,
                                            "driversLicenseNumber",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression:
                                          "drawerForm.driversLicenseNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "driversLicenseType",
                                      label: _vm.$t(
                                        "collectionCenter.driversLicenseType"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "collectionCenter.pleaseChoose"
                                          ),
                                          multiple: "",
                                        },
                                        model: {
                                          value:
                                            _vm.drawerForm.driversLicenseType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.drawerForm,
                                              "driversLicenseType",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "drawerForm.driversLicenseType",
                                        },
                                      },
                                      _vm._l(
                                        _vm.driversLicenseTypeList,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.type,
                                              value: item.type,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { type: "flex", justify: "space-between" } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "driversLicenseLimitedPeriod",
                                      label: _vm.$t(
                                        "collectionCenter.driveValidTime"
                                      ),
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      attrs: {
                                        type: "daterange",
                                        "value-format": "yyyy.MM.dd",
                                        "range-separator": _vm.$t(
                                          "collectionCenter.to"
                                        ),
                                        "start-placeholder": _vm.$t(
                                          "collectionCenter.StartTime"
                                        ),
                                        "end-placeholder": _vm.$t(
                                          "collectionCenter.EndTime"
                                        ),
                                      },
                                      model: {
                                        value:
                                          _vm.drawerForm
                                            .driversLicenseLimitedPeriod,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.drawerForm,
                                            "driversLicenseLimitedPeriod",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "drawerForm.driversLicenseLimitedPeriod",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "driversLicenseIssuingAgency",
                                      label: _vm.$t(
                                        "collectionCenter.driveIssuingAgency"
                                      ),
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "100",
                                        "show-word-limit": "",
                                        type: "textarea",
                                        placeholder: _vm.$t(
                                          "collectionCenter.pleaseInput"
                                        ),
                                      },
                                      model: {
                                        value:
                                          _vm.drawerForm
                                            .driversLicenseIssuingAgency,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.drawerForm,
                                            "driversLicenseIssuingAgency",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "drawerForm.driversLicenseIssuingAgency",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { type: "flex", justify: "space-between" } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "enabled",
                                      label: _vm.$t(
                                        "collectionCenter.ifEnabled"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "collectionCenter.pleaseChoose"
                                          ),
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.drawerForm.enabled,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.drawerForm,
                                              "enabled",
                                              $$v
                                            )
                                          },
                                          expression: "drawerForm.enabled",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: _vm.$t(
                                              "collectionCenter.enable"
                                            ),
                                            value: 0,
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: _vm.$t(
                                              "collectionCenter.disable"
                                            ),
                                            value: 1,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "vehicleVolume",
                                      label:
                                        _vm.$t("collectionCenter.Volume") +
                                        "：",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "7",
                                        "show-word-limit": "",
                                        clearable: "",
                                        placeholder: _vm.$t(
                                          "collectionCenter.pleaseInput"
                                        ),
                                      },
                                      model: {
                                        value: _vm.drawerForm.vehicleVolume,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.drawerForm,
                                            "vehicleVolume",
                                            $$v
                                          )
                                        },
                                        expression: "drawerForm.vehicleVolume",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { type: "flex", justify: "space-between" } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "vehiclePayload",
                                      label:
                                        _vm.$t("collectionCenter.Payload") +
                                        "：",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "6",
                                        "show-word-limit": "",
                                        clearable: "",
                                        placeholder: _vm.$t(
                                          "collectionCenter.pleaseInput"
                                        ),
                                      },
                                      model: {
                                        value: _vm.drawerForm.vehiclePayload,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.drawerForm,
                                            "vehiclePayload",
                                            $$v
                                          )
                                        },
                                        expression: "drawerForm.vehiclePayload",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "vehicleGrossWeight",
                                      label:
                                        _vm.$t("collectionCenter.GrossWeight") +
                                        "：",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "6",
                                        "show-word-limit": "",
                                        clearable: "",
                                        placeholder: _vm.$t(
                                          "collectionCenter.pleaseInput"
                                        ),
                                      },
                                      model: {
                                        value:
                                          _vm.drawerForm.vehicleGrossWeight,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.drawerForm,
                                            "vehicleGrossWeight",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "drawerForm.vehicleGrossWeight",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { type: "flex", justify: "space-between" } },
                          [
                            _c(
                              "el-col",
                              { staticClass: "overClass", attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "vehicleCNPJ",
                                      label:
                                        _vm.$t("collectionCenter.CNPJ") + "：",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        clearable: "",
                                        placeholder: _vm.$t(
                                          "collectionCenter.pleaseInput"
                                        ),
                                        maxlength: "60",
                                        "show-word-limit": "",
                                      },
                                      model: {
                                        value: _vm.drawerForm.vehicleCNPJ,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.drawerForm,
                                            "vehicleCNPJ",
                                            $$v
                                          )
                                        },
                                        expression: "drawerForm.vehicleCNPJ",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { staticClass: "overClass", attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "vehicleTrailerPlates",
                                      label:
                                        _vm.$t(
                                          "collectionCenter.trailerPlates"
                                        ) + "：",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        clearable: "",
                                        placeholder: _vm.$t(
                                          "collectionCenter.pleaseInput"
                                        ),
                                        maxlength: "60",
                                        "show-word-limit": "",
                                      },
                                      model: {
                                        value:
                                          _vm.drawerForm.vehicleTrailerPlates,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.drawerForm,
                                            "vehicleTrailerPlates",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "drawerForm.vehicleTrailerPlates",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { type: "flex", justify: "space-between" } },
                          [
                            _c(
                              "el-col",
                              { staticClass: "overClass", attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "vehicleRegistration",
                                      label:
                                        _vm.$t(
                                          "collectionCenter.vehicleStateRgtNumber"
                                        ) + "：",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        clearable: "",
                                        placeholder: _vm.$t(
                                          "collectionCenter.pleaseInput"
                                        ),
                                        maxlength: "60",
                                        "show-word-limit": "",
                                      },
                                      model: {
                                        value:
                                          _vm.drawerForm.vehicleRegistration,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.drawerForm,
                                            "vehicleRegistration",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "drawerForm.vehicleRegistration",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { staticClass: "overClass", attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "vehicleRNTRC",
                                      label:
                                        _vm.$t("collectionCenter.RNTRC") + "：",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        clearable: "",
                                        placeholder: _vm.$t(
                                          "collectionCenter.pleaseInput"
                                        ),
                                        maxlength: "60",
                                        "show-word-limit": "",
                                      },
                                      model: {
                                        value: _vm.drawerForm.vehicleRNTRC,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.drawerForm,
                                            "vehicleRNTRC",
                                            $$v
                                          )
                                        },
                                        expression: "drawerForm.vehicleRNTRC",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { type: "flex", justify: "space-between" } },
                          [
                            _c(
                              "el-col",
                              { staticClass: "overClass", attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "vehicleDocumentation",
                                      label:
                                        _vm.$t(
                                          "collectionCenter.documentation"
                                        ) + "：",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        clearable: "",
                                        placeholder: _vm.$t(
                                          "collectionCenter.pleaseInput"
                                        ),
                                        maxlength: "60",
                                        "show-word-limit": "",
                                      },
                                      model: {
                                        value:
                                          _vm.drawerForm.vehicleDocumentation,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.drawerForm,
                                            "vehicleDocumentation",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "drawerForm.vehicleDocumentation",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { staticClass: "noteClass", attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "remark",
                                      label: _vm.$t("collectionCenter.note"),
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        placeholder: _vm.$t(
                                          "collectionCenter.pleaseInput"
                                        ),
                                      },
                                      model: {
                                        value: _vm.drawerForm.remark,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.drawerForm,
                                            "remark",
                                            $$v
                                          )
                                        },
                                        expression: "drawerForm.remark",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 24 } }, [
                              _c(
                                "div",
                                { staticClass: "enclosureClass" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label:
                                          _vm.$t(
                                            "collectionCenter.driverLicenseAttachment"
                                          ) + "：",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "uploadClass" },
                                        [
                                          _c(
                                            "el-upload",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    !_vm.drawerForm
                                                      .driversLicenseAccessory,
                                                  expression:
                                                    "!drawerForm.driversLicenseAccessory",
                                                },
                                              ],
                                              ref: "demoUpload",
                                              staticClass: "upload-demo",
                                              attrs: {
                                                "before-upload":
                                                  _vm.beforeUpload,
                                                "http-request": _vm.upLoad,
                                                accept: "image/*",
                                                limit: 1,
                                                action: "",
                                                drag: "",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "el-upload__tip",
                                                  attrs: { slot: "tip" },
                                                  slot: "tip",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "collectionCenter.OnlyImageFormatIsSupported"
                                                      ) +
                                                        "," +
                                                        _vm.$t(
                                                          "collectionCenter.max1Files"
                                                        )
                                                    ) + " "
                                                  ),
                                                ]
                                              ),
                                              _c("i", {
                                                staticClass: "el-icon-plus",
                                                staticStyle: {
                                                  margin: "50px auto 20px",
                                                },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "el-upload__text",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "collectionCenter.cldrUpload"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.drawerForm.driversLicenseAccessory
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-image",
                                                {
                                                  staticStyle: {
                                                    width: "auto",
                                                    height: "200px",
                                                  },
                                                  attrs: {
                                                    src: _vm.drawerForm
                                                      .driversLicenseAccessory,
                                                    alt: "",
                                                    "preview-src-list": [
                                                      _vm.drawerForm
                                                        .driversLicenseAccessory,
                                                    ],
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "image-slot",
                                                      attrs: {
                                                        slot: "placeholder",
                                                      },
                                                      slot: "placeholder",
                                                    },
                                                    [
                                                      _c("p", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "collectionCenter.loading"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c("div", {
                                                staticClass:
                                                  "el-icon-delete-solid imgDelClass",
                                                on: { click: _vm.delImg },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "submitClickBtn" },
                      [
                        _c("el-button", { on: { click: _vm.registerClick } }, [
                          _vm._v(_vm._s(_vm.$t("collectionCenter.register"))),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }