var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "content", staticClass: "containerClass contentData" },
    [
      _c(
        "el-row",
        { staticStyle: { height: "100%" }, attrs: { gutter: 5 } },
        [
          _c(
            "el-col",
            { staticStyle: { height: "100%" }, attrs: { span: 7 } },
            [
              _c(
                "el-card",
                { staticStyle: { height: "100%" } },
                [
                  _c("div", { staticClass: "scan_Waybill" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("GlobalSetObj.ScanningWaybill")) + " "
                    ),
                    _c("span", { staticStyle: { float: "right" } }, [
                      _vm._v(_vm._s(_vm.equipmentNo)),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "scannerWeight",
                      staticStyle: { "margin-top": "10px" },
                    },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              (!_vm.isConnectWs && !_vm.connectStatus) ||
                              _vm.connectStatus,
                            expression:
                              "!isConnectWs&&!connectStatus||connectStatus",
                          },
                        ],
                        ref: "inputRef",
                        attrs: { disabled: _vm.isDisable },
                        on: { blur: _vm.blurFocus },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.saveStorageRecordNoenter(true, false)
                          },
                        },
                        model: {
                          value: _vm.packageListForm.params.waybillNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.packageListForm.params,
                              "waybillNumber",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "packageListForm.params.waybillNumber",
                        },
                      }),
                      _vm.isConnectWs
                        ? _c("h3", [
                            _vm._v(
                              _vm._s(_vm.packageListForm.params.waybillNumber)
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-top": "10px" } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "ruleForm",
                          staticClass: "demo-ruleForm",
                          attrs: {
                            model: _vm.ruleForm,
                            rules: _vm.rules,
                            "label-position": "top",
                            "label-width": "auto",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("GlobalSetObj.equipamento"),
                                prop: "equipmentBrand",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    id: "dropdown",
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.pleaseChoose"
                                    ),
                                    "popper-append-to-body": false,
                                  },
                                  on: { change: _vm.handleDeviceBrand },
                                  model: {
                                    value: _vm.ruleForm.equipmentBrand,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "equipmentBrand",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.equipmentBrand",
                                  },
                                },
                                _vm._l(_vm.DeviceBrandList, function (item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.id,
                                      attrs: {
                                        label: item.brandName,
                                        value: item.brandName,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticStyle: { width: "300px" } },
                                        [
                                          _c(
                                            "el-tooltip",
                                            { attrs: { placement: "left" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "content" },
                                                  slot: "content",
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass: "image",
                                                    staticStyle: {
                                                      width: "300px",
                                                      height: "300px",
                                                    },
                                                    attrs: {
                                                      src: item.pictureUrl,
                                                      alt: "",
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c("img", {
                                                staticClass: "image",
                                                staticStyle: {
                                                  width: "50px",
                                                  height: "50px",
                                                  float: "left",
                                                },
                                                attrs: {
                                                  src: item.pictureUrl,
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "20px",
                                                width: "50px",
                                                height: "50px",
                                              },
                                            },
                                            [_vm._v(_vm._s(item.brandName))]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", [
                    _c("div", { staticStyle: { "margin-bottom": "3px" } }, [
                      _vm._v(_vm._s(_vm.$t("operationCenter.Size")) + "（cm）"),
                    ]),
                    _c(
                      "div",
                      [
                        _c("el-input", {
                          staticStyle: {
                            width: "33%",
                            display: "inline-block",
                          },
                          model: {
                            value: _vm.ruleForm.length,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "length", $$v)
                            },
                            expression: "ruleForm.length",
                          },
                        }),
                        _c("el-input", {
                          staticStyle: {
                            width: "33%",
                            display: "inline-block",
                          },
                          model: {
                            value: _vm.ruleForm.width,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "width", $$v)
                            },
                            expression: "ruleForm.width",
                          },
                        }),
                        _c("el-input", {
                          staticStyle: {
                            width: "33%",
                            display: "inline-block",
                          },
                          model: {
                            value: _vm.ruleForm.height,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "height", $$v)
                            },
                            expression: "ruleForm.height",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticStyle: { "margin-top": "20px" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("orderCenterCont.Repetition")) +
                          "： " +
                          _vm._s(_vm.$t("GlobalSetObj.No")) +
                          " "
                      ),
                      _c("el-switch", {
                        staticStyle: { margin: "0 5px" },
                        attrs: {
                          disabled: _vm.isKuaiShow,
                          "active-color": "#13ce66",
                          "inactive-color": "#ff4949",
                        },
                        model: {
                          value: _vm.repetition,
                          callback: function ($$v) {
                            _vm.repetition = $$v
                          },
                          expression: "repetition",
                        },
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("GlobalSetObj.yes")) + " "),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.connectStatus && !_vm.isConnectWs,
                          expression: "!connectStatus&&!isConnectWs",
                        },
                      ],
                      staticStyle: { "margin-top": "100px" },
                    },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { span: 24 },
                        },
                        [
                          _c("div", { staticClass: "waringTip" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("GlobalSetObj.deviceNotConnected")
                                )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        { staticClass: "tip_Content", attrs: { span: 24 } },
                        [
                          _c("div", [
                            _vm._v(
                              " 1. " +
                                _vm._s(
                                  _vm.$t(
                                    "GlobalSetObj.PleaseConnectTheWeighingDeviceUSBPortToTheComputer"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("div", [
                            _vm._v(
                              " 2. " +
                                _vm._s(_vm.$t("GlobalSetObj.PleaseEnsure")) +
                                " "
                            ),
                            _c(
                              "a",
                              {
                                staticStyle: {
                                  "font-size": "14px",
                                  color: "#409eff",
                                },
                                attrs: {
                                  href: "https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/software/安骏桌面插件/安骏桌面插件.exe",
                                  type: "primary",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("GlobalSetObj.plugin")) + " "
                                ),
                              ]
                            ),
                            _vm._v(
                              " " + _vm._s(_vm.$t("GlobalSetObj.OnState")) + " "
                            ),
                          ]),
                          _c("div", [
                            _vm._v(
                              "3. " +
                                _vm._s(_vm.$t("GlobalSetObj.EquipmentMatching"))
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { staticStyle: { height: "100%" }, attrs: { span: 17 } },
            [
              _c(
                "el-card",
                {
                  staticStyle: {
                    height: "100%",
                    "overflow-y": "auto",
                    width: "100%",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "scan_Waybill",
                      staticStyle: { "margin-bottom": "10px" },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("GlobalSetObj.ScannerRecords"))),
                      ]),
                      _c("span", { staticStyle: { "margin-left": "25px" } }, [
                        _vm._v(
                          _vm._s(_vm.$t("GlobalSetObj.InStorage")) +
                            " " +
                            _vm._s(_vm.packageListForm.total)
                        ),
                      ]),
                      _c(
                        "el-button",
                        {
                          staticStyle: { float: "right" },
                          attrs: { size: "mini", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.exportData()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("operationCenter.exports")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "tableClass",
                      staticStyle: { width: "100%" },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "tableRef",
                          staticStyle: { width: "100%", height: "100%" },
                          attrs: {
                            data: _vm.tableData,
                            "header-cell-style": { background: "#f2f2f2" },
                            height: _vm.tableHeight,
                            "highlight-current-row": true,
                            "row-class-name": _vm.tableRowClassName,
                            border: "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.waybillNumber"),
                              prop: "waybillNumber",
                              width: "210",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("placeOrder.customerNumber"),
                              prop: "customerNumber",
                              width: "160",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.serviceLogistics"),
                              prop: "logisticsNumber",
                              width: "160",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.weigh") + "(kg)",
                              prop: "packageWeight",
                              width: "160",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.Comprimento"),
                              prop: "lengthWidthHeight",
                              width: "160",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.length
                                            ? scope.row.length
                                            : 0
                                        )
                                      ),
                                    ]),
                                    _vm._v("* "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.width ? scope.row.width : 0
                                        )
                                      ),
                                    ]),
                                    _vm._v("* "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.height
                                            ? scope.row.height
                                            : 0
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("operationCenter.volume"),
                              prop: "volume",
                              width: "160",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.operationTime"),
                              prop: "createTime",
                              width: "160",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.tipsObj.isPrompt,
                            expression: "tipsObj.isPrompt",
                          },
                        ],
                        class: _vm.promptClass,
                        style: { height: _vm.tipsObj.heightVal + "px" },
                      }),
                    ],
                    1
                  ),
                  _c("el-pagination", {
                    staticStyle: {
                      "margin-top": "10px",
                      float: "right",
                      "margin-bottom": "20px",
                    },
                    attrs: {
                      "current-page": _vm.packageListForm.pageNumber,
                      "page-size": _vm.packageListForm.pageSize,
                      "page-sizes": [10, 20, 50, 100],
                      "pager-count": 5,
                      total: _vm.packageListForm.total,
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "size-change": _vm.packageListSizeChange,
                      "current-change": _vm.packageListCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("GlobalSetObj.SetDevice"),
            visible: _vm.SetDeviceDialog,
            width: "25%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.SetDeviceDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-position": "top",
                    "label-width": "auto",
                  },
                },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { gutter: 10 },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("GlobalSetObj.equipamento"),
                                prop: "equipmentBrand",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    id: "dropdown",
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.pleaseChoose"
                                    ),
                                    "popper-append-to-body": false,
                                  },
                                  on: { change: _vm.handleDeviceBrand },
                                  model: {
                                    value: _vm.ruleForm.equipmentBrand,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "equipmentBrand",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.equipmentBrand",
                                  },
                                },
                                _vm._l(_vm.DeviceBrandList, function (item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.id,
                                      attrs: {
                                        label: item.brandName,
                                        value: item.brandName,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { float: "left" } },
                                        [
                                          _c(
                                            "el-tooltip",
                                            { attrs: { placement: "left" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "content" },
                                                  slot: "content",
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass: "image",
                                                    staticStyle: {
                                                      width: "300px",
                                                      height: "300px",
                                                    },
                                                    attrs: {
                                                      src: item.pictureUrl,
                                                      alt: "",
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c("img", {
                                                staticClass: "image",
                                                staticStyle: {
                                                  width: "50px",
                                                  height: "50px",
                                                },
                                                attrs: {
                                                  src: item.pictureUrl,
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-left": "20px",
                                            width: "50px",
                                            height: "50px",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.brandName))]
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.SetDeviceDialog = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.setupElectronicScales("ruleForm")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Salve")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
        },
        [
          _c("audio", {
            ref: "errRef",
            attrs: { src: _vm.errorMp3, controls: "controls" },
          }),
          _c("audio", {
            ref: "okRef",
            attrs: { src: _vm.okMp3, controls: "controls" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }