<template>
  <div class="Box">
    <el-tabs v-model="activePage" type="border-card">
      <el-tab-pane :label="$t('collectionCenter.summary')" name="hz">
        <el-form class="selectClass">
          <el-row type="flex" justify="start" style="flex-wrap: wrap;">
            <el-col :span="10" style="width: auto;">
              <!-- 稽查时间 -->
              <el-form-item :label="$t('collectionCenter.auditTime') + '：'">
                <el-date-picker
                  ref="dateRef"
                  v-model="queryDate"
                  :clearable="false"
                  type="datetimerange"
                  :default-time="['00:00:00', '23:59:59']"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :range-separator="$t('collectionCenter.to')"
                  :start-placeholder="$t('collectionCenter.StartTime')"
                  :end-placeholder="$t('collectionCenter.EndTime')"
                  @change="dateChange"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8" style="width: auto;">
              <!-- 稽查网点： -->
              <el-form-item :label="$t('collectionCenter.auditNetwork') + '：'">
                <el-select
                  v-model="queryForm.params.auditSiteId"
                  filterable
                  clearable
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  style="width: 100%"
                  remote
                  reserve-keyword
                  :remote-method="getSiteList"
                >
                  <el-option
                    v-for="item in siteIdList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" style="width: auto;">
              <!-- 稽查人： -->
              <el-form-item :label="$t('collectionCenter.auditor') + '：'">
                <el-select
                  v-model="queryForm.params.createUser"
                  clearable
                  filterable
                  :placeholder="$t('operationCenter.Only10AreDisplayed')"
                  remote
                  reserve-keyword
                  :remote-method="getScanUserId"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in customerList"
                    :key="item.id"
                    :label="item.realName"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" style="width: auto;">
              <el-form-item :label="$t('collectionCenter.auditResult') + '：'">
                <el-select
                  v-model="queryForm.params.auditStatus" :placeholder="$t('collectionCenter.pleaseChoose')" filterable
                  clearable
                >
                  <el-option :label="$t('collectionCenter.tudo')" :value="null" />
                  <el-option :label="$t('userManage.normal')" :value="0" />
                  <el-option :label="$t('newOperationCenter.abnormal')" :value="1" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" style="width: auto;">
              <el-form-item>
                <div style="display: flex; justify-content: start; align-items: start;">
                  <el-select
                    v-model="orderIndex" :placeholder="$t('collectionCenter.pleaseChoose')" filterable
                    class="autoWidth" @change="changeNum"
                  >
                    <template slot="prefix">
                      {{ (orderList.find(item => item.value === orderIndex) || {}).label }}
                    </template>
                    <el-option
                      v-for="(item, index) in orderList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                  <el-input
                    v-model="chooseNumber"
                    clearable
                    :placeholder="$t('collectionCenter.BatchQuery')"
                    type="textarea"
                    :rows="4"
                    @blur="inputNumber"
                  />
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-row>
          <el-col :span="24">
            <el-button
              v-permit:remove="'btn:collection-center:packet-scan:find'"
              type="primary"
              size="mini"
              icon="el-icon-search"
              @click="searchClick"
            >{{ $t('collectionCenter.search') }}</el-button>
            <!-- 查询 -->
            <el-button
              v-permit:remove="'btn:collection-center:packet-scan:reset'"
              icon="el-icon-refresh"
              size="mini"
              @click="resetClick"
            >{{ $t('collectionCenter.reset') }}</el-button>
            <!-- 重置 -->
            <el-button
              v-permit:remove="'btn:collection-center:packet-scan:exportAll'"
              icon="el-icon-upload2"
              size="mini"
              type="primary"
              :class="isClickHZ ? 'onlyClickOnce10' : ''"
              @click="downloadAll"
            >{{ $t('collectionCenter.allExport') }}</el-button>
            <!-- 全部导出 -->
          </el-col>
        </el-row>
        <el-divider />
        <el-table
          ref="tableRef"
          :max-height="600"
          border
          :data="tableDate"
        >
          <el-table-column
            type="selection"
            width="55"
          />
          <!-- 序号 -->
          <el-table-column
            :label="$t('collectionCenter.ind')" type="index" width="60"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="batchNumber"
            :label="$t('collectionCenter.auditBatchNumber')"
            align="center"
            width="110"
          />
          <el-table-column
            prop="number"
            :label="$t('collectionCenter.auditedBagOrBill')"
            align="center"
            width="120"
          />
          <el-table-column
            prop="auditTotalNum"
            :label="$t('collectionCenter.shouldAuditedTotal')"
            align="center"
            width="110"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.auditTotalNum == 0">{{ scope.row.auditTotalNum }}</span>
              <el-button v-else size="mini" @click="viewDetails(scope.row, 1, scope.row.auditTotalNum)">{{ scope.row.auditTotalNum }}</el-button>
            </template>
          </el-table-column>
          <el-table-column
            prop="actualAuditNum"
            :label="$t('collectionCenter.physicalAuditTotal')"
            align="center"
            width="130"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.actualAuditNum == 0">{{ scope.row.actualAuditNum }}</span>
              <el-button v-else size="mini" @click="viewDetails(scope.row, 2, scope.row.actualAuditNum)">{{ scope.row.actualAuditNum }}</el-button>
            </template>
          </el-table-column>
          <el-table-column
            prop="differenceNum"
            :label="$t('collectionCenter.discrepancyTotal')"
            align="center"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.differenceNum == 0">{{ scope.row.differenceNum }}</span>
              <el-button v-else size="mini" @click="viewDetails(scope.row, 3, scope.row.differenceNum)">{{ scope.row.differenceNum }}</el-button>
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            :label="$t('collectionCenter.auditTime')"
            align="center"
            width="110"
          />
          <el-table-column
            prop="auditSiteName"
            :label="$t('collectionCenter.auditNetwork')"
            align="center"
          />
          <el-table-column
            prop="createUserName"
            :label="$t('collectionCenter.auditor')"
            align="center"
          />
          <el-table-column
            prop="auditStatus"
            :label="$t('collectionCenter.auditResult')"
            align="center"
          >
            <!--  0 正常   1 异常-->
            <template slot-scope="scope">
              <div>{{ scope.row.auditStatus == 0 ? $t('userManage.normal') : scope.row.auditStatus == 1 ? $t('newOperationCenter.abnormal') : '' }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="result"
            :label="$t('collectionCenter.processingResult')"
            align="center"
          >
            <template slot-scope="scope">
              <el-tooltip placement="top" effect="dark">
                <div slot="content" style="width:300px;word-wrap: break-word;">{{ scope.row.result }}</div>
                <div class="paramsStyle">{{ scope.row.result }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="auditImg"
            :label="$t('collectionCenter.auditImg')"
            align="center"
          >
            <template slot-scope="scope">
              <el-popover
                :title="$t('collectionCenter.auditImg')"
                content=""
                placement="left-start"
                trigger="hover"
                width="400"
              >
                <img
                  v-show="scope.row.auditImg"
                  slot="reference"
                  :src="scope.row.auditImg"
                  alt=""
                  style="width: 50px;"
                >
                <div v-show="!scope.row.auditImg" slot="reference">{{ $t('collectionCenter.Null') }}</div><!-- 暂无 -->
                <img
                  v-if="scope.row.auditImg" :src="scope.row.auditImg" alt=""
                  style="width: 100%;height: 100%;"
                >
                <div v-else>{{ $t('collectionCenter.NoContentYet') }}</div><!-- 暂无内容 -->
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
            prop="remark"
            :label="$t('collectionCenter.Note')"
            align="center"
          >
            <template slot-scope="scope">
              <el-tooltip effect="dark" placement="top">
                <div slot="content">{{ scope.row.remark }}</div>
                <div class="paramsStyle">{{ scope.row.remark }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="updateUserName"
            :label="$t('collectionCenter.ModifiedBy')"
            align="center"
          />
          <el-table-column
            prop="updateTime"
            :label="$t('collectionCenter.ModificationTime')"
            align="center"
          />
          <el-table-column
            prop="collectSiteName"
            :label="$t('collectionCenter.LanShouSite')"
            align="center"
          />
          <el-table-column
            v-if="isEditBtn"
            :label="$t('collectionCenter.operation')"
            fixed="right"
            width="100"
            align="center"
          ><!-- 操作 -->
            <template slot-scope="scope">
              <div style="cursor: pointer;color: #26b3f1" @click="edit(scope.row)">{{ $t('collectionCenter.modify') }}</div>
            </template>
          </el-table-column>
        </el-table>
        <div class="right">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryForm.pageNumber"
            :page-sizes="[10, 20, 50, 100,500,1000]"
            :page-size.sync="queryForm.pageSize"
            :total="total"
            background
            style="margin: 10px 0"
            @current-change="getPageNum"
            @size-change="getPageSize"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane v-if="activePage == 'mx'" :label="$t('collectionCenter.particulars')" name="mx">
        <el-button
          v-permit:remove="'btn:collection-center:packet-scan:exportAll-mx'"
          icon="el-icon-upload2"
          size="mini"
          type="primary"
          :class="isClickMX ? 'onlyClickOnce10' : ''"
          @click="downloadAllMX"
        >{{ $t('collectionCenter.allExport') }}</el-button>
        <!-- 全部导出 -->
        <el-divider />
        <div class="overflowClass">
          <el-table
            ref="tableRef"
            :max-height="600"
            border
            style="width: 100%"
            :data="tableDateMX"
          >
            <!-- 序号 -->
            <el-table-column
              :label="$t('collectionCenter.ind')" type="index" width="60"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{ (queryFormMX.pageNumber - 1) * queryFormMX.pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="scanNumber"
              :label="$t('collectionCenter.pocketParcelNumber')"
              align="center"
            />
            <el-table-column
              prop="number"
              :label="$t('collectionCenter.auditedBagOrBill')"
              align="center"
              width="120"
            />
            <el-table-column
              prop="actualScanNumber"
              :label="$t('collectionCenter.actualAuditScanNumber')"
              align="center"
              width="140"
            />
            <el-table-column
              prop="collectStatusName"
              :label="$t('collectionCenter.OrderStatus')"
              align="center"
            />
            <el-table-column
              prop="auditScanTime"
              :label="$t('collectionCenter.checkScanningTime')"
              align="center"
            />
            <el-table-column
              prop="collectSiteName"
              :label="$t('collectionCenter.collectionDot')"
              align="center"
            />
            <el-table-column
              prop="collectUserName"
              :label="$t('collectionCenter.LanShouPeople')"
              align="center"
            />
            <el-table-column
              prop="collectTime"
              :label="$t('collectionCenter.lanshouT')"
              align="center"
            />
            <el-table-column
              prop="vehicleNumber"
              :label="$t('collectionCenter.licensePlateNumber')"
              align="center"
            />
            <el-table-column
              prop="loadUserName"
              :label="$t('collectionCenter.loader')"
              align="center"
            />
            <el-table-column
              prop="loadSiteName"
              :label="$t('customerManagements.loadSiteIdList')"
              align="center"
            />
            <el-table-column
              prop="loadTime"
              :label="$t('collectionCenter.loadTime')"
              align="center"
            />
            <el-table-column
              prop="unloadUserName"
              :label="$t('collectionCenter.unloader')"
              align="center"
            />
            <el-table-column
              prop="unloadTime"
              :label="$t('collectionCenter.unloadTime')"
              align="center"
            />
            <el-table-column
              prop="unloadSiteName"
              :label="$t('collectionCenter.unloadDot')"
              align="center"
            />
          </el-table>
        </div>
        <div class="right">
          <el-pagination
            :current-page.sync="queryFormMX.pageNumber"
            :page-size.sync="queryFormMX.pageSize"
            :page-sizes="[10, 20, 50, 100,500,1000]"
            :total="totalMX"
            background
            layout="total, sizes, prev, pager, next, jumper"
            style="margin: 10px 0"
            @current-change="getPageNumMX"
            @size-change="getPageSizeMX"
          />
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-dialog
      title=""
      :visible.sync="remarkVisible"
      width="30%"
      @close="remarkClose"
    >
      <span>{{ $t('collectionCenter.Note') }}：</span>
      <el-input v-model="editRemark.remark" clearable :placeholder="$t('collectionCenter.pleaseInput')" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="remarkClose">{{ $t('collectionCenter.cancel') }}</el-button>
        <el-button type="primary" @click="remarkSubmit">{{ $t('collectionCenter.confirm') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { queryWholeSite, transhipmentAuditPage, transhipmentAuditexportAll, transhipmentAuditEdit,
  transhipmentAuditDetailPage,
  transhipmentAuditDetailExportAll,
  queryRealName
} from '@/api/lanshou';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList.js';

export default {
  name: 'PacketQuery',
  data() {
    return {
      editRemark: {
        id: '',
        remark: ''
      },
      remarkVisible: false,
      isEditBtn: this.$store.getters.button_permissions.includes('btn:collection-center:packet-scan:edit'),
      queryDate: [],
      siteIdList: [],
      customerList: [],
      orderList: [
        { value: 0, label: this.$t('collectionCenter.Barcode') }, // 条形码
        { value: 1, label: this.$t('collectionCenter.BagNumber') }, // 袋号
        { value: 2, label: this.$t('collectionCenter.auditBatchNumber') } // 稽查批次号
      ],
      orderIndex: 0,
      chooseNumber: '',
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          beginTime: '',
          endTime: '',
          batchNumber: '', // 稽查批次号
          number: '', // 袋号
          createUser: '', // 稽查人
          auditSiteId: '',
          auditStatus: '' // 稽查 0 正常   1 异常
        }
      },
      total: 0,
      tableDate: [],
      queryFormMX: {},
      totalMX: 0,
      tableDateMX: [],
      activePage: 'hz',
      isClickHZ: false,
      isClickMX: false
    };
  },
  created() {
    this.activePage = 'hz';
    this.isClickHZ = false;
    this.isClickMX = false;
    this.default();
    this.searchClick();
    this.getSiteList();
    this.getScanUserId();
  },
  methods: {
    remarkClose() {
      this.remarkVisible = false;
      this.editRemark = {
        id: '',
        remark: ''
      };
    },
    getSiteList(val) {
      const value = val || '';
      queryWholeSite(value).then(res => {
        if (res.status === 'OK') {
          this.siteIdList = [];
          res.data.forEach(data => {
            this.siteIdList.push({ id: data.id, name: data.name });
          });
        }
      });
    },
    getScanUserId(name) {
      const nameVal = name || '';
      queryRealName(nameVal).then(res => {
        if (res.status === 'OK') {
          this.customerList = res.data;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    changeNum() {
      this.chooseNumber = '';
      this.queryForm.params.number = '';
      this.queryForm.params.batchNumber = '';
    },
    ft() {
      const val = this.queryForm.params.vehicleNumberList.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = numLen.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      this.queryForm.params.vehicleNumberList = bghTrim.join('\n');
    },
    inputNumber() {
      const val = this.chooseNumber.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = numLen.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      this.chooseNumber = bghTrim.join('\n');

      // 0 条形码    1 袋号   2 稽查批次号
      this.queryForm.params.number = '';
      this.queryForm.params.batchNumber = '';
      if (this.orderIndex === 0 || this.orderIndex === 1) {
        this.queryForm.params.number = this.chooseNumber;
      }
      if (this.orderIndex === 2) {
        this.queryForm.params.batchNumber = this.chooseNumber;
      }
    },
    default() {
      this.orderIndex = 0;
      this.chooseNumber = '';
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.queryDate = [today + ' 00:00:00', today + ' 23:59:59'];
      this.queryForm.params = {
        beginTime: today + ' 00:00:00',
        endTime: today + ' 23:59:59',
        batchNumber: '',
        number: '',
        createUser: '',
        auditSiteId: '',
        auditStatus: ''
      };
    },
    dateChange(val) {
      if (val === null) {
        this.queryDate = [];
        this.queryForm.params.beginTime = '';
        this.queryForm.params.endTime = '';
        return;
      }
      this.queryForm.params.beginTime = val[0];
      this.queryForm.params.endTime = val[1];
      if (val[1]) {
        const endTime = dayjs(val[0]).subtract(-1, 'month').format('YYYY-MM-DD') + ' 23:59:59';
        if (val[1] > endTime) {
          this.$message({
            // '只允许查1个月内的数据'
            message: this.$t('collectionCenter.OnlyAllowedOneMonthsOfData'),
            type: 'warning'
          });
          const d = new Date();
          const today = dayjs(d).format('YYYY-MM-DD');
          this.queryDate = [today + ' 00:00:00', today + ' 23:59:59'];
          this.queryForm.params.beginTime = today + ' 00:00:00';
          this.queryForm.params.endTime = today + ' 23:59:59';
        }
      }
    },
    getPageNum() {
      const queryForm = JSON.parse(localStorage.getItem('packetQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      transhipmentAuditPage(queryForm).then(res => {
        if (res.status === 'OK') {
          this.total = res.data.total;
          this.tableDate = res.data.records;
        }
      });
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      const query = JSON.parse(localStorage.getItem('packetQueryForm')) || this.queryForm;
      const queryForm = {
        pageNumber: 1,
        pageSize: this.queryForm.pageSize,
        params: query.params
      };
      transhipmentAuditPage(queryForm).then(res => {
        if (res.status === 'OK') {
          this.total = res.data.total;
          this.tableDate = res.data.records;
        }
      });
    },
    searchClick() {
      if (!this.queryForm.params.batchNumber && !this.queryForm.params.beginTime && !this.queryForm.params.endTime) {
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
        return;
      }
      if (this.queryForm.params.batchNumber) {
        this.queryDate = [];
        this.queryForm.params.beginTime = '';
        this.queryForm.params.endTime = '';
        this.queryForm.params.number = '';
        this.queryForm.params.createUser = '';
        this.queryForm.params.auditSiteId = '';
        this.queryForm.params.auditStatus = '';
      }
      this.queryForm.pageNumber = 1;
      localStorage.setItem('packetQueryForm', JSON.stringify(this.queryForm));
      transhipmentAuditPage(this.queryForm).then(res => {
        if (res.status === 'OK') {
          this.total = res.data.total;
          this.tableDate = res.data.records;
        }
      });
    },
    resetClick() {
      this.default();
      this.searchClick();
    },
    downloadAll() {
      this.isClickHZ = true;
      const queryForm = JSON.parse(localStorage.getItem('packetQueryForm')) || this.queryForm;
      transhipmentAuditexportAll(queryForm.params).then(res => {
        this.isClickHZ = false;
        goExportList(this);
      });
    },
    edit(row) {
      this.remarkVisible = true;
      this.editRemark = {
        id: row.id,
        remark: row.remark
      };
    },
    remarkSubmit() {
      transhipmentAuditEdit(this.editRemark).then(res => {
        if (res.status === 'OK') {
          this.remarkVisible = false;
          this.searchClick();
        }
      });
    },

    /**
     * 明细
     */
    viewDetails(row, type, val) {
      if (val === 0) return;
      this.queryFormMX = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          auditId: row.id,
          detailType: type
        }
      };
      this.queryFormMX.pageNumber = 1;
      this.getPageNumMX();
    },
    getPageNumMX() {
      transhipmentAuditDetailPage(this.queryFormMX).then(res => {
        if (res.status === 'OK') {
          this.activePage = 'mx';
          this.totalMX = res.data.total;
          this.tableDateMX = res.data.records;
        }
      });
    },
    getPageSizeMX() {
      transhipmentAuditDetailPage(this.queryFormMX).then(res => {
        if (res.status === 'OK') {
          this.totalMX = res.data.total;
          this.tableDateMX = res.data.records;
        }
      });
    },
    downloadAllMX() {
      this.isClickMX = true;
      const queryFormMX = {
        params: this.queryFormMX.params
      };
      transhipmentAuditDetailExportAll(queryFormMX).then(res => {
        this.isClickMX = false;
        const h = this.$createElement;
        this.$msgbox({
          // 提示
          title: this.$t('collectionCenter.tips'),
          message: h('p', null, [
            // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源任务列表中下载，'
            h('span', this.$t('collectionCenter.exportChangeTip')),
            // 请勿重复导出!
            h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
          ]),
          showCancelButton: true,
          // 立即前往
          confirmButtonText: this.$t('collectionCenter.Immediately'),
          // 关闭
          cancelButtonText: this.$t('collectionCenter.close')
        }).then(action => {
          if (action === 'confirm') {
            this.$router.push({ path: '/resource/exportTaskList' });
          }
        }).catch(() => {});
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.overflowClass{
  overflow-y: auto;
}
.Box{
  padding: 20px;
  box-sizing: border-box;
}
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.selectClass ::v-deep .el-form-item{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.selectClass ::v-deep .el-form-item__label{
  padding: 0;
  line-height: 16px;
  white-space: nowrap;
}
.selectClass ::v-deep .el-form-item__content{
  margin-left: 0 !important;
  width: 100%;
  margin-right: 20px;
}
.selectClass ::v-deep textarea.el-textarea__inner {
  width: 300px;
}

.col2 {
  width: auto !important;
}

.autoWidth ::v-deep .el-input__prefix { // 下拉框自适应占位
  position: relative;
  left: 0;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  padding: 0 25px;
  padding-left: 0;
  margin-left: 20px;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  visibility: hidden;
  white-space: nowrap;
}

.autoWidth ::v-deep input {
  position: absolute;
}

.autoWidth ::v-deep.el-input--suffix .el-input__inner{
  border: none !important;
  font-size: 14px;
  color: #606266;
  font-weight: bold;
  text-align: right;
  padding-left: 0;
  box-sizing: border-box;
}

// 防止按钮重复点击
@keyframes throttle{
  from{
    filter: opacity(40%);
    pointer-events: none;
  }
  to{
    pointer-events: all;
  }
}
.onlyClickOnce10{
  animation: throttle 10s step-end forwards;
}
.onlyClickOnce10:active{
  filter: opacity(40%);
  animation: none;
}

.paramsStyle {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

</style>
