import request from '@/utils/request';

// 理赔账单

// 分页
export const apiClaimBillPage = data => {
  return request.post('/fa/claimBill/page', data);
};

// 付款
export const apiClaimBillPayTodo = data => {
  return request.post('/fa/claimBill/payTodo', data);
};

// 账单抵扣
export const apiClaimBillDeduction = data => {
  return request.post('/fa/claimBill/deduction', data);
};

