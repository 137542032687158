// 工单

// 状态
const WorkCentreStatus = {
  close: {
    zh: '关闭',
    en: 'close',
    pu: 'fechar'
    // remark: {
    //   code: 'closed',
    //   remark: '关闭',
    //   color: 'info'
    // }
  },
  implementing: {
    zh: '实施中',
    en: 'In implementation',
    pu: 'em andamento'
    // remark: {
    //   code: 'implementing',
    //   remark: '实施中',
    //   color: 'success'
    // }
  },
  complete: {
    zh: '完成',
    en: 'complete',
    pu: 'Terminar'
    // remark: {
    //   code: 'over',
    //   remark: '完成',
    //   color: ''
    // }
  }
};

// 优先级
const WorkCentrePriority = {
  urgent: {
    zh: '紧急',
    en: 'urgent',
    pu: 'urgente'
  },
  high: {
    zh: '高',
    en: 'high',
    pu: 'alto'
  },
  normal: {
    zh: '正常',
    en: 'normal',
    pu: 'normal'
  },
  low: {
    zh: '低',
    en: 'low',
    pu: 'baixo'
  }
};

// 跟进状态
const FollowUpStatus = {
  NormalFollowUp: {
    zh: '正常跟进',
    en: 'Normal follow-up',
    pu: 'Seguimento normal'
  },
  FinishTask: {
    zh: '完结任务',
    en: 'Finish task',
    pu: 'Acabar a tarefa'
  },
  VoidTask: {
    zh: '作废任务',
    en: 'Void task',
    pu: 'Tarefa anulada'
  }
};

// 工单——微工单
const WorkCentreTable = {
  tips: {
    zh: '提示',
    en: 'tips',
    pu: 'Dica'
  },
  system: {
    zh: '系统',
    en: '',
    pu: 'sistema'
  },
  PleaseEnterName: {
    zh: '请输入名称',
    en: '',
    pu: 'Por favor, digite o nome'
  },
  PleaseSelectType: {
    zh: '请选择类型',
    en: '',
    pu: 'Favor selecionar o tipo'
  },
  inputPrompt: {
    zh: '请输入内容',
    en: '',
    pu: 'Por favor digite conteúdo'
  },
  PleaseSelectTime: {
    zh: '请选择日期时间',
    en: '',
    pu: 'Favor selecionar uma data e hora'
  },
  pleaseInput: {
    zh: '请输入',
    en: 'Please enter the content',
    pu: 'por favor digitar'
  },
  newAddSuc: {
    zh: '新增成功',
    en: '',
    pu: 'adicionar com sucesso'
  },
  AddedSuccessfully: {
    zh: '添加成功',
    en: 'Added successfully',
    pu: 'Adicionado com sucesso'
  },
  empty: {
    zh: '清空',
    en: 'empty',
    pu: 'Limpar'
  },
  SubmittedOn: {
    zh: '提交于',
    en: 'Submitted on',
    pu: 'submetido em'
  },
  PleaseEnterFollowUpContent: {
    zh: '请输入跟进内容',
    en: '',
    pu: 'Por favor, insira conteúdo de acompanhamento'
  },
  PleaseSelectFollowUpStatus: {
    zh: '请选择跟进状态',
    en: '',
    pu: 'Favor selecionar o status de acompanhamento'
  },
  UndefinedState: {
    zh: '未定义状态',
    en: '',
    pu: 'Status indefinido'
  },
  MicroWorkOrder: {
    zh: '微工单',
    en: 'Micro work order',
    pu: 'Ordem de microtrabalho'
  },
  index: {
    zh: '序号',
    en: 'index',
    pu: 'Número de série'
  },
  ticketType: {
    zh: '类型',
    en: 'ticketType',
    pu: 'tipo'
  },
  ticketName: {
    zh: '任务主题',
    en: 'ticketName',
    pu: 'título de tarefa'
  },
  sort: {
    zh: '优先级',
    en: 'sort',
    pu: 'prioridade'
  },
  ticketStatus: {
    zh: '状态',
    en: 'ticketStatus',
    pu: 'Status'
  },
  timeLeft: {
    zh: '剩余时间',
    en: 'timeLeft',
    pu: 'prazo restante'
  },
  lastFollowerName: {
    zh: '最后跟进人',
    en: 'lastFollowerName',
    pu: 'Última pessoa que acompanha'
  },
  lastFollowTime: {
    zh: '最后跟进时间',
    en: 'lastFollowTime',
    pu: 'Última hora de acompanhamento'
  },
  createTime: {
    zh: '创建时间',
    en: 'createTime',
    pu: 'hora de criação'
  },
  CompletionTime: {
    zh: '完成时间',
    en: 'Completion time',
    pu: 'data de conclusão '
  },
  planFinishTime: {
    zh: '期望完成时间',
    en: 'planFinishTime',
    pu: 'data de conclusão esperado'
  },
  createUserName: {
    zh: '创建人',
    en: 'createUserName',
    pu: 'criador'
  },
  executeUserName: {
    zh: '执行人',
    en: 'executeUserName',
    pu: 'Executor'
  },
  FollowUpPerson: {
    zh: '跟进人',
    en: 'Follow up person',
    pu: 'acompanhado por'
  },
  ticketDescribe: {
    zh: '内容',
    en: 'ticketDescribe',
    pu: 'conteúdo'
  },
  ContentTranslation: {
    zh: '内容翻译',
    en: 'Content translation',
    pu: 'Tradução de conteúdo'
  },
  enclosure: {
    zh: '附件',
    en: 'enclosure',
    pu: 'anexo'
  },
  whole: {
    zh: '全部',
    en: 'whole',
    pu: 'inteiro'
  },

  all: {
    zh: '全部工单',
    en: 'All',
    pu: 'Todas as tarefas'
  },

  ICreated: {
    zh: '我创建的',
    en: 'I created',
    pu: 'criado por mim'
  },

  IDid: {
    zh: '我执行的',
    en: 'I did',
    pu: 'eu fiz'
  },

  selection: {
    zh: '选取',
    en: 'selection',
    pu: 'seleção'
  },

  upload: {
    zh: '上传',
    en: 'upload',
    pu: 'enviar'
  },

  add: {
    zh: '新增',
    en: 'add',
    pu: 'adicionar'
  },

  details: {
    zh: '详情',
    en: 'details',
    pu: 'detalhes'
  },

  FollowUpRecord: {
    zh: '跟进记录',
    en: 'Follow up record',
    pu: 'Recordo de acompanhamento'
  },

  followUp: {
    zh: '跟进',
    en: 'follow-up',
    pu: 'acompanhamento'
  },

  cancel: {
    zh: '取消',
    en: 'cancel',
    pu: 'cancelar'
  },
  confirm: {
    zh: '确定',
    en: 'determine',
    pu: 'determinar'
  },
  pleaseUploadPictures: {
    zh: '请上传图片',
    en: '',
    pu: 'Carregue uma imagem'
  },
  FollowUpStatus: {
    zh: '跟进状态',
    en: 'Follow up status',
    pu: 'Estado de acompanhamento'
  },

  // 暂无内容
  NoContent: {
    zh: '暂无内容',
    en: 'No content',
    pu: 'Sem conteúdo'
  },

  // 上传图片
  UploadPictures: {
    zh: '上传图片',
    en: 'Upload pictures',
    pu: 'Tirar foto'
  },

  // 东八区
  DongbaDistrict: {
    zh: '时间为东八区',
    en: 'The time is Dongba district',
    pu: 'O tempo é o distrito de Dongba'
  },
  // 天
  Day: {
    zh: '天',
    en: 'Day',
    pu: 'dia'
  },

  // 小时
  Hour: {
    zh: '小时',
    en: 'Hour',
    pu: 'Hora'
  },

  TimedOut: {
    zh: '已超时',
    en: 'Timed out',
    pu: 'Tempo limite expirou'
  },

  // 工单状态
  ...WorkCentreStatus,

  // 优先级
  ...WorkCentrePriority,

  // 跟进状态
  ...FollowUpStatus
};

// 优先级

export {
  WorkCentreTable
};
