var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "warp",
      staticStyle: {
        padding: "0 20px",
        height: "calc(100vh - 80px)",
        overflow: "auto",
      },
    },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.exportToExcel },
            },
            [_vm._v(_vm._s(_vm.$t("newOrder.export")))]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            "header-cell-style": { background: "#f2f2f2", color: "black" },
            border: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("GlobalSetObj.serial"),
              align: "center",
              type: "index",
              width: "80",
            },
          }),
          _vm._l(_vm.tableHeaderList, function (item) {
            return _c("el-table-column", {
              key: item.id,
              attrs: {
                label: item.label,
                "min-width": item.cloWidth,
                prop: item.prop,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              scope.row[item.prop]
                                ? "" + scope.row[item.prop]
                                : "--"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticStyle: { "text-align": "right" } },
        [
          _c("el-pagination", {
            staticStyle: { margin: "15px 0" },
            attrs: {
              "current-page": _vm.searchForm.pageNumber,
              "page-size": _vm.searchForm.pageSize,
              "page-sizes": [10, 20, 50, 100],
              total: _vm.total,
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.searchForm, "pageNumber", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.searchForm, "pageNumber", $event)
              },
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.searchForm, "pageSize", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.searchForm, "pageSize", $event)
              },
              "current-change": _vm.getSearch,
              "size-change": _vm.getSearch,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }