<template>
  <div class="app-container">
    <!--  表格数据  -->
    <el-table
      ref="multipleTable"
      :cell-style="{textAlign:'center'}"
      :data="tableData"
      :header-cell-style="{background:'#f2f2f2',color:'black',textAlign:'center'}"
      border
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="55"
      />
      <el-table-column :label="$t('GlobalSetObj.serial')" type="index" width="70" />
      <el-table-column
        v-for="item in tableHeaderList"
        :key="item.id"
        :label="item.label"
        :min-width="item.cloWidth"
        :prop="item.prop"
      >
        <template slot-scope="scope">
          <div v-if="item.prop === 'requestParams'">
            <el-popover
              placement="top"
              popper-class="copy"
              trigger="hover"
            >
              <el-button
                style="color: black;font-size: 12px;padding: 2px"
                type="text"
                @click="copyFun(scope.row.requestParams)"
              >
                {{ $t('orderDetails.copy') }}
              </el-button>
              <span slot="reference" class="omit">{{ scope.row.requestParams }}</span>
            </el-popover>
          </div>
          <div v-else-if="item.prop === 'responseParams'">
            <el-popover
              placement="top"
              popper-class="copy"
              trigger="hover"
            >
              <el-button
                style="color: black;font-size: 12px;padding: 2px"
                type="text"
                @click="copyFun(scope.row.responseParams)"
              >
                {{ $t('orderDetails.copy') }}
              </el-button>
              <span slot="reference" class="omit">{{ scope.row.responseParams }}</span>
            </el-popover>
          </div>
          <div v-else-if="item.prop === 'requestAddress'">
            <el-popover
              placement="top"
              popper-class="copy"
              trigger="hover"
            >
              <el-button
                style="color: black;font-size: 12px;padding: 2px"
                type="text"
                @click="copyFun(scope.row.requestAddress)"
              >
                {{ $t('orderDetails.copy') }}
              </el-button>
              <span slot="reference" class="omit">{{ scope.row.requestAddress }}</span>
            </el-popover>
          </div>
          <div v-else>{{ scope.row[item.prop] ? `${scope.row[item.prop]}` : '--' }}</div>
        </template>
      </el-table-column>
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="paramsData.pageNumber"
      :page-size="paramsData.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="paramsData.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { trackPushLogPage } from '@/api/orderApi';
import { copyData } from '@/utils/copyData';

export default {
  name: 'MultipleSignRecords',
  props: {
    paramsData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tableData: [],
      tableHeaderList: [
        // 运单号
        { id: 2, label: this.$t('newOrder.waybillNumber'), prop: 'waybillNumber', cloWidth: '180' },
        // 扫描单号
        { id: 3, label: this.$t('GlobalSetObj.ScanNumber'), prop: 'scanOrderNumber', cloWidth: '180' },
        // 客户名称
        { id: 5, label: this.$t('orderCenterCont.CustomerName'), prop: 'platformCode', cloWidth: '170' },
        // 最新推送时间
        { id: 1, label: this.$t('orderCenterCont.newPushTime'), prop: 'updateTime', cloWidth: '170' },
        // 推送失败节点
        { id: 6, label: this.$t('orderCenterCont.pushFailedNode'), prop: 'trackNode', cloWidth: '170' },
        // 轨迹内容
        { id: 7, label: this.$t('orderCenterCont.trajectoryContent'), prop: 'trackContext', cloWidth: '180' },
        // 接收方URL
        { id: 8, label: this.$t('orderCenterCont.receiverURL'), prop: 'requestAddress', cloWidth: '180' },
        // 请求报文
        { id: 9, label: this.$t('orderCenterCont.RequestMessages'), prop: 'requestParams', cloWidth: '180' },
        // 响应报文
        { id: 10, label: this.$t('orderCenterCont.ResponseMessages'), prop: 'responseParams', cloWidth: '180' },
        // 最终状态
        { id: 11, label: this.$t('orderCenterCont.final'), prop: 'pushStatus', cloWidth: '180' }
      ],
      selectData: []
    };
  },
  created() {
    this.getSearch();
  },
  methods: {
    // 勾选表格
    handleSelectionChange(val) {
      console.log(val, 'LKLKJ');
      this.selectData = val;
    },
    getSelectData() {
      return this.selectData;
    },
    clearSelect() {
      this.$refs.multipleTable.clearSelection();
    },
    // 查询
    getSearch() {
      this.$nextTick(() => {
        console.log(this.paramsData, 'paramsData');
        console.log('父组件');
        trackPushLogPage(this.paramsData).then(res => {
          if (res.status === 'OK') {
            this.paramsData.total = res.data.total;
            this.tableData = res.data.records;
            this.tableData.forEach(item => {
              if (item.pushStatus == '1') {
                item.pushStatus = this.$t('GlobalSetObj.succeed');
              } else {
                item.pushStatus = this.$t('GlobalSetObj.Lose');
              }
            });
            console.log(this.tableData, 'tableData');
          }
        });
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.paramsData.pageSize = val;
      this.paramsData.pageNumber = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.paramsData.pageNumber = val;
      this.getSearch();
    },
    // 点击复制
    copyFun(data) {
      copyData(data);
    }
  }
};
</script>

<style scoped>
.WaybillNumber {
  color: #409eff;
  cursor: pointer;
  padding: 0;
}

.omit {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
