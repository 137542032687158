<template>
  <div>
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-width="200px"
      class="demo-ruleForm box"
    >
      <el-row>
        <el-col>
          <!-- 名称 -->
          <el-form-item :label="$t('WorkCentreTable.ticketName')" prop="ticketName">
            <el-input v-model="ruleForm.ticketName" :placeholder="$t('operationCenter.PleaseEnterTheContent')" />
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 类型 -->
      <el-form-item :label="$t('WorkCentreTable.ticketType')" prop="ticketType">
        <el-select
          v-model="ruleForm.ticketType"
          :placeholder="$t('operationCenter.PleaseSelect')"
          filterable
        >
          <el-option
            v-for="item in ticketOption"
            :key="item.id"
            :label="item.typeName + ( item.englishName ? `(${item.englishName})` : '' ) "
            :value="item.typeName"
          >
            <span style="float: left">{{ item.typeName }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.englishName }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <!-- 内容需求 -->
      <el-form-item :label="$t('WorkCentreTable.ticketDescribe')" prop="ticketDescribe">
        <tinymce v-model="ruleForm.ticketDescribe" :height="200" />
      </el-form-item>

      <!-- 执行人 -->
      <el-form-item :label="$t('WorkCentreTable.executeUserName')">
        <el-select
          v-model="ruleForm.executeUser"
          multiple
          clearable
          filterable
          remote
          :remote-method="asyncGetUserPage"
          :loading="userObj.loading"
          :placeholder="$t('operationCenter.PleaseSelect')"
        >
          <el-option
            v-for="item of userObj.arr"
            :key="item.id"
            :label="item.name + (item.englishName ? `(${item.englishName})` : '')"
            :value="JSON.stringify({ id: item.id, name: item.name, englishName: item.englishName || '' })"
          >
            <span style="float: left">{{ item.name }}{{ item.englishName ? `(${item.englishName})` : '' }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.positionName }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <!-- 期望完成时间 -->
      <el-form-item :label="$t('WorkCentreTable.planFinishTime')" prop="planFinishTime">
        <el-date-picker
          v-model="ruleForm.planFinishTime"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetime"
          :placeholder="$t('operationCenter.PleaseSelect')"
        />
        <span class="red">{{ $t('WorkCentreTable.DongbaDistrict') }}</span>
      </el-form-item>
      <!-- 优先级 -->
      <el-form-item :label="$t('WorkCentreTable.sort')" prop="sort">
        <el-radio-group v-model="ruleForm.sort" size="small">
          <!-- 紧急 -->
          <el-radio-button :label="1">{{ $t('WorkCentreTable.urgent') }}</el-radio-button>
          <!-- 高 -->
          <el-radio-button :label="2">{{ $t('WorkCentreTable.high') }}</el-radio-button>
          <!-- 正常 -->
          <el-radio-button :label="3">{{ $t('WorkCentreTable.normal') }}</el-radio-button>
          <!-- 低 -->
          <el-radio-button :label="4">{{ $t('WorkCentreTable.low') }}</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-row>
        <el-col :span="7">
          <!-- 附件 -->
          <el-form-item prop="appendix" :label="$t('WorkCentreTable.enclosure')">
            <el-upload
              ref="resourcesUpload"
              class="upload-demo"
              action
              multiple
              :http-request="avatarUpload"
              :auto-upload="false"
              :limit="5"
              :file-list="fileList"
              :on-remove="removeFile"
            >
              <el-button
                slot="trigger" size="small" type="primary"
                style="margin-left: 5px"
              >{{ $t('WorkCentreTable.selection') }}</el-button>
              <el-button
                style="margin-left: 5px"
                size="small"
                type="success"
                @click="resourcesUpload"
              >{{ $t('WorkCentreTable.upload') }}</el-button>
              <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>
    <div class="btnStyle">
      <el-button type="primary" @click="submitForm('ruleForm')">{{ $t('operationCenter.add') }}</el-button>
      <el-button @click="close">{{ $t('operationCenter.Cancel') }}</el-button>
    </div>
  </div>
</template>

<script>
import { uploadOos } from '@/utils/ali_oos_util';
import { getUserPage } from '@/api/user';
import { apiWorkMicroTicketTypePage, apiWorkMicroTicketAdd } from '@/api/workorder';
import { SORT_STATE } from '@/enums/workOrdersEnums';
import { cloneDeep } from 'lodash-es';

export default {
  components: {
    Tinymce: () => import('@/components/Tinymce')
  },
  props: {
    // 所有发起人
    userIdAndNameObj: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    },

    // 所有 微工单 类型
    ticketOption: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  data() {
    return {
      ruleForm: {
        ticketName: '', // 名称
        ticketType: '', // 标签
        ticketDescribe: '', // 内容需求
        planFinishTime: '', // 计划完成时间
        executeUser: [], // 执行人
        remark: '', // 备注
        appendix: '', // 附件
        sort: 3 // 优先级
      },
      rules: {
        ticketName: [
          { required: true, message: this.$t('WorkCentreTable.PleaseEnterName'), trigger: 'blur' } // '请输入名称'
        ],
        ticketType: [
          { required: true, message: this.$t('WorkCentreTable.PleaseSelectType'), trigger: 'change' } // '请选择类型'
        ],
        content: [
          { required: true, message: this.$t('WorkCentreTable.inputPrompt'), trigger: 'blur' } // '请输入内容'
        ],
        planFinishTime: [
          { required: true, message: this.$t('WorkCentreTable.PleaseSelectTime'), trigger: 'change' } // '请选择日期时间'
        ],
        ticketDescribe: [
          { required: true, message: this.$t('WorkCentreTable.pleaseInput'), trigger: 'blur' } // '请输入'
        ]
      },
      pickerOptions: {
        // disabledDate(time) {
        //   return time.getTime() <= (Date.now() - (3600 * 24 * 1000));
        // }
      },
      fileList: [], // 附件

      // 用户相关
      userObj: {
        loading: false, // 用户搜索 加载控制
        arr: []
      },

      // 标签
      typeObj: {
        arr: [],
        loading: false
      }
    };
  },

  watch: {
    index: {
      handler(newVal) {
        // 清空数据
        this.ruleForm = {
          ticketName: '', // 名称
          ticketType: '', // 标签
          ticketDescribe: '', // 内容需求
          planFinishTime: '', // 计划完成时间
          executeUser: [], // 执行人
          remark: '', // 备注
          appendix: '', // 附件
          sort: 3 // 优先级
        };
        this.fileList = [];
      },
      immediate: true
    }
  },

  created() {
    this.SORT_STATE = SORT_STATE;
    this.asyncWorkMicroTicketTypePage('');
    this.asyncGetUserPage('');
  },

  methods: {
    // 获取类型
    asyncWorkMicroTicketTypePage(val) {
      const obj = {
        pageSize: 10,
        pageNumber: 1,
        params: {
          typeName: val,
          typeStatus: 1
        }
      };
      this.typeObj.loading = true;
      apiWorkMicroTicketTypePage(obj, false).then(res => {
        // const { code, tableData } = res;
        if (res.status !== 'OK') { return; }
        // console.log('微工单——获取类型', res.data.records);
        this.typeObj.arr = res.data.records.map(item => {
          const { id, typeName } = item;
          return {
            id,
            typeName
          };
        });
      }).catch(err => {
        console.error(err);
      }).finally(() => { this.typeObj.loading = false; });
    },

    // 获取用户信息（含职务）
    asyncGetUserPage(val) {
      const obj = {
        pageSize: 20,
        pageNumber: 1,
        params: {
          realName: val,
          accountState: '1'// 启用
        }
      };
      this.userObj.loading = true;
      getUserPage(obj, false).then(res => {
        // const { code, tableData } = res;
        if (res.status !== 'OK') { return; }

        this.userObj.arr = res.data.records.map(item => {
          const { realName, id, positionName, englishName } = item;
          return {
            name: realName,
            id,
            englishName,
            positionName // 职位
          };
        });
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.userObj.loading = false;
      });
    },

    // 上传oos
    avatarUpload(option) {
      // console.log('上传', option);
      const file = option.file;
      return uploadOos(file, 'workOrder').then(res => {
        // console.log(res);
        const { code, url } = res;
        if (code !== 200) {
          return;
        }
        const { appendix } = this.ruleForm;
        this.ruleForm.appendix = appendix ? appendix + ',' + url : url;
      }).catch(err => {
        console.error(err);
      });
    },

    // 移除文件触发
    removeFile(file, fileList) {
      this.ruleForm.appendix = fileList.length > 0 ? fileList.map(item => item.url).toString() : '';
    },

    // 上传
    resourcesUpload() {
      this.$refs.resourcesUpload.submit();
    },

    // 提交表单
    submitForm(formName) {
      // const userIdAndNameObj = this.userIdAndNameObj;
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          this.$message({
            message: this.$t('operatingPlatform.PleaseFillInTheRequiredFields'), // '请填写必填项'
            type: 'warning'
          });
          return false;
        }

        const ruleForm = cloneDeep(this.ruleForm);

        const executeUserArr = ruleForm.executeUser.map(item => JSON.parse(item));

        ruleForm.executeUser = JSON.stringify(executeUserArr);

        // console.log('新增工单传入值', ruleForm);
        this.apiWorkMicroTicketAdd(ruleForm);
      });
    },

    // 新增微工单
    apiWorkMicroTicketAdd(obj) {
      apiWorkMicroTicketAdd(obj).then(res => {
        const { status } = res;
        if (status !== 'OK') { return; }
        this.$message({
          message: this.$t('WorkCentreTable.newAddSuc'), // '新增成功'
          type: 'success'
        });

        setTimeout(() => {
          this.$emit('close', true);
        }, 500);
      }).catch(err => {
        console.error(err);
      });
    },

    // 关闭
    close(isBool = false) {
      this.$emit('close', isBool);
    }
  }

};
</script>

<style scoped>
.box {
  height: calc(100vh - 200px);
  /* border: 1px solid red; */
  overflow-y: scroll;
  white-space: nowrap; /* 不换行 */
}
.btnStyle{
  text-align: right;
  padding: 10px;
}

.red {
  color: red;
  font-weight: bold;
}
</style>
