<template>
  <div class="Box">
    <div>
      <el-table
        border
        :data="importTableDate"
        :max-height="600"
        :cell-style="styleFun"
      >
        <!-- 序号 -->
        <el-table-column :label="$t('collectionCenter.ind')" type="index" width="60" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="customerName"
          align="center"
          :label="$t('collectionCenter.CustomerName')"
        /><!-- 客户名称 -->
        <el-table-column
          prop="deliveryNumber"
          align="center"
          width="150"
          :label="$t('collectionCenter.logisticsNum')"
        ><!-- 物流号 -->
          <template slot-scope="scope">
            <el-input v-if="scope.$index === changeCellIndex && hiddenInput" v-model="scope.row.deliveryNumber" clearable @blur="leaveInput" />
            <span v-else :class="scope.row.isSome ? 'redColor' : ''">{{ scope.row.deliveryNumber }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="customerOrderNumber"
          align="center"
          :label="$t('collectionCenter.customerOrderNum')"
        /><!-- 客户订单号 -->
        <el-table-column
          prop="costWeight"
          align="center"
          :label="$t('collectionCenter.chargedWeight')"
        /><!-- 计费重量 -->
        <el-table-column
          prop="distributionFees"
          align="center"
          :label="$t('collectionCenter.distributionCost')"
        /><!-- 配送费用 -->
        <el-table-column
          prop="operatingFees"
          align="center"
          :label="$t('collectionCenter.operatingCost')"
        /><!-- 操作费用 -->
        <el-table-column
          prop="exceptionalFees"
          align="center"
          :label="$t('collectionCenter.warehouseAnomalyCharge')"
        /><!-- 仓库异常费用 -->
        <el-table-column
          :label="$t('collectionCenter.operation')"
          fixed="right"
          width="160"
          align="center"
        ><!-- 操作 -->
          <template slot-scope="scope">
            <!--  -->
            <span v-if="scope.row.costTypeCheck == false || scope.row.deliveryNumberCheck == false || scope.row.isSome">
              <el-button v-show="scope.row.duplicate != true" type="primary" plain size="mini" @click="editClick(scope.row, scope.$index)">{{ $t('collectionCenter.modify') }}</el-button>
            </span>
            <el-popconfirm
              :title="$t('collectionCenter.deleteSure')"
              @confirm="delClick(scope.row, scope.$index)"
            >
              <el-button slot="reference" type="danger" plain size="mini">{{ $t('collectionCenter.Delete') }}</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="importSumbitClass">
      <el-button type="primary" @click="saveClick">{{ $t('collectionCenter.Save') }}</el-button>
      <el-button type="info" @click="closeClick">{{ $t('collectionCenter.cancel') }}</el-button>
    </div>
  </div>
</template>

<script>
import {
  billUploadExcelMsg
} from '@/api/lanshou';
export default {
  props: {
    // 解析后的数据
    billImportList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      importTableDate: [],
      importTotal: 0,
      changeCellIndex: '', // 可输入的行
      hiddenInput: false // 为了保存前的校验
    };
  },
  watch: {
    billImportList: {
      handler(newVal, oldVal) {
        this.importTableDate = newVal.map(item => {
          const isSome = false;
          return {
            isSome,
            ...item
          };
        });
      },
      immediate: true,
      deep: true
    },
    importTableDate() {
      // 查看相同物流号
      this.checkDeliveryNumber();
    }
  },
  methods: {
    styleFun({ row, column, columnIndex }) { // 渲染表格时判断数据是否有误
      const reg = /^[A-Za-z]{2}\d{9}BR$/g;
      if (row.duplicate === true || row.exist === false) {
        return {
          color: 'red'
        };
      }
      if (column.property === 'deliveryNumber' && !reg.test(row.deliveryNumber)) {
        return {
          color: 'red'
        };
      }
    },
    editClick(row, index) { // 编辑当前行
      // 可输入的行
      this.changeCellIndex = index;
      this.hiddenInput = true;
    },
    delClick(row, index) {
      this.importTableDate.splice(index, 1);
    },
    // 需要反复调用校验是否有重复
    checkDeliveryNumber() {
      // 判断是否有重复数据，重复值标红，查看相同物流号
      let isRepeat = false;
      let someIndex = [];
      this.importTableDate.map((item, index) => {
        this.importTableDate[index].isSome = false;
        const someVal = this.importTableDate.findIndex((x, idx) => {
          if (index !== idx && x.deliveryNumber === item.deliveryNumber) {
            someIndex.push(idx, index);
            isRepeat = true;
            return someIndex;
          }
        });
        if (someVal >= 0) {
          const setArr = new Set(someIndex);
          someIndex = [...setArr];
        }
      });
      someIndex.forEach(inx => {
        this.importTableDate[inx].isSome = true;
      });
      return isRepeat;
    },
    // 失去光标——>再次校验输入值
    leaveInput() {
      this.hiddenInput = false;
      this.checkDeliveryNumber();
    },
    // 提交导入表格
    saveClick() {
      this.hiddenInput = false;
      if (this.importTableDate.length <= 0) {
        this.$message.warning(this.$t('collectionCenter.nullSubmit'));
        return;
      }

      this.importTableDate.filter((item, index) => {
        delete this.importTableDate[index].isSome;
      });

      let isError = false;
      let isRepeat = false;
      let isExist = true;
      this.importTableDate.forEach((item, index) => {
        if (item.duplicate) {
          isRepeat = true;
        }
        if (!item.exist) {
          isExist = false;
        }
        console.log(item, 'item');
        const dd = item.deliveryNumber.toString();
        const d11 = item.deliveryNumber.slice(0, 1);
        const d12 = item.deliveryNumber.slice(1, 2);
        const d2 = item.deliveryNumber.slice(2, 11);
        const d3 = item.deliveryNumber.slice(-2);
        // 判断数据是否错误
        if (!isError) {
          if (dd.length !== 13) {
            isError = true;
          } else if (dd.length === 13) {
            if (d11.toLowerCase().charCodeAt() >= 97 &&
              d11.toLowerCase().charCodeAt() <= 122 &&
              d12.toLowerCase().charCodeAt() >= 97 &&
              d12.toLowerCase().charCodeAt() <= 122 &&
              d2.toLowerCase().charCodeAt() < 97 &&
              d3.toString() === 'BR'
            ) {
              isError = false;
            } else {
              isError = true;
            }
          } else {
            isError = false;
          }
        }
      });

      // 判断是否有重复数据，重复值标红，查看相同物流号
      const valIsTrue = this.checkDeliveryNumber();
      if (valIsTrue || isRepeat) {
        this.$message.warning(this.$t('collectionCenter.haveRepeatData')); // '有重复数据，不能提交'
        return;
      }
      if (!isExist) {
        this.$message.warning(this.$t('collectionCenter.dataNotExist')); // '数据不存在'
        return;
      }
      if (!isError && !valIsTrue && isExist) {
        // 提交数据
        const importTableList = {
          dtoList: this.importTableDate
        };
        billUploadExcelMsg(importTableList).then(res => {
          if (res.status === 'OK') {
            this.$emit('getTable');
          }
        });
      } else {
        this.$message.warning(this.$t('collectionCenter.dataError')); // 数据错误，不能提交
      }
    },
    // 关闭表格
    closeClick() {
      // this.openDialog = false;
      this.importTableDate = [];
      this.$emit('closeDialog');
    }
  }
};
</script>

<style lang="scss" scoped>
  .redColor{
    color: red;
  }
  .Box{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .importSumbitClass{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
  }
</style>
