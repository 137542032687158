<template>
  <el-button
    icon="el-icon-download"
    size="small"
    type="primary"
    @click="DownloadTemplate"
  >{{ $t('receivablePayable.DownloadTemplate') }}</el-button>
</template>

<script>
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
// import i18n from '@/lang';

export default {
  name: 'ImportTemplate',

  props: {

  },
  data() {
    return {

    };
  },
  methods: {
    // 下载模板
    async DownloadTemplate() {
      const that = this;
      async function ddd() {
        const wb = new ExcelJS.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        // 设置表头(Sheet1);
        Sheet1.columns = [
          {
            header: '*' + that.$t('receivablePayable.PaymentSlipNumber'), // 付款单号',
            key: 'barCode',
            width: 40
          },
          {
            header: '*' + that.$t('receivablePayable.TheAmountOfThePayment'), // 付款金额',
            key: 'fee',
            width: 40
          },
          {
            header: that.$t('receivablePayable.PaymentValidityPeriod') + '(' + that.$t('customerManagements.format') + '：2022-01-01)', // 付款有效期',
            key: 'paymentDeadline',
            width: 40
          }
        ];
        // Sheet1.mergeCells('A2:C2');
        // // 每次仅导入3000条  // 不可更改此模板;
        // Sheet1.getCell('A2').value = '1、' + that.$t('receivablePayable.Only3000PiecesImport') + '  2、' + that.$t('receivablePayable.ThisTemplateCannotBeChanged') + '  3、*' + that.$t('errorMessage.mandatory');
        // Sheet1.getRow(2).getCell(1).font = {
        //   color: { argb: 'ff0000' }
        // };

        new Array(3000).fill(0).forEach((_, idx) => {
          const row = idx + 2;
          // 有效日期 设置单元格为纯文本格式
          Sheet1.getCell(row, 3).numFmt = '@';
        });

        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        saveAs(blob, `${that.$t('receivablePayable.BillTemplate')}.xlsx`);
        return;
      }
      try {
        await ddd();
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style scoped>

</style>
