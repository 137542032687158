<script >
import { defineProps, ref } from '@vue/composition-api';
import { useVuelidate } from '@vuelidate/core';
import { FormActions, FormActionsNext, FormActionsPrevious } from '@/components/wizard-form/formStepActions';
import { required, numeric, maxLength, minLength } from '@/utils/validationErrors';
import { companyService } from '@/api/transfer/company';
const __sfc_main = {};
__sfc_main.props = {
  isLastStep: {
    type: Boolean,
    required: true
  },
  isFirstStep: {
    type: Boolean,
    required: true
  },
  handlePrevious: {
    type: Function
  },
  handleNext: {
    type: Function,
    required: true
  },
  formData: {
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  function checkingLongerTime(value, siblings) {
    return value > siblings.initialHour;
  }
  const rules = {
    companyName: {
      required
    },
    document: {
      required
    },
    base: {
      required
    },
    iata: {
      required
    },
    stateRegistration: {
      required,
      minLength: minLength(9),
      maxLength: maxLength(14)
    },
    receivingCapacity: {
      required,
      numeric
    },
    shippingCapacity: {
      required,
      numeric
    },
    initialHour: {
      required
    },
    finalHour: {
      required,
      checkingLongerTime
    }
  };
  const v$ = useVuelidate(rules, props.formData);
  async function handleSubmit() {
    const isValid = await v$.value.$validate();
    if (isValid) props.handleNext();
  }
  const listCompany = ref([]);
  async function filteredCompany() {
    try {
      const res = await companyService.getAll(0, 10, {
        name: props.formData.companyName
      });
      listCompany.value = res.data.result.map(company => {
        return {
          name: company.shipping_company_name,
          id: company.id
        };
      });
    } catch (error) {
      listCompany.value = [];
    }
  }
  function selectedCompany(value) {
    props.formData.companyId = value ? value.id : '';
  }
  return {
    v$,
    handleSubmit,
    listCompany,
    filteredCompany,
    selectedCompany
  };
};
__sfc_main.components = Object.assign({
  FormActions,
  FormActionsPrevious,
  FormActionsNext
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <form class="wrapper-form" @submit.prevent="handleSubmit">
    <o-field
      :label="$t('base.company')"
      label-for="company"
      :message="v$.companyName.$errors.length ? v$.companyName.$errors[0].$message : null"
    >
      <o-autocomplete
        id="company"
        v-model="formData.companyName"
        :placeholder="$t('base.companyNamePlaceholder')"
        clearable
        expanded
        field="name"
        :data="listCompany"
        @input="filteredCompany"
        @select="selectedCompany"
      >
        <template slot="empty">Sem resultados</template>
      </o-autocomplete>
    </o-field>

    <o-field
      :label="$t('base.baseName')"
      label-for="base"
      :message="v$.base.$errors.length ? v$.base.$errors[0].$message : null"
    >
      <o-input id="base" v-model="formData.base" :placeholder="$t('base.baseNamePlaceholder')" />
    </o-field>

    <o-field
      v-mask="['###.###.###-##', '##.###.###/####-##']"
      :label="$t('base.document')"
      label-for="document"
      :message="v$.document.$errors.length ? v$.document.$errors[0].$message : null"
    >
      <o-input id="document" v-model="formData.document" :placeholder="$t('base.documentPlaceholder')" />
    </o-field>

    <o-field
      v-mask="'##############'"
      :label="$t('base.stateRegistration')"
      label-for="stateRegistration"
      :message="v$.stateRegistration.$errors.length ? v$.stateRegistration.$errors[0].$message : null"
    >
      <o-input id="stateRegistration" v-model="formData.stateRegistration" :placeholder="$t('base.stateRegistrationPlaceholder')" />
    </o-field>

    <o-field
      :label="$t('base.receivingCapacity')"
      label-for="receivingCapacity"
      :message="v$.receivingCapacity.$errors.length ? v$.receivingCapacity.$errors[0].$message : null"
    >
      <o-input id="receivingCapacity" v-model="formData.receivingCapacity" :placeholder="$t('base.receivingCapacity')" />
    </o-field>

    <o-field
      :label="$t('base.shippingCapacity')"
      label-for="shippingCapacity"
      :message="v$.shippingCapacity.$errors.length ? v$.shippingCapacity.$errors[0].$message : null"
    >
      <o-input id="shippingCapacity" v-model="formData.shippingCapacity" :placeholder="$t('base.shippingCapacityPlaceholder')" />
    </o-field>

    <o-field
      label="IATA"
      label-for="iata"
      class="span-2 "
      :message="v$.iata.$errors.length ? v$.iata.$errors[0].$message : null"
    >
      <o-input id="iata" v-model="formData.iata" :placeholder="$t('base.IATAPlaceholder')" />
    </o-field>

    <o-field
      :label="$t('base.openingTime')"
      :message="v$.initialHour.$errors.length ? v$.initialHour.$errors[0].$message : null"
    >
      <o-timepicker v-model="formData.initialHour" rounded :placeholder="$t('base.selectTime')" icon="clock" :enable-seconds="false" hour-format="24" locale="pt-BR" :default-minutes="0" />
    </o-field>

    <o-field
      :label="$t('base.closingTime')"
      :message="v$.finalHour.$errors.length ? v$.finalHour.$errors[0].$message : null"
    >
      <o-timepicker v-model="formData.finalHour" rounded :placeholder="$t('base.selectTime')" icon="clock" :enable-seconds="false" hour-format="24" locale="pt-BR" :default-minutes="0" />
    </o-field>

    <FormActions>
      <FormActionsPrevious
        :is-first-step="isFirstStep"
        @handlePrevious="handlePrevious"
      />

      <FormActionsNext :is-last-step="isLastStep" />
    </FormActions>
  </form>
</template>

<style scoped>
.wrapper-form {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem/* 16px */;
  padding-top: 0.5rem/* 8px */;
  padding-bottom: 0.5rem/* 8px */;
}

.span-2 {
  grid-column: span 2 / span 2;
}
</style>
