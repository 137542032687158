<template>
  <div class="MerchantManagementBox">
    <div style="margin-bottom: 20px;">
      <el-form :model="queryForm">
        <el-row class="selectClass" :gutter="20">
          <el-col :span="10" style="width: auto;">
            <!-- 创建时间： -->
            <el-form-item :label="$t('collectionCenter.createTime')">
              <el-date-picker
                v-model="timeValue"
                :clearable="false"
                type="daterange"
                value-format="yyyy-MM-dd"
                :default-time="['00:00:00', '23:59:59']"
                :range-separator="$t('collectionCenter.to')"
                :start-placeholder="$t('collectionCenter.StartTime')"
                :end-placeholder="$t('collectionCenter.EndTime')"
                @change="dateChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="9" style="width: auto;">
            <!-- 客户名称： -->
            <el-form-item :label="$t('collectionCenter.CustomerN')" prop="customerId">
              <el-select
                v-model="queryForm.params.customerId"
                :placeholder="$t('collectionCenter.pleaseChoose')"
                clearable
                filterable
                @focus="getAllCustomer"
                @change="changeCustomerName"
              >
                <el-option
                  v-for="(item, index) in customerNameList"
                  :key="index"
                  :label="item.username"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="9" style="width: auto;" class="col10">
            <!-- 商户号/商户名称： -->
            <el-form-item class="m">
              <el-select
                v-model="merchantChoose"
                :placeholder="$t('collectionCenter.pleaseChoose')"
                class="selectTimeClass autoWidth"
                @change="changeMerchant"
              >
                <template slot="prefix">
                  {{ (merchantCodeOrName.find(item => item.m === merchantChoose) || {}).name }}
                </template>
                <el-option
                  v-for="(item, index) in merchantCodeOrName"
                  :key="index"
                  :label="item.name"
                  :value="item.m"
                />
              </el-select>
              <el-select
                v-model="merchantCodeValue"
                filterable
                clearable
                remote
                reserve-keyword
                :placeholder="$t('collectionCenter.pleaseInput')"
                :remote-method="remoteMethod"
                :loading="loading"
                @change="changeValue"
              >
                <el-option
                  v-for="(item, index) in merchantCodeList"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="9" style="width: auto;">
            <!-- 揽收模式： -->
            <el-form-item :label="$t('collectionCenter.receivingMode') + '：'" prop="collectType">
              <el-select
                v-model="queryForm.params.collectType"
                filterable
                clearable
                :placeholder="$t('collectionCenter.pleaseInput')"
              >
                <!--全部-->
                <el-option :key="0" :label="$t('collectionCenter.tudo')" :value="null" />
                <!--上门交件-->
                <el-option :key="1" :label="$t('collectionCenter.dropOff')" :value="1" />
                <!--上门揽收-->
                <el-option :key="2" :label="$t('collectionCenter.doorPickUp')" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="9" style="width: auto;">
            <!-- 所属网点： -->
            <el-form-item :label="$t('collectionCenter.belongDot')" prop="siteIdList">
              <el-select
                v-model="queryForm.params.siteIdList"
                :placeholder="$t('collectionCenter.pleaseChoose')"
                clearable
                multiple
                filterable
                collapse-tags
              >
                <el-option
                  v-for="(item, index) in siteAllList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="9" style="width: auto;">
            <!-- 状态： -->
            <el-form-item :label="$t('collectionCenter.accountState')" prop="enabledFlag">
              <el-select
                v-model="queryForm.params.enabledFlag"
                :placeholder="$t('collectionCenter.pleaseChoose')"
                clearable
                filterable
              >
                <!--全部-->
                <el-option key="null" :label="$t('collectionCenter.tudo')" :value="null" />
                <el-option
                  v-for="(item, index) in StatusList"
                  :key="index"
                  :label="item.StatusName"
                  :value="item.Status"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="9" style="width: auto;">
            <el-form-item prop="soteIdBool">
              <!--所属网点为空-->
              <el-checkbox v-model="queryForm.params.soteIdBool">{{ $t('collectionCenter.TheAffiliatedNetworkEmpty') }}</el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="24">
          <el-button
            v-permit:remove="'btn:collection-center:basicDate:MerchantManagement:search'"
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
          <el-button
            v-permit:remove="'btn:collection-center:basicDate:MerchantManagement:reset'"
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
          <el-button
            v-permit:remove="'btn:collection-center:basicDate:MerchantManagement:add'"
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="addClick"
          >{{ $t('collectionCenter.add') }}</el-button><!-- 新增 -->
          <el-button
            v-permit:remove="'btn:collection-center:basicDate:MerchantManagement:export'"
            size="mini"
            :class="selectData.length <=0 && isClick ? 'onlyClickOnce10' : ''"
            @click="exportData()"
          >{{ $t('collectionCenter.export') }}</el-button><!-- 导出 -->
          <el-button
            v-permit:remove="'btn:collection-center:basicDate:MerchantManagement:exportAll'"
            size="mini"
            :class="isClickAll ? 'onlyClickOnce10' : ''"
            @click="exportDataAll()"
          >{{ $t('collectionCenter.allExport') }}</el-button><!-- 全部导出 -->
        </el-col>
      </el-row>
      <el-divider />
    </div>
    <div>
      <el-table
        ref="multipleTable"
        :max-height="600"
        border
        row-key="id"
        :data="tableDate"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        />
        <!-- 序号 -->
        <el-table-column
          :label="$t('collectionCenter.ind')" type="index" width="60"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="customerName"
          :label="$t('collectionCenter.CustomerName')"
        /><!-- 客户名称 -->
        <el-table-column
          prop="customerId"
          :label="$t('customerManagements.clientID')"
        /><!-- 客户编号 -->
        <el-table-column
          prop="merchantCode"
          :label="$t('collectionCenter.MerchantNum')"
        /><!-- 商户号 -->
        <el-table-column
          prop="merchantName"
          :label="$t('collectionCenter.MerchantName')"
          :show-overflow-tooltip="true"
        /><!-- 商户名称 -->
        <el-table-column
          prop="collectTypeName"
          :label="$t('collectionCenter.receivingMode')"
          :show-overflow-tooltip="true"
        /><!-- 揽收模式 -->
        <el-table-column
          prop="contactPhone"
          :label="$t('collectionCenter.contactTel')"
        /><!-- 联系方式 -->
        <el-table-column
          prop="state"
          :label="$t('collectionCenter.state')"
        /><!-- 州 -->
        <el-table-column
          prop="city"
          :label="$t('collectionCenter.city')"
        /><!-- 城市 -->
        <el-table-column
          prop="contactAddress"
          :label="$t('collectionCenter.contactAddress')"
          :show-overflow-tooltip="true"
        /><!-- 联系地址 -->
        <el-table-column
          prop="merchantEmail"
          :label="$t('collectionCenter.merchantEmail')"
        /><!-- 商户邮箱 -->
        <el-table-column
          prop="siteName"
          :label="$t('collectionCenter.belongSite')"
        ><!-- 所属网点 -->
          <template slot-scope="scope">
            <el-tooltip effect="dark" placement="top">
              <div slot="content">{{ scope.row.siteName }}</div>
              <div style="max-width: 150px;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;">{{ scope.row.siteName }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="enabledFlagName"
          :label="$t('collectionCenter.ifUse')"
        /><!-- 是否启用 -->
        <el-table-column
          prop="createUserName"
          :label="$t('collectionCenter.creator')"
        /><!-- 创建人 -->
        <el-table-column
          prop="createTime"
          :label="$t('collectionCenter.CreationTime')"
        /><!-- 创建时间 -->
        <el-table-column
          prop="updateUserName"
          :label="$t('collectionCenter.ModifiedBy')"
        /><!-- 修改人 -->
        <el-table-column
          prop="updateTime"
          :label="$t('collectionCenter.ModificationTime')"
        /><!-- 修改时间 -->
        <el-table-column
          v-if="edit"
          :label="$t('collectionCenter.operation')"
          fixed="right"
          align="center"
          width="100"
        >
          <template slot-scope="scope">
            <div>
              <el-button
                size="mini"
                @click="changeClick(scope.row)"
              >{{ $t('collectionCenter.modify') }}</el-button><!-- 修改 -->
              <!-- <el-button
                size="mini"
                @click="disableClick"
              >{{ $t('collectionCenter.disable') }}</el-button> --><!-- 禁用 -->
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100, 500, 1000]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getTablePage"
        @size-change="getPageSize"
      />
    </div>
    <!-- 新增 / 修改 -->
    <el-drawer
      :title="drawerTitle"
      :visible.sync="drawerIsOpen"
      :direction="direction"
      size="40%"
      :show-close="false"
      :wrapper-closable="false"
    >
      <div class="addBox">
        <el-form
          ref="addQueryRef" :key="drawerIsOpen" :model="addQueryForm"
          :rules="rules"
        >
          <el-row type="flex" justify="space-between">
            <el-col :span="10">
              <!-- 客户名称： -->
              <el-form-item prop="customerId" :label="$t('collectionCenter.CustomerN')">
                <el-select
                  v-model="addQueryForm.customerId"
                  :placeholder="$t('collectionCenter.pleaseChoose')"
                  clearable
                  filterable
                  @focus="getAllCustomer"
                  @change="changeCustomer()"
                >
                  <el-option
                    v-for="(item, index) in customerNameList"
                    :key="index"
                    :label="item.username"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <div class="MerchantNumClass">
                <!-- 商户号： -->
                <el-form-item prop="merchantCode" :label="$t('collectionCenter.MerchantNumBer')">
                  <el-input
                    v-model="addQueryForm.merchantCode"
                    :placeholder="$t('collectionCenter.pleaseInput')"
                    clearable
                    :autosize="{ maxRows: 3}"
                    type="textarea"
                    maxlength="30"
                    show-word-limit
                  />
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between">
            <el-col :span="10">
              <div class="MerchantNameClass">
                <!-- 商户名称 -->
                <el-form-item prop="merchantName" :label="$t('collectionCenter.MerchantN')">
                  <el-input
                    v-model="addQueryForm.merchantName"
                    :placeholder="$t('collectionCenter.pleaseInput')"
                    clearable
                    :autosize="{ maxRows: 5}"
                    type="textarea"
                    maxlength="100"
                    show-word-limit
                  />
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="10">
              <div class="MerchantNumClass">
                <!-- 联系电话： -->
                <el-form-item prop="contactPhone" :label="$t('collectionCenter.contactPhone')">
                  <el-input
                    v-model="addQueryForm.contactPhone"
                    :placeholder="$t('collectionCenter.pleaseInput')"
                    clearable
                    :autosize="{ maxRows: 3}"
                    type="textarea"
                    maxlength="30"
                    show-word-limit
                  />
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between">
            <el-col :span="10">
              <div class="MerchantNameClass">
                <!-- 联系地址： -->
                <el-form-item prop="contactAddress" :label="$t('collectionCenter.contactArs')">
                  <el-input
                    v-model="addQueryForm.contactAddress"
                    :placeholder="$t('collectionCenter.pleaseInput')"
                    clearable
                    :autosize="{ maxRows: 5}"
                    type="textarea"
                    maxlength="255"
                    show-word-limit
                  />
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="10">
              <div>
                <!-- 商户邮箱： -->
                <el-form-item prop="merchantEmail" :label="$t('collectionCenter.merchantE')">
                  <el-input
                    v-model="addQueryForm.merchantEmail" :placeholder="$t('collectionCenter.pleaseInput')" clearable
                    @blur="merchantEmailCheck"
                  />
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between">
            <el-col :span="10">
              <!-- 所属网点： -->
              <el-form-item :label="$t('collectionCenter.belongDot')" prop="siteIds">
                <el-select
                  v-model="addQueryForm.siteIds"
                  :placeholder="$t('collectionCenter.pleaseChoose')"
                  clearable
                  filterable
                  multiple
                >
                  <el-option
                    v-for="(item, index) in siteList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <div class="MerchantNameClass">
                <!-- 州： -->
                <el-form-item :label="$t('GlobalSetObj.Estado')" prop="state">
                  <el-input
                    v-model="addQueryForm.state"
                    :placeholder="$t('collectionCenter.pleaseInput')"
                    :autosize="{ maxRows: 3}"
                    type="textarea"
                    maxlength="64"
                    show-word-limit
                    clearable
                  />
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between">
            <el-col :span="10">
              <div class="MerchantNameClass">
                <!-- 城市： -->
                <el-form-item :label="$t('collectionCenter.city')" prop="city">
                  <el-input
                    v-model="addQueryForm.city"
                    :placeholder="$t('collectionCenter.pleaseInput')"
                    clearable
                    :autosize="{ maxRows: 3}"
                    type="textarea"
                    maxlength="128"
                    show-word-limit
                  />
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="10">
              <!-- 揽收模式： -->
              <el-form-item :label="$t('collectionCenter.receivingMode') + '：'" prop="collectType">
                <el-select
                  v-model="addQueryForm.collectType"
                  filterable
                  clearable
                  :placeholder="$t('collectionCenter.pleaseInput')"
                >
                  <!--上门交件-->
                  <el-option :key="1" :label="$t('collectionCenter.dropOff')" :value="1" />
                  <!--上门揽收-->
                  <el-option :key="2" :label="$t('collectionCenter.doorPickUp')" :value="2" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between">
            <el-col v-if="drawerTitle === $t('collectionCenter.modify')" :span="10">
              <!-- 是否启用： -->
              <el-form-item prop="siteStatus" :label="$t('collectionCenter.ifEnabled')">
                <el-select
                  v-model="addQueryForm.enabledFlag" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in StatusList"
                    :key="index"
                    :label="item.StatusName"
                    :value="item.Status"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div style="text-align: right;padding-right: 20px;">
        <el-button
          type="primary"
          @click="saveClick"
        >{{ $t('collectionCenter.Save') }}</el-button><!-- 保存 -->
        <el-button
          @click="cancelClick"
        >{{ $t('collectionCenter.cancel') }}</el-button><!-- 取消 -->
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  queryAllCustomer,
  queryAllMerchantCode,
  customerMerchantPage,
  customerMerchantAdd,
  customerMerchantUpdate,
  searchSiteIdList,
  customerMerchantExportAll,
  searchSitequeryAllSite
} from '@/api/lanshou';
import { isNumber, phoneNumberCode } from '@/utils/validate';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { getUserName } from '@/utils/asyncTools';
import ExcelJs from 'exceljs';
import { saveAs } from 'file-saver';
import { cloneDeep } from 'lodash-es';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList.js';

export default {
  name: 'MerchantManagement',
  data() {
    return {
      isClickAll: false,
      isClick: false,
      edit: this.$store.getters.button_permissions.includes('btn:collection-center:basicDate:MerchantManagement:edit'),
      timeValue: [],
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: '',
          endTime: '',
          customerId: '',
          merchantCode: '',
          merchantName: '',
          collectType: null, // 揽收类型
          enabledFlag: null, // 状态
          siteIdList: [], // 所属网点
          soteIdBool: false // 所属网点为空
        }
      },
      customerNameList: [], // 客户列表
      merchantChoose: 'm2', // 选择输入商户号还是商户名称
      merchantCodeOrName: [
        { m: 'm1', name: this.$t('collectionCenter.MerchantNum') }, // '商户号'
        { m: 'm2', name: this.$t('collectionCenter.MerchantName') } // '商户名称'
      ],
      loading: false, // 商户号加载
      merchantCodeValue: '',
      merchantCodeList: [], // 商户编号列表
      tableDate: [],
      total: 0,
      drawerIsOpen: false,
      drawerTitle: this.$t('collectionCenter.add'), // '新增'
      direction: 'ltr',
      addQueryForm: {
        customerId: '',
        merchantCode: '',
        merchantName: '',
        contactPhone: '',
        contactAddress: '',
        merchantEmail: '',
        collectType: '',
        customerNumber: '',
        state: '',
        city: '',
        siteIds: []
      },
      rules: {
        customerId: [
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }
        ],
        siteIds: [
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }
        ],
        merchantCode: [
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] },
          {
            validator: async(rule, value, callback) => {
              if (!isNumber(value)) {
                return callback(new Error(this.$t('collectionCenter.inputNum'))); // '请输入数字'
              } else {
                callback();
              }
            }
          }
        ],
        merchantName: [
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }
        ],
        contactPhone: [
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (value && !phoneNumberCode(value)) {
                return callback(new Error(this.$t('collectionCenter.brazilPhoneRestrict'))); // 长度不低于10位，允许包含+-空格等特殊字符
              } else {
                callback();
              }
            }
          }
        ],
        contactAddress: [
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }
        ],
        collectType: [
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }
        ],
        state: [
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }
        ],
        city: [
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }
        ]
      },
      StatusList: [
        { Status: 0, StatusName: this.$t('collectionCenter.enable') }, // '启用'
        { Status: 1, StatusName: this.$t('collectionCenter.notEnabled') } // '未启用'
      ],
      userIdAndNameObj: [],
      checkIsEmail: true,
      siteList: [],
      siteAllList: [],
      selectData: []
    };
  },
  created() {
    this.getAllCustomer();
    this.default();
    this.searchClick();
    // 获取网点
    this.getSiteIdList();
    this.getAllSiteIdList();
    this.userIdAndNameObj = getUserIdAndNameObj();
    this.isClick = false;
    this.isClickAll = false;
  },
  methods: {
    handleSelectionChange(arr) {
      this.selectData = arr;
    },
    // 导出
    exportData() {
      this.isClick = true;
      if (this.selectData.length === 0) {
        this.$message({
          message: this.$t('collectionCenter.selectData'), // '请选择数据'
          type: 'warning'
        });
        return;
      }
      const that = this;
      async function ddd() {
        const wb = new ExcelJs.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        Sheet1.columns = [
          {
            header: that.$t('customerManagements.clientID'), // 客户编号
            key: 'customerId',
            width: 20
          },
          {
            header: that.$t('collectionCenter.MerchantNum'), // 商户号
            key: 'merchantCode',
            width: 20
          },
          {
            header: that.$t('collectionCenter.receivingMode'), // 揽收模式
            key: 'collectTypeName',
            width: 30
          },
          {
            header: that.$t('collectionCenter.contactTel'), // 联系电话
            key: 'contactPhone',
            width: 30
          },
          {
            header: that.$t('GlobalSetObj.Estado'), // 州
            key: 'state',
            width: 20
          },
          {
            header: that.$t('collectionCenter.city'), // 城市
            key: 'city',
            width: 30
          },
          {
            header: that.$t('collectionCenter.contactAddress'), // 联系地址
            key: 'contactAddress',
            width: 40
          },
          {
            header: that.$t('collectionCenter.belongSite'), // 所属网点
            key: 'siteName',
            width: 30
          },
          {
            header: that.$t('collectionCenter.ifUse'), // 是否启用
            key: 'enabledFlagName',
            width: 20
          },
          {
            header: that.$t('collectionCenter.creator'), // 创建人
            key: 'createUserName',
            width: 20
          },
          {
            header: that.$t('collectionCenter.CreationTime'), // 创建时间
            key: 'createTime',
            width: 30
          },
          {
            header: that.$t('collectionCenter.ModifiedBy'), // 修改人
            key: 'updateUserName',
            width: 20
          },
          {
            header: that.$t('collectionCenter.ModificationTime'), // 修改时间
            key: 'updateTime',
            width: 30
          }
        ];
        const Sheet1_data = [];
        that.selectData.map(item => {
          Sheet1_data.push({
            customerId: item.customerId,
            merchantCode: item.merchantCode,
            collectTypeName: item.collectTypeName,
            contactPhone: item.contactPhone,
            state: item.state,
            city: item.city,
            contactAddress: item.contactAddress,
            siteName: item.siteName,
            enabledFlagName: item.enabledFlagName,
            createUserName: item.createUserName,
            createTime: item.createTime,
            updateUserName: item.updateUserName,
            updateTime: item.updateTime
          });
        });
        Sheet1.addRows(Sheet1_data);

        Sheet1.getRow(1).height = 30;
        Sheet1.getRow(1).font = { bold: true, color: { argb: 'FFFFFF' }, sz: 30 };
        Sheet1.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
        Sheet1.getRow(1).eachCell((cell, colNumber) => {
          // 设置表头所有单元格的背景色为浅绿色 (例如)
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '009944' } // ARGB 颜色值，此处为浅绿色
          };
        });
        Sheet1.views = [
          { state: 'frozen', xSplit: undefined, ySplit: 1 }
        ];

        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        const saveName = that.$t('collectionCenter.MerchantManagement') + '.xlsx'; // 商户管理
        saveAs(blob, saveName);

        return;
      }
      ddd();
    },
    // 导出全部
    exportDataAll() {
      this.isClickAll = true;
      const param = this.queryForm.params;
      customerMerchantExportAll(param).then(res => {
        if (res.status === 'OK') {
          this.isClickAll = false;
          goExportList(this);
        }
      }).catch(() => {
        this.isClickAll = false;
      });
    },
    // 客户名称
    changeCustomer() {
      this.addQueryForm.customerNumber = '';
      this.customerNameList.map(item => {
        if (item.id === this.addQueryForm.customerId) {
          this.addQueryForm.customerNumber = item.code;
        }
      });
    },
    // 获取所有网点
    getAllSiteIdList() {
      searchSitequeryAllSite().then(res => {
        if (res.status === 'OK') {
          this.siteAllList = [];
          res.data.forEach(data => {
            this.siteAllList.push({ id: data.id, name: data.name });
          });
        }
      });
    },
    // 获取已启用网点
    getSiteIdList() {
      searchSiteIdList().then(res => {
        if (res.status === 'OK') {
          this.siteList = [];
          res.data.forEach(data => {
            this.siteList.push({ id: data.id, name: data.name });
          });
        }
      });
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        this.queryForm.params.startTime = '';
        this.queryForm.params.endTime = '';
        return;
      }
      this.queryForm.params.startTime = val[0] + ' 00:00:00';
      this.queryForm.params.endTime = val[1] + ' 23:59:59';

      if (this.queryForm.params.endTime) {
        const endTime = dayjs(this.queryForm.params.startTime).subtract(-1, 'year').format('YYYY-MM-DD') + ' 23:59:59';
        if (this.queryForm.params.endTime > endTime) {
          this.$message({
            // 最多可查询到一年的数据
            message: this.$t('collectionCenter.moreYear'),
            type: 'warning'
          });
          const d = new Date();
          const today = dayjs(d).format('YYYY-MM-DD 23:59:59');
          const day = dayjs(d).subtract(1, 'month').format('YYYY-MM-DD 00:00:00');
          this.timeValue = [day, today];
          this.queryForm.params.startTime = day;
          this.queryForm.params.endTime = today;
        }
      }
    },
    // 查询所有客户信息
    getAllCustomer() {
      // 查询所有客户信息
      queryAllCustomer().then(res => {
        if (res.status === 'OK') {
          this.customerNameList = [];
          res.data.forEach((list) => {
            this.customerNameList.push({
              id: list.id,
              username: list.username,
              code: list.code
            });
          });
        }
      });
    },
    changeCustomerName() {
      this.changeMerchant();
    },
    changeMerchant() { // 切换商户号/名称 清空输入框
      this.merchantCodeList = [];
      this.merchantCodeValue = '';
      this.queryForm.params.merchantName = '';
      this.queryForm.params.merchantCode = '';
      this.queryForm.params.collectType = null;
      this.queryForm.params.enabledFlag = null;
      this.queryForm.params.siteIdList = [];
      this.queryForm.params.soteIdBool = false;
    },
    remoteMethod(value) {
      const merchantCodeList = [];
      if (this.queryForm.params.customerId) {
        if (value !== '') {
          this.loading = true;
          let merchantForm = {};
          if (this.merchantChoose === 'm1') {
            merchantForm = {
              customerId: this.queryForm.params.customerId,
              merchantCode: value,
              merchantName: ''
            };
          } else {
            merchantForm = {
              customerId: this.queryForm.params.customerId,
              merchantCode: '',
              merchantName: value
            };
          }
          setTimeout(() => {
            queryAllMerchantCode(merchantForm).then(res => {
              if (res.status === 'OK') {
                res.data.records.forEach(list => {
                  if (list.merchantName) {
                    merchantCodeList.push({
                      name: list.merchantCode + ' / ' + list.merchantName
                    });
                  } else {
                    merchantCodeList.push({
                      name: list.merchantCode
                    });
                  }
                });
              }
            });
            this.merchantCodeList = merchantCodeList;
            this.loading = false;
          }, 200);
        } else {
          this.merchantCodeList = [];
        }
      } else {
        this.merchantCodeValue = '';
        this.$message.warning(this.$t('collectionCenter.chooseCustomerName')); // '请选择客户名称'
      }
    },
    changeValue(value) { // 选中商户号/名称
      value = value.split(' / ');
      this.queryForm.params.merchantCode = value[0];
      this.queryForm.params.merchantName = value[1];
    },
    default() {
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD 23:59:59');
      const day = dayjs(d).subtract(1, 'month').format('YYYY-MM-DD 00:00:00');
      this.timeValue = [day, today];
      this.queryForm.params = {
        startTime: day,
        endTime: today,
        customerId: '',
        merchantCode: '',
        merchantName: '',
        collectType: null, // 揽收类型
        enabledFlag: null, // 状态
        siteIdList: [], // 所属网点
        soteIdBool: false // 所属网点为空
      };
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      const query = JSON.parse(localStorage.getItem('mmQueryForm')) || this.queryForm;
      const queryForm = {
        pageNumber: 1,
        pageSize: this.queryForm.pageSize,
        params: query.params
      };
      customerMerchantPage(queryForm).then(res => {
        if (res.status === 'OK') {
          this.total = res.data.total;
          this.tableDate = res.data.records.map(item => {
            const createUserName = getUserName(item.createUser, this.userIdAndNameObj);
            const updateUserName = getUserName(item.updateUser, this.userIdAndNameObj);
            return {
              ...item,
              createUserName,
              updateUserName
            };
          });
        }
      }).catch({});
    },
    searchClick() {
      if (this.queryForm.params.startTime && this.queryForm.params.endTime) {
        this.queryForm.pageNumber = 1;
        localStorage.setItem('mmQueryForm', JSON.stringify(this.queryForm));
        this.getTablePage();
      } else {
        this.$message({
          message: this.$t('collectionCenter.PleaseSelectTime'),
          type: 'warning'
        });
      }
    },
    getTablePage() {
      const queryForm = JSON.parse(localStorage.getItem('mmQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      customerMerchantPage(queryForm).then(res => {
        if (res.status === 'OK') {
          this.total = res.data.total;
          this.tableDate = res.data.records.map(item => {
            const createUserName = getUserName(item.createUser, this.userIdAndNameObj);
            const updateUserName = getUserName(item.updateUser, this.userIdAndNameObj);
            return {
              ...item,
              createUserName,
              updateUserName
            };
          });
        }
      }).catch({});
    },
    resetClick() {
      this.default();
      this.changeMerchant();
    },
    merchantEmailCheck() { // 填写邮箱校验
      if (this.addQueryForm.merchantEmail) {
        const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/;
        if (!reg.test(this.addQueryForm.merchantEmail)) {
          this.$message.error(this.$t('collectionCenter.emailNotFormat')); // '邮箱格式不正确'
          this.checkIsEmail = false;
        } else {
          this.checkIsEmail = true;
        }
      } else {
        this.checkIsEmail = true;
      }
    },
    changeClick(data) { // 修改商户信息
      const row = cloneDeep(data);
      this.drawerTitle = this.$t('collectionCenter.modify'); // '修改'
      this.drawerIsOpen = true;
      this.addQueryForm = {
        customerId: row.customerId,
        merchantCode: row.merchantCode,
        merchantName: row.merchantName,
        contactPhone: row.contactPhone,
        contactAddress: row.contactAddress,
        merchantEmail: row.merchantEmail,
        enabledFlag: row.enabledFlag,
        id: row.id,
        collectType: row.collectType,
        customerNumber: row.customerNumber,
        state: row.state,
        city: row.city,
        siteIds: row.usiteIds ? row.usiteIds.split(',').map(Number) : null
      };
    },
    disableClick() {},
    addClick() { // 新增按钮
      this.drawerTitle = this.$t('collectionCenter.add'); // '新增'
      this.drawerIsOpen = true;
    },
    saveClick() {
      this.$refs['addQueryRef'].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          if (this.checkIsEmail) {
            //                    '是否确认保存?'                        '提示'
            this.$confirm(this.$t('collectionCenter.AreYouSureToSave'), this.$t('collectionCenter.tips'), {
              confirmButtonText: this.$t('collectionCenter.confirm'), // '确定'
              cancelButtonText: this.$t('collectionCenter.cancel') // '取消'
            }).then(() => {
              if (this.drawerTitle === this.$t('collectionCenter.add')) {
                // 新增--提交
                const addQueryForm = {
                  merchantCode: this.addQueryForm.merchantCode,
                  merchantName: this.addQueryForm.merchantName,
                  contactPhone: this.addQueryForm.contactPhone,
                  contactAddress: this.addQueryForm.contactAddress,
                  customerId: this.addQueryForm.customerId,
                  merchantEmail: this.addQueryForm.merchantEmail,
                  siteId: this.addQueryForm.siteName,
                  collectType: this.addQueryForm.collectType,
                  customerNumber: this.addQueryForm.customerNumber,
                  state: this.addQueryForm.state,
                  city: this.addQueryForm.city,
                  siteIds: this.addQueryForm.siteIds
                };
                customerMerchantAdd(addQueryForm).then(res => {
                  if (res.status === 'OK') {
                    this.searchClick();
                    this.cancelClick(); // 清空并解除校验
                  }
                }).catch({});
              }
              if (this.drawerTitle === this.$t('collectionCenter.modify')) {
                const param = cloneDeep(this.addQueryForm);
                customerMerchantUpdate(param).then(res => {
                  if (res.status === 'OK') {
                    this.searchClick();
                    this.cancelClick(); // 清空并解除校验
                  }
                }).catch({});
              }
            }).catch(() => {
              //
            });
          } else {
            this.$message.error(this.$t('collectionCenter.emailNotFormat')); // '邮箱格式不正确'
          }
        }
      });
    },
    cancelClick() { // 新增--取消
      this.drawerIsOpen = false;
      this.addQueryForm = {
        customerId: '',
        merchantCode: '',
        merchantName: '',
        contactPhone: '',
        contactAddress: '',
        merchantEmail: '',
        collectType: '',
        customerNumber: '',
        state: '',
        city: '',
        siteIds: []
      };
      this.$nextTick(() => {
        this.$refs['addQueryRef'].clearValidate();
      });
    }
  }
};
</script>

<style lang="scss" scoped>

  // 防止按钮重复点击
  @keyframes throttle{
    from{
      filter: opacity(40%);
      pointer-events: none;
    }
    to{
      pointer-events: all;
    }
  }
  .onlyClickOnce2{
    animation: throttle 2s step-end forwards;
  }
  .onlyClickOnce2:active{
    filter: opacity(40%);
    animation: none;
  }
  .onlyClickOnce10{
    animation: throttle 10s step-end forwards;
  }
  .onlyClickOnce10:active{
    filter: opacity(40%);
    animation: none;
  }

  .autoWidth ::v-deep .el-input__prefix{ // 下拉框自适应占位
    position: relative;
    left: 0;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    padding: 0 25px;
    padding-left: 0;
    margin-left: 20px;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    visibility: hidden;
    white-space: nowrap;
  }
  .autoWidth ::v-deep input {
    position: absolute;
  }
  .selectClass ::v-deep .el-form-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .selectClass ::v-deep .el-form-item__label{
    padding: 0;
    line-height: 16px;
    white-space: nowrap;
    margin-left: 20px;
  }
  .selectClass ::v-deep .el-form-item__content{
    margin-left: 0 !important;
    width: 100%;
  }
  .selectClass ::v-deep .el-select{
    width: 100%;
  }

  .MerchantManagementBox, .addBox{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .MerchantNameClass ::v-deep textarea.el-textarea__inner {
    min-height: 40px !important;
    max-height: 100px !important;
  }
  .MerchantNumClass ::v-deep textarea.el-textarea__inner {
    min-height: 40px !important;
    max-height: 60px !important;
  }
  .MerchantNumClass ::v-deep .el-textarea .el-input__count, .MerchantNameClass ::v-deep .el-textarea .el-input__count{
    bottom: 3px !important;
    line-height: 12px;
    background: rgba(0,0,0,0);
  }
  .MerchantManagementBox .selectClass ::v-deep .el-select{
    width: 100%;
  }
  .MerchantManagementBox .delMl ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
  .MerchantManagementBox .col10 ::v-deep .el-select, .addBox ::v-deep .el-select{
    width: 100%;
  }
  .MerchantManagementBox .m ::v-deep .el-form-item__content{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0px !important;
  }
  .MerchantManagementBox .m ::v-deep .el-form-item__content .selectTimeClass{
    width: auto !important;
  }
  .MerchantManagementBox .selectTimeClass{
    margin-left: 2px;
  }
  .MerchantManagementBox .selectTimeClass ::v-deep.el-input--suffix .el-input__inner{
    border: none !important;
    font-size: 14px;
    color: #606266;
    font-weight: bold;
    text-align: right;
    padding: 0 25px;
    padding-left: 0;
  }
</style>
