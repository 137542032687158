var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "searchBox" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              attrs: { inline: false, "label-position": "top" },
              model: {
                value: _vm.queryForm,
                callback: function ($$v) {
                  _vm.queryForm = $$v
                },
                expression: "queryForm",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 50 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("navbar.cargoOrderStatus") } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t("GlobalSetObj.all"),
                                clearable: "",
                                filterable: "",
                                multiple: "",
                                size: "small",
                              },
                              model: {
                                value: _vm.queryForm.params.cargoOrderStatus,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "cargoOrderStatus",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.cargoOrderStatus",
                              },
                            },
                            _vm._l(_vm.waybillStatusList, function (item) {
                              return _c("el-option", {
                                key: item.code,
                                attrs: { label: item.label, value: item.code },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("newOrder.CustomerName") } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "spareNumberPoo.Only10AreDisplayed"
                                ),
                                "remote-method": _vm.getCustomerPage,
                                clearable: "",
                                filterable: "",
                                remote: "",
                                "reserve-keyword": "",
                                size: "small",
                              },
                              on: {
                                focus: function ($event) {
                                  return _vm.getCustomerPage("")
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.customerId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "customerId",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.customerId",
                              },
                            },
                            _vm._l(_vm.customerList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label:
                                    item.customerAlias +
                                    "(" +
                                    item.username +
                                    ")",
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("navbar.senderState") } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                                size: "small",
                              },
                              model: {
                                value: _vm.queryForm.params.senderState,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "senderState",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.senderState",
                              },
                            },
                            _vm._l(_vm.StateList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.label, value: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("navbar.receiveState") } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                                size: "small",
                              },
                              model: {
                                value: _vm.queryForm.params.receiveState,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "receiveState",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.receiveState",
                              },
                            },
                            _vm._l(_vm.StateList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.label, value: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.LanShouSite"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.OnlyAreDisplayed"
                                ),
                                "remote-method": _vm.remoteGetSiteList,
                                clearable: "",
                                filterable: "",
                                remote: "",
                                "reserve-keyword": "",
                                size: "small",
                              },
                              on: {
                                focus: function ($event) {
                                  return _vm.remoteGetSiteList("")
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.collectStationId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "collectStationId",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.collectStationId",
                              },
                            },
                            _vm._l(_vm.siteList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("GlobalSetObj.preEntrega") } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.OnlyAreDisplayed"
                                ),
                                "remote-method": _vm.remoteGetSiteList,
                                clearable: "",
                                filterable: "",
                                remote: "",
                                "reserve-keyword": "",
                                size: "small",
                              },
                              on: {
                                focus: function ($event) {
                                  return _vm.remoteGetSiteList("")
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.preDeliverStationId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "preDeliverStationId",
                                    $$v
                                  )
                                },
                                expression:
                                  "queryForm.params.preDeliverStationId",
                              },
                            },
                            _vm._l(_vm.siteList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("GlobalSetObj.entrega") } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.OnlyAreDisplayed"
                                ),
                                "remote-method": _vm.remoteGetSiteList,
                                clearable: "",
                                filterable: "",
                                remote: "",
                                "reserve-keyword": "",
                                size: "small",
                              },
                              on: {
                                focus: function ($event) {
                                  return _vm.remoteGetSiteList("")
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.deliverStationId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "deliverStationId",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.deliverStationId",
                              },
                            },
                            _vm._l(_vm.siteList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "searchTimeType" } },
                        [
                          _c(
                            "template",
                            { slot: "label" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "selectClass",
                                  style: _vm.IsWidthTime,
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.pleaseChoose"
                                    ),
                                    size: "small",
                                  },
                                  model: {
                                    value: _vm.queryForm.params.searchTimeType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm.params,
                                        "searchTimeType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "queryForm.params.searchTimeType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: "1",
                                    attrs: {
                                      label: _vm.$t("GlobalSetObj.TimeOfOrder"),
                                      value: "1",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "default-time": ["00:00:00", "23:59:59"],
                              "end-placeholder": _vm.$t(
                                "operationCenter.endDate"
                              ),
                              format:
                                _vm.$i18n.locale === "zh"
                                  ? _vm.zhDate
                                  : _vm.ptDate,
                              "start-placeholder": _vm.$t(
                                "operationCenter.startDate"
                              ),
                              "range-separator": "~",
                              size: "small",
                              type: "datetimerange",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                            },
                            model: {
                              value: _vm.timeValue,
                              callback: function ($$v) {
                                _vm.timeValue = $$v
                              },
                              expression: "timeValue",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "template",
                            { slot: "label" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "selectClass",
                                  style: _vm.IsWidth,
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "orderDetails.pleaseChoose"
                                    ),
                                    size: "small",
                                  },
                                  model: {
                                    value:
                                      _vm.queryForm.params.searchNumberType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm.params,
                                        "searchNumberType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "queryForm.params.searchNumberType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: "1",
                                    attrs: {
                                      label: _vm.$t("navbar.anjunOrderNumber"),
                                      value: "1",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "100%",
                                "margin-top": "5px",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "newOrder.PreciseSearch2"
                                  ),
                                  rows: 2,
                                  size: "small",
                                  type: "textarea",
                                },
                                model: {
                                  value: _vm.queryForm.params.searchNumberStr,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "searchNumberStr",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "queryForm.params.searchNumberStr",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "35px" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.enterGetSearch },
                          },
                          [_vm._v(_vm._s(_vm.$t("newOrder.search")))]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "20px" },
                            attrs: { size: "small", type: "info" },
                            on: { click: _vm.resetData },
                          },
                          [_vm._v(_vm._s(_vm.$t("newOrder.reset")) + " ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            "margin-bottom": "10px",
            display: "flex",
            "justify-content": "flex-end",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "success" },
              on: { click: _vm.allExportOrder },
            },
            [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")))]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            "header-cell-style": { background: "#f2f2f2", color: "black" },
            border: "",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("GlobalSetObj.serial"),
              align: "center",
              type: "index",
              width: "80",
            },
          }),
          _vm._l(_vm.tableHeaderList, function (item) {
            return _c("el-table-column", {
              key: item.id,
              attrs: {
                align: item.center,
                label: item.label,
                "min-width": item.cloWidth,
                prop: item.prop,
                "show-overflow-tooltip": false,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        item.prop === "orderNumber"
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass: "textOrder",
                                  on: {
                                    click: function ($event) {
                                      return _vm.toDetail(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.orderNumber))]
                              ),
                            ])
                          : _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row[item.prop]
                                    ? "" + scope.row[item.prop]
                                    : "--"
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("newOrder.operation"),
              align: "center",
              fixed: "right",
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "center",
                          "align-items": "center",
                        },
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              content: _vm.$t("GlobalSetObj.details"),
                              effect: "dark",
                              placement: "top",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "iconfont icon-chakan textOperator",
                              on: {
                                click: function ($event) {
                                  return _vm.toDetail(scope.row)
                                },
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        staticStyle: { margin: "20px 0", "text-align": "right" },
        attrs: {
          "current-page": _vm.queryForm.pageNumber,
          "page-size": _vm.queryForm.pageSize,
          "page-sizes": [10, 50, 100, 200, 400],
          total: _vm.queryForm.total,
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "update:currentPage": function ($event) {
            return _vm.$set(_vm.queryForm, "pageNumber", $event)
          },
          "update:current-page": function ($event) {
            return _vm.$set(_vm.queryForm, "pageNumber", $event)
          },
          "update:pageSize": function ($event) {
            return _vm.$set(_vm.queryForm, "pageSize", $event)
          },
          "update:page-size": function ($event) {
            return _vm.$set(_vm.queryForm, "pageSize", $event)
          },
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c(
        "el-drawer",
        {
          attrs: {
            "append-to-body": true,
            visible: _vm.detailDialog,
            "custom-class": "drawerClass",
            direction: "ltr",
            size: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailDialog = $event
            },
            close: _vm.handledetailDialog,
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("div", [
                    _c("span", { staticStyle: { "margin-right": "20px" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("collectionCenter.largeOrderDetails"))
                      ),
                    ]),
                    _c("span", { staticStyle: { "margin-right": "10px" } }, [
                      _vm._v(
                        " " + _vm._s("" + _vm.detailDialogObj.orderNumber)
                      ),
                    ]),
                    _c("span", [
                      _vm._v(
                        "(" +
                          _vm._s(_vm.$t("navbar.gong")) +
                          " " +
                          _vm._s(_vm.detailDialogObj.totalNumber) +
                          " " +
                          _vm._s(_vm.$t("navbar.jian")) +
                          ")"
                      ),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("Detail", {
            key: _vm.keyChange,
            ref: "Detail",
            attrs: { grandson: _vm.DetailId },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }