<template>
  <div class="app-container">
    <el-card style="margin-bottom: 20px;">
      <el-form
        ref="form"
        :model="queryForm"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="7">
            <el-form-item class="numberClassSelect">
              <template slot="label">
                <el-select
                  v-model="queryForm.params.selectionType"
                  :placeholder="$t('orderDetails.pleaseChoose')"
                >
                  <!--运单号-->
                  <el-option key="1" :label="$t('newOrder.waybillNumber')" :value="1" />
                  <el-option key="3" :label="$t('placeOrder.customerNumber')" :value="2" />
                  <el-option key="2" :label="$t('GlobalSetObj.serviceLogistics')" :value="3" />
                </el-select>
              </template>
              <div class="searchOrder">
                <el-input
                  v-model="queryForm.params.orderNumber"
                  :placeholder="$t('operationCenter.Multiple200')"
                  clearable
                  type="textarea"
                  :rows="4"
                />
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!--入库人-->
            <el-form-item name="customerId">
              <template slot="label">
                <p style="margin: 6px 0px;">
                  {{ $t('operationCenter.InboundOperator') }}
                </p>
              </template>
              <el-select
                v-model="queryForm.params.customerId"
                filterable
                clearable
                :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
                style="width: 100%;"
              >
                <el-option
                  v-for="(item, ind) in customerList"
                  :key="ind"
                  :label="item.realName + (item.username ? '（' + item.username + '）' : '')"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item class="numberClassSelect">
              <template slot="label">
                <el-select v-model="queryForm.params.timeField" :placeholder="$t('operationCenter.PleaseSelect')">
                  <!--入库时间-->
                  <el-option key="1" :label="$t('operationCenter.InboundTime')" :value="1" />
                </el-select>
              </template>
              <!--查询时间-->
              <el-date-picker
                v-model="queryDate"
                style="width: 100%"
                type="datetimerange"
                :range-separator="$t('operationCenter.to')"
                :start-placeholder="$t('operationCenter.startDate')"
                :end-placeholder="$t('operationCenter.endDate')"
                value-format="yyyy-MM-dd HH:mm:ss"
                :picker-options="pickerOptions"
                @blur="refreshDate"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-button
        type="primary"
        size="small"
        icon="el-icon-search"
        @click="searchResources(1)"
      >{{ $t('operationCenter.Procurar') }}</el-button>
      <el-button size="small" icon="el-icon-refresh-left" @click="searchResources(2)">
        {{ $t('operationCenter.reset') }}
      </el-button>
    </el-card>
    <el-card>
      <div>
        <div style="margin-bottom: 10px;">
          <!--          <el-button type="primary" size="small" @click="exportData()">-->
          <!--            {{ $t('operationCenter.exports') }}-->
          <!--          </el-button>-->
        </div>
        <el-table :data="tableData" border>
          <!--序号-->
          <el-table-column :label="$t('operationCenter.ind')" width="60px" align="center">
            <template slot-scope="scope">
              <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <!--运单号-->
          <el-table-column
            v-for="(item, ind) in columnList"
            :key="ind"
            :prop="item.prop"
            :label="item.label"
            :align="item.align"
            :min-width="item.width"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row[item.prop] ? scope.row[item.prop] : '--' }}
              </div>
            </template>
          </el-table-column>
          <!--操作-->
          <el-table-column
            :label="$t('GlobalSetObj.operate')"
            align="center"
            min-width="140"
            fixed="right"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              <div><el-button
                :disabled="!scope.row.photoUrl" type="text" size="small"
                @click="checkWeighPhotos(scope.row)"
              >{{ $t('operationCenter.WeighPhotos') }}</el-button></div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getCurrentTableData"
          @size-change="getSizeTableData"
        />
      </div>
    </el-card>
    <!--全部导出-->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('GlobalSetObj.DataExport')"
      :visible.sync="showExportData"
      width="500px"
      @close="exportType = 'CSV';"
    >
      <div>
        <div style="margin-bottom: 30px;">
          {{ $t('GlobalSetObj.ThereAre') }}
          <span style="color:#E6A23C;">{{ queryForm.total }}</span>
          {{ $t('GlobalSetObj.dataRecordsCurrentFilterCondition') }}
        </div>
        <div style="margin-bottom: 30px;">
          <span style="margin-right: 10px;">{{ $t('collectionCenter.ExportAs') }}</span>
          <el-radio-group v-model="exportType">
            <el-radio label="CSV">CSV</el-radio>
            <el-radio label="XLSX">XLSX</el-radio>
          </el-radio-group>
        </div>
        <div style="margin-bottom: 40px;">
          {{ $t('collectionCenter.ExportIsSuccessful') }}
          <span
            style="color: #3F9DFD;cursor: pointer;"
            @click="toExportList(1)"
          >{{ $t('collectionCenter.AListOfResourceExports') }}</span>
          {{ $t('collectionCenter.systemAdministrationToDownload') }}
          {{ $t('collectionCenter.notRepeatTheExport') }}
        </div>
        <div style="text-align: right;">
          <el-button size="small" @click="toExportList(2)">{{ $t('collectionCenter.CheckBackLater') }}</el-button>
          <el-button size="small" type="primary" @click="toExportList(3)">{{
            $t('collectionCenter.CheckItOutNow')
          }}
          </el-button>
        </div>
      </div>
    </el-dialog>
    <div class="imgs">
      <el-image-viewer
        v-if="imageViewerObj.showViewer"
        :on-close="closeViewer"
        :url-list="imageViewerObj.imgArr"
      />
    </div>
  </div>
</template>

<script>
import { getUserPage } from '@/api/user';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { copyData } from '@/utils/copyData';
import { apiWaybillOperationLogExportAll, waybillOperationLogPage } from '@/api/storeAdmin';
import { getUserName } from '@/utils/asyncTools';
import dayjs from 'dayjs';

export default {
  name: 'WeighTheQuery',
  components: {
    ElImageViewer: () => import('element-ui/packages/image/src/image-viewer')
  },
  data() {
    return {
      userIdAndNameObj: {}, // 用户列表
      customerList: [],
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          orderNumber: '',
          customerId: this.$store.state.user.id,
          timeField: 1,
          selectionType: 1
        }
      },
      queryDate: [this.getAWeek().startDate + ' 00:00:00', this.getAWeek().endDate + ' 23:59:59'],
      tableData: [],
      total: 0,
      columnList: [
        // 运单号
        { label: this.$t('newOrder.waybillNumber'), prop: 'waybillNumber', width: 180, align: 'center' },
        // 客户单号
        { label: this.$t('placeOrder.customerNumber'), prop: 'customerNumber', width: 180, align: 'center' },
        // 服务商单号
        { label: this.$t('GlobalSetObj.serviceLogistics'), prop: 'logisticsNumber', width: 180, align: 'center' },
        // 入库单号
        { label: this.$t('operationCenter.TheInboundOrderNumber'), prop: 'inboundReceiptNumber', width: 180, align: 'center' },
        // 重量
        { label: this.$t('operationCenter.Weight'), prop: 'transportWeight', width: 100, align: 'center' },
        // 客户重量
        { label: this.$t('operationCenter.CustomerWeight'), prop: 'customerWeight', width: 120, align: 'center' },
        // 长
        { label: this.$t('placeOrder.long'), prop: 'length', width: 100, align: 'center' },
        // 宽
        { label: this.$t('placeOrder.width'), prop: 'width', width: 100, align: 'center' },
        // 高
        { label: this.$t('placeOrder.height'), prop: 'height', width: 100, align: 'center' },
        // 派送网点
        { label: this.$t('GlobalSetObj.DeliveryPoint'), prop: 'preDeliverStationName', width: 140, align: 'center' },
        // BaseCode
        { label: 'BaseCode', prop: 'baseCode', width: 140, align: 'center' },
        // 派送路线
        { label: this.$t('GlobalSetObj.DeliveryRoutes'), prop: 'pathNumber', width: 140, align: 'center' },
        // 服务商渠道
        { label: this.$t('GlobalSetObj.ServiceProviderChannels'), prop: 'systemName', width: 140, align: 'center' },
        // 操作人
        { label: this.$t('GlobalSetObj.operator'), prop: 'operator', width: 140, align: 'center' },
        // 操作时间
        { label: this.$t('GlobalSetObj.operationTime'), prop: 'createTime', width: 160, align: 'center' }
      ],
      searchNumberList: [],
      pickerOptions: {
        // 设置不能选择的日期
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate0 = minDate.getTime();
          if (maxDate) {
            this.choiceDate0 = '';
          }
        },
        disabledDate: (time) => {
          const choiceDateTime = new Date(this.choiceDate0).getTime();
          const minTime = new Date(choiceDateTime).setDate(
            new Date(choiceDateTime).getDate() - 30
          );
          const maxTime = new Date(choiceDateTime).setDate(
            new Date(choiceDateTime).getDate() + 30
          );
          const min = minTime;
          const newDate =
            new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000;
          const max = newDate < maxTime ? newDate : maxTime;
          // 如果已经选中一个日期 则 返回 该日期前后一个月时间可选
          if (this.choiceDate0) {
            return time.getTime() < min || time.getTime() > max;
          }
          // 若一个日期也没选中 则 返回 当前日期以前日期可选
          return time.getTime() > newDate;
        }
      },
      checkAll: false,
      imageViewerObj: {
        showViewer: false,
        imgArr: []
      },
      showExportData: false,
      exportType: 'CSV'
    };
  },
  created() {
    // 用户列表
    this.userIdAndNameObj = getUserIdAndNameObj();
    const buttonPermissions = this.$store.getters.button_permissions;
    this.checkAll = buttonPermissions.includes('btn:aRGeneration:onlyAgent');
    if (this.checkAll === false) {
      this.customerList = [
        {
          realName: this.$store.state.user.realName,
          username: this.$store.state.user.username,
          id: this.$store.state.user.id
        }
      ];
    } else {
      // 获取入库员选项
      this.getUserManageOption();
    }
  },
  methods: {
    // 关闭查看图片
    closeViewer() {
      this.$set(this.imageViewerObj, 'showViewer', false);
    },
    // 称重照片查看
    checkWeighPhotos(row) {
      const url = row.photoUrl.split(',');
      this.$set(this.imageViewerObj, 'showViewer', true);
      this.$set(this.imageViewerObj, 'imgArr', url);
    },
    // 导出提示
    exportData() {
      this.showExportData = true;
    },
    // 导出
    toExportList(type) {
      const paramData = this.searchNumberCollation().params;
      paramData.exportType = this.exportType;
      if (type === 1) {
        // 只跳转不导出
        this.showExportData = false;
        this.$router.push({ path: '/resource/exportList' });
        return;
      }
      apiWaybillOperationLogExportAll(paramData).then(res => {
        if (res.status === 'OK') {
          this.$message.success(this.$t('collectionCenter.ExportSuccessful1'));
          this.showExportData = false;
          if (type === 3) {
            this.$router.push({ path: '/resource/exportList' });
          }
        }
      });
    },
    // 日期选择器失去焦点
    refreshDate() {
      this.choiceDate0 = '';
    },
    // 查询单号整理
    searchNumberCollation() {
      this.searchNumberList = [];
      if (this.queryForm.params.orderNumber) {
        const orderList = this.queryForm.params.orderNumber.split('\n');
        orderList.map(item => {
          const number = item.replace(/\s/g, '').trim();
          if (number) {
            this.searchNumberList.push(number);
          }
        });
      }
      const param = {
        pageNumber: this.queryForm.pageNumber,
        pageSize: this.queryForm.pageSize,
        params: {
          waybillNumberList: [],
          customerNumberList: [],
          logisticsNumberList: [],
          inStorageStartTime: '',
          inStorageEndTime: '',
          userId: this.queryForm.params.customerId,
          queryType: 2
        }
      };
      if (this.queryForm.params.selectionType === 1) {
        param.params.waybillNumberList = this.searchNumberList;
        param.params.customerNumberList = [];
        param.params.logisticsNumberList = [];
      } else if (this.queryForm.params.selectionType === 2) {
        param.params.waybillNumberList = [];
        param.params.customerNumberList = this.searchNumberList;
        param.params.logisticsNumberList = [];
      } else if (this.queryForm.params.selectionType === 3) {
        param.params.waybillNumberList = [];
        param.params.customerNumberList = [];
        param.params.logisticsNumberList = this.searchNumberList;
      }
      if (this.queryForm.params.timeField === 1) {
        if (this.queryDate && this.queryDate.length > 0) {
          param.params.inStorageStartTime = this.queryDate[0];
          param.params.inStorageEndTime = this.queryDate[1];
        }
      }
      return param;
    },
    // 点击复制
    copy(data) {
      copyData(data);
    },
    // 搜索、重置
    searchResources(type) {
      if (type === 1) {
        this.queryForm.pageNumber = 1;
        this.getTableData();
      } else {
        this.queryForm = {
          pageNumber: 1,
          pageSize: 10,
          params: {
            orderNumber: '',
            customerId: this.$store.state.user.id,
            timeField: 1,
            selectionType: 1
          }
        };
        this.queryDate = [this.getAWeek().startDate + ' 00:00:00', this.getAWeek().endDate + ' 23:59:59'];
      }
    },
    // 获取最近7天时间
    getAWeek() {
      return {
        endDate: dayjs().format('YYYY-MM-DD'),
        startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD')
      };
    },
    getTableData() {
      const paramData = this.searchNumberCollation();
      if (this.searchNumberList.length > 200) {
        this.$message.warning(this.$t('operationCenter.queried200pieces'));
        return;
      }
      waybillOperationLogPage(paramData).then(res => {
        if (res.status === 'OK') {
          this.tableData = res.data.records;
          this.total = res.data.total;
          this.tableData.map((item) => {
            item.systemName = item.systemName ? item.systemName : '- -';
            item.pathNumber = item.pathNumber ? item.pathNumber : '- -';
            item.baseCode = item.baseCode ? item.baseCode : '- -';
            item.preDeliverStationName = item.preDeliverStationName ? item.preDeliverStationName : '- -';
            item.operator = getUserName(item.createUser, this.userIdAndNameObj);
          });
        }
      });
    },
    // 获取入库人选项
    getUserManageOption() {
      const param = {
        pageSize: 9999999,
        pageNumber: 1,
        params: {
          accountState: '1'
        }
      };
      getUserPage(param).then((response) => {
        if (response.status === 'OK') {
          const { records } = response.data;
          const obj = {
            realName: this.$t('GlobalSetObj.all'),
            id: null
          };
          this.customerList = records;
          this.customerList.unshift(obj);
        }
      });
    },
    // 页码变化翻页
    getCurrentTableData(val) {
      this.queryForm.pageNumber = val;
      this.getTableData();
    },
    // 每页显示数量变化
    getSizeTableData(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.getTableData();
    }
  }
};
</script>
<style>
.numberClassSelect .el-form-item__label .el-select .el-input__inner {
  border: none;
  padding: 0;
}
.numberClassSelect.el-form-item {
  margin-bottom: 10px;
}
</style>
<style scoped>
.imgs /deep/ .el-icon-circle-close {
  color: white;
}
</style>
