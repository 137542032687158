var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "wrapper-form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        },
      },
    },
    [
      _c(
        "o-field",
        {
          staticClass: "col-span-4",
          attrs: {
            label: "Motorista",
            "label-for": "driver",
            message: _vm.v$.nameDriver.$errors.length
              ? _vm.v$.nameDriver.$errors[0].$message
              : null,
          },
        },
        [
          _c(
            "o-autocomplete",
            {
              attrs: {
                id: "driver",
                placeholder: "Digite o nome de um motorista existente",
                expanded: "",
                data: _vm.listDrivers,
              },
              on: { input: _vm.filteredDriver, select: _vm.selectedDriver },
              model: {
                value: _vm.formData.nameDriver,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "nameDriver", $$v)
                },
                expression: "formData.nameDriver",
              },
            },
            [_c("template", { slot: "empty" }, [_vm._v("Sem resultados")])],
            2
          ),
        ],
        1
      ),
      _c(
        "o-field",
        {
          staticClass: "col-span-2",
          attrs: {
            label: "Documento",
            "label-for": "document",
            message: _vm.v$.documentOwnerVehicle.$errors.length
              ? _vm.v$.documentOwnerVehicle.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            directives: [
              {
                name: "mask",
                rawName: "v-mask",
                value: ["###.###.###-##", "##.###.###/####-##"],
                expression: "['###.###.###-##', '##.###.###/####-##']",
              },
            ],
            attrs: {
              id: "document",
              placeholder: "Digite o documento do dono do veículo",
            },
            model: {
              value: _vm.formData.documentOwnerVehicle,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "documentOwnerVehicle", $$v)
              },
              expression: "formData.documentOwnerVehicle",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          staticClass: "col-span-2",
          attrs: {
            label: "IE",
            "label-for": "ie",
            message: _vm.v$.ie.$errors.length
              ? _vm.v$.ie.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: { id: "ie", placeholder: "Digite a IE do dono" },
            model: {
              value: _vm.formData.ie,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "ie", $$v)
              },
              expression: "formData.ie",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          staticClass: "col-span-2",
          attrs: {
            label: "RNTRC",
            "label-for": "rntrc",
            message: _vm.v$.rntrc.$errors.length
              ? _vm.v$.rntrc.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: { id: "rntrc", placeholder: "Digite o RNTRC" },
            model: {
              value: _vm.formData.rntrc,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "rntrc", $$v)
              },
              expression: "formData.rntrc",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          staticClass: "col-span-2",
          attrs: {
            label: "RENAVAM",
            "label-for": "renavam",
            message: _vm.v$.renavam.$errors.length
              ? _vm.v$.renavam.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: { id: "renavam", placeholder: "Digite o RENAVAM" },
            model: {
              value: _vm.formData.renavam,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "renavam", $$v)
              },
              expression: "formData.renavam",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          staticClass: "col-span-4",
          attrs: {
            label: "Nome da empresa",
            "label-for": "companyDriver",
            message: _vm.v$.company.$errors.length
              ? _vm.v$.company.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "companyDriver",
              placeholder: "Digite a empresa do motorista",
            },
            model: {
              value: _vm.formData.company,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "company", $$v)
              },
              expression: "formData.company",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          staticClass: "col-span-2",
          attrs: {
            label: "Placa",
            "label-for": "licensePlate",
            message: _vm.v$.licensePlate.$errors.length
              ? _vm.v$.licensePlate.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            directives: [
              {
                name: "mask",
                rawName: "v-mask",
                value: "AAA-XXXX",
                expression: "'AAA-XXXX'",
              },
            ],
            attrs: {
              id: "licensePlate",
              placeholder: "Digite a placa do veículo",
            },
            model: {
              value: _vm.formData.licensePlate,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "licensePlate", $$v)
              },
              expression: "formData.licensePlate",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: "Capacidade (KG)",
            "label-for": "capacityKg",
            message: _vm.v$.capacityKg.$errors.length
              ? _vm.v$.capacityKg.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: { id: "capacityKg", placeholder: "100" },
            model: {
              value: _vm.formData.capacityKg,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "capacityKg", $$v)
              },
              expression: "formData.capacityKg",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: "Tara",
            "label-for": "tara",
            message: _vm.v$.tara.$errors.length
              ? _vm.v$.tara.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: { id: "tara", placeholder: "03" },
            model: {
              value: _vm.formData.tara,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "tara", $$v)
              },
              expression: "formData.tara",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          staticClass: "col-span-2",
          attrs: {
            label: "Tipo de Corpo",
            "label-for": "bodyType",
            message: _vm.v$.bodyType.$errors.length
              ? _vm.v$.bodyType.$errors[0].$message
              : null,
          },
        },
        [
          _c(
            "o-select",
            {
              attrs: {
                id: "bodyType",
                placeholder: "Selecione um tipo de corpo",
              },
              model: {
                value: _vm.formData.bodyType,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "bodyType", $$v)
                },
                expression: "formData.bodyType",
              },
            },
            _vm._l(_vm.optionsBodyType, function (bodyType) {
              return _c(
                "option",
                { key: bodyType.value, domProps: { value: bodyType.value } },
                [_vm._v(" " + _vm._s(bodyType.label) + " ")]
              )
            }),
            0
          ),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: "Tipo de roda",
            "label-for": "wheelsetType",
            message: _vm.v$.wheelsetType.$errors.length
              ? _vm.v$.wheelsetType.$errors[0].$message
              : null,
          },
        },
        [
          _c(
            "o-select",
            {
              attrs: { id: "wheelsetType", placeholder: "tipo de roda" },
              model: {
                value: _vm.formData.wheelsetType,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "wheelsetType", $$v)
                },
                expression: "formData.wheelsetType",
              },
            },
            _vm._l(_vm.optionsWheelsetType, function (wheelsetType) {
              return _c(
                "option",
                {
                  key: wheelsetType.value,
                  domProps: { value: wheelsetType.value },
                },
                [_vm._v(" " + _vm._s(wheelsetType.label) + " ")]
              )
            }),
            0
          ),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: "UF",
            "label-for": "uf",
            message: _vm.v$.uf.$errors.length
              ? _vm.v$.uf.$errors[0].$message
              : null,
          },
        },
        [
          _c(
            "o-select",
            {
              attrs: { id: "uf", placeholder: "SP" },
              model: {
                value: _vm.formData.uf,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "uf", $$v)
                },
                expression: "formData.uf",
              },
            },
            _vm._l(_vm.listUF, function (UF) {
              return _c("option", { key: UF, domProps: { value: UF } }, [
                _vm._v(" " + _vm._s(UF) + " "),
              ])
            }),
            0
          ),
        ],
        1
      ),
      _c(
        "FormActions",
        [
          _c("FormActionsPrevious", {
            attrs: { "is-first-step": _vm.isFirstStep },
            on: { handlePrevious: _vm.handlePrevious },
          }),
          _c("FormActionsNext", { attrs: { "is-last-step": _vm.isLastStep } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }