import request from '@/utils/request';
const requestCargo = process.env.VUE_APP_BASE_APICargo; // /api/v1/cargo

// 大件快递

/**
 * 大件订单——修改预派送网点
 */
export const allocationDeliverSite = (data) => {
  return request({
    url: `/cargo/order/client/allocationDeliverSite`,
    method: 'POST',
    data,
    baseURL: requestCargo
  });
};

/**
 * 大件订单——大件轨迹
 */
export const getCargoTracking = (data) => {
  return request({
    url: `/cargo/waybill/getCargoTracking?waybillNumber=${data}`,
    method: 'GET'
  });
};

/**
 * 大件订单——运单签收信息
 */
export const getReceiveInfo = (data) => {
  return request({
    url: `/cargo/attachment/getReceiveInfo?waybillId=${data}`,
    method: 'GET'
  });
};

/**
 * 大件订单——备注记录列表
 */
export const remarkWaybillId = (data) => {
  return request({
    url: `/cargo/remark/list?waybillId=${data}`,
    method: 'GET'
  });
};

/**
 * 大件订单——查询照片附件
 */
export const cargoWaybillPhoto = (data) => {
  return request({
    url: `/cargo/waybill/findPhoto/${data}`,
    method: 'get'
  });
};

/**
 * 大件订单——查询运单详情
 */
export const cargoWaybill = (data) => {
  return request({
    url: `/cargo/waybill/${data}`,
    method: 'get'
  });
};

/**
 * 大件订单——导出/全部导出
 */
export const exportWaybill = (data) => {
  return request({
    url: `/cargo/waybill/exportWaybill`,
    method: 'POST',
    data
  });
};

/**
 * 大件订单——下载快递标签
 */
export const downloadLabelLog = (data) => {
  return request({
    url: `/cargo/waybill/downloadLabelLog`,
    method: 'POST',
    data
  });
};

/**
 * 大件订单——新增备注
 */
export const cargoRemarkAdd = (data) => {
  return request({
    url: `/cargo/remark/add`,
    method: 'POST',
    data
  });
};

/**
 * 大件订单——分配揽收网点
 */
export const assignmentCollectSite = (data) => {
  return request({
    url: '/cargo/order/assignmentCollectSite',
    method: 'POST',
    data
  });
};

/**
 * 大件订单——分页
 */
export const waybillPage = (data) => {
  return request({
    url: '/cargo/waybill/page',
    method: 'POST',
    data
  });
};

/**
 * 揽收装车记录————所有车辆下拉，默认展示10条
 */
export const queryVehiclePage = (data) => {
  return request({
    url: `/collectVehicle/queryVehiclePage?pageSize=10&vehicleNumber=${data}`,
    method: 'get'
  });
};
/**
 * 揽收装车记录————查询所属网点州
 */
export const queryState = (data) => {
  return request({
    url: `/site/queryOptionSelectForState?name=${data}&pageSize=1000`,
    method: 'get'
  });
};
/**
 * 揽收装车记录————所有网点下拉，默认展示10条
 */
export const query10Site = (data) => {
  return request({
    url: `/site/queryPage?name=${data}`,
    method: 'get'
  });
};
/**
 * 揽收装车记录————所有员工下拉，默认展示10条
 */
export const query10User = (data) => {
  return request({
    url: `/user/queryPage?realName=${data}`,
    method: 'get'
  });
};
/**
 * 揽收装车记录————所有客户下拉，默认展示10条
 */
export const queryCustomerName = (data) => {
  return request({
    url: `/customer/queryPage?customerName=${data}`,
    method: 'GET'
  });
};

/**
 * 揽收装车记录————分页
 */
export const collectBulkyLoadPage = (data) => {
  return request({
    url: `/collectBulkyLoad/page`,
    method: 'post',
    data
  });
};

/**
 * 揽收装车记录————导出
 */
export const collectBulkyLoadExportAll = (data) => {
  return request({
    url: `/collectBulkyLoad/exportAll`,
    method: 'post',
    data
  });
};
