var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Box" }, [
    _c(
      "div",
      { staticStyle: { "margin-bottom": "20px" } },
      [
        _c(
          "el-form",
          [
            _c(
              "el-row",
              { staticClass: "selectClass" },
              [
                _c(
                  "el-col",
                  { staticClass: "col2" },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "selectTimeClass autoWidth",
                        attrs: {
                          placeholder: _vm.$t("collectionCenter.pleaseChoose"),
                        },
                        on: { change: _vm.changeTime },
                        model: {
                          value: _vm.selectTime,
                          callback: function ($$v) {
                            _vm.selectTime = $$v
                          },
                          expression: "selectTime",
                        },
                      },
                      [
                        _c("template", { slot: "prefix" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                (
                                  _vm.timeList.find(function (item) {
                                    return item.value === _vm.selectTime
                                  }) || {}
                                ).time
                              ) +
                              " "
                          ),
                        ]),
                        _vm._l(_vm.timeList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.time, value: item.value },
                          })
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "delMl" },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            clearable: false,
                            type: "datetimerange",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            "default-time": ["00:00:00", "23:59:59"],
                            "range-separator": _vm.$t("collectionCenter.to"),
                            "start-placeholder": _vm.$t(
                              "collectionCenter.StartTime"
                            ),
                            "end-placeholder": _vm.$t(
                              "collectionCenter.EndTime"
                            ),
                          },
                          on: { change: _vm.dateChange },
                          model: {
                            value: _vm.timeValue,
                            callback: function ($$v) {
                              _vm.timeValue = $$v
                            },
                            expression: "timeValue",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticClass: "col2" },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "selectTimeClass autoWidth",
                                attrs: {
                                  placeholder: _vm.$t(
                                    "collectionCenter.pleaseChoose"
                                  ),
                                },
                                on: { change: _vm.changeCode },
                                model: {
                                  value: _vm.selectCode,
                                  callback: function ($$v) {
                                    _vm.selectCode = $$v
                                  },
                                  expression: "selectCode",
                                },
                              },
                              [
                                _c("template", { slot: "prefix" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (
                                          _vm.selectCodeList.find(function (
                                            item
                                          ) {
                                            return item.value === _vm.selectCode
                                          }) || {}
                                        ).label
                                      ) +
                                      " "
                                  ),
                                ]),
                                _vm._l(
                                  _vm.selectCodeList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _vm.selectCode == "c1"
                          ? _c(
                              "el-col",
                              { staticStyle: { width: "auto" } },
                              [
                                _c(
                                  "el-form-item",
                                  { staticClass: "siteClass" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        autosize: "",
                                        placeholder: _vm.$t(
                                          "collectionCenter.BatchQuery"
                                        ),
                                      },
                                      nativeOn: {
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.BlockNewlineCode(
                                            _vm.queryForm.params.orderNumber
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.queryForm.params.orderNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "orderNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.orderNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.selectCode == "c2"
                          ? _c(
                              "el-col",
                              { staticStyle: { width: "auto" } },
                              [
                                _c(
                                  "el-form-item",
                                  { staticClass: "siteClass" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        autosize: "",
                                        placeholder: _vm.$t(
                                          "collectionCenter.BatchQuery"
                                        ),
                                      },
                                      nativeOn: {
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.BlockNewlineCode(
                                            _vm.queryForm.params.boxBarCode
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.queryForm.params.boxBarCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "boxBarCode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.boxBarCode",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.selectCode == "c3"
                          ? _c(
                              "el-col",
                              { staticStyle: { width: "auto" } },
                              [
                                _c(
                                  "el-form-item",
                                  { staticClass: "siteClass" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        autosize: "",
                                        placeholder: _vm.$t(
                                          "collectionCenter.BatchQuery"
                                        ),
                                      },
                                      nativeOn: {
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.BlockNewlineCode(
                                            _vm.queryForm.params.customerNumber
                                          )
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.queryForm.params.customerNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "customerNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.customerNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.selectCode == "c4"
                          ? _c(
                              "el-col",
                              { staticStyle: { width: "auto" } },
                              [
                                _c(
                                  "el-form-item",
                                  { staticClass: "siteClass" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        autosize: "",
                                        placeholder: _vm.$t(
                                          "collectionCenter.BatchQuery"
                                        ),
                                      },
                                      nativeOn: {
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.BlockNewlineCode(
                                            _vm.queryForm.params.bagNumber
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.queryForm.params.bagNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "bagNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.bagNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticClass: "col2" },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "selectTimeClass autoWidth",
                                attrs: {
                                  placeholder: _vm.$t(
                                    "collectionCenter.pleaseChoose"
                                  ),
                                },
                                on: { change: _vm.changePeople },
                                model: {
                                  value: _vm.selectPeople,
                                  callback: function ($$v) {
                                    _vm.selectPeople = $$v
                                  },
                                  expression: "selectPeople",
                                },
                              },
                              [
                                _c("template", { slot: "prefix" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (
                                          _vm.selectPeopleList.find(function (
                                            item
                                          ) {
                                            return (
                                              item.value === _vm.selectPeople
                                            )
                                          }) || {}
                                        ).label
                                      ) +
                                      " "
                                  ),
                                ]),
                                _vm._l(
                                  _vm.selectPeopleList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _vm.selectPeople == "n1"
                          ? _c(
                              "el-col",
                              { staticStyle: { width: "auto" } },
                              [
                                _c(
                                  "el-form-item",
                                  { staticClass: "siteClass" },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          clearable: "",
                                          remote: "",
                                          "reserve-keyword": "",
                                          placeholder: _vm.$t(
                                            "collectionCenter.pleaseInput"
                                          ),
                                          "remote-method": _vm.driverNameMethod,
                                          loading: _vm.loading,
                                        },
                                        model: {
                                          value:
                                            _vm.queryForm.params.collectUser,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryForm.params,
                                              "collectUser",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "queryForm.params.collectUser",
                                        },
                                      },
                                      _vm._l(
                                        _vm.driverNameList,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.driverName,
                                              value: item.driverId,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.selectPeople == "n2"
                          ? _c(
                              "el-col",
                              { staticStyle: { width: "auto" } },
                              [
                                _c(
                                  "el-form-item",
                                  { staticClass: "siteClass" },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          clearable: "",
                                          remote: "",
                                          "reserve-keyword": "",
                                          placeholder: _vm.$t(
                                            "collectionCenter.pleaseInput"
                                          ),
                                          "remote-method":
                                            _vm.signDriverNameMethod,
                                          loading: _vm.loading,
                                        },
                                        model: {
                                          value:
                                            _vm.queryForm.params.signerUser,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryForm.params,
                                              "signerUser",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "queryForm.params.signerUser",
                                        },
                                      },
                                      _vm._l(
                                        _vm.signDriverNameList,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.name,
                                              value: item.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticClass: "col2" },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "selectTimeClass autoWidth",
                                attrs: {
                                  placeholder: _vm.$t(
                                    "collectionCenter.pleaseChoose"
                                  ),
                                },
                                on: { change: _vm.changeSite },
                                model: {
                                  value: _vm.selectSite,
                                  callback: function ($$v) {
                                    _vm.selectSite = $$v
                                  },
                                  expression: "selectSite",
                                },
                              },
                              [
                                _c("template", { slot: "prefix" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (
                                          _vm.stList.find(function (item) {
                                            return item.value === _vm.selectSite
                                          }) || {}
                                        ).label
                                      ) +
                                      " "
                                  ),
                                ]),
                                _vm._l(_vm.stList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _vm.selectSite == "s1"
                          ? _c(
                              "el-col",
                              { staticStyle: { width: "auto" } },
                              [
                                _c(
                                  "el-form-item",
                                  { staticClass: "siteClass" },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "collectionCenter.pleaseChoose"
                                          ),
                                          clearable: "",
                                          filterable: "",
                                        },
                                        on: { focus: _vm.getNewestSite },
                                        model: {
                                          value:
                                            _vm.queryForm.params.collectSiteId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryForm.params,
                                              "collectSiteId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "queryForm.params.collectSiteId",
                                        },
                                      },
                                      _vm._l(
                                        _vm.siteIdList,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.name,
                                              value: item.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.selectSite == "s2"
                          ? _c(
                              "el-col",
                              { staticStyle: { width: "auto" } },
                              [
                                _c(
                                  "el-form-item",
                                  { staticClass: "siteClass" },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "collectionCenter.pleaseChoose"
                                          ),
                                          clearable: "",
                                          filterable: "",
                                        },
                                        on: { focus: _vm.getNewestSite },
                                        model: {
                                          value:
                                            _vm.queryForm.params.signerSiteId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryForm.params,
                                              "signerSiteId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "queryForm.params.signerSiteId",
                                        },
                                      },
                                      _vm._l(
                                        _vm.siteIdList,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.name,
                                              value: item.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("collectionCenter.orderStatus"),
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              multiple: "",
                              placeholder: _vm.$t(
                                "collectionCenter.pleaseChoose"
                              ),
                              clearable: "",
                              filterable: "",
                            },
                            model: {
                              value: _vm.queryForm.params.boxStatusList,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm.params,
                                  "boxStatusList",
                                  $$v
                                )
                              },
                              expression: "queryForm.params.boxStatusList",
                            },
                          },
                          _vm._l(_vm.orderTypeList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: {
                                label: item.collectStatusName,
                                value: item.collectStatus,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-row",
          { attrs: { type: "flex", justify: "space-between" } },
          [
            _c(
              "el-col",
              { attrs: { span: 24 } },
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value:
                          "btn:collection-center:order:warehouse-warehouse:find",
                        expression:
                          "'btn:collection-center:order:warehouse-warehouse:find'",
                        arg: "remove",
                      },
                    ],
                    attrs: {
                      type: "primary",
                      size: "mini",
                      icon: "el-icon-search",
                    },
                    on: { click: _vm.searchClick },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.search")))]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value:
                          "btn:collection-center:order:warehouse-warehouse:reset",
                        expression:
                          "'btn:collection-center:order:warehouse-warehouse:reset'",
                        arg: "remove",
                      },
                    ],
                    attrs: { icon: "el-icon-refresh", size: "mini" },
                    on: { click: _vm.resetClick },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")))]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value:
                          "btn:collection-center:order:warehouse-warehouse:allExport",
                        expression:
                          "'btn:collection-center:order:warehouse-warehouse:allExport'",
                        arg: "remove",
                      },
                    ],
                    class: _vm.isClickAll ? "onlyClickOnce10" : "",
                    attrs: { icon: "el-icon-upload", size: "mini" },
                    on: { click: _vm.downloadAll },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")))]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("el-divider"),
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "el-table",
          {
            ref: "tableList",
            attrs: { "max-height": 600, border: "", data: _vm.tableDate },
          },
          [
            _c("el-table-column", {
              attrs: {
                label: _vm.$t("collectionCenter.ind"),
                type: "index",
                width: "60",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            (_vm.queryForm.pageNumber - 1) *
                              _vm.queryForm.pageSize +
                              scope.$index +
                              1
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "boxNumber",
                label: _vm.$t("collectionCenter.boxNumber"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "boxBarCode",
                label: _vm.$t("collectionCenter.boxBarCode"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "createTime",
                label: _vm.$t("collectionCenter.orderCreateT"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "customerNumber",
                label: _vm.$t("collectionCenter.customerOrderNum"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "orderNumber",
                label: _vm.$t("collectionCenter.lanshouOrderNum"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "boxStatus",
                label: _vm.$t("collectionCenter.OrderStatus"),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.boxStatus == 0
                                ? _vm.$t("collectionCenter.notLanShou")
                                : scope.row.boxStatus == 10
                                ? _vm.$t("collectionCenter.hasLanShou")
                                : scope.row.boxStatus == 20
                                ? _vm.$t("collectionCenter.truckLoading")
                                : scope.row.boxStatus == 30
                                ? _vm.$t("collectionCenter.unloading")
                                : scope.row.boxStatus == 40
                                ? _vm.$t("collectionCenter.haveBeenSigned")
                                : scope.row.boxStatus == 50
                                ? _vm.$t("collectionCenter.ProblemPiece")
                                : ""
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "customerName",
                label: _vm.$t("collectionCenter.CustomerName"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "customerId",
                label: _vm.$t("collectionCenter.clientID"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "senderName",
                label: _vm.$t("orderCenterCont.sender"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "senderPhone",
                label: _vm.$t("orderCenterCont.senderPhone"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "senderMobile",
                label: _vm.$t("collectionCenter.senderMobileNumber"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "senderZipCode",
                label: _vm.$t("collectionCenter.senderZipCode"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "senderState",
                label: _vm.$t("collectionCenter.stateOrigin"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "senderCity",
                label: _vm.$t("collectionCenter.sendingCity"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "senderAddress",
                label: _vm.$t("collectionCenter.sendingDetailsAddress"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "receiverName",
                label: _vm.$t("orderDetails.receiveName"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "receiverPhone",
                label: _vm.$t("collectionCenter.RecipientPhoneNum"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "receiverMobile",
                label: _vm.$t("collectionCenter.RecipientP"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "receiverZipCode",
                label: _vm.$t("collectionCenter.Recipientzc"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "collectTime",
                label: _vm.$t("collectionCenter.lanshouT"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "collectName",
                label: _vm.$t("collectionCenter.LanShouPeople"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "collectSiteName",
                label: _vm.$t("collectionCenter.LanShouSite"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "collectUrl",
                label: _vm.$t("collectionCenter.lanshouImg"),
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "left-start",
                            title: _vm.$t("collectionCenter.lanshouImg"),
                            width: "300",
                            trigger: "hover",
                            content: "",
                          },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: scope.row.collectUrl,
                                expression: "scope.row.collectUrl",
                              },
                            ],
                            staticStyle: { width: "26px", height: "36px" },
                            attrs: {
                              slot: "reference",
                              src: scope.row.collectUrl,
                              alt: "",
                            },
                            slot: "reference",
                          }),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !scope.row.collectUrl,
                                  expression: "!scope.row.collectUrl",
                                },
                              ],
                              attrs: { slot: "reference" },
                              slot: "reference",
                            },
                            [_vm._v(_vm._s(_vm.$t("collectionCenter.Null")))]
                          ),
                          scope.row.collectUrl
                            ? _c("img", {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: { src: scope.row.collectUrl, alt: "" },
                              })
                            : _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("collectionCenter.NoContentYet")
                                  )
                                ),
                              ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "signerTime",
                label: _vm.$t("collectionCenter.signT"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "signerName",
                label: _vm.$t("collectionCenter.signer"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "signerSiteName",
                label: _vm.$t("collectionCenter.deliveryDot"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "signerUrl",
                label: _vm.$t("collectionCenter.signImage"),
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "left-start",
                            title: _vm.$t("collectionCenter.signImage"),
                            width: "300",
                            trigger: "hover",
                            content: "",
                          },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: scope.row.signerUrl,
                                expression: "scope.row.signerUrl",
                              },
                            ],
                            staticStyle: { width: "26px", height: "36px" },
                            attrs: {
                              slot: "reference",
                              src: scope.row.signerUrl,
                              alt: "",
                            },
                            slot: "reference",
                          }),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !scope.row.signerUrl,
                                  expression: "!scope.row.signerUrl",
                                },
                              ],
                              attrs: { slot: "reference" },
                              slot: "reference",
                            },
                            [_vm._v(_vm._s(_vm.$t("collectionCenter.Null")))]
                          ),
                          scope.row.signerUrl
                            ? _c("img", {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: { src: scope.row.signerUrl, alt: "" },
                              })
                            : _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("collectionCenter.NoContentYet")
                                  )
                                ),
                              ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm.isViewDetails
              ? _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("collectionCenter.operation"),
                    fixed: "right",
                    width: "160",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permit",
                                    rawName: "v-permit:remove",
                                    value:
                                      "btn:collection-center:order:warehouse-warehouse:ViewDetails",
                                    expression:
                                      "'btn:collection-center:order:warehouse-warehouse:ViewDetails'",
                                    arg: "remove",
                                  },
                                ],
                                staticStyle: { "white-space": "normal" },
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.cellClick(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("collectionCenter.ViewDetails"))
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3611305667
                  ),
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "right" },
      [
        _c("el-pagination", {
          staticStyle: { margin: "10px 0" },
          attrs: {
            layout: "total, sizes, prev, pager, next, jumper",
            "current-page": _vm.queryForm.pageNumber,
            "page-sizes": [10, 20, 50, 100, 500, 1000],
            "page-size": _vm.queryForm.pageSize,
            total: _vm.total,
            background: "",
          },
          on: {
            "update:currentPage": function ($event) {
              return _vm.$set(_vm.queryForm, "pageNumber", $event)
            },
            "update:current-page": function ($event) {
              return _vm.$set(_vm.queryForm, "pageNumber", $event)
            },
            "update:pageSize": function ($event) {
              return _vm.$set(_vm.queryForm, "pageSize", $event)
            },
            "update:page-size": function ($event) {
              return _vm.$set(_vm.queryForm, "pageSize", $event)
            },
            "current-change": _vm.tablePage,
            "size-change": _vm.tablePageSize,
          },
        }),
      ],
      1
    ),
    _vm.drawerIsOpen
      ? _c(
          "div",
          { staticClass: "drawerOpen" },
          [
            _c(
              "el-dialog",
              {
                attrs: { title: "", visible: _vm.drawerIsOpen, width: "80%" },
                on: {
                  "update:visible": function ($event) {
                    _vm.drawerIsOpen = $event
                  },
                },
              },
              [
                _c("div", { staticClass: "msgBox" }, [
                  _c("p", { staticClass: "msgTitle" }, [
                    _vm._v(
                      _vm._s(_vm.$t("collectionCenter.recipientInformation"))
                    ),
                  ]),
                  _c("div", { staticClass: "msgClass" }, [
                    _c("span", { staticClass: "span" }, [
                      _c("span", { staticClass: "s" }, [
                        _vm._v(_vm._s(_vm.$t("collectionCenter.recipient"))),
                      ]),
                      _vm._v(_vm._s(_vm.msgList.receiverName)),
                    ]),
                    _c("span", { staticClass: "span" }, [
                      _c("span", { staticClass: "s" }, [
                        _vm._v(_vm._s(_vm.$t("collectionCenter.phone"))),
                      ]),
                      _vm._v(_vm._s(_vm.msgList.receiverPhone)),
                    ]),
                    _c("span", { staticClass: "span" }, [
                      _c("span", { staticClass: "s" }, [
                        _vm._v(_vm._s(_vm.$t("collectionCenter.cellphone"))),
                      ]),
                      _vm._v(_vm._s(_vm.msgList.receiverMobile)),
                    ]),
                    _c("span", { staticClass: "span" }, [
                      _c("span", { staticClass: "s" }, [
                        _vm._v(_vm._s(_vm.$t("collectionCenter.mailbox"))),
                      ]),
                      _vm._v(_vm._s(_vm.msgList.receiverEmail)),
                    ]),
                    _c("span", { staticClass: "span" }, [
                      _c("span", { staticClass: "s" }, [
                        _vm._v(_vm._s(_vm.$t("collectionCenter.zipCode"))),
                      ]),
                      _vm._v(_vm._s(_vm.msgList.receiverZipCode)),
                    ]),
                    _c("span", { staticClass: "span" }, [
                      _c("span", { staticClass: "s" }, [
                        _vm._v(_vm._s(_vm.$t("collectionCenter.receiptState"))),
                      ]),
                      _vm._v(_vm._s(_vm.msgList.receiverState)),
                    ]),
                    _c("span", { staticClass: "span" }, [
                      _c("span", { staticClass: "s" }, [
                        _vm._v(_vm._s(_vm.$t("collectionCenter.receiptCity"))),
                      ]),
                      _vm._v(_vm._s(_vm.msgList.receiverCity)),
                    ]),
                    _c("span", { staticClass: "span" }, [
                      _c("span", { staticClass: "s" }, [
                        _vm._v(_vm._s(_vm.$t("collectionCenter.detailedArs"))),
                      ]),
                      _vm._v(_vm._s(_vm.msgList.receiverAddress)),
                    ]),
                    _c("span", { staticClass: "span" }, [
                      _c("span", { staticClass: "s" }, [
                        _vm._v(_vm._s(_vm.$t("collectionCenter.CPF"))),
                      ]),
                      _vm._v(_vm._s(_vm.msgList.receiverCpf)),
                    ]),
                  ]),
                  _c("p", { staticClass: "msgTitle" }, [
                    _vm._v(
                      _vm._s(_vm.$t("collectionCenter.SenderInformation"))
                    ),
                  ]),
                  _c("div", { staticClass: "msgClass" }, [
                    _c("span", { staticClass: "span" }, [
                      _c("span", { staticClass: "s" }, [
                        _vm._v(_vm._s(_vm.$t("collectionCenter.adrser"))),
                      ]),
                      _vm._v(_vm._s(_vm.msgList.senderName)),
                    ]),
                    _c("span", { staticClass: "span" }, [
                      _c("span", { staticClass: "s" }, [
                        _vm._v(_vm._s(_vm.$t("collectionCenter.phone"))),
                      ]),
                      _vm._v(_vm._s(_vm.msgList.senderPhone)),
                    ]),
                    _c("span", { staticClass: "span" }, [
                      _c("span", { staticClass: "s" }, [
                        _vm._v(_vm._s(_vm.$t("collectionCenter.cellphone"))),
                      ]),
                      _vm._v(_vm._s(_vm.msgList.senderMobile)),
                    ]),
                    _c("span", { staticClass: "span" }, [
                      _c("span", { staticClass: "s" }, [
                        _vm._v(_vm._s(_vm.$t("collectionCenter.mailbox"))),
                      ]),
                      _vm._v(_vm._s(_vm.msgList.senderEmail)),
                    ]),
                    _c("span", { staticClass: "span" }, [
                      _c("span", { staticClass: "s" }, [
                        _vm._v(_vm._s(_vm.$t("collectionCenter.zipCode"))),
                      ]),
                      _vm._v(_vm._s(_vm.msgList.senderZipCode)),
                    ]),
                    _c("span", { staticClass: "span" }, [
                      _c("span", { staticClass: "s" }, [
                        _vm._v(_vm._s(_vm.$t("collectionCenter.stateOri"))),
                      ]),
                      _vm._v(_vm._s(_vm.msgList.senderState)),
                    ]),
                    _c("span", { staticClass: "span" }, [
                      _c("span", { staticClass: "s" }, [
                        _vm._v(_vm._s(_vm.$t("collectionCenter.sendingCt"))),
                      ]),
                      _vm._v(_vm._s(_vm.msgList.senderCity)),
                    ]),
                    _c("span", { staticClass: "span" }, [
                      _c("span", { staticClass: "s" }, [
                        _vm._v(_vm._s(_vm.$t("collectionCenter.detailedArs"))),
                      ]),
                      _vm._v(_vm._s(_vm.msgList.senderAddress)),
                    ]),
                    _c("span", { staticClass: "span" }, [
                      _c("span", { staticClass: "s" }, [
                        _vm._v(_vm._s(_vm.$t("collectionCenter.CPF"))),
                      ]),
                      _vm._v(_vm._s(_vm.msgList.senderCpf)),
                    ]),
                  ]),
                  _c("p", { staticClass: "msgTitle" }, [
                    _vm._v(_vm._s(_vm.$t("collectionCenter.cargoInformation"))),
                  ]),
                  _c("div", { staticClass: "msgClass" }, [
                    _c("span", { staticClass: "span" }, [
                      _c("span", { staticClass: "s" }, [
                        _vm._v(_vm._s(_vm.$t("collectionCenter.tradeN"))),
                      ]),
                      _vm._v(_vm._s(_vm.msgList.goodsName)),
                    ]),
                    _c("span", { staticClass: "span" }, [
                      _c("span", { staticClass: "s" }, [
                        _vm._v(_vm._s(_vm.$t("collectionCenter.totalWeight"))),
                      ]),
                      _vm._v(_vm._s(_vm.msgList.weight)),
                    ]),
                    _c("span", { staticClass: "span" }, [
                      _c("span", { staticClass: "s" }, [
                        _vm._v(_vm._s(_vm.$t("collectionCenter.totalParcels"))),
                      ]),
                      _vm._v(_vm._s(_vm.msgList.totalPackage)),
                    ]),
                    _c("span", { staticClass: "span" }, [
                      _c("span", { staticClass: "s" }, [
                        _vm._v(
                          _vm._s(_vm.$t("collectionCenter.totalDeclaredValue"))
                        ),
                      ]),
                      _vm._v(_vm._s(_vm.msgList.totalValue)),
                    ]),
                    _c("span", { staticClass: "span" }, [
                      _c("span", { staticClass: "s" }, [
                        _vm._v(_vm._s(_vm.$t("collectionCenter.boxLen"))),
                      ]),
                      _vm._v(_vm._s(_vm.msgList.length)),
                    ]),
                    _c("span", { staticClass: "span" }, [
                      _c("span", { staticClass: "s" }, [
                        _vm._v(_vm._s(_vm.$t("collectionCenter.boxWei"))),
                      ]),
                      _vm._v(_vm._s(_vm.msgList.width)),
                    ]),
                    _c("span", { staticClass: "span" }, [
                      _c("span", { staticClass: "s" }, [
                        _vm._v(_vm._s(_vm.$t("collectionCenter.boxHei"))),
                      ]),
                      _vm._v(_vm._s(_vm.msgList.height)),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticStyle: { "margin-top": "25px" } },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "msgTableRef",
                          attrs: {
                            border: "",
                            "max-height": 600,
                            data: _vm.msgTableDate,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "goodsName",
                              align: "center",
                              label: _vm.$t("collectionCenter.tradeName"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "packageNumber",
                              align: "center",
                              label: _vm.$t("collectionCenter.parcelNum"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "scanNumber",
                              align: "center",
                              label: _vm.$t("collectionCenter.pgBarCode"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "weight",
                              align: "center",
                              label: _vm.$t("collectionCenter.bagWeight"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "goodsInvoicNo",
                              align: "center",
                              label: _vm.$t(
                                "collectionCenter.commodityInvoiceNumber"
                              ),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "goodsInvoiceSecretKey",
                              align: "center",
                              label: _vm.$t(
                                "collectionCenter.CommodityInvoiceKey"
                              ),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "totalValue",
                              align: "center",
                              label: _vm.$t("collectionCenter.declaredVal"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "goodsNumber",
                              align: "center",
                              label: _vm.$t("collectionCenter.numberItems"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "logisticsInvoiceType",
                              align: "center",
                              label: _vm.$t(
                                "collectionCenter.logisticsInvoiceType"
                              ),
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              scope.row.logisticsInvoiceType ==
                                                1
                                                ? "CTE"
                                                : scope.row
                                                    .logisticsInvoiceType == 2
                                                ? "NFSe"
                                                : ""
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2710620126
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "logisticsInvoiceNo",
                              align: "center",
                              label: _vm.$t(
                                "collectionCenter.logisticsInvoiceNumber"
                              ),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "logisticsInvoiceSecretKey",
                              align: "center",
                              label: _vm.$t("collectionCenter.cteInvoiceKey"),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }