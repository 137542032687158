<script >
import { ref, defineProps, onMounted } from '@vue/composition-api';
import { default as FormTrailerData } from '../../../../trailer/components/form/steps/formTrailerData';
import { default as FormAdditionalData } from '../../../../trailer/components/form/steps/formAdditionalData.vue';
import { default as FormMeasurementData } from '../../../../trailer/components/form/steps/formMeasurementData.vue';
import { FormActions, FormActionsNext, FormActionsPrevious } from '@/components/wizard-form/formStepActions';
import { trailerService } from '@/api/transport/trailer';
const __sfc_main = {};
__sfc_main.props = {
  formData: {
    type: Object
  },
  handleNext: {
    type: Function
  },
  isLastStep: {
    type: Boolean
  },
  isFirstStep: {
    type: Boolean
  },
  handlePrevious: {
    type: Function
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const activeTabs = ref(0);
  const listTrailer = ref([]);
  const trailerData = ref({
    document: null,
    capacityM3: null,
    capacityKG: null
  });
  async function filteredDataTrailers() {
    try {
      const res = await trailerService.getAll(0, 10, props.formData.trailerPlate);
      listTrailer.value = res.data.result;
    } catch (error) {
      listTrailer.value = [];
    }
  }
  function handleSelectedTrailer(selectedValue) {
    trailerData.value = {
      document: selectedValue ? selectedValue.trailer_documentation : '',
      capacityM3: selectedValue ? selectedValue.trailer_capacity_m3 : '',
      capacityKG: selectedValue ? selectedValue.trailer_capacity_kg : ''
    };
  }
  onMounted(async () => {
    try {
      if (props.formData.plate) {
        const res = await trailerService.getAll(0, 10, props.formData.trailerPlate);
        trailerData.value = {
          document: res.data.result[0].vehicle_documentation,
          capacityM3: res.data.result[0].vehicle_capacity_m3,
          capacityKG: res.data.result[0].vehicle_capacity_kg
        };
      }
    } catch (error) {
      // eslint-disable-next-line require-atomic-updates
      props.formData.trailerPlate = '';
      trailerData.value = {
        document: null,
        capacityM3: null,
        capacityKG: null
      };
    }
  });
  async function handleNextStep() {
    props.handleNext();
  }
  return {
    listTrailer,
    trailerData,
    filteredDataTrailers,
    handleSelectedTrailer,
    handleNextStep
  };
};
__sfc_main.components = Object.assign({
  FormActions,
  FormActionsPrevious,
  FormActionsNext
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <form class="wrapper-form-search-trailer" @submit.prevent="handleNextStep">
    <o-field
      :label="$t('order.trailerPlate')"
      class="col-span-2"
    >
      <o-autocomplete
        v-model="formData.trailerPlate"
        rounded
        expanded
        :data="listTrailer"
        :placeholder="$t('order.trailerPlatePlaceholder')"
        clearable
        field="trailer_license_plate"
        @input="filteredDataTrailers"
        @select="handleSelectedTrailer"
      >
        <template slot="empty">No results found</template>
      </o-autocomplete>
    </o-field>

    <o-field :label="$t('order.document')" class="col-span-2">
      <o-input v-model="trailerData.document" disabled />
    </o-field>

    <o-field :label="$t('order.capacityM3')">
      <o-input v-model="trailerData.capacityM3" disabled />
    </o-field>

    <o-field :label="$t('order.capacityKg')">
      <o-input v-model="trailerData.capacityKG" disabled />
    </o-field>

    <FormActions>
      <FormActionsPrevious
        :is-first-step="isFirstStep"
        @handlePrevious="handlePrevious"
      />

      <FormActionsNext
        :is-last-step="isLastStep"
      />
    </FormActions>
  </form>
</template>

<style scoped>
.wrapper-form-search-trailer {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1.5rem/* 24px */;
}

.col-span-2 {
    grid-column: span 2 / span 2;
  }

</style>
