import { Message } from 'element-ui';
import i18n from '@/lang';

const PleaseSelect = i18n.t('operationCenter.PleaseSelect'); // 请选择
const BuildThePerson = i18n.t('receivablePayable.BuildThePerson'); // 生成人员
const BuildTime = i18n.t('receivablePayable.BuildTime'); // 生成时间

/**
 * 导出明细校验
 * @param {Object} params 搜索条件（不含分页）
 * @returns {boolean} 校验通过返回true
 */
export const exportDetailCheck = (params) => {
  const { startTime, endTime, handlerId } = params;

  // 生成人员 生成时间 导出
  if (!startTime && !endTime && !handlerId) {
    Message.warning(PleaseSelect + BuildThePerson + '、' + BuildTime);
    return false;
  }

  // 没有选择 生成人员
  if (!handlerId) {
    Message.warning(PleaseSelect + BuildThePerson);
    return false;
  }

  // 没有选择 生成时间
  if (!startTime && !endTime) {
    Message.warning(PleaseSelect + BuildTime);
    return false;
  }

  return true;
};

export const TABLE_HEADER = [
  // 账单批次号
  { prop: 'billBatchNumber', label: i18n.t('receivablePayable.BillingBatchNumber'), width: 160, align: 'left', highlight: false, disabled: false },
  // 账单名称
  { prop: 'billName', label: i18n.t('receivablePayable.BillingName'), width: 160, align: 'left', highlight: false, disabled: false },
  // 账单日期
  { prop: 'billTime', label: i18n.t('receivablePayable.BillingDate'), width: 180, align: 'left', highlight: false, disabled: false },
  // 应收主体名称
  { prop: 'providerName', label: i18n.t('receivablePayable.BusinessPrincipalName'), width: 160, align: 'left', highlight: false, disabled: false },
  // 客户
  { prop: 'customerName', label: i18n.t('GlobalSetObj.client'), width: 160, align: 'left', highlight: false, disabled: false },
  // 账单状态
  { prop: 'billStatus', label: i18n.t('receivablePayable.BillingStatus'), width: 160, align: 'left', highlight: false, disabled: false },
  // 网点名称
  { prop: 'siteName', label: i18n.t('basicData.siteName'), width: 160, align: 'left', highlight: true, disabled: false },
  // 订单数量
  { prop: 'orderQty', label: i18n.t('receivablePayable.TheNumberOfOrders'), width: 160, align: 'left', highlight: true, disabled: false },
  // 费用条数
  { prop: 'feeQty', label: i18n.t('receivablePayable.TheNumberOfFees'), width: 160, align: 'left', highlight: false, disabled: false },
  // 计费总重量（kg）
  { prop: 'chargeWeight', label: i18n.t('receivablePayable.TotalBillableWeight'), width: 160, align: 'left', highlight: false, disabled: false },
  // 应收总金额
  { prop: 'totalReceivable', label: i18n.t('receivablePayable.TotalAmountReceivable'), width: 160, align: 'left', highlight: false, disabled: false },
  // 生成人员
  { prop: 'createUserName', label: i18n.t('receivablePayable.BuildThePerson'), width: 160, align: 'left', highlight: false, disabled: false },
  // 生成时间
  { prop: 'createTime', label: i18n.t('receivablePayable.BuildTime'), width: 160, align: 'left', highlight: false, disabled: false },
  // 审核人员
  { prop: 'auditUserName', label: i18n.t('receivablePayable.Auditors'), width: 160, align: 'left', highlight: false, disabled: false },
  // 审核时间
  { prop: 'auditTime', label: i18n.t('receivablePayable.ReviewTime'), width: 160, align: 'left', highlight: false, disabled: false },
  // 审核原因
  { prop: 'auditRemark', label: i18n.t('receivablePayable.AuditReason'), width: 160, align: 'left', highlight: false, disabled: false }
];
