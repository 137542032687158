var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Box" },
    [
      _c(
        "el-form",
        {
          staticClass: "selectClass",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { width: "auto" }, attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("collectionCenter.orderCreateTime"),
                      },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          clearable: false,
                          type: "datetimerange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "default-time": ["00:00:00", "23:59:59"],
                          "range-separator": _vm.$t("collectionCenter.to"),
                          "start-placeholder": _vm.$t(
                            "collectionCenter.StartTime"
                          ),
                          "end-placeholder": _vm.$t("collectionCenter.EndTime"),
                        },
                        on: { change: _vm.dateChange },
                        model: {
                          value: _vm.timeValue,
                          callback: function ($$v) {
                            _vm.timeValue = $$v
                          },
                          expression: "timeValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { width: "auto" }, attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("collectionCenter.CustomerN") } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t(
                              "collectionCenter.pleaseChoose"
                            ),
                            clearable: "",
                            filterable: "",
                          },
                          on: {
                            focus: _vm.getNewestCustomer,
                            change: _vm.changeCustomer,
                          },
                          model: {
                            value: _vm.queryForm.params.customerId,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm.params, "customerId", $$v)
                            },
                            expression: "queryForm.params.customerId",
                          },
                        },
                        _vm._l(_vm.customerIdList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.username, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                {
                  staticClass: "col10",
                  staticStyle: { width: "auto" },
                  attrs: { span: 6 },
                },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "m" },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "autoWidth",
                          attrs: {
                            placeholder: _vm.$t(
                              "collectionCenter.pleaseChoose"
                            ),
                          },
                          on: { change: _vm.changeMerchant },
                          model: {
                            value: _vm.merchantChoose,
                            callback: function ($$v) {
                              _vm.merchantChoose = $$v
                            },
                            expression: "merchantChoose",
                          },
                        },
                        [
                          _c("template", { slot: "prefix" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (
                                    _vm.merchantCodeOrName.find(function (
                                      item
                                    ) {
                                      return item.m === _vm.merchantChoose
                                    }) || {}
                                  ).name
                                ) +
                                " "
                            ),
                          ]),
                          _vm._l(
                            _vm.merchantCodeOrName,
                            function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.m },
                              })
                            }
                          ),
                        ],
                        2
                      ),
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        on: { change: _vm.changeValue },
                        model: {
                          value: _vm.merchantCodeValue,
                          callback: function ($$v) {
                            _vm.merchantCodeValue = $$v
                          },
                          expression: "merchantCodeValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value:
                        "btn:collection-center:order:collector-inquire:find",
                      expression:
                        "'btn:collection-center:order:collector-inquire:find'",
                      arg: "remove",
                    },
                  ],
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-search",
                  },
                  on: { click: _vm.searchClick },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.search")))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value:
                        "btn:collection-center:order:collector-inquire:reset",
                      expression:
                        "'btn:collection-center:order:collector-inquire:reset'",
                      arg: "remove",
                    },
                  ],
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetClick },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value:
                        "btn:collection-center:order:collector-inquire:allExport",
                      expression:
                        "'btn:collection-center:order:collector-inquire:allExport'",
                      arg: "remove",
                    },
                  ],
                  class: _vm.isClickAll ? "onlyClickOnce10" : "",
                  attrs: { icon: "el-icon-upload", size: "mini" },
                  on: { click: _vm.downloadAll },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-table",
        {
          ref: "tableRef",
          attrs: { "max-height": 600, border: "", data: _vm.tableDate },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.ind"),
              type: "index",
              width: "60",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          (_vm.queryForm.pageNumber - 1) *
                            _vm.queryForm.pageSize +
                            scope.$index +
                            1
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "customerId",
              label: _vm.$t("collectionCenter.clientID"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "customerName",
              label: _vm.$t("collectionCenter.CustomerName"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "merchantCode",
              label: _vm.$t("collectionCenter.MerchantNum"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "merchantName",
              label: _vm.$t("collectionCenter.MerchantName"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "collectTypeName",
              label: _vm.$t("collectionCenter.receivingMode"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "orderCount",
              label: _vm.$t("collectionCenter.toBeCollectedNumber"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "senderName",
              label: _vm.$t("collectionCenter.senderName"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "120",
              prop: "senderPhone",
              label: _vm.$t("collectionCenter.senderMobile"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.senderPhoneIndex ==
                      (_vm.queryForm.pageNumber - 1) * _vm.queryForm.pageSize +
                        scope.$index +
                        1 && _vm.findSenderPhone
                      ? _c("span", [_vm._v(_vm._s(scope.row.senderPhone))])
                      : _c("span", [
                          _vm._v(_vm._s(scope.row.senderEncryptPhone)),
                        ]),
                    _c("div", {
                      staticClass: "el-icon-view isShowSenderPhone",
                      on: {
                        click: function ($event) {
                          _vm.findSPhone(
                            (_vm.queryForm.pageNumber - 1) *
                              _vm.queryForm.pageSize +
                              scope.$index +
                              1
                          )
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "senderTotalAddress",
              label: _vm.$t("collectionCenter.SenderAddress"),
              "show-overflow-tooltip": false,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-tooltip", { attrs: { placement: "top" } }, [
                      _c(
                        "pre",
                        {
                          staticStyle: {
                            "max-height": "200px",
                            overflow: "auto",
                            width: "500px",
                          },
                          attrs: { slot: "content" },
                          slot: "content",
                        },
                        [_vm._v(_vm._s(scope.row.senderTotalAddress))]
                      ),
                      _c("div", { staticClass: "paramsStyle" }, [
                        _vm._v(_vm._s(scope.row.senderTotalAddress)),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "senderZipcode",
              label: _vm.$t("collectionCenter.senderZipCode"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "merchantLevel",
              label: _vm.$t("collectionCenter.MerchantClass"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "receiveName",
              label: _vm.$t("collectionCenter.recipientS"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "receiveTotalAddress",
              label: _vm.$t("collectionCenter.receiptArs"),
              "show-overflow-tooltip": false,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-tooltip", { attrs: { placement: "top" } }, [
                      _c(
                        "pre",
                        {
                          staticStyle: {
                            "max-height": "200px",
                            overflow: "auto",
                            width: "500px",
                          },
                          attrs: { slot: "content" },
                          slot: "content",
                        },
                        [_vm._v(_vm._s(scope.row.receiveTotalAddress))]
                      ),
                      _c("div", { staticClass: "paramsStyle" }, [
                        _vm._v(_vm._s(scope.row.receiveTotalAddress)),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              width: "120",
              prop: "receivePhone",
              label: _vm.$t("collectionCenter.addresseeContactNumber"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.receivePhoneIndex ==
                      (_vm.queryForm.pageNumber - 1) * _vm.queryForm.pageSize +
                        scope.$index +
                        1 && _vm.findReceivePhone
                      ? _c("span", [_vm._v(_vm._s(scope.row.receivePhone))])
                      : _c("span", [
                          _vm._v(_vm._s(scope.row.receiveEncryptPhone)),
                        ]),
                    _c("div", {
                      staticClass: "el-icon-view isShowSenderPhone",
                      on: {
                        click: function ($event) {
                          _vm.findRPhone(
                            (_vm.queryForm.pageNumber - 1) *
                              _vm.queryForm.pageSize +
                              scope.$index +
                              1
                          )
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "receiveZipcode",
              label: _vm.$t("collectionCenter.Recipientzc"),
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-pagination", {
            staticStyle: { margin: "10px 0" },
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              "current-page": _vm.queryForm.pageNumber,
              "page-sizes": [10, 20, 50, 100, 500, 1000],
              "page-size": _vm.queryForm.pageSize,
              total: _vm.total,
              background: "",
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "current-change": _vm.getPage,
              "size-change": _vm.getPageSize,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }