<script >
import { defineProps, ref, defineEmits, watch } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { FormActions, FormActionsNext, FormActionsPrevious } from '@/components/wizard-form/formStepActions';
import { required } from '@/utils/validationErrors';
const __sfc_main = {};
__sfc_main.props = {
  handleNext: {
    type: Function
  },
  isLastStep: {
    type: Boolean,
    required: true
  },
  isFirstStep: {
    type: Boolean,
    required: true
  },
  handlePrevious: {
    type: Function
  },
  codGroup: {
    type: String
  },
  isEdit: {
    type: Boolean
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const codGroupLocal = ref(props.codGroup);
  const emit = __ctx.emit;
  function emitCodGroup(codGroupValue) {
    emit('codGroupChanged', codGroupValue);
  }
  watch(() => codGroupLocal.value, newCodGroupLocal => {
    emitCodGroup(newCodGroupLocal);
  });
  const rules = {
    codGroup: {
      required
    }
  };
  const v$ = useVuelidate(rules, {
    codGroup: codGroupLocal
  });
  async function handleSubmit() {
    const isValid = await v$.value.$validate();
    if (isValid) props.handleNext();
  }
  return {
    codGroupLocal,
    v$,
    handleSubmit
  };
};
__sfc_main.components = Object.assign({
  FormActions,
  FormActionsPrevious,
  FormActionsNext
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <form class="wrapper-form" @submit.prevent="handleSubmit">
    <o-field :label="$t('editingRegistration.clientName')" label-for="customer">
      <o-input id="customer" disabled value="PANINI BRASIL S.A" />
    </o-field>

    <o-field
      :label="$t('editingRegistration.groupCode')"
      label-for="codGroup"
      :message="v$.codGroup.$errors.length ? v$.codGroup.$errors[0].$message : null"
    >
      <o-input
        id="codGroup"
        v-model="codGroupLocal"
        :disabled="isEdit"
        :placeholder="$t('editingRegistration.groupCodePlaceholder')"
      />
    </o-field>

    <FormActions>
      <FormActionsPrevious :is-first-step="isFirstStep" @handlePrevious="handlePrevious" />
      <FormActionsNext :is-last-step="isLastStep" />
    </FormActions>
  </form>
</template>

<style scoped>
.wrapper-form {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem /* 16px */;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}
.wapper-buttons {
  display: flex;
  justify-content: flex-end;
  padding-top: 0.5rem /* 8px */;
}
</style>
