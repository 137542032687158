<!--
 * @Author: nayg
 * @Date: 2022-05-27 16:36:05
 * @LastEditTime: 2022-05-31 16:04:36
 * @LastEditors: nayg
 * @Description:
 * @FilePath: \admin-oa-web\src\views\user\organizationManage\components\treeChart.vue
-->
<template>
  <div class="container">
    <div class="wrap">
      <div class="child">
        <div class="main">
          <div class="item-container">
            <div :class="['item',data.children.length?'item-line-bottom':'','item-top-node']">
              <div>
                <p class="name">{{ data.name }}</p>
                <p class="number">人数：<span class="number-crude">{{ data.number }}</span></p>
              </div>
            </div>
            <div class="inline">
              <div class="child">
                <div class="main">
                  <div
                    v-for="(item, index) in data.children"
                    :key="item.id"
                    :class="[
                      'item-container',
                      index == 0 &&data.children.length!=1? 'item-line-l' : '',
                      index == data.children.length - 1 &&data.children.length!=1? 'item-line-r' : '',
                      index != data.children.length - 1 || index != 0? 'item-line-m' : ''
                    ]"
                  >
                    <div
                      :class="[
                        'item',
                        'item-line-top',
                        item.children&&item.children.length ? 'item-line-bottom' : ''
                      ]"
                    >
                      <div>
                        <p class="name">{{ item.name }}</p>
                        <p class="number">人数：<span class="number-crude">{{ item.number }}</span></p>
                      </div>
                    </div>
                    <div class="inline" />
                    <div v-if="item.children&&item.children.length" class="child">
                      <div class="main">
                        <div
                          v-for="(items,index) in item.children"
                          :key="index"
                          :class="['item-container',
                                   index == 0 &&item.children.length!=1? 'item-line-l' : '',
                                   index == item.children.length - 1 &&item.children.length!=1? 'item-line-r' : '',
                                   index != item.children.length - 1 || index != 0? 'item-line-m' : '']"
                        >
                          <div class="item item-line-top">
                            <div>
                              <p class="name">{{ items.name }}</p>
                              <p class="number">人数：<span class="number-crude">{{ items.number }}</span></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding: 10px 0;
  margin: 50px auto 50px auto;
  display: inline-block;
}

.wrap {
  display: flex;
  padding: 10px 20px 10px 10px;
  display: inline-block;
}

.child {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main {
  display: flex;
  justify-content: center;
}

.item-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item {
  position: relative;
  display: flex;
  width: 110px;
  height: 74px;
  margin: 20px;
  background: #ffffff;
  border-radius: 1px 1px 0 0;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px 0 #ddd;
  border:1px solid #ddd;
}
.name{
  display: block;
  text-align: center;
  color:#606266;
  font-size: 14px;
  font-weight: 700;
}
.number{
  display: block;
  padding:3px 5px;
  background: #D6F0F8;
  text-align: center;
  color:#62A7C8;
  font-size: 14px;
  font-weight: 500;
  border-radius: 2px;
}
.number-crude{
  font-weight: 700;
}
.item-top-node {
  width: 200px;
  height: 74px;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0 2px 10px 0 #ddd;
}

.item-line-top:before {
  position: absolute;
  top: -22px;
  left: 50%;
  display: block;
  width: 1px;
  height: 20px;
  background: #d8d8d8;
  content: ' ';
}

.item-line-bottom:after {
  position: absolute;
  top: 74px;
  left: 50%;
  display: block;
  width: 1px;
  height: 20px;
  background: #d8d8d8;
  content: ' ';
}

.item-line-l:before {
  position: absolute;
  left: 50%;
  display: block;
  width: 50%;
  height: 1px;
  background: #d8d8d8;
  content: ' ';
}

.item-line-m:before {
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  background: #d8d8d8;
  content: ' ';
}

.item-line-r:before {
  position: absolute;
  right: 50%;
  display: block;
  width: 50%;
  height: 1px;
  background: #d8d8d8;
  content: ' ';
}
</style>
