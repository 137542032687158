var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    { attrs: { data: _vm.arr, height: _vm.tableHeight, border: "" } },
    [
      _c("el-table-column", {
        attrs: {
          label: _vm.$t("newOrder.SerialNumber"),
          type: "index",
          width: "50",
        },
      }),
      _c("el-table-column", {
        attrs: { label: _vm.$t("GlobalSetObj.Name"), prop: "name" },
      }),
      _c("el-table-column", {
        attrs: {
          label: _vm.$t("newOrder.Quantity"),
          prop: "quantity",
          width: "180",
        },
      }),
      _c("el-table-column", {
        attrs: { label: _vm.$t("newOrder.Size") },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("div", { staticClass: "table-size" }, [
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.$t("operationCenter.length")) +
                        " " +
                        _vm._s(scope.row.length)
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.$t("operationCenter.width")) +
                        " " +
                        _vm._s(scope.row.width)
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.$t("operationCenter.height")) +
                        " " +
                        _vm._s(scope.row.height)
                    ),
                  ]),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: _vm.$t("newOrder.Declaration") + "*" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("div", { staticClass: "table-size" }, [
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.$t("orderCenterCont.DeclareUnitPrice")) +
                        " " +
                        _vm._s(scope.row.declaredValue)
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.$t("orderCenterCont.DeclaredWeight")) +
                        " " +
                        _vm._s(scope.row.declaredWeight)
                    ),
                  ]),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }