<template>
  <div class="detialInfo">
    <el-collapse v-model="activeNames">
      <!--运单寄件信息-->
      <el-collapse-item :title="$t('collectionCenter.waybillSRMessage')" name="1">
        <el-card class="box-card">
          <el-descriptions :colon="false" :column="3" direction="vertical">
            <!--发件人-->
            <el-descriptions-item>
              <!--      插槽        -->
              <template slot="label">
                <div style="font-weight: normal">
                  <span
                    style="padding:5px;background: #00a121;color: #fff;border-radius: 5px;margin-right: 8px"
                  >{{ $t('collectionCenter.ji') }}</span>
                  <span>{{ $t('collectionCenter.addresser') }}</span>
                </div>
              </template>
              <div>{{ tableInfomation.senderName || '--' }}</div>
            </el-descriptions-item>
            <!--发件人手机-->
            <el-descriptions-item :label="$t('newOrder.senderPhone')">
              <div>{{ tableInfomation.senderMobile || '--' }}</div>
            </el-descriptions-item>
            <!--发件人电话-->
            <el-descriptions-item :label="$t('newOrder.SenderPhone')">
              <div>{{ tableInfomation.senderPhone || '--' }}</div>
            </el-descriptions-item>
            <!--发件人公司-->
            <el-descriptions-item :label="$t('newOrder.SenderCompany')">
              <div>{{ tableInfomation.senderCompany || '--' }}</div>
            </el-descriptions-item>
            <!--发件人税号-->
            <el-descriptions-item :label="$t('newOrder.SenderCPF')">
              <div>{{ tableInfomation.senderTax || '--' }}</div>
            </el-descriptions-item>
            <!--发件人邮箱-->
            <el-descriptions-item :label="$t('newOrder.SenderEmail')">
              <div>{{ tableInfomation.senderMail || '--' }}</div>
            </el-descriptions-item>
            <!--发件人街道-->
            <el-descriptions-item :label="$t('orderCenterCont.SenderStreet')">
              <div>{{ tableInfomation.senderStreet || '--' }}</div>
            </el-descriptions-item>
            <!--发件人门牌号-->
            <el-descriptions-item :label="$t('newOrder.SenderNumber')">
              <div>{{ tableInfomation.senderHouseNumber || '--' }}</div>
            </el-descriptions-item>
            <!--发件人区-->
            <el-descriptions-item :label="$t('newOrder.SenderDistrict')">
              <div>{{ tableInfomation.senderArea || '--' }}</div>
            </el-descriptions-item>
            <!--发件人城市-->
            <el-descriptions-item :label="$t('newOrder.SenderCity')">
              <div>{{ tableInfomation.senderCity || '--' }}</div>
            </el-descriptions-item>
            <!--发件人州-->
            <el-descriptions-item :label="$t('newOrder.SenderState')">
              <div>{{ tableInfomation.senderState || '--' }}</div>
            </el-descriptions-item>
            <!--州注册号-->
            <el-descriptions-item :label="$t('GlobalSetObj.NationalRegistrationNumber')">
              <div>{{ tableInfomation.senderIeNumber || '--' }}</div>
            </el-descriptions-item>
            <!--发件人地址补充-->
            <el-descriptions-item :label="$t('GlobalSetObj.sendderComplement')">
              <div>{{ tableInfomation.senderComplement || '--' }}</div>
            </el-descriptions-item>
            <!--发件人邮编-->
            <el-descriptions-item :label="$t('newOrder.SenderZipCode')">
              <div>{{ tableInfomation.senderZipcode || '--' }}</div>
            </el-descriptions-item>
            <!--发件地址-->
            <el-descriptions-item :label="$t('newOrder.SendingAddress')">
              <div>{{ tableInfomation.senderAddress || '--' }}</div>
            </el-descriptions-item>
          </el-descriptions>
        </el-card>
        <!--  收件人      -->
        <el-card class="box-card" style="margin-top: 10px">
          <el-descriptions :colon="false" :column="3" direction="vertical">
            <!--收件人-->
            <el-descriptions-item>
              <!--      插槽        -->
              <template slot="label">
                <div style="font-weight: normal">
                  <span
                    style="padding:5px;background: #00a121;color: #fff;border-radius: 5px;margin-right: 8px"
                  >{{ $t('collectionCenter.shou') }}</span>
                  <span>{{ $t('collectionCenter.recipientS') }}</span>
                </div>
              </template>
              <div>{{ tableInfomation.receiveName || '--' }}</div>
            </el-descriptions-item>
            <!--收件人手机-->
            <el-descriptions-item :label="$t('newOrder.Recipientphone')">
              <div>{{ tableInfomation.receiveMobile || '--' }}</div>
            </el-descriptions-item>
            <!--收件人电话-->
            <el-descriptions-item :label="$t('newOrder.Recipientphonenumber')">
              <div>{{ tableInfomation.receivePhone || '--' }}</div>
            </el-descriptions-item>
            <!--收件人公司-->
            <el-descriptions-item :label="$t('newOrder.RecipientCompany')">
              <div>{{ tableInfomation.receiveCompany || '--' }}</div>
            </el-descriptions-item>
            <!--收件人税号-->
            <el-descriptions-item :label="$t('newOrder.RecipientCPF')">
              <div>{{ tableInfomation.receiveTax || '--' }}</div>
            </el-descriptions-item>
            <!--收件人邮箱-->
            <el-descriptions-item :label="$t('newOrder.RecipientEmail')">
              <div>{{ tableInfomation.receiveMail || '--' }}</div>
            </el-descriptions-item>
            <!--收件人街道-->
            <el-descriptions-item :label="$t('orderCenterCont.Street')">
              <div>{{ tableInfomation.senderStreet || '--' }}</div>
            </el-descriptions-item>
            <!--收件人门牌号-->
            <el-descriptions-item :label="$t('newOrder.doorNumber')">
              <div>{{ tableInfomation.receiveHouseNumber || '--' }}</div>
            </el-descriptions-item>
            <!--收件人区-->
            <el-descriptions-item :label="$t('newOrder.RecipientDistrict')">
              <div>{{ tableInfomation.receiveArea || '--' }}</div>
            </el-descriptions-item>
            <!--收件人城市-->
            <el-descriptions-item :label="$t('newOrder.RecipientCity')">
              <div>{{ tableInfomation.receiveCity || '--' }}</div>
            </el-descriptions-item>
            <!--收件人州-->
            <el-descriptions-item :label="$t('newOrder.RecipientState')">
              <div>{{ tableInfomation.receiveState || '--' }}</div>
            </el-descriptions-item>
            <!--州注册号-->
            <el-descriptions-item :label="$t('GlobalSetObj.NationalRegistrationNumber')">
              <div>{{ tableInfomation.receiveIeNumber || '--' }}</div>
            </el-descriptions-item>
            <!--收件人地址补充-->
            <el-descriptions-item :label="$t('GlobalSetObj.recipientComplement')">
              <div>{{ tableInfomation.receiveComplement || '--' }}</div>
            </el-descriptions-item>
            <!--收件人邮编-->
            <el-descriptions-item :label="$t('newOrder.RecipientZipCode')">
              <div>{{ tableInfomation.receiveZipcode || '--' }}</div>
            </el-descriptions-item>
            <!--收件地址-->
            <el-descriptions-item :label="$t('newOrder.ReceivingAddress')">
              <div>{{ tableInfomation.receiveAddress || '--' }}</div>
            </el-descriptions-item>
          </el-descriptions>
        </el-card>
      </el-collapse-item>
      <!--运单基本信息-->
      <el-collapse-item :title="$t('navbar.willNumberInfo')" name="2">
        <el-card class="box-card">
          <el-descriptions :colon="false" :column="3" direction="vertical">
            <!--安骏订单号-->
            <el-descriptions-item :label="$t('navbar.anjunOrderNumber')">
              <div>{{ tableInfomation.orderNumber || '--' }}</div>
            </el-descriptions-item>
            <!--总件数-->
            <el-descriptions-item :label="$t('navbar.totalNumber')">
              <div>{{ tableInfomation.waybillCount || '--' }}</div>
            </el-descriptions-item>
            <!--创建时间-->
            <el-descriptions-item :label="$t('newOrder.createTime')">
              <div>{{ tableInfomation.createTime || '--' }}</div>
            </el-descriptions-item>
            <!--客户名称-->
            <el-descriptions-item :label="$t('newOrder.CustomerName')">
              <div>{{ tableInfomation.customerName || '--' }}</div>
            </el-descriptions-item>
            <!--客户订单号-->
            <el-descriptions-item :label="$t('newOrder.orderNumber')">
              <div>{{ tableInfomation.customerNumber || '--' }}</div>
            </el-descriptions-item>
            <!--服务类型-->
            <el-descriptions-item :label="$t('newOrder.TypeService')">
              <div>{{ expressTypeName[tableInfomation.expressType] || '--' }}</div>
            </el-descriptions-item>
            <!--揽收类型-->
            <el-descriptions-item :label="$t('newOrder.CollectionType')">
              <div>{{ collectTypeName[tableInfomation.collectType] || '--' }}</div>
            </el-descriptions-item>
            <!--货物类型-->
            <el-descriptions-item :label="$t('placeOrder.goodType')">
              <div>{{ cargoTypeName[tableInfomation.cargoType] || '--' }}</div>
            </el-descriptions-item>
            <!--下单总重量-->
            <el-descriptions-item :label="$t('navbar.totalCreateOrderWeight')">
              <div>{{ tableInfomation.totalCreateOrderWeight || '--' }}</div>
            </el-descriptions-item>
            <!--下单总体积(m³)-->
            <el-descriptions-item :label="$t('navbar.totalParcelDeclaredVolume')">
              <div>{{ tableInfomation.totalParcelDeclaredVolume || '--' }}</div>
            </el-descriptions-item>
            <!--中转复重(kg)-->
            <el-descriptions-item :label="$t('navbar.transportWeight')">
              <div>{{ tableInfomation.totalParcelDeclaredWeight1 || '--' }}</div>
            </el-descriptions-item>
            <!--中转测量体积(kg)-->
            <el-descriptions-item :label="$t('navbar.transportVolume')">
              <div>{{ tableInfomation.totalParcelDeclaredWeight1 || '--' }}</div>
            </el-descriptions-item>
          </el-descriptions>
        </el-card>
      </el-collapse-item>
      <!--子件信息-->
      <el-collapse-item :title="$t('navbar.childrenTableData')" name="3">
        <el-card class="box-card">
          <el-table
            :data="childrenTableData"
            :height="isHeight"
            border
            style="width: 100%"
          >
            <el-table-column
              :label="$t('newOrder.SerialNumber')"
              align="center"
              type="index"
              width="70"
            />
            <template v-for="item in tableHeaderList1">
              <el-table-column
                :key="item.id" :align="item.center" :label="item.label"
                :min-width="item.cloWidth"
                :prop="item.prop"
              >
                <template slot-scope="scope">
                  <div>{{ scope.row[item.prop] ? `${scope.row[item.prop]}` : '--' }}</div>
                </template>
              </el-table-column>
            </template>
            <el-table-column
              :label="$t('orderCenterCont.FaceSheet')"
              align="center"
              fixed="right"
              width="120"
            >
              <template slot-scope="scope">
                <span class="QuestionMark"><a :href="scope.row.labelUrl" target="_blank">{{
                  $t('GlobalSetObj.see')
                }}</a></span>
                <el-button size="mini" type="text" @click="downloadSheep(scope.row)">{{
                  $t('GlobalSetObj.download')
                }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-collapse-item>
      <!--托寄物信息-->
      <el-collapse-item :title="$t('collectionCenter.cnsignmentInformation')" name="4">
        <el-card class="box-card">
          <el-table
            :data="childrenTableData1"
            :height="isHeight1"
            border
            style="width: 100%"
          >
            <el-table-column
              :label="$t('newOrder.SerialNumber')"
              align="center"
              type="index"
              width="70"
            />
            <template v-for="item in tableHeaderList2">
              <el-table-column
                :key="item.id" :align="item.center" :label="item.label"
                :min-width="item.cloWidth"
                :prop="item.prop"
              >
                <template slot-scope="scope">
                  <div v-if="item.prop === 'declared'">
                    <div>{{ $t('orderCenterCont.DeclareUnitPrice') }}: {{
                      scope.row.declaredValue || '- -'
                    }}
                    </div>
                    <div>{{ $t('orderCenterCont.DeclaredWeight') }}: {{
                      scope.row.declaredWeight || '- -'
                    }}
                    </div>
                  </div>
                  <div v-else-if="item.prop === 'objSize'">
                    <div>{{ $t('navbar.objLength') }}:{{ scope.row.objLength || '- -' }}</div>
                  </div>
                  <div v-else>{{ scope.row[item.prop] ? `${scope.row[item.prop]}` : '--' }}</div>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </el-card>
      </el-collapse-item>
      <!--发票信息-->
      <el-collapse-item :title="$t('orderCenterCont.InvoiceInformation')" name="5">
        <el-card class="box-card">
          <el-table
            :data="childrenTableData2"
            :height="isHeight2"
            border
            style="width: 100%"
          >
            <el-table-column
              :label="$t('newOrder.SerialNumber')"
              align="center"
              type="index"
              width="70"
            />
            <template v-for="item in tableHeaderList3">
              <el-table-column
                :key="item.id" :align="item.center" :label="item.label"
                :min-width="item.cloWidth"
                :prop="item.prop"
              >
                <template slot-scope="scope">
                  <div>{{ scope.row[item.prop] ? `${scope.row[item.prop]}` : '--' }}</div>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </el-card>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>

import { getCargoOrderDetail } from '@/api/bulkExpressApi';
import { waybillStatusObj } from '@/utils/enumData';
import { setDateTime2 } from '@/utils/date_util';

export default {
  name: 'ShowDetail',
  props: {
    grandson: {
      type: Number
    }
  },
  data() {
    return {
      activeNames: ['1', '2', '3', '4'],
      tableInfomation: {},
      goodsTableData: [],
      waybillInvoiceVOList: [], // 合单发票数据
      encryptionReceiverInfo: {},
      encryptionSenderInfo: {},
      isHeight: '300px',
      isHeight1: '300px',
      isHeight2: '300px',
      tableHeaderList1: [ // 子件
        { id: 1, label: this.$t('collectionCenter.Number'), prop: 'waybillNumber', cloWidth: '190', center: 'center' },
        {
          id: 2,
          label: this.$t('navbar.newwaybillStatusName'),
          prop: 'waybillStatusName',
          cloWidth: '180',
          center: 'center'
        },
        {
          id: 3,
          label: this.$t('orderCenterCont.LatestTracks'),
          prop: 'lastTrackDescribe',
          cloWidth: '180',
          center: 'center'
        },
        { id: 4, label: this.$t('navbar.lastTrackTime'), prop: 'lastTrackTime', cloWidth: '190', center: 'center' },
        {
          id: 5,
          label: this.$t('collectionCenter.collectionDot'),
          prop: 'collectStationName',
          cloWidth: '160',
          center: 'center'
        },
        {
          id: 6,
          label: this.$t('GlobalSetObj.preEntrega'),
          prop: 'preDeliverStationName',
          cloWidth: '160',
          center: 'center'
        },
        { id: 7, label: this.$t('GlobalSetObj.entrega'), prop: 'deliverStationName', cloWidth: '160', center: 'center' },
        {
          id: 8,
          label: this.$t('orderCenterCont.deliveryPerson'),
          prop: 'delivererName',
          cloWidth: '180',
          center: 'center'
        }
      ],
      tableHeaderList2: [
        { id: 1, label: this.$t('newOrder.designation'), prop: 'name', cloWidth: '220', center: 'center' },
        { id: 2, label: this.$t('newOrder.Quantity'), prop: 'quantity', cloWidth: '100', center: 'center' },
        { id: 3, label: this.$t('newOrder.Unit'), prop: 'company', cloWidth: '100', center: 'center' },
        { id: 4, label: this.$t('newOrder.Declaration'), prop: 'declared', cloWidth: '220', center: 'center' },
        { id: 5, label: this.$t('newOrder.Brand'), prop: 'brand', cloWidth: '100', center: 'center' },
        { id: 6, label: this.$t('newOrder.Size'), prop: 'objSize', cloWidth: '150', center: 'center' },
        { id: 7, label: this.$t('newOrder.Parameters'), prop: 'parameter', cloWidth: '160', center: 'center' },
        {
          id: 8,
          label: this.$t('GlobalSetObj.RegistrationNumber'),
          prop: 'cadastralNumber',
          cloWidth: '100',
          center: 'center'
        },
        { id: 9, label: 'HSCODE', prop: 'hscode', cloWidth: '100', center: 'center' },
        { id: 10, label: this.$t('orderCenterCont.Material'), prop: 'texture', cloWidth: '100', center: 'center' },
        { id: 11, label: this.$t('orderCenterCont.use'), prop: 'purpose', cloWidth: '150', center: 'center' },
        { id: 12, label: this.$t('GlobalSetObj.Link'), prop: 'interlinkage', cloWidth: '150', center: 'center' },
        {
          id: 13,
          label: this.$t('orderCenterCont.Origin'),
          prop: 'placeOfProduction',
          cloWidth: '150',
          center: 'center'
        },
        {
          id: 14,
          label: this.$t('orderCenterCont.specification'),
          prop: 'specification',
          cloWidth: '120',
          center: 'center'
        },
        { id: 15, label: 'SKU', prop: 'sku', cloWidth: '120', center: 'center' },
        { id: 16, label: this.$t('orderCenterCont.insurance'), prop: 'insurance', cloWidth: '120', center: 'center' },
        {
          id: 17,
          label: this.$t('orderCenterCont.BatteryType'),
          prop: 'typeOfBattery',
          cloWidth: '220',
          center: 'center'
        }
      ],
      childrenTableData: [],
      childrenTableData1: [],
      childrenTableData2: [],
      tableHeaderList3: [
        // 子包号
        {
          id: 1,
          label: this.$t('orderCenterCont.SubpackageNumber'),
          prop: 'subPacketNumber',
          cloWidth: '210',
          center: 'center'
        },
        // 订单类型
        { id: 2, label: this.$t('newOrder.OrderType'), prop: 'orderType', cloWidth: '130', center: 'center' },
        // 包裹价值(BRL)
        { id: 3, label: this.$t('GlobalSetObj.packageVlaue'), prop: 'packetValue', cloWidth: '180', center: 'center' },
        // 开票金额(BRL)
        {
          id: 4,
          label: this.$t('placeOrder.InvoiceAmount') + '(BRL)',
          prop: 'invoicedAmount',
          cloWidth: '160',
          center: 'center'
        },
        // 发票号
        { id: 5, label: this.$t('newOrder.InvoiceNumber'), prop: 'invoiceNumber', cloWidth: '160', center: 'center' },
        // 发票密钥
        { id: 6, label: this.$t('newOrder.InvoiceKey'), prop: 'invoiceKey', cloWidth: '200', center: 'center' },
        // 发票序列号
        {
          id: 7,
          label: this.$t('newOrder.TheInvoiceSerialNumber'),
          prop: 'invoiceSerial',
          cloWidth: '180',
          center: 'center'
        },
        // 税码
        { id: 8, label: this.$t('newOrder.TaxCode'), prop: 'taxCode', cloWidth: '160', center: 'center' },
        // 生成时间
        { id: 9, label: this.$t('newOrder.BuildTime'), prop: 'generationTime', cloWidth: '180', center: 'center' },
        // 州注册号
        {
          id: 10,
          label: this.$t('GlobalSetObj.NationalRegistrationNumber'),
          prop: 'ienumber',
          cloWidth: '160',
          center: 'center'
        },
        // 物流发票
        { id: 12, label: this.$t('newOrder.LogisticsInvoices'), prop: 'waybillCte', cloWidth: '160', center: 'center' }
      ],
      labelUrl: '',
      dialogTableVisible: false,
      collectTypeName: {
        1: this.$t('collectionCenter.dropOff'),
        2: this.$t('collectionCenter.doorPickUp')
      },
      expressTypeName: {
        2: this.$t('GlobalSetObj.Canal'),
        3: this.$t('GlobalSetObj.postal')
      },
      cargoTypeName: {
        0: this.$t('placeOrder.Normal'),
        1: this.$t('placeOrder.big01')
      }
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      console.log(this.grandson, 'grandson');
      getCargoOrderDetail(this.grandson).then(res => {
        if (res.status === 'OK') {
          this.tableInfomation = res.data;
          this.childrenTableData = res.data.orderWaybillList;
          if (this.childrenTableData.length > 3) {
            this.isHeight = '360';
          }
          if (this.childrenTableData1.length > 3) {
            this.isHeight1 = '390';
          }
          if (this.childrenTableData2.length > 3) {
            this.isHeight2 = '360';
          }
          this.childrenTableData.forEach(item => {
            try {
              item['waybillStatusName'] = waybillStatusObj[item.waybillStatus].label;
            } catch (e) {
              item['waybillStatusName'] = '--';
            }
          });
          this.childrenTableData1 = res.data.cargoOrderItemList;
          this.childrenTableData2 = res.data.orderInvoiceList;
        }
      });
    },
    async downloadSheep(row) {
      if (row) {
        const url = row.labelUrl;
        if (url) {
          // 点击下载
          const loading = this.$loading({
            fullscreen: true,
            lock: true,
            spinner: 'el-icon-loading',
            text: 'loading...',
            background: 'rgba(250, 250, 250, 0.6)'
          });
          try {
            const response = await fetch(url);
            const blob = await response.blob();
            const res = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = res;
            a.download = setDateTime2() + this.$t('orderCenterCont.FaceSheet');
            document.body.appendChild(a);
            loading.close();
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(res);
          } catch (error) {
            loading.close();
          }
        }
        console.log(row, 'downloadSheep');
      } else {
        // 打开链接
        // window.open(row.labelUrl, '_blank');
        this.labelUrl = row.labelUrl;
        this.dialogTableVisible = true;
      }
    }
  }
};
</script>

<style scoped>
.detialInfo /deep/ .el-collapse-item__header {
  color: #409eff;
  font-size: 16px;
}

.detialInfo /deep/ .el-descriptions-item__label {
  font-size: 13px;
  color: #909399;
}

.detialInfo /deep/ .el-descriptions-item__cell {
  /*height: 35px !important;*/
  padding-bottom: 2px !important;
}

.QuestionMark {
  cursor: pointer;
  color: #409eff;
  margin-right: 10px;
}

.el-descriptions-item__cell div {
  font-weight: bold;
  font-size: 15px;
}

.signeCss {
  font-size: 16px;
  font-weight: bold;
}
</style>
