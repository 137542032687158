<script >
import { defineProps, ref } from '@vue/composition-api';
import { FormActions, FormActionsNext, FormActionsPrevious } from '@/components/wizard-form/formStepActions';
import { required, minLength, maxLength, numeric, between, alpha } from '@/utils/validationErrors';
import { addingLoading, closeLoading } from '@/utils/handleControlLoading';
import { driverService } from '@/api/transport/driver';
import { mdfeService } from '@/api/mdfe';
import useVuelidate from '@vuelidate/core';
const __sfc_main = {};
__sfc_main.props = {
  isLastStep: {
    type: Boolean,
    required: true
  },
  isFirstStep: {
    type: Boolean,
    required: true
  },
  handleNext: {
    type: Function,
    required: true
  },
  handlePrevious: {
    type: Function
  },
  formData: {
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const rules = {
    nameDriver: {
      required
    },
    documentOwnerVehicle: {
      required,
      maxLength: maxLength(18),
      minLength: minLength(14)
    },
    ie: {
      required,
      maxLength: maxLength(14),
      minLength: minLength(9)
    },
    renavam: {
      required
    },
    rntrc: {
      required
    },
    company: {
      required
    },
    capacityKg: {
      required
    },
    licensePlate: {
      required
    },
    tara: {
      required
    },
    bodyType: {
      required
    },
    wheelsetType: {
      required
    },
    uf: {
      required
    }
  };
  const v$ = useVuelidate(rules, props.formData);
  async function handleSubmit() {
    const isValid = await v$.value.$validate();
    if (isValid) props.handleNext();
  }
  const optionsBodyType = [{
    value: 'NOT_APPLICABLE',
    label: 'Não aplicavel'
  }, {
    value: 'CLOSED_CHEST',
    label: 'Caixa fechada'
  }, {
    value: 'OPEN',
    label: 'Aberto'
  }, {
    value: 'BULK',
    label: 'Volume'
  }, {
    value: 'CONTAINER_DOOR',
    label: 'PORTA DO CONTÊINER'
  }, {
    value: 'SIDER',
    label: 'Sider'
  }];
  const optionsWheelsetType = [{
    value: 'TRUCK',
    label: 'Truck'
  }, {
    value: 'STUMP',
    label: 'Stump'
  }, {
    value: 'MECHANICAL_HORSE',
    label: 'Mechanical horse'
  }, {
    value: 'VAN',
    label: 'Van'
  }, {
    value: 'UTILITY',
    label: 'Utility'
  }, {
    value: 'OTHERS',
    label: 'Others'
  }];
  const listUF = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];
  const listDrivers = ref([]);
  async function filteredDriver() {
    try {
      const res = await driverService.getAll({
        skip: 0,
        take: 10,
        name: props.formData.nameDriver
      });
      listDrivers.value = res.data.result.map(driver => driver.driver_name);
    } catch (error) {
      listDrivers.value = [];
    }
  }
  async function selectedDriver(selectDriver) {
    try {
      addingLoading();
      const res = await mdfeService.getInfoDriver(selectDriver);
      props.formData.infAnttRNTRC = res.data.infANTT.RNTRC;
      props.formData.nameDriver = res.data.condutor[0].xNome;
      props.formData.documentDriver = res.data.condutor[0].CPF;
      props.formData.veicTracaoCint = res.data.veicTracao.cInt ? res.data.veicTracao.cInt.toString() : '1';
      props.formData.licensePlate = res.data.veicTracao.placa;
      props.formData.renavam = res.data.veicTracao.RENAVAM;
      props.formData.tara = res.data.veicTracao.tara;
      props.formData.capacityKg = res.data.veicTracao.capKG;
      props.formData.documentOwnerVehicle = res.data.veicTracao.prop.CNPJ || res.data.veicTracao.prop.CPF;
      props.formData.rntrc = res.data.veicTracao.prop.RNTRC;
      props.formData.company = res.data.veicTracao.prop.xNome || 'Anjun express';
      props.formData.ie = res.data.veicTracao.prop.IE;
      props.formData.uf = res.data.veicTracao.prop.UF;
      props.formData.tpProp = res.data.veicTracao.prop.tpProp ?? '1';
      props.formData.wheelsetType = res.data.tpRod;
      props.formData.bodyType = res.data.tpCar;
      props.formData.condutorUF = res.data.UF;
    } catch (error) {
      props.formData.infAnttRNTRC = null;
      props.formData.documentDriver = null;
      props.formData.veicTracaoCint = null;
      props.formData.licensePlate = null;
      props.formData.renavam = null;
      props.formData.tara = null;
      props.formData.capacityKg = null;
      props.formData.documentOwnerVehicle = null;
      props.formData.rntrc = null;
      props.formData.company = null;
      props.formData.ie = null;
      props.formData.uf = null;
      props.formData.tpProp = null;
      props.formData.wheelsetType = null;
      props.formData.bodyType = null;
      props.formData.condutorUF = null;
    } finally {
      closeLoading();
    }
  }
  return {
    v$,
    handleSubmit,
    optionsBodyType,
    optionsWheelsetType,
    listUF,
    listDrivers,
    filteredDriver,
    selectedDriver
  };
};
__sfc_main.components = Object.assign({
  FormActions,
  FormActionsPrevious,
  FormActionsNext
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <form class="wrapper-form" @submit.prevent="handleSubmit">
    <o-field
      label="Motorista"
      label-for="driver"
      class="col-span-4"
      :message="v$.nameDriver.$errors.length ? v$.nameDriver.$errors[0].$message : null"
    >
      <o-autocomplete
        id="driver"
        v-model="formData.nameDriver"
        placeholder="Digite o nome de um motorista existente"
        expanded
        :data="listDrivers"
        @input="filteredDriver"
        @select="selectedDriver"
      >
        <template slot="empty">Sem resultados</template>
      </o-autocomplete>
    </o-field>

    <o-field
      label="Documento"
      label-for="document"
      class="col-span-2"
      :message="v$.documentOwnerVehicle.$errors.length ? v$.documentOwnerVehicle.$errors[0].$message : null"
    >
      <o-input
        id="document"
        v-model="formData.documentOwnerVehicle"
        v-mask="['###.###.###-##', '##.###.###/####-##']"
        placeholder="Digite o documento do dono do veículo"
      />
    </o-field>

    <o-field
      label="IE"
      label-for="ie"
      class="col-span-2"
      :message="v$.ie.$errors.length ? v$.ie.$errors[0].$message : null"
    >
      <o-input
        id="ie"
        v-model="formData.ie"
        placeholder="Digite a IE do dono"
      />
    </o-field>

    <o-field
      label="RNTRC"
      label-for="rntrc"
      class="col-span-2"
      :message="v$.rntrc.$errors.length ? v$.rntrc.$errors[0].$message : null"
    >
      <o-input
        id="rntrc"
        v-model="formData.rntrc"
        placeholder="Digite o RNTRC"
      />
    </o-field>

    <o-field
      label="RENAVAM"
      label-for="renavam"
      class="col-span-2"
      :message="v$.renavam.$errors.length ? v$.renavam.$errors[0].$message : null"
    >
      <o-input
        id="renavam"
        v-model="formData.renavam"
        placeholder="Digite o RENAVAM"
      />
    </o-field>

    <o-field
      label="Nome da empresa"
      label-for="companyDriver"
      class="col-span-4"
      :message="v$.company.$errors.length ? v$.company.$errors[0].$message : null"
    >
      <o-input
        id="companyDriver"
        v-model="formData.company"
        placeholder="Digite a empresa do motorista"
      />
    </o-field>

    <o-field
      label="Placa"
      label-for="licensePlate"
      class="col-span-2"
      :message="v$.licensePlate.$errors.length ? v$.licensePlate.$errors[0].$message : null"
    >
      <o-input
        id="licensePlate"
        v-model="formData.licensePlate"
        v-mask="'AAA-XXXX'"
        placeholder="Digite a placa do veículo"
      />
    </o-field>

    <o-field
      label="Capacidade (KG)"
      label-for="capacityKg"
      :message="v$.capacityKg.$errors.length ? v$.capacityKg.$errors[0].$message : null"
    >
      <o-input
        id="capacityKg"
        v-model="formData.capacityKg"
        placeholder="100"
      />
    </o-field>

    <o-field
      label="Tara"
      label-for="tara"
      :message="v$.tara.$errors.length ? v$.tara.$errors[0].$message : null"
    >
      <o-input
        id="tara"
        v-model="formData.tara"
        placeholder="03"
      />
    </o-field>

    <o-field
      label="Tipo de Corpo"
      label-for="bodyType"
      class="col-span-2"
      :message="v$.bodyType.$errors.length ? v$.bodyType.$errors[0].$message : null"
    >
      <o-select id="bodyType" v-model="formData.bodyType" placeholder="Selecione um tipo de corpo">
        <option v-for="bodyType in optionsBodyType" :key="bodyType.value" :value="bodyType.value"> {{ bodyType.label }} </option>
      </o-select>
    </o-field>

    <o-field
      label="Tipo de roda"
      label-for="wheelsetType"
      :message="v$.wheelsetType.$errors.length ? v$.wheelsetType.$errors[0].$message : null"
    >
      <o-select id="wheelsetType" v-model="formData.wheelsetType" placeholder="tipo de roda">
        <option v-for="wheelsetType in optionsWheelsetType" :key="wheelsetType.value" :value="wheelsetType.value"> {{ wheelsetType.label }} </option>
      </o-select>
    </o-field>

    <o-field
      label="UF"
      label-for="uf"
      :message="v$.uf.$errors.length ? v$.uf.$errors[0].$message : null"
    >
      <o-select id="uf" v-model="formData.uf" placeholder="SP">
        <option v-for="UF in listUF" :key="UF" :value="UF"> {{ UF }} </option>
      </o-select>
    </o-field>

    <FormActions>
      <FormActionsPrevious :is-first-step="isFirstStep" @handlePrevious="handlePrevious" />
      <FormActionsNext :is-last-step="isLastStep" />
    </FormActions>
  </form>
</template>

<style scope>
.wrapper-form {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 1rem/* 16px */;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}
</style>
