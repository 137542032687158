<script >
import { default as DropdownTable } from '@/components/dropdownTable';
import { default as TimeLinePoint } from './TimelinePoint.vue';
import { default as Alert } from '@/components/alert';
import { default as FormEquipament } from './formEquipamento/formEquipment.vue';
import { addingLoading, closeLoading } from '@/utils/handleControlLoading';
import { getToastContentComponent } from '@/utils/getToastContentComponent';
import { transferRouterService } from '@/api/transfer/transferRoute/index';
import { defineProps, ref } from '@vue/composition-api';
import { useQueryClient } from '@tanstack/vue-query';
import getMessageError from '@/utils/getMessageError';
import { FiltersEnum } from '../enum/filtersEnum';
import { useModal } from '@/composable/useModal';
import { toast } from '@/utils/programaticToast';
const __sfc_main = {};
__sfc_main.props = {
  row: {
    type: Object
  },
  filters: {
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const step = ref(0);
  const handlePreviousStep = () => {
    step.value--;
  };
  const handleNextStep = () => {
    if (props.filters.status === FiltersEnum.IN_PROGRESS && step.value <= 2) {
      step.value++;
    }
  };
  const handleClearSteps = () => {
    handleShowRouteModal();
    step.value = 0;
  };
  const {
    handleVisibility: handleShowEditModal,
    isOpen: isOpenEditModal
  } = useModal();
  const {
    handleVisibility: handleShowRouteModal,
    isOpen: isOpenRouteModal
  } = useModal();
  const {
    handleVisibility: handleShowEquipmentModal,
    isOpen: isOpenEquipmentModal
  } = useModal();
  const {
    handleVisibility: handleShowOrderModal,
    isOpen: isOpenOrderModal
  } = useModal();
  const {
    handleVisibility: handleShowDeleteModal,
    isOpen: isOpenDeleteModal
  } = useModal();
  const queryClient = useQueryClient();
  const handleDelete = async () => {
    try {
      addingLoading();
      await transferRouterService.delete(props.row.route_id);
      toast.open({
        variant: 'success',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Excluído com sucesso!',
          describe: 'Rota de transferência excluída com sucesso!',
          typeIcon: 'success'
        }),
        closable: true,
        duration: 4000
      });
      queryClient.invalidateQueries({
        queryKey: ['transfer-route']
      });
      handleShowDeleteModal();
    } catch (error) {
      toast.close({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Ocorreu um erro!',
          describe: getMessageError(error),
          typeIcon: 'error'
        }),
        closable: true,
        duration: 4000
      });
    } finally {
      closeLoading();
    }
  };
  return {
    FiltersEnum,
    props,
    step,
    handlePreviousStep,
    handleNextStep,
    handleClearSteps,
    handleShowRouteModal,
    isOpenRouteModal,
    handleShowEquipmentModal,
    isOpenEquipmentModal,
    handleShowDeleteModal,
    isOpenDeleteModal,
    handleDelete
  };
};
__sfc_main.components = Object.assign({
  DropdownTable,
  TimeLinePoint,
  FormEquipament,
  Alert
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <DropdownTable>
      <o-dropdown-item aria-role="listitem" @click="handleClearSteps">
        {{ props.filters.status == FiltersEnum.ACTIVE || props.filters.status == FiltersEnum.FINISHED ? "Visualizar rota" : "Atualizar carga" }}
      </o-dropdown-item>

      <o-dropdown-item v-if="props.filters.status == FiltersEnum.ACTIVE" aria-role="listitem" @click="handleShowEquipmentModal">
        Equipamento
      </o-dropdown-item>

      <!-- AINDA NÃO VAI TER O SERVIÇO DE EDITAR ROTA -->

      <!-- <o-dropdown-item aria-role="listitem" @click="handleShowOrderModal">
        Gerar ordem
      </o-dropdown-item>

      <o-dropdown-item v-if="props.filters.status == FiltersEnum.ACTIVE" aria-role="listitem" @click="handleShowEditModal">
        Editar
      </o-dropdown-item> -->

      <o-dropdown-item v-if="props.filters.status == FiltersEnum.ACTIVE" aria-role="listitem" @click="handleShowDeleteModal">
        Excluir
      </o-dropdown-item>
    </DropdownTable>

    <!-- <o-modal width="560" :active="isOpenEditModal" @close="handleShowEditModal">
      <div>
        <h2 class="header-2">Editando Rota</h2>
        <p class="paragraph">Preencha os campos logo abaixo para realizar a edição da rota em nosso sistema</p>
      </div>

      <FormCreateTransferRoute :data="row" :close-modal="handleShowEditModal" />
    </o-modal>

    <o-modal width="560" :active="isOpenOrderModal" @close="handleShowOrderModal">
      <div>
        <h2 class="header-2">Gerar ordem</h2>
      </div>
    </o-modal> -->

    <o-modal width="560" :active="isOpenRouteModal" @close="handleShowRouteModal">
      <div>
        <h2 class="header-2">
          {{ props.filters.status == FiltersEnum.ACTIVE || props.filters.status == FiltersEnum.FINISHED ? "Visualizar rota" : "Atualizar carga" }}
        </h2>
        <p v-show="filters.status === FiltersEnum.IN_PROGRESS && step === 0" class="paragraph">Clique em uma base abaixo para obter mais informações</p>

        <TimeLinePoint
          :row="row"
          :step="step"
          :handle-previous-step="handlePreviousStep"
          :handle-next-step="handleNextStep"
          :handle-show-route-modal="handleShowRouteModal"
        />
      </div>
    </o-modal>

    <o-modal width="560" :active="isOpenEquipmentModal" @close="handleShowEquipmentModal">
      <div>
        <h2 class="header-2">Criando equipamento</h2>
        <p class="paragraph">Preencha os campos logo abaixo para realizar a criação de um equipamento em nosso sistema</p>
      </div>

      <FormEquipament :close-modal="handleShowEquipmentModal" :route-id="row.route_id" />

    </o-modal>

    <Alert
      :active="isOpenDeleteModal"
      @handleClose="handleShowDeleteModal"
    >
      <template #title> Você tem certeza absoluta? </template>
      <template #message> Esta ação não pode ser desfeita. Isso irá desativar as informações desta rota. </template>

      <template #buttons>
        <o-button variant="outline" size="md" @click="handleShowDeleteModal"> Cancelar </o-button>
        <o-button variant="primary" size="md" @click="handleDelete"> Sim, quero excluir </o-button>
      </template>
    </Alert>
  </div>

</template>
