<template>
  <div class="app-container po">
    <el-dialog
      :title="permissionOpt.title"
      :visible.sync="permissionOpt.visible"
      width="30%"
      :close-on-click-modal="true"
      @open="openPermissionOpt"
      @close="closePermissionOpt"
    >
      <!--
        权限表单:
      -->
      <el-form
        ref="permissionRef"
        :model="permissonForm"
        :rules="permissonRules"
        label-width="100px"
        label-position="left"
      >
        <el-row v-if="permissionOpt.type!=='add'">
          <el-col :span="24">
            <!-- 权限ID -->
            <el-form-item :label="$t('roleManage.permissionsID')">
              <el-input v-model="permissonForm.id" disabled />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-show="permissonForm.parentId!==0">
          <el-col :span="24">
            <!-- 父权限ID -->
            <el-form-item :label="$t('roleManage.parentPermissionID')" prop="parentId">
              <el-input v-show="false" v-model="permissonForm.parentId" disabled />
              <el-input v-model="permissionOpt.parentName" disabled />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <!-- 权限名称 -->
            <el-form-item :label="$t('roleManage.permissionsName')" prop="name">
              <el-input v-model.trim="permissonForm.name" autocomplete="off" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <!-- 权限标识 -->
            <el-form-item :label="$t('roleManage.permissionsTag')" prop="code">
              <el-input v-model.trim="permissonForm.code" autocomplete="off" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <!-- 排序 -->
            <el-form-item
              :label="$t('roleManage.sort')"
              prop="sort"
              :rules="[ { required: true, message: $t('roleManage.sortNotEmpty')},{ type: 'number', message: $t('roleManage.sortIsNumber')}]"
            ><!--                                   排序不能为空                               排序必须为数字值 -->
              <el-input
                v-model.number="permissonForm.sort"
                type="sort"
                :placeholder="$t('roleManage.littleValueFront')"
                autocomplete="off"
              /><!-- 值越小越靠前 -->
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <!-- 备注 -->
            <el-form-item :label="$t('roleManage.note')">
              <el-input v-model="permissonForm.remark" type="textarea" autocomplete="off" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item size="large">
              <el-button size="small" type="primary" @click="onSubmit">{{ $t('roleManage.confirm') }}</el-button><!-- 确认 -->
              <el-button size="small" @click="permissionOpt.visible = false">{{ $t('roleManage.cancel') }}</el-button><!-- 取消 -->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>

import { addPermission, editPermission } from '@/api/permission';

export default {
  name: 'PermissionOperation',
  props: {
    permissionOpt: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      permissonRules: {
        // '父权限ID不能为空'
        parentId: [{ required: true, message: this.$t('organizationManage.PpermissionIDNotEmpty'), trigger: ['blur', 'change'] }],
        // '权限名称不能为空'
        name: [{ required: true, message: this.$t('organizationManage.permissionNameNotEmpty'), trigger: ['blur', 'change'] }],
        // '权限标识不能为空'
        code: [{ required: true, message: this.$t('organizationManage.permissionLogoNotEmpty'), trigger: ['blur', 'change'] }]
      },
      permissonForm: {
        id: null,
        parentId: null,
        name: '',
        code: '',
        sort: 1,
        remark: ''
      }
    };
  },
  methods: {
    openPermissionOpt() {
      this.permissonForm.id = this.permissionOpt.id;
      this.permissonForm.parentId = this.permissionOpt.parentId || 0;
      this.permissonForm.name = this.permissionOpt.name;
      this.permissonForm.code = this.permissionOpt.code;
      this.permissonForm.sort = this.permissionOpt.sort || 1;
      this.permissonForm.remark = this.permissionOpt.remark;
    },
    closePermissionOpt() {
      this.$refs['permissionRef'].resetFields();
    },
    onSubmit() {
      this.$refs.permissionRef.validate((valid) => {
        if (!valid) {
          return;
        }
        // 添加权限
        if (this.permissionOpt.type === 'add') {
          addPermission(this.permissonForm).then(() => {
            this.permissionOpt.visible = false;
            this.$emit('on-add-submit');
          }).catch(err => {
            console.error(err);
          });
        } else {
          editPermission(this.permissonForm).then(() => {
            this.permissionOpt.visible = false;
            this.$emit('on-add-submit');
          }).catch(err => {
            console.error(err);
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>

.app-container.po ::v-deep .el-form-item__error{
  top: 40px;
}
</style>
