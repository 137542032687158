var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        },
      },
    },
    [
      _c(
        "o-field",
        {
          staticClass: "col-span-3",
          attrs: { label: "Selecione a empresa", "label-for": "company" },
        },
        [
          _c(
            "o-select",
            {
              attrs: { id: "company", placeholder: "Selecione a empresa" },
              on: { input: _vm.handleSelectedCompany },
              model: {
                value: _vm.selectCompany,
                callback: function ($$v) {
                  _vm.selectCompany = $$v
                },
                expression: "selectCompany",
              },
            },
            _vm._l(_vm.optionsCompanies, function (company) {
              return _c(
                "option",
                { key: company, domProps: { value: company } },
                [_vm._v(" " + _vm._s(company) + " ")]
              )
            }),
            0
          ),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: "Cód. UF Inicial",
            "label-for": "cUF",
            message: _vm.v$.cUF.$errors.length
              ? _vm.v$.cUF.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: { id: "cUF", placeholder: "Digite o código da UF" },
            model: {
              value: _vm.formData.cUF,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "cUF", $$v)
              },
              expression: "formData.cUF",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          staticClass: "col-span-3",
          attrs: {
            label: "CEP inicial",
            "label-for": "initialZipCode",
            message: _vm.v$.initialZipCode.$errors.length
              ? _vm.v$.initialZipCode.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            directives: [
              {
                name: "mask",
                rawName: "v-mask",
                value: "#####-###",
                expression: "'#####-###'",
              },
            ],
            attrs: {
              id: "initialZipCode",
              placeholder: "Digite o CEP inicial",
            },
            model: {
              value: _vm.formData.initialZipCode,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "initialZipCode", $$v)
              },
              expression: "formData.initialZipCode",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: "UF inicial",
            "label-for": "initialUF",
            message: _vm.v$.initialUF.$errors.length
              ? _vm.v$.initialUF.$errors[0].$message
              : null,
          },
        },
        [
          _c(
            "o-select",
            {
              attrs: { id: "initialUF", placeholder: "SP" },
              model: {
                value: _vm.formData.initialUF,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "initialUF", $$v)
                },
                expression: "formData.initialUF",
              },
            },
            _vm._l(_vm.listUF, function (UF) {
              return _c("option", { key: UF, domProps: { value: UF } }, [
                _vm._v(" " + _vm._s(UF) + " "),
              ])
            }),
            0
          ),
        ],
        1
      ),
      _c(
        "o-field",
        {
          staticClass: "col-span-2",
          attrs: {
            label: "Nome do município inicial",
            "label-for": "initialNameMunicipality",
            message: _vm.v$.initialNameMunicipality.$errors.length
              ? _vm.v$.initialNameMunicipality.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "initialNameMunicipality",
              placeholder: "Nome do município",
            },
            model: {
              value: _vm.formData.initialNameMunicipality,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "initialNameMunicipality", $$v)
              },
              expression: "formData.initialNameMunicipality",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          staticClass: "col-span-2",
          attrs: {
            label: "Código do município inicial",
            "label-for": "initialCodMunicipality",
            message: _vm.v$.initialCodMunicipality.$errors.length
              ? _vm.v$.initialCodMunicipality.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "initialCodMunicipality",
              placeholder: "Código do município",
            },
            model: {
              value: _vm.formData.initialCodMunicipality,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "initialCodMunicipality", $$v)
              },
              expression: "formData.initialCodMunicipality",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          staticClass: "col-span-3",
          attrs: {
            label: "CEP final",
            "label-for": "finalZipCode",
            message: _vm.v$.finalZipCode.$errors.length
              ? _vm.v$.finalZipCode.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: { id: "finalZipCode", placeholder: "Digite o CEP final" },
            model: {
              value: _vm.formData.finalZipCode,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "finalZipCode", $$v)
              },
              expression: "formData.finalZipCode",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: "UF Final",
            "label-for": "finalUF",
            message: _vm.v$.finalUF.$errors.length
              ? _vm.v$.finalUF.$errors[0].$message
              : null,
          },
        },
        [
          _c(
            "o-select",
            {
              attrs: { id: "finalUF", placeholder: "SP" },
              model: {
                value: _vm.formData.finalUF,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "finalUF", $$v)
                },
                expression: "formData.finalUF",
              },
            },
            _vm._l(_vm.listUF, function (UF) {
              return _c("option", { key: UF, domProps: { value: UF } }, [
                _vm._v(" " + _vm._s(UF) + " "),
              ])
            }),
            0
          ),
        ],
        1
      ),
      _c(
        "o-field",
        {
          staticClass: "col-span-2",
          attrs: {
            label: "Nome do município final",
            "label-for": "finalnameMunicipality",
            message: _vm.v$.finalNameMunicipality.$errors.length
              ? _vm.v$.finalNameMunicipality.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "finalnameMunicipality",
              placeholder: "Nome do município",
            },
            model: {
              value: _vm.formData.finalNameMunicipality,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "finalNameMunicipality", $$v)
              },
              expression: "formData.finalNameMunicipality",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          staticClass: "col-span-2",
          attrs: {
            label: "Código do município final",
            "label-for": "finalCodMunicipality",
            message: _vm.v$.finalCodMunicipality.$errors.length
              ? _vm.v$.finalCodMunicipality.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "finalCodMunicipality",
              placeholder: "Código do município",
            },
            model: {
              value: _vm.formData.finalCodMunicipality,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "finalCodMunicipality", $$v)
              },
              expression: "formData.finalCodMunicipality",
            },
          }),
        ],
        1
      ),
      _c(
        "FormActions",
        [
          _c("FormActionsPrevious", {
            attrs: { "is-first-step": _vm.isFirstStep },
            on: { handlePrevious: _vm.handlePrevious },
          }),
          _c("FormActionsNext", { attrs: { "is-last-step": _vm.isLastStep } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }