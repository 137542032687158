<script >
import { defineProps, computed } from '@vue/composition-api';
import getMessageError from '@/utils/getMessageError';
const __sfc_main = {};
__sfc_main.props = ['error', 'isError'];
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const customError = computed(() => {
    return getMessageError(props.error);
  });
  function handleReloadWindow() {
    location.reload();
  }
  return {
    customError,
    handleReloadWindow
  };
};
export default __sfc_main;
</script>

<template>
  <div>
    <div v-if="isError" class="wrapper-error">
      <h2 class="header-1">Error ao mostrar dados: {{ customError }} </h2>
      <o-button variant="primary" size="md" @click="handleReloadWindow"> Tentar novamente </o-button>
    </div>
    <div v-else>
      <slot />
    </div>
  </div>
</template>

<style scoped>
  .wrapper-error {
    display: flex;
    max-width: 35rem; /* 560px */
    height: calc(100vh - 126px);
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem; /* 16px */
    text-align: center;
  }
</style>
