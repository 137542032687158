<template>
  <div>
    <div class="tabClass">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane :key="0" :label="$t('navbar.tDuration1')" name="transportationTime">
          <transportationTime v-if="isShow" />
        </el-tab-pane>
        <el-tab-pane :key="1" :label="$t('navbar.durationTransit')" name="durationTransit">
          <durationTransit v-if="isShow1" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AgingManagement',
  components: {
    'transportationTime': () => import('./component/transportationTime'),
    'durationTransit': () => import('./component/durationTransit')
  },
  data() {
    return {
      activeName: 'transportationTime',
      noRouteAssociated: 0,
      isShow: true,
      isShow1: false
    };
  },
  methods: {
    handleClick(tab, event) {
      if (tab.name === 'transportationTime') {
        this.isShow1 = false;
        this.isShow = true;
      }
      if (tab.name === 'durationTransit') {
        this.isShow = false;
        this.isShow1 = true;
      }
    }
  }
};
</script>

<style scoped>

.tabClass {
  padding: 10px;
}
</style>
