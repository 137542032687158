<template>
  <div class="basicTable">
    <el-row :gutter="20">
      <template v-if="[0,2].includes(status)">
        <!-- 添加  -->
        <el-button
          v-permit:remove="'but:compensationManagement:compensationList:details:add'"
          type="primary"
          size="small"
          :disabled="!isAddDisabled"
          @click="isAdd = true"
        >{{ $t('GlobalSetObj.append') }}</el-button>
        <!-- 导入 -->
        <el-button
          type="info"
          size="small"
          :disabled="!isAddDisabled"
          @click="isImport = true"
        >{{ $t('CenterForSorting.Import') }}</el-button>
        <!-- 移除 -->
        <el-button
          v-permit:remove="'but:compensationManagement:compensationList:details:remove'"
          type="danger"
          size="small"
          :disabled="!multipleSelection.length"
          @click="onRemove"
        >{{ $t('ClaimsManagement.Remove') }}</el-button>
      </template>

      <!-- <template v-else> -->
        <!--  导出勾选数据 :disabled="!multipleSelection.length"-->
        <el-button
          type="info"
          size="small"
          @click="onExport"
        >{{ $t('collectionCenter.export') }}</el-button>
      <!-- </template> -->

      <el-col :span="8">
        <StatisticiansTipsCmp :arr="arr" />
      </el-col>

      <el-col :span="6">
          <span>{{ $t('compensationManagement.orderStatus') }}：</span>
          <el-select v-model="orderType" clearable :placeholder="$t('operationCenter.PleaseSelect')"
            @change="searchOrderType"
          >
            <el-option
              v-for="item in optionList"
              :key="item.value"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
      </el-col>
    </el-row>

    <el-table
      :data="tableData"
      max-height="350"
      border
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <!--序号-->
      <el-table-column type="index" :label="$t('operationCenter.ind')" width="60px" />
      <!-- 单号 -->
      <el-table-column :label="$t('ChannelManage.oddNumbers')" prop="barCode" width="180" />
      <!-- 客户单号 -->
      <el-table-column :label="$t('collectionCenter.customerNum')" prop="customerNumber" width="180" />
      <!-- 安骏单号 -->
      <el-table-column :label="$t('collectionCenter.AnjunTrackingNumber')" prop="waybillNumber" width="180" />
      <!-- 申报价值 -->
      <el-table-column :label="$t('orderCenterCont.DeclaredValue')" prop="totalDeclaredValue" width="120"/>
      <!-- 订单状态 -->
      <el-table-column :label="$t('compensationManagement.orderStatus')" prop="statusText" width="120"/>
      <template v-if="isShow">
        <!-- 当前订单状态 -->
        <el-table-column :label="$t('compensationManagement.currentOrderStatus')" prop="realTimeWaybillStatusText" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.realTimeWaybillStatusText }}</span>
          </template>
        </el-table-column>
      </template>
      <!-- 当前轨迹 -->
      <el-table-column :label="$t('ClaimsManagement.CurrentTrajectory')" prop="trajectory" width="160">
        <template slot-scope="scope">
          <span v-if="$i18n.locale === 'zh'">{{ scope.row.trajectory }}</span>
          <span v-else>{{ scope.row.trajectoryPu }}</span>
        </template>
      </el-table-column>
      <!-- 轨迹时间 -->
      <el-table-column :label="$t('ClaimsManagement.TrackTime')" prop="trajectoryTime" width="160" />
      <template v-if="!isColumn">
        <!-- 实际入库网点 -->
        <el-table-column :label="$t('GlobalSetObj.infeatSite')" prop="deliverWarehouseStationName" width="160"/>
        <!-- 实际派送网点 -->
        <el-table-column :label="$t('GlobalSetObj.entrega')" prop="deliverStationName" width="160"/>
        <!-- 派送员 -->
        <el-table-column :label="$t('orderCenterCont.deliveryPerson')" prop="dispatchName" width="160"/>
        <!-- 网点派件时间 -->
        <el-table-column :label="$t('orderCenterCont.deliveryTime')" prop="deliveredTime" width="160"/>
        <!-- 签收时间 -->
        <el-table-column :label="$t('GlobalSetObj.SigningTime')" width="160" prop="signTime"/>
      </template>
      <template v-else>
        <!-- 揽收人 -->
        <el-table-column :label="$t('collectionCenter.LanShouPeople')" prop="dispatchName" />
        <!-- 揽收时间 -->
        <el-table-column :label="$t('collectionCenter.lanshouT')" prop="deliveredTime" />
        <!-- 揽收网点 -->
        <el-table-column :label="$t('collectionCenter.siteName')" prop="deliverStationName" />
      </template>
      <template v-if="isShow">
        <!-- 最新入库网点 -->
        <el-table-column :label="$t('compensationManagement.latestWarehouseEntryPoints')" prop="realTimeSiteName" width="120"/>
        <!-- 最新入库时间 -->
        <el-table-column :label="$t('compensationManagement.latestStorageTime')" prop="consigneeTime" width="120"/>
        <!-- 收件人州 -->
        <el-table-column :label="$t('newOrder.RecipientState')" prop="receiveState" width="120"/>
      </template>
    </el-table>
    <!-- 分页工具 -->
    <div v-if="[0,2].includes(status) === false" class="pagination">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryTable.pageNumber"
        :page-sizes="[10, 20, 50, 100, 500]"
        :page-size.sync="queryTable.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getCurrentTableData"
        @size-change="getSizeTableData"
      />
    </div>

    <!-- 添加单号 -->
    <el-dialog
      :title="$t('orderCenterCont.AddOrder')"
      :visible.sync="isAdd"
      width="500px"
      append-to-body
      :close-on-click-modal="false"
      :before-close="onClose">
      <el-input
        v-loading="loading"
        type="textarea"
        :placeholder="$t('trajectory.multipleOrders')"
        v-model="orderList"
        :autosize="{ minRows: 5, maxRows: 10}"
      />
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="onClose">{{ $t('GlobalSetObj.cancel') }}</el-button>
        <el-button size="small" type="primary" @click="add">{{ $t('GlobalSetObj.determine') }}</el-button>
      </span>
    </el-dialog>

    <!-- 导入单号 -->
    <el-dialog
      :title="$t('CenterForSorting.Import')"
      :visible.sync="isImport"
      width="500px"
      append-to-body
      :close-on-click-modal="false"
      :before-close="onClose">
      <ImportExcelCmp
        import-tmp-url="https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/upload-file/workOrder/%E7%B4%A2%E8%B5%94%E6%A8%A1%E6%9D%BF-202410241030-20241025T104256.xlsx?q-sign-algorithm=sha1&q-ak=AKID2vc8UcWIfqskPtEKDtMfookXDD1e6JP6&q-sign-time=1729824176;2045184176&q-key-time=1729824176;2045184176&q-header-list=host&q-url-param-list=&q-signature=263596cbd8621e8468e0302c265fa0254729354d" :key-and-val-obj="excelKeyAndValue"
        @change="onImportClick"
      />
    </el-dialog>
  </div>
</template>

<script>
import StatisticiansTipsCmp from '@/components/StatisticiansTipsCmp/index.vue';
import {
  apiDemageClaimformOrderDetail,
  apiDemageClaimformExport,
  apiDemageClaimFormRemoveBill,
  apiDemageClaimformUnClimFromDetail
} from '@/api/compensationManagement/list';
import { goExportList } from '@/utils/goExportList.js';
import { P_ORDER_STATUS, ORDER_STATUS } from '@/views/compensationManagement/content.js'
import { cloneDeep } from 'lodash-es';
import Big from 'big.js';

export default {
  props: {
    // 订单id
    id: {
      type: Number | String,
      default: ''
    },
    // 节点状态
    status: {
      type: String | Number,
      default: ''
    },
    // 基础信息
    formData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    // 表格数据
    dataList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: {
    StatisticiansTipsCmp,
    ImportExcelCmp: () => import('../../components/ImportExcelCmp.vue')
  },

  watch: {
    // 详情数据更新
    'dataList': {
      immediate: true,
      handler(val) {
        // 编辑状态下
        if ([0, 2].includes(this.status)) {
          this.tableData = val; // 赋值
          this.backupsList = cloneDeep(val);
        }
      }
    },

    // 关联订单数据更新汇总数据
    'tableData': {
      immediate: true,
      handler(val) {
        if ([0, 2].includes(this.status)) {
          // 新增汇总读取缓存数据
          this.arr[0].value = this.backupsList.length;
          this.arr[1].value = this.totalValue();
        }
      }
    },
    // 监听金额变化
    'formData.claimAmount': {
      immediate: true,
      handler(val) {
        // 关联订单数据为空，读取表单金额
        if (!this.tableData.length) {
          this.arr[1].value = val || 0;
        }
      }
    },

    // 监听业务类型
    'formData.claimsNode': {
      immediate: true,
      handler(val) {
        if (!val) return;
        this.optionList = val === 4 ? ORDER_STATUS : P_ORDER_STATUS;
      }
    }
  },
  computed: {
    // 是否可添加/导入
    isAddDisabled() {
      // if (this.formData.customerId && this.formData.claimsNode) {
      if (this.formData.claimsNode && this.formData.claimsType) {
        return true;
      }
      return false;
    },

    // 业务类型切换column
    isColumn() {
      if (this.formData.claimsNode === 4) {
        return true;
      }
      return false;
    },

    isShow() {
      if ([5, 6].includes(this.formData.claimsNode)) {
        return true;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      arr: [
        {
          name: this.$t('ClaimsManagement.TotalNumberOfVotes'), // 总票数
          value: 0,
          suffix: '；'
        },
        {
          name: this.$t('ClaimsManagement.TotalDeclaredValue'), // 总申报价值
          value: 0,
          suffix: '；'
        }
      ],
      isAdd: false, // 是否添加
      isImport: false, // 是否导入
      list: [],
      orderList: '', // 添加单号
      tableData: [], // 订单数据
      backupsList: [], // 备份数据
      multipleSelection: [], // 多选
      loading: false,
      excelKeyAndValue: {
        'barCode': {
          key: 'barCode',
          header: '订单号*'
        }
      },
      queryTable: {
        pageNumber: 1,
        pageSize: 20,
        params: {
          id: this.id
        }
      },
      total: 0,

      orderType: '', // 订单状态
      optionList: [],
      P_ORDER_STATUS,
      ORDER_STATUS,
    };
  },
  created() {
    // console.log(this.formData, '===formData');
    if (![0, 2].includes(this.status)) {
      this.getTableData();
    }
  },
  methods: {
    // 获取列表数据
    getTableData() {
      apiDemageClaimformUnClimFromDetail(this.queryTable).then(res => {
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.backupsList = res.data.records;
          this.total = res.data.total;
          this.arr[0].value = res.extra.claimOrderCount;
          this.arr[1].value = res.extra.totalDeclaredValue;
        }
      });
    },
    // 翻页
    getCurrentTableData(val) {
      this.queryTable.pageNumber = val;
      this.getTableData();
    },
    // 每页显示数量变化
    getSizeTableData(val) {
      this.queryTable.pageNumber = 1;
      this.queryTable.pageSize = val;
      this.getTableData();
    },
    getTableList() {
      // 是否缓存数据
      if (this.orderType) {
        return cloneDeep(this.backupsList);
      }
      return cloneDeep(this.tableData);
    },

    // 计算申报价值汇总且同步到索赔金额
    totalValue() {
      let count = new Big(0);
      if (this.backupsList.length > 0) {
        count = this.backupsList.reduce((sum, item) => sum.plus(item.totalDeclaredValue), new Big(0));
        count = count.round(3, Big.RM).toString();
        // 当backupsList数据大于dataList数据时， 说明数据已更新， 统计汇总更新索赔金额
        if (this.backupsList.length > this.dataList.length) {
          this.$emit('change', count);
        }
        console.log(count, '===count');
        return count;
      } else {
        return this.formData.claimAmount || 0;
      }
    },
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 添加关联订单
    add() {
      if (!this.orderList) {
        return this.$message.warning(this.$t('GlobalSetObj.pleaseInputContent'));
      }

      const arr = this.orderList.split(/[(\r\n)\r\n]+/).map(item => {
        return item.trim();
      }).filter(item => !!item);

      if (arr.length === 0) {
        return this.$message.warning(this.$t('GlobalSetObj.pleaseInputContent'));
      }

      if (arr.length > 500) {
        return this.$message.warning(this.$t('compensationManagement.Maximum500Orders'));
      }
      this.checkOrderNumber(arr);
    },

    // 导入关联订单
    onImportClick(val) {
      // 去重
      const codeArr = new Set([...val.map(item => {
        return item.barCode;
      })]);

      this.checkOrderNumber([...codeArr]);
    },

    /**
     * 校验订单号
     * @param arr 单号数据[]
     */
    checkOrderNumber(arr) {
      // if (arr.length > 500) {
      //   return this.$message.warning(`${this.$t('userManage.upTo500ID')}`);
      // }

      const { claimsNode, claimsType } = this.formData;

      // 业务类型、索赔类型、关联单号
      const obj = {
        claimsNode,
        // customerId,
        claimsType,
        orderNumbers: arr
      };
      this.loading = true;
      apiDemageClaimformOrderDetail(obj, false).then(res => {
        try {
          if (res.code === 200) {
            this.onClose();

            const data = res.data;
            // 1.成功
            // 1.1判断是否已添加---添加过滤
            // 1.2未添加---新增
            if (data?.claimRelatedOrdersVOList && data?.claimRelatedOrdersVOList.length > 0) {
              const tableList = this.backupsList.length > 0 ? cloneDeep(this.backupsList) : cloneDeep(this.tableData);
              this.tableData = union(tableList, data?.claimRelatedOrdersVOList, 'barCode') || [];

              // 备份数据
              this.backupsList = cloneDeep(this.tableData);
            }

            this.searchOrderType();

            // 失败单号
            if (data?.outNumber && data?.outNumber.length > 0) {
              if (data?.outNumber.length > 10) {
                const list = data?.outNumber.map(item => {
                  return {
                    code: item
                  };
                });

                this.showNotification(list);

                // const h = this.$createElement;
                // this.$notify.error({
                //   title: this.$t('GlobalSetObj.tips'),
                //   dangerouslyUseHTMLString: true,
                //   message: h('el-table', {props:{data:list,border:true}, style:"width: 100%; overflow-y: auto;"}, 
                //     [
                //       h('el-table-column', {props:{prop: "code", label: this.$t('compensationManagement.WrongTrackingNumber')}})
                //     ]
                //   ),
                //   duration: 0
                // });
              } else {
                const arr = data?.outNumber.join('、');
                this.$message({
                  message: `${arr} ${this.$t('operationCenter.TheOrderWasNotQueried')}`,
                  type: 'warning',
                  duration: 5 * 1000,
                  showClose: true
                });
              }
            }
            // 未查询到订单
            if (!data?.claimRelatedOrdersVOList && !data?.outNumber) {
              this.$message.warning(`${this.$t('operationCenter.TheOrderWasNotQueried')}`);
              return [];
            }
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      }).catch(err => {
        console.error(err);
        this.loading = false;
      });
    },

    // 消息通知组件
    showNotification(list) {
      // 关闭之前的通知
      this.$notify.closeAll();

      // 显示新的通知
      this.$notify({
        title: this.$t('compensationManagement.WrongTrackingNumber'),
        message: list.map(item => item.code).join('\n'),
        type: 'error',
        duration: 0, 
      });
    },

    // 关闭弹窗
    onClose() {
      this.isAdd = false;
      this.isImport = false;
      this.orderList = '';
    },

    // 处理删除请求
    onRemove() {
      // id存在编辑状态 不存在新增状态
      if (this.id) {
        const ids = this.multipleSelection.map(item => item.id);
        const code = this.multipleSelection.map(item => item.barCode);

        // 判断订单数据是否已保存， 未保存本地删除， 已保存删除远程
        const flag = ids.every(item => !item);
        if (flag) {
          // 本地删除
          this.filterTableData();
          return;
        }

        apiDemageClaimFormRemoveBill({ 
            claimBillIds: ids,
            claimsStatus: this.status,
            id: this.id,
            barCodes: code
        }).then(res => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: this.$t('GlobalSetObj.TheOperationSucceeded'),
            });
          }
        }).catch(err => {});
      }
      this.filterTableData();
    },
    filterTableData() {
      const addArr = this.multipleSelection.map((item) => {
        return item.barCode;
      });

      const list = this.backupsList.filter(item => {
        return !addArr.includes(item.barCode);
      });

      if (![0, 2].includes(this.status)) {
        this.getTableData();
        return;
      }
      this.tableData = list;
      this.backupsList = cloneDeep(list);
    },

    // 导出关联订单
    onExport() {
      // 未勾选默认导出所有
      // 已勾选导出勾选
      const isChecked = this.multipleSelection.length > 0 ? this.multipleSelection : [];
      const idArr = isChecked.map(item => {
        return item.id;
      });

      const obj = {
        id: this.id,
        status: this.orderType,
        ids: idArr
      };

      apiDemageClaimformExport(obj).then(res => {
        if (res.status === 'OK') {
          // 关闭弹窗
          this.$emit('close');
          goExportList(this);
        }
      }).catch(err => {
        console.error(err);
      });
    },

    /**
     * 清空关联订单
     * 新增状态切换供应商or业务类型重置
     * 清空备份数据
     * 清空订单状态
     */
    cleraTable() {
      this.tableData = [];
      this.backupsList = [];
      this.orderType = '';
    },

    // 订单状态过滤
    searchOrderType() {
      if ([0, 2].includes(this.status)) {
        // 新增
        if (!this.backupsList.length) { // 

        } else if (!this.orderType) { 
          this.tableData = this.backupsList;
        } else {
          this.tableData = this.backupsList.filter(item => {
            return item.status === this.orderType;
          });
        }
      } else {
        this.queryTable.params.status = this.orderType;
        this.getTableData();
      }
    },
  }
};

// 订单去重方法
const union = function(a, b, k) {
  return a.concat(b.filter(i => (k ? !a.map(i => i[k]).includes(i[k]) : !a.includes(i))));
};
</script>

<style scoped>
.el-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 10px;
}
</style>
<style>
.el-notification {
  overflow-y: scroll;
  height: calc(100% - 30px);
}
.el-notification__group {
  width: 100% !important;
}
</style>
