var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DropdownTable",
        [
          _c(
            "o-dropdown-item",
            {
              attrs: { "aria-role": "listitem" },
              on: { click: _vm.handleOpenModalCreate },
            },
            [_vm._v(" " + _vm._s(_vm.$t("seller.create")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "o-modal",
        {
          attrs: { active: _vm.isOpenModalCreate, width: "560" },
          on: { close: _vm.handleVisibleModalCreate },
        },
        [
          _c("div", [
            _c("h2", { staticClass: "header-2" }, [
              _vm._v(_vm._s(_vm.$t("seller.createTitle"))),
            ]),
            _c("p", { staticClass: "paragraph" }, [
              _vm._v(_vm._s(_vm.$t("seller.createParagraph"))),
            ]),
          ]),
          _c("FormCreateSeller", {
            attrs: {
              seller: _vm.sellerData,
              "close-modal": _vm.handleVisibleModalCreate,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }