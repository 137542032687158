<script >
import { ref, defineProps, onMounted } from '@vue/composition-api';
import { useVuelidate } from '@vuelidate/core';
import { default as FormAdditionalData } from '../../../../vehicle/components/form/steps/formAdditionalData';
import { default as FormMeasurementData } from '../../../../vehicle/components/form/steps/formMeasurementData';
import { default as FormVehicleData } from '../../../../vehicle/components/form/steps/formVehicleData';
import { FormActions, FormActionsNext, FormActionsPrevious } from '@/components/wizard-form/formStepActions';
import { vehicleService } from '@/api/transport/vehicle';
import getMessageError from '@/utils/getMessageError';
import { required } from '@/utils/validationErrors';
import { toast } from '@/utils/programaticToast';
import { getToastContentComponent } from '@/utils/getToastContentComponent';
const __sfc_main = {};
__sfc_main.props = {
  formData: {
    type: Object
  },
  handleNext: {
    type: Function
  },
  isLastStep: {
    type: Boolean
  },
  isFirstStep: {
    type: Boolean
  },
  handlePrevious: {
    type: Function
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const rules = {
    plate: {
      required
    }
  };
  const v$ = useVuelidate(rules, props.formData);
  const activeTabsVehicle = ref(0);

  /* buscando veículo*/
  const listVehicles = ref([]);
  const vehicleData = ref({
    document: null,
    capacityM3: null,
    capacityKG: null
  });
  async function filteredDataVehicles() {
    try {
      const params = {
        skip: 0,
        plate: props.formData.plate
      };
      const res = await vehicleService.getAll(params);
      listVehicles.value = res.data.result;
    } catch (error) {
      listVehicles.value = [];
    }
  }
  function handleSelectedVehicle(selectedValue) {
    vehicleData.value = {
      document: selectedValue ? selectedValue.vehicle_documentation : '',
      capacityM3: selectedValue ? selectedValue.vehicle_capacity_m3 : '',
      capacityKG: selectedValue ? selectedValue.vehicle_capacity_kg : ''
    };
  }
  onMounted(async () => {
    try {
      if (props.formData.plate) {
        const params = {
          skip: 0,
          plate: props.formData.plate
        };
        const res = await vehicleService.getAll(params);
        vehicleData.value = {
          document: res.data.result[0].vehicle_documentation,
          capacityM3: res.data.result[0].vehicle_capacity_m3,
          capacityKG: res.data.result[0].vehicle_capacity_kg
        };
      }
    } catch (error) {
      // eslint-disable-next-line require-atomic-updates
      props.formData.plate = '';
      vehicleData.value = {
        document: null,
        capacityM3: null,
        capacityKG: null
      };
    }
  });

  /* create vehicle */
  function defaultValueFormVehicle() {
    return {
      formStep01: {
        document: null,
        licensePlate: null,
        trailerPlate: null,
        rntrc: null,
        model: null,
        brand: null,
        state: null,
        provider: null,
        traction: 0,
        outsourced: 0
      },
      formStep02: {
        capacityKg: null,
        capacityM3: null,
        wheelsetType: null,
        bodyType: null,
        weight: null
      },
      formStep03: {
        documentOwner: null,
        stateRegistration: null,
        trackerCompany: null,
        trackerCompanyCode: null
      }
    };
  }
  const formDataVehicle = ref(defaultValueFormVehicle());
  const isOpenCollapse = ref(0);
  async function nextCollapseItem() {
    try {
      if (isOpenCollapse.value < 2) {
        isOpenCollapse.value = isOpenCollapse.value + 1;
      } else {
        const newVehicle = {
          ...formDataVehicle.value.formStep01,
          ...formDataVehicle.value.formStep02,
          ...formDataVehicle.value.formStep03
        };
        const res = await vehicleService.create(newVehicle);
        props.formData.plate = res.data.vehicle_license_plate;
        handleSelectedVehicle({
          vehicle_documentation: res.data.vehicle_documentation,
          vehicle_capacity_m3: res.data.vehicle_capacity_m3,
          vehicle_capacity_kg: res.data.vehicle_capacity_kg
        });
        // eslint-disable-next-line require-atomic-updates
        formDataVehicle.value = defaultValueFormVehicle();
        // eslint-disable-next-line require-atomic-updates
        isOpenCollapse.value = 0;
        activeTabsVehicle.value = 0;
      }
    } catch (error) {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Ocorreu um erro',
          describe: getMessageError(error),
          typeIcon: 'error'
        }),
        closable: true,
        duration: 4000
      });
    }
  }
  async function previousCollapseItem() {
    isOpenCollapse.value = isOpenCollapse.value - 1;
  }

  /* control step*/
  async function handleNextStep() {
    const isValid = await v$.value.plate.$validate();
    if (isValid) {
      props.handleNext();
    }
  }
  return {
    props,
    v$,
    activeTabsVehicle,
    listVehicles,
    vehicleData,
    filteredDataVehicles,
    handleSelectedVehicle,
    formDataVehicle,
    isOpenCollapse,
    nextCollapseItem,
    previousCollapseItem,
    handleNextStep
  };
};
__sfc_main.components = Object.assign({
  FormActions,
  FormActionsPrevious,
  FormActionsNext,
  FormVehicleData,
  FormMeasurementData,
  FormAdditionalData
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <o-tabs v-model="activeTabsVehicle" :multiline="false">
      <o-tab-item :value="0" :label="$t('order.searchVehicle')">
        <form class="wrapper-form-search-vehicle" @submit.prevent="handleNextStep">
          <o-field
            :label="$t('order.vehiclePlate')"
            class="col-span-2"
            :message="v$.plate.$errors.length ? v$.plate.$errors[0].$message : null"
          >
            <o-autocomplete
              v-model="formData.plate"
              rounded
              expanded
              :data="listVehicles"
              :placeholder="$t('order.vehiclePlatePlaceholder')"
              clearable
              field="vehicle_license_plate"
              @input="filteredDataVehicles"
              @select="handleSelectedVehicle"
            >
              <template slot="empty">No results found</template>
            </o-autocomplete>
          </o-field>

          <o-field :label="$t('order.document')" class="col-span-2">
            <o-input v-model="vehicleData.document" disabled />
          </o-field>

          <o-field :label="$t('order.capacityM3')">
            <o-input v-model="vehicleData.capacityM3" disabled />
          </o-field>

          <o-field :label="$t('order.capacityKg')">
            <o-input v-model="vehicleData.capacityKG" disabled />
          </o-field>

          <FormActions>
            <FormActionsPrevious
              :is-first-step="isFirstStep"
              @handlePrevious="handlePrevious"
            />

            <FormActionsNext
              :is-last-step="isLastStep"
            />
          </FormActions>
        </form>
      </o-tab-item>

      <o-tab-item :value="1" :label="$t('order.createVehicle')" class="wrapper-tab">
        <o-collapse
          :open="isOpenCollapse == 0"
          class="content-separator"
          @open="isOpenCollapse = 0"
        >
          <template #trigger="props">
            <div
              role="button"
              aria-controls="contentIdForA11y5-formDataVehicle"
              :aria-expanded="isOpenCollapse"
              class="wrapper-tab-item"
            >
              <p class="step-name">
                {{ $t('order.vehicleData') }}
              </p>
              <div>
                <o-icon :icon="props.open ? 'caret-up' : 'caret-down'" />
              </div>
            </div>
          </template>
          <div class="wrapper-form-step">
            <FormVehicleData
              :handle-next="nextCollapseItem"
              :handle-previous="previousCollapseItem"
              :is-last-step="false"
              :is-first-step="true"
              :form-data.sync="formDataVehicle.formStep01"
            />
          </div>
        </o-collapse>

        <o-collapse
          :open="isOpenCollapse == 1"
          class="content-separator"
          @open="isOpenCollapse = 1"
        >
          <template #trigger="props">
            <div
              role="button"
              aria-controls="contentIdForA11y5-formMeasurementVehicle"
              :aria-expanded="isOpenCollapse"
              class="wrapper-tab-item"
            >
              <p class="step-name">
                {{ $t('order.measurementData') }}
              </p>
              <div>
                <o-icon :icon="props.open ? 'caret-up' : 'caret-down'" />
              </div>
            </div>
          </template>
          <div class="wrapper-form-step">
            <FormMeasurementData
              :handle-next="nextCollapseItem"
              :handle-previous="previousCollapseItem"
              :is-last-step="false"
              :is-first-step="false"
              :form-data.sync="formDataVehicle.formStep02"
            />
          </div>
        </o-collapse>

        <o-collapse
          :open="isOpenCollapse == 2"
          class="content-separator"
          @open="isOpenCollapse = 2"
        >
          <template #trigger="props">
            <div
              role="button"
              aria-controls="contentIdForA11y5-formDataVehicle"
              :aria-expanded="isOpenCollapse"
              class="wrapper-tab-item"
            >
              <p class="step-name">
                {{ $t('order.additionalData') }}
              </p>
              <div>
                <o-icon :icon="props.open ? 'caret-up' : 'caret-down'" />
              </div>
            </div>
          </template>
          <div class="wrapper-form-step">
            <FormAdditionalData
              :handle-next="nextCollapseItem"
              :handle-previous="previousCollapseItem"
              :is-last-step="true"
              :is-first-step="false"
              :form-data.sync="formDataVehicle.formStep03"
            />
          </div>
        </o-collapse>

      </o-tab-item>
    </o-tabs>
  </div>
</template>

<style scoped>
  .wrapper-tab {
    margin-bottom: 2.25rem/* 36px */;
    display: grid;
    gap: 1.25rem/* 20px */;
  }

  .wrapper-form-search-vehicle {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.5rem/* 24px */;
  }

  .col-span-2 {
    grid-column: span 2 / span 2;
  }

  .wrapper-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem/* 32px */;
  }

  .content-separator {
    border-bottom-width: 1px;
    padding-bottom: 1rem/* 16px */;
  }

  .wrapper-tab-item {
    display: flex;
    justify-content: space-between;
  }

  .step-name {
    font-weight: 700;
    font-size: 0.875rem/* 14px */;
    line-height: 1.25rem/* 20px */;
  }

  .wrapper-form-step {
    height: 20rem/* 320px */;
    overflow-y: scroll;
    padding-left: 0.5rem/* 8px */;
    padding-right: 0.5rem/* 8px */;
  }

</style>
