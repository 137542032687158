<template>
  <div class="signForBox">
    <!-- 签收 -->
    <div>
      <div class="scanClass">
        <!-- 签收扫描： -->
        <span>{{ $t('operatingPlatform.signForScan') }}</span>
        <el-input
          v-model.trim="scanForm.signForScan"
          :placeholder="$t('operatingPlatform.PleaseScan')"
          prefix-icon="el-icon-full-screen"
          clearable
          class="iconBtn"
          @blur="getrecipient"
          @keyup.enter.native="getTable"
        />
      </div>
      <div class="scanClass">
        <!-- 收件类型：-->
        <span class="requiredClass">{{ $t('operatingPlatform.receivingType') }}</span>
        <div class="box">
          <el-select v-model="scanForm.signType" :placeholder="$t('collectionCenter.pleaseChoose')" filterable @change="changeSignType">
            <el-option :label="$t('operatingPlatform.inPerson')" :value="0" /><!-- 本人 -->
            <el-option :label="$t('operatingPlatform.notOneself')" :value="1" /><!-- 非本人 -->
          </el-select>
        </div>
      </div>
      <div class="scanClass">
        <!-- 收件人：-->
        <span class="requiredClass">{{ $t('operatingPlatform.recipient') }}</span>
        <div class="box">
          <el-input
            v-model="recipient"
            :disabled="scanForm.signType == 0"
            :placeholder="$t('collectionCenter.pleaseInput')"
            clearable
            @keyup.enter.native="getTable"
            @input="changeMessage"
            @change="changeMessage"
          />
        </div>
      </div>
      <div class="scanClass">
        <!-- 文件：-->
        <span>{{ $t('operatingPlatform.file') }}</span>
        <div class="box fileClass">
          <el-input
            v-model="scanForm.signFile"
            :placeholder="$t('operatingPlatform.pleaseInput')"
            clearable
            maxlength="255"
            show-word-limit
            @keyup.enter.native="getTable"
          />
        </div>
      </div>
    </div>
    <div class="uploadBtn">
      <!-- <el-button
        @click="upload"
      >{{ $t('operatingPlatform.Upload') }}</el-button>上传 -->
      <el-button
        @click="signature"
      >{{ $t('operatingPlatform.signature') }}</el-button><!-- 签名 -->
      <div class="uploadClass">
        <el-upload
          ref="resourcesUp"
          action
          :before-upload="beforeUpload"
          :http-request="imgUpload"
          :on-remove="handleRemove"
          multiple
          :file-list="fileList"
          :show-file-list="false"
        >
          <!-- 附件 -->
          <el-button slot="trigger" type="primary" style="margin-right: 5px">{{ $t('collectionCenter.enclosureFJ') }}</el-button>
        </el-upload>
      </div>

    </div>

    <div class="tableClass">
      <el-table
        :max-height="600"
        :data="scanTableData"
        border
      >
        <el-table-column
          prop="wayBillNumber"
          align="center"
          :label="$t('operatingPlatform.waybillNumber')"
        /><!-- 运单号 -->
        <el-table-column
          prop="signType"
          align="center"
          :label="$t('operatingPlatform.receivingT')"
        /><!-- 收件类型 -->
        <el-table-column
          prop="signUserName"
          align="center"
          :label="$t('operatingPlatform.addressee')"
        /><!-- 收件人 -->
        <el-table-column
          prop="scanTime"
          align="center"
          :label="$t('operatingPlatform.scanTime')"
        /><!-- 扫描时间 -->
        <el-table-column
          :label="$t('operatingPlatform.operation')"
          fixed="right"
          width="100"
          align="center"
        ><!-- 操作 -->
          <template slot-scope="scope">
            <el-popconfirm
              :title="$t('operatingPlatform.deleteSure')"
              @confirm="scanDelClick(scope.row, scope.$index)"
            ><!-- 确定删除吗？ -->
              <el-button slot="reference" type="danger" size="mini">{{ $t('operatingPlatform.Delete') }}</el-button><!-- 删除 -->
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-if="fileList.length > 0" style="margin-top: 20px;">
      <!-- 附件： -->{{ $t('collectionCenter.enclosure') }}
      <span v-for="(item, index) in fileList" :key="index" class="imgClass">
        <img :src="item.url" alt="">
        <el-button class="imgDelClass" size="mini" type="danger" icon="el-icon-delete" circle @click="delImgFile(item)" />
      </span>
    </div>

    <!-- 签名 -->
    <div v-if="signVisible" class="signMask">
      <div class="signVisibleClass">
        <div class="el-icon-close signClose" @click="closeSign" />
        <div class="descriptionsClass">
          <div ref="image">
            <el-descriptions title="" :column="1" border :content-style="rowClass" :label-style="labelRowClass">
              <!--
              增加或减少<el-descriptions-item>，需同步更新样式 .descriptionsClass ::v-deep tbody:nth-child(7 / 8 / 9 / 10) td
              -->
              <el-descriptions-item label="">
                <div class="topTitle">{{ $t('operatingPlatform.salesmanInfo') }}</div><!-- 业务员信息 -->
              </el-descriptions-item>
              <el-descriptions-item label="">
                <!-- 派送人： -->
                <span>{{ $t('operatingPlatform.deliverer') }}</span><span>{{ scanEsignForm.deliverer }}</span>
              </el-descriptions-item>
              <el-descriptions-item label="">
                <!-- 所属网点： -->
                <span>{{ $t('operatingPlatform.belongToSite') }}</span><span>{{ scanEsignForm.dot }}</span>
              </el-descriptions-item>

              <el-descriptions-item label="">
                <div class="topTitle">{{ $t('operatingPlatform.receivingInfo') }}</div><!-- 收件信息 -->
              </el-descriptions-item>
              <!--              <el-descriptions-item label="">-->
              <!--                &lt;!&ndash; 运单号： &ndash;&gt;-->
              <!--                <span>{{ $t('operatingPlatform.waybillNum') }}</span><span>{{ scanEsignForm.waybillNumber }}</span>-->
              <!--              </el-descriptions-item>-->
              <el-descriptions-item label="">
                <!-- 收件类型： -->
                <span>{{ $t('operatingPlatform.receivingType') }}</span><span>{{ scanEsignForm.signType }}</span>
              </el-descriptions-item>
              <el-descriptions-item label="">
                <!-- 收件人： -->
                <span>{{ $t('operatingPlatform.recipient') }}</span><span>{{ scanEsignForm.signUser }}</span>
              </el-descriptions-item>
              <el-descriptions-item label="">
                <!-- 签收时间： -->
                <span>{{ $t('operatingPlatform.signTime') }}</span><span>{{ scanEsignForm.brazilTime }}</span>
              </el-descriptions-item>

              <el-descriptions-item label="">
                <div class="topTitle">{{ $t('operatingPlatform.signature') }}</div><!-- 签名 -->
              </el-descriptions-item>
              <el-descriptions-item label="">
                <div style="width: 450px;height: 205px;overflow: hidden;">
                  <vue-esign ref="esign" :width="455" :height="205" :line-width="5" line-color="#000" bg-color="#FFF" />
                </div>
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <div class="flex">
            <el-button class="btn" type="danger" @click="handleReset">{{ $t('operatingPlatform.empty') }}</el-button><!-- 清空 -->
            <el-button :disabled="signImgOk" class="btn onlyClickOnce" type="success" @click="handleGenerate">{{ $t('operatingPlatform.confirm') }}</el-button><!-- 确认 -->
          </div>
        </div>
      </div>
    </div>

    <!-- 隐藏提示音 -->
    <div v-show="false">
      <audio ref="errRef" :src="errorMp3" controls="controls" />
      <audio ref="okRef" :src="okMp3" controls="controls" />
    </div>
  </div>
</template>

<script>
const okMp3 = '/static/scan_success.mp3';
const errorMp3 = '/static/scan_fail.mp3';
import { pcScanWaybillNumber, pcSignerSubmit, deleteScanRecord, getAddresseeName, pcQueryScanRecord } from '@/api/operatingPlatform';
import { uploadOos } from '@/utils/ali_oos_util';
import { getFormatServerTime } from '@/api/lanshou';
import html2canvas from 'html2canvas';
import { getTimes } from '@/utils/watermark/watermark_time';
export default {
  name: 'SignFor',
  data() {
    return {
      okMp3,
      errorMp3,
      photographList: [],
      fileList: [],
      scanForm: {
        signForScan: '',
        signType: 0, // '本人'
        signUserName: '',
        signFile: ''
      },
      recipient: '', // 收件人——选择本人时赋值
      scanTableData: [],
      signVisible: false, // 签名窗口
      signImg: '', // 签名图
      scanEsignForm: { // 截图用
        deliverer: '', // 派送人
        dot: '', // 所属网点
        waybillNumber: '', // 运单号
        signType: 0, // 收件类型
        signUser: '', // 收件人
        brazilTime: '' // 巴西时间
      },
      rowClass: {
        'border-color': '#000'
      },
      labelRowClass: {
        'border-color': '#000'
      },
      signImgOk: false
    };
  },
  watch: {
    'scanForm.signForScan'() {
      if (!this.scanForm.signForScan) {
        this.recipient = '';
      }
    }
  },
  created() {
    this.queryScanRecord();
  },
  // 获取截图缩放比
  methods: {
    beforeUpload(file) {
      if (this.fileList.length >= 3) {
        // 最多上传3张照片
        this.$message.error(this.$t('collectionCenter.upload3Photos'));
        return false;
      }
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1);
      const whiteList = ['png', 'jpg', 'bmp', 'jpeg'];
      if (whiteList.indexOf(fileSuffix) === -1) {
        // 仅支持png、bmp、jpg格式的图片
        this.$message.error(this.$t('collectionCenter.OnlyImageFormatIsSupported'));
        return false;
      }
      return new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file); // file转base64
        reader.onload = e => {
          const canvas = document.createElement('canvas');
          const img = new Image();
          img.src = e.target.result;
          img.onload = () => {
            // 图片加载后再添加水印，否则可能报错
            const width = img.width;
            const height = img.height;
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d'); // 绘制2d图形
            ctx.drawImage(img, 0, 0, width, height);
            var basePx = canvas.width;
            // 字体大小  照片添加水印
            var fontSize = basePx / 28; // 水印文字尺寸
            ctx.font = fontSize + 'px Open Sans';
            ctx.fillStyle = 'rgba(255,255,255, 0.5)';
            ctx.shadowOffsetY = 4;
            ctx.shadowBlur = 2;
            ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
            let time = getTimes();
            time = time.split(' ');
            const fontHeight = ctx.measureText(time[0]).fontBoundingBoxAscent + ctx.measureText(time[0]).fontBoundingBoxDescent;
            ctx.fillText(time[0], width - ctx.measureText(time[0]).width - 20, fontHeight);
            ctx.fillText(time[1], width - ctx.measureText(time[1]).width - 20, fontHeight * 2);
            var dataBase64 = canvas.toDataURL(file.type); // 输出压缩后的base64
            // base64转file
            const arr = dataBase64.split(',');
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
            }
            const files = new File(
              [new Blob([u8arr], { type: mime })],
              file.name,
              { type: file.type }
            );
            files.uid = file.uid;
            resolve(files);
          };
        };
      });
    },
    async imgUpload(option) {
      const files = option.file;
      const res = await uploadOos(files, 'collectionCenter');
      if (res.code === 200) {
        const obj = {
          name: files.name,
          url: res.url
        };
        this.fileList = [...this.fileList, obj];
        this.photographList.push(res.url);
        // 上传多个时，截取前三个
        if (this.fileList.length > 3) {
          this.fileList = this.fileList.slice(0, 3);
          this.photographList = this.photographList.slice(0, 3);
        }
      } else {
        this.$message.error(this.$t('placeOrder.uploadInvoiceErr'), res.msg);
      }
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
      this.photographList = [];
      fileList.forEach(arr => {
        this.photographList.push(arr.url);
      });
    },
    delImgFile(item) {
      const file = this.fileList.filter((img) => {
        return img.url !== item.url;
      });
      this.fileList = file;
      this.photographList = [];
      file.forEach(arr => {
        this.photographList.push(arr.url);
      });
    },

    changeMessage() {
      this.$forceUpdate();
    },
    changeSignType(val) {
      this.$forceUpdate();
      if (this.scanForm.signType === 0) {
        // 0 本人
        this.getrecipient();
      } else {
        this.recipient = '';
      }
    },
    getrecipient() {
      if (!this.scanForm.signForScan) {
        // this.$message({
        //   message: this.$t('operatingPlatform.pleaseScanNumber'), // '请先扫描单号'
        //   type: 'warning'
        // });
        return;
      }
      const scanName = {
        scanNumber: this.scanForm.signForScan
      };
      getAddresseeName(scanName).then(res => {
        if (res.status === 'OK' && res.data) {
          this.recipient = res.data;
          if (this.scanForm.signType !== 0) {
            this.recipient = '';
          }
          // this.$set(this.recipient, 'recipient', res.data);
          // if (this.scanForm.signType !== 0) {
          //   this.$set(this.recipient, 'recipient', '');
          // }
          // console.log(this.recipient, 'this.recipient');
        } else {
          this.recipient = '';
        }
      }).catch({});
    },
    // 填表成功后 / 进入页面时  调用查询单号扫描记录接口
    queryScanRecord() {
      pcQueryScanRecord().then(res => {
        if (res.status === 'OK') {
          if (res.data.length <= 0) {
            this.scanTableData = [];
            return;
          }
          let scanTableData = res.data.map(arr => {
            const signType = arr.signType === 0 ? this.$t('operatingPlatform.inPerson') : this.$t('operatingPlatform.notOneself');
            return {
              ...arr,
              signType
            };
          });
          scanTableData = Array.from(scanTableData);
          this.scanTableData = scanTableData.reverse();
        }
      }).catch({});
    },
    getTable() {
      if (this.scanForm.signType === '' || this.recipient === '') {
        this.$message({
          message: this.$t('operatingPlatform.PleaseFillInTheRequiredFields'), // '请填写必填项'
          type: 'warning'
        });
        this.$refs.errRef.play();
        return;
      }
      if (this.scanForm.signForScan) {
        // 查询有无重复值
        const isAdd = this.scanTableData.some(el => el.wayBillNumber === this.scanForm.signForScan);
        if (isAdd && this.scanTableData.length > 0) {
          this.$message({
            message: this.$t('collectionCenter.waybillNumRepeat'), // '当前运单号重复'
            type: 'warning'
          });
          // this.scanForm = {};
          // this.scanForm.signType = 0;
          this.$refs.errRef.play();
        } else {
          const scanNumber = {
            scanWaybillNumber: this.scanForm.signForScan,
            operationType: 5,
            type: this.scanForm.signType,
            receiveName: this.recipient,
            signFile: this.scanForm.signFile
          };
          pcScanWaybillNumber(scanNumber).then(res => {
            if (res.status === 'OK') {
              setTimeout(() => {
                this.queryScanRecord();
              }, 100);
              // this.scanForm = {};
              // this.scanForm.signType = 0;
              this.$refs.okRef.play();
              this.scanForm.signForScan = '';
            } else {
              this.scanForm.signForScan = '';
              // this.scanForm = {};
              // this.scanForm.signType = 0;
              this.$refs.errRef.play();
              this.$message.error(res.msg);
            }
          }).catch(() => {
            // this.scanForm = {};
            // this.scanForm.signType = 0;
            this.scanForm.signForScan = '';
            this.$refs.errRef.play();
          });
        }
      }
    },
    scanDelClick(row, index) { // 扫件——删除
      const delForm = {
        id: row.scanRecordId,
        collectScanRecordId: row.id
      };
      deleteScanRecord(delForm).then(res => {
        if (res.status === 'OK') {
          this.scanTableData.splice(index, 1);
          if (this.scanTableData.length <= 0 || row.wayBillNumber == this.scanForm.signForScan) {
            this.scanForm.signForScan = '';
          }
        }
      }).catch({});
    },
    upload() { // 上传
      if (!this.signImg) {
        this.$message({
          message: this.$t('operatingPlatform.unsigned'), // '未签名!'
          type: 'warning'
        });
      } else {
        const scanTableData = this.scanTableData.map(arr => {
          const signerImg = this.signImg;
          const signType = arr.signType === this.$t('operatingPlatform.inPerson') ? 0 : 1;
          const signUserName = arr.signUserName;
          const signFile = arr.signFile;
          const scanTime = arr.scanTime;
          const scanWaybillNumber = arr.wayBillNumber;
          const waybillId = arr.waybillId;
          const photograph = this.photographList;
          const weight = '';
          return {
            weight,
            signType,
            signUserName,
            signFile,
            scanTime,
            scanWaybillNumber,
            waybillId,
            signerImg,
            photograph
          };
        });
        const signForm = {
          photograph: '',
          orderSource: '1',
          signerImg: '',
          warbillList: scanTableData
        };
        pcSignerSubmit(signForm).then(res => {
          if (res.status === 'OK') {
            this.scanForm = {};
            this.scanForm.signType = 0;
            this.scanForm.signForScan = '';
            this.scanTableData = [];
            this.signImg = '';
            this.recipient = '';
            this.scanEsignForm = {};
            this.photographList = [];
            this.fileList = [];
            this.signVisible = false;
            this.$message.success(res.data);
          } else {
            this.signVisible = false;
            this.$message.error(res.msg);
          }
        }).catch(err => {
          console.error(err);
          this.signVisible = false;
        });
      }
    },

    getBrazilTime() {
      getFormatServerTime().then(res => {
        if (res.status === 'OK' && res.data) {
          const bxTime = res.data.split(' ');
          const bxt1 = bxTime[0].split('-');
          this.scanEsignForm.brazilTime = bxTime[1] + ' ' + bxt1[0] + '-' + bxt1[1] + '-' + bxt1[2];
        }
      }).catch({});
    },
    signature() {
      if (this.scanTableData.length <= 0) {
        this.$message({
          message: this.$t('operatingPlatform.nullSubmit'), // '无数据提交'
          type: 'warning'
        });
        this.scanEsignForm = {};
      } else {
        /**
         * 截屏需要用的展示数据（此时不包含签名图片）
        */
        this.getBrazilTime();
        const signUser = this.scanTableData[0].signUserName;
        const signType = this.scanTableData[0].signType;
        this.scanEsignForm = {
          deliverer: this.$store.state.user.realName, // 派送人
          dot: this.$store.state.user.dotName, // 所属网点
          waybillNumber: '', // 运单号
          signType, // 收件类型
          signUser, // 收件人
          brazilTime: this.scanEsignForm.brazilTime // 巴西时间
        };
        this.signImg = '';
        this.signVisible = true;
      }
    },
    closeSign() {
      this.signImg = '';
      this.scanEsignForm = {};
      this.scanEsignForm.signType = this.scanTableData[0].signType;
      this.signVisible = false;
    },
    handleReset() {
      this.$refs['esign'].reset(); // 清空画布
    },
    handleGenerate() {
      // // 查看截图样式
      // html2canvas(this.$refs.image).then(res => {
      //   const imgUrl = res.toDataURL('image/png');
      //   // console.log('图片临时地址',imgUrl)
      //   const aLink = document.createElement('a');
      //   aLink.href = imgUrl;
      //   aLink.download = new Date().toLocaleString() + '.png';	// 导出文件名，这里以时间命名
      //   document.body.appendChild(aLink);
      //   // 模拟a标签点击事件
      //   aLink.click();
      // });
      // return;

      this.$refs['esign'].generate().then(res => {
        this.isLoading = true;
        this.signImgOk = true;
        this.canvasImg();
      }).catch((err) => {
        console.log(err);
        this.$message({
          message: this.$t('operatingPlatform.unsigned'), // '未签名!'
          type: 'warning'
        });
      });
    },
    canvasImg() {
      const canvas = document.createElement('canvas'); // 截图区
      const canvasBox = this.$refs.image; // 父级
      const width = parseInt(window.getComputedStyle(canvasBox).width);
      const height = parseInt(window.getComputedStyle(canvasBox).height);
      const size = window.devicePixelRatio;
      canvas.width = width * 2 * size;
      canvas.height = height * 2 * size;
      canvas.style.width = width + 'px';
      canvas.style.height = height + 'px';
      const context = canvas.getContext('2d');
      context.scale(2, 2);
      const options = {
        backgroundColor: null,
        canvas: canvas,
        useCORS: true
      };
      html2canvas(canvasBox, options).then((canvas) => {
        // toDataURL 图片格式转成 base64
        const dataURL = canvas.toDataURL('image/png');
        const rSignImg = this.base64ImgtoFile(dataURL, 'file'); // 得到文件格式
        uploadOos(rSignImg, 'operatingPlatform').then(res => {
          if (res.code === 200) {
            if (res.url) {
              this.signImg = res.url;
              this.upload();
            } else {
              this.signImg = '';
            }
            this.signImgOk = false;
          }
        }).catch(() => {
          this.signImgOk = false;
        });
      });
    },
    base64ImgtoFile(dataurl, filename = 'file') {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const suffix = mime.split('/')[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
      });
    }
  }
};
</script>

<style lang="scss" scoped>

  .imgClass{
    margin-left: 10px;
    height: auto;
    width: auto;
    display: inline-block;
    position: relative;
    img{
      max-width: 140px;
      max-height: 140px;
    }
    .imgDelClass{
      position: absolute;
      right: 0;
      bottom: 7px;
    }
  }

  // 防止按钮重复点击
  @keyframes throttle{
    from{
      filter: opacity(40%);
      pointer-events: none;
    }
    to{
      pointer-events: all;
    }
  }
  .onlyClickOnce{
    animation: throttle 2s step-end forwards;
  }
  .onlyClickOnce:active{
    filter: opacity(40%);
    animation: none;
  }
  .fileClass ::v-deep .el-input__suffix{
    height: auto;
    bottom: 0 !important;
    top: auto;
    right: 0;
  }
  .fileClass ::v-deep .el-input__count{
    height: auto;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .fileClass ::v-deep .el-input .el-input__count .el-input__count-inner{
    background: transparent;
  }

  .signMask{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.4);
    z-index: 99;
    .signVisibleClass{
      position: absolute;
      bottom:0;
      left:0;
      width:100%;
      height:100%;
      background:#fff;
      padding:20px 0;
      // overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .signClose{
      position: absolute;
      top: 25px;
      right: 40px;
      transform: scale(1.3);
    }
  }
  .descriptionsClass {
    position: relative;
    width: 454px;
    margin: 50px auto;
    max-height: 700px;
    .topTitle{
      background: #abacac;
      text-align: center;
      font-size: 17px;
      font-weight: 500;
      color: #000;
      height: 40px;
      line-height: 40px;
    }
    .flex{
      position: absolute;
      left: 460px;
      bottom: 130px;
    }
  }
  .flex ::v-deep .el-button{
    margin: 0;
    margin-bottom: 10px;
    display: inline-block;
  }
  // 标题栏去除内边距，单独写样式
  .descriptionsClass ::v-deep tbody:nth-child(1) td,
  .descriptionsClass ::v-deep tbody:nth-child(4) td,
  .descriptionsClass ::v-deep tbody:nth-child(8) td,
  .descriptionsClass ::v-deep tbody:nth-child(9) td{
    padding: 0 !important;
  }
  .descriptionsClass ::v-deep .el-descriptions {
    border: 2px solid #000;
    box-sizing: border-box;
    font-size: 0;
  }
  .descriptionsClass ::v-deep th{
    padding: 0 !important;
    border: none !important;
    box-sizing: border-box;
    width: 0;
  }
  .descriptionsClass ::v-deep .el-descriptions .is-bordered .el-descriptions-item__cell{
    border-bottom: 1px solid #000;
    border-left: none !important;
    border-right: none !important;
    background: #fff;
    font-size: 19px;
    font-weight: 400;
    color: #000;
  }

  .tableClass{
    margin-top: 10px;
  }
  .signForBox{
    padding: 20px;
    box-sizing: border-box;
  }
  .scanClass{
    margin: 20px auto;
    .box{
      display: inline-block;
    }
    .iconBtn{
      width: 40%;
      position: relative;
    }
    .requiredClass{
      position: relative;
      margin-left: 4px;
    }
  }
  .requiredClass::before{
    content: '*';
    height: 100%;
    position: absolute;
    left: -8px;
    top: 50%;
    transform: translateY(-50%);
    color: #F56C6C;
  }
  .iconBtn::after{
    position: absolute;
    content: "-";
    top: 8px;
    left: 15px;
    transform: scaleX(2.3);
    color: #bbb;
  }
  .uploadBtn{
    margin-top: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .uploadClass{
      margin-left: 20px;
    }
  }

  //.signMask{
  //  position: absolute;
  //  top: 0;
  //  right: 0;
  //  width: 100%;
  //  height: 100%;
  //  overflow: hidden;
  //  background: rgba(0, 0, 0, 0.4);
  //  z-index: 99;
  //  .signVisibleClass{
  //    position: absolute;
  //    bottom:0;
  //    left:0;
  //    width:100%;
  //    height:100%;
  //    background:#fff;
  //    padding:20px 0;
  //    // overflow: hidden;
  //    display: flex;
  //    justify-content: center;
  //    align-items: center;
  //  }
  //  .signClose{
  //    position: absolute;
  //    top: 25px;
  //    right: 40px;
  //    transform: scale(1.3);
  //  }
  //  .page-content{
  //    margin: 0 auto;
  //    padding: 20px;
  //    width: 100%;
  //    height: 100%;
  //    box-sizing: border-box;
  //    display: flex;
  //    justify-content: flex-start;
  //    max-height: 644px;
  //  }
  //  .content {
  //    border: 1px solid #dadada;
  //    box-sizing: border-box;
  //    overflow: hidden;
  //  }
  //  .flex {
  //    margin: 10px;
  //  }
  //}
  //.flex ::v-deep .el-button{
  //  height: 40px;
  //  margin: 20px;
  //  display: inline-block;
  //}
  //.signVisibleClass ::v-deep .el-drawer__header{
  //  margin-bottom: 0 !important;
  //}
  //.signVisibleClass ::v-deep .el-drawer__header>:first-child{
  //  // display: none;
  //  opacity: 0;
  //}
  //.signVisibleClass ::v-deep .el-drawer__open .el-drawer.btt{
  //  height: 50% !important;
  //}
</style>
