import request from '@/utils/request';
import Qs from 'qs';

/**
 * PDA添加同级 / PDA添加下级
 */
export const addPdaPermission = data => {
  return request.post('/permission/add', data);
};

/**
 * 编辑权限——PDA
 */
export const editPdaPermission = data => {
  return request.post('/permission/edit', data);
};

/**
 * 删除权限——PDA
 */
export const delPermission = data => {
  return request.post('/permission/delete?permissionId=' + data);
};

// PC和PDA——权限树
export function getPermissionStructureAll() {
  return request({
    url: '/permission/getPermissionStructure',
    method: 'get'
  });
}
// PC——权限树展示
export function getPermissionStructure() {
  return request({
    url: '/permission/getPermissionStructureByPc',
    method: 'get'
  });
}

// PDA——无id值时(权限管理——pda权限表)
export function collectDriver() {
  return request({
    url: '/collectDriver/getPermissionStructure',
    method: 'get'
  });
}

/**
 * 删除权限
 * @param permissionId
 * @returns {*}
 */
export const removePermission = permissionId => {
  return request.post('/permission/delete', Qs.stringify({ permissionId: permissionId }));
};

/**
 * 添加权限
 * @param data
 * @returns
 */
export const addPermission = data => {
  return request.post('/permission/add', data);
};

/**
 * 编辑权限
 */
export const editPermission = data => {
  return request.post('/permission/edit', data);
};
