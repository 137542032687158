<template> <!-- 收件人信息 -->
  <el-form
    ref="ruleForm"
    class="demo-ruleForm" label-position="top"
  >
    <el-row :gutter="20">
      <el-col :span="8">
        <!-- 姓名 -->
        <el-form-item :label="$t('newOrder.SenderName')">
          <span>{{ ruleForm.senderName }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 公司 -->
        <el-form-item :label="$t('newOrder.SenderCompany')">
          <span>{{ ruleForm.senderCompany }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 手机 -->
        <el-form-item :label="$t('newOrder.senderPhone')">
          <span>{{ ruleForm.senderMobile }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 街道 -->
        <el-form-item :label="$t('driver.modalStreet')">
          <span>{{ ruleForm.senderStreet }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 门牌号 -->
        <el-form-item :label="$t('newOrder.SenderNumber')">
          <span>{{ ruleForm.senderHouseNumber }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 区 -->
        <el-form-item :label="$t('newOrder.SenderDistrict')">
          <span>{{ ruleForm.senderArea }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 城市 -->
        <el-form-item :label="$t('newOrder.SenderCity')">
          <span>{{ ruleForm.senderCity }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 州 -->
        <el-form-item :label="$t('newOrder.SenderState')">
          <span>{{ ruleForm.senderState }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 邮编 -->
        <el-form-item :label="$t('newOrder.SenderZipCode')">
          <span>{{ ruleForm.senderZipcode }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 地址补充 -->
        <el-form-item :label="$t('GlobalSetObj.sendderComplement')">
          <span>{{ ruleForm.senderComplement }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 税号 -->
        <el-form-item :label="$t('newOrder.SenderCPF')">
          <span>{{ ruleForm.senderTax }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 州注册号 -->
        <el-form-item :label="$t('navbar.senderIe')">
          <span>{{ ruleForm.senderIeNumber }}</span>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <!-- 地址 -->
        <el-form-item :label="$t('newOrder.SendingAddress')">
          <span>{{ ruleForm.senderAddress }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 电话 -->
        <el-form-item :label="$t('newOrder.SenderPhone')">
          <span>{{ ruleForm.senderPhone }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 邮箱 -->
        <el-form-item :label="$t('newOrder.SenderEmail')">
          <span>{{ ruleForm.senderMail }}</span>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  name: '',

  props: {
    ruleForm: {
      type: Object,
      required: true
    }
  }

};
</script>

<style scoped>
span {
  color: #666;
}
</style>
