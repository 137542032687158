var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          staticClass: "table-height",
          style: {
            height: _vm.tableHeightRow.height + "px",
          },
          attrs: { type: "border-card" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("collectionCenter.recipientInformation"),
                name: "1",
              },
            },
            [
              _c("ReceiveCmp", {
                ref: "receiveCmpRef",
                attrs: { disabled: !_vm.isEdit },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("collectionCenter.SenderInformation"),
                name: "2",
              },
            },
            [
              _c("SenderCmp", {
                ref: "senderRef",
                attrs: { disabled: !_vm.isEdit },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("orderCenterCont.ProductInformation"),
                name: "3",
              },
            },
            [
              _c("ProductCmp", {
                ref: "productRef",
                attrs: {
                  height: _vm.tableHeightRow.height,
                  disabled: !_vm.isEdit,
                },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("newOrder.InvoiceInformation"),
                name: "4",
              },
            },
            [
              _c("InvoiceCmp", {
                ref: "invoiceRef",
                attrs: { height: _vm.tableHeightRow.height },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "footer" }, [
        _c("span"),
        _c(
          "div",
          [
            _vm.isEdit
              ? _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.submit } },
                  [_vm._v(_vm._s(_vm.$t("customerManagements.EditSave")))]
                )
              : _vm._e(),
            _c("el-button", { on: { click: _vm.cancel } }, [
              _vm._v(_vm._s(_vm.$t("customerManagements.cancel"))),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }