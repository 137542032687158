var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "detailList",
      staticClass: "fontSizeStyle",
      attrs: { id: "detailList" },
    },
    [
      _c(
        "div",
        { ref: "detailListContent", staticClass: "detailListContent" },
        [
          _c("div", { staticClass: "headerList" }, [
            _c(
              "div",
              {
                staticStyle: { "text-align": "left", "padding-right": "20px" },
              },
              [
                _c("img", {
                  staticStyle: { width: "150px" },
                  attrs: { src: require("@/assets/anjunTxt.jpg"), alt: "tu" },
                }),
              ]
            ),
            _c("div", { staticStyle: { "font-size": "45px" } }, [
              _vm._v("IATA:" + _vm._s(_vm.paramData.iata || "- -")),
            ]),
          ]),
          _c("div", { staticClass: "listContent" }, [
            _c("div", [
              _c("div", [_vm._v("ANJUN EXPRESS LOGISTICA E TRANSPORTES LTDA")]),
              _c("div", [_vm._v("CNPJ:11605535000455")]),
              _c("div", [
                _vm._v(
                  "Data de impressã: " +
                    _vm._s(_vm.paramData.createTime || "- -")
                ),
              ]),
            ]),
            _vm._m(0),
          ]),
          _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
            _c(
              "table",
              { staticStyle: { width: "100%", "text-align": "left" } },
              [
                _c("tr", [
                  _c(
                    "td",
                    {
                      staticStyle: { "font-size": "25px" },
                      attrs: { colspan: "2" },
                    },
                    [
                      _vm._v(
                        _vm._s("Destino: " + (_vm.paramData.siteName || "- -"))
                      ),
                    ]
                  ),
                ]),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _vm._v(
                      _vm._s("End: " + (_vm.paramData.siteAddress || "- -"))
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      _vm._s("Bairro: " + (_vm.paramData.siteArea || "- -"))
                    ),
                  ]),
                  _c("td", [
                    _vm._v(
                      _vm._s("Cidade: " + (_vm.paramData.siteCity || "- -"))
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [
                    _vm._v(_vm._s("UF: " + (_vm.paramData.siteState || "- -"))),
                  ]),
                  _c("td", [
                    _vm._v(
                      _vm._s("CEP: " + (_vm.paramData.siteZipCode || "- -"))
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v(_vm._s("ResponsAvel:"))]),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        "Data Geracao Manifesto: " +
                          (_vm.paramData.createTime || "- -")
                      )
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
          _c("div", [
            _c(
              "table",
              { staticStyle: { width: "100%", "text-align": "left" } },
              [
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _vm._v(
                      _vm._s(
                        "Origem: ANJUN EXPRESS LOGISTICA E TRANSPORTES LTDA"
                      )
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _vm._v(
                      _vm._s("End: " + (_vm.paramData.senderAddress || "- -"))
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      _vm._s("Bairro: " + (_vm.paramData.senderArea || "- -"))
                    ),
                  ]),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        "Cidade: " + (_vm.paramData.senderProvince || "- -")
                      )
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      _vm._s("UF: " + (_vm.paramData.senderCity || "- -"))
                    ),
                  ]),
                  _c("td", [
                    _vm._v(
                      _vm._s("CEP: " + (_vm.paramData.senderZipcode || "- -"))
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v(_vm._s("ResponsAvel:"))]),
                  _c("td", [_vm._v(_vm._s("Data Geracao Manifesto:"))]),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "info" }, [_vm._v("Informações da Lista")]),
          _c("div", [
            _c(
              "table",
              { staticStyle: { width: "100%", "text-align": "center" } },
              [
                _vm._m(1),
                _vm._l(
                  _vm.paramData.transitWaybillSubVOList,
                  function (item, index) {
                    return _c("tr", { key: index }, [
                      _c("td", { staticStyle: { "font-size": "20px" } }, [
                        _vm._v(_vm._s(index + 1)),
                      ]),
                      _c("td", { staticStyle: { "font-size": "20px" } }, [
                        _vm._v(_vm._s(item.transitBagNumber || "- -")),
                      ]),
                      _c("td", { staticStyle: { "font-size": "20px" } }, [
                        _vm._v(_vm._s(item.transitBagCount || "- -")),
                      ]),
                      _c("td", { staticStyle: { "font-size": "20px" } }, [
                        _vm._v(_vm._s(item.totalWeight || "- -")),
                      ]),
                      _c("td", { staticStyle: { "font-size": "20px" } }, [
                        _vm._v(_vm._s(item.packageCount || "- -")),
                      ]),
                    ])
                  }
                ),
                _c("tr", { staticStyle: { height: "60px" } }, [
                  _c(
                    "td",
                    {
                      staticStyle: { "font-size": "30px" },
                      attrs: { colspan: "2" },
                    },
                    [_vm._v("Total")]
                  ),
                  _c("td", { staticStyle: { "font-size": "30px" } }, [
                    _vm._v(_vm._s(_vm.paramData.transitBagTotal || "- -")),
                  ]),
                  _c("td", { staticStyle: { "font-size": "30px" } }, [
                    _vm._v(
                      _vm._s(_vm.paramData.transitBagWeightTotal || "- -")
                    ),
                  ]),
                  _c("td", { staticStyle: { "font-size": "30px" } }, [
                    _vm._v(_vm._s(_vm.paramData.waybillNumberTotal || "- -")),
                  ]),
                ]),
              ],
              2
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("canvas", {
        staticStyle: { height: "80px", "min-width": "200px" },
        attrs: { id: "barcode" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "tr",
      {
        staticStyle: {
          background: "#808285",
          height: "50px",
          "font-size": "25px",
        },
      },
      [
        _c("th", { attrs: { colspan: "2" } }, [_vm._v("Seal number")]),
        _c("th", [_vm._v("Qtd")]),
        _c("th", [_vm._v("Kgs")]),
        _c("th", [_vm._v("Pacotes")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }