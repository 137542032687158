<script >
import { computed, ref } from '@vue/composition-api';
import dayjs from 'dayjs';
import { default as Popover, handleVisibilityPopover } from '@/components/popover';
import { default as SkeletonTable } from '@/components/skeletonTable';
import { default as SectionHeader } from '@/components/sectionHeader';
import { default as WrapperHandleError } from '@/components/wrapperHandleError';
import { default as FormCreateVtag } from './form/formCreateMDFE';
import { default as FormFilter } from './form/formFilter';
import { default as Actions } from './actions';
import { useGetAllMDFE } from '@/api/mdfe/query';
import { useModal } from '@/composable/useModal';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const queryParams = ref({
    keyMDFE: null,
    currentPage: 1,
    perPage: 10
  });
  const {
    data,
    isLoading,
    isFetched,
    error,
    isError
  } = useGetAllMDFE(queryParams);
  const tableData = computed(() => {
    return data.value?.data?.result ?? [data.value?.data];
  });
  const tableTotal = computed(() => {
    return data.value?.data?.length ?? 1;
  });
  const isLoadingTable = computed(() => {
    return isLoading.value || !isFetched.value;
  });
  const {
    isOpen,
    handleVisibility
  } = useModal();
  function handleSubmitFilter(filterValue) {
    queryParams.value = {
      ...queryParams.value,
      keyMDFE: filterValue
    };
    handleVisibilityPopover();
  }
  return {
    dayjs,
    queryParams,
    error,
    isError,
    tableData,
    tableTotal,
    isLoadingTable,
    isOpen,
    handleVisibility,
    handleSubmitFilter
  };
};
__sfc_main.components = Object.assign({
  WrapperHandleError,
  SectionHeader,
  Popover,
  FormFilter,
  SkeletonTable,
  Actions,
  FormCreateVtag
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <WrapperHandleError :error="error" :is-error="isError">
    <SectionHeader>
      <template #header>
        <h1 class="header-1">Consulta de MDFE</h1>

        <div class="wrapper-buttons">
          <Popover>
            <template #trigger>
              <o-button variant="outline" size="md"> Filtros </o-button>
            </template>
            <template #content>
              <FormFilter
                :query-params="queryParams"
                :handle-submit-filter="handleSubmitFilter"
              />
            </template>
          </Popover>
          <o-button variant="primary" size="md" @click="handleVisibility"> Emitir MDFE </o-button>
        </div>
      </template>
    </SectionHeader>

    <SkeletonTable :is-loading="isLoadingTable">
      <o-table
        :data="tableData"
        :row-key="'id'"
        bordered
        class="space-m-y-32"
        paginated
        backend-pagination
        :total="tableTotal"
        :per-page="queryParams.perPage"
        :current-page.sync="queryParams.currentPage"
        :pagination-simple="false"
      >
        <o-table-column v-slot="props" field="mdfeNumber" label="Número">
          {{ props.row.mdfeNumber }}
        </o-table-column>

        <o-table-column v-slot="props" field="mdfeKey" label="Chave">
          {{ props.row.mdfeKey }}
        </o-table-column>

        <o-table-column v-slot="props" field="receiptNumber" label="Número da receita">
          {{ props.row.receiptNumber }}
        </o-table-column>

        <o-table-column v-slot="props" field="protocolNumber" label="Número de Protocolo">
          {{ props.row.protocolNumber }}
        </o-table-column>

        <o-table-column v-slot="props" field="emissionDate" label="Data de emissão">
          {{ dayjs(props.row.emissionDate).format('DD/MM/YYYY, HH:mm:ss') }}
        </o-table-column>

        <o-table-column label="" width="100">
          <template slot-scope="{row}">
            <Actions :row="row" />
          </template>
        </o-table-column>
      </o-table>

    </SkeletonTable>

    <o-modal :active="isOpen" width="660" @close="handleVisibility">
      <div>
        <h2 class="header-2">Gerando MDFE</h2>
        <p class="paragraph">Preencha os campos logo abaixo para conseguir gerar um MDFE em nosso sistema</p>
      </div>
      <FormCreateVtag :close-modal="handleVisibility" />
    </o-modal>
  </WrapperHandleError>
</template>

<style>
.wrapper-buttons{
  display: flex;
  gap: 1rem;
}

</style>
