<template> <!-- 揽收+上门揽收 -->
  <div>
    <!-- 分区选择 -->
    <div v-if="partitionId">
      <el-radio-group v-model="partitionAffiliateId" size="small">
        <el-radio-button
          v-for="item in subregionList"
          :key="item.id"
          :label="item.id"
        >
          {{ item.partitionIdentifier }}
        </el-radio-button>
      </el-radio-group>
    </div>

    <div style="margin-top: 10px;" class="priceTableForm">
      <div style="margin-bottom: 10px;">
        <!-- 增加一行 -->
        <el-button
          size="small"
          type="primary"
          :disabled="isAddDisabled"
          @click="handleAdd"
        >{{ $t('basicData.AddARow') }}</el-button>

        <!-- 下载/导入 -->
        <importAndDowloadCmp
          :partition-id="partitionId"
          :subregion-list="subregionList"
          :get-data-func="onDownloadOffer"
          @importList="onImportList"
        />

        <!-- 清空报价 -->
        <el-popover
          placement="bottom-start"
          trigger="hover"
        >
          <div>
            <!--清空当前分区-->
            <div style="margin-bottom: 10px;">
              <el-button
                size="small"
                type="primary"
                @click="onClear"
              >{{ $t('basicData.EmptyCurrentPartition') }}
              </el-button>
            </div>
            <!--清空所有分区-->
            <div>
              <el-button
                size="small"
                type="primary"
                @click="onAllClear"
              >{{ $t('basicData.EmptyAllPartitions') }}
              </el-button>
            </div>
          </div>
          <!--清空报价-->
          <el-button
            slot="reference"
            size="small"
            style="margin-left: 10px"
          >{{ $t('basicData.ClearTheOffer') }}</el-button>
        </el-popover>
      </div>

      <!--没有选择分区 -->
      <TableCmp
        v-if="!partitionId"
        ref="defaultTableRef"
        :table-data="defaultPartitionObj.default.arr"
        @change="arr => defaultPartitionObj.default.arr = arr"
      />

      <!--选择了分区 -->
      <TableCmp
        v-if="partitionId && subregionList.length > 0"
        ref="tableRef"
        :table-data="getPartitionArr"
        @change="changePartition"
      />
    </div>
  </div>
</template>

<script>
import TableCmp from './TableCmp.vue';
import importAndDowloadCmp from './importAndDowloadCmp.vue';
import { TMP_OBJ, DISTANCE_MODEL, DISTANCE_MODEL_COUNT, validatePrice, SEPARATOR, notSetPartitionFun, setPartitionFun } from './utils';

/** @typedef {import('./types').TTableRow} TTableRow */
/** @typedef {import('./types').TTableObj} TTableObj */

export default {
  name: '',

  components: {
    // PartitionIdCmp,
    TableCmp,
    importAndDowloadCmp
  },

  props: {
    // 分区
    partitionId: {
      type: [Number, String],
      default: () => {
        return 0;
      }
    },

    // 所有分区
    subregionList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  data() {
    return {
      partitionAffiliateId: '', // 选中的分区id

      // 如果没有选择分区
      defaultPartitionObj: {
        default: {
          /** @type {TTableRow[]} */
          arr: []
        }
      },

      /** @type {TTableObj}
       * @desc 如果选择了分区
       */
      partitionObj: {
        // 分区id1: { arr: [] },
        // 分区id2: { arr: [] },
      }
    };
  },

  computed: {
    // 添加按钮是否禁用
    isAddDisabled() {
      // 没有选择分区
      if (!this.partitionId) {
        // console.log('12', this.defaultPartitionObj.default.arr.length === DISTANCE_MODEL_COUNT);

        return this.defaultPartitionObj.default.arr.length >= DISTANCE_MODEL_COUNT;
      }

      const row = this.partitionObj[this.partitionAffiliateId];
      if (row && row.arr) {
        // 选择了分区
        // console.log(this.partitionAffiliateId, JSON.stringify(this.partitionObj));

        const arr = this.partitionObj[this.partitionAffiliateId].arr;
        return arr.length >= DISTANCE_MODEL_COUNT;
      }
      return false;
    },

    partitionComputed() {
      const a = this.partitionId;// 分区
      const b = this.subregionList;// 所有子分区
      return {
        a, b
      };
    },

    // 获取当前分区的数据
    getPartitionArr() {
      const row = this.partitionObj[this.partitionAffiliateId];
      if (this.partitionAffiliateId && row && row.arr) {
        // console.log(this.partitionAffiliateId);
        return this.partitionObj[this.partitionAffiliateId].arr || [];
      }
      return [];
    }
  },

  watch: {
    partitionComputed: {
      handler() {
        // this.getPartition();
        // console.log('监听分区变化', this.partitionId, this.subregionList);

        // 没有选择分区
        if (!this.partitionId) {
          this.defaultPartitionObj.default.arr = [];
          return;
        }

        // 选择了分区 但是分区暂时没有数据
        if (this.subregionList.length === 0) {
          return;
        }

        // 默认选中
        this.partitionAffiliateId = this.subregionList[0].id;

        // 先将之前的数据清空
        for (const key in this.partitionObj) {
          this.$delete(this.partitionObj, key);
        }

        // 将分区数据赋值
        this.subregionList.map(item => item.id).forEach(item => {
          this.$set(this.partitionObj || {}, item, { arr: [] });
        });
      },
      deep: true,
      immediate: true
    }
  },

  methods: {

    // 添加
    handleAdd() {
      // 没有选择分区
      if (!this.partitionId) {
        this.defaultPartitionObj.default.arr.push({
          ...TMP_OBJ
        });
        return;
      }

      // 选择了分区
      this.partitionObj[this.partitionAffiliateId].arr.push({ ...TMP_OBJ });
    },

    // 选择分区
    changePartition(arr) {
      this.partitionObj[this.partitionAffiliateId].arr = arr;
    },

    /**
     * 提交校验
     *    1. 直接校验当前分区
     *    2. 其他分区填写的数据校验
     */
    async submitForm() {
      // 没有选择分区
      if (!this.partitionId) {
        // 没有添加分区
        if (this.defaultPartitionObj.default.arr.length === 0) {
          // this.$message.error('请添加详情');
          this.$message.error(this.$t('basicData.PleaseAddDetails'));
          return false;
        }

        //  直接调用子组件的方法检验
        const isBool = await this.$refs.defaultTableRef.submitForm();

        if (!isBool) {
          // this.$message.error('价格详情校验不通过');
          this.$message.warning(this.$t('operatingPlatform.PleaseFillInTheRequiredFields'));
          return false;
        }
        return true;
      }

      // 选择了分区

      // 校验是否添加了数据
      {
        const arr = []; // 二维数组
        for (const key in this.partitionObj) {
          if (this.partitionObj[key].arr.length > 0) {
            arr.push(this.partitionObj[key].arr);
          }
        }

        if (arr.length === 0) {
          // this.$message.error('请添加最少一个价格详情');
          this.$message.warning(this.$t('basicData.PleaseAddDetails'));
          return false;
        }
      }

      // 先校验当前选中的分区
      {
        const isBool = await this.$refs.tableRef.submitForm();
        if (!isBool) {
          // this.$message.error('价格详情校验不通过');
          this.$message.warning(this.$t('basicData.CheckFail'));
          return false;
        }
      }

      // 校验其他分区
      {
        const arr = [];
        for (const key in this.partitionObj) {
          const isBool = validatePrice(this.partitionObj[key].arr);
          if (!isBool) {
            arr.push(key);
          }
        }

        // 有校验不通过的分区
        if (arr.length > 0) {
          // 获取不通过的分区名称
          const list = this.subregionList.filter(item => {
            return arr.includes(String(item.id));
          }).map(item => {
            return item.partitionIdentifier;
          }).join(',');

          // this.$message.error(`分区${list}价格详情校验不通过`);
          this.$message.warning(list + '' + this.$t('basicData.CheckFail'));
          return false;
        }
      }

      return true;
    },

    // 设置数据(主要是为父组件提供操作)
    setData(arr) {
      if (arr.length === 0) { return; }

      console.log(arr, '第三方+上门揽收');
      // 没有选择分区
      if (!this.partitionId) {
        this.defaultPartitionObj.default.arr = notSetPartitionFun(arr, this.defaultPartitionObj.default.arr);
        // console.log('notSetPartitionFun', notSetPartitionFun(arr, this.defaultPartitionObj.default.arr));
        return;
      }

      // 选择了分区
      const row = setPartitionFun(arr, this.partitionObj);
      // console.log(row);
      for (const key in this.partitionObj) {
        this.partitionObj[key].arr = row[key].arr;
      }
    },

    /**
     * 获取数据
     * @returns {TTableRow[]}
     */
    getData() {
      /** @type {TTableRow[]} */
      let arr = [];

      // 没有选择分区
      if (!this.partitionId) {
        arr = this.defaultPartitionObj.default.arr.map(item => {
          return {
            ...item,
            partitionAffiliateId: undefined
          };
        });
        return arr;
      }

      // 选择了分区，将所有数据（二维数组）放入arr（一维数组）中
      Object.entries(this.partitionObj).filter(item => {
        const [, value] = item;
        if (value.arr.length > 0) {
          return true;
        }
        return false;
      }).map(item => {
        const [key, value] = item;
        const list = value.arr.map(item => {
          return {
            ...item,
            partitionAffiliateId: Number(key)
          };
        });
        arr.push(...list);
      });
      return arr;
    },

    // 下载报价回调函数
    onDownloadOffer() {
      /** @type {TTableRow[]} */
      const list = this.getData();
      // console.log(list);

      return list.map(item => {
        const { partitionAffiliateId, distanceModel } = item;

        const obj = {
          ...item
        };

        // 分区名称处理
        {
          const partitionAffiliateObj = this.subregionList.find(item => {
            return item.id === partitionAffiliateId;
          });
          // console.log('partitionAffiliateObj', partitionAffiliateObj);
          if (partitionAffiliateObj) {
            const { partitionIdentifier, id } = partitionAffiliateObj;
            obj.partitionAffiliateId = `${partitionIdentifier}${SEPARATOR}${id}`;
          }
        }

        // 距离处理
        {
          const name = DISTANCE_MODEL[distanceModel]?.label;
          obj.distanceModel = `${name}${SEPARATOR}${distanceModel}`;
        }
        return obj;
      });
    },

    /**
     * 导入数据
     * @param {TTableRow[]} arr 导入返回的数据
     */
    onImportList(arr) {
      // console.log('importList', arr);

      // this.setData(arr);
      // 如果选择了分区，则需要校验所有分区存在数据
      if (this.partitionId) {
        const isBool = arr.every(item => {
          const { partitionAffiliateId } = item;
          if (this.partitionObj[partitionAffiliateId]) {
            return true;
          }
          return false;
        });

        if (!isBool) {
          //
          const str1 = this.$t('operationCenter.DownloadTheTemplate'); // 下载模板
          const str2 = this.$t('GlobalSetObj.pleaseChoose'); // 请选择
          const str3 = this.$t('operationCenter.partition'); // 分区

          this.$message.warning(`${str1}${str2}${str3}`);
          return;
        }
      }
      this.setData(arr);
    },

    // 清空当前分区
    onClear() {
      // 没有选择分区
      if (!this.partitionId) {
        this.defaultPartitionObj.default.arr = [];
        return;
      }

      // 选择了分区
      this.partitionObj[this.partitionAffiliateId].arr = [];
    },

    // 清空所有报价
    onAllClear() {
      // 没有选择分区
      if (!this.partitionId) {
        this.defaultPartitionObj.default.arr = [];
        return;
      }

      // 选择了分区
      for (const key in this.partitionObj) {
        this.partitionObj[key].arr = [];
      }
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
