import i18n from '@/lang';
import dayjs from 'dayjs';
import { Message } from 'element-ui';

// 开票类型
export const INVOICE_TYPE = {
  1: {
    label: 'CTE',
    value: 1
  }
};

// 业务类型-与其他数值不同
export const BUSINESS_TYPE = Object.freeze({

  1: {
    label: i18n.t('collectionCenter.lanshou'), // 揽收
    value: 1
  },
  2: {
    label: i18n.t('collectionCenter.dispatch'), // 派送
    value: 2
  },
  3: {
    label: i18n.t('collectionCenter.turnTransport'), // 转运
    value: 3
  }

});

// 发票状态
export const INVOICE_STATUS = {
  1: {
    label: i18n.t('GlobalSetObj.succeed'), // 成功
    value: 1
  },
  2: {
    label: i18n.t('GlobalSetObj.Lose'), // 失败
    value: 2
  },
  3: {
    label: i18n.t('GlobalSetObj.cancel'), // 取消
    value: 3
  }
};

/**
 * 批量取消 校验
 * @param {any[]} 勾选的数据
 * @returns {boolean} 校验不通过返回false
 */
export const isCancelCheck = (arr) => {
  const str1 = i18n.t('GlobalSetObj.pleaseChoose'); // 请选择发票
  const str2 = i18n.t('route.invoice'); // 发票
  const st3 = i18n.t('basicData.发票不是成功状态'); // 发票
  const st4 = i18n.t('GlobalSetObj.SelectedInvoicesMoreThan24'); // 发票生成时间已超过24小时，不可取消
  // const arr = this.multipleSelection;

  // 规则1
  if (arr.length === 0) {
    Message.warning(str1 + str2);
    return false;
  }

  // 规则2：成功状态才能取消
  {
    const list = arr.filter(item => {
      return item.invoiceStatus !== INVOICE_TYPE['1'].value;
    }).map(item => item.invoiceNumber);

    // 当前选择都是非成功状态
    if (list.length !== 0) {
      const str = list.join(',');

      Message.warning(`【${str}】 ${st3}`);
      return false;
    }
  }

  // 规则3：超过24小时的发票不能取消
  {
    const list = arr.filter(item => {
      const val = dayjs(item.invoiceIssueTime || new Date());
      const isBool2 = dayjs().diff(val, 'hour') > 24;
      return isBool2;
    }).map(item => item.invoiceNumber);
    // 当前的选择状态为成功的发票，存在开票时间超过24小时
    if (list.length !== 0) {
      const str = list.join(',');
      Message.warning(`【${str}】 ${st4}`);
      return false;
    }
  }

  return true;
};

/**
 * 批量重开校验
 * @param {*} arr 勾选的数据
 * @returns {boolean} 校验不通过返回false
 */
export const restartCheck = (arr) => {
  const str1 = i18n.t('GlobalSetObj.pleaseChoose'); // 请选择发票
  const str2 = i18n.t('route.invoice'); // 发票
  const st4 = i18n.t('basicData.已成功的发票 不能进行重开');

  // 规则1
  if (arr.length === 0) {
    Message.warning(str1 + str2);
    return false;
  }

  // 取消或失败状态才能 重开
  {
    const list = arr.filter(item => {
      return item.invoiceStatus === INVOICE_TYPE['1'].value;
    }).map(item => item.invoiceNumber);

    // 当前选择都是成功状态
    if (list.length !== 0) {
      const str = list.join(',');

      Message.warning(`【${str}】 ${st4}`);
      return false;
    }
  }
  return true;
};

// 批量下载校验
export const downloadCheck = (arr) => {
  const str1 = i18n.t('GlobalSetObj.pleaseChoose'); // 请选择发票
  const str2 = i18n.t('route.invoice'); // 发票
  const str3 = i18n.t('GlobalSetObj.RejectedAndCanceledInvoices'); // 已拒绝和已取消的发票将不支持下载

  // 规则1
  if (arr.length === 0) {
    Message.warning(str1 + str2);
    return false;
  }

  // 规则2
  {
    const list = arr.filter(item => {
      return item.invoiceStatus !== INVOICE_TYPE['1'].value;
    }).map(item => item.invoiceNumber);

    // 当前选择都是成功状态
    if (list.length !== 0) {
      const str = list.join(',');

      Message.warning(`【${str}】 ${str3}`);
      return false;
    }
  }

  return true;
};

