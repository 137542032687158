import axios from 'axios';
import { addLoading, isCloseLoading } from '@/utils/request';
import store from '@/store';
import { getToken } from '@/utils/auth';

const requestPackage = axios.create({
  baseURL: process.env.VUE_APP_BASE_API5,
  timeout: 30000
});

requestPackage.interceptors.request.use(config => {
  addLoading();
  if (store.getters.token) {
    config.headers['Authorization'] = `Bearer ${getToken()}`;
  }
  return config;
});

requestPackage.interceptors.response.use((response) => {
  isCloseLoading();
  return response;
}, error => {
  isCloseLoading();
  return Promise.reject(error);
});

export const apiReplaceBag = (data) => {
  return requestPackage({
    method: 'PATCH',
    data,
    url: `v1/bag`
  });
};

export const apiCloseBag = data => {
  return requestPackage({
    method: 'POST',
    data,
    url: 'v1/handler/bagSealingTime'
  });
};

export const checkPackage = (params) => {
  const queryString = new URLSearchParams();

  for (const key in params) {
    if (params[key]) {
      queryString.append(key, params[key]);
    }
  }

  return requestPackage({
    method: 'GET',
    url: `v1/checker?${queryString.toString()}`
  });
};

export const createConfirmList = (data) => {
  return requestPackage(
    {
      method: 'POST',
      data,
      url: 'v1/handler/scanTime'
    }
  );
};

export const getRandomBagNumber = (data) => {
  return requestPackage({
    method: 'GET',
    url: 'v1/bag/randomBag'
  });
};
