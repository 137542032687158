var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: {
        "append-to-body": true,
        title: _vm.$t("receivablePayable.FeeDetails"),
        visible: _vm.isVisible,
        "custom-class": "drawerClass",
        direction: "ltr",
        size: "80%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.isVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "details" },
        [
          _c(
            "el-card",
            { attrs: { "body-style": { padding: 0 } } },
            [
              _c("OrderTable", {
                key: _vm.key,
                ref: "addItemRef",
                attrs: {
                  "query-form": _vm.queryForm,
                  "select-data": _vm.selectData,
                  "is-open": _vm.isVisible,
                  "payable-type": _vm.payableType,
                },
                on: { getSite: _vm.getSite },
              }),
            ],
            1
          ),
          _c(
            "el-card",
            { attrs: { "body-style": { padding: 0 } } },
            [
              _c("FeeTable", {
                ref: "detailsTableRef",
                attrs: {
                  "add-type": 1,
                  "fee-type-option": _vm.feeTypeOption,
                  "query-form": _vm.queryForm,
                  list: _vm.addBatchFeeList,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "btn" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: _vm.permission,
                      expression: "permission",
                      arg: "remove",
                    },
                  ],
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.submit },
                },
                [_vm._v(_vm._s(_vm.$t("basicData.Save")))]
              ),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.onClose } },
                [_vm._v(_vm._s(_vm.$t("orderCenterCont.ShutDown")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }