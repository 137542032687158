export const basicData = {
  LastUpdated: {
    zh: '上次更新时间',
    en: 'Last updated',
    pu: 'Última atualização'
  },
  LastUpdatedBy: {
    zh: '上次更新人',
    en: 'Last Updated By',
    pu: 'Última atualização feita por'
  },
  TheFormatContentIncorrect: {
    zh: '填写内容格式不正确',
    en: 'The format of the content is incorrect',
    pu: 'O formato do conteúdo está incorreto'
  },
  PartitionName: {
    zh: '分区名称',
    en: 'Partition name',
    pu: 'Nome da partição'
  },
  RemarkContent: {
    zh: '备注内容',
    en: 'Remark content',
    pu: 'Conteúdo da nota'
  },
  Search: {
    zh: '搜索',
    en: 'Search',
    pu: 'Buscar'
  },
  reset: {
    zh: '重置',
    en: 'reset',
    pu: 'Reinicialização'
  },
  Add: {
    zh: '添加',
    en: 'Add',
    pu: 'Adicionar'
  },
  serialNumber: {
    zh: '序号',
    en: 'serial number',
    pu: 'Número de série'
  },
  CreatePeople: {
    zh: '创建人',
    en: 'Create People',
    pu: 'Pessoa de criação'
  },
  CreationTime: {
    zh: '创建时间',
    en: 'Creation time',
    pu: 'Tempo de criação'
  },
  LastModifier: {
    zh: '最后修改人',
    en: 'Last modifier',
    pu: 'Pessoa de última modificação'
  },
  LastModified: {
    zh: '最后修改时间',
    en: 'Last modified',
    pu: 'Tempo de última modificação'
  },
  operate: {
    zh: '操作',
    en: 'operate',
    pu: 'Operar'
  },
  AreYouSureDisabled: {
    zh: '确定禁用吗？',
    en: 'Are you sure it\'s disabled?',
    pu: 'Você tem certeza de que vai desativar-lo?'
  },
  AreYouSureEnabled: {
    zh: '确定启用吗？',
    en: 'Are you sure it\'s enabled?',
    pu: 'Você tem certeza de que vai habilitar-lo?'
  },
  AreYouSureDelete: {
    zh: '确定删除吗？',
    en: 'Are you sure you want to delete?',
    pu: 'Tem certeza de apagar?'
  },
  zipCode: {
    zh: '邮编',
    en: 'zip code',
    pu: 'Código postal'
  },
  PartitionStatus: {
    zh: '分区状态',
    en: 'Partition status',
    pu: 'Status da Partição'
  },
  status: {
    zh: '状态',
    en: 'status',
    pu: 'Status'
  },
  remark: {
    zh: '备注',
    en: 'remark',
    pu: 'Observações'
  },
  enable: {
    zh: '启用',
    en: 'enable',
    pu: 'Habilitar'
  },
  disable: {
    zh: '禁用',
    en: 'disable',
    pu: 'Proibição de uso'
  },
  Undefined: {
    zh: '未定义',
    en: 'Undefined',
    pu: 'Indefinido'
  },
  Expired: {
    zh: '已过期',
    en: 'Expired',
    pu: 'Expirado'
  },
  PartitionSchemeName: {
    zh: '分区方案名称',
    en: 'Partition scheme name',
    pu: 'Nome do Esquema de Zoneamento'
  },
  PartitionImportTemplate: {
    zh: '导入分区模板',
    en: 'Partition import template',
    pu: 'Importação de modelos de divisórias'
  },
  PartitionImportRules: {
    zh: '注意：分区名称（必填）；邮编（必填，邮编号码段连接符“~”，比如：1001~2001，代表1001到2001之间全部1000个邮编号码）任一必填',
    en: 'Note: Partition name (required); Zip code (required, zip code number field connector "~", for example: 1001~2001, representing all 1000 zip codes between 1001 and 2001) are required',
    pu: 'Nota: Nome da divisão (obrigatório); Código postal (obrigatório, com o campo do número CEP concatenado com "~").(por exemplo, 1001~2001, representando todos os 1000 números de código postal entre 1001 e 2001) são necessários'
  },
  TheOperationSucceeded: {
    zh: '操作成功',
    en: 'The operation succeeded',
    pu: 'Operação bem-sucedida'
  },
  AddPartition: {
    zh: '新增分区',
    en: 'Add a partition',
    pu: 'Adicionar uma nova divisão'
  },
  PartitionDetails: {
    zh: '分区详情',
    en: 'Partition details',
    pu: 'Detalhes da Partição'
  },
  ImportDownload: {
    zh: '导入/下载',
    en: 'Import/Download',
    pu: 'Importação / Download'
  },
  CannotBeEmpty: {
    zh: '不能为空',
    en: 'Cannot be empty',
    pu: 'Não pode estar vazio'
  },
  ZipCodeExample: {
    zh: '填写邮编。填写示例如：10~20,21,22~30',
    en: 'Fill in the zip code. Fill in examples such as: 10~20, 21, 22~30',
    pu: 'Preencha o CEP. Preencha exemplos como: 10 ~ 20, 21, 22 ~ 30'
  },
  AreYouSureYouWantToDelete: {
    zh: '确定删除吗？',
    en: 'Are you sure you want to delete?',
    pu: 'Tem certeza de apagar?'
  },
  Delete: {
    zh: '删除',
    en: 'Delete',
    pu: 'Excluir'
  },
  Edit: {
    zh: '编辑',
    en: 'Edit',
    pu: 'Editar'
  },
  Cancel: {
    zh: '取消',
    en: 'Cancel',
    pu: 'Cancelar'
  },
  determine: {
    zh: '确定',
    en: 'determine',
    pu: 'Claor'
  },
  OperationalLogs: {
    zh: '操作日志',
    en: 'Operational logs',
    pu: 'Diário de operações'
  },
  tips: {
    zh: '提示',
    en: 'tips',
    pu: 'Dica'
  },
  Save: {
    zh: '保存',
    en: 'Save',
    pu: 'Salvar'
  },
  TheLengthBetween2And20Characters: {
    zh: '长度在 2 到 20 个字符',
    en: 'The length is between 2 and 20 characters',
    pu: 'O comprimento é entre 2 e 20 caracteres'
  },
  TheLengthBetween2And40Characters: {
    zh: '长度在 2 到 40 个字符',
    en: 'The length is between 2 and 40 characters',
    pu: 'O comprimento é entre 2 e 40 caracteres'
  },
  TheLengthBetween2And50Characters: {
    zh: '长度在 2 到 50 个字符',
    en: 'The length is between 2 and 50 characters',
    pu: 'O comprimento é entre 2 e 50 caracteres'
  },
  TheLengthBetween2And8Characters: {
    zh: '长度在 2 到 8 个字符',
    en: 'The length is between 2 and 8 characters',
    pu: 'O comprimento é entre 2 e 8 caracteres'
  },
  DownloadTheTemplate: {
    zh: '下载模板',
    en: 'Download the template',
    pu: 'Download do modelo'
  },
  ImportPartitions: {
    zh: '导入分区',
    en: 'Import partitions',
    pu: 'Divisória de importação'
  },
  DownloadThePartition: {
    zh: '下载分区',
    en: 'Download the partition',
    pu: 'Partição para download'
  },
  UploadTheFile: {
    zh: '上传文件',
    en: 'Upload the file',
    pu: 'Carregar o arquivo'
  },
  PleaseUploadExcelFiles: {
    zh: '请上传 .xlsx, .xls 文件',
    en: 'Please upload .xlsx, .xls files',
    pu: 'Carregar o arquivo'
  },
  AccountName: {
    zh: '账户名称',
    en: 'Account name',
    pu: 'Nome da conta'
  },
  AccountNumber: {
    zh: '账户号码',
    en: 'Account number',
    pu: 'Número de conta'
  },
  AccountOpeningName: {
    zh: '户名',
    en: 'Account opening name',
    pu: 'Nome da conta real'
  },
  TheBankWithWhichTheAccountOpened: {
    zh: '开户行',
    en: 'The bank with which the account is opened',
    pu: 'Banco'
  },
  PleaseAddPartitions: {
    zh: '请添加分区',
    en: 'Please add partitions',
    pu: 'Favor adicionar a divisória'
  },
  Lines: {
    zh: '第',
    en: 'Lines',
    pu: 'As linhas'
  },
  and: {
    zh: '和',
    en: 'and',
    pu: 'e'
  },
  repeat: {
    zh: '行名称重复',
    en: 'have duplicate names',
    pu: 'têm nomes duplicados'
  },
  areDuplicated: {
    zh: '行邮编重复',
    en: 'are duplicated',
    pu: 'são duplicadas'
  },
  theCityIsDuplicated: {
    zh: '行城市重复',
    en: 'the city is duplicated',
    pu: 'Cidades são duplicadas'
  },
  StateDuplication: {
    zh: '行州重复',
    en: 'State duplication',
    pu: 'Duplicação de estado'
  },
  intersect: {
    zh: '行邮编存在交集',
    en: 'intersect',
    pu: 'se cruzam'
  },
  areMalformed: {
    zh: '行邮编格式不正确',
    en: 'are malformed',
    pu: 'estão malformadas'
  },
  PleaseEnter: {
    zh: '请输入',
    en: 'Please enter',
    pu: 'Por favor, insira'
  },
  AddAnAccount: {
    zh: '添加账户',
    en: 'Add an account',
    pu: 'Adicionar conta'
  },
  AccountDetails: {
    zh: '账户详情',
    en: 'Account details',
    pu: 'Detalhes da conta'
  },
  ExpenseName: {
    zh: '费用名称',
    en: 'Expense name',
    pu: 'Nome da taxa'
  },
  FeeCode: {
    zh: '费用编码',
    en: 'Fee code',
    pu: 'Código da taxa'
  },
  ExpenseType: {
    zh: '费用类型',
    en: 'Expense type',
    pu: 'Tipo de custo'
  },
  chargeType: {
    zh: '计费类型',
    en: 'charge type',
    pu: 'Tipo de faturamento'
  },
  FeesApply: {
    zh: '应收费用',
    en: 'Fees apply',
    pu: 'Aplicam-se taxas'
  },
  FeesPayable: {
    zh: '应付费用',
    en: 'Fees Payable',
    pu: 'Valor a receber'
  },
  TotalFeesPayable: {
    zh: '应付总费用',
    en: 'Total fees payable',
    pu: 'Despesas Totais a Pagar'
  },
  TaxasAPagarEAPagar: {
    zh: '既是应收费用也是应付费用',
    en: 'It is both a fee payable and a fee payable',
    pu: 'É uma taxa a pagar e uma taxa a pagar'
  },
  Operator: {
    zh: '操作人',
    en: 'Operator',
    pu: 'Pessoa de poeração'
  },
  PleaseSelect: {
    zh: '请选择',
    en: 'Please select',
    pu: 'Favor selecionar'
  },
  PleaseEnterEnglishLetters: {
    zh: '请输入英文字母',
    en: 'Please enter English letters',
    pu: 'Por favor, insira letras em inglês'
  },
  AddAFee: {
    zh: '添加费用',
    en: 'Add a fee',
    pu: 'Acrescentar uma taxa'
  },
  FeeDetails: {
    zh: '费用详情',
    en: 'Fee details',
    pu: 'Detalhes das taxas'
  },
  TheNameOfTheQuoteProposal: {
    zh: '报价方案名称',
    en: 'The name of the quote proposal',
    pu: 'O nome da proposta de citação'
  },
  businessPrincipal: {
    zh: '应收主体',
    en: 'Subject receivable',
    pu: 'Assunto a receber'
  },
  BusinessPrincipal: {
    zh: '业务主体',
    en: 'Business principal',
    pu: 'Principal de negócios'
  },
  Outlets: {
    zh: '网点',
    en: 'Site',
    pu: 'Local'
  },
  WaybillNumber: {
    zh: '运单号',
    en: 'Waybill number',
    pu: 'Número da carta de porte'
  },
  UsageType: {
    zh: '使用类型',
    en: 'Usage type',
    pu: 'Tipo de uso'
  },
  UsageStatus: {
    zh: '使用状态',
    en: 'Usage status',
    pu: 'Status de uso'
  },
  QuoteType: {
    zh: '报价类型',
    en: 'Quote type',
    pu: 'Tipo de citação'
  },
  TypeOfCourier: {
    zh: '快递类型',
    en: 'Type of courier',
    pu: 'Tipo de correio'
  },
  TheValidityPeriodBegins: {
    zh: '有效期开始',
    en: 'The validity period begins',
    pu: 'Início do período de validade'
  },
  EndOfValidityPeriod: {
    zh: '有效期结束',
    en: 'End of validity period',
    pu: 'Fim do período de validade'
  },
  AddANewQuote: {
    zh: '新增报价',
    en: 'Add a new quote',
    pu: 'Adicionar citação'
  },
  QuoteDetails: {
    zh: '报价详情',
    en: 'Quote details',
    pu: 'Detalhes das cotações'
  },
  SelectADatetime: {
    zh: '选择日期时间',
    en: 'Select a datetime',
    pu: 'Selecione uma data/hora'
  },
  ImportQuotes: {
    zh: '导入报价',
    en: 'Import quotes',
    pu: 'Cotações de importação'
  },
  DownloadTheQuote: {
    zh: '下载报价',
    en: 'Download the quote',
    pu: 'Baixe a cotação'
  },
  StandardFast: {
    zh: '标快',
    en: 'Standard-fast',
    pu: 'Padrão rápido'
  },
  express: {
    zh: '特快',
    en: 'express',
    pu: 'Expresso especial'
  },
  OrdinaryCourier: {
    zh: '普快',
    en: 'Ordinary courier',
    pu: 'Expresso Comum'
  },
  UnderlyingData: {
    zh: '基础数据',
    en: 'Underlying data',
    pu: 'Dados básicos'
  },
  PriceDetails: {
    zh: '价格详情',
    en: 'Price details',
    pu: 'Detalhes do preço'
  },
  AddARow: {
    zh: '增加一行',
    en: 'Add a row',
    pu: 'Adicionar uma linha'
  },
  CollectFees: {
    zh: '揽收费',
    en: 'Collect fees',
    pu: 'Taxas de solicitação'
  },
  insurance: {
    zh: '保险费',
    en: 'insurance',
    pu: 'Taxa de seguro'
  },
  BulkImport: {
    zh: '批量导入',
    en: 'Bulk import',
    pu: 'Importação em massa'
  },
  MultiPartitionImport: {
    zh: '多分区导入',
    en: 'Multi-partition import',
    pu: 'Importação de várias partições'
  },
  PackageQuantityStarts: {
    zh: '包裹数开始（不包含）',
    en: 'Package quantity starts (not included)',
    pu: 'Início da contagem das encomendas (não incluído)'
  },
  PackageQuantityEnds: {
    zh: '包裹数结束（包含）',
    en: 'Package quantity ends (included)',
    pu: 'Fim da contagem das encomendas (incluído)'
  },
  Begin: {
    zh: '开始（不包含）',
    en: 'Begin (not included)',
    pu: 'Começar (não incluído)'
  },
  end: {
    zh: '结束（包含）',
    en: 'end (included)',
    pu: 'fim (incluído)'
  },
  PriceRange: {
    zh: '区间价格',
    en: 'Range price',
    pu: 'Preço de intervalo'
  },
  Price: {
    zh: '价格',
    en: 'Price',
    pu: 'Preço'
  },
  ThePriceTheFirstOrder: {
    zh: '首单价格',
    en: 'The price of the first order',
    pu: 'Preço do primeiro pedido'
  },
  ExceedingThePrice: {
    zh: '超出价格',
    en: 'Exceeding the price',
    pu: 'tipo de coleta'
  },
  CourierFee: {
    zh: '快递费',
    en: 'Courier fee',
    pu: 'Taxa de correio'
  },
  WeightStartKG: {
    zh: '重量开始KG(不包含)',
    en: 'Weight Start KG (not included)',
    pu: 'Peso inicial KG (não incluído)'
  },
  WeightEndKG: {
    zh: '重量结束KG(包含)',
    en: 'Weight End KG (included)',
    pu: 'Peso final KG (incluído)'
  },
  ExcessWeightFareIncrease: {
    zh: '超出重量加价(每KG)',
    en: 'Excess weight Fare increase (per KG)',
    pu: 'Taxa extra por excesso de peso (por KG)'
  },
  SetUpABillingPeriod: {
    zh: '设置账期',
    en: 'Set up a billing period',
    pu: 'Estabelecer um período de faturamento'
  },
  CustomerName: {
    zh: '客户名称',
    en: 'Customer name',
    pu: 'Nome do cliente'
  },
  AccountBalance: {
    zh: '账户余额',
    en: 'Account balance',
    pu: 'Saldo da conta'
  },
  creditLimit: {
    zh: '信用额度',
    en: 'credit limit',
    pu: 'Limite de crédito'
  },
  AccumulatedUnsettled: {
    zh: '累计未结算',
    en: 'Accumulated unsettled',
    pu: 'Acumulado não liquidado'
  },
  TotalBalance: {
    zh: '总计余额',
    en: 'Total balance',
    pu: 'Saldo total'
  },
  billingPeriod: {
    zh: '账单账期',
    en: 'Billing period',
    pu: 'Período de Faturamento'
  },
  ExportAccountInformation: {
    zh: '导出账户信息',
    en: 'Export account information',
    pu: 'Informações sobre a conta de exportação'
  },
  ExportAccountRollover: {
    zh: '导出账户流水',
    en: 'Export account rollover',
    pu: 'Fluxo da conta de exportação'
  },
  AddANewCustomer: {
    zh: '新增客户',
    en: 'Add a new customer',
    pu: 'Adicionar um novo cliente'
  },
  CustomerDetails: {
    zh: '客户详情',
    en: 'Customer details',
    pu: 'Detalhes do cliente'
  },
  UnsettledTotalAmount: {
    zh: '未结算总金额',
    en: 'Unsettled Total amount',
    pu: 'Valor total não liquidado'
  },
  category: {
    zh: '类别',
    en: 'category',
    pu: 'Categoria'
  },
  all: {
    zh: '全部',
    en: 'all',
    pu: 'todo'
  },
  acceptanceCheck: {
    zh: '核收',
    en: 'acceptance check',
    pu: 'Verificação do recebimento'
  },
  recharge: {
    zh: '充值',
    en: 'recharge',
    pu: 'Recarregar'
  },
  BusinessTime: {
    zh: '业务时间',
    en: 'Business time',
    pu: 'Horário comercial'
  },
  TransitInboundTime: {
    zh: '转运入库时间',
    en: 'Transit inbound time',
    pu: 'Tempo de entrada em trânsito'
  },
  TimeCollectIntoTreasury: {
    zh: '揽收入库时间',
    en: 'Time to collect into the treasury',
    pu: 'Hora de recolher no tesouro'
  },
  PickUpAndOutboundTime: {
    zh: '揽收出库时间',
    en: 'Pick-up and outbound time',
    pu: 'Tempo de recolha e saída'
  },
  detail: {
    zh: '详情',
    en: 'detail',
    pu: 'Detalhes'
  },
  BatchNo: {
    zh: '批次号',
    en: 'Batch No',
    pu: 'Número do lote'
  },
  PaymentAmount: {
    zh: '交款金额',
    en: 'Payment amount',
    pu: 'Montante do pagamento'
  },
  TicketAmount: {
    zh: '票单金额',
    en: 'Ticket amount',
    pu: 'Valor de bilhete'
  },
  PayOff: {
    zh: '缴清',
    en: 'Pay off',
    pu: 'Pagamento'
  },
  manner: {
    zh: '方式',
    en: 'manner',
    pu: 'Método'
  },
  cash: {
    zh: '现金',
    en: 'cash',
    pu: 'Dinheiro'
  },
  online: {
    zh: '网络',
    en: 'online',
    pu: 'Internet'
  },
  Elimination: {
    zh: '冲销',
    en: 'Elimination',
    pu: 'offset'
  },
  other: {
    zh: '其他',
    en: 'other',
    pu: 'Outros'
  },
  TicketNumber: {
    zh: '票证号',
    en: 'Ticket number',
    pu: 'Número do bilhete'
  },
  GoAccount: {
    zh: '走账户',
    en: 'Go account',
    pu: 'Contas para pagamento/recebimento'
  },
  PaymentMethod: {
    zh: '付款方式',
    en: 'Payment method',
    pu: 'Forma de pagamento'
  },
  annex: {
    zh: '附件',
    en: 'annex',
    pu: 'Anexos'
  },
  View: {
    zh: '查看',
    en: 'View',
    pu: 'Vista'
  },
  CompanyName: {
    zh: '公司名称',
    en: 'Company name',
    pu: 'Nome da empresa'
  },
  CustomerCode: {
    zh: '客户编码',
    en: 'Customer Code',
    pu: 'Código do cliente'
  },
  CurrentBalance: {
    zh: '当前余额',
    en: 'Current balance',
    pu: 'Balanço atual'
  },
  AccountAlias: {
    zh: '账户别称',
    en: 'Account alias',
    pu: 'Outro nome da conta'
  },
  acceptMethod: {
    zh: '收款方式',
    en: 'Payment method',
    pu: 'Método de pagamento'
  },
  TopUpAmount: {
    zh: '充值金额',
    en: 'Top-up amount',
    pu: 'Valor de recargo'
  },
  TopUpMethod: {
    zh: '充值方式',
    en: 'Top-up method',
    pu: 'Método de recarga'
  },
  account: {
    zh: '账户',
    en: 'account',
    pu: 'Conta'
  },
  exchangeRate: {
    zh: '汇率',
    en: 'exchange rate',
    pu: 'Taxas de câmbio'
  },
  Handler: {
    zh: '经办人',
    en: 'Handler',
    pu: 'Treinador'
  },
  SelectADate: {
    zh: '选择日期',
    en: 'Select a date',
    pu: 'Selecione uma data'
  },
  DragTheFileHereOrClickUpload: {
    zh: '将文件拖到此处，或点击上传',
    en: 'Drag the file here, or click Upload',
    pu: 'Arraste o arquivo aqui, ou clique em Upload'
  },
  UploadTheAttachmentFormat: {
    zh: '仅支持上传格式为bmp，jpg，png，gif，pdf，且大小在5M以内的附件',
    en: 'Only attachments in bmp, jpg, png, gif, pdf formats and within 5M in size are supported',
    pu: 'Somente anexos em formato bmp, jpg, png, gif, pdf e até 5M de tamanho são suportados.'
  },
  PayoutDetails: {
    zh: '收款详情',
    en: 'Payout details',
    pu: 'Detalhes da coleta'
  },
  IncorrectFormatting: {
    zh: '格式不正确',
    en: 'Incorrect formatting',
    pu: 'Formato incorreto'
  },
  UploadFileSizeCannotExceed5MB: {
    zh: '上传文件大小不能超过5MB!',
    en: 'Upload file size cannot exceed 5MB!',
    pu: 'O tamanho do arquivo de upload não pode exceder 5MB!'
  },
  Only1FileCanBeUploadedAtATime: {
    zh: '每次只能上传1个文件',
    en: 'Only 1 file can be uploaded at a time',
    pu: 'Apenas 1 arquivo pode ser carregado de cada vez'
  },
  ReceiptNumber: {
    zh: '收款单号',
    en: 'Receipt number',
    pu: 'Número do recibo'
  },
  OrderNumber: {
    zh: '订单号',
    en: 'Order number',
    pu: 'Número do pedido'
  },
  ReferenceNumber: {
    zh: '参考号',
    en: 'Reference number',
    pu: 'Número de referência'
  },
  TrackingNumber: {
    zh: '物流单号',
    en: 'Tracking number',
    pu: 'Número de rastreamento'
  },
  FinalTrackingNumber: {
    zh: '最终物流单号',
    en: 'Final tracking number',
    pu: 'Número de rastreamento final'
  },
  CustomerAlias: {
    zh: '客户别名',
    en: 'Customer alias',
    pu: 'Alias de cliente'
  },
  CustomerChannelName: {
    zh: '客户渠道名称',
    en: 'Customer channel name',
    pu: 'Nome do canal do cliente'
  },
  VerificationStatus: {
    zh: '核收状态',
    en: 'Verification status',
    pu: 'Status da verificação'
  },
  Received: {
    zh: '已核收',
    en: 'Received',
    pu: 'Recebido'
  },
  NotReconciled: {
    zh: '未核收',
    en: 'Not reconciled',
    pu: 'Não reconciliado'
  },
  VerificationTime: {
    zh: '核收时间',
    en: 'Verification time',
    pu: 'Tempo de verificação'
  },
  CheckUsers: {
    zh: '核收用户',
    en: 'Check users',
    pu: 'Verificar usuários'
  },
  TotalBillableWeight: {
    zh: '计费总重量',
    en: 'Total billable weight',
    pu: 'Peso total faturável'
  },
  TotalFeesReceivable: {
    zh: '应收总费用',
    en: 'Total fees receivable',
    pu: 'Total de comissões a receber'
  },
  BalanceBeforeDeduction: {
    zh: '扣款前余额',
    en: 'Balance before deduction',
    pu: 'Saldo antes da dedução'
  },
  BalanceAfterDeduction: {
    zh: '扣款后余额',
    en: 'Balance after deduction',
    pu: 'Saldo após dedução'
  },
  CustomerAccount: {
    zh: '客户账户',
    en: 'Customer Account',
    pu: 'Conta de Cliente'
  },
  TotalAmountReceived: {
    zh: '已收总金额',
    en: 'Total amount received',
    pu: 'Montante total recebido'
  },
  TheLengthCannotBeGreaterThan: {
    zh: '长度不能大于',
    en: 'The length cannot be greater than',
    pu: 'O comprimento não pode ser maior que'
  },
  CurrentBillingPeriod: {
    zh: '当前账期',
    en: 'Current billing period',
    pu: 'Período de Faturamento Atual'
  },
  AdjustTheBillingPeriod: {
    zh: '调整后账期',
    en: 'Adjust the billing period',
    pu: 'Período de Faturamento Ajustado'
  },
  siteName: {
    zh: '网点名称',
    en: '',
    pu: 'Nome de ponto'
  },
  PrincipalType: {
    zh: '主体类型',
    en: 'Principal type',
    pu: 'Tipo de Assunto'
  },
  SelfOperated: {
    zh: '自营',
    en: 'Self-operated',
    pu: 'Trabalhadores autônomos'
  },
  thirdParty: {
    zh: '第三方',
    en: 'third party',
    pu: 'Terceiros'
  },
  postal: {
    zh: '邮政',
    en: 'postal',
    pu: 'Postal'
  },
  MustPositiveIntegerGreaterThan0: {
    zh: '必须是大于0的正整数',
    en: 'Must be a positive integer greater than 0',
    pu: 'Deve ser um número inteiro positivo maior que 0'
  },
  MustBeGreaterThan0: {
    zh: '必须是大于0的数',
    en: 'Must be greater than 0',
    pu: 'Deve ser maior que 0'
  },
  MustPositiveIntegerGreaterThanOrEqual0: {
    zh: '必须是大于或等于0的正整数',
    en: 'Must be a positive integer greater than or equal to 0',
    pu: 'Deve ser um número inteiro positivo maior ou igual a 0'
  },
  CannotBeLessThanTheCurrentDate: {
    zh: '不能小于当前日期',
    en: 'Cannot be less than the current date',
    pu: 'A data não pode ser menor que a data atual'
  },
  PleaseAddCollectionFee: {
    zh: '请添加揽收费',
    en: 'Please add a collection fee',
    pu: 'Por favor, adicione uma taxa de cobrança'
  },
  PleaseAddCourierFee: {
    zh: '请添加快递费',
    en: 'Please add courier fee',
    pu: 'Por favor, adicione a taxa de correio'
  },
  MustGreaterThanStartingValue: {
    zh: '必须大于起始值',
    en: 'Must be greater than the starting value',
    pu: 'Deve ser maior do que o valor inicial'
  },
  MustLessThanEndValue: {
    zh: '必须小于结束值',
    en: 'Must be less than the end value',
    pu: 'Deve ser menor do que o valor final'
  },
  Receivables: {
    zh: '应收',
    en: 'Receivables',
    pu: 'Recebíveis'
  },
  payable: {
    zh: '应付',
    en: 'payable',
    pu: 'Pagável'
  },
  SelectTheBusinessPrincipalFirst: {
    zh: '请先选择应收主体',
    en: 'Please select the receivable entity first',
    pu: 'Selecione primeiro o assunto a receber'
  },
  PleaseSelectThePayableEntity: {
    zh: '请先选择应付主体',
    en: '',
    pu: 'Por favor, selecione a entidade pagadora primeiro'
  },
  ImportFeeTemplatesInBulk: {
    zh: '批量导入揽收费模板',
    en: 'Import fee templates in bulk',
    pu: 'Modelos de taxa de importação em massa'
  },
  ImportCourierFeeTemplatesInBulk: {
    zh: '批量导入快递费模板',
    en: 'Import courier fee templates in bulk',
    pu: 'Importe modelos de taxa de correio em massa'
  },
  ImportQuotationTemplatesInBulk: {
    zh: '批量导入报价模板',
    en: 'Import quotation templates in bulk',
    pu: 'Import modelos de cotação em massa'
  },
  ImportOtherQuoteTemplatesBulk: {
    zh: '批量导入其他报价模板',
    en: 'Import other quote templates in bulk',
    pu: 'Importar outros modelos de cotação em massa'
  },
  row: {
    zh: '第',
    en: 'row',
    pu: 'linha'
  },
  line: {
    zh: '行',
    en: '',
    pu: ''
  },
  PackageStartQuantityCannotBeGreaterThanPackageEndQuantity: {
    zh: '包裹开始数量不能大于包裹结束数量',
    en: 'Package Start Quantity cannot be greater than Package End Quantity',
    pu: 'A quantidade inicial da encomenda não pode ser maior do que a quantidade final da encomenda'
  },
  WeightStartCannotBeGreaterThanWeightEnd: {
    zh: '重量开始不能大于重量结束',
    en: 'Weight Start cannot be greater than Weight End',
    pu: 'O início do peso não pode ser maior que o final do peso'
  },
  TheStartValueCannotBeGreaterThanTheEndValue: {
    zh: '开始值不能大于结束值',
    en: 'The start value cannot be greater than the end value',
    pu: 'O valor inicial não pode ser maior que o valor final'
  },
  ThereCantBeDifferentBillingTypesForTheSameChargeType: {
    zh: '相同费用类型不能存在不同计费类型',
    en: 'There can\'t be different billing types for the same charge type',
    pu: 'Não pode haver tipos de faturamento diferentes para o mesmo tipo de cobrança'
  },
  TheCostTypeTheSamePartitionCannotBeTheSame: {
    zh: '分区相同费用类型不能相同',
    en: 'The cost type cannot be the same for the same partition',
    pu: 'O tipo de custo não pode ser o mesmo para a mesma partição'
  },
  ticket: {
    zh: '票',
    en: 'ticket',
    pu: 'bilhete'
  },
  StandardPrice: {
    zh: '标准价',
    en: 'Standard price',
    pu: 'Preço padrão'
  },
  BusinessEntityPrice: {
    zh: '应收主体价',
    en: 'Subject price receivable',
    pu: 'Preço do assunto a receber'
  },
  SitePrice: {
    zh: '网点价',
    en: 'Site price',
    pu: 'Preço de saída'
  },
  WeightOverlap: {
    zh: '重量开始重量结束区间存在交集',
    en: 'There is an overlap in the Weight Start Weight End intervals',
    pu: 'Intervalo entre peso inicial e peso final'
  },
  packageOverlap: {
    zh: '包裹数开始包裹数结束区间存在交集',
    en: 'There is an overlap in the Package count start package count end section',
    pu: 'O número de parcelas começando no final da parcela se intercepta com o número de parcelas terminando no final da parcela'
  },
  Used: {
    zh: '已使用',
    en: 'Used',
    pu: 'Usado'
  },
  NotUsed: {
    zh: '未使用',
    en: 'Not used',
    pu: 'Não utilizado'
  },
  OperationalIP: {
    zh: '操作IP',
    en: 'Operational IP',
    pu: 'Operação ip'
  },
  ActionContent: {
    zh: '操作内容',
    en: 'Action content',
    pu: 'Conteúdo da operação'
  },
  OperatorName: {
    zh: '操作人姓名',
    en: 'Operator name',
    pu: 'Nome do operador'
  },
  OperationTime: {
    zh: '操作时间',
    en: 'Operation time',
    pu: 'Tempo da operação'
  },
  transferable: {
    zh: '转账',
    en: 'transferable',
    pu: 'Transferência'
  },
  ModifyThePerson: {
    zh: '修改人',
    en: 'Modify the person',
    pu: 'Pessoa de modificação'
  },
  ModificationTime: {
    zh: '修改时间',
    en: 'Modification time',
    pu: 'Tempo de modificação'
  },
  TopUpTime: {
    zh: '充值时间',
    en: 'Top-up time',
    pu: 'Tempo de recargo'
  },
  BeBetween2And20CharactersLong: {
    zh: '长度在2到20个字符',
    en: 'Be between 2 and 20 characters long',
    pu: 'Comprimento entre 2 e 20 caracteres'
  },
  BalanceBeforeOperation: {
    zh: '操作前余额',
    en: 'Balance before operation',
    pu: 'Equilíbrio antes da operação'
  },
  BalanceAfterOperation: {
    zh: '操作后余额',
    en: 'Balance after operation',
    pu: 'Saldo após a operação'
  },
  SelectTheExportTime: {
    zh: '选择导出时间',
    en: 'Select the export time',
    pu: 'Selecione o tempo de exportação'
  },
  templatePartition: {
    zh: '模板分区与选择的分区方案不匹配',
    en: 'The template partition does not match the selected partition scheme',
    pu: 'A partição de modelo não corresponde ao esquema de partição selecionado'
  },
  CourierSystemBusiness: {
    zh: '快递订单',
    en: 'Express orders',
    pu: 'Encomendas expressas'
  },
  CollectionSystemBusiness: {
    zh: '揽收订单',
    en: 'Collect orders',
    pu: 'Cobrar pedidos'
  },
  DeliverOrder: {
    zh: '派送订单',
    en: 'Deliver the order',
    pu: 'Pedido de entrega'
  },
  amountPaymentOriginal: {
    zh: '原币种交款金额',
    en: 'Original currency payment amount',
    pu: 'Valor pago na moeda original'
  },
  Currency: {
    zh: '币种',
    en: 'Currency type',
    pu: 'Tipo de moeda'
  },
  SpecifyCustomerPrice: {
    zh: '指定客户价',
    en: 'Specified customer price',
    pu: 'Preço especificado pelo cliente'
  },
  Parent: {
    zh: '父级',
    en: 'Parent',
    pu: 'Pai'
  },
  twoCode: {
    zh: '二字码',
    en: 'Two-word code',
    pu: 'Código de duas palavras'
  },
  TheCharacterLength: {
    zh: '字符长度为',
    en: 'The character length is',
    pu: 'O comprimento do caractere é '
  },
  ImportAddRegion: {
    zh: '导入添加地区',
    en: 'Import Add Region',
    pu: 'Importar Adicionar Região'
  },
  // 其他费用规则
  AddAdditionalRules: {
    zh: '增加其他规则',
    en: 'Add additional rules',
    pu: 'Adicionar regras adicionais'
  },
  BillingRules: {
    zh: '计费规则',
    en: 'Billing rules',
    pu: 'Regras de faturamento'
  },
  PalletHandlingHandlingFee: {
    zh: '托盘操作处理费',
    en: 'Pallet Handling Handling Fee',
    pu: 'Taxa de Manuseio de Paletes'
  },
  TheAmountDeclaredForTheProduct: {
    zh: '商品申报金额',
    en: 'The amount declared for the product',
    pu: 'A quantidade declarada para o produto'
  },
  MinimumStandards: {
    zh: '最低标准',
    en: 'Minimum standards',
    pu: 'Normas mínimas'
  },
  CollectTaxes: {
    zh: '揽收税',
    en: 'Collect taxes',
    pu: 'Recolher impostos'
  },
  InsuranceIncluded: {
    zh: '含保险',
    en: 'Insurance included',
    pu: 'Seguro incluído'
  },
  AdditionalCharges: {
    zh: '加收',
    en: 'Additional charges',
    pu: 'Custos adicionais'
  },
  CourierTax: {
    zh: '快递税',
    en: 'Express tax',
    pu: 'Imposto expresso'
  },
  DeliveryTax: {
    zh: '派件税',
    en: 'Delivery tax',
    pu: 'Imposto de entrega'
  },
  DeliveryFee: {
    zh: '派件费',
    en: 'Delivery fee',
    pu: 'Taxa de entrega'
  },
  StateCityZipCodeLeastOneRequired: {
    zh: '州、城市、邮编，至少一项必填',
    en: 'State, City, Zip Code, at least one is required',
    pu: 'Estado, Cidade, CEP, pelo menos um é necessário'
  },
  PayableOutlets: {
    zh: '应付网点',
    en: 'Payable outlets',
    pu: 'Ponto a Pagar'
  },
  PayablePrincipal: {
    zh: '应付主体',
    en: 'Payable Principal',
    pu: 'Entidade a Pagar'
  },
  NetworkBusinessModel: {
    zh: '网点经营模式',
    en: 'Network business model',
    pu: 'Modelo de Operação para Pontos de Atendimento'
  },
  join: {
    zh: '加盟',
    en: 'join',
    pu: 'Franquia'
  },
  SelfEmployed: {
    zh: '直营',
    en: 'Direct sales',
    pu: 'Operação Própria'
  },
  TheTypeBranchBusiness: {
    zh: '网点业务类型',
    en: 'The type of branch business',
    pu: 'Tipo de Negócio de Pontos'
  },
  TheTypeOutlet: {
    zh: '网点类型',
    en: 'The type of outlet',
    pu: 'Tipo de ponto de rede'
  },
  AbbreviationPayableEntity: {
    zh: '应付主体简称',
    en: 'Abbreviation of the payable entity',
    pu: 'Sigla do Sujeito a Pagar'
  },
  TradingHours: {
    zh: '交易时间',
    en: 'Trading hours',
    pu: 'Horário de Transação'
  },
  PaymentSlipNumber: {
    zh: '交款单号',
    en: 'Payment slip number',
    pu: 'Número do Recibo de Pagamento'
  },
  PaymentAccount: {
    zh: '付款账户',
    en: 'Payment account',
    pu: 'Conta de Pagamento'
  },
  PaymentDetails: {
    zh: '付款明细',
    en: 'Payment details',
    pu: 'Detalhes do Pagamento'
  },
  PayableManagement: {
    zh: '应付管理',
    en: 'Payable management',
    pu: 'Gestão a Pagar'
  },
  GeneratePayables: {
    zh: '生成应付',
    en: 'Generate payables',
    pu: 'Gerar a Pagar'
  },
  QuantityStartTicketNotIncluded: {
    zh: '货量开始（票）(不包含)',
    en: 'Quantity start (ticket) (not included)',
    pu: 'Volume Inicial (Pacotes) (Excluído)'
  },
  EndOfVolumeTicketIncluded: {
    zh: '货量结束（票）(包含)',
    en: 'End of volume (ticket) (included)',
    pu: 'Volume Final (Pacotes) (Incluído)'
  },
  PickUpPrice: {
    zh: '揽收价格',
    en: 'Pick-up price',
    pu: 'Preço de Coleta'
  },
  ExcessKilometreSurcharge: {
    zh: '超出公里加价(每KM)',
    en: 'Excess kilometre surcharge (per KM)',
    pu: 'Taxa Adicional por Quilômetro Excedido (por KM)'
  },
  PleaseSelectTheBusinessTypeFirst: {
    zh: '请先选择业务类型',
    en: 'Please select the business type first',
    pu: 'Selecione o tipo de negócio primeiro'
  },
  PleaseSelectPickUpMethodFirst: {
    zh: '请先选择揽收方式',
    en: 'Please select a pick-up method first',
    pu: 'Selecione um método de coleta primeiro'
  },
  DeliveryDistance: {
    zh: '派送距离',
    en: 'Delivery distance',
    pu: 'Distância de entrega'
  },
  deliveryPostcode: {
    zh: '派送邮编',
    en: '',
    pu: 'Código postal de entrega'
  },
  TypeOfRisk: {
    zh: '风险类型',
    en: 'Type of risk',
    pu: 'Tipo de risco'
  },
  RiskRatio: {
    zh: '风险比例',
    en: 'Risk ratio',
    pu: 'Índice de risco'
  },
  ZipCodeSection: {
    zh: '邮编区间',
    en: 'Zip code section',
    pu: 'Seção CEP'
  },
  RiskAreas: {
    zh: '风险区域',
    en: 'Risk areas',
    pu: 'Áreas de risco'
  },
  DeliveryProvince: {
    zh: '派送省份',
    en: 'Delivery province',
    pu: 'Província de entrega'
  },
  DeliveryCity: {
    zh: '派送城市',
    en: 'Delivery city',
    pu: 'Cidade de entrega'
  },
  ImportDeliveryReferenceTemplate: {
    zh: '导入派送参照模板',
    en: 'Import a delivery reference template',
    pu: 'Importar modelo de referência de entrega'
  },
  TheDeliveryNetworkNotOwnedByTheSupplier: {
    zh: '派送网点不属于所属供应商',
    en: 'The delivery network is not owned by the supplier',
    pu: 'A rede de entrega não é propriedade do fornecedor'
  },
  doesNotBelongTo: {
    zh: '不属于',
    en: 'does not belong to',
    pu: 'não pertence a'
  },
  PercentageInsurancePremium: {
    zh: '保险费比例',
    en: 'Percentage of insurance premium',
    pu: 'Percentagem do prémio de seguro'
  },
  MinimumPremiumStandards: {
    zh: '保险费最低标准',
    en: 'Minimum premium standards',
    pu: 'Normas mínimas em matéria de prémios'
  },
  WhetherToAddICMS: {
    zh: '是否添加ICMS',
    en: 'Whether to add ICMS',
    pu: 'Se deve adicionar ICMS'
  },
  WhetherToAddISS: {
    zh: '是否添加ISS',
    en: 'Whether to add ISS',
    pu: 'Se deve adicionar ISS'
  },
  GRISTaxRates: {
    zh: 'GRIS税率',
    en: 'GRIS Tax Rates',
    pu: 'Alíquotas GRIS'
  },
  PickupCategory: {
    zh: '揽收类别',
    en: 'Pickup category',
    pu: 'Categoria de recolha'
  },
  OrdinaryPickUp: {
    zh: '普通揽收',
    en: 'Ordinary pick-up',
    pu: 'coleta normal'
  },
  StockingAndPickup: {
    zh: '备货揽收',
    en: 'Stocking and pickup',
    pu: 'coleta de SFS'
  },
  BoxCountStartsNotIncluded: {
    zh: '箱数开始(不包含)',
    en: 'Box count starts (not included)',
    pu: 'Número inicial de caixas (exclusivo)'
  },
  EndOfCaseCountIncluded: {
    zh: '箱数结束(包含)',
    en: 'End of Case Count (included)',
    pu: 'Número final de caixas (inclusive)'
  },
  IncreaseThePrice: {
    zh: '增加价格',
    en: 'Increase the price',
    pu: 'Preço addicional'
  },
  OverweightHandlingFee: {
    zh: '超重操作费',
    en: 'Overweight handling fee',
    pu: 'Taxa de excesso de peso'
  },
  SuperHeavy: {
    zh: '超重',
    en: 'Super heavy',
    pu: 'Super pesado'
  },
  TheTypeOfMerchant: {
    zh: '商家类型',
    en: 'The type of merchant',
    pu: 'tipo de seller'
  },
  BasePriceCharter: {
    zh: '基础价格（包车）',
    en: 'Base Price (Charter)',
    pu: 'Preço Base (Conclui o Veículo)'
  },
  BasePrice: {
    zh: '基础价格',
    en: 'Base Price',
    pu: 'Preço Base'
  },
  StandardVolume: {
    zh: '标准货量',
    en: 'Standard volume',
    pu: 'Volume Padrão'
  },
  Overstock: {
    zh: '超货量',
    en: 'Overstock',
    pu: 'Excesso'
  },
  PleaseSelectOperatingModelFirst: {
    zh: '请先选择运营模式',
    en: 'Please select the operating model first',
    pu: 'Selecione o modelo operacional primeiro'
  },
  EmployeeAccountName: {
    zh: '员工账户名',
    en: 'Employee account name',
    pu: 'nome da conta do pessoal'
  },
  ClearTheOffer: {
    zh: '清空报价',
    en: 'Clear the offer',
    pu: 'limpar cotação'
  },
  EmptyCurrentPartition: {
    zh: '清空当前分区',
    en: 'Empty the current partition',
    pu: 'limpar a partição atual'
  },
  EmptyAllPartitions: {
    zh: '清空所有分区',
    en: 'Empty all partitions',
    pu: 'limpar a partição total'
  },
  LengthCantExceed20: {
    zh: '字符长度不能超过20',
    en: 'The length of the characters cannot exceed 20',
    pu: 'O comprimento dos caracteres não pode exceder 20'
  },
  LengthCantExceed200: {
    zh: '字符长度不能超过200',
    en: 'The length of the characters cannot exceed 200',
    pu: 'O comprimento dos caracteres não pode exceder 200'
  },
  tax: {
    zh: '税',
    en: 'tax',
    pu: 'imposto'
  },
  PercentageOfDeclaredAmountMinimumCanBeSet: {
    zh: '申报金额比例（可设置最低）',
    en: 'Percentage of declared amount (minimum can be set)',
    pu: 'Proporção do valor declarado (pode ser configurado o mínimo)'
  },
  OrderAllCostRatioGRIS: {
    zh: '订单所有费用比例',
    en: 'Order All Cost Ratio',
    pu: 'Proporção de todos os custos do pedido'
  },
  ExcessWeightSurcharges: {
    zh: '超重额外费用',
    en: 'Excess weight surcharges',
    pu: 'Custo extra por excesso de peso'
  },
  SpecialRules: {
    zh: '特殊规则',
    en: 'Special Rules',
    pu: 'Regras especiais'
  },
  PalletHandlingChargesAreOverweight: {
    zh: '托盘操作处理费超重',
    en: 'Pallet handling charges are overweight',
    pu: 'As taxas de manuseio de paletes estão acima do peso'
  },
  OverweightHandlingFeeForOverweight: {
    zh: '超重处理费超重',
    en: 'Overweight handling fee for overweight',
    pu: 'Taxa de manuseio de excesso de peso por excesso de peso'
  },
  OverweightHandlingFee1: {
    zh: '超重处理费',
    en: 'Overweight Handling Fee',
    pu: 'Taxa de manuseio com excesso de peso'
  },
  fromTheTaxPriceRatio: {
    zh: '从税价比例',
    en: 'from the tax price ratio',
    pu: 'a partir da relação de preços de impostos'
  },
  FromTheMinimumStandardOfTaxPrice: {
    zh: '从税价最低标准',
    en: 'From the minimum standard of tax price',
    pu: 'A partir do padrão mínimo de preço do imposto'
  },
  adValoremRatio: {
    zh: '从价税比例',
    en: '',
    pu: 'Taxa de imposto sobre o valor agregado'
  },
  adValoremTaxFloor: {
    zh: '从价税最低标准',
    en: '',
    pu: 'Padrão mínimo de imposto sobre o valor agregado'
  },
  minimumStandard: {
    zh: 'GRIS最低标准',
    en: '',
    pu: 'Padrão mínimo do GRIS'
  },
  riskManagementTaxRate: {
    zh: '风险管理费税率',
    en: '',
    pu: 'Taxa de gestão de riscos'
  },
  firstMilePickup: {
    zh: '头程揽收',
    en: 'First-mile pickup',
    pu: 'Colección de la primera milla'
  },
  lastMilePickup: {
    zh: '尾程揽收',
    en: 'Last-mile pickup',
    pu: 'Colección de la última milla'
  },
  roundUp: {
    zh: '向上取整',
    en: '',
    pu: 'Arredondar para cima'
  },
  increase: {
    zh: '增加',
    en: '',
    pu: 'aumento'
  },
  roadTollKG: {
    zh: '过路费/KG',
    en: '',
    pu: 'Pedágio/KG'
  },
  roadTollBRL: {
    zh: '过路费（BRL）',
    en: '',
    pu: 'Pedágio(BRL)'
  },
  IssueFeeBRL: {
    zh: '发行费（BRL）',
    en: '',
    pu: 'Taxa de emissão (BRL)'
  },
  KilometersStartKM: {
    zh: '公里数开始KM(不包含)',
    en: 'Kilometers Start KM(not included)',
    pu: 'Quilometragem início KM(não incluído)'
  },
  MileageEndKM: {
    zh: '公里数结束KM(包含)',
    en: 'Mileage End KM(inclusive)',
    pu: 'Quilometragem final KM(incluído)'
  },
  DriverType: {
    zh: '司机类型',
    en: 'Driver type',
    pu: 'Tipo de condutor'
  },
  DailyTaskCapacityFromBase: {
    zh: '起底日任务量',
    en: 'Daily task capacity from base',
    pu: 'Início dia quantidade de tarefas'
  },
  CostCycle: {
    zh: '费用/周期',
    en: 'Cost/cycle',
    pu: 'Taxa/período'
  },
  BasicDeliveryFee: {
    zh: '基础派送费',
    en: 'Basic delivery fee',
    pu: 'Taxa de entrega básica'
  },
  GuaranteedMinimumSalary: {
    zh: '保底薪',
    en: 'Guaranteed minimum salary',
    pu: 'Salário base seguro'
  },
  ExceedBillingUnitPrice: {
    zh: '超出计费/单价',
    en: 'Exceed billing/unit price',
    pu: 'Excesso de faturamento/preço unitário'
  },
  AttendanceIncentiveBonus: {
    zh: '出勤激励奖金',
    en: 'Attendance incentive bonus',
    pu: 'Bônus de incentivo de atendimento'
  },
  SingleVolumeBonusOverTarget: {
    zh: '超目标单量奖金',
    en: 'Single volume bonus over target',
    pu: 'Bônus de volume único super alvo'
  },
  ExceedingTargetIncentiveBonus: {
    zh: '超目标激励奖金',
    en: 'Exceeding Target Incentive Bonus',
    pu: 'Exceder a meta do bônus de incentivo'
  },
  FuelSubsidy: {
    zh: '燃油补贴',
    en: 'Fuel subsidy',
    pu: 'Subsídio de combustível'
  },
  NoteGuaranteedBase: {
    zh: '注：保底薪和超出计费/单价必须填写其一（可以都填）',
    en: 'Note: Guaranteed base salary and excess charge/unit price must be filled in (can be filled in both)',
    pu: 'Nota: o salário base de garantia e o excesso de faturamento/preço unitário devem ser preenchidos (ambos podem ser preenchidos)'
  },
  PleaseAddDetails: {
    zh: '请添加详情',
    en: 'Please add details',
    pu: 'Por favor adicione detalhes'
  },
  CheckFail: {
    zh: '校验不通过',
    en: 'Check fail',
    pu: 'Falha na verificação'
  },
  MensageiroDeBicicleta: {
    zh: '自行车快递员',
    en: 'Bicycle Courier',
    pu: 'Biciclista'
  },
  CompanyCarDeliveryMan: {
    zh: '公司配车快递员',
    en: 'Company car delivery man',
    pu: 'Entregador com o veículo de empressa'
  },
  LeasingCompanyVehiclesThirdpartyDrivers: {
    zh: '租赁公司车辆给第三方司机',
    en: 'Leasing of company vehicles to third-party drivers',
    pu: 'Aluguel de veículos da empresa para motoristas terceirizados'
  },
  MotorbikeLeasingThirdpartyRiders: {
    zh: '摩托车租赁给第三方骑手',
    en: 'Motorbike leasing to third-party riders',
    pu: 'Aluguel de motocicletas para motoristas terceirizados'
  },
  ThirdpartyRiders: {
    zh: '第三方骑手',
    en: 'Third-party riders',
    pu: 'Motoristas terceirizados'
  },
  ThirdPartyVringyourownvehicleDeliveryDrivers: {
    zh: '第三方自带车派送司机',
    en: 'Third-party bring-your-own-vehicle delivery drivers',
    pu: 'Motoristas terceirizados com carro próprio'
  },
  zongpianshu: {
    zh: '网点入库总票数',
    en: '',
    pu: 'Qtd. total de inbound ao ponto'
  },
  StateOfOutlet: {
    zh: '网点所属州',
    en: '',
    pu: 'Estado do ponto'
  },
  h24: {
    zh: '24小时',
    en: '',
    pu: '24 horas '
  },
  h48: {
    zh: '48小时',
    en: '',
    pu: '48 horas '
  },
  h72: {
    zh: '72小时',
    en: '',
    pu: '72 horas '
  },
  returned: {
    zh: '退件数',
    en: '',
    pu: 'Qtd. de pacotes devolvidos'
  },
  transportWeight: {
    zh: '转运称重(kg)',
    en: '',
    pu: 'Pesar em transferência(kg)'
  },
  shouldDelivered: {
    zh: '理应送达票数',
    en: '',
    pu: 'Qtd. de pacote deve ser entregue'
  },
  rightAmount: {
    zh: '妥投量',
    en: '',
    pu: 'Quantidade de entregas'
  },
  shouldDeliveredTime: {
    zh: '理应送达时间',
    en: '',
    pu: 'Horário em que deve ser entregue'
  },
  InvoiceNumber: {
    zh: '发票号码',
    en: 'Invoice number',
    pu: 'Número da fatura'
  },
  BillingBody: {
    zh: '开票主体',
    en: 'Billing body',
    pu: 'Órgão de faturamento'
  },
  InvoiceStatus: {
    zh: '发票状态',
    en: 'Invoice status',
    pu: 'Status da fatura'
  },
  reopen: {
    zh: '重开',
    en: 'reopen',
    pu: 'reabrir'
  },
  Issn1: {
    zh: 'ISS税(n/(1-税率)*税率)',
    en: '',
    pu: 'Imposto ISS(n/(1-taxa de imposto)*taxa de imposto)'
  },
  Issn: {
    zh: 'ISS税(n*税率)',
    en: '',
    pu: 'Imposto ISS(n*taxa de imposto)'
  },
  isAddIssn1: {
    zh: '是否添加ISS税(n/(1-税率)*税率)',
    en: '',
    pu: 'Imposto ISS(n/(1-taxa de imposto)*taxa de imposto)'
  },
  isAddIssn: {
    zh: '是否添加ISS税(n*税率)',
    en: '',
    pu: 'Adicionar o imposto ISS (n*taxa)'
  },
  numberOrInvoiceNumber: {
    zh: '按业务单号或发票号导出',
    en: '',
    pu: 'Exportar por número do pedido ou número da fatura'
  },
  Inconformity: {
    zh: '不一致',
    en: 'inconformity',
    pu: 'Inconsistente em'
  },
  Remission: {
    zh: '减免',
    en: 'remission',
    pu: 'remissão'
  },
  TaxBase: {
    zh: '税基',
    en: 'tax base',
    pu: 'base tributária'
  },
  ExistenceDocuments: {
    zh: '是否存在文件',
    en: 'Existence of documents',
    pu: 'Existência de documentos'
  },
  '揽收安骏小屋重算(上限10w单)': {
    zh: '揽收安骏小屋重算(上限10w单)',
    en: 'Re-calculation of the collection of the Anjun Hut (up to a maximum of 10w orders)',
    pu: 'Recálculo da cobrança da Anjun Hut (até 10w pedidos)'
  },

  '上门揽收(第三方)重算(上限10w单)': {
    zh: '上门揽收(第三方)重算(上限10w单)',
    en: 'Door-to-door collection (third-party) recalculation (up to 10w orders)',
    pu: 'Recálculo da coleta porta a porta (terceiros) (pedidos de até 10w)'
  },
  '导出账单文件': {
    zh: '导出账单文件',
    en: 'Exporting Billing Files',
    pu: 'Exportação de arquivos de faturamento'
  },
  '实际收款人': {
    zh: '实际收款人',
    en: 'Actual payee',
    pu: 'Recebedor real do pagamento'
  },

  '发票不是成功状态': {
    zh: '发票不是成功状态',
    en: 'Invoices are not in a successful state',
    pu: 'As faturas não estão em um estado de sucesso'
  },
  '已成功的发票 不能进行重开': {
    zh: '已成功的发票 不能进行重开',
    en: 'Successful invoices cannot be reissued',
    pu: 'As faturas bem-sucedidas não podem ser reemitidas'
  },
  '账单导出': {
    zh: '账单导出',
    en: 'Bill Export',
    pu: 'Exportação de faturas'
  },

  '账单明细导出': {
    zh: '账单明细导出',
    en: 'Billing Detail Export',
    pu: 'Exportação de detalhes de faturamento'
  },
  '审核备注': {
    zh: '审核备注',
    en: 'Audit Remarks',
    pu: 'Observações de auditoria'
  },
  '揽收商家提成': {
    zh: '揽收商家提成',
    en: 'Collecting merchant commission',
    pu: 'Cobrança de comissão do comerciante'
  },
  '揽收包裹价格': {
    zh: '揽收包裹价格',
    en: 'Prices for parcel collection',
    pu: 'Preços para coleta de encomendas'
  },
  '最低货量': {
    zh: '最低货量',
    en: 'minimum quantity',
    pu: 'Quantidade mínima'
  },
  '重复': {
    zh: '重复',
    en: 'repeatable',
    pu: 'repetível'
  },
  '个': {
    zh: '个',
    en: 'pcs',
    pu: 'pcs'
  },
  '明细业务类型': {
    zh: '明细业务类型',
    en: 'Breakdown of business types',
    pu: 'Detalhamento dos tipos de negócios'
  },
  '发起付款': {
    zh: '发起付款',
    en: 'Initiation of payment',
    pu: 'Início do pagamento'
  },
  '查询结果': {
    zh: '查询结果',
    en: 'Enquiry results',
    pu: 'Resultados da consulta'
  },
  '付款总金额': {
    zh: '付款总金额',
    en: 'Total payments',
    pu: 'Pagamentos totais'
  },
  '刷新数据': {
    zh: '刷新数据',
    en: 'Refresh data',
    pu: 'Atualizar dados'
  },
  '区间不能重叠': {
    zh: '区间不能重叠',
    en: 'Intervals cannot overlap',
    pu: 'Os intervalos não podem se sobrepor'
  },
  '商家提成': {
    zh: '商家提成',
    en: 'merchant commission',
    pu: 'comissão de comerciantes'
  },
  '包裹价格': {
    zh: '包裹价格',
    en: 'Package Price',
    pu: 'Preço do pacote'
  },
  '揽收操作人': {
    zh: '揽收操作人',
    en: 'collection agent',
    pu: 'agente de cobrança'
  },
  '派送操作人': {
    zh: '派送操作人',
    en: 'dispatch operator',
    pu: 'operador de despacho'
  },
  IncorrectPostalCode: {
    zh: '错误邮编',
    en: 'Incorrect postal code',
    pu: 'CEP incorreto'
  },
  CorrectPostalCode: {
    zh: '正确邮编',
    en: 'Correct postal code',
    pu: 'CEP correto'
  },
  AddPostalCode: {
    zh: '新增邮编',
    en: 'Add postal code',
    pu: 'Adicionar CEP'
  },
  PostalCodeDetails: {
    zh: '编辑邮编',
    en: 'Postal Code Details',
    pu: 'Editar CEP'
  },
  MustBeAPositiveInteger: {
    zh: '必须是正整数',
    en: '',
    pu: 'Deve ser um número inteiro positivo'
  },
  ImportTemplate: {
    zh: '导入模板',
    en: '',
    pu: 'Importar o Modelo'
  },
  StartingFromKMExcluding: {
    zh: '公里数开始KM(不包含)',
    en: '',
    pu: 'Quilometragem Inicial KM (não inclusa)'
  },
  EndOfKilometersKMInclusive: {
    zh: '公里数结束KM(包含)',
    en: '',
    pu: 'Quilometragem Final KM (inclusa)'
  },
  '选择业务时间或业务单号': {
    zh: '选择业务时间或业务单号',
    en: '',
    pu: 'Selecionar o tempo de operação ou o número do pedido'
  },
  '订单信息': {
    zh: '订单信息',
    en: '',
    pu: 'Informações do pedido'
  },
  AddOrder: {
    zh: '添加订单',
    en: 'Add an order',
    pu: 'Adicione um pedido'
  },
  '请添加订单信息': {
    zh: '请添加订单信息',
    en: '',
    pu: 'Por favor, adicione as informações do pedido'
  },
  '是否存在数据': {
    zh: '是否存在数据',
    en: '',
    pu: 'Os dados existem?'
  },
  taxFeeType: {
    zh: '税费类型',
    en: '',
    pu: 'Tipo de imposto'
  },
  detailedCalculation: {
    zh: '详细计算',
    en: '',
    pu: 'Cálculo detalhado'
  },
  basicRate: {
    zh: '基础比例',
    en: 'Basic Rate',
    pu: 'Taxa básica'
  },
  sourceWithholdingTaxSestSenat: {
    zh: '源头代扣税Sest Senat',
    en: '',
    pu: 'Retenção de origem Sest Senat'
  },
  sourceWithholdingTaxINSS: {
    zh: '源头代扣税INSS',
    en: '',
    pu: 'Retenção de origem INSS'
  },
  sourceWithholdingTaxIRRF: {
    zh: '代扣个人所得税IRRF',
    en: '',
    pu: 'Retenção de Imposto de Renda Retido na Fonte (IRRF)'
  },
  exemptionPercentage: {
    zh: '减免比例%',
    en: 'Exemption Percentage %',
    pu: 'Percentual de isenção'
  },
  deductedAmount: {
    zh: '扣除金额',
    en: 'Deducted Amount',
    pu: 'Valor deduzido'
  },
  taxableBasePercentage: {
    zh: '%税基',
    en: '%Taxable Base Percentage',
    pu: '%base de cálculo'
  },
  exemptionRate: {
    zh: '%减免',
    en: '%Exemption Rate ',
    pu: '%de isenção'
  },
  inssExemption11: {
    zh: '减免INSS 11%（PT010）',
    en: 'INSS Exemption 11% (PT010)',
    pu: 'Isenção de INSS 11% (PT010)'
  },
  sestSenatExemption25: {
    zh: '减免Sest Senat 2.5%（PT011）',
    en: 'Sest Senat Exemption 2.5% (PT011)',
    pu: 'Isenção de Sest Senat 2.5% (PT011)'
  },
  incomeTaxIRRF10: {
    zh: '个税IRRF 10%（PT024）',
    en: 'Income Tax IRRF 10% (PT024)',
    pu: 'IRRF 10% (PT024)'
  },
  amountStartInclusive: {
    zh: '金额开始（包含）',
    en: 'Amount Start (Inclusive)',
    pu: 'Valor Inicial (Inclusivo)'
  },
  amountEndExclusive: {
    zh: '金额结束（不包含）',
    en: 'Amount End (Exclusive)',
    pu: 'Valor Final (Exclusivo)'
  },
  taxesPerPeriod: {
    zh: '税费/周期',
    en: 'Taxes/Period',
    pu: 'Taxas/Período'
  },
  '保底价公里数': {
    zh: '保底价公里数',
    en: 'Guaranteed kilometres',
    pu: 'Quilometragem do preço mínimo'
  },
  '报价模式': {
    zh: '报价模式',
    en: 'Quote mode',
    pu: 'Modo de cotação'
  },
  '保底价': {
    zh: '保底价',
    en: 'Guaranteed price',
    pu: 'Preço mínimo'
  },
  '区间取最高': {
    zh: '区间取最高',
    en: 'Take the highest in the interval',
    pu: 'Tomar o mais alto no intervalo'
  },
  '直乘': {
    zh: '直乘',
    en: 'Multiply directly',
    pu: 'Multiplicar diretamente'
  },
  'TheRangeOfKilometersCannotOverlap': {
    zh: '费用类型和车型相同，公里数区间不能存在重叠部分',
    en: '',
    pu: 'O intervalo de quilômetros não pode existir sobreposição'
  },
  TheMinimumMileageCanOnlyBeFilledInOne: {
    zh: '费用类型和车型相同，保底公里数只能填写一个',
    en: '',
    pu: 'O tipo de custo e o tipo de veículo são os mesmos, o número de quilômetros de segurança só pode ser preenchido com um'
  },
  AtLeastOneMinimumMileageMustBeFilledIn: {
    zh: '费用类型和车型相同，保底公里数至少要填写一个',
    en: '',
    pu: 'O tipo de custo e o tipo de veículo são os mesmos, o número de quilômetros de segurança deve ser preenchido pelo menos com um'
  },
  ImportRouteReferenceTableTemplate: {
    zh: '导入路线参照表模板',
    en: '',
    pu: 'Modelo de importação de tabela de referência de rota'
  },
  SettlementNode: {
    zh: '结算节点',
    en: '',
    pu: 'Ponto de Liquidação'
  },
  DeliveryTask: {
    zh: '派送任务',
    en: '',
    pu: 'Tafera de entrega'
  },
  signFor: {
    zh: '签收',
    en: '',
    pu: 'Recebido'
  },
  bankTransfer: {
    zh: '银行转账',
    en: 'bank transfer',
    pu: 'Transferência Bancária'
  },
  ReceivingName: {
    zh: '收款名称',
    en: 'Receiving name',
    pu: 'Nome do Beneficiário'
  },
  CertificateNumber: {
    zh: '证件号码',
    en: 'Certificate number',
    pu: 'Número do documento'
  },
  BankNumber: {
    zh: '银行编号',
    en: 'Bank number',
    pu: 'Código do Banco'
  },
  BranchNumber: {
    zh: '分行号码',
    en: 'Branch number',
    pu: 'Número da agência'
  },
  ReceivingAccount: {
    zh: '收款账户',
    en: 'Receiving account',
    pu: 'Conta do Beneficiário'
  },
  PaymentData: {
    zh: '支付数据',
    en: 'Payment data',
    pu: 'Dados de Pagamento'
  }
};
