
// 登录、注册、忘记密码
const login = {
  anJun: {
    zh: '安骏跨境物流系统',
    en: '',
    pu: 'Sistema Logístico Cross Border de Anjun'
  },
  anjunKD: {
    zh: '安骏快递系统',
    en: '',
    pu: 'Sistema Anjun Express'
  },
  WELCOME: {
    zh: 'WELCOME',
    en: 'WELCOME',
    pu: 'Bem-vindo'
  },
  login: {
    zh: '登录',
    en: '',
    pu: 'Fazer login'
  },
  welcome: {
    zh: '欢迎',
    en: '',
    pu: 'Bem-vindo'
  },
  email: {
    zh: '邮箱',
    en: '',
    pu: 'email'
  },
  emailPrompt: {
    zh: '请输入邮箱',
    en: '',
    pu: 'Por favor digite o email'
  },
  password: {
    zh: '密码',
    en: '',
    pu: 'Senha'
  },
  passwordPrompt: {
    zh: '请输入密码',
    en: '',
    pu: 'Por favor digite a senha'
  },
  loginCode: {
    zh: '验证码',
    en: '',
    pu: 'Código de verificaçaõ'
  },
  inputPrompt: {
    zh: '请输入内容',
    en: '',
    pu: 'Por favor digite conteúdo'
  },
  isForget: {
    zh: '忘记密码？',
    en: '',
    pu: 'Esqueceu a senha?'
  },
  isRegistered1: {
    zh: '第一次登录？点击',
    en: '',
    pu: 'Login pela primeira vez? Clique'
  }, // 第一次登录？点击 注册
  isRegistered2: {
    zh: '注册',
    en: '',
    pu: 'Cadastrar'
  },
  getCode: {
    zh: '获取',
    en: '',
    pu: 'Obter'
  },
  ss: {
    zh: '秒',
    en: '',
    pu: 'segundos'
  },
  toObtain: {
    zh: '重新获取',
    en: '',
    pu: 'obter novamente'
  },
  raLogin: {
    zh: '立即登录',
    en: '',
    pu: 'Fazer login agora'
  },
  // 已有账号，立即登陆
  toLoginPrompt: {
    zh: '已有账号，立即',
    en: '',
    pu: 'Já tenho conta, login de imediato'
  },
  forget: {
    zh: '忘记密码',
    en: '',
    pu: 'Esqueci a senha'
  },
  // 登录时弱密码提示
  pwdTipTop: {
    zh: '提示',
    en: 'tips',
    pu: 'Dica'
  },
  pwdTipContent: {
    zh: '当前密码为弱密码，请更换密码',
    en: '',
    pu: 'A senha atual é fraca, por favor mude-a'
  },
  confirm: {
    zh: '确认',
    en: '',
    pu: 'confirmar'
  },
  changePwd: {
    zh: '修改密码',
    en: 'changePwd',
    pu: 'alterar a senha'
  },
  lginOut: {
    zh: '退出登录',
    en: 'lginOut',
    pu: 'logout'
  },
  inputNumber: {
    zh: '请输入账号',
    en: '',
    pu: 'Por favor digite o número de conta'
  },
  inputRightCode: {
    zh: '请输入正确的验证码',
    en: '',
    pu: 'Por favor digite o código de verificação correto'
  },
  quanxian: {
    zh: '将要跳转的页面没有权限访问',
    en: '',
    pu: 'A página a ser acessada não tem permissão de acesso'
  }

};
export {
  login
};
