import { LoadingProgrammatic } from '@oruga-ui/oruga';

let loading = null;

export function addingLoading() {
  loading = LoadingProgrammatic.open({
    fullPage: true,
    container: null
  });
}

export function closeLoading() {
  if (loading) loading.close();
}
