<script >
import { ref, computed, watch } from '@vue/composition-api';
import { default as SkeletonTable } from '@/components/skeletonTable';
import { default as WrapperHandleError } from '@/components/wrapperHandleError';
import { default as SectionHeader } from '@/components/sectionHeader';
import { default as FormCreateBase } from './form/formCreateBase';
import { default as Actions } from './actions';
import { useModal } from '@/composable/useModal';
import { useGetAllBases } from '@/api/transfer/base/query';
import { formatHours } from '../utils/formatHours';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const filters = ref({
    name: null,
    document: null
  });
  const queryParams = ref({
    document: null,
    name: null,
    currentPage: 1,
    perPage: 10
  });
  watch(filters.value, newFilters => {
    if (queryParams.value.currentPage > 1) {
      queryParams.value = {
        ...newFilters,
        currentPage: 1,
        perPage: 10
      };
    } else {
      queryParams.value = {
        ...queryParams.value,
        ...newFilters
      };
    }
  });
  const {
    data,
    isError,
    isLoading,
    isFetched,
    error
  } = useGetAllBases(queryParams);
  const tableData = computed(() => {
    return data.value?.data?.result ?? data.value;
  });
  const tableTotal = computed(() => {
    return data.value?.data?.activeShippingBaseCompany ?? 1;
  });
  const isLoadingTable = computed(() => {
    return isLoading.value || !isFetched.value;
  });

  /* create base */
  const {
    isOpen: isOpenModalCreate,
    handleVisibility: handleVisibilityModalCreate
  } = useModal();
  function getAddress(row) {
    return `${row.shipping_company_base_address}, ${row.shipping_company_base_address_number}, ${row.shipping_company_base_neighborhood}, ${row.shipping_company_base_municipality} - ${row.shipping_company_base_state}`;
  }
  return {
    formatHours,
    filters,
    queryParams,
    isError,
    error,
    tableData,
    tableTotal,
    isLoadingTable,
    isOpenModalCreate,
    handleVisibilityModalCreate,
    getAddress
  };
};
__sfc_main.components = Object.assign({
  WrapperHandleError,
  SectionHeader,
  SkeletonTable,
  Actions,
  FormCreateBase
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <WrapperHandleError :error="error" :is-error="isError">
    <SectionHeader>
      <template #header>
        <h1 class="header-1">{{ $t('base.base') }}</h1>
        <o-button variant="primary" size="md" @click="handleVisibilityModalCreate">{{ $t('base.createBase') }}</o-button>
      </template>

      <template #filter>
        <o-field :label="$t('base.name')" label-for="name" class="flex-1">
          <o-input id="name" v-model="filters.name" :placeholder="$t('base.namePlaceholder')" />
        </o-field>

        <o-field :label="$t('base.document')" label-for="document" class="flex-1">
          <o-input id="document" v-model="filters.document" :placeholder="$t('base.documentPlaceholder')" />
        </o-field>
      </template>
    </SectionHeader>

    <SkeletonTable :is-loading="isLoadingTable">
      <o-table
        :data="tableData"
        :row-key="'id'"
        bordered
        class="space-m-y-32"
        paginated
        backend-pagination
        :total="tableTotal"
        :per-page="queryParams.perPage"
        :current-page.sync="queryParams.currentPage"
        :pagination-simple="false"
      >
        <o-table-column v-slot="props" field="shipping_company_base" :label="$t('base.base')">
          {{ props.row.shipping_company_base }}
        </o-table-column>

        <o-table-column v-slot="props" field="shipping_company_base_document" :label="$t('base.document')">
          {{ props.row.shipping_company_base_document }}
        </o-table-column>

        <o-table-column v-slot="props" field="shipping_company_base_state_registration" :label="$t('base.stateRegistration')">
          {{ props.row.shipping_company_base_state_registration }}
        </o-table-column>

        <o-table-column v-slot="props" field="shipping_company_base_address" :label="$t('base.address')">
          {{
            getAddress(props.row)
          }}
        </o-table-column>

        <o-table-column v-slot="props" field="shipping_company_base_opening_hours" :label="$t('base.openingHours')">
          {{ formatHours(props.row.shipping_company_base_opening_hours) }}
        </o-table-column>

        <o-table-column label="" width="100">
          <template slot-scope="{row}">
            <Actions :row="row" />
          </template>
        </o-table-column>

        <template #empty>
          {{ $t('base.noResults') }}
        </template>
      </o-table>
    </SkeletonTable>

    <o-modal :active="isOpenModalCreate" width="560" @close="handleVisibilityModalCreate">
      <div>
        <h2 class="header-2">{{ $t('base.createBaseTitle') }}</h2>
        <p class="paragraph">{{ $t('base.createBaseParagraph') }}</p>
      </div>

      <FormCreateBase :close-modal="handleVisibilityModalCreate" />
    </o-modal>
  </WrapperHandleError>
</template>

<style scoped>
.flex-1 {
  flex: 1;
}

</style>
