var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tabs",
    { attrs: { type: "border-card" } },
    [
      _vm.detailsObj.id === 0
        ? _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("WorkCentreTable.add") } },
            [
              _c("add-cmp", {
                attrs: {
                  index: _vm.index,
                  "user-id-and-name-obj": _vm.userIdAndNameObj,
                  "ticket-option": _vm.ticketOption,
                },
                on: { close: _vm.close },
              }),
            ],
            1
          )
        : _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("WorkCentreTable.details") } },
            [
              _c("details-cmp", {
                attrs: {
                  index: _vm.index,
                  "user-id-and-name-obj": _vm.userIdAndNameObj,
                  "details-obj": _vm.detailsObj,
                },
                on: { closeDetailsCmp: _vm.closeDetailsCmp },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }