export const vehicle = {
  vehicleTitle: {
    zh: '车辆',
    en: '',
    pu: 'Veículos'
  },
  createVehicle: {
    zh: '创建车辆',
    en: '',
    pu: 'Criar veículo'
  },
  plateLabel: {
    zh: '车牌',
    en: '',
    pu: 'Placa'
  },
  //   platePlaceholder: {
  //     zh: '按车牌搜索',
  //     en: '',
  //     pu: 'Pesquise pela placa'
  //   },
  statusLabel: {
    zh: '车辆状态',
    en: '',
    pu: 'Status Veículo'
  },
  activeStatus: {
    zh: '活跃车辆',
    en: '',
    pu: 'veículos ativos'
  },
  disabledStatus: {
    zh: '闲置车辆',
    en: '',
    pu: 'veículos inativos'
  },
  rotationStatus: {
    zh: '限行车辆',
    en: '',
    pu: 'veículos em rodízio'
  },
  documentLabel: {
    zh: '个人/法人税号',
    en: '',
    pu: 'Documento'
  },
  modelLabel: {
    zh: '车型',
    en: '',
    pu: 'Modelo'
  },
  brandLabel: {
    zh: '品牌',
    en: '',
    pu: 'Marca'
  },
  bodyTypeLabel: {
    zh: '车身类型',
    en: '',
    pu: 'Tipo do Corpo'
  },
  wheelTypeLabel: {
    zh: '车轮类型',
    en: '',
    pu: 'Tipo de Roda'
  },
  ownerLabel: {
    zh: '所有者',
    en: '',
    pu: 'Dono'
  },
  stateLabel: {
    zh: '州',
    en: '',
    pu: 'Estado'
  },
  outsourcedLabel: {
    zh: '外包',
    en: '',
    pu: 'Terceirizado'
  },
  yes: {
    zh: '是',
    en: '',
    pu: 'Sim'
  },
  no: {
    zh: '否',
    en: '',
    pu: 'Não'
  },
  edit: {
    zh: '编辑',
    en: '',
    pu: 'Editar'
  },
  delete: {
    zh: '删除',
    en: '',
    pu: 'Excluir'
  },
  qrCode: {
    zh: 'QR码',
    en: '',
    pu: 'QRcode'
  },
  restore: {
    zh: '恢复',
    en: '',
    pu: 'Restaurar'
  },
  rntrc: {
    zh: 'RNTRC(货物运输证)',
    en: '',
    pu: 'RNTRC'
  },

  // modal
  createTitle: {
    zh: '创建车辆',
    en: '',
    pu: 'Criando veículo'
  },
  createParagraph: {
    zh: '请填写下方的字段以完成在我们系统中创建车辆的操作。',
    en: '',
    pu: 'Preencha os campos logo abaixo para realizar a criação de um motorista em nosso sistema'
  },
  documentPlaceholder: {
    zh: '输入用户的文件',
    en: '',
    pu: 'Digite o documento do usuário'
  },
  platePlaceholder: {
    zh: '输入车牌号码',
    en: '',
    pu: 'Digite a placa do veículo'
  },
  brandPlaceholder: {
    zh: '输入车辆品牌',
    en: '',
    pu: 'Digite a marca do veículo'
  },
  modelPlaceholder: {
    zh: '输入车辆型号',
    en: '',
    pu: 'Digite o modelo do veículo'
  },
  shedLabel: {
    zh: '仓库',
    en: '',
    pu: 'Barracão'
  },
  shedPlaceholder: {
    zh: '输入仓库名称',
    en: '',
    pu: 'Digite o nome do barracão'
  },
  rntrcPlaceholder: {
    zh: '输入RNTRC',
    en: '',
    pu: 'Digite o RNTRC'
  },
  statePlaceholder: {
    zh: '选择一个州',
    en: '',
    pu: 'Selecione um estado'
  },
  outsourcedDriver: {
    zh: '外包司机',
    en: '',
    pu: 'Motorista terceirizado'
  },
  tractionVehicle: {
    zh: '车辆有牵引力',
    en: '',
    pu: 'Veículo tem tração'
  },
  noResults: {
    zh: '没有结果',
    en: '',
    pu: 'Sem resultados'
  },

  editTitle: {
    zh: '编辑车辆',
    en: '',
    pu: 'Editando veículo'
  },
  editParagraph: {
    zh: '请填写下方的字段以完成在我们系统中编辑车辆的操作。',
    en: '',
    pu: 'Preencha os campos logo abaixo para realizar a edição de um veículo em nosso sistema'
  },
  deleteTitle: {
    zh: '您确定吗？',
    en: '',
    pu: 'Você tem certeza absoluta?'
  },
  deleteParagraph: {
    zh: '此操作无法撤销。它将使车辆从所有与这些数据相关的操作中停用。',
    en: '',
    pu: 'Essa ação não pode ser desfeita. Isso irá desativar o veículo de todas as operações relacionadas a esses dados'
  },
  restoreTitle: {
    zh: '您确定吗？',
    en: '',
    pu: 'Você tem certeza absoluta?'
  },
  restoreParagraph: {
    zh: '此操作将恢复所选车辆的数据，使其在我们的系统中再次变为可用，可进行各种操作。',
    en: '',
    pu: 'Essa ação irá restaurar os dados do veículo selecionado, fazendo com que ele volte a ficar disponível para todo tipo de operação no nosso sistema'
  },
  buttonCancel: {
    zh: '取消',
    en: '',
    pu: 'Cancelar'
  },
  buttonDelete: {
    zh: '是的，我想要删除',
    en: '',
    pu: 'Sim, quero excluir'
  },
  buttonRestore: {
    zh: '是的，我想要恢复',
    en: '',
    pu: 'Sim, quero restaurar'
  },

  kgLabel: {
    zh: '重量（公斤）',
    en: '',
    pu: 'Capacidade (KG)'
  },
  kgPlaceholder: {
    zh: '输入以公斤为单位的容量',
    en: '',
    pu: 'Digite a capacidade em KG'
  },
  m3Label: {
    zh: '容积（立方米）',
    en: '',
    pu: 'Capacidade (m³)'
  },
  m3Placeholder: {
    zh: '输入立方米为单位的容量',
    en: '',
    pu: 'Digite a capacidade em metros cúbicos (m³)'
  },
  wheelTypePlaceholder: {
    zh: '选择一个车轮类型',
    en: '',
    pu: 'Selecione um tipo de roda'
  },
  bodyTypePlaceholder: {
    zh: '选择一个车身类型',
    en: '',
    pu: 'Selecione um tipo de corpo'
  },
  weightLabel: {
    zh: '重量',
    en: '',
    pu: 'Peso'
  },
  weightPlaceholder: {
    zh: '输入重量',
    en: '',
    pu: 'Digite o peso'
  },

  documentOwnerLabel: {
    zh: '文件所有者',
    en: '',
    pu: 'Documento do proprietário'
  },
  documentOwnerPlaceholder: {
    zh: '输入车辆文件',
    en: '',
    pu: 'Digite o documento do veículo'
  },
  stateRegistrationLabel: {
    zh: '州注册',
    en: '',
    pu: 'Registro Estadual'
  },
  stateRegistrationPlaceholder: {
    zh: '输入州注册',
    en: '',
    pu: 'Digite o registro estadual'
  },
  trackerCompanyLabel: {
    zh: '追踪公司',
    en: '',
    pu: 'Empresa de Rastreio'
  },
  trackerCompanyPlaceholder: {
    zh: '输入追踪公司',
    en: '',
    pu: 'Digite a empresa de rastreio'
  },
  trackerCompanyCodeLabel: {
    zh: '追踪公司代码',
    en: '',
    pu: 'Código da Empresa de Rastreio'
  },
  trackerCompanyCodePlaceholder: {
    zh: '输入追踪公司代码',
    en: '',
    pu: 'Digite o código da empresa de rastreio'
  },

  // options
  notApplicable: {
    zh: '不适用',
    en: '',
    pu: 'Não aplicavel'
  },
  closedChest: {
    zh: '封闭箱',
    en: '',
    pu: 'Caixa fechada'
  },
  open: {
    zh: '开放式',
    en: '',
    pu: 'Aberto'
  },
  bulk: {
    zh: '体积货',
    en: '',
    pu: 'Volume'
  },
  containerDoor: {
    zh: '集装箱门',
    en: '',
    pu: 'Porta do contêiner'
  },
  sider: {
    zh: '侧开式',
    en: '',
    pu: 'Sider'
  },
  truck: {
    zh: '卡车',
    en: '',
    pu: 'Caminhão'
  },
  stump: {
    zh: '树桩',
    en: '',
    pu: 'Toco'
  },
  mechanicalHorse: {
    zh: '机械马',
    en: '',
    pu: 'Cavalo mecânico'
  },
  van: {
    zh: '货车',
    en: '',
    pu: 'Van'
  },
  utility: {
    zh: '公用事业',
    en: '',
    pu: 'Utilitário'
  },
  others: {
    zh: '其他',
    en: '',
    pu: 'Outros'
  },

  a: {
    zh: '',
    en: '',
    pu: ''
  }
};
