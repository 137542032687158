var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FormTransportSupply", {
    attrs: {
      "form-data": _vm.formData,
      "handle-next": _vm.handleSubmit,
      "is-last-step": true,
      "is-first-step": true,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }