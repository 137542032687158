var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 4 } }, [
            _c(
              "div",
              { staticClass: "grid-content bg-purple" },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    rows: 20,
                    placeholder: _vm.$t(
                      "GlobalSetObj.PleaseEnterTheWaybillNumber"
                    ),
                  },
                  on: {
                    blur: function ($event) {
                      return _vm.getQueryNumber()
                    },
                  },
                  model: {
                    value: _vm.orderContainer,
                    callback: function ($$v) {
                      _vm.orderContainer = $$v
                    },
                    expression: "orderContainer",
                  },
                }),
                _c("div", { staticStyle: { "margin-top": "10px" } }, [
                  _vm._v(
                    _vm._s(_vm.$t("trajectory.NumberOfQueries")) +
                      "：" +
                      _vm._s(_vm.queryNumber) +
                      "； "
                  ),
                ]),
                _c(
                  "div",
                  { staticStyle: { color: "#f00", "margin-top": "10px" } },
                  [_vm._v(_vm._s(_vm.$t("trajectory.UpTo500Queries")))]
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-top": "10px" },
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.queryOrder()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("operationCenter.Procurar")))]
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-top": "10px" },
                    attrs: { size: "small" },
                    on: { click: _vm.resetInput },
                  },
                  [_vm._v(_vm._s(_vm.$t("operationCenter.reset")))]
                ),
              ],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: 20 } }, [
            _c(
              "div",
              { staticClass: "grid-content bg-purple order-Type" },
              [
                _c(
                  "el-tabs",
                  {
                    attrs: { type: "border-card" },
                    model: {
                      value: _vm.selectedName,
                      callback: function ($$v) {
                        _vm.selectedName = $$v
                      },
                      expression: "selectedName",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      { attrs: { name: "all" } },
                      [
                        _c("template", { slot: "label" }, [
                          _c("i", {
                            staticClass: "iconfont icon-zichan-quanbushebei",
                            staticStyle: {
                              "margin-right": "5px",
                              color: "#0d47a1!important",
                            },
                          }),
                          _c(
                            "span",
                            { staticStyle: { color: "#0d47a1!important" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("trajectory.all")) +
                                  "（" +
                                  _vm._s(_vm.orderNumber.orderAll.length) +
                                  "） "
                              ),
                            ]
                          ),
                        ]),
                        _vm.orderNumber.orderAll.length > 0
                          ? _c(
                              "el-collapse",
                              {
                                attrs: { accordion: "" },
                                model: {
                                  value: _vm.activeName,
                                  callback: function ($$v) {
                                    _vm.activeName = $$v
                                  },
                                  expression: "activeName",
                                },
                              },
                              _vm._l(
                                _vm.orderNumber.orderAll,
                                function (orderItem, orderIndex) {
                                  return _c(
                                    "el-collapse-item",
                                    {
                                      key: orderIndex,
                                      attrs: {
                                        title: orderItem.waybillNumber,
                                        name: orderItem.waybillNumber,
                                      },
                                    },
                                    [
                                      _c("template", { slot: "title" }, [
                                        _c(
                                          "div",
                                          { staticStyle: { display: "flex" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "30px",
                                                    },
                                                  },
                                                  [
                                                    orderItem.tabStatus ===
                                                    "noTrajectory"
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "iconStyle",
                                                            staticStyle: {
                                                              background:
                                                                "#757575",
                                                            },
                                                          },
                                                          [
                                                            _c("span", {
                                                              staticClass:
                                                                "iconfont icon-shalou",
                                                              staticStyle: {
                                                                "font-size":
                                                                  "18px",
                                                                color: "#fff",
                                                              },
                                                            }),
                                                          ]
                                                        )
                                                      : orderItem.tabStatus ===
                                                        "inTransit"
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "iconStyle",
                                                            staticStyle: {
                                                              background:
                                                                "#2196f3",
                                                            },
                                                          },
                                                          [
                                                            _c("span", {
                                                              staticClass:
                                                                "iconfont icon-airTransport",
                                                              staticStyle: {
                                                                "font-size":
                                                                  "16px",
                                                                color: "#fff",
                                                              },
                                                            }),
                                                          ]
                                                        )
                                                      : orderItem.tabStatus ===
                                                        "delivery"
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "iconStyle",
                                                            staticStyle: {
                                                              background:
                                                                "#0d47a1",
                                                            },
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticStyle: {
                                                                width: "19px",
                                                                height: "19px",
                                                                "margin-top":
                                                                  "5px",
                                                              },
                                                              attrs: {
                                                                src: _vm.paisong1,
                                                              },
                                                            }),
                                                          ]
                                                        )
                                                      : orderItem.tabStatus ===
                                                        "signed"
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "iconStyle",
                                                            staticStyle: {
                                                              background:
                                                                "#43a047",
                                                            },
                                                          },
                                                          [
                                                            _c("span", {
                                                              staticClass:
                                                                "iconfont icon-chenggong",
                                                              staticStyle: {
                                                                "font-size":
                                                                  "16px",
                                                                color: "#fff",
                                                              },
                                                            }),
                                                          ]
                                                        )
                                                      : orderItem.tabStatus ===
                                                        "abnormal"
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "iconStyle",
                                                            staticStyle: {
                                                              background:
                                                                "#ff6f00",
                                                            },
                                                          },
                                                          [
                                                            _c("span", {
                                                              staticClass:
                                                                "iconfont icon-yichangtishi",
                                                              staticStyle: {
                                                                "font-size":
                                                                  "18px",
                                                                color: "#fff",
                                                              },
                                                            }),
                                                          ]
                                                        )
                                                      : _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "iconStyle",
                                                            staticStyle: {
                                                              background:
                                                                "#e6a23c",
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-more",
                                                              staticStyle: {
                                                                "font-size":
                                                                  "17px",
                                                                color: "#fff",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "align-items": "center",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            orderItem.waybillNumber
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "align-items": "center",
                                                      "margin-left": "20px",
                                                    },
                                                  },
                                                  [
                                                    orderItem.waybillStatusName
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              orderItem.waybillStatusName
                                                            )
                                                          ),
                                                        ])
                                                      : orderItem.tabStatus ===
                                                        "noTrajectory"
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "trajectory.NoDataWasQueried"
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : orderItem.tabStatus ===
                                                        "other"
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "GlobalSetObj.other"
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : orderItem.tabStatus ===
                                                        "inTransit"
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "trajectory.delivering"
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : orderItem.tabStatus ===
                                                        "delivery"
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "trajectory.awaitingForPickUp"
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : orderItem.tabStatus ===
                                                        "signed"
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "trajectory.Signed"
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : orderItem.tabStatus ===
                                                        "abnormal"
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "trajectory.mayBeAbnormal"
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                orderItem.trackNodes &&
                                                orderItem.trackNodes.length > 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "20px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              orderItem
                                                                .trackNodes[0]
                                                                .status
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-left": "20px",
                                                  width: "130px",
                                                  display: "flex",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                orderItem.dateTime
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          orderItem.dateTime
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            ),
                                            orderItem.trackNodes &&
                                            orderItem.trackNodes.length > 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "20px",
                                                      display: "flex",
                                                      "align-items": "center",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          orderItem
                                                            .trackNodes[0]
                                                            .address
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]),
                                      orderItem.trackNodes &&
                                      orderItem.trackNodes.length > 0
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "border-top": "1px solid #ccc",
                                                "padding-top": "20px",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-timeline",
                                                _vm._l(
                                                  orderItem.trackNodes,
                                                  function (item, index) {
                                                    return _c(
                                                      "el-timeline-item",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          color:
                                                            index === 0
                                                              ? "#409EFF"
                                                              : "",
                                                          placement: "top",
                                                          timestamp:
                                                            item.dateTime +
                                                            "；  " +
                                                            item.status +
                                                            "；  " +
                                                            item.address,
                                                        },
                                                      }
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { name: "notFound" } },
                      [
                        _c("template", { slot: "label" }, [
                          _c("i", {
                            staticClass: "iconfont icon-shalou",
                            staticStyle: {
                              "margin-right": "5px",
                              color: "#757575",
                            },
                          }),
                          _c("span", { staticStyle: { color: "#757575" } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("trajectory.NoDataWasQueried")) +
                                "（" +
                                _vm._s(_vm.orderNumber.notFound.length) +
                                "）"
                            ),
                          ]),
                        ]),
                        _vm.orderNumber.notFound.length > 0
                          ? _c(
                              "el-collapse",
                              {
                                attrs: { accordion: "" },
                                model: {
                                  value: _vm.activeName,
                                  callback: function ($$v) {
                                    _vm.activeName = $$v
                                  },
                                  expression: "activeName",
                                },
                              },
                              _vm._l(
                                _vm.orderNumber.notFound,
                                function (orderItem, orderIndex) {
                                  return _c(
                                    "el-collapse-item",
                                    {
                                      key: orderIndex,
                                      attrs: {
                                        title: orderItem.waybillNumber,
                                        name: orderItem.waybillNumber,
                                      },
                                    },
                                    [
                                      _c("template", { slot: "title" }, [
                                        _c(
                                          "div",
                                          { staticStyle: { display: "flex" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "30px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "iconStyle",
                                                        staticStyle: {
                                                          background: "#757575",
                                                        },
                                                      },
                                                      [
                                                        _c("span", {
                                                          staticClass:
                                                            "iconfont icon-shalou",
                                                          staticStyle: {
                                                            "font-size": "18px",
                                                            color: "#fff",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "align-items": "center",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            orderItem.waybillNumber
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "align-items": "center",
                                                      "margin-left": "20px",
                                                    },
                                                  },
                                                  [
                                                    orderItem.waybillStatusName
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              orderItem.waybillStatusName
                                                            )
                                                          ),
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "trajectory.NoDataWasQueried"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                  ]
                                                ),
                                                orderItem.trackNodes &&
                                                orderItem.trackNodes.length > 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "20px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              orderItem
                                                                .trackNodes[0]
                                                                .status
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-left": "20px",
                                                  width: "130px",
                                                  display: "flex",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                orderItem.dateTime
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          orderItem.dateTime
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            ),
                                            orderItem.trackNodes &&
                                            orderItem.trackNodes.length > 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "20px",
                                                      display: "flex",
                                                      "align-items": "center",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          orderItem
                                                            .trackNodes[0]
                                                            .address
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    2
                                  )
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { name: "inTransit" } },
                      [
                        _c("template", { slot: "label" }, [
                          _c("i", {
                            staticClass: "iconfont icon-airTransport",
                            staticStyle: {
                              "margin-right": "5px",
                              color: "#2196f3",
                            },
                          }),
                          _c("span", { staticStyle: { color: "#2196f3" } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("trajectory.delivering")) +
                                "（" +
                                _vm._s(_vm.orderNumber.inTransit.length) +
                                "）"
                            ),
                          ]),
                        ]),
                        _vm.orderNumber.inTransit.length > 0
                          ? _c(
                              "el-collapse",
                              {
                                attrs: { accordion: "" },
                                model: {
                                  value: _vm.activeName,
                                  callback: function ($$v) {
                                    _vm.activeName = $$v
                                  },
                                  expression: "activeName",
                                },
                              },
                              _vm._l(
                                _vm.orderNumber.inTransit,
                                function (orderItem, orderIndex) {
                                  return _c(
                                    "el-collapse-item",
                                    {
                                      key: orderIndex,
                                      attrs: {
                                        title: orderItem.waybillNumber,
                                        name: orderItem.waybillNumber,
                                      },
                                    },
                                    [
                                      _c("template", { slot: "title" }, [
                                        _c(
                                          "div",
                                          { staticStyle: { display: "flex" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "30px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "iconStyle",
                                                        staticStyle: {
                                                          background: "#2196f3",
                                                        },
                                                      },
                                                      [
                                                        _c("span", {
                                                          staticClass:
                                                            "iconfont icon-airTransport",
                                                          staticStyle: {
                                                            "font-size": "16px",
                                                            color: "#fff",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "align-items": "center",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            orderItem.waybillNumber
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "align-items": "center",
                                                      "margin-left": "20px",
                                                    },
                                                  },
                                                  [
                                                    orderItem.waybillStatusName
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              orderItem.waybillStatusName
                                                            )
                                                          ),
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "trajectory.delivering"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                  ]
                                                ),
                                                orderItem.trackNodes &&
                                                orderItem.trackNodes.length > 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "20px",
                                                          flex: "1",
                                                          display: "flex",
                                                          "align-items":
                                                            "center",
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                orderItem
                                                                  .trackNodes[0]
                                                                  .status
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-left": "20px",
                                                  width: "130px",
                                                  display: "flex",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(orderItem.dateTime)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            orderItem.trackNodes &&
                                            orderItem.trackNodes.length > 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "20px",
                                                      display: "flex",
                                                      "align-items": "center",
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            orderItem
                                                              .trackNodes[0]
                                                              .address
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]),
                                      orderItem.trackNodes &&
                                      orderItem.trackNodes.length > 0
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "border-top": "1px solid #ccc",
                                                "padding-top": "20px",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-timeline",
                                                _vm._l(
                                                  orderItem.trackNodes,
                                                  function (item, index) {
                                                    return _c(
                                                      "el-timeline-item",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          color:
                                                            index === 0
                                                              ? "#409EFF"
                                                              : "",
                                                          placement: "top",
                                                          timestamp:
                                                            item.dateTime +
                                                            "；  " +
                                                            item.status +
                                                            "；  " +
                                                            item.address,
                                                        },
                                                      }
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { name: "arriveCollect" } },
                      [
                        _c("template", { slot: "label" }, [
                          _c("img", {
                            staticStyle: {
                              width: "19px",
                              height: "19px",
                              float: "left",
                              "margin-top": "10px",
                              "margin-right": "5px",
                            },
                            attrs: { src: _vm.paisong },
                          }),
                          _c("span", { staticStyle: { color: "#0d47a1" } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("newOrder.Delivering")) +
                                "（" +
                                _vm._s(_vm.orderNumber.arriveCollect.length) +
                                "）"
                            ),
                          ]),
                        ]),
                        _vm.orderNumber.arriveCollect.length > 0
                          ? _c(
                              "el-collapse",
                              {
                                attrs: { accordion: "" },
                                model: {
                                  value: _vm.activeName,
                                  callback: function ($$v) {
                                    _vm.activeName = $$v
                                  },
                                  expression: "activeName",
                                },
                              },
                              _vm._l(
                                _vm.orderNumber.arriveCollect,
                                function (orderItem, orderIndex) {
                                  return _c(
                                    "el-collapse-item",
                                    {
                                      key: orderIndex,
                                      attrs: {
                                        title: orderItem.waybillNumber,
                                        name: orderItem.waybillNumber,
                                      },
                                    },
                                    [
                                      _c("template", { slot: "title" }, [
                                        _c(
                                          "div",
                                          { staticStyle: { display: "flex" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "30px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "iconStyle",
                                                        staticStyle: {
                                                          background: "#0d47a1",
                                                        },
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticStyle: {
                                                            width: "19px",
                                                            height: "19px",
                                                            "margin-top": "5px",
                                                          },
                                                          attrs: {
                                                            src: _vm.paisong1,
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "align-items": "center",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            orderItem.waybillNumber
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "align-items": "center",
                                                      "margin-left": "20px",
                                                    },
                                                  },
                                                  [
                                                    orderItem.waybillStatusName
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              orderItem.waybillStatusName
                                                            )
                                                          ),
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "newOrder.Delivering"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                  ]
                                                ),
                                                orderItem.trackNodes &&
                                                orderItem.trackNodes.length > 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "20px",
                                                          flex: "1",
                                                          display: "flex",
                                                          "align-items":
                                                            "center",
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                orderItem
                                                                  .trackNodes[0]
                                                                  .status
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-left": "20px",
                                                  width: "130px",
                                                  display: "flex",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(orderItem.dateTime)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            orderItem.trackNodes &&
                                            orderItem.trackNodes.length > 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "20px",
                                                      display: "flex",
                                                      "align-items": "center",
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            orderItem
                                                              .trackNodes[0]
                                                              .address
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]),
                                      orderItem.trackNodes &&
                                      orderItem.trackNodes.length > 0
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "border-top": "1px solid #ccc",
                                                "padding-top": "20px",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-timeline",
                                                _vm._l(
                                                  orderItem.trackNodes,
                                                  function (item, index) {
                                                    return _c(
                                                      "el-timeline-item",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          color:
                                                            index === 0
                                                              ? "#409EFF"
                                                              : "",
                                                          placement: "top",
                                                          timestamp:
                                                            item.dateTime +
                                                            "；  " +
                                                            item.status +
                                                            "；  " +
                                                            item.address,
                                                        },
                                                      }
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { name: "successfulSignIn" } },
                      [
                        _c("template", { slot: "label" }, [
                          _c("i", {
                            staticClass: "iconfont icon-chenggong",
                            staticStyle: {
                              "margin-right": "5px",
                              color: "#43a047",
                              "font-size": "13px",
                            },
                          }),
                          _c("span", { staticStyle: { color: "#43a047" } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("trajectory.Signed")) +
                                "（" +
                                _vm._s(
                                  _vm.orderNumber.successfulSignIn.length
                                ) +
                                "）"
                            ),
                          ]),
                        ]),
                        _vm.orderNumber.successfulSignIn.length > 0
                          ? _c(
                              "el-collapse",
                              {
                                attrs: { accordion: "" },
                                model: {
                                  value: _vm.activeName,
                                  callback: function ($$v) {
                                    _vm.activeName = $$v
                                  },
                                  expression: "activeName",
                                },
                              },
                              _vm._l(
                                _vm.orderNumber.successfulSignIn,
                                function (orderItem, orderIndex) {
                                  return _c(
                                    "el-collapse-item",
                                    {
                                      key: orderIndex,
                                      attrs: {
                                        title: orderItem.waybillNumber,
                                        name: orderItem.waybillNumber,
                                      },
                                    },
                                    [
                                      _c("template", { slot: "title" }, [
                                        _c(
                                          "div",
                                          { staticStyle: { display: "flex" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "30px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "iconStyle",
                                                        staticStyle: {
                                                          background: "#43a047",
                                                        },
                                                      },
                                                      [
                                                        _c("span", {
                                                          staticClass:
                                                            "iconfont icon-chenggong",
                                                          staticStyle: {
                                                            "font-size": "16px",
                                                            color: "#fff",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "align-items": "center",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            orderItem.waybillNumber
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "align-items": "center",
                                                      "margin-left": "20px",
                                                    },
                                                  },
                                                  [
                                                    orderItem.waybillStatusName
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              orderItem.waybillStatusName
                                                            )
                                                          ),
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "trajectory.DeliveredSuccessfully"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                  ]
                                                ),
                                                orderItem.trackNodes &&
                                                orderItem.trackNodes.length > 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "20px",
                                                          flex: "1",
                                                          display: "flex",
                                                          "align-items":
                                                            "center",
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                orderItem
                                                                  .trackNodes[0]
                                                                  .status
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-left": "20px",
                                                  width: "130px",
                                                  display: "flex",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(orderItem.dateTime)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            orderItem.trackNodes &&
                                            orderItem.trackNodes.length > 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "20px",
                                                      display: "flex",
                                                      "align-items": "center",
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            orderItem
                                                              .trackNodes[0]
                                                              .address
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]),
                                      orderItem.trackNodes &&
                                      orderItem.trackNodes.length > 0
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "border-top": "1px solid #ccc",
                                                "padding-top": "20px",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-timeline",
                                                _vm._l(
                                                  orderItem.trackNodes,
                                                  function (item, index) {
                                                    return _c(
                                                      "el-timeline-item",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          color:
                                                            index === 0
                                                              ? "#409EFF"
                                                              : "",
                                                          placement: "top",
                                                          timestamp:
                                                            item.dateTime +
                                                            "；  " +
                                                            item.status +
                                                            "；  " +
                                                            item.address,
                                                        },
                                                      }
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { name: "itMayBeAbnormal" } },
                      [
                        _c("template", { slot: "label" }, [
                          _c("i", {
                            staticClass: "iconfont icon-yichangtishi",
                            staticStyle: {
                              "margin-right": "5px",
                              color: "#ff6f00",
                            },
                          }),
                          _c("span", { staticStyle: { color: "#ff6f00" } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("trajectory.mayBeAbnormal")) +
                                "（" +
                                _vm._s(_vm.orderNumber.itMayBeAbnormal.length) +
                                "）"
                            ),
                          ]),
                        ]),
                        _vm.orderNumber.itMayBeAbnormal.length > 0
                          ? _c(
                              "el-collapse",
                              {
                                attrs: { accordion: "" },
                                model: {
                                  value: _vm.activeName,
                                  callback: function ($$v) {
                                    _vm.activeName = $$v
                                  },
                                  expression: "activeName",
                                },
                              },
                              _vm._l(
                                _vm.orderNumber.itMayBeAbnormal,
                                function (orderItem, orderIndex) {
                                  return _c(
                                    "el-collapse-item",
                                    {
                                      key: orderIndex,
                                      attrs: {
                                        title: orderItem.waybillNumber,
                                        name: orderItem.waybillNumber,
                                      },
                                    },
                                    [
                                      _c("template", { slot: "title" }, [
                                        _c(
                                          "div",
                                          { staticStyle: { display: "flex" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "30px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "iconStyle",
                                                        staticStyle: {
                                                          background: "#ff6f00",
                                                        },
                                                      },
                                                      [
                                                        _c("span", {
                                                          staticClass:
                                                            "iconfont icon-yichangtishi",
                                                          staticStyle: {
                                                            "font-size": "18px",
                                                            color: "#fff",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "align-items": "center",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            orderItem.waybillNumber
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "align-items": "center",
                                                      "margin-left": "20px",
                                                    },
                                                  },
                                                  [
                                                    orderItem.waybillStatusName
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              orderItem.waybillStatusName
                                                            )
                                                          ),
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "trajectory.mayBeAbnormal"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                  ]
                                                ),
                                                orderItem.trackNodes &&
                                                orderItem.trackNodes.length > 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "20px",
                                                          flex: "1",
                                                          display: "flex",
                                                          "align-items":
                                                            "center",
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                orderItem
                                                                  .trackNodes[0]
                                                                  .status
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-left": "20px",
                                                  width: "130px",
                                                  display: "flex",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(orderItem.dateTime)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            orderItem.trackNodes &&
                                            orderItem.trackNodes.length > 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "20px",
                                                      display: "flex",
                                                      "align-items": "center",
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            orderItem
                                                              .trackNodes[0]
                                                              .address
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]),
                                      orderItem.trackNodes &&
                                      orderItem.trackNodes.length > 0
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "border-top": "1px solid #ccc",
                                                "padding-top": "20px",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-timeline",
                                                _vm._l(
                                                  orderItem.trackNodes,
                                                  function (item, index) {
                                                    return _c(
                                                      "el-timeline-item",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          color:
                                                            index === 0
                                                              ? "#409EFF"
                                                              : "",
                                                          placement: "top",
                                                          timestamp:
                                                            item.dateTime +
                                                            "；  " +
                                                            item.status +
                                                            "；  " +
                                                            item.address,
                                                        },
                                                      }
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { name: "other" } },
                      [
                        _c("template", { slot: "label" }, [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                padding: "1px 3px",
                                "border-radius": "50%",
                                "margin-right": "5px",
                                background: "#e6a23c",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-more",
                                staticStyle: {
                                  color: "#fff",
                                  "font-size": "12px",
                                },
                              }),
                            ]
                          ),
                          _c("span", { staticStyle: { color: "#e6a23c" } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("GlobalSetObj.other")) +
                                "（" +
                                _vm._s(_vm.orderNumber.other.length) +
                                "）"
                            ),
                          ]),
                        ]),
                        _vm.orderNumber.other.length > 0
                          ? _c(
                              "el-collapse",
                              {
                                attrs: { accordion: "" },
                                model: {
                                  value: _vm.activeName,
                                  callback: function ($$v) {
                                    _vm.activeName = $$v
                                  },
                                  expression: "activeName",
                                },
                              },
                              _vm._l(
                                _vm.orderNumber.other,
                                function (orderItem, orderIndex) {
                                  return _c(
                                    "el-collapse-item",
                                    {
                                      key: orderIndex,
                                      attrs: {
                                        title: orderItem.waybillNumber,
                                        name: orderItem.waybillNumber,
                                      },
                                    },
                                    [
                                      _c("template", { slot: "title" }, [
                                        _c(
                                          "div",
                                          { staticStyle: { display: "flex" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "30px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "iconStyle",
                                                        staticStyle: {
                                                          background: "#e6a23c",
                                                        },
                                                      },
                                                      [
                                                        _c("span", {
                                                          staticClass:
                                                            "el-icon-more",
                                                          staticStyle: {
                                                            "font-size": "17px",
                                                            color: "#fff",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "align-items": "center",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            orderItem.waybillNumber
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "align-items": "center",
                                                      "margin-left": "20px",
                                                    },
                                                  },
                                                  [
                                                    orderItem.waybillStatusName
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              orderItem.waybillStatusName
                                                            )
                                                          ),
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "GlobalSetObj.other"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                  ]
                                                ),
                                                orderItem.trackNodes &&
                                                orderItem.trackNodes.length > 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "20px",
                                                          flex: "1",
                                                          display: "flex",
                                                          "align-items":
                                                            "center",
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                orderItem
                                                                  .trackNodes[0]
                                                                  .status
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-left": "20px",
                                                  width: "130px",
                                                  display: "flex",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(orderItem.dateTime)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            orderItem.trackNodes &&
                                            orderItem.trackNodes.length > 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "20px",
                                                      display: "flex",
                                                      "align-items": "center",
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            orderItem
                                                              .trackNodes[0]
                                                              .address
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]),
                                      orderItem.trackNodes &&
                                      orderItem.trackNodes.length > 0
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "border-top": "1px solid #ccc",
                                                "padding-top": "20px",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-timeline",
                                                _vm._l(
                                                  orderItem.trackNodes,
                                                  function (item, index) {
                                                    return _c(
                                                      "el-timeline-item",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          color:
                                                            index === 0
                                                              ? "#409EFF"
                                                              : "",
                                                          placement: "top",
                                                          timestamp:
                                                            item.dateTime +
                                                            "；  " +
                                                            item.status +
                                                            "；  " +
                                                            item.address,
                                                        },
                                                      }
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }