<template>
  <div id="printBagNumber" ref="printBagNumber" class="fontSizeStyle">
    <div
      ref="printBagNumberContent"
      class="printBagNumberContent"
    >
      <!--  头部    -->
      <div class="headerBag">
        <div style="font-size: 25px;font-weight: bold">{{ paramData.providerName || '--' }}</div>
        <div>
          <div>{{ paramData.time || '--' }}</div>
          <div>{{ paramData.timeSs || '--' }}</div>
        </div>
      </div>
      <!--   内容部分   -->
      <div class="bagContent">
        <div style="text-align: center;font-size: 22px;padding-top: 10px;">Informações da Saca</div>
        <div style="text-align: center;font-size: 38px;margin: 20px 0;font-weight: bold;"> {{
          paramData.threeCode || '--'
        }}
        </div>
        <div>
          <span style="margin-right:10px;font-size: 18px">{{
            $t('GlobalSetObj.bagNumber')
          }}:</span>
          <span style="font-size: 22px">{{ paramData.bagNumber || '--' }}</span>
        </div>
        <div>
          <span style="margin-right:10px;font-size: 18px">{{
            $t('GlobalSetObj.DeliveryOutlets')
          }}:</span>
          <span style="font-size: 22px">{{ paramData.deliverSiteName || '--' }}</span>
        </div>
        <div>
          <span style="margin-right: 10px;font-size: 18px">{{
            $t('collectionCenter.netAdress')
          }}:</span>
          <span style="font-size: 22px">{{ paramData.siteAddress || '--' }}</span>
        </div>
        <div>
          <span style="margin-right: 10px;font-size: 18px">{{ $t('collectionCenter.fenboCode') }}:</span>
          <span style="font-size: 22px">{{ paramData.departmentCode || '--' }}</span>
        </div>
        <div>
          <span style="margin-right: 10px;font-size: 18px">{{ 'Basecode' }}:</span>
          <span style="font-size: 22px">{{ paramData.baseCodeName || '--' }}</span>
        </div>
      </div>
      <div class="logoAnjun">
        <img :src="require('@/assets/anjunTxt.jpg')" alt="tu" style="width: 150px;">
      </div>
    </div>
  </div>
</template>
<script>
// import JsBarcode from 'jsbarcode';
import printXq from 'print-xq';

export default {
  props: {
    paramData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      dateTime: '',
      total: 0
    };
  },
  created() {
    this.$nextTick(() => {
      setTimeout(() => {
        // JsBarcode('#barcode', this.paramData.departmentCode, {
        //   height: 60
        // });
        printXq({
          printable: 'printBagNumber', // // 文档来源：pdf或图像的url，html元素的id或json数据的对象
          type: 'html', // 可打印的类型。可用的打印选项包括：pdf，html，图像，json和raw-html。
          targetStyles: ['*'], // 库在打印HTML元素时处理任何样式
          font_size: ''
        });
        this.$emit('hiddenBagNumberHtml');
      }, 500);
    });
  }
};
</script>
<style scoped>
.fontSizeStyle * {
  font-size: 16px;
  box-sizing: border-box;
}

.printBagNumberContent {
  position: relative;
  width: 410px;
  /*height: 500px;*/
  margin: 0 auto;
  border: 1px solid #000000;
  box-sizing: border-box;
}

.headerBag {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #000000;
  height: 80px;
  padding: 10px;
}

.bagContent {
  line-height: 35px;
  padding-left: 10px;
  font-size: 16px;
}

#printBagNumber {
  page-break-before: always;
  break-inside: avoid; /* 避免元素内容在列间断开 */
}

.logoAnjun {
  text-align: right;
  margin-right: 5px;
  margin-top: 70px;
}
</style>
