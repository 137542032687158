var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bulkImportBox" },
    [
      _c(
        "div",
        {
          staticStyle: {
            "margin-bottom": "15px",
            "line-height": "28px",
            color: "#000",
            "font-size": "17px",
            "font-weight": "bold",
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("orderImport.demoUpload")) + " ")]
      ),
      _c("div", { staticClass: "downBtnBox" }, [
        _c(
          "div",
          {
            ref: "download1",
            staticClass: "downBtn",
            on: {
              click: function ($event) {
                return _vm.initDown()
              },
            },
          },
          [
            _c("span", { staticClass: "el-icon-tickets" }),
            _c("span", { staticClass: "downBtnText" }, [
              _vm._v("TPL_ANJUN.xlsx"),
            ]),
            _c("span", { staticClass: "el-icon-download" }),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "uploadBox" },
        [
          _c(
            "div",
            {
              staticStyle: {
                "margin-bottom": "15px",
                "line-height": "28px",
                color: "#000",
                "font-size": "17px",
                "font-weight": "bold",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("orderImport.orderUpload")) + " ")]
          ),
          _c(
            "el-upload",
            {
              ref: "demoUpload",
              staticClass: "upload-demo",
              attrs: {
                "before-upload": _vm.beforeUpload,
                "http-request": _vm.upLoad,
                limit: 1,
                accept: ".xlsx",
                action: "",
                drag: "",
                multiple: "",
              },
            },
            [
              _c("i", {
                staticClass: "el-icon-plus",
                staticStyle: { margin: "50px auto 20px" },
              }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v(_vm._s(_vm.$t("orderImport.cldrUpload"))),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [_vm._v(_vm._s(_vm.$t("orderImport.onlyXlsx")))]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-bottom": "50px" } },
        [
          _c(
            "div",
            {
              staticStyle: {
                "line-height": "28px",
                color: "#000",
                "font-size": "17px",
                "font-weight": "bold",
                "margin-bottom": "15px",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("orderImport.client")) + " ")]
          ),
          _c(
            "el-select",
            {
              attrs: {
                placeholder: _vm.$t("orderImport.pleaseChoose"),
                clearable: "",
                filterable: "",
              },
              model: {
                value: _vm.chooseCustomer,
                callback: function ($$v) {
                  _vm.chooseCustomer = $$v
                },
                expression: "chooseCustomer",
              },
            },
            _vm._l(_vm.customer, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.username, value: item.id },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-button",
        { staticClass: "primary", on: { click: _vm.handleUpload } },
        [_vm._v(_vm._s(_vm.$t("orderImport.Upload")))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }