<template>
  <div>
    <div class="row" style="margin-bottom: 20px;">
      <!-- 导出 -->
      <el-button
        type="primary"
        size="small"
        :disabled="multipleSelection.length === 0"
        @click="asyncApiClaimformExport"
      >{{ $t('CenterForSorting.export') }}</el-button>

      <TipsCmp :len="tableData.length" :total-value="totalValue" />
    </div>

    <el-table
      :data="tableData"
      :min-height="300"
      :height="480"
      border
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >

      <el-table-column
        type="selection"
        width="55"
      />

      <!--序号-->
      <el-table-column
        type="index" :label="$t('operationCenter.ind')" width="60px"
        align="center"
      />

      <!-- 操作网点 -->
      <el-table-column :label="$t('collectionCenter.OperationSite')" prop="siteName" width="120" />

      <!-- 订单号 -->
      <el-table-column :label="$t('basicData.OrderNumber')" prop="barCode" width="170" />

      <!-- 客户单号 -->
      <el-table-column :label="$t('collectionCenter.customerNum')" prop="customerNumber" width="170" />

      <!-- 揽收类型 -->
      <el-table-column :label="$t('newOrder.CollectionType')" prop="collectTypeStr" />

      <!-- 包裹重量 -->
      <el-table-column :label="$t('collectionCenter.bagWeight')" prop="totalDeclaredWeight" />

      <!-- 申报价值 -->
      <el-table-column :label="$t('orderCenterCont.DeclaredValue')" prop="totalDeclaredValue" />

      <!-- 订单状态 -->
      <el-table-column :label="$t('orderCenterCont.OrderStatus')" prop="statusStr" />

      <!-- 当前轨迹 -->
      <el-table-column :label="$t('ClaimsManagement.CurrentTrajectory')" prop="trajectory" width="170" />

      <!-- 轨迹时间 -->
      <el-table-column :label="$t('ClaimsManagement.TrackTime')" prop="trajectoryTime" width="170" />

      <!-- 备注 -->
      <el-table-column
        :label="$t('GlobalSetObj.remark')" prop="remarks" fixed="right"
        width="200"
      />
    </el-table>
  </div>
</template>

<script>
import OrderTipsCmp from './../OrderTipsCmp.vue';
import { setAddData } from '../Add/utils.js';
import Big from 'big.js';
import { apiClaimformExport } from '@/api/claimsManagement/list';
import { goExportList } from '@/utils/goExportList.js';

export default {
  name: '',

  components: {
    TipsCmp: OrderTipsCmp
  },

  props: {
    ids: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      tableData: [],
      multipleSelection: [] // 选中的数据
    };
  },

  computed: {
    // 总申报价值
    totalValue() {
      let val = 0;
      // return 100;
      if (this.tableData.length !== 0) {
        val = this.tableData.reduce((acc, item) => {
          return new Big(acc).plus(item.totalDeclaredValue || 0);
        }, 0).toNumber();
      }

      return val;
    }
  },

  created() {

  },

  methods: {
    // 回填数据
    setDataList(arr) {
      this.tableData = setAddData(arr);
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 导出
    asyncApiClaimformExport() {
      const idArr = this.multipleSelection.map(item => {
        return item.id;
      });

      const obj = {
        id: this.ids,
        ids: idArr
      };

      apiClaimformExport(obj).then(res => {
        goExportList(this);
      }).catch(err => {
        console.error(err);
      });
    }
  }

};
</script>

<style  scoped>

.row {
  display: flex;
}
</style>
