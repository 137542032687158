var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      staticClass: "demo-ruleForm",
      attrs: { model: _vm.ruleForm, rules: _vm.rules, "label-width": "100px" },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "", prop: "passed" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.ruleForm.passed,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleForm, "passed", $$v)
                },
                expression: "ruleForm.passed",
              },
            },
            [
              _c("el-radio", { attrs: { label: 1 } }, [
                _vm._v(_vm._s(_vm.$t("ClaimsManagement.Pass"))),
              ]),
              _c("el-radio", { attrs: { label: 2 } }, [
                _vm._v(_vm._s(_vm.$t("ClaimsManagement.TurnDown"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("GlobalSetObj.remark"),
            prop: "rejectionReasons",
          },
        },
        [
          _c("el-input", {
            attrs: {
              maxlength: "200",
              type: "textarea",
              "show-word-limit": "",
              placeholder: _vm.$t("GlobalSetObj.pleaseInputContent"),
            },
            model: {
              value: _vm.ruleForm.rejectionReasons,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "rejectionReasons", $$v)
              },
              expression: "ruleForm.rejectionReasons",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
            [_vm._v(_vm._s(_vm.$t("customerManagements.submit")))]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function () {
                  return _vm.onClose(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }