<template>
  <div class="warp">
    <el-form label-width="auto">
      <el-row :gutter="20">
        <el-descriptions :colon="false" :column="3" direction="vertical">
          <!--创建人-->
          <el-descriptions-item :label="$t('GlobalSetObj.CreatePeople')">
            <div style="display: flex;">
              <span style="font-weight: bold">{{ orderwaybillclaim.claimsCreateUserName || '--' }}</span>
            </div>
          </el-descriptions-item>
          <!--理赔状态-->
          <el-descriptions-item :label="$t('ClaimsManagement.ClaimStatus')">
            <div style="display: flex;">
              <span style="font-weight: bold">{{ stataList[orderwaybillclaim.claimsStatus] || '--' }}</span>
            </div>
          </el-descriptions-item>
          <!--审核人-->
          <el-descriptions-item :label="$t('Resource.Reviewer')">
            <div style="display: flex;">
              <span style="font-weight: bold">{{ orderwaybillclaim.auditingUserName || '--' }}</span>
            </div>
          </el-descriptions-item>
          <!--审核时间-->
          <el-descriptions-item :label="$t('receivablePayable.ReviewTime')">
            <div style="display: flex;">
              <span style="font-weight: bold">{{ orderwaybillclaim.auditingTime || '--' }}</span>
            </div>
          </el-descriptions-item>
          <!--付款时间-->
          <el-descriptions-item :label="$t('receivablePayable.PaymentTime')">
            <div>{{ orderwaybillclaim.trancheTime || '--' }}</div>
          </el-descriptions-item>
          <!--付款人-->
          <el-descriptions-item :label="$t('ClaimsManagement.Drawee')">
            <div>{{ orderwaybillclaim.trancheUserName || '--' }}</div>
          </el-descriptions-item>
          <!--付款单号-->
          <el-descriptions-item :label="$t('receivablePayable.PaymentSlipNumber')">
            <div>{{ orderwaybillclaim.trancheNumber || '--' }}</div>
          </el-descriptions-item>
        </el-descriptions>
      </el-row>
    </el-form>
  </div>
</template>

<script>

export default {
  name: 'ClaimInformation',
  props: {
    orderwaybillclaim: {
      type: Object,
      required: true,
      default: () => {
      }
    }
  },
  data() {
    return {
      showDetail: true,
      showDetail1: true,
      showDetail2: true,
      showDetail3: true,
      showDetail4: true,
      showDetail5: true,
      stataList: ['理赔中', '理赔完成', '理赔关闭']
    };
  },
  mounted() {
  },
  methods: {
    handlerhidden(item, value) {
      const buttonPermissions = this.$store.getters.button_permissions;
      const promise = 'field:customer-sensitive-info';
      const hasPromise = buttonPermissions.includes(promise);
      if (hasPromise) {
        if (item === 'name') {
          this.showDetail = !this.showDetail;
        }
        if (item === 'senderPhone') {
          this.showDetail1 = !this.showDetail1;
        }
        if (item === 'senderMobile') {
          this.showDetail2 = !this.showDetail2;
        }
        if (item === 'senderMail') {
          this.showDetail3 = !this.showDetail3;
        }
        if (item === 'senderTax') {
          this.showDetail4 = !this.showDetail4;
        }
        if (item === 'senderCompany') {
          this.showDetail5 = !this.showDetail5;
        }
      } else {
        this.$message.error(this.$t('GlobalSetObj.sensitiveInfoPermission'));
      }
    }
  }
};
</script>

<style scoped>
.warp /deep/ .el-col {
  height: 50px;
}

.iconEye {
  cursor: pointer;
  margin-left: 10px;
}

.warp /deep/ .el-descriptions-item__label {
  font-size: 13px;
  color: #909399;
}

.warp /deep/ .el-descriptions-item__cell {
  /*height: 35px !important;*/
  padding-bottom: 2px !important;
}

.el-descriptions-item__cell div {
  font-weight: bold;
  font-size: 15px;
}
</style>
