<template>
  <div class="warpp">
    <el-card class="box-card">
      <div>
        <el-form
          ref="queryFormRef"
          class="queryFormClass"
          :model="queryForm.params"
          label-width="100px"
          size="small"
          :label-position="$i18n.locale!='zh'?'top':'right'"
          @submit.native.prevent
        >
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item :label="$t('operationCenter.largePackageNumber')">
                <el-input
                  v-model="queryForm.bigBagNo"
                  clearable
                  disabled
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item :label="$t('operationCenter.nextHome')">
                <el-input
                  v-model="queryForm.downstreamName"
                  clearable
                  disabled
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item :label="$t('operationCenter.state')">
                <el-input
                  v-model="radioOb[queryForm.bigBagStatus].name"
                  clearable
                  disabled
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="10">
              <el-form-item :label="$t('operationCenter.Number')">
                <el-input
                  v-model.trim="queryForm.orderNo"
                  clearable
                  :placeholder="$t('operationCenter.PleaseEnterTheContent')"
                  @keyup.enter.native="handleFind"
                />
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item :label="$t('operationCenter.TransferNumber')">
                <el-input
                  v-model.trim="queryForm.logisticsNumber"
                  clearable
                  :placeholder="$t('operationCenter.PleaseEnterTheContent')"
                  @keyup.enter.native="handleFindLogistics"
                />
              </el-form-item>
            </el-col>

          </el-row>

          <el-row type="flex" justify="start">
            <el-col :span="8">
              <div>
                <el-button type="primary" size="mini" @click="handleFind">{{ $t('operationCenter.Procurar') }}</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="queryFormReset">{{ $t('operationCenter.reset') }}</el-button>
              </div>
            </el-col>
          </el-row>
        </el-form>
        <el-divider />

        <el-button
          type="primary"
          :disabled="queryForm.bigBagStatus !== radioOb.unpacked.code"
          @click="handleArchive"
        >{{ $t('operationCenter.SealPreservation') }}</el-button>
        <!-- 已打包 已封袋 状态下可解封 -->
        <!-- <el-button
          type="warning"
          :disabled="(queryForm.bigBagStatus !== radioOb.printed.code)"
          @click="handleRelieve"
        >解封</el-button>
        <el-button
          type="warning"
          :disabled="(queryForm.bigBagStatus !== radioOb.packaged.code)"
          @click="handleRelieve"
        >解封</el-button> -->
        <el-button
          type="warning"
          :disabled="(queryForm.bigBagStatus !== radioOb.printed.code) && (queryForm.bigBagStatus !== radioOb.packaged.code)"
          @click="handleRelieve"
        >{{ $t('operationCenter.Unblock') }}</el-button>

      </div>

      <el-table
        :data="bigBagNoArr"
        style="width: 100%"
        border
        :height="'50vh'"
      >
        <el-table-column
          prop="index"
          :label="$t('operationCenter.ind')"
          width="50"
        />
        <!-- <el-table-column
          prop="bigBagNo"
          label="袋号"
        /> -->
        <el-table-column
          prop="orderNo"
          :label="$t('operationCenter.Number')"
        />
        <el-table-column
          prop="trackingNo"
          :label="$t('operationCenter.TheInboundOrderNumber')"
        />
        <el-table-column
          prop="customerChannelName"
          :label="$t('operationCenter.CustomerChannels')"
        />
        <el-table-column
          prop="receiveCountryName"
          :label="$t('operationCenter.country')"
        />
        <el-table-column
          prop="weight"
          :label="$t('operationCenter.Weight') + 'KG'"
          width="90"
        />
        <el-table-column :label="$t('operationCenter.operate')" width="80">
          <template slot-scope="scope">
            <el-popconfirm
              :title="$t('operationCenter.AreYouSureDelete')"
              @confirm="asyncRemoveSmallBag(scope.$index, scope.row.id)"
            >
              <el-button slot="reference" :disabled="queryForm.bigBagStatus !== radioOb.unpacked.code" type="danger" size="mini">
                {{ $t('operationCenter.Delete') }}</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <div class="mt">
        <el-row class="mb10 mt">
          <el-col :span="2">
            <span class="linheight">{{ $t('operationCenter.Number') }}</span>
          </el-col>
          <el-col :span="10">
            <el-input
              ref="weightRef"
              v-model.trim="otherObj.sumWeight"
              clearable
              :disabled="queryForm.bigBagStatus !== radioOb.unpacked.code"
              :placeholder="$t('operationCenter.PleaseEnterTheContent')"
              @keyup.enter.native="handleAdd"
            />
          </el-col>
          <el-col :span="4">
            <el-button type="primary" :disabled="queryForm.bigBagStatus !== radioOb.unpacked.code" @click="handleAdd">添加</el-button>
          </el-col>
          <el-col :span="2">
            <el-checkbox v-model="otherObj.validateWeightFlag" class="check">{{ $t('operationCenter.whetherToCheckTheWeight') }}</el-checkbox>
          </el-col>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
// import {
//   apiGetSmallBagByBigBagNo,
//   apiDelPackage,
//   apiOverPackage,
//   apiAddPackage,
//   apiCancelBagRelation
// } from '@/api/wms';
import {
  apiGetSmallBagByBigBagNo,
  apiDelPackage,
  apiOverPackage,
  apiAddPackage,
  apiCancelBagRelation
} from '@/api/operationCenter';
import { RADIOOB, DOWNSTREAM } from '@/enums/operationCenterEnums';
import { cloneDeep } from 'lodash-es';

export default {
  props: {
    index: {
      type: Number,
      default: 1
    },
    bigBagNo: {
      type: String,
      default: ''
    },
    bigBagStatus: {
      type: String,
      default: ''
    },
    downstreamCode: {
      type: String,
      default: ''
    },
    ids: {
      type: Number,
      default: 0
    },
    downstreamName: {
      type: String,
      default: ''
    },
    randomVal: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      bigBagNoArr: [], // 大包下的小包
      bigBagNoArrCopy: [], // 大包下的小包 复制一份

      queryForm: {
        orderNo: '', // 小包包号
        bigBagStatus: '', // 大包状态
        downstreamCode: '',
        bigBagNo: '', // 大包号
        logisticsNumber: ''
      },

      detailedObj: {
        customerChannelId: '', // 线路 === 渠道
        bigBagNo: '' // 袋号
      },

      otherObj: {
        sumWeight: '', // 添加的小包号
        dialogTableVisible: false, // 弹出框
        validateWeightFlag: true
      }
    };
  },

  computed: {
    // 获取总重量
    getTotalWeight() {
      const val = this.bigBagNoArr.reduce((accumulator, item) => {
        return accumulator + (item.weight * 1000);
      }, 0);
      return (val / 1000).toFixed(3);
    },

    // 获取总件数
    getTotalSize() {
      return this.bigBagNoArr.length;
    }
  },

  watch: {
    // index: {
    //   handler() {
    //     this.getData();
    //     this.$nextTick(() => {
    //       this.$refs.totalListNoRef.focus();
    //     });
    //   },
    //   immediate: true
    // },
    randomVal: {
      handler(newVal) {
        // this.getData();
        this.asyncGetSmallBagByBigBagNo();
        this.queryForm.bigBagNo = this.bigBagNo;
        this.queryForm.bigBagStatus = this.bigBagStatus;
        this.queryForm.downstreamCode = this.downstreamCode;
        this.queryForm.downstreamName = this.downstreamName;
        // this.queryForm.downstreamCode = this.ids;
        // this.$nextTick(() => {
        //   this.$refs.totalListNoRef.focus();
        // });
      },
      immediate: true
    }
  },

  created() {
    this.radioOb = RADIOOB;
  },

  methods: {

    // 重置
    queryFormReset() {
      this.queryForm.orderNo = '';
      this.queryForm.logisticsNumber = '';
    },

    // 获取大包下的所有小包
    asyncGetSmallBagByBigBagNo() {
      apiGetSmallBagByBigBagNo(this.bigBagNo).then(res => {
        if (res.code !== 200) { return; }
        const data = res.data;
        console.log(data);
        const arr = data.map((item, index) => {
          return {
            ...item,
            index: index + 1,
            id: item.id,
            bigBagNo: item.bigBagNo,
            orderNo: item.orderNo,
            trackingNo: item.trackingNo,
            name: '',
            weight: item.weight
          };
        });
        this.bigBagNoArrCopy = cloneDeep(arr);
        this.bigBagNoArr = arr;
        this.total = arr.length;
      }).catch(err => {
        console.log(err);
      });
    },

    // 删除小包
    asyncRemoveSmallBag(index, ids) {
      apiDelPackage(ids).then(res => {
        if (res.code === 200) {
          this.$message({
            message: this.$i18n.t('operationCenter.DeleteSucceeded'),
            type: 'success'
          });
          this.asyncGetSmallBagByBigBagNo();
          this.$emit('handleUpdata');
        }
      }).catch(err => {
        console.error(err);
      });
    },

    // 添加
    handleAdd() {
      console.log(this.otherObj.sumWeight);
      if (!this.otherObj.sumWeight.length) {
        // this.scanningErr({ type: 'oddNumbers' });
        this.$message.error(this.$i18n.t('operationCenter.PleaseEnterTheContent') + this.$i18n.t('operationCenter.Number'));
        return;
      }

      this.asyncAddPackage(DOWNSTREAM.single_pkg.code);
    },

    // 添加包裹
    asyncAddPackage(packageBagOperateType) {
      const { downstreamCode, bigBagNo } = this.queryForm;
      const { sumWeight, validateWeightFlag } = this.otherObj;
      const obj = {
        bigBagNo: bigBagNo,
        downstreamCode,
        packageBagOperateType,
        // smallBagTrackingNoList: [this.otherObj.sumWeight]
        smallBagNoList: [sumWeight],
        validateWeightFlag
      };

      apiAddPackage(obj).then(res => {
        if (res.code !== 200) { return; }
        const { data: { successList }} = res;

        if (!Array.isArray(successList)) {
          return;
        }

        this.$message({
          message: this.$i18n.t('operationCenter.add') + this.$i18n.t('operationCenter.succeed'),
          type: 'success'
        });
        this.asyncGetSmallBagByBigBagNo();
        this.$emit('handleUpdata');
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        this.otherObj.sumWeight = '';
        setTimeout(() => {
          this.$refs.weightRef.focus();
        }, 1000);
      });
    },

    // 解封
    handleRelieve() {
      apiCancelBagRelation(this.ids).then(res => {
        if (res.code !== 200) { return; }
        this.$message({
          message: this.$i18n.t('operationCenter.Unblock') + this.$i18n.t('operationCenter.succeed'),
          type: 'success'
        });
        this.queryForm.bigBagStatus = this.radioOb.unpacked.code;
        this.$emit('handleUpdata');
      }).catch(err => {
        console.error(err);
      });
    },

    // 封袋保存
    handleArchive() {
      if (this.bigBagNoArr.length === 0) {
        this.$message.error(this.$i18n.t('operationCenter.PleaseAddSmallPacket'));
        return;
      }
      const { bigBagNo } = this.queryForm;
      if (bigBagNo.length === 0) {
        this.$message.error(this.$i18n.t('operationCenter.currentlyNoLargePackage'));
        return;
      }

      apiOverPackage(this.queryForm.bigBagNo).then(res => {
        const { code } = res;
        // if (!data.isSuccess) {
        //   this.$message.error(msg || '封袋失败');
        //   return;
        // }
        if (code !== 200) { return; }
        this.$message({
          message: this.$i18n.t('operationCenter.SealingBags'),
          type: 'success'
        });
        this.queryForm.bigBagStatus = this.radioOb.packaged.code;
        this.$emit('handleUpdata');
      }).catch(err => {
        console.error(err);
      });
    },

    handleBigBagNo() {
      if (this.bigBagNoArr.length === 0) {
        this.bigBagNoArr = cloneDeep(this.bigBagNoArrCopy);
        return;
      }
      this.bigBagNoArr = this.bigBagNoArr.filter(item => {
        if (item.orderNo.includes(this.queryForm.orderNo)) {
          return true;
        }
        return false;
      });
    },

    // 查询 单号
    handleFind() {
      const { orderNo, logisticsNumber } = this.queryForm;
      if ((orderNo === '') && (logisticsNumber === '')) {
        this.asyncGetSmallBagByBigBagNo();
        return;
      }
      if (orderNo === '') {
        this.handleFindLogistics();
        return;
      }
      this.bigBagNoArr = this.bigBagNoArr.filter(item => {
        if (item.orderNo === orderNo) {
          return true;
        }
        return false;
      });
    },

    // 搜索转单号
    handleFindLogistics() {
      const logisticsNumber = this.queryForm.logisticsNumber;
      if (logisticsNumber === '') {
        this.asyncGetSmallBagByBigBagNo();
        return;
      }

      this.bigBagNoArr = this.bigBagNoArr.filter(item => {
        if (item.trackingNo === logisticsNumber) {
          return true;
        }
        return false;
      });
    }
  }
};
</script>

<style scoped>
.warpp {
  padding: 0 10px;
}
.mt {
  margin-top: 10px;
}
.mb {
  margin-bottom: 10px;
}
.linheight {
  line-height: 38px;
}

.ml {
  margin-left: 5px;
}

.check {
  margin-top: 10px;
}
</style>
