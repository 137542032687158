var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper" },
    [
      _c(
        "transition",
        {
          attrs: {
            name: _vm.isTransition ? "fade-transform" : "",
            mode: "out-in",
          },
          on: {
            "before-leave": function ($event) {
              1
            },
          },
        },
        [
          _c(
            "keep-alive",
            { attrs: { include: _vm.cachedViews } },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }