<template>
  <div class="warp">
    <el-card style="margin-bottom: 20px;">
      <el-input
        v-model="waybillNumber"
        class="positionIcon"
        :placeholder="$t('GlobalSetObj.PleaseScanTrackingNumber')"
        style="width: 500px;"
        @keyup.enter.native="eventEnter()"
      >
        <i slot="suffix" class="iconfont icon-a-ziyuan38" />
      </el-input>
      <div v-if="errMsg" style="color:red;font-size: 14px;margin-top: 3px;">{{ errMsg }}</div>
    </el-card>
    <el-card>
      <div style="margin-bottom: 10px">
        <el-button type="primary" size="small" @click="changeOutlets">{{ $t('GlobalSetObj.ModifyPreDeliveryLocation') }}</el-button>
        <!--更新面单-->
        <!--        <el-button-->
        <!--          v-permit:remove="'btn:logisticsOrders:UpdateTheFaceSheet'"-->
        <!--          size="small"-->
        <!--          type="primary"-->
        <!--          @click="updateFaceSheet()"-->
        <!--        >-->
        <!--          {{ $t('newOrder.UpdateTheFaceSheet') }}-->
        <!--        </el-button>-->
        <el-button
          size="small"
          type="primary"
          @click="downloadTheMenu()"
        >
          {{ $t('newOrder.DownloadFaceSheets') }}
        </el-button>
      </div>
      <el-table ref="scanTable" border :data="tableData" @selection-change="selectionChangeList">
        <el-table-column
          align="center"
          type="selection"
          width="60"
          :selectable="selectable"
        />
        <el-table-column
          v-for="(item, index) in tableHeaderList"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          align="center"
          :min-width="item.cloWidth"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.searchType === 'search'">
              <!--运单号-->
              <div v-if="item.prop === 'waybillNumber'">
                <el-input v-model="queryForm.params.waybillNumberList" @change="searchResources" />
              </div>
              <!--大包号-->
              <div v-if="item.prop === 'transitBagNumber'">
                <el-input v-model="queryForm.params.bagNumberList" @change="searchResources" />
              </div>
              <!--扫描时间-->
              <div v-if="item.prop === 'entryTime'">
                <el-date-picker
                  v-model="timeValue"
                  :clearable="true"
                  :default-time="['00:00:00', '23:59:59']"
                  :format="$i18n.locale=='zh'?zhDate:ptDate"
                  :placeholder="$t('newOrder.SelectDatetime')"
                  range-separator="~"
                  type="datetimerange"
                  style="width: 100%;"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  @change="searchResources"
                />
              </div>
              <!--扫描人-->
              <div v-if="item.prop === 'updateUserName'">
                <el-select
                  v-model="queryForm.params.updateUser"
                  filterable
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  style="width: 100%;"
                  @change="searchResources"
                >
                  <el-option
                    v-for="(list, ind) in customerList"
                    :key="ind"
                    :label="list.realName"
                    :value="list.id"
                  />
                </el-select>
              </div>
            </div>
            <div v-else>
              {{ scope.row[item.prop] }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!--  分页  -->
      <el-pagination
        :current-page="queryForm.pageNumber"
        :page-size="queryForm.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 10px 0;float: right"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <!-- 隐藏提示音 -->
    <div v-show="false">
      <audio ref="errRef" :src="errorMp3" controls="controls" />
      <audio ref="okRef" :src="okMp3" controls="controls" />
    </div>
  </div>
</template>

<script>
import { apiTransferScanStorage, apiTransitWarehousePage } from '@/api/logisticsOrders';
import { getUserPage } from '@/api/user';
import { cloneDeep } from 'lodash-es';
import dayjs from 'dayjs';

const okMp3 = 'https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/static/music/su.wav';
const errorMp3 = 'https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/static/music/print_error.wav';
// 防抖处理
const debounceHandle = (function() {
  let timer;
  return function(callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();
export default {
  name: 'Scan',
  data() {
    return {
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          startEntryTime: '',
          endEntryTime: '',
          waybillNumberList: '',
          bagNumberList: '',
          updateUser: ''
        }
      },
      tableData: [],
      searchForm: {},
      timeValue: [this.getAWeek().startDate + ' 00:00:00', this.getAWeek().endDate + ' 23:59:59'],
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      total: 0,
      waybillNumber: '',
      selectionList: [],
      customerList: [],
      okMp3,
      errorMp3,
      errMsg: '',
      tableHeaderList: [
        // 运单号
        { label: this.$t('newOrder.waybillNumber'), prop: 'waybillNumber', cloWidth: '160' },
        // 袋号
        // { label: this.$t('orderCenterCont.BagNumber'), prop: 'transitBagNumber', cloWidth: '120' },
        // 扫描时间
        { label: this.$t('collectionCenter.scanTime'), prop: 'entryTime', cloWidth: '300' },
        // 扫描人
        { label: this.$t('collectionCenter.scanner'), prop: 'updateUserName', cloWidth: '120' }
      ]
    };
  },
  created() {
    this.searchForm = {
      waybillNumber: this.queryForm.params.waybillNumberList,
      bagNumber: this.queryForm.params.bagNumberList,
      getTime: this.timeValue,
      scanner: this.queryForm.params.updateUser,
      searchType: 'search'
    };
    this.tableData = [this.searchForm];
    this.getScanUserId();
    this.getTableData();
  },
  methods: {
    // 获取操作员
    getScanUserId() {
      const param = {
        pageNumber: 1,
        pageSize: 9999999,
        params: {
          accountState: 1
        }
      };
      getUserPage(param).then(res => {
        if (res.code === 200) {
          this.customerList = res.data.records;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 搜索
    searchResources() {
      this.queryForm.pageNumber = 1;
      this.getTableData();
    },
    // 扫描运单号提交
    eventEnterSubmit() {
      const param = {
        waybillNumber: this.waybillNumber
      };
      apiTransferScanStorage(param).then(res => {
        if (res.status === 'OK') {
          this.$refs.okRef.play();
          this.waybillNumber = '';
          this.errMsg = '';
          this.$emit('scanUpdate');
          this.getTableData();
        } else {
          this.$refs.errRef.play();
          this.errMsg = res.msg;
        }
      }).catch((err) => {
        this.$refs.errRef.play();
        this.errMsg = err.msg;
      });
    },
    // 扫描运单号
    eventEnter() {
      debounceHandle(() => { this.eventEnterSubmit(); }, 500);
    },
    // 禁用复选框
    selectable(row, rowIndex) {
      if (rowIndex === 0) {
        return false;
      } else {
        return true;
      }
    },
    // 获取最近一星期时间
    getAWeek() {
      // 默认最近一周时间
      return {
        endDate: dayjs().format('YYYY-MM-DD'),
        startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD')
      };
    },
    // 下载面单
    downloadTheMenu() {
      const newArray = cloneDeep(this.selectionList);
      const downloadArr = newArray.map((item) => {
        if (item.labelUrl === '' || item.labelUrl === null) {
          item.labelUrl = item.serviceLableUrl;
        }
        return item.labelUrl;
      });
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$i18n.t('orderCenterCont.PleaseSelectTheWaybill')); // 请选择运单
        return;
      }
      if (this.selectionList.length > 200) {
        this.$message.warning(this.$i18n.t('orderCenterCont.TheNumberOfSelectedPolygonsExceedsTheMaximumLimit200')); // 选中面单数超出最大限制：200
        return;
      }
      this.$emit('downloadTheMenuFee', downloadArr, 'scan');
    },
    // 更新面单
    updateFaceSheet() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('orderCenterCont.PleaseSelectTheWaybill'));
        return;
      }
      const orderList = this.selectionList.map(item => {
        return item.waybillNumber;
      });
      this.$emit('updateSheets', orderList, 'scan');
    },
    // 选择数据
    selectionChangeList(data) {
      this.selectionList = data;
    },
    // 打开修改预派送网点弹窗
    changeOutlets() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('orderCenterCont.PleaseSelectTheWaybill'));
        return;
      }
      this.$emit('changeOutletsData', this.selectionList, 'scan');
    },
    // 更新面单后查询
    getListData(data) {
      this.queryForm.pageNumber = 1;
      this.queryForm.params.waybillNumbers = data;
      this.getTableData();
    },
    // 获取列表数据
    getTableData() {
      const param = {
        pageNumber: this.queryForm.pageNumber,
        pageSize: this.queryForm.pageSize,
        params: {
          startEntryTime: '',
          endEntryTime: '',
          waybillNumberList: [],
          bagNumberList: [],
          updateUser: this.queryForm.params.updateUser,
          state: 2
        }
      };
      if (this.timeValue && this.timeValue.length > 0) {
        param.params.startEntryTime = this.timeValue[0];
        param.params.endEntryTime = this.timeValue[1];
      }
      if (this.queryForm.params.waybillNumberList) {
        param.params.waybillNumberList = [this.queryForm.params.waybillNumberList];
      }
      if (this.queryForm.params.bagNumberList) {
        param.params.bagNumberList = [this.queryForm.params.bagNumberList];
      }
      console.log(param, '====param');
      apiTransitWarehousePage(param).then(res => {
        if (res.status === 'OK') {
          this.tableData = [this.searchForm, ...res.data.records];
          this.total = res.data.total;
          this.selectionList = [];
          this.$refs.scanTable.clearSelection();
        }
      });
    },
    // 翻页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getTableData();
    }
  }
};
</script>

<style scoped>
.warp >>> .positionIcon .el-input__suffix {
  right: 10px;
  top: 7px;
}
</style>
