// @ts-check
import i18n from '@/lang';
import { checkIntervalsOverlap } from '@/views/finance/utils.js';
import dayjs from 'dayjs';

export const DIALOG_TEXT = {
  add: i18n.t('routes.新增班次'), // 新增
  edit: i18n.t('GlobalSetObj.edit') // 编辑
};

/** @typedef {import('./types').TCollectTime} TCollectTime */

//
export const SCHEDULE_ROW = {
  id: 0, // 如果新增则为0
  scheduleNumber: '', // 班次编号
  collectTimeList: [] // 揽收时间
};

// 揽收时间校验
export const { COLLECT_TIME_ROW, collectTimeCheck, collectLoadFinishTimeCheck, hub1ArrivalTimeCheck } = (() => {
  /** @type {TCollectTime} */
  const COLLECT_TIME_ROW = {
    collectTime: ['00:00', '01:00'], // 揽收时间
    startCollect: '00:00',
    endCollect: '01:00',
    collectLoadFinishTime: '', // 揽收装车完成时间
    hub1ArrivalTime: '' // HUB1到件时间
  };

  const timeTm = '2000-01-01';
  const overlapMsg = /** @type {string} */ (i18n.t('basicData.区间不能重叠'));
  // 对应揽收时间校验
  const collectTimeCheck = (rule, value, callback, row, list, type) => {
    if (!value) {
      // @ts-ignore
      return callback(new Error(i18n.t('GlobalSetObj.pleaseChoose')));
    }
    // 对应揽收时间大小值校验
    {
      const minTime = dayjs(`${timeTm} ${row.startCollect}:00`).valueOf();
      const maxTime = dayjs(`${timeTm} ${row.endCollect}:00`).valueOf();
      if (minTime >= maxTime) {
        if (type === 1) {
          return callback(new Error(i18n.t('GlobalSetObj.CannotExceedTheEndTime'))); // 开始时间不能大于结束时间
        } else {
          return callback(new Error(i18n.t('GlobalSetObj.endTimeMustBeLarger'))); // 开始时间不能大于结束时间
        }
      }
    }
    if (list.length === 1) {
      return callback();
    }
    // 最大值应该大于 对应揽收装车完成时间
    {
      const { collectLoadFinishTime } = row;
      if (collectLoadFinishTime) {
        // 最大值
        const valMaxVal = dayjs(`${timeTm} ${row.endCollect}:00`).valueOf();
        const bVal = dayjs(`${timeTm} ${collectLoadFinishTime}:00`).valueOf();
        if (bVal <= valMaxVal) {
          return callback(new Error(i18n.t('routes.对应揽收时间不能大于对应揽收装车完成时间'))); // 对应揽收时间不能大于对应揽收装车完成时间
        }
      }
    }

    // 重叠校验
    {
      const arr = list.map(item => {
        // const { collectTime } = item;
        const collectTime = [item.startCollect, item.endCollect];
        // return [`${timeTm} ${dayjs(a[0]).valueOf()}:00`, `${timeTm} ${dayjs(a[1]).valueOf()}:00`];
        return [dayjs(`${timeTm} ${collectTime[0]}:00`).valueOf(), dayjs(`${timeTm} ${collectTime[1]}:00`).valueOf()];
      });
      const isBool = checkIntervalsOverlap(arr, '[]');

      if (isBool) {
        return callback(new Error(overlapMsg));
      }
    }

    callback();
  };

  // 对应揽收装车完成时间 校验
  const collectLoadFinishTimeCheck = (rule, value, callback, row) => {
    if (!value) {
      // @ts-ignore
      return callback(new Error(i18n.t('GlobalSetObj.pleaseChoose')));
    }

    const { hub1ArrivalTime } = row; // collectTime
    const collectTime = [row.startCollect, row.endCollect];
    const bVal = dayjs(`${timeTm} ${value}:00`).valueOf();

    // 不能小于揽收时间的最大值
    if (collectTime && (collectTime.length === 2)) {
      const aMaxVal = dayjs(`${timeTm} ${collectTime[1]}:00`).valueOf();
      const bVal = dayjs(`${timeTm} ${value}:00`).valueOf();

      if (bVal <= aMaxVal) {
        return callback(new Error(i18n.t('routes.CannotBeLessThanTheMaximumValue'))); // 不能小于 对应揽收时间的最大值
      }
    }

    // 不能小于 对应HUB1到件时间
    if (hub1ArrivalTime) {
      const cMaxVal = dayjs(`${timeTm} ${hub1ArrivalTime}:00`).valueOf();

      if (bVal >= cMaxVal) {
        return callback(new Error(i18n.t('routes.correspondingHUB1'))); // 不能大于对应HUB1到件时间
      }
    }

    callback();
  };

  // 对应HUB1到件时间 校验
  const hub1ArrivalTimeCheck = (rule, value, callback, row) => {
    if (!value) {
      // @ts-ignore
      return callback(new Error(i18n.t('GlobalSetObj.pleaseChoose')));
    }

    const { collectLoadFinishTime } = row;

    // 不能小于 对应揽收装车完成时间
    if (collectLoadFinishTime) {
      const bVal = dayjs(`${timeTm} ${collectLoadFinishTime}:00`).valueOf();
      const cVal = dayjs(`${timeTm} ${value}:00`).valueOf();
      if (cVal < bVal) {
        return callback(new Error(i18n.t('routes.CannotBeLessThanTheCorresponding'))); // 不能小于对应揽收装车完成时间
      }
    }

    callback();
  };

  return {
    COLLECT_TIME_ROW,
    collectTimeCheck, collectLoadFinishTimeCheck, hub1ArrivalTimeCheck
  };
})();

