var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "space-p-20 o-container" },
    [
      _c(
        "WrapperHandleError",
        { attrs: { error: _vm.error, "is-error": _vm.isError } },
        [
          _c("SectionHeader", {
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c("h1", { staticClass: "header-1" }, [
                      _vm._v(_vm._s(_vm.$t("seller.seller"))),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "filter",
                fn: function () {
                  return [
                    _c(
                      "o-field",
                      {
                        staticClass: "flex-1",
                        attrs: {
                          label: _vm.$t("seller.sellerName"),
                          "label-for": "name",
                        },
                      },
                      [
                        _c("o-input", {
                          attrs: {
                            id: "name",
                            placeholder: _vm.$t("seller.sellerNamePlaceholder"),
                          },
                          model: {
                            value: _vm.filters.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "name", $$v)
                            },
                            expression: "filters.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "o-field",
                      {
                        staticClass: "flex-1",
                        attrs: {
                          label: _vm.$t("seller.sellerCode"),
                          "label-for": "code",
                        },
                      },
                      [
                        _c("o-input", {
                          attrs: {
                            id: "code",
                            placeholder: _vm.$t("seller.sellerCodePlaceholder"),
                          },
                          model: {
                            value: _vm.filters.code,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "code", $$v)
                            },
                            expression: "filters.code",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "o-field",
                      {
                        staticClass: "flex-1",
                        attrs: {
                          label: _vm.$t("seller.driverStatus"),
                          "label-for": "status",
                        },
                      },
                      [
                        _c(
                          "o-select",
                          {
                            attrs: {
                              id: "status",
                              placeholder: _vm.$t(
                                "seller.driverStatusPlaceholder"
                              ),
                            },
                            model: {
                              value: _vm.filters.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "status", $$v)
                              },
                              expression: "filters.status",
                            },
                          },
                          [
                            _c("option", { domProps: { value: 1 } }, [
                              _vm._v(
                                _vm._s(_vm.$t("seller.registeredSellers"))
                              ),
                            ]),
                            _c("option", { domProps: { value: 0 } }, [
                              _vm._v(
                                _vm._s(_vm.$t("seller.unregisteredSellers"))
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          !!_vm.filters.status
            ? _c("TableDataRegistered", {
                attrs: {
                  filters: _vm.filters,
                  "error-sellers-registered": _vm.errorSellersRegistered,
                },
              })
            : _vm._e(),
          !_vm.filters.status
            ? _c("TableDataNotRegistered", {
                attrs: {
                  filters: _vm.filters,
                  "error-sellers-not-registered": _vm.errorSellersNotRegistered,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }