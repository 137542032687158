<script >
import { ref, defineProps } from '@vue/composition-api';
import { useQueryClient } from '@tanstack/vue-query';
import { default as FormTrailerData } from './steps/formTrailerData.vue';
import { default as FormAdditionalData } from './steps/formAdditionalData.vue';
import { default as FormMeasurementData } from './steps/formMeasurementData.vue';
import { useWizardForm } from '@/composable/useWizardForm';
import { useUpdateTrailer } from '@/api/transport/trailer/query';
const __sfc_main = {};
__sfc_main.props = {
  closeModal: {
    type: Function
  },
  trailer: {
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const STEPS_TOTAL = 3;
  const formData = ref({
    formStep01: {
      document: props.trailer.trailer_documentation,
      licensePlate: props.trailer.trailer_license_plate,
      rntrc: props.trailer.trailer_RNTRC,
      brand: props.trailer.trailer_brand,
      state: props.trailer.trailer_state,
      provider: props.trailer.provider_name,
      outsourced: !!props.trailer.trailer_outsourced
    },
    formStep02: {
      capacityKg: props.trailer.trailer_capacity_kg,
      capacityM3: props.trailer.trailer_capacity_m3,
      bodyType: props.trailer.trailer_body_type,
      weight: props.trailer.trailer_tare_weight
    },
    formStep03: {
      documentOwner: props.trailer.trailer_type_ownership ? props.trailer.trailer_owner_CNPJ : props.trailer.trailer_owner_CPF,
      stateRegistration: props.trailer.trailer_state_registration,
      trackerCompany: props.trailer.trailer_tracker_company_name,
      trackerCompanyCode: props.trailer.trailer_tracker_id
    }
  });
  const queryClient = useQueryClient();
  const {
    mutate,
    isLoading
  } = useUpdateTrailer();
  function handleSubmit() {
    const newTrailer = {
      ...formData.value.formStep01,
      ...formData.value.formStep02,
      ...formData.value.formStep03
    };
    mutate({
      newTrailer,
      id: props.trailer.id
    }, {
      onSuccess() {
        props.closeModal();
      }
    });
  }
  const {
    step,
    handleNextStep,
    handlePreviousStep,
    isFirstStep,
    isLastStep
  } = useWizardForm(formData, handleSubmit, STEPS_TOTAL);
  return {
    formData,
    isLoading,
    step,
    handleNextStep,
    handlePreviousStep,
    isFirstStep,
    isLastStep
  };
};
__sfc_main.components = Object.assign({
  FormTrailerData,
  FormMeasurementData,
  FormAdditionalData
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <FormTrailerData
      v-show="step === 0"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      :form-data.sync="formData.formStep01"
    />

    <FormMeasurementData
      v-show="step === 1"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      :form-data.sync="formData.formStep02"
    />

    <FormAdditionalData
      v-show="step === 2"
      :handle-next="handleNextStep"
      :handle-previous="handlePreviousStep"
      :is-last-step="isLastStep"
      :is-first-step="isFirstStep"
      :form-data.sync="formData.formStep03"
    />

    <o-loading :full-page="true" :active.sync="isLoading" />
  </div>
</template>
