<template>
  <div class="warpp">
    <img src="@/assets/LinkFailure.png" alt="LinkFailure">
    <div>
      <p>当前链接失效/Current link is invalid</p>
      <p @click="$router.go(-1)">返回上一级/Return to the previous level</p>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {

    };
  },

  created() {

  },

  methods: {

  }

};
</script>

<style lang="scss" scoped>
.warpp {
  // width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 1px solid red;
}

div {
  margin-left: 40px;
}

div p:first-child {
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 20px;
}

div p:last-child {
  cursor: pointer;
}
</style>
