var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { background: "#fff", "min-height": "100%" },
    },
    [
      _c(
        "el-form",
        { attrs: { "label-position": "top" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { staticStyle: { height: "70px" }, attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.status") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.all"),
                            clearable: "",
                          },
                          on: { change: _vm.handleChange },
                          model: {
                            value: _vm.queryForm.params.shippingStatus,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "shippingStatus",
                                $$v
                              )
                            },
                            expression: "queryForm.params.shippingStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            key: "1",
                            attrs: {
                              label: _vm.$t("GlobalSetObj.all"),
                              value: "",
                            },
                          }),
                          _c("el-option", {
                            key: "3",
                            attrs: {
                              label: _vm.$t("GlobalSetObj.Carregado"),
                              value: "1",
                            },
                          }),
                          _c("el-option", {
                            key: "4",
                            attrs: {
                              label: _vm.$t("GlobalSetObj.Descarregado"),
                              value: "2",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { height: "70px" }, attrs: { span: 16 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "searchTimeType" } },
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "div",
                          { staticClass: "selectLabel" },
                          [
                            _c(
                              "el-select",
                              {
                                style: _vm.IsWidthTime,
                                attrs: {
                                  placeholder: _vm.$t(
                                    "GlobalSetObj.pleaseChoose"
                                  ),
                                },
                                on: { change: _vm.SwitchTimeType },
                                model: {
                                  value: _vm.queryForm.params.searchTimeType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "searchTimeType",
                                      $$v
                                    )
                                  },
                                  expression: "queryForm.params.searchTimeType",
                                },
                              },
                              [
                                _c("el-option", {
                                  key: "1",
                                  attrs: {
                                    label: _vm.$t("GlobalSetObj.LoadCarTime"),
                                    value: "1",
                                  },
                                }),
                                _c("el-option", {
                                  key: "2",
                                  attrs: {
                                    label: _vm.$t("GlobalSetObj.unCarTime"),
                                    value: "2",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-date-picker", {
                        attrs: {
                          clearable: false,
                          format:
                            _vm.$i18n.locale == "zh" ? _vm.zhDate : _vm.ptDate,
                          placeholder: _vm.$t("newOrder.SelectDatetime"),
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        model: {
                          value: _vm.queryForm.params.createStartTime,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.queryForm.params,
                              "createStartTime",
                              $$v
                            )
                          },
                          expression: "queryForm.params.createStartTime",
                        },
                      }),
                      _vm._v(" ~ "),
                      _c("el-date-picker", {
                        attrs: {
                          clearable: false,
                          format:
                            _vm.$i18n.locale == "zh" ? _vm.zhDate : _vm.ptDate,
                          placeholder: _vm.$t("newOrder.SelectDatetime"),
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        model: {
                          value: _vm.queryForm.params.createEndTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm.params, "createEndTime", $$v)
                          },
                          expression: "queryForm.params.createEndTime",
                        },
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: { height: "70px", margin: "10px 0" },
                  attrs: { span: 9 },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "searchType" } },
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "div",
                          { staticClass: "selectLabel" },
                          [
                            _c(
                              "el-select",
                              {
                                style: _vm.IsWidth,
                                attrs: {
                                  placeholder: _vm.$t(
                                    "GlobalSetObj.pleaseChoose"
                                  ),
                                },
                                on: { change: _vm.SwitchType },
                                model: {
                                  value: _vm.queryForm.params.searchType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "searchType",
                                      $$v
                                    )
                                  },
                                  expression: "queryForm.params.searchType",
                                },
                              },
                              [
                                _c("el-option", {
                                  key: "1",
                                  attrs: {
                                    label: _vm.$t(
                                      "orderCenterCont.TransitBinBagNumber"
                                    ),
                                    value: "1",
                                  },
                                }),
                                _c("el-option", {
                                  key: "2",
                                  attrs: {
                                    label: _vm.$t(
                                      "GlobalSetObj.DeliveryRoutes"
                                    ),
                                    value: "2",
                                  },
                                }),
                                _c("el-option", {
                                  key: "3",
                                  attrs: {
                                    label: _vm.$t("GlobalSetObj.Carregador"),
                                    value: "3",
                                  },
                                }),
                                _c("el-option", {
                                  key: "4",
                                  attrs: {
                                    label: _vm.$t("GlobalSetObj.Descarregador"),
                                    value: "4",
                                  },
                                }),
                                _c("el-option", {
                                  key: "5",
                                  attrs: {
                                    label: _vm.$t(
                                      "GlobalSetObj.licensePlateNumber"
                                    ),
                                    value: "5",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-form-item", { staticClass: "multipleQuery" }, [
                        _c(
                          "div",
                          { staticClass: "searchMultiple" },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: _vm.$t("orderDetails.pleaseInput"),
                                clearable: "",
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.getSearchEnter($event)
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.inputValue,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "inputValue",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "queryForm.params.inputValue",
                              },
                            }),
                            _c(
                              "div",
                              { staticStyle: { width: "40px" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "searchBtn",
                                    on: { click: _vm.getSearchEnter },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont icon-chaxun",
                                      staticStyle: { "font-size": "24px" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "bottom-end",
                                  trigger: "manual",
                                  width: "400",
                                },
                                model: {
                                  value: _vm.showList,
                                  callback: function ($$v) {
                                    _vm.showList = $$v
                                  },
                                  expression: "showList",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "newOrder.PreciseSearch"
                                        ),
                                        rows: 8,
                                        type: "textarea",
                                      },
                                      model: {
                                        value: _vm.searchStr,
                                        callback: function ($$v) {
                                          _vm.searchStr = $$v
                                        },
                                        expression: "searchStr",
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticStyle: { "margin-top": "10px" } },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { size: "small" },
                                            on: { click: _vm.clearSearch },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("newOrder.empty"))
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticStyle: { float: "right" } },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { size: "small" },
                                                on: {
                                                  click: _vm.openSearchList,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("orderExport.close")
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  plain: "",
                                                  size: "small",
                                                  type: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.getSearch(1)
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("newOrder.search")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "btnIcon",
                                    attrs: { slot: "reference" },
                                    on: { click: _vm.openText },
                                    slot: "reference",
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont icon-liebiao1",
                                      staticStyle: { "font-size": "24px" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.searchList.length > 0,
              expression: "searchList.length > 0",
            },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "searchTip" },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: { effect: "dark", placement: "bottom" },
                },
                [
                  _c("template", { slot: "content" }, [
                    _c("pre", [_vm._v(_vm._s(_vm.searchStr))]),
                  ]),
                  _c("div", { staticClass: "searchNumWarp" }, [
                    _c("div", { staticClass: "wordBreak" }, [
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.switchSearchType) +
                            "： " +
                            _vm._s(_vm.searchList.join("，"))
                        ),
                      ]),
                    ]),
                  ]),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "clearSearch", on: { click: _vm.clearSearch } },
                [_c("i", { staticClass: "el-icon-close" })]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.getSearchEnter },
            },
            [_vm._v(_vm._s(_vm.$t("newOrder.search")))]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "info" },
              on: { click: _vm.resetSearch },
            },
            [_vm._v(_vm._s(_vm.$t("newOrder.reset")))]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  _vm.listUnloadingPoints(
                    _vm.$t("GlobalSetObj.ListUnloadingPoints")
                  )
                },
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("GlobalSetObj.ListUnloadingPoints")) + " "
              ),
            ]
          ),
          _c(
            "el-button",
            { attrs: { plain: "", size: "small" }, on: { click: _vm.MDFEBtn } },
            [_vm._v(_vm._s("MDFE"))]
          ),
        ],
        1
      ),
      _c("AnjunTableList", {
        ref: "anjunTable",
        attrs: {
          "format-time": _vm.isTormat,
          "header-list": _vm.tableHeaderList,
          "is-tip": true,
          "table-data": _vm.tableData,
        },
        on: {
          "to-OrderDetail": _vm.toOrderDetail,
          "on-click-text": _vm.onLook,
        },
      }),
      _c("el-pagination", {
        staticStyle: { margin: "10px 0", float: "right" },
        attrs: {
          "current-page": _vm.queryForm.number,
          "page-size": _vm.queryForm.pageSize,
          "page-sizes": [10, 20, 50, 100],
          total: _vm.queryForm.total,
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "show-close": true,
            title: _vm.$t("GlobalSetObj.GeraMDFE"),
            visible: _vm.Mdfeialog,
            width: _vm.IsMdfe,
            top: "15vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.Mdfeialog = $event
            },
            close: _vm.clearForm,
          },
        },
        [
          _c(
            "div",
            { staticClass: "mdfeClass" },
            [
              _c(
                "el-form",
                {
                  key: _vm.Mdfeialog,
                  ref: "mdfeForm",
                  attrs: { model: _vm.mdfeForm, "label-position": "top" },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.NIV") + ":",
                        rules: _vm.tagNumberRuler,
                        prop: "tagNumber",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          directives: [
                            {
                              name: "loadmore",
                              rawName: "v-loadmore",
                              value: _vm.loadmore,
                              expression: "loadmore",
                            },
                          ],
                          staticStyle: { width: "80%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            clearable: "",
                            filterable: "",
                          },
                          on: { change: _vm.handleTagNumber },
                          model: {
                            value: _vm.mdfeForm.tagNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.mdfeForm, "tagNumber", $$v)
                            },
                            expression: "mdfeForm.tagNumber",
                          },
                        },
                        _vm._l(_vm.tagNumberList, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.id,
                              attrs: {
                                label: "" + item.tagNumber,
                                value: item.tagNumber,
                              },
                            },
                            [
                              _c("span", { staticStyle: { float: "left" } }, [
                                _vm._v(_vm._s(item.tagNumber)),
                              ]),
                              _c("span", { staticStyle: { float: "right" } }, [
                                _vm._v(_vm._s(item.createTime)),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.crossCitySize || _vm.cteSize
                ? _c("div", { staticStyle: { "margin-top": "50px" } }, [
                    _c("span", { staticStyle: { "margin-right": "40px" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("GlobalSetObj.Enviepacotes")) +
                          ": " +
                          _vm._s(_vm.crossCitySize)
                      ),
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("GlobalSetObj.Quantidade")) +
                          ": " +
                          _vm._s(_vm.cteSize)
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.MdfeialogClose } },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: _vm.isBtn,
                    type: _vm.isBtn ? "info" : "primary",
                    size: "small",
                  },
                  on: { click: _vm.confirmMdef },
                },
                [_vm._v(" " + _vm._s(_vm.$t("GlobalSetObj.confirm")) + " ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "show-close": true,
            title: _vm.$t("GlobalSetObj.GeraMDFE"),
            visible: _vm.driverMdfeialog,
            width: _vm.IsMdfe,
            top: "15vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.driverMdfeialog = $event
            },
          },
        },
        [
          _c("div", { staticClass: "driverMdfeialogClass" }, [
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _c("div", { staticStyle: { "margin-right": "10px" } }, [
                  _vm._v(_vm._s(_vm.$t("GlobalSetObj.driver"))),
                ]),
                _c(
                  "el-select",
                  {
                    directives: [
                      {
                        name: "loadmore",
                        rawName: "v-loadmore",
                        value: _vm.loadmoreDriver,
                        expression: "loadmoreDriver",
                      },
                    ],
                    staticStyle: { width: "60%" },
                    attrs: {
                      placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                      clearable: "",
                    },
                    on: { change: _vm.handleChange1 },
                    model: {
                      value: _vm.driversId,
                      callback: function ($$v) {
                        _vm.driversId = $$v
                      },
                      expression: "driversId",
                    },
                  },
                  _vm._l(_vm.driversList, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.driverName, value: item.id },
                    })
                  }),
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-left": "10px",
                      cursor: "pointer",
                      color: "#409eff",
                    },
                    on: { click: _vm.addMdfe },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("GlobalSetObj.append")) + " ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { "margin-top": "100px", color: "#a4a7ab" } },
              [_vm._v(_vm._s(_vm.$t("GlobalSetObj.verificarEstado")))]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.MdfeialogClose } },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.confirmMdef1 },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            "append-to-body": true,
            title: _vm.$t("GlobalSetObj.WrappedInsideTheBag"),
            visible: _vm.SummaryDetailsDrawer,
            "custom-class": "drawerClass",
            direction: "ltr",
            size: "55%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.SummaryDetailsDrawer = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                height: "calc(100vh - 67px)",
                "overflow-y": "auto",
                padding: "20px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "packageList" },
                [
                  _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("GlobalSetObj.waybillNumber") +
                          "/" +
                          _vm.$t("GlobalSetObj.serviceLogistics")
                      ) + " "
                    ),
                  ]),
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "50%" },
                      attrs: {
                        placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                        clearable: "",
                      },
                      model: {
                        value: _vm.packageListForm.params.waybillNumber,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.packageListForm.params,
                            "waybillNumber",
                            $$v
                          )
                        },
                        expression: "packageListForm.params.waybillNumber",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-search",
                          type: "primary",
                        },
                        on: { click: _vm.searchPackageList },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-top": "20px" } },
                    [
                      _c("AnjunTableList", {
                        ref: "PackageList",
                        attrs: {
                          "format-time": _vm.isTormat,
                          "has-operation": false,
                          "header-list": _vm.packageTableHeaderList,
                          "is-select-btn": false,
                          "is-tip": true,
                          "table-data": _vm.packageListTableData,
                        },
                      }),
                      _c("el-pagination", {
                        staticStyle: {
                          margin: "10px 0",
                          "text-align": "right",
                        },
                        attrs: {
                          "current-page": _vm.packageListForm.pageNumber,
                          "page-size": _vm.packageListForm.pageSize,
                          "page-sizes": [10, 20, 50, 100],
                          "pager-count": 5,
                          total: _vm.packageListForm.total,
                          background: "",
                          layout: "total, sizes, prev, pager, next",
                        },
                        on: {
                          "size-change": _vm.packageListSizeChange,
                          "current-change": _vm.packageListCurrentChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.title,
            visible: _vm.showListUnloadingPoints,
            width: _vm.IsMdfe,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showListUnloadingPoints = $event
            },
            close: _vm.clearShippingList,
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  key: _vm.showListUnloadingPoints,
                  ref: "UnloadingPointsList",
                  attrs: {
                    model: _vm.UnloadingPointsList,
                    rules: _vm.rulesData,
                    "label-position": "top",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.PleaseSelectVehicle"),
                        prop: "vehicleNumber",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t(
                              "GlobalSetObj.PleaseSelectVehicle"
                            ),
                            clearable: "",
                            filterable: "",
                          },
                          on: { change: _vm.changeVehicleNumber },
                          model: {
                            value: _vm.UnloadingPointsList.vehicleNumber,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.UnloadingPointsList,
                                "vehicleNumber",
                                $$v
                              )
                            },
                            expression: "UnloadingPointsList.vehicleNumber",
                          },
                        },
                        _vm._l(_vm.tableCarNumber, function (item, ind) {
                          return _c("el-option", {
                            key: ind,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.PleaseSelectCarNumber"),
                        prop: "tagNumber",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t(
                              "GlobalSetObj.PleaseSelectCarNumber"
                            ),
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.UnloadingPointsList.tagNumber,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.UnloadingPointsList,
                                "tagNumber",
                                $$v
                              )
                            },
                            expression: "UnloadingPointsList.tagNumber",
                          },
                        },
                        _vm._l(
                          _vm.vehicleIdentificationNumber,
                          function (item) {
                            return _c(
                              "el-option",
                              {
                                key: item.id,
                                attrs: {
                                  label: "" + item.tagNumber,
                                  value: item.tagNumber,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      float: "left",
                                      "margin-right": "30px",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.tagNumber))]
                                ),
                                _c("span", [_vm._v(_vm._s(item.createTime))]),
                              ]
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showListUnloadingPoints = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.clickDistributvehicle(_vm.title)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticStyle: {
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            "z-index": "-100",
          },
        },
        [
          _vm.showShippingList
            ? _c(
                "div",
                { ref: "logisticsNumber" },
                [
                  _c("invoice-html", {
                    ref: "logisticsNumber1",
                    attrs: { "param-data": _vm.paramData },
                    on: { clearShippingList: _vm.clearShippingList },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        {
          staticStyle: {
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            "z-index": "-900",
          },
        },
        [
          _vm.showTransferList
            ? _c("TransferManifestHtml", {
                attrs: { "param-transfer-data": _vm.paramTransferData },
                on: { clearTransferList: _vm.clearTransferList },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            visible: _vm.showShippingMinfisto,
            width: _vm.IsMdfe,
            "element-loading-background": "rgba(250, 250, 250, 0.6)",
            "element-loading-spinner": "el-icon-loading",
            "element-loading-text": "loading...",
            title: "Shipping Manifestos",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showShippingMinfisto = $event
            },
            close: _vm.clearShippingMinfisto,
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  key: _vm.showShippingMinfisto,
                  ref: "ShippingMinfistoObj",
                  attrs: {
                    model: _vm.ShippingMinfistoObj,
                    rules: _vm.rulesShippingData,
                    "label-position": "top",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.PleaseSelectVehicle"),
                        prop: "vehicleNumber",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t(
                              "GlobalSetObj.PleaseSelectVehicle"
                            ),
                            clearable: "",
                            filterable: "",
                          },
                          on: { change: _vm.smchangeVehicleNumber },
                          model: {
                            value: _vm.ShippingMinfistoObj.vehicleNumber,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ShippingMinfistoObj,
                                "vehicleNumber",
                                $$v
                              )
                            },
                            expression: "ShippingMinfistoObj.vehicleNumber",
                          },
                        },
                        _vm._l(_vm.vehicleNumberList, function (item, ind) {
                          return _c("el-option", {
                            key: ind,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.PleaseSelectCarNumber"),
                        prop: "tagNumber",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            disabled: _vm.VehicleTagNumber.length === 0,
                            placeholder: _vm.$t(
                              "GlobalSetObj.PleaseSelectCarNumber"
                            ),
                            clearable: "",
                            filterable: "",
                          },
                          on: { change: _vm.changeTagNumber },
                          model: {
                            value: _vm.ShippingMinfistoObj.tagNumber,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ShippingMinfistoObj,
                                "tagNumber",
                                $$v
                              )
                            },
                            expression: "ShippingMinfistoObj.tagNumber",
                          },
                        },
                        _vm._l(_vm.VehicleTagNumber, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.id,
                              attrs: {
                                label: "" + item.tagNumber,
                                value: item.tagNumber,
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    float: "left",
                                    "margin-right": "30px",
                                  },
                                },
                                [_vm._v(_vm._s(item.tagNumber))]
                              ),
                              _c("span", [_vm._v(_vm._s(item.createTime))]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.errMsg
                    ? _c("div", { staticStyle: { color: "#f56c6c" } }, [
                        _vm._v(_vm._s(_vm.errMsg)),
                      ])
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "driver" } },
                    [
                      _c("template", { slot: "label" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("GlobalSetObj.driver"))),
                        ]),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              float: "right",
                              color: "#409eff",
                              cursor: "pointer",
                            },
                            on: { click: _vm.addMdfe },
                          },
                          [_vm._v(_vm._s(_vm.$t("GlobalSetObj.create")))]
                        ),
                      ]),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            disabled: _vm.isSelect,
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            clearable: "",
                            filterable: "",
                          },
                          on: { change: _vm.changeDrivers },
                          model: {
                            value: _vm.ShippingMinfistoObj.driver,
                            callback: function ($$v) {
                              _vm.$set(_vm.ShippingMinfistoObj, "driver", $$v)
                            },
                            expression: "ShippingMinfistoObj.driver",
                          },
                        },
                        _vm._l(_vm.allDrivers, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.driverName,
                              value: item.driverLicense,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showShippingMinfisto = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.clickShippingMinfisto },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticStyle: {
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            "z-index": "-900",
          },
        },
        [
          _vm.showShippingMinfistoPdf
            ? _c(
                "div",
                { ref: "ShippingMinfistoPdftHtml1" },
                [
                  _c("ShippingMinfistoPdftHtml", {
                    ref: "ShippingMinfistoPdftHtml2",
                    attrs: {
                      "param-shipping-data": _vm.paramShippingMinfistoData,
                    },
                    on: { clearShippingMin: _vm.clearShippingMin },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }