// 葡萄牙-巴西
import { setLang } from './all';

const KEY = 'pu';

const obj = setLang(KEY);

export default {
  ...obj
};
