import { apiMDFE } from '@/lib/axios';
import clearString from '@/utils/clearString';
import { removeNullParams } from '@/utils/removeNullParams';

export const mdfeService = {
  async getAll(params) {
    const urlParam = params.keyMDFE ? `/${params.keyMDFE}` : '';

    const queryParams = params.keyMDFE ? {} : removeNullParams({
      take: params?.take ?? 10,
      ...params
    });

    return await apiMDFE({
      method: 'GET',
      url: `mdfe${urlParam}`,
      params: queryParams
    });
  },
  async create(data) {
    const body = this.formatBody(data);

    return await apiMDFE({
      url: 'mdfe',
      data: body,
      method: 'post'
    });
  },
  async getAllVtag() {
    return await apiMDFE({
      url: 'get-infos-for-mdfe',
      method: 'GET'
    });
  },
  async getInfoVtag(vtag) {
    return await apiMDFE({
      url: 'get-infos-for-mdfe/get-all-packages',
      params: {
        ordensCarga: vtag
      },
      method: 'get'
    });
  },
  async getAllCompanies() {
    return await apiMDFE({
      url: 'get-infos-for-mdfe/get-all-company',
      method: 'GET'
    });
  },
  async getInfoDriver(driver) {
    return await apiMDFE({
      url: 'get-infos-for-mdfe/get-one-driver',
      params: {
        name: driver
      },
      method: 'GET'
    });
  },
  async getInfoCompany(company) {
    return await apiMDFE({
      url: 'get-infos-for-mdfe/get-one-company',
      params: {
        name: company
      },
      method: 'GET'
    });
  },
  async closeMDFE(body) {
    return await apiMDFE({
      url: 'mdfe/close',
      method: 'PATCH',
      data: body
    });
  },
  async cancelMDFE(body) {
    return await apiMDFE({
      url: 'mdfe',
      method: 'DELETE',
      data: body
    });
  },
  formatBody(data) {
    const clearDocumentOwnerVehicle = clearString(data.formStepSenderInformation.documentOwnerVehicle);

    const isDocumentOwnerVeicleCNPJ = clearDocumentOwnerVehicle.length === 14;

    const keyDocumentOwnerVeicTracao = isDocumentOwnerVeicleCNPJ ? 'CNPJ' : 'CPF';

    function dateTimeExit() {
      const tzoffset = new Date().getTimezoneOffset() * 60000;
      const newDate = new Date(Date.now() - tzoffset + 3 * 3600 * 1000);
      const localISOTime = newDate.toISOString().slice(0, -1);

      const [before] = localISOTime.split('.');
      const dateWithoutMs = before;
      const dateNowFormatted = `${dateWithoutMs}-03:00`;
      return dateNowFormatted;
    }

    function getVeicReboque() {
      const isEmpty = Object.values(data.formStepInsuranceInformation).every((value) => value === null);
      if (isEmpty) {
        return [];
      }

      const clearDocumentOwnerTrailer = clearString(data.formStepInsuranceInformation.document);
      const keyDocumentOwnerVeicReboque = clearDocumentOwnerTrailer.length === 14 ? 'CNPJ' : 'CPF';

      return {
        UF: data.formStepInsuranceInformation.uf,
        cInt: data.formStepSenderInformation.veicTracaoCint || '0',
        prop: {
          IE: isDocumentOwnerVeicleCNPJ ? data.formStepInsuranceInformation.ie : 'ISENTO',
          UF: data.formStepInsuranceInformation.uf,
          [keyDocumentOwnerVeicReboque]: clearDocumentOwnerTrailer,
          RNTRC: data.formStepInsuranceInformation.rntrc,
          xNome: data.formStepInsuranceInformation.name,
          tpProp: clearDocumentOwnerTrailer.length === 14 ? '1' : '2'
        },
        tara: data.formStepInsuranceInformation.tara,
        capKG: data.formStepInsuranceInformation.capacityKg,
        placa: data.formStepInsuranceInformation.licensePlate,
        tpCar: '00',
        RENAVAM: data.formStepInsuranceInformation.renavam
      };
    }

    // function vCargaFormmated() {
    //   const [before] = data.formStepVtag.totvCarga.split('.');
    //   const vCargaWithoutDecimals = before;
    //   return vCargaWithoutDecimals;
    // }

    return {
      ide: {
        cUF: data.formStepEletronicIdentification.cUF.toString(),
        tpAmb: '1',
        tpEmit: '1',
        tpTransp: isDocumentOwnerVeicleCNPJ ? '1' : '2',
        mod: '58',
        serie: 1,
        nMDF: '1',
        cMDF: '00000001',
        cDV: '1',
        modal: '1',
        tpEmis: '1',
        procEmi: '0',
        verProc: '1',
        UFIni: data.formStepEletronicIdentification.initialUF,
        UFFim: data.formStepEletronicIdentification.finalUF,
        infMunCarrega: [
          {
            cMunCarrega: data.formStepEletronicIdentification.initialCodMunicipality,
            xMunCarrega: data.formStepEletronicIdentification.initialNameMunicipality
          }
        ],
        dhIniViagem: dateTimeExit()
      },
      emit: {
        CNPJ: clearString(data.formStepEletronicIdentification.emitCnpj),
        IE: clearString(data.formStepEletronicIdentification.emitIe),
        xNome: data.formStepEletronicIdentification.emitxNome,
        xFant: data.formStepEletronicIdentification.emitxFant,
        enderEmit: {
          xLgr: data.formStepEletronicIdentification.emitxLgr,
          nro: data.formStepEletronicIdentification.emitNro,
          xBairro: data.formStepEletronicIdentification.emitxBairro,
          cMun: data.formStepEletronicIdentification.emitcMun,
          xMun: data.formStepEletronicIdentification.emitxMun,
          CEP: clearString(data.formStepEletronicIdentification.emitCEP),
          UF: data.formStepEletronicIdentification.emitUF,
          fone: clearString(data.formStepEletronicIdentification.emitfone),
          email: data.formStepEletronicIdentification.emitemail
        },
        infModal: {
          rodo: {
            infANTT: {
              RNTRC: data.formStepSenderInformation.infAnttRNTRC,
              valePed: {
                disp: [
                  {
                    CNPJPg: '11605535000455',
                    nCompra: '1',
                    CNPJForn: '04088208000165',
                    vValePed: '50.00'
                  }
                ],
                categCombVeic: '02'
              }
            }
          },
          veicTracao: {
            cInt: data.formStepSenderInformation.veicTracaoCint || '0',
            placa: clearString(data.formStepSenderInformation.licensePlate),
            RENAVAM: clearString(data.formStepSenderInformation.renavam),
            tara: data.formStepSenderInformation.tara,
            capKG: data.formStepSenderInformation.capacityKg,
            prop: {
              [keyDocumentOwnerVeicTracao]: clearDocumentOwnerVehicle,
              RNTRC: clearString(data.formStepSenderInformation.rntrc),
              xNome: data.formStepSenderInformation.company,
              IE: isDocumentOwnerVeicleCNPJ ? clearString(data.formStepSenderInformation.ie) : 'ISENTO',
              UF: data.formStepSenderInformation.uf,
              tpProp: isDocumentOwnerVeicleCNPJ ? '1' : '2'
            },
            condutor: [
              {
                CPF: data.formStepSenderInformation.documentDriver,
                xNome: data.formStepSenderInformation.nameDriver
              }
            ],
            tpRod: data.formStepSenderInformation.wheelsetType,
            tpCar: data.formStepSenderInformation.bodyType,
            UF: data.formStepSenderInformation.condutorUF
          },
          veicReboque: getVeicReboque()
        },
        infDoc: {
          infMunDescarga: [
            {
              cMunCarrega: data.formStepEletronicIdentification.finalCodMunicipality,
              xMunCarrega: data.formStepEletronicIdentification.finalNameMunicipality,
              infCTe: data.formStepVtag.infCTe
            }
          ]
        },
        seg: [
          {
            infResp: { segCNPJ: '11605535000455', segrespSeg: '1' },
            infSeg: { segCNPJ: '61383493000180', segxSeg: 'SOMPOS' },
            nApol: '5500016572',
            nAver: []
          }
        ],
        prodPred: {
          tpCarga: '05',
          xProd: 'Pacotes de E-Commerce',
          infLotacao: {
            infLocalCarrega: {
              cep: clearString(data.formStepEletronicIdentification.initialZipCode)
            },
            infLocalDescarrega: {
              cep: clearString(data.formStepEletronicIdentification.finalZipCode)
            }
          }
        },
        tot: {
          qCTe: data.formStepVtag.totqCTe,
          qMDFe: '0',
          vCarga: '',
          cUnid: '01',
          qCarga: data.formStepVtag.totqCarga
        }
      }
    };
  }
};
