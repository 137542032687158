
export function getTimes() {
  const year = new Date().getFullYear();
  let month = new Date().getMonth() + 1;
  let day = new Date().getDate();
  let hours = new Date().getHours();
  let minutes = new Date().getMinutes();
  let seconds = new Date().getSeconds();
  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  if (month < 10) {
    month = '0' + month;
  }
  if (day < 10) {
    day = '0' + day;
  }
  if (sessionStorage.getItem('language') === 'zh') {
    return hours + ':' + minutes + ':' + seconds + ' ' + year + '-' + month + '-' + day;
  } else {
    return hours + ':' + minutes + ':' + seconds + ' ' + day + '-' + month + '-' + year;
  }
}
