var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.emitNetdot,
            title: _vm.$t("orderExport.hint"),
            visible: _vm.netdot,
            width: "25%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.netdot = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "wrapDialog" },
            [
              _c("div", { staticClass: "selectDot" }, [
                _vm._v(_vm._s(_vm.$t("newOrder.collect"))),
              ]),
              _c(
                "el-form",
                {
                  ref: "addForm",
                  attrs: {
                    model: _vm.addForm,
                    rules: _vm.addRules,
                    "label-width": "auto",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "websitId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "80%" },
                          attrs: {
                            placeholder: _vm.$t("newOrder.selectDot"),
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.addForm.websitId,
                            callback: function ($$v) {
                              _vm.$set(_vm.addForm, "websitId", $$v)
                            },
                            expression: "addForm.websitId",
                          },
                        },
                        _vm._l(_vm.dotList, function (item, ind) {
                          return _c(
                            "el-option",
                            {
                              key: ind,
                              attrs: {
                                label: item.name + "  " + item.zipCode,
                                value: item.id,
                              },
                            },
                            [
                              _c("span", { staticStyle: { float: "left" } }, [
                                _vm._v(_vm._s(item.name || "- -")),
                              ]),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "13px",
                                    "margin-left": "20px",
                                  },
                                },
                                [_vm._v(_vm._s(item.zipCode || "- -"))]
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "undistributed" }, [
                _vm._v(_vm._s(_vm.$t("newOrder.unallocated"))),
              ]),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.emitNetdot } }, [
                _vm._v(_vm._s(_vm.$t("newOrder.cancel"))),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.NetdotSave } },
                [_vm._v(_vm._s(_vm.$t("newOrder.save")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }