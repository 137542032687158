var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "warpp" },
    [
      _c(
        "el-popover",
        { attrs: { placement: "bottom-start", trigger: "hover" } },
        [
          _c("div", [
            _c(
              "div",
              { staticStyle: { "margin-bottom": "10px" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.onDownloadTemplate },
                  },
                  [_vm._v(_vm._s(_vm.$t("basicData.DownloadTheTemplate")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { "margin-bottom": "10px" } },
              [
                _c("upload-excel", {
                  attrs: {
                    "on-success": _vm.importLogistics,
                    "but-name": _vm.$t("basicData.BulkImport"),
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.downloadOffer },
                  },
                  [_vm._v(_vm._s(_vm.$t("basicData.DownloadTheQuote")))]
                ),
              ],
              1
            ),
          ]),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "10px" },
              attrs: { slot: "reference", size: "small", plain: "" },
              slot: "reference",
            },
            [_vm._v(_vm._s(_vm.$t("basicData.ImportDownload")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }