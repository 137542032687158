import router, { WHITE_LIST } from './router';
import store from './store';
import { Message } from 'element-ui';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
import { getToken } from '@/utils/auth'; // get token from cookie
import getPageTitle from '@/utils/get-page-title';

NProgress.configure({ showSpinner: false }); // NProgress Configuration

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start();

  // set page title
  document.title = getPageTitle(to.meta.title);

  const isWhiteList = WHITE_LIST.indexOf(to.path) !== -1;

  // 当前页面在白名单内
  if (isWhiteList) {
    // 在免费登录白名单中，直接进入
    console.log('在免费登录白名单中，直接进入');
    NProgress.done();
    next();
    return;
  }

  // determine whether the user has logged in
  const hasToken = getToken();

  if (hasToken) {
    // if (to.path === '/login') {
    //   // if is logged in, redirect to the home page
    //   next({ path: '/' });
    //   NProgress.done();
    // } else {
    // 确定用户是否通过getInfo获得了他的权限角色
    const hasRoles = store.getters.roles && store.getters.roles.length > 0;
    if (hasRoles) {
      const toStrName = to.name;
      const lang = sessionStorage.getItem('language');
      const navigatorLanguage = lang || (navigator.language.toLowerCase().indexOf('zh') > -1 ? 'zh' : 'pu');
      if (toStrName === undefined) {
        if (navigatorLanguage === 'zh') {
          Message.error('将要跳转的页面没有权限访问或页面不存在');
        } else {
          Message.error('A página a ser acessada não tem permissão de acesso');
        }
        next({ path: '/' });
        return;
      }
      next();
    } else {
      try {
        // get user info
        // note: 角色必须是对象数组! such as: ['admin'] or ,['developer','editor']
        const { permission } = await store.dispatch('user/getInfo');
        // 根据角色生成可访问的路由
        const accessRoutes = await store.dispatch('permission/generateRoutes', permission.menuPermissions);
        // 动态添加可访问的路线
        router.addRoutes(accessRoutes);

        // hack方法，以确保addRoutes是完整的
        // 设置replace：true，因此导航不会留下历史记录
        next({ ...to, replace: true });
      } catch (error) {
        // 删除令牌并进入登录页面重新登录
        await store.dispatch('user/resetToken');
        Message.error('Has Error');
        next(`/login?redirect=${to.path}`);
        NProgress.done();
      }
    }
    // }
  } else {
    /* has no token*/

    // 其他无权访问的页面将被重定向到登录页面。
    next(`/login?redirect=${to.path}`);
    NProgress.done();
  }
});

router.afterEach(() => {
  // 完成进度栏
  NProgress.done();
});
