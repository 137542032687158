import { getSkipPage } from '@/utils/getSkipPage';
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';

import { trailerService } from '.';
import getMessageError from '@/utils/getMessageError';
import { toast } from '@/utils/programaticToast';
import { getToastContentComponent } from '@/utils/getToastContentComponent';

export function useGetAllTrailers(queryParams) {
  return useQuery({
    queryKey: ['trailers', queryParams],
    queryFn: async() => {
      try {
        const { status, plate, currentPage, perPage } = queryParams.value;
        const skip = getSkipPage(currentPage);
        const res = await trailerService.getAll(skip, perPage, plate, status);

        return res;
      } catch (error) {
        if (error.response && error.response.status === 400) {
          return [];
        }

        throw error;
      }
    }
  });
}

export function useCreateTrailer() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (newTrailer) => trailerService.create(newTrailer),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['trailers'] });

      toast.open({
        variant: 'success',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Criado com sucesso', describe: 'Carreta foi criada com sucesso em nosso sistema', typeIcon: 'success' }),
        closable: true,
        duration: 4000
      });
    },
    onError: (error) => {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Ocorreu um erro', describe: getMessageError(error), typeIcon: 'error' }),
        closable: true,
        duration: 4000
      });
    }
  });
}

export function useUpdateTrailer() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ newTrailer, id, isFormatedBody }) => trailerService.edit(newTrailer, id, isFormatedBody),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['trailers'] });
      toast.open({
        variant: 'success',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Editado com sucesso', describe: 'Carreta foi editada com sucesso em nosso sistema', typeIcon: 'success' }),
        closable: true,
        duration: 4000
      });
    },
    onError: (error) => {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({ title: 'Ocorreu um erro', describe: getMessageError(error), typeIcon: 'error' }),
        closable: true,
        duration: 4000
      });
    }
  });
}
