<template>
  <el-form label-width="120px">
    <el-form-item :label="`${$t('GlobalSetObj.downloadImportTemplate')}`">
      <el-button type="text" icon="el-icon-edit">
        <a :href="importTmpUrl" :download="`${$t('uploadFile.template')}.xlsx`">{{ $t('GlobalSetObj.Download') }}</a>
      </el-button>
    </el-form-item>
    <el-form-item v-show="false" label=" ">
      <input
        ref="uploadInputRef" class="excel-upload-input" type="file"
        accept=".xlsx, .xls" @change="handleClick"
      >
    </el-form-item>
    <el-form-item label="">
      <el-button
        icon="el-icon-upload2" :loading="loading" size="small"
        type="primary" @click="handleUpload"
      >
        {{ $t('uploadFile.uploadFiles') }}
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { Workbook } from 'exceljs';

export default {
  name: '',
  props: {
    // 下载模板的url 使用正式环境地址
    importTmpUrl: {
      type: String,
      default: ''
    },

    // 对照模板 格式 {  a: { key:'a', header: '客户名称', width: 10 } }
    keyAndValObj: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      loading: false
    };
  },

  computed: {
    
  },

  created() {},

  methods: {
    handleUpload() {
      this.$refs['uploadInputRef'].click();
    },

    async handleClick(e) {
      const files = e.target.files[0];
      if (!files) return;

      if (!isExcel(files)) {
        this.$message.error(this.$i18n.t('basicData.PleaseUploadExcelFiles')); // '请上传 .xlsx, .xls 文件'
        return false;
      }

      this.loading = true;

      this.$refs['uploadInputRef'].value = null;

      const workbook = new Workbook();
      try {
        const workbook2 = await workbook.xlsx.load(files);
        workbook2.eachSheet((sheet, index) => {
          const value = sheet.getSheetValues();

          const arr = value.filter((item, index) => {
            return index !== 0;
          }).map(item => {
            return item.filter((item, index) => {
              return index !== 0;
            });
          });
          const list = this.setKeyAndVal(arr);

          // if (list.length > 500) {
          //   return this.$message.warning(`${this.$t('receivablePayable.Only500PiecesImport')}`);
          // }
          this.$emit('change', list);
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    // 文件 key val 对照
    setKeyAndVal(arr) {
      // 无内容
      if (arr.length === 0) {
        return arr;
      }

      // 未传入对照模板
      if (isEmpty(this.keyAndValObj)) {
        return arr;
      }

      const valueArr = Object.values(this.keyAndValObj);
      return arr.slice(2).map(item => {
        const obj = {};
        valueArr.forEach((k, i) => {
          obj[k.key] = item[i];
        });

        return obj;
      });
    }
  }

};

// 是否是excel
const isExcel = (file) => {
  return /\.(xlsx|xls)$/.test(file.name);
};

// 是否是空对象
const isEmpty = (obj) => {
  return Reflect.ownKeys(obj).length === 0 && obj.constructor === Object;
};
</script>

<style scoped>
.row {
  display: flex;
  border: 1px solid #000;
}
</style>
