<template>
  <div class="MonitoringReportBox">
    <el-tabs v-model="findActive" type="border-card">
      <!--                按时间范围 -->
      <el-tab-pane :label="$t('collectionCenter.byTimeRange')" name="timeFind">
        <timeRange />
      </el-tab-pane>
      <!-- 按单号 -->
      <el-tab-pane :label="$t('collectionCenter.byOdd')" name="oddFind">
        <odd />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import odd from '@/views/collectionCenter/Report/MR/odd.vue';
import timeRange from '@/views/collectionCenter/Report/MR/timeRange.vue';
export default {
  name: 'MonitoringReport',
  components: {
    odd,
    timeRange
  },
  data() {
    return {
      findActive: 'timeFind' // timeFind 按时间范围   oddFind 按单号
    };
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
  .MonitoringReportBox{
    padding: 20px;
    box-sizing: border-box;
  }
</style>
