<script >
import { defineProps } from '@vue/composition-api';
import { default as DropdownTable } from '@/components/dropdownTable';
import { default as FormEditSeller } from './form/formEditSeller';
import { default as Alert } from '@/components/alert';
import { sellerService } from '@/api/transport/seller';
import { useModal } from '@/composable/useModal';
import getMessageError from '@/utils/getMessageError';
import { useQueryClient } from '@tanstack/vue-query';
import { getToastContentComponent } from '@/utils/getToastContentComponent';
import { toast } from '@/utils/programaticToast';
const __sfc_main = {};
__sfc_main.props = {
  row: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    isOpen: isOpenModalEdit,
    handleVisibility: handleVisibleModalEdit
  } = useModal();
  const {
    isOpen: isOpenModalDelete,
    handleVisibility: handleVisibleModalDelete
  } = useModal();
  const queryClient = useQueryClient();
  async function handleDelete() {
    try {
      await sellerService.delete(props.row.id);
      handleVisibleModalDelete();
      queryClient.invalidateQueries({
        queryKey: ['sellersRegistered']
      });
      toast.open({
        variant: 'success',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Excluído com sucesso',
          describe: 'Seller foi excluído com sucesso',
          typeIcon: 'success'
        }),
        closable: true,
        duration: 4000
      });
    } catch (error) {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Ocorreu um erro',
          describe: getMessageError(error),
          typeIcon: 'error'
        }),
        closable: true,
        duration: 4000
      });
    }
  }
  return {
    isOpenModalEdit,
    handleVisibleModalEdit,
    isOpenModalDelete,
    handleVisibleModalDelete,
    handleDelete
  };
};
__sfc_main.components = Object.assign({
  DropdownTable,
  FormEditSeller,
  Alert
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <DropdownTable>
      <o-dropdown-item aria-role="listitem" @click="handleVisibleModalEdit">
        {{ $t('seller.edit') }}
      </o-dropdown-item>

      <o-dropdown-item aria-role="listitem" @click="handleVisibleModalDelete">
        {{ $t('seller.delete') }}
      </o-dropdown-item>
    </DropdownTable>

    <o-modal :active="isOpenModalEdit" width="560" @close="handleVisibleModalEdit">
      <div>
        <h2 class="header-2">{{ $t('seller.editTitle') }}</h2>
        <p class="paragraph">{{ $t('seller.editParagraph') }}</p>
      </div>

      <FormEditSeller
        :seller="row"
        :close-modal="handleVisibleModalEdit"
      />
    </o-modal>

    <Alert
      :active="isOpenModalDelete"
      @handleClose="handleVisibleModalDelete"
    >
      <template #title> {{ $t('seller.deleteTitle') }} </template>
      <template #message> {{ $t('seller.deleteParagraph') }} </template>

      <template #buttons>
        <o-button variant="outline" size="md" @click="handleVisibleModalDelete"> {{ $t('seller.buttonCancel') }} </o-button>
        <o-button variant="primary" size="md" @click="handleDelete"> {{ $t('seller.buttonDelete') }} </o-button>
      </template>
    </Alert>
  </div>
</template>
