var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "warp" },
    [
      _c(
        "el-form",
        { key: _vm.keyInvoice, attrs: { "label-width": "auto" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-descriptions",
                { attrs: { colon: false, column: 3, direction: "vertical" } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.signedPhoto") } },
                    [
                      _c(
                        "div",
                        {
                          style:
                            "" +
                            (_vm.$i18n.locale !== "zh"
                              ? "width:50px"
                              : "width:60px"),
                        },
                        [
                          _vm.tableInfomation.signedNamePhoto
                            ? _c(
                                "el-tooltip",
                                {
                                  staticClass: "QuestionMark",
                                  attrs: { placement: "top" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "image",
                                        staticStyle: { width: "300px" },
                                        attrs: {
                                          src: _vm.tableInfomation
                                            .signedNamePhoto,
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        "justify-content": "center",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("GlobalSetObj.see"))
                                        ),
                                      ]),
                                      _c("svg-icon", {
                                        attrs: { "icon-class": "iconEye" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _c("span", [_vm._v(_vm._s("--"))]),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.signedNamePhoto"),
                        span: 2,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          style:
                            "" +
                            (_vm.$i18n.locale !== "zh"
                              ? "width:50px"
                              : "width:60px"),
                        },
                        [
                          _vm.tableInfomation.signedPhoto
                            ? _c(
                                "el-tooltip",
                                {
                                  staticClass: "QuestionMark",
                                  attrs: { placement: "top" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    _vm._l(
                                      _vm.tableInfomation.signedPhoto.split(
                                        ","
                                      ),
                                      function (item, index) {
                                        return _c("img", {
                                          key: index,
                                          staticClass: "image",
                                          staticStyle: {
                                            width: "300px",
                                            margin: "5px",
                                          },
                                          attrs: { src: item, alt: "" },
                                        })
                                      }
                                    ),
                                    0
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        "justify-content": "center",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("GlobalSetObj.see"))
                                        ),
                                      ]),
                                      _c("svg-icon", {
                                        attrs: { "icon-class": "iconEye" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _c("span", [_vm._v(_vm._s("--"))]),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.TypeRecipient") } },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.tableInfomation.signTypeName)),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.SignerName") } },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.tableInfomation.deliverySigner || "--")
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.RecipientDOC") } },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm.tableInfomation.receiverDocumentNumber || "--"
                          )
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.SigningTime"),
                        span: 3,
                      },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.tableInfomation.deliveredTime || "--")
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }