var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      staticStyle: { width: "100%" },
      attrs: { data: _vm.tableData, height: _vm.tableHeight, border: "" },
    },
    [
      _c("el-table-column", {
        attrs: {
          label: _vm.$t("newOrder.SerialNumber"),
          type: "index",
          width: "50",
        },
      }),
      _c("el-table-column", {
        attrs: {
          label: _vm.$t("orderCenterCont.SubpackageNumber"),
          prop: "subPacketNumber",
        },
      }),
      _c("el-table-column", {
        attrs: {
          label: _vm.$t("collectionCenter.tipoTitle"),
          prop: "orderType",
        },
      }),
      _c("el-table-column", {
        attrs: {
          label: _vm.$t("GlobalSetObj.packageVlaue"),
          prop: "packetValue",
        },
      }),
      _c("el-table-column", {
        attrs: {
          label: _vm.$t("GlobalSetObj.kaipiaojine"),
          prop: "invoicedAmount",
        },
      }),
      _c("el-table-column", {
        attrs: {
          label: _vm.$t("newOrder.InvoiceNumber"),
          prop: "invoiceNumber",
        },
      }),
      _c("el-table-column", {
        attrs: { label: _vm.$t("newOrder.InvoiceKey"), prop: "invoiceKey" },
      }),
      _c("el-table-column", {
        attrs: {
          label: _vm.$t("newOrder.TheInvoiceSerialNumber"),
          prop: "invoiceSerial",
        },
      }),
      _c("el-table-column", {
        attrs: { label: _vm.$t("newOrder.TaxCode"), prop: "taxCode" },
      }),
      _c("el-table-column", {
        attrs: { label: _vm.$t("newOrder.BuildTime"), prop: "generationTime" },
      }),
      _c("el-table-column", {
        attrs: {
          label: _vm.$t("GlobalSetObj.NationalRegistrationNumber"),
          prop: "ienumber",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }