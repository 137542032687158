<script >
import { defineProps } from '@vue/composition-api';
import { useVuelidate } from '@vuelidate/core';
import { FormActions, FormActionsNext, FormActionsPrevious } from '@/components/wizard-form/formStepActions';
import { required, minLength, maxLength } from '@/utils/validationErrors';
const __sfc_main = {};
__sfc_main.props = {
  isLastStep: {
    type: Boolean,
    required: true
  },
  isFirstStep: {
    type: Boolean,
    required: true
  },
  handlePrevious: {
    type: Function
  },
  handleNext: {
    type: Function,
    required: true
  },
  formData: {
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const rules = {
    comercialPhone: {
      required,
      minLength: minLength(18),
      maxLength: maxLength(19)
    },
    contactName: {
      required
    },
    phone: {
      required,
      minLength: minLength(18),
      maxLength: maxLength(19)
    },
    email: {
      required
    }
  };
  const v$ = useVuelidate(rules, props.formData);
  async function handleSubmit() {
    const isValid = await v$.value.$validate();
    if (isValid) props.handleNext();
  }
  return {
    v$,
    handleSubmit
  };
};
__sfc_main.components = Object.assign({
  FormActions,
  FormActionsPrevious,
  FormActionsNext
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <form class="wrapper-form" @submit.prevent="handleSubmit">
    <o-field
      v-mask="['+## (##) ####-####', '+## (##) #####-####']"
      :label="$t('base.businessPhone')"
      label-for="comercialPhone"
      :message="v$.comercialPhone.$errors.length ? v$.comercialPhone.$errors[0].$message : null"
    >
      <o-input id="comercialPhone" v-model="formData.comercialPhone" :placeholder="$t('base.businessPhonePlaceholder')" />
    </o-field>

    <o-field
      :label="$t('base.contactName')"
      label-for="contactName"
      :message="v$.contactName.$errors.length ? v$.contactName.$errors[0].$message : null"
    >
      <o-input id="contactName" v-model="formData.contactName" :placeholder="$t('base.contactNamePlaceholder')" />
    </o-field>

    <o-field
      v-mask="['+## (##) ####-####', '+## (##) #####-####']"
      :label="$t('base.mobilePhone')"
      label-for="phone"
      :message="v$.phone.$errors.length ? v$.phone.$errors[0].$message : null"
    >
      <o-input id="phone" v-model="formData.phone" :placeholder="$t('base.mobilePhonePlaceholder')" />
    </o-field>

    <o-field
      :label="$t('base.email')"
      label-for="email"
      :message="v$.email.$errors.length ? v$.email.$errors[0].$message : null"
    >
      <o-input id="email" v-model="formData.email" :placeholder="$t('base.emailPlaceholder')" />
    </o-field>

    <FormActions>
      <FormActionsPrevious
        :is-first-step="isFirstStep"
        @handlePrevious="handlePrevious"
      />

      <FormActionsNext :is-last-step="isLastStep" />
    </FormActions>
  </form>
</template>

<style scoped>
  .wrapper-form {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem/* 16px */;
    padding-top: 0.5rem/* 8px */;
    padding-bottom: 0.5rem/* 8px */;
  }
</style>
