<template>
  <div class="app-container">
    <el-card class="box-card">
      <el-form ref="form" :model="queryForm" label-position="top" size="small">
        <el-row :gutter="10">
          <el-col :span="6">
            <!-- 货币代码 -->
            <div class="grid-content bg-purple">
              <el-form-item :label="$t('GlobalSetObj.CurrencyCode')">
                <el-input v-model="queryForm.params.code" />
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <!-- 状态 -->
              <el-form-item :label="$t('GlobalSetObj.status')">
                <el-select
                  v-model="queryForm.params.status"
                  filterable
                  clearable
                  style="width: 100%;"
                >
                  <el-option :key="0" :label="$t('GlobalSetObj.all')" :value="null" />
                  <el-option :key="1" :label="$t('GlobalSetObj.disable')" :value="0" />
                  <el-option :key="2" :label="$t('GlobalSetObj.enable')" :value="1" />
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <el-button type="primary" size="small" icon="el-icon-search" @click="searchResources(1)">{{ $t('GlobalSetObj.search') }}</el-button>
            <el-button size="small" icon="el-icon-refresh-left" @click="searchResources(2)">{{ $t('GlobalSetObj.reset') }}</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <div style="margin-top: 10px;">
      <el-card class="box-card">
        <div style="margin-bottom: 10px;">
          <el-button
            v-permit:remove="'btn:currencyManagement:edit'"
            type="primary"
            size="small"
            @click="addCurrency(1)"
          >
            {{ $t('GlobalSetObj.append') }}
          </el-button>
        </div>
        <el-table
          ref="elTable"
          :key="tableKey"
          :data="tableData"
          border
          class="table-height"
          :height="tableHeightRow.height"
        >
          <el-table-column :label="$t('operationCenter.ind')" width="60px" align="center">
            <template slot-scope="scope">
              <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <!--货币代码-->
          <el-table-column
            prop="code"
            :label="$t('GlobalSetObj.CurrencyCode')"
            align="left"
            width="100"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div style="color: #409EFF;cursor: pointer;" @click="editCurrency(2, scope.row)">{{ scope.row.code }}</div>
            </template>
          </el-table-column>
          <!--中文名称-->
          <el-table-column
            prop="chineseName"
            :label="$t('GlobalSetObj.ChineseName')"
            align="left"
            width="140"
            :show-overflow-tooltip="true"
          />
          <!--英文名称-->
          <el-table-column
            prop="englishName"
            :label="$t('userManage.englishName')"
            align="left"
            min-width="160"
            :show-overflow-tooltip="true"
          />
          <!--默认汇率-->
          <el-table-column
            prop="exchangeRate"
            :label="$t('GlobalSetObj.DefaultExchangeRate')"
            align="left"
            width="120"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.exchangeRate.toFixed(5) }}</span>
            </template>
          </el-table-column>
          <!--状态-->
          <el-table-column
            prop="status"
            :label="$t('GlobalSetObj.status')"
            align="center"
            width="140"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <!--启用-->
              <el-tag v-if="scope.row.status == 1" type="success">{{ $t('GlobalSetObj.enable') }}</el-tag>
              <!--停用-->
              <el-tag v-else type="info">{{ $t('GlobalSetObj.disable') }}</el-tag>
            </template>
          </el-table-column>
          <!--创建时间-->
          <el-table-column
            prop="createTime"
            :label="$t('GlobalSetObj.CreationTime')"
            align="left"
            width="160"
            :show-overflow-tooltip="true"
          />
          <!--创建人-->
          <el-table-column
            prop="createUser"
            :label="$t('GlobalSetObj.CreatePeople')"
            align="left"
            width="120"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ createUser[scope.row.createUser] }}</span>
            </template>
          </el-table-column>
          <!--最新修改人-->
          <el-table-column
            prop="createUser"
            :label="$t('GlobalSetObj.LatestModifiedBy')"
            align="center"
            width="120"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ createUser[scope.row.updateUser] }}</span>
            </template>
          </el-table-column>
          <!--最新修改时间-->
          <el-table-column
            prop="updateTime"
            :label="$t('GlobalSetObj.LastModified')"
            align="center"
            width="160"
            :show-overflow-tooltip="true"
          />
          <!--备注-->
          <el-table-column
            prop="remark"
            :label="$t('GlobalSetObj.remark')"
            align="left"
            min-width="160"
            :show-overflow-tooltip="true"
          />
          <!--操作-->
          <el-table-column
            :label="$t('GlobalSetObj.operate')"
            fixed="right"
            width="220px"
          >
            <template slot-scope="scope">
              <!--确定禁用吗？-->
              <el-popconfirm
                :title="$t('basicData.AreYouSureDisabled')"
                @confirm="operationBtn(0, scope.row)"
              >
                <!--禁用-->
                <el-button
                  v-if="scope.row.status === 1"
                  slot="reference"
                  v-permit:remove="'btn:currencyManagement:edit'"
                  style="margin-right: 10px;"
                  type="danger"
                  size="mini"
                >{{ $t('GlobalSetObj.disable') }}</el-button>
              </el-popconfirm>
              <!--确定启用吗？-->
              <el-popconfirm
                :title="$t('basicData.AreYouSureEnabled')"
                @confirm="operationBtn(1, scope.row)"
              >
                <!--启用-->
                <el-button
                  v-if="scope.row.status === 0"
                  slot="reference"
                  v-permit:remove="'btn:currencyManagement:edit'"
                  style="margin-right: 10px;"
                  type="primary"
                  size="mini"
                >{{ $t('GlobalSetObj.enable') }}</el-button>
              </el-popconfirm>
              <!--确定删除吗？-->
              <el-popconfirm
                :title="$t('GlobalSetObj.AreYouSureDelete')"
                @confirm="deleteCurrency(scope.row)"
              >
                <!--删除-->
                <el-button
                  slot="reference"
                  v-permit:remove="'btn:currencyManagement:delete'"
                  style="margin-right: 10px;"
                  size="mini"
                  type="danger"
                >{{ $t('GlobalSetObj.Delete') }}</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页工具 -->
        <div class="pagination">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryForm.pageNumber"
            :page-sizes="[10, 20, 50, 100]"
            :page-size.sync="queryForm.pageSize"
            :total="total"
            background
            style="margin: 10px 0"
            @current-change="getCurrentTableData"
            @size-change="getChangeTableData"
          />
        </div>
      </el-card>
    </div>
    <!--    新增、编辑-->
    <el-drawer
      :append-to-body="true"
      :visible.sync="showCurrencyDetails"
      direction="ltr"
      custom-class="drawerClass"
      :title="operationType === 1 ? $t('GlobalSetObj.add') : $t('GlobalSetObj.edit')"
      size="80%"
    >
      <div style="height: calc(100vh - 80px); overflow: auto">
        <check-detail
          :key="newKey"
          :detail-data="formData"
          :operation-type="operationType"
          @saveChange="saveChange"
        />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import checkDetail from './checkDetail';
import { batchDisabledType, batchEnabledType, currencyTypePage, deleteCurrentcyType } from '@/api/finance/partition';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';

export default {
  name: 'CurrencyManagement',
  components: {
    checkDetail
  },
  mixins: [estimateTableHeight],
  data() {
    return {
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          code: '',
          status: null
        }
      },
      total: 0,
      tableData: [],
      tableKey: new Date().getTime(),
      createUser: null,
      showCurrencyDetails: false,
      operationType: 1,
      newKey: new Date().getTime(),
      formData: {
        chineseName: '', // 中文名
        code: '', // 货币代码
        englishName: '', // 英文名称
        exchangeRate: '', // 默认汇率
        id: '', // ID
        // isDefault: false, // 是否是默认币种： 1 是 0 否 默认为 0
        remark: '', // 备注
        status: 1 // 状态： 1 启用 0 禁用 默认为 1
      }
    };
  },

  created() {
    this.createUser = getUserIdAndNameObj();
    this.getCurrencyTable();
  },
  methods: {
    // 搜索、重置
    searchResources(type) {
      this.queryForm.pageNumber = 1;
      if (type === 1) {
        this.getCurrencyTable();
      } else {
        this.queryForm.params.code = '';
        this.queryForm.params.status = '';
      }
    },
    // 添加币种
    addCurrency(type) {
      this.operationType = type;
      this.formData = {
        chineseName: '', // 中文名
        code: '', // 货币代码
        englishName: '', // 英文名称
        exchangeRate: '', // 默认汇率
        id: '', // ID
        // isDefault: false, // 是否是默认币种： 1 是 0 否 默认为 0
        remark: '', // 备注
        status: 1 // 状态： 1 启用 0 禁用 默认为 1
      };
      this.newKey = new Date().getTime();
      this.showCurrencyDetails = true;
    },
    // 编辑
    editCurrency(type, row) {
      this.operationType = type;
      this.formData = {
        chineseName: row.chineseName, // 中文名
        code: row.code, // 货币代码
        englishName: row.englishName, // 英文名称
        exchangeRate: row.exchangeRate, // 默认汇率
        id: row.id, // ID
        // isDefault: false, // 是否是默认币种： 1 是 0 否 默认为 0
        remark: row.remark, // 备注
        status: row.status // 状态： 1 启用 0 禁用 默认为 1
      };
      this.newKey = new Date().getTime();
      this.showCurrencyDetails = true;
    },
    // 启用、禁用
    operationBtn(type, data) {
      const param = {
        ids: data.id
      };
      if (type === 0) {
        // 禁用
        batchDisabledType(param).then(res => {
          if (res.status === 'OK') {
            this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded')); // 操作成功
            this.getCurrencyTable();
          }
        });
      } else {
        // 启用
        batchEnabledType(param).then(res => {
          if (res.status === 'OK') {
            this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded')); // 操作成功
            this.getCurrencyTable();
          }
        });
      }
    },
    // 删除
    deleteCurrency(row) {
      const param = {
        id: row.id
      };
      deleteCurrentcyType(param).then(res => {
        if (res.status === 'OK') {
          this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded')); // 操作成功
          this.getCurrencyTable();
        }
      });
    },
    // 获取币种列表
    getCurrencyTable() {
      currencyTypePage(this.queryForm).then((res) => {
        if (res.status === 'OK') {
          this.tableData = res.data.records;
          this.total = res.data.total;
          this.tableKey = new Date().getTime();
        }
      });
    },
    // 点击页码翻页
    getCurrentTableData(val) {
      this.queryForm.pageNumber = val;
      this.getCurrencyTable();
    },
    // 每页显示数量切换
    getChangeTableData(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.getCurrencyTable();
    },
    // 添加、编辑保存
    saveChange(type) {
      if (type === 1) {
        this.getCurrencyTable();
      }
      this.showCurrencyDetails = false;
    }
  }
};
</script>

<style scoped>

</style>
