var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        { attrs: { inline: false, "label-position": "top", size: "small" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("navbar.selectNIG") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t(
                              "GlobalSetObj.Only10AreDisplayed"
                            ),
                            "remote-method": _vm.getTagNumber,
                            clearable: "",
                            filterable: "",
                            remote: "",
                            "reserve-keyword": "",
                            size: "small",
                          },
                          on: { focus: _vm.focegetTagNumber },
                          model: {
                            value: _vm.printObj.vehicleTagNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.printObj, "vehicleTagNumber", $$v)
                            },
                            expression: "printObj.vehicleTagNumber",
                          },
                        },
                        _vm._l(_vm.tagNumberList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("collectionCenter.PrintCopies"),
                        prop: "inputValue",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            filterable: "",
                          },
                          model: {
                            value: _vm.printObj.printCount,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.printObj,
                                "printCount",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "printObj.printCount",
                          },
                        },
                        _vm._l(10, function (i) {
                          return _c("el-option", {
                            key: i,
                            attrs: { label: i, value: i },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { staticStyle: { "margin-top": "28px" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.clickPrint },
                        },
                        [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Print")) + " ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: { click: _vm.clickPdf },
                        },
                        [_vm._v(_vm._s("导出PDF"))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "titleClass" }, [
        _vm._v(_vm._s(_vm.$t("collectionCenter.printRecord"))),
      ]),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            "cell-style": { "text-align": "center" },
            data: _vm.tableData,
            "header-cell-style": {
              background: "#f2f2f2",
              color: "black",
              "text-align": "center",
            },
            border: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("GlobalSetObj.serial"),
              type: "index",
              width: "80",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.printTime"),
              "min-width": "180",
              prop: "createTime",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("GlobalSetObj.NIV"),
              "min-width": "180",
              prop: "vehicleTagNumber",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("GlobalSetObj.operator"),
              "min-width": "150",
              prop: "operatorUser",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.PrintCopies"),
              "min-width": "150",
              prop: "printCount",
            },
          }),
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { margin: "10px 0", float: "right" },
        attrs: {
          "current-page": _vm.queryForm.number,
          "page-size": _vm.queryForm.pageSize,
          "page-sizes": [15, 20, 50, 100],
          total: _vm.queryForm.total,
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c(
        "div",
        { staticClass: "printStyle" },
        [
          _vm.showPrintBagNumberHtml
            ? _c("printBagNumberHtml", {
                attrs: { "param-data": _vm.printBagObj },
                on: { hiddenBagNumberHtml: _vm.hiddenBagNumberHtml },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.isPdfContent
        ? _c("div", [
            _c(
              "div",
              { staticClass: "fontSizeStyle", attrs: { id: "pdfContent" } },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      width: "100%",
                    },
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "100px" },
                      attrs: {
                        src: require("@/assets/ajlogo1.svg"),
                        alt: "tu",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "20px",
                          "margin-left": "20px",
                        },
                      },
                      [_vm._v("RELATÓRIO DE EXPEDIÇÃO-DESTINO ✖ IATAS")]
                    ),
                  ]
                ),
                _c(
                  "table",
                  {
                    staticStyle: {
                      width: "100%",
                      "text-align": "left",
                      "margin-top": "15px",
                      "border-collapse": "collapse",
                    },
                  },
                  [
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          "Data: " +
                            _vm._s(_vm.tagDatasiteObj.uploadTime || "--")
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          "Vtag: " +
                            _vm._s(_vm.tagDatasiteObj.vehicleTagNumber || "--")
                        ),
                      ]),
                    ]),
                    _vm._m(0),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          "Nome: " +
                            _vm._s(_vm.tagDatasiteObj.vehicleDriver || "--")
                        ),
                      ]),
                      _c(
                        "td",
                        { staticStyle: { "background-color": "#add88d" } },
                        [_vm._v("Outras informações")]
                      ),
                    ]),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          "RG/CPF: " + _vm._s(_vm.tagDatasiteObj.itin || "--")
                        ),
                      ]),
                      _c("td", { attrs: { rowspan: "4" } }, [
                        _vm._v(_vm._s(_vm.tagDatasiteObj.remark || "--")),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          "Veiculo: " +
                            _vm._s(_vm.tagDatasiteObj.vehicleTypeName || "--")
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          "Inicio Carregamento/Horário: " +
                            _vm._s(_vm.tagDatasiteObj.startLoadingTime || "--")
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          "Fim Carregamento/Horário: " +
                            _vm._s(_vm.tagDatasiteObj.endLoadingTime || "--")
                        ),
                      ]),
                    ]),
                    _vm._m(1),
                    _c("tr", [
                      _c("td", [
                        _c("div", [_vm._v("Placa:")]),
                        _c("div", { staticStyle: { "text-align": "center" } }, [
                          _vm._v(
                            _vm._s(_vm.tagDatasiteObj.vehicleNumber || "--")
                          ),
                        ]),
                        _vm._m(2),
                      ]),
                      _c("td", [
                        _c("div", [_vm._v("Vtag:")]),
                        _c("div", { staticStyle: { "text-align": "center" } }, [
                          _vm._v(
                            _vm._s(_vm.tagDatasiteObj.vehicleTagNumber || "--")
                          ),
                        ]),
                        _vm._m(3),
                      ]),
                    ]),
                  ]
                ),
                _c("div", { staticStyle: { margin: "10px 0" } }, [
                  _vm._v("CONTAGEM"),
                ]),
                _c(
                  "table",
                  {
                    staticStyle: {
                      width: "100%",
                      "border-collapse": "collapse",
                      "text-align": "center",
                    },
                  },
                  [
                    _vm._m(4),
                    _vm._l(
                      _vm.tagDatasiteObj.siteAddressesSort01,
                      function (item1, index1) {
                        return _c(
                          "tbody",
                          {
                            key: index1,
                            staticStyle: { "text-align": "center" },
                          },
                          [
                            _c("tr", [
                              _c("td", { attrs: { rowspan: "2" } }, [
                                _vm._v(_vm._s(item1.sort || "--")),
                              ]),
                              _c("td", { staticStyle: { width: "140px" } }, [
                                _vm._v(_vm._s(item1.name || "--")),
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(item1.bagPackageCount || "--")),
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(item1.cargoPackageCount || "--")),
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(item1.totalPackageCount || "--")),
                              ]),
                              _c("td"),
                              _c("td"),
                              _c("td"),
                            ]),
                            _c(
                              "tr",
                              { staticStyle: { "text-align": "left" } },
                              [
                                _c("td", { attrs: { colspan: "5" } }, [
                                  _vm._v(
                                    "Endereço: " +
                                      _vm._s(item1.originAddress || "--")
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                  ],
                  2
                ),
                _vm._m(5),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticStyle: { "background-color": "#ffc000" } }, [
      _c("td", { attrs: { colspan: "2" } }, [
        _vm._v("DADOS MOTORISTA/ VEÍCULO:"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c(
        "td",
        {
          staticStyle: { "background-color": "#ffc000" },
          attrs: { colspan: "2" },
        },
        [_vm._v("Destino das lATAS (Expedicao/ Local)")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { display: "flex", "justify-content": "center" } },
      [_c("div", { attrs: { id: "qrCanvas1" } })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { display: "flex", "justify-content": "center" } },
      [_c("div", { attrs: { id: "barcode1" } })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticStyle: { "background-color": "#add88d" } }, [
        _c("th", [_vm._v("No.")]),
        _c("th", { staticStyle: { width: "140px" } }, [_vm._v("IATA")]),
        _c("th", [_vm._v("Número de sacolas")]),
        _c("th", [_vm._v("Quantidade de pacote grande")]),
        _c("th", [_vm._v("Quantidade de pacote")]),
        _c("th", [_vm._v("Tempo de descarga")]),
        _c("th", [_vm._v("Número de descargas")]),
        _c("th", [_vm._v("Confirmação da assinatura")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "space-between",
          "margin-top": "20px",
        },
      },
      [
        _c("div", { staticStyle: { "border-bottom": "1px solid #000000" } }, [
          _c("div", { staticStyle: { "margin-bottom": "30px" } }, [
            _vm._v("Nome Motorista"),
          ]),
        ]),
        _c("div", { staticStyle: { "border-bottom": "1px solid #000000" } }, [
          _c("div", { staticStyle: { "margin-bottom": "30px" } }, [
            _vm._v("Nome Anjun (Expedição Perus)"),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }