// 合并存在交集的数组
const merge1 = (intervals) => {
  if (intervals.length === 0) return [];
  const res = [];
  intervals.sort((a, b) => a[0] - b[0]); // 数组首位排序函数sort
  let candidate = intervals[0]; // 排序后选择第一个数组  (临时空间)
  for (let i = 1; i < intervals.length; i++) {
    const cur = intervals[i]; // 从第二个开始取cur
    if (candidate[1] >= cur[0]) { // 有重合 能合并
      candidate[1] = Math.max(cur[1], candidate[1]); // 左端不变 右端取大的
    } else { // 不重合 不能合并
      res.push(candidate);
      candidate = cur; // 把cur放进去临时控件
    }
  }
  res.push(candidate);
  return res;
};
const merge = (intervals) => {
  const res = [];
  const len = intervals.length;
  if (len === 0) return [];
  intervals.sort((a, b) => a[0] - b[0]);
  for (let i = 0; i < len; i++) {
    const left = parseInt(intervals[i][0]);
    let right = parseInt(intervals[i][1]);
    while (i < len - 1 && right >= parseInt(intervals[i + 1][0]) && right <= parseInt(intervals[i + 1][1])) {
      i++;
      right = Math.max(right, parseInt(intervals[i][0]), parseInt(intervals[i][1]));
    }
    res.push([left, right]);
  }
  return merge1(res);
  // return res;
};
// sort从小到大排序规则
const sortChange = (a, b) => {
  return a - b;
};
// 将字符串中’~‘拼接的区间字符串拆开并组成新的数组
export const compressList = (list) => {
  const pattern = /[`!@#$^\-&*()=|{}':·+;'\\\[\]\.<>\/?！@#￥……&*（）——|{}【】'；：""'。，、+？\s]/g;
  list.replace(pattern, '');
  // 中文逗号转英文逗号
  const arr = list.replace(/，/ig, ',').replace(pattern, '').split(',');
  console.log(arr, '==arr');
  const a = [];
  arr.map(item => {
    if (item) {
      const aTrim = item.trim();
      if (!a.includes(aTrim)) {
        a.push(aTrim);
      }
    }
  });
  // 数组去重
  const unique = (arr) => {
    const newArr = [];
    for (let i = 0; i < arr.length; i++) {
      if (newArr.indexOf(arr[i]) === -1) {
        newArr.push(arr[i]);
      }
    }
    return newArr;
  };
  const uniqueArr = unique(a);
  // 去除所有前后空格并按从小到大排序
  const listArr = uniqueArr.map(itemArr => {
    if (itemArr) {
      return itemArr.trim();
    }
  }).sort(sortChange);
  const result = [];
  let start = null;
  let last = null;
  for (let i = 0; i < listArr.length; i++) {
    const item = listArr[i];
    if (start === null) {
      start = item;
      last = item;
    } else if (Number(last) + 1 === Number(item)) {
      last = item;
    } else {
      if (start === last) {
        result.push(start);
      } else {
        result.push(start + '~' + last);
      }
      start = item;
      last = item;
    }
  }
  if (start === last) {
    result.push(start);
  } else {
    result.push(start + '~' + last);
  }
  const data = result.map(it => {
    let itArr = it.split('~').sort(sortChange);
    if (itArr.length === 1) {
      itArr = [itArr[0], itArr[0]];
    }
    return itArr;
  });
  const arrData = data.map(item => {
    return item.filter((s) => {
      return s && s.trim();
    });
  });
  let dataArr;
  if (arrData.length > 1) {
    dataArr = merge(arrData);
  } else {
    dataArr = arrData;
  }
  const zipCodeData = [];
  dataArr.map(item => {
    if (item[0] === item[1]) {
      zipCodeData.push([item[0]].join('~'));
    } else if (!isNaN(item[0]) && !isNaN(item[1])) {
      zipCodeData.push(item.join('~'));
    } else if (isNaN(item[0]) && !isNaN(item[1])) {
      zipCodeData.push([item[1]].join('~'));
    } else if (!isNaN(item[0]) && isNaN(item[1])) {
      zipCodeData.push([item[0]].join('~'));
    }
  });
  console.log(zipCodeData, '==zipCodeData');
  return zipCodeData;
};
