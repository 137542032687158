var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "printBagNumber",
      staticClass: "fontSizeStyle",
      attrs: { id: "printBagNumber" },
    },
    [
      _c(
        "div",
        { ref: "printBagNumberContent", staticClass: "printBagNumberContent" },
        [
          _c("div", { staticClass: "headerBag" }, [
            _c(
              "div",
              { staticStyle: { "font-size": "25px", "font-weight": "bold" } },
              [_vm._v(_vm._s(_vm.paramData.providerName || "--"))]
            ),
            _c("div", [
              _c("div", [_vm._v(_vm._s(_vm.paramData.time || "--"))]),
              _c("div", [_vm._v(_vm._s(_vm.paramData.timeSs || "--"))]),
            ]),
          ]),
          _c("div", { staticClass: "bagContent" }, [
            _c(
              "div",
              {
                staticStyle: {
                  "text-align": "center",
                  "font-size": "22px",
                  "padding-top": "10px",
                },
              },
              [_vm._v("Informações da Saca")]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  "text-align": "center",
                  "font-size": "38px",
                  margin: "20px 0",
                  "font-weight": "bold",
                },
              },
              [_vm._v(" " + _vm._s(_vm.paramData.threeCode || "--") + " ")]
            ),
            _c("div", [
              _c(
                "span",
                {
                  staticStyle: { "margin-right": "10px", "font-size": "18px" },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.bagNumber")) + ":")]
              ),
              _c("span", { staticStyle: { "font-size": "22px" } }, [
                _vm._v(_vm._s(_vm.paramData.bagNumber || "--")),
              ]),
            ]),
            _c("div", [
              _c(
                "span",
                {
                  staticStyle: { "margin-right": "10px", "font-size": "18px" },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.DeliveryOutlets")) + ":")]
              ),
              _c("span", { staticStyle: { "font-size": "22px" } }, [
                _vm._v(_vm._s(_vm.paramData.deliverSiteName || "--")),
              ]),
            ]),
            _c("div", [
              _c(
                "span",
                {
                  staticStyle: { "margin-right": "10px", "font-size": "18px" },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.netAdress")) + ":")]
              ),
              _c("span", { staticStyle: { "font-size": "22px" } }, [
                _vm._v(_vm._s(_vm.paramData.siteAddress || "--")),
              ]),
            ]),
            _c("div", [
              _c(
                "span",
                {
                  staticStyle: { "margin-right": "10px", "font-size": "18px" },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.fenboCode")) + ":")]
              ),
              _c("span", { staticStyle: { "font-size": "22px" } }, [
                _vm._v(_vm._s(_vm.paramData.departmentCode || "--")),
              ]),
            ]),
            _c("div", [
              _c(
                "span",
                {
                  staticStyle: { "margin-right": "10px", "font-size": "18px" },
                },
                [_vm._v(_vm._s("Basecode") + ":")]
              ),
              _c("span", { staticStyle: { "font-size": "22px" } }, [
                _vm._v(_vm._s(_vm.paramData.baseCodeName || "--")),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "logoAnjun" }, [
            _c("img", {
              staticStyle: { width: "150px" },
              attrs: { src: require("@/assets/anjunTxt.jpg"), alt: "tu" },
            }),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }