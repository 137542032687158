<template>
  <div class="Box">

    <div>
      <div class="scanClass">
        <!-- 退件登记： -->
        <span class="redClass">{{ $t('collectionCenter.returnRegistration') }}</span>
        <el-input
          v-model.trim="waybillNumber"
          :placeholder="$t('collectionCenter.PleaseScan')"
          class="iconBtn"
          clearable
          prefix-icon="el-icon-full-screen"
          @keyup.enter.native="addTable"
        />
      </div>
      <div class="scanClass">
        <!-- 退回网点： -->
        <span>{{ $t('collectionCenter.returnPoint') }}</span>
        <el-select
          v-model="siteId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
          filterable @focus="getNewestSite"
        >
          <el-option
            v-for="(item, index) in siteIdList"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
    </div>
    <el-button
      class="onlyClickOnce"
      type="primary"
      @click="upload"
    >{{ $t('collectionCenter.Upload') }}
    </el-button><!-- 上传 -->

    <div class="tableClass">
      <el-table
        :data="scanData"
        :max-height="600"
        border
      >
        <el-table-column
          :label="$t('operatingPlatform.waybillNumber')"
          align="center"
          prop="waybillNumber"
        /><!-- 运单号 -->
        <el-table-column
          :label="$t('operatingPlatform.scanTime')"
          align="center"
          prop="scanTime"
        /><!-- 扫描时间 -->
        <el-table-column
          :label="$t('operatingPlatform.operation')"
          align="center"
          fixed="right"
          width="100"
        ><!-- 操作 -->
          <template slot-scope="scope">
            <el-popconfirm
              :title="$t('operatingPlatform.deleteSure')"
              @confirm="scanDelClick(scope.row, scope.$index)"
            ><!-- 确定删除吗？ -->
              <el-button slot="reference" size="mini" type="danger">{{ $t('operatingPlatform.Delete') }}</el-button>
              <!-- 删除 -->
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>

  </div>
</template>

<script>
import {
  searchSiteIdList,
  expressReturnScan,
  expressReturnDel,
  expressReturnUpload
} from '@/api/lanshou';

export default {
  name: 'ExpressReturn',
  data() {
    return {
      waybillNumber: '',
      siteId: '',
      siteIdList: [],
      scanData: [],
      erScanList: {} // 缓存
    };
  },
  created() {
    this.getNewestSite();
    if (localStorage.getItem('expressReturnList')) {
      this.erScanList = JSON.parse(window.atob(localStorage.getItem('expressReturnList'))) || {};
      const who = 'eR' + this.$store.state.user.id;
      if (this.erScanList[who]) {
        this.scanData = this.erScanList[who].scanData;
      } else {
        this.scanData = [];
      }
    }
  },
  methods: {
    // 获取最新的网点list数据
    getNewestSite() {
      searchSiteIdList().then(res => {
        if (res.status === 'OK') {
          this.siteIdList = [];
          res.data.forEach(data => {
            // if (this.siteId) {
            //   const id = data.id === this.siteId ? this.siteId : '';
            //   this.siteId = id;
            // }
            this.siteIdList.push({ id: data.id, name: data.name });
          });
        }
      });
    },
    addTable() {
      if (!this.waybillNumber) return;
      const form = {
        scanWaybillNumber: this.waybillNumber,
        sourceType: 1,
        operationType: 13
      };
      expressReturnScan(form).then(res => {
        if (res.status === 'OK') {
          this.scanData.unshift({
            scanRecordId: res.data.scanRecordId,
            waybillNumber: res.data.waybillNumber,
            scanTime: res.data.scanTime
          });
          this.erScanList['eR' + this.$store.state.user.id] = {
            scanData: this.scanData
          };
          const a = window.btoa(JSON.stringify(this.erScanList));
          localStorage.setItem('expressReturnList', a);

          this.waybillNumber = '';
        } else {
          this.waybillNumber = '';
        }
      }).catch(() => {
        this.waybillNumber = '';
      });
    },
    scanDelClick(row, index) {
      expressReturnDel(row.scanRecordId).then(res => {
        if (res.status === 'OK') {
          this.scanData.splice(index, 1);
          this.erScanList['eR' + this.$store.state.user.id] = {
            scanData: this.scanData
          };
          const a = window.btoa(JSON.stringify(this.erScanList));
          localStorage.setItem('expressReturnList', a);
        }
      }).catch({});
    },
    upload() {
      if (this.scanData.length <= 0) {
        this.$message.warning(this.$t('collectionCenter.nullSubmit')); // 无数据提交
        return;
      }
      const wayillNumerList = this.scanData.map(arr => {
        const arrw = arr.waybillNumber;
        return arrw;
      });
      const form = {
        sourceType: 1,
        siteId: this.siteId,
        wayillNumerList
      };
      expressReturnUpload(form).then(res => {
        if (res.status === 'OK') {
          this.scanData = [];
          this.siteId = '';

          this.erScanList['eR' + this.$store.state.user.id] = {
            scanData: []
          };
          const a = window.btoa(JSON.stringify(this.erScanList));
          localStorage.setItem('expressReturnList', a);
          this.$message.success(this.$t('collectionCenter.UploadSuccessful')); // 上传成功
        }
      }).catch(() => {});
    }
  }
};
</script>

<style lang="scss" scoped>
  .Box{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .scanClass{
    margin-bottom: 20px;
  }
  .scanClass ::v-deep .el-input {
    width: 350px;
  }
  .iconBtn::after {
    position: absolute;
    content: "-";
    top: 8px;
    left: 15px;
    transform: scaleX(2.3);
    color: #bbb;
  }
  // 防止按钮重复点击
  @keyframes throttle{
    from{
      filter: opacity(40%);
      pointer-events: none;
    }
    to{
      pointer-events: all;
    }
  }
  .onlyClickOnce{
    animation: throttle 2s step-end forwards;
  }
  .onlyClickOnce:active{
    filter: opacity(40%);
    animation: none;
  }
  .tableClass{
    margin-top: 20px;
  }
  .redClass{
    display: inline-block;
    position: relative;
    padding-left: 10px;
  }
  .redClass::before {
    position: absolute;
    margin-left: -10px;
    content: '*';
    color: #F56C6C;
  }

</style>
