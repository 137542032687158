<template>
  <div class="app-container pda">
    <el-tree
      :data="permissionList"
      :expand-on-click-node="false"
      :props="defaultProps"
    >
      <span slot-scope="{ node,data }" class="custom-tree-node mb">
        <span class="titlts">
          <i class="iconfont el-icon-mobile-phone" />
          {{ language == 'zh' ? data.name : data.puName }}
        </span>

        <span class="custom-tree-node-btn">
          <el-button
            v-permit:remove="'btn:user-center:permission:add2'"
            size="mini"
            type="primary"
            @click="() => addPdaChild(node,data)"
          >{{ $t('organizationManage.addNextLevel') }}</el-button><!-- 添加下级 -->
        </span>

        <span class="custom-tree-node-btn">
          <el-button
            v-permit:remove="'btn:user-center:permission:add1'"
            size="mini"
            type="success"
            @click="() => addPdaBrother(node,data)"
          >{{ $t('organizationManage.addSameLevel') }}</el-button><!-- 添加同级 -->
        </span>
        <span class="custom-tree-node-btn">
          <el-button
            size="mini"
            type="info"
            @click="() => editNode(node,data)"
          >{{ $t('organizationManage.edit') }}</el-button><!-- 编辑权限 -->
        </span>
        <span class="custom-tree-node-btn">
          <el-popconfirm
            :title="$t('roleManage.AreYouSureDelete')"
            @confirm="delNode(node,data)"
          ><!-- 确定删除吗？ -->
            <el-button
              slot="reference"
              size="mini"
              type="danger"
            >{{ $t('organizationManage.Delete') }}</el-button><!-- 删除权限 -->
          </el-popconfirm>
        </span>
      </span>
    </el-tree>
    <!-- 添加/编辑权限 -->
    <div class="pdaPrmClass">
      <el-dialog
        :title="pda.title"
        :visible.sync="pda.visible"
        width="30%"
        :close-on-click-modal="true"
        @close="closePDAPermission"
      >
        <!--
          权限表单:
        -->
        <el-form
          ref="pdaPermissionRef"
          :model="pdaPermissonForm"
          :rules="pdaPermissonRules"
          label-width="100px"
          label-position="left"
        >
          <el-row v-if="pda.title!==$t('roleManage.addPermissions')">
            <el-col :span="24">
              <!-- 权限ID -->
              <el-form-item :label="$t('roleManage.permissionsID')">
                <el-input v-model="pdaPermissonForm.id" disabled />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row v-show="pdaPermissonForm.parentId!==0">
            <el-col :span="24">
              <!-- 父权限ID -->
              <el-form-item :label="$t('roleManage.parentPermissionID')" prop="parentId">
                <el-input v-show="false" v-model="pdaPermissonForm.parentId" disabled />
                <el-input v-model="pdaPermissonForm.parentName" disabled />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <!-- 权限名称 -->
              <el-form-item :label="$t('roleManage.permissionsName')" prop="name">
                <el-input v-model.trim="pdaPermissonForm.name" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <!-- 权限名称（葡语） -->
              <el-form-item :label="$t('roleManage.permissionsNamePu')" prop="puName">
                <el-input v-model.trim="pdaPermissonForm.puName" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <!-- 权限名称（英语） -->
              <el-form-item :label="$t('roleManage.permissionsNameEn')" prop="enName">
                <el-input v-model.trim="pdaPermissonForm.enName" autocomplete="off" />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <!-- 权限标识 -->
              <el-form-item :label="$t('roleManage.permissionsTag')" prop="code">
                <el-input v-model.trim="pdaPermissonForm.code" autocomplete="off" />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <!-- 排序 -->
              <el-form-item
                :label="$t('roleManage.sort')"
                prop="sort"
                :rules="[ { required: true, message: $t('roleManage.sortNotEmpty')},{ type: 'number', message: $t('roleManage.sortIsNumber')}]"
              ><!--                                   排序不能为空                               排序必须为数字值 -->
                <el-input
                  v-model.number="pdaPermissonForm.sort"
                  type="sort"
                  :placeholder="$t('roleManage.littleValueFront')"
                  autocomplete="off"
                /><!-- 值越小越靠前 -->
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <!-- 备注 -->
              <el-form-item :label="$t('roleManage.note')">
                <el-input v-model="pdaPermissonForm.remark" type="textarea" autocomplete="off" />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item size="large">
                <el-button size="small" type="primary" @click="onSubmit">{{ $t('roleManage.confirm') }}</el-button><!-- 确认 -->
                <el-button size="small" @click="pda.visible = false">{{ $t('roleManage.cancel') }}</el-button><!-- 取消 -->
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { collectDriver, editPdaPermission, delPermission, addPdaPermission } from '@/api/permission';
export default {
  name: 'PositionManage',
  data() {
    return {
      language: this.$store.state.language.language,
      // 覆盖默认变量名
      defaultProps: {
        label: 'name'
      },
      permissionList: [],
      pda: {
        title: '',
        visible: false
      },
      pdaPermissonForm: {
        permissionType: 2,
        name: '',
        puName: '',
        enName: '',
        code: '',
        sort: '',
        remark: ''
      },
      pdaPermissonRules: {
        // '权限名称不能为空'
        name: [{ required: true, message: this.$t('organizationManage.permissionNameNotEmpty'), trigger: ['blur', 'change'] }],
        // '权限标识不能为空'
        code: [{ required: true, message: this.$t('organizationManage.permissionLogoNotEmpty'), trigger: ['blur', 'change'] }],
        puName: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        enName: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }]
      }
    };
  },
  created() {
    this.init();
  },
  methods: {
    /* 页面初始化工作*/
    init() {
      collectDriver().then(res => {
        if (res.status === 'OK') {
          this.permissionList = res.data.permissionStructure;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    editNode(node, data) { // 打开权限弹窗——编辑
      this.pda.title = this.$t('roleManage.editPermissions');
      this.pda.visible = true;
      this.pdaPermissonForm = {
        permissionType: 2,
        id: data.id,
        name: data.name,
        puName: data.puName,
        enName: data.enName,
        code: data.code,
        sort: data.sort,
        remark: data.remark,
        parentId: data.parentId,
        parentName: node.parent.data.name || ''
      };
      setTimeout(() => {
        this.$refs['pdaPermissionRef'].clearValidate();
      }, 0);
    },
    // addNode() { // 打开权限表单框——添加
    //   this.pda.title = this.$t('roleManage.addPermissions');
    //   this.pda.visible = true;
    //   setTimeout(() => {
    //     this.$refs['pdaPermissionRef'].clearValidate();
    //   }, 0);
    // },
    addPdaChild(node, data) { // 添加下级
      this.pda.title = this.$t('roleManage.addPermissions');
      this.pda.visible = true;
      setTimeout(() => {
        this.$refs['pdaPermissionRef'].clearValidate();
      }, 0);
      this.pdaPermissonForm.parentId = data.id || 0;
      this.pdaPermissonForm.parentName = data.name || '';
    },
    addPdaBrother(node, data) { // 添加同级
      this.pda.title = this.$t('roleManage.addPermissions');
      this.pda.visible = true;
      setTimeout(() => {
        this.$refs['pdaPermissionRef'].clearValidate();
      }, 0);
      this.pdaPermissonForm.parentId = node.parent.data.id || 0;
      this.pdaPermissonForm.parentName = node.parent.data.name || '';
    },
    onSubmit() { // 提交权限表单
      this.$refs.pdaPermissionRef.validate((valid) => {
        if (!valid) {
          return;
        }
        // 编辑
        if (this.pda.title === this.$t('roleManage.editPermissions')) {
          delete this.pdaPermissonForm.parentName;
          editPdaPermission(this.pdaPermissonForm).then(res => {
            if (res.status === 'OK') {
              this.pda.visible = false;
              this.init();
            }
          });
        }
        // 添加
        if (this.pda.title === this.$t('roleManage.addPermissions')) {
          addPdaPermission(this.pdaPermissonForm).then(res => {
            if (res.status === 'OK') {
              this.pda.visible = false;
              this.init();
            }
          });
        }
      });
    },
    closePDAPermission() { // 关闭权限表单
      this.pdaPermissonForm = {
        permissionType: 2,
        name: '',
        puName: '',
        enName: '',
        code: '',
        sort: '',
        remark: ''
      };
      this.$refs['pdaPermissionRef'].clearValidate();
    },
    delNode(node, data) {
      delPermission(data.id).then(res => {
        if (res.status === 'OK') {
          this.init();
        }
      });
    }

  }
};
</script>

<style lang="scss" scoped>
  .pdaPrmClass ::v-deep .el-form-item__label{
    line-height: 20px;
  }
.custom-tree-node {
  font-size: 18px;
  .custom-tree-node-btn {
    margin-left: 10px;
  }
}

.app-container.pda .iconfont {
  font-size: 20px;
  font-family: element-icons !important;
}

.titlts {
  display: inline-block;
  min-width: 120px;
  // border: 1px solid red;
}
</style>

<style  scoped>
.app-container >>> div.el-tree-node {
  margin-bottom: 5px;
}

.app-container >>> div.el-tree-node__children {
  margin-top: 10px;
}
</style>

