<script >
import { defineProps } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  isLoading: {
    type: Boolean
  },
  qtdRows: {
    type: Number,
    default: 10
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  return {};
};
export default __sfc_main;
</script>

<template>
  <div>
    <div v-if="isLoading" class="space-m-y-32">
      <o-skeleton v-for="i in qtdRows" :key="i" animated height="50px" />
    </div>
    <div v-else>
      <slot />
    </div>
  </div>
</template>

