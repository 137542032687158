import { apiDemageClaimFormEdit } from '@/api/compensationManagement/list'; 

export const commonMinXin = {
  data() {
    return {
      
    }
  },

  methods: {
    // 同步基础信息
    updateForm(data) {
      this.formList = data;
    },

    // 更新索赔金额
    clickUpdate(value) {
      this.$set(this.formList, 'claimAmount', value);

      // 更新基础信息
      setTimeout(() => {
        this.$refs.basicForm.setFormData(this.formList);
      }, 300);
    },

    // 供应商及业务类型变更清除关联订单
    remove() {
      if (this.Id) {
        this.claimRelatedOrdersList = [];
      } else {
        this.$refs.basicTable.cleraTable();
      }
    },
    // delConFirm() {
    //   this.$confirm('此操作将删除关联订单数据, 是否继续?', this.$i18n.t('GlobalSetObj.tips'), {
    //     confirmButtonText: this.$i18n.t('GlobalSetObj.determine'),
    //     cancelButtonText: this.$i18n.t('GlobalSetObj.cancel'),
    //     type: 'warning'
    //   }).then(() => {
    //     this.$message({
    //       type: 'success',
    //       message: this.$i18n.t('GlobalSetObj.TheOperationSucceeded')
    //     });
    //   }).catch(() => {
    //     this.$message({
    //       type: 'info',
    //       message: this.$i18n.t('GlobalSetObj.cancel')
    //     });          
    //   });
    // },

    // 保存/修改
    save() {
      const flag = this.formValidate();
      if (flag) {
        const data = this.getFormData();
        data.auditStatus = this.auditStatus;
        apiDemageClaimFormEdit(data).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$t('GlobalSetObj.SaveSuccessfully'));
            this.$emit('close', true);
          }
        }).catch(err => {
          console.error(err);
        });
      }
    },

    // 提交
    submit() {
      // 关联订单不可为空
      // auditStatus 1 提交 2 保存
      const flag = this.formValidate();
      if (flag) {
        const list = this.$refs.basicTable.getTableList();
        if (!list.length) {
          this.$message.warning(this.$t('compensationManagement.RelatedOrdersCannotBeEmpty'));
          return;
        }

        this.auditStatus = 1;

        this.save();
      }
    },

    // 表单验证
    formValidate() {
      let flag = false;
      this.$refs.basicForm.$refs.basicForm.validate((valid) => {
        if (valid) {
          flag = true;
        } else {
          flag = false;
        }
      });

      return flag;
    },

    // 获取索赔数据
    getFormData() {
      // 获取基础信息表单数据
      const obj = this.$refs.basicForm.getFormData();

      // 获取关联订单表单数据
      const arr = this.$refs.basicTable.getTableList();

      return {
        ...obj,
        relatedOrders: arr
      };
    },
  }
};