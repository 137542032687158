var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "wrapper-form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        },
      },
    },
    [
      _c(
        "o-field",
        {
          directives: [
            {
              name: "mask",
              rawName: "v-mask",
              value: ["###.###.###-##", "##.###.###/####-##"],
              expression: "['###.###.###-##', '##.###.###/####-##']",
            },
          ],
          attrs: {
            label: _vm.$t("trailer.documentOwner"),
            "label-for": "documentOwner",
            message: _vm.v$.documentOwner.$errors.length
              ? _vm.v$.documentOwner.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "documentOwner",
              placeholder: _vm.$t("trailer.documentOwnerPlaceholder"),
            },
            model: {
              value: _vm.formData.documentOwner,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "documentOwner", $$v)
              },
              expression: "formData.documentOwner",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("trailer.stateRegistration"),
            "label-for": "stateRegistration",
            message: _vm.v$.stateRegistration.$errors.length
              ? _vm.v$.stateRegistration.$errors[0].$message
              : null,
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "stateRegistration",
              placeholder: _vm.$t("trailer.stateRegistrationPlaceholder"),
            },
            model: {
              value: _vm.formData.stateRegistration,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "stateRegistration", $$v)
              },
              expression: "formData.stateRegistration",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("trailer.trackerCompany"),
            "label-for": "trackerCompany",
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "trackerCompany",
              placeholder: _vm.$t("trailer.trackerCompanyPlaceholder"),
            },
            model: {
              value: _vm.formData.trackerCompany,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "trackerCompany", $$v)
              },
              expression: "formData.trackerCompany",
            },
          }),
        ],
        1
      ),
      _c(
        "o-field",
        {
          attrs: {
            label: _vm.$t("trailer.trackerCompanyCode"),
            "label-for": "trackerCompanyCode",
          },
        },
        [
          _c("o-input", {
            attrs: {
              id: "trackerCompanyCode",
              placeholder: _vm.$t("trailer.trackerCompanyCodePlaceholder"),
            },
            model: {
              value: _vm.formData.trackerCompanyCode,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "trackerCompanyCode", $$v)
              },
              expression: "formData.trackerCompanyCode",
            },
          }),
        ],
        1
      ),
      _c(
        "FormActions",
        [
          _c("FormActionsPrevious", {
            attrs: { "is-first-step": _vm.isFirstStep },
            on: { handlePrevious: _vm.handlePrevious },
          }),
          _c("FormActionsNext", { attrs: { "is-last-step": _vm.isLastStep } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }