<template>
  <el-table
    :data="tableData"
    :min-height="480"
    :height="480"
    border
    style="width: 100%"
  >

    <!--序号-->
    <el-table-column
      type="index" :label="$t('operationCenter.ind')" width="60px"
      align="center"
    />

    <!-- 操作时间 -->
    <el-table-column :label="$t('GlobalSetObj.operationTime')" prop="createTime" />

    <!-- 操作节点 -->
    <el-table-column :label="$t('userManage.node')" prop="operationNodeStr" />

    <!-- 操作人 -->
    <el-table-column :label="$t('GlobalSetObj.operator')" prop="createUserStr" />

    <!-- 操作详情 -->
    <el-table-column :label="$t('GlobalSetObj.details')" prop="remarks" />
  </el-table>
</template>

<script>
import { CLAIMS_STATUS_OBJ } from '@/views/claimsManagement/claimList/utils.js';
import { getUserIdAndNameObj } from '@/enums/storageEnum';

export default {
  name: '',
  data() {
    return {
      tableData: []
    };
  },

  created() {
    this.userObj = getUserIdAndNameObj();
  },

  methods: {
    setDataList(arr) {
      this.tableData = arr.map(item => {
        const { id, createTime, operationNode, createUser, remarks } = item;

        const operationNodeStr = CLAIMS_STATUS_OBJ[operationNode]?.name ?? '';

        let createUserStr = '';
        if (createUser) {
          createUserStr = this.userObj[createUser] || '';
        }

        const obj = {
          id,
          createTime, // 操作时间
          operationNode, // 操作节点
          operationNodeStr, // 操作节点
          createUser, // 操作人
          createUserStr, // 操作人
          remarks// 操作详情
        };

        return Object.freeze(obj);
      });
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
