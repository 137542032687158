<script >
import { useQueryClient } from '@tanstack/vue-query';
import { defineProps } from '@vue/composition-api';
import { default as DropdownTable } from '@/components/dropdownTable';
import { default as Alert } from '@/components/alert';
import { useModal } from '@/composable/useModal';
import { mdfeService } from '@/api/mdfe';
import { toast } from '@/utils/programaticToast';
import { getToastContentComponent } from '@/utils/getToastContentComponent';
import getMessageError from '@/utils/getMessageError';
const __sfc_main = {};
__sfc_main.props = {
  row: {
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;

  /* close bank */
  const {
    isOpen: isOpenModalClose,
    handleVisibility: handleVisibilityModalClose
  } = useModal();

  /* cancel bank */
  const {
    isOpen: isOpenModalCancel,
    handleVisibility: handleVisibilityModalCancel
  } = useModal();
  const queryClient = useQueryClient();
  async function handleClose() {
    const body = {
      mdfeKey: props.row.mdfeKey,
      nProt: props.row.protocolNumber,
      cnpj: props.row.requestBody.emit.CNPJ,
      seq: '1',
      cMun: props.row.requestBody.emit.enderEmit.cMun,
      cUF: '35'
    };
    try {
      await mdfeService.closeMDFE(body);
      queryClient.invalidateQueries({
        queryKey: ['mdfe']
      });
      handleVisibilityModalClose();
      toast.open({
        variant: 'success',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Encerrado com sucesso',
          describe: 'MDFE foi encerrada com sucesso',
          typeIcon: 'success'
        }),
        closable: true,
        duration: 4000
      });
    } catch (error) {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Ocorreu um erro',
          describe: getMessageError(error),
          typeIcon: 'error'
        }),
        closable: true,
        duration: 4000
      });
    }
  }
  async function handleCancel() {
    const body = {
      nProt: props.row.protocolNumber,
      mdfeKey: props.row.mdfeKey,
      cnpj: props.row.requestBody.emit.CNPJ,
      seq: '1'
    };
    try {
      await mdfeService.cancelMDFE(body);
      queryClient.invalidateQueries({
        queryKey: ['mdfe']
      });
      handleVisibilityModalCancel();
      toast.open({
        variant: 'success',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Cancelado com sucesso',
          describe: 'MDFE foi cancelada com sucesso',
          typeIcon: 'success'
        }),
        closable: true,
        duration: 4000
      });
    } catch (error) {
      toast.open({
        variant: 'error',
        position: 'bottom',
        component: getToastContentComponent({
          title: 'Ocorreu um erro',
          describe: getMessageError(error),
          typeIcon: 'error'
        }),
        closable: true,
        duration: 4000
      });
    }
  }
  return {
    isOpenModalClose,
    handleVisibilityModalClose,
    isOpenModalCancel,
    handleVisibilityModalCancel,
    handleClose,
    handleCancel
  };
};
__sfc_main.components = Object.assign({
  DropdownTable,
  Alert
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
  <div>
    <DropdownTable aria-role="list">
      <o-dropdown-item aria-role="listitem" @click="handleVisibilityModalClose">
        Encerrar
      </o-dropdown-item>

      <o-dropdown-item aria-role="listitem" @click="handleVisibilityModalCancel">
        Cancelar
      </o-dropdown-item>
    </DropdownTable>

    <Alert
      :active="isOpenModalClose"
      @handleClose="handleVisibilityModalClose"
    >
      <template #title> Você tem certeza absoluta? </template>
      <template #message> Essa ação não pode ser desfeita. Isso ira encerrar a MDFE {{ row.mdfeKey }} </template>

      <template #buttons>
        <o-button variant="outline" size="md" @click="handleVisibilityModalClose"> cancelar </o-button>
        <o-button variant="primary" size="md" @click="handleClose"> sim, quero encerrar </o-button>
      </template>
    </Alert>

    <Alert
      :active="isOpenModalCancel"
      @handleClose="handleVisibilityModalCancel"
    >
      <template #title> Você tem certeza absoluta? </template>
      <template #message> Essa ação não pode ser desfeita. Isso ira cancelar a MDFE {{ row.mdfeKey }} </template>

      <template #buttons>
        <o-button variant="outline" size="md" @click="handleVisibilityModalCancel"> cancelar </o-button>
        <o-button variant="primary" size="md" @click="handleCancel"> sim, quero encerrar </o-button>
      </template>
    </Alert>
  </div>
</template>
